import React from "react";
import { Alert } from "react-bootstrap";

import NsStickyButton from "../../../../components/buttons/NsStickyButton";
import NsCheckbox from "../../../../components/inputs/NsCheckbox";
import NsModal from "../../../../components/modals/NsModal";
import NsSnackbar from "../../../../components/snackbars/NsSnackbar";
import Enumerations from "../../../../configs/Enumerations";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import StorageHelper from "../../../../utilities/StorageHelper";

class AddToListResultModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            CommonHelper.createStickyButtonItem('common.ok', null, props.closeModal, 'primary'),
        ];
        this.isShowSnackbar = !!StorageHelper.getFromStorage(Enumerations.storage.showAddToListProfessionalOrderModal);
        this.state = {
            open: !!StorageHelper.getFromStorage(Enumerations.storage.showAddToListProfessionalOrderModal)
        }
    }

    checkboxClicked = (checked) => {
        StorageHelper.saveInStorage(Enumerations.storage.showAddToListProfessionalOrderModal, checked)
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        this.setState({ open: false });
        CommonHelper.safeFunctionCall(this.props.closeModal);
    };

    render() {
        return this.isShowSnackbar
            ? <NsSnackbar open={this.state.open}
                autoHideDuration={10000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={this.handleClose}>
                <Alert variant="success" className="p-0">
                    <div className='d-flex align-items-center justify-content-between px-2'>
                        <i className='ns-icon-added-to-list info-color h2 mb-0' />
                        <span className='fw-bold'>
                            {getTranslatedRawText('regularOrder.modal.addToListResultSnackbarMessage')}
                        </span>
                    </div>
                </Alert>
            </NsSnackbar >
            : <NsModal showModal={true}
                dialogTitle={getTranslatedRawText('regularOrder.modal.addToListResultModalTitle')}
                closeModal={this.props.closeModal}
                actions={<NsStickyButton stickyButtons={this.mainButtons} />}>
                <div className='d-flex align-items-center justify-content-between px-2'>
                    <i className='ns-icon-added-to-list info-color' style={{ fontSize: '80px' }} />
                    <p className=''>
                        {getTranslatedRawText('regularOrder.modal.addToListResultModalMessage')}
                    </p>
                </div>
                <NsCheckbox value={false}
                    onChange={this.checkboxClicked}
                    className='ps-4 mt-2 font-size-16'
                    checkboxClassName='ps-0 font-size-16'
                    label={
                        <span className="font-size-16 fw-bold">
                            {getTranslatedRawText('profile.pay.modal.iWasNoticed')}
                        </span>
                    } />
            </NsModal >
    }
}

export default AddToListResultModal;