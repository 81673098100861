import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import '../../assets/sass/views/layout/main/footers/Footers.scss';
import Config from '../../configs/Config';
import CommonHelper from "../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../utilities/CultureHelper";
import { getTitleMetaTagByCulture } from "../metaTags/NsSetPageTitleAndDescription";

class NsEnamad extends React.Component {
    constructor(props) {
        super(props);
        this.enamad = Config.enamad;
    }
    render() {
        return <Container>
            <Row className={`d-flex m-0 p-0 pb-1 w-100 ${!CommonHelper.isMobileDevice() ? 'justify-content-between' : 'ps-1'}`} >
                <Col className="m-0 p-0"
                    xs={5}
                    sm={4}
                    md={3}
                    lg={2}>
                    {this.enamad.length > 0 && this.enamad[0].show &&
                        <img className={`${!CommonHelper.isMobileDevice() && 'h-100'} w-sm-75`}
                            src={this.enamad[0].src}
                            alt={CommonHelper.stringFormat(getTranslatedRawText('components.imagesAlt.logo'), getTitleMetaTagByCulture)} />
                    }
                </Col>

                <Col className="m-0 p-0 d-flex h-100"
                    xs={7}
                    sm={8}
                    md={4}
                    lg={2}>
                    {this.enamad.length > 1 && this.enamad[1].show &&
                        <Enamad item={this.enamad[1]} />
                    }
                    {this.enamad.length > 2 && this.enamad[2].show &&
                        <Enamad item={this.enamad[2]} />
                    }
                </Col>
            </Row>
        </Container>
    }
}

function Enamad(props) {
    return <div className="rounded">
        <a target='_blank' href={props.item.link}>
            <img className="w-xs-75 w-md-100" width={'100%'} src={props.item.src} alt={getTranslatedRawText("components.imagesAlt.enamd")} />
        </a>
    </div>
}

export default NsEnamad;