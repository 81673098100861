import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import ImageHelper from '../../../utilities/ImageHelper';
import NsTypography from '../../../components/text/NsTypography';
import Config from '../../../configs/Config';
import Enumerations from '../../../configs/Enumerations';
import RegularOrderTours from '../../bases/tours/RegularOrderTours';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsTour from '../../../components/tour/NsTour';
import NsButton from '../../../components/buttons/NsButton';
import { messageClass } from '../../../utilities/MessageModalHelper';
import NsPunchArea from '../../../components/punchArea/NsPunchArea';
import NsAlertModal from '../../../components/modals/NsAlertModal';
import { isEmpty } from '../../../utilities/ValidationHelper';
import OBaseModalItemList from './bases/OBaseModalItemList';

class AddPunchModal extends React.Component {

    constructor(props) {
        super(props);
        this.tourSteps = RegularOrderTours.getAddPunchModalTour();
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.choosePunchModal);
        this.mainButtons = [
            CommonHelper.createStickyButtonItem("common.save", 'ns-icon-save', this.saveClicked, 'primary'),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.closeClicked, 'primary-outline')
        ];
        this.maxPunches = Config.order.maxPunchQuantity;

        this.state = {
            masterFileInfo: props.masterFileInfo,
            productDesignTemplateUrl: props.productDesignTemplateUrl,
            hasMaxPunchDistanceEdges: props.masterFileInfo.width > Config.order.maxPunchDistanceFromEdge * 2 && props.masterFileInfo.height > Config.order.maxPunchDistanceFromEdge * 2,
            punches: [],
            ratio: null,
            isChangedPosition: false,
            isAlertModalOpen: false,
            resultMessageClass: null,
        }
    }

    deleteClicked = (deletedPunchIndex) => {
        this.setState(prevState => ({
            punches: prevState.punches.filter((punch, index) => index !== deletedPunchIndex)
        }));
    }

    addPunchClicked = () => {
        const { masterFileInfo, ratio } = this.state;
        let minPunchDistanceFromEdge = ImageHelper.convertMillimeterToPixel(Config.order.minPunchDistanceFromEdge +
            this.props.trimZone?.top + this.props.pointDiameter, masterFileInfo.resolution) * ratio;
        this.setPunchPosition(minPunchDistanceFromEdge, minPunchDistanceFromEdge);
    }

    closeClicked = () => {
        this.state.isChangedPosition
            ? this.setState({ isAlertModalOpen: true })
            : CommonHelper.safeFunctionCall(this.props.closeAddPunchModalClicked);
    }

    closeAlertClicked = (confirm) => {
        this.setState({ isAlertModalOpen: false });
        !!confirm && CommonHelper.safeFunctionCall(this.props.closeAddPunchModalClicked);
    }

    saveClicked = () => {
        const { punches } = this.state;
        let punch = !isEmpty(punches) ? punches[0] : [];
        if (this.checkAuthorizedArea(punch?.verticalPoint, punch?.horizontalPoint))
            this.setState({
                resultMessageClass: messageClass(getTranslatedRawText("common.messages.notAuthorizedAreaText"))
            });
        else
            CommonHelper.safeFunctionCall(this.props.saveAddPunchModalClicked, !isEmpty(punches) ? punches[0] : {});
    }

    setRatio = (ratio) => {
        const { masterFileInfo } = this.state;
        let punches = !isEmpty(this.props.selectedPunch)
            ? [{
                ...this.props.selectedPunch,
                top: ImageHelper.convertMillimeterToPixel(this.props.selectedPunch?.verticalPoint, masterFileInfo?.resolution) * ratio,
                left: ImageHelper.convertMillimeterToPixel(this.props.selectedPunch?.horizontalPoint, masterFileInfo?.resolution) * ratio,
                isSelected: false,
            }]
            : [];
        this.setState({
            pointDiameter: ImageHelper.convertMillimeterToPixel(this.props.pointDiameter, masterFileInfo?.resolution) * ratio,
            punches,
            ratio
        });
    }

    convertToMilimeter = (position) => {
        const { masterFileInfo, ratio } = this.state;
        return ImageHelper.convertPixelToMillimeter(position / ratio, masterFileInfo?.resolution);
    }

    convertToPixel = (position) => {
        const { masterFileInfo, ratio } = this.state;
        return ImageHelper.convertMillimeterToPixel(position * ratio, masterFileInfo?.resolution);
    }

    checkAuthorizedArea = (top, left) => {
        const { masterFileInfo, hasMaxPunchDistanceEdges } = this.state;
        const { pointDiameter } = this.props;
        let trimZone = { ...this.props.trimZone };
        if (!trimZone) {
            trimZone = {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            };
        }
        let outsidePunchTrimZone = Math.max(Config.order.minPunchDistanceFromEdge, trimZone.left);
        let insidePunchTrimZone = Config.order.maxPunchDistanceFromEdge;
        if (top <= pointDiameter / 2 + outsidePunchTrimZone || top > masterFileInfo.height - pointDiameter / 2 - outsidePunchTrimZone ||
            left <= pointDiameter / 2 + outsidePunchTrimZone || left > masterFileInfo.width - pointDiameter / 2 - outsidePunchTrimZone
        ) {
            return true;
        }
        if (hasMaxPunchDistanceEdges) {
            if (top > pointDiameter / 2 + insidePunchTrimZone && top <= masterFileInfo.height + pointDiameter / 2 - insidePunchTrimZone &&
                left > pointDiameter / 2 + insidePunchTrimZone && left < masterFileInfo.width + pointDiameter / 2 - insidePunchTrimZone)
                return true;
        }
        return false;
    }

    changeTopPunchPositionClicked = (verticalPoint, selectedIndex) => {
        this.setState(prevState => {
            let punches = prevState.punches.map((punch, index) => index === selectedIndex ? { ...punch, top: this.convertToPixel(verticalPoint), verticalPoint, isSelected: index === selectedIndex } : punch);
            return { punches, isChangedPosition: true }
        });
    }

    changeLeftPunchPositionClicked = (horizontalPoint, selectedIndex) =>
        this.setState(prevState => {
            let punches = prevState.punches.map((punch, index) => index === selectedIndex ? { ...punch, left: this.convertToPixel(horizontalPoint), horizontalPoint, isSelected: index === selectedIndex } : punch);
            return { punches, isChangedPosition: true }
        });

    setPunchPosition = (left, top) => {
        let punches = [...this.state.punches];
        punches.map(punch => ({ ...punch, isSelected: false }));
        punches?.length < this.maxPunches
            ? punches.push({ top, left, verticalPoint: this.convertToMilimeter(top).toFixed(2), horizontalPoint: this.convertToMilimeter(left).toFixed(2), isSelected: true })
            : punches[punches.length - 1] = { top, left, verticalPoint: this.convertToMilimeter(top).toFixed(2), horizontalPoint: this.convertToMilimeter(left).toFixed(2), isSelected: true };

        this.setState({ punches, isChangedPosition: true });
    }

    render() {
        const { masterFileInfo, punches, pointDiameter, hasMaxPunchDistanceEdges, productDesignTemplateUrl, isAlertModalOpen } = this.state;
        const isEmptyPunch = isEmpty(punches);
        return (
            <NsModal showModal={this.props.showModal}
                maxWidth="lg"
                primaryActionButton={this.saveClicked}
                closeModal={this.closeClicked}
                dialogClassName="modal-choose-printing-files"
                dialogTitle={getTranslatedRawText('regularOrder.extendedService.addPunch')}
                actions={<NsStickyButton stickyButtons={this.mainButtons} />}>

                <Row className='justify-content-center'>
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} className='position-relative'>
                        <NsPunchArea onClick={this.imageClicked}
                            imgSrc={masterFileInfo?.draft}
                            masterFileInfo={masterFileInfo}
                            trimZone={this.props.trimZone}
                            punches={punches}
                            pointDiameter={pointDiameter}
                            hasMaxPunchDistanceEdges={hasMaxPunchDistanceEdges}
                            productDesignTemplateUrl={productDesignTemplateUrl}
                            setItemPosition={this.setPunchPosition}
                            changeLeftPunchPositionClicked={this.changeLeftPunchPositionClicked}
                            changeTopPunchPositionClicked={this.changeTopPunchPositionClicked}
                            punchClicked={this.punchClicked}
                            setRatio={this.setRatio} />
                    </Col>

                    <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 2 }} className='mb-2'>
                        <div className='d-flex justify-content-end'>
                            <NsButton label='regularOrder.common.add'
                                size='small'
                                disabled={punches.length >= this.maxPunches}
                                wrapperClassName='mb-2'
                                className='secondary'
                                startIcon="ns-icon-add"
                                onClick={this.addPunchClicked} />
                        </div>

                        {!isEmptyPunch &&
                            <NsTypography variant='inherit' className='font-size-14 black-color'>
                                {getTranslatedRawText('regularOrder.extendedService.punchPrice')}
                                <span className='fw-bold'>
                                    {CommonHelper.currencyFormatByDefaultConfig(this.props.punchPrice)}
                                </span>
                            </NsTypography>
                        }

                        <OBaseModalItemList items={punches}
                            isPunchList={true}
                            checkAuthorizedArea={this.checkAuthorizedArea}
                            masterFileDimention={{ width: masterFileInfo?.width, height: masterFileInfo.height }}
                            changeTopItemPositionClicked={this.changeTopPunchPositionClicked}
                            changeLeftItemPositionClicked={this.changeLeftPunchPositionClicked}
                            deleteClicked={this.deleteClicked} />

                    </Col>
                </Row>

                {isAlertModalOpen &&
                    <NsAlertModal isOpen={isAlertModalOpen}
                        cancelClicked={() => this.closeAlertClicked(false)}
                        confirmClicked={() => this.closeAlertClicked(true)} >
                        {getTranslatedRawText('regularOrder.modal.closeServicesModalAlert')}
                    </NsAlertModal>}

                {
                    (!isEmptyPunch && this.showTour) && <NsTour steps={this.tourSteps}
                        id={Enumerations.tourGuide.choosePunchModal} />
                }

                {this.state.resultMessageClass}

            </NsModal >
        );
    }
}

export default AddPunchModal