import React from 'react';
import { Container } from 'react-bootstrap';

import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import SocialMedias from './SocialMedias';

const TopFooter = () => {
    return (
        <div className='top-footer__border-bottom'>
            <Container>
                <div className='pt-2 d-flex justify-content-between align-items-center font-size-14 m-0'>
                    <div>{getTranslatedRawText('footer.contactWays')}</div>
                    <SocialMedias />
                </div>
            </Container>
        </div>
    );
};

export default React.memo(TopFooter);