import React from "react";
import { Col, Row } from "react-bootstrap";

import ApDfbRatingParameterProgress from "../feedbaclDashboardAnnouncements/ApDfbRatingParameterProgress";
import ApDfbRatingParameterChart from "../feedbaclDashboardAnnouncements/ApDfbRatingParameterChart";
import ApDfbLastFeedbacksGrid from "../feedbaclDashboardAnnouncements/ApDfbLastFeedbacksGrid";
import ApBasesDfbStrengthWeeknessChart from "../bases/ApBasesDfbStrengthWeeknessChart";
import ApBasesDfbPercentageStatusChart from "../bases/ApBasesDfbPercentageStatusChart";
import ApBasesDfbTotalByStatusGedgets from "../bases/ApBasesDfbTotalByStatusGedgets";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import ApBasesDfbStatusBarChart from "../bases/ApBasesDfbStatusBarChart";
import ApBasesDfbTotalLineChart from "../bases/ApBasesDfbTotalLineChart";
import ApBasesDfbCustomersGrid from "../bases/ApBasesDfbCustomersGrid";
import ApBasesDfbProvinceMap from "../bases/ApBasesDfbProvinceMap";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import ApBasesDfbCitiesGrid from "../bases/ApBasesDfbCitiesGrid";
import Enumerations from "../../../../../configs/Enumerations";
import ApBasesDfbComments from "../bases/ApBasesDfbComments";
import ApDfbEmployeesGrid from "./ApDfbEmployeesGrid";

const ApDfbEmployees = (props) => {

    const { refreshDate } = props;

    const parameters = { ...props.parameters, feedbackType: Enumerations.feedbackTypes.user }

    return (
        <Row className="g-2">
            {hasAccess([UserClaimEnumerations.getApDfbTotalByStatusGedgets]) && <Col xs={12} className="mb-3">
                <ApBasesDfbTotalByStatusGedgets parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbUserFeedbacks]) && <Col xs={12} className="mb-3">
                <ApBasesDfbTotalLineChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbStrengthWeeknessChart]) && <>
                <Col lg={6} className="mb-3">
                    <ApBasesDfbStrengthWeeknessChart title={getTranslatedRawText('adminPanel.feedback.strengths')}
                        feedbackTypeMetricType={Enumerations.feedbackMetricTypes.strengths}
                        parameters={parameters}
                        refreshDate={refreshDate} />
                </Col>
                <Col lg={6} className="mb-3">
                    <ApBasesDfbStrengthWeeknessChart title={getTranslatedRawText('adminPanel.feedback.weaknesses')}
                        feedbackTypeMetricType={Enumerations.feedbackMetricTypes.weaknesses}
                        parameters={parameters}
                        refreshDate={refreshDate} />
                </Col>
            </>
            }
            {hasAccess([UserClaimEnumerations.getApDfbTotalStatusByFilterOptions]) && <Col md={5} className="mb-3">
                <ApBasesDfbPercentageStatusChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbComments]) && <Col md={7} className="mb-3">
                <ApBasesDfbComments parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbByStateTypesAndDateChart]) && <Col xs={12} className="mb-3">
                <ApBasesDfbStatusBarChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbByTypeMetricAndRates]) && <Col md={4} className="mb-3">
                <ApDfbRatingParameterProgress parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbRatingParametersChart]) && <Col md={8} className="mb-3">
                <ApDfbRatingParameterChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbProvinces]) && <Col md={4} className="mb-3">
                <ApBasesDfbProvinceMap parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbCitiesGrid]) && <Col md={4} className="mb-3">
                <ApBasesDfbCitiesGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbCustomers]) && <Col md={4} className="mb-3">
                <ApBasesDfbCustomersGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApFeedbackShareSheetOrders]) && <Col xs={7} className="mb-3">
                <ApDfbLastFeedbacksGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {/* update claim after */}
            {hasAccess([UserClaimEnumerations.getApDfbEmployeesGrid]) && true && < Col xs={5} className="mb-3">
                <ApDfbEmployeesGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
        </Row >

    );
}

export default React.memo(ApDfbEmployees);