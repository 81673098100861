import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import NsButton from '../../../components/buttons/NsButton';
import NsModal from '../../../components/modals/NsModal';
import NsTypography from '../../../components/text/NsTypography';
import Links from '../../../configs/links/AllLinks';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture, isEnCulture } from '../../../utilities/CultureHelper';


class SuccessPaymentStoreOrderCartModal extends React.Component {

    redirectToShipmentModal = () => this.props.history.push(
        getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequest),
        { isShowShipmentModal: true }
    )

    closeModal = () => CommonHelper.safeFunctionCall(this.props.closeModal, false);

    render() {

        return (
            <NsModal maxWidth="sm"
                dialogTitle={getTranslatedRawText('profile.orderCart.modal.successfullyOrder')}
                showModal={true}
                closeModal={this.closeModal}>
                <div className='d-flex align-items-center'>
                    <div className='me-3'>
                        <i className='ns-icon-circle-confirm fs-1 success-color' />
                    </div>
                    <div className='flex-grow-1'>
                        <NsTypography className='mb-2'>{getTranslatedRawText('common.messages.successStoreOrderPaymentMessage')}</NsTypography>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-end pt-3'>

                    <Link className='text-decoration-none' to={getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.readyToDeliverOrderInTheWarehouse)}>
                        <div className='red-color cursor-pointer'>
                            <i className={`${isEnCulture() ?'ns-icon-expand-left' : 'ns-icon-expand-right'} me-2`}></i>
                            <span className='font-size-14 fw-bold'>{getTranslatedRawText('profile.actionButton.readyToDeliver')} </span>
                            <span className='font-size-12'>{getTranslatedRawText('profile.orderCart.storeOrders')}</span>
                        </div>
                    </Link>

                    <NsButton className='primary ms-3 w-auto'
                        onClick={this.redirectToShipmentModal}
                        startIcon='ns-icon-add'
                        label='profile.readyToDeliver.addShippingRequest' />

                </div>
            </NsModal>
        )
    }
}

export default withRouter(SuccessPaymentStoreOrderCartModal);