import React, { useEffect, useState } from 'react';

import NsCard from '../../../../components/cards/NsCard';
import { GetOrderByTurnaroundService } from '../../../../services/adminPanel/AdminDashboardService';
import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';

const ApDfOrderByTurnaround = (props) => {

    const { parameters, refreshDate } = props;
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState();

    useEffect(() => {
        getOrderByTurnaround();
    }, [refreshDate])

    const getOrderByTurnaround = () => {
        setIsLoading(true);
        GetOrderByTurnaroundService(parameters, getOrderByTurnaroundCallback);
    }
    const getOrderByTurnaroundCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setBarChartOptions(resultData, 0.4));
        } else
            setMessageClassModal(messageClassModal);
    };

    const options = {
        scales: {
            x: {
                display: true,
                grid: {
                    display: true
                }
            },
            y: {
                display: true,
                grid: {
                    display: true
                }
            }
        }
    };

    return (
        <NsCard className='p-3'>
            <ApBasesDashboardCharts title='adminPanel.dashboard.totalOnlinePayments'
                height='75vh'
                type='bar'
                tooltipTitle='adminPanel.dashboard.orderByTurnaroundDfChartDes'
                data={data}
                hasZoom={true}
                refreshClicked={getOrderByTurnaround}
                options={options}
                isLoading={isLoading} />
            {messageClassModal}
        </NsCard>
    );
};

export default React.memo(ApDfOrderByTurnaround);