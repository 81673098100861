import React, { Component } from 'react'
import NsButton from '../../../../components/buttons/NsButton'

import NsGroupNumberInput from '../../../../components/inputs/NsGroupNumberInput'
import { ChangeSeries } from '../../../../services/order/ProfessionalOrderService'
import CommonHelper from '../../../../utilities/CommonHelper'
class PoChangeSeries extends Component {
    constructor(props) {
        super(props)
        this.state = {
            series: this.props.series,
            isEnableConfirm: false,
            resultMessageClass: (<></>)
        }
    }

    seriresChanged = (series) => {
        if (series !== this.props.series)
            this.setState({ isEnableConfirm: true })
        else
            this.setState({ isEnableConfirm: false })

        this.setState({ series })
    }

    confirmButtonClicked = () => {
        let data = {
            sharedSheetOrderDetailId: this.props.sharedSheetOrderDetailId,
            series: this.state.series
        }
        ChangeSeries(data, this.changeSeriesCallback)
    }

    changeSeriesCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            CommonHelper.safeFunctionCall(this.props.refreshGrid)
        } else {
            this.setState({ resultMessageClass: messageClassModal })
        }
    }

    render() {
        const { series, isEnableConfirm } = this.state
        return (
            <div className='d-flex align-items-center'>
                <NsGroupNumberInput label={''}
                    value={series}
                    onChanged={this.seriresChanged} />
                <NsButton startIcon='ns-icon-confirm'
                    size='small'
                    className='light ms-1'
                    disabled={!isEnableConfirm}
                    onClick={this.confirmButtonClicked} />

                {this.state.resultMessageClass}
            </div>
        )
    }
}
export default PoChangeSeries