import React, { useCallback, useEffect, useState } from "react";

import NsStickyButton from "../../../../components/buttons/NsStickyButton";
import NsEditor from "../../../../components/editor/NsEditor";
import NsTextField from "../../../../components/inputs/NsTextField";
import NsMessageClassModal from "../../../../components/modals/NsMessageClassModal";
import NsModal from "../../../../components/modals/NsModal";
import NsSwitch from "../../../../components/switch/NsSwitch";
import Enumerations from "../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import { DeleteStaticPageRowService, EditStaticPageRowService, GetStaticPageRowByIdService, PostStaticPageService } from '../../../../services/adminPanel/AdminSinglePagesService';
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApBaseMapActionButtons from "../../bases/ApBaseMapActionButtons";
import ApLanguageTabs from "../../bases/ApLanguageTabs";
import ContentHelper from "../bases/ContentHelper";
import { DeleteAgreementStaticPagesContentService, EditAgreementStaticPagesContentService, GetAgreementStaticPageDetailService, PostAgreementStaticPagesContentService } from "../../../../services/adminPanel/AdminAgreementService";

const ApSinglePagesModal = (props) => {
    const { showModal, rowId, isNewRow, staticPageInfo, agreementId } = props;

    const [multiLanguageRowContent, setMultiLanguageRowContent] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [editorValue, setEditorValue] = useState({});
    const [showValidationDate, setShowValidationDate] = useState();
    const [showDeleteRowModal, setShowDeleteRowModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        !!isNewRow
            ? setMultiLanguageRowContent(ContentHelper.normalizeContentObject([], { staticPageType: staticPageInfo.id, isActive: false, isTitleVisible: true }))
            : agreementId
                ? GetAgreementStaticPageDetailService({
                    id: rowId,
                    staticPageTypeId: staticPageInfo.id
                }, getRowByIdCallback)
                : GetStaticPageRowByIdService(rowId, {
                    staticPageTypeId: staticPageInfo.id
                }, getRowByIdCallback);
    }, []);

    const getRowByIdCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            setMultiLanguageRowContent(ContentHelper.normalizeContentObject(resultData, { staticPageType: staticPageInfo.id, isActive: resultData[0].content.isActive, isTitleVisible: resultData[0].content.isTitleVisible }));
            showCurrentTabData(resultData);
        };
    }

    const closeModalClicked = (messageClassModal) => CommonHelper.safeFunctionCall(props.closeModal, messageClassModal);

    const saveButtonClicked = () => {
        editorHandler(true, [...multiLanguageRowContent], editorValue, selectedLanguage.id);
        let tempContent = ContentHelper.removeStaticPageEmptyContent(multiLanguageRowContent);
        let isNotValidData = ContentHelper.isValidStaticPageContent(tempContent);

        if (isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }
        setIsLoading(true);

        if (!!isNewRow) {
            !!agreementId
                ? PostAgreementStaticPagesContentService(agreementId, tempContent, postRowCallback, true)
                : PostStaticPageService(tempContent, postRowCallback, true);
        } else {
            !!agreementId
                ? EditAgreementStaticPagesContentService(agreementId, tempContent[0]?.content?.id, tempContent, editRowCallback, true)
                : EditStaticPageRowService(rowId, tempContent, editRowCallback, true)
        }
    };

    const postRowCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModalClicked(messageClassModal);
        } else setMessageClassModal(messageClassModal);
    }

    const editRowCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModalClicked(messageClassModal);
        } else setMessageClassModal(messageClassModal);
    }

    const deleteRowClicked = () => setShowDeleteRowModal(true);

    const deleteRow = (confirm) => {
        setShowDeleteRowModal(false);
        if (confirm) {
            setIsLoading(true);
            !!agreementId
                ? DeleteAgreementStaticPagesContentService(rowId, staticPageInfo.id, deleteRowContentCallback, true)
                : DeleteStaticPageRowService(rowId, staticPageInfo.id, deleteRowContentCallback, true);
        }
    };

    const deleteRowContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModalClicked(messageClassModal);
        else
            setMessageClassModal(messageClassModal);
    };

    const mainButtons = [
        {
            label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary',
            accessIds: isNewRow ? [UserClaimEnumerations.postApSpecificStaticPageRow] : [UserClaimEnumerations.editApSpecificStaticPageRow]
        },

        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(''), className: 'primary-outline' },
    ];

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
        setEditorValue("")
    }, [])

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguageRowContent([...newData]);
    }

    const editorHandler = (isMergeData, data, value, selectedLanguageId) => {
        if (isMergeData)
            !isEmpty(value) && stateHandler(data, value, selectedLanguageId);
        else
            setEditorValue(value);
    }

    const headerButtons = [
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deleteRowClicked, accessIds: [UserClaimEnumerations.deleteApSpecificStaticPageRow] }
    ]

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText(isNewRow ? 'adminPanel.common.newTitle' : 'adminPanel.common.editTitle')}
            showModal={showModal}
            noSpace={true}
            closeModal={closeModalClicked}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>
            {!isNewRow && <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            }
            <div className='p-3'>
                <ApLanguageTabs languageChanged={(selectedTab) => {
                    editorHandler(true, [...multiLanguageRowContent], editorValue, selectedLanguage.id)
                    languageChangedHandler(selectedTab)
                }}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguageRowContent) &&
                    <TabDataTables stateHandler={stateHandler}
                        editorHandler={editorHandler}
                        data={[...multiLanguageRowContent]}
                        key={selectedLanguage.id}
                        showValidationDate={showValidationDate}
                        selectedLanguage={selectedLanguage} />}
            </div>
            {showDeleteRowModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => deleteRow(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), '')}</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }
            {messageClassModal}
        </NsModal>
    );
}

function TabDataTables(props) {
    const { stateHandler, editorHandler, showValidationDate, data, selectedLanguage } = props;
    let rowContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);
    return (
        <div className='d-flex flex-column'>
            <div className="d-flex mb-4 align-items-end justify-content-between w-100 flew-row" >
                <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                    required
                    valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                    value={rowContent?.content.title || null}
                    showValidationDate={showValidationDate}
                    className='w-100' />
                <div className='ps-2 d-flex align-items-end'>
                    <NsSwitch label='adminPanel.slider.active'
                        checked={rowContent?.content.isActive || false}
                        disabled={!selectedLanguage.isDefault}
                        onChange={() => stateHandler(data, { isActive: !rowContent?.content.isActive }, selectedLanguage.id)} />
                </div>
            </div>
            <NsSwitch label='adminPanel.common.isTitleVisible'
                checked={rowContent?.content.isTitleVisible || false}
                disabled={!selectedLanguage.isDefault}
                onChange={() => stateHandler(data, { isTitleVisible: !rowContent?.content.isTitleVisible }, selectedLanguage.id)} />
            <NsEditor editorMode={Enumerations.editorMode.advance}
                initialValue={rowContent?.content.mainContent || null}
                selectedLanguage={props.selectedLanguage.id}
                changeHandler={(value) => {
                    editorHandler(false, data, { mainContent: value })
                }} />
        </div>
    )
}

export default React.memo(ApSinglePagesModal);