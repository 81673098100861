import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Links from '../../../../configs/links/AllLinks';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';

class SpBasesBanner extends Component {
    constructor(props) {
        super(props);
        this.isMobileDevice = CommonHelper.isMobileDevice();
        this.mobileDeviceInline = { 'd-inline text-center': this.isMobileDevice }
    }

    render() {
        const { className, title, text, hasIcon, iconClassName } = this.props;
        return (
            <Row className={clsx('d-flex justify-content-center align-items-center mx-lg-0 mx-md-0 mx-sm-3 mx-2', className)}>
                {this.isMobileDevice ?
                    <Col lg={10} md={10} sm={12} className='text-center font-size-24 px-4'>
                        <div className='fs-3 fw-bold text-white mb-3 d-inline'>{title}</div>
                        <Link className='text-decoration-none'
                            rel="nofollow"
                            to={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)}>
                            <div className='text-white d-inline'>{text}</div>
                            {hasIcon && <i className={clsx('white-color fs-5 ms-1', iconClassName)}></i>}
                        </Link>
                    </Col>
                    :
                    <Col lg={10} md={10} sm={12}>
                        <div className='fs-3 fw-bold text-white mb-3'>{title}</div>
                        <Link className='d-flex align-items-end text-decoration-none'
                            rel="nofollow"
                            to={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)}>
                            <div className='text-white'>{text}</div>
                            {hasIcon && <i className={clsx('white-color fs-5 ms-1', iconClassName)}></i>}
                        </Link>
                    </Col>
                }
            </Row >
        );
    }
}

export default SpBasesBanner;