import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { Row, Col, Nav } from 'react-bootstrap';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { getUrlWithCurrentCulture, getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Links from '../../../../../../configs/links/AllLinks';
import NsCollapse from '../../../../../../components/dropdowns/NsCollapse';
import NsButton from '../../../../../../components/buttons/NsButton';
import NsSearchInput from '../../../../../../components/inputs/NsSearchInput';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import LanguageSelection from '../../bases/LanguageSelection';
import NsCheckboxTreeView from '../../../../../../components/treeViews/NsCheckboxTreeView';
import CurrencySelection from '../../bases/CurrencySelection';

class SideMenu extends React.Component {
    constructor(props) {
        super(props);
        this.Transition = React.forwardRef(function Transition(props, ref) {
            return <Slide direction="right" ref={ref} {...props} />;
        });
        this.state = {
            searchText: '',
        };
    }

    closeSideMenu = () => CommonHelper.safeFunctionCall(this.props.closeModal);

    mapCategoryItems = () => {
        return this.props.categories.map((category, index) =>
            category.id !== 0 && <Nav.Item key={index} className='mt-1 py-1' data-dismiss="modal" onClick={this.closeSideMenu}>
                <Link to={category.path}>
                    {category.name}
                </Link>
            </Nav.Item>
        );
    }

    handleSearchTextChanged = searchText => {
        this.setState({ searchText });
    }

    searchClicked = () => {
        const { searchText } = this.state;
        if (!isEmpty(searchText))
            this.props.history.push(
                getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { searchInput: searchText }));
        this.setState({ searchText: '', });
        this.closeSideMenu();
    }

    navLinkItems = () => {
        return (
            <Nav className='d-flex flex-column'>
                {this.props.linkItems.map((item, index) => {
                    if (!item) return;
                    return <Nav.Item key={index}
                        className={`py-2 px-3 ${index % 2 !== 0 && 'background-white-color'}`} >
                        <Link to={!!item.isExternalLink ? { pathname: item.link } : item.link} target={!!item.isExternalLink ? "_blank" : "_self"}
                            onClick={item.showItem ? this.closeSideMenu : (event) => event.preventDefault()}
                            className={`primary-paragraph fw-bold text-decoration-none d-flex py-1 ${!item.showItem && 'text-muted'}`} >
                            {getTranslatedRawText(item.text)}
                        </Link>
                    </Nav.Item>
                })}
            </Nav>
        );
    }
    render() {
        const { showModal, username } = this.props
        const { searchText } = this.state;
        return (
            <Dialog className="side-menu"
                open={showModal}
                // TransitionComponent={this.Transition}
                keepMounted
                aria-labelledby="side-menu-title"
                onClose={this.closeSideMenu}>
                <DialogTitle id="side-menu-title" >
                    <Row className=" ps-1 pt-1">
                        <div className='d-flex justify-content-between w-100'>
                            <div className='d-flex gap-2'>
                                <LanguageSelection classname='black-color' />
                                {username && <CurrencySelection classname='black-color' />}

                            </div>
                            <button className="border-0 p-0 bg-transparent"
                                onClick={this.closeSideMenu} >
                                <i className="ns-icon-close h3" />
                            </button>
                        </div>
                        <div className='side-menu__search d-flex g-0 flex-grow-1'>
                            <NsSearchInput hasSearchIcon={false}
                                value={searchText}
                                handleSearchTextChanged={this.handleSearchTextChanged}
                                placeHolder={getTranslatedRawText('headers.middle.searchProduct')}
                                inputClassName='background-white-color font-size-16' />
                            <NsButton className="primary rounded-0 fw-bold pb-2"
                                label='product.common.search'
                                onClick={this.searchClicked} />
                        </div>
                    </Row>
                </DialogTitle>
                <DialogContent className="p-0">
                    <Row className="categories g-0 text-start py-2 rounded-none">
                        <NsCollapse title={getTranslatedRawText('headers.bottom.allProductsCategories')}
                            className="fw-bold font-size-14 px-3"
                            collapseClassName=" font-size-14 px-3"
                            titleClassName='white-color '>
                            <NsCheckboxTreeView items={this.props.categories.filter(item => item.id !== 0)} isMenu={true} className="mt-2 white-color" />
                        </NsCollapse>
                    </Row>
                    <Col className='background-color p-0'>
                        {this.navLinkItems()}
                    </Col>
                </DialogContent>
            </Dialog >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        categories: state.categories.categories,
    }
}

export default withRouter(connect(mapStateToProps)(SideMenu));