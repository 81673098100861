import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";

import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsNumberInput from "../../../../components/inputs/NsNumberInput";
import CommonHelper from "../../../../utilities/CommonHelper";
import { isAdmin } from "../../../../utilities/ClaimHelper";


const ShoppingBagMainResultCalculation = (props) => {

    const { calculationResult, isAuthenticated, printPriceChanged, makePricePerShoppingBag, rollWidthChanged, customPrice, customPricePerBag, customRollWidth } = props;
    const { makeAndPackageingInfo, openedDimension, paperInfo, paperWasteColor, paperWastePercentage, pricePerBag, totalPrice, errors } = calculationResult;
    const unavailaibleSize = (!errors.isWidthAvailable || !errors.isHeightAvailable);
    const rollWidths = ConstantsWithTranslation.getRollWidths();

    return <Row className="py-2 border-top my-2 g-3 font-size-14">
        {isAdmin() && <>
            <Col md={4} className="d-flex align-items-end">
                <NsNumberInput label={getTranslatedRawText('product.shoppingBag.printPrice')}
                    thousandSeparator={true}
                    hasDecimal={true}
                    value={customPrice}
                    valueChanged={printPriceChanged} />
                <span className={clsx("fw-bold ms-3 text-nowrap", !!paperInfo.customPrintPrice ? "text-decoration-line-through" : '')}>
                    {isAuthenticated
                        ? CommonHelper.currencyFormat(paperInfo.printPrice)
                        : CommonHelper.currencyFormatByDefaultConfig(paperInfo.printPrice)}
                </span>
            </Col>
            <Col md={4} className="d-flex align-items-end">
                <NsNumberInput label={getTranslatedRawText('product.shoppingBag.makePricePerShoppingBag')}
                    value={customPricePerBag}
                    thousandSeparator={true}
                    hasDecimal={true}
                    valueChanged={makePricePerShoppingBag} />
                <span className={clsx("fw-bold ms-3 text-nowrap", !!makeAndPackageingInfo.customMakePrice ? "text-decoration-line-through" : '')}>
                    {isAuthenticated
                        ? CommonHelper.currencyFormat(makeAndPackageingInfo.makePricePerBag)
                        : CommonHelper.currencyFormatByDefaultConfig(makeAndPackageingInfo.makePricePerBag)}</span>
            </Col>
            <Col md={3} className="d-flex align-items-end">
                <NsNumberInput label={getTranslatedRawText('product.shoppingBag.rollWidth')}
                    value={customRollWidth}
                    thousandSeparator={true}
                    items={rollWidths}
                    hasDecimal={true}
                    valueChanged={rollWidthChanged} />
                <span className={clsx("fw-bold ms-3", !!customRollWidth ? "text-decoration-line-through" : '')}>
                    {paperInfo.rollWidth}</span>
            </Col>
        </>
        }
        <Col md={6} className="align-self-center pt-2 pt-md-0">
            <div className="background-element-color p-3 rounded">
                {getTranslatedRawText('product.shoppingBag.price')}:&nbsp;
                <b className="red-color">
                    {unavailaibleSize && getTranslatedRawText('product.shoppingBag.unavailable')}
                    {unavailaibleSize && !isAdmin()
                        ? null
                        : isAuthenticated
                            ? CommonHelper.currencyFormat(totalPrice)
                            : CommonHelper.currencyFormatByDefaultConfig(totalPrice)
                    }
                </b>&nbsp;
                <span className="font-size-12">({getTranslatedRawText('profile.detail.exclusiveOfVAT')})</span>
            </div>
        </Col>
        <Col md={6} className="align-self-center">
            <div className="d-flex flex-column">
                <span>{getTranslatedRawText('product.shoppingBag.paperWasteWeight')}:&nbsp;
                    <b style={{ color: paperWasteColor }}>
                        {CommonHelper.currencyFormat(paperWastePercentage, true)}%
                    </b>
                </span>
                <span>{getTranslatedRawText('product.shoppingBag.pricePerShoppingbag')}:&nbsp;
                    <b>{(!errors.isWidthAvailable || !errors.isHeightAvailable) && !isAdmin()
                        ? getTranslatedRawText('product.shoppingBag.unavailable')
                        : isAuthenticated
                            ? CommonHelper.currencyFormat(pricePerBag)
                            : CommonHelper.currencyFormatByDefaultConfig(pricePerBag)}
                    </b>&nbsp;
                    <span className="font-size-12">({getTranslatedRawText('profile.detail.exclusiveOfVAT')})</span>
                </span>
            </div>
        </Col>
        {isAdmin() && <>
            <Col xs={{ span: 12, order: 'last' }} md={{ span: 6 }} >
                <div className="d-flex gap-3 mb-1">
                    <span className="me-1">{getTranslatedRawText('product.shoppingBag.openWidth')}:&nbsp;
                        <b className={errors.isWidthAvailable ? '' : 'red-color'}>
                            {CommonHelper.currencyFormat(openedDimension.width, true)}{getTranslatedRawText('product.shoppingBag.mm')}
                        </b>
                    </span>
                    <span>{getTranslatedRawText('product.shoppingBag.printSize')}:&nbsp;
                        <b>{paperInfo.paperName}</b>
                    </span>
                </div>
                <div className="d-flex gap-3 mb-1">
                    <span>{getTranslatedRawText('product.shoppingBag.openHeight')}:&nbsp;
                        <b className={errors.isHeightAvailable ? '' : 'red-color'}>
                            {CommonHelper.currencyFormat(openedDimension.height, true)}{getTranslatedRawText('product.shoppingBag.mm')}
                        </b>
                    </span>
                    <span>{getTranslatedRawText('product.shoppingBag.totalPaperWeight')}:&nbsp;
                        <b>{CommonHelper.currencyFormat(paperInfo.paperWeight, true)}{getTranslatedRawText('product.shoppingBag.kg')}</b>
                    </span>
                </div>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 6, order: 'last' }}>
                <div className="d-flex flex-column gap-1">
                    <span>{getTranslatedRawText('product.shoppingBag.makePrice')}:&nbsp;
                        <b>{isAuthenticated
                            ? CommonHelper.currencyFormat(makeAndPackageingInfo.customMakePrice || makeAndPackageingInfo.makePrice)
                            : CommonHelper.currencyFormatByDefaultConfig(makeAndPackageingInfo.customMakePrice || makeAndPackageingInfo.makePrice)}
                        </b>
                    </span>
                    <span>{getTranslatedRawText('product.shoppingBag.shrinkPrice')}:&nbsp;
                        <b>{isAuthenticated
                            ? CommonHelper.currencyFormat(makeAndPackageingInfo.shrinkPrice)
                            : CommonHelper.currencyFormatByDefaultConfig(makeAndPackageingInfo.shrinkPrice)}
                        </b>
                    </span>
                    <span>{getTranslatedRawText('product.shoppingBag.cartonPrice')}:&nbsp;
                        <b>{isAuthenticated
                            ? CommonHelper.currencyFormat(makeAndPackageingInfo.cartonPrice)
                            : CommonHelper.currencyFormatByDefaultConfig(makeAndPackageingInfo.cartonPrice)}
                        </b>
                    </span>
                </div>
            </Col>
        </>
        }
    </Row >
}

export default React.memo(ShoppingBagMainResultCalculation);