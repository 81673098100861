import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NsSetPageTitleAndDescription from '../../../../components/metaTags/NsSetPageTitleAndDescription';
import { breadcrumbsActionCreators } from '../../../../redux/reducers/BreadcrumbsReducer';
import { GetCmsBlogsFilterPostService, GetCmsNewsFilterPostService } from '../../../../services/singlePages/CmsService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import DateHelper from '../../../../utilities/DateHelper';
import UrlHelper from '../../../../utilities/UrlHelper';
import BlogNews from '../../BlogNews';
import BnBasesPosts from '../bases/BnBasesPosts';
import NsPageTitleBox from '../../../../components/labels/NsPageTitleBox';

class BnContentSearchResultPost extends React.Component {

    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.title = getTranslatedRawText(this.isBlog ? 'adminPanel.sidebar.blog' : 'adminPanel.sidebar.news');
        this.state = {
            urlParameters: UrlHelper.getRouteParameters(props.location.search),
            refreshDate: new Date(),
            category: {},
            posts: [],
            totalCount: 0,
            pageLength: 6,
            currentPageIndex: 1,
            isLoading: true,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        this.getPostsResult();
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        let urlParameters = UrlHelper.getRouteParameters(nextProps.location.search);
        if (urlParameters !== this.props.urlParameters)
            this.setState({ refreshDate: new Date(), urlParameters }, this.getPostsResult);
    }

    getPostsResult = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const { pageLength, currentPageIndex, urlParameters } = this.state;
        let submitInDate = new Date(urlParameters?.submitIn)
        submitInDate.setMonth(submitInDate.getMonth() + 1)
        let parameters = {
            pageLength,
            currentPageIndex,
            'Filter.Keyword': urlParameters?.searchInput,
            'Filter.CreateByUserId': urlParameters?.createdBy,
            'Filter.submitTimeFrom': urlParameters?.submitIn,
            'Filter.submitTimeTo': urlParameters?.submitIn ? DateHelper.convertDateTimeToDate(submitInDate) : '',
        };
        this.setState({ isLoading: true });
        this.isBlog
            ? GetCmsBlogsFilterPostService(parameters, this.getCmsFilterPostCallback)
            : GetCmsNewsFilterPostService(parameters, this.getCmsFilterPostCallback);
    }

    getCmsFilterPostCallback = (resultData, result, messageClassModal) => {
        const { currentPageIndex, pageLength } = this.state;
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            let totalCount = CommonHelper.getTotalPageCount(resultData.totalCount, pageLength);
            CommonHelper.redirectToCorrectPathIfInvalidPageNumber(currentPageIndex, totalCount, window.location.pathname, this.props);
            this.setState({ posts: resultData.dataItems, totalCount });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    };

    onPageChanged = (currentPageIndex) => this.setState({ currentPageIndex }, this.getPostsResult);

    render() {
        const { posts, totalCount, refreshDate, isLoading, currentPageIndex } = this.state;

        return <BlogNews urlParameters={this.state.urlParameters}>

            <NsPageTitleBox title={this.title}
                icon={this.isBlog ? 'ns-icon-blog h1' : 'ns-icon-news h1'} />
            <BnBasesPosts key={refreshDate}
                posts={posts}
                hasPagination={true}
                isLoading={isLoading}
                count={totalCount}
                onPageChanged={this.onPageChanged} />
            <NsSetPageTitleAndDescription pageTitle={this.isBlog ? 'adminPanel.sidebar.blog' : 'adminPanel.sidebar.news'}
                pageNumber={currentPageIndex} />
        </BlogNews>
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...breadcrumbsActionCreators }, dispatch);
};

export default connect(null, mapDispatchToProps)(BnContentSearchResultPost);