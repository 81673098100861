import clsx from 'clsx';
import React from 'react';
import { Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsTypography from '../../../../../components/text/NsTypography';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsImage from '../../../../../components/images/NsImage';
import Enumerations from '../../../../../configs/Enumerations';
import FestivalAwardModal from '../modals/FestivalAwardModal';
import UrlHelper from '../../../../../utilities/UrlHelper';

class FdPrizes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showWinnerModal: false,
            selectedAward: null
        }
    }

    showWinnerModal = (item) => {
        this.setState({
            showWinnerModal: !this.state.showWinnerModal,
            selectedAward: item
        });
    }

    onClose = () => this.setState({ showWinnerModal: false });

    mapPrizes = () => {
        return (
            !isEmpty(this.props.prizes) && this.props.prizes.map((item, index) =>
                <Col xs={12} md={6} lg={4} xl={3} key={index}>
                    <div className='festival__border-box p-0 red-color'>
                        <NsImage src={CommonHelper.createImageUrl(item.imageUrl)}
                            className='w-100 border-bottom border-danger'
                            imgAlt="components.imagesAlt.festivalPrize" />
                        <div className={clsx('d-flex flex-column justify-content-between border-top border-danger px-3 py-2', this.props.showFestivalResult ? "background-red-color white-color" : "background-white-color")}>
                            <div className='d-flex justify-content-between my-2'>
                                <NsTypography className='fw-bold font-size-18'>
                                    {item.name}
                                </NsTypography>
                                <div className='d-flex me-2 align-items-center'>
                                    <i className='ns-icon-award font-size-18'></i>
                                    <span className='ps-2 fw-bold'>{CommonHelper.stringFormat(getTranslatedRawText('festival.giftCount'), item.count)}</span>
                                </div>
                            </div>
                            {this.props.showFestivalResult && <div onClick={() => this.showWinnerModal(item)} className='p-1 cursor-pointer text-center background-white-color red-color fw-bold rounded'>{getTranslatedRawText("festival.viewWinner")}</div>}
                        </div>
                    </div>
                </Col>
            )
        )
    }

    render() {
        return (
            <>
                {this.mapPrizes()}
                {this.state.showWinnerModal && <FestivalAwardModal showWinnerModal={this.state.showWinnerModal}
                    selectedAward={this.state.selectedAward}
                    festivalId={UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id)}
                    onClose={this.onClose} />}
            </>)
    }
}

export default withRouter(FdPrizes);