import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { categoryActionCreators } from '../../../../redux/reducers/CategoryReducer';
import NsAutocomplete from '../../../../components/dropdowns/NsAutoComplete';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';

class PdpSidebarCategory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: [...this.props.categories],
            selectedCategory: this.props.categories[0]
        }
    }

    valueChanged = (selectedCategory) => {
        this.setState({ ...this.state, selectedCategory: !!selectedCategory ? selectedCategory : this.state.categories[0] },
            () => CommonHelper.safeFunctionCall(this.props.selectedCategoryChanged, selectedCategory))
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.categories !== this.props.categories)
            this.setState({
                categories: this.props.categories
            }, () => this.valueChanged(this.state?.categories[0]))
    }

    render() {
        return (
            <>
                {isEmpty(this.props.categories)
                    ? <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.text,
                        ]} />
                    : <NsAutocomplete label={getTranslatedRawText('professionalOrder.sidebar.productCategories')}
                        items={this.state.categories}
                        onChange={this.valueChanged}
                        value={this.state.selectedCategory}
                        className='w-100 mb-2' />}
            </>
        );
    }
}

const mapStateToProps = state => ({ categories: state.categories.categories })

const mapDispachToProps = (dispatch) => {
    return bindActionCreators({ ...categoryActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(PdpSidebarCategory);