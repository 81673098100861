import React from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Badge } from '@mui/material';

import '../../assets/sass/components/dateTimePickers/NsDateTimePickers.scss';
import { isEnCulture } from '../../utilities/CultureHelper';
import DateHelper from '../../utilities/DateHelper';

class NsStaticDatePicker extends React.Component {

    // const HighlightedDay = styled(PickersDay)(({ theme }) => ({
    //     "&.Mui-selected": {
    //       backgroundColor: theme.palette.primary.main,
    //       color: theme.palette.primary.contrastText,
    //     },
    //   }));

    myCustomDay = (prop) => {
        const { day, outsideCurrentMonth, ...other } = prop;
        const { normalBusinessDays } = this.props;
        let currentDate = DateHelper.getIsoDate(prop['data-timestamp']);
        const isSelected = !outsideCurrentMonth
            && normalBusinessDays.includes(currentDate);

        return (
            <Badge key={prop.day.toString()}
                overlap="circular"
                badgeContent={isSelected ?
                    <div className='d-flex flex-column h-100 mt-2 me-2'>
                        {normalBusinessDays.indexOf(currentDate) == 0 && <i className='ns-icon-save fs-6 mb-1' />}
                        {normalBusinessDays.indexOf(currentDate) == normalBusinessDays.length - 1 && <i className='ns-icon-circle-confirm success-color fs-6' />}
                    </div>
                    : undefined
                }
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}
                    className={isSelected
                        ? (normalBusinessDays.indexOf(currentDate) !== 0 || normalBusinessDays.length == 1)
                            ? 'static-date-picker__business-day'
                            : 'static-date-picker__before-business-day'
                        : ''
                    }
                />
            </Badge >
        );

        // return (
        //   <HighlightedDay
        //     {...other}
        //     outsideCurrentMonth={outsideCurrentMonth}
        //     day={day}
        //     selected={isSelected}
        //   />
        // );
    }

    render() {
        const { minDate } = this.props;

        return <LocalizationProvider dateAdapter={isEnCulture() ? AdapterDateFns : AdapterDateFnsJalali}>
            <StaticDatePicker
                className='static-date-picker'
                readOnly={true}
                disabled={true}
                value={new Date(minDate)}
                minDate={minDate}
                maxDate={minDate}
                views={['day']}
                slots={{
                    day: this.myCustomDay
                }}
                slotProps={{
                    actionBar: {
                        hidden: true
                    },
                    // day: { normalBusinessDays },
                    toolbar: {
                        hidden: true,
                    }
                }}
            />

        </LocalizationProvider >
    }
}

export default NsStaticDatePicker;