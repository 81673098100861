import React from 'react';

import UpBaseShippingReadyToDeliverOrdersGrid from '../bases/UpBaseShippingReadyToDeliverOrdersGrid';
import UpShippingRequestConfirmationModal from '../modals/UpShippingRequestConfirmationModal';
import NsHtmlToReact from '../../../../../components/htmlToReact/NsHtmlToReact';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsTypography from '../../../../../components/text/NsTypography';
import NsCheckbox from '../../../../../components/inputs/NsCheckbox';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import UpShippingRequestHeader from './UpShippingRequestHeader';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import NsTooltip from '../../../../../components/tooltips/NsTooltip';
import { GetShippingEstimatedTimeSheetService } from '../../../../../services/ProfileSetting/DeliveryAddressSettingService';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import DateHelper from '../../../../../utilities/DateHelper';
import NsButton from '../../../../../components/buttons/NsButton';

class UpShippingRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sheetOrderSelectedRowIds: [],
            orderProductType: props.orderProductType,
            storeSelectedRowIds: [],
            selectedAddress: null,
            confirmDisabled: false,
            createNewDeliveryRequestLoading: false,
            resultMessageClass: null,
            selectedShippingMethod: null,
            expressShippingAvailableMessage: null,
            showShippingTodayMessageOnCost: false,
            setExpressShippingDate: null,
            isAcceptExpressPolicy: false,
            showOrdersConfirmationModal: false,
            showDismatchedShippingTimesMessageModal: false,
            selectedShippingPlan: null,
            shippingTime: null,
            newShippingTime: null,
            isShippingAvailable: false,
            shipmentDescription: '',
            messageText: '',
        }
    }

    getShippingEstimatedTimeSheet = () => {
        this.setState({ createNewDeliveryRequestLoading: true });
        GetShippingEstimatedTimeSheetService({
            shippingPlanType: this.state.selectedShippingPlan?.key,
            shippingMethodCategoryType: this.state.selectedShippingMethod.shippingMethodCategoryTypeId
        }, this.getShippingEstimatedTimeSheetCallback);
    }

    getShippingEstimatedTimeSheetCallback = (resultData, result, resultMessageClassModal) => {
        this.setState({ createNewDeliveryRequestLoading: false });
        let shippingTime, errorMessageEstimatedTimeSheet = null;
        let hasTime = false;
        if (result.data.hasError) this.setState({ resultMessageClassModal });
        else {
            resultData.map(result => {
                hasTime = result.messageClass.messageItems.some(message => message.data == Enumerations.timeSheetDayType.shippingToShippingMethodDay);
                if (hasTime) shippingTime = result.dayDateTime;
            })
            if (shippingTime == this.state.shippingTime)
                this.setState({ showOrdersConfirmationModal: true });
            else
                this.setState({
                    showDismatchedShippingTimesMessageModal: true,
                    newShippingTime: shippingTime,
                    messageText: CommonHelper.stringFormat(getTranslatedRawText('profile.delivery.dismatchedShippingTimesMessage'),
                        this.state.selectedShippingMethod.name, DateHelper.getDateAndTime(this.state.shippingTime), DateHelper.getDateAndTime(shippingTime))
                })
        }
    }

    valueChanged = (value, valueName) => {
        let name = CommonHelper.getVariableName(valueName);
        this.setState({ [name]: value });
    }

    methodChanged = (selectedShippingMethod) => this.setState({
        selectedShippingMethod,
        confirmDisabled: false,
    });

    dataGridSelectionChanged = (selection) => {
        selection.rowIds = selection.rowIds.map(id => CommonHelper.toInt(id));
        if (this.state.orderProductType === Enumerations.orderProductType.store)
            this.setState({ storeSelectedRowIds: [...selection.rowIds] });
        else
            this.setState({ sheetOrderSelectedRowIds: [...selection.rowIds] });
    }

    closeDismatchedShippingTimesMessageModal = (confirm) => {
        this.setState({ showDismatchedShippingTimesMessageModal: false });
        confirm && this.setState({ showOrdersConfirmationModal: true, shippingTime: this.state.newShippingTime });
    }

    handleDisableSave = (boolean) => this.setState({ confirmDisabled: boolean });

    expressShippingClicked = () => this.setState({ setExpressShippingDate: new Date() });

    createExpressShippingAvailableMessage = (isStandardTab, isExpressTab, isShippingAvailable, isExpressShippingAvailable) => {
        const expressShippingAvailableMessage = isStandardTab && isExpressShippingAvailable
            ? <div className='d-flex my-2'>
                <i className='ns-icon-information fs-5 mx-1 text-color' />
                <div onClick={this.expressShippingClicked}>
                    <NsHtmlToReact>
                        {CommonHelper.stringFormat(getTranslatedRawText('profile.delivery.shippingGridExpressHint'),
                            `<span className='info-color cursor-pointer'>${getTranslatedRawText('profile.delivery.expressShipping')}</span>`)}
                    </NsHtmlToReact>
                </div>
            </div>
            : isExpressTab && isShippingAvailable
                ? <NsCheckbox label={getTranslatedRawText('profile.delivery.shippingGridExpressCheckbox')}
                    value={this.state.isAcceptExpressPolicy}
                    onChange={this.confirmExpressPolicy} />
                : '';
        this.setState({ expressShippingAvailableMessage, showShippingTodayMessageOnCost: isStandardTab && isExpressShippingAvailable });
    }

    toggleOrdersConfirmationModal = (isShippingRequestSuccessful, messsage) => {
        this.setState({
            showOrdersConfirmationModal: !this.state.showOrdersConfirmationModal,
            resultMessageClass: !!isShippingRequestSuccessful ? messsage : <></>,
        });
        if (isShippingRequestSuccessful)
            CommonHelper.safeFunctionCall(this.props.closeModal, isShippingRequestSuccessful, messsage);
    }

    getIsAvailabel = (isShippingAvailable) => this.setState({ isShippingAvailable });

    confirmExpressPolicy = (isAcceptExpressPolicy) => this.setState({ isAcceptExpressPolicy });

    render() {
        const { resultMessageClass, shipmentDescription, confirmDisabled, createNewDeliveryRequestLoading, selectedAddress, sheetOrderSelectedRowIds,
            storeSelectedRowIds, expressShippingAvailableMessage, selectedShippingMethod, shippingTime, isShippingAvailable,
            showOrdersConfirmationModal, setExpressShippingDate, showShippingTodayMessageOnCost, selectedShippingPlan,
            showDismatchedShippingTimesMessageModal, messageText } = this.state;
        const mainButtons = [
            {
                label: "profile.delivery.checkAndConfirm",
                icon: 'ns-icon-confirm',
                onClicked: this.getShippingEstimatedTimeSheet,
                className: 'primary',
                disabled: //confirmDisabled ||
                    isEmpty([...storeSelectedRowIds, ...sheetOrderSelectedRowIds]) ||
                    isEmpty(selectedShippingPlan) ||
                    isEmpty(shippingTime) ||
                    isEmpty(selectedShippingMethod)
            },
        ];
        return (
            <NsModal maxWidth="lg"
                dialogTitle={getTranslatedRawText("profile.readyToDeliver.addShippingRequest")}
                showModal={true}
                primaryActionButton={this.createNewDeliveryRequestClicked}
                closeModal={() => this.props.closeModal(false)}
                actions={<NsStickyButton stickyButtons={mainButtons} isLoading={createNewDeliveryRequestLoading}>
                    {CommonHelper.isMobileDevice() &&
                        <NsTypography variant='inherit' className='font-size-16 my-2'>
                            {!isEmpty(selectedShippingMethod?.name) && <div>
                                <NsHtmlToReact>
                                    {CommonHelper.stringFormat(getTranslatedRawText('adminPanel.shippings.estimatedSendingTimeTo'),
                                        `<span className='fw-bold'>${selectedShippingMethod?.name}</span>`)}
                                </NsHtmlToReact>
                            </div>}
                            <div className='d-flex justify-content-between mt-2 mb-3'>
                                {!isEmpty(shippingTime) && <div className='fw-bold font-size-18'>
                                    {DateHelper.getDateAndTime(shippingTime)}
                                </div>}
                                {showShippingTodayMessageOnCost &&
                                    <div className='fw-bold font-size-18'>
                                        <NsButton label='profile.delivery.shippingToday'
                                            startIcon='ns-icon-courier'
                                            size='medium'
                                            onClick={this.expressShippingClicked} />
                                    </div>}
                            </div>
                        </NsTypography>
                    }
                </NsStickyButton>}>

                <UpShippingRequestHeader
                    orderProductType={this.state.orderProductType}
                    handleDisableSave={this.handleDisableSave}
                    methodChanged={this.methodChanged}
                    setExpressShippingDate={setExpressShippingDate}
                    showShippingTodayMessageOnCost={showShippingTodayMessageOnCost}
                    expressShippingClicked={this.expressShippingClicked}
                    getIsAvailabel={this.getIsAvailabel}
                    createExpressShippingAvailableMessage={this.createExpressShippingAvailableMessage}
                    valueChanged={this.valueChanged} />

                {this.state.selectedShippingPlan?.key === Enumerations.shippingPlanType.standard &&
                    <NsTypography className='fw-bold mt-4 my-2'>
                        {getTranslatedRawText("profile.readyToDeliver.readyToDeliveryOrders")}
                    </NsTypography>}

                {this.state.selectedShippingPlan?.key === Enumerations.shippingPlanType.express &&
                    <div className='d-flex align-items-center gap-1 mt-4 mb-2'>
                        <NsTypography className='fw-bold'>
                            {getTranslatedRawText("profile.delivery.availableOrders")}
                        </NsTypography>
                        <NsTooltip title={getTranslatedRawText('profile.delivery.availableOrdersTooltip')}>
                            <i className='ns-icon-question fs-5' />
                        </NsTooltip>
                    </div>}

                {this.state.selectedShippingPlan?.key === Enumerations.shippingPlanType.byAgency &&
                    <NsTypography className='fw-bold mt-4 mb-2'>
                        {getTranslatedRawText("profile.delivery.ordersShippedByAgency")}
                    </NsTypography>}

                {isShippingAvailable && <UpBaseShippingReadyToDeliverOrdersGrid dataGridSelectionChanged={this.dataGridSelectionChanged}
                    orderProductType={this.state.orderProductType}
                    selectedShippingPlanId={selectedShippingPlan?.key}
                    hasCheckbox />
                }
                {expressShippingAvailableMessage}
                {showOrdersConfirmationModal &&
                    <UpShippingRequestConfirmationModal selectedAddress={selectedAddress}
                        selectedShippingPlan={selectedShippingPlan}
                        shippingTime={shippingTime}
                        selectedShippingMethod={selectedShippingMethod}
                        orderProductType={this.state.orderProductType}
                        storeSelectedRowIds={storeSelectedRowIds}
                        sheetOrderSelectedRowIds={sheetOrderSelectedRowIds}
                        shipmentDescription={shipmentDescription}
                        closeModal={this.toggleOrdersConfirmationModal}
                        showShippingTodayMessageOnCost={showShippingTodayMessageOnCost} />
                }

                {showDismatchedShippingTimesMessageModal &&
                    <NsMessageClassModal show={true}
                        onClose={(confirm) => this.closeDismatchedShippingTimesMessageModal(confirm)}
                        messageItems={[
                            {
                                messageText: messageText,
                                messageType: Enumerations.messageType.warning
                            }]} />
                }

                {resultMessageClass}
            </NsModal >
        )
    }
}

export default UpShippingRequest;