import React from 'react';
import clsx from 'clsx';
import NsCollapse from '../../../components/dropdowns/NsCollapse';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import NsButton from '../../../components/buttons/NsButton';

class OBaseWrapper extends React.Component {

    render() {
        const { title, defaultIsOpen, showNextButton, children } = this.props;
        return <div className={clsx('d-flex flex-column wrapper-white p-3 mb-md-0', this.props.className)}>
            {CommonHelper.isMobileDevice()
                ? <NsCollapse title={getTranslatedRawText(title ?? '')}
                    defaultIsOpen={!!defaultIsOpen}
                    className="fw-bold d-md-none font-size-14"
                    collapseClassName="mb-2"
                    titleClassName='black-color '>
                    {children}
                    {showNextButton &&
                        <div className='d-flex mt-2 justify-content-end'>
                            <NsButton label='common.next'
                                className='secondary' />
                        </div>
                    }
                </NsCollapse>
                : children

            }
        </div>
    }
}

export default OBaseWrapper;