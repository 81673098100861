import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GetPaymentDetailsService, GetPaymentReportService } from '../../../../services/payments/PaymentService';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import { miniCartActionCreators } from '../../../../redux/reducers/MiniCartReducer';
import NsDownloadFile from '../../../../components/downloadFiles/NsDownloadFile';
import { purchaseEvent } from '../../../../utilities/GoogleTagManagerHelper';
import '../../../../assets/sass/views/profileSettings/ProfileSetting.scss';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../components/text/NsTypography';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsDataGrid from '../../../../components/grids/NsDataGrid';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import NsCard from '../../../../components/cards/NsCard';
import Links from '../../../../configs/links/AllLinks';
import UrlHelper from '../../../../utilities/UrlHelper';
import clsx from 'clsx';


class UpPaymentsIpgResult extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            CommonHelper.createStickyButtonItem("payment.ipg.backToCart", CommonHelper.getBackIconByCulture() + ' pe-1', this.backClicked, 'primary-outline'),
            CommonHelper.createStickyButtonItem("payment.ipg.print", 'ns-icon-print pe-1', this.printReportClicked, 'secondary-outline'),
        ];
        this.createSkeletonArray = [
            { variant: Enumerations.skeleton.circle, className: 'ipg-skeleton__icon' },
            { variant: Enumerations.skeleton.rect, className: 'ipg-skeleton__icon-text' },
            { variant: Enumerations.skeleton.rect, className: 'ipg-skeleton__grid' }
        ];
        this.columns = [
            {
                field: 'paymentDate',
                headerName: getTranslatedRawText('profile.dataGridColumns.datePayment'),
                width: 200,
                dataType: Enumerations.gridType.dateTime,
            },
            {
                field: 'amount',
                headerName: getTranslatedRawText('profile.dataGridColumns.amount'),
                width: 250,
                dataType: Enumerations.gridType.price,
            },
            {
                field: 'paymentGatewayName',
                headerName: getTranslatedRawText('profile.dataGridColumns.paymentGateway'),
                width: 200,
            },
            {
                field: 'trackingNo',
                headerName: getTranslatedRawText('profile.dataGridColumns.trackingNo'),
                width: 200,
                dataType: Enumerations.gridType.number,
            },
            {
                field: 'referenceNo',
                headerName: getTranslatedRawText('profile.dataGridColumns.referenceNo'),
                width: 200,
                dataType: Enumerations.gridType.number,
            },
        ];
        this.state = {
            rows: [],
            file: null,
            isLoading: true,
            paymentStateType: null,
            isLoadingButtons: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.getPaymentDetails();

    getPaymentDetails = () => {
        const URLParameters = UrlHelper.getRouteParameters(this.props.location.search);
        GetPaymentDetailsService(URLParameters, this.getPaymentDetailsCallback);
    }

    getPaymentDetailsCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.hasError && !!resultData) {
            this.setState({
                rows: [resultData],
                paymentStateType: resultData.paymentStateType
            }, () => {
                this.props.getTotalPriceAndCount()
                purchaseEvent(resultData)
            });
        }
        else
            this.setState({ resultMessageClass });
    }

    backClicked = () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder));

    orderAnotherProductClicked = () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts));

    printReportClicked = () => {
        this.setState({ isLoadingButtons: true });
        GetPaymentReportService({ id: this.state.rows[0]?.id }, this.getPaymentReportCallback);
    }

    getPaymentReportCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoadingButtons: false });
        if (!result.hasError && !!resultData) {
            const href = !isEmpty(resultData) && CommonHelper.createDownloadFileUrl(resultData?.fileDownloadName, resultData?.fileContents);
            this.setState({ file: { fileContents: href } });
        } else
            this.setState({ resultMessageClass });
    }

    getPaymentState = () => {
        switch (this.state.paymentStateType) {
            case Enumerations.paymentStateType.success:
                return { icon: 'ns-icon-circle-confirm success-color', label: 'payment.ipg.successMessage', className: 'success-color' }
            case Enumerations.paymentStateType.failed:
                return { icon: 'ns-icon-critical red-color', label: 'payment.ipg.errorMessage', className: 'red-color' }
            case Enumerations.paymentStateType.open:
                return { icon: 'ns-icon-post-on warning-color', label: 'payment.ipg.warningMessage', className: 'warning-color' }
            default: return {};
        }
    }

    render() {
        const { rows, file, isLoading, isLoadingButtons, resultMessageClass } = this.state;
        const paymentState = this.getPaymentState();
        return (
            <NsCard className='profile-setting p-3 text-center' >
                {isLoading ?
                    this.createSkeletonArray.map((item, index) =>
                        <NsSkeleton key={index} isVisible={true}
                            className={`${item.className} mx-auto mb-3`}
                            variant={[item.variant]} />)
                    :
                    <>
                        <div className='text-center mb-4'>
                            <i className={clsx(paymentState?.icon, 'big-icon-size')} />
                            <NsTypography className={clsx(paymentState?.className, 'fw-bold px-1')}>
                                {!!paymentState?.label && getTranslatedRawText(paymentState.label)}
                            </NsTypography>
                        </div>
                        <div className='col-xl-10 mx-auto mb-3'>
                            <NsDataGrid isLoading={isLoading}
                                columnDefs={this.columns}
                                rows={rows}
                                refreshButtonClicked={this.getPaymentDetails}
                                totalRows={rows.length} />
                        </div>

                        <NsDownloadFile file={file} />

                        <NsStickyButton stickyButtons={this.mainButtons}
                            isLoading={isLoadingButtons}
                            buttonsContainerClassName='justify-content-center' />
                    </>
                }
                {resultMessageClass}
            </NsCard >
        );
    }
}
const mapStateToProps = (state) => { return { miniCartInfo: state.miniCartInfo.miniCartInfo, }; }

const mapDispachToProps = (dispatch) => { return bindActionCreators({ ...miniCartActionCreators }, dispatch) }

export default connect(mapStateToProps, mapDispachToProps)(UpPaymentsIpgResult);