import React from 'react';
import { withRouter } from 'react-router';

import { GetInTransitExcelReportService, GetInTransitOrdersService, GetInWarehouseExcelReportService, GetInWarehouseOrdersService, GetTransferToWarehouseExcelReportService, GetTransferToWarehouseOrdersService } from '../../../../../../services/ProfileSetting/UserOrderService';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../../utilities/CultureHelper';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import UpBaseGroupSheetMobileRedirectToAllOrders from '../bases/UpBaseGroupSheetMobileRedirectToAllOrders';
import NsDownloadFile from '../../../../../../components/downloadFiles/NsDownloadFile';
import { createGroupSheetOrderColumn } from '../bases/UpBaseGroupSheetOrderHelper';
import NsStickyButton from '../../../../../../components/buttons/NsStickyButton';
import UpGroupSheetReadyToDeliverOrderDetailModal from './UpGroupSheetReadyToDeliverOrderDetailModal';
import UpShippingRequest from '../../../userShippings/shippingRequests/UpShippingRequest';
import UpBaseGroupSheetOrdersSearchSection from '../bases/UpBaseGroupSheetOrdersSearchSection';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../../../UserPanelWrapper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../../configs/Enumerations';
import UrlHelper from '../../../../../../utilities/UrlHelper';
import Links from '../../../../../../configs/links/AllLinks';
import NsTab from '../../../../../../components/tabs/NsTab';
import NsExportExcelReportButton from '../../../../../../components/downloadFiles/NsExportExcelReportButton';

class ReadyToDeliverOrdersContent extends React.Component {

    constructor(props) {
        super(props);
        const { transferToWarehouse, inTheWarehouse, inTransit } = Enumerations.SharedSheetOrderReadyToDeliverStateType;
        this.memoizeCreateStickyItem = CommonHelper.memoizeFunc(CommonHelper.createStickyButtonItem);
        this.columns = createGroupSheetOrderColumn(true, true, false, false, this.detailViewClicked, null, null, true);
        this.tabs = ConstantsWithTranslation.getUserOrderReadyToDeliverTabs(true);
        this.selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);

        this.selectedTabGridService = (tab) => tab?.key === transferToWarehouse ? GetTransferToWarehouseOrdersService
            : tab?.key === inTheWarehouse ? GetInWarehouseOrdersService : GetInTransitOrdersService;
        this.selectedTabExcelService = (tab) => tab?.key === transferToWarehouse ? GetTransferToWarehouseExcelReportService
            : tab?.key === inTheWarehouse ? GetInWarehouseExcelReportService : GetInTransitExcelReportService,

            this.state = {
                gridService: this.selectedTabGridService(this.selectedTab),
                excelService: this.selectedTabExcelService(this.selectedTab),
                selectedTab: this.selectedTab || this.tabs[1],
                selectedTabIndex: this.tabs.indexOf(this.selectedTab) > -1 ? this.tabs.indexOf(this.selectedTab) : 1,
                hasDeliveryRequest: true,
                hasPagination: false,
                file: null,
                showAddShippmentRequestModal: false,
                showDetailModal: false,
                callServiceDate: null,
                orderId: null,
                isLoading: false,
                rows: [],
                selectedRow: null,
                searchValues: null,
                openFeedbackModalDate: null,
                resultMessageClass: (<></>),
            };
    }

    componentDidMount = () => {
        let pathparams = UrlHelper.checkUrlIsOrderDetailFeedback(window.location.pathname);
        if (pathparams.isOrderDetail) {
            this.detailViewClicked(pathparams.orderId);
            if (pathparams.isOrderDetailFeedback)
                this.setState({ openFeedbackModalDate: new Date() });
        }
    }

    detailViewClicked = (id) => {
        this.setState({ showDetailModal: true, orderId: id, selectedRow: this.state.rows.filter(item => item.id === id) });
        !!this.props.history && this.props.history.push(CommonHelper.stringFormat(
            getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.readyToDeliverOrderDetail), id));
    }

    closeDetailModalClicked = () => {
        this.setState({ showDetailModal: false, openFeedbackModalDate: null });
        !!this.props.history && this.props.history.push(this.state.selectedTab?.url);
    }

    tabChanged = (newValue, selectedTab) => {
        const { transferToWarehouse, inTheWarehouse, inTransit } = Enumerations.SharedSheetOrderReadyToDeliverStateType;

        newValue !== this.state.selectedTabIndex &&
            this.setState({
                selectedTabIndex: newValue,
                selectedTab,
                gridService: selectedTab?.key === transferToWarehouse ? GetTransferToWarehouseOrdersService
                    : selectedTab?.key === inTheWarehouse ? GetInWarehouseOrdersService : GetInTransitOrdersService,
                excelService: selectedTab?.key === transferToWarehouse ? GetTransferToWarehouseExcelReportService
                    : selectedTab?.key === inTheWarehouse ? GetInWarehouseExcelReportService : GetInTransitExcelReportService,
                hasPagination: selectedTab?.key === transferToWarehouse || selectedTab?.key === inTransit,
                hasDeliveryRequest: selectedTab?.key === inTheWarehouse,
                isLoading: true,
            }, () => !!this.props.history && this.props.history.push(selectedTab.url))
    }

    getData = (rows, isLoading) => this.setState({ rows, isLoading });

    addShippmentRequestModalClicked = () => this.setState({ showAddShippmentRequestModal: true });

    closeAddShippmentRequestModalClicked = (isOperationSuccessful, resultMessageClass) =>
        this.setState({ showAddShippmentRequestModal: false, callServiceDate: new Date(), resultMessageClass: isOperationSuccessful ? resultMessageClass : '' });

    searchFormClicked = (searchValues) => this.setState({ searchValues, isLoading: true, callServiceDate: new Date() });

    createButtons = (hasDeliveryRequest) => [
        hasDeliveryRequest && this.memoizeCreateStickyItem("profile.readyToDeliver.addShippingRequest", 'ns-icon-add', this.addShippmentRequestModalClicked, 'primary'),
    ];

    render() {
        const { showAddShippmentRequestModal, callServiceDate, hasDeliveryRequest, hasPagination, searchValues, gridService, excelService,
            isLoading, openFeedbackModalDate, selectedTab, selectedTabIndex, showDetailModal } = this.state;
        const { inTheWarehouse } = Enumerations.SharedSheetOrderReadyToDeliverStateType;

        const serviceParameters = {
            ...searchValues,
            sharedSheetOrderStateTypes: [selectedTab?.id],
            orderProductType: Enumerations.orderProductType.sharedSheet
        };

        return (
            <>
                <div className="w-100">
                    <UpBaseGroupSheetOrdersSearchSection
                        searchFormClicked={this.searchFormClicked}
                        findOrdersSearchSection
                        autoFocus
                        isLoading={isLoading}>

                        <NsExportExcelReportButton searchValues={serviceParameters}
                            isLoading={isLoading}
                            service={excelService} />
                    </UpBaseGroupSheetOrdersSearchSection>

                    <NsStickyButton stickyButtons={hasDeliveryRequest ? this.createButtons(hasDeliveryRequest) : [{}]} isLoading={isLoading} />

                    <NsTab value={selectedTabIndex}
                        tabs={this.tabs}
                        onChange={this.tabChanged} />

                    <NsDataGridWithServiceCall columnDefs={this.columns}
                        hasPagination={hasPagination}
                        service={gridService}
                        parameters={selectedTab?.key === inTheWarehouse
                            ? serviceParameters
                            : { filter: serviceParameters }}
                        callServiceDate={callServiceDate}
                        gridId={GridEnumerations.siteGrids.inWarehouseGroupSheetOrders}
                        hasCustomColumnOrder={true}
                        getData={this.getData} />

                    <UpBaseGroupSheetMobileRedirectToAllOrders />
                </div>

                {showAddShippmentRequestModal &&
                    <UpShippingRequest orderProductType={Enumerations.orderProductType.sharedSheet}
                        closeModal={this.closeAddShippmentRequestModalClicked} />
                }

                {showDetailModal &&
                    <UpGroupSheetReadyToDeliverOrderDetailModal closeModal={this.closeDetailModalClicked}
                        hasTransferRequest={this.state.selectedRow?.hasTransferRequest}
                        openFeedbackModalDate={openFeedbackModalDate}
                        breadcrumbs={this.props.breadCrumb}
                        orderId={this.state.orderId} />
                }

                <NsDownloadFile file={this.state.file} />

                {this.state.resultMessageClass}
            </>
        )
    }
}

export { ReadyToDeliverOrdersContent };

const UpGroupSheetReadyToDeliverOrders = (props) => {
    return (
        <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.readyToDeliver')}
            titleIcon='ns-icon-ready-order' >
            <ReadyToDeliverOrdersContent {...props} />
        </UserPanelWrapper >
    );
}

export default withRouter(UpGroupSheetReadyToDeliverOrders);