import React, { Component } from "react";

import { GetProductCategoryInfoService } from "../../../services/product/ProductService";
import { isBusinessPartnerRoute } from "../../../utilities/BusinessPartnerHelper";
import ProductCategoryInfo from "./ProductCategoryInfo";
import NsCard from "../../../components/cards/NsCard";
import ContentPagination from './ContentPagination'
import ContentCardBody from "./ContentCardBody";
import ContentListBody from "./ContentListBody";
import ContentHeader from "./ContentHeader";

class ContentMain extends Component {

    render() {
        const { pageNumber, totalPageCount, filters, productsFilterLabel, products, categories, isLoading, isAuthenticated, selectedCategories, supplierOrderCartDate, isStoreProducts } = this.props;
        return (
            <div className='content'>
                <ContentHeader filters={filters}
                    products={products}
                    productsFilterLabel={productsFilterLabel}
                    sortFilter={this.props.sortFilter}
                    isStoreProducts={isStoreProducts}
                    submitMobileFilters={this.props.submitMobileFilters}
                    removeAllFilters={this.props.removeAllFilters} />

                {/* {isBusinessPartnerRoute() */}
                {false
                    ? <NsCard className="mt-2 p-2">
                        <ContentListBody products={products}
                            isAuthenticated={isAuthenticated}
                            isLoading={isLoading}
                            categories={categories} />
                    </NsCard>
                    : <>
                        <ContentCardBody products={products}
                            isStoreProducts={isStoreProducts}
                            isAuthenticated={isAuthenticated}
                            supplierOrderCartDate={supplierOrderCartDate}
                            isLoading={isLoading} />
                        <ContentPagination pageNumber={pageNumber}
                            totalPageCount={totalPageCount}
                            handleCurrentPage={this.props.handleCurrentPage} />
                        {selectedCategories.length > 0 &&
                            <ProductCategoryInfo productsFilterLabel={productsFilterLabel}
                                wrapperClassName='px-4 py-3 mt-2'
                                service={GetProductCategoryInfoService}
                                selectedCategories={selectedCategories}
                            />}
                    </>
                }
            </div>
        );
    }
}

export default ContentMain;