import React from "react";

import { getTranslatedRawText } from "../../../../../../utilities/CultureHelper";
import NsModal from "../../../../../../components/modals/NsModal";
import { ApShippingContent } from "../../list/ApShipping";

const ApBaseShippingsInModal = (props) => {
    return (
        <NsModal maxWidth="lg"
            dialogTitle={getTranslatedRawText('adminPanel.shippings.shippingRequest')}
            showModal={true}
            noSpace={true}
            closeModal={props.closeModal} >
            <ApShippingContent {...props} />
        </NsModal>
    );
}

export default React.memo(ApBaseShippingsInModal);