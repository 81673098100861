import React from 'react';

import NsButton from '../../../../../components/buttons/NsButton';
import OrderHelper from '../../../../../utilities/OrderHelper';
import Enumerations from '../../../../../configs/Enumerations';
import ExtendedServiceWrapper from './ExtendedServiceWrapper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import NsTour from '../../../../../components/tour/NsTour';
import BillBookModal from '../../../modals/billBook/BillBookModal';
import EnumerationTranslationHelper from '../../../../../utilities/EnumerationTranslationHelper';
import BillBookPreviewModal from '../../../modals/billBookPreview/BillBookPreviewModal';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import NsSelect from '../../../../../components/dropdowns/NsSelect';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';

class ExtendedServiceBillBook extends React.Component {

    constructor(props) {
        super(props);
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = {
            tourSteps: RegularOrderTours.getBillBookOptionButtonTour(),
            showTour: !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookOptionButton),
            tourId: Enumerations.tourGuide.billBookOptionButton,
            selectedItem: [],
            billBookProperty: null,
            showBillBookModal: false,
            showPreviewBillBookModal: false,
            refreshExtendedServiceSummaryDate: null,
        };
    }

    componentDidMount = () => {
        OrderHelper.getMasterFilesWithFullInfo(this.routeId, (masterFiles) => {
            let service = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.billBook)
            this.setState({
                selectedItem: service?.selectedItem ?? {},
                billBookProperty: service?.billBookProperty,
                masterFile: masterFiles[0].frontSideFileInfo,
            });
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.selectedItem.billBookCopy !== this.state.selectedItem.billBookCopy) {
            !isEmpty(this.state.selectedItem.billBookCopy) && this.setState({
                showTour: !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookPreviewButton),
                tourSteps: RegularOrderTours.getBillBookPreviewButtonTour(),
                tourId: Enumerations.tourGuide.billBookPreviewButton,
            })
        }
    }

    billBookModalClicked = () => this.setState({ showBillBookModal: true });

    previewBillBookModalClicked = () => this.setState({ showPreviewBillBookModal: true });

    closeBillBookModalClicked = () => this.setState({ showBillBookModal: false });

    closePreviewBillBookModalClicked = () => this.setState({ showPreviewBillBookModal: false });

    numberingTypeChanged = (value, isValid) => this.setState({ billBookNumberInSeriesType: value });

    saveBillBookModalClicked = (selectedItem) => {
        this.setState({ selectedItem, showBillBookModal: false }, this.updateExtendedService);
    }

    updateExtendedService = () => {
        const { selectedItem } = this.state;
        OrderHelper.updateExtendedService(this.routeId, Enumerations.extendedServices.billBook,
            { ...selectedItem });
        this.setState({ refreshExtendedServiceSummaryDate: new Date() });
    }

    render() {
        const { masterFile, selectedItem, showBillBookModal, showPreviewBillBookModal, refreshExtendedServiceSummaryDate
            , billBookProperty, showTour, tourId, tourSteps } = this.state;
        const { billBookCopy, billBookPrintColor, billBookBindingType, billBookBindingSideType, serialStartFrom,
            hasUnderhand, billBookNumberInSeriesType } = selectedItem;
        const serialEndTo = OrderHelper.calculateSerialEndTo(serialStartFrom, billBookNumberInSeriesType, this.routeId);
        const series = OrderHelper.getSeries(this.routeId);

        return (
            <ExtendedServiceWrapper otherProps={this.props}
                routeId={this.routeId}
                isSelectedRequirements={!!billBookCopy && !!billBookPrintColor && !!billBookBindingType}
                refreshExtendedServiceSummaryDate={refreshExtendedServiceSummaryDate}
                currentExtendedService={Enumerations.extendedServices.billBook}>

                <ExtendedServiceBillBookContent billBookCopy={billBookCopy}
                    billBookPrintColor={billBookPrintColor}
                    billBookBindingType={billBookBindingType}
                    serialStartFrom={serialStartFrom}
                    serialEndTo={serialEndTo}
                    series={series}
                    numberingTypeChanged={this.numberingTypeChanged}
                    billBookNumberInSeriesType={billBookNumberInSeriesType}
                    billBookModalClicked={this.billBookModalClicked}
                    previewBillBookModalClicked={this.previewBillBookModalClicked} />

                {showBillBookModal &&
                    <BillBookModal showModal={true}
                        routeId={this.routeId}
                        selectedItem={selectedItem}
                        masterFile={masterFile}
                        billBookProperty={billBookProperty}
                        saveModalClicked={this.saveBillBookModalClicked}
                        closeModal={this.closeBillBookModalClicked} />
                }

                {showPreviewBillBookModal &&
                    <BillBookPreviewModal masterFile={masterFile}
                        billBookCopy={billBookCopy}
                        billBookPrintColor={billBookPrintColor?.billBookPrintColorType}
                        billBookBindingSideType={billBookBindingSideType}
                        billBookBindingType={billBookBindingType}
                        hasUnderhand={hasUnderhand}
                        serialStartFrom={serialStartFrom}
                        trimZone={OrderHelper.getTrimZone(this.routeId)}
                        serialBoxDimension={billBookProperty?.serialBoxDimension}
                        selectedSerials={selectedItem?.selectedSerials}
                        selectedPerforations={selectedItem?.selectedPerforations}
                        closePreviewBillBookModalClicked={this.closePreviewBillBookModalClicked} />
                }

                {showTour && <NsTour key={tourId} steps={tourSteps} id={tourId} />}

            </ExtendedServiceWrapper >
        );
    }
}

export function ExtendedServiceBillBookContent(props) {
    const { billBookCopy, billBookPrintColor, billBookBindingType, serialStartFrom, serialEndTo, billBookNumberInSeriesType, series } = props;
    return <div className="d-flex flex-column justify-content-start g-2 p-3">

        <div className='d-flex gap-2 mb-3'>
            <NsButton startIcon='ns-icon-product-information'
                size='small'
                label='regularOrder.billBook.billBookOptions'
                onClick={props.billBookModalClicked}
                className='secondary extended-service__tour__bill-book__option-button' />
            <NsButton startIcon='ns-icon-fit'
                size='small'
                label='regularOrder.billBook.previewBillBook'
                onClick={props.previewBillBookModalClicked}
                disabled={isEmpty(billBookCopy)}
                className='secondary extended-service__tour__bill-book__preview-button' />
        </div>
        {billBookCopy && billBookPrintColor && billBookBindingType &&
            <div className='d-flex font-size-14  mb-2'>
                {EnumerationTranslationHelper.getTranslateBookNumberOfCopyType(billBookCopy?.billBookCopyType)?.label}&nbsp;-&nbsp;
                {EnumerationTranslationHelper.getTranslateBookNumberOfPrintColorType(billBookPrintColor?.billBookPrintColorType)}&nbsp;-&nbsp;
                {EnumerationTranslationHelper.getTranslateBookBindingType(billBookBindingType)?.label}
            </div>
        }
        {series > 1 && !!serialStartFrom && false &&
            <div className='mb-3 w-75'>
                <NsSelect label={getTranslatedRawText('regularOrder.billBook.nextSeriesMode')}
                    valueChanged={props.numberingTypeChanged}
                    defaultOpen={true}
                    options={ConstantsWithTranslation.getBillBookNumberInSeriesTypes()}
                    selectedItem={billBookNumberInSeriesType} />
            </div>
        }
        {!!serialStartFrom &&
            <div className='font-size-14'>
                {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.billBook.serialNumberStartedFromTo'), serialStartFrom, serialEndTo)}
            </div>
        }
    </div>
}

export default ExtendedServiceBillBook;