import clsx from 'clsx';
import React, { Component } from 'react';

import '../../../../assets/sass/views/singlePages/bases/card/SpBasesFeatureCard.scss';
import NsTypography from '../../../../components/text/NsTypography'

class SpBasesFeatureCard extends Component {
    render() {
        const { item } = this.props;
        return (
            <div className='ns-feature-card d-flex flex-column justify-content-center align-items-center cursor-pointer' onClick={() => item.onClick(item?.id)}>
                <div className='d-flex justify-content-center'>
                    <div className='bg-white rounded-circle img-background d-flex justify-content-center p-5 wrapper-white align-items-center'>
                        <img src={item?.img} />
                    </div>
                </div>
                <div className='title-color d-flex flex-column justify-content-center align-items-center mt-2'>
                    <NsTypography varient='inherit' className='fw-bold font-size-20'>
                        {item?.title}
                    </NsTypography>
                    <NsTypography variant='inherit' className='font-size-14 text-center'>
                        {item?.text}
                    </NsTypography>
                </div>
                <div className='d-flex mt-3 text-decoration-none'>
                    <div className='font-size-14 fw-bold red-color me-2'>{item?.button}</div>
                    <i className={clsx(item?.icon, 'red-color fs-4')}></i>
                </div>
            </div>
        )
    }
}

export default SpBasesFeatureCard;