import React from 'react';

import { GetQueueOfOrdersService } from '../../../../../services/ProfileSetting/UserOrderService';
import UpBaseQueueOrdersContent from './bases/UpBaseQueueOrdersContent';

const UpQueueOrdersGroupSheet = (props) => {

    return (
        <UpBaseQueueOrdersContent service={GetQueueOfOrdersService} {...props} />
    );
};

export default React.memo(UpQueueOrdersGroupSheet);