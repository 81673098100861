import React from 'react';
import { Skeleton } from '@mui/material';
import Enumerations from '../../configs/Enumerations';

class NsSkeleton extends React.Component {

    variantIdentifying = (variant, index) => {
        const { className } = this.props;
        let variantMode = "";
        switch (variant) {
            case Enumerations.skeleton.text:
                variantMode = "text";
                break;
            case Enumerations.skeleton.rect:
                variantMode = 'rect';
                break;
            case Enumerations.skeleton.circle:
                variantMode = 'circle';
                break;
            default:
                return;
        };
        return (
            <Skeleton animation='pulse'
                key={index}
                variant={variantMode}
                className={className} />
        );
    }

    render() {
        const { isVisible, variant } = this.props;
        if (isVisible)
            return variant.map((item, index) => this.variantIdentifying(item, index));
        return null;
    }
}

export default NsSkeleton;