import React, { useEffect } from 'react';
import ImageGallery from 'react-image-gallery';

import { isEmpty } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';

export default function NsImageGallery(props) {

    const { selectedImage, images, isLocal } = props;
    const renderedImages = [];

    const renderImagePusher = (fileUrl) => {
        if (isEmpty(fileUrl)) return;
        let imgFile = isLocal ? fileUrl : CommonHelper.createImageUrl(fileUrl);
        let newObj = {
            original: imgFile,
            thumbnail: imgFile,
        }
        renderedImages.push(newObj);
    }

    useEffect(() => {
        renderImagePusher(selectedImage.fileUrl);
        if (renderedImages.length < 0) return;
        if (!isEmpty(images)) {
            const filterImage = images.filter((img) => img.fileUrl !== selectedImage?.fileUrl)
            filterImage?.map((img) => {
                renderImagePusher(img?.fileUrl);
            })
        }
    }, [selectedImage])

    return (
        <ImageGallery items={renderedImages} />
    )
}
