import React, { useEffect, useState } from 'react';

import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { GetAgenciesService } from '../../../../services/adminPanel/AdminAgenciesService';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import AgencyInfoModal from '../../../profileSettings/modals/AgencyInfoModal';
import ApAgenciesSearch from './ApAgenciesSearch';
import Enumerations from '../../../../configs/Enumerations';

const ApAgencies = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [searchData, setSearchData] = useState(null);
    const [selectedAgencyId, setSelectedAgencyId] = useState(null);
    const [showAgencyModal, setShowAgencyModal] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);

    const columns = [
        hasAccess([UserClaimEnumerations.getApAgencyById]) &&
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => detailViewClicked(id),
            }
        },
        {
            field: 'name',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.agencyName'),
            width: 200
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 100,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'state',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.state'),
            width: 150,
        },
        {
            field: 'city',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.city'),
            width: 150,
        },
        {
            field: 'phoneNumber',
            headerName: getTranslatedRawText('adminPanel.communicationChannel.phoneNumber'),
            dataType: Enumerations.gridType.number,
            width: 200,
        },
        {
            field: 'mobileNumber',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
            dataType: Enumerations.gridType.number,
            width: 180,
        },
        {
            field: 'companyPriceChangePercent',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceChange'),
            dataType: Enumerations.gridType.number,
            width: 100,
        },
        {
            field: 'agancyDiscountPercent',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.agencyDiscount'),
            dataType: Enumerations.gridType.number,
            width: 150,
        }
    ];

    useEffect(() => {
        setCallServiceDate(new Date());
        setIsLoading(true);
    }, [searchData]);

    const detailViewClicked = (id) => {
        setSelectedAgencyId(id);
        setShowAgencyModal(true);
    }

    const searchClicked = (data) => setSearchData(data);

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.agencies")}
            icon={'ns-icon-business-partners h1'}>

            <ApAgenciesSearch isLoading={isLoading}
                searchClicked={searchClicked} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetAgenciesService}
                parameters={{
                    'Filter.Name': searchData?.agencyName || '',
                    'Filter.PhoneNumber': searchData?.phoneNumber || '',
                    'Filter.StateIds': searchData?.selectedState?.key || [],
                    'Filter.CityIds': searchData?.selectedCity?.key || [],
                }}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showAgencyModal &&
                <AgencyInfoModal agencyId={selectedAgencyId}
                    isInAdminPanel={true}
                    closeModal={() => setShowAgencyModal(false)} />
            }
        </NsAdminPanelContentWrapper>
    );
};

export default React.memo(ApAgencies);