import React from "react";

import { GetBlogCategoriesService } from "../../../../../services/adminPanel/AdminBlogService";
import ApBasesCategoryContent from "../../bases/ApBasesCategoryContent";

const ApBlogCategory = () => {

    return (
        <ApBasesCategoryContent isBlog={true}
            service={GetBlogCategoriesService} />

    )
};

export default React.memo(ApBlogCategory);