import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import NsButton from "../../../../components/buttons/NsButton";
import NsAdminPanelContentWrapper from "../../../../components/cards/NsAdminPanelContentWrapper";
import NsOptionBox from "../../../../components/labels/NsOptionBox";
import NsTypography from "../../../../components/text/NsTypography";
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApPromotionModal from "./ApPromotionModal";

const ApPromotion = () => {

    const [showPromotionModal, setShowPromotionModal] = useState(false);
    const [selectedPromotion, setSelectedPromotion] = useState(null);
    const [messageClassModal, setMessageClassModal] = useState();

    const showPromotionModalClicked = (promotion) => {
        setSelectedPromotion(promotion)
        setShowPromotionModal(true);
    }

    const closePromotionModalClicked = (messageClassModal) => {
        setShowPromotionModal(false);
        if (!isEmpty(messageClassModal)) {
            setMessageClassModal(messageClassModal);
        }
    }

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.promotions")}
            icon={'ns-icon-advertisment h1'}>
            <Row className="px-2">
                <PromotionOption promotionOptions={ConstantsWithTranslation.getPromotions()}
                    showPromotionModalClicked={showPromotionModalClicked} />
            </Row>
            {showPromotionModal &&
                <ApPromotionModal selectedPromotion={selectedPromotion}
                    closeModal={closePromotionModalClicked} />
            }
            {messageClassModal}
        </NsAdminPanelContentWrapper>
    )
};

function PromotionOption(props) {
    return props.promotionOptions.map((promotion, index) => {
        return (
            <Col key={index} md={6} xl={4} xxl={3} className='g-3 px-1'>
                <NsOptionBox isSelected={false}>
                    <div className='d-flex flex-row cursor-none p-2 w-100'>
                        <img src={promotion.src} className='' width='40%' alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.promotionSetting"), getTranslatedRawText(promotion.title))} />
                        <div className='d-flex flex-column justify-content-between ms-3 w-100'>
                            <div>
                                <NsTypography variant="inherit" className='font-size-12'>{getTranslatedRawText(promotion.title)}</NsTypography>
                            </div>
                            <div className="d-flex flex-row-reverse">
                                <NsButton label={getTranslatedRawText('adminPanel.promotions.change')}
                                    className='secondary w-auto'
                                    size='small'
                                    loading={props?.isLoading}
                                    startIcon='ns-icon-change'
                                    onClick={() => props.showPromotionModalClicked(promotion)} />
                            </div>
                        </div>
                    </div>
                </NsOptionBox>
            </Col>
        );
    });
}

export default React.memo(ApPromotion);