import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetSmsPanelCreditService } from '../../../../services/adminPanel/AdminDashboardService';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import ApBasesDashboardHeader from '../bases/ApBasesDashboardHeader';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import DateHelper from '../../../../utilities/DateHelper';
import ApDspGadgets from './ApDspGadgets';

const ApDashboardSmsPanelsCredit = (props) => {

    const [dates, setDates] = useState([new Date(DateHelper.getCustomPreviousMonth(2)), new Date()]);
    const [refreshDate, setRefreshDate] = useState();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const refreshClicked = useCallback((dates, isRefreshDate) => {
        setDates(dates);
        isRefreshDate && setRefreshDate(new Date());
    })

    useEffect(() => {
        getSaleGadgets();
    }, [refreshDate])

    const getSaleGadgets = () => {
        setIsLoading(true);
        GetSmsPanelCreditService(getSaleGadgetsCallback)
    }

    const getSaleGadgetsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            setData(resultData)
    }

    return (
        <Row className='d-flex flex-column m-0 px-0 gap-2' >
            <Col>
                <ApBasesDashboardHeader title='adminPanel.dashboard.smsPanelsCreditDashboard'
                    refreshClicked={refreshClicked} />
            </Col>
            {hasAccess([UserClaimEnumerations.getApDSmsPanelCredit]) &&
                <Row className='p-0 m-0 gy-2'>
                    <ApDspGadgets data={data} isLoading={isLoading} />
                </Row>
            }
        </Row>
    );
};

export default React.memo(ApDashboardSmsPanelsCredit);