import React from "react";

import NsTab from "../../../../components/tabs/NsTab";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import SeoBox from "../../../singlePages/mainSections/specialNeeds/SeoBox";
import NsButton from "../../../../components/buttons/NsButton";
import { ContactInfo } from "./ShoppingBagSubmitOrderModal";

class ShoppingBagMainGuidance extends React.Component {
    constructor(props) {
        super(props);
        this.diamensionsDes = [
            { title: 'Gusset', description: "A paper bags gusset is an important feature that enhances the functionality of a bag and provides additional space for carrying items. The gusset is a triangular or rectangular panel added to the sides or bottom of the bag, allowing it to expand and accommodate bulkier or larger items. This feature is particularly beneficial for retail and grocery stores, as it provides customers with a convenient solution for carrying their purchases. Not only does the gusset increase the bag's capacity, but it also adds strength and durability to ensure that the bag can withstand the weight of the items inside. Overall, the bag paper gusset is a practical and essential aspect of any bag design, enhancing the overall shopping experience for retailers and customers." },
            { title: 'Width', description: "paper bags width refers to the measurement of the bag from one side to the other, typically taken at its widest point. This dimension plays a crucial role in determining the bag's capacity and functionality. A wider bags for paper offers more room for carrying items and allows for easier organization of purchases. It also ensures the bag can accommodate larger or bulkier items. Additionally, a wider width provides added stability and prevents items from shifting and potentially getting damaged during transportation. Therefore, considering the width of a shopping bag is essential to ensure a convenient and efficient shopping experience." },
            { title: 'Height', description: 'The height of white paper bags plays an essential role in ensuring convenient and practical usage for shoppers. An appropriate height allows for easy access and retrieval of items while reducing strain on the user. The ideal height of a shopping bag should align with the average comfort level of an individual. This measurement strikes a balance between a spacious interior that can accommodate various items and a manageable size that allows for effortless carrying. Consequently, selecting shopping bags with suitable heights promotes a satisfying shopping experience for customers and helps businesses successfully meet customer needs.' },
            { title: 'Top Fold', description: 'The top fold is a popular feature in paper bags that provides convenience and practicality. Located at the top of the bag, this fold allows for easy access to the contents inside. It also offers added security by neatly sealing the bag and preventing items from falling out or getting damaged. With a simple fold and press, the top fold ensures a clean and organized appearance while carrying groceries, clothing, or any other type of merchandise. This feature is particularly useful when on the go, as it allows for quick and hassle-free handling of the bag, making it a preferred choice for both retailers and consumers alike.' },
            { title: 'Paper Thickness (gsm)', description: 'The paper thickness, measured in grams per square meter (gsm), indicates the weight and sturdiness of the shopping bag (black paper bags). Higher gsm values indicate thicker and sturdier paper, providing better support and reducing the risk of tearing or breakage. This is particularly crucial for carrying heavier or bulkier items. Therefore, it is important for businesses to carefully consider the gsm of the paper used in their kraft paper bags to ensure they meet the needs and expectations of their customers.' },
            { title: 'Paper Type', description: 'The choice of paper type is pivotal in determining the sustainability of your packaging. Two main options available in the market are virgin paper and recycled paper. Virgin paper is made from freshly cut trees and offers a pristine, high-quality appearance. It is renowned for its strength and durability, making it an excellent choice for heavy items. On the other hand, recycled paper is a sustainable option that helps conserve natural resources and reduce environmental impact. It is made from post-consumer or post-industrial waste, offering a more eco-friendly alternative. While it may not have the same pristine look as virgin paper, it still maintains sufficient strength for regular shopping needs. Both options have their merits, and selecting the appropriate paper type depends on the specific requirements and priorities of the business.' },
            { title: 'Packaging', description: "Packaging plays a crucial role in the shopping bags industry, enhancing the product's presentation and ensuring its protection. Shrink film is often used as a packaging material, providing a tight and secure wrapping around the contents. This transparent and flexible film not only showcases the product's features but also safeguards it from dirt, moisture, and tampering. On the other hand, cartons are a popular packaging option for paper bags as they offer strength and durability. With their rigid structure and ability to be customized with vibrant designs, cartons not only ensure the safe transport of the bags but also serve as an attractive display on store shelves. The combination of both materials can offer the best of both worlds, ensuring your products are not only secure but also beautifully presented." },
        ];
        this.howTomeasure = [
            {
                title: 'How to measure paper bags',
                texts: ['Measuring a paper bags is a simple process that ensures you choose the right size for your needs. Follow these steps to accurately measure your shopping bag:'],
                links: [],
                type: 'blockTitle',
            },
            {
                title: 'Lay the bag flat:',
                texts: ['Place the kraft paper bags on a flat surface with all sides unfolded.'],
                links: [],
                type: 'blockTitle',
            },
            {
                title: 'Measure width:',
                texts: ['Measure the width of the bag from side to side, ensuring you include any side gussets.'],
                links: [],
                type: 'blockTitle',
            },
            {
                title: 'Measure height:',
                texts: ["Measure the height from the base to the top of the handles or the bag's highest point."],
                links: [],
                type: 'blockTitle',
            },
            {
                title: 'Measure the Gusset Depth:',
                texts: ["To measure the gusset depth, locate the fold or pleat that creates the gusset. Measure the distance from the bottom of the bag to the highest point of the gusset's fold."],
                links: [],
                type: 'blockTitle',
            },
            {
                title: 'Measure volume:',
                texts: ['To calculate the volume of the bag, you can use the following formula: Volume = Height × Width × Length'],
                links: [],
                type: 'blockTitle',
            },
            {
                title: 'Note additional features:',
                texts: ["Take note of any extra features, such as handles, closures, or top fold, that may affect the bag's functionality. Keep in mind that having a top folding can increase the size of the paper bags bulk."],
                links: [],
                type: 'blockTitle',
            },
            {
                title: '',
                texts: ["By following these steps, you'll have the accurate measurements needed for selecting or customizing the perfect paper shopping bags."],
                links: [],
                type: 'blockTitle',
            },
        ];

        this.tabs = ConstantsWithTranslation.getShoppingBagGuidanceTabs();
        this.state = {
            selectedTabIndex: 0,
            selectedTab: this.tabs[0],
            showMoreIndex: null,
            showMore: false
        }
    }

    tabChanged = (tabIndex, selectedTab) => this.setState({ selectedTabIndex: tabIndex, selectedTab, showMoreIndex: null, viewMore: false });

    showMoreClicked = (showMoreIndex) => showMoreIndex !== this.state.showMoreIndex
        ? this.setState({ showMoreIndex, showMore: true })
        : this.setState({ showMore: !this.state.showMore });

    onlineAvailableProductsClicked

    mapGuidance = () => {
        const { showMore, showMoreIndex, selectedTab } = this.state;
        switch (selectedTab.id) {
            case 1: return <ContactInfo onClick={this.props.onClick} selectedPriceInqueryValuesDescription={this.props.selectedPriceInqueryValuesDescription} />
            case 2: return this.diamensionsDes.map((guide, index) =>
                <div key={index} className={index !== this.diamensionsDes.length - 1 ? "border-bottom mb-2 px-2 font-size-12" : "px-2 font-size-12"} >
                    <span className="fw-bold">{guide.title}</span>
                    <span className={showMoreIndex == index && !!showMore ? 'd-block text-justify' : 'truncate-text'}>
                        {guide.description}
                    </span>
                    <div className="d-flex justify-content-end my-2">
                        <NsButton label={showMoreIndex == index && !!showMore ? 'regularOrder.confirmDetail.viewLess' : 'regularOrder.confirmDetail.viewMore'}
                            className='secondary-text'
                            size='small'
                            onClick={() => this.showMoreClicked(index)} />
                    </div>
                </ div>)
            case 3: return <SeoBox isExpandable={false}
                textClassName='font-size-12'
                titleClassName='font-size-12'
                className='px-2'
                content={this.howTomeasure} />
        }
    }

    render() {
        const { selectedTabIndex } = this.state;

        return (
            <div>
                <NsTab tabs={this.tabs}
                    className='mb-2'
                    value={selectedTabIndex}
                    onChange={this.tabChanged} />
                <div key={selectedTabIndex} className="shopping-bag__guidance overflow-scroll custom-scrollbar">
                    {this.mapGuidance()}
                </div>
            </div>
        );
    }
}

export default ShoppingBagMainGuidance;