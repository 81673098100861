const ApiLinks = {

    api: {
        home: {
            getAnnouncementContents: 'api/{culture}/Cms/AnnouncementContent/GetAnnouncementContents'
        },

        account: {
            signIn: 'api/{culture}/Account/Authentication/SignIn',
            getAccount: 'api/{culture}/Account/SignUp/AccountInformation',
            signup: 'api/{culture}/Account/SignUp/AccountInformation',
            changePassword: 'api/{culture}/Account/SignUp/ResetPassword',
            verifyMobile: 'api/{culture}/Account/SignUp/VerifyMobile',
            sendVerificationCodeForSignup: 'api/{culture}/Account/SignUp/SendVerificationCodeForSignup',
            sendVerificationCodeForResetPassword: 'api/{culture}/Account/SignUp/SendVerificationCodeForResetPassword',
            resendVerificationCode: 'api/{culture}/Account/SignUp/ResendVerificationCode',
            getCaptcha: 'api/{culture}/Common/Captcha/GetCaptcha',
            getSignupFieldVisibilityType: 'api/{culture}/Account/SignUp/FieldVisibilityType',

        },

        ipStack: 'https://api.ipstack.com/check?access_key={0}',

        addressFromMap: 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat={0}&lon={1}',

        profile: {
            getProfileFieldVisibilityType: 'api/{culture}/Account/Profile/FieldVisibilityType',
            getPersonalSetting: 'api/{culture}/Account/Profile/GetPersonalInformation',
            editPersonalSetting: 'api/{culture}/Account/Profile/EditPersonalSetting',
            editOfficialInvoiceStatus: 'api/{culture}/Account/Profile/EditOfficialInvoiceStatus',
            getDisableToken: 'api/{culture}/Account/Profile/DisableToken',
            getPublicProfile: 'api/{culture}/Account/Profile/PublicProfile',

            changePassword: 'api/{culture}/Account/Profile/ChangePassword',

            getNotifications: 'api/{culture}/Account/Notification/GetNotifications',
            editNotifications: 'api/{culture}/Account/Notification/EditNotification',

            getAddressesForAutoDelivery: 'api/{culture}/Account/UserAddress/GetAddressesForAutoDelivery',
            getDefaultDeliveryAddress: 'api/{culture}/Nas/Shipping/GetAutoDelivery',
            getAgencies: 'api/{culture}/Account/Profile/Agencies',
            getAgencyDetail: 'api/{culture}/Account/Profile/AgencyDetail',
            postSetAgency: 'api/{culture}/Account/Profile/SetAgency',
            getCoachMarksStatus: 'api/{culture}/Account/Profile/GetShowTipsStatus',
            editCoachMarksStatus: 'api/{culture}/Account/Profile/EditShowTipsStatus',
            autoDelivery: 'api/{culture}/Nas/Shipping/AutoDelivery',
            inPersonDelivery: 'api/{culture}/Nas/Shipping/InPersonDelivery',
            getAddresses: 'api/{culture}/Account/UserAddress/GetAddresses',
            getAddressDetail: 'api/{culture}/Account/UserAddress/GetAddressDetail',
            addAddress: 'api/{culture}/Account/UserAddress/AddAddress',
            editAddress: 'api/{culture}/Account/UserAddress/EditAddress',
            deleteAddress: 'api/{culture}/Account/UserAddress/DeleteAddress',
            getProfileUserFeedbacks: 'api/{culture}/Account/Profile/GetUserFeedbacks',


            getCountry: 'api/{culture}/Nas/Address/GetCountry',
            getStates: 'api/{culture}/Common/Common/GetCountryStates',
            getCities: 'api/{culture}/Common/Common/GetCities',

            getAvailableOrdersForShipping: 'api/{culture}/Nas/Shipping/AvailableOrdersForShipping',
            submitDeliveryRequest: 'api/{culture}/Nas/Shipping/ShippingRequest',
            getShippingPlans: 'api/{culture}/Nas/Shipping/ShippingPlans',
            getShippingMethods: 'api/{culture}/Nas/Shipping/ShippingMethods',
            getShippingMethod: 'api/{culture}/Nas/Shipping/ShippingMethod',
            getShippingCost: 'api/{culture}/Nas/Shipping/ShippingCost',
            getShippingPlanAvailability: 'api/{culture}/Nas/Shipping/ShippingPlanAvailability',
            getShippingEstimatedTimeSheet: 'api/{culture}/Nas/Shipping/ShippingEstimatedTimeSheets',

            getCashbackHistories: 'api/{culture}/Account/Profile/GetCashBackHistories',
            getCashbackPlans: 'api/{culture}/Account/Profile/GetCashBackPlans',
            getCashbackForecast: 'api/{culture}/Account/Profile/GetCashBackForecast',
            getCurrentCashbackInfo: 'api/{culture}/Account/Profile/CurrentCashbackInfo',
        },

        payment: {
            getAmountOfPayment: 'api/{culture}/Payment/Payment/GetPaymentAmount',
            getPaymentGateways: 'api/{culture}/Payment/PaymentGateways/GetPaymentGateways',
            postAmountToIncreaseCredit: 'api/{culture}/Payment/Payment/PaymentForIncreaseCredit',
            getPaymentUrl: 'api/{culture}/Payment/Payment/GetRequestUrlToIpg',
            settleNgeniusPayment: 'api/{culture}/Payment/NgeniusPayment/SettlePayment',
            settleSepehrPayment: 'api/{culture}/Payment/SepehrPayment/SettlePayment',
            settleAsanPardakhtPayment: 'api/{culture}/Payment/AsanPardakhtPayment/SettlePayment',
            postPaymentForOrders: 'api/{culture}/Payment/Payment/PaymentForOrders',
            postPaymentForStoreProductOrders: 'api/{culture}/Payment/Payment/PaymentForStoreProductOrders',
            getPaymentReport: 'api/{culture}/Payment/Payment/GetPaymentReport',
            getAccountingExcelReport: 'api/{culture}/Account/UserAccounting/AccountingExcelReport',
            getPaymentExcelReport: 'api/{culture}/Payment/Payment/PaymentsExcelReport'
        },

        userOrder: {
            getCartItemExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/CartItemExcelReport',
            getPreparingExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/PreparingExcelReport',
            getFailedExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/FailedExcelReport',
            getInProcessExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/InProcessExcelReport',
            getArchiveExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/ArchiveExcelReport',
            getInTransitExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/InTransitExcelReport',
            getInWarehouseExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/InWarehouseExcelReport',
            getTransferToWarehouseExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/TransferToWarehouseExcelReport',
            getAllOrderExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/AllExcelReport',
            getAllOrderFilterExcelReport: 'api/{culture}/Order/SharedSheetOrderByState/AllOrderFilterExcelReport',
            getSidebarCartItems: 'api/{culture}/Order/SharedSheetOrderByState/GetCartItems',
            getMyOrderCartItems: 'api/{culture}/Order/SharedSheetOrderByState/GetCartItemWithDetails',
            getSelectedOrdersForConfirmation: 'api/{culture}/Order/SharedSheetOrder/GetSelectedOrdersForConfirmation',
            getTotalPriceAndCount: 'api/{culture}/Order/UserOrder/GetTotalPriceAndCount',
            getOrderBasicInfo: 'api/{culture}/Order/UserOrder/GetOrderBasicInfo',
            getOrderDetail: 'api/{culture}/Order/UserOrder/GetOrderDetail',
            getAdditionalOrderInformation: 'api/{culture}/Order/SharedSheetOrderDetail/GetAdditionalInformation',
            getFilterPoints: 'api/{culture}/Order/SharedSheetOrderDetailOption/GetFilterPoints',
            getPunchPoints: 'api/{culture}/Order/SharedSheetOrderDetailOption/GetPunchPoints',
            getFoldLines: 'api/{culture}/Order/SharedSheetOrderDetailOption/GetFoldLines',
            getBillBookOptions: 'api/{culture}/Order/SharedSheetOrderDetailOption/GetBillBookOptions',
            getOrdersByStatus: 'api/{culture}/Order/UserOrder/GetOrdersByStatus',
            getPreparingOrders: 'api/{culture}/Order/SharedSheetOrderByState/Preparing',
            getFailedOrders: 'api/{culture}/Order/SharedSheetOrderByState/Failed',
            getInProcessingOrders: 'api/{culture}/Order/SharedSheetOrderByState/InProcess',
            changeStateFromFailToPreparing: 'api/{culture}/Order/UserOrder/ChangeStateFromFailToPreparing',
            getArchiveOrders: 'api/{culture}/Order/SharedSheetOrderByState/Archive',
            getAllOrders: 'api/{culture}/Order/SharedSheetOrderByState/All',
            getAllOrdersFilter: 'api/{culture}/Order/SharedSheetOrderByState/AllOrderFilter',
            getTransferToWarehouseOrders: 'api/{culture}/Order/SharedSheetOrderByState/TransferToWarehouse',
            getInWarehouseOrders: 'api/{culture}/Order/SharedSheetOrderByState/InWarehouse',
            getInTransitOrders: 'api/{culture}/Order/SharedSheetOrderByState/InTransit',
            getUserQueueStatus: 'api/{culture}/Order/SharedSheetOrderByState/StatusQueue',
            getShippingRequestsByStatus: 'api/{culture}/Nas/Shipping/ShippingRequestsByStatus',
            getShippingBasicInfo: 'api/{culture}/Nas/Shipping/ShippingBasicInfo',
            getShippingRequestOrders: 'api/{culture}/Nas/Shipping/ShippingRequestOrders',
            userOrderDelete: 'api/{culture}/Order/UserOrder/Delete',
            userOrderUnOfficialPrintInvoice: 'api/{culture}/Order/UserOrderReport/GetUnOfficialInvoice',
            userOrderOfficialPrintInvoice: 'api/{culture}/Order/UserOrderReport/GetOfficialInvoice',
            userOrderOfficialPrintInvoiceStatus: 'api/{culture}/Order/UserOrderReport/GetOfficialInvoiceStatus',
            userAccountingReport: 'api/{culture}/Account/UserAccounting/AccountingReport',
            userAccountingPaymentReport: 'api/{culture}/Payment/Payment/GetPayments',
            getUserItems: 'api/{culture}/Order/SharedSheetDraftOrder/GetUserItems',
            uploadDraftOrderFile: 'api/{culture}/Order/SharedSheetDraftOrder/Upload',
            deleteDraftOrderFile: 'api/{culture}/Order/SharedSheetDraftOrder/DeleteOrderFile',
            saveDraftOrderFile: 'api/{culture}/Order/SharedSheetDraftOrder/Save',
            downloadEditedOrderFile: 'api/{culture}/Order/SharedSheetDraftOrder/DownloadEditedOrderFile',
            GetFailedOrderCount: 'api/{culture}/Order/UserOrder/GetFailedOrderCount',
            getUserOrderHistories: 'api/{culture}/Order/SharedSheetOrderByState/Histories',
            deleteByCancellationCode: 'api/{culture}/Order/UserOrder/DeleteByCancellationCode',
            getOrderEstimatedDeliveryTime: 'api/{culture}/Order/UserOrder/OrderPreparationEstimatedTimeSheets',
            getOrderSeasonalPurchaseLimitStatus: 'api/{culture}/Order/UserOrder/GetOrderSeasonalPurchaseLimitStatus',
        },

        agency: {
            getAgencies: 'api/{culture}/Nas/Agency/Agencies',
            getDetail: 'api/{culture}/Nas/Agency/Detail',
            getProducts: 'api/{culture}/Nas/Agency/GetAgencyProducts',
            getCities: 'api/{culture}/Nas/Agency/Cities',
            getCustomers: 'api/{culture}/Agency/UserAgency/Users',
            getOrdersBasic: 'api/{culture}/Agency/SharedSheetOrder/SharedSheetOrdersBasicFilter',
            getOrdersAdvance: 'api/{culture}/Agency/SharedSheetOrder/SharedSheetOrdersAdvancedFilter',
            getOrderDetail: 'api/{culture}/Agency/SharedSheetOrder/GetOrderDetail',
            getBasicInfoSharedSheetOrderDetail: 'api/{culture}/Agency/SharedSheetOrder/GetOrderBasicInfo',
        },

        userBalance: {
            getCredit: 'api/{culture}/Account/Profile/GetCredit',
            getBalance: 'api/{culture}/Account/Profile/GetBalance',
            increaseCredit: 'api/{culture}',
            paymentByCredit: 'api/{culture}/Order/SharedSheetOrder/PaymentByCredit',
        },

        fileManager: {
            getTreeViewFolders: 'api/{culture}/FileManager/FolderManagement/GetTreeView',
            getFolderContent: 'api/{culture}/FileManager/FolderManagement/GetFileAndFolders',
            getFolderDetail: 'api/{culture}/FileManager/FolderManagement/GetFolderDetail',
            createFolder: 'api/{culture}/FileManager/FolderManagement/Create',
            deleteFolder: 'api/{culture}/FileManager/FolderManagement/Delete',
            renameFolder: 'api/{culture}/FileManager/FolderManagement/Rename',
            uploadFile: 'api/{culture}/FileManager/Upload/Upload',
            renameFile: 'api/{culture}/FileManager/FileManagement/Rename',
            deleteFile: 'api/{culture}/FileManager/FileManagement/Delete',
            getFileDetail: 'api/{culture}/FileManager/FileManagement/GetFileDetail',
            getFileInfo: 'api/{culture}/FileManager/FileManagement/GetFileInfo',
            fileActions: 'api/{culture}/FileManager/FileEditorTool/ApplyChanges',
            getDiskUsage: 'api/{culture}/FileManager/FileManagement/GetDiskUsage',
            isNameExists: 'api/{culture}/FileManager/FileManagement/Exists',
        },

        product: {
            getProductList: 'api/{culture}/Nas/Product/GetProductList',
            getProductInfo: 'api/{culture}/Nas/Product/GetProductInfo',
            getProductNameList: 'api/{culture}/Nas/Product/GetProductNameList',
            getProductsCirculations: 'api/{culture}/Nas/Product/GetProductsCirculations',
            getPrintingFeature: 'api/{culture}/Nas/Product/GetPrintingFeature',
            getProductSpecialDetail: 'api/{culture}/Nas/Product/GetProductSpecialDetail',
            getPrintingProcessTime: 'api/{culture}/Nas/Product/GetPrintingProcessTime',
            getProductCategoryInfo: 'api/{culture}/Nas/ProductCategory/GetProductCategoryInfo',
            getProductDetail: 'api/{culture}/Nas/Product/ProductDetail',
            getOPreparationEstimatedTimeSheetsBySelectedConfirmTime: 'api/{culture}/Nas/Product/OrderPreparationEstimatedTimeSheetsBySelectedConfirmTime',
            getDecreasedProductPrices: 'api/{culture}/Nas/Product/DecreasedProductPrices',
        },

        shoppingBag: {
            getBagPrintPrices: 'api/{culture}/Nas/Product/ShoppingBagPrintPrices',
        },

        storeProducts: {
            getStoreProductList: 'api/{culture}/Stores/StoreProduct/GetProductList',
            getStoreProductInfo: 'api/{culture}/Nas/StoreProduct/GetProductInfo',
            getStoreProductSuppliers: 'api/{culture}/Stores/StoreProductOrder/GetBasicPrice',
            addToCartStoreProduct: 'api/{culture}/Stores/StoreProductOrder/AddToCart',
            getStoreCartItemsCount: 'api/{culture}/Order/SharedSheetOrderByState/StoreProductCartItems',
            paymentByCreditStoreOrder: 'api/{culture}/Stores/StoreProductOrder/PaymentByCredit',
            stateHistoriesStoreOrder: 'api/{culture}/Stores/StoreProductOrder/StateHistories',
            getStoreProductOrderQueueStatus: 'api/fa/Stores/StoreProductOrderByState/StatusQueue',
        },

        storeOrders: {
            getSelectedStoreOrdersForConfirmation: 'api/{culture}/Stores/StoreProductOrder/GetSelectedOrdersForConfirmation',
            getStoreProductCart: 'api/{culture}/Stores/StoreProductCartOrder/StoreProductCartItemWithDetails',
            deleteFromCartStoreProduct: 'api/{culture}/Stores/StoreProductOrder/Delete',
            getNotAppliedStoreOrders: 'api/{culture}/Stores/StoreProductOrderByState/NotApplied',
            getPreparingStoreOrders: 'api/{culture}/Stores/StoreProductOrderByState/Preparing',
            getInWarehouseStoreOrders: 'api/{culture}/Stores/StoreProductOrderByState/InWarehouse',
            getInTransitStoreOrders: 'api/{culture}/Stores/StoreProductOrderByState/InTransit',
            getRetryToReadyState: 'api/{culture}/Stores/StoreProductOrder/RetryToReadyState',
            getCanceledStoreOrders: 'api/{culture}/Stores/StoreProductOrderByState/Canceled',
            getDeliveredStoreOrders: 'api/{culture}/Stores/StoreProductOrderByState/Delivered',
            getDetailStoreOrder: 'api/{culture}/Stores/UserStoreProductOrder/GetDetail',
            getDetailBasicInfoStoreOrder: 'api/{culture}/Stores/UserStoreProductOrder/GetBasicInfo',
            getAdditionalInformationStoreOrder: 'api/{culture}/Stores/StoreProductOrderDetail/GetAdditionalInformation',
            getDeliveryRequestStoreOrders: 'api/{culture}/Nas/Shipping/GetDeliveryRequestStoreProductOrders',
        },

        order: {
            getProductBasicPrice: 'api/{culture}/Order/SharedSheetOrder/GetBasicPrice',
            getSpecificDimensionInfo: 'api/{culture}/Order/SharedSheetOrder/SpecificDimensionInfo',
            getProductAdvancePrice: 'api/{culture}/Order/SharedSheetOrder/GetAdvancedPrice',
            checkFileBeforeUpload: 'api/{culture}/Order/SharedSheetOrder/CheckFileBeforeUpload',
            uploadImageFile: 'api/{culture}/Order/SharedSheetOrder/UploadMasterFile',
            uploadFilmFile: 'api/{culture}/Order/SharedSheetOrder/UploadFilmFile',
            deleteFileAndFilm: 'api/{culture}/Order/SharedSheetOrder/DeleteFile',
            getFilePublicUrl: 'api/{culture}/Order/SharedSheetOrder/GetFilePublicUrl',
            resizeFile: 'api/{culture}/Order/SharedSheetOrder/ResizeFile',
            saveOrder: 'api/{culture}/Order/SharedSheetOrder/Save',
            masterFileInformation: 'api/{culture}/Order/SharedSheetProfessionalOrder/MasterFileInformation',
            filmFileInformation: 'api/{culture}/Order/SharedSheetProfessionalOrder/FilmFileInformation',
            prepareMasterFile: 'api/{culture}/Order/SharedSheetProfessionalOrder/PrepareMasterFile',
            prepareFilmFile: 'api/{culture}/Order/SharedSheetProfessionalOrder/PrepareFilmFile',
            getExtendedServicePrice: 'api/{culture}/Order/SharedSheetOrder/ExtendedServicePrice',
            addToOrderList: 'api/{culture}/Order/SharedSheetProfessionalOrder/AddOrderDetail',
            deleteOrderDetail: 'api/{culture}/Order/UserOrder/DeleteDetail',
            saveProfessionalOrder: 'api/{culture}/Order/SharedSheetProfessionalOrder/Submit',
            changeSeries: 'api/{culture}/Order/SharedSheetProfessionalOrder/ChangeSeries',
            getBillBookPrintColor: 'api/{culture}/Order/SharedSheetOrderDetailOption/GetBillBookPrintColor',
            billBookOptionPrice: 'api/{culture}/Order/SharedSheetOrderDetailOption/billBookOptionPrice',
            postDiscount: 'api/{culture}/Order/UserOrder/GetDiscount'
        },

        common: {
            getGender: 'api/{culture}/Common/Common/GetGenderTypes',
            getCountries: 'api/{culture}/Common/Common/Countries',
            getCountryNames: 'api/{culture}/Common/Common/GetCountryNames',
            getStateNames: 'api/{culture}/Common/Common/GetStateNames',
            getCityNames: 'api/{culture}/Common/Common/GetCityNames',
            getCategories: 'api/{culture}/Nas/ProductCategory/List',
            getStoreCategories: 'api/{culture}/Stores/StoreProductCategory/List',
            getImage: 'api/{culture}/FileManager/FileManagement/File',
            getCurrencies: 'api/{culture}/Common/Common/GetCurrencies',
        },

        singlePage: {
            getFestivalEventDetail: 'api/{culture}/Nas/LotteryEvent/GetDetail',
            getFestivals: 'api/{culture}/Nas/LotteryEvent/Lotteries',
            getLatestFestival: 'api/{culture}/Nas/LotteryEvent/GetLatestLottery',
            getIsWinner: 'api/{culture}/Nas/LotteryEvent/IsWinner',
            getDownloadCategories: 'api/{culture}/Common/Categories/DownloadCategories',
            getRandomTags: 'api/{culture}/Nas/Product/GetRandomTags',
        },

        cms: {
            getCmsBlogFilterCategories: 'api/{culture}/Cms/Blog/FilterPageCategory',
            getCmsBlogCategoryDetail: 'api/{culture}/Cms/Blog/PageCategory',
            getCmsBlogFilterPosts: 'api/{culture}/Cms/Blog/FilterPageContent',
            getCmsBlogPostDetail: 'api/{culture}/Cms/Blog/PageContent',
            getCmsBlogsArchivesDate: 'api/{culture}/Cms/Blog/Archives',

            getCmsNewsFilterCategories: 'api/{culture}/Cms/News/FilterPageCategory',
            getCmsNewsCategoryDetail: 'api/{culture}/Cms/News/PageCategory',
            getCmsNewsFilterPosts: 'api/{culture}/Cms/News/FilterPageContent',
            getCmsNewsPostDetail: 'api/{culture}/Cms/News/PageContent',
            getCmsNewsArchivesDate: 'api/{culture}/Cms/News/Archives',

            getCmsSliders: 'api/{culture}/Cms/Slider/Sliders',
            getCmsHomePromoteContent: 'api/{culture}/Cms/PromoteContent/HomePromoteContents',
            getCmsMiddlePromoteContent: 'api/{culture}/Cms/PromoteContent/MiddlePromoteContents',
            getCmsSinglePromoteContent: 'api/{culture}/Cms/PromoteContent/SinglePromoteContents',
            getCmsBlogAndNewsPromoteContent: 'api/{culture}/Cms/PromoteContent/BlogAndNewsPromoteContents',
            getCmsHomeBottomPromoteContent: 'api/{culture}/Cms/PromoteContent/BottomPromotionContents',

            getCmsAnnouncements: 'api/{culture}/Cms/AnnouncementContent/AnnouncementContents',
            getCmsSinglePageCategories: 'api/{culture}/Cms/StaticPage/GetStaticPageCategories',
            getCmsSinglePage: 'api/{culture}/Cms/StaticPage/GetStaticPage',

            getGalleries: 'api/{culture}/Cms/Galleries/GetBasicDetails',

            getCmsFile: 'api/{culture}/Cms/FileUploader/File',

            getCmsDownloadCategoryDetails: 'api/{culture}/Cms/Downloads/Get',
        },

        crm: {
            getCrmAnnouncementContentFeedback: 'api/{culture}/Crm/FeedbackToAnnouncementContent/GetUserFeedback',
            addCrmAnnouncementContentFeedback: 'api/{culture}/Crm/FeedbackToAnnouncementContent/Save',
            getCrmOrderFeedback: 'api/{culture}/Crm/FeedbackToSharedSheetOrder/GetUserFeedback',
            addCrmOrderFeedback: 'api/{culture}/Crm/FeedbackToSharedSheetOrder/Save',
            getCrmUserFeedback: 'api/{culture}/Crm/FeedbackToUser/GetUserFeedback',
            addCrmUserFeedback: 'api/{culture}/Crm/FeedbackToUser/Save',

        }
    },
};

export default ApiLinks;