import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { getTranslatedRawText, getUrlWithCurrentCulture, isEnCulture } from "../../../utilities/CultureHelper";
import { AddToCartStoreProductService } from "../../../services/product/StoreProductService";
import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import { miniCartActionCreators } from "../../../redux/reducers/MiniCartReducer";
import PdBaseLoginMessage from "../../productDetails/bases/PdBaseLoginMessage";
import NsAddToCartButton from "../../../components/buttons/NsAddToCartButton";
import OrderCartModal from '../../profileSettings/modals/OrderCartModal';
import DelayedTaskHelper from "../../../utilities/DelayedTaskHelper";
import NsButton from "../../../components/buttons/NsButton";
import { isEmpty } from "../../../utilities/ValidationHelper";
import CommonHelper from "../../../utilities/CommonHelper";
import Links from "../../../configs/links/AllLinks";

class BaseAddToCart extends React.Component {

    constructor(props) {
        super(props);
        this.getTotalPriceAndCountByDelay = new DelayedTaskHelper(() => {
            this.props.getTotalPriceAndCount();
        }, 1000);
        this.state = {
            supplierOrderCart: this.props.supplierOrderCart,
            showCartOrderModal: false,
            showSignInMessageModalDate: null,
            isLoading: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.supplierOrderCart !== this.props.supplierOrderCart)
            this.setState({ supplierOrderCart: this.props.supplierOrderCart });
    }

    addToCart = (unitCount, storeProductSupplierPriceId) => {
        if (this.props.isAuthenticated) {
            this.setState({ isLoading: true });
            AddToCartStoreProductService({ storeProductSupplierPriceId, unitCount }, this.addToCartStoreProductCallback(unitCount, storeProductSupplierPriceId));
        } else
            this.setState({ showSignInMessageModalDate: new Date() });
    }

    addToCartStoreProductCallback = (unitCount, storeProductSupplierPriceId) => (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (result?.data?.hasError)
            this.setState({ resultMessageClass });
        else {
            this.getTotalPriceAndCountByDelay.run();
            this.valueChanged(unitCount, storeProductSupplierPriceId);
        }
        CommonHelper.safeFunctionCall(this.props.refreshProduct);
    }

    valueChanged = (value, storeProductSupplierPriceId) => {
        let newSupplierOrderCart = this.state.supplierOrderCart;
        isEmpty(newSupplierOrderCart)
            ? newSupplierOrderCart = { key: storeProductSupplierPriceId, value }
            : newSupplierOrderCart.value = value;
        this.setState({ supplierOrderCart: newSupplierOrderCart });
    }

    toggleCartOrderModalClicked = (refreshDate) => {
        if (this.props.showIconButton && !!refreshDate) CommonHelper.safeFunctionCall(this.props.refreshProduct);

        this.setState({ showCartOrderModal: !this.state.showCartOrderModal });
    }

    render() {
        const { showCartOrderModal, resultMessageClass, supplierOrderCart, showSignInMessageModalDate, isLoading } = this.state;
        const { className, isAuthenticated, storeProductSupplierPriceId, showIconButton, isBasket, isSmallSize, hasDeleteIcon, isLoadingStoreCartItems, noShowCart, } = this.props;

        return <div className={clsx('d-flex justify-content-between align-items-center', className)} >
            <NsAddToCartButton className='ms-auto'
                valueChanged={(value) => this.addToCart(value, storeProductSupplierPriceId)}
                value={supplierOrderCart?.value}
                isBasket={isBasket}
                hasDeleteIcon={hasDeleteIcon}
                isSmallSize={isSmallSize}
                isLoading={isLoading || isLoadingStoreCartItems}
                showIconButton={showIconButton}
                isAuthenticated={isAuthenticated} />
            {supplierOrderCart?.value > 0 && !noShowCart &&
                <NsButton className="secondary-text" onClick={this.toggleCartOrderModalClicked} >
                    <span className="font-size-12">{getTranslatedRawText('product.store.cart')}</span>
                    <i className={isEnCulture() ? 'ns-icon-expand-right fs-5' : 'ns-icon-expand-left fs-5'} />
                </NsButton>
            }

            <PdBaseLoginMessage showSignInMessageModalDate={showSignInMessageModalDate} />

            {showCartOrderModal &&
                <OrderCartModal selectedTabIndex={1}
                    selectedTab={ConstantsWithTranslation.getOrderProductTabs(true, true)[1]}
                    closeModal={() => this.toggleCartOrderModalClicked(true)} />}

            {resultMessageClass}
        </div>
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(miniCartActionCreators, dispatch);
}

export default withRouter(connect(null, mapDispatchToProps)(BaseAddToCart));