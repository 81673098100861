import React from 'react';
import NsMessageClassModal from '../../../components/modals/NsMessageClassModal';
import NsUploadingFileProgress from '../../../components/progresses/NsUploadingFileProgress';
import Enumerations from '../../../configs/Enumerations';
import { DeleteApFileManagerFilesService, PostFileService } from '../../../services/adminPanel/AdminFileUploaderService';
import { CancelRequest } from '../../../services/bases/BaseService';
import { DeleteFileService, DuplicateNameCheckingService, UploadFileService } from '../../../services/fileManager/FileManagementService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';

class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.cancelationUploadingReference = null;
        this.cancelationExistingReference = null
        this.state = {
            progress: {
                loaded: 0,
                total: 0,
                percent: 0,
                status: Enumerations.uploadingStatus.prepareForUpload,
                message: null
            },
            filesInfo: [],
            fileName: null,
            fileId: null,
            isLoading: false,
            isShowQuestionModal: false,
            messageItems: [],
        };
    }

    componentDidMount() {
        const { file, currentFolderId } = this.props;
        CommonHelper.getPdfPageNumber(file, (pageCount) => {
            const parameters = {
                ParentId: currentFolderId,
                Name: file.name,
                pageCount
            };
            this.props?.isAdminPanelFileManager
                ? this.sendFileHandler(Enumerations.duplicateFile.none)
                : this.cancelationExistingReference = DuplicateNameCheckingService(parameters, this.duplicateNameCheckingCallback);
        });
    }

    duplicateNameCheckingCallback = (resultData, result, messageClassModal) => {
        if (!result.data?.hasError) {
            this.sendFileHandler(Enumerations.duplicateFile.none);
        } else {
            this.setState({ progress: { ...this.state.progress, status: Enumerations.uploadingStatus.duplicateName, message: { messageItems: result.data?.messageItems } } });
        }

    }

    sendFileHandler = (duplicateFileActionType) => {
        const { file, currentFolderId } = this.props;
        const parameters = {
            currentFolderId,
            duplicateFileActionType
        }
        this.cancelationUploadingReference = this.props?.isAdminPanelFileManager
            ? PostFileService(file, currentFolderId || Enumerations.fileUploaderCategoryType.root, null, this.uploadFileCallback, this.onUploadProgressCallback)
            : UploadFileService(file, parameters, this.uploadFileCallback, this.onUploadProgressCallback);
        this.setState({ progress: { ...this.state.progress, message: null } });
    }

    cancelDuplicateClicked = () => {
        this.props.getCanceledProgressId(this.props.id);
    }

    componentWillUnmount() {
        CancelRequest(this.cancelationExistingReference);
        CancelRequest(this.cancelationUploadingReference);
    }

    onUploadProgressCallback = (id, loaded, total, percent, status) => {
        this.setState({ progress: { ...this.state.progress, loaded, total, percent, status } });
    }

    uploadFileCallback = (resultData, result, messageClassModal) => {

        this.props.setUploadedFiles(this.props.id);
        if (!result?.data?.hasError) {
            this.setState({
                progress: {
                    ...this.state.progress,
                    status: Enumerations.uploadingStatus.finished
                },
                filesInfo: resultData,
                fileName: resultData[0]?.name,
                fileId: resultData[0]?.id
            });
        } else {
            this.setState({
                progress: {
                    ...this.state.progress,
                    status: Enumerations.uploadingStatus.failed,
                    message: messageClassModal.props
                }
            });
        }
    }

    cancelUploadingClicked = () => this.setState({
        isShowQuestionModal: true,
        messageItems: this.setMessageItems(getTranslatedRawText('common.messages.areYouSureToCancelUploadingFile')),
        isDeleteQuestion: false
    });

    deleteUploadedFileClicked = () => this.setState({
        isShowQuestionModal: true,
        messageItems: this.setMessageItems(CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), this.state.fileName)),
        isDeleteQuestion: true
    });

    closeQuestionModal = (confirm) => {
        if (!!confirm)
            if (this.state.isDeleteQuestion) {
                const { filesInfo } = this.state;
                this.setState({ isLoading: true });
                let data = filesInfo.map(fileInfo => ({
                    name: fileInfo.name,
                    id: fileInfo.id
                }));
                !!this.props?.isAdminPanelFileManager
                    ? DeleteApFileManagerFilesService(filesInfo[0], this.deleteCallback)
                    : DeleteFileService(data, this.deleteCallback)
            }
            else {
                CancelRequest(this.cancelationUploadingReference);
                this.props.getCanceledProgressId(this.props.id);
            }
        this.setState({ isShowQuestionModal: false, messageItems: [] });
    }

    deleteCallback = (resultData, result, messageClassModal) => {

        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            this.props.getCanceledProgressId(this.props.id);
        } else {
            this.setState({
                progress: {
                    ...this.state.progress,
                    status: Enumerations.uploadingStatus.deleteFailed,
                    message: messageClassModal.props
                }
            });
        }
    }

    setMessageItems = (message) => {
        return [{
            messageText: (
                <span>{message}</span>
            ),
            messageType: Enumerations.messageType.question
        }];
    }

    render() {
        const { filesInfo, progress, isLoading, isShowQuestionModal, messageItems } = this.state;
        const { file, folderRoute } = this.props;
        return (
            <div className='border rounded background-element-color mb-2 p-1'>
                <NsUploadingFileProgress fileName={file.name}
                    thumbnails={!!this.props?.isAdminPanelFileManager ? filesInfo.map(file => file.contentUrl) : filesInfo.map(file => file.thumbnail)}
                    isCmsFile={!!this.props?.isAdminPanelFileManager}
                    progress={progress}
                    isLoading={isLoading}
                    cancelUploadingClicked={this.cancelUploadingClicked}
                    deleteUploadedFileClicked={this.deleteUploadedFileClicked}
                    replaceDuplicateClicked={() => this.sendFileHandler(Enumerations.duplicateFile.replace)}
                    keepBothDuplicateClicked={() => this.sendFileHandler(Enumerations.duplicateFile.keepBoth)}
                    cancelDuplicateClicked={this.cancelDuplicateClicked} />
                {folderRoute}
                {
                    !!isShowQuestionModal &&
                    <NsMessageClassModal show={isShowQuestionModal}
                        onClose={this.closeQuestionModal}
                        messageItems={messageItems} />
                }
            </div>
        );
    }
}

export default UploadFile;