import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import NsButton from '../../../../../components/buttons/NsButton';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { categoryActionCreators } from '../../../../../redux/reducers/CategoryReducer';
import { GetProductsCirculationsService } from "../../../../../services/product/ProductService";
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import ApBaseOrderSelectModal from '../../../bases/ApBaseOrderSelectModal';
import ApBaseSearchButtons from '../../../bases/ApBaseSearchButtons';
import ApBaseUserSelectModal from '../../../bases/ApBaseUserSelectModal';

const ApFeedbackOrdersSearch = (props) => {

    const [showUserSelectModal, setShowUserSelectModal] = useState(false)
    const [showOrderSelectModal, setShowOrderSelectModal] = useState(false);
    const [user, setUser] = useState(props.customerDetails?.phoneNumber || '');
    const [selectedOrder, setSelectedOrder] = useState('');
    const [selectedSharedSheetOrderIds, setSelectedSharedSheetOrderIds] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsId, setSelectedProductsId] = useState([]);
    const [selectedCirculations, setSelectedCirculations] = useState([]);
    const [displayToAll, setDisplayToAll] = useState([]);
    const [averageRateFrom, setAverageRateFrom] = useState([]);
    const [averageRateTo, setAverageRateTo] = useState([]);
    const [submitTimeFrom, setSubmitTimeFrom] = useState(null);
    const [submitTimeTo, setSubmitTimeTo] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [selectedUser, setSelectedUser] = useState(props.customerDetails || '');
    const [demandService, setDemandService] = useState(null);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [productCategories, setProductCategories] = useState([]);
    const [resultMessageClass, setResultMessageClass] = useState('');

    const dispatch = useDispatch();

    const handleCategories = () => dispatch(categoryActionCreators.getCategories());
    const showOrderSelectModalToggle = () => setShowOrderSelectModal(!showOrderSelectModal);

    useEffect(() => {
        handleCategories();
    }, []);

    const categories = useSelector(state => {
        return {
            categories: state.categories.categories
        }
    });

    useEffect(() => {
        !isEmpty(selectedUser) && searchClicked();
    }, [selectedUser])

    useEffect(() => {
        !isEmpty(selectedOrder) && searchClicked();
    }, [selectedOrder])

    useEffect(() => {
        !isEmpty(selectedProducts)
            ? setSelectedProductsId(selectedProducts.circulations.map(circulation => circulation.key))
            : setSelectedProductsId([]);
    }, [selectedProducts])

    const getProductsCirculationsCallback = (resultData, result) => {
        setIsLoading(false);
        if (!result?.data?.hasError) {
            setProducts(resultData);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        GetProductsCirculationsService(null, getProductsCirculationsCallback);
    }, [])

    const usersModalButtonClicked = () => {
        setShowUserSelectModal(true);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptionsInMobile = [
        !props.customerDetails?.phoneNumber && hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            onChange: (value) => setUser(value),
            endAdornment: !props.customerDetails?.phoneNumber ? <NsButton className='light-circle'
                loading={isLoading}
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" /> : null
        },
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.order'),
            fieldName: 'selectedOrder',
            value: selectedOrder,
            disabled: true,
            endAdornment: <NsButton className='light-circle'
                onClick={showOrderSelectModalToggle}
                startIcon="ns-icon-more" />
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('profile.allOrders.productCategory'),
            fieldName: 'productCategories',
            value: productCategories,
            items: categories.categories || [],
            onChange: (value) => setProductCategories(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.product'),
            fieldName: 'selectedProducts',
            value: selectedProducts,
            items: !isEmpty(products) ? products : [],
            onChange: (value) => {
                setSelectedProducts(value)
                if (showMore) {
                    let selectedProductsId = []
                    value?.circulations?.map(circulation => {
                        selectedProductsId.push(circulation?.key)
                    })
                    setSelectedProductsId(selectedProductsId)
                }
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.averageRateFrom'),
            fieldName: 'averageRateFrom',
            value: averageRateFrom,
            items: ConstantsWithTranslation.getFeedbackRates(),
            onChange: (value) => setAverageRateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.averageRateTo'),
            fieldName: 'averageRateTo',
            value: averageRateTo,
            items: ConstantsWithTranslation.getFeedbackRates(),
            onChange: (value) => setAverageRateTo(value)
        },
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.feedback.demandService'),
            fieldName: 'demandService',
            value: demandService,
            items: ConstantsWithTranslation.getFeedbackDemandServiceType(),
            onChange: (value) => setDemandService(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeFrom'),
            fieldName: 'submitTimeFrom',
            value: submitTimeFrom,
            onChange: (value) => setSubmitTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeTo'),
            fieldName: 'submitTimeTo',
            value: submitTimeTo,
            onChange: (value) => setSubmitTimeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.dataGridColumn.displaytoAll'),
            fieldName: 'displayToAll',
            value: displayToAll,
            items: ConstantsWithTranslation.getUseFileInOrderOptions(),
            onChange: (value) => setDisplayToAll(value)
        },
    ];

    const searchValues = {
        user,
        selectedUser,
        selectedOrder,
        selectedSharedSheetOrderIds,
        products,
        selectedProducts,
        selectedProductsId,
        selectedCirculations,
        averageRateFrom,
        averageRateTo,
        submitTimeFrom,
        submitTimeTo,
        demandService,
        productCategories,
        displayToAll,
    }

    const showMoreClicked = () => setShowMore(!showMore);

    const searchClicked = () => CommonHelper.safeFunctionCall(props.setSearchData, searchValues);

    const clearButtonClicked = () => {
        setUser('');
        setSelectedUser('');
        setSelectedOrder('');
        setSelectedSharedSheetOrderIds([]);
        setSelectedProducts([]);
        setSelectedProductsId([]);
        setAverageRateFrom([]);
        setAverageRateTo([]);
        setSelectedCirculations([]);
        setProductCategories([]);
        setSubmitTimeFrom(null);
        setSubmitTimeTo(null);
        setDemandService(null);
        setDisplayToAll(null);
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user?.phoneNumber);
        setShowUserSelectModal(false);
    }

    const orderSelected = (order) => {
        setSelectedOrder(order?.productName);
        setSelectedSharedSheetOrderIds([order?.id]);
        showOrderSelectModalToggle();
    };

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={showMore
                    ? advanceSearchOptions
                    : CommonHelper.isMobileDevice()
                        ? basicSearchOptionsInMobile
                        : basicSearchOptions}
                    searchValues={searchValues} mdCol={2} smCol={4} />
                <ApBaseSearchButtons searchClicked={searchClicked}
                    showMoreHandler={showMoreClicked}
                    showMore={showMore}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={props.isLoading} />
            </div>

            {showUserSelectModal &&
                <ApBaseUserSelectModal showModal={showUserSelectModal}
                    userSelected={userSelected}
                    closeModal={() => setShowUserSelectModal(false)} />
            }
            {showOrderSelectModal &&
                <ApBaseOrderSelectModal showModal={showOrderSelectModal}
                    orderSelected={orderSelected}
                    closeModal={() => setShowOrderSelectModal()} />
            }

            {resultMessageClass}
        </>
    )
}

export default React.memo(ApFeedbackOrdersSearch);