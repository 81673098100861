import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import NsCard from '../../../components/cards/NsCard';
import NsCollapse from '../../../components/dropdowns/NsCollapse';
import NsSkeleton from '../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../components/text/NsTypography';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import { SidebarCategoriesCreator } from '../../../redux/reducers/SidebarCategoriesReducer';

class SpSidebarMenu extends React.Component {

    componentDidMount = () => isEmpty(this.props.sidebarMenus.sidebarCategories) && this.props.getCategoriesSideBar();

    createSubMenus = (menu, currentPageId) => {
        return menu.map(subMenu => subMenu.isActive &&
            <Link key={subMenu.id}
                className={`d-flex btn mb-1 p-0 ${!subMenu.isEnabled && 'disabled'}`}
                to={getUrlWithCurrentCulture(Links, subMenu.link)}>
                <span className={currentPageId === subMenu.code ? 'red-color' : ''}>
                    {subMenu.latinName}
                </span>
            </Link>
        )
    }

    render() {
        const currentPageId = this.props.currentPageId === Enumerations.breadCrumbs.downloadDetail ? Enumerations.breadCrumbs.downloadCenter : this.props.currentPageId;
        return (
            <NsCard className='p-3 sidebar__menu'>
                <div className='mb-2'>
                    <NsTypography variant='inherit' className='section-title'>
                        {getTranslatedRawText('footer.relatedPage')}
                    </NsTypography>
                </div>
                {!isEmpty(this.props.sidebarMenus)
                    ? this.props.sidebarMenus.map((menu, index) => <NsCollapse key={index} className='mb-2'
                        defaultIsOpen={!isEmpty(menu.key.filter(subMenu => subMenu.code == currentPageId))}
                        titleClassName='text-color fw-bold w-100'
                        title={menu.value} >

                        {this.createSubMenus(menu.key, currentPageId)}

                    </NsCollapse>)
                    : <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                        ]} />
                }

            </NsCard>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sidebarMenus: state.sidebarCategories.sidebarCategories,
        currentPageId: state.breadcrumbs[state.breadcrumbs.length - 1]?.id,
    }
}

const mapDispachToProps = (dispatch) => {
    return bindActionCreators(SidebarCategoriesCreator, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(SpSidebarMenu);