import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ChangePasswordService, SendVerificationCodeForResetPasswordService } from '../../../services/accounts/AccountService';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import NsCountDownButton from '../../../components/buttons/NsCountDownButton';
import NsActivationInput from '../../../components/inputs/NsActivationInput';
import NsPasswordInput from '../../../components/inputs/NsPasswordInput';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsTypography from '../../../components/text/NsTypography';
import NsButton from '../../../components/buttons/NsButton';
import Enumerations from '../../../configs/Enumerations';
import BaseCaptchaModal from '../bases/BaseCaptchaModal';
import Links from '../../../configs/links/AllLinks';
import AccountWrapper from '../AccountWrapper';
import NsSetPageTitleAndDescription from '../../../components/metaTags/NsSetPageTitleAndDescription';

class ChangeForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            code: '',
            isValidCode: false,
            password: '',
            isValidPassword: false,
            confirmPassword: '',
            isValidConfirmPassword: false,
            showValidationDate: null,
            showMatchValidationDate: null,
            showCaptchaModal: false,
            captchaMessage: '',
            isLoading: false,
            countDownTime: 0,
            resultMessageClass: (<></>)
        };
    }

    componentDidMount = () => {
        if (!CurrentUserHelper.getMobile() || !CurrentUserHelper.getCountryId())
            this.props.history.push(getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn));
        else
            !window.history.state?.state?.totalRemainingTimeInSecond
                ? this.resendCodeClicked()
                : this.setState({ countDownTime: window.history.state?.state?.totalRemainingTimeInSecond });
        window.history.replaceState({ totalRemainingTimeInSecond: 0 }, 'aaa');
    }

    keyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter && !this.state.showCaptchaModal) {
            event.preventDefault();
            this.changePasswordClicked();
        }
    }

    codeChanged = (value, isValid) => this.setState({ code: value, isValidCode: isValid });

    passwordChanged = (value, isValid) => this.setState({ password: value, isValidPassword: isValid });

    confirmPasswordChanged = (value, isValid) => this.setState({ confirmPassword: value, isValidConfirmPassword: isValid });

    resendCodeClicked = (captchaId, captchaCode) => {
        let data = {
            countryCode: CurrentUserHelper.getCountryCode(),
            mobileWithOutCountryCode: CurrentUserHelper.getMobileWithOutCountryCode(),
        };
        if (!!captchaId && !!captchaCode)
            data = { ...data, captchaCode, captchaId };
        this.setState({ isLoading: true });
        SendVerificationCodeForResetPasswordService(data, this.resendVerificationCodeCallback);
    }

    changePasswordClicked = () => {
        if (!this.state.isValidCode ||
            !this.state.isValidPassword ||
            !this.state.isValidConfirmPassword) {
            this.setState({
                showValidationDate: new Date(),
            });
            return false;
        }
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({ showMatchValidationDate: new Date() });
        } else {
            let data = {
                Mobile: CurrentUserHelper.getMobile().replace(/ /g, '').substr(1),
                verificationCode: this.state.code.replace(/-/g, '').replace(/ /g, ''),
                password: this.state.password,
                confirmPassword: this.state.confirmPassword,
            }
            this.setState({ isLoading: true });
            ChangePasswordService(data, this.changePasswordCallback);
        }
    }

    changePasswordCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            this.props.history.push(getUrlWithCurrentCulture(Links.view.account.forgotPassword, Links.view.account.forgotPassword.success));
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    resendVerificationCodeCallback = (resultData, result, messageClassModal) => {
        if (result?.data?.hasError || result.data?.messageItems[0]?.messageType == Enumerations.messageType.warning)
            this.setState({
                resultMessageClass: messageClassModal,
                showCaptchaModal: result.data.messageItems.some(item => item?.messageType === Enumerations.messageType.needCaptcha),
                captchaMessage: result.data.messageItems.find(item => item?.messageType === Enumerations.messageType.needCaptcha)?.messageText
            });
        this.setState({
            isLoading: false,
            countDownTime: resultData?.totalRemainingTimeInSecond || 0
        });
    }

    closeCaptchaModal = (captchaId, captchaCode, confirm) => {
        this.setState({ showCaptchaModal: false });
        !!confirm && this.resendCodeClicked(captchaId, captchaCode);
    }

    render() {
        const { countDownTime, showCaptchaModal, captchaMessage } = this.state;
        return (
            <AccountWrapper layoutType={Enumerations.loginLayoutType.signIn} >

                <NsSetPageTitleAndDescription pageTitle='common.breadcrumbs.changePassword' />
                
                <Form onKeyDown={this.keyDown}>
                    <div className="white-panel g-0 d-flex flex-column my-sm-n5 w-100 p-4">
                        <h1 className='section-title'>
                            {getTranslatedRawText("account.forgotPassword.forgotPasswordChangeTitle")}
                        </h1>
                        <NsTypography variant='inherit' className='primary-paragraph'>
                            {getTranslatedRawText("account.forgotPassword.forgotPasswordChangeDescription")}
                        </NsTypography>
                        <div className='d-flex align-items-baseline mt-2'>
                            <NsTypography variant='inherit' className='light-paragraph'>
                                {getTranslatedRawText("account.common.mobile")}
                            </NsTypography>

                            <NsTypography variant='inherit' className='light-paragraph not-rtl'>
                                : {CurrentUserHelper.getMobile()}
                            </NsTypography>

                            <Link className='font-size-14 ms-2 p-2'
                                to={getUrlWithCurrentCulture(Links.view.account.forgotPassword, Links.view.account.forgotPassword.mobile)} >
                                {getTranslatedRawText("common.edit")}
                            </Link>

                        </div>
                        <div className="mt-4 mb-3 w-100">
                            <NsActivationInput
                                codeChanged={this.codeChanged}
                                showValidationDate={this.state.showValidationDate}
                                mask={Enumerations.mask.validationCode}
                                onChange={this.changeVerifyCode}
                                disabled={false}
                                maskChar=" "
                                showMask
                                label={getTranslatedRawText("components.verificationInput.verifyMaskLabel")}
                            />
                        </div>
                        <div className="w-100 mb-3">
                            <NsPasswordInput label={getTranslatedRawText("components.passwordInput.password")}

                                passwordChanged={this.passwordChanged}
                                showMatchValidationDate={''}
                                className="w-100"
                                showValidationDate={this.state.showValidationDate} />
                        </div>
                        <div className="w-100 mb-3">
                            <NsPasswordInput label={getTranslatedRawText("components.passwordInput.confirmPassword")}
                                passwordChanged={this.confirmPasswordChanged}
                                className="w-100"
                                showMatchValidationDate={this.state.showMatchValidationDate}
                                showValidationDate={this.state.showValidationDate} />
                        </div>
                        <div className="d-flex mx-auto mt-5">
                            <NsButton className="primary"
                                onClick={this.changePasswordClicked}
                                startIcon='ns-icon-change'
                                loading={this.state.isLoading}
                                label="account.forgotPassword.changePassword" />
                        </div>
                        <div className='w-100'></div>
                        <div className="d-flex mx-auto text-center my-2">
                            <NsCountDownButton variant="contained"
                                label={getTranslatedRawText("account.forgotPassword.resendCode")}
                                time={{ min: Math.floor(countDownTime / 60), second: countDownTime % 60 }}
                                loading={this.state.isLoading}
                                onClick={this.resendCodeClicked}
                            />
                        </div>
                        {showCaptchaModal &&
                            <BaseCaptchaModal closeModal={this.closeCaptchaModal}
                                captchaMessage={captchaMessage} />
                        }
                        {this.state.resultMessageClass}
                    </div>
                </Form >
            </AccountWrapper>
        );
    }
}

export default ChangeForgotPassword;