import React, { useEffect, useState } from "react";

import { EditSorFeedbackTypeMetricService, EditSortDemandServiceMetricService, GeFeedbackTypeByIdService, GeFeedbackTypeMetricsService, UpdateFeedbackTypeStateService } from "../../../../services/adminPanel/AdminFeedbackService";
import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import ApBaseMapActionButtons from "../../bases/ApBaseMapActionButtons";
import { feedbackMetricColumns } from "../bases/ApBasesFeedbackHelper";
import ApFeedbackTypeMetricModal from "./ApFeedbackTypeMetricModal";
import NsButton from "../../../../components/buttons/NsButton";
import NsModal from "../../../../components/modals/NsModal";
import Enumerations from "../../../../configs/Enumerations";
import NsTab from "../../../../components/tabs/NsTab";
import NsTooltip from "../../../../components/tooltips/NsTooltip";
import NsImage from "../../../../components/images/NsImage";
//guidance images
import rateImg from '../../../../assets/images/adminPanel/feedbackSettingGuidance/Rate.png'
import extraSupportImg from '../../../../assets/images/adminPanel/feedbackSettingGuidance/Extra Support.png'
import strengthsImg from '../../../../assets/images/adminPanel/feedbackSettingGuidance/Strengths.png'
import weaknessesImg from '../../../../assets/images/adminPanel/feedbackSettingGuidance/Weaknesses.png'
import CommonHelper from "../../../../utilities/CommonHelper";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import NsBasicInfoLabelBox from "../../../../components/labels/NsBasicInfoLabelBox";
import { getFeedbackTypeBasicInfos } from "../../../../utilities/BasicInfoHelper";

const ApFeedbackTypeDetailModal = (props) => {

    const { closeModal, selectedFeedbackTypeId } = props;
    const tabs = ConstantsWithTranslation.getFeedbackMetricTypeTabs();
    const [feedbackTypeInfo, setFeedbackTypeInfo] = useState(null);
    const [metrics, setMetrics] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [selectedfeedbackTypeMetric, setSelectedfeedbackTypeMetric] = useState();
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [showFeedbackTypeMetricModal, setShowFeedbackTypeMetricModal] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getFeedbackTypeById();
    }, [])

    useEffect(() => {
        setCallServiceDate(new Date());
    }, [selectedTabIndex])

    const updateFeedbackTypeStateCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        getFeedbackTypeById();
        setResultMessageClass(resultMessageClass);
    }

    const updateFeedbackTypeState = () => {
        setIsLoading(true);
        UpdateFeedbackTypeStateService(selectedFeedbackTypeId, updateFeedbackTypeStateCallback);
    }

    const geFeedbackTypeByIdCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result.data.hasError) setFeedbackTypeInfo(resultData);
        else setResultMessageClass(resultMessageClass);
    }

    const getFeedbackTypeById = () => {
        setIsLoading(true);
        GeFeedbackTypeByIdService(selectedFeedbackTypeId, geFeedbackTypeByIdCallback);
    }

    const tabChanged = (newValue, selectedTab) => {
        if (newValue !== selectedTabIndex) {
            setSelectedTabIndex(newValue);
            setSelectedTab(selectedTab);
        }
    }

    const newMetricClicked = () => setShowFeedbackTypeMetricModal(true);

    const sortArrowClicked = (selectedFeedbackMetric, isUpArrow) => {
        const index = metrics.findIndex(metric => {
            return metric.id == selectedFeedbackMetric.id;
        });
        if ((index == 0 && isUpArrow) || (index == metrics.length - 1 && !isUpArrow)) return;
        setIsLoading(true);
        let parameters = {
            movedContentId: selectedFeedbackMetric.id,
            mustBeChangeContentId: (metrics[isUpArrow ? index - 1 : index + 1])?.id
        };
        selectedTab?.id === Enumerations.feedbackMetricTypes.demandService
            ? EditSortDemandServiceMetricService(parameters, editSorFeedbackTypeMetricCallback)
            : EditSorFeedbackTypeMetricService(parameters, editSorFeedbackTypeMetricCallback);
    };

    const editSorFeedbackTypeMetricCallback = (resultData, result, resultMessageClass) => {
        if (result.data.hasError) {
            setIsLoading(false);
            setResultMessageClass(resultMessageClass);
        } else
            setCallServiceDate(new Date());
    };

    const showFeedbackTypeMetricModalClicked = (id) => {
        setSelectedfeedbackTypeMetric(id);
        setShowFeedbackTypeMetricModal(true);
    };

    const closeFeedbackTypeMetricModalClicked = (resultMessageClass) => {
        setShowFeedbackTypeMetricModal(false);
        setResultMessageClass(resultMessageClass);
        setSelectedfeedbackTypeMetric(null);
        setCallServiceDate(new Date());
    }

    const getData = (data, isLoading) => {
        setMetrics(data);
        setIsLoading(isLoading);
    }

    const GuidanceImageObj = (img, feedbackTypeName) => ({
        img,
        alt: CommonHelper.stringFormat(
            getTranslatedRawText('components.imagesAlt.guidance'),
            getTranslatedRawText(`adminPanel.feedback.${feedbackTypeName}`)
        )
    });

    const guidanceImagesHandler = () => {
        switch (selectedTab.id) {
            case Enumerations.feedbackMetricTypes.ratingparamenter:
                return GuidanceImageObj(rateImg, 'ratingParameter')
                break;
            case Enumerations.feedbackMetricTypes.demandService:
                return GuidanceImageObj(extraSupportImg, 'demandService')
                break;
            case Enumerations.feedbackMetricTypes.strengths:
                return GuidanceImageObj(strengthsImg, 'strengths')
                break;
            case Enumerations.feedbackMetricTypes.weaknesses:
                return GuidanceImageObj(weaknessesImg, 'weaknesses')
                break;
            default:
                return GuidanceImageObj(rateImg, 'ratingParameter')
        }
    }

    const headerButtons = [
        hasAccess([UserClaimEnumerations.updateApFeedbackTypeState]) && { wrapperClassName: 'ms-1', label: !!feedbackTypeInfo?.isActive ? 'adminPanel.orders.deactivate' : 'adminPanel.orders.setActive', onClick: updateFeedbackTypeState },
    ]

    const addButtonAccess = hasAccess([selectedTab.id == Enumerations.feedbackMetricTypes.demandService
        ? UserClaimEnumerations.postApFeedbackTypeDemandService
        : UserClaimEnumerations.postApFeedbackTypeMetric]);

    return (
        <NsModal maxWidth='md'
            showModal={true}
            dialogTitle={getTranslatedRawText('adminPanel.feedback.metricsDetails')}
            noSpace={true}
            closeModal={closeModal}>
            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />

            <div className='background-color px-3 py-2'>
                <NsBasicInfoLabelBox basicInfo={getFeedbackTypeBasicInfos(feedbackTypeInfo)} isLoading={isLoading} hasAccessInfo={true} />
            </div>
            <div className="px-3 pb-3">

                <NsTab tabs={tabs}
                    value={selectedTabIndex}
                    onChange={tabChanged} />
                {addButtonAccess &&
                    <div className="d-flex justify-content-end mb-2 align-items-center gap-2">
                        <NsTooltip
                            title={
                                <NsImage
                                    src={guidanceImagesHandler().img}
                                    width={'140'}
                                    imgAlt={guidanceImagesHandler().alt}
                                />
                            }
                        >
                            <i className='ns-icon-question fs-5' />
                        </NsTooltip>

                        <NsButton label='common.add'
                            className='primary'
                            startIcon='ns-icon-add'
                            onClick={newMetricClicked} />
                    </div>
                }
                <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                    service={hasAccess([UserClaimEnumerations.getApFeedbackTypeMetrics]) && GeFeedbackTypeMetricsService}
                    parameters={{
                        id: selectedFeedbackTypeId,
                        feedbackTypeMetricType: selectedTab.id
                    }}
                    columnDefs={feedbackMetricColumns(showFeedbackTypeMetricModalClicked, sortArrowClicked, isLoading, selectedTab.id == Enumerations.feedbackMetricTypes.demandService)}
                    getData={getData}
                    hasPagination={true} />
            </div>
            {showFeedbackTypeMetricModal &&
                <ApFeedbackTypeMetricModal selectedfeedbackTypeMetric={selectedfeedbackTypeMetric}
                    feedbackTypeMetricTypeId={selectedTab.id}
                    feedbackTypeId={selectedFeedbackTypeId}
                    selectedTab={selectedTab}
                    closeModal={closeFeedbackTypeMetricModalClicked} />}

            {resultMessageClass}
        </NsModal>
    );

}

export default React.memo(ApFeedbackTypeDetailModal);