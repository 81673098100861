import React, { Component } from 'react';

import HomeBaseInstantDiscount from '../../../home/bases/HomeBaseInstantDiscount';

export default class InstantDiscounts extends Component {
    render() {
        return (
            <div className='my-4'>
                <HomeBaseInstantDiscount />
            </div>
        )
    }
}
