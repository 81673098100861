import React from 'react';
import NsImage from '../../../../components/images/NsImage';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';


class FmDetailFile extends React.Component {

    dragStarted = (ev) => {
        ev.dataTransfer.setData("text", JSON.stringify(this.props.selectedItemContent));
    }

    render() {
        const { selectedItemContent, isShowDetail, onLoad } = this.props;
        const dimension = CommonHelper.stringFormat(getTranslatedRawText('fileManager.mainSectionDetail.fileDimension'), selectedItemContent?.width, selectedItemContent?.height);
        const resolution = CommonHelper.stringFormat(getTranslatedRawText('fileManager.mainSectionDetail.fileResolution'), selectedItemContent?.originalFileVerticalResolution);
        let usedVolume = CommonHelper.convertByteToMegaByte(selectedItemContent?.volume);

        return (
            isShowDetail && <React.Fragment>
                <div className="align-self-center my-2 pe-2 w-100"
                    draggable='true' onDragStart={this.dragStarted}>
                    <NsImage className="mx-1 border my-2 w-75"
                        imageUrl={selectedItemContent?.contentUrl}
                        imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.imagePreview"), selectedItemContent?.name)}
                        onLoad={onLoad} />
                </div>
                <InfoLine label='fileManager.mainSectionDetail.fileName' info={selectedItemContent?.name} />
                <InfoLine label='fileManager.mainSectionDetail.resolution' info={resolution} />
                <InfoLine label='fileManager.mainSectionDetail.dimension' info={dimension} />
                <InfoLine label='fileManager.mainSectionDetail.fileSize' info={usedVolume + ' ' + getTranslatedRawText('common.mb')} />
            </React.Fragment>
        );
    }
}

function InfoLine(props) {
    return <div>
        <span className='text-nowrap font-size-12'>
            {getTranslatedRawText(props.label)}
        </span>
        <span className="font-size-14 word-break text-break fw-bold mx-1">
            {props.info}
        </span>
    </div>
}

export default FmDetailFile;
