import clsx from "clsx";
import Enumerations from "../../../../configs/Enumerations";
import CommonHelper from "../../../../utilities/CommonHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";

const blue = 'rgba(0, 88, 255, 1)';
const lightBlue = 'rgba(0, 88, 255, 0.15)';
const orange = 'rgba(249, 150, 0, 1)';
const lightOrange = 'rgba(249, 150, 0, 0.15)';
const yellow = 'rgba(255, 199, 0, 1)';
const lightYellow = 'rgba(255, 199, 0, 0.15)';
const green = 'rgba(33, 213, 155, 1)';
const lightGreen = 'rgba(33, 213, 155, 0.15)';
const gray = 'rgba(204, 204, 204, 1)';
const lightGray = 'rgba(204, 204, 204, 0.15)';

const blue5 = '#002F78';
const blue4 = '#0146B5';
const blue3 = '#3985FF';
const blue2 = '#A8CAFF';
const blue1 = '#D5E5FF';


class ApBasesDashboardHelper {

    static setAreaLineChartOptions = (data, tension) => {
        data.labels.length === 1 && data.labels.unshift('')
        data.datasets.map(item => {
            item.borderColor = this.getTotalSalesColor(item.chartDatasetType)[0];
            item.backgroundColor = this.getTotalSalesColor(item.chartDatasetType)[1];
            item.borderWidth = 1;
            item.pointRadius = 0;
            item.fill = true;
            tension ? item.tension = tension : item.tension = 0;
            if (item.data.length === 1) {
                item.data.unshift(0)
            }
        })
        return data;
    };

    static setLineChartOptions = (data, tension) => {
        data.datasets.map(item => {
            item.borderColor = this.getBackgroundColor(item.chartDatasetType);
            item.borderWidth = 1;
            item.pointRadius = 0;
            tension ? item.tension = tension : item.tension = 0;
        })
        return data;
    };

    static getTotalSalesColor = (id) => {
        switch (id) {
            case Enumerations.chartDatasetType.totalSales:
                return [gray, lightGray]
            case Enumerations.chartDatasetType.onlineSales:
                return [blue, lightBlue]
            default:
                break;
        }
    }

    static setDoughnutChartOptions = (data) => {
        data?.datasets.map(item => {
            item.backgroundColor = this.getOnlinePaymentGatewayColor(item.data);
        })
        return data;
    };

    static setDoughnutChartFeedbackStatus = (data) => {
        let colors = [orange, blue, green];
        data?.datasets.map((item, index) => {
            item.backgroundColor = colors;
        })
        return data;
    };

    static setDoughnutChartFeedbackStrengthAndWeekness = (data) => {
        let colors = [orange, green, gray, blue, yellow];
        data?.datasets.map((item, index) => {
            item.backgroundColor = colors;
        })
        return data;
    };

    static setDoughnutTotalSalesColor = (data) => {
        data?.datasets.map(item => {
            item.backgroundColor = this.getDoughnutTotalSalesColor(item.data);
        })
        return data;
    };

    static getDoughnutTotalSalesColor = (data) => {
        let colors = [];
        data.map(item => {
            switch (item.key) {
                case Enumerations.agencyTotalSalesAmountType.company:
                    colors.push(blue);
                    break
                case Enumerations.agencyTotalSalesAmountType.agency:
                    colors.push(yellow);
                    break
                default:
                    break;
            }
        })
        return colors;
    };

    static getOnlinePaymentGatewayColor = (data) => {
        let colors = [];
        data.map(item => {
            switch (item.key) {
                case Enumerations.paymentGateway.asanPardakht:
                    colors.push(orange);
                    break
                case Enumerations.paymentGateway.behPardakht:
                    colors.push(green);
                    break;
                case Enumerations.paymentGateway.iranKish:
                    colors.push(gray);
                    break;
                case Enumerations.paymentGateway.sadad:
                    colors.push('rgb(133, 103, 155)');
                    break;
                case Enumerations.paymentGateway.saman:
                    colors.push('rgb(73, 213, 105)');
                    break;
                case Enumerations.paymentGateway.nGeniusPayment:
                    colors.push(blue);
                    break;
                case Enumerations.paymentGateway.sepehr:
                    colors.push(yellow);
                    break;
                default:
                    break;
            }
        })
        return colors;
    };

    static setBarChartOptions = (data) => {
        data?.datasets.map(item => {
            item.backgroundColor = this.getBackgroundColor(item.chartDatasetType);
            item.borderWidth = 1;
            item.stack = 'Stack 0';
        })
        return data;
    };

    static setSingleBarChartOptions = (data) => {
        data?.datasets.map(item => {
            item.backgroundColor = this.getBackgroundColor(item.chartDatasetType);
            item.borderWidth = 1;
        })
        return data;
    };

    static getBackgroundColor = (id) => {
        switch (id) {
            case Enumerations.chartDatasetType.successPayment:
            case Enumerations.chartDatasetType.regularOrdering:
            case Enumerations.chartDatasetType.expressOrder:
            case Enumerations.chartDatasetType.orderedUser:
            case Enumerations.chartDatasetType.userFeedbackStateTypeArchived:
                return green
            case Enumerations.chartDatasetType.failedPayment:
            case Enumerations.chartDatasetType.inPersonOrdering:
            case Enumerations.chartDatasetType.normalOrder:
            case Enumerations.chartDatasetType.notAudited:
            case Enumerations.chartDatasetType.userFeedbackStateTypePending:
                return orange
            case Enumerations.chartDatasetType.unknownPayment:
            case Enumerations.chartDatasetType.others:
                return gray
            case Enumerations.chartDatasetType.professionalOrdering:
            case Enumerations.chartDatasetType.newUser:
            case Enumerations.chartDatasetType.audited:
            case Enumerations.chartDatasetType.userFeedbackStateTypeInProgress:
                return blue
            default:
                break;
        }
    };

    static setRatingParameterChartOptions = (data) => {
        data?.datasets.map((item, index) => {
            item.backgroundColor = this.getRatingParameterBackgroundColor(index);
            item.borderWidth = 1;
            item.borderRadius = 5;
        })
        return data;
    };

    static getRatingParameterBackgroundColor = (id) => {
        switch (id) {
            case 5: return blue5;
            case 4: return blue4;
            case 3: return blue3;
            case 2: return blue2;
            case 1: return blue1;
            default:
                break;
        }
    };

    static setCashbackSpecialChartOptions = (data) => {
        data?.datasets.map((item, index) => {
            item.backgroundColor = this.getCashbackSpecialChartBackgroundColor(index + 1);
            item.borderWidth = 1;
            item.borderRadius = 5;
        })
        return data;
    };
    static getCashbackSpecialChartBackgroundColor = (id) => {
        switch (id) {
            case 3: return blue;
            case 2: return green;
            case 1: return orange;
            default:
                break;
        }
    };

    static setCashbackPlansChartOptions = (data) => {
        !isEmpty(data.labels) && data?.datasets.map(item => {
            item.backgroundColor = blue;
            item.borderWidth = 1;
        })
        return data;
    };

}

export const CustomGrowthField = (props) => {
    const { number, percent, isPercentage } = props;
    return <span className='not-rtl'>
        {CommonHelper.currencyFormatByDefaultConfig(number, true)}
        {isPercentage && '%'}
        &nbsp;
        <span style={{ width: '50px' }} className={clsx('d-inline-block', CommonHelper.getActivationStatusColor(percent > 0))}>
            {!!props.percent &&
                <>
                    {Math.abs(percent)} %
                    {CommonHelper.getGrowthPercentageIcon(percent)}
                </>
            }
        </span>
    </span>
}

export default ApBasesDashboardHelper;