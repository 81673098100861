import React from 'react';

import { getConfigObjectByCulture } from '../../../utilities/ConfigHelper';
import NsHtmlToReact from '../../../components/htmlToReact/NsHtmlToReact';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import { isEmpty } from '../../../utilities/ValidationHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import Config from '../../../configs/Config';

class OfficialInvoiceRulesModal extends React.Component {

    closeModal = () => CommonHelper.safeFunctionCall(this.props.closeModal);

    render() {
        const mainButtons = [
            { label: "common.ok", onClicked: this.closeModal, className: 'primary' }
        ];

        return (
            <NsModal
                dialogTitle={getTranslatedRawText('profile.orderCart.vatRules')}
                showModal={true}
                closeModal={this.props?.closeModal}
                actions={<NsStickyButton stickyButtons={mainButtons} />} >

                <OfficialInvoiceRulesContent />

            </NsModal >
        )
    }
}

export const OfficialInvoiceRulesContent = () => {

    const rules = getConfigObjectByCulture(Config.orders);

    return <div className='border rounded p-3 m-2'>
        <ul style={{ listStyleType: "disc" }}>
            {!isEmpty(rules) && rules.map((rule, index) =>
                <li key={index} className='mb-2'>
                    <NsHtmlToReact>
                        {rule}
                    </NsHtmlToReact>
                </li>
            )}
        </ul>
    </div>
}

export default OfficialInvoiceRulesModal;