import React, { Component } from 'react';

import { PostInPersonDeliveryService } from '../../../../../services/ProfileSetting/DeliveryAddressSettingService';
import NsLoadingProgress from '../../../../../components/circularProgress/NsLoadingProgress';
import NsToggleGroupButton from '../../../../../components/buttons/NsToggleGroupButton';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import Enumerations from '../../../../../configs/Enumerations';

class UpBaseProfileInPersonShipping extends Component {

    constructor(props) {
        super(props);
        this.groupButtons = ConstantsWithTranslation.getKarajTehranSwitch();
        this.mainButtons = [
            { label: "common.save", icon: 'ns-icon-save', onClicked: this.saveClicked, className: 'primary', disabled: false },
        ];

        this.state = {
            isLoading: false,
            isKarajInPersonDelivery: this.props.isKarajInPersonDelivery,
            resultMessageClass: (<></>)
        }
    }

    autoDeliveryClicked = (value) => {
        this.setState({
            isKarajInPersonDelivery: value.key == Enumerations.city.karaj
                ? true
                : false
        })
    }

    saveClicked = () => {
        this.setState({ isLoading: true })
        PostInPersonDeliveryService({ autoSending: this.state.isKarajInPersonDelivery }, this.postInPersonDeliveryCallback, true);
    }

    postInPersonDeliveryCallback = (resultData, result, messageClassModal) => this.setState({ isLoading: false, resultMessageClass: messageClassModal });

    render() {
        const selectedGroupButton = !!this.state.isKarajInPersonDelivery
            ? this.groupButtons[1].key
            : this.groupButtons[0].key;
        return (
            <>
                <NsLoadingProgress visible={this.state.isLoading} />
                <div className='d-flex mb-3'>
                    <NsToggleGroupButton isShowGroupLabel={false}
                        onClick={this.autoDeliveryClicked}
                        selectedItemId={selectedGroupButton}
                        items={this.groupButtons} />
                </div>
                <NsStickyButton stickyButtons={this.mainButtons} />
                {this.state.resultMessageClass}
            </>
        )
    }
}

export default UpBaseProfileInPersonShipping;