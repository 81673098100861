import React, { Component } from 'react';

import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsCollapse from '../../../../components/dropdowns/NsCollapse';
import NsHtmlToReact from '../../../../components/htmlToReact/NsHtmlToReact';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../components/text/NsTypography';
import Enumerations from '../../../../configs/Enumerations';
import { GetCmsSinglePageService } from '../../../../services/singlePages/CmsService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import SinglePage from '../../SinglePage';
import NsReachResult from '../../../../components/metaTags/NsReachResult';
import { Col, Row } from 'react-bootstrap';

class FrequentlyAskedQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            faqs: [],
            isLoading: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => { this.getContent(); }

    getContent = () => {
        this.setState({ isLoading: true });
        GetCmsSinglePageService(Enumerations.staticPageType.faqs, this.GetCmsFaqsCallback);
    }

    GetCmsFaqsCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ faqs: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        const { faqs, isLoading, resultMessageClass } = this.state;
        return (
            <SinglePage >

                <NsPageMainContentWrapper header={getTranslatedRawText('singlePage.faqs.frequentlyAskedQuestions')}
                    icon=' ns-icon-frequently-question h1' className='mb-3'>
                    <div className='d-flex flex-column font-size-14'>
                        <NsReachResult faqs={faqs} reachResultType={Enumerations.reachResultType.faq} />
                        <NsTypography variant='inherit' className='mb-3'>
                            {getTranslatedRawText('singlePage.faqs.description')}
                        </NsTypography>
                        {isLoading
                            ? <NsSkeleton isVisible={true}
                                variant={[
                                    Enumerations.skeleton.text,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                ]} />
                            : <Row >
                                <FAQList FAQArray={faqs?.staticPages}
                                    childClassName='p-3 mb-2' />
                            </Row>
                        }
                    </div>
                    {resultMessageClass}

                </NsPageMainContentWrapper>

            </SinglePage >
        );
    }
}

export function FAQList(props) {
    const { FAQArray, widthCol, childClassName } = props;
    const collapsibleFAQList = !isEmpty(FAQArray)
        ? FAQArray?.map(item => (
            <Col key={item.id} md={widthCol ? widthCol : 12} >
                <div className='background-element-color rounded'>
                    <NsCollapse showChildrenForSeo={true}
                        className={childClassName}
                        titleClassName='font-size-16 fw-bold'
                        title={item?.title} >
                        <div className='d-block text-justify px-3 pb-2'>
                            <NsHtmlToReact>
                                {item?.mainContent}
                            </NsHtmlToReact>
                        </div>
                    </NsCollapse>
                </div>
            </Col>
        ))
        : <NsTypography variant='inherit' className='section-title'>
            {getTranslatedRawText('singlePage.dataNotFound')}
        </NsTypography>;
    return collapsibleFAQList;
}

export default FrequentlyAskedQuestions;