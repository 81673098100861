import { CancelRequest } from "./BaseService";

class SingletonCallService {

    constructor(method, callback) {
        this.method = method;
        this.callback = callback;
        this.cancelationGetFileFolderReference = null;
    }

    run = (parameters) => {

        if (this.cancelationGetFileFolderReference != null)
            CancelRequest(this.cancelationGetFileFolderReference);

        this.cancelationGetFileFolderReference = this.method(parameters, this.callback);
    }
}

export default SingletonCallService;