import React, { Component } from 'react'
import clsx from 'clsx';
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import NsTypography from '../text/NsTypography';
import '../../assets/sass/components/cards/NsCard.scss';

class NsCard extends Component {
	render() {
		const { imgSource, hasMedia, imgTitle, imgCaption, children, className, actionAreaClassName, contentClassName, madiaClassName } = this.props;
		return (
			<Card className={clsx("ns-card", className)}>
				{hasMedia &&
					<CardActionArea className={actionAreaClassName}>
						<CardMedia
							className={madiaClassName}
							image={imgSource}
							title={imgTitle} />
						<NsTypography>
							{imgCaption}
						</NsTypography>
					</CardActionArea>
				}
				<CardContent className={clsx("p-0", contentClassName)}>
					{children}
				</CardContent>
			</Card>
		);
	}
}

export default NsCard;