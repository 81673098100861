import clsx from 'clsx';
import React from 'react';

import { getCurrentCulture, getTranslatedRawText } from '../../utilities/CultureHelper';
import ImageHelper from '../../utilities/ImageHelper';
import CommonHelper from '../../utilities/CommonHelper';
import NsImage from './NsImage';
import { getEnumKeyFromValue } from '../../utilities/EnumerationHelper';
import Enumerations from '../../configs/Enumerations';
import imgNotFoundEn from '../../assets/images/not-found-img-en.png';
import imgNotFoundFa from '../../assets/images/not-found-img-fa.png';

class NsImageScaledView extends React.Component {

    constructor(props) {
        super(props);
        this.defaultImgSrc = getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en)
            ? props.showDefaultImage ? imgNotFoundEn : ''
            : props.showDefaultImage ? imgNotFoundFa : '';
        this.state = {
            imgWidth: '0',
            imgHeight: '0',
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps) => ImageHelper.getResolutionAndDimension(newProps.src, this.getResolutionAndDimensionCallback);

    onClick = () => CommonHelper.safeFunctionCall(this.props.onClick);

    getResolutionAndDimensionCallback = (imageInfo) => this.setState({ imgWidth: imageInfo.width, imgHeight: imageInfo.height });

    componentDidMount = () => {
        const imgSrc = this.props.src || this.props.src || this.defaultImgSrc;
        ImageHelper.getResolutionAndDimension(imgSrc, this.getResolutionAndDimensionCallback);
    }

    render() {
        const { src, showDefaultImage, imageUrl, imgAlt, width, height, className } = this.props
        const scaleWidth = ImageHelper.getImageAppliedWidth(this.state.imgWidth + '', this.state.imgHeight + '');
        const scaleHeight = ImageHelper.getImageAppliedHeight(this.state.imgWidth + '', this.state.imgHeight + '');
        const imgSrc = src || this.defaultImgSrc;
        const alt = getTranslatedRawText(imgAlt) || getTranslatedRawText("components.imagesAlt.uploadedImage");
        return (

            <div className={clsx("d-flex flex-column justify-content-center align-items-center",
                className)}
                style={{ 'width': width, "height": height }}
                onClick={this.onClick}>

                {imageUrl
                    ? <NsImage imageUrl={imageUrl}
                        imgAlt={this.props.alt ? this.props.alt : alt}
                        width={scaleWidth >= scaleHeight ? scaleWidth : ''}
                        height={scaleWidth <= scaleHeight ? scaleHeight : ''} />
                    :
                    <img src={imgSrc}
                        alt={getTranslatedRawText("components.imagesAlt.uploadedImage")}
                        width={scaleWidth >= scaleHeight ? scaleWidth : ''}
                        height={scaleWidth <= scaleHeight ? scaleHeight : ''} />
                }
            </div>

        );
    }
}

export default NsImageScaledView;