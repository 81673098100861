import Links from "../../configs/links/AllLinks"
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest, PatchRequest } from "../bases/BaseService"

const GetPromoteContentService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPromoteContent), id), {}, callback);
};

const EditPromoteContentService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApPromoteContent), id), data, callback, showSuccessResponseMessage);
};

export {
    GetPromoteContentService,
    EditPromoteContentService,
}