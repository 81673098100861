import React from 'react';

import NsStepperWithActionButtons from '../../../../../components/stepper/NsStepperWithActionButtons';
import RegularOrderRouteHelper from '../../bases/RegularOrderRouteHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import OrderHelper from '../../../../../utilities/OrderHelper';
import MainContentWrapper from '../MainContentWrapper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../utilities/CultureHelper';
import Links from '../../../../../configs/links/AllLinks';
import { messageClass } from '../../../../../utilities/MessageModalHelper';

class ExtendedServiceWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.routeId = props.routeId,
            this.state = {
                currentExtendedServiceIndex: this.findCurrentStepIndex(props.currentExtendedService),
                stepList: [],
                resultMessageClass: (<></>)
            };
    }

    findCurrentStepIndex = (currentExtendedService) => {
        let services = OrderHelper.getExtendedServices(this.props.routeId);
        let currentServiceIndex = 0;
        !isEmpty(services) && services.map((service, index) => {
            if (service.extendedServiceType === currentExtendedService)
                currentServiceIndex = index;
            return true;
        });
        return currentServiceIndex;
    }

    componentDidMount = () => this.createStepList(this.props.isSelectedRequirements);

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isSelectedRequirements !== this.props.isSelectedRequirements)
            this.createStepList(newProps.isSelectedRequirements);
    }

    createStepList = (isSelectedRequirements) => {
        let stepList = OrderHelper.getExtendedServices(this.routeId)?.map((service, index) => {
            return (
                {
                    title: OrderHelper.getExtendedServicesName(service.extendedServiceType),
                    serviceType: service.extendedServiceType,
                    buttons: this.createButtons(index, service.extendedServiceType, isSelectedRequirements)
                }
            );
        });

        this.setState({ stepList });
    }

    createButtons = (index, serviceType, isSelectedRequirements) => {
        let buttons = [];
        buttons.push(
            {
                visible: true,
                className: 'primary-outline',
                label: index === 0 ? 'common.label.backToSelectFile' : 'common.label.back',
                icon: CommonHelper.getBackIconByCulture(),
                disabled: false,
                type: Enumerations.stepperButtonType.previous,
                validation: this.stepprButtonValidation,
                event: this.stepperButtonClicked
            },
            {
                visible: true,
                className: 'primary',
                label: 'common.label.next',
                icon: CommonHelper.getNextIconByCulture(),
                // disabled: (serviceType === Enumerations.extendedServices.filters || serviceType === Enumerations.extendedServices.billBook) && !isSelectedRequirements,
                disabled: false,
                type: Enumerations.stepperButtonType.next,
                validation: this.stepprButtonValidation,
                event: this.stepperButtonClicked
            },
        );
        return buttons;
    }

    stepprButtonValidation = (buttonType, currentStep) => {
        let service = OrderHelper.getSpecificService(this.routeId, this.props.currentExtendedService);
        switch (buttonType) {
            case Enumerations.stepperButtonType.previous:
                return this.state.currentExtendedServiceIndex === currentStep;
            case Enumerations.stepperButtonType.next:
                if (service?.fillingOptionType === Enumerations.extendedServiceFillingOptionType.mandatory) {
                    if (!this.props.isSelectedRequirements) {
                        this.setState({
                            resultMessageClass: messageClass(
                                CommonHelper.stringFormat(getTranslatedRawText('regularOrder.messages.mandatoryExtendedServiceMessage'), OrderHelper.getExtendedServicesName(service.extendedServiceType)))
                        });
                        return false;
                    }
                    return this.state.currentExtendedServiceIndex === currentStep;
                }
                else
                    return this.state.currentExtendedServiceIndex === currentStep;
            default: return false;
        }
    }

    stepperButtonClicked = (buttonType, newStep) => {
        switch (buttonType) {
            case Enumerations.stepperButtonType.previous:
            case Enumerations.stepperButtonType.next:
            default: this.foundNextOrPreviousStep(newStep);
        }
    }

    foundNextOrPreviousStep = (newStep) => {
        let newServiceType = !!this.state.stepList[newStep]
            ? this.state.stepList[newStep].serviceType
            : newStep <= 0 ? 0 : 10;
        if (newStep >= OrderHelper.getExtendedServicesLength(this.routeId))
            OrderHelper.setIsCompeteledExtendedServiceSteps(this.routeId, true);
        RegularOrderRouteHelper.extendedServicesStep(this.routeId, this.props.otherProps, newServiceType);
    }

    render() {
        if (!OrderHelper.isValidOrder(this.routeId)) {
            this.props.otherProps.history.replace(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts));
            return null;
        }
        return (
            <MainContentWrapper otherProps={this.props.otherProps}
                mainStepperActiveStep={Enumerations.orderLocation.extendedService}
                refreshExtendedServiceSummaryDate={this.props.refreshExtendedServiceSummaryDate}
                routeId={this.props.routeId}>

                <div className='border border-light-text-color regular-order-detail__header w-100'>
                    <NsStepperWithActionButtons stepList={this.state.stepList}
                        orientation={CommonHelper.isMobileDevice() ? 'vertical' : 'horizontal'}
                        activeStep={this.state.currentExtendedServiceIndex}
                        currentStep={this.state.currentExtendedServiceIndex}>
                        {this.props.children}
                    </NsStepperWithActionButtons>
                </div>
                {this.state.resultMessageClass}
            </MainContentWrapper>
        );
    }
}

export default ExtendedServiceWrapper;
