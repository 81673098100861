import { Col, Row } from "react-bootstrap";
import React from "react";

import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsTextField from "../../../../components/inputs/NsTextField";
import ContentHelper from "./ContentHelper";
import NsSwitch from "../../../../components/switch/NsSwitch";
import NsCheckbox from "../../../../components/inputs/NsCheckbox";
import ApBasesImageContent from "./ApBasesImageContent";
import ApBasesContentVisibility from "./ApBasesContentVisibility";
import ApBasesStandardSizesForUpload from "./ApBasesStandardSizesForUpload";

const ApBasesCategoryContentModal = (props) => {

    const { data, showValidationDate, selectedLanguage, stateHandler, fileUploaderCategoryType, serviceCall } = props;

    let categoryContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);

    const contentVisibilityTypeIdChanged = (selectedItem) => {
        stateHandler(data, { contentVisibilityTypeId: selectedItem }, selectedLanguage.id)
    }

    const selectedAgencyIdsChanged = (agencyIds) => {
        stateHandler(data, { agencyIds }, selectedLanguage.id)
    }

    return (
        <Row>
            <Col md={5}>
                <ApBasesImageContent content={categoryContent?.content}
                    isChangeFile={!!categoryContent?.content?.fileUrl}
                    serviceCall={serviceCall}
                    fileUploaderCategoryType={fileUploaderCategoryType}
                    stateHandler={(uploadedFileDetail) => stateHandler(data, { ...uploadedFileDetail, }, selectedLanguage.id)} />
                <ApBasesStandardSizesForUpload />
            </Col>
            <Col md={7}>
                <div className='d-flex flex-column'>

                    <div className="d-flex mb-2 align-items-end justify-content-between w-100 flew-row" >
                        <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                            valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                            value={categoryContent?.content.title}
                            showValidationDate={showValidationDate}
                            className='w-100' />
                        <div className='ps-2 d-flex align-items-end'>
                            <NsSwitch label='adminPanel.slider.active'
                                checked={categoryContent?.content.isActive}
                                disabled={!selectedLanguage.isDefault}
                                onChange={() => stateHandler(data, { isActive: !categoryContent?.content.isActive }, selectedLanguage.id)} />
                        </div>
                    </div>

                    <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.description')}
                        valueChanged={(value) => stateHandler(data, { mainContent: value }, selectedLanguage.id)}
                        value={categoryContent?.content.mainContent}
                        showValidationDate={showValidationDate}
                        className='w-100 mb-2' />

                    <NsCheckbox label={getTranslatedRawText('adminPanel.subject.showInMainPage')}
                        value={categoryContent?.content.isInHome || false}
                        disabled={!selectedLanguage.isDefault}
                        className='mb-2'
                        onChange={(checked) => stateHandler(data, { isInHome: checked }, selectedLanguage.id)} />

                    <ApBasesContentVisibility contentVisibilityTypeId={categoryContent?.content?.contentVisibilityTypeId}
                        selectedAgencies={categoryContent?.content?.selectedAgencies}
                        contentVisibilityTypeIdChanged={contentVisibilityTypeIdChanged}
                        selectedAgencyIdsChanged={selectedAgencyIdsChanged} />

                </div>
            </Col>
        </Row>
    );
}

export default React.memo(ApBasesCategoryContentModal);