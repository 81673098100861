import clsx from 'clsx';
import React from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import '../../assets/sass/components/circularProgress/NsCircularProgress.scss';

class NsCircularProgressWithLabel extends React.Component {

  render() {
    const { value, progressSize, progressColor, isLoading, ...otherProps } = this.props;
    return (
      <div className={clsx('position-relative d-inline-flex ')}>
        <CircularProgress variant="determinate"
          {...otherProps}
          className={clsx('background-progress', progressSize)}
          value={100}
        />
        <CircularProgress variant={isLoading ? 'indeterminate' : 'determinate'}
          {...otherProps}
          value={value}
          className={clsx(progressColor, progressSize, 'position-absolute')} />

        {!isLoading &&
          <div className='position-absolute top-0 start-0 bottom-0 end-0 d-flex justify-content-center align-items-center'>
            <Typography variant="inherit"
              className='light-text-color'>
              {`${Math.round(value) || 0}%`}
            </Typography>
          </div>
        }
      </div >
    );
  }
}

export default NsCircularProgressWithLabel;
