import clsx from 'clsx';
import React from 'react';
import { TextField } from '@mui/material';
import { NumericFormat } from "react-number-format";

import '../../assets/sass/components/inputs/TextField.scss';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CommonHelper from '../../utilities/CommonHelper';
import Config from '../../configs/Config';
import Enumerations from '../../configs/Enumerations';
import DelayedTaskHelper from '../../utilities/DelayedTaskHelper';
import StorageHelper from '../../utilities/StorageHelper';

const NumericFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, prefix, suffix, maxLength, hasDecimal, thousandSeparator, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            decimalScale={hasDecimal ? StorageHelper.getFromStorage(Enumerations.storage.userCurrency)?.defaultCurrency?.decimalPointLength : 0
            }
            thousandSeparator={thousandSeparator}
            valueIsNumericString
            allowLeadingZeros
            maxLength={maxLength}
            prefix={prefix}
            suffix={suffix}
        />
    );
});

class NsNumberInput extends React.Component {

    constructor(props) {
        super(props);
        this.setDelayWhenValueChanged = new DelayedTaskHelper(() => {
            this.exportDataToParent();
        }, 2000);
        this.state = {
            input: this.props.value || '',
            helperText: '',
            maxLength: props.maxLength || Config.numberInputsLength,
        };
    }

    helperTextHandler = () => {
        if (this.props.helperText)
            return this.props.helperText
        else if (this.props.showValidationDate && this.state.input?.length < 1) {
            return CommonHelper.stringFormat(getTranslatedRawText("common.messages.enter"), this.props.label)
        } else
            return ''
    }

    componentDidMount = () => {
        this.props.value && CommonHelper.safeFunctionCall(this.props.valueChanged, this.props.value, true)
    }

    componentDidUpdate = (prevProps) => {
        if ((prevProps.showValidationDate !== this.props.showValidationDate) || (prevProps.helperText !== this.props.helperText))
            this.setState({ helperText: this.helperTextHandler() });

        if (prevProps.value !== this.props.value) {
            if (!!this.props.value)
                this.setState({ input: this.props.value });
            else
                this.setState({ input: '' });
        }
    }

    handleChange = (event) => {
        this.setState({ input: event.target.value, helperText: this.helperTextHandler() });
        CommonHelper.safeFunctionCall(this.props.valueChanged, event.target.value, event.target.value.length >= 1);
        this.setDelayWhenValueChanged.run();
    };

    exportDataToParent = () => CommonHelper.safeFunctionCall(this.props.delayValueChanged, this.state.input);

    render() {
        const { showValidationDate, placeholder, valueChanged, helperTxt, inputProps, autoFocus, fontSize, suffix, prefix, hasDecimal, thousandSeparator, delayValueChanged, ...otherProps } = this.props;
        const { helperText, input, maxLength } = this.state;
        return (
            <TextField {...otherProps}
                className={clsx('ns-text-field', otherProps.className)}
                helperText={helperText}
                value={input}
                placeholder={placeholder}
                variant='standard'
                onChange={this.handleChange}
                autoFocus={autoFocus}
                InputProps={{
                    inputComponent: NumericFormatCustom,
                }}
                inputProps={{
                    thousandSeparator: thousandSeparator,
                    suffix: suffix,
                    prefix: prefix,
                    maxLength: maxLength,
                    hasDecimal: hasDecimal
                }}
            />
        );
    }
}

export default NsNumberInput;