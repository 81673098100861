import React from 'react';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsPasswordInput from '../../../components/inputs/NsPasswordInput';
import NsModal from '../../../components/modals/NsModal';
import Enumerations from '../../../configs/Enumerations';
import { ChangePasswordService } from '../../../services/ProfileSetting/PersonalSettingService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';


class ChangePasswordModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            { label: "common.save", icon: 'ns-icon-save', onClicked: this.saveClicked, className: 'primary' },
            { label: "common.cancel", icon: 'ns-icon-close', onClicked: this.cancelClicked, className: 'primary-outline' },
        ];
        this.state = {
            currentPassword: '',
            isValidCurrentPassword: false,

            newPassword: '',
            isValidNewPassword: false,

            confirmPassword: '',
            isValidConfirmPassword: false,

            showValidationDate: '',
            showMatchValidationDate: '',
            isLoading: false,
            resultMessageClass: (<></>),
        };
    }

    valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);

        this.setState({
            [valueName]: value,
            [isValidName]: isValid,
        });
    }

    cancelClicked = () => CommonHelper.safeFunctionCall(this.props.closeChangePasswordModal);

    saveClicked = () => {
        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({ showMatchValidationDate: new Date() });
            return false;
        }
        if (!this.state.isValidCurrentPassword ||
            !this.state.isValidNewPassword ||
            !this.state.isValidConfirmPassword) {

            this.setState({ showValidationDate: new Date() });
            return false;
        }

        else {
            let data = {
                currentPassword: this.state.currentPassword,
                password: this.state.newPassword,
                confirmPassword: this.state.confirmPassword
            };
            this.setState({ isLoading: true });
            ChangePasswordService(data, this.changePasswordCallback, true);
        }
    }

    changePasswordCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            this.cancelClicked();
            this.props.handleMessage(messageClassModal);
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        const {
            currentPassword,
            isValidCurrentPassword,
            newPassword,
            isValidNewPassword,
            confirmPassword,
            isValidConfirmPassword,
            showValidationDate,
            showMatchValidationDate,
        } = this.state;
        return (
            <NsModal showModal={this.props.showModal}
                maxWidth='xs'
                dialogTitle={getTranslatedRawText("profile.userInfo.changePassword")}
                closeModal={this.cancelClicked}
                primaryActionButton={this.saveClicked}
                actions={<NsStickyButton stickyButtons={this.mainButtons} isLoading={this.state.isLoading} />}
            >
                <div className="d-flex flex-column w-100">
                    <div className='mb-2'>
                        <NsPasswordInput label={getTranslatedRawText("components.passwordInput.currentPassword")}
                            passwordChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { currentPassword }, { isValidCurrentPassword })}
                            showValidationDate={showValidationDate}
                            inputProps={{ autocomplete: 'new-password' }}
                            autoFocus={true}
                            className="w-100 " />
                    </div>
                    <div className='mb-2'>
                        <NsPasswordInput label={getTranslatedRawText("components.passwordInput.newPassword")}
                            passwordChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { newPassword }, { isValidNewPassword })}
                            showValidationDate={showValidationDate}
                            className="w-100 " />
                    </div>
                    <div>
                        <NsPasswordInput label={getTranslatedRawText("components.passwordInput.confirmPassword")}
                            passwordChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { confirmPassword }, { isValidConfirmPassword })}

                            showMatchValidationDate={showMatchValidationDate}
                            className="w-100 " />
                    </div>
                </div>
                {this.state.resultMessageClass}
            </NsModal >
        );
    }
}

export default ChangePasswordModal;