import React from 'react';

import { GetAdditionalOrderInformationService } from '../../../../services/ProfileSetting/UserOrderService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import OrderDetailPreviewContent from './OrderDetailPreviewContent';
import NsModal from '../../../../components/modals/NsModal';
import PunchModal from '../punch/PunchModal';
import FoldLinesModal from '../foldLines/FoldLinesModal';
import FiltersModal from '../filters/FiltersModal';
import BillBookModal from '../billBook/BillBookModal';

class OrderDetailPreviewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFront: false,
            file: null,
            draft: null,
            resolution: null,
            resultData: null,
            showFiltersModal: false,
            showFoldLinesModal: false,
            showPunchModal: false,
            showBillBookModal: false,
            billBookId: null,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => {
        GetAdditionalOrderInformationService({ id: this.props.orderId }, this.getAdditionalOrderInformationCallback);
    }

    getAdditionalOrderInformationCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.setState({ resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    showFiltersModalClicked = (file, isFront) => this.setState({ file, isFront, showFiltersModal: true });
    showFoldLineshModalClicked = (draft, resolution) => this.setState({ draft, resolution, showFoldLinesModal: true });
    showBillBookModalClicked = (draft, resolution, billBookId) => this.setState({ draft, resolution, billBookId, showBillBookModal: true });
    showPunchModalClicked = (draft, resolution) => this.setState({ draft, resolution, showPunchModal: true });

    closeFiltersModalClicked = () => this.setState({ showFiltersModal: false });
    closeFoldLinesModalClicked = () => this.setState({ showFoldLinesModal: false });
    closePunchModalClicked = () => this.setState({ showPunchModal: false });
    closeBillBookModalClicked = () => this.setState({ showBillBookModal: false });


    render() {

        const { resultData, draft, resolution, isFront, file, showFiltersModal, showFoldLinesModal, showPunchModal, showBillBookModal, billBookId, resultMessageClass } = this.state;
        const { orderId, showModal } = this.props;

        const hasExtendedService = resultData?.extendedServices?.length > 0;

        return (
            <NsModal showModal={showModal}
                maxWidth={hasExtendedService ? 'md' : 'sm'}
                dialogTitle={getTranslatedRawText("profile.detail.orderDetail")}
                closeModal={this.props.closeModal}>

                <OrderDetailPreviewContent resultData={resultData}
                    orderId={orderId}
                    showFiltersModalClicked={this.showFiltersModalClicked}
                    showFoldLineshModalClicked={this.showFoldLineshModalClicked}
                    showBillBookModalClicked={this.showBillBookModalClicked}
                    showPunchModalClicked={this.showPunchModalClicked} />

                {showFiltersModal &&
                    <FiltersModal id={orderId}
                        isFront={isFront}
                        file={file}
                        closeModal={this.closeFiltersModalClicked} />}

                {showFoldLinesModal &&
                    <FoldLinesModal id={orderId}
                        draft={draft}
                        resolution={resolution}
                        closeModal={this.closeFoldLinesModalClicked} />}

                {showBillBookModal &&
                    <BillBookModal id={billBookId}
                        draft={draft}
                        resolution={resolution}
                        closeModal={this.closeBillBookModalClicked} />
                }

                {showPunchModal &&
                    <PunchModal id={orderId}
                        draft={draft}
                        resolution={resolution}
                        closeModal={this.closePunchModalClicked} />}

                {resultMessageClass}

            </NsModal>
        );
    }
}

export default OrderDetailPreviewModal;