import React, { useState } from 'react';

import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import DateHelper from '../../../../utilities/DateHelper';

const ApStatisticalReportSearch = (props) => {

    const [reportDateFrom, setReportDateFrom] = useState(DateHelper.getPreviousWeek());
    const [reportDateTo, setReportDateTo] = useState(new Date());
    const [orderTypes, setOrderTypes] = useState([]);
    const [orderType, setOrderType] = useState(null);
    const [orderApplicationTypes, setOrderApplicationTypes] = useState([]);

    const options = props.isBusiness ? [
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.reportDateFrom'),
            fieldName: 'reportDateFrom',
            value: reportDateFrom,
            onChange: (value) => setReportDateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.reportDateTo'),
            fieldName: 'reportDateTo',
            value: reportDateTo,
            onChange: (value) => setReportDateTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.orderType.orderTypes'),
            fieldName: 'orderTypes',
            value: orderTypes,
            items: ConstantsWithTranslation.getOrderType(),
            onChange: (value) => {
                setOrderTypes(value);
                setOrderType(value?.id)
                setOrderApplicationTypes([])
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.orderType.orderApplicationType'),
            fieldName: 'orderApplicationTypes',
            value: orderApplicationTypes,
            items: ConstantsWithTranslation.getOrderApplicationType(orderType),
            onChange: (value) => setOrderApplicationTypes(value)
        }
    ] : [
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.reportDateFrom'),
            fieldName: 'reportDateFrom',
            value: reportDateFrom,
            onChange: (value) => setReportDateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.reportDateTo'),
            fieldName: 'reportDateTo',
            value: reportDateTo,
            onChange: (value) => setReportDateTo(value)
        },
    ]

    const businessSearchValues = { reportDateFrom, reportDateTo, orderApplicationTypes, orderTypes }
    const financialSearchValues = { reportDateFrom, reportDateTo }

    const clearButtonClicked = () => {
        setReportDateFrom(DateHelper.getPreviousWeek());
        setReportDateTo(new Date());
        setOrderTypes([]);
        setOrderType([]);
        setOrderApplicationTypes([]);
        CommonHelper.safeFunctionCall(props.searchClicked, {});
    }

    const searchButtonClicked = () => CommonHelper.safeFunctionCall(props.searchClicked,
        props.isBusiness ? businessSearchValues : financialSearchValues);

    const exportExcelButtonClicked = () => {

    }

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            searchButtonClicked();
    }

    return (
        <div className='report-business-align mb-3' onKeyDown={handleKeyDown}>
            <div>
                <NsAdvanceSearch searchOptions={options}
                    searchValues={props.isBusiness ? businessSearchValues : financialSearchValues}
                    mdCol={3} smCol={6} />
            </div>

            <div className="d-flex my-2 me-auto">
                <NsButton label={getTranslatedRawText('common.search')}
                    size='small'
                    wrapperClassName=''
                    onClick={searchButtonClicked}
                    className='secondary'
                    loading={props?.isLoading}
                    startIcon='ns-icon-search' />

                <NsButton label={getTranslatedRawText('regularOrder.common.clear')}
                    size='small'
                    wrapperClassName='mx-1'
                    onClick={clearButtonClicked}
                    className='secondary-outline'
                    loading={props?.isLoading}
                    startIcon='ns-icon-clear' />

                {false && <NsButton label={getTranslatedRawText('common.exportExcel')}
                    size='small'
                    onClick={exportExcelButtonClicked}
                    className='secondary-outline text-nowrap'
                    loading={props?.isLoading}
                    startIcon='ns-icon-excel' />}

            </div>
        </div>
    );
};

export default React.memo(ApStatisticalReportSearch);