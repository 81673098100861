import React from 'react';

import { connect } from 'react-redux';
import '../../../assets/sass/views/singlePages/SinglePageSidebar.scss';
import NsCard from '../../../components/cards/NsCard';
import NsLinkImage from '../../../components/images/NsLinkImage';
import Enumerations from '../../../configs/Enumerations';
import { GetCmsSidebarPromoteContentsService } from '../../../services/singlePages/CmsService';
import { selectPromotionEvent, viewPromotionEvent } from '../../../utilities/GoogleTagManagerHelper';
import SpSidebarAds from './SpSidebarAds';
import SpSidebarLinkToShopBanner from './SpSidebarLinkToShopBanner';
import SpSidebarMenu from './SpSidebarMenu';
import SpSidebarOurSuggestionProducts from './SpSidebarOurSuggestionProducts';
import SpSidebarSocialMedia from './SpSidebarSocialMedia';
import SpSidebarTestimonial from './SpSidebarTestimonial';

class SpSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            advertisements: [],
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        GetCmsSidebarPromoteContentsService(this.getCmsSidebarPromoteContentsCallback);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.userFullName !== prevProps.userFullName) {
            GetCmsSidebarPromoteContentsService(this.getCmsSidebarPromoteContentsCallback);
        }
    }

    getCmsSidebarPromoteContentsCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ advertisements: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        const { advertisements, messageClassModal } = this.state;

        return (
            <div className='sidebar d-flex flex-column'>
                <div className="mb-3">
                    <SpSidebarMenu />
                </div>

                <SpSidebarLinkToShopBanner />

                <div className="mb-3">
                    <SpSidebarOurSuggestionProducts />
                </div>

                <AdvertisingImage img={advertisements[0]} promotionEnum={Enumerations.promotionsId.singleTop} listEnum={Enumerations.googleTagManagerListId.singlePageSidebar} />

                <SpSidebarTestimonial />

                <AdvertisingImage img={advertisements[1]} promotionEnum={Enumerations.promotionsId.singleBottom} listEnum={Enumerations.googleTagManagerListId.singlePageSidebar} />

                {false && <div className="mb-3">
                    <SpSidebarAds />
                </div>}

                <div className="mb-3">
                    <SpSidebarSocialMedia />
                </div>
                {messageClassModal}
            </div>
        );
    }
}

export function AdvertisingImage(props) {
    const { img } = props;
    return !!img?.fileUrl
        ? <div className="mb-3">
            <NsCard>
                <NsLinkImage imgInfo={img}
                    imgClassName='sidebar__adv-image'
                    imageSitePosition={Enumerations.imageSitePosition.homeSlider}
                    onLoad={(e) => viewPromotionEvent(e.target, 1, props.promotionEnum, props.listEnum)}
                    onClick={(e) => selectPromotionEvent(e.target, 1, props.promotionEnum, props.listEnum)} />
            </NsCard>
        </div >
        : null
}

const mapStateToProps = state => {
    return {
        userFullName: state.currentUser.userFullName,
    };
};

export default connect(mapStateToProps)(SpSidebar);