import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetTourActionCreators } from '../../redux/reducers/ResetTourReducer';
import CommonHelper from '../../utilities/CommonHelper';
import NsButton from '../buttons/NsButton';


class NsResetTour extends Component {
    currentDateChanged = () => {
        this.props.setResetTour(this.props.breadCrumb)
        CommonHelper.safeFunctionCall(this.props.refreshDate, new Date())
    }
    render() {
        return (
            <NsButton onClick={this.currentDateChanged}
                label='common.help'
                size='small'
                startIcon='ns-icon-question'
                className='secondary-text text-decoration-none text-muted' />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...resetTourActionCreators }, dispatch);
};

export default connect(null, mapDispatchToProps)(NsResetTour);