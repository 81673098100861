import React from 'react';
import { withRouter } from 'react-router-dom';

import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsNumberInput from '../../../components/inputs/NsNumberInput';
import NsModal from '../../../components/modals/NsModal';
import Links from '../../../configs/links/AllLinks';
import { PostAmountForIncreaseCreditService } from '../../../services/payments/PaymentService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import NsTypography from '../../../components/text/NsTypography';
import NumberHelper from '../../../utilities/NumberHelper';

class IncreaseCreditModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            { label: "common.pay", icon: 'ns-icon-payments', onClicked: this.payClicked, className: 'primary' },
            { label: "common.cancel", icon: 'ns-icon-close', onClicked: this.cancelClicked, className: 'primary-outline' },
        ];
        this.state = {
            amount: 0,
            isValidAmount: false,
            showValidationDate: '',
            isLoading: false,
        }
    }

    valueChanged = (value, isValid) => {
        this.setState({
            amount: value,
            isValidAmount: isValid,
        });
    }

    cancelClicked = () => CommonHelper.safeFunctionCall(this.props.closeIncreaseCreditModal);

    payClicked = () => {
        const { amount } = this.state;
        if (!this.state.isValidAmount || amount == '0') {
            this.setState({ showValidationDate: new Date() });
            return false;
        } else {
            this.setState({ isLoading: true },
                PostAmountForIncreaseCreditService({ amount }, this.postAmountForIncreaseCreditCallback)
            );
        }
    }

    postAmountForIncreaseCreditCallback = (resultData, result, messageClassModal) => {
        CommonHelper.safeFunctionCall(this.props.closeIncreaseCreditModal);
        if (!result?.hasError && !!resultData)
            this.props.history.push(CommonHelper.stringFormat(
                getUrlWithCurrentCulture(Links.view.main.userPayment, Links.view.main.userPayment.readyToPay), resultData));
        else
            CommonHelper.safeFunctionCall(this.props.handleMessage, messageClassModal);
    }

    preparePaymentStateCallback = (resultData, result, messageClassModal) => {
        if (!result?.hasError && !!resultData)
            this.props.history.push(CommonHelper.stringFormat(
                getUrlWithCurrentCulture(Links.view.main.userPayment, Links.view.main.userPayment.readyToPay), 232));
        else
            CommonHelper.safeFunctionCall(this.props.handleMessage, messageClassModal);
    }

    render() {
        const numberHelper = new NumberHelper();
        return (
            <NsModal showModal={this.props.showModal}
                primaryActionButton={this.payClicked}
                maxWidth='xs'
                dialogTitle={getTranslatedRawText("profile.userInfo.increaseCredit")}
                closeModal={this.cancelClicked}
                actions={<NsStickyButton stickyButtons={this.mainButtons} isLoading={this.state.isLoading} />} >
                <p className='primary-paragraph'>
                    {getTranslatedRawText("profile.userInfo.increaseDescription")}
                </p>

                <NsNumberInput label={CommonHelper.stringFormat(getTranslatedRawText("profile.userInfo.amount"), CommonHelper.getDefaultCurrency())}
                    valueChanged={this.valueChanged}
                    autoFocus={true}
                    thousandSeparator={true}
                    hasDecimal={true}
                    value={this.state.amount}
                    showValidationDate={this.state.showValidationDate}
                    className="w-100 " />
                <NsTypography variant='inherit' className='light-text-color font-size-14 mt-1'>
                    <div>{numberHelper.convertNumberToText(this.state.amount)} <span className='fw-bold red-color'>{CommonHelper.getDefaultCurrency()}</span></div>
                </NsTypography>
            </NsModal >
        );
    }
}

export default withRouter(IncreaseCreditModal);