import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';
import { GetShippingBasicInfoService } from '../../../../services/ProfileSetting/UserOrderService';
import CommonHelper from '../../../../utilities/CommonHelper';
import ApBaseLabelInfo from '../../../adminPanel/bases/ApBaseLabelInfo';
import { changeAddressAndTelProperty } from './UpShippings';
import UpShippingRequestHeaderCost from './shippingRequests/UpShippingRequestHeaderCost';
import { GetShippingEstimatedTimeSheetService, GetShippingMethodService } from '../../../../services/ProfileSetting/DeliveryAddressSettingService';

class UpShippingDetailHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shippingBasicInfo: null,
            isLoading: true,
            shippingTime: null,
            shippingMethodInfo: null,
        };
    }

    componentDidMount = () => GetShippingBasicInfoService({ id: this.props.orderId }, this.getDeliveryRequestsBasicInfoCallback);

    getDeliveryRequestsBasicInfoCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            this.setState({ shippingBasicInfo: resultData }, () => {
                CommonHelper.safeFunctionCall(this.props.updateOrdersCount, resultData?.sharedSheetOrdersCount, resultData?.storeProductOrdersCount);
                this.getShippingEstimatedTimeSheet();
                this.getShippingMethodInfo();
            });
        }
        else
            this.setState({ resultMessageClass });
    }

    getShippingEstimatedTimeSheet = () => {
        GetShippingEstimatedTimeSheetService({
            shippingPlanType: this.state.shippingBasicInfo?.shippingPlanTypeId,
            shippingMethodCategoryType: this.state.shippingBasicInfo?.shippingMethodCategoryTypeId
        }, this.getShippingEstimatedTimeSheetCallback);
    }

    getShippingEstimatedTimeSheetCallback = (resultData, result, resultMessageClassModal) => {
        this.setState({ isLoading: false });
        let shippingTime = null;
        let hasTime = false;
        if (result.data.hasError) this.setState({ resultMessageClassModal });
        else {
            resultData.map(result => {
                hasTime = result.messageClass.messageItems.some(message => message.data == Enumerations.timeSheetDayType.shippingToShippingMethodDay);
                if (hasTime) shippingTime = result.dayDateTime;
            })
            this.setState({ shippingTime }, () =>
                CommonHelper.safeFunctionCall(this.props.valueChanged, shippingTime, { shippingTime }));
        }
    }

    getShippingMethodInfo = () => GetShippingMethodService({ ShippingMethodTypeId: this.state?.shippingBasicInfo?.shippingMethodTypeId }, this.getShippingMethodService);

    getShippingMethodService = (resultData, result, resultMessageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) this.setState({ shippingMethodInfo: resultData });
        else this.setState({ resultMessageClassModal });
    };

    render() {
        const { shippingBasicInfo, isLoading, shippingTime, shippingMethodInfo, resultMessageClass } = this.state;
        return (
            <>
                {isLoading ?
                    <div className='d-inline-block w-25 px-1'>
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                    </div>
                    :
                    <div className='p-2'>
                        <Row>
                            <Col md={8} sm={12}>
                                <ApBaseLabelInfo info={{ shippingInfoDto: changeAddressAndTelProperty({ ...shippingBasicInfo }) }}
                                    isLoading={isLoading}
                                    hasAccessInfo />
                            </Col>
                            <Col md={4} sm={12}>
                                <UpShippingRequestHeaderCost
                                    selectedShippingMethod={{ ...shippingMethodInfo, ...shippingBasicInfo }}
                                    shippingTime={shippingTime}
                                    selectedShippingPlan={{ key: shippingBasicInfo?.shippingPlanTypeId }} />
                            </Col>
                        </Row>
                    </div>
                }
                {resultMessageClass}
            </>
        );
    }
}

export default UpShippingDetailHeader;