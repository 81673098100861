import React from 'react';

import { MasterFileInformationService, FilmFileInformationService } from '../../../../../services/order/ProfessionalOrderService';
import { GetFilePublicUrlService, GetProductBasicPriceService } from '../../../../../services/order/OrderService';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import { GetImageService } from '../../../../../services/common/CommonService';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import Enumerations from '../../../../../configs/Enumerations';
import PoModalFileManager from '../PoModalFileManager';
import ImageHelper from '../../../../../utilities/ImageHelper';

class PoBaseFileSelectorHelper extends React.Component {

    constructor(props) {
        super(props);
        this.seriesFromLocalStorage = OrderHelper.getSeries(this.props.routeId);
        this.productId = CommonHelper.toInt(props.routeId);
        this.turnaround = OrderHelper.getTurnaround(this.props.routeId);
        this.printedSide = OrderHelper.getPrintedSide(this.props.routeId);
        this.state = {
            selectedFileInfo: null,
            availableSizes: OrderHelper.getAvailableSizes(props.routeId),
            isShowFileManagerModal: false,
            isShowMessageModal: false,
        };
    }

    componentDidMount = () => this.checkFileIsSelected(this.props.selectedFileInfo);

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.getFileInformationDate !== this.props.getFileInformationDate) {
            this.getFileInformation(newProps.selectedFileInfo);
        }

        if (newProps.getAvailabelSizesDate !== this.props.getAvailabelSizesDate) {
            this.getAvailableSize(null, newProps.selectedFileInfo.width, newProps.selectedFileInfo.height);
        }

        if (newProps.changeFileDate !== this.props.changeFileDate) {
            this.setState({ isShowFileManagerModal: true });
        }
    }

    checkFileIsSelected = (selectedFileInfo) => {
        const { storageFileInfo, currentFileKey, isFront, routeId } = this.props;

        if (currentFileKey === 0 && !isFront && isEmpty(OrderHelper.getFrontSideMasterFiles(routeId))) {
            this.setState({
                messageItems: [{
                    messageText: getTranslatedRawText('common.messages.masterFrontFileIsNotSelected'),
                    messageType: Enumerations.messageType.error
                }],
                isShowMessageModal: true,
            });
            return;
        }

        if (currentFileKey > 0 && this.masterFileIsNotSelected(isFront, routeId)) {
            this.setState({
                messageItems: [{
                    messageText: getTranslatedRawText('common.messages.masterFileIsNotSelected'),
                    messageType: Enumerations.messageType.error
                }],
                isShowMessageModal: true,
            });
            return;
        }

        if (isEmpty(selectedFileInfo)) {
            this.setState({ isShowFileManagerModal: true });
            return;
        }
        if (isEmpty(storageFileInfo)) {
            this.getFileInformation(selectedFileInfo, currentFileKey);
            return;
        }
        if (selectedFileInfo?.id === storageFileInfo?.id) {
            CommonHelper.safeFunctionCall(this.props.setSelectedFileInfo, storageFileInfo);
            CommonHelper.safeFunctionCall(this.props.setAvailableSizes, this.state.availableSizes);
        }
        else
            this.setState({
                messageItems: [{
                    messageText: getTranslatedRawText('common.messages.allLastInfoIsReset'),
                    messageType: Enumerations.messageType.warning
                }],
                isShowMessageModal: true,
            });
    }

    masterFileIsNotSelected = (isFront, routeId) => {
        let masterFile = isFront ? OrderHelper.getFrontSideMasterFiles(routeId) : OrderHelper.getBackSideMasterFiles(routeId);
        return isEmpty(masterFile);
    }

    getFileInformation = (selectedFileInfo) => {
        CommonHelper.safeFunctionCall(this.props.changeIsLoadingState, true);
        if (!isEmpty(selectedFileInfo)) {
            this.props.currentFileKey === 0
                ? MasterFileInformationService(selectedFileInfo, this.props.routeId, this.fileInFormationCallback)
                : FilmFileInformationService(selectedFileInfo, this.fileInFormationCallback);
        }
    }

    fileInFormationCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({ selectedFileInfo: resultData },
                () => {
                    this.getAvailableSize(resultData.id, null, null);
                    GetFilePublicUrlService(resultData.id, this.getFilePublicUrlCallback);
                });
        } else {
            CommonHelper.safeFunctionCall(this.props.changeIsLoadingState, false);
            this.setState({ messageItems: messageClassModal.props.messageItems, isShowMessageModal: true });
        }
    }

    getFilePublicUrlCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            let selectedFileInfo = { ...this.state.selectedFileInfo };
            selectedFileInfo.filePublicUrl = resultData;
            selectedFileInfo.resolution = resultData.resolution;
            this.setState({ selectedFileInfo }, () => {
                GetImageService(resultData.draft, this.getDraftCallback);
                GetImageService(resultData.thumbnail, this.getThumbnailCallback);
            });
        } else {
            CommonHelper.safeFunctionCall(this.props.changeIsLoadingState, false);
            this.setState({ messageItems: messageClassModal.props.messageItems, isShowMessageModal: true });
        }
    }

    getDraftCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState((prevState) => { prevState.selectedFileInfo.draft = resultData }, this.setSelectedFileInfoAfterFileLoaded);
        } else {
            CommonHelper.safeFunctionCall(this.props.changeIsLoadingState, false);
            this.setState({ messageItems: messageClassModal.props.messageItems, isShowMessageModal: true });
        }
    }

    getThumbnailCallback = (resultData, result, messageClassModal) => {
        CommonHelper.safeFunctionCall(this.props.changeIsLoadingState, false);
        if (!result?.data?.hasError) {
            this.setState((prevState) => { prevState.selectedFileInfo.thumbnail = resultData }, this.setSelectedFileInfoAfterFileLoaded);
        } else
            this.setState({ messageItems: messageClassModal.props.messageItems, isShowMessageModal: true });
    }

    setSelectedFileInfoAfterFileLoaded = () => {

        if (!isEmpty(this.state.selectedFileInfo?.draft) && !isEmpty(this.state.selectedFileInfo?.thumbnail)) {
            CommonHelper.safeFunctionCall(this.props.setSelectedFileInfo, this.state.selectedFileInfo);
            CommonHelper.safeFunctionCall(this.props.setAvailableSizes, this.state.availableSizes);
        }
    }

    getAvailableSize = (masterFileId, width, height) => {
        let frontFileId = null;
        frontFileId = this.props.isFront
            ? null
            : OrderHelper.getFrontSideMasterFiles(this.props.routeId)?.id
        const data = {
            orderId: OrderHelper.getOrderId(this.props.routeId),
            productId: this.productId,
            frontFileId,
            series: this.seriesFromLocalStorage,
            turnaroundType: this.turnaround?.key,
            twoSidePrintingType: this.printedSide?.key,
            currentFileId: masterFileId,
            width,
            height,
        }
        GetProductBasicPriceService(data, this.getProductBasicPriceCallback);
    }

    getProductBasicPriceCallback = (resultData, result, messageClassModal) => {
        this.setState({ availableSizes: resultData },
            () => CommonHelper.safeFunctionCall(this.props.setAvailableSizes, this.state.availableSizes, messageClassModal));
    }

    closeMessageModal = (confirm, hasCancelButton) => {
        this.setState({ isShowMessageModal: false });
        (confirm && hasCancelButton)
            ? this.getFileInformation(this.props.selectedFileInfo)
            : CommonHelper.safeFunctionCall(this.props.closeParentModal);
    }

    selectFileManagerModalClicked = (selectedFile) => {
        this.setState({ isShowFileManagerModal: false });
        this.getFileInformation(selectedFile);
    }

    closeFileManagerModalClicked = () => {
        this.setState({ isShowFileManagerModal: false });
        CommonHelper.isMobileDevice() && isEmpty(this.state.selectedFileInfo) &&
            isEmpty(this.props.selectedFileInfo) && CommonHelper.safeFunctionCall(this.props.closeParentModal);
    }

    render() {
        const { isShowFileManagerModal, isShowMessageModal, messageItems } = this.state;

        return (
            <>
                {isShowFileManagerModal &&
                    (<PoModalFileManager showModal={isShowFileManagerModal}
                        selectFileManagerModalClicked={this.selectFileManagerModalClicked}
                        closeFileManagerModalClicked={this.closeFileManagerModalClicked} />)
                }

                {isShowMessageModal &&
                    <NsMessageClassModal show={isShowMessageModal}
                        onClose={this.closeMessageModal}
                        messageItems={messageItems} />
                }
            </ >
        );
    }
}

export default PoBaseFileSelectorHelper;