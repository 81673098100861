import React, { useState } from "react";

import NsAdminPanelContentWrapper from "../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import { GeFeedbackTypesService } from "../../../../services/adminPanel/AdminFeedbackService";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { feedbackTypesColumns } from "../bases/ApBasesFeedbackHelper";
import ApFeedbackTypeDetailModal from "./ApFeedbackTypeDetailModal";

const ApFeedbackTypes = () => {

    const [selectedFeedbackTypeId, setSelectedFeedbackTypeId] = useState(null);
    const [showFeedbackTypeDetailModal, setShowFeedbackTypeDetailModal] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);

    const showFeedbackTypeModalClicked = (id) => {
        setSelectedFeedbackTypeId(id);
        setShowFeedbackTypeDetailModal(true);
    }

    const closeSFeedbackTypeDetailModalClicked = () => {
        setShowFeedbackTypeDetailModal(false);
        setCallServiceDate(new Date());
    }

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.feedbackTypes")}
            icon={'ns-icon-feedback-type h1'}>

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GeFeedbackTypesService}
                columnDefs={feedbackTypesColumns(showFeedbackTypeModalClicked)}
                hasPagination={true} />

            {showFeedbackTypeDetailModal &&
                <ApFeedbackTypeDetailModal selectedFeedbackTypeId={selectedFeedbackTypeId}
                    closeModal={closeSFeedbackTypeDetailModalClicked} />
            }

        </NsAdminPanelContentWrapper>
    )
};

export default React.memo(ApFeedbackTypes);