import React, { useEffect, useState } from 'react';

import NsButton from '../../../../components/buttons/NsButton';
import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsImage from '../../../../components/images/NsImage';
import Enumerations from '../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { GetCustomerFilesService } from '../../../../services/adminPanel/AdminCustomerFilesService';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApCustomersFilesModal from './ApCustomersFilesModal';
import ApCustomersFilesSearch from './ApCustomersFilesSearch';

const ApCustomersFiles = (props) => {

    const [showCustomerFileModal, setShowCustomerFileModal] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [searchData, setSearchData] = useState(props.searchData);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const columns = [
        hasAccess([UserClaimEnumerations.getApCustomerFileById]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (file) => {
                    return <NsButton className='secondary-text'
                        loading={isLoading}
                        startIcon={'ns-icon-invoice-details'}
                        onClick={() => fileDetailClicked(file.userFileId)} />
                }
            }
        },
        {
            field: 'filePreview',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.filePreview'),
            width: 120,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (file) => {
                    return <NsImage imageUrl={file.thumbnail}
                        imgAlt="components.imagesAlt.imageThumbnail" />
                }
            }
        },
        {
            field: 'name',
            headerName: getTranslatedRawText('adminPanel.customersFiles.fileName'),
            width: 120
        },
        {
            field: 'extension',
            headerName: getTranslatedRawText('adminPanel.customersFiles.fileFormat'),
            width: 120,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (row) => {
                    return <span>{row.extension.toUpperCase()}</span>
                }
            }
        },
        {
            field: 'volume',
            headerName: getTranslatedRawText('adminPanel.customersFiles.fileSize'),
            width: 120,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <span>{CommonHelper.convertByteToMegaByte(props.volume)} {getTranslatedRawText('common.mb')}</span>
                }
            }
        },
        {
            field: 'used',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.usingInAOrder'),
            width: 120,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (row) => {
                    return <span>{row.used
                        ? getTranslatedRawText('common.yes')
                        : getTranslatedRawText('common.no')}
                    </span>
                }
            }
        },
        {
            field: 'nasInvoiceId',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber'),
            width: 150,
            dataType: Enumerations.gridType.number,
        },
        {
            field: 'sharedSheetOrderId',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.remotingOrderId'),
            width: 135,
            dataType: Enumerations.gridType.number,
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 110,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 120,
        },
    ];

    useEffect(() => {
        setCallServiceDate(new Date());
        setIsLoading(true);
    }, [searchData]);

    const fileDetailClicked = (id) => {
        setSelectedFileId(id);
        setShowCustomerFileModal(true);
    }

    const closeApCustomersFilesModal = () => setShowCustomerFileModal(false);

    const searchClicked = (data) => setSearchData(data);

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.customersFiles")}
            icon={'ns-icon-open-folder h1'}>

            <ApCustomersFilesSearch isCustomersFiles={true}
                isLoading={isLoading}
                searchClicked={searchClicked} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetCustomerFilesService}
                parameters={{
                    'Filter.UserId': searchData?.selectedUserInfo?.userId,
                    'Filter.OrderId': searchData?.selectedOrderInfo?.id,
                    'Filter.UseInOrder': searchData?.usingInAOrder?.status,
                    'Filter.VolumeFrom': searchData?.fileSizeFrom,
                    'Filter.VolumeTo': searchData?.fileSizeTo,
                    'Filter.SubmitTimeFrom': searchData?.createTimeFrom,
                    'Filter.SubmitTimeTo': searchData?.createTimeTo,
                }}
                columnDefs={CommonHelper.clearArrayFromEmptyItem(columns)}
                hasPagination={true}
                getData={getData} />

            {showCustomerFileModal &&
                <ApCustomersFilesModal selectedFileId={selectedFileId}
                    isLoading={isLoading}
                    closeModal={closeApCustomersFilesModal} />
            }
        </NsAdminPanelContentWrapper>
    )
}

export default React.memo(ApCustomersFiles);