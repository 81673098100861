import React, { useEffect, useRef, useState } from 'react';

import { GetPaymentsHasProblemService, GetPaymentsService } from '../../../../services/adminPanel/AdminPaymentService';
import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import Enumerations from '../../../../configs/Enumerations';
import DateHelper from '../../../../utilities/DateHelper';
import ApPaymentDetailModal from './ApPaymentDetailModal';
import { getApPaymentColumns } from './ApPaymentHelper';
import ApPaymentSearch from './ApPaymentSearch';
import NsTab from '../../../../components/tabs/NsTab';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';

export const PaymentContent = (props) => {

    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [payments, setPayments] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [searchData, setSearchData] = useState(!!props.useInPaymentsHasProblem
        ? { ...props.searchData, submitTimeFrom: new Date(DateHelper.getPreviousWeek()) } : props.searchData);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(ConstantsWithTranslation.getProblematicPaymentsTabs()[0]);
    const [showPaymentDetailModal, setShowPaymentDetailModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState((<></>));
    const [callServiceDate, setCallServiceDate] = useState(null);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData, selectedTabIndex]);

    const getParameters = () => {
        let paymentType = !props.useInPaymentsHasProblem ? searchData?.paymentType?.id
            : selectedTab?.id === Enumerations.paymentType.increaseCredit ? Enumerations.paymentType.increaseCredit
                : Enumerations.paymentType.orderPayment || null;

        return ({
            "Filter.UserId": searchData?.user || props.phoneNumber?.userId || props?.userId || '',
            "Filter.TrackNumber": searchData?.trackingNo || '',
            "Filter.FromSubmitTime": searchData?.submitTimeFrom || '',
            "Filter.ToSubmitTime": searchData?.submitTimeTo || '',
            "Filter.FromStatusTime": searchData?.statusTimeFrom || '',
            "Filter.ToStatusTime": searchData?.statusTimeTo || '',
            "Filter.InvoiceNumber": searchData?.invoiceNo || '',
            "Filter.MinPrice": searchData?.minAmount || '',
            "Filter.MaxPrice": searchData?.maxAmount || '',
            "Filter.PaymentStateType": searchData?.paymentStatus?.id || '',
            "Filter.PaymentType": paymentType,
            "Filter.PaymentGatewayType": searchData?.paymentGateways?.id || '',
            "Filter.VoucherNumber": searchData?.voucherNo || '',
            "Filter.RefrenceNumber": searchData?.refrenceNo || '',
            "Filter.AdminUserId": searchData?.admin || props.phoneNumber?.userId || '',
            "Filter.OrderId": searchData?.orderId || '',
            "Filter.OrderProductTypeId": searchData?.orderProductTypeId?.id || '',
        })
    };

    const handlers = {

        paymentDetailClicked: (id) => {
            setSelectedPayment(payments.filter(payment => payment.id === id)[0]);
            setShowPaymentDetailModal(true);
        },

        closeApPaymentDetailModal: (message) => {
            setShowPaymentDetailModal(false);
            if (!!message) {
                setResultMessageClass(message)
                setCallServiceDate(new Date());
            }
        },

        getSearchData: (data) => {
            setSearchData(data);
        },

        getData: (payments, isLoading) => {
            setPayments(payments);
            setIsLoading(isLoading);
        },

        tabChanged: (selectedTabIndex, selectedTab) => {
            setSelectedTabIndex(selectedTabIndex);
            setSelectedTab(selectedTab);
        }
    }

    const { paymentDetailClicked, getData, closeApPaymentDetailModal, getSearchData, tabChanged } = handlers;

    const columns = getApPaymentColumns(paymentDetailClicked, props.useInPaymentsHasProblem);

    return (
        <>
            <ApPaymentSearch type={Enumerations.adminSearchType.payments}
                isLoading={isLoading}
                searchData={searchData}
                customerDetails={props.customerDetails}
                isHideSearch={props.isHideSearch}
                selectedOrder={props.selectedOrder}
                userId={props?.userId}
                useInPaymentsHasProblem={props.useInPaymentsHasProblem}
                setSearchData={getSearchData} />
            {props.useInPaymentsHasProblem &&
                <NsTab tabs={ConstantsWithTranslation.getProblematicPaymentsTabs()}
                    value={selectedTabIndex}
                    onChange={tabChanged}>
                </NsTab>
            }
            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={props.useInPaymentsHasProblem
                    ? GetPaymentsHasProblemService
                    : GetPaymentsService
                }
                parameters={getParameters()}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showPaymentDetailModal &&
                <ApPaymentDetailModal showModal={showPaymentDetailModal}
                    selectedPaymentId={selectedPayment?.id}
                    useInPaymentsHasProblem={props.useInPaymentsHasProblem}
                    closeModal={closeApPaymentDetailModal} />
            }

            {resultMessageClass}
        </>

    );
}

const ApPayment = ({ useInPaymentsHasProblem }) => {
    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText(!useInPaymentsHasProblem ? "adminPanel.sidebar.list" : "adminPanel.sidebar.problematicPayments")}
            icon={!useInPaymentsHasProblem ? 'ns-icon-payments h1' : 'ns-icon-problematic-payments'}>
            <PaymentContent useInPaymentsHasProblem={useInPaymentsHasProblem} />
        </NsAdminPanelContentWrapper>
    );
}

export default React.memo(ApPayment);