import React from "react";

import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsSkeleton from "../../../../components/skeletons/NsSkeleton";
import NsTypography from "../../../../components/text/NsTypography";
import CommonHelper from "../../../../utilities/CommonHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsCard from "../../../../components/cards/NsCard";

const ApDcbCustomerPlanList = (props) => {

    const { cashback, isLoading } = props;

    return (
        <>
            <NsCard className="mb-2 p-4">
                <NsTypography className='font-size-16 fw-bold mb-3'>
                    {getTranslatedRawText('adminPanel.cashback.customersCommonPlans')}&nbsp;
                    <span className="font-size-14 fw-normal">{getTranslatedRawText('adminPanel.cashback.eligibleForCashback')}</span>
                </NsTypography>
                {isLoading
                    ? <NsSkeleton isVisible={true}
                        className='w-100'
                        variant={[Enumerations.skeleton.text,
                        Enumerations.skeleton.text]} />
                    : <>
                        <div className="d-flex justify-content-between border-bottom py-2">
                            <NsTypography className='section-title'>
                                {getTranslatedRawText('adminPanel.cashback.qtyOfCustomers')}
                            </NsTypography>
                            <NsTypography className='section-title'>
                                {cashback?.countUserWithRegularPlanDiscount}
                            </NsTypography>
                        </div>
                        <div className="d-flex justify-content-between border-bottom py-2">
                            <NsTypography className='section-title'>
                                {getTranslatedRawText('adminPanel.cashback.totalSalesAmount')}
                            </NsTypography>
                            <NsTypography className='section-title'>
                                {CommonHelper.currencyFormatByDefaultConfig(cashback?.sumNetSaleUserWithRegularPlanDiscount)}
                            </NsTypography>
                        </div>
                        <div className="d-flex justify-content-between border-bottom py-2">
                            <NsTypography className='section-title'>
                                {getTranslatedRawText('adminPanel.cashback.totalCashbackAmount')}
                            </NsTypography>
                            <NsTypography className='section-title'>
                                {CommonHelper.currencyFormatByDefaultConfig(cashback?.sumCurrentDiscountAmountUserWithRegularPlanDiscount)}
                            </NsTypography>
                        </div>
                        <div className="d-flex justify-content-between border-bottom py-2">
                            <NsTypography className='section-title'>
                                {getTranslatedRawText('adminPanel.cashback.avgSalesAmount')}
                            </NsTypography>
                            <NsTypography className='section-title'>
                                {CommonHelper.currencyFormatByDefaultConfig(cashback?.averageSumNetSaleUserWithRegularPlanDiscount)}
                            </NsTypography>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                            <NsTypography className='section-title'>
                                {getTranslatedRawText('adminPanel.cashback.avgCashbackPercentage')}
                            </NsTypography>
                            <NsTypography className='section-title'>
                                {!!cashback?.averageDiscountPercentageUserWithRegularPlanDiscount ? cashback?.averageDiscountPercentageUserWithRegularPlanDiscount.toFixed(2) : 0}%
                            </NsTypography>
                        </div>
                    </>
                }
            </NsCard>
            <NsCard className="p-4">
                <NsTypography className='font-size-16 fw-bold mb-3'>
                    {getTranslatedRawText('adminPanel.cashback.customersSpecialPlan')}&nbsp;
                    <span className="font-size-14 fw-normal">{getTranslatedRawText('adminPanel.cashback.eligibleForCashback')}</span>
                </NsTypography>
                {isLoading
                    ? <NsSkeleton isVisible={true}
                        className='w-100'
                        variant={[Enumerations.skeleton.text,
                        Enumerations.skeleton.text]} />
                    : <>
                        <div className="d-flex justify-content-between border-bottom py-2">
                            <NsTypography className='section-title'>
                                {getTranslatedRawText('adminPanel.cashback.qtyOfCustomers')}
                            </NsTypography>
                            <NsTypography className='section-title'>
                                {cashback?.countUserWithSpecialDiscount}
                            </NsTypography>
                        </div>
                        <div className="d-flex justify-content-between border-bottom py-2">
                            <NsTypography className='section-title'>
                                {getTranslatedRawText('adminPanel.cashback.totalSalesAmount')}
                            </NsTypography>
                            <NsTypography className='section-title'>
                                {CommonHelper.currencyFormatByDefaultConfig(cashback?.sumNetSaleUserWithSpecialDiscount)}
                            </NsTypography>
                        </div>
                        <div className="d-flex justify-content-between border-bottom pt-2">
                            <NsTypography className='section-title'>
                                {getTranslatedRawText('adminPanel.cashback.totalCashbackAmount')}
                            </NsTypography>
                            <NsTypography className='section-title'>
                                {CommonHelper.currencyFormatByDefaultConfig(cashback?.sumCurrentDiscountAmountUserWithSpecialDiscount)}
                            </NsTypography>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                            <NsTypography className='section-title'>
                                {getTranslatedRawText('adminPanel.cashback.avgCashbackPercentage')}
                            </NsTypography>
                            <NsTypography className='section-title'>
                                {!!cashback?.averageDiscountPercentageUserWithSpecialDiscount ? cashback?.averageDiscountPercentageUserWithSpecialDiscount.toFixed(2) : 0}%
                            </NsTypography>
                        </div>
                    </>
                }
            </NsCard>
        </>
    );
};

export default React.memo(ApDcbCustomerPlanList);