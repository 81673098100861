import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { adminSidebarActionCreators } from '../../../redux/reducers/AdminSidebarReducer';

const ApLayoutContent = (props) => {
    const Component = props.component;
    const dispatch = useDispatch();
    const selectedItemIdChanged = () => {
        dispatch(adminSidebarActionCreators.selectedItemIdChanged(props.breadCrumb[0]));
    }

    useEffect(() => {
        selectedItemIdChanged()
    }, []);

    const store = useSelector(state => {
        return {
            isAdminSidebarOpen: state.AdminSidebarReducer.isOpen
        }
    });

    return (
        <main className={clsx('admin-panel__content p-2', store.isAdminSidebarOpen && 'admin-panel__content--shift')}
            style={{ "--drawerWidth": `${props.width}px`, }}>

            <Route
                render={matchProps => (
                    <Component {...props} {...matchProps} />
                )} />
        </main >
    );
}

export default React.memo(ApLayoutContent);