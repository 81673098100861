import React from 'react';

import { getUrlWithCurrentCulture } from '../../../../../utilities/CultureHelper';
import NsChooseFile from '../../../../../components/dragFiles/NsChooseFile';
import DelayedTaskHelper from '../../../../../utilities/DelayedTaskHelper';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import RegularOrderRouteHelper from '../../bases/RegularOrderRouteHelper';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import FilmFileModal from '../../modals/uploadingFilmFile/FilmFileModal';
import OrderHelper from '../../../../../utilities/OrderHelper';
import ChoosingFileWrapper from './ChoosingFileWrapper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsTour from '../../../../../components/tour/NsTour';
import Links from '../../../../../configs/links/AllLinks';
class FilmFile extends React.Component {

    constructor(props) {
        super(props);
        this.tourSteps = RegularOrderTours.getFilmFileTour();
        this.tourId = Enumerations.tourGuide.filmFile;
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = this.setStatesFromOrder(this.props);
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.match.params.filmNo !== this.props.match.params.filmNo)
            this.setState(this.setStatesFromOrder(newProps));
    }

    setStatesFromOrder = (props) => {
        if (OrderHelper.isValidOrder(this.routeId)) {
            let filmNo = CommonHelper.toInt(UrlHelper.getRouteValue(props, Enumerations.routeKey.filmNo));
            let files = OrderHelper.getFiles(this.routeId);
            let fileIndex = files.findIndex(f => f.key === filmNo);
            if (filmNo === 0 || fileIndex < 0) {
                filmNo = 0;
                RegularOrderRouteHelper.choosingFilesStep(this.routeId, this.props, 0);
            }

            this.getFilmFilesInfo();

            return {
                isFront: true,
                currentFilmKey: filmNo,
                isShowFilmFileModal: false,
                refreshFilePreviewSummaryDate: null,
                currentFilmModalTitle: files[filmNo].title,
                printedSide: OrderHelper.getPrintedSide(this.routeId).key,
                showTour: !CurrentUserHelper.isTourSeen(this.tourId),
            };
        }
    }

    openFilmFileModalClicked = (isFront) => {
        !CurrentUserHelper.isTourSeen(this.tourId) && CurrentUserHelper.addToSeenTours(this.tourId);
        this.setState({ isShowFilmFileModal: true, isFront, showTour: false });
    }

    closeFilmFileModalClicked = () => this.setState({ isShowFilmFileModal: false });

    saveFilmFileModalClicked = (selectedFilm, suggestedFiles) => {
        OrderHelper.updateFilesInStorages(this.routeId, this.state.currentFilmKey, selectedFilm, suggestedFiles, this.state.isFront, this.updateFilesCallback);
    }

    updateFilesCallback = () => {
        this.getFilmFilesInfo();
        this.closeFilmFileModalClicked();
    }

    getFilmFilesInfo = () => OrderHelper.getFilesWithFullInfo(this.routeId, Enumerations.presentableFileType.none, (files) => {
        this.setState({ film: files[this.state.currentFilmKey], refreshFilePreviewSummaryDate: new Date() });
    });

    render() {
        if (!OrderHelper.isValidOrder(this.routeId)) {
            this.props.history.replace(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts));
            return null;
        }
        const { film, isFront, printedSide, currentFilmKey, currentFilmModalTitle, isShowFilmFileModal, refreshFilePreviewSummaryDate, showTour } = this.state;

        return (
            <ChoosingFileWrapper otherProps={this.props}
                routeId={this.routeId}
                ChoosingFileCurrentStep={currentFilmKey}
                refreshFilePreviewSummaryDate={refreshFilePreviewSummaryDate} >

                <NsChooseFile printedSide={printedSide}
                    uploadFileClicked={this.openFilmFileModalClicked}
                    file={film} />

                {!!isShowFilmFileModal &&
                    <FilmFileModal
                        showModal={isShowFilmFileModal}
                        title={currentFilmModalTitle}
                        routeId={this.routeId}
                        isFront={isFront}
                        currentFilmKey={currentFilmKey}
                        saveFilmFileModalClicked={this.saveFilmFileModalClicked}
                        closeFilmFileModalClicked={this.closeFilmFileModalClicked} />
                }

                {showTour && <NsTour steps={this.tourSteps}
                    id={this.tourId} />}

            </ChoosingFileWrapper >
        );
    }
}

export default FilmFile