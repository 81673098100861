import React from 'react';

import { GetAdditionalInformationStoreOrderService } from '../../../../services/ProfileSetting/StoreOrderService';
import StoreOrderDetailPreviewContent from './StoreOrderDetailPreviewContent';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsModal from '../../../../components/modals/NsModal';

class StoreOrderDetailPreviewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resultData: null,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => GetAdditionalInformationStoreOrderService({ id: this.props.orderId }, this.getAdditionalOrderInformationCallback);

    getAdditionalOrderInformationCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError)
            this.setState({ resultData });
        else
            this.setState({ resultMessageClass });
    }

    showPunchModalClicked = (draft, resolution) => this.setState({ draft, resolution, showPunchModal: true });

    closeBillBookModalClicked = () => this.setState({ showBillBookModal: false });

    render() {

        const { resultData, resultMessageClass } = this.state;
        const { orderId } = this.props;

        return (
            <NsModal showModal={true}
                maxWidth='md'
                dialogTitle={getTranslatedRawText("profile.detail.orderDetail")}
                closeModal={this.props.closeModal}>

                <StoreOrderDetailPreviewContent resultData={resultData}
                    orderId={orderId}
                    showPunchModalClicked={this.showPunchModalClicked} />

                {resultMessageClass}

            </NsModal>
        );
    }
}

export default StoreOrderDetailPreviewModal;