import clsx from 'clsx';
import React from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

import CommonHelper from '../../utilities/CommonHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsCheckbox from '../inputs/NsCheckbox';

class NsAutocomplete extends React.Component {

    constructor(props) {
        super(props);
        this.input = '';
        this.state = {
            input: props.value ? this.checkAndSendValue(props.value, true) : '',
            helperText: '',
            invisibleHelperText: this.messageHelperText(),
        };
    }

    messageHelperText = () => {
        return CommonHelper.stringFormat(getTranslatedRawText("common.messages.enter"), this.props.label);
    }

    setInput = (e, value) => {
        this.setState({ input: value, helperText: '' });
        let isValidInput = this.getIsValidInput(value);
        this.checkAndSendValue(value, isValidInput);
    }

    checkAndSendValue = (value, isValidInput) => {
        CommonHelper.safeFunctionCall(this.props.onChange, value, isValidInput);
    }

    getIsValidInput = (value) => {
        if (isEmpty(value)) {
            this.setState({ invisibleHelperText: this.messageHelperText() });
            return false;
        } else {
            this.setState({ invisibleHelperText: '' });
            return true
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.showValidationDate !== this.props.showValidationDate)
            this.setState({ helperText: isEmpty(newProps.value) ? this.messageHelperText() : '' });
    }

    getLable = (option) => option?.value?.toString() || option?.label || option?.name || option?.productName || option?.title || '';

    render() {
        const { label, items, className, textFieldClassName, value, multiple, disabled, disableClearable } = this.props;
        return (
            <Autocomplete
                multiple={multiple}
                disabled={disabled}
                className={clsx('w-100', className)}
                options={items}
                limitTags={2}
                getOptionLabel={this.getLable}
                isOptionEqualToValue={(option, value) => JSON.stringify(option) == JSON.stringify(value)}
                value={!!value ? value : null}
                disableCloseOnSelect={multiple}
                disableClearable={disableClearable}
                size={this.props.size}
                onChange={this.setInput}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props}>
                            {multiple &&
                                <NsCheckbox
                                    value={selected} />
                            }
                            {this.getLable(option)}
                        </ li>
                    )
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        margin="normal"
                        variant="standard"
                        helperText={this.state.helperText}
                        className={clsx('ns-auto-complete', textFieldClassName)} />
                )} />
        )
    }
}

export default NsAutocomplete;