import React, { useState } from "react";
import clsx from "clsx";

import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import CommonHelper from "../../../../utilities/CommonHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsTab from "../../../../components/tabs/NsTab";

const ApBaseFeedbackStrengthsWeaknesses = (props) => {
    const { items, onChange } = props;
    const strengths = items.filter(item => item.feedbackTypeMetricType == Enumerations.feedbackMetricTypes.strengths);
    const weaknesses = items.filter(item => item.feedbackTypeMetricType == Enumerations.feedbackMetricTypes.weaknesses);
    const tabs = ConstantsWithTranslation.getFeedbackStrengthWeeknestabs(!isEmpty(strengths), !isEmpty(weaknesses));

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const tabChanged = (newValue, selectedTab) => {
        setSelectedTabIndex(newValue);
        setSelectedTab(selectedTab)
    }
    return <div className={clsx("border rounded-3", props.className)}>
        <NsTab tabs={tabs}
            tabClassName={`border-bottom ${tabs?.length === 1 ? 'w-100' : 'w-50'}`}
            value={selectedTabIndex}
            onChange={tabChanged} />
        <SelectionLabelBox items={items}
            feedbackTypeMetricType={selectedTab?.id}
            onChange={onChange} />
    </div>
}

export default React.memo(ApBaseFeedbackStrengthsWeaknesses);

function SelectionLabelBox(props) {
    const { items, feedbackTypeMetricType, onChange } = props;

    const [refreshDate, setRefreshDate] = useState(null);

    const itemClicked = (index) => {
        items[index].isSelected = !items[index].isSelected;
        setRefreshDate(new Date());
        CommonHelper.safeFunctionCall(onChange, items);
    }

    return <div key={refreshDate} className="d-flex justify-content-center flex-wrap gap-2 p-3 font-size-12">
        {
            !isEmpty(items) && items.map((item, index) => item.feedbackTypeMetricType == feedbackTypeMetricType && < div key={index} className={
                item.isSelected
                    ? `${feedbackTypeMetricType === Enumerations.feedbackMetricTypes.strengths
                        ? 'background-first-copy-color'
                        : 'background-second-copy-color'} border rounded p-1 cursor-pointer`
                    : 'border bg-white-color rounded p-1 cursor-pointer'
            }
                onClick={() => itemClicked(index)}>
                {item.title}
            </div>
            )
        }
    </div >
}
