import Links from "../../configs/links/AllLinks"
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest } from "../bases/BaseService"

const GetLatestFestivalService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.singlePage, Links.api.singlePage.getLatestFestival), {}, callback)
}

const GetIsWinnerService = (data, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.singlePage, Links.api.singlePage.getIsWinner), data, callback)
}

const GetFestivalEventDetailService = (id, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.singlePage, Links.api.singlePage.getFestivalEventDetail), { id }, callback);
}

const GetFestivalsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.singlePage, Links.api.singlePage.getFestivals), {}, callback);
}

export {
    GetFestivalEventDetailService,
    GetFestivalsService,
    GetLatestFestivalService,
    GetIsWinnerService
}