import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import NsButton from '../../../../components/buttons/NsButton';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';
import Links from '../../../../configs/links/AllLinks';
import { GetCmsBlogFilterCategoriesService, GetCmsNewsFilterCategoriesService } from '../../../../services/singlePages/CmsService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import BnBasesCategoryCard from '../bases/BnBasesCategoryCard';

class BnContentMainCategories extends React.Component {

    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.state = {
            categories: [],
            pageLength: 4,
            currentPageIndex: 1,
            isLoading: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.getCategories();

    getCategories = () => {
        const { pageLength, currentPageIndex } = this.state;
        let parameters = { pageLength, currentPageIndex };
        this.setState({ isLoading: true });
        this.isBlog
            ? GetCmsBlogFilterCategoriesService(parameters, this.getCmsCategoriesCallback)
            : GetCmsNewsFilterCategoriesService(parameters, this.getCmsCategoriesCallback);
    }

    getCmsCategoriesCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.setState({ categories: resultData.dataItems, totalCount: resultData.totalCount });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    };

    viewMoreClicked = () => {
        return this.isBlog
            ? getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogCategories)
            : getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsCategories);
    }

    mapCategories = () => {
        return this.state.isLoading
            ? CommonHelper.createArray(4).map((item, index) =>
                <div key={index} className="d-inline d-md-inline-block col-sm-6 p-2" >
                    <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                        ]} />
                </div >
            )
            : this.state.categories?.map((category, index) =>
                <Col key={index} md={6} className='px-1'>
                    <BnBasesCategoryCard category={category} />
                </Col>
            )
    }

    render() {
        return <>
            <Row className='g-1'>
                {this.mapCategories()}
            </Row>
            <div className='text-center my-2'>
                {!isEmpty(this.state.categories) && <NsButton className='light'>
                    <Link to={() => this.viewMoreClicked()} className='d-flex justify-content-center w-100 black-color text-decoration-none'>
                        {getTranslatedRawText('product.common.viewMore')}
                    </Link>
                </NsButton>}
            </div>
            {this.state.resultMessageClass}
        </>
    }
}

export default withRouter(BnContentMainCategories);