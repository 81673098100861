import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import ApBasesDashboardHeader from '../bases/ApBasesDashboardHeader';
import ApDbOnlinePaymentStatus from './ApDbOnlinePaymentStatus';
import ApDbTotalOnlinePayments from './ApDbTotalOnlinePayments';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import DateHelper from '../../../../utilities/DateHelper';
import ApDbOrderTypeSales from './ApDbOrderTypeSales';
import ApDbTotalOrdering from './ApDbTotalOrdering';

const ApDashboardBusiness = (props) => {

    const [dates, setDates] = useState([new Date(DateHelper.getCustomPreviousMonth(2)), new Date()]);
    const [dateRangeType, setDateRangeType] = useState(ConstantsWithTranslation.getDateRanges()[0].key);
    const [refreshDate, setRefreshDate] = useState();
    const [userId, setUserId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [stateId, setStateId] = useState(null);

    const refreshClicked = useCallback((dates, isRefreshDate, dateRange, userId, stateId, cityId) => {
        setDateRangeType(dateRange);
        setDates(dates);
        setUserId(userId);
        setStateId(stateId);
        setCityId(cityId);
        isRefreshDate && setRefreshDate(new Date());
    })

    const parameters = {
        submitTimeFrom: DateHelper.convertDateTimeToDate(new Date(dates[0])),
        submitTimeTo: dates[1],
        timeIntervalType: dateRangeType,
        userId,
        stateId,
        cityId
    }

    return (
        <Row className='d-flex flex-column m-0 px-0 gap-2' >
            <Col>
                <ApBasesDashboardHeader title='adminPanel.dashboard.businessDashboard'
                    refreshClicked={refreshClicked}
                    hasStateFilter={true}
                    breadCrumb={props.breadCrumb} />
            </Col>
            {hasAccess([UserClaimEnumerations.getApDbTotalSales]) &&
                <Col >
                    <ApDbOrderTypeSales parameters={parameters} refreshDate={refreshDate} />
                </Col>
            }
            <Row className='ms-0'>
                {hasAccess([UserClaimEnumerations.getApDbTotalOnlinePayments]) &&
                    <ApDbTotalOnlinePayments parameters={parameters} refreshDate={refreshDate} />
                }
                {hasAccess([UserClaimEnumerations.getApDbOnlinePaymentStatus]) &&
                    <ApDbOnlinePaymentStatus parameters={parameters} refreshDate={refreshDate} />
                }
            </Row>
            <Row className='m-0 align-self-stretch px-0 gy-2 gy-md-0'>
                {hasAccess([UserClaimEnumerations.getApDbTotalOrders]) &&
                    <Col className='mt-0' lg={7}>
                        <ApDbTotalOrdering parameters={parameters} refreshDate={refreshDate} />
                    </Col>
                }
            </Row>
        </Row >
    )
};

export default React.memo(ApDashboardBusiness);