import Enumerations from "../../../configs/Enumerations";
import Links from "../../../configs/links/AllLinks";
import { getUrlWithCurrentCulture } from "../../../utilities/CultureHelper";

class BreadcrumbsReducerHelper {

    static createProfileUserGroupSheetOrderBreadcrumbs(enu) {
        let breadcrumbs = [{
            id: Enumerations.breadCrumbs.orderCard,
            name: 'profile.actionButton.groupSheetOrder',
            link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.preparing)
        }];

        switch (enu) {
            case Enumerations.breadCrumbs.orderCard:
                return [
                    {
                        id: Enumerations.breadCrumbs.orderCard,
                        name: 'profile.actionButton.cart',
                        link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder)
                    }
                ];
            case Enumerations.breadCrumbs.orderPreparing:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.orderPreparing,
                        name: 'profile.actionButton.preparing',
                        link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.preparing)
                    }
                ];
            case Enumerations.breadCrumbs.orderInProcess:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.orderInProcess,
                        name: 'profile.actionButton.inProcess',
                        link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrder)
                    }
                ];
            case Enumerations.breadCrumbs.orderReady:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.orderReady,
                        name: 'profile.actionButton.readyToDeliver',
                        link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.readyToDeliverOrder)
                    }
                ];
            case Enumerations.breadCrumbs.orderArchive:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.orderArchive,
                        name: 'profile.actionButton.archive',
                        link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.archiveOrder)
                    }
                ];
            case Enumerations.breadCrumbs.userOrdersQueue:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.userOrdersQueue,
                        name: 'adminPanel.sidebar.queue',
                        link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.groupSheetOrderQueueStatus)
                    }
                ];
            case Enumerations.breadCrumbs.orderAll:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.orderAll,
                        name: 'profile.actionButton.allOrders',
                        link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.allOrders)
                    }
                ];
            default:
                return [];
        }
    }
    static createProfileUserStoreOrderBreadcrumbs(enu) {
        let breadcrumbs = [{
            id: Enumerations.breadCrumbs.orderCard,
            name: 'profile.actionButton.storeOrder',
            link: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.inProcessOrder)
        }];

        switch (enu) {
            case Enumerations.breadCrumbs.storeOrderInProcess:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.storeOrderInProcess,
                        name: 'profile.actionButton.inProcess',
                        link: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.inProcessOrder)
                    }
                ];
            case Enumerations.breadCrumbs.storeOrderReady:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.storeOrderReady,
                        name: 'profile.actionButton.readyToDeliver',
                        link: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.readyToDeliverOrderInTheWarehouse)
                    }
                ];
            case Enumerations.breadCrumbs.storeOrderArchive:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.storeOrderArchive,
                        name: 'profile.actionButton.archive',
                        link: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.archiveOrderDelivered)
                    }
                ];
            default:
                return [];
        }
    }

    static createProfileMyShippingBreadcrumbs(enu) {
        let breadcrumbs = [{
            id: Enumerations.breadCrumbs.myShipping,
            name: 'profile.actionButton.deliveryRequest',
            link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequest)
        }];
        switch (enu) {
            case Enumerations.breadCrumbs.myShipping:
                return [
                    ...breadcrumbs
                ];
            case Enumerations.breadCrumbs.myShippingDetail:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.myShippingDetail,
                        name: 'common.breadcrumbs.detail',
                        link: '#'
                    }
                ];
            default:
                return [];
        }
    }

    static createProfileReportsBreadcrumbs(enu) {
        let breadcrumbs = [{
            id: Enumerations.breadCrumbs.reportPayment,
            name: 'profile.actionButton.report',
            link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderPaymentReport)
        }];
        switch (enu) {
            case Enumerations.breadCrumbs.reportPayment:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.reportPayment,
                        name: 'profile.actionButton.payment',
                        link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderPaymentReport)
                    }
                ];
            case Enumerations.breadCrumbs.reportFinancial:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.myShippingDetail,
                        name: 'profile.actionButton.debitCredit',
                        link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderFinancialReport)
                    }
                ];
            default:
                return [];
        }
    }

    static createProfileDashboardBreadcrumbs(enu) {
        let breadcrumbs = [{
            id: Enumerations.breadCrumbs.dashboard,
            name: 'common.breadcrumbs.dashboard',
            link: getUrlWithCurrentCulture(Links.view.main.userDashboard, Links.view.main.userDashboard.cashback)
        }];
        switch (enu) {
            case Enumerations.breadCrumbs.cashback:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.cashback,
                        name: 'profile.actionButton.cashback',
                        link: getUrlWithCurrentCulture(Links.view.main.userDashboard, Links.view.main.userDashboard.cashback)
                    }
                ];
            default:
                return [];
        }
    }

    static createProfileInformationBreadcrumbs(enu) {
        let breadcrumbs = [{
            id: Enumerations.breadCrumbs.profileInformation,
            name: 'common.breadcrumbs.profile',
            link: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationSetting)
        }];
        switch (enu) {
            case Enumerations.breadCrumbs.profileInformation:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.profileInformation,
                        name: 'profile.actionButton.profileInfo',
                        link: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationSetting)
                    }
                ];
            case Enumerations.breadCrumbs.profileFeebacks:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.profileFeebacks,
                        name: 'profile.feedback.feedbacks',
                        link: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileFeedbackSetting)
                    }
                ];
            case Enumerations.breadCrumbs.profileNotification:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.profileNotification,
                        name: 'profile.actionButton.notificationSetting',
                        link: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileNotificationSetting)
                    }
                ];
            case Enumerations.breadCrumbs.profileAgency:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.profileAgency,
                        name: 'profile.actionButton.agencySetting',
                        link: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAgencySetting)
                    }
                ];

            case Enumerations.breadCrumbs.profileAutoShipping:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.profileAutoShipping,
                        name: 'profile.actionButton.autoDeliverySetting',
                        link: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAutoShippingSetting)
                    }
                ];
            case Enumerations.breadCrumbs.profileAddress:
                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.profileAddress,
                        name: 'profile.actionButton.address',
                        link: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAddressSetting)
                    }
                ];
            default:
                return [];
        }
    }

    static createSinglePageBreadcrumbs(enu) {
        switch (enu) {

            case Enumerations.breadCrumbs.aboutUs:
                return [
                    {
                        id: Enumerations.breadCrumbs.aboutUs,
                        name: 'common.breadcrumbs.about',
                        link: getUrlWithCurrentCulture(Links, Links.aboutUs)
                    }
                ];
            case Enumerations.breadCrumbs.contactUs:
                return [
                    {
                        id: Enumerations.breadCrumbs.contactUs,
                        name: 'common.breadcrumbs.contactUs',
                        link: getUrlWithCurrentCulture(Links, Links.contactUs)
                    }
                ];
            case Enumerations.breadCrumbs.instantDiscounts:
                return [
                    {
                        id: Enumerations.breadCrumbs.instantDiscounts,
                        name: 'common.breadcrumbs.instantDiscounts',
                        link: getUrlWithCurrentCulture(Links, Links.instantDiscounts)
                    }
                ];
            case Enumerations.breadCrumbs.faqs:
                return [
                    {
                        id: Enumerations.breadCrumbs.faqs,
                        name: 'common.breadcrumbs.FAQs',
                        link: getUrlWithCurrentCulture(Links, Links.frequentlyAskedQuestions)
                    }
                ];
            case Enumerations.breadCrumbs.gallery:
                return [
                    {
                        id: Enumerations.breadCrumbs.gallery,
                        name: 'common.breadcrumbs.gallery',
                        link: getUrlWithCurrentCulture(Links, Links.gallery)
                    }
                ];
            case Enumerations.breadCrumbs.galleryCategories:
                return [
                    {
                        id: Enumerations.breadCrumbs.gallery,
                        name: 'common.breadcrumbs.gallery',
                        link: getUrlWithCurrentCulture(Links, Links.gallery)
                    },
                    {
                        id: Enumerations.breadCrumbs.galleryCategories,
                        name: 'common.breadcrumbs.galleryCategories',
                        link: getUrlWithCurrentCulture(Links, Links.galleryCategoryPosts)
                    },
                ];
            case Enumerations.breadCrumbs.ourEquipment:
                return [
                    {
                        id: Enumerations.breadCrumbs.ourEquipment,
                        name: "common.breadcrumbs.ourEquipment",
                        link: getUrlWithCurrentCulture(Links, Links.ourEquipment),
                    },
                ];
            case Enumerations.breadCrumbs.termOfUse:
                return [
                    {
                        id: Enumerations.breadCrumbs.termOfUse,
                        name: "common.breadcrumbs.termsOfUse",
                        link: getUrlWithCurrentCulture(Links, Links.termsOfUse),
                    },
                ];
            case Enumerations.breadCrumbs.privacyPolicy:
                return [
                    {
                        id: Enumerations.breadCrumbs.privacyPolicy,
                        name: 'common.breadcrumbs.privacyPolicy',
                        link: getUrlWithCurrentCulture(Links, Links.privacyPolicy)
                    }
                ];
            case Enumerations.breadCrumbs.warrantyTerms:
                return [
                    {
                        id: Enumerations.breadCrumbs.warrantyTerms,
                        name: 'common.breadcrumbs.warrantyTerms',
                        link: getUrlWithCurrentCulture(Links, Links.warrantyTerms)
                    }
                ];
            case Enumerations.breadCrumbs.festivals:
                return [
                    {
                        id: Enumerations.breadCrumbs.festivals,
                        name: 'common.breadcrumbs.festivals',
                        link: getUrlWithCurrentCulture(Links, Links.festivals)
                    }
                ];
            case Enumerations.breadCrumbs.siteMap:
                return [
                    {
                        id: Enumerations.breadCrumbs.siteMap,
                        name: 'common.breadcrumbs.siteMap',
                        link: getUrlWithCurrentCulture(Links, Links.siteMap)
                    }
                ];
            case Enumerations.breadCrumbs.downloadCenter:
                return [
                    {
                        id: Enumerations.breadCrumbs.downloadCenter,
                        name: 'common.breadcrumbs.downloadCenter',
                        link: getUrlWithCurrentCulture(Links, Links.downloadCenter)
                    }
                ];
            case Enumerations.breadCrumbs.downloadDetail:
                return [
                    {
                        id: Enumerations.breadCrumbs.downloadCenter,
                        name: 'common.breadcrumbs.downloadCenter',
                        link: getUrlWithCurrentCulture(Links, Links.downloadCenter)
                    },
                    {
                        id: Enumerations.breadCrumbs.downloadDetail,
                        name: 'common.breadcrumbs.downloadDetail',
                        link: '#'
                    }
                ];
            case Enumerations.breadCrumbs.specialNeeds:
                return [
                    {
                        id: Enumerations.breadCrumbs.specialNeeds,
                        name: 'common.breadcrumbs.specialNeeds',
                        link: getUrlWithCurrentCulture(Links, Links.specialNeeds)
                    }
                ];
            case Enumerations.breadCrumbs.saveOutputInPdfFormat:
                return [
                    {
                        id: Enumerations.breadCrumbs.saveOutputInPdfFormat,
                        name: 'common.breadcrumbs.saveOutputInPdfFormat',
                        link: getUrlWithCurrentCulture(Links, Links.saveOutputInPdfFormat)
                    }
                ];
            case Enumerations.breadCrumbs.productCategories:
                return [
                    {
                        id: Enumerations.breadCrumbs.productCategories,
                        name: 'common.breadcrumbs.productCategories',
                        link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.productCategories)
                    }
                ];

            default:
                return [];
        }
    }

    static createNewsBlogBreadcrumbs(enu, isBlog) {
        let breadcrumbs = isBlog
            ? [{
                id: Enumerations.breadCrumbs.blogs,
                name: 'common.breadcrumbs.blogs',
                link: getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogs)
            }]
            : [{
                id: Enumerations.breadCrumbs.news,
                name: 'common.breadcrumbs.news',
                link: getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.news)
            }];
        switch (enu) {
            case Enumerations.breadCrumbs.blogs:

                return [
                    ...breadcrumbs,
                ];
            case Enumerations.breadCrumbs.blogCategories:

                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.blogCategories,
                        name: 'common.breadcrumbs.blogCategories',
                        link: getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogCategories)
                    },
                ];
            case Enumerations.breadCrumbs.blogCategoryPosts:

                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.blogCategories,
                        name: 'common.breadcrumbs.blogCategories',
                        link: getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogCategories)
                    },
                    {
                        id: Enumerations.breadCrumbs.blogCategoryPosts,
                        name: 'common.breadcrumbs.blogCategoryPosts',
                        link: '#'
                    },
                ];
            case Enumerations.breadCrumbs.blogPostDetail:

                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.blogPostDetail,
                        name: 'common.breadcrumbs.blogPostDetail',
                        link: '#'
                    },
                ];
            case Enumerations.breadCrumbs.blogPosts:

                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.blogPosts,
                        name: 'common.breadcrumbs.blogPosts',
                        link: '#'
                    },
                ];
            case Enumerations.breadCrumbs.news:

                return [
                    ...breadcrumbs,
                ];
            case Enumerations.breadCrumbs.newsCategories:

                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.newsCategories,
                        name: 'common.breadcrumbs.newsCategories',
                        link: getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsCategories)
                    },
                ];
            case Enumerations.breadCrumbs.newsCategoryPosts:

                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.newsCategories,
                        name: 'common.breadcrumbs.newsCategories',
                        link: getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsCategories)
                    },
                    {
                        id: Enumerations.breadCrumbs.newsCategoryPosts,
                        name: 'common.breadcrumbs.newsCategoryPosts',
                        link: '#'
                    },
                ];
            case Enumerations.breadCrumbs.newsPostDetail:

                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.newsPostDetail,
                        name: 'common.breadcrumbs.newsPostDetail',
                        link: '#'
                    },
                ];
            case Enumerations.breadCrumbs.newsPosts:

                return [
                    ...breadcrumbs,
                    {
                        id: Enumerations.breadCrumbs.newsPosts,
                        name: 'common.breadcrumbs.newsPosts',
                        link: '#'
                    },
                ];
            default: return [];
        }
    }
}

export default BreadcrumbsReducerHelper;