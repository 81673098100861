import React from 'react';

import NsBrowseFileButton from '../../../../components/buttons/NsBrowseFileButton';
import FileCorrectionUploadingProgress from './FileCorrectionUploadingProgress';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import Config from '../../../../configs/Config';
import CommonHelper from '../../../../utilities/CommonHelper';

class FileCorrectionUploadingFile extends React.Component {

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.validFilePickerExtensions = Config.validFilePickerExtensions;
        this.state = {
            files: [],
            uploadedFilesIds: [],
        }
    }

    setSelectedFile = (file, checkFileExtension) => {
        if (checkFileExtension) {
            this.setState(oldState => ({
                ...oldState,
                files: [
                    {
                        name: file.name || '',
                        size: file.size || 0,
                        id: Math.random(),
                        fileContent: file,
                    },
                    ...oldState.files,
                ]
            }));
        } else {
            this.setState({
                resultMessageClass: messageClass(CommonHelper.stringFormat('{0} {1}',
                    file.name, getTranslatedRawText('regularOrder.messages.formatIsWrong')))
            });
        }
    }

    getCanceledProgressId = (id) => {
        const filteredFiles = this.state.files.filter(file => file.id !== id);
        this.setState({ files: filteredFiles });
    }

    setUploadedFiles = (id) => {
        this.setState({ uploadedFilesIds: [...this.state.uploadedFilesIds, id] }
            , () => CommonHelper.safeFunctionCall(this.props.selectedFiles, this.state.uploadedFilesIds));
    }

    render() {
        const { files } = this.state;

        return (
            <div className='wrapper-element d-flex flex-column gap-2 p-2'>

                <NsBrowseFileButton className='d-flex ms-auto'
                    setSelectedFile={this.setSelectedFile} />

                {!isEmpty(files) && files.map(file =>
                    <FileCorrectionUploadingProgress file={file.fileContent}
                        key={file.id}
                        id={file.id}
                        setUploadedFiles={this.setUploadedFiles}
                        getCanceledProgressId={this.getCanceledProgressId} />
                )}
            </div>
        );
    }
}

export default FileCorrectionUploadingFile;