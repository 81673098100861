import React, { useState } from 'react';

import NsCard from '../../../../components/cards/NsCard';
import NsExportExcelReportButton from '../../../../components/downloadFiles/NsExportExcelReportButton';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsTab from '../../../../components/tabs/NsTab';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../configs/Enumerations';
import { GetApUserDuplicateCompanyNameExcelReportService, GetApUserDuplicateCompanyNameService, GetApUserDuplicateFullNameExcelReportService, GetApUserDuplicateFullNameService, GetApUserDuplicatePhoneNumbersExcelReportService, GetApUserDuplicatePhoneNumbersService } from '../../../../services/adminPanel/AdminUserService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

const ApDuDuplicatePhoneNumbers = ({ parameters, refreshDate }) => {

    const tabs = ConstantsWithTranslation.getUsersDashboardDuplicateUsersTab();
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(new Date());
    const [resultMessageClass, setResultMessageClass] = useState((<></>));

    const columns = [
        {
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.user 1'),
            headerClass: 'background-element-color border-outline-color-1px',
            children: [
                {
                    field: 'detailLedgerId',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.detailLedger'),
                    width: 120
                },
                {
                    field: 'fullName',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
                    width: 190
                },
                {
                    field: 'phoneNumber',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
                    width: 200,
                },
                {
                    field: 'companyName',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.companyName'),
                    width: 300
                },

            ]
        },
        {
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.user 2'),
            headerClass: 'background-element-color border-outline-color-1px',
            children: [
                {
                    field: 'subUser.detailLedgerId',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.detailLedger'),
                    width: 120
                },
                {
                    field: 'subUser.fullName',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
                    width: 190
                },
                {
                    field: 'subUser.phoneNumber',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
                    width: 200,
                },
                {
                    field: 'subUser.companyName',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.companyName'),
                    width: 300
                },

            ]
        }
    ]

    const tabChanged = (selectedTabIndex, selectedTab) => {
        setSelectedTabIndex(selectedTabIndex);
        setSelectedTab(selectedTab)
    }

    const getService = () => {
        switch (selectedTab?.id) {
            case fullName: return { gridService: GetApUserDuplicateFullNameService, excelService: GetApUserDuplicateFullNameExcelReportService };
            case companyName: return { gridService: GetApUserDuplicateCompanyNameService, excelService: GetApUserDuplicateCompanyNameExcelReportService };
            default: return { gridService: GetApUserDuplicatePhoneNumbersService, excelService: GetApUserDuplicatePhoneNumbersExcelReportService };
        }
    }

    const { fullName, companyName, mobile } = Enumerations.duplicateUsersbaseType;
    return (
        <NsCard className='p-3 h-100'>
            <div className='fw-bold mb-3'>
                {getTranslatedRawText('adminPanel.dataGridColumn.usersWhoRegisterWithTwoNumber')}
            </div>
            <div className='d-flex'>
                <NsExportExcelReportButton isLoading={isLoading}
                    buttonSize='small'
                    service={getService()?.excelService} />
            </div>
            <NsTab tabs={tabs}
                value={selectedTabIndex}
                onChange={tabChanged}>
            </NsTab>
            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={getService()?.gridService}
                parameters={{
                    'Filter.SubmitTimeFrom': parameters.submitTimeFrom,
                    'Filter.SubmitTimeTo': parameters.submitTimeTo,
                }}
                columnDefs={columns}
                hasPagination={true} />
            {resultMessageClass}

        </NsCard>
    );
};

export default React.memo(ApDuDuplicatePhoneNumbers);