import ConstantsWithTranslation from '../../configs/ConstantsWithTranslation';
import Enumerations from '../../configs/Enumerations';
import Links from '../../configs/links/AllLinks';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PostRequest } from '../bases/BaseService';

const GetAmountOfPaymentService = (paymentId, callback) => {
    const parameters = { paymentId };
    GetRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment.getAmountOfPayment), parameters, callback);
}

const GetAccountingExcelReportService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment.getAccountingExcelReport), parameters, callback);
}

const GetPaymentExcelReportService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment.getPaymentExcelReport), parameters, callback);
}

const GetPaymentGatewaysService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment.getPaymentGateways), {}, callback);
}

const GetPaymentUrlService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment.getPaymentUrl), data, callback);
}

const GetPaymentDetailsService = (URLParameters, callback) => {
    const paymentGateways = ConstantsWithTranslation.getPaymentGateways();
    const selectedGateWayLink = paymentGateways.find(gateway => gateway.id === Number(URLParameters.paymentGatewayTypeId))?.link;
    let parameters;
    switch (Number(URLParameters.paymentGatewayTypeId)) {
        case Enumerations.paymentGateway.sepehr:
            parameters = {
                PaymentGatewayId: URLParameters.paymentGatewayId,
                RespCode: URLParameters.respcode,
                RespMsg: URLParameters.respmsg,
                TerminalId: URLParameters.terminalid,
                Amount: URLParameters.amount,
                InvoiceId: URLParameters.invoiceid,
                TraceNumber: URLParameters.tracenumber,
                Rrn: URLParameters.rrn,
                DatePaid: URLParameters.datepaid,
                DigitalReceipt: URLParameters.digitalreceipt,
                IssuerBank: URLParameters.issuerbank,
                CardNumber: URLParameters.cardnumber,
                TrackNumber: URLParameters.trackNumber || URLParameters.trackingNo,
            }
            break;
        case Enumerations.paymentGateway.asanPardakht:
            parameters = {
                PaymentGatewayId: URLParameters.paymentGatewayId,
                PaymentId: URLParameters.paymentId,
                TrackNumber: URLParameters.trackNumber || URLParameters.trackingNo,
            }
            break;
        case Enumerations.paymentGateway.nGeniusPayment:
            parameters = {
                ReferenceCode: URLParameters.ref || URLParameters.referenceNo,
                PaymentGatewayId: Number(URLParameters.paymentGatewayId),
                TrackNumber: URLParameters.trackNumber || URLParameters.trackingNo,
            }
            break;

        default:
            break;
    }
    GetRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment[selectedGateWayLink]), parameters, callback);
}

const PostAmountForIncreaseCreditService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment.postAmountToIncreaseCredit), data, callback);
}

const PostPaymentForOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment.postPaymentForOrders), data, callback);
}

const PostPaymentForStoreProductOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment.postPaymentForStoreProductOrders), data, callback);
}

const GetPaymentReportService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.payment, Links.api.payment.getPaymentReport), parameters, callback);
}

export {
    GetAmountOfPaymentService,
    GetPaymentGatewaysService,
    PostAmountForIncreaseCreditService,
    GetPaymentUrlService,
    GetPaymentDetailsService,
    PostPaymentForOrdersService,
    PostPaymentForStoreProductOrdersService,
    GetPaymentReportService,
    GetAccountingExcelReportService,
    GetPaymentExcelReportService
};
