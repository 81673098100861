import React, { useState } from 'react';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';

const NsInfoBox = (props) => {

    const { isShow, content, title, subject } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (isShow &&
        <div className='card mt-3 p-2 font-size-12'>
            <div className='d-flex flex-start align-items-center'>
                <i className='ns-icon-information h5 m-0'></i>
                <span className='fw-bold mx-1'>
                    {title}
                </span>
                <i className={`${isOpen
                    ? 'ns-icon-expand-up'
                    : 'ns-icon-expand-down'} cursor-pointer h5 m-0`}
                    onClick={() => setIsOpen(!isOpen)} ></i>
            </div>
            <div className={`${isOpen ? 'd-flex' : 'd-none'} align-items-center mt-2`}>
                <div className='me-1 fw-bold'>{subject}</div>
                {isEmpty(content)
                    ? getTranslatedRawText('common.noDescription')
                    : content}
            </div>
        </div>
    );
};

export default NsInfoBox;