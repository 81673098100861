import React from "react";
import { Col, Row } from "react-bootstrap";

import NsCarouselSwiper from "../../../../../components/carousel/NsCarouselSwiper";
import NsImageCarousel from "../../../../../components/carousel/NsImageCarousel";
import NsImage from "../../../../../components/images/NsImage";
import NsModal from "../../../../../components/modals/NsModal";
import NsSkeleton from "../../../../../components/skeletons/NsSkeleton";
import Enumerations from "../../../../../configs/Enumerations";
import { GetFestivalEventDetailService } from "../../../../../services/singlePages/FestivalService";
import CommonHelper from "../../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";

class FestivalAwardModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStep: null,
            festivalDetail: null,
            awardImages: [],
            isLoading: false,
            lotteryEventAwardWinners: []
        }
    }

    componentDidMount = () => this.getFestivalEventDetail();

    onClose = () => CommonHelper.safeFunctionCall(this.props.onClose, false);

    imageChanged = (activeStep) => this.setState({ activeStep });

    getFestivalEventDetail = () => {
        this.setState({ isLoading: true });
        GetFestivalEventDetailService(this.props.festivalId, this.getFestivalEventDetailCallback);
    }

    getFestivalEventDetailCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            this.setState({
                festivalDetail: resultData,
            }, () => {
                let selectedAwardDetail = this.state.festivalDetail.lotteryEventAwards.find((award) => award.id === this.props.selectedAward.id);
                this.setState({
                    awardImages: [selectedAwardDetail.imageUrl],
                    lotteryEventAwardWinners: selectedAwardDetail.lotteryEventAwardWinners
                });
            }
            );
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        const { awardImages, lotteryEventAwardWinners } = this.state;
        return (
            <NsModal dialogTitle={this.props.selectedAward.name}
                showModal={this.props.showWinnerModal}
                maxWidth="lg"
                closeModal={this.onClose}>
                <Row>
                    <Col xs={12} md={6}>
                        {!this.state.isLoading
                            ? awardImages.length > 1
                                ? <>
                                    <NsImageCarousel imageList={awardImages.map((image) => {
                                        return { src: CommonHelper.createImageUrl(image.imageUrl) }
                                    })}
                                        activeIndex={this.state.activeStep} />
                                    <div>
                                        <NsCarouselSwiper images={awardImages}
                                            isFestivalAwardModal={true}
                                            imageChanged={(activeStep) => this.imageChanged(activeStep)}
                                            activeStep={this.state.activeStep} />
                                    </div>
                                </>
                                : <NsImage src={CommonHelper.createImageUrl(awardImages[0])}
                                    imgAlt="components.imagesAlt.festivalPrize"
                                    width={"100%"} />
                            : <NsSkeleton isVisible={true}
                                variant={[
                                    Enumerations.skeleton.rect,
                                ]} />}
                    </Col>
                    <Col xs={12} md={6}>
                        {!this.state.isLoading
                            ? lotteryEventAwardWinners.map((notif, index) => {
                                return <p key={index} className='background-red-color rounded p-2 white-color'>
                                    <span>{getTranslatedRawText('festival.theOneOf')}</span>
                                    <span className="fw-bold">{this.props.selectedAward.name}</span>
                                    <span>{getTranslatedRawText('festival.winnerIs')}</span>
                                    <span className="fw-bold">{notif.tarafTafsiliName}</span>
                                    <span> {getTranslatedRawText('common.and')} </span>
                                    <span className="fw-bold"> {notif.code} </span>
                                    <span>{getTranslatedRawText('festival.winningCode')}</span>
                                </p>
                            })
                            : <NsSkeleton isVisible={true}
                                variant={[
                                    Enumerations.skeleton.text,
                                    Enumerations.skeleton.text,
                                    Enumerations.skeleton.text,
                                    Enumerations.skeleton.text,
                                ]} />}
                    </Col>
                </Row>
            </NsModal >
        )
    }
}

export default FestivalAwardModal;