import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import clsx from 'clsx';

import { GetAuditGatewaysService, UploadAuditFile } from '../../../../../services/adminPanel/AdminPaymentService';
import NsUploadingFileProgress from '../../../../../components/progresses/NsUploadingFileProgress';
import NsCircularProgress from '../../../../../components/circularProgress/NsCircularProgress';
import ApPaymentAuditImportedFileDetailModal from './ApPaymentAuditImportedFileDetailModal';
import guidanceAsanpardakht from '../../../../../assets/images/guidance-asanpardakht.png';
import guidanceNgenius from '../../../../../assets/images/guidance_Ngenius.svg';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import NsAutocomplete from '../../../../../components/dropdowns/NsAutoComplete';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import guidanceSepehr from '../../../../../assets/images/guidance-sepehr.png';
import { messageClass } from '../../../../../utilities/MessageModalHelper';
import { CancelRequest } from '../../../../../services/bases/BaseService';
import NsDragFile from '../../../../../components/dragFiles/NsDragFile';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import NsImage from '../../../../../components/images/NsImage';

const ApPaymentAuditImportFileModal = (props) => {

    const [cancelationUploadingReference, setCancelationUploadingReference] = useState(null)
    const [selectedGateway, setSelectedGateway] = useState(null);
    const [file, setFile] = useState(null);
    const [auditGateways, setAuditGateways] = useState([]);
    const [progress, setProgress] = useState({
        loaded: 0,
        total: 0,
        percent: 0,
        status: Enumerations.uploadingStatus.prepareForUpload,
        message: null
    });
    const [fileSourceId, setFileSourceId] = useState(null);
    const [stepMessage, setStepMessage] = useState(null);
    const [showDetailImportedFile, setShowDetailImportedFile] = useState(false);
    const [showGuidanceModal, setShowGuidanceModal] = useState(false);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [messageItems, setMessageItems] = useState(<></>);
    const [isCloseModal, setIsCloseModal] = useState(false);
    const [guidanceImage, setGuidanceImage] = useState();
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        GetAuditGatewaysService(getAuditGatewaysCallback);
    }, [])

    const getAuditGatewaysCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError)
            setAuditGateways(resultData);
        else
            setMessageItems(messageClassModal);
    }

    const selectedFileFromBrowser = (file, checkFileExtention) => {
        if (checkFileExtention) {
            setFile(file);
            setFileSourceId(null);
            setProgress({ loaded: 0, total: 0, percent: 0, status: Enumerations.uploadingStatus.prepareForUpload, message: null });
            setStepMessage(getTranslatedRawText('components.nsLinearProgress.prepareForUpload'));
        } else
            setResultMessageClass(messageClass(CommonHelper.stringFormat('{0} {1}',
                file.name, getTranslatedRawText('regularOrder.messages.formatIsWrong'))));
    }

    const onUploadProgressCallback = (id, loaded, total, percent, status) =>
        setProgress({ ...progress, loaded, total, percent, status });

    const uploadFileCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError) {
            let newProgress = progress;
            newProgress.status = Enumerations.uploadingStatus.finished;
            newProgress.file = file;
            newProgress.total = resultData?.volume
            setProgress(newProgress);
            setFileSourceId(resultData.sourceId);
        } else {
            setProgress({
                ...progress,
                status: Enumerations.uploadingStatus.failed,
                message: messageClassModal.props
            });
        }
    }

    const startUploadingClicked = () => {
        if (!!fileSourceId) {
            CommonHelper.safeFunctionCall(props.closeModal);
            return;
        }
        setIsLoading(true);
        setCancelationUploadingReference(UploadAuditFile(file, selectedGateway.key, uploadFileCallback, onUploadProgressCallback));
    }

    const closeQuestionModal = (confirm) => {
        if (!!confirm) {
            CancelRequest(cancelationUploadingReference);
            isCloseModal && CommonHelper.safeFunctionCall(props.closeModal);
        }

        setShowQuestionModal(false);
        setMessageItems([]);
    }

    const cancelUploadingClicked = (isCloseModal) => {
        if (progress.status === Enumerations.uploadingStatus.uploading) {
            setShowQuestionModal(true);
            setIsCloseModal(isCloseModal);
            setMessageItems([{
                messageText: (
                    <span>{getTranslatedRawText('common.messages.areYouSureToCancelUploadingFile')}</span>
                ),
                messageType: Enumerations.messageType.question
            }]);
        } else
            CommonHelper.safeFunctionCall(props.closeModal);
    }

    const selectedGatewayChanged = (value) => {
        setSelectedGateway(value);
        setFile(null);
        setFileSourceId(null);
    }

    const importedFiledetailModalClicked = () => setShowDetailImportedFile(!showDetailImportedFile);

    const mainButtons = () => {
        const isSepehr = selectedGateway?.key == Enumerations.paymentGateway.sepehr;
        const isNgenius = selectedGateway?.key == Enumerations.paymentGateway.nGeniusPayment;
        const accessResult = hasAccess([isSepehr ? UserClaimEnumerations.getApPaymentAuditSepehrs : isNgenius ? UserClaimEnumerations.getApPaymentAuditNgenius : UserClaimEnumerations.getApPaymentAuditAsanPardakhts]);
        return [
            { label: !!fileSourceId ? "common.ok" : "common.label.startProcessing", onClicked: startUploadingClicked, icon: !!fileSourceId ? '' : 'ns-icon-save', className: 'primary', disabled: isEmpty(file?.name) || isEmpty(selectedGateway) },
            { label: "common.label.showResult", onClicked: importedFiledetailModalClicked, icon: 'ns-icon-view', className: 'primary-outline', disabled: isEmpty(file?.name) || isEmpty(selectedGateway) || !fileSourceId || !accessResult },
        ];
    }

    const toggleGuidanceModal = () => {
        const image = selectedGateway?.key === Enumerations.paymentGateway.asanPardakht
            ? guidanceAsanpardakht
            : selectedGateway?.key === Enumerations.paymentGateway.sepehr
                ? guidanceSepehr
                : selectedGateway?.key === Enumerations.paymentGateway.nGeniusPayment
                    ? guidanceNgenius
                    : null;
        setGuidanceImage(image);
        setShowGuidanceModal(!showGuidanceModal);
    }

    return (<NsModal maxWidth='md'
        showModal={true}
        closeModal={() => cancelUploadingClicked(true)}
        dialogTitle={getTranslatedRawText("adminPanel.download.uploadFile")}
        actions={<NsStickyButton stickyButtons={mainButtons()} isLoading={isLoading} />}>

        <Row className=''>
            <Col md={4} className='position-relative d-flex align-items-end mb-2'>
                <NsCircularProgress size={24}
                    visible={isLoading} />
                <NsAutocomplete label={getTranslatedRawText('adminPanel.dataGridColumn.paymentGateway')}
                    items={auditGateways}
                    onChange={selectedGatewayChanged}
                    value={selectedGateway}
                    className='w-75' />
                {!isEmpty(selectedGateway) && <NsButton className='secondary-text'
                    startIcon='ns-icon-question'
                    onClick={toggleGuidanceModal} />
                }
            </Col>
            <Col xs={12}>
                {!isEmpty(selectedGateway) &&
                    <div className={clsx('w-100', isLoading ? 'admin-panel__mute-cover' : '')}>
                        <NsDragFile getSelectedFile={selectedFileFromBrowser}
                            isShowSelectFileButton={true}
                            isExcelFile={true}
                            multiple={false} />
                    </div>
                }
            </Col>
            <Col xs={12}>
                {!isEmpty(file?.name) && !isEmpty(selectedGateway) &&
                    <div className='border rounded background-element-color mt-2 p-1'>
                        <NsUploadingFileProgress fileName={file?.name}
                            justShowCancelButton={true}
                            progress={progress}
                            cancelUploadingClicked={() => cancelUploadingClicked(false)}
                            isSingleFile={true}
                            stepMessage={stepMessage} />
                    </div>
                }
            </Col>
        </Row>

        {showDetailImportedFile &&
            <ApPaymentAuditImportedFileDetailModal fileSourceId={fileSourceId}
                paymentGatewayType={selectedGateway}
                closeModal={importedFiledetailModalClicked} />
        }
        {showGuidanceModal && !!guidanceImage &&
            <GuidanceModal guidanceImage={guidanceImage}
                selectedGateway={selectedGateway}
                closeModal={toggleGuidanceModal} />
        }
        {showQuestionModal && <NsMessageClassModal show={true}
            onClose={closeQuestionModal}
            messageItems={messageItems} />
        }
        {resultMessageClass}
    </NsModal >
    );
}

function GuidanceModal(props) {
    const { guidanceImage, selectedGateway } = props;

    return (
        <NsModal showModal={true}
            maxWidth='lg'
            noSpace
            dialogTitle={CommonHelper.stringFormat(getTranslatedRawText('adminPanel.audit.guidanceGatewayFile'), selectedGateway?.value)}
            closeModal={props.closeModal}>
            <div className='p-3'>
                <p>{getTranslatedRawText('adminPanel.audit.guidanceTitle')}</p>
                <img src={guidanceImage} className='max-width-350' />
            </div>

        </NsModal>
    );
}

export default React.memo(ApPaymentAuditImportFileModal);