import NsButton from "../../../../../components/buttons/NsButton";
import NsImage from "../../../../../components/images/NsImage";
import ConstantsWithTranslation from "../../../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import CommonHelper from "../../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";

export const getApOrderColumns = (selectOrderClicked, orderDetailClicked, isOrderSelection, isLoading, hasCheckbox) => [
    (isOrderSelection || hasAccess([UserClaimEnumerations.getApOrderDetail])) &&
    {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: hasCheckbox ? 120 : 80,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (order) => {
                return <NsButton className='secondary-text'
                    loading={isLoading}
                    startIcon={isOrderSelection ? 'ns-icon-confirm' : 'ns-icon-invoice-details'}
                    onClick={isOrderSelection ? () => selectOrderClicked(order) : () => orderDetailClicked(order.id)} />
            }
        }
    },
    {
        field: 'userFullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 140
    },
    {
        field: 'nasInvoiceId',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber'),
        width: 120,
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'lastStatusType',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.lastStatus'),
        width: 150,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className={`${CommonHelper.getShareSheetStateColor(props?.lastStatusType)}`}>
                    {ConstantsWithTranslation.getSharedSheetOrderStateType(props?.lastStatusType)}
                </div>
            },
        }
    },
    {
        field: 'orderApplicationType',
        headerName: getTranslatedRawText('components.orderType.orderApplicationType'),
        width: 150,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div>
                    {ConstantsWithTranslation.getOrderApplicationTypeById(props?.orderApplicationType)}
                </div>
            },
        }
    },
    {
        field: 'lastStatusTime',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.statusDate'),
        width: 150,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'productName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
        width: 135
    },
    {
        field: 'circulation',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.circulation'),
        width: 110,
        dataType: Enumerations.gridType.circulation
    },
    {
        field: 'twoSidePrintingType',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.printedSide'),
        width: 120,
        dataType: Enumerations.gridType.printSide
    },
    {
        field: 'turnaroundType',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.turnaround'),
        width: 110,
        dataType: Enumerations.gridType.turnaround
    },
    {
        field: 'totalQuantity',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.quantity'),
        width: 130,
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'submitTime',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
        width: 120,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'totalPrice',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceExcVat'),
        width: 140,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'priceInclusiveOfVat',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceIncVat'),
        width: 140,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'discountAmount',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.discount'),
        width: 120,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'paymentInAdvance',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentInAdvance'),
        width: 170,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'agencyName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.agencyName'),
        width: 140
    },
    {
        field: 'businessPartnerCustomerIdentifier',
        headerName: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
        width: 160
    },

];

export const getApOrderDetailColumns = (detailClicked, order) => [
    hasAccess([UserClaimEnumerations.getApOrderAdditionalInformation]) &&
    {
        field: 'detail',
        headerName: getTranslatedRawText('profile.dataGridColumns.detail'),
        width: 90,
        resizable: false,
        lockPosition: true,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasDetail: true,
            detailClicked: (id) => detailClicked(id),
        }
    },
    {
        field: 'frontImageUrl',
        headerName: getTranslatedRawText('profile.dataGridColumns.frontImage'),
        width: 110,
        resizable: false,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => <NsImage className="w-100"
                imgAlt="components.imagesAlt.orderFrontSide"
                imageUrl={props.frontImageUrl} />
        },
    },
    order?.orderItemInfoDto?.twoSidePrintingType === Enumerations.printedSide.twoSide
        ? {
            field: 'backImageUrl',
            headerName: getTranslatedRawText('profile.dataGridColumns.backImage'),
            width: 110,
            resizable: false,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => <NsImage className="w-100"
                    imgAlt="components.imagesAlt.orderBackSide"
                    imageUrl={props.backImageUrl} />
            },
        }
        : { hide: true },

    {
        field: 'size',
        headerName: getTranslatedRawText('profile.dataGridColumns.dimension'),
        width: 180,
    },
    {
        field: 'basePriceMultiplyInLatCount',
        headerName: getTranslatedRawText('profile.dataGridColumns.rate'),
        width: 90,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'extendedServicePrice',
        headerName: getTranslatedRawText('profile.dataGridColumns.servicePrice'),
        width: 150,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'series',
        headerName: getTranslatedRawText('profile.dataGridColumns.series'),
        width: 70,
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'totalPrice',
        headerName: getTranslatedRawText('profile.dataGridColumns.totalPrice'),
        dataType: Enumerations.gridType.price,
    },
];