import clsx from 'clsx';
import React from 'react';
import { Col } from 'react-bootstrap';

import '../../assets/sass/components/circularProgress/NsLoadingProgress.scss';
import Config from '../../configs/Config';
import { getConfigObjectByCulture } from '../../utilities/ConfigHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsCard from '../cards/NsCard';
import NsCircularProgress from './NsCircularProgress';

class NsLoadingProgress extends React.Component {
    render() {
        const { visible, className } = this.props;
        if (visible) {

            return (
                <div className='loading'>
                    <div className={clsx("loading-component", className)}>
                        <Col xs={8} sm={6} md={3} xl={2}>
                            <NsCard className='background-black-color border-0 p-3 text-center'>
                                <div className='mt-3 py-3'>
                                    <img src={Config.siteLogo}
                                        width="150"
                                        className=''
                                        alt={getTranslatedRawText("components.imagesAlt.NsLoadingGif")} />
                                </div>
                                <p className='my-4 white-color text-nowrap'>
                                    {getConfigObjectByCulture(Config.pageLoadingTitle).titleFirstSection}
                                </p>
                                <p className='my-4 white-color'>
                                    {getConfigObjectByCulture(Config.pageLoadingTitle).titleSecondSection}
                                </p>
                                <div className='mb-3'>
                                    <NsCircularProgress size={40}
                                        relative={true}
                                        color='red-color'
                                        visible={true} />
                                </div>

                            </NsCard>
                        </Col>
                    </div>
                </div >
            )
        } else {
            return null;
        }
    }
}

export default NsLoadingProgress;