import React, { useEffect, useState } from 'react';

import NsModal from '../../../../../components/modals/NsModal';
import {
    GetOrderNasHistoryService,
    GetOrderNasSheetDetail,
    GetOrdersService
} from '../../../../../services/adminPanel/AdminOrderService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseWrapper from '../../../bases/ApBaseWrapper';
import { getApOrderColumns } from './ApShareSheetOrderHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';

const ApShareSheetOrderSheetInfoModal = (props) => {

    const { showModal, selectedOrder, sheetId, nasInvoiceId } = props;
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [sheetDetail, setSheetDetail] = useState('');
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [orderDetail, setOrderDetail] = useState('');

    const hasAccessInfo = hasAccess([UserClaimEnumerations.getApOrderDto]);
    const hasAccessHistory = hasAccess([UserClaimEnumerations.getApOrderStatusHistory]);

    useEffect(() => {
        getSheetDto();
    }, []);

    const getSheetDto = () => {
        setIsLoading(true);
        GetOrderNasSheetDetail(sheetId, getOrderSheetDetailCallback);
    }

    const getOrderSheetDetailCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) setSheetDetail({ sheetInfoDto: resultData });
        else setResultMessageClass(messageClassModal);
    }

    const selectOrderClicked = (data) => CommonHelper.safeFunctionCall(selectedOrder, data);
    const orderDetailClicked = (data) => CommonHelper.safeFunctionCall(setOrderDetail, data);

    const columns = getApOrderColumns(selectOrderClicked, orderDetailClicked, false, isLoading, true);

    return (<NsModal maxWidth='lg'
        showModal={showModal}
        closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
        noSpace={true}
        dialogTitle={getTranslatedRawText("adminPanel.orders.ordersSheet")} >
        <ApBaseWrapper isLoadingInfo={isLoading}
            info={sheetDetail}
            isOrderDetail={true}
            hasAccessInfo={hasAccessInfo}
            hasAccessHistory={hasAccessHistory}
            historyService={GetOrderNasHistoryService}
            service={GetOrdersService}
            parameters={{
                "Filter.NasInvoiceIds": sheetDetail.nasInvoiceIds,
                "Filter.Id": nasInvoiceId,
            }}
            columnDefs={columns}
            refreshDate={refreshDate} />
        {resultMessageClass}
    </NsModal >
    );
}

export default React.memo(ApShareSheetOrderSheetInfoModal);