import React from 'react';
import { Form } from 'react-bootstrap';
import NsButton from '../../../components/buttons/NsButton';
import NsSetPageTitleAndDescription, { getTitleMetaTagByCulture } from '../../../components/metaTags/NsSetPageTitleAndDescription';
import NsTypography from '../../../components/text/NsTypography';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import AccountWrapper from '../AccountWrapper';


class SignupWelcome extends React.Component {

    signInClicked = () => {
        this.props.history.push(getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn));
    }

    keyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter) {
            event.preventDefault();
            this.signInClicked();
        }
    }

    render() {
        return (
            <AccountWrapper layoutType={Enumerations.loginLayoutType.signup}>

                <NsSetPageTitleAndDescription pageTitle='common.breadcrumbs.signupWelcome' />

                <Form onKeyDown={this.keyDown}>
                    <div className="white-panel g-0 d-flex flex-column my-sm-n5 w-100 p-4">
                        <p className='section-title'>
                            {CommonHelper.stringFormat(getTranslatedRawText('account.signup.signupWelcomeTitle'), getTitleMetaTagByCulture)}
                        </p>
                        <p>
                            {getTranslatedRawText('account.signup.dear')}
                            <span className='fw-bold'>
                                {CurrentUserHelper.getUserName()}
                            </span>
                        </p>
                        <NsTypography variant='inherit' className='primary-paragraph mb-5 pb-5'>
                            {getTranslatedRawText('account.signup.signupWelcomeDescription')}
                        </NsTypography>
                        <div className="d-flex justify-content-center mt-4 mb-3 d-sm-none">
                            <NsButton className="primary"
                                onClick={this.signInClicked}
                                startIcon='ns-icon-sign-in'
                                label='account.common.login' />
                        </div>
                    </div>
                </Form>
            </AccountWrapper>
        );
    }
}

export default SignupWelcome;