import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsDatePicker from '../../../../../components/dateTimePickers/NsDatePicker';
import NsAutocomplete from '../../../../../components/dropdowns/NsAutoComplete';
import NsTextField from '../../../../../components/inputs/NsTextField';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import NsSwitch from '../../../../../components/switch/NsSwitch';
import Enumerations from '../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { GetDownloadCategoryDropDownService } from '../../../../../services/adminPanel/AdminDownloadService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApLanguageTabs from '../../../bases/ApLanguageTabs';
import ApBasesContentVisibility from '../../bases/ApBasesContentVisibility';
import ApBasesImageContent from '../../bases/ApBasesImageContent';
import ContentHelper from '../../bases/ContentHelper';
import ApBasesStandardSizesForUpload from '../../bases/ApBasesStandardSizesForUpload';

const ApBaseDownloadNewFileContent = ({ showValidationDate, selectedRowId, serviceCall, modalDetail, isEditMode, nextButtonTriggerDate, isUsedInDownload, ...props }) => {

    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [messageClassModal, setMessageClassModal] = useState(<></>);
    const [multiLanguageFileContent, setMultiLanguageFileContent] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [categoryItems, setCategoryItems] = useState([]);

    useEffect(() => {
        setMultiLanguageFileContent(modalDetail);
    }, [modalDetail]);

    useEffect(() => {
        isUsedInDownload && GetDownloadCategoryDropDownService(getDownloadCategoryCallback);
    }, [])

    useEffect(() => {
        if (!isEmpty(nextButtonTriggerDate)) {
            CommonHelper.safeFunctionCall(props.saveButtonClicked, selectedRowId, multiLanguageFileContent);
        }
    }, [nextButtonTriggerDate])

    const getDownloadCategoryCallback = (resultData, result, messageClass) => {
        if (!result.data.hasError) {
            setCategoryItems(resultData);
        } else setMessageClassModal(messageClass);
    }

    const deleteFileClicked = () => setShowDeleteModal(true);

    const deleteFile = (confirm) => {
        if (confirm)
            CommonHelper.safeFunctionCall(props.deleteButtonClicked, selectedRowId);
        else setShowDeleteModal(false);
    }

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguageFileContent([...newData]);
    }

    const headerButtons = [
        isEditMode && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deleteFileClicked, accessIds: isUsedInDownload ? [UserClaimEnumerations.deleteApDownloads] : [UserClaimEnumerations.deleteApDownloadsCategory] }
    ];

    return (
        <>
            <ApBaseMapActionButtons actionButtons={headerButtons} isLoading={props.isLoading} />
            <div className="p-3">
                <ApLanguageTabs languageChanged={languageChangedHandler}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguageFileContent) && <TabDataTables key={selectedLanguage.id}
                    stateHandler={stateHandler}
                    showValidationDate={showValidationDate}
                    data={[...multiLanguageFileContent]}
                    categoryItems={categoryItems}
                    isNew={isEditMode}
                    serviceCall={serviceCall}
                    selectedLanguage={selectedLanguage}
                    id={selectedRowId}
                    isUsedInDownload={!!isUsedInDownload} />
                }
            </div>
            {showDeleteModal &&
                <NsMessageClassModal show={showDeleteModal}
                    onClose={(confirm) => deleteFile(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), '')}</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }

            {messageClassModal}
        </>
    )
}

function TabDataTables(props) {
    const { stateHandler, data, serviceCall, categoryItems, showValidationDate, selectedLanguage, isUsedInDownload } = props;
    let fileContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);

    const contentVisibilityTypeIdChanged = (selectedItemId) => {
        stateHandler(data, { contentVisibilityTypeId: selectedItemId }, selectedLanguage.id)
    }

    const selectedAgencyIdsChanged = (agencyIds) => {
        stateHandler(data, { agencyIds }, selectedLanguage.id)
    }

    return (
        <Row>
            <Col md={5}>
                <ApBasesImageContent content={fileContent?.content}
                    isChangeFile={!!fileContent?.content?.fileUrl}
                    serviceCall={serviceCall}
                    fileUploaderCategoryType={Enumerations.fileUploaderCategoryType.adminDownload}
                    stateHandler={(uploadedFileDetail) => stateHandler(data, { ...uploadedFileDetail, }, selectedLanguage.id)} />
                <ApBasesStandardSizesForUpload />
            </Col>
            <Col md={7}>
                <div className='d-flex flex-column'>
                    <div className="d-flex align-items-end mb-2" >
                        <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                            required
                            showValidationDate={showValidationDate}
                            valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                            value={fileContent?.content.title || null}
                            className='w-100' />
                        <NsSwitch label='adminPanel.slider.active'
                            checked={fileContent?.content.isActive || false}
                            disabled={!selectedLanguage.isDefault}
                            onChange={() => stateHandler(data, { isActive: !fileContent?.content.isActive }, selectedLanguage.id)} />
                    </div>
                    {isUsedInDownload && <>
                        <NsAutocomplete label={getTranslatedRawText('adminPanel.dataGridColumn.category')}
                            value={fileContent?.content.pageCategories || []}
                            multiple={true}
                            disabled={!selectedLanguage.isDefault}
                            items={categoryItems}
                            showValidationDate={showValidationDate}
                            onChange={(value) => { stateHandler(data, { pageCategories: value }, selectedLanguage.id) }}
                            className="w-100" />
                        <NsTextField label={getTranslatedRawText('adminPanel.post.summary')}
                            valueChanged={(value) => stateHandler(data, { summary: value }, selectedLanguage.id)}
                            value={fileContent?.content.summary}
                            className='w-100 mb-2' />
                        <NsTextField label={getTranslatedRawText('adminPanel.post.tags')}
                            valueChanged={(value) => stateHandler(data, { tags: value }, selectedLanguage.id)}
                            value={fileContent?.content.tags || null}
                            showValidationDate={showValidationDate}
                            className='w-100 mt-1' />
                        <div className="font-size-12 pt-2">
                            {getTranslatedRawText('adminPanel.post.tagsInfo')}
                        </div>
                        <div className='d-flex my-2'>
                            <NsDatePicker label={getTranslatedRawText('adminPanel.download.releaseDate')}
                                selectedDate={new Date(fileContent?.content.releaseDate)}
                                valueChanged={(value) => stateHandler(data, { releaseDate: value }, selectedLanguage.id)}
                                disabled={!selectedLanguage.isDefault}
                                className='w-auto me-2' />
                            <NsTextField label={getTranslatedRawText('adminPanel.download.version')}
                                valueChanged={(value) => stateHandler(data, { version: value }, selectedLanguage.id)}
                                value={fileContent?.content.version}
                                disabled={!selectedLanguage.isDefault}
                                className='w-auto ms-2' />
                        </div>
                    </>}
                    <ApBasesContentVisibility contentVisibilityTypeId={fileContent?.content.contentVisibilityTypeId}
                        selectedAgencies={fileContent?.content.selectedAgencies}
                        contentVisibilityTypeIdChanged={contentVisibilityTypeIdChanged}
                        selectedAgencyIdsChanged={selectedAgencyIdsChanged} />
                </div>
            </Col>
        </Row>
    )
}

export default React.memo(ApBaseDownloadNewFileContent);