import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { i18nReducer } from "react-redux-i18n";
import {
    setLocale,
    loadTranslations,
    syncTranslationWithStore,
} from "react-redux-i18n";
import Translations from "../../resources/Translations";

import Stores from './Stores'
import { getCurrentCulture } from '../../utilities/CultureHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';

export default function configureStore(history, initialState) {
    const reducers = {
        ...Stores,
        i18n: i18nReducer
    };

    const checkTokenExpirationMiddleware = store => next => action => {
        const token = CurrentUserHelper.getToken()
        // if (jwtDecode(token).exp < Date.now() / 1000) {
        //   next(action);
        // //   localStorage.clear();
        // }
        next(action);
    };

    const middleware = [
        thunk,
        routerMiddleware(history),
        // checkTokenExpirationMiddleware,
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const appReducer  = (history) => combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const rootReducer = (state, action) => {
        // if (action.type === 'REMOVE_CURRENT_USER') {
        //     state.miniCartInfo.miniCartInfo = null;
        // }
        return appReducer(state, action);
    }

    const store = createStore(
        rootReducer(history),
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    let currentCulture = getCurrentCulture();
    syncTranslationWithStore(store);
    store.dispatch(loadTranslations(Translations));
    store.dispatch(setLocale(currentCulture));

    return store;
}