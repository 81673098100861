import NsButton from "../../../../../../components/buttons/NsButton";
import ConstantsWithTranslation from "../../../../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../../../../configs/Enumerations";
import CommonHelper from "../../../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../../../utilities/CultureHelper";
import DateHelper from "../../../../../../utilities/DateHelper";

export const getApDailyQueueOfEstimateTimeColumns = (selectOrderClicked, orderDetailClicked, isOrderSelection, isLoading, hasCheckbox) => [
    {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: hasCheckbox ? 120 : 80,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (order) => {
                return <NsButton className='secondary-text'
                    loading={isLoading}
                    startIcon={isOrderSelection ? 'ns-icon-invoice-details' : 'ns-icon-confirm'}
                    onClick={isOrderSelection ? () => selectOrderClicked(order) : () => orderDetailClicked(order.id)} />
            }
        }
    },
    {
        field: 'estimateReadyToDeliverTimeLabel',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime'),
        width: 250,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return (props?.remainingToEstimateReadyToDeliverTimeDay < 2 && -2 < props?.remainingToEstimateReadyToDeliverTimeDay)
                    ? <div className={`${CommonHelper.getOrderQueueRemainingDaysByEstimateTime(props?.remainingToEstimateReadyToDeliverTimeDay)}`}>
                        {props?.estimateReadyToDeliverTimeLabel}
                    </div>
                    : DateHelper.getDatePart(props?.estimateReadyToDeliverTime)
            },
        }
    },
    {
        field: 'layoutingOrderCount',
        headerName: getTranslatedRawText('adminPanel.orderStateType.layouting'),
        width: 120,
    },
    {
        field: 'lithographyOrderCount',
        headerName: getTranslatedRawText('adminPanel.orderStateType.lithography'),
        width: 120,
    },
    {
        field: 'printingOrderCount',
        headerName: getTranslatedRawText('adminPanel.orderStateType.printing'),
        width: 120,
    },
    {
        field: 'cuttingAndPackagingOrderCount',
        headerName: getTranslatedRawText('adminPanel.orderStateType.cutting'),
        width: 140,
    },
    {
        field: 'transferToWarehouseOrderCount',
        headerName: getTranslatedRawText('adminPanel.orderStateType.transferToWarehouse'),
        width: 140,
    },
    {
        field: 'readyToDeliverOrderCount',
        headerName: getTranslatedRawText('adminPanel.orderStateType.ready'),
        width: 120,
    }
];