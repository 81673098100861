import { PaymentAuditMatchingType, PaymentStateType, TransactionStateType } from "../../../../utilities/DataGridHelper";
import EnumerationTranslationHelper from "../../../../utilities/EnumerationTranslationHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsButton from "../../../../components/buttons/NsButton";
import CommonHelper from "../../../../utilities/CommonHelper";

export const getApPaymentColumns = (paymentDetailClicked, useInPaymentsHasProblem) => [
    hasAccess([UserClaimEnumerations.getApPaymentById]) &&
    {
        field: 'action', headerName: getTranslatedRawText('adminPanel.common.action'),
        width: '80',
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasDetail: true,
            detailClicked: (id) => paymentDetailClicked(id),
        }
    },
    {
        field: 'userFullName',
        width: '150',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
    },
    !useInPaymentsHasProblem &&
    {
        field: 'paymentType',
        width: '100',
        headerName: getTranslatedRawText('profile.dataGridColumns.status'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => <PaymentStateType status={props.lastStatusType} />
        }
    },
    !!useInPaymentsHasProblem &&
    {
        field: 'paymentProblemType',
        width: '200',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentProblemType'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return EnumerationTranslationHelper.setTranslateForPaymentProblemType(props.paymentProblemType);
            }
        }
    },
    {
        field: 'lastStatusTime',
        width: '150',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.statusDate'),
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'trackNumber',
        width: '140',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.trackNumber'),
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'refrenceNumber',
        width: '150',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.referenceNumber'),
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'amount',
        width: '150',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.amount'),
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'submitTime',
        width: '150',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'orderProductTypeId',
        width: '120',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.productType'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return EnumerationTranslationHelper.getProductType(props?.orderProductTypeId);
            }
        }
    },
    {
        field: 'paymentGateway',
        width: '150',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentGateway'),
    },
    !useInPaymentsHasProblem && {
        field: 'paymentTypeDetail',
        width: '130',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentType'),
        cellRenderer: 'customRowRenderer',
        cellRendererParams: {
            customElementRender: (props) => {
                return EnumerationTranslationHelper.getPaymentTypeText(props.paymentType);
            }
        }
    },
    {
        field: 'voucherNumber',
        width: '150',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.voucherNumber'),
        dataType: Enumerations.gridType.number,
    },
];

export const getApPaymentDetailModalColumns = (detailClicked, paymentType) => paymentType === Enumerations.paymentType.increaseCredit
    ? [
        {
            field: 'detailLedger',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.detailLedger'),
        },
        {
            field: 'description',
            width: '200',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.description'),
        },
        {
            field: 'debtor',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.debtor'),
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'creditor',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creditor'),
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'createAt',
            width: '130',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'voucherNumber',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.voucherNumber'),
        },
    ]
    : [
        hasAccess([UserClaimEnumerations.getApOrderDetail]) &&
        {
            field: 'action', headerName: getTranslatedRawText('adminPanel.common.action'),
            width: '80',
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => detailClicked(id),
            }
        },
        {
            field: 'userFullName',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        },
        {
            field: 'nasInvoiceId',
            width: '130',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber'),
        },
        {
            field: 'lastStatusType',
            width: '130',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.lastStatus'),
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return ConstantsWithTranslation.getSharedSheetOrderStateType(props?.lastStatusType)
                }
            }
        },
        {
            field: 'lastStatusTime',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.statusDate'),
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'productName',
            width: '170',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
        },
        {
            field: 'circulation',
            width: '130',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.circulation'),
            dataType: Enumerations.gridType.circulation
        },
        {
            field: 'printedSideType',
            width: '130',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.printedSide'),
            dataType: Enumerations.gridType.printSide
        },
        {
            field: 'turnaroundType',
            width: '130',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.turnaround'),
            dataType: Enumerations.gridType.turnaround
        },
        {
            field: 'totalQuantity',
            width: '130',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.quantity'),
        },

        {
            field: 'submitTime',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'totalPrice',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceExcVat'),
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'priceInclusiveOfVat',
            width: '140',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceIncVat'),
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'discountAmount',
            width: '140',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.discount'),
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'paymentInAdvance',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentInAdvance'),
            dataType: Enumerations.gridType.price,
        },

    ];

export const getApAuditColumns = (auditDetailClicked, isAdvanceMode) => [
    hasAccess(isAdvanceMode
        ? [UserClaimEnumerations.getApPaymentAuditById]
        : [UserClaimEnumerations.getApPaymentAuditAsanPardakhtByActionId, UserClaimEnumerations.getApPaymentAuditSepehtByActionId]) &&
    {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        cellRenderer: "customRowRenderer",
        width: 80,
        cellRendererParams: {
            customElementRender: (props) => <NsButton className='secondary-text px-0'
                startIcon='ns-icon-invoice-details cursor-pointer'
                onClick={() => auditDetailClicked(props)} />
        }
    },
    {
        field: 'rrn',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.referenceNumber'),
        dataType: Enumerations.gridType.number,
        width: 180,
    },
    {
        field: 'datePaid',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.datePaid'),
        dataType: Enumerations.gridType.dateTime,
        width: 135,
    },
    {
        field: 'paymentAuditMatchingTypeId',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentAuditMatchingTypeId'),
        cellRenderer: "customRowRenderer",
        width: 145,
        cellRendererParams: {
            customElementRender: (props) => <PaymentAuditMatchingType matchingType={props.paymentAuditMatchingTypeId} />
        }
    },
    {
        field: 'amount',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fileAmount'),
        dataType: Enumerations.gridType.price,
        width: 130,
    },
    {
        field: 'websiteAmount',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.websiteAmount'),
        dataType: Enumerations.gridType.price,
        cellRenderer: "customRowRenderer",
        width: 120,
        cellRendererParams: {
            customElementRender: (props) =>
                props.paymentAuditMatchingTypeId === Enumerations.paymentAuditMatchingType.paymentNotFound
                    ? null
                    : <div className={props.amount !== props.websiteAmount ? "red-color" : ''} >
                        {props.websiteAmount}
                    </div >
        }
    },
    {
        field: 'fileTransactionStatus',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fileTransactionStatus'),
        cellRenderer: "customRowRenderer",
        width: 150,
        cellRendererParams: {
            customElementRender: (props) => EnumerationTranslationHelper.getPaymentStatusTypeText(props.fileTransactionStatus)
        }
    },
    {
        field: 'websiteTransactionStatus',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.websiteTransactionStatus'),
        cellRenderer: "customRowRenderer",
        width: 190,
        cellRendererParams: {
            customElementRender: (props) => <TransactionStateType {...props} />
        }
    },
    {
        field: 'trackNumber',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.trackNumber'),
        dataType: Enumerations.gridType.number,
        width: 120,
    },
    {
        field: 'cardNumber',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.cardNumber'),
        dataType: Enumerations.gridType.number,
        width: 150,
    },
    {
        field: 'paymentGatewayType',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentGateway'),
        cellRenderer: "customRowRenderer",
        width: 120,
        cellRendererParams: {
            customElementRender: (props) => {
                return EnumerationTranslationHelper.getPaymentGatewayType(props.paymentGatewayType);
            }
        }
    },
    {
        field: 'fullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 140,
    },
    {
        field: 'transactionStatusMessage',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.transactionStatusMessage'),
        width: 250,
    },
];

export const getApAuditDetailColumns = (importedFileDetailModalClicked, paymentGatewayType, showRrn) => {
    const detailActionAccess = !!showRrn
        ? hasAccess([UserClaimEnumerations.getApPaymentAuditHistories])
        : paymentGatewayType == Enumerations.paymentGateway.sepehr
            ? hasAccess([UserClaimEnumerations.getApPaymentAuditSepehtByActionId])
            : paymentGatewayType == Enumerations.paymentGateway.asanPardakht
                ? hasAccess([UserClaimEnumerations.getApPaymentAuditAsanPardakhtByActionId])
                : hasAccess([UserClaimEnumerations.getApPaymentAuditNgeniusByActionId]);

    const getValue = (paymentGatewayType, sepehrValue, asanPardakhtValue, ngeniusPaymentValue) => {
        let value = '';
        switch (paymentGatewayType) {
            case Enumerations.paymentGateway.sepehr:
                value = sepehrValue;
                break;
            case Enumerations.paymentGateway.asanPardakht:
                value = asanPardakhtValue;
                break;
            case Enumerations.paymentGateway.nGeniusPayment:
                value = ngeniusPaymentValue;
                break;
        };
        return value;
    }

    return [
        {
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.filePayment'),
            headerClass: 'background-element-color border-outline-color-1px',
            children: CommonHelper.clearArrayFromEmptyItem([
                detailActionAccess && {
                    field: 'action', headerName: getTranslatedRawText('adminPanel.common.action'),
                    cellRenderer: "customRowRenderer",
                    width: 80,
                    cellRendererParams: {
                        customElementRender: (props) => <NsButton className='secondary-text px-0'
                            startIcon='ns-icon-invoice-details cursor-pointer'
                            onClick={() => importedFileDetailModalClicked(props)} />
                    }
                },
                paymentGatewayType == Enumerations.paymentGateway.asanPardakht && {
                    field: 'paymentAuditAsanPardakht.settleDate',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.settleDate'),
                    dataType: Enumerations.gridType.dateTime,
                    width: 150,
                },
                {
                    field: 'paymentAuditMatchingType',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentAuditMatchingType'),
                    cellRenderer: "customRowRenderer",
                    width: 145,
                    cellRendererParams: {
                        customElementRender: (props) => {
                            return EnumerationTranslationHelper.getPaymentAuditMatchingType(props.paymentAuditMatchingType)[0];
                        }
                    }
                },
                !!showRrn && {
                    field: 'rrn',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.referenceNumber'),
                    width: 200,
                },
                {
                    field: getValue(paymentGatewayType, 'paymentAuditSepehr.datePaid', 'paymentAuditAsanPardakht.datePaid', 'paymentAuditNgenius.datePaid'),
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.datePaid'),
                    dataType: Enumerations.gridType.dateTime,
                    width: 150,
                },
                {
                    field: 'amount',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.amount'),
                    dataType: Enumerations.gridType.price,
                    width: 140,
                },
                {
                    field: getValue(paymentGatewayType, 'paymentAuditSepehr.paymentStateTypeId', 'paymentAuditAsanPardakht.paymentStateTypeId', 'paymentAuditNgenius.paymentStateTypeId'),
                    width: '115',
                    headerName: getTranslatedRawText('profile.dataGridColumns.status'),
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => {
                            return EnumerationTranslationHelper.getPaymentStatusTypeText(
                                getValue(paymentGatewayType, props?.paymentAuditSepehr?.paymentStateTypeId, props?.paymentAuditAsanPardakht?.paymentStateTypeId, props?.paymentAuditNgenius?.paymentStateTypeId));
                        }
                    }
                },
                {
                    field: getValue(paymentGatewayType, 'paymentAuditSepehr.transactionStatus', 'paymentAuditAsanPardakht.transactionStatus', 'paymentAuditNgenius.paymentStatus'),
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.transactionStatus'),
                    width: 150,
                },
                {
                    field: getValue(paymentGatewayType, 'paymentAuditSepehr.cardNumber', 'paymentAuditAsanPardakht.cardNumber', 'paymentAuditNgenius.cardNumber'),
                    headerClass: 'border-left',
                    cellClass: 'border-outline-color-1px',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.cardNumber'),
                    dataType: Enumerations.gridType.number,
                    width: 155,
                },
                {
                    field: getValue(paymentGatewayType, 'paymentAuditSepehr.transactionStatusMessage', 'paymentAuditAsanPardakht.transactionStatusMessage', 'paymentAuditNgenius.transactionStatusMessage'),
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.transactionStatusMessage'),
                    width: 250,
                },
                {
                    field: 'gatewayMessage',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.gatewayMessage'),
                    width: 250,
                },
            ])
        },
        {
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.websitePayment'),
            headerClass: 'background-element-color border-outline-color-1px',
            children: [
                {
                    field: 'paymentInformation.lastStatusType',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.lastStatus'),
                    cellRenderer: "customRowRenderer",
                    width: 90,
                    cellRendererParams: {
                        customElementRender: (props) => <PaymentStateType status={props.paymentInformation?.lastStatusType} />
                    }
                },
                {
                    field: 'paymentInformation.lastStatusTime',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.lastStatusTime'),
                    dataType: Enumerations.gridType.dateTime,
                    width: 210,
                },
                {
                    field: 'paymentInformation.trackNumber',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.trackNumber'),
                    dataType: Enumerations.gridType.number,
                    width: 120,
                },
                {
                    field: 'paymentInformation.amount',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.amount'),
                    dataType: Enumerations.gridType.price,
                    width: 110,
                },
                {
                    field: 'paymentInformation.submitTime',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
                    dataType: Enumerations.gridType.dateTime,
                    width: 140,
                },
                {
                    field: 'paymentInformation.paymentType',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentType'),
                    cellRenderer: 'customRowRenderer',
                    width: 115,
                    cellRendererParams: {
                        customElementRender: (props) => {
                            return EnumerationTranslationHelper.getPaymentTypeText(props.paymentInformation?.paymentType);
                        }
                    }
                },
                {
                    field: 'paymentInformation.voucherNumber',
                    headerName: getTranslatedRawText('adminPanel.dataGridColumn.voucherNumber'),
                    dataType: Enumerations.gridType.number,
                    width: 90,
                },
            ]
        }
    ];
}

export const getApAuditFileDetailColumns = (getPaymentDetailClicked, paymentGatewayTypeId) => {
    const isSepehr = paymentGatewayTypeId == Enumerations.paymentGateway.sepehr;
    const isAsanPardakht = paymentGatewayTypeId == Enumerations.paymentGateway.asanPardakht;
    const isNGenius = paymentGatewayTypeId == Enumerations.paymentGateway.nGeniusPayment;
    return isNGenius
        ? [
            hasAccess([UserClaimEnumerations.getApPaymentById]) &&
            {
                field: 'action', headerName: getTranslatedRawText('adminPanel.common.action'),
                cellRenderer: "customRowRenderer",
                width: 80,
                cellRendererParams: {
                    customElementRender: (props) => < NsButton className='secondary-text px-0'
                        startIcon='ns-icon-invoice-details cursor-pointer'
                        disabled={!props.paymentId}
                        onClick={() => getPaymentDetailClicked(props.paymentId)} />
                }
            },
            {
                field: 'paymentAuditMatchingTypeId',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentAuditMatchingTypeId'),
                cellRenderer: "customRowRenderer",
                width: 150,
                cellRendererParams: {
                    customElementRender: (props) => <PaymentAuditMatchingType matchingType={props.paymentAuditMatchingTypeId} />
                }
            },
            {
                field: 'paymentStateTypeId',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentStateType'),
                cellRenderer: "customRowRenderer",
                width: 160,
                cellRendererParams: {
                    customElementRender: (props) => <PaymentStateType status={props.paymentStateTypeId} />
                }
            },
            {
                field: 'trackNumber',
                width: '140',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.trackNumber'),
                dataType: Enumerations.gridType.number,
            },
            {
                field: 'datePaid',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.transactionDate'),
                dataType: Enumerations.gridType.dateTime,
                width: 135,
            },
            {
                field: 'orderStatus',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.orderStatus'),
                width: 135,
            },
            {
                field: 'paymentStatus',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentStatus'),
                width: 155,
            },
            {
                field: 'amount',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.amount'),
                dataType: Enumerations.gridType.number,
                width: 140,
            },
            {
                field: 'requestPaymentCurrency',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.currencyType'),
                dataType: Enumerations.gridType.number,
                width: 180,
            },
            {
                field: 'rrn',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.referenceNumber'),
                dataType: Enumerations.gridType.number,
                width: 180,
            },
            {
                field: 'capturedAmount',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.capturedAmount'),
                dataType: Enumerations.gridType.number,
                width: 180,
            },
            {
                field: 'capturedDate',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.capturedDate'),
                dataType: Enumerations.gridType.dateTime,
                width: 180,
            },
            {
                field: 'refundAmount',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.refundAmount'),
                dataType: Enumerations.gridType.number,
                width: 180,
            },
            {
                field: 'refundDate',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.refundDate'),
                dataType: Enumerations.gridType.dateTime,
                width: 180,
            },
            {
                field: 'systemGeneratedOrder',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.systemGeneratedOrder'),
                width: 200,
            },
            {
                field: 'declineReason',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.declineReason'),
                width: 155,
            },
            {
                field: 'gatewayRisk',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.gatewayRisk'),
                width: 155,
            },
            {
                field: 'gatewayRiskViolations',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.gatewayRiskViolations'),
                width: 180,
            },
            {
                field: 'authenticationResponse',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.authenticationResponse'),
                width: 180,
            },
            {
                field: 'threeDsfailureReason',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.threeDsfailureReason'),
                width: 180,
            },
            {
                field: 'outlet',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.outlet'),
                width: 155,
            },
            {
                field: 'cardHolderEmail',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.cardHolderEmail'),
                width: 220,
            },
            {
                field: 'outletReferenceId',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.outletReferenceId'),
                width: 155,
            },
            {
                field: 'merchantId',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.merchantId'),
                dataType: Enumerations.gridType.number,
                width: 150,
            },
            {
                field: 'cardholderName',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.cardholderName'),
                width: 170,
            },
            {
                field: 'cardNumber',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.cardNumber'),
                dataType: Enumerations.gridType.number,
                width: 170,
            },
            {
                field: 'paymentMethod',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentMethod'),
                width: 155,
            },
            {
                field: 'originIp',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.originIp'),
                width: 300,
            },
        ]
        : [
            hasAccess([UserClaimEnumerations.getApPaymentById]) &&
            {
                field: 'action', headerName: getTranslatedRawText('adminPanel.common.action'),
                cellRenderer: "customRowRenderer",
                width: 80,
                cellRendererParams: {
                    customElementRender: (props) => < NsButton className='secondary-text px-0'
                        startIcon='ns-icon-invoice-details cursor-pointer'
                        disabled={!props.paymentId}
                        onClick={() => getPaymentDetailClicked(props.paymentId)} />
                }
            },
            {
                field: 'paymentAuditMatchingTypeId',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentAuditMatchingTypeId'),
                cellRenderer: "customRowRenderer",
                width: 150,
                cellRendererParams: {
                    customElementRender: (props) => <PaymentAuditMatchingType matchingType={props.paymentAuditMatchingTypeId} />
                }
            },
            {
                field: 'paymentStateTypeId',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentStateType'),
                cellRenderer: "customRowRenderer",
                width: 135,
                cellRendererParams: {
                    customElementRender: (props) => <PaymentStateType status={props.paymentStateTypeId} />
                }
            },
            {
                field: 'cardNumber',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.cardNumber'),
                dataType: Enumerations.gridType.number,
                width: 155,
            },
            {
                field: 'amount',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.amount'),
                dataType: Enumerations.gridType.price,
                width: 140,
            },
            {
                field: 'rrn',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.referenceNumber'),
                dataType: Enumerations.gridType.number,
                width: 180,
            },
            isSepehr && {
                field: 'terminalId',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.terminalId'),
                dataType: Enumerations.gridType.number,
                width: 100,
            },
            isSepehr && {
                field: 'traceNumber',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.payGateTranId'),
                dataType: Enumerations.gridType.number,
                width: 95,
            },
            {
                field: 'datePaid',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.transactionDate'),
                dataType: Enumerations.gridType.dateTime,
                width: 135,
            },
            isAsanPardakht && {
                field: 'factorNumber',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumberId'),
                dataType: Enumerations.gridType.number,
                width: 110,
            },
            isAsanPardakht && {
                field: 'merchantId',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.merchantId'),
                dataType: Enumerations.gridType.number,
                width: 100,
            },
            isAsanPardakht &&
            {
                field: 'payGateTranId',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.payGateTranId'),
                dataType: Enumerations.gridType.number,
                width: 115,
            },
            {
                field: 'transactionStatus',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.transactionStatus'),
                width: 155,
            },
            isAsanPardakht &&
            {
                field: 'settleDate',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.settleStatus'),
                width: 120,
                dataType: Enumerations.gridType.date
            },
            isAsanPardakht && {
                field: 'refoundDate',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.refoundStatus'),
                dataType: Enumerations.gridType.dateTime,
                width: 170,
            },
            {
                field: 'transactionStatusMessage',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.transactionStatusMessage'),
                width: 250,
            },
        ];
}