import React from 'react';
import { withRouter } from 'react-router';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../utilities/CultureHelper';
import UserPanelWrapper from '../../../UserPanelWrapper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Links from '../../../../../configs/links/AllLinks';
import UpCartOrderContent from './UpCartOrderContent';

class UpCartOrder extends React.Component {

    state = {
        refreshUserAccountDate: null
    }

    // componentDidMount = () => this.props.history.replace(
    //     getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder), {});

    refreshUserAccount = () => this.setState({ refreshUserAccountDate: new Date() });

    changeRouteToCartOrderDetail = (id) => this.props.history.push(CommonHelper.stringFormat(
        getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrderDetail), id));

    changeRouteToCartOrder = () => this.props.history.push(CommonHelper.stringFormat(
        getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder)
    ));

    cartOrderContentWithoutWrapper = () => {
        return (<UpCartOrderContent refreshUserAccount={this.refreshUserAccount}
            changeRouteToCartOrderDetail={this.changeRouteToCartOrderDetail}
            changeRouteToCartOrder={this.changeRouteToCartOrder} />)
    }

    render() {
        const { notShowProfileSettingWrapper } = this.props;
        return (
            notShowProfileSettingWrapper ?
                this.cartOrderContentWithoutWrapper()
                : <UserPanelWrapper titleName={getTranslatedRawText('profile.orderCart.cart')}
                    titleIcon='ns-icon-cart'
                    refreshUserAccountDate={this.state.refreshUserAccountDate} >
                    {this.cartOrderContentWithoutWrapper()}
                </UserPanelWrapper >
        );
    }
}

export default withRouter(UpCartOrder);