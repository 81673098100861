import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import NsTypography from '../../../../components/text/NsTypography';
import CommonHelper from '../../../../utilities/CommonHelper';
import NsCard from '../../../../components/cards/NsCard';
import Links from '../../../../configs/links/AllLinks';

class BnBasesCategoryCard extends React.Component {

	isBlog = CommonHelper.isBlog(window.location.href);

	render() {
		const { category, className } = this.props;

		return (
			<div className={clsx("d-inline d-md-inline-block w-100", className)} style={{ '--category-url': `url("${CommonHelper.createImageUrl(category?.fileUrl)}")` }} >
				<NsCard >
					<Link to={this.isBlog
						? CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogCategoryPosts), category?.id, category?.title)
						: CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsCategoryPosts), category?.id, category?.title)}>
						<div className='blogs__categories__img blogs__categories__img__gradient'>
							<NsTypography variant='inherit' className="position-absolute bottom-0 font-size-18 fw-bold ms-3 ms-lg-4">
								{category?.title}
								<p className="font-size-14 fw-normal">
									{category?.mainContent}
								</p>
							</NsTypography>
						</div>
					</Link >
				</NsCard >
			</div >
		);
	}
}

export default BnBasesCategoryCard;