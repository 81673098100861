import React, { useEffect, useState } from "react";

import { GetShippingByIdService, GetShippingGroupSheetOrdersService, GetShippingStoreOrdersService } from "../../../../../services/adminPanel/AdminShippingService";
import UpShippingMethodInformationModal from "../../../../profileSettings/contents/userShippings/modals/UpShippingMethodInformationModal";
import UpShippingRequestHeaderCost from "../../../../profileSettings/contents/userShippings/shippingRequests/UpShippingRequestHeaderCost";
import { createStoreOrderColumns } from "../../../../profileSettings/contents/userOrder/stores/bases/UpBaseStoreOrderHelper";
import { changeAddressAndTelProperty } from '../../../../profileSettings/contents/userShippings/UpShippings';
import NsDataGridWithServiceCall from "../../../../../components/grids/NsDataGridWithServiceCall";
import ApShareSheetOrderDetailModal from "../../orders/shareSheets/ApShareSheetOrderDetailModal";
import ConstantsWithTranslation from "../../../../../configs/ConstantsWithTranslation";
import ApStoreOrderDetailModal from "../../orders/stores/ApStoreOrderDetailModal";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import { createShippingDetailcolumns } from "../bases/ApBaseShippingHelper";
import ApBaseMapActionButtons from "../../../bases/ApBaseMapActionButtons";
import ApBaseUserInfoModal from "../../users/bases/ApBaseUserInfoModal";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import CommonHelper from "../../../../../utilities/CommonHelper";
import NsModal from "../../../../../components/modals/NsModal";
import Enumerations from "../../../../../configs/Enumerations";
import ApBaseLabelInfo from "../../../bases/ApBaseLabelInfo";
import NsTab from "../../../../../components/tabs/NsTab";
import { Col, Row } from "react-bootstrap";

const ApShippingDetailModal = (props) => {

    const { selectedShipmentId } = props;
    const hasAccessInfo = hasAccess([UserClaimEnumerations.getApShippingById]);
    const hasAccessGrid = hasAccess([UserClaimEnumerations.getApShippingGroupSheetOrders]);
    const hasAccessStoreGrid = hasAccess([UserClaimEnumerations.getApShippingStoreOrders]);
    const [tabs, setTabs] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [shipment, setShipment] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [showShippingMethodModal, setShowShippingMethodModal] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [isLoadingGrid, setIsLoadingGrid] = useState(true);
    const [resultMessageClass, setResultMessageClass] = useState((<></>));

    useEffect(() => {
        getShipment();
    }, [])

    const getShipment = () => {
        if (!hasAccessInfo) return;
        setIsLoadingInfo(true);
        GetShippingByIdService(selectedShipmentId, getShippingByIdCallback);
    }

    const getShippingByIdCallback = (resultData, result, messageClassModal) => {
        if (result.data.hasError)
            setResultMessageClass(messageClassModal);
        else {
            setShipment({ shippingInfoDto: resultData });
            updateOrdersCount(resultData?.sharedSheetOrdersCount, resultData?.storeProductOrdersCount);
        }
        setIsLoadingInfo(false);
    }

    const updateOrdersCount = (badgeCount0, badgeCount1) => {
        let tabs = ConstantsWithTranslation.getOrderProductTabs(!!badgeCount0 && hasAccessGrid, !!badgeCount1 && hasAccessStoreGrid);
        if (tabs.length > 1) {
            tabs[0].badgeCount = badgeCount0;
            tabs[1].badgeCount = badgeCount1;
        }
        setTabs(tabs);
        setSelectedTab(tabs[0]);
        setSelectedTabIndex(0);
        setCallServiceDate(new Date());
    }

    const handlers = {

        detailViewClicked: (id) => {
            setSelectedOrder(orders.filter(order => order.id === id)[0]);
            setShowOrderDetailModal(true);
        },

        closeApShareSheetOrderDetailModal: () => {
            setShowOrderDetailModal(false);
        },

        userInfoClicked: () => {
            setShowUserInfoModal(true);
        },

        closeUserInfoModal: () => {
            setShowUserInfoModal(false);
        },

        tabChanged: (newValue, selectedTab) => {
            if (newValue !== selectedTabIndex) {
                setSelectedTabIndex(newValue);
                setSelectedTab(selectedTab);
                setCallServiceDate(new Date());
            }
        },

        toggleshippingMethodModal: () => setShowShippingMethodModal(!showShippingMethodModal)
    }

    const getData = (orders, isLoading) => {
        setOrders(orders);
        setIsLoadingGrid(isLoading);
    }

    const { detailViewClicked, tabChanged, closeApShareSheetOrderDetailModal, userInfoClicked, closeUserInfoModal, toggleshippingMethodModal } = handlers;

    const headerButtons = [
        hasAccess([UserClaimEnumerations.getApCustomerById]) && { wrapperClassName: '', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: userInfoClicked },
        hasAccess([UserClaimEnumerations.getApCustomerById]) && { wrapperClassName: '', startIcon: '', label: 'adminPanel.shippings.shippingMethod', onClick: toggleshippingMethodModal },
    ];

    const isStore = selectedTab?.id == Enumerations.orderProductType.store;
    const selectedShippingMethod = {
        shippingMethodCategoryTypeId: shipment.shippingInfoDto?.shippingMethodCategoryTypeId,
        shippingMethodTypeId: shipment.shippingInfoDto?.shippingMethodTypeId,
        name: shipment.shippingInfoDto?.shippingMethodName,
    }

    return <NsModal maxWidth='lg'
        showModal={true}
        closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
        noSpace={true}
        dialogTitle={getTranslatedRawText("adminPanel.shippings.shipmentDetails")} >

        <ApBaseMapActionButtons actionButtons={headerButtons}
            isLoading={isLoadingGrid || isLoadingInfo} />
        {!isEmpty(shipment) &&
            <div className='background-color p-2 px-3'>
                <ApBaseLabelInfo info={{ shippingInfoDto: changeAddressAndTelProperty({ ...shipment.shippingInfoDto }) }}
                    isLoading={isLoadingInfo}
                    hasAccessInfo={hasAccessInfo}
                />
            </div>
        }

        <div className="p-3">

            <NsTab value={selectedTabIndex}
                tabs={tabs}
                onChange={tabChanged} />

            <Row className='w-100 g-2'>
                <Col md={8}>
                    <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                        columnDefs={isStore ? createStoreOrderColumns(detailViewClicked) : createShippingDetailcolumns(detailViewClicked)}
                        hasPagination={true}
                        service={isEmpty(tabs) ? '' : isStore ? hasAccessStoreGrid ? GetShippingStoreOrdersService : '' : hasAccessGrid ? GetShippingGroupSheetOrdersService : ''}
                        parameters={{
                            'Filter.Id': selectedShipmentId,
                            userId: shipment.shippingInfoDto?.userId
                        }}
                        getData={getData} />
                </Col>
                <Col md={4} className="background-element-color rounded-3 p-2">
                    <UpShippingRequestHeaderCost selectedShippingMethod={selectedShippingMethod}
                        shippingTime={shipment.shippingInfoDto?.estimatedDeliveryTime}
                        shippingCost={shipment.shippingInfoDto?.shippingCost}
                        isAdminPanel={true}
                        selectedShippingPlan={{ key: shipment.shippingInfoDto?.shippingPlanTypeId }} />
                </Col>
            </Row>
        </div>

        {showOrderDetailModal
            ? isStore
                ? <ApStoreOrderDetailModal showModal={true}
                    selectedOrder={{ ...selectedOrder, userId: shipment.shippingInfoDto?.userId }}
                    closeModal={closeApShareSheetOrderDetailModal} />

                : <ApShareSheetOrderDetailModal showModal={true}
                    selectedOrder={{ ...selectedOrder, userId: shipment.shippingInfoDto?.userId }}
                    closeModal={closeApShareSheetOrderDetailModal} />
            : null
        }

        {showUserInfoModal &&
            <ApBaseUserInfoModal showModal={true}
                selectedUserId={shipment?.shippingInfoDto?.userId}
                closeModal={closeUserInfoModal} />
        }

        {showShippingMethodModal &&
            <UpShippingMethodInformationModal selectedShippingMethod={selectedShippingMethod}
                selectedShippingPlan={{ key: shipment.shippingInfoDto?.shippingPlanTypeId }}
                shippingRequestTime={shipment.shippingInfoDto?.requestDate}
                closeModal={toggleshippingMethodModal} />}

        {resultMessageClass}
    </NsModal >
}

export default React.memo(ApShippingDetailModal);