import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { getTranslatedRawText, getUrlWithCurrentCulture, removeQueryFromURL } from "../../utilities/CultureHelper";
import NsSetPageTitleAndDescription from "../../components/metaTags/NsSetPageTitleAndDescription";
import NsLoadingProgress from "../../components/circularProgress/NsLoadingProgress";
import { clearFilters, getParametersFromUrl, productsFilterLabel } from "./bases/ProductHelper";
import ConstantsWithTranslation from "../../configs/ConstantsWithTranslation";
import { GetProductsService } from "../../services/product/ProductService";
import { viewItemListEvent } from "../../utilities/GoogleTagManagerHelper";
import { getConfigObjectByCulture } from "../../utilities/ConfigHelper";
import CurrentUserHelper from "../../utilities/CurrentUserHelper";
import { isEmpty } from "../../utilities/ValidationHelper";
import '../../assets/sass/views/products/Products.scss';
import CommonHelper from "../../utilities/CommonHelper";
import Enumerations from "../../configs/Enumerations";
import NsTour from "../../components/tour/NsTour";
import UrlHelper from "../../utilities/UrlHelper";
import Links from "../../configs/links/AllLinks";
import ContentMain from "./content/ContentMain";
import SidebarMain from "./sidebar/SidebarMain";
import Config from "../../configs/Config";

const preventRouteToSet = true;

class Products extends Component {
    constructor(props) {
        super(props);
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.product);
        this.tourSteps = [
            {
                selector: `.product-tour-sidebar${CommonHelper.isMobileDevice() ? '-mobile' : ''}`,
                content: getTranslatedRawText('product.tour.sidebarFilter'),
            },
            {
                selector: '.product-tour-card',
                content: getTranslatedRawText('product.tour.productResult'),
            },
        ];
        this.maximumFilterPrice = Config.products.maximumFilterPrice;
        this.sortItemsConstant = ConstantsWithTranslation.getSortItems();
        this.defaultSorting = Config.homeSection.showBestSellerProducts
            ? this.sortItemsConstant.find(item => item.id === Enumerations.sortItems.bestSelling)
            : this.sortItemsConstant.find(item => item.id === Enumerations.sortItems.newest);
        this.state = {
            filters: {
                pageNumber: 1,
                categories: props.categories,
                searchInput: '',
                price: {
                    minPrice: 0,
                    maxPrice: this.maximumFilterPrice,
                    filterLabel: ""
                },
                option: {
                    printedSide: null,
                    turnaround: null
                },
                sortBy: this.defaultSorting,
            },
            totalPageCount: 0,
            products: [],
            isLoading: false,
            resultMessageClass: '',
            firstRendering: true
        };
    }

    componentDidMount = () => {
        const { filters } = this.state;

        if (!isEmpty(filters.categories))
            getParametersFromUrl(this.props, this.state.filters, preventRouteToSet, this.updatefilters);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.userFullName && this.props.userFullName)
            this.getProducts();

        if (prevProps.selectedCurrency !== this.props.selectedCurrency)
            this.getProducts(preventRouteToSet);

        if (isEmpty(prevProps.categories) && !isEmpty(this.props.categories))
            this.setState({ filters: { ...this.state.filters, categories: this.props.categories } },
                () => getParametersFromUrl(this.props, this.state.filters, preventRouteToSet, this.updatefilters)
            );

        if ((prevProps.location.search !== this.props.location.search)) {
            const selectedCategories = CommonHelper.recursiveSeparator(this.state.filters.categories).parentLessItems.filter(child => child.checked);
            if (isEmpty(UrlHelper.getRouteParameters(this.props.location.search)) && selectedCategories.length === 0)
                this.removeAllFilters();

            if (this.props.location.state) getParametersFromUrl(this.props, this.state.filters, preventRouteToSet, this.updatefilters);
            window.onpopstate = () => {
                this.setState({ filters: clearFilters(this.props.categories, this.state.filters.sortBy) },
                    () => getParametersFromUrl(this.props, this.state.filters, preventRouteToSet, this.updatefilters)
                );
            }

            if (prevProps.location.pathname !== this.props.location.pathname)
                getParametersFromUrl(this.props, this.state.filters, preventRouteToSet, this.updatefilters);
        }
    }

    updatefilters = (filters) => this.setState({ filters }, () => this.getProducts());

    getProductsCallback = (resultData, result, messageClassModal) => {
        const { filters } = this.state;
        if (!result?.data?.hasError) {
            const totalPageCount = CommonHelper.getTotalPageCount(resultData.totalCount, Config.products.cardPerPage);
            CommonHelper.redirectToCorrectPathIfInvalidPageNumber(filters.pageNumber, totalPageCount, window.location.pathname, this.props);
            this.setState({
                products: resultData.dataItems,
                totalPageCount: totalPageCount,
                isLoading: false,
            }, () => viewItemListEvent(this.state.products, Enumerations.googleTagManagerListId.shop));
        }
        else {
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
        }
    }

    getProducts = (preventRouteToSet) => {
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.product);
        const { pageNumber, categories, searchInput, price, option, sortBy } = this.state.filters;
        const selectedCategories = CommonHelper.recursiveSeparator(this.state.filters.categories).parentLessItems.filter(child => child?.checked)?.map(item => item.id)
        const searchText = isEmpty(searchInput) ? null : searchInput;
        const minPrice = price?.minPrice;
        const maxPrice = price?.maxPrice;
        const printedSide = option.printedSide?.id;
        const turnaround = option.turnaround?.id;
        const selectedSort = sortBy?.id;
        let parameters = {
            pageLength: Config.products.cardPerPage,
            currentPageIndex: pageNumber,
            "filter.search": searchText,
            "filter.twoSidePrintingType": printedSide,
            "filter.turnaroundType": turnaround,
            "filter.MinimumPrice": minPrice === 0 ? null : minPrice,
            "filter.MaximumPrice": maxPrice === this.maximumFilterPrice ? null : maxPrice,
            "Filter.productSortType": selectedSort
        };

        selectedCategories.map((category, index) => parameters["filter.categoryIds[" + index + "]"] = category);
        this.setState({ isLoading: true },
            () => GetProductsService(parameters, this.getProductsCallback));
        const searchParameters = {
            [CommonHelper.kebabize(CommonHelper.getVariableName({ pageNumber }))]: (pageNumber !== 1) ? pageNumber : null,
            [CommonHelper.kebabize(CommonHelper.getVariableName({ categories }))]: selectedCategories.length > 1 ? selectedCategories : null,
            [CommonHelper.kebabize(CommonHelper.getVariableName({ searchInput }))]: searchText,
            [CommonHelper.kebabize(CommonHelper.getVariableName({ printedSide }))]: printedSide,
            [CommonHelper.kebabize(CommonHelper.getVariableName({ turnaround }))]: turnaround,
            [CommonHelper.kebabize(CommonHelper.getVariableName({ minPrice }))]: minPrice === 0 ? null : minPrice,
            [CommonHelper.kebabize(CommonHelper.getVariableName({ maxPrice }))]: maxPrice === this.maximumFilterPrice ? null : maxPrice,
            [CommonHelper.kebabize(CommonHelper.getVariableName({ sortBy }))]: (selectedSort !== this.defaultSorting?.id) ? selectedSort : null,
        };
        !preventRouteToSet && UrlHelper.setRouteParameters(this.props, searchParameters, removeQueryFromURL(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)));

        if (selectedCategories.length === 1) {
            let selectedCategory = this.state.filters.categories.filter(item => item.id === selectedCategories[0])[0]
            const searchParams = new URLSearchParams(CommonHelper.cleanObject(searchParameters)).toString();
            return this.props.history.push({
                pathname: UrlHelper.createCorrectRouteAndUrl(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main, Links.view.main.productsByCategory), selectedCategory.id, selectedCategory.name)),
                search: searchParams
            });
        }
    }

    handleCurrentPage = (pageNumber) => {
        this.setState({ filters: { ...this.state.filters, pageNumber: pageNumber } }, pageNumber !== this.state.filters.pageNumber ? this.getProducts : null);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    categoryDeleteFilter = (id) => {
        const filters = { ...this.state.filters };
        const _categories = CommonHelper.recursiveSelector(filters.categories, { targetIds: [id], isChecked: true })
        filters.categories = _categories;
        this.setFilter(filters);
    }

    searchDeleteFilter = () => {
        const filters = { ...this.state.filters };
        filters.searchInput = '';
        this.setFilter(filters);
    }

    priceDeleteFilter = () => {
        const filters = { ...this.state.filters };
        filters.price.minPrice = 0;
        filters.price.maxPrice = this.maximumFilterPrice;
        filters.price.filterLabel = '';
        this.setFilter(filters);
    }

    optionDeleteFilter = (name) => {
        const filters = { ...this.state.filters };
        filters.option[name] = null;
        this.setFilter(filters);
    }

    setFilter = (filters) => this.setState({ filters: { ...filters, pageNumber: 1 } }, this.getProducts);

    setSepecificFilter = (filter, name) => {
        const filterName = CommonHelper.getVariableName(name);
        this.setState({ filters: { ...this.state.filters, [filterName]: filter, pageNumber: 1 } }, this.getProducts);
    }

    // handleMobileFilter = (filters) => this.setState({ filters: { ...filters, pageNumber: 1 } }, this.getProducts);

    removeAllFilters = () => {
        this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts));
        this.setState({ filters: clearFilters(this.props.categories, this.state.filters.sortBy) }, () => this.getProducts(preventRouteToSet));
    }

    render() {
        const { pageNumber, categories, searchInput, price, option, sortBy } = this.state.filters;
        const { isLoading, totalPageCount, products, filters } = this.state;
        const productsFilterLabels = productsFilterLabel(this.state.filters, this.categoryDeleteFilter, this.searchDeleteFilter, this.priceDeleteFilter, this.optionDeleteFilter);
        return (
            <Row className='products '>
                <NsLoadingProgress visible={isLoading} />
                <Col xs={3} className='products__sidebar d-none d-lg-block '>
                    <SidebarMain filters={filters}
                        productsFilterLabel={productsFilterLabels}
                        categoriesFilter={filter => this.setSepecificFilter(filter, { categories })}
                        searchFilter={filter => this.setSepecificFilter(filter, { searchInput })}
                        priceFilter={filter => this.setSepecificFilter(filter, { price })}
                        optionFilter={filter => this.setSepecificFilter(filter, { option })}

                        categoryDeleteFilter={this.categoryDeleteFilter}
                        searchDeleteFilter={this.searchDeleteFilter}
                        priceDeleteFilter={this.priceDeleteFilter}
                        optionDeleteFilter={this.optionDeleteFilter}
                        removeAllFilters={this.removeAllFilters} />
                </Col>
                <Col xs={12} lg={9} className='products__content '>
                    <ContentMain products={products}
                        isLoading={isLoading}
                        filters={filters}
                        categories={categories}
                        productsFilterLabel={productsFilterLabels}
                        sortFilter={filter => this.setSepecificFilter(filter, { sortBy })}
                        handleCurrentPage={this.handleCurrentPage}
                        pageNumber={pageNumber}
                        totalPageCount={totalPageCount}
                        isAuthenticated={!!this.props.userFullName}
                        submitMobileFilters={this.setFilter}
                        removeAllFilters={this.removeAllFilters}
                        selectedCategories={CommonHelper.recursiveSeparator(this.state.filters.categories).parentLessItems.filter(child => child?.checked)?.map(item => item.id)} />
                </Col>
                {(this.showTour && !isLoading) && <NsTour steps={this.tourSteps}
                    id={Enumerations.tourGuide.product} />}
                {this.state.resultMessageClass}
                <NsSetPageTitleAndDescription pageTitle='common.breadcrumbs.products'
                    description={getConfigObjectByCulture(Config.metaTags)?.descriptions.products}
                    pageNumber={pageNumber}
                    sortBy={filters?.sortBy?.label}
                    minPrice={price?.minPrice}
                    maxPrice={price?.maxPrice}
                    maximumFilterPrice={this.maximumFilterPrice}
                    selectedCategoryNames={CommonHelper.recursiveSeparator(categories).parentLessItems.filter(child => child?.checked)?.map(item => item.name)}
                    printedSide={option?.printedSide}
                    turnaround={option?.turnaround}
                    searchText={isEmpty(searchInput) ? null : searchInput} />
            </Row>
        );
    }
}

const mapStateToProps = state => {
    return {
        userFullName: state.currentUser.userFullName,
        categories: state.categories.categories,
        selectedCurrency: state.currencies.selectedCurrency
    };
};

export default connect(mapStateToProps)(Products);