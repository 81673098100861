import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';

import NsCard from '../../../components/cards/NsCard';
import Links from '../../../configs/links/AllLinks';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';

class UpSidebarUserReports extends React.Component {

    navLinks = [
        { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderPaymentReport), icon: "ns-icon-reports", label: "profile.actionButton.report", isHeadline: true },
        { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderPaymentReport), icon: "ns-icon-payment", label: "profile.actionButton.payment" },
        { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderFinancialReport), icon: "ns-icon-account-balance", label: "profile.actionButton.debitCredit"},
    ]

    render() {
        return (
            <NsCard className='px-3 py-2'>
                {this.navLinks.map((link, index) => {
                    return link.to && <NavLink key={index}
                        to={link.to}
                        onClick={this.props.onClick}
                        className={isActive => clsx('nav-link-profile-setting', !!link.isHeadline ? 'py-1' : 'ps-3 pb-1', isActive && !link.isHeadline && 'background-element-color')}>
                        <i className={clsx(link.icon, "align-middle me-2 h2")} />
                        <span className="primary-paragraph">
                            {getTranslatedRawText(link.label)}
                        </span>
                    </NavLink>
                })}
            </NsCard>
        );
    }
}

export default UpSidebarUserReports;