import React from 'react';

import NsGroupNumberInput from '../../../components/inputs/NsGroupNumberInput';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import NsTooltip from '../../../components/tooltips/NsTooltip';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import OrderHelper from '../../../utilities/OrderHelper';
import NsSkeleton from '../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../configs/Enumerations';

class OSummaryConfirm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refreshSummaryDate: props.refreshSummaryDate,
            choosingFileSummary: OrderHelper.getFileSizeAndOrderPrices(props.routeId),
            maxSeries: OrderHelper.getAvailableSizes(this.props.routeId)?.find(item => item.isSelected)?.maxSeries,
            isTooltipOpen: false,
            // series: props.isUsedInProfessional ? 1 : OrderHelper.getSeries(props.routeId),
            series: OrderHelper.getSeries(props.routeId),
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshSummaryDate != prevState.refreshSummaryDate) {
            let choosingFileSummary = OrderHelper.getFileSizeAndOrderPrices(nextProps.routeId);
            return ({
                choosingFileSummary,
                refreshSummaryDate: nextProps.refreshSummaryDate,
                maxSeries: OrderHelper.getAvailableSizes(nextProps.routeId)?.find(item => item.isSelected)?.maxSeries,
                series: isEmpty(choosingFileSummary) && nextProps.isUsedInProfessional ? 1 : prevState.series
            });
        }
        return null;
    }

    addToCurrentListClicked = () => CommonHelper.safeFunctionCall(this.props.addToCurrentListClicked);

    seriesChanged = (value) => {
        value <= this.state.maxSeries && this.setState({ series: value }, () => {
            OrderHelper.setSeries(this.props.routeId, value);
            CommonHelper.safeFunctionCall(this.props.doRefreshSummaryDate, new Date());

        });
    }

    toggleTooltipClicked = () => this.setState({ isTooltipOpen: !this.state.isTooltipOpen });

    render() {
        const { isTooltipOpen, series, choosingFileSummary, maxSeries } = this.state;
        const { isViewMore, totalExtendedServicePrice, pricesLoading, isUsedInProfessional, routeId } = this.props;
        const totalAmount = (choosingFileSummary?.basePrice * choosingFileSummary?.latCount + totalExtendedServicePrice) * series;

        return (
            <div className='d-flex flex-column font-size-12 '>
                <div className='border-bottom pb-1'>
                    <div className='font-size-14 fw-bold mb-1'>{getTranslatedRawText('regularOrder.confirmDetail.totalPrice')}</div>
                    <SeriesComponent series={series}
                        maxSeries={maxSeries}
                        seriesChanged={this.seriesChanged}
                        isTooltipOpen={isTooltipOpen}
                        isUsedInProfessional={isUsedInProfessional}
                        toggleTooltipClicked={this.toggleTooltipClicked}
                        closeTooltip={() => this.setState({ isTooltipOpen: false })} />
                    <div className='mb-1 '>
                        {getTranslatedRawText('regularOrder.confirmDetail.totalProductPrice')}:&nbsp;
                        <span className='fw-bold'>
                            {isViewMore
                                ? CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.priceDetailWithUpsAndSeries'), CommonHelper.currencyFormatByDefaultConfig(choosingFileSummary.basePrice), choosingFileSummary.latCount, series,
                                    CommonHelper.currencyFormatByDefaultConfig(choosingFileSummary?.basePrice * choosingFileSummary?.latCount * series))
                                : CommonHelper.currencyFormatByDefaultConfig(choosingFileSummary?.basePrice * choosingFileSummary?.latCount * series)
                            }
                        </span>
                    </div>
                    {!!totalExtendedServicePrice &&
                        <div className='mb-1 '>
                            {pricesLoading
                                ? <NsSkeleton isVisible={true}
                                    variant={[
                                        Enumerations.skeleton.text,
                                    ]} />
                                : <>
                                    {getTranslatedRawText('regularOrder.confirmDetail.totalExtendedServicePrice')}:&nbsp;
                                    <span className='fw-bold'>
                                        {isViewMore
                                            ? CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.priceDetailWithoutUps'), CommonHelper.currencyFormatByDefaultConfig(totalExtendedServicePrice), series,
                                                CommonHelper.currencyFormatByDefaultConfig(totalExtendedServicePrice * series))
                                            : CommonHelper.currencyFormatByDefaultConfig(totalExtendedServicePrice * series)
                                        }
                                    </span>
                                </>
                            }
                        </div>
                    }
                </div>
                {
                    pricesLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.text,
                            ]} />
                        : <div className='mt-2 mb-3 font-size-14'>
                            {getTranslatedRawText('regularOrder.confirmDetail.totalAmount')}:&nbsp;
                            <span className='fw-bold red-color font-size-18 ps-3'>
                                {CommonHelper.currencyFormatByDefaultConfig(totalAmount)}
                            </span>
                        </div>
                }

                {!!isUsedInProfessional &&
                    <NsButton className='secondary'
                        wrapperClassName='d-none d-md-flex professionalOrder-tour-addToCurrentList'
                        size='small'
                        onClick={this.addToCurrentListClicked} >
                        <div className='d-flex justify-content-between px-2 align-items-center w-100'>
                            <div>{getTranslatedRawText('regularOrder.confirmDetail.addToCurrentList')}</div>
                            <i className='ns-icon-down font-size-16' />
                        </div>
                    </NsButton>
                }
            </div>
        );
    }
}

function SeriesComponent(props) {
    const { isUsedInProfessional, routeId, series, maxSeries, seriesChanged, isTooltipOpen, closeTooltip, toggleTooltipClicked } = props;
    const title = !maxSeries
        ? getTranslatedRawText('common.messages.selectFrontSide')
        : CommonHelper.stringFormat(getTranslatedRawText('professionalOrder.orderDetails.toolTip'), maxSeries || 0);
    return !!isUsedInProfessional
        ? <NsGroupNumberInput label='regularOrder.confirmDetail.series'
            className=' mb-2'
            value={series}
            maxLength={2}
            addButtonDisabled={series >= maxSeries}
            step={1}
            onChanged={seriesChanged}>
            <NsTooltip className={'ms-2'} open={isTooltipOpen}
                title={title}
                closeTooltip={closeTooltip}>
                <NsButton className='secondary-text p-0'
                    startIcon='ns-icon-question'
                    size='small'
                    onClick={toggleTooltipClicked} />
            </NsTooltip>
        </NsGroupNumberInput>
        : <>
            <div>
                {getTranslatedRawText('regularOrder.confirmDetail.series')}:&nbsp;
                {series}
            </div>
        </>
}

export default OSummaryConfirm;