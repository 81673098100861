import React from 'react';
import { Route } from 'react-router-dom';

import { getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import BusinessPartnerLayout from './BusinessPartnerLayout';
import CommonHelper from '../../../utilities/CommonHelper';
import UrlHelper from '../../../utilities/UrlHelper';
import Links from '../../../configs/links/AllLinks';

class BusinessPartnerLayoutRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice: CommonHelper.isMobileDevice(),
        }
    }

    UNSAFE_componentWillMount = () => UrlHelper.addWwwToRoute();

    componentDidMount() {
        // The page is loaded inside an iframe
        if (window.self !== window.top) {
            this.setWindowSize = () => this.setState({ isMobileDevice: CommonHelper.isMobileDevice() });
            window.addEventListener('resize', this.setWindowSize);
            this.rightClickDisabled = event => event.preventDefault();
            window.addEventListener('contextmenu', this.rightClickDisabled);
        } else {
            // The page is not loaded inside an iframe
            if (window.location.pathname !== getUrlWithCurrentCulture(Links, Links.exception))
                window.location.href = getUrlWithCurrentCulture(Links, Links.exception);
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setWindowSize);
        window.removeEventListener('contextmenu', this.rightClickDisabled);

    }

    render() {
        let { component, ...rest } = this.props;
        const Component = component;
        return <Route {...rest} render={matchProps => (
            <div key={this.state.isMobileDevice} className='background-color d-flex flex-column min-vh-100'>
                <BusinessPartnerLayout {...rest}>
                    <Component {...matchProps}
                        showResetTour={rest?.showResetTour}
                        breadCrumb={rest?.breadCrumb} />
                </BusinessPartnerLayout>
            </div>
        )} />
    }
};

export default BusinessPartnerLayoutRoute;