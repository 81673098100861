import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import '../../../../assets/sass/views/singlePages/AboutUs.scss';
import NsButton from '../../../../components/buttons/NsButton';
import NsCard from '../../../../components/cards/NsCard';
import NsHtmlToReact from '../../../../components/htmlToReact/NsHtmlToReact';
import NsSetPageTitleAndDescription, { getTitleMetaTagByCulture } from '../../../../components/metaTags/NsSetPageTitleAndDescription';
import NsTypography from '../../../../components/text/NsTypography';
import Config from '../../../../configs/Config';
import Enumerations from '../../../../configs/Enumerations';
import Links from '../../../../configs/links/AllLinks';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import ImageHelper from '../../../../utilities/ImageHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import SinglePage from '../../SinglePage';

class AboutUs extends Component {

    showAllEquipmentClicked = () => this.props.history.push(getUrlWithCurrentCulture(Links, Links.ourEquipment));

    setLink = (link) => {
        return `<a className='d-block text-decoration-none mx-3 my-1'
            target='_blank'
            href=${link}>
            ${link}
        </a>`
    }

    convertUrlToHtmlTag = (text) => {
        let Text = text;
        const regex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
        const matches = Text?.match(regex);

        for (let i = 0; i < matches?.length; i++) {
            Text = Text?.replace(matches[i], this.setLink(matches[i]));
        }
        return <NsHtmlToReact>{Text}</NsHtmlToReact>
    }

    render() {

        const aboutUs = getConfigObjectByCulture(Config.aboutUs);

        return (
            <SinglePage >
                <NsCard className='mb-4'>
                    <Row className='primary-text'>
                        <Col xs={12} className='about-us' style={{ '--image-url': `url(${getConfigObjectByCulture(Config.images)?.aboutUs[0].src})` }}>
                            <div className='about-us__img'>
                                <NsTypography variant='inherit' className=" about-us__heading--primary ms-3 ms-lg-4">
                                    <h1 className='fw-bold display-5'>{getTranslatedRawText('singlePage.about.aboutUs')}</h1>
                                </NsTypography>
                            </div>
                        </Col>
                    </Row>
                    {!isEmpty(aboutUs) &&
                        aboutUs.map((item, index) => index === aboutUs.length - 1
                            ? <OurEquipmentSection item={item} key={index}
                                showAllEquipmentClicked={this.showAllEquipmentClicked} />
                            : <div key={index} className='about-us__description m-3 mb-5'>
                                <div className='d-flex align-items-center mb-3'>
                                    <NsTypography variant='inherit'
                                        className="about-us__section--title">
                                        <h2 className='h4 fw-bold mb-0'>{item.title}</h2>
                                    </NsTypography>
                                    <div className="about-us__section--line m-0 mt-2 ms-3"></div>
                                </div>
                                <NsTypography variant='inherit' className="d-block about-us__section--description">
                                    {item.description.map((item, index) =>
                                        <p key={index}>
                                            {this.convertUrlToHtmlTag(item)}
                                        </p>
                                    )}
                                </NsTypography>
                            </div>)}
                </NsCard>
                <NsSetPageTitleAndDescription pageTitle='singlePage.about.aboutUs'
                    description={getConfigObjectByCulture(Config.metaTags)?.descriptions.aboutUs} />
            </SinglePage >
        );
    }
}

function OurEquipmentSection(props) {
    return <div className="about-us__equipment mx-3">
        <div className='d-flex align-items-center mb-3'>
            <NsTypography variant='inherit'
                className="about-us__section--title">
                {props.item.title}
            </NsTypography>
            <div className="about-us__section--line m-0 mt-2 ms-3"></div>
        </div>
        <Row className='mb-4'>
            <Col xs={12} md={7} className='d-flex flex-column justify-content-between'>
                <NsTypography variant='inherit' className="d-block about-us__section--description">
                    {props.item.description[0]}
                </NsTypography>
                <div className='text-center my-4 my-md-2'>
                    <NsButton label='singlePage.about.showAllEquipment'
                        size='small'
                        className='secondary'
                        onClick={props.showAllEquipmentClicked}
                        wrapperClassName='d-inline-block' />
                </div>
            </Col>
            <Col xs={12} md={5} className="">
                <img className='mw-100'
                    src={getConfigObjectByCulture(Config.images)?.aboutUs[1].src}
                    srcSet={ImageHelper.getCdnImageSrcset(Enumerations.imageSitePosition.aboutUsOurEquipment, Config.images.en.aboutUs[1].src)}
                    alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.ourEquipment"), getTitleMetaTagByCulture)} />
            </Col>
        </Row>
    </div>
}

export default AboutUs;