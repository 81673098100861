import React, { Component } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CommonHelper from '../../utilities/CommonHelper';
import '../../assets/sass/components/buttons/NsRadioButton.scss';

class NsRadioGroupButtons extends Component {

    radioButtonClicked = (event) => {
        CommonHelper.safeFunctionCall(this.props.radioButtonClicked, event.target.value);
    };
    
    render() {
        const { selectedItem, formTitle, radioList } = this.props;
        return (
            <FormControl component="fieldset" >
                <FormLabel component="legend">{formTitle}</FormLabel>
                <RadioGroup value={selectedItem} onChange={this.radioButtonClicked}>
                    <RadioList radioList={radioList} />
                </RadioGroup>
            </FormControl>
        );
    }
}

function RadioList(props) {
    const { radioList } = props;
    const formList = radioList.map(item => <FormControlLabel key={item.id} value={item.id} control={<Radio className="Radio" />} label={item.label} />);
    return formList;
}

export default NsRadioGroupButtons;