import React from 'react';
import { Form } from 'react-bootstrap';
import NsButton from '../../../components/buttons/NsButton';
import NsTypography from '../../../components/text/NsTypography';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import AccountWrapper from '../AccountWrapper';
import NsSetPageTitleAndDescription from '../../../components/metaTags/NsSetPageTitleAndDescription';


class SuccessChangeForgotPassword extends React.Component {

    signInClicked = () => this.props.history.push(getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn));

    keyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter) {
            event.preventDefault();
            this.signInClicked();
        }
    }

    render() {
        return (
            <AccountWrapper layoutType={Enumerations.loginLayoutType.signIn}>

                <NsSetPageTitleAndDescription pageTitle='common.breadcrumbs.successChangePassword' />

                <Form onKeyDown={this.keyDown}>
                    <div className="white-panel g-0 d-flex flex-column my-sm-n5 w-100 p-4">
                        <h1 className='section-title'>
                            {getTranslatedRawText('account.forgotPassword.forgotPasswordSuccessTitle')}
                        </h1>
                        <NsTypography variant='inherit' className='primary-paragraph mb-5 pb-5'>
                            {getTranslatedRawText('account.forgotPassword.forgotPasswordSuccessDescription')}
                        </NsTypography>
                        <div className="d-flex justify-content-center mt-4 mb-3">
                            <NsButton className="primary"
                                onClick={this.signInClicked}
                                startIcon='ns-icon-sign-in'
                                label='account.common.login' />
                        </div>
                    </div>
                </Form>
            </AccountWrapper>
        );
    }
}

export default SuccessChangeForgotPassword;