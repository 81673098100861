import React from 'react';
import { connect } from 'react-redux';
import { GetDiskUsageService } from '../../../services/fileManager/ContentManagementService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';



class FmHeaderUsageSpace extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            maximumVolume: 0,
            usedVolume: 0,
            headerDiskUsageRefreshDate: null,
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.headerDiskUsageRefreshDate !== this.state.headerDiskUsageRefreshDate)
            this.getUsageDiskInfo();
    }

    getDiskUsageCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            let usedVolume = CommonHelper.convertByteToMegaByte(resultData.usedVolume);
            let maximumVolume = CommonHelper.convertByteToMegaByte(resultData.maximumVolume);
            this.setState({ usedVolume, maximumVolume });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getUsageDiskInfo = () => GetDiskUsageService(this.getDiskUsageCallback);

    componentDidMount = () => this.getUsageDiskInfo();

    render() {
        const title = CommonHelper.stringFormat(getTranslatedRawText('fileManager.headerUsageSpace.usedCapacity'),
            this.state.usedVolume, this.state.maximumVolume);

        return (
            <div className='ms-sm-auto align-self-center px-1 file-manager__header__usage-space' >
                <div className="d-flex align-self-center primary-paragraph">
                    <div>
                        <span className='ns-icon-pie-chart new-folder align-self-center h2' />
                    </div>
                    <div className='black-color align-self-center px-2 fw-bold'>
                        {title}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headerDiskUsageRefreshDate: state.fileManager.headerDiskUsageRefreshDate,
    }
}

export default connect(mapStateToProps)(FmHeaderUsageSpace);