import clsx from "clsx";
import React from "react";
import { Link } from 'react-router-dom';

import NsCard from "../../../components/cards/NsCard";
import NsHtmlToReact from "../../../components/htmlToReact/NsHtmlToReact";
import NsTab from "../../../components/tabs/NsTab";
import NsTypography from "../../../components/text/NsTypography";
import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../configs/Enumerations";
import CommonHelper from "../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import UrlHelper from "../../../utilities/UrlHelper";
import { isEmpty } from "../../../utilities/ValidationHelper";
import PdBaseExplanation from "./PdBaseExplanation";
import PdBaseSimilarProducts from "./PdBaseSimilarProducts";

class PdBaseContents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTabIndex: null,
            selectedTab: null,
            getSimilarProductsDate: null
        }
    }

    tabChanged = (selectedTabIndex, selectedTab) => {
        this.setState({ selectedTabIndex, selectedTab });
        selectedTabIndex == Enumerations.productDetailContentTabs.recommendedProducts && this.setState({ getSimilarProductsDate: new Date() });
    }

    setSelectedTabHandler = (otherTab) => {
        this.setState({
            selectedTab: otherTab,
            selectedTabIndex: this.getTabs().findIndex(tab => tab.id === otherTab.id)
        })
    }

    getTabs = () => CommonHelper.clearArrayFromEmptyItem(ConstantsWithTranslation.getProductDetailContentTabs(this.props.product?.pprContent))

    otherTabs = () => {
        if (isEmpty(this.state.selectedTab)) return;
        const otherTabs = this.getTabs().filter(tab => tab.id !== this.state.selectedTab?.id);

        return (
            <NsTypography className='mt-2' variant='inherit'>
                {this.props.product && otherTabs.map((otherTab, index) => (
                    <React.Fragment key={otherTab.id}>
                        <Link onClick={() => this.setSelectedTabHandler(otherTab)}
                            //just fot SEO
                            to={`?${CommonHelper.makeCorrectLink(otherTab.link)}`}>{otherTab.label}</Link>
                        {index < otherTabs.length - 1 && <span> | </span>}
                    </React.Fragment>
                ))
                }
            </NsTypography>
        );
    }

    contentHandler = (content, label) => {
        return (
            <div className={clsx("font-size-14 p-2", label !== this.state.selectedTab?.label && 'd-none')}>
                <NsHtmlToReact>
                    {content}
                </NsHtmlToReact>
                {this.otherTabs()}
            </div>
        );
    }

    render() {
        const { selectedTabIndex, getSimilarProductsDate, selectedTab } = this.state;
        const { product, selectedCurrencyId, history } = this.props;

        return (
            <NsCard className='mt-3 p-2'>
                <NsTab tabs={this.getTabs()}
                    onChange={this.tabChanged}
                    value={selectedTabIndex}
                    history={history}
                    queryManagement
                />

                {this.contentHandler(product?.pprContent?.overview, getTranslatedRawText('product.detail.overview'))}
                <PdBaseExplanation otherTabs={this.otherTabs()} {...this.props} selectedTab={selectedTab} />
                {selectedTab?.label === getTranslatedRawText('product.detail.recommendedProducts') &&
                    <PdBaseSimilarProducts
                        getSimilarProductsDate={getSimilarProductsDate}
                        categoryIds={isEmpty(product) ? [] : product?.categories?.map(category => category.id)}
                        selectedCurrency={selectedCurrencyId}
                        selectedTab={selectedTab}
                        reloadPageAfterClickingProduct={true} />
                }
                {this.contentHandler(product?.pprContent?.faqs, getTranslatedRawText('product.shoppingBag.faqs'))}

            </NsCard>
        )
    }
}

export default PdBaseContents;