import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import NsButton from '../../../components/buttons/NsButton';
import NsCard from '../../../components/cards/NsCard';
import NsCollapse from '../../../components/dropdowns/NsCollapse';
import Links from '../../../configs/links/AllLinks';
import { GetCmsBlogsArchivesDateService, GetCmsNewsArchivesDateService } from '../../../services/singlePages/CmsService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';

class BnSidebarArchiveDate extends Component {

    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.state = {
            archiveDates: [],
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.isBlog
        ? GetCmsBlogsArchivesDateService(this.getArchiveDatesCallback)
        : GetCmsNewsArchivesDateService(this.getArchiveDatesCallback);

    getArchiveDatesCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            this.setState({ archiveDates: resultData });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    categoryItemClicked = (submitIn) => {
        let UrlParameters = { submitIn }
        return this.isBlog
            ? getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogSearchResult, UrlParameters)
            : getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsSearchResult, UrlParameters);
    }

    render() {
        const { archiveDates, resultMessageClass } = this.state;

        return isEmpty(archiveDates)
            ? null
            : <NsCard className='p-3 categories mb-3' >
                <NsCollapse className='mb-2'
                    defaultIsOpen={true}
                    titleClassName='section-title w-100'
                    title={getTranslatedRawText('product.sideBarTitles.monthlyArchive')} >

                    {archiveDates?.map((item, index) =>
                        <NsCollapse key={index} className='mb-2'
                            defaultIsOpen={index === 0}
                            titleClassName='section-title w-100'
                            title={item?.value} >

                            {item?.key?.map((month, index) =>
                                <NsButton key={index}
                                    className='black-color w-100 justify-content-start' >
                                    <Link to={() => this.categoryItemClicked(month.key)} className='d-flex w-100 black-color text-decoration-none'>
                                        {month.value}
                                    </Link>
                                </NsButton>
                            )}

                        </NsCollapse>
                    )}

                </NsCollapse>
                {resultMessageClass}
            </NsCard >
    }
}

export default withRouter(BnSidebarArchiveDate);