import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { isBusinessPartnerRoute } from '../../utilities/BusinessPartnerHelper';

const NsButtonOrLink = (props) => {
    const { className, onClick, to, children } = props;
    return isBusinessPartnerRoute()
        ? <div className={clsx(className, 'cursor-pointer')} onClick={onClick}>{children}</div>
        : <Link className={className} onClick={onClick} to={to}>{children}</Link>
}

export default NsButtonOrLink;