import Config from "../../../../../configs/Config";

const FootersTranslationEn = {

    footer: {
        copyright: 'Copyright by ',
        website: '{0}',
        reserved: 'All right reserved.',

        links: 'Links',
        contactInfo: 'Contact Info',
        hotLine: 'HotLine',
        contactTime: Config.contactInformation.en.availableTimes,
        email: Config.contactInformation.en.email,
        contactWays: 'Join Us!',
        news: 'News',
        followUs: 'Follow Us',
        discount: '{0}% off',
        testimonial: 'Testimonial',

        relatedPage: 'Related Page',
        ourCompany: 'Our Company',
        aboutUs: 'About Us',
        contactUs: 'Contact Us',
        ourEquipment: 'Our Equipment',
        specialNeeds: 'Special Needs',

        customerService: 'Customer Service',
        downloadTemplate: 'Download Center',
        ads: 'Ads',
        fAQs: 'FAQs',
        termOfUse: 'Terms of Use',
        privacyPolicy: 'Privacy Policy',
        specialInstructions: 'Special Instructions',
        warrantyTerms: 'Warranty Terms',
        help: 'Help',
        festivals: 'Festivals',

        resources: 'Resources',
        requestSample: 'Request Sample',
        siteMap: 'Site Map',
        gallery: 'Gallery',

        latestFromBlog: 'Latest From Blog',
        blogOneDate: '5 sep 2020',
        blogOneDescription: 'Rounded Corner Gold Foil spot uv Velvet Laminated',
        blogTwoDate: '12 Aug 2020',
        blogTwoDescription: 'Rounded Corner Gold Foil spot uv Velvet Laminated',
		cashback: 'Cashback',
    },

    website: {
        title: "{0}",
        changeCulture: "change language",
    }

};
export default FootersTranslationEn;