import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsTextField from '../../../../components/inputs/NsTextField';
import NsModal from '../../../../components/modals/NsModal';
import NsSwitch from '../../../../components/switch/NsSwitch';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApLanguageTabs from '../../bases/ApLanguageTabs';
import ContentHelper from '../../contents/bases/ContentHelper';

const EditSmsModal = (props) => {

    const [multiLanguageSmsConfigContent, setMultiLanguageSmsConfigContent] = useState([]);
    const [showValidationDate, setShowValidationDate] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLanguageInfo, setSelectedLanguageInfo] = useState({ id: 1 });
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    const languageChangedHandler = useCallback(lngInfo => {
        setSelectedLanguageInfo(lngInfo);
    }, []);

    useEffect(() => {
        getSmsConfigs(props.selectedSmsId);
    }, [])

    const getSmsConfigs = (id) => {
        // setIsLoading(true);
        // GetSmsConfigsService(id, getSmsConfigsCallback);
    }

    const getSmsConfigsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguageSmsConfigContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive }));
        } else
            setMessageClassModal(messageClassModal);
    }

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguageSmsConfigContent([...newData]);
    }

    const saveButtonClicked = () => {
        let tempContent = ContentHelper.removeSmsConfigsEmptyContent(multiLanguageSmsConfigContent);
        let isNotValidData = ContentHelper.isValidSmsConfigsContent(tempContent);
        if (isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }

        setIsLoading(true);
        // EditSmsConfigContentService(selectedSmsId, tempContent, smsConfigsContentCallback);
    }

    const smsConfigsContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModal();
        } else
            setMessageClassModal(messageClassModal);
    };

    const cancelClicked = () => {
        CommonHelper.safeFunctionCall(props.closeModal);
    }

    const mainButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary' },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: cancelClicked, className: 'primary-outline' },
    ];

    return (
        <NsModal maxWidth='lg'
            showModal={true}
            closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
            noSpace={true}
            dialogContentClassName='p-3'
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}
            dialogTitle={getTranslatedRawText(props.isNewSmsConfig ? 'adminPanel.sidebar.addNewSmsConfig' : "adminPanel.sidebar.editSmsConfig")}>

            <ApLanguageTabs languageChanged={languageChangedHandler}
                selectedTabId={selectedLanguageInfo?.id} />

            {/* {!isEmpty(multiLanguageSmsConfigContent) || */}
            {true &&
                <TabDataTables key={selectedLanguageInfo.id}
                    stateHandler={stateHandler}
                    data={[...multiLanguageSmsConfigContent]}
                    showValidationDate={showValidationDate}
                    selectedLanguage={selectedLanguageInfo} />
            }

            {messageClassModal}

        </NsModal>
    );
};

function TabDataTables(props) {
    const { stateHandler, data, showValidationDate, selectedLanguage } = props;
    let smsConfigContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);

    return <div className='d-flex flex-column justify-content-start'>

        <div className="d-flex mb-2 align-items-end justify-content-between w-100 flew-row" >
            <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                value={smsConfigContent?.content.title}
                showValidationDate={showValidationDate}
                className='w-100' />
            <div className='ps-2 d-flex align-items-end'>
                <NsSwitch label='adminPanel.slider.active'
                    checked={smsConfigContent?.content.isActive}
                    disabled={!selectedLanguage.isDefault}
                    onChange={() => stateHandler(data, { isActive: !smsConfigContent?.content.isActive }, selectedLanguage.id)} />
            </div>
        </div>

        <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.content')}
            valueChanged={(value) => stateHandler(data, { content: value }, selectedLanguage.id)}
            value={smsConfigContent?.content.content}
            className='w-100 mb-2' />

    </div>
}

export default React.memo(EditSmsModal);