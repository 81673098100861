import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PatchRequest, PostRequest } from '../bases/BaseService';

const GetCustomersService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCustomers), parameters, callback);
}
const GetUserFieldVisibilityTypeService = (id, parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserFieldVisibilityType), id), parameters, callback);
}
const GetCustomerDetailService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCustomerById), id), {}, callback);
}
const EditCustomerDetailService = (id, parameters, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApCustomer), id), parameters, callback, showSuccessResponseMessage);
}
const GetUserBasicInfoByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserBasicInfoById), id), {}, callback);
}
const PostUserDisableTokenService = (id, callback, showSuccessResponseMessage) => {
    PostRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApUserDisableToken)), { id }, callback, showSuccessResponseMessage);
}
const GetApUserDuplicatePhoneNumbersService = (parameters, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDuplicatePhoneNumbers)), parameters, callback, showSuccessResponseMessage);
}
const GetApUserDuplicateFullNameService = (parameters, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDuplicateFullName)), parameters, callback, showSuccessResponseMessage);
}
const GetApUserDuplicateCompanyNameService = (parameters, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDuplicateCompanyName)), parameters, callback, showSuccessResponseMessage);
}
const GetApUserDuplicatePhoneNumbersExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDuplicatePhoneNumbersExcelReport), data, callback);
}
const GetApUserDuplicateFullNameExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDuplicateFullNameExcelReport), data, callback);
}
const GetApUserDuplicateCompanyNameExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDuplicateCompanyNameExcelReport), data, callback);
}
const GetApUserCreditService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserCredit), id), {}, callback);
}
const GetApUserProfileQrCodeService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserProfileQrCode), parameters, callback);
}
const EditUserPublicProfileStatusService = (parameters, callback, showSuccessResponseMessage) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editUserPublicProfileStatus), parameters, callback, showSuccessResponseMessage);
}
const GetSupportersService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApSupporters), parameters, callback);
}
const GetSupporterDetailService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApSupporterById), id), {}, callback);
}
const EditSupporterDetailService = (id, parameters, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApSupporter), id), parameters, callback, showSuccessResponseMessage);
}
const GetSupporterBasicInfoByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApSupporterBasicInfoById), id), {}, callback);
}
const GetClaimValueTypesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApClaimValueTypes), parameters, callback);
}
const EditClaimValueTypesService = (id, parameters, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApClaimValueTypes), id), parameters, callback, showSuccessResponseMessage);
}
const GrantCreditUserService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApUserCredit), id), data, callback, showSuccessResponseMessage);
}

const GetBusinessPartnersService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApBusinessPartners), parameters, callback);
}
const GetBusinessPartnerDetailService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApBusinessPartnerById), id), {}, callback);
}
const EditBusinessPartnerDetailService = (id, parameters, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApBusinessPartner), id), parameters, callback, showSuccessResponseMessage);
}
const GetUserBusinessPartnerBasicInfoByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserBusinessPartnerBasicInfoById), id), {}, callback);
}
const GetUserBusinessPartnerApiKeyWithAbstractDetailService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserBusinessPartnerApiKeyWithAbstractDetail), id), {}, callback);
}
const GetUserBusinessPartnerResetSecureApiKeyService = (id, callback, showSuccessResponseMessage) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserBusinessPartnerResetSecureApiKey), { id }, callback, showSuccessResponseMessage);
}
const GetUserActiveAddressesService = (userId, callback, showSuccessResponseMessage) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserActiveAddresses), { userId }, callback, showSuccessResponseMessage);
}

export {
    GetSupportersService,
    GetSupporterDetailService,
    EditSupporterDetailService,
    GetSupporterBasicInfoByIdService,
    GetClaimValueTypesService,
    EditClaimValueTypesService,
    GrantCreditUserService,

    GetCustomersService,
    GetCustomerDetailService,
    EditCustomerDetailService,
    GetUserBasicInfoByIdService,
    PostUserDisableTokenService,
    GetApUserDuplicatePhoneNumbersService,
    GetApUserDuplicateFullNameService,
    GetApUserDuplicateCompanyNameService,
    GetApUserDuplicatePhoneNumbersExcelReportService,
    GetApUserDuplicateFullNameExcelReportService,
    GetApUserDuplicateCompanyNameExcelReportService,
    GetApUserCreditService,
    GetApUserProfileQrCodeService,
    EditUserPublicProfileStatusService,
    GetUserFieldVisibilityTypeService,

    GetBusinessPartnersService,
    GetBusinessPartnerDetailService,
    EditBusinessPartnerDetailService,
    GetUserBusinessPartnerBasicInfoByIdService,
    GetUserBusinessPartnerApiKeyWithAbstractDetailService,
    GetUserBusinessPartnerResetSecureApiKeyService,
    GetUserActiveAddressesService
}