import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PostRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';

const SignInService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.signIn), data, callback);
}

const GetCaptchaService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.getCaptcha), {}, callback);
}

const GetAccountService = (mobile, callback) => {
    let parameters = { mobile: mobile };
    GetRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.getAccount), parameters, callback);
}

const GetSignupFieldVisibilityTypeService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.getSignupFieldVisibilityType), parameters, callback);
}

const SignupService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.signup), data, callback);
}

const SendVerificationCodeForSignupService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.sendVerificationCodeForSignup), data, callback);
}
const SendVerificationCodeForResetPasswordService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.sendVerificationCodeForResetPassword), data, callback);
}

const ResendVerificationCodeService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.resendVerificationCode), data, callback);
}

const VerifyMobileService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.verifyMobile), data, callback);
}

const ChangePasswordService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.account, Links.api.account.changePassword), data, callback);
}

export {
    SignInService,
    GetAccountService,
    SignupService,
    SendVerificationCodeForSignupService,
    SendVerificationCodeForResetPasswordService,
    ResendVerificationCodeService,
    VerifyMobileService,
    ChangePasswordService,
    GetCaptchaService,
    GetSignupFieldVisibilityTypeService
}