import React, { useCallback, useEffect, useState } from "react";

import NsStickyButton from "../../../../components/buttons/NsStickyButton";
import NsDatePicker from "../../../../components/dateTimePickers/NsDatePicker";
import NsCheckbox from "../../../../components/inputs/NsCheckbox";
import NsTextField from "../../../../components/inputs/NsTextField";
import NsMessageClassModal from "../../../../components/modals/NsMessageClassModal";
import NsModal from "../../../../components/modals/NsModal";
import NsSwitch from "../../../../components/switch/NsSwitch";
import Enumerations from "../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import {
    DeleteAnnouncementContentService,
    EditAnnouncementContentService,
    GetAnnouncementContentByIdService,
    PostAnnouncementContentService
} from "../../../../services/adminPanel/AdminAnnouncementService";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText, isEnCulture } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApBaseMapActionButtons from "../../bases/ApBaseMapActionButtons";
import ApLanguageTabs from "../../bases/ApLanguageTabs";
import ApBasesContentVisibility from "../bases/ApBasesContentVisibility";
import ContentHelper from "../bases/ContentHelper";
import NsDateTimePicker from "../../../../components/dateTimePickers/NsDateTimePicker";
import { ApFeedbackAnnouncementsModal } from '../../feedbacks/feedbackCenter/announcements/ApFeedbackAnnouncements';

const ApAnnouncementModal = (props) => {
    const { announcementId, isNewAnnouncement } = props;

    const [multiLanguageAnnouncementContent, setMultiLanguageAnnouncementContent] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [isLoading, setIsLoading] = useState(!isNewAnnouncement);
    const [showValidationDate, setShowValidationDate] = useState();
    const [showFeedbackAnnouncementModal, setShowFeedbackAnnouncementModal] = useState(false);
    const [showDeleteAnnouncementModal, setShowDeleteAnnouncementModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState(<></>);
    let announcementContent = ContentHelper.getSpecificLanguageContent(multiLanguageAnnouncementContent, selectedLanguage.id);

    useEffect(() => {
        !!isNewAnnouncement
            ? setMultiLanguageAnnouncementContent(ContentHelper.normalizeContentObject([], { isActive: false }))
            : GetAnnouncementContentByIdService(announcementId, getAnnouncementContentByIdCallback);
    }, []);

    const getAnnouncementContentByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguageAnnouncementContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive, isDisplayAsModal: resultData[0].content.isDisplayAsModal }));
        } else
            setMessageClassModal(messageClassModal);
    };

    const closeModalClicked = (messageClassModal) => CommonHelper.safeFunctionCall(props.closeModal, messageClassModal);

    const saveButtonClicked = () => {
        let tempContent = ContentHelper.removeAnnouncementEmptyContent(multiLanguageAnnouncementContent);
        let isNotValidData = ContentHelper.isValidAnnouncementContent(tempContent);
        if (isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }

        setIsLoading(true);
        !!isNewAnnouncement
            ? PostAnnouncementContentService(tempContent, announcementContentCallback, true)
            : EditAnnouncementContentService(announcementId, tempContent, announcementContentCallback, true);
    };

    const announcementContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModalClicked(messageClassModal)
        else
            setMessageClassModal(messageClassModal);
    };

    const deleteAnnouncementClicked = () => setShowDeleteAnnouncementModal(true);

    const deleteAnnouncement = (confirm) => {
        setShowDeleteAnnouncementModal(false);
        if (confirm) {
            setIsLoading(true);
            DeleteAnnouncementContentService(announcementId, deleteAnnouncementContentCallback, true);
        }
    };

    const deleteAnnouncementContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModalClicked(messageClassModal);
        else
            setMessageClassModal(messageClassModal);
    };

    const mainButtons = [
        {
            label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary',
            accessIds: isNewAnnouncement ? [UserClaimEnumerations.postApAnnouncement] : [UserClaimEnumerations.editApAnnouncement]
        },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(false), className: 'primary-outline' },
    ];

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguageAnnouncementContent([...newData]);
    }

    const toggleFeedbackAnnouncementModal = () => setShowFeedbackAnnouncementModal(!showFeedbackAnnouncementModal)

    const headerButtons = [
        { wrapperClassName: '', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deleteAnnouncementClicked, accessIds: [UserClaimEnumerations.deleteApAnnouncement] },
        { wrapperClassName: '', startIcon: 'ns-icon-feedback-center', label: 'adminPanel.feedback.feedbackAnnouncements', onClick: toggleFeedbackAnnouncementModal, accessIds: [UserClaimEnumerations.getApFeedbackAnnouncements] }
    ]

    return (
        <NsModal
            maxWidth="md"
            dialogTitle={getTranslatedRawText(isNewAnnouncement ? 'adminPanel.announcement.newAnnouncement' : 'adminPanel.announcement.editAnnouncement')}
            showModal={true}
            noSpace={true}
            closeModal={closeModalClicked}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>

            {!isNewAnnouncement && <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            }
            <div className="p-3">
                <ApLanguageTabs languageChanged={languageChangedHandler}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguageAnnouncementContent) &&
                    <TabDataTables key={selectedLanguage.id}
                        stateHandler={stateHandler}
                        data={[...multiLanguageAnnouncementContent]}
                        showValidationDate={showValidationDate}
                        selectedLanguage={selectedLanguage} />
                }
            </div>
            {showDeleteAnnouncementModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => deleteAnnouncement(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), '')}</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }
            {showFeedbackAnnouncementModal &&
                <ApFeedbackAnnouncementsModal showModal={showFeedbackAnnouncementModal}
                    closeModal={toggleFeedbackAnnouncementModal}
                    searchData={{ user: announcementContent?.content?.editByUserId }} />
            }

            {messageClassModal}
        </NsModal >
    );
}

function TabDataTables(props) {
    const { stateHandler, data, showValidationDate, selectedLanguage } = props;
    let announcementContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);

    const contentVisibilityTypeIdChanged = (selectedItem) => {
        stateHandler(data, { contentVisibilityTypeId: selectedItem }, selectedLanguage.id)
    }

    const selectedAgencyIdsChanged = (agencyIds) => {
        stateHandler(data, { agencyIds }, selectedLanguage.id)
    }

    return (
        <div className='d-flex flex-column'>
            <div className="row">
                <div className="col-md-6 col-sm-12 mb-4">
                    <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                        required
                        valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                        value={announcementContent?.content.title || null}
                        showValidationDate={showValidationDate}
                        className='w-100' />
                </div>
                <div className="col-md-3 col-sm-6 mb-4">
                    <NsDateTimePicker label={getTranslatedRawText('components.advanceSearch.expirationDate')}
                        value={!isEmpty(announcementContent?.content.expirationAt) ? new Date(announcementContent?.content.expirationAt) : null}
                        valueChanged={(value) => stateHandler(data, { expirationAt: value }, selectedLanguage.id)}
                        noValidationDate={true}
                        disableFuture={true}
                        nullAble={true} />
                </div>
                <div className="col-md-2 col-sm-6 pt-2 mb-4">
                    <NsSwitch label='adminPanel.slider.active'
                        checked={announcementContent?.content.isActive || false}
                        disabled={!selectedLanguage.isDefault}
                        onChange={() => stateHandler(data, { isActive: !announcementContent?.content.isActive }, selectedLanguage.id)} />
                </div>
            </div>
            <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.content')}
                required
                valueChanged={(value) => stateHandler(data, { mainContent: value }, selectedLanguage.id)}
                value={announcementContent?.content.mainContent}
                showValidationDate={showValidationDate}
                className='w-100 mb-2' />
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.link')}
                        valueChanged={(value) => stateHandler(data, { linkUrl: value }, selectedLanguage.id)}
                        value={announcementContent?.content.linkUrl}
                        className={`w-100 mb-3 ${!isEnCulture() && 'not-rtl'}`} />
                </div>
                <div className="col-md-6 col-sm-12">
                    <NsTextField label={getTranslatedRawText('adminPanel.announcement.linkText')}
                        valueChanged={(value) => stateHandler(data, { linkText: value }, selectedLanguage.id)}
                        value={announcementContent?.content.linkText}
                        className={`w-100 mb-3`} />
                </div>
            </div>
            <NsCheckbox label={getTranslatedRawText('adminPanel.announcement.displayAsModal')}
                value={announcementContent?.content.isDisplayAsModal || false}
                disabled={!selectedLanguage.isDefault}
                className='mb-3'
                onChange={(checked) => stateHandler(data, { isDisplayAsModal: checked }, selectedLanguage.id)} />

            <ApBasesContentVisibility contentVisibilityTypeId={announcementContent?.content?.contentVisibilityTypeId}
                selectedAgencies={announcementContent?.content?.selectedAgencies}
                contentVisibilityTypeIdChanged={contentVisibilityTypeIdChanged}
                selectedAgencyIdsChanged={selectedAgencyIdsChanged} />
        </div>
    )
}

export default React.memo(ApAnnouncementModal);