import React, { useState } from 'react';
import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch';
import NsButton from '../../../../components/buttons/NsButton';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../bases/ApBaseSearchButtons';
import ApBaseUserSelectModal from '../../bases/ApBaseUserSelectModal';

const ApSearchLogs = ({ isLoading, setSearchData }) => {

    const [user, setUser] = useState(null);
    const [message, setMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [customLogType, setCustomLogType] = useState(null);
    const [fromSubmitTime, setFromSubmitTime] = useState(null);
    const [toSubmitTime, setToSubmitTime] = useState(null);
    const [orderApplicationTypes, setOrderApplicationTypes] = useState([]);
    const [showUserSelectModal, setShowUserSelectModal] = useState(false);

    const searchValues = {
        user,
        message,
        selectedUser,
        customLogType,
        fromSubmitTime,
        toSubmitTime,
        orderApplicationTypes
    };

    const usersModalButtonClicked = () => setShowUserSelectModal(!showUserSelectModal);

    const searchOptions = [
        hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            onChange: (value) => setUser(value),
            endAdornment: <NsButton className='light-circle'
                loading={isLoading}
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" />
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.systemMonitoring.logType'),
            fieldName: 'customLogType',
            value: customLogType,
            items: ConstantsWithTranslation.getLogType(),
            onChange: (value) => setCustomLogType(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.orderType.orderApplicationType'),
            fieldName: 'orderApplicationTypes',
            value: orderApplicationTypes,
            items: ConstantsWithTranslation.getOrderApplicationType(),
            onChange: (value) => setOrderApplicationTypes(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('adminPanel.systemMonitoring.message'),
            fieldName: 'message',
            value: message,
            onChange: (value) => setMessage(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.storeProduct.createTimeFrom'),
            fieldName: 'fromSubmitTime',
            value: fromSubmitTime,
            onChange: (value) => setFromSubmitTime(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.storeProduct.createTimeTo'),
            fieldName: 'toSubmitTime',
            value: toSubmitTime,
            onChange: (value) => setToSubmitTime(value)
        }
    ];

    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user?.phoneNumber);
        setShowUserSelectModal(false);
    }

    const clearButtonClicked = () => {
        setUser('');
        setMessage('');
        setSelectedUser(null);
        setCustomLogType(null);
        setFromSubmitTime(null);
        setToSubmitTime(null);
        setOrderApplicationTypes(null);

        CommonHelper.safeFunctionCall(setSearchData, {});
    }

    const searchClicked = () => {
        let data = {
            'Filter.UserId': selectedUser?.userId,
            'Filter.SubmitTimeFrom': fromSubmitTime,
            'Filter.SubmitTimeTo': toSubmitTime,
            'Filter.Message': message,
            'Filter.OrderApplicationType': orderApplicationTypes?.id,
            'Filter.CustomLogType': customLogType?.id
        }
        CommonHelper.safeFunctionCall(setSearchData, data);
    }

    return (
        <div>
            <NsAdvanceSearch searchOptions={searchOptions}
                searchValues={searchValues}
                mdCol={3}
                smCol={6} />

            <ApBaseSearchButtons searchClicked={searchClicked}
                clearButtonClicked={clearButtonClicked}
                isLoading={isLoading} />

            {showUserSelectModal &&
                <ApBaseUserSelectModal showModal={true}
                    userSelected={userSelected}
                    closeModal={() => setShowUserSelectModal(false)} />
            }

        </div>
    );
};

export default React.memo(ApSearchLogs);