import { Translate, I18n } from 'react-redux-i18n';

import { checkIsValidEnumKey, checkIsValidEnumKeyFromValue, getEnumKeyFromValue } from './EnumerationHelper'
import ConstantsWithTranslation from '../configs/ConstantsWithTranslation';
import Enumerations from '../configs/Enumerations';
import { isEmpty } from './ValidationHelper';
import StorageHelper from './StorageHelper';
import Config from '../configs/Config';

export const getCurrentCulture = () => {

    let culture = '';
    let pathname = window.location.pathname;
    let cultureInUrl = pathname.substring(1, 3).toLowerCase();
    var isExists = checkIsValidEnumKey(Enumerations.culture, cultureInUrl);
    let isValidLangUrl = !!pathname && isExists;
    let isValidLangStorage = !isEmpty(StorageHelper.getFromStorage(Enumerations.storage.culture))

    if (!isValidLangUrl) {
        if (isValidLangStorage)
            culture = StorageHelper.getFromStorage(Enumerations.storage.culture)
        else
            culture = Config.app.defaultCulture
    } else
        culture = cultureInUrl;

    if (!culture)
        culture = Config.app.defaultCulture;

    setCurrentCulture(culture);

    return culture;
}

export const setCurrentCulture = (culture) => {

    StorageHelper.saveInStorage(Enumerations.storage.culture, culture);
}

export const isEnCulture = () => getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en);

export const getUrlWithCurrentCulture = (parentObject, url, parameters) => {

    if (!checkIsValidEnumKeyFromValue(parentObject, url))
        return 'Invalid Url!!!';

    let query = '';
    if (parameters != null)
        query += '?' + Object.keys(parameters).map(key => key + '=' + parameters[key]).join('&');

    return '/' + url.replace("{culture}", getCurrentCulture()) + query;
}

export const removeQueryFromURL = (url) => url.split('?')[0];

export const getCurrentDirection = () => {

    let currentCulture = getCurrentCulture();
    switch (currentCulture) {
        case getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.fa):
            return getEnumKeyFromValue(Enumerations.direction, Enumerations.direction.rtl);

        case getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en):
        default:
            return getEnumKeyFromValue(Enumerations.direction, Enumerations.direction.ltr);
    }
}

// export const getTranslatedText = (textEnum) => {
//     return <Translate value= {textEnum} />;
// }

export const changeCulture = (pathname, newCulture) => {
    const currentCulture = getCurrentCulture();
    const newURL = pathname.toLowerCase().replace(currentCulture, newCulture);
    return newURL;
}

export const isLanguageAdmissible = (props) => {
    const activeLanguages = ConstantsWithTranslation.getAllLanguages().filter(lang => lang.isActive);
    if (!activeLanguages.some(lang => lang.culture === getCurrentCulture().toLocaleLowerCase())) {
        const newURL = changeCulture(props.location.pathname, Config.app.defaultCulture);
        props.history.push({
            pathname: newURL,
            search: props.location.search
        });
        return false;
    }
    return true
}

export const getTranslatedRawText = (textEnum) => I18n.t(textEnum);