import React from 'react';
import NsModal from '../../../components/modals/NsModal';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import { ShareSheetOrderContent } from '../managements/orders/shareSheets/ApShareSheetOrder';

const ApBaseOrderSelectModal = (props) => {
    const closeModal = () => {
        CommonHelper.safeFunctionCall(props.closeModal);
    }

    return (
        <NsModal maxWidth='lg'
            showModal={props.showModal}
            closeModal={closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.usersInfo.searchInOrders")}>
            <div className='p-3 pb-0'>
                <ShareSheetOrderContent isOrderSelection={true} selectedRows={props.orderSelected} />
            </div>
        </NsModal>
    );
};

export default React.memo(ApBaseOrderSelectModal);