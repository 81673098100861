import Links from "../../configs/links/AllLinks";
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { DeleteRequest, GetRequest, PatchRequest, PostRequest } from "../bases/BaseService";

const GetDownloadsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getDownloads), parameter, callback);
};

const PostDownloadService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postDownloads), data, callback);
};

const GetDownloadByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getDownloadById), id), {}, callback);
};

const EditDownloadService = (id, data, callback) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editDownloadById), id), data, callback);
};

const DeleteDownloadService = (id, callback) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteDownloadById), id), {}, callback);
};

const PostDownloadDetailService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postDownloadDetails), data, callback);
};

const GetDownloadDetailByIdService = (id, parameter, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getDownloadDetailById), id), parameter, callback);
};

const EditDownloadDetailService = (id, data, callback) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editDownloadDetailById), id), data, callback);
};

const DeleteDownloadDetailByIdService = (id, callback) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteDownloadDetailById), id), {}, callback);
};

const GetDownloadCategoryDropDownService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getDownloadCategoryDropDown), {}, callback);
};

const GetDownloadAllCategoryService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getDownloadCategory), parameter, callback);
};

const PostDownloadAllCategoryService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postDownloadCategory), data, callback, showSuccessResponseMessage);
};

const GetDownloadAllDetailsService = (parameters, callback) => {
    const { id, ...params } = parameters;
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getDownloadAllDetails), id), params, callback);
};

const GetDownloadCategoryByIdService = (id, parameter, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getDownloadCategoryById), id), parameter, callback);
};

const EditDownloadAllCategoryService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editDownloadCategoryById), id), data, callback, showSuccessResponseMessage);
};

const DeleteCategoryService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteDownloadCategoryById), id), {}, callback, showSuccessResponseMessage);
};

export {
    GetDownloadsService,
    PostDownloadService,
    GetDownloadByIdService,
    EditDownloadService,
    DeleteDownloadService,
    PostDownloadDetailService,
    GetDownloadDetailByIdService,
    EditDownloadDetailService,
    GetDownloadCategoryDropDownService,
    GetDownloadAllCategoryService,
    EditDownloadAllCategoryService,
    PostDownloadAllCategoryService,
    GetDownloadAllDetailsService,
    GetDownloadCategoryByIdService,
    DeleteCategoryService,
    DeleteDownloadDetailByIdService
}