import React, { Component } from "react";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import NsCard from "../../../components/cards/NsCard";
import NsCollapsibleList from "../../../components/collapsibleList/NsCollapsibleList";
import NsCollapse from "../../../components/dropdowns/NsCollapse";
import CommonHelper from "../../../utilities/CommonHelper";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import { isEmpty } from "../../../utilities/ValidationHelper";
import Enumerations from "../../../configs/Enumerations";
import DelayedTaskHelper from "../../../utilities/DelayedTaskHelper";

class SidebarCategories extends Component {
    constructor(props) {
        super(props);
        this.delayCategoriesFilter = new DelayedTaskHelper(() => {
            this.submitCategoriesFilter();
        }, 1500);

        this.state = {
            categoryItems: props.filters?.categories?.filter(item => !this.props.isStoreProducts ? item.id !== 0 : item.id !== 1000000),
        };
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.filters?.categories !== this.props.filters?.categories) {
            this.setState({
                categoryItems: this.props.filters?.categories?.filter(item => item.id !== 0),
            })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.filters?.categories !== nextProps.filters?.categories)
            this.setState({ categoryItems: nextProps.filters.categories });
    }

    categoryItemClicked = payload => {
        const categoryItems = CommonHelper.recursiveSelector(this.state.categoryItems, payload);
        this.setState({ categoryItems }, () => {
            CommonHelper.safeFunctionCall(this.props.categoriesMobileFilter, this.state.categoryItems);
            this.delayCategoriesFilter.run();
        });
    }

    submitCategoriesFilter = () => {
        CommonHelper.safeFunctionCall(this.props.categoriesFilter, this.state.categoryItems);
    }

    render() {
        const { visibleCount } = this.props;
        const categoryItems = this.state.categoryItems.filter(item => !isEmpty(item.orderProductTypeId));
        return (
            <NsCard className='p-3 categories'>
                <NsCollapse className='mb-2'
                    defaultIsOpen={true}
                    titleClassName='section-title w-100'
                    title={getTranslatedRawText('product.sideBarTitles.categories')} >
                    <NsCollapsibleList visibleCount={visibleCount === 'all' ? 1000 : 4}
                        list={categoryItems}
                        categoryItemClicked={this.categoryItemClicked}
                        viewLessWord={getTranslatedRawText('product.common.viewLess')}
                        viewMoreWord={getTranslatedRawText('product.common.viewMore')}
                        className='light-paragraph font-size-12'
                        collapseClassName='light-paragraph categories__view-more my-1' />
                    <NsSkeleton isVisible={isEmpty(categoryItems)}
                        variant={[
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                        ]} />

                </NsCollapse>
            </NsCard >
        );
    }
}

export default SidebarCategories;