import React from 'react';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

class FmDetailFolder extends React.Component {

    render() {
        const { selectedItemContent, isShowDetail } = this.props

        return (
            isShowDetail && <React.Fragment>
                <div className="align-self-center">
                    <i className='file-manager__big-icon-size ns-icon-folder' />
                </div>
                <div className="w-100 px-1">
                    <span className='text-nowrap font-size-12'>
                        {getTranslatedRawText('fileManager.mainSectionDetail.folderName')}
                    </span>
                    <span className="font-size-14 word-break text-break fw-bold mx-1">
                        {selectedItemContent.name}
                    </span>
                </div>
            </React.Fragment>
        );
    }
}

export default FmDetailFolder;
