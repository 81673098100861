import React from 'react';
import FmHeaderToolbar from './FmHeaderToolbar';
import FmHeaderUsageSpace from './FmHeaderUsageSpace';


class FileManagerHeader extends React.Component {

    render() {

        return (
            <div className='file-manager__border-style d-flex align-items-center w-100 flex-wrap py-1'>

                <FmHeaderToolbar treeViewFolders={this.props.treeViewFolders} />

                <FmHeaderUsageSpace />

            </div>
        );
    }
}

export default FileManagerHeader;