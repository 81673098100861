import React from 'react';

import Enumerations from '../../../configs/Enumerations';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import DelayedTaskHelper from '../../../utilities/DelayedTaskHelper';
import ImageHelper from '../../../utilities/ImageHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';


class OBaseFilmImagePreview extends React.Component {

    constructor(props) {
        super(props);
        this.printingImageRef = React.createRef();
        this.filmImageRef = React.createRef();
        this.delaySetTopAndLeft = new DelayedTaskHelper(this.setTopAndLeft, 500);
        this.firstTime = false;
        this.top = ImageHelper.convertMillimeterToPixel(props.filmImageInfo?.top, props.filmImageInfo?.resolution);
        this.left = ImageHelper.convertMillimeterToPixel(props.filmImageInfo?.left, props.filmImageInfo?.resolution);
        this.state = {
            filmImageInfo: props.filmImageInfo,
            top: this.top,
            left: this.left,
            masterImageWidth: 0,
            masterImageHeight: 0,
            ratio: 1,
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {

        if (this.state.filmImageInfo !== newProps.filmImageInfo)
            this.setState({
                filmImageInfo: newProps.filmImageInfo,
                top: ImageHelper.convertMillimeterToPixel(newProps.filmImageInfo?.top, newProps.filmImageInfo?.resolution) * this.state.ratio ?? 0,
                left: ImageHelper.convertMillimeterToPixel(newProps.filmImageInfo?.left, newProps.filmImageInfo?.resolution) * this.state.ratio ?? 0,
            });

        if (newProps.isAutoMatchClicked != this.props.isAutoMatchClicked)
            this.setState({ top: 0, left: 0 });

        if (!!newProps.alignType && newProps.alignType != this.props.alignType)
            this.setState({ alignType: newProps.alignType }, () => this.alignFilm(newProps.alignType));
    }

    getClientPos = (e) => {
        let pageX, pageY;

        if (e.touches) {
            pageX = e.touches[0].pageX;
            pageY = e.touches[0].pageY;
        } else {
            pageX = e.pageX;
            pageY = e.pageY;
        }

        return {
            x: pageX,
            y: pageY
        };
    }

    mouseDown = (e) => {
        if (document.onmousemove)
            document.onmousemove = null;
        let xy = this.getClientPos(e);
        this.offsetX = xy.x;
        this.offsetY = xy.y;
        let target = e.target;
        if (!target.style.left)
            target.style.left = '0px';
        if (!target.style.top)
            target.style.top = '0px';
        this.coordX = CommonHelper.toInt(target.style.left);
        this.coordY = CommonHelper.toInt(target.style.top);
        this.drag = true;

        document.onmousemove = (e) => {
            let xy = this.getClientPos(e);
            if (!this.drag)
                return;
            let target = e.target;
            if (!target.className.includes('draggable-image'))
                return;
            target.style.left = this.coordX + xy.x - this.offsetX + 'px';
            target.style.top = this.coordY + xy.y - this.offsetY + 'px';
            this.top = this.coordY + xy.y - this.offsetY;
            this.left = this.coordX + xy.x - this.offsetX;
            this.delaySetTopAndLeft.run();
            return false;
        }
        document.ontouchmove = (e) => {
            let xy = this.getClientPos(e);
            if (!this.drag)
                return;
            let target = e.target;
            if (!target.className.includes('draggable-image'))
                return;
            target.style.left = this.coordX + xy.x - this.offsetX + 'px';
            target.style.top = this.coordY + xy.y - this.offsetY + 'px';
            this.left = this.coordX + xy.x - this.offsetX;
            this.top = this.coordY + xy.y - this.offsetY;
            this.delaySetTopAndLeft.run();
            return false;
        }
        return false;
    }

    mouseUp = (e) => {
        this.drag = false;
        if (document.onmousemove)
            document.onmousemove = null;
        return false;
    }

    moveFilm = (e) => {
        this.top = this.state.top;
        this.left = this.state.left;
        switch (e.keyCode) {
            case Enumerations.keyboard.leftArrow:
                this.left -= 1
                break;
            case Enumerations.keyboard.rightArrow:
                this.left += 1
                break;
            case Enumerations.keyboard.upArrow:
                this.top -= 1
                break;
            case Enumerations.keyboard.downArrow:
                this.top += 1
                break;
        }
        this.setTopAndLeft();
    }

    alignFilm = (alignType) => {
        const image = this.printingImageRef.current;
        const film = this.filmImageRef.current;
        if (!image || !film) return;
        const imageHeight = image.height;
        const imageWidth = image.width;
        const filmHeight = film.height;
        const filmWidth = film.width;
        this.top = this.state.top;
        this.left = this.state.left;
        switch (alignType) {
            case Enumerations.coordination.top:
                this.top = 0;
                break;
            case Enumerations.coordination.verticalCenter:
                this.top = imageHeight / 2;
                this.top -= filmHeight / 2;
                break;
            case Enumerations.coordination.bottom:
                this.top = imageHeight - filmHeight;
                break;
            case Enumerations.coordination.left:
                this.left = 0;
                break;
            case Enumerations.coordination.horizontalCenter:
                this.left = imageWidth / 2;
                this.left -= filmWidth / 2;
                break;
            case Enumerations.coordination.right:
                this.left = imageWidth - filmWidth;
                break;
            case Enumerations.coordination.center:
                this.left = imageWidth / 2;
                this.left -= filmWidth / 2;
                this.top = imageHeight / 2;
                this.top -= filmHeight / 2;
                break;

            default: break;
        }
        this.setTopAndLeft();
    }

    setTopAndLeft = () => this.setState({ top: this.top, left: this.left },
        () => {
            CommonHelper.safeFunctionCall(this.props.setTopAndLeft,
                ImageHelper.convertPixelToMillimeter(this.top, this.state.filmImageInfo.resolution) / this.state.ratio,
                ImageHelper.convertPixelToMillimeter(this.left, this.state.filmImageInfo.resolution) / this.state.ratio)
        });

    setDimensionsBasedOnRatio = (masterImageInfo) => {
        if (this.state.filmImageInfo?.draft && !this.firstTime) {
            this.firstTime = true;
            const { resolution, width, height, draft } = masterImageInfo;
            const masterFilePixelWidth = ImageHelper.convertMillimeterToPixel(width, resolution);
            const masterFilePixelHeight = ImageHelper.convertMillimeterToPixel(height, resolution);
            ImageHelper.getResolutionAndDimension(draft, ({ width, height }) => {
                let mainBox = this.props.mainBox.current;
                if (mainBox) {
                    let ratio = ImageHelper.calculateMaxRatio(mainBox.clientWidth, mainBox.clientHeight, masterFilePixelWidth, masterFilePixelHeight);
                    this.setState({
                        masterImageWidth: masterFilePixelWidth,
                        masterImageHeight: masterFilePixelHeight,
                        ratio,
                        top: this.state.top * ratio,
                        left: this.state.left * ratio,
                    });
                }
            });
        }
    }

    componentDidMount = () => !this.props.isReadOnly && window.addEventListener('keydown', this.moveFilm);
    componentWillUnmount = () => {
        if (document.onmousemove)
            document.onmousemove = null;
    }

    render() {

        const { filmImageInfo, masterImageWidth, masterImageHeight, ratio, top, left } = this.state;
        const { isReadOnly, masterImageInfo, trimZone } = this.props;
        !isEmpty(masterImageInfo) && this.setDimensionsBasedOnRatio(masterImageInfo);

        return (!!filmImageInfo?.draft
            ? <div className="modal-film-and-printing-file__image-container overflow-auto custom-scrollbar bg-white w-100"
                style={{
                    "--border-top": `${trimZone?.pixelTop * ratio}px`,
                    "--border-left": `${trimZone?.pixelLeft * ratio}px`,
                    "--border-right": `${trimZone?.pixelRight * ratio}px`,
                    "--border-bottom": `${trimZone?.pixelBottom * ratio}px`,
                }}>
                <img alt={getTranslatedRawText("components.imagesAlt.uploadedImage")}
                    ref={this.printingImageRef}
                    className='modal-film-and-printing-file__printing-file w-100'
                    width={masterImageWidth * ratio}
                    height={masterImageHeight * ratio}
                    src={masterImageInfo?.draft}
                    draggable={!isReadOnly} />
                <img alt={getTranslatedRawText("components.imagesAlt.uploadedImage")}
                    ref={this.filmImageRef}
                    width={ImageHelper.convertMillimeterToPixel(filmImageInfo?.width, filmImageInfo?.resolution) * ratio}
                    height={ImageHelper.convertMillimeterToPixel(filmImageInfo?.height, filmImageInfo?.resolution) * ratio}
                    onMouseDown={!isReadOnly && this.mouseDown}
                    onTouchStart={!isReadOnly && this.mouseDown}
                    onMouseUp={!isReadOnly && this.mouseUp}
                    onTouchEnd={!isReadOnly && this.mouseUp}
                    onKeyDown={!isReadOnly && this.moveFilm}
                    className='modal-film-and-printing-file__front-film draggable-image w-100'
                    style={{
                        top: top,
                        left: left,
                    }}
                    src={filmImageInfo.draft}
                    draggable={!isReadOnly} />

            </div>
            : ''
        );
    }
}

export default OBaseFilmImagePreview;

