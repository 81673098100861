import React from 'react';
import { Row, Col } from 'react-bootstrap';

import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import OrderHelper from '../../../../utilities/OrderHelper';
import NsTour from '../../../../components/tour/NsTour';
import PoFoldLineModal from '../../professionalOrders/modals/foldLine/PoFoldLineModal';
import AddSerialModal from '../AddSerialModal';
import BillBookModalSummary from './BillBookModalSummary';
import BillBookModalTableOption from './BillBookModalTableOption';
import BillBookModalBasicOption from './BillBookModalBasicOption';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsModal from '../../../../components/modals/NsModal';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import { GetBillBookPrintColorService, GetBillBookOptionPriceService } from '../../../../services/order/OrderService';
import Enumerations from '../../../../configs/Enumerations';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsAlertModal from '../../../../components/modals/NsAlertModal';
import RegularOrderTours from '../../../bases/tours/RegularOrderTours';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';

class BillBookModal extends React.Component {

    constructor(props) {
        super(props);
        this.routeId = props.routeId;
        this.totalBillBookPrice = 0;
        this.state = {
            tourSteps: RegularOrderTours.getBillBookModalInputsTour(),
            showTour: !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookInputs),
            tourId: Enumerations.tourGuide.billBookInputs,
            optionPrice: 0,
            masterFile: props.masterFile,
            showSerialModal: false,
            showPerforationModal: false,
            billBookCopy: null,
            billBookPrintColor: null,
            billBookBindingType: null,
            billBookBindingSideType: null,
            hasUnderhand: false,
            billBookPrintColorOptions: [],
            serialStartFrom: '1000',
            selectedSerialLanguageType: null,
            billBookNumberInSeriesType: null,
            selectedSerials: [],
            selectedPerforations: [],
            isLoadingDropdown: true,
            isLoadingPrice: true,
            isAlertModalOpen: false,
            hasChangedOptions: false,
        };
    }

    componentDidMount = () => {
        const { billBookCopy, billBookPrintColor, billBookBindingType, billBookBindingSideType, serialStartFrom,
            selectedSerials, selectedPerforations, billBookNumberLanguageType, billBookNumberInSeriesType, hasUnderhand } = this.props.selectedItem;
        const { billBookProperty } = this.props;

        this.setState({
            billBookCopy,
            billBookPrintColor: billBookPrintColor?.id ?? null,
            billBookBindingType,
            billBookBindingSideType,
            serialStartFrom,
            hasUnderhand: !isEmpty(this.props.selectedItem) ? hasUnderhand : (billBookProperty.billBookUnderHandType === Enumerations.billBookUnderHandType.have),
            selectedSerialLanguageType: billBookNumberLanguageType,
            billBookNumberInSeriesType: billBookNumberInSeriesType,
            selectedSerials: selectedSerials ?? [],
            selectedPerforations: selectedPerforations ?? [],
            billBookPrintColorOptions: [],
            resultMessageClass: (<></>)
        }, () => this.getNumberOfPrintColor(billBookCopy?.id, false));

    }

    componentDidUpdate = (prevProps, prevState) => {

        if (prevState.billBookBindingType !== this.state.billBookBindingType) {
            if (!!this.state.billBookBindingType && this.state.billBookBindingType !== Enumerations.billBookBindingType.free) {
                this.setState({
                    showTour: !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookBindingSideInput),
                    tourSteps: RegularOrderTours.getBillBookModalBindingSideInputTour(),
                    tourId: Enumerations.tourGuide.billBookBindingSideInput
                })
            }

            if (!!this.state.billBookBindingType && this.state.billBookBindingType == Enumerations.billBookBindingType.free) {
                this.setState({
                    showTour: !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookModalContent),
                    tourSteps: RegularOrderTours.getBillBookModalContentTour(),
                    tourId: Enumerations.tourGuide.billBookModalContent,
                })
            }
        }

        if (prevState.selectedSerials !== this.state.selectedSerials) {
            !isEmpty(this.state.selectedSerials) && this.setState({
                showTour: !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookModalSerialRows),
                tourSteps: RegularOrderTours.getBillBookSerialNumberRowsModalTour(),
                tourId: Enumerations.tourGuide.billBookModalSerialRows
            })
        }

        if (prevState.selectedPerforations !== this.state.selectedPerforations) {
            !isEmpty(this.state.selectedPerforations) && this.setState({
                showTour: !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookModalPerforationRows),
                tourSteps: RegularOrderTours.getBillBookPerforationRowsModalTour(),
                tourId: Enumerations.tourGuide.billBookModalPerforationRows
            })
        }

        if (prevState.selectedPerforations !== this.state.selectedPerforations) {
            !isEmpty(this.state.selectedPerforations) && this.setState({
                showTour: !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookModalPerforationRows),
                tourSteps: RegularOrderTours.getBillBookPerforationRowsModalTour(),
                tourId: Enumerations.tourGuide.billBookModalPerforationRows
            })
        }

        if (prevState.billBookBindingSideType !== this.state.billBookBindingSideType) {
            !isEmpty(this.state.billBookBindingSideType) && this.setState({
                showTour: !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookModalContent),
                tourSteps: RegularOrderTours.getBillBookModalContentTour(),
                tourId: Enumerations.tourGuide.billBookModalContent
            })
        }
    }

    closeClicked = () => {
        this.state.hasChangedOptions
            ? this.setState({ isAlertModalOpen: true })
            : CommonHelper.safeFunctionCall(this.props.closeModal);
    }

    closeAlertClicked = (confirm) => {
        this.setState({ isAlertModalOpen: false });
        !!confirm && CommonHelper.safeFunctionCall(this.props.closeModal);
    }

    addSerialModalClicked = () => this.setState({ showSerialModal: true });

    addPerforationModalClicked = () => this.setState({ showPerforationModal: true });

    closeAddSerialModalClicked = () => this.setState({ showSerialModal: false });

    closeAddPerforationModalClicked = () => this.setState({ showPerforationModal: false });

    saveSerialModalClicked = (selectedSerials, serialStartFrom, selectedSerialLanguageType, billBookNumberInSeriesType) => {
        this.setState({ selectedSerials, serialStartFrom, selectedSerialLanguageType, billBookNumberInSeriesType, showSerialModal: false });
    }

    savePerforationModalClicked = (notUsed, selectedPerforations) => {
        this.setState({ selectedPerforations, showPerforationModal: false });
    }

    getPrintColorTypeObject = (value) => {
        return value ? this.state.billBookPrintColorOptions.find(item => item.id === value) : null;
    }

    getTotalBillBookPrice = (totalBillBookPrice) => this.totalBillBookPrice = totalBillBookPrice;

    refactoringCheckboxCopyOptions = (selectedList, newNumberOfCopy) => {
        let differentLength = newNumberOfCopy - selectedList[0]?.copyNumberOptions?.length;
        if (differentLength === 0)
            return;
        if (differentLength > 0)
            selectedList.map(item => {
                for (let i = 1; i <= differentLength; i++) {
                    item.copyNumberOptions.push({ copyNumber: selectedList[0]?.copyNumberOptions.length + i, checked: true });
                }
            })
        if (differentLength < 0)
            selectedList.map(item => {
                item.copyNumberOptions = [...item.copyNumberOptions.slice(0, newNumberOfCopy)];
            })
    }


    valueChanged = (value, isValid, valueName, isNumberOfCopy) => {
        let valueKey = CommonHelper.getVariableName(valueName);
        let hasUnderhand = this.state.hasUnderhand;
        if (valueKey === 'billBookBindingType') {
            const { billBookBindingType } = this.state;
            const { billBookProperty } = this.props;
            hasUnderhand = (!billBookBindingType || billBookBindingType === Enumerations.billBookBindingType.free)
                ? false
                : (billBookProperty.billBookUnderHandType === Enumerations.billBookUnderHandType.optional && billBookBindingType !== Enumerations.billBookBindingType.stapler)
                    ? false
                    : (billBookProperty.billBookUnderHandType === Enumerations.billBookUnderHandType.have)
            this.setState({ hasUnderhand });
        }
        this.setState({ [valueKey]: value, hasChangedOptions: true }, () =>
            isNumberOfCopy
                ? this.getNumberOfPrintColor(value.id, true)
                : this.getBillBookOptionPrice()
        );
    }

    getNumberOfPrintColor = (id, resetNumberOfPrintColor) => {
        const { selectedSerials, selectedPerforations, billBookCopy } = this.state;
        if (resetNumberOfPrintColor && selectedSerials.length > 0)
            this.refactoringCheckboxCopyOptions([...selectedSerials], billBookCopy.billBookCopyType);
        if (resetNumberOfPrintColor && selectedPerforations.length > 0)
            this.refactoringCheckboxCopyOptions([...selectedPerforations], billBookCopy.billBookCopyType);

        this.setState({ isLoadingDropdown: !!id, isLoadingPrice: !!id });
        !!id && GetBillBookPrintColorService(id, this.getBillBookPrintColorCallback(resetNumberOfPrintColor));
    }

    getBillBookPrintColorCallback = (resetNumberOfPrintColor) => (resultData, result, messageClassModal) => {
        this.setState({ isLoadingDropdown: false, isLoadingPrice: false });
        if (!result.data.hasError) {
            this.setState({
                billBookPrintColorOptions: resultData,
                billBookPrintColor: resetNumberOfPrintColor ? null : this.state.billBookPrintColor,
                optionPrice: resetNumberOfPrintColor ? 0 : this.state.optionPrice,
            }, () =>
                !resetNumberOfPrintColor && this.getBillBookOptionPrice()
            );
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getBillBookOptionPrice = () => {
        this.setState({ isLoadingPrice: true });
        const { billBookCopy, billBookPrintColor, billBookBindingType, billBookBindingSideType, hasUnderhand } = this.state;
        let data = {
            billBookBindingType: billBookBindingType ?? Enumerations.billBookBindingType.free,
            billBookBindingSideType: billBookBindingSideType ?? Enumerations.billBookBindingSideType.none,
            billBookCopy: billBookCopy,
            hasUnderhand: (!billBookBindingType || billBookBindingType === Enumerations.billBookBindingType.free)
                ? false
                : hasUnderhand,
            billBookPrintColor: this.getPrintColorTypeObject(billBookPrintColor),
            productId: this.routeId.split('-')[0],
            width: OrderHelper.getFileSizeAndOrderPrices(this.props.routeId).width,
            height: OrderHelper.getFileSizeAndOrderPrices(this.props.routeId).height
        }
        GetBillBookOptionPriceService(data, this.getBillBookOptionPriceCallback);
    }

    getBillBookOptionPriceCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoadingPrice: false });
        if (!result.data.hasError)
            this.setState({ optionPrice: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    saveClicked = () => {
        const { billBookCopy, billBookPrintColor, billBookBindingType, billBookBindingSideType, serialStartFrom,
            selectedSerials, selectedPerforations, selectedSerialLanguageType, billBookNumberInSeriesType, hasUnderhand } = this.state;

        const disabled = !billBookCopy || !billBookPrintColor || !billBookBindingType || (billBookBindingType !== Enumerations.billBookBindingType.free && !billBookBindingSideType);
        if (!disabled) {
            CommonHelper.safeFunctionCall(this.props.saveModalClicked,
                {
                    billBookCopy, billBookPrintColor, billBookBindingType, serialStartFrom,
                    selectedSerials,
                    selectedPerforations: billBookBindingType === Enumerations.billBookBindingType.stapler ? selectedPerforations : [],
                    billBookNumberLanguageType: selectedSerialLanguageType,
                    billBookNumberInSeriesType: billBookNumberInSeriesType,
                    billBookBindingSideType: billBookBindingType === Enumerations.billBookBindingType.free ? 0 : billBookBindingSideType,
                    billBookPrintColor: this.getPrintColorTypeObject(billBookPrintColor),
                    totalBillBookPrice: this.totalBillBookPrice,
                    width: OrderHelper.getFileSizeAndOrderPrices(this.props.routeId).width,
                    height: OrderHelper.getFileSizeAndOrderPrices(this.props.routeId).height,
                    hasUnderhand: hasUnderhand
                });
        }
    }

    createMainButtons = (disabled) => [
        CommonHelper.createStickyButtonItem("common.save", 'ns-icon-save', this.saveClicked, 'primary', disabled),
        CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.closeClicked, 'primary-outline')
    ];

    render() {
        const { masterFile, selectedSerials, serialStartFrom, selectedSerialLanguageType, billBookNumberInSeriesType, billBookCopy, isLoadingDropdown,
            billBookPrintColorOptions, billBookPrintColor, billBookBindingType, billBookBindingSideType, selectedPerforations, showSerialModal, showPerforationModal,
            showTour, optionPrice, isLoadingPrice, isAlertModalOpen, hasUnderhand } = this.state;
        const { billBookProperty } = this.props;
        const disabled = !billBookCopy || !billBookPrintColor || !billBookBindingType || (billBookBindingType !== Enumerations.billBookBindingType.free && !billBookBindingSideType);

        return (<NsModal showModal={true}
            maxWidth='lg'
            primaryActionButton={this.saveClicked}
            closeModal={this.closeClicked}
            className='fold-line-modal'
            dialogClassName="modal-choose-printing-files"
            dialogTitle={getTranslatedRawText('regularOrder.extendedService.billBook')}
            actions={<NsStickyButton stickyButtons={this.createMainButtons(disabled)} isLoading={isLoadingDropdown} />}>

            <Row className=" justify-content-start p-3">

                <BillBookModalBasicOption routeId={this.routeId}
                    billBookCopy={billBookCopy}
                    isLoadingDropdown={isLoadingDropdown}
                    billBookPrintColor={billBookPrintColor}
                    billBookPrintColorOptions={billBookPrintColorOptions}
                    billBookBindingType={billBookBindingType}
                    billBookBindingSideType={billBookBindingSideType}
                    billBookUnderHandType={billBookProperty?.billBookUnderHandType}
                    hasUnderhand={hasUnderhand}
                    valueChanged={this.valueChanged} />

                {!isEmpty(billBookCopy) && !isEmpty(billBookPrintColor) && !!billBookBindingType &&
                    <Row className='mt-3'>

                        <Col xs={12} md={7} className="extended-service__tour__bill-book__modal-content">
                            <p className='font-size-14'>
                                {getTranslatedRawText('regularOrder.billBook.billBookDescription')}
                            </p>

                            <div className='d-flex  mb-2'>
                                <NsButton startIcon={!!false ? 'ns-icon-edit me-auto' : 'ns-icon-add me-auto'}
                                    size='small'
                                    label='regularOrder.billBook.serialNumber'
                                    onClick={this.addSerialModalClicked}
                                    className='secondary extendedService-tour-filter' />
                            </div>
                            <BillBookModalTableOption rows={selectedSerials}
                                isSerial={true}
                                className="extended-service__tour__bill-book__serial-number-row"
                                billBookCopy={billBookCopy?.billBookCopyType ?? 0} />

                            {billBookBindingType === Enumerations.billBookBindingType.stapler &&
                                <>
                                    <div className=' d-flex  mb-2'>
                                        <NsButton startIcon={!!false ? 'ns-icon-edit me-auto' : 'ns-icon-add me-auto'}
                                            size='small'
                                            label='regularOrder.billBook.perforation'
                                            onClick={this.addPerforationModalClicked}
                                            className='secondary extendedService-tour-filter' />
                                    </div>
                                    <BillBookModalTableOption rows={selectedPerforations}
                                        className="extended-service__tour__bill-book__perforation-row"
                                        billBookCopy={billBookCopy?.billBookCopyType ?? 0} />
                                </>
                            }
                        </Col>

                        <Col xs={12} md={5}>
                            <BillBookModalSummary billBookCopy={billBookCopy}
                                billBookPrintColor={this.getPrintColorTypeObject(billBookPrintColor)?.billBookPrintColorType}
                                getTotalBillBookPrice={this.getTotalBillBookPrice}
                                billBookBindingType={billBookBindingType}
                                billBookBindingSideType={billBookBindingSideType}
                                billBookUnderHandType={billBookProperty?.billBookUnderHandType}
                                hasUnderhand={hasUnderhand}
                                optionPrice={optionPrice}
                                isLoadingPrice={isLoadingPrice}
                                defaultPerforationAndSerialNumberPrice={billBookProperty?.defaultPerforationAndSerialNumberPrice}
                                extraPerforationPrice={billBookProperty?.extraPerforationPrice}
                                extraSerialNumberPrice={billBookProperty?.extraSerialNumberPrice}
                                selectedSerialsLength={selectedSerials?.length}
                                selectedPerforationsLength={selectedPerforations?.length} />
                        </Col>
                    </Row>
                }
            </Row>

            {showSerialModal &&
                <AddSerialModal showModal={true}
                    masterFileInfo={masterFile}
                    trimZone={OrderHelper.getTrimZone(this.routeId)}
                    productDesignTemplateUrl={OrderHelper.getProductDesignTemplateUrl(this.routeId)}
                    selectedSerials={selectedSerials}
                    serialStartFrom={serialStartFrom}
                    routeId={this.routeId}
                    billBookCopy={billBookCopy?.billBookCopyType}
                    selectedSerialLanguageType={selectedSerialLanguageType}
                    billBookNumberLanguageType={billBookProperty?.billBookNumberLanguageType}
                    billBookNumberInSeriesType={billBookNumberInSeriesType}
                    hasExtraSerialNumber={billBookProperty?.hasExtraNumber}
                    serialBoxDimension={billBookProperty?.serialBoxDimension}
                    saveModalClicked={this.saveSerialModalClicked}
                    closeModal={this.closeAddSerialModalClicked} />
            }

            {showPerforationModal &&
                <PoFoldLineModal masterFiles={masterFile}
                    trimZone={OrderHelper.getTrimZone(this.routeId)}
                    showModal={true}
                    isPerforation={true}
                    billBookCopy={billBookCopy?.billBookCopyType}
                    selectedSerials={selectedSerials}
                    hasExtraPerforation={billBookProperty?.hasExtraPerforation}
                    closeModal={this.closeAddPerforationModalClicked}
                    savedClicked={this.savePerforationModalClicked}
                    selectedFoldLinePreset={null}
                    customFoldLines={selectedPerforations} />
            }

            {isAlertModalOpen &&
                <NsAlertModal isOpen={isAlertModalOpen}
                    cancelClicked={() => this.closeAlertClicked(false)}
                    confirmClicked={() => this.closeAlertClicked(true)} >
                    {getTranslatedRawText('regularOrder.modal.closeServicesModalAlert')}
                </NsAlertModal>
            }

            {this.state.showTour && <NsTour key={this.state.tourId}
                steps={this.state.tourSteps}
                id={this.state.tourId} />}

            {this.state.resultMessageClass}
        </NsModal >
        );
    }
}

export default BillBookModal;