import Links from "../../configs/links/AllLinks";
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { DeleteRequest, GetRequest, PatchRequest, PostRequest } from "../bases/BaseService";


const GetAgreementService = (parameter, callback, showSuccessResponseMessage) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAgreements), parameter, callback, showSuccessResponseMessage);
};

const PostAgreementService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApAgreement), data, callback, showSuccessResponseMessage);
};

const PostAgreementStaticPagesContentService = (id, data, callback, showSuccessResponseMessage) => {
    PostRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApAgreementStaticPageDetails), id), data, callback, showSuccessResponseMessage);
};

const EditAgreementStaticPagesContentService = (agreementId, id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApAgreementStaticPageDetails), agreementId, id), data, callback, showSuccessResponseMessage);
};

const DeleteAgreementStaticPagesContentService = (id, staticPageId, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApAgreementStaticPageDetails), id), { StaticPageType: staticPageId }, callback, showSuccessResponseMessage);
};

const GetAgreementStaticPagesService = (parameter, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAgreementStaticPages), parameter.id), parameter, callback, showSuccessResponseMessage);
};

const GetAgreementStaticPageDetailService = (parameter, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAgreementStaticPageDetail), parameter.id), parameter, callback, showSuccessResponseMessage);
};

const GetAgreementInfoService = (id, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAgreementInfo), id), {}, callback, showSuccessResponseMessage);
};

const DeleteAgreementService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApAgreementById), id), {}, callback, showSuccessResponseMessage);
};

const EditAgreementService = (id, data, callback) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApAgreement), id), data, callback);
};

const GetCurrentActiveUserAgreementService = (parameter, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCurrentActiveUserAgreement), parameter.id), parameter, callback, showSuccessResponseMessage);
};

const GetUserAgreementHistoriesService = (parameter, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserAgreementHistories), parameter.id), {}, callback, showSuccessResponseMessage);
};

const GetUserProfileInfoService = (id, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserProfileInfo), id), {}, callback, showSuccessResponseMessage);
};

const GetSelectedUserAgreementService = (id, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApSelectedUserAgreement), id), {}, callback, showSuccessResponseMessage);
};

const GetUsersAcceptedAgreementService = (params, callback, showSuccessResponseMessage) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUsersAcceptedAgreement), params.id), params, callback, showSuccessResponseMessage);
};

const EditActivateUserAgreementStateService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApActivateUserAgreementState), id), data, callback, showSuccessResponseMessage);
};

export {
    DeleteAgreementService,
    EditAgreementService,
    GetAgreementService,
    GetAgreementStaticPagesService,
    GetAgreementStaticPageDetailService,
    PostAgreementService,
    PostAgreementStaticPagesContentService,
    EditAgreementStaticPagesContentService,
    DeleteAgreementStaticPagesContentService,
    GetCurrentActiveUserAgreementService,
    GetUserAgreementHistoriesService,
    GetUserProfileInfoService,
    GetAgreementInfoService,
    EditActivateUserAgreementStateService,
    GetSelectedUserAgreementService,
    GetUsersAcceptedAgreementService
};
