import clsx from 'clsx';
import React, { useState } from 'react';

import EnumerationTranslationHelper from '../../../../../../utilities/EnumerationTranslationHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../../configs/Enumerations';
import DateHelper from '../../../../../../utilities/DateHelper';
import NsCard from '../../../../../../components/cards/NsCard';
import ApBaseShippingsInModal from './ApBaseShippingsInModal';
import { hasAccess } from '../../../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../../../configs/UserClaimEnumerations';

const ApShippingQueueCard = (props) => {
    const hasAccessShippingList = hasAccess([UserClaimEnumerations.getApShippings]);
    const { item, orderCalculationBasesType } = props;
    const [showShippingInModal, setShowShippingInModal] = useState(false);

    const toggleShippingInModal = () => setShowShippingInModal(!showShippingInModal);

    const queueCardClicked = () => hasAccessShippingList && toggleShippingInModal();

    return (
        <NsCard className='p-3 mb-3'>
            <div className={clsx('d-flex flex-column gap-2', hasAccessShippingList && 'cursor-pointer')} onClick={queueCardClicked} >
                <div className='d-flex gap-2 font-size-18 mb-3'>
                    <span>{item.title}</span>
                    <span className='ms-auto fw-bold'>{item.count}</span>
                </div>
                <table className='w-100'>
                    <thead>
                        <tr className='font-size-12 fw-light'>
                            <th></th>
                            <th>{getTranslatedRawText('adminPanel.shippings.shippingRequest')}&emsp;</th>
                            <th>{getTranslatedRawText(orderCalculationBasesType == Enumerations.orderCalculationBasesType.basedOnSeriesInOrderDetail
                                ? 'adminPanel.shippings.orderDetailSeries' : 'adminPanel.shippings.orderSum')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(item?.shippingRequests) && item.shippingRequests.map((shippingRequest, index) => (
                            <tr key={index} className={clsx('font-size-14 lh-lg', shippingRequest.shippingPlanTypeId == Enumerations.shippingPlanType.express && 'info-color')}>

                                <td>{shippingRequest.shippingPlanTypeId == Enumerations.shippingPlanType.express &&
                                    <i className='ns-icon-courier m-0 p-0 me-1' />}
                                    {EnumerationTranslationHelper.getShippingPlanType(shippingRequest.shippingPlanTypeId)}
                                    &emsp;
                                </td>
                                <td className='fw-bold'> {shippingRequest.shippingRequestCount}</td>
                                <td className='fw-bold'> {shippingRequest.orderCount}</td>
                            </tr>))}
                    </tbody>
                </table>
                <div className='font-size-12 mt-3'>
                    <span className='d-block mb-2'>{getTranslatedRawText('adminPanel.shippings.firstLastShippingRequest')}:</span>
                    <b>{CommonHelper.stringFormat(getTranslatedRawText('adminPanel.shippings.fromTo'), DateHelper.getDateAndTime(item.firstRequestDate), DateHelper.getDateAndTime(item.lastRequestDate))}</b>
                </div>
            </div >
            {
                showShippingInModal &&
                <ApBaseShippingsInModal closeModal={toggleShippingInModal}
                    {...props} />
            }
        </NsCard >
    );
}

export default React.memo(ApShippingQueueCard);