import React, { useCallback, useEffect, useState } from "react";

import NsTextField from '../../../../components/inputs/NsTextField';
import NsModal from "../../../../components/modals/NsModal";
import NsSwitch from '../../../../components/switch/NsSwitch';
import { DeleteApUserDepartmentByIdService, EditApUserDepartmentByIdService, GetApUserDepartmentByIdService, GetApUserDepartmentTreesService, PostApUserDepartmentService } from "../../../../services/adminPanel/AdminDepartmentService";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApBaseMapActionButtons from "../../bases/ApBaseMapActionButtons";
import ApLanguageTabs from "../../bases/ApLanguageTabs";
import ContentHelper from "../bases/ContentHelper";
import NsAutocomplete from "../../../../components/dropdowns/NsAutoComplete";
import NsStickyButton from "../../../../components/buttons/NsStickyButton";
import CommonHelper from "../../../../utilities/CommonHelper";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import NsMessageClassModal from "../../../../components/modals/NsMessageClassModal";
import Enumerations from "../../../../configs/Enumerations";

const ApDepartmentModal = (props) => {
    const [showDeleteDepartmentModal, setShowDeleteDepartmentModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [userDepartment, setUserDepartment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showValidationDate, setShowValidationDate] = useState('');
    const [userDepartmentTrees, setUserDepartmentTrees] = useState([]);
    const [multiLanguagePostContent, setMultiLanguagePostContent] = useState([]);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        !props?.departmentId && GetApUserDepartmentTreesService(null, getApUserDepartmentTreesCallback);

        if (isEmpty(props?.departmentId)) setMultiLanguagePostContent(ContentHelper.normalizeContentObject([], { isActive: false }))
        else {setIsLoading(true);
            GetApUserDepartmentByIdService(props?.departmentId, getApUserDepartmentByIdCallback)};
    }, [props?.departmentId])

    const getApUserDepartmentByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            setMultiLanguagePostContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive }));
        else
            setMessageClassModal(messageClassModal);
    }

    const getApUserDepartmentTreesCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) setUserDepartmentTrees(resultData)
        else setMessageClassModal(messageClassModal);
    }

    const deleteDepartmentClicked = () => setShowDeleteDepartmentModal(true);

    const deleteRow = (confirm) => {
        if (confirm) {
            setIsLoading(true);
            DeleteApUserDepartmentByIdService(props?.departmentId, deleteRowContentCallback, true);
        }
    };

    const deleteRowContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        setShowDeleteDepartmentModal(false);

        if (!result.data.hasError) {
            closeModalClicked(true, messageClassModal);
            setMessageClassModal(messageClassModal)
        }
        else setMessageClassModal(messageClassModal)
    };

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguagePostContent([...newData]);
    }

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const closeModalClicked = (refreshData, messageClassModal) => CommonHelper.safeFunctionCall(closeModal, !!refreshData, messageClassModal);

    const saveButtonClicked = () => {
        setIsLoading(true);
        let tempContent = ContentHelper.removeDepartmentEmptyContent(multiLanguagePostContent);
        if (isEmpty(props?.departmentId)) PostApUserDepartmentService(tempContent, userDepartmentChangedCallback, true)
        else EditApUserDepartmentByIdService(props?.departmentId, tempContent, userDepartmentChangedCallback, true)
    };

    const userDepartmentChangedCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) closeModalClicked(true, messageClassModal)
        else setMessageClassModal(messageClassModal)
    }

    const headerButtons = [
        hasAccess([UserClaimEnumerations.deleteApUserDepartment]) && !!props?.departmentId &&
        { wrapperClassName: '', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deleteDepartmentClicked },
    ]

    const mainButtons = [
        hasAccess([props?.departmentId ? UserClaimEnumerations.editApUserDepartment : UserClaimEnumerations.postApUserDepartment]) && {
            label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary'
        },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(false), className: 'primary-outline' },
    ];

    const { showModal, closeModal } = props;
    const postContent = ContentHelper.getSpecificLanguageContent([...multiLanguagePostContent], selectedLanguage.id);

    return (
        <NsModal
            maxWidth="sm"
            dialogTitle={!!props?.departmentId ? getTranslatedRawText('adminPanel.department.editDepartments') : getTranslatedRawText('adminPanel.department.addDepartment')}
            showModal={showModal}
            noSpace={true}
            closeModal={closeModal}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>

            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            <div className="px-3">
                <ApLanguageTabs languageChanged={languageChangedHandler}
                    selectedTabId={selectedLanguage?.id} />
                {!isEmpty(multiLanguagePostContent) &&
                    <Content key={selectedLanguage.id}
                        stateHandler={stateHandler}
                        data={[...multiLanguagePostContent]}
                        showValidationDate={showValidationDate}
                        selectedLanguage={selectedLanguage}
                        userDepartmentTrees={userDepartmentTrees}
                        departmentId={props?.departmentId}
                        postContent={postContent}
                    />
                }
            </div>
            {showDeleteDepartmentModal &&
                <NsMessageClassModal show={showDeleteDepartmentModal}
                    onClose={(confirm) => !!confirm ? deleteRow(confirm) : setShowDeleteDepartmentModal(false)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureToDeleteDepartment'), postContent?.content?.title)}</>,
                            messageType: Enumerations.messageType.question,
                        }]} />
            }
            {messageClassModal}
        </NsModal >
    );
}

const Content = ({ stateHandler, data, showValidationDate, selectedLanguage, userDepartmentTrees, departmentId, postContent }) => {

    postContent.content = postContent.content && { ...postContent.content, contentVisibilityTypeId: 1 };

    return <div className='d-flex flex-column'>
        <div className='d-flex'>
            <div className='flex-grow-1 pe-2'>
                <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                    valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                    value={postContent?.content?.title || null}
                    showValidationDate={showValidationDate}
                    className='w-100 mb-2' />
            </div>
            <div className='align-self-center'>
                <NsSwitch label='adminPanel.slider.active'
                    checked={postContent?.content?.isActive}
                    disabled={!selectedLanguage.isDefault}
                    onChange={() => stateHandler(data, { isActive: !postContent?.content?.isActive }, selectedLanguage.id)} />
            </div>
        </div>
        <NsAutocomplete label={getTranslatedRawText('adminPanel.department.parentDepartment')}
            items={!departmentId ? userDepartmentTrees : []}
            onChange={(inputValue) => !departmentId && stateHandler(data, { parentId: inputValue?.id, fullParentTitle: inputValue?.name }, selectedLanguage.id)}
            value={{ id: 1, value: postContent?.content?.fullParentTitle }}
            disabled={!!departmentId}
            showValidationDate={showValidationDate}
            className='w-100 mb-2' />
    </div>
}



export default React.memo(ApDepartmentModal);