import Links from "../../configs/links/AllLinks";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { GetRequest } from "../bases/BaseService";

const GetProfileUserFeedbacksService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getProfileUserFeedbacks), parameters, callback);
}


export {
    GetProfileUserFeedbacksService
};
