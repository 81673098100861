import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import '../../../../assets/sass/views/singlePages/SpecialNeeds.scss';
import NsCarouselSwiper from '../../../../components/carousel/NsCarouselSwiper';
import NsHtmlToReact from '../../../../components/htmlToReact/NsHtmlToReact';
import NsLabelLine from '../../../../components/labels/NsLabelLine';
import NsTypography from '../../../../components/text/NsTypography';
import Enumerations from '../../../../configs/Enumerations';
import Links from '../../../../configs/links/AllLinks';
import { GetProductsService } from '../../../../services/product/ProductService';
import { GetRandomTagsService } from '../../../../services/singlePages/SeoService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import { viewItemListEvent } from '../../../../utilities/GoogleTagManagerHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import SpBasesBanner from '../../bases/banner/SpBasesBanner';
import SpBasesFeatureCard from '../../bases/card/SpBasesFeatureCard';
import SpBasesProductList from '../../bases/productList/SpBasesProductList';
import SpBasesSlogan from '../../bases/slogan/SpBasesSlogan';
import SeoBox from './SeoBox';
import Config from '../../../../configs/Config';
import SpecialNeedsFaq from './SpecialNeedsFaq';

// FeaturesImages
import marketingBackground from '../../../../assets/images/specialNeeds/features/marketing.webp';
import officeBackground from '../../../../assets/images/specialNeeds/features/office.webp';
import restaurantBackground from '../../../../assets/images/specialNeeds/features/resturant.webp';
import storeBackground from '../../../../assets/images/specialNeeds/features/store.webp';

//CategoryImages
import billBook from '../../../../assets/images/specialNeeds/categories/Bill-Book.png';
import brochure from '../../../../assets/images/specialNeeds/categories/Brochure.png';
import businessCard from '../../../../assets/images/specialNeeds/categories/Business_Card.png';
import dieCutting from '../../../../assets/images/specialNeeds/categories/Die_Cutting.png';
import fastFoodBox from '../../../../assets/images/specialNeeds/categories/FastFood_Box.png';
import flyer from '../../../../assets/images/specialNeeds/categories/Flyer.png';
import menu from '../../../../assets/images/specialNeeds/categories/Menu.png';
import officalAndCommercial from '../../../../assets/images/specialNeeds/categories/Offical_and_Commercial.png';
import premiumBusinessCard from '../../../../assets/images/specialNeeds/categories/Premium_BusinessCard.png';
import shoppingBag from '../../../../assets/images/specialNeeds/categories/ShoppingBag.png';
import sticker from '../../../../assets/images/specialNeeds/categories/Sticker.png';
import trayMat from '../../../../assets/images/specialNeeds/categories/Tray_mat.png';
import NsTags from '../../../../components/tags/NsTags';

class SpecialNeeds extends Component {

  constructor(props) {
    super(props);
    this.isMobileDevice = CommonHelper.isMobileDevice();
    const metaTags = getConfigObjectByCulture(Config.metaTags);
    this.state = {
      product: [],
      isLoading: true,
      resultMessageClass: '',
      tags: '',
    }

    this.specialNeedsFeatures = [
      { onClick: this.handleClickScroll, icon: CommonHelper.getNextThinIconByCulture(), img: officeBackground, title: getTranslatedRawText('singlePage.specialNeeds.features.office.title'), text: getTranslatedRawText('singlePage.specialNeeds.features.office.text'), button: getTranslatedRawText('singlePage.specialNeeds.features.office.button'), id: 'office ' },
      { onClick: this.handleClickScroll, icon: CommonHelper.getNextThinIconByCulture(), img: marketingBackground, title: getTranslatedRawText('singlePage.specialNeeds.features.marketing.title'), text: getTranslatedRawText('singlePage.specialNeeds.features.marketing.text'), button: getTranslatedRawText('singlePage.specialNeeds.features.marketing.button'), id: 'marketing ' },
      { onClick: this.handleClickScroll, icon: CommonHelper.getNextThinIconByCulture(), img: storeBackground, title: getTranslatedRawText('singlePage.specialNeeds.features.store.title'), text: getTranslatedRawText('singlePage.specialNeeds.features.store.text'), button: getTranslatedRawText('singlePage.specialNeeds.features.store.button'), id: 'store ' },
      { onClick: this.handleClickScroll, icon: CommonHelper.getNextThinIconByCulture(), img: restaurantBackground, title: getTranslatedRawText('singlePage.specialNeeds.features.resturant.title'), text: getTranslatedRawText('singlePage.specialNeeds.features.resturant.text'), button: getTranslatedRawText('singlePage.specialNeeds.features.resturant.button'), id: 'resturant ' },
    ];

    this.officeCategoryLinks = [
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links, Links.contactUs)}>${getTranslatedRawText('singlePage.specialNeeds.seoBox.description1.links.contactUs')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.official')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.official')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.businessCard')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.businessCard')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.brochures')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.brochures')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.flyer')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.flyer')}</a>`,
    ];

    this.storeCategoryLinks = [
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links, Links.home)}>${metaTags.ogSiteName}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.billBook')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.billBook')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.shoppingBag')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.shoppingBag')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.businessCard')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.businessCard')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.flyer')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.flyer')}</a>`,
    ];

    this.marketingCategoryLinks = [
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.marketing.categoryList.dieCutting')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.marketing.categoryList.dieCutting')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.shoppingBag')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.shoppingBag')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.businessCard')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.businessCard')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.flyer')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.flyer')}</a>`,
    ];

    this.restaurantCategoryLinks = [
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.fastFood')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.fastFood')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.menu')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.menu')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.trayMat')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.trayMat')}</a>`,
      `<a className='text-decoration-none' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": CommonHelper.stringSpacesToHyphen(getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.shoppingBag')) })}>${getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.shoppingBag')}</a>`,
    ];

    this.categories = [
      {
        title: getTranslatedRawText('singlePage.specialNeeds.categories.office.title'),
        subTitle: getTranslatedRawText('singlePage.specialNeeds.categories.office.subTitle'),
        text: <NsHtmlToReact>{CommonHelper.stringFormat(getTranslatedRawText('singlePage.specialNeeds.categories.office.text'),
          this.officeCategoryLinks[0], this.officeCategoryLinks[1], this.officeCategoryLinks[2], this.officeCategoryLinks[3], this.officeCategoryLinks[4])}</NsHtmlToReact>,
        id: 'office category',
        categoryList: [
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.official'), img: officalAndCommercial },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.businessCard'), img: businessCard },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.brochures'), img: brochure },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.office.categoryList.flyer'), img: flyer },
        ],
      },
      {
        title: getTranslatedRawText('singlePage.specialNeeds.categories.store.title'),
        subTitle: getTranslatedRawText('singlePage.specialNeeds.categories.store.subTitle'),
        text: <NsHtmlToReact>{CommonHelper.stringFormat(getTranslatedRawText('singlePage.specialNeeds.categories.store.text'),
          this.storeCategoryLinks[0], this.storeCategoryLinks[1], this.storeCategoryLinks[2], this.storeCategoryLinks[3], this.storeCategoryLinks[4],)}</NsHtmlToReact>,
        id: 'store category',
        categoryList: [
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.flyer'), img: flyer },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.shoppingBag'), img: shoppingBag },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.businessCard'), img: premiumBusinessCard },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.store.categoryList.billBook'), img: billBook },
        ]
      },
      {
        title: getTranslatedRawText('singlePage.specialNeeds.categories.marketing.title'),
        subTitle: getTranslatedRawText('singlePage.specialNeeds.categories.marketing.subTitle'),
        text: <NsHtmlToReact>{CommonHelper.stringFormat(getTranslatedRawText('singlePage.specialNeeds.categories.marketing.text'),
          this.marketingCategoryLinks[0], this.marketingCategoryLinks[1], this.marketingCategoryLinks[2], this.marketingCategoryLinks[3])}</NsHtmlToReact>,
        id: 'marketing category',
        categoryList: [
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.marketing.categoryList.dieCutting'), img: dieCutting },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.marketing.categoryList.sticker'), img: sticker },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.marketing.categoryList.premiumBusinessCard'), img: premiumBusinessCard },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.marketing.categoryList.brochure'), img: brochure },
        ]
      },
      {
        title: getTranslatedRawText('singlePage.specialNeeds.categories.resturant.title'),
        subTitle: getTranslatedRawText('singlePage.specialNeeds.categories.resturant.subTitle'),
        text: <NsHtmlToReact>{CommonHelper.stringFormat(getTranslatedRawText('singlePage.specialNeeds.categories.resturant.text'),
          this.restaurantCategoryLinks[0], this.restaurantCategoryLinks[1], this.restaurantCategoryLinks[2], this.restaurantCategoryLinks[3],)}</NsHtmlToReact>,
        id: 'resturant category',
        categoryList: [
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.fastFood'), img: fastFoodBox },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.menu'), img: menu },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.trayMat'), img: trayMat },
          { title: getTranslatedRawText('singlePage.specialNeeds.categories.resturant.categoryList.shoppingBag'), img: shoppingBag },
        ]
      },
    ];

    this.seoBox = [
      {
        title: getTranslatedRawText('singlePage.specialNeeds.seoBox.description1.title'),
        texts: [
          getTranslatedRawText(!CommonHelper.isMobileDevice() ? 'singlePage.specialNeeds.seoBox.description1.texts.text1' : 'singlePage.specialNeeds.seoBox.description1.texts.mobileCollapsedText1'),
          getTranslatedRawText(!CommonHelper.isMobileDevice() ? 'singlePage.specialNeeds.seoBox.description1.texts.text2' : ''),
        ],
        links: [
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description1.links.clickAway'), href: Links.home, linkType: 0 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description1.links.FAQ'), href: Links.frequentlyAskedQuestions, linkType: 0 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description1.links.contactUs'), href: Links.contactUs, linkType: 0 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description1.links.aboutUs'), href: Links.aboutUs, linkType: 0 },
        ],
        type: 'blockTitle',
      },
      {
        title: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.title'),
        texts: [getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.texts')],
        links: [
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.flyers'), linkType: 1 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.stickers'), linkType: 1 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.brochures'), linkType: 1 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.officialAndCommercial'), linkType: 1 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.Envelope'), linkType: 1 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.Kraft'), linkType: 1 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.laminatedMenus'), linkType: 2 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.trayMats'), linkType: 1 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.fastFoodPackaging'), linkType: 2 },
          { name: getTranslatedRawText('singlePage.specialNeeds.seoBox.description2.links.DiamondPrintingPress'), linkType: 0 },
        ],
        type: 'blockTitle',
      },
      {
        title: getTranslatedRawText('singlePage.specialNeeds.seoBox.description3.title'),
        texts: [getTranslatedRawText('singlePage.specialNeeds.seoBox.description3.texts')],
        links: [],
        type: 'blockTitle',
      },
      {
        title: getTranslatedRawText('singlePage.specialNeeds.seoBox.description4.title'),
        texts: [getTranslatedRawText('singlePage.specialNeeds.seoBox.description4.texts')],
        links: [],
        type: 'blockTitle',
      },
      {
        title: getTranslatedRawText('singlePage.specialNeeds.seoBox.description5.title'),
        texts: [
          getTranslatedRawText('singlePage.specialNeeds.seoBox.description5.texts.text1'),
          getTranslatedRawText('singlePage.specialNeeds.seoBox.description5.texts.text2'),
        ],
        links: [],
        type: 'blockTitle',
      }
    ];
  }

  componentDidMount = () => {
    this.getOfferProduct();
    this.getRandomTags();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCurrency !== this.props.selectedCurrency)
      this.getOfferProduct();
  }

  getProductsCallback = (resultData, result, messageClassModal) => {
    if (!result?.data?.hasError)
      this.setState({
        product: resultData.dataItems,
        isLoading: false
      }, () => viewItemListEvent(this.state.product, Enumerations.googleTagManagerListId.singlePageSidebar));
    else
      this.setState({ resultMessageClass: messageClassModal, isLoading: false });
  }

  getOfferProduct = () => {
    const parameters = {
      pageLength: 8,
      currentPageIndex: 1,
      'Filter.OrderProductType': Enumerations.orderProductType.sharedSheet,
      "Filter.productSortType": Enumerations.sortItems.random
    };
    this.setState({ isLoading: true },
      () => GetProductsService(parameters, this.getProductsCallback)
    );
  }

  getRandomTagsCallback = (resultData, result, messageClassModal) => {
    if (!result?.data?.hasError) {
      this.setState({
        tags: resultData,
        isLoading: false
      });
    }
    else
      this.setState({ resultMessageClass: messageClassModal, isLoading: false });
  }

  getRandomTags = () => {
    this.setState({ isLoading: true },
      () => GetRandomTagsService(this.getRandomTagsCallback)
    );
  }

  handleClickScroll = (id) => {
    const element = document.getElementById(`${id}category`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    const { product, tags } = this.state;

    return (
      <div className='special-needs'>
        <div>
          <SpBasesBanner className={clsx('header-banner', !CommonHelper.isMobileDevice() && 'pb-5')}
            title={<h1 class="fs-3 fw-bold text-white mb-3">{getTranslatedRawText(this.isMobileDevice ? 'singlePage.specialNeeds.mobileBanner.title' : 'singlePage.specialNeeds.desktopBanner.title')}</h1>}
            text={getTranslatedRawText(this.isMobileDevice ? 'singlePage.specialNeeds.mobileBanner.text' : 'singlePage.specialNeeds.desktopBanner.text')} />
        </div>
        {!CommonHelper.isMobileDevice() &&
          <div>
            <Row className='gap-4 mx-4 feature-row'>
              {this.specialNeedsFeatures.map(item =>
                <Col>
                  <SpBasesFeatureCard item={item} />
                </Col>
              )}
            </Row>
            <SpBasesSlogan className='fs-3 mb-5' title={getTranslatedRawText('singlePage.specialNeeds.slogan.title')} text={getTranslatedRawText('singlePage.specialNeeds.slogan.text')} />
          </div>}
        <div>
          <Row className='mb-5'>
            <SpBasesProductList id={this.categories[0].id}
              categoryList={this.categories[0].categoryList}
              title={this.categories[0].title}
              subTitle={this.categories[0].subTitle}
              text={this.categories[0].text} />
          </Row>
          <Row className='gap-5 align-items-end mb-4'>
            <SpBasesProductList id={this.categories[1].id}
              categoryList={this.categories[1].categoryList}
              title={this.categories[1].title}
              subTitle={this.categories[1].subTitle}
              text={this.categories[1].text}
              colClassName='col-6' />
            <SpBasesProductList id={this.categories[2].id}
              categoryList={this.categories[2].categoryList}
              title={this.categories[2].title}
              subTitle={this.categories[2].subTitle}
              text={this.categories[2].text}
              colClassName='col-6' />
          </Row>
          <Row className='mb-5'>
            <SpBasesProductList id={this.categories[3].id}
              categoryList={this.categories[3].categoryList}
              title={this.categories[3].title}
              subTitle={this.categories[3].subTitle}
              text={this.categories[3].text} />
          </Row>
        </div>
        <div>
          <SpBasesBanner
            className='quality-banner'
            title={getTranslatedRawText('singlePage.specialNeeds.qualityBanner.title')}
            text={getTranslatedRawText('singlePage.specialNeeds.qualityBanner.text')}
            hasIcon={true}
            iconClassName='ns-icon-next-thin' />
        </div>
        <div className='my-5'>
          <SeoBox isExpandable={true} content={this.seoBox} className='wrapper-white pt-4 pb-2 px-4' />
        </div>
        <div className='my-3'>
          <SpecialNeedsFaq />
        </div>
        <div>
          <NsLabelLine primaryTitle={getTranslatedRawText('singlePage.specialNeeds.showCase')}
            secondaryTitle=''
            linkTo={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)}
            smallSize={true}
          />
          <NsCarouselSwiper products={product} />
        </div>
        {!isEmpty(tags) &&
          <div className='d-flex flex-column justify-content-center align-items-center my-5'>
            <NsTypography className='fs-4 fw-bold special-text-color text-center'>{getTranslatedRawText('singlePage.specialNeeds.tagTitle')}</NsTypography>
            <div className='mt-4 d-flex flex-wrap justify-content-center align-items-center'>
              <NsTags tags={tags.split(/[-,]/)}
                linkToMaster={Links.view.main}
                linkToSlave={Links.view.main.allProducts} />
            </div>
          </div>
        }
      </div>

    )
  }
}

export default SpecialNeeds;