import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';

import '../../../../assets/sass/views/singlePages/Festival.scss';
import NsButton from '../../../../components/buttons/NsButton';
import Config from '../../../../configs/Config';
import Enumerations from '../../../../configs/Enumerations';
import Links from '../../../../configs/links/AllLinks';
import { GetIsWinnerService, GetLatestFestivalService } from '../../../../services/singlePages/FestivalService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import StorageHelper from '../../../../utilities/StorageHelper';
import FestivalCongratulationModal from '../../../singlePages/mainSections/festivals/modals/FestivalCongratulationModal';

class FestivalEventPromotion extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showFestivalEventPromotion: StorageHelper.getFromStorage(Enumerations.storage.showFestivalEventPromotion),
            isCollapse: true,
            festivalId: null,
            festivalName: null,
            messageClassModal: '',
            showFestivalCongratulationModal: false,
        }
    }

    componentDidMount = () => this.getLatestFestival();

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.userFullName !== this.props.userFullName)
            this.getLatestFestival()
    }

    getLatestFestival = () => GetLatestFestivalService(this.getLatestFestivalCallback);

    getLatestFestivalCallback = (resultData, result, messageClassModal) => {
        let festivalId = StorageHelper.getFromStorage(Enumerations.storage.festivalId);

        if (!result.data.hasError) {

            if (resultData?.id !== festivalId)
                StorageHelper.saveInStorage(Enumerations.storage.showFestivalEventPromotion, true);

            this.setState({
                festivalId: resultData.id,
                festivalName: resultData.name
            }, () => CurrentUserHelper.isAuthenticated() && GetIsWinnerService({
                id: resultData.id,
                cacheCode: resultData.cacheCode
            }, this.getIsWinnerCallback))
        }
        else
            this.setState({ messageClassModal: messageClassModal });
    }

    getIsWinnerCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError)
            this.setState({
                showFestivalCongratulationModal: resultData.isWinner && !StorageHelper.getFromStorage(Enumerations.storage.festivalCongratulationModals)?.includes(this.state.festivalId)
            });
        else
            this.setState({ messageClassModal: messageClassModal });
    }

    closeModal = () => this.setState({ showFestivalCongratulationModal: false });

    closeFestivalEventClicked = () => {
        StorageHelper.saveInStorage(Enumerations.storage.showFestivalEventPromotion, false);
        StorageHelper.saveInStorage(Enumerations.storage.festivalId, this.state.festivalId);
        this.setState({ showFestivalEventPromotion: false });
    }

    collapseClicked = (isCollapse) => {
        StorageHelper.saveInStorage(Enumerations.storage.isFestivalEventCollapse, isCollapse);
        this.setState({ isCollapse });
    }

    render() {
        const { isCollapse } = this.state;
        const festivalGiftBoxImage = getConfigObjectByCulture(Config.images)?.festivalPromotions[0];
        const festivalMessageBoxImage = getConfigObjectByCulture(Config.images)?.festivalPromotions[1];

        const showFestivalEventPromotion = true;
        return Config.festivalEvent?.showSnackbar
            ? <>
                {CommonHelper.isMobileDevice() ?
                    <div className={`w-100 ${showFestivalEventPromotion === null
                        ? 'd-flex'
                        : showFestivalEventPromotion
                            ? 'd-flex'
                            : 'd-none'
                        } px-2 align-items-center position-relative top-0 start-0 background-red-color`}>
                        <div className='w-100 d-flex justify-content-center align-items-center'>
                            <img src={festivalGiftBoxImage?.src}
                                className='festival-promotion__image__transition mx-2'
                                width={'40px'}
                                alt={getTranslatedRawText("components.imagesAlt.festivalIcon")} />
                            <Link to={CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.festival), this.state.festivalId)} className='text-decoration-none' >
                                <div className='white-color'>{this.state.festivalName}</div>
                            </Link>
                            <img src={festivalGiftBoxImage?.src}
                                className='festival-promotion__image__transition mx-2'
                                width={'40px'}
                                alt={getTranslatedRawText("components.imagesAlt.festivalIcon")} />
                        </div>

                    </div>
                    :
                    <>
                        <div className={!!isCollapse
                            ? 'festival-promotion__hide'
                            : 'festival-promotion__block d-flex flex-column'}>
                            {!isCollapse &&
                                <NsButton startIcon='ns-icon-close'
                                    wrapperClassName='me-auto'
                                    size='small'
                                    onClick={() => this.collapseClicked(!isCollapse)}
                                    className='primary mb-2' />
                            }
                            {!isCollapse &&
                                <div className='festival-promotion__block__message' style={{ '--promotion-image': `url(${festivalMessageBoxImage?.src})` }}>
                                    <Link to={CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.festival), this.state.festivalId)} className='text-decoration-none' >
                                        <div className='d-flex flex-column align-items-start justify-content-start p-2 ms-3 me-5 white-color font-size-14 text-nowrap'>
                                            <span className='fw-bold py-1'>{this.state.festivalName}</span>
                                        </div>
                                    </Link>
                                </div>
                            }
                        </div>
                        {<div className={clsx(!!isCollapse && 'cursor-pointer', 'festival-promotion__image')}
                            onClick={() => this.collapseClicked(!isCollapse)}>

                            <img src={festivalGiftBoxImage?.src}
                                className='festival-promotion__image__transition'
                                width={!isCollapse ? '100vw' : CommonHelper.isMobileDevice() ? '60vw' : '80vw'}
                                alt='festival gift box' />
                        </div>}
                    </>
                }
                {this.state.showFestivalCongratulationModal && <FestivalCongratulationModal className='vh-100 w-50'
                    closeModal={this.closeModal}
                    showModal={this.state.showFestivalCongratulationModal} />}
            </>
            : null;
    }
}

const mapStateToProps = (state) => {
    return {
        userFullName: state.currentUser.userFullName,
    };
}

export default connect(mapStateToProps)(FestivalEventPromotion);