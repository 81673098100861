import React from 'react';
import { Col, Row } from 'react-bootstrap';
import '../../../assets/sass/views/orders/professionalOrder.scss';

import { AddToOrderListService, GetExtendedServicePriceService, SaveProfessionalOrderService } from '../../../services/order/ProfessionalOrderService';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import NsLoadingProgress from '../../../components/circularProgress/NsLoadingProgress';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsFloatButton from '../../../components/buttons/NsFloatButton';
import ProfessionalOrderHelper from './bases/ProfessionalOrderHelper';
import { messageClass } from '../../../utilities/MessageModalHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import PoModalSuccessful from './modals/PoModalSuccessful';
import PoMainSection from './mainSections/PoMainSection';
import PoOrderDetail from './orderDetails/PoOrderDetail';
import Enumerations from '../../../configs/Enumerations';
import OrderHelper from '../../../utilities/OrderHelper';
import NsModal from '../../../components/modals/NsModal';
import UrlHelper from '../../../utilities/UrlHelper';
import NsTour from '../../../components/tour/NsTour';
import Links from '../../../configs/links/AllLinks';
import OBaseWrapper from '../bases/OBaseWrapper';
import OSummary from '../summaries/OSummary';
import OHeader from '../headers/OHeader';
import NsMessageClassModal from '../../../components/modals/NsMessageClassModal';
import StorageHelper from '../../../utilities/StorageHelper';
import { addToCartEvent } from '../../../utilities/GoogleTagManagerHelper';
import AddToListResultModal from './modals/AddToListResultModal';

class ProfessionalOrder extends React.Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.detailRef = React.createRef();
        this.mainButtons = [
            CommonHelper.createStickyButtonItem('regularOrder.confirmDetail.addToCurrentList', 'ns-icon-down', this.addToCurrentListClicked, 'secondary'),
            CommonHelper.createStickyButtonItem('regularOrder.confirmDetail.viewList', null, this.showCurrentListModalClicked, 'secondary-outline'),
        ];
        this.state = {
            routeId: UrlHelper.getRouteValue(props, Enumerations.routeKey.id),
            orderId: null,
            totalExtendedServicePrice: 0,
            refreshSummaryDate: null,
            refreshOrderDetailDate: null,
            showSubmitOrderButton: false,
            orderInfo: null,
            grandTotal: 0,
            isOrderAdded: false,
            isLoading: false,
            pricesLoading: false,
            isShowCurrentListModal: false,
            isShowSuccsessfulModal: false,
            isShowAddToListResultModal: false,
            showTour: false,
            tourSteps: [],
            tourId: null,
            hasFileInFolder: false,
            resultMessageClass: (<></>),
            isShowMessageModal: false
        }
    }

    componentDidMount = () => {
        this.toggleProfessionalOrderTour(true);
        this.setState({ orderId: OrderHelper.getOrderId(this.state.routeId), showSubmitOrderButton: window.innerHeight == document.body.offsetHeight });
        if (window.innerHeight > 1300)
            this.setState({ showSubmitOrderButton: true });
        else
            window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount = () => window.removeEventListener('scroll', this.listenToScroll);

    listenToScroll = () => {
        if (this.detailRef.current) {
            if (window.pageYOffset >= this.detailRef.current.offsetTop - 300) {
                !this.state.showSubmitOrderButton && this.setState({ showSubmitOrderButton: true });
            } else {
                this.state.showSubmitOrderButton && this.setState({ showSubmitOrderButton: false });
            }
        }
    }


    viewDetailClicked = () => {
        window.scrollTo({ top: this.ref.current.clientHeight - this.detailRef.current.clientHeight, behavior: 'smooth' });
        this.toggleProfessionalOrderTour(true, Enumerations.tourGuide.poAfterClickingAddToCurrentList);
    }

    floatButtonClicked = () => window.scrollTo({ top: 100, behavior: 'smooth' });

    doRefreshSummaryDate = (refreshSummaryDate) => this.setState({ refreshSummaryDate });

    showCurrentListModalClicked = () => this.setState({ isShowCurrentListModal: true });

    closeCurrentListModalClicked = () => this.setState({ isShowCurrentListModal: false });

    closeAddTolistResultModal = () => {
        this.setState({ isShowAddToListResultModal: false });
        if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poAfterClickingAddToCurrentList))
            this.toggleProfessionalOrderTour(true, Enumerations.tourGuide.poAddToCurrentList);
        else
            this.setState({ showTour: false });

    }

    addToCurrentListClicked = () => {
        let messages = ProfessionalOrderHelper.isAllowAddToListButton(this.state.routeId);
        if (!isEmpty(messages))
            this.setState({ resultMessageClass: messageClass(messages) });
        else {
            const { routeId } = this.state;
            this.toggleProfessionalOrderTour();
            this.changeIsLoadingState(true);
            let files = OrderHelper.getFilesValue(routeId);
            let data = {
                orderId: this.state.orderId,
                productId: CommonHelper.toInt(OrderHelper.getId(routeId)),
                turnaroundType: OrderHelper.getTurnaround(routeId).key,
                twoSidePrintingType: OrderHelper.getPrintedSide(routeId).key,
                series: OrderHelper.getSeries(routeId),
                masterFiles: files.masterFiles,
                filmFiles: files.filmFiles,
                extendedServices: OrderHelper.getExtendedServicesValue(routeId),
                description: '',
            };
            AddToOrderListService(data, this.addToOrderListCallback);
        }
    }

    addToOrderListCallback = (resultData, result, messageClassModal) => {
        this.changeIsLoadingState(false);
        if (!result?.data?.hasError) {
            this.setState({ isShowAddToListResultModal: true });
            this.setOrderInfo(resultData);
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    closeMessageModal = (confirm, hasCancelButton) => {
        (confirm && hasCancelButton)
            ? this.submitOrder()
            : this.setState({ isShowMessageModal: false });
    }

    submitOrder = () => {
        this.setState({ isShowMessageModal: false });
        this.changeIsLoadingState(true);
        let orderId = OrderHelper.getOrderId(this.state.routeId);
        !!orderId && <>
            {SaveProfessionalOrderService({ orderId }, this.saveProfessionalOrderCallback)}
            {this.triggerGoogleTagManagerAddToCartEvent(orderId)}
        </>
    }

    triggerGoogleTagManagerAddToCartEvent = (id) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        if (!!orders) {
            if (OrderHelper.isValidOrder(id))
                orders = orders.filter(order => order.id !== id);
        }
        addToCartEvent(orders[0], Enumerations.googleTagManagerListId.professionalOrder)
    }

    submitOrderClicked = () => {
        if (isEmpty(OrderHelper.getFrontSideMasterFiles(this.state.routeId))) {
            this.submitOrder()
        } else {
            this.setState({
                messageItems: [{
                    messageText: getTranslatedRawText('professionalOrder.messages.masterFileIsNotEmpty'),
                    messageType: Enumerations.messageType.warning
                }],
                isShowMessageModal: true,
            });
        }
    }

    saveProfessionalOrderCallback = (resultData, result, messageClassModal) => {
        this.changeIsLoadingState(false);
        if (!result.data.hasError) {
            this.setState({ isShowSuccsessfulModal: true });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    refreshSummary = () => {
        this.setState({ pricesLoading: true });
        GetExtendedServicePriceService(this.state.routeId, this.getExtendedServicePriceCallback);
    }

    getExtendedServicePriceCallback = (resultData, result, messageClassModal) => {
        this.setState({ pricesLoading: false });
        if (!result.data.hasError) {
            this.setState({ totalExtendedServicePrice: resultData.totalPrice, refreshSummaryDate: new Date() });
            OrderHelper.setTotalExtendedServicePrice(this.state.routeId, resultData.totalPrice);
            !!resultData.totalPrice && this.toggleProfessionalOrderTour(true);
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    setOrderInfo = (orderInfo) => {
        const { routeId } = this.state;
        let id = OrderHelper.createRouteOrderId(OrderHelper.getCirculation(routeId)?.key);
        OrderHelper.createCopyOfOrderByNewId(routeId, id, orderInfo?.orderId, () => {
            OrderHelper.remove(routeId);
            this.setState({ routeId: id, orderInfo, orderId: OrderHelper.getOrderId(id), refreshOrderDetailDate: new Date() });
        });
        this.props.history.push(CommonHelper.stringFormat(
            getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.professionalOrder), id));
    }

    setGrandTotal = (grandTotal, isOrderAdded) => this.setState({ grandTotal, isOrderAdded });

    changeIsLoadingState = (state) => this.setState({ isLoading: state });

    closeSuccessfulModal = (isConfirmOrder) => {
        this.setState({ isShowSuccsessfulModal: false });
        !!isConfirmOrder
            ? this.props.history.push(
                getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder),
                { isExpressMode: true }
            )
            : this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.productDetails),
                CommonHelper.toInt(this.state.routeId)));
        OrderHelper.remove(this.state.routeId);
    }

    toggleProfessionalOrderTour = (isToogle, tourId) => {
        const { routeId, isOrderAdded } = this.state;
        if (!!tourId) {
            !CurrentUserHelper.isTourSeen(tourId) && CurrentUserHelper.addToSeenTours(tourId);
            this.setState({ showTour: false },
                () => !!isToogle ? ProfessionalOrderHelper.recognizeCurrentTour(routeId, !!this.props.currentFileContent, isOrderAdded, (showTour, tourSteps, tourId) =>
                    this.setState({ showTour, tourSteps, tourId }))
                    : this.setState({ showTour: false, tourSteps: [], tourId: null })
            );
        } else
            !!isToogle
                ? ProfessionalOrderHelper.recognizeCurrentTour(routeId, !!this.props.currentFileContent, isOrderAdded, (showTour, tourSteps, tourId) => {
                    this.setState({ showTour, tourSteps, tourId })
                })
                : this.setState({ showTour: false, tourSteps: [], tourId: null });
    }

    render() {
        if (!OrderHelper.isValidOrder(this.state.routeId)) {
            let routeValue = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
            let productId = routeValue.split('-');
            this.props.history.replace(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.productDetails), productId[0]));
            return null;
        }
        const { routeId, isOrderAdded, grandTotal, totalExtendedServicePrice, refreshSummaryDate, refreshOrderDetailDate, isShowAddToListResultModal,
            showSubmitOrderButton, messageItems, isShowMessageModal, isShowSuccsessfulModal, isLoading, isShowCurrentListModal, pricesLoading, tourId, tourSteps, showTour } = this.state;
        const isMobile = CommonHelper.isMobileDevice();

        return (
            <Row ref={this.ref} className='position-relative pb-5 pb-md-0'>
                <NsLoadingProgress visible={isLoading} />
                <Col xs={12}
                    className='mb-2'>
                    <OHeader routeId={routeId}
                        toggleProfessionalOrderTour={this.toggleProfessionalOrderTour}
                        isShowInProfessionalOrder={true} />
                </Col>
                <Col lg={9}>
                    <PoMainSection routeId={routeId}
                        refreshSummary={this.refreshSummary}
                        toggleProfessionalOrderTour={this.toggleProfessionalOrderTour} />
                </Col>
                <Col lg={3}
                    className='ps-lg-0 mt-lg-0 mt-2'>
                    <OSummary routeId={routeId}
                        isUsedInProfessional={true}
                        addToCurrentListClicked={this.addToCurrentListClicked}
                        refreshSummaryDate={refreshSummaryDate}
                        doRefreshSummaryDate={this.doRefreshSummaryDate}
                        pricesLoading={pricesLoading}
                        totalExtendedServicePrice={totalExtendedServicePrice} />
                </Col>

                {!isMobile &&
                    <Col xs={12} ref={this.detailRef} className='d-none d-md-block mt-3'>

                        <OBaseWrapper title='OrderList'>
                            <PoOrderDetail routeId={routeId}
                                refreshSummary={this.refreshSummary}
                                refreshOrderDetailDate={refreshOrderDetailDate}
                                setGrandTotal={this.setGrandTotal} />
                        </OBaseWrapper>
                    </Col>
                }

                <div className='sticky-content d-none d-lg-flex justify-content-end p-3  mt-4 gap-3 align-items-center rounded professionalOrder-tour-stickySubmit'>
                    <div className='mt-1'>
                        {getTranslatedRawText('regularOrder.confirmDetail.grandTotal')}:&nbsp;
                        <span className='fw-bold'>{CommonHelper.currencyFormatByDefaultConfig(grandTotal)}</span>
                    </div>
                    <NsButton label='regularOrder.confirmDetail.viewList'
                        size='medium'
                        wrapperClassName={showSubmitOrderButton ? 'd-none' : 'd-flex professionalOrder-tour-viewList'}
                        onClick={this.viewDetailClicked}
                        className='primary-outline' />
                    <NsButton label='regularOrder.confirmDetail.submitOrder'
                        wrapperClassName={showSubmitOrderButton ? 'd-flex' : 'd-none'}
                        className='primary'
                        size='medium'
                        disabled={!isOrderAdded}
                        onClick={this.submitOrderClicked} />
                </div>

                <div className='d-lg-none'>
                    <NsStickyButton stickyButtons={this.mainButtons} isLoading={isLoading} />
                </div>

                <NsFloatButton color='inherit'
                    elementType='button'
                    size='small'
                    className='float-button-position d-none d-lg-flex'
                    onClick={this.floatButtonClicked}>
                    <i className='ns-icon-scroll-up fs-4 mb-0 white-color' />
                </NsFloatButton>

                {isShowCurrentListModal &&
                    <CurrentListModal showModal={isShowCurrentListModal}
                        routeId={routeId}
                        disabled={!isOrderAdded}
                        setGrandTotal={this.setGrandTotal}
                        submitOrderClicked={this.submitOrderClicked}
                        closeModal={this.closeCurrentListModalClicked} />
                }

                {isShowAddToListResultModal &&
                    <AddToListResultModal closeModal={this.closeAddTolistResultModal} />
                }

                {isShowSuccsessfulModal &&
                    <PoModalSuccessful showModal={isShowSuccsessfulModal}
                        closeSuccessfulModal={this.closeSuccessfulModal} />
                }

                {this.state.resultMessageClass}

                {!CommonHelper.isMobileDevice() && showTour && !isLoading && <NsTour steps={tourSteps}
                    id={tourId}
                    closeTour={this.toggleProfessionalOrderTour} />
                }
                {isShowMessageModal &&
                    <NsMessageClassModal show={isShowMessageModal}
                        onClose={this.closeMessageModal}
                        messageItems={messageItems} />
                }

            </Row >
        );
    }
}

function CurrentListModal(props) {
    let mainButtons = [
        CommonHelper.createStickyButtonItem('regularOrder.confirmDetail.submitOrder', null, props.submitOrderClicked, 'primary', props.disabled),
        CommonHelper.createStickyButtonItem('regularOrder.confirmDetail.resumeOrdering', null, props.closeModal, 'primary-outline'),

    ];
    return <NsModal showModal={props.showModal}
        dialogTitle={getTranslatedRawText('regularOrder.confirmDetail.currentList')}
        closeModal={props.closeModal}
        actions={<NsStickyButton stickyButtons={mainButtons} />}>
        <PoOrderDetail routeId={props.routeId}
            setGrandTotal={props.setGrandTotal} />
    </NsModal >
}

export default ProfessionalOrder