import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import '../../../../assets/sass/views/orders/professionalOrder.scss';
import NsImageOrderMasterFileView from '../../../../components/images/NsImageOrderMasterFileView';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import Enumerations from '../../../../configs/Enumerations';
import ImageHelper from '../../../../utilities/ImageHelper';
import NsEtiquette from '../../../../components/etiquette/NsEtiquette';
import NsGroupNumberInput from '../../../../components/inputs/NsGroupNumberInput';
import NsToggleGroupButton from '../../../../components/buttons/NsToggleGroupButton';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import NsTypography from '../../../../components/text/NsTypography';

class FoldLinesContent extends Component {

    constructor(props) {
        super(props);
        this.foldLineShapes = ConstantsWithTranslation.foldLineShapes();
        this.foldLineDirections = ConstantsWithTranslation.foldLineDirections();
        this.state = {
            foldLines: [],
            foldLinesLength: 0,
            isFoldLineDirectionVertical: true,
            ratio: null,
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        const { resultData } = newProps;
        if (!isEmpty(resultData) && this.props.resultData !== resultData) {
            let foldLines = resultData.customFoldLineItems;
            !isEmpty(foldLines) && foldLines.map(foldLine => {
                foldLine.position = Math.round(ImageHelper.convertMillimeterToPixel(foldLine.distanceFromOrigin, this.props.resolution) * resultData.ratio);
            });
            this.setState({
                foldLines,
                isFoldLineDirectionVertical: resultData.isVertical,
                ratio: resultData.ratio
            });

        }
    }

    render() {
        
        const { foldLines, isFoldLineDirectionVertical } = this.state;
        const { draft, isLoading } = this.props;
        const direction = this.foldLineDirections.find(direction => direction.key === (this.state.isFoldLineDirectionVertical ? Enumerations.foldLineDirections.vertical : Enumerations.foldLineDirections.horizontal))?.value;

        return (
            <Row className=''>
                <Col xs={{ span: 12, order: 2 }} md={{ span: 7, order: 1 }} className='position-relative'>
                    <div className='fold-line-modal_img'>
                        <NsImageOrderMasterFileView imgSrc={draft}
                            wrapperClassName='position-relative'
                            setRatio={this.props.setRatio} >
                        </NsImageOrderMasterFileView>
                        <FoldLineDrawing isFoldLineDirectionVertical={isFoldLineDirectionVertical}
                            foldLines={foldLines}
                            foldLineShapes={this.foldLineShapes} />
                    </div>
                </Col>

                <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
                    <span className='mt-2'>
                        {getTranslatedRawText('profile.detail.direction')}:&nbsp;<b>{direction}</b>
                    </span>
                    <FoldLineList
                        foldLines={foldLines}
                        isLoading={isLoading}
                        foldLineShapes={this.foldLineShapes}
                        isFoldLineDirectionVertical={isFoldLineDirectionVertical} />
                </Col>
            </Row >
        )
    }
}

function FoldLineList(props) {
    const { foldLines, isFoldLineDirectionVertical, foldLineShapes, isLoading } = props;

    let style = 'background-outline-color';
    let border = 'border-outline-color-1px';
    let errorText = '';
    let groupButtonTitle = isFoldLineDirectionVertical ? 'offSetFromLeft' : 'offSetFromTop';
    const foldLineList = !isEmpty(foldLines)
        ? foldLines.map((foldLine, index) => {
            return (
                <NsEtiquette style={style}
                    border={border}
                    key={foldLine.id}
                    isLoading={isLoading}
                    index={index}
                    title={getTranslatedRawText('regularOrder.extendedService.foldLine')}
                    deleteEtiquette={props.deleteFoldLineClicked}>
                    <div className='d-flex justify-content-around align-items-end flex-wrap'>
                        <NsGroupNumberInput label={getTranslatedRawText(`regularOrder.extendedService.${groupButtonTitle}`)}
                            fieldClassName='bg-white'
                            value={foldLine.distanceFromOrigin}
                            step={1}
                            disabled={true}
                            unit={getTranslatedRawText('common.mm')}
                            addButtonDisabled={true}
                            minusButtonDisabled={true} />
                        <div className='group-button__convex'>
                            <NsToggleGroupButton isShowGroupLabel={false}
                                disabled={true}
                                selectedItemId={foldLine.isConvex ? Enumerations.foldLineShapes.convex : Enumerations.foldLineShapes.concave}
                                items={foldLineShapes} />
                        </div>
                    </div>
                </NsEtiquette>
            );
        })
        : <NsEtiquette style={style}
            border={border}
            errorText={errorText}
            index={0}
            isLoading={isLoading}
            title={getTranslatedRawText('regularOrder.extendedService.foldLine')}>
            <div className='d-flex  justify-content-center mx-1 my-2'>
                <NsTypography variant='inherit' className='font-size-14'>
                    {getTranslatedRawText('regularOrder.modal.noFoldLineSelected')}
                </NsTypography>
            </div>
        </NsEtiquette>;

    return foldLineList;
}

function FoldLineDrawing(props) {
    const {
        foldLines,
        foldLineShapes,
        isFoldLineDirectionVertical,
    } = props;
    let className = 'fold-line-modal_vertical-line';
    let direction = 'left';
    if (!isFoldLineDirectionVertical) {
        className = 'fold-line-modal_horizontal-line';
        direction = 'top';
    }
    const foldLineList = !isEmpty(foldLines) ? foldLines.map((foldLine, index) => {

        const foldLineText = foldLineShapes.find(foldLineShape => foldLineShape.key === (foldLine?.isConvex ? Enumerations.foldLineShapes.convex : Enumerations.foldLineShapes.concave))?.value;
        let foldLineNumberClassName = 'white-color background-black-color';

        return (
            <div className={className}
                key={foldLine.id}
                style={{ [direction]: `${foldLine.position}px` }} >
                <button
                    className={`fold-line-modal_button  px-2 ${foldLineNumberClassName} ${foldLine.isSelected && 'selected-fold-line'}`}>
                    <span className='font-size-18'>
                        {index + 1}
                    </span>
                    <span className='font-size-12'>
                        ({foldLineText})
                    </span>
                </button>
            </div>
        )
    })
        : [];

    return foldLineList;
}

export default FoldLinesContent;