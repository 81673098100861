import clsx from 'clsx';
import React, { Component } from 'react';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import NsHtmlToReact from '../../../components/htmlToReact/NsHtmlToReact';
import NsTypography from '../../../components/text/NsTypography';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsButton from '../../../components/buttons/NsButton';
import NsRating from '../../../components/ratings/NsRating';
import CommonHelper from '../../../utilities/CommonHelper';
import Enumerations from '../../../configs/Enumerations';
import DateHelper from '../../../utilities/DateHelper';
import NsTags from '../../../components/tags/NsTags';
import UrlHelper from '../../../utilities/UrlHelper';
import Links from '../../../configs/links/AllLinks';

export default class PdBaseExplanation extends Component {

    constructor(props) {
        super(props);
        this.setLinkOfStaticPagesInContent = [
            `<a className='fw-normal text-primary' target='_blank' href=${getUrlWithCurrentCulture(Links, Links.termsOfUse)}><u>${getTranslatedRawText('footer.termOfUse')}</u> </a>`,
            `<a className='fw-normal text-primary' target='_blank' href=${getUrlWithCurrentCulture(Links, Links.privacyPolicy)}><u>${getTranslatedRawText('footer.privacyPolicy')}</u> </a>`,
            `<a className='fw-normal text-primary' target='_blank' href=${getUrlWithCurrentCulture(Links, Links.warrantyTerms)}><u>${getTranslatedRawText('footer.warrantyTerms')}</u> </a>`,
            `<a className='fw-normal text-primary' target='_blank' href=${getUrlWithCurrentCulture(Links, Links.frequentlyAskedQuestions)}><u>${getTranslatedRawText('singlePage.faqs.frequentlyAskedQuestions')}</u> </a>`,
            `<a className='fw-normal text-primary' target='_blank' href=${getUrlWithCurrentCulture(Links, Links.contactUs)}><u>${getTranslatedRawText('footer.contactUs')}</u> </a>`
        ];
        this.state = {
            isExpanded: false,
            progressTimesContent: []
        }
    }

    componentDidMount() {
        this.setState({ progressTimesContent: this.mapProgressTimes(this.props.progressTimes) });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.progressTimes?.length !== this.props.progressTimes?.length)
            this.setState({ progressTimesContent: this.mapProgressTimes(this.props.progressTimes) });
    }

    showMoreChanged = () => this.setState({ isExpanded: !this.state.isExpanded })

    loginClicked = () => CommonHelper.safeFunctionCall(this.props.loginClickedByExplanation, getTranslatedRawText('common.messages.messageBeforeSignIn'));

    renderProgress = (text, processTime) => {
        return CommonHelper.stringFormat(getTranslatedRawText(text), DateHelper.calcProcessHoursAndDays(processTime?.processHours));
    }

    mapProgressTimes = (progressTimes) => {
        if (isEmpty(progressTimes)) return [];
        let progressTimesContent = [];
        progressTimes.map((turn, index) => {
            if (turn.key === Enumerations.turnaround.normal)
                turn.printingSideProductFeature.map(side =>
                    side.key === Enumerations.printedSide.oneSide
                        ? progressTimesContent.push(this.renderProgress('product.detail.explanationNormalFrontProgressTime', side))
                        : progressTimesContent.push(this.renderProgress('product.detail.explanationNormalFrontBackProgressTime', side))
                )
            if (turn.key === Enumerations.turnaround.express)
                turn.printingSideProductFeature.map(side =>
                    side.key === Enumerations.printedSide.twoSide
                        ? progressTimesContent.push(this.renderProgress('product.detail.explanationExpressFrontBackProgressTime', side))
                        : progressTimesContent.push(this.renderProgress('product.detail.explanationExpressFrontProgressTime', side))
                )
        })
        return progressTimesContent.map((item, index) => <div className='mb-2' key={index}>{item}</div>);
    }
    guidancesUrlsString = (guidancesUrlsList) => {
        const guidancesUrls = guidancesUrlsList.map((item, index) =>
            (`<a target='_blank' href=${CommonHelper.createImageUrl(item)}>${CommonHelper.stringFormat(getTranslatedRawText("common.label.downloadGuide"), index + 1)}</a>`)
        )
        return `${guidancesUrls}`;
    }

    render() {
        const { series, selectedCirculation, product, selectedTurnaround, selectedPrintedSide, processTimeBasedSelectedOptions,
            userFullName, guidancesUrls, sizeAndPrices, isCustomizableDimension, progressTimes, otherTabs, selectedTab
        } = this.props;
        const { isExpanded } = this.state;
        const category = !isEmpty(product?.categories) ? product?.categories[0] : '';
        return (
            <div className={clsx('font-size-14 p-2', selectedTab?.label !== getTranslatedRawText('product.detail.explanation') && 'd-none')}>
                <div>
                    <NsTypography variant='inherit' className='page-title mb-2 text'>
                        {product?.pprContent?.extendedName}
                    </NsTypography>
                    <NsTypography variant='inherit' className='mb-2'>
                        {CommonHelper.stringFormat(getTranslatedRawText('product.detail.explanationViewPart1'), series, selectedCirculation?.value,
                            series * selectedCirculation?.value, product?.name, selectedTurnaround?.value, selectedPrintedSide?.value)}
                    </NsTypography>
                    <NsTypography variant='inherit' className='mb-2'>
                        {CommonHelper.stringFormat(getTranslatedRawText('product.detail.explanationViewPart2'),
                            category?.name, processTimeBasedSelectedOptions)}
                        {!!userFullName &&
                            <a target="_blank" href={getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequest)}>
                                ({getTranslatedRawText('profile.actionButton.deliveryRequest')})
                            </a>
                        }
                    </NsTypography>
                    <div className='mb-2'>
                        {otherTabs}
                    </div>
                </div>
                <div className={clsx('text-justify', isExpanded ? 'd-block' : 'd-none')} >
                    {!isEmpty(guidancesUrls) &&
                        <NsTypography variant='inherit' className='mb-2'>
                            <NsHtmlToReact>
                                {CommonHelper.stringFormat(getTranslatedRawText("product.detail.explanationGuidances"),
                                    this.guidancesUrlsString(guidancesUrls))}
                            </NsHtmlToReact>
                        </NsTypography>
                    }
                    <NsTypography variant='inherit'>
                        {getTranslatedRawText('product.detail.explanationNotViewPart1')}
                        {getTranslatedRawText('product.detail.explanationNotViewPart2')}
                    </NsTypography>
                    <NsTypography variant='inherit' className="fw-bold mt-2">
                        {getTranslatedRawText('product.detail.explanationAvailableDimensionTitle')}
                    </NsTypography>
                    {sizeAndPrices?.length > 0 && sizeAndPrices.map((item, index) =>
                        <NsTypography key={index} variant='inherit' className='ms-2'
                            onClick={!!userFullName ? () => { } : this.loginClicked}>
                            <NsHtmlToReact>
                                {CommonHelper.stringFormat(getTranslatedRawText(!!item.isAvailable ? 'product.detail.explanationAvailableText' : 'product.detail.explanationUnavailableText'),
                                    item.name,
                                    !!userFullName
                                        ? item.basePrice
                                        : `<button className='border-0' onclick="console.log('click')">${getTranslatedRawText('product.detail.showPrice')}</button>`,
                                    item.width, item.height)
                                }
                            </NsHtmlToReact>
                        </NsTypography>
                    )}

                    {isCustomizableDimension &&
                        <NsTypography variant='inherit' className='mt-2'>
                            {getTranslatedRawText('product.detail.explanationCustomSizeText')}
                        </NsTypography>
                    }

                    <NsTypography variant='inherit' className='mt-2'>
                        {product?.printCirculations?.length > 1 &&
                            CommonHelper.stringFormat(getTranslatedRawText('product.detail.explanationSelectableCirculation'), product.printCirculations.map((item, index) => `${item.value}${index !== product.printCirculations.length - 1 ? ' ' : ''}`))
                        }
                        {progressTimes?.length > 1 && getTranslatedRawText('product.detail.explanationSelectableTurnaround')}
                        {!isEmpty(progressTimes) && progressTimes[0]?.printingSideProductFeature?.length > 1 && getTranslatedRawText('product.detail.explanationSelectablePrintedSide')}
                    </NsTypography>

                    {!isEmpty(this.state.progressTimesContent) &&
                        <NsTypography variant='inherit' className='mt-2'>
                            {this.state.progressTimesContent}
                        </NsTypography>
                    }

                    <NsHtmlToReact>
                        {CommonHelper.stringFormat(getTranslatedRawText('product.detail.explanationTerms'),
                            this.setLinkOfStaticPagesInContent[0], this.setLinkOfStaticPagesInContent[1], this.setLinkOfStaticPagesInContent[2], this.setLinkOfStaticPagesInContent[3], this.setLinkOfStaticPagesInContent[4])
                        }
                    </NsHtmlToReact>
                    <NsTypography variant='inherit' className='my-2'>
                        <NsHtmlToReact>
                            {CommonHelper.stringFormat(getTranslatedRawText('product.detail.explanationExploreCategory'),
                                category?.name,
                                `<a className='fw-normal text-primary' href=${UrlHelper.createCorrectRouteAndUrl(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main, Links.view.main.productsByCategory), category?.id, category?.name))}><u>${category.name}</u> </a>`)
                            }
                        </NsHtmlToReact>
                    </NsTypography>
                    {/* <NsTypography variant='inherit'>
                        {getTranslatedRawText('product.detail.explanationIntroduction')}
                    </NsTypography> */}
                    <NsRating key={product?.pprContent?.ratingValue} className='mt-3 mb-1'
                        readOnly={true}
                        precision={0.1}
                        value={product?.pprContent?.ratingValue} />
                    {!!product?.pprContent?.tags &&
                        <NsTags tags={product?.pprContent?.tags.split(/[-,]/)}
                            linkToMaster={Links.view.main}
                            linkToSlave={Links.view.main.allProducts} />
                    }
                </div>
                <div className="d-flex">
                    <NsButton label={isExpanded ? 'common.showLess' : 'common.showMore'}
                        className='secondary-text'
                        onClick={this.showMoreChanged} />
                </div>
            </div>)
    }
}
