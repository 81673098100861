import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NsTreeView from '../../../components/treeViews/NsTreeView';
import { fileManagerActionCreators } from '../../../redux/reducers/FileManagerReducer';
import { findNodeById, getPathListById } from '../bases/TraceTreeView';

class FmSidebarFolderTreeView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expandedFolderIds: getPathListById(props.currentFolderId, props.treeViewFolders),
            currentFolderId: props.currentFolderId,
            treeViewFolders: props.treeViewFolders || [],
            prevFolderId: null
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let expandedFolderIds = [...this.state.expandedFolderIds];
        if (this.state.currentFolderId !== newProps.currentFolderId) {
            expandedFolderIds = getPathListById(newProps.currentFolderId, this.state.treeViewFolders);
        }
        this.setState({
            expandedFolderIds: expandedFolderIds,
            currentFolderId: newProps.currentFolderId,
            treeViewFolders: newProps.treeViewFolders,
        });
        if (this.props.isUploadTreeView && this.state.currentFolderId !== newProps.currentUploadFolderId) {
            expandedFolderIds = getPathListById(newProps.currentUploadFolderId, this.state.treeViewFolders);
            this.setState({
                expandedFolderIds: expandedFolderIds,
                currentFolderId: newProps.currentUploadFolderId,
                treeViewFolders: newProps.treeViewFolders,
            });
        }
    }

    clickTreeItem = (id, toggle = false, hasChildren = true) => {
        this.setState({ prevFolderId: id })
        if (this.state.prevFolderId === id && hasChildren)
            toggle = true

        if (toggle && !hasChildren) return;
        let clickedFolderId = id;
        const expandedFolderIdsLength = this.state.expandedFolderIds.length;
        if (toggle) {
            if (expandedFolderIdsLength > 1 && this.state.expandedFolderIds[expandedFolderIdsLength - 1] === id) {
                clickedFolderId = this.state.expandedFolderIds[expandedFolderIdsLength - 2];
            }
        }

        const selectedTreeItem = findNodeById(clickedFolderId, this.state.treeViewFolders, null);

        if (toggle) {
            let expandedFolderIds = (selectedTreeItem) ? selectedTreeItem.path || [] : [];
            this.setState({
                expandedFolderIds,
            });
        } else {
            if (!this.props.isUploadTreeView)
                this.props.setCurrentFolderId(clickedFolderId);
            else
                this.props.setCurrentUploadFolderId(clickedFolderId);
        }
    }
    render() {
        const { expandedFolderIds, currentFolderId, treeViewFolders } = this.state;

        return (
            <NsTreeView treeViewFolders={treeViewFolders}
                expandedFolderIds={expandedFolderIds}
                currentFolderId={currentFolderId}
                treeViewClassName='file-manager__tree-view'
                openFolderIcon='ns-icon-open-folder h4'
                closeFolderIcon='ns-icon-folder h4'
                clickTreeItem={this.clickTreeItem} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentFolderId: state.fileManager.currentFolderId,
        currentUploadFolderId: state.fileManager.currentUploadFolderId,
    }
}

const mapDispatchToPops = (dispatch) => {
    return bindActionCreators(fileManagerActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToPops)(FmSidebarFolderTreeView);