import React, { useEffect, useState } from 'react'
import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch'
import NsButton from '../../../../components/buttons/NsButton';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../configs/Enumerations';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../bases/ApBaseSearchButtons'
import ApBaseUserSelectModal from '../../bases/ApBaseUserSelectModal';
import ApBaseOrderSelectModal from '../../bases/ApBaseOrderSelectModal';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { isEmpty } from '../../../../utilities/ValidationHelper';

const ApCustomersFilesSearch = (props) => {

    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUserInfo, setSelectedUserInfo] = useState('');
    const [selectedOrder, setSelectedOrder] = useState('');
    const [selectedOrderInfo, setSelectedOrderInfo] = useState('');
    const [usingInAOrder, setUsingInAOrder] = useState('');
    const [isShowOrderSelectModal, setIsShowOrderSelectModal] = useState(false);
    const [isShowUserSelectModal, setIsShowUserSelectModal] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [fileSizeTo, setFileSizeTo] = useState()
    const [fileSizeFrom, setFileSizeFrom] = useState()
    const [createTimeFrom, setCreateTimeFrom] = useState(null)
    const [createTimeTo, setCreateTimeTo] = useState(null)

    useEffect(() => {
        !isEmpty(selectedOrder) && searchClicked();
    }, [selectedOrder])

    useEffect(() => {
        !isEmpty(selectedUser) && searchClicked();
    }, [selectedUser])

    const showOrderSelectModalToggle = () => setIsShowOrderSelectModal(!isShowOrderSelectModal);

    const showUserSelectModalToggle = () => setIsShowUserSelectModal(!isShowUserSelectModal);

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptionsInMobile = [
        hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'selectedUser',
            value: selectedUser,
            disabled: true,
            endAdornment: <NsButton className='light-circle'
                onClick={showUserSelectModalToggle}
                startIcon="ns-icon-more" />
        },
    ];

    const basicSearchOptions = [
        hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'selectedUser',
            value: selectedUser,
            disabled: true,
            endAdornment: <NsButton className='light-circle'
                onClick={showUserSelectModalToggle}
                startIcon="ns-icon-more" />
        },
        hasAccess([UserClaimEnumerations.getApOrders]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.order'),
            fieldName: 'selectedOrder',
            value: selectedOrder,
            disabled: true,
            endAdornment: <NsButton className='light-circle'
                onClick={showOrderSelectModalToggle}
                startIcon="ns-icon-more" />
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.dataGridColumn.usingInAOrder'),
            fieldName: 'usingInAOrder',
            value: usingInAOrder,
            items: ConstantsWithTranslation.getUseFileInOrderOptions(),
            onChange: (value) => setUsingInAOrder(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('adminPanel.customersFiles.fileSizeFrom'),
            fieldName: 'fileSizeFrom',
            value: fileSizeFrom,
            onChange: (value) => setFileSizeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('adminPanel.customersFiles.fileSizeTo'),
            fieldName: 'fileSizeTo',
            value: fileSizeTo,
            onChange: (value) => setFileSizeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.customersFiles.createTimeFrom'),
            fieldName: 'createTimeFrom',
            value: createTimeFrom,
            onChange: (value) => setCreateTimeFrom(value)
        },
    ]

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.customersFiles.createTimeTo'),
            fieldName: 'createTimeTo',
            value: createTimeTo,
            onChange: (value) => setCreateTimeTo(value)
        }
    ];

    const searchClicked = () => {
        let data = {
            selectedUserInfo,
            selectedOrderInfo,
            usingInAOrder,
            fileSizeFrom,
            fileSizeTo,
            createTimeFrom,
            createTimeTo,
        }
        CommonHelper.safeFunctionCall(props.searchClicked, data)
    }

    const clearButtonClicked = () => {
        setSelectedUser('');
        setSelectedUserInfo('');
        setSelectedOrder('');
        setSelectedOrderInfo('');
        setUsingInAOrder('');
        setFileSizeFrom(null);
        setFileSizeTo(null);
        setCreateTimeFrom(null);
        setCreateTimeTo(null);
        CommonHelper.safeFunctionCall(props.searchClicked, {})
    }

    const searchValues = {
        selectedUser,
        selectedUserInfo,
        selectedOrder,
        selectedOrderInfo,
        usingInAOrder,
        fileSizeFrom,
        fileSizeTo,
        createTimeFrom,
        createTimeTo,
    }

    const getSelectedUser = (user) => {
        setSelectedUserInfo(user);
        setSelectedUser(user?.phoneNumber);
        setIsShowUserSelectModal(false);
    }

    const getSelectedOrder = (order) => {
        setSelectedOrderInfo(order);
        setSelectedOrder(order.productName);
        setIsShowOrderSelectModal(false);
    }

    const showMoreHandler = () => setShowMore(!showMore);

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={showMore
                    ? advanceSearchOptions
                    : CommonHelper.isMobileDevice() ? basicSearchOptionsInMobile : basicSearchOptions}
                    searchValues={searchValues} mdCol={2} smCol={4} />

                <ApBaseSearchButtons searchClicked={searchClicked}
                    showMoreHandler={showMoreHandler}
                    showMore={showMore}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={props.isLoading} />
            </div>

            {isShowUserSelectModal &&
                <ApBaseUserSelectModal showModal={isShowUserSelectModal}
                    userSelected={getSelectedUser}
                    closeModal={() => showUserSelectModalToggle()} />
            }

            {isShowOrderSelectModal &&
                <ApBaseOrderSelectModal showModal={isShowOrderSelectModal}
                    orderSelected={getSelectedOrder}
                    closeModal={() => showOrderSelectModalToggle()} />
            }
        </>
    )
}

export default React.memo(ApCustomersFilesSearch);