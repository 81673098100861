import clsx from "clsx";
import React from "react";

import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import NsLabelNoData from "../../../components/labels/NsLabelNoData";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import Enumerations from "../../../configs/Enumerations";
import NsCard from "../../../components/cards/NsCard";
import NsTab from "../../../components/tabs/NsTab";

class PdsDescription extends React.Component {

    constructor(props) {
        super(props);
        this.tabs = ConstantsWithTranslation.getStoreDetailTabs();
        this.state = {
            selectedTabIndex: 0
        }
    }

    tabChanged = (selectedTabIndex, selectedTab) => this.setState({ selectedTabIndex });

    selectedTabData = () => {
        switch (this.state.selectedTabIndex) {
            case 0: return <p>{this.props.description || <NsLabelNoData label='home.bannerSection.noAnyDataToView' />}</p>
            case 1: return <p>{this.props.overview || <NsLabelNoData label='home.bannerSection.noAnyDataToView' />}</p>
            case 2: return <p>{this.props.children || <NsLabelNoData label='home.bannerSection.noAnyDataToView' />}</p>
            default: null
        }
    }

    render() {
        const { selectedTabIndex } = this.state;

        return <NsCard className='mb-3'>
            <NsTab value={selectedTabIndex}
                tabs={this.tabs}
                onChange={this.tabChanged} />
            <div className={clsx("p-3 min-vh-40", true ? 'max-vh-40' : '')}>
                {this.props.isLoading
                    ? <NsSkeleton isVisible={true}
                        className='my-2 w-100'
                        variant={[Enumerations.skeleton.text, Enumerations.skeleton.rect]} />
                    : this.selectedTabData()
                }
            </div>
        </NsCard>
    }
}

export default PdsDescription;