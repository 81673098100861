import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetCrmAnnouncementContentFeedbackService, AddCrmAnnouncementContentFeedbackService, GetCrmOrderFeedbackService, AddCrmOrderFeedbackService, GetCrmUserFeedbackService, AddCrmUserFeedbackService } from '../../../services/singlePages/CrmService';
import ApBaseFeedbackMetrics from '../../adminPanel/feedbacks/bases/ApBaseFeedbackMetrics';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsSkeleton from '../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../components/text/NsTypography';
import NsTextField from '../../../components/inputs/NsTextField';
import { isEmpty } from '../../../utilities/ValidationHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import Enumerations from '../../../configs/Enumerations';
import DateHelper from '../../../utilities/DateHelper';
import EnumerationTranslationHelper from '../../../utilities/EnumerationTranslationHelper';
import NsBasicInfoLabelBox from '../../../components/labels/NsBasicInfoLabelBox';
import { getFeedbackBasicInfos } from '../../../utilities/BasicInfoHelper';

class FeedbackModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            feedback: null,
            comment: null,
            getParameters: null,
            addParameters: null,
            getFeedbackservices: null,
            addFeedbackService: null,
            selectedDemandService: null,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        this.setState({ isLoading: true });
        this.getFeedbackservices(this.props.feedbackType, () => {
            this.state.getFeedbackservices(this.state.getParameters,
                this.getFeedbackCallback);
        });
    }

    getFeedbackservices = (feedbackType, callback) => {
        switch (feedbackType) {
            case Enumerations.feedbackTypes.announcementContent:
                this.setState({
                    getParameters: { id: this.props.id },
                    addParameters: { feedbackToAnnouncementContentId: this.props.id },
                    getFeedbackservices: GetCrmAnnouncementContentFeedbackService,
                    addFeedbackService: AddCrmAnnouncementContentFeedbackService
                }, callback);
                break;
            case Enumerations.feedbackTypes.sharedSheetOrder:
                this.setState({
                    getParameters: { id: this.props.id },
                    addParameters: { feedbackToSharedSheetOrderId: this.props.id },
                    getFeedbackservices: GetCrmOrderFeedbackService,
                    addFeedbackService: AddCrmOrderFeedbackService
                }, callback);
                break;
            case Enumerations.feedbackTypes.storeProductOrder:
                this.setState({
                    getParameters: { id: this.props.id },
                    addParameters: { feedbackToStoreProductOrderId: this.props.id },
                    getFeedbackservices: null,
                    addFeedbackService: null
                }, callback);
                break;
            case Enumerations.feedbackTypes.department:
                this.setState({
                    getParameters: { id: this.props.id },
                    addParameters: { feedbackToDepartmentId: this.props.id },
                    getFeedbackservices: null,
                    addFeedbackService: null
                }, callback);
                break;
            case Enumerations.feedbackTypes.user:
                const feedbackToUserPublicProfileId = !isEmpty(this.props?.id) ? Number(this.props.id) : null;
                this.setState({
                    getParameters: { publicProfileId: feedbackToUserPublicProfileId },
                    addParameters: { feedbackToUserPublicProfileId, publicProfileId: feedbackToUserPublicProfileId },
                    getFeedbackservices: GetCrmUserFeedbackService,
                    addFeedbackService: AddCrmUserFeedbackService
                }, callback);
                break;
            default: break;
        }
    }

    getFeedbackCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.setState({ feedback: resultData, selectedDemandService: isEmpty(resultData?.demandedServices) ? null : resultData?.demandedServices.find(item => item.key === resultData.demandedServiceTypeId) });
        }
        else this.setState({ resultMessageClass });
    }

    saveButtonClicked = () => {
        const { feedback, addParameters, addFeedbackService } = this.state;
        this.setState({ isLoading: true });
        let data = {
            ...addParameters,
            comment: feedback.comment,
            feedbackType: this.props.feedbackType,
            demandedServiceTypeId: feedback.demandedServiceTypeId,
            rates: feedback?.rates,
            tags: feedback?.tags
        }

        !isEmpty(addFeedbackService) && addFeedbackService(data, this.addFeedbackCallback, true);
    }

    addFeedbackCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.closeModalClicked(resultMessageClass);
        } else this.setState({ resultMessageClass });
    }

    closeModalClicked = (messageClassModal) => CommonHelper.safeFunctionCall(this.props.closeModal, messageClassModal, true);

    ratingChanged = (rates) => this.setState(oldState => ({ feedback: { ...oldState.feedback, rates } }));

    tagsChanged = (tags) => this.setState(oldState => ({ feedback: { ...oldState.feedback, tags } }));

    commentChanged = (comment) => this.setState(oldState => ({ feedback: { ...oldState.feedback, comment } }));

    demandServiceChanged = (selectedDemandService) => this.setState(oldState => ({ feedback: { ...oldState.feedback, demandedServiceTypeId: selectedDemandService?.key }, selectedDemandService }));

    mapActivityHistory = (history) => {
        return history.map((item, index) =>
            <div key={index} className='border rounded-3 p-2 font-size-14 background-white-color my-2 mx-3' >
                <div className='font-size-12 fw-bold mb-1'>{EnumerationTranslationHelper.getTranslategetFeedbackState(item?.userFeedbackStateType)}</div>

                <span className='light-text-color d-block'>{CommonHelper.replaceSlashNToLinebreaks(item?.comment)}</span>
                <div className='d-flex justify-content-between mt-2'>
                    <div className='d-flex align-items-center'>
                        {item?.isPublic &&
                            <>
                                <i className='ns-icon-view font-size-16 light-text-color me-1'></i>
                                <span className='font-size-12 light-text-color'>{getTranslatedRawText('profile.common.visibleToUser')}</span>
                            </>}
                    </div>
                    <div className='d-flex justify-content-end align-items-center gap-2 font-size-12'>
                        <span className='secondary-text d-block'>{item?.fullName}</span>
                        <span>{DateHelper.getDateAndTime(item?.submitDate)}</span>
                    </div>
                </div>
            </div >)
    }

    render() {
        const { resultMessageClass, feedback, isLoading, selectedDemandService } = this.state;
        const { feedbackType, usedInProfileSetting } = this.props;
        const isOrder = feedbackType == Enumerations.feedbackTypes.sharedSheetOrder;
        const stickyButtons = [
            { label: "common.submit", icon: 'ns-icon-confirm', onClicked: this.saveButtonClicked, className: 'primary', disabled: isLoading },
        ]
        const feedbackHistory = feedback?.states?.some(item => item?.isPublic) && feedback?.states?.map(item => item?.isPublic ? item : null);

        return (
            <NsModal maxWidth={feedbackHistory ? "md" : "xs"}
                dialogTitle={getTranslatedRawText("adminPanel.feedback.feedback")}
                showModal={true}
                dialogClassName='feedback-modal'
                closeModal={this.closeModalClicked}
                actions={<NsStickyButton stickyButtons={stickyButtons} isLoading={isLoading} />}>
                <div className='px-2'>
                    <NsBasicInfoLabelBox className='background-color min-size' basicInfo={getFeedbackBasicInfos(feedback)} isLoading={isLoading} hasAccessInfo={true} />
                </div>
                <Row className='g-3'>
                    <Col md={feedbackHistory ? 7 : 12} className='p-3'>
                        <div className='d-flex flex-column gap-2 font-size-14'>
                            <NsSkeleton isVisible={isLoading}
                                variant={[Enumerations.skeleton.text, Enumerations.skeleton.text]} />
                            {isOrder &&
                                <span className='text-justify'>
                                    {CommonHelper.stringFormatWithBold(getTranslatedRawText('profile.feedback.feedbackModalOrderMessage'), getTranslatedRawText('profile.feedback.feedbackModalOrderBoldPartMessage'))}
                                </span>
                            }
                            <ApBaseFeedbackMetrics metricTypeId={Enumerations.feedbackMetricTypes.ratingparamenter}
                                items={feedback?.rates}
                                onChange={this.ratingChanged}
                            />
                            <ApBaseFeedbackMetrics metricTypeId={Enumerations.feedbackMetricTypes.strengths}
                                items={feedback?.tags}
                                onChange={this.tagsChanged}
                                className='mt-3 mb-2' />
                            <NsTextField className='border rounded-3 p-2 announce-rating'
                                multiline
                                inputProps={{ maxLength: 500 }}
                                InputProps={{ disableUnderline: true }}
                                placeholder={getTranslatedRawText('profile.feedback.comment')}
                                valueChanged={this.commentChanged}
                                value={feedback?.comment} />
                            <NsTypography variant='inherit'>
                                {CommonHelper.stringFormat(getTranslatedRawText('profile.feedback.characterLimit'), this.state.feedback?.comment?.length || 0)}
                            </NsTypography>
                            <NsSkeleton isVisible={isLoading}
                                variant={[Enumerations.skeleton.text, Enumerations.skeleton.text]} />
                            <div className='d-flex w-50'>
                                <ApBaseFeedbackMetrics metricTypeId={Enumerations.feedbackMetricTypes.demandService}
                                    items={feedback?.demandedServices}
                                    selectedDemandService={selectedDemandService}
                                    onChange={this.demandServiceChanged} />
                            </div>
                        </div>
                    </Col>
                    {feedbackHistory &&
                        <Col md={5} className='p-3'>
                            <div className='background-color py-2 h-100'>
                                <NsTypography variant='inherit' className='px-3 py-2 text-color fw-bold'>{getTranslatedRawText('adminPanel.audit.history')}</NsTypography>
                                {this.mapActivityHistory(feedbackHistory)}
                            </div>
                        </Col>}
                </Row>
                {resultMessageClass}
            </NsModal >
        );
    }
};

export default FeedbackModal;