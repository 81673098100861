import React from 'react';
import { withRouter } from 'react-router';
import { isEmpty } from '../../utilities/ValidationHelper';
import UrlHelper from '../../utilities/UrlHelper';



class NsPageTitleBox extends React.Component {

    mapUrlParameters = (urlParameters) => {
        delete urlParameters.createdBy;
        let values = Object.values(urlParameters);
        return values.map((item, index) => `${item}${index !== values.length - 1 ? '/' : ''}`)
    }

    render() {
        const { title, icon } = this.props;
        const urlParameters = UrlHelper.getRouteParameters(this.props.location.search);

        return <div className='d-flex align-items-center justify-content-between border rounded-3 background-element-color px-2 mb-2'>
            <div className='d-flex align-items-center'>
                <i className={icon} />&nbsp;
                <span className='section-title mb-0'>
                    <h1 className='font-size-16 fw-bold mb-0'>{title}</h1>
                </span>
            </div>
            <div className=''>
                {!isEmpty(urlParameters)
                    ? this.mapUrlParameters(urlParameters)
                    : ''}
            </div>
        </div>
    }
}

export default withRouter(NsPageTitleBox);