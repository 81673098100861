import React from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import NsAddToHomeScreen from '../../../components/addToHomeScreen/NsAddToHomeScreen';
import NsChat from '../../../components/chats/NsChat';
import NsResetTour from '../../../components/resetTour/NsResetTour';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import AgencyAlert from '../../profileSettings/modals/AgencyAlert';
import MainBreadcrumbs from './breadcrumbs/MainBreadcrumbs';

class MainLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            refreshDate: null,
            showAgencyAlert: CurrentUserHelper.getCustomerMustHaveAgency() && !CurrentUserHelper.getCustomerAgencyId(),
        }
    }

    agencyAlertClosed = () => this.setState({ showAgencyAlert: false });

    render() {
        return (
            <div key={this.state.refreshDate} className='background-color' aria-label='promotions'>
                <NsAddToHomeScreen />
                <NsChat isSetUserInChatService={this.props.isSetUserInChatService} />
                <Container className='my-2 mx-auto'>
                    <div className="d-none d-lg-flex">
                        <MainBreadcrumbs
                            breadCrumb={this.props.breadCrumb}
                            title={this.props.title} />

                        {!!this.props.showResetTour &&
                            <NsResetTour refreshDate={(refreshDate) => this.setState({ refreshDate })}
                                breadCrumb={this.props.breadCrumb} />}
                    </div>
                    <div>
                        {this.props.children}
                    </div>

                    <AgencyAlert open={this.state.showAgencyAlert}
                        handleClose={this.agencyAlertClosed} />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userFullName: state.currentUser.userFullName,
        isSetUserInChatService: state.currentUser.isSetUserInChatService
    };
}

export default connect(mapStateToProps)(MainLayout);