import { Search } from "@mui/icons-material";

const HeadersTranslationFa = {

	headers: {
		common: {
			categories: {
				businessCards: 'کارت‌های تجاری',
				premiumBusinessCards: 'کارت‌های تجاری ویژه',
				officialAndCommercialPapers: 'برگه‌های اداری و تجاری',
				brochures: 'دفترچه راهنما',
				flyers: 'آگهی',
				envelopes: 'پوشش',
				shoppingBags: 'سبد خرید',
				fastFoodBoxes: 'جعبه فست فود',
				searchCategories: 'جستجو دسته‌بندی‌ها',
				Confirm: 'تایید',
				undo: 'بازگشت'
			},
			headerStoreProduct: 'محصول‌های آماده',
			storeProduct: 'محصول‌های فروشگاهی',
			sharedSheetProduct: 'محصول‌های فرم های عمومی',
		},

		top: {
			account: 'حساب کاربری',
			welcomeTo: "خوش آمدید به ",
			enjoyDifferentWay: '، از سفارش آنلاین خود لذت ببرید.',
			welcome: 'خوش آمدید'
		},

		middle: {
			hello: 'سلام',
			allCategories: 'همه دسته‌بندی‌ها',
			search: 'جستجو',
			searchProductName: 'جستجو نام محصول',
			myAccount: 'حساب کاربری',
			myCart: 'سبد خرید',
			signIn: 'ورود',
			createAccount: 'ایجاد حساب کاربری',
			searchProduct: 'جستجوی محصول...'
		},

		bottom: {
			allProductsCategories: 'دسته‌بندی محصول‌ها',
			home: 'صفحه اصلی',
			shop: 'فرم های عمومی',
			paperBag: 'ساک خرید',
			iOrderFrequently: 'ثبت سفارش حرفه‌‌ای',
			fileManager: 'مدیریت فایل',
			deliveryRequest: 'مدیریت ارسال‌ها',
			blog: 'اخبار',
			aboutUs: 'درباره ما',
			contactUs: 'تماس با ما',
			callUs: 'تماس با ما:',
			phoneNumber: '(+800) 456 789',
			categories: 'دسته‌بندی‌ها',
			festivals: 'جشنواره‌ها',
			admin: 'پنل مدیریت'
		},

		modals: {
			order: 'سفارش',
			turnaround: 'سرعت چاپ',
			normal: 'معمولی',
			express: 'فوری',
			printedSide: 'یکرو یا دورو',
			frontOnly: 'یکرو',
			frontAndBack: 'دو رو',
			quantity: 'تعداد',
			submitDate: 'تاریخ ثبت',
			circulation: 'تیراژ',
			totalPrice: 'مبلغ کل',
			payment: 'پرداخت',
			moreDetail: 'جزییات بیشتر',
			ordersEmptyMessage: 'هیچ سفارشی وجود ندارد',
			showCart: 'نمایش سبد خرید',
			totalPriceWithVat: 'مبلغ کل: ',
			confirmOrder: 'تایید سفارش‌ها',
			confirmShareSheetOrder: 'تایید فرم های عمومی',
			confirmStoreOrder: 'تایید فروشگاهی',
			cartIsEmpty: 'سبد خرید شما خالی است',
			detail: 'ویژگی',
			unit: 'واحد',
			supplierName: 'تامین کننده',
			totalQuantity: 'تعداد کل',
			storeProduct: 'محصول‌های فروشگاهی',
			sharedSheetProduct: 'محصول‌های عمومی',
		}

	}

};
export default HeadersTranslationFa;