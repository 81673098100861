import clsx from 'clsx';
import { Component, React } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import NsButton from '../../../../../components/buttons/NsButton';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import Config from '../../../../../configs/Config';
import Enumerations from '../../../../../configs/Enumerations';
import Links from '../../../../../configs/links/AllLinks';
import { currentUserActionCreators } from '../../../../../redux/reducers/CurrentUserReducer';
import { GetIsWinnerService, GetLatestFestivalService } from '../../../../../services/singlePages/FestivalService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../../../../utilities/ConfigHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import SignInModal from '../../../../layouts/main/headers/modals/signIn/SigninModal';

class FdResultBanner extends Component {

    constructor(props) {
        super(props);
        this.festivalResultBanners = getConfigObjectByCulture(Config.images)?.festivalResultBanners;
        this.state = {
            winnerOrLoserData: null,
            festivalName: null,
            festivalId: null,
            showSignInModal: false,
            isLoading: false,
        }
    }

    componentDidMount = () => {
        this.setState({ isLoading: true });
        GetLatestFestivalService(this.getLatestFestivalCallback);
    }

    getLatestFestivalCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false })
        if (!result.data.hasError) {
            this.setState({
                festivalName: resultData.name,
                festivalId: resultData.id,
            })
            CurrentUserHelper.isAuthenticated() && GetIsWinnerService({
                id: resultData.id,
                isLoading: true,
                cacheCode: resultData.cacheCode
            }, this.getIsWinnerCallback);
        } else {
            this.setState({
                messageClassModal: messageClassModal
            });
        }
    }

    getIsWinnerCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ winnerOrLoserData: resultData });
        else
            this.setState({ messageClassModal: messageClassModal });

    }

    resultBannerModalClicked = () => {
        this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.festival), this.state.festivalId))
        CurrentUserHelper.addToSeenFestivalCongratulationModals(this.state.festivalId);
        this.props.isModal && CommonHelper.safeFunctionCall(this.props.closeModal)
    }

    imageLinkBannerGenerator = () => {
        if (!CurrentUserHelper.isAuthenticated())
            return this.festivalResultBanners[2]?.src
        else if (this.state.winnerOrLoserData?.isWinner)
            return this.festivalResultBanners[0]?.src
        else
            return this.festivalResultBanners[1]?.src
    }

    singInButtonClicked = () => this.setState({ showSignInModal: true });

    closeSingInModal = () => this.setState({ showSignInModal: false });

    signIn = (resultData) => CommonHelper.safeFunctionCall(this.props.setCurrentUser, resultData);

    textBannerGenerator = () => {
        if (!CurrentUserHelper.isAuthenticated())
            return <div>
                <p className={clsx(this.props?.isModal && 'white-color')}>{getTranslatedRawText('festival.needToSignIn')}</p>
                <div onClick={this.singInButtonClicked} className='cursor-pointer mt-5 background-white-color mx-auto rounded w-25 text-center'>
                    <NsButton className="red-color fw-bold" label={getTranslatedRawText('account.common.login')} />
                </div>
            </div>
        else if (this.state.winnerOrLoserData?.isWinner)
            return <div className={'d-flex flex-column justify-content-between h-100'}>
                <div>
                    <p className={clsx(this.props?.isModal && 'white-color', 'festival__font-32')}>{getTranslatedRawText('festival.congratulations')}</p>
                    <p className={clsx(this.props?.isModal && 'white-color')}>
                        <span>{getTranslatedRawText('festival.winnerPrize')}</span>
                        {this.state.winnerOrLoserData?.lotteryEventWinnerAwards.map((item, index) => {
                            return <span key={index} className={'fw-bold'}>{item.name},</span>
                        })}
                        <span>{getTranslatedRawText('festival.in')}</span>
                        <span className='fw-bold'>{this.state.festivalName}</span>
                    </p>
                </div>
                <img src={CommonHelper.createImageUrl(this.state.winnerOrLoserData?.lotteryEventWinnerAwards?.[0].imageUrl)}
                    className='w-100'
                    alt={getTranslatedRawText("components.imagesAlt.festivalResultBanner")} />
            </div>
        else
            return <>
                <p className={clsx(this.props?.isModal && 'white-color', 'festival__font-32')}>{getTranslatedRawText('festival.sorry')}</p>
                <p className={clsx(this.props?.isModal && 'white-color')}>{CommonHelper.stringFormat(getTranslatedRawText('festival.sorryDes'), this.state.festivalName)}</p>
            </>
    }

    render() {
        return (
            <>
                {!this.state.isLoading && !isEmpty(this.state.winnerOrLoserData)
                    ? <Row className={clsx(this.props.isModal && 'cursor-pointer')} onClick={() => this.props.isModal && this.resultBannerModalClicked()}>
                        <Col xs={12} lg={6}>
                            {this.textBannerGenerator()}
                        </Col>
                        <Col xs={12} lg={6}>
                            <img src={this.imageLinkBannerGenerator()}
                                className='w-100'
                                alt={getTranslatedRawText("components.imagesAlt.festivalWinner")} />
                        </Col>
                    </Row>
                    : <NsSkeleton isVisible={true}
                        variant={this.props?.isModal
                            ? [
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                            ] : [
                                Enumerations.skeleton.text,
                            ]} />
                }
                {
                    this.state.showSignInModal &&
                    <SignInModal showModal={true}
                        closeModal={this.closeSingInModal}
                        signIn={this.signIn} />
                }
            </>
        )
    }
}

const mapDispachToProps = (dispatch) => {
    return bindActionCreators({ ...currentUserActionCreators }, dispatch);
}

export default connect(null, mapDispachToProps)(withRouter(FdResultBanner));