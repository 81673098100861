import React from "react";

import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../utilities/CultureHelper";
import { isBusinessPartnerRoute } from "../../../utilities/BusinessPartnerHelper";
import NsTypography from "../../../components/text/NsTypography";
import ex404 from '../../../assets/images/exceptions/ex404.png';
import NsButton from "../../../components/buttons/NsButton";
import CommonHelper from "../../../utilities/CommonHelper";
import Links from "../../../configs/links/AllLinks";

const Exception404 = (props) => {

    const handleClick = () => props.history.push(getUrlWithCurrentCulture(Links, Links.home));

    return (<div className="d-flex flex-column align-items-center">
        <img src={ex404} className={CommonHelper.isMobileDevice() ? "w-50" : 'mb-3'} alt='exception' />
        <NsTypography variant='inherit' className='font-size-16 fw-bold mb-2' >
            {getTranslatedRawText('common.exception.title404')}
        </NsTypography>
        {!isBusinessPartnerRoute() && <>
            <NsTypography variant='inherit' className='font-size-14 mb-4' >
                {getTranslatedRawText('common.exception.description404')}
            </NsTypography>
            <div className="d-flex align-self-center mb-md-3">
                <NsButton label={getTranslatedRawText('account.common.home')}
                    onClick={handleClick}
                    startIcon='ns-icon-home'
                    className='primary-outline' />
            </div>
        </>
        }
    </div>);
}

export default React.memo(Exception404);