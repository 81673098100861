import React from 'react';

import { GetUserAccountingReportService } from '../../../../services/ProfileSetting/UserOrderService';
import { GetAccountingExcelReportService } from '../../../../services/payments/PaymentService';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsDownloadFile from '../../../../components/downloadFiles/NsDownloadFile';
import NsDatePicker from '../../../../components/dateTimePickers/NsDatePicker';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../UserPanelWrapper';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import DateHelper from '../../../../utilities/DateHelper';
import GridEnumerations from '../../../../configs/GridEnumerations';

class UpReportFinancial extends React.Component {

    constructor(props) {
        super(props);
        this.memoizeCreateStickyItem = CommonHelper.memoizeFunc(CommonHelper.createStickyButtonItem);
        this.columns = [
            {
                field: 'sanadNumber',
                headerName: getTranslatedRawText('profile.orderReport.voucherNumber'),
                dataType: Enumerations.gridType.number,
                width: 100
            },
            {
                field: 'submitTime',
                headerName: getTranslatedRawText('profile.orderReport.date'),
                dataType: Enumerations.gridType.dateTime,
                width: 110
            },
            {
                field: 'systemResult',
                headerName: getTranslatedRawText('profile.orderReport.systemResult'),
                width: 100
            },
            {
                field: 'description',
                headerName: getTranslatedRawText('profile.orderReport.details'),
                width: 300
            },
            {
                field: 'bedAmount',
                headerName: getTranslatedRawText('profile.orderReport.debtor'),
                dataType: Enumerations.gridType.price,
                width: 100
            },
            {
                field: 'besAmount',
                headerName: getTranslatedRawText('profile.orderReport.creditor'),
                dataType: Enumerations.gridType.price,
                width: 100
            },
            {
                field: 'balance',
                headerName: getTranslatedRawText('profile.orderReport.balance'),
                dataType: Enumerations.gridType.price,
                width: 100
            },
            {
                field: 'sign',
                headerName: getTranslatedRawText('profile.orderReport.sign'),
                width: 100
            },
        ],
            this.state = {
                fromDate: DateHelper.getPreviousMonth(),
                isValidFromDate: true,
                toDate: new Date(),
                isValidToDate: true,
                showValidationDate: null,
                file: null,
                isLoading: true,
                callServiceDate: null,
                resultMessageClass: (<></>),
            };
    }

    valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        this.setState({
            [valueName]: value,
            [isValidName]: isValid,
        });
    }

    searchClicked = () => {
        const { isValidFromDate, isValidToDate } = this.state;
        if (!isValidFromDate || !isValidToDate) {
            this.setState({ showValidationDate: new Date() });
            return;
        }
        this.setState({ callServiceDate: new Date(), isLoading: true });
    }

    excelReportButtonClicked = () => {
        this.setState({ isLoading: true });
        let data = {
            SubmitTimeFrom: this.state.fromDate,
            SubmitTimeTo: this.state.toDate,
        };
        GetAccountingExcelReportService(data, this.accountingExcelReportCallback);
    }

    accountingExcelReportCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            const href = !isEmpty(resultData) && CommonHelper.createDownloadFileUrl(resultData?.fileDownloadName, resultData?.fileContents);
            this.setState({ file: { fileDownloadName: resultData?.fileDownloadName, fileContents: href } });
        } else this.setState({ resultMessageClass });
    }

    keyPressed = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter) {
            event.preventDefault();
            this.searchClicked();
        }
    }

    getData = (rows, isLoading) => {
        window.scrollTo({ top: 0 });
        this.setState({ isLoading });
    }

    createButtons = () => [
        this.memoizeCreateStickyItem("profile.orderReport.search", 'ns-icon-search', this.searchClicked, 'primary', (!this.state.isValidToDate && !this.state.isValidFromDate)),
        { label: "regularOrder.common.exportExcel", icon: 'ns-icon-excel', onClicked: this.excelReportButtonClicked, className: 'secondary-outline' },
    ];

    render() {
        const { fromDate, callServiceDate, isValidFromDate, toDate, isValidToDate, showValidationDate, isLoading, file, resultMessageClass } = this.state;

        return (
            <UserPanelWrapper titleName={getTranslatedRawText('profile.title.debitCreditReport')}
                titleIcon='ns-icon-account-balance' >

                <div className='d-flex gap-3 w-100 mb-3' onKeyDown={this.keyPressed}>

                    <NsDatePicker label={getTranslatedRawText('profile.orderReport.fromDate')}
                        className='w-100 me-5'
                        showValidationDate={showValidationDate}
                        valueChanged={(value, isValidDate) => this.valueChanged(value, isValidDate, { fromDate }, { isValidFromDate })}
                        selectedDate={fromDate} />

                    <NsDatePicker label={getTranslatedRawText('profile.orderReport.toDate')}
                        className='w-100'
                        showValidationDate={showValidationDate}
                        disabled={!isValidFromDate}
                        valueChanged={(value, isValidDate) => this.valueChanged(value, isValidDate, { toDate }, { isValidToDate })}
                        selectedDate={toDate} />

                    <NsStickyButton stickyButtons={this.createButtons()}
                        isLoading={isLoading} />
                </div>

                <NsDataGridWithServiceCall columnDefs={this.columns}
                    hasPagination={true}
                    callServiceDate={callServiceDate}
                    service={GetUserAccountingReportService}
                    parameters={{
                        FromDate: DateHelper.convertDateTimeToDate(fromDate),
                        ToDate: DateHelper.getDateWithEndOfDayTime(toDate),
                    }}
                    gridId={GridEnumerations.siteGrids.financialBalance}
                    hasCustomColumnOrder={true}
                    getData={this.getData} />

                <NsDownloadFile file={file} />

                {resultMessageClass}
            </UserPanelWrapper >
        );
    }
}

export default UpReportFinancial;