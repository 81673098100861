import React, { useState } from 'react';
import NsModal from '../../../components/modals/NsModal';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import { ApCustomerContent } from '../managements/users/customers/ApCustomerContent';

const ApBaseUserSelectModal = (props) => {
    const closeModal = () => {
        CommonHelper.safeFunctionCall(props.closeModal);
    }

    return (
        <NsModal maxWidth='lg'
            showModal={props.showModal}
            closeModal={closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.usersInfo.searchInUsers")}>
            <div className='p-3 pb-0'>
                <ApCustomerContent isUserSelection={true} userSelected={props.userSelected} />
            </div>
        </NsModal>
    );
};

export default React.memo(ApBaseUserSelectModal);