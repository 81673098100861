import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConstantsWithTranslation from '../../configs/ConstantsWithTranslation';
import { GetProductsService } from '../../services/product/ProductService';
import { viewItemListEvent } from '../../utilities/GoogleTagManagerHelper';
import NsReachResult from '../../components/metaTags/NsReachResult';
import NsProductCard from '../../components/cards/NsProductCard';
import NsSkeleton from '../../components/skeletons/NsSkeleton';
import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import NsLabelLine from '../../components/labels/NsLabelLine';

class HomeProductSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            isLoading: false,
        };
    }

    componentDidMount = () => this.getProducts();

    componentDidUpdate(prevProps) {
        if (!prevProps.isAuthenticated && this.props.isAuthenticated)
            this.getProducts();
        if (prevProps.selectedCurrency !== this.props.selectedCurrency)
            this.getProducts();
    }

    getProductsCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.setState({
                products: resultData.dataItems,
                isLoading: false
            }, () => viewItemListEvent(this.state.products, Enumerations.googleTagManagerListId.home));
        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    getProducts = () => {
        let parameters = {
            pageLength: CommonHelper.isMobileDevice() ? 4 : 8,
            currentPageIndex: 1,
            "Filter.productSortType": this.props.sortIndex,
            'Filter.OrderProductType': Enumerations.orderProductType.sharedSheet,
        };

        if (!!this.props.shoppingBagCategoryId)
            parameters = {
                pageLength: CommonHelper.isMobileDevice() ? 4 : 8,
                currentPageIndex: 1,
                'Filter.CategoryIds': this.props.shoppingBagCategoryId
            }

        this.setState({ isLoading: true },
            () => GetProductsService(parameters, this.getProductsCallback));
    }

    render() {
        const { products, isLoading } = this.state;
        const { primaryTitle, secondaryTitle, linkTo, isAuthenticated, reachResultType, shoppingBagCategoryId } = this.props;

        return (
            <>
                <NsLabelLine linkTo={linkTo}
                    smallSize={!!shoppingBagCategoryId}
                    primaryTitle={primaryTitle}
                    secondaryTitle={secondaryTitle} />

                <div className='row m-0'>
                    <ShowCard products={products}
                        isLoading={isLoading}
                        isAuthenticated={isAuthenticated} />
                </div>
                <NsReachResult reachResultType={reachResultType}
                    products={products}
                    primaryTitle={primaryTitle}
                />
            </>
        );
    }
}

function ShowCard(props) {
    const numberOfSkeletons = CommonHelper.isMobileDevice() ? CommonHelper.createArray(4) : CommonHelper.createArray(8);

    if (props.isLoading)
        return numberOfSkeletons.map((item, index) =>
            <div key={index} className="d-inline d-md-inline-block col-sm-6 col-lg-3 p-2">
                <NsSkeleton isVisible={true}
                    variant={[
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.text,
                        Enumerations.skeleton.text,
                    ]} />
            </div>
        );
    if (props?.products?.length > 0)
        return props.products.map((product, index) => (
            <NsProductCard key={index}
                product={product}
                usedIn={Enumerations.imageSitePosition.homeProductCard}
                isAuthenticated={props.isAuthenticated}
                className="col-sm-6 col-lg-3 p-2"
                googleTagManagerListName={ConstantsWithTranslation.getListNameGoogleTagManager(Enumerations.googleTagManagerListId.home)}
                googleTagManagerListId={Enumerations.googleTagManagerListId.home}
                googleTagManagerIndex={index} />
        ));
    else
        return null;
}

const mapStateToProps = state => ({ selectedCurrency: state.currencies.selectedCurrency });

export default connect(mapStateToProps)(HomeProductSection);