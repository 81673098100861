import React, { useState } from 'react';

import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import Enumerations from '../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBlogPostModal from '../blogs/posts/ApBlogPostModal';
import ApGalleryPostModal from '../gallery/posts/ApGalleryPostModal';
import ApNewsPostModal from '../news/posts/ApNewsPostModal';

function ApBasePostContent(props) {

    const { isBlog, service, isGallery } = props;

    const [showPostModal, setShowPostModal] = useState(false);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    const columns = [
        hasAccess(isGallery ? [UserClaimEnumerations.getApGalleryById] : isBlog ? [UserClaimEnumerations.getApBlogById] : [UserClaimEnumerations.getApNewsById]) && {
            field: 'isActive', headerName: getTranslatedRawText('adminPanel.common.action'), maxWidth: 80, width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: true,
                editClicked: (id) => showPostModalClicked(id),
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            flex: 1
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 90,
            cellClass: 'text-center',
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 150
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 150
        },
    ];

    const showPostModalClicked = (id) => {
        setShowPostModal(true);
        setSelectedPostId(id);
    }

    const closePostModalClicked = (messageClassModal) => {
        setShowPostModal(false);
        if (!isEmpty(messageClassModal)) {
            setMessageClassModal(messageClassModal);
            setCallServiceDate(new Date());
        }
    };

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText(isGallery ? "adminPanel.sidebar.galleryPosts" : isBlog ? "adminPanel.sidebar.blogPosts" : "adminPanel.sidebar.newsPosts")}
            icon={isGallery ? 'ns-icon-blog h1' : isBlog ? 'ns-icon-blog h1' : 'ns-icon-news h1'}
            actionButton={{
                onClick: showPostModalClicked,
                isLoading: isLoading,
                disabled: !hasAccess(isGallery ? [UserClaimEnumerations.postApGallery] : isBlog ? [UserClaimEnumerations.postApBlog] : [UserClaimEnumerations.postApNews])
            }}>

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={service}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {
                showPostModal && !!isGallery &&
                <ApGalleryPostModal postId={selectedPostId}
                    isNewPost={!selectedPostId}
                    closeModal={closePostModalClicked} />
            }

            {
                showPostModal && !!isBlog &&
                <ApBlogPostModal postId={selectedPostId}
                    isNewPost={!selectedPostId}
                    closeModal={closePostModalClicked} />
            }

            {
                showPostModal && !isBlog && !isGallery &&
                <ApNewsPostModal postId={selectedPostId}
                    isNewPost={!selectedPostId}
                    closeModal={closePostModalClicked} />
            }
            {messageClassModal}
        </NsAdminPanelContentWrapper >
    )
}

export default React.memo(ApBasePostContent);