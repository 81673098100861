import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { adminSidebarActionCreators } from '../../../redux/reducers/AdminSidebarReducer';
import { currentUserActionCreators } from '../../../redux/reducers/CurrentUserReducer';
import { currencyActionCreators } from '../../../redux/reducers/CurrencyReducer';
import { hasAccess, isAdminOrSuperAdmin } from '../../../utilities/ClaimHelper';
import { List, ListItem, ListItemText, ListSubheader } from '@mui/material';
import UserClaimEnumerations from '../../../configs/UserClaimEnumerations';
import DelayedTaskHelper from '../../../utilities/DelayedTaskHelper';
import NsCollapse from '../../../components/dropdowns/NsCollapse';
import StorageHelper from '../../../utilities/StorageHelper';
import NsDrawer from '../../../components/drawers/NsDrawer';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';

const ApLayoutSidebar = (props) => {
    let topScroll = 0;
    const scrollRef = useRef();
    const dispatch = useDispatch();
    const store = useSelector(state => {
        return {
            isAdminSidebarOpen: state.AdminSidebarReducer.isOpen,
            selectedItemId: state.AdminSidebarReducer.selectedItemId,
        }
    });
    const selectedItemIdChanged = (e, id) => {
        StorageHelper.saveInStorage(Enumerations.storage.adminSidebarTop, topScroll);
        dispatch(adminSidebarActionCreators.selectedItemIdChanged(id));
        CommonHelper.isMobileDevice() && dispatch(adminSidebarActionCreators.toggleAdminSidebar());
    }
    const handleToggler = () => {
        dispatch(adminSidebarActionCreators.toggleAdminSidebar());
    }

    const nav = [
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.dashboard',
            icon: 'ns-icon-dashboard h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.financialDashboard,
                    name: 'adminPanel.dashboard.financial',
                    to: Links.adminPanel.view.dashboardFinancial,
                    accessIds: [
                        UserClaimEnumerations.getApDfSaleGadgets,
                        UserClaimEnumerations.getApDfTotalSales,
                        UserClaimEnumerations.getApDfOrderByApplicationType,
                        UserClaimEnumerations.getApDfOrderByTurnaround,
                        UserClaimEnumerations.getApDfTotalOnlinePayments,
                        UserClaimEnumerations.getApDfOnlinePaymentsGateway,
                    ],
                    // badge: {
                    //   color: 'info',
                    //   text: 'NEW',
                    // }
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.businessDashboard,
                    name: 'adminPanel.dashboard.business',
                    to: Links.adminPanel.view.dashboardBusiness,
                    accessIds: [
                        UserClaimEnumerations.getApDbTotalOrders,
                        UserClaimEnumerations.getApDbTotalSales,
                        UserClaimEnumerations.getApDbTotalOnlinePayments,
                        UserClaimEnumerations.getApDbOnlinePaymentStatus,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.usersDashboard,
                    name: 'adminPanel.dashboard.users',
                    to: Links.adminPanel.view.dashboardUsers,
                    accessIds: [
                        UserClaimEnumerations.getApDuTotalUsers,
                        UserClaimEnumerations.getApDuLastJoinedUsers,
                        UserClaimEnumerations.getApDuRemainingLimitWithCredit,
                        UserClaimEnumerations.getApDuRemainingLimitWithoutCredit,
                        UserClaimEnumerations.getApDuGrantedCredit,
                        UserClaimEnumerations.getApDuInactiveUsers,
                        UserClaimEnumerations.getApCustomersDuplicatePhoneNumbers,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.smsPanelsCreditDashboard,
                    name: 'adminPanel.dashboard.smsPanelsCredit',
                    to: Links.adminPanel.view.dashboardSmsPanelsCredit,
                    accessIds: [
                        UserClaimEnumerations.getApDSmsPanelCredit
                    ],
                },
                // {
                //     tag: 'ListItem',
                //     id: Enumerations.adminBreadCrumbs.dashboardAgencies,
                //     name: 'adminPanel.dashboard.agencies',
                //     to: Links.adminPanel.view.dashboardAgencies,
                //     accessIds: [
                //         UserClaimEnumerations.getApDaLatestSubmittedAgencies,
                //         UserClaimEnumerations.getApDaTotalSalesAmountAgencies,
                //         UserClaimEnumerations.getApDaPopularAgencies,
                //         UserClaimEnumerations.getApDaActiveStates
                //     ],
                // },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.auditDashboard,
                    name: 'adminPanel.dashboard.audits',
                    to: Links.adminPanel.view.dashboardAudit,
                    accessIds: [
                        UserClaimEnumerations.getApDauStatisticsBasedOnAuditedAndPaymentStatuses,
                        UserClaimEnumerations.getApDauStatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes,
                        UserClaimEnumerations.getApDauAggregateStatistics,
                        UserClaimEnumerations.getApPaymentAuditSources,
                        UserClaimEnumerations.getApDauNotProcessedPaymentAuditCount,
                        UserClaimEnumerations.getApPaymentAudits,
                        UserClaimEnumerations.getApDauPaymentGatewaysMessages,
                        
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.feedbackDashboard,
                    name: 'adminPanel.dashboard.feedbacks',
                    to: Links.adminPanel.view.dashboardFeedback,
                    accessIds: [
                        UserClaimEnumerations.getApDfbComments,
                        UserClaimEnumerations.getApDfbRatingParametersChart,
                        UserClaimEnumerations.getApDfbStrengthWeeknessChart,
                        UserClaimEnumerations.getApDfbProducts,
                        UserClaimEnumerations.getApDfbTotalByStatusGedgets,
                        UserClaimEnumerations.getApDfbUserFeedbacks,
                        UserClaimEnumerations.getApDfbByTypeMetricAndRates,
                        UserClaimEnumerations.getApDfbByStateTypesAndDateChart,
                        UserClaimEnumerations.getApDfbStatuses,
                        UserClaimEnumerations.getApDfbCitiesGrid,
                        UserClaimEnumerations.getApDfbCustomers,
                        UserClaimEnumerations.getApDfbProvinces,
                        UserClaimEnumerations.getApDfbTotalStatusByFilterOptions,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.cashback,
                    name: 'adminPanel.dashboard.cashback',
                    to: Links.adminPanel.view.dashboardCashback,
                    accessIds: [
                        UserClaimEnumerations.getApCshbUsersCashbackForecastes,
                    ],
                },
            ]
        },
        {
            tag: 'ListSubheader',
            name: 'adminPanel.sidebar.management'
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.users',
            icon: 'ns-icon-admin-users h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.customer,
                    name: 'adminPanel.sidebar.customers',
                    to: Links.adminPanel.view.customerUser,
                    accessIds: [
                        UserClaimEnumerations.getApCustomers,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.supporter,
                    name: 'adminPanel.sidebar.supporters',
                    to: Links.adminPanel.view.supporterUser,
                    accessIds: [
                        UserClaimEnumerations.getApSupporters,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.businessPartners,
                    name: 'adminPanel.sidebar.businessPartners',
                    to: Links.adminPanel.view.businessPartners,
                    accessIds: [
                        UserClaimEnumerations.getApUserBusinessPartners
                    ],
                },
            ]
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.orders',
            icon: 'ns-icon-orders h3 mb-0 ',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.shareSheetsOrders,
                    name: 'adminPanel.sidebar.shareSheets',
                    to: Links.adminPanel.view.shareSheetOrders,
                    accessIds: [
                        UserClaimEnumerations.getApOrders,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.storeOrders,
                    name: 'adminPanel.sidebar.stores',
                    to: Links.adminPanel.view.storeOrders,
                    accessIds: [
                        UserClaimEnumerations.getApStoreOrders,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.queue,
                    name: 'adminPanel.sidebar.queue',
                    to: Links.adminPanel.view.queue,
                    accessIds: [
                        UserClaimEnumerations.getApStatusQueue,
                        UserClaimEnumerations.getApStoreStatusQueue,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.queueByEstimatedTime,
                    name: 'adminPanel.sidebar.queueByRemaining',
                    to: Links.adminPanel.view.queueByEstimatedTime,
                    accessIds: [
                        UserClaimEnumerations.getApOrderQueueByRemainingToEstimatedTime,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.dailyQueue,
                    name: 'adminPanel.sidebar.dailyQueueShorted',
                    to: Links.adminPanel.view.dailyQueue,
                    accessIds: [
                        UserClaimEnumerations.getApDailyQueueOfEstimateTime,
                    ],
                },
            ]
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.payments',
            icon: 'ns-icon-payments h3 mb-0 ',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.payment,
                    name: 'adminPanel.sidebar.list',
                    to: Links.adminPanel.view.payments,
                    accessIds: [
                        UserClaimEnumerations.getApPayments,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.problematicPayments,
                    name: 'adminPanel.sidebar.problematicPayments',
                    to: Links.adminPanel.view.problematicPayments,
                    accessIds: [
                        UserClaimEnumerations.getApPaymentsHasProblem,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.audit,
                    name: 'adminPanel.sidebar.audit',
                    to: Links.adminPanel.view.auditPayments,
                    accessIds: [
                        UserClaimEnumerations.getApPaymentAudits,
                    ],
                },
            ]
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.reports',
            icon: 'ns-icon-reports h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.statisticalReportOfOrders,
                    name: 'adminPanel.sidebar.statisticalReportOfOrders',
                    to: Links.adminPanel.view.statisticalReportOfOrders,
                    accessIds: [
                        UserClaimEnumerations.getApStatisticalReportOfOrders,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.statisticalReportOfPayments,
                    name: 'adminPanel.sidebar.statisticalReportOfPayments',
                    to: Links.adminPanel.view.statisticalReportOfPayments,
                    accessIds: [
                        UserClaimEnumerations.getApStatisticalReportOfPayments,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.usersDebitCredit,
                    name: 'adminPanel.sidebar.usersDebitCredit',
                    to: Links.adminPanel.view.usersDebitCredit,
                    accessIds: [
                        UserClaimEnumerations.getApUsersDebitCredit,
                    ],
                },
            ]
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.agencies,
            name: 'adminPanel.sidebar.agencies',
            to: Links.adminPanel.view.agencies,
            icon: 'ns-icon-delivery-request h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApAgencies
            ],
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.festivals,
            name: 'adminPanel.sidebar.festivals',
            to: Links.adminPanel.view.festivals,
            icon: 'ns-icon-festivals h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApFestivals
            ],
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.shippings',
            icon: 'ns-icon-delivery-request h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.shipping,
                    name: 'adminPanel.sidebar.list',
                    to: Links.adminPanel.view.shippingList,
                    accessIds: [
                        UserClaimEnumerations.getApShippings
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.inTheWarehouseShipping,
                    name: 'adminPanel.sidebar.inTheWarehouse',
                    to: Links.adminPanel.view.inTheWarehouseShipping,
                    accessIds: [
                        UserClaimEnumerations.getApShippings
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.shippingQueueByEstimatedTime,
                    name: 'adminPanel.sidebar.queueByEstimatedTime',
                    to: Links.adminPanel.view.shippingQueueByEstimatedTime,
                    accessIds: [
                        UserClaimEnumerations.getApShippingRemainingEstimatedTime
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.shippingQueueByLatestStatus,
                    name: 'adminPanel.sidebar.queueByLatestStatus',
                    to: Links.adminPanel.view.shippingQueueByLatestStatus,
                    accessIds: [
                        UserClaimEnumerations.getApShippingQueueByLastStatuses
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.shippingQueueByShippingMethods,
                    name: 'adminPanel.sidebar.queueByShippingMethods',
                    to: Links.adminPanel.view.shippingQueueByShippingMethods,
                    accessIds: [
                        UserClaimEnumerations.getApQueueByShippingMethods
                    ],
                },
            ]
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.customersFiles,
            name: 'adminPanel.sidebar.customersFiles',
            to: Links.adminPanel.view.customersFiles,
            icon: 'ns-icon-open-folder h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApCustomerFiles,
            ],
        },
        {
            tag: 'ListSubheader',
            name: 'adminPanel.sidebar.products'
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.storeProducts,
            name: 'adminPanel.sidebar.storeProducts',
            to: Links.adminPanel.view.storeProducts,
            icon: 'ns-icon-cart h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApStoreProducts,
            ],
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.productContent,
            name: 'adminPanel.sidebar.productContent',
            to: Links.adminPanel.view.productContent,
            icon: 'ns-icon-product-content h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApPprContents,
            ],
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.paperBag',
            icon: 'ns-icon-shopping-bag h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.shoppingBag,
                    name: 'adminPanel.sidebar.calculateCustomize',
                    to: Links.adminPanel.view.paperBag,
                    accessIds: [
                        // UserClaimEnumerations.getApPprContents,
                    ],
                },
            ]
        },
        {
            tag: 'ListSubheader',
            name: 'adminPanel.sidebar.feedbacks'
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.usersFeedbackTo',
            icon: 'ns-icon-feedback-center h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.feedbackAnnouncements,
                    name: 'adminPanel.sidebar.announcements',
                    to: Links.adminPanel.view.feedbackAnnouncements,
                    accessIds: [
                        UserClaimEnumerations.getApFeedbackAnnouncements,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.feedbackOrders,
                    name: 'adminPanel.sidebar.orders',
                    to: Links.adminPanel.view.feedbackOrders,
                    accessIds: [
                        UserClaimEnumerations.getApFeedbackShareSheetOrders,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.feedbackUsers,
                    name: 'adminPanel.sidebar.employees',
                    to: Links.adminPanel.view.feedbackUsers,
                    accessIds: [
                        UserClaimEnumerations.getApFeedbackToEmployees,
                    ],
                },
            ]
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.basicInformation',
            icon: 'ns-icon-feedback-types h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.feedbackTypes,
                    name: 'adminPanel.sidebar.feedbackTypes',
                    to: Links.adminPanel.view.feedbackTypes,
                    accessIds: [
                        UserClaimEnumerations.getApFeedbackTypes,
                    ],
                },
            ]
        },
        {
            tag: 'ListSubheader',
            name: 'adminPanel.sidebar.content'
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.downloadCenter',
            icon: 'ns-icon-download h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.downloadCenter,
                    name: 'adminPanel.sidebar.downloadCenter',
                    to: Links.adminPanel.view.downloadCenter,
                    accessIds: [
                        UserClaimEnumerations.getApDownloads,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.downloadCategory,
                    name: 'adminPanel.sidebar.category',
                    to: Links.adminPanel.view.downloadCategory,
                    accessIds: [
                        UserClaimEnumerations.getApDownloadCategories,
                    ],
                },
            ]
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.blog',
            icon: 'ns-icon-blog h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.blogPost,
                    name: 'adminPanel.sidebar.posts',
                    to: Links.adminPanel.view.blogPost,
                    accessIds: [
                        UserClaimEnumerations.getApBlogs,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.blogCategory,
                    name: 'adminPanel.sidebar.category',
                    to: Links.adminPanel.view.blogCategories,
                    accessIds: [
                        UserClaimEnumerations.getApBlogCategories,
                    ],
                },
            ]
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.news',
            icon: 'ns-icon-news h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.newsPost,
                    name: 'adminPanel.sidebar.posts',
                    to: Links.adminPanel.view.newsPost,
                    accessIds: [
                        UserClaimEnumerations.getApNews,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.newsCategory,
                    name: 'adminPanel.sidebar.category',
                    to: Links.adminPanel.view.newsCategories,
                    accessIds: [
                        UserClaimEnumerations.getApNewsCategories,
                    ],
                },
            ]
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.gallery',
            icon: 'ns-icon-news h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.galleryPosts,
                    name: 'adminPanel.sidebar.posts',
                    to: Links.adminPanel.view.galleryPosts,
                    accessIds: [
                        UserClaimEnumerations.getApGalleries,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.galleryCategories,
                    name: 'adminPanel.sidebar.category',
                    to: Links.adminPanel.view.galleryCategories,
                    accessIds: [
                        UserClaimEnumerations.getApGalleryCategories,
                    ],
                },
            ]
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.announcement,
            name: 'adminPanel.sidebar.announcements',
            to: Links.adminPanel.view.announcement,
            icon: 'ns-icon-announcement h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApAnnouncements,
            ],
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.advertisement,
            name: 'adminPanel.sidebar.advertisement',
            to: Links.adminPanel.view.advertisement,
            icon: 'ns-icon-advertisement h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApAdvertisements,
            ],
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.agreements,
            name: 'adminPanel.sidebar.agreements',
            to: Links.adminPanel.view.agreement,
            icon: 'ns-icon-agreements h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApUserAggrements,
            ],
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.departments,
            name: 'adminPanel.sidebar.departments',
            to: Links.adminPanel.view.departments,
            icon: 'ns-icon-site-map h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApUserDepartments,
            ],
        },
        {
            tag: 'NsCollapse',
            name: 'adminPanel.sidebar.singlePages',
            to: Links.adminPanel.view.adminCharts,
            icon: 'ns-icon-single-pages h3 mb-0',
            children: [
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.faq,
                    name: 'adminPanel.sidebar.faqs',
                    to: Links.adminPanel.view.faqs,
                    accessIds: [
                        UserClaimEnumerations.getApSpecificStaticPageList,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.termOfUse,
                    name: 'adminPanel.sidebar.termsOfUse',
                    to: Links.adminPanel.view.termOfUse,
                    accessIds: [
                        UserClaimEnumerations.getApSpecificStaticPageList,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.privacyPolicy,
                    name: 'adminPanel.sidebar.privacyPolicy',
                    to: Links.adminPanel.view.privacyPolicy,
                    accessIds: [
                        UserClaimEnumerations.getApSpecificStaticPageList,
                    ],
                },
                {
                    tag: 'ListItem',
                    id: Enumerations.adminBreadCrumbs.warrantyTerms,
                    name: 'adminPanel.sidebar.warrantyTerms',
                    to: Links.adminPanel.view.warrantyTerms,
                    accessIds: [
                        UserClaimEnumerations.getApSpecificStaticPageList,
                    ],
                },

            ],
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.slider,
            name: 'adminPanel.sidebar.slider',
            to: Links.adminPanel.view.slider,
            icon: 'ns-icon-slider h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApSliders,
            ],
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.promotion,
            name: 'adminPanel.sidebar.promotions',
            to: Links.adminPanel.view.promotion,
            icon: 'ns-icon-promotions h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApPromoteContent,
            ],
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.fileManager,
            name: 'adminPanel.sidebar.fileManager',
            to: Links.adminPanel.view.fileManager,
            icon: 'ns-icon-open-folder h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getFileUploader,
            ],
        },
        {
            tag: 'ListSubheader',
            name: 'adminPanel.sidebar.systemMonitoring'
        },
        {
            tag: 'ListItem',
            id: Enumerations.adminBreadCrumbs.logs,
            name: 'adminPanel.sidebar.logs',
            to: Links.adminPanel.view.logs,
            icon: 'ns-icon-log-file h3 mb-0',
            accessIds: [
                UserClaimEnumerations.getApCmsLogs,
            ],
        },

        // {
        //     tag: 'NsCollapse',
        //     name: 'adminPanel.sidebar.websiteInfo',
        //     to: Links.adminPanel.view.adminCharts,
        //     icon: <i className='ns-icon-setting mr-4' />,
        //     children: [
        //         {
        //             tag: 'ListItem',
        //             id: Enumerations.adminBreadCrumbs.smsConfig,
        //             name: 'adminPanel.sidebar.smsConfig',
        //             to: Links.adminPanel.view.smsConfig,
        //             icon: <i className='ns-icon-setting mr-4' />,
        //         },
        //     ]
        // },
    ];

    const createMenu = () => {
        return nav.map((menu, index) => {
            switch (menu.tag) {
                case 'ListItem': return <React.Fragment key={index}>{createSubMenus([menu], 'px-2 py-1')}</React.Fragment>

                case 'ListSubheader': return <div key={index} className='white-color'>
                    <ListSubheader disableSticky
                        component="div"
                        color='inherit'
                        className='fw-bold text-uppercase px-2 font-size-14'
                        id="nested-list-subheader">
                        {getTranslatedRawText(menu.name)}
                    </ListSubheader>
                </div>

                case 'NsCollapse': return hasAccessMenu(menu) && <NsCollapse key={index} className='py-1 px-2 font-size-14'
                    expandIconClassName='white-color'
                    defaultIsOpen={menu.children.some(item => item.id === store.selectedItemId)}
                    icon={clsx(menu.icon, 'white-color')}
                    titleClassName='mx-2 white-color w-100'
                    title={getTranslatedRawText(menu.name)} >

                    {createSubMenus(menu.children, 'py-1')}

                </NsCollapse>
            }
        })
    }

    const signOut = () => {
        dispatch(currencyActionCreators.removeCurrencies());
        dispatch(currentUserActionCreators.removeCurrentUser());
    }

    const createSubMenus = (menus, className) => {
        return <List className='mb-1 p-0'>
            {menus.map((menu, index) => hasAccess(menu.accessIds)

                ? <ListItem className={className} key={index} button
                    selected={store.selectedItemId === menu?.id}
                    onClick={(e) => selectedItemIdChanged(e, menu?.id)}>
                    <Link className='white-color text-decoration-none d-flex w-100 font-size-14' to={getUrlWithCurrentCulture(Links.adminPanel.view, menu.to)}>
                        {menu.icon && <div className='me-2'>
                            <i className={menu.icon} />
                        </div>
                        }
                        <ListItemText disableTypography={true} primary={getTranslatedRawText(menu.name)} className={!menu.icon ? 'ps-1 ms-4' : ''} />
                    </Link>
                </ListItem>
                : null
            )}
        </List >
    }

    const hasAccessMenu = (menu) => {
        if (isAdminOrSuperAdmin())
            return true;
        let accesses = menu.children.map(child => hasAccess(child.accessIds))
        return accesses.includes(true);
    }

    const scrollToSelectedItem = () => {
        if (!!scrollRef.current) {
            topScroll = StorageHelper.getFromStorage(Enumerations.storage.adminSidebarTop);
            scrollRef.current.scrollTo({ top: topScroll, behavior: 'smooth' });
        }
    }

    const delayScrollToSelectedItem = new DelayedTaskHelper(scrollToSelectedItem, 100);

    const handleOnScroll = (e) => {
        topScroll = e.currentTarget.scrollTop;
    }


    useEffect(() => {
        delayScrollToSelectedItem.run();
    }, [store.selectedItemId])

    return (<NsDrawer variant='persistent'
        open={store.isAdminSidebarOpen}
        onClose={handleToggler}>
        <div className='admin-panel__sidebar__menu custom-scrollbar' ref={scrollRef} onScroll={handleOnScroll}>
            <List component="nav" className='p-1 admin-panel__sidebar'>
                {createMenu()}
            </List>
        </div>
        <NsButton onClick={signOut} className="pb-1">
            <div className='my-1'>
                <i className="ns-icon-sign-out align-middle me-2 fs-2 white-color" />
                <span className="white-color ">
                    {getTranslatedRawText('profile.actionButton.signOut')}
                </span>
            </div>
        </NsButton>
    </NsDrawer>
    );

}

export default React.memo(withRouter(ApLayoutSidebar));