import React, { useEffect, useState } from "react";

import NsAdminPanelContentWrapper from "../../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../../components/grids/NsDataGridWithServiceCall";
import NsImage from "../../../../../components/images/NsImage";
import Enumerations from "../../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { GetDownloadsService } from "../../../../../services/adminPanel/AdminDownloadService";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import CommonHelper from "../../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import ApDownloadModal from "./ApDownloadModal";
import ApDownloadSearch from "./ApDownloadSearch";

function ApDownload() {

    const columns = [
        hasAccess([UserClaimEnumerations.getApDownloadById]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: true,
                editClicked: (id) => showDownloadModalHandler(id),
            }
        },
        {
            field: 'image',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.image'),
            width: 110,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => <NsImage className="w-100"
                    imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.contentImage"), props.title)}
                    src={CommonHelper.createImageUrl(props.fileUrl)} />
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 200
        },
        {
            field: 'pageCategories',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.category'),
            width: 150,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    let values = [];
                    props.pageCategories.map(category => {
                        values.push(category.value)
                    })
                    return <span> {values.join(' , ')}</span>
                }
            }
        },
        {
            field: 'files',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.files'),
            width: 80,
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 150
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 150
        },
    ];

    const [showDownloadModal, setshowDownloadModal] = useState(false);
    const [searchData, setSearchData] = useState({});
    const [selectedDownloadId, setSelectedDownloadId] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const searchClicked = (data) => {
        setSearchData(data);
    }

    useEffect(() => {
        setCallServiceDate(new Date());
        setIsLoading(true);
    }, [searchData]);

    const showDownloadModalHandler = (id) => {
        setSelectedDownloadId(id);
        setshowDownloadModal(true);
    };

    const closeModalClicked = () => {
        setshowDownloadModal(false);
        setCallServiceDate(new Date());
    };

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.downloadCenter")}
            actionButton={{
                onClick: showDownloadModalHandler,
                isLoading: isLoading,
                disabled: !hasAccess([UserClaimEnumerations.postApDownload]) || !hasAccess([UserClaimEnumerations.postApDownloadDetails])
            }}
            icon={'ns-icon-download'} >

            <ApDownloadSearch searchClicked={searchClicked}
                isLoading={isLoading} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetDownloadsService}
                parameters={{
                    'Filter.Title': searchData?.fileTitle,
                    'Filter.CategoryId': searchData?.category,
                    'Filter.FromSubmitDate': searchData?.createTimeFrom,
                    'Filter.ToSubmitDate': searchData?.createTimeTo,
                    'Filter.Status': !isEmpty(searchData?.status) ? searchData?.status.key === Enumerations.activation.active : null
                }}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {
                showDownloadModal &&
                <ApDownloadModal selectedDownloadId={selectedDownloadId}
                    closeModalClicked={closeModalClicked} />
            }
        </NsAdminPanelContentWrapper >
    )
}

export default React.memo(ApDownload);