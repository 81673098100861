import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import NsBreadcrumbs from '../../../../components/breadcrumbs/NsBreadcrumbs';
import NsButton from '../../../../components/buttons/NsButton';
import NsToggleGroupButton from '../../../../components/buttons/NsToggleGroupButton';
import NsSelectMenu from '../../../../components/dropdowns/NsSelectMenu';
import Enumerations from '../../../../configs/Enumerations';
import { fileManagerActionCreators } from '../../../../redux/reducers/FileManagerReducer';
import CommonHelper from '../../../../utilities/CommonHelper';
import StorageHelper from '../../../../utilities/StorageHelper';
import { getContentListById } from '../../bases/TraceTreeView';
import FmHeaderToolbar from '../../headers/FmHeaderToolbar';

class FmMainSectionBreadcrumb extends React.Component {

    constructor(props) {
        super(props);
        this.items = [
            { key: Enumerations.viewType.viewList, value: <i className='ns-icon-detail-view font-size-12' /> },
            { key: Enumerations.viewType.viewSingle, value: <i className='ns-icon-small-view font-size-12' /> },
        ];
        this.state = {
            selectedItemId: StorageHelper.getFromStorage(Enumerations.storage.fileManagerContentDetailStyle) ? Enumerations.viewType.viewList : Enumerations.viewType.viewSingle,
            treeViewFolders: props.treeViewFolders,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            treeViewFolders: newProps.treeViewFolders,
        });
    }

    refreshClicked = () => this.props.refreshMainSectionItems();

    selectAllClicked = () => CommonHelper.safeFunctionCall(this.props.selectAllClicked)

    linkClicked = (event) => {
        event.preventDefault();
        let parentTag = event.target.closest('a');
        this.props.setCurrentFolderId(!!parentTag.dataset.id ? parentTag.dataset.id : null);
    }

    viewItemChanged = (selectedItem) => {
        StorageHelper.saveInStorage(Enumerations.storage.fileManagerContentDetailStyle, selectedItem.key === Enumerations.viewType.viewList);
        this.setState({ selectedItemId: selectedItem.key });
        this.refreshClicked();
    }

    getBreadcrumbClassNameByIndex = (index, contentLength) => {
        return clsx(
            "text-decoration-none",
            "d-flex",
            "align-self-center",
            "breadcrumb-link",
            { "truncate-text": index + 1 !== contentLength },
            { "text-muted": index + 1 !== contentLength },
            { "selected-breadcrumb": index + 1 === contentLength },
        );
    }

    selectItemClicked = (id) => {
        id === null
            ? this.props.setCurrentFolderId(null)
            : typeof id !== 'object' && this.props.setCurrentFolderId(id)
    }

    getLinkLabelByIndex = (link, index, contentLength, contentList) => {
        if (index === 0)
            return (
                <div className="d-flex align-self-center pe-2 first-label">
                    <NsSelectMenu options={contentList}
                        selectedItem={0}
                        menuItemClassName='font-size-12'
                        label={<i className='ns-icon-home' />}
                        className='border px-1'
                        valueChanged={this.selectItemClicked} />
                </div>
            );
        if (index === contentLength - 1 && index > 0)
            return (
                <div>
                    <span className="ns-icon-open-folder d-inline-block h4 open-and-folder-icon pe-2 last-label" >
                    </span>
                    <span>
                        {this.props.isUsedInPo ? CommonHelper.textTruncate(link.name) : link.name}
                    </span>
                </div>
            );
        if (index >= 0 && index < contentLength - 1)
            return (
                <div className="d-inline">
                    <span className="ns-icon-folder title-color d-inline-block h4 open-and-folder-icon pe-2 middle-label">
                    </span>
                    <span className='title-color'>
                        {link.name}
                    </span>
                </div>
            );
    }

    breadcrumbItems = (currentFolderId) => {
        let contentList = getContentListById(currentFolderId, this.state.treeViewFolders)
        let contentLength = contentList?.length ?? 0;
        return contentList.map((link, index) => (
            <Link key={index}
                to="#"
                data-id={link.id}
                className={this.getBreadcrumbClassNameByIndex(index, contentLength)}
                onClick={index !== 0 ? this.linkClicked : null}>

                {this.getLinkLabelByIndex(link, index, contentLength, contentList)}

            </Link>
        ));
    }

    render() {
        this.toolbarButtonList = [
            !this.props.isEmptySelectedFiles && {
                label: '',
                icon: 'ns-icon-delete',
                onClicked: this.props.multipleDeleteButtonClicked
            },
            !this.props.isUsedInPo && {
                label: !CommonHelper.isMobileDevice()
                    ? this.props.isEmptySelectedFiles
                        ? 'fileManager.headerActionButtons.selectAllFiles'
                        : 'fileManager.headerActionButtons.deselectAllFiles'
                    : '',
                icon: this.props.isEmptySelectedFiles
                    ? 'ns-icon-select-all'
                    : 'ns-icon-dis-select-all',
                onClicked: this.selectAllClicked
            },
            {
                label: '', icon: 'ns-icon-refresh',
                onClicked: this.refreshClicked
            },
        ];
        return (
            <div key={this.props.currentFolderId} className="d-flex justify-content-between align-items-center file-manager__breadcrumb border p-1 background-element-color title-color w-100">
                <div className="overflow-auto">
                    <NsBreadcrumbs aria-label="breadcrumb"
                        maxItems={CommonHelper.isMobileDevice() ? 2 : 3}
                        itemsAfterCollapse={CommonHelper.isMobileDevice() ? 1 : 2}
                        itemsBeforeCollapse={1}
                        separator={<span className={`${CommonHelper.getBreadcrumbsSeparatorIcon()} h5 m-0 px-1`} />}>

                        {this.breadcrumbItems(this.props.currentFolderId)}
                    </NsBreadcrumbs>
                </div>

                <BreadcrumbsButtons buttonList={this.toolbarButtonList}
                    isUsedInPo={this.props.isUsedInPo}
                    items={this.items}
                    treeViewFolders={this.props.treeViewFolders}
                    selectedItemId={this.state.selectedItemId}
                    viewItemChanged={this.viewItemChanged} />
            </div>
        );
    }
}

function BreadcrumbsButtons(props) {
    return (
        <div className='d-flex file-manager__breadcrumb-buttons'>
            {!CommonHelper.isMobileDevice() && <NsToggleGroupButton items={props.items}
                className=''
                selectedItemId={props.selectedItemId}
                onClick={props.viewItemChanged} />
            }
            {props.buttonList.map((button, index) =>
                button.icon && <NsButton key={index} className={clsx('secondary-outline', button.className)}
                    wrapperClassName='ps-2'
                    size='small'
                    startIcon={button.icon}
                    label={button.label}
                    onClick={button.onClicked}
                />)}
            {props.isUsedInPo && <FmHeaderToolbar treeViewFolders={props.treeViewFolders} isUsedInPo={props.isUsedInPo} wrapperClassName={"ps-2"} />}
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        currentFolderId: state.fileManager.currentFolderId,
        currentUploadFolderId: state.fileManager.currentUploadFolderId,
    }
}

const mapDispatchToPops = (dispatch) => {
    return bindActionCreators(fileManagerActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToPops)(FmMainSectionBreadcrumb);