import React from "react";
import { Row } from "react-bootstrap";

import { getTranslatedRawText, isEnCulture } from "../../../../utilities/CultureHelper";
import imgNotFoundEn from '../../../../assets/images/not-found-img-en.png';
import imgNotFoundFa from '../../../../assets/images/not-found-img-fa.png';
import { isEmpty } from "../../../../utilities/ValidationHelper";
import NsButton from "../../../../components/buttons/NsButton";
import CommonHelper from "../../../../utilities/CommonHelper";
import NsCard from "../../../../components/cards/NsCard";
import NsTags from "../../../../components/tags/NsTags";
import DownloadItems from "./DownloadItems";

class DownloadViewCard extends React.Component {

    constructor(props) {
        super(props);
        this.defaultImgSrc = isEnCulture()
            ? imgNotFoundEn
            : imgNotFoundFa;
        this.state = {
            showMore: false
        }
    }

    showMoreHandler = () => this.setState({ showMore: !this.state.showMore });

    render() {
        const { showMore } = this.state;
        const { downloadCategoryDetail } = this.props;

        return (
            <NsCard hasMedia={!showMore}
                className='h-100'
                imgSource={CommonHelper.createImageUrl(downloadCategoryDetail.fileUrl) || this.defaultImgSrc}>
                <div className="d-flex flex-column p-2">

                    {showMore
                        ? <Row className="g-2 m-1">
                            <DownloadItems isShowInCard={true}
                                downloadDetails={downloadCategoryDetail.downloadDetails} />
                        </Row>
                        : <>
                            <div className="section-title">
                                {downloadCategoryDetail.title}
                            </div>
                            <span className="d-inline-block text-truncate">
                                {downloadCategoryDetail.summary}
                            </span>
                            {!isEmpty(downloadCategoryDetail.tags)
                                ? <>
                                    <div className='mb-2 d-flex ms-2 fw-bold'>{getTranslatedRawText('adminPanel.post.tags')}</div>
                                    <NsTags tags={downloadCategoryDetail.tags.split(/[-,]/)} />
                                </>
                                : ''}
                        </>
                    }
                    <NsButton label={showMore ? 'common.label.back' : 'common.download'}
                        wrapperClassName='ms-auto'
                        endIcon={<i className={showMore ? 'ns-icon-expand-left' : 'ns-icon-expand-right'} />}
                        className='primary-text'
                        onClick={this.showMoreHandler} />
                </div>

            </NsCard>
        );
    }
}

export default DownloadViewCard;