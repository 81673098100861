import Links from "../../configs/links/AllLinks";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { GetRequest, PostRequest } from "../bases/BaseService";
import { createDataForApi } from "./UserOrderService";

const GetAgenciesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getAgencies), {}, callback);
}

const GetAgencyDetailService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getDetail), {}, callback);
}

const PostAgencyOrdersBasicService = (state, callback) => {
    let data = createDataForApi(state);
    PostRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getOrdersBasic), data, callback);
}
const PostAgencyOrdersAdvanceService = (state, callback) => {
    let data = createDataForApi(state);
    PostRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getOrdersAdvance), data, callback);
}

const GetAgencyProductsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getProducts), parameters, callback);
}

const GetAgencyCustomersService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getCustomers), parameters, callback);
}

const GetAgencyCitiesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getCities), {}, callback)
}

const GetAgencyOrderDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getOrderDetail), parameters, callback)
}

const GetAgencyBasicInfoOrderDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getBasicInfoSharedSheetOrderDetail), parameters, callback)
}

const GetStateHistoriesDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.agency, Links.api.agency.getStateHistories), parameters, callback)
}

export {
    GetAgenciesService,
    GetAgencyDetailService,
    PostAgencyOrdersBasicService,
    PostAgencyOrdersAdvanceService,
    GetAgencyProductsService,
    GetAgencyCustomersService,
    GetAgencyCitiesService,
    GetAgencyOrderDetailService,
    GetAgencyBasicInfoOrderDetailService,
    GetStateHistoriesDetailService,
}