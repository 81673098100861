import React from 'react';
import { Helmet } from "react-helmet";
import Config from '../../configs/Config';
import { getConfigObjectByCulture } from '../../utilities/ConfigHelper';

class NsGoogleTagManager extends React.PureComponent {
    render() {
        const googleTagManagerApiCode = getConfigObjectByCulture(Config.googleTagManagerApiCode);
        const googleAnalyticsApiCode = getConfigObjectByCulture(Config.googleAnalyticsApiCode);
        return (
            <>
                <Helmet>
                    {/* <!-- Google Tag Manager --> */}
                    <script id='googleTagManager'>
                        {`(function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${googleTagManagerApiCode}')`}
                    </script>
                    <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsApiCode}`}></script>
                    {/* <!-- End Google Tag Manager --> */}

                    <script>
                        {`window.dataLayer = window.dataLayer || [];
    function gtag() {dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', '${googleAnalyticsApiCode}');`}
                    </script>

                </Helmet>
                <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerApiCode}`} height="0" width="0"
                    style="display:none;visibility:hidden"></iframe></noscript>
            </>
        )
    }
};

export default NsGoogleTagManager