import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import StorageHelper from '../../../../utilities/StorageHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import FmItemBreadcrumb from './FmItemBreadcrumb';
import FmDetail from '../details/FmDetail';
import FmItemFolder from './FmItemFolder';
import FmItemFile from './FmItemFile';

class FmItems extends Component {

    fileOrFolder = (content) => {
        let contentStyle = StorageHelper.getFromStorage(Enumerations.storage.fileManagerContentDetailStyle);
        if (content.isFolder) {
            return (
                <FmItemFolder
                    folderClicked={this.props.folderClicked}
                    folderDoubleClicked={this.props.folderDoubleClicked}
                    selectedItemId={this.props.selectedItemId}
                    contentStyle={contentStyle}
                    content={content}
                    isUsedInPo={this.props.isUsedInPo} />
            );
        } else {
            return (
                <FmItemFile multiFileSelectClicked={this.props.multiFileSelectClicked}
                    fileClicked={this.props.fileClicked}
                    selectedItemId={this.props.selectedItemId}
                    isSelected={!isEmpty(this.props.selectedFiles.find(file => content.id === file.id))}
                    contentStyle={contentStyle}
                    content={content}
                    hideCheckbox={this.props.hideCheckbox}
                    isUsedInPo={this.props.isUsedInPo} />
            );
        }
    }

    mapContents = (currentFolderContent) => {
        if (currentFolderContent.length > 0) {
            return currentFolderContent.map(content => <React.Fragment key={content.id}>
                {this.fileOrFolder(content)}
            </React.Fragment>
            );
        }
    }

    deselectFileHandler = () => CommonHelper.safeFunctionCall(this.props.deselectFileHandler)

    render() {
        const { isLoading, currentFolderContent, toggleProfessionalOrderTour, isUsedInPo } = this.props;
        const showDetail = (isUsedInPo && this.props.selectedItemContent) || !isUsedInPo;
        return (
            <div className={clsx(isUsedInPo ? 'p-0 professionalOrder-tour-fileManager' : 'p-2')}>

                <FmItemBreadcrumb treeViewFolders={this.props.treeViewFolders}
                    toggleProfessionalOrderTour={toggleProfessionalOrderTour}
                    isUsedInPo={isUsedInPo}
                    multipleDeleteButtonClicked={this.props.multipleDeleteButtonClicked}
                    selectAllClicked={this.props.selectAllClicked}
                    isEmptySelectedFiles={isEmpty(this.props.selectedFiles)} />

                <Row className='mt-2'>
                    <Col md={showDetail ? 8 : 12} className="pe-1">
                        {isLoading
                            ? <div className='d-flex'>
                                {CommonHelper.createArray(5).map((item, index) =>
                                    <div key={index} className="w-25 my-4 px-2">
                                        <NsSkeleton isVisible={isLoading}
                                            variant={[
                                                Enumerations.skeleton.rect,
                                                Enumerations.skeleton.rect,
                                                Enumerations.skeleton.rect,
                                                Enumerations.skeleton.text,
                                            ]} />
                                    </div>
                                )}
                            </div>
                            : <>
                                {StorageHelper.getFromStorage(Enumerations.storage.fileManagerContentDetailStyle) &&
                                    <table className='border w-100 font-size-12'>
                                        <thead>
                                            <tr>
                                                <th className='col-6 text-center border'>{getTranslatedRawText('fileManager.common.fileName')}</th>
                                                {!isUsedInPo && <th className='col-2 text-center border'>{getTranslatedRawText('fileManager.common.fileType')}</th>}
                                                {!isUsedInPo && <th className='col-2 text-center border'>{getTranslatedRawText('fileManager.common.fileSize')}</th>}
                                                <th className={clsx(isUsedInPo ? 'col-4' : 'col-2', 'text-center border')}>{getTranslatedRawText('fileManager.common.dimension')}</th>
                                            </tr>
                                        </thead>
                                    </table>
                                }
                                <div className="file-manager__items d-flex flex-wrap custom-scrollbar overflow-auto align-content-start h-100">
                                    {this.mapContents(currentFolderContent)}
                                </div>
                            </>
                        }
                    </Col>

                    {showDetail && <Col md={4} className="ps-0" >
                        <div className="position-relative background-element-color h-100 file-manager__border-file-detail d-flex align-items-stretch w-100 p-2">
                            {this.props.selectedItemContent && <i className="end-0 ns-icon-close position-absolute font-size-18 cursor-pointer pe-2"
                                onClick={this.deselectFileHandler} />}
                            <FmDetail selectedItemContent={this.props.selectedItemContent}
                                showPreviewTitle={true}
                                refreshFileContentAfterEdit={this.props.refreshFileContentAfterEdit}
                                isUsedInPo={isUsedInPo} />
                        </div>
                    </Col>
                    }
                </Row>
            </div >
        );
    }
}

export default FmItems;