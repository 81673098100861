import { Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import { UpStoreReadyToDeliverOrdersContent } from '../../stores/readyToDeliver/UpStoreReadyToDeliverOrders';
import { UpStorePreparingOrdersContent } from '../../stores/preparing/UpStorePreparingOrders';
import { UpStoreArchiveOrdersContent } from '../../stores/archive/UpStoreArchiveOrders';
import { ReadyToDeliverOrdersContent } from '../../groupSheets/readyToDeliver/UpGroupSheetReadyToDeliverOrders';
import { InProcessOrdersContent } from '../../groupSheets/inProcess/UpGroupSheetInProcessOrders';
import { PreparingOrdersContent } from '../../groupSheets/preparing/UpGroupSheetPreparingOrders';
import { ArchiveOrdersContent } from '../../groupSheets/archive/UpGroupSheetArchiveOrders';
import Enumerations from '../../../../../../configs/Enumerations';
import DateHelper from '../../../../../../utilities/DateHelper';
import UpBaseQueueOrdersItem from './UpBaseQueueOrdersItem';
import OrdersModal from '../../../../modals/OrdersModal';

const UpBaseQueueOrdersContent = (props) => {

    const { toDate, fromDate, isStore, service, refreshDate } = props;
    const [ordersQueue, setOrdersQueue] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState((<></>));
    const [modalContent, setModalContent] = useState(null);
    const [showGroupSheetOrderModal, setShowGroupSheetOrderModal] = useState(false);

    useEffect(() => {
        getStatusQueue();
    }, [fromDate, toDate, refreshDate]);

    const getStatusQueue = () => {
        setIsLoading(true);
        let statusQueueParameters = {
            FromDate: fromDate,
            ToDate: toDate ? toDate : DateHelper.getDateWithEndOfDayTime(new Date())
        }
        setOrdersQueue([]);
        service(statusQueueParameters, getStatusQueueCallback);
    }

    const getStatusQueueCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setOrdersQueue(resultData);
        } else
            setResultMessageClass(messageClassModal);
    }

    const handelModalContent = (content) => {
        setShowGroupSheetOrderModal(true);
        setModalContent(content)
    }

    const closeModal = () => setShowGroupSheetOrderModal(false);

    const orderQueueClicked = (index) => !!isStore
        ? storeOueueItemClicked(index)
        : groupSheetOueueItemClicked(index);

    const groupSheetOueueItemClicked = (index) => {
        switch (ordersQueue[index]?.sharedSheetOrderStateType) {
            case Enumerations.sharedSheetOrderStateType.inFirstQueue:
            case Enumerations.sharedSheetOrderStateType.prepare:
            case Enumerations.sharedSheetOrderStateType.prepareFail:
            case Enumerations.sharedSheetOrderStateType.inSecondQueue:
            case Enumerations.sharedSheetOrderStateType.inProgress:
            case Enumerations.sharedSheetOrderStateType.progressFail:
                return handelModalContent(<PreparingOrdersContent />)
            case Enumerations.sharedSheetOrderStateType.layouting:
            case Enumerations.sharedSheetOrderStateType.lithography:
            case Enumerations.sharedSheetOrderStateType.printing:
            case Enumerations.sharedSheetOrderStateType.cutting:
                return handelModalContent(<InProcessOrdersContent />)
            case Enumerations.sharedSheetOrderStateType.ready:
            case Enumerations.sharedSheetOrderStateType.transferToWarehouse:
                return handelModalContent(<ReadyToDeliverOrdersContent />)
            case Enumerations.sharedSheetOrderStateType.delivered:
            case Enumerations.sharedSheetOrderStateType.canceled:
                return handelModalContent(<ArchiveOrdersContent />)
        }
    };

    const storeOueueItemClicked = (index) => {
        switch (ordersQueue[index]?.sharedSheetOrderStateType) {
            case Enumerations.sharedSheetOrderStateType.prepare:
                return handelModalContent(<UpStorePreparingOrdersContent />)
            case Enumerations.sharedSheetOrderStateType.ready:
                return handelModalContent(<UpStoreReadyToDeliverOrdersContent />)
            case Enumerations.sharedSheetOrderStateType.delivered:
                return handelModalContent(<UpStoreArchiveOrdersContent />)
        }
    };

    return (
        <Row className='g-3 mx-2'>
            <UpBaseQueueOrdersItem queues={ordersQueue}
                isUseInUserPanel={true}
                isLoading={isLoading}
                orderQueueClicked={orderQueueClicked} />

            {showGroupSheetOrderModal &&
                <OrdersModal closeModal={closeModal}>
                    {modalContent}
                </OrdersModal>
            }
            {resultMessageClass}
        </Row >
    );
};

export default React.memo(UpBaseQueueOrdersContent);