import { Modal } from "bootstrap";

const HeadersTranslationEn = {

  headers: {
    common: {
      categories: {
        businessCards: 'Business Cards',
        premiumBusinessCards: 'Premium Business Cards',
        officialAndCommercialPapers: 'Official and Commercial Papers',
        brochures: 'Brochures',
        flyers: 'Flyers',
        envelopes: 'Envelopes',
        shoppingBags: 'Shopping Bags',
        fastFoodBoxes: 'Fast Food Boxes',
        searchCategories: 'Search Categories',
        Confirm: 'confirm',
        undo: 'Undo'
      },
      headerStoreProduct: 'Store',
      storeProduct: 'Store',
      sharedSheetProduct: 'Group Sheet',
    },

    top: {
      account: 'Account',
      welcomeTo: "Welcome to ",
      enjoyDifferentWay: ', Enjoy a different way of online order.',
      welcome: 'Welcome'
    },

    middle: {
      hello: 'Hello',
      allCategories: 'All categories',
      search: 'Search',
      searchProductName: 'search product name',
      myAccount: 'My Account',
      myCart: 'My Cart',
      signIn: 'Sign In',
      createAccount: 'Create Account',
      searchProduct: 'Search Product...',
      userSignInAriaLabel: 'User Sign In'
    },

    bottom: {
      allProductsCategories: 'All Product Categories',
      home: 'Home',
      shop: 'Group Sheets',
      paperBag: 'Paper Bag',
      iOrderFrequently: 'I Order frequently',
      fileManager: 'File Manager',
      deliveryRequest: 'My Shippings',
      blog: 'Blog',
      aboutUs: 'About Us',
      contactUs: 'Contact Us',
      callUs: 'Call Us:',
      phoneNumber: '(+800) 456 789',
      categories: 'Categories',
      festivals: 'Festivals',
      admin: "Admin"
    },

    modals: {
      order: 'Order(s)',
      turnaround: 'Turnaround',
      normal: 'Normal',
      express: 'Express',
      printedSide: 'Printed Side',
      frontOnly: 'Front Only',
      frontAndBack: 'Front&Back',
      quantity: 'Quantity',
      submitDate: 'Submit Date',
      circulation: 'Circulation',
      totalPrice: 'Total Price',
      payment: 'Payment',
      moreDetail: 'More Detail',
      ordersEmptyMessage: 'There is no any orders',
      showCart: 'show Cart',
      totalPriceWithVat: 'Total Price: ',
      confirmOrder: 'Confirm Orders',
      confirmShareSheetOrder: 'confirm Group Sheet',
      confirmStoreOrder: 'Confirm Store',
      cartIsEmpty: 'Your cart is Empty',
      detail: 'Detail',
      unit: 'Unit',
      totalQuantity: 'Total Quantity',
      storeProduct: 'Store',
      supplierName: 'Supplier Name',
      sharedSheetProduct: 'Group Sheet',
    },

  }

};
export default HeadersTranslationEn;