import React from 'react';

import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsModal from '../../../../components/modals/NsModal';
import FileManager from '../../../fileManager/FileManager';
import CommonHelper from '../../../../utilities/CommonHelper';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import { connect } from 'react-redux';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import { messageClass } from '../../../../utilities/MessageModalHelper';


class PoModalFileManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resultMessageClassModal: (<></>),
        }
    }

    selectFileManagerModalClicked = () => {
        if (isEmpty(this.props.currentFileContent)) {
            this.setState({ resultMessageClassModal: messageClass(getTranslatedRawText('common.messages.fileIsNotSelected')) });
            return;
        }
        CommonHelper.safeFunctionCall(this.props.selectFileManagerModalClicked, this.props.currentFileContent);
    }

    closeFileManagerModalClicked = () => CommonHelper.safeFunctionCall(this.props.closeFileManagerModalClicked);

    render() {
        const mainButtons = [
            CommonHelper.createStickyButtonItem("common.select", 'ns-icon-save', this.selectFileManagerModalClicked, 'primary masterFileModal-tour-save', isEmpty(this.props.currentFileContent)),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.closeFileManagerModalClicked, 'primary-outline')
        ];
        return (
            <NsModal maxWidth="md"
                dialogTitle={getTranslatedRawText("headers.bottom.fileManager")}
                showModal={this.props.showModal}
                closeModal={this.closeFileManagerModalClicked}
                actions={<NsStickyButton stickyButtons={mainButtons} />}>

                <div className='p-1'>
                    <FileManager isModal={true}
                        hideCheckbox={CommonHelper.isMobileDevice()}
                        isUsedInPo={true} />
                </div>

                {this.state.resultMessageClassModal}

            </NsModal >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentFileContent: state.fileManager.currentFileContent,
    }
}

export default connect(mapStateToProps)(PoModalFileManager);