import Enumerations from "../../../../configs/Enumerations";
import Links from "../../../../configs/links/AllLinks";
import OrderHelper from "../../../../utilities/OrderHelper";
import UrlHelper from "../../../../utilities/UrlHelper";

class RegularOrderRouteHelper {

    static goTo = (props, route) => props.history.push(UrlHelper.createRouteUrl(props, Links.view.main.regularOrder, route, Enumerations.routeKey.id));

    static choosingFilesStep = (id, props, index) => {
        let nextRoute = this.defineChoosingFileRoute(id, index);
        props.history.push(UrlHelper.createRouteUrlWithTwoValue(
            props,
            Links.view.main.regularOrder,
            nextRoute,
            Enumerations.routeKey.id, index));
    }

    static defineChoosingFileRoute = (id, index) => {
        if (index > OrderHelper.getFilesLength(id) - 1)
            return !!OrderHelper.hasExtendedService(id)
                ? this.defineExtendedSetviceRoute(id, OrderHelper.getFirstExtendedService(id).extendedServiceType)
                : Links.view.main.regularOrder.orderDetailConfirm;

        if (index <= 0)
            return (Links.view.main.regularOrder.orderDetailChoosingMasterFile);

        return Links.view.main.regularOrder.orderDetailChoosingFilmFile;
    }

    static extendedServicesStep = (id, props, serviceType) => {
        let nextRoute = this.defineExtendedSetviceRoute(id, serviceType);
        props.history.push(UrlHelper.createRouteUrl(
            props,
            Links.view.main.regularOrder,
            nextRoute,
            Enumerations.routeKey.id));
    }

    static defineExtendedSetviceRoute = (id, serviceType) => {
        switch (serviceType) {
            case Enumerations.extendedServices.foldLine:
                return (Links.view.main.regularOrder.orderDetailExtendedServiceFoldLine);

            case Enumerations.extendedServices.filters:
                return (Links.view.main.regularOrder.orderDetailExtendedServiceFilter);

            case Enumerations.extendedServices.roundedCorner:
                return (Links.view.main.regularOrder.orderDetailExtendedServiceCornerType);

            case Enumerations.extendedServices.punch:
                return (Links.view.main.regularOrder.orderDetailExtendedServicePunch);

            case Enumerations.extendedServices.billBook:
                return (Links.view.main.regularOrder.orderDetailExtendedServiceBillBook);

            case Enumerations.extendedServices.gripperEdge:
                return (Links.view.main.regularOrder.orderDetailExtendedServiceGripperEdge);

            case Enumerations.extendedServices.topFolding:
                return (Links.view.main.regularOrder.orderDetailExtendedServiceTopFolding);

            case Enumerations.extendedServices.handleType:
                return (Links.view.main.regularOrder.orderDetailExtendedServiceHandleType);

            default:
                if (serviceType > OrderHelper.getExtendedServicesLength(id))
                    return (Links.view.main.regularOrder.orderDetailConfirm);
                if (serviceType <= 0)
                    return (Links.view.main.regularOrder.orderDetailChoosingMasterFile);
        }
    }
}

export default RegularOrderRouteHelper;