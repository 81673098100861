import Config from "../../../../../configs/Config";

const FootersTranslationFa = {

	footer: {
		copyright: 'کلیه حقوق این سایت متعلق به ',
		website: '{0}',
		reserved: 'می باشد.',

		links: 'پیوندها',
		contactInfo: 'اطلاعات تماس',
		hotLine: 'شماره تماس',
		contactTime: Config.contactInformation.fa.availableTimes,
		email: Config.contactInformation.fa.email,
		contactWays: 'همراه ما باشید!',
		news: 'تازه‌ها',
		followUs: 'ما را دنبال کنید',
		discount: '{0)% تخفیف',
		testimonial: 'توصیف‌ها',

		relatedPage: 'صفحات مرتبط',
		ourCompany: 'شرکت ما',
		aboutUs: 'درباره ما',
		contactUs: 'تماس با ما',
		ourEquipment: 'تجهیزات و ماشین آلات',

		customerService: 'سرویس مشتریان',
		downloadTemplate: 'مرکز دانلود',
		ads: 'تبلیغ‌ها',
		fAQs: 'سوالات متداول',
		termOfUse: 'شرایط استفاده',
		privacyPolicy: ' حریم خصوصی',
		specialInstructions: 'دستورالعمل‌های خاص',
		warrantyTerms: 'شرایط گارانتی',
		help: 'راهنما',
		festivals: 'جشنواره‌ها',

		resources: 'منابع',
		requestSample: 'درخواست نمونه',
		siteMap: 'نقشه سایت',
		gallery: 'گالری',

		latestFromBlog: 'آخرین اخبار',
		blogOneDate: '15 1399 شهریور ',
		blogOneDescription: 'کارت ویزیت لمینت برجسته مخملی طلا کوب',
		blogTwoDate: '22 مرداد 1399',
		blogTwoDescription: 'کارت ویزیت لمینت برجسته مخملی طلا کوب',
		cashback: 'برگشت پول',
	},

	website: {
		title: "{0}",
		changeCulture: "تغییر زبان",
	}
};
export default FootersTranslationFa;