import React, { useEffect, useState } from 'react';

import NsCard from '../../../../components/cards/NsCard';
import { GetBusinessOnlinePaymentStatusService } from '../../../../services/adminPanel/AdminDashboardService';
import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';

const ApDbOnlinePaymentStatus = (props) => {

    const { parameters, refreshDate } = props;
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState();

    useEffect(() => {
        getBusinessOnlinePaymentStatus();
    }, [refreshDate])

    const getBusinessOnlinePaymentStatus = () => {
        setIsLoading(true);
        GetBusinessOnlinePaymentStatusService(parameters, getBusinessOnlinePaymentStatusCallback);
    }
    const getBusinessOnlinePaymentStatusCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setBarChartOptions(resultData, 0.4));
        } else
            setMessageClassModal(messageClassModal);
    };

    const options = {
        scales: {
            x: {
                display: true,
                grid: {
                    display: true
                }
            },
            y: {
                display: true,
                grid: {
                    display: true
                }
            }
        }
    };

    return (
        <NsCard className='p-3'>
            <ApBasesDashboardCharts title='adminPanel.dashboard.onlinePaymentStatus'
                height='75vh'
                type='bar'
                tooltipTitle='adminPanel.dashboard.onlinePaymentStatusDbChartDes'
                data={data}
                hasZoom={true}
                refreshClicked={getBusinessOnlinePaymentStatus}
                options={options}
                isLoading={isLoading} />
            {messageClassModal}
        </NsCard>
    );
};

export default React.memo(ApDbOnlinePaymentStatus);