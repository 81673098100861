import React, { useEffect, useState } from 'react';

import NsCard from '../../../../components/cards/NsCard';
import { GetTotalSalesService } from '../../../../services/adminPanel/AdminDashboardService';
import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';

const ApDfTotalSales = (props) => {

    const { parameters, refreshDate } = props;
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        getTotalSales();
    }, [refreshDate])

    const getTotalSales = () => {
        setIsLoading(true);
        GetTotalSalesService(parameters, getTotalSalesServiceCallback);
    }

    const getTotalSalesServiceCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setAreaLineChartOptions(resultData));
        } else
            setMessageClassModal(messageClassModal);
    }

    const options = {
        scales: {
            x: {
                display: true,
                grid: {
                    display: true
                }
            },
            y: {
                display: true,
                grid: {
                    display: false
                }
            }
        }
    };

    return <NsCard className='p-3'>

        <ApBasesDashboardCharts title='adminPanel.dashboard.totalSales'
            height='50vh'
            type='line'
            hasZoom={true}
            refreshClicked={getTotalSales}
            hasLabelBoxInChart={true}
            tooltipTitle='adminPanel.dashboard.totalSalesDfChartDes'
            data={data}
            options={options}
            isLoading={isLoading} />

        {messageClassModal}
    </NsCard>
};

export default React.memo(ApDfTotalSales);