import React, { useState } from 'react';

import { GetDownloadAllCategoryService } from '../../../../../services/adminPanel/AdminDownloadService';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import NsAdminPanelContentWrapper from '../../../../../components/cards/NsAdminPanelContentWrapper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import ApDownloadCategoryModal from './ApDownloadCategoryModal';
import Enumerations from '../../../../../configs/Enumerations';
import { isEmpty } from '../../../../../utilities/ValidationHelper';

const ApDownloadCategory = () => {

    const [showDownloadCategoryModal, setShowDownloadCategoryModal] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [messageClassModal, setMessageClassModal] = useState(<></>);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const columns = [
        hasAccess([UserClaimEnumerations.getApDownloadCategoryById]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 160,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: true,
                editClicked: (id) => showCategoryModalModalClicked(id),
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 400
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 200
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 240
        },
    ];

    const showCategoryModalModalClicked = (id) => {
        setShowDownloadCategoryModal(true);
        setSelectedCategoryId(id);
    }

    const closeModalClicked = (messageClassModal) => {
        setShowDownloadCategoryModal(false);
        if (!isEmpty(messageClassModal)) {
            setMessageClassModal(messageClassModal);
            setCallServiceDate(new Date());
        }
    }

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("common.categories")}
            actionButton={{
                onClick: showCategoryModalModalClicked,
                isLoading: isLoading,
                disabled: !hasAccess([UserClaimEnumerations.postApDownloadCategory])
            }}
            icon={'ns-icon-download'} >

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetDownloadAllCategoryService}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {
                showDownloadCategoryModal &&
                <ApDownloadCategoryModal selectedCategoryId={selectedCategoryId}
                    closeModalClicked={closeModalClicked} />
            }
            {messageClassModal}
        </NsAdminPanelContentWrapper >
    )
}

export default React.memo(ApDownloadCategory);