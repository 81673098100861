import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { currentUserActionCreators } from '../../../../redux/reducers/CurrentUserReducer';
import { currencyActionCreators } from '../../../../redux/reducers/CurrencyReducer';
import { encodeJwtToken, getUserRole } from '../../../../utilities/ClaimHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import NsTypography from '../../../../components/text/NsTypography';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsAvatar from '../../../../components/avatar/NsAvatar';
import NsCard from '../../../../components/cards/NsCard';

const ApHeaderUserInfo = (props) => {

    const { username } = props;
    const dispatch = useDispatch();

    const mapRoles = () => {
        let roles = encodeJwtToken()?.role;
        return !isEmpty(roles) && roles.map((role, index) =>
            <span key={index} className='background-element-color p-1 rounded-3 font-size-14'>
                {getUserRole(Number(role))}
            </span>
        )
    }

    const signOutClicked = () => {
        dispatch(currencyActionCreators.removeCurrencies());
        dispatch(currentUserActionCreators.removeCurrentUser());
    }

    return (
        <NsCard className='d-flex flex-column left-panel-user-info border-0'>
            <div className='d-flex align-items-start p-2 border-bottom'>
                <NsAvatar className='avatar-icon'
                    name={username} />

                <div className='pt-2 flex-grow-1'>
                    <div className=' ps-4 d-flex flex-column font-size-12'>
                        <p className="truncate text-capitalize section-title">
                            {username}
                        </p>
                        <span className="light-paragraph not-rtl-with-margin" >
                            {CurrentUserHelper.getMobileForUI()}
                        </span>
                    </div>
                    <div className='ps-4 d-flex flex-wrap gap-2 py-2'>
                        {mapRoles()}
                    </div>
                </div>
            </div>
            <div className='p-2'>
                <Link to="#"
                    className="text-decoration-none d-flex align-items-center mt-1"
                    onClick={signOutClicked} >
                    <i className="ns-icon-sign-out align-middle text-color mb-0 me-2 h3 " />
                    <NsTypography variant='inherit' className='text-color'>
                        {getTranslatedRawText('profile.actionButton.signOut')}
                    </NsTypography>
                </Link>
            </div>
        </NsCard >
    );
}

export default React.memo(ApHeaderUserInfo);