import React, { useEffect, useState } from 'react';

import NsModal from '../../../../../components/modals/NsModal';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import ApBaseUserInfoModal from '../../users/bases/ApBaseUserInfoModal';
import { ShareSheetOrderContent } from './ApShareSheetOrder';
import { GetUserBasicInfoByIdService } from '../../../../../services/adminPanel/AdminUserService'
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import Enumerations from '../../../../../configs/Enumerations';
import { UpdateOrderStatusService } from '../../../../../services/adminPanel/AdminOrderService';
import { isEmpty } from '../../../../../utilities/ValidationHelper';

const ApShareSheetOrderModal = (props) => {

    const hasAccessInfo = hasAccess([UserClaimEnumerations.getApUserBasicInfoById]);
    const { serverName } = props

    const [showModal, setShowModal] = useState(props.showModal);
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [info, setInfo] = useState();
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedRowIdsWithUserIds, setSelectedRowIdsWithUserIds] = useState([]);
    const [refreshOrdersGrid, setRefreshOrdersGrid] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState(<></>)

    const userInfoClicked = () => {
        setShowUserInfoModal(true);
    }

    const closeUserInfoModal = () => {
        setShowUserInfoModal(false);
    }

    const closeModal = () => {
        setShowModal(false);
        CommonHelper.safeFunctionCall(props.closeModal);
    }

    useEffect(() => {
        !!props?.searchData?.user && hasAccessInfo && getUserInfo(props?.searchData?.user)
    }, [props?.searchData?.user])

    useEffect(() => {
        !!props?.searchData?.lastStatuses && setInfo({ orderItemInfoDto: { lastStatusType: props.searchData.lastStatuses.id } })
    }, [props?.searchData?.lastStatuses])

    const getUserInfo = (userId) => !isEmpty(userId) && GetUserBasicInfoByIdService(userId, getUserBasicInfoByIdCallback)

    const getUserBasicInfoByIdCallback = (resultData, result, messageClassModal) => {
        if (!result?.HasError)
            setInfo(resultData)
        else
            setMessageClassModal(messageClassModal);
    }

    const changeStatusToInFirstQueueClicked = () => {
        setIsLoading(true);
        UpdateOrderStatusService(selectedRowIdsWithUserIds, updateOrderStatusCallback, true);
    }

    const updateOrderStatusCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        setMessageClassModal(messageClassModal);
        setRefreshOrdersGrid(new Date());
    }

    const dataGridSelectionChanged = (selection, selectionWithUserIds) => {
        selection.rowIds = selection.rowIds.map(id => CommonHelper.toInt(id));
        setSelectedRowIds({ selectedRowIds: [...selection.rowIds] });
        setSelectedRowIdsWithUserIds(selectionWithUserIds);
    }

    const headerButtons = [
        !props.isQueue && !isEmpty(props?.searchData?.user) && hasAccess([UserClaimEnumerations.getApCustomerById]) && { wrapperClassName: '', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: userInfoClicked },
        props.isQueue && props.searchData?.lastStatuses?.id === Enumerations.sharedSheetOrderStateType.prepareFail &&
        hasAccess([UserClaimEnumerations.updateApOrderStatus]) && { wrapperClassName: '', startIcon: 'ns-icon-retry', label: 'adminPanel.common.retry', disabled: isEmpty(selectedRowIds?.selectedRowIds), onClick: changeStatusToInFirstQueueClicked },
    ];

    return (
        <NsModal maxWidth='lg '
            showModal={showModal}
            closeModal={closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.sidebar.orders")}>

            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />

            {
                !!info && <div className='background-color px-3 py-2'>
                    <ApBaseLabelInfo info={info} hasAccessInfo={hasAccessInfo} />
                </div>
            }

            {
                !!props?.searchData && <div className='background-color px-3 py-2'>
                    <ApBaseLabelInfo info={{
                        ...props?.searchData,
                        orderItemInfoDto: {
                            turnaroundType: props.searchData.turnarounds?.id,
                            category: !!props.searchData.productCategories ? getTranslatedRawText('adminPanel.orders.sixHours') : null
                        }
                    }} hasAccessInfo={hasAccessInfo} />
                </div>
            }

            {
                !!serverName && <div className='background-color px-3 py-2'>
                    <ApBaseLabelInfo info={{ orderItemInfoDto: { serverName } }} hasAccessInfo={hasAccessInfo} />
                </div>
            }

            <div className='p-3'>
                <ShareSheetOrderContent customerDetails={props.customerDetails}
                    isQueue={props.isQueue}
                    hasCheckbox={!!props.isQueue && Enumerations.sharedSheetOrderStateType.prepareFail === props?.searchData?.lastStatuses?.id}
                    searchData={props.searchData}
                    refreshOrdersGrid={refreshOrdersGrid}
                    sharedSheetOrderStateTypeTabs={props.sharedSheetOrderStateTypeTabs}
                    selectedRows={dataGridSelectionChanged} />
            </div>

            {showUserInfoModal &&
                <ApBaseUserInfoModal showModal={showUserInfoModal}
                    selectedUserId={props.userId}
                    closeModal={closeUserInfoModal} />
            }

            {messageClassModal}
        </NsModal>
    );
}

export default React.memo(ApShareSheetOrderModal);