import React from 'react';
import NsSkeleton from '../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../configs/Enumerations';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import OrderHelper from '../../../utilities/OrderHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';

class OSummaryExtendedService extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refreshSummaryDate: props.refreshSummaryDate,
            extendedServices: OrderHelper.getExtendedServices(props.routeId),
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshSummaryDate !== prevState.refreshSummaryDate)
            return { extendedServices: OrderHelper.getExtendedServices(nextProps.routeId), refreshSummaryDate: nextProps.refreshSummaryDate };
        return null;
    }

    isValidServiceSummary = (service, serviceEnumeration) => {
        return (service.extendedServiceType === serviceEnumeration && !isEmpty(service.selectedItem))
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshSummaryDate !== prevState.refreshSummaryDate) {
            return { extendedServices: OrderHelper.getExtendedServices(nextProps.routeId) };
        }
        else return null;
    }

    mapExtendedServices = (isViewMore) => {
        let services = this.state.extendedServices.filter(service => !!service.selectedItem);

        return services.map((service, index) => {

            let serviceName = OrderHelper.getExtendedServicesName(service.extendedServiceType);
            if (this.isValidServiceSummary(service, Enumerations.extendedServices.foldLine))
                return (
                    <div key={index} className=' mb-1'>
                        {serviceName}:&nbsp;
                        {isViewMore &&
                            <b> {Array.isArray(service.selectedItem)
                                ? CommonHelper.stringFormat(getTranslatedRawText('professionalOrder.summaries.foldLinePriceDetail'), service.selectedItem?.length, CommonHelper.currencyFormatByDefaultConfig(service.price))
                                : <>({service.selectedItem.title})</>}
                            </b>
                        }
                        <b>{CommonHelper.currencyFormatByDefaultConfig(Array.isArray(service.selectedItem) ? service.price * service.selectedItem?.length : service.selectedItem.price)}</b>
                    </div>
                );

            if (this.isValidServiceSummary(service, Enumerations.extendedServices.filters))
                return (
                    <div key={index} className=' mb-1'>
                        {serviceName}:&nbsp;
                        {isViewMore &&
                            <b> {CommonHelper.stringFormat('{0} * {1} =', service.selectedItem.length, CommonHelper.currencyFormatByDefaultConfig(service.price))}</b>}
                        <b>{CommonHelper.currencyFormatByDefaultConfig(service.price * service.selectedItem.length)}</b>

                    </div>
                );

            if (this.isValidServiceSummary(service, Enumerations.extendedServices.roundedCorner) && service.selectedItem?.id === Enumerations.roundedCorner.curved) {
                return (
                    <div key={index} className=' mb-1'>
                        {serviceName}:&nbsp;
                        {isViewMore &&
                            <b> ({service.selectedItem.label})</b>}
                        <b>{CommonHelper.currencyFormatByDefaultConfig(service.price)} </b>
                    </div>
                );

            } if (this.isValidServiceSummary(service, Enumerations.extendedServices.gripperEdge) && service.selectedItem?.id === Enumerations.gripperEdge.withGripperEdge) {
                return (
                    <div key={index} className='mb-1'>
                        {serviceName}:&nbsp;
                        {isViewMore &&
                            <b> ({service.selectedItem.label})</b>}
                        <b>{CommonHelper.currencyFormatByDefaultConfig(service.price)} </b>
                    </div>
                );

            } if (this.isValidServiceSummary(service, Enumerations.extendedServices.punch)) {
                return (
                    <div key={index} className=' mb-1'>
                        {CommonHelper.stringFormat('{0}: ', serviceName)}
                        <b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(service.price)} </b>
                    </div>
                );

            } if (this.isValidServiceSummary(service, Enumerations.extendedServices.billBook)) {
                return (
                    <div key={index} className=' mb-1'>
                        {CommonHelper.stringFormat('{0}: ', serviceName)}
                        <b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(service.selectedItem?.totalBillBookPrice)} </b>
                    </div>
                );

            } if (this.isValidServiceSummary(service, Enumerations.extendedServices.handleType) && service.selectedItem?.id !== Enumerations.handleType.withoutHandle) {
                return (
                    <div key={index} className=' mb-1'>
                        {CommonHelper.stringFormat('{0}: ', serviceName)}
                        <b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(service?.price)} </b>
                    </div>
                );

            } if (this.isValidServiceSummary(service, Enumerations.extendedServices.topFolding) && service.selectedItem?.id === Enumerations.topFolding.has) {
                return (
                    <div key={index} className=' mb-1'>
                        {CommonHelper.stringFormat('{0}: ', serviceName)}
                        <b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(service?.price)} </b>
                    </div>
                );
            }

            return true;
        });
    }

    render() {
        const { extendedServices } = this.state;
        const { totalExtendedServicePrice, isViewMore, pricesLoading } = this.props;
        return isEmpty(extendedServices) || !totalExtendedServicePrice
            ? null
            : <div className='mb-3 order-tour-extendedServiceSummary'>
                <div className='border-bottom font-size-12 pb-1'>
                    <div className='font-size-14 fw-bold mb-1'>{getTranslatedRawText('regularOrder.extendedService.extendedServices')}</div>
                    {this.mapExtendedServices(isViewMore)}
                </div>
                <div className='w-100 font-size-12 mt-1'>
                    {pricesLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.text,
                            ]} />
                        : <>
                            {getTranslatedRawText('regularOrder.confirmDetail.servicesPrice')}:&nbsp;
                            <span className='fw-bold'>
                                {CommonHelper.currencyFormatByDefaultConfig(totalExtendedServicePrice)}
                            </span>
                        </>
                    }
                </div>

            </div>
    }
}

export default OSummaryExtendedService;