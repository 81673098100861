import React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NsButton from '../../../../components/buttons/NsButton';
import NsChart from '../../../../components/charts/NsChart';
import NsSelect from '../../../../components/dropdowns/NsSelect';
import NsLabelBox from '../../../../components/labels/NsLabelBox';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import NsTooltip from '../../../../components/tooltips/NsTooltip';
import Enumerations from '../../../../configs/Enumerations';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBasesDashboardChartSkeleton from './ApBasesDashboardChartSkeleton';

const ApBasesDashboardDoughnutCharts = (props) => {

    const { title, data, options, height, type, hasLabelBoxInChart, isLoading, tooltipTitle, hasSelect, selectedItem, selectLabel, selectOptions, hasZoom } = props;
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const toggleTooltipClicked = () => {
        setIsTooltipOpen(!isTooltipOpen);
    }

    return (
        <Row>
            <Col xs={hasSelect ? 8 : 12}>
                <div className='d-flex fw-bold'>
                    <span>{getTranslatedRawText(title)}</span>
                    <span>
                        <NsButton className='ms-2 secondary-text masterFileModal-tour-preview p-0'
                            startIcon='ns-icon-refresh'
                            onClick={() => CommonHelper.safeFunctionCall(props.refreshClicked)} />
                    </span>
                </div>
            </Col>
            {hasSelect && <Col xs={4} >
                <NsSelect options={selectOptions}
                    label={getTranslatedRawText(selectLabel)}
                    valueChanged={(value) => CommonHelper.safeFunctionCall(props.selectValueChanged, value)}
                    selectedItem={selectedItem} />
            </Col>}
            <Col className='px-0 align-self-center' xs={8} sm={4} md={6} xl={4}>
                {isLoading
                    ? <ApBasesDashboardChartSkeleton />
                    : !isEmpty(data) && <NsChart height={height} type={type} data={data} options={options} />
                }
            </Col >
            <Col md={7} className='align-self-center'>
                {props.children}
            </Col>
        </Row >
    );
};

export default React.memo(ApBasesDashboardDoughnutCharts);