import React, { useEffect, useState } from 'react';

import NsModal from '../../../../../../components/modals/NsModal';
import { GetAdminFilterPointsService } from '../../../../../../services/adminPanel/AdminOrderService';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import FiltersContent from '../../../../../profileSettings/modals/filters/FiltersContent';

const ApOrderFiltersModal = (props) => {

    const { file, isFront, id, userId } = props;

    const [resultData, setResultData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => GetAdminFilterPointsService(id, { id: userId, isFront }, getFilterPointCallback)
        , []);

    const getFilterPointCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError)
            setResultData(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    return (
        <NsModal showModal={true}
            maxWidth="sm"
            dialogTitle={CommonHelper.stringFormat('{0}({1})', getTranslatedRawText('regularOrder.extendedService.filter'),
                getTranslatedRawText(isFront ? 'regularOrder.common.frontSide' : 'regularOrder.common.backSide'))}
            closeModal={props.closeModal}>

            <FiltersContent resultData={resultData}
                isLoading={isLoading}
                file={file} />

            {resultMessageClass}

        </NsModal >
    );
}

export default React.memo(ApOrderFiltersModal);