import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NsSetPageTitleAndDescription from '../../../../components/metaTags/NsSetPageTitleAndDescription';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';
import { breadcrumbsActionCreators } from '../../../../redux/reducers/BreadcrumbsReducer';
import SingletonCallService from '../../../../services/bases/SingletonCallService';
import { GetCmsBlogCategoryDetailService, GetCmsBlogsFilterPostService, GetCmsNewsCategoryDetailService, GetCmsNewsFilterPostService } from '../../../../services/singlePages/CmsService';
import CommonHelper from '../../../../utilities/CommonHelper';
import UrlHelper from '../../../../utilities/UrlHelper';
import BlogNews from '../../BlogNews';
import BnBasesCategoryCard from '../bases/BnBasesCategoryCard';
import BnBasesPosts from '../bases/BnBasesPosts';

class BnContentCategoryPosts extends React.Component {

    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.pageCategoryId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id)
        this.singletonBlogCategoryService = new SingletonCallService(GetCmsBlogCategoryDetailService, this.getCmsCategoryCallback);
        this.singletonNewsCategoryService = new SingletonCallService(GetCmsNewsCategoryDetailService, this.getCmsCategoryCallback);
        this.singletonBlogCategoryPostsService = new SingletonCallService(GetCmsBlogsFilterPostService, this.getCmsCategoryPostsCallback);
        this.singletonNewsCategoryPostsService = new SingletonCallService(GetCmsNewsFilterPostService, this.getCmsCategoryPostsCallback);
        this.state = {
            posts: [],
            category: null,
            totalCount: 0,
            pageLength: 12,
            currentPageIndex: 1,
            isLoading: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        this.getCategory();
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (this.pageCategoryId !== UrlHelper.getRouteValue(newProps, Enumerations.routeKey.id)) {
            this.pageCategoryId = UrlHelper.getRouteValue(newProps, Enumerations.routeKey.id)
            this.setState({ currentPageIndex: 1 },
                () => {
                    this.getCategory();
                })
        }
    }

    getPostsOfCategory = () => {
        const { pageLength, currentPageIndex } = this.state;
        let parameters = { pageLength, currentPageIndex, 'Filter.CategoryIds': this.pageCategoryId };
        this.isBlog
            ? this.singletonBlogCategoryPostsService.run(parameters)
            : this.singletonNewsCategoryPostsService.run(parameters);
    }

    getCmsCategoryPostsCallback = (resultData, result, messageClassModal) => {
        const { currentPageIndex, pageLength } = this.state;
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            let totalCount = CommonHelper.getTotalPageCount(resultData.totalCount, pageLength);
            CommonHelper.redirectToCorrectPathIfInvalidPageNumber(currentPageIndex, totalCount, window.location.pathname, this.props);
            this.setState({ posts: resultData.dataItems, totalCount });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    };

    getCategory = () => {
        let parameters = { pageCategoryId: this.pageCategoryId };
        this.setState({ isLoading: true });
        this.isBlog
            ? this.singletonBlogCategoryService.run(parameters)
            : this.singletonNewsCategoryService.run(parameters);
    }

    getCmsCategoryCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false })
        this.getPostsOfCategory();
        if (!result.data.hasError) {
            this.setState({ category: resultData });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    };

    onPageChanged = (currentPageIndex) => this.setState({ currentPageIndex }, this.getPostsOfCategory);

    render() {
        const { posts, category, totalCount, isLoading, currentPageIndex } = this.state;
        return <BlogNews >
            {isLoading
                ? <div className='w-100'>
                    <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                        ]} />
                </div>
                : <>
                    <BnBasesCategoryCard category={category}
                        className='w-100' />
                    <BnBasesPosts posts={posts}
                        isLoading={isLoading}
                        hasPagination={true}
                        count={totalCount}
                        onPageChanged={this.onPageChanged} />
                </>
            }
            <NsSetPageTitleAndDescription pageNumber={currentPageIndex}
                selectedCategoryNames={category?.title}
                pageTitle={this.isBlog ? 'adminPanel.sidebar.blog' : 'adminPanel.sidebar.news'} />
        </BlogNews>
    }
}

const mapStateToProps = (state) => {
    return {
        breadcrumbs: state.breadcrumbs
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...breadcrumbsActionCreators }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BnContentCategoryPosts);