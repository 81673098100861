import React, { useEffect, useRef, useState } from "react";

import NsDataGridWithServiceCall from "../../../../../components/grids/NsDataGridWithServiceCall";
import { GetSupportersService } from "../../../../../services/adminPanel/AdminUserService";
import { getApSupporterColumn } from "../bases/ApBaseUserHelper";
import ApAddSupporterUserModal from "./ApAddSupporterUserModal";
import Enumerations from "../../../../../configs/Enumerations";
import ApBaseUserInfoModal from "../bases/ApBaseUserInfoModal";
import { convertMobile } from "../customers/ApCustomerContent";
import ApSupporterUserSearch from "./ApSupporterUserSearch";

const ApSupporterContent = (props) => {
    
    const { headerActionDate, adminSelected, isAdminSelection } = props;
    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [selectedSupporter, setSelectedSupporter] = useState(null);
    const [searchData, setSearchData] = useState({});
    const [showSupporterUserModal, setShowSupporterUserModal] = useState(false);
    const [showAddSupporterUserModal, setAddShowSupporterUserModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData]);

    useEffect(() => {
        if (!!headerActionDate) {
            addSupporterClicked();
        }
    }, [headerActionDate]);

    const getParameters = () => ({
        "Filter.PhoneNumber": !!searchData?.mobile ? convertMobile(searchData?.mobile) : '',
        "Filter.FirstName": searchData?.name || '',
        "Filter.LastName": searchData?.lastName || '',
        "Filter.NationalCode": searchData?.idNumber || '',
        "Filter.IsCompany": searchData?.accountTypes?.id || [],
        "Filter.Roles": searchData?.roles?.id || [],
        "Filter.AccountStatus": searchData.accountStatus?.id,
        "Filter.RegistrationTimeFrom": searchData?.registionTimeFrom || '',
        "Filter.RegistrationTimeTo": searchData?.registionTimeTo || '',
        "Filter.DetailLedgerId": searchData?.detailLedger || '',
    });

    const handlers = {

        supporterDetailClicked: (user) => {
            setSelectedSupporter(user);
            setShowSupporterUserModal(true);
        },

        closeSupporterUserModalClicked: () => {
            setShowSupporterUserModal(false);
        },

        addSupporterClicked: () => { setAddShowSupporterUserModal(true); },

        closeAddSupporterUserModalClicked: () => {
            setAddShowSupporterUserModal(false);
            setCallServiceDate(new Date());
        },

        getSearchData: (data) => {
            setSearchData(data);
        },

        getData: (supporters, isLoading) => {
            setIsLoading(isLoading);
        }
    }

    const { supporterDetailClicked, addSupporterClicked, closeSupporterUserModalClicked, closeAddSupporterUserModalClicked,
        getData, getSearchData } = handlers;

    const columns = getApSupporterColumn(adminSelected, supporterDetailClicked, isAdminSelection);

    return (<>
        <div className="mb-4">
            <ApSupporterUserSearch isLoading={isLoading}
                setSearchData={getSearchData} />
        </div>
        <div>
            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetSupportersService}
                parameters={getParameters()}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showSupporterUserModal &&
                <ApBaseUserInfoModal showModal={true}
                    roleType={Enumerations.role.supporter}
                    selectedUserId={selectedSupporter?.userId}
                    closeModal={closeSupporterUserModalClicked} />
            }

            {showAddSupporterUserModal &&
                <ApAddSupporterUserModal
                    isAddNewAdmin={true}
                    closeModal={closeAddSupporterUserModalClicked} />
            }

        </div>
    </>)
}

export default React.memo(ApSupporterContent);