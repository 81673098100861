import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import officialAndCommercial from '../../../assets/images/specialNeeds/categories/Offical_and_Commercial.png';
import NsCard from '../../../components/cards/NsCard';
import NsHtmlToReact from '../../../components/htmlToReact/NsHtmlToReact';

const Categories = () => {

    const categories = useSelector(state => {
        return {
            categories: state.categories.categories
        }
    });

    return (
        <Row>
            {categories?.categories?.map((item, index) => <Col md={6} className='mb-3 ' key={item.id} >
                <NsCard className='border-0 p-3 cursor-pointer h-100'>
                    <Row className='p-0 m-0'>
                        <Col xs={5} className='m-0 p-0'>
                            <Link className='wrapper-white border-start-0 border-end-0 text-decoration-none'
                                to={item.path}>
                                <img className='rounded w-100' src={officialAndCommercial} />
                            </Link>
                        </Col>
                        <Col xs={7} className='m-0 ps-2 flex-grow-1'>
                            <span className='light-text-color mb-2 fw-bold line-clamp line-clamp--1'>
                                {item.name}
                            </span>
                            <p className='light-text-color font-size-14 line-clamp line-clamp--7'>
                                <NsHtmlToReact>{item.description}</NsHtmlToReact>
                            </p>
                        </Col>
                    </Row>
                </NsCard>
            </Col>)}
        </Row>
    );
};

export default React.memo(Categories);