import React from 'react';

import { GetCmsBlogsFilterPostService, GetCmsNewsFilterPostService } from '../../../../services/singlePages/CmsService';
import CommonHelper from '../../../../utilities/CommonHelper';
import BnBasesPosts from '../bases/BnBasesPosts';

class BnContentMainPosts extends React.Component {

    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.state = {
            posts: [],
            isLoading: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.getPosts();

    getPosts = () => {
        let parameters = { pageLength: 3, currentPageIndex: 1, 'Filter.CategoryIds': this.props.categoryId };
        this.setState({ isLoading: true });
        this.isBlog
            ? GetCmsBlogsFilterPostService(parameters, this.getCmsFilterPostCallback)
            : GetCmsNewsFilterPostService(parameters, this.getCmsFilterPostCallback);
    }

    getCmsFilterPostCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.setState({ posts: resultData.dataItems, totalCount: resultData.totalCount });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    };

    render() {
        const { title, className, isNewPosts, categoryId } = this.props;

        return <>
            <BnBasesPosts posts={this.state.posts}
                title={title}
                categoryId={categoryId}
                isNewPosts={isNewPosts}
                isLoading={this.state.isLoading}
                className={className}
                hasPagination={false} />
            {this.state.resultMessageClass}
        </>
    }
}

export default BnContentMainPosts;