import React, { useEffect, useRef, useState } from 'react';

import NsAdminPanelContentWrapper from '../../../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import NsTab from '../../../../../../components/tabs/NsTab';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../../configs/Enumerations';
import { GetApOrderQueueByEstimatedTimeService } from '../../../../../../services/adminPanel/AdminOrderService';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import ApShareSheetOrderDetailModal from '../../shareSheets/ApShareSheetOrderDetailModal';
import { getApOrderQueueByEstimatedTimeColumns } from './ApOrderQueueByEstimatedTimeHelper';
import ApOrderQueueByEstimatedTimeSearch from './ApOrderQueueByEstimatedTimeSearch';
import CommonHelper from '../../../../../../utilities/CommonHelper';

export function ApOrderQueueByEstimatedTimeContent(props) {
    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [searchData, setSearchData] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(false);
    const [selectedTab, setSelectedTab] = useState(ConstantsWithTranslation.sharedSheetOrderEstimatedQueueTurnaround()[0]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    const closeApShareSheetOrderDetailModal = () => setShowOrderDetailModal(false);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData]);

    const tabChanged = (selectedTabIndex, selectedTab) => {
        setSelectedTabIndex(selectedTabIndex);
        setSelectedTab(selectedTab);
    };


    const getSearchData = (data) => {
        setSearchData(data);
    };

    const getData = (data, isLoading) => setIsLoading(isLoading);

    const getParameters = () => ({
        "Filter.UserId": searchData?.userId || null,
        "Filter.EstimateReadyToDeliverTimeFrom": searchData?.estimateReadyToDeliverTimeFrom || null,
        "Filter.EstimateReadyToDeliverTimeTo": searchData?.estimateReadyToDeliverTimeTo || null,
        ...(searchData?.productCategoryIds?.length > 0 && { "Filter.ProductCategoryIds[0]": searchData?.productCategoryIds[0] || null }),
        ...(searchData?.productCategoryIds?.length > 1 && { "Filter.productCategoryIds[1]": searchData?.productCategoryIds[1] || null }),
        "Filter.ProductId[0]": searchData?.productId?.length > 0 ? searchData?.productId[0] : null || null,
        "Filter.SharedSheetOrderStateTypes[0]": searchData?.sharedSheetOrderStateTypes?.length > 0 ? searchData?.sharedSheetOrderStateTypes[0] : [],
        "Filter.NasInvoiceIds[0]": searchData?.nasInvoiceIds?.length > 0 ? searchData?.nasInvoiceIds[0] : null,
        "Filter.SubmitTimeFrom": searchData?.submitTimeFrom || null,
        "Filter.SubmitTimeTo": searchData?.submitTimeTo || null,
        "Filter.TurnaroundType": searchData?.turnaroundType || null,
        "Filter.DelayToEstimateReadyToDeliverDay": searchData?.delayToEstimateReadyToDeliverDay,
    })

    const selectOrderClicked = (order) => {
        setSelectedOrder(order);
        setShowOrderDetailModal(true);
    }

    const columns = getApOrderQueueByEstimatedTimeColumns(selectOrderClicked, null, true, null, false);

    return (
        <>
            <ApOrderQueueByEstimatedTimeSearch customerDetails={props?.customerDetails}
                setSearchData={getSearchData}
                isLoading={isLoading}
                selectedTab={selectedTab}
                tabChanged={tabChanged} />

            <NsTab tabs={CommonHelper.clearArrayFromEmptyItem(ConstantsWithTranslation.sharedSheetOrderEstimatedQueueTurnaround())}
                value={selectedTabIndex}
                className='border-bottom'
                onChange={tabChanged} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetApOrderQueueByEstimatedTimeService}
                parameters={getParameters()}
                getData={getData}
                columnDefs={columns}
                hasPagination={true} />

            {showOrderDetailModal &&
                <ApShareSheetOrderDetailModal showModal={true}
                    selectedOrder={selectedOrder}
                    closeModal={closeApShareSheetOrderDetailModal} />
            }
            {resultMessageClass}
        </>
    );
}

function ApOrderQueueByEstimatedTime() {
    return (<NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.queueByRemainingToERDGroupSheets")}
        icon={'ns-icon-remaining-estimated h1'}>
        <ApOrderQueueByEstimatedTimeContent />
    </NsAdminPanelContentWrapper>);
}

export default ApOrderQueueByEstimatedTime;