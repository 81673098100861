import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PostRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';

const GetCreditService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userBalance, Links.api.userBalance.getCredit), {}, callback);
}

const GetBalanceService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userBalance, Links.api.userBalance.getBalance), {}, callback);
}

const PaymentByCreditService = (orderIds, callback) => {
    let data = { orderIds: orderIds };
    PostRequest(getUrlWithCurrentCulture(Links.api.userBalance, Links.api.userBalance.paymentByCredit), data, callback, true);
}



export {
    GetCreditService,
    GetBalanceService,
    PaymentByCreditService

}