import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import clsx from 'clsx';

import CommonHelper from '../../../utilities/CommonHelper';
import NsImage from '../../../components/images/NsImage';
import instantDiscountImg from '../../../assets/images/instant-discount.jpg';
import NsTypography from '../../../components/text/NsTypography';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import NsMenu from '../../../components/menu/NsMenu';
import { GetDecreasedProductPricesService } from '../../../services/product/ProductService';
import NumberHelper from '../../../utilities/NumberHelper';
import EnumerationTranslationHelper from '../../../utilities/EnumerationTranslationHelper';
import { Link } from '@mui/material';
import UrlHelper from '../../../utilities/UrlHelper';

export default class HomeBaseInstantDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discountedProducts: [],
            resultMessageClass: <></>,
            backgroundColorPattern: [],
        }
    }

    componentDidMount = () => this.getDiscountedProducts();

    getDiscountedProducts = () => GetDecreasedProductPricesService(this.getDiscountedProductsCallback);

    getDiscountedProductsCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.setState({
                discountedProducts: resultData,
                backgroundColorPattern: CommonHelper.isMobileDevice()
                    ? this.mobileBackgroundColorPattern(resultData?.length)
                    : this.desktopBackgroundColorPattern(resultData?.length)
            });
        }
        else this.setState({ resultMessageClass: messageClassModal });
    };

    productDiscountMenu = (discountedProduct, index, backgroundColorPattern) => {

        let findHighestDiscountPercentage = () => {
            let productsDiscountRates = [];

            for (let i = 0; i < discountedProduct?.decreasedProductPricesDetails?.length; i++) {
                productsDiscountRates.push(discountedProduct?.decreasedProductPricesDetails[i]?.decreasePercentage);
            }
            return `${Math.max(...productsDiscountRates)}%`;
        };

        return (
            <div className={clsx(backgroundColorPattern.includes(index) ? 'background-element-color rounded' : 'background-white')} >
                <NsMenu buttonEndIcon={<i className='ns-icon-expand-down' />}
                    buttonClassName='secondary-text'
                    buttonLabel={<NsTypography variant='inherit' className='font-size-12 d-flex justify-content-between w-100 px-2'>
                        <div className='text-truncate max-width-300 px-2'>{discountedProduct?.pprName}</div>
                        <div className='fw-bold'>{findHighestDiscountPercentage()}</div>
                    </NsTypography>}
                    placement='bottom-end'
                    buttonSize='small'>
                    <div>
                        {this.productDiscountDetail(discountedProduct?.decreasedProductPricesDetails, discountedProduct?.pprId, discountedProduct?.pprName)}
                    </div>
                </NsMenu>
            </div >
        )
    }

    productDiscountDetail = (decreasedProductPricesDetails, pprId, pprName) => {
        return (
            decreasedProductPricesDetails?.length > 0 && decreasedProductPricesDetails?.map((item, index) =>
                <Link key={index}
                    underline="none"
                    href={UrlHelper.createPdRegularDetailUrl({}, pprId, pprName)}>
                    <NsTypography variant='inherit' class='font-size-12 mb-0'>
                        <div className='d-flex justify-content-between align-items-center p-1 rounded'>
                            <div className='d-flex align-items-center'>
                                <div className='text-color'><strong>{item?.tiraj}</strong> {getTranslatedRawText('home.instantDiscountSection.pcs')}</div>
                                <div class="vr mx-1"></div>
                                <strong className='text-color'>{EnumerationTranslationHelper.getTurnaroundText(item?.turnaroundTypeId)}</strong>
                                <div class="vr mx-1"></div>
                                <strong className='text-color'>{EnumerationTranslationHelper.getPrintedSideText(item?.twoSidePrintingTypeId)}</strong>
                            </div>
                            <div className='d-flex align-items-center mx-3 text-decoration-line-through font-size-10 light-text-color'>
                                {CommonHelper.rtlFriendlyDefaultCurrencyFormat(item?.oldPrice)}
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='red-color fw-bold'>{CommonHelper.currencyFormatByDefaultConfig(item?.price, true)}</div>&nbsp;
                                <div className='light-text-color font-size-10'>{CommonHelper.getDefaultCurrency()}</div>
                                <div className='background-red-color py-1 px-2 fw-bold white-color rounded font-size-10 ms-2'>{item?.decreasePercentage}%</div>
                            </div>
                        </div>
                    </NsTypography>
                </Link>
            )
        )
    }

    desktopBackgroundColorPattern = (n) => {
        const result = [];
        let num = 1;

        for (let i = 0; i < n; i++) {
            result.push(num);
            if (i % 2 === 0) { num += 1 }
            else { num += 3 }
        }
        return result;
    }

    mobileBackgroundColorPattern = (n) => {
        const result = [];
        for (let i = 0; i < n; i++) {
            result.push(1 + i * 2);
        }
        return result;
    }

    render() {
        const { discountedProducts, backgroundColorPattern, resultMessageClass } = this.state;
        return (
            <>
                <Row className='wrapper-white p-2 p-lg-5 p-md-5 mx-1'>
                    {false && !CommonHelper.isMobileDevice() &&
                        <Col md={3} className='px-5'>
                            <NsImage className='w-auto'
                                imgAlt="components.imagesAlt.instantDiscount"
                                src={instantDiscountImg} />
                        </Col>
                    }
                    <Col md={12} sm={12} className='px-3'>
                        <NsTypography variant='inherit' className='font-size-18 fw-bold d-flex justify-content-center mb-4'>
                            {getTranslatedRawText("home.instantDiscountSection.cheaperThanBefore")}
                        </NsTypography>
                        <Row className='d-flex justify-content-evenly'>
                            {discountedProducts?.length > 0 && discountedProducts.map((product, index) => (
                                <Col md={6} sm={12} className='my-1'>
                                    {this.productDiscountMenu(product, (index + 1), backgroundColorPattern)}
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
                {resultMessageClass}
            </>
        )
    }
};