import React, { useEffect, useState } from 'react';

import { GetUsersFinancialBalanceSheetService } from '../../../../services/adminPanel/AdminReportService';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import { getApUsersFinancialBalanceSheetColumn } from './ApStaticalReportHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsModal from '../../../../components/modals/NsModal';
import ApUsersReportsSearch from './ApUsersReportsSearch';
import ApBaseLabelInfo from '../../bases/ApBaseLabelInfo';
import DateHelper from '../../../../utilities/DateHelper';
import ApBaseMapActionButtons from '../../bases/ApBaseMapActionButtons';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import ApBaseUserInfoModal from '../users/bases/ApBaseUserInfoModal';

const ApUsersFinancialBalanceSheetContent = (props) => {

    const { userDetails } = props;
    const columns = getApUsersFinancialBalanceSheetColumn();

    const [isLoading, setIsLoading] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [searchData, setSearchData] = useState(props.searchData || {});

    useEffect(() => {
        setCallServiceDate(new Date());
    }, [searchData]);

    const getParameters = () => {
        return {
            userId: searchData?.userId || null,
            fromDate: DateHelper.convertDateTimeToDate(searchData?.reportDateFrom || DateHelper.getPreviousWeek()),
            toDate: DateHelper.convertDateTimeToDate(searchData?.reportDateTo || new Date()),
        }
    }

    const searchClicked = (data) => {
        setSearchData(data);
    }

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <>
            <ApUsersReportsSearch isLoading={isLoading}
                userDetails={userDetails}
                searchClicked={searchClicked} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={searchData?.userId ? GetUsersFinancialBalanceSheetService : ''}
                parameters={getParameters()}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />
        </>
    );
}

const ApUsersFinancialBalanceSheet = () => {
    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.title.debitCreditReport")}
            icon={'ns-icon-reports h1'}>
            <ApUsersFinancialBalanceSheetContent />
        </NsAdminPanelContentWrapper>
    )
}

export const ApUsersFinancialBalanceSheetModal = (props) => {
    const [isShowUserInfoModal, setIsShowUserInfoModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const showUserInfoModalToggle = () => setIsShowUserInfoModal(!isShowUserInfoModal)

    const headerButtons = () => ([
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: showUserInfoModalToggle, accessIds: [UserClaimEnumerations.getApCustomerById] }
    ])

    const { userDetails } = props;
    return (
        <NsModal dialogTitle={getTranslatedRawText("adminPanel.title.debitCreditReport")}
            showModal={true}
            noSpace={true}
            maxWidth='lg'
            closeModal={props.closeModal}>
            {isLoading
                ? <NsSkeleton isVisible={true}
                    variant={[Enumerations.skeleton.rect]} />
                : <ApBaseMapActionButtons actionButtons={headerButtons()}
                    isLoading={isLoading} />}
            <div>
                {!isEmpty(userDetails) &&
                    <div className='background-color p-2'>
                        <ApBaseLabelInfo info={userDetails}
                            hasAccessInfo={true} />
                    </div>
                }
                <div className='p-2'>
                    <ApUsersFinancialBalanceSheetContent userDetails={userDetails} />
                </div>
            </div>
            {isShowUserInfoModal &&
                <ApBaseUserInfoModal showModal={isShowUserInfoModal}
                    selectedUserId={userDetails?.userId}
                    closeModal={showUserInfoModalToggle} />
            }
        </NsModal>
    )
}

export default React.memo(ApUsersFinancialBalanceSheet);