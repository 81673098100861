import React, { Component } from "react";
import { Row } from "react-bootstrap";

import "../../../../assets/sass/views/singlePages/OurEquipment.scss";
import NsCard from "../../../../components/cards/NsCard";
import NsTypography from "../../../../components/text/NsTypography";
import Config from "../../../../configs/Config";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getConfigObjectByCulture } from "../../../../utilities/ConfigHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import SinglePage from "../../SinglePage";
import { getTitleMetaTagByCulture } from "../../../../components/metaTags/NsSetPageTitleAndDescription";

class OurEquipment extends Component {

    ourEquipments = getConfigObjectByCulture(Config.ourEquipments);

    getOurEquipmentText = () => {
        return this.ourEquipments?.map(equipment =>
            <tr key={equipment.id}>
                <td>{equipment.id}</td>
                <td>{getTranslatedRawText(equipment.name)}</td>
                <td>{equipment.quantity}</td>
            </tr>
        )
    }

    render() {
        return (
            <SinglePage >
                <NsCard className="our-equipment mb-4">
                    <Row className="primary-text">
                        <div className="our-equipment-background-image" style={{ '--image-url': `url(${getConfigObjectByCulture(Config.images)?.ourEquipments[0].src})` }}>
                            <span className="d-flex our-equipment-text-parent mb-2">

                                <h1 className="our-equipment-background-image-text ms-3 ms-lg-4 mb-1">
                                {getTranslatedRawText("singlePage.ourEquipment.ourEquipment")} 
                                </h1>
                            </span>
                        </div>
                        <div className="d-flex py-3 px-4 flex-column">
                            <div className="d-flex our-equipment-subject mb-3">
                                <div className="our-equipment-title">
                                    <h2 className="fw-bold mb-0 font-size-24">{getTranslatedRawText("singlePage.ourEquipment.machineryAndEquipment")}</h2>
                                </div>
                                <span className="our-equipment-line ms-2"></span>
                            </div>
                            <p className="m-0 our-equipment-p1 mb-4 mt-1">
                                {CommonHelper.stringFormat(getTranslatedRawText("singlePage.ourEquipment.mainP1"), getTitleMetaTagByCulture)}
                            </p>
                            <table className="col-lg-8">
                                <caption className="font-size-14 title-color background-element-color border border-light-text-color px-3">
                                    {CommonHelper.stringFormat(getTranslatedRawText("singlePage.ourEquipment.tableCaption"), getTitleMetaTagByCulture)}
                                </caption>
                                <thead>
                                    <tr>
                                        <th className="background-disable-color fw-bold">
                                            {getTranslatedRawText("singlePage.ourEquipment.headerTable.no")}
                                        </th>
                                        <th className="background-disable-color fw-bold">
                                            {getTranslatedRawText("singlePage.ourEquipment.headerTable.machineryAndEquipmentName")}
                                        </th>
                                        <th className="background-disable-color fw-bold text-align-center">
                                            {getTranslatedRawText("singlePage.ourEquipment.headerTable.quantity")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.getOurEquipmentText()}
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </NsCard>
            </SinglePage>
        );
    }
}

export default OurEquipment;