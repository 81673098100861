import clsx from 'clsx';
import React from 'react';

import NsChip from '../../../../../components/chips/NsChip';
import NsTypography from '../../../../../components/text/NsTypography';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';

class FdCodes extends React.Component {

    render() {
        return (
            <div className='p-3 background-white-color'>
                <div className='py-2 festival__codes__title background-white-color'>
                    <NsTypography variant='inherit' className='font-size-18 fw-bold text-nowrap'>{getTranslatedRawText('festival.codes.yourCodes')}</NsTypography>
                </div>
                <div className='d-flex flex-wrap'>
                    {!!this.props.lotteryEventCodes && this.props.lotteryEventCodes.map((code, index) => {
                        return <NsChip key={code.id}
                            chipLabel={code.code}
                            size='small'
                            className={clsx('m-1 ', index % 2 === 1 ? 'festival__codes--odd-list' : 'festival__codes--even-list')} />
                    })}
                </div>
            </div>
        )
    }
}

export default FdCodes;