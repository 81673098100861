import React, { useEffect, useState } from "react";
import NsTab from "../../../components/tabs/NsTab";
import CommonHelper from "../../../utilities/CommonHelper";
import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";

const ApLanguageTabs = (props) => {

    const languageTabs = ConstantsWithTranslation.getAllLanguages().sort(function (a, b) {
        return a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1;
    });

    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    useEffect(() => {
        CommonHelper.safeFunctionCall(props.languageChanged, languageTabs[0]);
    }, [])

    useEffect(() => {
        languageTabs.map((tab) => {
            if (tab.id == props.selectedTabId) {
                setSelectedTabIndex(languageTabs.indexOf(tab))
            } else
                setSelectedTabIndex(0)
        })
    }, [props.selectedTabId])

    const tabChanged = (selectedTabIndex, selectedTab) => {
        CommonHelper.safeFunctionCall(props.languageChanged, selectedTab);
    }
    return (
        <>
            <NsTab tabs={languageTabs}
                value={selectedTabIndex}
                className='border-bottom mb-3'
                onChange={tabChanged} />
        </>
    );
}

export default React.memo(ApLanguageTabs);