import React from 'react';

import NsButton from '../../../../../components/buttons/NsButton';
import NsImageScaledView from '../../../../../components/images/NsImageScaledView';
import NsTypography from '../../../../../components/text/NsTypography';
import NsTour from '../../../../../components/tour/NsTour';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import AddPunchModal from '../../../modals/AddPunchModal';
import ExtendedServiceWrapper from './ExtendedServiceWrapper';
class ExtendedServicePunch extends React.Component {

    constructor(props) {
        super(props);
        this.tourId = Enumerations.tourGuide.punch;
        this.tourSteps = RegularOrderTours.getPunchTour();
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = {
            showTour: !CurrentUserHelper.isTourSeen(this.tourId),
            punchPrice: 0,
            masterFiles: null,
            selectedPunch: null,
            isShowAddPunchModal: false,
        };
    }

    componentDidMount = () => {
        let service = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.punch);
        OrderHelper.getMasterFilesWithFullInfo(this.routeId, (masterFiles) => {
            this.setState({
                selectedPunch: service?.selectedItem,
                punchPrice: service.price,
                pointDiameter: service.pointDiameter,
                masterFiles: masterFiles[0].frontSideFileInfo,
            });
        });
    }

    showAddPunchModal = () => {
        !CurrentUserHelper.isTourSeen(this.tourId) && CurrentUserHelper.addToSeenTours(this.tourId);
        this.setState({ isShowAddPunchModal: true, showTour: false });
    }

    closeAddPunchModalClicked = () => this.setState({ isShowAddPunchModal: false });

    saveAddPunchModalClicked = (selectedPunch) => {
        OrderHelper.updateExtendedService(this.routeId, Enumerations.extendedServices.punch, selectedPunch)
        this.setState({ selectedPunch, isShowAddPunchModal: false, refreshExtendedServiceSummaryDate: new Date() });
    }

    render() {
        const { routeId, selectedPunch, pointDiameter, masterFiles, isShowAddPunchModal, refreshExtendedServiceSummaryDate, punchPrice, showTour } = this.state;
        const hasPunch = !!selectedPunch?.verticalPoint || !!selectedPunch?.horizontalPoint;

        return (
            <ExtendedServiceWrapper otherProps={this.props}
                routeId={this.routeId}
                refreshExtendedServiceSummaryDate={refreshExtendedServiceSummaryDate}
                currentExtendedService={Enumerations.extendedServices.punch}>
                <div className='p-4'>

                    <NsTypography variant='inherit' className='light-text'>
                        {getTranslatedRawText('regularOrder.common.frontSide')}
                    </NsTypography>

                    <div className='d-flex mt-2'>
                        <div className='w-25 d-none d-md-block position-relative'>
                            <NsImageScaledView src={masterFiles?.thumbnail}
                                imgAlt='components.imagesAlt.scaledImage'
                                width='w-100' />
                        </div>

                        <div className='mx-2 mb-2'>
                            <NsButton startIcon={hasPunch ? 'ns-icon-edit me-auto' : 'ns-icon-add me-auto'}
                                size='small'
                                label={hasPunch ? "regularOrder.extendedService.editPunch" : "regularOrder.extendedService.addPunch"}
                                onClick={this.showAddPunchModal}
                                className='secondary extendedService-tour-punch' />
                        </div>
                    </div>

                    {hasPunch &&
                        <NsTypography className='light-paragraph my-2'>
                            {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.extendedService.punchAdded'),
                                CommonHelper.currencyFormatByDefaultConfig(punchPrice))}
                        </NsTypography>
                    }
                </div>

                {isShowAddPunchModal &&
                    <AddPunchModal showModal={isShowAddPunchModal}
                        masterFileInfo={masterFiles}
                        trimZone={OrderHelper.getTrimZone(this.routeId)}
                        productDesignTemplateUrl={OrderHelper.getProductDesignTemplateUrl(routeId)}
                        selectedPunch={selectedPunch}
                        pointDiameter={pointDiameter}
                        punchPrice={punchPrice}
                        saveAddPunchModalClicked={this.saveAddPunchModalClicked}
                        closeAddPunchModalClicked={this.closeAddPunchModalClicked} />
                }
                {showTour && <NsTour steps={this.tourSteps}
                    id={this.tourId} />}
            </ExtendedServiceWrapper >
        );
    }
}

export default ExtendedServicePunch