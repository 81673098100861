const RegularOrderTranslationFa = {
    regularOrder: {
        common: {
            aed: 'دِرهم',
            save: 'ذخیره',
            add: 'افزودن',
            clear: 'پاک کردن',
            next: 'مرحله بعد',
            previous: 'مرحله قبل',
            skip: 'رد شدن',
            frontSide: 'روی سفارش',
            backSide: 'پشت سفارش',
            clickHere: 'کلیک کنید',
            dragHere: 'فایل خود را بکشید و رها کنید',
            height: "ارتفاع",
            width: "عرض",
            imgSide: "سمت تصویر",
            removeAndSave: 'حذف و ذخیره',
            horizontal: "افقی",
            vertical: "عمودی",
            convex: "محدب",
            concave: "مقعر",
            exportExcel: "خروجی اکسل",
            preview: 'پیش نمایش'
        },

        header: {
            editOption: 'ویرایش محصول',
            turnaround: 'سرعت چاپ',
            normal: 'معمولی',
            express: 'فوری',
            printedSide: 'یکرو یا دورو',
            frontOnly: 'یکرو',
            frontAndBack: 'دو رو',
            circulation: 'تیراژ',
            latCount: 'تعداد لت',
            totalUps: 'مجموع تعداد لت',
            quantity: 'تعداد',
            downloadGuide: 'راهنما',
            discount: 'تخفیف',
            cancellationCode: "رمز حذف",
            totalQuantity: 'مجموع تعداد'
        },

        mainStepper: {
            chooseYourFilesTitle: 'انتخاب فایل',
            chooseYourFilesDescription: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects1',
            addExtendedServiceTitle: 'افزودن خدمات',
            addExtendedServiceDescription: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects2',
            confirmDetailsTitle: 'تایید جزییات سفارش',
            confirmDetailsDescription: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects3',
        },

        choosingFile: {
            fourColorPrintedFile: 'فایل چاپی 4 رنگ',
            choosingFile: 'انتخاب فایل',
            clearOrder: 'حذف سفارش جاری',
        },

        extendedService: {
            extendedService: 'خدمات',
            extendedServices: 'خدمات',
            filter: 'فیلتر',
            foldLine: 'خط تا',
            offSetFromLeft: 'فاصله از چپ',
            offSetFromTop: 'فاصله از بالا',
            punch: 'پانچ',
            addFilter: 'افزودن فیلتر',
            editFilter: 'ویرایش فیلتر',
            totalFilter: 'فیلتر ({0}باکس) : {1}',
            addPunch: 'افزودن پانچ',
            editPunch: 'ویرایش پانچ',
            punchAdded: 'یک پانچ اضافه شد: {0}',
            punchPrice: 'قیمت پانچ: ',
            corners: 'لبه ی کار',
            squareCorners: 'دور تیز',
            roundedCorners: 'دور گرد',
            square: 'دور تیز',
            rounded: 'دور گرد',
            gripperEdge: 'لب پنجه',
            cutLine: 'خط برش',
            withGripperEdge: 'با لب پنجه',
            withoutGripperEdge: 'بدون لب پنجه',
            billBook: 'فاکتور',
            handleType: 'نوع دسته',
            none: 'ندارد',
            flatHandle: 'ساده',
            twistHandle: 'مارپیچ',
            topFolding: 'تای لبه ی بالا',
            hasTopFolding: 'تای لبه ی بالا دارد',
            gripperEdgeDescription: 'در طراحی و فرم‌بندی، یک حاشیه ۱ سانتی‌متری از پایین کاغذ به عنوان لب پنجه در نظر گرفته می‌شود که قابل چاپ نیست. (لب‌پنجه در چاپ افست، گیره‌ای بر روی سیلندرهای حامل کاغذ است که کاغذ را از بیرون ماشین گرفته و به داخل می‌برد.)',
        },

        billBook: {
            noSerialSelected: 'محل درج سریال را با کلیک بر روی تصویر انتخاب کنید.',
            noSerialStartFrom: 'از صحت شماره سریال اطمینان حاصل فرمایید.',
            enterSerial: 'شماره سریال را وارد کنید.',
            billBookOptions: 'گزینه‌های فاکتور',
            previewBillBook: 'پیش نمایش فاکتور',
            billBookDescription: 'با توجه به  امکان اضافه کردن شماره سریال و خط پرفراژ در جدول زیر، در صورت نیاز آنها را مشخص نمایید .',
            billBookCopy: 'تعداد نسخه',
            billBookCopiesErrorText: 'تنظیمات مربوط به تعداد نسخه‌های فاکتور وارد نشده ، با مدیر فرم‌بندی تماس بگیرید.',
            billBookPrintColor: 'نوع چاپ',
            billBookBindingType: 'نوع صحافی',
            billBookBindingSideType: 'طرف صحافی',
            hasUnderHand: 'زیر برگردان دارد',
            underHand: 'زیر برگردان',
            serialStartFrom: 'شروع سریال از',
            hasIt: 'دارد',
            hasNotIt: 'ندارد',
            serialNumber: 'شماره سریال',
            serialNumberAndPerforation: 'شماره سریال اول و خط پرفراژ اول',
            firstSerialNumber: 'شماره سریال اول',
            secondSerialNumber: 'شماره سریال دوم',
            perforation: 'خط پرفراژ',
            firstPerforation: 'خط پرفراژ اول',
            secondPerforation: 'خط پرفراژ دوم',
            addSerial: 'اضافه کردن شماره سریال',
            serialNumberDirection: 'جهت',
            oneCopy: 'فقط اصلی',
            twoCopies: 'اصلی + 1 کپی',
            threeCopies: 'اصلی + 2 کپی',
            fourCopies: 'اصلی + 3 کپی',
            black: 'تک رنگ (مشکی)',
            navyBlue: 'تک رنگ (سرمه‌‌ای)',
            redAndBlack: 'دو رنگ (قرمز و مشکی)',
            fourColors: 'چهار رنگ',
            master: 'نسخه اصلی',
            firstCopy: 'کپی اول',
            secondCopy: 'کپی دوم',
            thirdCopy: 'کپی سوم',
            first: 'اول',
            second: 'دوم',
            free: 'بدون صحافی',
            glue: 'چسب',
            stapler: 'منگنه',
            up: 'بالا',
            right: 'راست',
            down: 'پایین',
            left: 'چپ',
            invalidSerial: 'شماره سریال معتبر نمی باشد',
            serialNumberStartedFromTitle: 'شروع از',
            serialNumberStartedFromTo: 'شروع از {0} تا {1}',
            billBookLanguageType: 'زبان',
            persian: 'فارسی',
            english: 'انگلیسی',
            copies: 'نسخه',
            nextSeriesMode: 'حالت بعدی سری',
            restartNumbering: 'شماره از ابتدا',
            continueNumbering: 'ادامه شماره سریال',
        },

        modal: {
            fourColorPrintingFile: 'فایل چاپی چهار رنگ ({0})',
            closeModalAlert: 'با بستن این پنجره، آخرین فایل آپلود شده حذف خواهد شد. آیا از بستن این پنجره مطمئن هستید؟',
            closeServicesModalAlert: 'با بستن این پنجره، تغییرات از بین خواهد رفت. آیا از بستن این پنجره مطمئن هستید؟',
            changeFile: 'تغییر فایل',
            followingPointsTitle: 'تصویری که در اینجا می‌بینید، فایل پرینت نهایی است. با دقت موارد زیر را بررسی فرمایید.',
            zoneSafeTitle: '1- ناحیه رزرو',
            zoneSafeDescription: 'ناحیه خارج خط قرمز در فرآیند پرینت و برش بریده خواهد شد. مطمئن شوید اطلاعات از خط قرمز عبور نمی کند.',
            bleedTitle: '2- خطوط برش',
            bleedDescription: 'به منظور داشتن سفارش چاپی مطلوب، باید تصویر پس زمینه سفارش شما تا لبه‌ها امتداد داشته باشد',
            filmDescription: 'به منظور داشتن سفارش چاپی مطلوب، فایل چاپی و فایل فیلم  باید کاملا با یک دیگر منطبق باشند',
            howToMatch: 'نحوه تطبیق؟',
            howToMatchDescription: 'جهت تطبیق فایل چاپی و فایل فیلم، فیلم را بروی فایل چاپی بکشید. همچنین می‌توانید از ابزارهای تنظیم زیر استفاده کنید.',
            howToResize: 'نحوه تغییر سایز',
            howToResizeDescription: 'به منظور تغییر سایز فایل خود از ابزار تفییر سایز فایل استفاده کرده یا ابعاد فایل خود را وارد نمایید',
            filterTitle: 'سرویس اضافه (فیلتر)',
            foldLineTitle: 'سرویس اضافه (خط تا)',
            filterDescription: 'با کشیدن موس روی عکس موقعیت فیلتر خود را مشخص کنید',
            filterWidth: 'عرض= {0} میلیمتر',
            filterHeight: 'ارتفاع= {0} میلیمتر',
            dimensionError: 'حداقل ابعاد یک فیلتر {0}×{1} میلیمتر است',
            overlapError: 'فیلترهای بی اثر را حذف کنید',
            foldLineDistanceError: 'حداقل فاصله بین دو خط تا باید {0} میلیمتر باشد.',
            perforationDistanceError: 'حداقل فاصله بین دو خط پرفراژ باید {0} میلیمتر باشد.',
            foldLineTrimZoneError: 'خط تا بایستی در محدوده مجاز باشد',
            filterQuantity: '{0} فیلتر',
            noFilterSelected: 'هیچ فیلتری برای این طرف فایل انتخاب نشده است.',
            noFoldLineSelected: 'هیچ خط تایی انتخاب نشده است.',
            resize: 'تغییر سایز',
            dimension: 'ابعاد',
            selectedDimension: 'ابعاد انتخاب شده',
            noDimensionSelected: 'ابعادی انتخاب نشده',
            maxSeries: 'سری مجاز',
            use: 'اعمال',
            Used: 'اعمال شده',
            total: 'جمع کل:',
            align: 'جانمایی',
            resizingFilmFile: 'تغییر ابعاد فیلم',
            fit: 'هم سایز کردن با فایل اصلی',
            punchPositionError: 'پانچ باید داخل محدوده مجاز باشد',
            serialPositionError: 'شماره سریال باید داخل محدوده مجاز باشد',
            topPunch: 'بالا= {0} میلیمتر',
            leftPunch: 'چپ= {0} میلیمتر',
            preset: 'از پیش تنظیم شده',
            customized: 'تنظیم دلخواه',
            addToListResultModalTitle: 'سفارش به لیست افزوده شد',
            addToListResultModalMessage: 'سفارش شما به لیست افزوده شد. شما میتوانید پروسه سفارش خود را ادامه دهید.',
            addToListResultSnackbarMessage: 'سفارش شما به لیست افزوده شد.',

            ups: 'لت',
            perforationTitle: 'سرویس اضافه (پرفراژ فاکتور)',
        },

        confirmDetail: {
            detailDescription: 'در این قسمت می‌توانید تمامی جزییات سفارش خود را ملاحظه کنید.',
            filePreview: 'پیش نمایش فایل',
            orderDetails: 'جزییات سفارش',
            quantityDetail: '{0}×{1} سری = {2}',
            productUpDimension: 'ابعاد سفارش',
            fileDimension: 'ابعاد فایل',
            dimension: '{0}×{1} میلیمتر',
            latCount: '{0} لت',
            price: 'قیمت',
            basePrice: 'قیمت پایه',
            productPrice: 'قیمت محصول',
            priceDetail: '{0}×{1} ×{2} سری = {3}',
            priceDetailWithoutSeries: '{0}×{1} = {2}',
            priceDetailWithoutUps: '{0}×{1} سری = {2}',
            priceDetailWithUps: '{0}×{1} لت = {2}',
            priceDetailWithUpsAndSeries: '{0}×{1} لت×{2} سری = {3}',
            servicesPrice: 'قیمت خدمات',
            servicesPriceDetail: '{0} قیمت خدمات×{1} سری= {2}',
            series: 'سری چاپ',
            totalPriceExVat: 'مجموع قیمت (بدون احتساب مالیات بر ارزش افزوده)',
            submitOrder: 'تایید سفارش',
            viewMore: 'نمایش بیشتر',
            viewLess: 'نمایش کمتر',
            viewList: 'نمایش لیست',
            addToCurrentList: 'افزودن به لیست',
            currentList: 'لیست سفارش‌ها',
            productDetails: 'جزییات محصول',
            totalPrice: 'مبلغ کل',
            totalProductPrice: 'مجموع قیمت محصول',
            totalExtendedServicePrice: 'مجموع قیمت خدمات',
            totalAmount: 'مجموع',
            grandTotal: 'جمع نهایی',
            status: 'وضعیت',
            resumeOrdering: 'ادامه سفارش',
            availableDimesionTooltip: 'ابعاد نهایی محصول به طور خودکار بر اساس ابعاد فایل بارگذاری شده تعیین می‌شود. جهت بارگذاری فایل روی "{0}" کلیک کنید.',
            startOrdering: 'شروع سفارش',
            customDimension: 'توجه: ابعاد وارد شده فقط به منظور استعلام قیمت است. برای تعیین اندازه نهایی، روی "{0}" کلیک کنید. ابعاد به طور خودکار بر اساس فایل بارگذاری شده تعیین می شود.',
        },

        saveResult: {
            successMessage: 'سفارش شما با موفقیت ثبت شد.',
            successDescription: 'جهت ادامه فرایند، سفارش خود را تایید کنید. در صورت عدم تایید سفارش شما معلق خواهد ماند.',
            confirmOrder: 'تایید سفارش',
            orderAnotherProduct: 'ثبت سفارش جدید',
            returnToShop: 'بازگشت به فروشگاه',
        },

        messages: {
            formatIsWrong: 'فرمت اشتباه است',
            quantityError: 'تعداد درخواستی باید ضریبی از تیراژ فرم باشد',
            isAlreadyUploaded: 'لحظاتی پیش آپلود شده است.',
            chooseFrontMasterFile: 'فایل چاپی چهار رنگ رو را انتخاب کنید',
            chooseBackMasterFile: 'فایل چاپی چهار رنگ پشت را انتخاب کنید',
            chooseFrontFilmFile: 'فیلم رو {0} را انتخاب کنید',
            chooseFilmFile: 'فایل {0} را انتخاب کنید',
            chooseFilter: 'با استفاده از موس نواحی فیلتر سفارش خود را انتخاب نمایید.',
            mandatoryExtendedServiceMessage: 'اضافه یا تنظیمات سرویس {0} اجباری است.',
        },
        tour: {
            headerDetails: 'در اینجا می‌توانید نام و جزئیات محصول انتخابی را مشاهده نمایید.',
            headerEditOption: 'با کلیک بر روی ویرایش می‌توانید مشخصات محصول را تغییر دهید.',
            masterFile: 'جهت بارگذاری فایل اینجا را کلیک نمایید.',
            filmFile: 'فایل فیلم را بر روی فایل چاپی بکشید و مطمئن شوید کاملا منطبق است.',
            chooseFileModalPreview: 'تطابق فایل خود را با راهنمای محصول بررسی نمایید.',
            chooseFileModalChangeFile: 'با کلیک بر روی تغییر فایل می‌توانید فایل دیگری آپلود نمایید.',
            chooseFileModalSave: 'اگر فایل شما کاملا مطابق راهنمای محصول می‌باشد، بر روی گزینه ذخیره کلیک نمایید.',
            chooseFileModalMatchItems: 'با استفاده از این ابزارهای تنظیم می‌توانید فایل فیلم را بر روی فایل چاپی منطبق نمایید.',
            chooseFileModalAlignment: 'شما می‌تنوانید از ابزار تراز استفاده نموده و فایل چاپی و فایل فیلم را با هم دیگر تراز نمایید',
            chooseFileModalResize: 'شما می‌توانید از ابزار تغییر سایز استفاده کرده و سایز فایل چاپی خود را تغییر دهید',
            chooseFileModalFit: 'به منظور تطبیق فایل چاپی و فایل فیلم بر روی گزینه تغییر سایز کلیک نمایید',
            extendedServiceFoldLine: 'در اینجا می‌توانید نوع خط تای سفارش خود را مشخص نمایید.',
            extendedServiceRoundedCorner: 'در اینجا دورگرد یا معمولی بودن سفارش خود را مشخص نمایید.',
            extendedServiceGripperEdge: 'در اینجا لب پنجه دار بودن یا نبودن سفارش خود را مشخص نمایید.',
            extendedServiceFilter: 'با کلیک بر روی اضافه کردن فیلتر مشخص نمایید چه قسمت‌هایی از سفارش نباید UV بخورد.',
            extendedServicePunch: 'با کلیک بر روی اضافه کردن پانچ مشخص نمایید چه قسمت‌هایی از سفارش باید پانچ بخورد.',
            extendedServiceConfirmDetails: 'تمام جزئیات محصول شامل ابعاد، قیمت، تعداد و غیره را بررسی نمایید.',
            extendedServiceConfirmSubmit: 'جهت نهایی کردن سفارش خود بر روی گزینه تایید سفارش کلیک نمایید.',
            extendedServiceModalAddedFilter: 'این فیلتر انتخاب شده است. برای جابه جایی آن می‌توانید از کلیدهای جهت دار استفاده نمایید ',
            extendedServiceModalAddedPunch: 'این پانچ انتخاب شده است. برای جابه جایی آن می‌توانید از کلیدهای جهت دار استفاده نمایید ',
            extendedServiceModalBillBookInputs: 'تعداد نسخه، نوع چاپ و نوع صحافی محصول فاکتور را انتخاب کنید',
            extendedServiceModalBillBookBindingSide: 'با توجه به نوع صحافی انتخابی جهت آن را نیز تعیین فرمایید',
            extendedServiceBillBookOptionButton: 'برای انتخاب ویژگی‌های محصول فاکتور روی این دکمه کلیک کنید.',
            extendedServiceBillBookPreviewButton: 'مشخصات محصول فاکتور انتخابی را مجددا بررسی نمایید.',
            extendedServiceModalBillBookModalContent: 'تنظیمات مربوط به پرفراژ و شماره سریال محصول فاکتور را وارد نمایید.',
            extendedServiceModalBillBookModalSummary: 'مشخصات محصول انتخابی را بررسی نمایید',
            extendedServiceModalBillBookSerialNumberRow: 'تعیین نمایید شماره سریال در کدام یک از نسخه‌ها درج گردد',
            extendedServiceModalBillBookPerforationRow: 'مشخص نمایید خط پرفراژ در کدام یک از نسخه‌ها ایجاد گردد.',
            extendedServiceModalSerialNo: 'ابتدا جهت شماره سریال خود را انتخاب نموده و سپس شروع شماره سریال را تعیین نموده و سپس بر روی دکمه افزودن کلیک نمایید',
            orderFilesPreview: 'در این بخش می‌توانید پیش نمایشی از سفارش را بر اساس مشخصات و فایل‌های انتخابی مشاهده نمایید.',
            extendedServiceModalPerforation: 'جهت خط پرفراژ محصول فاکتور را انتخاب نموده یا با جانمایی موس محل آن را روی تصویر مشخص نمایید',
            extendedServiceBillBookModalPreview: "نسخه‌های محصول فاکتور را براساس گزینه‌هایی که انتخاب کرده اید بررسی نمایید.",

            clickHere: 'برای اعمال فایل بر روی سفارش خود اینجا کلیک کنید. همچنین شما میتوانید با استفاده از موس خود نیز فایل مورد نظر را اعمال نمایید.',
            infoFile: 'سیستم آنلاین سایز و قیمت نهایی را بر اساس فایلی که شما انتخاب کرده اید محاسبه می‌نماید. آنها را چک کنید',
            addToCurrentList: 'جزئیات سفارش خود را چک کرده و از درست بودن آن اطمینان حاصل نمایید سپس بر روی افزودن به لیست کلیک کنید',
            extendedServiceInfo: 'خدمات پس از چاپ انتخاب شده به سفارش شما افزوده شد.',
            currentList: 'سفارش شما هم اکنون به لیست اضافه شده اما ثبت نگردیده است.',
            addOrder: 'در ثبت سفارش حرفه‌‌ای شما می‌توانید سفارش‌های دیگری از یک جنس را به لیست خود اضافه نمایید. اگر سفارش دیگری دارید آن را به لیست اضافه کنید.',
            viewList: 'برای مشاهده سفارش‌های اضافه شده به لیست بر روی مشاهده لیست کلیک کنید',
            submit: 'در این قسمت شما مبلغ کل سفارش‌های خود را مشاهده می‌نمایید.پس از بررسی و اطمینان از درست بودن آن، بر روی گزینه ثبت سفارش کلیک نمایید.'

        }
    }
};

export default RegularOrderTranslationFa;