import StorageHelper from './StorageHelper';
import Enumerations from '../configs/Enumerations';
import CommonHelper from './CommonHelper';
import DateHelper from './DateHelper';

class CurrentUserHelper {

    static create = (expirationDate, firstName, lastName, mobile, genderTypeId, token, persistentMobile,
        isPersistent, isAdmin, hasAutoDeliveryAccess, defaultCurrencyTypeId, countryId, cityName,
        stateName, businessPartnerCustomerIdentifier, isOfficialInvoiceEnabled, isCompany) => {
        let userInfo = StorageHelper.getDecFromStorage(Enumerations.storage.currentUserInfo);

        userInfo = {
            expirationDate: DateHelper.getDatePart(expirationDate),
            countryId: userInfo?.countryId,
            firstName,
            lastName,
            mobile,
            genderTypeId,
            token,
            persistentMobile: !!isPersistent ? persistentMobile : null,
            isPersistent,
            isAdmin,
            hasAutoDeliveryAccess,
            defaultCurrencyTypeId,
            countryId,
            cityName,
            stateName,
            businessPartnerCustomerIdentifier,
            isOfficialInvoiceEnabled,
            isCompany
        };

        StorageHelper.saveEncInStorage(Enumerations.storage.currentUserInfo, userInfo);
    }

    static set = (key, value) => {
        let userInfo = StorageHelper.getDecFromStorage(Enumerations.storage.currentUserInfo);
        userInfo = {
            ...userInfo,
            [key]: value
        }
        StorageHelper.saveEncInStorage(Enumerations.storage.currentUserInfo, userInfo);
    }

    static get = () => {
        let userInfo = StorageHelper.getDecFromStorage(Enumerations.storage.currentUserInfo);
        if (!userInfo)
            userInfo = {};

        return userInfo;
    }

    static isAuthenticated = () => !!CurrentUserHelper.get().token;

    static getFirstName = () => CurrentUserHelper.get().firstName;

    static getLastName = () => CurrentUserHelper.get().lastName;

    static getUserName = () => CurrentUserHelper.get().username;

    static getFullName = () => {
        var currentUser = CurrentUserHelper.get();
        return currentUser.firstName + ' ' + currentUser.lastName;
    }

    static getBusinessPartnerCustomerIdentifier = () => CurrentUserHelper.get()?.businessPartnerCustomerIdentifier;

    static getToken = () => CurrentUserHelper.get().token;

    static getIsAdmin = () => CurrentUserHelper.get().isAdmin;

    static getMobile = () => CurrentUserHelper.get().mobile;

    static getCountryId = () => CurrentUserHelper.get().countryId;

    static getCountryCode = () => CommonHelper.toInt(this.getMobile().split(' ')[0].substr(1), 10);

    static getMobileWithOutCountryCode = () => this.getMobile().split(' ')[1] + this.getMobile().split(' ')[2];

    static getMobileForUI = () => '+' + CurrentUserHelper.get().mobile;

    static getGenderTypeId = () => CurrentUserHelper.get().genderTypeId;

    static getCustomerMustHaveAgency = () => CurrentUserHelper.get()?.customerAgency?.customerMustHaveAgency;

    static getCustomerAgencyId = () => CurrentUserHelper.get()?.customerAgency?.customerAgencyId;

    static getHasAutoDeliveryAccess = () => CurrentUserHelper.get()?.hasAutoDeliveryAccess;

    static getDefaultCurrencyTypeId = () => CurrentUserHelper.get()?.defaultCurrencyTypeId;

    static getCityName = () => CurrentUserHelper.get()?.cityName;

    static getStateName = () => CurrentUserHelper.get()?.stateName;

    static getIsOfficialInvoiceEnabled = () => CurrentUserHelper.get()?.isOfficialInvoiceEnabled;

    static getIsCompany = () => CurrentUserHelper.get()?.isCompany;

    static remove = () => {

        const userInfo = CurrentUserHelper.get();
        let newUserInfo = {
            isPersistent: userInfo.isPersistent,
            persistentMobile: userInfo.persistentMobile,
        };

        StorageHelper.saveEncInStorage(Enumerations.storage.currentUserInfo, newUserInfo);
        StorageHelper.removeFromStorage(Enumerations.storage.userCurrency);
    }

    static getPersistentMobile = () => CurrentUserHelper.get().persistentMobile;

    static getIsPersistent = () => CurrentUserHelper.get().isPersistent;

    static addToSeenTours = (id) => {
        const alreadySeenTours = StorageHelper.getFromStorage(Enumerations.storage.tours) || [];
        const updatedSeenTours = [...alreadySeenTours, id];
        !alreadySeenTours?.includes(id) && StorageHelper.saveInStorage(Enumerations.storage.tours, updatedSeenTours);
    }

    static addAllToursInStorage = () => {
        StorageHelper.saveInStorage(Enumerations.storage.tours, Object.values(Enumerations.tourGuide))
    }

    static addToSeenFestivalCongratulationModals = (id) => {
        const alreadySeenModals = StorageHelper.getFromStorage(Enumerations.storage.festivalCongratulationModals) || [];
        const updatedSeenModals = [...alreadySeenModals, id];
        !alreadySeenModals?.includes(id) && StorageHelper.saveInStorage(Enumerations.storage.festivalCongratulationModals, updatedSeenModals);
    }

    static isTourSeen = (id) => {
        const alreadySeenTours = StorageHelper.getFromStorage(Enumerations.storage.tours);
        return alreadySeenTours?.includes(id);
    }

    static resetTour = (ids) => {
        ids.map((id) => {
            const alreadySeenTours = StorageHelper.getFromStorage(Enumerations.storage.tours);
            if (alreadySeenTours?.includes(id)) {
                alreadySeenTours.splice(alreadySeenTours.indexOf(id), 1)
                StorageHelper.saveInStorage(Enumerations.storage.tours, alreadySeenTours);
            }
        })
    }
}

export default CurrentUserHelper;