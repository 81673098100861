import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

import customizeOrder from '../../../assets/images/shoppingbag/customizeOrder.png';
import '../../../assets/sass/views/singlePages/shoppingBags.scss';
import NsLabelLine from "../../../components/labels/NsLabelLine";
import Config from "../../../configs/Config";
import Links from "../../../configs/links/AllLinks";
import { isBusinessPartnerRoute } from "../../../utilities/BusinessPartnerHelper";
import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../utilities/CultureHelper";
import HomeProductSection from "../../home/HomeProductSection";
import ShoppingBagAdvantages from "./ShoppingBagAdvantages";
import ShoppingBagExploreProduct from "./ShoppingBagExploreProduct";
import ShoppingBagFaqs from "./ShoppingBagFaqs";
import ShoppingBagGalleries from "./ShoppingBagGalleries";
import ShoppingBagMainBanner from "./main/ShoppingBagMainBanner";
import ShoppingBagMainGuidance from "./main/ShoppingBagMainGuidance";
import ShoppingBagMainHistory from "./main/ShoppingBagMainHistory";
import ShoppingBagMainPriceInquery from "./main/ShoppingBagMainPriceInquery";

class ShoppingBagMain extends React.Component {
    constructor(props) {
        super(props);
        this.onlineOrderRef = React.createRef();
        this.state = {
            selectedPriceInqueryValuesDescription: '',
            showHistory: false,
            refreshHistoriesDate: null,
            reviewHistoryId: null,
        }
    }

    componentDidMount = () => {
        !!window.history.state?.state?.onlineshoppingbag
            ? this.onlineOrderClicked()
            : this.customizeOrderClicked();
        window.history.replaceState({ onlineshoppingbag: false }, 'aaa');
    }

    customizeOrderClicked = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    onlineOrderClicked = () => {
        window.scrollTo({
            behavior: 'smooth',
            top:
                this.onlineOrderRef.current.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                50,
        });
    }

    selectedPriceInqueryValuesDescriptionChanged = (text) => this.setState({ selectedPriceInqueryValuesDescription: text });

    refreshHistories = () => this.setState({ refreshHistoriesDate: new Date() });

    toggleHistoryClicked = (toggle) => this.setState({ showHistory: toggle });

    reviewHistoryClicked = (reviewHistoryId) => this.setState({ reviewHistoryId });

    render() {
        const { refreshHistoriesDate, showHistory, reviewHistoryId, selectedPriceInqueryValuesDescription } = this.state;

        return (
            <div className="background-white-color border rounded-3 p-3 p-md-5 shopping-bag">

                <h1 className='text-color fs-3 fw-bold mb-3'>{getTranslatedRawText('product.shoppingBag.primaryImageTitle')}</h1>

                <NsLabelLine linkTo='#'
                    smallSize={true}
                    primaryTitle={getTranslatedRawText('product.shoppingBag.customizeProduct')}
                    secondaryTitle={getTranslatedRawText('product.shoppingBag.priceInquiry')} />

                <Row className="m-0 p-0 g-2 my-3">
                    <Col lg={8} >
                        <ShoppingBagMainPriceInquery isAuthenticated={!!this.props.userFullName}
                            userFullName={this.props.userFullName}
                            reviewHistoryId={reviewHistoryId}
                            selectedPriceInqueryValuesDescriptionChanged={this.selectedPriceInqueryValuesDescriptionChanged}
                            selectedPriceInqueryValuesDescription={selectedPriceInqueryValuesDescription}
                            refreshHistories={this.refreshHistories}
                            toggleHistoryClicked={this.toggleHistoryClicked} />
                    </Col>
                    <Col lg={4} className="background-white-color border rounded-3">
                        <ShoppingBagMainGuidance onClick={this.onlineOrderClicked}
                            selectedPriceInqueryValuesDescription={selectedPriceInqueryValuesDescription}
                        />
                    </Col>
                    <Col xs={12} className={!!showHistory ? 'd-block' : 'd-none'}>
                        <ShoppingBagMainHistory refreshHistoriesDate={refreshHistoriesDate}
                            toggleHistoryClicked={this.toggleHistoryClicked}
                            reviewHistoryClicked={this.reviewHistoryClicked} />
                    </Col>
                </Row>

                <ShoppingBagAdvantages isMainAdvantage={true} />

                <div ref={this.onlineOrderRef} className={clsx("mb-5", isBusinessPartnerRoute() && 'shopping-bag__mute-cover')}>
                    <HomeProductSection isAuthenticated={!!this.props.userFullName}
                        shoppingBagCategoryId={Config.shoppingBags?.shoppingBagCategoryId}
                        linkTo={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)}
                        primaryTitle={getTranslatedRawText('product.shoppingBag.onlineAvailableProducts')}
                        secondaryTitle={getTranslatedRawText('product.shoppingBag.popularSpecification')} />
                </div>
                <div className="mb-5 cursor-pointer d-none d-md-block" onClick={this.customizeOrderClicked}>
                    <ShoppingBagMainBanner bannerBackground={customizeOrder} />
                </div >

                <ShoppingBagExploreProduct isMainExplore={true} />

                <ShoppingBagFaqs isMainFaqs={true} />

                <ShoppingBagGalleries isMainGallery={true} />

            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        userFullName: state.currentUser.userFullName,
    }
}

export default connect(mapStateToProps)(ShoppingBagMain);