import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { GetApDfbTotalByStatusGedgetsService } from '../../../../../services/adminPanel/AdminDashboardService';
import EnumerationTranslationHelper from '../../../../../utilities/EnumerationTranslationHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import Enumerations from '../../../../../configs/Enumerations';
import ApBasesDfbStatusGadget from './ApBasesDfbStatusGadget';

const ApBasesDfbTotalByStatusGedgets = (props) => {

    const { parameters, refreshDate } = props;
    const [resultMessageClass, setResultMessageClass] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(
        [
            { title: getTranslatedRawText('adminPanel.common.all'), feedbackStateTypeId: Enumerations.feedbackStatus.none },
            { title: getTranslatedRawText('adminPanel.feedback.pending'), feedbackStateTypeId: Enumerations.feedbackStatus.pending },
            { title: getTranslatedRawText('adminPanel.feedback.inProgress'), feedbackStateTypeId: Enumerations.feedbackStatus.inprocess },
            { title: getTranslatedRawText('adminPanel.feedback.archive'), feedbackStateTypeId: Enumerations.feedbackStatus.archive },
        ]
    );

    useEffect(() => {
        !!refreshDate && getTotalFeedBacks();
    }, [refreshDate])

    const getTotalFeedBacks = () => {
        setIsLoading(true);
        GetApDfbTotalByStatusGedgetsService(parameters, getApDfbTotalByStatusGedgetsCallback);
    }

    const getApDfbTotalByStatusGedgetsCallback = (resultData, result, resultMessageClassModal) => {
        setIsLoading(false);
        if (result.data.hasError) setResultMessageClass(resultMessageClassModal);
        else {
            resultData.map(item => {
                item.title = EnumerationTranslationHelper.getTranslategetFeedbackState(item.userFeedbackStateTypeId);
            })
            setData(resultData);
        }
    }

    return isEmpty(data)
    ? null
    : (
        <Row>
            {data.map((item, index) =>
                <Col key={index} sm={6} lg={3} className='mb-2'>
                    <ApBasesDfbStatusGadget data={item} isLoading={isLoading} />
                </Col>
            )}
            {resultMessageClass}
        </Row>

    );
}

export default React.memo(ApBasesDfbTotalByStatusGedgets);