import React, { Component } from 'react';
import Slider from '@mui/material/Slider';

import '../../assets/sass/components/sliders/NsSliders.scss';
import CommonHelper from '../../utilities/CommonHelper';
import Config from '../../configs/Config';

class NsSlider extends Component {

	onChanged = (event, [minValue, maxValue]) => {
		CommonHelper.safeFunctionCall(this.props.onChanged, minValue, maxValue);
	};

	onChangedSingleValue = (event, value) => CommonHelper.safeFunctionCall(this.props.onChanged, value);

	onChangeCommitted = () => {
		CommonHelper.safeFunctionCall(this.props.onChangeCommitted);
	}

	render() {
		const { minValue, maxValue, value, isSingleValue, max, step, valueDisplay, maximumFilterPrice } = this.props;
		return (
			<Slider className='slider'
				value={isSingleValue ? value : [minValue, maxValue]}
				max={max || maximumFilterPrice}
				onChange={isSingleValue ? this.onChangedSingleValue : this.onChanged}
				onChangeCommitted={this.onChangeCommitted}
				valueLabelDisplay={valueDisplay || "auto"}
				step={step || 1}
				color="secondary" />
		);
	}
}

export default NsSlider;