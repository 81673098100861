import React from 'react';
import { Col, Row } from 'react-bootstrap';

import PoChoosingFile from '../mainSections/choosingFiles/PoChoosingFile';
import PoFileManager from '../mainSections/fileManagers/PoFileManager';
import PoExtendedService from '../mainSections/extendedServices/PoExtendedService';
import CommonHelper from '../../../../utilities/CommonHelper';

class PoMainSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refreshExtendedServicesDate: null,
        }
    }

    refreshExtendedServices = () => this.setState({ refreshExtendedServicesDate: new Date() });

    render() {
        const { routeId, refreshSummary, toggleProfessionalOrderTour } = this.props;

        return (
            <Row>
                {!CommonHelper.isMobileDevice() &&
                    <Col lg={7}>
                        <PoFileManager toggleProfessionalOrderTour={toggleProfessionalOrderTour} />
                    </Col>
                }
                <Col lg={5} className='ps-lg-0'>
                    <div className='d-flex flex-column'>
                        <PoChoosingFile routeId={routeId}
                            refreshSummary={refreshSummary}
                            toggleProfessionalOrderTour={toggleProfessionalOrderTour}
                            refreshExtendedServices={this.refreshExtendedServices} />

                        <div className='mt-2'>
                            <PoExtendedService routeId={routeId}
                                refreshExtendedServicesDate={this.state.refreshExtendedServicesDate}
                                refreshSummary={refreshSummary} />
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default PoMainSection;