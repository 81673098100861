import clsx from 'clsx';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import NsButton from '../../../../../components/buttons/NsButton';
import NsMenu from '../../../../../components/menu/NsMenu';
import NsTypography from '../../../../../components/text/NsTypography';
import Config from '../../../../../configs/Config';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import { currencyActionCreators } from '../../../../../redux/reducers/CurrencyReducer';
import { GetCurrenciesService } from '../../../../../services/common/CommonService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { changeCulture, getCurrentCulture, getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import StorageHelper from '../../../../../utilities/StorageHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';

class LanguageSelection extends Component {
    constructor(props) {
        super(props);
        this.allLanguages = ConstantsWithTranslation.getAllLanguages();
        this.languageOptions = this.allLanguages.filter(lng => lng.isActive === true);

        this.selectedLanguage = this.languageOptions.find(item => item.culture === getCurrentCulture()) || [];
        this.state = {
            closeMenuDate: null,
            messageClassModal: (<></>)
        };
    }

    componentDidMount = () => {
        if (!!this.props.userFullName && isEmpty(this.props.currencies))
            this.getCurrencies();
        else if (!isEmpty(this.props.currencies) && isEmpty(StorageHelper.getFromStorage(Enumerations.storage.userCurrency)))
                this.checkAndUpdateStorageCurrency(this.props.currencies);
    }

    componentDidUpdate = (prevState) => {
        if ((prevState.userFullName !== this.props.userFullName) && !!this.props.userFullName)
            this.getCurrencies();
    }

    openMenuClicked = ({ event }) => this.setState({ anchorEl: event.currentTarget });

    languageClicked = (id) => {
        const selectedLanguage = this.languageOptions.find(item => item.id == id);
        const currentCulture = getCurrentCulture();
        if (currentCulture === selectedLanguage.culture) {
            this.setState({ closeMenuDate: new Date() });
            return;
        }
        const newURL = changeCulture(this.props.location.pathname, selectedLanguage.culture);
        this.props.history.push({
            pathname: newURL,
            search: this.props.location.search
        });
        window.location.reload();
    }

    getCurrencies = () => GetCurrenciesService(this.getCurrenciesCallback);

    getCurrenciesCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasErroe)
            !isEmpty(resultData) && this.checkAndUpdateStorageCurrency(resultData);
        else
            this.setState({ messageClassModal });
    }

    checkAndUpdateStorageCurrency = (currencies) => {
        let userCurrency = StorageHelper.getFromStorage(Enumerations.storage.userCurrency);
        if (!isEmpty(currencies)) {
            if (isEmpty(userCurrency)) {
                let countryCurrencies = Config.defaultCurrency.countryCurrencies;
                let currentCurrencyId = countryCurrencies.find(item => item.countryId == CurrentUserHelper.getCountryId())?.currencyTypeId;
                let currentCurrency = currencies.find(result => result.id == currentCurrencyId);
                let defaultCurrencyId = currencies.some(result => result.id == CurrentUserHelper.getDefaultCurrencyTypeId())
                    ? CurrentUserHelper.getDefaultCurrencyTypeId()
                    : Config.defaultCurrency.id;

                userCurrency = {
                    currentCurrency: isEmpty(currentCurrency) ? currencies.find(currency => currency.id === defaultCurrencyId) : currentCurrency,
                    defaultCurrency: currencies.find(currency => currency.id === defaultCurrencyId)
                }
            } else {
                currencies.map(element => {
                    if (element.id == userCurrency.currentCurrency.id)
                        userCurrency.currentCurrency = element;
                    if (element.id == userCurrency.defaultCurrency.id)
                        userCurrency.defaultCurrency = element;
                });
            }
        }
        this.props.setDefaultCurrency({ currencies, userCurrency });
        CommonHelper.safeFunctionCall(this.props.currencyUpdated, userCurrency.defaultCurrency);
    }

    render() {
        const { className } = this.props;
        const { closeMenuDate } = this.state;

        return (
            this.languageOptions?.length > 1
                ? <NsMenu buttonClassName="p-0"
                    closeMenuDate={closeMenuDate}
                    buttonChildren={<ButtonChildren className={className} selectedLanguage={this.selectedLanguage} />}
                // anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                // transformOrigin={{ vertical: "top", horizontal: "right" }} >
                >

                    <LanguageOptions languageOptions={this.languageOptions}
                        languageClicked={this.languageClicked} />
                </NsMenu>
                : <div className='d-flex align-items-center'>
                    <div>
                        <img src={this.languageOptions[0]?.flag}
                            width="20px"
                            alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.language"), this.languageOptions[0]?.name)} />
                    </div>
                    <NsTypography variant='inherit' className={clsx(CommonHelper.isMobileDevice() ? 'black-color' : 'white-color', 'text-capitalize font-size-14 ms-2')}>
                        {this.languageOptions[0]?.name}
                    </NsTypography>
                </div>
        );
    }
}

function ButtonChildren(props) {
    return <div className='d-flex align-items-center' >
        <img src={props.selectedLanguage.flag} width="20px"
            className='me-1'
            alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.language"), props.selectedLanguage.name)} />
        <NsTypography variant='inherit' className={clsx('text-capitalize mx-1', props.className)}>
            {props.selectedLanguage.name}
        </NsTypography>
        <i className={clsx('ns-icon-expand-down', props.className)} />
    </div>
}

function LanguageOptions(props) {
    const languages = props.languageOptions.map(item => (
        <NsButton key={item.id}
            onClick={() => props.languageClicked(item.id)}>
            <img src={item.flag}
                width="20px"
                alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.language"), item.name)} />
            <NsTypography variant='inherit' className='text-capitalize black-color font-size-14 ms-2' >
                {item.name}
            </NsTypography>
        </NsButton>
    ));
    return languages;
}

const mapStateToProps = (state) => {
    return {
        currencies: state.currencies.currencies,
        userFullName: state.currentUser.userFullName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(currencyActionCreators, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageSelection));