import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsRadioButton from '../../../../../components/buttons/NsRadioButton';
import NsOptionBox from '../../../../../components/labels/NsOptionBox';
import NsTypography from '../../../../../components/text/NsTypography';
import NsTour from '../../../../../components/tour/NsTour';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import ExtendedServiceWrapper from './ExtendedServiceWrapper';
class ExtendedServiceFoldLine extends React.Component {

    constructor(props) {
        super(props);
        this.tourId = Enumerations.tourGuide.foldLine;
        this.tourSteps = RegularOrderTours.getFoldLineTour();
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = {
            showTour: !CurrentUserHelper.isTourSeen(this.tourId),
            foldLineOptions: [],
            selectedFoldLine: {},
            refreshExtendedServiceSummaryDate: null,
        };
    }

    componentDidMount = () => {
        OrderHelper.getMasterFilesWithFullInfo(this.routeId, (masterFile) => {
            let foldLineOptions = masterFile[0].frontSideFileInfo?.foldLineItems;
            this.setState({ foldLineOptions });
            if (isEmpty(foldLineOptions))
                return;
            let service = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.foldLine);
            if (!isEmpty(service?.selectedItem))
                this.setState({ selectedFoldLine: service.selectedItem });
            else
                this.foldLineOptionClicked(foldLineOptions[0], true);
        })
    }

    foldLineOptionClicked = (option, doNotAddToSeenTours) => {
        !doNotAddToSeenTours && !CurrentUserHelper.isTourSeen(this.tourId) && CurrentUserHelper.addToSeenTours(this.tourId);
        OrderHelper.updateExtendedService(this.routeId, Enumerations.extendedServices.foldLine, option);
        this.setState({ selectedFoldLine: option, refreshExtendedServiceSummaryDate: new Date(), showTour: !CurrentUserHelper.isTourSeen(this.tourId) });
    }

    render() {
        const { foldLineOptions, selectedFoldLine, refreshExtendedServiceSummaryDate, showTour } = this.state;

        return (
            <ExtendedServiceWrapper otherProps={this.props}
                routeId={this.routeId}
                isSelectedRequirements={!isEmpty(selectedFoldLine)}
                currentExtendedService={Enumerations.extendedServices.foldLine}
                refreshExtendedServiceSummaryDate={refreshExtendedServiceSummaryDate}>
                <Row className='extendedService-tour-foldLine g-0 px-3'>
                    <FoldLineOptions foldLineOptions={foldLineOptions}
                        foldLineOptionClicked={this.foldLineOptionClicked}
                        selectedFoldLine={selectedFoldLine} />
                </Row>

                {showTour && <NsTour steps={this.tourSteps}
                    id={this.tourId} />
                }
            </ExtendedServiceWrapper >
        );
    }
}

function FoldLineOptions(props) {
    if (!isEmpty(props.foldLineOptions)) {
        return props.foldLineOptions.map((option) => {
            return (
                <Col xs={6} sm={3} md={4} key={option.id} className='py-1' onClick={() => props.foldLineOptionClicked(option)}>
                    <NsOptionBox isSelected={props.selectedFoldLine?.id === option.id}>

                        <img src={option.flagImage}
                            className='pt-2'
                            width='60px'
                            height='50px'
                            alt={getTranslatedRawText("components.imagesAlt.foldLineIcons")} />

                        <div className='w-100'>
                            <NsRadioButton option={option}
                                checked={props.selectedFoldLine?.id === option.id} />
                            <div className="font-size-12 d-none d-md-block ms-3 mb-2">
                                {option.description}
                            </div>
                            <div className="font-size-12 light-text-color mt-auto ms-3">
                                {CommonHelper.currencyFormatByDefaultConfig(option.price)}
                            </div>

                        </div>
                    </NsOptionBox>
                </Col>
            );
        });
    } else {
        return <NsTypography className='red-color'>{getTranslatedRawText('common.messages.noFoldLineOption')}</NsTypography>
    }
}

export default ExtendedServiceFoldLine