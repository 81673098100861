import React, { useState } from "react";

import NsAdminPanelContentWrapper from "../../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../../components/grids/NsDataGridWithServiceCall";
import Enumerations from "../../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { GetAgreementService } from "../../../../../services/adminPanel/AdminAgreementService";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import ApAgreementDetailModal from "./ApAgreementDetailModal";

const ApAgreement = () => {

    const [callServiceDate, setCallServiceDate] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [showAgreementDetailModal, setShowAgreementDetailModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const getData = (rows, isLoading) => {
        setRows(rows);
        setIsLoading(isLoading);
    }

    const columns = [
        hasAccess([UserClaimEnumerations.getApUserAggrementById, UserClaimEnumerations.getApUserAggrementStaticPages]) && {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 160,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: true,
                editClicked: (id) => showAgreementDetailModalClicked(id)
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 400
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 90,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
            width: 160,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createBy',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 160
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 160,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editBy',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 160
        },
    ];

    const showAgreementDetailModalClicked = (selected) => {
        setSelectedRowId(selected);
        setShowAgreementDetailModal(true);
    }

    const closeModalClicked = (refreshData) => {
        setSelectedRowId(null);
        setShowAgreementDetailModal(false);
        !!refreshData && setCallServiceDate(new Date());
    }

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText('adminPanel.sidebar.agreements')}
            actionButton={{
                onClick: showAgreementDetailModalClicked,
                isLoading: isLoading,
                disabled: !hasAccess([UserClaimEnumerations.postApUserAggrement, UserClaimEnumerations.postApUserAggrementStaticPages])
            }}
            icon='ns-icon-agreements h1'>

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetAgreementService}
                parameters={{}}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showAgreementDetailModal &&
                <ApAgreementDetailModal showModal={true}
                    selectedAgreementId={selectedRowId}
                    row={rows?.filter(item => item.id === selectedRowId)[0]}
                    closeModal={closeModalClicked} />
            }
        </NsAdminPanelContentWrapper>
    )
};

export default React.memo(ApAgreement);