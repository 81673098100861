import React from "react";

import { GetNewsCategoriesService } from "../../../../../services/adminPanel/AdminNewsService";
import ApBasesCategoryContent from "../../bases/ApBasesCategoryContent";

const ApBlogCategory = () => {

    return (
        <ApBasesCategoryContent service={GetNewsCategoriesService} />
    )
};

export default React.memo(ApBlogCategory);