import Links from "../../configs/links/AllLinks"
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest } from "../bases/BaseService"

const GetDownloadCategoriesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.singlePage, Links.api.singlePage.getDownloadCategories), {}, callback)
}

const GetDownloadCategoryDetailsService = (parameters, callback) => {
    return GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsDownloadCategoryDetails), parameters, callback)
}

export {
    GetDownloadCategoriesService,
    GetDownloadCategoryDetailsService,
}