import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsCard from '../../../../components/cards/NsCard';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';
import ApBasesDashboardChartSkeleton from '../bases/ApBasesDashboardChartSkeleton';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';
import { GetTotalSalesAmountAgenciesService } from '../../../../services/adminPanel/AdminDashboardService';

const ApDaTotalSale = (props) => {
    const totalSalesOptions = ConstantsWithTranslation.getAgencyTotalSalesAmountType();
    const { parameters, refreshDate } = props;
    const [totalSalesStatus, setTotalSalesStatus] = useState(totalSalesOptions[0]?.id);
    const [data, setData] = useState({});
    const [dataDetails, setDataDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        getTotalSalesAmountAgencies();
    }, [refreshDate, totalSalesStatus]);

    const getTotalSalesAmountAgencies = () => {
        let totalSalesStatusType = totalSalesStatus !== totalSalesOptions[0]?.id ? totalSalesStatus : '';
        setIsLoading(true);
        GetTotalSalesAmountAgenciesService({ ...parameters, paymentStateType: totalSalesStatusType }, getTotalSalesAmountAgenciesCallback);
    };

    const getTotalSalesAmountAgenciesCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setDoughnutTotalSalesColor(resultData));
            setDataObject(ApBasesDashboardHelper.setDoughnutTotalSalesColor(resultData));
        } else
            setMessageClassModal(messageClassModal);
    };

    const setDataObject = (data) => {
        let secondData = data;
        let newData = [];

        secondData.labels.map(label =>
            newData.push({ label: label })
        );
        newData.map((item, index) => {
            item.value = secondData.datasets[0].data[index].value;
            item.backgroundColor = secondData.datasets[0].backgroundColor[index];
        })
        setDataDetails(newData);
    }



    return (
        <>
            {!isEmpty(dataDetails)
                ? <NsCard className='p-3 h-100 '>
                    <Row className='m-0 px-0 mb-auto align-items-center justify-content-between'>{
                        isLoading
                            ? <ApBasesDashboardChartSkeleton />
                            : <>
                                <ApBasesDashboardCharts title='adminPanel.dashboard.totalSales'
                                    height=''
                                    type='doughnut'
                                    data={data}
                                    hasSelect={false}
                                    refreshClicked={getTotalSalesAmountAgencies}
                                    selectOptions={totalSalesOptions}
                                    selectValueChanged={(value) => setTotalSalesStatus(value)}
                                    selectedItem={totalSalesStatus}
                                    selectLabel='components.advanceSearch.totalSalesStatus'
                                    isLoading={isLoading} />
                                {dataDetails.map((item, index) =>
                                    <Row key={index} className='m-0 mb-2 px-0 align-items-center'>
                                        <Col className='px-0 rounded' xs={1} style={{ backgroundColor: item.backgroundColor, width: '1rem', height: '1rem' }}></Col>
                                        <Col className='px-0 ps-3' xs={8}>{item.label}</Col>
                                        <Col className='px-0 text-end' xs={3}>{item.value.toFixed(2)} %</Col>
                                    </Row>
                                )}
                            </>
                    }
                    </Row>
                </NsCard>
                : <></>}
            {messageClassModal}
        </>
    )
}

export default React.memo(ApDaTotalSale);