import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NsImageCarousel from '../../components/carousel/NsImageCarousel';
import NsLinkImage from '../../components/images/NsLinkImage';
import NsSkeleton from '../../components/skeletons/NsSkeleton';
import Enumerations from '../../configs/Enumerations';
import { currentUserActionCreators } from '../../redux/reducers/CurrentUserReducer';
import { GetCmsHomePromoteContentsService, GetCmsSlidersService } from '../../services/singlePages/CmsService';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import HomeAnnouncementSection from './HomeAnnouncementSection';
import { getTitleMetaTagByCulture } from '../../components/metaTags/NsSetPageTitleAndDescription';

class HomeBannerSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            homeSliderImages: [],
            banners: [],
            isLoading: true,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        GetCmsSlidersService(this.getCmsSlidersCallback);
        GetCmsHomePromoteContentsService(this.getCmsHomePromoteContentsCallback);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.currentUser !== prevProps.currentUser) {
            GetCmsHomePromoteContentsService(this.getCmsHomePromoteContentsCallback);
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.currentUser !== this.props.currentUser)
            GetCmsSlidersService(this.getCmsSlidersCallback);
    }

    getCmsSlidersCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ homeSliderImages: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getCmsHomePromoteContentsCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError)
            this.setState({ banners: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        const { banners, isLoading, homeSliderImages, resultMessageClass } = this.state;

        return (
            isLoading
                ? <div>

                    <NsSkeleton isVisible={true}
                        variant={
                            [
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]
                        } />
                </div >
                : <Row className='banner'>
                    <Col xs={12} md={6} className="mb-md-2 ps-md-0 pe-md-3 m-xs-0 p-xs-0 banner__carousel">
                        <NsImageCarousel imageList={homeSliderImages} />
                    </Col>
                    <Col xs={12} md={3} className="ps-md-0 pe-md-3 m-xs-0 p-xs-0 pt-3 pt-md-0 banner__promote">
                        <Row aria-label='top promotions'>
                            <Col xs={6} md={12} className='home-top__banner' aria-label={getTranslatedRawText('home.bannerSection.homeTopPromotion1AriaLabel')}>
                                <Banner alt={CommonHelper.stringFormat(getTranslatedRawText('components.imagesAlt.homeBanner'), getTitleMetaTagByCulture)}
                                    banner={banners[0]} />
                            </Col>
                            <Col xs={6} md={12} className="home-top__banner" aria-label={getTranslatedRawText('home.bannerSection.homeTopPromotion2AriaLabel')}>
                                <Banner alt={CommonHelper.stringFormat(getTranslatedRawText('components.imagesAlt.homeBanner'), getTitleMetaTagByCulture)}
                                    banner={banners[1]} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={3} className="pt-3 pt-md-0 banner__announcement" aria-label={getTranslatedRawText('home.bannerSection.announcement')}>
                        <HomeAnnouncementSection isAuthenticated={!!this.props.currentUser} />
                    </Col>
                    {resultMessageClass}
                </Row>
        );
    }
}

function Banner(props) {
    const { banner, alt } = props;
    return <NsLinkImage imgInfo={banner}
        alt={alt}
        imgClassName='mw-100 border-outline-color-1px'
        imageSitePosition={Enumerations.imageSitePosition.homeTopPromotion} />
}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser.userFullName }
}

const mapDispachToProps = (dispatch) => {
    return bindActionCreators({ ...currentUserActionCreators }, dispatch)
}

export default connect(mapStateToProps, mapDispachToProps)(HomeBannerSection);