import React, { useEffect, useState } from "react";

import NsAdvanceSearch from "../../../../../components/advanceSearch/NsAdvanceSearch";
import NsButton from "../../../../../components/buttons/NsButton";
import ConstantsWithTranslation from "../../../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { GetApAuditExcelReportService, GetAuditGatewaysService } from "../../../../../services/adminPanel/AdminPaymentService";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import CommonHelper from "../../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import ApBaseSearchButtons from "../../../bases/ApBaseSearchButtons";
import ApBaseSupporterSelectModal from "../../../bases/ApBaseSupporterSelectModal";
import ApBaseUserSelectModal from "../../../bases/ApBaseUserSelectModal";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import DateHelper from "../../../../../utilities/DateHelper";

const ApPaymentAuditSearch = (props) => {

    const [showMore, setShowMore] = useState(false);
    const [auditGateways, setAuditGateways] = useState([]);
    const [showUserSelectModal, setShowUserSelectModal] = useState(false);
    const [showAdminSelectModal, setShowAdminSelectModal] = useState(false);
    const [user, setUser] = useState(props.customerDetails?.phoneNumber || '');
    const [changeStatusByAdmin, setChangeStatusByAdmin] = useState('');
    const [selectedAdmin, setSelectedAdmin] = useState()
    const [selectedUser, setSelectedUser] = useState(null);
    const [matchingStatus, setMatchingStatus] = useState();
    const [referenceNo, setReferenceNo] = useState();
    const [trackingNo, setTrackingNo] = useState();
    const [fileTransactionStatus, setFileTransactionStatus] = useState();
    const [websiteTransactionStatus, setWebsiteTransactionStatus] = useState();
    const [minAmount, setMinAmount] = useState();
    const [maxAmount, setMaxAmount] = useState();
    const [fileDatePaidFrom, setFileDatePaidFrom] = useState(null);
    const [fileDatePaidTo, setFileDatePaidTo] = useState(null);
    const [paymentGateway, setPaymentGateway] = useState();

    useEffect(() => {
        GetAuditGatewaysService(getAuditGatewaysCallback);
    }, [])

    const getAuditGatewaysCallback = (resultData, result) => {
        if (!result?.data?.hasError)
            setAuditGateways(resultData);
    }

    const usersModalButtonClicked = () => {
        setShowUserSelectModal(true)
    }

    const adminsModalButtonClicked = () => {
        setShowAdminSelectModal(true)
    }

    const disableUpdateMatchingStatusButton = () => CommonHelper.safeFunctionCall(props.disableUpdateMatchingStatusButton);

    const basicSearchOptionsInMobile = [
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.matchingStatus'),
            fieldName: 'matchingStatus',
            value: matchingStatus,
            items: ConstantsWithTranslation.getPaymentAuditMatchingTypes(),
            onChange: (value) => {
                setMatchingStatus(value);
                disableUpdateMatchingStatusButton()
            }
        },
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.paymentGateway'),
            fieldName: 'paymentGateway',
            value: paymentGateway,
            items: auditGateways,
            onChange: (value) => {
                setPaymentGateway(value);
                disableUpdateMatchingStatusButton();
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.trackingNo'),
            fieldName: 'trackingNo',
            value: trackingNo,
            onChange: (value) => {
                setTrackingNo(value);
                disableUpdateMatchingStatusButton();
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.referenceNo'),
            fieldName: 'referenceNo',
            value: referenceNo,
            onChange: (value) => {
                setReferenceNo(value);
                disableUpdateMatchingStatusButton();
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.datePaidFrom'),
            fieldName: 'fileDatePaidFrom',
            value: fileDatePaidFrom,
            onChange: (value) => {
                setFileDatePaidFrom(value);
                disableUpdateMatchingStatusButton();
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.datePaidTo'),
            fieldName: 'fileDatePaidTo',
            value: fileDatePaidTo,
            onChange: (value) => {
                setFileDatePaidTo(value);
                disableUpdateMatchingStatusButton();
            }
        },
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.fileTransactionStatus'),
            fieldName: 'fileTransactionStatus',
            value: fileTransactionStatus,
            items: ConstantsWithTranslation.getPaymentStatus(),
            onChange: (value) => {
                setFileTransactionStatus(value);
                disableUpdateMatchingStatusButton();
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.websiteTransactionStatus'),
            fieldName: 'websiteTransactionStatus',
            value: websiteTransactionStatus,
            items: ConstantsWithTranslation.getPaymentStatus(),
            onChange: (value) => {
                setWebsiteTransactionStatus(value);
                disableUpdateMatchingStatusButton();
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.minAmountFile'),
            fieldName: 'minAmount',
            value: minAmount,
            onChange: (value) => {
                setMinAmount(value);
                disableUpdateMatchingStatusButton();
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.maxAmountFile'),
            fieldName: 'maxAmount',
            value: maxAmount,
            onChange: (value) => {
                setMaxAmount(value);
                disableUpdateMatchingStatusButton();
            }
        },
        hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            endAdornment: <NsButton className='light-circle'
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" />
        },
        hasAccess([UserClaimEnumerations.getApSupporters]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.changeStatusByAdmin'),
            fieldName: 'changeStatusByAdmin',
            value: changeStatusByAdmin,
            disabled: true,
            onChange: (value) => setChangeStatusByAdmin(value),
            endAdornment: <NsButton className='light-circle'
                onClick={adminsModalButtonClicked}
                startIcon="ns-icon-more" />
        },
    ];

    const searchValues = {
        matchingStatus,
        referenceNo,
        trackingNo,
        fileTransactionStatus,
        websiteTransactionStatus,
        minAmount,
        maxAmount,
        fileDatePaidFrom,
        fileDatePaidTo,
        paymentGateway,
        user,
        selectedUser,
        selectedAdmin,
        changeStatusByAdmin,

    }

    const excelSearchValues = {
        "paymentAuditMatchingTypes": !isEmpty(matchingStatus) ? [matchingStatus.id] : null,
        "paymentGatewayTypes": !isEmpty(paymentGateway) ? [paymentGateway.key] : null,
        "paymentGateWayReferenceNumber": !isEmpty(referenceNo) ? referenceNo : null,
        "trackNumber": !isEmpty(trackingNo) ? trackingNo : null,
        "fromSubmitTime": DateHelper.getIsoDateTime(fileDatePaidFrom),
        "toSubmitTime": DateHelper.getIsoDateTime(fileDatePaidTo),
        "fileTransactionStatus": !isEmpty(fileTransactionStatus) ? fileTransactionStatus.id : null,
        "websiteTransactionStatus": !isEmpty(websiteTransactionStatus) ? websiteTransactionStatus.id : null,
        "minAmount": !isEmpty(minAmount) ? Number(minAmount) : null,
        "maxAmount": !isEmpty(maxAmount) ? Number(maxAmount) : null,
        "userId": !isEmpty(selectedUser) ? selectedUser.userId : null,
        "changeStateByAdminId": !isEmpty(selectedAdmin) ? selectedAdmin.userId : null,
        "showInvalidRrn": false
    }

    const showMoreClicked = () => setShowMore(!showMore);

    const searchClicked = () => CommonHelper.safeFunctionCall(props.setSearchData,
        {
            ...searchValues,
            fileDatePaidFrom: DateHelper.getIsoDateTime(fileDatePaidFrom),
            fileDatePaidTo: DateHelper.getIsoDateTime(fileDatePaidTo)
        });

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            searchClicked();
    }

    const clearButtonClicked = () => {
        setMatchingStatus();
        setReferenceNo();
        setTrackingNo();
        setFileTransactionStatus();
        setWebsiteTransactionStatus();
        setMinAmount('');
        setMaxAmount('');
        setFileDatePaidFrom(null);
        setFileDatePaidTo(null);
        setPaymentGateway();
        setUser('');
        setSelectedAdmin('');
        setSelectedUser('');
        setChangeStatusByAdmin('');
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user?.phoneNumber);
        setShowUserSelectModal(false);
        disableUpdateMatchingStatusButton();
    }

    const adminSelected = (admin) => {
        setSelectedAdmin(admin);
        setChangeStatusByAdmin(admin?.phoneNumber);
        setShowAdminSelectModal(false);
        disableUpdateMatchingStatusButton();
    }

    return <>

        <div onKeyDown={handleKeyDown}>
            <NsAdvanceSearch searchOptions={showMore ? advanceSearchOptions : CommonHelper.isMobileDevice() ? basicSearchOptionsInMobile : basicSearchOptions}
                searchValues={searchValues} mdCol={2} smCol={4} />

            <ApBaseSearchButtons searchClicked={searchClicked}
                isLoading={props.isLoading}
                showMoreHandler={showMoreClicked}
                showMore={showMore}
                clearButtonClicked={clearButtonClicked}
                excelSearchValues={excelSearchValues}
                exportExcelService={hasAccess([UserClaimEnumerations.getApAllPaymentAuditsExcelReport]) ? GetApAuditExcelReportService : ''}
            />
        </div>
        {showUserSelectModal &&
            <ApBaseUserSelectModal showModal={showUserSelectModal}
                userSelected={userSelected}
                closeModal={() => setShowUserSelectModal(false)} />
        }

        {showAdminSelectModal &&
            <ApBaseSupporterSelectModal showModal={showAdminSelectModal}
                adminSelected={adminSelected}
                closeModal={() => setShowAdminSelectModal(false)} />
        }
    </>
}

export default React.memo(ApPaymentAuditSearch);