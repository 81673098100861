import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsImage from '../../../../components/images/NsImage';
import NsModal from '../../../../components/modals/NsModal';
import NsTypography from '../../../../components/text/NsTypography';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { GetCustomerFileByIdService, GetOriginFileService } from '../../../../services/adminPanel/AdminCustomerFilesService';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBaseLabelInfo from '../../bases/ApBaseLabelInfo';
import ApBaseMapActionButtons from '../../bases/ApBaseMapActionButtons';
import ApShareSheetOrderDetailModal from '../orders/shareSheets/ApShareSheetOrderDetailModal';
import ApBaseUserInfoModal from '../users/bases/ApBaseUserInfoModal';

const ApCustomersFilesModal = (props) => {
    const { closeModal, selectedFileId } = props;
    const downloadButton = useRef();

    const [information, setInformation] = useState({});
    const [file, setFile] = useState();
    const [isShowUserInfoModal, setIsShowUserInfoModal] = useState(false);
    const [isShowFilesOrdersModal, setIsShowFilesOrdersModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>)

    useEffect(() => {
        getCustomerFile()
    }, [])

    useEffect(() => {
        if (!isEmpty(downloadButton.current?.click))
            downloadButton.current.click();
    }, [file])

    const getCustomerFile = () => {
        setIsLoading(true);
        GetCustomerFileByIdService(selectedFileId, getCustomerFileCallback);
    }

    const getCustomerFileCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setInformation({ customerFileItemInfoDto: resultData });
        } else
            setResultMessageClass(messageClassModal);
    }

    const showUserInfoModalToggle = () => setIsShowUserInfoModal(!isShowUserInfoModal)

    const showFilesOrdersModalToggle = () => setIsShowFilesOrdersModal(!isShowFilesOrdersModal)

    const downloadFileClicked = () => {
        setIsLoading(true);
        GetOriginFileService(selectedFileId, getOriginFileCallback);
    }

    const getOriginFileCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            const href = !isEmpty(resultData) && CommonHelper.createDownloadFileUrl(resultData?.fileDownloadName, resultData?.fileContents);
            setFile({ fileDownloadName: resultData?.fileDownloadName, fileContents: href });
        } else
            setResultMessageClass(messageClassModal);
        setIsLoading(false);
    }

    const headerButtons = () => {
        return [
            { wrapperClassName: 'ms-1', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: showUserInfoModalToggle, accessIds: [UserClaimEnumerations.getApCustomerById] },
            information?.customerFileItemInfoDto?.used && {
                wrapperClassName: 'ms-1', startIcon: 'ns-icon-orders', label: 'adminPanel.customersFiles.filesOrder', onClick: showFilesOrdersModalToggle,
                accessIds: [UserClaimEnumerations.getApOrderDetail]
            },
            { wrapperClassName: 'ms-1', startIcon: 'ns-icon-pdf-file', label: 'adminPanel.customersFiles.downloadFile', onClick: downloadFileClicked, accessIds: [UserClaimEnumerations.getApDownloadOriginFile] }
        ];
    }

    return (
        <NsModal maxWidth='lg'
            showModal={true}
            noSpace={true}
            closeModal={() => CommonHelper.safeFunctionCall(closeModal)}
            dialogTitle={getTranslatedRawText("adminPanel.sidebar.customersFiles")} >

            <ApBaseMapActionButtons actionButtons={headerButtons()}
                isLoading={isLoading} />

            {!!information &&
                <div className='background-color p-2 px-3'>
                    <ApBaseLabelInfo info={information}
                        isLoading={isLoading}
                        hasAccessInfo={hasAccess([UserClaimEnumerations.getApCustomerFileById])} />
                </div>
            }
            <Row className='m-0 p-3'>
                <Col xs={6} className='p-1 text-truncate text-nowrap overflow-hidden'>
                    <NsTypography variant="inherit" className="fw-bold font-size-14">{getTranslatedRawText('adminPanel.customersFiles.draftFile')}</NsTypography>
                    <NsImage className='w-100'
                        imgAlt="components.imagesAlt.imageDraft"
                        imageUrl={information?.customerFileItemInfoDto?.draft} />
                </Col>

                <Col xs={6} className='p-1 text-truncate text-nowrap overflow-hidden'>
                    <NsTypography variant="inherit" className="fw-bold font-size-14">{getTranslatedRawText('adminPanel.customersFiles.thumbnailFile')}</NsTypography>
                    <div className='d-flex'>
                        <Col xs={3} className="p-1">
                            <NsImage className='w-100'
                                imgAlt="components.imagesAlt.imageThumbnail"
                                imageUrl={information?.customerFileItemInfoDto?.thumbnail} />
                        </Col>
                    </div>
                </Col>
            </Row>

            <a ref={downloadButton} target='_blank' href={file?.fileContents}
                download={file?.fileDownloadName} />

            {
                isShowUserInfoModal &&
                <ApBaseUserInfoModal showModal={isShowUserInfoModal}
                    selectedUserId={information?.customerFileItemInfoDto?.createByUserId}
                    closeModal={showUserInfoModalToggle} />
            }

            {
                isShowFilesOrdersModal &&
                <ApShareSheetOrderDetailModal showModal={isShowFilesOrdersModal}
                    selectedOrder={{
                        id: information?.customerFileItemInfoDto?.sharedSheetOrderId,
                        userId: information?.customerFileItemInfoDto?.createByUserId
                    }}
                    closeModal={showFilesOrdersModalToggle} />
            }
            {resultMessageClass}
        </NsModal >
    )
}

export default React.memo(ApCustomersFilesModal);