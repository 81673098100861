
import { Redirect } from 'react-router-dom';
import Enumerations from '../configs/Enumerations';

import MasterFile from '../views/orders/regularOrders/mainContents/choosingFiles/MasterFile';
import FilmFile from '../views/orders/regularOrders/mainContents/choosingFiles/FilmFile';
import ExtendedServiceFoldLine from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceFoldLine';
import ExtendedServiceFilter from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceFilter';
import ExtendedServiceCornerType from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceCornerType';
import ExtendedServicePunch from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServicePunch';
import ExtendedServiceGripperEdgeType from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceGripperEdgeType';
import ExtendedServiceBillBook from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceBillBook';
import ExtendedServiceTopFolding from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceTopFolding';
import ExtendedServiceHandleType from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceHandleType';
import ConfirmDetail from '../views/orders/regularOrders/mainContents/confirmDetails/ConfirmDetail';
import RegularOrderSaveResult from '../views/orders/regularOrders/RegularOrderSaveResult';
import BusinessPartnerLayoutRoute from '../views/layouts/businessPartnerLayout/BusinessPartnerLayoutRoute';
import PdrRegularDetail from '../views/productDetails/regularDetails/PdrRegularDetail';
import Products from '../views/products/Products';
import UpPaymentsReadyToPay from '../views/profileSettings/contents/userPayments/UpPaymentsReadyToPay';
import UpPaymentsIpgResult from '../views/profileSettings/contents/userPayments/UpPaymentsIpgResult';
import Exception404 from '../views/singlePages/exceptions/Exception404';
import ShoppingBagMain from '../views/products/shoppingBags/ShoppingBagMain';
import ShoppingBagMainPriceInquery from '../views/products/shoppingBags/main/ShoppingBagMainPriceInquery';
import ShoppingBagExploreProduct from '../views/products/shoppingBags/ShoppingBagExploreProduct';
import ShoppingBagGalleries from '../views/products/shoppingBags/ShoppingBagGalleries';
import ShoppingBagFaqs from '../views/products/shoppingBags/ShoppingBagFaqs';
import BpOrders from '../views/businessPartners/businessPartnerOrders/BpOrders';

const BusinessPartnerRoutes = [

    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/category/:categoryId/:categoryName", Component: Products, breadCrumb: [Enumerations.breadCrumbs.products], title: 'common.breadcrumbs.products', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products", Component: Products, breadCrumb: [Enumerations.breadCrumbs.products], title: 'common.breadcrumbs.products', exact: true, showResetTour: true, priority: 1.0 },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/shopping-bag", Component: ShoppingBagMain, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.shoppingBag', exact: true, showResetTour: false, priority: 1.0 },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/shopping-bag/price-inquery", Component: ShoppingBagMainPriceInquery, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.shoppingBag', exact: true, showResetTour: false, priority: 1.0 },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/shopping-bag/frequently-asked-questions", Component: ShoppingBagFaqs, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.shoppingBagFaq', exact: true, showResetTour: false, priority: 1.0 },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/shopping-bag/gallery", Component: ShoppingBagGalleries, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.shoppingBagGallery', exact: true, showResetTour: false, priority: 1.0 },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/shopping-bag/explore-product", Component: ShoppingBagExploreProduct, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.shoppingBagExploreProduct', exact: true, showResetTour: false, priority: 1.0 },


    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/detail/:name", Component: PdrRegularDetail, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.roProductDetail], title: '', exact: true, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/choosing-master-file", Component: MasterFile, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: true, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/choosing-film-file-:filmNo", Component: FilmFile, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: true, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/extended-service/fold-line", Component: ExtendedServiceFoldLine, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/extended-service/filters", Component: ExtendedServiceFilter, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/extended-service/rounded-corner", Component: ExtendedServiceCornerType, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/extended-service/punch", Component: ExtendedServicePunch, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/extended-service/gripper-edge", Component: ExtendedServiceGripperEdgeType, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/extended-service/bill-book", Component: ExtendedServiceBillBook, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/extended-service/top-folding", Component: ExtendedServiceTopFolding, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/extended-service/handle-type", Component: ExtendedServiceHandleType, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/confirm-detail", Component: ConfirmDetail, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/products/:id/regular/order/save-result", Component: RegularOrderSaveResult, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false },
    { Layout: Redirect, from: "/:lng/business-partner/products/:id/regular/order/choosing", to: "/:lng/business-partner/product/:id/regular/order/choosing-master-file", breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: Redirect, from: "/:lng/business-partner/products/:id/regular/order/extended", to: "/:lng/business-partner/product/:id/regular/order/extended-service/fold-line", breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: Redirect, from: "/:lng/business-partner/products/:id/regular/order/confirm", to: "/:lng/business-partner/product/:id/regular/order/confirm-detail", breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },

    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/user-payment/:id/ready-to-pay", Component: UpPaymentsReadyToPay, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.chooseGateway', exact: false },
    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/user-payment/payment-result", Component: UpPaymentsIpgResult, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.resultPayment', exact: false },

    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/order/status", Component: BpOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.orderStatus', exact: false, },

    { Layout: BusinessPartnerLayoutRoute, path: "/:lng/business-partner/exception", Component: Exception404, breadCrumb: [], title: 'common.breadcrumbs.exception', exact: false },


];

export default BusinessPartnerRoutes;