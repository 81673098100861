import React, { Component } from "react";
import NsCard from "../../../components/cards/NsCard";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import NsSearchInput from "../../../components/inputs/NsSearchInput";
import CommonHelper from "../../../utilities/CommonHelper";

class SidebarSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: props.filters.searchInput
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.props.filters.searchInput !== nextProps.filters.searchInput)
                this.setState({ searchText: nextProps.filters.searchInput });
    }

    handleSearchTextChanged = (value) => {
        this.setState({ searchText: value }, 
            () => CommonHelper.safeFunctionCall(this.props.searchMobileFilter, this.state.searchText));
    }

    searchFormClicked = () => {
        CommonHelper.safeFunctionCall(this.props.searchFilter, this.state.searchText);
    }

    render() {
        const { searchText } = this.state;
        const { hasSearchIcon } = this.props;

        return (
            <NsCard className='p-3 search'>
                <p className='section-title'>
                    {getTranslatedRawText('product.sideBarTitles.searchInResult')}
                </p>
                <NsSearchInput hasSearchIcon={hasSearchIcon}
                    value={searchText}
                    searchFormClicked={this.searchFormClicked}
                    handleSearchTextChanged={this.handleSearchTextChanged}
                    placeHolder={getTranslatedRawText('product.common.searchPlaceHolder')}
                    className='background-color'
                    inputClassName='background-color'
                />
            </NsCard>
        );
    }
}

export default SidebarSearch;