import React from 'react';

import { getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import Enumerations from '../../../configs/Enumerations';
import UrlHelper from '../../../utilities/UrlHelper';
import Links from '../../../configs/links/AllLinks';
import AccountWrapper from '../AccountWrapper';
import BaseSigninContent from '../bases/BaseSigninContent';
import Config from '../../../configs/Config';
import { getConfigObjectByCulture } from '../../../utilities/ConfigHelper';
import NsSetPageTitleAndDescription from '../../../components/metaTags/NsSetPageTitleAndDescription';

class SignIn extends React.Component {

    handleBackUrl = () => {
        const URLParameters = UrlHelper.getRouteParameters(this.props.location.search);
        !isEmpty(URLParameters['back-url'])
            ? this.props.history.push(URLParameters['back-url'])
            : this.props.history.push(getUrlWithCurrentCulture(Links, Links.home));
    }

    render() {
        return (
            <AccountWrapper layoutType={Enumerations.loginLayoutType.signIn}>

                <div className="white-panel g-0 d-flex flex-column my-sm-n5 w-100 py-5 px-4">
                    <BaseSigninContent handleBackUrl={this.handleBackUrl} />
                </div>
                <NsSetPageTitleAndDescription pageTitle='account.common.login'
                    description={getConfigObjectByCulture(Config.metaTags)?.descriptions.signIn} />
            </AccountWrapper>
        );
    }
}

export default SignIn;