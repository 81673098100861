import clsx from 'clsx';
import React from 'react';
import {
    FormControl,
    InputLabel,
    Input,
    IconButton,
    InputAdornment,
    FormHelperText
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { getTranslatedRawText } from './../../utilities/CultureHelper';
import { passwordIsValid, englishCharacterIsValid, isContainNumber, isContainCharacter } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';
import '../../assets/sass/components/inputs/TextField.scss';
import Enumerations from '../../configs/Enumerations';

class NsPasswordInput extends React.Component {

    constructor(props) {
        super(props);
        this.password = React.createRef();
        this.state = {
            isPersistent: !!CurrentUserHelper.getIsPersistent() ? 'off' : 'new-password',
            password: '',
            isPasswordVisible: false,
            helperText: '',
            showValidationDate: null,
            invisibleHelperText: this.getDefaultHelperText(),
        };
    }

    getDefaultHelperText = () => {
        return CommonHelper.stringFormat(getTranslatedRawText("common.messages.enter"), this.props.label);
    }

    passwordChanged = (event) => {
        let password = event.target.value.trim();
        let result = this.getIsValidAndInvisibleHelperTextPassword(password);
        this.setState({
            password,
            invisibleHelperText: result.invisibleHelperText,
            helperText: ''
        },
            () => CommonHelper.safeFunctionCall(this.props.passwordChanged, password, result.isValid));
    }

    isPasswordVisibleClicked = () => {
        this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    }

    getIsValidAndInvisibleHelperTextPassword = (password) => {

        const result = {
            invisibleHelperText: '',
            isValid: false
        };

        if (password === null) {
            result.invisibleHelperText = '';
            result.resultisValid = false;
        }
        if (password.length < 6) {
            result.invisibleHelperText = getTranslatedRawText('components.passwordInput.passwordRegex');
            result.resultisValid = false;
        }
        else if (!englishCharacterIsValid(password)) {
            result.invisibleHelperText = getTranslatedRawText('components.passwordInput.persianPasswordRegex');
            result.isValid = false;
        }
        else if (!isContainNumber(password)) {
            result.invisibleHelperText = getTranslatedRawText('components.passwordInput.needNumber');
            result.isValid = false;
        }
        else if (!isContainCharacter(password)) {
            result.invisibleHelperText = getTranslatedRawText('components.passwordInput.needCharacter');
            result.isValid = false;
        }
        else {
            result.invisibleHelperText = '';
            result.isValid = true;
        }

        return result;
    }

    UNSAFE_componentWillReceiveProps(newProps) {

        if (newProps.showValidationDate !== this.state.showValidationDate)
            this.setState({
                helperText: this.state.invisibleHelperText,
                showValidationDate: newProps.showValidationDate
            });

        if (newProps.showMatchValidationDate !== this.props.showMatchValidationDate)
            this.setState({ helperText: getTranslatedRawText("components.passwordInput.passwordNotMatch") });
    }

    handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(this.props.enterKeyClicked);
    }
    render() {
        const { label, className } = this.props;
        return (
            <FormControl onKeyDown={this.handleKeyDown} size="small" required className={clsx('ns-numeric ns-password not-rtl', this.props.className)}>
                <InputLabel shrink>{this.props.label}</InputLabel>
                <Input autoFocus={this.props.autoFocus}
                    inputRef={this.password}
                    type={this.state.isPasswordVisible ? 'text' : 'password'}
                    value={this.state.password}
                    autoComplete='new-password'
                    onChange={this.passwordChanged}
                    disabled={!!this.props.disabled}
                    label={label}
                    className={className}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                style={{ width: "4px", marginRight: '2px' }}
                                aria-label="toggle password visibility"
                                onClick={this.isPasswordVisibleClicked} >
                                {this.state.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    } />
                <FormHelperText className='red-color mb-md-2'>{this.state.helperText}</FormHelperText>
            </FormControl>
        );
    }
}

export default NsPasswordInput;