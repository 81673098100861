import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsTypography from '../text/NsTypography';

class NsCountDownTimerLabel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            durationInSeconds: props.currentCashbackInfo.remainingMinutes * 60,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    componentDidMount() {
        this.updateCountdown();
        this.interval = setInterval(this.updateCountdown, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    updateCountdown = () => {
        const { durationInSeconds } = this.state;

        if (durationInSeconds <= 0) {
            clearInterval(this.interval);
            return;
        }

        const days = Math.floor(durationInSeconds / (60 * 60 * 24));
        const hours = Math.floor((durationInSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((durationInSeconds % (60 * 60)) / 60);
        const seconds = durationInSeconds % 60;

        this.setState((prevState) => {
            return {
                durationInSeconds: prevState.durationInSeconds - 1,
                days,
                hours,
                minutes,
                seconds
            };
        });
    };

    padWithZero = (number) => {
        return number < 10 ? `0${number}` : `${number}`;
    };

    render() {
        const { days, hours, minutes, seconds } = this.state;
        const { containerClassName, countdownClassName } = this.props;

        return (
            <div className={clsx('not-rtl', containerClassName)}>
                <div >
                    <Row className='justify-content-around d-flex pb-1'>
                        <Col xs={12} md={8} lg={12} >
                            <Row className={clsx('d-flex justify-content-between', countdownClassName)}>
                                <Col className='d-flex flex-column align-items-center px-0'>
                                    <NsTypography variant='inherit' className='festival__countdown__date-box fw-bold'>{this.padWithZero(days)}</NsTypography>
                                    <NsTypography variant='inherit' className='font-size-12'>{getTranslatedRawText('festival.countdown.days')}</NsTypography>
                                </Col>
                                <span className='w-auto festival__countdown__date-box px-0'>:</span>
                                <Col className='d-flex flex-column align-items-center px-0'>
                                    <NsTypography variant='inherit' className='festival__countdown__date-box fw-bold'>{this.padWithZero(hours)}</NsTypography>
                                    <NsTypography variant='inherit' className='font-size-12'>{getTranslatedRawText('festival.countdown.hours')}</NsTypography>
                                </Col>
                                <span className='w-auto festival__countdown__date-box px-0'>:</span>
                                <Col className='d-flex flex-column align-items-center px-0'>
                                    <NsTypography variant='inherit' className='festival__countdown__date-box fw-bold'>{this.padWithZero(minutes)}</NsTypography>
                                    <NsTypography variant='inherit' className='font-size-12'>{getTranslatedRawText('festival.countdown.minutes')}</NsTypography>
                                </Col>
                                <span className='w-auto festival__countdown__date-box px-0'>:</span>
                                <Col className='d-flex flex-column align-items-center px-0'>
                                    <NsTypography variant='inherit' className='festival__countdown__date-box fw-bold'>{this.padWithZero(seconds)}</NsTypography>
                                    <NsTypography variant='inherit' className='font-size-12'>{getTranslatedRawText('festival.countdown.seconds')}</NsTypography>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default NsCountDownTimerLabel;