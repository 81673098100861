import React from 'react';

import NsExportExcelReportButton from '../../../components/downloadFiles/NsExportExcelReportButton';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsButton from '../../../components/buttons/NsButton';

const ApBaseSearchButtons = (props) => {

    const getFromPbxClicked = () => { }
    return (
        <div className='d-flex justify-content-between mb-3 mt-2'>
            <div className='d-flex'>
                {!!props.hasExcelExport && <NsButton label={getTranslatedRawText('regularOrder.common.exportExcel')}
                    size='small'
                    onClick={props.excelButtonClicked}
                    className='secondary-outline ms-1'
                    loading={props.isLoading}
                    disabled={props.isLoading}
                    startIcon='ns-icon-excel' />}

                {false && <NsButton size='small'
                    label={getTranslatedRawText('adminPanel.buttons.getFromPbx')}
                    onClick={getFromPbxClicked}
                    className='secondary ms-1'
                    loading={props.isLoading}
                    disabled={props.isLoading}
                    startIcon={'ns-icon-pbx'} />}
            </div>
            <div className='d-flex me-auto'>
                <NsButton label={getTranslatedRawText('common.search')}
                    size='small'
                    onClick={props.searchClicked}
                    className='secondary'
                    loading={props.isLoading}
                    disabled={props.isLoading}
                    startIcon='ns-icon-search' />

                <NsButton label={getTranslatedRawText('regularOrder.common.clear')}
                    size='small'
                    onClick={props.clearButtonClicked}
                    className='secondary-outline'
                    wrapperClassName='ms-1'
                    loading={props.isLoading}
                    disabled={props.isLoading}
                    startIcon='ns-icon-clear' />
                {(!isEmpty(props.excelSearchValues) && !isEmpty(props.exportExcelService)) && (
                    <NsExportExcelReportButton
                        searchValues={props.excelSearchValues}
                        isAllOrder={false}
                        isLoading={props.isLoading}
                        disabled={props.isLoading}
                        service={props.exportExcelService}
                        buttonSize='small'
                        wrapperClassName='ms-1'
                    />
                )}
                {!!props.showMoreHandler && <NsButton onClick={props.showMoreHandler}
                    size='small'
                    className='secondary-outline'
                    wrapperClassName='ms-1'
                    loading={props.isLoading}
                    disabled={props.isLoading}
                    startIcon={props.showMore ? 'ns-icon-expand-up' : 'ns-icon-expand-down'} />}
            </div>
        </div>
    );
};

export default React.memo(ApBaseSearchButtons);