import Links from "../../configs/links/AllLinks";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { GetRequest } from "../bases/BaseService";

const GetRandomTagsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.singlePage, Links.api.singlePage.getRandomTags), {}, callback)
}

export {
    GetRandomTagsService
};