import React, { useState } from 'react';

import NsButton from '../../../../components/buttons/NsButton';
import NsCard from '../../../../components/cards/NsCard';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import Enumerations from '../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { GetInactiveUsersService } from '../../../../services/adminPanel/AdminDashboardService';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseUserInfoModal from '../../managements/users/bases/ApBaseUserInfoModal';

const ApDuInactiveUsers = (props) => {

    const { refreshDate, parameters } = props;
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showCustomerDetailModal, setShowCustomerDetailModal] = useState(false);
    const columns = [
        (hasAccess([UserClaimEnumerations.getApDuInactiveUsers])) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (user) => {
                    return <NsButton className='secondary-text '
                        startIcon='ns-icon-invoice-details'
                        onClick={() => customerDetailClicked(user.userId)} />
                }
            }
        },
        {
            field: 'registeredAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitTime'),
            width: 150,
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'fullName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 390
        },
        {
            field: 'mobile',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
            width: 200,
        },
        {
            field: 'companyName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.companyName'),
            width: 300
        },
        {
            field: 'accountStatus',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.accountStatus'),
            width: 130,
            dataType: Enumerations.gridType.accountStatus,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div className={props.accountStatus ? 'success-color' : 'red-color'}>{props.accountStatus ? getTranslatedRawText('adminPanel.usersInfo.active') : getTranslatedRawText('adminPanel.usersInfo.block')}</div>
                }
            }
        },
        {
            field: 'accountType',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.accountType'),
            width: 150,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    switch (props.accountType) {
                        case Enumerations.accountType.personal:
                            return getTranslatedRawText('adminPanel.usersInfo.personal');
                        case Enumerations.accountType.business:
                            return getTranslatedRawText('adminPanel.usersInfo.business');
                        default:
                            return;
                    }
                }
            }
        }
    ];

    const customerDetailClicked = (id) => {
        setSelectedUserId(id);
        setShowCustomerDetailModal(true);
    }

    return (
        <NsCard className='p-3 h-100'>
            <div className='fw-bold mb-4'>{getTranslatedRawText('adminPanel.dataGridColumn.inactiveUsers')}</div>

            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={GetInactiveUsersService}
                parameters={{
                    'Filter.SubmitTimeFrom': parameters.submitTimeFrom,
                    'Filter.SubmitTimeTo': parameters.submitTimeTo,
                }}
                columnDefs={columns}
                hasPagination={true} />

            {showCustomerDetailModal &&
                <ApBaseUserInfoModal showModal={true}
                    selectedUserId={selectedUserId}
                    closeModal={() => setShowCustomerDetailModal(false)} />
            }

        </NsCard>
    );
};

export default React.memo(ApDuInactiveUsers);