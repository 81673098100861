import React from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import Config from '../../configs/Config';
import Enumerations from '../../configs/Enumerations';
import { currentUserActionCreators } from '../../redux/reducers/CurrentUserReducer';
import CommonHelper from '../../utilities/CommonHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';
import DelayedTaskHelper from '../../utilities/DelayedTaskHelper';

class NsChat extends React.PureComponent {

    constructor(props) {
        super(props);
        this.setUserInfoDelayed = new DelayedTaskHelper(this.setUserInfo, 1000);
        this.defaultChatService = Config.defaultChatService;
        this.userName = CurrentUserHelper.getFullName();
        this.userMobile = CurrentUserHelper.getMobile();
        this.cityName = CurrentUserHelper.getCityName();
        this.stateName = CurrentUserHelper.getStateName();
    }

    componentDidMount = () => window.addEventListener('goftino_ready', this.setUserInfoDelayed.run());

    setUserInfo = () => {
        !!window?.Goftino && CommonHelper.isMobileDevice() && window.Goftino.setWidget({
            marginBottom: 50,
        })
        if (!this.props.isSetUserInChatService && !!this.userName && !!this.userMobile) {
            !!window?.Goftino && window.Goftino.setUser({
                name: this.userName,
                phone: this.userMobile,
                about: `${this.stateName}-${this.cityName}`,
                forceUpdate: true
            });
            this.props.setUserInChatService();
        }
    }

    render() {
        return (this.defaultChatService === Enumerations.chats.goftino
            ? <Helmet>
                <script async="" src={`https://www.goftino.com/widget/${Config.chatApiKeys.goftinoApiKey}`} ></script>
            </Helmet >

            : null
        )
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setUserInChatService: () => dispatch(currentUserActionCreators.setCurrentUserInChatService())
    }
}

export default connect(null, mapDispatchToProps)(NsChat);