import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetBusinessPartnerDetailService, GetCustomerDetailService, GetSupporterDetailService, GetUserFieldVisibilityTypeService } from '../../../../../services/adminPanel/AdminUserService';
import EnumerationTranslationHelper from '../../../../../utilities/EnumerationTranslationHelper';
import ApDashboardFinancial from '../../../dashboards/financialDashboard/ApDashboardFinancial';
import { ApUsersFinancialBalanceSheetModal } from '../../reports/ApUsersFinancialBalanceSheet';
import ApBusinessPartnerEmbedCodeModal from '../businessPartners/ApBusinessPartnerEmbedCodeModal';
import CashbackDetailModal from '../../../dashboards/cashbackDashboard/CashbackDetailModal';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import ApShareSheetOrderModal from '../../orders/shareSheets/ApShareSheetOrderModal';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApAgreementsHistoryModal from '../customers/ApAgreementsHistoryModal';
import ApCustomerFeedbacksModal from '../customers/ApCustomerFeedbacksModal';
import ApBaseUserRemainingLimitModal from './ApBaseUserRemainingLimitModal';
import ApAddSupporterUserModal from '../supporters/ApAddSupporterUserModal';
import ApBaseUserProfileBarcodeModal from './ApBaseUserProfileBarcodeModal';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApBaseUserGrantedCreditModal from './ApBaseUserGrantedCreditModal';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import ApUserDepartmentModal from '../supporters/ApUserDepartmentModal';
import NsLabelBox from '../../../../../components/labels/NsLabelBox';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import ApBaseUserEditModal from '../bases/ApBaseUserEditModal';
import ApBaseUserProfileModal from './ApBaseUserProfileModal';
import DateHelper from '../../../../../utilities/DateHelper';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import ApPaymentModal from '../../payments/ApPaymentModal';

const ApBaseUserInfoModal = (props) => {

    const { roleType } = props;
    const [userDetails, setUserDetails] = useState({});
    const [userNotFound, setUserNotFound] = useState(false);
    const [fieldVisibilityType, setFieldVisibilityType] = useState({});
    const [showUserEditModal, setShowUserEditModal] = useState(false);
    const [showAddSupporterUserModal, setAddShowSupporterUserModal] = useState(false);
    const [showGrantedCreditModal, setShowGrantedCreditModal] = useState(false);
    const [showBusinessPartnerEmbedCodeModal, setShowBusinessPartnerEmbedCodeModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [showUserOrderModal, setShowUserOrderModal] = useState(false);
    const [showUserPaymentModal, setShowUserPaymentModal] = useState(false);
    const [showUserBalanceSheetModal, setShowUserBalanceSheetModal] = useState(false);
    const [showUserAgreementsHistoryModal, setShowUserAgreementsHistoryModal] = useState(false);
    const [showRemainingLimit, setShowRemainingLimit] = useState(false);
    const [showCashbackModal, setShowCashbackModal] = useState(false);
    const [isShowFinancialDashboardModal, setIsShowFinancialDashboardModal] = useState(false);
    const [isShowUsersFeedbacksModal, setIsShowUsersFeedbacksModal] = useState(false);
    const [showUserProfileBarcodeModal, setShowUserProfileBarcodeModal] = useState(false);
    const [showUserProfileModal, setShowUserProfileModal] = useState(false);
    const [showUserDepartmentModal, setShowUserDepartmentModal] = useState(false);
    const hasBusinessPartnerRole = userDetails?.roles?.includes(Enumerations.role.businessPartner);

    useEffect(() => {
        getUserDetails();
    }, []);

    useEffect(() => {
        !isEmpty(userDetails) && getUserFieldVisibilityType();
    }, [userDetails]);

    const getUserDetails = () => {
        console.log(props.selectedUserId);
        setIsLoading(true);
        switch (roleType) {
            case Enumerations.role.businessPartner: GetBusinessPartnerDetailService(props.selectedUserId, getUserDetailCallback);
                break;
            case Enumerations.role.supporter: GetSupporterDetailService(props.selectedUserId, getUserDetailCallback);
                break;
            default: GetCustomerDetailService(props.selectedUserId, getUserDetailCallback);
                break;
        }
    }

    const getUserFieldVisibilityType = () => {
        setIsLoading(true);
        let parameters = {
            pageType: Enumerations.pageType.userInformationInAdminPanel,
            accountType: userDetails.accountType,
            isOfficialInvoiceEnabled: userDetails.isOfficialInvoiceEnabled
        };
        GetUserFieldVisibilityTypeService(userDetails.userId, parameters, getUserFieldVisibilityTypeCallback);
    }

    const getUserFieldVisibilityTypeCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result?.data?.hasError)
            setFieldVisibilityType(resultData);
        else
            setResultMessageClass(resultMessageClass);
    }

    const getUserDetailCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError)
            !!resultData ? setUserDetails(resultData) : setUserNotFound(true);
        else {
            setResultMessageClass(messageClassModal);
        }
    }

    const handlers = {

        editUserClicked: () => setShowUserEditModal(true),

        editAccessClicked: () => setAddShowSupporterUserModal(true),

        embedCodeModalClicked: () => setShowBusinessPartnerEmbedCodeModal(true),

        closeUserEditModal: () => setShowUserEditModal(false),

        closeAddSupporterUserModalClicked: () => setAddShowSupporterUserModal(false),

        closeUserBalanceSheetModal: () => setShowUserBalanceSheetModal(false),

        closeUserAgreementsHistoryModal: () => setShowUserAgreementsHistoryModal(false),

        closeBusinessPartnerEmbedCodeModal: () => setShowBusinessPartnerEmbedCodeModal(false),

        userOrderClicked: () => setShowUserOrderModal(!showUserOrderModal),

        userPaymentClicked: () => setShowUserPaymentModal(!showUserPaymentModal),

        userBalanceSheetClicked: () => setShowUserBalanceSheetModal(!showUserBalanceSheetModal),

        userAgreementsHistoryClicked: () => setShowUserAgreementsHistoryModal(!showUserAgreementsHistoryModal),

        RemainingLimitClicked: (refreshContent) => {
            setShowRemainingLimit(!showRemainingLimit);
            !!refreshContent && getUserDetails();
        },

        closeModalClicked: () => setShowCashbackModal(false),

        cashbackModalClicked: () => setShowCashbackModal(!showCashbackModal),

        toggleUserProfileBarcodeModal: () => setShowUserProfileBarcodeModal(!showUserProfileBarcodeModal),

        userProfileClicked: (hasError, message) => {
            setShowUserProfileModal(!showUserProfileModal);
            !hasError && setResultMessageClass(message);
        },

        toggleUserDepartmentModal: () => setShowUserDepartmentModal(!showUserDepartmentModal),

        toggleGrantedCreditModal: (confirm, resultMessageClassModal) => {
            setShowGrantedCreditModal(!showGrantedCreditModal);
            if (!!confirm) {
                setResultMessageClass(resultMessageClassModal);
                getUserDetails();
            }
        }

    }

    const { editUserClicked, editAccessClicked, embedCodeModalClicked, toggleGrantedCreditModal, closeAddSupporterUserModalClicked, closeUserBalanceSheetModal, closeUserEditModal, userOrderClicked, userPaymentClicked, userBalanceSheetClicked, userAgreementsHistoryClicked,
        closeUserAgreementsHistoryModal, closeBusinessPartnerEmbedCodeModal, RemainingLimitClicked, closeModalClicked, cashbackModalClicked, toggleUserProfileBarcodeModal, userProfileClicked, toggleUserDepartmentModal } = handlers;

    const userEditSaveClicked = () => {
        setShowUserEditModal(false);
        getUserDetails();
    }

    const userFinancialDashboardClicked = () => setIsShowFinancialDashboardModal(true);

    const usersFeedbacksClicked = () => setIsShowUsersFeedbacksModal(true);

    const headerButtons = () => {
        switch (roleType) {
            case Enumerations.role.businessPartner: return [
                //role badan dorost shavadd
                hasAccess([UserClaimEnumerations.editApCustomer]) && { wrapperClassName: '', startIcon: 'ns-icon-edit', label: 'adminPanel.common.editUser', onClick: editUserClicked },
                hasAccess([UserClaimEnumerations.editApCustomer]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-api-key', label: 'adminPanel.usersInfo.BusinessPartnerEmbedCode', onClick: embedCodeModalClicked },
            ]
            case Enumerations.role.supporter: return [
                hasAccess([UserClaimEnumerations.editApClaimValueTypes]) && { wrapperClassName: '', startIcon: 'ns-icon-edit', label: 'adminPanel.usersInfo.editAccess', onClick: editAccessClicked },
                hasAccess([UserClaimEnumerations.editApSupporter]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-edit', label: 'adminPanel.common.editUser', onClick: editUserClicked },
                { wrapperClassName: 'ms-1', startIcon: 'ns-icon-coach-marks', label: 'adminPanel.usersInfo.employeesProfile', onClick: userProfileClicked },
                hasAccess([UserClaimEnumerations.getApUserDepartmentsTree]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-site-map', label: 'adminPanel.sidebar.departments', onClick: toggleUserDepartmentModal },
            ]
            default: return [
                hasAccess([UserClaimEnumerations.editApCustomer]) && { wrapperClassName: '', startIcon: 'ns-icon-edit', label: 'adminPanel.common.editUser', onClick: editUserClicked },
                hasAccess([UserClaimEnumerations.getApOrders]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-orders', label: 'adminPanel.common.usersOrder', onClick: userOrderClicked },
                hasAccess([UserClaimEnumerations.getApPayments]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-payments', label: 'adminPanel.common.usersPayments', onClick: userPaymentClicked },
                hasAccess([UserClaimEnumerations.getApUsersDebitCredit]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-reports', label: 'adminPanel.common.usersDebitCredit', onClick: userBalanceSheetClicked },
                hasAccess([UserClaimEnumerations.getApUserAggrementSignUserInfo, UserClaimEnumerations.getApUserAggrementSignHistories]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-agreement-histories', label: 'components.advanceSearch.agreementsHistory', onClick: userAgreementsHistoryClicked },
                { wrapperClassName: 'ms-1', startIcon: 'ns-icon-dashboard', label: 'adminPanel.dashboard.financialDashboard', onClick: userFinancialDashboardClicked },
                hasAccess([UserClaimEnumerations.getApFeedbackAnnouncements, UserClaimEnumerations.getApFeedbackShareSheetOrders]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-users-feedback', label: 'adminPanel.usersInfo.usersFeedback', onClick: usersFeedbacksClicked },
                hasBusinessPartnerRole && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-api-key', label: 'adminPanel.usersInfo.BusinessPartnerEmbedCode', onClick: embedCodeModalClicked },
                false && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-api-key', label: 'adminPanel.usersInfo.userProfile', onClick: toggleUserProfileBarcodeModal },
                { wrapperClassName: 'ms-1', startIcon: 'ns-icon-discount', label: 'adminPanel.dataGridColumn.cashback', onClick: cashbackModalClicked },
                hasAccess([UserClaimEnumerations.editApUserCredit]) && { startIcon: 'ns-icon-granted-credit', label: 'adminPanel.usersInfo.grantedCredit', onClick: toggleGrantedCreditModal },
            ]
        }
    }

    const getMessageClassModal = (message) => setResultMessageClass(message);

    const userFullNameData = () => ({
        fullName: `${userDetails?.firstName} ${userDetails?.lastName}`
    })

    const financialBalanceModalUserData = () => ({
        accountStatus: userDetails?.accountStatus ? getTranslatedRawText('adminPanel.usersInfo.active') : getTranslatedRawText('adminPanel.usersInfo.block'),
        fullName: `${userDetails?.firstName} ${userDetails?.lastName}`,
        userId: userDetails?.userId
    })

    return (
        <NsModal
            maxWidth='lg'
            showModal={props.showModal}
            closeModal={props.closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.usersInfo.displayUserInfo")}
            dialogContentClassName={!!userNotFound && 'd-flex align-items-center justify-content-center'}>
            {!!userNotFound &&
                <div className='d-flex position-absolute justify-content-center align-items-center z-1 mx-2'>
                    <i className='ns-icon-exclamation warning-color me-1 font-size-24' />
                    <div>{getTranslatedRawText('adminPanel.usersInfo.userNotFoundHint')}</div>
                </div>}
            <div className={!!userNotFound && 'blur'}>
                <ApBaseMapActionButtons actionButtons={headerButtons()}
                    isLoading={isLoading} />

                <div className='background-color p-2'>
                    <ApBaseLabelInfo info={CommonHelper.removePropertyFromObject(userDetails, 'description')}
                        isLoading={isLoading} hasAccessInfo={hasAccess([UserClaimEnumerations.getApCustomerById])} />
                </div>
                {isLoading
                    ? <NsSkeleton isVisible={true}
                        variant={[Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect]} />
                    : <Row className='p-3 m-0'>
                        <div className='fw-bold py-1 pb-3'>{getTranslatedRawText('adminPanel.usersInfo.accountSettings')}</div>
                        <Row className='p-0 m-0'>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.accountStatus')}
                                    value={userDetails?.accountStatus ? getTranslatedRawText('adminPanel.usersInfo.active') : getTranslatedRawText('adminPanel.usersInfo.block')} />
                            </Col>
                            {!userDetails?.accountStatus && !!userDetails?.lockoutEnd &&
                                <Col md={3} xs={6} className='p-1 m-0'>
                                    <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.inactiveUntil')}
                                        value={DateHelper.getDateAndTime(userDetails?.lockoutEnd)} />
                                </Col>
                            }
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.mobileActivation')}
                                    value={userDetails?.phoneNumberConfirmed ? getTranslatedRawText('adminPanel.usersInfo.confirmed') : getTranslatedRawText('adminPanel.usersInfo.notConfirmed')} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.officialInvoiceIssue')}
                                    value={userDetails?.isOfficialInvoiceEnabled ? getTranslatedRawText('adminPanel.usersInfo.active') : getTranslatedRawText('adminPanel.usersInfo.inactive')} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.detailLedger')}
                                    value={userDetails?.detailLedgerId} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('components.advanceSearch.agreementStatus')}
                                    value={userDetails?.agreementStatus ? getTranslatedRawText('components.advanceSearch.agree') : getTranslatedRawText('components.advanceSearch.disAgree')} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0 cursor-pointer'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.remainingLimit')}
                                    value={<span>
                                        {CommonHelper.currencyFormatByDefaultConfig(userDetails?.userCredit?.remainingLimit)}
                                    </span>}
                                    onClick={RemainingLimitClicked} />
                            </Col>
                        </Row>
                        <Row className='p-0 m-0'>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.accountType')}
                                    value={EnumerationTranslationHelper.getTranslateAccountType(userDetails?.accountType)} />
                            </Col>
                            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType) &&
                                <Col md={3} xs={6} className='p-1 m-0 text-truncate text-nowrap overflow-hidden'>
                                    <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.companyName')}
                                        value={userDetails?.companyName} />
                                </Col>
                            }
                            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType) &&
                                <Col md={3} xs={6} className='p-1 m-0'>
                                    <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.licenseNumber')}
                                        value={userDetails?.licenceNumber} />
                                </Col>
                            }
                            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType) &&
                                <Col md={3} xs={6} className='p-1 m-0 text-truncate text-nowrap overflow-hidden'>
                                    <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.TRN')}
                                        value={userDetails?.countryId === Enumerations.countryId.oman ? getTranslatedRawText('common.omanTrn') + ' ' + userDetails?.trn : userDetails?.trn} />
                                </Col>
                            }
                        </Row>
                        <div className='fw-bold py-3'>
                            {getTranslatedRawText('adminPanel.usersInfo.personalInformation')}
                        </div>
                        <Row className='p-0 m-0'>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.genderType')}
                                    value={EnumerationTranslationHelper.getTranslateGenderType(userDetails?.genderTypeId)} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0 text-truncate text-nowrap overflow-hidden'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.firstName')}
                                    value={userDetails?.firstName} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0 text-truncate text-nowrap overflow-hidden'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.lastName')}
                                    value={userDetails?.lastName} />
                            </Col>
                            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.nationalCodeVisibilityType) &&
                                <Col md={3} xs={6} className='p-1 m-0'>
                                    <NsLabelBox label={EnumerationTranslationHelper.getNationalCodeNameById(userDetails?.countryId)}
                                        value={userDetails?.nationalCode} />
                                </Col>
                            }
                        </Row>
                        <Row className='p-0 m-0'>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.dateOfBirth')}
                                    value={DateHelper.getDatePart(userDetails?.birthday)} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.email')}
                                    value={userDetails?.email} />
                            </Col>
                        </Row>
                        <Row className='p-0 m-0'>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.state')}
                                    value={userDetails?.stateName} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.city')}
                                    value={userDetails?.cityName} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.defaultUserAddress')}
                                    value={userDetails?.defaultUserAddress} />
                            </Col>
                            <Col md={3} xs={6} className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.agency')}
                                    value={userDetails?.agencyName ?? getTranslatedRawText('adminPanel.usersInfo.noAgency')} />
                            </Col>
                        </Row>
                        <Row className='p-0 m-0'>
                            <Col className='p-1 m-0'>
                                <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.description')}
                                    value={userDetails?.description} />
                            </Col>
                        </Row>
                    </Row>
                }

                {showUserEditModal &&
                    <ApBaseUserEditModal showModal={true}
                        roleType={roleType}
                        closeUserEditModal={userEditSaveClicked}
                        getMessageClassModal={getMessageClassModal}
                        selectedUserId={props.selectedUserId}
                        closeModal={closeUserEditModal} />
                }

                {isShowFinancialDashboardModal &&
                    <UserFinancialDashboardModal selectedUserId={props?.selectedUserId}
                        closeModal={() => setIsShowFinancialDashboardModal(false)}
                        userDetails={userFullNameData()} />
                }

                {showUserOrderModal &&
                    <ApShareSheetOrderModal showModal={true}
                        customerDetails={userDetails}
                        searchData={{ user: props.selectedUserId }}
                        userId={props.selectedUserId}
                        closeModal={userOrderClicked} />
                }

                {showUserPaymentModal &&
                    <ApPaymentModal showModal={true}
                        searchData={{ user: props.selectedUserId }}
                        customerDetails={userDetails}
                        userId={props.selectedUserId}
                        closeModal={userPaymentClicked} />
                }

                {isShowUsersFeedbacksModal &&
                    <ApCustomerFeedbacksModal searchData={{ user: props.selectedUserId }}
                        userId={props.selectedUserId}
                        closeModal={() => setIsShowUsersFeedbacksModal(false)} />
                }

                {showAddSupporterUserModal &&
                    <ApAddSupporterUserModal isAddNewAdmin={false}
                        user={userDetails}
                        closeModal={closeAddSupporterUserModalClicked} />
                }

                {showUserBalanceSheetModal &&
                    <ApUsersFinancialBalanceSheetModal userDetails={financialBalanceModalUserData()}
                        closeModal={closeUserBalanceSheetModal} />
                }
                {showUserAgreementsHistoryModal &&
                    <ApAgreementsHistoryModal userId={userDetails?.userId}
                        closeModal={closeUserAgreementsHistoryModal} />
                }
                {showBusinessPartnerEmbedCodeModal &&
                    <ApBusinessPartnerEmbedCodeModal userId={userDetails?.userId}
                        showModal={true}
                        closeModal={closeBusinessPartnerEmbedCodeModal} />
                }
                {showUserProfileBarcodeModal &&
                    <ApBaseUserProfileBarcodeModal userId={userDetails?.userId}
                        showModal={true}
                        closeModal={toggleUserProfileBarcodeModal} />
                }
                {showRemainingLimit &&
                    <ApBaseUserRemainingLimitModal closeModal={RemainingLimitClicked}
                        roleType={roleType}
                        userId={userDetails?.userId}
                        showModal={true} />
                }
                {showCashbackModal &&
                    <CashbackDetailModal userId={userDetails?.detailLedgerId}
                        showCashbackDetailModal={showCashbackModal}
                        closeModalClicked={closeModalClicked} />
                }
                {showUserProfileModal &&
                    <ApBaseUserProfileModal userId={userDetails?.userId}
                        roleType={Enumerations.role.supporter}
                        closeModal={userProfileClicked}
                        showModal={true} />
                }
                {showUserDepartmentModal &&
                    <ApUserDepartmentModal userId={userDetails?.userId}
                        roleType={Enumerations.role.supporter}
                        closeModal={toggleUserDepartmentModal}
                        showModal={showUserDepartmentModal} />
                }
                {showGrantedCreditModal &&
                    <ApBaseUserGrantedCreditModal userId={userDetails?.userId}
                        closeModal={toggleGrantedCreditModal} />
                }
                {resultMessageClass}
            </div>
        </NsModal>
    );

};

function UserFinancialDashboardModal({ closeModal, selectedUserId, userDetails }) {
    const [showUserDetailModal, setShowUserDetailModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleUserInfoModal = () => setShowUserDetailModal(!showUserDetailModal)

    const headerButtons = () => ([
        hasAccess([UserClaimEnumerations.getApCustomerById]) && { wrapperClassName: '', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: () => toggleUserInfoModal() },])

    return <NsModal maxWidth='xl'
        showModal={true}
        closeModal={closeModal}
        noSpace={true}
        dialogContentClassName=''
        dialogTitle={getTranslatedRawText("adminPanel.dashboard.financialDashboard")}>

        <ApBaseMapActionButtons actionButtons={headerButtons()}
            isLoading={isLoading} />

        <div className='background-color p-2'>
            <ApBaseLabelInfo info={userDetails}
                isLoading={isLoading} hasAccessInfo={hasAccess([UserClaimEnumerations.getApCustomerById])} />
        </div>

        <ApDashboardFinancial userId={selectedUserId} />

        {showUserDetailModal &&
            <ApBaseUserInfoModal showModal={showUserDetailModal}
                selectedUserId={selectedUserId}
                closeModal={toggleUserInfoModal} />
        }

    </NsModal>
}

export default React.memo(ApBaseUserInfoModal);