import React, { useState } from 'react';

import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../bases/ApBaseSearchButtons';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';

const ApFileManagerSearch = (props) => {

    const { isLoading } = props;
    const [fileFormat, setFileFormat] = useState(null);
    const [fileName, setFileName] = useState(null);

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptions = [
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.customersFiles.fileFormat'),
            fieldName: 'fileFormat',
            value: fileFormat,
            items: ConstantsWithTranslation.getFileExtensionType(),
            onChange: (value) => setFileFormat(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('adminPanel.customersFiles.fileName'),
            fieldName: 'fileName',
            value: fileName,
            onChange: (value) => setFileName(value)
        },
    ]

    const searchClicked = () => {
        let data = {
            fileFormat,
            fileName
        }
        CommonHelper.safeFunctionCall(props.setSearchData, data);
    }

    const clearButtonClicked = () => {
        setFileFormat(null);
        setFileName(null);
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    const searchValues = { fileFormat, fileName };

    return (
        <div onKeyDown={handleKeyDown}>
            <NsAdvanceSearch searchOptions={basicSearchOptions}
                searchValues={searchValues} mdCol={2} smCol={4} />

            <ApBaseSearchButtons searchClicked={searchClicked}
                clearButtonClicked={clearButtonClicked}
                isLoading={isLoading} />
        </div>
    )
}

export default React.memo(ApFileManagerSearch);