import Links from "../../configs/links/AllLinks"
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest } from "../bases/BaseService"

const GetCmsBlogFilterCategoriesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsBlogFilterCategories), parameters, callback);
}
const GetCmsBlogCategoryDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsBlogCategoryDetail), parameters, callback);
}
const GetCmsBlogsFilterPostService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsBlogFilterPosts), parameters, callback);
}
const GetCmsBlogPostDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsBlogPostDetail), parameters, callback);
}
const GetCmsBlogsArchivesDateService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsBlogsArchivesDate), {}, callback);
}

const GetCmsNewsFilterCategoriesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsNewsFilterCategories), parameters, callback);
}
const GetCmsNewsCategoryDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsNewsCategoryDetail), parameters, callback);
}
const GetCmsNewsFilterPostService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsNewsFilterPosts), parameters, callback);
}
const GetCmsNewsPostDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsNewsPostDetail), parameters, callback);
}
const GetCmsNewsArchivesDateService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsNewsArchivesDate), {}, callback);
}

const GetCmsSlidersService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsSliders), {}, callback);
}

const GetCmsHomePromoteContentsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsHomePromoteContent), {}, callback);
}

const GetCmsMiddlePromoteContentsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsMiddlePromoteContent), {}, callback);
}

const GetCmsSidebarPromoteContentsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsSinglePromoteContent), {}, callback);
}

const GetCmsSidebarBlogPromoteContentsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsBlogAndNewsPromoteContent), {}, callback);
}

const GetCmsHomeBottomPromoteContentsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsHomeBottomPromoteContent), {}, callback);
}

const GetCmsAnnouncementsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsAnnouncements), {}, callback);
}

const GetCmsSinglePageService = (staticPageType, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsSinglePage), { staticPageType }, callback);
}

const GetGalleriesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getGalleries), parameters, callback);
}

const GetCmsSinglePageCategoriesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.cms, Links.api.cms.getCmsSinglePageCategories), {}, callback);
}

const GetCmsFileService = (url, callback) => {
    GetRequest(url, {}, callback);
}

export {
    GetCmsBlogFilterCategoriesService,
    GetCmsBlogCategoryDetailService,
    GetCmsBlogsFilterPostService,
    GetCmsBlogPostDetailService,
    GetCmsBlogsArchivesDateService,

    GetCmsNewsFilterCategoriesService,
    GetCmsNewsCategoryDetailService,
    GetCmsNewsFilterPostService,
    GetCmsNewsPostDetailService,
    GetCmsNewsArchivesDateService,

    GetCmsSlidersService,
    GetCmsHomePromoteContentsService,
    GetCmsMiddlePromoteContentsService,
    GetCmsSidebarPromoteContentsService,
    GetCmsSidebarBlogPromoteContentsService,
    GetCmsHomeBottomPromoteContentsService,
    GetCmsAnnouncementsService,
    GetCmsSinglePageService,
    GetCmsSinglePageCategoriesService,
    GetCmsFileService,

    GetGalleriesService
}