import React from 'react';
import { withRouter } from 'react-router';

import { GetArchiveExcelReportService, GetArchiveOrdersService } from '../../../../../../services/ProfileSetting/UserOrderService';
import NsExportExcelReportButton from '../../../../../../components/downloadFiles/NsExportExcelReportButton';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../../utilities/CultureHelper';
import UpBaseGroupSheetMobileRedirectToAllOrders from '../bases/UpBaseGroupSheetMobileRedirectToAllOrders';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import UpBaseGroupSheetOrdersSearchSection from '../bases/UpBaseGroupSheetOrdersSearchSection';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import UpGroupSheetArchiveOrderDetailModal from './UpGroupSheetArchiveOrderDetailModal';
import { createGroupSheetOrderColumn } from '../bases/UpBaseGroupSheetOrderHelper';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import UserPanelWrapper from '../../../../UserPanelWrapper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../../configs/Enumerations';
import UrlHelper from '../../../../../../utilities/UrlHelper';
import Links from '../../../../../../configs/links/AllLinks';
import NsTab from '../../../../../../components/tabs/NsTab';

class ArchiveOrdersContent extends React.Component {

    constructor(props) {
        super(props);
        this.columns = createGroupSheetOrderColumn(true, true, false, false, this.detailViewClicked);
        this.tabs = ConstantsWithTranslation.getUserOrderArchiveTabs();
        this.selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);
        this.state = {
            selectedTab: this.selectedTab || this.tabs[0],
            selectedTabIndex: this.tabs.indexOf(this.selectedTab) > -1 ? this.tabs.indexOf(this.selectedTab) : 0,
            searchValues: null,
            showDetailModal: false,
            orderId: null,
            callServiceDate: null,
            openFeedbackModalDate: null,
            isLoading: true,
        };
    }

    componentDidMount = () => {
        let pathparams = UrlHelper.checkUrlIsOrderDetailFeedback(window.location.pathname);
        if (pathparams.isOrderDetail) {
            this.detailViewClicked(pathparams.orderId);
            if (pathparams.isOrderDetailFeedback)
                this.setState({ openFeedbackModalDate: new Date() });
        }
    }

    detailViewClicked = (id) => {
        this.setState({ showDetailModal: true, orderId: id });
        !!this.props.history && this.props.history.push(CommonHelper.stringFormat(
            getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.archiveOrderDetail), id));
    }

    closeDetailModalClicked = () => {
        this.setState({ showDetailModal: false, openFeedbackModalDate: null });
        !!this.props.history && this.props.history.push(this.state.selectedTab?.url);
    }

    tabChanged = (newValue, selectedTab) => newValue !== this.state.selectedTabIndex &&
        this.setState({ selectedTabIndex: newValue, callServiceDate: new Date(), isLoading: true, selectedTab }, () =>
            !!this.props.history && this.props.history.push(selectedTab.url));

    searchFormClicked = (searchValues) => this.setState({ searchValues, isLoading: true, callServiceDate: new Date() });

    getData = (rows, isLoading) => this.setState({ isLoading });

    render() {
        const { callServiceDate, searchValues, isLoading, orderId, showDetailModal, openFeedbackModalDate, selectedTab, selectedTabIndex } = this.state;
        const gridId = selectedTab?.id == Enumerations.sharedSheetOrderStateType.delivered ? GridEnumerations.siteGrids.archiveGroupSheetOrders : GridEnumerations.siteGrids.canceledGroupSheetOrders;

        return (
            <>
                <UpBaseGroupSheetOrdersSearchSection
                    searchFormClicked={this.searchFormClicked}
                    findOrdersSearchSection
                    autoFocus
                    isLoading={isLoading}>

                    <NsExportExcelReportButton searchValues={{ SharedSheetOrderStateTypes: [selectedTab?.id], ...searchValues }}
                        isLoading={isLoading}
                        service={GetArchiveExcelReportService} />
                </UpBaseGroupSheetOrdersSearchSection>

                <NsTab value={selectedTabIndex}
                    tabs={this.tabs}
                    onChange={this.tabChanged} />

                <NsDataGridWithServiceCall key={gridId}
                    columnDefs={this.columns}
                    hasPagination={true}
                    service={GetArchiveOrdersService}
                    parameters={{ filter: { ...searchValues, sharedSheetOrderStateTypes: [selectedTab?.id] } }}
                    callServiceDate={callServiceDate}
                    gridId={gridId}
                    hasCustomColumnOrder={true}
                    getData={this.getData} />

                <UpBaseGroupSheetMobileRedirectToAllOrders />

                {showDetailModal &&
                    <UpGroupSheetArchiveOrderDetailModal closeModal={this.closeDetailModalClicked}
                        openFeedbackModalDate={openFeedbackModalDate}
                        breadcrumbs={this.props.breadCrumb}
                        orderId={orderId} />
                }
            </>
        )
    }
}

export { ArchiveOrdersContent };

const UpGroupSheetArchiveOrders = (props) => {
    return (
        <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.archive')}
            titleIcon='ns-icon-archived-order' >
            <ArchiveOrdersContent {...props} />
        </UserPanelWrapper >
    )
}

export default withRouter(UpGroupSheetArchiveOrders);