import React from 'react';
import { Row, Col } from 'react-bootstrap';

import NsLoadingProgress from '../../../../../components/circularProgress/NsLoadingProgress';
import { GetFilePublicUrlService, GetProductAdvancePriceService, GetSpecificDimensionInfoService } from '../../../../../services/order/OrderService';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import NsAlertModal from '../../../../../components/modals/NsAlertModal';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import OrderHelper from '../../../../../utilities/OrderHelper';
import Enumerations from '../../../../../configs/Enumerations';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import PoBaseImageInModal from '../bases/PoBaseImageInModal';
import PoBaseGuidanceInModal from '../bases/PoBaseGuidanceInModal';
import PoBaseFileSelectorHelper from '../bases/PoBaseFileSelectorHelper';
import PoBaseEditFileActionInModal from '../bases/PoBaseEditFileActionInModal';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import DelayedTaskHelper from '../../../../../utilities/DelayedTaskHelper';
import { PrepareMasterFileService } from '../../../../../services/order/ProfessionalOrderService';
import { GetImageService } from '../../../../../services/common/CommonService';
import ImageHelper from '../../../../../utilities/ImageHelper';
import NsAvailableDimensionTable from '../../../../../components/availableDimensions/NsAvailableDimensionTable';

class PoModalMasterFile extends React.Component {

    constructor(props) {
        super(props);
        this.headerLabel = props.isFront ? getTranslatedRawText('regularOrder.common.frontSide')
            : getTranslatedRawText('regularOrder.common.backSide'),
            this.deleyedUpdateAvailableSizesByNewDimension = new DelayedTaskHelper(this.updateAvailableSizesByNewDimension, 1000);
        this.delayCheckAllDataLoadedAndClose = new DelayedTaskHelper(this.checkAllDataLoadedAndClose, 3000);
        this.state = {
            messageClassModalItems: [
                { messageText: getTranslatedRawText('common.messages.discardNewFileMessage'), messageType: Enumerations.messageType.question },
                { messageText: CommonHelper.stringFormat(getTranslatedRawText('professionalOrder.messages.areYouSureQuestion'), ''), messageType: Enumerations.messageType.question }
            ],
            selectedFileInfo: props.selectedFileInfo,
            originWidth: props.selectedFileInfo?.width,
            originHeight: props.selectedFileInfo?.height,
            imgContent: null,
            imgContentActions: [],
            imgResizeActionsStack: [],
            availableSizes: [],
            trimZone: null,
            productDesignTemplateUrl: null,
            billBookCopies: null,
            selectedDimension: {},
            isAlertModalOpen: false,
            isAvailableSizeLoading: true,
            isShowDeleteMessageModal: false,
            getFileInformationDate: null,
            changeFileDate: null,
            getAvailabelSizesDate: null,
            setNewSizeDate: null,
            disableConfirm: true,
            resultMessageClass: (<></>)
        };
    }

    isDraftAndThumbnailLoadedForCloseModal = false;
    isProductAdvancePriceLoadedForCloseModal = false;

    componentWillUnmount = () => this.delayCheckAllDataLoadedAndClose.clear();

    removeAndSaveClicked = () => this.setState({ isShowDeleteMessageModal: true });

    changeFileClicked = () => this.setState({ changeFileDate: new Date() });

    deleteFilm = (confirm) => {
        this.setState({ isShowDeleteMessageModal: false });
        if (!!confirm) {
            if (this.props.isFront) {
                OrderHelper.clearOrder(this.props.routeId)
                this.closeClicked();
            } else
                this.setState({ selectedFileInfo: '' }, this.removeFileFromStorage);
        }
    }

    closeClicked = () => CommonHelper.safeFunctionCall(this.props.closeMasterFileModalClicked, this.props.currentFileKey);

    checkAllDataLoadedAndClose = () => {
        if (!!this.isDraftAndThumbnailLoadedForCloseModal && !!this.isProductAdvancePriceLoadedForCloseModal) {
            this.changeIsLoadingState(false);
            this.closeClicked();
        } else
            this.delayCheckAllDataLoadedAndClose.run();

    }

    handleAlertModal = () => {
        const { imgContentActions, imgResizeActionsStack } = this.state;
        if (!isEmpty(imgResizeActionsStack) || !isEmpty(imgContentActions))
            this.setState({ isAlertModalOpen: true });
        else
            this.closeClicked();
    };

    closeAlertClicked = (confirm) => {
        this.setState({ isAlertModalOpen: false });
        !!confirm && this.closeClicked();
    }

    confirmFileClicked = () => {
        const { selectedFileInfo, imgContentActions, imgResizeActionsStack, disableConfirm } = this.state;
        if (disableConfirm) return;
        let storageFileId = this.props.isFront
            ? OrderHelper.getFrontSideMasterFiles(this.props.routeId)?.id
            : OrderHelper.getBackSideMasterFiles(this.props.routeId)?.id;
        if (isEmpty(imgContentActions) && isEmpty(imgResizeActionsStack) && selectedFileInfo.id === storageFileId) {
            this.closeClicked();
            return;
        }
        this.changeIsLoadingState(true);
        this.isDraftAndThumbnailLoadedForCloseModal = false;
        this.isProductAdvancePriceLoadedForCloseModal = false;
        this.prepareMasterFile();
    }

    prepareMasterFile = () => {
        CommonHelper.safeFunctionCall(this.changeIsLoadingState, true);
        if (!isEmpty(this.state.selectedFileInfo)) {
            PrepareMasterFileService(this.state.selectedFileInfo, this.props.isFront, this.props.routeId, [...this.state.imgContentActions, ...this.state.imgResizeActionsStack], this.prepareMasterFileCallback)
            this.props.isFront && this.props.currentFileKey === 0 && OrderHelper.setTrimZone(this.props.routeId, this.state.trimZone);
            this.props.isFront && this.props.currentFileKey === 0 && OrderHelper.setBillBookCopies(this.props.routeId, this.state.billBookCopies);
        }
    }

    prepareMasterFileCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.setState({ selectedFileInfo: resultData },
                () => {
                    this.getProductAdvancePrice();
                    GetFilePublicUrlService(resultData.id, this.getFilePublicUrlCallback);
                });
        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });

    }

    getFilePublicUrlCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            let selectedFileInfo = { ...this.state.selectedFileInfo };
            selectedFileInfo.filePublicUrl = resultData;
            selectedFileInfo.resolution = resultData.resolution;
            if (!!selectedFileInfo?.productLatSizeTrimZone && this.props.currentFileKey === 0 && this.props.isFront) {
                selectedFileInfo.productLatSizeTrimZone.pixelTop = ImageHelper.convertMillimeterToPixel(selectedFileInfo.productLatSizeTrimZone?.top, selectedFileInfo.resolution);
                selectedFileInfo.productLatSizeTrimZone.pixelLeft = ImageHelper.convertMillimeterToPixel(selectedFileInfo.productLatSizeTrimZone?.left, selectedFileInfo.resolution);
                selectedFileInfo.productLatSizeTrimZone.pixelRight = ImageHelper.convertMillimeterToPixel(selectedFileInfo.productLatSizeTrimZone?.right, selectedFileInfo.resolution);
                selectedFileInfo.productLatSizeTrimZone.pixelBottom = ImageHelper.convertMillimeterToPixel(selectedFileInfo.productLatSizeTrimZone?.bottom, selectedFileInfo.resolution);
            }
            this.setState({ selectedFileInfo },
                () => {
                    GetImageService(resultData.draft, this.getDraftCallback);
                    GetImageService(selectedFileInfo.filePublicUrl.thumbnail, this.getThumbnailCallback);
                });
        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    getDraftCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState((prevState) => { prevState.selectedFileInfo.draft = resultData },
                () => this.saveFileInStorage());
        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    getThumbnailCallback = (resultData, result, messageClassModal) => {
        CommonHelper.safeFunctionCall(this.props.changeIsLoadingState, false);
        if (!result?.data?.hasError) {
            this.setState((prevState) => { prevState.selectedFileInfo.thumbnail = resultData },
                () => this.saveFileInStorage());
        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    saveFileInStorage = () => {
        const { selectedFileInfo } = this.state;
        if (!isEmpty(selectedFileInfo?.draft) && !isEmpty(selectedFileInfo?.thumbnail)) {
            this.changeIsLoadingState(true);
            !this.isDraftAndThumbnailLoadedForCloseModal && OrderHelper.updateFilesInStorages(this.props.routeId, this.props.currentFileKey, { id: selectedFileInfo.id, name: selectedFileInfo.name }, [selectedFileInfo], this.props.isFront, this.saveFileInStorageCallback);
            if (this.props.isFront && !isEmpty(this.state.fileSizeAndOrderPrices)) {
                OrderHelper.setAvailableSizes(this.props.routeId, this.state.availableSizes);
                OrderHelper.setFileSizeAndOrderPrices(this.props.routeId, this.state.fileSizeAndOrderPrices);
            }
        }
    }

    saveFileInStorageCallback = () => {
        this.isDraftAndThumbnailLoadedForCloseModal = true;
        this.checkAllDataLoadedAndClose();
    }

    removeFileFromStorage = () => OrderHelper.updateFilesInStorages(this.props.routeId, this.props.currentFileKey, null, [], this.props.isFront, this.closeClicked);

    getProductAdvancePrice = () => {

        if (this.props.isFront) {
            let data = OrderHelper.createAdvancePriceParameters(this.props.routeId, this.state.selectedFileInfo.id);
            GetProductAdvancePriceService(data, this.getProductAdvancePriceCallback);
        } else {
            this.isProductAdvancePriceLoadedForCloseModal = true;
            this.checkAllDataLoadedAndClose();
        }
    }

    getProductAdvancePriceCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.isProductAdvancePriceLoadedForCloseModal = true;
            this.setState({ fileSizeAndOrderPrices: resultData }, () => this.saveFileInStorage());
        } else {
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
        }
    }

    setSelectedFileInfo = (selectedFileInfo) => {
        this.setState({ selectedFileInfo, imgContent: selectedFileInfo?.draft, imgResizeActionsStack: [], imgContentActions: [] });
        !this.state.originWidth && this.setState({ originWidth: selectedFileInfo.width, originHeight: selectedFileInfo.height });
    }

    setAvailableSizes = (availableSizes) => {
        let selectedDimension = availableSizes.find(size => size.isSelected === true);
        this.setState({ availableSizes, selectedDimension, isAvailableSizeLoading: false, disableConfirm: true });
        !isEmpty(selectedDimension)
            ? GetSpecificDimensionInfoService(this.props.routeId, selectedDimension?.width, selectedDimension?.height,
                this.state.selectedFileInfo.width, this.state.selectedFileInfo.height, !this.props.isFront, this.getSpecificDimensionInfoCallback)
            : this.setState({ trimZone: null, productDesignTemplateUrl: null });
    }

    getSpecificDimensionInfoCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({
                trimZone: OrderHelper.convertTrimZoneToPixel(resultData.productLatSizeTrimZone, this.state.selectedFileInfo?.resolution),
                productDesignTemplateUrl: resultData?.productDesignTemplateUrl,
                billBookCopies: resultData?.billBookCopies,
                disableConfirm: false
            });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    setEditFileActionChanges = (selectedFileInfo, imgContent, imgContentActions, imgResizeActionsStack) => {
        !!imgContent && this.setState({ imgContent });
        this.setState({ selectedFileInfo, imgContentActions, imgResizeActionsStack },
            this.deleyedUpdateAvailableSizesByNewDimension.run
        );
    }

    updateAvailableSizesByNewDimension = () => {
        this.setState({ getAvailabelSizesDate: new Date(), isAvailableSizeLoading: true });
    }

    undoAllChangesClicked = () => this.setState({ imgResizeActionsStack: [], imgContentActions: [], getFileInformationDate: new Date() });

    changeIsLoadingState = (state) => this.setState({ isLoading: state });

    createStickyButtons = () => {
        return [
            CommonHelper.createStickyButtonItem("common.save", 'ns-icon-save', this.confirmFileClicked, 'primary masterFileModal-tour-save', this.state.disableConfirm),
            CommonHelper.createStickyButtonItem("common.remove", 'ns-icon-delete', this.removeAndSaveClicked, 'secondary'),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.handleAlertModal, 'primary-outline')
        ];
    }

    render() {
        const { imgContent,
            isAlertModalOpen,
            changeFileDate,
            getFileInformationDate,
            getAvailabelSizesDate,
            setNewSizeDate,
            availableSizes,
            trimZone,
            productDesignTemplateUrl,
            isLoading,
            isAvailableSizeLoading,
            selectedFileInfo,
            selectedDimension } = this.state;
        return (
            <NsModal showModal={this.props.showModal}
                closeModal={this.handleAlertModal}
                dialogClassName="modal-choose-printing-files"
                dialogTitle={CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.fourColorPrintingFile'), this.headerLabel)}
                maxWidth="md"
                primaryActionButton={this.confirmFileClicked}
                actions={<NsStickyButton stickyButtons={this.createStickyButtons()} isLoading={isLoading} />} >

                <Row>
                    <NsLoadingProgress visible={isLoading} />
                    <PoBaseFileSelectorHelper selectedFileInfo={selectedFileInfo}
                        storageFileInfo={this.props.storageFileInfo}
                        currentFileKey={this.props.currentFileKey}
                        isFront={this.props.isFront}
                        setSelectedFileInfo={this.setSelectedFileInfo}
                        setAvailableSizes={this.setAvailableSizes}
                        closeParentModal={this.closeClicked}
                        changeIsLoadingState={this.changeIsLoadingState}
                        changeFileDate={changeFileDate}
                        getFileInformationDate={getFileInformationDate}
                        getAvailabelSizesDate={getAvailabelSizesDate}
                        routeId={this.props.routeId} />

                    <Col md={7} >
                        <PoBaseImageInModal imgContent={imgContent}
                            routeId={this.props.routeId}
                            resolution={selectedFileInfo?.resolution}
                            trimZone={trimZone}
                            productDesignTemplateUrl={productDesignTemplateUrl}
                            fileName={selectedFileInfo?.name}
                            imgWidth={selectedFileInfo?.width}
                            imgHeight={selectedFileInfo?.height}
                            currentFileKey={this.props.currentFileKey}
                            changeFileClicked={this.changeFileClicked} />
                        <div className='font-size-12 mt-1 text-justify mb-3 mb-md-0'>
                            {getTranslatedRawText('common.messages.orderMasterFileModalImageHelper')}
                        </div>
                    </Col>

                    <Col md={5} className='ps-md-0' >
                        <PoBaseGuidanceInModal routeId={this.props.routeIs}
                            headerLabel={this.headerLabel}
                            currentFileKey={this.props.currentFileKey} />

                        <PoBaseEditFileActionInModal hasPositioning={false}
                            selectedDimension={selectedDimension}
                            hasResizeButton={!this.props.isFront}
                            masterImageWidth={this.props.frontSideMasterFileInfo?.width}
                            masterImageHeight={this.props.frontSideMasterFileInfo?.height}
                            selectedFileInfo={selectedFileInfo}
                            imgContent={imgContent}
                            setNewSizeDate={setNewSizeDate}
                            currentFileKey={this.props.currentFileKey}
                            changeIsLoadingState={this.changeIsLoadingState}
                            undoAllChangesClicked={this.undoAllChangesClicked}
                            setEditFileActionChanges={this.setEditFileActionChanges}>

                            {this.props.isFront &&
                                <NsAvailableDimensionTable rows={availableSizes}
                                    isLoading={isAvailableSizeLoading}
                                    isAuthenticated={true}
                                    usedInOrderProcess={true}
                                    showGuidance={true} />
                            }
                        </PoBaseEditFileActionInModal>

                    </Col>
                </Row>

                {this.state.isShowDeleteMessageModal &&
                    <NsMessageClassModal show={this.state.isShowDeleteMessageModal}
                        onClose={(confirm) => this.deleteFilm(confirm)}
                        messageItems={[this.state.messageClassModalItems[1]]} />
                }

                {isAlertModalOpen &&
                    <NsAlertModal isOpen={isAlertModalOpen}
                        cancelClicked={() => this.closeAlertClicked(false)}
                        confirmClicked={() => this.closeAlertClicked(true)} >
                        {getTranslatedRawText('regularOrder.modal.closeModalAlert')}
                    </NsAlertModal>}

                {this.state.resultMessageClass}

            </NsModal >
        );
    }
}

export default PoModalMasterFile;