import React, { useEffect, useState } from 'react';
import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import NsButton from '../../../../../components/buttons/NsButton';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import { GetShippingMethodsService, GetApShippingPlansService } from '../../../../../services/adminPanel/AdminShippingService';
import ApBaseSearchButtons from '../../../bases/ApBaseSearchButtons';
import ApBaseUserSelectModal from '../../../bases/ApBaseUserSelectModal';

const ApShippingSearch = (props) => {
    const [showUserSelectModal, setShowUserSelectModal] = useState(false)
    const [user, setUser] = useState(props.customerDetails?.phoneNumber || '');
    const [invoiceNo, setInvoiceNo] = useState('');
    const [requestNumber, setRequestNumber] = useState('');
    const [transerNumber, setTranserNumber] = useState('');
    const [lastStatuses, setLastStatuses] = useState([])
    const [submitTimeFrom, setSubmitTimeFrom] = useState(null);
    const [submitTimeTo, setSubmitTimeTo] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [shippingPlan, setShippingPlan] = useState([]);
    const [shippingPlans, setShippingPlans] = useState([]);
    const [shippingMethod, setShippingMethod] = useState([]);
    const [shippingMethods, setShippingMethods] = useState([]);
    const [estimatedDeliveryTimeFrom, setEstimatedDeliveryTimeFrom] = useState(null);
    const [estimatedDeliveryTimeTo, setEstimatedDeliveryTimeTo] = useState(null);
    const [shippingLastStatusTimeFrom, setShippingLastStatusTimeFrom] = useState(null);
    const [shippingLastStatusTimeTo, setShippingLastStatusTimeTo] = useState(null);
    const [selectedUser, setSelectedUser] = useState(props.customerDetails || '');
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getShippingPlans();
        getShippingMethods();
    }, []);

    useEffect(() => {
        !isEmpty(selectedUser) && searchClicked();
    }, [selectedUser]);

    const getShippingPlans = () => GetApShippingPlansService(getApShippingPlansCallback);
    const getShippingMethods = () => GetShippingMethodsService(getShippingMethodsCallback);

    const getApShippingPlansCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setShippingPlans(resultData);
        } else
            setResultMessageClass(messageClassModal);
    };

    const getShippingMethodsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setShippingMethods(resultData);
        } else
            setResultMessageClass(messageClassModal);
    };

    const usersModalButtonClicked = () => {
        setShowUserSelectModal(true)
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    };

    const basicSearchOptionsInMobile = [
        hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            onChange: (value) => setUser(value),
            endAdornment: !props.customerDetails?.phoneNumber ? <NsButton className='light-circle'
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" /> : null
        },
    ];

    const basicSearchOptions = [
        hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            onChange: (value) => setUser(value),
            endAdornment: !props.customerDetails?.phoneNumber ? <NsButton className='light-circle'
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" /> : null
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.shippingRequestNumber'),
            fieldName: 'requestNumber',
            value: requestNumber,
            onChange: (value) => setRequestNumber(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.invoiceNo'),
            fieldName: 'invoiceNo',
            value: invoiceNo,
            onChange: (value) => setInvoiceNo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.shippings.shippingLastStatus'),
            fieldName: 'lastStatuses',
            value: lastStatuses,
            items: ConstantsWithTranslation.getDeliveryRequestTabs(),
            onChange: (value) => setLastStatuses(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.shippings.shippingPlan'),
            fieldName: 'shippingPlan',
            value: shippingPlan,
            items: shippingPlans,
            onChange: (value) => setShippingPlan(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.shippings.shippingMethod'),
            fieldName: 'shippingMethod',
            value: shippingMethod,
            items: shippingMethods,
            onChange: (value) => setShippingMethod(value)
        },
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.shippings.estimatedDeliveryTimeFrom'),
            fieldName: 'estimatedDeliveryTimeFrom',
            value: estimatedDeliveryTimeFrom,
            onChange: (value) => setEstimatedDeliveryTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.shippings.estimatedDeliveryTimeTo'),
            fieldName: 'estimatedDeliveryTimeTo',
            value: estimatedDeliveryTimeTo,
            onChange: (value) => setEstimatedDeliveryTimeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.shippings.shippingLastStatusTimeFrom'),
            fieldName: 'shippingLastStatusTimeFrom',
            value: shippingLastStatusTimeFrom,
            onChange: (value) => setShippingLastStatusTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.shippings.shippingLastStatusTimeTo'),
            fieldName: 'shippingLastStatusTimeTo',
            value: shippingLastStatusTimeTo,
            onChange: (value) => setShippingLastStatusTimeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.shippings.shippingRequestTimeFrom'),
            fieldName: 'submitTimeFrom',
            value: submitTimeFrom,
            onChange: (value) => setSubmitTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.shippings.shippingRequestTimeTo'),
            fieldName: 'submitTimeTo',
            value: submitTimeTo,
            onChange: (value) => setSubmitTimeTo(value)
        },
    ];

    const showMoreClicked = () => {
        setShowMore(!showMore)
    };

    const searchClicked = () => {
        let data = {
            user,
            selectedUser,
            invoiceNo,
            transerNumber,
            requestNumber,
            lastStatuses,
            shippingPlan,
            shippingMethod,
            estimatedDeliveryTimeFrom,
            estimatedDeliveryTimeTo,
            shippingLastStatusTimeFrom,
            shippingLastStatusTimeTo,
            submitTimeFrom,
            submitTimeTo,
        }
        CommonHelper.safeFunctionCall(props.setSearchData, data)
    };

    const clearButtonClicked = () => {
        setUser('');
        setSelectedUser('');
        setInvoiceNo('');
        setTranserNumber('');
        setRequestNumber('');
        setLastStatuses([]);
        setShippingPlan([]);
        setShippingMethod([]);
        setEstimatedDeliveryTimeFrom(null);
        setEstimatedDeliveryTimeTo(null);
        setShippingLastStatusTimeFrom(null);
        setShippingLastStatusTimeTo(null);
        setSubmitTimeFrom(null);
        setSubmitTimeTo(null);

        CommonHelper.safeFunctionCall(props.setSearchData, {})
    };

    const searchValues = {
        user,
        invoiceNo,
        transerNumber,
        requestNumber,
        lastStatuses,
        shippingPlan,
        shippingMethod,
        estimatedDeliveryTimeFrom,
        estimatedDeliveryTimeTo,
        shippingLastStatusTimeFrom,
        shippingLastStatusTimeTo,
        submitTimeFrom,
        submitTimeTo,
    };

    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user?.phoneNumber)
        setShowUserSelectModal(false)
    };

    const excelButtonClicked = () => {
        let data = {
            user,
            selectedUser,
            invoiceNo,
            transerNumber,
            requestNumber,
            lastStatuses,
            shippingPlan,
            shippingMethod,
            estimatedDeliveryTimeFrom,
            estimatedDeliveryTimeTo,
            shippingLastStatusTimeFrom,
            shippingLastStatusTimeTo,
            submitTimeFrom,
            submitTimeTo,
        }
        CommonHelper.safeFunctionCall(props.excelButtonClicked, data)
    };

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={showMore ? advanceSearchOptions : CommonHelper.isMobileDevice() ? basicSearchOptionsInMobile : basicSearchOptions}
                    searchValues={searchValues} mdCol={2} smCol={4} />
                <ApBaseSearchButtons searchClicked={searchClicked}
                    excelButtonClicked={excelButtonClicked}
                    showMoreHandler={showMoreClicked}
                    showMore={showMore}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={props.isLoading} />
            </div>

            {showUserSelectModal &&
                <ApBaseUserSelectModal showModal={showUserSelectModal}
                    userSelected={userSelected}
                    closeModal={() => setShowUserSelectModal(false)} />
            }
            {resultMessageClass}
        </>
    )

}

export default React.memo(ApShippingSearch);