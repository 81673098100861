import React, { useState } from "react";

import NsAdminPanelContentWrapper from "../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import Enumerations from "../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApBlogCategoryModal from "../blogs/categories/ApBlogCategoryModal";
import ApGalleryCategoryModal from "../gallery/categories/ApGalleryCategoryModal";
import ApNewsCategoryModal from "../news/categories/ApNewsCategoryModal";

const ApBasesCategoryContent = (props) => {

    const { service, isBlog, isGallery } = props;

    const [messageClassModal, setMessageClassModal] = useState(<></>);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const columns = [
        hasAccess(isGallery ? [UserClaimEnumerations.getApGalleryCategoryById] : isBlog ? [UserClaimEnumerations.getApBlogCategoryById] : [UserClaimEnumerations.getApNewsCategoryById]) && {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: true,
                editClicked: (id) => showCategoryModalClicked(id),
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 150
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 200
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 200
        },
    ];

    const showCategoryModalClicked = (id) => {
        setSelectedCategoryId(id);
        setShowCategoryModal(true);
    }

    const closeCategoryModalClicked = (messageClassModal) => {
        setShowCategoryModal(false);
        if (!isEmpty(messageClassModal)) {
            setMessageClassModal(messageClassModal);
            setCallServiceDate(new Date());
        }
    }

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText(isGallery ? "adminPanel.sidebar.galleryCategory" : isBlog ? "adminPanel.sidebar.blogsCategory" : "adminPanel.sidebar.newsCategory")}
            icon={isGallery ? 'ns-icon-blog h1' : isBlog ? 'ns-icon-blog h1' : 'ns-icon-news h1'}
            actionButton={{
                onClick: showCategoryModalClicked,
                isLoading: isLoading,
                disabled: !hasAccess(isGallery
                    ? [UserClaimEnumerations.postApGalleryCategory]
                    : isBlog
                        ? [UserClaimEnumerations.postApBlogCategory]
                        : [UserClaimEnumerations.postApNewsCategory])
            }}
            hasAction={true}
            headerButtonClicked={() => showCategoryModalClicked(null)}>

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={service}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />


            {showCategoryModal && !!isGallery &&
                <ApGalleryCategoryModal categoryId={selectedCategoryId}
                    isNewCategory={!selectedCategoryId}
                    closeModal={closeCategoryModalClicked} />
            }
            {showCategoryModal && !!isBlog &&
                <ApBlogCategoryModal categoryId={selectedCategoryId}
                    isNewCategory={!selectedCategoryId}
                    closeModal={closeCategoryModalClicked} />
            }
            {showCategoryModal && !isBlog && !isGallery &&
                <ApNewsCategoryModal categoryId={selectedCategoryId}
                    isNewCategory={!selectedCategoryId}
                    closeModal={closeCategoryModalClicked} />
            }
            {messageClassModal}
        </NsAdminPanelContentWrapper>
    )
};

export default React.memo(ApBasesCategoryContent);