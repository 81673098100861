import React, { useEffect, useState } from 'react';

import NsLoadingProgress from '../../../../../components/circularProgress/NsLoadingProgress';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import NsModal from '../../../../../components/modals/NsModal';
import NsStepperWithActionButtons from '../../../../../components/stepper/NsStepperWithActionButtons';
import Enumerations from '../../../../../configs/Enumerations';
import { DeleteAgreementService, EditAgreementService, PostAgreementService } from '../../../../../services/adminPanel/AdminAgreementService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ContentHelper from '../../bases/ContentHelper';
import ApAgreementContent from './ApAgreementContent';
import ApAgreementVersionInfo from './ApAgreementVersionInfo';
import ApAgreementsStatusModal from './agreementDetailModal/ApAgreementsStatusModal';
import ApAgreementUsersAccepted from './agreementDetailModal/ApAgreementUsersAccepted';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';

const ApAgreementDetailModal = ({ selectedAgreementId, closeModal, row }) => {

    const [stepIndex, setStepIndex] = useState(0);
    const [tempStepIndex, setTempStepIndex] = useState(null);
    const [nextButtonTriggerDate, setNextButtonTriggerDate] = useState(null);
    const [agreementId, setAgreementId] = useState(selectedAgreementId);
    const [messageClassModal, setMessageClassModal] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [showValidationDate, setShowValidationDate] = useState(null);
    const [isEditMode, setIsEditMode] = useState(!isEmpty(agreementId));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showActivatingAgreementModal, setShowActivatingAgreementModal] = useState(false);
    const [showAgreementUsersAcceptedModal, setShowAgreementUsersAcceptedModal] = useState(false);

    useEffect(() => {
        setIsEditMode(!isEmpty(agreementId));
    }, [agreementId]);

    const stepButtons = [
        stepIndex > 0 && {
            visible: true,
            className: 'primary-outline',
            label: 'common.label.back',
            icon: CommonHelper.getBackIconByCulture(),
            disabled: stepIndex == 0,
            type: Enumerations.stepperButtonType.previous,
            validation: () => true,
            event: (type, newStep) => {
                setNextButtonTriggerDate(null);
                setStepIndex(newStep);
            }
        },
        stepIndex === 1 &&
        {
            visible: true,
            className: 'primary mx-auto',
            label: 'common.save',
            icon: 'ns-icon-save',
            disabled: false,
            type: Enumerations.stepperButtonType.next,
            validation: () => true,
            event: (type, newStep) => closeModal(true)
        },
        stepIndex === 0 &&
        {
            visible: true,
            className: 'primary mx-auto',
            label: 'adminPanel.download.saveAndNextStep',
            icon: CommonHelper.getNextIconByCulture(),
            disabled: false,
            type: Enumerations.stepperButtonType.next,
            validation: () => true,
            event: (type, newStep) => {
                setNextButtonTriggerDate(new Date().toISOString());
                setTempStepIndex(newStep);
            }
        },
    ]

    const steps = [
        { title: getTranslatedRawText('adminPanel.download.version'), buttons: stepButtons },
        { title: getTranslatedRawText('adminPanel.dataGridColumn.content'), buttons: stepButtons },
    ]

    const goToNextStep = (id) => {
        setAgreementId(id);
        tempStepIndex >= steps.length
            ? closeModal(true)
            : setStepIndex(tempStepIndex);
        setNextButtonTriggerDate(null);
    }

    const saveButtonClicked = (id, data) => {
        let tempContent = !isEmpty(data) ? ContentHelper.removeAgreementEmptyContent(data) : null;
        let isNotValidData = !isEmpty(tempContent) ? ContentHelper.isValidAgreementContent(tempContent) : true;

        if (isEmpty(tempContent) || isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }
        else {
            setIsLoading(true);
            if (!!id) {
                EditAgreementService(id, tempContent, EditAgreementCallback)
            } else PostAgreementService(tempContent, postAndEditDownloadCallback);

        }
    }

    const EditAgreementCallback = () => {
        setIsLoading(false);
        goToNextStep(agreementId);
    }

    const postAndEditDownloadCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        goToNextStep(resultData);
    }

    const deleteRow = (confirm) => {
        setShowDeleteModal(false);
        if (confirm) {
            setIsLoading(true);
            DeleteAgreementService(agreementId, deleteRowContentCallback)
        }
    };

    const deleteRowContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModal(true);
        else
            setMessageClassModal(messageClassModal);
    };

    const deleteFileClicked = () => setShowDeleteModal(true);

    const usersAcceptedClicked = () => setShowAgreementUsersAcceptedModal(!showAgreementUsersAcceptedModal)

    const activatingAgreementClicked = () => setShowActivatingAgreementModal(!showActivatingAgreementModal);

    const closeAgreementStatusModalClicked = (message) => {
        if (!!message) setMessageClassModal(message);
        setShowActivatingAgreementModal(false);
    }

    const headerButtons = [
        hasAccess([UserClaimEnumerations.deleteApUserAggrement]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deleteFileClicked, },
        hasAccess([UserClaimEnumerations.getApUserAggrementSignUsers]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-approval-users', label: 'adminPanel.singlePage.usersAccepted', onClick: usersAcceptedClicked, },
        hasAccess([UserClaimEnumerations.getApUserAggrementDetailById]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-active-agreement', label: 'adminPanel.singlePage.activatingAgreement', onClick: activatingAgreementClicked, }
    ];

    return (
        <NsModal maxWidth="lg"
            headerClassName='px-3'
            dialogTitle={getTranslatedRawText(isEditMode ? 'adminPanel.download.editFile' : 'adminPanel.download.newFile')}
            showModal={true}
            noSpace={true}
            closeModal={() => closeModal(true)}>

            {isEditMode && <ApBaseMapActionButtons actionButtons={headerButtons} isLoading={isLoading} />}

            <NsLoadingProgress visible={isLoading} />

            <NsStepperWithActionButtons stepList={steps}
                orientation={CommonHelper.isMobileDevice() ? 'vertical' : 'horizontal'}
                activeStep={stepIndex}
                currentStep={stepIndex}>
                {stepIndex === 1 &&
                    <ApAgreementContent agreementId={agreementId} />}
                {stepIndex === 0 &&
                    <ApAgreementVersionInfo nextButtonTriggerDate={nextButtonTriggerDate}
                        agreementId={agreementId}
                        isUsedInDownload={true}
                        saveButtonClicked={saveButtonClicked}
                        showValidationDate={showValidationDate}
                        closeModalClicked={() => closeModal(true)} />
                }
            </NsStepperWithActionButtons>

            {messageClassModal}

            {showDeleteModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => deleteRow(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), '')}</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }
            {showActivatingAgreementModal && agreementId && <ApAgreementsStatusModal agreementId={agreementId}
                closeModalWithMessageClass={closeAgreementStatusModalClicked}
                closeModal={activatingAgreementClicked} />}

            {showAgreementUsersAcceptedModal && agreementId && <ApAgreementUsersAccepted agreementId={agreementId}
                closeModal={usersAcceptedClicked} />}

        </NsModal>
    )
}

export default React.memo(ApAgreementDetailModal);