import clsx from 'clsx';
import React from 'react';
import { TextField } from '@mui/material';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { emailIsValid } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';

class NsEmailInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.value,
            helperText: '',
            invisibleHelperText: this.props.helperTxt,
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.showValidationDate !== this.props.showValidationDate)
            this.setState({ helperText: this.state.invisibleHelperText });
    }

    valueChanged = (event) => {
        let inputValue = event.target.value;
        this.setState({
            inputValue,
            helperText: ''
        });
        let isValidInput = this.getIsValidInput(inputValue);
        CommonHelper.safeFunctionCall(this.props.valueChanged, inputValue, isValidInput);
    }

    getIsValidInput = (inputValue) => {
        if (emailIsValid(inputValue)) {
            this.setState({ invisibleHelperText: '' });
            return true;
        } else {
            this.setState({ invisibleHelperText: getTranslatedRawText('components.emailInput.emailFormatWrong'), helperText: '' });
            return false;
        }
    }

    render() {
        const { showValidationDate, value, helperTxt, valueChanged, className, ...otherProps } = this.props;
        return (
            <TextField {...otherProps}
                className={clsx('ns-text-field', className)}
                variant='standard'
                value={value}
                helperText={this.state.helperText}
                onChange={this.valueChanged} />
        );
    }
}

export default NsEmailInput;