import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import UpBaseQueueOrdersItem from '../../../../profileSettings/contents/userOrder/queue/bases/UpBaseQueueOrdersItem';
import { GetStoreStatusQueueService } from '../../../../../services/adminPanel/AdminStoreOrderService';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import ApStoreOrderModal from '../stores/ApStoreOrderModal';
import DateHelper from '../../../../../utilities/DateHelper';

const ApOrderQueueStore = (props) => {

    const { fromDate, toDate, refreshDate } = props;
    const [ordersQueue, setOrdersQueue] = useState([]);
    const [selectedOrderQueue, setSelectedOrderQueue] = useState(null);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [resultMessageClass, setResultMessageClass] = useState((<></>));

    useEffect(() => {
        setSelectedOrderQueue(null);
        getStatusQueue();
    }, [fromDate, toDate, refreshDate])

    const getStatusQueue = () => {
        let parameters = {
            FromDate: fromDate,
            ToDate: toDate ? toDate : DateHelper.getDateWithEndOfDayTime(new Date())
        }
        setIsLoading(true);
        GetStoreStatusQueueService(parameters, getStoreStatusQueueCallback);
    }

    const getStoreStatusQueueCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setOrdersQueue(resultData);
        } else
            setResultMessageClass(messageClassModal);
    }

    const handlers = {
        orderQueueClicked: (index) => {
            setSelectedOrderQueue(ordersQueue[index]);
            setShowOrderModal(true);
        },

        closeApStoreOrderDetailModal: () => {
            setShowOrderModal(false);
            setSelectedOrderQueue(null);
        },
    }

    const { closeApStoreOrderDetailModal, orderQueueClicked } = handlers;

    return (
        <Row className='g-3 mx-2'>

            <UpBaseQueueOrdersItem queues={ordersQueue}
                isLoading={isLoading}
                orderQueueClicked={orderQueueClicked} />

            {showOrderModal && !isEmpty(selectedOrderQueue) &&
                <ApStoreOrderModal isQueue={true}
                    searchData={{
                        lastStatuses: { id: selectedOrderQueue.sharedSheetOrderStateType },
                    }}
                    closeModal={closeApStoreOrderDetailModal} />
            }

            {resultMessageClass}

        </Row>
    );
}

export default React.memo(ApOrderQueueStore);