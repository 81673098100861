import React, { useState } from 'react';

import NsButton from '../../../../../components/buttons/NsButton';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import Enumerations from '../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { GetDownloadAllDetailsService } from '../../../../../services/adminPanel/AdminDownloadService';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApDownloadFileUploader from './ApDownloadFileUploaderModal';

const ApDownloadAttachFileContent = ({ downloadId }) => {

    const [showAttachFileModal, setShowAttachFileModal] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const columns = [
        hasAccess([UserClaimEnumerations.getApDownloadDetailsById]) && {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: true,
                editClicked: (id) => showFileModalClicked(id),
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 100
        },
        {
            field: 'releaseDate',
            headerName: getTranslatedRawText('adminPanel.download.releaseDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'version',
            headerName: getTranslatedRawText('adminPanel.download.version'),
            width: 100
        },
        {
            field: 'volume',
            headerName: getTranslatedRawText('adminPanel.customersFiles.fileSize'),
            width: 100
        },
        {
            field: 'downloadFileUrl',
            headerName: getTranslatedRawText('adminPanel.download.attachFile'),
            flex: 300
        },

    ];

    const showFileModalClicked = (id) => {
        setSelectedFile(id);
        setShowAttachFileModal(true);
    };

    const closeModalClicked = () => {
        setShowAttachFileModal(false);
        setCallServiceDate(new Date());
    };

    return (
        <div className='px-3'>
            <NsButton className='secondary text-nowrap w-auto my-2'
                label={'adminPanel.download.attachFile'}
                disabled={!hasAccess([UserClaimEnumerations.postApDownloadDetails])}
                onClick={() => showFileModalClicked(null)} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetDownloadAllDetailsService}
                parameters={{ id: downloadId }}
                columnDefs={columns}
                hasPagination={true} />

            {showAttachFileModal &&
                <ApDownloadFileUploader downloadId={downloadId}
                    selectedFileId={selectedFile}
                    closeModalClicked={closeModalClicked} />}
        </div>
    )
}

export default React.memo(ApDownloadAttachFileContent);