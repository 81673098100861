import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { DeleteRequest, GetRequest, PatchRequest, PostRequest } from '../bases/BaseService';

const GetApStoreOrdersService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStoreOrders), parameter, callback);
}
const GetStoreOrderDtoService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStoreOrderDto), id), {}, callback);
}
const GetStoreOrderDetailService = (parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStoreOrderDetail), parameters.orderId), { id: parameters.id }, callback);
}
const GetStoreOrderHistoryService = (parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStoreOrderStatusHistory), parameters.orderId, parameters?.id), parameters, callback);
}
const GetStoreOrderOfficialReportService = (id, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStoreOrderOfficialReport), id), { id: userId }, callback);
}
const GetStoreOrderProductDetailService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStoreOrderProductDetail), id), {}, callback);
}
const GetStoreProductNameService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStoreProductName), {}, callback);
}
const GetStoreStatusQueueService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStoreStatusQueue), parameters, callback);
}
const UpdateStoreRetryToReadyStateService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.updateApStoreRetryToReadyState), data, callback, true);
}

export {
    GetApStoreOrdersService,
    GetStoreOrderDtoService,
    GetStoreOrderDetailService,
    GetStoreOrderHistoryService,
    GetStoreOrderOfficialReportService,
    GetStoreProductNameService,
    GetStoreStatusQueueService,
    GetStoreOrderProductDetailService,
    UpdateStoreRetryToReadyStateService
}