import React, { useEffect, useState } from 'react';

import NsDateRangePicker from '../../../../../../components/dateTimePickers/NsDateRangePicker';
import NsButton from '../../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import DateHelper from '../../../../../../utilities/DateHelper';

const UpBaseQueueOrdersHeader = (props) => {

    const [fromDate, setFromDate] = useState(new Date(DateHelper.getCustomPreviousMonth(2)));
    const [toDate, setToDate] = useState(null);
    const [refreshDate, setRefreshDate] = useState(null);

    useEffect(() => {
        CommonHelper.safeFunctionCall(props.updateDates, fromDate, toDate, refreshDate);
    }, [fromDate, toDate, refreshDate]);


    const dateRangeChanged = (dates) => {
        setFromDate(dates.length > 0 ? new Date(dates[0]) : null);
        if (dates.length > 1)
            setToDate(new Date(dates[1]));
        else
            setToDate(null);
    }

    const refreshQueueClicked = () => setRefreshDate(new Date());

    return (
        <>
            <NsButton className='secondary-outline w-auto'
                startIcon='ns-icon-retry'
                size='small'
                onClick={refreshQueueClicked} />
            <div className='w-auto'>
                <NsDateRangePicker buttonColor='secondary-outline'
                    dates={[fromDate, toDate]}
                    onChange={dateRangeChanged} />
            </div>
        </>
    );
}

export default React.memo(UpBaseQueueOrdersHeader);