import clsx from 'clsx';
import React from 'react';

import '../../../../assets/sass/views/layout/main/footers/Footers.scss';
import MiddleFooter from './MiddleFooter';
import BottomFooter from './BottomFooter';
import TopFooter from './TopFooter';
import NsEnamad from '../../../../components/enamad/NsEnamad';
import CommonHelper from '../../../../utilities/CommonHelper';

class Footer extends React.Component {

    render() {
        const { isShowMiddleFooter } = this.props;

        return (
            <div className={clsx('background-black-color text-white', !isShowMiddleFooter && 'fixed-bottom', CommonHelper.isMobileDevice() ? 'mt-100' : 'mt-auto')}>

                {isShowMiddleFooter &&
                    <>
                        <TopFooter />
                        <MiddleFooter />
                        {!CommonHelper.isUAE() && <NsEnamad />}
                    </>
                }
                <BottomFooter />

            </div>
        );
    }
}

export default Footer;