import clsx from 'clsx';
import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.scss';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../assets/sass/components/grids/NsGrid.scss';

import { getTranslatedRawText, getCurrentCulture, isEnCulture } from '../../utilities/CultureHelper';
import EnumerationTranslationHelper from '../../utilities/EnumerationTranslationHelper';
import ConstantsWithTranslation from '../../configs/ConstantsWithTranslation';
import { addOrUpdateGridColumnOrder, deleteGridColumnOrder } from '../../utilities/DataGridHelper';
import { getEnumKeyFromValue } from '../../utilities/EnumerationHelper';
import DelayedTaskHelper from '../../utilities/DelayedTaskHelper';
import NsGridPagination from '../pagination/NsGridPagination';
import { isEmpty } from '../../utilities/ValidationHelper';
import StorageHelper from '../../utilities/StorageHelper';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from '../../configs/Enumerations';
import DateHelper from '../../utilities/DateHelper';
import Constants from '../../configs/Constants';
import NsButton from '../buttons/NsButton';
import NsModal from '../modals/NsModal';
import NsStickyButton from '../buttons/NsStickyButton';
import NsTypography from '../text/NsTypography';

class NsDataGrid extends Component {
    constructor(props) {
        super(props);
        this.notRtlClass = ` ${!!isEnCulture() && 'text-end not-rtl'} `;
        this.delayExportSortData = new DelayedTaskHelper(() => {
            this.exportSortData();
        }, 1000);
        this.state = {
            rows: [],
            refreshDate: null,
            sortData: {},
            noRender: false,
            showResetCustomColumnOrderModal: false
        }
        this.gridOptions = {
            defaultColDef: {
                wrapText: true,
                autoHeight: true,
                resizable: true,
                minWidth: 70,
                headerCheckboxSelection: props.hasCheckbox && this.isFirstColumn,
                checkboxSelection: props.hasCheckbox && this.isFirstColumn,
            },
            headerHeight: props.headerHeight,
            domLayout: 'autoHeight',
            overlayNoRowsTemplate: overlayTemplate('components.nsDataGrid.noRowData'),
            overlayLoadingTemplate: overlayTemplate('components.nsDataGrid.loading'),

            getRowStyle: params => ({ ...params.node.data?.rowClassName }),
            onColumnMoved: this.onColumnMoved,
        };
        this.frameworkComponents = {
            customRowRenderer: customRowRenderer
        }
        this.gridApi = null;
        this.params = null;
        this.gridColumnApi = null;
    }

    componentDidUpdate = (prevProps) => {
        if (!this.props.isLoading) {
            if (this.props.totalRows < 1)
                this.gridApi?.showNoRowsOverlay();
            else
                this.gridApi?.hideOverlay();

        } else
            this.gridApi?.showLoadingOverlay();
    }

    onColumnMoved = (params) => {
        if (!this.props.gridId || !this.props.hasCustomColumnOrder) return;
        var columnState = JSON.stringify(params.columnApi.getColumnState());
        addOrUpdateGridColumnOrder(this.props.gridId, columnState);
    }

    isFirstColumn = (params) => {
        var displayedColumns = params.columnApi.getAllDisplayedColumns();
        var thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
    }

    getColmumnValueFromRow = (row, column) =>
        column.field.split('.').reduce((accumulatorObject, currentKey) => accumulatorObject != null && accumulatorObject[currentKey], row);

    setColmumnValueInRow = (row, column, newValue) => {
        const columnFieldParts = column.field.split('.');
        columnFieldParts.reduce((accumulatorObject, currentKey, index) => {
            if (accumulatorObject != null) {
                //TODO change alorithem
                if (columnFieldParts.length == index + 1)
                    accumulatorObject[currentKey] = newValue;
                else
                    return accumulatorObject[currentKey];
            }
        }, row);
    }

    flatAndCleanColumns = () => {
        if (isEmpty(this.props.columnDefs)) return [];
        let columns = [];
        function flatColumns(arrs, columns) {
            !isEmpty(arrs) && arrs.forEach(arr => {
                if (!isEmpty(arr.children))
                    flatColumns(arr.children, columns);
                else columns.push(arr);
            })
        }
        flatColumns(this.props.columnDefs, columns);
        return CommonHelper.clearArrayFromEmptyItem(columns);
    }

    //TODO why call mulitiple
    customizeRows = () => {
        const { rows } = this.props;

        if (isEmpty(rows)) return [];

        let modifiedRows = [];
        try {
            modifiedRows = structuredClone(rows);

        } catch {
            modifiedRows = JSON.parse(JSON.stringify(rows));
        }
        let columns = this.flatAndCleanColumns();
        columns.forEach(column => {

            let prepareValueFunction = null;
            switch (column.dataType) {
                case Enumerations.gridType.date:
                    prepareValueFunction = DateHelper.getDatePart;
                    column.cellClass = this.notRtlClass
                    break;
                case Enumerations.gridType.dateTime:
                    prepareValueFunction = DateHelper.getDateAndTime
                    column.cellClass = this.notRtlClass
                    break;
                case Enumerations.gridType.printSide:
                    prepareValueFunction = EnumerationTranslationHelper.getPrintedSideText;
                    break;
                case Enumerations.gridType.turnaround:
                    prepareValueFunction = EnumerationTranslationHelper.getTurnaroundText;
                    break;
                case Enumerations.gridType.price:
                    prepareValueFunction = CommonHelper.currencyFormatByDefaultConfig;
                    column.cellClass = this.notRtlClass;
                    break;
                case Enumerations.gridType.circulation:
                    prepareValueFunction = (value) => `${value} ${getTranslatedRawText('product.common.pieces')}`;
                    break;
                case Enumerations.gridType.number:
                    column.cellClass = this.notRtlClass;
                    break;
            };

            modifiedRows = modifiedRows.map(row => {
                if (!!prepareValueFunction)
                    this.setColmumnValueInRow(row, column, prepareValueFunction(this.getColmumnValueFromRow(row, column)));
                return row;
            });
        });
        return modifiedRows
    }

    onFirstDataRendered = (params) => {
        !isEmpty(this.props.selectedRowIds) && params.api.forEachNode((node) => {
            node.setSelected(this.props.selectedRowIds.includes(node.data.id))
        });
        const allColumnIds = [];
        this.gridColumnApi.getColumns().forEach((column) => {
            !column.colDef.width && allColumnIds.push(column.getId());
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    }

    getGridColumnsOrder = () => {
        const { gridId } = this.props;
        if (!gridId) return null;
        let gridColumnOrders = StorageHelper.getFromStorage(Enumerations.storage.gridColumnOrder);
        let gridColumnOrder = isEmpty(gridColumnOrders) ? null : gridColumnOrders.find(item => item.id == gridId);
        return !!gridColumnOrder?.columnState ? JSON.parse(gridColumnOrder?.columnState) : []
    }

    toggleCustomColumnOrderModal = (confirm) => {
        !!confirm && deleteGridColumnOrder(this.props.gridId);
        this.setState({
            showResetCustomColumnOrderModal: !this.state.showResetCustomColumnOrderModal,
            refreshDate: !!confirm ? new Date() : this.state.refreshDate
        });
    }

    onGridReady = (params) => {
        const { rows } = this.state;
        this.params = params;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (!!this.props.gridId && !!this.props.hasCustomColumnOrder) {
            let columnState = this.getGridColumnsOrder();
            if (!isEmpty(columnState))
                this.gridColumnApi.applyColumnState({ state: columnState, applyOrder: true });
        }

        // rows.length > 0 && params.api.setRowData(this.customizeRows());
        this.gridOptions.api.setColumnDefs(this.gridOptions.api.getColumnDefs().map(column => {
            column.resizable = !!this.props.hasCustomColumnOrder;
            column.lockPosition = !this.props.hasCustomColumnOrder;
            return {
                ...column,
                unSortIcon: column.sortable ? true : false,
            }
        }))
        this.setState({ noRender: this.props.sortData?.selectedSortingPropertyName })
        params.columnApi.applyColumnState({
            state: [
                {
                    colId: this.props.sortData?.selectedSortingPropertyName,
                    sort: ConstantsWithTranslation.getSortGridById(this.props.sortData?.sortType),
                },
            ]
        });

        if (this.props.isLoading) {
            if (this.props.rows?.length === 0)
                this.gridApi?.showLoadingOverlay();
        }
    };

    onColumnResized = (params) => {
        if (!!this.props.gridId && !!this.props.hasCustomColumnOrder)
            this.onColumnMoved(params);
        params.api.resetRowHeights();
    }

    onColumnVisible = (params) => params.api.resetRowHeights();

    onSelectionChanged = () => {
        const selectedRows = this.gridApi.getSelectedRows().map(item => item.id);
        const selectedRowWithUserIds = this.gridApi.getSelectedRows().map(item => ({ userId: item?.userId, orderId: item.id }));
        CommonHelper.safeFunctionCall(this.props.selectedRows, { rowIds: selectedRows }, selectedRowWithUserIds);
    };

    onRowClicked = (row) => {
        CommonHelper.safeFunctionCall(this.props.rowClicked, row?.data);
    };

    onSortChanged = (e) => {
        if (!this.state.noRender) {
            let columnData = e.columnApi.getColumnState().find(col => Boolean(col.sort))
            this.setState({
                rows: [],
                sortData: {
                    selectedSortingPropertyName: columnData?.colId,
                    sortType: ConstantsWithTranslation.getSortGrid(columnData?.sort),
                },
            })
            this.delayExportSortData.run()
        } else this.setState({ noRender: !this.state.noRender })
    }

    exportSortData = () => {
        CommonHelper.safeFunctionCall(this.props.sortChanged, this.state.sortData)
    }

    render() {
        const { columnDefs, totalRows, currentPage, rowPerPage, noFooter, theme, hasCustomColumnOrder } = this.props;

        return (
            <div className=''>
                <div className={clsx(!!theme ? theme : "ag-theme-alpine", "w-100 h-100")} >

                    <AgGridReact key={this.state.refreshDate}
                        gridOptions={Object.assign(this.gridOptions)}
                        columnDefs={CommonHelper.clearArrayFromEmptyItem(columnDefs)}
                        rowSelection={'multiple'}
                        ag-theme-material
                        enableRtl={getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.fa)}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.onFirstDataRendered}
                        frameworkComponents={this.frameworkComponents}
                        onSelectionChanged={this.onSelectionChanged}
                        onRowClicked={this.onRowClicked}
                        onSortChanged={this.onSortChanged}
                        rowData={this.customizeRows()}
                        suppressRowClickSelection={true}
                        onColumnResized={this.onColumnResized}
                        onColumnVisible={this.onColumnVisible}
                        suppressDragLeaveHidesColumns={true} />
                </div>
                {
                    !noFooter && <NsGridPagination borderStyle={{ borderRadius: "0px 0px 10px 10px", border: "1px solid #cccccc" }}
                        currentPage={currentPage}
                        rowPerPage={rowPerPage}
                        totalRows={totalRows}
                        hasCustomColumnOrder={hasCustomColumnOrder}
                        deleteGridColumnOrder={() => this.toggleCustomColumnOrderModal(false)}
                        rowPerPageOptionsInGrid={Constants.rowPerPageOptionsInGrid}
                        handleRowPerPage={this.props.handleRowPerPage}
                        handlePagination={this.props.handlePagination} />
                }
                {this.state.showResetCustomColumnOrderModal &&
                    <ResetCustomColumnOrderModal closeModal={this.toggleCustomColumnOrderModal} />
                }
            </div >
        );
    }
}

function overlayTemplate(content) {
    return `<span class="ag-overlay-loading-center">${getTranslatedRawText(content)}</span>`
}

function customRowRenderer(props) {

    return (
        <div className='d-flex'>
            {props.hasDetail &&
                <NsButton className='secondary-text p-0'
                    startIcon='ns-icon-invoice-details cursor-pointer'
                    onClick={() => props.detailClicked(props.node.data.id)} />
            }
            {props.hasEdit &&
                <NsButton className='secondary-text p-0'
                    startIcon='ns-icon-edit cursor-pointer'
                    onClick={() => props.editClicked(props.node.data.id)} />
            }
            {props.hasDelete &&
                <NsButton className='secondary-text p-0'
                    startIcon='ns-icon-delete cursor-pointer'
                    onClick={() => props.deleteClicked(props.node.data.id)} />
            }
            {props.hasPrint &&
                <NsButton className='secondary-text p-0'
                    startIcon='ns-icon-print cursor-pointer'
                    onClick={() => props.printClicked(props.node.data.id)} />
            }
            {props.hasActivationStatus &&
                <div className={props.node.data.isActive ? 'success-color' : 'red-color'}>
                    {EnumerationTranslationHelper.getActivationStatus(props.node.data.isActive)}
                </div>
            }
            {!!props.customElementRender && props.customElementRender(props.node.data)}
        </div>
    )
}

function ResetCustomColumnOrderModal(props) {
    const buttons = [
        CommonHelper.createStickyButtonItem("common.reset", 'ns-icon-setting', () => props.closeModal(true), 'primary '),
        CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', () => props.closeModal(false), 'primary-outline')
    ];

    return <NsModal
        dialogTitle={getTranslatedRawText('components.nsDataGrid.resetSetting')}
        showModal={true}
        closeModal={() => props.closeModal(false)}
        actions={<NsStickyButton stickyButtons={buttons} />} >
        <div className='d-flex flex-column align-items-start'>
            <NsTypography className='mb-2'>{getTranslatedRawText('common.messages.customColumnOrder')}</NsTypography>
            <NsTypography className='mb-2'>{getTranslatedRawText('common.messages.customeColumnResize')}</NsTypography>
            <NsTypography className='mb-2'>{getTranslatedRawText('common.messages.resetCustomColumnOrderAndResizeToDefault')}</NsTypography>
        </div>
    </NsModal>
}

export default NsDataGrid;