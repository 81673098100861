import { format } from "date-fns";
import CommonHelper from "./CommonHelper";
import { getTranslatedRawText, isEnCulture } from "./CultureHelper";
import { isEmpty } from "./ValidationHelper";
import { DateObject } from "react-multi-date-picker";

const tzoffset = ((new Date()).getTimezoneOffset() * 60000)
class DateHelper {

    static getWeekDayName = (value) => !!value && new Date(value).toLocaleDateString(isEnCulture() ? 'en-US' : 'fa-IR', { weekday: 'short' });

    static getIsoDate = (value) => DateHelper.getIsoDateTime(value).slice(0, 10);

    static getIsoDateTime = (value) => !!value ? new Date((value - tzoffset)).toISOString() : value;

    static getTodayDateTime = (secondValue) => new Date(new Date().setSeconds(secondValue, 0));

    static getDatePart = (date) => {
        if (!date) return date;
        return isEnCulture()
            ? date?.split('T')[0]
            : this.addZeroBeforeMonthAndDay(this.convertGregorianToPersian(date?.split('T')[0]));
    }

    static convertGregorianToPersian = (date) => !!date ? new Date(date).toLocaleDateString('fa-IR') : date;

    static getCurrentYear = () => new Date().getFullYear();

    static getPreviousMonth = () => {
        const current = (new Date(Date.now() - tzoffset))
        current.setMonth(current.getMonth() - 1);
        // return current.toISOString().slice(0, 10);
        return current;
    }

    static getNextMonth = () => {
        const current = (new Date(Date.now() - tzoffset))
        current.setMonth(current.getMonth() + 1);
        // return current.toISOString().slice(0, 10);
        return current;
    }

    static getCustomPreviousMonth = (month) => {
        const current = (new Date(Date.now() - tzoffset))
        current.setMonth(current.getMonth() - month);
        return current.toISOString().slice(0, 10);
    }

    static getDateWithEndOfDayTime = (date) => {
        let temp = !!date ? format(new Date(date), 'yyyy-MM-dd') : '';
        if (!isEmpty(temp)) {
            return temp + "T" + "23:59:59"
        }
    }

    static getFistDayOfNextMonth = () => {
        const today = new Date();
        const nextMonth = today.getMonth() + 1;
        const year = today.getFullYear() + (nextMonth === 12 ? 1 : 0);
        return new Date(year, nextMonth % 12, 1);
    }

    static addZeroBeforeMonthAndDay = (persianDate) => {
        if (!persianDate) return persianDate;
        let date = persianDate.split('/');
        if (isEmpty(date)) return persianDate;
        if (date[1]?.length == 1)
            date[1] = isEnCulture() ? '0' : '\u06F0' + date[1];
        if (date[2]?.length == 1)
            date[2] = isEnCulture() ? '0' : '\u06F0' + date[2];

        return date.join('/');
    }
    static getPreviousWeek = () => {
        const current = new Date()
        current.setDate(current.getDate() - 7);
        return current;
        // return current.toISOString().slice(0, 10);
    }

    static getDateAndTime = (date) => {
        if (!date) return date;
        const splittedDate = date?.split('T');
        return splittedDate ? CommonHelper.stringFormat('{0}  {1}',
            isEnCulture()
                ? splittedDate[0]
                : this.addZeroBeforeMonthAndDay(this.convertGregorianToPersian(splittedDate[0])),
            splittedDate[1]?.slice(0, 5)) : null;
    }

    static compareTwoDate = (fromDate, toDate) => {
        if (!fromDate || !toDate)
            return false;
        if (fromDate.split('-')[0] > toDate.split('-')[0])
            return false;
        if (fromDate.split('-')[1] > toDate.split('-')[1])
            return false;
        if (fromDate.split('-')[2] > toDate.split('-')[2])
            return false;
        return true;

    }

    static getTodayZeroHour = () => {
        let date = new Date()
        date.setUTCHours(0, 0, 0, 0)
        return date.toISOString().slice(0, 16)
    }

    static getEndOfTheDayHour = () => {
        let date = new Date()
        date.setUTCHours(23, 59, 59)
        return date.toISOString().slice(0, 16)
    }

    static convertDateTimeToDate = (value) => {
        if (!value) return value;
        let month = String(value.getMonth() + 1).padStart(2, '0');
        let day = String(value.getDate()).padStart(2, '0');
        let year = value.getFullYear();
        return `${year}-${month}-${day}`
    }
    static getTime = (date) => {
        if (!date) return date;
        return date?.split('T')[1];
    }

    static calcProcessHoursAndDays = (hours) => {
        if (hours) {
            return CommonHelper.stringFormat(getTranslatedRawText('product.detail.processHoursAndDays'), hours >= 24
                ? Math.ceil(hours / 24)
                : hours,
                hours >= 24
                    ? getTranslatedRawText('product.detail.days')
                    : getTranslatedRawText('product.detail.hours'))
        }
    }

    static mergeDateAndTime = (date, time, result) => {
        let combinedDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes(),
            time.getSeconds(),
            time.getMilliseconds()
        );
        result(DateHelper.getIsoDateTime(combinedDate));
    }

    static convertDateToText = (executeDate) => !!executeDate
        ? Intl.DateTimeFormat(isEnCulture() ? 'en-Us' : 'fa-IR', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(executeDate))
        : '';

    static convertObjectDateToNormalDate = (date) => {
        if (!!date) {
            let newDate = new DateObject(date);
            date = newDate.year + '-' + newDate.month + '-' + newDate.day
        }
        return new Date(date).toISOString();
    }

    static changeDayPartofDateTime = (daysToChange, isoFormat) => {
        const currentDate = new Date();
        const changedDays = new Date();
        changedDays.setDate(currentDate.getDate() + daysToChange);

        return isoFormat ? DateHelper.getIsoDateTime(changedDays) : changedDays
    }

}

export default DateHelper;