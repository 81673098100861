import React from 'react';

import { GetGalleryCategoriesService } from '../../../../../services/adminPanel/AdminGalleryService';
import ApBasesCategoryContent from '../../bases/ApBasesCategoryContent';

const ApGalleryCategory = () => {

    return (
        <ApBasesCategoryContent isGallery={true}
            service={GetGalleryCategoriesService} />
    )
};

export default React.memo(ApGalleryCategory);