import React from 'react';
import { Col, Row } from 'react-bootstrap';
import '../../../../assets/sass/views/profileSettings/ProfileSetting.scss';
import NsRadioButton from '../../../../components/buttons/NsRadioButton';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsCard from '../../../../components/cards/NsCard';
import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import NsSelect from '../../../../components/dropdowns/NsSelect';
import NsImage from '../../../../components/images/NsImage';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../components/text/NsTypography';
import Config from '../../../../configs/Config';
import Enumerations from '../../../../configs/Enumerations';
import Links from '../../../../configs/links/AllLinks';
import { GetAmountOfPaymentService, GetPaymentGatewaysService, GetPaymentUrlService } from '../../../../services/payments/PaymentService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import StorageHelper from '../../../../utilities/StorageHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import IpgGuidanceMessageModal from '../../modals/IpgGuidanceMessageModal';
import NumberHelper from '../../../../utilities/NumberHelper';

class UpPaymentsReadyToPay extends React.Component {

    constructor(props) {
        super(props);
        this.memoizeCreateStickyItem = CommonHelper.memoizeFunc(this.handleMainButtons);
        this.gatewayForm = React.createRef()
        this.state = {
            ipgs: [],
            selectedIpg: null,
            amountOfPayment: 0,
            ipgVisibilityType: Config.ipgVisibilityType,
            resultMessageClass: (<></>),
            isLoading: false,
            gatewayLoading: true,
            amountLoading: true,
            paymentId: null,
            isShowReturnToBasketModal: false,
            isChecked: false,
            gatewayData: {}
        }
    }

    componentDidMount = () => {
        this.getAmountAndGateway();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.getAmountAndGateway();
        }
    }

    getAmountAndGateway = () => {
        this.setState({ paymentId: this.props.match.params.id },
            () => GetAmountOfPaymentService(this.state.paymentId, this.getAmountOfPaymentCallback)
        );
        GetPaymentGatewaysService(this.getPaymentGatewaysCallback);
    }

    getPaymentGatewaysCallback = (resultData, result, messageClassModal) => {
        this.setState({ gatewayLoading: false });
        if (!!resultData && !result.data.hasError) {
            this.setState({ ipgs: resultData, selectedIpg: resultData[0] });
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    backClicked = () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder));

    modalPayClicked = () => {
        const { paymentId, selectedIpg } = this.state;
        this.setState({ isLoading: true },
            () => GetPaymentUrlService({
                paymentId: Number(paymentId),
                paymentGatewayId: Number(selectedIpg.id)
            }, this.getPaymentUrlServiceCallback),
            !!this.state.isChecked && StorageHelper.saveInStorage(Enumerations.storage.isAcceptPayWarningModal, this.state.isChecked)
        );
    }

    getPaymentUrlServiceCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            this.setState({
                gatewayData: resultData,
            }, () => Number(this.state.selectedIpg.paymentGatewayTypeId) === Enumerations.paymentGateway.nGeniusPayment
                ? window.location.replace(resultData.url)
                : this.gatewayForm.current.click())
        }
        else this.setState({ isLoading: false, resultMessageClass: messageClassModal });
    }

    checkboxClicked = () => this.setState({ isChecked: !this.state.isChecked });

    payClicked = () => {
        this.state.selectedIpg?.bankTypeId === 1 && !StorageHelper.getFromStorage(Enumerations.storage.isAcceptPayWarningModal)
            ? this.setState({ isShowReturnToBasketModal: !this.state.isShowReturnToBasketModal })
            : this.modalPayClicked();
    }

    closePayModalClicked = () => this.setState({ isShowReturnToBasketModal: !this.state.isShowReturnToBasketModal });

    isButtonDisable = () => isEmpty(this.state?.ipgs) || isEmpty(this.state.amountOfPayment);

    ipgChanged = (id) => {
        let selectedIpg = this.state.ipgs.find(option => option.id === Number(id));
        this.setState({ selectedIpg });
    }

    mapIpgs(ipgVisibilityType) {
        const { ipgs, selectedIpg } = this.state;
        if (ipgs.length === 0)
            return <NsTypography className='m-4 red-color'>{getTranslatedRawText('payment.ipg.noGatewayFound')}</NsTypography>;
        if (ipgs.length === 1)
            return (<BoxIpg ipgs={ipgs[0]} />);

        if (ipgVisibilityType === Enumerations.ipgVisibilityType.radio)
            return (<RadioIpgs ipgs={ipgs}
                selectedIpg={selectedIpg}
                radioButtonClicked={this.ipgChanged} />);

        if (ipgVisibilityType === Enumerations.ipgVisibilityType.select)
            return (<DropdownIpgs ipgs={ipgs}
                selectedIpg={selectedIpg}
                valueChanged={this.ipgChanged} />);
    }

    getAmountOfPaymentCallback = (resultData, result, messageClassModal) => {
        this.setState({ amountLoading: false });
        if (!!resultData && !result.data.hasError)
            this.setState({ amountOfPayment: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    handleMainButtons = (isButtonDisable) => {
        const mainButtons = [
            CommonHelper.createStickyButtonItem("common.pay", 'ns-icon-payments pe-1', this.payClicked, 'primary', isButtonDisable),
            CommonHelper.createStickyButtonItem("payment.ipg.backToCart", CommonHelper.getBackIconByCulture() + ' pe-1', this.backClicked, 'primary-outline'),
        ];
        return mainButtons;
    }

    createGatewayForm = () => {
        return !isEmpty(this.state.gatewayData.parameters) &&
            <form method="post" action={this.state.gatewayData.url} className='d-none'>
                {Object.keys(this.state.gatewayData.parameters).map(key => {
                    return <input type="text" name={key} value={this.state.gatewayData.parameters[key]} readOnly />
                })}
                <input type="submit" value="Post" ref={this.gatewayForm} />
            </form>
    }

    render() {

        const { resultMessageClass, ipgVisibilityType, amountOfPayment, isLoading, gatewayLoading, amountLoading } = this.state;
        const numberHelper = new NumberHelper();
        const mainButtons = this.memoizeCreateStickyItem(this.isButtonDisable());
        return (
            <NsCard>
                <NsLoadingProgress visible={isLoading} />
                <div className='d-flex flex-column justify-content-center align-items-center text-center profile-setting p-3'>
                    {/* {this.state.gatewayData.parameters && <form method="post" action={this.state.gatewayData.url} className='d-none'>
                    {this.state.gatewayData.parameters?.TerminalId && <input type="text" name="TerminalId" value={this.state.gatewayData.parameters.TerminalId} readOnly />}
                    {this.state.gatewayData.parameters?.RefId && <input type="text" name="refId" value={this.state.gatewayData.parameters.RefId} readOnly />}
                    {this.state.gatewayData.parameters?.Token && <input type="text" name="Token" value={this.state.gatewayData.parameters.Token} readOnly />}
                    {this.state.gatewayData.parameters?.GetMethod && < input name="GetMethod" value={this.state.gatewayData.parameters.GetMethod} readOnly />}
                    <input type="submit" value="Post" ref={this.gatewayForm} />
                </form>} */}
                    {this.createGatewayForm()}
                    <span className='ns-icon-payments red-color big-icon-size mx-auto pt-4 pb-2'></span>

                    <p className='section-title py-2'>{getTranslatedRawText('payment.ipg.paymentConfirmation')} </p>

                    {/* <p>{getTranslatedRawText('payment.ipg.paymentCheckAmount')}</p> */}

                    <div className='d-flex m-2 align-items-top'>
                        <NsTypography variant='inherit' className='text-nowrap'>
                            {getTranslatedRawText('payment.ipg.youAreGoingToPay')}
                        </NsTypography>
                        {amountLoading ?
                            <div className='ms-1'>
                                <NsSkeleton isVisible={true}
                                    className='payment-amount-skeleton'
                                    variant={[Enumerations.skeleton.text]} />
                            </div>
                            :
                            <div className='ms-4 text-start'>
                                <div>{CommonHelper.currencyFormatByDefaultConfig(amountOfPayment, true)} <span className='fw-bold red-color'>{CommonHelper.getDefaultCurrency()}</span></div>
                                <div>{numberHelper.convertNumberToText(amountOfPayment)} <span className='fw-bold red-color'>{CommonHelper.getDefaultCurrency()}</span></div>
                            </div>
                        }
                    </div>
                    {gatewayLoading ?
                        <div className='col-11 col-sm-8 col-md-5 col-xl-3 mb-3'>
                            <NsSkeleton isVisible={true}
                                variant={[Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect]} />
                        </div>
                        :
                        this.mapIpgs(ipgVisibilityType)
                    }
                    <div className=''>
                        <NsStickyButton stickyButtons={mainButtons} />
                    </div>
                </div>
                {resultMessageClass}
                <IpgGuidanceMessageModal showModal={this.state.isShowReturnToBasketModal}
                    closePayModalClicked={this.closePayModalClicked}
                    checkboxClicked={this.checkboxClicked}
                    isChecked={this.state.isChecked}
                    payClicked={this.modalPayClicked} />
            </NsCard>
        );
    }
}

function BoxIpg(props) {
    return (
        <Row className='g-0 m-4'>
            <Col xs={2} className='background-color border rounded'>
                <NsImage src={props.ipgs.flagImage}
                    className='mw-100'
                    imgAlt="components.imagesAlt.paymentMethodIcon" />
            </Col>
            <Col xs={10} className='ps-3'>
                <div className='background-element-color h-100 rounded p-1'>
                    <NsTypography variant='inherit' className="d-block light-paragraph text-start ps-2">
                        {getTranslatedRawText('payment.ipg.bankPortal')}
                    </NsTypography>
                    <NsTypography variant='inherit' className='section-title'>
                        {props.ipgs.name}
                    </NsTypography>
                </div>
            </Col>
        </Row>
    );
}

function RadioIpgs(props) {
    return (
        <div className='d-flex m-4'>
            {props.ipgs.map((ipg, index) =>
                <div key={ipg.id} className='d-flex flex-column mx-2'>
                    <NsImage src={ipg.flagImage}
                        wrapperClassName=''
                        width='100px'
                        className='border rounded mw-100'
                        imgAlt="components.imagesAlt.paymentMethodIcon" />
                    <NsRadioButton option={ipg}
                        checked={props.selectedIpg?.id === ipg.id}
                        radioButtonClicked={() => props.radioButtonClicked(ipg.id)} />
                </div>
            )}
        </div>
    );
}

function DropdownIpgs(props) {
    return (
        <div className='m-4'>
            <NsSelect label={getTranslatedRawText('payment.ipg.selectIpg')} options={props.ipgs}
                selectedItem={props.selectedIpg?.id}
                hasImage={true}
                valueChanged={(value) => props.valueChanged(value)} />
        </div>
    );
}

export default UpPaymentsReadyToPay;