import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import { isEnCulture } from '../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsStepperSlider from '../../../../../components/stepper/NsStepperSlider';
import UpDashboardCashbackReminderCard from './UpDashboardCashbackReminderCard';

class UpDashboardCashbackStepperPlans extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    percentageRangeValue = (valuePercentage) => {
        //another way to calculate scroll
        // const { cashbackPlans } = this.state;
        // let index = cashbackPlans.findIndex(item => item.toPrice > currentPrice)
        // let scroll = 1000 * index / cashbackPlans.length
        let scroll = 1000 * valuePercentage / 100 - 100
        if (!!this.ref?.current && scroll > 150)
            this.ref.current.scrollLeft = isEnCulture() ? scroll : -scroll;
    }

    render() {
        const { cashbackPlans, cashbackForecast, currentCashbackInfo, isLoading } = this.props;

        return (
            <div className='wrapper-white mb-3 py-2'>

                <div className={clsx('w-100', isLoading ? 'd-block' : 'd-none')}>
                    <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                        ]} />
                </div>
                <Row className={clsx('gap-2 mx-1', isLoading ? 'invisible' : '')}>
                    <Col lg={8} md={12} className={clsx(CommonHelper.isMobileDevice() ? 'overflow-auto' : '', 'px-lg-4 px-md-4 px-sm-5 px-5')}
                        ref={this.ref}>
                        <NsStepperSlider cashbackPlans={cashbackPlans}
                            percentageRangeValue={this.percentageRangeValue}
                            cashbackForecast={cashbackForecast} />
                    </Col>
                    <Col>
                        <UpDashboardCashbackReminderCard cashbackForecast={cashbackForecast}
                            currentCashbackInfo={currentCashbackInfo}
                            cashbackPlans={cashbackPlans} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default UpDashboardCashbackStepperPlans;