import React, { Component } from 'react';

import { GetAvailableOrdersForShippingService } from '../../../../../services/ProfileSetting/DeliveryAddressSettingService';
import UpGroupSheetReadyToDeliverOrderDetailModal from '../../userOrder/groupSheets/readyToDeliver/UpGroupSheetReadyToDeliverOrderDetailModal';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import UpBaseStoreOrdersGrid from '../../userOrder/stores/bases/UpBaseStoreOrdersGrid';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import Enumerations from '../../../../../configs/Enumerations';
import UpBaseGroupSheetOrdersGrid from '../../userOrder/groupSheets/bases/UpBaseGroupSheetOrdersGrid';

class UpBaseShippingReadyToDeliverOrdersGrid extends Component {

    constructor(props) {
        super(props);
        this.tabs = ConstantsWithTranslation.getOrderProductTabs(true, false);
        this.state = {
            orderProductType: props.orderProductType,
            callServiceDate: new Date(),
            storeSelectedRowIds: [],
            sheetOrderSelectedRowIds: [],
            selectedItemId: '',
            showOrderDetailModal: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!!prevProps.selectedShippingPlanId && prevProps.selectedShippingPlanId !== this.props.selectedShippingPlanId)
            this.setState({ callServiceDate: new Date() });
    }

    orderDetailViewClicked = (id) => this.setState({ selectedItemId: id, showOrderDetailModal: true });

    closeDetailModalClicked = () => this.setState({ showOrderDetailModal: false });

    createOrderIdsParameters = (sharedSheetOrderIds) => {
        const orderIds = [];
        !isEmpty(sharedSheetOrderIds) && sharedSheetOrderIds.map((order, index) => orderIds["SharedSheetOrderIds[" + index + "]"] = order);
        return orderIds;
    }

    render() {
        const { storeSelectedRowIds, sharedSheetOrderStateType, orderProductType, callServiceDate,
            sheetOrderSelectedRowIds, selectedItemId, showOrderDetailModal } = this.state;
        const { hasCheckbox, dataGridSelectionChanged, filter, selectedShippingPlanId } = this.props;
        const isStore = orderProductType === Enumerations.orderProductType.store;

        return (
            <div>
                {isStore
                    ? <UpBaseStoreOrdersGrid service={!!selectedShippingPlanId ? GetAvailableOrdersForShippingService : ''}
                        parameters={{
                            orderProductTypeId: orderProductType,
                            shippingPlanTypeId: selectedShippingPlanId,
                            ...this.createOrderIdsParameters(filter?.storeOrderIds)
                        }}
                        selectedRowIds={storeSelectedRowIds}
                        selectionChanged={dataGridSelectionChanged}
                        hasCheckbox={hasCheckbox} />
                    : <UpBaseGroupSheetOrdersGrid callServiceDate={callServiceDate}
                        service={!!selectedShippingPlanId ? GetAvailableOrdersForShippingService : ''}
                        parameters={{
                            sharedSheetOrderStateTypes: sharedSheetOrderStateType,
                            orderProductTypeId: orderProductType,
                            shippingPlanTypeId: selectedShippingPlanId,
                            ...this.createOrderIdsParameters(filter?.sharedSheetOrderIds)
                        }}
                        orderProductType={orderProductType}
                        detailViewClicked={this.orderDetailViewClicked}
                        selectedRowIds={sheetOrderSelectedRowIds}
                        selectionChanged={dataGridSelectionChanged}
                        inModal={true}
                        hasInvoiceNumber={true}
                        checkboxSelection={hasCheckbox} />
                }
                {showOrderDetailModal &&
                    <UpGroupSheetReadyToDeliverOrderDetailModal orderId={selectedItemId}
                        closeModal={this.closeDetailModalClicked} />
                }
            </div>
        )
    }
}

export default UpBaseShippingReadyToDeliverOrdersGrid;