import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import ApDuNegativeAccountBalanceUsers from './ApDuNegativeAccountBalanceUsers';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import ApBasesDashboardHeader from '../bases/ApBasesDashboardHeader';
import ApDuDuplicatePhoneNumbers from './ApDuDuplicatePhoneNumbers';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import DateHelper from '../../../../utilities/DateHelper';
import ApDuLastJoinedUsers from './ApDuLastJoinedUsers';
import ApDuInactiveUsers from './ApDuInactiveUsers';
import ApDuUsers from './ApDuUsers';

const ApDashboardUsers = () => {

    const [dates, setDates] = useState([new Date(DateHelper.getCustomPreviousMonth(2)), new Date()]);
    const [dateRangeType, setDateRangeType] = useState(ConstantsWithTranslation.getDateRanges()[0].key);
    const [refreshDate, setRefreshDate] = useState();

    const refreshClicked = useCallback((dates, isRefreshDate, dateRange) => {
        setDateRangeType(dateRange);
        setDates(dates);
        isRefreshDate && setRefreshDate(new Date());
    })

    const parameters = {
        submitTimeFrom: DateHelper.convertDateTimeToDate(new Date(dates[0])),
        submitTimeTo: dates[1],
        timeIntervalType: dateRangeType
    }

    return (
        <Row className='d-flex flex-column m-0 px-0 gap-2' >
            <Col>
                <ApBasesDashboardHeader title='adminPanel.dashboard.usersDashboard'
                    refreshClicked={refreshClicked} />
            </Col>
            <Row className='align-self-stretch m-0 px-0 gy-2'>
                {hasAccess([UserClaimEnumerations.getApDuTotalUsers]) &&
                    <Col md={12}>
                        <ApDuUsers parameters={parameters} refreshDate={refreshDate} />
                    </Col>
                }
                {hasAccess([UserClaimEnumerations.getApDuLastJoinedUsers]) &&
                    <Col md={5}>
                        <ApDuLastJoinedUsers parameters={parameters} refreshDate={refreshDate} />
                    </Col>
                }
                {hasAccess([UserClaimEnumerations.getApDuRemainingLimitWithCredit, UserClaimEnumerations.getApDuRemainingLimitWithoutCredit, UserClaimEnumerations.getApDuGrantedCredit]) &&
                    <Col md={7}>
                        <ApDuNegativeAccountBalanceUsers refreshDate={refreshDate} />
                    </Col>
                }
                {hasAccess([UserClaimEnumerations.getApDuInactiveUsers]) &&
                    <Col xs={12}>
                        <ApDuInactiveUsers parameters={parameters} refreshDate={refreshDate} />
                    </Col>
                }
                {hasAccess([UserClaimEnumerations.getApCustomersDuplicatePhoneNumbers]) &&
                    <Col xs={12}>
                        <ApDuDuplicatePhoneNumbers parameters={parameters} refreshDate={refreshDate} />
                    </Col>
                }
            </Row>
        </Row>
    );
};

export default React.memo(ApDashboardUsers);