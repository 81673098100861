import clsx from 'clsx';
import React, { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import CommonHelper from '../../utilities/CommonHelper';
import NsLabelBox from '../labels/NsLabelBox';
import '../../assets/sass/components/buttons/NsButton.scss'
import '../../assets/sass/components/buttons/NsToggleGroupButton.scss'
import { isEmpty } from '../../utilities/ValidationHelper';
import NsTooltip from '../tooltips/NsTooltip';
import NsButton from './NsButton';

export default function NsToggleGroupButton(props) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const { label, size, labelClassName, isShowGroupLabel, selectedItemId, items, borderColor, isVertical, disabled, tooltipTitle, tooltipSize,
        tooltipIcon } = props;

    const closeTooltip = () => setIsTooltipOpen(false);
    const toggleTooltipClicked = () => setIsTooltipOpen(!isTooltipOpen);

    const onChange = (event, newValue) => {
        if (newValue) {
            let selectedItem = items?.filter(item => item.key === newValue)
            CommonHelper.safeFunctionCall(props.onClick, selectedItem[0]);
        }
    };

    return (!isEmpty(items) && items.length > 1
        ?
        <div className={clsx('align-items-end', props.className)}>
            {!!isShowGroupLabel
                ? <div className={clsx("g-0 font-size-12 pb-1", labelClassName)}>
                    <span>{label}</span>
                    {!!tooltipTitle &&
                        <NsTooltip className={'ms-1'} open={isTooltipOpen}
                            title={tooltipTitle}
                            closeTooltip={closeTooltip}>
                            <NsButton className='secondary-text p-0 d-inline'
                                startIcon={clsx(tooltipIcon ? tooltipIcon : 'ns-icon-question')}
                                size={clsx(tooltipSize ? tooltipSize : 'small')}
                                onClick={toggleTooltipClicked} />
                        </NsTooltip>
                    }
                </div>
                : <div className=""></div>}
            <ToggleButtonGroup value={selectedItemId}
                className={clsx('p-1 gap-1 border-black', borderColor)}
                exclusive
                disabled={disabled}
                orientation={!!isVertical ? 'vertical' : 'horizontal'}
                onChange={onChange}>

                {items?.map((item, index) =>
                    <ToggleButton className={clsx(`py-1 border-0 ${!(disabled || item.disabled) && 'text-dark'} rounded ns-button text-nowrap MuiButton-label ${item.key === selectedItemId && 'secondary'}`)}
                        key={index}
                        value={item.key}
                        size={size}
                        disabled={disabled || item.disabled}>
                        {item.value}
                    </ToggleButton>
                )}

            </ToggleButtonGroup>
        </div>
        : <div className={clsx('d-flex', props.className)}>
            <NsLabelBox label={label}
                value={!!items?.[0] && items[0]?.value} />
        </div>
    );
}
