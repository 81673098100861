import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import NsStickyButton from "../../../../components/buttons/NsStickyButton";
import NsImage from "../../../../components/images/NsImage";
import NsTextField from "../../../../components/inputs/NsTextField";
import NsModal from "../../../../components/modals/NsModal";
import NsSwitch from "../../../../components/switch/NsSwitch";
import Enumerations from "../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import {
    EditPromoteContentService,
    GetPromoteContentService
} from '../../../../services/adminPanel/AdminPromoteService';
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText, isEnCulture } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApLanguageTabs from "../../bases/ApLanguageTabs";
import ApBasesContentVisibility from "../bases/ApBasesContentVisibility";
import ApBasesImageContent from "../bases/ApBasesImageContent";
import ApBasesStandardSizesForUpload from "../bases/ApBasesStandardSizesForUpload";
import ContentHelper from "../bases/ContentHelper";

const ApPromotionModal = (props) => {

    const { closeModal, selectedPromotion } = props;

    const [multiLanguagePromotionContent, setMultiLanguagePromotionContent] = useState([]);
    const [showValidationDate, setShowValidationDate] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLanguageInfo, setSelectedLanguageInfo] = useState({ id: 1 });
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        getPromotions(selectedPromotion.id);
    }, [])

    const getPromotions = (id) => {
        setIsLoading(true);
        GetPromoteContentService(id, getPromotionsCallback);
    }

    const getPromotionsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguagePromotionContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive }));
        } else
            setMessageClassModal(messageClassModal);
    }

    const saveButtonClicked = () => {
        let tempContent = ContentHelper.removePromotionEmptyContent(multiLanguagePromotionContent);
        let isNotValidData = ContentHelper.isValidPromotionContent(tempContent);
        if (isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }

        setIsLoading(true);
        EditPromoteContentService(selectedPromotion.id, tempContent, promotionContentCallback, true);
    }

    const promotionContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModal(messageClassModal);
        } else
            setMessageClassModal(messageClassModal);
    };

    const cancelClicked = () => {
        CommonHelper.safeFunctionCall(closeModal);
    }

    const languageChangedHandler = useCallback(lngInfo => {
        setSelectedLanguageInfo(lngInfo);
    }, []);

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguagePromotionContent([...newData]);
    }

    const mainButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary', accessIds: [UserClaimEnumerations.editApPromoteContent] },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: cancelClicked, className: 'primary-outline' },
    ];

    return (
        <NsModal
            maxWidth="md"
            dialogTitle={getTranslatedRawText('adminPanel.promotion.editPromotion')}
            showModal={true}
            closeModal={() => CommonHelper.safeFunctionCall(closeModal)}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>

            <ApLanguageTabs languageChanged={languageChangedHandler}
                selectedTabId={selectedLanguageInfo?.id} />

            {!isEmpty(multiLanguagePromotionContent) &&
                <TabDataTables key={selectedLanguageInfo.id}
                    stateHandler={stateHandler}
                    serviceCall={() => getPromotions(selectedPromotion.id)}
                    selectedPromotion={selectedPromotion}
                    data={[...multiLanguagePromotionContent]}
                    showValidationDate={showValidationDate}
                    selectedLanguage={selectedLanguageInfo} />
            }

            {messageClassModal}

        </NsModal >
    );
}

function TabDataTables(props) {
    const { stateHandler, data, showValidationDate, serviceCall, selectedLanguage, selectedPromotion } = props;
    let promotionContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);

    const contentVisibilityTypeIdChanged = (selectedItem) => {
        stateHandler(data, { contentVisibilityTypeId: selectedItem }, selectedLanguage.id)
    }

    const selectedAgencyIdsChanged = (agencyIds) => {
        stateHandler(data, { agencyIds }, selectedLanguage.id)
    }

    return <Row>
        <Col md={5}>
            <ApBasesImageContent content={promotionContent?.content}
                isChangeFile={!!promotionContent?.content?.fileUrl}
                serviceCall={serviceCall}
                fileUploaderCategoryType={Enumerations.fileUploaderCategoryType.promotion}
                stateHandler={(uploadedFileDetail) => stateHandler(data, { ...uploadedFileDetail, }, selectedLanguage.id)} />
            <ApBasesStandardSizesForUpload selectedPromotion={selectedPromotion} />
        </Col>
        <Col md={7}>
            <div className='d-flex flex-column justify-content-start'>
                <div className="d-flex">
                    <NsImage src={selectedPromotion.src}
                        imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.promotionSetting"), getTranslatedRawText(props.selectedPromotion.title))}
                        wrapperClassName='col-3 col-md-5 mb-2 w-auto' />
                </div>


                <div className="d-flex mb-2 align-items-end justify-content-between w-100 flew-row" >
                    <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                        valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                        value={promotionContent?.content.title}
                        showValidationDate={showValidationDate}
                        className='w-100' />
                    <div className='ps-2 d-flex align-items-end'>
                        <NsSwitch label='adminPanel.slider.active'
                            checked={promotionContent?.content.isActive}
                            disabled={!selectedLanguage.isDefault}
                            onChange={() => stateHandler(data, { isActive: !promotionContent?.content.isActive }, selectedLanguage.id)} />
                    </div>
                </div>

                <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.link')}
                    valueChanged={(value) => stateHandler(data, { linkUrl: value }, selectedLanguage.id)}
                    value={promotionContent?.content.linkUrl}
                    showValidationDate={showValidationDate}
                    className={`w-100 mb-2 ${!isEnCulture() && 'not-rtl'}`} />

                <div className='d-flex'>
                    <NsSwitch label='adminPanel.slider.onlyActiveLinkInLoggingMode'
                        checked={promotionContent?.content.isAuthenticationRequiredForLinkUrl}
                        disabled={!selectedLanguage.isDefault}
                        onChange={() => stateHandler(data, { isAuthenticationRequiredForLinkUrl: !promotionContent?.content.isAuthenticationRequiredForLinkUrl }, selectedLanguage.id)} />
                </div>

                <ApBasesContentVisibility contentVisibilityTypeId={promotionContent?.content.contentVisibilityTypeId}
                    selectedAgencies={promotionContent?.content.selectedAgencies}
                    contentVisibilityTypeIdChanged={contentVisibilityTypeIdChanged}
                    selectedAgencyIdsChanged={selectedAgencyIdsChanged} />

            </div>
        </Col>
    </Row>
}

export default React.memo(ApPromotionModal);