import React, { useState } from 'react';

import PdBaseImagePanel from '../../views/productDetails/bases/PdBaseImagePanel';
import NsCarouselSwiper from '../carousel/NsCarouselSwiper';
import { useEffect } from 'react';
import { isEmpty } from '../../utilities/ValidationHelper';

const NsProductImagesGallery = ({ product, isLoading, className }) => {

    const [selectedImage, setSelectedImage] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [images, setImages] = useState(null);

    useEffect(() => {
        if (!isEmpty(product?.imageUrls)) {
            setSelectedImage(product?.imageUrls[0]);
            createProductImagesUrl(product?.imageUrls);
        }
    }, [product?.imageUrls])

    const imageChanged = (activeStep) => {
        setSelectedImage(product?.imageUrls[+activeStep]);
        setActiveStep(activeStep)
    }

    const createProductImagesUrl = (imagesUrls) => {
        const productImagesUrl = [];
        imagesUrls?.map(item => productImagesUrl.push({ imageUrl: item }))
        setImages(productImagesUrl);
    }

    return (!isEmpty(product?.imageUrls)
        ? <div>
            <PdBaseImagePanel isLoading={isLoading}
                key={activeStep}
                className={className}
                product={{
                    name: product.name,
                    imageUrl: selectedImage
                }} />
            {images?.length > 1 &&
                <div className='mt-3'>
                    <NsCarouselSwiper images={images}
                        isFestivalAwardModal={true}
                        imageChanged={(activeStep) => imageChanged(activeStep)}
                        activeStep={activeStep} />
                </div>}

        </div>
        : <PdBaseImagePanel isLoading={isLoading}
            key={activeStep}
            className={className}
            product={{
                name: product.name,
                imageUrl: selectedImage
            }} />
    )
};

export default NsProductImagesGallery;