import clsx from 'clsx'
import React, { Component } from 'react'

class SpBasesSlogan extends Component {
    render() {
        const { className, title, text } = this.props;
        return (
            <div className={clsx('text-center', className)}>
                <div className='d-inline red-color fw-bold'>{title}</div>
                <div className='d-inline'>{text}</div>
            </div>
        )
    }
}

export default SpBasesSlogan;