import React from 'react';
import { GetUserAccountingPaymentReportService } from '../../../services/ProfileSetting/UserOrderService';
import NsModal from '../../../components/modals/NsModal';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import { PaymentStateType } from '../../../utilities/DataGridHelper';
import Enumerations from '../../../configs/Enumerations';
import EnumerationTranslationHelper from '../../../utilities/EnumerationTranslationHelper';
import NsDataGridWithServiceCall from '../../../components/grids/NsDataGridWithServiceCall';
import { useState } from 'react';
import { isEmpty } from '../../../utilities/ValidationHelper';
import { GetPaymentReportService } from '../../../services/payments/PaymentService';
import NsDownloadFile from '../../../components/downloadFiles/NsDownloadFile';

const UserOrderPaymentsModal = ({ orderId, closeModal }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    const columns = [
        {
            field: 'paymentDetail',
            headerName: getTranslatedRawText('profile.dataGridColumns.action'),
            width: 110,
            lockPosition: true,
            resizable: false,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasPrint: true,
                printClicked: (id) => printReportClicked(id),
            }
        },
        {
            field: 'paymentType',
            headerName: getTranslatedRawText('profile.dataGridColumns.status'),
            width: 120,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => <PaymentStateType status={props.paymentStateType} />
            }
        },
        {
            field: 'paymentDate',
            headerName: getTranslatedRawText('profile.orderReport.date'),
            width: 120,
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'paymentType',
            headerName: getTranslatedRawText('profile.orderReport.paymentType'),
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div>{EnumerationTranslationHelper.getPaymentTypeText(props.paymentType)}</div>
                }
            }
        },
        {
            field: 'amount',
            headerName: getTranslatedRawText('profile.orderReport.amount'),
            dataType: Enumerations.gridType.price,
            width: 120
        },
        {
            field: 'paymentGatewayName',
            headerName: getTranslatedRawText('profile.orderReport.paymentGateway'),
            width: 150
        },
        {
            field: 'trackingNo',
            headerName: getTranslatedRawText('profile.orderReport.trackingNo'),
            dataType: Enumerations.gridType.number,
            width: 130
        },
        {
            field: 'referenceNo',
            headerName: getTranslatedRawText('profile.orderReport.referenceNo'),
            dataType: Enumerations.gridType.number,
            width: 170
        },
    ]

    const printReportClicked = (id) => {
        GetPaymentReportService({ id }, getPaymentReportCallback);
        setIsLoading(true);
    }

    const getPaymentReportCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result.hasError && !!resultData) {
            const href = !isEmpty(resultData) && CommonHelper.createDownloadFileUrl(resultData?.fileDownloadName, resultData?.fileContents);
            setFile({ fileContents: href });
        } else
            setResultMessageClass(resultMessageClass);
    }


    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText('adminPanel.common.ordersPayment')}
            showModal={true}
            noSpace={true}
            closeModal={() => CommonHelper.safeFunctionCall(closeModal)}>

            <div className='p-3'>
                <NsDataGridWithServiceCall service={GetUserAccountingPaymentReportService}
                    parameters={{
                        orderId,
                    }}
                    columnDefs={columns}
                    hasPagination={true}
                    getData={getData} />
            </div>

            {resultMessageClass}

            <NsDownloadFile file={file} />

        </NsModal>
    );
};

export default UserOrderPaymentsModal;