import Links from "../../configs/links/AllLinks";
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { DeleteRequest, GetRequest, PatchRequest, PostRequest } from "../bases/BaseService";

const GetApUserDepartmentService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDepartment), parameters, callback);
};

const PostApUserDepartmentService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApUserDepartment), data, callback, showSuccessResponseMessage);
};

const GetApUserDepartmentByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDepartmentById), id), {}, callback);
};

const GetApUserDepartmentTreesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDepartmentTrees), parameters, callback);
};

const GetApDepartments = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDepartment), {}, callback);
};

const EditApUserDepartmentByIdService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApUserDepartmentById), id), data, callback, showSuccessResponseMessage);
};

const GetApUserDepartmentTreesByIdService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUserDepartmentTreesById), parameters, callback);
};

const EditApUserDepartmentTreesByIdService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApUserDepartmentTreesById), data, callback, showSuccessResponseMessage);
};

const DeleteApUserDepartmentByIdService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApUserDepartmentById), id), {}, callback, showSuccessResponseMessage);
};

export {
    GetApUserDepartmentService,
    GetApUserDepartmentByIdService,
    GetApUserDepartmentTreesService,
    GetApDepartments,
    EditApUserDepartmentByIdService,
    PostApUserDepartmentService,
    GetApUserDepartmentTreesByIdService,
    EditApUserDepartmentTreesByIdService,
    DeleteApUserDepartmentByIdService
};