import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import NsToggleGroupButton from '../../../../../components/buttons/NsToggleGroupButton';

class PoExtendedServiceBagTopFolding extends React.Component {

    constructor(props) {
        super(props);
        this.topFoldingOptions = ConstantsWithTranslation.getBagTopFoldingOptions(null);

        this.state = {
            hasService: false,
            selectedTopFolding: false,
            refreshExtendedServicesDate: props.refreshExtendedServicesDate
        }
    }

    componentDidMount = () => this.prepareService();

    componentDidUpdate = (prevProps) => {
        if (prevProps.refreshExtendedServicesDate !== this.state.refreshExtendedServicesDate)
            this.prepareService();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshExtendedServicesDate !== prevState.refreshExtendedServicesDate)
            return ({ refreshExtendedServicesDate: nextProps.refreshExtendedServicesDate });
        return null;
    }

    prepareService = () => {
        let service = OrderHelper.getSpecificService(this.props.routeId, Enumerations.extendedServices.topFolding);
        this.setState({
            selectedTopFolding: !isEmpty(service?.selectedItem) ? service.selectedItem : this.topFoldingOptions[0],
            hasService: !isEmpty(service),
            price: service?.price
        });
    }

    topFoldingClicked = (selectedItem) => {
        this.setState({ selectedTopFolding: selectedItem }, () => {
            OrderHelper.updateExtendedService(this.props.routeId, Enumerations.extendedServices.topFolding, selectedItem);
            CommonHelper.safeFunctionCall(this.props.refreshSummary);
        });
    }

    render() {
        const { hasService, selectedTopFolding, price } = this.state;

        return (hasService
            ? <Row className='my-1'>
                <Col xs={8}>
                    <NsToggleGroupButton isShowGroupLabel={true}
                        label={getTranslatedRawText('regularOrder.extendedService.topFolding')}
                        items={this.topFoldingOptions}
                        onClick={this.topFoldingClicked}
                        selectedItemId={selectedTopFolding?.key} />
                </Col>
                <Col className='align-self-end'>
                    {selectedTopFolding?.key === Enumerations.topFolding.has &&
                        <div className='font-size-12 mb-2 text-nowrap'>
                            <b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(price)}</b>
                        </div>
                    }
                </Col>
            </Row >

            : null
        );
    }
}

export default PoExtendedServiceBagTopFolding;