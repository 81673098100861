import React from 'react';
import { GetNewsService } from "../../../../../services/adminPanel/AdminNewsService";
import ApBasesPostContent from "../../bases/ApBasesPostContent";

const ApNewsPost = (props) => {

    return (
        <ApBasesPostContent service={GetNewsService} />
    )
}

export default React.memo(ApNewsPost);