import React, { useEffect, useState } from 'react';

import NsModal from '../../../../../components/modals/NsModal';
import NsTab from '../../../../../components/tabs/NsTab';
import Config from '../../../../../configs/Config';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import { GetUserBusinessPartnerBasicInfoByIdService } from '../../../../../services/adminPanel/AdminUserService';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApBaseUserInfoModal from '../bases/ApBaseUserInfoModal';
import ApBusinessPartnerSecureKeyModal from './ApBusinessPartnerSecureKeyModal';

const ApBusinessPartnerEmbedCodeModal = (props) => {

    const { userId, showModal, closeModal } = props;

    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [showXSecureApiKeyModal, setShowXSecureApiKeyModal] = useState(false);
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getBasicUserInfo();
    }, [])

    const getBasicUserInfo = () => {
        setIsLoading(true);
        GetUserBusinessPartnerBasicInfoByIdService(userId, getBasicUserInfoCallback);
    }

    const getBasicUserInfoCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            setData(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const getEmbedCodeDataByEnum = () => {
        let selectedTab = selectedTabIndex + 1;
        switch (selectedTab) {
            case Enumerations.embedCodeFormat.wordPress: return data?.wordPressEmbedCode;
            case Enumerations.embedCodeFormat.iframe: return data?.iFrameEmbedCode;
            case Enumerations.embedCodeFormat.script: return '';
        };
    }

    const showUserInfoModalClicked = () => setShowUserInfoModal(true);

    const showXSecureApiKeyModalClicked = () => setShowXSecureApiKeyModal(true);

    const headerButtons = [
        { wrapperClassName: '', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: showUserInfoModalClicked },
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-api-key', label: 'adminPanel.usersInfo.xSecureApiKey', onClick: showXSecureApiKeyModalClicked }
    ]

    const tabChanged = (selectedTabIndex) => setSelectedTabIndex(selectedTabIndex);
    const fullNameUserData = () => ({ fullName: data?.fullName })

    const copyEmbedCodeClick = (value) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(value);
        }
    }

    return (
        <NsModal
            maxWidth='md'
            showModal={showModal}
            closeModal={closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.usersInfo.BusinessPartnerEmbedCode")}>

            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />

            <div className='background-color p-2'>
                <ApBaseLabelInfo info={fullNameUserData()}
                    hasAccessInfo={true}
                    isLoading={isLoading} />
            </div>

            <div className='p-3 pt-0'>
                <NsTab tabs={ConstantsWithTranslation.getEmbedCodeTabs()}
                    value={selectedTabIndex}
                    onChange={tabChanged}>
                </NsTab>
                <div className='d-flex flex-row card position-relative p-2 min-height-10rem max-height-10rem'>
                    <div className='col-11 pe-2 overflow-auto'>
                        {getEmbedCodeDataByEnum()}
                    </div>
                    <div onClick={() => copyEmbedCodeClick(getEmbedCodeDataByEnum())} className='col-1 position-sticky top-0 end-0  d-flex align-items-center justify-content-center background-disable-color'>
                        <i className='ns-icon-copy h2 cursor-pointer'></i>
                    </div>
                </div>
            </div>

            {showUserInfoModal &&
                <ApBaseUserInfoModal showModal={true}
                    selectedUserId={userId}
                    closeModal={() => setShowUserInfoModal(false)} />
            }

            {showXSecureApiKeyModal &&
                <ApBusinessPartnerSecureKeyModal showModal={true}
                    userId={userId}
                    closeModal={() => setShowXSecureApiKeyModal(false)} />
            }

            {resultMessageClass}
        </NsModal>
    );
};

export default React.memo(ApBusinessPartnerEmbedCodeModal);