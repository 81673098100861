import React from "react";

import ApBaseUploadFile from "../../bases/ApBaseUploadFile";

const ApBasesImageContent = (props) => {

    const { content, stateHandler, isChangeFile, fileUploaderCategoryType, serviceCall, userId } = props;
    return (
        <>
            <ApBaseUploadFile value={content?.uploadedFileDetail || null}
                content={content}
                serviceCall={serviceCall}
                isChangeFile={isChangeFile}
                fileUploaderCategoryType={fileUploaderCategoryType}
                fileUploadHandler={stateHandler}
                userId={userId || null} />
        </>

    );
}

export default React.memo(ApBasesImageContent);