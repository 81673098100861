import React from 'react';

import NsGroupNumberInput from '../../../../../components/inputs/NsGroupNumberInput';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsTypography from '../../../../../components/text/NsTypography';
import NsTooltip from '../../../../../components/tooltips/NsTooltip';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Constants from '../../../../../configs/Constants';

class FilmFileActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAlignTooltipOpen: false,
            isResizeTooltip: false,
            filmWidth: props.filmWidth,
            filmHeight: props.filmHeight,
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.filmWidth != this.state.filmWidth || newProps.filmHeight != this.state.filmHeight)
            this.setState({
                filmWidth: newProps.filmWidth,
                filmHeight: newProps.filmHeight,
            });
    }

    valueChanged = (value, valueStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        this.setState({
            [valueName]: value
        }, this.changeNewFileSizeInParent);
    }

    toggleTooltipClicked = (isTooltipOpenName) => {
        let name = CommonHelper.getVariableName(isTooltipOpenName);
        this.setState({ [name]: !this.state[name] });
    }

    changeNewFileSizeInParent = (isAutoMatchClicked) => {
        CommonHelper.safeFunctionCall(this.props.setNewWidthAndHeightFilm, this.state.filmWidth,
            this.state.filmHeight, isAutoMatchClicked);
    }

    setFilmSizeToMasterImageSizeClicked = () => {
        this.setState({
            filmWidth: this.props.masterImageInfo?.width,
            filmHeight: this.props.masterImageInfo?.height
        }, () => this.changeNewFileSizeInParent(true));
    }

    render() {
        const alignButtons = Constants.alignButtons;
        const { alignFilm } = this.props;
        const { filmWidth, filmHeight, isAlignTooltipOpen, isResizeTooltip } = this.state;
        return (
            <div className='d-flex flex-column align-items-center align-items-md-start'>

                <div className='masterFileModal-tour-align'>
                    <GuidanceToolTip open={isAlignTooltipOpen}
                        title='regularOrder.modal.align'
                        tooltipTitle='regularOrder.modal.howToMatch'
                        tooltipDescription='regularOrder.modal.howToMatchDescription'
                        onClick={() => this.toggleTooltipClicked({ isAlignTooltipOpen })}
                        closeTooltip={() => this.setState({ isAlignTooltipOpen: false })} />

                    <div className='d-flex flex-wrap gap-1 mb-2'>
                        {alignButtons.map((item, index) =>
                            <div key={index}>

                                <NsButton startIcon={item.icon}
                                    onClick={() => alignFilm(item.actionType)}
                                    className='light' />
                            </div>
                        )}
                    </div>
                </div>

                <div className='masterFileModal-tour-resize'>
                    <GuidanceToolTip open={isResizeTooltip}
                        title='regularOrder.modal.resizingFilmFile'
                        tooltipTitle='regularOrder.modal.howToResize'
                        tooltipDescription='regularOrder.modal.howToResizeDescription'
                        onClick={() => this.toggleTooltipClicked({ isResizeTooltip })}
                        closeTooltip={() => this.setState({ isResizeTooltip: false })} />

                    <div className='d-flex'>
                        <NsButton label='regularOrder.modal.fit'
                            wrapperClassName='pb-2 masterFileModal-tour-fit'
                            size='small'
                            startIcon='ns-icon-fit'
                            className='secondary-outline'
                            onClick={this.setFilmSizeToMasterImageSizeClicked} />
                    </div>

                    <NsGroupNumberInput label="regularOrder.common.width"
                        className='mb-2'
                        value={filmWidth}
                        maxLength={2}
                        unit={getTranslatedRawText('common.mm')}
                        step={1}
                        onChanged={(value) => this.valueChanged(value, { filmWidth })} />

                    <NsGroupNumberInput label="regularOrder.common.height"
                        value={filmHeight}
                        maxLength={2}
                        unit={getTranslatedRawText('common.mm')}
                        step={1}
                        onChanged={(value) => this.valueChanged(value, { filmHeight })} />
                </div>


            </div>
        );
    }
}

function GuidanceToolTip(props) {
    return <NsTypography variant='inherit' className='d-flex align-items-center section-title mb-2'>
        {getTranslatedRawText(props.title)}
        <NsTooltip open={props.open}
            title={<NsTypography variant='inherit' className='font-size-14'>
                <p className='section-title'>
                    {getTranslatedRawText(props.tooltipTitle)}
                </p>
                {getTranslatedRawText(props.tooltipDescription)}
            </NsTypography>}
            closeTooltip={props.closeTooltip}>
            <NsButton className='secondary-text'
                startIcon='ns-icon-question'
                onClick={props.onClick} />
        </NsTooltip>
    </NsTypography>
}

export default FilmFileActions;

