import React from 'react';
import { Col, Row } from 'react-bootstrap';
import NsButton from '../../../../components/buttons/NsButton';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import NsEditFileActionTab from '../../../../components/editFileActions/NsEditFileActionTab';
import NsModal from '../../../../components/modals/NsModal';
import Enumerations from '../../../../configs/Enumerations';
import { GetImageService } from '../../../../services/common/CommonService';
import { FileActionsService, GetFileInfoService } from '../../../../services/fileManager/FileManagementService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ImageHelper from '../../../../utilities/ImageHelper';
import { messageClass } from '../../../../utilities/MessageModalHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';


class FmEditFileModal extends React.Component {
    constructor(props) {
        super(props);
        this.mainButtonList = [
            { label: 'common.save', onClicked: this.confirmClicked, className: 'primary' },
            { label: 'common.cancel', onClicked: this.cancelClicked, className: 'primary-outline' },
        ];

        this.state = {
            imgId: '',
            imgName: null,
            imgUrl: '',
            imgContent: '',
            imgContentActions: [],
            imgResizeActionsStack: [],
            imgHeight: '0',
            imgWidth: '0',
            imgResolution: 0,
            selectedTabIndex: 0,
            isLoading: true,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => this.getFileInfo(this.props.selectedItemContent);

    cancelClicked = () => CommonHelper.safeFunctionCall(this.props.onClose);

    undoAllChangesClicked = () => this.getFileInfo(this.props.selectedItemContent);

    confirmClicked = () => {
        this.changeisLoadingState(true);
        let imgContentActions = this.mergeActionsAndResizes();
        if (imgContentActions.length > 0) {
            let data = {
                id: this.state.imgId,
                name: this.state.imgName,
                userFileChanges: imgContentActions
            }
            FileActionsService(data, this.FileActionsCallback);
        } else
            CommonHelper.safeFunctionCall(this.props.onClose);
    }

    FileActionsCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            CommonHelper.safeFunctionCall(this.props.onClose, Enumerations.fileManagerContentType.file);
        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    setNewContent = (newContent, isRotate90, callback) => {
        let width = this.state.imgWidth;
        let height = this.state.imgHeight;
        if (!!isRotate90) {
            width = this.state.imgHeight;
            height = this.state.imgWidth;
        }
        this.setState({
            imgWidth: width,
            imgHeight: height,
            imgContent: newContent,
            imgContentActions: this.mergeActionsAndResizes(),
            imgResizeActionsStack: [],
        }, () => callback(true));
    }

    imgContentActionsChanged = (newAction) => {
        newAction.width = Number(this.state.imgWidth);
        newAction.height = Number(this.state.imgHeight);
        this.setState({ imgContentActions: [...this.state.imgContentActions, newAction] });
    }

    imgContentSizeChanged = (imgResizeActionsStack, imgWidth, imgHeight) => {
        this.setState({ imgResizeActionsStack, imgWidth, imgHeight });
    }

    getIamgeCallback = (resultData, result, messageClassModal) => {
        this.changeisLoadingState(false);
        if (!result?.data?.hasError)
            this.setState({ imgContent: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getFileInfoCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            if (!resultData.resolution || !resultData.width || !resultData.height)
                this.setState({ resultMessageClass: messageClass('common.message.unTrustableFile') });
            this.setState({
                imgId: resultData.id,
                imgName: resultData.name,
                imgUrl: resultData.contentUrl,
                imgContentActions: [],
                imgResizeActionsStack: [],
                imgWidth: parseFloat(resultData.width) + '',
                imgHeight: parseFloat(resultData.height) + '',
                imgResolution: parseInt(resultData.resolution, 10),
            }
                , GetImageService(resultData.contentUrl, this.getIamgeCallback)
            );

        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false, });
    }

    getFileInfo = (selectedItemContent) => {
        this.changeisLoadingState(true);
        if (selectedItemContent) {
            let parameters = {
                id: selectedItemContent.id,
                name: selectedItemContent.name
            }
            GetFileInfoService(parameters, this.getFileInfoCallback)
        }
    }

    mergeActionsAndResizes = () => {
        return !isEmpty(this.state.imgResizeActionsStack)
            ? [...this.state.imgContentActions, ...this.state.imgResizeActionsStack]
            : [...this.state.imgContentActions];
    }

    changeisLoadingState = (status) => this.setState({ isLoading: status });

    render() {
        const { imgName, imgContent, imgWidth, imgHeight, isLoading, imgContentActions, imgResizeActionsStack } = this.state;

        const imgNameText = CommonHelper.textTruncate(imgName ? imgName : '')
        const mobileTitle = CommonHelper.stringFormat(getTranslatedRawText("fileManager.modals.editFile.title"), imgNameText);

        return (
            <NsModal maxWidth="md"
                dialogTitle={mobileTitle}
                showModal={this.props.showModal}
                closeModal={() => this.props.onClose()}
                primaryActionButton={this.confirmClicked}
                showModalDate={this.props.showModalDate}
                className="overflow-hidden"
                actions={<NsStickyButton stickyButtons={this.mainButtonList} isModal={true} />}>

                <NsLoadingProgress visible={isLoading} className='position-absolute' />
                <Row>
                    <Col md={7} className="mb-3 align-self-center">
                        <ImagePreview imgContent={imgContent}
                            imgWidth={imgWidth}
                            imgHeight={imgHeight}
                            imgName={imgName} />
                    </Col>
                    <Col md={5} >
                        <div className="d-flex flex-column">
                            <div className='d-flex ms-auto mb-2'>
                                <NsButton label={getTranslatedRawText('fileManager.common.undoAllChanges')}
                                    onClick={this.undoAllChangesClicked}
                                    startIcon='ns-icon-undo'
                                    size='small'
                                    disabled={isEmpty(imgContentActions) && isEmpty(imgResizeActionsStack)}
                                    className='secondary-outline' />
                            </div>
                            <div className='d-flex flex-column border rounded background-element-color'>

                                <NsEditFileActionTab tabClassName={'small-tab'}
                                    imgWidth={imgWidth}
                                    imgHeight={imgHeight}
                                    imgContent={imgContent}
                                    changeisLoadingState={this.changeisLoadingState}
                                    setNewContent={this.setNewContent}
                                    imgContentSizeChanged={this.imgContentSizeChanged}
                                    imgContentActionsChanged={this.imgContentActionsChanged} />
                            </div>

                        </div>
                    </Col>
                </Row>
                {this.state.resultMessageClass}
            </NsModal >
        );
    }
}

function ImagePreview(props) {
    const imgWidth = ImageHelper.getImageAppliedWidth(props.imgWidth + '', props.imgHeight + '');
    const imgHeight = ImageHelper.getImageAppliedHeight(props.imgWidth + '', props.imgHeight + '');
    const imgName = props.imgName;
    return (
        <div className="border w-100 background-light-text-color d-flex flex-column justify-content-center align-items-center "
            style={{ 'height': '500px' }}
        >
            <img src={props.imgContent}
                className='border'
                alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.imagePreview"), imgName)}
                width={imgWidth}
                height={imgHeight}
            />
        </div>);
}

export default FmEditFileModal;