import React from 'react';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import FileManager from '../../../../fileManager/FileManager';
import OBaseWrapper from '../../../bases/OBaseWrapper';

class PoFileManager extends React.Component {
  
    render() {
        return (
            <OBaseWrapper className={'h-100'} title='headers.bottom.fileManager'>
                <FileManager isModal={true}
                    isUsedInPo={true}
                    toggleProfessionalOrderTour={this.props.toggleProfessionalOrderTour} />
            </OBaseWrapper>
        );
    }
}

export default PoFileManager;