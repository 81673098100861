import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { DeleteRequest, GetRequest, PatchRequest, PostRequest } from '../bases/BaseService';

const GetGalleryService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApGallery), parameter, callback);
}

const GetGalleryByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApGalleryById), id), {}, callback);
}

const EditGalleryService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApGallery), id), data, callback, showSuccessResponseMessage);
}

const DeleteGalleryService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApGallery), id), {}, callback, showSuccessResponseMessage);
}

const PostGalleryService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApGallery), data, callback, showSuccessResponseMessage);
}

const GetGalleryCategoriesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApGalleryCategories), parameter, callback);
}

const GetBasicGalleryCategoriesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApBasicGalleryCategories), {}, callback);
}

const GetGalleryCategoryByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApGalleryCategoryById), id), {}, callback);
}

const EditGalleryCategoryService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApGalleryCategory), id), data, callback, showSuccessResponseMessage);
}

const DeleteGalleryCategoryService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApGalleryCategory), id), {}, callback, showSuccessResponseMessage);
}

const PostGalleryCategoryService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApGalleryCategory), data, callback, showSuccessResponseMessage);
}

export {
    GetGalleryService,
    GetGalleryByIdService,
    EditGalleryService,
    DeleteGalleryService,
    PostGalleryService,
    GetBasicGalleryCategoriesService,
    GetGalleryCategoriesService,
    GetGalleryCategoryByIdService,
    EditGalleryCategoryService,
    DeleteGalleryCategoryService,
    PostGalleryCategoryService,

}