import Links from "../../configs/links/AllLinks"
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest } from "../bases/BaseService"

const GetCustomerFilesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCustomerFiles), parameter, callback);
};

const GetCustomerFileByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCustomerFileById), id), {}, callback);
};

const GetOriginFileService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDownloadOriginFile), id), {}, callback);
};

export {
    GetCustomerFilesService,
    GetCustomerFileByIdService,
    GetOriginFileService
}