import React, { useEffect, useState } from 'react';

import { GetApDfbByEmployeesGridService } from '../../../../../services/adminPanel/AdminDashboardService';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import { GetApDepartments } from '../../../../../services/adminPanel/AdminDepartmentService';
import NsAutocomplete from '../../../../../components/dropdowns/NsAutoComplete';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApDfbEmployeeFeedbacksModal from './ApDfbEmployeeFeedbacksModal';
import Enumerations from '../../../../../configs/Enumerations';
import NsCard from '../../../../../components/cards/NsCard';

const ApDfbEmployeesGrid = ({ refreshDate, parameters }) => {

    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showEmployeeFeedbacksModal, setShowEmployeeFeedbacksModal] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [callServiceDate, setCallServiceDate] = useState(null);

    const columns = [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => employeeInfoDetailClicked(id),
            }
        },
        {
            field: 'employeeFullName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.employeeFullName'),
            width: 200
        },
        {
            field: 'averageRate',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.avgRate'),
            dataType: Enumerations.gridType.number,
            sortable: true,
            width: 130
        },
        {
            field: 'quantity',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.qty'),
            dataType: Enumerations.gridType.number,
            sortable: true,
            width: 90
        }
    ];

    useEffect(() => {
        GetApDepartments(getApDepartmentsCallback);
    }, [])

    const getApDepartmentsCallback = (resultData, result, resultMessageClass) => {
        if (!result.data.hasError)
            setDepartments(resultData);
    }

    const employeeInfoDetailClicked = (id) => {
        setSelectedUserId(id);
        setShowEmployeeFeedbacksModal(true);
    }

    const closeEmployeeFeedbacksModal = () => setShowEmployeeFeedbacksModal(false);

    const employeeDepartmentChanged = (value, isValid) => {
        setSelectedDepartment(value);
        setCallServiceDate(new Date());
    }

    return (
        <NsCard className="p-3 h-100">
            <div className='d-flex justify-content-between align-items-end mb-2'>
                <div className='page-title mb-2'>{getTranslatedRawText('adminPanel.feedback.employees')}</div>
                <div className='w-50'>
                    <NsAutocomplete label={getTranslatedRawText('adminPanel.sidebar.departments')}
                        items={departments}
                        value={selectedDepartment}
                        onChange={employeeDepartmentChanged} />
                </div>
            </div>
            <NsDataGridWithServiceCall key={refreshDate}
                callServiceDate={callServiceDate}
                service={GetApDfbByEmployeesGridService}
                parameters={{
                    ...parameters,
                    departmentId: selectedDepartment?.key
                }}
                hasPagination={true}
                columnDefs={columns} />

            {showEmployeeFeedbacksModal &&
                <ApDfbEmployeeFeedbacksModal selectedUserId={selectedUserId}
                    closeModal={closeEmployeeFeedbacksModal} />
            }

        </NsCard >
    )
}

export default React.memo(ApDfbEmployeesGrid);