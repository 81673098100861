import React, { Component } from 'react';

import { GetCashBackHistoriesService } from '../../../../../services/ProfileSetting/UserDashboardService';
import { GetApDaCbHistoriesService } from '../../../../../services/adminPanel/AdminDashboardService';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../../components/text/NsTypography';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import DateHelper from '../../../../../utilities/DateHelper';
import NsTab from '../../../../../components/tabs/NsTab';

class UpDashboardCashbackHistory extends Component {
    constructor(props) {
        super(props);
        this.tabs = ConstantsWithTranslation.getCashbackTabs();
        this.columns = [
            {
                field: 'voucherNumber',
                width: '150',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.voucherNumber'),
                dataType: Enumerations.gridType.number,
            },
            {
                field: 'date',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.date'),
                width: 110,
                dataType: Enumerations.gridType.date,
            },
            {
                field: 'description',
                headerName: getTranslatedRawText('profile.dataGridColumns.detail'),
                width: 180,
            },
            {
                field: 'discount',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.amount'),
                dataType: Enumerations.gridType.price,
                width: 160,
            },
        ];
        this.state = {
            selectedTabIndex: 0,
            selectedTabId: this.tabs[0].id,
        };
    }

    tabChanged = (newValue, selectedTab) => {
        newValue !== this.state.selectedTabIndex && this.setState({ selectedTabIndex: newValue, selectedTabId: selectedTab.id })
    }

    explanationTab = () => {
        const { currentCashbackInfo, cashbackForecast, cashbackPlans } = this.props;
        if (isEmpty(currentCashbackInfo) || isEmpty(cashbackForecast) || isEmpty(cashbackPlans)) return;

        const isRemainingTimeMoreThanADay = currentCashbackInfo?.remainingMinutes > 1440 && true;
        const remainingTime = isRemainingTimeMoreThanADay
            ? (Math.floor(currentCashbackInfo.remainingMinutes / 60 / 24))
            : (Math.floor(currentCashbackInfo.remainingMinutes / 60));

        return <div className='pt-2 pb-3 px-1'>
            <NsTypography variant='inherit font-size-14 mb-2 d-block'>
                {CommonHelper.stringFormat(getTranslatedRawText('profile.cashback.purchaseAmountPart1'),
                    DateHelper.getDatePart(currentCashbackInfo?.firstDay),
                    CommonHelper.currencyFormatByDefaultConfig(cashbackForecast?.totalPurchasedAmount),
                    CommonHelper.currencyFormatByDefaultConfig(cashbackForecast?.currentDiscountAmount))}
            </NsTypography>
            {/* <NsTypography variant='inherit font-size-14 d-block'>
                {CommonHelper.stringFormat(getTranslatedRawText('profile.cashback.cashbackRate'),
                    isEnCulture() ? `${cashbackForecast?.discountPercentage}%` : `%${cashbackForecast?.discountPercentage}`,
                    '2.3')}
            </NsTypography> */}
            {cashbackPlans?.length > 1 && <NsTypography variant='inherit font-size-14 d-block mb-2'>
                {CommonHelper.stringFormat(getTranslatedRawText(cashbackForecast?.discountPercentage > 0 && cashbackForecast?.currentDiscountAmount > 0
                    ? 'profile.cashback.untilNextDiscountType1'
                    : 'profile.cashback.untilNextDiscountType2'),
                    CommonHelper.currencyFormatByDefaultConfig(cashbackForecast?.remaindToNextDiscount),
                    `${cashbackForecast?.nextDiscountPercentage.toFixed(2)}%`,
                    CommonHelper.currencyFormatByDefaultConfig(cashbackForecast?.nextDiscountAmount))}
            </NsTypography>}
            <NsTypography variant='inherit font-size-14 d-block'>
                {CommonHelper.stringFormat(getTranslatedRawText('profile.cashback.daysLeft'),
                    DateHelper.getDatePart(currentCashbackInfo?.firstDay),
                    DateHelper.getDatePart(currentCashbackInfo?.lastDay),
                    `${Math.round(remainingTime)} ${isRemainingTimeMoreThanADay ? getTranslatedRawText('festival.countdown.days') : getTranslatedRawText('product.detail.hours')}`)}
            </NsTypography>
            {!cashbackForecast?.currentDiscountAmount == 0 &&
                <NsTypography variant='inherit font-size-14 d-block'>
                    {CommonHelper.stringFormat(getTranslatedRawText('profile.cashback.cashbckDeposit'),
                        CommonHelper.currencyFormatByDefaultConfig(cashbackForecast?.currentDiscountAmount))}
                </NsTypography>}
        </div>
    }

    createParameters = () => this.props.isUsedInAdminPanel
        ? {
            'Filter.Id': this.props?.detailLedgerId,
        }
        : null

    render() {
        const { selectedTabIndex, selectedTabId, isLoading } = this.state;
        const { isUsedInAdminPanel, callServiceDate } = this.props;
        const isHistoryTab = selectedTabId !== this.tabs[0].id

        return <>
            <NsTab tabs={this.tabs}
                value={selectedTabIndex}
                onChange={this.tabChanged} />
            <div className="label-line-line"></div>
            {
                isLoading
                    ? <div className='w-100'>
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                            ]} />
                    </div>
                    : isHistoryTab
                        ? <div className='py-2 pb-4'>
                            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                                service={isUsedInAdminPanel ? GetApDaCbHistoriesService : GetCashBackHistoriesService}
                                parameters={this.createParameters()}
                                columnDefs={this.columns}
                                hasPagination={true}
                            />
                        </div>
                        : this.explanationTab()
            }
        </>

    }
}

export default UpDashboardCashbackHistory;
