import React, { useCallback, useEffect, useRef, useState } from 'react';

import NsAdminPanelContentWrapper from '../../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import { GetOrdersService } from '../../../../../services/adminPanel/AdminOrderService';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import ApShareSheetOrderDetailModal from './ApShareSheetOrderDetailModal';
import { getApOrderColumns } from './ApShareSheetOrderHelper';
import ApShareSheetOrderSearch from './ApShareSheetOrderSearch';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsTab from '../../../../../components/tabs/NsTab';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';

export const ShareSheetOrderContent = (props) => {

    const { isOrderSelection, hasCheckbox, refreshOrdersGrid, customerDetails, isQueue, selectedRows } = props;

    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState();
    const [searchData, setSearchData] = useState(props.searchData);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState(null);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData]);

    useEffect(() => {
        if (!!refreshOrdersGrid) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
    }, [refreshOrdersGrid])

    const selectOrderClicked = (order) => CommonHelper.safeFunctionCall(selectedRows, order);
    const getTabs = () => {
        const tabs = [];

        if (props?.sharedSheetOrderStateTypeTabs?.length > 0) {
            // باید بیشتر فکر کنیم. چون در این تب، حتی وضعیت هایی را که نمی خواهیم می آورد
            // tabs.push({
            //     id: null,
            //     label: getTranslatedRawText('adminPanel.common.all')
            // });
            for (let i = 0; i < props?.sharedSheetOrderStateTypeTabs?.length; i++) {
                tabs.push({
                    id: props.sharedSheetOrderStateTypeTabs[i],
                    label: ConstantsWithTranslation.getSharedSheetOrderStateType(props.sharedSheetOrderStateTypeTabs[i])
                })
            }
            if(isEmpty(selectedTab))
                setSelectedTab(tabs[0]);
        }
        return tabs;
    }

    const tabChanged = (selectedTabIndex, selectedTab) => {
        setSelectedTabIndex(selectedTabIndex);
        setSelectedTab(selectedTab);
        setCallServiceDate(new Date());
    }

    const getParameters = () => {
        const parameters = {
            "Filter.NasInvoiceIds": searchData?.invoiceNo || null,
            "Filter.SharedSheetOrderStateTypes": selectedTab?.id || searchData?.lastStatuses?.id || props.searchData?.lastStatuses?.id || [],
            "Filter.ProductCategoryIds": searchData?.productCategories?.id || [],
            "Filter.AdminUserId": searchData?.selectedAdmin?.userId || null,
            "Filter.MaxPrice": searchData?.maxPaymentInAdvance || null,
            "Filter.MinPrice": searchData?.minPaymentInAdvance || null,
            "Filter.MinDiscountAmount": searchData?.minDiscountAmount || null,
            "Filter.MaxDiscountAmount": searchData?.maxDiscountAmount || null,
            "Filter.TwoSidePrintingTypes": searchData?.printedSides?.id || null,
            "Filter.SubmitTimeFrom": searchData?.submitTimeFrom || '',
            "Filter.SubmitTimeTo": searchData?.submitTimeTo || '',
            "Filter.NotRegulatedOrdersOnly": searchData?.ServerNameFileRegulator ? true : searchData?.NotProcessedOrdersOnly || null,
            "Filter.ServerNameFileRegulator": searchData?.ServerNameFileRegulator || '',
            "Filter.StatusDateFrom": searchData?.statusDateFrom || '',
            "Filter.StatusDateTo": searchData?.statusDateTo || '',
            "Filter.TurnaroundTypes": searchData?.turnarounds?.id || [],
            "Filter.AgencyId": searchData?.selectedAgency?.key || [],
            "Filter.UserId": searchData?.selectedUser?.userId || searchData?.user || props?.searchData?.user || null,
            "Filter.OrderApplicationTypes": searchData?.orderApplicationTypes?.id || [],
            "Filter.TotalPrice": searchData?.totalPrice || '',
            "Filter.estimateReadyToDeliverTimeFrom": searchData?.estimateReadyToDeliverTimeFrom || '',
            "Filter.estimateReadyToDeliverTimeTo": searchData?.estimateReadyToDeliverTimeTo || '',
        };

        CommonHelper.pushParameterToObject(searchData?.selectedProductsId, "Filter.ProductId", parameters);
        return parameters;
    };



    const handlers = {

        orderDetailClicked: (id) => {
            setSelectedOrder(orders.filter(order => order.id === id)[0]);
            setShowOrderDetailModal(true);
        },

        getSearchData: useCallback((data) => {
            setSearchData(data);
        }, []),

        closeApShareSheetOrderDetailModal: (messageClassModal) => {
            setShowOrderDetailModal(false);
            if (!!messageClassModal) {
                setResultMessageClass(messageClassModal);
                setCallServiceDate(new Date());
            }
        },

        updateSharedSheetOrderData: (data) => {
            setIsLoading(true);
            setCallServiceDate(data);
        },

        getData: (orders, isLoading) => {
            setOrders(orders);
            setIsLoading(isLoading);
        }
    }

    const { orderDetailClicked, getData, getSearchData, closeApShareSheetOrderDetailModal, updateSharedSheetOrderData } = handlers;

    const columns = getApOrderColumns(selectOrderClicked, orderDetailClicked, isOrderSelection,
        isLoading, hasCheckbox);

    return (
        <>
            <ApShareSheetOrderSearch customerDetails={customerDetails}
                setSearchData={getSearchData}
                isQueue={isQueue}
                isLoading={isLoading} />

            {props?.sharedSheetOrderStateTypeTabs &&
                <NsTab tabs={getTabs()}
                    value={selectedTabIndex}
                    onChange={tabChanged}>
                </NsTab>}

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetOrdersService}
                parameters={getParameters()}
                columnDefs={columns}
                hasPagination={true}
                selectedRows={selectedRows}
                hasCheckbox={hasCheckbox}
                getData={getData} />

            {showOrderDetailModal &&
                <ApShareSheetOrderDetailModal showModal={true}
                    selectedOrder={selectedOrder}
                    closeModal={closeApShareSheetOrderDetailModal}
                    updateSharedSheetOrderData={updateSharedSheetOrderData} />
            }
            {resultMessageClass}
        </>
    );
}

const ApShareSheetOrder = () => {
    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.shareSheets")}
            icon={'ns-icon-orders h1'}>
            <ShareSheetOrderContent />
        </NsAdminPanelContentWrapper>
    );
}

export default React.memo(ApShareSheetOrder);