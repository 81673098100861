import React from "react";
import { Col, Row } from "react-bootstrap";

import ApBasesDfbStrengthWeeknessChart from "../bases/ApBasesDfbStrengthWeeknessChart";
import ApBasesDfbPercentageStatusChart from "../bases/ApBasesDfbPercentageStatusChart";
import ApBasesDfbTotalByStatusGedgets from "../bases/ApBasesDfbTotalByStatusGedgets";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import ApDfbRatingParameterProgress from "./ApDfbRatingParameterProgress";
import ApBasesDfbTotalLineChart from "../bases/ApBasesDfbTotalLineChart";
import ApBasesDfbStatusBarChart from "../bases/ApBasesDfbStatusBarChart";
import ApBasesDfbCustomersGrid from "../bases/ApBasesDfbCustomersGrid";
import ApDfbRatingParameterChart from "./ApDfbRatingParameterChart";
import ApBasesDfbProvinceMap from "../bases/ApBasesDfbProvinceMap";
import ApBasesDfbCitiesGrid from "../bases/ApBasesDfbCitiesGrid";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import Enumerations from "../../../../../configs/Enumerations";
import ApDfbAnnouncementsGrid from "./ApDfbAnnouncementsGrid";
import ApDfbLastFeedbacksGrid from "./ApDfbLastFeedbacksGrid";
import ApBasesDfbComments from "../bases/ApBasesDfbComments";

const ApDfbAnnouncements = (props) => {

    const { refreshDate } = props;

    const parameters = { ...props.parameters, feedbackType: Enumerations.feedbackTypes.announcementContent }

    return (
        <Row className="g-2">
            {hasAccess([UserClaimEnumerations.getApDfbTotalByStatusGedgets]) && <Col xs={12} className="mb-3">
                <ApBasesDfbTotalByStatusGedgets parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbUserFeedbacks]) && <Col xs={12} className="mb-3">
                <ApBasesDfbTotalLineChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbStrengthWeeknessChart]) && <>
                <Col lg={6} className="mb-3">
                    <ApBasesDfbStrengthWeeknessChart title={getTranslatedRawText('adminPanel.feedback.strengths')}
                        feedbackTypeMetricType={Enumerations.feedbackMetricTypes.strengths}
                        parameters={parameters}
                        refreshDate={refreshDate} />
                </Col>
                <Col lg={6} className="mb-3">
                    <ApBasesDfbStrengthWeeknessChart title={getTranslatedRawText('adminPanel.feedback.weaknesses')}
                        feedbackTypeMetricType={Enumerations.feedbackMetricTypes.weaknesses}
                        parameters={parameters}
                        refreshDate={refreshDate} />
                </Col>
            </>
            }
            {hasAccess([UserClaimEnumerations.getApDfbTotalStatusByFilterOptions]) && <Col md={5} className="mb-3">
                <ApBasesDfbPercentageStatusChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbComments]) && <Col md={7} className="mb-3">
                <ApBasesDfbComments parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbByStateTypesAndDateChart]) && <Col xs={12} className="mb-3">
                <ApBasesDfbStatusBarChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbByTypeMetricAndRates]) && <Col md={4} className="mb-3">
                <ApDfbRatingParameterProgress parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbRatingParametersChart]) && <Col md={8} className="mb-3">
                <ApDfbRatingParameterChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbProvinces]) && <Col md={4} className="mb-3">
                <ApBasesDfbProvinceMap parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbCitiesGrid]) && <Col md={4} className="mb-3">
                <ApBasesDfbCitiesGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbCustomers]) && <Col md={4} className="mb-3">
                <ApBasesDfbCustomersGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApFeedbackAnnouncements]) && <Col xs={7} className="mb-3">
                <ApDfbLastFeedbacksGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApAnnouncements]) && <Col xs={5} className="mb-3">
                <ApDfbAnnouncementsGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
        </Row>
    );
}

export default React.memo(ApDfbAnnouncements);