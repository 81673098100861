import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsAvailableDimensionTable from '../../../../components/availableDimensions/NsAvailableDimensionTable';
import NsButton from '../../../../components/buttons/NsButton';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsProductGuidance from '../../../../components/guidance/NsProductGuidance';
import NsImage from '../../../../components/images/NsImage';
import NsImageOrderMasterFileView from '../../../../components/images/NsImageOrderMasterFileView';
import NsAlertModal from '../../../../components/modals/NsAlertModal';
import NsModal from '../../../../components/modals/NsModal';
import NsTypography from '../../../../components/text/NsTypography';
import NsTooltip from '../../../../components/tooltips/NsTooltip';
import NsTour from '../../../../components/tour/NsTour';
import Enumerations from '../../../../configs/Enumerations';
import SingletonCallService from '../../../../services/bases/SingletonCallService';
import { GetProductAdvancePriceService, GetProductBasicPriceService } from '../../../../services/order/OrderService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import ImageHelper from '../../../../utilities/ImageHelper';
import OrderHelper from '../../../../utilities/OrderHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import RegularOrderTours from '../../../bases/tours/RegularOrderTours';
import SelectAndUploadFileManagement from './bases/SelectAndUploadFileManagement';
import NsSwitch from '../../../../components/switch/NsSwitch';

class MasterFileModal extends React.Component {

    constructor(props) {
        super(props);
        this.tourSteps = RegularOrderTours.getMasterFileModalTour();
        this.seriesFromLocalStorage = OrderHelper.getSeries(this.props.routeId);
        this.turnaround = OrderHelper.getTurnaround(this.props.routeId);
        this.printedSide = OrderHelper.getPrintedSide(this.props.routeId);
        this.availableSizesFromLocalStorage = OrderHelper.getAvailableSizes(this.props.routeId);
        this.mainButtons = [
            CommonHelper.createStickyButtonItem("common.save", 'ns-icon-save', this.confirmFileClick, 'primary masterFileModal-tour-save'),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.handleAlertModal, 'primary-outline')
        ];
        this.guidancesUrls = OrderHelper.findOrderById(this.props.routeId).guidancesUrls;
        this.singletonAvailableSizeService = new SingletonCallService(GetProductBasicPriceService, this.getProductBasicPriceCallback);

        this.state = {
            headerLabel: props.isFront ? getTranslatedRawText('regularOrder.common.frontSide')
                : getTranslatedRawText('regularOrder.common.backSide'),
            messageClassModalItems: [{
                messageText: getTranslatedRawText('common.messages.discardNewFileMessage'),
                messageType: Enumerations.messageType.question
            }],
            thumbnails: [],
            previewFileClassName: 'd-none',
            guideColumnClassName: 'd-none',
            isDisabledConfirmation: true,
            imageSrc: '',
            selectedFileInfo: '',
            suggestedFilesInfo: [],
            suggestedFileIndex: -1,
            fileInfoFromFilePicker: '',
            isCloseWithAlert: false,
            isShowNsMessageClassModal: false,
            isOpenTooltip: false,
            isAlertModalOpen: false,
            availableSizes: [],
            trimZone: null,
            productDesignTemplateUrl: null,
            billBookCopies: null,
            isTooltipOpen: false,
            isAvailableSizeLoading: true,
            sharpness: false,
            resultMessageClass: (<></>)
        };
    }

    getInfoFromManagement = (state) => {
        const { imageSrc, previewFileClassName, guideColumnClassName, fileInfoFromFilePicker, selectedFileInfo, suggestedFilesInfo, availableSizes, trimZone, billBookCopies, productDesignTemplateUrl, isAvailableSizeLoading } = state;
        this.setState({ imageSrc, previewFileClassName, guideColumnClassName, fileInfoFromFilePicker, selectedFileInfo, suggestedFilesInfo, trimZone, billBookCopies, productDesignTemplateUrl, availableSizes: !isEmpty(availableSizes) ? [...availableSizes] : [], isAvailableSizeLoading });
    }

    confirmFileClick = () => {
        let localStorageFileInfoId = OrderHelper.getMasterFileByIsFront(this.props.routeId, this.props.isFront)?.id
        if (this.state.selectedFileInfo.id === localStorageFileInfoId) {
            this.closeClicked();
            return;
        }

        if (this.props.isFront) {
            this.setState({ isLoading: true });
            OrderHelper.setTrimZone(this.props.routeId, this.state.trimZone);
            OrderHelper.setBillBookCopies(this.props.routeId, this.state.billBookCopies);
            let data = OrderHelper.createAdvancePriceParameters(this.props.routeId, this.state.selectedFileInfo.id);
            GetProductAdvancePriceService(data, this.getProductAdvancePriceCallback);
        } else
            CommonHelper.safeFunctionCall(this.props.saveMasterFileModalClicked, { id: this.state.selectedFileInfo.id, name: this.state.selectedFileInfo.name }, this.state.suggestedFilesInfo);
    }

    getProductAdvancePriceCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            OrderHelper.setFileSizeAndOrderPrices(this.props.routeId, resultData);
            CommonHelper.safeFunctionCall(this.props.saveMasterFileModalClicked, { id: this.state.selectedFileInfo.id, name: this.state.selectedFileInfo.name }, this.state.suggestedFilesInfo);
        } else {
            this.setState({ resultMessageClass: messageClassModal });

        }
    }

    closeClicked = (confirm = false) => {
        if (this.state.isCloseWithAlert) {
            this.setState({ isShowNsMessageClassModal: !this.state.isShowNsMessageClassModal },
                () => {
                    if (!!confirm) {
                        CommonHelper.safeFunctionCall(this.props.closeMasterFileModalClicked);
                    }
                }
            );
        }
        else
            CommonHelper.safeFunctionCall(this.props.closeMasterFileModalClicked);
    }

    handleAlertModal = () => {
        if (!!this.state.fileInfoFromFilePicker)
            this.setState({ isAlertModalOpen: true });
        else
            this.closeClicked();
    };

    alertCancelClicked = () => this.setState({ isAlertModalOpen: false });

    alertConfirmClicked = () => {
        this.setState({ isAlertModalOpen: false });
        this.closeClicked();
    }

    sharpDieCutLineClicked = () => this.setState({ sharpness: !this.state.sharpness });

    showGuidanceTooltip = () => this.setState({ isOpenTooltip: !this.state.isOpenTooltip });

    closeGuidanceTooltip = () => this.setState({ isOpenTooltip: false });

    toggleTooltipClicked = () => this.setState({ isTooltipOpen: !this.state.isTooltipOpen });

    render() {
        const { guideColumnClassName, trimZone, productDesignTemplateUrl, isAlertModalOpen, availableSizes, isTooltipOpen, isAvailableSizeLoading, sharpness } = this.state;
        const showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.chooseFileModal);

        return (
            <NsModal showModal={this.props.showModal}
                closeModal={this.handleAlertModal}
                primaryActionButton={!!this.state.selectedFileInfo && !!this.state.imageSrc && this.confirmFileClick}
                isCloseWithAlert={this.state.isCloseWithAlert}
                isShowNsMessageClassModal={this.state.isShowNsMessageClassModal}
                messageClassModalItems={this.state.messageClassModalItems}
                dialogClassName="modal-choose-printing-files"
                dialogTitle={CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.fourColorPrintingFile'), this.state.headerLabel)}
                maxWidth="lg"
                actions={this.state.guideColumnClassName === 'd-block' &&
                    <NsStickyButton stickyButtons={this.mainButtons}
                        isLoading={this.state.isLoading}
                    />}>

                <Row className="justify-content-center" >
                    <Col lg={7} >
                        <SelectAndUploadFileManagement isFront={this.props.isFront}
                            routeId={this.props.routeId}
                            presentableFileType={Enumerations.presentableFileType.isPresentableInFilmFile}
                            getInfoFromManagement={this.getInfoFromManagement} />

                        {!!this.state.imageSrc && <div className='d-flex justify-content-end'>
                            <NsSwitch label='product.detail.sharpDieCutLine'
                                checked={sharpness}
                                disabled={!this.state.imageSrc}
                                onChange={this.sharpDieCutLineClicked} />
                        </div>
                        }
                        <div className={clsx("w-100 align-self-center border ", this.state.previewFileClassName)}>
                            <NsImageOrderMasterFileView className='border p-1'
                                imgSrc={this.state.imageSrc}
                                productDesignTemplateUrl={productDesignTemplateUrl}
                                isSharpProductDesignTemplate={sharpness}
                                trimZone={isEmpty(trimZone) ? null : trimZone} />
                        </div>
                        <div className='font-size-12 mt-1 text-justify mb-3 mb-md-0'>
                            {getTranslatedRawText('common.messages.orderMasterFileModalImageHelper')}
                        </div>

                    </Col>
                    <Col lg={5} className={this.state.guideColumnClassName}>
                        <div className='text-end'>
                            <NsProductGuidance guidancesUrls={this.guidancesUrls} />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mb-3'>
                            <span className='primary-paragraph fw-b'>
                                {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.fourColorPrintingFile'), this.state.headerLabel)}
                            </span>
                            <NsTooltip open={isTooltipOpen}
                                title={<Guidance />}
                                closeTooltip={() => this.setState({ isTooltipOpen: false })}>
                                <NsButton className='secondary-text masterFileModal-tour-preview p-0'
                                    startIcon='ns-icon-question'
                                    onClick={this.toggleTooltipClicked} />
                            </NsTooltip>
                        </div>
                        <NsAvailableDimensionTable routeId={this.props.routeId}
                            isLoading={isAvailableSizeLoading}
                            label={getTranslatedRawText('product.detail.availableDimension')}
                            series={this.seriesFromLocalStorage}
                            rows={availableSizes}
                            isAuthenticated={true}
                            usedInOrderProcess={true}
                            showGuidance={false} />
                    </Col>
                </Row>

                {isAlertModalOpen &&
                    <NsAlertModal isOpen={isAlertModalOpen}
                        cancelClicked={this.alertCancelClicked}
                        confirmClicked={this.alertConfirmClicked} >
                        {getTranslatedRawText('regularOrder.modal.closeModalAlert')}
                    </NsAlertModal>}

                {this.state.resultMessageClass}

                {(guideColumnClassName === 'd-block' && showTour) && <NsTour steps={this.tourSteps}
                    id={Enumerations.tourGuide.chooseFileModal} />}

            </NsModal >
        );
    }
}

function Guidance() {
    return (
        <div className="masterFileModal-tour-preview">
            <NsTypography className='font-size-12'>
                {getTranslatedRawText('regularOrder.modal.followingPointsTitle')}
            </NsTypography>
            <NsTypography className=''>
                {getTranslatedRawText('regularOrder.modal.zoneSafeDescription')}
            </NsTypography>
            <NsImage src={ImageHelper.blankSrc()}
                className='preview'
                imgAlt="components.imagesAlt.masterUploadGuidance" />
        </div>
    );
}

export default MasterFileModal
