import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';

import { GetBalanceService, GetCreditService } from '../../../services/ProfileSetting/UserBalanceService';
import { GetCashbackForecastService } from '../../../services/ProfileSetting/UserDashboardService';
import { getTranslatedRawText, getUrlWithCurrentCulture, isEnCulture } from '../../../utilities/CultureHelper';
import { currentUserActionCreators } from '../../../redux/reducers/CurrentUserReducer';
import { currencyActionCreators } from '../../../redux/reducers/CurrencyReducer';
import ConstantsWithTranslation from '../../../configs/ConstantsWithTranslation';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsCollapse from '../../../components/dropdowns/NsCollapse';
import NsSkeleton from '../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../components/text/NsTypography';
import ChangePasswordModal from '../modals/ChangePasswordModal';
import IncreaseCreditModal from '../modals/IncreaseCreditModal';
import NsTooltip from '../../../components/tooltips/NsTooltip';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import NsAvatar from '../../../components/avatar/NsAvatar';
import Enumerations from '../../../configs/Enumerations';
import NsCard from '../../../components/cards/NsCard';
import Links from '../../../configs/links/AllLinks';
import Config from '../../../configs/Config';
import { getConfigObjectByCulture } from '../../../utilities/ConfigHelper';

class UpSidebarUserInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showIncreaseCreditModal: false,
            showChangePasswordModal: false,
            mobile: '',
            credit: {},
            accountBalance: 0,
            balanceType: ConstantsWithTranslation.getBalanceType(Enumerations.balanceType.creditor),
            isLoadingRemainingLimit: true,
            isLoadingAccountBalance: true,
            isLoadingCashback: false,
            resultMessageClass: (<React.Fragment></React.Fragment>),
            showFinancialInformation: false,
            cashbackForecast: '',
            primaryActions: [
                {
                    id: 1,
                    icon: 'ns-icon-orders',
                    to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder),
                    onClick: () => this.props.closeMenuClicked(),
                    content: 'profile.actionButton.myOrder'
                },
                {
                    id: 2,
                    icon: 'ns-icon-profile-information',
                    to: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationSetting),
                    onClick: () => this.props.closeMenuClicked(),
                    content: 'profile.actionButton.profileInfo'
                },
                {
                    id: 3,
                    icon: 'ns-icon-sign-out',
                    to: '#',
                    onClick: this.signOutClicked,
                    content: 'profile.actionButton.signOut'
                },
            ],
            staticPages: [
                {
                    id: 1,
                    to: getUrlWithCurrentCulture(Links, Links.privacyPolicy),
                    content: 'footer.privacyPolicy'
                },
                {
                    id: 2,
                    to: getUrlWithCurrentCulture(Links, Links.frequentlyAskedQuestions),
                    content: 'common.breadcrumbs.FAQs'
                },
                {
                    id: 3,
                    to: getUrlWithCurrentCulture(Links, Links.termsOfUse),
                    content: 'footer.termOfUse'
                },
            ]
        };
    }

    componentDidMount() {
        this.setState({ mobile: CurrentUserHelper.getMobileForUI(), userAvatar: null, });
        this.props.showInHeader && this.getFinancialInformation();

        if (getConfigObjectByCulture(Config?.showCashback)) {
            GetCashbackForecastService(this.getCashbackForecastCallback);
            this.setState({ isLoadingCashback: true });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((this.state.showFinancialInformation !== prevState.showFinancialInformation && this.state.showFinancialInformation) ||
            (prevProps.refreshUserAccountDate !== this.props.refreshUserAccountDate)) {
            this.getFinancialInformation();
        }
    }

    getFinancialInformation = () => {
        this.getCredit();
        this.getBalance();
    }

    signOutClicked = () => {
        this.props.closeMenuClicked();
        this.props.removeCurrencies();
        this.props.removeCurrentUser();
    }

    showChangePasswordModalClicked = () => this.setState({ showChangePasswordModal: true });

    closeChangePasswordModal = () => this.setState({ showChangePasswordModal: false });

    showIncreaseCreditModalClicked = () => this.setState({ showIncreaseCreditModal: true });

    userCashbackClicked = () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main.userDashboard, Links.view.main.userDashboard.cashback));

    closeIncreaseCreditModal = () => {
        this.setState({ showIncreaseCreditModal: false });
        CommonHelper.safeFunctionCall(this.props.closeMenuClicked);
    }

    getCredit = () => {
        this.setState({ isLoadingRemainingLimit: true });
        GetCreditService(this.getCreditCallback);
    }

    getCashbackForecastCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoadingCashback: false })
        if (!result?.data?.hasError)
            this.setState({ cashbackForecast: resultData });
        else this.setState({ resultMessageClass: messageClassModal })
    }

    getCreditCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoadingRemainingLimit: false });
        if (!result?.data?.hasError && !isEmpty(resultData))
            this.setState({ credit: resultData });
    }

    getBalance = () => {
        this.setState({ isLoadingAccountBalance: true });
        GetBalanceService(this.getBalanceCallback);
    }

    getBalanceCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoadingAccountBalance: false });
        if (!result?.data?.hasError) {
            let balanceType = resultData > 0
                ? ConstantsWithTranslation.getBalanceType(Enumerations.balanceType.creditor)
                : resultData < 0
                    ? ConstantsWithTranslation.getBalanceType(Enumerations.balanceType.debtor)
                    : '';
            this.setState({ accountBalance: resultData, balanceType });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    handleMessage = (message) => {
        if (this.props.showInHeader)
            CommonHelper.safeFunctionCall(this.props.handleMessage, message)
        else
            this.setState({ resultMessageClass: message })
    }

    handelPrimaryActions = (data, textColor) => {
        return !isEmpty(data) && data.map(item => <Link to={item.to}
            key={item.id}
            className={`text-decoration-none ${textColor} d-flex align-items-center`}
            onClick={item?.onClick} >
            {!!item.icon && <i className={`${item.icon} mb-0 me-2 h3`} />}
            <NsTypography variant='inherit' className={textColor}>
                {getTranslatedRawText(item.content)}
            </NsTypography>
        </Link>)
    }

    render() {
        const { showInHeader } = this.props;
        const { showChangePasswordModal, showIncreaseCreditModal, credit } = this.state;
        const balance = CommonHelper.stringFormat(getTranslatedRawText('profile.userInfo.balance'), CommonHelper.currencyFormatByDefaultConfig(Math.abs(this.state.accountBalance))
            , this.state.balanceType);
        return (
            <NsCard className={`left-panel-user-info p-2 ${showInHeader && 'border-0'}`}>
                <div>
                    <div className='d-flex align-items-start'>
                        <div className="rounded-circle d-flex flex-column justify-content-center align-items-center">
                            <NsAvatar className='avatar-icon'
                                name={this.props.currentUser}
                                src={this.state.userAvatar} />

                            {(this.state.showFinancialInformation || showInHeader) && <div className="double-bullet-path" />}
                        </div>

                        <div className='pt-2 flex-grow-1'>
                            <div className=' ps-4 d-flex flex-column font-size-12'>
                                <p className="truncate text-capitalize section-title">
                                    {this.props.currentUser}
                                </p>
                                <span className="light-paragraph not-rtl-with-margin" >
                                    {this.state.mobile}
                                </span>
                            </div>
                        </div>
                    </div>
                    {(this.state.showFinancialInformation || showInHeader) && <>
                        <div className='d-flex align-items-baseline mt-n5 left-padding'>
                            <NsButton startIcon='ns-icon-refresh'
                                className='light-circle'
                                size='small'
                                onClick={this.getCredit} disabled={this.state.isLoadingRemainingLimit} />
                            {this.state.isLoadingRemainingLimit
                                ? <NsSkeleton isVisible={this.state.isLoadingRemainingLimit}
                                    className='ms-2 w-100'
                                    variant={[Enumerations.skeleton.text]} />
                                : <NsCollapse title={<NsTypography variant='inherit' className='ps-1 font-size-12'>
                                    {getTranslatedRawText('profile.userInfo.remainingLimit')}:&nbsp;
                                    <span className='fw-bold'>{CommonHelper.currencyFormatByDefaultConfig(credit?.remainingLimit)}</span>
                                </NsTypography>}>
                                    <div>
                                        <Link to={getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderFinancialReport)} className='text-decoration-none'>
                                            <NsTypography variant='inherit' className='ps-1 font-size-12 black-color d-flex justify-content-between'>
                                                <Row className='w-100'>
                                                    <Col><div>{getTranslatedRawText('profile.userInfo.creditor')}:&nbsp;</div></Col>
                                                    <Col><div>{CommonHelper.currencyFormatByDefaultConfig(credit?.creditor)}</div></Col>
                                                </Row>
                                            </NsTypography>
                                        </Link>
                                        <Link to={getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderFinancialReport)} className='text-decoration-none'>
                                            <NsTypography variant='inherit' className='ps-1 font-size-12 black-color d-flex justify-content-between'>
                                                <Row className='w-100'>
                                                    <Col><div>{getTranslatedRawText('profile.userInfo.debtor')}:&nbsp;</div></Col>
                                                    <Col><div>{CommonHelper.rtlFriendlyDefaultCurrencyFormat(credit?.debtor)}</div></Col>
                                                </Row>
                                            </NsTypography>
                                        </Link>
                                        <NsTypography variant='inherit' className='ps-1 font-size-12 d-flex justify-content-between'>
                                            <Row className='w-100'>
                                                <Col><div>{getTranslatedRawText('profile.userInfo.grantedCredit')}:&nbsp;</div></Col>
                                                <Col><div>{CommonHelper.currencyFormatByDefaultConfig(credit?.grantedCredit)}</div></Col>
                                            </Row>
                                        </NsTypography>

                                        <NsTypography variant='inherit' className='ps-1 font-size-12 d-flex justify-content-between'>
                                            <Row className='w-100'>
                                                <Col><div>{getTranslatedRawText('profile.userInfo.queue')}:&nbsp;</div></Col>
                                                <Col>
                                                    <div className='d-flex flex-nowrap'>
                                                        <span>{CommonHelper.rtlFriendlyDefaultCurrencyFormat(credit?.queue)}</span>
                                                        <NsTooltip title={getTranslatedRawText('profile.userInfo.creditQueueDescription')}>
                                                            <i className='ns-icon-question fs-6' />
                                                        </NsTooltip>
                                                    </div></Col>
                                            </Row>
                                        </NsTypography>
                                    </div>
                                </NsCollapse>
                            }
                        </div>
                        <div className='d-flex align-items-baseline left-padding mt-1'>
                            <NsButton startIcon='ns-icon-refresh'
                                wrapperClassName='circle'
                                className='light-circle'
                                size='small'
                                onClick={this.getBalance} disabled={this.state.isLoadingAccountBalance} />
                            {this.state.isLoadingAccountBalance
                                ? <NsSkeleton isVisible={this.state.isLoadingAccountBalance}
                                    className='ms-2 w-100'
                                    variant={[Enumerations.skeleton.text]} />
                                : <NsTypography variant='inherit' className='ps-1 font-size-12'>
                                    {getTranslatedRawText('profile.userInfo.accountBalance')}:&nbsp;
                                    <span className='fw-bold'>{balance}</span>
                                </NsTypography>}
                        </div>
                        {getConfigObjectByCulture(Config?.showCashback) &&
                            <div className='d-flex align-items-baseline left-padding mt-2 mx-3 cursor-pointer'>
                                {this.state.isLoadingCashback
                                    ? <NsSkeleton isVisible={this.state.isLoadingCashback}
                                        className='ms-2 w-100'
                                        variant={[Enumerations.skeleton.text]} />
                                    : <div className='d-flex'>
                                        <NsTypography variant='inherit' className='ps-1 font-size-12' onClick={this.userCashbackClicked}>
                                            {getTranslatedRawText('profile.cashback.cashback')}:&nbsp;
                                            <span className='fw-bold text-decoration-underline'>{CommonHelper.rtlFriendlyDefaultCurrencyFormat(this.state.cashbackForecast?.currentDiscountAmount)}</span>
                                        </NsTypography>
                                        <NsTooltip
                                            title={getTranslatedRawText('profile.userInfo.cashBackDescription')}>
                                            <i className='ns-icon-question fs-6 ms-2' />
                                        </NsTooltip>
                                    </div>
                                }
                            </div>
                        }
                    </>}
                    {
                        !showInHeader && <div className='d-flex align-items-baseline left-padding mt-1'>
                            <NsButton startIcon={this.state.showFinancialInformation
                                ? 'ns-icon-expand-up'
                                : 'ns-icon-expand-down'}
                                wrapperClassName='circle'
                                className='light-circle red-color'
                                size='small'
                                onClick={() => this.setState({ showFinancialInformation: !this.state.showFinancialInformation })} />

                            <NsTypography onClick={() => this.setState({ showFinancialInformation: !this.state.showFinancialInformation })}
                                variant='inherit' className='ps-1 cursor-pointer red-color font-size-12'>
                                {getTranslatedRawText(this.state.showFinancialInformation
                                    ? 'profile.userInfo.hideFinancialInformation'
                                    : 'profile.userInfo.showFinancialInformation')}
                            </NsTypography>
                        </div>
                    }

                </div >

                <div className={`w-100 py-3 justify-content-center d-flex flex-column ${showInHeader && 'border-bottom'}`}>
                    <div className="px-lg-5">
                        <NsButton label='profile.userInfo.increaseCredit'
                            size='small'
                            startIcon='ns-icon-increase-credit'
                            onClick={this.showIncreaseCreditModalClicked}
                            className='secondary-outline' />
                    </div>

                    {!showInHeader && <div className="px-lg-5 mt-1 ">
                        <NsButton label='profile.userInfo.changePassword'
                            size='small'
                            startIcon='ns-icon-change-password'
                            onClick={this.showChangePasswordModalClicked}
                            className='secondary-outline' />
                    </div>
                    }
                </div>
                {
                    showInHeader &&
                    <Col xs={12} className="font-size-14 pt-2">
                        {this.handelPrimaryActions(this.state.primaryActions, 'text-black')}
                        <div className='d-flex justify-content-around border-top font-size-12 pt-2'>
                            {this.handelPrimaryActions(this.state.staticPages, 'text-color')}
                        </div>
                    </Col>
                }
                {
                    showChangePasswordModal &&
                    <ChangePasswordModal showModal={showChangePasswordModal}
                        handleMessage={this.handleMessage}
                        closeChangePasswordModal={() => this.closeChangePasswordModal()} />
                }
                {
                    showIncreaseCreditModal &&
                    <IncreaseCreditModal showModal={showIncreaseCreditModal}
                        closeIncreaseCreditModal={() => this.closeIncreaseCreditModal()}
                        handleMessage={this.handleMessage} />
                }
                {this.state.resultMessageClass}
            </NsCard >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.userFullName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...currentUserActionCreators, ...currencyActionCreators }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpSidebarUserInfo));