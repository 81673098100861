import EXIF from 'exif-js'
import CommonHelper from './CommonHelper';
import Enumerations from '../configs/Enumerations';
import Constants from '../configs/Constants';
import Config from '../configs/Config';

class ImageHelper {

    static blankSrc = () => "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAmAAAAJgAQMAAAAXpU2TAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAERJREFUeNrtwTEBAAAAwqD1T20MH6AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4GLbgAAG6FTkhAAAAAElFTkSuQmCC";

    static rotateCwAngle90 = (content, callback) => {
        var img = new Image();
        img.src = content;
        img.onload = () => {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.width = img.height;
            canvas.height = img.width;
            ctx.setTransform(1, 0, 0, 1, canvas.width / 2, canvas.height / 2);
            ctx.rotate(90 * (Math.PI / 180));
            ctx.drawImage(img, -canvas.height / 2, -canvas.width / 2);
            CommonHelper.safeFunctionCall(callback, canvas.toDataURL("image/jpeg"));
        }
    }

    static rotateCcwAngle90 = (content, callback) => {
        var img = new Image();
        img.src = content;
        img.onload = () => {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.width = img.height;
            canvas.height = img.width;
            ctx.setTransform(1, 0, 0, 1, canvas.width / 2, canvas.height / 2);
            ctx.rotate(270 * (Math.PI / 180));
            ctx.drawImage(img, -canvas.height / 2, -canvas.width / 2);
            CommonHelper.safeFunctionCall(callback, canvas.toDataURL("image/jpeg"));
        }
    }

    static rotateAngle180 = (content, callback) => {
        var img = new Image();
        img.src = content;
        img.onload = () => {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.setTransform(1, 0, 0, 1, canvas.width / 2, canvas.height / 2);
            ctx.rotate(180 * (Math.PI / 180));
            ctx.drawImage(img, -canvas.width / 2, -canvas.height / 2);
            CommonHelper.safeFunctionCall(callback, canvas.toDataURL("image/jpeg"));
        }
    }

    static flipHorizontally = (content, callback) => {
        var img = new Image();
        img.src = content;
        img.onload = () => {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.scale(-1, 1);
            ctx.drawImage(img, -canvas.width, 0);
            CommonHelper.safeFunctionCall(callback, canvas.toDataURL("image/jpeg"));
        }
    }

    static flipVertically = (content, callback) => {
        var img = new Image();
        img.src = content;
        img.onload = () => {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.scale(1, -1);
            ctx.drawImage(img, 0, -canvas.height);
            CommonHelper.safeFunctionCall(callback, canvas.toDataURL("image/jpeg"));
        }
    }

    static getBase64Image = (content, callback) => {
        let img = new Image();
        img.src = content
        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/jpeg");
            CommonHelper.safeFunctionCall(callback, dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
        }
    }

    static contentToBase64 = (fileContents, contentType, fileExtension) => {
        if (new RegExp(Constants.regex.image).test(fileExtension)) {
            return `data:${contentType};base64,${fileContents}`
        } else return ''
    }

    static convertPixelToMillimeter = (value, resolution) => {
        if (!!value && !!resolution)
            return (value / resolution) * 25.4;
        return 0;
    }

    static convertMillimeterToPixel = (value, resolution) => {
        if (!!value && !!resolution)
            return (value / 25.4) * resolution;
        return 0;
    }

    static getImageAppliedWidth = (w, h) => {
        const width = ImageHelper.getNumber(w);
        const height = ImageHelper.getNumber(h);
        return `${(width * 100 / Math.max(width, height))}%`;
    }

    static getImageAppliedHeight = (w, h) => {
        const width = ImageHelper.getNumber(w);
        const height = ImageHelper.getNumber(h);
        return `${(height * 100 / Math.max(width, height))}%`;
    }

    static getNumber = (str, suffix = "") => {
        return str.replace(/[^0-9\\.]+/g, '') + suffix;
    }

    static imageURl = (file) => {
        return URL.createObjectURL(file);
    }

    static getResolutionAndDimension = (content, callback) => {
        let img = new Image();
        img.src = content;

        img.onload = () => {

            EXIF.getData(img, function () {

                let xResolution = EXIF.getTag(this, "XResolution");
                let yResolution = EXIF.getTag(this, "YResolution");
                let resolutionUnit = EXIF.getTag(this, "ResolutionUnit");

                const resolution = ImageHelper.getResolutionBaseOnInch(!!xResolution ? Number(xResolution) : null,
                    !!yResolution ? Number(yResolution) : null,
                    resolutionUnit);

                let resolutionAndDimension = {
                    width: img.width,
                    height: img.height,
                    xResolution: resolution.xResolution,
                    yResolution: resolution.yResolution
                };

                CommonHelper.safeFunctionCall(callback, resolutionAndDimension);
            });
        }
    }

    static getResolutionBaseOnInch = (xResolution, yResolution, resolutionUnit) => {

        let result = {};
        if (!xResolution || !yResolution)
            return result;

        switch (resolutionUnit) {
            case Enumerations.resolutionUnitType.inch:
                result.xResolution = xResolution;
                result.yResolution = yResolution;
                break;

            case Enumerations.resolutionUnitType.centimeter:
                result.xResolution = Math.round(xResolution / Constants.coefficientCentimeterToInch);
                result.yResolution = Math.round(yResolution / Constants.coefficientCentimeterToInch);
                break;
        }
        return result;
    }

    static calculateRatio = (contentWidth, contentHeight, imageWidth, imageHeight) => {
        // if (contentWidth > imageWidth && contentHeight > imageHeight)
        //     return 1
        // ratio = Math.floor(Math.min(ratioHeight, ratioWidth, 1) * 90) / 100;
        let ratioWidth = contentWidth / imageWidth;
        let ratioHeight = contentHeight / imageHeight;
        // ratioWidth = ratioWidth < 1 ? ratioWidth : 1;
        // ratioHeight = ratioHeight < 1 ? ratioHeight : 1;
        return Math.min(ratioWidth, ratioHeight);
    }

    static calculateMaxRatio = (contentWidth, contentHeight, imageWidth, imageHeight) => {
        // if (contentWidth > imageWidth && contentHeight > imageHeight)
        //     return 1
        // ratio = Math.floor(Math.min(ratioHeight, ratioWidth, 1) * 90) / 100;
        let ratioWidth = contentWidth / imageWidth;
        let ratioHeight = contentHeight / imageHeight;
        // ratioWidth = ratioWidth < 1 ? ratioWidth : 1;
        // ratioHeight = ratioHeight < 1 ? ratioHeight : 1;
        return Math.max(ratioWidth, ratioHeight);
    }

    static convertFiltersFromPercentageToMillimeter = (imageWidth, imageHeight, regions, isFront) => {
        regions.map(region => {
            if (!regions.isChanging && !region.new) {

                region.topLeftCornerX = region.x * imageWidth / 100;
                region.topLeftCornerY = region.y * imageHeight / 100;
                region.bottomRightCornerX = region.width * imageWidth / 100 + region.topLeftCornerX;
                region.bottomRightCornerY = region.height * imageHeight / 100 + region.topLeftCornerY;
                region.isFront = isFront;
            }
        });
        return (regions);
    }

    static convertFiltersFromMillimeterToPercentage = (imgWidth, imgHeight, resolution, filters) => {
        let imgSrcWidth = this.convertMillimeterToPixel(imgWidth, resolution);
        let imgSrcHeight = this.convertMillimeterToPixel(imgHeight, resolution);
        filters.map((filter, index) => {
            let topLeftCornerX = ImageHelper.convertMillimeterToPixel(filter.topLeftCornerX, 72);
            let topLeftCornerY = ImageHelper.convertMillimeterToPixel(filter.topLeftCornerY, 72);
            let bottomRightCornerX = ImageHelper.convertMillimeterToPixel(filter.bottomRightCornerX, 72);
            let bottomRightCornerY = ImageHelper.convertMillimeterToPixel(filter.bottomRightCornerY, 72);
            filter.x = topLeftCornerX * 100 / imgSrcWidth;
            filter.y = topLeftCornerY * 100 / imgSrcHeight;
            filter.width = (bottomRightCornerX - topLeftCornerX) * 100 / imgSrcWidth;
            filter.height = (bottomRightCornerY - topLeftCornerY) * 100 / imgSrcHeight;
            filter.data = { index };
            filter.isChanging = true;
            filter.new = false
        });
        return (filters);
    }

    static getCdnImageSrcset = (imageSitePosition, imgUrl) => {
        switch (imageSitePosition) {
            case Enumerations.imageSitePosition.homeSlider:
                return this.createSrcset(514, 360, 330, 227, 450, 310, 540, 371.4, 630, 433, imgUrl, '');
            case Enumerations.imageSitePosition.homeTopPromotion:
                return this.createSrcset(516, 370, 174, 113.3, 234, 152.133, 279, 181.25, 324, 210.3, imgUrl, '');
            case Enumerations.imageSitePosition.homeMiddlePromotion:
                return this.createSrcset(500, 192.7, 216, 83.9333, 296, 114.583, 356, 137.55, 416, 160.5, imgUrl, '');
            case Enumerations.imageSitePosition.homeProductCard:
                return this.createSrcset(514, 385.5, 330, 250, 225, 170, 261, 195.75, 306, 230, imgUrl, Config.apiServer.baseUrl);
            case Enumerations.imageSitePosition.shopProductCard:
                return this.createSrcset(514, 385.5, 338, 253.5, 225, 170, 261, 195.75, 306, 230, imgUrl, Config.apiServer.baseUrl);
            case Enumerations.imageSitePosition.productDetailsImage:
                return this.createSrcset(310.667, 232.867, 430.667, 322.817, 280, 210, 340, 254.85, 400, 300, imgUrl, Config.apiServer.baseUrl);
            case Enumerations.imageSitePosition.sidebarProductCard:
                return this.createSrcset(514, 385.5, 215, 250, 225, 170, 261, 195.75, 306, 230, imgUrl, Config.apiServer.baseUrl);
            case Enumerations.imageSitePosition.sidebarPromotion:
                return this.createSrcset(514, 514, 214, 285.33, 214, 285.33, 259, 345.33, 304, 405.33, imgUrl, '');
            case Enumerations.imageSitePosition.aboutUsOurEquipment:
                return this.createSrcset(482, 274.533, 161.833, 92.1667, 261.833, 149.117, 318.083, 181.167, 374.3, 213.2, imgUrl, '');
        }
    }

    static createSrcset = (width1, height1, width2, height2, width3, height3, width4, height4, width5, height5, imgUrl, baseUrl) => {
        return Config.loadImagesByCdn
            ? `${baseUrl}/cdn-cgi/image/fit=contain,width=${width1},height=${height1}${imgUrl} 576w, 
            ${baseUrl}/cdn-cgi/image/fit=contain,width=${width2},height=${height2}${imgUrl} 768w,
            ${baseUrl}/cdn-cgi/image/fit=contain,width=${width3},height=${height3}${imgUrl} 992w,
            ${baseUrl}/cdn-cgi/image/fit=contain,width=${width4},height=${height4}${imgUrl} 1200w,
            ${baseUrl}/cdn-cgi/image/fit=contain,width=${width5},height=${height5}${imgUrl} 1400w`
            : '';
    }

}

export default ImageHelper;