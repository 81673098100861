import clsx from 'clsx';
import React, { Component, memo } from 'react';
import { withRouter } from 'react-router-dom';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { isBusinessPartnerRoute } from '../../utilities/BusinessPartnerHelper';
import { selectItemEvent } from '../../utilities/GoogleTagManagerHelper';
import BaseAddToCart from '../../views/products/bases/BaseAddToCart';
import CommonHelper from '../../utilities/CommonHelper';
import NsButtonOrLink from '../buttons/NsButtonOrLink';
import Enumerations from '../../configs/Enumerations';
import NsProductImage from '../images/NsProductImage';
import UrlHelper from "../../utilities/UrlHelper";
import Links from '../../configs/links/AllLinks';
import NsCarousel from '../carousel/NsCarousel';
import NsCard from './NsCard';

class NsProductCard extends Component {

	getProductUrlById = (id) => {
		const { product, isStoreProducts } = this.props;
		let productName = CommonHelper.makeCorrectLink(product.name);
		return !isStoreProducts ?
			UrlHelper.createPdRegularDetailUrl(null, id || product?.printCirculations[0].key, productName)
			:
			CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main, Links.view.main.storeDetails),
				product?.selectedId, productName)
	}

	productClicked = () => {
		isBusinessPartnerRoute()
			? this.props.history.push(this.getProductUrlById())
			: selectItemEvent(this.props.product, this.props.googleTagManagerListId, this.props.googleTagManagerIndex, this.props.isStoreProducts);
		if (!!this.props.reloadPageAfterClickingProduct) {
			this.props.history.push(this.getProductUrlById());
			window.location.reload();
		}
	}

	render() {
		const { product, className, nsCardClassName, usedIn, supplierOrderCart, isAuthenticated, isLoadingStoreCartItems, refreshProduct, isStoreProducts, isNotShowAddToCardSection } = this.props;
		return (
			<div className={clsx(usedIn !== Enumerations.imageSitePosition.sidebarProductCard && "d-inline d-md-inline-block p-1", className)}>
				<NsCard className={clsx('custom-card product-tour-card h-100', nsCardClassName)}>
					<NsButtonOrLink onClick={this.productClicked} to={this.getProductUrlById()}>
						<div className='position-relative'>
							<figure className='m-0 overflow-hidden d-flex justify-content-center'>
								{isStoreProducts && <figcaption className='custom-card__fig--title on-image-title-light m-1 p-1 rounded'>
									{getTranslatedRawText('product.customCard.availableInStore')}
								</figcaption>
								}
								<NsProductImage product={product} usedIn={usedIn} className="w-100 my-n4" />
								<figcaption className='custom-card__fig--caption on-image-title w-100 p-1'>
									<div className='d-flex'>
										{product.name}
										{isStoreProducts && <div className='ms-auto'>
											{product.code}
										</div>
										}
									</div>
								</figcaption>
							</figure>
						</div>
					</NsButtonOrLink>
					<div className=' p-2'>
						{isStoreProducts
							? <>
								{isAuthenticated &&
									<span>
										<b className='red-color'>{CommonHelper.currencyFormat(product.storeProductUnitPrices[0].price, true)}</b>
										&nbsp;{product.currencyTypeName}
									</span>
								}
								{!isNotShowAddToCardSection && <BaseAddToCart className='mt-3'
									showIconButton={true}
									isBasket={true}
									isSmallSize={true}
									hasDeleteIcon={true}
									isLoadingStoreCartItems={isLoadingStoreCartItems}
									storeProductSupplierPriceId={product?.storeProductSupplierPriceId}
									supplierOrderCart={supplierOrderCart}
									refreshProduct={refreshProduct}
									isAuthenticated={isAuthenticated} />
								}
							</>
							: <>
								<p className='font-size-12'>
									{getTranslatedRawText('product.customCard.availableCirculations')}
								</p>
								<NsCarousel circulations={product.printCirculations}
									currencyTypeName={product.currencyTypeName}
									productItemClicked={this.getProductUrlById} />
							</>
						}
					</div>
				</NsCard >
			</div >
		);
	}
}

export default memo(withRouter(NsProductCard));