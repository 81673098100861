import * as CurrentUser from '../reducers/CurrentUserReducer';
import BreadcrumbsReducer from '../reducers/BreadcrumbsReducer';
import * as CategoryReducer from '../reducers/CategoryReducer';
import * as CountryReducer from '../reducers/CountryReducer';
import * as MiniCartReducer from '../reducers/MiniCartReducer';
import * as MetaTagReducer from '../reducers/MetaTagReducer';
import * as FileManagerReducer from '../reducers/FileManagerReducer';
import AdminSidebarReducer from '../reducers/AdminSidebarReducer';
import * as ResetTourReducer from '../reducers/ResetTourReducer';
import * as SidebarCategoriesReducer from '../reducers/SidebarCategoriesReducer';
import * as CurrencyReducer from '../reducers/CurrencyReducer';

const Stores = {
    currencies: CurrencyReducer.reducer,
    currentUser: CurrentUser.reducer,
    breadcrumbs: BreadcrumbsReducer,
    categories: CategoryReducer.reducer,
    sidebarCategories: SidebarCategoriesReducer.reducer,
    countries: CountryReducer.reducer,
    miniCartInfo: MiniCartReducer.reducer,
    metaTag: MetaTagReducer.reducer,
    fileManager: FileManagerReducer.reducer,
    AdminSidebarReducer: AdminSidebarReducer,
    resetTour: ResetTourReducer.reducer
};

export default Stores;