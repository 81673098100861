import React from 'react';
import clsx from 'clsx';

import CommonHelper from '../../../../utilities/CommonHelper';
import PdrRegularDetail from '../../regularDetails/PdrRegularDetail';
import NsCollapse from '../../../../components/dropdowns/NsCollapse'
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

class PdpMainSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultIsOpen: false,
        }
    }

    isOpenChanged = (status) => CommonHelper.safeFunctionCall(this.props.isOpenStatus, status, true);

    render() {
        const { selectedId, startOrdering, startOrderingClicked } = this.props;
        const isMobileDevice = CommonHelper.isMobileDevice();
        return (
            <div className={clsx(isMobileDevice && 'd-flex flex-column wrapper-white p-3 mb-md-0')}>
                {
                    isMobileDevice
                        ? <NsCollapse title={getTranslatedRawText('professionalOrder.collapse.productDetail')}
                            defaultIsOpen={this.props.showMainSectionCollapse}
                            isOpenStatus={this.isOpenChanged}
                            className="fw-bold d-md-none font-size-14"
                            collapseClassName="mb-2"
                            titleClassName='black-color '>
                            <div className='product-tour-select'>
                                <PdrRegularDetail isUsedInPo={true}
                                    selectedId={selectedId}
                                    startOrdering={startOrdering}
                                    startOrderingClicked={startOrderingClicked}
                                    printCirculations={this.props.printCirculations} />
                            </div>
                        </NsCollapse>
                        : <PdrRegularDetail isUsedInPo={true}
                            selectedId={selectedId}
                            startOrdering={startOrdering}
                            startOrderingClicked={startOrderingClicked}
                            printCirculations={this.props.printCirculations} />
                }
            </div>
        )
    }
}

export default PdpMainSection;