import React from 'react';

import submittedOrderImage from '../../../assets/images/regularOrder/submitted-order.png';
import NsButton from '../../../components/buttons/NsButton';
import NsTypography from '../../../components/text/NsTypography';
import Links from '../../../configs/links/AllLinks';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { isBusinessPartnerRoute } from '../../../utilities/BusinessPartnerHelper';


class RegularOrderSaveResult extends React.Component {

    confirmOrderClicked = () => {
        !!this.props.isProfessional
            ? CommonHelper.safeFunctionCall(this.props.closeSuccessfulModal, true)
            : this.props.history.push(
                getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder),
                { isExpressMode: true }
            )
    }

    orderAnotherProductClicked = () => {
        !!this.props.isProfessional
            ? CommonHelper.safeFunctionCall(this.props.closeSuccessfulModal, false)
            : this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts));
    }

    componentDidMount = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    render() {
        return (
            <div className='d-flex flex-column p-3 border border-light-text-color regular-order-detail__save-result justify-content-center text-center'>
                <div>
                    <img src={submittedOrderImage}
                        width='112px'
                        className='pt-4 pb-5'
                        alt={getTranslatedRawText("components.imagesAlt.submittedOrderImage")} />
                </div>

                <NsTypography className='section-title text-center'>
                    {getTranslatedRawText('regularOrder.saveResult.successMessage')}
                </NsTypography>

                <NsTypography className='light-paragraph py-1'>
                    {getTranslatedRawText('regularOrder.saveResult.successDescription')}
                </NsTypography>
                <div className='d-flex justify-content-center py-3'>
                    {!isBusinessPartnerRoute() && <NsButton className='primary text-nowrap'
                        startIcon='ns-icon-confirm'
                        label='regularOrder.saveResult.confirmOrder'
                        onClick={this.confirmOrderClicked} />
                    }
                    <NsButton className='primary-outline text-nowrap'
                        wrapperClassName='ps-2'
                        label={this.props.isProfessional ? 'regularOrder.saveResult.orderAnotherProduct' : 'regularOrder.saveResult.returnToShop'}
                        onClick={this.orderAnotherProductClicked} />
                </div>
            </div>
        );
    }
}

export default RegularOrderSaveResult;