import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";

import ApDfbAnnouncements from "./feedbaclDashboardAnnouncements/ApDfbAnnouncements";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import ApDfbEmployees from "./feedbaclDashboardEmployees/ApDfbEmployees";
import ApBasesDashboardHeader from "../bases/ApBasesDashboardHeader";
import ApDfbOverall from "./feedbaclDashboardOverall/ApDfbOverall";
import ApDfbOrders from "./feedbaclDashboardOrders/ApDfbOrders";
import Enumerations from "../../../../configs/Enumerations";
import DateHelper from "../../../../utilities/DateHelper";
import NsTab from "../../../../components/tabs/NsTab";

const ApDashboardFeedback = (props) => {

    const tabs = ConstantsWithTranslation.getFeedbackDashboardTabs();
    const [dates, setDates] = useState([new Date(DateHelper.getCustomPreviousMonth(2)), new Date()]);
    const [dateRangeType, setDateRangeType] = useState(ConstantsWithTranslation.getDateRanges()[0].key);
    const [userId, setUserId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [refreshDate, setRefreshDate] = useState();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [refreshMeDate, setRefreshMeDate] = useState();

    const refreshClicked = useCallback((dates, isRefreshDate, dateRange, userId, stateId, cityId) => {
        setDateRangeType(dateRange);
        setDates(dates);
        setUserId(userId);
        setStateId(stateId);
        setCityId(cityId);
        if (isRefreshDate) {
            setRefreshDate(new Date());
        }
    })

    const tabChanged = (newValue, selectedTab) => {
        if (newValue !== selectedTabIndex) {
            setSelectedTab(selectedTab);
            setSelectedTabIndex(newValue);
        }
    }

    const parameters = {
        submitTimeFrom: DateHelper.convertDateTimeToDate(new Date(dates[0])),
        submitTimeTo: dates[1],
        timeIntervalType: dateRangeType,
        userId,
        stateId,
        cityId
    }

    const switchComponent = () => {
        switch (selectedTab.id) {
            case Enumerations.feedbackTypes.sharedSheetOrder:
                return <ApDfbOrders parameters={parameters} refreshDate={refreshDate} />;
            case Enumerations.feedbackTypes.announcementContent:
                return <ApDfbAnnouncements parameters={parameters} refreshDate={refreshDate} />;
            case Enumerations.feedbackTypes.user:
                return <ApDfbEmployees parameters={parameters} refreshDate={refreshDate} />;
            default: return <ApDfbOverall parameters={parameters} refreshDate={refreshDate} />;
        }
    }

    return (
        <Row className="g-2">
            <Col xs={12} className="mb-3">
                <ApBasesDashboardHeader title='adminPanel.dashboard.feedbackDashboard'
                    // hasStateFilter={true}
                    refreshClicked={refreshClicked} />
            </Col>
            <Col xs={12} className="mb-3 border-bottom">
                <NsTab tabs={tabs}
                    value={selectedTabIndex}
                    onChange={tabChanged} />
            </Col>
            {switchComponent()}
        </Row>
    );
}

export default React.memo(ApDashboardFeedback);