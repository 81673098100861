import Links from "../../configs/links/AllLinks"
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest } from "../bases/BaseService"

const GetSaleGadgetsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfSaleGadgets), parameter, callback);
};

const GetTotalSalesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfTotalSales), parameter, callback);
};

const GetTotalOnlinePaymentsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfTotalOnlinePayments), parameter, callback);
};

const GetOnlinePaymentsGatewayService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfOnlinePaymentsGateway), parameter, callback);
};

const GetBusinessTotalSalesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDbTotalSales), parameter, callback);
};

const GetBusinessTotalOrdersService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDbTotalOrders), parameter, callback);
};

const GetBusinessTotalOnlinePaymentsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDbTotalOnlinePayments), parameter, callback);
};

const GetOrderByApplicationTypeService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfOrderByApplicationType), parameter, callback);
};

const GetOrderByTurnaroundService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfOrderByTurnaround), parameter, callback);
};

const GetBusinessOnlinePaymentStatusService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDbOnlinePaymentStatus), parameter, callback);
};

const GetTotalUsersService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDuTotalUsers), parameter, callback);
};

const GetSmsPanelCreditService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDSmsPanelCredit), {}, callback);
};

const GetLastJoinedUsersService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDuLastJoinedUsers), parameter, callback);
};

const GetInactiveUsersService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDuInactiveUsers), parameter, callback);
};

const GetApDuRemainingLimitWithCreditService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDuRemainingLimitWithCredit), parameter, callback);
};

const GetApDuRemainingLimitWithoutCreditService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDuRemainingLimitWithoutCredit), parameter, callback);
};

const GetApDuGrantedCreditsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDuGrantedCredits), parameter, callback);
};
const GetApDuRemainingLimitWithCreditExcelReportService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDuRemainingLimitWithCreditExcelReport), parameter, callback);
};

const GetApDuRemainingLimitWithoutCreditExcelReportService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDuRemainingLimitWithoutCreditExcelReport), parameter, callback);
};

const GetApDuGrantedCreditsExcelReportService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDuGrantedCreditsExcelReport), parameter, callback);
};

//Agenceis
const GetLatestSubmittedAgenciesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaLatestSubmittedAgencies), parameter, callback);
};
const GetTotalSalesAmountAgenciesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaTotalSalesAmountAgencies), parameter, callback);
};
const GetPopularAgenciesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaPopularAgencies), parameter, callback);
}
const GetAgencyActiveStatesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaActiveStates), parameter, callback);
}
//audits
const GetAuditStatisticPaymentsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDauStatisticPayments), parameter, callback);
}
const GetApDauStatisticsMismatchProgressService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDauStatisticsMismatchProgress), parameter, callback);
}
const GetApDauNotProcessedAuditCountService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDauNotProcessedAuditCount), parameter, callback);
}
const GetApDauAggregateStatisticsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDauAggregateStatistics), parameter, callback);
}
const GetApDauPaymentGatewaysMessagesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDauPaymentGatewaysMessages), parameter, callback);
}
//feedbacks
const GetApDfbTotalByStatusGedgetsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbTotalByStatusGedgets), parameter, callback);
}
const GetApDfbTotalTotalLineChartService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbTotalTotalLineChart), parameter, callback);
}
const GetApDfbStrengthWeeknessChartService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbStrengthWeeknessChart), parameter, callback);
}
const GetApDfbRatingParameterChartService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbRatingParameterChart), parameter, callback);
}
const GetApDfbByStatusAndFilterChartService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbByStatusAndFilterChart), parameter, callback);
}
const GetApDfbCommentsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbComments), parameter, callback);
}
const GetApDfbProgressMetricsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbProgressMetrics), parameter, callback);
}
const GetApDfbByStateAndDateChartService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbByStateAndDateChart), parameter, callback);
}
const GetApDfbByCustomersGridService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbByCustomersGrid), parameter, callback);
}
const GetApDfbByProductsGridService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbByProductsGrid), parameter, callback);
}
const GetApDfbByEmployeesGridService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbByEmployeesGrid), parameter, callback);
}
const GetApDfbByCitiesGridService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbByCitiesGrid), parameter, callback);
}
const GetApDfbByProvincesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbByProvinces), parameter, callback);
}
const GetApDfbByProductService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbByProduct), parameter, callback);
}
const GetApDfbAnnouncementsGridService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDfbAnnouncementsGrid), parameter, callback);
}
//cashback
const GetApDaUCbForecastsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaUCbForecasts), parameter, callback);
}
const GetApDaUCbForecastsExcelReportService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaUCbForecastsExcelReport), parameter, callback);
}
const GetApDaUCbForecastWithGrowthPercentService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaUCbForecastWithGrowthPercent), parameter, callback);
}
const GetApDaUCbForeCastService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaUCbForeCast), id), {}, callback);
}
const GetApDaUCCInfoService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaUCCInfo), {}, callback);
}
const GetApDaCbPlansService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaCbPlans), id), {}, callback);
}
const GetApDaCbHistoriesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaCbHistories), parameter, callback);
}
const GetApDaCbFromInqueryTimesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaCbFromInqueryTimes), {}, callback);
}
const GetApDaCbToInqueryTimesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDaCbToInqueryTimes), parameters, callback);
}

export {
    GetSaleGadgetsService,
    GetTotalSalesService,
    GetTotalOnlinePaymentsService,
    GetOnlinePaymentsGatewayService,
    GetBusinessTotalSalesService,
    GetBusinessTotalOrdersService,
    GetTotalUsersService,
    GetSmsPanelCreditService,
    GetLastJoinedUsersService,
    GetInactiveUsersService,
    GetBusinessTotalOnlinePaymentsService,
    GetBusinessOnlinePaymentStatusService,
    GetOrderByApplicationTypeService,
    GetOrderByTurnaroundService,
    GetApDuRemainingLimitWithCreditService,
    GetApDuRemainingLimitWithoutCreditService,
    GetApDuGrantedCreditsService,
    GetApDuRemainingLimitWithCreditExcelReportService,
    GetApDuRemainingLimitWithoutCreditExcelReportService,
    GetApDuGrantedCreditsExcelReportService,
    //agencies
    GetLatestSubmittedAgenciesService,
    GetTotalSalesAmountAgenciesService,
    GetPopularAgenciesService,
    GetAgencyActiveStatesService,
    //audits
    GetAuditStatisticPaymentsService,
    GetApDauStatisticsMismatchProgressService,
    GetApDauNotProcessedAuditCountService,
    GetApDauAggregateStatisticsService,
    GetApDauPaymentGatewaysMessagesService,
    //feedback
    GetApDfbTotalByStatusGedgetsService,
    GetApDfbTotalTotalLineChartService,
    GetApDfbStrengthWeeknessChartService,
    GetApDfbRatingParameterChartService,
    GetApDfbByStatusAndFilterChartService,
    GetApDfbCommentsService,
    GetApDfbProgressMetricsService,
    GetApDfbByStateAndDateChartService,
    GetApDfbByCustomersGridService,
    GetApDfbByCitiesGridService,
    GetApDfbByProductsGridService,
    GetApDfbByProvincesService,
    GetApDfbByProductService,
    GetApDfbAnnouncementsGridService,
    GetApDfbByEmployeesGridService,
    //cashback
    GetApDaUCbForecastsService,
    GetApDaUCbForecastsExcelReportService,
    GetApDaUCbForeCastService,
    GetApDaUCCInfoService,
    GetApDaCbPlansService,
    GetApDaCbHistoriesService,
    GetApDaCbFromInqueryTimesService,
    GetApDaCbToInqueryTimesService,
    GetApDaUCbForecastWithGrowthPercentService
}