import React from 'react';
import { withRouter } from 'react-router';

import { GetInProcessExcelReportService, GetInProcessingOrdersService } from '../../../../../../services/ProfileSetting/UserOrderService';
import NsExportExcelReportButton from '../../../../../../components/downloadFiles/NsExportExcelReportButton';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../../utilities/CultureHelper';
import UpBaseGroupSheetMobileRedirectToAllOrders from '../bases/UpBaseGroupSheetMobileRedirectToAllOrders';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import UpBaseGroupSheetOrdersSearchSection from '../bases/UpBaseGroupSheetOrdersSearchSection';
import UpGroupSheetInProcessOrderDetailModal from './UpGroupSheetInProcessOrderDetailModal';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import { createGroupSheetOrderColumn } from '../bases/UpBaseGroupSheetOrderHelper';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import UserPanelWrapper from '../../../../UserPanelWrapper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Links from '../../../../../../configs/links/AllLinks';
import UrlHelper from '../../../../../../utilities/UrlHelper';
import NsTab from '../../../../../../components/tabs/NsTab';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';

class InProcessOrdersContent extends React.Component {

    constructor(props) {
        super(props);
        this.columns = createGroupSheetOrderColumn(true, true, false, false, this.detailViewClicked);
        this.tabs = ConstantsWithTranslation.getUserOrderInProcessingTabs();
        this.selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);
        this.state = {
            selectedTab: this.selectedTab || this.tabs[0],
            selectedTabIndex: this.tabs.indexOf(this.selectedTab) > -1 ? this.tabs.indexOf(this.selectedTab) : 0,
            searchTerm: '',
            showDetailModal: false,
            orderId: null,
            callServiceDate: null,
            openFeedbackModalDate: null,
            searchValues: {},
            isLoading: true,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => {
        let pathparams = UrlHelper.checkUrlIsOrderDetailFeedback(window.location.pathname);
        if (pathparams.isOrderDetail) {
            this.detailViewClicked(pathparams.orderId);
            if (pathparams.isOrderDetailFeedback)
                this.setState({ openFeedbackModalDate: new Date() });
        }
    }

    detailViewClicked = (id) => {
        this.setState({ showDetailModal: true, orderId: id });
        !!this.props.history && this.props.history.push(CommonHelper.stringFormat(
            getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrderDetail), id));
    }

    closeDetailModalClicked = (refresh, resultMessageClass) => {
        this.setState({ showDetailModal: false, openFeedbackModalDate: null });
        !!this.props.history && this.props.history.push(this.state.selectedTab?.url);
        !!refresh && this.setState({ callServiceDate: new Date(), resultMessageClass });
    }

    tabChanged = (newValue, selectedTab) => newValue !== this.state.selectedTabIndex &&
        this.setState({ selectedTabIndex: newValue, callServiceDate: new Date(), isLoading: true, selectedTab }, () =>
            !!this.props.history && this.props.history.push(selectedTab.url));

    searchFormClicked = (searchValues) => this.setState({ searchValues, isLoading: true, callServiceDate: new Date() });

    getData = (rows, isLoading) => this.setState({ isLoading });

    render() {
        const { callServiceDate, searchValues, isLoading, selectedTabIndex, selectedTab, showDetailModal, orderId,
            resultMessageClass, openFeedbackModalDate } = this.state;

        return (
            <>
                <UpBaseGroupSheetOrdersSearchSection
                    searchFormClicked={this.searchFormClicked}
                    findOrdersSearchSection
                    autoFocus
                    isLoading={isLoading}>

                    <NsExportExcelReportButton searchValues={{ SharedSheetOrderStateTypes: !isEmpty(selectedTab?.id) ? [selectedTab?.id] : null, ...searchValues }}
                        isLoading={isLoading}
                        service={GetInProcessExcelReportService} />
                </UpBaseGroupSheetOrdersSearchSection>

                <NsTab value={selectedTabIndex}
                    tabs={this.tabs}
                    onChange={this.tabChanged} />

                <NsDataGridWithServiceCall columnDefs={this.columns}
                    hasPagination={true}
                    service={GetInProcessingOrdersService}
                    parameters={{ filter: { sharedSheetOrderStateTypes: !isEmpty(selectedTab?.id) ? [selectedTab?.id] : null, ...searchValues } }}
                    callServiceDate={callServiceDate}
                    gridId={GridEnumerations.siteGrids.inProcessGroupSheetOrdersAll}
                    hasCustomColumnOrder={true}
                    getData={this.getData} />

                <UpBaseGroupSheetMobileRedirectToAllOrders />

                {showDetailModal &&
                    <UpGroupSheetInProcessOrderDetailModal closeModal={this.closeDetailModalClicked}
                        openFeedbackModalDate={openFeedbackModalDate}
                        breadcrumbs={this.props.breadCrumb}
                        orderId={orderId} />
                }
                {resultMessageClass}
            </>
        )
    }
}

export { InProcessOrdersContent };

const UpGroupSheetInProcessOrders = (props) => {
    return (
        <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.inProcess')}
            titleIcon='ns-icon-inprocess-order'>
            <InProcessOrdersContent {...props} />
        </UserPanelWrapper >
    )
}

export default withRouter(UpGroupSheetInProcessOrders);
