import Config from "../../../configs/Config";
import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../configs/Enumerations";
import CommonHelper from "../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import UrlHelper from "../../../utilities/UrlHelper";
import { isEmpty } from "../../../utilities/ValidationHelper";

const urlValidation = (numberValue, value, constant, defaultValue) => !!constant.find(item => item.id === Number(value)) ? { id: numberValue, label: constant.find(item => item.id === numberValue).label } : defaultValue;

const getPriceLabel = (minPrice, maxPrice, isStore) => {
    let maximumFilterPrice = isStore ? Config.products.storeMaximumFilterPrice : Config.products.maximumFilterPrice;
    if (minPrice !== 0 || maxPrice !== maximumFilterPrice)
        return (
            CommonHelper.stringFormat(getTranslatedRawText('product.detail.priceLabel'),
                CommonHelper.currencyFormatByDefaultConfig(minPrice),
                CommonHelper.currencyFormatByDefaultConfig(maxPrice))
        );
    else
        return '';
}

export const getParametersFromUrl = (props, stateFilters, preventRouteToSet, callback) => {

    const filters = { ...stateFilters };
    const { pageNumber, searchInput, price, option, sortBy, categories, isStore } = filters;
    const { minPrice, maxPrice } = price;
    const { printedSide, turnaround } = option;

    const URLParameters = UrlHelper.getRouteParameters(props.location.search);
    const urlCategoryId = UrlHelper.getRouteValue(props, Enumerations.routeKey.categoryId);
    const turnaroundConstant = ConstantsWithTranslation.getTurnarounds();
    const printedSideConstant = ConstantsWithTranslation.getPrintedSide();
    const sortItemsConstant = ConstantsWithTranslation.getSortItems();
    const defaultSorting = Config.homeSection.showBestSellerProducts
        ? sortItemsConstant.find(item => item.id === Enumerations.sortItems.bestSelling)
        : sortItemsConstant.find(item => item.id === Enumerations.sortItems.newest);

    if (!!urlCategoryId) {
        let childrenItem = CommonHelper.recursiveSeparator(CommonHelper.recursiveSeparator(categories).itemsWithChild.filter(item => item.id == urlCategoryId)).parentLessItems.map(item => parseInt(item.id))
        filters.categories = isEmpty(childrenItem)
            ? CommonHelper.recursiveSelector(categories, { targetIds: [urlCategoryId].map(id => parseInt(id)), isChecked: false, isKeepOtherCheckes: false })
            : CommonHelper.recursiveSelector(categories, { targetIds: childrenItem, isChecked: false, isKeepOtherCheckes: false });
    }
    else
        filters.categories = props.categories;

    if (preventRouteToSet && isEmpty(URLParameters))
        filters.sortBy = defaultSorting;

    Object.entries(URLParameters).forEach(([key, value]) => {
        const numberValue = Number(value);
        switch (CommonHelper.camelize(key)) {
            case CommonHelper.getVariableName({ pageNumber }):
                filters.pageNumber = isNaN(numberValue) ? pageNumber : numberValue;
                break;
            case CommonHelper.getVariableName({ searchInput }):
                filters.searchInput = value;
                break;
            case CommonHelper.getVariableName({ printedSide }):
                filters.option.printedSide = urlValidation(numberValue, value, printedSideConstant, null)
                break;
            case CommonHelper.getVariableName({ turnaround }):
                filters.option.turnaround = urlValidation(numberValue, value, turnaroundConstant, null)
                break;
            case CommonHelper.getVariableName({ minPrice }):
                filters.price.minPrice = isNaN(numberValue) ? price.minPrice : numberValue;
                break;
            case CommonHelper.getVariableName({ maxPrice }):
                filters.price.maxPrice = isNaN(numberValue) ? price.maxPrice : numberValue;
                break;
            case CommonHelper.getVariableName({ sortBy }):
                filters.sortBy = urlValidation(numberValue, value, sortItemsConstant, defaultSorting)
                break;
            case CommonHelper.getVariableName({ categories }):
                const selectedCategories = value?.split(',');
                filters.categories = CommonHelper.recursiveSelector(categories, { targetIds: selectedCategories.map(value => parseInt(value)), isChecked: false });
                break;
            default:
                return;
        }
    });

    filters.price.filterLabel = getPriceLabel(filters.price.minPrice, filters.price.maxPrice, isStore);
    callback(filters);
}

export const productsFilterLabel = (filters, categoryDeleteFilter, searchDeleteFilter, priceDeleteFilter, optionDeleteFilter) => {
    const { categories, searchInput, price, option } = filters;
    const categoryFilterLabel = CommonHelper.recursiveSeparator(categories).parentLessItems.filter(child => child?.checked)
        .map(item => ({ filterLabel: item.name, id: item.id, labelDeleteClicked: categoryDeleteFilter }));

    const optionsFiltersLabel = Object.entries(option)
        .map(([key, value]) => ({ filterLabel: value?.label, id: key, labelDeleteClicked: optionDeleteFilter }))
        .filter(item => Object.values(item)[0]);

    const productsFilterLabel = [...categoryFilterLabel,
    { filterLabel: searchInput, labelDeleteClicked: searchDeleteFilter },
    { filterLabel: price.filterLabel, labelDeleteClicked: priceDeleteFilter },
    ...optionsFiltersLabel].filter(item => !isEmpty(item.filterLabel));

    return productsFilterLabel;
}

export const clearFilters = (categories, sortBy, isStore) => {
    const filters = {
        pageNumber: 1,
        categories: categories,
        searchInput: '',
        price: {
            minPrice: 0,
            maxPrice: isStore ? Config.products.storeMaximumFilterPrice : Config.products.maximumFilterPrice,
            filterLabel: ""
        },
        option: {
            printedSide: null,
            turnaround: null
        },
        sortBy: sortBy,
    };
    return filters;
}