import clsx from 'clsx';
import React, { Component } from 'react';

import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsButton from '../../../../../components/buttons/NsButton';
import NsToggleGroupButton from '../../../../../components/buttons/NsToggleGroupButton';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import '../../../../../assets/sass/views/orders/professionalOrder.scss';
import Enumerations from '../../../../../configs/Enumerations';
import NsEtiquette from '../../../../../components/etiquette/NsEtiquette';
import NsGroupNumberInput from '../../../../../components/inputs/NsGroupNumberInput';

class PoFoldLineModalCustomized extends Component {
    constructor(props) {
        super(props);
        this.foldLineDirections = ConstantsWithTranslation.foldLineDirections();
        this.foldLineShapes = ConstantsWithTranslation.foldLineShapes();
    }

    render() {
        const {
            foldLines,
            isAddButtonDisabled,
            maxFileDimension,
            maxFoldLineNumber,
            unacceptableFoldLines,
            outOfTrimZoneFoldLines,
            isFoldLineDirectionVertical,
            minimumDistanceBetweenFoldLines,
            toggleLineDirectionClicked,
            addFoldLineClicked,
            changeFoldLinePositionClicked,
            toggleConvexityClicked,
            deleteFoldLineClicked,
            handleFoldLineFocus,
            isPerforation
        } = this.props;

        return (
            <>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='group-button__direction'>
                        <NsToggleGroupButton isShowGroupLabel={false}
                            onClick={toggleLineDirectionClicked}
                            // selectedItemId={Enumerations.foldLineDirections.vertical}
                            selectedItemId={isFoldLineDirectionVertical ? Enumerations.foldLineDirections.vertical : Enumerations.foldLineDirections.horizontal}
                            // specialSelectedId={isFoldLineDirectionVertical ? 1 : 0}
                            items={this.foldLineDirections} />
                    </div>
                    <div className='d-flex'>
                        <div className='me-1'>( {foldLines.length} / {maxFoldLineNumber} )</div>
                        <NsButton label='regularOrder.common.add'
                            size='small'
                            disabled={isAddButtonDisabled}
                            className='secondary'
                            startIcon="ns-icon-add"
                            onClick={addFoldLineClicked} />
                    </div>
                </div>
                <div>
                    <FoldLineList foldLines={foldLines}
                        foldLineShapes={this.foldLineShapes}
                        isPerforation={isPerforation}
                        maxFileDimension={maxFileDimension}
                        isFoldLineDirectionVertical={isFoldLineDirectionVertical}
                        changeFoldLinePositionClicked={changeFoldLinePositionClicked}
                        deleteFoldLineClicked={deleteFoldLineClicked}
                        unacceptableFoldLines={unacceptableFoldLines}
                        outOfTrimZoneFoldLines={outOfTrimZoneFoldLines}
                        minimumDistanceBetweenFoldLines={minimumDistanceBetweenFoldLines}
                        toggleConvexityClicked={toggleConvexityClicked}
                        handleFoldLineFocus={handleFoldLineFocus} />
                </div>
            </>

        )
    }
}

function FoldLineList(props) {
    const { foldLines,
        isFoldLineDirectionVertical,
        changeFoldLinePositionClicked,
        toggleConvexityClicked,
        foldLineShapes,
        maxFileDimension,
        unacceptableFoldLines,
        outOfTrimZoneFoldLines,
        minimumDistanceBetweenFoldLines,
        handleFoldLineFocus,
        isPerforation
    } = props;
    let style, border, errorText = '';
    let groupButtonTitle = isFoldLineDirectionVertical ? 'offSetFromLeft' : 'offSetFromTop';
    const foldLineList = foldLines.map((foldLine, index) => {
        let addButtonDisabled;
        if (foldLine.distanceFromOrigin >= maxFileDimension)
            addButtonDisabled = true;
        if (unacceptableFoldLines.includes(index + 1)) {
            errorText = CommonHelper.stringFormat(getTranslatedRawText(isPerforation ? 'regularOrder.modal.perforationDistanceError' : 'regularOrder.modal.foldLineDistanceError'), minimumDistanceBetweenFoldLines);
            style = 'background-red-color white-color';
            border = 'border-red-color-1px';
        }
        else {
            style = 'background-outline-color';
            border = 'border-outline-color-1px';
            errorText = ''
        }
        if (outOfTrimZoneFoldLines.includes(index + 1)) {
            errorText = getTranslatedRawText('regularOrder.modal.foldLineTrimZoneError');
            style = 'background-red-color white-color';
            border = 'border-red-color-1px';
        }
        return (
            <NsEtiquette style={style}
                border={border}
                errorText={errorText}
                key={foldLine.id}
                index={index}
                title={getTranslatedRawText(isPerforation ? 'regularOrder.billBook.perforation' : 'regularOrder.extendedService.foldLine')}
                deleteEtiquette={props.deleteFoldLineClicked}>
                <div className={clsx('d-flex align-items-end flex-wrap', isPerforation ? ' justify-content-start ms-2' : ' justify-content-around')}>
                    <NsGroupNumberInput label={getTranslatedRawText(`regularOrder.extendedService.${groupButtonTitle}`)}
                        fieldClassName='bg-white'
                        value={foldLine.distanceFromOrigin}
                        inputProps={{ min: 0, max: maxFileDimension }}
                        step={1}
                        disabled={true}
                        unit={getTranslatedRawText('common.mm')}
                        handleFocus={() => handleFoldLineFocus(index)}
                        addButtonDisabled={addButtonDisabled}
                        minusButtonDisabled={foldLine.distanceFromOrigin < 1}
                        onChanged={(modifiedFoldLine) => changeFoldLinePositionClicked(modifiedFoldLine, index)}
                    />
                    {!isPerforation && <div className='group-button__convex'>
                        <NsToggleGroupButton isShowGroupLabel={false}
                            onClick={(e) => toggleConvexityClicked(e, index)}
                            selectedItemId={foldLine.isConvex ? Enumerations.foldLineShapes.convex : Enumerations.foldLineShapes.concave}
                            items={foldLineShapes} />
                    </div>
                    }
                </div>
            </NsEtiquette>
        );
    });
    return foldLineList;
}

export default PoFoldLineModalCustomized;