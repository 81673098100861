import React, { useEffect, useState } from 'react';

import { GetStoreProductNameService } from '../../../../../services/adminPanel/AdminStoreOrderService';
import { GetAgencyListService } from '../../../../../services/adminPanel/AdminAgenciesService';
import { categoryActionCreators } from '../../../../../redux/reducers/CategoryReducer';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import ApBaseSupporterSelectModal from '../../../bases/ApBaseSupporterSelectModal';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseUserSelectModal from '../../../bases/ApBaseUserSelectModal';
import ApBaseSearchButtons from '../../../bases/ApBaseSearchButtons';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import Enumerations from '../../../../../configs/Enumerations';
import { useDispatch, useSelector } from 'react-redux';

const ApStoreOrderSearch = (props) => {

    const [showUserSelectModal, setShowUserSelectModal] = useState(false)
    const [user, setUser] = useState(props.customerDetails?.phoneNumber || '');
    const [invoiceNo, setInvoiceNo] = useState('');
    const [lastStatuses, setLastStatuses] = useState([])
    const [selectedProductsId, setSelectedProductsId] = useState([]);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [submitTimeFrom, setSubmitTimeFrom] = useState(null);
    const [submitTimeTo, setSubmitTimeTo] = useState(null);
    const [statusDateFrom, setStatusDateFrom] = useState(null);
    const [statusDateTo, setStatusDateTo] = useState(null);
    const [orderApplicationTypes, setOrderApplicationTypes] = useState([]);
    const [minPaymentInAdvance, setMinPaymentInAdvance] = useState('');
    const [maxPaymentInAdvance, setMaxPaymentInAdvance] = useState('');
    const [minDiscountAmount, setMinDiscountAmount] = useState('');
    const [maxDiscountAmount, setMaxDiscountAmount] = useState('');
    const [changeStatusByAdmin, setChangeStatusByAdmin] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [selectedUser, setSelectedUser] = useState(props.customerDetails || '');
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [showAdminSelectModal, setShowAdminSelectModal] = useState('');
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [productCategories, setProductCategories] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [agencies, setAgencies] = useState([]);
    const [resultMessageClass, setResultMessageClass] = useState('');

    const dispatch = useDispatch();

    const handleCategories = () => dispatch(categoryActionCreators.getStoreCategories());

    useEffect(() => {
        handleCategories();
        setIsLoading(true);
        let params = {
            // id: selectedCity
        }
        GetAgencyListService(params, getAgencyNameListCallback);
    }, []);

    const getAgencyNameListCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (result?.data?.hasError) setResultMessageClass(messageClassModal);
        else setAgencies(resultData);
    }

    const categories = useSelector(state => {
        return {
            categories: state.categories.storeCategories
        }
    });

    useEffect(() => {
        !isEmpty(selectedUser) && searchClicked();
    }, [selectedUser])


    useEffect(() => {
        !isEmpty(selectedAdmin) && searchClicked();
    }, [selectedAdmin])

    const getStoreProductNameCallback = (resultData, result) => {
        setIsLoading(false);
        if (!result?.data?.hasError) {
            setProducts(resultData);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        GetStoreProductNameService(getStoreProductNameCallback);
    }, [])

    const usersModalButtonClicked = () => setShowUserSelectModal(true);

    const adminsModalButtonClicked = () => setShowAdminSelectModal(true);

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptionsInMobile = [
        hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            onChange: (value) => setUser(value),
            endAdornment: !props.customerDetails?.phoneNumber ? <NsButton className='light-circle'
                loading={isLoading}
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" /> : null
        },
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.invoiceNo'),
            fieldName: 'invoiceNo',
            value: invoiceNo,
            onChange: (value) => setInvoiceNo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.lastStatus'),
            fieldName: 'lastStatuses',
            value: lastStatuses,
            items: ConstantsWithTranslation.getStoreStatusesOrder(),
            onChange: (value) => setLastStatuses(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.orderType.orderApplicationType'),
            fieldName: 'orderApplicationTypes',
            value: orderApplicationTypes,
            items: ConstantsWithTranslation.getOrderApplicationType(),
            onChange: (value) => setOrderApplicationTypes(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('profile.allOrders.productCategory'),
            fieldName: 'productCategories',
            value: productCategories,
            items: categories.categories || [],
            onChange: (value) => setProductCategories(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.product'),
            fieldName: 'selectedProductsId',
            value: selectedProductsId,
            items: !isEmpty(products) ? products : [],
            onChange: (value) => setSelectedProductsId(value)
        },
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeFrom'),
            fieldName: 'submitTimeFrom',
            value: submitTimeFrom,
            onChange: (value) => setSubmitTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeTo'),
            fieldName: 'submitTimeTo',
            value: submitTimeTo,
            onChange: (value) => setSubmitTimeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.statusDateFrom'),
            fieldName: 'statusDateFrom',
            value: statusDateFrom,
            onChange: (value) => setStatusDateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.statusDateTo'),
            fieldName: 'statusDateTo',
            value: statusDateTo,
            onChange: (value) => setStatusDateTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.minAmount'),
            fieldName: 'minPrice',
            value: minPrice,
            onChange: (value) => setMinPaymentInAdvance(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.maxAmount'),
            fieldName: 'maxPrice',
            value: maxPrice,
            onChange: (value) => setMaxPaymentInAdvance(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.minPaymentInAdvance'),
            fieldName: 'minPaymentInAdvance',
            value: minPaymentInAdvance,
            onChange: (value) => setMinPaymentInAdvance(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.maxPaymentInAdvance'),
            fieldName: 'maxPaymentInAdvance',
            value: maxPaymentInAdvance,
            onChange: (value) => setMaxPaymentInAdvance(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.minDiscountAmount'),
            fieldName: 'minDiscountAmount',
            value: minDiscountAmount,
            onChange: (value) => setMinDiscountAmount(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.maxDiscountAmount'),
            fieldName: 'maxDiscountAmount',
            value: maxDiscountAmount,
            onChange: (value) => { setMaxDiscountAmount(value); }
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.usersInfo.agency'),
            fieldName: 'selectedAgency',
            value: selectedAgency,
            items: !isEmpty(agencies) ? agencies : [],
            onChange: (value) => setSelectedAgency(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.changeStatusByAdmin'),
            fieldName: 'changeStatusByAdmin',
            value: changeStatusByAdmin,
            disabled: true,
            onChange: (value) => setChangeStatusByAdmin(value),
            endAdornment: <NsButton className='light-circle'
                loading={isLoading}
                onClick={adminsModalButtonClicked}
                startIcon="ns-icon-more" />
        }
    ];

    const showMoreClicked = () => setShowMore(!showMore);

    const searchValues = {
        user,
        invoiceNo,
        lastStatuses,
        products,
        selectedProductsId,
        minPrice,
        maxPrice,
        submitTimeFrom,
        selectedAgency,
        submitTimeTo,
        productCategories,
        statusDateFrom,
        statusDateTo,
        minPaymentInAdvance,
        maxPaymentInAdvance,
        minDiscountAmount,
        maxDiscountAmount,
        changeStatusByAdmin,
        orderApplicationTypes
    }

    const searchClicked = () => CommonHelper.safeFunctionCall(props.setSearchData, searchValues);

    const clearButtonClicked = () => {
        setUser('');
        setSelectedUser('');
        setChangeStatusByAdmin('');
        setSelectedAdmin('');
        setInvoiceNo('');
        setLastStatuses([]);
        setSelectedProductsId([]);
        setMaxPrice('');
        setMinPrice('');
        setSelectedAgency(null);
        setProductCategories([]);
        setSubmitTimeFrom(null);
        setSubmitTimeTo(null);
        setStatusDateFrom(null);
        setStatusDateTo(null);
        setMinPaymentInAdvance('');
        setMaxPaymentInAdvance('');
        setMinDiscountAmount('');
        setMaxDiscountAmount('');
        setChangeStatusByAdmin('');
        setOrderApplicationTypes([])
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user?.phoneNumber);
        setShowUserSelectModal(false);
    }

    const adminSelected = (admin) => {
        setChangeStatusByAdmin(admin?.phoneNumber);
        setSelectedAdmin(admin);
        setShowAdminSelectModal(false);
    }

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={showMore
                    ? advanceSearchOptions
                    : CommonHelper.isMobileDevice()
                        ? basicSearchOptionsInMobile
                        : basicSearchOptions}
                    searchValues={searchValues} mdCol={2} smCol={4} />
                <ApBaseSearchButtons searchClicked={searchClicked}
                    showMoreHandler={showMoreClicked}
                    showMore={showMore}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={props.isLoading} />
            </div>

            {showAdminSelectModal &&
                <ApBaseSupporterSelectModal showModal={showAdminSelectModal}
                    adminSelected={adminSelected}
                    closeModal={() => setShowAdminSelectModal(false)} />
            }

            {showUserSelectModal &&
                <ApBaseUserSelectModal showModal={showUserSelectModal}
                    userSelected={userSelected}
                    closeModal={() => setShowUserSelectModal(false)} />
            }

            {resultMessageClass}
        </>
    )

}
export default React.memo(ApStoreOrderSearch);