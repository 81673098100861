import React from 'react';
import { Col, Row } from 'react-bootstrap';
import clsx from 'clsx'

import { getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import ProductDetailTours from '../../bases/tours/ProductDetailTours';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import PdpMainSection from './mainSections/PdpMainSection';
import CommonHelper from '../../../utilities/CommonHelper';
import Enumerations from '../../../configs/Enumerations';
import UrlHelper from '../../../utilities/UrlHelper';
import NsTour from '../../../components/tour/NsTour';
import Links from '../../../configs/links/AllLinks';
import PdpSidebar from './sidebars/PdpSidebar';

class PdpProfessionalDetail extends React.Component {
    constructor(props) {
        super(props)
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.professionalProductDetail);
        this.tourSteps = ProductDetailTours.getProductDetailTour(true);
        this.state = {
            selectedId: UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id),
            productsLoaded: false,
            showMainSectionCollapse: false,
            printCirculations: []
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.selectedId !== prevState.selectedId)
            this.props.history.replace(CommonHelper.stringFormat(
                getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.productDetails), this.state.selectedId))

        window.onpopstate = (e) => this.setState({ selectedId: UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id) });
    }

    selectedIdChanged = (selectedId) => this.setState({ selectedId });

    productsLoaded = (isLoaded) => this.setState({ productsLoaded: isLoaded });

    isOpenChanged = (showMainSectionCollapse, isSidebar) => this.setState({ showMainSectionCollapse: isSidebar ? showMainSectionCollapse : !showMainSectionCollapse });

    render() {
        return (
            <Row className={clsx(!CommonHelper.isMobileDevice() && "border border-light-text-color product-detail m-0 p-1")} onKeyDown={this.keyDown}>
                <Col md={4} sm={12} >
                    <PdpSidebar selectedId={this.state.selectedId}
                        selectedIdChanged={this.selectedIdChanged}
                        productsLoaded={this.productsLoaded}
                        showMainSectionCollapse={!this.state.showMainSectionCollapse}
                        isOpenStatus={this.isOpenChanged}
                        printCirculations={this.state.printCirculations} />
                </Col>
                <Col md={8} sm={12} className='mb-5 mt-3'>
                    <PdpMainSection selectedId={this.state.selectedId}
                        showMainSectionCollapse={this.state.showMainSectionCollapse}
                        isOpenStatus={this.isOpenChanged}
                        printCirculations={(printCirculations) => this.setState({ printCirculations })} />
                </Col>

                {(this.showTour && this.state.productsLoaded) && <NsTour steps={this.tourSteps}
                    id={Enumerations.tourGuide.professionalProductDetail} />}


            </Row>
        );
    }
}

export default PdpProfessionalDetail