import React, { Component } from 'react';

import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import Enumerations from '../../../../configs/Enumerations';
import { GetAgencyCustomersService } from '../../../../services/ProfileSetting/AgencyService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../UserPanelWrapper';
import UpBaseAgencySearchOptions from './bases/UpBaseAgencySearchOptions';

class UpAgencyCustomers extends Component {

	constructor(props) {
		super(props);
		this.columns = [
			{
				field: 'fullName',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
				width: 170,
			},
			{
				field: 'mobileNumber',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
				width: 120,
				dataType: Enumerations.gridType.number,
			},
			{
				field: 'companyName',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.companyName'),
				width: 130,
			},
			{
				field: 'nationalCode',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.idNumber'),
				width: 120,
				dataType: Enumerations.gridType.number,
			},
			{
				field: 'accountStatus',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.accountStatus'),
				width: 130,
				dataType: Enumerations.gridType.accountStatus,
				cellRenderer: "customRowRenderer",
				cellRendererParams: {
					customElementRender: (props) => {
						return <div className={props.accountStatus ? 'success-color' : 'red-color'}>{props.accountStatus ? getTranslatedRawText('adminPanel.usersInfo.active') : getTranslatedRawText('adminPanel.usersInfo.block')}</div>
					}
				}
			},
			{
				field: 'accountType',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.accountType'),
				width: 120,
				cellRenderer: "customRowRenderer",
				cellRendererParams: {
					customElementRender: (props) => {
						switch (props.accountType) {
							case Enumerations.accountType.personal:
								return getTranslatedRawText('adminPanel.usersInfo.personal');
							case Enumerations.accountType.business:
								return getTranslatedRawText('adminPanel.usersInfo.business');
							default:
								return;
						}
					}
				}
			},
			{
				field: 'roles',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.role'),
				width: 100,
			},
			{
				field: 'country',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.country'),
				width: 100
			},
			{
				field: 'state',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.state'),
				width: 80,
			},
			{
				field: 'registredTime',
				headerName: getTranslatedRawText('adminPanel.dataGridColumn.registrationTime'),
				width: 150,
				dataType: Enumerations.gridType.dateTime
			},
		];
		this.state = {
			parameters: [],
			callServiceDate: null,
			refreshDate: null,
			isLoading: false
		}
	}

	getData = (rows, isLoading) => this.setState({ isLoading });

	searchFormClicked = (parameters) => this.setState({
		parameters: {
			'Filter.PhoneNumber': parameters?.mobile ? CommonHelper.removeMobileNumberMask(parameters?.mobile) : null,
			'Filter.AccountStatus': !isEmpty(parameters?.accountStatus) ? parameters?.accountStatus?.status : null,
			'Filter.HaveCompany': parameters?.accountTypes?.id || null,
			'Filter.CityIds': parameters?.city?.key || [],
		},
		callServiceDate: new Date()
	});


	render() {
		const { isLoading, callServiceDate, parameters } = this.state;

		return (
			<UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.agencyCustomers')}
				titleIcon='ns-icon-agency-customers'>

				<UpBaseAgencySearchOptions searchFormClicked={this.searchFormClicked}
					autoFocus={true}
					isLoading={isLoading}
					findAgenciesUser={true} />

				<NsDataGridWithServiceCall callServiceDate={callServiceDate}
					service={GetAgencyCustomersService}
					parameters={parameters}
					columnDefs={this.columns}
					hasPagination={true}
					getData={this.getData} />

			</UserPanelWrapper >
		)
	}
}

export default UpAgencyCustomers;