import React, { useState } from 'react';

import '../../../../../../assets/sass/views/orders/professionalOrder.scss';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import NsModal from '../../../../../../components/modals/NsModal';
import { GetAdminFoldLinesService } from '../../../../../../services/adminPanel/AdminOrderService';
import FoldLinesContent from '../../../../../profileSettings/modals/foldLines/FoldLinesContent';

const ApOrderFoldLinesModal = (props) => {

    const { id, userId, draft, resolution } = props;

    const [resultData, setResultData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    const setRatio = (ratio) => GetAdminFoldLinesService(id, userId, getFoldLinesCallback(ratio));

    const getFoldLinesCallback = (ratio) => (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError)
            setResultData({ ...resultData, ratio, });
        else
            setResultMessageClass(messageClassModal);
    }

    return (
        <NsModal showModal={true}
            maxWidth="md"
            closeModal={props.closeModal}
            className='fold-line-modal'
            dialogClassName="modal-choose-printing-files"
            dialogTitle={getTranslatedRawText('regularOrder.modal.foldLineTitle')}>

            <FoldLinesContent resultData={resultData}
                draft={draft}
                resolution={resolution}
                isLoading={isLoading}
                setRatio={setRatio} />

            {resultMessageClass}

        </NsModal >
    )
}

export default React.memo(ApOrderFoldLinesModal);