import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';

import NsStickyButton from "../../../../../components/buttons/NsStickyButton";
import NsMessageClassModal from "../../../../../components/modals/NsMessageClassModal";
import NsModal from "../../../../../components/modals/NsModal";
import Enumerations from "../../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import Links from "../../../../../configs/links/AllLinks";
import { DeleteBlogService, EditBlogService, GetBasicBlogCategoriesService, GetBlogByIdService, PostBlogService } from "../../../../../services/adminPanel/AdminBlogService";
import CommonHelper from "../../../../../utilities/CommonHelper";
import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import ApBaseMapActionButtons from "../../../bases/ApBaseMapActionButtons";
import ApLanguageTabs from "../../../bases/ApLanguageTabs";
import ApBasesPostContentModal from '../../bases/ApBasesPostContentModal';
import ContentHelper from "../../bases/ContentHelper";


const ApPostModal = (props) => {

    const { postId, closeModal, isNewPost } = props;

    const [multiLanguagePostContent, setMultiLanguagePostContent] = useState([])
    const [messageClassModal, setMessageClassModal] = useState(<></>)
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [showValidationDate, setShowValidationDate] = useState();
    const [showDeletePostModal, setShowDeletePostModal] = useState(false);
    const [isLoading, setIsLoading] = useState(!isNewPost);
    const [editorValue, setEditorValue] = useState("");
    const [categories, setCategories] = useState([])

    useEffect(() => {
        !!isNewPost
            ? setMultiLanguagePostContent(ContentHelper.normalizeContentObject([], { isActive: false, pageContentCommentType: Enumerations.pageContentCommentType.allowWithAdminConfirmation }))
            : GetBlogByIdService(postId, getPostContentByIdCallback);
    }, []);

    useEffect(() => {
        GetBasicBlogCategoriesService(getBlogCategoriesCallback)
    }, [])

    const getBlogCategoriesCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            setCategories(resultData);
        } else
            setMessageClassModal(messageClassModal);
    }

    const getPostContentByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguagePostContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive, pageCategories: resultData[0].content.pageCategories, pageContentCommentType: resultData[0].content.pageContentCommentType }));
        } else
            setMessageClassModal(messageClassModal);
    }

    const closeModalClicked = (messageClassModal) => CommonHelper.safeFunctionCall(closeModal, messageClassModal);

    const saveButtonClicked = () => {
        editorHandler(true, [...multiLanguagePostContent], editorValue, selectedLanguage.id)
        let tempContent = ContentHelper.removePostEmptyContent(multiLanguagePostContent);
        let isNotValidData = ContentHelper.isValidPostContent(tempContent);
        if (isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }

        setIsLoading(true);
        !!isNewPost
            ? PostBlogService(tempContent, postContentCallback, true)
            : EditBlogService(postId, tempContent, postContentCallback, true);
    };

    const postContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModalClicked(messageClassModal);
        } else setMessageClassModal(messageClassModal)
    }

    const deletePostClicked = () => setShowDeletePostModal(true);

    const editPostClicked = () => props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogPostDetail),
        postId, CommonHelper.makeCorrectLink(multiLanguagePostContent[0].content.title)));

    const deletePost = (confirm) => {
        setShowDeletePostModal(false);
        if (confirm) {
            setIsLoading(true);
            DeleteBlogService(postId, deletePostContentCallback, true);
        }
    };

    const deletePostContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModalClicked(messageClassModal);
        else
            setMessageClassModal(messageClassModal);
    };

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguagePostContent([...newData]);
    }

    const editorHandler = (isMergeData, data, value, selectedLanguageId) => {
        if (isMergeData)
            !isEmpty(value) && stateHandler(data, value, selectedLanguageId);
        else
            setEditorValue(value);
    }

    const mainButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary', accessIds: [UserClaimEnumerations.editApBlog] },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(false), className: 'primary-outline' },
    ];

    const headerButtons = [
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deletePostClicked, accessIds: [UserClaimEnumerations.deleteApBlog] },
        postId && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-blog', label: 'adminPanel.post.showInWebsite', onClick: editPostClicked }
    ];

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText(!postId ? 'adminPanel.post.newPost' : 'adminPanel.post.editPost')}
            showModal={true}
            noSpace={true}
            closeModal={() => CommonHelper.safeFunctionCall(closeModal)}
            actions={< NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>

            {!isNewPost && <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            }

            <div className="p-3">
                <ApLanguageTabs languageChanged={(selectedTab) => {
                    editorHandler(true, [...multiLanguagePostContent], editorValue, selectedLanguage.id)
                    languageChangedHandler(selectedTab)
                }}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguagePostContent) &&
                    <ApBasesPostContentModal key={selectedLanguage.id}
                        categories={categories}
                        serviceCall={() => GetBlogByIdService(postId, getPostContentByIdCallback)}
                        stateHandler={stateHandler}
                        editorHandler={editorHandler}
                        data={[...multiLanguagePostContent]}
                        fileUploaderCategoryType={Enumerations.fileUploaderCategoryType.blog}
                        showValidationDate={showValidationDate}
                        selectedLanguage={selectedLanguage} />
                }
            </div>
            {
                showDeletePostModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => deletePost(confirm)}
                    messageItems={[
                        {
                            messageText: <>{getTranslatedRawText('common.messages.areYouSureQuestionWithOutParams')} <b>{ContentHelper.getSpecificLanguageContent(multiLanguagePostContent, ContentHelper.getDefaultCultureId()).content.title}</b>?</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }
            {messageClassModal}
        </NsModal >
    );
}

export default React.memo(withRouter(ApPostModal));