import clsx from "clsx";
import React from "react";
import { withRouter } from "react-router-dom";

import { getTranslatedRawText, getUrlWithCurrentCulture, removeQueryFromURL } from "../../../utilities/CultureHelper";
import NsSetPageTitleAndDescription from "../../../components/metaTags/NsSetPageTitleAndDescription";
import lowQuantityAdvantage from '../../../assets/images/shoppingbag/lowQuantityAdvantage.svg';
import multiColorExpertise from '../../../assets/images/shoppingbag/multiColorExpertise.svg';
import fastProduction from '../../../assets/images/shoppingbag/fastProduction.svg';
import noBlockCharge from '../../../assets/images/shoppingbag/noBlockCharge.svg';
import NsHtmlToReact from "../../../components/htmlToReact/NsHtmlToReact";
import NsLabelLine from "../../../components/labels/NsLabelLine";
import NsTypography from "../../../components/text/NsTypography";
import { isEmpty } from "../../../utilities/ValidationHelper";
import CommonHelper from "../../../utilities/CommonHelper";
import ShoppingBagWrapper from "./ShoppingBagWrapper";
import Links from "../../../configs/links/AllLinks";
import { Col, Row } from "react-bootstrap";

class ShoppingBagAdvantages extends React.Component {
    constructor(props) {
        super(props);
        this.advantages = [
            { id: 1, title: 'Low Quantity Advantage', description: 'Accepting quantities as low as 1000, while other companies require a minimum order of 10,000.', icon: lowQuantityAdvantage, onClick: () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagAdvantage), { id: 1 }) },
            { id: 2, title: 'No Block Charge', description: 'Offering a pricing advantag for additional colors—no extra charge for a broader color palette.', icon: noBlockCharge, onClick: () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagAdvantage), { id: 2 }) },
            { id: 3, title: 'Multi-Color Expertise', description: 'Supporting millions of colors in printing, versus the limitations of others restricted to 1-6 colors.', icon: multiColorExpertise, onClick: () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagAdvantage), { id: 3 }) },
            { id: 4, title: 'Fast Production', description: 'Ensuring rapid production and delivery within 5 days, showcasing our commitment to efficiency.', icon: fastProduction, onClick: () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagAdvantage), { id: 4 }) }
        ]
        this.textItems = [
            {
                title: 'Unlocking Business Success Through Low Quantity Printing: A Paradigm Shift in Flexibility and Affordability for Paper Shopping Bags',
                body: ''
            },
            {
                title: '1. The Rise of Low Quantity Printing (LQP):',
                body: `<p>Are you currently on the lookout for a reliable and budget-friendly paper shopping bag printing service in the vibrant city of Dubai, United Arab Emirates? Well, your search ends here with Diamond Printing Press. What sets us apart from other printing companies is our commitment to flexibility. Unlike those imposing a hefty minimum order of 10,000 bags, we recognize and respect the diverse needs and preferences of our clients.
                At Diamond Printing Press, we offer you unparalleled flexibility, allowing you to place orders for as low as 1000 bags. And here's the best part – this flexibility comes without any compromise on the quality or the pricing. Whether you are a retail store, an online business, or planning a promotional event, we've got you covered. Our team is dedicated to helping you craft the perfect paper shopping bags that seamlessly align with your brand identity and budget constraints.</p>
                <p>With our revolutionary low-quantity printing service, you not only save on costs but also contribute to waste reduction, showcasing your commitment to sustainable practices. Elevate your brand, streamline your expenses, and enhance customer satisfaction by choosing Diamond Printing Press for all your paper shopping bag printing needs in Dubai, UAE.</p>
                `
            },
            {
                title: '2. Navigating the No Block Charge Advantage:',
                body: `<p>One of the most frustrating things about printing kraft shopping bags is the hidden fees that some printing companies charge. You may think you are getting a good deal, but then you find out that you have to pay extra for each color block that you use in your design.</p>
                <p>This can quickly add up and make your printing project more expensive than you expected. At Diamond Printing Press, we believe in transparency and honesty. We do not charge you any block fees, no matter how many colors you use in your design. You can choose from millions of colors and create stunning, vibrant prints that catch the attention of your customers.</p>
                <p>With Diamond Printing Press, you can enjoy true creative freedom without worrying about additional costs. Our no block charge advantage gives you the opportunity to explore a spectrum of colors for your designs without constraints. You can unlock the full potential of your paper shopping bags and make them more attractive and appealing.</p>
                <p>With our No additional color fees, you can get more value for your money and more satisfaction for your customers.</p>`
            },
            {
                title: `3. Millions of Colors, One Printing Partner:`,
                body: `<p>If you want to make your paper shopping bags stand out from the crowd, you need to use colors that are eye-catching and appealing. Colors can convey different emotions and messages, and they can also influence the buying behavior of your customers.</p>
                <p>That is why you need to choose the right colors for your kraft shopping bags and make sure they match your brand image and your marketing goals. However, not all printing companies can offer you the same level of color expertise and variety.</p>
                <p>Some printing companies are limited by the flexographic printing technology, which only allows them to print a limited number of colors, usually 1, 2, 4, or 6. Flexographic printing is a type of printing that uses flexible plates and fast-drying inks to transfer images onto various materials, such as paper, plastic, or metal.</p>
                <p>Flexographic printing is widely used for printing kraft shopping bags, but it has some limitations. One of the main limitations is that you cannot use complex or gradient colors in your design, and you have to compromise on the quality and appearance of your paper shopping bags.</p>
                <p>Diamond Printing Press is different. We use the latest offset printing technology, which enables us to print millions of colors with high accuracy and resolution. Offset printing is a type of printing that uses metal plates and oil-based inks to transfer images onto paper. Offset printing can print fine details and smooth edges, as well as any color you can imagine.</p>
                <p>With offset printing, you can create stunning, vibrant prints that catch the attention of your customers. You can also mix and match different colors and fonts to create a unique and memorable design that reflects your brand identity. With Diamond Printing Press, you can enjoy the best of both worlds: the durability and eco-friendliness of kraft paper, and the unlimited color options of offset printing.</p>
`
            },
            {
                title: `4. Swift Turnaround with Fast Production:`,
                body: `<p>We know that time is of the essence when it comes to printing paper shopping bags. You may have a tight deadline, or you may want to launch your new product or service as soon as possible.</p>
                <p>You do not want to wait for weeks or months to receive your printed kraft shopping bags, and you do not want to compromise on the quality or the quantity. That is why you need a printing company that can deliver fast and efficient service, without sacrificing the quality or the value.</p>
                <p>Diamond Printing Press is the printing company you need. We have a team of experienced and skilled professionals who can handle any printing project, no matter how big or small. We have a state-of-the-art printing facility that can produce high-quality paper shopping bags in a short time.</p>
                <p>We use the latest digital printing technology, which enables us to print millions of colors with high accuracy and resolution. We also use eco-friendly and durable kraft paper, which is biodegradable and recyclable.</p>
                <p>With our experienced team and state-of-the-art facility, we can deliver high-quality paper shopping bags in just 8 days to Dubai, Abu Dhabi, Sharjah, Ajman, Ras Al Khaimah, Fujairah, Umm al-Quwain, and Muscat, Oman.</p>
                <p>You can trust us to meet your deadlines and exceed your expectations. With our fast production service, you can experience the perfect balance of speed and precision for your printing projects.</p>`
            },
        ];

        this.state = {
            selectedTitle: this.textItems[0].title,
        };
    }

    componentDidMount() {
        if (!this.props.isMainAdvantage) {
            if (!isEmpty(this.props.location?.state?.id)) this.handleClickScroll(this.props.location?.state?.id)
            else window.scrollTo({ top: 120, behavior: 'smooth' })
        }
    }

    showTableOfContent = (isMainAdvantage) => {
        return !isMainAdvantage && (<div className='d-inline-block background-element-color p-3 my-5 rounded'>
            <NsTypography variant='inherit' className='d-inline-block section-title mb-2'>
                {getTranslatedRawText('singlePage.privacyPolicy.tableOfContent')}
            </NsTypography>
            {this.textItems.map((textItem, index) => {
                return (<a key={index} href={`${removeQueryFromURL(getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagExploreProduct))}#${CommonHelper.makeCorrectLink(textItem.title)}`} className='table-content d-block pb-1' onClick={() => this.setState({ selectedTitle: textItem.title })}>
                    {textItem.title}
                </a>)
            })}
        </div>)
    }

    renderChild = (isMainAdvantage) => {
        const currentTextItems = !isMainAdvantage
            ? this.textItems
            : [this.textItems[0]];

        return (
            <div>
                <NsLabelLine linkTo='#'
                    smallSize={true}
                    primaryTitle={getTranslatedRawText('product.shoppingBag.WhyWeAreDifferent')}
                    secondaryTitle={getTranslatedRawText('product.shoppingBag.UniqueValueProposition')}
                    viewMoreLink={isMainAdvantage ? getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagExploreProduct) : null} />
                {
                    currentTextItems.map((textItem, index) => {
                        return (<div key={index} className='pt-5 text-justify' id={CommonHelper.makeCorrectLink(textItem.title)}>
                            <h6 className="fw-bold mb-2 font-size-14">{textItem.title}</h6>
                            <div className="font-size-14"><NsHtmlToReact key={index} >{textItem.body}</NsHtmlToReact></div>
                        </div>)
                    })
                }
            </div>
        );
    }

    handleClickScroll = (id) => {
        const activeParagraph = this.textItems?.find(textItem => textItem?.title?.substring(0, 1) == id);
        const element = document.getElementById(CommonHelper.makeCorrectLink(activeParagraph?.title));
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    onClick = () => getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagAdvantage);

    render() {
        const { isMainAdvantage } = this.props;
        return (<>
            {isMainAdvantage
                ? <Row className="justify-content-between align-items-center my-5">
                    <Col md={5} >
                        <span className="d-flex font-size-24 fw-bold mb-3">
                            WHY WE ARE DIFFERENT
                        </span>
                        <div className="text-justify font-size-14">
                            Discover the perfect paper shopping bags at Diamond Printing Press — versatile, affordable, and luxurious. Whether you prefer printed or plain, low quantity or high volume, our bags cater to all businesses, blending elegance with functionality
                        </div>
                    </Col>
                    <Col md={7} >
                        <Row className="g-2">
                            {this.advantages.map((advantage, index) => <Col key={index} md={6}>
                                <div className="d-flex border rounded h-100 cursor-pointer"
                                    onClick={() => advantage.onClick(advantage?.id)} >
                                    <img src={advantage.icon} />
                                    <div className="d-flex flex-column p-2">
                                        <span className="fw-bold">{advantage.title}</span>
                                        <div className="font-size-12 text-justify text-wrap">{advantage.description}</div>
                                        <div className='d-flex mt-3 text-decoration-none align-self-end'>
                                            <div className='font-size-12 fw-bold red-color me-2'>{getTranslatedRawText('product.shoppingBag.seeMore')}</div>
                                            <i className={clsx(CommonHelper.getNextThinIconByCulture(), 'red-color fs-6')}></i>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            )}
                        </Row>
                    </Col >

                </Row >
                : <ShoppingBagWrapper>
                    {this.renderChild(isMainAdvantage)}
                </ShoppingBagWrapper>
            }
            <NsSetPageTitleAndDescription pageTitle={CommonHelper.stringFormat('{0} | {1}', getTranslatedRawText('common.breadcrumbs.shoppingBagExploreProduct'), this.state.selectedTitle)} />
        </>
        );
    }

}

export default withRouter(ShoppingBagAdvantages);