import React from 'react';
import NsTypography from '../../../../../components/text/NsTypography';

import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { messageClass } from '../../../../../utilities/MessageModalHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import BillBookModal from '../../../modals/billBook/BillBookModal';
import BillBookPreviewModal from '../../../modals/billBookPreview/BillBookPreviewModal';
import { ExtendedServiceBillBookContent } from '../../../regularOrders/mainContents/extendedservices/ExtendedServiceBillBook';

class PoExtendedServiceBillBook extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasBillBookService: false,
            showBillBookModal: false,
            masterFile: null,
            selectedItem: {},
            billBookProperty: null,
            refreshExtendedServicesDate: props.refreshExtendedServicesDate,
            resultMessageClass: (<></>),
            showPreviewBillBookModal: false
        }
    }

    componentDidMount = () => this.prepareService();

    componentDidUpdate = (prevProps) => {
        if (prevProps.refreshExtendedServicesDate !== this.state.refreshExtendedServicesDate)
            this.prepareService();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshExtendedServicesDate !== prevState.refreshExtendedServicesDate)
            return ({ refreshExtendedServicesDate: nextProps.refreshExtendedServicesDate });
        return null;
    }

    prepareService = () => {
        let service = OrderHelper.getSpecificService(this.props.routeId, Enumerations.extendedServices.billBook);
        this.setState({
            hasBillBookService: !isEmpty(service),
            selectedItem: service?.selectedItem ?? {},
            billBookProperty: service?.billBookProperty
        });
    }

    billBookModalClicked = () => {
        if (OrderHelper.checkFrontFileExist(this.props.routeId)) {
            OrderHelper.getMasterFilesWithFullInfo(this.props.routeId, (masterFiles) => {
                this.setState({
                    masterFile: masterFiles[0].frontSideFileInfo,
                    showBillBookModal: true
                });
            });
        } else
            this.setState({ resultMessageClass: messageClass(getTranslatedRawText('common.messages.selectFileError')) });
    }

    saveBillBookModalClicked = (selectedItem) => {
        this.setState({ selectedItem, showBillBookModal: false }, this.updateExtendedService);
    }

    updateExtendedService = () => {
        const { selectedItem } = this.state;
        OrderHelper.updateExtendedService(this.props.routeId, Enumerations.extendedServices.billBook,
            { ...selectedItem });
        CommonHelper.safeFunctionCall(this.props.refreshSummary);
    }

    closeBillBookModalClicked = () => {
        this.setState({ showBillBookModal: false });
    }

    previewBillBookModalClicked = () => this.setState({ showPreviewBillBookModal: true });

    closePreviewBillBookModalClicked = () => this.setState({ showPreviewBillBookModal: false });

    numberingTypeChanged = (value, isValid) => {
        this.setState(oldState => ({ selectedItem: { ...oldState.selectedItem, billBookNumberInSeriesType: value } }), this.updateExtendedService);
    }


    render() {
        const { hasBillBookService, showBillBookModal, masterFile, selectedItem, billBookProperty, showPreviewBillBookModal } = this.state;
        const { billBookCopy, billBookPrintColor, billBookBindingType, billBookBindingSideType, billBookNumberInSeriesType, serialStartFrom, hasUnderhand } = selectedItem;
        const serialEndTo = OrderHelper.calculateSerialEndTo(serialStartFrom,billBookNumberInSeriesType, this.props.routeId);
        const series = OrderHelper.getSeries(this.props.routeId);

        return (hasBillBookService
            ? <>
                <NsTypography variant='inherit' className='font-size-14'>
                    {getTranslatedRawText('regularOrder.extendedService.billBook')}:
                </NsTypography>
                <ExtendedServiceBillBookContent billBookCopy={billBookCopy}
                    billBookPrintColor={billBookPrintColor}
                    billBookBindingType={billBookBindingType}
                    serialStartFrom={serialStartFrom}
                    serialEndTo={serialEndTo}
                    series={series}
                    numberingTypeChanged={this.numberingTypeChanged}
                    billBookNumberInSeriesType={billBookNumberInSeriesType}
                    billBookModalClicked={this.billBookModalClicked}
                    previewBillBookModalClicked={this.previewBillBookModalClicked} />

                {showBillBookModal &&
                    <BillBookModal routeId={this.props.routeId}
                        masterFile={masterFile}
                        selectedItem={selectedItem}
                        billBookProperty={billBookProperty}
                        saveModalClicked={this.saveBillBookModalClicked}
                        closeModal={this.closeBillBookModalClicked} />
                }

                {showPreviewBillBookModal &&
                    <BillBookPreviewModal masterFile={masterFile}
                        billBookCopy={billBookCopy}
                        billBookPrintColor={billBookPrintColor?.billBookPrintColorType}
                        billBookBindingSideType={billBookBindingSideType}
                        billBookBindingType={billBookBindingType}
                        hasUnderhand={hasUnderhand}
                        serialStartFrom={serialStartFrom}
                        trimZone={OrderHelper.getTrimZone(this.props.routeId)}
                        serialBoxDimension={billBookProperty?.serialBoxDimension}
                        selectedSerials={selectedItem?.selectedSerials}
                        selectedPerforations={selectedItem?.selectedPerforations}
                        closePreviewBillBookModalClicked={this.closePreviewBillBookModalClicked} />
                }

                {this.state.resultMessageClass}
            </>
            : null
        );
    }
}

export default PoExtendedServiceBillBook;