import React, { useState } from 'react';

import NsButton from '../../../../components/buttons/NsButton';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsModal from '../../../../components/modals/NsModal';
import Enumerations from '../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { GetFestivalPrizesWinnerService } from '../../../../services/adminPanel/AdminFestivalsService';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../../../utilities/CultureHelper';
import ApBaseUserInfoModal from '../users/bases/ApBaseUserInfoModal';
;

const ApFestivalPrizesModal = (props) => {

    const [selectedWinnerId, setSelectedWinnerId] = useState(null);
    const [showWinnerDetailModal, setShowWinnerDetailModal] = useState(false);
    const columns = [
        hasAccess([UserClaimEnumerations.getApCustomerById]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (winner) => {
                    return <NsButton className='secondary-text'
                        startIcon={'ns-icon-invoice-details'}
                        onClick={() => winnerDetailClicked(winner.detailLedgerId)} />
                }
            }
        },
        {
            field: 'name',
            headerName: getTranslatedRawText('adminPanel.festivals.winnerName'),
            width: 530
        },
        {
            field: 'prizeName',
            headerName: getTranslatedRawText('adminPanel.festivals.prizeName'),
            width: 200
        },
        {
            field: 'winCode',
            headerName: getTranslatedRawText('adminPanel.festivals.winsCode'),
            width: 150,
            dataType: Enumerations.gridType.number,
        },
        {
            field: 'dateOfWinning',
            headerName: getTranslatedRawText('adminPanel.festivals.dateOfWinning'),
            width: 200,
            dataType: Enumerations.gridType.dateTime
        }
    ];

    const winnerDetailClicked = (id) => {
        setShowWinnerDetailModal(true);
        setSelectedWinnerId(id);
    }

    const closeWinnerModal = () => setShowWinnerDetailModal(false);

    return (
        <NsModal maxWidth='lg'
            showModal={true}
            closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
            dialogTitle={getTranslatedRawText("adminPanel.festivals.prizeWinner")} >

            <NsDataGridWithServiceCall service={GetFestivalPrizesWinnerService}
                parameters={{
                    id: props.festivalId,
                    actionId: props.selectedPrizeId
                }}
                columnDefs={columns}
                hasPagination={true} />

            {showWinnerDetailModal &&
                <ApBaseUserInfoModal showModal={true}
                    selectedUserId={selectedWinnerId}
                    closeModal={closeWinnerModal} />
            }

        </NsModal >
    )
}

export default React.memo(ApFestivalPrizesModal);