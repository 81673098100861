import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NsMessageClassModal from '../../../components/modals/NsMessageClassModal';
import NsModal from '../../../components/modals/NsModal';
import Enumerations from '../../../configs/Enumerations';
import { fileManagerActionCreators } from '../../../redux/reducers/FileManagerReducer';
import SingletonCallService from '../../../services/bases/SingletonCallService';
import { GetFileAndFolderService } from '../../../services/fileManager/ContentManagementService';
import { DeleteFileService } from '../../../services/fileManager/FileManagementService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import FmDetail from './details/FmDetail';
import FmItem from './items/FmItem';


class FmMainSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowFmDetailMobileModal: false,
            currentFolderContent: [],
            selectedItemContent: null,
            selectedItemId: null,
            refreshDate: null,
            isLoading: false,
            selectedFiles: [],
            isShowDeleteModal: false
        }
        this.singletonFileAndFolderService = new SingletonCallService(GetFileAndFolderService, this.getFileAndFolderCallback);
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {

        if (this.state.refreshDate !== newProps.mainSectionFileFolderSectionItemsRefreshDate) {
            this.setState({ refreshDate: newProps.mainSectionFileFolderSectionItemsRefreshDate, isLoading: true, isShowFmDetailMobileModal: false },
                () => this.refresh());
        }

        if (this.props.currentFolderId !== newProps.currentFolderId) {
            this.setState({ selectedItemId: null, selectedItemContent: null, isLoading: true, },
                () => this.refresh());
        }
    }

    componentWillUnmount = () => {
        this.props.setCurrentFolderId(null);
        this.props.setCurrentFileContent(null);
    }

    showDetailPreviewModal = () => this.setState({ isShowFmDetailMobileModal: true });

    closeClicked = () => CommonHelper.safeFunctionCall(this.props.closeClicked);

    closeDetailPreviewModal = () => this.setState({
        isShowFmDetailMobileModal: false,
        selectedItemId: '',
        selectedItemContent: '',
    });

    fileClicked = (item) => {
        if (this.state.selectedItemContent !== item)
            this.setState({
                selectedItemId: item.id,
                selectedItemContent: item,
            }, () => {
                CommonHelper.isMobileDevice() && this.setState({ isShowFmDetailMobileModal: true });
                this.props.setCurrentFileContent(item);
            });
    }

    multiFileSelectClicked = (file) => {
        if (!this.state.selectedFiles.some(selectedFile => selectedFile.id === file.id))
            this.setState({ selectedFiles: [...this.state.selectedFiles, file] }, () => {
                this.state.selectedFiles.length > 1
                    ? this.setState({
                        selectedItemContent: null,
                        selectedItemId: null,
                    })
                    : !this.state.selectedFiles.some(selectedFile => selectedFile.id === this.state.selectedItemId) && this.setState({
                        selectedItemContent: null,
                        selectedItemId: null,
                    })
            });
        else
            this.setState(prevState => ({ selectedFiles: prevState.selectedFiles.filter(fileInfo => fileInfo.id !== file.id) }));
    }

    folderClicked = (item) => {
        if (this.state.selectedItemContent !== item)
            this.setState({
                selectedItemId: item.id,
                selectedItemContent: item,
            }, () => {
                CommonHelper.isMobileDevice() && this.setState({ isShowFmDetailMobileModal: true });
                this.props.setCurrentFileContent(null);
            });
    }

    folderDoubleClicked = (item) => {
        this.setState({
            selectedItemContent: item,
        }, () => {
            this.props.setCurrentFolderId(item.id);
            this.props.setCurrentFileContent(null);
        });
        this.refresh(item.id);
    }

    refresh = (id) => {
        let parentId = id || this.props.currentFolderId;
        this.setState({ selectedItemContent: null, selectedItemId: null, currentFolderContent: [] });
        let parameters = !!parentId ? { parentId } : {};
        this.singletonFileAndFolderService.run(parameters);
    }

    refreshFileContentAfterEdit = () => {
        let id = this.state.selectedItemId;
        let content = this.state.selectedItemContent;
        this.setState({
            selectedItemId: '',
            selectedItemContent: '',
        }, () => {
            this.setState({
                selectedItemId: id,
                selectedItemContent: content,
            })
        });
    }

    selectAllClicked = () => {
        if (!isEmpty(this.state.selectedFiles))
            this.setState({
                selectedFiles: []
            })
        else this.setState({
            selectedFiles: this.state.currentFolderContent.filter(content => !content.isFolder)
        })
    }

    getFileAndFolderCallback = (resultData, result, messageClassModal) => {
        (resultData !== undefined) && this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            this.setState({
                currentFolderContent: resultData,
                selectedFiles: []
            });
        } else {
            this.setState({
                resultMessageClass: messageClassModal,
            });
        }
    }

    multipleDeleteButtonClicked = () => {
        this.setState({
            isShowDeleteModal: true
        })
    }

    deleteSelectetContent = (confirm) => {
        confirm
            ? DeleteFileService(this.state.selectedFiles, this.deleteMultipleFileCallback)
            : this.setState({ isShowDeleteModal: false })
    }

    deleteMultipleFileCallback = (resultData, result, messageClassModal) => {
        this.setState({ isShowDeleteModal: false })
        if (!result.data.hasError) {
            this.props.refreshMainSectionItems();
        } else this.setState({ resultMessageClass: messageClassModal })
    }

    deselectFileHandler = () => {
        this.setState({
            selectedItemId: null,
            selectedItemContent: null,
        }, () => {
            CommonHelper.isMobileDevice() && this.setState({ isShowFmDetailMobileModal: false });
            this.props.setCurrentFileContent(null);
        });
    }

    render() {
        const { currentFolderContent, selectedItemContent, selectedItemId, isLoading } = this.state;
        const { treeViewFolders, isUsedInPo, toggleProfessionalOrderTour, hideCheckbox } = this.props;

        return (
            <Row className='px-0 mx-0'>

                <Col md={12} className="p-0">
                    <div className={clsx(!isUsedInPo && "file-manager__border-style", "w-100 h-100")} >
                        <FmItem fileClicked={this.fileClicked}
                            selectedFiles={this.state.selectedFiles}
                            multiFileSelectClicked={this.multiFileSelectClicked}
                            folderClicked={this.folderClicked}
                            multipleDeleteButtonClicked={this.multipleDeleteButtonClicked}
                            treeViewFolders={treeViewFolders}
                            isLoading={isLoading}
                            folderDoubleClicked={this.folderDoubleClicked}
                            currentFolderContent={currentFolderContent}
                            selectedItemId={selectedItemId}
                            hideCheckbox={hideCheckbox}
                            selectAllClicked={this.selectAllClicked}
                            selectedItemContent={selectedItemContent}
                            refreshFileContentAfterEdit={this.refreshFileContentAfterEdit}
                            toggleProfessionalOrderTour={toggleProfessionalOrderTour}
                            isUsedInPo={isUsedInPo}
                            deselectFileHandler={this.deselectFileHandler} />
                    </div>
                </Col>
                {!!this.state.isShowFmDetailMobileModal && !isUsedInPo &&
                    <FmDetailMobileModal showModal={this.state.isShowFmDetailMobileModal}
                        closeClicked={this.closeDetailPreviewModal}
                        selectedItemContent={selectedItemContent}
                        refreshFileContentAfterEdit={this.refreshFileContentAfterEdit}
                        isUsedInPo={isUsedInPo} />
                }
                {!!this.state.isShowDeleteModal &&
                    <NsMessageClassModal show={this.state.isShowDeleteModal}
                        onClose={this.deleteSelectetContent}
                        messageItems={[{
                            messageText: (<span>{CommonHelper.stringFormatWithBold(getTranslatedRawText('common.messages.areYouSureMultiFileQuestionFileManager'), this.state.selectedFiles.length)}</span>),
                            messageType: Enumerations.messageType.warning
                        }]} />
                }
            </Row>
        );
    }
}

function FmDetailMobileModal(props) {
    return (
        <NsModal maxWidth="xs"
            dialogTitle={getTranslatedRawText("fileManager.mainSectionDetail.preview")}
            showModal={props.showModal}
            closeModal={props.closeClicked}>

            <FmDetail selectedItemContent={props.selectedItemContent}
                showPreviewTitle={false}
                refreshFileContentAfterEdit={props.refreshFileContentAfterEdit}
                isUsedInPo={props.isUsedInPo} />

        </NsModal>
    )
}

const mapStateToProps = (state) => {
    return {
        currentFolderId: state.fileManager.currentFolderId,
        mainSectionFileFolderSectionItemsRefreshDate: state.fileManager.mainSectionFileFolderSectionItemsRefreshDate,
    }
}

const mapDispatchToPops = (dispatch) => {
    return bindActionCreators(fileManagerActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToPops)(FmMainSection);