import React, { useEffect, useState } from 'react';

import NsModal from '../../../../../components/modals/NsModal';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../../components/text/NsTypography';
import Enumerations from '../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import {
    GetApUserCreditService, GetBusinessPartnerDetailService, GetCustomerDetailService, GetSupporterDetailService,
    GrantCreditUserService
} from '../../../../../services/adminPanel/AdminUserService';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApBaseUserInfoModal from './ApBaseUserInfoModal';
import NsNumberInput from '../../../../../components/inputs/NsNumberInput';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import ApBaseUserRemainingLimitModal from './ApBaseUserRemainingLimitModal';
import NumberHelper from '../../../../../utilities/NumberHelper';
import NsTooltip from '../../../../../components/tooltips/NsTooltip';
import NsButton from '../../../../../components/buttons/NsButton';

const ApBaseUserGrantedCreditModal = (props) => {

    const { roleType } = props;
    const [userDetails, setUserDetails] = useState('');
    const [showUserDetailModal, setShowUserDetailModal] = useState(false);
    const [showRemainingLimitModal, setShowRemainingLimitModal] = useState(false);
    const [showValidationDate, setShowValidationDate] = useState(null);
    const [resultMessageClassModal, setResultMessageClass] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [isValidAmount, setIsValidAmount] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    useEffect(() => {
        getUserDetails();
        GetApUserCreditService(props.userId, getApUserCreditCallback)
    }, [])

    const toggleUserDetailModal = () => setShowUserDetailModal(!showUserDetailModal);

    const toggleRemainingLimitModal = () => setShowRemainingLimitModal(!showRemainingLimitModal);

    const getUserDetailCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError)
            setUserDetails(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const getUserDetails = () => {
        setIsLoading(true);
        switch (roleType) {
            case Enumerations.role.businessPartner: GetBusinessPartnerDetailService(props?.userId, getUserDetailCallback);
                break;
            case Enumerations.role.supporter: GetSupporterDetailService(props?.userId, getUserDetailCallback);
                break;
            default: GetCustomerDetailService(props?.userId, getUserDetailCallback);
                break;
        }
    }

    const getApUserCreditCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError)
            setAmount(resultData?.grantedCredit || 0);
        else
            setResultMessageClass(messageClassModal);
    }

    const grantCreditUserCallback = (resultData, result, resultMessageClassModal) => {
        setIsLoading(false);
        CommonHelper.safeFunctionCall(props.closeModal, true, resultMessageClassModal);
    }

    const saveClicked = () => {
        if (!isValidAmount || amount == '0') {
            setShowValidationDate(new Date());
            return false;
        } else
            GrantCreditUserService(props?.userId, { grantedCredit: amount }, grantCreditUserCallback, true);
    }

    const valueChanged = (value, isValid) => {
        setAmount(value);
        setIsValidAmount(isValid);
    }

    const toggleTooltipClicked = () => setIsTooltipOpen(!isTooltipOpen);

    const headerButtons = [
        { startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserDetailModal },
        hasAccess([UserClaimEnumerations.editApUserCredit]) && { startIcon: 'ns-icon-credit', label: 'adminPanel.usersInfo.remainingLimit', onClick: toggleRemainingLimitModal },

    ];

    const mainButtons = [
        { label: "common.confirm", icon: 'ns-icon-confirm', onClicked: saveClicked, className: 'primary' },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: props.closeModal, className: 'primary-outline' },
    ];

    const userFullName = { fullName: `${userDetails?.firstName} ${userDetails?.lastName}` };
    const numberHelper = new NumberHelper();
    return (
        <div>
            <NsModal maxWidth='sm'
                showModal={true}
                closeModal={props.closeModal}
                noSpace={true}
                dialogTitle={getTranslatedRawText('adminPanel.usersInfo.grantedCredit')}
                actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />} >


                <ApBaseMapActionButtons actionButtons={headerButtons}
                    isLoading={isLoading} />

                {isLoading ?
                    <div className='w-100'>
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                    </div>
                    : <>
                        <div className='background-color p-2'>
                            <ApBaseLabelInfo info={userFullName} hasAccessInfo={true} />
                        </div>
                        <div className='p-4'>
                            <div className='d-flex align-items-end'>
                                <NsNumberInput label={getTranslatedRawText("adminPanel.usersInfo.amountOfGantedCredit")}
                                    valueChanged={valueChanged}
                                    autoFocus={true}
                                    thousandSeparator={true}
                                    hasDecimal={true}
                                    value={amount}
                                    showValidationDate={showValidationDate}
                                    className="w-100 " />
                                <span className='ms-2'>
                                    <NsTooltip open={isTooltipOpen}
                                        title={getTranslatedRawText('adminPanel.usersInfo.grantedCreditDescription')}
                                        closeTooltip={() => setIsTooltipOpen(false)}>
                                        <NsButton className='secondary-text masterFileModal-tour-preview p-0'
                                            startIcon='ns-icon-question'
                                            onClick={toggleTooltipClicked} />
                                    </NsTooltip>
                                </span>
                            </div>
                            <NsTypography variant='inherit' className='light-text-color font-size-14 mt-1'>
                                <div>{numberHelper.convertNumberToText(amount)} <span className='fw-bold red-color'>{CommonHelper.getDefaultCurrency()}</span></div>
                            </NsTypography>
                        </div>

                    </>
                }
            </NsModal>
            {showUserDetailModal &&
                <ApBaseUserInfoModal showModal={true}
                    roleType={roleType}
                    selectedUserId={props.userId}
                    closeModal={toggleUserDetailModal} />
            }
            {showRemainingLimitModal &&
                <ApBaseUserRemainingLimitModal closeModal={toggleRemainingLimitModal}
                    roleType={roleType}
                    userId={userDetails?.userId} />
            }
            {resultMessageClassModal}
        </div>
    );

}

export default React.memo(ApBaseUserGrantedCreditModal);
