import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { GetRequest, PostRequest } from "../bases/BaseService";
import Links from "../../configs/links/AllLinks";

const GetStoreProductCartService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getStoreProductCart), parameters, callback);
}

const GetSelectedStoreOrdersForConfirmationService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getSelectedStoreOrdersForConfirmation), data, callback);
}

const DeleteFromCartStoreProductService = (data, callback,showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.deleteFromCartStoreProduct), data, callback, showSuccessResponseMessage);
}

const GetDetailStoreOrderService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getDetailStoreOrder), parameters, callback);
}
const GetDetailBasicInfoStoreOrderService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getDetailBasicInfoStoreOrder), parameters, callback);
}
const GetAdditionalInformationStoreOrderService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getAdditionalInformationStoreOrder), parameters, callback);
}
const GetInWarehouseStoreOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getInWarehouseStoreOrders), data, callback);
}
const GetNotAppliedStoreOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getNotAppliedStoreOrders), data, callback);
}
const GetPreparingStoreOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getPreparingStoreOrders), data, callback);
}
const GetRetryToReadyStateService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getRetryToReadyState), data, callback, true);
}
const GetInTransitStoreOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getInTransitStoreOrders), data, callback);
}
const GetDeliveryRequestsStoreOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getDeliveryRequestStoreOrders), data, callback);
}
const GetCanceledStoreOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getCanceledStoreOrders), data, callback);
}
const GetDeliveredStoreOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeOrders, Links.api.storeOrders.getDeliveredStoreOrders), data, callback);
}

export {
    GetStoreProductCartService,
    GetSelectedStoreOrdersForConfirmationService,
    DeleteFromCartStoreProductService,
    GetDetailStoreOrderService,
    GetDetailBasicInfoStoreOrderService,
    GetAdditionalInformationStoreOrderService,
    GetNotAppliedStoreOrdersService,
    GetPreparingStoreOrdersService,
    GetRetryToReadyStateService,
    GetInWarehouseStoreOrdersService,
    GetInTransitStoreOrdersService,
    GetDeliveryRequestsStoreOrdersService,
    GetCanceledStoreOrdersService,
    GetDeliveredStoreOrdersService
}