import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PatchRequest, PostRequest } from '../bases/BaseService';

const GetApProductContentsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApProductContents), parameters, callback);
}
const GetApProductContentDetailByIdService = (id, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApProductContentDetailById), { id }, callback);
}
const GetApProductContentByNameService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApProductContentByName), parameters, callback);
}
const GetApProductBasicInfoByIdService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApProductBasicInfoById), parameters, callback);
}
const AddApProductContentService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApProductContents), data, callback);
}
const EditApProductContentService = (id, data, callback) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApProductContents), id), data, callback);
}

export {
    GetApProductContentsService,
    GetApProductContentDetailByIdService,
    GetApProductContentByNameService,
    GetApProductBasicInfoByIdService,
    AddApProductContentService,
    EditApProductContentService
}