import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import NsButton from '../../../components/buttons/NsButton';
import NsMobileInput from '../../../components/inputs/NsMobileInput';
import NsSetPageTitleAndDescription from '../../../components/metaTags/NsSetPageTitleAndDescription';
import NsTypography from '../../../components/text/NsTypography';
import Config from '../../../configs/Config';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';
import { SendVerificationCodeForResetPasswordService } from '../../../services/accounts/AccountService';
import { getConfigObjectByCulture } from '../../../utilities/ConfigHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import { getEnumKeyFromValue } from '../../../utilities/EnumerationHelper';
import AccountWrapper from '../AccountWrapper';
import BaseCaptchaModal from '../bases/BaseCaptchaModal';

class ForgotPasswordMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            isLoading: false,
            isValidMobile: false,
            showValidationDate: null,
            showCaptchaModal: false,
            captchaMessage: '',
            resultMessageClass: (<></>)
        };
    }

    componentDidMount = () => !!CurrentUserHelper.getPersistentMobile() &&
        this.setState({ mobile: CurrentUserHelper.getPersistentMobile(), isValidMobile: true });

    keyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter && !this.state.showCaptchaModal) {
            event.preventDefault();
            this.sendVerificationCodeClicked();
        }
    }

    mobileChanged = (value, isValid) => {
        this.setState({
            mobile: value,
            isValidMobile: isValid
        });
        if (isValid) CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.persistentMobile), value);
    }

    sendVerificationCodeClicked = (captchaId, captchaCode) => {
        if (!this.state.isValidMobile) {
            this.setState({ showValidationDate: new Date() });
            return false;
        }
        CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.mobile), this.state.mobile);
        this.setState({ isLoading: true });
        let data = {
            countryCode: CurrentUserHelper.getCountryCode(),
            mobileWithOutCountryCode: CurrentUserHelper.getMobileWithOutCountryCode(),
        };
        if (!!captchaId && !!captchaCode)
            data = { ...data, captchaCode, captchaId };
        this.setState({ isLoading: true });
        SendVerificationCodeForResetPasswordService(data, this.sendVerificationCodeCallback);
    }

    sendVerificationCodeCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.props.history.push(getUrlWithCurrentCulture(Links.view.account.forgotPassword, Links.view.account.forgotPassword.changePassword), { totalRemainingTimeInSecond: resultData?.totalRemainingTimeInSecond || 0 });
        else
            this.setState({
                resultMessageClass: messageClassModal,
                isLoading: false,
                showCaptchaModal: result.data.messageItems.some(item => item?.messageType === Enumerations.messageType.needCaptcha),
                captchaMessage: result.data.messageItems.find(item => item?.messageType === Enumerations.messageType.needCaptcha)?.messageText
            });
    }

    closeCaptchaModal = (captchaId, captchaCode, confirm) => {
        this.setState({ showCaptchaModal: false });
        !!confirm && this.sendVerificationCodeClicked(captchaId, captchaCode);
    }

    render() {
        return (
            <AccountWrapper layoutType={Enumerations.loginLayoutType.signIn}>

                <Form onKeyDown={this.keyDown}>
                    <div className="white-panel g-0 d-flex flex-column my-sm-n5 w-100 py-5 px-4">
                        <h1 className='section-title'>
                            {getTranslatedRawText("account.forgotPassword.forgotPasswordMobileTitle")}
                        </h1>
                        <NsTypography variant='inherit' className='primary-paragraph'>
                            {getTranslatedRawText("account.forgotPassword.forgotPasswordMobileDescription")}
                        </NsTypography>

                        <div className="mt-5">
                            <input hidden name="username" value={this.state.mobile} readOnly />
                            <NsMobileInput mobileChanged={this.mobileChanged}
                                mobile={this.state.mobile}
                                showValidationDate={this.state.showValidationDate} />
                        </div>

                        <div className="d-flex mt-5">
                            <Link to={getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn)}
                                className="text-nowrap">
                                {getTranslatedRawText("account.common.login")}
                            </Link>
                            <NsButton onClick={this.sendVerificationCodeClicked}
                                wrapperClassName='ms-5'
                                className="primary"
                                loading={this.state.isLoading}
                                label="account.forgotPassword.sendVerificationCode" />
                        </div>
                        {this.state.resultMessageClass}
                    </div>
                    {this.state.showCaptchaModal &&
                        <BaseCaptchaModal closeModal={this.closeCaptchaModal}
                            captchaMessage={this.state.captchaMessage} />
                    }
                    <NsSetPageTitleAndDescription pageTitle='common.breadcrumbs.forgotPassword'
                        description={getConfigObjectByCulture(Config.metaTags)?.descriptions.forgetPassword} />
                </Form >
            </AccountWrapper>
        );
    }
}

export default ForgotPasswordMobile;