import React from "react";

import { GetBpOrderStateService } from "../../../services/businessPartners/BusinessPartnerService";
import EnumerationTranslationHelper from "../../../utilities/EnumerationTranslationHelper";
import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import NsNumberInput from "../../../components/inputs/NsNumberInput";
import NsCaptcha from "../../../components/recaptcha/NsCaptcha";
import NsLabelBox from "../../../components/labels/NsLabelBox";
import { isEmpty } from "../../../utilities/ValidationHelper";
import NsButton from "../../../components/buttons/NsButton";
import BpOrdersHistoryModal from "./BpOrdersHistoryModal";
import Enumerations from "../../../configs/Enumerations";
import NsCard from "../../../components/cards/NsCard";

class BpOrders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            invoiceNumber: null,
            isValidInvoiceNumber: false,
            showValidationDate: null,
            orderData: null,
            isLoading: false,
            showOrderHistoryModal: false,
            resultMessageClass: (<></>)
        }
    }

    valueChanged = (invoiceNumber, isValidInvoiceNumber) => this.setState({ invoiceNumber, isValidInvoiceNumber });

    searchClicked = () => {
        if (!!this.state.isValidInvoiceNumber) {
            this.setState({ isLoading: true });
            GetBpOrderStateService(this.state.invoiceNumber, this.getBpOrderStateCallback)
        } else
            this.setState({ showValidationDate: new Date(), orderData: null });
    }

    getBpOrderStateCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (result.data.hasError) this.setState({ resultMessageClass, orderData: null });
        else this.setState({ orderData: resultData });
    }

    // recaptchaChanged = (captchaCode, isValidCaptchaCode, captchaId) => this.setState({ captchaCode, isValidCaptchaCode, captchaId });

    historyModalToggle = () => this.setState({ showOrderHistoryModal: !this.state.showOrderHistoryModal });

    handleOnKeyDown = (event) => {
        console.log(event);
        if (event.keyCode === Enumerations.keyboard.enter)
            this.searchClicked();
    }

    render() {
        const { orderData, invoiceNumber, showValidationDate, isLoading, resultMessageClass, showOrderHistoryModal } = this.state;

        return (
            <NsCard className="d-flex flex-column align-items-center p-2">
                <div onKeyDown={this.handleOnKeyDown}>

                    <div className="page-title mb-2">{getTranslatedRawText('profile.orderArchive.orderStatus')}</div>
                    < NsNumberInput label={getTranslatedRawText('components.advanceSearch.invoiceNo')}
                        value={invoiceNumber}
                        showValidationDate={showValidationDate}
                        valueChanged={this.valueChanged} />
                    {/* <NsCaptcha recaptchaChanged={this.recaptchaChanged}
                    captchaMessage={captchaMessage}
                    showValidationDateCaptcha={showValidationDateCaptcha} /> */}
                    <div className="d-flex">
                        < NsButton label='common.search'
                            startIcon='ns-icon-search'
                            className='primary my-2'
                            loading={isLoading}
                            onClick={this.searchClicked} />
                    </div>
                    {!isEmpty(orderData) && <>
                        <div className="d-flex w-100 border-bottom my-3"></div>
                        <div className="d-flex w-100 justify-content-between  my-3">
                            <span>{orderData?.productName}</span>
                            <NsButton label='profile.actionButton.statusHistory'
                                startIcon='ns-icon-history'
                                className='secondary-outline'
                                onClick={this.historyModalToggle} />
                        </div>
                        <div className='d-flex flex-wrap gap-2'>
                            {!!orderData?.invoiceNumber &&
                                <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.invoiceNumber')}
                                    value={orderData.invoiceNumber} />
                            }
                            {!!orderData?.twoSidePrintingType && <NsLabelBox label={getTranslatedRawText('regularOrder.header.printedSide')}
                                value={EnumerationTranslationHelper.getPrintedSideText(orderData.twoSidePrintingType)} />
                            }
                            {!!orderData?.turnaroundType && <NsLabelBox label={getTranslatedRawText('regularOrder.header.turnaround')}
                                value={EnumerationTranslationHelper.getTurnaroundText(orderData.turnaroundType)} />
                            }
                            {!!orderData?.circulation && <NsLabelBox label={getTranslatedRawText('regularOrder.header.circulation')}
                                value={orderData.circulation} />
                            }
                            {!!orderData?.lastState &&
                                <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.status')}
                                    value={ConstantsWithTranslation.getSharedSheetOrderStateType(orderData.lastState)} />
                            }
                        </div>
                    </>
                    }
                    {showOrderHistoryModal &&
                        <BpOrdersHistoryModal showModal={true}
                            invoiceNumber={invoiceNumber}
                            closeModal={this.historyModalToggle}
                        />}
                    {resultMessageClass}
                </div>
            </NsCard >

        );
    }
}

export default BpOrders;