import clsx from 'clsx';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import NsHtmlToReact from '../../../../components/htmlToReact/NsHtmlToReact';
import Links from '../../../../configs/links/AllLinks';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

class SeoBox extends Component {
    constructor(props) {
        super(props);
        this.mapLinks = [];
        this.state = { isExpanded: false };
    }

    linkHandler = (item, text) => {
        this.mapLinks = [];
        if (isEmpty(item) || isEmpty(text)) return;

        item.links.forEach(link => {
            switch (link.linkType) {
                case 0:
                    this.mapLinks.push(`<a className='fw-normal text-primary' target='_blank' href=${getUrlWithCurrentCulture(Links, link.href)}><u>${link.name}</u></a>`)
                    break;
                case 1:
                    let categoryLink = this.props.categories.filter(category => category.name === link.name)

                    if (categoryLink.length > 0) this.mapLinks.push(`<a className='fw-normal text-primary' target='_blank' href=${CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main, Links.view.main.productsByCategory), categoryLink[0]?.id, categoryLink[0]?.name)}><u>${link.name}</u></a>`)
                    else this.mapLinks.push(`<a className='fw-normal text-primary' target='_blank' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { 'search-input': CommonHelper.makeCorrectLink(link.name) })}><u>${link.name}</u></a>`)
                    break;
                default:
                    this.mapLinks.push(`<a className='fw-normal text-primary' target='_blank' href=${getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { 'search-input': CommonHelper.makeCorrectLink(link.name) })}><u>${link.name}</u></a>`)
                    break;
            }
        });

        return (
            <NsHtmlToReact>
                {CommonHelper.stringFormat(text, ...this.mapLinks)}
            </NsHtmlToReact>
        );
    }

    expandHandler = () => { this.setState({ isExpanded: !this.state.isExpanded }) }

    contentHandler = (useWithNoneDisplay) => {
        const { titleClassName, textClassName } = this.props;
        const inlineContentClassName = isEmpty(this.props.inlineContentClassName) ? 'mb-2 text-justify' : this.props.inlineContentClassName;
        const blockContentClassName = isEmpty(this.props.blockContentClassName) ? 'pt-5 text-justify' : this.props.blockContentClassName;

        let seoItems = useWithNoneDisplay ? this.props.content : this.state.isExpanded || !this.props.isExpandable ? this.props.content : [this.props.content[0]];
        return seoItems.map((item, index) => {
            switch (item.type) {
                case 'inlineTitle':
                    return (
                        <div className={inlineContentClassName} key={index} id={index}>
                            <span className={clsx('fw-bold mb-2', titleClassName)}>{item.title} </span>
                            <span className={clsx('text-justify', textClassName)}>{item.texts[0]}</span>
                        </div>
                    );
                case 'blockTitle':
                    return (
                        <div className={blockContentClassName} key={index} id={index}>
                            <p className={clsx('fw-bold mb-2', titleClassName)}>{item.title}</p>
                            {item.texts.map(text => (
                                <span className={clsx('my-2', textClassName)} key={text}>
                                    {this.linkHandler(item, text)}
                                </span>
                            ))}
                        </div>
                    );
                default:
                    return null;
            }
        });
    }


    render() {
        const { isExpanded } = this.state;
        const { isExpandable, noBackground, className } = this.props;
        const buttonText = isEmpty(this.props.buttonText) ? getTranslatedRawText('common.readMore') : this.props.buttonText;
        return (
            <div className={className} >
                {this.contentHandler()}
                {isExpandable &&
                    <button className='d-flex mb-3 border-0 background-white-color' onClick={this.expandHandler}>
                        <div className='font-size-14 text-color fw-bold'>{buttonText}</div>
                        <i className={clsx('text-color font-size-24', !isExpanded ? 'ns-icon-expand-down' : 'ns-icon-expand-up')}></i>
                    </button>
                }

                <div className='d-none'>{this.contentHandler(true)}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        categories: state.categories.categories,
    };
}

export default connect(mapStateToProps)(SeoBox);