import React, { useEffect, useState } from 'react';

import { GetApDfbRatingParameterChartService } from '../../../../../services/adminPanel/AdminDashboardService';
import ApBasesDashboardCharts from '../../bases/ApBasesDashboardCharts';
import ApBasesDashboardHelper from '../../bases/ApBasesDashboardHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsCard from '../../../../../components/cards/NsCard';

const ApDfbRatingParameterChart = (props) => {

    const { parameters, refreshDate } = props;
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState();

    useEffect(() => {
        getRatingParameters();
    }, [refreshDate])

    const getRatingParameters = () => {
        setIsLoading(true);
        GetApDfbRatingParameterChartService(parameters, getRatingParametersCallback);
    }
    const getRatingParametersCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setRatingParameterChartOptions(resultData));
        } else
            setMessageClassModal(messageClassModal);
    }

    const options = {
        scales: {
            x: {
                display: true,
                grid: {
                    display: false
                }
            },
        },
    }

    return (!isEmpty(data?.labels) &&
        <NsCard className='p-3 h-100 align-content-between'>
            <ApBasesDashboardCharts title='adminPanel.feedback.ratingParameter'
                height=''
                type='bar'
                // hasSelect={true}
                // selectOptiond={[]}
                hasLabelBoxInChart={true}
                tooltipTitle='adminPanel.dashboard.totalUsersChartDes'
                data={data}
                refreshClicked={getRatingParameters}
                options={options}
                isLoading={isLoading} />

            {messageClassModal}
        </NsCard>
    );
};

export default React.memo(ApDfbRatingParameterChart);