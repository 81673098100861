import React, { useEffect, useRef, useState } from "react";

import { GetFeedbackAnnouncementBasicInfoService, GetFeedbackAnnouncementsService } from "../../../../../services/adminPanel/AdminFeedbackService";
import NsAdminPanelContentWrapper from "../../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../../components/grids/NsDataGridWithServiceCall";
import { createFeedbackAnnouncementsColumns } from "../../bases/ApBasesFeedbackHelper";
import ConstantsWithTranslation from "../../../../../configs/ConstantsWithTranslation";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import ApBaseFeedbackDetailModal from "../../bases/ApBaseFeedbackDetailModal";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import ApFeedbackAnnouncementsSearch from "./ApFeedbackAnnouncementsSearch";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import Enumerations from "../../../../../configs/Enumerations";
import NsTab from "../../../../../components/tabs/NsTab";
import NsModal from "../../../../../components/modals/NsModal";

const ApFeedbackAnnouncementsContent = (props) => {

    const tabs = ConstantsWithTranslation.getFeedbackstatus();
    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [searchData, setSearchData] = useState(props.searchData);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showFeedbackTypeDetailModal, setShowFeedbackTypeDetailModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState();
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData, selectedTabIndex]);

    const showFeedbackTypeDetailModalClicked = (selectedFeedback) => {
        setSelectedFeedback(selectedFeedback);
        setShowFeedbackTypeDetailModal(true);
    }

    const closeFeedbackTypeModalClicked = (messageClassModal) => {
        setShowFeedbackTypeDetailModal(false);
        if (!isEmpty(messageClassModal)) {
            setMessageClassModal(messageClassModal);
            setCallServiceDate(new Date());
        }
    }

    const tabChanged = (newValue, selectedTab) => {
        setSelectedTabIndex(newValue);
        setSelectedTab(selectedTab);
    }

    const getData = (data, isLoading) => setIsLoading(isLoading);

    const getSearchData = (data) => setSearchData(data);

    const getParameters = () => ({
        "Filter.UserId": searchData?.selectedUser?.userId || searchData?.user || props?.searchData?.user || null,
        "Filter.SubmitTimeFrom": searchData?.submitTimeFrom || '',
        "Filter.SubmitTimeTo": searchData?.submitTimeTo || '',
        "Filter.FromAverageRateValue": searchData?.averageRateFrom?.value || null,
        "Filter.ToAverageRateValue": searchData?.averageRateTo?.value || null,
        "Filter.IsPublic": searchData?.displayToAll?.key === 2 || null,
        "Filter.demandServiceTypeId": searchData?.demandService?.key || null,
        "Filter.UserFeedbackStateTypeId": selectedTab?.id,
    });

    return (
        <>
            <ApFeedbackAnnouncementsSearch setSearchData={getSearchData}
                isLoading={isLoading} />
            <NsTab tabs={tabs}
                value={selectedTabIndex}
                onChange={tabChanged} />
            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetFeedbackAnnouncementsService}
                columnDefs={createFeedbackAnnouncementsColumns(showFeedbackTypeDetailModalClicked)}
                parameters={getParameters()}
                hasPagination={true}
                getData={getData} />

            {showFeedbackTypeDetailModal &&
                <ApBaseFeedbackDetailModal selectedFeedback={{ ...selectedFeedback, feedbackType: Enumerations.feedbackTypes.announcementContent }}
                    getFeedbackDtoService={GetFeedbackAnnouncementBasicInfoService}
                    hasAccessInfo={hasAccess([UserClaimEnumerations.getApFeedbackAnnouncementBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory])}
                    closeModal={closeFeedbackTypeModalClicked} />}
            {messageClassModal}
        </>
    )
};

export const ApFeedbackAnnouncementsModal = (props) => {
    const { showModal, closeModal } = props;
    return (
        <NsModal maxWidth='lg'
            showModal={showModal}
            closeModal={closeModal}
            dialogTitle={getTranslatedRawText("adminPanel.feedback.feedbackAnnouncements")}>
            <ApFeedbackAnnouncementsContent {...props} />
        </NsModal>
    )
}

const ApFeedbackAnnouncements = (props) => {
    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.feedback.feedbackAnnouncements")}
            icon={'ns-icon-announcement-feedback h1'}>
            <ApFeedbackAnnouncementsContent {...props} />
        </NsAdminPanelContentWrapper>)
}

export default ApFeedbackAnnouncements;