import React from 'react';

import NsModal from '../../../../../components/modals/NsModal';
import CommonHelper from '../../../../../utilities/CommonHelper';
import FdResultBanner from '../festivalDetail/FdResultBanner';


class FestivalCongratulationModal extends React.Component {

    mapDiv = () => CommonHelper.createArray(301).map((item, index) => <div key={index} className={`confetti-${index}`} ></div >);

    render() {
        return (
            <div className="festival-congratulation">
                {this.mapDiv()}
                <NsModal showModal={this.props.showModal}
                    maxWidth='md'
                    noSpace={true}
                    dialogContentClassName={"background-red-color px-3"}>
                    <FdResultBanner isModal={true} closeModal={this.props.closeModal} />
                </NsModal>
            </div>
        )
    }
}

export default FestivalCongratulationModal;