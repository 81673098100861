const ProfessionalOrderTranslationEn = {
    professionalOrder: {
        orderResult: 'Order Result',
        noProductsFound: 'No Products found in this category',

        collapse: {
            products: 'Products',
            productDetail: 'Product Detail',
        },

        sidebar: {
            productCategories: 'Product Categories',
            all: "All"
        },

        choosingFile: {
            clearOrderQuestion: 'You are resetting all previous files. All files will be removed. ',
            dragHere: 'Drag And Drop Here',
        },

        orderDetails: {
            toolTip: "Maximum series for this product is {0}."
        },

        extendedService: {
            filter: 'Filter',
            foldLine: 'Fold Line',
            punch: 'Punch',
            box: 'Box'
        },

        summaries: {
            foldLineCount: 'Fold line',
            foldLinePriceDetail: '{0}×{1}='
        },

        modal: {
            fit: 'Resize To Front File',
        },

        messages: {
            areYouSureQuestion: 'Are you sure to remove ?',
            masterFileIsNotEmpty: "You have an order in progress that you have not added to the list. After submitting, the order will be deleted. are you sure?"
        },
    }
}
export default ProfessionalOrderTranslationEn
