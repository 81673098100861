import React from 'react';
import { GetBlogsService } from "../../../../../services/adminPanel/AdminBlogService";
import ApBasesPostContent from "../../bases/ApBasesPostContent";

const ApPost = () => {

    return (
        <ApBasesPostContent isBlog={true}
            service={GetBlogsService} />
    )
};

export default React.memo(ApPost);