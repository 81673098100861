import React from "react";

import NsModal from "../../../../../../components/modals/NsModal";
import NsStickyButton from "../../../../../../components/buttons/NsStickyButton";
import CommonHelper from "../../../../../../utilities/CommonHelper";
import { isEmpty } from "../../../../../../utilities/ValidationHelper";
import { GetCmsSinglePageService } from "../../../../../../services/singlePages/CmsService";
import Enumerations from "../../../../../../configs/Enumerations";
import { TermsOfUseContent } from "../../../../../singlePages/mainSections/termsOfUse/TermsOfUse";
import { PrivacyPolicyContent } from "../../../../../singlePages/mainSections/privacyPolicy/PrivacyPolicy";
import { getTranslatedRawText } from "../../../../../../utilities/CultureHelper";

class AgreementPolicyModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            termOfUse: {},
            privacyPolicy: {},
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        GetCmsSinglePageService(Enumerations.staticPageType.termOfUse, this.getCmsTermOfUseCallback);
        GetCmsSinglePageService(Enumerations.staticPageType.privacyPolicy, this.getCmsPrivacyPolicyCallback);
    }

    getCmsPrivacyPolicyCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ privacyPolicy: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getCmsTermOfUseCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ termsOfUse: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    checkboxClicked = () => this.setState({ isChecked: !this.state.isChecked });

    closeModal = () => CommonHelper.safeFunctionCall(this.props.closeModal);

    confirmAgreement = () => {
        CommonHelper.safeFunctionCall(this.props.closeModal);
    }

    render() {
        const { isLoading, termsOfUse, privacyPolicy, resultMessageClass } = this.state;
        const isTitleVisibleTerms = !isEmpty(termsOfUse) && termsOfUse.staticPages.some(item => item.isTitleVisible === true);
        const isTitleVisiblePolicy = !isEmpty(privacyPolicy) && privacyPolicy.staticPages.some(item => item.isTitleVisible === true);

        const stickyButtons = [CommonHelper.createStickyButtonItem('common.agree',
            '', this.confirmAgreement, 'primary ')];

        return <NsModal showModal={true}
            maxWidth='sm'
            actions={<NsStickyButton stickyButtons={stickyButtons}
                isLoading={isLoading} />}>

            <div>
                <div className="section-title">{getTranslatedRawText('singlePage.termsOfUse')}</div>
                <TermsOfUseContent termsOfUse={termsOfUse}
                    isTitleVisible={isTitleVisibleTerms} />

                <div className="section-title mt-5">{getTranslatedRawText('singlePage.privacyPolicy.privacyPolicy')}</div>
                <PrivacyPolicyContent privacyPolicy={privacyPolicy}
                    isTitleVisible={isTitleVisiblePolicy} />
            </div>
            {resultMessageClass}
        </NsModal >
    }
}

export default AgreementPolicyModal;