import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NsAdvanceSearch from '../../../../../../components/advanceSearch/NsAdvanceSearch';
import NsButton from '../../../../../../components/buttons/NsButton';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../../configs/UserClaimEnumerations';
import { categoryActionCreators } from '../../../../../../redux/reducers/CategoryReducer';
import { GetProductsCirculationsService } from "../../../../../../services/product/ProductService";
import { hasAccess } from '../../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import ApBaseSearchButtons from '../../../../bases/ApBaseSearchButtons';
import ApBaseUserSelectModal from '../../../../bases/ApBaseUserSelectModal';
import { GetApOrderQueueByEstimatedTimeExcelReportService } from '../../../../../../services/adminPanel/AdminOrderService';
import { getConfigObjectByCulture } from '../../../../../../utilities/ConfigHelper';
import Config from '../../../../../../configs/Config';

const ApOrderQueueByEstimatedTimeSearch = (props) => {

    const [showUserSelectModal, setShowUserSelectModal] = useState(false)
    const [user, setUser] = useState(props.customerDetails?.phoneNumber || '');
    const [invoiceNo, setInvoiceNo] = useState('');
    const [lastStatuses, setLastStatuses] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsId, setSelectedProductsId] = useState([]);
    const [submitTimeFrom, setSubmitTimeFrom] = useState(null);
    const [submitTimeTo, setSubmitTimeTo] = useState(null);
    const [estimateReadyToDeliverTimeFrom, setEstimateReadyToDeliverTimeFrom] = useState(null);
    const [estimateReadyToDeliverTimeTo, setEstimateReadyToDeliverTimeTo] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [delayedOrderChecked, setDelayedOrderChecked] = useState(false);
    const [selectedUser, setSelectedUser] = useState(props.customerDetails || '');
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [productCategory, setProductCategory] = useState([]);
    const [resultMessageClass, setResultMessageClass] = useState('');

    const dispatch = useDispatch();

    const handleCategories = () => dispatch(categoryActionCreators.getCategories());

    useEffect(() => {
        handleCategories();
        setIsLoading(true);
    }, []);

    const categories = useSelector(state => {
        return {
            categories: state.categories.categories
        }
    });

    useEffect(() => {
        !isEmpty(selectedUser) && searchClicked();
    }, [selectedUser])

    useEffect(() => {
        !isEmpty(props.selectedTab.id) && searchClicked();
    }, [props.selectedTab.id])

    useEffect(() => {
        !isEmpty(selectedProducts)
            ? setSelectedProductsId(selectedProducts.circulations.map(circulation => circulation.key))
            : setSelectedProductsId([]);
    }, [selectedProducts])

    const getProductsCirculationsCallback = (resultData, result) => {
        setIsLoading(false);
        if (!result?.data?.hasError) {
            setProducts(resultData);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        GetProductsCirculationsService(null, getProductsCirculationsCallback);
    }, [])

    const usersModalButtonClicked = () => {
        setShowUserSelectModal(true);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptionsInMobile = [
        !props.customerDetails?.phoneNumber && hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            onChange: (value) => setUser(value),
            endAdornment: !props.customerDetails?.phoneNumber ? <NsButton className='light-circle'
                loading={isLoading}
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" /> : null
        },
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTimeFrom'),
            fieldName: 'submitTimeFrom',
            availableFuture: true,
            value: estimateReadyToDeliverTimeFrom,
            onChange: (value) => setEstimateReadyToDeliverTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTimeTo'),
            fieldName: 'estimateReadyToDeliverTimeTo',
            value: estimateReadyToDeliverTimeTo,
            availableFuture: true,
            onChange: (value) => setEstimateReadyToDeliverTimeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('profile.allOrders.productCategory'),
            fieldName: 'productCategory',
            value: productCategory,
            items: categories.categories || [],
            onChange: (value) => setProductCategory(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.product'),
            fieldName: 'selectedProducts',
            value: selectedProducts,
            items: !isEmpty(products) ? products : [],
            onChange: (value) => {
                setSelectedProducts(value)
                if (showMore) {
                    let selectedProductsId = []
                    value?.circulations?.map(circulation => {
                        selectedProductsId.push(circulation?.key)
                    })
                    setSelectedProductsId(selectedProductsId)
                }
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.checkbox,
            label: getTranslatedRawText('adminPanel.dataGridColumn.onlyDelayedOrder'),
            fieldName: 'delayedOrderChecked',
            value: delayedOrderChecked,
            onChange: (value) => setDelayedOrderChecked(value)
        },
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.lastStatus'),
            fieldName: 'lastStatuses',
            value: lastStatuses,
            items: ConstantsWithTranslation.getAllStatusesOrder(),
            onChange: (value) => setLastStatuses(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.invoiceNo'),
            fieldName: 'invoiceNo',
            value: invoiceNo,
            onChange: (value) => setInvoiceNo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeFrom'),
            fieldName: 'submitTimeFrom',
            value: submitTimeFrom,
            onChange: (value) => setSubmitTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeTo'),
            fieldName: 'submitTimeTo',
            value: submitTimeTo,
            onChange: (value) => setSubmitTimeTo(value)
        },
    ];

    const showMoreClicked = () => {
        setShowMore(!showMore);
    }

    const searchClicked = () => {
        CommonHelper.safeFunctionCall(props.setSearchData, searchValues());
    }

    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user.phoneNumber);
        setShowUserSelectModal(false);
    }

    const clearButtonClicked = () => {
        setUser('');
        setSelectedUser('');
        setInvoiceNo('');
        setLastStatuses([]);
        setSelectedProducts([]);
        setSelectedProductsId([]);
        setDelayedOrderChecked(false);
        setProductCategory([]);
        setSubmitTimeFrom(null);
        setSubmitTimeTo(null);
        setEstimateReadyToDeliverTimeFrom(null);
        setEstimateReadyToDeliverTimeTo(null);
        CommonHelper.safeFunctionCall(props?.tabChanged, 0, ConstantsWithTranslation.sharedSheetOrderEstimatedQueueTurnaround()[0]);
        CommonHelper.safeFunctionCall(props.setSearchData, { turnaroundType: searchValues().turnaroundType });
    }

    const searchValues = () => {
        let sixHoursCategory = getConfigObjectByCulture(Config.sixHoursCategoryId);
        let productCategoryIds = props?.selectedTab.id == Enumerations.sharedSheetOrderEstimatedQueueTurnaround.sixHours
            ? !!productCategory?.id ? [sixHoursCategory, productCategory?.id] : [sixHoursCategory]
            : !!productCategory?.id ? [productCategory?.id] : null;

        return {
            user,
            userId: selectedUser?.userId,
            estimateReadyToDeliverTimeFrom,
            estimateReadyToDeliverTimeTo,
            productCategoryIds,
            productCategory,
            invoiceNo,
            nasInvoiceIds: !!invoiceNo ? [Number(invoiceNo)] : null,
            lastStatuses,
            sharedSheetOrderStateTypes: !!lastStatuses?.id ? [lastStatuses?.id] : null,
            selectedProducts,
            productId: selectedProductsId,
            delayToEstimateReadyToDeliverDay: delayedOrderChecked,
            submitTimeFrom,
            submitTimeTo,
            turnaroundType: props?.selectedTab.id === Enumerations.sharedSheetOrderEstimatedQueueTurnaround.normal
                ? Enumerations.turnaround.normal
                : props?.selectedTab.id === Enumerations.sharedSheetOrderEstimatedQueueTurnaround.express
                    ? Enumerations.turnaround.express
                    : null
        }
    };


    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={showMore
                    ? advanceSearchOptions
                    : CommonHelper.isMobileDevice()
                        ? basicSearchOptionsInMobile
                        : basicSearchOptions}
                    searchValues={searchValues()} mdCol={2} smCol={4} />
                <ApBaseSearchButtons searchClicked={searchClicked}
                    showMoreHandler={showMoreClicked}
                    showMore={showMore}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={props.isLoading}
                    excelSearchValues={searchValues()}
                    exportExcelService={hasAccess([UserClaimEnumerations.getApOrderQueueByRemainingToEstimatedTimeExcelReport]) ? GetApOrderQueueByEstimatedTimeExcelReportService : ''} />
            </div>
            {showUserSelectModal &&
                <ApBaseUserSelectModal showModal={showUserSelectModal}
                    userSelected={userSelected}
                    closeModal={() => setShowUserSelectModal(false)} />
            }

            {resultMessageClass}
        </>
    )

}
export default React.memo(ApOrderQueueByEstimatedTimeSearch);