import React from 'react';

import NsImageGallery from '../../../../components/carousel/NsImageGallery';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import '../../../../assets/sass/components/carousel/NsImageGallery.scss';
import NsModal from '../../../../components/modals/NsModal';

const GalleryDetailModal = (props) => {

    const { selectedImage, images, closeModal, isLocal } = props;
    
    return (
        <NsModal maxWidth="md"
            showModal={true}
            dialogTitle={getTranslatedRawText('regularOrder.common.preview')}
            noSpace={true}
            closeModal={closeModal} >
            <div className='w-100 h-100'>
                <NsImageGallery isLocal={isLocal} selectedImage={selectedImage} images={images} />
            </div>
        </NsModal>
    );
};

export default React.memo(GalleryDetailModal);