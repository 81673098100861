
import { Redirect } from 'react-router-dom';
import Enumerations from '../configs/Enumerations';
import ApLayout from '../views/layouts/adminPanel/ApLayout';

import ApDashboardFinancial from '../views/adminPanel/dashboards/financialDashboard/ApDashboardFinancial';
import ApDashboardBusiness from '../views/adminPanel/dashboards/businessDashboard/ApDashboardBusiness';
import ApDashboardSmsPanelsCredit from '../views/adminPanel/dashboards/smsPanelsCredit/ApDashboardSmsPanelsCredit';
import ApDashboardUsers from '../views/adminPanel/dashboards/users/ApDashboardUsers';
import ApDashboardAgencies from '../views/adminPanel/dashboards/agenciesDashboard/ApDashboardAgencies';
import ApAuditDashboard from '../views/adminPanel/dashboards/auditDashboard/ApAuditDashboard';
import ApCustomerUser from '../views/adminPanel/managements/users/customers/ApCustomerUser';
import ApSupporterUser from '../views/adminPanel/managements/users/supporters/ApSupporterUser';
import ApShareSheetOrder from '../views/adminPanel/managements/orders/shareSheets/ApShareSheetOrder';
import ApStoreOrder from '../views/adminPanel/managements/orders/stores/ApStoreOrder';
import ApOrderQueue from '../views/adminPanel/managements/orders/queues/ApOrderQueue';
import ApOrderQueueByEstimatedTime from '../views/adminPanel/managements/orders/queues/estimatedTime/ApOrderQueueByEstimatedTime';
import ApDailyQueueOfEstimateTime from '../views/adminPanel/managements/orders/queues/daily/ApDailyQueueOfEstimateTime';
import ApPayment from '../views/adminPanel/managements/payments/ApPayment';
import ApStatisticalReportOfOrders from '../views/adminPanel/managements/reports/ApStatisticalReportOfOrders';
import ApStatisticalReportOfPayments from '../views/adminPanel/managements/reports/ApStatisticalReportOfPayments';
import ApUsersFinancialBalanceSheet from '../views/adminPanel/managements/reports/ApUsersFinancialBalanceSheet';
import ApBlogCategory from '../views/adminPanel/contents/blogs/categories/ApBlogCategory';
import ApBlogPost from '../views/adminPanel/contents/blogs/posts/ApBlogPost';
import ApNewsCategory from '../views/adminPanel/contents/news/categories/ApNewsCategory';
import ApNewsPost from '../views/adminPanel/contents/news/posts/ApNewsPost';
import ApAnnouncement from '../views/adminPanel/contents/announcements/ApAnnouncement';
import ApAgreement from '../views/adminPanel/contents/singlePages/agreements/ApAgreement';
import ApSinglePages from '../views/adminPanel/contents/singlePages/ApSinglePages';
import ApSlider from '../views/adminPanel/contents/sliders/ApSlider';
import ApPromotion from '../views/adminPanel/contents/promotions/ApPromotion';
import contactInfo from '../views/adminPanel/contents/websiteInfo/contactInfo/ContactInfo';
import communicationChannel from '../views/adminPanel/contents/websiteInfo/communicationChannel/CommunicationChannel';
import metaTags from '../views/adminPanel/contents/websiteInfo/metaTags/MetaTags';
import signupAgeRules from '../views/adminPanel/settings/signupAgeRules/SignupAgeRules';
import telecommunication from '../views/adminPanel/settings/telecommunication/Telecommunication';
import notificationConfig from '../views/adminPanel/settings/notificationConfig/NotificationConfig';
import fileManagerConfig from '../views/adminPanel/settings/fileManagerConfig/FileManagerConfig';
import MapConfig from '../views/adminPanel/settings/mapConfig/MapConfig';
import supportLanguage from '../views/adminPanel/settings/supportLanguage/SupportLanguage';
import ApCustomersFiles from '../views/adminPanel/managements/customersFiles/ApCustomersFiles';
import ApShipping from '../views/adminPanel/managements/shippings/list/ApShipping';
import ApShippingInTheWarehouse from '../views/adminPanel/managements/shippings/inTheWarehouse/ApShippingInTheWarehouse';
import ApShippingQueueByLatestStatus from '../views/adminPanel/managements/shippings/queues/ApShippingQueueByLatestStatus';
import ApShippingQueueByShippingMethods from '../views/adminPanel/managements/shippings/queues/ApShippingQueueByShippingMethods';
import ApAgencies from '../views/adminPanel/managements/agency/ApAgencies';
import ApBusinessPartners from '../views/adminPanel/managements/users/businessPartners/ApBusinessPartners';
import ApFestival from '../views/adminPanel/managements/festivals/ApFestival';
import SmsConfig from '../views/adminPanel/settings/smsConfig/SmsConfig';
import ApFileManager from '../views/adminPanel/contents/fileManager/ApFileManager';
import ApDownload from '../views/adminPanel/contents/download/download/ApDownload';
import ApDownloadCategory from '../views/adminPanel/contents/download/category/ApDownloadCategory';
import ApStoreProduct from '../views/adminPanel/products/storeProducts/ApStoreProduct';
import ApPaymentProblematic from '../views/adminPanel/managements/payments/ApPaymentProblematic';
import ApPaymentAudit from '../views/adminPanel/managements/payments/audits/ApPaymentAudit';
import ApAdvertisement from '../views/adminPanel/contents/advertisement/ApAdvertisement';
import ApGalleryCategory from '../views/adminPanel/contents/gallery/categories/ApGalleryCategory';
import ApGalleryPost from '../views/adminPanel/contents/gallery/posts/ApGalleryPost';
import ApProductContents from '../views/adminPanel/products/productContents/ApProductContents';
import ApLogs from '../views/adminPanel/systemMonitoring/logs/ApLogs';
import ApShoppingBagPriceInquery from '../views/adminPanel/products/shoppingBags/ApShoppingBagPriceInquery';
import ApFeedbackTypes from '../views/adminPanel/feedbacks/feedbackInformation/ApFeedbackTypes';
import ApFeedbackOrders from '../views/adminPanel/feedbacks/feedbackCenter/orders/ApFeedbackOrders';
import ApFeedbackUsers from '../views/adminPanel/feedbacks/feedbackCenter/users/ApFeedbackUsers';
import ApFeedbackAnnouncements from '../views/adminPanel/feedbacks/feedbackCenter/announcements/ApFeedbackAnnouncements';
import ApDashboardFeedback from '../views/adminPanel/dashboards/feedbackDashboard/ApDashboardFeedback';
import ApDashboardCashback from '../views/adminPanel/dashboards/cashbackDashboard/ApDashboardCashback';
import ApDepartments from '../views/adminPanel/contents/departments/ApDepartments';
import ApShippingQueueByEstimatedTime from '../views/adminPanel/managements/shippings/queues/estimatedTime/ApShippingQueueByEstimatedTime';

const AdminPanelRoutes = [
    { Layout: ApLayout, path: "/:lng/admin/dashboard/agencies", Component: ApDashboardAgencies, breadCrumb: [Enumerations.adminBreadCrumbs.dashboardAgencies], title: 'adminPanel.dashboard.dashboardAgencies', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/dashboard/financial", Component: ApDashboardFinancial, breadCrumb: [Enumerations.adminBreadCrumbs.financialDashboard], title: 'adminPanel.dashboard.financialDashboard', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/dashboard/business", Component: ApDashboardBusiness, breadCrumb: [Enumerations.adminBreadCrumbs.businessDashboard], title: 'adminPanel.dashboard.businessDashboard', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/dashboard/sms-panels", Component: ApDashboardSmsPanelsCredit, breadCrumb: [Enumerations.adminBreadCrumbs.smsPanelsCreditDashboard], title: 'adminPanel.dashboard.smsPanelsCreditDashboard', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/dashboard/users", Component: ApDashboardUsers, breadCrumb: [Enumerations.adminBreadCrumbs.usersDashboard], title: 'adminPanel.dashboard.usersDashboard', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/dashboard/audits", Component: ApAuditDashboard, breadCrumb: [Enumerations.adminBreadCrumbs.auditDashboard], title: 'adminPanel.dashboard.auditDashboard', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/dashboard/feedbacks", Component: ApDashboardFeedback, breadCrumb: [Enumerations.adminBreadCrumbs.feedbackDashboard], title: 'adminPanel.dashboard.feedbackDashboard', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/dashboard/cashback", Component: ApDashboardCashback, breadCrumb: [Enumerations.adminBreadCrumbs.cashback], title: 'adminPanel.dashboard.cashbackDashboard', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/users/customers", Component: ApCustomerUser, breadCrumb: [Enumerations.adminBreadCrumbs.customer], title: 'adminPanel.sidebar.customers', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/users/supporters", Component: ApSupporterUser, breadCrumb: [Enumerations.adminBreadCrumbs.supporter], title: 'adminPanel.sidebar.supporters', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/orders/share-sheet", Component: ApShareSheetOrder, breadCrumb: [Enumerations.adminBreadCrumbs.shareSheetsOrders], title: 'adminPanel.sidebar.shareSheets', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/orders/store", Component: ApStoreOrder, breadCrumb: [Enumerations.adminBreadCrumbs.storeOrders], title: 'adminPanel.sidebar.stores', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/orders/queue", Component: ApOrderQueue, breadCrumb: [Enumerations.adminBreadCrumbs.queue], title: 'adminPanel.sidebar.queue', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/orders/queue/estimated-time", Component: ApOrderQueueByEstimatedTime, breadCrumb: [Enumerations.adminBreadCrumbs.queueByEstimatedTime], title: 'adminPanel.sidebar.queueByEstimatedTime', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/orders/queue/daily", Component: ApDailyQueueOfEstimateTime, breadCrumb: [Enumerations.adminBreadCrumbs.dailyQueue], title: 'adminPanel.sidebar.dailyQueue', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/payments/list", Component: ApPayment, breadCrumb: [Enumerations.adminBreadCrumbs.payment], title: 'adminPanel.sidebar.list', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/payments/problematic-payments", Component: ApPaymentProblematic, breadCrumb: [Enumerations.adminBreadCrumbs.problematicPayments], title: 'adminPanel.sidebar.problematicPayments', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/payments/audit", Component: ApPaymentAudit, breadCrumb: [Enumerations.adminBreadCrumbs.audit], title: 'adminPanel.sidebar.audit', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/report/statistical-of-orders", Component: ApStatisticalReportOfOrders, breadCrumb: [Enumerations.adminBreadCrumbs.statisticalReportOfOrders], title: 'adminPanel.title.statisticalReportOfOrders', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/report/statistical-of-payments", Component: ApStatisticalReportOfPayments, breadCrumb: [Enumerations.adminBreadCrumbs.statisticalReportOfPayments], title: 'adminPanel.title.statisticalReportOfPayments', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/report/debit-credit-report", Component: ApUsersFinancialBalanceSheet, breadCrumb: [Enumerations.adminBreadCrumbs.usersDebitCredit], title: 'adminPanel.title.debitCreditReport', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/customers-files", Component: ApCustomersFiles, breadCrumb: [Enumerations.adminBreadCrumbs.customersFiles], title: 'adminPanel.sidebar.customersFiles', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/shippings/list", Component: ApShipping, breadCrumb: [Enumerations.adminBreadCrumbs.shipping], title: 'adminPanel.shippings.shippingRequests', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/shippings/in-the-warehouse", Component: ApShippingInTheWarehouse, breadCrumb: [Enumerations.adminBreadCrumbs.inTheWarehouseShipping], title: 'adminPanel.shippings.inTheWarehouseShippingRequests', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/shippings/queue-by-estimated-time", Component: ApShippingQueueByEstimatedTime, breadCrumb: [Enumerations.adminBreadCrumbs.shippingQueueByEstimatedTime], title: 'adminPanel.shippings.queueByEstimatedTime', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/shippings/queue-by-latest-status", Component: ApShippingQueueByLatestStatus, breadCrumb: [Enumerations.adminBreadCrumbs.shippingQueueByLatestStatus], title: 'adminPanel.shippings.shippingRequestsQueueByLatestStatuses', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/shippings/queue-by-shipping-methods", Component: ApShippingQueueByShippingMethods, breadCrumb: [Enumerations.adminBreadCrumbs.shippingQueueByShippingMethods], title: 'adminPanel.shippings.shippingRequestsQueueByShippingMethods', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/agencies", Component: ApAgencies, breadCrumb: [Enumerations.adminBreadCrumbs.agencies], title: 'adminPanel.sidebar.agencies', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/businessPartners", Component: ApBusinessPartners, breadCrumb: [Enumerations.adminBreadCrumbs.businessPartners], title: 'adminPanel.sidebar.businessPartners', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/festivals", Component: ApFestival, breadCrumb: [Enumerations.adminBreadCrumbs.festivals], title: 'adminPanel.sidebar.festivals', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/system-monitoring/logs", Component: ApLogs, breadCrumb: [Enumerations.adminBreadCrumbs.festivals], title: 'adminPanel.sidebar.logs', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/content/files-manager", Component: ApFileManager, breadCrumb: [Enumerations.adminBreadCrumbs.fileManager], title: 'adminPanel.sidebar.fileManager', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/content/download/download-center", Component: ApDownload, breadCrumb: [Enumerations.adminBreadCrumbs.downloadCenter], title: 'adminPanel.sidebar.downloadCenter', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/download/categories", Component: ApDownloadCategory, breadCrumb: [Enumerations.adminBreadCrumbs.downloadCategory], title: 'adminPanel.sidebar.downloadCategory', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/content/blog/posts", Component: ApBlogPost, breadCrumb: [Enumerations.adminBreadCrumbs.blogPost], title: 'adminPanel.sidebar.blogsPosts', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/blog/categories", Component: ApBlogCategory, breadCrumb: [Enumerations.adminBreadCrumbs.blogCategory], title: 'adminPanel.sidebar.blogCategories', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/news/posts", Component: ApNewsPost, breadCrumb: [Enumerations.adminBreadCrumbs.newsPost], title: 'adminPanel.sidebar.newssPosts', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/news/categories", Component: ApNewsCategory, breadCrumb: [Enumerations.adminBreadCrumbs.newsCategory], title: 'adminPanel.sidebar.newsCategories', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/content/announcements", Component: ApAnnouncement, breadCrumb: [Enumerations.adminBreadCrumbs.announcement], title: 'adminPanel.sidebar.announcements', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/content/departments", Component: ApDepartments, breadCrumb: [Enumerations.adminBreadCrumbs.departments], title: 'adminPanel.sidebar.departments', exact: true },

    { Layout: ApLayout, path: "/:lng/admin/content/single-page/agreements", Component: ApAgreement, breadCrumb: [Enumerations.adminBreadCrumbs.agreements], title: 'adminPanel.sidebar.agreements', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/single-page/faqs", Component: ApSinglePages, breadCrumb: [Enumerations.adminBreadCrumbs.faq], title: 'adminPanel.sidebar.faqs', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/single-page/terms-of-use", Component: ApSinglePages, breadCrumb: [Enumerations.adminBreadCrumbs.termOfUse], title: 'adminPanel.sidebar.termsOfUse', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/single-page/privacy-policy", Component: ApSinglePages, breadCrumb: [Enumerations.adminBreadCrumbs.privacyPolicy], title: 'adminPanel.sidebar.privacyPolicy', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/single-page/warranty-terms", Component: ApSinglePages, breadCrumb: [Enumerations.adminBreadCrumbs.warrantyTerms], title: 'adminPanel.sidebar.warrantyTerms', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/content/advertisements", Component: ApAdvertisement, breadCrumb: [Enumerations.adminBreadCrumbs.advertisement], title: 'adminPanel.sidebar.advertisement', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/content/gallery/categories", Component: ApGalleryCategory, breadCrumb: [Enumerations.adminBreadCrumbs.galleryCategories], title: 'adminPanel.sidebar.galleryCategories', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/gallery/posts", Component: ApGalleryPost, breadCrumb: [Enumerations.adminBreadCrumbs.galleryPosts], title: 'adminPanel.sidebar.galleryPosts', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/content/sliders", Component: ApSlider, breadCrumb: [Enumerations.adminBreadCrumbs.slider], title: 'adminPanel.sidebar.slider', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/content/promotions", Component: ApPromotion, breadCrumb: [Enumerations.adminBreadCrumbs.promotion], title: 'adminPanel.sidebar.promotions', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/content/websiteInfo/contact-info", Component: contactInfo, breadCrumb: [], title: 'footer.contactInfo', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/websiteInfo/communication-channel", Component: communicationChannel, breadCrumb: [], title: 'adminPanel.sidebar.communicationChannel', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/content/websiteInfo/meta-tags", Component: metaTags, breadCrumb: [], title: '', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/settings/signup-age-rules", Component: signupAgeRules, breadCrumb: [], title: '', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/settings/sms-configs", Component: SmsConfig, breadCrumb: [Enumerations.adminBreadCrumbs.smsConfig], title: 'adminPanel.sidebar.smsConfig', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/settings/telecommunication", Component: telecommunication, breadCrumb: [], title: 'adminPanel.sidebar.telecommunication', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/settings/notification-config", Component: notificationConfig, breadCrumb: [], title: 'adminPanel.sidebar.notificationConfig', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/settings/support-languages", Component: supportLanguage, breadCrumb: [], title: 'adminPanel.sidebar.supportLanguage', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/settings/file-manager-config", Component: fileManagerConfig, breadCrumb: [], title: 'adminPanel.sidebar.fileManagerConfig', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/settings/map-config", Component: MapConfig, breadCrumb: [], title: 'adminPanel.sidebar.mapConfig', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/product/store", Component: ApStoreProduct, breadCrumb: [Enumerations.adminBreadCrumbs.productContent], title: 'adminPanel.sidebar.storeProducts', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/product/content", Component: ApProductContents, breadCrumb: [], title: 'adminPanel.sidebar.productContent', exact: false },
    { Layout: ApLayout, path: "/:lng/admin/product/paper-bag", Component: ApShoppingBagPriceInquery, breadCrumb: [], title: 'adminPanel.sidebar.paperBag', exact: false },

    { Layout: ApLayout, path: "/:lng/admin/feedback/types", Component: ApFeedbackTypes, breadCrumb: [Enumerations.adminBreadCrumbs.feedbackTypes], title: 'adminPanel.sidebar.feedbackTypes', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/feedback/announcements", Component: ApFeedbackAnnouncements, breadCrumb: [Enumerations.adminBreadCrumbs.feedbackAnnouncements], title: 'adminPanel.sidebar.announcements', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/feedback/orders", Component: ApFeedbackOrders, breadCrumb: [Enumerations.adminBreadCrumbs.feedbackOrders], title: 'adminPanel.feedback.feedbackOrders', exact: true },
    { Layout: ApLayout, path: "/:lng/admin/feedback/users", Component: ApFeedbackUsers, breadCrumb: [Enumerations.adminBreadCrumbs.feedbackUsers], title: 'adminPanel.feedback.feedbackUsers', exact: true },

    { Layout: Redirect, from: "/:lng/admin", to: "/:lng/admin/dashboard/financial", breadCrumb: [], title: 'adminPanel.dashboard.financialDashboard', exact: true },
];

export default AdminPanelRoutes;