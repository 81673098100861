const AdminPanelLinks = {

    adminPanel: {

        api: {
            getApDfSaleGadgets: 'api/{culture}/AdminPanel/FinancialDashboard/FinancialSaleGadgets',
            getApDfTotalSales: 'api/{culture}/AdminPanel/FinancialDashboard/FinancialTotalAndAverageOrder',
            getApDfOrderByApplicationType: 'api/{culture}/AdminPanel/FinancialDashboard/FinancialOrderByApplicationType',
            getApDfTotalOnlinePayments: 'api/{culture}/AdminPanel/FinancialDashboard/FinancialOnlinePaymentByStatus',
            getApDfOrderByTurnaround: 'api/{culture}/AdminPanel/FinancialDashboard/FinancialOrderByTurnaround',
            getApDfOnlinePaymentsGateway: 'api/{culture}/AdminPanel/FinancialDashboard/FinancialOnlinePaymentsGateway',

            getApDbTotalSales: 'api/{culture}/AdminPanel/BusinessDashboard/BusinessTotalAndAverageOrder',
            getApDbTotalOrders: 'api/{culture}/AdminPanel/BusinessDashboard/BusinessOrderByTurnaround',
            getApDbTotalOnlinePayments: 'api/{culture}/AdminPanel/BusinessDashboard/BusinessOrderByApplicationType',
            getApDbOnlinePaymentStatus: 'api/{culture}/AdminPanel/BusinessDashboard/BusinessOnlinePaymentByStatus',

            getApDuTotalUsers: 'api/{culture}/AdminPanel/UserDashboard/TotalUsers',
            getApDuLastJoinedUsers: 'api/{culture}/AdminPanel/UserDashboard/LastJoinedUsers',
            getApDuInactiveUsers: 'api/{culture}/AdminPanel/UserDashboard/InactiveUsers',
            getApDuRemainingLimitWithCredit: 'api/{culture}/AdminPanel/UserDashboard/UsersRemainingLimitWithCredit',
            getApDuRemainingLimitWithoutCredit: 'api/{culture}/AdminPanel/UserDashboard/UsersRemainingLimitWithoutCredit',
            getApDuGrantedCredits: 'api/{culture}/AdminPanel/UserDashboard/UserGrantedCredits',
            getApDuRemainingLimitWithCreditExcelReport: 'api/{culture}/AdminPanel/UserDashboard/UsersRemainingLimitWithCreditExcelReport',
            getApDuRemainingLimitWithoutCreditExcelReport: 'api/{culture}/AdminPanel/UserDashboard/UsersRemainingLimitWithoutCreditExcelReport',
            getApDuGrantedCreditsExcelReport: 'api/{culture}/AdminPanel/UserDashboard/UserGrantedCreditsExcelReport',

            getApDSmsPanelCredit: 'api/{culture}/AdminPanel/Dashboard/SmsPanelsCredit',

            getApDaLatestSubmittedAgencies: 'api/{culture}/AdminPanel/Agency/LatestSubmittedAgencies',
            getApDaTotalSalesAmountAgencies: 'api/{culture}/AdminPanel/Agency/TotalSalesAmount',
            getApDaPopularAgencies: 'api/{culture}/AdminPanel/Agency/PopularAgencies',
            getApDaActiveStates: 'api/{culture}/AdminPanel/Agency/ActiveStates',

            getApDauStatisticPayments: 'api/{culture}/AdminPanel/PaymentAuditDashboards/StatisticsBasedOnAuditedAndPaymentStatuses',
            getApDauStatisticsMismatchProgress: 'api/{culture}/AdminPanel/PaymentAuditDashboards/StatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes',
            getApDauNotProcessedAuditCount: 'api/{culture}/AdminPanel/PaymentAuditDashboards/NotProcessedPaymentAuditCount',
            getApDauAggregateStatistics: 'api/{culture}/AdminPanel/PaymentAuditDashboards/AggregateStatisticsBasedOnAuditedPaymentsAndNotFoundPayments',
            getApDauPaymentGatewaysMessages: 'api/{culture}/AdminPanel/PaymentAuditDashboards/PaymentGatewaysMessages',

            getApDaUCbForecasts: 'api/{culture}/AdminPanel/UserCashbackDashboard/GetUsersCashbackForecasts',
            getApDaUCbForecastsExcelReport: 'api/{culture}/AdminPanel/UserCashbackDashboard/GetUsersCashbackForecastsExcelReport',
            getApDaUCbForecastWithGrowthPercent: 'api/{culture}/AdminPanel/UserCashbackDashboard/UserCashbackForecastWithGrowthPercent',
            getApDaUCbForeCast: 'api/{culture}/AdminPanel/UserCashbackDashboard/UserCashbackForecast/{0}',
            getApDaUCCInfo: 'api/{culture}/AdminPanel/UserCashbackDashboard/UserCurrentCashbackInfo',
            getApDaCbPlans: 'api/{culture}/AdminPanel/UserCashbackDashboard/CashbackPlans/{0}',

            getApDaCbHistories: 'api/{culture}/AdminPanel/UserCashbackDashboard/CashbackHistories',
            getApDaCbFromInqueryTimes: 'api/{culture}/AdminPanel/UserCashbackDashboard/FromInquiryTimes',
            getApDaCbToInqueryTimes: 'api/{culture}/AdminPanel/UserCashbackDashboard/ToInquiryTimes',

            getApDfbTotalByStatusGedgets: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByStateTypes',
            getApDfbTotalTotalLineChart: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbacks',
            getApDfbStrengthWeeknessChart: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByFeedbackTypeMetricTypes',
            getApDfbByStatusAndFilterChart: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByStateTypesFilterByCommentsAndDemandServices',
            getApDfbComments: 'api/{culture}/AdminPanel/UserFeedbackDashboard/Comments',
            getApDfbByStateAndDateChart: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByStateTypesAndDate',
            getApDfbByCustomersGrid: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByCustomers',
            getApDfbByCitiesGrid: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByCities',
            getApDfbByProvinces: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByProvinces',
            getApDfbByProduct: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackSharedSheetOrders',
            getApDfbAnnouncementsGrid: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByAnnouncementContents',

            getApDfbByProductsGrid: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByProducts',
            getApDfbProgressMetrics: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByFeedbackTypeMetrics',
            getApDfbRatingParameterChart: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByFeedbackTypeMetricAndRates',
            getApDfbStatisticsGrid: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByProducts',

            getApDfbByEmployeesGrid: 'api/{culture}/AdminPanel/UserFeedbackDashboard/UserFeedbackAggregateByEmployees',

            getApCustomers: 'api/{culture}/AdminPanel/UserCustomer',
            getApCustomerById: 'api/{culture}/AdminPanel/UserCustomer/{0}',
            editApCustomer: 'api/{culture}/AdminPanel/UserCustomer/{0}',
            getApUserBasicInfoById: 'api/{culture}/AdminPanel/UserCustomer/UserAbstractById/{0}',
            postApUserDisableToken: 'api/{culture}/AdminPanel/UserCustomer/disableToken',
            getApUserDuplicatePhoneNumbers: 'api/{culture}/AdminPanel/UserCustomer/DuplicatePhoneNumbers',
            getApUserDuplicateFullName: 'api/{culture}/AdminPanel/UserCustomer/UsersWithDuplicateFullName',
            getApUserDuplicateCompanyName: 'api/{culture}/AdminPanel/UserCustomer/UsersWithDuplicateCompanyName',
            getApUserDuplicatePhoneNumbersExcelReport: 'api/{culture}/AdminPanel/UserCustomer/UsersWithDuplicatePhoneNumbersExcelReport',
            getApUserDuplicateFullNameExcelReport: 'api/{culture}/AdminPanel/UserCustomer/UsersWithDuplicateFullNamesExcelReport',
            getApUserDuplicateCompanyNameExcelReport: 'api/{culture}/AdminPanel/UserCustomer/UsersWithDuplicateCompanyNamesExcelReport',
            getApUserCredit: 'api/{culture}/AdminPanel/UserCustomer/{0}/Credit',
            getApUserProfileQrCode: 'api/{culture}/AdminPanel/UserCustomer/UserProfileQrCode',
            editUserPublicProfileStatus: 'api/{culture}/AdminPanel/UserCustomer/ToggleUserPublicProfileStatus',
            getApUserFieldVisibilityType: 'api/{culture}/AdminPanel/UserCustomer/{0}/FieldVisibilityType',

            getApUserActiveAddresses: 'api/{culture}/AdminPanel/UserAddressesAdmin/Actives',

            getApCmsLog: 'api/{culture}/AdminPanel/CmsLog',
            getApCmsLogById: 'api/{culture}/AdminPanel/CmsLog/{0}',
            getApCmsLogExceptionTypes: 'api/{culture}/AdminPanel/CmsLog/GetExceptionTypes',
            getApCmsLogSources: 'api/{culture}/AdminPanel/CmsLog/GetSources',
            getApCmsLogCustomLogException: 'api/{culture}/AdminPanel/CmsLog/GetCustomLogException',

            getApSupporters: 'api/{culture}/AdminPanel/UserSupporter',
            getApSupporterById: 'api/{culture}/AdminPanel/UserSupporter/{0}',
            editApSupporter: 'api/{culture}/AdminPanel/UserSupporter/{0}',
            getApSupporterBasicInfoById: 'api/{culture}/AdminPanel/UserSupporter/UserAbstractById/{0}',
            getApClaimValueTypes: 'api/{culture}/AdminPanel/UserAccess/ClaimValueTypes',
            editApClaimValueTypes: 'api/{culture}/AdminPanel/UserAccess/ClaimValueTypes/{0}',
            editApUserCredit: 'api/{culture}/AdminPanel/UserSupporter/{0}/GrantedCredit',

            getApBusinessPartners: 'api/{culture}/AdminPanel/UserBusinessPartner',
            getApBusinessPartnerById: 'api/{culture}/AdminPanel/UserBusinessPartner/{0}',
            editApBusinessPartner: 'api/{culture}/AdminPanel/UserBusinessPartner/{0}',
            getApUserBusinessPartnerBasicInfoById: 'api/{culture}/AdminPanel/UserBusinessPartner/UserAbstract/{0}',
            getApUserBusinessPartnerApiKeyWithAbstractDetail: 'api/{culture}/AdminPanel/UserBusinessPartner/ApiKeyWithAbstractDetail/{0}',
            getApUserBusinessPartnerResetSecureApiKey: 'api/{culture}/AdminPanel/UserBusinessPartner/ResetSecureApiKey',

            getApOrders: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins',
            getApOrderDto: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/{0}',
            getApOrderDetail: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/{0}/OrderDetail',
            updateApOrderStatus: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/ChangeFailedStatus',
            getApOrderWebsiteStatusHistory: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/{0}/StateHistories',
            getApOrderNasStatusHistory: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/NasInvoiceSheetStateHistories',
            getApOrderNasSheetDetail: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/NasInvoiceSheetDetail/{0}',
            deleteApOrder: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/{0}/delete',
            getApOrderUnOfficialReport: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/UnOfficialReport/{0}',
            getApOrderOfficialReport: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/OfficialReport/{0}',
            getApOrderOfficialInvoiceStatus: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/OfficialInvoiceStatus/{0}',
            getApStatusQueue: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/StatusQueue',
            getApStatusQueueByServerName: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/StatusQueueByServerNameFileRegulator',
            getApOrderEstimatedDeliveryTime: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/OrderPreparationEstimatedTimeSheets/{0}',
            getApOrderQueueByEstimatedTime: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/QueueByRemainingToEstimateReadyToDeliverGroupSheet',
            getApOrderQueueByEstimatedTimeExcelReport: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/QueueByRemainingToEstimateReadyToDeliverGroupSheetExcelReport',
            getApDailyQueueOfEstimateTime: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/DailyQueueOfEstimateReadyToDeliverByLastStatusOfGroupSheetOrders',
            getApOrdersExcelReport: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/GroupSheetExcelReport',

            getApOrderFilterPoints: 'api/{culture}/AdminPanel/SharedSheetOrderDetailOptionAdmin/{0}/FilterPoints',
            getApOrderBillBookOptions: 'api/{culture}/AdminPanel/SharedSheetOrderDetailOptionAdmin/{0}/BillBookOptions',
            getApOrderPunchPoints: 'api/{culture}/AdminPanel/SharedSheetOrderDetailOptionAdmin/{0}/PunchPoints',
            getApOrderFoldLines: 'api/{culture}/AdminPanel/SharedSheetOrderDetailOptionAdmin/{0}/FoldLines',
            getApOrderAdditionalInformation: 'api/{culture}/AdminPanel/SharedSheetOrderDetailOptionAdmin/{0}/GetAdditionalInformation',
            getApOrderProductDetail: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/{0}/ProductDetail',

            getApStoreOrders: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin',
            getApStoreOrderDto: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin/{0}',
            getApStoreOrderDetail: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin/{0}/OrderDetail',
            getApStoreOrderStatusHistory: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin/{0}/StateHistories',
            getApStoreOrderOfficialReport: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin/OfficialReport/{0}',
            getApStoreOrderProductDetail: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin/{0}/GetStoreProductOrderDetailInfo',
            getApStoreProductName: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin/GetStoreProductNames',
            getApStoreStatusQueue: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin/StatusQueue',
            getApStoreStatusQueue: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin/StatusQueue',
            updateApStoreRetryToReadyState: 'api/{culture}/AdminPanel/StoreProductOrdersAdmin/RetryToReadyState',

            getApPayments: 'api/{culture}/AdminPanel/PaymentsAdmins',
            getApPaymentsHasProblem: 'api/{culture}/AdminPanel/PaymentsAdmins/IncompletedProcessPayments',
            getApPaymentResolveProblem: 'api/{culture}/AdminPanel/PaymentsAdmins/ResolveProblem',
            getApPaymentById: 'api/{culture}/AdminPanel/PaymentsAdmins/{0}',
            getApPaymentOrders: 'api/{culture}/AdminPanel/PaymentsAdmins/{0}/Orders',
            getApPaymentIncreaseCredit: 'api/{culture}/AdminPanel/PaymentsAdmins/{0}/IncreaseCreditDetails/{1}',
            getApPaymentStatusHistory: 'api/{culture}/AdminPanel/PaymentsAdmins/{0}/StatusHistories/{1}',
            getApPaymentReport: 'api/{culture}/AdminPanel/PaymentsAdmins/{0}/Report',
            updateApPaymentStatus: 'api/{culture}/AdminPanel/PaymentsAdmins/{0}/Status',
            getApPaymentListExcelReport: 'api/{culture}/AdminPanel/PaymentsAdmins/AllPaymentsExcelReport',

            getApAudits: 'api/{culture}/AdminPanel/PaymentAudits',
            getApAuditDetailById: 'api/{culture}/AdminPanel/PaymentAudits/{0}',
            getApAuditHistoriesById: 'api/{culture}/AdminPanel/PaymentAudits/{0}/Histories',
            updateApAuditOpenToFail: 'api/{culture}/AdminPanel/PaymentAudits/{0}/ChangePaymentStateToFail',
            updateApAuditMatchingType: 'api/{culture}/AdminPanel/PaymentAudits/{0}/UpdateMatchingType',
            updateApAuditMatchingTypes: 'api/{culture}/AdminPanel/PaymentAudits/UpdateMatchingTypes',
            getApAuditGateways: 'api/{culture}/AdminPanel/PaymentAudits/PaymentGateways',
            postApAuditFile: 'api/{culture}/AdminPanel/PaymentAuditSources',
            getApAuditFile: 'api/{culture}/AdminPanel/PaymentAuditSources',
            getApAuditFileAsanPardakht: 'api/{culture}/AdminPanel/PaymentAuditSources/{0}/AsanPardakhts/{1}',
            getApAuditFileSepehr: 'api/{culture}/AdminPanel/PaymentAuditSources/{0}/Sepehrs/{1}',
            getApAuditFileNgenius: 'api/{culture}/AdminPanel/PaymentAuditSources/{0}/Ngenius/{1}',
            getApAuditExcelReport: 'api/{culture}/AdminPanel/PaymentAudits/AllPaymentAuditsExcelReport',

            getApStatisticalReportOfOrders: 'api/{culture}/AdminPanel/SharedSheetOrderReport/StatisticalReportOfOrders',
            getApStatisticalReportOfPayments: 'api/{culture}/AdminPanel/SharedSheetOrderReport/StatisticalReportOfPayments',
            getApUsersDebitCredit: 'api/{culture}/AdminPanel/SharedSheetOrderReport/UsersDebitCredit',

            getApShippings: 'api/{culture}/AdminPanel/Shipping',
            getApShippingById: 'api/{culture}/AdminPanel/Shipping/{0}',
            getApShippingGroupSheetOrders: 'api/{culture}/AdminPanel/Shipping/GetDeliveryRequestOrders',
            getApShippingStoreOrders: 'api/{culture}/AdminPanel/Shipping/GetDeliveryRequestStoreProductOrders',
            getApShippingMethods: 'api/{culture}/AdminPanel/Shipping/GetShippingMethods',
            getApShippingQueueByMethods: 'api/{culture}/AdminPanel/Shipping/QueueByShippingMethods',
            getApShippingQueueByLastStatus: 'api/{culture}/AdminPanel/Shipping/QueueByLastStatuses',
            getApShippingQueueByEstimatedTime: 'api/{culture}/AdminPanel/Shipping/ShippingQueueByRemainingToEstimatedReadyToDeliver',
            getApShippingPlans: 'api/{culture}/AdminPanel/shipping/GetShippingPlans',

            getApAgencies: 'api/{culture}/AdminPanel/Agency',
            getApAgencyById: 'api/{culture}/AdminPanel/Agency/{0}',
            getApAgencyProducts: 'api/{culture}/AdminPanel/Agency/GetProducts',
            getApAgencyList: 'api/{culture}/AdminPanel/Agency/BasicDetails',

            getApFestivals: 'api/{culture}/AdminPanel/Festival',
            getApFestivalById: 'api/{culture}/AdminPanel/Festival/{0}',
            getApFestivalPrizeById: 'api/{culture}/AdminPanel/Festival/{0}/Prizes',
            getApFestivalWinnerById: 'api/{culture}/AdminPanel/Festival/{0}/Winners',
            getApFestivalPrizesWinnerById: 'api/{culture}/AdminPanel/Festival/{0}/Winners/{1}',

            getApStoreProducts: 'api/{culture}/AdminPanel/StoreProducts',
            getApStoreProductById: 'api/{culture}/AdminPanel/StoreProducts/{0}',
            postApStoreProduct: 'api/{culture}/AdminPanel/StoreProducts',
            editApStoreProduct: 'api/{culture}/AdminPanel/StoreProducts/{0}',
            deleteApStoreProduct: 'api/{culture}/AdminPanel/StoreProducts/{0}',

            getApStoreProductProperty: 'api/{culture}/AdminPanel/StoreProductProperties/{0}',
            postApStoreProductProperty: 'api/{culture}/AdminPanel/StoreProductProperties',
            editApStoreProductProperty: 'api/{culture}/AdminPanel/StoreProductProperties/{0}',
            deleteApStoreProductProperty: 'api/{culture}/AdminPanel/StoreProductProperties/{0}',

            getApProductContents: 'api/{culture}/AdminPanel/PprContents',
            getApProductContentDetailById: 'api/{culture}/AdminPanel/PprContents/GetDetail',
            getApProductContentByName: 'api/{culture}/AdminPanel/PprContents/ByName',
            getApProductBasicInfoById: 'api/{culture}/AdminPanel/PprContents/BasicInfo',
            postApProductContents: 'api/{culture}/AdminPanel/PprContents',
            editApProductContents: 'api/{culture}/AdminPanel/PprContents/{0}',

            getApCustomerFiles: 'api/{culture}/AdminPanel/CustomerFiles',
            getApCustomerFileById: 'api/{culture}/AdminPanel/CustomerFiles/{0}',
            getApDownloadOriginFile: 'api/{culture}/AdminPanel/CustomerFiles/{0}/Download',

            getApFeedbackTypes: 'api/{culture}/AdminPanel/FeedbackTypes',
            getApFeedbackTypeById: 'api/{culture}/AdminPanel/FeedbackTypes/BasicInfo',
            updateApFeedbackTypeState: 'api/{culture}/AdminPanel/FeedbackTypes/ChangeFeedbackTypeState/{0}',
            getApFeedbackTypeMetrics: 'api/{culture}/AdminPanel/FeedbackTypeMetrics/{0}/feedbackTypeMetrics',
            getApFeedbackTypeMetricById: 'api/{culture}/AdminPanel/FeedbackTypeMetrics/{0}',
            postApFeedbackTypeMetric: 'api/{culture}/AdminPanel/FeedbackTypeMetrics',
            editApFeedbackTypeMetric: 'api/{culture}/AdminPanel/FeedbackTypeMetrics/{0}',
            deleteApFeedbackTypeMetric: 'api/{culture}/AdminPanel/FeedbackTypeMetrics/{0}',
            editApSortFeedbackTypeMetric: 'api/{culture}/AdminPanel/FeedbackTypeMetrics/ChangeSortNumber',

            getApDemandServiceMetricTypes: 'api/{culture}/AdminPanel/DemandedServiceTypes/GetAll',
            getApDemandServiceMetricById: 'api/{culture}/AdminPanel/DemandedServiceTypes/{0}',
            postApDemandServiceMetric: 'api/{culture}/AdminPanel/DemandedServiceTypes',
            editApDemandServiceMetric: 'api/{culture}/AdminPanel/DemandedServiceTypes/{0}',
            editApSortDemandServiceMetric: 'api/{culture}/AdminPanel/DemandedServiceTypes/ChangeSortNumber',
            deleteDemandedServiceTypes: 'api/{culture}/AdminPanel/DemandedServiceTypes/{0}',

            getApFeedbackAnnouncements: 'api/{culture}/AdminPanel/FeedbackToAnnouncementContents',
            getApFeedbackAnnouncementBasicInfo: 'api/{culture}/AdminPanel/FeedbackToAnnouncementContents/{0}/BasicInfo/{1}',

            getApFeedbackToEmployees: 'api/{culture}/AdminPanel/FeedbackToEmployees',
            getApFeedbackToEmployeeBasicInfo: 'api/{culture}/AdminPanel/FeedbackToEmployees/{0}/BasicInfo/{1}',

            getApFeedbackShareSheetOrders: 'api/{culture}/AdminPanel/FeedbackToSharedSheetOrders',
            getApFeedbackShareSheetOrderBasicInfo: 'api/{culture}/AdminPanel/FeedbackToSharedSheetOrders/{0}/BasicInfo/{1}',

            getApFeedbackActivityHistory: 'api/{culture}/AdminPanel/UserFeedbacks/ActivityHistory/{0}',
            updateApFeedbackDisplayToAllState: 'api/{culture}/AdminPanel/UserFeedbacks/IsPublic/{0}',
            postApFeedbackActivity: 'api/{culture}/AdminPanel/UserFeedbacks/AddState',

            getApBlogs: 'api/{culture}/AdminPanel/Blog',
            getApBlogById: 'api/{culture}/AdminPanel/Blog/{0}',
            postApBlog: 'api/{culture}/AdminPanel/Blog',
            editApBlog: 'api/{culture}/AdminPanel/Blog/{0}',
            deleteApBlog: 'api/{culture}/AdminPanel/Blog/{0}',
            getApBasicBlogCategories: 'api/{culture}/AdminPanel/Blog/BasicDetails',

            getApBlogCategories: 'api/{culture}/AdminPanel/BlogCategory',
            getApBlogCategoryById: 'api/{culture}/AdminPanel/BlogCategory/{0}',
            postApBlogCategory: 'api/{culture}/AdminPanel/BlogCategory',
            editApBlogCategory: 'api/{culture}/AdminPanel/BlogCategory/{0}',
            deleteApBlogCategory: 'api/{culture}/AdminPanel/BlogCategory/{0}',

            getApNews: 'api/{culture}/AdminPanel/News',
            getApNewsById: 'api/{culture}/AdminPanel/News/{0}',
            postApNews: 'api/{culture}/AdminPanel/News',
            editApNews: 'api/{culture}/AdminPanel/News/{0}',
            deleteApNews: 'api/{culture}/AdminPanel/News/{0}',
            getApBasicNewsCategories: 'api/{culture}/AdminPanel/News/BasicDetails',

            getApNewsCategories: 'api/{culture}/AdminPanel/NewsCategory',
            getApNewsCategoryById: 'api/{culture}/AdminPanel/NewsCategory/{0}',
            postApNewsCategory: 'api/{culture}/AdminPanel/NewsCategory',
            editApNewsCategory: 'api/{culture}/AdminPanel/NewsCategory/{0}',
            deleteApNewsCategory: 'api/{culture}/AdminPanel/NewsCategory/{0}',

            getApGallery: 'api/{culture}/AdminPanel/Gallery',
            getApGalleryById: 'api/{culture}/AdminPanel/Gallery/{0}',
            postApGallery: 'api/{culture}/AdminPanel/Gallery',
            editApGallery: 'api/{culture}/AdminPanel/Gallery/{0}',
            deleteApGallery: 'api/{culture}/AdminPanel/Gallery/{0}',
            getApBasicGalleryCategories: 'api/{culture}/AdminPanel/Gallery/BasicDetails',

            getApGalleryCategories: 'api/{culture}/AdminPanel/GalleryCategory',
            getApGalleryCategoryById: 'api/{culture}/AdminPanel/GalleryCategory/{0}',
            postApGalleryCategory: 'api/{culture}/AdminPanel/GalleryCategory',
            editApGalleryCategory: 'api/{culture}/AdminPanel/GalleryCategory/{0}',
            deleteApGalleryCategory: 'api/{culture}/AdminPanel/GalleryCategory/{0}',

            getApAnnouncements: 'api/{culture}/AdminPanel/AnnouncementContent',
            getApAnnouncementById: 'api/{culture}/AdminPanel/AnnouncementContent/{0}',
            postApAnnouncement: 'api/{culture}/AdminPanel/AnnouncementContent',
            editApAnnouncement: 'api/{culture}/AdminPanel/AnnouncementContent/{0}',
            editApSortAnnouncement: 'api/{culture}/AdminPanel/AnnouncementContent/ChangeSortNumber',
            deleteApAnnouncement: 'api/{culture}/AdminPanel/AnnouncementContent/{0}',
            getApAnnouncementContentById: 'api/{culture}/AdminPanel/AnnouncementContent/AnnouncementContentTitleById/{0}',

            getApAdvertisements: 'api/{culture}/AdminPanel/Advertisement',
            getApAdvertisementById: 'api/{culture}/AdminPanel/Advertisement/{0}',
            postApAdvertisement: 'api/{culture}/AdminPanel/Advertisement',
            editApAdvertisement: 'api/{culture}/AdminPanel/Advertisement/{0}',
            deleteApAdvertisement: 'api/{culture}/AdminPanel/Advertisement/{0}',

            getApSliders: 'api/{culture}/AdminPanel/Slider',
            getApSliderById: 'api/{culture}/AdminPanel/Slider/{0}',
            postApSlider: 'api/{culture}/AdminPanel/Slider',
            editApSlider: 'api/{culture}/AdminPanel/Slider/{0}',
            editApSortSlider: 'api/{culture}/AdminPanel/Slider/ChangeSortNumber',
            deleteApSlider: 'api/{culture}/AdminPanel/Slider/{0}',

            getApPromoteContent: 'api/{culture}/AdminPanel/PromoteContent/{0}',
            editApPromoteContent: 'api/{culture}/AdminPanel/PromoteContent/{0}',

            getApSpecificStaticPageList: 'api/{culture}/AdminPanel/StaticPageAdmin',
            getApSpecificStaticPageRowById: 'api/{culture}/AdminPanel/StaticPageAdmin/{0}',
            postApSpecificStaticPageRow: 'api/{culture}/AdminPanel/StaticPageAdmin',
            editApSpecificStaticPageRow: 'api/{culture}/AdminPanel/StaticPageAdmin/{0}',
            editApSortStaticPageAdmin: 'api/{culture}/AdminPanel/StaticPageAdmin/ChangeSortNumber',
            deleteApSpecificStaticPageRow: 'api/{culture}/AdminPanel/StaticPageAdmin/{0}',

            postFileUploader: 'api/{culture}/AdminPanel/FileUploader',
            getApFileManagerFiles: 'api/{culture}/AdminPanel/FileUploader',
            deleteApFileManagerFiles: 'api/{culture}/AdminPanel/FileUploader',

            getDownloads: 'api/{culture}/AdminPanel/Downloads',
            postDownloads: 'api/{culture}/AdminPanel/Downloads',
            getDownloadById: 'api/{culture}/AdminPanel/Downloads/{0}',
            editDownloadById: 'api/{culture}/AdminPanel/Downloads/{0}',
            deleteDownloadById: 'api/{culture}/AdminPanel/Downloads/{0}',
            postDownloadDetails: 'api/{culture}/AdminPanel/DownloadDetails',
            getDownloadDetailById: 'api/{culture}/AdminPanel/DownloadDetails/{0}',
            editDownloadDetailById: 'api/{culture}/AdminPanel/DownloadDetails/{0}',
            deleteDownloadDetailById: 'api/{culture}/AdminPanel/DownloadDetails/{0}',
            getDownloadCategory: 'api/{culture}/AdminPanel/DownloadCategory',
            postDownloadCategory: 'api/{culture}/AdminPanel/DownloadCategory',
            getDownloadAllDetails: 'api/{culture}/AdminPanel/Downloads/{0}/DownloadDetails',
            getDownloadCategoryDropDown: 'api/{culture}/AdminPanel/DownloadCategory/DropDown',
            getDownloadCategoryById: 'api/{culture}/AdminPanel/DownloadCategory/{0}',
            editDownloadCategoryById: 'api/{culture}/AdminPanel/DownloadCategory/{0}',
            deleteDownloadCategoryById: 'api/{culture}/AdminPanel/DownloadCategory/{0}',

            getApAgreements: 'api/{culture}/AdminPanel/UserAgreements',
            postApAgreement: 'api/{culture}/AdminPanel/UserAgreements',
            postApAgreementStaticPageDetails: 'api/{culture}/AdminPanel/UserAgreements/{0}/StaticPages',
            getApAgreementStaticPages: 'api/{culture}/AdminPanel/UserAgreements/{0}/StaticPages',
            getApAgreementStaticPageDetail: 'api/{culture}/AdminPanel/UserAgreements/StaticPageDetail/{0}',
            deleteApAgreementStaticPageDetails: 'api/{culture}/AdminPanel/UserAgreements/StaticPages/{0}',
            editApAgreementStaticPageDetails: 'api/{culture}/AdminPanel/UserAgreements/{0}/StaticPages/{1}',
            deleteApAgreementById: 'api/{culture}/AdminPanel/UserAgreements/{0}',
            editApAgreement: 'api/{culture}/AdminPanel/UserAgreements/{0}',
            getApAgreementInfo: 'api/{culture}/AdminPanel/UserAgreements/{0}',
            getApCurrentActiveUserAgreement: 'api/{culture}/AdminPanel/SharedSheetOrdersAdmins/UserAgreementDetail/{0}',
            getApUserAgreementHistories: 'api/{culture}/AdminPanel/UserAgreementSigns/Histories/{0}',
            getApUserProfileInfo: 'api/{culture}/AdminPanel/UserAgreementSigns/UserInfo/{0}',
            getApUsersAcceptedAgreement: 'api/{culture}/AdminPanel/UserAgreementSigns/Users/{0}',
            getApSelectedUserAgreement: 'api/{culture}/AdminPanel/UserAgreements/UserAgreementDetail/{0}',
            editApActivateUserAgreementState: 'api/{culture}/AdminPanel/UserAgreements/ActivateUserAgreementState/{0}',

            getApUserDepartment: 'api/{culture}/AdminPanel/UserDepartment',
            postApUserDepartment: 'api/{culture}/AdminPanel/UserDepartment',
            getApUserDepartmentById: 'api/{culture}/AdminPanel/UserDepartment/{0}',
            getApUserDepartmentTrees: 'api/{culture}/AdminPanel/UserDepartment/DepartmentTrees',
            getApDepartment: 'api/{culture}/AdminPanel/UserDepartment/Departments',
            editApUserDepartmentById: 'api/{culture}/AdminPanel/UserDepartment/{0}',
            getApUserDepartmentTreesById: 'api/{culture}/AdminPanel/UserDepartment/UserDepartmentTrees',
            editApUserDepartmentTreesById: 'api/{culture}/AdminPanel/UserDepartment/AddUserDepartment',
            deleteApUserDepartmentById: 'api/{culture}/AdminPanel/UserDepartment/{0}',
        },

        view: {
            dashboardFinancial: '{culture}/admin/dashboard/financial',
            dashboardBusiness: '{culture}/admin/dashboard/business',
            dashboardAgencies: '{culture}/admin/dashboard/agencies',
            dashboardAudit: '{culture}/admin/dashboard/audits',
            dashboardFeedback: '{culture}/admin/dashboard/feedbacks',
            dashboardCashback: '{culture}/admin/dashboard/cashback',
            dashboardSmsPanelsCredit: '{culture}/admin/dashboard/sms-panels',
            dashboardUsers: '{culture}/admin/dashboard/users',
            customerUser: '{culture}/admin/users/customers',
            supporterUser: '{culture}/admin/users/supporters',
            shareSheetOrders: '{culture}/admin/orders/share-sheet',
            storeOrders: '{culture}/admin/orders/store',
            queue: '{culture}/admin/orders/queue',
            queueByEstimatedTime: '{culture}/admin/orders/queue/estimated-time',
            dailyQueue: '{culture}/admin/orders/queue/daily',
            payments: '{culture}/admin/payments/list',
            problematicPayments: '{culture}/admin/payments/problematic-payments',
            auditPayments: '{culture}/admin/payments/audit',
            customersFiles: '{culture}/admin/customers-files',
            shippingList: '{culture}/admin/shippings/list',
            inTheWarehouseShipping: '{culture}/admin/shippings/in-the-warehouse',
            shippingQueueByLatestStatus: '{culture}/admin/shippings/queue-by-latest-status',
            shippingQueueByShippingMethods: '{culture}/admin/shippings/queue-by-shipping-methods',
            shippingQueueByEstimatedTime: '{culture}/admin/shippings/queue-by-estimated-time',
            agencies: '{culture}/admin/agencies',
            businessPartners: '{culture}/admin/businessPartners',
            festivals: '{culture}/admin/festivals',
            downloadCenter: '{culture}/admin/content/download/download-center',
            downloadCategory: '{culture}/admin/content/download/categories',
            blogPost: '{culture}/admin/content/blog/posts',
            blogCategories: '{culture}/admin/content/blog/categories',
            newsPost: '{culture}/admin/content/news/posts',
            newsCategories: '{culture}/admin/content/news/categories',
            announcement: '{culture}/admin/content/announcements',
            agreement: '{culture}/admin/content/single-page/agreements',
            faqs: '{culture}/admin/content/single-page/faqs',
            galleryPosts: '{culture}/admin/content/gallery/posts',
            galleryCategories: '{culture}/admin/content/gallery/categories',
            termOfUse: '{culture}/admin/content/single-page/terms-of-use',
            privacyPolicy: '{culture}/admin/content/single-page/privacy-policy',
            warrantyTerms: '{culture}/admin/content/single-page/warranty-terms',
            advertisement: '{culture}/admin/content/advertisements',
            slider: '{culture}/admin/content/sliders',
            promotion: '{culture}/admin/content/promotions',
            testimonial: '{culture}/admin/content/testimonial',
            contactInfo: '{culture}/admin/content/websiteInfo/contact-info',
            departments: '{culture}/admin/content/departments',
            smsConfig: '{culture}/admin/settings/sms-configs',
            communicationChannel: '{culture}/admin/content/websiteInfo/communication-channel',
            metaTags: '{culture}/admin/content/websiteInfo/meta-tags',
            signupAgeRules: '{culture}/admin/settings/signup-age-rules',
            telecommunication: '{culture}/admin/settings/telecommunication',
            notificationConfig: '{culture}/admin/settings/notification-config',
            supportLanguage: '{culture}/admin/settings/support-languages',
            fileManagerConfig: '{culture}/admin/settings/file-manager-config',
            mapConfig: '{culture}/admin/settings/map-config',
            statisticalReportOfOrders: '{culture}/admin/report/statistical-of-orders',
            statisticalReportOfPayments: '{culture}/admin/report/statistical-of-payments',
            usersDebitCredit: '{culture}/admin/report/debit-credit-report',
            fileManager: '{culture}/admin/content/files-manager',
            storeProducts: '{culture}/admin/product/store',
            productContent: '{culture}/admin/product/content',
            logs: '{culture}/admin/system-monitoring/logs',
            paperBag: '{culture}/admin/product/paper-bag',
            feedbackTypes: '{culture}/admin/feedback/types',
            feedbackAnnouncements: '{culture}/admin/feedback/announcements',
            feedbackOrders: '{culture}/admin/feedback/orders',
            feedbackUsers: '{culture}/admin/feedback/users',
        },
    }
};

export default AdminPanelLinks;