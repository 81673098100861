import React, { useEffect, useState } from 'react';

import { GetBusinessPartnerDetailService, GetCustomerDetailService, GetSupporterDetailService } from '../../../../../services/adminPanel/AdminUserService';
import { EditApUserDepartmentTreesByIdService, GetApUserDepartmentTreesByIdService } from '../../../../../services/adminPanel/AdminDepartmentService';
import NsCheckboxTreeView from '../../../../../components/treeViews/NsCheckboxTreeView';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsModal from '../../../../../components/modals/NsModal';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';

export default function ApUserDepartmentModal(props) {
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [userDetails, setUserDetails] = useState('');
    const [userDepartment, setUserDepartment] = useState('');
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);

    const toggleUserInfoModal = () => setShowUserInfoModal(!showUserInfoModal)

    useEffect(() => {
        setIsLoading(true);
        let parameters = { Id: props?.userId };

        getUserDetails();
        GetApUserDepartmentTreesByIdService(parameters, getApUserDepartmentTreesByIdCallback)
    }, []);

    const getUserDetails = () => {
        setIsLoading(true);
        switch (props?.roleType) {
            case Enumerations.role.businessPartner: GetBusinessPartnerDetailService(props?.userId, getUserDetailCallback);
                break;
            case Enumerations.role.supporter: GetSupporterDetailService(props?.userId, getUserDetailCallback);
                break;
            default: GetCustomerDetailService(props?.userId, getUserDetailCallback);
                break;
        }
    };

    const getUserDetailCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError)
            setUserDetails(resultData);
        else
            setResultMessageClass(messageClassModal);
    };

    const getApUserDepartmentTreesByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError)
            setUserDepartment(resultData);
        else
            setResultMessageClass(messageClassModal);
    };

    const editApUserDepartmentCallback = (resultData, result, messageClassModal) => {
        setResultMessageClass(messageClassModal);
        setIsLoading(false);
    };

    const headerButtons = () => ([
        hasAccess([UserClaimEnumerations.getApCustomerById]) && { startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserInfoModal },
    ]);

    const updateUserDepartment = (item, selectedDepartment) => {
        if (!isEmpty(selectedDepartment)) {
            setUserDepartment([...selectedDepartment.data]);
        }
    };

    const saveButtonClicked = () => {
        let allUserDepartments = CommonHelper.recursiveSeparator(userDepartment).parentLessItems.filter(item => item.checked).map(item => item.id)

        let requestBody = {
            userId: props?.userId,
            categoryIds: allUserDepartments,
        };
        setIsLoading(true);
        EditApUserDepartmentTreesByIdService(requestBody, editApUserDepartmentCallback, true);
    }

    const mainButtons = [
        hasAccess([UserClaimEnumerations.postApAddUserDepartment]) && { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary' },
    ];

    const userFullName = () => ({ orderItemInfoDto: { userFullName: `${userDetails?.firstName} ${userDetails?.lastName}` } });

    return (
        <NsModal showModal={props?.showModal}
            maxWidth="sm"
            closeModal={props?.closeModal}
            dialogTitle={getTranslatedRawText('adminPanel.sidebar.departments')}
            noSpace
            actions={<NsStickyButton stickyButtons={mainButtons} />}>

            <ApBaseMapActionButtons actionButtons={headerButtons()}
                isLoading={isLoading} />

            <div className='background-color p-2'>
                <ApBaseLabelInfo info={userFullName()}
                    hasAccessInfo={true}
                    isLoading={isLoading} />
            </div>
            {isLoading
                ? <NsSkeleton isVisible={true}
                    variant={[Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect]} />
                : <div className='border rounded p-3 m-4'>
                    <NsCheckboxTreeView items={CommonHelper.addFieldToRecursiveObj(userDepartment, 'show', true)}
                        roleId={Enumerations.role.supporter}
                        isReturnData={true}
                        checked={userDepartment?.checked}
                        onChange={updateUserDepartment} />
                </div>}
            {resultMessageClass}
        </NsModal>
    )
}
