import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetShippingEstimatedTimeSheetService, GetShippingMethodsService, GetShippingPlansService } from '../../../../../services/ProfileSetting/DeliveryAddressSettingService';
import UpBaseDefaultAddress from '../../userProfile/bases/UpBaseDefaultAddress';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import UpShippingRequestHeaderCost from './UpShippingRequestHeaderCost';
import UpShippingRequestHeaderPlan from './UpShippingRequestHeaderPlan';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import NsTextField from '../../../../../components/inputs/NsTextField';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import NsTypography from '../../../../../components/text/NsTypography';

class UpShippingRequestHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAddressId: props.selectedAddressId,
            selectedCarrierId: props.selectedCarrierId,
            shippingPlans: [],
            shippingMethods: [],
            selectedShippingPlan: null,
            selectedShippingMethod: null,
            selectedAddress: null,
            shipmentDescription: '',
            showShippingAddressConfirmationModal: false,
            isLoading: true,
            shippingTime: null,
            resultMessageClass: (<></>)
        };
    }

    componentDidMount = () => {
        CommonHelper.safeFunctionCall(this.props.handleDisableSave, true);
        this.getShippingPlans();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { selectedAddress, selectedShippingPlan, selectedAddressId } = this.state;
        if ((selectedAddressId !== prevState.selectedAddressId) ||
            (selectedShippingPlan?.key !== prevState.selectedShippingPlan?.key)) {

            let shippingMethodsParameters = {
                orderProductTypeId: this.props.orderProductType,
                cityId: selectedAddress?.cityId,
                shippingPlanTypeId: selectedShippingPlan?.key,
            }
            !isEmpty(selectedAddress) && !isEmpty(selectedShippingPlan)
                && GetShippingMethodsService(shippingMethodsParameters, this.getShippingMethodsCallback)
        }
        if (prevProps.setExpressShippingDate !== this.props.setExpressShippingDate)
            this.shippingPlanClicked(this.state.shippingPlans[1]);
    }

    getShippingMethodsCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false })
        if (!result?.data?.hasError)
            this.setState({ shippingMethods: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getShippingPlans = () => {
        let parameters = {
            orderProductTypeId: this.props.orderProductType
        }
        GetShippingPlansService(parameters, this.getShippingPlansCallback);
    }

    getShippingPlansCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            resultData.map(el => el.disabled = !el.isAvailable);
            this.setState({ shippingPlans: resultData });
            if (resultData.length > 0)
                this.shippingPlanClicked(resultData[0]);
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getShippingEstimatedTimeSheet = () => {
        GetShippingEstimatedTimeSheetService({
            shippingPlanType: this.state.selectedShippingPlan?.key,
            shippingMethodCategoryType: this.state.selectedShippingMethod.shippingMethodCategoryTypeId
        }, this.getShippingEstimatedTimeSheetCallback);
    }

    getShippingEstimatedTimeSheetCallback = (resultData, result, resultMessageClassModal) => {
        this.setState({ isLoading: false });
        let shippingTime = null;
        let hasTime = false;
        if (result.data.hasError) this.setState({ resultMessageClassModal });
        else {
            resultData.map(result => {
                hasTime = result.messageClass.messageItems.some(message => message.data == Enumerations.timeSheetDayType.shippingToShippingMethodDay);
                if (hasTime) shippingTime = result.dayDateTime;
            })
            this.setState({ shippingTime }, () =>
                CommonHelper.safeFunctionCall(this.props.valueChanged, shippingTime, { shippingTime }));
        }
    }

    addressChanged = (value, isValid, valueName, isValidName, addressList) => {
        if (value !== this.state.selectedAddressId) {
            let selectedAddress = addressList.find(address => address.id === value);
            this.setState({
                selectedAddressId: value,
                selectedCarrierId: null,
                selectedAddress,
                shippingMethods: [],
                selectedShippingMethod: null,
            });
            CommonHelper.safeFunctionCall(this.props.handleDisableSave, true);
            CommonHelper.safeFunctionCall(this.props.valueChanged, selectedAddress, { selectedAddress });
        }
    }

    availableMethodChanged = (value, isValid) => {
        let selectedShippingMethod = this.state.shippingMethods.find(method => method.id === value);
        selectedShippingMethod?.shippingMethodCategoryTypeId === Enumerations.shippingMethodCategoryType.doorToDoor &&
            this.toggleshippingAddressConfirmationModal();

        this.setState({ selectedShippingMethod }, () => {
            this.getShippingEstimatedTimeSheet();
            CommonHelper.safeFunctionCall(this.props.valueChanged, selectedShippingMethod, { selectedShippingMethod });
        });
    }

    shipmentDescriptionChanged = (shipmentDescription) => this.setState({ shipmentDescription }, () => {
        CommonHelper.safeFunctionCall(this.props.valueChanged, shipmentDescription, { shipmentDescription });
    });

    shippingPlanClicked = (selectedShippingPlan) => {
        const { selectedShippingMethod } = this.state;
        selectedShippingPlan?.key !== this.state.selectedShippingPlan?.key
            && this.setState({ selectedShippingPlan, selectedShippingMethod: null }, () => {
                CommonHelper.safeFunctionCall(this.props.valueChanged, selectedShippingPlan, { selectedShippingPlan });
                CommonHelper.safeFunctionCall(this.props.valueChanged, this.state.selectedShippingMethod, { selectedShippingMethod });
            });
    }

    toggleshippingAddressConfirmationModal = () => this.setState({ showShippingAddressConfirmationModal: !this.state.showShippingAddressConfirmationModal });

    changeSelectedShippingPlan = (plan) => this.setState({ selectedShippingPlan: plan });

    shippingAddressConfirmationModalClicked = (confirm) => {
        if (!!confirm) this.toggleshippingAddressConfirmationModal()
        else {
            this.setState({
                selectedAddressId: '',
                shippingPlans: [],
                shippingMethods: [],
                selectedShippingPlan: null,
                selectedShippingMethod: null,
                selectedAddress: null,
                shipmentDescription: '',
                showShippingAddressConfirmationModal: false,
            }, () => this.getShippingPlans());
            CommonHelper.safeFunctionCall(this.props.methodChanged, null)
        }
    }

    render() {
        const { selectedAddressId, shippingPlans, selectedShippingPlan, shippingMethods, showShippingAddressConfirmationModal,
            selectedShippingMethod, selectedAddress, shipmentDescription, resultMessageClass, isLoading, shippingTime } = this.state;
        const { autoDelivery, showShippingTodayMessageOnCost, orderProductType } = this.props;
        return (
            <div className='w-100'>
                {(isLoading && (autoDelivery ?? true))
                    ? <Row className='g-0'>
                        <Col xs={12} className='mb-2'>
                            <NsSkeleton isVisible={true}
                                variant={[Enumerations.skeleton.text]} />
                        </Col>
                        {CommonHelper.createArray(3).map((item, index) =>
                            <Col key={index} xs={12} md={4} className={`${index === 1 && 'px-3'} ${index !== 0 && 'd-none d-md-block'}`}>
                                <NsSkeleton isVisible={true}
                                    variant={[Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect]} />
                            </Col>
                        )}
                    </Row>
                    : <Row>
                        <Col md={8} sm={12}>
                            <UpBaseDefaultAddress selectedDefaultUserAddressId={selectedAddressId}
                                buttonClassName='secondary-outline'
                                valueChanged={this.addressChanged} />
                            <UpShippingRequestHeaderPlan
                                orderProductType={orderProductType}
                                selectedAddress={selectedAddress}
                                shippingPlans={shippingPlans}
                                shippingMethods={shippingMethods}
                                availableMethodChanged={this.availableMethodChanged}
                                selectedShippingMethod={selectedShippingMethod}
                                selectedShippingPlan={selectedShippingPlan}
                                shippingPlanClicked={this.shippingPlanClicked}
                                getIsAvailabel={this.props.getIsAvailabel}
                                createExpressShippingAvailableMessage={this.props.createExpressShippingAvailableMessage} />
                        </Col>
                        <Col md={4} sm={12}>
                            {!isEmpty(selectedShippingMethod?.id) && !isEmpty(selectedShippingPlan?.key)
                                && <UpShippingRequestHeaderCost selectedShippingMethod={selectedShippingMethod}
                                    changeSelectedShippingPlan={this.changeSelectedShippingPlan}
                                    shippingTime={shippingTime}
                                    selectedShippingPlan={selectedShippingPlan}
                                    expressShippingClicked={this.props.expressShippingClicked}
                                    showShippingTodayMessageOnCost={showShippingTodayMessageOnCost} />}
                            {false && <NsTextField className='border border-secondary-subtle rounded-3 p-2 w-100 my-2 shipment-description'
                                multiline
                                inputProps={{ maxLength: 500 }}
                                InputProps={{ disableUnderline: true }}
                                placeholder={getTranslatedRawText('profile.delivery.shipmentDescription')}
                                valueChanged={this.shipmentDescriptionChanged}
                                value={shipmentDescription} />
                            }
                        </Col>
                    </Row>
                }
                {resultMessageClass}
                {
                    showShippingAddressConfirmationModal &&
                    <NsMessageClassModal show={true}
                        onClose={(confirm) => this.shippingAddressConfirmationModalClicked(confirm)}
                        messageItems={[{
                            messageText: <div className='py-2'>
                                <NsTypography variant='inherit'>{getTranslatedRawText('profile.delivery.shiporderstoaddress')}</NsTypography>
                                <NsTypography variant='inherit' className='mt-2 fw-bold'>{selectedAddress?.name}</NsTypography>
                            </div>,
                            messageType: Enumerations.messageType.question,
                        }]} />
                }
            </div >
        );
    }
}

export default UpShippingRequestHeader;