import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import '../../assets/sass/components/carousel/NsCarousel.scss';
import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import { getCurrentCulture, getTranslatedRawText } from '../../utilities/CultureHelper';
import { getEnumKeyFromValue } from '../../utilities/EnumerationHelper';
import { selectPromotionEvent, viewPromotionEvent } from '../../utilities/GoogleTagManagerHelper';
import ImageHelper from '../../utilities/ImageHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import NsButton from '../buttons/NsButton';
import NsImage from '../images/NsImage';
import NsLinkImage from '../images/NsLinkImage';


class NsImageCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    imageLoaded = (e, index) => viewPromotionEvent(e.target, index, Enumerations.promotionsId.homeTopSlider, Enumerations.googleTagManagerListId.home)

    imageSelected = (e, index) => selectPromotionEvent(e.target, index, Enumerations.promotionsId.homeTopSlider, Enumerations.googleTagManagerListId.home)

    mapCarouselItem = (imageList) => {
        return !isEmpty(imageList)
            ? imageList.map((item, index) => (
                !!item && <Carousel.Item key={index}>
                    <NsLinkImage imgInfo={item} imgClassName={!isEmpty(item.linkUrl) ? "carousel-img d-block w-100" : "d-block w-100"}
                        imageSitePosition={Enumerations.imageSitePosition.homeSlider}
                        onLoad={(e) => this.imageLoaded(e, index)}
                        onClick={(e) => this.imageSelected(e, index)} />
                </Carousel.Item>
            ))
            : <Carousel.Item >
                <NsImage src=''
                    imgAlt="components.imagesAlt.imageNotFound" />
            </Carousel.Item>
    }

    handleSelect = (index) => {
        this.setState({ index });
    }

    componentDidUpdate = (newProps) => {
        if (this.props.activeIndex !== newProps.activeIndex)
            this.handleSelect(this.props.activeIndex)
    }

    render() {
        const { index } = this.state;
        const { imageList } = this.props;

        return (
            <Carousel
                className="carousel-home text-center border-outline-color-1px"
                activeIndex={index}
                aria-label={getTranslatedRawText('components.carousel.ariaLabelCarousel')}
                // pause={false}
                defaultActiveIndex={0}
                onSelect={this.handleSelect}
                // indicators={false}
                // interval={null}
                controls={imageList?.length > 1}
                prevIcon={<NsButton startIcon={getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en) ? 'ns-icon-expand-left' : 'ns-icon-expand-right'} size='small' className='light' wrapperClassName='ms-2' />}
                nextIcon={<NsButton startIcon={getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en) ? 'ns-icon-expand-right' : 'ns-icon-expand-left'} size='small' className='light' wrapperClassName='me-2' />} >
                {this.mapCarouselItem(imageList)}

            </Carousel>
        );
    }
}

export default withRouter(NsImageCarousel);