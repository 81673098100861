import React, { Component } from 'react';

import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsCollapse from '../../../../components/dropdowns/NsCollapse';
import NsImage from '../../../../components/images/NsImage';
import NsSetPageTitleAndDescription from '../../../../components/metaTags/NsSetPageTitleAndDescription';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../components/text/NsTypography';
import Enumerations from '../../../../configs/Enumerations';
import Links from '../../../../configs/links/AllLinks';
import { GetDownloadCategoriesService } from '../../../../services/singlePages/DownloadService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import UrlHelper from '../../../../utilities/UrlHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import SinglePage from '../../SinglePage';
import DownloadCategoryContent from './DownloadCategoryContent';
import DownloadHeader from './DownloadHeader';
import NsCard from '../../../../components/cards/NsCard';
import PdBaseSimilarProducts from '../../../productDetails/bases/PdBaseSimilarProducts';
import { connect } from 'react-redux';

class DownloadCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadCategories: [],
            isLoading: true,
            activeCategoryId: UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id),
            activeCategoryTitle: UrlHelper.getRouteValue(this.props, Enumerations.routeKey.name),
            selectedViewId: Enumerations.viewType.viewList,
            searchValue: '',
            getDownloadDetailDate: null,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        GetDownloadCategoriesService(this.getDownloadCategoriesCallback);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.downloadCategories.length !== prevState.downloadCategories.length) {
            if (isEmpty(this.state.activeCategoryId)) {
                this.setState({
                    activeCategoryId: this.state.downloadCategories[0].id,
                    activeCategoryTitle: this.state.downloadCategories[0].title
                })
                this.props.history.push(CommonHelper.stringFormat(
                    getUrlWithCurrentCulture(Links, Links.download),
                    this.state.downloadCategories[0].id, CommonHelper.makeCorrectLink(this.state.downloadCategories[0].title)
                ));
            }

        }
    }

    getDownloadCategoriesCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.setState({ downloadCategories: resultData });
            if (!isEmpty(resultData) && window.location.pathname === getUrlWithCurrentCulture(Links, Links.downloadCenter).slice(0, -1))
                this.activeCategoryIdChanged(true, resultData[0], false);
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    activeCategoryIdChanged = (isOpen, category, showDetailInThePage) => {
        if (showDetailInThePage) {
            this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.downloadDetail), category?.id, CommonHelper.makeCorrectLink(CommonHelper.removeWhiteSpaceFromText(category?.title))));
        }
        else {
            this.setState({ activeCategoryId: category?.id, activeCategoryTitle: category?.title },
                this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.download), category?.id, CommonHelper.makeCorrectLink(CommonHelper.removeWhiteSpaceFromText(category?.title)))));
        }
    }

    searchValueChanged = (searchValue) => this.setState({ searchValue });

    searchFormClicked = () => this.setState({ getDownloadDetailDate: new Date() });

    deleteSearchChipClicked = () => this.setState({ searchValue: '', getDownloadDetailDate: new Date() });

    viewItemChanged = (selectedViewId) => this.setState({ selectedViewId: selectedViewId?.key });

    render() {
        const { downloadCategories, selectedViewId, searchValue, isLoading, resultMessageClass, activeCategoryId, activeCategoryTitle, getDownloadDetailDate } = this.state;
        return (
            <SinglePage >
                <NsPageMainContentWrapper header={getTranslatedRawText('singlePage.download.downloadCenter')}
                    icon='ns-icon-download h1' className='mb-3'>

                    <DownloadHeader searchValueChanged={this.searchValueChanged}
                        searchFormClicked={this.searchFormClicked}
                        viewItemChanged={this.viewItemChanged}
                        selectedViewId={selectedViewId}
                        searchText={searchValue} />

                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                        : <DownloadList downloadCategories={downloadCategories}
                            activeCategoryId={activeCategoryId}
                            activeCategoryTitle={activeCategoryTitle}
                            selectedViewId={selectedViewId}
                            searchValue={searchValue}
                            deleteSearchChipClicked={this.deleteSearchChipClicked}
                            getDownloadDetailDate={getDownloadDetailDate}
                            activeCategoryIdChanged={this.activeCategoryIdChanged} />
                    }
                    {resultMessageClass}

                </NsPageMainContentWrapper>
                <NsSetPageTitleAndDescription pageTitle='singlePage.download.downloadCenter'
                    selectedCategoryNames={CommonHelper.replaceHyphenToSpace(activeCategoryTitle)} />
                <NsCard className='px-3'>
                    <PdBaseSimilarProducts getSimilarProductsDate={''}
                        categoryIds={[]}
                        selectedCurrency={this.props.selectedCurrency?.id} />
                </NsCard>
            </SinglePage >
        );
    }
}

function DownloadList(props) {
    const { downloadCategories, activeCategoryId, activeCategoryTitle, selectedViewId, getDownloadDetailDate, searchValue } = props;

    return !isEmpty(downloadCategories)
        ? downloadCategories?.map(item => (
            <div key={item.id} className='background-element-color rounded mb-2'>
                <a onClick={(e) => e.preventDefault()}>
                    <NsCollapse
                        className="p-1"
                        defaultIsOpen={activeCategoryId == item?.id}
                        isOpenStatus={(isOpen) => props.activeCategoryIdChanged(isOpen, item, false)}
                        titleClassName='font-size-16 fw-bold'
                        title={<div className='d-flex align-items-center gap-2'>
                            {item.fileUrl
                                && <NsImage src={CommonHelper.createImageUrl(item.fileUrl) || ''}
                                    width='50px'
                                    wrapperClassName='w-auto'
                                    imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.contentImage"), item.title)} />
                            }
                            <div>
                                {item.title}
                            </div>
                        </div>}>

                        <div className='d-block bg-white'>
                            <DownloadCategoryContent selectedViewId={selectedViewId}
                                activeCategoryId={activeCategoryId}
                                activeCategoryTitle={activeCategoryTitle}
                                searchValue={searchValue}
                                deleteSearchChipClicked={props.deleteSearchChipClicked}
                                getDownloadDetailDate={getDownloadDetailDate} />
                        </div>
                    </NsCollapse>
                </a>
            </div >
        ))
        : <NsTypography variant='inherit' className='section-title'>
            {getTranslatedRawText('singlePage.dataNotFound')}
        </NsTypography>;
}

const mapStateToProps = (state) => {
    return {
        selectedCurrency: state.currencies.selectedCurrency
    };
}

export default connect(mapStateToProps)(DownloadCategories);