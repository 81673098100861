import React from 'react';
import { Link } from 'react-router-dom';

import Links from '../../../../configs/links/AllLinks';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import DateHelper from '../../../../utilities/DateHelper';

class BnBasesPostSpecification extends React.Component {

    isBlog = CommonHelper.isBlog(window.location.href);

    render() {
        const { createAt, createByUser, createByUserId } = this.props;

        return <div className='d-flex align-items-center font-size-12 my-2'>
            <i className='ns-icon-author h5 mb-0' />
            <Link to={this.isBlog
                ? CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogSearchResult, { createdBy: createByUserId, createByUser: createByUser }))
                : CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsSearchResult, { createdBy: createByUserId, createByUser: createByUser }))}
                className='black-color text-decoration-none'>
                {createByUser}&nbsp;&nbsp;
            </Link>
            <i className='ns-icon-post-on h5 mb-0' />
            {DateHelper.getDatePart(createAt)}&nbsp;&nbsp;
            {false && <div>
                <i className='ns-icon-like-result h5 mb-0' />
                120&nbsp;&nbsp;
                <i className='ns-icon-comment-result h5 mb-0' />
                10
            </div>
            }
        </div>
    }
}

export default BnBasesPostSpecification;