import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import EnumerationTranslationHelper from '../../../utilities/EnumerationTranslationHelper';
import NsMessageClassModal from '../../../components/modals/NsMessageClassModal';
import NsProductGuidance from '../../../components/guidance/NsProductGuidance';
import OrderFilesPreview from '../regularOrders/orderSummary/OrderFilesPreview';
import OSummaryExtendedService from '../summaries/OSummaryExtendedService';
import OSummaryChoosingFile from '../summaries/OSummaryChoosingFile';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import RegularOrderTours from '../../bases/tours/RegularOrderTours';
import NsLabelBox from '../../../components/labels/NsLabelBox';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import Enumerations from '../../../configs/Enumerations';
import OrderHelper from '../../../utilities/OrderHelper';
import Links from '../../../configs/links/AllLinks';
import NsTour from '../../../components/tour/NsTour';
import OBaseWrapper from '../bases/OBaseWrapper';
import NsTypography from '../../../components/text/NsTypography';
import NsModal from '../../../components/modals/NsModal';
import NsImage from '../../../components/images/NsImage';
import OBaseFilmImagePreview from '../bases/OBaseFilmImagePreview';
import { DraftImagePreview } from '../summaries/OSummary';

class OHeader extends React.Component {

    constructor(props) {
        super(props);
        this.tourSteps = RegularOrderTours.getHeaderTour();
        this.productName = OrderHelper.getProductName(props.routeId);
        this.turnaround = OrderHelper.getTurnaround(props.routeId)
        this.printedSide = OrderHelper.getPrintedSide(props.routeId)
        this.circulation = OrderHelper.getCirculation(props.routeId)?.value;
        this.series = OrderHelper.getSeries(props.routeId);
        this.hasNoSupportedExtendedServices = OrderHelper.getNoSupportedExtendedServices(props.routeId)?.length > 0;

        this.state = {
            defaultIsOpen: false,
            isShowQuestionModal: false,
            hasImage: null,
            selectedImageIndex: null,
            isFront: null,
            files: [],
            messageItems: [{
                messageText: getTranslatedRawText('common.messages.questionToGoProductDetail'),
                messageType: Enumerations.messageType.warning
            }]
        }
    }

    backToOrderDetailClicked = () => {
        CommonHelper.safeFunctionCall(this.props.toggleProfessionalOrderTour);
        this.setState({ isShowQuestionModal: true, defaultIsOpen: true });
    }

    closeQuestionModal = (confirm) => {
        this.setState({ isShowQuestionModal: false, defaultIsOpen: false });
        if (!!confirm) {
            let link = this.props.isShowInProfessionalOrder ? Links.view.main.professionalOrder : Links.view.main.regularOrder;
            this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(link, link.productDetails),
                CommonHelper.toInt(this.props.routeId)));
        } else
            CommonHelper.safeFunctionCall(this.props.toggleProfessionalOrderTour, true);
    }

    imageClicked = (hasImage, selectedImageIndex, isFront) => this.setState({ hasImage, selectedImageIndex, isFront, defaultIsOpen: true });

    filesHandler = (files) => this.setState({ files, defaultIsOpen: true });

    closePreviewImageClicked = () => this.setState({ hasImage: null });


    render() {
        const { isShowInProfessionalOrder, routeId, totalExtendedServicePrice } = this.props;
        const { isShowQuestionModal, messageItems, defaultIsOpen } = this.state;
        const productOrder = OrderHelper.findOrderById(this.props.routeId);
        const showTour = productOrder.files[0].frontSideFileInfo && !productOrder.files[0].backSideFileInfo && !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.preview);
        return (
            <OBaseWrapper title={this.productName}
                defaultIsOpen={defaultIsOpen}
                collapseClassName='mt-2'>

                <Row className='justify-content-around px-2 g-2 header-tour-details'>
                    <Col lg={4} xl={5} className="page-title mt-3 d-none d-md-flex">
                        {this.productName}
                    </Col>
                    <Col lg={6} xl={5} className="d-flex align-items-center pt-3 pt-md-0">
                        <OrderInfo backToOrderDetailClicked={this.backToOrderDetailClicked}
                            turnaround={this.turnaround}
                            printedSide={this.printedSide}
                            quantityLabel={isShowInProfessionalOrder ? 'regularOrder.header.circulation' : 'regularOrder.header.quantity'}
                            quantity={isShowInProfessionalOrder ? this.circulation : this.circulation * this.series} />
                    </Col>
                    <Col md={2} className="d-flex justify-content-end py-3">
                        <NsProductGuidance guidancesUrls={productOrder.guidancesUrls} />
                    </Col>
                    {this.hasNoSupportedExtendedServices && false && //is disable now
                        <NsTypography variant='inherit' className='red-color'>
                            {CommonHelper.stringFormat(getTranslatedRawText('common.messages.notSupportedExtendedServices'), OrderHelper.getNoSupportedExtendedServices(routeId).map(item => OrderHelper.getExtendedServicesName(item.extendedServiceType) + ' '))}
                        </NsTypography>}
                </Row >
                {!isShowInProfessionalOrder &&
                    <Row className=' justify-content-around p-1 mx-0 d-md-none header-tour'>
                        <Col xs={12}>
                            <OSummaryChoosingFile routeId={routeId} />
                            <OSummaryExtendedService routeId={routeId}
                                totalExtendedServicePrice={totalExtendedServicePrice} />
                            <OrderFilesPreview routeId={routeId}
                                imageClicked={this.imageClicked}
                                filesHandler={this.filesHandler} />
                        </Col>
                    </Row>
                }

                {isShowQuestionModal &&
                    <NsMessageClassModal show={isShowQuestionModal}
                        messageItems={messageItems}
                        onClose={this.closeQuestionModal} />
                }

                {
                    (showTour && CommonHelper.isMobileDevice()) && <NsTour steps={this.tourSteps}
                        id={Enumerations.tourGuide.preview} />
                }

                {this.state.hasImage && productOrder.files.length > 0 &&
                    <DraftImagePreview hasImage={this.state.hasImage}
                        files={this.state.files}
                        isFront={this.state.isFront}
                        selectedImageIndex={this.state.selectedImageIndex}
                        closePreviewImageClicked={this.closePreviewImageClicked} />
                }

            </OBaseWrapper>
        );
    }
}

function OrderInfo(props) {
    return <div className='d-flex flex-wrap gap-2'>
        <NsButton className="secondary header-tour-edit"
            wrapperClassName='mx-1'
            size='small'
            startIcon='ns-icon-edit'
            onClick={props.backToOrderDetailClicked}
            label='regularOrder.header.editOption' />

        <BaseInfo turnaround={props.turnaround}
            printedSide={props.printedSide}
            quantityLabel={props.quantityLabel}
            quantity={props.quantity} />
    </div>
}

function BaseInfo(props) {
    return (<>
        <NsLabelBox label={getTranslatedRawText('regularOrder.header.turnaround')}
            value={EnumerationTranslationHelper.getTurnaroundText(props.turnaround?.key)} />
        <NsLabelBox label={getTranslatedRawText('regularOrder.header.printedSide')}
            value={EnumerationTranslationHelper.getPrintedSideText(props.printedSide?.key)} />
        <NsLabelBox label={getTranslatedRawText(props.quantityLabel)}
            value={props.quantity} />
    </>
    );
}

export default withRouter(OHeader);