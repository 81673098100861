import clsx from 'clsx';
import React, { Component } from 'react';

import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import { getCurrentCulture } from '../../utilities/CultureHelper';
import { getEnumKeyFromValue } from '../../utilities/EnumerationHelper';
import ImageHelper from '../../utilities/ImageHelper';
import NsImage from '../images/NsImage';

class NsProductImage extends Component {
    render() {
        const { product, className, usedIn } = this.props;
        const nsImageClassName = getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.fa)
            ? "not-available-fa"
            : "not-available-en";

        return product.imageUrl ?
            <img src={CommonHelper.createImageUrl(product.imageUrl)}
                srcSet={ImageHelper.getCdnImageSrcset(usedIn, product.imageUrl)}
                alt={product.name}
                className={className} />
            :
            Array.isArray(product.imageUrls) && product.imageUrls.length > 0 ?
                <img src={CommonHelper.createImageUrl(product.imageUrls[0])}
                    srcSet={ImageHelper.getCdnImageSrcset(usedIn, product.imageUrls[0])}
                    alt={product.name}
                    className={className} />
                : <NsImage className={clsx(nsImageClassName, className)}
                    imgAlt="components.imagesAlt.imageNotFound"
                    src={ImageHelper.blankSrc()} />;
    }
}

export default NsProductImage;