import React from 'react';
import clsx from 'clsx';
import NsCollapse from '../../../../components/dropdowns/NsCollapse';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import PdpSidebarCategory from './PdpSidebarCategory'
import PdpSidebarProductsList from './PdpSidebarProductsList'
import NsStickyButton from '../../../../components/buttons/NsStickyButton';

class PdpSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: {},
            selectedId: !!this.props.selectedId ? this.props.selectedId : this.props?.selectedId,
        }
    }

    selectedCategoryChanged = (selectedCategory) => this.setState({ selectedCategory });

    componentDidUpdate = (prevProps) => {
        if (prevProps?.selectedId !== this.props?.selectedId)
            this.setState({ selectedId: !!this.props.selectedId ? this.props.selectedId : this.props?.selectedId })
    }

    nextButtonClicked = () => CommonHelper.safeFunctionCall(this.props.isOpenStatus)

    render() {
        const { selectedCategory } = this.state;
        return (
            <div className={clsx(CommonHelper.isMobileDevice() && 'd-flex flex-column wrapper-white p-3 ')}>
                {CommonHelper.isMobileDevice()
                    ? <NsCollapse
                        title={getTranslatedRawText('professionalOrder.collapse.products')}
                        defaultIsOpen={this.props.showMainSectionCollapse}
                        isOpenStatus={this.props.isOpenStatus}
                        className="fw-bold d-md-none font-size-14"
                        collapseClassName="mb-2"
                        titleClassName='black-color '>

                        <PdpSidebarRender selectedCategory={selectedCategory}
                            selectedCategoryChanged={this.selectedCategoryChanged}
                            selectedId={this.state.selectedId}
                            selectedIdChanged={this.props.selectedIdChanged}
                            productsLoaded={this.props.productsLoaded}
                            printCirculations={this.props.printCirculations} />

                        <div className='d-flex mt-2 justify-content-end'>
                            <NsStickyButton stickyButtons={[CommonHelper.createStickyButtonItem('common.next', CommonHelper.getNextIconByCulture(), this.nextButtonClicked, 'secondary', !this.state.selectedId)]} />
                        </div>

                    </NsCollapse>
                    : <PdpSidebarRender selectedCategory={selectedCategory}
                        selectedCategoryChanged={this.selectedCategoryChanged}
                        selectedId={this.state.selectedId}
                        selectedIdChanged={this.props.selectedIdChanged}
                        productsLoaded={this.props.productsLoaded}
                        printCirculations={this.props.printCirculations} />}
            </div>
        );
    }
}

function PdpSidebarRender(props) {
    const { selectedCategory, selectedCategoryChanged, selectedId, selectedIdChanged, productsLoaded, printCirculations } = props
    return (<div className='product-tour-select'>
        <PdpSidebarCategory selectedCategory={selectedCategory}
            selectedCategoryChanged={selectedCategoryChanged} />

        <PdpSidebarProductsList selectedCategory={selectedCategory}
            selectedId={selectedId}
            selectedIdChanged={selectedIdChanged}
            productsLoaded={productsLoaded}
            printCirculations={printCirculations} />
    </div>)
}

export default PdpSidebar;