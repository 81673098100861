import React from 'react';

import NsButton from '../../../components/buttons/NsButton';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsTextField from '../../../components/inputs/NsTextField';
import NsModal from '../../../components/modals/NsModal';
import NsTypography from '../../../components/text/NsTypography';
import { DeleteByCancellationCodeService } from '../../../services/ProfileSetting/UserOrderService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';

class OrderWithInvoiceCancellationModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            { label: "common.delete", icon: 'ns-icon-delete', onClicked: this.deleteClicked, className: 'primary' },
            { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => this.cancelClicked(false), className: 'primary-outline' },
        ];
        this.state = {
            cancellationCode: 0,
            isValidCode: false,
            showValidationDate: '',
            isLoading: false,
            resultMessageClass: (<></>),
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.isLoading !== this.props.isLoading)
            this.setState({ isLoading: this.props.isLoading });
    }

    valueChanged = (value, isValid) => this.setState({ cancellationCode: value, isValidCode: isValid, });

    cancelClicked = (closeParent, messageClassModal) => CommonHelper.safeFunctionCall(this.props.closeModal, closeParent, messageClassModal);

    deleteClicked = () => {
        const { cancellationCode, isValidCode } = this.state;
        if (!isValidCode || cancellationCode == '0') {
            this.setState({ showValidationDate: new Date() });
            return false;
        } else {
            this.setState({ isLoading: true },
                DeleteByCancellationCodeService({ sharedSheetOrderId: this.props.orderId, cancellationCode }, this.deleteByCancellationCodeCallback, true)
            );
        }

    }

    deleteByCancellationCodeCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.cancelClicked(true, messageClassModal);
        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    invoiceClicked = () => {
        this.setState({
            isLoading: true
        }, () => CommonHelper.safeFunctionCall(this.props.printInvoiceClicked))
    }

    render() {
        const { isLoading, showValidationDate, cancellationCode, resultMessageClass } = this.state;
        return (
            <NsModal showModal={true}
                primaryActionButton={this.deleteClicked}
                maxWidth='xs'
                dialogTitle={getTranslatedRawText("profile.orderPreparing.orderWithInvoiceCancelation")}
                closeModal={this.cancelClicked}
                actions={<NsStickyButton stickyButtons={this.mainButtons} isLoading={isLoading} />} >
                <p className='primary-paragraph'>
                    {getTranslatedRawText("profile.orderPreparing.enterCancellationCode")}
                </p>
                <NsTextField label={CommonHelper.stringFormat(getTranslatedRawText("profile.orderPreparing.cancellationCode"), CommonHelper.getDefaultCurrency())}
                    valueChanged={this.valueChanged}
                    autoFocus={true}
                    value={cancellationCode}
                    showValidationDate={showValidationDate}
                    className="w-100 " />
                <div>
                    <NsButton label='adminPanel.common.invoice'
                        startIcon='ns-icon-pdf-file'
                        size='small'
                        className='secondary-outline w-auto mt-2 '
                        disabled={!this.props.hasInvoiceNumber}
                        onClick={this.invoiceClicked}
                        loading={isLoading} />
                    {!this.props.hasInvoiceNumber &&
                        <NsTypography variant='inherit' className='red-color font-size-12'>{getTranslatedRawText('profile.detail.invoiceNumberIsMissing')}</NsTypography>
                    }
                </div>
                {resultMessageClass}
            </NsModal >
        );
    }
}

export default React.memo(OrderWithInvoiceCancellationModal);