import React from 'react';

import NsDataGrid from '../../../../components/grids/NsDataGrid';
import NsImage from '../../../../components/images/NsImage';
import NsMessageClassModal from '../../../../components/modals/NsMessageClassModal';
import NsTypography from '../../../../components/text/NsTypography';
import Enumerations from '../../../../configs/Enumerations';
import { GetOrderDetailService } from '../../../../services/ProfileSetting/UserOrderService';
import { GetProductBasicPriceService } from '../../../../services/order/OrderService';
import { DeleteOrderDetailService } from '../../../../services/order/ProfessionalOrderService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import OrderHelper from '../../../../utilities/OrderHelper';
import OrderDetailPreviewModal from '../../../profileSettings/modals/orderDetailPreview/OrderDetailPreviewModal';
import PoChangeSeries from './PoChangeSeries';

class PoOrderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                field: '',
                headerName: getTranslatedRawText('profile.dataGridColumns.action'),
                width: 110,
                resizable: false,
                lockPosition: true,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    hasDetail: true,
                    hasDelete: true,
                    detailClicked: (id) => this.orderDetailPreviewClicked(id),
                    deleteClicked: (id) => this.orderDeleteClicked(id),
                }
            },
            {
                field: 'frontImageUrl',
                headerName: getTranslatedRawText('profile.dataGridColumns.frontImage'),
                width: 110,
                resizable: false,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (props) => <NsImage className="w-100"
                        imgAlt="components.imagesAlt.orderFrontSide"
                        imageUrl={props.frontImageUrl} />
                },
            },
            OrderHelper.getPrintedSide(this.props.routeId)?.key === Enumerations.printedSide.twoSide
                ? {
                    field: 'backImageUrl',
                    headerName: getTranslatedRawText('profile.dataGridColumns.backImage'),

                    width: 110,
                    resizable: false,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <NsImage className="w-100"
                            imgAlt="components.imagesAlt.orderBackSide"
                            imageUrl={props.backImageUrl} />
                    },
                }
                : { hide: true },

            {
                field: 'size',
                headerName: getTranslatedRawText('profile.dataGridColumns.dimension'),
                width: 180,
            },
            {
                field: 'basePriceMultiplyInLatCount',
                headerName: getTranslatedRawText('profile.dataGridColumns.rate'),
                width: 90,
                dataType: Enumerations.gridType.price,
            },
            {
                field: 'extendedServicePrice',
                headerName: getTranslatedRawText('profile.dataGridColumns.servicePrice'),
                width: 150,
                dataType: Enumerations.gridType.price,
            },
            {
                field: 'series',
                headerName: getTranslatedRawText('profile.dataGridColumns.series'),
                dataType: Enumerations.gridType.number,
                width: 290,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (props) => <PoChangeSeries className="w-100"
                        series={props.series}
                        sharedSheetOrderDetailId={props.id}
                        refreshGrid={this.refreshGrid} />
                },
            },
            {
                field: 'totalPrice',
                headerName: getTranslatedRawText('profile.dataGridColumns.totalPrice'),
                width: 120,
                dataType: Enumerations.gridType.price,
            },
        ];
        this.state = {
            refreshOrderDetailDate: props.refreshOrderDetailDate,
            selectedItemDetailId: '',
            rows: [],
            isLoading: false,
            isShowOrderDetailPreviewModal: false,
            isShowDeleteOrderModal: false,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => this.getOrderDetails(this.props.routeId);

    componentDidUpdate = (prevProps) => {
        if (prevProps.refreshOrderDetailDate !== this.state.refreshOrderDetailDate)
            this.getOrderDetails(this.props.routeId);
    }

    refreshGrid = () => {
        this.setState({ rows: [] }
            , () => {
                CommonHelper.safeFunctionCall(this.props.refreshSummary);
                this.getOrderDetails(this.props.routeId)
                this.getAvailableSize()
            })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshOrderDetailDate !== prevState.refreshOrderDetailDate)
            return ({ refreshOrderDetailDate: nextProps.refreshOrderDetailDate });
        return null;
    }

    getOrderDetails = (routeId) => {
        let orderId = OrderHelper.getOrderId(routeId);
        if (!!orderId) {
            this.setState({ isLoading: true });
            GetOrderDetailService({ id: orderId }, this.getOrderDetailCallback);
        }
    }

    getOrderDetailCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.setState({ rows: resultData });
            CommonHelper.safeFunctionCall(this.props.setGrandTotal,
                resultData.reduce((sum, { totalPrice }) => sum + totalPrice, 0), !!resultData?.length);
        } else {
            this.setState({ resultMessageClass: messageClassModal });
        }
    }

    orderDetailPreviewClicked = (id) => this.setState({ selectedItemDetailId: id, isShowOrderDetailPreviewModal: true });

    orderDeleteClicked = (id) => this.setState({ selectedItemDetailId: id, isShowDeleteOrderModal: true });

    closeOrderDetailPreviewModal = () => this.setState({ isShowOrderDetailPreviewModal: false });

    closeDeleteModal = (confirm) => {
        this.setState({ isShowDeleteOrderModal: false });
        if (!!confirm) {
            this.setState({ rows: [] });
            DeleteOrderDetailService(this.state.selectedItemDetailId, this.deleteOrderDetailCallback, true);
        }
    }

    deleteOrderDetailCallback = (resultData, result, messageClassModal) => {
        this.setState({ resultMessageClass: messageClassModal });
        this.getOrderDetails(this.props.routeId);
        this.getAvailableSize();
    }

    getAvailableSize = () => {
        const data = {
            orderId: OrderHelper.getOrderId(this.props.routeId),
            productId: CommonHelper.toInt(this.props.routeId),
            frontFileId: null,
            series: 1,
            turnaroundType: OrderHelper.getTurnaround(this.props.routeId)?.key,
            twoSidePrintingType: OrderHelper.getPrintedSide(this.props.routeId)?.key,
            currentFileId: OrderHelper.getFrontSideMasterFiles(this.props.routeId)?.id
        }
        GetProductBasicPriceService(data, this.getProductBasicPriceCallback);
    }

    getProductBasicPriceCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            OrderHelper.setAvailableSizes(this.props.routeId, resultData);
            CommonHelper.safeFunctionCall(this.props.refreshSummary);
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        const { isShowOrderDetailPreviewModal, isShowDeleteOrderModal, isLoading, rows, selectedItemDetailId } = this.state;

        return (
            <div className='h-100 professionalOrder-tour-currentList'>
                <div className='d-flex justify-content-between mb-2'>
                    <NsTypography variant='inherit' className='section-title d-none d-md-flex'>
                        {getTranslatedRawText('regularOrder.confirmDetail.currentList')}
                    </NsTypography>
                </div>

                <NsDataGrid totalRows={rows.length}
                    isLoading={isLoading}
                    columnDefs={this.columns}
                    refreshButtonClicked={() => this.getOrderDetails(this.props.routeId)}
                    rows={rows} />

                {
                    isShowOrderDetailPreviewModal &&
                    <OrderDetailPreviewModal showModal={isShowOrderDetailPreviewModal}
                        orderId={selectedItemDetailId}
                        closeModal={this.closeOrderDetailPreviewModal} />
                }

                {
                    isShowDeleteOrderModal &&
                    <NsMessageClassModal show={isShowDeleteOrderModal}
                        orderId={selectedItemDetailId}
                        messageItems={[{
                            messageText: getTranslatedRawText('common.messages.areYouSureQuestionWithOutParams'),
                            messageType: Enumerations.messageType.warning
                        }]}
                        onClose={this.closeDeleteModal} />
                }

                {this.state.resultMessageClass}
            </div>
        );
    }
}

export default PoOrderDetail;