import Enumerations from "../../configs/Enumerations";
import { getCurrentCulture, isEnCulture } from "../../utilities/CultureHelper";
import { checkIsValidEnumKeyFromValue } from "../../utilities/EnumerationHelper";
import StorageHelper from "../../utilities/StorageHelper";

export const getApiUrlWithCurrentCulture = (parentObject, url, parameters) => {
    if (!checkIsValidEnumKeyFromValue(parentObject, url))
        return 'Invalid Url!!!';
    const currentCurrency = StorageHelper.getFromStorage(Enumerations.storage.userCurrency)?.currentCurrency?.abbreviation


    let query = '?';
    if (parameters != null)
        query += Object.keys(parameters).map(key => key + '=' + parameters[key]).join('&');

    let country = isEnCulture() ? 'US' : 'IR';
    let prefixUrl = getCurrentCulture() + '-' + country

    if (!!currentCurrency) prefixUrl = prefixUrl + '-' + currentCurrency;

    return '/' + url.replace("{culture}", prefixUrl) + query;
}