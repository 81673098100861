import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PostRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';

const GetProfileFieldVisibilityTypeService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getProfileFieldVisibilityType), parameters, callback);
}

const GetPersonalSettingService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getPersonalSetting), {}, callback);
}

const EditPersonalSettingService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.editPersonalSetting), data, callback, showSuccessResponseMessage);
}

const EditOfficialInvoiceStatusService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.editOfficialInvoiceStatus), data, callback, showSuccessResponseMessage);
}

const GetExpireUserTokenService = (callback, showSuccessResponseMessage) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getDisableToken), {}, callback, showSuccessResponseMessage);
}

const ChangePasswordService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.changePassword), data, callback, showSuccessResponseMessage);
}

const GetNotificationsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getNotifications), {}, callback);
}

const EditNotificationsService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.editNotifications), data, callback, showSuccessResponseMessage);
}

const GetAgenciesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getAgencies), {}, callback);
}

const GetAgencyDetailService = (id, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getAgencyDetail), { id }, callback);
}

const GetCoachMarksStatusService = (params, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getCoachMarksStatus), params, callback);
}

const EditCoachMarksStatusService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.editCoachMarksStatus), data, callback, showSuccessResponseMessage);
}

const SetAgencyService = (id, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.postSetAgency), { id }, callback, showSuccessResponseMessage);
}

const GetPublicProfileService = (params, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getPublicProfile), params, callback);
}

export {
    GetProfileFieldVisibilityTypeService,
    GetPersonalSettingService,
    EditPersonalSettingService,
    EditOfficialInvoiceStatusService,
    GetExpireUserTokenService,
    ChangePasswordService,
    GetNotificationsService,
    EditNotificationsService,
    GetAgenciesService,
    GetAgencyDetailService,
    SetAgencyService,
    GetCoachMarksStatusService,
    EditCoachMarksStatusService,
    GetPublicProfileService
}