import clsx from 'clsx';
import React from 'react';

import { GetNotAppliedStoreOrdersService } from '../../../../../../services/ProfileSetting/StoreOrderService';
import UpStoreNotAppliedOrderDetailModal from './UpStoreNotAppliedOrderDetailModal';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import NsCollapse from '../../../../../../components/dropdowns/NsCollapse';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import UpBaseStoreOrdersGrid from '../bases/UpBaseStoreOrdersGrid';

class UpStoreNotAppliedOrders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showDetailModal: false,
            orderId: null,
            callServiceDate: null,
            resultMessageClass: (<></>)
        };
    }

    getData = (data) => this.setState({ data });

    detailViewClicked = (id) => this.setState({ showDetailModal: true, orderId: id });

    closeDetailModalClicked = (isRefreshData, resultMessageClass) => {
        this.setState({ showDetailModal: false });
        isRefreshData && this.setState({ callServiceDate: new Date(), resultMessageClass });
    }

    render() {
        const { data, showDetailModal, orderId, callServiceDate, resultMessageClass } = this.state;

        return (
            <div className={clsx('border rounded-3 p-3', data?.length > 0 ? '' : 'invisible')} >
                <NsCollapse title={getTranslatedRawText('profile.orderInProcess.inprocessPayments')}
                    defaultIsOpen={true}
                    icon='ns-icon-information text-color fs-5'>

                    <div className='w-100 mt-3'>
                        <UpBaseStoreOrdersGrid service={GetNotAppliedStoreOrdersService}
                            hasPagination={true}
                            callServiceDate={callServiceDate}
                            detailViewClicked={this.detailViewClicked}
                            getData={this.getData}
                            gridId={GridEnumerations.siteGrids.inProcessPaymentStoreOrders}
                            hasCustomColumnOrder={true} />
                    </div >

                </NsCollapse >

                {showDetailModal && <UpStoreNotAppliedOrderDetailModal orderId={orderId}
                    closeModal={this.closeDetailModalClicked} />}

                {resultMessageClass}
            </div >
        );
    }
}

export default UpStoreNotAppliedOrders;

