import React, { useEffect, useState } from 'react';

import { GetProductsCirculationsService } from "../../../../services/product/ProductService";
import { categoryActionCreators } from '../../../../redux/reducers/CategoryReducer';
import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../bases/ApBaseSearchButtons';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import { useDispatch, useSelector } from 'react-redux';

const ApProductContentSearch = (props) => {

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsId, setSelectedProductsId] = useState([]);
    const [products, setProducts] = useState([]);
    const [productName, setProductName] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [productCategories, setProductCategories] = useState([]);
    const [resultMessageClass, setResultMessageClass] = useState('');

    const dispatch = useDispatch();

    const handleCategories = () => dispatch(categoryActionCreators.getCategories());

    useEffect(() => {
        handleCategories();
    }, []);

    const categories = useSelector(state => {
        return {
            categories: state.categories.categories
        }
    });

    const getProductsCirculationsCallback = (resultData, result) => {
        setIsLoading(false);
        if (!result?.data?.hasError) setProducts(resultData);
    };

    useEffect(() => {
        setIsLoading(true);
        GetProductsCirculationsService(null, getProductsCirculationsCallback);
    }, [])

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptionsInMobile = [
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('profile.allOrders.productCategory'),
            fieldName: 'productCategories',
            value: productCategories,
            items: categories.categories || [],
            onChange: (value) => setProductCategories(value)
        },
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.product'),
            fieldName: 'selectedProducts',
            value: selectedProducts,
            items: !isEmpty(products) ? products : [],
            onChange: (value) => {
                setSelectedProducts(value);
                setSelectedProductsId(!isEmpty(value?.circulations) ? value?.circulations[0].key : null);
            }
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.productName'),
            fieldName: 'productName',
            value: productName,
            onChange: (value) => setProductName(value),
        }
    ];

    const searchClicked = () => {
        let data = {
            selectedProductsId,
            productName,
            productCategories,
        }
        CommonHelper.safeFunctionCall(props.setSearchData, data);
    }

    const clearButtonClicked = () => {
        setSelectedProducts([]);
        setSelectedProductsId([]);
        setProductCategories([]);
        setProductName('');
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    const searchValues = {
        products,
        selectedProducts,
        selectedProductsId,
        productCategories,
        productName
    }

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={CommonHelper.isMobileDevice()
                    ? basicSearchOptionsInMobile
                    : basicSearchOptions}
                    searchValues={searchValues} mdCol={2} smCol={4} />
                <ApBaseSearchButtons searchClicked={searchClicked}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={isLoading} />
            </div>

            {resultMessageClass}
        </>
    )

}

export default React.memo(ApProductContentSearch);