import React from 'react';
import { Col } from 'react-bootstrap';
import NsCard from '../../../components/cards/NsCard';
import NsTypography from '../../../components/text/NsTypography';
import Config from '../../../configs/Config';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import ImageHelper from '../../../utilities/ImageHelper';

class SpSidebarSocialMedia extends React.Component {

    constructor(props) {
        super(props);
        this.whatsAppLink = (!!Config.socialMedia.whatsApp && !!Config.whatsApPhoneNumber) && CommonHelper.stringFormat(Config.socialMedia.whatsApp, Config.whatsApPhoneNumber);
        this.instagramLink = Config.socialMedia.instagram;
        this.facebookLink = Config.socialMedia.facebook;
    }

    render() {
        return (
            <NsCard className='p-3'>
                <div className=' mb-3'>
                    <NsTypography variant='inherit' className='section-title'>
                        {getTranslatedRawText('footer.followUs')}
                    </NsTypography>
                </div>
                <Col xs={8} xl={6}>
                    {!!this.facebookLink &&
                        <a target="_blank" rel="nofollow" href={this.facebookLink}>
                            <img className="facebook mx-1"
                                src={ImageHelper.blankSrc()}
                                alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.socialMediaLogo"), getTranslatedRawText("common.socialChannels.facebook"))} />
                        </a>
                    }
                    {!!this.instagramLink &&
                        <a target="_blank" rel="nofollow" href={this.instagramLink}>
                            <img className="instagram mx-1"
                                src={ImageHelper.blankSrc()}
                                alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.socialMediaLogo"), getTranslatedRawText("common.socialChannels.instagram"))} />
                        </a>
                    }
                    {!!this.whatsAppLink &&
                        <a target="_blank" rel="nofollow" href={this.whatsAppLink}>
                            <img className="whatsapp mx-1"
                                src={ImageHelper.blankSrc()}
                                alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.socialMediaLogo"), getTranslatedRawText("common.socialChannels.whatsapp"))} />
                        </a>
                    }
                </Col>
            </NsCard>
        );

    }
}

export default SpSidebarSocialMedia;