import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import NsButton from '../../../../components/buttons/NsButton';
import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsImage from '../../../../components/images/NsImage';
import NsAlertModal from '../../../../components/modals/NsAlertModal';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { DeleteApFileManagerFilesService, GetApFileManagerFilesService } from '../../../../services/adminPanel/AdminFileUploaderService';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isValidImgExtension } from '../../../../utilities/ValidationHelper';
import ApFileManagerSearch from './ApFileManagerSearch';
import FmUploadFileModal from '../../../fileManager/modals/FmUploadFileModal';
import { createAdminUploaderTreeList } from '../../../fileManager/bases/CreateTreeViewJson';


const ApFileManager = (props) => {

    const columns = [
        hasAccess([UserClaimEnumerations.deleteFileUploader]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (file) => {
                    return <NsButton className='secondary-text px-0'
                        startIcon='ns-icon-delete cursor-pointer'
                        onClick={() => fileDeleteClicked(file)} />
                }
            }
        },
        {
            field: 'filePreview',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.filePreview'),
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (file) => {
                    return isValidImgExtension(file.fileExtension)
                        ? <NsImage src={CommonHelper.createImageUrl(file.fullPath)}
                            width='130'
                            imgAlt="components.imagesAlt.imageThumbnail" />
                        : <div className={`ns-icon-${ConstantsWithTranslation.getFileExtensionTypeById(file.fileExtension)}-file h1 mx-auto`} />

                }
            }
        },
        {
            field: 'name',
            headerName: getTranslatedRawText('adminPanel.customersFiles.fileName'),
            width: 150,
        },
        {
            field: 'extension',
            headerName: getTranslatedRawText('adminPanel.customersFiles.fileFormat'),
            width: 100,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (row) => {
                    return <span>{ConstantsWithTranslation.getFileExtensionTypeById(row.fileExtension)}</span>
                }
            }
        },
        {
            field: 'volume',
            headerName: getTranslatedRawText('adminPanel.customersFiles.fileSize'),
            width: 100,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <span>{CommonHelper.convertByteToMegaByte(props.volume)} {getTranslatedRawText('common.mb')}</span>
                }
            }
        },
        {
            field: 'fullPath',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fileUrl'),
            width: 550,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (row) => {
                    return <div onClick={() => handleCopyClick(CommonHelper.createImageUrl(row.fullPath))}
                        className='d-flex justify-content-center cursor-pointer '>
                        <i className='ns-icon-copy h4 mx-2' />
                        <span>{CommonHelper.createImageUrl(row.fullPath)}</span>
                    </div>
                }
            }
        },
        {
            field: 'used',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.used'),
            width: 120,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (row) => {
                    return <span>{row.isInUse}</span>
                }
            }
        },
    ];

    const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
    const [searchData, setSearchData] = useState(props.searchData);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showFmUploadFileModal, setshowFmUploadFileModal] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const store = useSelector(state => {
        return {
            currentUploadFolderId: state.fileManager.currentUploadFolderId,
        }
    });

    useEffect(() => {
        setCallServiceDate(new Date());
    }, [searchData])

    const handleCopyClick = (path) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(path);
        }
    }

    const deleteFileHandler = (isAccept) => {
        if (isAccept) {
            setIsLoading(true);
            DeleteApFileManagerFilesService({
                fileUrl: selectedFile.fullPath
            }, deleteApFileManagerFilesCallback, true);
        } else
            setShowDeleteFileModal(false);
    }

    const deleteApFileManagerFilesCallback = (resultData, result, messageClassModal) => {
        setShowDeleteFileModal(false);
        if (!result.data.hasError)
            setCallServiceDate(new Date());
        else {
            setIsLoading(false);
        }
        setResultMessageClass(messageClassModal);
    }

    const fileDeleteClicked = (file) => {
        setSelectedFile(file);
        setShowDeleteFileModal(true);
    }

    const searchDataHandler = (data) => {
        setSearchData(data);
        setIsLoading(true);
    }

    const closeUploadModal = () => {
        setshowFmUploadFileModal(false);
        setCallServiceDate(new Date());
    }


    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.fileManager")}
            actionButton={{
                onClick: () => setshowFmUploadFileModal(!showFmUploadFileModal),
                isLoading: isLoading,
                disabled: !hasAccess([UserClaimEnumerations.postFileUploader])
            }}
            icon={'ns-icon-open-folder h1'}>

            <ApFileManagerSearch setSearchData={searchDataHandler}
                isLoading={isLoading} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetApFileManagerFilesService}
                parameters={{
                    'Filter.Name': searchData?.fileName,
                    'Filter.FileExtensions': searchData?.fileFormat?.key
                }}
                columnDefs={CommonHelper.clearArrayFromEmptyItem(columns)}
                hasPagination={true}
                getData={getData} />

            {showDeleteFileModal &&
                <NsAlertModal isOpen={showDeleteFileModal}
                    activeEnter={true}
                    cancelClicked={() => deleteFileHandler(false)}
                    confirmClicked={() => deleteFileHandler(true)} >
                    {CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), selectedFile.name)}
                </NsAlertModal>}

            {!!showFmUploadFileModal &&
                <FmUploadFileModal showModal={showFmUploadFileModal}
                    treeViewFolders={createAdminUploaderTreeList()}
                    currentFolderId={store.currentUploadFolderId}
                    onClose={closeUploadModal}
                    // refresh={searchClicked}
                    isAdminPanelFileManager={true} />
            }

            {resultMessageClass}

        </NsAdminPanelContentWrapper>
    )
}

export default React.memo(ApFileManager);