import React, { Component } from 'react';

import '../../../../assets/sass/views/orders/professionalOrder.scss';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsModal from '../../../../components/modals/NsModal';
import { GetFoldLinesService } from '../../../../services/ProfileSetting/UserOrderService';
import FoldLinesContent from './FoldLinesContent';

class FoldLineModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resultData: null,
            isLoading: true,
            resultMessageClass: null,
        }
    }

    setRatio = (ratio) => GetFoldLinesService({ id: this.props.id }, this.getFoldLinesCallback(ratio));

    getFoldLinesCallback = (ratio) => (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.setState({
                resultData: {
                    ...resultData,
                    ratio
                },
                isLoading: false
            });
        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    render() {

        const { resultData, isLoading, resultMessageClass } = this.state;
        const { draft, resolution } = this.props;

        return (
            <NsModal showModal={true}
                maxWidth="md"
                closeModal={this.props.closeModal}
                className='fold-line-modal'
                dialogClassName="modal-choose-printing-files"
                dialogTitle={getTranslatedRawText('regularOrder.modal.foldLineTitle')}>

                <FoldLinesContent resultData={resultData}
                    draft={draft}
                    resolution={resolution}
                    isLoading={isLoading}
                    setRatio={this.setRatio} />

                {resultMessageClass}

            </NsModal >
        )
    }
}

export default FoldLineModal;