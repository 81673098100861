import React from 'react';
import { withRouter } from 'react-router-dom';

import NsToggleGroupButton from '../../../components/buttons/NsToggleGroupButton';
import NsGroupNumberInput from '../../../components/inputs/NsGroupNumberInput';
import NsTypography from '../../../components/text/NsTypography';
import Config from '../../../configs/Config';
import Links from '../../../configs/links/AllLinks';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import DelayedTaskHelper from '../../../utilities/DelayedTaskHelper';
import UrlHelper from '../../../utilities/UrlHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsButton from '../../../components/buttons/NsButton';
import NsTooltip from '../../../components/tooltips/NsTooltip';

class PdBaseQuantity extends React.Component {

    constructor(props) {
        super(props);
        this.minQuantityValue = Config.minOrderQuantityValue;
        this.state = {
            series: props.series,
            ...this.getCirculationFromProps(props),
            isTooltipOpen: false,
        };
        this.delayInQuantityRequest = new DelayedTaskHelper(() => {
            this.quantityDelayCallback();
        }, 300);
    }

    getCirculationFromProps = (props) => {
        return {
            circulations: props.circulations,
            selectedCirculation: props.selectedCirculation,
            bestCirculation: props.selectedCirculation.value,
            quantity: isNaN(props.selectedCirculation?.value * this.state?.series) ? null : props.selectedCirculation?.value * this.state?.series,
            minCirculation: Math.min(...(props.circulations?.map(item => item.value) ?? []))
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.circulations !== this.props.circulations || prevProps.selectedCirculation !== this.props.selectedCirculation)
            this.setState({
                ...this.getCirculationFromProps(this.props),
            });
    }

    getBestCirculation = (quantity) => {
        const values = this.state.circulations.map(item => {
            if (quantity % item.value === 0)
                return item.value;
        }).filter(item => !!item);
        const bestCirculation = !!Math.max(...values) && values.length > 0 ? Math.max(...values) : null
        this.setState({ bestCirculation }
            , () => CommonHelper.safeFunctionCall(this.props.bestCirculationChanged, this.state.bestCirculation, this.state.quantity)
        );

        return bestCirculation;
    }

    seriesChanged = (value, isValid) => {
        let absValue = isEmpty(value) ? null : CommonHelper.toInt(Math.abs(value));
        this.getBestCirculation(Math.abs(absValue * this.state.selectedCirculation.value));
        this.setState({ series: absValue, quantity: Math.abs(absValue * this.state.selectedCirculation.value) },
            () => CommonHelper.safeFunctionCall(this.props.seriesChanged, this.state.series, true));
    }

    circulationClicked = (selectedItem) => {
        this.props.isUsedInPo
            ? this.props.history.push(CommonHelper.stringFormat(
                getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.productDetails),
                selectedItem.key).toLowerCase())
            : this.props.history.push(UrlHelper.createPdRegularDetailUrl(null, selectedItem.key, this.props.productName));

        if (selectedItem !== this.state.selectedCirculation) {
            this.setState({ selectedCirculation: selectedItem },
                this.delayInQuantityRequest.run()
            );
        }
        else
            CommonHelper.safeFunctionCall(this.props.seriesChanged, this.state.series, true);
    }

    quantityChanged = (value, isValidValue) => {
        let absValue = isEmpty(value) ? null : CommonHelper.toInt(Math.abs(value));
        this.setState({ quantity: absValue });
        let bestCirculation = this.getBestCirculation(absValue);
        if (!!bestCirculation && absValue > this.minQuantityValue) {
            let newCirculation = this.state.circulations.filter(item => item.value === bestCirculation)[0];
            this.setState({ series: absValue / bestCirculation, },
                () => {
                    CommonHelper.safeFunctionCall(this.props.seriesChanged, this.state.series, false);
                    this.circulationClicked(newCirculation);
                });

        }
    }

    quantityDelayCallback = () => {
        CommonHelper.safeFunctionCall(this.props.circulationChanged, this.state.selectedCirculation);
    }

    render() {
        const { minCirculation, quantity, series, isTooltipOpen } = this.state
        return (
            <>
                <div className='d-flex align-items-center flex-wrap'>

                    {!this.props.isUsedInPo &&
                        <div className='d-flex align-items-center'>
                            <NsGroupNumberInput label={getTranslatedRawText('product.detail.quantity')}
                                className='mb-2'
                                autoFocus={true}
                                value={quantity}
                                inputProps={{ min: minCirculation }}
                                step={minCirculation}
                                onChanged={this.quantityChanged} />
                            <NsTypography className="mx-3 mt-3">&#61;</NsTypography>
                        </div>
                    }
                    <div className='mt-3'>
                        <NsToggleGroupButton isShowGroupLabel={!!this.props.isUsedInPo}
                            label={getTranslatedRawText('product.detail.circulation')}
                            isVertical={!!CommonHelper.isMobileDevice() && !this.props.isUsedInPo}
                            onClick={this.circulationClicked}
                            selectedItemId={this.state.selectedCirculation.key}
                            items={this.state.circulations} />
                    </div>
                    {!this.props.isUsedInPo &&
                        <>
                            <NsTypography className="mx-3 mt-3">&times;</NsTypography>
                            <div className='d-flex align-items-center'>
                                <NsGroupNumberInput label={getTranslatedRawText('product.detail.series')}
                                    className='mb-2'
                                    value={series}
                                    maxLength={8}
                                    minusButtonDisabled={series <= 1}
                                    onChanged={this.seriesChanged} />
                                {!this.props.isUsedInPo && <NsTooltip open={isTooltipOpen}
                                    title={getTranslatedRawText('product.detail.quantityHelper')}
                                    closeTooltip={() => this.setState({ isTooltipOpen: false })}>
                                    <NsButton className='secondary-text masterFileModal-tour-preview'
                                        startIcon='ns-icon-question'
                                        onClick={() => this.setState({ isTooltipOpen: !this.state.isTooltipOpen })} />
                                </NsTooltip>}
                            </div>
                        </>
                    }

                </div>
                <NsTypography variant='inherit' className='font-size-12 red-color mt-3'>{(!this.state.bestCirculation || this.state.quantity === 0) && getTranslatedRawText('regularOrder.messages.quantityError')}</NsTypography>
            </>
        );
    }
}

export default withRouter(PdBaseQuantity);