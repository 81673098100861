import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

const NsTooltip = ({ title, children, placement = 'bottom' }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Tooltip
      title={title}
      placement={placement}
      open={open}
      onClose={handleTooltipClose}
      disableFocusListener
      disableTouchListener
    >
      <span
        onClick={handleClick}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        style={{ display: 'inline-flex', cursor: 'pointer' }}
      >
        {children}
      </span>
    </Tooltip>
  );
};
export default NsTooltip;
