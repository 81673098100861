import { getEnumKeyFromValue } from './EnumerationHelper'
import Enumerations from '../configs/Enumerations'
import CommonHelper from './CommonHelper';
import { getCurrentCulture, getUrlWithCurrentCulture } from './CultureHelper';
import { isEmpty, isValidExternalUrl } from './ValidationHelper';
import Config from '../configs/Config';
import Links from '../configs/links/AllLinks';

class UrlHelper {

    static getRouteValue = (props, routeKey) => {
        let key = getEnumKeyFromValue(Enumerations.routeKey, routeKey)
        const { match: { params } } = props;
        return params && params[key];
    }

    static createRouteUrl = (props, parentLink, link, routeKey,) => {
        return CommonHelper.stringFormat(
            getUrlWithCurrentCulture(parentLink, link),
            UrlHelper.getRouteValue(props, routeKey));
    }

    static createRouteUrlWithTwoValue = (props, parentLink, link, routeKey, param) => {
        return CommonHelper.stringFormat(
            getUrlWithCurrentCulture(parentLink, link),
            UrlHelper.getRouteValue(props, routeKey),
            param,
        );
    }

    static isCurrentUrl = (pageName) => {
        return window.location.href.indexOf(pageName) > 0;
    }

    static getRouteParameters = location => Object.fromEntries(new URLSearchParams(location));

    static setRouteParameters = (props, obj, pathname, hash) => {
        const searchParams = new URLSearchParams(CommonHelper.cleanObject(obj)).toString();
        props.history.push({
            pathname: !isEmpty(pathname) ? pathname : props.location.pathname,
            search: searchParams,
            hash: !isEmpty(hash) ? hash : '',
        });
    }

    static addWwwToRoute = () => {
        if (!Config.redirectToWww) return;
        let route = window.location.href;
        if (route.includes('www')) return;
        let temp = route.split('//');
        if (temp.length === 2) {
            window.location.assign(temp[0] + '//www.' + temp[1])
        }
        return;
    }

    static linkUrlHandler = (url, host) => {
        url = url.replaceAll(' ', '%20');
        url = url.startsWith('/')
            ? url.slice(1, url.length)
            : url.split(`${host}/${getCurrentCulture()}/`).pop()

        if (isValidExternalUrl(url)) {
            return {
                url: { pathname: url },
                target: url.includes(host) ? "_top" : "_blank"
            }
        } else {
            return {
                url: url,
                target: "_self"
            }
        }
    }

    static createCorrectRouteAndUrl = (route) => !isEmpty(route)
        ? route.trim().replace(/\s+/g, '-').toLowerCase()
        : "";

    static createPdRegularDetailUrl = (urlParameters, selectedId, productName) => {
        if (isEmpty(selectedId) || isEmpty(productName)) return;
        return CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.regularOrder, Links.view.main.regularOrder.productDetails, urlParameters),
            selectedId, CommonHelper.makeCorrectLink(productName)).toLowerCase()
    }

    static hashValidator = (array) => {
        if (array.length === 0 || isEmpty(location.hash)) return;
        const currentHash = decodeURIComponent(window.location.hash.slice(1));
        const matchingItem = array.find(item => CommonHelper.makeCorrectLink(item) === currentHash);

        return CommonHelper.makeCorrectLink(matchingItem) || '';
    };

    static getUserOrderFeedbackUrl = (showFeedbackModal, breadcrumbs) => {
        switch (breadcrumbs) {
            case Enumerations.breadCrumbs.orderPreparing: return showFeedbackModal ? Links.view.main.userOrder.preparingDetailFeedback : Links.view.main.userOrder.preparingDetail;
            case Enumerations.breadCrumbs.orderInProcess: return showFeedbackModal ? Links.view.main.userOrder.inProcessOrderDetailFeedback : Links.view.main.userOrder.inProcessOrderDetail;
            case Enumerations.breadCrumbs.orderReady: return showFeedbackModal ? Links.view.main.userOrder.readyToDeliverOrderDetailFeedback : Links.view.main.userOrder.readyToDeliverOrderDetail;
            case Enumerations.breadCrumbs.orderArchive: return showFeedbackModal ? Links.view.main.userOrder.archiveOrderDetailFeedback : Links.view.main.userOrder.archiveOrderDetail;
            case Enumerations.breadCrumbs.orderAll:
            default: return showFeedbackModal ? Links.view.main.userOrder.allOrdersDetailFeedback : Links.view.main.userOrder.allOrdersDetail;
        }
    }

    static checkUrlIsOrderDetailFeedback = (pathname) => {
        pathname = pathname.split('/');
        let orderId = pathname.includes('feedback') ? Number(pathname[pathname.length - 3]) : Number(pathname[pathname.length - 2])
        return ({
            orderId,
            isOrderDetail: !!orderId && pathname.includes('detail'),
            isOrderDetailFeedback: !!orderId && pathname.includes('detail') && pathname.includes('feedback')
        });

    }
}

export default UrlHelper;