import React, { useEffect, useState } from "react";

import { GetApDauPaymentGatewaysMessagesService } from "../../../../services/adminPanel/AdminDashboardService";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsSkeleton from "../../../../components/skeletons/NsSkeleton";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsCard from "../../../../components/cards/NsCard";
import ApPaymentAuditModal from "../../managements/payments/audits/ApPaymentAuditModal";

const ApDauPaymentErrorTypes = (props) => {

    const { refreshDate, parameters } = props;
    const [isLoading, setIsloading] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState();
    const [errorTypes, setErrorTypes] = useState([]);
    const [showApPaymentAuditModal, setShowApPaymentAuditModal] = useState(false);
    const [transactionStatusMessage, setTransactionStatusMessage] = useState('');

    const toggleShowApPaymentAuditModal = () => setShowApPaymentAuditModal(!showApPaymentAuditModal);
    const transactionMessageClicked = (message) => {
        toggleShowApPaymentAuditModal();
        setTransactionStatusMessage(message);
    };

    useEffect(() => {
        setIsloading(true);
        GetApDauPaymentGatewaysMessagesService(parameters, getApDauPaymentGatewaysMessagesCallback);
    }, [refreshDate])

    const getApDauPaymentGatewaysMessagesCallback = (resultData, result, messageClassModal) => {
        setIsloading(false);
        if (!result.data.hasError) {
            setErrorTypes(resultData);
        } else {
            setMessageClassModal(messageClassModal);
        }
    };

    const searchData = {
        transactionStatusMessage: transactionStatusMessage
    }

    return (
        <NsCard className='p-3 h-100'>
            <div className='page-title mb-2'>{getTranslatedRawText('adminPanel.dashboard.gatewayResponseTypes')}</div>
            <div className="d-flex flex-column">
                {isLoading
                    ? <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                        ]} />
                    : !isEmpty(errorTypes) && errorTypes.map((error, index) => (
                        <div key={index}
                            className="d-flex justify-content-start gap-2 border-bottom py-1 cursor-pointer"
                            onClick={() => transactionMessageClicked(error?.message)}>
                            <i className="ns-icon-information h4 mb-0" />
                            <span className="font-size-14">{error?.message}</span>
                            <span className="font-size-14 ms-auto">{error?.count}</span>
                        </div>
                    ))
                }
            </div>
            {messageClassModal}

            {showApPaymentAuditModal &&
                <ApPaymentAuditModal searchData={searchData}
                    showModal={showApPaymentAuditModal}
                    closeModal={toggleShowApPaymentAuditModal} />}
        </NsCard>
    );
};

export default React.memo(ApDauPaymentErrorTypes);