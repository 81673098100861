import React from 'react';
import SpBasesBanner from '../../bases/banner/SpBasesBanner';
import clsx from 'clsx';
import CommonHelper from '../../../../utilities/CommonHelper';
import { Col, Row } from 'react-bootstrap';
import NsCard from '../../../../components/cards/NsCard';

const SaveOutputInPdfFormat = () => {

    const isMobile = CommonHelper.isMobileDevice();

    const comparisonData = [
        {
            title: 'معایب فایل JPEG',
            content: [
                {
                    subject: 'از دست دادن کیفیت:',
                    description: 'فایل‌های JPEG از فشرده‌سازی با ضربه‌پذیری داده‌ها استفاده می‌کنند. این فشرده‌سازی می‌تواند باعث از دست رفتن جزئیات و کاهش کیفیت تصویر شود، به خصوص در صورت تکراری شدن عملیات ذخیره و بازیابی.'
                },
                {
                    subject: 'عدم پشتیبانی از شفافیت:',
                    description: 'فایل‌های JPEG نمی‌توانند شفافیت (transparency) را پشتیبانی کنند. این به این معنی است که اگر تصویر شامل بخش‌های شفاف است، در فرمت JPEG این بخش‌ها به صورت سفید یا رنگ پیش‌فرضی نمایش داده می‌شوند.'
                },
                {
                    subject: 'عدم حفظ جزئیات رنگ:',
                    description: 'با ادغام فشرده‌سازی داده‌های تصویر، فرمت JPEG می‌تواند باعث از دست رفتن جزئیات رنگی و تغییرات ناخواسته در تنظیمات رنگی شود. این ممکن است در نتیجه چاپ، تفاوت‌های قابل توجهی در رنگ‌ها و تنظیمات رنگی ایجاد کند.'
                },
                {
                    subject: 'عدم قابلیت برگشت به حالت اولیه:',
                    description: 'با ذخیره تصویر در فرمت JPEG، اطلاعات برخی پیکسل‌ها به طور غیرقابل بازیابی از بین می‌روند. این به معنی عدم قابلیت برگشت به حالت اولیه و ویرایش‌پذیری کامل تصویر است.'
                },
            ]
        },
        {
            title: 'مزایا فایل PDF',
            content: [
                {
                    subject: 'حفظ کیفیت بالا: ',
                    description: 'فرمت PDF به طور کامل حفظ کیفیت تصویر را ارائه می‌دهد. با استفاده از PDF، می‌توانید از جزئیات و تفاصیل طرح خود، شامل رنگ‌ها، نوارهای رنگی ریز و جزئیات دقیق دیگر، حفظ کنید.'
                },
                {
                    subject: 'حفظ قالب بندی و طرح صفحه:',
                    description: 'PDF قابلیت حفظ قالب بندی و طرح صفحه را دارد، به این معنی که طرح شما به همان شکل و قالب مورد نظر در هر سیستمی که باز می‌شود، نمایش داده می‌شود. این امر از اهمیت بالایی برخوردار است، زیرا اطمینان حاصل می‌شود که طرح شما در چاپ با دقت و صحیحی نمایش داده می‌شود.'
                },
                {
                    subject: 'قابلیت جستجو و قابلیت انتقال:',
                    description: 'PDF اجازه می‌دهد تا متن و اطلاعات طرح شما قابل جستجو و کپی شدن باشد. این امر برای اشتراک گذاری و تبادل طرح‌ها با دیگران بسیار مفید است. همچنین، فرمت PDF قابلیت انتقال و باز شدن در سیستم‌های مختلف را داراست، بدون آنکه ساختار و نمایش طرح را تغییر دهد.'
                },
                {
                    subject: 'امکانات امنیتی:',
                    description: 'PDF امکانات امنیتی متنوعی را برای حفاظت از طرح شما در اختیار قرار می‌دهد. می‌توانید رمز عبور برای محدود کردن دسترسی به فایل PDF قرار دهید، مجوزهای مشخصی برای چاپ، کپی و ویرایش تعیین کنید، و حتی امضای دیجیتال را به طرح خود اضافه کنید.'
                },
            ]
        }
    ];

    return (
        <div className='special-needs'>
            <div>
                <SpBasesBanner className={clsx('header-banner my-3', !isMobile && 'pb-5')}
                    title='How to save outputs in PDF format' />

                <Row className='flex-nowrap gap-3 p-0 m-0'>
                    {comparisonData.map(item => <Col md={6} className='p-0 m-0'>
                        <h5 className='fw-bold mb-3'>{item.title}</h5>
                        {item.content.map(child => <p className='font-size-14'>
                            <span className='me-2 fw-bold '>{child.subject}</span>
                            <span>{child.description}</span>
                        </p>)}
                    </Col>)}
                </Row>
            </div>
            <div>
                <Row className='flex-nowrap gap-3 p-0 m-0'>
                    <Col md={6} className='p-0 m-0'>
                        <SpBasesBanner className={clsx('header-banner', !isMobile && 'pb-5')}
                            title='How to save outputs in PDF format' />
                    </Col>
                    <Col md={6} className='p-0 m-0'>
                        <SpBasesBanner className={clsx('header-banner', !isMobile && 'pb-5')}
                            title='How to save outputs in PDF format' />
                    </Col>
                </Row>
            </div>
            <h5 className='fw-bold my-3'>چطوری تو هر برنامه خروجی درست بگیرم؟</h5>
            <div className='mb-3'>
                <Row className='flex-nowrap gap-3 p-0 m-0'>
                    <Col md={4} className='p-0 m-0'>
                        <NsCard className='p-3'>
                            <div className='pb-3 pt-0 text-center'>Adobe illastrator</div>
                            <SpBasesBanner className={clsx('rounded quality-banner', !isMobile && 'pb-5')} />
                            <SpBasesBanner className={clsx('mt-3 rounded header-banner', !isMobile && 'pb-5')}
                                title='How to save outputs in PDF format' />
                        </NsCard>
                    </Col>
                    <Col md={4} className='p-0 m-0'>
                        <NsCard className='p-3'>
                            <div className='pb-3 pt-0 text-center'>Adobe photo shop</div>
                            <SpBasesBanner className={clsx('rounded quality-banner', !isMobile && 'pb-5')} />
                            <SpBasesBanner className={clsx('mt-3 rounded header-banner', !isMobile && 'pb-5')}
                                title='How to save outputs in PDF format' />
                        </NsCard>
                    </Col>
                    <Col md={4} className='p-0 m-0'>
                        <NsCard className='p-3'>
                            <div className='pb-3 pt-0 text-center'>Corel</div>
                            <SpBasesBanner className={clsx('rounded quality-banner', !isMobile && 'pb-5')} />
                            <SpBasesBanner className={clsx('mt-3 rounded header-banner', !isMobile && 'pb-5')}
                                title='How to save outputs in PDF format' />
                        </NsCard>
                    </Col>
                </Row>
            </div>
        </div >
    );
};

export default SaveOutputInPdfFormat;