import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { GetAgencyActiveStatesService } from '../../../../services/adminPanel/AdminDashboardService';
import { getTranslatedRawText, isEnCulture } from '../../../../utilities/CultureHelper';
import NsIranMap from '../../../../components/svgMaps/NsIranSvgMap';
import NsCard from '../../../../components/cards/NsCard';
import Enumerations from '../../../../configs/Enumerations';

const ApDaActiveState = ({ refreshDate, parameters }) => {

    const columns = [
        {
            field: 'state',
            headerName: getTranslatedRawText('adminPanel.dashboard.state'),
        },
        {
            field: 'usersCount',
            headerName: getTranslatedRawText('adminPanel.dashboard.usersCount'),
            dataType: Enumerations.gridType.number,
        },
        {
            field: 'ordersAmount',
            headerName: getTranslatedRawText('adminPanel.dashboard.ordersAmount'),
            dataType: Enumerations.gridType.number,
        },
        {
            field: 'agancyDiscountPercent',
            headerName: getTranslatedRawText('adminPanel.dashboard.agancyDiscountPercent'),
            dataType: Enumerations.gridType.number,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div>{props.agancyDiscountPercent * 100} %</div>
                },
            }
        },
        {
            field: 'companyPriceChangePercent',
            headerName: getTranslatedRawText('adminPanel.dashboard.companyPriceChangePercent'),
            dataType: Enumerations.gridType.number,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div>{props.companyPriceChangePercent * 100} %</div>
                },
            }
        },
    ];

    const [activeStates, setActiveStates] = useState([]);

    const getData = (rows) => setActiveStates(rows);

    return (
        <NsCard>
            <Row className="d-flex align-items-center m-3">

                <Col md={4}>
                    <NsIranMap activeStates={activeStates} />
                </Col>

                <Col>
                    <NsDataGridWithServiceCall callServiceDate={refreshDate}
                        service={GetAgencyActiveStatesService}
                        parameters={parameters}
                        noFooter={true}
                        columnDefs={columns}
                        getData={getData} />
                </Col>

            </Row>
        </NsCard >
    )
}

export default React.memo(ApDaActiveState);