import React from "react";
import { Col, Row } from "react-bootstrap";

import NsTypography from "../../../../components/text/NsTypography";
import {isEnCulture } from '../../../../utilities/CultureHelper';

class ShoppingBagMainBanner extends React.Component {

    render() {
        const { bannerBackground, applyOpacity, children } = this.props;
        const revertShoppingBagImage = !isEnCulture() ? { transform: 'scalex(-1)' } : {};

        return (
            <Row>
                <Col xs={12} className={`shopping-bag ${applyOpacity && 'opacity-25'}`} style={{ '--image-url': `url(${bannerBackground})` }}>
                    <div className='shopping-bag__img' style={revertShoppingBagImage}>
                        <NsTypography variant='inherit' className="shopping-bag__heading--primary ms-3 ms-lg-4">
                            {children}
                        </NsTypography>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default ShoppingBagMainBanner;