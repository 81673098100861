import React from 'react';

import NsButton from '../../../../components/buttons/NsButton';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsMessageClassModal from '../../../../components/modals/NsMessageClassModal';
import Enumerations from '../../../../configs/Enumerations';
import { DeleteAddressService, GetAddressesService } from '../../../../services/ProfileSetting/DeliveryAddressSettingService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import UserPanelWrapper from '../../UserPanelWrapper';
import ProfileAddressSettintgModal from '../../modals/ProfileAddressSettintgModal';

class UpProfileAddressSetting extends React.Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                field: 'Detail',
                headerName: getTranslatedRawText('components.nsDataGrid.edit'),
                width: 80,
                cellRenderer: "customRowRenderer",
                lockPosition: true,
                cellRendererParams: {
                    hasEdit: true,
                    editClicked: (id) => this.editAddressClicked(id),
                }
            },
            {
                field: 'delete',
                headerName: getTranslatedRawText('components.nsDataGrid.delete'),
                width: 80,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    hasDelete: true,
                    deleteClicked: (id) => this.deleteAddressClicked(id)
                },
            },
            {
                field: 'isActive',
                headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
                width: 90,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    hasActivationStatus: true
                }
            },
            {
                field: 'countryName',
                headerName: getTranslatedRawText('components.country.country'),
                width: 100,
            },
            {
                field: 'stateName',
                headerName: getTranslatedRawText('components.state.state'),
                width: 100,
            },
            {
                field: 'cityName',
                headerName: getTranslatedRawText('components.city.city'),
                width: 100,
            },

            {
                field: 'address',
                headerName: getTranslatedRawText('components.nsDataGrid.address'),
                width: 500,
            },
        ];
        this.state = {
            addresses: [],
            selectedAddressId: '',
            showAddressModal: false,
            showDeleteAddressMessageModal: false,
            callServiceDate: null,
            messageItems: [],
            isLoading: false,
            resultMessageClass: (<></>),

        };
    }

    getData = (addresses, isLoading) => this.setState({ addresses, isLoading });

    addAddressClicked = () => this.showModalAddress(null);

    editAddressClicked = (addressId) => this.showModalAddress(addressId);

    showModalAddress = (addressId) => this.setState({ showAddressModal: true, selectedAddressId: addressId });

    closeAddressModal = (resultMessageClass) => this.setState({ showAddressModal: false, resultMessageClass, callServiceDate: new Date() });

    deleteAddressClicked = (addressId) =>
        this.setState({
            showDeleteAddressMessageModal: true,
            selectedAddressId: addressId,
            messageItems: [
                {
                    messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'),
                        this.state.addresses.find(address => address.id === addressId).address)}</>,
                    messageType: Enumerations.messageType.question
                }],
        });

    deleteAddress = (confirm) => {
        if (!!confirm) {
            this.setState({ isLoading: true });
            DeleteAddressService({ id: this.state.selectedAddressId }, this.deleteAddressCallback);
        } else
            this.setState({ showDeleteAddressMessageModal: false });
    }

    deleteAddressCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false, showDeleteAddressMessageModal: false })
        if (!result?.data?.hasError) {
            this.setState({ callServiceDate: new Date() });
        }
        else
            this.setState({ resultMessageClass });
    }

    render() {
        const { callServiceDate, resultMessageClass, showAddressModal, showDeleteAddressMessageModal, messageItems, selectedAddressId } = this.state;

        return (
            <UserPanelWrapper titleName={getTranslatedRawText("profile.actionButton.address")}
                titleIcon='ns-icon-address'>

                <div className="d-flex justify-content-end mb-3">
                    <NsButton className="primary"
                        startIcon='ns-icon-add'
                        label="common.add"
                        onClick={this.addAddressClicked} />

                </div>
                <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                    service={GetAddressesService}
                    hasPagination={true}
                    columnDefs={this.columns}
                    getData={this.getData} />

                {showAddressModal &&
                    <ProfileAddressSettintgModal addressId={selectedAddressId}
                        closeModal={this.closeAddressModal} />
                }
                {showDeleteAddressMessageModal &&
                    <NsMessageClassModal show={true}
                        onClose={this.deleteAddress}
                        messageItems={messageItems} />
                }
                {resultMessageClass}

            </UserPanelWrapper>
        );
    }
}

export default UpProfileAddressSetting;