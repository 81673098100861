import clsx from 'clsx';
import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import '../../assets/sass/components/inputs/NsGroupNumberInput.scss'

import NsButton from '../buttons/NsButton';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';

class NsGroupNumberInput extends React.Component {

    valueChanged = (value) => {
        const { inputProps, onChanged } = this.props;
        let modifiedvalue = parseFloat(value);
        if (parseFloat(value) < 1)
            modifiedvalue = inputProps?.min;

        if (parseFloat(value) > inputProps?.max)
            modifiedvalue = inputProps?.max;

        CommonHelper.safeFunctionCall(onChanged, isNaN(modifiedvalue) ? null : modifiedvalue);
    }


    handleFocus = () => {
        CommonHelper.safeFunctionCall(this.props.handleFocus);
    }

    onBlur = () => {
        isEmpty(this.props.value) && CommonHelper.safeFunctionCall(this.props.onChanged, this.props.inputProps?.min || 1);
    }

    render() {
        const { label, value, unit, step, maxLength, isSmallSize, className, hasDeleteIcon, fieldClassName, minusButtonDisabled, addButtonDisabled, disabled, isBasket, isLoading } = this.props;
        const changeStep = !!step ? step : 1;
        return (
            <div className={clsx(isSmallSize ? 'input-width-button--small' : 'input-width-button--normal', className)}>
                <div className='d-flex align-items-center justify-content-start font-size-12 pb-1'>
                    {getTranslatedRawText(label)}
                    {this.props.children}
                </div>
                <div className={clsx("rounded ", isBasket ? 'border-red' : 'border p-1', isEnCulture() ? 'd-flex' : 'd-flex flex-row-reverse', fieldClassName)}>

                    <NsButton startIcon={hasDeleteIcon && value === 1 ? 'ns-icon-delete' : 'ns-icon-minus'}
                        disabled={minusButtonDisabled}
                        size='small'
                        loading={isLoading}
                        className={isBasket ? "primary-text" : "light"}
                        onBlur={this.onBlur}
                        onFocus={this.handleFocus}
                        onClick={() => this.valueChanged(value - changeStep)} />

                    <TextField type='number'
                        onKeyDown={this.handleKeyDown}
                        className='px-2'
                        variant='standard'
                        onFocus={this.handleFocus}
                        autoFocus={this.props.autoFocus}
                        value={value || ''}
                        onBlur={this.onBlur}
                        disabled={disabled}
                        onChange={(e) => this.valueChanged(e.target.value)}
                        InputProps={{
                            inputMode: 'numeric',
                            min: 1,
                            endAdornment: <InputAdornment position="end">
                                <span className='font-size-12'>{unit || ''}</span>
                            </InputAdornment>
                        }}
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxLength)
                        }}
                    />

                    <NsButton startIcon='ns-icon-plus'
                        disabled={addButtonDisabled}
                        size='small'
                        loading={isLoading}
                        onFocus={this.handleFocus}
                        className={isBasket ? "primary-text" : "light"}
                        onClick={() => this.valueChanged(value + changeStep)} />
                </div>
            </div>
        );
    }
}

export default NsGroupNumberInput;