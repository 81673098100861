import React, { useState } from 'react';

import { GetApDfbByProductsGridService } from '../../../../../services/adminPanel/AdminDashboardService';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsCard from '../../../../../components/cards/NsCard';
import ApDfbProductFeedbacksModal from './ApDfbProductFeedbacksModal';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import ApDfbTypesFeedbacksModal from '../bases/ApDfbTypesFeedbacksModal';

const ApDfbOrderProductsGrid = ({ refreshDate, parameters }) => {

    const [selectedProductId, setselectedProductId] = useState(null);
    const [showFeedbacksTypesModal, setShowFeedbacksTypesModal] = useState(null);

    const columns = [
        hasAccess([UserClaimEnumerations.getApDfbSharedSheetOrderFeedback]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => productDetailClicked(id),
            }
        },
        {
            field: 'productName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
            width: 200
        },
        {
            field: 'averageRate',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.avgRate'),
            dataType: Enumerations.gridType.number,
            sortable: true,
            width: 130
        },
        {
            field: 'quantity',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.qty'),
            dataType: Enumerations.gridType.number,
            sortable: true,
            width: 90
        }
    ];

    const productDetailClicked = (id) => {
        setselectedProductId(id);
        setShowFeedbacksTypesModal(true);
    }

    const toggleFeedbacksTypesModal = () => setShowFeedbacksTypesModal(!showFeedbacksTypesModal);

    return (
        <NsCard className="p-3 h-100">
            <div className='page-title mb-2'>{getTranslatedRawText('adminPanel.sidebar.products')}</div>

            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={GetApDfbByProductsGridService}
                parameters={parameters}
                hasPagination={true}
                columnDefs={columns} />

            {showFeedbacksTypesModal &&
                <ApDfbTypesFeedbacksModal showModal={true}
                    feedbackType={parameters?.feedbackType}
                    selectedFeedbackTypeId={selectedProductId}
                    closeModal={toggleFeedbacksTypesModal} />}
        </NsCard >
    )
}

export default React.memo(ApDfbOrderProductsGrid);