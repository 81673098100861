import React, { useState } from "react";

import { deleteAllShoppingBagHistory, deleteShoppingBagHistory, getShoppingBagHistories, getShoppingBagSavedHistories, saveShoppingBagHistoryName } from "../bases/shoppingBagBasesHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsDataGrid from "../../../../components/grids/NsDataGrid";
import NsButton from "../../../../components/buttons/NsButton";
import Enumerations from "../../../../configs/Enumerations";
import CommonHelper from "../../../../utilities/CommonHelper";
import NsTab from "../../../../components/tabs/NsTab";
import NsModal from "../../../../components/modals/NsModal";
import NsTextField from "../../../../components/inputs/NsTextField";
import NsStickyButton from "../../../../components/buttons/NsStickyButton";

class ShoppingBagMainHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            histories: getShoppingBagHistories(),
            selectedTabIndex: 0,
            selectedRowId: null,
            showHistoryNameModal: false
        };
        this.tabs = [
            { id: 1, label: getTranslatedRawText('product.shoppingBag.history'), icon: 'ns-icon-history fs-6' },
            { id: 2, label: getTranslatedRawText('product.shoppingBag.saved'), icon: 'ns-icon-mark-fill fs-6' }
        ]
    }

    createColumns = () => [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            cellRenderer: "customRowRenderer",
            width: 120,
            cellRendererParams: {
                customElementRender: (props) => <>
                    <NsButton className='secondary-text'
                        startIcon='ns-icon-view'
                        size='small'
                        onClick={() => this.reviewHistoryClicked(props)} />
                    <NsButton className='secondary-text'
                        startIcon='ns-icon-delete'
                        size='small'
                        onClick={() => this.deleteClicked(props.id)} />
                    {this.state.selectedTabIndex == 0 && <NsButton className='secondary-text'
                        startIcon={!!props.name ? 'ns-icon-mark-fill' : 'ns-icon-mark'}
                        size='small'
                        onClick={() => this.toggleHistoryNameModalClicked(props.id)} />
                    }
                </>
            }
        },
        this.state.selectedTabIndex !== 0 && {
            field: 'name',
            headerName: getTranslatedRawText('product.shoppingBag.name'),
            width: 120,
        },
        {
            field: 'result.totalPrice',
            headerName: getTranslatedRawText('product.shoppingBag.price'),
            dataType: Enumerations.gridType.price,
            cellClass: 'color-red',
            width: 120,
        },
        {
            field: 'gwh',
            headerName: getTranslatedRawText('product.shoppingBag.gwh'),
            width: 120,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => <>{props.gusset.value}*{props.width.value}*{props.height.value}</>,
            },
        },
        {
            field: 'quantity.value',
            headerName: getTranslatedRawText('product.shoppingBag.quantity'),
            width: 100,
        },
        {
            field: 'paperThickness.value',
            headerName: getTranslatedRawText('product.shoppingBag.paperThickness'),
            width: 180,
        },
        {
            field: 'selectedNeedPrint.value',
            headerName: getTranslatedRawText('product.shoppingBag.needPrint'),
            width: 110,
        },
        {
            field: 'selectedTopFolding.value',
            headerName: getTranslatedRawText('product.shoppingBag.topFold'),
            width: 100,
        },
        {
            field: 'selectedGripper.value',
            headerName: getTranslatedRawText('product.shoppingBag.gripperMarginPrint'),
            width: 100,
        },
        {
            field: 'selectedPackaging.value',
            headerName: getTranslatedRawText('product.shoppingBag.packaging'),
            width: 100,
        },
        {
            field: 'selectedPaperType.value',
            headerName: getTranslatedRawText('product.shoppingBag.paperType'),
            width: 110,
        },
        {
            field: 'selectedTurnaround.value',
            headerName: getTranslatedRawText('product.shoppingBag.turnaround'),
            width: 110,
        },
        {
            field: 'openwh',
            headerName: getTranslatedRawText('product.shoppingBag.openwh'),
            width: 110,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => <>{props.result.openedDimension.width}*{props.result.openedDimension.height}</>,
            },
        },
        {
            field: 'result.paperInfo.paperWeight',
            headerName: getTranslatedRawText('product.shoppingBag.totalPaperWeight'),
            width: 110,
            dataType: Enumerations.gridType.number
        },
        {
            field: 'result.paperInfo.paperWasteWeight',
            headerName: getTranslatedRawText('product.shoppingBag.paperWasteWeight'),
            width: 110,
            dataType: Enumerations.gridType.number
        },
        {
            field: 'result.paperInfo.paperName',
            headerName: getTranslatedRawText('product.shoppingBag.printSize'),
            width: 110,
            dataType: Enumerations.gridType.number
        },
        {
            field: 'result.paperInfo.rollWidth',
            headerName: getTranslatedRawText('product.shoppingBag.rollWidth'),
            width: 110,
            dataType: Enumerations.gridType.number
        },
        {
            field: 'result.paperInfo.printPrice',
            headerName: getTranslatedRawText('product.shoppingBag.printPrice'),
            width: 110,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => !!props.customPrice
                    ? <>{props.customPrice}&nbsp;<span className="text-decoration-line-through">{props.result.paperInfo.printPrice}</span></>
                    : props.result.paperInfo.printPrice,
            },
        },
        {
            field: 'result.makeAndPackageingInfo.makePricePerBag',
            headerName: getTranslatedRawText('product.shoppingBag.makePricePerShoppingBag'),
            width: 110,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => !!props.customPricePerBag
                    ? <>{props.customPricePerBag}&nbsp;<span className="text-decoration-line-through">{props.result.makeAndPackageingInfo.makePricePerBag}</span></>
                    : props.result.makeAndPackageingInfo.makePricePerBag,
            },
        },
        {
            field: 'result.makeAndPackageingInfo.makePrice',
            headerName: getTranslatedRawText('product.shoppingBag.makePrice'),
            width: 110,
            dataType: Enumerations.gridType.price

        },
        {
            field: 'result.makeAndPackageingInfo.shrinkPrice',
            headerName: getTranslatedRawText('product.shoppingBag.shrinkPrice'),
            width: 110,
            dataType: Enumerations.gridType.price

        },
        {
            field: 'result.makeAndPackageingInfo.cartonPrice',
            headerName: getTranslatedRawText('product.shoppingBag.cartonPrice'),
            width: 110,
            dataType: Enumerations.gridType.price

        },
        {
            field: 'result.pricePerBag',
            headerName: getTranslatedRawText('product.shoppingBag.pricePerShoppingbag'),
            width: 110,
            dataType: Enumerations.gridType.price

        },
    ];

    componentDidUpdate = (prevProps) => {
        if (prevProps.refreshHistoriesDate !== this.props.refreshHistoriesDate)
            this.getHistories(this.state.selectedTabIndex);
    }

    reviewHistoryClicked = (row) => CommonHelper.safeFunctionCall(this.props.reviewHistoryClicked, row?.id);

    deleteClicked = (id) => {
        deleteShoppingBagHistory(id);
        this.getHistories(this.state.selectedTabIndex);
    }

    deleteAllClicked = () => {
        deleteAllShoppingBagHistory(this.state.selectedTabIndex);
        this.getHistories(this.state.selectedTabIndex);
    }

    toggleHistoryNameModalClicked = (selectedRowId) => {
        this.setState({ showHistoryNameModal: !this.state.showHistoryNameModal, selectedRowId });
        this.getHistories(this.state.selectedTabIndex);
    }

    toggleHistoryClicked = () => CommonHelper.safeFunctionCall(this.props.toggleHistoryClicked, false);

    tabChanged = (selectedTabIndex) => {
        if (selectedTabIndex !== this.state.selectedTabIndex) {
            this.setState({ selectedTabIndex });
            this.getHistories(selectedTabIndex);
        }
    }

    getHistories = (selectedTabIndex) => this.setState({ histories: selectedTabIndex == 1 ? getShoppingBagSavedHistories() : getShoppingBagHistories() });

    render() {
        const { histories, selectedTabIndex, showHistoryNameModal, selectedRowId } = this.state;
        const { isUsedInAdmin } = this.props;

        return (<>
            <div className="d-flex">
                <NsTab tabs={this.tabs}
                    value={selectedTabIndex}
                    onChange={this.tabChanged} />
                <NsButton className='secondary-text'
                    wrapperClassName='ms-auto'
                    startIcon='ns-icon-delete'
                    onClick={this.deleteAllClicked} />
                {!isUsedInAdmin && <NsButton className='secondary-text'
                    startIcon='ns-icon-close'
                    onClick={this.toggleHistoryClicked} />
                }
            </div>
            <NsDataGrid rows={histories}
                columnDefs={this.createColumns()}
                noFooter={true} />
            {showHistoryNameModal &&
                <HistoryNameModal selectedRowId={selectedRowId}
                    closeModal={this.toggleHistoryNameModalClicked} />
            }
        </>
        );
    }
}

function HistoryNameModal(props) {
    const { selectedRowId } = props;
    const [rowName, setRowName] = useState(props.rowName);

    const saveNameClicked = () => {
        saveShoppingBagHistoryName(selectedRowId, rowName);
        props.closeModal();
    }

    const mainButtons = [
        CommonHelper.createStickyButtonItem("common.save", 'ns-icon-mark-fill', () => saveNameClicked(rowName), 'primary'),
        CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', props.closeModal, 'primary-outline')
    ];

    return <NsModal showModal={true}
        maxWidth='xs'
        closeModal={props.closeModal}
        dialogTitle={getTranslatedRawText('product.shoppingBag.saveItem')}
        actions={<NsStickyButton stickyButtons={mainButtons} isModal={true} />} >
        <NsTextField label={getTranslatedRawText('product.shoppingBag.name')}
            value={rowName}
            valueChanged={(value) => setRowName(value)} />
    </NsModal>
}

export default ShoppingBagMainHistory;