import React from 'react';
import Config from '../../configs/Config';
import ConstantsWithTranslation from '../../configs/ConstantsWithTranslation';
import Enumerations from '../../configs/Enumerations';

import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';
import NsTab from '../tabs/NsTab';
import NsGoogleMap from './NsGoogleMap';
import NsLeaflet from './NsLeaflet';
import NsPigeon from './NsPigeon';
import { GetAddressFromMapService } from '../../services/common/CommonService';

class NsMapWithTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mapEnum: Config.countryDefaultMap[CurrentUserHelper.getCountryId()],
            selectedTabIndex: ConstantsWithTranslation.getMapTabs().findIndex(map => map.id === Config.countryDefaultMap[CurrentUserHelper.getCountryId()])
        }
    }

    onClick = (lat, lng) => GetAddressFromMapService(lat, lng, (response) => {
        CommonHelper.safeFunctionCall(this.props.onClick, lat, lng, response?.address);
    })

    tabChanged = (selectedTabIndex) => this.setState({ selectedTabIndex, mapEnum: ConstantsWithTranslation.getMapTabs()[selectedTabIndex].id })

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.selectedMapIndex !== prevProps.selectedMapIndex) {
            this.setState({
                mapEnum: ConstantsWithTranslation.getMapTabs()[this.props.selectedMapIndex].id
            })
        }
    }

    mapMaps = () => {
        const { lat, lng, mapCenterLongitude, mapCenterLatitude } = this.props;

        switch (this.state.mapEnum) {
            case Enumerations.maps.leaflet:
                return <NsLeaflet lat={lat}
                    label={this.props.label}
                    showValidationDate={this.props.showValidationDate}
                    isAddNewAddress={this.props.isAddNewAddress}
                    lng={lng}
                    mapCenterLatitude={mapCenterLatitude}
                    mapCenterLongitude={mapCenterLongitude}
                    onClick={this.onClick} />
            case Enumerations.maps.pigeon:
                return <NsPigeon lat={lat}
                    label={this.props.label}
                    showValidationDate={this.props.showValidationDate}
                    isAddNewAddress={this.props.isAddNewAddress}
                    lng={lng}
                    mapCenterLatitude={mapCenterLatitude}
                    mapCenterLongitude={mapCenterLongitude}
                    onClick={this.onClick} />
            default:
                return <NsGoogleMap lat={lat}
                    lng={lng}
                    mapCenterLatitude={mapCenterLatitude}
                    mapCenterLongitude={mapCenterLongitude}
                    onClick={this.onClick} />
        }
    }

    render() {
        const { selectedTabIndex } = this.state;
        const { lat, lng } = this.props;
        return <>

            {this.props.showValidationDate && !lat && !lng && <p className='red-color mt-3 font-size-14'>
                {getTranslatedRawText("profile.address.selectLocation")}
            </p>}

            <NsTab tabs={ConstantsWithTranslation.getMapTabs()}
                value={selectedTabIndex}
                onChange={this.tabChanged}>
            </NsTab>

            {this.mapMaps()}

        </>


    }
}

export default NsMapWithTab;
