import React from 'react';

import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import Enumerations from '../../../../configs/Enumerations';
import OrderHelper from '../../../../utilities/OrderHelper';
import OrderThumbnailGroupPreview from '../bases/OrderThumbnailGroupPreview';
import CommonHelper from '../../../../utilities/CommonHelper';

class OrderFilesPreview extends React.Component {

    constructor(props) {
        super(props);
        this.printedSide = OrderHelper.getPrintedSide(props.routeId)?.key;
        this.state = {
            files: [],
        };
    }

    componentDidMount = () => this.getFiles(this.props.routeId);

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.refreshFilePreviewSummaryDate !== this.props.refreshFilePreviewSummaryDate)
            this.getFiles(this.props.routeId);
    }

    getFiles = (routeId) => {
        OrderHelper.getFilesWithFullInfo(routeId, Enumerations.presentableFileType.none, (files) => {
            this.setState({ files }, () => CommonHelper.safeFunctionCall(this.props.filesHandler, this.state.files));
        });
    }

    render() {
        return (
            <div className='pt-2 w-100'>
                <div className='section-title'>
                    {getTranslatedRawText('regularOrder.confirmDetail.filePreview')}
                </div>
                <div className='d-flex gap-2 w-100 justify-content-start '>
                    <OrderThumbnailGroupPreview files={this.state.files}
                        showDefaultImage={true}
                        isPointerActive={true}
                        printedSide={this.printedSide}
                        imageClicked={this.props.imageClicked} />
                </div>
            </div>
        );
    }
}

export default OrderFilesPreview;