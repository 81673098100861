import React from 'react';
import { connect } from 'react-redux';

import { GetProductsCirculationsService } from '../../../../../../services/product/ProductService';
import { isEmpty, isValidInvoiceNumber } from '../../../../../../utilities/ValidationHelper';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import NsAdvanceSearch from '../../../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import NsSearchInput from '../../../../../../components/inputs/NsSearchInput';
import NsButton from '../../../../../../components/buttons/NsButton';
import NsSwitch from '../../../../../../components/switch/NsSwitch';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../../configs/Enumerations';

class UpBaseGroupSheetOrdersSearchSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            invoiceNumber: '',
            status: [],
            minPrice: null,
            maxPrice: null,
            turnaround: [],
            fromSubmitTime: null,
            toSubmitTime: null,
            confirmTimeFrom: null,
            confirmTimeTo: null,
            deliveredTimeFrom: null,
            deliveredTimeTo: null,
            productCategory: [],
            selectedProducts: [],
            printedSide: [],
            selectedCirculation: [],
            products: [],
            circulations: [],
            mobile: null,
            businessPartnerCustomerIdentifier: '',
            resultMessageClass: (<></>),
        }
    }

    getSearchOptions = (isAdvancedSearch, isAgency) => {

        let searchFields = [
            {
                type: Enumerations.advanceSearchFieldType.numberInput,
                label: getTranslatedRawText('profile.dataGridColumns.invoiceNumber'),
                fieldName: 'invoiceNumber',
                value: this.state.invoiceNumber,
                autoFocus: true,
                onChange: (value) => {
                    this.valueChanged(value, { invoiceNumber: this.state.invoiceNumber })
                },
                sortId: 1
            },
            {
                type: Enumerations.advanceSearchFieldType.autoComplete,
                label: getTranslatedRawText('profile.fileCorrection.product'),
                fieldName: 'selectedProducts',
                value: this.state.selectedProducts,
                items: this.state.products || [],
                onChange: (value) => {
                    this.valueChanged(value, { selectedProducts: this.state.selectedProducts })
                },
                sortId: 3
            },
            {
                type: Enumerations.advanceSearchFieldType.datePicker,
                label: getTranslatedRawText('profile.allOrders.submitTimeFrom'),
                fieldName: 'fromSubmitTime',
                value: this.state.fromSubmitTime,
                onChange: (value) => {
                    this.valueChanged(value, { fromSubmitTime: this.state.fromSubmitTime })
                },
                sortId: 7
            },
            {
                type: Enumerations.advanceSearchFieldType.datePicker,
                label: getTranslatedRawText('profile.allOrders.submitTimeTo'),
                fieldName: 'toSubmitTime',
                value: this.state.toSubmitTime,
                onChange: (value) => {
                    this.valueChanged(value, { toSubmitTime: this.state.toSubmitTime })
                },
                sortId: 8
            }
        ];

        if (isAdvancedSearch) {
            searchFields = [
                ...searchFields,
                {
                    type: Enumerations.advanceSearchFieldType.autoComplete,
                    label: getTranslatedRawText('profile.allOrders.productCategory'),
                    fieldName: 'productCategory',
                    value: this.state.productCategory,
                    items: this.props.categories || [],
                    onChange: (value) => {
                        this.valueChanged(value, { productCategory: this.state.productCategory })
                    },
                    sortId: 2
                },
                {
                    type: Enumerations.advanceSearchFieldType.autoComplete,
                    label: getTranslatedRawText('profile.detail.turnaround'),
                    fieldName: 'turnaround',
                    value: this.state.turnaround,
                    items: ConstantsWithTranslation.getTurnarounds(),
                    onChange: (value) => {
                        this.valueChanged(value, { turnaround: this.state.turnaround })
                    },
                    sortId: 5
                },
                {
                    type: Enumerations.advanceSearchFieldType.autoComplete,
                    label: getTranslatedRawText('profile.dataGridColumns.circulation'),
                    fieldName: 'selectedCirculation',
                    value: this.state.selectedCirculation,
                    items: this.state.circulations,
                    onChange: (value) => {
                        this.valueChanged(value, { selectedCirculation: this.state.selectedCirculation })
                    },
                    sortId: 4
                },
                {
                    type: Enumerations.advanceSearchFieldType.autoComplete,
                    label: getTranslatedRawText('profile.detail.printedSide'),
                    fieldName: 'printedSide',
                    value: this.state.printedSide,
                    items: ConstantsWithTranslation.getPrintedSide(),
                    onChange: (value) => {
                        this.valueChanged(value, { printedSide: this.state.printedSide })
                    },
                    sortId: 6
                },
                {
                    type: Enumerations.advanceSearchFieldType.datePicker,
                    label: getTranslatedRawText('profile.allOrders.confirmTimeFrom'),
                    fieldName: 'confirmTimeFrom',
                    value: this.state.confirmTimeFrom,
                    onChange: (value) => {
                        this.valueChanged(value, { confirmTimeFrom: this.state.confirmTimeFrom })
                    },
                    sortId: 9
                },
                {
                    type: Enumerations.advanceSearchFieldType.datePicker,
                    label: getTranslatedRawText('profile.allOrders.confirmTimeTo'),
                    fieldName: 'confirmTimeTo',
                    value: this.state.confirmTimeTo,
                    onChange: (value) => {
                        this.valueChanged(value, { confirmTimeTo: this.state.confirmTimeTo })
                    },
                    sortId: 10
                },
                {
                    type: Enumerations.advanceSearchFieldType.datePicker,
                    label: getTranslatedRawText('profile.allOrders.deliveredTimeFrom'),
                    fieldName: 'deliveredTimeFrom',
                    value: this.state.deliveredTimeFrom,
                    onChange: (value) => {
                        this.valueChanged(value, { deliveredTimeFrom: this.state.deliveredTimeFrom })
                    },
                    sortId: 11
                },
                {
                    type: Enumerations.advanceSearchFieldType.datePicker,
                    label: getTranslatedRawText('profile.allOrders.deliveredTimeTo'),
                    fieldName: 'deliveredTimeTo',
                    value: this.state.deliveredTimeTo,
                    onChange: (value) => {
                        this.valueChanged(value, { deliveredTimeTo: this.state.deliveredTimeTo })
                    },
                    sortId: 12
                },
                {
                    type: Enumerations.advanceSearchFieldType.textField,
                    label: getTranslatedRawText('profile.allOrders.minPrice'),
                    fieldName: 'minPrice',
                    value: this.state.minPrice,
                    onChange: (value) => {
                        this.valueChanged(value, { minPrice: this.state.minPrice })
                    },
                    sortId: 13
                },
                {
                    type: Enumerations.advanceSearchFieldType.textField,
                    label: getTranslatedRawText('profile.allOrders.maxPrice'),
                    fieldName: 'maxPrice',
                    value: this.state.maxPrice,
                    onChange: (value) => {
                        this.valueChanged(value, { maxPrice: this.state.maxPrice })
                    },
                    sortId: 14
                },
                {
                    type: Enumerations.advanceSearchFieldType.autoComplete,
                    label: getTranslatedRawText('profile.dataGridColumns.page'),
                    fieldName: 'status',
                    value: this.state.status,
                    items: ConstantsWithTranslation.getOrderStatusPageNameList(),
                    onChange: (value) => {
                        this.valueChanged(value, { status: this.state.status })
                    },
                    sortId: 15
                },
                isAgency && {
                    type: Enumerations.advanceSearchFieldType.numberInput,
                    label: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
                    fieldName: 'mobile',
                    value: this.state.mobile,
                    onChange: (value) => this.valueChanged(value, { mobile: this.state.mobile }),
                },
                {
                    type: Enumerations.advanceSearchFieldType.textField,
                    label: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
                    fieldName: 'businessPartnerCustomerIdentifier',
                    value: this.state.businessPartnerCustomerIdentifier,
                    onChange: (value) => this.valueChanged(value, { businessPartnerCustomerIdentifier: this.state.businessPartnerCustomerIdentifier }),
                },
            ];
        }

        return searchFields.sort((itemPrev, itemNext) => itemPrev.sortId - itemNext.sortId);
    }

    valueChanged = (value, valueStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        this.setState({
            [valueName]: value,
        }, () => {
            this.setState({
                invoiceNumberHelperText: isValidInvoiceNumber(this.state.invoiceNumber) ? null : getTranslatedRawText('profile.allOrders.enterInvoiceNumberCompletely')
            })
        });
    }

    searchTextChanged = (value) => this.setState({ searchTerm: value });

    searchFormClicked = () => {
        const { invoiceNumber, selectedProducts, fromSubmitTime, toSubmitTime } = this.state;
        let compactedState = {
            nasInvoiceIds: !!invoiceNumber ? [Number(invoiceNumber)] : null,
            pprId: selectedProducts?.circulations?.length > 0 ? selectedProducts?.circulations[0]?.key : null,
            fromSubmitTime,
            toSubmitTime,
        };
        CommonHelper.safeFunctionCall(this.props.searchFormClicked, this.props?.advancedMode ? { ...this.state, ...compactedState } : compactedState)
    };

    clearClicked = () => {
        this.setState({
            searchTerm: '',
            invoiceNumber: '',
            status: [],
            minPrice: null,
            maxPrice: null,
            turnaround: [],
            fromSubmitTime: null,
            toSubmitTime: null,
            confirmTimeFrom: null,
            confirmTimeTo: null,
            deliveredTimeFrom: null,
            deliveredTimeTo: null,
            businessPartnerCustomerIdentifier: '',
            mobile: null,
            productCategory: [],
            selectedProducts: [],
            printedSide: [],
            selectedCirculation: [],
        }, () => {
            CommonHelper.safeFunctionCall(this.props.searchFormClicked, {});
        });
    }

    componentDidMount = () => GetProductsCirculationsService(this.state.productCategory?.id || null, this.getProductsCirculationsCallback);

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.productCategory?.id !== this.state.productCategory?.id)
            GetProductsCirculationsService(this.state.productCategory?.id || null, this.getProductsCirculationsCallback);
        if (prevState.selectedProducts !== this.state.selectedProducts) {
            this.setState({ selectedCirculation: [] });
            this.calcCirculations();
        }
    }

    calcCirculations = () => {
        let arrayOfSelectedProducts = Array.isArray(this.state.selectedProducts) ? this.state.selectedProducts : [this.state.selectedProducts]
        let arrayOfCirculations = []
        arrayOfSelectedProducts.map((product) => {
            !isEmpty(product?.circulations) && arrayOfCirculations.push(...product.circulations);
        });
        this.setState({ circulations: arrayOfCirculations });
    }

    getProductsCirculationsCallback = (resultData, result, resultMessageClass) => {
        if (!result.data.hasError)
            this.setState({ products: resultData }, () => this.calcCirculations());
        else
            this.setState({ resultMessageClass });
    }

    render() {
        const {
            searchTerm,
            invoiceNumber,
            status,
            minPrice,
            maxPrice,
            turnaround,
            fromSubmitTime,
            toSubmitTime,
            confirmTimeFrom,
            confirmTimeTo,
            deliveredTimeTo,
            deliveredTimeFrom,
            productCategory,
            selectedProducts,
            printedSide,
            selectedCirculation,
            invoiceNumberHelperText,
            mobile,
            businessPartnerCustomerIdentifier,
        } = this.state;

        const searchValues = {
            invoiceNumber,
            status,
            minPrice,
            maxPrice,
            turnaround,
            fromSubmitTime,
            toSubmitTime,
            confirmTimeFrom,
            confirmTimeTo,
            deliveredTimeTo,
            deliveredTimeFrom,
            productCategory,
            selectedProducts,
            printedSide,
            selectedCirculation,
            mobile,
            businessPartnerCustomerIdentifier
        }

        const helperTexts = {
            invoiceNumber: invoiceNumberHelperText
        }

        const { autoFocus,
            findOrdersSearchSection,
            children,
            isLoading,
            hasAdvancedMode,
            switchChanged,
            advancedMode,
            showAdvanceSearch,
            isAgency
        } = this.props;

        const searchOptions = CommonHelper.clearArrayFromEmptyItem(this.getSearchOptions(advancedMode, isAgency));

        return (
            findOrdersSearchSection ?
                <>
                    <NsAdvanceSearch searchOptions={searchOptions}
                        helperTexts={helperTexts}
                        autoFocus={autoFocus}
                        searchValues={searchValues} />
                    <div className='d-flex flex-wrap justify-content-between align-items-end'>

                        <div className='d-flex flex-row-reverse my-2 gap-1'>
                            {children}
                            <NsButton className='secondary-outline'
                                label='common.clear'
                                startIcon='ns-icon-clear'
                                loading={isLoading}
                                onClick={this.clearClicked} />
                            <NsButton className='primary'
                                label={getTranslatedRawText('profile.orderReport.search')}
                                startIcon='ns-icon-search'
                                loading={isLoading}
                                onClick={this.searchFormClicked} />
                        </div>
                        {hasAdvancedMode && <div>
                            <NsSwitch onChange={switchChanged}
                                checked={advancedMode}
                                label={'profile.allOrders.advanceMode'} />
                        </div>
                        }
                    </div>
                </>
                : <>
                    <div className='d-flex flex-column flex-md-row w-100 mb-3 gap-1'>
                        <NsSearchInput hasSearchIcon={true}
                            value={searchTerm}
                            searchFormClicked={this.searchFormClicked}
                            handleSearchTextChanged={this.searchTextChanged}
                            placeHolder={getTranslatedRawText('product.common.searchPlaceHolder')}
                            autoFocus={this.props.autoFocus}
                            className='background-color rounded'
                            inputClassName='background-color' />
                        <div className='ms-auto d-flex mt-2 mt-md-0 gap-1'>
                            {showAdvanceSearch && <NsButton className='primary-outline mt-0'
                                startIcon={expandSearchIcon}
                                wrapperClassName='px-1'
                                onClick={toggleBasicSearchOptions} />}

                            <NsButton className='primary'
                                label='common.search'
                                startIcon='ns-icon-search'
                                loading={isLoading}
                                onClick={this.searchFormClicked} />

                            {children}

                        </div>
                    </div>
                </>
        )
    }
}

const mapStateToProps = state => ({ categories: state.categories.categories });

export default connect(mapStateToProps)(UpBaseGroupSheetOrdersSearchSection);