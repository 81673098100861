const AdminPanelTranslationEn = {

    adminPanel: {

        common: {
            action: 'Action',
            new: 'New',
            retry: 'Retry',
            invoice: 'Invoice',
            receipt: 'Receipt',
            userInfo: 'User Info',
            editUser: 'Edit User',
            updateStatus: 'Update Status',
            statusHistory: 'Status History',
            notAvailableHistory: 'There is no any history',
            usersOrder: 'Users Order',
            usersPayments: 'Users Payments',
            ordersPayment: "Order's Payment",
            notificationSetting: 'Notification Setting',
            searchPlaceHolder: 'Search in blog/news...',
            closeModalAlert: 'If you close modal, changes that have not yet been saved will be canceled. Are you sure to close the modal?',
            newTitle: 'New title',
            editTitle: 'Edit title',
            isTitleVisible: 'Show title',
            updatePaymentState: 'Fix Problem ({0})',
            noAnyDataToView: 'No Any Data To View.',
            all: 'All',
            ordersInfo: "Order's Info",
            usersDebitCredit: 'Debit-Credit',
            guest: 'Guest',
        },

        title: {
            statisticalReportOfOrders: 'Total Report Of Orders',
            statisticalReportOfPayments: 'Financial Reports',
            debitCreditReport: 'Debit-Credit Report',
            paymentReport: 'Payment Report'
        },

        dateRange: {
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly',
        },

        sidebar: {
            dashboard: 'Dashboard',
            management: 'Management',
            users: 'Users',
            customers: 'Customers',
            customersFiles: "Customer's Files",
            supporters: 'Supporters',
            orders: 'Orders',
            shareSheets: 'Share sheets',
            stores: 'Stores',
            queue: 'Queue',
            payments: 'Payments',
            list: 'List',
            problematicPayments: 'Problematic Payments',
            reports: 'Group Sheet Reports',
            statisticalReportOfOrders: 'Total Report Of Orders',
            statisticalReportOfPayments: 'Financial Reports',
            usersDebitCredit: 'Debit-Credit',
            comments: 'Comments',
            content: 'Content',
            downloadCenter: 'Download Center',
            downloadDetail: 'Download Detail',
            blog: 'Blog',
            blogsPosts: 'Blog-Posts',
            blogCategories: 'Blog-Categories',
            newssPosts: 'News-Posts',
            newsCategories: 'News-Categories',
            posts: 'Posts',
            category: 'Category',
            news: 'News',
            announcements: 'Announcements',
            singlePages: 'Single Pages',
            aboutUs: 'About us',
            contactUs: 'Contact us',
            warrantyTerms: 'Warranty Terms',
            agreements: 'Agreements',
            faqs: 'FAQs',
            slider: 'Slider',
            promotions: 'Promotions',
            testimonial: 'Testimonial',
            setting: 'Setting',
            websiteInfo: 'Website Information',
            contactInfo: 'Contact Information',
            communicationChannel: 'Communication Channel',
            websiteSetting: 'Website Setting',
            signupAgeRules: 'Signup Age Rules',
            telecommunication: 'Telecommunication Channel',
            notificationConfig: 'Notification Config',
            smsConfig: 'SMS Config',
            editSmsConfig: 'Edit Sms Config',
            addNewSmsConfig: 'Add New Sms Config',
            supportLanguage: 'Support Language',
            fileManagerConfig: 'File Manager Config',
            mapConfig: 'Map Config',
            shippings: 'Shippings',
            inTheWarehouse: 'In The Warehouse',
            queueByLatestStatus: 'Queue By Latest Status',
            queueByShippingMethods: 'Queue By Shipping Methods',
            agencies: 'Agencies',
            businessPartners: 'Business Partners',
            festivals: 'Festivals',
            termsOfUse: 'Terms of use',
            privacyPolicy: 'Privacy policy',
            warrantyTerms: 'Warranty terms',
            help: "Help",
            fileManager: "File manager",
            products: 'Products',
            gallery: 'Gallery',
            storeProducts: 'Store Products',
            advertisement: 'Advertisement',
            audit: 'Audit',
            productContent: 'Product Content',
            logs: 'Logs',
            systemMonitoring: 'System Monitoring',
            paperBag: 'Paper Bags',
            calculateCustomize: 'Price Inquiry',
            feedbacks: 'Feedbacks',
            basicInformation: 'Basic Information',
            feedbackTypes: 'Feedback Settings',
            usersFeedbackTo: "User's Feedback to",
            employees: 'Employees',
            departments: 'Departments',
            galleryPosts: 'Gallery Posts',
            galleryCategories: 'Gallery Categories',
            downloadCategory: 'Download Category',
            blogPosts: 'Blog Posts',
            newsPosts: 'News Posts',
            blogsCategory: "Blog's Category",
            newsCategory: 'News Category',
            galleryCategory: "Gallery's Category",
            queueByEstimatedTime: 'Queue By Estimated Time',
            queueByRemainingToERDGroupSheets: 'Queue By Remaining To Estimated Ready to Deliver Group Sheets',
            queueByRemaining: 'Queue By Remaining...',
            remainingToEstimateReadyToDeliver: 'Remaining To Estimated Ready To Deliver',
            dailyQueue: 'Daily Queue',
            dailyQueueOfEstimateTime: 'Daily Queue of Estimated Ready to Deliver by Last status of Group Sheets orders',
            dailyQueueShorted: 'Daily Queue of Estimated Ready ...',
        },

        department: {
            editDepartments: 'Edit Departments',
            parentDepartment: 'Department',
            addDepartment: 'Add Department',
        },

        systemMonitoring: {
            message: 'Message',
            logType: 'Log Type',
            generalException: 'General Exception',
            nullException: 'Null Exception',
            clientIp: 'Client Ip',
            host: 'Host',
            source: 'Source',
            logsDetail: 'Logs Detail',
            allXml: 'All XMLs'
        },

        storeProduct: {
            productDetail: 'Product Detail',
            supplierPrice: 'Supplier Price',
            property: 'Property',
            editStoreProduct: 'Edit Store Product',
            addStoreProduct: 'Add New Store Product',
            localAndRemote: 'Local-Remote',
            showProductIn: 'Show Product In',
            local: 'Local',
            remote: 'Remote',
            createTimeFrom: "Create Time From",
            createTimeTo: "Create Time To",
            modifyTimeFrom: "Modify Time From",
            modifyTimeTo: "Modify Time To",
            description: 'Description',
            overview: 'Overview',
            saveAndNext: 'Save and Next',
            saveAndClose: 'Save and Close',
        },

        newsBlog: {
            noAnyPost: 'No Any Post',
            newBlog: 'New Blog',
            newNews: 'New News',
            minimals: 'Minimals',
            share: 'Share'
        },

        dataGridColumn: {
            phoneNumberConfirmed: 'Granted Credit By Company',
            category: "Category",
            files: "Files",
            fullName: 'Full Name',
            status: 'Status',
            title: 'Title',
            image: 'Image',
            creator: 'Creator',
            modifyDate: 'Modify Date',
            modifier: 'Modifier',
            question: 'Question',
            link: 'Link',
            lastStatus: 'Last Status',
            mobile: 'Mobile',
            registrationTime: 'Registration Time',
            detailLedger: 'Detail Ledger',
            inactiveUsers: 'Users without orders',
            country: 'Country',
            state: 'State',
            city: 'City',
            role: 'Roles',
            content: 'Content',
            accountType: 'Account Type',
            accountStatus: 'Account Status',
            idNumber: 'ID Number',
            companyName: 'Company Name',
            mobileActivation: 'Mobile Activation',
            licenseNumber: 'License Number',
            TRN: 'TRN',
            trackNumber: 'Tracking Number',
            amount: 'Amount',
            createDate: 'Create Date',
            paymentGateway: 'Payment Gateway ',
            paymentType: 'Payment Type',
            TermsOfPayment: "Mode/ Terms of Payment",
            statusDate: 'Last Status Time',
            voucherNumber: 'Voucher Number',
            referenceNumber: 'Payment Gateway Reference Number',
            invoiceNumber: 'Invoice Number',
            submitDate: 'Submit Time',
            description: 'Description',
            productName: 'Product Name',
            totalPrice: 'Total Price',
            priceExcVat: 'Price(Exc. VAT)',
            excVat: '(Exc. VAT)',
            incVat: '(Inc. VAT)',
            priceIncVat: 'Price(Inc. VAT)',
            paymentInAdvance: 'Payment In Advance',
            discount: 'Discount',
            totalOrdersDiscountAmount: 'Total Orders Discount Amount',
            quantity: 'Total Quantity',
            debtor: 'Debtor',
            creditor: 'Creditor',
            detailLedger: 'Detail Ledger',
            circulation: 'Circulation',
            printedSide: 'Printed Side',
            turnaround: 'Turnaround',
            date: 'Date',
            newUsers: 'New Users',
            orderUsers: 'Number of Ordered Users',
            orders: 'Orders',
            notConfirmed: 'Not Confirmed',
            prepareFailed: 'Prepare Failed',
            prepare: 'Prepare',
            inProcess: "In Process",
            archived: "Archived",
            inTransit: 'In Transit',
            ordersAmount: 'Total Orders Paid Amount',
            successfullyPaymentAmount: 'Successfully Orders Paid Amount',
            totalOrdersCount: 'Number of Total Orders',
            confirmedOrdersCount: 'Number of Confirmed Orders',
            totalOrdersAmount: 'Total Orders Amount',
            failedPaymentAmount: 'Failed Payment Amount',
            successfullyPaymentCount: 'Number of Successful Payments',
            failedPaymentCount: 'Number of Failed Payments',
            successfullyPaymentDistinctUserCount: 'Number of Users With Successful Payment',
            failedPaymentDistinctUserCount: 'Number of Users With Failed Payment',
            imageUrl: 'Image Url',
            filePreview: "File preview",
            usingInAOrder: "Using in a Order",
            used: 'Used',
            fileUrl: 'File Url',
            remotingOrderId: "Remoting order id",
            user: "User",
            shippingRequestNumber: 'Shipping Request Number',
            shippingRequestTime: 'Shipping Request Time',
            shippingMethod: 'Shipping Method',
            courierNumber: 'Courier Number',
            agencyName: 'Agency Name',
            stateCity: 'State/City',
            priceChange: 'Price Change',
            agencyDiscount: 'Agency Discount',
            code: 'Code',
            showIn: 'Show In',
            paymentProblemType: 'Payment Problem Type',
            cardNumber: 'Card Number',
            websiteTransactionStatus: 'Website Transaction Status',
            fileTransactionStatus: 'File Transaction Status',
            transactionStatus: 'Transaction Status',
            fileAmount: 'File Amount',
            websiteAmount: 'Website Amount',
            paymentAuditMatchingTypeId: 'Payment Audit Matching Status Id',
            paymentAuditMatchingType: 'Matching Status',
            filePayment: "File",
            lastStatusType: 'Last Status Type',
            lastStatusTime: 'last Status Time',
            websitePayment: 'Website',
            invoiceNumberId: 'Invoice Number Id',
            merchantId: 'Merchant Id',
            payGateTranId: 'Switch Transaction Num',
            traceNumber: 'Trace Number',
            paymentId: 'Payment Id',
            paymentStateType: 'Final Payment State',
            refoundStatus: 'Refound Status',
            settleStatus: 'Settle Status',
            datePaid: 'Date Paid',
            terminalId: 'Terminal Id',
            transactionDate: 'Transaction Date',
            approvalTime: 'Approval Time',
            extendedName: 'Extended Name',
            usersWhoRegisterWithTwoNumber: 'Users Who Register With Two Number',
            demandService: 'Demand Service',
            displaytoAll: 'Display to all',
            averageRate: 'Average Rate',
            announcementTitle: 'Announcement Title',
            avgRate: 'Avg Rate',
            qty: 'Quantity',
            purchase: 'Purchase',
            cashbackPlan: 'Cashback Plan',
            cashback: 'Cashback',
            remainingPurchaseToNextPlan: 'Remaining Purchase To Next Plan',
            nextCashbackPlan: 'Next Cashback Plan',
            nextCashback: 'Next Cashback',
            estimateReadyToDeliverTime: 'Estimated Ready To Deliver Time',
            actualReadyToDeliveryTime: 'Actual Ready To Delivery Time',
            actualDeliveredTime: 'Actual Delivered Time',
            productType: 'Product Type',
            lastSheetStateQueueType: 'Last Sheet State Queue Type',
            lastChangeStateUser: 'Last Change State User',
            openTime: 'Open Time',
            closeTime: 'Close Time',
            isClosed: 'Closed Status',
            lockedUsername: 'Locked User',
            isSheetDeleted: 'Is Sheet Deleted?',
            collectPaperStatus: 'Collect Paper Status',
            collectPaperUser: 'Collect Paper User',
            layoutedCloseStatus: 'Layouted Close Status',
            layoutedCloseTime: 'Layouted Close Time',
            layoutedException: 'Layouted Exception',
            deliveryTimeForecast: 'Delivery Time Forecast',
            submitTime: 'Submit Time',
            confirmTime: 'Confirm Time',
            readyTime: 'Ready In The Next',
            parents: 'Parents',
            remainingDaysToEstimateReadyToDeliverTime: 'Remaining Days To Estimate Ready To Deliver Time',
            days: 'day(s)',
            feedbackSubmitDate: 'Feedback Submit Date',
            employeeFullName: "Employee's Full Name",
            extraSupport: 'Extra Support',
            employee: 'Employee',
            transactionStatusMessage: 'Transaction Status Message',
            gatewayMessage: 'Gateway Message',
            estimateReadyToDeliverTimeFrom: 'From Estimated Ready Deliver Time',
            estimateReadyToDeliverTimeTo: 'To Estimated Ready Deliver Time',
            onlyDelayedOrder: 'Only Delayed Order',
            department: 'Department',
            feedbackGiver: 'Feedback Giver',
            fileRegulatorServer: 'File Regulator Server',
            orderStatus: 'OrderStatus',
            currencyType: 'Currency Type',
            capturedAmount: 'Captured Amount',
            capturedDate: 'Captured Date',
            refundAmount: 'Refund Amount',
            refundDate: 'Refund Date',
            systemGeneratedOrder: 'System Generated Order',
            declineReason: 'Decline Reason',
            gatewayRisk: 'Gateway Risk',
            gatewayRiskViolations: 'Gateway Risk Violations',
            authenticationResponse: 'Authentication Response',
            threeDsfailureReason: 'Three Ds Failure Reason',
            outlet: 'Outlet',
            cardHolderEmail: 'Card Holder Email',
            outletReferenceId: 'Outlet Reference',
            cardholderName: 'Card Holder Name',
            paymentMethod: 'Payment Method',
            originIp: 'Origin Ip',
            paymentStatus: 'Payment Status'
        },

        financialReports: {
            totalPaymentAmount: 'Total Payment',
            successfullyPaymentAmount: 'Successful',
            failedPaymentAmount: 'Failed',
            numberOfTotalPayments: 'Total Payments',
            numberOfSuccessfulPayments: 'Successful Payments',
            unknownPaymentAmount: 'Unknown',
            numberOfUnknownPayments: 'Number of Unknown Payments',
            numberOfFailedPayments: 'Number of Failed Payments',
            numberOfTotalPaymentsByDistinctUser: 'Users with Payment',
            numberOfSuccessfulPaymentsByDistinctUser: 'Users with Successful Payments',
            numberOfFailedPaymentsByDistinctUser: 'Users with Failed Payments',
            numberOfUnknownPaymentsByDistinctUser: 'Users with Unknown Payments',
            moreInsights: 'More Insights'
        },

        usersInfo: {
            displayUserInfo: 'Display User Info',
            officialInvoiceIssue: 'Official Invoice Issue',
            editUserInfo: 'Edit User Info',
            searchInUsers: 'Search In Users',
            accountSettings: 'Account Settings',
            personalInformation: 'Personal Information',
            firstName: 'First Name',
            lastName: 'Last Name',
            description: 'Description',
            dateOfBirth: 'Date of Birth',
            email: 'Email',
            genderType: 'Gender Type',
            business: 'Business',
            personal: 'Personal',
            admin: 'Admin',
            superAdmin: 'Super Admin',
            user: 'User',
            subUser: 'Sub User',
            system: 'System',
            supporter: 'Supporter',
            none: 'None',
            confirmed: 'Confirmed',
            notConfirmed: 'Not Confirmed',
            active: 'Active',
            inactive: 'Inactive',
            block: 'Block',
            accountSetting: 'Account Setting',
            accountType: "Account Type",
            companyName: 'Company Name',
            licenseNumber: 'License Number',
            trn: 'TRN',
            idNumber: 'ID NUmber',
            state: 'State',
            city: 'City',
            agency: 'Agency',
            company: "Company",
            noAgency: 'No Agency',
            editSupporterInfo: 'Edit Supporter Info',
            editCustomerInfo: 'Edit Customer Info',
            editBusinessPartnerInfo: 'Edit Business Partner Info',
            newAdmin: 'New Admin',
            editAccess: 'Edit Access',
            searchInOrders: 'Search In Orders',
            adminRoleMessage: 'This role has full Access',
            agencyRoleMessage: 'This role has full Access of Agency',
            businessPartnerRoleMessage: 'This role has full Access of business partner',
            businessPartner: 'Business Partner',
            inactiveUntil: 'Inactive Until',
            expireToken: 'Sign out (All devices)',
            areYouSureToExpireTokenP1: 'By clicking on "OK" button, all Sign in to this website, in any browser or device, will expire, and you will need to Sign in again.',
            areYouSureToExpireTokenP2: 'This feature is to enhance the security of your user account and prevent unauthorized access. In case of password disclosure, it is necessary to change your password immediately and Sign out of your account in any browser or device.',
            BusinessPartnerEmbedCode: 'Business Partner Embed Code',
            xSecureApiKey: 'X Secure Api Key',
            resetSecureApiKey: 'Reset X Secure Api Key',
            areYouSureToResetSecureApiKey: 'Are You Sure To Reset Secure Api Key?',
            remainingLimit: 'Remaining Limit',
            remainingLimitDescription: 'Remaining Limit: Calculated by adding the "Creditor Amount" and "Granted Credit" and then subtracting the "Debtor Amount" And "Queue Amount".',
            creditor: 'Creditor',
            creditorDescription: 'Creditor: Includes the total cash receipts, online or cash deposits, deleted or returned orders and cash back or monthly discounts.',
            debtor: 'Debtor',
            debtorDescription: 'Debtor: Includes all orders and debts.',
            grantedCredit: 'Granted Credit',
            grantedCreditDescription: 'Granted Credit: An amount that is considered when calculating the "Remaining Limit". which allows the customer to confirm their order on credit up to that amount.',
            amountOfGantedCredit: 'Amount of granted credit',
            queue: 'Queue',
            queueDescription: 'Orders are added to the queue for invoice number assignment after confirmation. In this state, the total amount of the orders is deducted from the account balance, and upon exiting the queue, this amount is added back to the account.',
            remainingLimitResultMessage: 'The result is the following values:',
            usersFeedback: "user's Feedbacks",
            profilePicture: 'Profile Picture',
            withGrantedCredit: 'With Granted Credit',
            withoutGrantedCredit: 'Without Granted Credit',
            justGrantedCredit: 'Just Granted Credit',
            defaultUserAddress: 'Default address',
            userProfile: "User's Profile",
            activateProfile: 'Activate Profile',
            deActivateProfile: 'Deactivate Profile',
            activate: 'Activate',
            deactivate: 'Deactivate',
            downloadQrCode: 'Download Qr-Code',
            employeesProfile: "Employee's Profile",
            userNotFoundHint: 'No Information is available from this user.'
        },

        buttons: {
            getFromPbx: 'Get From Pbx',
            addRole: 'Add Role',
        },

        orders: {
            firstOrder: 'First Order',
            lastOrder: 'Last Order',
            isActive: 'Status',
            serverName: 'Server Name',
            isActiveRemote: 'Remote Status',
            isActiveLocal: 'Local Status',
            extendedServices: 'Extended Services',
            filmFile: 'Film File',
            circulation: 'Circulation',
            turnaround: 'Turnaround',
            printedSide: 'Printed Side',
            category: 'Category',
            printProcessTime: 'Print Process Time',
            productDetail: 'Product Detail',
            active: 'Active',
            inactive: 'Inactive',
            orderQueue: 'Share Sheets Orders Queue By {0}',
            storeOrderQueue: 'Store Orders Queue By {0}',
            orderQueueStatusByServerName: 'Share Sheets Orders Queue By {0}',
            setActive: 'Active',
            deactivate: 'Deactivate',
            startDateFileRegulator: 'Start Date File Regulator',
            serverNameFileRegulator: 'Server Name File Regulator',
            estimatedPreparationTime: 'Estimated Delivery Time',
            businessDay: 'Business Day',
            groupSheet: 'Group Sheet',
            store: 'Store',
            dedicated: 'Dedicated',
            readyInTheNext: 'Ready In The Next',
            websiteStatus: 'Website Status',
            nasStatus: 'Comprehensive Accounting System (NAS) Status',
            sheetId: 'Sheet Id',
            ordersSheet: "Order's Sheet",
            storeOrderDetail: 'Store Order Detail',
            groupSheetOrderDetail: 'Group Sheet Order Detail',
            statusInNas: 'Status In NAS',
            statusInWebsite: 'Status In Website',
            sixHours: '6 Hours',
            groupSheetsOrders: 'Group Sheets Orders',
        },

        shippings: {
            shipmentDetails: 'Shipment Detail',
            courierAddress: 'Courier Address',
            address: 'Customer Address',
            shippingRequest: 'Shipping Request',
            shippingRequests: 'Shipping Requests',
            firstLastShippingRequest: 'First/Last Shipping Request',
            fromTo: '{0} to {1}',
            orderDetailSeries: 'Order Detail Series',
            orderSum: 'Order Count',
            shippingLastStatus: 'Shipping Last Status',
            request: 'Request',
            shippingPlan: 'Shipping Plan',
            shippingMethod: 'Shipping Method',
            estimatedDeliveryTimeFrom: 'Estimated Delivery Time From',
            estimatedDeliveryTimeTo: 'Estimated Delivery Time To',
            shippingLastStatusTimeFrom: 'Shipping Last Status Time From',
            shippingLastStatusTimeTo: 'Shipping Last Status Time To',
            shippingRequestTimeFrom: 'Shipping Request Time From',
            shippingRequestTimeTo: 'Shipping Request Time To',
            estimatedDeliveryTimeToShippingMethod: 'Estimated Delivery Time To Shipping Method',
            remainingToEstimatedSendingTimeToShippingmethod: 'Remaining To Estimated Sending Time To Shipping Method',
            orderCount: 'Order Count',
            orderDetailSeriesCount: 'Order Detail Series Count',
            shippingLastStatusTime: 'Shipping Last Status Time',
            shippingRequestTime: 'Shipping Request Time',
            calculationBases: 'Calculation Basis',
            basedOnSeriesInOrderDetail: 'Based On Series In Order Detail',
            basedOnOrderCount: 'Based On Order Count',
            inTheWarehouseShippingRequests: 'In the warehouse Shipping Requests',
            shippingRequestsQueueByLatestStatuses: 'Shipping Requests Queue by Latest Statuses',
            shippingRequestsQueueByShippingMethods: 'Shipping Requests Queue by Shipping Methods',
            estimatedSendingTimeTo: 'Estimated Sending Time To {0}',
        },

        promotions: {
            change: 'Change',
            homeTopPromotion1: 'Home Top Promotion 1',
            homeTopPromotion2: 'Home Top Promotion 2',
            middlePromotion1: 'Middle Promotion 1',
            middlePromotion2: 'Middle Promotion 2',
            middlePromotion3: 'Middle Promotion 3',
            sidebarPromotion1: 'Sidebar Promotion 1',
            sidebarPromotion2: 'Sidebar Promotion 2',
            blogNewsPromotion1: 'Blog and News Promotion 1',
            blogNewsPromotion2: 'Blog and News Promotion 2',
            homeAboveTheAboutPromotion1: 'home Above The About Us Promotion 1',
            homeAboveTheAboutPromotion2: 'home Above The About Us Promotion 2',
            homeAboveTheAboutPromotion3: 'home Above The About Us Promotion 3',
        },

        payments: {
            increaseCredit: 'Increase Credit',
            orderPayment: 'Order Payment',
            success: 'Success',
            open: 'Open',
            failed: 'Failed',
            onlinePayment: "Online Payment",
            usedByBalance: "Used By Balance",
            managerPermit: "Manager Permit",
            usedByDepositCredit: "Used By Deposit Credit",
            missingIncreaseCreditInNas: 'Missing Increase Credit In Nas',
            someOrdersNotConfirmed: 'Some Orders Not Confirmed',
            inAdvanceOrderNotFilled: 'in Advance Order Not Filled',
            moreThanOneSuccessfulPayment: 'More Than One Successful Payment'
        },

        announcement: {
            announcementDetail: 'Announcement Detail',
            newAnnouncement: 'New Announcement',
            editAnnouncement: 'Edit Announcement',
            displayAsModal: 'Display As Modal',
            linkText: 'Link text'
        },

        advertisement: {
            newAdvertisement: 'new Advertisement',
            editAdvertisement: 'Edit Advertisement',
        },

        post: {
            summary: 'Summary',
            subject: 'Subject',
            commentType: 'Comment Type',
            newPost: 'New Post',
            editPost: 'Edit Post',
            postImage: 'Post image',
            category: "Category",
            allowWithAdminConfirmation: "Allow with Admin Confirmation",
            allow: "Allow",
            disable: "Disable",
            recentPosts: 'Recent Posts',
            popularPosts: 'Most Popular in Posts',
            tags: 'Tags',
            tagsInfo: 'Separate Tags with - or ,.',
            showInWebsite: 'show In Website',
        },

        subject: {
            newSubject: 'New Subject',
            editSubject: 'Edit Subject',
            newCategory: 'New Category',
            editCategory: 'Edit Category',
            showInMainPage: 'Show In Main Page'
        },

        singlePage: {
            newAgreement: 'New Agreement',
            editAgreement: 'Edit Agreement',
            approvalUsers: 'Approval Users',
            activeAgreement: 'Active Agreement',
            agreementDetail: 'Agreement Detail',
            agreementStatus: 'Agreement Status',
            activatingAgreement: 'Activating Agreement',
            usersAccepted: 'Agreement Users Accepted',
            acceptedTimeFrom: 'Accepted Time From',
            acceptedTimeTo: 'Accepted Time To',
            selectedAgreement: 'Selected Agreement',
            agreementAttention: 'By Activating this agreement, the Current active agreement will be Inactive.',
            acceptedTime: 'Accepted Time',
        },

        slider: {
            newSlider: 'New Slider',
            editSlider: 'Edit Slider',
            sliderImage: 'Slider image',
            setToActive: 'Set To Active',
            setToInactive: 'Set To Inactive',
            active: 'Active',
            summary: 'Summary',
            imageUrl: 'Image Url',
            summary: 'Summary',
            contentVisibilityMode: 'Content Visibility Mode',
            onlyActiveLinkInLoggingMode: 'only Active Link In Logging Mode',
        },

        standardSizesForUpload: {
            minAndMaxDimensions: 'Min/Max Dimensions: ',
            fileSize: 'Export your file as Save For Web.',
            uploadYourFileCover: 'Upload your file cover.'
        },

        promotion: {
            newPromotion: 'New Promotion',
            editPromotion: 'Edit Promotion',
            promotionImage: 'Promotion image'
        },

        contactInfo: {
            availableTimes: 'Available Times',
            website: 'Website',
            email: 'Email',
            hotline: 'Hotline',
            googleMapLink: 'Google Map Link',
            address: 'address',
        },

        orderStateType: {
            notApplied: 'Not Applied',
            inFirstQueue: 'In First Queue',
            prepare: 'Prepare',
            inProgress: 'In Progress',
            layouting: 'Layouting',
            progressFail: 'Progress Fail',
            prepareFail: 'Prepare Fail',
            lithography: 'Lithography',
            printing: 'Printing',
            cutting: 'Cutting',
            ready: 'Ready',
            delivered: 'Delivered',
            inSecondQueue: 'In Second Queue',
            canceled: 'Canceled',
            preparing: 'Preparing',
            failed: 'Failed',
            inProcess: "In Process",
            cart: "Cart",
            archive: "Archive",
            readyToDeliver: 'Ready To Deliver',
            transferToWarehouse: 'Transfer To Warehouse'
        },

        communicationChannel: {
            phoneNumber: 'Phone Number',
            facebook: 'Facebook',
            instagram: 'Instagram',
            whatsAppPhoneNumber: 'WhatsApp Phone Number',
            youTube: 'YouTube',
            whatsApp: 'WhatsApp',
        },

        metaTags: {
            title: 'Title',
            keyWords: 'Key Words',
            description: 'Description',
            organizationTitle: 'Organization Title',
            organizationType: 'Organization Type',
            organizationImage: 'Organization Image',
            organizationDescription: 'Organization Description',
            organizationSiteName: 'Organization Site Name',
            organizationEmail: 'Organization Email',
            organizationPhoneNumber: 'Organization Phone Number',
            organizationFaxNumber: 'Organization Fax Number',
            organizationLocation: 'Organization Location',
            organizationStreetAddress: 'Organization Street Address',
            organizationLocality: 'Organization Locality',
            organizationRegion: 'Organization Region',
            organizationPostalCode: 'Organization Postal Code',
            countryName: 'Country Name',
            subject: 'Subject',
            author: 'Author',
            replyTo: 'Reply to',
            url: 'Url',
            identifierUrl: 'Identifier Url',
        },

        signupAgeRules: {
            minAge: 'Minimum Age For Sign up',
            maxAge: 'Maximum Age For Sign up'
        },

        telecommunication: {
            sahandSMS: 'Sahand SMS',
            username: 'Username',
            password: 'Password',
            fromNumber: 'From Number',
            senderID: 'Sender ID',
            baseApiURL: 'Base Api URL',
            smartSMS: 'Smart SMS',
            smtp: 'SMTP',
            host: 'Host',
            port: 'Port',
            useDefaultCredential: 'Use Default Credential:'

        },

        notificationConfig: {
            signInNotification: 'Sign In Notification',
            sms: 'Sms:',
            email: 'Email:',
            newOrderNotification: 'New Order Notification'
        },

        supportLanguage: {
            primaryLanguage: 'Primary Language',
            fa: 'Fa'
        },

        fileManagerConfig: {
            maxSpace: 'Maximum Space Per User (MB)'
        },

        mapConfig: {
            mapApiKeys: 'Map API Keys',
            leaflet: 'Leaflet',
            googleMap: 'Google Map'
        },

        agency: {
            productPriceChange: 'Product Price Change / Discount',
            agencyInfo: 'Agency info',
            signedInUser: 'Signed In User',
            signedInUserWithoutAgency: 'Without Agency',
            signedInUserWithSpecificAgencies: 'With Specific Agencies',
        },

        festivals: {
            festivalName: 'Festival name',
            festivalStatus: 'Festival status',
            codeCount: 'Code count',
            prizesCount: 'Prizes count',
            startDate: 'Start date',
            endDate: 'End date',
            executionDate: 'Execution date',
            startDateFrom: 'Start date from',
            startDateTo: 'Start date to',
            endDateTo: 'End date to',
            endDateFrom: 'End date from',
            executionDateTo: 'Execution date to',
            executionDateFrom: 'Execution date from',
            active: 'Active',
            done: 'Done',
            salesApp: 'Sales app',
            executionType: 'Execution type',
            createDate: 'Create date',
            invoiceType: 'Invoice type',
            removeWinnerType: 'Remove winner type',
            pointingType: 'Pointing type',
            winners: 'Winners',
            prizes: 'Prizes',
            winnerName: 'Winner name',
            prizeName: 'Prize name',
            winsCode: 'Wins code',
            dateOfWinning: 'Date of winning',
            prizePhoto: 'Prize photo',
            count: 'Count',
            code: 'Code',
            prizeWinner: 'Prize winner',
            mobileNumber: 'Mobile Number',
            minBuyAmount: 'Minimum Purchase Amount to Participate in Lottery'
        },

        customersFiles: {
            fileName: "File Name",
            fileFormat: "File Format",
            fileSize: "File Size",
            fileSizeFrom: "File Size From",
            fileSizeTo: "File Size To",
            fileDimension: "File Dimension",
            createTimeTo: 'create Time To',
            createTimeFrom: 'create Time From',
            draftFile: 'Draft File',
            thumbnailFile: 'Thumbnail File',
            downloadFile: "Download Original File",
            filesOrder: "Order Detail",
            fileResolution: 'File Resolution'
        },

        dashboard: {
            financialDashboard: 'Financial Dashboard',
            businessDashboard: 'Business Dashboard',
            smsPanelsCreditDashboard: 'SMS Panels Credit Dashboard',
            usersDashboard: 'Users Dashboard',
            dashboardAgencies: 'Agencies Dashboard',
            auditDashboard: 'Audits Dashboard',
            financial: 'Financial',
            business: 'Business',
            smsPanelsCredit: 'SMS Panels Credit',
            users: 'Users',
            agencies: 'Agencies',
            audits: 'Audits',
            feedbacks: 'Feedbacks',
            lastJoinedUsers: 'Last joined Users',
            usersWithGrantedCredit: 'Users Based on Granted Credit',
            totalUsers: 'Total Users',
            totalSales: 'Total Sales',
            allOrders: 'All Orders',
            onlinePaymentStatus: 'Online Payment Status',
            totalOnlinePayments: 'Total Online Payments',
            onlinePaymentsStatus: 'Online Payments Status',
            onlinePaymentsGateway: 'Online Payments Gateway',
            lastOrder: 'Last Order',
            orderByApplicationType: 'Order By Application Type',
            totalAmount: 'Total Amount',
            totalSaleDes: '',
            totalPaymentDes: '',
            totalDiscountDes: '',
            totalVatDes: '',
            totalSalesDfChartDes: '',
            totalOnlinePaymentsChartDes: '',
            onlinePaymentsIpgChartDes: '',
            totalSalesDbChartDes: '',
            totalOnlinePaymentsDbChartDes: '',
            totalUsersChartDes: '',
            orderByApplicationTypeDbChartDes: '',
            orderByTurnaroundDbChartDes: '',
            orderByApplicationTypeDfChartDes: '',
            orderByTurnaroundDfChartDes: '',
            newSubmitted: 'New Submitted',
            popularAgencies: 'Popular Agencies',
            state: "State",
            usersCount: "Users Count",
            ordersAmount: "Orders Amount",
            companyPriceChangePercent: "Price Change",
            agancyDiscountPercent: "Agency Discount",
            stateInfoOnMap: "{0} : {1}",
            noStateInfoOnMap: "There is no data for this state",
            payments: 'Payments',
            mismatch: 'Mismatch',
            audited: 'Audited',
            auditedSubTitle: '{0} Audited + Not Audited (Successfully Payments)',
            paymentNotFound: 'Payment Not Found',
            paymentNotFoundSubTitle: '{0} File Payments',
            lastUploader: 'Last Uploader',
            gatewayResponseTypes: 'Gateway Response Types',
            successNotProcess: 'Not Audited(Successfully Payments)',
            ordersQueue: 'Orders Queue',
            feedbackDashboard: 'Feedback Dashboard',
            cashbackDashboard: 'Cashback Dashboard',
            cashback: 'Cashback',
            province: 'Province',
            customer: 'Customer',
            totalFeedback: 'Total Feedback',
            status: 'Status',
            comments: 'Comments',
            min: 'Min',
            avg: 'Avg',
            max: 'Max',
            lastFeedback: 'Last Feedback',
            customerCashback: 'Customer Cashback',
            cashbackDetail: 'Cashback Detail',
            feedbackDetails: 'Feedback Details',
            productsInfo: "Product's Info",
        },

        download: {
            fileTitle: 'File Title',
            category: "Category",
            createTimeFrom: "Create Time From",
            createTimeTo: "Create Time To",
            status: "Status",
            releaseDate: "Release Date",
            version: "version",
            newFile: "New File",
            editFile: "Edit File",
            cssClass: "Css Class",
            attachFile: "Attach File",
            attachLink: "Attach Link",
            uploadFile: "Upload File",
            details: "Details",
            files: "Files",
            saveAndNextStep: "Save and next step",
            editCategory: 'Edit Category',
            newCategory: 'New Category',
            clearByChangingTab: 'Your uploaded file will be deleted if you switch tabs, do you want to continue?'
        },

        audit: {
            notProcessed: 'Not Processed',
            paymentNotFound: 'Payment Not Found',
            amountMismatch: 'Amount Mismatch',
            stateTypeMismatch: 'State Type Mismatch',
            amountAndStateTypeMismatch: 'Amount And State Type Mismatch',
            matched: 'Matched',
            alternativePaymentAttempt: 'Alternative Payment Attempt',
            auditpaymentHistory: 'Audit Payment History',
            auditpaymentAttempt: 'Audit Payment Attempt',
            importedFileDetail: 'Imported File Detail',
            isConfirmed: 'Is Confirmed',
            isNotConfirmed: 'Is Not Confirmed',
            guidanceTitle: 'The file you want to upload must match the specified format.',
            guidanceGatewayFile: 'Guidance {0}',
            updateMatchingStatus: 'Update Matching Status',
            updateRecordsMatchingStatus: 'Update {0} Records Matching Status',
            updateRecordsMatchingStatusTooltip: 'To update "Matching Status", first select the payment gateway, and use the search filter options. then, by pressing the "Update Matching Status" button, the "Matching Status" of filtered results will be refreshed.',
            changeOpenStatusToFail: 'Change Open Website Status To Fail',
            basicMode: 'Basic',
            advanceMode: 'Advance',
            attempts: 'Attempts',
            history: 'History',
            details: 'Details',
            oldWebiteStoreProduct: 'Old Website Store Product',
        },
        productContent: {
            productContentDetail: 'Product Content Detail',
            metaKeyword: 'Meta Keyword',
            metaKeywordInfo: 'Separate meta keywords with - or ,.',
            metaDescription: 'Meta Description',
            overview: 'Overview',
            faqs: 'FAQs'
        },
        feedback: {
            feedback: 'Feedback',
            feebackDetail: 'Feedback Detail',
            feedbackOrders: "User's Feedback to Orders",
            feedbackUsers: "User's Feedback to Employees",
            feedbackAnnouncements: "User's Feedback to Announcements",
            metricsDetails: 'Metrics Details',
            ratingParameter: 'Rating Parameter',
            demandService: 'Demand Service',
            strengths: 'Strengths',
            weaknesses: 'Weaknesses',
            feedbackType: 'Feedback Type',
            newFeedbackMetric: 'New {0}',
            editFeedbackMetric: 'Edit {0}',
            overall: 'Overall',
            pending: 'Not Checked',
            inprocess: 'In Follow Up',
            archive: 'Archive (done)',
            callMe: 'Call Me',
            originalComment: 'Original Comment',
            comment: 'Comment',
            activityHistory: 'Activity History',
            sendFeedback: 'Feedback',
            newActivity: 'Change Status',
            activityState: 'State',
            feedbackTo: 'Feedback To {0}',
            changeStatus: 'Change Status',
            displayNottoAll: 'No Display Anyone',
            orderDetail: 'Order Detail',
            hasDemandService: 'Has Demand Service',
            hasComment: 'Has Commnet',
            feedbackToUser: 'Feedback to User',
            thisMessageIsVisibleToUser: 'This Message is Visible to User.',
            employees: 'Employees',
            lastUserFeedbackStateTypeId: 'Last feedback State',
            customerFeedbackTo: 'Customer Feedback To'

        },
        cashback: {
            currentMonth: 'Current Month',
            currentMonthWithVs: 'Current Month VS ',
            lastMonthsOfThisYear: 'From Beginning of Year to Before Current Month',
            totalSalesAmountEligibleForCashback: 'Total Sales Amount eligible for Cashback',
            totalCashbackAmount: 'Total Cashback Amount',
            qtyOfCustomersIncludedInCashback: 'Qty of Customers included in cashback',
            reachedAtLeastTheFirstCashbackPlan: '(Reached at least The first cashback plan)',
            specialVsCommon: 'Customers with Special Plan Vs Common Plans (Eligible for Cashback)',
            eligibleForCashback: '(Eligible for Cashback)',
            customersCommonPlans: 'Customers with Common Plans',
            qtyOfCustomers: 'Qty of Customers',
            totalSalesAmount: 'Total Sales Amount',
            totalCashbackAmount: 'Total Cashback Amount',
            avgSalesAmount: 'Avg Sales Amount',
            avgCashbackPercentage: 'Avg Cashback Percentage',
            customersSpecialPlan: 'Customers with Special Plan',
            cashbackPlans: 'Cashback (Percent) Plans',
            qtyOfCustomersNotReached: 'Qty of Customers not reached The Cashback Plan.',
            withSubmittedOrders: '(With submitted orders)',
            totalSalesAmountOfCustomersWhoHaveNotReached: 'Total Sales Amount of customers not reached The Cashback Plan.',
            common: 'Common Plan',
            special: 'Special Plan',
            noPlan: 'No Plan',
            withoutCashback: 'Without Cashback',
            cashbackAmount: 'Cashback Amount',
            purchaseAmount: 'Purchase Amount',
            qtyOfCustomers: 'Qty Of Customers',
            basedOn: 'Based on',
            from: 'From',
            to: 'To',
            vs: '{0} VS {1}',
            sumNetSale: 'Total Sales Amount',
            totalCashbackAmount: 'Total Cashback Amount',
            countAchievedUserToDiscount: 'Qty of Customers Included in Cashback',
            customerWithSpecialPlan: 'Customer With Special Plan',
            customerWithCommonPlan: 'Customer With Common Plan',
            countUser: 'Qty of Customers',
            averageSaleAmount: 'Avg Sales Amount',
            averageCashbackPercentage: 'Avg Cashback Percentage',
            qtyOfCustomersNotReachedTheCashbackPlan: 'Qty of Customers Not Reached The Cashback Plan',
            totalSalesOfCustomersNotReachedTheCashbackPlan: 'Total Sales Amount of Customers Not Reached The Cashback Plan'
        }
    }
};

export default AdminPanelTranslationEn;