import React, { Component } from "react";
import clsx from 'clsx';

import NsPageMainContentWrapper from "../../../../components/cards/NsPageMainContentWrapper";
import NsHtmlToReact from "../../../../components/htmlToReact/NsHtmlToReact";
import NsTypography from "../../../../components/text/NsTypography";
import Enumerations from "../../../../configs/Enumerations";
import Links from "../../../../configs/links/AllLinks";
import { GetCmsSinglePageService } from "../../../../services/singlePages/CmsService";
import { getTranslatedRawText, getUrlWithCurrentCulture, removeQueryFromURL } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import SinglePage from "../../SinglePage";
import NsSkeleton from "../../../../components/skeletons/NsSkeleton";

class TermOfUse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            termsOfUse: {},
            resultMessageClass: '',
            isLoading: false
        }
    }

    componentDidMount = () => { this.getContent() }

    getContent = () => {
        this.setState({ isLoading: true });
        GetCmsSinglePageService(Enumerations.staticPageType.termOfUse, this.GetPageContentCallback);
    }

    GetPageContentCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ termsOfUse: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    tableContent = () => {
        const titles = this.state.termsOfUse.staticPages;
        const tableTitles = !isEmpty(titles) && titles.map((item, index) =>
            <a key={index} href={`${removeQueryFromURL(getUrlWithCurrentCulture(Links, Links.termsOfUse))}#${index}`} className='table-content d-block pb-1'>
                {item.isTitleVisible && item.title}
            </a>
        );
        return tableTitles;
    }

    render() {
        const { termsOfUse, resultMessageClass, isLoading } = this.state;
        const isTitleVisible = !isEmpty(termsOfUse) && termsOfUse.staticPages.some(item => item.isTitleVisible === true);
        const summary = termsOfUse?.staticPageType?.summary;
        return (
            <SinglePage >

                <NsPageMainContentWrapper header={getTranslatedRawText('singlePage.termsOfUse')}
                    icon='ns-icon-terms-of-use h1' className='mb-3'>
                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                        : <>
                            {!isEmpty(summary) && <p>{summary}</p>}

                            {isTitleVisible &&
                                <div className='d-inline-block background-element-color p-3 rounded'>
                                    <NsTypography variant='inherit' className='d-inline-block section-title mb-2'>
                                        {getTranslatedRawText('singlePage.privacyPolicy.tableOfContent')}
                                    </NsTypography>
                                    {this.tableContent()}
                                </div>}

                            <TermsOfUseContent termsOfUse={termsOfUse}
                                isTitleVisible={isTitleVisible} />
                        </>
                    }
                </NsPageMainContentWrapper>
                {resultMessageClass}
            </SinglePage >
        );
    }
}

export function TermsOfUseContent(props) {
    const { termsOfUse, isTitleVisible } = props;
    return !isEmpty(termsOfUse) && termsOfUse.staticPages.map((item, index) =>
        <div key={item.id} id={index} className={clsx(isTitleVisible && "pt-5")}>
            {item.isTitleVisible && <NsTypography key={index} variant='inherit' className='d-block section-title'>
                {item.title}
            </NsTypography>}
            <div className='mt-3'>
                <NsHtmlToReact>
                    {item.mainContent}
                </NsHtmlToReact>
            </div>
        </div>
    )
}

export default TermOfUse;