import React, { useEffect, useState } from 'react';

import { GetAdminBillBookOptionsService } from '../../../../../../services/adminPanel/AdminOrderService';
import NsLoadingProgress from '../../../../../../components/circularProgress/NsLoadingProgress';
import NsModal from '../../../../../../components/modals/NsModal';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import BillBookPreviewModalContent from '../../../../../orders/modals/billBookPreview/BillBookPreviewModalContent';

const ApOrderBillBookModal = (props) => {

    const { id, userId, draft, resolution } = props;

    const [resultData, setResultData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        GetAdminBillBookOptionsService(id, userId, getBillBookOptionsCallback);
    }, []);

    const getBillBookOptionsCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            setResultData(resultData);
        } else
            setResultMessageClass(messageClassModal);
        setIsLoading(false);
    }

    return (
        <NsModal showModal={true}
            maxWidth='md'
            closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
            dialogTitle={getTranslatedRawText("regularOrder.billBook.previewBillBook")} >

            <NsLoadingProgress visible={isLoading} />

            {isEmpty(resultData)
                ? <div>
                    {isLoading
                        ? getTranslatedRawText('fileManager.modals.rename.isLoading')
                        : getTranslatedRawText('components.nsDataGrid.noRowData')
                    }
                </div>
                : <BillBookPreviewModalContent masterFile={{ draft, resolution }}
                    billBookCopy={{ billBookCopyType: resultData.billBookCopyType }}
                    billBookPrintColor={resultData.billBookPrintColorType}
                    billBookBindingSideType={resultData.billBookBindingSideType}
                    billBookBindingType={resultData.billBookBindingType}
                    hasUnderhand={resultData.hasUnderHand}
                    serialStartFrom={resultData.serialStartFrom}
                    trimZone={null}
                    serialBoxDimension={resultData.serialBoxDimension}
                    selectedSerials={resultData.selectedSerials}
                    selectedPerforations={resultData.selectedPerforations} />
            }
            {resultMessageClass}

        </NsModal >
    )
}

export default React.memo(ApOrderBillBookModal);