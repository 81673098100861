import React, { useState } from 'react';

import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../../bases/ApBaseSearchButtons';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';

const ApBusinessPartnersSearch = (props) => {

    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const searchFields = [
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            fieldName: 'fullName',
            disabled: props.isLoading,
            value: fullName,
            onChange: (value) => setFullName(value),
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('adminPanel.communicationChannel.phoneNumber'),
            fieldName: 'phoneNumber',
            value: phoneNumber,
            onChange: (value) => setPhoneNumber(value)
        },
    ]

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const searchClicked = () => {
        let data = {
            phoneNumber,
            fullName
        }
        CommonHelper.safeFunctionCall(props.searchClicked, data);
    }

    const clearButtonClicked = () => {
        setFullName('');
        setPhoneNumber('');
        CommonHelper.safeFunctionCall(props.searchClicked, {});
    }

    const searchValues = {
        fullName,
        phoneNumber,
    }

    return (
        <div onKeyDown={handleKeyDown}>
            <NsAdvanceSearch searchOptions={searchFields}
                searchValues={searchValues} mdCol={3} smCol={6} />
            <ApBaseSearchButtons searchClicked={searchClicked}
                clearButtonClicked={clearButtonClicked} />
        </div>
    );
};

export default React.memo(ApBusinessPartnersSearch);