import { getTranslatedRawText } from "../../../utilities/CultureHelper";

export const ConvertArrayToTreeList = (folders) => {
    let treeItems = [{ id: null, name: getTranslatedRawText("fileManager.root"), parentId: 1, isFolder: true },
    ...folders.map(folder => { if (!folder.parentId) folder.parentId = null; return folder; })];

    for (let folder of treeItems) {
        if (!folder.children)
            folder.children = [];
        let parent = treeItems.find(f => f.id === folder.parentId);
        if (parent) {
            if (!parent.children) parent.children = [];
            parent.children.push(folder);
        }
    }
    return [createPathFromRoot(treeItems)];
}

function createPathFromRoot(tree) {
    var treeRoot = tree.find(x => x.parentId === 1);
    treeRoot.path = [treeRoot.id];
    treeRoot.pathRef = [treeRoot];
    scanTheTree(treeRoot);
    return treeRoot;
}

function scanTheTree(node) {
    for (let child of node.children) {
        child.path = [...node.path, child.id];
        child.pathRef = [...node.pathRef, child];
        scanTheTree(child);
    }
}

export const createAdminUploaderTreeList = () => {
    return ConvertArrayToTreeList([
        {
            id: '5',
            parentId: null,
            name: getTranslatedRawText('fileManager.admin.announcement'),
            isFolder: true,
            thumbnail: null,
            draft: null,
            fullPath: null,
            hasChild: false,
            width: null,
            height: null,
            volume: 0
        },
        {
            id: '8',
            parentId: null,
            name: getTranslatedRawText('fileManager.admin.blog'),
            isFolder: true,
            thumbnail: null,
            draft: null,
            fullPath: null,
            hasChild: false,
            width: null,
            height: null,
            volume: 0
        },
        {
            id: '2',
            parentId: null,
            name: getTranslatedRawText('fileManager.admin.blogCategory'),
            isFolder: true,
            thumbnail: null,
            draft: null,
            fullPath: null,
            hasChild: false,
            width: null,
            height: null,
            volume: 0
        },
        {
            id: '3',
            parentId: null,
            name: getTranslatedRawText('fileManager.admin.news'),
            isFolder: true,
            thumbnail: null,
            draft: null,
            fullPath: null,
            hasChild: false,
            width: null,
            height: null,
            volume: 0
        },
        {
            id: '4',
            parentId: null,
            name: getTranslatedRawText('fileManager.admin.newsCategory'),
            isFolder: true,
            thumbnail: null,
            draft: null,
            fullPath: null,
            hasChild: false,
            width: null,
            height: null,
            volume: 0
        },
        {
            id: '7',
            parentId: null,
            name: getTranslatedRawText('fileManager.admin.promotion'),
            isFolder: true,
            thumbnail: null,
            draft: null,
            fullPath: null,
            hasChild: false,
            width: null,
            height: null,
            volume: 0
        },
        {
            id: '6',
            parentId: null,
            name: getTranslatedRawText('fileManager.admin.slider'),
            isFolder: true,
            thumbnail: null,
            draft: null,
            fullPath: null,
            hasChild: false,
            width: null,
            height: null,
            volume: 0
        },

    ])
}

