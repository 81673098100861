import clsx from 'clsx';
import React, { Component } from 'react';

import { GetShippingCostService } from '../../../../../services/ProfileSetting/DeliveryAddressSettingService';
import EnumerationTranslationHelper from '../../../../../utilities/EnumerationTranslationHelper';
import UpShippingMethodInformationModal from '../modals/UpShippingMethodInformationModal';
import UpBaseShippingEstimatedTimeSheet from '../bases/UpBaseShippingEstimatedTimeSheet';
import NsHtmlToReact from '../../../../../components/htmlToReact/NsHtmlToReact';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsTypography from '../../../../../components/text/NsTypography';
import NsTooltip from '../../../../../components/tooltips/NsTooltip';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsMenu from '../../../../../components/menu/NsMenu';
import DateHelper from '../../../../../utilities/DateHelper';
import NsCollapse from '../../../../../components/dropdowns/NsCollapse';

export class UpShippingRequestHeaderCostContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shippingCost: null,
            showUpShippingsMethodInformationModal: false,
            resultMessageClass: <></>,
        }
    }

    componentDidMount = () => this.getShippingCost();

    componentDidUpdate = (prevProps, prevState) => {
        const { selectedShippingMethod, selectedShippingPlan } = this.props;

        if ((selectedShippingMethod?.id !== prevProps?.selectedShippingMethod?.id)
            || (selectedShippingPlan?.key !== prevProps?.selectedShippingPlan?.key)) {
            this.getShippingCost();
        }
        if (prevProps.shippingCost !== this.props.shippingCost)
            this.setState({ shippingCost: this.props.shippingCost })
    }

    toggleUpShippingsMethodInformationModal = () => this.setState({ showUpShippingsMethodInformationModal: !this.state.showUpShippingsMethodInformationModal })

    getShippingCost = () => {
        const { selectedShippingMethod, selectedShippingPlan } = this.props;
        let parameters = {
            ShippingMethodId: selectedShippingMethod?.id || selectedShippingMethod?.shippingMethodId,
            ShippingPlanTypeId: selectedShippingPlan?.key,
        }
        if ((!isEmpty(selectedShippingMethod?.id) || !isEmpty(selectedShippingMethod?.shippingMethodId)) && (!isEmpty(selectedShippingPlan?.key))) GetShippingCostService(parameters, this.getShippingCostCallback);
    }

    getShippingCostCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({ shippingCost: resultData });
            CommonHelper.safeFunctionCall(this.props.shippingCost, resultData)
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    additionalCostOptions = (shippingMethodCategoryTypeId) => {
        let howToGet = <div onClick={this.toggleUpShippingsMethodInformationModal}
            className='d-flex justify-content-between align-items-center cursor-pointer'>
            <NsTypography variant='inherit' className='text-color font-size-14 my-1 text-decoration-underline'>{getTranslatedRawText('profile.delivery.howToGet')}</NsTypography>
        </div>;
        // let shippingToday = <div onClick={this.shippingToDay}
        //     className='d-flex justify-content-between align-items-center my-1 cursor-pointer'>
        //     <div className='d-flex align-items-end align-items-center'>
        //         <i className="ns-icon-courier info-color fs-6 mx-1" />
        //         <NsTypography variant='inherit' className='font-size-14 my-1 info-color'>{getTranslatedRawText('profile.delivery.shippingToday')}</NsTypography>
        //     </div>
        // </div>

        switch (shippingMethodCategoryTypeId) {
            case Enumerations.shippingMethodCategoryType.cargo: return [howToGet];
            case Enumerations.shippingMethodCategoryType.inPersonDelivery: return [
                howToGet,
                // shippingToday
            ]
            default: return;
        }
    }

    render() {
        const { shippingCost, showUpShippingsMethodInformationModal, resultMessageClass } = this.state;
        const { selectedShippingMethod, selectedShippingPlan, showShippingTodayMessageOnCost, shippingTime } = this.props;
        const isInPersonDelivery = selectedShippingMethod?.shippingMethodCategoryTypeId == Enumerations.shippingMethodCategoryType.inPersonDelivery;

        return (
            <div className={clsx(!CommonHelper.isMobileDevice() && 'wrapper-white my-2', 'p-2')}>
                {!CommonHelper.isMobileDevice() && <NsTypography variant='inherit' className='text-color font-size-18 fw-bold my-1'>{getTranslatedRawText('profile.delivery.cost')}</NsTypography>}
                {!isEmpty(shippingCost?.packagingCost) &&
                    <div className='d-flex justify-content-between align-items-center'>
                        <NsTypography variant='inherit' className='text-color font-size-14 my-1'>{getTranslatedRawText('profile.delivery.packaging')}</NsTypography>
                        <NsTypography variant='inherit' className='font-size-14 my-1 fw-bold'>{CommonHelper.rtlFriendlyDefaultCurrencyFormat(shippingCost?.packagingCost)}</NsTypography>
                    </div>}
                {!isEmpty(shippingCost?.extraOptionCost) &&
                    <div className='d-flex justify-content-between align-items-center'>
                        <NsTypography variant='inherit' className='text-color font-size-14 my-1'>{getTranslatedRawText('profile.delivery.extraOption')}</NsTypography>
                        <div className='d-flex align-items-center gap-1'>
                            <NsTypography variant='inherit' className='font-size-14 my-1 fw-bold'>{CommonHelper.rtlFriendlyDefaultCurrencyFormat(shippingCost?.extraOptionCost)}</NsTypography>
                            <NsTooltip title={getTranslatedRawText('profile.delivery.expressServiceTooltip')}>
                                <i className='ns-icon-question fs-5' />
                            </NsTooltip>
                        </div>
                    </div>}
                {!isEmpty(shippingCost?.shippingToCarrierCost) && !isInPersonDelivery &&
                    <div className='d-flex justify-content-between align-items-center'>
                        <NsTypography variant='inherit' className='text-color font-size-14 my-1'>{getTranslatedRawText(selectedShippingMethod?.shippingMethodCategoryTypeId == Enumerations.shippingMethodCategoryType.cargo ? 'profile.delivery.toCarrierCost' : 'profile.delivery.toDoorInDoorCost')}</NsTypography>
                        <NsTypography variant='inherit' className='font-size-14 my-1 fw-bold'>{CommonHelper.rtlFriendlyDefaultCurrencyFormat(shippingCost?.shippingToCarrierCost)}</NsTypography>
                    </div>}
                {!!shippingCost?.shippingPaymentTypeId && selectedShippingMethod?.shippingMethodCategoryTypeId !== Enumerations.shippingMethodCategoryType.inPersonDelivery
                    && <div className='d-flex justify-content-between align-items-center'>
                        <NsTypography variant='inherit' className='text-color font-size-14 my-1'>{getTranslatedRawText('profile.delivery.shipping')}</NsTypography>
                        <div className='d-flex align-items-center gap-1'>
                            <NsTypography variant='inherit' className='font-size-14 my-1 fw-bold'>
                                {selectedShippingMethod?.shippingMethodCategoryTypeId === Enumerations.shippingMethodCategoryType.inPersonDelivery
                                    ? CommonHelper.rtlFriendlyDefaultCurrencyFormat(0)
                                    : EnumerationTranslationHelper.getShippingPaymentType(shippingCost?.shippingPaymentTypeId)}</NsTypography>

                            {shippingCost?.shippingPaymentTypeId === Enumerations.shippingPaymentType.postpaid &&
                                <NsTooltip title={getTranslatedRawText('profile.delivery.shippingMethodTooltip')}>
                                    <i className='ns-icon-question fs-5' />
                                </NsTooltip>}

                        </div>
                    </div>}
                {!isEmpty(selectedShippingMethod) &&
                    <div className='d-flex justify-content-between align-items-center'>
                        <NsTypography variant='inherit' className='text-color font-size-14 my-1'>
                            <NsHtmlToReact>
                                {CommonHelper.stringFormat(getTranslatedRawText(isInPersonDelivery ? 'profile.delivery.deliveryTimeTo' : 'profile.delivery.exitingWarehouseTimeTo'),
                                    `<span className='fw-bold'>${selectedShippingMethod?.name}</span>`
                                )}
                            </NsHtmlToReact>
                        </NsTypography>
                        {!!this.props.isAdminPanel
                            ? <NsTypography variant='inherit' className='fw-bold'>{DateHelper.getDateAndTime(shippingTime)}</NsTypography>
                            : <NsMenu buttonStartIcon={<i className='ns-icon-expand-down' />}
                                buttonClassName={clsx('p-0 m-0 fw-bold', isEmpty(shippingTime) ? 'primary-text' : ' secondary-text')}
                                buttonLabel={<div className=' fw-bold font-size-14'>{isEmpty(shippingTime) ? getTranslatedRawText('profile.delivery.notAvailable') : DateHelper.getDateAndTime(shippingTime)}</div>}
                                placement='bottom-end'
                                buttonSize='small'>
                                <div className='d-flex justify-content-center p-2'>
                                    <UpBaseShippingEstimatedTimeSheet selectedShippingPlan={selectedShippingPlan}
                                        shippingRequestTime={selectedShippingMethod.requestDate}
                                        selectedShippingMethod={selectedShippingMethod} />
                                </div>
                            </NsMenu>
                        }
                    </div>
                }
                {(selectedShippingMethod?.shippingMethodCategoryTypeId || selectedShippingMethod?.id) && this.additionalCostOptions(selectedShippingMethod?.shippingMethodCategoryTypeId)}
                {showShippingTodayMessageOnCost &&
                    <NsButton label='profile.delivery.shippingToday'
                        startIcon='ns-icon-courier'
                        size='small'
                        onClick={this.props.expressShippingClicked} />
                }

                {showUpShippingsMethodInformationModal &&
                    <UpShippingMethodInformationModal selectedShippingMethod={selectedShippingMethod}
                        selectedShippingPlan={selectedShippingPlan}
                        closeModal={this.toggleUpShippingsMethodInformationModal} />}

                {resultMessageClass}
            </div>
        )
    }
};

export default class UpShippingRequestHeaderCost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shippingCost: null,
        };
    }

    shippingCostChanged = (shippingCost) => this.setState({ shippingCost });

    render() {
        const { shippingCost } = this.state;
        return (
            CommonHelper.isMobileDevice()
                ? <div className='wrapper-white rounded px-1 my-2'>
                    <NsCollapse showChildrenForSeo={true}
                        defaultIsOpen
                        titleClassName='font-size-16 fw-bold'
                        title={
                            <div className='d-flex justify-content-between align-items-center px-2 py-1'>
                                <NsTypography variant='inherit' className='text-color font-size-16 fw-bold my-1'>{getTranslatedRawText('profile.delivery.cost')}</NsTypography>
                                <NsTypography variant='inherit' className='d-flex font-size-14 color-text'>
                                    {CommonHelper.rtlFriendlyDefaultCurrencyFormat(shippingCost?.extraOptionCost + shippingCost?.packagingCost + shippingCost?.shippingToCarrierCost)}&nbsp;+&nbsp;
                                    {EnumerationTranslationHelper.getShippingPaymentType(shippingCost?.shippingPaymentTypeId)}
                                </NsTypography>
                            </div>
                        }>
                        <UpShippingRequestHeaderCostContent {...this.props} shippingCost={this.shippingCostChanged} />
                    </NsCollapse>
                </div>
                :
                < UpShippingRequestHeaderCostContent {...this.props} />
        )
    }
};