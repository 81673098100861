import React from 'react';

import NsGroupNumberInput from '../../components/inputs/NsGroupNumberInput';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from '../../configs/Enumerations';

class NsEditFileActionResize extends React.Component {

    imgWidthChanged = (imgWidth) => this.applyResizeClicked(imgWidth, this.props.imgHeight);

    imgHeightChanged = (imgHeight) => this.applyResizeClicked(this.props.imgWidth, imgHeight);

    applyResizeClicked = (width, height) => {
        let newAction = {
            fileChangeType: Enumerations.fileActions.resize,
            width: width,
            height: height
        };
        CommonHelper.safeFunctionCall(this.props.imgContentSizeChanged, [newAction], width, height);
    }

    render() {
        const { imgWidth, imgHeight } = this.props;
        return (
            <div className='d-flex  align-items-end w-100 py-3 px-md-2 background-white-color'>
                <NsGroupNumberInput label="regularOrder.common.width"
                    className='me-2'
                    value={Math.round(imgWidth) || null}
                    maxLength={3}
                    autoFocus={true}
                    unit={getTranslatedRawText('common.mm')}
                    step={1}
                    onChanged={this.imgWidthChanged} />

                <NsGroupNumberInput label="regularOrder.common.height"
                    value={Math.round(imgHeight) || null}
                    maxLength={3}
                    unit={getTranslatedRawText('common.mm')}
                    step={1}
                    onChanged={this.imgHeightChanged} />
            </div>
        );
    }
}

export default NsEditFileActionResize;