import clsx from 'clsx';
import React from 'react';
import { Table } from 'react-bootstrap';

import { isBusinessPartnerRoute } from '../../utilities/BusinessPartnerHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsProductGuidance from '../guidance/NsProductGuidance';
import NsDownloadFile from '../downloadFiles/NsDownloadFile';
import { isEmpty } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from '../../configs/Enumerations';
import OrderHelper from '../../utilities/OrderHelper';
import NsSkeleton from '../skeletons/NsSkeleton';
import NsTypography from '../text/NsTypography';
import NsButton from '../buttons/NsButton';
import NsTooltip from '../tooltips/NsTooltip';
import NsHtmlToReact from '../htmlToReact/NsHtmlToReact';

class NsAvailableDimensionTable extends React.Component {

    constructor(props) {
        super(props);
        this.guidancesUrls = props.showGuidance && OrderHelper.findOrderById(this.props.routeId).guidancesUrls;
        this.state = {
            file: null,
            isTooltipOpen: false,
            isLatTooltipOpen: false
        }
    }

    downloadFileClicked = (productDesignTemplateUrl) => this.setState({ file: { fileDownloadName: null, fileContents: CommonHelper.createImageUrl(productDesignTemplateUrl) } });

    tableRows = (isAllProductAvailable, hasProductDesignTemplateUrl) => {
        const { rows, isLoading, usedInOrderProcess, isAuthenticated } = this.props;

        if (isEmpty(rows))
            return <tr>
                <td>
                    {isLoading
                        ? <TableRowSkeleton className='flex-grow-1 ms-2' />
                        : <NsTypography className='red-color'>{getTranslatedRawText('product.detail.dimensionNotFound')}</NsTypography>
                    }
                </td>
            </tr>
        else
            return rows.map((row, index) => {
                let rowColor, label = 'regularOrder.modal.use', disabled = false
                const availableColumnText = !row.isAvailable && getTranslatedRawText('product.detail.unavailable');
                if (!row.isAvailable) {
                    rowColor = 'red-color';
                    label = 'regularOrder.modal.use';
                    disabled = true;
                }
                if (row.isSelected && !this.props.isCustomDimension) {
                    rowColor = 'success-color';
                    label = 'regularOrder.modal.used';
                    disabled = true;
                }
                return <tr key={index} className={'m-0 font-size-14'}>
                    <td>
                        <div className={clsx('d-flex font-size-14 me-3', rowColor)}>
                            {isLoading
                                ? <TableRowSkeleton className='flex-grow-1 ms-2' />
                                : CommonHelper.stringFormat(getTranslatedRawText('product.detail.rowDimension'), row.name, row.width, row.height)
                            }
                        </div>
                    </td>
                    {row.latCount > 1 &&
                        <td className='font-size-14'>
                            {isLoading
                                ? <TableRowSkeleton />
                                : <div className={rowColor}>{row.latCount}</div>
                            }
                        </td>
                    }
                    {isAuthenticated &&
                        <>
                            <td>
                                <div className={clsx('font-size-14 text-wrap me-3 w-100', rowColor)} >
                                    {isLoading
                                        ? <TableRowSkeleton className='flex-grow-1 ms-2' />
                                        : usedInOrderProcess || isBusinessPartnerRoute()
                                            ? `${CommonHelper.currencyFormatByDefaultConfig(row.basePrice)}`
                                            : <div>
                                                {!!row?.basePriceInCurrentCurrencyWithoutOnlineDiscount ? <div className='text-decoration-line-through w-auto light-text-color'>{CommonHelper.currencyFormat(row?.basePriceInCurrentCurrencyWithoutOnlineDiscount)}</div> : ''}
                                                {!!row.basePriceInCurrentCurrency
                                                    ? CommonHelper.currencyFormat(row.basePriceInCurrentCurrency)
                                                    : CommonHelper.currencyFormat(row.basePrice)}
                                            </div>
                                    }
                                </div>
                            </td>
                            <td>
                                <div className={clsx('font-size-14 text-wrap me-3 w-100', rowColor)} >
                                    {isLoading
                                        ? <TableRowSkeleton className='flex-grow-1 ms-2' />
                                        : usedInOrderProcess || isBusinessPartnerRoute()
                                            ? `${CommonHelper.currencyFormatByDefaultConfig(row.basePriceIncludeVat)}`
                                            : <div>
                                                {!!row?.basePriceInCurrentCurrencyWithoutOnlineDiscountIncludeVat ? <div className='text-decoration-line-through w-auto light-text-color d-flex'>{CommonHelper.currencyFormat(row?.basePriceInCurrentCurrencyWithoutOnlineDiscountIncludeVat)}</div> : ''}
                                                {!!row.basePriceInCurrentCurrencyIncludeVat
                                                    ? CommonHelper.currencyFormat(row.basePriceInCurrentCurrencyIncludeVat)
                                                    : CommonHelper.currencyFormat(row.basePriceIncludeVat)}
                                            </div>}
                                </div>
                            </td>
                        </>
                    }
                    {!isAllProductAvailable &&
                        <td className='fw-bold'>
                            {isLoading
                                ? <TableRowSkeleton />
                                : <div className={rowColor}>{availableColumnText}</div>
                            }
                        </td>
                    }
                    {hasProductDesignTemplateUrl &&
                        <td className='fw-bold'>
                            {isLoading
                                ? <TableRowSkeleton />
                                : <NsButton className='secondary-text'
                                    startIcon='ns-icon-download fs-6'
                                    size='small'
                                    onClick={() => this.downloadFileClicked(row.productDesignTemplateUrl)}
                                    disabled={isEmpty(row.productDesignTemplateUrl)} />
                            }
                        </td >
                    }
                </tr >
            });
    }

    toggleTooltipClicked = () => this.setState({ isTooltipOpen: !this.state.isTooltipOpen });

    toggleLatTooltipClicked = () => this.setState({ isLatTooltipOpen: !this.state.isLatTooltipOpen });

    findHighestDiscountPercentage = (arrays) => {
        if (this.props?.rows?.length < 0) return;
        let discountPercentages = [];

        for (let i = 0; i < arrays.length; i++) {
            discountPercentages.push(arrays[i]?.basePriceOnlineDiscountPercent);

        };
        return Math.max(...discountPercentages);
    }

    render() {
        const { isAuthenticated, showGuidance, label, isLoading } = this.props;
        const { isTooltipOpen, isLatTooltipOpen } = this.state;
        const isAllProductAvailable = this.props.rows.every(item => item.isAvailable);
        const hasOneProductUps = this.props.rows.some(item => item.latCount > 1);
        const hasProductDesignTemplateUrl = this.props.rows.some(item => !!item.productDesignTemplateUrl);

        return (
            <>
                {!!label &&
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <NsTypography variant='inherit' className='section-title d-flex'>
                            {label}
                            <NsTooltip open={isTooltipOpen}
                                title={<NsHtmlToReact>
                                    {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.availableDimesionTooltip'),
                                        `<span className='fw-bold'>${getTranslatedRawText('regularOrder.confirmDetail.startOrdering')}</span>`)}
                                </NsHtmlToReact>}
                                closeTooltip={() => this.setState({ isTooltipOpen: false })}>
                                <NsButton className='text-color p-0 font-size-18'
                                    startIcon='ns-icon-question'
                                    onClick={this.toggleTooltipClicked} />
                            </NsTooltip>
                        </NsTypography>
                        {showGuidance && <NsProductGuidance guidancesUrls={this.guidancesUrls} />}
                    </div>
                }
                <div className=' overflow-auto custom-scrollbar'>
                    <Table className={clsx(this.props.isCustomDimension ? 'custom-dimension__available-dimension__table' : 'w-auto', 'mt-1 m-0 border table-bordered font-size-14 table-sm mb-2')} size={this.props.size}>
                        <thead>
                            <tr className='background-element-color title-color'>
                                <th className='text-nowrap text-center background-element-color align-content-center'>{getTranslatedRawText('regularOrder.modal.dimension')}</th>
                                {hasOneProductUps && <th className='text-center text-nowrap background-element-color align-content-center'>{getTranslatedRawText('regularOrder.modal.ups')}
                                    <NsTooltip className={'ms-1'} title={getTranslatedRawText('common.messages.latDescription')}>
                                        <i className='ns-icon-question' />
                                    </NsTooltip>
                                </th>}
                                {isAuthenticated && <th className='text-center background-element-color px-2 align-content-center'>{getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat')}</th>}
                                {isAuthenticated && <th className='text-center background-element-color px-2 align-content-center'>{getTranslatedRawText('profile.orderCart.modal.TotalPriceWithVat')}</th>}
                                {!isAllProductAvailable && <th className='text-center text-nowrap background-element-color align-content-center'>{getTranslatedRawText('regularOrder.confirmDetail.status')}</th>}
                                {hasProductDesignTemplateUrl && <th className='text-center text-nowrap background-element-color align-content-center'>{getTranslatedRawText('product.detail.dieLineFile')}</th>}
                            </tr>
                        </thead>
                        <tbody >
                            {this.props.isLoading
                                ? <>
                                    <TableRowSkeleton className='flex-grow-1 ms-2' />
                                    <TableRowSkeleton className='flex-grow-1 ms-2' />
                                    <TableRowSkeleton className='flex-grow-1 ms-2' />
                                </>
                                : this.tableRows(isAllProductAvailable, hasProductDesignTemplateUrl)}
                        </tbody>
                    </Table>
                    {this.props?.rows?.length > 0 && this.findHighestDiscountPercentage(this.props?.rows) !== 0 &&
                        <NsTypography variant='inherit' className='d-flex align-items-center font-size-14'>
                            <i className='ns-icon-information font-size-24 me-1' />
                            {CommonHelper.stringFormat(getTranslatedRawText('product.detail.pdrOnlineDiscountInformation'),
                                `${this.findHighestDiscountPercentage(this.props?.rows)}`)}
                        </NsTypography>
                    }
                </div>

                <NsDownloadFile file={this.state.file} />
                {
                    !isAllProductAvailable && !isLoading &&
                    <span className='red-color font-size-14 mb-2'>
                        {getTranslatedRawText('product.detail.unavailableNote')}
                    </span>
                }
            </>
        );
    }
}

export function TableRowSkeleton(props) {
    return <tr>
        <td>
            <NsSkeleton isVisible={true}
                className={props.className}
                variant={[
                    Enumerations.skeleton.text,
                ]} />
        </td>
    </tr>
}

export default NsAvailableDimensionTable;