import React, { useEffect, useState } from "react";

import { GetApDaUCbForecastsExcelReportService } from "../../../../services/adminPanel/AdminDashboardService";
import NsExportExcelReportButton from "../../../../components/downloadFiles/NsExportExcelReportButton";
import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import { getTranslatedRawText, isEnCulture } from "../../../../utilities/CultureHelper";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import NsTypography from "../../../../components/text/NsTypography";
import NsButton from "../../../../components/buttons/NsButton";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsCard from "../../../../components/cards/NsCard";
import CashbackDetailModal from "./CashbackDetailModal";
import NsTab from "../../../../components/tabs/NsTab";

const ApDcbPlansGrid = (props) => {
    const { GetCashbackForecasts, toDate, fromDate } = props;
    const tabs = ConstantsWithTranslation.getCashbackPlanTabs();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [detailLedgerId, setDetailLedgerId] = useState('');
    const [userId, setUserId] = useState('');
    const [showCashbackDetailModal, setShowCashbackDetailModal] = useState(false);

    const showCashbackDetailModalClicked = (detailLedgerId, userId) => {
        setShowCashbackDetailModal(true);
        setDetailLedgerId(detailLedgerId);
        setUserId(userId);
    }

    const columns = [
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => <NsButton className='secondary-text p-0'
                    startIcon='ns-icon-edit cursor-pointer'
                    onClick={() => showCashbackDetailModalClicked(props?.detailLedgerId, props?.userId)} />
            }
        },
        {
            field: 'detailLedgerNameFamily',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 150
        },
        {
            field: 'totalPurchasedAmount',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.purchase'),
            width: 200,
            dataType: Enumerations.gridType.price
        },
        {
            field: 'discountPercentage',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.cashbackPlan'),
            width: 200,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => props?.discountPercentage ? isEnCulture() ? `%${props?.discountPercentage?.toFixed(2)}` : `${props?.discountPercentage?.toFixed(2)}%` : ''
            }
        },
        {
            field: 'currentDiscountAmount',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.cashback'),
            width: 200,
            dataType: Enumerations.gridType.price
        },
        selectedTab.id !== Enumerations.cashbackPlanType.spacial && {
            field: 'remaindToNextDiscount',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.remainingPurchaseToNextPlan'),
            width: 200,
            dataType: Enumerations.gridType.price
        },
        selectedTab.id !== Enumerations.cashbackPlanType.spacial && {
            field: 'nextDiscountPercentage',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.nextCashbackPlan'),
            width: 200,
            cellClass: 'text-center',
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => props?.nextDiscountPercentage ? isEnCulture() ? `%${props?.nextDiscountPercentage?.toFixed(2)}` : `${props?.nextDiscountPercentage?.toFixed(2)}%` : ''
            }
        },
        selectedTab.id !== Enumerations.cashbackPlanType.spacial && {
            field: 'nextDiscountAmount',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.nextCashback'),
            width: 200,
            dataType: Enumerations.gridType.price
        },
    ];

    useEffect(() => {
        !!props.callServiceDate && setCallServiceDate(props.callServiceDate);
    }, [props.callServiceDate])

    const closeModalClicked = () => setShowCashbackDetailModal(false);

    const tabChanged = (newValue, selectedTab) => {
        if (newValue !== selectedTabIndex) {
            setSelectedTab(selectedTab);
            setSelectedTabIndex(newValue);
            setCallServiceDate(new Date());
        }
    }

    const excelSearchValues = { cashbackPlanType: selectedTab?.id, toDate: props.toDate };

    return (
        <NsCard className='p-4'>
            <NsTypography variant='inherit' className="fw-bold font-size-16 mb-3">
                {getTranslatedRawText('adminPanel.sidebar.customers')}
            </NsTypography>
            {hasAccess([UserClaimEnumerations.getApCshbExcelReport]) &&
                <div className="d-flex">
                    <NsExportExcelReportButton
                        searchValues={excelSearchValues}
                        isLoading={props.isLoading}
                        service={GetApDaUCbForecastsExcelReportService}
                        buttonSize='small' />
                </div>
            }
            <NsTab tabs={tabs}
                value={selectedTabIndex}
                onChange={tabChanged} />
            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetCashbackForecasts}
                parameters={{ Filter: selectedTab?.id || null, toDate, fromDate }}
                columnDefs={columns}
                hasPagination={true} />

            {showCashbackDetailModal &&
                <CashbackDetailModal userId={userId}
                    detailLedgerId={detailLedgerId}
                    showCashbackDetailModal={showCashbackDetailModal}
                    closeModalClicked={closeModalClicked} />
            }
        </NsCard>
    );
}

export default React.memo(ApDcbPlansGrid);