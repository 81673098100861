import clsx from "clsx";
import React from "react";

import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import NsRadioButton from "../../../../../components/buttons/NsRadioButton";
import NsLabelBox from "../../../../../components/labels/NsLabelBox";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import NsPigeon from "../../../../../components/maps/NsPigeon";

class UpBaseProfileAgencyInfo extends React.Component {

    mapPhones = (phones) => {
        return !isEmpty(phones) && phones.map((phone, index) =>
            <NsLabelBox key={index}
                label={getTranslatedRawText('profile.agency.phone') + ' ' + (index + 1)}
                value={phone} />
        );
    }

    render() {
        const { isSelectableAgency, option, radioButtonClicked, disabled } = this.props;

        return <div className="d-flex gap-2 flex-column border rounded-3 p-2">
            {isSelectableAgency
                ? <NsRadioButton option={option}
                    checked={option.isSelected}
                    disabled={disabled}
                    radioButtonClicked={radioButtonClicked} />
                : <span className={clsx("section-title", disabled && 'disable-color')} > {option.name}</span>
            }
            <div className="d-flex flex-wrap gap-1">

                {this.mapPhones(option.phoneNumbers)}

                <NsLabelBox label={getTranslatedRawText('profile.agency.mobileNumber')}
                    value={option.mobile} />

                <NsLabelBox label={getTranslatedRawText('profile.agency.fax')}
                    value={option.fax} />

            </div>
            <NsLabelBox label={getTranslatedRawText('components.city.city')}
                className='w-50'
                value={option.cityName} />

            <NsLabelBox label={getTranslatedRawText('profile.agency.citiesSupported')}
                value={isEmpty(option.supportedCities) ? '' : option.supportedCities.toString()} />

            <NsLabelBox label={getTranslatedRawText('profile.address.address')}
                value={option.addressInfo?.address} />

            {!!option.addressInfo?.latitude && <NsPigeon lat={option.addressInfo.latitude}
                lng={option.addressInfo.longitude} />
            }
        </div >
    }
}

export default UpBaseProfileAgencyInfo;