import React, { useState, useEffect } from 'react';

import NsModal from '../../../../../components/modals/NsModal';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { GetUserBusinessPartnerApiKeyWithAbstractDetailService } from '../../../../../services/adminPanel/AdminUserService';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import NsButton from '../../../../../components/buttons/NsButton';
import ApBaseUserInfoModal from './ApBaseUserInfoModal';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';

const ApBaseUserProfileBarcodeModal = (props) => {

    const { userId, showModal, closeModal } = props;
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [showActivationProfileBarcodeModal, setShowActivationProfileBarcodeModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [callServiceDate, setCallServiceDate] = useState(null);

    useEffect(() => {
        getBasicUserInfo();
    }, [callServiceDate])

    const getBasicUserInfo = () => {
        setIsLoading(true);
        GetUserBusinessPartnerApiKeyWithAbstractDetailService(userId, getUserBusinessPartnerApiKeyWithAbstractDetailCallback);
    }

    const getUserBusinessPartnerApiKeyWithAbstractDetailCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) setData(resultData);
    }

    const toggleUserInfoModal = () => setShowUserInfoModal(!showUserInfoModal);

    const toggleUserActivationProfileModal = () => setShowActivationProfileBarcodeModal(!showActivationProfileBarcodeModal);

    const activeDeactiveProfileClicked = (confirm) => {
        toggleUserActivationProfileModal();
    }

    const copyApiKeyClick = (value) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(value);
        }
    }

    const headerButtons = [
        hasAccess([UserClaimEnumerations.getApCustomerById]) && { startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserInfoModal },
        { startIcon: '', label: 'adminPanel.common.activeProfile', onClick: toggleUserActivationProfileModal },
    ]

    return (
        <NsModal
            maxWidth='sm'
            showModal={showModal}
            closeModal={closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.usersInfo.userProfile")}>

            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />

            <div className='background-color p-2'>
                <ApBaseLabelInfo info={data}
                    hasAccessInfo={true}
                    isLoading={isLoading} />
            </div>

            <div className='d-flex background-white-color gap-2 m-3'>
                <div> barcode</div>
                <div className='d-flex-grow-1'>
                    <div className='d-flex justify-content-between align-items-center border rounded p-2'>
                        <span >
                            {data?.apiKey}
                        </span>
                        <div onClick={() => copyApiKeyClick(apiKey)} className=''>
                            <i className='ns-icon-copy h4 cursor-pointer m-0'></i>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <NsButton label='adminPanel.usersInfo.download'
                            className='secondary-text'
                            startIcon='ns-icon-download'
                        />
                    </div>
                </div>
            </div>

            {showUserInfoModal &&
                <ApBaseUserInfoModal showModal={showUserInfoModal}
                    selectedUserId={userId}
                    closeModal={toggleUserInfoModal} />
            }
            {showActivationProfileBarcodeModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => activeDeactiveProfileClicked(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), '')}</>,
                            messageType: Enumerations.messageType.warning
                        }]} />
            }

            {resultMessageClass}

        </NsModal>
    );
};

export default ApBaseUserProfileBarcodeModal;