import React from 'react';

import NsImageOrderMasterFileView from '../../../../components/images/NsImageOrderMasterFileView';
import NsTab from '../../../../components/tabs/NsTab';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import '../../../../assets/sass/components/punchAreas/NsPunchArea.scss';
import ImageHelper from '../../../../utilities/ImageHelper';
import { Col, Row } from 'react-bootstrap';
import { BasicOptions } from '../billBook/BillBookModalSummary';
import RegularOrderTours from '../../../bases/tours/RegularOrderTours';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import NsTour from '../../../../components/tour/NsTour';
import Enumerations from '../../../../configs/Enumerations';
import CommonHelper from '../../../../utilities/CommonHelper';
import clsx from 'clsx';


class BillBookPreviewModalContent extends React.Component {

    constructor(props) {
        super(props);
        this.tourSteps = RegularOrderTours.getBillBookPreviewModalTour();
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.billBookPreviewModal);

        this.state = {
            serialBoxDimension: props.serialBoxDimension,
            serials: [],
            selectedTabIndex: 0,
            selectedTab: {},
            tabs: []
        }
    };

    setRatio = (ratio) => {
        const { selectedSerials, selectedPerforations, masterFile, billBookCopy } = this.props;

        let serials = !isEmpty(selectedSerials)
            ? selectedSerials.map(serial => ({
                ...serial,
                top: ImageHelper.convertMillimeterToPixel(serial?.verticalPoint, masterFile?.resolution) * ratio,
                left: ImageHelper.convertMillimeterToPixel(serial?.horizontalPoint, masterFile?.resolution) * ratio,
                isSelected: false,
            }))
            : [];
        let perforations = selectedPerforations;
        !isEmpty(perforations) && perforations.map(perforation => {
            perforation.position = Math.round(ImageHelper.convertMillimeterToPixel(perforation.distanceFromOrigin, masterFile?.resolution) * ratio);
        });
        this.setState({
            serialBoxDimension: {
                width: !!serials[0]?.isVertical
                    ? ImageHelper.convertMillimeterToPixel(this.state.serialBoxDimension?.height, masterFile?.resolution) * ratio
                    : ImageHelper.convertMillimeterToPixel(this.state.serialBoxDimension?.width, masterFile?.resolution) * ratio,
                height: !!serials[0]?.isVertical
                    ? ImageHelper.convertMillimeterToPixel(this.state.serialBoxDimension?.width, masterFile?.resolution) * ratio
                    : ImageHelper.convertMillimeterToPixel(this.state.serialBoxDimension?.height, masterFile?.resolution) * ratio
            },
            serials,
            perforations,
            isPerforationDirectionVertical: isEmpty(perforations) ? true : perforations[0]?.isVertical,
            ratio
        });
        this.tabCreator(billBookCopy.billBookCopyType);
    }

    tabChanged = (index, value) => this.setState({ selectedTabIndex: index, selectedTab: value, key: new Date() });

    tabCreator = (billBookCopy) => {
        const headers = [
            { text: 'regularOrder.billBook.master', className: 'background-white-color', colorCode: "#ffffff" },
            { text: 'regularOrder.billBook.firstCopy', className: 'background-first-copy-color', colorCode: "#ffffff" },
            { text: 'regularOrder.billBook.secondCopy', className: 'background-second-copy-color', colorCode: "#ffffff" },
            { text: 'regularOrder.billBook.thirdCopy', className: 'background-third-copy-color', colorCode: "#ffffff" },
        ];
        headers.map((item, index) => index < billBookCopy && this.setState((prevState) => ({
            tabs: [...prevState.tabs, { label: getTranslatedRawText(item.text), colorCode: item.colorCode }]
        }), () => this.tabChanged(0, this.state.tabs[0])));
    };

    render() {
        const { serials, serialBoxDimension, perforations, isPerforationDirectionVertical, selectedTabIndex } = this.state;
        const { masterFile, trimZone, billBookCopy, billBookBindingType, serialStartFrom, billBookBindingSideType, billBookPrintColor,
            hasUnderhand } = this.props;
        return (
            <Row>
                <Col md={8}>
                    <NsTab tabs={this.state.tabs}
                        value={this.state.selectedTabIndex}
                        tabClassName="w-25"
                        className="extended-service__tour__bill-book__preview__header"
                        onChange={this.tabChanged}>
                    </NsTab>

                    <div style={{ '--bill-book-color': this.state.selectedTab.colorCode }} className='bill-book-preview-modal__overly'>
                        <NsImageOrderMasterFileView imgSrc={masterFile?.draft}
                            setRatio={this.setRatio}
                            trimZone={trimZone}
                            wrapperClassName='position-relative'
                            serialBoxDimension={serialBoxDimension}
                            selectedSerials={serials} >

                            {!isEmpty(serials) && serials.map((serial, index) =>
                                serial.copyNumberOptions[selectedTabIndex].isSelected
                                    ? <div key={index} className='serial-area--pointer'
                                        style={{
                                            "--width": `${serialBoxDimension.width}px`,
                                            "--height": `${serialBoxDimension.height}px`,
                                            "--top": `${serial.top}px`,
                                            "--left": `${serial.left}px`,
                                        }} >
                                        <div className={clsx('position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-center background-white-color px-2 rounded-1 text-center cursor-pointer opacity-50 ', CommonHelper.isMobileDevice() ? 'font-size-10' : 'font-size-14')}>
                                            {serialStartFrom}
                                        </div>
                                    </div>
                                    : null
                            )}
                        </NsImageOrderMasterFileView>
                        <PerforationDrawing isPerforationDirectionVertical={isPerforationDirectionVertical}
                            perforations={perforations}
                            selectedTabIndex={selectedTabIndex} />
                    </div >
                </Col>
                <Col md={4}>
                    <div className='label-box font-size-12 p-2 gap-2' >
                        <BasicOptions billBookCopy={billBookCopy}
                            billBookPrintColor={billBookPrintColor}
                            billBookBindingType={billBookBindingType}
                            serialStartFrom={serialStartFrom}
                            billBookBindingSideType={billBookBindingSideType}
                            hasUnderhand={hasUnderhand} />
                    </div>
                </Col>
                {
                    this.showTour && <NsTour steps={this.tourSteps}
                        id={Enumerations.tourGuide.billBookPreviewModal} />
                }

            </Row>
        )
    }
}

function PerforationDrawing(props) {
    const { perforations, isPerforationDirectionVertical, selectedTabIndex } = props;
    let className = 'fold-line-modal_vertical-line';
    let direction = 'left';

    if (!isPerforationDirectionVertical) {
        className = 'fold-line-modal_horizontal-line';
        direction = 'top';
    }
    const perforationList = !isEmpty(perforations)
        ? perforations.map((perforation, index) => {

            let perforationNumberClassName = 'white-color background-black-color';

            return perforation.copyNumberOptions[selectedTabIndex].isSelected
                ? <div className={className}
                    key={perforation.id}
                    style={{ [direction]: `${perforation.position}px` }
                    } >
                    <button
                        className={`fold-line-modal_button  px-2 ${perforationNumberClassName} ${perforation.isSelected && 'selected-fold-line'}`}>
                        <span className='font-size-18'>
                            {index + 1}
                        </span>
                        <span className='font-size-12'>
                            {getTranslatedRawText('regularOrder.billBook.perforation')}
                        </span>
                    </button>
                </div >
                : null
        })
        : [];

    return perforationList;
}

export default BillBookPreviewModalContent;