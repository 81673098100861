import Links from "../../configs/links/AllLinks";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { GetRequest, PostRequest } from "../bases/BaseService";

const GetCrmAnnouncementContentFeedbackService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.crm, Links.api.crm.getCrmAnnouncementContentFeedback), parameters, callback);
}

const AddCrmAnnouncementContentFeedbackService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.crm, Links.api.crm.addCrmAnnouncementContentFeedback), data, callback, true);
}

const GetCrmOrderFeedbackService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.crm, Links.api.crm.getCrmOrderFeedback), parameters, callback);
}

const AddCrmOrderFeedbackService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.crm, Links.api.crm.addCrmOrderFeedback), data, callback, true);
}

const GetCrmUserFeedbackService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.crm, Links.api.crm.getCrmUserFeedback), parameters, callback);
}

const AddCrmUserFeedbackService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.crm, Links.api.crm.addCrmUserFeedback), data, callback, true);
}

export {
    GetCrmAnnouncementContentFeedbackService,
    AddCrmAnnouncementContentFeedbackService,
    GetCrmOrderFeedbackService,
    AddCrmOrderFeedbackService,
    GetCrmUserFeedbackService,
    AddCrmUserFeedbackService
}