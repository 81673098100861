import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { DeleteRequest, GetRequest, PatchRequest, PostRequest } from '../bases/BaseService';

const GetBlogsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApBlogs), parameter, callback);
}

const GetBlogByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApBlogById), id), {}, callback);
}

const EditBlogService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApBlog), id), data, callback, showSuccessResponseMessage);
}

const DeleteBlogService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApBlog), id), {}, callback, showSuccessResponseMessage);
}

const PostBlogService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApBlog), data, callback, showSuccessResponseMessage);
}

const GetBlogCategoriesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApBlogCategories), parameter, callback);
}

const GetBasicBlogCategoriesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApBasicBlogCategories), {}, callback);
}

const GetBlogCategoryByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApBlogCategoryById), id), {}, callback);
}

const EditBlogCategoryService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApBlogCategory), id), data, callback, showSuccessResponseMessage);
}

const DeleteBlogCategoryService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApBlogCategory), id), {}, callback, showSuccessResponseMessage);
}

const PostBlogCategoryService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApBlogCategory), data, callback, showSuccessResponseMessage);
}

export {
    GetBlogsService,
    GetBlogByIdService,
    EditBlogService,
    DeleteBlogService,
    PostBlogService,
    GetBasicBlogCategoriesService,
    GetBlogCategoriesService,
    GetBlogCategoryByIdService,
    EditBlogCategoryService,
    DeleteBlogCategoryService,
    PostBlogCategoryService,

}