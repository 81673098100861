import React from 'react';
import Container from 'react-bootstrap/Container';

class LoginLayout extends React.Component {

    render() {
        return (
            <Container fluid className='p-0 main' >
                {this.props.children}
            </Container>
        );
    }
}

export default LoginLayout;