import React, { Component } from 'react';
import clsx from 'clsx'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import NsTypography from '../text/NsTypography';
import CommonHelper from '../../utilities/CommonHelper';

class NsCollapse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.defaultIsOpen ? true : false
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.defaultIsOpen !== this.state.isOpen)
            this.setState({ isOpen: newProps.defaultIsOpen });
    }

    listToggle = () => this.setState({ isOpen: !this.state.isOpen },
        () => CommonHelper.safeFunctionCall(this.props.isOpenStatus, this.state.isOpen));

    render() {
        const { title, showChildrenForSeo, children, icon, titleClassName, className, collapseClassName, expandIconClassName } = this.props;
        const { isOpen } = this.state;
        return (
            <List component="nav"
                className="p-0 mb-1 w-100">
                <ListItem button
                    className={clsx("p-0 d-flex align-items-center", className)}
                    onClick={this.listToggle} >
                    {icon && <div>
                        <i className={icon} />
                    </div>
                    }
                    <NsTypography component={'span'} className={clsx('flex-grow-1', titleClassName)}
                        variant='inherit'>
                        {title}
                    </NsTypography>
                    {isOpen ? <i className={clsx('ns-icon-expand-up ns-collapse-header-icon', expandIconClassName)} /> :
                        <i className={clsx('ns-icon-expand-down ns-collapse-header-icon', expandIconClassName)} />}
                </ListItem>
                <Collapse className={collapseClassName} in={isOpen} timeout="auto" unmountOnExit>
                    {children}
                </Collapse>
                {showChildrenForSeo && <div className='d-none'>{children}</div>}
            </List>
        );
    }
}

export default NsCollapse;