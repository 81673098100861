import React, { useEffect, useState } from "react";

import NsAdminPanelContentWrapper from "../../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../../components/grids/NsDataGridWithServiceCall";
import { GetShippingsService } from "../../../../../services/adminPanel/AdminShippingService";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import { createShippingListColumns } from "../bases/ApBaseShippingHelper";
import ApShippingDetailModal from "./ApShippingDetailModal";
import ApShippingSearch from "./ApShippingSearch";
import ApBaseLabelInfo from "../../../bases/ApBaseLabelInfo";
import { isEmpty } from "../../../../../utilities/ValidationHelper";

export const ApShippingContent = (props) => {

    const { shippingInfoDto } = props;
    const [showShipmentDetailModal, setShowShipmentDetailModal] = useState(false);
    const [selectedShipmentId, setSelectedShipmentId] = useState(null);
    const [searchData, setSearchData] = useState(props.searchData);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handlers = {

        detailViewClicked: (id) => {
            setSelectedShipmentId(id);
            setShowShipmentDetailModal(true);
        },

        closeApShipmentDetailModal: () => {
            setShowShipmentDetailModal(false);
        },

        getSearchData: (data) => {
            setSearchData(data);
            setCallServiceDate(new Date());
            setIsLoading(true);
        },
    }

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    const { detailViewClicked, closeApShipmentDetailModal, getSearchData } = handlers;

    const parameters = {
        "Filter.UserIds": searchData?.selectedUser?.userId ?? [],
        "Filter.TransferStateTypes": searchData?.lastStatuses?.id ?? [],
        "Filter.EstimatedDeliveryTimeFrom": searchData?.estimatedDeliveryTimeFrom || null,
        "Filter.EstimatedDeliveryTimeTo": searchData?.estimatedDeliveryTimeTo || null,
        "Filter.ShippingLastStatusTimeFrom": searchData?.shippingLastStatusTimeFrom || null,
        "Filter.ShippingLastStatusTimeTo": searchData?.shippingLastStatusTimeTo || null,
        "Filter.SubmitTimeFrom": searchData?.submitTimeFrom || null,
        "Filter.SubmitTimeTo": searchData?.submitTimeTo || null,
        "Filter.NasInvoiceIds[0]": searchData?.invoiceNo || [],
        "Filter.RequestNumbers[0]": searchData?.requestNumber || [],
        "Filter.ReceiptTransferToIds[0]": searchData?.transerNumber || [],
        "Filter.ShippingPlanTypeId": searchData?.shippingPlan?.key || null,
        "Filter.ShippingMethodTypeId": searchData?.shippingMethod?.key || [],
        "Filter.orderProductType": searchData?.orderProductType?.id || [],
    };

    return (
        <>
            {isEmpty(shippingInfoDto)
                ? <div className="mb-2">
                    <ApShippingSearch isLoading={isLoading}
                        customerDetails={props.customerDetails}
                        setSearchData={getSearchData} />
                </div>
                : <div className='background-color p-2 my-2'>
                    <ApBaseLabelInfo info={{ shippingInfoDto: shippingInfoDto }}
                        hasAccessInfo={true}
                        isLoading={isLoading} />
                </div>
            }
            <div className={isEmpty(shippingInfoDto) ? "" : 'p-3'} >
                <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                    service={GetShippingsService}
                    columnDefs={createShippingListColumns(detailViewClicked)}
                    parameters={parameters}
                    hasPagination={true}
                    getData={getData} />
            </div >

            {showShipmentDetailModal &&
                <ApShippingDetailModal showModal={true}
                    selectedShipmentId={selectedShipmentId}
                    closeModal={closeApShipmentDetailModal} />
            }
        </>
    );
}

const ApShipping = (props) => {
    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText('adminPanel.shippings.shippingRequests')}
            icon={'ns-icon-delivery-request h1'}>
            <ApShippingContent {...props} />
        </NsAdminPanelContentWrapper>
    );
}

export default React.memo(ApShipping);