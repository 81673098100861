
import ApiLinks from './ApiLinks';
import ViewLinks from './ViewLinks';
import AdminPanelLinks from './AdminPanelLinks';
import BusinessPartnerLinks from './BusinessPartnerLinks';
import { isBusinessPartnerRoute } from '../../utilities/BusinessPartnerHelper';

const Links = isBusinessPartnerRoute()
    ? { ...BusinessPartnerLinks }
    : {
        ...ApiLinks,
        ...ViewLinks,
        ...AdminPanelLinks,
    };

export default Links;