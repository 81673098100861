import CommonHelper from "./CommonHelper";
import Enumerations from "../configs/Enumerations";
import { getTranslatedRawText } from "./CultureHelper";
import StorageHelper from "./StorageHelper";
import UrlHelper from "./UrlHelper";
import { isEmpty } from "./ValidationHelper";
import ImageHelper from "./ImageHelper";

class OrderHelper {

    static createRouteOrderId = (id) => {
        return id + '-' + Math.floor(10000000 + Math.random() * 90000000);
    }

    static getRouteOrderId = (props) => {
        return UrlHelper.getRouteValue(props, Enumerations.routeKey.id).split('-')[0];
    }

    static findOrderById = (id) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        try {
            if (!isEmpty(orders))
                orders = orders.filter(order => order?.id === id);
        } catch (error) {
            StorageHelper.removeFromStorage(Enumerations.storage.orders)
        }
        return !isEmpty(orders) ? orders[0] : {};
    }

    static isValidOrder = (id) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        if (!isEmpty(orders))
            orders = orders.filter(order => order.id === id);
        return orders?.length > 0;
    }

    static createCopyOfOrderByNewId = (id, newId, orderId, callback) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        let order = { ...this.findOrderById(id) };
        order.id = newId;
        order.orderId = orderId;
        let newOrders = [...orders, order];
        StorageHelper.saveInStorage(Enumerations.storage.orders, newOrders);
        this.clearOrder(newId, callback);
    }

    static addOrderToStorage = (id, name, turnaround, printedSide, circulation, series, films, extendedServices, noSupportedExtendedServices, productDesignTemplateUrl, guidancesUrls, availableSizes, categories) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        if (!!orders) {
            if (this.isValidOrder(id))
                orders = orders.filter(order => order.id !== id);
        } else
            orders = [];
        let newOrders = [
            ...orders,
            {
                id: id,
                productName: name,
                turnaround: turnaround,
                printedSide: printedSide,
                circulation: circulation,
                series: CommonHelper.toInt(series),
                isCompeteledChoosingFileSteps: false,
                isCompeteledExtendedServiceSteps: false,
                extendedServices: extendedServices,
                noSupportedExtendedServices: noSupportedExtendedServices,
                productDesignTemplateUrl: productDesignTemplateUrl,
                files: this.generateOrderFileList(films),
                guidancesUrls: guidancesUrls,
                availableSizes: availableSizes,
                categories: categories
            }
        ];
        StorageHelper.saveInStorage(Enumerations.storage.orders, newOrders);
    }

    static generateOrderFileList = (films) => {
        let files = [{
            key: 0,
            title: getTranslatedRawText('regularOrder.choosingFile.fourColorPrintedFile'),
            required: true,
            frontSideFileInfo: null,
            backSideFileInfo: null,
            activeStep: 0,
        }];
        if (!!films)
            films.map(film =>
                files = [
                    ...files,
                    {
                        key: film.filmNo,
                        title: film.filmName,
                        frontSideFileInfo: null,
                        backSideFileInfo: null,
                        activeStep: 0,
                        minimumRequirementFile: film.minimumRequirementFile
                    },
                ]);
        return files;
    }

    static clearOrder = (id, callback) => {
        let files = [...this.getFiles(id)];
        OrderHelper.resetOrder(id);
        files.map(file => {
            file.frontSideFileInfo = null;
            file.backSideFileInfo = null;
            file.activeStep = 0;
            return true;
        });
        this.setFileSizeAndOrderPrices(id, {});
        this.setSeries(id, 1);
        this.setFiles(id, files);
        StorageHelper.saveInLobStorage(id, [], callback);
    }

    static resetOrder = (id, isUpdateMasterFile) => {
        let services = this.getExtendedServices(id)
        services.map(service => {
            service.selectedItem = null;
            return true;
        });
        this.setExtendedServices(id, services);
        //this.setSeries(id, 1);
        this.setAvailableSizes(id, []);
        !isUpdateMasterFile && this.setTrimZone(id, null);
        this.setIsCompeteledChoosingFileSteps(id, false);
        this.setIsCompeteledExtendedServiceSteps(id, false);
    }

    static get = (id) => {
        return this.findOrderById(id);
    }

    static setSuggestedFilesInLobStorage = (id, files, callback) => {
        StorageHelper.getFromLobStorage(id, (suggestedFiles => {
            suggestedFiles = suggestedFiles ?? [];
            files.map(file => {
                if (!!suggestedFiles?.find(suggestedFile => suggestedFile.id === file.id))
                    return;
                suggestedFiles.push(file);
            });
            StorageHelper.saveInLobStorage(id, suggestedFiles, callback);
        }));
    }

    static getLocalSuggestedFilesInfo = (id, presentableFileType, callback) => {
        StorageHelper.getFromLobStorage(id, (suggestedFiles => {
            let suggestedFilesInfo = !!suggestedFiles ? suggestedFiles.filter(file => file.presentableFileType !== presentableFileType) : [];
            let thumbnails = suggestedFilesInfo.map(file => file.thumbnail);
            callback(suggestedFilesInfo, thumbnails)
        }));
    }

    static getSuggestedFileIndex = (suggestedFiles, selectedFileId) => {
        return suggestedFiles.findIndex(file => file.id === selectedFileId);
    }

    static setFiles = (id, files) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.files = files;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    static getFiles = (id) => {
        let order = this.findOrderById(id);
        return !!order.files ? order.files : [];
    }
    static getFilesWithFullInfo = (id, presentableFileType, callback) => {
        let order = this.findOrderById(id);
        if (!isEmpty(order.files))
            this.getFilesInfoById(id, order.files, presentableFileType, callback);
        return [];
    }

    static updateFilesInStorages = (id, key, fileInfo, allFilesInfo, isFront, callback) => {
        let files = [...this.getFiles(id)];
        const index = key;
        if (key === 0 && !!files[key].frontSideFileInfo) {
            if (isFront) {
                OrderHelper.resetOrder(id, true)
                files.map(file => {
                    file.frontSideFileInfo = null;
                    file.backSideFileInfo = null;
                    return true;
                });
            } else {
                files.map(file => {
                    file.backSideFileInfo = null;
                    return true;
                });
            }
        }

        if (isFront)
            files[index].frontSideFileInfo = fileInfo;
        else
            files[index].backSideFileInfo = fileInfo;

        this.setFiles(id, files);
        this.setSuggestedFilesInLobStorage(id, allFilesInfo, callback);
    }

    static getMasterFiles = (id) => {
        let files = this.getFiles(id);
        if (files && files.length > 0)
            return files[0];
        return files = null;
    }

    static getMasterFilesWithFullInfo = (id, callback) => {
        let files = this.getFiles(id);
        if (files && files.length > 0)
            this.getFilesInfoById(id, files, Enumerations.presentableFileType.isPresentableInFilmFile, callback);
        return files = null;
    }

    static checkFrontFileExist = (routeId) => {
        if (this.getFrontSideMasterFiles(routeId))
            return true
        else
            return false

    }

    static getFilesInfoById = (routeId, files, presentableFileType, callback) => {
        this.getLocalSuggestedFilesInfo(routeId, presentableFileType, (suggestedFiles) => {
            files.map(file => {
                let frontSideFileInfo = suggestedFiles.find(suggestedFile => suggestedFile.id === file.frontSideFileInfo?.id)
                let backSideFileInfo = suggestedFiles.find(suggestedFile => suggestedFile.id === file.backSideFileInfo?.id)
                file.frontSideFileInfo = frontSideFileInfo
                file.backSideFileInfo = backSideFileInfo
            });
            callback(files);
        });
    }

    static getFrontSideMasterFiles = (id) => !!this.getMasterFiles(id) && this.getMasterFiles(id).frontSideFileInfo;

    static getBackSideMasterFiles = (id) => !!this.getMasterFiles(id) && this.getMasterFiles(id).backSideFileInfo;

    static getMasterFileByIsFront = (id, isFront) => !!isFront ? this.getFrontSideMasterFiles(id) : this.getBackSideMasterFiles(id);

    //Film Files
    static getFilmFiles = (id) => this.getFiles(id).filter((file, index) => index !== 0);

    static getFilmFile = (id, index) => this.getFiles(id)[index];

    static getFrontSideFilmFile = (id, index) => this.getFilmFile(id, index).frontSideFileInfo;

    static getBackSideFilmFile = (id, index) => this.getFilmFile(id, index).backSideFileInfo;

    //set FileSizeAndOrderPrices
    static setFileSizeAndOrderPrices = (id, fileSizeAndOrderPrices) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.fileSizeAndOrderPrices = fileSizeAndOrderPrices;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    //extended services
    static setExtendedServices = (id, extendedServices) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.extendedServices = extendedServices;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    static updateExtendedService = (id, extendedServiceOption, selectedItem) => {
        let services = this.getExtendedServices(id);
        services.map(service => {
            if (service.extendedServiceType === extendedServiceOption) {
                service.selectedItem = selectedItem;
            }
            return true;
        });
        this.setExtendedServices(id, services);
    }

    static getFirstExtendedService = (id) => {
        let service = this.getExtendedServices(id);
        if (!isEmpty(service))
            return service[0];
        return service = null;
    }

    static hasExtendedService = (id) => {
        let service = this.getExtendedServices(id);
        return !isEmpty(service);
    }

    static getFilesValue = (id) => {
        let temp = this.getMasterFiles(id);
        let masterFiles = [
            {
                id: temp.frontSideFileInfo?.id,
                fileName: temp.frontSideFileInfo?.name,
                sharedSheetOrderDetailUserFileType: Enumerations.sharedSheetOrderDetailUserFileType.FrontImage
            }
        ];
        if (this.getPrintedSide(id).key === Enumerations.printedSide.twoSide)
            masterFiles = [
                ...masterFiles,
                {
                    id: temp.backSideFileInfo?.id,
                    fileName: temp.backSideFileInfo?.name,
                    sharedSheetOrderDetailUserFileType: Enumerations.sharedSheetOrderDetailUserFileType.BackImage
                }
            ];
        let filmFiles = [];
        temp = this.getFilmFiles(id)
        temp.map((film, index) => {
            if (!isEmpty(film.frontSideFileInfo))
                filmFiles.push({
                    id: film.frontSideFileInfo?.id,
                    fileName: film.frontSideFileInfo?.name,
                    filmNo: film?.key,
                    sharedSheetOrderDetailUserFileType: film.key === 1
                        ? Enumerations.sharedSheetOrderDetailUserFileType.FirstFrontFilm
                        : Enumerations.sharedSheetOrderDetailUserFileType.SecondFrontFilm,
                    position: {
                        top: film.frontSideFileInfo?.top,
                        left: film.frontSideFileInfo?.left,
                    }
                });
            if (this.getPrintedSide(id).key === Enumerations.printedSide.twoSide && !isEmpty(film.backSideFileInfo))
                filmFiles.push({
                    id: film.backSideFileInfo?.id,
                    fileName: film.backSideFileInfo?.name,
                    filmNo: film.key,
                    sharedSheetOrderDetailUserFileType: film.key === 1
                        ? Enumerations.sharedSheetOrderDetailUserFileType.FirstBackFilm
                        : Enumerations.sharedSheetOrderDetailUserFileType.SecondBackFilm,
                    position: {
                        top: film.backSideFileInfo?.top,
                        left: film.backSideFileInfo?.left,
                    }
                });
            return true;
        });
        return { masterFiles: masterFiles, filmFiles: filmFiles };
    }

    // static convertFilterToMilimeters = (filters) => {
    //     let milimeterFilters = [];
    //     if (filters.length > 0) {
    //         filters.map(filter=>)
    //     }
    // }

    static getExtendedServicesValue = (id) => {
        let filters = this.getSpecificService(id, Enumerations.extendedServices.filters)?.selectedItem || [];
        let foldLineItems = this.getSpecificService(id, Enumerations.extendedServices.foldLine)?.selectedItem;

        let foldLinePresetId = foldLineItems?.id;

        let customFoldLineDto = foldLineItems?.length > 0
            ? {
                isVertical: foldLineItems[0]?.isVertical,
                customFoldLineItems: foldLineItems.map(item => ({ distanceFromOrigin: item?.distanceFromOrigin, isConvex: item?.isConvex }))
            }
            : null;

        let roundedCorner = this.getSpecificService(id, Enumerations.extendedServices.roundedCorner)?.selectedItem?.id === Enumerations.roundedCorner.curved;

        let gripperEdge = this.getSpecificService(id, Enumerations.extendedServices.gripperEdge)?.selectedItem?.id === Enumerations.gripperEdge.withGripperEdge;

        let punch = this.getSpecificService(id, Enumerations.extendedServices.punch)?.selectedItem;

        let billBookOptions = this.getSpecificService(id, Enumerations.extendedServices.billBook)?.selectedItem;
        if (!isEmpty(billBookOptions))
            billBookOptions['productId'] = id.split('-')[0];

        let handleType = this.getSpecificService(id, Enumerations.extendedServices.handleType)?.selectedItem?.id || null;

        let topFoldingType = this.getSpecificService(id, Enumerations.extendedServices.topFolding)?.selectedItem?.id;

        return { foldLinePresetId, filters, roundedCorner, punch, customFoldLineDto, gripperEdge, billBookOptions, handleType, topFoldingType };
    }

    static getSpecificService = (id, serviceType) => {
        return this.getExtendedServices(id)?.filter(service => service.extendedServiceType === serviceType).length > 0 ?
            this.getExtendedServices(id).filter(service => service.extendedServiceType === serviceType)[0]
            : null;
    }

    static getExtendedServicesName = (extendedServiceType) => {
        switch (extendedServiceType) {
            case Enumerations.extendedServices.foldLine:
                return getTranslatedRawText('regularOrder.extendedService.foldLine');
            case Enumerations.extendedServices.filters:
                return getTranslatedRawText('regularOrder.extendedService.filter');
            case Enumerations.extendedServices.roundedCorner:
                return getTranslatedRawText('regularOrder.extendedService.corners');
            case Enumerations.extendedServices.punch:
                return getTranslatedRawText('regularOrder.extendedService.punch');
            case Enumerations.extendedServices.billBook:
                return getTranslatedRawText('regularOrder.extendedService.billBook');
            case Enumerations.extendedServices.gripperEdge:
                return getTranslatedRawText('regularOrder.extendedService.gripperEdge');
            case Enumerations.extendedServices.cutLine:
                return getTranslatedRawText('regularOrder.extendedService.cutLine');
            case Enumerations.extendedServices.topFolding:
                return getTranslatedRawText('regularOrder.extendedService.topFolding');
            case Enumerations.extendedServices.handleType:
                return getTranslatedRawText('regularOrder.extendedService.handleType');
            default: return null;
        }
    }

    static setTotalExtendedServicePrice = (id, totalPrice) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.totalExtendedServicePrice = totalPrice;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    static getFoldLineList = (id) => {
        let service = this.getSpecificService(id, Enumerations.extendedServices.foldLine);
        return service?.foldLineItems;
    }

    static createAdvancePriceParameters = (idProduct, idFile) => {
        let id = !!idFile ? idFile : this.getFrontSideMasterFiles(idProduct)?.id;
        return !!id ?
            {
                productId: CommonHelper.toInt(this.getId(idProduct)),
                turnaroundType: this.getTurnaround(idProduct).key,
                twoSidePrintingType: this.getPrintedSide(idProduct).key,
                series: !!this.getSeries(idProduct) ? this.getSeries(idProduct) : 1,
                currentFileId: id,
                extendedServices: this.getExtendedServicesValue(idProduct)
            } : '';
    }

    static createBasicPriceParameters = () => {
        return {
            productId: this.getId(),
            turnaroundType: this.getTurnaround().key,
            twoSidePrintingType: this.getPrintedSide().key,
        };
    }

    static setIsCompeteledChoosingFileSteps = (id, isCompeteledChoosingFileSteps) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.isCompeteledChoosingFileSteps = isCompeteledChoosingFileSteps;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    static setIsCompeteledExtendedServiceSteps = (id, isCompeteledExtendedServiceSteps) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.isCompeteledExtendedServiceSteps = isCompeteledExtendedServiceSteps;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    static setSeries = (id, series) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.series = series;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    static getExtendedServicesValidationMessage = (id) => {
        let messages = [];
        let services = this.getExtendedServices(id);
        !isEmpty(services) && services.map(service => {
            if (service.fillingOptionType === Enumerations.extendedServiceFillingOptionType.mandatory && isEmpty(service.selectedItem))
                messages.push(CommonHelper.stringFormat(getTranslatedRawText('regularOrder.messages.mandatoryExtendedServiceMessage'), this.getExtendedServicesName(service.extendedServiceType)))
        })
        return messages;
    }

    static getId = (id) => this.get(id).id;

    static getOrderId = (id) => this.get(id)?.orderId;

    static getProductName = (id) => this.get(id).productName;

    static getTurnaround = (id) => this.get(id).turnaround;

    static getPrintedSide = (id) => this.get(id).printedSide;

    static getCirculation = (id) => this.get(id).circulation;

    static getFiles = (id) => this.get(id).files;

    static getFilesLength = (id) => this.get(id).files.length;

    static getProductDesignTemplateUrl = (id) => this.get(id).productDesignTemplateUrl;

    static getExtendedServices = (id) => this.get(id).extendedServices;

    static getExtendedServicesLength = (id) => this.get(id)?.extendedServices?.length;

    static getNoSupportedExtendedServices = (id) => this.get(id).noSupportedExtendedServices;

    static getTotalExtendedServicesPrice = (id) => this.get(id)?.totalExtendedServicePrice;

    static getIsCompeteledChoosingFileSteps = (id) => this.get(id).isCompeteledChoosingFileSteps;

    static getIsCompeteledExtendedServiceSteps = (id) => this.get(id).isCompeteledExtendedServiceSteps;

    static getSeries = (id) => this.get(id).series;

    static getAvailableSizes = (id) => this.get(id).availableSizes;

    static setAvailableSizes = (id, availableSizes) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.availableSizes = availableSizes;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    static suggestedFiles = (id, callback) => {
        StorageHelper.getFromLobStorage(id, callback);
    }

    static getFileSizeAndOrderPrices = (id) => this.get(id)?.fileSizeAndOrderPrices


    static remove = (id) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders = orders.filter(order => order.id !== id);
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
        StorageHelper.removeFromLobStorage(id)
    }

    static checkIfSuggestedFilesUsedInOrder = (productId) => {
        let suggestedFiles = this.getLocalSuggestedFilesInfo(productId);
        let files = this.getFiles(productId);
        if (suggestedFiles.map(tmpFile => files.map(file => file.frontSideFileInfo?.id).indexOf(tmpFile.id) > -1))
            return true;
        if (suggestedFiles.map(tmpFile => files.map(file => file.backSideFileInfo?.id).indexOf(tmpFile.id) > -1))
            return true;
        return false;
    }

    static convertTrimZoneToPixel = (trimZone, resolution) => {
        if (!isEmpty(trimZone)) {
            trimZone.pixelTop = ImageHelper.convertMillimeterToPixel(trimZone?.top, resolution);
            trimZone.pixelLeft = ImageHelper.convertMillimeterToPixel(trimZone?.left, resolution);
            trimZone.pixelRight = ImageHelper.convertMillimeterToPixel(trimZone?.right, resolution);
            trimZone.pixelBottom = ImageHelper.convertMillimeterToPixel(trimZone?.bottom, resolution);
        }
        return trimZone;
    }

    static setTrimZone = (id, trimZone) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.trimZone = trimZone;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    static getTrimZone = (id) => this.get(id).trimZone;

    static setBillBookCopies = (id, billBookCopies) => {
        let orders = StorageHelper.getFromStorage(Enumerations.storage.orders);
        orders.map(order => {
            if (order.id === id)
                order.billBookCopies = billBookCopies;
        });
        StorageHelper.saveInStorage(Enumerations.storage.orders, orders);
    }

    static getBillBookCopies = (id) => this.get(id).billBookCopies;

    static calculateSerialEndTo = (serialStartFrom, billBookNumberInSeriesType, id) => {
        let circulations = this.getCirculation(id)?.value;
        let series = this.getSeries(id);
        let serialStartFromOrginalLength = (serialStartFrom?.replace(/-/g, '') ?? "").length;
        //ignored calculating series in final serial number
        return billBookNumberInSeriesType === Enumerations.billBookNumberInSeriesTypes.continueNumbering
            ? padLeadingZeros(Number(serialStartFrom) + (circulations * series), serialStartFromOrginalLength)
            : padLeadingZeros(Number(serialStartFrom) + (circulations * 1), serialStartFromOrginalLength);
    }
}

function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export default OrderHelper;