import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import ApDfOrderByApplicationType from './ApDfOrderByApplicationType';
import ApBasesDashboardHeader from '../bases/ApBasesDashboardHeader';
import ApDfOnlinePaymentGateway from './ApDfOnlinePaymentGateway';
import ApDfTotalOnlinePayment from './ApDfTotalOnlinePayment';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import ApDfOrderByTurnaround from './ApDfOrderByTurnaround';
import DateHelper from '../../../../utilities/DateHelper';
import ApDfTotalSales from './ApDfTotalSales';
import ApDfGadget from './ApDfGadget';

const ApDashboardFinancial = (props) => {

    const [dates, setDates] = useState([new Date(DateHelper.getCustomPreviousMonth(2)), new Date()]);
    const [dateRangeType, setDateRangeType] = useState(ConstantsWithTranslation.getDateRanges()[0].key);
    const [refreshDate, setRefreshDate] = useState();
    const [userId, setUserId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [stateId, setStateId] = useState(null);

    const refreshClicked = useCallback((dates, isRefreshDate, dateRange, userId, stateId, cityId) => {
        setDateRangeType(dateRange);
        setDates(dates);
        setUserId(userId);
        setStateId(stateId);
        setCityId(cityId);
        isRefreshDate && setRefreshDate(new Date());
    })

    const parameters = {
        submitTimeFrom: DateHelper.convertDateTimeToDate(new Date(dates[0])),
        submitTimeTo: dates[1],
        timeIntervalType: dateRangeType,
        userId: props.userId || userId,
        stateId,
        cityId
    }

    return (
        <Row className='d-flex flex-column m-0 px-0 gap-2'>
            <Col>
                <ApBasesDashboardHeader title='adminPanel.dashboard.financialDashboard'
                    refreshClicked={refreshClicked}
                    hasStateFilter={true}
                    breadCrumb={props.breadCrumb} />
            </Col>
            {hasAccess([UserClaimEnumerations.getApDfSaleGadgets]) && <Row className='p-0 m-0 gy-2'>
                <ApDfGadget parameters={parameters} refreshDate={refreshDate} />
            </Row>
            }
            {hasAccess([UserClaimEnumerations.getApDfTotalSales]) && <Col>
                <ApDfTotalSales parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfOrderByApplicationType]) &&
                <Row className='m-0'>
                    <ApDfOrderByApplicationType parameters={parameters} refreshDate={refreshDate} />
                </Row>
            }
            {hasAccess([UserClaimEnumerations.getApDfOrderByTurnaround]) &&
                <Row className='m-0'>
                    <ApDfOrderByTurnaround parameters={parameters} refreshDate={refreshDate} />
                </Row>
            }
            <Row className='align-self-stretch m-0 px-0 gy-2'>
                {hasAccess([UserClaimEnumerations.getApDfTotalOnlinePayments]) && <Col md={7}>
                    <ApDfTotalOnlinePayment parameters={parameters} refreshDate={refreshDate} />
                </Col>
                }
                {hasAccess([UserClaimEnumerations.getApDfOnlinePaymentsGateway]) && <Col md={5}>
                    <ApDfOnlinePaymentGateway parameters={parameters} refreshDate={refreshDate} />
                </Col>
                }
            </Row>
        </Row>
    )
};

export default React.memo(ApDashboardFinancial);