import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { PostRequest, GetRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';

const UploadFileService = (file, parameters, callback, onUploadProgressCallback) => {
    let data = new FormData();
    data.append('file', file, file.name);
    if (!!parameters) {
        data.append('parentId', parameters.currentFolderId || '');
        data.append('duplicateFileActionType', parameters.duplicateFileActionType);
    }
        
    return PostRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.uploadFile), data, callback, null, onUploadProgressCallback);
}

const GetFileDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.getFileDetail), parameters, callback);
}

const DeleteFileService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.deleteFile), data, callback);
}

const RenameFileService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.renameFile), data, callback);
}

const GetFileInfoService = (parameters, callback) => {
    return GetRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.getFileInfo), parameters, callback);
}

const FileActionsService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.fileActions), data, callback);
}

const DuplicateNameCheckingService = (parameters, callback) => {
    return GetRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.isNameExists), parameters, callback);
}

export {
    UploadFileService,
    GetFileDetailService,
    RenameFileService,
    DeleteFileService,
    GetFileInfoService,
    FileActionsService,
    DuplicateNameCheckingService
}