import Slider from '@mui/material/Slider';
import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import '../../assets/sass/views/profileSettings/NsStepperSlider.scss';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';

export default class NsStepperSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marks: [],
        }
    }

    percentageCalculator = (userPercentage) => {
        if (isEmpty(this.props.cashbackPlans)) return;

        const totalMaxPercentage = this.props.cashbackPlans[this.props.cashbackPlans.length - 1]?.percentage;
        const percentage = Math.round((userPercentage * 100) / totalMaxPercentage);
        return (percentage)
    }

    userRangeValueCalculator = (plans) => {
        if (isEmpty(plans)) return;

        let maxRangePercentage = plans[plans.length - 1].percentage;
        let userRangePercentage = plans.find(plan => plan.isInRange)?.percentage;
        if (!userRangePercentage) return 1;
        let userRangeValue = (Math.round(userRangePercentage * 100) / maxRangePercentage);
        CommonHelper.safeFunctionCall(this.props.percentageRangeValue, userRangeValue);
        return userRangeValue;
    }

    createMarkLabel = (percentage, cashbackPlans, fromPrice, toPrice) => {
        return <div className='d-flex flex-column align-items-center flew-wrap'>
            <div className='fw-bold fs-4 mb-2'>{isEnCulture() ? `${percentage}%` : `%${percentage}`}</div>
            {!isEmpty(fromPrice) && !isEmpty(toPrice) &&
                <div className='font-size-10 text-black'>{CommonHelper.currencyFormatByDefaultConfig(cashbackPlans.length > 1 ? fromPrice : toPrice, true)}</div>}
        </div>
    }

    createMarks = () => {
        const { cashbackPlans } = this.props;
        if (isEmpty(cashbackPlans)) return;

        // for single plan users
        let maxSinglePlanPercentage = { value: 100, label: '' };
        let userSinglePlanPercentage = { value: 50, label: this.createMarkLabel(cashbackPlans[0]?.percentage) };

        const marks = cashbackPlans.map(plan => ({
            value: this.percentageCalculator(plan.percentage),
            label: this.createMarkLabel(plan.percentage, cashbackPlans, plan.fromPrice, plan.toPrice),
        }));
        if (cashbackPlans.length > 1) this.setState({ marks })
        else this.setState({ marks: [userSinglePlanPercentage, maxSinglePlanPercentage] })
    }

    componentDidMount() {
        this.createMarks();
    }

    sliderValueCalculator = () => {
        return this.props.cashbackPlans.length > 1 ? this.userRangeValueCalculator(this.props.cashbackPlans) : 50
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.cashbackPlans.length !== prevProps.cashbackPlans.length) {
            this.createMarks();
            this.sliderValueCalculator();
        }
    }

    valueLabelFormat = () => {
        const { cashbackForecast } = this.props;
        if (isEmpty(cashbackForecast)) return;

        return <Row className='wrapper-white px-1 py-2 flex-nowrap'>
            <Col className='d-flex flex-wrap align-items-between'>
                <div className='text-color mx-2 d-flex align-items-center'>{getTranslatedRawText('profile.actionButton.cashback')}</div>
                <div className='text-color mx-2 d-flex align-items-center'>{getTranslatedRawText('profile.cashback.purchase')}</div>
            </Col>
            <Col>
                <div className='red-color fw-bold fs-5 d-flex justify-content-start'>
                    {CommonHelper.currencyFormatByDefaultConfig(cashbackForecast.currentDiscountAmount, true)}
                    <span className='font-size-16 text-color ms-1'>{CommonHelper.getDefaultCurrency()}</span>
                </div>
                <div className=' fs-6 text-color d-flex justify-content-start'>
                    {CommonHelper.currencyFormatByDefaultConfig(cashbackForecast.totalPurchasedAmount)}
                </div>
            </Col>
        </Row>
    }

    render() {
        const { marks } = this.state;

        return (
            <div className={clsx('stepper-slider', this.userRangeValueCalculator(this.props.cashbackPlans) < 50 ?
                isEnCulture() ? 'stepper-valueLabel-left' : 'stepper-valueLabel-right'
                : isEnCulture() ? 'stepper-valueLabel-right' : 'stepper-valueLabel-left')}
                style={{ "min-width": CommonHelper.isMobileDevice() ? '1000px' : 'auto' }}>
                <Slider
                    disabled
                    aria-label="nsstepper slider"
                    marks={marks}
                    valueLabelDisplay="on"
                    valueLabelFormat={this.valueLabelFormat}
                    aria-labelledby="non-linear-slider"
                    value={this.sliderValueCalculator()}
                />
            </div>
        );
    }
}
