import React, { useEffect, useRef, useState } from 'react';

import NsAdminPanelContentWrapper from '../../../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import NsTab from '../../../../../../components/tabs/NsTab';
import Config from '../../../../../../configs/Config';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../../configs/Enumerations';
import { GetApDailyQueueOfEstimateTimeService } from '../../../../../../services/adminPanel/AdminOrderService';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../../../../../utilities/ConfigHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import DateHelper from '../../../../../../utilities/DateHelper';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import ApShareSheetOrderModal from '../../shareSheets/ApShareSheetOrderModal';
import { getApDailyQueueOfEstimateTimeColumns } from './ApDailyQueueOfEstimateTimeHelper';
import ApDailyQueueOfEstimateTimeSearch from './ApDailyQueueOfEstimateTimeSearch';

export function ApDailyQueueOfEstimateTime() {
    return (<NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.dailyQueueOfEstimateTime")}
        icon={'ns-icon-daily-estimated h1'}>
        <ApDailyQueueOfEstimateTimeContent />
    </NsAdminPanelContentWrapper>);
}

function ApDailyQueueOfEstimateTimeContent(props) {
    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [searchData, setSearchData] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(false);
    const [selectedTab, setSelectedTab] = useState(ConstantsWithTranslation.sharedSheetOrderEstimatedQueueTurnaround()[0]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [showGroupSheetsOrdersModal, setShowGroupSheetsOrdersModal] = useState(false);
    const [estimateReadyToDeliverTimeFrom, setEstimateReadyToDeliverTimeFrom] = useState(null);
    const [estimateReadyToDeliverTimeTo, setEstimateReadyToDeliverTimeTo] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    const closeApShareSheetOrderDetailModal = () => setShowOrderDetailModal(false);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        };
        return () => { if (!isEmpty(searchData)) avoidingRunUseEffectForFirstTime.current = false };
    }, [searchData]);

    const tabChanged = (selectedTabIndex, selectedTab) => {
        setSelectedTabIndex(selectedTabIndex);
        setSelectedTab(selectedTab);
    };

    const getSearchData = (data) => {
        setSearchData(data);
    };

    const getData = (data, isLoading) => setIsLoading(isLoading);

    const productCategories = () => {
        let isSixHoursSelected = selectedTab?.id === Enumerations.sharedSheetOrderEstimatedQueueTurnaround.sixHours;
        let productCategories = CommonHelper.clearArrayFromEmptyItem([isSixHoursSelected ? getConfigObjectByCulture(Config.sixHoursCategoryId) : null, searchData?.productCategory?.id]);

        let filterProductCategoryIds = {};
        productCategories.forEach((category, index) => {
            if (category !== null) {
                filterProductCategoryIds[`Filter.ProductCategoryIds[${index}]`] = category;
            }
        });
        return filterProductCategoryIds;
    }

    const getParameters = () => {
        let expressTab = Enumerations.sharedSheetOrderEstimatedQueueTurnaround.express;
        let normalTab = Enumerations.sharedSheetOrderEstimatedQueueTurnaround.normal;
        let { express, normal } = Enumerations.turnaround;

        return {
            "Filter.UserId": searchData?.userId || null,
            "Filter.OrderCalculationBasesTypeId": searchData?.calculationBasis?.id || ConstantsWithTranslation.getOrderCalculationBasesTypeOptions()[0]?.id,
            "Filter.TurnaroundTypeId": selectedTab?.id === expressTab ? express : selectedTab?.id === normalTab ? normal : null,
            ...productCategories(),
            "Filter.EstimateReadyToDeliverTimeFrom": DateHelper.getIsoDateTime(searchData?.estimateReadyToDeliverTimeFrom) || DateHelper.getIsoDateTime(DateHelper.changeDayPartofDateTime(-3).setHours(0, 0, 0, 0)), // used 2 DateHelper cause setHours should execute on raw date format
            "Filter.EstimateReadyToDeliverTimeTo": DateHelper.getIsoDateTime(searchData?.estimateReadyToDeliverTimeTo) || DateHelper.getIsoDateTime(DateHelper.changeDayPartofDateTime(6).setHours(23, 59, 59, 59)),
            "Filter.ProductIds[0]": searchData?.productId?.length > 0 ? searchData?.productId[0] : null,
        };
    };

    const selectOrderClicked = (order) => {
        setSelectedOrder(order);
        if (!isEmpty(order.estimateReadyToDeliverTime)) {
            setEstimateReadyToDeliverTimeFrom(order.estimateReadyToDeliverTime);
            setEstimateReadyToDeliverTimeTo(DateHelper.getDateWithEndOfDayTime(order.estimateReadyToDeliverTime));
        }
        setShowGroupSheetsOrdersModal(true);
    }

    const toggleSahreSheetsOrdersModal = () => setShowGroupSheetsOrdersModal(!showGroupSheetsOrdersModal);
    const columns = getApDailyQueueOfEstimateTimeColumns(selectOrderClicked, null, true, null, false);

    const sharedSheetOrderStateTypeTabs = [
        Enumerations.sharedSheetOrderStateType.layouting,
        Enumerations.sharedSheetOrderStateType.lithography,
        Enumerations.sharedSheetOrderStateType.printing,
        Enumerations.sharedSheetOrderStateType.cutting,
        Enumerations.sharedSheetOrderStateType.transferToWarehouse,
        Enumerations.sharedSheetOrderStateType.ready,
    ];

    return (
        <>
            <ApDailyQueueOfEstimateTimeSearch customerDetails={props?.customerDetails}
                setSearchData={getSearchData}
                isLoading={isLoading}
                selectedTab={selectedTab}
                tabChanged={tabChanged} />
            <NsTab tabs={CommonHelper.clearArrayFromEmptyItem(ConstantsWithTranslation.sharedSheetOrderEstimatedQueueTurnaround())}
                value={selectedTabIndex}
                className='border-bottom'
                onChange={tabChanged} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetApDailyQueueOfEstimateTimeService}
                parameters={getParameters()}
                getData={getData}
                columnDefs={columns}
                hasPagination={true} />
            {showGroupSheetsOrdersModal &&
                <ApShareSheetOrderModal showModal={true}
                    searchData={{
                        estimateReadyToDeliverTimeFrom: estimateReadyToDeliverTimeFrom,
                        estimateReadyToDeliverTimeTo: estimateReadyToDeliverTimeTo,
                        turnarounds: selectedTab?.id == Enumerations.sharedSheetOrderEstimatedQueueTurnaround.express
                            ? ConstantsWithTranslation.getTurnarounds()[1]
                            : selectedTab?.id == Enumerations.sharedSheetOrderEstimatedQueueTurnaround.normal
                                ? ConstantsWithTranslation.getTurnarounds()[0]
                                : null,
                        productCategories: selectedTab.id == Enumerations.sharedSheetOrderEstimatedQueueTurnaround.sixHours ? { id: Config.sixHoursCategoryId } : null
                    }}
                    sharedSheetOrderStateTypeTabs={sharedSheetOrderStateTypeTabs}
                    closeModal={toggleSahreSheetsOrdersModal} />
            }
            {resultMessageClass}
        </>
    );
}

export default ApDailyQueueOfEstimateTime;