import React, { useEffect, useState } from 'react';

import NsDateRangePicker from '../../../../../../components/dateTimePickers/NsDateRangePicker';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import NsTypography from '../../../../../../components/text/NsTypography';
import NsSelect from '../../../../../../components/dropdowns/NsSelect';
import NsButton from '../../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import DateHelper from '../../../../../../utilities/DateHelper';

const ApBaseShippingQueueHeader = (props) => {
    const options = ConstantsWithTranslation.getOrderCalculationBasesTypeOptions();
    const [fromDate, setFromDate] = useState(new Date(DateHelper.getCustomPreviousMonth(2)));
    const [toDate, setToDate] = useState(null);
    const [refreshDate, setRefreshDate] = useState(null);
    const [orderCalculationBasesType, setOrderCalculationBasesType] = useState(options[0].id);

    useEffect(() => {
        CommonHelper.safeFunctionCall(props.updateDates, fromDate, toDate, orderCalculationBasesType, refreshDate);
    }, [fromDate, toDate, orderCalculationBasesType, refreshDate]);


    const dateRangeChanged = (dates) => {
        setFromDate(dates.length > 0 ? new Date(dates[0]) : null);
        if (dates.length > 1)
            setToDate(new Date(dates[1]));
        else
            setToDate(null);
    }

    const refreshQueueClicked = () => setRefreshDate(new Date());

    const calculationBasesChanged = (value) => {
        setOrderCalculationBasesType(value);
    }

    return (
        <>
            <div>
                <NsTypography variant='inherit' className='page-title'>
                    {getTranslatedRawText(props.title)}
                </NsTypography>
            </div>
            <div className='d-flex align-items-end gap-2'>
                <NsSelect options={ConstantsWithTranslation.getOrderCalculationBasesTypeOptions()}
                    label={getTranslatedRawText('adminPanel.shippings.calculationBases')}
                    menuItemClassName='font-size-12'
                    className='font-size-12 mx-2'
                    selectedItem={orderCalculationBasesType}
                    valueChanged={calculationBasesChanged} />

                <NsButton className='secondary-outline w-auto'
                    startIcon='ns-icon-retry'
                    size='small'
                    onClick={refreshQueueClicked} />
                <div className='w-auto'>
                    <NsDateRangePicker buttonColor='secondary-outline'
                        dates={[fromDate, toDate]}
                        onChange={dateRangeChanged} />
                </div>
            </div>
        </>
    );
}

export default React.memo(ApBaseShippingQueueHeader);