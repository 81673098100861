import React, { useState } from 'react';

import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../../bases/ApBaseSearchButtons';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';

const ApCustomerFeedbacksModalSearch = (props) => {

    const [demandService, setDemandService] = useState([]);
    const [displayToAll, setDisplayToAll] = useState([]);
    const [averageRateFrom, setAverageRateFrom] = useState([]);
    const [averageRateTo, setAverageRateTo] = useState([]);
    const [submitTimeFrom, setSubmitTimeFrom] = useState(null);
    const [submitTimeTo, setSubmitTimeTo] = useState(null);
    const [feedbackStatus, setFeedbackStatus] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [selectedUser, setSelectedUser] = useState(props.customerDetails || '');

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptionsInMobile = [
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.feedbackStatus'),
            fieldName: 'feedbackStatus',
            value: feedbackStatus,
            items: ConstantsWithTranslation.getFeedbackstatus(),
            onChange: (value) => setFeedbackStatus(value)
        }

    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.demandService'),
            fieldName: 'demandService',
            value: demandService,
            items: ConstantsWithTranslation.getFeedbackDemandServiceType(),
            onChange: (value) => setDemandService(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.averageRateFrom'),
            fieldName: 'averageRateFrom',
            value: averageRateFrom,
            items: ConstantsWithTranslation.getFeedbackRates(),
            onChange: (value) => setAverageRateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.averageRateTo'),
            fieldName: 'averageRateTo',
            value: averageRateTo,
            items: ConstantsWithTranslation.getFeedbackRates(),
            onChange: (value) => setAverageRateTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeFrom'),
            fieldName: 'submitTimeFrom',
            value: submitTimeFrom,
            onChange: (value) => setSubmitTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeTo'),
            fieldName: 'submitTimeTo',
            value: submitTimeTo,
            onChange: (value) => setSubmitTimeTo(value)
        },
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.displaytoAll'),
            fieldName: 'displayToAll',
            value: displayToAll,
            items: ConstantsWithTranslation.getUseFileInOrderOptions(),
            onChange: (value) => setDisplayToAll(value)
        },
    ];

    const searchValues = {
        feedbackStatus,
        selectedUser,
        demandService,
        averageRateFrom,
        averageRateTo,
        submitTimeFrom,
        submitTimeTo,
        displayToAll,
    }

    const showMoreClicked = () => setShowMore(!showMore);

    const searchClicked = () => CommonHelper.safeFunctionCall(props.setSearchData, searchValues);

    const clearButtonClicked = () => {
        setFeedbackStatus(null);
        setSelectedUser('');
        setDemandService('');
        setAverageRateFrom([]);
        setAverageRateTo([]);
        setSubmitTimeFrom(null);
        setSubmitTimeTo(null);
        setDisplayToAll(null);
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={showMore
                    ? advanceSearchOptions
                    : CommonHelper.isMobileDevice()
                        ? basicSearchOptionsInMobile
                        : basicSearchOptions}
                    searchValues={searchValues} mdCol={2} smCol={4} />
                <ApBaseSearchButtons searchClicked={searchClicked}
                    showMoreHandler={showMoreClicked}
                    showMore={showMore}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={props.isLoading} />
            </div>
        </>
    )
}

export default React.memo(ApCustomerFeedbacksModalSearch);