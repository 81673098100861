import React, { useCallback, useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import NsStickyButton from "../../../../components/buttons/NsStickyButton";
import NsTextField from "../../../../components/inputs/NsTextField";
import NsMessageClassModal from "../../../../components/modals/NsMessageClassModal";
import NsModal from "../../../../components/modals/NsModal";
import NsSwitch from "../../../../components/switch/NsSwitch";
import Enumerations from "../../../../configs/Enumerations";
import { DeleteAdvertisementContentService, EditAdvertisementContentService, GetAdvertisementContentByIdService, PostAdvertisementContentService } from "../../../../services/adminPanel/AdminAdvertisementService";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApBaseMapActionButtons from "../../bases/ApBaseMapActionButtons";
import ApLanguageTabs from "../../bases/ApLanguageTabs";
import ApBasesContentVisibility from "../bases/ApBasesContentVisibility";
import ApBasesImageContent from "../bases/ApBasesImageContent";
import ContentHelper from "../bases/ContentHelper";
import ApBasesStandardSizesForUpload from "../bases/ApBasesStandardSizesForUpload";

const ApAdvertisementModal = (props) => {

    const { advertisementId, closeModal, isNewAdvertisement } = props;

    const [multiLanguagePostContent, setMultiLanguagePostContent] = useState([])
    const [messageClassModal, setMessageClassModal] = useState(<></>)
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [showValidationDate, setShowValidationDate] = useState();
    const [showDeletePostModal, setShowDeletePostModal] = useState(false);
    const [isLoading, setIsLoading] = useState(!isNewAdvertisement);

    useEffect(() => {
        !!isNewAdvertisement
            ? setMultiLanguagePostContent(ContentHelper.normalizeContentObject([], { isActive: false }))
            : GetAdvertisementContentByIdService(advertisementId, getPostContentByIdCallback);
    }, []);

    const getPostContentByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguagePostContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive }));
        } else
            setMessageClassModal(messageClassModal);
    }

    const closeModalClicked = (refreshData) => CommonHelper.safeFunctionCall(closeModal, !!refreshData);

    const saveButtonClicked = () => {
        let tempContent = ContentHelper.removePromotionEmptyContent(multiLanguagePostContent);
        let isValidData = ContentHelper.isValidPromotionContent(tempContent);
        if (isValidData) {
            setShowValidationDate(new Date());
            !!isNewAdvertisement
                ? PostAdvertisementContentService(tempContent, postContentCallback)
                : EditAdvertisementContentService(advertisementId, tempContent, postContentCallback)
            setIsLoading(true);
        }
    };

    const postContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModalClicked(true);
        } else setMessageClassModal(messageClassModal)
    }

    const deletePostClicked = () => setShowDeletePostModal(true);

    const deletePost = (confirm) => {
        setShowDeletePostModal(false);
        if (confirm) {
            setIsLoading(true);
            DeleteAdvertisementContentService(advertisementId, deletePostContentCallback);
        }
    };

    const deletePostContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModalClicked(true);
        else
            setMessageClassModal(messageClassModal);
    };

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguagePostContent([...newData]);
    }

    const mainButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary' },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(false), className: 'primary-outline' },
    ];

    const headerButtons = [
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deletePostClicked }
    ];

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText(isNewAdvertisement ? 'adminPanel.post.newPost' : 'adminPanel.post.editPost')}
            showModal={true}
            noSpace={true}
            closeModal={() => CommonHelper.safeFunctionCall(closeModal)}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>

            {!isNewAdvertisement && <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            }

            <div className="p-3">
                <ApLanguageTabs languageChanged={(selectedTab) => {
                    languageChangedHandler(selectedTab)
                }}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguagePostContent) &&
                    <Content key={selectedLanguage.id}
                        serviceCall={() => GetAdvertisementContentByIdService(advertisementId, getPostContentByIdCallback)}
                        stateHandler={stateHandler}
                        fileUploaderCategoryType={Enumerations.fileUploaderCategoryType.promotion}
                        data={[...multiLanguagePostContent]}
                        showValidationDate={showValidationDate}
                        selectedLanguage={selectedLanguage} />
                }
            </div>
            {showDeletePostModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => deletePost(confirm)}
                    messageItems={[
                        {
                            messageText: <>{getTranslatedRawText('common.messages.areYouSureQuestionWithOutParams')} <b>{ContentHelper.getSpecificLanguageContent(multiLanguagePostContent, ContentHelper.getDefaultCultureId()).content.title}</b>?</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }
            {messageClassModal}
        </NsModal>
    )
}

const Content = ({ stateHandler, data, showValidationDate, selectedLanguage, fileUploaderCategoryType, serviceCall }) => {
    let postContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);

    const contentVisibilityTypeIdChanged = (selectedItem) => {
        stateHandler(data, { contentVisibilityTypeId: selectedItem }, selectedLanguage.id)
    }

    const selectedAgencyIdsChanged = (agencyIds) => {
        stateHandler(data, { agencyIds }, selectedLanguage.id)
    }

    return <Row>
        <Col md={5}>
            <ApBasesImageContent content={postContent?.content}
                isChangeFile={!!postContent?.content?.fileUrl}
                fileUploaderCategoryType={fileUploaderCategoryType}
                serviceCall={serviceCall}
                stateHandler={(uploadedFileDetail) => stateHandler(data, { ...uploadedFileDetail }, selectedLanguage.id)} />
            <ApBasesStandardSizesForUpload />
        </Col>
        <Col md={7}>
            <div className='d-flex flex-column'>
                <div className='d-flex'>
                    <div className='flex-grow-1 pe-2'>
                        <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                            valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                            value={postContent?.content.title || null}
                            showValidationDate={showValidationDate}
                            className='w-100 mb-2' />
                    </div>
                    <div className='align-self-center'>
                        <NsSwitch label='adminPanel.slider.active'
                            checked={postContent?.content.isActive}
                            disabled={!selectedLanguage.isDefault}
                            onChange={() => stateHandler(data, { isActive: !postContent?.content.isActive }, selectedLanguage.id)} />
                    </div>
                </div>

                <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.content')}
                    valueChanged={(value) => stateHandler(data, { mainContent: value }, selectedLanguage.id)}
                    value={postContent?.content.mainContent || null}
                    showValidationDate={showValidationDate}
                    className='w-100 mb-2' />

                <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.link')}
                    valueChanged={(value) => stateHandler(data, { link: value }, selectedLanguage.id)}
                    value={postContent?.content.link || null}
                    showValidationDate={showValidationDate}
                    className='w-100 mb-2' />

                <ApBasesContentVisibility contentVisibilityTypeId={postContent?.content.contentVisibilityTypeId}
                    selectedAgencies={postContent?.content.selectedAgencies}
                    contentVisibilityTypeIdChanged={contentVisibilityTypeIdChanged}
                    selectedAgencyIdsChanged={selectedAgencyIdsChanged} />
            </div>
        </Col>
    </Row>
}

export default React.memo(ApAdvertisementModal);