import clsx from 'clsx';
import React from 'react';
import { Button, CircularProgress, IconButton } from '@mui/material';

import '../../assets/sass/components/buttons/NsButton.scss';
import NsCircularProgress from '../circularProgress/NsCircularProgress';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { LoadingButton } from '@mui/lab';

class NsButton extends React.Component {

    buttonClick = (event) => {
        CommonHelper.safeFunctionCall(this.props.onClick, { event });
    }

    render() {
        const { label, loading, disabled, wrapperClassName, onBlur, children, className, startIcon, ...otherProps } = this.props;
        return (
            <div className={clsx("wrapper", wrapperClassName)}>
                {!!label || !!children
                    ? <LoadingButton {...otherProps}
                        disabled={disabled}
                        className={clsx('ns-button', className)}
                        loading={loading}
                        loadingPosition={!!startIcon ? "start" : "center"}
                        startIcon={!!startIcon && <i className={startIcon} />}
                        onClick={this.buttonClick}>
                        {/* {label} */}
                        {!!label ? getTranslatedRawText(label) : label}
                        {children}
                    </LoadingButton>
                    : <>
                        <IconButton {...otherProps}
                            disabled={disabled}
                            onBlur={onBlur}
                            className={clsx('ns-button icon-button', className)}>
                            <i className={startIcon} />
                        </IconButton>
                        <NsCircularProgress size={24}
                            visible={loading} />
                    </>
                }
            </div>
        );
    }
}

export default NsButton;