
import CommonTranslationEn from './common/CommonTranslationEn';
import CommonTranslationFa from './common/CommonTranslationFa';

import ComponentsTranslationEn from './components/ComponentsTranslationEn';
import ComponentsTranslationFa from './components/ComponentsTranslationFa';

import AccountsTranslationEn from './views/accounts/AccountsTranslationEn';
import AccountsTranslationFa from './views/accounts/AccountsTranslationFa';

import ProfileSettingTranslationEn from './views/profileSetting/ProfileSettingTranslationEn';
import ProfileSettingTranslationFa from './views/profileSetting/ProfileSettingTranslationFa';

import HeadersTranslationEn from './views/layouts/main/headers/HeadersTranslationEn';
import HeadersTranslationFa from './views/layouts/main/headers/HeadersTranslationFa';

import FootersTranslationEn from './views/layouts/main/footers/FootersTranslationEn';
import FootersTranslationFa from './views/layouts/main/footers/FootersTranslationFa';

import FileManagerTranslationEn from './views/fileManager/FileManagerTranslationEn';
import FileManagerTranslationFa from './views/fileManager/FileManagerTranslationFa';

import ProductTranslationEn from './views/product/ProductTranslationEn';
import ProductTranslationFa from './views/product/ProductTranslationFa';

import RegularOrderTranslationEn from './views/orders/regularOrder/RegularOrderTranslationEn';
import RegularOrderTranslationFa from './views/orders/regularOrder/RegularOrderTranslationFa';

import ProfessionalOrderTranslationEn from './views/orders/professionalOrder/ProfessionalOrderTranslationEn';
import ProfessionalOrderTranslationFa from './views/orders/professionalOrder/ProfessionalOrderTranslationFa';

import HomeTranslationEn from './views/home/HomeTranslationEn';
import HomeTranslationFa from './views/home/HomeTranslationFa';

import CommonWithoutTranslation from './common/CommonWithoutTranslation';

import AdminPanelTranslationEn from './views/adminPanel/AdminPanelTranslationEn';
import AdminPanelTranslationFa from './views/adminPanel/AdminPanelTranslationFa';

import FestivalTranslationEn from './views/festivals/FestivalTranslationEn';
import FestivalTranslationFa from './views/festivals/FestivalTranslationFa';

import SinglePageTranslationEn from './views/singlePage/SinglePageTranslationEn';
import SinglePageTranslationFa from './views/singlePage/SinglePageTranslationFa';

import ClaimTranslationEn from './claims/ClaimTranslationEn';
import ClaimTranslationFa from './claims/ClaimTranslationFa';

const Translations = {
    en: {
        ...CommonTranslationEn,
        ...ComponentsTranslationEn,
        ...AccountsTranslationEn,
        ...HeadersTranslationEn,
        ...FootersTranslationEn,
        ...FileManagerTranslationEn,
        ...ProfileSettingTranslationEn,
        ...ProductTranslationEn,
        ...RegularOrderTranslationEn,
        ...ProfessionalOrderTranslationEn,
        ...HomeTranslationEn,
        ...CommonWithoutTranslation,
        ...AdminPanelTranslationEn,
        ...FestivalTranslationEn,
        ...SinglePageTranslationEn,
        ...ClaimTranslationEn,
    },
    fa: {
        ...CommonTranslationFa,
        ...ComponentsTranslationFa,
        ...AccountsTranslationFa,
        ...HeadersTranslationFa,
        ...FootersTranslationFa,
        ...FileManagerTranslationFa,
        ...ProfileSettingTranslationFa,
        ...ProductTranslationFa,
        ...RegularOrderTranslationFa,
        ...ProfessionalOrderTranslationFa,
        ...HomeTranslationFa,
        ...CommonWithoutTranslation,
        ...AdminPanelTranslationFa,
        ...FestivalTranslationFa,
        ...SinglePageTranslationFa,
        ...ClaimTranslationFa
    }
};
export default Translations;