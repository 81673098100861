import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetAdvertisementContentsService } from '../../../../services/adminPanel/AdminAdvertisementService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import UrlHelper from '../../../../utilities/UrlHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

class Ads extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ads: [],
            isLoading: false,
            resultMessageClass: <></>
        }
    }

    componentDidMount = () => {
        this.serviceCall();
    }

    serviceCall = () => {
        let parameters = {
            currentPageIndex: 1,
            pageLength: 4,
        }
        // GetAdvertisementContentsService(parameters, this.getAdvertisementContentsCallback);
        this.setState({
            // isLoading: true,
            ads:[]
        })
    }

    getAdvertisementContentsCallback = (resultData, result, resultMessageClass) => {
        this.setState({
            isLoading: false,
            // resultMessageClass
        })
        if (!result.data.hasError) {
            this.setState({
                ads: resultData?.dataItems,
            })
        }
    }

    createAdsMenus = (menus) => {
        return menus?.map((item, index) =>
            <Nav.Item key={index} >
                <Link key={item?.id || index}
                    className='text-decoration-none font-size-12 white-color'
                    target={!!item.link ? UrlHelper.linkUrlHandler(item?.link, window.location.host).target : '#'}
                    to={!!item.link ? UrlHelper.linkUrlHandler(item?.link, window.location.host).url : '#'}>

                    <div className='mb-1'>{item?.title}</div>
                </Link>
            </Nav.Item>)
    }

    render() {
        return (
            !isEmpty(this.state.ads) && <Nav className='d-flex flex-column'>
                <p className="font-size-14 fw-bold">
                    {getTranslatedRawText('footer.ads')}
                </p>
                {this.createAdsMenus(this.state.ads)}
            </Nav>
        );
    }
}

export default Ads;