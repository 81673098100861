import React, { Component } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import '../../../../assets/sass/views/singlePages/bases/card/SpBasesCategoryCard.scss';
import { getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import Links from '../../../../configs/links/AllLinks';
import CommonHelper from '../../../../utilities/CommonHelper';



class SpBasesCategoryCard extends Component {

    constructor(props) {
        super(props);
        this.isMobileDevice = CommonHelper.isMobileDevice();
    }

    render() {
        const { img, title } = this.props;

        return (
            <Link className={clsx('wrapper-white border-start-0 border-end-0 text-decoration-none', this.isMobileDevice && 'ns-category-card')}
                to={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { 'search-input': title, 'page-number': 1 })}
            >
                <div>
                    <img className={clsx('rounded', !this.isMobileDevice && 'w-100')} src={img} />
                </div>
                <div className={clsx('d-flex justify-content-center fw-bold py-2 my-1 title-color text-center px-1', this.isMobileDevice && 'fs-6')}>{title}</div>
            </Link>
        )
    }
}

export default SpBasesCategoryCard;