import React, { useState, useEffect } from 'react';

import NsToggleGroupButton from '../../../../components/buttons/NsToggleGroupButton';
import NsAutocomplete from '../../../../components/dropdowns/NsAutoComplete';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../configs/Enumerations';
import { GetAgencyListService } from '../../../../services/adminPanel/AdminAgenciesService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

const ApBasesContentVisibility = (props) => {
    const { contentVisibilityTypeId,
        selectedAgencies,
        contentVisibilityTypeIdChanged,
        selectedAgencyIdsChanged,
    } = props;
    const [agencies, setAgencies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowAutocompleteAgencies, setIsShowAutocompleteAgencies] = useState(false);
    const [selectedAgenciesAutoComplete, setSelectedAgenciesAutoComplete] = useState([])
    const [messageClassModal, setMessageClassModal] = useState(null);

    useEffect(() => {
        isEmpty(selectedAgencies) && selectedAgencyIdsChanged([]);
        isEmpty(contentVisibilityTypeId) && contentVisibilityTypeIdChanged(Enumerations.contentVisibilityType.all);
        if (contentVisibilityTypeId === Enumerations.contentVisibilityType.signedInUserWithSpecificAgencies)
            setIsShowAutocompleteAgencies(true);
        setSelectedAgenciesAutoComplete(selectedAgencies);
        getAgencies();
    }, [])

    useEffect(() => {
        addSelectedFiledIntoSelectedAgencies(selectedAgencies);
    }, [agencies])

    const addSelectedFiledIntoSelectedAgencies = (selectedAgencies) => {
        if (isEmpty(selectedAgencies))
            selectedAgencies = [];
        setSelectedAgenciesAutoComplete(selectedAgencies)
        return selectedAgencies;
    }

    const getAgencies = () => {
        if (isEmpty(agencies)) {
            setIsLoading(true);
            GetAgencyListService({}, getAgencyNameListCallback);
        } else
            addSelectedFiledIntoSelectedAgencies(selectedAgencies);
    }

    const getAgencyNameListCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError) {
            setAgencies(resultData);
        } else
            setMessageClassModal(messageClass);
    }

    const componentContentVisibilityTypeChanged = (selectedItem) => {

        if (selectedItem.id === Enumerations.contentVisibilityType.signedInUserWithSpecificAgencies) {
            setIsShowAutocompleteAgencies(true);
            getAgencies();
        } else {
            setIsShowAutocompleteAgencies(false);
            setSelectedAgenciesAutoComplete([]);
        }

        CommonHelper.safeFunctionCall(contentVisibilityTypeIdChanged, selectedItem.id);
    }

    const componentSelectedAgenciesChanged = (selectedItems) => {
        let agencyIds = selectedItems.map(item => item.key);
        setSelectedAgenciesAutoComplete(selectedItems);
        CommonHelper.safeFunctionCall(selectedAgencyIdsChanged, agencyIds);
    }

    return (
        <>
            <NsToggleGroupButton isShowGroupLabel={true}
                size='small'
                label={getTranslatedRawText('adminPanel.slider.contentVisibilityMode')}
                items={ConstantsWithTranslation.getContentVisibilityOptions()}
                onClick={componentContentVisibilityTypeChanged}
                selectedItemId={contentVisibilityTypeId} />
            {isShowAutocompleteAgencies &&
                <NsAutocomplete label={getTranslatedRawText('adminPanel.dataGridColumn.agencyName')}
                    multiple={true}
                    disabled={isLoading}
                    onChange={componentSelectedAgenciesChanged}
                    items={agencies}
                    value={selectedAgenciesAutoComplete || []} />
            }
            {messageClassModal}
        </>
    );
};

export default React.memo(ApBasesContentVisibility);