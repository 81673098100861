import React, { Component } from 'react';
import { connect } from 'react-redux';

import Enumerations from '../../configs/Enumerations';
import { GetCmsHomeBottomPromoteContentsService, GetCmsMiddlePromoteContentsService } from '../../services/singlePages/CmsService';
import NsLinkImage from '../../components/images/NsLinkImage';
import { isEmpty } from '../../utilities/ValidationHelper';

class HomeAdvertisementSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            homeAdvertisements: [],
            resultMessageClass: (<></>),
            isLogin: false
        }
    }

    componentDidMount = () => {
        this.props.isBottomSec
            ? GetCmsHomeBottomPromoteContentsService(this.getCmsMiddlePromoteContentsCallback)
            : GetCmsMiddlePromoteContentsService(this.getCmsMiddlePromoteContentsCallback);
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.userFullName !== prevProps.userFullName) {
            GetCmsMiddlePromoteContentsService(this.getCmsMiddlePromoteContentsCallback);
        }
    }

    getCmsMiddlePromoteContentsCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError)
            this.setState({ homeAdvertisements: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        const { homeAdvertisements, resultMessageClass } = this.state;
        return (!isEmpty(homeAdvertisements) &&
            <div className="home-advertisement mb-5" aria-label='middle promotions - advertisement'>

                <div className='text-center'>

                    <ShowImages images={homeAdvertisements} />

                    {resultMessageClass}
                </div>
            </div >
        );
    }
}

function ShowImages(props) {
    const images = [...props.images];
    const showImages = images.map((item, index) => (
        <div key={index}
            className='advertisement-img d-inline-block col-12 col-md-4 p-2'>
            <NsLinkImage imgInfo={item}
                imgClassName="mw-100 border-outline-color-1px"
                imageSitePosition={Enumerations.imageSitePosition.homeMiddlePromotion} />
        </div>
    ));
    return showImages;
}

const mapStateToProps = state => {
    return {
        userFullName: state.currentUser.userFullName,
    };
};

export default connect(mapStateToProps)(HomeAdvertisementSection);