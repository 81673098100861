import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsTextField from '../../../../components/inputs/NsTextField';
import NsTypography from '../../../../components/text/NsTypography';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

function MapConfig(props) {
    const [state, setState] = useState({
        loading: false,

        leaflet: '',
        isValidLeaflet: false,

        googleMap: '',
        isValidGoogleMap: false,

        showValidationDate: ''
    })

    const saveClicked = () => {
        setState({
            ...state,
            showValidationDate: new Date()
        });
        return;
    }

    const discardClicked = () => setState({ loading: true },
        /* () =>  api*/)

    const mainButtons = [
        { label: "common.save", onClicked: saveClicked, icon: 'ns-icon-save', className: 'primary' },
        { label: "common.discard", onClicked: discardClicked, icon: 'ns-icon-close', className: 'primary-outline' },
    ];

    const valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        setState({
            ...state,
            [valueName]: value,
            [isValidName]: isValid
        })
    }

    return (
        <NsPageMainContentWrapper header={getTranslatedRawText("adminPanel.sidebar.mapConfig")}
            icon={'ns-icon-setting h1'}>
            <NsTypography variant='inherit' className='ms-3 section-title d-block'>{getTranslatedRawText('adminPanel.mapConfig.mapApiKeys')}</NsTypography>

            <Col xs={4} className="mb-3">
                <NsTextField
                    valueChanged={(value, isValid) =>
                        valueChanged(value, isValid, { leaflet: state.leaflet }, { isValidLeaflet: state.isValidLeaflet })}
                    showValidationDate={state.showValidationDate}
                    label={getTranslatedRawText("adminPanel.mapConfig.leaflet")}
                    size="small"
                    value={state.leaflet}
                    className="w-100" />
            </Col>

            <Col xs={4} className="mb-3">
                <NsTextField
                    valueChanged={(value, isValid) =>
                        valueChanged(value, isValid, { googleMap: state.googleMap }, { isValidGoogleMap: state.isValidGoogleMap })}
                    showValidationDate={state.showValidationDate}
                    label={getTranslatedRawText("adminPanel.mapConfig.googleMap")}
                    size="small"
                    value={state.googleMap}
                    className="w-100" />
            </Col>


            <NsStickyButton stickyButtons={mainButtons} />

        </NsPageMainContentWrapper>
    );
}

export default React.memo(MapConfig);