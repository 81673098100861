import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NsBreadcrumbs from '../../../../components/breadcrumbs/NsBreadcrumbs';
import { breadcrumbsActionCreators } from '../../../../redux/reducers/BreadcrumbsReducer';
import { metaTagActionCreators } from '../../../../redux/reducers/MetaTagReducer';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsButtonOrLink from '../../../../components/buttons/NsButtonOrLink';
import CommonHelper from '../../../../utilities/CommonHelper';

class MainBreadcrumbs extends React.Component {

    componentDidMount = () => {
        this.props.setMetaTags({ title: getTranslatedRawText(this.props.title) });
        this.props.setBreadcrumbs(this.props.breadCrumb);
    }

    onClick = (link) => link?.length > 1 && CommonHelper.safeFunctionCall(this.props.onClick, link);

    mapBreadcrumbsLinks = () => {
        const { breadcrumbs } = this.props;
        if (breadcrumbs.length <= 1)
            return;
        else
            return breadcrumbs.map(item =>
                <NsButtonOrLink key={item.id}
                    className="text-decoration-none text-muted"
                    to={item.link}
                    onClick={() => this.onClick(item.link)}>
                    {typeof item.name === 'object' ? item.name : getTranslatedRawText(item.name)}
                </NsButtonOrLink>
            );
    }

    render() {
        return (
            <NsBreadcrumbs className="w-100 mb-1" >
                {this.mapBreadcrumbsLinks()}
            </NsBreadcrumbs>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        breadcrumbs: state.breadcrumbs
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...breadcrumbsActionCreators, ...metaTagActionCreators }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MainBreadcrumbs);