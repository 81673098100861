import React, { useEffect, useState } from 'react';

import { GetApDaUCbForecastWithGrowthPercentService } from '../../../../services/adminPanel/AdminDashboardService';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { CustomGrowthField } from '../bases/ApBasesDashboardHelper';
import NsDataGrid from '../../../../components/grids/NsDataGrid';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import DateHelper from '../../../../utilities/DateHelper';
import NsCard from '../../../../components/cards/NsCard';

const ApDcbCurrentMonthVsFromDate = (props) => {
    const { refreshDate, fromDate } = props;
    const [forecastWithGrowthPercent, setForecastWithGrowthPercent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState((<></>));

    useEffect(() => {
        hasAccess([UserClaimEnumerations.getApCshbForcastsWithGrowthPercent]) && getCashbackForecastWithGrowthPercent();
    }, [refreshDate, fromDate])

    const getCashbackForecastWithGrowthPercent = () => {
        setIsLoading(true);
        GetApDaUCbForecastWithGrowthPercentService({ fromDate }, getApDaUCbForecastWithGrowthPercentCallback);
    }

    const getApDaUCbForecastWithGrowthPercentCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result?.data?.hasError) setForecastWithGrowthPercent(resultData);
        else setResultMessageClass(resultMessageClass);
    }

    const columns = [
        {
            headerName: '',
            headerClass: 'background-element-color border-outline-color-1px',
            children: [
                {
                    field: 'date',
                    headerName: getTranslatedRawText('adminPanel.cashback.currentMonthWithVs') + DateHelper.getDateAndTime(fromDate),
                    width: 250,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <div>
                            {CommonHelper.stringFormat(getTranslatedRawText('adminPanel.cashback.vs'), DateHelper.getDateAndTime(props.currentDate), DateHelper.getDateAndTime(props.baseDate))}
                        </div>
                    }
                },
                {
                    field: 'sumNetSale',
                    headerName: getTranslatedRawText('adminPanel.cashback.totalSalesAmount'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.sumNetSale} percent={props.sumNetSaleGrowthPercent} />
                    }
                },
                {
                    field: 'totalCashbackAmount',
                    headerName: getTranslatedRawText('adminPanel.cashback.totalCashbackAmount'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.totalCashbackAmount} percent={props.sumCurrentDiscountAmountGrowthPercent} />
                    }
                },
                {
                    field: 'countAchievedUserToDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.countAchievedUserToDiscount'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.countAchievedUserToDiscount} percent={props.countAchievedUserToDiscountGrowthPercent} />
                    }
                }
            ]
        },
        {
            headerName: getTranslatedRawText('adminPanel.cashback.customerWithCommonPlan'),
            headerClass: 'background-element-color border-outline-color-1px',
            children: [
                {
                    field: 'countUserWithRegularPlanDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.countUser'),
                    width: 190,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.countUserWithRegularPlanDiscount} percent={props.countUserWithRegularPlanDiscountGrowthPercent} />
                    }
                },
                {
                    field: 'sumNetSaleUserWithRegularPlanDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.totalSalesAmount'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.sumNetSaleUserWithRegularPlanDiscount} percent={props.sumNetSaleUserWithRegularPlanDiscountGrowthPercent} />
                    }
                },
                {
                    field: 'sumCurrentDiscountAmountUserWithRegularPlanDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.totalCashbackAmount'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.sumCurrentDiscountAmountUserWithRegularPlanDiscount} percent={props.sumCurrentDiscountAmountUserWithRegularPlanDiscountGrowthPercent} />
                    }
                },
                {
                    field: 'averageSumNetSaleUserWithRegularPlanDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.averageSaleAmount'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.averageSumNetSaleUserWithRegularPlanDiscount} percent={props.averageSumNetSaleUserWithRegularPlanDiscountGrowthPercent} />
                    }
                },
                {
                    field: 'averageDiscountPercentageUserWithRegularPlanDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.averageCashbackPercentage'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.averageDiscountPercentageUserWithRegularPlanDiscount} percent={props.averageDiscountPercentageUserWithRegularPlanDiscountGrowthPercent} isPercentage={true} />
                    }
                }
            ]
        },
        {
            headerName: getTranslatedRawText('adminPanel.cashback.customerWithSpecialPlan'),
            headerClass: 'background-element-color border-outline-color-1px',
            children: [
                {
                    field: 'countUserWithSpecialDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.countUser'),
                    width: 190,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.countUserWithSpecialDiscount} percent={props.countUserWithSpecialDiscountGrowthPercent} />
                    }
                },
                {
                    field: 'sumNetSaleUserWithSpecialDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.totalSalesAmount'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.sumNetSaleUserWithSpecialDiscount} percent={props.sumNetSaleUserWithSpecialDiscountGrowthPercent} />
                    }
                },
                {
                    field: 'sumCurrentDiscountAmountUserWithSpecialDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.totalCashbackAmount'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.sumCurrentDiscountAmountUserWithSpecialDiscount} percent={props.sumCurrentDiscountAmountUserWithSpecialDiscountGrowthPercent} />
                    }
                },
                {
                    field: 'averageSumNetSaleUserWithSpecialDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.averageSaleAmount'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.averageSumNetSaleUserWithSpecialDiscount} percent={props.averageSumNetSaleUserWithSpecialDiscountGrowthPercent} />
                    }
                },
                {
                    field: 'averageDiscountPercentageUserWithSpecialDiscount',
                    headerName: getTranslatedRawText('adminPanel.cashback.averageCashbackPercentage'),
                    width: 200,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.averageDiscountPercentageUserWithSpecialDiscount} percent={props.averageDiscountPercentageUserWithSpecialDiscountGrowthPercent} isPercentage={true} />
                    }
                }
            ]
        },
        {
            headerName: '',
            headerClass: 'background-element-color border-outline-color-1px',
            children: [
                {
                    field: 'countNotAchievedUserToDiscountButHasOrder',
                    headerName: getTranslatedRawText('adminPanel.cashback.qtyOfCustomersNotReachedTheCashbackPlan'),
                    width: 250,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.countNotAchievedUserToDiscountButHasOrder} percent={props.countNotAchievedUserToDiscountButHasOrderGrowthPercent} />
                    }
                },
                {
                    field: 'sumNetSaleNotAchievedUserToDiscountButHasOrder',
                    headerName: getTranslatedRawText('adminPanel.cashback.totalSalesOfCustomersNotReachedTheCashbackPlan'),
                    width: 270,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <CustomGrowthField number={props.sumNetSaleNotAchievedUserToDiscountButHasOrder} percent={props.sumNetSaleNotAchievedUserToDiscountButHasOrderGrowthPercent} />
                    }
                },
            ]
        }
    ]

    const refreshGridClicked = () => {
        setForecastWithGrowthPercent([]);
        getCashbackForecastWithGrowthPercent();
    }

    return (
        <NsCard className='p-3 h-100' >
            <NsDataGrid key={forecastWithGrowthPercent.length}
                isLoading={isLoading}
                columnDefs={columns}
                handlePagination={refreshGridClicked}
                rows={forecastWithGrowthPercent} />

            {resultMessageClass}
        </NsCard >
    );
};

export default React.memo(ApDcbCurrentMonthVsFromDate);