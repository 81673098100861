import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsTextField from '../../../../components/inputs/NsTextField';
import NsTypography from '../../../../components/text/NsTypography';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

function Telecommunication(props) {
    const [state, setState] = useState({
        loading: false,
        sahandIsActive: false,
        sahandUsername: '',
        isValidSahandUsername: '',

        sahandPassword: '',
        isValidSahandPassword: '',

        fromNumber: '',
        isValidFromNumber: '',

        sahandBaseApiURL: '',
        isValidSahandBaseApiURL: '',

        smartIsActive: false,
        smartUsername: '',
        isValidSmartUsername: '',

        smartPassword: '',
        isValidSmartPassword: '',

        senderID: '',
        isValidSenderID: '',

        smtpIsActive: false,
        smtpUsername: '',
        isValidSmtpUsername: '',

        smtpPassword: '',
        isValidSmtpPassword: '',

        host: '',
        isValidHost: '',

        smtpPort: '',
        isValidSmtpPort: '',

        showValidationDate: ''
    })
    const saveClicked = () => {
        setState({
            ...state,
            showValidationDate: new Date()
        });
        return;
    }

    const discardClicked = () => setState({ loading: true },
        /* () =>  api*/)

    const mainButtons = [
        { label: "common.save", onClicked: saveClicked, icon: 'ns-icon-save', className: 'primary' },
        { label: "common.discard", onClicked: discardClicked, icon: 'ns-icon-close', className: 'primary-outline' },
    ];

    const valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        setState({
            ...state,
            [valueName]: value,
            [isValidName]: isValid
        })
    }

    return (
        <NsPageMainContentWrapper header={getTranslatedRawText("adminPanel.sidebar.telecommunication")}
            icon='ns-icon-setting h1'>

            <div>
                <NsTypography variant='inherit' className='section-title d-block'>{getTranslatedRawText('adminPanel.telecommunication.sahandSMS')}</NsTypography>
                {/* <NsSwitchButton className='mt-3 primary'
                    labelOn='on'
                    checked={state.sahandIsActive}
                    labelOff='off'
                    switchChanged={() => setState({
                        ...state,
                        sahandIsActive: !state.sahandIsActive
                    })} /> */}
                <Row className='w-100 '>
                    <Col xs={4} >
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { sahandUsername: state.sahandUsername }, { isValidSahandUsername: state.isValidSahandUsername })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.username")}
                            size="small"
                            value={state.sahandUsername}
                            className="w-100 mt-3" />
                    </Col>
                    <Col xs={4}>
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { sahandPassword: state.sahandPassword }, { isValidSahandPassword: state.isValidSahandPassword })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.password")}
                            size="small"
                            value={state.sahandPassword}
                            className="w-100 mt-3" />
                    </Col>
                    <Col xs={4}>
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { fromNumber: state.fromNumber }, { isValidFromNumber: state.isValidFromNumber })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.fromNumber")}
                            size="small"
                            value={state.fromNumber}
                            className="w-100 mt-3" />
                    </Col>
                    <Col xs={4}>
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { sahandBaseApiURL: state.sahandBaseApiURL }, { isValidSahandBaseApiURL: state.isValidSahandBaseApiURL })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.baseApiURL")}
                            size="small"
                            value={state.sahandBaseApiURL}
                            className="w-100 mt-3" />
                    </Col>
                </Row>

            </div>

            <div className='mt-5'>
                <NsTypography variant='inherit' className='section-title d-block'>{getTranslatedRawText('adminPanel.telecommunication.smartSMS')}</NsTypography>
                <NsSwitchButton className='mt-3 primary'
                    labelOn='on'
                    checked={state.smartIsActive}
                    labelOff='off'
                    switchChanged={() => setState({
                        ...state,
                        smartIsActive: !state.smartIsActive
                    })} />
                <Row className='w-100 '>
                    <Col xs={4} >
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { smartUsername: state.smartUsername }, { isValidSmartUsername: state.isValidSmartUsername })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.username")}
                            size="small"
                            value={state.smartUsername}
                            className="w-100 mt-3" />
                    </Col>
                    <Col xs={4}>
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { smartPassword: state.smartPassword }, { isValidSmartPassword: state.isValidSmartPassword })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.password")}
                            size="small"
                            value={state.smartPassword}
                            className="w-100 mt-3" />
                    </Col>
                    <Col xs={4}>
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { senderID: state.senderID }, { isValidSenderID: state.isValidSenderID })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.senderID")}
                            size="small"
                            value={state.senderID}
                            className="w-100 mt-3" />
                    </Col>
                    <Col xs={4}>
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { smartBaseApiURL: state.smartBaseApiURL }, { isValidSmartBaseApiURL: state.isValidSmartBaseApiURL })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.baseApiURL")}
                            size="small"
                            value={state.smartBaseApiURL}
                            className="w-100 mt-3" />
                    </Col>
                </Row>

            </div>

            <div className='mt-5'>
                <NsTypography variant='inherit' className='section-title d-block'>{getTranslatedRawText('adminPanel.telecommunication.smtp')}</NsTypography>
                <div className='d-flex mt-3'>
                    <NsTypography variant='inherit' className='text-color font-size-16 me-2'>{getTranslatedRawText('adminPanel.telecommunication.useDefaultCredential')}</NsTypography>
                    <NsSwitchButton className=' primary'
                        labelOn='on'
                        checked={state.smtpIsActive}
                        labelOff='off'
                        switchChanged={() => setState({
                            ...state, smtpIsActive: !state.smtpIsActive
                        })} />
                </div>
                <Row className='w-100'>
                    <Col xs={4} >
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { smtpUsername: state.smtpUsername }, { isValidSmtpUsername: state.isValidSmtpUsername })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.username")}
                            size="small"
                            value={state.smtpUsername}
                            className="w-100 mt-3" />
                    </Col>
                    <Col xs={4}>
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { smtpPassword: state.smtpPassword }, { isValidSmtpPassword: state.isValidSmtpPassword })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.password")}
                            size="small"
                            value={state.smtpPassword}
                            className="w-100 mt-3" />
                    </Col>
                    <Col xs={4}>
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { host: state.host }, { isValidHost: state.isValidHost })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.host")}
                            size="small"
                            value={state.host}
                            className="w-100 mt-3" />
                    </Col>
                    <Col xs={4}>
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { smtpPort: state.smtpPort }, { isValidSmtpPort: state.isValidSmtpPort })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.telecommunication.port")}
                            size="small"
                            value={state.smtpPort}
                            className="w-100 mt-3" />
                    </Col>
                </Row>

            </div>

            <NsStickyButton stickyButtons={mainButtons} />


        </NsPageMainContentWrapper>
    );
}

export default React.memo(Telecommunication);