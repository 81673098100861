import { Row } from 'react-bootstrap';
import React, { useState } from 'react';

import { GetAuditFileAsanPardakhtService, GetAuditFileNgeniusService, GetAuditFileSepehrService } from '../../../../../services/adminPanel/AdminPaymentService';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsLabelBox from '../../../../../components/labels/NsLabelBox';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getApAuditFileDetailColumns } from '../ApPaymentHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import ApPaymentDetailModal from '../ApPaymentDetailModal';

export const ApPaymentAuditImportedFileDetailContent = (props) => {

    const { paymentGatewayType, fileSourceId, auditId } = props;
    const [showPaymentDetailModal, setShowPaymentDetailModal] = useState(false);
    const [selectedPaymentId, setSelectedPaymentId] = useState(null);

    const getService = () => {
        switch (paymentGatewayType?.key) {
            case Enumerations.paymentGateway.asanPardakht: return GetAuditFileAsanPardakhtService;
            case Enumerations.paymentGateway.sepehr: return GetAuditFileSepehrService;
            case Enumerations.paymentGateway.nGeniusPayment: return GetAuditFileNgeniusService;
            default: return '';
        }
    }

    const getPaymentDetailClicked = (id) => {
        setSelectedPaymentId(id);
        setShowPaymentDetailModal(true);
    }

    const closeApPaymentDetailModal = () => setShowPaymentDetailModal(false);

    const columns = getApAuditFileDetailColumns(getPaymentDetailClicked, paymentGatewayType?.key);

    return (<>
        <Row className='mx-auto'>
            <div className='background-color px-3 py-2 mb-3 d-flex'>
                <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.paymentGateway')} value={paymentGatewayType?.value} />
            </div>
        </Row>

        <div className='p-2'>
            <NsDataGridWithServiceCall columnDefs={columns}
                service={getService()}
                parameters={{ id: fileSourceId, actionId: auditId || '' }}
                hasPagination={true} />
        </div>
        {
            showPaymentDetailModal &&
            <ApPaymentDetailModal showModal={true}
                selectedPaymentId={selectedPaymentId}
                closeModal={closeApPaymentDetailModal} />
        }
    </>
    );
}

const ApPaymentAuditImportedFileDetailModal = (props) => {
    return (<NsModal maxWidth='lg'
        showModal={true}
        closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
        noSpace={true}
        dialogTitle={getTranslatedRawText("adminPanel.audit.importedFileDetail")} >

        <ApPaymentAuditImportedFileDetailContent {...props} />
    </NsModal >
    );
}

export default React.memo(ApPaymentAuditImportedFileDetailModal);