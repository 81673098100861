import React from 'react';
import { GetGalleryService } from "../../../../../services/adminPanel/AdminGalleryService";
import ApBasesPostContent from "../../bases/ApBasesPostContent";

const ApGalleryPost = () => {

    return (
        <ApBasesPostContent
            isGallery={true}
            service={GetGalleryService} />
    )
}

export default React.memo(ApGalleryPost);