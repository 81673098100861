import React, { useState } from 'react';

import { GetApDfbByCustomersGridService } from '../../../../../services/adminPanel/AdminDashboardService';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsCard from '../../../../../components/cards/NsCard';
import ApCustomerFeedbacksModal from '../../../managements/users/customers/ApCustomerFeedbacksModal';

const ApBasesDfbCustomersGrid = ({ refreshDate, parameters }) => {

    const [selectedUserId, setselectedUserId] = useState(null);
    const [showUserModal, setShowUserModal] = useState(null);

    const columns = [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => userDetailClicked(id),
            }
        },
        {
            field: 'userName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 150
        },
        {
            field: 'averageRate',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.avgRate'),
            dataType: Enumerations.gridType.number,
            sortable: true,
            width: 130
        },
        {
            field: 'quantity',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.qty'),
            dataType: Enumerations.gridType.number,
            sortable: true,
            width: 90
        }
    ];

    const userDetailClicked = (id) => {
        setselectedUserId(id);
        setShowUserModal(true);
    }

    const closeUserModal = () => setShowUserModal(false);

    return (
        <NsCard className="p-3 h-100">
            <div className='page-title mb-2'>{getTranslatedRawText('adminPanel.dashboard.customer')}</div>

            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={GetApDfbByCustomersGridService}
                parameters={parameters}
                hasPagination={true}
                columnDefs={columns} />

            {showUserModal &&
                <ApCustomerFeedbacksModal searchData={{ user: selectedUserId }}
                    userId={selectedUserId}
                    closeModal={closeUserModal} />
            }

        </NsCard >
    )
}

export default React.memo(ApBasesDfbCustomersGrid);