import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import OrderHelper from '../../../../../utilities/OrderHelper';
import ExtendedServiceWrapper from './ExtendedServiceWrapper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import NsRadioButton from '../../../../../components/buttons/NsRadioButton';
import NsTour from '../../../../../components/tour/NsTour';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import NsOptionBox from '../../../../../components/labels/NsOptionBox';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
class ExtendedServiceCornerType extends React.Component {
    constructor(props) {
        super(props);
        this.tourId = Enumerations.tourGuide.roundedCorner;
        this.tourSteps = RegularOrderTours.getRoundedCornerTour();
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = {
            showTour: !CurrentUserHelper.isTourSeen(this.tourId),
            selectedRoundedCorner: {},
            refreshExtendedServiceSummaryDate: null,
            roundedCornerOptions: this.getCornerOptions(),
        }
    }

    getCornerOptions = () => {
        if (OrderHelper.isValidOrder(this.routeId)) {
            let roundedCornerPrice = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.roundedCorner)?.price;
            let roundedCornerOptions = ConstantsWithTranslation.getRoundedCornerOptions(roundedCornerPrice)
            return roundedCornerOptions;
        }
        return [];
    }

    componentDidMount = () => {
        if (OrderHelper.isValidOrder(this.routeId)) {
            let service = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.roundedCorner);
            if (!isEmpty(service?.selectedItem))
                this.setState({ selectedRoundedCorner: service.selectedItem });
            else
                this.cornerOptionClicked(this.state.roundedCornerOptions[0]?.id, true);
        }

    }

    cornerOptionClicked = (id, doNotAddToSeenTours) => {
        let selectedRoundedCorner = this.state.roundedCornerOptions.find(option => option.id === Number(id));
        OrderHelper.updateExtendedService(this.routeId, Enumerations.extendedServices.roundedCorner, selectedRoundedCorner);
        this.setState({ selectedRoundedCorner, refreshExtendedServiceSummaryDate: new Date(), showTour: !CurrentUserHelper.isTourSeen(this.tourId) });
        !doNotAddToSeenTours && !CurrentUserHelper.isTourSeen(this.tourId) && CurrentUserHelper.addToSeenTours(this.tourId);

    }

    render() {
        const { selectedRoundedCorner, roundedCornerOptions, refreshExtendedServiceSummaryDate, showTour } = this.state;

        return (
            <ExtendedServiceWrapper otherProps={this.props}
                routeId={this.routeId}
                isSelectedRequirements={!isEmpty(selectedRoundedCorner)}
                refreshExtendedServiceSummaryDate={refreshExtendedServiceSummaryDate}
                currentExtendedService={Enumerations.extendedServices.roundedCorner}>

                <Row className='extendedService-tour-roundedCorner g-0 px-3'>
                    <CornerOptions roundedCornerOptions={roundedCornerOptions}
                        cornerOptionClicked={this.cornerOptionClicked}
                        selectedRoundedCorner={selectedRoundedCorner} />

                </Row>

                {showTour && <NsTour steps={this.tourSteps}
                    id={this.tourId} />}
            </ExtendedServiceWrapper>
        );
    }
}

function CornerOptions(props) {
    if (props.roundedCornerOptions) {
        return props.roundedCornerOptions.map((option) => {
            return (
                <Col xs={10} sm={4} key={option.id} className='mb-2' onClick={() => props.cornerOptionClicked(option.id)}>
                    <NsOptionBox isSelected={props.selectedRoundedCorner.id === option.id}>

                        <span className={clsx('icon-size', option.icon)} />

                        <div>
                            <div className="font-size-12 light-text-divor fw-bold mt-2">
                                <NsRadioButton option={option}
                                    checked={props.selectedRoundedCorner.id === option.id} />
                            </div>
                            <div className="font-size-12 d-none d-md-block ms-3 mb-2">
                                {option.description}
                            </div>
                            <div className="font-size-12 light-text-color mt-auto ms-3">
                                {CommonHelper.currencyFormatByDefaultConfig(option.price)}
                            </div>

                        </div>
                    </NsOptionBox>
                </Col>
            );
        });
    }
}

export default ExtendedServiceCornerType