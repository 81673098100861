import clsx from 'clsx';
import React from 'react';
import NsButton from '../../../../components/buttons/NsButton';

import NsCircularProgress from '../../../../components/circularProgress/NsCircularProgress';
import NsImageScaledView from '../../../../components/images/NsImageScaledView';
import Enumerations from '../../../../configs/Enumerations';
import { GetOriginFileService } from '../../../../services/adminPanel/AdminCustomerFilesService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

class OrderThumbnailGroupPreview extends React.Component {

    constructor(props) {
        super(props);
        this.downloadButton = React.createRef();
        this.state = {
            fileDownloadName: null,
            fileContents: null,
            isLoading: false,
            messageClassModal: <></>
        }
    }

    checkMousePointerIsActive = (hasThumbnail) => this.props.isPointerActive && hasThumbnail && 'cursor-pointer';

    downloadOriginFileClicked = (userFileId) => {
        this.setState({
            isLoading: true
        })
        GetOriginFileService(userFileId, this.getOriginFileCallback)
    };

    getOriginFileCallback = (resultData, result, messageClassModal) => {
        const href = !isEmpty(resultData) && CommonHelper.createDownloadFileUrl(resultData?.fileDownloadName, resultData?.fileContents);
        this.setState({
            fileDownloadName: resultData?.fileDownloadName,
            fileContents: href,
            isLoading: false,
            messageClassModal
        }, () => {
            !isEmpty(href) && this.downloadButton.current.click();
        });
    }

    render() {
        const { files, printedSide, showDefaultImage, showDownloadButton } = this.props;

        return files.map((file, index) =>
            <div key={index}>
                <div className='light-paragraph text-nowrap pb-1'>
                    {file.title}
                </div>
                <ThumbnailPreview className={this.checkMousePointerIsActive(!!file?.frontSideFileInfo?.thumbnail)}
                    isLoading={this.state.isLoading}
                    imageClicked={() => CommonHelper.safeFunctionCall(this.props.imageClicked, !!file.frontSideFileInfo?.draft, index, true)}
                    downloadOriginFileClicked={() => this.downloadOriginFileClicked(file?.frontSideFileInfo?.userFileId)}
                    showDefaultImage={showDefaultImage}
                    showDownloadButton={showDownloadButton}
                    thumbnail={file?.frontSideFileInfo?.thumbnail}
                    width={this.props.width}
                    height={this.props.height} />

                {printedSide === Enumerations.printedSide.twoSide &&
                    <ThumbnailPreview thumbnail={file?.backSideFileInfo?.thumbnail}
                        className={this.checkMousePointerIsActive(!!file?.backSideFileInfo?.thumbnail)}
                        isLoading={this.state.isLoading}
                        imageClicked={() => CommonHelper.safeFunctionCall(this.props.imageClicked, !!file?.backSideFileInfo?.draft, index, false)}
                        downloadOriginFileClicked={() => this.downloadOriginFileClicked(file?.backSideFileInfo?.userFileId)}
                        showDefaultImage={showDefaultImage}
                        showDownloadButton={showDownloadButton}
                        width={this.props.width}
                        height={this.props.height} />
                }

                {this.state.messageClassModal}

                <a ref={this.downloadButton} target='_blank' href={this.state.fileContents}
                    download={this.state.fileDownloadName} />

            </div>
        );
    }
}

export function ThumbnailPreview({
    className,
    imageClicked,
    isLoading,
    showDefaultImage,
    thumbnail,
    width,
    height,
    showDownloadButton,
    isUsedInPo,
    downloadOriginFileClicked }) {
    return (<div className={clsx('mb-2 w-100 img-container', className)}>
        <div className='position-relative'>
            <NsCircularProgress size={24}
                visible={isLoading} />
            <NsImageScaledView className='background-disable-color'
                showDefaultImage={showDefaultImage}
                imgAlt="components.imagesAlt.imageThumbnail"
                src={thumbnail}
                width={width || '90px'}
                height={height || '90px'} />

                <div className='d-flex gap-2 position-absolute top-50 start-50 btn-on-img translate-middle'>
                    {showDownloadButton &&
                        <NsButton startIcon='ns-icon-download'
                            size='small'
                            className='secondary'
                            onClick={downloadOriginFileClicked} />
                    }
                    <NsButton startIcon='ns-icon-fit'
                        size='small'
                        className='secondary'
                        onClick={imageClicked} />
                </div>


        </div>
    </div >
    );
}

export default OrderThumbnailGroupPreview;