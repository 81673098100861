import React, { useState, useEffect } from "react";

import { GetApDauNotProcessedAuditCountService } from "../../../../services/adminPanel/AdminDashboardService";
import NsSkeleton from "../../../../components/skeletons/NsSkeleton";
import Enumerations from "../../../../configs/Enumerations";
import NsCard from "../../../../components/cards/NsCard";

const ApDauNotProcessed = (props) => {

    const { parameters, refreshDate } = props;
    const [notProcessedCount, setNotProcessedCount] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState();

    useEffect(() => {
        setIsLoading(true);
        GetApDauNotProcessedAuditCountService(parameters, getApDauNotProcessedAuditCountCallback);
    }, [refreshDate])

    const getApDauNotProcessedAuditCountCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setNotProcessedCount(resultData);
        } else
            setMessageClassModal(messageClassModal);
    };

    return (
        <NsCard className='px-3 py-2'>
            <div className="d-flex justify-content-between ">
                <i className="ns-icon-time light-text-color align-self-center h1" />
                <div className="d-flex flex-column align-items-end">
                    <span className="fw-bold font-size-24">{isLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.text,
                            ]} />
                        : notProcessedCount?.value
                    }</span>
                    <span className="light-text-color font-size-14">{notProcessedCount?.key}</span>
                </div>
            </div>
            {messageClassModal}
        </NsCard>
    );
};

export default React.memo(ApDauNotProcessed);