import clsx from "clsx";
import { Col, Row } from "react-bootstrap";
import React, { useRef, useState, useEffect } from "react";

import { getShoppingBagHistoryById, priceCalculation, saveShoppingBagHistory } from "../bases/shoppingBagBasesHelper";
import { GetBagPrintPricesService } from "../../../../services/product/BagProductServices";
import NsToggleGroupButton from "../../../../components/buttons/NsToggleGroupButton";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import ShoppingBagMainResultCalculation from "./ShoppingBagMainResultCalculation";
import SignInModal from "../../../layouts/main/headers/modals/signIn/SigninModal";
import NsAutoComplete from "../../../../components/dropdowns/NsAutoComplete";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import ShoppingBagSubmitOrderModal from "./ShoppingBagSubmitOrderModal";
import DelayedTaskHelper from "../../../../utilities/DelayedTaskHelper";
import NsTooltip from "../../../../components/tooltips/NsTooltip";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import NsButton from "../../../../components/buttons/NsButton";
import CommonHelper from "../../../../utilities/CommonHelper";
import Enumerations from "../../../../configs/Enumerations";
import Config from "../../../../configs/Config";

const ShoppingBagMainPriceInquery = (props) => {
    const { reviewHistoryId, isUsedInAdmin, isAuthenticated } = props;
    const avoidingRunUseEffectForFirstTime = useRef();
    const calculationRef = useRef();
    const shoppingBagsConfig = Config.shoppingBags;
    const gussets = createOptions(shoppingBagsConfig.gusset);
    const widths = createOptions(shoppingBagsConfig.width);
    const heights = createOptions(shoppingBagsConfig.height);
    const quantities = createOptions(shoppingBagsConfig.quantity);
    const paperThicknesses = createOptions(shoppingBagsConfig.paperThickness);
    const packaging = ConstantsWithTranslation.getPackagingType();
    const paperType = ConstantsWithTranslation.getPaperType();
    const topFold = ConstantsWithTranslation.getUseFileInOrderOptions();
    const turnaround = ConstantsWithTranslation.getTurnarounds();
    const [gusset, setGusset] = useState(gussets[0]);
    const [width, setWidth] = useState(widths[0]);
    const [height, setHeight] = useState(heights[0]);
    const [paperThickness, setPaperThickness] = useState(paperThicknesses[0]);
    const [quantity, setQuantity] = useState(quantities[0]);
    const [selectedPackaging, setSelectedPackaging] = useState(packaging[0]);
    const [selectedPaperType, setSelectedPaperType] = useState(paperType[0]);
    const [selectedTopFolding, setSelectedTopFolding] = useState(topFold[0]);
    const [selectedNeedPrint, setSelectedNeedPrint] = useState(topFold[0]);
    const [selectedGripper, setSelectedGripper] = useState(topFold[1]);
    const [selectedTurnaround, setSelectedTurnaround] = useState(turnaround[0]);
    const [customPricePerBag, setCustomPricePerBag] = useState(null);
    const [customPrice, setCustomPrice] = useState(null);
    const [customRollWidth, setCustomRollWidth] = useState(null);
    const [calculationResult, setCalculationResult] = useState({});
    const [bagPrintPrices, setBagPrintPrices] = useState([]);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [selectedTooltip, setSelectedTooltip] = useState(null);
    const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false);
    const [showSignInModal, setShowSignInModall] = useState(false);
    const [refreshCalculateDate, setRefreshCalculateDate] = useState(null);

    const turnaroundDescription = () => {

        return <div className="d-flex flex-column w-100 font-size-12 background-element-color rounded p-1 mt-1">
            {selectedTurnaround.key == Enumerations.turnaround.normal
                ? <span><b>12</b> Business Days</span>
                : <>
                    <span><b>5</b> Business Days</span>
                    <span><span className="red-color fw-bold">30%</span> Extra charge</span>
                </>
            }
        </div>
    }

    const groupNumberInputs = [
        { label: 'product.shoppingBag.gusset', value: gusset, options: gussets, onChanged: (value) => setGusset(value), tooltip: 'common.mm' },
        { label: 'product.shoppingBag.width', value: width, options: widths, onChanged: (value) => setWidth(value), tooltip: 'common.mm' },
        { label: 'product.shoppingBag.height', value: height, options: heights, onChanged: (value) => setHeight(value), tooltip: 'common.mm' },
        { label: 'product.shoppingBag.quantity', value: quantity, options: quantities, onChanged: (value) => setQuantity(value), tooltip: null },
        { label: 'product.shoppingBag.paperThickness', value: paperThickness, options: paperThicknesses, onChanged: (value) => setPaperThickness(value), tooltip: null },
    ];

    const toggleButtons = CommonHelper.clearArrayFromEmptyItem([
        { label: getTranslatedRawText('product.shoppingBag.needPrint'), selectedItemId: selectedNeedPrint.key, items: topFold, onClicked: (value) => setSelectedNeedPrint(value) },
        { label: getTranslatedRawText('product.shoppingBag.gripperMarginPrint'), selectedItemId: selectedGripper.key, items: topFold, onClicked: (value) => setSelectedGripper(value), disabled: selectedNeedPrint.key == 1 },
        { label: getTranslatedRawText('product.shoppingBag.topFold'), selectedItemId: selectedTopFolding.key, items: topFold, onClicked: (value) => setSelectedTopFolding(value) },
        { label: getTranslatedRawText('product.shoppingBag.paperType'), selectedItemId: selectedPaperType.key, items: paperType, onClicked: (value) => setSelectedPaperType(value) },
        { label: getTranslatedRawText('product.shoppingBag.packaging'), selectedItemId: selectedPackaging.key, items: packaging, onClicked: (value) => setSelectedPackaging(value) },
        { label: getTranslatedRawText('product.shoppingBag.turnaround'), selectedItemId: selectedTurnaround.key, items: turnaround, onClicked: (value) => setSelectedTurnaround(value), description: isUsedInAdmin ? null : turnaroundDescription() },
    ]);

    const getBagPrintPricesCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            setBagPrintPrices(resultData);
            calculateClicked(false);
        }
    }

    useEffect(() => {
        GetBagPrintPricesService(getBagPrintPricesCallback);
    }, []);

    useEffect(() => {
        selectedNeedPrint.key == 1 && setSelectedGripper(topFold[1]);
    }, [selectedNeedPrint]);

    useEffect(() => {
        !!refreshCalculateDate && calculateClicked(false);
    }, [refreshCalculateDate]);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            calculateClicked(false);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [customPrice, customPricePerBag, customRollWidth]);

    useEffect(() => {
        let row = getShoppingBagHistoryById(reviewHistoryId);
        if (!isEmpty(row)) {
            setGusset(row.gusset);
            setWidth(row.width);
            setHeight(row.height);
            setQuantity(row.quantity);
            setPaperThickness(row.paperThickness);
            setSelectedNeedPrint(row.selectedNeedPrint);
            setSelectedTopFolding(row.selectedTopFolding);
            setSelectedGripper(row.selectedGripper);
            setSelectedPaperType(row.selectedPaperType);
            setSelectedPackaging(row.selectedPackaging);
            setSelectedTurnaround(row.selectedTurnaround);
            setCustomPricePerBag(row.customPricePerBag);
            setCustomPrice(row.customPrice);
            setRefreshCalculateDate(new Date());
            setCustomRollWidth(row.customRollWidth);
            calculationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [reviewHistoryId]);

    const closeSigninModal = (name) => setShowSignInModall(false);

    const toggleTooltipClicked = (index, isTooltipOpen) => {
        setSelectedTooltip(index);
        setIsTooltipOpen(!isTooltipOpen);
    }

    const mapGroupNumberInput = () => {
        return groupNumberInputs.map((item, index) =>
            <Col md={4} key={index} className="d-flex align-items-end">
                <NsAutoComplete label={getTranslatedRawText(item.label)}
                    className='w-50 me-2'
                    items={item.options}
                    value={item.value}
                    disableClearable={true}
                    onChange={item.onChanged} />
                {false && <NsTooltip key={index} open={selectedTooltip === index && isTooltipOpen}
                    title={getTranslatedRawText('components.captcha.tooltipInfo')}>
                    <NsButton className='secondary-text masterFileModal-tour-preview p-0'
                        startIcon='ns-icon-question'
                        onClick={() => toggleTooltipClicked(index, isTooltipOpen)} />
                </NsTooltip>}
            </Col>
        )
    }

    const mapToggleButtons = () => {
        return toggleButtons.map((item, index) =>
            <div className="pe-4" key={index}>
                <NsToggleGroupButton isShowGroupLabel={true}
                    size='small'
                    label={item.label}
                    items={item.items}
                    disabled={item?.disabled}
                    onClick={item.onClicked}
                    selectedItemId={item.selectedItemId} />
                {false && <NsTooltip key={index} open={selectedTooltip === index + 5 && isTooltipOpen}
                    title={getTranslatedRawText('components.captcha.tooltipInfo')}>
                    <NsButton className='secondary-text masterFileModal-tour-preview p-0'
                        startIcon='ns-icon-question'
                        onClick={() => toggleTooltipClicked(index + 5, isTooltipOpen)} />
                </NsTooltip>}
                {item?.description}
            </div>
        )
    }

    const selectedPriceInqueryValuesDescriptionChanged = () => {
        let message = `I would like to place an order for paper shopping bags. The specifications I require are as follows:%0A
        • Gusset: {0} mm%0A
        • Width: {1} mm%0A
        • Height: {2} mm%0A
        • Quantity: {3} bags%0A
        • Paper Thickness: {4} gsm%0A
        • Need Print: {5}%0A
        • Has Top Fold: {6}%0A
        • Has Gripper Margin Print: {7}%0A
        • Paper Type: {8}%0A
        • Packaging: {9}%0A
        • Turaround: {10}%0A
        `;

        if (isAuthenticated) {
            message = `I'm ` + props.userFullName + `, and ` + message;
        }
        message = CommonHelper.stringFormat(message,
            gusset?.value,
            width?.value,
            height?.value,
            quantity?.value,
            paperThickness?.value,
            selectedNeedPrint?.key == 2 ? 'Yes' : 'No',
            selectedTopFolding?.key == 2 ? 'Yes' : 'No',
            selectedGripper?.key == 2 ? 'Yes' : 'No',
            selectedPaperType?.key == 1 ? 'Virgin' : 'Recycle',
            selectedPackaging?.key == 1 ? 'Shrinked Film' : selectedPackaging?.key == 2 ? 'Carton' : 'Both',
            selectedTurnaround?.key == Enumerations.turnaround.normal ? 'Normal' : 'Express');

        CommonHelper.safeFunctionCall(props.selectedPriceInqueryValuesDescriptionChanged, message);
    }

    const calculateClicked = (isSaveInStorage) => {
        if (!!isSaveInStorage && !isAuthenticated) {
            setShowSignInModall(true);
            return;
        }
        let userInput = {
            dimension: {
                gusset: gusset?.value,
                width: width?.value,
                height: height?.value,
            },
            paperThickness: paperThickness?.value,
            quantity: quantity?.value,
            packagingType: selectedPackaging.key,
            paperType: selectedPaperType.key,
            hasTopFold: selectedTopFolding.key == 2,
            hasGripperMargin: selectedGripper.key == 2,
            turnaround: selectedTurnaround.key,
            printing: {
                printPrices: bagPrintPrices,
                hasPrint: selectedNeedPrint.key == 2,
                customPrice: Number(customPrice)
            },
            making: {
                pricePerBags: shoppingBagsConfig.makePrices,
                customPricePerBag: Number(customPricePerBag)
            },
            paperRoll: {
                widths: shoppingBagsConfig.rollWidths,
                customWidth: Number(customRollWidth)
            },
        }

        selectedPriceInqueryValuesDescriptionChanged();
        const result = priceCalculation(userInput);
        setCalculationResult(result);
        let customMakePricePerBagTemp = !!customPricePerBag ? customPricePerBag : null;
        let customMakePriceTemp = !!customPrice ? customPrice : null;
        let customRollWidthTemp = !!customRollWidth ? customRollWidth : null;
        let id = '' + gusset.id + width.id + height.id + quantity.id + paperThickness.id + selectedNeedPrint.key + selectedTopFolding.key + selectedGripper.key +
            selectedPaperType.key + selectedPackaging.key + selectedTurnaround.key + customRollWidthTemp + customMakePriceTemp + customMakePricePerBagTemp;
        const newHistoryRow = {
            // id: Math.random(),
            id,
            date: new Date(),
            name: null,
            gusset,
            width,
            height,
            quantity,
            paperThickness,
            selectedNeedPrint,
            selectedTopFolding,
            selectedGripper,
            selectedPaperType,
            selectedPackaging,
            selectedTurnaround,
            customRollWidth,
            customPricePerBag,
            customPrice,
            result
        }

        if (isSaveInStorage) {
            saveShoppingBagHistory(newHistoryRow);
            CommonHelper.safeFunctionCall(props.refreshHistories);
        }
    }

    const printPriceChanged = (value) => setCustomPrice(value);
    const makePricePerShoppingBag = (value) => setCustomPricePerBag(value);
    const rollWidthChanged = (value) => setCustomRollWidth(value);
    const toggleHistoryClicked = () => CommonHelper.safeFunctionCall(props.toggleHistoryClicked, true);
    const processOrderClicked = () => setShowConfirmOrderModal(!showConfirmOrderModal);

    const delayedPrintPriceChanged = new DelayedTaskHelper(printPriceChanged, 2000);
    const delayedMakePricePerShoppingBag = new DelayedTaskHelper(makePricePerShoppingBag, 2000);
    const delayedRollWidthChanged = new DelayedTaskHelper(rollWidthChanged, 2000);


    return (
        <div className="d-flex flex-column">
            <Row className="g-2 mb-2">
                {mapGroupNumberInput()}
            </Row>
            <div className="d-flex flex-wrap gap-3 gap-md-4 mb-4">
                {mapToggleButtons()}
            </div>
            <div className={clsx("d-flex gap-3 mb-2", CommonHelper.isMobileDevice() ? 'flex-column' : '')}>
                <NsButton label='product.shoppingBag.calculate'
                    className='primary'
                    disabled={isEmpty(bagPrintPrices)}
                    startIcon='ns-icon-calculate-customize'
                    onClick={() => calculateClicked(true)} />
                {!isUsedInAdmin &&
                    <>
                        <NsButton
                            className='secondary-text fs-4'
                            disabled={isEmpty(bagPrintPrices)}
                            startIcon='ns-icon-history'
                            onClick={toggleHistoryClicked} />
                        <NsButton label='product.shoppingBag.orderProcess'
                            className='secondary-outline'
                            disabled={isEmpty(bagPrintPrices)}
                            startIcon='ns-icon-start-ordering'
                            onClick={processOrderClicked} />
                    </>
                }
            </div>
            <div ref={calculationRef}>
                {!isEmpty(calculationResult) &&
                    <ShoppingBagMainResultCalculation isAuthenticated={isAuthenticated}
                        calculationResult={calculationResult}
                        customPrice={customPrice}
                        customPricePerBag={customPricePerBag}
                        customRollWidth={customRollWidth}
                        printPriceChanged={(value) => delayedPrintPriceChanged.run(value)}
                        makePricePerShoppingBag={(value) => delayedMakePricePerShoppingBag.run(value)}
                        rollWidthChanged={(value) => delayedRollWidthChanged.run(value)} />
                }
            </div>
            {showConfirmOrderModal &&
                <ShoppingBagSubmitOrderModal closeModal={processOrderClicked}
                    selectedPriceInqueryValuesDescription={props.selectedPriceInqueryValuesDescription} />
            }
            {showSignInModal &&
                <SignInModal closeModal={closeSigninModal} />
            }
        </div>
    );
}

const createOptions = (item) => {
    let list = [];
    for (let index = 0, value = item.min; value <= item.max; index++, value += item.step)
        list.push({ id: index, value })
    return list;
}

export default React.memo(ShoppingBagMainPriceInquery);