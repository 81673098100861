import React from "react";

import { GetCaptchaService } from "../../services/accounts/AccountService";
import { getTranslatedRawText, isEnCulture } from "../../utilities/CultureHelper";
import { isEmpty } from "../../utilities/ValidationHelper";
import CommonHelper from "../../utilities/CommonHelper";
import Enumerations from "../../configs/Enumerations";
import NsSkeleton from "../skeletons/NsSkeleton";
import NsTextField from "../inputs/NsTextField";
import NsButton from "../buttons/NsButton";
import NsTooltip from "../tooltips/NsTooltip";

class NsCaptcha extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recaptchaUrl: null,
            captchaId: null,
            isLoading: false,
            recaptchaText: '',
            captchaMessage: props.captchaMessage,
            enableCaseSensitive: false,
            isTooltipOpen: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.getRecaptcha();

    componentDidUpdate = (prevProps, prevState) => {
        if (!!this.props.refreshCaptchaDate && prevProps.refreshCaptchaDate !== this.props.refreshCaptchaDate) {
            this.getRecaptcha();
            this.setState({ captchaMessage: this.props.captchaMessage });
        }
    }

    getRecaptcha = () => {
        this.setState({ isLoading: true });
        GetCaptchaService(this.getRecaptchaCallback);
    }

    getRecaptchaCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (result.data.hasError)
            this.setState({ resultMessageClass });
        else {
            this.setState({
                recaptchaUrl: resultData.captchaCode,
                captchaId: resultData.captchaId,
                recaptchaText: '',
                enableCaseSensitive: resultData?.enableCaseSensitive,
                message: result.me
            });
            CommonHelper.safeFunctionCall(this.props.recaptchaChanged, null, false, resultData.captchaId);
        }
    }

    valueChanged = (value, isValid) => {
        this.setState({ recaptchaText: value, isValidRecaptcha: isValid });
        CommonHelper.safeFunctionCall(this.props.recaptchaChanged, value, isValid, this.state.captchaId);
    }

    refreshRecaptchaClicked = () => {
        this.setState({ recaptchaText: '', captchaMessage: null });
        this.getRecaptcha();
    }

    toggleTooltipClicked = () => this.setState({ isTooltipOpen: !this.state.isTooltipOpen });

    render() {
        const { recaptchaUrl, recaptchaText, isLoading, resultMessageClass, enableCaseSensitive, captchaMessage, isTooltipOpen } = this.state;
        const { showValidationDateCaptcha } = this.props;

        return <>
            <div className="d-flex flex-column align-items-center">
                <div className="d-flex justify-content-center align-items-end px-2 not-rtl">
                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            className='w-100'
                            variant={[
                                Enumerations.skeleton.text,
                            ]} />
                        : <img src={recaptchaUrl}
                            className="border"
                            alt={getTranslatedRawText("components.imagesAlt.captchaImage")} />
                    }
                    <div className="d-flex flex-column">
                        <NsTooltip open={isTooltipOpen}
                            title={getTranslatedRawText('components.captcha.tooltipInfo')}
                            closeTooltip={() => this.setState({ isTooltipOpen: false })}>
                            <NsButton className='secondary-text masterFileModal-tour-preview p-0'
                                startIcon='ns-icon-question'
                                onClick={this.toggleTooltipClicked} />
                        </NsTooltip>                    <NsButton className='text py-0'
                            wrapperClassName='d-flex align-items-end'
                            startIcon='ns-icon-retry'
                            loading={isLoading}
                            onClick={this.refreshRecaptchaClicked} />
                    </div>
                </div>
                <div className="my-2 red-color font-size-12">
                    {enableCaseSensitive && getTranslatedRawText('components.captcha.caseSensitive')}
                </div>
                <NsTextField label={getTranslatedRawText('components.captcha.typeLetters')}
                    className='mb-0 pb-0 w-100'
                    value={recaptchaText}
                    showValidationDate={showValidationDateCaptcha}
                    valueChanged={this.valueChanged} />
                {!isEmpty(captchaMessage) &&
                    <div className={`my-2 w-100 red-color font-size-12`}>
                        {captchaMessage}
                    </div>
                }
                {resultMessageClass}
            </div>
        </>
    }
}

export default NsCaptcha;