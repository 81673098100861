import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getApiUrlWithCurrentCulture } from '../bases/GetApiUrlWithCurrentCulture';

const GetProductsService = (parameters, callback) => {
    GetRequest(getApiUrlWithCurrentCulture(Links.api.product, Links.api.product.getProductList), parameters, callback);
}

const GetProductInfoService = (id, callback) => {
    let parameters = { id: CommonHelper.toInt(id), title: 'is not valid now' };
    GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getProductInfo), parameters, callback);
}

const GetPrintingProcessTimeService = (id, callback) => {
    return GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getPrintingProcessTime), id, callback);
}

const GetProductNameListService = (categoryId, callback) => {
    let parameters = { CategoryId: categoryId };
    GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getProductNameList), parameters, callback);
}

const GetProductsCirculationsService = (categoryId, callback) => {
    let parameters = { CategoryId: categoryId };
    GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getProductsCirculations), parameters, callback);
}

const GetPrintingFeatureService = (id, callback) => {
    let parameters = { Id: CommonHelper.toInt(id) };
    GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getPrintingFeature), parameters, callback);
}

const GetProductSpecialDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getProductSpecialDetail), parameters, callback);
}

const GetProductCategoryInfoService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getProductCategoryInfo), parameters, callback);
}

const GetProductDetailService = (pprName, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getProductDetail), { pprName }, callback);
}

const GetOPreparationEstimatedTimeSheetsBySelectedConfirmTimeService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getOPreparationEstimatedTimeSheetsBySelectedConfirmTime), parameters, callback);
}

const GetDecreasedProductPricesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.product, Links.api.product.getDecreasedProductPrices), {}, callback);
}

export {
    GetProductsService,
    GetProductDetailService,
    GetProductInfoService,
    GetProductNameListService,
    GetProductsCirculationsService,
    GetPrintingFeatureService,
    GetProductSpecialDetailService,
    GetPrintingProcessTimeService,
    GetProductCategoryInfoService,
    GetOPreparationEstimatedTimeSheetsBySelectedConfirmTimeService,
    GetDecreasedProductPricesService
}