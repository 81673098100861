import React from 'react';
import NsDataGrid from '../../../../../../components/grids/NsDataGrid';
import Enumerations from '../../../../../../configs/Enumerations';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import UpBaseGroupSheetMobileRedirectToAllOrders from '../bases/UpBaseGroupSheetMobileRedirectToAllOrders';
import { isBusinessPartner } from '../../../../../../utilities/ClaimHelper';
import GridEnumerations from '../../../../../../configs/GridEnumerations';

class UpGroupSheetCartOrders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    field: 'Action',
                    headerName: getTranslatedRawText('profile.dataGridColumns.action'),
                    width: 110,
                    lockPosition: true,
                    resizable: false,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        hasDetail: true,
                        detailClicked: (id) => CommonHelper.safeFunctionCall(this.props.detailViewClicked, id),
                        hasDelete: true,
                        deleteClicked: (id) => CommonHelper.safeFunctionCall(this.props.deleteRowClicked, id)
                    },
                },
                {
                    field: 'productName',
                    headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
                    width: 130,
                },
                {
                    field: 'createDate',
                    headerName: getTranslatedRawText('profile.dataGridColumns.submitDate'),
                    dataType: Enumerations.gridType.dateTime,
                    width: 115,
                    maxWidth: 130,
                },
                {
                    field: 'twoSidePrintingType',
                    headerName: getTranslatedRawText('profile.dataGridColumns.printedSide'),
                    dataType: Enumerations.gridType.printSide,
                    width: 120,
                },
                {
                    field: 'turnaroundType',
                    headerName: getTranslatedRawText('profile.dataGridColumns.turnaround'),
                    dataType: Enumerations.gridType.turnaround,
                    width: 120,

                },
                {
                    field: 'circulation',
                    headerName: getTranslatedRawText('profile.dataGridColumns.circulation'),
                    dataType: Enumerations.gridType.circulation,
                    width: 110
                },
                {
                    field: 'totalQuantity',
                    headerName: getTranslatedRawText('profile.dataGridColumns.totalQuantity'),
                    dataType: Enumerations.gridType.number,
                    width: 130,
                },
                {
                    field: 'totalPrice',
                    headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat'),
                    width: 150,
                    dataType: Enumerations.gridType.price,
                },
                {
                    field: 'totalPriceIncludeVat',
                    headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithVat'),
                    width: 150,
                    dataType: Enumerations.gridType.price,
                },
                {
                    field: 'agencyName',
                    headerName: getTranslatedRawText('profile.dataGridColumns.agencyName'),
                    width: 130,
                },
                isBusinessPartner() &&
                {
                    field: 'businessPartnerCustomerIdentifier',
                    headerName: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
                    width: 160,
                },
            ],
        }
    }

    render() {
        return (
            <div className='w-100'>
                <NsDataGrid totalRows={this.props.totalRows}
                    rowPerPage={this.props.rowPerPage}
                    isLoading={this.props.isLoading}
                    columnDefs={this.state.columns}
                    rows={this.props.rows}
                    currentPage={this.props.currentPage}
                    paginationMode="server"
                    selectedRowIds={this.props.selectedRowIds}
                    handlePagination={this.props.handlePagination}
                    handleRowPerPage={this.props.handleRowPerPage}
                    hasCheckbox={true}
                    gridId={GridEnumerations.siteGrids.cartGroupSheetOrders}
                    hasCustomColumnOrder={true}
                    selectedRows={this.props.selectedRows} />
                <UpBaseGroupSheetMobileRedirectToAllOrders />
            </div>
        );
    }
};

export default UpGroupSheetCartOrders;