import React, { useState } from "react";

import NsAdminPanelContentWrapper from "../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import Enumerations from "../../../../configs/Enumerations";
import { GetApUserDepartmentService } from "../../../../services/adminPanel/AdminDepartmentService";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import ApDepartmentModal from "./ApDepartmentModal";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";



const ApDepartments = () => {

    const [callServiceDate, setCallServiceDate] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [ShowDepartmentDetailModal, setShowDepartmentDetailModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [messageClassModal, setMessageClassModal] = useState(<></>)

    const getData = (rows, isLoading) => {
        setRows(rows);
        setIsLoading(isLoading);
    }

    const columns = [
        hasAccess([UserClaimEnumerations.getApUserDepartmentById]) && {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 100,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: true,
                editClicked: (id) => departmentModalClicked(id)
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 300
        },
        {
            field: 'fullParentTitle',
            headerName: getTranslatedRawText('adminPanel.department.parentDepartment'),
            width: 200,
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 90,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
            width: 160,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 160
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 160,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 160
        },
    ];

    const departmentModalClicked = (selected) => {
        setSelectedRowId(selected);
        setShowDepartmentDetailModal(true);
    }

    const closeModalClicked = (refreshData, messageClassModal) => {
        setSelectedRowId(null);
        setShowDepartmentDetailModal(false);
        !!refreshData && setCallServiceDate(new Date());
        !!messageClassModal && setMessageClassModal(messageClassModal);
    }

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText('adminPanel.sidebar.departments')}
            actionButton={{
                onClick: () => departmentModalClicked(null, true),
                disabled: !hasAccess([UserClaimEnumerations.postApUserDepartment]) || !hasAccess([UserClaimEnumerations.getApDepartmentsTree]),
                isLoading: isLoading,
            }}
            icon='ns-icon-agreements h1'>

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetApUserDepartmentService}
                parameters={{}}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {ShowDepartmentDetailModal &&
                <ApDepartmentModal showModal={true}
                    closeModal={closeModalClicked}
                    departmentId={selectedRowId || null} />
            }
            {messageClassModal}
        </NsAdminPanelContentWrapper>
    )
};

export default React.memo(ApDepartments);