import { getTranslatedRawText, isEnCulture } from "../../../../../utilities/CultureHelper";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import NsButton from "../../../../../components/buttons/NsButton";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import Enumerations from "../../../../../configs/Enumerations";

const notRtlClass = `${!!isEnCulture() && 'text-end not-rtl'}`;

export const getApCustomerColumn = (selectUserClicked, customerDetailClicked, isUserSelection) => [
    (isUserSelection || hasAccess([UserClaimEnumerations.getApCustomerById])) &&
    {
        field: 'action', headerName: getTranslatedRawText('adminPanel.common.action'),
        cellRenderer: "customRowRenderer",
        width: 80,
        cellRendererParams: {
            customElementRender: (user) => {
                return <NsButton className='secondary-text '
                    startIcon={isUserSelection ? 'ns-icon-confirm' : 'ns-icon-invoice-details'}
                    onClick={isUserSelection ? () => selectUserClicked(user) : () => customerDetailClicked(user.userId)} />
            }
        }
    },
    {
        field: 'fullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 175,
    },
    {
        field: 'phoneNumber',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
        dataType: Enumerations.gridType.number,
        width: 150,
    },
    {
        field: 'companyName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.companyName'),
        width: 180,
    },
    {
        field: 'nationalCode',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.idNumber'),
        dataType: Enumerations.gridType.number,
        width: 125,
    },
    {
        field: 'accountStatus', headerName: getTranslatedRawText('adminPanel.dataGridColumn.accountStatus'),
        cellRenderer: "customRowRenderer",
        width: 120,
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className={props.accountStatus ? 'success-color' : 'red-color'}>
                    {props.accountStatus
                        ? getTranslatedRawText('adminPanel.usersInfo.active')
                        : getTranslatedRawText('adminPanel.usersInfo.block')}
                </div>
            }
        }
    },
    {
        field: 'agreementStatus', headerName: getTranslatedRawText('components.advanceSearch.agreementStatus'),
        cellRenderer: "customRowRenderer",
        width: 130,
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className={props.agreementStatus ? 'success-color' : 'red-color'}>
                    {props.agreementStatus
                        ? getTranslatedRawText('components.advanceSearch.agree')
                        : getTranslatedRawText('components.advanceSearch.disAgree')}
                </div>
            }
        }
    },
    {
        field: 'accountType', headerName: getTranslatedRawText('adminPanel.dataGridColumn.accountType'),
        cellRenderer: "customRowRenderer",
        width: 100,
        cellRendererParams: {
            customElementRender: (props) => {
                switch (props.accountType) {
                    case Enumerations.accountType.personal:
                        return getTranslatedRawText('adminPanel.usersInfo.personal');
                    case Enumerations.accountType.business:
                        return getTranslatedRawText('adminPanel.usersInfo.business');
                    default:
                        return;
                }
            }
        }
    },
    {
        field: 'roles',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.role'),
        width: 90,
    },
    {
        field: 'country',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.country'),
        width: 100,
    },
    {
        field: 'registredTime',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.registrationTime'),
        dataType: Enumerations.gridType.dateTime,
        width: 140,
    },
    {
        field: 'detailLedgerId',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.detailLedger'),
        dataType: Enumerations.gridType.number,
        width: 125,
    },
    {
        field: 'agencyName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.agencyName'),
        width: 140,
    },
];

export const getApSupporterColumn = (adminSelected, supporterDetailClicked, isAdminSelection) => [
    {
        field: 'action', headerName: getTranslatedRawText('adminPanel.common.action'),
        cellRenderer: "customRowRenderer",
        width: 80,
        cellRendererParams: {
            customElementRender: (admin) => {
                return <NsButton className='secondary-text '
                    startIcon={isAdminSelection ? 'ns-icon-confirm' : 'ns-icon-invoice-details'}
                    onClick={isAdminSelection ? () => adminSelected(admin) : () => supporterDetailClicked(admin)} />
            }
        }
    },
    {
        field: 'fullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 150,
    },
    {
        field: 'phoneNumber',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
        dataType: Enumerations.gridType.number,
        width: 150,
    },
{
    field: 'nationalCode',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.idNumber'),
        width: 140,
            dataType: Enumerations.gridType.number,
    },
{
    field: 'accountStatus', headerName: getTranslatedRawText('adminPanel.dataGridColumn.accountStatus'),
        cellRenderer: "customRowRenderer",
        width: 125,
            cellRendererParams: {
        customElementRender: (props) => {
            return <div className={props.accountStatus ? 'success-color' : 'red-color'}>
                {props.accountStatus
                    ? getTranslatedRawText('adminPanel.usersInfo.active')
                    : getTranslatedRawText('adminPanel.usersInfo.block')}
            </div>
        }
    }
},
{
    field: 'roles',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.role'),
        width: 250,
},
{
    field: 'registredTime',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.registrationTime'),
            dataType: Enumerations.gridType.dateTime,
            width: 135,
},
{
    field: 'detailLedgerId',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.detailLedger'),
            dataType: Enumerations.gridType.number,
            width: 130,
    },
];

export const getApBussinessPartnerColumn = (detailViewClicked) => [
    (hasAccess([UserClaimEnumerations.getApCustomerById])) && {
        field: 'action', headerName: getTranslatedRawText('adminPanel.common.action'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (user) => {
                return <NsButton className='secondary-text '
                    startIcon={'ns-icon-invoice-details'}
                    onClick={() => detailViewClicked(user.userId)} />
            }
        }
    },
    {
        field: 'fullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
    },
    {
        field: 'phoneNumber',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'companyName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.companyName'),
    },
    {
        field: 'nationalCode',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.idNumber'),
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'accountStatus', headerName: getTranslatedRawText('adminPanel.dataGridColumn.accountStatus'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className={props.accountStatus ? 'success-color' : 'red-color'}>{props.accountStatus ? getTranslatedRawText('adminPanel.usersInfo.active') : getTranslatedRawText('adminPanel.usersInfo.block')}</div>
            }
        }
    },
    {
        field: 'accountType', headerName: getTranslatedRawText('adminPanel.dataGridColumn.accountType'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                switch (props.accountType) {
                    case Enumerations.accountType.personal:
                        return getTranslatedRawText('adminPanel.usersInfo.personal');
                    case Enumerations.accountType.business:
                        return getTranslatedRawText('adminPanel.usersInfo.business');
                    default:
                        return;
                }
            }
        }
    },
    {
        field: 'country',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.country'),
    },
    {
        field: 'detailLedgerId',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.detailLedger'),
        dataType: Enumerations.gridType.number,
    },

];