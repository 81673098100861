import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import ImageHelper from '../../../utilities/ImageHelper';
import Enumerations from '../../../configs/Enumerations';
import NsButton from '../../../components/buttons/NsButton';
import NsPunchArea from '../../../components/punchArea/NsPunchArea';
import NsAlertModal from '../../../components/modals/NsAlertModal';
import { isEmpty } from '../../../utilities/ValidationHelper';
import ConstantsWithTranslation from '../../../configs/ConstantsWithTranslation';
import NsMessageClassModal from '../../../components/modals/NsMessageClassModal';
import NsTypography from '../../../components/text/NsTypography';
import OBaseModalItemList from './bases/OBaseModalItemList';
import OBaseModalNumberDigits from './bases/OBaseModalNumberDigits';
import NsToggleGroupButton from '../../../components/buttons/NsToggleGroupButton';
import NsSelect from '../../../components/dropdowns/NsSelect';
import OrderHelper from '../../../utilities/OrderHelper';
import RegularOrderTours from '../../bases/tours/RegularOrderTours';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsTour from '../../../components/tour/NsTour';
import Config from '../../../configs/Config';

class AddSerialModal extends React.Component {

    constructor(props) {
        super(props);
        this.tourSteps = RegularOrderTours.getSerialModalTour();
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.serialNumberModal);
        this.createMemoizeMainButtons = CommonHelper.memoizeFunc(this.createMainButtons);
        this.maxSerials = props.hasExtraSerialNumber ? 2 : 1;
        this.serialDirections = ConstantsWithTranslation.foldLineDirections();
        this.series = OrderHelper.getSeries(props.routeId);
        this.state = {
            masterFileInfo: props.masterFileInfo,
            productDesignTemplateUrl: props.productDesignTemplateUrl,
            serials: [],
            selectedSerialLanguageType: props.selectedSerialLanguageType,
            billBookNumberInSeriesType: props.billBookNumberInSeriesType || Enumerations.billBookNumberInSeriesTypes.restartAtStartNumber,
            serialBoxDimension: props.serialBoxDimension,
            isSerialDirectionVertical: false,
            ratio: null,
            isChangedPosition: false,
            isAlertModalOpen: false,
            messageModalText: null,
            isShowErrorModal: false,
            hasDuplicatedSerials: false,
            serialStartFrom: props.serialStartFrom || null,
            completeSerialNumber: null,
            clearSerialStartFromTimestamp: null,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.serials !== prevState.serials && isEmpty(this.state.serials)) {
            this.setState({
                serialStartFrom: null,
                clearSerialStartFromTimestamp: new Date()
            })
        }
    }

    toggleSerialDirectionClicked = () => {
        const { serials } = this.state;
        if (!isEmpty(serials)) {
            this.setState({
                messageModalText: 'common.messages.serialNumberDirectionError',
                isShowErrorModal: false
            });
            return;
        }
        this.setState(prevState => ({
            isSerialDirectionVertical: !prevState.isSerialDirectionVertical,
            serialBoxDimension: { width: prevState.serialBoxDimension.height, height: prevState.serialBoxDimension.width },
        }));
    }

    languageChanged = (value, isValid) => this.setState({ selectedSerialLanguageType: value });

    numberingTypeChanged = (value, isValid) => this.setState({ billBookNumberInSeriesType: value });

    deleteClicked = (deletedPunchIndex) => {
        this.setState(prevState => ({
            serials: prevState.serials.filter((serial, index) => index !== deletedPunchIndex)
        }), () => this.getOverlappingSerials());
    }

    addSerialClicked = () => {
        const { masterFileInfo, ratio } = this.state;
        let minPunchDistanceFromEdge = ImageHelper.convertMillimeterToPixel(Config.order.minSerialDistanceFromEdge + 1, masterFileInfo.resolution) * ratio;
        this.setSerialPosition(minPunchDistanceFromEdge, minPunchDistanceFromEdge);
    }

    closeClicked = (e) => {
        this.state.isChangedPosition
            ? this.setState({ isAlertModalOpen: true })
            : CommonHelper.safeFunctionCall(this.props.closeModal);
    }

    closeMessageModalClicked = () => {
        this.setState({
            messageModalText: null,
            isShowErrorModal: false
        });
    }

    closeAlertClicked = (confirm) => {
        this.setState({ isAlertModalOpen: false });
        !!confirm && CommonHelper.safeFunctionCall(this.props.closeModal);
    }

    clearSerialClicked = () => {
        this.setState({
            serials: [],
            serialStartFrom: null,
            hideNotification: true,
            completeSerialNumber: null,
            billBookNumberInSeriesType: Enumerations.billBookNumberInSeriesTypes.restartAtStartNumber
        })
    }

    saveClicked = () => {
        const { serials, serialStartFrom, hasDuplicatedSerials, completeSerialNumber } = this.state;

        if (((isEmpty(completeSerialNumber) ? isEmpty(serialStartFrom) : completeSerialNumber?.split('-').every((num) => num === ''))
            && isEmpty(serials)) || (!isEmpty(serialStartFrom) && !isEmpty(serials))) {
            let resultMessageClass = !isEmpty(serials) && serials.some(serial => {
                if (this.checkAuthorizedArea(serial?.verticalPoint, serial?.horizontalPoint))
                    return true;
                return false;
            });
            if (!!resultMessageClass || hasDuplicatedSerials)
                this.setState({
                    messageModalText: hasDuplicatedSerials ? "common.messages.duplicatedSerial" : "common.messages.notAuthorizedAreaText",
                    isShowErrorModal: false
                });
            else {
                let selectedSerialLanguageType = this.state.selectedSerialLanguageType ?? this.props.billBookNumberLanguageType;
                let billBookNumberInSeriesType = this.state.billBookNumberInSeriesType ?? this.props.billBookNumberInSeriesType;
                CommonHelper.safeFunctionCall(this.props.saveModalClicked, serials, serialStartFrom, selectedSerialLanguageType, billBookNumberInSeriesType);
            }
        }

        if (isEmpty(completeSerialNumber) ? isEmpty(serialStartFrom) : completeSerialNumber?.split('-').pop() === '') {
            this.setState({
                messageModalText: 'regularOrder.billBook.noSerialStartFrom',
                isShowErrorModal: true,
                hideNotification: false,
            })
        }

        if (isEmpty(serials) && !isEmpty(serialStartFrom)) {
            this.setState({
                messageModalText: 'regularOrder.billBook.noSerialSelected',
                isShowErrorModal: true
            })
        }

    }

    setRatio = (ratio) => {
        const { masterFileInfo } = this.state;
        const { selectedSerials } = this.props;
        let serials = !isEmpty(selectedSerials)
            ? selectedSerials.map(serial => ({
                ...serial,
                top: ImageHelper.convertMillimeterToPixel(serial?.verticalPoint, masterFileInfo?.resolution) * ratio,
                left: ImageHelper.convertMillimeterToPixel(serial?.horizontalPoint, masterFileInfo?.resolution) * ratio,
                isSelected: false,
            }))
            : [];
        this.setState({
            serialBoxDimension: {
                width: !!serials[0]?.isVertical
                    ? ImageHelper.convertMillimeterToPixel(this.state.serialBoxDimension.height, masterFileInfo?.resolution) * ratio
                    : ImageHelper.convertMillimeterToPixel(this.state.serialBoxDimension.width, masterFileInfo?.resolution) * ratio,
                height: !!serials[0]?.isVertical
                    ? ImageHelper.convertMillimeterToPixel(this.state.serialBoxDimension.width, masterFileInfo?.resolution) * ratio
                    : ImageHelper.convertMillimeterToPixel(this.state.serialBoxDimension.height, masterFileInfo?.resolution) * ratio
            },
            isSerialDirectionVertical: !!serials[0]?.isVertical,
            serials,
            ratio
        });
    }

    convertToMillimeter = (position) => {
        const { masterFileInfo, ratio } = this.state;
        return ImageHelper.convertPixelToMillimeter(position / ratio, masterFileInfo?.resolution);
    }

    convertToPixel = (position) => {
        const { masterFileInfo, ratio } = this.state;
        return ImageHelper.convertMillimeterToPixel(position * ratio, masterFileInfo?.resolution);
    }

    checkAuthorizedArea = (top, left) => {
        const { masterFileInfo, serialBoxDimension, ratio } = this.state;
        let trimZone = { ...this.props.trimZone };
        if (!trimZone) {
            trimZone = {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            };
        }
        const minSerialDistanceFromEdge = Math.max(trimZone.left, Config.order.minSerialDistanceFromEdge);
        if (top <= minSerialDistanceFromEdge || top > masterFileInfo.height - minSerialDistanceFromEdge - this.convertToMillimeter(serialBoxDimension.height) ||
            left <= minSerialDistanceFromEdge || left > masterFileInfo.width - minSerialDistanceFromEdge - this.convertToMillimeter(serialBoxDimension.width))
            return true;

        return false;
    }

    getOverlappingSerials = () => {
        const { serials, serialBoxDimension } = this.state;
        let hasDuplicatedSerials = false;
        for (let i = 0; i < serials.length; i++) {
            for (let j = 0; j < serials.length; j++) {
                if (serials[i].left < serials[j].left + serialBoxDimension.width &&
                    serials[i].left + serialBoxDimension.width > serials[j].left &&
                    serials[i].top < serials[j].top + serialBoxDimension.height &&
                    serials[i].top + serialBoxDimension.height > serials[j].top)
                    if (j !== i) {
                        hasDuplicatedSerials = true;
                        break;
                    }
            }
        }
        this.setState({ hasDuplicatedSerials });
        return hasDuplicatedSerials;
    }

    checkIsOutOfImage = (left, top) => {
        const { masterFileInfo, serialBoxDimension } = this.state;
        if (left + serialBoxDimension.width > this.convertToPixel(masterFileInfo.width) ||
            top + serialBoxDimension.height > this.convertToPixel(masterFileInfo.height))
            return true;
        return false;
    }

    changeTopSerialPositionClicked = (verticalPoint, selectedIndex) => {
        let top = this.convertToPixel(verticalPoint)
        this.setState(prevState => {
            let serials = prevState.serials.map((serial, index) => index === selectedIndex && !this.checkIsOutOfImage(serial.left, top)
                ? { ...serial, top, verticalPoint, isSelected: index === selectedIndex }
                : serial);
            return { serials, isChangedPosition: true }
        }, () => this.getOverlappingSerials());
    }

    changeLeftSerialPositionClicked = (horizontalPoint, selectedIndex) => {
        let left = this.convertToPixel(horizontalPoint)
        this.setState(prevState => {
            let serials = prevState.serials.map((serial, index) => index === selectedIndex && !this.checkIsOutOfImage(left, serial.top)
                ? { ...serial, left, horizontalPoint, isSelected: index === selectedIndex }
                : serial);
            return { serials, isChangedPosition: true }
        }, () => this.getOverlappingSerials());
    }


    setSerialPosition = (left, top) => {
        let serials = [...this.state.serials];
        serials.map(serial => ({ ...serial, isSelected: false }));
        if (this.checkIsOutOfImage(left, top)) return;
        serials?.length < this.maxSerials
            ? serials.push({ top, left, verticalPoint: this.convertToMillimeter(top).toFixed(2), horizontalPoint: this.convertToMillimeter(left).toFixed(2), isSelected: true, isVertical: this.state.isSerialDirectionVertical, copyNumberOptions: createChechboxOptions(this.props.billBookCopy) })
            : serials[serials.length - 1] = { top, left, verticalPoint: this.convertToMillimeter(top).toFixed(2), horizontalPoint: this.convertToMillimeter(left).toFixed(2), isSelected: true, isVertical: this.state.isSerialDirectionVertical, copyNumberOptions: serials[serials.length - 1].copyNumberOptions };
        this.setState({ serials, isChangedPosition: true }, () => this.getOverlappingSerials());
    }

    digitPadClicked = (serialStartFrom, completeSerialNumber) => {
        this.setState({
            serialStartFrom,
            completeSerialNumber,
            hideNotification: false
        });
    }

    createMainButtons = (serials, serialStartFrom, selectedSerialLanguageType) => {
        return [
            CommonHelper.createStickyButtonItem("common.save", 'ns-icon-save', this.saveClicked, 'primary'),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.closeClicked, 'primary-outline')
        ];
    }

    render() {
        const { masterFileInfo, serials, serialBoxDimension, productDesignTemplateUrl, isAlertModalOpen, isShowErrorModal, hideNotification,
            isSerialDirectionVertical, messageModalText, serialStartFrom, billBookNumberInSeriesType, selectedSerialLanguageType, hasDuplicatedSerials, clearSerialStartFromTimestamp } = this.state;
        const { billBookNumberLanguageType, routeId } = this.props;
        const serialEndTo = OrderHelper.calculateSerialEndTo(serialStartFrom, billBookNumberInSeriesType, routeId);

        return (
            <NsModal showModal={this.props.showModal}
                maxWidth="md"
                primaryActionButton={this.saveClicked}
                closeModal={this.closeClicked}
                dialogClassName="modal-choose-printing-files"
                dialogTitle={getTranslatedRawText('regularOrder.billBook.addSerial')}
                actions={<NsStickyButton stickyButtons={this.createMemoizeMainButtons(serials, serialStartFrom, selectedSerialLanguageType)} />}>

                <Row className='justify-content-center'>
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 7, order: 1 }} className='position-relative'>
                        <NsPunchArea onClick={this.imageClicked}
                            imgSrc={masterFileInfo?.draft}
                            masterFileInfo={masterFileInfo}
                            trimZone={this.props.trimZone}
                            isSerrialArea={true}
                            selectedSerials={serials}
                            serialBoxDimension={serialBoxDimension}
                            productDesignTemplateUrl={productDesignTemplateUrl}
                            setItemPosition={this.setSerialPosition}
                            setRatio={this.setRatio} />
                    </Col>

                    <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }} className='mb-2'>
                        <div className='border p-2 extendedService__tour__serial-modal'>
                            <div className='d-flex justify-content-end mb-2 '>
                                <div className='group-button__direction me-auto'>
                                    <NsToggleGroupButton isShowGroupLabel={true}
                                        label={getTranslatedRawText('regularOrder.billBook.serialNumberDirection')}
                                        onClick={this.toggleSerialDirectionClicked}
                                        selectedItemId={isSerialDirectionVertical ? Enumerations.foldLineDirections.vertical : Enumerations.foldLineDirections.horizontal}
                                        items={this.serialDirections} />
                                </div>
                            </div>
                            <div className='d-flex gap-2 mb-3'>
                                {billBookNumberLanguageType === Enumerations.billBookNumberLanguageType.persianAndEnglish &&
                                    <NsSelect label={getTranslatedRawText('regularOrder.billBook.billBookLanguageType')}
                                        valueChanged={this.languageChanged}
                                        options={ConstantsWithTranslation.getBillBookLanguageType()}
                                        selectedItem={selectedSerialLanguageType} />
                                }
                                {this.series > 1 || false &&
                                    <NsSelect label={getTranslatedRawText('regularOrder.billBook.nextSeriesMode')}
                                        valueChanged={this.numberingTypeChanged}
                                        options={ConstantsWithTranslation.getBillBookNumberInSeriesTypes()}
                                        selectedItem={billBookNumberInSeriesType} />
                                }
                            </div>
                            <div className='mb-2'>
                                <NsTypography variant='inherit' className='font-size-12 mt-3 mb-2'>
                                    {getTranslatedRawText('regularOrder.billBook.serialNumberStartedFromTitle')}
                                </NsTypography>
                                <OBaseModalNumberDigits serialDigits={serialStartFrom}
                                    hideNotification={hideNotification}
                                    clearSerialStartFromTimestamp={clearSerialStartFromTimestamp}
                                    digitPadClicked={this.digitPadClicked} />

                                {serialStartFrom &&
                                    <NsTypography variant='inherit' className='font-size-14'>
                                        {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.billBook.serialNumberStartedFromTo'), serialStartFrom, serialEndTo)}
                                    </NsTypography>
                                }
                            </div>
                            <div className='d-flex align-items-center justify-content-end w-full'>
                                <NsButton label='regularOrder.common.add'
                                    size='small'
                                    disabled={serials.length >= this.maxSerials}
                                    className='secondary'
                                    startIcon="ns-icon-add"
                                    onClick={this.addSerialClicked} />
                                <NsButton label='common.clear'
                                    size='small'
                                    wrapperClassName='ms-2'
                                    className='secondary-outline w-auto text-nowrap'
                                    startIcon="ns-icon-clear"
                                    onClick={this.clearSerialClicked} />
                            </div>
                        </div>
                        <OBaseModalItemList items={serials}
                            checkAuthorizedArea={this.checkAuthorizedArea}
                            hasDuplicatedSerials={hasDuplicatedSerials}
                            masterFileDimention={{ width: masterFileInfo?.width, height: masterFileInfo.height }}
                            changeTopItemPositionClicked={this.changeTopSerialPositionClicked}
                            changeLeftItemPositionClicked={this.changeLeftSerialPositionClicked}
                            deleteClicked={this.deleteClicked} />

                    </Col>
                </Row>

                {
                    isAlertModalOpen &&
                    <NsAlertModal isOpen={isAlertModalOpen}
                        cancelClicked={() => this.closeAlertClicked(false)}
                        confirmClicked={() => this.closeAlertClicked(true)} >
                        {getTranslatedRawText('regularOrder.modal.closeServicesModalAlert')}
                    </NsAlertModal>
                }

                {
                    !isEmpty(messageModalText) &&
                    <NsMessageClassModal show={true}
                        onClose={this.closeMessageModalClicked}
                        messageItems={[{
                            messageText: <>{getTranslatedRawText(messageModalText)}</>,
                            messageType: isShowErrorModal ? Enumerations.messageType.error : Enumerations.messageType.info
                        }]} />
                }

                {
                    this.showTour && <NsTour steps={this.tourSteps}
                        id={Enumerations.tourGuide.serialNumberModal} />
                }

                {this.state.resultMessageClass}

            </NsModal >
        );
    }
}

export const createChechboxOptions = (billBookCopy) =>
    CommonHelper.createArray(billBookCopy).map((item, index) => ({ copyNumber: index + 1, isSelected: true }));


export default AddSerialModal