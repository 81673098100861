import React, { Component } from "react";
import { withRouter } from "react-router";

import NsCard from "../../../components/cards/NsCard";
import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../utilities/CultureHelper";
import NsSearchInput from "../../../components/inputs/NsSearchInput";
import CommonHelper from "../../../utilities/CommonHelper";
import Links from "../../../configs/links/AllLinks";
import { isEmpty } from "../../../utilities/ValidationHelper";

class BnSidebarSearch extends Component {
    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.state = {
            searchText: props.urlParameters?.searchInput
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.urlParameters?.searchInput !== nextProps.urlParameters?.searchInput)
            this.setState({ searchText: nextProps.urlParameters?.searchInput });
        if (!nextProps.urlParameters?.searchInput)
            this.setState({ searchText: '' });
    }

    handleSearchTextChanged = (value) => {
        this.setState({ searchText: value },
            () => CommonHelper.safeFunctionCall(this.props.searchMobileFilter, this.state.searchText));
    }

    searchFormClicked = () => {
        let searchParameters = this.props.urlParameters || {};
        if (!isEmpty(this.state.searchText)) {
            searchParameters.searchInput = this.state.searchText;
        } else
            delete searchParameters.searchInput;
        this.isBlog
            ? this.props.history.push(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogSearchResult, searchParameters))
            : this.props.history.push(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsSearchResult, searchParameters));
    }

    render() {
        const { searchText } = this.state;
        const { hasSearchIcon } = this.props;
        return (
            <NsCard className='p-3 search'>
                <p className='section-title'>
                    {getTranslatedRawText('product.sideBarTitles.searchInResult')}
                </p>
                <NsSearchInput hasSearchIcon={hasSearchIcon}
                    value={searchText}
                    searchFormClicked={this.searchFormClicked}
                    handleSearchTextChanged={this.handleSearchTextChanged}
                    placeHolder={getTranslatedRawText('adminPanel.common.searchPlaceHolder')}
                    className='background-color'
                    inputClassName='background-color'
                />
            </NsCard>
        );
    }
}

export default withRouter(BnSidebarSearch);