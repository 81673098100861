import React from "react";
import { Col, Row } from "react-bootstrap";

import { getTranslatedRawText } from "../../utilities/CultureHelper";
import CommonHelper from "../../utilities/CommonHelper";
import Enumerations from "../../configs/Enumerations";
import NsSkeleton from "../skeletons/NsSkeleton";
import NsLabelBox from "./NsLabelBox";
import { isEmpty } from "../../utilities/ValidationHelper";

const NsBasicInfoLabelBox = (props) => {
    const { basicInfo, isLoading, className, children } = props;
    const defaultClassName = 'background-white-color min-size';
    console.log(basicInfo, !isEmpty(Object.keys(basicInfo)));

    return (isLoading
        ? <Row>
            {CommonHelper.createArray(3).map((item, index) =>
                <Col key={index} xs={2}>
                    <NsSkeleton isVisible={true}
                        variant={[Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect]} />
                </Col>
            )}
        </Row>
        : <div className='d-flex flex-wrap gap-2'>
            {!isEmpty(Object.keys(basicInfo)) && Object.keys(basicInfo).map(key => {
                const value = basicInfo[key];
                return !!value && <NsLabelBox label={getTranslatedRawText(`components.basicInfo.${key}`)}
                    className={className || defaultClassName}
                    hasTooltip={true}
                    value={value} />
            })}
            {children}
        </div>
    );
}

export default React.memo(NsBasicInfoLabelBox);