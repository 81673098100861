import React, { useEffect, useState } from 'react';

import NsCard from '../../../../../components/cards/NsCard';
import { GetApDfbTotalTotalLineChartService } from '../../../../../services/adminPanel/AdminDashboardService';
import ApBasesDashboardCharts from '../../bases/ApBasesDashboardCharts';
import { isEmpty } from '../../../../../utilities/ValidationHelper';

const ApBasesDfbTotalLineChart = (props) => {

    const { parameters, refreshDate } = props;
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState();

    const adjustDatasets = (data) => isEmpty(data) && data.length > 1 ? {} : {
        "labels": data.labels,
        "datasets":
            [{
                type: 'line',
                label: data.datasets[0].label,
                yAxisID: 'y-axis-0',
                borderColor: '#3468C0',
                backgroundColor: '#3468C0',
                borderWidth: 3,
                radius: 4,
                hoverBorderWidth: 4,
                rotate: 90,
                data: data.datasets[0].data,
            },
            {
                type: 'line',
                label: data.datasets[1].label,
                yAxisID: 'y-axis-1',
                backgroundColor: '#FF9843',
                borderColor: '#FF9843',
                borderWidth: 2,
                data: data.datasets[1].data,
            }]
    };

    useEffect(() => {
        !!refreshDate && getTotalFeedbacks();
    }, [refreshDate])

    const getTotalFeedbacks = () => {
        setIsLoading(true);
        GetApDfbTotalTotalLineChartService(parameters, getApDfbTotalTotalLineChartCallback);
    }
    const getApDfbTotalTotalLineChartCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) setData(adjustDatasets(resultData));
        else setMessageClassModal(messageClassModal);
    };

    const options = {
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                id: 'y-axis-1',
                position: 'left',
                gridLines: {
                    display: false,
                }
            }, {
                id: 'y-axis-0',
                position: 'right',
                gridLines: {
                    display: false
                }
            }],
        }
    };

    return (
        <NsCard className='p-3 h-100'>
            <ApBasesDashboardCharts title='adminPanel.dashboard.totalFeedback'
                height='75vh'
                type='line'
                tooltipTitle='adminPanel.dashboard.totalSalesDbChartDes'
                data={data}
                // hasZoom={true}
                refreshClicked={getTotalFeedbacks}
                options={options}
                isLoading={isLoading} />
            {messageClassModal}
        </NsCard>
    );
};

export default React.memo(ApBasesDfbTotalLineChart);