import React, { Component } from 'react';
import Tour from 'reactour';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';
import DelayedTaskHelper from '../../utilities/DelayedTaskHelper';
import NsButton from '../buttons/NsButton';

class NsTour extends Component {
    constructor(props) {
        super(props);
        this.delayedGetCurrentStep = new DelayedTaskHelper(this.getCurrentStep, 500);
        this.state = {
            isTourOpen: true,
            currentStep: this.delayedGetCurrentStep.run(0),
        };
    }

    closeTour = () => {
        CurrentUserHelper.addToSeenTours(this.props.id);
        CommonHelper.safeFunctionCall(this.props.closeTour);
        this.setState({ isTourOpen: false });
    }

    showOverflowY = () => document.body.style.overflowY = 'auto';

    hideOverflowY = () => document.body.style.overflowY = 'hidden';

    componentWillUnmount = () => this.showOverflowY();

    getCurrentStep = (step) => {
        if (this.state.currentStep !== step)
            this.setState({ currentStep: step });
    }

    render() {
        const { steps } = this.props;
        const { isTourOpen, currentStep } = this.state;

        return (
            <Tour steps={steps}
                isOpen={isTourOpen}
                badgeContent={(curr, tot) => CommonHelper.stringFormat(getTranslatedRawText('common.tour.badgeContent'), curr, tot)}
                onAfterOpen={this.hideOverflowY}
                onBeforeClose={this.showOverflowY}
                onRequestClose={this.closeTour}
                closeWithMask={false}
                disableKeyboardNavigation={true}
                lastStepNextButton={<NsButton size='small'
                    className="primary-outline"
                    label='common.tour.lastStep' />}
                showCloseButton={false}
                getCurrentStep={(step) => this.delayedGetCurrentStep.run(step)}
                currentStep={currentStep}
                disableDotsNavigation={true}
                showNavigation={steps.length <= 1 ? false : true}
                showNumber={steps.length <= 1 ? false : true} />
        );
    }
}

export default NsTour;