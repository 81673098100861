import React from 'react';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import Config from '../../../../configs/Config';

const ContactInformation = () => {

    const contactInfos = [
        {
            id: 1,
            type: 'phone',
            content: Config.phoneNumber,
            icon: 'ns-icon-support'
        },
        {
            id: 2,
            content: getTranslatedRawText('footer.contactTime'),
            type: 'text',
            icon: 'ns-icon-call'
        },
        {
            id: 3,
            type: 'email',
            content: getTranslatedRawText('footer.email'),
            icon: 'ns-icon-email'
        },
    ]

    const setLink = (content, type) => {
        switch (type) {
            case 'text': return content
            case 'email': return <a className='text-decoration-none text-white' href={`mailto:${content}`}>{content}</a>
            case 'phone': return <a className='text-decoration-none text-white' href={`tel:${content}`}>{content}</a>
        }
    }

    return (
        <div>
            <p className="font-size-14 fw-bold">{getTranslatedRawText('footer.contactInfo')}</p>
            {contactInfos.map((item, index) =>
                <div key={index} className='my-0 d-flex align-items-center font-size-12'>
                    <i className={`${item.icon} h4 my-0 me-2`} />
                    <span>{setLink(item.content, item.type)}</span>
                </div>
            )}
        </div>
    );
};

export default React.memo(ContactInformation);