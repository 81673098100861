import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import SpSidebar from './sidebars/SpSidebar';

class SinglePage extends Component {

    componentDidMount = () => window.scrollTo({ top: -200, behavior: 'smooth' });

    render() {
        return (
            <Row>
                <Col md={8} lg={9}>
                    {this.props.children}
                </Col>

                <Col md={{ span: 4, order: 'first' }} lg={{ span: 3, order: 'first' }}>
                    <SpSidebar />
                </Col>
            </Row >
        );
    }
}

export default SinglePage;