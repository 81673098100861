import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { GetCitiesService, GetStatesService } from "../../../../../services/ProfileSetting/DeliveryAddressSettingService";
import { GetUserActiveAddressesService } from "../../../../../services/adminPanel/AdminUserService";
import NsNumberInputWithPattern from "../../../../../components/inputs/NsNumberInputWithPattern";
import EnumerationTranslationHelper from "../../../../../utilities/EnumerationTranslationHelper";
import { GetAgencyListService } from "../../../../../services/adminPanel/AdminAgenciesService";
import ConstantsWithTranslation from "../../../../../configs/ConstantsWithTranslation";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import NsDatePicker from '../../../../../components/dateTimePickers/NsDatePicker';
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import NsTextField from "../../../../../components/inputs/NsTextField";
import NsTypography from "../../../../../components/text/NsTypography";
import NsSelect from "../../../../../components/dropdowns/NsSelect";
import CommonHelper from "../../../../../utilities/CommonHelper";
import NsSwitch from "../../../../../components/switch/NsSwitch";
import DateHelper from "../../../../../utilities/DateHelper";

const ApBaseUserEditContent = (props) => {
    const { userDetails, userInfoDate, fieldVisibilityType } = props;
    const [accountStatus, setAccountStatus] = useState(false);
    const [isOfficialInvoiceEnabled, setIsOfficialInvoiceEnabled] = useState(false)
    const [accountType, setAccountType] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [licenceNumber, setLicenceNumber] = useState('');
    const [TRN, setTRN] = useState('');
    const [genderTypeId, setGenderTypeId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nationalCode, setNationalCode] = useState('');
    const [nationalCodeMask, setNationalCodeMask] = useState(null);
    const [nationalCodeRegex, setNationalCodeRegex] = useState(null);
    const [trnMask, setTrnMask] = useState(null);
    const [trnRegex, setTrnRegex] = useState(null);
    const [birthday, setBirthday] = useState('');
    const [email, setEmail] = useState('');
    const [stateId, setStateId] = useState('');
    const [cityId, setCityId] = useState('');
    const [defaultUserAddressId, setDefaultUserAddressId] = useState('');
    const [userActiveAddresses, setUserActiveAddresses] = useState([]);
    const [agencyId, setAgencyId] = useState(10000);
    const [description, setDescription] = useState('');
    const [lockoutEnd, setLockoutEnd] = useState('');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [flagImage, setFlagImage] = useState(null);
    const [agencyList, setAgencyList] = useState([]);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    const editValue = {
        accountStatus,
        accountType,
        companyName,
        licenceNumber,
        TRN: TRN?.replace(/_/g, ''),
        genderTypeId,
        firstName,
        lastName,
        nationalCode,
        birthday,
        email,
        stateId,
        cityId,
        defaultUserAddressId,
        agencyId,
        description,
        isOfficialInvoiceEnabled
    }

    useEffect(() => {
        setAccountStatus(userDetails?.accountStatus);
        setIsOfficialInvoiceEnabled(userDetails?.isOfficialInvoiceEnabled);
        setAccountType(userDetails?.accountType);
        setNationalCodeMask(userDetails?.nationalCodeMask);
        setTrnMask(userDetails?.trnMask);
        setCompanyName(userDetails?.companyName);
        setLicenceNumber(userDetails?.licenceNumber);
        setTRN(userDetails?.trn);
        setGenderTypeId(userDetails?.genderTypeId);
        setFirstName(userDetails?.firstName);
        setLastName(userDetails?.lastName);
        setNationalCode(userDetails?.nationalCode);
        setBirthday(new Date(userDetails?.birthday));
        setEmail(userDetails?.email);
        setStateId(userDetails?.stateId);
        setCityId(userDetails?.cityId);
        setDefaultUserAddressId(userDetails?.defaultUserAddressId);
        setAgencyId(userDetails?.agencyId);
        setNationalCodeRegex(userDetails?.nationalCodeJsRegexPattern);
        setTrnRegex(userDetails?.trnJsRegexPattern);
        setDescription(userDetails?.description);
        setLockoutEnd(userDetails?.lockoutEnd);
        setFlagImage(userDetails?.flagImage);
        !isEmpty(userDetails?.userId) && GetUserActiveAddressesService(userDetails?.userId, getUserActiveAddressesCallback);
    }, [userDetails])

    useEffect(() => {
        GetStatesService({ id: userDetails?.countryId }, getStatesCallback);
    }, [userDetails?.countryId])

    useEffect(() => {
        getCities(stateId);
    }, [stateId])

    useEffect(() => {
        getAgencies(cityId);
    }, [cityId])

    useEffect(() => {
        !!userInfoDate && CommonHelper.safeFunctionCall(props.setNewUserInfo, editValue)
    }, [userInfoDate]);

    const getCitiesCallback = (resultData, result, messageClassModal) => {
        if (!result.hasError)
            setCityList(resultData);
        else
            setMessageClassModal(messageClassModal);
    }

    const getUserActiveAddressesCallback = (resultData, result, messageClassModal) => {
        if (!result.hasError)
            setUserActiveAddresses(resultData);
        else
            setMessageClassModal(messageClassModal);
    }

    const getCities = (stateId) => {
        stateId && GetCitiesService({ stateId }, getCitiesCallback);
    }

    const getAgencies = (id) => {
        id && GetAgencyListService({ id }, getAgencyListCallback);
    }

    const getAgencyListCallback = (resultData, result, messageClassModal) => {
        if (!result.hasError) {
            setAgencyList(resultData);
        } else
            setMessageClassModal(messageClassModal);
    }

    const getStatesCallback = (resultData, result, messageClassModal) => {
        if (!result.hasError && !!resultData && resultData.length > 0)
            setStateList(resultData);
        else
            setMessageClassModal(messageClassModal);
    }

    const stateIdChanged = (newValue) => {
        setStateId(newValue);
        setCityId(null);
        setAgencyId(null);
        setAgencyList([]);
    }

    return (
        <>
            <div className="m-3">
                <NsTypography variant='inherit' className='fw-bold'>{getTranslatedRawText('adminPanel.usersInfo.accountSettings')}</NsTypography>
            </div>

            <Row className="mx-1">
                <Col xl={3} lg={6} sm={12}>
                    <NsSwitch label="adminPanel.dataGridColumn.accountStatus"
                        checked={accountStatus}
                        onChange={() => setAccountStatus(!accountStatus)} />

                    {!accountStatus && lockoutEnd &&
                        <span className="font-size-12">
                            {getTranslatedRawText('adminPanel.usersInfo.inactiveUntil')}:&nbsp; {DateHelper.getDateAndTime(lockoutEnd)}
                        </span>
                    }
                </Col>
                <Col xl={3} lg={6} sm={12}>
                    <NsSwitch label="adminPanel.usersInfo.officialInvoiceIssue"
                        checked={isOfficialInvoiceEnabled}
                        onChange={() => setIsOfficialInvoiceEnabled(!isOfficialInvoiceEnabled)} />
                </Col>
            </Row>

            <Row className="mx-1 pt-4">
                <Col xl={3} lg={6} sm={12}>
                    <NsSelect label={getTranslatedRawText('adminPanel.usersInfo.accountType')}
                        valueChanged={(value) => { setAccountType(value); props.accountTypechange(value) }}
                        selectedItem={accountType}
                        options={ConstantsWithTranslation.getAccountType()} />
                </Col>
                {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType) &&
                    <Col xl={3} lg={6} sm={12}>
                        <NsTextField className='w-100' label={getTranslatedRawText('adminPanel.usersInfo.companyName')}
                            value={companyName}
                            required
                            valueChanged={(value) => setCompanyName(value)} />
                    </Col>
                }
                {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType) &&
                    <Col xl={3} lg={6} sm={12}>
                        <NsTextField className='w-100' label={getTranslatedRawText('adminPanel.usersInfo.licenseNumber')}
                            value={licenceNumber}
                            required
                            valueChanged={(value) => setLicenceNumber(value)} />
                    </Col>
                }
                {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType) &&
                    <Col xl={3} lg={6} sm={12}>
                        <NsNumberInputWithPattern className='w-100'
                            codeMask={trnMask}
                            regex={trnRegex}
                            required
                            endAdornment={<img src={flagImage} alt={getTranslatedRawText("adminPanel.usersInfo.trn")} />}
                            label={getTranslatedRawText('adminPanel.usersInfo.trn')}
                            value={TRN}
                            valueChanged={(value) => setTRN(value)} />
                    </Col>
                }
            </Row >

            <div className="mx-3 pt-4">
                <NsTypography variant='inherit' className='fw-bold'>{getTranslatedRawText('adminPanel.usersInfo.personalInformation')}</NsTypography>
            </div>

            <Row className='mx-1'>
                <Col xl={3} lg={6} sm={12} className="mb-2">
                    <NsSelect label={getTranslatedRawText('adminPanel.usersInfo.genderType')}
                        valueChanged={(value) => setGenderTypeId(value)}
                        options={ConstantsWithTranslation.getGender()}
                        selectedItem={genderTypeId} />
                </Col>
                <Col xl={3} lg={6} sm={12} className="mb-2">
                    <NsTextField className='w-100' label={getTranslatedRawText('adminPanel.usersInfo.firstName')}
                        value={firstName}
                        required
                        valueChanged={(value) => setFirstName(value)} />
                </Col>
                <Col xl={3} lg={6} sm={12} className="mb-2">
                    <NsTextField className='w-100' label={getTranslatedRawText('adminPanel.usersInfo.lastName')}
                        value={lastName}
                        required
                        valueChanged={(value) => setLastName(value)} />
                </Col>
                {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.nationalCodeVisibilityType) &&
                    <Col xl={3} lg={6} sm={12} className="mb-2">
                        <NsNumberInputWithPattern className='w-100'
                            codeMask={nationalCodeMask}
                            regex={nationalCodeRegex}
                            required
                            endAdornment={<img src={flagImage} alt="adminPanel.usersInfo.idNumber" />}
                            label={EnumerationTranslationHelper.getNationalCodeNameById(userDetails?.countryId)}
                            value={nationalCode}
                            valueChanged={(value) => setNationalCode(value)} />
                    </Col>
                }
                <Col xl={3} lg={6} sm={12} className="mb-2">
                    <NsDatePicker label={getTranslatedRawText('adminPanel.usersInfo.dateOfBirth')}
                        selectedDate={birthday}
                        valueChanged={(value) => setBirthday(value)}
                        className='w-100' />
                </Col>
                <Col xl={3} lg={6} sm={12} className="mb-2">
                    <NsTextField className='w-100' label={getTranslatedRawText('adminPanel.usersInfo.email')}
                        value={email}
                        valueChanged={(value) => setEmail(value)} />
                </Col>
                <Col xl={3} lg={6} sm={12} className="mb-2">
                    <NsSelect className='w-100'
                        label={getTranslatedRawText('adminPanel.usersInfo.state')}
                        valueChanged={stateIdChanged}
                        options={stateList}
                        selectedItem={stateId} />
                </Col>
                <Col xl={3} lg={6} sm={12} className="mb-2">
                    <NsSelect className='w-100'
                        label={getTranslatedRawText('adminPanel.usersInfo.city')}
                        valueChanged={(value) => setCityId(value)}
                        options={cityList}
                        selectedItem={cityId} />
                </Col>
                {!isEmpty(userDetails?.userId) &&
                    <Col xl={9} lg={6} sm={12} className="mb-2">
                        <NsSelect className='w-100'
                            label={getTranslatedRawText('adminPanel.usersInfo.defaultUserAddress')}
                            valueChanged={(value) => setDefaultUserAddressId(value)}
                            options={userActiveAddresses}
                            selectedItem={defaultUserAddressId} />
                    </Col>
                }
                <Col xl={3} lg={6} sm={12} className="mb-2">
                    <NsSelect className='w-100'
                        label={getTranslatedRawText('adminPanel.usersInfo.agency')}
                        valueChanged={(value) => setAgencyId(value)}
                        options={agencyList}
                        selectedItem={agencyId}
                        notRequired />
                </Col>
                <Col xs={12} >
                    <NsTextField className='w-100' label={getTranslatedRawText('adminPanel.usersInfo.description')}
                        value={description}
                        valueChanged={(value) => setDescription(value)} />
                </Col>
            </Row>
            {messageClassModal}
        </>
    )
}

export default React.memo(ApBaseUserEditContent);