import Links from '../../configs/links/AllLinks';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest } from '../bases/BaseService';

const GetStatisticalReportOfOrdersService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStatisticalReportOfOrders), parameters, callback);
};

const GetStatisticalReportOfPaymentsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStatisticalReportOfPayments), parameters, callback);
};

const GetUsersFinancialBalanceSheetService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApUsersDebitCredit), parameters, callback);
};

export {
    GetStatisticalReportOfOrdersService,
    GetStatisticalReportOfPaymentsService,
    GetUsersFinancialBalanceSheetService
}
