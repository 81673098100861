import React, { useState } from 'react';

import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import Enumerations from '../../../../configs/Enumerations';
import { GetAdvertisementContentsService } from '../../../../services/adminPanel/AdminAdvertisementService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApAdvertisementModal from './ApAdvertisementModal';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';

const ApAdvertisement = () => {

    const [showAdvertisementModal, setShowAdvertisementModal] = useState(false);
    const [selectedAdvertisementId, setSelectedAdvertisementId] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const columns = [
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: true,
                editClicked: (id) => showAdvertisementModalClicked(id),
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 460
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 90,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 200
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 150
        },
    ];

    const showAdvertisementModalClicked = (id) => {
        setSelectedAdvertisementId(id);
        setShowAdvertisementModal(true);
    };

    const closeAdvertisementModalClicked = (refreshData) => {
        setShowAdvertisementModal(false);
        !!refreshData && setCallServiceDate(new Date());
    };

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.advertisement")}
            actionButton={{
                onClick: showAdvertisementModalClicked,
                isLoading: isLoading,
                disabled: !hasAccess([UserClaimEnumerations.postApAdvertisement])
            }}
            icon={'ns-icon-advertisement h1'}>

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetAdvertisementContentsService}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showAdvertisementModal &&
                <ApAdvertisementModal advertisementId={selectedAdvertisementId}
                    isNewAdvertisement={!selectedAdvertisementId}
                    closeModal={closeAdvertisementModalClicked} />
            }

        </NsAdminPanelContentWrapper>
    );
};

export default React.memo(ApAdvertisement);