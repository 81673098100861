import React, { Component } from 'react';

import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsHtmlToReact from '../../../../components/htmlToReact/NsHtmlToReact';
import NsTypography from '../../../../components/text/NsTypography';
import Enumerations from '../../../../configs/Enumerations';
import { GetCmsSinglePageService } from '../../../../services/singlePages/CmsService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import SinglePage from '../../SinglePage';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';

class WarrantyTerms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            warrantyTerms: {},
            resultMessageClass: '',
            isLoading: false,
        }
    }

    componentDidMount = () => { this.getContent(); }

    getContent = () => {
        this.setState({ isLoading: true });
        GetCmsSinglePageService(Enumerations.staticPageType.warrantyTerms, this.GetPageContentCallback);
    }

    GetPageContentCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ warrantyTerms: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    warrantyBodyText = () => {
        const conditions = this.state.warrantyTerms.staticPages;
        const terms = !isEmpty(conditions) && conditions.map((item, index) =>
            <div key={index}>
                <NsTypography key={index} variant='inherit' className='d-block fw-bold mb-3'>
                    {item.isTitleVisible && item.title}
                </NsTypography>

                <div className='mt-3'>
                    <NsHtmlToReact>
                        {item.mainContent}
                    </NsHtmlToReact>
                </div>
            </div>
        );
        return terms;
    }

    render() {
        const summary = this.state.warrantyTerms?.staticPageType?.summary;
        return (
            <SinglePage >
                <NsPageMainContentWrapper header={getTranslatedRawText('singlePage.warrantyTerms')}
                    icon='ns-icon-warranty-terms h1' className='mb-3'>

                    {this.state.isLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                        : <>
                            {summary && <p>{summary}</p>}

                            {this.warrantyBodyText()}
                        </>
                    }
                </NsPageMainContentWrapper>
            </SinglePage >
        );
    }
}

export default WarrantyTerms;