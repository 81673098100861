import React from 'react';

import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import LanguageSelection from '../../main/headers/bases/LanguageSelection';
import NsTypography from '../../../../components/text/NsTypography';
import StorageHelper from '../../../../utilities/StorageHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsAvatar from '../../../../components/avatar/NsAvatar';
import Enumerations from '../../../../configs/Enumerations';
import NsMenu from '../../../../components/menu/NsMenu';
import ApHeaderUserInfo from './ApHeaderUserInfo';
import { useState } from 'react';

const ApHeaderNavbar = (props) => {
    const { userName } = props;
    const [defaultCurrency, setDefaultCurrency] = useState(StorageHelper.getFromStorage(Enumerations.storage.userCurrency)?.defaultCurrency);

    const currencyUpdated = (defaultCurrency) => setDefaultCurrency(defaultCurrency);

    return (
        <>
            <NsTypography className='me-auto'>{getTranslatedRawText('headers.top.welcome')} </NsTypography>
            {!isEmpty(defaultCurrency) &&
                <div className='d-flex me-2 align-items-center'>
                    <div className='bg-black rounded white-color font-size-10 px-1'>
                        {defaultCurrency?.symbol}
                    </div>
                    <NsTypography variant='inherit' className='black-color text-capitalize font-size-14 ms-2'>
                        {defaultCurrency?.name}
                    </NsTypography>
                </div>
            }
            <LanguageSelection className='black-color font-size-12'
                currencyUpdated={currencyUpdated} />

            <NsMenu buttonClassName={userName
                ? "text-capitalize text-start p-0"
                : 'border-0 p-0 bg-transparent text-white'}
                buttonChildren={<NsAvatar className='mx-3'
                    name={userName} />}>

                <div className='middle-header__account'>
                    {userName && <ApHeaderUserInfo username={userName} />}
                </div>

            </NsMenu>

        </>
    );
}

export default React.memo(ApHeaderNavbar);