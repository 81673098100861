import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsAvailableDimensionTable from '../../../components/availableDimensions/NsAvailableDimensionTable';
import ShoppingBagMainPriceInquery from '../../products/shoppingBags/main/ShoppingBagMainPriceInquery';
import { getTranslatedRawText, isEnCulture } from '../../../utilities/CultureHelper';
import { GetProductBasicPriceService } from '../../../services/order/OrderService';
import NsGroupNumberInput from '../../../components/inputs/NsGroupNumberInput';
import DelayedTaskHelper from '../../../utilities/DelayedTaskHelper';
import NsTypography from '../../../components/text/NsTypography';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import NsMenu from '../../../components/menu/NsMenu';
import NsHtmlToReact from '../../../components/htmlToReact/NsHtmlToReact';

class PdCustomDimension extends Component {
    constructor(props) {
        super(props);
        this.delayGetAvailableDimensions = new DelayedTaskHelper(() => {
            this.getAvailableDimensions();
        }, 1000);
        this.state = {
            customWidth: this.props.rows[0]?.width || 100,
            customHeight: this.props.rows[0]?.height || 100,
            isValidCustomWidth: false,
            isValidCustomHeight: false,
            showModal: false,
            rows: this.props.rows,
            resultMessageClass: (<></>)
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.rows !== prevProps.rows)
            this.setState({
                rows: this.props.rows,
                customWidth: this.props.rows[0]?.width,
                customHeight: this.props.rows[0]?.height,
            })
    }

    valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        this.setState({
            [valueName]: value,
            [isValidName]: isValid,
        }, () => this.delayGetAvailableDimensions.run())
    }

    getAvailableDimensions = () => {
        const { productId, series, turnaroundType, twoSidePrintingType } = this.props;
        const { customWidth, customHeight } = this.state;
        let data = {
            productId: productId,
            series,
            turnaroundType,
            twoSidePrintingType,
            width: customWidth,
            height: customHeight
        }
        this.setState({ isLoading: true });
        !!series && GetProductBasicPriceService(data, this.getProductBasicPriceCallback);
    }

    getProductBasicPriceCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({ rows: resultData, isLoading: false });
        }
        else {
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
        }
    }

    render() {
        const { series, isAuthenticated, isLoadingGrid, showShoppingBagCustomDimensionPrice } = this.props;
        const { rows, isLoading, resultMessageClass, showModal } = this.state;
        return (
            <>
                {
                    CommonHelper.isMobileDevice()
                        ? <>
                            <NsButton label={getTranslatedRawText('product.detail.customDimension')}
                                className='secondary-outline fw-bold w-auto'
                                size='small'
                                isLoading={isLoadingGrid}
                                onClick={() => this.setState({ showModal: true })} />
                            {showModal &&
                                <NsModal maxWidth="sm"
                                    dialogTitle={getTranslatedRawText('product.detail.customDimension')}
                                    showModal={showModal}
                                    closeModal={() => this.setState({ showModal: false })}>
                                    {!!showShoppingBagCustomDimensionPrice
                                        ? <ShoppingBagMainPriceInquery isMenu={true}
                                            isAuthenticated={isAuthenticated} />
                                        : <InquiryGrid state={this.state}
                                            rows={rows}
                                            series={series}
                                            isLoading={isLoading}
                                            isAuthenticated={isAuthenticated}
                                            valueChanged={this.valueChanged} />
                                    }
                                </NsModal>
                            }
                        </>
                        : <NsMenu menuClassName={clsx(isEnCulture() ? '' : '')}
                            buttonClassName='secondary-outline fw-bold w-auto'
                            isLoading={isLoadingGrid}
                            buttonLabel={getTranslatedRawText('product.detail.customDimension')}
                            placement='bottom-start'>
                            {!!showShoppingBagCustomDimensionPrice
                                ? <Row className='p-3'>
                                    <ShoppingBagMainPriceInquery isMenu={true}
                                        isAuthenticated={isAuthenticated} />
                                </Row>
                                : <InquiryGrid state={this.state}
                                    rows={rows}
                                    series={series}
                                    isLoading={isLoading}
                                    isAuthenticated={isAuthenticated}
                                    valueChanged={this.valueChanged} />
                            }
                        </NsMenu>
                }
                {resultMessageClass}
            </>
        );
    }
}

function InquiryGrid({ state, rows, series, isLoading, isAuthenticated, valueChanged }) {
    const { customWidth, isValidCustomWidth, customHeight, isValidCustomHeight } = state;
    return <Col className='p-3'>
        <NsGroupNumberInput label={getTranslatedRawText("regularOrder.common.width")}
            inputProps={{ min: rows[0].width }}
            value={customWidth}
            maxLength={10}
            unit={getTranslatedRawText('common.mm')}
            step={1}
            autoFocus={true}
            onChanged={(value, isValid) => valueChanged(value, isValid, { customWidth }, { isValidCustomWidth })} />
        <NsGroupNumberInput label={getTranslatedRawText("regularOrder.common.height")}
            className='mt-2'
            inputProps={{ min: rows[0].height }}
            value={customHeight}
            maxLength={10}
            unit={getTranslatedRawText('common.mm')}
            step={1}
            onChanged={(value, isValid) => valueChanged(value, isValid, { customHeight }, { isValidCustomHeight })} />
        <div className='w-100 mt-3 product-tour-available'>
            <NsAvailableDimensionTable size='small'
                label={getTranslatedRawText('product.detail.availableDimension')}
                series={series}
                isLoading={isLoading}
                isAuthenticated={isAuthenticated}
                rows={rows}
                isCustomDimension={true}
                showGuidance={false} />
        </div>
        <div>
            <NsTypography variant='inherit'
                className={clsx(!CommonHelper.isMobileDevice() && 'product-custom-dimension-hint', 'font-size-12 mt-2')}>
                <NsHtmlToReact>
                    {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.customDimension'),
                        `<span className='fw-bold'>${getTranslatedRawText('regularOrder.confirmDetail.startOrdering')}</span>`)}
                </NsHtmlToReact>
            </NsTypography>
        </div>
    </Col>

}


export default PdCustomDimension;