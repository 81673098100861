import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getTitleMetaTagByCulture } from '../../../../components/metaTags/NsSetPageTitleAndDescription';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import OrderCartConfirmationModal from '../../../profileSettings/modals/OrderCartConfirmationModal';
import { currentUserActionCreators } from '../../../../redux/reducers/CurrentUserReducer';
import { miniCartActionCreators } from '../../../../redux/reducers/MiniCartReducer';
import UpSidebarUserInfo from '../../../profileSettings/sidebars/UpSidebarUserInfo';
import '../../../../assets/sass/views/profileSettings/ProfileSetting.scss';
import NsSelectMenu from '../../../../components/dropdowns/NsSelectMenu';
import NsSearchInput from '../../../../components/inputs/NsSearchInput';
import NsTypography from '../../../../components/text/NsTypography';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import NsBadge from '../../../../components/badge/NsBadge';
import NsCard from '../../../../components/cards/NsCard';
import NsMenu from '../../../../components/menu/NsMenu';
import Links from '../../../../configs/links/AllLinks';
import SignInModal from './modals/signIn/SigninModal';
import SideMenu from './modals/sideMenu/SideMenu';
import Config from '../../../../configs/Config';
import CartList from './bases/CartList';

const isMobileDevice = CommonHelper.isMobileDevice();

class MiddleHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            selectedCategory: null,
            showConfirmModal: false,
            showSignInModal: false,
            ShowSideMenu: false,
            closeMenuDate: null,
            allOrderIds: [],
            isStore: false,
            resultMessageClass: (<></>)
        };
    }

    handleSearchTextChanged = (searchText) => this.setState({ searchText });

    searchClicked = () => {
        const { searchText, selectedCategory } = this.state;
        if (selectedCategory)
            this.props.history.push(CommonHelper.stringFormat(
                getUrlWithCurrentCulture(Links.view.main, Links.view.main.productsByCategory,
                    { "search-input": searchText, "page-number": 1 }), selectedCategory.id, CommonHelper.makeCorrectLink(selectedCategory.value)), { from: 'isFromHeader' });
        else if (!isEmpty(searchText))
            this.props.history.push(
                getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": searchText, "page-number": 1 }), { from: 'isFromHeader' });
        else
            this.props.history.push(
                getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": searchText, "page-number": 1 }), { from: 'isFromHeader' });
        this.setState({
            searchText: '',
            selectedCategory: null
        });
    }

    closeConfirmOrderModalClicked = () => {
        this.setState({ showConfirmModal: false });
    }

    cartOrdersClicked = (allOrderIds, isStore) => {
        isStore
            ? this.props.history.push(
                getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.cartOrder),
                { isExpressMode: true }
            )
            : this.props.history.push(
                getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder),
                { isExpressMode: true }
            )
        !isEmpty(allOrderIds) && this.setState({ allOrderIds, isStore, showConfirmModal: true });
        this.closeMenuClicked();
    }

    showCartClicked = (isStore) => this.props.history.push(getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder),
        { isExpressMode: false, isStore })


    closeMenuClicked = id => {
        const selectedCategory = this.props.categories.find(item => item.id === id) || this.state.selectedCategory;
        this.setState({ selectedCategory, selectedCategoryId: id, closeMenuDate: new Date() });
    }

    toggleModal = (name) => {
        const modalName = CommonHelper.getVariableName(name);
        this.closeMenuClicked();
        this.setState({ [modalName]: !this.state[modalName] });
    }

    signInClicked = () => this.props.getTotalPriceAndCount();

    handleMessage = (message) => this.setState({ resultMessageClass: message })

    render() {
        const { searchText,
            selectedCategory,
            showConfirmModal,
            ShowSideMenu,
            allOrderIds,
            isStore,
            showSignInModal } = this.state;
        const { username, miniCartInfo, categories } = this.props;

        return (
            <div className='d-flex align-items-center p-2'>

                <NsButton className="m-0 p-0 justify-content-start d-lg-none pt-1"
                    onClick={() => this.toggleModal({ ShowSideMenu })} >
                    <i className='ns-icon-menu fs-2 white-color mb-0' />
                </NsButton>
                <div className='middle-header__logo me-xl-5'>
                    <Link to={getUrlWithCurrentCulture(Links, Links.home)}>
                        <img src={CommonHelper.isMobileDevice() ? Config.siteLogoMobile : Config.siteLogo}
                            className='w-75'
                            alt={CommonHelper.stringFormat(getTranslatedRawText('components.imagesAlt.logo'), getTitleMetaTagByCulture)} />
                    </Link>
                </div>

                <div className='middle-header__search flex-grow-1 d-none d-md-block' aria-label='search in products'>
                    <NsCard className='rounded border-0'
                        contentClassName='d-flex align-items-center'>
                        <div>

                            <NsSelectMenu className='font-size-12 text-nowrap text-capitalize px-1'
                                selectedItem={!!selectedCategory ? selectedCategory.id : 0}
                                options={categories}
                                menuItemClassName='font-size-12'
                                valueChanged={this.closeMenuClicked} />
                        </div>
                        <div className='border-start h-50'>&nbsp;</div>
                        <div className='d-flex flex-grow-1'>
                            <NsSearchInput hasSearchIcon={false}
                                value={searchText}
                                handleSearchTextChanged={this.handleSearchTextChanged}
                                placeHolder={getTranslatedRawText('headers.middle.searchProduct')}
                                inputClassName='background-white-color font-size-12'
                                searchFormClicked={this.searchClicked} />
                            <NsButton className="primary rounded-0 py-2"
                                size='small'
                                label='headers.middle.search'
                                onClick={this.searchClicked} />
                        </div>
                    </NsCard>
                </div>
                <div className='middle-header__user d-flex'>

                    <NsMenu buttonClassName={this.props.username
                        ? "text-capitalize text-start p-0"
                        : 'border-0 p-0 bg-transparent text-white'}
                        closeMenuDate={this.state.closeMenuDate}
                        buttonChildren={<AccountButtonChildren username={username}
                            showSignInModal={showSignInModal}
                            signInClicked={this.signInClicked}
                            toggleModal={() => this.toggleModal({ showSignInModal })} />}

                        disabledOpenMenu={!isMobileDevice && !this.props.username}>

                        <div className='middle-header__account'>
                            {username ?
                                <UpSidebarUserInfo showInHeader={true}
                                    closeMenuClicked={this.closeMenuClicked}
                                    signOutClicked={this.closeMenuClicked}
                                    handleMessage={this.handleMessage} />
                                :
                                <div className='p-3'>
                                    <NsButton className="transparent-white-text justify-content-start text-capitalize p-0 pb-2"
                                        onClick={() => { this.toggleModal({ showSignInModal }), this.closeMenuClicked() }} >
                                        <NsTypography className='text-capitalize title-color text-decoration-none'>
                                            {getTranslatedRawText('headers.middle.signIn')}
                                        </NsTypography>
                                    </NsButton>
                                    <NsButton className="transparent-white-text justify-content-start text-capitalize p-0 pb-2"
                                        onClick={() => this.props.history.push(getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.mobile))} >
                                        <NsTypography className='text-capitalize title-color text-decoration-none'>
                                            {getTranslatedRawText('headers.middle.createAccount')}
                                        </NsTypography>
                                    </NsButton>
                                </div>
                            }

                        </div>

                    </NsMenu>

                    {username &&
                        <NsMenu buttonClassName="text-capitalize text-start p-0"
                            closeMenuDate={this.state.closeMenuDate}
                            buttonChildren={<CartButtonChildren miniCartInfo={miniCartInfo} />}>

                            <CartList miniCartInfo={miniCartInfo}
                                showCartClicked={this.showCartClicked}
                                confirmOrderClicked={this.cartOrdersClicked} />
                        </NsMenu>
                    }
                </div>
                {showConfirmModal && <OrderCartConfirmationModal showModal={true}
                    selectedRowIds={allOrderIds}
                    isStore={isStore}
                    onClose={this.closeConfirmOrderModalClicked} />
                }
                {ShowSideMenu &&
                    <SideMenu username={username}
                        showModal={ShowSideMenu}
                        closeModal={() => this.toggleModal({ ShowSideMenu })}
                        linkItems={this.props.linkItems} />
                }
                {this.state.resultMessageClass}
            </div>
        );
    }
}

function CartButtonChildren(props) {
    const { miniCartInfo } = props;
    const count = miniCartInfo?.sharedSheetOrderCount + miniCartInfo?.storeProductOrderCount || 0;
    return <div className='middle-header__user--cart white-color d-flex align-items-center ms-2'>
        <NsBadge badgeIcon={<i className="ns-icon-cart h1 mb-0" />}
            className={` ${count ? 'mx-lg-3' : 'mx-lg-2'}`}
            badgeNumber={count} />
        <i className="ns-icon-expand-down d-lg-none align-self-end mb-2" />
        <div className='d-none d-lg-block'>
            <NsTypography variant='inherit' className='d-block font-size-12'>
                {getTranslatedRawText('headers.middle.myCart')}
            </NsTypography>
            <NsTypography variant='inherit' className='font-size-14 fw-bold'>
                {CommonHelper.currencyFormatByDefaultConfig(miniCartInfo?.totalPrice || 0)} <i className="ns-icon-expand-down" />
            </NsTypography>
        </div>
    </div>
}

function AccountButtonChildren(props) {
    return (
        props.username ?
            <div className='middle-header__user--account white-color d-flex align-items-center ms-md-4'>
                <i className='ns-icon-account h1 mx-lg-2 mb-0' aria-label={getTranslatedRawText('headers.middle.userSignInAriaLabel')} />
                <i className="ns-icon-expand-down d-lg-none align-self-end mb-2" />
                <div className='d-none d-lg-block'>
                    <NsTypography variant='inherit' className='truncate d-block font-size-12'>
                        {getTranslatedRawText('headers.middle.hello')},&nbsp;{props.username?.split(' ')[0]}
                    </NsTypography>
                    <NsTypography variant='inherit' className='fw-bold font-size-14'>
                        {getTranslatedRawText('headers.middle.myAccount')} <i className="ns-icon-expand-down" />
                    </NsTypography>
                </div>
            </div>

            :
            <div className='middle-header__user--account white-color d-flex align-items-start ms-md-4' aria-label='sign in or create account'>
                <button onClick={!isMobileDevice ? props.toggleModal : () => { }}
                    className='border-0 p-0 bg-transparent text-white'>
                    <i className='ns-icon-account h1 mx-lg-2' aria-label='user sign in' />
                    <i className="ns-icon-expand-down d-lg-none" />
                </button>
                <div className='d-none d-lg-block'>
                    <NsButton className="justify-content-start p-0"
                        onClick={props.toggleModal} >
                        <NsTypography variant='inherit' className='white-color text-capitalize font-size-12'>
                            {getTranslatedRawText('headers.middle.signIn')}
                        </NsTypography>
                    </NsButton>
                    <Link to={getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.mobile)}
                        className='text-capitalize fw-bold font-size-14'>
                        {getTranslatedRawText('headers.middle.createAccount')}
                    </Link>
                </div>
                {props.showSignInModal &&
                    <SignInModal closeModal={props.toggleModal}
                        signInClicked={props.signInClicked} />
                }
            </div>
    );
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...currentUserActionCreators, ...miniCartActionCreators }, dispatch);
}

export default connect(null, mapDispatchToProps)(MiddleHeader);