import React, { Component } from 'react';

import { CancelRequest } from '../../services/bases/BaseService';
import { isEmpty } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';
import NsDataGrid from './NsDataGrid';

class NsDataGridWithServiceCall extends Component {
	constructor(props) {
		super(props);
		this.cancelationServiceRefrence = null;
		this.state = {
			rows: [],
			isLoading: false,
			pageLength: props.pageLength || CommonHelper.defaultRowPerPage(),
			currentPageIndex: 1,
			totalRows: null,
			sortData: null
		}
	}

	componentWillUnmount = () => CancelRequest(this.cancelationServiceRefrence);

	componentDidMount = () => this.callService();

	componentDidUpdate = (prevProps) => {
		if ((prevProps.callServiceDate !== this.props.callServiceDate) ||
			(prevProps.service !== this.props.service)) {
			CancelRequest(this.cancelationServiceRefrence);
			this.setState({ currentPageIndex: 1 }, this.callService);
		}
	}

	callService = () => {
		if (!this.props.service) return;
		this.setState({ isLoading: true, rows: [] });
		const { currentPageIndex, pageLength, sortData } = this.state;
		let parameters = this.props.hasPagination
			? {
				...this.props.parameters,
				currentPageIndex,
				pageLength: pageLength.value,
				sortData
			}
			: { ...this.props.parameters };
		this.cancelationServiceRefrence = this.props.service(parameters, this.serviceCallback);
		CommonHelper.safeFunctionCall(this.props.gridParametersChanged, parameters);
	}

	serviceCallback = (resultData, result, resultMessageClass) => {
		this.setState({ isLoading: false })
		let rows = [];
		let totalRows = 0;
		if (!result.data.hasError) {
			rows = isEmpty(resultData)
				? []
				: resultData?.hasOwnProperty("dataItems") ? resultData.dataItems : resultData;
			totalRows = isEmpty(resultData)
				? 0
				: resultData.hasOwnProperty("totalCount") ? resultData?.totalCount : resultData?.length,
				this.setState({
					rows, totalRows,
					sortData: {
						selectedSortingPropertyName: resultData?.selectedSortingPropertyName,
						sortType: resultData?.sortType,
					},
				});
		} else
			this.setState({ resultMessageClass: resultMessageClass?.props?.messageItems[0]?.isCanceled ? <></> : resultMessageClass });
		CommonHelper.safeFunctionCall(this.props.getData, rows, false, totalRows);

	}

	currentPageChanged = (currentPage) => this.setState({ currentPageIndex: currentPage }, this.callService);

	pageLengthChanged = (pageLength) => this.setState({ pageLength, currentPageIndex: 1 }, this.callService);

	sortChanged = (sortData) => this.setState({ sortData, currentPageIndex: 1, rows: [] }, this.callService);

	render() {
		const { columnDefs, noFooter, theme, hasPagination, hasCheckbox, headerHeight, selectedRowIds, gridId, hasCustomColumnOrder } = this.props;
		const { isLoading, rows, currentPageIndex, pageLength, totalRows, sortData, resultMessageClass } = this.state;

		return (<>
			<NsDataGrid isLoading={isLoading}
				columnDefs={columnDefs}
				rows={rows}
				rowPerPage={pageLength}
				currentPage={hasPagination ? currentPageIndex : null}
				totalRows={totalRows}
				noFooter={noFooter}
				theme={theme}
				headerHeight={headerHeight}
				hasCheckbox={hasCheckbox}
				selectedRows={this.props.selectedRows}
				selectedRowIds={selectedRowIds}
				sortChanged={this.sortChanged}
				sortData={sortData}
				gridId={gridId}
				hasCustomColumnOrder={hasCustomColumnOrder}
				refreshButtonClicked={this.callService}
				handlePagination={this.currentPageChanged}
				handleRowPerPage={this.pageLengthChanged} />
			{resultMessageClass}
		</>
		);
	}
}

export default NsDataGridWithServiceCall;