import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import NsStickyButton from "../../../../components/buttons/NsStickyButton";
import NsTextField from "../../../../components/inputs/NsTextField";
import NsMessageClassModal from "../../../../components/modals/NsMessageClassModal";
import NsModal from "../../../../components/modals/NsModal";
import NsSwitch from "../../../../components/switch/NsSwitch";
import Enumerations from "../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import {
    DeleteSliderService,
    EditSliderService,
    GetSliderByIdService,
    PostSliderService
} from "../../../../services/adminPanel/AdminSliderService";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText, isEnCulture } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApBaseMapActionButtons from "../../bases/ApBaseMapActionButtons";
import ApLanguageTabs from "../../bases/ApLanguageTabs";
import ApBasesContentVisibility from "../bases/ApBasesContentVisibility";
import ApBasesImageContent from "../bases/ApBasesImageContent";
import ContentHelper from "../bases/ContentHelper";
import ApBasesStandardSizesForUpload from '../bases/ApBasesStandardSizesForUpload'

const ApSliderModal = (props) => {
    const { sliderId, isNewSlider } = props;

    const [multiLanguageSliderContent, setMultiLanguageSliderContent] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [isLoading, setIsLoading] = useState(!isNewSlider);
    const [showValidationDate, setShowValidationDate] = useState();
    const [showDeleteSliderModal, setShowDeleteSliderModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        !!isNewSlider
            ? setMultiLanguageSliderContent(ContentHelper.normalizeContentObject([], { isActive: false }))
            : GetSliderByIdService(sliderId, getSliderByIdCallback);
    }, []);

    const getSliderByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguageSliderContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive }));
        } else
            setMessageClassModal(messageClassModal);
    };

    const closeModalClicked = (messageClassModal) => CommonHelper.safeFunctionCall(props.closeModal, messageClassModal);

    const saveButtonClicked = () => {
        let tempContent = ContentHelper.removeSliderEmptyContent(multiLanguageSliderContent);
        let isNotValidData = ContentHelper.isValidSliderContent(tempContent);
        if (isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }

        setIsLoading(true);
        !!isNewSlider
            ? PostSliderService(tempContent, SliderContentCallback, true)
            : EditSliderService(sliderId, tempContent, SliderContentCallback, true);
    };

    const SliderContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModalClicked(messageClassModal);
        } else
            setMessageClassModal(messageClassModal);
    };

    const deleteSliderClicked = () => setShowDeleteSliderModal(true);

    const deleteSlider = (confirm) => {
        setShowDeleteSliderModal(false);
        if (confirm) {
            setIsLoading(true);
            DeleteSliderService(sliderId, deleteSliderCallback, true);
        }
    };

    const deleteSliderCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModalClicked(messageClassModal);
        else
            setMessageClassModal(messageClassModal);
    };

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguageSliderContent([...newData]);
    }

    const mainButtons = [
        {
            label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary'
            , accessIds: isNewSlider ? [UserClaimEnumerations.postApSlider] : [UserClaimEnumerations.editApSlider]
        },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(false), className: 'primary-outline' },
    ];

    const headerButtons = [
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deleteSliderClicked, accessIds: [UserClaimEnumerations.deleteApSlider] }
    ]

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText(!sliderId ? 'adminPanel.slider.newSlider' : 'adminPanel.slider.editSlider')}
            showModal={true}
            noSpace={true}
            closeModal={closeModalClicked}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>

            {!isNewSlider && <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            }

            <div className="p-3">
                <ApLanguageTabs languageChanged={languageChangedHandler}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguageSliderContent) &&
                    <TabDataTables isNew={isNewSlider} key={selectedLanguage.id}
                        stateHandler={stateHandler}
                        serviceCall={() => GetSliderByIdService(sliderId, getSliderByIdCallback)}
                        data={[...multiLanguageSliderContent]}
                        showValidationDate={showValidationDate}
                        selectedLanguage={selectedLanguage} />
                }
            </div>

            {showDeleteSliderModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => deleteSlider(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), '')}</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }
            {messageClassModal}

        </NsModal >
    );
}

function TabDataTables(props) {
    const { stateHandler, data, showValidationDate, serviceCall, selectedLanguage, isNew } = props;
    let sliderContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);

    const contentVisibilityTypeIdChanged = (selectedItem) => {
        stateHandler(data, { contentVisibilityTypeId: selectedItem }, selectedLanguage.id)
    }

    const selectedAgencyIdsChanged = (agencyIds) => {
        stateHandler(data, { agencyIds }, selectedLanguage.id)
    }

    return <Row className='mt-2 g-1'>
        <Col md={5} className='pe-md-2'>
            <ApBasesImageContent content={sliderContent?.content}
                isChangeFile={!!sliderContent?.content?.fileUrl}
                serviceCall={serviceCall}
                fileUploaderCategoryType={Enumerations.fileUploaderCategoryType.slider}
                stateHandler={(uploadedFileDetail) => stateHandler(data, { ...uploadedFileDetail, }, selectedLanguage.id)} />
            <ApBasesStandardSizesForUpload />
        </Col>
        <Col md={7}>
            <div className='d-flex flex-column'>
                <div className="d-flex mb-2 align-items-end justify-content-between w-100 flew-row" >
                    <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                        valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                        value={sliderContent?.content.title}
                        showValidationDate={showValidationDate}
                        className='w-100' />
                    <div className='ps-2 d-flex align-items-end'>
                        <NsSwitch onChange={() => stateHandler(data, { isActive: !sliderContent?.content.isActive }, selectedLanguage.id)}
                            checked={sliderContent?.content.isActive}
                            disabled={!selectedLanguage.isDefault}
                            label={getTranslatedRawText('adminPanel.slider.active')} />
                    </div>
                </div>

                <NsTextField label={getTranslatedRawText('adminPanel.slider.summary')}
                    valueChanged={(value) => stateHandler(data, { summary: value }, selectedLanguage.id)}
                    value={sliderContent?.content.summary}
                    showValidationDate={showValidationDate}
                    className='w-100 mb-2' />

                <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.link')}
                    valueChanged={(value) => stateHandler(data, { linkUrl: value }, selectedLanguage.id)}
                    value={sliderContent?.content.linkUrl}
                    className={`w-100 mb-2 ${!isEnCulture() && 'not-rtl'}`} />

                <ApBasesContentVisibility contentVisibilityTypeId={sliderContent?.content.contentVisibilityTypeId}
                    selectedAgencies={sliderContent?.content.selectedAgencies}
                    contentVisibilityTypeIdChanged={contentVisibilityTypeIdChanged}
                    selectedAgencyIdsChanged={selectedAgencyIdsChanged} />
            </div>
        </Col>
    </Row>
}

export default React.memo(ApSliderModal);