import React, { Component } from 'react';
import clsx from 'clsx';
import '../../assets/sass/components/inputs/NsSearchInput.scss';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from '../../configs/Enumerations';

class NsSearchInput extends Component {

    searchFormClicked = () => {
        CommonHelper.safeFunctionCall(this.props.searchFormClicked);
    }

    handleSearchTextChanged = (event) => {
        CommonHelper.safeFunctionCall(this.props.handleSearchTextChanged, event.target.value);
    }

    handleOnKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(this.props.searchFormClicked);
    }

    render() {
        const { value, placeHolder, className, inputClassName, hasSearchIcon } = this.props;

        return (
            <div className={clsx("search w-100 d-flex", className)}>
                {hasSearchIcon &&
                    <button className='search__icon' type="submit" onClick={this.searchFormClicked}>
                        <i className='ns-icon-search h3' />
                    </button>
                }
                <input value={value}
                    onKeyDown={this.handleOnKeyDown}
                    onChange={this.handleSearchTextChanged}
                    placeholder={placeHolder}
                    type="text"
                    autoFocus={this.props.autoFocus}
                    className={clsx("search__input font-size-14 p-2 w-100", inputClassName)} />
            </div>
        );
    }
}

export default NsSearchInput;