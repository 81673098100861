import { getTranslatedRawText, getUrlWithCurrentCulture, isEnCulture } from "../utilities/CultureHelper";
import { getEnumKeyFromValue } from "../utilities/EnumerationHelper";
import EnFlag from '../assets/images/en-flag.PNG';
import IrFlag from '../assets/images/ir-flag.PNG';
import Promotion2 from '../assets/images/promotions/Home-Top-Promotion-1.svg';
import Promotion1 from '../assets/images/promotions/Home-Top-Promotion-2.svg';
import Promotion3 from '../assets/images/promotions/Midle-Promotion-1.svg';
import Promotion4 from '../assets/images/promotions/Midle-Promotion-2.svg';
import Promotion5 from '../assets/images/promotions/Midle-Promotion-3.svg';
import Promotion6 from '../assets/images/promotions/Side-Bar-Promotion-1.svg';
import Promotion7 from '../assets/images/promotions/Side-Bar-Promotion-2.svg';
import Promotion8 from '../assets/images/promotions/Blog&News-Side-Bar-Promotion-1.svg';
import Promotion9 from '../assets/images/promotions/Blog&News-Side-Bar-Promotion-2.svg';
import homeAboveTheAboutPromotion1 from '../assets/images/promotions/homeAboveTheAboutPromotion1.svg';
import homeAboveTheAboutPromotion2 from '../assets/images/promotions/homeAboveTheAboutPromotion2.svg';
import homeAboveTheAboutPromotion3 from '../assets/images/promotions/homeAboveTheAboutPromotion3.svg';
import handle_none from '../assets/images/shoppingbag/handle_none.svg';
import flatHandle from '../assets/images/shoppingbag/flatHandle.svg';
import twistedHandle from '../assets/images/shoppingbag/twistedHandle.svg';
import topFolding_none from '../assets/images/shoppingbag/topFolding_none.svg';
import topFolding from '../assets/images/shoppingbag/topFolding.svg';

import Config from "./Config";
import Enumerations from "./Enumerations";
import Links from "./links/AllLinks";
import { hasAccess } from "../utilities/ClaimHelper";
import UserClaimEnumerations from "./UserClaimEnumerations";
import { isEmpty } from "../utilities/ValidationHelper";
import { getConfigObjectByCulture } from "../utilities/ConfigHelper";
import CommonHelper from "../utilities/CommonHelper";

class ConstantsWithTranslation {

	static getAllLanguages() {
		return [
			{
				id: Enumerations.culture.en,
				name: getTranslatedRawText('commonWithoutTranslation.english'),
				label: getTranslatedRawText('regularOrder.billBook.english'),
				culture: getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en),
				flag: EnFlag,
				isActive: Config.supportedLanguages.includes(Enumerations.culture.en),
				isDefault: Config.app.defaultCulture === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en)
			},
			{
				id: Enumerations.culture.fa,
				name: getTranslatedRawText('commonWithoutTranslation.persian'),
				label: getTranslatedRawText('regularOrder.billBook.persian'),
				culture: getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.fa),
				flag: IrFlag,
				isActive: Config.supportedLanguages.includes(Enumerations.culture.fa),
				isDefault: Config.app.defaultCulture === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.fa)
			},
		];
	}

	static getPageContentCommentType() {
		return [
			{ key: Enumerations.pageContentCommentType.allowWithAdminConfirmation, value: getTranslatedRawText('adminPanel.post.allowWithAdminConfirmation') },
			{ key: Enumerations.pageContentCommentType.allow, value: getTranslatedRawText('adminPanel.post.allow') },
			{ key: Enumerations.pageContentCommentType.disable, value: getTranslatedRawText('adminPanel.post.disable') }
		]
	}

	static getPromotions() {
		return ([
			{ id: Enumerations.promoteContentType.homeTopPromotion1, title: "adminPanel.promotions.homeTopPromotion1", src: Promotion1 },
			{ id: Enumerations.promoteContentType.homeTopPromotion2, title: "adminPanel.promotions.homeTopPromotion2", src: Promotion2 },
			{ id: Enumerations.promoteContentType.middlePromotion1, title: "adminPanel.promotions.middlePromotion1", src: Promotion3 },
			{ id: Enumerations.promoteContentType.middlePromotion2, title: "adminPanel.promotions.middlePromotion2", src: Promotion4 },
			{ id: Enumerations.promoteContentType.middlePromotion3, title: "adminPanel.promotions.middlePromotion3", src: Promotion5 },
			{ id: Enumerations.promoteContentType.singlePageSideBarPromotion1, title: "adminPanel.promotions.sidebarPromotion1", src: Promotion6 },
			{ id: Enumerations.promoteContentType.singlePageSideBarPromotion2, title: "adminPanel.promotions.sidebarPromotion2", src: Promotion7 },
			{ id: Enumerations.promoteContentType.blogAndNewsSideBarPromotion1, title: "adminPanel.promotions.blogNewsPromotion1", src: Promotion8 },
			{ id: Enumerations.promoteContentType.blogAndNewsSideBarPromotion2, title: "adminPanel.promotions.blogNewsPromotion2", src: Promotion9 },
			{ id: Enumerations.promoteContentType.homeAboveTheAboutPromotion1, title: "adminPanel.promotions.homeAboveTheAboutPromotion1", src: homeAboveTheAboutPromotion1 },
			{ id: Enumerations.promoteContentType.homeAboveTheAboutPromotion2, title: "adminPanel.promotions.homeAboveTheAboutPromotion2", src: homeAboveTheAboutPromotion2 },
			{ id: Enumerations.promoteContentType.homeAboveTheAboutPromotion3, title: "adminPanel.promotions.homeAboveTheAboutPromotion3", src: homeAboveTheAboutPromotion3 },
		]);
	}

	static getGender() {
		return ([
			{ id: 1, name: getTranslatedRawText('components.genderType.male') },
			{ id: 2, name: getTranslatedRawText('components.genderType.female') }
		]);
	}

	static getOrderType() {
		return ([
			{ id: Enumerations.orderType.online, name: getTranslatedRawText('components.orderType.online') },
			{ id: Enumerations.orderType.inPerson, name: getTranslatedRawText('components.orderType.inPerson') }
		]);
	}

	static getLogType() {
		return ([
			{ id: Enumerations.customLogType.generalException, name: getTranslatedRawText('adminPanel.systemMonitoring.generalException') },
			{ id: Enumerations.customLogType.nullException, name: getTranslatedRawText('adminPanel.systemMonitoring.nullException') }
		]);
	}
	static getLogTypeLabel(id) {
		switch (id) {
			case Enumerations.customLogType.generalException: return getTranslatedRawText('adminPanel.systemMonitoring.generalException')
			case Enumerations.customLogType.nullException: return getTranslatedRawText('adminPanel.systemMonitoring.nullException')
		};
	}

	static getOrderApplicationType(id) {
		switch (id) {
			case 1: return [
				{ id: Enumerations.orderApplicationType.regular, name: getTranslatedRawText('components.orderApplicationType.regular') },
				{ id: Enumerations.orderApplicationType.professional, name: getTranslatedRawText('components.orderApplicationType.professional') }
			]
			case 2: return [
				{ id: Enumerations.orderApplicationType.nas, name: getTranslatedRawText('components.orderApplicationType.nas') },
			]
			default: return [
				{ id: Enumerations.orderApplicationType.regular, name: getTranslatedRawText('components.orderApplicationType.regular') },
				{ id: Enumerations.orderApplicationType.nas, name: getTranslatedRawText('components.orderApplicationType.nas') },
				{ id: Enumerations.orderApplicationType.professional, name: getTranslatedRawText('components.orderApplicationType.professional') }
			]
		}
	}

	static getOrderApplicationTypeById(id) {
		switch (id) {
			case Enumerations.orderApplicationType.regular: return getTranslatedRawText('components.orderApplicationType.regular')
			case Enumerations.orderApplicationType.nas: return getTranslatedRawText('components.orderApplicationType.nas')
			case Enumerations.orderApplicationType.professional: return getTranslatedRawText('components.orderApplicationType.professional')
		}
	}

	static getAccountType() {
		return ([
			{ id: Enumerations.accountType.personal, name: getTranslatedRawText('components.accountType.personal') },
			{ id: Enumerations.accountType.business, name: getTranslatedRawText('components.accountType.business') }
		]);
	}

	static getAccountStatus() {
		return ([
			{ id: false, name: getTranslatedRawText('adminPanel.usersInfo.block') },
			{ id: true, name: getTranslatedRawText('adminPanel.usersInfo.active') },
		]);
	}

	static getAgreementStatus() {
		return ([
			{ id: false, name: getTranslatedRawText('components.advanceSearch.disAgree') },
			{ id: true, name: getTranslatedRawText('components.advanceSearch.agree') },
		]);
	}

	static getActivation() {
		return ([
			{ key: Enumerations.activation.active, value: getTranslatedRawText('profile.address.active') },
			{ key: Enumerations.activation.inactive, value: getTranslatedRawText('profile.address.inactive') },
		]);
	}

	static getMobileActivation() {
		return ([
			{ id: false, name: getTranslatedRawText('adminPanel.usersInfo.notConfirmed') },
			{ id: true, name: getTranslatedRawText('adminPanel.usersInfo.confirmed') },
		]);
	}

	static getOnOffSwitch() {
		return ([
			{ key: Enumerations.switchValues.off, value: getTranslatedRawText("common.off") },
			{ key: Enumerations.switchValues.on, value: getTranslatedRawText("common.on") }
		]);
	}

	static getKarajTehranSwitch() {
		return ([
			{ key: Enumerations.city.tehran, value: getTranslatedRawText("profile.actionButton.tehran") },
			{ key: Enumerations.city.karaj, value: getTranslatedRawText("profile.actionButton.karaj") }
		]);
	}

	static foldLineDirections() {
		return ([
			{ key: Enumerations.foldLineDirections.horizontal, value: getTranslatedRawText('regularOrder.common.horizontal') },
			{ key: Enumerations.foldLineDirections.vertical, value: getTranslatedRawText('regularOrder.common.vertical') }
		])
	}

	static foldLineShapes() {
		return ([
			{ key: Enumerations.foldLineShapes.convex, value: getTranslatedRawText('regularOrder.common.convex') },
			{ key: Enumerations.foldLineShapes.concave, value: getTranslatedRawText('regularOrder.common.concave') }
		])
	}

	static getUseFileInOrderOptions() {
		return ([
			{ key: 2, status: true, value: getTranslatedRawText("common.yes") },
			{ key: 1, status: false, value: getTranslatedRawText("common.no") },
		]);
	}

	static getDateRanges() {
		return ([
			{ key: Enumerations.dateRangeType.daily, value: getTranslatedRawText('adminPanel.dateRange.daily') },
			{ key: Enumerations.dateRangeType.monthly, value: getTranslatedRawText('adminPanel.dateRange.monthly') },
			{ key: Enumerations.dateRangeType.yearly, value: getTranslatedRawText('adminPanel.dateRange.yearly') }
		])
	}

	static getRoundedCornerOptions(price) {
		return ([
			{
				id: Enumerations.roundedCorner.notCurved,
				key: Enumerations.roundedCorner.notCurved,
				label: getTranslatedRawText('regularOrder.extendedService.squareCorners'),
				value: getTranslatedRawText('regularOrder.extendedService.square'),
				description: getTranslatedRawText('common.noDescription'),
				icon: 'ns-icon-square-corner',
				price: 0,
			},
			{
				id: Enumerations.roundedCorner.curved,
				key: Enumerations.roundedCorner.curved,
				label: getTranslatedRawText('regularOrder.extendedService.roundedCorners'),
				value: getTranslatedRawText('regularOrder.extendedService.rounded'),
				description: getTranslatedRawText('common.noDescription'),
				icon: 'ns-icon-rounded-corner',
				price: price,
			},
		]);
	}
	static getGripperEdgeOptions(price) {
		return ([
			{
				id: Enumerations.gripperEdge.withoutGripperEdge,
				key: Enumerations.gripperEdge.withoutGripperEdge,
				label: getTranslatedRawText('regularOrder.extendedService.withoutGripperEdge'),
				value: getTranslatedRawText('regularOrder.extendedService.withoutGripperEdge'),
				description: getTranslatedRawText('common.noDescription'),
				icon: 'ns-icon-without-grappler',
				price: 0,
			},
			{
				id: Enumerations.gripperEdge.withGripperEdge,
				key: Enumerations.gripperEdge.withGripperEdge,
				label: getTranslatedRawText('regularOrder.extendedService.withGripperEdge'),
				value: getTranslatedRawText('regularOrder.extendedService.withGripperEdge'),
				description: getTranslatedRawText('regularOrder.extendedService.gripperEdgeDescription'),
				icon: 'ns-icon-with-grappler',
				price: price,
			},
		]);
	}

	static getBagHandleOptions(price) {
		return ([
			{
				id: Enumerations.handleType.withoutHandle,
				key: Enumerations.handleType.withoutHandle,
				label: getTranslatedRawText('regularOrder.extendedService.none'),
				value: getTranslatedRawText('regularOrder.extendedService.none'),
				description: getTranslatedRawText('common.noDescription'),
				img: handle_none,
				price: 0,
			},
			{
				id: Enumerations.handleType.flatHandle,
				key: Enumerations.handleType.flatHandle,
				label: getTranslatedRawText('regularOrder.extendedService.flatHandle'),
				value: getTranslatedRawText('regularOrder.extendedService.flatHandle'),
				description: getTranslatedRawText('common.noDescription'),
				img: flatHandle,
				price: price,
			},
			{
				id: Enumerations.handleType.twistHanle,
				key: Enumerations.handleType.twistHanle,
				label: getTranslatedRawText('regularOrder.extendedService.twistHandle'),
				value: getTranslatedRawText('regularOrder.extendedService.twistHandle'),
				description: getTranslatedRawText('common.noDescription'),
				img: twistedHandle,
				price: price,
			},
		]);
	}

	static getBagTopFoldingOptions(price) {
		return ([
			{
				id: Enumerations.topFolding.notHas,
				key: Enumerations.topFolding.notHas,
				label: getTranslatedRawText('regularOrder.extendedService.none'),
				value: getTranslatedRawText('regularOrder.extendedService.none'),
				description: getTranslatedRawText('common.noDescription'),
				img: topFolding_none,
				price: 0,
			},
			{
				id: Enumerations.topFolding.has,
				key: Enumerations.topFolding.has,
				label: getTranslatedRawText('regularOrder.extendedService.hasTopFolding'),
				value: getTranslatedRawText('regularOrder.extendedService.hasTopFolding'),
				description: getTranslatedRawText('common.noDescription'),
				img: topFolding,
				price: price,
			},
		]);
	}

	static getBillBookLanguageType() {
		return ([
			{ id: Enumerations.billBookNumberLanguageType.persian, label: getTranslatedRawText('regularOrder.billBook.persian') },
			{ id: Enumerations.billBookNumberLanguageType.english, label: getTranslatedRawText('regularOrder.billBook.english') },
		]);
	}

	static getBillBookNumberInSeriesTypes() {
		return ([
			{ id: Enumerations.billBookNumberInSeriesTypes.restartAtStartNumber, label: getTranslatedRawText('regularOrder.billBook.restartNumbering') },
			{ id: Enumerations.billBookNumberInSeriesTypes.continueNumbering, label: getTranslatedRawText('regularOrder.billBook.continueNumbering') },
		]);
	}

	static getBillBookBindingType() {
		return ([
			{ id: Enumerations.billBookBindingType.free, label: getTranslatedRawText('regularOrder.billBook.free'), icon: 'ns-icon-free-papers' },
			{ id: Enumerations.billBookBindingType.glue, label: getTranslatedRawText('regularOrder.billBook.glue'), icon: 'ns-icon-Glue-Binding' },
			{ id: Enumerations.billBookBindingType.stapler, label: getTranslatedRawText('regularOrder.billBook.stapler'), icon: 'ns-icon-Stitching' },
		]);
	}

	static getBillBookBindingSideType() {
		return ([
			{ id: Enumerations.billBookBindingSideType.up, label: getTranslatedRawText('regularOrder.billBook.up'), icon: 'ns-icon-up-binding' },
			{ id: Enumerations.billBookBindingSideType.right, label: getTranslatedRawText('regularOrder.billBook.right'), icon: 'ns-icon-right-binding' },
			{ id: Enumerations.billBookBindingSideType.down, label: getTranslatedRawText('regularOrder.billBook.down'), icon: 'ns-icon-down-binding' },
			{ id: Enumerations.billBookBindingSideType.left, label: getTranslatedRawText('regularOrder.billBook.left'), icon: 'ns-icon-left-binding' },
		]);
	}

	static getPrintedSide() {
		return ([
			{ id: 1, label: getTranslatedRawText('regularOrder.header.frontOnly') },
			{ id: 2, label: getTranslatedRawText('regularOrder.header.frontAndBack') }
		]);
	}

	static getTurnarounds() {
		return ([
			{ id: Enumerations.turnaround.normal, key: Enumerations.turnaround.normal, value: getTranslatedRawText('regularOrder.header.normal'), label: getTranslatedRawText('regularOrder.header.normal') },
			{ id: Enumerations.turnaround.express, key: Enumerations.turnaround.express, value: getTranslatedRawText('regularOrder.header.express'), label: getTranslatedRawText('regularOrder.header.express') },
		]);
	}

	static getSortItems() {
		return ([
			{ label: getTranslatedRawText('product.sortItems.lowestPrice'), id: Enumerations.sortItems.lowestPrice },
			{ label: getTranslatedRawText('product.sortItems.highestPrice'), id: Enumerations.sortItems.highestPrice },
			Config.homeSection.showBestSellerProducts && { label: getTranslatedRawText('product.sortItems.bestSelling'), id: Enumerations.sortItems.bestSelling },
			{ label: getTranslatedRawText('product.sortItems.newest'), id: Enumerations.sortItems.newest },
		]);
	}

	static getDownloadGuideItems() {
		return ([
			{ key: 1, value: getTranslatedRawText("common.no"), path: '#', onclick: () => { } },
			{ key: 2, value: getTranslatedRawText("common.yes"), path: '#', onclick: () => { } }
		]);
	}

	static getHomeAnnouncementTabs() {
		return ([
			{ id: Enumerations.announcementType.weekly, label: getTranslatedRawText('adminPanel.sidebar.news') },
			{ id: Enumerations.announcementType.monthly, label: getTranslatedRawText('home.bannerSection.announcement') },
		]);
	}

	static getStoreDetailTabs() {
		return ([
			{ id: 1, label: getTranslatedRawText('singlePage.description') },
			{ id: 2, label: getTranslatedRawText('singlePage.overview') },
			{ id: 3, label: getTranslatedRawText('product.store.similarProducts') },
		]);
	}

	static getUserOrderPreparingTabs() {
		return ([
			{ id: Enumerations.preparingOrderStateType.preparing, label: getTranslatedRawText('profile.orderPreparing.preparing'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.preparing) },
			{ id: Enumerations.preparingOrderStateType.failed, label: getTranslatedRawText('profile.orderPreparing.failed'), hasBadgeCount: true, url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.preparingFailed) },
		]);
	}

	static getUserOrderInProcessingTabs() {
		return ([
			{ id: null, label: getTranslatedRawText('profile.orderInProcess.all'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrder) },
			{ id: Enumerations.sharedSheetOrderStateType.layouting, label: getTranslatedRawText('profile.orderInProcess.layouting'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrderLayouting) },
			{ id: Enumerations.sharedSheetOrderStateType.lithography, label: getTranslatedRawText('profile.orderInProcess.lithography'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrderLithography) },
			{ id: Enumerations.sharedSheetOrderStateType.printing, label: getTranslatedRawText('profile.orderInProcess.printing'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrderPrinting) },
			{ id: Enumerations.sharedSheetOrderStateType.cutting, label: getTranslatedRawText('profile.orderInProcess.cutting'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrderCutting) },
		]);
	}

	static getApAgreementsTabs() {
		return ([
			{ id: Enumerations.staticPageType.termOfUse, label: getTranslatedRawText('footer.termOfUse') },
			{ id: Enumerations.staticPageType.privacyPolicy, label: getTranslatedRawText('footer.privacyPolicy') },
			{ id: Enumerations.staticPageType.warrantyTerms, label: getTranslatedRawText('footer.warrantyTerms') },
		]);
	}

	static getApCommentTabs() {
		return ([
			{ id: 0, label: getTranslatedRawText('adminPanel.orderInProcess.blog') },
			{ id: 1, label: getTranslatedRawText('profile.orderInProcess.news') },
			{ id: 2, label: getTranslatedRawText('profile.orderInProcess.products') },
		]);
	}

	static getUserOrderReadyToDeliverTabs(isSharedSheetOrder) {
		return ([
			!!isSharedSheetOrder && { key: Enumerations.SharedSheetOrderReadyToDeliverStateType.transferToWarehouse, id: Enumerations.sharedSheetOrderStateType.transferToWarehouse, label: getTranslatedRawText('profile.readyToDeliver.transferToWarehouse'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.readyToDeliverOrderTransferToWarehouse) },
			{ key: Enumerations.SharedSheetOrderReadyToDeliverStateType.inTheWarehouse, id: Enumerations.sharedSheetOrderStateType.ready, label: getTranslatedRawText('profile.readyToDeliver.inTheWarehouse'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.readyToDeliverOrderInTheWarehouse) },
			{ key: Enumerations.SharedSheetOrderReadyToDeliverStateType.inTransit, id: Enumerations.sharedSheetOrderStateType.ready, label: getTranslatedRawText('profile.readyToDeliver.inTransit'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.readyToDeliverOrderInTransit) },
		]);
	}

	static getStoreOrderReadyToDeliverTabs() {
		return ([
			{ key: Enumerations.SharedSheetOrderReadyToDeliverStateType.inTheWarehouse, id: Enumerations.sharedSheetOrderStateType.ready, label: getTranslatedRawText('profile.readyToDeliver.inTheWarehouse'), url: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.readyToDeliverOrderInTheWarehouse) },
			{ key: Enumerations.SharedSheetOrderReadyToDeliverStateType.inTransit, id: Enumerations.sharedSheetOrderStateType.ready, label: getTranslatedRawText('profile.readyToDeliver.inTransit'), url: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.readyToDeliverOrderInTransit) },
		]);
	}

	static getUserOrderArchiveTabs() {
		return ([
			{ id: Enumerations.sharedSheetOrderStateType.delivered, label: getTranslatedRawText('profile.orderArchive.delivered'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.archiveOrder) },
			{ id: Enumerations.sharedSheetOrderStateType.canceled, label: getTranslatedRawText('profile.orderArchive.canceled'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.archiveOrderCanceled) },
		]);
	}
	static getStoreOrderArchiveTabs() {
		return ([
			{ id: Enumerations.sharedSheetOrderStateType.delivered, label: getTranslatedRawText('profile.orderArchive.delivered'), url: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.archiveOrderDelivered) },
			{ id: Enumerations.sharedSheetOrderStateType.canceled, label: getTranslatedRawText('profile.orderArchive.canceled'), url: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.archiveOrderCanceled) },
		]);
	}

	static getDeliveryRequestTabs() {
		return ([
			{ id: Enumerations.transferStateType.inTheWarehouse, label: getTranslatedRawText('profile.deliveryRequest.inTheWarehouse'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequest) },
			{ id: Enumerations.transferStateType.exitingWareHouse, label: getTranslatedRawText('profile.deliveryRequest.exitingWareHouse'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequestExitingWarehouse) },
			{ id: Enumerations.transferStateType.sendingToCourier, label: getTranslatedRawText('profile.deliveryRequest.sentToWarehouse'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequestSendingToCourier) },
			{ id: Enumerations.transferStateType.deliveredToCourier, label: getTranslatedRawText('profile.deliveryRequest.deliveredToWarehouse'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequestDeliveredToCourier) },
			{ id: Enumerations.transferStateType.deliveredToCustomer, label: getTranslatedRawText('profile.deliveryRequest.deliveredToCustomer'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequestDeliveredToCustomer) },
		]);
	}

	static getOrderProductTabs(shareSheetAccess, storeAccess) {
		return CommonHelper.clearArrayFromEmptyItem([
			shareSheetAccess && { id: Enumerations.orderProductType.sharedSheet, label: getTranslatedRawText('profile.orderCart.groupSheetOrders'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.groupSheetOrderQueueStatus) },
			storeAccess && { id: Enumerations.orderProductType.store, label: getTranslatedRawText('profile.orderCart.storeOrders'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.storeOrderQueueStatus) },
		]);
	}

	static getCartOrderTabs() {
		return [
			{ id: Enumerations.orderProductType.sharedSheet, label: getTranslatedRawText('profile.orderCart.groupSheetOrders'), url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder) },
			{ id: Enumerations.orderProductType.store, label: getTranslatedRawText('profile.orderCart.storeOrders'), url: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.cartOrder) },
		];
	}

	static getFoldLineModalTabs() {
		return ([
			{ id: Enumerations.foldLineModalTab.preset, label: getTranslatedRawText('regularOrder.modal.preset') },
			{ id: Enumerations.foldLineModalTab.customized, label: getTranslatedRawText('regularOrder.modal.customized') }
		]);
	}

	static getProblematicPaymentsTabs() {
		return ([
			{ id: Enumerations.paymentType.increaseCredit, label: getTranslatedRawText('adminPanel.payments.increaseCredit') },
			{ id: Enumerations.paymentType.orderPayment, label: getTranslatedRawText('adminPanel.payments.orderPayment') }
		]);
	}

	static getNegativeAccountBalanceUsersTabs() {
		return CommonHelper.clearArrayFromEmptyItem([
			hasAccess([UserClaimEnumerations.getApDuRemainingLimitWithCredit]) && { id: Enumerations.negativeAccountBalanceUsers.withCredit, label: getTranslatedRawText('adminPanel.usersInfo.withGrantedCredit') },
			hasAccess([UserClaimEnumerations.getApDuRemainingLimitWithoutCredit]) && { id: Enumerations.negativeAccountBalanceUsers.withoutCredit, label: getTranslatedRawText('adminPanel.usersInfo.withoutGrantedCredit') },
			hasAccess([UserClaimEnumerations.getApDuGrantedCredit]) && { id: Enumerations.negativeAccountBalanceUsers.justGrantedCredit, label: getTranslatedRawText('adminPanel.usersInfo.justGrantedCredit') }
		]);
	}

	static sharedSheetOrderEstimatedQueueTurnaround() {
		return ([
			{ id: Enumerations.sharedSheetOrderEstimatedQueueTurnaround.none, label: getTranslatedRawText('adminPanel.common.all') },
			getConfigObjectByCulture(Config.sharedSheetOrderEstimatedQueue) && { id: Enumerations.sharedSheetOrderEstimatedQueueTurnaround.sixHours, label: getTranslatedRawText('adminPanel.orders.sixHours') },
			{ id: Enumerations.sharedSheetOrderEstimatedQueueTurnaround.express, label: getTranslatedRawText('regularOrder.header.express') },
			{ id: Enumerations.sharedSheetOrderEstimatedQueueTurnaround.normal, label: getTranslatedRawText('regularOrder.header.normal') },
		]);
	}

	static getFileExtensionType() {
		return ([
			{ key: Enumerations.fileExtension.ai, value: getTranslatedRawText('common.fileExtension.ai') },
			{ key: Enumerations.fileExtension.bmp, value: getTranslatedRawText('common.fileExtension.bmp') },
			{ key: Enumerations.fileExtension.csv, value: getTranslatedRawText('common.fileExtension.csv') },
			{ key: Enumerations.fileExtension.doc, value: getTranslatedRawText('common.fileExtension.doc') },
			{ key: Enumerations.fileExtension.docx, value: getTranslatedRawText('common.fileExtension.docx') },
			{ key: Enumerations.fileExtension.gif, value: getTranslatedRawText('common.fileExtension.gif') },
			{ key: Enumerations.fileExtension.gif, value: getTranslatedRawText('common.fileExtension.gif') },
			{ key: Enumerations.fileExtension.jpeg, value: getTranslatedRawText('common.fileExtension.jpeg') },
			{ key: Enumerations.fileExtension.jpg, value: getTranslatedRawText('common.fileExtension.jpg') },
			{ key: Enumerations.fileExtension.mp3, value: getTranslatedRawText('common.fileExtension.mp3') },
			{ key: Enumerations.fileExtension.pdf, value: getTranslatedRawText('common.fileExtension.pdf') },
			{ key: Enumerations.fileExtension.png, value: getTranslatedRawText('common.fileExtension.png') },
			{ key: Enumerations.fileExtension.psd, value: getTranslatedRawText('common.fileExtension.psd') },
			{ key: Enumerations.fileExtension.svg, value: getTranslatedRawText('common.fileExtension.svg') },
			{ key: Enumerations.fileExtension.rar, value: getTranslatedRawText('common.fileExtension.rar') },
			{ key: Enumerations.fileExtension.tif, value: getTranslatedRawText('common.fileExtension.tif') },
			{ key: Enumerations.fileExtension.txt, value: getTranslatedRawText('common.fileExtension.txt') },
			{ key: Enumerations.fileExtension.webp, value: getTranslatedRawText('common.fileExtension.webp') },
			{ key: Enumerations.fileExtension.xls, value: getTranslatedRawText('common.fileExtension.xls') },
			{ key: Enumerations.fileExtension.xlsx, value: getTranslatedRawText('common.fileExtension.xlsx') },
			{ key: Enumerations.fileExtension.zip, value: getTranslatedRawText('common.fileExtension.zip') },
			{ key: Enumerations.fileExtension.mp4, value: getTranslatedRawText('common.fileExtension.mp4') },
		])
	}

	static getFileExtensionTypeById(id) {
		switch (id) {
			case Enumerations.fileExtension.ai: return getTranslatedRawText('common.fileExtension.ai');
			case Enumerations.fileExtension.bmp: return getTranslatedRawText('common.fileExtension.bmp');
			case Enumerations.fileExtension.csv: return getTranslatedRawText('common.fileExtension.csv');
			case Enumerations.fileExtension.doc: return getTranslatedRawText('common.fileExtension.doc');
			case Enumerations.fileExtension.docx: return getTranslatedRawText('common.fileExtension.docx');
			case Enumerations.fileExtension.gif: return getTranslatedRawText('common.fileExtension.gif');
			case Enumerations.fileExtension.gif: return getTranslatedRawText('common.fileExtension.gif');
			case Enumerations.fileExtension.jpeg: return getTranslatedRawText('common.fileExtension.jpeg');
			case Enumerations.fileExtension.jpg: return getTranslatedRawText('common.fileExtension.jpg');
			case Enumerations.fileExtension.mp3: return getTranslatedRawText('common.fileExtension.mp3');
			case Enumerations.fileExtension.pdf: return getTranslatedRawText('common.fileExtension.pdf');
			case Enumerations.fileExtension.png: return getTranslatedRawText('common.fileExtension.png');
			case Enumerations.fileExtension.psd: return getTranslatedRawText('common.fileExtension.psd');
			case Enumerations.fileExtension.svg: return getTranslatedRawText('common.fileExtension.svg');
			case Enumerations.fileExtension.rar: return getTranslatedRawText('common.fileExtension.rar');
			case Enumerations.fileExtension.tif: return getTranslatedRawText('common.fileExtension.tif');
			case Enumerations.fileExtension.txt: return getTranslatedRawText('common.fileExtension.txt');
			case Enumerations.fileExtension.webp: return getTranslatedRawText('common.fileExtension.webp');
			case Enumerations.fileExtension.xls: return getTranslatedRawText('common.fileExtension.xls');
			case Enumerations.fileExtension.xlsx: return getTranslatedRawText('common.fileExtension.xlsx');
			case Enumerations.fileExtension.zip: return getTranslatedRawText('common.fileExtension.zip');
			case Enumerations.fileExtension.mp4: return getTranslatedRawText('common.fileExtension.mp4');
		}
	}

	static getSharedSheetOrderStateType(id) {
		switch (id) {
			case Enumerations.sharedSheetOrderStateType.notApplied: return getTranslatedRawText('adminPanel.orderStateType.notApplied');
			case Enumerations.sharedSheetOrderStateType.inFirstQueue: return getTranslatedRawText('adminPanel.orderStateType.inFirstQueue');
			case Enumerations.sharedSheetOrderStateType.prepare: return getTranslatedRawText('adminPanel.orderStateType.prepare');
			case Enumerations.sharedSheetOrderStateType.inProgress: return getTranslatedRawText('adminPanel.orderStateType.inProgress');
			case Enumerations.sharedSheetOrderStateType.layouting: return getTranslatedRawText('adminPanel.orderStateType.layouting');
			case Enumerations.sharedSheetOrderStateType.progressFail: return getTranslatedRawText('adminPanel.orderStateType.progressFail');
			case Enumerations.sharedSheetOrderStateType.prepareFail: return getTranslatedRawText('adminPanel.orderStateType.prepareFail');
			case Enumerations.sharedSheetOrderStateType.lithography: return getTranslatedRawText('adminPanel.orderStateType.lithography');
			case Enumerations.sharedSheetOrderStateType.printing: return getTranslatedRawText('adminPanel.orderStateType.printing');
			case Enumerations.sharedSheetOrderStateType.cutting: return getTranslatedRawText('adminPanel.orderStateType.cutting');
			case Enumerations.sharedSheetOrderStateType.ready: return getTranslatedRawText('adminPanel.orderStateType.ready');
			case Enumerations.sharedSheetOrderStateType.delivered: return getTranslatedRawText('adminPanel.orderStateType.delivered');
			case Enumerations.sharedSheetOrderStateType.inSecondQueue: return getTranslatedRawText('adminPanel.orderStateType.inSecondQueue');
			case Enumerations.sharedSheetOrderStateType.canceled: return getTranslatedRawText('adminPanel.orderStateType.canceled');
			case Enumerations.sharedSheetOrderStateType.transferToWarehouse: return getTranslatedRawText('adminPanel.orderStateType.transferToWarehouse');
		}
	}

	static getAllStatusesOrder() {
		return ([
			{ id: Enumerations.sharedSheetOrderStateType.notApplied, label: getTranslatedRawText('adminPanel.orderStateType.notApplied') },
			{ id: Enumerations.sharedSheetOrderStateType.inFirstQueue, label: getTranslatedRawText('adminPanel.orderStateType.inFirstQueue') },
			{ id: Enumerations.sharedSheetOrderStateType.prepare, label: getTranslatedRawText('adminPanel.orderStateType.prepare') },
			{ id: Enumerations.sharedSheetOrderStateType.inProgress, label: getTranslatedRawText('adminPanel.orderStateType.inProgress') },
			{ id: Enumerations.sharedSheetOrderStateType.layouting, label: getTranslatedRawText('adminPanel.orderStateType.layouting') },
			{ id: Enumerations.sharedSheetOrderStateType.progressFail, label: getTranslatedRawText('adminPanel.orderStateType.progressFail') },
			{ id: Enumerations.sharedSheetOrderStateType.prepareFail, label: getTranslatedRawText('adminPanel.orderStateType.prepareFail') },
			{ id: Enumerations.sharedSheetOrderStateType.lithography, label: getTranslatedRawText('adminPanel.orderStateType.lithography') },
			{ id: Enumerations.sharedSheetOrderStateType.printing, label: getTranslatedRawText('adminPanel.orderStateType.printing') },
			{ id: Enumerations.sharedSheetOrderStateType.cutting, label: getTranslatedRawText('adminPanel.orderStateType.cutting') },
			{ id: Enumerations.sharedSheetOrderStateType.ready, label: getTranslatedRawText('adminPanel.orderStateType.ready') },
			{ id: Enumerations.sharedSheetOrderStateType.delivered, label: getTranslatedRawText('adminPanel.orderStateType.delivered') },
			{ id: Enumerations.sharedSheetOrderStateType.inSecondQueue, label: getTranslatedRawText('adminPanel.orderStateType.inSecondQueue') },
			{ id: Enumerations.sharedSheetOrderStateType.canceled, label: getTranslatedRawText('adminPanel.orderStateType.canceled') },
			{ id: Enumerations.sharedSheetOrderStateType.transferToWarehouse, label: getTranslatedRawText('adminPanel.orderStateType.transferToWarehouse') },
		])
	}

	static getStoreStatusesOrder() {
		return ([
			{ id: Enumerations.sharedSheetOrderStateType.notApplied, label: getTranslatedRawText('adminPanel.orderStateType.notApplied') },
			{ id: Enumerations.sharedSheetOrderStateType.inFirstQueue, label: getTranslatedRawText('adminPanel.orderStateType.inFirstQueue') },
			{ id: Enumerations.sharedSheetOrderStateType.prepare, label: getTranslatedRawText('adminPanel.orderStateType.prepare') },
			{ id: Enumerations.sharedSheetOrderStateType.ready, label: getTranslatedRawText('adminPanel.orderStateType.ready') },
			{ id: Enumerations.sharedSheetOrderStateType.delivered, label: getTranslatedRawText('adminPanel.orderStateType.delivered') },
			{ id: Enumerations.sharedSheetOrderStateType.canceled, label: getTranslatedRawText('adminPanel.orderStateType.canceled') },
		])
	}

	static getOrderStatusPageNameList() {
		return ([
			{
				id: Enumerations.sharedSheetOrderStateType.notApplied, label: getTranslatedRawText('profile.actionButton.cart'), sharedSheetOrderStateTypes: [
					Enumerations.sharedSheetOrderStateType.notApplied
				]
			},
			{
				id: Enumerations.sharedSheetOrderStateType.prepare, label: getTranslatedRawText('profile.actionButton.preparing'), sharedSheetOrderStateTypes: [
					Enumerations.sharedSheetOrderStateType.prepare,
					Enumerations.sharedSheetOrderStateType.inFirstQueue,
					Enumerations.sharedSheetOrderStateType.inSecondQueue,
					Enumerations.sharedSheetOrderStateType.inProgress,
					Enumerations.sharedSheetOrderStateType.progressFail,
					Enumerations.sharedSheetOrderStateType.prepareFail,
				]
			},
			{
				id: Enumerations.sharedSheetOrderStateType.inProgress, label: getTranslatedRawText('profile.actionButton.inProcess'), sharedSheetOrderStateTypes: [
					Enumerations.sharedSheetOrderStateType.layouting,
					Enumerations.sharedSheetOrderStateType.lithography,
					Enumerations.sharedSheetOrderStateType.printing,
					Enumerations.sharedSheetOrderStateType.cutting,
				]
			},
			{
				id: Enumerations.sharedSheetOrderStateType.ready, label: getTranslatedRawText('profile.actionButton.readyToDeliver'), sharedSheetOrderStateTypes: [
					Enumerations.sharedSheetOrderStateType.ready,
					Enumerations.sharedSheetOrderStateType.transferToWarehouse,
				]
			},
			{
				id: Enumerations.sharedSheetOrderStateType.delivered, label: getTranslatedRawText('profile.actionButton.archive'), sharedSheetOrderStateTypes: [
					Enumerations.sharedSheetOrderStateType.delivered,
					Enumerations.sharedSheetOrderStateType.canceled,
				]
			},
		])
	}

	static getPageNameBasedOnId(id) {
		switch (id) {
			case Enumerations.sharedSheetOrderStateType.notApplied: return getTranslatedRawText('profile.orderStateType.cart');
			case Enumerations.sharedSheetOrderStateType.inFirstQueue: return getTranslatedRawText('profile.orderStateType.preparing');
			case Enumerations.sharedSheetOrderStateType.prepare: return getTranslatedRawText('profile.orderStateType.preparing');
			case Enumerations.sharedSheetOrderStateType.inProgress: return getTranslatedRawText('profile.orderStateType.preparing');
			case Enumerations.sharedSheetOrderStateType.layouting: return getTranslatedRawText('profile.orderStateType.inProcess');
			case Enumerations.sharedSheetOrderStateType.progressFail: return getTranslatedRawText('profile.orderStateType.preparing');
			case Enumerations.sharedSheetOrderStateType.prepareFail: return getTranslatedRawText('profile.orderStateType.preparing');
			case Enumerations.sharedSheetOrderStateType.lithography: return getTranslatedRawText('profile.orderStateType.inProcess');
			case Enumerations.sharedSheetOrderStateType.printing: return getTranslatedRawText('profile.orderStateType.inProcess');
			case Enumerations.sharedSheetOrderStateType.cutting: return getTranslatedRawText('profile.orderStateType.inProcess');
			case Enumerations.sharedSheetOrderStateType.ready: return getTranslatedRawText('profile.orderStateType.readyToDeliver');
			case Enumerations.sharedSheetOrderStateType.delivered: return getTranslatedRawText('profile.orderStateType.archive');
			case Enumerations.sharedSheetOrderStateType.inSecondQueue: return getTranslatedRawText('profile.orderStateType.preparing');
			case Enumerations.sharedSheetOrderStateType.canceled: return getTranslatedRawText('profile.orderStateType.archive');
			case Enumerations.sharedSheetOrderStateType.transferToWarehouse: return getTranslatedRawText('profile.orderStateType.transferToWarehouse');
		}
	}

	static getMonthName(id) {
		switch (id) {
			case Enumerations.months.January: return getTranslatedRawText('product.months.january');
			case Enumerations.months.February: return getTranslatedRawText('product.months.february');
			case Enumerations.months.March: return getTranslatedRawText('product.months.march');
			case Enumerations.months.April: return getTranslatedRawText('product.months.april');
			case Enumerations.months.May: return getTranslatedRawText('product.months.may');
			case Enumerations.months.June: return getTranslatedRawText('product.months.june');
			case Enumerations.months.July: return getTranslatedRawText('product.months.july');
			case Enumerations.months.August: return getTranslatedRawText('product.months.august');
			case Enumerations.months.September: return getTranslatedRawText('product.months.september');
			case Enumerations.months.October: return getTranslatedRawText('product.months.october');
			case Enumerations.months.November: return getTranslatedRawText('product.months.november');
			case Enumerations.months.December: return getTranslatedRawText('product.months.december');
		}
	}

	static getFileCheckingStatus = (id) => {
		switch (id) {
			case Enumerations.fileCheckingStatus.notChecked: return getTranslatedRawText('profile.fileCheckingStatus.notChecked')
			case Enumerations.fileCheckingStatus.pending: return getTranslatedRawText('profile.fileCheckingStatus.pending')
			case Enumerations.fileCheckingStatus.applyChanged: return getTranslatedRawText('profile.fileCheckingStatus.applyChanged')
			case Enumerations.fileCheckingStatus.rejected: return getTranslatedRawText('profile.fileCheckingStatus.rejected')
		}
	}

	static getDeliveryRequestStateType(id) {
		switch (id) {
			case Enumerations.transferStateType.inTheWarehouse: return getTranslatedRawText('profile.deliveryRequest.inTheWarehouse');
			case Enumerations.transferStateType.exitingWareHouse: return getTranslatedRawText('profile.deliveryRequest.exitingWareHouse');
			case Enumerations.transferStateType.sendingToCourier: return getTranslatedRawText('profile.deliveryRequest.sendingToCourier');
			case Enumerations.transferStateType.deliveredToCourier: return getTranslatedRawText('profile.deliveryRequest.deliveredToCourier');
			case Enumerations.transferStateType.deliveredToCustomer: return getTranslatedRawText('profile.deliveryRequest.deliveredToCustomer');
		}
	}

	static getOurCompanyMenus() {
		return ([
			{ id: Enumerations.breadCrumbs.aboutUs, label: 'footer.aboutUs', link: Links.aboutUs, baseLink: Links },
			{ id: Enumerations.breadCrumbs.contactUs, label: 'footer.contactUs', link: Links.contactUs, baseLink: Links },
			{ id: Enumerations.breadCrumbs.ourEquipment, label: 'footer.ourEquipment', link: Links.ourEquipment, baseLink: Links },
			{ id: Enumerations.breadCrumbs.festivals, label: 'footer.festivals', link: Links.festivals, baseLink: Links },
			getConfigObjectByCulture(Config.showCashback) && { id: Enumerations.breadCrumbs.cashback, label: 'footer.cashback', link: Links.view.main.userDashboard.cashback, baseLink: Links.view.main.userDashboard },
			isEnCulture() && { id: Enumerations.breadCrumbs.specialNeeds, label: 'footer.specialNeeds', link: Links.specialNeeds, baseLink: Links },
		]);
	}

	static getCustomerServiceMenus() {
		return ([
			{ id: Enumerations.breadCrumbs.faqs, label: 'footer.fAQs', link: Links.frequentlyAskedQuestions, baseLink: Links },
			{ id: Enumerations.breadCrumbs.termOfUse, label: 'footer.termOfUse', link: Links.termsOfUse, baseLink: Links },
			{ id: Enumerations.breadCrumbs.privacyPolicy, label: 'footer.privacyPolicy', link: Links.privacyPolicy, baseLink: Links },
			{ id: Enumerations.breadCrumbs.warrantyTerms, label: 'footer.warrantyTerms', link: Links.warrantyTerms, baseLink: Links },
		]);
	}

	static getResourcesMenus() {
		return ([
			{ id: Enumerations.breadCrumbs.siteMap, label: 'footer.siteMap', link: Links.siteMap, baseLink: Links },
			{ id: Enumerations.breadCrumbs.gallery, label: 'footer.gallery', link: Links.gallery, baseLink: Links },
			{ id: Enumerations.breadCrumbs.downloadCenter, label: 'footer.downloadTemplate', link: Links.downloadCenter, baseLink: Links },
			false && { id: Enumerations.breadCrumbs.saveOutputInPdfFormat, label: 'common.breadcrumbs.saveOutputInPdfFormat', link: Links.saveOutputInPdfFormat, baseLink: Links },
			{ id: 90, label: 'footer.requestSample', link: '', disable: true, baseLink: Links },
		]);
	}

	static getBlogsAndNewsMenus() {
		return ([
			{ id: Enumerations.breadCrumbs.blogs, label: 'adminPanel.sidebar.blog', link: Links.view.main.blog.blogs, baseLink: Links.view.main.blog },
			{ id: Enumerations.breadCrumbs.news, label: 'adminPanel.sidebar.news', link: Links.view.main.news.news, baseLink: Links.view.main.news },
		]);
	}

	static getPaymentGateways(hasAll) {
		let paymentGateways = [
			{ id: Enumerations.paymentGateway.asanPardakht, label: getTranslatedRawText('payment.gateways.asanPardakht'), link: 'settleAsanPardakhtPayment' },
			{ id: Enumerations.paymentGateway.behPardakht, label: getTranslatedRawText('payment.gateways.behPardakht'), link: 'not-set' },
			{ id: Enumerations.paymentGateway.iranKish, label: getTranslatedRawText('payment.gateways.iranKish'), link: 'not-set' },
			{ id: Enumerations.paymentGateway.sadad, label: getTranslatedRawText('payment.gateways.sadad'), link: 'not-set' },
			{ id: Enumerations.paymentGateway.saman, label: getTranslatedRawText('payment.gateways.saman'), link: 'not-set' },
			{ id: Enumerations.paymentGateway.nGeniusPayment, label: getTranslatedRawText('payment.gateways.nGeniusPayment'), link: 'settleNgeniusPayment' },
			{ id: Enumerations.paymentGateway.sepehr, label: getTranslatedRawText('payment.gateways.sepehr'), link: 'settleSepehrPayment' },
		]

		hasAll && paymentGateways.unshift({ id: 10, label: getTranslatedRawText('profile.orderInProcess.all') })

		return paymentGateways;
	}

	static getPaymentAuditMatchingTypes() {
		return [
			{
				id: Enumerations.paymentAuditMatchingType.notProcessed,
				label: getTranslatedRawText('adminPanel.audit.notProcessed')
			},
			{
				id: Enumerations.paymentAuditMatchingType.paymentNotFound,
				label: getTranslatedRawText('adminPanel.audit.paymentNotFound')
			},
			{
				id: Enumerations.paymentAuditMatchingType.amountMismatch,
				label: getTranslatedRawText('adminPanel.audit.amountMismatch')
			},
			{
				id: Enumerations.paymentAuditMatchingType.stateTypeMismatch,
				label: getTranslatedRawText('adminPanel.audit.stateTypeMismatch')
			},
			{
				id: Enumerations.paymentAuditMatchingType.amountAndStateTypeMismatch,
				label: getTranslatedRawText('adminPanel.audit.amountAndStateTypeMismatch')
			},
			{
				id: Enumerations.paymentAuditMatchingType.matched,
				label: getTranslatedRawText('adminPanel.audit.matched')
			},
			{
				id: Enumerations.paymentAuditMatchingType.alternativePaymentAttempt,
				label: getTranslatedRawText('adminPanel.audit.alternativePaymentAttempt')
			},
		];
	}

	static getPaymentStatus(hasAll) {
		let paymentStatus = [
			{ id: Enumerations.paymentStateType.success, label: getTranslatedRawText('payment.states.success') },
			{ id: Enumerations.paymentStateType.failed, label: getTranslatedRawText('payment.states.failed') },
			{ id: Enumerations.paymentStateType.open, label: getTranslatedRawText('payment.states.open') },
		]

		hasAll && paymentStatus.unshift({ id: 10, label: getTranslatedRawText('profile.orderInProcess.all') })
		return (paymentStatus);
	}

	static getAgencyTotalSalesAmountType() {
		return ([
			{ id: 10, label: getTranslatedRawText('profile.orderInProcess.all') },
			{ id: Enumerations.agencyTotalSalesAmountType.agency, label: getTranslatedRawText('adminPanel.usersInfo.agency') },
			{ id: Enumerations.agencyTotalSalesAmountType.company, label: getTranslatedRawText('adminPanel.usersInfo.company') },
		]);
	}

	static getContentVisibilityOptions() {
		return ([
			{
				id: Enumerations.contentVisibilityType.all,
				key: Enumerations.contentVisibilityType.all,
				label: getTranslatedRawText('profile.orderInProcess.all'),
				value: getTranslatedRawText('profile.orderInProcess.all'),
			},
			{
				id: Enumerations.contentVisibilityType.signedInUser,
				label: getTranslatedRawText('adminPanel.agency.signedInUser'),
				key: Enumerations.contentVisibilityType.signedInUser,
				value: getTranslatedRawText('adminPanel.agency.signedInUser'),
			},
			{
				id: Enumerations.contentVisibilityType.signedInUserWithoutAgency,
				label: getTranslatedRawText('adminPanel.agency.signedInUserWithoutAgency'),
				key: Enumerations.contentVisibilityType.signedInUserWithoutAgency,
				value: getTranslatedRawText('adminPanel.agency.signedInUserWithoutAgency'),
			},
			{
				id: Enumerations.contentVisibilityType.signedInUserWithSpecificAgencies,
				label: getTranslatedRawText('adminPanel.agency.signedInUserWithSpecificAgencies'),
				key: Enumerations.contentVisibilityType.signedInUserWithSpecificAgencies,
				value: getTranslatedRawText('adminPanel.agency.signedInUserWithSpecificAgencies'),
			},
			{
				id: Enumerations.contentVisibilityType.guest,
				label: getTranslatedRawText('adminPanel.common.guest'),
				key: Enumerations.contentVisibilityType.guest,
				value: getTranslatedRawText('adminPanel.common.guest'),
			},
		]);
	}

	static getHistoryPaymentStatus() {
		return ([
			{ id: Enumerations.paymentStateType.success, label: getTranslatedRawText('payment.states.success') },
			{ id: Enumerations.paymentStateType.failed, label: getTranslatedRawText('payment.states.failed') },
		]);
	}

	static getPaymentType() {
		return ([
			{ id: Enumerations.paymentType.increaseCredit, label: getTranslatedRawText('payment.types.increaseCredit') },
			{ id: Enumerations.paymentType.orderPayment, label: getTranslatedRawText('payment.types.orderPayment') },
		]);
	}

	static getFileManagerEditFileModalTabs() {
		return ([
			{ id: 1, label: getTranslatedRawText('fileManager.modals.editFile.position') },
			{ id: 2, label: getTranslatedRawText('fileManager.modals.editFile.resize') },
			{ id: 3, label: getTranslatedRawText('fileManager.modals.editFile.rotate') },
			{ id: 4, label: getTranslatedRawText('fileManager.modals.editFile.flip') },
		]);
	}

	static getAttachFileTabs() {
		return ([
			{ id: Enumerations.attachType.attachLink, label: getTranslatedRawText('adminPanel.download.attachLink') },
			{ id: Enumerations.attachType.uploadFile, label: getTranslatedRawText('adminPanel.download.uploadFile') },
		]);
	}

	static getFestivalInvoiceType(id) {
		switch (id) {
			case Enumerations.festivalInvoiceType.sharedSheetOrder: return getTranslatedRawText('festival.groupSheetJobs');
			case Enumerations.festivalInvoiceType.dedicatedOrder: return getTranslatedRawText('festival.customizedSheetJobs');
			case Enumerations.festivalInvoiceType.otherProducts: return getTranslatedRawText('festival.readyProductJobs');
		}
	}

	static getFestivalSaleAppType(id) {
		switch (id) {
			case Enumerations.festivalSaleAppType.inPerson: return getTranslatedRawText('festival.inPerson');
			case Enumerations.festivalSaleAppType.online: return getTranslatedRawText('festival.online');
		};
	}

	static getBalanceType(id) {
		switch (id) {
			case Enumerations.balanceType.creditor: return getTranslatedRawText('profile.orderReport.creditor');
			case Enumerations.balanceType.debtor: return getTranslatedRawText('profile.orderReport.debtor');
		};
	}

	static getSortGrid(sort) {
		switch (sort) {
			case 'asc':
				return Enumerations.gridSortType.asc
			case 'desc':
				return Enumerations.gridSortType.desc

			default:
				return Enumerations.gridSortType.none
		}
	}

	static getSortGridById(id) {
		switch (id) {
			case Enumerations.gridSortType.asc:
				return 'asc'
			case Enumerations.gridSortType.desc:
				return 'desc'

			default:
				return null
		}
	}

	static getCustomersRole() {
		return ([
			{ id: 2, label: getTranslatedRawText('adminPanel.usersInfo.user') },
			{ id: 3, label: getTranslatedRawText('adminPanel.usersInfo.subUser') },
		])
	}

	static getMapTabs() {
		return ([
			{ id: Enumerations.maps.googleMap, label: getTranslatedRawText('profile.address.modal.googleMap') },
			{ id: Enumerations.maps.leaflet, label: getTranslatedRawText('profile.address.modal.leaflet') },
			{ id: Enumerations.maps.pigeon, label: getTranslatedRawText('profile.address.modal.pigeon') },
		])
	}

	static getEmbedCodeTabs() {
		return ([
			{ id: Enumerations.embedCodeFormat.wordPress, label: getTranslatedRawText('WordPress') },
			{ id: Enumerations.embedCodeFormat.iframe, label: getTranslatedRawText('iframe') },
			{ id: Enumerations.embedCodeFormat.script, label: getTranslatedRawText('Script') },
		])
	}

	static getAdminsRole() {
		return ([
			{ id: Enumerations.role.admin, label: getTranslatedRawText('adminPanel.usersInfo.admin') },
			{ id: Enumerations.role.supporter, label: getTranslatedRawText('adminPanel.usersInfo.supporter') },
			{ id: Enumerations.role.agency, label: getTranslatedRawText('adminPanel.usersInfo.agency') },
			{ id: Enumerations.role.businessPartner, label: getTranslatedRawText('adminPanel.usersInfo.businessPartner') }
		])
	}

	static getListNameGoogleTagManager(id) {
		switch (id) {
			case Enumerations.googleTagManagerListId.home: return "Home"
			case Enumerations.googleTagManagerListId.shop: return "Shop"
			case Enumerations.googleTagManagerListId.singlePageSidebar: return "Single page sidebar"
			case Enumerations.googleTagManagerListId.regularOrderDetail: return "Regular Order Detail"
			case Enumerations.googleTagManagerListId.professionalOrderDetail: return "Professional Order Detail"
			case Enumerations.googleTagManagerListId.regularOrder: return "Regular Order"
			case Enumerations.googleTagManagerListId.professionalOrder: return "Professional Order"
			case Enumerations.googleTagManagerListId.cart: return "Cart"
			case Enumerations.googleTagManagerListId.orderDetail: return "Order Detail"
			case Enumerations.googleTagManagerListId.singlePage: return "Single Page"
			case Enumerations.googleTagManagerListId.cartConfirmationModal: return "Cart Confirmation Modal"
			case Enumerations.googleTagManagerListId.credit: return "Credit"
		}
	}

	static getPromotionNameGoogleTagManager(id) {
		switch (id) {
			case Enumerations.promotionsId.homeTopRightTop: return 'Home Top Right Top'
			case Enumerations.promotionsId.homeTopRightBottom: return 'Home Top Right Bottom'
			case Enumerations.promotionsId.homeTopSlider: return 'Home Top Slider'
			case Enumerations.promotionsId.homeCenterLeft: return 'Home Center Left'
			case Enumerations.promotionsId.homeCenterCenter: return 'Home Center Center'
			case Enumerations.promotionsId.homeCenterRight: return 'Home Center Right'
			case Enumerations.promotionsId.singleTop: return "Single Top"
			case Enumerations.promotionsId.singleBottom: return 'Single Bottom'
		}
	}

	static getFestivalInfoTabs() {
		return ([
			hasAccess([UserClaimEnumerations.getApFestivalWinners]) && { id: Enumerations.festivalInfoTabs.winners, label: getTranslatedRawText('adminPanel.festivals.winners') },
			hasAccess([UserClaimEnumerations.getApFestivalPrizes]) && { id: Enumerations.festivalInfoTabs.prizes, label: getTranslatedRawText('adminPanel.festivals.prizes') },

		])
	}

	static getFestivalStatuses() {
		return ([
			{ id: Enumerations.festivalStatusType.active, label: getTranslatedRawText('adminPanel.festivals.active') },
			{ id: Enumerations.festivalStatusType.done, label: getTranslatedRawText('adminPanel.festivals.done') }
		])
	}
	static getFestivalStatusById(id) {
		switch (id) {
			case Enumerations.festivalStatusType.active: return getTranslatedRawText('adminPanel.festivals.active')
			case Enumerations.festivalStatusType.done: return getTranslatedRawText('adminPanel.festivals.done')
		}
	}

	static getShowInStoreProductById(showInLocal, showInRemote) {
		return !!showInLocal && !!showInRemote ? getTranslatedRawText('adminPanel.storeProduct.localAndRemote')
			: !!showInLocal ? getTranslatedRawText('adminPanel.storeProduct.local')
				: !!showInRemote ? getTranslatedRawText('adminPanel.storeProduct.remote')
					: ''
	}

	static getAdminStoreProductDetailTabs = () => [
		{ id: 1, label: getTranslatedRawText('adminPanel.storeProduct.description') },
		{ id: 2, label: getTranslatedRawText('adminPanel.storeProduct.overview') }
	];

	static getAdminProductContentDetailTabs = () => [
		{ id: 1, label: getTranslatedRawText('adminPanel.productContent.faqs') },
		{ id: 2, label: getTranslatedRawText('adminPanel.productContent.overview') }
	];

	static getProductDetailContentTabs = (pprContent) => [
		!isEmpty(pprContent?.overview) && { id: Enumerations.productDetailContentTabs.overview, label: getTranslatedRawText('product.detail.overview'), link: getTranslatedRawText('product.detail.overview') },
		{ id: Enumerations.productDetailContentTabs.explanation, label: getTranslatedRawText('product.detail.explanation'), link: getTranslatedRawText('product.detail.explanation') },
		{ id: Enumerations.productDetailContentTabs.recommendedProducts, label: getTranslatedRawText('product.detail.recommendedProducts'), link: getTranslatedRawText('product.detail.recommendedProducts') },
		!isEmpty(pprContent?.faqs) && { id: Enumerations.productDetailContentTabs.faqs, label: getTranslatedRawText('product.shoppingBag.faqs'), link: getTranslatedRawText('product.shoppingBag.faqs') },
	];
	static getAuditContentTabs = () => [
		{ id: Enumerations.editorMode.basic, label: getTranslatedRawText('adminPanel.audit.basicMode') },
		{ id: Enumerations.editorMode.advance, label: getTranslatedRawText('adminPanel.audit.advanceMode') }
	];

	static getRollWidths = () => [
		{ key: 1, value: Config.shoppingBags.rollWidths[0] },
		{ key: 2, value: Config.shoppingBags.rollWidths[1] },
		{ key: 3, value: getTranslatedRawText('product.shoppingBag.all') }
	];
	static getPackagingType = () => [
		{ key: Enumerations.shoppingBagPackagingType.shrinkedFilm, value: getTranslatedRawText('product.shoppingBag.shrinkedFilm') },
		{ key: Enumerations.shoppingBagPackagingType.carton, value: getTranslatedRawText('product.shoppingBag.carton') },
		{ key: Enumerations.shoppingBagPackagingType.both, value: getTranslatedRawText('product.shoppingBag.both') }
	];
	static getPaperType = () => [
		{ key: Enumerations.shoppingBagPaperType.virgin, value: getTranslatedRawText('product.shoppingBag.virgin') },
		{ key: Enumerations.shoppingBagPaperType.recycle, value: getTranslatedRawText('product.shoppingBag.recycle') },
	];

	static getShoppingBagGuidanceTabs = () => [
		{ id: 1, label: getTranslatedRawText('product.shoppingBag.howToOrder') },
		{ id: 2, label: getTranslatedRawText('product.shoppingBag.description') },
		{ id: 3, label: getTranslatedRawText('product.shoppingBag.howToMeasure') },
	];

	static getCashbackTabs = () => [
		{ id: 1, label: getTranslatedRawText('product.detail.explanation') },
		{ id: 2, label: getTranslatedRawText('product.shoppingBag.history') },
	];

	static getViewStyle = () => [
		{ key: Enumerations.viewType.viewSingle, value: <i className='ns-icon-small-view' /> },
		{ key: Enumerations.viewType.viewList, value: <i className='ns-icon-detail-view' /> },
	];

	static getFeedbackRates = () => [
		{ key: 1, value: 1 },
		{ key: 2, value: 2 },
		{ key: 3, value: 3 },
		{ key: 4, value: 4 },
		{ key: 5, value: 5 },

	]

	static getUsersDashboardDuplicateUsersTab = () => ([
		{ id: Enumerations.duplicateUsersbaseType.fullName, label: getTranslatedRawText('adminPanel.dataGridColumn.fullName') },
		{ id: Enumerations.duplicateUsersbaseType.companyName, label: getTranslatedRawText('adminPanel.dataGridColumn.companyName') },
		{ id: Enumerations.duplicateUsersbaseType.mobile, label: getTranslatedRawText('adminPanel.dataGridColumn.mobile') },
	]);

	static getFeedbackMetricTypeTabs = () => [
		{ id: Enumerations.feedbackMetricTypes.ratingparamenter, label: getTranslatedRawText('adminPanel.feedback.ratingParameter') },
		{ id: Enumerations.feedbackMetricTypes.demandService, label: getTranslatedRawText('adminPanel.feedback.demandService') },
		{ id: Enumerations.feedbackMetricTypes.strengths, label: getTranslatedRawText('adminPanel.feedback.strengths') },
		{ id: Enumerations.feedbackMetricTypes.weaknesses, label: getTranslatedRawText('adminPanel.feedback.weaknesses') },
	]

	static getFeedbackDashboardTabs = () => [
		{ id: Enumerations.feedbackTypes.none, label: getTranslatedRawText('adminPanel.feedback.overall') },
		{ id: Enumerations.feedbackTypes.sharedSheetOrder, label: getTranslatedRawText('adminPanel.sidebar.orders') },
		{ id: Enumerations.feedbackTypes.announcementContent, label: getTranslatedRawText('adminPanel.sidebar.announcements') },
		{ id: Enumerations.feedbackTypes.user, label: getTranslatedRawText('adminPanel.feedback.employees') },
	]

	static getFeedbackstatus = (hasAll) => CommonHelper.clearArrayFromEmptyItem([
		!!hasAll && { id: 100, label: getTranslatedRawText('adminPanel.common.all') },
		{ id: Enumerations.feedbackStatus.pending, label: getTranslatedRawText('adminPanel.feedback.pending') },
		{ id: Enumerations.feedbackStatus.inprocess, label: getTranslatedRawText('adminPanel.feedback.inprocess') },
		{ id: Enumerations.feedbackStatus.archive, label: getTranslatedRawText('adminPanel.feedback.archive') },
	]);

	static getFeedbackDemandServiceType = () => [
		{ id: Enumerations.feedbackDemandServiceType.callMe, key: Enumerations.feedbackDemandServiceType.callMe, label: getTranslatedRawText('adminPanel.feedback.callMe') },
	];

	static getFeedbackStrengthWeeknestabs = (hasStrength, hasWeekness) => CommonHelper.clearArrayFromEmptyItem([
		hasStrength && { id: Enumerations.feedbackMetricTypes.strengths, label: getTranslatedRawText('adminPanel.feedback.strengths') },
		hasWeekness && { id: Enumerations.feedbackMetricTypes.weaknesses, label: getTranslatedRawText('adminPanel.feedback.weaknesses') },
	]);

	static getFeedbackStatusFilterOptions = () => [
		{ id: Enumerations.feedbackStatusFilterOptions.all, label: getTranslatedRawText('profile.orderInProcess.all') },
		{ id: Enumerations.feedbackStatusFilterOptions.hasComment, label: getTranslatedRawText('adminPanel.feedback.hasComment') },
		{ id: Enumerations.feedbackStatusFilterOptions.hasDemandedService, label: getTranslatedRawText('adminPanel.feedback.hasDemandService') },
	];

	static getFeedbackFilterByValueOptions = () => [
		{ id: 1, label: getTranslatedRawText('adminPanel.dataGridColumn.qty') },
		{ id: 2, label: getTranslatedRawText('adminPanel.dataGridColumn.avgRate') },
	];

	static getCashbackTimesTabs = (fromDate, toDate) => CommonHelper.clearArrayFromEmptyItem([
		{ id: Enumerations.cashbackTimeType.currentMonth, label: getTranslatedRawText('adminPanel.cashback.currentMonth') },
		!!fromDate && { id: Enumerations.cashbackTimeType.currentMonthVsFromDate, label: getTranslatedRawText('adminPanel.cashback.currentMonthWithVs') + fromDate },
		!!fromDate && !!toDate && { id: Enumerations.cashbackTimeType.basedOnSelectedDate, label: getTranslatedRawText('adminPanel.cashback.from') + ' ' + fromDate + ' ' + getTranslatedRawText('adminPanel.cashback.to') + ' ' + toDate },
		// { id: Enumerations.cashbackTimeType.lastMonthsOfThisYear, label: getTranslatedRawText('adminPanel.cashback.lastMonthsOfThisYear') },
	]);

	static getCashbackPlanTabs = () => [
		{ id: Enumerations.cashbackPlanType.none, label: getTranslatedRawText('adminPanel.common.all') },
		{ id: Enumerations.cashbackPlanType.common, label: getTranslatedRawText('adminPanel.cashback.common') },
		{ id: Enumerations.cashbackPlanType.spacial, label: getTranslatedRawText('adminPanel.cashback.special') },
		{ id: Enumerations.cashbackPlanType.noPlan, label: getTranslatedRawText('adminPanel.cashback.withoutCashback') },
	];

	static getCashbackCustomerInsightsOptions = () => [
		{ id: 39, label: getTranslatedRawText('adminPanel.cashback.cashbackAmount') },
		{ id: 21, label: getTranslatedRawText('adminPanel.cashback.purchaseAmount') },
		{ id: 38, label: getTranslatedRawText('adminPanel.cashback.qtyOfCustomers') },
	];

	static getOrderCalculationBasesTypeOptions = () => [
		{ id: Enumerations.orderCalculationBasesType.basedOnSeriesInOrderDetail, value: getTranslatedRawText('adminPanel.shippings.basedOnSeriesInOrderDetail') },
		{ id: Enumerations.orderCalculationBasesType.basedOnOrderCount, value: getTranslatedRawText('adminPanel.shippings.basedOnOrderCount') },
	];
}

export default ConstantsWithTranslation;