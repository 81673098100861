import React, { useEffect, useState } from 'react';

import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';
import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsCard from '../../../../components/cards/NsCard';

const ApDcbPlansChart = (props) => {

    const { data, isLoading, refreshClicked } = props;
    const chartOptions = ConstantsWithTranslation.getCashbackCustomerInsightsOptions();
    const [selectedItem, setSelectedItem] = useState(chartOptions[0].id);
    const [datasets, setDatasets] = useState(data);

    useEffect(() => {
        let filterDataset = isEmpty(data) ? [] : data.datasets.filter(dataset => dataset.chartDatasetType === selectedItem)
        setDatasets(ApBasesDashboardHelper.setCashbackPlansChartOptions({ labels: data?.labels, datasets: filterDataset }));

    }, [data, selectedItem])

    const selectValueChanged = (value) => setSelectedItem(value);

    const options = {
        scales: {
            x: {
                display: true,
                grid: {
                    display: true
                }
            },
            y: {
                display: true,
                grid: {
                    display: true
                }
            }
        }
    };

    return (
        <NsCard className='p-4 h-100'>
            <ApBasesDashboardCharts title='adminPanel.cashback.cashbackPlans'
                height='75vh'
                type='bar'
                tooltipTitle='adminPanel.dashboard.orderByApplicationTypeDfChartDes'
                data={datasets}
                hasSelect={true}
                selectOptions={chartOptions}
                selectedItem={selectedItem}
                selectLabel='adminPanel.cashback.basedOn'
                selectValueChanged={selectValueChanged}
                refreshClicked={refreshClicked}
                options={options}
                isLoading={isLoading} />
        </NsCard>
    );
};

export default React.memo(ApDcbPlansChart);