import Config from "../../configs/Config";
import Enumerations from "../../configs/Enumerations";
import CurrentUserHelper from "../../utilities/CurrentUserHelper";
import StorageHelper from "../../utilities/StorageHelper";

const SETCURRENCY = 'SET_CURRENTCY';
const SETDEFAULTCURRENCY = 'SET_DEFAULT_CURRENTCY';
const REMOVECURRENCIES = 'REMOVE_CURRENCIES';

const initialState = {
    selectedCurrency: {},
    currencies: []
};

export const currencyActionCreators = {
    setDefaultCurrency: (data) => ({ type: SETDEFAULTCURRENCY, data }),
    setCurrency: (data) => ({ type: SETCURRENCY, data }),
    removeCurrencies: () => ({ type: REMOVECURRENCIES }),
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SETDEFAULTCURRENCY:
            StorageHelper.saveInStorage(Enumerations.storage.userCurrency, action.data.userCurrency)
            return {
                currencies: action.data.currencies,
                selectedCurrency: action.data.userCurrency.currentCurrency
            }
        case SETCURRENCY:
            let selectedCurrency = state.currencies.find(currency => currency.id === action.data);
            let defaultCurrencyId = CurrentUserHelper.getDefaultCurrencyTypeId() || Config.defaultCurrency.id;
            StorageHelper.saveInStorage(Enumerations.storage.userCurrency,
                {
                    currentCurrency: selectedCurrency,
                    defaultCurrency: state.currencies.find(currency => currency.id === defaultCurrencyId)
                });
            return {
                ...state,
                selectedCurrency
            };
        case REMOVECURRENCIES: return {
            selectedCurrency: {},
            currencies: []
        }

        default:
            return state;
    }
};