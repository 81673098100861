import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { AddFeedbackActivityService } from '../../../../services/adminPanel/AdminFeedbackService';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsTextField from '../../../../components/inputs/NsTextField';
import NsTypography from '../../../../components/text/NsTypography';
import NsCheckbox from '../../../../components/inputs/NsCheckbox';
import NsSelect from '../../../../components/dropdowns/NsSelect';
import CommonHelper from '../../../../utilities/CommonHelper';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import NsModal from '../../../../components/modals/NsModal';
import NsTags from '../../../../components/tags/NsTags';
import Config from '../../../../configs/Config';

const ApBaseFeedbackAddActivityModal = (props) => {

    const { userFeedbackId } = props;
    const feedbackReadyMessages = getConfigObjectByCulture(Config.feedbackReadyMessages);
    const activityStates = ConstantsWithTranslation.getFeedbackstatus();
    const [comment, setComment] = useState('');
    const [isValidComment, setIsValidComment] = useState(false);
    const [activityState, setActivityState] = useState(null);
    const [isVisibilityChecked, setIsVisibilityChecked] = useState(false);
    const [isValidActivityState, setIsValidActivityState] = useState(false);
    const [showValidationDate, setShowValidationDate] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(null);

    const valueChanged = (value, isValid) => {
        setComment(value);
        setIsValidComment(isValid);
    }
    const activityStateChanged = (value, isValid) => {
        setActivityState(value);
        setIsValidActivityState(isValid);
    }

    const saveButtonClicked = () => {
        if (!isValidActivityState || !isValidComment) {
            setShowValidationDate(new Date());
            return;
        }
        setIsLoading(true);
        const data = {
            userFeedbackId: userFeedbackId,
            comment,
            userFeedbackStateTypeId: activityState,
            isPublic: isVisibilityChecked
        }
        AddFeedbackActivityService(data, addFeedbackActivityCallback);
    }

    const addFeedbackActivityCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (result.data.hasError) setResultMessageClass(resultMessageClass);
        else CommonHelper.safeFunctionCall(props.closeModal, resultMessageClass, true)
    }

    const visibilityChanged = (value) => setIsVisibilityChecked(value);
    const suggestionCommentClicked = (suggestionComment) => setComment(comment + suggestionComment);

    const stickyButtons = [
        hasAccess([UserClaimEnumerations.postApFeedbackAddState]) && CommonHelper.createStickyButtonItem('common.save', 'ns-icon-save', saveButtonClicked, 'primary', !comment || !activityState),
        CommonHelper.createStickyButtonItem('common.cancel', 'ns-icon-close', () => props.closeModal(false), 'primary-outline')
    ];

    return (<NsModal maxWidth='sm'
        showModal={true}
        closeModal={() => CommonHelper.safeFunctionCall(props.closeModal, false)}
        dialogTitle={getTranslatedRawText("adminPanel.feedback.newActivity")}
        actions={<NsStickyButton stickyButtons={stickyButtons} isLoading={isLoading} />} >

        <Row className='justify-content-between align-items-end'>
            <Col md={5}>
                <NsSelect label={getTranslatedRawText('adminPanel.feedback.activityState')}
                    options={activityStates}
                    showValidationDate={showValidationDate}
                    selectedItem={activityState}
                    className='w-75 mb-3'
                    valueChanged={activityStateChanged} />
            </Col>
            <Col md={6}>
                <NsCheckbox value={isVisibilityChecked}
                    onChange={visibilityChanged}
                    label={getTranslatedRawText('adminPanel.feedback.thisMessageIsVisibleToUser')}
                    className='mb-3'
                    checkboxClassName='ps-0' />
            </Col>
        </Row>
        <NsTags tags={feedbackReadyMessages}
            containerClassName='mt-4 mb-2 d-flex'
            isTexttrimmed={true}
            lengthTrimmed={30}
            tagClassName='font-size-12'
            tagClicked={suggestionCommentClicked} />
        <NsTextField label={getTranslatedRawText('adminPanel.feedback.comment')}
            required
            multiline
            inputProps={{ maxLength: 500 }}
            valueChanged={valueChanged}
            value={comment}
            showValidationDate={showValidationDate}
            className='w-100 textfield-line-limit' />
        <NsTypography variant='inherit font-size-12'>{CommonHelper.stringFormat(getTranslatedRawText('profile.feedback.characterLimit'), comment?.length || 0)}</NsTypography>

        {resultMessageClass}
    </NsModal >
    );
}

export default React.memo(ApBaseFeedbackAddActivityModal);