import React from 'react';

import NsButton from '../buttons/NsButton';
import NsDownloadFile from './NsDownloadFile';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';

class NsExportExcelReportButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            turnaround: 0,
            isLoading: false,
            resultMessageClass: (<></>),
            searchValues: null,
            file: null,
            rows: [],
            advancedMode: false,
        };
    }

    excelReportButtonClicked = () => {
        this.setState({ isLoading: true })
        const { searchValues, isAllOrder } = this.props;
        let data = isAllOrder
            ? {
                nasInvoiceIds: searchValues?.invoiceNumber ? [searchValues?.invoiceNumber] : null,
                fromSubmitTime: searchValues?.fromSubmitTime,
                toSubmitTime: searchValues?.toSubmitTime,
                productCategoryIds: searchValues?.productCategory?.id ? [searchValues?.productCategory?.id] : null,
                maxPrice: searchValues?.maxPrice,
                minPrice: searchValues?.minPrice,
                toDeliverTime: searchValues?.deliveredTimeTo,
                fromDeliverTime: searchValues?.deliveredTimeFrom,
                toConfirmTime: searchValues?.confirmTimeTo,
                fromConfirmTime: searchValues?.confirmTimeFrom,
                twoSidePrintingTypes: searchValues?.printedSide?.id,
                turnaroundTypes: searchValues?.turnaround?.id,
            }
            : { ...searchValues }
        isAllOrder && !isEmpty(searchValues?.circulations) && searchValues?.circulations.map((item, index) => data["ProductId[" + index + "]"] = item.key);
        this.props.service(data, this.orderExcelReportCallback)
    }

    orderExcelReportCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false })
        if (!result.data.hasError) {
            const href = !isEmpty(resultData) && CommonHelper.createDownloadFileUrl(resultData?.fileDownloadName, resultData?.fileContents);
            this.setState({
                file: { fileDownloadName: resultData?.fileDownloadName, fileContents: href }
            });
        } else this.setState({
            resultMessageClass: messageClassModal
        })
    }

    render() {
        const { isLoading, file, resultMessageClass } = this.state;
        const { buttonSize, wrapperClassName } = this.props;
        return (<>
            <NsButton label={getTranslatedRawText('regularOrder.common.exportExcel')}
                size={buttonSize}
                onClick={this.excelReportButtonClicked}
                className='secondary-outline text-nowrap'
                wrapperClassName={wrapperClassName}
                loading={this.props.isLoading || isLoading}
                disabled={this.props.isLoading || isLoading}
                startIcon='ns-icon-excel' />

            <NsDownloadFile file={file} />

            {resultMessageClass}
        </>
        )
    }
}

export default NsExportExcelReportButton;