import clsx from 'clsx';
import React from 'react';
import { Breadcrumbs } from '@mui/material';
import '../../assets/sass/components/breadcrumbs/NsBreadcrumbs.scss';
import CommonHelper from '../../utilities/CommonHelper';
class NsBreadcrumbs extends React.Component {
    render() {
        const { className, separator, ...otherProps } = this.props;
        return (
            <Breadcrumbs className={clsx('breadcrumbs', className)}
                {...otherProps}
                separator={!!separator ? separator : <i className={`${CommonHelper.getBreadcrumbsSeparatorIcon()} font-size-14  p-0 fw-bold`} />}>
                {this.props.children}
            </Breadcrumbs>
        );
    }
}

export default NsBreadcrumbs;