import React, { useState } from "react";

import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { GetPaymentsService } from "../../../../services/adminPanel/AdminPaymentService";
import ApPaymentDetailModal from "../../managements/payments/ApPaymentDetailModal";
import { getApPaymentColumns } from "../../managements/payments/ApPaymentHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsCard from "../../../../components/cards/NsCard";

const ApDauNotProcessSuccessfullyPayments = (props) => {

    const { parameters, refreshDate } = props;

    const [payments, setPayments] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [showPaymentDetailModal, setShowPaymentDetailModal] = useState(false);

    const getParameters = () => ({
        "Filter.isAudited": false,
        "Filter.PaymentStateType": Enumerations.paymentStateType.success,
        "Filter.FromSubmitTime": parameters?.submitTimeFrom,
        "Filter.ToSubmitTime": parameters?.submitTimeTo,
    });

    const getData = (payments) => setPayments(payments);

    const paymentDetailClicked = (id) => {
        setSelectedPayment(payments.filter(payment => payment.id === id)[0]);
        setShowPaymentDetailModal(true);
    }

    const closeApPaymentDetailModal = () => setShowPaymentDetailModal(false);

    const columns = getApPaymentColumns(paymentDetailClicked);

    return (
        <NsCard className='p-3'>
            <div className='fw-bold mb-2'>{getTranslatedRawText('adminPanel.dashboard.successNotProcess')}</div>
            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={GetPaymentsService}
                parameters={getParameters()}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showPaymentDetailModal &&
                <ApPaymentDetailModal showModal={true}
                    selectedPaymentId={selectedPayment?.id}
                    closeModal={closeApPaymentDetailModal} />
            }
        </NsCard>
    );
};

export default React.memo(ApDauNotProcessSuccessfullyPayments);