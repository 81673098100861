import React, { Component } from 'react';

import { PostAgencyOrdersAdvanceService, PostAgencyOrdersBasicService } from '../../../../../services/ProfileSetting/AgencyService';
import UpGroupSheetAllOrders from '../../userOrder/groupSheets/all/UpGroupSheetAllOrders';

class UpAgencyCustomersOrders extends Component {

    render() {
        return (
            <UpGroupSheetAllOrders isAgency={true}
                titleIcon='ns-icon-agency-orders'
                agencyAdvanceService={PostAgencyOrdersAdvanceService}
                agencyBasicService={PostAgencyOrdersBasicService}
            />
        )
    }
}

export default UpAgencyCustomersOrders;