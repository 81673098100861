import clsx from 'clsx';
import React from 'react';
import '../../assets/sass/components/dragFiles/NsDragFiles.scss';

import { isValidFileExtension } from '../../utilities/ValidationHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CommonHelper from '../../utilities/CommonHelper';
import Config from '../../configs/Config';
import ConstantsWithTranslation from '../../configs/ConstantsWithTranslation';

class NsDragFile extends React.Component {

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.validFilePickerExtensions = props.isExcelFile
            ? Config.validExcelFilePickerExtensions
            : props.isAdminPanelFileManager
                ? ConstantsWithTranslation.getFileExtensionType().map(extension => '.' + extension.value)
                : Config.validFilePickerExtensions;
        this.validFileExtensions = props.isExcelFile
            ? Config.validExcelFileExtensions
            : props.isAdminPanelFileManager
                ? ConstantsWithTranslation.getFileExtensionType().map(extension => extension.value)
                : Config.validFileExtensions;
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.triggerFilePicker !== this.props.triggerFilePicker)
            this.clickOnBrowseFile();
    }

    clickOnBrowseFile = () => this.fileInput.current.click()

    chooseInputFile = () => {
        if (this.fileInput.current && this.fileInput.current.files) {
            for (let file of this.fileInput.current.files) {
                if (isValidFileExtension(file.name, this.validFileExtensions)) {
                    CommonHelper.safeFunctionCall(this.props.getSelectedFile, file, true);
                } else {
                    CommonHelper.safeFunctionCall(this.props.getSelectedFile, file, false);
                }
            }
            this.fileInput.current.value = null;
        }
    }

    showSelectFileButton = (isShowSelectFileButton) => {
        if (!!isShowSelectFileButton)
            return (
                <div className="drag-file__button-select text-center text-nowrap pb-4 mt-4 mt-md-0 px-md-4">
                    <div className="display-3 ns-icon-pdf-thumbnail light-text-color pt-md-4 pb-2" />
                    <div className="h6  fw-bold pb-2" >
                        {getTranslatedRawText('components.nsDragFile.browseYourFile')}
                    </div>
                    <a className='white-color background-red-color rounded px-4 py-2 font-weight-bold'
                        onClick={this.clickOnBrowseFile}>
                        {getTranslatedRawText('components.nsDragFile.browse')}
                    </a>
                </div>
            );
        else
            return null;
    }

    render() {
        const { noInnerPadding, multiple, isShowSelectFileButton, isShowVertical } = this.props;
        return (
            <div className={clsx('drag-file position-relative background-element-color border rounded w-100 text-center m-0',)}>
                <div className={clsx('d-flex align-items-center justify-content-around', !noInnerPadding ? 'py-md-5' : 'py-0', (isShowVertical || CommonHelper.isMobileDevice()) && 'flex-column')} >
                    <div>
                        <input className="drag-file__selector"
                            ref={this.fileInput}
                            type="file"
                            id="myFile"
                            multiple={multiple}
                            accept={this.validFilePickerExtensions}
                            onChange={this.chooseInputFile} />
                        <i className="display-1 ns-icon-drag-drop d-none d-md-block light-text-color py-4" />
                        <div className="h6 d-none d-md-block fw-bold" >
                            {getTranslatedRawText('components.nsDragFile.dragHere')}
                        </div>
                    </div>

                    <div className={clsx(isShowVertical ? "mt-4" : "", " d-none d-md-block text-muted text-center")} >
                        {getTranslatedRawText('components.nsDragFile.or')}
                    </div>

                    {this.showSelectFileButton(isShowSelectFileButton)}
                </div>
            </div>
        );
    }
}

export default NsDragFile;