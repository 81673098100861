import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NsButton from '../../../components/buttons/NsButton';
import NsCountDownButton from '../../../components/buttons/NsCountDownButton';
import NsActivationInput from '../../../components/inputs/NsActivationInput';
import NsTypography from '../../../components/text/NsTypography';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';
import { ResendVerificationCodeService, VerifyMobileService } from '../../../services/accounts/AccountService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import AccountWrapper from '../AccountWrapper';
import NsSetPageTitleAndDescription from '../../../components/metaTags/NsSetPageTitleAndDescription';

class SignupActivation extends React.Component {

    constructor(props) {
        super(props);
        this.mobile = CurrentUserHelper.getMobile();
        this.countryId = CurrentUserHelper.getCountryId();
        this.state = {
            code: '',
            isValidCode: false,
            isLoading: false,
            showValidationDate: null,
            countDownTime: 0,
            resultMessageClass: (<></>)
        };
    }

    componentDidMount = () => {
        (!this.mobile || !this.countryId)
            ? this.props.history.push(getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.mobile))
            : !window.history.state?.state?.totalRemainingTimeInSecond ? this.resendCodeClicked() : this.setState({ countDownTime: window.history.state?.state?.totalRemainingTimeInSecond });
        window.history.replaceState({ totalRemainingTimeInSecond: 0 }, 'aaa');
    }

    keyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter) {
            event.preventDefault();
            this.verifyMobileClicked();
        }
    }

    codeChanged = (value, isValid) => {
        this.setState({
            code: value,
            isValidCode: isValid
        }, () => {
            let newValue = value.replace(/-/g, '').replace(/ /g, '');
            if (newValue.length === 6)
                this.verifyMobileClicked();
        });
    }

    resendCodeClicked = () => {
        let data = {
            countryCode: CurrentUserHelper.getCountryCode(),
            mobileWithOutCountryCode: CurrentUserHelper.getMobileWithOutCountryCode(),
        };
        this.setState({ isLoading: true });
        ResendVerificationCodeService(data, this.resendVerificationCodeCallback);
    }

    verifyMobileClicked = () => {
        if (!this.state.isValidCode) {
            this.setState({ showValidationDate: new Date() });
            return false;
        }
        let data = {
            Mobile: this.mobile?.replace(/ /g, '').substr(1),
            verificationCode: this.state.code.replace(/-/g, '').replace(/ /g, ''),
        };
        this.setState({ isLoading: true });
        VerifyMobileService(data, this.verifyMobileCallback);
    }

    verifyMobileCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError && result.data?.messageItems[0]?.messageType == Enumerations.messageType.success)
            this.props.history.push(getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.accountInformation));
        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    resendVerificationCodeCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (result?.data?.hasError || !resultData)
            this.setState({ resultMessageClass: messageClassModal });
        else
            this.setState({ countDownTime: CommonHelper.toInt(resultData.totalRemainingTimeInSecond) });
    }

    render() {
        const { countDownTime } = this.state;

        return (
            <AccountWrapper layoutType={Enumerations.loginLayoutType.signup}>

                <NsSetPageTitleAndDescription pageTitle='common.breadcrumbs.signupActivationMobile' />

                <Form onKeyDown={this.keyDown}>
                    <div className="white-panel g-0 d-flex flex-column my-sm-n5 w-100 p-4">
                        <h1 className='section-title'>
                            {getTranslatedRawText("account.signup.signupVerificationTitle")}
                        </h1>
                        <NsTypography variant='inherit' className='primary-paragraph'>
                            {getTranslatedRawText("account.signup.signupVerificationDescription")}
                        </NsTypography>
                        <div className='d-flex align-items-baseline mt-2'>
                            <NsTypography variant='inherit' className='light-paragraph'>
                                {getTranslatedRawText("account.common.mobile")} :
                            </NsTypography>

                            <NsTypography variant='inherit' className='light-paragraph not-rtl'>
                                {this.mobile}
                            </NsTypography>

                            <Link className='font-size-14 ms-2 p-2'
                                to={getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.mobile)} >
                                {getTranslatedRawText("common.edit")}
                            </Link>

                        </div>
                        <div className="mt-4 w-100">
                            <NsActivationInput
                                codeChanged={this.codeChanged}
                                showValidationDate={this.state.showValidationDate}
                                mask={Enumerations.mask.validationCode}
                                onChange={this.changeVerifyCode}
                                disabled={false}
                                maskChar=" "
                                showMask
                                label={getTranslatedRawText("components.verificationInput.verifyMaskLabel")}
                            />
                        </div>

                        <div className="d-flex justify-content-center mt-5">
                            <NsButton className="primary"
                                onClick={this.verifyMobileClicked}
                                loading={this.state.isLoading}
                                label="account.signup.verifyMobile" />
                        </div>

                        <div className="d-flex justify-content-center mb-md-0 mt-2 mb-3">
                            <NsCountDownButton label={getTranslatedRawText("account.signup.resendCode")}
                                time={{ min: Math.floor(countDownTime / 60), second: countDownTime % 60 }}
                                loading={this.state.isLoading}
                                onClick={this.resendCodeClicked} />
                        </div>
                    </div>
                    {this.state.resultMessageClass}
                </Form >
            </AccountWrapper>
        );
    }
}

export default SignupActivation;