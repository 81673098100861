import React, { useState } from "react";

import NsAdminPanelContentWrapper from "../../../../../components/cards/NsAdminPanelContentWrapper";
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApSupporterContent from "./ApSupporterContent";

const ApSupporterUser = () => {

    const [headerActionDate, setHeaderActionDate] = useState();

    const headerButtonClicked = () => setHeaderActionDate(new Date());

    return (<NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.supporters")}
        actionButton={{ onClick: headerButtonClicked, label: getTranslatedRawText('adminPanel.common.new') }}
        icon={'ns-icon-customer-users h1'} >

        <ApSupporterContent headerActionDate={headerActionDate} />

    </NsAdminPanelContentWrapper >);
}

export default React.memo(ApSupporterUser);