import React from 'react';
import { Col, Row } from 'react-bootstrap';

import CommonHelper from '../../../../utilities/CommonHelper';
import NsButton from '../../../../components/buttons/NsButton';
import NsMenu from '../../../../components/menu/NsMenu';
import '../../../../assets/sass/views/orders/RegularOrder.scss';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

class OBaseModalNumberDigits extends React.Component {

    constructor(props) {
        super(props);
        this.numberOfDigits = 7;
        this.padElements = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-'];
        this.state = {
            closeMenuDate: null,
            serialDigits: this.convertSerial(props.serialDigits),
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.clearSerialStartFromTimestamp !== prevProps.clearSerialStartFromTimestamp) {
            this.setState({
                serialDigits: this.convertSerial(null)
            })
        }
    }

    convertSerial = (serialDigits) => {
        let serial = '-------';
        serial = serial.split('');
        for (let i = 0; i < serialDigits?.length; i++) {
            serial[serial.length - 1 - i] = serialDigits[serialDigits?.length - 1 - i];
        }
        return serial.join('')
    }

    digitPadClicked = (index, chr) => {
        let tempSerialDigits = this.state.serialDigits.substr(0, index) + chr + this.state.serialDigits.substr(index + 1);
        let firstNumberIndex = this.numberOfDigits - 1;
        let tempArray = tempSerialDigits.split("")
        tempArray.forEach((element, index) => {
            if (element === '-')
                tempArray[index] = index > firstNumberIndex ? '0' : '-';
            else
                firstNumberIndex = index;
        });

        this.setState({ serialDigits: tempArray.join(''), closeMenuDate: new Date() },
            () => CommonHelper.safeFunctionCall(this.props.digitPadClicked, (this.state.serialDigits.split('-').pop() || ''), this.state.serialDigits));
    }

    render() {
        const { serialDigits, closeMenuDate } = this.state;
        return <div className='mb-2 not-rtl'>
            <div className='d-flex gap-1'>
                {CommonHelper.createArray(this.numberOfDigits).map((item, index) =>
                    <NsMenu menuClassName='number-digits'
                        key={index}
                        buttonChildren={
                            <div className='d-flex flex-column align-items-center m-1'>
                                {serialDigits[index]}
                                <i className='ns-icon-expand-down' />
                            </div>}
                        buttonSize='small'
                        buttonClassName='secondary-outline smallest'
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        closeMenuDate={closeMenuDate}>
                        <Row className='justify-content-start g-1 p-1'>
                            <DigitPad padElements={this.padElements}
                                onClick={(chr) => this.digitPadClicked(index, chr)} />
                        </Row>
                    </NsMenu>
                )}
            </div>
            {!this.props.hideNotification && <>
                {!serialDigits.split('-').pop() && !serialDigits.split('-').every(num => num === '') && <p className='font-size-12 red-color mt-1' >{getTranslatedRawText('regularOrder.billBook.invalidSerial')}</p>}
                {serialDigits.split('-').every(num => num === '') && <p className='font-size-12 red-color mt-1' >{getTranslatedRawText('regularOrder.billBook.enterSerial')}</p>}
            </>}
        </div>
    }
}

function DigitPad(props) {
    const { padElements } = props;

    return CommonHelper.createArray(padElements.length).map((item, index) =>
        <Col key={index} xs={4}>
            <NsButton label={padElements[index]}
                className='light'
                size='small'
                onClick={() => props.onClick(padElements[index])} />
        </Col>)
}

export default OBaseModalNumberDigits;