import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import NsButton from '../../../../components/buttons/NsButton';
import NsPagination from '../../../../components/pagination/NsPagination';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';
import Links from '../../../../configs/links/AllLinks';
import CommonHelper from '../../../../utilities/CommonHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import BnBasesPostCard from '../bases/BnBasesPostCard';
import { Link } from 'react-router-dom';
import NsLabelNoData from '../../../../components/labels/NsLabelNoData';

class BnBasesPosts extends React.Component {

	isBlog = CommonHelper.isBlog(window.location.href);

	onPageChanged = (currentPageIndex) => {
		CommonHelper.safeFunctionCall(this.props.onPageChanged, currentPageIndex)
	}

	viewMoreClicked = () => {
		return this.props.isNewPosts
			? this.isBlog
				? CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogSearchResult))
				: CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsSearchResult))

			: this.isBlog
				? CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogCategoryPosts), this.props.categoryId)
				: CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsCategoryPosts), this.props.categoryId);
	}

	mapResultContents = (posts) => {
		return this.props.isLoading
			? CommonHelper.createArray(3).map((item, index) =>
				<div key={index} className="d-inline d-md-inline-block col-md-4 p-2" >
					<NsSkeleton isVisible={true}
						variant={[
							Enumerations.skeleton.rect,
							Enumerations.skeleton.rect,
							Enumerations.skeleton.rect,
							Enumerations.skeleton.rect,
							Enumerations.skeleton.rect,
							Enumerations.skeleton.rect,
							Enumerations.skeleton.rect,
							Enumerations.skeleton.rect,
							Enumerations.skeleton.rect,
							Enumerations.skeleton.rect,
							Enumerations.skeleton.text,
							Enumerations.skeleton.text,
						]} />
				</div >
			)
			: isEmpty(posts)
				? <div className='d-flex justify-content-center'>
					<NsLabelNoData label='adminPanel.newsBlog.noAnyPost' />
				</div>
				: posts.map((post, index) =>
					<Col key={index} lg={6} xxl={4} className='px-1'>
						<BnBasesPostCard post={post} />
					</Col>
				)
	}

	render() {
		const { posts, title, className, hasPagination, count } = this.props;

		return <div className={className}>
			{!hasPagination &&
				<div className='d-flex align-items-center mb-1'>
					<span className='section-title me-2'>
						{title}
					</span>
					<div className='flex-grow-1 border-top border-2'></div>
				</div>
			}
			<Row className='g-2 align-items-stretch'>
				{this.mapResultContents(posts)}
			</Row>
			{!!hasPagination
				? count > 1
					? <div className='text-center mt-2'>
						<NsPagination variant='outlined'
							showFirstButton={true}
							showLastButton={true}
							count={count}
							onChange={this.onPageChanged} />
					</div>
					: null

				: <div className='text-center mt-2'>
					<NsButton className='light'>
						<Link to={() => this.viewMoreClicked()} className='d-flex justify-content-center w-100 black-color text-decoration-none'>
							{getTranslatedRawText('product.common.viewMore')}
						</Link>
					</NsButton>
				</div>
			}
		</div>
	}
}

export default withRouter(BnBasesPosts);