import React from 'react';

import NsButton from '../buttons/NsButton';

import Constants from '../../configs/Constants';
import CommonHelper from '../../utilities/CommonHelper';

class NsEditFileActionAlign extends React.Component {
    constructor(props) {
        super(props);
        this.alignButtons = Constants.alignButtons;
    }

    alignFilm = (alignType) => CommonHelper.safeFunctionCall(this.props.alignFilm, alignType);

    render() {

        return (
            <div className="d-flex flex-wrap p-2 background-white-color gap-1">
                {this.alignButtons.map((button, index) =>
                    <NsButton key={index} className="secondary-outline light"
                        size='small'
                        startIcon={button.icon}
                        onClick={() => this.alignFilm(button.actionType)}
                    />
                )}
            </div>
        );
    }
}

export default NsEditFileActionAlign;