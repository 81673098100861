import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { DeleteRequest, GetRequest, PatchRequest, PostRequest } from '../bases/BaseService';

const GetOrdersService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrders), parameter, callback);
}
const GetOrderDtoService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderDto), id), {}, callback);
}
const UpdateOrderStatusService = (data, callback, showSuccessResponseMessage) => {
    PatchRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.updateApOrderStatus), data, callback, showSuccessResponseMessage);
}
const GetOrderDetailService = (parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderDetail), parameters.orderId), { id: parameters.id }, callback);
}
const GetOrderWebsiteHistoryService = (parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderWebsiteStatusHistory), parameters?.orderId), parameters, callback);
}
const GetOrderNasHistoryService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderNasStatusHistory), parameters, callback);
}
const GetOrderNasSheetDetail = (sheetId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderNasSheetDetail), sheetId), {}, callback);
}
const DeleteOrderService = (id, userId, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApOrder), id), { id: userId }, callback, showSuccessResponseMessage);
}
const GetOrderUnOfficialReportService = (id, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderUnOfficialReport), id), { id: userId }, callback);
}
const GetOrderOfficialReportService = (id, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderOfficialReport), id), { id: userId }, callback);
}
const GetOrderOfficialInvoiceStatusService = (id, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderOfficialInvoiceStatus), id), { id: userId }, callback);
}
const GetAdminFilterPointsService = (id, parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderFilterPoints), id), parameters, callback);
}
const GetAdminBillBookOptionsService = (id, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderBillBookOptions), id), { id: userId }, callback);
}
const GetAdminPunchPointsService = (id, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderPunchPoints), id), { id: userId }, callback);
}
const GetAdminFoldLinesService = (id, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderFoldLines), id), { id: userId }, callback);
}
const GetAdditionalOrderInformationService = (id, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderAdditionalInformation), id), { id: userId }, callback);
}
const GetOrderProductDetailService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderProductDetail), id), {}, callback);
}
const GetStatusQueueService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStatusQueue), parameters, callback);
}
const GetOrderQueueStatusByServerNameService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApStatusQueueByServerName), parameters, callback);
}
const GetOrderEstimatedDeliveryTimeService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderEstimatedDeliveryTime), id), {}, callback);
}
const GetApOrderQueueByEstimatedTimeService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderQueueByEstimatedTime), parameters, callback);
}
const GetApOrderQueueByEstimatedTimeExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApOrderQueueByEstimatedTimeExcelReport), data, callback);
}
const GetApDailyQueueOfEstimateTimeService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDailyQueueOfEstimateTime), parameters, callback);
}

export {
    GetOrdersService,
    GetOrderDtoService,
    UpdateOrderStatusService,
    GetOrderDetailService,
    GetOrderWebsiteHistoryService,
    GetOrderNasHistoryService,
    GetOrderNasSheetDetail,
    DeleteOrderService,
    GetOrderUnOfficialReportService,
    GetOrderOfficialReportService,
    GetOrderOfficialInvoiceStatusService,
    GetStatusQueueService,
    GetAdminFilterPointsService,
    GetAdminBillBookOptionsService,
    GetAdminPunchPointsService,
    GetAdminFoldLinesService,
    GetAdditionalOrderInformationService,
    GetOrderProductDetailService,
    GetOrderQueueStatusByServerNameService,
    GetOrderEstimatedDeliveryTimeService,
    GetApOrderQueueByEstimatedTimeService,
    GetApOrderQueueByEstimatedTimeExcelReportService,
    GetApDailyQueueOfEstimateTimeService
}