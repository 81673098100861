import clsx from 'clsx';
import React from 'react';
import InputMask from 'react-input-mask';
import { InputAdornment, TextField } from '@mui/material';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { isEmpty, isValidIdNumber } from '../../utilities/ValidationHelper';
import '../../assets/sass/components/inputs/TextField.scss';
import CommonHelper from '../../utilities/CommonHelper';

class NsNumberInputWithPattern extends React.Component {

    constructor(props) {
        super(props);
        this.input = '';
        this.state = {
            input: props.value ? this.checkAndSendValue(props.value, true) : '',
            helperText: '',
            invisibleHelperText: this.messageHelperText(),
        };
    }

    messageHelperText = () => CommonHelper.stringFormat(getTranslatedRawText("common.messages.wrongIdNumber"), this.props.label);

    changeInput = (event) => this.setInput(event.target.value);

    setInput = (value) => {
        if(!isEmpty(this.props.escapeWordInPattern)){
            var regExp = new RegExp(this.props.escapeWordInPattern, 'g');
            value = value?.replace(regExp, '');
        }

        this.setState({ input: value, helperText: '' });
        let isValidInput = this.getIsValidInput(value);
        this.checkAndSendValue(value, isValidInput);
    }

    checkAndSendValue = (value, isValidInput) => CommonHelper.safeFunctionCall(this.props.valueChanged, value, isValidInput);

    getIsValidInput = (value) => {
        if (isEmpty(this.props.regex) || isValidIdNumber(value, null, this.props.regex)) {
            this.setState({ invisibleHelperText: '' });
            return true;
        } else {
            this.setState({ invisibleHelperText: this.messageHelperText() });
            return false;
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {

        if (!!newProps.value) {
            if (newProps.value != this.state.input)
                this.setInput(newProps.value);
        }
        else
            this.setState({ input: '', invisibleHelperText: this.messageHelperText() });

        if (newProps.showValidationDate !== this.props.showValidationDate)
            this.setState({ helperText: this.state.invisibleHelperText });
    }

    render() {
        const { showValidationDate, valueChanged, helperTxt, margin, codeMask, disabled, endAdornment, ...otherProps } = this.props;
        return (
            <InputMask
                className='ns-activation-input'
                mask={codeMask}
                onChange={this.changeInput}
                maskChar={this.props.maskChar}
                disabled={disabled}
                value={this.state.input}
                showMask >
                {() => <TextField inputRef={el => this.input = el}
                    {...otherProps}
                    disabled={disabled}
                    variant='standard'
                    className={clsx('ns-text-field not-rtl', otherProps.className)}
                    helperText={this.state.helperText}
                    // type='number'
                    value={this.state.input}
                    onChange={this.changeInput}
                    margin={margin}
                    inputProps={{ inputMode: 'numeric' }}
                    InputProps={{
                        endAdornment: <InputAdornment position={"end"} >{endAdornment}</InputAdornment>,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />}
            </InputMask>
        );
    }
}

export default NsNumberInputWithPattern;