import React, { Component } from 'react';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../utilities/CultureHelper';
import { getConfigObjectByCulture } from '../../../../../utilities/ConfigHelper';
import NsHtmlToReact from '../../../../../components/htmlToReact/NsHtmlToReact';
import NsTypography from '../../../../../components/text/NsTypography';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import Links from '../../../../../configs/links/AllLinks';
import Config from '../../../../../configs/Config';
import NsButton from '../../../../../components/buttons/NsButton';

class UpShippingPlanInformationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewMore: false,
            shippingPlanInformations: this.getOrderedShippingPlanInformations(props.selectedShippingPlan)
        };
    };

    getOrderedShippingPlanInformations = (selectedShippingPlan) => {
        let availableShippingPlanIds = this.props.shippingPlans.filter(shippingPlan => shippingPlan?.isAvailable)
            .map(shippingPlan => shippingPlan?.key);

        let unOrderedShippingPlanInformations = [...getConfigObjectByCulture(Config.shippingPlans)
            .filter(shippingPlan => availableShippingPlanIds?.includes(shippingPlan?.key))];

        unOrderedShippingPlanInformations = unOrderedShippingPlanInformations.map(el => {
            return {
                key: el.key,
                value: this.replaceLinks(el.value, this.getShippingPlanInformationLinks())
            }
        });
        return unOrderedShippingPlanInformations.sort((itemPrev, itemNext) => ((itemNext.key === selectedShippingPlan.key) + 0) - ((itemPrev.key === selectedShippingPlan.key) + 0));
    }

    replaceLinks = (text, linkes) => CommonHelper.stringFormat(text, ...linkes.map(el => this.getLink(el.name, el.url)));

    getLink = (name, url) => `<a href=${url} target='_blank'>${name}</a>`;

    getShippingPlanInformationLinks = () => [
        {
            id: 0,
            name: getTranslatedRawText('profile.delivery.readyToDeliverWithTheStatusShowing'),
            url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.readyToDeliverOrderInTheWarehouse)
        },
        {
            id: 1,
            name: getTranslatedRawText('profile.delivery.countyWarehouse'),
            url: `${getUrlWithCurrentCulture(Links, Links.contactUs)}?${CommonHelper.makeCorrectLink(getTranslatedRawText('singlePage.contact.common.countyWarehouse'))}`
        },
        {
            id: 2,
            name: getTranslatedRawText('profile.delivery.sixHoursProducts'),
            url: getUrlWithCurrentCulture(Links.view.main, Links.view.main.productCategories),
        },
        {
            id: 3,
            name: getTranslatedRawText('profile.orderInProcess.cutting'),
            url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrder),
        },
        {
            id: 4,
            name: getTranslatedRawText('profile.orderStateType.transferToWarehouse'),
            url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.readyToDeliverOrderInTheWarehouse),
        },
        {
            id: 5,
            name: getTranslatedRawText('profile.delivery.profileInformation'),
            url: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationSetting),
        },
        {
            id: 6,
            name: getTranslatedRawText('profile.delivery.agencyIsSelected'),
            url: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAgencySetting),
        },
        {
            id: 7,
            name: getTranslatedRawText('profile.delivery.centralOffice'),
            url: `${getUrlWithCurrentCulture(Links, Links.contactUs)}?${CommonHelper.makeCorrectLink(getTranslatedRawText('singlePage.contact.common.central-office'))}`
        }
    ];

    viewMoreButtonClicked = () => this.setState({ viewMore: !this.state.viewMore });

    render() {
        const { shippingPlanInformations, viewMore } = this.state;
        const { closeModal, selectedShippingPlan } = this.props;
        return (
            <NsModal
                dialogTitle={getTranslatedRawText('profile.delivery.shippingPlans')}
                showModal={true}
                closeModal={closeModal}>
                {shippingPlanInformations?.length > 0 && !isEmpty(shippingPlanInformations[0]?.key) &&
                    <div className='wrapper-white p-2'>
                        {shippingPlanInformations?.map((shippingPlanInformation, key) =>
                            (viewMore || shippingPlanInformation.key === selectedShippingPlan.key) && <NsHtmlToReact key={key}>
                                {shippingPlanInformation.value}
                            </NsHtmlToReact>)}
                        {shippingPlanInformations?.length > 1 &&
                            <NsButton label={viewMore ? 'product.common.viewLess' : 'product.common.viewMore'}
                                className="mb-3"
                                onClick={this.viewMoreButtonClicked} />
                        }
                    </div>
                }
            </NsModal>
        )
    }
}

export default UpShippingPlanInformationModal;