import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import Config from '../../configs/Config';

const GetIpLocationService = (callback) => {
    // GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.api, Links.api.ipStack), Config.ipApiKey.ipStack), {}, callback);
    let url = CommonHelper.stringFormat(Links.api.ipStack, Config.ipApiKey.ipStack);
    let headers = { 'Access-Control-Allow-Origin': Config.accessControlAllowOrigin, }
    fetch(url, { headers, })
        // fetch(url)
        .then(response => response.json())
        .then(data => callback(data))
        .catch(error => callback(null))
    // callback(null);
}

const GetAddressFromMapService = (lat, lng, callback) => {
    let url = CommonHelper.stringFormat(Links.api.addressFromMap, lat, lng);
    fetch(url)
        .then(response => response.json())
        .then(data => callback(data))
        .catch(error => callback(null))
}

const GetCountryService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.common, Links.api.common.getCountries), {}, callback);
}

const GetCountryNamesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.common, Links.api.common.getCountryNames), {}, callback);
}

const GetStateNamesService = (countryId, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.common, Links.api.common.getStateNames), { Id: countryId }, callback);
}

const GetCityNamesService = (stateId, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.common, Links.api.common.getCityNames), { stateId: stateId }, callback);
}

const GetGenderService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.common, Links.api.common.getGender), {}, callback);
}

const GetCategoriesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.common, Links.api.common.getCategories), {}, callback);
}

const GetStoreCategoriesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.common, Links.api.common.getStoreCategories), {}, callback);
}

const GetImageService = (url, callback) => {
    GetRequest(url, {}, callback);
}

const GetCurrenciesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.common, Links.api.common.getCurrencies), {}, callback);
}

export {
    GetIpLocationService,
    GetCountryNamesService,
    GetStateNamesService,
    GetCityNamesService,
    GetCountryService,
    GetGenderService,
    GetCategoriesService,
    GetImageService,
    GetCurrenciesService,
    GetAddressFromMapService,
    GetStoreCategoriesService
}