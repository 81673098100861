import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import { GetCanceledStoreOrdersService, GetDeliveredStoreOrdersService } from '../../../../../../services/ProfileSetting/StoreOrderService';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import NsStickyButton from '../../../../../../components/buttons/NsStickyButton';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import UserPanelWrapper from '../../../../UserPanelWrapper';
import UpBaseStoreOrdersGrid from '../bases/UpBaseStoreOrdersGrid';
import Enumerations from '../../../../../../configs/Enumerations';
import NsTab from '../../../../../../components/tabs/NsTab';
import UpBaseStoreOrderSearchSection from '../bases/UpBaseStoreOrderSearchSection';

class UpStoreArchiveOrdersContent extends React.Component {

    constructor(props) {
        super(props);
        this.tabs = ConstantsWithTranslation.getStoreOrderArchiveTabs();
        this.selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);
        this.state = {
            selectedTab: this.selectedTab || this.tabs[0],
            selectedTabIndex: this.tabs.indexOf(this.selectedTab) > -1 ? this.tabs.indexOf(this.selectedTab) : 0,
            searchValues: null,
            callServiceDate: new Date(),
            isLoading: false,
            resultMessageClass: (<></>),
        };
    }

    tabChanged = (newValue, selectedTab) => newValue !== this.state.selectedTabIndex &&
        this.setState({ selectedTabIndex: newValue, selectedTab, isLoading: true },
            () => !!this.props.history && this.props.history.push(selectedTab.url));

    getData = (rows, isLoading) => this.setState({ isLoading });

    excelReportButtonClicked = () => { };

    searchFormClicked = (searchValues) => this.setState({ searchValues, isLoading: true, callServiceDate: new Date() });

    render() {
        const { selectedTabIndex, callServiceDate, isLoading, resultMessageClass, selectedTab, searchValues } = this.state;
        const mainButtons = [
            { label: "regularOrder.common.exportExcel", icon: 'ns-icon-excel', onClicked: this.excelReportButtonClicked, className: 'secondary-outline', disabled: true },
        ];
        return (
            <div className="w-100">
                <UpBaseStoreOrderSearchSection
                    searchFormClicked={this.searchFormClicked}
                    findOrdersSearchSection
                    autoFocus
                    isLoading={isLoading}>
                </UpBaseStoreOrderSearchSection>

                <NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />

                <NsTab value={selectedTabIndex}
                    tabs={this.tabs}
                    onChange={this.tabChanged} />

                <div className='w-100'>
                    <UpBaseStoreOrdersGrid callServiceDate={callServiceDate}
                        parameters={{ filter: searchValues }}
                        service={selectedTab?.id == Enumerations.sharedSheetOrderStateType.delivered ? GetDeliveredStoreOrdersService : GetCanceledStoreOrdersService}
                        hasPagination={true}
                        gridId={selectedTab?.id == Enumerations.sharedSheetOrderStateType.canceled ? GridEnumerations.siteGrids.canceledStoreOrders : GridEnumerations.siteGrids.archiveStoreOrders}
                        hasCustomColumnOrder={true}
                        getData={this.getData} />
                </div>
                {resultMessageClass}
            </div>
        );
    }
}

export { UpStoreArchiveOrdersContent };

const UpStoreArchiveOrders = (props) => {
    return <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.archive')}
        titleIcon='ns-icon-archived-order'>
        <UpStoreArchiveOrdersContent {...props} />
    </UserPanelWrapper>
}

export default withRouter(UpStoreArchiveOrders);

