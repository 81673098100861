import React from "react";
import { Row } from "react-bootstrap";

import NsTextExtender from "../../../../components/textExtender/NsTextExtender";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import NsButton from "../../../../components/buttons/NsButton";
import CommonHelper from "../../../../utilities/CommonHelper";
import NsImage from "../../../../components/images/NsImage";
import DateHelper from "../../../../utilities/DateHelper";
import NsTags from "../../../../components/tags/NsTags";
import DownloadItems from "./DownloadItems";

class DownloadViewList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMore: false,
            downloadAllDate: null,
        }
    }

    showMoreHandler = () => this.setState({ showMore: !this.state.showMore });

    // downloadAllClicked = () => this.setState({ downloadAllDate: new Date() });

    render() {
        const { showMore, downloadAllDate } = this.state;
        const { downloadCategoryDetail } = this.props;

        return (<>
            <div className="d-flex flex-column border rounded p-2 gap-2">
                <div className="d-flex gap-2">
                    <div >
                        {downloadCategoryDetail.fileUrl
                            && <NsImage wrapperClassName='w-auto'
                                src={CommonHelper.createImageUrl(downloadCategoryDetail.fileUrl) || ''}
                                width='50px'
                                imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.contentImage"), downloadCategoryDetail.title)} />
                        }
                    </div>
                    <div className="d-flex flex-column w-100">
                        <div className="section-title">
                            {downloadCategoryDetail.title}
                            {!isEmpty(downloadCategoryDetail.releaseDate) && <span className="px-1 font-size-10 fw-normal">{DateHelper.getDatePart(downloadCategoryDetail.releaseDate)}</span>}
                        </div>
                        <NsTextExtender text={downloadCategoryDetail.summary}
                            hasViewMore={true}
                            className='flex-wrap'
                            viewLessClassName='text-truncate w-25' />

                        {!isEmpty(downloadCategoryDetail.tags)
                            ? <>
                                <div className='mb-2 ms-2 fw-bold'>{getTranslatedRawText('adminPanel.post.tags')}</div>
                                <NsTags tags={downloadCategoryDetail.tags.split(/[-,]/)} />
                            </>
                            : ''}

                    </div>
                </div>
                {!isEmpty(downloadCategoryDetail.downloadDetails) &&
                    <NsButton onClick={this.showMoreHandler}
                        size='small'
                        label='singlePage.download.showDownloadItems'
                        className='primary-outline'
                        wrapperClassName='ms-auto'
                        startIcon={showMore ? 'ns-icon-expand-up' : 'ns-icon-expand-down'} />
                }
            </div>
            <Row className="g-2 my-1">
                {showMore &&
                    <>
                        <DownloadItems downloadDetails={downloadCategoryDetail.downloadDetails}
                            downloadAllDate={downloadAllDate} />
                        {/* {downloadCategoryDetail.downloadDetails?.length > 1 &&
                            <div className="d-flex align-item-center ms-auto">
                                <NsButton label='singlePage.download.downloadAll'
                                    startIcon='ns-icon-download'
                                    size='small'
                                    disabled={!showMore}
                                    className='primary-text'
                                    onClick={this.downloadAllClicked} />
                            </div>
                        } */}
                    </>
                }
            </Row>
        </>
        );
    }
}

export default DownloadViewList;