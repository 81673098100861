import React, { useEffect, useState } from 'react';

import { GetStatisticalReportOfPaymentsService } from '../../../../services/adminPanel/AdminReportService';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import { getApStatisticalReportOfPaymentsColumn } from './ApStaticalReportHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApStatisticalReportSearch from './ApStatisticalReportSearch';
import DateHelper from '../../../../utilities/DateHelper';

const ApStatisticalReportOfPayments = () => {

    const columns = getApStatisticalReportOfPaymentsColumn();

    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [callServiceDate, setCallServiceDate] = useState(null);

    useEffect(() => {
        setCallServiceDate(new Date());
        setIsLoading(true);
    }, [searchData]);

    const getParameters = () => {
        return {
            "Filter.FromTime": DateHelper.convertDateTimeToDate(searchData?.reportDateFrom || DateHelper.getPreviousWeek()),
            "Filter.ToTime": DateHelper.convertDateTimeToDate(searchData?.reportDateTo || new Date()),
        }
    }

    const searchClicked = (data) => setSearchData(data);

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.title.statisticalReportOfPayments")}
            icon={'ns-icon-reports h1'}>

            <ApStatisticalReportSearch isLoading={isLoading}
                searchClicked={searchClicked} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetStatisticalReportOfPaymentsService}
                parameters={getParameters()}
                columnDefs={columns}
                getData={getData} />
        </NsAdminPanelContentWrapper>
    );
};

export default React.memo(ApStatisticalReportOfPayments);