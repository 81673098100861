import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PostRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getApiUrlWithCurrentCulture } from '../bases/GetApiUrlWithCurrentCulture';


const GetStoreProductsService = (parameters, callback) => {
    GetRequest(getApiUrlWithCurrentCulture(Links.api.storeProducts, Links.api.storeProducts.getStoreProductList), parameters, callback);
}

const GetStoreProductInfoService = (id, callback) => {
    let parameters = { id: CommonHelper.toInt(id) };
    GetRequest(getUrlWithCurrentCulture(Links.api.storeProducts, Links.api.storeProducts.getStoreProductInfo), parameters, callback);
}

const GetStoreProductStatusHistoryService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.storeProducts, Links.api.storeProducts.stateHistoriesStoreOrder), parameters, callback);
}

const GetStoreProductSuppliersService = (parameters, callback) => {
    GetRequest(getApiUrlWithCurrentCulture(Links.api.storeProducts, Links.api.storeProducts.getStoreProductSuppliers), parameters, callback);
}

const PaymentByCreditStoreOrderService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeProducts, Links.api.storeProducts.paymentByCreditStoreOrder), data, callback, true);
}

const AddToCartStoreProductService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.storeProducts, Links.api.storeProducts.addToCartStoreProduct), data, callback);
}

const GetStoreProductCartItemsCountService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.storeProducts, Links.api.storeProducts.getStoreCartItemsCount), {}, callback);
}

const GetStoreProductOrderQueueStatusService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.storeProducts, Links.api.storeProducts.getStoreProductOrderQueueStatus), parameters, callback);
}

export {
    GetStoreProductsService,
    GetStoreProductInfoService,
    GetStoreProductStatusHistoryService,
    GetStoreProductSuppliersService,
    AddToCartStoreProductService,
    GetStoreProductCartItemsCountService,
    PaymentByCreditStoreOrderService,
    GetStoreProductOrderQueueStatusService
}