import React, { useState } from 'react';

import UpBaseQueueOrdersHeader from '../../../../profileSettings/contents/userOrder/queue/bases/UpBaseQueueOrdersHeader';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import Enumerations from '../../../../../configs/Enumerations';
import ApOrderQueueShareSheet from './ApOrderQueueShareSheet';
import DateHelper from '../../../../../utilities/DateHelper';
import NsTab from '../../../../../components/tabs/NsTab';
import ApOrderQueueStore from './ApOrderQueueStore';

const ApOrderQueue = () => {

    const tabs = CommonHelper.clearArrayFromEmptyItem(ConstantsWithTranslation.getOrderProductTabs(hasAccess([UserClaimEnumerations.getApStatusQueue]), true));
    // const tabs = CommonHelper.clearArrayFromEmptyItem(ConstantsWithTranslation.getOrderProductTabs(true, false));
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [fromDate, setFromDate] = useState(new Date(DateHelper.getCustomPreviousMonth(2)));
    const [toDate, setToDate] = useState(null);
    const [refreshDate, setRefreshDate] = useState(null);

    const tabChanged = (newValue, selectedTab) => {
        if (newValue !== selectedTabIndex) {
            setSelectedTabIndex(newValue);
            setSelectedTab(selectedTab);
        }
    }

    const updateDates = (fromDate, toDate, refreshDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
        setRefreshDate(refreshDate);
    }

    return (
        <>
            <div className='d-flex justify-content-end gap-2 mb-3 px-3'>
                <UpBaseQueueOrdersHeader updateDates={updateDates} />
            </div>

            {tabs.length > 1 && <NsTab tabs={tabs}
                className='mb-3'
                value={selectedTabIndex}
                onChange={tabChanged} />
            }

            {selectedTab?.id === Enumerations.orderProductType.sharedSheet
                ? < ApOrderQueueShareSheet toDate={toDate}
                    fromDate={fromDate}
                    refreshDate={refreshDate} />
                : < ApOrderQueueStore toDate={toDate}
                    fromDate={fromDate}
                    refreshDate={refreshDate} />
            }
        </>
    );
}

export default React.memo(ApOrderQueue);