import React, { useEffect, useState } from 'react';
import NsCard from '../../../../components/cards/NsCard';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsTypography from '../../../../components/text/NsTypography';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import { GetPopularAgenciesService } from '../../../../services/adminPanel/AdminDashboardService';

const ApDaPopularAgencies = ({ refreshDate, parameters }) => {

    const [popularAgencies, setPopularAgencies] = useState([]);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getPopularAgencies()
    }, [refreshDate])

    const getPopularAgencies = () => {
        GetPopularAgenciesService(parameters, getPopularAgenciesCallback)
    }

    const getPopularAgenciesCallback = (resultData, result, resultMessageClass) => {
        if (!result.data.hasError) {
            setPopularAgencies(resultData)
        } else setResultMessageClass(resultMessageClass)
    }

    return (
        <NsCard className='p-3 h-100 '>
            <div className='fw-bold mb-4'>{getTranslatedRawText('adminPanel.dashboard.popularAgencies')}</div>
            {isEmpty(popularAgencies)
                ? <NsTypography variant='inherit' className="border p-2"> {getTranslatedRawText('components.nsDataGrid.noRowData')}</NsTypography>
                : popularAgencies.map((agency, index) => {
                    return <div key={index} className='d-flex justify-content-between py-3 border-bottom'>
                        <div>{agency.agencyName}</div>
                        <div className='fw-bold'>{agency.orderAmount}</div>
                    </div>
                })
            }
            {resultMessageClass}
        </NsCard >
    )
}

export default React.memo(ApDaPopularAgencies);