import React from 'react';
import '../../assets/sass/components/etiquette/NsEtiquette.scss';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CommonHelper from '../../utilities/CommonHelper';
import NsTypography from '../text/NsTypography';
import NsSkeleton from '../skeletons/NsSkeleton';

class NsEtiquette extends React.Component {

    render() {
        const { style, border, errorText, index, title, deleteEtiquette, children, isLoading } = this.props;

        return (
            <div key={index} className={`etiquette-box ${border} background-element-color my-1 w-100`}>
                <div className={`etiquette-text d-flex align-items-center justify-content-between w-50 ms-auto p-1 px-2 ${style}`}>
                    <NsTypography variant='inherit' className='font-size-14 fw-bold'>
                        {getTranslatedRawText(title)} {index + 1}
                    </NsTypography>
                    {!!deleteEtiquette &&
                        <button className={`border-0 p-0 ${style}`}
                            onClick={() => CommonHelper.safeFunctionCall(deleteEtiquette, index)}>
                            <i className='ns-icon-close h5 m-0' />
                        </button>
                    }
                </div>
                {isLoading
                    ? <NsSkeleton className='mx-1'
                        isVisible={isLoading}
                        variant={[1, 1]} />
                    : children}
                <div className='ps-3 pb-2 text-start'>
                    <NsTypography variant='inherit' className='font-size-12 red-color'>
                        {errorText}
                    </NsTypography>
                </div>
            </div>
        );

    }
}

export default NsEtiquette;