
import { Redirect } from 'react-router-dom';
import Enumerations from '../configs/Enumerations';

import MainLayoutRoute from '../views/layouts/main/MainLayoutRoute';
//file manager
import FileManager from '../views/fileManager/FileManager';
import TestApis from '../views/samples/TestApis';
//profile setting
import UpProfileInformationSetting from '../views/profileSettings/contents/userProfile/UpProfileInformationSetting';
import UpProfileFeedbackSetting from '../views/profileSettings/contents/userProfile/UpProfileFeedbackSetting';
import UpProfileNotificationSetting from '../views/profileSettings/contents/userProfile/UpProfileNotificationSetting';
import UpProfileAgencySetting from '../views/profileSettings/contents/userProfile/UpProfileAgencySetting';
import UpProfileAutoShippingSetting from '../views/profileSettings/contents/userProfile/UpProfileAutoShippingSetting';
import UpProfileAddressSetting from '../views/profileSettings/contents/userProfile/UpProfileAddressSetting';
import ProfilPublicUser from '../views/singlePages/mainSections/feedbacks/ProfilPublicUser';

//user Agency
import UpAgencyInformation from '../views/profileSettings/contents/userAgency/UpAgencyInformation';
import UpAgencyCustomersOrders from '../views/profileSettings/contents/userAgency/customerOrders/UpAgencyCustomersOrders';
import UpAgencyProducts from '../views/profileSettings/contents/userAgency/UpAgencyProducts';
import UpAgencyCustomers from '../views/profileSettings/contents/userAgency/UpAgencyCustomers';
import UpAgencyShipping from '../views/profileSettings/contents/userAgency/UpAgencyShipping';
// user order
import UpCartOrder from '../views/profileSettings/contents/userOrder/carts/UpCartOrder';
import UpGroupSheetPreparingOrders from '../views/profileSettings/contents/userOrder/groupSheets/preparing/UpGroupSheetPreparingOrders';
import UpGroupSheetInProcessOrders from '../views/profileSettings/contents/userOrder/groupSheets/inProcess/UpGroupSheetInProcessOrders';
import UpGroupSheetReadyToDeliverOrders from '../views/profileSettings/contents/userOrder/groupSheets/readyToDeliver/UpGroupSheetReadyToDeliverOrders';
import UpGroupSheetArchiveOrders from '../views/profileSettings/contents/userOrder/groupSheets/archive/UpGroupSheetArchiveOrders';
import UpGroupSheetAllOrders from '../views/profileSettings/contents/userOrder/groupSheets/all/UpGroupSheetAllOrders';
import UpShippings from '../views/profileSettings/contents/userShippings/UpShippings';
import UpPaymentsReadyToPay from '../views/profileSettings/contents/userPayments/UpPaymentsReadyToPay';
import UpQueueOrders from '../views/profileSettings/contents/userOrder/queue/UpQueueOrders';
import FileCorrection from '../views/profileSettings/contents/userOrder/fileCorrection/FileCorrection';
import UpPaymentsIpgResult from '../views/profileSettings/contents/userPayments/UpPaymentsIpgResult';
//reports
import UpReportOrderPayment from '../views/profileSettings/contents/reports/UpReportOrderPayment';
import UpReportFinancial from '../views/profileSettings/contents/reports/UpReportFinancial';
//user dashboard
import UpDashboardCashback from '../views/profileSettings/contents/userDashboard/cashback/UpDashboardCashback';
//products
import Products from '../views/products/Products';
import StoreProducts from '../views/products/StoreProducts';
//product Details
import PdrRegularDetail from '../views/productDetails/regularDetails/PdrRegularDetail';
import PdpProfessionalDetail from '../views/productDetails/professionalDetails/PdpProfessionalDetail';
//professional order
import ProfessionalOrder from '../views/orders/professionalOrders/ProfessionalOrder';
//store order
import PdsStoreDetail from '../views/productDetails/storeDetails/PdsStoreDetail';
//regular order
import MasterFile from '../views/orders/regularOrders/mainContents/choosingFiles/MasterFile';
import FilmFile from '../views/orders/regularOrders/mainContents/choosingFiles/FilmFile';
import ExtendedServiceFoldLine from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceFoldLine';
import ExtendedServiceFilter from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceFilter';
import ExtendedServiceCornerType from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceCornerType';
import ExtendedServicePunch from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServicePunch';
import ExtendedServiceGripperEdgeType from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceGripperEdgeType';
import ExtendedServiceBillBook from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceBillBook';
import ExtendedServiceTopFolding from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceTopFolding';
import ExtendedServiceHandleType from '../views/orders/regularOrders/mainContents/extendedservices/ExtendedServiceHandleType';
import ConfirmDetail from '../views/orders/regularOrders/mainContents/confirmDetails/ConfirmDetail';
import RegularOrderSaveResult from '../views/orders/regularOrders/RegularOrderSaveResult';
//singlePages
import Home from '../views/home/Home';
import AboutUs from '../views/singlePages/mainSections/aboutUs/AboutUs';
import ContactUs from '../views/singlePages/mainSections/contactUs/ContactUs';
import InstantDiscounts from '../views/singlePages/mainSections/instantDiscounts/InstantDiscounts';
import FrequentlyAskedQuestions from '../views/singlePages/mainSections/frequentlyAskedQuestions/FrequentlyAskedQuestions'
import PrivacyPolicy from '../views/singlePages/mainSections/privacyPolicy/PrivacyPolicy';
import Gallery from '../views/singlePages/mainSections/gallery/Gallery';
import WarrantyTerms from '../views/singlePages/mainSections/warrantyTerms/WarrantyTerms';
import OurEquipment from '../views/singlePages/mainSections/ourEquipment/OurEquipment';
import TermsOfUse from '../views/singlePages/mainSections/termsOfUse/TermsOfUse';
import SiteMap from '../views/singlePages/mainSections/siteMap/SiteMap';
import FestivalDetail from '../views/singlePages/mainSections/festivals/festivalDetail/FestivalDetail';
import Festivals from '../views/singlePages/mainSections/festivals/Festivals';
import DownloadCategories from '../views/singlePages/mainSections/downloads/DownloadCategories';
import DownloadCategoryDetail from '../views/singlePages/mainSections/downloads/DownloadCategoryDetail';
import GalleryCategories from '../views/singlePages/mainSections/gallery/GalleryCategories';
import SpecialNeeds from '../views/singlePages/mainSections/specialNeeds/SpecialNeeds';
import SaveOutputInPdfFormat from '../views/singlePages/mainSections/SaveOutputInPdfFormat/SaveOutputInPdfFormat';

//blogs and news
import BnContentMain from '../views/blogs/contents/main/BnContentMain';
import BnContentCategories from '../views/blogs/contents/categories/BnContentCategories';
import BnContentCategoryPosts from '../views/blogs/contents/categoryPosts/BnContentCategoryPosts';
import BnContentPostDetail from '../views/blogs/contents/postDetails/BnContentPostDetail';
import BnContentSearchResultPost from '../views/blogs/contents/searchResultPosts/BnContentSearchResultPost';
//store
import UpStorePreparingOrders from '../views/profileSettings/contents/userOrder/stores/preparing/UpStorePreparingOrders';
import UpStoreReadyToDeliverOrders from '../views/profileSettings/contents/userOrder/stores/readyToDeliver/UpStoreReadyToDeliverOrders';
import UpStoreArchiveOrders from '../views/profileSettings/contents/userOrder/stores/archive/UpStoreArchiveOrders';
//exception
import Exception404 from '../views/singlePages/exceptions/Exception404';
//shopping bag
import ShoppingBagMain from '../views/products/shoppingBags/ShoppingBagMain';
import ShoppingBagFaqs from '../views/products/shoppingBags/ShoppingBagFaqs';
import ShoppingBagGalleries from '../views/products/shoppingBags/ShoppingBagGalleries';
import ShoppingBagExploreProduct from '../views/products/shoppingBags/ShoppingBagExploreProduct';
import ShoppingBagAdvantages from '../views/products/shoppingBags/ShoppingBagAdvantages';
import Categories from '../views/products/categories/Categories';

const MainRoutes = [
    { Layout: MainLayoutRoute, path: "/:lng/", Component: Home, breadCrumb: [Enumerations.breadCrumbs.home], title: '', exact: true, priority: 0.9 },
    { Layout: MainLayoutRoute, path: "/:lng/about-us", Component: AboutUs, breadCrumb: [Enumerations.breadCrumbs.aboutUs], title: 'common.breadcrumbs.about', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/contact-us", Component: ContactUs, breadCrumb: [Enumerations.breadCrumbs.contactUs], title: 'common.breadcrumbs.contactUs', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/instant-discounts", Component: InstantDiscounts, breadCrumb: [Enumerations.breadCrumbs.instantDiscounts], title: 'common.breadcrumbs.instantDiscounts', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/our-equipment", Component: OurEquipment, breadCrumb: [Enumerations.breadCrumbs.ourEquipment], title: 'common.breadcrumbs.ourEquipment', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/frequently-asked-questions", Component: FrequentlyAskedQuestions, breadCrumb: [Enumerations.breadCrumbs.faqs], title: 'common.breadcrumbs.FAQs', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/gallery", Component: Gallery, breadCrumb: [Enumerations.breadCrumbs.gallery], title: 'adminPanel.sidebar.gallery', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/gallery/category/:id/:name", Component: GalleryCategories, breadCrumb: [Enumerations.breadCrumbs.galleryCategories], title: 'common.breadcrumbs.galleryCategories', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/terms-of-use", Component: TermsOfUse, breadCrumb: [Enumerations.breadCrumbs.termOfUse], title: 'common.breadcrumbs.termsOfUse', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/privacy-policy", Component: PrivacyPolicy, breadCrumb: [Enumerations.breadCrumbs.privacyPolicy], title: 'common.breadcrumbs.privacyPolicy', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/warranty-terms", Component: WarrantyTerms, breadCrumb: [Enumerations.breadCrumbs.warrantyTerms], title: 'common.breadcrumbs.warrantyTerms', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/site-map", Component: SiteMap, breadCrumb: [Enumerations.breadCrumbs.siteMap], title: 'common.breadcrumbs.siteMap', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/festivals", Component: Festivals, breadCrumb: [Enumerations.breadCrumbs.festivals], title: 'common.breadcrumbs.festivals', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/festival/event/:id", Component: FestivalDetail, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.festival', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/download-center", Component: DownloadCategories, breadCrumb: [Enumerations.breadCrumbs.downloadCenter], title: 'adminPanel.sidebar.downloadCenter', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/download-center/:id/:name", Component: DownloadCategories, breadCrumb: [Enumerations.breadCrumbs.downloadCenter], title: 'adminPanel.sidebar.downloadCenter', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/download-center/:id/detail/:name", Component: DownloadCategoryDetail, breadCrumb: [Enumerations.breadCrumbs.downloadDetail], title: 'common.breadcrumbs.downloadDetail', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/special-needs", Component: SpecialNeeds, breadCrumb: [Enumerations.breadCrumbs.specialNeeds], title: 'common.breadcrumbs.specialNeeds', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/save-output-in-pdf-format", Component: SaveOutputInPdfFormat, breadCrumb: [Enumerations.breadCrumbs.saveOutputInPdfFormat], title: 'common.breadcrumbs.saveOutputInPdfFormat', exact: true, },
    { Layout: MainLayoutRoute, path: "/:lng/profile/:id", Component: ProfilPublicUser, breadCrumb: [Enumerations.breadCrumbs.supporterProfile], title: 'common.breadcrumbs.publicProfile', exact: false, },

    { Layout: MainLayoutRoute, path: "/:lng/blogs", Component: BnContentMain, breadCrumb: [Enumerations.breadCrumbs.blogs], title: 'adminPanel.sidebar.blog', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/blogs/post/:id/detail/:name", Component: BnContentPostDetail, breadCrumb: [Enumerations.breadCrumbs.blogPostDetail], title: 'adminPanel.sidebar.blog', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/blogs/categories", Component: BnContentCategories, breadCrumb: [Enumerations.breadCrumbs.blogCategories], title: 'adminPanel.sidebar.blog', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/blogs/category/:id/posts/:name", Component: BnContentCategoryPosts, breadCrumb: [Enumerations.breadCrumbs.blogCategoryPosts], title: 'adminPanel.sidebar.blog', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/blogs/posts/result", Component: BnContentSearchResultPost, breadCrumb: [Enumerations.breadCrumbs.blogPosts], title: 'adminPanel.sidebar.blog', exact: true },

    { Layout: MainLayoutRoute, path: "/:lng/news", Component: BnContentMain, breadCrumb: [Enumerations.breadCrumbs.news], title: 'adminPanel.sidebar.news', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/news/post/:id/detail", Component: BnContentPostDetail, breadCrumb: [Enumerations.breadCrumbs.newsPostDetail], title: 'adminPanel.sidebar.news', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/news/categories", Component: BnContentCategories, breadCrumb: [Enumerations.breadCrumbs.newsCategories], title: 'adminPanel.sidebar.news', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/news/category/:id/categoryName/:name/posts", Component: BnContentCategoryPosts, breadCrumb: [Enumerations.breadCrumbs.newsCategoryPosts], title: 'adminPanel.sidebar.news', exact: true },
    { Layout: MainLayoutRoute, path: "/:lng/news/posts/result", Component: BnContentSearchResultPost, breadCrumb: [Enumerations.breadCrumbs.newsPosts], title: 'adminPanel.sidebar.news', exact: true },


    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/cart", Component: UpCartOrder, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.orderCard], title: 'common.breadcrumbs.ordersCart', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/group-sheet/preparing", Component: UpGroupSheetPreparingOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.orderPreparing], title: 'common.breadcrumbs.ordersPreparing', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/group-sheet/in-process", Component: UpGroupSheetInProcessOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.orderInProcess], title: 'common.breadcrumbs.ordersInProcess', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/group-sheet/ready-to-deliver", Component: UpGroupSheetReadyToDeliverOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.orderReady], title: 'common.breadcrumbs.ordersReady', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/group-sheet/archive", Component: UpGroupSheetArchiveOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.orderArchive], title: 'common.breadcrumbs.ordersArchive', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/group-sheet/all-order", Component: UpGroupSheetAllOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.orderAll], title: 'common.breadcrumbs.allOrders', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/group-sheet/queue-status", Component: UpQueueOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.userOrdersQueue], title: 'adminPanel.sidebar.queue', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/file-correction", Component: FileCorrection, needAuthentication: true, breadCrumb: [], title: 'profile.fileCorrection.fileCorrection', exact: true },

    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/stores/in-process", Component: UpStorePreparingOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.storeOrderInProcess], title: 'common.breadcrumbs.ordersInProcess', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/stores/ready-to-deliver", Component: UpStoreReadyToDeliverOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.storeOrderReady], title: 'common.breadcrumbs.ordersReady', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/stores/archive", Component: UpStoreArchiveOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.storeOrderArchive], title: 'common.breadcrumbs.ordersArchive', exact: false, },

    { Layout: MainLayoutRoute, path: "/:lng/user-panel/orders/my-shippings", Component: UpShippings, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.myShipping], title: 'common.breadcrumbs.deliveryRequest', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/reports/debit-credit-report", Component: UpReportFinancial, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.reportFinancial], title: 'common.breadcrumbs.debitCreditReport', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/reports/payment-report", Component: UpReportOrderPayment, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.reportPayment], title: 'common.breadcrumbs.paymentReport', exact: false, },

    { Layout: MainLayoutRoute, path: "/:lng/user-panel/dashboard/cashback", Component: UpDashboardCashback, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.cashback], title: 'common.breadcrumbs.cashback', exact: false, },

    { Layout: MainLayoutRoute, path: "/:lng/user-panel/agency/information", Component: UpAgencyInformation, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.agencyInformation], title: 'common.breadcrumbs.agencyInformation', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/agency/customers", Component: UpAgencyCustomers, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.agencyCustomers], title: 'common.breadcrumbs.agencyCustomers', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/agency/customers-orders", Component: UpAgencyCustomersOrders, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.agencyCustomersOrders], title: 'common.breadcrumbs.agencyCustomersOrders', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/agency/products", Component: UpAgencyProducts, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.agencyProducts], title: 'common.breadcrumbs.agencyProducts', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/agency/shipping", Component: UpAgencyShipping, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.agencyShipping], title: 'common.breadcrumbs.agencyShipping', exact: false, },

    { Layout: Redirect, from: "/:lng/user", to: "/:lng/user-panel/orders/cart", breadCrumb: [Enumerations.breadCrumbs.orderCard], title: 'common.breadcrumbs.ordersCart', exact: false },
    { Layout: Redirect, from: "/:lng/user-panel/orders", to: "/:lng/user-panel/orders/cart", breadCrumb: [Enumerations.breadCrumbs.orderCard], title: 'common.breadcrumbs.ordersCart', exact: false },

    { Layout: MainLayoutRoute, path: "/:lng/user-payment/:id/ready-to-pay", Component: UpPaymentsReadyToPay, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.chooseGateway', exact: false },
    { Layout: MainLayoutRoute, path: "/:lng/user-payment/payment-result", Component: UpPaymentsIpgResult, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.resultPayment', exact: false },


    { Layout: MainLayoutRoute, path: "/:lng/user-panel/profile/information-setting", Component: UpProfileInformationSetting, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.profileInformation], title: 'common.breadcrumbs.profileInformation', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/profile/feedback-setting", Component: UpProfileFeedbackSetting, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.profileFeebacks], title: 'profile.feedback.feedbacks', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/profile/notification-setting", Component: UpProfileNotificationSetting, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.profileNotification], title: 'common.breadcrumbs.notificationSetting', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/profile/agency-setting", Component: UpProfileAgencySetting, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.profileAgency], title: 'common.breadcrumbs.agencySetting', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/profile/auto-shipping-setting", Component: UpProfileAutoShippingSetting, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.profileAutoShipping], title: 'common.breadcrumbs.autoDeliverySetting', exact: false, },
    { Layout: MainLayoutRoute, path: "/:lng/user-panel/profile/address-setting", Component: UpProfileAddressSetting, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.profileAddress], title: 'common.breadcrumbs.address', exact: false, },
    { Layout: Redirect, from: "/:lng/user-panel/profile", to: "/:lng/user-panel/profile/information-setting", breadCrumb: [Enumerations.breadCrumbs.profileInformation], title: 'common.breadcrumbs.profileInformation', exact: false, },

    { Layout: MainLayoutRoute, path: "/:lng/products/category/:categoryId/:categoryName", Component: Products, breadCrumb: [Enumerations.breadCrumbs.products], title: 'common.breadcrumbs.products', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products", Component: Products, breadCrumb: [Enumerations.breadCrumbs.products], title: 'common.breadcrumbs.products', exact: true, showResetTour: true, priority: 1.0 },
    { Layout: MainLayoutRoute, path: "/:lng/products/categories", Component: Categories, breadCrumb: [Enumerations.breadCrumbs.productCategories], title: 'common.breadcrumbs.productCategories', exact: true },

    { Layout: MainLayoutRoute, path: "/:lng/shopping-bag", Component: ShoppingBagMain, breadCrumb: [Enumerations.breadCrumbs.paperBag], title: 'common.breadcrumbs.paperBag', exact: true, showResetTour: false, priority: 1.0 },
    { Layout: MainLayoutRoute, path: "/:lng/shopping-bag/frequently-asked-questions", Component: ShoppingBagFaqs, breadCrumb: [Enumerations.breadCrumbs.paperBagFaqs], title: 'common.breadcrumbs.paperBagFaq', exact: true, showResetTour: false, priority: 0.5 },
    { Layout: MainLayoutRoute, path: "/:lng/shopping-bag/gallery", Component: ShoppingBagGalleries, breadCrumb: [Enumerations.breadCrumbs.paperBagGalleries], title: 'common.breadcrumbs.paperBagGallery', exact: true, showResetTour: false, priority: 0.5 },
    { Layout: MainLayoutRoute, path: "/:lng/shopping-bag/explore-product", Component: ShoppingBagExploreProduct, breadCrumb: [Enumerations.breadCrumbs.paperBagExploreProduct], title: 'common.breadcrumbs.paperBagExploreProduct', exact: true, showResetTour: false, priority: 0.5 },
    { Layout: MainLayoutRoute, path: "/:lng/shopping-bag/advantage", Component: ShoppingBagAdvantages, breadCrumb: [Enumerations.breadCrumbs.paperBagAdvantages], title: 'common.breadcrumbs.paperBagAdvantage', exact: true, showResetTour: false, priority: 0.5 },

    { Layout: MainLayoutRoute, path: "/:lng/paper-bag", Component: ShoppingBagMain, breadCrumb: [Enumerations.breadCrumbs.paperBag], title: 'common.breadcrumbs.paperBag', exact: true, showResetTour: false, priority: 1.0 },
    { Layout: MainLayoutRoute, path: "/:lng/paper-bag/frequently-asked-questions", Component: ShoppingBagFaqs, breadCrumb: [Enumerations.breadCrumbs.paperBagFaqs], title: 'common.breadcrumbs.paperBagFaq', exact: true, showResetTour: false, priority: 0.5 },
    { Layout: MainLayoutRoute, path: "/:lng/paper-bag/gallery", Component: ShoppingBagGalleries, breadCrumb: [Enumerations.breadCrumbs.paperBagGalleries], title: 'common.breadcrumbs.paperBagGallery', exact: true, showResetTour: false, priority: 0.5 },
    { Layout: MainLayoutRoute, path: "/:lng/paper-bag/explore-product", Component: ShoppingBagExploreProduct, breadCrumb: [Enumerations.breadCrumbs.paperBagExploreProduct], title: 'common.breadcrumbs.paperBagExploreProduct', exact: true, showResetTour: false, priority: 0.5 },
    { Layout: MainLayoutRoute, path: "/:lng/paper-bag/advantage", Component: ShoppingBagAdvantages, breadCrumb: [Enumerations.breadCrumbs.paperBagAdvantages], title: 'common.breadcrumbs.paperBagAdvantage', exact: true, showResetTour: false, priority: 0.5 },

    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/detail/:name", Component: PdrRegularDetail, breadCrumb: [Enumerations.breadCrumbs.roProductDetail], title: '', exact: true, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/choosing-master-file", Component: MasterFile, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: true, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/choosing-film-file-:filmNo", Component: FilmFile, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: true, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/extended-service/fold-line", Component: ExtendedServiceFoldLine, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/extended-service/filters", Component: ExtendedServiceFilter, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/extended-service/rounded-corner", Component: ExtendedServiceCornerType, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/extended-service/punch", Component: ExtendedServicePunch, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/extended-service/gripper-edge", Component: ExtendedServiceGripperEdgeType, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/extended-service/bill-book", Component: ExtendedServiceBillBook, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/extended-service/top-folding", Component: ExtendedServiceTopFolding, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/extended-service/handle-type", Component: ExtendedServiceHandleType, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/confirm-detail", Component: ConfirmDetail, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/regular/order/save-result", Component: RegularOrderSaveResult, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false },
    { Layout: Redirect, from: "/:lng/products/:id/regular/order/choosing", to: "/:lng/product/:id/regular/order/choosing-master-file", breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: Redirect, from: "/:lng/products/:id/regular/order/extended", to: "/:lng/product/:id/regular/order/extended-service/fold-line", breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: Redirect, from: "/:lng/products/:id/regular/order/confirm", to: "/:lng/product/:id/regular/order/confirm-detail", breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: Redirect, from: "/:lng/products/:id/regular/detail/", to: "/:lng/products/:id/regular/detail/product-name", breadCrumb: [Enumerations.breadCrumbs.regularOrder], title: 'common.breadcrumbs.regularOrder', exact: false, showResetTour: true },
    { Layout: Redirect, from: "/:lng/product", to: "/:lng/products", breadCrumb: [], title: 'common.breadcrumbs.products', exact: false },

    { Layout: MainLayoutRoute, path: "/:lng/file-manager", Component: FileManager, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.fileManager], title: 'common.breadcrumbs.fileManager', exact: false, showResetTour: true },

    { Layout: MainLayoutRoute, path: "/:lng/products/:id/professional/detail", Component: PdpProfessionalDetail, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.poProductDetail], title: '', exact: true, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/professional", Component: PdpProfessionalDetail, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.poProductDetail], title: 'components.orderApplicationType.professional', exact: true, showResetTour: true, priority: 0.9 },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/professional/order", Component: ProfessionalOrder, needAuthentication: true, breadCrumb: [Enumerations.breadCrumbs.home], title: 'common.breadcrumbs.professionalOrder', exact: true, showResetTour: true },
    { Layout: Redirect, from: "/:lng/products/:id/professional/detail/:name", to: "/:lng/products/:id/professional/detail", breadCrumb: [], title: '', exact: true },

    { Layout: MainLayoutRoute, path: "/:lng/products/store/category/:categoryId/:categoryName", Component: StoreProducts, needAuthentication: false, breadCrumb: [Enumerations.breadCrumbs.storeProducts], title: 'common.breadcrumbs.storeProducts', exact: false, showResetTour: true },
    { Layout: MainLayoutRoute, path: "/:lng/products/store", Component: StoreProducts, needAuthentication: false, breadCrumb: [Enumerations.breadCrumbs.storeProducts], title: 'common.breadcrumbs.storeProducts', exact: true, showResetTour: true, priority: 1.0 },
    { Layout: MainLayoutRoute, path: "/:lng/products/:id/store/detail/:name", Component: PdsStoreDetail, needAuthentication: false, breadCrumb: [Enumerations.breadCrumbs.stProductDetails], title: 'common.breadcrumbs.productDetails', exact: true, showResetTour: true },
    { Layout: Redirect, from: "/:lng/products/:id/store", to: "/:lng/products/:id/store/detail:name", breadCrumb: [], title: '', exact: true },

    { Layout: MainLayoutRoute, path: "/:lng/test-apis", Component: TestApis, breadCrumb: [], title: '', exact: false },
    { Layout: MainLayoutRoute, path: "/:lng/exception", Component: Exception404, breadCrumb: [], title: 'common.breadcrumbs.exception', exact: false },

];

export default MainRoutes;