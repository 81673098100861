import React, { useState } from 'react';

import { GetFeedbackAnnouncementBasicInfoService, GetFeedbackAnnouncementsService, GetFeedbackEmployeeBasicInfoService, GetFeedbackEmployeesService, GetFeedbackShareSheetOrderBasicInfoService, GetFeedbackShareSheetOrdersService } from '../../../../../services/adminPanel/AdminFeedbackService';
import { createFeedbackAnnouncementsColumns, createFeedbackShareSheetOrdersColumns, createFeedbackUserColumns } from '../../../feedbacks/bases/ApBasesFeedbackHelper';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import ApBaseFeedbackDetailModal from '../../../feedbacks/bases/ApBaseFeedbackDetailModal';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApCustomerFeedbacksModalSearch from './ApCustomerFeedbacksModalSearch';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsModal from '../../../../../components/modals/NsModal';
import NsTab from '../../../../../components/tabs/NsTab';

const ApCustomerFeedbacksModal = ({ closeModal, userId }) => {

    const tabs = ConstantsWithTranslation.getFeedbackDashboardTabs().filter(item => item.id !== Enumerations.feedbackTypes.none);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [searchData, setSearchData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showFeedbackTypeDetailModal, setShowFeedbackTypeDetailModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState();

    const tabChanged = (newValue, selectedTab) => {
        if (newValue !== selectedTabIndex) {
            setSelectedTabIndex(newValue);
            setSelectedTab(selectedTab);
        }
    }

    const getParameters = () => (
        {
            "Filter.UserId": userId,
            "Filter.NasInvoiceIds": searchData?.invoiceNo || null,
            "Filter.SubmitTimeFrom": searchData?.submitTimeFrom || '',
            "Filter.SubmitTimeTo": searchData?.submitTimeTo || '',
            "Filter.FromAverageRateValue": searchData?.averageRateFrom?.value || null,
            "Filter.ToAverageRateValue": searchData?.averageRateTo?.value || null,
            "Filter.IsPublic": searchData?.displayToAll?.key === 2 || null,
            "Filter.DemandServiceTypeId": searchData?.demandService?.key || null,
            "Filter.UserFeedbackStateTypeId": searchData?.feedbackStatus?.id || null,
        }
    )

    const getSearchData = (data) => {
        setSearchData(data);
        setCallServiceDate(new Date());
    }

    const getData = (data, isLoading) => setIsLoading(isLoading);

    const showFeedbackTypeDetailModalClicked = (selectedFeedback) => {
        setSelectedFeedback(selectedFeedback);
        setShowFeedbackTypeDetailModal(true);
    }

    const closeFeedbackTypeModalClicked = (messageClassModal) => {
        setShowFeedbackTypeDetailModal(false);
        if (!isEmpty(messageClassModal)) {
            setMessageClassModal(messageClassModal);
            setCallServiceDate(new Date());
        }
    }

    const getGridProperties = () => {
        switch (selectedTab?.id) {
            case Enumerations.feedbackTypes.announcementContent:
                return { service: GetFeedbackAnnouncementsService, dtoService: GetFeedbackAnnouncementBasicInfoService, columns: createFeedbackAnnouncementsColumns(showFeedbackTypeDetailModalClicked), hasAccess: hasAccess([UserClaimEnumerations.getApFeedbackAnnouncementBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]) };
            case Enumerations.feedbackTypes.sharedSheetOrder:
                return { service: GetFeedbackShareSheetOrdersService, dtoService: GetFeedbackShareSheetOrderBasicInfoService, columns: createFeedbackShareSheetOrdersColumns(showFeedbackTypeDetailModalClicked), hasAccess: hasAccess([UserClaimEnumerations.getApFeedbackShareSheetOrderBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]) };
            case Enumerations.feedbackTypes.user:
                return { service: GetFeedbackEmployeesService, dtoService: GetFeedbackEmployeeBasicInfoService, columns: createFeedbackUserColumns(showFeedbackTypeDetailModalClicked), hasAccess: hasAccess([UserClaimEnumerations.getApFeedbackToEmployeeBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]) };
            default: false
        }
    }

    const gridProperties = getGridProperties();

    return (
        <NsModal maxWidth='lg'
            showModal={true}
            closeModal={closeModal}
            dialogTitle={getTranslatedRawText("adminPanel.feedback.customerFeedbackTo")}>

            <ApCustomerFeedbacksModalSearch setSearchData={getSearchData}
                isLoading={isLoading} />

            <NsTab tabs={tabs}
                value={selectedTabIndex}
                onChange={tabChanged} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={gridProperties.service}
                parameters={getParameters()}
                columnDefs={gridProperties.columns}
                hasPagination={true}
                getData={getData} />

            {showFeedbackTypeDetailModal &&
                <ApBaseFeedbackDetailModal selectedFeedback={{ ...selectedFeedback, feedbackType: selectedTab.id }}
                    getFeedbackDtoService={gridProperties.dtoService}
                    hasAccessInfo={gridProperties.hasAccess}
                    closeModal={closeFeedbackTypeModalClicked} />}

            {messageClassModal}
        </NsModal>
    )
};

export default React.memo(ApCustomerFeedbacksModal);