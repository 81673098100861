import CommonHelper from "../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";

class FileManagerTour {

    static getFileManagerTour() {
        return ([
            {
                selector: '.file-manager__header__usage-space',
                content: getTranslatedRawText('fileManager.tour.usageSpace'),
            },
            {
                selector: '.bread-crumb__upload',
                content: getTranslatedRawText('fileManager.tour.breadcrumbButtons'),
            },
        ]);
    }

    static getActionButtonTour() {
        return ([
            {
                selector: '.fm-detail__selected-item-preview',
                content: getTranslatedRawText('fileManager.tour.selectedItemPreview'),
            },
            {
                selector: '.detail__action-button',
                content: getTranslatedRawText('fileManager.tour.actionButton'),
            },
        ]);
    }

}

export default FileManagerTour;