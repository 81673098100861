import React from 'react';

import { GetPaymentDetailsService, GetPaymentExcelReportService, GetPaymentReportService } from '../../../../services/payments/PaymentService';
import { GetUserAccountingPaymentReportService } from '../../../../services/ProfileSetting/UserOrderService';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import EnumerationTranslationHelper from '../../../../utilities/EnumerationTranslationHelper';
import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import NsDownloadFile from '../../../../components/downloadFiles/NsDownloadFile';
import NsDatePicker from '../../../../components/dateTimePickers/NsDatePicker';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { PaymentStateType } from '../../../../utilities/DataGridHelper';
import GridEnumerations from '../../../../configs/GridEnumerations';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsInfoBox from '../../../../components/infoBox/NsInfoBox';
import UserPanelWrapper from '../../UserPanelWrapper';
import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import DateHelper from '../../../../utilities/DateHelper';

class UpReportOrderPayment extends React.Component {

    constructor(props) {
        super(props);
        this.memoizeCreateStickyItem = CommonHelper.memoizeFunc(CommonHelper.createStickyButtonItem);
        this.columns = [
            {
                field: 'paymentDetail',
                headerName: getTranslatedRawText('profile.dataGridColumns.action'),
                width: 110,
                lockPosition: true,
                resizable: false,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (props) => {
                        return <NsButton className='secondary-text '
                            wrapperClassName={!!props.paymentStateType === Enumerations.paymentStateType.success ||
                                (Number(props.paymentGatewayTypeId !== Enumerations.paymentGateway.nGeniusPayment))
                                ? "invisible"
                                : ""}
                            startIcon='ns-icon-retry cursor-pointer'
                            onClick={() => this.refreshClicked(props)
                            } />
                    },
                    hasPrint: true,
                    printClicked: (id) => this.printReportClicked(id),
                }
            },
            {
                field: 'paymentType',
                headerName: getTranslatedRawText('profile.dataGridColumns.status'),
                width: 120,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (props) => <PaymentStateType status={props.paymentStateType} />
                }
            },
            {
                field: 'paymentDate',
                headerName: getTranslatedRawText('profile.orderReport.date'),
                width: 120,
                dataType: Enumerations.gridType.dateTime,
            },
            {
                field: 'paymentType',
                headerName: getTranslatedRawText('profile.orderReport.paymentType'),
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (props) => {
                        return <div>{EnumerationTranslationHelper.getPaymentTypeText(props.paymentType)}</div>
                    }
                }
            },
            {
                field: 'amount',
                headerName: getTranslatedRawText('profile.orderReport.amount'),
                dataType: Enumerations.gridType.price,
                width: 90
            },
            {
                field: 'paymentGatewayName',
                headerName: getTranslatedRawText('profile.orderReport.paymentGateway'),
                width: 150
            },
            {
                field: 'trackingNo',
                headerName: getTranslatedRawText('profile.orderReport.trackingNo'),
                dataType: Enumerations.gridType.number,
                width: 110
            },
            {
                field: 'referenceNo',
                headerName: getTranslatedRawText('profile.orderReport.referenceNo'),
                dataType: Enumerations.gridType.number,
                width: 170
            },
        ],
            this.state = {
                fromDate: DateHelper.getPreviousMonth(),
                isValidFromDate: true,
                toDate: new Date(),
                isValidToDate: true,
                showValidationDate: null,
                callServiceDate: null,
                file: null,
                isLoading: true,
                resultMessageClass: (<></>),
            };
    }

    refreshClicked = (payment) => !isEmpty(payment.paymentGatewayId) && GetPaymentDetailsService(payment, this.getPaymentDetailsCallback);

    getPaymentDetailsCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError && !!resultData)
            this.setState({ callServiceDate: new Date() });
        else
            this.setState({ resultMessageClass });
    }

    printReportClicked = (id) => {
        this.setState({ isLoading: true });
        GetPaymentReportService({ id }, this.reportCallback(false));
    }

    excelReportButtonClicked = () => {
        this.setState({ isLoading: true });
        let data = {
            SubmitTimeFrom: this.state.fromDate,
            SubmitTimeTo: this.state.toDate,
        }
        GetPaymentExcelReportService(data, this.reportCallback(true));
    }

    reportCallback = (hasFileDownloadName) => (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            const href = !isEmpty(resultData) && CommonHelper.createDownloadFileUrl(resultData?.fileDownloadName, resultData?.fileContents);
            this.setState({ file: { fileDownloadName: hasFileDownloadName ? resultData?.fileDownloadName : null, fileContents: href } });
        } else
            this.setState({ resultMessageClass });
    }

    valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        this.setState({
            [valueName]: value,
            [isValidName]: isValid,
        });
    }

    searchClicked = () => {
        const { isValidFromDate, isValidToDate } = this.state;
        if (!isValidFromDate || !isValidToDate) {
            this.setState({ showValidationDate: new Date(), });
            return;
        }
        this.setState({ callServiceDate: new Date(), isLoading: true });
    }

    keyPressed = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter) {
            event.preventDefault();
            this.searchClicked();
        }
    }

    getData = (rows, isLoading) => this.setState({ isLoading });

    createButtons = () => [
        this.memoizeCreateStickyItem("profile.orderReport.search", 'ns-icon-search', this.searchClicked, 'primary', (!this.state.isValidToDate && !this.state.isValidFromDate)),
        { label: "regularOrder.common.exportExcel", icon: 'ns-icon-excel', onClicked: this.excelReportButtonClicked, className: 'secondary-outline' },
    ];

    render() {
        const { fromDate, isValidFromDate, toDate, isValidToDate, showValidationDate, isLoading, callServiceDate, file, resultMessageClass } = this.state;

        return (
            <UserPanelWrapper titleName={getTranslatedRawText('profile.title.paymentReport')}
                titleIcon='ns-icon-payment'>

                <div className='d-flex w-100 mb-3' onKeyDown={this.keyPressed}>
                    <NsLoadingProgress isVisible={isLoading} />
                    <NsDatePicker label={getTranslatedRawText('profile.orderReport.fromDate')}
                        className='w-100 me-5'
                        showValidationDate={showValidationDate}
                        valueChanged={(value, isValidDate) => this.valueChanged(value, isValidDate, { fromDate }, { isValidFromDate })}
                        selectedDate={fromDate} />

                    <NsDatePicker label={getTranslatedRawText('profile.orderReport.toDate')}
                        className='w-100'
                        showValidationDate={showValidationDate}
                        disabled={!isValidFromDate}
                        valueChanged={(value, isValidDate) => this.valueChanged(value, isValidDate, { toDate }, { isValidToDate })}
                        selectedDate={toDate} />

                    <NsStickyButton stickyButtons={this.createButtons()} isLoading={isLoading} />
                </div>

                <NsDataGridWithServiceCall columnDefs={this.columns}
                    hasPagination={true}
                    callServiceDate={callServiceDate}
                    service={GetUserAccountingPaymentReportService}
                    parameters={{
                        FromDate: DateHelper.convertDateTimeToDate(fromDate),
                        ToDate: DateHelper.getDateWithEndOfDayTime(toDate),
                    }}
                    gridId={GridEnumerations.siteGrids.paymentsReport}
                    hasCustomColumnOrder={true}
                    getData={this.getData} />

                <NsInfoBox isShow={true}
                    title={getTranslatedRawText('profile.common.moreInformation')}
                    subject={getTranslatedRawText('adminPanel.payments.open')}
                    content={getTranslatedRawText('infos.reportsUnknownPayments')} />

                <NsDownloadFile file={file} />

                {resultMessageClass}
            </UserPanelWrapper >
        );
    }
}

export default UpReportOrderPayment;