import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import NsDateRangePicker, { converDates } from '../../../../components/dateTimePickers/NsDateRangePicker';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsSelect from '../../../../components/dropdowns/NsSelect';
import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import DateHelper from '../../../../utilities/DateHelper';
import NsTextField from '../../../../components/inputs/NsTextField';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import ApBaseUserSelectModal from '../../bases/ApBaseUserSelectModal';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBaseUserInfoModal from '../../managements/users/bases/ApBaseUserInfoModal';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import { GetCitiesService, GetStatesService } from '../../../../services/ProfileSetting/DeliveryAddressSettingService';
import NsAutocomplete from '../../../../components/dropdowns/NsAutoComplete';

const ApBasesDashboardHeader = (props) => {

    const { hasStateFilter } = props;
    const [dates, setDates] = useState([new Date(CommonHelper.isMobileDevice() ? DateHelper.getPreviousWeek() : DateHelper.getCustomPreviousMonth(2)), new Date()]);
    const [dateRangeType, setDateRangeType] = useState(ConstantsWithTranslation.getDateRanges()[0].key);
    const [showUserSelectModal, setShowUserSelectModal] = useState(false);
    const [user, setUser] = useState(null);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [showUserDetailModal, setShowUserDetailModal] = useState(false);

    useEffect(() => {
        hasStateFilter && GetStatesService({ id: CurrentUserHelper.getCountryId() }, getStatesCallback);
    }, [])

    useEffect(() => {
        setDates(converDates(dates, dateRangeType == Enumerations.dateRangeType.monthly, dateRangeType == Enumerations.dateRangeType.yearly));
    }, [dateRangeType])

    useEffect(() => {
        if (dates.length > 1) dates[1] = DateHelper.getDateWithEndOfDayTime(dates[1]);
        CommonHelper.safeFunctionCall(props.refreshClicked, dates, dates.length > 1, dateRangeType, user?.userId, selectedState?.id, selectedCity?.id);
    }, [dates, selectedState, selectedCity])

    const refreshClicked = () => CommonHelper.safeFunctionCall(props.refreshClicked, dates, dates.length > 1, dateRangeType, user?.userId, selectedState?.id, selectedCity?.id);

    const dateRangeChanged = (dates) => {
        setDates(converDates(dates, dateRangeType == Enumerations.dateRangeType.monthly, dateRangeType == Enumerations.dateRangeType.yearly));
    }

    const timeIntervalRangeChanged = (selectedItem) => {
        setDateRangeType(selectedItem);
        selectedItem === Enumerations.dateRangeType.daily && CommonHelper.safeFunctionCall(props.refreshClicked, dates, dates.length > 1, selectedItem, user?.userId, selectedState?.id, selectedCity?.id);
    }

    const usersModalButtonClicked = () => setShowUserSelectModal(true);

    const userSelected = (user) => {
        setUser(user);
        setShowUserSelectModal(false);
        CommonHelper.safeFunctionCall(props.refreshClicked, dates, dates.length > 1, dateRangeType, user?.userId, selectedState?.id, selectedCity?.id);
    }

    const selectedStateChanged = (value) => {
        setSelectedState(value);
        setSelectedCity(null);
        !!value?.id && getCities(value.id);
    }

    const selectedCityChanged = (value) => setSelectedCity(value);

    const getCities = (value) => {
        !isEmpty(cityList) && setSelectedCity(null);
        value && GetCitiesService({ stateId: value }, getCitiesCallback);
    }

    const getCitiesCallback = (resultData, result) => {
        if (!result.data.hasError)
            setCityList(resultData);
    }

    const getStatesCallback = (resultData, result) => {
        if (!result.data.hasError)
            setStateList(resultData);
    }

    const showUserInput = isEmpty(props.breadCrumb) ? false : props.breadCrumb.some(item => item == Enumerations.adminBreadCrumbs.businessDashboard || item == Enumerations.adminBreadCrumbs.financialDashboard);

    const closeApCustomerUserModal = () => setShowUserDetailModal(false)

    const handleShowUserDetailModal = () => setShowUserDetailModal(!showUserDetailModal);

    return (
        <div className={clsx('d-flex ', CommonHelper.isMobileDevice() ? 'flex-column' : 'justify-content-between')}>
            <div className='fw-bold'>{getTranslatedRawText(props?.title)}</div>
            <div>
                <div className={clsx('d-flex align-items-end gap-1', CommonHelper.isMobileDevice() ? 'flex-wrap' : '')} >
                    {hasStateFilter && <>
                        <NsAutocomplete label={getTranslatedRawText('account.signup.state')}
                            onChange={selectedStateChanged}
                            items={stateList}
                            textFieldClassName='m-0'
                            value={selectedState} />
                        <NsAutocomplete label={getTranslatedRawText('account.signup.city')}
                            onChange={selectedCityChanged}
                            items={cityList}
                            textFieldClassName='m-0'
                            disabled={!selectedState}
                            value={selectedCity} />
                    </>
                    }
                    <NsButton className='secondary-outline'
                        loading={props?.isLoading}
                        size='small'
                        onClick={refreshClicked}
                        startIcon='ns-icon-refresh' />
                    {user && <div>
                        <NsButton className='secondary-outline'
                            size='small'
                            onClick={handleShowUserDetailModal}
                            startIcon='ns-icon-coach-marks' />
                    </div>
                    }
                    {hasAccess([UserClaimEnumerations.getApCustomers]) && showUserInput &&
                        <NsTextField label={getTranslatedRawText('components.advanceSearch.user')}
                            value={user?.fullName}
                            disabled={true}
                            endAdornment={<>
                                <NsButton className='light-circle'
                                    onClick={usersModalButtonClicked}
                                    startIcon="ns-icon-more" />
                                <NsButton className='light-circle'
                                    onClick={() => userSelected(null)}
                                    startIcon="ns-icon-clear" />
                            </>
                            }
                        />
                    }
                    <NsDateRangePicker buttonColor='secondary-outline'
                        key={dateRangeType}
                        dates={dates}
                        onlyMonthPicker={dateRangeType == Enumerations.dateRangeType.monthly}
                        onlyYearPicker={dateRangeType == Enumerations.dateRangeType.yearly}
                        onChange={dateRangeChanged} />
                    <div style={{ width: '120px' }}>
                        <NsSelect options={ConstantsWithTranslation.getDateRanges()}
                            valueChanged={timeIntervalRangeChanged}
                            selectedItem={dateRangeType} />
                    </div>
                    {showUserSelectModal &&
                        <ApBaseUserSelectModal showModal={true}
                            userSelected={userSelected}
                            closeModal={() => setShowUserSelectModal(false)} />
                    }
                    {showUserDetailModal &&
                        <ApBaseUserInfoModal showModal={true}
                            selectedUserId={user?.userId}
                            closeModal={closeApCustomerUserModal} />
                    }
                </div>
            </div>
        </div >
    );
};

export default React.memo(ApBasesDashboardHeader);