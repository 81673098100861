import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import '../../../assets/sass/views/products/ProductDetail.scss';
import NsAvailableDimensionTable from '../../../components/availableDimensions/NsAvailableDimensionTable';
import NsButton from '../../../components/buttons/NsButton';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsLoadingProgress from '../../../components/circularProgress/NsLoadingProgress';
import NsProductGuidance from '../../../components/guidance/NsProductGuidance';
import NsHtmlToReact from '../../../components/htmlToReact/NsHtmlToReact';
import NsProductImagesGallery from '../../../components/images/NsProductImagesGallery';
import NsReachResult from '../../../components/metaTags/NsReachResult';
import NsSetPageTitleAndDescription from '../../../components/metaTags/NsSetPageTitleAndDescription';
import NsTour from '../../../components/tour/NsTour';
import Config from '../../../configs/Config';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';
import { metaTagActionCreators } from '../../../redux/reducers/MetaTagReducer';
import { GetProductInfoService, GetProductSpecialDetailService } from '../../../services/product/ProductService';
import { isBusinessPartnerRoute } from '../../../utilities/BusinessPartnerHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../../utilities/ConfigHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture, isEnCulture } from '../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import DelayedTaskHelper from '../../../utilities/DelayedTaskHelper';
import { viewItemEvent } from '../../../utilities/GoogleTagManagerHelper';
import OrderHelper from '../../../utilities/OrderHelper';
import UrlHelper from '../../../utilities/UrlHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import ProductDetailTours from '../../bases/tours/ProductDetailTours';
import PdBaseContents from '../bases/PdBaseContents';
import PdBaseFeature from '../bases/PdBaseFeature';
import PdBaseLoginMessage from '../bases/PdBaseLoginMessage';
import PdBaseQuantity from '../bases/PdBaseQuantity';
import PdCustomDimension from '../bases/PdCustomDimension';
import ProductLicenseModal from './ProductLicenseModal';

class PdrRegularDetail extends React.Component {

    constructor(props) {
        super(props);
        this.tourId = Enumerations.tourGuide.regularProductDetails;
        this.tourSteps = ProductDetailTours.getProductDetailTour(false);
        // this.memoizeGetPrice = CommonHelper.memoizeFunc(this.getPrice);
        this.memoizeSeriesChanged = CommonHelper.memoizeFunc(this.seriesChanged);
        this.state = {
            showSignInMessageModalDate: null,
            messageBeforeSignIn: null,
            product: {},
            sizeAndPrices: [],
            selectedTurnaround: {},
            selectedPrintedSide: {},
            oldCirculation: {},
            selectedCirculation: {},
            bestCirculation: true,
            series: 1,
            guidancesUrls: [],
            quantity: null,
            isCustomizableDimension: false,
            isTooltipOpen: false,
            selectedId: null,
            isLoading: true,
            isLoadingGrid: true,
            startOrderingLoading: false,
            refreshFeaturesDate: null,
            processTimeBasedSelectedOptions: null,
            progressTimes: [],
            resultMessageClass: (<React.Fragment></React.Fragment>)
        };
        this.delayInSeriesRequest = new DelayedTaskHelper(() => {
            this.seriesDelayCallback();
        }, 1000);
    }

    productIdChanged = () => !!this.props.isUsedInPo ? this.props.selectedId : UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);

    componentDidMount = () => {
        this.setState({ selectedId: this.productIdChanged() }, () => {
            !!this.state.selectedId && GetProductInfoService(this.state.selectedId, this.getProductInfoCallback);
        })
        // window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.selectedCirculation.key !== prevState.selectedCirculation.key) {
            if (!!this.props.match.params.name && !!this.state.product.name) {
                let productName = CommonHelper.makeCorrectLink(this.state.product.name);
                productName = productName.replace(/#/g, '--')
                let paramsName = this.props.match.params.name
                if (productName !== paramsName) {
                    this.props.history.replace(UrlHelper.createPdRegularDetailUrl(null, this.state.selectedCirculation.key, productName))
                }
            }
        }

        if (prevState.series !== this.state.series)
            this.setState({ isLoadingGrid: true });

        if (this.props.isUsedInPo) {
            if (this.state.selectedId !== this.props.selectedId) {
                this.setState({ isLoading: true, selectedId: this.productIdChanged() },
                    () => !!this.state.selectedId && GetProductInfoService(this.state.selectedId, this.getProductInfoCallback));
            }
        } else {
            window.onpopstate = (e) => {
                if (this.state.selectedId !== this.props.selectedId) {
                    this.setState({ isLoading: true, selectedId: this.productIdChanged() },
                        () => !!this.state.selectedId && GetProductInfoService(this.state.selectedId, this.getProductInfoCallback));
                }
            }
        }
    }

    getProductInfoCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            let selectedCirculation = resultData.printCirculations.filter(
                circulation => circulation.key === CommonHelper.toInt(this.state.selectedId))[0];
            CommonHelper.safeFunctionCall(this.props.printCirculations, resultData.printCirculations)
            this.setState({
                product: resultData,
                selectedCirculation: selectedCirculation,
                refreshFeaturesDate: new Date(),
            });
        }
        else
            this.setState({ resultMessageClass: messageClassModal },
                () => this.props.isUsedInPo
                    ? this.props.history.push(getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.productDetailsWithoutId))
                    : this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)));
    }

    seriesDelayCallback = () => this.setState({ sizeAndPrices: [], refreshFeaturesDate: !this.props.isUsedInPo ? new Date() : this.state.refreshFeaturesDate });

    bestCirculationChanged = (bestCirculation, quantity) => this.setState({ bestCirculation, quantity });

    circulationChanged = (selectedItem) => {
        this.setState({
            oldCirculation: this.state.selectedCirculation,
            selectedCirculation: selectedItem,
            sizeAndPrices: [],
            refreshFeaturesDate: new Date()
        });
    }

    seriesChanged = (series, reCallApi) => {
        this.setState({ series });
        reCallApi && this.delayInSeriesRequest.run();
    }

    guidancesUrlUpdated = (guidancesUrls) => this.setState({ guidancesUrls });

    enableLoadingClicked = () => this.setState({ isLoading: true });

    sizeAndPricesChanged = (isFeatureOptionsChanged, sizeAndPrices, selectedTurnaround, selectedPrintedSide) => {
        isFeatureOptionsChanged ? this.setState({ isLoadingGrid: true })
            : this.setState({
                sizeAndPrices, selectedTurnaround, selectedPrintedSide, isLoading: false, isLoadingGrid: false,
                isCustomizableDimension: isEmpty(sizeAndPrices) ? false : sizeAndPrices[0].isCustomizableDimension,
            }, () => {
                if (this.state.selectedCirculation?.key !== this.state.oldCirculation?.key && !isEmpty(sizeAndPrices))
                    viewItemEvent(this.state.product, this.props.isUsedInPo ? Enumerations.googleTagManagerListId.professionalOrderDetail : Enumerations.googleTagManagerListId.regularOrderDetail, this.state.sizeAndPrices);

                this.setState({ oldCirculation: this.state.selectedCirculation });
            });
    }

    startOrderingClicked = () => {
        if (!!this.props.userFullName) {
            let parameters = {
                id: this.state.selectedCirculation.key,
                title: this.state.product.name
            };
            this.setState({ startOrderingLoading: true }, () => GetProductSpecialDetailService(parameters, this.getProductSpecialDetailCallback))
        }
        else this.setState({ showSignInMessageModalDate: new Date(), messageBeforeSignIn: null });
        !this.props.isUsedInPo
            ? !CurrentUserHelper.isTourSeen(this.tourId) && CurrentUserHelper.addToSeenTours(this.tourId)
            : CurrentUserHelper.addToSeenTours(Enumerations.tourGuide.professionalProductDetail);
    }

    getProductSpecialDetailCallback = (resultData, result, messageClassModal) => {
        this.setState({ startOrderingLoading: false });
        if (!result?.data?.hasError) {
            let id = OrderHelper.createRouteOrderId(this.state.selectedCirculation?.key);
            OrderHelper.addOrderToStorage(
                id,
                this.state.product.name,
                this.state.selectedTurnaround,
                this.state.selectedPrintedSide,
                this.state.selectedCirculation,
                this.state.series,
                resultData.productFilms,
                resultData.extendedServices.filter(item => item.extendedServiceType !== Enumerations.extendedServices.cutLine),
                resultData.extendedServices.filter(item => item.extendedServiceType === Enumerations.extendedServices.cutLine),
                null,
                this.state.guidancesUrls,
                this.state.sizeAndPrices,
                this.state.product.categories
            );
            sessionStorage.setItem('orderRouteId', id);
            this.props.isUsedInPo
                ? this.props.history.push(CommonHelper.stringFormat(
                    getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.professionalOrder), id))
                : this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.regularOrder, Links.view.main.regularOrder.orderDetailChoosingMasterFile), id));
        }
        else {
            this.setState({ resultMessageClass: messageClassModal })
        }
    }

    keyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter && this.checkStartOrdering()) {
            event.preventDefault();
            this.startOrderingClicked();
        }
    }

    checkStartOrdering = () => {
        const { isLoadingGrid, sizeAndPrices } = this.state;
        const isAvailableProduct = sizeAndPrices.some(item => item.isAvailable);
        return !(this.state.series === 0) &&
            this.state.bestCirculation &&
            !(this.state.quantity === 0) &&
            !isLoadingGrid &&
            isAvailableProduct
    }

    linkKeyWordsInProductDescription = (text, keywords) => {
        if (isEmpty(text) || isEmpty(keywords)) return text;
        const splittedKeywords = keywords.split(',');
        let replacedText = text;
        for (const word of splittedKeywords) {
            const regex = new RegExp(word.trim(), 'gi');
            let searchLink = getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { "search-input": word.replace(/\s+/g, '+') });
            const replacement = `<a className='fw-normal text-primary' href=${searchLink.toLowerCase()}><u>${word.trim()}</u> </a>`;
            replacedText = replacedText.replace(regex, replacement);
        }
        return replacedText;
    };

    getBodyTagOfDescriptionAndLinkKeywordsOnDescriptionToShop = () => {
        const keywordSetText = this.linkKeyWordsInProductDescription(this.state.product?.description, getConfigObjectByCulture(Config.metaTags)?.keywords);
        const separateBodyText = CommonHelper.getContentFromBodyTag(keywordSetText);
        return separateBodyText
    }

    getProcessTimeBasedSelectedOptions = (processTimeBasedSelectedOptions, progressTimes) => this.setState({ processTimeBasedSelectedOptions, progressTimes });

    loginClickedByExplanation = (messageBeforeSignIn) => this.setState({ showSignInMessageModalDate: new Date(), messageBeforeSignIn });

    render() {
        const { product, guidancesUrls, startOrderingLoading, sizeAndPrices, isLoading, isLoadingGrid, selectedTurnaround, selectedPrintedSide, messageBeforeSignIn } = this.state;
        const productId = this.props.isUsedInPo ? this.props.selectedId : UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        const mainButton = [CommonHelper.createStickyButtonItem('product.detail.startOrdering', 'ns-icon-start-ordering', this.startOrderingClicked, 'primary text-nowrap product-tour-start', !this.checkStartOrdering())]
        const category = !isEmpty(product?.categories) ? product?.categories[0] : '';
        const showCustomDimensionPrice = !!this.props.userFullName && this.state.isCustomizableDimension;
        const showShoppingBagCustomDimensionPrice = Config.showShoppingBag && category?.id == Config.shoppingBags.shoppingBagCategoryId;
        if (!this.state.selectedId && !productId)
            return <Redirect to={this.props.isUsedInPo ? getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.productDetailsWithoutId)
                : getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)} />;
        return (<>
            <Row className={clsx('product-detail m-0 p-0 mb-5 mb-md-0', this.props.isUsedInPo ? 'justify-content-between' : " justify-content-center border border-light-text-color")} onKeyDown={this.keyDown}>
                <NsLoadingProgress visible={isLoading} />
                <Col xs={{ span: 8, order: this.props.isUsedInPo ? 'last' : 'first' }} lg={{ span: 4, order: this.props.isUsedInPo ? 'last' : 'first' }} className="p-3">
                    {this.props.isUsedInPo && !isBusinessPartnerRoute() &&
                        <div className={clsx('d-flex pb-2', CommonHelper.isMobileDevice() ? 'justify-content-start' : 'justify-content-end')} >
                            <NsProductGuidance guidancesUrls={guidancesUrls} />
                        </div>}
                    <NsProductImagesGallery isLoading={isLoading}
                        className={this.props.isUsedInPo ? '' : 'product-detail__product-image'}
                        product={product} />
                    <ProductLicenseModal />
                </Col>
                <Col lg={8} className="py-3">
                    <div className="d-md-flex justify-content-between pb-2">
                        <div className="d-flex align-items-center">
                            <h1 className="page-title me-2 mb-0">
                                {product.name}
                            </h1>
                        </div>
                        {!this.props.isUsedInPo && !isBusinessPartnerRoute() &&
                            <div className={clsx('d-flex pb-2', CommonHelper.isMobileDevice() ? 'justify-content-start' : 'justify-content-end')} >
                                <NsProductGuidance guidancesUrls={guidancesUrls} />
                            </div>}
                    </div>
                    <NsHtmlToReact>
                        {this.getBodyTagOfDescriptionAndLinkKeywordsOnDescriptionToShop()}
                    </NsHtmlToReact>
                    <div className='product-tour-quantity px-0 py-2'>
                        <PdBaseQuantity series={this.state.series}
                            circulations={product.printCirculations}
                            selectedItemId={this.state.selectedId}
                            selectedCirculation={this.state.selectedCirculation}
                            bestCirculationChanged={this.bestCirculationChanged}
                            circulationChanged={this.circulationChanged}
                            seriesChanged={(series, reCallApi) => this.memoizeSeriesChanged(series, reCallApi)}
                            isUsedInPo={this.props.isUsedInPo}
                            productName={product.name} />
                    </div>

                    <div className='product-tour-options'>
                        <PdBaseFeature refreshFeaturesDate={this.state.refreshFeaturesDate}
                            enableLoadingClicked={this.enableLoadingClicked}
                            userFullName={this.props.userFullName}
                            selectedCurrency={this.props.selectedCurrency}
                            sizeAndPricesChanged={this.sizeAndPricesChanged}
                            guidancesUrlUpdated={this.guidancesUrlUpdated}
                            series={this.state.series}
                            getProcessTimeBasedSelectedOptions={this.getProcessTimeBasedSelectedOptions}
                            productId={!!this.state.selectedCirculation ? this.state.selectedCirculation.key : null} />
                    </div>

                    <div className={clsx('mt-3 product-tour-available', !CommonHelper.isMobileDevice() && 'product-available')}>
                        <NsAvailableDimensionTable size='small'
                            isLoading={isLoadingGrid}
                            label={getTranslatedRawText('product.detail.availableDimension')}
                            series={this.state.series}
                            isAuthenticated={!!this.props.userFullName}
                            rows={sizeAndPrices}
                            showGuidance={false} />
                    </div>

                    {(showCustomDimensionPrice || showShoppingBagCustomDimensionPrice) && <div className='my-3 product-tour-available'>
                        <PdCustomDimension rows={sizeAndPrices}
                            productId={productId}
                            showShoppingBagCustomDimensionPrice={showShoppingBagCustomDimensionPrice}
                            turnaroundType={selectedTurnaround?.key}
                            twoSidePrintingType={selectedPrintedSide?.key}
                            label={getTranslatedRawText('product.detail.availableDimension')}
                            series={this.state.series}
                            isLoadingGrid={isLoadingGrid}
                            isAuthenticated={!!this.props.userFullName} />
                    </div>}

                    {!this.props.isUsedInPo && <NsStickyButton stickyButtons={mainButton} isLoading={startOrderingLoading || isLoadingGrid} />}
                </Col>

                <PdBaseLoginMessage showSignInMessageModalDate={this.state.showSignInMessageModalDate}
                    message={messageBeforeSignIn} />

                {this.state.resultMessageClass}

                {(!this.props.isUsedInPo && !CurrentUserHelper.isTourSeen(this.tourId) && !this.state.isLoading) && <NsTour steps={this.tourSteps}
                    id={this.tourId} />}
                <NsReachResult reachResultType={Enumerations.reachResultType.product}
                    name={product?.name}
                    category={!isEmpty(product?.categories) ? product?.categories[0]?.name : ''}
                    description={CommonHelper.getContentFromBodyTag(product?.description)}
                    productId={product?.selectedId}
                    images={product?.imageUrl}
                    productUrl={window.location.href}
                    price={!isEmpty(sizeAndPrices) ? sizeAndPrices[0]?.basePrice : 0} />
                <NsSetPageTitleAndDescription pageTitle={CommonHelper.replaceHyphenToSpace(this.state?.product?.name)}
                    noNeedToTranslate={true}
                    printedSide={selectedPrintedSide}
                    turnaround={selectedTurnaround}
                    circulation={this.state?.selectedCirculation?.value}
                    description={this.state.product?.pprContent?.metaDescription}
                    keywords={this.state.product?.pprContent?.metaKeywords} />
            </Row>
            {this.props.isUsedInPo && <NsStickyButton stickyButtons={mainButton} isLoading={startOrderingLoading || isLoadingGrid} />}

            {Config.showProductContent && !this.props.isUsedInPo && !isBusinessPartnerRoute() &&
                <PdBaseContents {...this.state} {...this.props}
                    userFullName={this.props.userFullName}
                    loginClickedByExplanation={this.loginClickedByExplanation}
                    selectedCurrencyId={this.props.selectedCurrency?.id}
                />
            }
        </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userFullName: state.currentUser.userFullName,
        selectedCurrency: state.currencies.selectedCurrency,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...metaTagActionCreators }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PdrRegularDetail));