import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsToggleGroupButton from '../../../../../components/buttons/NsToggleGroupButton';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';

class PoExtendedServiceGripperEdgeType extends React.Component {

    constructor(props) {
        super(props);
        this.gripperEdgeOptions = ConstantsWithTranslation.getGripperEdgeOptions(null);

        this.state = {
            hasService: false,
            selectedGripperEdgeType: {},
            refreshExtendedServicesDate: props.refreshExtendedServicesDate
        }
    }

    componentDidMount = () => this.prepareService();

    componentDidUpdate = (prevProps) => {
        if (prevProps.refreshExtendedServicesDate !== this.state.refreshExtendedServicesDate)
            this.prepareService();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshExtendedServicesDate !== prevState.refreshExtendedServicesDate)
            return ({ refreshExtendedServicesDate: nextProps.refreshExtendedServicesDate });
        return null;
    }

    prepareService = () => {
        let service = OrderHelper.getSpecificService(this.props.routeId, Enumerations.extendedServices.gripperEdge);
        this.setState({
            selectedGripperEdgeType: !isEmpty(service?.selectedItem) ? service.selectedItem : this.gripperEdgeOptions[0],
            hasService: !isEmpty(service),
            price: service?.price
        });
    }

    cornerOptionClicked = (selectedItem) => {
        this.setState({ selectedGripperEdgeType: selectedItem });
        OrderHelper.updateExtendedService(this.props.routeId, Enumerations.extendedServices.gripperEdge, selectedItem);
        CommonHelper.safeFunctionCall(this.props.refreshSummary);
    }

    render() {
        const { hasService, selectedGripperEdgeType, price } = this.state;

        return (hasService
            ? <Row className='my-1'>
                <Col xs={8}>
                    <NsToggleGroupButton isShowGroupLabel={true}
                        label={getTranslatedRawText('regularOrder.extendedService.gripperEdge')}
                        labelClassName='d-flex flex-nowrap'
                        items={this.gripperEdgeOptions}
                        onClick={this.cornerOptionClicked}
                        selectedItemId={selectedGripperEdgeType?.key}
                        tooltipTitle={getTranslatedRawText('regularOrder.extendedService.gripperEdgeDescription')} />
                </Col>
                <Col className='align-self-end'>
                    {selectedGripperEdgeType?.key === Enumerations.gripperEdge.withGripperEdge &&
                        <div className='font-size-12 mb-2 text-nowrap'>
                            <b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(price)}</b>
                        </div>
                    }
                </Col>
            </Row >

            : null
        );
    }
}

export default PoExtendedServiceGripperEdgeType;