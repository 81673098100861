import Links from "../../configs/links/AllLinks";
import { GetRequest } from '../bases/BaseService';
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";


const GetBpOrderStateService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getBpOrderState), id), {}, callback);
}
const GetBpOrderStateHistoriesService = (parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getBpOrderStateHistories),parameters?.invoiceNumber), parameters, callback);
}

const GetBpProductWithFirstDimensionService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.gridProduct, Links.api.gridProduct.getProductWithFirstDimension), parameters, callback);
}

const GetBpProductWithAllDimensionsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.gridProduct, Links.api.gridProduct.getProductWithAllDimension), parameters, callback);
}

export {
    GetBpOrderStateService,
    GetBpOrderStateHistoriesService,
    GetBpProductWithFirstDimensionService,
    GetBpProductWithAllDimensionsService
}