import React, { useEffect, useRef, useState } from 'react';

import { GetFeedbackAnnouncementBasicInfoService, GetFeedbackAnnouncementsService, GetFeedbackEmployeeBasicInfoService, GetFeedbackEmployeesService, GetFeedbackShareSheetOrderBasicInfoService, GetFeedbackShareSheetOrdersService } from '../../../../../services/adminPanel/AdminFeedbackService';
import { createFeedbackAnnouncementsColumns, createFeedbackShareSheetOrdersColumns, createFeedbackUserColumns } from '../../../feedbacks/bases/ApBasesFeedbackHelper';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import ApBaseFeedbackDetailModal from '../../../feedbacks/bases/ApBaseFeedbackDetailModal';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsCard from '../../../../../components/cards/NsCard';
import NsTab from '../../../../../components/tabs/NsTab';

const ApDfbLastFeedbacksGrid = (props) => {

    const { parameters, refreshDate } = props;
    const avoidingRunUseEffectForFirstTime = useRef(true);
    const tabs = ConstantsWithTranslation.getFeedbackstatus();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showFeedbackTypeDetailModal, setShowFeedbackTypeDetailModal] = useState(false);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [refreshDate, selectedTabIndex]);

    const showFeedbackTypeModalClicked = (selectedFeedback) => {
        setSelectedFeedback(selectedFeedback);
        setShowFeedbackTypeDetailModal(true);
    }

    const closeFeedbackTypeModalClicked = () => setShowFeedbackTypeDetailModal(false);

    const tabChanged = (newValue, selectedTab) => {
        setSelectedTabIndex(newValue);
        setSelectedTab(selectedTab);
    }

    const getGridProperties = (feedbackType) => {
        switch (feedbackType) {
            case Enumerations.feedbackTypes.announcementContent:
                return {
                    service: GetFeedbackAnnouncementsService, dtoService: GetFeedbackAnnouncementBasicInfoService, columns: createFeedbackAnnouncementsColumns(showFeedbackTypeModalClicked), hasAccess: hasAccess([UserClaimEnumerations.getApFeedbackAnnouncementBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory])
                };
            case Enumerations.feedbackTypes.sharedSheetOrder:
                return { service: GetFeedbackShareSheetOrdersService, dtoService: GetFeedbackShareSheetOrderBasicInfoService, columns: createFeedbackShareSheetOrdersColumns(showFeedbackTypeModalClicked), hasAccess: hasAccess([UserClaimEnumerations.getApFeedbackShareSheetOrderBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]) };
            case Enumerations.feedbackTypes.user:
                return {
                    service: GetFeedbackEmployeesService, dtoService: GetFeedbackEmployeeBasicInfoService, columns: createFeedbackUserColumns(showFeedbackTypeModalClicked), hasAccess: hasAccess([UserClaimEnumerations.getApFeedbackToEmployeeBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory])
                };
            default: false
        }
    }

    const gridProperties = getGridProperties(parameters.feedbackType);

    return (
        <NsCard className='p-3 h-100 align-content-between'>
            <div className='page-title'>
                {getTranslatedRawText('adminPanel.dashboard.lastFeedback')}
            </div>
            <NsTab tabs={tabs}
                value={selectedTabIndex}
                onChange={tabChanged} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate || refreshDate}
                service={gridProperties.service}
                parameters={{
                    ...parameters,
                    "Filter.UserFeedbackStateTypeId": selectedTab?.id,
                }}
                columnDefs={gridProperties.columns}
                hasPagination={true} />

            {showFeedbackTypeDetailModal &&
                < ApBaseFeedbackDetailModal selectedFeedback={{ ...selectedFeedback, feedbackType: parameters.feedbackType }}
                    getFeedbackDtoService={gridProperties.dtoService}
                    hasAccessInfo={gridProperties.hasAccess}
                    closeModal={closeFeedbackTypeModalClicked} />}

        </NsCard>
    );
};

export default React.memo(ApDfbLastFeedbacksGrid);