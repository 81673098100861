import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import ApLayoutContent from './ApLayoutContent';
// import ApLayoutFooter from './ApLayoutFooter';
import { Container } from 'react-bootstrap';
import '../../../assets/sass/views/adminPanel/AdminPanel.scss';
import NsMetaTag from '../../../components/metaTags/NsMetaTag';
import { getCurrentDirection } from '../../../utilities/CultureHelper';
import UrlHelper from '../../../utilities/UrlHelper';
import AuthenticationChecker from '../bases/AuthenticationChecker';
import MainBreadcrumbs from '../main/breadcrumbs/MainBreadcrumbs';
import ApHeader from './adminPanelHeaders/ApHeader';
import ApLayoutSidebar from './ApLayoutSidebar';
import { useSelector } from 'react-redux';
import DelayedTaskHelper from '../../../utilities/DelayedTaskHelper';

const ApLayout = (props) => {

    const [width, setWidth] = useState(0);

    UrlHelper.addWwwToRoute();

    useEffect(() => {
        delayGetSidebarWidth.run();
    }, [])

    const getSidebarWidth = () => {
        let box = document.querySelector('.MuiDrawer-paper');
        setWidth(box?.offsetWidth);

    }

    const delayGetSidebarWidth = new DelayedTaskHelper(getSidebarWidth, 10);

    useSelector(state => {
        return {
            currentUser: state.currentUser,
        }
    });

    return <AuthenticationChecker needAuthentication={true}>
        <div className={clsx("d-flex admin-panel", getCurrentDirection())}>
            <ApLayoutSidebar />
            <NsMetaTag />
            <div className="d-none">
                <MainBreadcrumbs
                    breadCrumb={props.breadCrumb}
                    title={props.title} />
            </div>
            <ApHeader width={width} />

            <Container fluid className='background-element-color min-vh-100' >
                <ApLayoutContent {...props} width={width} />
                {/* {false && <ApLayoutFooter />} */}
            </Container>
        </div>
    </AuthenticationChecker>
}

export default React.memo(ApLayout);