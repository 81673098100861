import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import '../../assets/sass/components/labels/NsLabelLine.scss';
import CommonHelper from '../../utilities/CommonHelper';
import NsButton from '../buttons/NsButton';

class NsLabelLine extends React.Component {

    onClick = () => CommonHelper.safeFunctionCall(this.props.onClick);

    render() {
        const { primaryTitle, secondaryTitle, linkTo, viewMoreLink } = this.props;
        return (
            <div className="d-flex align-items-center">
                <Link to={linkTo || '#'} className={clsx(CommonHelper.isMobileDevice() ? 'label-line-title-small' : 'label-line-title', 'text-decoration-none ms-2')}>
                    {primaryTitle} <span>{secondaryTitle}</span>
                </Link>
                <div className="label-line-line m-0 ms-3 mt-3 d-none d-md-flex"></div>
                {!!viewMoreLink &&
                    <div className={CommonHelper.isMobileDevice() ? 'ms-auto' : 'ms-2'}>
                        <NsButton className='light py-0' >
                            <Link className='black-color text-decoration-none'
                                to={viewMoreLink}>
                                {getTranslatedRawText('product.common.viewMore')}
                            </Link>
                        </NsButton>
                    </div>
                }
            </div>
        )
    }
}

export default NsLabelLine;