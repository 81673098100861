import React from 'react';
import clsx from 'clsx';
import { TreeView, TreeItem } from '@mui/lab';
import CommonHelper from '../../utilities/CommonHelper';

class NsTreeView extends React.Component {

    clickTreeItem = (event, nodeIds) => {
        CommonHelper.safeFunctionCall(this.props.clickTreeItem, nodeIds[0], false, nodeIds.length > 0);
    }
    selectedNode = (event, nodeId) => {
        CommonHelper.safeFunctionCall(this.props.clickTreeItem, nodeId, false);
    }

    renderTree = (nodes) => {
        return <TreeItem
            key={nodes.id}
            nodeId={nodes?.id}
            label={
                <div>
                    <small className="d-inline-block title-color mb-2">
                        {nodes.name}
                    </small>
                </div>
            }
            icon={nodes.children.length === 0 &&
                (<span>

                    <span className="invisible pointer-none default-collapse-expand-icon ns-icon-expand-down" />

                    {(this.props.expandedFolderIds.indexOf(nodes.id) >= 0)
                        ? <span className={this.props.openFolderIcon} />
                        : <span className={this.props.closeFolderIcon} />}

                </span>)
            }>

            {
                Array.isArray(nodes.children) && nodes.children.length > 0 ?
                    nodes.children
                        .map((node) => this.renderTree(node))
                    : null
            }
        </TreeItem>
    };

    render() {
        const { treeViewFolders, expandedFolderIds, currentFolderId, treeViewClassName } = this.props;
        const treeItems = treeViewFolders.map(folder => this.renderTree(folder));
        return (
            <TreeView className={clsx(treeViewClassName, "custom-scrollbar text-nowrap p-2")}
                defaultCollapseIcon={
                    <>
                        <i className="default-collapse-expand-icon ns-icon-expand-down pt-1" />
                        <i className={this.props.openFolderIcon} />
                    </>
                }
                defaultExpandIcon={
                    <>
                        <i className={`default-collapse-expand-icon ${CommonHelper.getBreadcrumbsSeparatorIcon()} pt-1`} />
                        <i className={this.props.closeFolderIcon} />
                    </>
                }
                // onNodeToggle={(event, nodeIds) => this.props.clickTreeItem(nodeIds, false, nodes.children.length > 0)}
                onNodeToggle={this.clickTreeItem}
                onNodeSelect={this.selectedNode}
                // onLabelClick={() => this.props.clickTreeItem(nodes.id, false)}
                expanded={expandedFolderIds}
                selected={currentFolderId}>
                {treeItems}
            </TreeView>
        );
    }
}

export default NsTreeView;