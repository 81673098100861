import { isBusinessPartnerRoute } from "../../../utilities/BusinessPartnerHelper";
import CommonHelper from "../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";

class ProductDetailTours {

    static getProductDetailTour(isProfessional) {
        let tour = [
            {
                selector: '.product-tour-quantity',
                content: getTranslatedRawText(isProfessional ? 'product.tour.poDetailQuantity' : 'product.tour.detailQuantity'),
            },
            {
                selector: '.product-tour-options',
                content: getTranslatedRawText('product.tour.detailOptions'),
            },
            {
                selector: '.product-tour-available',
                content: getTranslatedRawText('product.tour.detailAvailable'),
            },
            !isBusinessPartnerRoute() && {
                selector: '.product-tour-start',
                content: getTranslatedRawText('product.tour.detailStartOrdering'),
            },
        ];
        isProfessional && tour.unshift({
            selector: '.product-tour-select',
            content: getTranslatedRawText('product.tour.selectProduct'),
        });
        return CommonHelper.clearArrayFromEmptyItem(tour);
    }
}

export default ProductDetailTours;