import clsx from 'clsx';
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'
import { NsTransition } from './NsTransition';
import '../../assets/sass/components/modals/NsModal.scss';

import CommonHelper from '../../utilities/CommonHelper';
import NsMessageClassModal from './NsMessageClassModal';
import { isEmpty } from '../../utilities/ValidationHelper';
import Enumerations from '../../configs/Enumerations';

class NsModal extends React.Component {

    constructor(props) {
        super(props);
        //موقت
        this.Transition = React.forwardRef(function Transition(props, ref) {
            return <Slide direction={CommonHelper.isMobileDevice() ? "up" : "down"} ref={ref} {...props} />;
        });
        this.state = {
            showModal: props.showModal,
            isShowNsMessageClassModal: !!props.isShowNsMessageClassModal,
            isCloseWithAlert: props.isCloseWithAlert || false,
        };
    }

    onKeyUp = (event) => {
        event.keyCode === Enumerations.keyboard.enter && CommonHelper.safeFunctionCall(this.props.primaryActionButton)
        CommonHelper.safeFunctionCall(this.props.onKeyUp, event);
    }

    closeModal = (event, reason) => {
        if (!!reason && reason == "backdropClick")
            return;
        CommonHelper.safeFunctionCall(this.props.closeModal);
    }

    handleClose = (confirm) => CommonHelper.safeFunctionCall(this.props.closeModal, confirm);

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            showModal: newProps.showModal,
            isCloseWithAlert: newProps.isCloseWithAlert,
            isShowNsMessageClassModal: newProps.isShowNsMessageClassModal
        });
    }

    render() {
        const { maxWidth, dialogClassName, dialogTitle, showCloseButtonWithoutTitle, dialogTitleClassName, dialogTooltip,
            disableEscapeKeyDown, noSpace, actions, messageClassModalItems, dialogContentClassName, titleCloseIconClassName } = this.props;

        const isMobile = CommonHelper.isMobileDevice();
        const mobileStyle = isMobile ? "mobile-style" : "desktop-style";
        const titleBackground = !isMobile ? "bg-dialog-title py-1" : "pb-0";
        const titleCloseIcon = isMobile ? "ns-icon-expand-down  h4 text-black ms-auto" : "ns-icon-close btn text-white ms-auto"
        const tooltip = isMobile ? dialogTooltip : '';
        return (
            <Dialog open={this.state.showModal}
                onKeyUp={this.onKeyUp}
                fullWidth={true}
                maxWidth={maxWidth}
                TransitionComponent={this.Transition}
                keepMounted
                disableEnforceFocus
                disableEscapeKeyDown={disableEscapeKeyDown}
                className={clsx(mobileStyle, dialogClassName)}
                onClose={this.closeModal}>
                {dialogTitle &&
                    <DialogTitle className={clsx(titleBackground, dialogTitleClassName)}>
                        <div className='d-flex align-items-baseline font-size-16'>
                            {dialogTitle}
                            {tooltip}
                            <span className={clsx(titleCloseIcon, titleCloseIconClassName)} onClick={this.closeModal} />
                        </div>
                    </DialogTitle>
                }
                {showCloseButtonWithoutTitle && <DialogTitle className={clsx(titleBackground, dialogTitleClassName)}>
                    <div className='d-flex align-items-baseline font-size-16'>
                        <span className={clsx(titleCloseIcon, titleCloseIconClassName)} onClick={this.closeModal} />
                    </div>
                </DialogTitle>}
                <DialogContent className={clsx(!isEmpty(actions) && 'mb-5', noSpace ? 'm-0 p-0' : 'p-3 pb-5 p-md-3 mb-md-0', dialogContentClassName)}>
                    {this.props.children}
                    {!!this.state.isShowNsMessageClassModal
                        ? (<NsMessageClassModal show={this.state.isShowNsMessageClassModal}
                            messageItems={messageClassModalItems}
                            onClose={this.handleClose} />)
                        : null}
                </DialogContent>
                {
                    !isEmpty(actions) &&
                    <DialogActions className='p-0 mt-md-0 p-md-3'>{actions}</DialogActions>
                }
            </Dialog >
        );
    }
}

export default NsModal;