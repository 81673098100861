import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PostRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';
import { isEmpty } from '../../utilities/ValidationHelper';

const GetCartItemsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getSidebarCartItems), {}, callback);
}

const GetSidebarTotalPriceAndCountService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getTotalPriceAndCount), {}, callback);
}

const GetMyOrderCartItemsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getMyOrderCartItems), parameters, callback);
}

const GetSelectedOrdersForConfirmationService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getSelectedOrdersForConfirmation), data, callback);
}

const GetOrderBasicInfoService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getOrderBasicInfo), parameters, callback);
}

const GetOrderDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getOrderDetail), parameters, callback);
}

const GetAdditionalOrderInformationService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getAdditionalOrderInformation), parameters, callback);
}

const GetFilterPointsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getFilterPoints), parameters, callback);
}

const GetFailedExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getFailedExcelReport), data, callback);
}

const GetArchiveExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getArchiveExcelReport), data, callback);
}

const GetAllOrderExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getAllOrderExcelReport), data, callback);
}

const GetCartItemExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getCartItemExcelReport), data, callback);
}

const GetInProcessExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getInProcessExcelReport), data, callback);
}

const GetInTransitExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getInTransitExcelReport), data, callback);
}

const GetPreparingExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getPreparingExcelReport), data, callback);
}

const GetInWarehouseExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getInWarehouseExcelReport), data, callback);
}

const GetTransferToWarehouseExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getTransferToWarehouseExcelReport), data, callback);
}

const GetAllOrderFilterExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getAllOrderFilterExcelReport), data, callback);
}

const GetBillBookOptionsService = (id, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getBillBookOptions), { id }, callback);
}

const GetPunchPointsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getPunchPoints), parameters, callback);
}

const GetFoldLinesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getFoldLines), parameters, callback);
}

const GetFailedOrderCountService = (callback) => GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.GetFailedOrderCount), {}, callback);

const DeleteOrderService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.userOrderDelete), data, callback, showSuccessResponseMessage);
}

const GetPreParingOrdersService = (data, callback) => {
    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getPreparingOrders), data, callback);
}

const GetFailedOrdersService = (data, callback) => {
    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getFailedOrders), data, callback);
}

const ChangeStateFromFailToPreparingService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.changeStateFromFailToPreparing), data, callback);
}

const GetInProcessingOrdersService = (data, callback) => {
    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getInProcessingOrders), data, callback);
}

const GetInWarehouseOrdersService = (data, callback) => {
    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getInWarehouseOrders), data, callback);
}

const GetInTransitOrdersService = (data, callback) => {
    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getInTransitOrders), data, callback);
}

const GetTransferToWarehouseOrdersService = (data, callback) => {
    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getTransferToWarehouseOrders), data, callback);
}

const GetQueueOfOrdersService = (parameters, callback) => {
    return GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getUserQueueStatus), parameters, callback);
}

const GetArchiveOrdersService = (data, callback) => {
    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getArchiveOrders), data, callback);
}

const GetAllOrdersService = (data, callback) => {
    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getAllOrders), data, callback);
}

const GetAllOrdersFilterService = (data, callback) => {
    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getAllOrdersFilter), data, callback)
}

const GetShippingRequestsByStatusService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getShippingRequestsByStatus), data, callback);
}
const GetShippingBasicInfoService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getShippingBasicInfo), parameters, callback);
}

const GetShippingRequestOrdersService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getShippingRequestOrders), data, callback);
}

const GetUnOfficialPrintInvoiceService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.userOrderUnOfficialPrintInvoice), parameters, callback);
}

const GetOfficialPrintInvoiceService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.userOrderOfficialPrintInvoice), parameters, callback);
}

const GetOfficialPrintInvoiceStatusService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.userOrderOfficialPrintInvoiceStatus), parameters, callback);
}

const GetUserOrderHistoriesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getUserOrderHistories), parameters, callback);
}

const GetUserAccountingReportService = (data, callback) => {
    return GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.userAccountingReport), data, callback);
}

const GetUserAccountingPaymentReportService = (data, callback) => {
    return GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.userAccountingPaymentReport), data, callback);
}

const GetUserItemsService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getUserItems), data, callback, null);
}

const UploadDraftOrderFileService = (file, callback, onUploadProgressCallback) => {
    let data = new FormData();
    data.append('file', file, file.name);

    return PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.uploadDraftOrderFile), data, callback, null, onUploadProgressCallback);
}

const DeleteDraftOrderFileService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.deleteDraftOrderFile), data, callback, null);
}

const SaveDraftOrderFileService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.saveDraftOrderFile), data, callback, showSuccessResponseMessage);
}

const DownloadEditedOrderFileService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.downloadEditedOrderFile), data, callback);
}

const DeleteByCancellationCodeService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.deleteByCancellationCode), data, callback, showSuccessResponseMessage);
}
const GetProfileOrderEstimatedDeliveryTimeService = (id, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getOrderEstimatedDeliveryTime), { id }, callback);
}
const GetOrderSeasonalPurchaseLimitStatusService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.userOrder, Links.api.userOrder.getOrderSeasonalPurchaseLimitStatus), data, callback);
}


export function createDataForApi(state) {
    const { sharedSheetOrderStateType, sortData, pageLength, orderProductType, hasTransferRequest, searchTerm, sharedSheetOrderIds, invoiceNumber, submitTimeFrom, submitTimeTo } = state;
    //New Standard for naming
    const { toSubmitTime, sharedSheetOrderStateTypes, fromSubmitTime, selectedProducts, circulations, productCategoryIds, turnaroundTypes, deliveredTimeFrom, deliveredTimeTo, twoSidePrintingTypes, confirmTimeTo, confirmTimeFrom, maxPrice, minPrice, productId, mobile, businessPartnerCustomerIdentifier } = state;
    ///
    let filter = {
        sharedSheetOrderIds: sharedSheetOrderIds || [],
        hasTransferRequest: !!hasTransferRequest,
        searchTerm: searchTerm?.toString().trim(),
        submitTimeFrom: submitTimeFrom,
        submitTimeTo: submitTimeTo,
        ///New standard for naming
        fromConfirmTime: confirmTimeFrom,
        toConfirmTime: confirmTimeTo,
        fromDeliverTime: deliveredTimeFrom,
        toDeliverTime: deliveredTimeTo,
        toSubmitTime,
        fromSubmitTime,
        orderProductType,
        nasInvoiceIds: !isEmpty(invoiceNumber) && invoiceNumber?.length > 0 ? (typeof invoiceNumber === 'string' || invoiceNumber instanceof String ? [invoiceNumber] : [...invoiceNumber]) : null,
        productNames: !!selectedProducts ? selectedProducts : [],
        turnaroundTypes: !!turnaroundTypes ? turnaroundTypes : [],
        twoSidePrintingTypes: !!twoSidePrintingTypes ? twoSidePrintingTypes : [],
        maxPrice: parseInt(maxPrice),
        minPrice: parseInt(minPrice),
        productCategoryIds,
        circulations,
        productId,
        businessPartnerCustomerIdentifier,
        mobile,
        sharedSheetOrderStateTypes: !isEmpty(sharedSheetOrderStateTypes) ? Array.isArray(sharedSheetOrderStateTypes) ? sharedSheetOrderStateTypes : [sharedSheetOrderStateTypes] : [],
    }
    let data = {};
    let currentPageIndex = state.currentPageIndex == 0 ? 1 : state.currentPageIndex;
    if (!!currentPageIndex) {
        data.filter = filter;
        data.currentPageIndex = currentPageIndex;
        data.pageLength = pageLength;
        data.selectedSortingPropertyName = sortData?.selectedSortingPropertyName;
        data.sortType = sortData?.sortType;
        return data;
    } else
        return {
            ...filter,
            selectedSortingPropertyName: sortData?.selectedSortingPropertyName,
            sortType: sortData?.sortType
        };
}

export {
    GetCartItemsService,
    GetOrderBasicInfoService,
    GetOrderDetailService,
    DeleteOrderService,
    GetUnOfficialPrintInvoiceService,
    GetOfficialPrintInvoiceService,
    GetOfficialPrintInvoiceStatusService,
    GetFailedExcelReportService,
    GetArchiveExcelReportService,
    GetAllOrderExcelReportService,
    GetCartItemExcelReportService,
    GetInProcessExcelReportService,
    GetInTransitExcelReportService,
    GetPreparingExcelReportService,
    GetInWarehouseExcelReportService,
    GetTransferToWarehouseExcelReportService,
    GetAllOrderFilterExcelReportService,
    GetMyOrderCartItemsService,
    GetPreParingOrdersService,
    GetFailedOrdersService,
    GetFailedOrderCountService,
    ChangeStateFromFailToPreparingService,
    GetInProcessingOrdersService,
    GetTransferToWarehouseOrdersService,
    GetInWarehouseOrdersService,
    GetInTransitOrdersService,
    GetArchiveOrdersService,
    GetAllOrdersService,
    GetAllOrdersFilterService,
    GetSelectedOrdersForConfirmationService,
    GetSidebarTotalPriceAndCountService,
    GetAdditionalOrderInformationService,
    GetFilterPointsService,
    GetBillBookOptionsService,
    GetPunchPointsService,
    GetFoldLinesService,
    GetShippingRequestsByStatusService,
    GetShippingBasicInfoService,
    GetShippingRequestOrdersService,
    GetUserAccountingReportService,
    GetUserAccountingPaymentReportService,
    GetUserItemsService,
    UploadDraftOrderFileService,
    DeleteDraftOrderFileService,
    SaveDraftOrderFileService,
    DownloadEditedOrderFileService,
    GetUserOrderHistoriesService,
    DeleteByCancellationCodeService,
    GetQueueOfOrdersService,
    GetProfileOrderEstimatedDeliveryTimeService,
    GetOrderSeasonalPurchaseLimitStatusService
}