import Enumerations from "../../../configs/Enumerations";
import CommonHelper from "../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import OrderHelper from "../../../utilities/OrderHelper";
import ProfessionalOrderHelper from "../../orders/professionalOrders/bases/ProfessionalOrderHelper";

class ProfessionalOrderTours {

    static getBeforeSelectingFileTours() {
        return [
            {
                selector: `${CommonHelper.isMobileDevice() ? '.header-tour' : '.header-tour-edit'}`,
                content: getTranslatedRawText('regularOrder.tour.headerEditOption'),
            },
            {
                selector: '.professionalOrder-tour-fileManager',
                content: getTranslatedRawText('fileManager.tour.PoFileManager'),
            },
        ];
    }

    static getSelectingFileTours() {
        return [
            {
                selector: '.professionalOrder-tour-choosingFileItem',
                content: getTranslatedRawText('regularOrder.tour.clickHere'),
            }
        ];
    }
    static getChoosingFileSummaryTours(routeId) {
        let tour = [
            {
                selector: '.order-tour-choosingFileSummary',
                content: getTranslatedRawText('regularOrder.tour.infoFile'),
            },
        ];
        ProfessionalOrderHelper.isAllowAddToListButton(routeId)?.length === 0 &&
            tour.push({
                selector: '.professionalOrder-tour-addToCurrentList',
                content: getTranslatedRawText('regularOrder.tour.addToCurrentList'),
            });
        return tour;
    }

    static getExtendedServiceSummaryTours(routeId) {
        let tour = [
            {
                selector: '.order-tour-extendedServiceSummary',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceInfo'),
            },
        ];
        ProfessionalOrderHelper.isAllowAddToListButton(routeId)?.length === 0 &&
            tour.push({
                selector: '.professionalOrder-tour-addToCurrentList',
                content: getTranslatedRawText('regularOrder.tour.addToCurrentList'),
            });
        return tour;
    }

    static getAddToCurrentListTours() {
        return [
            {
                selector: '.professionalOrder-tour-addToCurrentList',
                content: getTranslatedRawText('regularOrder.tour.addToCurrentList'),
            },
        ];
    }

    static getAfterClickingAddToCurrentListTours() {
        return [
            {
                selector: '.professionalOrder-tour-currentList',
                content: getTranslatedRawText('regularOrder.tour.currentList'),
            },
            {
                selector: '.professionalOrder-tour-choosingFile',
                content: getTranslatedRawText('regularOrder.tour.addOrder'),
            },
            {
                selector: '.professionalOrder-tour-viewList',
                content: getTranslatedRawText('regularOrder.tour.viewList'),
            },
        ];
    }

    static getSubmitTours() {
        return [
            {
                selector: '.professionalOrder-tour-stickySubmit',
                content: getTranslatedRawText('regularOrder.tour.submit'),
            },
        ];
    }
}

export default ProfessionalOrderTours;