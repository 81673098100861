import React, { Component } from 'react';

import NsCard from '../../components/cards/NsCard';
import NsImage from '../../components/images/NsImage';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import ImageHelper from '../../utilities/ImageHelper';

class HomeOrderingProcessSection extends Component {
    constructor(props) {
        super(props);
        this.createProcess = [
            { title: getTranslatedRawText('home.orderingProcessSection.ordering'), className: 'ordering', alt: 'ordering' },
            { title: getTranslatedRawText('home.orderingProcessSection.payment'), className: 'payment', alt: 'payment' },
            { title: getTranslatedRawText('home.orderingProcessSection.printingProcess'), className: 'printing', alt: 'printing Process' }, /*alt*/
            { title: getTranslatedRawText('home.orderingProcessSection.readyToDelivery'), className: 'ready', alt: 'ready To Delivery' },
            { title: getTranslatedRawText('home.orderingProcessSection.delivered'), className: 'delivered', alt: 'delivered' },
        ];
    }

    render() {
        const showProcess = this.createProcess.map((item, index) =>
            <div key={index} className="text-center my-4" aria-label={item.alt}>
                <figure className='m-0'>
                    <NsImage imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.orderProcessSection"), item.alt)}
                        src={ImageHelper.blankSrc()}
                        className={`${item.className} rounded-0`}
                        wrapperClassName='justify-content-center' />
                    <figcaption className='page-title mt-2'>
                        {item.title}
                    </figcaption>
                </figure>
            </div>
        );
        return (
            <NsCard>
                <div className="d-flex justify-content-around align-items-center flex-wrap">
                    {showProcess}
                </div>
            </NsCard>
        );
    }
}

export default HomeOrderingProcessSection;