import React, { Component } from 'react';

import NsHtmlToReact from '../../../../../components/htmlToReact/NsHtmlToReact';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../../components/text/NsTypography';
import Enumerations from '../../../../../configs/Enumerations';

class UpDashboardCashbackCalculationBasis extends Component {

    render() {
        const { isLoading } = this.props;

        return isLoading
            ? <div className='w-100 pt-3'>
                < NsSkeleton isVisible={true}
                    variant={
                        [
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.text,
                        ]} />
            </div >
            : <div className='py-3 px-1'>

                <NsTypography variant='inherit font-size-18 d-block fw-bold mb-3'>{getTranslatedRawText('profile.cashback.calculationBasis')}</NsTypography>
                <div>
                    <div className='d-flex align-items-center flex-nowrap mb-3'>
                        <div className='num-list-style font-size-12 fw-bold me-2'>1</div>
                        <div><NsTypography variant='inherit font-size-14 d-block'><NsHtmlToReact>{getTranslatedRawText('profile.cashback.groupSheetOrders')}</NsHtmlToReact></NsTypography></div>
                    </div>
                    <div className='d-flex flex-nowrap align-items-center mb-3'>
                        <div className='num-list-style font-size-12 fw-bold me-2'>2</div>
                        <div><NsTypography variant='inherit font-size-14 d-block'><NsHtmlToReact>{getTranslatedRawText('profile.cashback.returnedOrders')}</NsHtmlToReact></NsTypography></div>
                    </div>
                    <div className='d-flex flex-nowrap align-items-center mb-3'>
                        <div className='num-list-style font-size-12 fw-bold me-2'>3</div>
                        <div><NsTypography variant='inherit font-size-14 d-block'>{getTranslatedRawText('profile.cashback.orderSubmissionTime')}</NsTypography></div>
                    </div>
                </div>
            </div>
    }
}

export default UpDashboardCashbackCalculationBasis;

