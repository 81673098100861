class ConstHelper {

    static make = (object) => {

        const propNames = Object.getOwnPropertyNames(object);

        for (const name of propNames) {
            const value = object[name];

            if (value && typeof value === "object")
                this.make(value);
        }

        !Object.isFrozen(object) && Object.freeze(object);
    }
}

export default ConstHelper;