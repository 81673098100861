import React, { useState } from 'react';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsTextField from '../../../../components/inputs/NsTextField';
import NsTypography from '../../../../components/text/NsTypography';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

function NotificationConfig(props) {
    const [state, setState] = useState({
        loading: false,
        signInSmsIsActive: false,
        signInEmailIsActive: false,
        newOrderSmsIsActive: false,
        newOrderEmailIsActive: false
    })

    const saveClicked = () => {
        setState({
            ...state,
        });
    }

    const discardClicked = () => setState({ loading: true },
        /* () =>  api*/)

    const mainButtons = [
        { label: "common.save", onClicked: saveClicked, icon: 'ns-icon-save', className: 'primary' },
        { label: "common.discard", onClicked: discardClicked, icon: 'ns-icon-close', className: 'primary-outline' },
    ];

    return (
        <div>
            <NsPageMainContentWrapper header={getTranslatedRawText('adminPanel.sidebar.notificationConfig')}
                icon={'ns-icon-setting h1'}>
                <NsTypography variant='inherit' className='section-title d-block'>{getTranslatedRawText('adminPanel.notificationConfig.signInNotification')}</NsTypography>
                <div className='d-flex mt-3'>
                    <NsTypography variant='inherit' className='text-color font-size-16 me-2'>{getTranslatedRawText('adminPanel.notificationConfig.sms')}</NsTypography>
                    {/* <NsSwitchButton className=' primary'
                        labelOn='on'
                        checked={state.signInSmsIsActive}
                        labelOff='off'
                        switchChanged={() => setState({
                            ...state, signInSmsIsActive: !state.signInSmsIsActive
                        })} /> */}
                </div>
                <div className='d-flex mt-3'>
                    <NsTypography variant='inherit' className='text-color font-size-16 me-2'>{getTranslatedRawText('adminPanel.notificationConfig.email')}</NsTypography>
                    <NsSwitchButton className=' primary'
                        labelOn='on'
                        checked={state.signInEmailIsActive}
                        labelOff='off'
                        switchChanged={() => setState({
                            ...state, signInEmailIsActive: !state.signInEmailIsActive
                        })} />
                </div>
                <NsTypography variant='inherit' className='section-title mt-3 d-block'>{getTranslatedRawText('adminPanel.notificationConfig.newOrderNotification')}</NsTypography>
                <div className='d-flex mt-3'>
                    <NsTypography variant='inherit' className='text-color font-size-16 me-2'>{getTranslatedRawText('adminPanel.notificationConfig.sms')}</NsTypography>
                    <NsSwitchButton className=' primary'
                        labelOn='on'
                        checked={state.newOrderSmsIsActive}
                        labelOff='off'
                        switchChanged={() => setState({
                            ...state, newOrderSmsIsActive: !state.newOrderSmsIsActive
                        })} />
                </div>
                <div className='d-flex mt-3'>
                    <NsTypography variant='inherit' className='text-color font-size-16 me-2'>{getTranslatedRawText('adminPanel.notificationConfig.email')}</NsTypography>
                    <NsSwitchButton className=' primary'
                        labelOn='on'
                        checked={state.newOrderEmailIsActive}
                        labelOff='off'
                        switchChanged={() => setState({
                            ...state, newOrderEmailIsActive: !state.newOrderEmailIsActive
                        })} />
                </div>
                <NsStickyButton stickyButtons={mainButtons} />
            </NsPageMainContentWrapper>
        </div>
    );
}

export default React.memo(NotificationConfig);