const HomeTranslationEn = {
    home: {
        bannerSection: {
            instagramTitle: "Instagram Page",
            instagramDescription: "Join Us on instagram",
            NewReleaseTitle: "New Release",
            NewReleaseDescription: "New Release Description",
            homeTopPromotion1AriaLabel: 'Promotion Our Target Customers',
            homeTopPromotion2AriaLabel: 'Promotion Now We Are Online',
            announcement: 'Announcements',
            noAnyAnnouncement: 'No Any Announcement',
            noAnyNews: 'No Any News',
            noAnyDataToView: 'No Any Data To View.'
        },
        productSection: {
            bestSellers: "BEST SELLERS",
            newArrival: "NEW ARRIVAL",
            groupSheets: 'Group Sheets',
        },
        advertisementSection: {

        },
        aboutSection: {
            readMore: "READ MORE",
            aboutTitle: 'About {0} in brief',
            primaryAboutText: '{0} started its activity in the beginning of 2019 as one of the largest printing presses in Dubai and the UAE. Establishing a well-equipped and modern printing press. After comprehensive market research and review of the printing industry in the target countries, the United Arab Emirates and the city of Dubai were finally.',
            secondaryAboutText: '{0} after about 2 years of its activity has gained a good reputation and is expanding day by day by providing various printing services with reasonable prices in the competitive market of Dubai.'
        },
        orderingProcessSection: {
            ordering: 'Ordering',
            payment: 'Payment',
            printingProcess: 'Printing Process',
            readyToDelivery: 'Ready to Shipping',
            delivered: 'Delivered'
        },
        siteMap: {
            salesAndSupport: 'Sales & Support',
            ordersAndShipping: 'Orders & Shipping',
            inProcessOrders: 'In Process Orders',
            readyToDeliverOrders: 'Ready To Deliver Orders',
            archivedOrders: 'Archived Orders',
            regularOrdering: 'Regular Ordering',
            professionalOrdering: 'Professional Ordering',
            fileChecking: 'File Checking',
            support: 'Support',
            company: 'Company',
            newsAndBlog: 'Blog & News',
        },
        tour: {
            shop: 'To place a Regular order, order from the shop menu.',
            iOrderFrequently: 'To place a professional order, place an order from the professional order menu.'
        },
        instantDiscountSection : {
            cheaperThanBefore: 'Cheaper Than Before',
            pcs: 'Pcs',
        },
    }
};

export default HomeTranslationEn;