import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetApUserCreditService, GetBusinessPartnerDetailService, GetCustomerDetailService, GetSupporterDetailService } from '../../../../../services/adminPanel/AdminUserService';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../../components/text/NsTypography';
import NsLabelBox from '../../../../../components/labels/NsLabelBox';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import ApBaseUserInfoModal from './ApBaseUserInfoModal';
import ApBaseUserGrantedCreditModal from './ApBaseUserGrantedCreditModal';
import { ApUsersFinancialBalanceSheetModal } from '../../reports/ApUsersFinancialBalanceSheet';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';

export default function ApBaseUserRemainingLimitModal(props) {

    const { roleType } = props;
    const [showUserDetailModal, setShowUserDetailModal] = useState(false);
    const [showGrantedCreditModal, setShowGrantedCreditModal] = useState(false);
    const [showFinancialBalanceSheetModal, setShowFinancialBalanceSheetModal] = useState(false);
    const [userCredit, setUserCredit] = useState(null);
    const [userDetails, setUserDetails] = useState('');
    const [resultMessageClassModal, setResultMessageClass] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [creditIsChanged, setCreditIsChanged] = useState(false);

    const toggleUserDetailModal = () => setShowUserDetailModal(!showUserDetailModal);

    const toggleGrantedCreditModal = (confirm, resultMessageClassModal) => {
        setShowGrantedCreditModal(!showGrantedCreditModal);
        if (!!confirm) {
            setResultMessageClass(resultMessageClassModal);
            setCreditIsChanged(!!confirm);
            GetApUserCreditService(props.userId, getApUserCreditCallback);
        }
    }
    const toggleFinancialBalanceSheetModal = () => setShowFinancialBalanceSheetModal(!showFinancialBalanceSheetModal);
    const financialBalanceModalUserData = () => ({
        accountStatus: userDetails?.accountStatus ? getTranslatedRawText('adminPanel.usersInfo.active') : getTranslatedRawText('adminPanel.usersInfo.block'),
        fullName: `${userDetails?.firstName} ${userDetails?.lastName}`,
        userId: userDetails?.userId
    })

    useEffect(() => {
        setIsLoading(true);
        getUserDetails();
        GetApUserCreditService(props.userId, getApUserCreditCallback)
    }, [])

    const getApUserCreditCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError)
            setUserCredit(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const getUserDetailCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError)
            setUserDetails(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const getUserDetails = () => {
        setIsLoading(true);
        switch (roleType) {
            case Enumerations.role.businessPartner: GetBusinessPartnerDetailService(props?.userId, getUserDetailCallback);
                break;
            case Enumerations.role.supporter: GetSupporterDetailService(props?.userId, getUserDetailCallback);
                break;
            default: GetCustomerDetailService(props?.userId, getUserDetailCallback);
                break;
        }
    }

    const closeModal = () => CommonHelper.safeFunctionCall(props.closeModal, creditIsChanged);

    const headerButtons = [
        { startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserDetailModal },
        hasAccess([UserClaimEnumerations.editApUserCredit]) && { startIcon: 'ns-icon-granted-credit', label: 'adminPanel.usersInfo.grantedCredit', onClick: toggleGrantedCreditModal },
        hasAccess([UserClaimEnumerations.getApUsersDebitCredit]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-reports', label: 'adminPanel.title.debitCreditReport', onClick: toggleFinancialBalanceSheetModal },
    ];

    const userFullName = { fullName: `${userDetails?.firstName} ${userDetails?.lastName}` };

    return (
        <div>
            <NsModal maxWidth='sm'
                showModal={true}
                closeModal={closeModal}
                noSpace={true}
                dialogTitle={getTranslatedRawText('adminPanel.usersInfo.remainingLimit')}>

                <ApBaseMapActionButtons actionButtons={headerButtons}
                    isLoading={isLoading} />
                {isLoading ?
                    <div className='w-100'>
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                    </div>
                    : <>
                        <div className='background-color p-2'>
                            <ApBaseLabelInfo info={userFullName}
                                hasAccessInfo={hasAccess([UserClaimEnumerations.getApCustomerById])} />
                        </div>
                        <Row className='mx-2 my-2'>
                            <Row className=''>
                                <Col md={6} xs={6} className='p-1 m-0'>
                                    <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.remainingLimit')}
                                        value={CommonHelper.currencyFormatByDefaultConfig(userCredit?.remainingLimit)}
                                        hasDescription={true}
                                        description={getTranslatedRawText('adminPanel.usersInfo.remainingLimitDescription')} />
                                </Col>
                            </Row>
                            <NsTypography variant='inherit' className='fw-bold mt-3'>
                                {getTranslatedRawText('adminPanel.usersInfo.remainingLimitResultMessage')}
                            </NsTypography>
                            <Row className='mx-0 px-0 mt-1'>
                                <Col md={6} xs={6} className='p-1 m-0'>
                                    <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.debtor')}
                                        value={CommonHelper.rtlFriendlyDefaultCurrencyFormat(userCredit?.debtor)}
                                        hasDescription={true}
                                        description={getTranslatedRawText('adminPanel.usersInfo.debtorDescription')}/>
                                </Col>
                                <Col md={6} xs={6} className='p-1 m-0'>
                                    <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.creditor')}
                                        value={CommonHelper.currencyFormatByDefaultConfig(userCredit?.creditor)}
                                        hasDescription={true}
                                        description={getTranslatedRawText('adminPanel.usersInfo.creditorDescription')} />
                                </Col>
                                <Col md={6} xs={6} className='p-1 m-0'>
                                    <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.grantedCredit')}
                                        value={CommonHelper.currencyFormatByDefaultConfig(userCredit?.grantedCredit)}
                                        hasDescription={true}
                                        description={getTranslatedRawText('adminPanel.usersInfo.grantedCreditDescription')} />
                                </Col>
                                <Col md={6} xs={6} className='p-1 m-0'>
                                    <NsLabelBox label={getTranslatedRawText('adminPanel.usersInfo.queue')}
                                        value={CommonHelper.rtlFriendlyDefaultCurrencyFormat(userCredit?.queue)}
                                        hasDescription={true}
                                        description={getTranslatedRawText('adminPanel.usersInfo.queueDescription')} />
                                </Col>
                            </Row>
                        </Row>
                    </>
                }
            </NsModal>
            {showUserDetailModal &&
                <ApBaseUserInfoModal showModal={true}
                    roleType={roleType}
                    selectedUserId={userDetails?.userId}
                    closeModal={toggleUserDetailModal} />
            }
            {showGrantedCreditModal &&
                <ApBaseUserGrantedCreditModal userId={userDetails?.userId}
                    roleType={roleType}
                    closeModal={toggleGrantedCreditModal} />
            }
            {showFinancialBalanceSheetModal &&
                <ApUsersFinancialBalanceSheetModal
                    roleType={roleType}
                    userDetails={financialBalanceModalUserData()}
                    closeModal={() => setShowFinancialBalanceSheetModal(false)}
                />
            }

            {resultMessageClassModal}
        </div>
    );

}
