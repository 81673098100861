import Links from "../../configs/links/AllLinks"
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest, PatchRequest, DeleteRequest, PostRequest } from "../bases/BaseService"

const GetAdvertisementContentsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAdvertisements), parameter, callback);
};

const GetAdvertisementContentByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAdvertisementById), id), {}, callback);
};

const PostAdvertisementContentService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApAdvertisement), data, callback);
};

const EditAdvertisementContentService = (id, data, callback) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApAdvertisement), id), data, callback);
};

const DeleteAdvertisementContentService = (id, callback) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApAdvertisement), id), {}, callback);
};

export {
    GetAdvertisementContentsService,
    GetAdvertisementContentByIdService,
    PostAdvertisementContentService,
    EditAdvertisementContentService,
    DeleteAdvertisementContentService
}