import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import React from 'react';

import '../../assets/sass/components/inputs/TextField.scss';
import Config from '../../configs/Config';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../utilities/CultureHelper';

class NsDatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.getDefaultYear = Config.signup.minAcceptableDate.substr(0, 4);
        this.state = { helperText: '' }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.showValidationDate !== this.props.showValidationDate) {
            this.onError(!this.getIsValidDate(this.props.selectedDate), this.props.selectedDate)
        }
    }

    setSelectedDate = (value, er) => {
        let isValidDate = this.getIsValidDate(value);
        CommonHelper.safeFunctionCall(this.props.valueChanged, value, isValidDate);
    }

    onError = (reason, value) => {
        this.setState({ helperText: reason ? getTranslatedRawText('components.datePicker.invalidDateMessage') : '' })
    }

    getIsValidDate = (value) => {
        try {
            if (Number(new Date(value).toISOString()?.substr(0, 4)) >= this.getDefaultYear)
                return true;
            else
                return false;
        } catch {
            return false;
        }
    }

    render() {
        const { label, selectedDate, disabled, className, helperText, noValidationDate, disableFuture } = this.props;

        return <LocalizationProvider dateAdapter={isEnCulture() ? AdapterDateFns : AdapterDateFnsJalali}>
            <DatePicker
                label={getTranslatedRawText(label)}
                className={clsx('ns-date-picker', className)}
                disabled={disabled}
                value={selectedDate}
                onChange={this.setSelectedDate}
                onError={this.onError}
                minDate={new Date(Config.signup.minAcceptableDate)}
                disableFuture={disableFuture}
                format='yyyy/MM/dd'
                // views={['year', 'month', 'day']}
                localeText={{
                    cancelButtonLabel: getTranslatedRawText('common.cancel'),
                    clearButtonLabel: getTranslatedRawText('common.clear'),
                    okButtonLabel: getTranslatedRawText('common.save'),
                    todayButtonLabel: getTranslatedRawText('components.datePicker.today')
                }}
                slotProps={{
                    actionBar: {
                        actions: ["clear", "today"],

                    },
                    textField: {
                        variant: 'standard',
                        helperText: helperText,
                    },

                    toolbar: {
                        toolbarPlaceholder: '__',
                        toolbarFormat: isEnCulture() ? 'yyyy / MM / dd' : 'dd / MM / yyyy',
                        hidden: false,
                    }

                }}
            />

        </LocalizationProvider >
    }
}

export default NsDatePicker;