import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';

import { GetShippingQueueByMethodsService } from '../../../../../services/adminPanel/AdminShippingService';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import NsLabelNoData from '../../../../../components/labels/NsLabelNoData';
import ApBaseShippingQueueHeader from './bases/ApBaseShippingQueueHeader';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import Enumerations from '../../../../../configs/Enumerations';
import ApShippingQueueCard from './bases/ApShippingQueueCard';
import DateHelper from '../../../../../utilities/DateHelper';
import NsTab from '../../../../../components/tabs/NsTab';

function ApShippingQueueByShippingMethods() {

    const avoidingRunUseEffectForFirstTime = useRef(true);
    const tabs = ConstantsWithTranslation.getDeliveryRequestTabs();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [fromDate, setFromDate] = useState(new Date(DateHelper.getCustomPreviousMonth(2)));
    const [toDate, setToDate] = useState(null);
    const [refreshDate, setRefreshDate] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [isLoading, setIsLoading] = useState(true);
    const [shippingQueue, setShippingQueue] = useState([]);
    const [orderCalculationBasesType, setOrderCalculationBasesType] = useState();

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current)
            getShippingQueueByMethods();
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [selectedTabIndex, fromDate, toDate, orderCalculationBasesType, refreshDate])

    const getShippingQueueByMethods = () => {
        setIsLoading(true);
        let parameters = {
            orderCalculationBasesType,
            transferStateType: selectedTab.id,
            submitTimeFrom: fromDate,
            submitTimeTo: toDate
        }
        GetShippingQueueByMethodsService(parameters, getShippingQueueByMethodsCallback);
    }

    const getShippingQueueByMethodsCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (result.data.hasError) setResultMessageClass(resultMessageClass);
        else setShippingQueue(resultData);
    }

    const tabChanged = (newValue, selectedTab) => {
        if (newValue != selectedTabIndex) {
            setSelectedTab(selectedTab);
            setSelectedTabIndex(newValue);
        }
    }

    const updateDates = (fromDate, toDate, orderCalculationBasesType, refreshDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
        setRefreshDate(refreshDate);
        setOrderCalculationBasesType(orderCalculationBasesType);
    }

    const mapShippingQueueByMethods = () => {
        return !isEmpty(shippingQueue)
            ? shippingQueue.map((item, index) =>
                <Col md={3} key={index}>
                    <ApShippingQueueCard item={item}
                        orderCalculationBasesType={orderCalculationBasesType}
                        searchData={{
                            lastStatuses: selectedTab,
                            shippingMethod: { key: item.shippingMethodType, value: item.title },
                            estimatedDeliveryTimeFrom: fromDate,
                            estimatedDeliveryTimeTo: toDate
                        }}
                        shippingInfoDto={{
                            shippingMethodName: item.title,
                            transferStateType: selectedTab.id,
                            estimatedDeliveryTimeFrom: !!fromDate && DateHelper.getIsoDateTime(fromDate),
                            estimatedDeliveryTimeTo: !!toDate && DateHelper.getIsoDateTime(toDate),
                        }} />
                </Col>)
            : <Col md={8}>
                <NsLabelNoData label='adminPanel.common.noAnyDataToView' />
            </Col>
    }

    return (
        <div>
            <div className='d-flex justify-content-between gap-2 mb-3 px-3'>
                <ApBaseShippingQueueHeader updateDates={updateDates}
                    title='adminPanel.shippings.shippingRequestsQueueByShippingMethods' />
            </div>
            <NsTab tabs={tabs}
                value={selectedTabIndex}
                onChange={tabChanged} />
            <Row className='my-3'>
                {isLoading
                    ? <NsSkeleton isVisible={true}
                        variant={[Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect]} />
                    : mapShippingQueueByMethods()
                }
            </Row>
            {resultMessageClass}
        </div>
    )
}

export default React.memo(ApShippingQueueByShippingMethods);