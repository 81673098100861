import clsx from 'clsx';
import React from 'react';

import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { messageClass } from '../../../../../utilities/MessageModalHelper';
import NsDragFile from '../../../../../components/dragFiles/NsDragFile';
import CommonHelper from '../../../../../utilities/CommonHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import Enumerations from '../../../../../configs/Enumerations';
import UploadFileHelper from '../../bases/UploadFileHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import { GetProductBasicPriceService, GetSpecificDimensionInfoService } from '../../../../../services/order/OrderService';
import SingletonCallService from '../../../../../services/bases/SingletonCallService';
import NsCarouselSwiper from '../../../../../components/carousel/NsCarouselSwiper';
import NsUploadingFileProgress from '../../../../../components/progresses/NsUploadingFileProgress';
import NsAvailableDimensionTable from '../../../../../components/availableDimensions/NsAvailableDimensionTable';

class SelectAndUploadFileManagement extends React.Component {

    constructor(props) {
        super(props);
        this.uploadFileHelperInstance = new UploadFileHelper(CommonHelper.toInt(props.routeId), props.routeId, props.isFront, this.onUploadProgressCallback, this.uploadFileHelperCallback);
        this.seriesFromLocalStorage = OrderHelper.getSeries(this.props.routeId);
        this.turnaround = OrderHelper.getTurnaround(this.props.routeId);
        this.printedSide = OrderHelper.getPrintedSide(this.props.routeId);
        this.availableSizesFromLocalStorage = OrderHelper.getAvailableSizes(this.props.routeId);
        this.singletonAvailableSizeService = new SingletonCallService(GetProductBasicPriceService, this.getProductBasicPriceCallback);

        this.state = {
            thumbnails: [],
            selectingFileClassName: 'd-block',
            previewFileClassName: 'd-none',
            guideColumnClassName: 'd-none',
            isDisabledConfirmation: true,
            productId: CommonHelper.toInt(props.routeId),
            triggerFilePicker: '',
            imageSrc: '',
            selectedFileInfo: '',
            suggestedFilesInfo: [],
            suggestedFileIndex: -1,
            fileInfoFromFilePicker: '',
            progress: { loaded: 0, total: 0, percent: 0, status: Enumerations.uploadingStatus.finished, message: null },
            isChangeFileView: true,
            stepMessage: null,
            availableSizes: [],
            isAvailableSizeLoading: true,
            trimZone: null,
            productDesignTemplateUrl: null,
            billBookCopies: null,
            resultMessageClass: (<></>),
            isChangedFilmSize: false,
        };
    }

    componentDidMount = () => {
        const { routeId, isFront, currentFilmKey, presentableFileType } = this.props;
        let selectedFile = isFront
            ? !currentFilmKey ? OrderHelper.getFrontSideMasterFiles(routeId) : OrderHelper.getFrontSideFilmFile(routeId, currentFilmKey)
            : !currentFilmKey ? OrderHelper.getBackSideMasterFiles(routeId) : OrderHelper.getBackSideFilmFile(routeId, currentFilmKey);

        OrderHelper.getLocalSuggestedFilesInfo(routeId, presentableFileType, (suggestedFilesInfo, thumbnails) => {
            this.setState({ suggestedFilesInfo, thumbnails },
                () => {
                    const selectedFileInfo = { ...suggestedFilesInfo.find(suggestedFile => suggestedFile.id === selectedFile?.id) };
                    const suggestedFileIndex = OrderHelper.getSuggestedFileIndex(suggestedFilesInfo, selectedFileInfo?.id);
                    const fileSize = suggestedFilesInfo[suggestedFileIndex]?.fileLength;

                    if (!isEmpty(selectedFileInfo)) {
                        if (!currentFilmKey)
                            this.getAvailableSize(selectedFileInfo?.id);
                        else {
                            selectedFileInfo.top = selectedFile?.top;
                            selectedFileInfo.left = selectedFile?.left;
                        }
                    }

                    if (!!selectedFileInfo?.draft)
                        this.setState({
                            imageSrc: selectedFileInfo?.draft,
                            selectedFileInfo,
                            suggestedFileIndex,
                            isDisabledConfirmation: false,
                            selectingFileClassName: 'd-none',
                            previewFileClassName: 'd-block',
                            guideColumnClassName: 'd-block',
                            progress: { ...this.state.progress, total: fileSize },
                            isChangedFilmSize: false,
                        }, this.sendInfoToParent);
                    else if (suggestedFilesInfo.length > 0) {
                        this.setState({
                            selectedFileInfo,
                        }, this.sendInfoToParent);
                    }
                });
        });
    }

    componentWillUnmount = () => this.uploadFileHelperInstance.cancelUpload();

    sendInfoToParent = () => CommonHelper.safeFunctionCall(this.props.getInfoFromManagement, this.state);

    selectedFileFromBrowser = (file, checkFileExtention) => {
        if (checkFileExtention)
            this.setState({
                fileInfoFromFilePicker: file,
                isDisabledConfirmation: false,
                selectingFileClassName: !file ? 'd-block' : 'd-none',
                previewFileClassName: !!file ? 'd-block' : 'd-none',
                guideColumnClassName: 'd-none',
                progress: { loaded: 0, total: 0, percent: 0, status: Enumerations.uploadingStatus.prepareForUpload },
                isChangeFileView: false,
                stepMessage: getTranslatedRawText('components.nsLinearProgress.prepareForUpload'),
                imageSrc: '',
                suggestedFileIndex: -1,
                isChangedFilmSize: false
            }, () => {
                this.sendInfoToParent();
                !this.props.currentFilmKey
                    ? this.uploadFileHelperInstance.uploadMasterFile(this.state.fileInfoFromFilePicker)
                    : this.uploadFileHelperInstance.uploadFimFile(this.state.fileInfoFromFilePicker);
            });
        else
            this.setState({
                resultMessageClass: messageClass(CommonHelper.stringFormat('{0} {1}',
                    file.name, getTranslatedRawText('regularOrder.messages.formatIsWrong')))
            });
    }

    uploadFileHelperCallback = (messageClassModal, tempContentList) => {
        if (!!messageClassModal) {
            this.setState({
                isChangeFileView: true,
                progress: {
                    ...this.state.progress,
                    status: Enumerations.uploadingStatus.failed,
                    message: messageClassModal.props
                }
            });
            return;
        }

        let validTemp = tempContentList.filter(temp => temp.isValid === true);

        let index = !isEmpty(validTemp) && tempContentList.findIndex(temp => temp.id === validTemp[0]?.id);

        !this.props.currentFilmKey && this.getAvailableSize(validTemp[0]?.id);

        let thumbnails = tempContentList.map(temp => temp.thumbnail);
        this.setState({
            suggestedFilesInfo: [...this.state.suggestedFilesInfo, ...tempContentList],
            thumbnails: [...this.state.thumbnails, ...thumbnails],
            imageSrc: validTemp[0]?.draft,
            selectedFileInfo: validTemp[0],
            suggestedFileIndex: index + this.state.suggestedFilesInfo?.length,
            guideColumnClassName: 'd-block',
            isChangeFileView: true,
            stepMessage: <i className='ns-icon-circle-confirm align-middle h4' />,
            progress: {
                ...this.state.progress, status: Enumerations.uploadingStatus.finished
            },
            isChangedFilmSize: false,
        }, this.sendInfoToParent);
    }

    imageChanged = (index) => {
        this.state.suggestedFileIndex !== index &&
            this.setState({
                imageSrc: this.state.suggestedFilesInfo[index]?.draft,
                selectedFileInfo: this.state.suggestedFilesInfo[index],
                suggestedFileIndex: index,
                isDisabledConfirmation: false,
                selectingFileClassName: 'd-none',
                previewFileClassName: 'd-block',
                guideColumnClassName: 'd-block',
                isAvailableSizeLoading: true,
                progress: { ...this.state.progress, total: this.state.suggestedFilesInfo[index]?.fileLength },
                isChangedFilmSize: false,
            }, () => {
                this.sendInfoToParent();
                if (!this.props.currentFilmKey) {
                    const data = {
                        productId: this.state.productId,
                        series: this.seriesFromLocalStorage,
                        turnaroundType: this.turnaround.key,
                        twoSidePrintingType: this.printedSide.key,
                        currentFileId: this.state.selectedFileInfo?.id
                    }
                    this.singletonAvailableSizeService.run(data)
                }
            })
    }

    getAvailableSize = (masterFileId) => {
        const data = {
            productId: this.state.productId,
            series: this.seriesFromLocalStorage,
            turnaroundType: this.turnaround.key,
            twoSidePrintingType: this.printedSide.key,
            currentFileId: masterFileId
        }
        GetProductBasicPriceService(data, this.getProductBasicPriceCallback);
    }

    getProductBasicPriceCallback = (resultData, result, messageClassModal) => {
        this.setState({ availableSizes: resultData, isAvailableSizeLoading: false }, this.sendInfoToParent);
        let selectedAvailableSize = resultData?.find(item => item.isSelected === true);
        if (!isEmpty(selectedAvailableSize))
            GetSpecificDimensionInfoService(this.props.routeId, selectedAvailableSize?.width, selectedAvailableSize?.height,
                this.state.selectedFileInfo.width, this.state.selectedFileInfo.height, !this.props.isFront, this.getSpecificDimensionInfoCallback);
        else
            this.setState({ trimZone: null, }, this.sendInfoToParent);
    }

    getSpecificDimensionInfoCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({
                trimZone: OrderHelper.convertTrimZoneToPixel(resultData.productLatSizeTrimZone, this.state.selectedFileInfo?.resolution),
                productDesignTemplateUrl: resultData?.productDesignTemplateUrl,
                billBookCopies: resultData?.billBookCopies,
            }, this.sendInfoToParent);

        } else {
            this.setState({ resultMessageClass: messageClassModal });
        }
    }

    changeFileClicked = () => this.setState({ triggerFilePicker: new Date() });

    cancelUploadingClicked = () => {
        this.uploadFileHelperInstance.cancelUpload();
        let hasSelectedFile = !isEmpty(this.state.selectedFileInfo);

        this.setState({
            fileInfoFromFilePicker: '',
            imageSrc: this.state.selectedFileInfo?.draft,
            guideColumnClassName: hasSelectedFile ? 'd-block' : 'd-none',
            selectingFileClassName: hasSelectedFile ? 'd-none' : 'd-block',
            previewFileClassName: hasSelectedFile ? 'd-block' : 'd-none',
            progress: { loaded: 0, total: 0, percent: 0, status: Enumerations.uploadingStatus.prepareForUpload },
        }, this.sendInfoToParent);
    }

    onUploadProgressCallback = (id, loaded, total, percent, status, message) => {
        this.setState({ progress: { loaded, total, percent, status, message } },
            () => this.setState({ stepMessage: percent < 100 ? '' : getTranslatedRawText('components.nsLinearProgress.preparePage') }));
    }

    render() {
        const { suggestedFileIndex, selectedFileInfo, fileInfoFromFilePicker, suggestedFilesInfo } = this.state;
        const file = !!fileInfoFromFilePicker ? fileInfoFromFilePicker : selectedFileInfo;
        const isValidFile = !!fileInfoFromFilePicker ? !!fileInfoFromFilePicker : !isEmpty(selectedFileInfo);
        const showThumbnailCarousel = suggestedFilesInfo.length >= 1;

        return (
            <>
                {isValidFile &&
                    <div className='w-100 py-1 d-inline-flex border background-element-color m-0 p-0'>
                        <div className='w-100 px-1'>
                            <NsUploadingFileProgress key={selectedFileInfo?.id} fileName={file.name}
                                selectedFileInfo={selectedFileInfo}
                                progress={this.state.progress}
                                cancelUploadingClicked={this.cancelUploadingClicked}
                                deleteUploadedFileClicked={this.cancelUploadingClicked}
                                changeFileClicked={this.changeFileClicked}
                                isSingleFile={true}
                                stepMessage={this.state.stepMessage} />
                        </div>
                    </div>}

                {showThumbnailCarousel &&
                    <div className={clsx("w-100 align-self-center border")}>
                        <NsCarouselSwiper images={this.state.thumbnails}
                            activeStep={suggestedFileIndex}
                            imageChanged={this.imageChanged}
                            file={file} />
                    </div>
                }

                <div className={clsx("w-100 align-self-center mb-4", this.state.selectingFileClassName)}>
                    <NsDragFile getSelectedFile={this.selectedFileFromBrowser}
                        isShowSelectFileButton={true}
                        multiple={false}
                        triggerFilePicker={this.state.triggerFilePicker} />
                </div>
                {!this.props.currentFilmKey &&
                    <div className={this.state.selectingFileClassName}>
                        <NsAvailableDimensionTable routeId={this.props.routeId}
                            showGuidance={true}
                            usedInOrderProcess={true}
                            label={getTranslatedRawText('product.detail.availableDimension')}
                            series={this.seriesFromLocalStorage}
                            rows={this.availableSizesFromLocalStorage}
                            isAuthenticated={true} />
                    </div>}
                {this.state.resultMessageClass}
            </ >
        );
    }
}


export default SelectAndUploadFileManagement;