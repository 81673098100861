import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsButton from '../../../../components/buttons/NsButton';
import NsImage from '../../../../components/images/NsImage';
import NsLabelBox from '../../../../components/labels/NsLabelBox';
import NsModal from '../../../../components/modals/NsModal';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';
import { GetImageService } from '../../../../services/common/CommonService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import EnumerationTranslationHelper from '../../../../utilities/EnumerationTranslationHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import OBaseFilmImagePreview from '../../../orders/bases/OBaseFilmImagePreview';
import OrderThumbnailGroupPreview from '../../../orders/regularOrders/bases/OrderThumbnailGroupPreview';

class OrderDetailPreviewContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            basicInfo: null,
            files: [],
            hasImage: null,
            selectedImageIndex: null,
            isFront: true,
            isLoading: true,
        };
    }

    componentWillReceiveProps = (newProps) => {
        if (!isEmpty(newProps.resultData) && this.props.resultData !== newProps.resultData) {
            const { resultData } = newProps;
            !isEmpty(resultData) && this.getImagesContent(resultData?.sharedSheetOrderDetailFiles);
            let basicInfo = {
                productName: resultData?.productName,
                circulation: resultData?.circulation,
                twoSidePrintingType: resultData?.twoSidePrintingType,
                turnaround: resultData?.turnaroundType,
                series: resultData?.series,
                productDimension: resultData?.productDimension,
                fileDimension: resultData?.fileDimension,
                totalPrice: resultData?.totalProductAndExtendedServicePrice,
                isFileDeleted: resultData?.isFileDeleted,
                totalProductPrice: resultData?.totalProductPrice,
            };
            let extendedServiceFilter = resultData?.extendedServices?.find(service => service.extendedServiceType === Enumerations.extendedServices.filters);
            let extendedServiceFoldLine = resultData?.extendedServices?.find(service => service.extendedServiceType === Enumerations.extendedServices.foldLine);
            let extendedServiceRoundedCorner = resultData?.extendedServices?.find(service => service.extendedServiceType === Enumerations.extendedServices.roundedCorner);
            let extendedServiceGripperEdge = resultData?.extendedServices?.find(service => service.extendedServiceType === Enumerations.extendedServices.gripperEdge);
            let extendedServiceTopFolding = resultData?.extendedServices?.find(service => service.extendedServiceType === Enumerations.extendedServices.topFolding);
            let extendedServiceHandleType = resultData?.extendedServices?.find(service => service.extendedServiceType === Enumerations.extendedServices.handleType);
            let extendedServiceBillBook = resultData?.extendedServices?.find(service => service.extendedServiceType === Enumerations.extendedServices.billBook);
            let extendedServicePunch = resultData?.extendedServices?.find(service => service.extendedServiceType === Enumerations.extendedServices.punch);
            let totalExtendedServicesPrice = 0;
            !isEmpty(resultData) && resultData?.extendedServices.map(service => totalExtendedServicesPrice += service.price);
            this.setState({
                basicInfo, extendedServiceFilter, extendedServiceFoldLine, extendedServiceRoundedCorner, extendedServiceGripperEdge, extendedServiceTopFolding, extendedServiceHandleType,
                extendedServiceBillBook, extendedServicePunch, totalExtendedServicesPrice, hasExtendedService: resultData?.extendedServices?.length > 0,
            });
        }
    }

    imageClicked = (hasImage, selectedImageIndex, isFront) => this.setState({ hasImage, selectedImageIndex, isFront });

    closePreviewImageClicked = () => this.setState({ hasImage: null });

    getIsFront = (sharedSheetOrderDetailUserFileType) => {
        switch (sharedSheetOrderDetailUserFileType) {
            case Enumerations.sharedSheetOrderDetailUserFileType.FrontImage:
            case Enumerations.sharedSheetOrderDetailUserFileType.FirstFrontFilm:
            case Enumerations.sharedSheetOrderDetailUserFileType.SecondFrontFilm: return true;
            case Enumerations.sharedSheetOrderDetailUserFileType.BackImage:
            case Enumerations.sharedSheetOrderDetailUserFileType.FirstBackFilm:
            case Enumerations.sharedSheetOrderDetailUserFileType.SecondBackFilm: return false;
        }
    }

    getImagesContent = (resultDataFiles) => {
        let files = [];
        resultDataFiles.map((data, index) => {
            files.push({ title: data.fileTitle, frontSideFileInfo: null, backSideFileInfo: null });
            data.sharedSheetOrderDetailFiles.map(file => {
                GetImageService(file.thumbnail, (resultData) => {
                    GetImageService(file.draft, (draft) => {
                        if (this.getIsFront(file.sharedSheetOrderDetailUserFileType)) {
                            files[index].frontSideFileInfo = { thumbnail: resultData, draft: draft, src: draft, width: file.width, height: file.height, resolution: file.draftResolution, top: file.topPosition, left: file.leftPosition, userFileId: file?.userFileId };
                            this.setState({ files, isLoading: false });
                        } else {
                            files[index].backSideFileInfo = { thumbnail: resultData, draft: draft, src: draft, width: file.width, height: file.height, resolution: file.draftResolution, top: file.topPosition, left: file.leftPosition, userFileId: file?.userFileId };
                            this.setState({ files });
                        }
                    });
                });
            });
        });
    }

    showFiltersModal = (isFront) => {
        const file = !isEmpty(this.state.files) ? this.state.files[0] : {};
        CommonHelper.safeFunctionCall(this.props.showFiltersModalClicked,
            isFront ? file.frontSideFileInfo : file.backSideFileInfo, isFront);
    }

    showBillBookModal = () => CommonHelper.safeFunctionCall(this.props.showBillBookModalClicked,
        this.state.files[0].frontSideFileInfo.draft, this.state.files[0].frontSideFileInfo?.resolution, this.state.extendedServiceBillBook.id);

    showPunchModal = () => CommonHelper.safeFunctionCall(this.props.showPunchModalClicked,
        this.state.files[0].frontSideFileInfo.draft, this.state.files[0].frontSideFileInfo?.resolution);

    showFoldLinesModal = () => CommonHelper.safeFunctionCall(this.props.showFoldLineshModalClicked,
        this.state.files[0].frontSideFileInfo.draft, this.state.files[0].frontSideFileInfo?.resolution);

    render() {
        const { files, isFront, basicInfo, extendedServiceFilter, extendedServiceFoldLine, extendedServiceRoundedCorner, extendedServiceGripperEdge, extendedServicePunch, extendedServiceBillBook,
            extendedServiceTopFolding, extendedServiceHandleType, totalExtendedServicesPrice, hasImage, selectedImageIndex, hasExtendedService, isLoading } = this.state;

        return (
            <>
                <p className='section-title'>{basicInfo?.productName}</p>

                <BasicInfoLabelBox basicInfo={basicInfo} />

                <Row className='border-bottom '>
                    <Col md={isLoading ? 4 : 6} >
                        <div className='section-title my-2'>{getTranslatedRawText("profile.detail.filesPreview")}</div>
                        {isLoading
                            ? <NsSkeleton isVisible={true} className='my-2'
                                variant={[
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                ]} />
                            : <div className='d-flex gap-2 w-100 my-2'>
                                <OrderThumbnailGroupPreview files={files}
                                    showDefaultImage={true}
                                    showDownloadButton={this.props.showDownloadButton}
                                    isPointerActive={true}
                                    printedSide={basicInfo?.twoSidePrintingType}
                                    imageClicked={this.imageClicked} />
                            </div>
                        }
                        {!!basicInfo?.isFileDeleted && <p className='red-color font-size-12 fw-bold'>{getTranslatedRawText('profile.detail.fileDeleted')}</p>}

                    </Col>
                    {hasExtendedService && <Col>
                        <TotalProductPrice basicInfo={basicInfo} />
                        <div className='section-title my-2'>{getTranslatedRawText("profile.detail.extendedServices")}</div>
                        <Filter filter={extendedServiceFilter}
                            isLoading={isLoading}
                            series={basicInfo?.series}
                            showFiltersModal={this.showFiltersModal}
                            twoSidePrintingType={basicInfo?.twoSidePrintingType} />
                        <FoldLine foldLine={extendedServiceFoldLine}
                            series={basicInfo?.series}
                            isLoading={isLoading}
                            showFoldLinesModal={this.showFoldLinesModal} />
                        <Corners roundedCorner={extendedServiceRoundedCorner}
                            series={basicInfo?.series} />
                        <GripperEdge gripperEdge={extendedServiceGripperEdge}
                            series={basicInfo?.series} />
                        <TopFolding topFolding={extendedServiceTopFolding}
                            series={basicInfo?.series} />
                        <HandleType handleType={extendedServiceHandleType}
                            series={basicInfo?.series} />
                        <BillBook billBook={extendedServiceBillBook}
                            isLoading={isLoading}
                            series={basicInfo?.series}
                            showBillBookModal={this.showBillBookModal} />
                        <Punch punch={extendedServicePunch}
                            isLoading={isLoading}
                            series={basicInfo?.series}
                            showPunchModal={this.showPunchModal} />
                        <div className='w-100 border-top'>
                            <div className='my-2'>{getTranslatedRawText("profile.detail.totalServicePrice")}:<b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(totalExtendedServicesPrice * basicInfo?.series)}</b></div>
                        </div>
                    </Col>
                    }

                </Row>
                <div className='mt-3'>
                    <div>{getTranslatedRawText("profile.detail.totalAmount")}:
                        <b className='red-color'>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(basicInfo?.totalPrice)}</b><b>&nbsp;({getTranslatedRawText("profile.detail.exclusiveOfVAT")})</b>
                    </div>
                </div>

                {!!hasImage &&
                    <DraftImagePreview hasImage={hasImage}
                        files={files}
                        isFront={isFront}
                        selectedImageIndex={selectedImageIndex}
                        closePreviewImageClicked={this.closePreviewImageClicked} />
                }
            </>
        );
    }
}

function TotalProductPrice({ basicInfo }) {
    return (
        <>
            <div className='w-100 section-title py-1'>
                {getTranslatedRawText('regularOrder.confirmDetail.productDetails')}
            </div>
            <div className='w-100 py-1'>
                {getTranslatedRawText('regularOrder.confirmDetail.totalProductPrice')} :&nbsp;
                <b className='section-title'>
                    {CommonHelper.currencyFormatByDefaultConfig(basicInfo?.totalProductPrice)}
                </b>
            </div>
        </>
    )
}

function DraftImagePreview(props) {
    const { selectedImageIndex, files, isFront } = props;
    let file = isFront ? files[selectedImageIndex]?.frontSideFileInfo : files[selectedImageIndex]?.backSideFileInfo;
    let mainBox = React.createRef();
    return (
        <NsModal showModal={!!props.hasImage}
            maxWidth="sm"
            dialogTitle={getTranslatedRawText(isFront ? 'profile.detail.originalFrontFileDimension' : 'profile.detail.originalBackFileDimension')}
            closeModal={props.closePreviewImageClicked} >

            <span>{getTranslatedRawText('regularOrder.confirmDetail.fileDimension')}:&nbsp;</span>
            <span className='fw-bold'>{CommonHelper.stringFormat(getTranslatedRawText('fileManager.mainSectionDetail.fileDimension'), file?.width, file?.height)}</span>
            <div>
                {selectedImageIndex === 0
                    ? <NsImage src={isFront ? file?.draft : file?.draft}
                        className="w-100 border"
                        imgAlt="components.imagesAlt.imageDraft"
                        draggable={false} />

                    : <div ref={mainBox}
                        id='draggable-image position-absolute fixed-top fixed-bottom'
                        className="w-100 border not-rtl position-relative">
                        <OBaseFilmImagePreview masterImageInfo={isFront ? files[0]?.frontSideFileInfo : files[0]?.backSideFileInfo}
                            isReadOnly={true}
                            filmImageInfo={file}
                            mainBox={mainBox} />
                    </div>
                }
            </div>
        </NsModal >
    );
}

function pricesMultipleSeries(price, series) {
    if (series === 1)
        return CommonHelper.currencyFormatByDefaultConfig(price);
    else
        return CommonHelper.stringFormat('{0} * {1}', series, CommonHelper.currencyFormatByDefaultConfig(price));
}

function Filter(props) {
    const { filter, series, isLoading } = props;
    return (!!filter
        ? <React.Fragment>
            <span>{getTranslatedRawText('regularOrder.extendedService.filter') + showDescription(filter?.description)}: <b>{pricesMultipleSeries(filter?.price, series)}</b></span>
            <div className='d-flex justify-content-start my-2'>
                <NsButton className='secondary'
                    wrapperClassName='pe-1'
                    size='small'
                    loading={isLoading}
                    label='profile.detail.showFrontFilter'
                    onClick={() => props.showFiltersModal(true)} />

                {props.twoSidePrintingType === Enumerations.printedSide.twoSide &&
                    <NsButton className='secondary'
                        size='small'
                        loading={isLoading}
                        label='profile.detail.showBackFilter'
                        onClick={() => props.showFiltersModal(false)} />

                }
            </div>
        </React.Fragment>
        : ''
    );
}

function FoldLine(props) {
    const { foldLine, series, isLoading } = props;
    return (!!foldLine
        ? <div className='d-flex align-items-center my-2'>
            <span className='my-2'>{getTranslatedRawText('regularOrder.extendedService.foldLine') + showDescription(foldLine?.description)}:
                <b>&nbsp;{pricesMultipleSeries(foldLine?.price, series)}</b>
            </span>
            <NsButton className='secondary'
                wrapperClassName='ps-2'
                size='small'
                loading={isLoading}
                label='profile.detail.showFoldLines'
                onClick={props.showFoldLinesModal} />
        </div>
        : ''
    );
}

function Corners(props) {
    const { roundedCorner, series } = props;
    return (!!roundedCorner
        ? <div className='d-flex my-2'>
            <span>
                {CommonHelper.stringFormat('{0} ({1}):', getTranslatedRawText('regularOrder.extendedService.corners'),
                    getTranslatedRawText('regularOrder.extendedService.roundedCorners')) + showDescription(roundedCorner?.description)}<b>&nbsp; {pricesMultipleSeries(roundedCorner?.price, series)}</b></span>
        </div>
        : ''
    );
}

function GripperEdge(props) {
    const { gripperEdge, series } = props;
    return (!!gripperEdge
        ? <div className='d-flex my-2'>
            <span>
                {getTranslatedRawText('regularOrder.extendedService.gripperEdge') + showDescription(gripperEdge?.description)}:<b>&nbsp; {pricesMultipleSeries(gripperEdge?.price, series)}</b></span>
        </div>
        : ''
    );
}

function TopFolding(props) {
    const { topFolding, series } = props;
    return (!!topFolding
        ? <div className='d-flex my-2'>
            <span>
                {getTranslatedRawText('regularOrder.extendedService.topFolding') + showDescription(topFolding?.description)}:<b>&nbsp; {pricesMultipleSeries(topFolding?.price, series)}</b></span>
        </div>
        : ''
    );
}

function HandleType(props) {
    const { handleType, series } = props;
    return (!!handleType
        ? <div className='d-flex my-2'>
            <span>
                {getTranslatedRawText('regularOrder.extendedService.handleType') + showDescription(handleType?.description)}:<b>&nbsp; {pricesMultipleSeries(handleType?.price, series)}</b></span>
        </div>
        : ''
    );
}

function showDescription(des) {
    return isEmpty(des)
        ? ''
        : `(${des})`
}

function BillBook(props) {
    const { billBook, series, isLoading } = props;
    return (!!billBook
        ? <div className='d-flex align-items-center my-2'>
            <span>
                {CommonHelper.stringFormat('{0}:', getTranslatedRawText('regularOrder.extendedService.billBook')) + showDescription(billBook?.description)}
                <b>&nbsp; {pricesMultipleSeries(billBook?.price, series)}</b>
            </span>
            <NsButton className='secondary'
                wrapperClassName='ps-2'
                size='small'
                loading={isLoading}
                label='regularOrder.billBook.previewBillBook'
                onClick={props.showBillBookModal} />
        </div>
        : ''
    );
}

function Punch(props) {
    const { punch, series, isLoading } = props;
    return (!!punch
        ? <div className='d-flex align-items-center my-2'>
            <span>
                {CommonHelper.stringFormat('{0}:', getTranslatedRawText('regularOrder.extendedService.punch')) + showDescription(punch?.description)}
                <b>&nbsp; {pricesMultipleSeries(punch?.price, series)}</b>
            </span>
            <NsButton className='secondary'
                wrapperClassName='ps-2'
                size='small'
                loading={isLoading}
                label='profile.detail.showPunchPoint'
                onClick={props.showPunchModal} />
        </div>
        : ''
    );
}

function BasicInfoLabelBox(props) {
    const { basicInfo } = props;
    return (
        <Row className='mb-2'>
            {!basicInfo ?
                CommonHelper.createArray(4).map((item, index) =>
                    <div key={index} className='d-inline-block w-25 px-1'>
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                    </div>
                )
                :
                <div className='d-flex gap-2 flex-wrap'>
                    <NsLabelBox label={getTranslatedRawText('regularOrder.header.printedSide')}
                        value={EnumerationTranslationHelper.getPrintedSideText(basicInfo?.twoSidePrintingType)} />

                    <NsLabelBox label={getTranslatedRawText('regularOrder.header.turnaround')}
                        value={EnumerationTranslationHelper.getTurnaroundText(basicInfo?.turnaround)} />

                    <NsLabelBox label={getTranslatedRawText('regularOrder.header.circulation')}
                        value={basicInfo?.circulation} />

                    <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.totalQuantity')}
                        value={CommonHelper.stringFormat('{0}({1}) * {2}', basicInfo?.series, getTranslatedRawText('profile.dataGridColumns.series'), basicInfo?.circulation)} />

                    <NsLabelBox label={getTranslatedRawText('regularOrder.confirmDetail.productUpDimension')}
                        value={basicInfo?.productDimension} />

                    <NsLabelBox label={getTranslatedRawText('regularOrder.confirmDetail.fileDimension')}
                        value={basicInfo?.fileDimension} />
                </div>
            }
        </Row>
    );
}

export default OrderDetailPreviewContent;