const BusinessPartnerLinks = {
    exception: '{culture}/business-partner/exception',
    view: {
        main: {

            allProducts: '{culture}/business-partner/products',
            productsByCategory: '{culture}/business-partner/products/category/{0}/{1}',
            shoppingBag: '{culture}/business-partner/shopping-bag',
            shoppingBagPriceInquery: '{culture}/business-partner/shopping-bag/price-inquery',
            shoppingBagFaq: '{culture}/business-partner/shopping-bag/frequently-asked-questions',
            shoppingBagGallery: '{culture}/business-partner/shopping-bag/gallery',
            shoppingBagExploreProduct: '{culture}/business-partner/shopping-bag/explore-product',

            regularOrder: {
                productDetails: '{culture}/business-partner/products/{0}/regular/detail/{1}',
                orderDetailChoosingMasterFile: '{culture}/business-partner/products/{0}/regular/order/choosing-master-file',
                orderDetailChoosingFilmFile: '{culture}/business-partner/products/{0}/regular/order/choosing-film-file-{1}',
                orderDetailExtendedServiceFoldLine: '{culture}/business-partner/products/{0}/regular/order/extended-service/fold-line',
                orderDetailExtendedServiceFilter: '{culture}/business-partner/products/{0}/regular/order/extended-service/filters',
                orderDetailExtendedServiceCornerType: '{culture}/business-partner/products/{0}/regular/order/extended-service/rounded-corner',
                orderDetailExtendedServicePunch: '{culture}/business-partner/products/{0}/regular/order/extended-service/punch',
                orderDetailExtendedServiceBillBook: '{culture}/business-partner/products/{0}/regular/order/extended-service/bill-book',
                orderDetailExtendedServiceGripperEdge: '{culture}/business-partner/products/{0}/regular/order/extended-service/gripper-edge',
                orderDetailExtendedServiceTopFolding: '{culture}/business-partner/products/{0}/regular/order/extended-service/top-folding',
                orderDetailExtendedServiceHandleType: '{culture}/business-partner/products/{0}/regular/order/extended-service/handle-type',
                orderDetailConfirm: '{culture}/business-partner/products/{0}/regular/order/confirm-detail',
                orderSaveResult: '{culture}/business-partner/products/{0}/regular/order/save-result',
            },

            userPayment: {
                readyToPay: '{culture}/business-partner/user-payment/{0}/ready-to-pay',
                paymentResult: '{culture}/business-partner/user-payment/payment-result',
            },
            userOrder: {
                orderStatus: '{culture}/business-partner/order/status',
            }
        }
    },

    api: {
        account: {
            signIn: 'api/{culture}/BusinessPartner/Authentication',
        },
        payment: {
            getAmountOfPayment: 'api/{culture}/BusinessPartner/Payment/GetPaymentAmount',
            getPaymentGateways: 'api/{culture}/BusinessPartner/PaymentGateways/GetPaymentGateways',
            getPaymentUrl: 'api/{culture}/BusinessPartner/Payment/GetRequestUrlToIpg',
            settleSepehrPayment: 'api/{culture}/BusinessPartner/SepehrPayment/SettlePayment',
            settleAsanPardakhtPayment: 'api/{culture}/BusinessPartner/AsanPardakhtPayment/SettlePayment',
            postPaymentForOrders: 'api/{culture}/BusinessPartner/Payment/PaymentForOrders',
        },
        userOrder: {
            getSelectedOrdersForConfirmation: 'api/{culture}/BusinessPartner/SharedSheetOrder/GetSelectedOrdersForConfirmation',
        },
        userBalance: {
            paymentByCredit: 'api/{culture}/BusinessPartner/SharedSheetOrder/PaymentByCredit',
        },
        product: {
            getProductList: 'api/{culture}/BusinessPartner/Product/GetProductList',
            getProductInfo: 'api/{culture}/BusinessPartner/Product/GetProductInfo',
            getProductNameList: 'api/{culture}/BusinessPartner/Product/GetProductNameList',
            getProductsCirculations: 'api/{culture}/BusinessPartner/Product/GetProductsCirculations',
            getPrintingFeature: 'api/{culture}/BusinessPartner/Product/GetPrintingFeature',
            getProductSpecialDetail: 'api/{culture}/BusinessPartner/Product/GetProductSpecialDetail',
            getPrintingProcessTime: 'api/{culture}/BusinessPartner/Product/GetPrintingProcessTime',
        },
        gridProduct: {
            getProductWithFirstDimension: 'api/{culture}/Nas/Product/GetProductsWithFirstDimension',
            getProductWithAllDimension: 'api/{culture}/Nas/Product/GetProductWithAllDimensions',
        },
        order: {
            getProductBasicPrice: 'api/{culture}/BusinessPartner/SharedSheetOrder/GetBasicPrice',
            getSpecificDimensionInfo: 'api/{culture}/BusinessPartner/SharedSheetOrder/SpecificDimensionInfo',
            getProductAdvancePrice: 'api/{culture}/BusinessPartner/SharedSheetOrder/GetAdvancedPrice',
            checkFileBeforeUpload: 'api/{culture}/BusinessPartner/SharedSheetOrder/CheckFileBeforeUpload',
            uploadImageFile: 'api/{culture}/BusinessPartner/SharedSheetOrder/UploadMasterFile',
            uploadFilmFile: 'api/{culture}/BusinessPartner/SharedSheetOrder/UploadFilmFile',
            getFilePublicUrl: 'api/{culture}/BusinessPartner/SharedSheetOrder/GetFilePublicUrl',
            resizeFile: 'api/{culture}/BusinessPartner/SharedSheetOrder/ResizeFile',
            saveOrder: 'api/{culture}/BusinessPartner/SharedSheetOrder/Save',
            getExtendedServicePrice: 'api/{culture}/BusinessPartner/SharedSheetOrder/ExtendedServicePrice',
        },
        userOrder: {
            getBpOrderState: 'api/{culture}/BusinessPartner/SharedSheetOrder/{0}',
            getBpOrderStateHistories: 'api/{culture}/BusinessPartner/SharedSheetOrderState/Histories/{0}'
        },
        common: {
            getCategories: 'api/{culture}/BusinessPartner/ProductCategory/List',
            getImage: 'api/{culture}/BusinessPartner/FileManagement/File',
        },
    }
};

export default BusinessPartnerLinks;