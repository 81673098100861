import clsx from 'clsx';
import React from 'react';
import { TextField, MenuItem } from '@mui/material';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import '../../assets/sass/components/inputs/TextField.scss'
import { isEmpty } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';
import NsTypography from '../text/NsTypography';
import NsImage from '../images/NsImage';

class NsSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            invisibleHelperText: !this.props.selectedItem ? this.messageHelperText() : '',
            helperText: '',
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.showValidationDate !== this.props.showValidationDate)
            this.setState({ helperText: this.props.selectedItem ? '' : this.state.invisibleHelperText });
    }

    messageHelperText = () => {
        return CommonHelper.stringFormat(getTranslatedRawText("common.messages.select"),
            getTranslatedRawText(this.props.label));
    }

    onChangeHandler = (event) => {
        const isValidSelectedItem = this.getIsValidValue(this.normalizedOptions(this.props.options), event.target.value);
        CommonHelper.safeFunctionCall(this.props.valueChanged, event.target.value, isValidSelectedItem);
    }

    normalizedOptions = (options) => options?.map(item => item.key ? ({ ...item, id: item.key }) : item)

    getIsValidValue = (options, selectedItem) => {
        let normalizeOption = this.normalizedOptions(options)
        let isValidSelectedItem = false;
        const isOptionsEmpty = isEmpty(normalizeOption);
        const isSelectedItemEmpty = isEmpty(selectedItem);

        if (!isOptionsEmpty && !isSelectedItemEmpty)
            isValidSelectedItem = 0 <= normalizeOption.findIndex(el => el.id == selectedItem);

        if (isValidSelectedItem)
            this.setState({ invisibleHelperText: '', helperText: '' });
        else
            this.setState({ invisibleHelperText: this.messageHelperText() });

        return isValidSelectedItem;
    }

    mapSelectItems = (hasImage) => {
        return this.normalizedOptions(this.props.options).map(option =>
            <MenuItem key={option?.id} value={option?.id} >
                {hasImage ?
                    <div className='d-flex align-items-center'>
                        <NsImage src={option?.flagImage}
                            wrapperClassName='border rounded me-2'
                            width='30px'
                            imgAlt={CommonHelper.stringFormat(getTranslatedRawText('components.imagesAlt.countryFlag'), option?.name)} />
                        <NsTypography>
                            {option?.name || option?.value || option?.label}
                        </NsTypography>
                    </div>
                    :
                    option?.icon ?
                        <div className='d-flex align-items-center'>
                            <i className={clsx(option.icon, 'me-2')} />
                            <NsTypography>
                                {option?.name || option?.value || option?.label}
                            </NsTypography>
                        </div>
                        :
                        option?.name || option?.value || option?.label
                }
            </MenuItem >)
    }

    render() {
        const { className, label, disabled, hasImage, selectedItem, defaultOpen, notRequired } = this.props;
        const { helperText } = this.state;
        return (
            <TextField select
                className={clsx('ns-select w-100', className)}
                label={label}
                size="small"
                required={!notRequired}
                SelectProps={{
                    defaultOpen: !!defaultOpen
                }}
                variant='standard'
                disabled={disabled}
                onChange={this.onChangeHandler}
                helperText={helperText}
                value={selectedItem || ''} >
                {this.mapSelectItems(hasImage)}
            </TextField >
        );
    }
}

export default NsSelect;