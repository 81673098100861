import React from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NsButton from '../../../../../components/buttons/NsButton';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import NsCard from '../../../../../components/cards/NsCard';
import NsTypography from '../../../../../components/text/NsTypography';
import NsTour from '../../../../../components/tour/NsTour';
import Enumerations from '../../../../../configs/Enumerations';
import Links from '../../../../../configs/links/AllLinks';
import { miniCartActionCreators } from '../../../../../redux/reducers/MiniCartReducer';
import { GetProductAdvancePriceService, SaveOrderService } from '../../../../../services/order/OrderService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture, isEnCulture } from '../../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import { addToCartEvent } from '../../../../../utilities/GoogleTagManagerHelper';
import { messageClass } from '../../../../../utilities/MessageModalHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import RegularOrderRouteHelper from '../../bases/RegularOrderRouteHelper';
import MainContentWrapper from '../MainContentWrapper';


class ConfirmDetail extends React.Component {

    constructor(props) {
        super(props);
        this.tourId = Enumerations.tourGuide.confirmDetails;
        this.tourSteps = RegularOrderTours.getConfirmDetailTour();
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.mainButtons = [
            CommonHelper.createStickyButtonItem(OrderHelper.getExtendedServicesLength(this.routeId) > 0 ? 'regularOrder.extendedService.extendedService' : 'regularOrder.choosingFile.choosingFile', CommonHelper.getBackIconByCulture(), this.previousClicked, 'primary-outline'),
            CommonHelper.createStickyButtonItem('regularOrder.confirmDetail.submitOrder', 'ns-icon-confirm', this.saveOrderClicked, 'extendedService-tour-confirmSubmit primary'),
        ];
        this.state = {
            showTour: !CurrentUserHelper.isTourSeen(this.tourId),
            isViewMore: false,
            isLoading: false,
            viewMoreLabel: getTranslatedRawText('regularOrder.confirmDetail.viewMore'),
            series: OrderHelper.getSeries(this.routeId),
            sizeAndPrices: OrderHelper.getFileSizeAndOrderPrices(this.routeId),
            hasExtendedService: !!OrderHelper.getExtendedServicesLength(this.routeId),
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => {
        if (OrderHelper.isValidOrder(this.routeId))
            this.getPrice();
        else
            this.props.history.replace(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts));
    }

    detailMoreClicked = () => {
        const { isViewMore } = this.state;
        this.setState({ isViewMore: !isViewMore, viewMoreLabel: !isViewMore ? getTranslatedRawText('regularOrder.confirmDetail.viewLess') : getTranslatedRawText('regularOrder.confirmDetail.viewMore') });
    }

    getPrice = () => {
        this.setState({ isLoading: true });
        let data = OrderHelper.createAdvancePriceParameters(this.routeId);
        GetProductAdvancePriceService(data, this.getProductAdvancePriceCallback);
    }

    getProductAdvancePriceCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            OrderHelper.setFileSizeAndOrderPrices(this.routeId, resultData);
            this.setState({ sizeAndPrices: OrderHelper.getFileSizeAndOrderPrices(this.routeId) });
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    previousClicked = () => {
        if (OrderHelper.getExtendedServicesLength(this.routeId) > 0)
            RegularOrderRouteHelper.extendedServicesStep(this.routeId, this.props, OrderHelper.getFirstExtendedService(this.routeId).extendedServiceType);
        else
            RegularOrderRouteHelper.goTo(this.props, Links.view.main.regularOrder.orderDetailChoosingMasterFile);
    }

    saveOrderClicked = () => {
        !CurrentUserHelper.isTourSeen(this.tourId) && CurrentUserHelper.addToSeenTours(this.tourId);
        let files = OrderHelper.getFilesValue(this.routeId);
        let messages = OrderHelper.getExtendedServicesValidationMessage(this.routeId);
        if (!isEmpty(messages)) {
            this.setState({ resultMessageClass: messageClass(messages) });
            return;
        }
        OrderHelper.suggestedFiles(this.routeId, (suggestedFiles) => {
            let data = {
                productId: CommonHelper.toInt(OrderHelper.getId(this.routeId)),
                turnaroundType: OrderHelper.getTurnaround(this.routeId).key,
                twoSidePrintingType: OrderHelper.getPrintedSide(this.routeId).key,
                series: OrderHelper.getSeries(this.routeId),
                masterFiles: files.masterFiles,
                filmFiles: files.filmFiles,
                extendedServices: OrderHelper.getExtendedServicesValue(this.routeId),
                description: this.state.description,
                uploadedFiles: suggestedFiles.map(item => ({ id: item.id, name: item.name }))
            };
            this.setState({ isLoading: true, showTour: false });
            SaveOrderService(data, this.saveRegularOrderCallback);

        });
    }

    saveRegularOrderCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            const product = OrderHelper.get(this.routeId);
            addToCartEvent(product, Enumerations.googleTagManagerListId.regularOrderDetail,)
            OrderHelper.remove(this.routeId);
            this.props.getTotalPriceAndCount();
            this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.regularOrder, Links.view.main.regularOrder.orderSaveResult)
                , this.routeId));
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        if (!OrderHelper.isValidOrder(this.routeId)) {
            this.props.history.replace(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts));
            return null;
        }
        const { sizeAndPrices, viewMoreLabel, showTour } = this.state;
        return (
            <MainContentWrapper otherProps={this.props}
                mainStepperActiveStep={Enumerations.orderLocation.confirmDetail}
                routeId={this.routeId}>

                <NsCard className='p-3'>
                    <div className='d-flex align-items-baseline justify-content-between mb-3'>
                        <NsTypography variant='inherit' className='section-title' >
                            {OrderHelper.getProductName(this.routeId)}
                        </NsTypography>
                        <NsButton label={viewMoreLabel}
                            wrapperClassName='ms-2'
                            size='small'
                            className='primary-text text-nowrap'
                            onClick={this.detailMoreClicked} />
                    </div>
                    <ShowResult data={this.state} routeId={this.routeId} />
                    <NsTypography className='font-size-18 border-top mt-3 pt-2'>
                        {getTranslatedRawText('regularOrder.confirmDetail.totalPriceExVat')}
                        <span className="red-color fw-bold">
                            {CommonHelper.stringFormat(' : {0}',
                                CommonHelper.currencyFormatByDefaultConfig(sizeAndPrices.totalPriceAfterMultipleInQuantity))}
                        </span>
                    </NsTypography>

                    <NsStickyButton stickyButtons={this.mainButtons}
                        isLoading={this.state.isLoading} />

                </NsCard>
                {this.state.resultMessageClass}
                {showTour && <NsTour steps={this.tourSteps}
                    id={this.tourId} />}
            </MainContentWrapper >
        );
    }
}

function ShowResult(props) {
    const { isViewMore, series, sizeAndPrices, hasExtendedService } = props.data;
    const circulationValue = OrderHelper.getCirculation(props.routeId).value;
    const latCount = CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.latCount'), sizeAndPrices.latCount);

    const defaultDetails = [
        {
            label: getTranslatedRawText('regularOrder.header.quantity'),
            value: (isViewMore && series > 1) ? CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.quantityDetail'), circulationValue, series, circulationValue * series)
                : CommonHelper.stringFormat('{0}', circulationValue * series)
        },
        {
            label: getTranslatedRawText('regularOrder.confirmDetail.fileDimension'),
            value: sizeAndPrices?.fileDimension
        },

        {
            label: getTranslatedRawText('regularOrder.confirmDetail.price'),
            value: isViewMore && (series > 1 || latCount > 1)
                ? CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.priceDetail'),
                    CommonHelper.currencyFormatByDefaultConfig(sizeAndPrices.basePrice), latCount, series, CommonHelper.currencyFormatByDefaultConfig(series * sizeAndPrices.latCount * sizeAndPrices.basePrice))

                : CommonHelper.currencyFormatByDefaultConfig(series * sizeAndPrices.latCount * sizeAndPrices.basePrice)

        },
        hasExtendedService &&
        {
            label: getTranslatedRawText('regularOrder.confirmDetail.servicesPrice'),
            value: (isViewMore && series > 1) ? CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.servicesPriceDetail'),
                CommonHelper.currencyFormatByDefaultConfig(sizeAndPrices.servicePrice), series, CommonHelper.currencyFormatByDefaultConfig(series * sizeAndPrices.servicePrice))
                :
                CommonHelper.currencyFormatByDefaultConfig(series * sizeAndPrices.servicePrice)
        },
    ];
    const subDetails = [
        {
            label: getTranslatedRawText('regularOrder.header.turnaround'),
            value: OrderHelper.getTurnaround(props.routeId).value
        },
        {
            label: getTranslatedRawText('regularOrder.header.printedSide'),
            value: OrderHelper.getPrintedSide(props.routeId).value
        }
    ];
    let details = "";
    details = isViewMore ? [...subDetails, ...defaultDetails] : [...defaultDetails];

    return (
        <Table className='border table-bordered extendedService-tour-confirmDetails'>
            <tbody>
                {details.map((item, index) => (
                    !!item &&
                    <tr key={index} className='primary-paragraph'>
                        <td className='p-2'>{item.label}</td>
                        <td className='p-2'> {item.value}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...miniCartActionCreators }, dispatch);
}

export default connect(null, mapDispatchToProps)(ConfirmDetail);