import React, { useCallback, useEffect, useState } from "react";

import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import NsStickyButton from "../../../../../components/buttons/NsStickyButton";
import CommonHelper from "../../../../../utilities/CommonHelper";
import NsModal from "../../../../../components/modals/NsModal";
import ApLanguageTabs from "../../../bases/ApLanguageTabs";
import NsMessageClassModal from "../../../../../components/modals/NsMessageClassModal";
import Enumerations from "../../../../../configs/Enumerations";
import ContentHelper from "../../bases/ContentHelper";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import { messageClass } from "../../../../../utilities/MessageModalHelper";
import ApBasesCategoryContentModal from "../../bases/ApBasesCategoryContentModal";
import {
    DeleteNewsCategoryService,
    EditNewsCategoryService,
    GetNewsCategoryByIdService,
    PostNewsCategoryService
} from "../../../../../services/adminPanel/AdminNewsService";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import ApBaseMapActionButtons from "../../../bases/ApBaseMapActionButtons";

const ApNewsCategoryModal = (props) => {
    const { categoryId, isNewCategory } = props;

    const [multiLanguageCategoryContent, setMultiLanguageCategoryContent] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [isLoading, setIsLoading] = useState(!isNewCategory);
    const [showValidationDate, setShowValidationDate] = useState();
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        !!isNewCategory
            ? setMultiLanguageCategoryContent(ContentHelper.normalizeContentObject([], { isActive: false }))
            : GetNewsCategoryByIdService(categoryId, getCategoryByIdCallback);
    }, []);

    const getCategoryByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguageCategoryContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive }));
        } else
            setMessageClassModal(messageClassModal);
    };

    const closeModalClicked = (messageClassModal) => CommonHelper.safeFunctionCall(props.closeModal, messageClassModal);

    const saveButtonClicked = () => {
        let tempContent = ContentHelper.removeBlogCategoryEmptyContent(multiLanguageCategoryContent);
        let isNotValidData = ContentHelper.isValidBlogCategoryContent(tempContent);
        if (isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }

        setIsLoading(true);
        !!isNewCategory
            ? PostNewsCategoryService(tempContent, CategoryContentCallback, true)
            : EditNewsCategoryService(categoryId, tempContent, CategoryContentCallback, true);
    };

    const CategoryContentCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModalClicked(messageClassModal);
        } else
            setMessageClassModal(messageClassModal);
    };

    const deleteCategoryClicked = () => setShowDeleteCategoryModal(true);

    const deleteCategory = (confirm) => {
        setShowDeleteCategoryModal(false);
        if (confirm) {
            setIsLoading(true);
            DeleteNewsCategoryService(categoryId, deleteCategoryCallback, true);
        }
    };

    const deleteCategoryCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModalClicked(messageClassModal);
        else
            setMessageClassModal(messageClassModal);
    };

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguageCategoryContent([...newData]);
    }

    const mainButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary', accessIds: [UserClaimEnumerations.editApNewsCategory] },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(false), className: 'primary-outline' },
    ];

    const headerButtons = [
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deleteCategoryClicked, accessIds: [UserClaimEnumerations.deleteApNewsCategory] }
    ];

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText(!categoryId ? 'adminPanel.subject.newCategory' : 'adminPanel.subject.editCategory')}
            showModal={true}
            noSpace={true}
            closeModal={closeModalClicked}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>

            {!isNewCategory && <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            }
            <div className="p-3">
                <ApLanguageTabs languageChanged={languageChangedHandler}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguageCategoryContent) &&
                    <ApBasesCategoryContentModal key={selectedLanguage.id}
                        stateHandler={stateHandler}
                        serviceCall={() => GetNewsCategoryByIdService(categoryId, getCategoryByIdCallback)}
                        fileUploaderCategoryType={Enumerations.fileUploaderCategoryType.newsCategory}
                        data={[...multiLanguageCategoryContent]}
                        showValidationDate={showValidationDate}
                        selectedLanguage={selectedLanguage} />
                }
            </div>

            {showDeleteCategoryModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => deleteCategory(confirm)}
                    messageItems={[
                        {
                            messageText: <>{getTranslatedRawText('common.messages.areYouSureQuestionWithOutParams')} <b>{ContentHelper.getSpecificLanguageContent(multiLanguageCategoryContent, ContentHelper.getDefaultCultureId()).content.title}</b>?</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }
            {messageClassModal}

        </NsModal >
    );
}

export default React.memo(ApNewsCategoryModal);