import clsx from 'clsx';
import React from 'react';
import { Col } from 'react-bootstrap';

import NsCard from '../../../../components/cards/NsCard';
import NsLabelBox from '../../../../components/labels/NsLabelBox';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';
import CommonHelper from '../../../../utilities/CommonHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

const ApDspGadgets = (props) => {

    const { data, isLoading } = props;

    return isLoading
        ? CommonHelper.createArray(4).map((item, index) =>
            <div key={index} className="d-inline d-md-inline-block col-md-3 p-2" >
                <NsSkeleton isVisible={true}
                    variant={[
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                    ]} />
            </div >
        )
        : data.map((item, index) =>
            !isEmpty(item) && <Col sm={6} lg={4} xl={3} key={index}>
                <NsCard contentClassName={clsx(!item?.isActive && 'opacity-50', 'd-flex align-items-center justify-content-between p-3')}>
                    <NsLabelBox className='background-white-color'
                        label={item?.name}
                        value={`${CommonHelper.currencyFormat(item?.value, true)} ${item?.currencyTypeName}`} />
                </NsCard>
            </Col>
        )
};

export default React.memo(ApDspGadgets);