import React from 'react';
import { Row } from 'react-bootstrap';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';

import '../../assets/sass/components/stepper/NsStepper.scss';
import Enumerations from '../../configs/Enumerations';
import NsTypography from '../text/NsTypography';
import NsButton from '../buttons/NsButton';

class NsStepperWithActionButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stepperOrientation: props.orientation,
            stepList: props.stepList || [],
            activeStep: props.activeStep || 3,
            currentStep: props.currentStep,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            stepperOrientation: newProps.orientation,
            stepList: newProps.stepList,
            activeStep: newProps.activeStep || 0,
            currentStep: newProps.currentStep,
        });
    }

    doAction = (button) => {
        let isValid = button.validation(button.type, this.state.currentStep);
        if (isValid) {
            let newStep = 0;
            switch (button.type) {
                case Enumerations.stepperButtonType.previous:
                    this.setState({ activeStep: this.state.activeStep - 1 });
                    newStep = this.state.currentStep - 1;
                    break;
                case Enumerations.stepperButtonType.skip:
                case Enumerations.stepperButtonType.next:
                    if (this.state.currentStep === this.state.activeStep)
                        this.setState({ activeStep: this.state.activeStep + 1 });
                    newStep = this.state.currentStep + 1;
                    break;
                default: return;
            }
            button.event(button.type, newStep);
        }
    }

    mapStepItems = (stepList, activeStep, stepperOrientation) => {

        return !!stepList && stepList.map((item, index) => (

            <Step key={index} className='stepper__item'>
                <StepLabel className=' stepper__label'>{item.title}</StepLabel>
                {stepperOrientation === 'vertical' &&
                    <StepContent>
                        {this.props.children}
                        {this.mapButtons(stepList, activeStep)}
                    </StepContent>
                }
            </Step>
        ));

    }

    mapButtons = (stepList, activeStep) => {
        let index = activeStep
        if (!!this.state.currentStep || this.state.currentStep === 0)
            index = this.state.currentStep
        index = index >= stepList.length ? index - 1 : index;

        return !!stepList[index] && !!stepList[index].buttons ?
            <div className="d-flex px-3  my-3 justify-content-end">
                {stepList[index].buttons.map((button, index) =>
                    button.visible ?

                        <NsButton className={button.className}
                            key={index}
                            wrapperClassName={index === 0 ? 'ms-auto' : 'ms-2'}
                            onClick={() => this.doAction(button)
                            }
                            startIcon={button.icon}
                            disabled={button.disabled}
                            label={button.label} />

                        : '')
                }
            </div>
            : ''
    }

    mapStepperContent = (stepList, activeStep) => {
        return (<React.Fragment>
            {this.props.children}
            {this.mapButtons(stepList, activeStep)}
        </React.Fragment>);
    }

    render() {
        const { stepList, activeStep, stepperOrientation } = this.state;
        return stepList?.length > 1
            ? <React.Fragment>
                <Stepper activeStep={activeStep}
                    orientation={this.props.orientation}
                    className='stepper py-3'>
                    {this.mapStepItems(stepList, activeStep, stepperOrientation)}
                </Stepper>
                {stepperOrientation !== 'vertical' && this.mapStepperContent(stepList, activeStep)}
            </React.Fragment>
            :
            <React.Fragment>
                <NsTypography variant='inherit' className='d-block primary-paragraph p-3'>
                    {!!stepList[0] && stepList[0].title}
                </NsTypography>
                {this.mapStepperContent(stepList, activeStep)}
            </React.Fragment>
    }
}

export default NsStepperWithActionButtons;