const GridEnumerations = {
    siteGrids: {
        none: 0,
        allGroupSheetOrders: 1,
        advancedAllGroupSheetOrders: 2,
        archiveGroupSheetOrders: 3,
        canceledGroupSheetOrders: 4,
        inWarehouseGroupSheetOrders: 5,
        inTransitGroupSheetOrders: 6,
        inProcessGroupSheetOrdersAll: 7,
        inProcessGroupSheetOrdersLayouting: 8,
        inProcessGroupSheetOrdersLithography: 9,
        inProcessGroupSheetOrdersPrinting: 10,
        inProcessGroupSheetOrdersCutting: 11,
        preparingGroupSheetOrders: 12,
        failedGroupSheetOrders: 13,
        cartGroupSheetOrders: 14,
        groupSheetOrderDetail: 15,
        myShippingInTheWarehouse: 16,
        myShippingExitingWarehouse: 17,
        myShippingSendingToCourier: 18,
        myShippingDeliveredToCourier: 19,
        myShippingDeliveredToCustomer: 20,
        myShippmentGroupSheetDetail: 21,
        paymentsReport: 22,
        financialBalance: 23,
        archiveStoreOrders: 24,
        canceledStoreOrders: 25,
        inWarehouseStoreOrders: 26,
        inTransitStoreOrders: 27,
        preparingStoreOrders: 28,
        inProcessPaymentStoreOrders: 29,
        cartStoreOrders: 30,
        storeOrderDetail: 31,
        myShippmentStoreDetail: 32
    },

    adminPanelGrids: {
        none: 0,
    }

}

export default GridEnumerations;

