import { getTranslatedRawText } from "../../../utilities/CultureHelper";


class UserOrderTours {

    static getUserCartTour() {
        return ([
            {
                selector: '.userOrders-tour-card',
                content: getTranslatedRawText('profile.orderCart.tours.cart'),
            }
        ]);
    }
}

export default UserOrderTours;
