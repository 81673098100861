import React from 'react';

import NsButton from '../../../../components/buttons/NsButton';
import Config from '../../../../configs/Config';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import CurrencySelection from './bases/CurrencySelection';
import LanguageSelection from './bases/LanguageSelection';

class TopHeader extends React.Component {

    removeAllTours = () => CurrentUserHelper.addAllToursInStorage();

    render() {
        return (
            <div className="d-none d-md-flex py-1">
                <div>
                    <div className="white-color font-size-12">
                        {getTranslatedRawText('headers.top.welcomeTo')}
                        <p className='d-inline-block font-size-12'>{getConfigObjectByCulture(Config.metaTags)?.title}</p>
                        {getTranslatedRawText('headers.top.enjoyDifferentWay')}
                    </div>
                </div>
                <div className='ms-auto d-flex gap-2'>
                    {this.props.username &&
                        <CurrencySelection className='white-color font-size-12' />
                    }
                    <LanguageSelection className='white-color font-size-12' />
                    {process.env.NODE_ENV === 'development' && <NsButton onClick={this.removeAllTours} startIcon="ns-icon-setting" size='small' className='light' />}
                </div>
            </div>
        );
    }
}

export default TopHeader;