import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NsPageMainContentWrapper from '../../../../../components/cards/NsPageMainContentWrapper';
import NsTextField from '../../../../../components/inputs/NsTextField';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton'
import CommonHelper from '../../../../../utilities/CommonHelper';

function CommunicationChannel(props) {
    const [state, setState] = useState({
        loading: false,
        phoneNumber: '',
        isValidPhoneNumber: false,
        whatsAppPhoneNumber: '',
        isValidWhatsAppPhoneNumber: false,
        facebook: '',
        isValidFacebook: false,
        youTube: '',
        isValidYouTube: false,
        whatsApp: '',
        isValidWhatsApp: false,
        instagram: '',
        isValidInstagram: false,
        showValidationDate: '',
    });

    const saveClicked = () => {
        setState({
            ...state,
            showValidationDate: new Date()
        });
        return;
    }

    const discardClicked = () => setState({ loading: true },
        /* () =>  api*/)


    const mainButtons = [
        { label: "common.save", onClicked: saveClicked, icon: 'ns-icon-save', className: 'primary' },
        { label: "common.discard", onClicked: discardClicked, icon: 'ns-icon-close', className: 'primary-outline' },
    ];
    const valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        setState({
            ...state,
            [valueName]: value,
            [isValidName]: isValid
        })
    }
    return (
        <NsPageMainContentWrapper header={getTranslatedRawText("adminPanel.sidebar.communicationChannel")}
            icon={'ns-icon-information h1'}>

            <Row className='w-100' >
                <Row className='w-100' >
                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { phoneNumber: state.phoneNumber }, { isValidPhoneNumber: state.isValidPhoneNumber })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.communicationChannel.phoneNumber")}
                            size="small"
                            value={state.phoneNumber}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { whatsAppPhoneNumber: state.whatsAppPhoneNumber }, { isValidWhatsAppPhoneNumber: state.isValidWhatsAppPhoneNumber })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.communicationChannel.whatsAppPhoneNumber")}
                            value={state.whatsAppPhoneNumber}
                            className="w-100" />
                    </Col>
                </Row>
                <Row className='w-100' >

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { facebook: state.facebook }, { isValidFacebook: state.isValidFacebook })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.communicationChannel.facebook")}
                            size="small"
                            value={state.facebook}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { youTube: state.youTube }, { isValidYouTube: state.isValidYouTube })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.communicationChannel.youTube")}
                            size="small"
                            fullWidth={true}
                            value={state.youTube}
                            className="w-100"
                        />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { whatsApp: state.whatsApp }, { isValidWhatsApp: state.isValidWhatsApp })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.communicationChannel.whatsApp")}
                            size="small"
                            value={state.whatsApp}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { instagram: state.instagram }, { isValidInstagram: state.isValidInstagram })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.communicationChannel.instagram")}
                            size="small"
                            value={state.instagram}
                            className="w-100" />
                    </Col>

                </Row>

            </Row>

            <NsStickyButton stickyButtons={mainButtons} />

        </NsPageMainContentWrapper>
    );
}

export default React.memo(CommunicationChannel);