import React from 'react';

import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import NsStickyButton from '../../../../../../components/buttons/NsStickyButton';
import BaseSigninContent from '../../../../../accounts/bases/BaseSigninContent';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import NsModal from '../../../../../../components/modals/NsModal';

class SignInModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [CommonHelper.createStickyButtonItem("account.common.login", null, this.signInClicked, 'primary')];
        this.state = {
            isLoading: false,
            signInDate: null
        };
    }

    signInClicked = () => this.setState({ signInDate: new Date() });

    toggleLoading = (isLoading) => {
        this.setState({ isLoading });
    }

    closeModal = () => {
        CommonHelper.safeFunctionCall(this.props.signInClicked);
        CommonHelper.safeFunctionCall(this.props.closeModal);
    }

    render() {
        return (
            <NsModal showModal={true}
                dialogTitle={getTranslatedRawText('components.signInModal.title')}
                maxWidth="xs"
                closeModal={this.props.closeModal}
                actions={<NsStickyButton stickyButtons={this.mainButtons}
                    isLoading={this.state.isLoading} />} >

                <BaseSigninContent useInModal={true}
                    closeModal={this.closeModal}
                    signInDate={this.state.signInDate}
                    toggleLoading={this.toggleLoading} />

            </NsModal>
        );
    }
}

export default SignInModal;