import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import NsSetPageTitleAndDescription from '../../../components/metaTags/NsSetPageTitleAndDescription';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { SendVerificationCodeForSignupService } from '../../../services/accounts/AccountService';
import { getConfigObjectByCulture } from '../../../utilities/ConfigHelper';
import { getEnumKeyFromValue } from '../../../utilities/EnumerationHelper';
import NsMobileInput from '../../../components/inputs/NsMobileInput';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsTypography from '../../../components/text/NsTypography';
import NsCaptcha from '../../../components/recaptcha/NsCaptcha';
import NsButton from '../../../components/buttons/NsButton';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';
import AccountWrapper from '../AccountWrapper';
import Config from '../../../configs/Config';

class SignupMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            countryId: '',
            isValidMobile: false,
            isLoading: false,
            showValidationDate: null,
            refreshCaptchaDate: null,
            captchaMessage: null,
            showValidationDateCaptcha: null,
            captchaId: null,
            captchaCode: '',
            resultMessageClass: (<></>)
        };
    }

    componentDidMount = () => {
        const persistentMobile = CurrentUserHelper.getPersistentMobile();
        !!persistentMobile &&
            this.setState({ mobile: persistentMobile, isValidMobile: true });
    }

    keyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter) {
            event.preventDefault();
            this.sendVerificationCodeClicked();
        }
    }

    mobileChanged = (value, isValid, countryId) => {
        this.setState({
            mobile: value,
            isValidMobile: isValid,
            countryId: countryId
        });
        if (isValid)
            CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.persistentMobile), value);
    }

    sendVerificationCodeClicked = () => {
        if (!this.state.isValidMobile) {
            this.setState({ showValidationDate: new Date() });
            return false;
        }
        if (!!this.state.refreshCaptchaDate && !this.state.isValidCaptchaCode) {
            this.setState({ showValidationDateCaptcha: new Date() });
            return false;
        }
        CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.mobile), this.state.mobile);
        let data = {
            countryCode: CurrentUserHelper.getCountryCode(),
            mobileWithOutCountryCode: CurrentUserHelper.getMobileWithOutCountryCode(),
        };
        if (!!this.state.refreshCaptchaDate) {
            data = {
                ...data,
                captchaCode: this.state.captchaCode,
                captchaId: this.state.captchaId,
            }
        };
        this.setState({ isLoading: true });
        SendVerificationCodeForSignupService(data, this.sendVerificationCodeCallback);
    }

    sendVerificationCodeCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.props.history.push(
                getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.activation), { totalRemainingTimeInSecond: resultData?.totalRemainingTimeInSecond || 0 });
        else
            this.setState({
                resultMessageClass: messageClassModal, isLoading: false,
                refreshCaptchaDate: result.data.messageItems.some(item => item?.messageType === Enumerations.messageType.needCaptcha)
                    ? new Date()
                    : null,
                captchaMessage: result.data.messageItems.find(item => item?.messageType === Enumerations.messageType.needCaptcha)?.messageText
            });
    }

    recaptchaChanged = (captchaCode, isValidCaptchaCode, captchaId) => this.setState({ captchaCode, isValidCaptchaCode, captchaId });

    render() {
        const { refreshCaptchaDate, captchaMessage, showValidationDateCaptcha, mobile, showValidationDate,
            isLoading, resultMessageClass } = this.state;
        return (
            <AccountWrapper layoutType={Enumerations.loginLayoutType.signup}>

                <Form onKeyDown={this.keyDown}>
                    <div className="white-panel g-0 d-flex flex-column my-sm-n5 w-100 p-4">

                        <h1 className='section-title'>
                            {getTranslatedRawText("account.signup.signupMobileTitle")}
                        </h1>
                        <NsTypography variant='inherit' className='primary-paragraph'>
                            {getTranslatedRawText("account.signup.signupMobileDescription")}
                        </NsTypography>

                        <div className="mt-5">
                            <input hidden name="username" value={mobile} readOnly />
                            <NsMobileInput mobileChanged={this.mobileChanged}
                                mobile={mobile}
                                saveCountryDataInStorage={true}
                                showValidationDate={showValidationDate} />
                        </div>
                        {!!refreshCaptchaDate &&
                            <div className="mt-3">
                                <NsCaptcha refreshCaptchaDate={refreshCaptchaDate}
                                    recaptchaChanged={this.recaptchaChanged}
                                    captchaMessage={captchaMessage}
                                    showValidationDateCaptcha={showValidationDateCaptcha} />
                            </div>
                        }
                        <div className="d-flex justify-content-center mt-5">
                            <NsButton className="primary"
                                wrapperClassName="my-md-0 mt-4 mb-3"
                                onClick={this.sendVerificationCodeClicked}
                                loading={isLoading}
                                label="account.signup.sendVerificationCode" />
                        </div>
                        <div className="d-flex justify-content-center d-sm-none">
                            <Link to={getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn)} className="py-1">
                                {getTranslatedRawText("account.common.login")}
                            </Link>
                        </div>
                        {resultMessageClass}
                    </div>
                </Form>
                <NsSetPageTitleAndDescription pageTitle='common.breadcrumbs.signup'
                    description={getConfigObjectByCulture(Config.metaTags)?.descriptions.signUp} />
            </AccountWrapper>
        );
    }
}

export default SignupMobile;