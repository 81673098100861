import React, { useEffect, useState } from 'react';

import NsDownloadFile from '../../../../components/downloadFiles/NsDownloadFile';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseMapActionButtons from '../../bases/ApBaseMapActionButtons';
import ApBaseUserInfoModal from '../users/bases/ApBaseUserInfoModal';
import { getApPaymentDetailModalColumns } from './ApPaymentHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import ApShareSheetOrderDetailModal from '../orders/shareSheets/ApShareSheetOrderDetailModal';
import Enumerations from '../../../../configs/Enumerations';
import NsModal from '../../../../components/modals/NsModal';
import ApBaseWrapper from '../../bases/ApBaseWrapper';
import {
    GetPaymentHistoryService,
    GetPaymentIncreaseCreditService,
    GetPaymentOrdersService,
    GetPaymentReportService,
    GetPaymentByIdService,
    UpdatePaymentStatusService,
    PaymentResolveProblemService
} from '../../../../services/adminPanel/AdminPaymentService';
import EnumerationTranslationHelper from '../../../../utilities/EnumerationTranslationHelper';
import ApPaymentAuditImportedFileDetailModal from './audits/ApPaymentAuditImportedFileDetailModal';

const ApPaymentDetailModal = (props) => {

    const { showModal, selectedPaymentId } = props;
    const [payment, setPayment] = useState({});
    const [selectedOrder, setSelectedOrder] = useState();
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [showAuditDetailModal, setShowAuditDetailModal] = useState(false);
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [file, setFile] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState((<></>));

    const hasAccessInfo = hasAccess([UserClaimEnumerations.getApPaymentById]);
    const hasAccessHistory = hasAccess([UserClaimEnumerations.getApPaymentStatusHistory]);
    const hasAccessGrid = hasAccess(payment?.paymentType === Enumerations.paymentType.increaseCredit
        ? [UserClaimEnumerations.getApPaymentIncreaseCredit]
        : [UserClaimEnumerations.getApPaymentOrders]);

    const paymentGatewayType = {
        key: payment?.paymentGatewayType,
        value: EnumerationTranslationHelper.getPaymentGatewayType(payment?.paymentGatewayType)
    }

    useEffect(() => {
        if (!!selectedPaymentId) {
            getPayment();
        }
    }, []);

    const handlers = {
        detailClicked: (id) => {
            setSelectedOrder({ id, userId: payment?.userId });
            setShowOrderDetailModal(true);
        },

        toggleUserInfoModal: () => {
            setShowUserInfoModal(!showUserInfoModal);
        },

        showInvoiceClicked: (id) => {
            setIsLoadingInfo(true);
            GetPaymentReportService(selectedPaymentId, payment?.userId, getPaymentReportCallback);
        },

        updateStatusClicked: () => {
            setIsLoadingInfo(true);
            UpdatePaymentStatusService(selectedPaymentId, updatePaymentStatusCallback);
        },

        toggleAuditDetailModal: () => setShowAuditDetailModal(!showAuditDetailModal),

        closeApShareSheetOrderDetailModal: () => setShowOrderDetailModal(false),
    }

    const getPayment = () => {
        if (!hasAccessInfo) return;
        setIsLoadingInfo(true);
        GetPaymentByIdService(selectedPaymentId, getPaymentByIdCallback);
    }

    const getPaymentByIdCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            setPayment(resultData?.paymentItemInfoDto);
        } else
            setResultMessageClass(messageClassModal);
        setIsLoadingInfo(false);
    }

    const getPaymentReportCallback = (resultData, result, messageClassModal) => {
        setIsLoadingInfo(false);
        if (!result.data.hasError) {
            const href = !isEmpty(resultData) && CommonHelper.createDownloadFileUrl(resultData?.fileDownloadName, resultData?.fileContents);
            setFile({ fileContents: href });
        } else
            setResultMessageClass(messageClassModal);
    }

    const updatePaymentStatusCallback = (resultData, result, messageClassModal) => {
        setResultMessageClass(messageClassModal);
        setIsLoadingInfo(false);
        if (!result.data.hasError)
            getPayment();
    }

    const resolvePaymentProblemClicked = () => {
        let params = {
            userId: payment.userId,
            paymentId: selectedPaymentId,
            paymentProblemType: payment.paymentProblemType
        }
        setIsLoadingInfo(true);
        PaymentResolveProblemService(params, resolvePaymentProblemCallback, true)
    }

    const resolvePaymentProblemCallback = (resultData, result, messageClassModal) => {
        setIsLoadingInfo(false);
        CommonHelper.safeFunctionCall(props.closeModal, messageClassModal)
    }

    const { detailClicked, toggleUserInfoModal, showInvoiceClicked, updateStatusClicked, closeApShareSheetOrderDetailModal, toggleAuditDetailModal } = handlers;

    const headerButtons = [
        hasAccess([UserClaimEnumerations.getApCustomerById]) && { wrapperClassName: '', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: () => toggleUserInfoModal() },
        payment?.lastStatusType === Enumerations.paymentStateType.open && hasAccess([UserClaimEnumerations.updateApPaymentStatus]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-retry', label: 'adminPanel.common.updateStatus', onClick: () => updateStatusClicked() },
        hasAccess([UserClaimEnumerations.getApPaymentReport]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-pdf-file', label: 'adminPanel.common.receipt', onClick: () => showInvoiceClicked() },
        props.useInPaymentsHasProblem && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-fix-problem', label: CommonHelper.stringFormat(getTranslatedRawText('adminPanel.common.updatePaymentState'), EnumerationTranslationHelper.setTranslateForPaymentProblemType(payment.paymentProblemType)), onClick: resolvePaymentProblemClicked },
        hasAccess([UserClaimEnumerations.getApPaymentAuditById]) &&
        !!payment?.paymentAuditId &&
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-audit', label: 'adminPanel.sidebar.audit', onClick: () => toggleAuditDetailModal() },
    ];

    return (<NsModal maxWidth='lg'
        showModal={showModal}
        closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
        noSpace={true}
        dialogTitle={getTranslatedRawText("adminPanel.sidebar.payments")} >

        <ApBaseMapActionButtons actionButtons={headerButtons}
            isLoading={isLoadingInfo} />

        {!isEmpty(payment) &&
            <ApBaseWrapper isLoadingInfo={isLoadingInfo}
                historyService={GetPaymentHistoryService}
                info={{ paymentItemInfoDto: { ...payment } }}
                hasAccessInfo={hasAccessInfo}
                hasAccessHistory={hasAccessHistory}
                service={hasAccessGrid ?
                    payment?.paymentType === Enumerations.paymentType.increaseCredit
                        ? GetPaymentIncreaseCreditService
                        : GetPaymentOrdersService
                    : ''
                }
                parameters={{
                    paymentId: selectedPaymentId,
                    id: payment?.userId
                }}
                paymentType={payment?.paymentType}
                columnDefs={getApPaymentDetailModalColumns(detailClicked, payment?.paymentType)} />
        }
        {
            showOrderDetailModal &&
            <ApShareSheetOrderDetailModal showModal={showOrderDetailModal}
                selectedOrder={selectedOrder}
                closeModal={closeApShareSheetOrderDetailModal} />
        }

        {
            showUserInfoModal &&
            <ApBaseUserInfoModal showModal={showUserInfoModal}
                selectedUserId={payment?.userId}
                closeModal={toggleUserInfoModal} />
        }

        {
            showAuditDetailModal &&
            <ApPaymentAuditImportedFileDetailModal fileSourceId={payment?.paymentAuditSourceId}
                auditId={payment?.paymentAuditId}
                paymentGatewayType={paymentGatewayType}
                closeModal={toggleAuditDetailModal} />
        }

        <NsDownloadFile file={file} />

        {resultMessageClass}
    </NsModal >
    );
}

export default React.memo(ApPaymentDetailModal);