import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { currentUserActionCreators } from '../../../redux/reducers/CurrentUserReducer';
import { currencyActionCreators } from '../../../redux/reducers/CurrencyReducer';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import NsCard from '../../../components/cards/NsCard';
import Links from '../../../configs/links/AllLinks';

class UpSidebarProfileSetting extends React.Component {

    signOut = () => {
        this.props.removeCurrencies();
        this.props.removeCurrentUser();
    }

    navLinks = [
        { to: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationSetting), icon: "ns-icon-profile-information", label: "profile.actionButton.profileInfo" },
        { to: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileFeedbackSetting), icon: "ns-icon-feedback-types", label: "profile.feedback.feedbacks" },
        { to: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileNotificationSetting), icon: "ns-icon-notification-setting", label: "profile.actionButton.notificationSetting" },
        { to: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAgencySetting), icon: "ns-icon-agency-setting", label: "profile.actionButton.agencySetting" },
        CurrentUserHelper.getHasAutoDeliveryAccess() && { to: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAutoShippingSetting), icon: "ns-icon-auto-delivery-setting", label: "profile.actionButton.autoDeliverySetting" },
        { to: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAddressSetting), icon: "ns-icon-address", label: "profile.actionButton.address" },
    ]

    render() {
        return (
            <NsCard className='px-3 py-2'>
                {CommonHelper.clearArrayFromEmptyItem(this.navLinks).map((link, index) => {
                    return link.to && <NavLink key={index}
                        to={link.to}
                        onClick={this.props.onClick}
                        className={isActive => clsx('nav-link-profile-setting pb-1', isActive && 'background-element-color')}>
                        <i className={clsx(link.icon, "align-middle me-2 h2")} />
                        <span className="primary-paragraph">
                            {getTranslatedRawText(link.label)}
                        </span>
                    </NavLink>
                })}
                <NavLink to="#"
                    onClick={this.signOut}
                    className="nav-link-profile-setting pb-1">
                    <i className="ns-icon-sign-out align-middle me-2 h2" />

                    <span className="primary-paragraph">
                        {getTranslatedRawText('profile.actionButton.signOut')}
                    </span>
                </NavLink>
            </NsCard>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...currentUserActionCreators, ...currencyActionCreators }, dispatch);
}

export default connect(null, mapDispatchToProps)(UpSidebarProfileSetting);