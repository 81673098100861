const ViewLinks = {
    home: '{culture}/',
    aboutUs: '{culture}/about-us',
    admin: '{culture}/admin',
    contactUs: '{culture}/contact-us',
    instantDiscounts: '{culture}/instant-discounts',
    frequentlyAskedQuestions: '{culture}/frequently-asked-questions',
    gallery: '{culture}/gallery',
    galleryCategoryPosts: '{culture}/gallery/category/{0}/{1}',
    privacyPolicy: '{culture}/privacy-policy',
    warrantyTerms: '{culture}/warranty-terms',
    termsOfUse: '{culture}/terms-of-use',
    ourEquipment: '{culture}/our-equipment',
    siteMap: '{culture}/site-map',
    festival: '{culture}/festival/event/{0}',
    festivals: '{culture}/festivals',
    downloadCenter: '{culture}/download-center',
    download: '{culture}/download-center/{0}/{1}',
    downloadDetail: '{culture}/download-center/{0}/detail/{1}',
    specialNeeds: '{culture}/special-needs',
    saveOutputInPdfFormat: '{culture}/save-output-in-pdf-format',
    supporterProfile: '{culture}/profile/{0}',

    view: {
        account: {
            signIn: '{culture}/sign-in',

            forgotPassword: {
                mobile: '{culture}/forgot-password/mobile',
                changePassword: '{culture}/forgot-password/change-password',
                success: '{culture}/forgot-password/success'
            },

            signup: {
                mobile: '{culture}/sign-up/mobile',
                activation: '{culture}/sign-up/activation',
                accountInformation: '{culture}/sign-up/account-information',
                welcome: '{culture}/sign-up/welcome'
            }
        },
        main: {
            fileManager: '{culture}/file-manager',
            allProducts: '{culture}/products',
            productsByCategory: '{culture}/products/category/{0}/{1}',
            allStoreProducts: '{culture}/products/store',
            storeProductsByCategory: '{culture}/products/store/category/{0}/{1}',
            paperBag: '{culture}/paper-bag',
            paperBagFaq: '{culture}/paper-bag/frequently-asked-questions',
            paperBagGallery: '{culture}/paper-bag/gallery',
            paperBagExploreProduct: '{culture}/paper-bag/explore-product',
            paperBagAdvantage: '{culture}/paper-bag/advantage',

            regularOrder: {
                productDetails: '{culture}/products/{0}/regular/detail/{1}',
                orderDetailChoosingMasterFile: '{culture}/products/{0}/regular/order/choosing-master-file',
                orderDetailChoosingFilmFile: '{culture}/products/{0}/regular/order/choosing-film-file-{1}',
                orderDetailExtendedServiceFoldLine: '{culture}/products/{0}/regular/order/extended-service/fold-line',
                orderDetailExtendedServiceFilter: '{culture}/products/{0}/regular/order/extended-service/filters',
                orderDetailExtendedServiceCornerType: '{culture}/products/{0}/regular/order/extended-service/rounded-corner',
                orderDetailExtendedServicePunch: '{culture}/products/{0}/regular/order/extended-service/punch',
                orderDetailExtendedServiceBillBook: '{culture}/products/{0}/regular/order/extended-service/bill-book',
                orderDetailExtendedServiceGripperEdge: '{culture}/products/{0}/regular/order/extended-service/gripper-edge',
                orderDetailExtendedServiceTopFolding: '{culture}/products/{0}/regular/order/extended-service/top-folding',
                orderDetailExtendedServiceHandleType: '{culture}/products/{0}/regular/order/extended-service/handle-type',
                orderDetailConfirm: '{culture}/products/{0}/regular/order/confirm-detail',
                orderSaveResult: '{culture}/products/{0}/regular/order/save-result',
            },

            professionalOrder: {
                productDetails: '{culture}/products/{0}/professional/detail',
                productDetailsWithoutId: '{culture}/products/professional',
                professionalOrder: '{culture}/products/{0}/professional/order'
            },

            productCategories: '{culture}/products/Categories',
            storeDetails: '{culture}/products/{0}/store/detail/{1}',

            profile: {
                profileInformationSetting: '{culture}/user-panel/profile/information-setting',
                profileInformationOfficialInvoiceSetting: '{culture}/user-panel/profile/information-setting/official-invoice',
                profileFeedbackSetting: '{culture}/user-panel/profile/feedback-setting',
                profileNotificationSetting: '{culture}/user-panel/profile/notification-setting',
                profileAgencySetting: '{culture}/user-panel/profile/agency-setting',
                profileAutoShippingSetting: '{culture}/user-panel/profile/auto-shipping-setting',
                profileAddressSetting: '{culture}/user-panel/profile/address-setting',
            },

            userOrder: {
                cartOrder: '{culture}/user-panel/orders/cart',
                cartOrderDetail: '{culture}/user-panel/orders/cart/{0}/detail',
                preparing: '{culture}/user-panel/orders/group-sheet/preparing',
                preparingFailed: '{culture}/user-panel/orders/group-sheet/preparing/failed',
                groupSheetOrderQueueStatus: '{culture}/user-panel/orders/group-sheet/queue-status/group-sheet',
                storeOrderQueueStatus: '{culture}/user-panel/orders/group-sheet/queue-status/store',
                preparingDetail: '{culture}/user-panel/orders/group-sheet/preparing/{0}/detail',
                preparingDetailFeedback: '{culture}/user-panel/orders/group-sheet/preparing/{0}/detail/feedback',
                inProcessOrder: '{culture}/user-panel/orders/group-sheet/in-process',
                inProcessOrderLayouting: '{culture}/user-panel/orders/group-sheet/in-process/layouting',
                inProcessOrderLithography: '{culture}/user-panel/orders/group-sheet/in-process/lithography',
                inProcessOrderPrinting: '{culture}/user-panel/orders/group-sheet/in-process/printing',
                inProcessOrderCutting: '{culture}/user-panel/orders/group-sheet/in-process/cutting',
                inProcessOrderDetail: '{culture}/user-panel/orders/group-sheet/in-process/{0}/detail',
                inProcessOrderDetailFeedback: '{culture}/user-panel/orders/group-sheet/in-process/{0}/detail/feedback',
                readyToDeliverOrderTransferToWarehouse: '{culture}/user-panel/orders/group-sheet/ready-to-deliver/transfer-to-warehouse',
                readyToDeliverOrderInTheWarehouse: '{culture}/user-panel/orders/group-sheet/ready-to-deliver/in-warehouse',
                readyToDeliverOrderInTransit: '{culture}/user-panel/orders/group-sheet/ready-to-deliver/in-transit',
                readyToDeliverOrderDetail: '{culture}/user-panel/orders/group-sheet/ready-to-deliver/{0}/detail',
                readyToDeliverOrderDetailFeedback: '{culture}/user-panel/orders/group-sheet/ready-to-deliver/{0}/detail/feedback',
                archiveOrder: '{culture}/user-panel/orders/group-sheet/archive/delivered',
                archiveOrderCanceled: '{culture}/user-panel/orders/group-sheet/archive/canceled',
                archiveOrderDetail: '{culture}/user-panel/orders/group-sheet/archive/{0}/detail',
                archiveOrderDetailFeedback: '{culture}/user-panel/orders/group-sheet/archive/{0}/detail/feedback',
                allOrders: '{culture}/user-panel/orders/group-sheet/all-order',
                allOrdersDetail: '{culture}/user-panel/orders/group-sheet/all-order/{0}/detail',
                allOrdersDetailFeedback: '{culture}/user-panel/orders/group-sheet/all-order/{0}/detail/feedback',
                userOrderFinishedOrderDetail: '{culture}/user-panel/orders/group-sheet/archive/{0}/detail',
                deliveryRequest: '{culture}/user-panel/orders/my-shippings/in-warehouse',
                deliveryRequestExitingWarehouse: '{culture}/user-panel/orders/my-shippings/exiting-warehouse',
                deliveryRequestSendingToCourier: '{culture}/user-panel/orders/my-shippings/sending-to-courier',
                deliveryRequestDeliveredToCourier: '{culture}/user-panel/orders/my-shippings/delivered-to-courier',
                deliveryRequestDeliveredToCustomer: '{culture}/user-panel/orders/my-shippings/delivered-to-customer',
                deliveryRequestDetail: '{culture}/user-panel/orders/my-shippings/{0}/detail',
                userOrderPaymentReport: '{culture}/user-panel/reports/payment-report',
                userOrderFinancialReport: '{culture}/user-panel/reports/debit-credit-report',
                fileCorrection: '{culture}/user-panel/orders/group-sheet/file-correction',
            },

            userDashboard: {
                cashback: '{culture}/user-panel/dashboard/cashback',
            },

            agency: {
                agencyInformation: '{culture}/user-panel/agency/information',
                agencyCustomers: '{culture}/user-panel/agency/customers',
                agencyCustomersOrders: '{culture}/user-panel/agency/customers-orders',
                agencyCustomersOrderDetail: '{culture}/user-panel/agency/customers-orders/{0}/detail',
                agencyProducts: '{culture}/user-panel/agency/products',
                agencyShipping: '{culture}/user-panel/agency/shipping',
            },

            storeOrder: {
                cartOrder: '{culture}/user-panel/orders/cart/stores',
                inProcessOrder: '{culture}/user-panel/orders/stores/in-process',
                inProcessOrderDetail: '{culture}/user-panel/orders/stores/in-process/{0}/detail',
                // readyToDeliverOrder: '{culture}/user-panel/orders/stores/ready-to-deliver',
                readyToDeliverOrderInTheWarehouse: '{culture}/user-panel/orders/stores/ready-to-deliver/in-warehouse',
                readyToDeliverOrderInTransit: '{culture}/user-panel/orders/stores/ready-to-deliver/in-transit',
                readyToDeliverOrderDetail: '{culture}/user-panel/orders/stores/ready-to-deliver/{0}/detail',
                // archiveOrder: '{culture}/user-panel/orders/stores/archive/delivered',
                archiveOrderDelivered: '{culture}/user-panel/orders/stores/archive/delivered',
                archiveOrderCanceled: '{culture}/user-panel/orders/stores/archive/canceled',
                archiveOrderDetail: '{culture}/user-panel/orders/stores/archive/{0}/detail',
            },

            userPayment: {
                readyToPay: '{culture}/user-payment/{0}/ready-to-pay',
                paymentResult: '{culture}/user-payment/payment-result',
            },

            blog: {
                blogs: '{culture}/blogs',
                blogCategories: '{culture}/blogs/categories',
                blogCategoryPosts: '{culture}/blogs/category/{0}/posts/{1}',
                blogPostDetail: '{culture}/blogs/post/{0}/detail/{1}',
                blogSearchResult: '{culture}/blogs/posts/result',
            },

            news: {
                news: '{culture}/news',
                newsCategories: '{culture}/news/categories',
                newsCategoryPosts: '{culture}/news/category/{0}/categoryName/{1}/posts',
                newsPostDetail: '{culture}/news/post/{0}/detail',
                newsSearchResult: '{culture}/news/posts/result',
            },


        },
    }
};

export default ViewLinks;