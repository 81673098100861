import React, { Component } from 'react';
import Typography from '@mui/material/Typography';

class NsTypography extends Component {
  render() {
    const { variant, className, children, ...props } = this.props;
    return (
      <Typography variant={variant}
        className={className}
        {...props} >
        {children}
      </Typography>
    );
  }
}
export default NsTypography;