import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import { GetInTransitStoreOrdersService, GetInWarehouseStoreOrdersService } from '../../../../../../services/ProfileSetting/StoreOrderService';
import { getTranslatedRawText, getUrlWithCurrentCulture, isEnCulture } from '../../../../../../utilities/CultureHelper';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import NsStickyButton from '../../../../../../components/buttons/NsStickyButton';
import UpShippingRequest from '../../../userShippings/shippingRequests/UpShippingRequest';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import UserPanelWrapper from '../../../../UserPanelWrapper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import UpBaseStoreOrdersGrid from '../bases/UpBaseStoreOrdersGrid';
import Enumerations from '../../../../../../configs/Enumerations';
import Links from '../../../../../../configs/links/AllLinks';
import NsTab from '../../../../../../components/tabs/NsTab';
import UpBaseStoreOrderSearchSection from '../bases/UpBaseStoreOrderSearchSection';

class UpStoreReadyToDeliverOrdersContent extends React.Component {

    constructor(props) {
        super(props);
        this.tabs = ConstantsWithTranslation.getStoreOrderReadyToDeliverTabs();
        this.selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);
        this.state = {
            selectedTab: this.selectedTab || this.tabs[0],
            selectedTabIndex: this.tabs.indexOf(this.selectedTab) > -1 ? this.tabs.indexOf(this.selectedTab) : 0,
            orderProductType: Enumerations.orderProductType.store,
            searchValues: null,
            callServiceDate: new Date(),
            isLoading: false,
            showAddShipmentModal: false,
            resultMessageClass: (<></>),
        };
    }

    tabChanged = (newValue, selectedTab) => newValue !== this.state.selectedTabIndex &&
        this.setState({ selectedTabIndex: newValue, selectedTab, isLoading: true, callServiceDate: new Date() },
            () => !!this.props.history && this.props.history.push(selectedTab.url));

    getData = (rows, isLoading) => this.setState({ isLoading });

    submitDeliveryClicked = () => this.setState({ showAddShipmentModal: true });

    closeAddShippmentRequestModalClicked = (isOperationSuccessful, resultMessageClass) =>
        this.setState({ showAddShipmentModal: false, callServiceDate: new Date(), resultMessageClass: isOperationSuccessful ? resultMessageClass : '' });

    excelReportButtonClicked = () => { }

    searchFormClicked = (searchValues) => this.setState({ searchValues, isLoading: true, callServiceDate: new Date() });

    render() {
        const { showAddShipmentModal, selectedTab, selectedTabIndex, callServiceDate, orderProductType, searchValues, isLoading, resultMessageClass } = this.state;
        const hasTransferRequest = selectedTab?.key == Enumerations.SharedSheetOrderReadyToDeliverStateType.inTransit;
        const mainButtons = [
            !hasTransferRequest && CommonHelper.createStickyButtonItem("profile.readyToDeliver.addShippingRequest", 'ns-icon-add', this.submitDeliveryClicked, 'primary'),
            { label: "regularOrder.common.exportExcel", icon: 'ns-icon-excel', onClicked: this.excelReportButtonClicked, className: 'secondary-outline', disabled: true },
        ];

        return (
            <div className="w-100">
                <UpBaseStoreOrderSearchSection
                    searchFormClicked={this.searchFormClicked}
                    findOrdersSearchSection
                    autoFocus
                    isLoading={isLoading}>
                </UpBaseStoreOrderSearchSection>

                <NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />

                <NsTab value={selectedTabIndex}
                    tabs={this.tabs}
                    onChange={this.tabChanged} />

                <div className='w-100'>
                    <UpBaseStoreOrdersGrid callServiceDate={callServiceDate}
                        parameters={{ filter: searchValues }}
                        service={hasTransferRequest ? GetInTransitStoreOrdersService : GetInWarehouseStoreOrdersService}
                        gridId={hasTransferRequest ? GridEnumerations.siteGrids.inTransitStoreOrders : GridEnumerations.siteGrids.inWarehouseStoreOrders}
                        hasPagination={true}
                        getData={this.getData}
                        hasCustomColumnOrder={true} />
                </div>

                <div className='d-flex mt-3 ps-1'>
                    <span className='font-size-12 me-2'>
                        {getTranslatedRawText('profile.allOrders.canNotFindYourOrder')}
                    </span>
                    <Link className='d-flex align-items-center cursor-pointer font-size-12'
                        to={getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.inProcessOrder)}>
                        <span className='red-color fw-bold '>{getTranslatedRawText('profile.actionButton.preparing')}</span>
                        <i className={clsx(isEnCulture() ? 'ns-icon-expand-right' : 'ns-icon-expand-left', 'red-color fw-bold')} ></i>
                    </Link>
                </div >

                {showAddShipmentModal &&
                    <UpShippingRequest orderProductType={orderProductType}
                        closeModal={this.closeAddShippmentRequestModalClicked} />
                }

                {resultMessageClass}
            </div>
        );
    }
}

export { UpStoreReadyToDeliverOrdersContent }

const UpStoreReadyToDeliverOrders = (props) => {
    return <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.readyToDeliver')}
        titleIcon='ns-icon-ready-order' >
        <UpStoreReadyToDeliverOrdersContent {...props} />
    </UserPanelWrapper>
}

export default withRouter(UpStoreReadyToDeliverOrders);

