import React from 'react';

import NsModal from '../../../../components/modals/NsModal';
import { GetPunchPointsService } from '../../../../services/ProfileSetting/UserOrderService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import PunchContent from './PunchContent';

class PunchModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resultData: null,
            isLoading: true,
            resultMessageClass: (<></>),
        }
    }

    setRatio = (ratio) => GetPunchPointsService({ id: this.props.id }, this.getPunchPointCallback(ratio));

    getPunchPointCallback = (ratio) => (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({
                resultData: {
                    ...resultData,
                    ratio,
                },
                isLoading: false
            });
        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    render() {

        const { resultData, isLoading } = this.state;
        const { draft, resolution } = this.props;

        return (
            <NsModal showModal={true}
                closeModal={this.props.closeModal}
                dialogClassName="modal-choose-printing-files"
                dialogTitle={getTranslatedRawText('regularOrder.extendedService.punch')}
                maxWidth="sm">

                <PunchContent resultData={resultData}
                    draft={draft}
                    resolution={resolution}
                    isLoading={isLoading}
                    setRatio={this.setRatio} />

                {this.state.resultMessageClass}

            </NsModal >
        );
    }
}

export default PunchModal;