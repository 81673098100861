import Config from "../../../../configs/Config";
import Enumerations from "../../../../configs/Enumerations";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import CurrentUserHelper from "../../../../utilities/CurrentUserHelper";
import OrderHelper from "../../../../utilities/OrderHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ProfessionalOrderTours from "../../../bases/tours/ProfessionalOrderTours";

class ProfessionalOrderHelper {

    static isAllowAddToListButton = (routeId) => {
        let messageList = [];
        const isTwoSide = OrderHelper.getPrintedSide(routeId)?.key === Enumerations.printedSide.twoSide;
        if (isEmpty(OrderHelper.getFrontSideMasterFiles(routeId))) messageList.push(this.createMessage('regularOrder.messages.chooseFrontMasterFile'));
        if (isTwoSide) if (isEmpty(OrderHelper.getBackSideMasterFiles(routeId))) messageList.push(this.createMessage('regularOrder.messages.chooseBackMasterFile'));
        if (OrderHelper.getFiles(routeId)?.length > 1) {
            let film = OrderHelper.getFilmFile(routeId, 1);
            if (!isTwoSide) {
                if (isEmpty(film.frontSideFileInfo))
                    messageList.push(this.createMessage('regularOrder.messages.chooseFrontFilmFile', film.title));
            } else
                if (Config.mandatoryTwoSideOfFilm ? (isEmpty(film.frontSideFileInfo) || isEmpty(film.backSideFileInfo)) : (isEmpty(film.frontSideFileInfo) && isEmpty(film.backSideFileInfo)))
                    messageList.push(this.createMessage('regularOrder.messages.chooseFilmFile', film.title));
        }
        if (OrderHelper.getFiles(routeId)?.length > 2) {
            let film = OrderHelper.getFilmFile(routeId, 2);
            if (!isTwoSide) {
                if (isEmpty(film.frontSideFileInfo))
                    messageList.push(this.createMessage('regularOrder.messages.chooseFrontFilmFile', film.title));
            } else
                if (Config.mandatoryTwoSideOfFilm ? (isEmpty(film.frontSideFileInfo) || isEmpty(film.backSideFileInfo)) : (isEmpty(film.frontSideFileInfo) && isEmpty(film.backSideFileInfo)))
                    messageList.push(this.createMessage('regularOrder.messages.chooseFilmFile', film.title));
        }
        let messages = OrderHelper.getExtendedServicesValidationMessage(routeId);
        return [...messageList, ...messages];
    }

    static createMessage = (message, value) => <p>{CommonHelper.stringFormat(getTranslatedRawText(message), value)}</p>

    static recognizeCurrentTour = (routeId, isSelectFile, isOrderAdded, callback) => {
        if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poBeforeSelectingFile)) {
            callback(true, ProfessionalOrderTours.getBeforeSelectingFileTours(), Enumerations.tourGuide.poBeforeSelectingFile);
            return;
        }
        if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poSelectingFile)) {
            callback(isSelectFile, ProfessionalOrderTours.getSelectingFileTours(), Enumerations.tourGuide.poSelectingFile);
            return;
        }
        if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poChoosingFileSummary) && !!OrderHelper.getFrontSideMasterFiles(routeId)?.id) {
            callback(true, ProfessionalOrderTours.getChoosingFileSummaryTours(routeId), Enumerations.tourGuide.poChoosingFileSummary);
            return;
        }
        if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poExtendedServiceSummary) && !!OrderHelper.getFrontSideMasterFiles(routeId)?.id
            && OrderHelper.getTotalExtendedServicesPrice(routeId) > 0) {
            callback(true, ProfessionalOrderTours.getExtendedServiceSummaryTours(routeId), Enumerations.tourGuide.poExtendedServiceSummary);
            return;
        }
        if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poAddToCurrentList) && this.isAllowAddToListButton(routeId)?.length === 0) {
            callback(true, ProfessionalOrderTours.getAddToCurrentListTours(), Enumerations.tourGuide.poAddToCurrentList);
            return;
        }
        if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poAfterClickingAddToCurrentList) && CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poAddToCurrentList)) {
            callback(isOrderAdded, ProfessionalOrderTours.getAfterClickingAddToCurrentListTours(), Enumerations.tourGuide.poAfterClickingAddToCurrentList);
            return;
        }
        if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poSubmit) && CurrentUserHelper.isTourSeen(Enumerations.tourGuide.poAddToCurrentList)) {
            callback(true, ProfessionalOrderTours.getSubmitTours(), Enumerations.tourGuide.poSubmit);
            return;
        }
        return (false, [], null);
    }
}

export default ProfessionalOrderHelper;