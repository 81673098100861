import React from 'react';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { messageClass } from '../../utilities/MessageModalHelper';
import NsButton from '../../components/buttons/NsButton';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from '../../configs/Enumerations';
import ImageHelper from '../../utilities/ImageHelper';

class NsEditFileActionRotate extends React.Component {
    constructor(props) {
        super(props);
        this.rotateButtonList = [
            { label: 'fileManager.modals.editFile.rotate90', icon: 'ns-icon-rotate-cw', onClicked: () => this.rotateClicked(Enumerations.fileActions.rotateCwAngle90) },
            { label: 'fileManager.modals.editFile.rotate270', icon: 'ns-icon-rotate-ccw', onClicked: () => this.rotateClicked(Enumerations.fileActions.rotateCcwAngle90) },
            { label: 'fileManager.modals.editFile.rotate180', icon: 'ns-icon-rotate-cw', onClicked: () => this.rotateClicked(Enumerations.fileActions.rotateAngle180) },
        ];
    }

    rotateClicked = (rotateType) => {
        CommonHelper.safeFunctionCall(this.changeIsLoadingState, true);
        switch (rotateType) {
            case Enumerations.fileActions.rotateCwAngle90:
                ImageHelper.rotateCwAngle90(this.props.imgContent, this.rotateCallback(rotateType));
                return;
            case Enumerations.fileActions.rotateCcwAngle90:
                ImageHelper.rotateCcwAngle90(this.props.imgContent, this.rotateCallback(rotateType));
                return;
            case Enumerations.fileActions.rotateAngle180:
                ImageHelper.rotateAngle180(this.props.imgContent, this.rotateCallback(rotateType));
                return;
            default: return;
        }
    }

    rotateCallback = (rotateType) => (newContent) => {
        if (!!newContent)
            this.props.setNewContent(newContent, rotateType === Enumerations.fileActions.rotateAngle180 ? false : true, () => {
                let newAction = {
                    fileChangeType: rotateType,
                    width: 0,
                    height: 0
                }
                CommonHelper.safeFunctionCall(this.props.imgContentActionsChanged, newAction);
                CommonHelper.safeFunctionCall(this.changeIsLoadingState, false);
            });
        else
            this.setState({ resultMessageClass: messageClass(getTranslatedRawText("common.messages.somethingIsWrong")) });
    }

    render() {

        return (
            <div className="d-flex p-3 background-white-color">
                {this.rotateButtonList.map((button, index) =>
                    <NsButton key={index} className="light"
                        wrapperClassName='pe-1'
                        size='small'
                        startIcon={button.icon}
                        label={button.label}
                        onClick={button.onClicked}
                    />
                )}
            </div>
        );
    }
}

export default NsEditFileActionRotate;