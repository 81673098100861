import ConstantsWithTranslation from "../../../../../../configs/ConstantsWithTranslation";
import { getTranslatedRawText } from "../../../../../../utilities/CultureHelper";
import { isBusinessPartner } from "../../../../../../utilities/ClaimHelper";
import BaseAddToCart from "../../../../../products/bases/BaseAddToCart";
import CommonHelper from "../../../../../../utilities/CommonHelper";
import Enumerations from "../../../../../../configs/Enumerations";
import NsButton from "../../../../../../components/buttons/NsButton";

export const createStoreCartColumns = (deleteRowClicked, refreshStoreProductGrid) => [
    {
        field: 'Action',
        headerName: getTranslatedRawText('profile.dataGridColumns.action'),
        width: 110,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasDelete: true,
            deleteClicked: (id) => CommonHelper.safeFunctionCall(deleteRowClicked, id)
        },
    },
    {
        field: 'productName',
        headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
        width: 280,
    },
    {
        field: 'unitValue',
        headerName: getTranslatedRawText('profile.dataGridColumns.quantity'),
        dataType: Enumerations.gridType.number,
        width: 160,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <BaseAddToCart storeProductSupplierPriceId={props.storeProductSupplierPriceId}
                    supplierOrderCart={
                        {
                            key: props.storeProductSupplierId,
                            value: props.totalQuantity
                        }
                    }
                    noShowCart={true}
                    isSmallSize={true}
                    hasDeleteIcon={true}
                    refreshProduct={() => CommonHelper.safeFunctionCall(refreshStoreProductGrid)}
                    isAuthenticated={true} />
            },
        },
    },
    {
        field: 'unitTypeName',
        headerName: getTranslatedRawText('profile.dataGridColumns.unitTypeName'),
        width: 120,
    },
    {
        field: 'unitPrice',
        headerName: getTranslatedRawText('profile.dataGridColumns.unitPrice'),
        dataType: Enumerations.gridType.price,
        width: 120,
    },
    {
        field: 'discountAmount',
        headerName: getTranslatedRawText('profile.dataGridColumns.discount'),
        dataType: Enumerations.gridType.price,
        width: 120,
    },
    {
        field: 'totalPrice',
        headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat'),
        width: 150,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'totalPriceIncludeVat',
        headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithVat'),
        width: 150,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'details',
        headerName: getTranslatedRawText('profile.dataGridColumns.detail'),
        width: 180,
    },
    {
        field: 'storeProductSupplierName',
        headerName: getTranslatedRawText('profile.dataGridColumns.supplierName'),
        width: 150,
    },
    {
        field: 'createDate',
        headerName: getTranslatedRawText('profile.dataGridColumns.submitTime'),
        dataType: Enumerations.gridType.dateTime,
        width: 130,
    },
    isBusinessPartner() &&
    {
        field: 'businessPartnerCustomerIdentifier',
        headerName: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
        width: 290,
    },
];

export const createStoreConfirmationModalColumns = () => [
    {
        field: 'productName',
        headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
        width: 280,
    },
    {
        field: 'totalQuantity',
        headerName: getTranslatedRawText('profile.dataGridColumns.quantity'),
        dataType: Enumerations.gridType.number,
        width: 100,
    },
    {
        field: 'unitTypeName',
        headerName: getTranslatedRawText('profile.dataGridColumns.unitTypeName'),
        width: 120,
    },
    {
        field: 'unitPrice',
        headerName: getTranslatedRawText('profile.dataGridColumns.unitPrice'),
        dataType: Enumerations.gridType.price,
        width: 120,
    },
    {
        field: 'discountAmount',
        headerName: getTranslatedRawText('profile.dataGridColumns.discount'),
        dataType: Enumerations.gridType.price,
        width: 120,
    },
    {
        field: 'createDate',
        headerName: getTranslatedRawText('profile.dataGridColumns.submitTime'),
        dataType: Enumerations.gridType.dateTime,
        width: 150,
        maxWidth: 200,
    },
    {
        field: 'details',
        headerName: getTranslatedRawText('profile.dataGridColumns.detail'),
        width: 180,
    },
    {
        field: 'storeProductSupplierName',
        headerName: getTranslatedRawText('profile.dataGridColumns.supplierName'),
        width: 120,
    },
];

export const createStoreOrderColumns = (detailClicked, refreshClicked) =>
    [
        {
            field: 'Action',
            headerName: getTranslatedRawText('profile.dataGridColumns.action'),
            width: 120,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {

                customElementRender: (props) => {
                    return refreshClicked && <NsButton className='secondary-text p-1 '
                        startIcon='ns-icon-retry cursor-pointer'
                        onClick={() => refreshClicked(props)} />
                },

                hasDetail: true,
                detailClicked: (id) => CommonHelper.safeFunctionCall(detailClicked, id)
            },
        },
        {
            field: 'nasInvoiceId',
            headerName: getTranslatedRawText('profile.dataGridColumns.invoiceNumber'),
            dataType: Enumerations.gridType.number,
            width: 150,
        },
        {
            field: 'totalPrice',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat'),
            dataType: Enumerations.gridType.price,
            width: 150,
        },
        {
            field: 'priceInclusiveOfVat',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithVat'),
            dataType: Enumerations.gridType.price,
            width: 150,
        },
        {
            field: 'discount',
            headerName: getTranslatedRawText('profile.dataGridColumns.discount'),
            dataType: Enumerations.gridType.price,
            width: 120,
        },
        {
            field: 'paymentInAdvance',
            headerName: getTranslatedRawText('profile.dataGridColumns.paymentInAdvance'),
            dataType: Enumerations.gridType.price,
            width: 150,
        },
        {
            field: 'submitTime',
            headerName: getTranslatedRawText('profile.dataGridColumns.submitTime'),
            dataType: Enumerations.gridType.dateTime,
            width: 130,
        },
        isBusinessPartner() &&
        {
            field: 'businessPartnerCustomerIdentifier',
            headerName: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
            width: 160,
        },
    ];

export const createStoreOrderDetaiColumns = (detailClicked) => [
    {
        field: 'Action',
        headerName: getTranslatedRawText('profile.dataGridColumns.action'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasDetail: true,
            detailClicked: (id) => CommonHelper.safeFunctionCall(detailClicked, id)
        },
        width: 100
    },
    {
        field: 'productName',
        headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
        width: 280,
    },
    {
        field: 'unitCount',
        headerName: getTranslatedRawText('profile.dataGridColumns.quantity'),
        dataType: Enumerations.gridType.number,
        width: 120,
    },
    {
        field: 'unitPrice',
        headerName: getTranslatedRawText('profile.dataGridColumns.unitPrice'),
        dataType: Enumerations.gridType.price,
        width: 130,
    },
    {
        field: 'totalPrice',
        headerName: getTranslatedRawText('profile.dataGridColumns.totalPrice'),
        dataType: Enumerations.gridType.price,
        width: 130,
    },
    {
        field: 'details',
        headerName: getTranslatedRawText('profile.dataGridColumns.detail'),
        width: 180,
    },
    isBusinessPartner() &&
    {
        field: 'businessPartnerCustomerIdentifier',
        headerName: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
        width: 160,
    },
];

export const createApStoreOrderColumns = (detailClicked) =>
    [
        {
            field: 'Action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => CommonHelper.safeFunctionCall(detailClicked, id)
            },
        },
        {
            field: 'fullName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 150,
        },
        {
            field: 'invoiceNumber',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber'),
            dataType: Enumerations.gridType.number,
            width: 150,
        },
        {
            field: 'lastStatusType',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.lastStatus'),
            width: 150,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div className={`${CommonHelper.getShareSheetStateColor(props?.lastStatusType)}`}>
                        {ConstantsWithTranslation.getSharedSheetOrderStateType(props?.lastStatusType)}
                    </div>
                },
            }
        },
        {
            field: 'lastStatusTime',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.statusDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'submitTime',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
            dataType: Enumerations.gridType.dateTime,
            width: 130,
        },
        {
            field: 'priceExcludeVat',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceExcVat'),
            width: 140,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'priceIncludeVat',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceIncVat'),
            width: 140,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'discount',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.discount'),
            width: 120,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'paymentInAdvance',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentInAdvance'),
            width: 170,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'businessPartnerCustomerIdentifier',
            headerName: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
            width: 160
        },
    ];