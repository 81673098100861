import React from 'react';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';
import SingletonCallService from '../../../../services/bases/SingletonCallService';
import { GetFileInfoService } from '../../../../services/fileManager/FileManagementService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import FmDetailActionButton from './FmDetailActionButton';
import FmDetailFile from './FmDetailFile';
import FmDetailFolder from './FmDetailFolder';
import NsTour from '../../../../components/tour/NsTour';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import FileManagerTour from '../../../bases/tours/FileManagerTour';
class FmDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItemContent: null,
            isLoading: false,
            isShowDetail: true,
            isImageLoaded: false,
            showTour: false,
            resultMessageClass: (<React.Fragment></React.Fragment>)
        }
        this.singletonGetFileInfo = new SingletonCallService(GetFileInfoService, this.getFileInfoCallback);
        this.tourSteps = FileManagerTour.getActionButtonTour();

    }
    imageLoaded = () => {
        this.setState({ isImageLoaded: true })
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.selectedItemContent !== this.props.selectedItemContent)
            this.getFileInfo(newProps.selectedItemContent)
    }

    getFileInfo = (selectedItemContent) => {
        if (!!selectedItemContent && !selectedItemContent.isFolder)
            this.setState({ isLoading: true },
                () => {

                    let parameters = {
                        id: selectedItemContent.id,
                        name: selectedItemContent.name
                    }
                    this.singletonGetFileInfo.run(parameters);
                });
        else selectedItemContent?.isFolder &&
            this.setState({
                isShowDetail: true,
                selectedItemContent
            })
    }

    getFileInfoCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({
                isShowDetail: true,
                selectedItemContent: resultData,
                showTour: !this.props.isUsedInPo && !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.fileManagerDetail) && !resultData?.isFolder,
                isLoading: false,
            });
        } else
            this.setState({
                isShowDetail: false,
                selectedItemContent: null,
                resultMessageClass: messageClassModal,
                isLoading: false,
            });
    }

    setValidContentDetailPreview = (selectedItemContent) => {

        if (!selectedItemContent.isFolder)
            return (<FmDetailFile refreshFileContentAfterEdit={this.props.refreshFileContentAfterEdit}
                selectedItemContent={this.state.selectedItemContent}
                isShowDetail={this.state.isShowDetail}
                onLoad={this.imageLoaded} />);

        if (selectedItemContent.isFolder)
            return (<FmDetailFolder refreshFileContentAfterEdit={this.props.refreshFileContentAfterEdit}
                selectedItemContent={this.props.selectedItemContent}
                isShowDetail={this.state.isShowDetail} />);
    }

    componentDidMount = () => this.getFileInfo(this.props.selectedItemContent);

    render() {
        const { selectedItemContent } = this.props;
        const { isLoading, showTour } = this.state;

        if (!!selectedItemContent)
            return (
                <>
                    {isLoading
                        ? <div className="w-100 my-4 px-2">
                            <NsSkeleton isVisible={isLoading}
                                variant={
                                    [
                                        Enumerations.skeleton.rect,
                                        Enumerations.skeleton.rect,
                                        Enumerations.skeleton.rect,
                                        Enumerations.skeleton.rect,
                                        Enumerations.skeleton.text,
                                        Enumerations.skeleton.text,
                                        Enumerations.skeleton.text,
                                        Enumerations.skeleton.text,
                                    ]
                                } />
                        </div>
                        :
                        <div className='d-flex flex-column align-items-start w-100 p-1'>
                            <div className='fm-detail__selected-item-preview'>
                                {this.state.selectedItemContent
                                    ? this.setValidContentDetailPreview(selectedItemContent)
                                    : <div>{getTranslatedRawText('singlePage.dataNotFound')}</div>
                                }
                            </div>
                            {this.state.isShowDetail && !this.props.isUsedInPo && <div className='mt-3 align-self-center pe-1'>
                                <FmDetailActionButton selectedItemContent={selectedItemContent}
                                    refreshFileContentAfterEdit={this.props.refreshFileContentAfterEdit} />
                            </div>}

                        </div>}

                    {showTour && this.state.isImageLoaded &&
                        <NsTour steps={this.tourSteps}
                            id={Enumerations.tourGuide.fileManagerDetail} />
                    }
                </>
            );

        else
            return (<NoItemSelected />);

    }
}

function NoItemSelected() {
    if (!CommonHelper.isMobileDevice())
        return (
            <div className='d-flex flex-column align-items-start w-100 px-1'>
                <span className='ns-icon-no-item-selected file-manager__big-icon-size light-text-color mt-5 mb-2 align-self-center' />
                <span className='align-self-center light-text-color'>
                    {getTranslatedRawText('fileManager.mainSectionDetail.noItemSelected')}
                </span>
            </div>
        );
    return '';
}

export default FmDetails