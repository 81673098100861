const ProfileSettingTranslationEn = {
    profile: {
        common: {
            submitDateFrom: 'Submit Date From',
            submitDateTo: 'Submit Date To',
            submitDate: 'Submit Date',
            printInvoice: 'Print Invoice',
            backToMenu: 'Back To Menu',
            moreInformation: 'More Information',
            visibleToUser: 'Visible To User',
        },

        title: {
            debitCreditReport: 'Debit-Credit Report',
            paymentReport: 'Payment Report'
        },

        userInfo: {
            remainingLimit: 'Remaining Limit',
            accountBalance: 'Account Balance',
            creditor: 'Creditor',
            debtor: 'Debtor',
            grantedCredit: 'Granted Credit',
            queue: 'Queue',
            creditQueueDescription: "Orders are added to the queue for invoice number assignment after confirmation. In this state, the total amount of the orders is deducted from the account balance, and upon exiting the queue, this amount is added back to the account.",
            cashBackDescription: "Cashback refers to the return of a portion of the customer's purchase amount to their Remaining Limit.",
            balance: '{0} {1}',
            increaseCredit: 'Increase Credit',
            changePassword: 'Change Password',
            amount: 'Amount ({0})',
            increaseDescription: 'You are increasing your account balance:',
            showFinancialInformation: 'Show Balance & Limit',
            hideFinancialInformation: 'Hide Balance & Limit',
            vatInvoice: 'Vat Invoice',
            profileInformationForVatInvoice: 'Profile Information For Vat Invoice',
            rules: 'Rules',
            saveAndEnableVatInvoice: 'Save And Enable Vat Invoice',
            prepaid: 'Prepaid',
            postpaid: 'Postpaid',
        },

        actionButton: {
            groupSheetOrder: 'Group Sheet Order',
            storeOrder: 'Store Order',
            myOrder: 'My Orders',
            cart: 'Cart',
            inProcess: 'In Process',
            preparing: 'Preparing',
            readyToDeliver: 'Ready To Deliver',
            allOrders: 'All Orders',
            archive: 'Archive',
            deliveryRequest: 'My Shippings',
            fileCorrection: 'File correction by our specialist',
            report: 'Reports',
            profileInfo: 'Profile Information',
            payment: 'Payment',
            financial: 'Financial',
            debitCredit: 'Debit-Credit',
            notificationSetting: 'Notification Setting',
            autoDeliverySetting: 'Auto Shipping Setting',
            address: 'Addresses',
            signOut: 'Sign Out',
            statusHistory: 'Status History',
            karaj: "Karaj",
            tehran: "Tehran",
            myAgency: 'My Agency',
            agencySetting: 'Agency Setting',
            agencyInformation: "Information",
            agencyCustomers: "Customers",
            agencyCustomersOrder: "Orders",
            agencyProducts: "Products Prices",
            agencyShipping: "Shipping",
            dashboard: 'Dashboard',
            cashback: 'Cashback',
            instantDiscounts: 'Instant Discounts'
        },

        orderCart: {
            groupSheetOrders: 'Group Sheet Orders',
            storeOrders: 'Store Orders',
            cart: 'Cart',
            backToCart: 'Back to cart',
            onlinePayment: 'Online Payment',
            useMyRemainingLimit: 'Use My Remaining Limit',
            checkoutAndPay: 'Checkout and Pay',
            vatRules: 'Vat Rules',
            modal: {
                confirmation: 'Confirmation',
                TotalPriceWithVat: 'Total Price INC. VAT',
                TotalPriceWithoutVat: 'Total Price Exclusive of VAT',
                withVat: ' (INC. VAT)',
                totalDiscountPrice: 'Total Discount Price',
                vat: 'VAT',
                alertTitle: 'Confirmation',
                alertDescription: 'You can choose paying by your credit or paying online.',
                vatCertificate: 'Vat Certificate',
                successfullyOrder: 'Successfully Order'
            },
            tours: {
                cart: 'Once orders have been added to this list and confirmed, they will be put into the preparation process and they are finalized by selecting and confirming.'
            }
        },

        nationalCode: {
            emirates: 'Emirates Id',
            qatar: 'Personal No / Id No',
            oman: 'Civil Id',
            azerbaijan: 'Personal No',
            iran: 'National Code',
            kuwait: 'Civil No',
            turkish: 'TR Identity No / Kimlik No',
            iraqi: 'Identity No',
            bahrain: 'Personal No'
        },

        orderPreparing: {
            preparing: 'Preparing',
            failed: 'Failed',
            orderWithInvoiceCancelation: 'Cancel Order',
            cancellationCode: 'Cancelation Code',
            enterCancellationCode: 'Enter cancellation code witch is available in your invoice'
        },

        orderInProcess: {
            all: 'All',
            layouting: 'Layouting',
            lithography: 'Lithography',
            printing: 'Printing and Covering',
            cutting: 'Cutting and Packaging',
            inprocessPayments: 'In Process Payments'
        },

        readyToDeliver: {
            submitDeliveryRequest: 'Submit shipment',
            addShippingRequest: 'Add Shipping Request',
            submitDeliverySuccessfully: 'Shipment Submitted Successfully',
            readyToDeliveryOrders: 'Ready to Deliver Orders',
            readyToDeliveryDescription: 'Be careful when submitting your shipping request, the collection will not be responsible for choosing the wrong address, ready-to-deliver invoices, recipient or carrier.',
            inTheWarehouse: 'In The Warehouse',
            inTransit: 'In Transit',
            transferToWarehouse: 'Transfer To Warehouse'
        },

        fileCorrection: {
            downloadCleanFile: 'Download Clean File',
            status: 'Status',
            filePreview: 'File Preview',
            product: 'Product',
            createDate: 'Create Date',
            adminDescription: 'Admin Description',
            fileCorrection: 'File Correction',
            NewFileCorrection: 'New File Correction',
            yourFileIsNotReady: 'Your File is not ready',
            noticeText: 'As you know, sending a correct printing file is necessary to get an ideal printing result. In the process of file correction, our specialists check your files in details and after correcting , send it back to you.',
            secondNoticeText: 'To begin correcting files, first select your product , then upload your file and click on submit.',
            modalNoticeText: 'After correcting, our specialists will upload the file here and you can download it easily.',
        },

        orderArchive: {
            delivered: 'Delivered',
            canceled: 'Canceled',
            orderStatus: 'OrderStatus'
        },

        detail: {
            invoiceDetail: 'Order Detail',
            printedSide: 'Printed Side',
            Turnaround: 'Turnaround',
            orderDetail: 'Order Detail',
            deliveryRequestDetail: 'Shipment Detail',
            filesPreview: 'Files Preview',
            extendedServices: 'Extended Services',
            totalServicePrice: 'Total Service Price',
            totalAmount: 'Total Amount',
            exclusiveOfVAT: 'Exclusive of VAT',
            showFrontFilter: 'Show Front Filters',
            showBackFilter: 'Show Back Filters',
            showPunchPoint: 'Show Punch Point',
            showFoldLines: 'Show Fold Lines',
            originalFileDimension: 'Original File Dimension',
            originalFrontFileDimension: 'Original Front Side File Dimension',
            originalBackFileDimension: 'Original Back Side File Dimension',
            requestDetail: 'Request Detail',
            invoiceNumberIsMissing: 'Invoice number is missing',
            fileDeleted: "The pictures of your order no longer exist because it's been for a long time.",
            direction: 'Direction',
        },

        allOrders: {
            advanceMode: 'Advanced Mode',
            invoiceNumber: 'Invoice No',
            productCategory: 'Product Category',
            product: 'Product',
            turnaround: 'Turnaround',
            circulation: 'Circulation',
            printedSide: 'Printed Side',
            submitTimeFrom: 'Submit Time From',
            submitTimeTo: 'Submit Time To',
            confirmTimeFrom: 'Confirm Time From',
            confirmTimeTo: 'Confirm Time To',
            deliveredTimeFrom: 'Delivered Time From',
            deliveredTimeTo: 'Delivered Time To',
            minPrice: 'Min Price',
            maxPrice: 'Max Price',
            status: 'Status',
            enterInvoiceNumberCompletely: 'Enter Invoice Number Completely',
            canNotFindYourOrder: "Can't find your order?"
        },

        deliveryRequest: {
            inTheWarehouse: 'In The Warehouse',
            readyOrder: 'Ready Order',
            exitingWareHouse: 'Exiting WareHouse',
            sendingToCourier: 'Sending To Courier',
            deliveredToCourier: 'Delivered To Courier',
            deliveredToCustomer: 'Delivered To Customer',
            selectedCourier: 'Selected Courier',
            requestDetail: 'Request Detail',
            completedOrder: 'completed Order',
            courierAddress: 'Courier Address',
            countyWarehouseAddress: 'County Warehouse Address',
            courierAddress: 'Courier Address',
            senderAddress: "Sender Address",
            countyWarehouseTel: 'County Warehouse Phone number',
            courierTel: 'Courier Phone number',
            senderTel: "Sender Phone number",
            courier: 'Courier',
            sentToWarehouse: 'Sent to Warehouse «Selected shipping method»',
            deliveredToWarehouse: 'Delivered to Warehouse «Selected shipping method»',
        },

        personal: {
            profileInformation: 'Profile Information',
            accountSetting: 'Account Setting',
            accountType: 'Account Type',
            personalSetting: 'Personal Information',
            mobile: 'Mobile',
            firstName: 'First Name',
            lastName: 'Last Name',
            idNumber: 'Id Number',
            birthDate: 'Birth date',
            address: 'Address',
            autoDelivery: 'Auto shipping',
            companyName: 'Company Name',
            license: 'License',
            trn: 'TRN',
            description: 'Description',
            officialInvoiceIssue: 'Official Invoice Issue',
            invalidInput: '{0} is invalid',
            coachMarks: 'Coach Marks',
            coachMarksStatus: 'Coach Marks Status',
            modals: {
                officialInvoiceAlertP1: 'After enabling the VAT invoice (completing the user profile information), it will not be possible to disable it (change status to an VAT invoice with incomplete information). The opportunity to complete profile information and issue a VAT invoice is available until this order reaches the printing state.',
                officialInvoiceAlertP2: 'If the profile information is not fully entered, the VAT invoice for that order will be issued with incomplete details. This may not be approved by the Iranian National Tax Administration. The opportunity to complete profile information and issue a VAT invoice is available until this order reaches the printing state.',
                coachMarksAlertDescription: 'By disabling this section, all Tour Marks will no longer be shown to you.'
            },
            enableOfficialInvoceTitle: 'To Enable Official Invoice with complete information fill:',
        },

        countryName: {
            azerbaijan: 'Azerbaijan',
            uae: 'UAE',
            oman: 'Oman',
            bahrain: 'Bahrain',
            qatar: 'Qatar',
            iran: 'Iran',
            kuwait: 'Kuwait',
            turkey: 'Turkey',
            iraq: 'Iraq',
        },

        notification: {
            selectAll: 'Select All',
            notificationSetting: 'Notification Setting',
            notificationOptions: 'Notification Options',
            selectNotificationType: 'Select Notification Type',
            communicationChannels: 'Communication Channels',
            signInNotify: 'Every time I sign in, inform me.',
            newOrderNotify: 'Inform me about my new orders.',
            newGroupSheetNotify: 'New group sheet invoice',
            readyCustomizedNotify: 'Ready customized invoice',
            deletingInvoiceNotify: 'Deleting invoice',
            newCustomizedNotify: 'New customized invoice',
            groupSheetConfirmationNotify: 'Group sheet invoice confirmation',
            lotteryNotify: 'Lottery',
            readyGroupSheetNotify: 'Ready group sheet invoice',
            customizedConfirmationNotify: 'Customized invoice confirmation',
            monthlyDiscountNotify: 'Monthly discount ',
            telegramId: 'Telegram ID',
            instagramId: 'Instagram ID',
            emailAddress: 'Email Address',
            facebookId: 'Facebook ID',
            notifyByMobile: 'CEO Mobile No',
            notifyByEmail: 'CEO E-mail',
        },

        delivery: {
            defaultSending: 'Default Shipping',
            carrierName: 'Carrier Name',
            yourAddress: 'Your Address',
            address: 'Address',
            addressEnter: 'Enter Address',
            addressErrorMessage: 'select address',
            carrierErrorMessage: 'select courier',
            availableCarrier: 'Available Carrier(s)',
            noCarrier: 'No carrier found for the selected address (city)',
            noStateSelected: 'First select your address',
            addNewAddress: 'Add New Address',
            shippingPlans: 'Shipping Plans',
            readyToDeliverWithTheStatusShowing: 'ready to deliver with the status "In The Warehouse"',
            countyWarehouse: 'County Warehouse',
            shippingMethodTooltip: 'Postpaid (Cash on delivery) shipping method, the shipping price is paid by the recipient at the time of delivery.',
            expressServiceTooltip: 'Order Processing Service cost',
            shippingMethods: 'Shipping Methods',
            availableOrdersTooltip: 'Ready-to-deliver orders with the status showing In The Warehouse (if they have been ready to deliver within the last 10 hours) and "6-hour products" order (at the Cutting and Packaging, Transfer to Warehouse status.)',
            availableOrders: 'Available Orders',
            ordersShippedByAgency: 'Orders Shipped By Agency',
            informationForWhenShippingMethodNotAvailable: 'Based on selected Address ({0}) and Shipping Plans.',
            bulkyOrdersHint: 'This is not recommended for heavy and bulky orders',
            inPersonDelivery: 'In Person Delivery',
            diamondCarrier: 'Diamond Carrier',
            tipax: 'Tipax',
            shippingGridExpressHint: 'Use {0} for the above orders (if they ahve been ready to deliver within the last 10 hours) and 3 in production',
            shippingGridExpressCheckbox: 'Some of the selected orders are not ready and may not be ready at the scheduled time. In case of any issues, our team will contact you.',
            expressShipping: 'Express Shipping',
            cost: 'Cost',
            packaging: 'Packaging',
            extraOption: 'Express Options',
            shipping: 'Shipping',
            toCarrierCost: 'Shipping To Courier',
            toDoorInDoorCost: 'Shipping To Door in Door',
            deliveryTimeTo: '{0} Time',
            exitingWarehouseTimeTo: 'Exiting Warehouse Time to {0}',
            howToGet: 'How to get?',
            shippingToday: 'Shipping Today?',
            shipmentExpressNotAvailableHintOne: 'There must be atleast one {0} order (at the {1}, {2} or {3} status).',
            sixHoursProducts: '6-hours products',
            shipmentExpressNotAvailableHintTwo: "It's not available on Holidays",
            shipmentExpressNotAvailableHintThree: "It's only available between {0} and {1}",
            fourPm: '4 PM',
            sixPm: '6 PM',
            shipmentByAgencyNotSelectedOne: 'The shipment cost will be  reduced.',
            shipmentByAgencyNotSelectedTwo: 'Shipment is processed automatically and requires no action.',
            shipmentByAgencyNotSelectedThree: '{0} to select Agency.',
            clickHere: 'Click here',
            about: 'About «{0}»',
            inPersonMethodInformation: 'Have your Shipping Request Number with you when visiting the Headquarters office in Tehran',
            selectedCarrierMethodInformation: 'Have your Request Number with you when visiting the Headquarters office in Tehran',
            tipaxMethodInformation: 'Be careful when submitting your shipping request, the collection will not be responsible for choosing the wrong address, ready-to-deliver invoices, recipient or carrier.',
            addressAndPhone: 'Address and phone number',
            tipaxAgencies: 'Tipax Agencies',
            checkAndConfirmShipment: 'Check and Confirm Shipment',
            checkAndConfirm: 'Check and Confirm',
            phoneNumber: 'Phone Number',
            shippingPlan: 'Shipping Plan',
            carrier: 'Carrier',
            countyWarehouse: 'County Warehouse',
            shipmentDescription: 'Shipment Description',
            profileInformation: 'profile Information',
            agencyIsSelected: 'Agency is selected',
            centralOffice: 'Central Office',
            shiporderstoaddress: 'Ship orders to below address?',

            shippingRequestNumber: 'Shipping Request Number', //*

            shippingRequestTime: 'Shipping Request Time', //*

            shipmentMethod: 'Shipment Method',

            orderCount: 'Order Count', //*
            notAvailable: 'Not Available',
            shipmentAddress: '{0} Address',
            standard: 'Standard',
            express: 'Express',
            byAgency: 'By Agency',
            shippingMethod: 'Shipping Method',
            dismatchedShippingTimesMessage: 'Attention: Due to the time took to add the shipping request, the Estimated Sending Time to {0} has changed from {1} to {2}.',
            estimatedSendingTimeFrom: 'Estimated Sending Time From',
            estimatedSendingTimeTo: 'Estimated Sending Time To',
            estimatedSendingTimeToShippingMethod: 'Estimated Sending Time To Shipping Method',
        },

        address: {
            addressAddedSuccessfully: 'The address was added successfully',
            addressEditedSuccessfully: 'The address was edited successfully',
            address: 'Address',
            add: 'Add',
            active: "Active",
            selectLocation: 'select your location on map.',
            locationOnMap: "Location on map",
            inactive: "Inactive",
            postalCode: "Postal code",
            modal: {
                addAddress: 'Add Address',
                editAddress: 'Edit Address',
                country: 'Country',
                countryTooltip: 'The Country is based on your registered mobile number',
                leaflet: "Leaflet",
                googleMap: "Google Map",
                pigeon: "Pigeon"
            }
        },

        agency: {
            agencies: 'Agencies',
            agency: 'Agency',
            fax: 'Fax Number',
            mobileNumber: 'Mobile Number',
            phone: 'Phone Number',
            noAgencyToChoose: 'There are no agents to choose.',
            activeAgency: 'Active Agency',
            alertAgencyTitle: 'Active Your Agency',
            alertAgencyDescription: 'Choose the nearest place to deliver your parcels by activating the agency section',
            agentAdvantageTitle: 'Agent Advantages',
            agentAdvantageDescription: 'If you choose an agency, there is no need to register a shipment request, orders will be sent to the agency, and the registration of some products through the agency includes a discount. It should be noted that choosing an agency is mandatory in some cities.',
            orderDeliveryFromAgency: 'Order Delivery from Agency',
            citiesSupported: 'Cities Supported'
        },

        pay: {
            modal: {
                importantNotice: 'Important Notice',
                AfterPayment: 'After payment click on',
                returnToBasket: 'Return to Basket, ',
                otherwiseYourPayment: 'otherwise your payment will not be submitted.',
                iWasNoticed: 'I was noticed. Do not show me this again .'
            }
        },

        orderReport: {
            report: 'Report',
            fromDate: 'From Date',
            toDate: 'To Date',
            search: 'Search',
            voucherNumber: 'Voucher Number',
            date: 'Submit Date',
            details: 'Details',
            debtor: 'Debtor',
            creditor: 'Creditor',
            balance: 'Balance',
            sign: 'Sign',
            systemResult: 'System Result',
            fail: 'Fail',
            success: 'Success',
            paymentType: 'Payment type',
            amount: 'Amount',
            paymentGateway: 'Payment Gateway',
            trackingNo: 'Tracking No',
            referenceNo: 'Reference No'
        },

        cashback: {
            purchaseAmountPart1: 'Your purchase amount from {0} up to now has been {1} and you will receive a cashback of {2}',
            purchaseAmountPart2: ', which will be added to your credit a few days after {3}.',
            cashbackRate: 'Your cashback rate is {0} based on your purchase amount, which has increased by {1} compared to the previous month.',
            untilNextDiscountType1: 'If you buy another {0}, you will be included to the next plan, where your cashback rate will be {1} based on your purchase amount and your total cashback amount will be {2}',
            untilNextDiscountType2: 'If you buy another {0}, you will be included to the first plan, where your cashback rate will be {1} based on your purchase amount and your total cashback amount will be {2}',
            daysLeft: 'This period has been started from {0} and will continue until {1} and only {2} are left.',
            cashbckDeposit: 'An amount of {0} will be deposited into your account 5 days after the end of the period.',
            calculationBasis: 'Calculation Basis',
            groupSheetOrders: '<span>It is applies to confirmed orders in<strong> Group sheet products.</strong></span>',
            returnedOrders: '<span>It is<strong> not included</strong> deleted or returned orders.</span>',
            orderSubmissionTime: 'The order confirmation time must be within the start and end of the period.',
            purchase: 'Purchase',
            thanLastMonth: 'than last month',
            toTheNextPlan: 'To the Next Plan',
            remainingPurchase: 'Remaining Purchase',
            totalCashback: 'Total Cashback Will Be',
            cashback: 'Cashback',
        },

        dataGridColumns: {
            action: 'Action',
            detail: 'Detail',
            delete: 'Delete',
            productName: 'Product Name',
            submitDate: 'Submit Date',
            submitTime: 'Submit Time',
            statusDate: 'Status Date',
            confirmedDate: 'Confirmed Date',
            circulation: 'Circulation',
            totalQuantity: 'Total Quantity',
            price: 'Price',
            priceChange: "Price Change",
            agencyDiscount: "Agency Discount",
            priceExcVat: 'Total Price (EXC. VAT)',
            priceIncVat: 'Total Price (INC. VAT)',
            frontImage: 'Front',
            backImage: 'Back',
            services: 'Services',
            files: 'Files',
            series: 'Series',
            dimension: 'Product Up Dimension',
            fileDimension: 'File Dimension',
            price: 'Price',
            rate: 'Rate',
            servicePrice: 'Total Service Price',
            totalPrice: 'Total Price',
            status: 'Status',
            lastStatus: 'Last Status',
            shipmentNumber: 'Shipment Number',
            deliveryDate: 'Delivery Date',
            invoiceNumber: 'Invoice Number',
            printedSide: 'Printed Side',
            businessPartnerCustomerIdentifier: 'Business Partner Customer Identifier',
            turnaround: 'Turnaround',
            taxable: 'Taxable',
            vat: 'VAT',
            includingVat: 'Inclusive of VAT',

            shippingRequestNumber: 'Shipping Request Number',
            orderDetailSeriesCount: 'Order Detail Series Count',
            requestDate: 'Request Date',
            courierServiceName: 'Courier Service Name',
            courierNumber: 'Courier Number',
            datePayment: 'Date Payment',
            amount: 'Amount',
            paymentGateway: 'Payment Gateway',
            trackingNo: 'Tracking Number',
            referenceNo: 'Payment Gateway Reference Number',
            total: 'Total Rows: {0}',
            page: 'Page',
            of: 'of {0}',
            rowsPerPage: 'Rows per page:',
            loading: 'Loading...',
            page: "Page",
            deliveredDate: 'Delivered Date',
            paymentInAdvance: "Payment In Advance",
            priceInclusiveOfVat: "Price Inclusive Of Vat",
            discount: 'Discount',
            unitPrice: 'Unit Price',
            supplierName: 'Supplier Name',
            unitTypeName: 'Unit',
            unitValue: 'Unit Value',
            agencyName: 'Agency Name',
            quantity: 'Quantity',
        },

        orderStateType: {
            notApplied: 'Not Applied',
            inFirstQueue: 'In First Queue',
            prepare: 'Prepare',
            inProgress: 'In Progress',
            layouting: 'Layouting',
            progressFail: 'Progress Fail',
            prepareFail: 'Prepare Fail',
            lithography: 'Lithography',
            printing: 'Printing',
            cutting: 'Cutting',
            ready: 'Ready',
            delivered: 'Delivered',
            inSecondQueue: 'In Second Queue',
            canceled: 'Canceled',
            preparing: 'Preparing',
            failed: 'Failed',
            inProcess: "In Process",
            cart: "Cart",
            archive: "Archive",
            readyToDeliver: 'Ready To Deliver',
            transferToWarehouse: 'Transfer To Warehouse',
        },

        fileCheckingStatus: {
            none: "None",
            notChecked: "Not Checked",
            pending: "Pending",
            applyChanged: "Apply Changed",
            rejected: "Rejected"
        },
        feedback: {
            feedback: 'Feedback',
            feedbacks: 'Feedbacks',
            about: 'About',
            characterLimit: '{0}/500',
            comment: 'Comment ...',
            feedbackModalOrderMessage: 'Feedback {0} the current ordering process (layouting, printing, and delivery). The option to provide feedback on orders is available to improve service quality and ensure your satisfaction in future orders.',
            feedbackModalOrderBoldPartMessage: 'does not impact',
            announcements: 'Announcements',
            employees: 'Employees',
        },
    },
    payment: {
        ipg: {
            success: 'Success',
            error: 'Error',
            paymentConfirmation: 'Payment Confirmation.',
            noGatewayFound: 'No Gateway Found',
            paymentCheckAmount: 'check the total amount and pay through the following gateways.',
            youAreGoingToPay: 'You are going to pay',
            selectIpg: 'Select Gateway',
            bankPortal: 'Bank Portal',
            backToCart: 'Back To Cart',
            print: 'Print',
            orderAnotherProduct: 'Order Another Product',
            successMessage: 'Your Payment Has Been Successfully Received',
            errorMessage: 'Your Payment Was Not Successful',
            warningMessage: 'Your Payment Is Indeterminate',
            completeProfileAndPay: 'Complete Profile And Pay',
            iNeedOfficialInvoice: 'I Need a full Official Invoice.',
            iNeedOfficialInvoiceDescription: 'Due to incomplete profile information, a VAT invoice for this order will be issued with incomplete details. This may not be approved by the Iranian National Tax Administration. The opportunity to complete profile information and issue a VAT invoice is available until this order reaches the printing state.',
            clickOfficialInvoice: 'To receive an official invoice with complete account information, you need to complete the process.',
            iDontNeedOfficialInvoice: 'I don\'t need a full official invoice.',
            byConfirmingTitle: 'By confirming, I Agree to the following',
            byConfirmingItem1: 'I\'m aware that an incomplete VAT invoice may not be legally valid.',
            byConfirmingItem2: 'I have the opportunity to complete the information before the order goes to print.',
            mandatoryOfficialInvoiceTitle: 'Complete Profile Information is required for the Business Account (legal entities).',
            mandatoryOfficialInvoiceDescription1: 'To continue the process, click on the "Complete Profile and Pay" and complete your profile information.',
            mandatoryOfficialInvoiceDescription2: 'If you do not wish to complete your profile information, go to <a href={0}>Profile Information</a> and change your Account Type from business to personal to allow order confirmation without complete information (official invoice with incomplete information).',
        },
        gateways: {
            asanPardakht: 'Asan Pardakht',
            behPardakht: 'Beh Pardakht',
            iranKish: 'Iran Kish',
            sadad: 'Sadad',
            saman: 'Saman',
            nGeniusPayment: 'NGenius Payment',
            sepehr: 'Sepehr'
        },
        states: {
            success: 'Success',
            failed: 'Failed',
            open: 'Not Specified',
        },
        types: {
            increaseCredit: 'Increase Credit',
            orderPayment: 'Order Payment',
            cash: 'Cash',
        }
    },

    infos: {
        preparingFailedRefresh: 'By clicking Retry, your order will turn to “preparing”',
        reportsUnknownPayments: 'Is a state which the payment response or cancellation is not received from the payment gateway.',
        notificationSetting: 'By selecting “Communication Channels” how to do the “Notification Options “ is submitted in the system.',
    }

};

export default ProfileSettingTranslationEn;