import { openDB } from 'idb';

const dbName = 'mainDb';
const dbVersion = 2;
const mainStore = 'mainStore';

class IndexedDbHelper {

    static dbPromise = openDB(dbName, dbVersion, {
        upgrade(db) {
            db.createObjectStore(mainStore);
        },
    });

    static get = async (key) => (await this.dbPromise).get(mainStore, key);

    static save = async (key, val) => (await this.dbPromise).put(mainStore, val, key);

    static remove = async (key) => (await this.dbPromise).delete(mainStore, key);

    static clearAllData = async () => (await this.dbPromise).clear(mainStore);

    static getKeys = async () => (await this.dbPromise).getAllKeys(mainStore);
}

export default IndexedDbHelper;