import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';

const GetTreeViewFoldersService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.getTreeViewFolders), {}, callback);
}

const GetFileAndFolderService = (parameters, callback) => {
    return GetRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.getFolderContent), parameters, callback);
}

const GetDiskUsageService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.getDiskUsage), {}, callback);
}

export {
    GetTreeViewFoldersService,
    GetFileAndFolderService,
    GetDiskUsageService,
}