import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetApDfbByStatusAndFilterChartService } from '../../../../../services/adminPanel/AdminDashboardService';
import ApBasesDashboardDoughnutCharts from '../../bases/ApBasesDashboardDoughnutCharts';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import ApBasesDashboardChartSkeleton from '../../bases/ApBasesDashboardChartSkeleton';
import ApBasesDashboardHelper from '../../bases/ApBasesDashboardHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsCard from '../../../../../components/cards/NsCard';

const ApBasesDfbPercentageStatusChart = (props) => {
    const feedbackFilterOptions = ConstantsWithTranslation.getFeedbackStatusFilterOptions();
    const { parameters, refreshDate } = props;
    const [selectedFilterOption, setSelectedFilterOption] = useState(feedbackFilterOptions[0]?.id);
    const [data, setData] = useState({});
    const [dataDetails, setDataDetails] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        getFeedbacksByStatus();
    }, [refreshDate, selectedFilterOption]);

    const getFeedbacksByStatus = () => {
        setIsLoading(true);
        let extraParams = {
            hasComment: selectedFilterOption === Enumerations.feedbackStatusFilterOptions.hasComment ? true : null,
            hasDemandedService: selectedFilterOption === Enumerations.feedbackStatusFilterOptions.hasDemandedService ? true : null
        }
        GetApDfbByStatusAndFilterChartService({ ...parameters, ...extraParams }, getApDfbByStatusAndFilterChartCallback)
    };

    const getApDfbByStatusAndFilterChartCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setDoughnutChartFeedbackStatus(resultData));
            !isEmpty(resultData?.labels) && setDataObject(ApBasesDashboardHelper.setDoughnutChartFeedbackStatus(resultData));
        } else
            setMessageClassModal(messageClassModal);
    };

    const options = {
        cutout: 45,
    }

    const setDataObject = (data) => {
        let secondData = data;
        let newData = [];

        secondData.labels.map(label =>
            newData.push({ label: label })
        );
        newData.map((item, index) => {
            item.value = secondData.datasets[0].data[index]?.value;
            item.backgroundColor = secondData.datasets[0].backgroundColor[index];
        })
        setDataDetails(newData);
    }

    return (!isEmpty(data?.labels) &&
        <NsCard className='p-3 h-100'>
            <ApBasesDashboardDoughnutCharts title='adminPanel.dashboard.status'
                height='250'
                type='doughnut'
                tooltipTitle='adminPanel.dashboard.onlinePaymentsIpgChartDes'
                data={data}
                hasSelect={true}
                options={options}
                refreshClicked={getFeedbacksByStatus}
                selectOptions={feedbackFilterOptions}
                selectValueChanged={(value) => setSelectedFilterOption(value)}
                selectedItem={selectedFilterOption}
                selectLabel='adminPanel.feedback.feedback'
                isLoading={isLoading} >

                {isLoading
                    ? <ApBasesDashboardChartSkeleton />
                    : !isEmpty(data) &&
                    dataDetails.map((item, index) =>
                        <Row key={index} className='m-0 mb-2 px-0 align-items-center'>
                            <Col className='px-0 rounded' xs={1} style={{ backgroundColor: item.backgroundColor, width: '1rem', height: '1rem' }}></Col>
                            <Col className='px-0 ps-3' xs={8}>{item.label}</Col>
                            <Col className='px-0 text-end' xs={3}>{!!item.value ? item.value.toFixed(2) : item?.value} %</Col>
                        </Row>
                    )
                }
            </ApBasesDashboardDoughnutCharts>
            {messageClassModal}
        </NsCard >
    );
};

export default React.memo(ApBasesDfbPercentageStatusChart);