import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsAutocomplete from '../dropdowns/NsAutoComplete';
import NsDatePicker from '../dateTimePickers/NsDatePicker';
import NsTextField from '../inputs/NsTextField';
import NsDateTimePicker from '../dateTimePickers/NsDateTimePicker';
import NsNumberInput from '../inputs/NsNumberInput';
import NsMobileInput from '../inputs/NsMobileInput';
import Enumerations from '../../configs/Enumerations';
import NsCheckbox from '../inputs/NsCheckbox';

class NsAdvanceSearch extends React.Component {

    render() {
        return (
            <Row className='align-items-start' >
                {
                    this.props.searchOptions.map((item, index) => {
                        switch (item.type) {
                            case Enumerations.advanceSearchFieldType.textField:
                                return <Col sm={this.props.smCol || 6} md={this.props.mdCol || 3} key={index} className=''>
                                    <NsTextField label={item.label}
                                        value={this.props.searchValues[item.fieldName]}
                                        valueChanged={item.onChange}
                                        margin='normal'
                                        autoFocus={item.autoFocus}
                                        className="mb-2 w-100"
                                        disabled={item.disabled}
                                        endAdornment={item.endAdornment} />
                                </Col>
                            case Enumerations.advanceSearchFieldType.autoComplete:
                                return <Col sm={this.props.smCol || 6} md={this.props.mdCol || 3} key={index} className=''>
                                    <NsAutocomplete label={item.label}
                                        value={this.props.searchValues[item.fieldName]}
                                        items={item.items}
                                        disabled={item.disabled}
                                        onChange={item.onChange}
                                        className="mb-0 w-100" />
                                </Col>
                            case Enumerations.advanceSearchFieldType.datePicker:
                                return <Col sm={this.props.smCol || 6} md={this.props.mdCol || 3} key={index} className='my-1 pt-2'>
                                    <NsDatePicker key={this.props.searchValues[item.fieldName]} label={item.label}
                                        selectedDate={this.props.searchValues[item.fieldName]}
                                        valueChanged={item.onChange}
                                        noValidationDate={true}
                                        className='w-100'
                                        nullAble={true} />
                                </Col>
                            case Enumerations.advanceSearchFieldType.dateTimePicker:
                                return <Col sm={this.props.smCol || 6} md={this.props.mdCol || 3} key={index} className='my-1 pt-2'>
                                    <NsDateTimePicker key={this.props.searchValues[item.fieldName]} valueChanged={item.onChange}
                                        label={item.label}
                                        value={this.props.searchValues[item.fieldName]}
                                        margin='normal'
                                        className="w-100"
                                        availableFuture={item.availableFuture} />
                                </Col>
                            case Enumerations.advanceSearchFieldType.numberInput:
                                return <Col sm={this.props.smCol || 6} md={this.props.mdCol || 3} key={index} className=''>
                                    <NsNumberInput label={item.label}
                                        helperText={this.props.helperTexts?.[item.fieldName]}
                                        valueChanged={item.onChange}
                                        margin='normal'
                                        disabled={item.disabled}
                                        value={this.props.searchValues[item.fieldName]}
                                        className="w-100" />
                                </Col>
                            case Enumerations.advanceSearchFieldType.mobileInput:
                                return <Col sm={this.props.smCol || 6} md={this.props.mdCol || 3} key={index} className='my-1 pt-2'>
                                    <NsMobileInput label={item.label}
                                        mobileChanged={item.onChange}
                                        size="small"
                                        disabled={item.disabled}
                                        usedInAdminPanel={true}
                                        mobile={this.props.searchValues[item?.fieldName]}
                                        className="w-100" />
                                </Col>
                            case Enumerations.advanceSearchFieldType.checkbox:
                                return <Col sm={this.props.smCol || 6} md={this.props.mdCol || 3} key={index} className='mt-4 py-2 border-bottom'>
                                    <NsCheckbox label={item.label}
                                        onChange={item.onChange}
                                        onClick={item.onClick}
                                        indeterminate={item.indeterminate}
                                        checkboxClassName={item.checkboxClassName}
                                        disabled={item.disabled}
                                        value={item.value}
                                        className="w-100" />
                                </Col>
                            default:
                                break;
                        }
                    })
                }
            </Row>
        )
    }
}

export default NsAdvanceSearch;