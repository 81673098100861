const ComponentsTranslationEn = {

    components: {

        mobileInput: {
            enterMobile: 'Enter mobile',
            countryCodeWrong: 'Country Code is Wrong',
            mobileFormatWrong: 'Mobile format is wrong',
            mobileInvalid: 'Mobile is invalid',
        },

        passwordInput: {
            password: 'Password',
            passwordEnter: 'Enter password',
            currentPassword: 'Current password',
            currentPasswordEnter: 'Enter current password',
            newPassword: 'New password',
            newPasswordEnter: 'Enter new password',
            confirmPassword: 'Confirm Password',
            confirmPasswordEnter: 'Enter confirm password',
            passwordNotMatch: 'Password didn\'t match',
            passwordRegex: 'At least 6 character and number',
            persianPasswordRegex: 'Use just English characters and numbers in password',
            needCharacter: 'Password must contain at least one character',
            needNumber: 'Password must contain at least one number',
        },

        captcha: {
            typeLetters: 'Enter the characters in the picture',
            caseSensitive: 'Characters are Case-Sensitive',
            tooltipInfo: "Help Us to Make Sure You're Not a Robot."
        },

        datePicker: {
            dateIsTooFar: 'Date Is Too Far',
            invalidDateMessage: 'Invalid Date Format',
            minDateMessage: 'Date should not be before minimal date',
            today: 'Today',
            now: 'Now',
            days: {
                sat: 'Saturday',
                sun: 'Sunday',
                mon: 'Monday',
                tues: 'Tuesday',
                wed: 'Wednesday',
                thurs: 'Thursday',
                fri: 'Friday',
            }
        },

        verificationInput: {
            verifyMaskLabel: 'Verification Code',
            verifyMaskEnter: 'Enter verification code',
            verifyMaskNotMatch: 'Verification code is not matched',
        },

        emailInput: {
            email: 'Email',
            enterEmail: 'Enter Email',
            emailFormatWrong: 'Email format is wrong',
            emailInvalid: 'Email is invalid',
        },

        country: {
            country: 'Country',
            countryEnter: 'Select country',
        },

        state: {
            state: 'State',
        },

        city: {
            city: 'City',
        },

        genderType: {
            gender: 'Gender Type',
            male: 'Male',
            female: 'Female',
            genderEnter: 'Select gender type',
        },

        paymentStatusType: {
            type: 'Type'
        },

        accountType: {
            accountType: 'Account Type',
            business: 'Business',
            personal: 'Personal',
            accountTypeEnter: 'Select account type',
        },

        orderType: {
            orderTypes: 'Order Type',
            orderApplicationType: 'Order Application Type',
            online: 'Online',
            inPerson: 'In Person',
        },

        orderApplicationType: {
            regular: 'Regular',
            nas: 'NAS',
            professional: 'Professional',
        },

        signInModal: {
            title: 'Sign In',
            close: 'Close',
        },

        searchBox: {
            allCategories: 'All Categories',
            search: 'Search',
        },

        nsChooseFile: {
            frontSide: 'Front Side',
            backSide: 'Back Side',
            clickHere: 'Click Here',
            dragHere: 'Drag Here',
            or: 'Or',
            changeFile: 'Change File',
        },

        nsStepper: {
            next: 'Next',
            previous: 'Previous',
            skip: 'Skip',
        },

        nsDragFile: {
            dragHere: 'Drag Here',
            or: 'Or',
            browse: 'Browse',
            browseYourFile: 'Browse Your File',
            changeYourFile: 'Change Your File',
        },

        nsLinearProgress: {
            progressInfo: '{0}MB from {1}MB',
            percentageInfo: '{2}%',
            finalInfo: '{1}MB',
            prepareForUpload: 'Preparing the file for upload...',
            waiting: 'Uploading done successfully, wait around 2 minutes...',
            preparePage: 'Preparing page...',
            uploadFailedWithWarning: "Upload Failed",
            uploadFailedWithError: "Upload Failed",
            deleteFailed: 'Delete Failed',
            waitToDecision: "Waiting",
            replace: "Replace",
            keepBoth: "Keep both",
            cancel: "Cancel"
        },

        nsDataGrid: {
            address: 'Address',
            delete: 'Delete',
            edit: 'Edit',
            noRowData: 'No Data Is Available',
            loading: 'Loading...',
            resetSetting: 'Reset Setting'
        },

        nsProductsList: {
            noProductMatch: 'No Product Match With Your Search',
        },

        advanceSearch: {
            new: 'New',
            statusDateFrom: 'Status Date From',
            statusDateTo: 'Status Date To',
            user: 'User',
            order: 'Order',
            paymentGateway: 'Payment Gateway',
            trackingNo: 'Tracking Number',
            referenceNo: 'Payment Gateway Reference Number',
            submitTimeFrom: "Submit Time From",
            submitTimeTo: "Submit Time To",
            createTimeFrom: "Create Time From",
            createTimeTo: "Create Time To",
            minAmount: "Min Amount",
            maxAmount: "Max Amount",
            minAmountFile: "Min File Amount",
            maxAmountFile: "Max File Amount",
            paymentStatus: "Payment Status",
            statusTimeFrom: "Status Time From",
            statusTimeTo: "Status Time To",
            paymentType: "Payment Type",
            invoiceNo: "Invoice Number",
            voucherNo: "Voucher Number",
            changeStatusByAdmin: "Change Status By Admin",
            role: 'Role',
            detailLedger: 'Detail Ledger',
            accountStatus: 'Account Status',
            idNumber: 'ID Number',
            registrationTimeFrom: 'Registration Time From',
            registrationTimeTo: 'Registration Time To',
            expirationDate: 'Expiration Date',
            mobileActivation: 'Mobile Activation',
            dateOfBirthFrom: 'Birth Date From',
            dateOfBirthTo: 'Birth Date To',
            name: 'Name',
            lastName: 'Last Name',
            mobile: 'Mobile',
            lastStatus: 'Last Status',
            product: 'Product',
            circulation: 'Circulation',
            printedSide: 'Printed Side',
            turnaround: 'Turnaround',
            minPaymentInAdvance: 'Min Payment In Advance',
            maxPaymentInAdvance: 'Max Payment In Advance',
            minDiscountAmount: 'Min Discount Amount',
            maxDiscountAmount: 'Max Discount Amount',
            companyName: 'Company Name',
            firstName: 'First Name',
            reportDateFrom: 'Report Date From',
            reportDateTo: 'Report Date To',
            shippingRequestNumber: 'Shipping Request Number',
            agreementStatus: 'Agreement Status',
            agree: 'Agree',
            disAgree: 'Disagree',
            agreementsHistory: 'Agreements History',
            matchingStatus: 'Matching Status',
            fileTransactionStatus: 'File Transaction Status',
            websiteTransactionStatus: 'Website Transaction Status',
            datePaidFrom: 'Date Paid From',
            datePaidTo: 'Date Paid To',
            productName: 'Product Name',
            displayAll: 'Display All',
            averageRateFrom: 'Average Rate From',
            averageRateTo: 'Average Rate To',
            demandService: 'Demand Service',
            displaytoAll: 'Display to all',
            feedbackStatus: 'Feedback Status',
            productType: 'Product Type',
            priceExcVat: 'Total Price (EXC. VAT)',
            feedbackGiver: 'Feedback Giver',
            departments: 'Department',
        },

        history: {
            from: 'From',
            to: 'To',
            user: 'User',
            type: 'Type',
        },

        addToHomeScreen: {
            installAppText: "For download {0} application click on install",
            install: 'Install',
            neverMind: 'Never Mind',
            ios: {
                installAppText: "Add the {0} web application to phone's home screen",
                firstStep: '1. In the following bar click on {0}',
                share: "Share",
                secondStep: '2. In the opened menu click {0}',
                a2hc: 'Add to Home Screen',
                thirdStep: 'In the next step, on the bar click {0}',
                add: 'Add',
                understood: 'Understood'
            }
        },

        chart: {
            zoomIn: "Zoom In",
            zoomOut: "Zoom Out",
            resetZoom: "Reset Zoom"
        },

        imagesAlt: {
            NsLoadingGif: "Website is Loading",
            logo: "Logo of {0}",
            enamd: "Logo of e-commerce and internet businesses",
            uploadedImage: "Image of uploaded file",
            scaledImage: "scaled view of image",
            countryFlag: "country flag of {0}",
            selectCountryFlag: "selected country flag",
            UploadProgressThumbnail: "uploading process",
            contentImage: "{0} content image",
            imageThumbnail: "thumbnail of image",
            promotionSetting: "set {0}",
            imageDraft: "image Draft",
            festivalPrize: "image of festival prize",
            orderFrontSide: "front image of order",
            orderBackSide: "back image of order",
            newsThumbnails: "thumbnail of new's image",
            socialMediaLogo: "logo of {0} social media",
            imagePreview: "preview of {0}",
            aboutUs: "About {0}",
            homeBanner: "{0} website main home banner",
            orderProcessSection: "{0} image",
            language: "icon of {0} language",
            festivalIcon: "festival gift icon",
            masterUploadGuidance: "Guide of uploading master file",
            productDesignTemplate: "product design template",
            foldLineIcons: "fold line preset icon",
            submittedOrderImage: "submitted Order Image",
            paymentMethodIcon: "payment method icon",
            returnToBasket: "return to basket",
            ourEquipment: "{0} company equipments",
            festivalBanners: "{0} festival banner",
            festivalResultBanner: "festival result banner",
            festivalWinner: "festival Winner",
            blogImage: "{0} blog image",
            testimonialAvatar: "commenter profile image",
            extendedService: "extended service (Gripper Edge)",
            imageNotFound: "image not found",
            captchaImage: 'Image verification',
            downloadImage: "download section Image",
            galleryImage: "{0} gallery image",
            guidance: '{0} guidance',
            instantDiscount: 'Instant Discount',
        },
        nsInvoiceButton: {
            orderRegistrationReceipt: 'order registration receipt',
            vatInvoice: 'vat Invoice'
        },

        basicInfo: {
            // phoneNumberConfirmed: 'Granted Credit By Company',
            // category: "Category",
            // files: "Files",
            fullName: 'Full Name',
            // status: 'Status',
            // title: 'Title',
            // image: 'Image',
            // creator: 'Creator',
            modifyDate: 'Modify Date',
            // modifier: 'Modifier',
            // question: 'Question',
            // link: 'Link',
            // lastStatus: 'Last Status',
            // mobile: 'Mobile',
            // registrationTime: 'Registration Time',
            // detailLedger: 'Detail Ledger',
            // inactiveUsers: 'Users without orders',
            // country: 'Country',
            // state: 'State',
            // city: 'City',
            // role: 'Roles',
            // content: 'Content',
            // accountType: 'Account Type',
            // accountStatus: 'Account Status',
            // idNumber: 'ID Number',
            // companyName: 'Company Name',
            // mobileActivation: 'Mobile Activation',
            // licenseNumber: 'License Number',
            // TRN: 'TRN',
            // trackNumber: 'Tracking Number',
            // amount: 'Amount',
            createDate: 'Create Date',
            // paymentGateway: 'Payment Gateway ',
            // paymentType: 'Payment Type',
            // TermsOfPayment: "Mode/ Terms of Payment",
            // statusDate: 'Last Status Time',
            // voucherNumber: 'Voucher Number',
            // referenceNumber: 'Payment Gateway Reference Number',
            invoiceNumber: 'Invoice Number',
            // submitDate: 'Submit Time',
            // description: 'Description',
            productName: 'Product Name',
            // totalPrice: 'Total Price',
            // priceExcVat: 'Price(Exc. VAT)',
            // excVat: '(Exc. VAT)',
            // incVat: '(Inc. VAT)',
            // priceIncVat: 'Price(Inc. VAT)',
            // paymentInAdvance: 'Payment In Advance',
            // discount: 'Discount',
            // totalOrdersDiscountAmount: 'Total Orders Discount Amount',
            // quantity: 'Total Quantity',
            // debtor: 'Debtor',
            // creditor: 'Creditor',
            // detailLedger: 'Detail Ledger',
            // circulation: 'Circulation',
            // printedSide: 'Printed Side',
            // turnaround: 'Turnaround',
            // date: 'Date',
            // newUsers: 'New Users',
            // orderUsers: 'Number of Ordered Users',
            // orders: 'Orders',
            // notConfirmed: 'Not Confirmed',
            // prepareFailed: 'Prepare Failed',
            // prepare: 'Prepare',
            // inProcess: "In Process",
            // archived: "Archived",
            // inTransit: 'In Transit',
            // ordersAmount: 'Total Orders Paid Amount',
            // successfullyPaymentAmount: 'Successfully Orders Paid Amount',
            // totalOrdersCount: 'Number of Total Orders',
            // confirmedOrdersCount: 'Number of Confirmed Orders',
            // totalOrdersAmount: 'Total Orders Amount',
            // failedPaymentAmount: 'Failed Payment Amount',
            // successfullyPaymentCount: 'Number of Successful Payments',
            // failedPaymentCount: 'Number of Failed Payments',
            // successfullyPaymentDistinctUserCount: 'Number of Users With Successful Payment',
            // failedPaymentDistinctUserCount: 'Number of Users With Failed Payment',
            // imageUrl: 'Image Url',
            // filePreview: "File preview",
            // usingInAOrder: "Using in a Order",
            // used: 'Used',
            // fileUrl: 'File Url',
            // remotingOrderId: "Remoting order id",
            // user: "User",
            // shippingRequestNumber: 'Shipping Request Number',
            // shippingRequestTime: 'Shipping Request Time',
            // shippingMethod: 'Shipping Method',
            // courierNumber: 'Courier Number',
            // agencyName: 'Agency Name',
            // stateCity: 'State/City',
            // priceChange: 'Price Change',
            // agencyDiscount: 'Agency Discount',
            // code: 'Code',
            // showIn: 'Show In',
            // paymentProblemType: 'Payment Problem Type',
            // cardNumber: 'Card Number',
            // websiteTransactionStatus: 'Website Transaction Status',
            // fileTransactionStatus: 'File Transaction Status',
            // transactionStatus: 'Transaction Status',
            // fileAmount: 'File Amount',
            // websiteAmount: 'Website Amount',
            // paymentAuditMatchingTypeId: 'Payment Audit Matching Status Id',
            // paymentAuditMatchingType: 'Matching Status',
            // filePayment: "File",
            // lastStatusType: 'Last Status Type',
            // lastStatusTime: 'last Status Time',
            // websitePayment: 'Website',
            // invoiceNumberId: 'Invoice Number Id',
            // merchantId: 'Merchant Id',
            // payGateTranId: 'Switch Transaction Num',
            // traceNumber: 'Trace Number',
            // paymentId: 'Payment Id',
            // paymentStateType: 'Final Payment State',
            // refoundStatus: 'Refound Status',
            // settleStatus: 'Settle Status',
            // datePaid: 'Date Paid',
            // terminalId: 'Terminal Id',
            // transactionDate: 'Transaction Date',
            // approvalTime: 'Approval Time',
            // extendedName: 'Extended Name',
            // usersWhoRegisterWithTwoNumber: 'Users Who Register With Two Number',
            // demandService: 'Demand Service',
            displaytoAll: 'Display to all',
            // averageRate: 'Average Rate',
            announcementTitle: 'Announcement Title',
            // avgRate: 'Avg Rate',
            // qty: 'Quantity',
            // purchase: 'Purchase',
            // cashbackPlan: 'Cashback Plan',
            // cashback: 'Cashback',
            // remainingPurchaseToNextPlan: 'Remaining Purchase To Next Plan',
            // nextCashbackPlan: 'Next Cashback Plan',
            // nextCashback: 'Next Cashback',
            // estimateReadyToDeliverTime: 'Estimated Ready To Deliver Time',
            // actualReadyToDeliveryTime: 'Actual Ready To Delivery Time',
            // actualDeliveredTime: 'Actual Delivered Time',
            // productType: 'Product Type',
            // lastSheetStateQueueType: 'Last Sheet State Queue Type',
            // lastChangeStateUser: 'Last Change State User',
            // openTime: 'Open Time',
            // closeTime: 'Close Time',
            // isClosed: 'Closed Status',
            // lockedUsername: 'Locked User',
            // isSheetDeleted: 'Is Sheet Deleted?',
            // collectPaperStatus: 'Collect Paper Status',
            // collectPaperUser: 'Collect Paper User',
            // layoutedCloseStatus: 'Layouted Close Status',
            // layoutedCloseTime: 'Layouted Close Time',
            // layoutedException: 'Layouted Exception',
            // deliveryTimeForecast: 'Delivery Time Forecast',
            // submitTime: 'Submit Time',
            // confirmTime: 'Confirm Time',
            // readyTime: 'Ready In The Next',
            // parents: 'Parents',
            // remainingDaysToEstimateReadyToDeliverTime: 'Remaining Days To Estimate Ready To Deliver Time',
            // days: 'day(s)',
            feedbackSubmitDate: 'Feedback Submit Date',
            lastUserFeedbackStateTypeId: 'Last feedback State',
            employeeFullName: "Employee's Full Name",
            // extraSupport: 'Extra Support',
            // employee: 'Employee',
            // transactionStatusMessage: 'Transaction Status Message',
            // gatewayMessage: 'Gateway Message',
            // estimateReadyToDeliverTimeFrom: 'From Estimated Ready Deliver Time',
            // estimateReadyToDeliverTimeTo: 'To Estimated Ready Deliver Time',
            // onlyDelayedOrder: 'Only Delayed Order',
            // department: 'Department',
            // feedbackGiver: 'Feedback Giver',
            // fileRegulatorServer: 'File Regulator Server',
            // orderStatus: 'OrderStatus',
            // currencyType: 'Currency Type',
            // capturedAmount: 'Captured Amount',
            // capturedDate: 'Captured Date',
            // refundAmount: 'Refund Amount',
            // refundDate: 'Refund Date',
            // systemGeneratedOrder: 'System Generated Order',
            // declineReason: 'Decline Reason',
            // gatewayRisk: 'Gateway Risk',
            // gatewayRiskViolations: 'Gateway Risk Violations',
            // authenticationResponse: 'Authentication Response',
            // threeDsfailureReason: 'Three Ds Failure Reason',
            // outlet: 'Outlet',
            // cardHolderEmail: 'Card Holder Email',
            // outletReferenceId: 'Outlet Reference',
            // cardholderName: 'Card Holder Name',
            // paymentMethod: 'Payment Method',
            // originIp: 'Origin Ip',
            // paymentStatus: 'Payment Status',
            // paymentWay: 'Payment Way'
            isActive: 'Status',
            feedbackType: 'Feedback Type',
            logType: 'Log Type',
            clientIp: 'Client Ip',
            host: 'Host',
            source: 'Source',
            message: 'Message',
            type: 'Type',
            orderApplicationType: 'Order Application Type',


        }
    },
};

export default ComponentsTranslationEn;