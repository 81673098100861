const ProfileSettingTranslationFa = {
    profile: {
        common: {
            submitDateFrom: 'تاریخ ثبت از',
            submitDateTo: 'تاریخ ثبت تا',
            submitDate: 'تاریخ ثبت',
            printInvoice: 'چاپ فاکتور',
            backToMenu: 'بازگشت به منو',
            moreInformation: 'اطلاعات بیشتر',
            visibleToUser: 'نمایش به کاربر',
        },

        title: {
            debitCreditReport: 'گزارش بدهکاری-بستانکاری',
            paymentReport: 'گزارش پرداخت'
        },

        userInfo: {
            remainingLimit: 'اعتبار باقیمانده',
            accountBalance: 'موجودی حساب',
            creditor: 'بستانکار',
            debtor: 'بدهکار',
            grantedCredit: 'اعتبار داده شده',
            queue: 'صف',
            creditQueueDescription: `سفارش‌ها پس از تایید برای دریافت شماره فاکتور به صف افزوده می‌شوند. در این حالت، مبلغ کل سفارش‌ها از موجودی حساب کسر می‌شود و پس از خروج از صف، این مبلغ به حساب افزوده می‌شود.`,
            cashBackDescription: 'برگشت پول (Cashback یا تخفیف ماهیانه) به معنای بازگشت بخشی از مبلغ خرید مشتری به اعتبار باقیمانده او است.',
            balance: '{0} {1}',
            increaseCredit: 'افزایش اعتبار',
            changePassword: 'تغییر گذرواژه',
            amount: 'مبلغ اعتبار ({0}) ',
            increaseDescription: 'شما مبلغ زیر را به اعتبار خود اضافه می‌کنید',
            showFinancialInformation: 'نمایش موجودی',
            hideFinancialInformation: 'مخفی کردن موجودی',
            vatInvoice: 'فاکتور رسمی',
            profileInformationForVatInvoice: 'اطلاعات حساب کاربری برای فاکتور رسمی',
            rules: 'قوانین',
            saveAndEnableVatInvoice: 'ذخیره و فعال سازی فاکتور رسمی',
            prepaid: 'پیش پرداخت کرایه',
            postpaid: 'پس‌کرایه',
        },

        actionButton: {
            groupSheetOrder: 'سفارش‌های عمومی',
            storeOrder: 'سفارش‌های فروشگاهی',
            myOrder: 'سفارش‌های من',
            cart: 'سبد خرید',
            inProcess: 'در پروسه تولید',
            preparing: 'در حال آماده سازی',
            readyToDeliver: 'آماده تحویل',
            allOrders: 'تمام سفارش‌ها',
            archive: 'بایگانی',
            report: 'گزارش‌ها',
            deliveryRequest: 'مدیریت ارسال‌ها',
            fileCorrection: 'تصحیح فایل‌ها توسط متخصصین ما',
            invoice: 'صدور فاکتور',
            profileInfo: 'اطلاعات حساب کاربری',
            payment: 'پرداخت',
            debitCredit: 'بدهکاری-بستانکاری',
            notificationSetting: 'تنظیمات اطلاع رسانی',
            autoDeliverySetting: 'تنظیمات ارسال پیشفرض',
            address: 'نشانی‌ها',
            signOut: 'خروج از حساب کاربری',
            statusHistory: 'تاریخچه وضعیت',
            karaj: "کرج",
            tehran: "تهران",
            myAgency: 'نمایندگی من',
            agencySetting: 'تنظیمات نمایندگی',
            agencyInformation: "اطلاعات",
            agencyCustomers: "مشتریان",
            agencyCustomersOrder: "سفارش‌ها",
            agencyProducts: "قیمت محصول‌ها",
            agencyShipping: "ارسال‌ها",
            dashboard: 'داشبورد',
            cashback: 'برگشت پول (Cashback)',
            instantDiscounts: 'تخفیف های لحظه‌ای'
        },

        orderCart: {
            groupSheetOrders: 'سفارش‌های عمومی',
            storeOrders: 'سفارش‌های فروشگاهی',
            cart: 'سبد خرید',
            backToCart: 'بازگشت به سبد خرید',
            onlinePayment: 'پرداخت آنلاین',
            useMyRemainingLimit: 'استفاده از اعتبار باقیمانده',
            checkoutAndPay: 'بررسی و پرداخت',
            vatRules: 'قوانین مالیات بر ارزش افزوده',
            modal: {
                confirmation: 'تایید نهایی',
                TotalPriceWithVat: 'مبلغ کل با مالیات',
                TotalPriceWithoutVat: 'مبلغ کل بدون مالیات',
                withVat: ' (با احتساب  مالیات بر ارزش افزوده)',
                totalDiscountPrice: 'مجموع مبلغ تخفیف',
                vat: 'ارزش افزوده',
                alertTitle: 'تایید',
                alertDescription: 'شما می‌توانید انتخاب کنید که مبلغ را از طریق اعتبار خود در سایت پرداخت کرده یا آنلاین پرداخت نمایید',
                vatCertificate: 'گواهینامه مالیات',
                successfullyOrder: 'سفارش موفق'
            },
            tours: {
                cart: 'سفارش‌ها بعد از ثبت در این لیست و پس از تایید در فرآیند آماده‌سازی قرار می‌گیرند و با انتخاب و تایید آن، نهایی می‌شوند.'
            }
        },

        nationalCode: {
            emirates: 'کد ملی امارات متحده عربی',
            qatar: 'کد ملی قطر',
            oman: 'کد ملی عمان',
            azerbaijan: 'کد ملی آذربایجان',
            iran: 'کد ملی',
            kuwait: 'کد ملی کویت',
            turkish: 'شناسه ترکیه',
            iraqi: 'شناسه ملی عراق',
            bahrain: 'شناسه ملی بحرین'
        },

        orderPreparing: {
            preparing: 'در حال آماده سازی',
            failed: 'ناموفق',
            orderWithInvoiceCancelation: 'حذف سفارش',
            cancellationCode: "رمز حذف",
            enterCancellationCode: 'رمز حذف سفارش که در فاکتور می‌باشد را وارد کنید'
        },

        orderInProcess: {
            all: 'همه',
            layouting: 'چیدمان',
            lithography: 'لیتوگرافی',
            printing: 'چاپ و روکش',
            cutting: 'برش و بسته‌بندی',
            inprocessPayments: 'در پروسه پرداخت'
        },

        readyToDeliver: {
            submitDeliveryRequest: 'تایید درخواست ارسال',
            addShippingRequest: ' افزودن درخواست ارسال',
            submitDeliverySuccessfully: 'ارسال با موفقیت ثبت شد',
            readyToDeliveryOrders: 'سفارش‌های آماده تحویل',
            readyToDeliveryDescription: 'در ثبت درخواست ارسال خود دقت فرمایید، مجموعه مسئولیت در قبال انتخاب اشتباه نشانی، فاکتورهای آماده تحویل، گیرنده یا باربری نخواهد داشت.',
            inTheWarehouse: 'در انبار',
            inTransit: 'در پروسه ارسال',
            transferToWarehouse: 'انتقال به انبار'
        },

        fileCorrection: {
            downloadCleanFile: 'دانلود فایل ویرایش شده',
            status: 'وضعیت',
            filePreview: 'پیش نمایش فایل',
            product: 'محصول',
            createDate: 'زمان ثبت سفارش',
            adminDescription: 'توضیحات مدیر',
            fileCorrection: 'چک کردن فایل‌ها',
            NewFileCorrection: 'تصحیح فایل جدید',
            yourFileIsNotReady: 'فایل شما آماده نیست',
            noticeText: 'ارسال فایل در این صفحه به معنی، ارسال سفارش نیست. پس از بررسی فایل، متخصصان ما فایل‌های شما را با جزئیات بررسی می‌کنند و پس از تصحیح، آن را برای شما ارسال می‌کنند.',
            secondNoticeText: 'برای شروع تصحیح فایل‌ها، ابتدا محصول خود را انتخاب کنید، سپس فایل خود را آپلود کرده و بر روی ارسال کلیک کنید.',
            modalNoticeText: 'پس از اصلاح، متخصصین ما فایل را در اینجا آپلود می‌کنند و شما می‌توانید آن را به راحتی دانلود کنید.',
        },

        orderArchive: {
            delivered: 'تحویل داده شده',
            canceled: 'لغو شده',
            orderStatus: 'وضعیت سفارش'
        },

        detail: {
            invoiceDetail: 'جزئیات سفارش',
            printedSide: 'یکرو یا دورو',
            turnaround: 'سرعت چاپ',
            orderDetail: 'جزییات سفارش',
            deliveryRequestDetail: 'جزئیات درخواست ارسال',
            filesPreview: 'پیش نمایش فایلها',
            extendedServices: 'خدمات',
            totalServicePrice: 'مجموع قیمت خدمات',
            totalAmount: 'جمع کل',
            exclusiveOfVAT: 'بدون احتساب ارزش افزوده',
            showFrontFilter: 'نمایش فیلتر روی سفارش',
            showBackFilter: 'نمایش فیلتر پشت سفارش',
            showPunchPoint: 'نمایش موقعیت پانچ',
            showFoldLines: 'نمایش خط تا',
            originalFileDimension: 'ابعاد اصلی فایل',
            originalFrontFileDimension: 'ابعاد اصلی فایل طرف رو',
            originalBackFileDimension: 'ابعاد اصلی فایل طرف پشت',
            requestDetail: 'جزئیات درخواست',
            invoiceNumberIsMissing: 'شماره فاکتور موجود نیست',
            fileDeleted: 'تصویر سفارش شما دیگر در دسترس نیست زیرا زمان زیادی از سفارش آن گذشته است.',
            direction: 'جهت',
        },

        allOrders: {
            advanceMode: 'حالت پیشرفته',
            productCategory: 'دسته‌بندی محصول‌ها',
            submitTimeFrom: 'زمان ثبت از تاریخ',
            submitTimeTo: 'زمان ثبت تا تاریخ',
            confirmTimeFrom: 'زمان تایید از تاریخ',
            confirmTimeTo: 'زمان تایید تا تاریخ',
            deliveredTimeFrom: 'زمان تحویل از تاریخ',
            deliveredTimeTo: 'زمان تحویل تا تاریخ',
            minPrice: 'حداقل قیمت',
            maxPrice: 'حداکثر قیمت',
            status: 'وضعیت',
            enterInvoiceNumberCompletely: 'شماره فاکتور را به طور کامل وارد کنید',
            canNotFindYourOrder: "سفارش خود را پیدا نمی‌کنید؟"
        },

        deliveryRequest: {
            inTheWarehouse: 'در انبار',
            readyOrder: 'سفارش آماده',
            exitingWareHouse: 'خروج از انبار',
            sendingToCourier: 'ارسال به پیک',
            deliveredToCourier: 'تحویل به پیک',
            deliveredToCustomer: 'تحویل به مشتری',
            selectedCourier: 'پایانه انتخاب شده',
            requestDetail: 'جزئیات درخواست',
            completedOrder: 'آماده ارسال',
            courierAddress: 'آدرس پیک',
            countyWarehouseAddress: 'آدرس انبار شهرستان',
            courierAddress: 'آدرس باربری',
            senderAddress: 'آدرس ارسال کننده',
            countyWarehouseTel: 'شماره تماس انبار شهرستان',
            courierTel: 'شماره تماس باربری',
            senderTel: 'شماره تماس ارسال کننده',
            courier: 'پیک',
            sentToWarehouse: 'ارسال به انبار «روش ارسال انتخابی»',
            deliveredToWarehouse: 'تحویل به انبار «روش ارسال انتخابی»',
        },

        personal: {
            profileInformation: 'اطلاعات حساب کاربری',
            accountSetting: 'تنظیمات حساب کاربری',
            accountType: 'نوع حساب کاربری',
            personalSetting: 'اطلاعات شخصی',
            mobile: 'موبایل',
            firstName: 'نام',
            lastName: 'نام خانوادگی',
            idNumber: 'شماره ملی',
            birthDate: 'تاریخ تولد',
            address: 'نشانی',
            autoDelivery: 'تحویل خودکار',
            companyName: 'نام شرکت',
            license: 'لایسنس',
            trn: 'کد اقتصادی',
            description: 'شرح',
            invalidInput: '{0} معتبر نیست',
            officialInvoiceIssue: 'تنظیمات صدور فاکتور رسمی',
            coachMarks: 'کوچ مارک‌ها',
            coachMarksStatus: 'وضعیت کوچ مارک‌ها',
            defaultUserAddress: 'نشانی پیش فرض',
            modals: {
                officialInvoiceAlertP1: 'پس از فعال‌سازی فاکتور رسمی (تکمیل اطلاعات پروفایل کاربری)، امکان غیرفعال‌سازی آن (تغییر وضعیت به حالت فاکتور رسمی با اطلاعات ناقص) وجود نخواهد داشت. اگر اطلاعات پروفایل به صورت کامل وارد نشود، فاکتور رسمی برای آن سفارش با اطلاعات ناقص صادر خواهد شد. ',
                officialInvoiceAlertP2: 'این موضوع ممکن است مورد تایید سازمان امور مالیاتی کشور نباشد. فرصت برای تکمیل اطلاعات پروفایل و صدور فاکتور رسمی تا قبل از رسیدن هر سفارش به مرحله چاپ فراهم شده است.',
                coachMarksAlertDescription: 'با غیر فعال کردن این بخش، تورها دیگر به شما نمایش داده نمی‌شوند.'
            },
            enableOfficialInvoceTitle: 'فعال سازی فاکتور رسمی با تکمیل اطلاعات:',
        },

        countryName: {
            azerbaijan: 'آذربایجان',
            uae: 'امارات',
            oman: 'عمان',
            bahrain: 'بحرین',
            qatar: 'قطر',
            iran: 'ایران',
            kuwait: 'کویت',
            turkey: 'ترکیه',
            iraq: 'عراق',
        },

        notification: {
            selectAll: 'انتخاب همه',
            notificationSetting: 'تنظیمات اطلاع رسانی',
            notificationOptions: 'گزینه‌های اطلاع رسانی',
            selectNotificationType: 'انتخاب انواع اطلاع رسانی',
            communicationChannels: 'کانالهای ارتباطی',
            signInNotify: ' ورود به حساب کاربری',
            newOrderNotify: 'ثبت سفارش جدید فرم عمومی (آنلاین)',
            newGroupSheetNotify: 'ثبت سفارش جدید فرم عمومی (حضوری)',
            readyCustomizedNotify: 'آماده شدن سفارش‌های فرم اختصاصی',
            deletingInvoiceNotify: 'حذف فاکتور',
            newCustomizedNotify: 'ثبت سفارش جدید فرم اختصاصی (حضوری)',
            groupSheetConfirmationNotify: 'پرداخت فاکتور فرم‌های عمومی',
            lotteryNotify: 'قرعه کشی',
            readyGroupSheetNotify: 'آماده شدن سفارش‌های فرم عمومی',
            customizedConfirmationNotify: 'پرداخت فاکتور فرم‌های اختصاصی',
            monthlyDiscountNotify: 'تخفیف ماهیانه',
            telegramId: 'آیدی تلگرام',
            instagramId: 'آیدی اینستاگرام',
            emailAddress: 'نشانی ایمیل',
            facebookId: 'آیدی فیسبوک',
            notifyByMobile: 'توسط پیامک مرا مطلع کن',
            notifyByEmail: 'توسط ایمیل مرا مطلع کن',
        },

        delivery: {
            defaultSending: 'ارسال پیشفرض به',
            carrierName: 'نوع حمل',
            yourAddress: 'نشانی‌های فعال',
            address: 'نشانی',
            addressEnter: 'نشانی را وارد کنید',
            addressErrorMessage: 'نشانی را انتخاب کنید',
            carrierErrorMessage: 'نحوه ارسال سفارش را انتخاب کنید',
            availableCarrier: 'نحوه ارسال',
            noCarrier: 'امکان ارسال برای شهر انتخابی وجود ندارد',
            noStateSelected: 'ابتدا نشانی خود را انتخاب کنید',
            addNewAddress: 'افزودن نشانی',
            shippingPlans: 'پلن‌های ارسال',
            readyToDeliverWithTheStatusShowing: 'آماده تحویل با وضعیت "در انبار"',
            countyWarehouse: 'انبار شهرستان',
            shippingMethodTooltip: 'روش ارسال پس کرایه (پرداخت بعد از تحویل): هزینه ارسال توسط گیرنده در زمان تحویل پرداخت می شود',
            expressServiceTooltip: 'هزینه خدمات پردازش سفارش',
            shippingMethods: 'روش‌های ارسال',
            availableOrdersTooltip: 'سفارش‌های آماده تحویل با وضعیت در انبار (اگر طی 10 ساعت اخیر آماده تحویل شده باشند) و محصول‌های 6 ساعته (با وضعیت‌های برش و بسته‌بندی و انتقال به انبار)',
            availableOrders: '‌سفارش‌های قابل ارسال',
            ordersShippedByAgency: 'سفارش‌های ارسالی از طریق نمایندگی',
            informationForWhenShippingMethodNotAvailable: 'بر اساس آدرس ({0}) و پلن ارسال انتخابی.',
            bulkyOrdersHint: 'این روش برای سفارش های سنگین و حجیم توصیه نمی شود.',
            inPersonDelivery: 'تحویل حضوری',
            diamondCarrier: 'Diamond Carrier',
            tipax: 'تیپاکس',
            shippingGridExpressHint: 'برای سفارش های بالا (اگر طی 10 ساعت اخیر آماده تحویل شده باشند) و 3 سفارش در پروسه تولید {0} را انتخاب کنید',
            shippingGridExpressCheckbox: 'برخی از سفارشات انتخاب شده هنوز آماده نیستند و ممکن است در زمان تعیین شده آماده نشوند. در صورت بروز هر گونه مشکل، تیم ما با شما تماس خواهد گرفت.',
            expressShipping: 'ارسال اکسپرس',
            cost: 'هزینه‌ها',
            packaging: 'بسته بندی',
            extraOption: 'خدمات اکسپرس',
            shipping: 'ارسال',
            prepaid: 'پیش‌کرایه',
            postpaid: 'پس‌کرایه',
            deliveryTimeTo: 'زمان {0}',
            exitingWarehouseTimeTo: 'زمان خروج از انبار به {0}',
            howToGet: 'نحوه دریافت؟',
            shippingToday: 'ارسال امروز',
            shipmentExpressNotAvailableHintOne: 'حداقل یک سفارش از {0} الزامی است (با وضعیت {0}،{1} یا {2})',
            sixHoursProducts: 'محصول های 6 ساعته',
            shipmentExpressNotAvailableHintTwo: 'در روز های تعطیل غیر فعال است.',
            shipmentExpressNotAvailableHintThree: "فقط بین ساعت {0} تا {1} بعد از‌ظهر فعال است.",
            fourPm: '4',
            sixPm: '6',
            shipmentByAgencyNotSelectedOne: 'هزینه ارسال به طور قابل توجهی کاهش خواهد یافت.',
            shipmentByAgencyNotSelectedTwo: 'فرآیند ارسال به طور خودکار انجام می‌شود و نیاز به هیچ اقدامی ندارد.',
            shipmentByAgencyNotSelectedThree: 'برای انتخاب نمایندگی {0} کنید.',
            clickHere: 'اینجا کلیک',
            about: 'درباره «{0}»',
            inPersonMethodInformation: 'هنگام مراجعه به انبار شهرستان «شماره درخواست ارسال» را همراه داشته باشید.',
            selectedCarrierMethodInformation: ' ',
            tipaxMethodInformation: ' ',
            addressAndPhone: 'آدرس و شماره تلفن',
            tipaxAgencies: 'لیست نمایندگی های تیپاکس',
            checkAndConfirmShipment: 'تایید نهایی ارسال',
            checkAndConfirm: 'بررسی و تایید',
            phoneNumber: 'شماره تماس',
            shippingPlan: 'پلن ارسال',
            carrier: 'پیک',
            countyWarehouse: 'انبار شهرستان',
            shipmentDescription: 'توضیحات ارسال',
            profileInformation: 'اطلاعات حساب کاربری',
            agencyIsSelected: 'انتخاب نمایندگی',
            centralOffice: 'انبار دفتر مرکزی',
            shiporderstoaddress: 'سفارش ها به آدرس زیر ارسال شود؟',
            shippingRequestNumber: 'شماره درخواست ارسال',
            shippingRequestTime: 'زمان درخواست ارسال',
            shipmentMethod: 'روش ارسال',
            orderCount: 'تعداد سفارش‌',
            notAvailable: 'در دسترس نیست',
            shipmentAddress: 'آدرس {0}',
            standard: 'استاندارد',
            express: 'اکسپرس',
            byAgency: 'از طریق نمایندگی',
            shippingMethod: 'روش ارسال',
            toCarrierCost: 'حمل تا باربری',
            toDoorInDoorCost: 'حمل تا فرستنده',
            dismatchedShippingTimesMessage: 'توجه: به دلیل زمان صرف شده برای افزودن درخواست ارسال، زمان تخمینی ارسال به {0} از {1} به {2} تغییر یافته است.',
            estimatedSendingTimeFrom: 'زمان تقریبی ارسال از',
            estimatedSendingTimeTo: 'زمان تقریبی ارسال تا',
            estimatedSendingTimeToShippingMethod: 'زمان تقریبی ارسال به روش انتخابی',
        },

        address: {
            addressAddedSuccessfully: 'نشانی با موفقیت اضافه شد',
            addressEditedSuccessfully: 'نشانی با موفقیت ویرایش شد',
            address: 'نشانی',
            add: 'افزودن',
            active: "فعال",
            selectLocation: 'نشانی خود را روی نقشه انتخاب کنید.',
            locationOnMap: 'نشانی روی نقشه',
            inactive: "غیر فعال",
            postalCode: "کد پستی",
            modal: {
                addAddress: 'افزودن نشانی جدید',
                editAddress: 'ویرایش نشانی',
                country: 'کشور',
                countryTooltip: 'کشور بر اساس شماره موبایل شما انتخاب شده است',
                leaflet: "Leaflet",
                googleMap: "Google Map",
                pigeon: "Pigeon"
            }
        },

        agency: {
            agencies: 'نمایندگی‌ها',
            agency: 'نمایندگی',
            fax: 'شماره فکس',
            mobileNumber: 'شماره موبایل',
            phone: 'شماره تلفن',
            noAgencyToChoose: 'نمایندگی برای انتخاب وجود ندارد.',
            activeAgency: 'برو به صفحه فعال کردن',
            alertAgencyTitle: 'فعال کردن نمایندگی',
            alertAgencyDescription: 'با فعال کردن نمایندگی، سفارش‌های خود را از نزدیکترین مکان تحویل بگیرید.',
            agentAdvantageTitle: 'مزایای نمایندگی',
            agentAdvantageDescription: 'در صورت انتخاب نمایندگی دیگر نیاز به ثبت درخواست ارسال نمی باشد، سفارش‌ها برای نمایندگی ارسال می‌گردد همچنین ثبت برخی از محصول‌ها از طریق نمایندگی شامل تخفیف می‌شود. لازم به ذکر است که انتخاب نمایندگی در بعضی شهرها اجباری می‌باشد.',
            orderDeliveryFromAgency: 'تحویل سفارش‌ها از نمایندگی',
            citiesSupported: 'شهرهای پشتیبانی شده'
        },

        pay: {
            modal: {
                importantNotice: 'پیغام مهم',
                AfterPayment: 'بعد از پرداخت بر روی دکمه ی ',
                returnToBasket: 'بازگشت به سایت',
                otherwiseYourPayment: ' کلیک کنید، در غیر اینصورت پرداخت شما مورد تایید نخواهد بود.',
                iWasNoticed: 'متوجه شدم، دیگر به من نمایش ندهید.'
            }
        },

        orderReport: {
            report: 'گزارش‌ها',
            fromDate: 'از تاریخ',
            toDate: 'تا تاریخ',
            search: 'جستجو',
            voucherNumber: 'شماره سند ',
            date: 'تاریخ',
            details: 'جزئیات',
            debtor: 'بدهکار',
            creditor: 'بستانکار',
            balance: 'مانده',
            sign: 'شاخص',
            systemResult: 'دلیل سیستمی',
            fail: 'تایید نشده',
            success: 'تایید شده',
            paymentType: 'نوع پرداخت',
            amount: 'مقدار',
            paymentGateway: 'درگاه پرداخت',
            trackingNo: 'کد رهگیری',
            referenceNo: 'شماره مرجع سیستم بانکی',
        },

        cashback: {
            purchaseAmountPart1: 'مبلغ خرید شما از {0} تا کنون، {1} بوده و برگشت پول (Cashback یا تخفیف ماهیانه) که به شما تعلق خواهد گرفت {2} است ',
            purchaseAmountPart2: 'که چند روز پس از {3} به اعتبار شما اضافه خواهد شد. ',
            cashbackRate: 'درصد برگشت پول شما نسبت به میزان خرید {0} است که {1} نسبت به ماه قبل افزایش یافته است. ',
            untilNextDiscountType1: 'اگر {0} دیگر خرید کنید، وارد پلن بعدی خواهید شد که درصد برگشت پول نسبت به میزان خرید، {1} خواهد بود و مبلغ کل برگشت پول شما {2} خواهد شد.',
            untilNextDiscountType2: 'اگر {0} دیگر خرید کنید، وارد پلن اول خواهید شد که درصد برگشت پول نسبت به میزان خرید، {1} خواهد بود و مبلغ کل برگشت پول شما {2} خواهد شد.',
            daysLeft: 'این دوره از تاریخ {0} شروع شده و تا پایان روز {1} ادامه دارد و فقط {2} مهلت باقی مانده است.',
            cashbckDeposit: 'مبلغ {0} پنج روز پس از پایان دوره در «اعتبار باقیمانده» شما منظور خواهد شد.',
            calculationBasis: 'مبنای محاسبه',
            groupSheetOrders: '<span>به سفارش‌های تایید شده در <strong>فرم‌های عمومی</strong> تعلق می‌گیرد.</span>',
            returnedOrders: '<span>به سفارش‌های حذفی و مرجوعی‌ تعلق<strong> نمی‌گیرد.</strong></span>',
            orderSubmissionTime: 'تاریخ تایید سفارش باید در محدوده شروع تا پایان دوره باشد.',
            purchase: 'خرید',
            thanLastMonth: 'نسبت به ماه قبلی',
            toTheNextPlan: 'تا پلن بعدی',
            remainingPurchase: 'خرید باقیمانده',
            totalCashback: 'کل برگشت پول',
            cashback: 'برگشت پول (Cashback) '
        },

        dataGridColumns: {
            action: 'عملیات',
            detail: 'جزییات',
            delete: 'حذف',
            productName: 'نام محصول',
            submitDate: 'تاریخ ثبت',
            submitTime: 'زمان ثبت',
            statusDate: 'تاریخ وضعیت',
            confirmedDate: 'تاریخ تایید',
            circulation: 'تیراژ',
            totalQuantity: 'تعداد کل',
            price: 'قیمت',
            priceChange: "تغییر قیمت",
            agencyDiscount: "تخفیف نماینده",
            priceExcVat: 'مبلغ (بدون احتساب مالیات)',
            priceIncVat: 'مبلغ (با احتساب مالیات)',
            frontImage: 'تصویر رو',
            backImage: 'تصویر پشت',
            services: 'خدمات',
            files: 'فایل‌ها',
            series: 'سری',
            dimension: 'ابعاد (لت)',
            fileDimension: 'ابعاد فایل',
            price: 'قیمت',
            rate: 'قیمت پایه',
            servicePrice: 'قیمت خدمات',
            totalPrice: 'مبلغ کل',
            status: 'وضعیت',
            lastStatus: 'آخرین وضعیت',
            shipmentNumber: 'شماره درخواست ارسال',
            deliveryDate: 'تاریخ ارسال',
            invoiceNumber: 'شماره فاکتور',
            printedSide: 'یکرو یا دورو',
            businessPartnerCustomerIdentifier: 'شناسه مشتری شریک تجاری',
            turnaround: 'سرعت چاپ',
            taxable: 'مبلغ بدون احتساب مالیات بر ارزش افزوده',
            vat: 'مالیات بر ارزش افزوده',
            includingVat: 'مبلغ با احتساب مالیات ارزش افزوده',

            shippingRequestNumber: 'شماره درخواست ارسال',
            orderDetailSeriesCount: 'مجموع سری در جزییات سفارش',
            requestDate: 'تاریخ درخواست',
            courierServiceName: 'نام پیک',
            courierNumber: 'شماره پیک',
            datePayment: 'تاریخ پرداخت',
            amount: 'مبلغ',
            paymentGateway: 'درگاه پرداخت',
            trackingNo: 'شماره رهگیری',
            referenceNo: 'شماره مرجع سیستم بانکی',
            total: 'تعداد ردیف: {0}',
            of: 'از {0}',
            rowsPerPage: 'ردیف در هر صفحه:',
            loading: 'در حال دریافت اطلاعات...',
            page: "صفحه",
            deliveredDate: "تاریخ تحویل",
            paymentInAdvance: "پیش پرداخت",
            priceInclusiveOfVat: "قیمت با احتساب مالیات برارزش افزوده",
            discount: 'تخفیف',
            unitPrice: 'قیمت واحد',
            supplierName: 'نام تامین کننده',
            unitTypeName: 'واحد',
            unitValue: 'مقدار واحد',
            agencyName: 'نام نمایندگی',
            quantity: 'تعداد',
        },

        orderStateType: {
            notApplied: 'در انتظار تایید',
            inFirstQueue: 'در صف آماده سازی',
            prepare: 'آماده سازی',
            inProgress: 'ارسال به چاپ خانه',
            layouting: 'چیدمان',
            progressFail: 'خطا در ارسال به چاپ خانه',
            prepareFail: 'خطا در آماده سازی',
            lithography: 'لیتوگرافی',
            printing: 'در حال چاپ',
            cutting: 'برش و بسته‌بندی',
            ready: 'آماده تحویل',
            delivered: 'تحویل داده شده',
            inSecondQueue: 'در صف ارسال به چاپ خانه',
            canceled: 'انصراف',
            preparing: 'در حال آماده سازی',
            failed: 'ناموفق',
            inProcess: "در پروسه تولید",
            cart: "سبد خرید",
            archive: "بایگانی",
            readyToDeliver: 'آماده تحویل',
            transferToWarehouse: 'انتقال به انبار',
        },

        fileCheckingStatus: {
            none: "اعمال نشده",
            notChecked: "چک نشده",
            pending: "در حال پردازش",
            applyChanged: "تغییرات اعمال شده",
            rejected: "رد شده"
        },
        feedback: {
            feedback: 'بازخورد',
            feedbacks: 'بازخورد‌ها',
            about: 'درباره',
            characterLimit: '{0}/500',
            comment: 'نظر ...',
            feedbackModalOrderMessage: 'بازخورد، در فرایند سفارش فعلی (فرم‌بندی، چاپ و تحویل) {0}. امکان ارائه بازخورد به سفارش به منظور ارتقای کیفیت خدمات و جلب رضایت شما در سفارش‌های آتی فراهم شده است',
            feedbackModalOrderBoldPartMessage: 'تاثیری ندارد',
            announcements: 'اطلاعیه‌ها',
            user: '',
            employees: 'پرسنل',
        },

    },
    payment: {
        ipg: {
            success: 'موفق',
            error: 'خطا',
            paymentConfirmation: 'تایید پرداخت',
            noGatewayFound: 'درگاه پرداخت فعالی یافت نشد.',
            paymentCheckAmount: 'مبلغ پرداختی را چک کرده و از طریق درگاه‌های موجود پرداخت نمایید.',
            youAreGoingToPay: 'مبلغ پرداختی',
            selectIpg: 'انتخاب درگاه',
            bankPortal: 'پورتال بانک',
            backToCart: 'بازگشت به سبد',
            print: 'چاپ',
            orderAnotherProduct: 'سفارش جدید',
            successMessage: 'پرداخت شما با موفقیت انجام شد',
            errorMessage: 'پرداخت شما با خطا مواجه شد',
            warningMessage: 'پرداخت شما نامشخص است',
            completeProfileAndPay: 'تکمیل حساب کاربری و پرداخت',
            iNeedOfficialInvoice: 'نیاز به فاکتور رسمی با اطلاعات کامل دارم',
            iNeedOfficialInvoiceDescription: 'با توجه به اینکه اطلاعات پروفایل به صورت کامل وارد نشده است، فاکتور رسمی برای این سفارش با اطلاعات ناقص صادر خواهد شد. این موضوع ممکن است مورد تایید سازمان امور مالیاتی کشور نباشد. فرصت برای تکمیل اطلاعات پروفایل و صدور فاکتور رسمی تا قبل از رسیدن این سفارش به مرحله چاپ فراهم شده است',
            clickOfficialInvoice: 'برای دریافت فاکتور رسمی با اطلاعات کامل حساب کاربری باید تکمیل شود',
            iDontNeedOfficialInvoice: 'فاکتور رسمی با اطلاعات کامل نیاز ندارم',
            byConfirmingTitle: 'با تأیید این درخواست، من تأیید می کنم',
            byConfirmingItem1: 'از عدم اعتبار قانونی فاکتور رسمی ناقص آگاه هستم.',
            byConfirmingItem2: 'تا قبل از رسیدن سفارش به مرحله چاپ، فرصت برای تکمیل اطلاعات دارم.',
            mandatoryOfficialInvoiceTitle: 'تکمیل اطلاعات حساب کاربری برای حساب تجاری (حقوقی) الزامی است.',
            mandatoryOfficialInvoiceDescription1: 'برای ادامه فرآیند، روی دکمه «تکمیل حساب کاربری و پرداخت» کلیک کنید و اطلاعات حساب کاربری خود را تکمیل نمایید.',
            mandatoryOfficialInvoiceDescription2: 'در صورت عدم تمایل، به <a href={0}>اطلاعات حساب کاربری</a> مراجعه کنید و نوع حساب خود را از تجاری به شخصی (حقوقی به حقیقی) تغییر دهید تا امکان تایید سفارش بدون تکمیل اطلاعات (فاکتور رسمی با اطلاعات ناقص) فراهم شود.',
        },
        gateways: {
            asanPardakht: 'آسان پرداخت',
            behPardakht: 'به پرداخت',
            iranKish: 'ایران کیش',
            sadad: 'سداد',
            saman: 'سامان',
            nGeniusPayment: 'NGenius',
            sepehr: 'سپهر'
        },
        states: {
            success: 'موفق',
            failed: 'نا موفق',
            open: 'نا مشخص',
        },
        types: {
            increaseCredit: 'افرایش اعتبار',
            orderPayment: 'پرداخت سفارش',
            cash: 'پول نقد',
        }
    },
    infos: {
        preparingFailedRefresh: 'با کلیک روی تلاش مجدد، سفارش به «در حال آماده‌سازی» منتقل می‌شود.',
        reportsUnknownPayments: 'حالتی‌ است که پاسخ پرداخت یا انصراف، از درگاه پرداخت دریافت نمی‌شود.',
        notificationSetting: 'با انتخاب «کانال‌های ارتباطی» نحوه انجام «گزینه‌های اطلاع‌رسانی» در سیستم ثبت می‌شود.',
    }
};

export default ProfileSettingTranslationFa;