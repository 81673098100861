import React from 'react';

import { GetUserOrderHistoriesService } from '../../../services/ProfileSetting/UserOrderService';
import NsHistoryWithServiceCall from '../../../components/histories/NsHistoryWithServiceCall';
import ConstantsWithTranslation from '../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import { GetStoreProductStatusHistoryService } from '../../../services/product/StoreProductService';

class HistoryModal extends React.Component {

    closeModal = () => CommonHelper.safeFunctionCall(this.props.closeModal);

    render() {
        console.log(this.props.isStoreHistory);
        const { orderId } = this.props;

        return (
            <NsModal maxWidth="xs"
                dialogTitle={getTranslatedRawText("profile.actionButton.statusHistory")}
                showModal={true}
                closeModal={this.closeModal}>

                <NsHistoryWithServiceCall service={!!this.props.isStoreHistory ? GetStoreProductStatusHistoryService : GetUserOrderHistoriesService}
                    parameters={{
                        "Filter.OrderId": orderId,
                    }}
                    typeItems={ConstantsWithTranslation.getAllStatusesOrder()}
                    hasPagination={true} />

            </NsModal>
        )
    }
}

export default HistoryModal;