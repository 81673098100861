import clsx from 'clsx';
import React from 'react';
import InputMask from 'react-input-mask';
import { Row, Col } from 'react-bootstrap';
import { TextField, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';

import { getTranslatedRawText } from './../../utilities/CultureHelper';
import { GetCountryService, GetIpLocationService } from '../../services/common/CommonService';
import { getEnumKeyFromValue } from '../../utilities/EnumerationHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';
import { isEmpty, mobileIsValid } from '../../utilities/ValidationHelper';
import '../../assets/sass/components/inputs/TextField.scss';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from '../../configs/Enumerations';
import Config from '../../configs/Config';
import NsCircularProgress from '../circularProgress/NsCircularProgress';
import StorageHelper from '../../utilities/StorageHelper';

class NsMobileInput extends React.Component {

    constructor(props) {
        super(props);
        this.number = '';
        this.state = {
            countries: [],
            country: '',
            countryId: '',
            mobile: '',
            flag: '',
            mask: '',
            regex: '',
            maskChar: '-',
            helperText: '',
            invisibleHelperText: this.messageHelperText(),
            isLoading: false,
            resultMessageClass: (<React.Fragment></React.Fragment>)
        };
    }

    messageHelperText = () => {
        return CommonHelper.stringFormat(getTranslatedRawText("common.messages.enter"),
            getTranslatedRawText("account.common.mobile"));
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (!this.getIsValidMobile(this.state.mobile, this.state.regex))
            if (newProps.showValidationDate !== this.props.showValidationDate) {
                this.setState({ helperText: this.state.invisibleHelperText });
                if (this.state.invisibleHelperText)
                    this.number.focus();
            }
        if (newProps.mobile !== this.props.mobile && !newProps.mobile && this.props.usedInAdminPanel) {
            this.setState({ mobile: '+' + this.state.countries[0]?.countryTelCode },
                () => this.changeCountryValue(this.state.countries[0]?.countryTelCode));
        }

    }

    findCountry = (value) => {
        return this.state.countries.filter(country => country.countryTelCode === value)[0];
    }

    // updateSelectionStart = () => this.number.selectionStart = this.state.mobile.trim().length;

    setStateWhenCountryChanged = (select) => {
        this.setState({
            country: select.countryTelCode,
            countryId: select.id,
            flag: select.flagImage,
            mask: select.mobileNumberMask,
            regex: select.mobileNumberJsRegexPattern,
        },
            () => {
                CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.countryId), this.state.countryId)
                this.props.saveCountryDataInStorage && StorageHelper.saveInStorage(Enumerations.storage.countryData, {
                    trnJsRegexPattern: select.trnJsRegexPattern
                })
            });
    }

    changeCountry = (e) => this.setState({ mobile: e.target.value },
        () => this.changeCountryValue(e.target.value));

    changeCountryValue = (value) => {
        let select = this.findCountry(value);
        if (!select)
            select = this.findCountry(value.substr(0, 2));
        if (!!select) {
            this.setStateWhenCountryChanged(select);
            this.number.focus();
            return select;
        }
        else

            this.setState({ invisibleHelperText: getTranslatedRawText("components.mobileInput.countryCodeWrong") });
    }

    beforeMobileChange = (newState) => {
        let { value, selection } = newState;
        const currentValueLength = value.length;
        value = value.replace(this.state.maskChar, '');

        const newValueLength = value.length;
        const newValueTrimLength = value.trim().length;

        if (newValueTrimLength < selection?.start)
            selection = { start: newValueTrimLength, end: newValueTrimLength };
        value += this.state.maskChar.repeat(currentValueLength - newValueLength);

        return {
            value,
            selection
        };
    }

    changeMobile = (e) => {
        let isValidMobile = false;
        this.setState({ mobile: e.target.value },
            () => {
                let country = CommonHelper.removeStringSpaces(this.state.mobile).substr(1, 3);
                let select = this.changeCountryValue(country);
                if (!!select) {
                    this.setState({
                        invisibleHelperText: '',
                        helperText: ''
                    });
                    isValidMobile = this.getIsValidMobile(this.state.mobile, select.mobileNumberJsRegexPattern)
                }
                CommonHelper.safeFunctionCall(this.props.mobileChanged, this.state.mobile, isValidMobile, this.state.countryId);
            });
    }

    getIsValidMobile = (mobile, regex) => {
        if (!!mobile && mobile.trim().length >= 13) {
            let mobileWithoutCode = mobile.split(' ')[1] + mobile.split(' ')[2];
            return this.checkRegex(mobileWithoutCode, regex);
        } else {
            this.setState({ invisibleHelperText: this.messageHelperText() });
            return false;
        }
    }

    checkRegex = (mobile, regString) => {

        if (mobileIsValid(mobile, regString)) {
            this.setState({ helperText: '', invisibleHelperText: '' });
            return true;
        } else {
            if (!this.state.invisibleHelperText)
                this.setState({ invisibleHelperText: getTranslatedRawText('components.mobileInput.mobileFormatWrong'), helperText: '' });
            return false;
        }
    }

    mapSelectItems = () => {
        return this.state.countries.map(option =>
            <MenuItem
                key={option.countryTelCode}
                value={option.countryTelCode}>
                <img src={option.flagImage} width="20px" alt={CommonHelper.stringFormat(getTranslatedRawText('components.imagesAlt.countryFlag'), option.name)} />
                &nbsp;{CommonHelper.stringFormat('{0} (+{1})', option.name, option.countryTelCode)}
            </MenuItem>
        )
    }

    getCountryCallback = (resultData, result, messageClassModal) => {
        try {
            if (!result.hasError) {
                let defaultCountryTelCode = Config.signup.defaultCountryTelCode;

                let countryIndex = resultData.map(country => country.countryTelCode).indexOf(defaultCountryTelCode);
                if (countryIndex < 0) countryIndex = 0;

                this.setState({
                    isLoading: false,
                    countries: resultData,
                    mobile: !!this.props.mobile ? this.props.mobile : '+' + resultData[countryIndex].countryTelCode
                },
                    () => {
                        if (!!this.props.mobile) {
                            let country = CommonHelper.removeStringSpaces(this.props.mobile).substr(1, 3);
                            let select = this.changeCountryValue(country);
                        } else
                            this.setStateWhenCountryChanged(resultData[countryIndex]);
                        this.number.focus();
                    });

            } else
                this.setState({ resultMessageClass: messageClassModal });
        } catch (ex) {
            GetCountryService(this.getCountryCallback);
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            GetCountryService(this.getCountryCallback);
            //GetIpLocationService(this.getIpLocationServiceCallback);
            this.getIpLocationServiceCallback(null);
        });
        this.number.focus();
    }

    getIpLocationServiceCallback = (data) => {
        const { mobile, countries } = this.state;
        const defaultCountryTelCode = data?.location?.calling_code;
        const maximumLengthOfCountryTelCode = !isEmpty(countries) && countries.reduce((prev, current) => (prev.countryTelCode.length > current.countryTelCode.length) ? prev : current)?.countryTelCode?.length + 1;
        if (!isEmpty(defaultCountryTelCode) &&
            (this.state.country === Config.signup.defaultCountryTelCode) &&
            mobile.length <= maximumLengthOfCountryTelCode) {
            const countryTelCode = this.state.countries[defaultCountryTelCode] ?? Config.signup.defaultCountryTelCode;
            this.setStateWhenCountryChanged(this.state.countries[countryTelCode]);
        }
    }

    render() {
        const { isLoading, flag, country, mask, mobile, helperText, maskChar } = this.state;
        return (
            <FormControl className="ns-numeric w-100 not-rtl">
                <Row className={clsx("ns-numeric row-cols-3 justify-content-between align-items-end")} >
                    <Col xs={2}>
                        {isLoading ?
                            <div className='mt-4'>
                                <NsCircularProgress size={24}
                                    visible={true} />
                            </div>
                            :
                            <Select autoWidth
                                variant='standard'
                                value={!!country ? country : ''}
                                renderValue={(selected) => {
                                    if (selected.length > 0) {
                                        return <img src={flag} width="20px" alt={getTranslatedRawText("components.imagesAlt.selectCountryFlag")} />;
                                    }
                                }}
                                onChange={this.changeCountry}>
                                {this.mapSelectItems()}
                            </Select>
                        }
                    </Col>
                    <Col xs={10}>
                        <InputMask
                            tabIndex="0"
                            mask={mask}
                            value={mobile}
                            onChange={this.changeMobile}
                            disabled={false}
                            beforeMaskedValueChange={this.beforeMobileChange}
                            maskChar={maskChar}>
                            {() => <TextField
                                variant='standard'
                                inputRef={el => this.number = el}
                                onSelect={this.updateSelectionStart}
                                size="small"
                                label={getTranslatedRawText("account.common.mobile")}
                                value={mobile}
                                InputLabelProps={{
                                    shrink: mobile ? true : false,
                                }}
                                inputProps={{ inputMode: 'numeric' }}
                                className="w-100" />
                            }
                        </InputMask>
                    </Col>
                    <Col xs={12}>
                        <FormHelperText>{helperText}</FormHelperText>
                    </Col>
                </Row>
                {this.state.resultMessageClass}
            </FormControl >
        );
    }
}

export default NsMobileInput;