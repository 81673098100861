import React, { Component } from "react";

import SidebarCategories from "./SidebarCategories";
import SidebarSearch from "./SidebarSearch";
import SidebarPrice from "./SidebarPrice";
import SidebarOptions from "./SidebarOptions";
// import SidebarFinishing from "./SidebarFinishing";
import SidebarFilterResults from "./SidebarFilterResults";
import { isEmpty } from "../../../utilities/ValidationHelper";

class SidebarMain extends Component {

    render() {
        const { filters, productsFilterLabel, isStoreProducts } = this.props;
        return (
            <div className='sidebar__rows product-tour-sidebar'>
                <IsShowFilterResult productsFilterLabel={productsFilterLabel}
                    categoryDeleteFilter={this.props.categoryDeleteFilter}
                    searchDeleteFilter={this.props.searchDeleteFilter}
                    priceDeleteFilter={this.props.priceDeleteFilter}
                    optionDeleteFilter={this.props.optionDeleteFilter}
                    removeAllFilters={this.props.removeAllFilters} />
                <div className="mb-2">
                    <SidebarCategories filters={filters}
                        isStoreProducts={isStoreProducts}
                        categoriesFilter={this.props.categoriesFilter} />
                </div>
                <div className="mb-2">
                    <SidebarSearch filters={filters}
                        hasSearchIcon={true}
                        searchFilter={this.props.searchFilter} />
                </div>
                <div className="mb-2">
                    <SidebarPrice filters={filters}
                        isStoreProducts={isStoreProducts}
                        priceFilter={this.props.priceFilter} />
                </div>
                {!isStoreProducts && <div className="mb-2">
                    <SidebarOptions filters={filters}
                        optionFilter={this.props.optionFilter} />
                </div>
                }
                {false && <div className="mb-3">
                    <SidebarFinishing hasGold={this.props.hasGold}
                        hasSilver={this.props.hasSilver}
                        hasUV={this.props.hasUV}
                        hasDie={this.props.hasDie}
                        finishingChanged={this.props.finishingChanged} />
                </div>
                }
            </div>
        );
    }
}

function IsShowFilterResult(props) {
    const { productsFilterLabel } = props;
    const isFiltered = productsFilterLabel.some(item => !isEmpty(item));

    if (isFiltered)
        return (
            <div className="mb-3">
                <SidebarFilterResults productsFilterLabel={productsFilterLabel}
                    categoryDeleteFilter={props.categoryDeleteFilter}
                    searchDeleteFilter={props.searchDeleteFilter}
                    priceDeleteFilter={props.priceDeleteFilter}
                    optionDeleteFilter={props.optionDeleteFilter}
                    removeAllFilters={props.removeAllFilters} />
            </div>
        );
    else
        return null;
}

export default SidebarMain;