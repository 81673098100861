import React from 'react';

import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsCaptcha from '../../../components/recaptcha/NsCaptcha';
import CommonHelper from '../../../utilities/CommonHelper';
import Enumerations from '../../../configs/Enumerations';
import NsModal from '../../../components/modals/NsModal';

class BaseCaptchaModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            { label: "account.forgotPassword.sendVerificationCode", icon: '', onClicked: this.captchaClicked, className: 'primary' },
        ];
        this.state = {
            captchaCode: '',
            captchaId: null,
            isValidCaptchaCode: false,
            showValidationDateCaptcha: null,
        };
    }

    captchaClicked = () => {
        if (!this.state.isValidCaptchaCode) {
            this.setState({ showValidationDateCaptcha: new Date() });
            return;
        }
        this.closeModalClicked(true);
    }

    onKeyUp = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter) {
            event.preventDefault();
            this.captchaClicked();
        }
    }

    recaptchaChanged = (captchaCode, isValidCaptchaCode, captchaId) => this.setState({ captchaCode, isValidCaptchaCode, captchaId });

    closeModalClicked = (confirm) => CommonHelper.safeFunctionCall(this.props.closeModal, this.state.captchaId, this.state.captchaCode, confirm);

    render() {
        const { captchaMessage } = this.props;
        const { showValidationDateCaptcha } = this.state;

        return (
            <NsModal maxWidth="xs"
                dialogTitle='adminPanel.subject.newCategory'
                showModal={true}
                onKeyUp={this.onKeyUp}
                closeModal={this.closeModalClicked}
                actions={<NsStickyButton stickyButtons={this.mainButtons} />}>

                <div className="mb-3">
                    <NsCaptcha recaptchaChanged={this.recaptchaChanged}
                        captchaMessage={captchaMessage}
                        showValidationDateCaptcha={showValidationDateCaptcha} />
                </div>

            </NsModal>
        );
    }
}

export default BaseCaptchaModal;