import React from 'react';

import NsProductsList from '../../../../components/productsList/NsProductsList';

class PdpSidebarProductsList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedCategoryId: this.props.selectedCategory?.id,
        }

    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedCategory !== prevProps.selectedCategory) {
            this.setState({
                selectedCategoryId: this.props.selectedCategory?.id
            });
        }
    }

    render() {
        return (
            <NsProductsList productId={this.props.selectedId}
                selectedCategoryId={this.state.selectedCategoryId}
                selectedIdChanged={this.props.selectedIdChanged}
                productsLoaded={this.props.productsLoaded}
                printCirculations={this.props.printCirculations} />
        );
    }
}

export default PdpSidebarProductsList;