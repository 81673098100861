import React, { useState, useEffect } from "react";

import { GetApDauStatisticsMismatchProgressService } from "../../../../services/adminPanel/AdminDashboardService";
import EnumerationTranslationHelper from "../../../../utilities/EnumerationTranslationHelper";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import NsLinearProgress from "../../../../components/progresses/NsLinearProgress";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsSelect from "../../../../components/dropdowns/NsSelect";
import NsSkeleton from "../../../../components/skeletons/NsSkeleton";
import NsTypography from "../../../../components/text/NsTypography";
import Enumerations from "../../../../configs/Enumerations";
import NsCard from "../../../../components/cards/NsCard";

const ApDauMismatchesProgress = (props) => {

    const { parameters, refreshDate } = props;
    const gateways = ConstantsWithTranslation.getPaymentGateways(true);
    const [selectedGateway, setSelectedGateway] = useState(gateways[0].id);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState();

    useEffect(() => {
        setIsLoading(true);
        let params = {
            ...parameters,
            PaymentGatewayType: selectedGateway != gateways[0].id ? selectedGateway : null
        }
        GetApDauStatisticsMismatchProgressService(params, getApDauStatisticsMismatchProgressServiceCallback);
    }, [refreshDate, selectedGateway])

    const getApDauStatisticsMismatchProgressServiceCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(resultData);
        } else {
            setMessageClassModal(messageClassModal);
        }
    };

    const gatewayChanged = (selected) => setSelectedGateway(selected);

    const mapMismatchTypes = (data) => {
        return (
            <div className="mb-3">
                {isLoading
                    ? <div className="col-8">
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.text,
                            ]} />
                    </div>
                    : <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                            <span className="fw-bold font-size-24">{data?.count}</span>
                            <NsTypography variant='inherit' className='light-text-color font-size-14'>
                                {!!data?.paymentAuditMatchingType && EnumerationTranslationHelper.getPaymentAuditMatchingType(data?.paymentAuditMatchingType)[0]}
                            </NsTypography>
                        </div>
                        <div className="fw-bold font-size-24">
                            {data.percentage}%
                        </div>
                    </div>
                }
                <NsLinearProgress progress={data?.percentage || 0}
                    isLoading={isLoading}
                    className='admin-panel__audit-dashboard--mismatch' />
            </div>
        );
    }
    return (
        <NsCard className='p-3'>
            <div className="d-flex mb-4">
                <span className='page-title'>
                    {getTranslatedRawText('adminPanel.dashboard.mismatch')}
                </span>
                <div className='w-50 ms-auto' >
                    <NsSelect label={getTranslatedRawText('components.advanceSearch.paymentGateway')}
                        valueChanged={gatewayChanged}
                        selectedItem={selectedGateway}
                        options={gateways} />
                </div>
            </div>
            <div className="d-flex flex-column gap-3">
                {mapMismatchTypes(data[0] || {})}
                {mapMismatchTypes(data[1] || {})}
                {mapMismatchTypes(data[2] || {})}
            </div>
            {messageClassModal}
        </NsCard >
    );
};

export default React.memo(ApDauMismatchesProgress);