import React, { useCallback, useEffect, useState } from "react";

import NsTextField from "../../../../../components/inputs/NsTextField";
import CommonHelper from "../../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import ApLanguageTabs from "../../../bases/ApLanguageTabs";
import ContentHelper from "../../bases/ContentHelper";
import { GetAgreementInfoService } from "../../../../../services/adminPanel/AdminAgreementService";

const ApAgreementVersionInfo = ({ showValidationDate, selectedRowId, agreementId, nextButtonTriggerDate, isUsedInDownload, ...props }) => {

    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [multiLanguageFileContent, setMultiLanguageFileContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (agreementId) {
            setIsLoading(true);
            GetAgreementInfoService(agreementId, getAgreementInfoCallback)
        } else setMultiLanguageFileContent(ContentHelper.normalizeContentObject([], { isActive: false }));
    }, []);

    const getAgreementInfoCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        setMultiLanguageFileContent(ContentHelper.normalizeContentObject([resultData], { isActive: false }))
    }

    useEffect(() => {
        if (!isEmpty(nextButtonTriggerDate)) {
            CommonHelper.safeFunctionCall(props.saveButtonClicked, agreementId, multiLanguageFileContent);
        }
    }, [nextButtonTriggerDate])

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguageFileContent([...newData]);
    }

    return (
        <>
            <div className='p-3'>
                <ApLanguageTabs languageChanged={languageChangedHandler}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguageFileContent) &&
                    <TabDataTables stateHandler={stateHandler}
                        // editorHandler={editorHandler}
                        data={[...multiLanguageFileContent]}
                        key={selectedLanguage.id}
                        showValidationDate={showValidationDate}
                        selectedLanguage={selectedLanguage} />
                }
            </div>
        </>
    );
}

function TabDataTables(props) {
    const { stateHandler, showValidationDate, data, selectedLanguage } = props;
    let rowContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);
    return (
        <div className='d-flex flex-column'>
            <div className="d-flex mb-4 align-items-end justify-content-between w-100 flew-row" >
                <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                    required
                    valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                    value={rowContent?.content.title || null}
                    showValidationDate={showValidationDate}
                    className='w-100' />
            </div>
        </div>
    )
}

export default React.memo(ApAgreementVersionInfo);