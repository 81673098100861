import React, { useState } from 'react';

import NsButton from '../../../../components/buttons/NsButton';
import NsCard from '../../../../components/cards/NsCard';
import NsExportExcelReportButton from '../../../../components/downloadFiles/NsExportExcelReportButton';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsTab from '../../../../components/tabs/NsTab';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../configs/Enumerations';
import {
    GetApDuGrantedCreditsExcelReportService, GetApDuGrantedCreditsService, GetApDuRemainingLimitWithCreditExcelReportService,
    GetApDuRemainingLimitWithCreditService, GetApDuRemainingLimitWithoutCreditExcelReportService, GetApDuRemainingLimitWithoutCreditService
} from '../../../../services/adminPanel/AdminDashboardService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseUserRemainingLimitModal from '../../managements/users/bases/ApBaseUserRemainingLimitModal';

const ApDuNegativeAccountBalanceUsers = (props) => {

    const { refreshDate } = props;
    const { withCredit, withoutCredit, justGrantedCredit } = Enumerations.negativeAccountBalanceUsers;
    const [selectedTab, setSelectedTab] = useState(ConstantsWithTranslation.getNegativeAccountBalanceUsersTabs()[0]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [showRemainingLimitModal, setShowRemainingLimitModal] = useState(false);
    const [gridParameters, setGridParameters] = useState(null);
    const [isLoading, setIsloading] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');

    const createColumns = () => [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 90,
            cellClass: 'text-center',
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (user) => {
                    return !!user?.userId && <NsButton className='secondary-text'
                        startIcon='ns-icon-details'
                        onClick={() => userClicked(user)} />
                }
            }
        },
        selectedTab?.id !== Enumerations.negativeAccountBalanceUsers.withoutCredit && {
            field: 'grantedCredit',
            headerName: getTranslatedRawText('adminPanel.usersInfo.grantedCredit'),
            width: 200,
            dataType: Enumerations.gridType.price,
        },
        selectedTab?.id !== Enumerations.negativeAccountBalanceUsers.justGrantedCredit && {
            field: 'remainingLimit',
            headerName: getTranslatedRawText('adminPanel.usersInfo.remainingLimit'),
            width: 200,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'fullName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 170,
        },
        {
            field: 'phoneNumber',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
            width: 170,
        },
    ];

    const toggleShowRemainingLimitModal = () => setShowRemainingLimitModal(!showRemainingLimitModal);

    const userClicked = (user) => {
        toggleShowRemainingLimitModal();
        setSelectedUser(user);
    };

    const tabChanged = (selectedTabIndex, selectedTab) => {
        setSelectedTabIndex(selectedTabIndex);
        setSelectedTab(selectedTab)
    };

    const negativeAccountBalanceUsersService = () => {
        switch (selectedTab?.id) {
            case withCredit: return { gridService: GetApDuRemainingLimitWithCreditService, excelService: GetApDuRemainingLimitWithCreditExcelReportService }
            case withoutCredit: return { gridService: GetApDuRemainingLimitWithoutCreditService, excelService: GetApDuRemainingLimitWithoutCreditExcelReportService }
            default: return { gridService: GetApDuGrantedCreditsService, excelService: GetApDuGrantedCreditsExcelReportService }
        }
    };

    const gridParametersChanged = (gridParameters) => setGridParameters(gridParameters);

    return (
        <NsCard className='p-3 h-100'>
            <div className='fw-bold mb-4'>{getTranslatedRawText('adminPanel.dashboard.usersWithGrantedCredit')}</div>

            <div className='d-flex'>
                <NsExportExcelReportButton searchValues={gridParameters}
                    isLoading={isLoading}
                    service={negativeAccountBalanceUsersService()?.excelService} />
            </div>

            <NsTab tabs={ConstantsWithTranslation.getNegativeAccountBalanceUsersTabs()}
                value={selectedTabIndex}
                onChange={tabChanged}>
            </NsTab>

            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={negativeAccountBalanceUsersService()?.gridService}
                hasPagination={true}
                columnDefs={createColumns()}
                gridParametersChanged={gridParametersChanged} />

            {showRemainingLimitModal &&
                <ApBaseUserRemainingLimitModal closeModal={toggleShowRemainingLimitModal}
                    userId={selectedUser?.userId}
                    showModal={true} />
            }
        </NsCard>
    );
};

export default React.memo(ApDuNegativeAccountBalanceUsers);