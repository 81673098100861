import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsModal from '../../../../components/modals/NsModal';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsTextField from '../../../../components/inputs/NsTextField';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsProductsList from '../../../../components/productsList/NsProductsList'
import { GetPrintingFeatureService, GetProductInfoService, GetProductNameListService } from '../../../../services/product/ProductService';
import NsAutocomplete from '../../../../components/dropdowns/NsAutoComplete';
import { categoryActionCreators } from '../../../../redux/reducers/CategoryReducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NsToggleGroupButton from '../../../../components/buttons/NsToggleGroupButton';
import FileCorrectionUploadingFile from './FileCorrectionUploadingFile'
import { SaveDraftOrderFileService } from '../../../../services/ProfileSetting/UserOrderService';
import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import NsTypography from '../../../../components/text/NsTypography';

class AddFileCorrectionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: '',
            isValidDescription: false,
            showValidationDate: '',
            productList: [],
            selectedCategory: this.props.categories[0],
            categories: this.props.categories,
            product: {},
            selectedCirculation: {},
            refreshFeaturesDate: '',
            resultMessageClass: (<React.Fragment></React.Fragment>),
            selectedId: null,
            circulations: [],
            selectedPrintedSide: null,
            printedSides: [],
            filesId: [],
            isLoading: false,
        }
    }

    getProductInfoCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            let selectedCirculation = resultData.printCirculations.filter(
                circulation => circulation.key === CommonHelper.toInt(this.state.selectedId))[0];
            this.setState({
                product: resultData,
                selectedCirculation: selectedCirculation,
                refreshFeaturesDate: new Date(),
                isLoading: false
            });
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }
    getProductInfo = (selectedId) => {
        this.setState({ selectedId })
        !!selectedId && GetProductInfoService(selectedId, this.getProductInfoCallback);
    }

    getPrintingFeature = (productId) => {
        GetPrintingFeatureService(this.state.selectedId, this.getPrintingFeatureCallback);
    }

    getPrintingFeatureCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            const selectedPrintedSide = (resultData.printedSideTypes.length === 1 || !this.state.selectedPrintedSide) ? resultData.printedSideTypes[0] : this.state.selectedPrintedSide;
            this.setState({
                turnarounds: resultData.turnaroundTypes,
                printedSides: resultData.printedSideTypes,
                selectedTurnaround: resultData.turnaroundTypes[0],
                selectedPrintedSide,
                isLoadingFeatures: false
            }, () => CommonHelper.safeFunctionCall(this.props.printedSideChanged, this.state.selectedPrintedSide));
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getProductsCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({
                ...this.state,
                productList: resultData,
            })
        } else
            this.setState({ resultMessageClass: messageClassModal })

    }

    getProducts = (selectedCategoryId) => GetProductNameListService(selectedCategoryId || null, this.getProductsCallback)


    componentDidMount = () => {
        this.getProducts()
        this.getPrintingFeature(!!this.state.selectedCirculation ? this.state.selectedCirculation.key : null)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.categories !== this.props.categories) {
            this.setState({
                categories: this.props.categories
            })
        }
        if (prevState.selectedId !== this.state.selectedId) {
            this.getProductInfo(this.state.selectedId)
            this.getPrintingFeature(!!this.state.selectedCirculation ? this.state.selectedCirculation.key : null)
        }
    }

    selectedCategoryChanged = (selectedCategory) => {
        this.setState({ ...this.state, selectedCategory: selectedCategory }
            , () => this.getProducts(this.state.selectedCategory?.id))
    }

    valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        this.setState({
            [valueName]: value,
            [isValidName]: isValid,
        });
    }

    printedSideClicked = (selectedItem) => {
        if (selectedItem !== this.state.selectedPrintedSide)
            this.setState({ selectedPrintedSide: selectedItem });
    }

    circulationClicked = (selectedItem) => {
        if (selectedItem !== this.state.selectedCirculation) {
            this.setState({ selectedCirculation: selectedItem });
        }
    }

    selectedFiles = (filesId) => this.setState({ filesId })

    saveDraftOrderFile = (filesId) => {
        const data = {
            productId: this.state.selectedCirculation.key || 0,
            twoSidePrintingType: this.state.selectedPrintedSide.key,
            orderFileIds: filesId,
            description: this.state.description
        }
        SaveDraftOrderFileService(data, this.saveDraftOrderFileCallback, true)
    }

    saveDraftOrderFileCallback = (result, resultData, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({ isLoading: false }, () => {
                CommonHelper.safeFunctionCall(this.props.closeModal)
                CommonHelper.safeFunctionCall(this.props.openMessagePopup, messageClassModal);
            }
            )
        } else
            this.setState({ resultMessageClass: messageClassModal })
    }

    selectedIdChanged = (selectedId) => {
        this.setState({
            selectedId,
            isLoading: true
        })
    }

    render() {
        const { description, isValidDescription, product } = this.state

        const saveButtonClicked = () => {
            this.setState({ isLoading: true })
            this.saveDraftOrderFile(this.state.filesId)
        }

        const cancelClicked = () => {
            CommonHelper.safeFunctionCall(this.props.closeModal())
        }

        const mainButtons = [
            { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary' },
            { label: "common.cancel", icon: 'ns-icon-close', onClicked: cancelClicked, className: 'primary-outline' },
        ];

        return (
            <NsModal NsModal showModal={this.props.showModal}
                maxWidth={'md'}
                dialogTitle={getTranslatedRawText("profile.fileCorrection.NewFileCorrection")}
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeModal)}>
                <NsLoadingProgress visible={this.state.isLoading} />
                <div>
                    <NsTypography variant='inherit' className='fw-bold'>
                        {getTranslatedRawText("profile.fileCorrection.modalNoticeText")}
                    </NsTypography>
                </div>
                <Row>
                    <Col md={6}>
                        <NsAutocomplete label={getTranslatedRawText('professionalOrder.sidebar.productCategories')}
                            items={this.state.categories}
                            onChange={this.selectedCategoryChanged}
                            value={this.state.selectedCategory}
                            className='w-100 mb-2' />

                        <NsProductsList productId={this.state.selectedId}
                            selectedIdChanged={this.selectedIdChanged}
                            isModal={true}
                            selectedCategoryId={this.state.selectedCategory?.id} />
                    </Col>
                    <Col md={6} className='d-flex mb-2 mt-4 flex-column justify-content-start'>
                        <span className="page-title mb-4">
                            {product.name}
                        </span>
                        <div className='mb-3'>
                            <NsToggleGroupButton isShowGroupLabel={true}
                                label={getTranslatedRawText('product.detail.circulation')}
                                vertical={!!CommonHelper.isMobileDevice()}
                                onClick={this.circulationClicked}
                                selectedItemId={this.state.selectedCirculation.key}
                                items={product.printCirculations} />
                        </div>

                        <div className='mb-3'>
                            <NsToggleGroupButton isShowGroupLabel={true}
                                label={getTranslatedRawText('product.detail.printedSide')}
                                vertical={false}
                                onClick={this.printedSideClicked}
                                selectedItemId={!!this.state.selectedPrintedSide && this.state.selectedPrintedSide.key}
                                items={this.state.printedSides}
                            />
                        </div>
                        <div className='mb-3'>
                            <FileCorrectionUploadingFile selectedFiles={this.selectedFiles} />
                        </div>
                        <NsTextField valueChanged={(value, isValid) =>
                            this.valueChanged(value, isValid, { description }, { isValidDescription })}
                            showValidationDate={this.state.showValidationDate}
                            label={getTranslatedRawText('profile.fileCheking.Description')}
                            size="small"
                            minLength={2}
                            value={this.state.description}
                            className="w-100 mb-3" />
                    </Col>
                </Row>
                <NsStickyButton stickyButtons={mainButtons} />
            </NsModal>
        );
    }
}

const mapStateToProps = state => ({ categories: state.categories.categories })

const mapDispachToProps = (dispatch) => {
    return bindActionCreators({ ...categoryActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(AddFileCorrectionModal);