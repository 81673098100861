import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsRadioButton from '../../../../../components/buttons/NsRadioButton';
import NsOptionBox from '../../../../../components/labels/NsOptionBox';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import ExtendedServiceWrapper from './ExtendedServiceWrapper';

class ExtendedServiceTopFolding extends React.Component {

    constructor(props) {
        super(props);
        this.tourId = Enumerations.tourGuide.gripperEdge;
        this.tourSteps = RegularOrderTours.getGripperEdgeTour();
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = {
            showTour: !CurrentUserHelper.isTourSeen(this.tourId),
            selectedTopFolding: {},
            refreshExtendedServiceSummaryDate: null,
            topFoldingOptions: this.getTopFoldingOptions(),
        }
    }

    getTopFoldingOptions = () => {
        if (OrderHelper.isValidOrder(this.routeId)) {
            let topFoldingPrice = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.topFolding)?.price;
            let topFoldingOptions = ConstantsWithTranslation.getBagTopFoldingOptions(topFoldingPrice)
            return topFoldingOptions;
        }
        return [];
    }

    componentDidMount = () => {
        if (OrderHelper.isValidOrder(this.routeId)) {
            let service = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.topFolding);
            if (!!service?.selectedItem)
                this.setState({ selectedTopFolding: service.selectedItem });
            else
                this.topFoldingOptionClicked(this.state.topFoldingOptions[0]?.id, true);
        }
    }

    topFoldingOptionClicked = (id, dontAddToSeenTours) => {
        let selectedTopFolding = this.state.topFoldingOptions.find(option => option.id === Number(id));
        OrderHelper.updateExtendedService(this.routeId, Enumerations.extendedServices.topFolding, selectedTopFolding);
        this.setState({ selectedTopFolding, refreshExtendedServiceSummaryDate: new Date(), showTour: !CurrentUserHelper.isTourSeen(this.tourId) });
        !dontAddToSeenTours && !CurrentUserHelper.isTourSeen(this.tourId) && CurrentUserHelper.addToSeenTours(this.tourId);
    }

    render() {
        const { selectedTopFolding, topFoldingOptions, refreshExtendedServiceSummaryDate, showTour } = this.state;

        return (
            <ExtendedServiceWrapper otherProps={this.props}
                routeId={this.routeId}
                isSelectedRequirements={!isEmpty(selectedTopFolding)}
                refreshExtendedServiceSummaryDate={refreshExtendedServiceSummaryDate}
                currentExtendedService={Enumerations.extendedServices.topFolding}>

                <Row className='extendedService-tour-handeType g-0 px-3'>
                    <TopFoldingOptions topFoldingOptions={topFoldingOptions}
                        topFoldingOptionClicked={this.topFoldingOptionClicked}
                        selectedTopFolding={selectedTopFolding} />

                </Row>

                {/* {showTour && <NsTour steps={this.tourSteps}
                    id={this.tourId} />} */}
            </ExtendedServiceWrapper>
        );
    }
}

function TopFoldingOptions(props) {
    if (props.topFoldingOptions) {
        return props.topFoldingOptions.map((option) => {
            return (
                <Col xs={10} sm={4} key={option.id} className='mb-2' onClick={() => props.topFoldingOptionClicked(option.id)}>
                    <NsOptionBox isSelected={props.selectedTopFolding.id === option.id}>

                        <img src={option.img}
                            className='pt-2'
                            width='60px'
                            height='50px'
                            alt={getTranslatedRawText("components.imagesAlt.foldLineIcons")} />
                        <div>
                            <div className="font-size-12 light-text-divor fw-bold mt-2">
                                <NsRadioButton option={option}
                                    checked={props.selectedTopFolding.id === option.id} />
                            </div>
                            <div className="font-size-12 d-none d-md-block ms-3 mb-2">
                                {option.description}
                            </div>
                            <div className="font-size-12 light-text-color mt-auto ms-3">
                                {CommonHelper.currencyFormatByDefaultConfig(option.price)}
                            </div>

                        </div>
                    </NsOptionBox>
                </Col>
            );
        });
    }
}

export default ExtendedServiceTopFolding