import Links from "../../configs/links/AllLinks"
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest, PatchRequest, DeleteRequest, PostRequest } from "../bases/BaseService"

const GeFeedbackTypesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackTypes), parameter, callback);
};

const GeFeedbackTypeByIdService = (id, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackTypeById), { id }, callback);
};

const UpdateFeedbackTypeStateService = (id, callback) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.updateApFeedbackTypeState), id), {}, callback, true);
};

const GeFeedbackTypeMetricsService = (parameter, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackTypeMetrics), parameter.id), parameter, callback);
};

const GeFeedbackTypeMetricByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackTypeMetricById), id), {}, callback);
};

const PosFeedbackTypeMetricService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApFeedbackTypeMetric), data, callback, showSuccessResponseMessage);
};

const EdiFeedbackTypeMetricService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApFeedbackTypeMetric), id), data, callback, showSuccessResponseMessage);
};

const EditSorFeedbackTypeMetricService = (data, callback) => {
    PatchRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApSortFeedbackTypeMetric), data, callback);
};

const DeleteFeedbackTypeMetricService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApFeedbackTypeMetric), id), {}, callback, showSuccessResponseMessage);
};

const GetDemandServiceMetricTypesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDemandServiceMetricTypes), {}, callback);
};

const GetApDemandServiceMetricByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApDemandServiceMetricById), id), {}, callback);
};

const PostDemandServiceMetricService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApDemandServiceMetric), data, callback, showSuccessResponseMessage);
};

const EditDemandServiceMetricService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApDemandServiceMetric), id), data, callback, showSuccessResponseMessage);
};

const EditSortDemandServiceMetricService = (data, callback) => {
    PatchRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApSortDemandServiceMetric), data, callback);
};

const DeleteDemandedServiceTypes = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteDemandedServiceTypes), id), {}, callback, showSuccessResponseMessage);
}

const GetFeedbackAnnouncementsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackAnnouncements), parameters, callback);
};
const GetFeedbackAnnouncementBasicInfoService = (selectedFeedback, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackAnnouncementBasicInfo), selectedFeedback?.userFeedbackId, selectedFeedback?.announcementContentId || selectedFeedback?.actionId), {}, callback);
};

const GetFeedbackEmployeesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackToEmployees), parameters, callback);
};
const GetFeedbackEmployeeBasicInfoService = (selectedFeedback, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackToEmployeeBasicInfo), selectedFeedback?.userFeedbackId, selectedFeedback?.employeeId), {}, callback);
};

const GetFeedbackShareSheetOrdersService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackShareSheetOrders), parameters, callback);
};
const GetFeedbackShareSheetOrderBasicInfoService = (selectedFeedback, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackShareSheetOrderBasicInfo), selectedFeedback?.userFeedbackId, selectedFeedback?.sharedSheetOrderId || selectedFeedback?.actionId), {}, callback);
};

const GetFeedbackActivityHistoryService = (selectedFeedback, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFeedbackActivityHistory), selectedFeedback?.userFeedbackId), { userId, feedbackType: selectedFeedback?.feedbackType }, callback);
};
const UpdateFeedbackDisplayToAllStateService = (feedbackId, isPublic, callback) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.updateApFeedbackDisplayToAllState), feedbackId), { isPublic }, callback, true);
};
const AddFeedbackActivityService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApFeedbackActivity), data, callback, true);
};
export {
    GeFeedbackTypesService,
    GeFeedbackTypeByIdService,
    UpdateFeedbackTypeStateService,
    GeFeedbackTypeMetricsService,
    GeFeedbackTypeMetricByIdService,
    PosFeedbackTypeMetricService,
    EdiFeedbackTypeMetricService,
    EditSorFeedbackTypeMetricService,
    DeleteFeedbackTypeMetricService,

    GetDemandServiceMetricTypesService,
    GetApDemandServiceMetricByIdService,
    PostDemandServiceMetricService,
    EditDemandServiceMetricService,
    EditSortDemandServiceMetricService,
    DeleteDemandedServiceTypes,

    GetFeedbackAnnouncementsService,
    GetFeedbackAnnouncementBasicInfoService,

    GetFeedbackEmployeesService,
    GetFeedbackEmployeeBasicInfoService,

    GetFeedbackShareSheetOrdersService,
    GetFeedbackShareSheetOrderBasicInfoService,

    GetFeedbackActivityHistoryService,
    UpdateFeedbackDisplayToAllStateService,
    AddFeedbackActivityService,

}