import React, { useEffect } from 'react';
import NsModal from '../../../../../components/modals/NsModal';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import { useState } from 'react';
import NsLoadingProgress from '../../../../../components/circularProgress/NsLoadingProgress';
import NsLabelBox from '../../../../../components/labels/NsLabelBox';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApBaseUserInfoModal from '../bases/ApBaseUserInfoModal';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import { GetUserAgreementHistoriesService, GetUserProfileInfoService } from '../../../../../services/adminPanel/AdminAgreementService';
import NsButton from '../../../../../components/buttons/NsButton';
import Enumerations from '../../../../../configs/Enumerations';
import ApCurrentActiveAgreementDetailModal from '../../../contents/singlePages/agreements/agreementDetailModal/ApCurrentActiveAgreementDetailModal';
import { isEmpty } from '../../../../../utilities/ValidationHelper';

const ApAgreementsHistoryModal = (props) => {

    const { userId, closeModal } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [showAgreementDetailModal, setShowAgreementDetailModal] = useState(false);
    const [currentActiveAgreementId, setCurrentActiveAgreementId] = useState(null);

    const columnDefs = [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 100,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => <NsButton className='secondary-text px-0'
                    startIcon='ns-icon-invoice-details cursor-pointer'
                    onClick={() => showAgreementDetailClicked(props.id)} />
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 500
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 90,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.approvalTime'),
            width: 200,
            dataType: Enumerations.gridType.dateTime
        }
    ]

    useEffect(() => {
        getUserProfileInfo();
    }, [])

    const getUserProfileInfo = () => {
        setIsLoading(true);
        GetUserProfileInfoService(userId, getUserProfileInfoCallback);
    }

    const getUserProfileInfoCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setUserDetails(resultData);
            setCurrentActiveAgreementId(resultData.currentActiveAgreement.id);
        } else {
            setUserDetails([]);
        }
    }

    const showAgreementDetailClicked = (id) => {
        if (!!id) setCurrentActiveAgreementId(id);

        setShowAgreementDetailModal(!showAgreementDetailModal);
    }

    const userInfoClicked = () => {
        setShowUserInfoModal(true);
    }

    const headerButtons = [
        { wrapperClassName: '', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: userInfoClicked },
    ];

    const getData = (rows, loading) => setIsLoading(loading);

    return (
        <NsModal dialogTitle={getTranslatedRawText("components.advanceSearch.agreementsHistory")}
            showModal={true}
            noSpace={true}
            maxWidth='md'
            closeModal={closeModal}>
            <>
                <ApBaseMapActionButtons actionButtons={headerButtons}
                    isLoading={isLoading} />

                <NsLoadingProgress visible={isLoading} />

                <div className='background-color px-3 py-2 d-flex flex-wrap gap-2'>
                    {userDetails?.fullName && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                        value={userDetails?.fullName} />}
                    {!!userDetails?.phoneNumber && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.mobile')}
                        value={userDetails?.phoneNumber} />}
                    {!!userDetails?.currentActiveAgreement?.title && <NsLabelBox className='cursor-pointer background-white-color'
                        label={getTranslatedRawText('adminPanel.singlePage.activeAgreement')}
                        value={<span>
                            {userDetails?.currentActiveAgreement.title}
                            <i className='ns-icon-expand-right' ></i>
                        </span>}
                        onClick={showAgreementDetailClicked} />}
                </div>

                <NsDataGridWithServiceCall service={GetUserAgreementHistoriesService}
                    parameters={{
                        id: userId
                    }}
                    getData={getData}
                    hasPagination={true}
                    columnDefs={columnDefs} />

                {showUserInfoModal &&
                    <ApBaseUserInfoModal showModal={showUserInfoModal}
                        selectedUserId={userId}
                        closeModal={() => setShowUserInfoModal(false)} />
                }

                {showAgreementDetailModal && !isEmpty(currentActiveAgreementId) &&
                    <ApCurrentActiveAgreementDetailModal agreementId={currentActiveAgreementId}
                        closeModal={showAgreementDetailClicked} />
                }

            </>
        </NsModal>
    );
};

export default React.memo(ApAgreementsHistoryModal);