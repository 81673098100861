import React from 'react';

import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsEtiquette from '../../../../components/etiquette/NsEtiquette';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsGroupNumberInput from '../../../../components/inputs/NsGroupNumberInput';
import CommonHelper from '../../../../utilities/CommonHelper';

class OBaseModalItemList extends React.Component {

    render() {

        let style, border, errorText = '';
        const { items, masterFileDimention, isPunchList, hasDuplicatedSerials } = this.props;

        let punchList = !isEmpty(items)
            ? items.map((item, index) => {
                if (this.props.checkAuthorizedArea(item.verticalPoint, item.horizontalPoint) || hasDuplicatedSerials) {

                    errorText = isPunchList ? getTranslatedRawText('regularOrder.modal.punchPositionError') : getTranslatedRawText('regularOrder.modal.serialPositionError');
                    if (hasDuplicatedSerials)
                        errorText = getTranslatedRawText('regularOrder.modal.overlapError')

                    style = 'background-red-color white-color';
                    border = 'border-red-color-1px';
                } else {
                    style = 'background-outline-color';
                    border = 'border-outline-color-1px';
                    errorText = ''
                }
                return <NsEtiquette style={style}
                    border={border}
                    
                    key={index}
                    errorText={errorText}
                    index={index}
                    title={isPunchList ? getTranslatedRawText('regularOrder.extendedService.punch') : getTranslatedRawText('regularOrder.billBook.serialNumber')}
                    deleteEtiquette={() => this.props.deleteClicked(index)}>

                    <div className='d-flex justify-content-around align-items-end flex-wrap'>
                        <NsGroupNumberInput label={getTranslatedRawText('regularOrder.extendedService.offSetFromTop')}
                            fieldClassName='bg-white'
                            value={CommonHelper.toFloat(item.verticalPoint)}
                            inputProps={{ min: 0, max: masterFileDimention?.height }}
                            step={1}
                            // disabled={!isEmpty(errorText)}
                            unit={getTranslatedRawText('common.mm')}
                            // handleFocus={() => this.props.handlePunchFocus(index + 1)}
                            addButtonDisabled={item.verticalPoint > masterFileDimention?.height}
                            minusButtonDisabled={item.verticalPoint <= 1}
                            onChanged={(modifiedTop) => this.props.changeTopItemPositionClicked(modifiedTop, index)}
                        />
                        <NsGroupNumberInput label={getTranslatedRawText('regularOrder.extendedService.offSetFromLeft')}
                            fieldClassName='bg-white'
                            value={CommonHelper.toFloat(item.horizontalPoint)}
                            inputProps={{ min: 0, max: masterFileDimention?.width }}
                            step={1}
                            // disabled={true}
                            unit={getTranslatedRawText('common.mm')}
                            // handleFocus={() => this.props.handlePunchFocus(index + 1)}
                            addButtonDisabled={item.horizontalPoint > masterFileDimention?.width}
                            minusButtonDisabled={item.horizontalPoint <= 1}
                            onChanged={(modifiedLeft) => this.props.changeLeftItemPositionClicked(modifiedLeft, index)}
                        />
                    </div>

                </NsEtiquette>
            })
            : null
        return punchList;
    }
}

export default OBaseModalItemList