const ProductTranslationEn = {
    product: {
        common: {
            aed: 'AED',
            pieces: "pcs",
            piece: "pcs",
            save: 'Save',
            add: 'Add',
            clear: 'Clear',
            next: 'Next',
            previous: 'Previous',
            skip: 'Skip',
            frontSide: 'Front Side',
            backSide: 'Back Side',
            searchPlaceHolder: 'Search in Product Name ...',
            viewMore: 'View More',
            viewLess: 'View Less',
            filter: 'Filter',
            goldFoil: 'Gold foil',
            silverFoil: 'Silver foil',
            UV: 'UV',
            dieCut: 'Die cut',
            remove: 'Remove',
            removeAllFilters: "Remove All Filters",
            search: "Search",
            groupSheetList: 'Group Sheet Products',
            storeList: 'Store'
        },
        sideBarTitles: {
            selectedFilter: 'Selected Filters',
            categories: 'Categories',
            searchInResult: 'Search in result',
            price: 'Price range',
            options: 'Printing Options',
            finishing: 'Finishing',
            monthlyArchive: 'Monthly Archive'
        },
        months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
        sortItems: {
            sortBy: 'Sort by',
            lowestPrice: 'Lowest price',
            highestPrice: "Highest price",
            bestSelling: "Best selling",
            newest: "Newest",
            fastestDelivery: "Fastest Shipping"
        },
        detail: {
            downloadGuide: 'Guides',
            quantityHelper: 'In order to print the most economical option, based on the circulation and series, enter your required quantity.',
            turnaround: 'Turnaround',
            printedSide: 'Printed Side',
            days: 'Business Day(s)',
            hours: 'Business Hour(s)',
            circulation: 'Circulation',
            quantity: 'Quantity',
            series: 'Series',
            availableDimension: 'Available Dimension(s):',
            dieLineFile: 'Die/Cut Line',
            sharpDieCutLine: 'Sharp Die/Cut Line',
            customDimension: 'Custom Dimension Price Inquiry',
            rowDimension: '{0} ({1}×{2} mm) ',
            latCount: '({0} Ups)',
            startOrdering: 'Start Ordering',
            priceLabel: '{0} - {1}',
            available: 'Available',
            unavailable: 'Unavailable',
            processHoursAndDays: '{0} {1}',
            processTimeNotice: "Process Duration",
            selectedDimension: 'Selected Dimension',
            dimensionNotFound: 'There is no dimension for the selected circulation',
            unavailableNote: '* Note: Unavailable items are due to the quantity / series you have selected.',
            overview: 'Overview',
            explanation: 'Explanation',
            showPrice: 'Show Price',
            recommendedProducts: 'Recommended Products',
            explanationViewPart1: 'Your selected product is for {0} Series of {1} Circulation, totaling {2} Quantity of {3}, which are printed with a {4} Turnaround and a {5} Printed Side.',
            explanationViewPart2: 'You\'ll receive high-quality printing {0} to meet your specific needs. The order will be ready in the next {1}. After printing, you can choose a delivery method to ensure fast delivery of your order.',
            explanationGuidances: 'The Guidance file of this product is available, which you can download and design based on ',
            explanationNotViewPart1: 'We offer competitive pricing, ensuring that high-quality promotional materials are accessible to businesses of all sizes.',
            explanationNotViewPart2: 'Choose the dimensions that best fit your campaign, and let us help you make a lasting impression on your audience at the lowest price.',
            explanationAvailableDimensionTitle: 'Available Dimensions and best offer Price list (Excluding Value-Added Tax (VAT)):',
            explanationAvailableText: '{0} at the price of {1} (width is {2} mm and height is {3} mm)',
            explanationUnavailableText: '{0} at the price of {1} (width is {2} mm and height is {3} mm), is Unavailable based on the selected quantity/series.',
            explanationCustomSizeText: 'if you don\'t see your needed dimension and size click on “custom dimension price inquiry“.',
            explanationSelectableCirculation: 'You can choose the order circulation from {0}. ',
            explanationSelectableTurnaround: 'Select the printing turnaround between Normal and Express. ',
            explanationSelectablePrintedSide: 'Choose whether your printed side order should be single-sided (Front only) or double-sided (Front and Back).',
            explanationNormalFrontProgressTime: 'In Normal turnaround Front only Print side , the product will be ready in {0}. ',
            explanationExpressFrontProgressTime: 'For Express turnaround Front only Print side, orders will be in the next {0}. ',
            explanationNormalFrontBackProgressTime: 'For double-sided offset printing (Front and Back) with Normal turnaround, your order will be ready within {0}. ',
            explanationExpressFrontBackProgressTime: 'In Express turnaround Front and Back printing (double-sided), processing will be completed within the {0}.',
            explanationTerms: 'By clicking on the ”Start Ordering” button, you can easily and conveniently place your order online. Before placing an order, please pay attention to our {0}, {1}, and {2}. For additional customization or any issues with placing your order or have a question that is not addressed in the {3},  please get in {4}. Our support team is ready to assist you.',
            explanationExploreCategory: 'If you wish to explore a variety of {0} and more, please refer to our {1} on our website.',
            explanationIntroduction: 'Diamond Printing Press is one of the largest online printing companies in Dubai and provides printing services across the United Arab Emirates (UAE) (Dubai, Abu Dhabi, Sharjah, Ajman, Ras Al Khaimah, Fujairah, Umm al-Quwain, And Print shop near me). We offer wide range of offset printing products, including business cards (matte and glossy laminated, round corners, velvet, UV), flyers, letterheads, labels, posters, brochures, invoices, office sets, commercial printing services, and packaging products, along with post print services such as folding, cutting, punching, embossing, and so on. All with high quality and competitive prices. Additionally, we provide you with the option to print your own customized and exclusive designs. Diamond Press, with its user-friendly and secure ordering system, allows you to place your orders at any time and from anywhere, ensuring the shortest possible turnaround time.',
            confirmationDate: 'Confirmation Date',
            confirmationTime: 'Confirmation Time',
            productLicenseRequired: 'Product License Required?',
            prohibitsPrintingDetail: 'Prohibits/Printing Details',
            pdrOnlineDiscountInformation: 'Up to {0}% off for online Orders. The crossed-out price is for email or in-person purchases.',
        },

        messages: {
            formatIsWrong: 'Format Is Wrong',
            noResult: 'No Result Found',
            noDiscountedProductAvailable: 'Unfortunately No Discounted Product is Available for now',
        },
        customCard: {
            availableCirculations: 'Available Circulations:',
            availableUnitType: 'Available Unit',
            availableInStore: 'Available in Store'
        },
        store: {
            similarProducts: 'Similar Products',
            cart: 'cart',
            addToBasket: 'Add to cart'
        },
        shoppingBag: {
            primaryImageTitle: 'ECO-FRIENDLY PAPER BAG',
            primaryMiddleImageTitle: 'Didn\'t find the product you\'re looking for among the options above? Enter your desired specifications, and I\'ll calculate the price for you.',
            secondaryMiddleImageTitle: 'Customize My Order',
            customizeProduct: 'Customize Product',
            customizeProductDescription: 'Customize your products with special and adjustable options according to your preferences and needs, and see the final price instantly.',
            priceInquiry: 'Price Inquiry',
            onlineAvailableProducts: 'Online Available Products',
            onlineAvailableProductsDescription: 'Explore the rich variety of shopping bag available online, carefully curated to elevate and enhance every aspect of your shopping experience.',
            popularSpecification: 'Popular Specification',
            gusset: 'Gusset (mm)',
            width: 'Width (mm)',
            height: 'Height (mm)',
            quantity: 'Quantity',
            paperThickness: 'Paper Thickness (gsm)',
            packaging: 'Packaging',
            paperType: 'Paper Type',
            topFold: 'Top Fold',
            shrinkedFilm: 'Shrinked Film',
            carton: 'Carton',
            both: 'Both',
            all: 'All',
            virgin: 'Virgin',
            recycle: 'Recycle',
            calculate: 'Calculate',
            howToOrder: 'How to Order',
            price: 'Price',
            paperWasteWeight: 'Paper Waste Weight',
            pricePerShoppingbag: 'Price Per Bag',
            description: 'Description',
            howToMeasure: 'How to measure',
            discoverOurProducts: 'Discover Our Products',
            thePerfectShopping: 'The Perfect Shopping',
            introducingOurShoppingBags: 'Introducing Our Shopping Bags',
            seeMore: 'See More',
            orderProcess: 'Order Process',
            faqs: 'FAQs',
            openWidth: 'Open Width',
            rollWidth: 'Roll Width',
            openHeight: 'Open Height',
            printSize: 'Print Size',
            makePrice: 'Make Price',
            shrinkPrice: 'Shrink Price',
            cartonPrice: 'Carton Price',
            totalPaperWeight: 'Total Paper Weight',
            printPrice: 'Print Price',
            makePricePerShoppingBag: 'Make Price Per Shopping Bag',
            needPrint: 'Need Print',
            mm: 'mm',
            kg: 'kg',
            unavailable: 'Unavailable',
            calculateHistory: 'Calculate History',
            gwh: 'G*W*H',
            openwh: 'Open W*H',
            WhyWeAreDifferent: 'WHY WE ARE DIFFERENT',
            UniqueValueProposition: 'Unique Value Proposition',
            gripperMarginPrint: 'Gripper Margin Print',
            turnaround: 'Turnaround',
            history: 'History',
            saved: 'Saved',
            saveItem: 'Save Item',
            name: 'Name'
        },

        listStyle: {
            action: 'Action',
            productName: 'Product Name',
            circulation: 'Circulation',
            dimension: 'Dimension',
            normalFrontOnly: 'Normal | Front Only',
            normalFrontAndBack: 'Normal | Front and Back',
            expressFrontOnly: 'Express | Front Only',
            expressFrontAndBack: 'Express | Front and Back',

        },

        tour: {
            sidebarFilter: 'Different filters like categories, price, and so on can be selected.',
            productResult: 'Products are shown based on the selected filters. Start the ordering process by clicking on the product.',
            detailQuantity: 'enter your required quantity and check the circulation and series.',
            detailOptions: 'Front Only or Front And Back printing and Turnaround are shown here.',
            detailAvailable: 'The system will automatically check the available sizes based on the file you upload.',
            detailStartOrdering: ' After making sure about the details of the selected product, click on start ordering.',
            selectProduct: 'select your product. You can do this easily using search option and categories part.',
            poDetailQuantity: 'check and select your required circulation.',
        }
    }
};

export default ProductTranslationEn;