import { FormControlLabel } from '@mui/material';
import React, { Component } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { TreeItem, TreeView } from '@mui/lab';

import '../../assets/sass/components/treeViews/NsTreeViews.scss'
import CommonHelper from '../../utilities/CommonHelper';
import NsCheckbox from '../inputs/NsCheckbox';
import { isEmpty } from '../../utilities/ValidationHelper';
import Links from '../../configs/links/AllLinks';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';

class NsCheckboxTreeView extends Component {

    menuItemClicked = (item) => {
        CommonHelper.safeFunctionCall(this.props.menuItemClicked, item)
    }

    treeItemRenderer = (item, checkboxClassName, checkboxHandler) => {
        return this.props.isMenu
            ? <TreeItem key={item.id} nodeId={item?.id.toString()} className={this.props.menuClassName}
                label={<FormControlLabel control={<span className='py-1 font-size-14'
                    key={item.id}>
                    {!!item?.path && isEmpty(item.children)
                        ? <Link to={this.props.isHeader
                            ? CommonHelper.stringFormat(
                                getUrlWithCurrentCulture(Links.view.main, Links.view.main.productsByCategory,
                                    { "page-number": 1 }), item.id, CommonHelper.makeCorrectLink(item.name))
                            : item.path}
                            rel='canonical'
                            onClick={this.menuItemClicked}
                            className='d-inline-block w-100 white-color'>
                            {item.name}
                        </Link>
                        : <span className='cursor-pointer white-color'
                            onClick={this.menuItemClicked}>
                            {item.name}
                        </span>
                    }
                </ span>} />}>
                {!isEmpty(item.children) && item.children.map(childItem => this.treeItemRenderer(childItem, checkboxClassName, checkboxHandler))}
            </TreeItem>
            : !!item?.show && <TreeItem key={item.id} nodeId={item?.id.toString()} className={CommonHelper.isMobileDevice() ? 'mt-3' : ''}
                label={<FormControlLabel control={<NsCheckbox
                    onClick={(e) => e.stopPropagation()}
                    value={item.checked || !isEmpty(item.children) && this.checkedTypeSpecifier(item).isChecked}
                    indeterminate={!isEmpty(item.children) ? this.checkedTypeSpecifier(item).isIndeterminate : false}
                    onChange={() => checkboxHandler(item)}
                    className={checkboxClassName}
                    label={item.name} />} />}>
                {!isEmpty(item.children) && item.children.map(childItem => this.treeItemRenderer(childItem, checkboxClassName, checkboxHandler))}
            </TreeItem>
    }

    checkedTypeSpecifier = (item) => {
        let parentLessItems = CommonHelper.recursiveSeparator(item).parentLessItems
        return {
            isChecked: parentLessItems.every(item => item.checked),
            isIndeterminate: !parentLessItems.every(item => item.checked)
                ? parentLessItems.some(item => item.checked)
                : false,
        }
    }

    checkboxHandler = (item) => {
        CommonHelper.safeFunctionCall(this.props.onChange, item, !isEmpty(item?.children)
            ? {
                id: item.id,
                isChecked: (this.checkedTypeSpecifier(item).isIndeterminate || this.checkedTypeSpecifier(item).isChecked),
                roleId: this.props.isReturnData && this.props.roleId,
                data: this.props.isReturnData && CommonHelper.recursiveSelector(this.props.items, {
                    targetIds: CommonHelper.recursiveSeparator(item).parentLessItems.map(item => item.id),
                    isChecked: (this.checkedTypeSpecifier(item).isIndeterminate || this.checkedTypeSpecifier(item).isChecked),
                })
            }
            : {
                roleId: this.props.isReturnData && this.props.roleId,
                data: this.props.isReturnData && CommonHelper.recursiveSelector(this.props.items, { targetIds: [item.id] })
            })
    }

    render() {
        const { items, checkboxClassName, className } = this.props;

        const menuItems = items.filter(item => !isEmpty(item.id));
        return (
            <TreeView className={clsx("ns-checkbox-tree-view", className ? className : '')}
                defaultCollapseIcon={<i className={clsx("ns-icon-expand-down pe-3", CommonHelper.isMobileDevice() && 'me-3')} />}
                defaultExpandIcon={<i className={clsx(`${CommonHelper.getBreadcrumbsSeparatorIcon()} pe-3`, CommonHelper.isMobileDevice() && 'me-3')} />}>
                {menuItems.map(item => this.treeItemRenderer(item, checkboxClassName, this.checkboxHandler))}
            </TreeView>
        )
    }

}

export default NsCheckboxTreeView;
