import React, { Component } from 'react';
import Links from '../../../configs/links/AllLinks';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { Link } from 'react-router-dom';
import NsButton from '../../../components/buttons/NsButton';
import { Alert } from 'react-bootstrap';
import NsSnackbar from '../../../components/snackbars/NsSnackbar';

class AgencyAlert extends Component {

    render() {
        const { handleClose, open } = this.props;
        return <NsSnackbar open={open}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={handleClose}>

            <Alert variant="warning" className="p-2" >
                <div className='d-flex flex-column align-items-start justify-content-start px-2'>
                    <i className='ns-icon-close cursor-pointer align-self-end' onClick={handleClose} />
                    <span className='fw-bold pb-3'>
                        {getTranslatedRawText('profile.agency.alertAgencyTitle')}
                    </span>
                    <p>
                        {getTranslatedRawText('profile.agency.alertAgencyDescription')}
                    </p>
                    <Link to={getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAgencySetting)}
                        className='text-decoration-none'>
                        <NsButton label={getTranslatedRawText('profile.agency.activeAgency')}
                            className='primary-text' />
                    </Link>
                </div>
            </Alert>
        </NsSnackbar >
    }
}

export default AgencyAlert;