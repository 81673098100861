import React, { useEffect, useState } from 'react';

import { DeleteDownloadService, EditDownloadService, GetDownloadByIdService, PostDownloadService } from '../../../../../services/adminPanel/AdminDownloadService';
import NsStepperWithActionButtons from '../../../../../components/stepper/NsStepperWithActionButtons';
import NsLoadingProgress from '../../../../../components/circularProgress/NsLoadingProgress';
import ApBaseDownloadNewFileContent from '../bases/ApBaseDownloadNewFileContent';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApDownloadAttachFileContent from './ApDownloadAttachFileContent';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import DateHelper from '../../../../../utilities/DateHelper';
import ContentHelper from '../../bases/ContentHelper';

const ApDownloadModal = ({ selectedDownloadId, closeModalClicked }) => {

    const [stepIndex, setStepIndex] = useState(0);
    const [tempStepIndex, setTempStepIndex] = useState(null);
    const [nextButtonTriggerDate, setNextButtonTriggerDate] = useState(null);
    const [downloadId, setDownloadId] = useState(selectedDownloadId);
    const [messageClassModal, setMessageClassModal] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [multiLanguageFileContent, setMultiLanguageFileContent] = useState([]);
    const [showValidationDate, setShowValidationDate] = useState(null);
    const [isEditMode, setIsEditMode] = useState(!isEmpty(downloadId));

    useEffect(() => {
        if (stepIndex == 0 && !isEmpty(downloadId)) {
            setIsLoading(true);
            setMultiLanguageFileContent(null);
            GetDownloadByIdService(downloadId, getDownloadByIdCallback);
        } else
            setMultiLanguageFileContent(ContentHelper.normalizeContentObject([], { isActive: false, releaseDate: DateHelper.getTodayDateTime(0) }));
    }, [stepIndex]);

    useEffect(() => {
        setIsEditMode(!isEmpty(downloadId));
    }, [downloadId]);

    const getDownloadByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguageFileContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive }));
        } else setMessageClassModal(messageClassModal);
    }

    const stepButtons = [
        stepIndex !== 0 && {
            visible: true,
            className: 'primary-outline',
            label: 'common.label.back',
            icon: CommonHelper.getBackIconByCulture(),
            disabled: stepIndex == 0,
            type: Enumerations.stepperButtonType.previous,
            validation: () => true,
            event: (type, newStep) => {
                setNextButtonTriggerDate(null);
                setStepIndex(newStep);
            }
        },
        stepIndex >= 1 && {
            visible: true,
            className: 'primary mx-auto',
            label: 'common.save',
            icon: 'ns-icon-save',
            disabled: false,
            type: Enumerations.stepperButtonType.next,
            validation: () => true,
            event: (type, newStep) => closeModalClicked()
        },
        isEditMode && !stepIndex >= 1 && {
            visible: true,
            className: 'primary-outline mx-auto',
            label: 'common.next',
            icon: CommonHelper.getNextIconByCulture(),
            disabled: false,
            type: Enumerations.stepperButtonType.next,
            validation: () => true,
            event: (type, newStep) => {
                setNextButtonTriggerDate(null);
                setStepIndex(newStep);
            }
        },
        !stepIndex >= 1 && {
            visible: true,
            className: 'primary mx-auto',
            label: 'adminPanel.download.saveAndNextStep',
            icon: CommonHelper.getNextIconByCulture(),
            disabled: false,
            type: Enumerations.stepperButtonType.next,
            validation: () => true,
            event: (type, newStep) => {
                setNextButtonTriggerDate(new Date().toISOString());
                setTempStepIndex(newStep);
            }
        },
    ]

    const steps = [
        { title: getTranslatedRawText('adminPanel.download.details'), buttons: stepButtons },
        { title: getTranslatedRawText('adminPanel.download.files'), buttons: stepButtons },
    ]

    const goToNextStep = (id) => {
        setDownloadId(id);
        tempStepIndex >= steps.length
            ? closeModalClicked()
            : setStepIndex(tempStepIndex);
        setNextButtonTriggerDate(null);
    }

    const saveButtonClicked = (id, data) => {
        let tempContent = ContentHelper.removeDownloadFileEmptyContent(data);
        let isNotValidData = ContentHelper.isValidDownloadFileContent(tempContent);
        if (isEmpty(tempContent) || isNotValidData) {
            setShowValidationDate(new Date());
            return;
        } else {
            setIsLoading(true);
            isEditMode
                ? EditDownloadService(id, tempContent, postAndEditDownloadCallback)
                : PostDownloadService(tempContent, postAndEditDownloadCallback);
        }
    }

    const postAndEditDownloadCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setDownloadId(resultData);
            goToNextStep(isEditMode ? downloadId : resultData);
        } else setMessageClassModal(messageClassModal);
    }

    const deleteButtonClicked = (selectedRowId) => DeleteDownloadService(selectedRowId, deleteDownloadCallback);

    const deleteDownloadCallback = (resultData, result, messageClass) => {
        if (!result.data.hasError)
            closeModalClicked();
        else setMessageClassModal(messageClass);
    }

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText(isEditMode ? 'adminPanel.download.editFile' : 'adminPanel.download.newFile')}
            showModal={true}
            noSpace={true}
            closeModal={closeModalClicked}>

            <NsLoadingProgress visible={isLoading} />

            <NsStepperWithActionButtons stepList={steps}
                orientation={CommonHelper.isMobileDevice() ? 'vertical' : 'horizontal'}
                activeStep={isEditMode ? steps.length : stepIndex}
                currentStep={stepIndex}>
                {stepIndex === 0
                    ? <ApBaseDownloadNewFileContent selectedRowId={downloadId}
                        nextButtonTriggerDate={nextButtonTriggerDate}
                        isEditMode={isEditMode}
                        isUsedInDownload={true}
                        serviceCall={() => GetDownloadByIdService(downloadId, getDownloadByIdCallback)}
                        deleteButtonClicked={deleteButtonClicked}
                        modalDetail={multiLanguageFileContent}
                        saveButtonClicked={saveButtonClicked}
                        showValidationDate={showValidationDate}
                        closeModalClicked={closeModalClicked} />
                    : <ApDownloadAttachFileContent nextButtonTriggerDate={nextButtonTriggerDate}
                        downloadId={downloadId} />}
            </NsStepperWithActionButtons>
            {messageClassModal}
        </NsModal>
    )
}

export default React.memo(ApDownloadModal);