import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import { GetStatusQueueService, GetOrderQueueStatusByServerNameService } from '../../../../../services/adminPanel/AdminOrderService';
import UpBaseQueueOrdersItem from '../../../../profileSettings/contents/userOrder/queue/bases/UpBaseQueueOrdersItem';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApShareSheetOrderModal from '../shareSheets/ApShareSheetOrderModal';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import DateHelper from '../../../../../utilities/DateHelper';

const ApOrderQueue = (props) => {

    const { fromDate, toDate, refreshDate } = props;
    const [ordersQueue, setOrdersQueue] = useState([]);
    const [orderQueueStatusByServerName, setOrderQueueStatusByServerName] = useState([]);
    const [selectedOrderQueue, setSelectedOrderQueue] = useState(null);
    const [selectedOrderQueueByServerName, setSelectedOrderQueueByServerName] = useState(null);
    const [isLoadingForOrdersQueue, setIsLoadingForOrdersQueue] = useState(false);
    const [isLoadingForOrdersQueueByServerName, setIsLoadingForOrdersQueueByServerName] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState((<></>));

    useEffect(() => {
        setSelectedOrderQueue(null);
        setSelectedOrderQueueByServerName(null);
        getStatusQueue();
    }, [fromDate, toDate, refreshDate])

    const getStatusQueue = () => {

        const statusQueueByServer = hasAccess([UserClaimEnumerations.getApStatusQueueByServerName]);
        let parameters = {
            FromDate: fromDate,
            ToDate: toDate ? toDate : DateHelper.getDateWithEndOfDayTime(new Date())
        }
        setIsLoadingForOrdersQueue(true);
        setIsLoadingForOrdersQueueByServerName(statusQueueByServer);
        setOrdersQueue([]);
        setOrderQueueStatusByServerName([]);
        GetStatusQueueService(parameters, getStatusQueueCallback);

        statusQueueByServer && GetOrderQueueStatusByServerNameService(parameters, getOrderQueueStatusByServerNameCallback);
    }

    const getStatusQueueCallback = (resultData, result, messageClassModal) => {
        setIsLoadingForOrdersQueue(false);
        if (!result.data.hasError) {
            setOrdersQueue(resultData);
        } else
            setResultMessageClass(messageClassModal);
    }

    const getOrderQueueStatusByServerNameCallback = (resultData, result, messageClassModal) => {
        setIsLoadingForOrdersQueueByServerName(false);
        if (!result.data.hasError) {
            setOrderQueueStatusByServerName(resultData);
        } else
            setResultMessageClass(messageClassModal);
    }

    const handlers = {
        orderQueueClicked: (index) => {
            setSelectedOrderQueue(ordersQueue[index]);
            setShowOrderModal(true);
        },

        orderQueueStatusByServerNameClicked: (index) => {
            setSelectedOrderQueueByServerName(orderQueueStatusByServerName[index]);
            setShowOrderModal(true);
        },

        closeApShareSheetOrderDetailModal: () => {
            setShowOrderModal(false);
            setSelectedOrderQueue(null);
            setSelectedOrderQueueByServerName(null);
        },
    }

    const { closeApShareSheetOrderDetailModal, orderQueueClicked, orderQueueStatusByServerNameClicked } = handlers;

    return (
        <Row className='g-3 mx-2'>
            <span>{CommonHelper.stringFormatWithBold(getTranslatedRawText('adminPanel.orders.orderQueue'), getTranslatedRawText('adminPanel.dataGridColumn.lastStatus'))}</span>

            <UpBaseQueueOrdersItem queues={ordersQueue}
                isLoading={isLoadingForOrdersQueue}
                orderQueueClicked={orderQueueClicked} />

            <div>{CommonHelper.stringFormatWithBold(getTranslatedRawText('adminPanel.orders.orderQueueStatusByServerName'), getTranslatedRawText('adminPanel.orders.serverName'))}</div>

            <UpBaseQueueOrdersItem queues={orderQueueStatusByServerName}
                isLoading={isLoadingForOrdersQueueByServerName}
                orderQueueClicked={orderQueueStatusByServerNameClicked} />

            {showOrderModal && !isEmpty(selectedOrderQueue) &&
                <ApShareSheetOrderModal showModal={true}
                    isQueue={true}
                    searchData={{
                        lastStatuses: { id: selectedOrderQueue.sharedSheetOrderStateType },
                        FromDate: fromDate,
                        ToDate: toDate
                    }}
                    closeModal={closeApShareSheetOrderDetailModal} />
            }

            {showOrderModal && !isEmpty(selectedOrderQueueByServerName) &&
                <ApShareSheetOrderModal showModal={true}
                    isQueue={true}
                    searchData={{
                        ServerNameFileRegulator: selectedOrderQueueByServerName.serverNameFileRegulator ? selectedOrderQueueByServerName.serverNameFileRegulator : null,
                        NotProcessedOrdersOnly: isEmpty(selectedOrderQueueByServerName.serverNameFileRegulator) ? true : false,
                        FromDate: fromDate,
                        ToDate: toDate
                    }}
                    serverName={selectedOrderQueueByServerName?.serverNameFileRegulator}
                    closeModal={closeApShareSheetOrderDetailModal} />
            }

            {resultMessageClass}

        </Row>
    );
}

export default React.memo(ApOrderQueue);