import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsCircularProgress from '../../../../components/circularProgress/NsCircularProgress';
import NsSelect from '../../../../components/dropdowns/NsSelect';
import NsCheckbox from '../../../../components/inputs/NsCheckbox';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../configs/Enumerations';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import EnumerationTranslationHelper from '../../../../utilities/EnumerationTranslationHelper';
import OrderHelper from '../../../../utilities/OrderHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

class BillBookModalBasicOption extends React.Component {

    valueChanged = (value, isValid, valueName, isNumberOfCopy) => CommonHelper.safeFunctionCall(this.props.valueChanged, value, isValid, valueName, isNumberOfCopy);

    getNumberOfCopyObject = (value) => OrderHelper.getBillBookCopies(this.props.routeId).find(item => item.id === value);

    render() {
        const { billBookCopy, billBookPrintColor, billBookPrintColorOptions, billBookBindingType,
            billBookBindingSideType, billBookUnderHandType, isLoadingDropdown, hasUnderhand } = this.props;
        return (
            <>
                <Row className="extended-service__tour__bill-book__inputs">
                    <Col lg={2}>
                        <NsSelect label={getTranslatedRawText('regularOrder.billBook.billBookCopy')}
                            valueChanged={(value, isValid) => {
                                this.valueChanged(this.getNumberOfCopyObject(value), isValid, { billBookCopy }, true)
                            }}
                            options={
                                OrderHelper.getBillBookCopies(this.props.routeId)
                                    ? OrderHelper.getBillBookCopies(this.props.routeId).map(item => ({
                                        ...item,
                                        name: EnumerationTranslationHelper.getTranslateBookNumberOfCopyType(item.billBookCopyType)?.label,
                                        icon: EnumerationTranslationHelper.getTranslateBookNumberOfCopyType(item.billBookCopyType)?.iconClassName
                                    }))
                                    : []
                            }
                            selectedItem={billBookCopy?.id} />
                    </Col>

                    <Col lg={3} className='position-relative align-self-end'>
                        <NsCircularProgress size={24}
                            visible={isLoadingDropdown} />
                        <NsSelect label={getTranslatedRawText('regularOrder.billBook.billBookPrintColor')}
                            valueChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { billBookPrintColor })}
                            options={billBookPrintColorOptions.map(item => ({ ...item, name: EnumerationTranslationHelper.getTranslateBookNumberOfPrintColorType(item.billBookPrintColorType) }))}
                            selectedItem={billBookPrintColor}
                            disabled={isEmpty(billBookCopy)} />
                        {/* } */}
                    </Col>

                    <Col lg={2}>
                        <NsSelect label={getTranslatedRawText('regularOrder.billBook.billBookBindingType')}
                            valueChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { billBookBindingType })}
                            options={ConstantsWithTranslation.getBillBookBindingType()}
                            selectedItem={billBookBindingType}
                        // disabled={!billBookPrintColor} 
                        />
                    </Col>

                    {!!billBookBindingType && billBookBindingType !== Enumerations.billBookBindingType.free &&
                        <>
                            <Col lg={2}>
                                <NsSelect label={getTranslatedRawText('regularOrder.billBook.billBookBindingSideType')}
                                    valueChanged={(value, isValid) =>
                                        this.valueChanged(value, isValid, { billBookBindingSideType })}
                                    options={ConstantsWithTranslation.getBillBookBindingSideType()}
                                    className="extended-service__tour__bill-book__binding-side"
                                    selectedItem={billBookBindingSideType} />
                            </Col>
                            {billBookUnderHandType === Enumerations.billBookUnderHandType.optional && billBookBindingType === Enumerations.billBookBindingType.stapler &&
                                <Col lg={2} className='align-self-end mt-2' >
                                    <NsCheckbox label={getTranslatedRawText('regularOrder.billBook.hasUnderHand')}
                                        onChange={(checked) => this.valueChanged(checked, false, { hasUnderhand })}
                                        value={hasUnderhand} />
                                </Col>
                            }
                        </>
                    }
                </Row>
                {!OrderHelper.getBillBookCopies(this.props.routeId) &&
                    <div className='red-color d-flex justify-content-center align-items-center pt-5'>
                        <span className='px-2'>{getTranslatedRawText('regularOrder.billBook.billBookCopiesErrorText')}</span>
                        <i className='ns-icon-admin-users h1 '></i>
                    </div>
                }
            </>
        );
    }
}

export default BillBookModalBasicOption;