import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetCashbackForecastService, GetCashbackPlansService, GetCurrentCashbackInfoService } from '../../../../../services/ProfileSetting/UserDashboardService';
import { GetApDaCbPlansService, GetApDaUCbForeCastService, GetApDaUCCInfoService } from '../../../../../services/adminPanel/AdminDashboardService';
import ApBaseUserInfoModal from '../../../../adminPanel/managements/users/bases/ApBaseUserInfoModal';
import ApBaseMapActionButtons from '../../../../adminPanel/bases/ApBaseMapActionButtons';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseLabelInfo from '../../../../adminPanel/bases/ApBaseLabelInfo';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../../UserPanelWrapper';
import UpDashboardCashbackCalculationBasis from './UpDashboardCashbackCalculationBasis';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import UpDashboardCashbackStepperPlans from './UpDashboardCashbackStepperPlans';
import UpDashboardCashbackHistory from './UpDashboardCashbackHistory';

export class CashBackContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cashbackPlans: [],
            cashbackForecast: null,
            currentCashbackInfo: null,
            showUserDetailModal: false,
            isLoading: false,
            resultMessageClass: <></>,
        };
    }

    componentDidMount = () => this.callCashbackServices();

    callCashbackServices = () => {
        const { userId, detailLedgerId, isUsedInAdminPanel } = this.props;
        this.setState({ isLoading: true });
        if (isUsedInAdminPanel) {
            GetApDaUCbForeCastService(userId || detailLedgerId, this.getCashbackForecastCallback);
            GetApDaUCCInfoService(this.getCurrentCashbackInfoCallback);
            GetApDaCbPlansService(userId || detailLedgerId, this.getCashbackPlansCallBack);
        } else {
            GetCashbackPlansService(this.getCashbackPlansCallBack);
            GetCashbackForecastService(this.getCashbackForecastCallback);
            GetCurrentCashbackInfoService(this.getCurrentCashbackInfoCallback);
        }
    }

    getCashbackPlansCallBack = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false })
        if (!result.data.hasError) this.setState({ cashbackPlans: resultData })
        else this.setState({ resultMessageClass })
    }

    getCashbackForecastCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false })
        if (!result.data.hasError) this.setState({ cashbackForecast: resultData })
        else this.setState({ resultMessageClass })
    }

    getCurrentCashbackInfoCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false })
        if (!result.data.hasError) this.setState({
            currentCashbackInfo: resultData
        })
        else this.setState({ resultMessageClass })
    }

    toggleUserInfoModal = () => this.setState({ showUserDetailModal: !this.state.showUserDetailModal });

    headerButtons = () => ([
        !isEmpty(this.state?.cashbackForecast) && hasAccess([UserClaimEnumerations.getApCustomerById]) && { startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: this.toggleUserInfoModal },
    ])

    render() {
        const { resultMessageClass, cashbackPlans, cashbackForecast, currentCashbackInfo, isLoading, showUserDetailModal } = this.state;
        const { isUsedInAdminPanel } = this.props;

        return (
            <>{isUsedInAdminPanel &&
                <>
                    <ApBaseMapActionButtons actionButtons={this.headerButtons()}
                        isLoading={isLoading} />
                    <div className='background-color p-2 mb-2'>
                        <ApBaseLabelInfo info={{
                            orderItemInfoDto: {
                                userFullName: cashbackForecast?.fullName,
                                mobile: cashbackForecast?.phoneNumber,
                                detailLedgerId: cashbackForecast?.detailLedgerId,
                            }
                        }}
                            isLoading={isLoading}
                            hasAccessInfo={hasAccess([UserClaimEnumerations.getApCustomerById])} />
                    </div>
                </>
            }
                <div className='px-2'>

                    <UpDashboardCashbackStepperPlans isLoading={isLoading}
                        currentCashbackInfo={currentCashbackInfo}
                        cashbackForecast={cashbackForecast}
                        cashbackPlans={cashbackPlans} />

                    <Row className='gap-3 mx-1'>
                        <Col className='wrapper-white gap-2'>
                            <UpDashboardCashbackCalculationBasis isLoading={isLoading} />
                        </Col>
                        <Col lg={8} className='wrapper-white gap-2'>
                            <UpDashboardCashbackHistory isLoading={isLoading}
                                isUsedInAdminPanel={isUsedInAdminPanel}
                                userId={cashbackForecast?.userId}
                                detailLedgerId={cashbackForecast?.detailLedgerId}
                                currentCashbackInfo={currentCashbackInfo}
                                cashbackForecast={cashbackForecast}
                                cashbackPlans={cashbackPlans} />
                        </Col>
                    </Row>

                    {showUserDetailModal &&
                        <ApBaseUserInfoModal showModal={showUserDetailModal}
                            selectedUserId={cashbackForecast?.userId}
                            closeModal={this.toggleUserInfoModal} />
                    }

                    {resultMessageClass}
                </div>
            </>
        )
    }
}

export default class UpDashboardCashback extends Component {
    render() {
        return (
            <UserPanelWrapper titleName={getTranslatedRawText('profile.cashback.cashback')}
                titleIcon='ns-icon-discount cu-ch'>
                <CashBackContent />
            </UserPanelWrapper>
        )
    }
}
