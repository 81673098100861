const FileManagerTranslationFa = {
    fileManager: {
        root: "پوشه اصلی",
        admin: {
            announcement: 'اطلاعیه‌ها',
            blog: 'وبلاگ',
            blogCategory: 'دسته‌بندی وبلاگ',
            news: 'اخبار',
            newsCategory: 'دسته‌بندی اخبار',
            promotion: 'تبلیغ‌ها',
            slider: 'اسلایدر',
        },
        common: {
            undoAllChanges: 'برگشت به حالت اولیه',
            fileName: 'نام',
            dimension: 'ابعاد',
            fileSize: 'حجم',
            fileType: 'نوع فایل',
            file: 'فایل',
            folder: 'پوشه'
        },
        headerActionButtons: {
            newFolder: 'ایجاد پوشه',
            uploadFile: 'بارگذاری فایل',
            delete: 'حذف',
            deleteSelectedFiles: 'حذف فایل‌های انتخابی',
            rename: 'تغییر نام',
            refresh: 'به روزرسانی',
            editFile: 'ویرایش فایل',
            selectAllFiles: 'انتخاب همه',
            deselectAllFiles: 'لغو انتخاب همه'
        },
        headerUsageSpace: {
            usedCapacity: '{0} مگابایت از  {1} مگابایت',
        },
        mainSectionDetail: {
            preview: 'پیش نمایش',
            noItemSelected: 'آیتمی انتخاب نشده است',
            detail: 'جزئیات:',
            fileName: 'نام:',
            folderName: 'نام:',
            resolution: 'رزولوشن:',
            dimension: 'ابعاد:',
            fileSize: 'حجم:',
            editFile: 'ویرایش فایل',
            fileDimension: '{0}×{1} میلیمتر',
            fileResolution: '{0} DPI'
        },
        modals: {
            create: {
                inputLabel: 'نام پوشه',
                enterInput: 'نام پوشه خود را وارد کنید',
                submit: 'ایجاد',
                title: 'ایجاد پوشه جدید ',
            },
            uploadFile: {
                title: 'بارگذاری فایل',
                closeModalAlert: 'در صورت بستن این پنجره، فایل‌های در حال آپلود از بین خواهند رفت. آیا از بستن این پنجره مطمئن هستید؟'
            },
            rename: {
                title: 'تغییر نام ({0})',
                inputLabel: 'نام جدید',
                enterInput: 'نام جدید را وارد کنید',
                submit: 'تغییر',
                isLoading: 'درحال بازیابی',
            },
            editFile: {
                position: 'جانمایی',
                title: 'ویرایش فایل ({0})',
                resize: 'تغییر سایز',
                rotate: 'چرخش',
                rotate90: '90 درجه ساعتگرد',
                rotate270: '90 درجه پاد ساعتگرد',
                rotate180: '180 درجه ساعتگرد',
                flip: 'قرینه',
                horizontallyFlip: 'افقی',
                verticallyFlip: 'عمودی',
                width: 'طول',
                height: 'ارتفاع',
                mm: 'میلیمتر',
                apply: 'اعمال'
            }
        },
        tour: {
            PoFileManager: 'در اینجا شما میتوانید فایل‌های خود را مدیریت کرده و فایل جدید بارگذاری کنید.',
            usageSpace: 'فضای قابل استفاده ',
            breadcrumbButtons: 'برای بارگذاری فایل کلیک کنید',
            selectedItemPreview: 'در این قسمت شما پیش نمایشی از فایل خود را مشاهده می‌نمایید',
            actionButton: ' در این قسمت شما میتوانید فایل خود را حذف کنید ، ویرایش کنید و تغییر نام دهید'
        }
    }
};
export default FileManagerTranslationFa;