import React, { useEffect, useState } from "react";

import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import NsAdminPanelContentWrapper from "../../../../components/cards/NsAdminPanelContentWrapper";
import { GetFestivalsService } from "../../../../services/adminPanel/AdminFestivalsService";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import CommonHelper from "../../../../utilities/CommonHelper";
import ApFestivalDetailModal from "./ApFestivalDetailModal";
import Enumerations from "../../../../configs/Enumerations";
import ApFestivalSearch from "./ApFestivalSearch";

const ApFestival = () => {

    const columns = [
        hasAccess([UserClaimEnumerations.getApFestivalById]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => festivalDetailClicked(id),
            }
        },
        {
            field: 'name',
            headerName: getTranslatedRawText('adminPanel.festivals.festivalName'),
            width: 400
        },
        {
            field: 'festivalStatusType',
            headerName: getTranslatedRawText('adminPanel.festivals.festivalStatus'),
            width: 150,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (row) => {
                    return <span>{ConstantsWithTranslation.getFestivalStatusById(row?.festivalStatusType)}</span>
                }
            }
        },
        {
            field: 'codeCount',
            headerName: getTranslatedRawText('adminPanel.festivals.codeCount'),
            width: 150,
            dataType: Enumerations.gridType.number,
        },
        {
            field: 'prizeCount',
            headerName: getTranslatedRawText('adminPanel.festivals.prizesCount'),
            width: 150,
            dataType: Enumerations.gridType.number,
        },
        {
            field: 'startDate',
            headerName: getTranslatedRawText('adminPanel.festivals.startDate'),
            width: 140,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'endDate',
            headerName: getTranslatedRawText('adminPanel.festivals.endDate'),
            width: 140,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'executionDate',
            headerName: getTranslatedRawText('adminPanel.festivals.executionDate'),
            width: 140,
            dataType: Enumerations.gridType.dateTime
        },
    ];

    const [showFestivalDetailModal, setShowFestivalDetailModal] = useState(false);
    const [selectedFestivalId, setSelectedFestivalId] = useState(0);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchData, setSearchData] = useState({});

    useEffect(() => {
        setCallServiceDate(new Date());
        setIsLoading(true);
    }, [searchData]);

    const festivalDetailClicked = (id) => {
        setSelectedFestivalId(id);
        setShowFestivalDetailModal(true);
    }

    const closeApFestivalDetailModal = () => setShowFestivalDetailModal(false);

    const searchClicked = (data) => setSearchData(data);

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.festivals")}
            icon={'ns-icon-festivals h1'}>

            <ApFestivalSearch searchClicked={searchClicked}
                isLoading={isLoading} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetFestivalsService}
                parameters={{
                    name: searchData.festivalName,
                    festivalStatusType: searchData.festivalStatus?.id,
                    fromStartDate: searchData.startDateFrom,
                    toStartDate: searchData.startDateTo,
                    fromEndDate: searchData.endDateFrom,
                    toEndDate: searchData.endDateTo,
                    fromExecutionDate: searchData.executionDateFrom,
                    toExecutionDate: searchData.executionDateTo,
                }}
                columnDefs={CommonHelper.clearArrayFromEmptyItem(columns)}
                hasPagination={true}
                getData={getData} />

            {showFestivalDetailModal &&
                <ApFestivalDetailModal festivalId={selectedFestivalId}
                    closeModal={closeApFestivalDetailModal} />
            }

        </NsAdminPanelContentWrapper>
    );

}

export default React.memo(ApFestival);