import React from 'react';
import { connect } from 'react-redux';

import NsAdvanceSearch from '../../../../../../components/advanceSearch/NsAdvanceSearch';
import NsButton from '../../../../../../components/buttons/NsButton';
import NsSearchInput from '../../../../../../components/inputs/NsSearchInput';
import Enumerations from '../../../../../../configs/Enumerations';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { GetStoreProductNameService } from '../../../../../../services/adminPanel/AdminStoreOrderService';

class UpBaseStoreOrderSearchSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            invoiceNumber: '',
            fromSubmitTime: null,
            toSubmitTime: null,
            selectedProducts: [],
            products: [],
            isLoading: false,
            resultMessageClass: (<></>),
        }
    }

    getSearchOptions = () => {
        let searchFields = [
            {
                type: Enumerations.advanceSearchFieldType.numberInput,
                label: getTranslatedRawText('profile.dataGridColumns.invoiceNumber'),
                fieldName: 'invoiceNumber',
                value: this.state.invoiceNumber,
                autoFocus: true,
                onChange: (value) => {
                    this.valueChanged(value, { invoiceNumber: this.state.invoiceNumber })
                },
                sortId: 1
            },
            {
                type: Enumerations.advanceSearchFieldType.autoComplete,
                label: getTranslatedRawText('profile.fileCorrection.product'),
                fieldName: 'selectedProducts',
                value: this.state.selectedProducts,
                items: this.state.products || [],
                onChange: (value) => {
                    this.valueChanged(value, { selectedProducts: this.state.selectedProducts })
                },
                sortId: 3
            },
            {
                type: Enumerations.advanceSearchFieldType.datePicker,
                label: getTranslatedRawText('profile.allOrders.submitTimeFrom'),
                fieldName: 'fromSubmitTime',
                value: this.state.fromSubmitTime,
                onChange: (value) => {
                    this.valueChanged(value, { fromSubmitTime: this.state.fromSubmitTime })
                },
                sortId: 7
            },
            {
                type: Enumerations.advanceSearchFieldType.datePicker,
                label: getTranslatedRawText('profile.allOrders.submitTimeTo'),
                fieldName: 'toSubmitTime',
                value: this.state.toSubmitTime,
                onChange: (value) => {
                    this.valueChanged(value, { toSubmitTime: this.state.toSubmitTime })
                },
                sortId: 8
            }
        ];

        return searchFields.sort((itemPrev, itemNext) => itemPrev.sortId - itemNext.sortId);
    }

    valueChanged = (value, valueStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        this.setState({ [valueName]: value });
    }

    searchTextChanged = (value) => this.setState({ searchTerm: value });

    searchFormClicked = () => {
        const { invoiceNumber, selectedProducts, fromSubmitTime, toSubmitTime } = this.state;
        let searchParameters = {
            nasInvoiceIds: !!invoiceNumber ? [Number(invoiceNumber)] : null,
            storeProductId: !!selectedProducts?.key ? selectedProducts?.key : null,
            submitTimeFrom: fromSubmitTime,
            submitTimeTo: toSubmitTime,
        };
        CommonHelper.safeFunctionCall(this.props.searchFormClicked, searchParameters)
    };

    clearClicked = () => {
        this.setState({
            invoiceNumber: '',
            fromSubmitTime: null,
            toSubmitTime: null,
            selectedProducts: [],
        }, () => {
            CommonHelper.safeFunctionCall(this.props.searchFormClicked, {});
        });
    };

    componentDidMount = () => this.getStoreProductsList();

    getStoreProductsList = () => {
        this.setState({ isLoading: true });
        GetStoreProductNameService(this.getStoreProductsListCallback);
    };

    getStoreProductsListCallback = (resultData, result) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) this.setState({ products: resultData });
        else this.setState({ resultMessageClass: messageClassModal });

    };

    render() {
        const { invoiceNumber, fromSubmitTime, toSubmitTime, selectedProducts, } = this.state;
        const { autoFocus, children, isLoading, } = this.props;

        const searchValues = { invoiceNumber, fromSubmitTime, toSubmitTime, selectedProducts }
        const searchOptions = CommonHelper.clearArrayFromEmptyItem(this.getSearchOptions());
        return (
            <>
                <NsAdvanceSearch searchOptions={searchOptions}
                    autoFocus={autoFocus}
                    searchValues={searchValues} />
                <div className='d-flex flex-wrap justify-content-between align-items-end'>

                    <div className='d-flex flex-row-reverse my-2 gap-1'>
                        {children}
                        <NsButton className='secondary-outline'
                            label='common.clear'
                            startIcon='ns-icon-clear'
                            loading={isLoading}
                            onClick={this.clearClicked} />
                        <NsButton className='primary'
                            label={getTranslatedRawText('profile.orderReport.search')}
                            startIcon='ns-icon-search'
                            loading={isLoading}
                            onClick={this.searchFormClicked} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({ categories: state.categories.categories });

export default connect(mapStateToProps)(UpBaseStoreOrderSearchSection);