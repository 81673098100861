import EnumerationTranslationHelper from "../../../../../utilities/EnumerationTranslationHelper";
import ConstantsWithTranslation from "../../../../../configs/ConstantsWithTranslation";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import Enumerations from "../../../../../configs/Enumerations";
import CommonHelper from "../../../../../utilities/CommonHelper";

export const createShippingListColumns = (detailViewClicked) => [
    hasAccess([UserClaimEnumerations.getApShippingById]) && {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: 80,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasDetail: true,
            detailClicked: (id) => detailViewClicked(id),
        }
    },
    {
        field: 'userFullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 150
    },
    {
        field: 'requestNumber',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.shippingRequestNumber'),
        dataType: Enumerations.gridType.number,
        width: 180,
    },
    {
        field: 'estimatedDeliveryToShippingMethod',
        headerName: getTranslatedRawText('adminPanel.shippings.estimatedDeliveryTimeToShippingMethod'),
        width: 250,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'shippingPlanTypeId',
        headerName: getTranslatedRawText('adminPanel.shippings.shippingPlan'),
        width: 120,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className="d-flex" >
                    {EnumerationTranslationHelper.getShippingPlanType(props?.shippingPlanTypeId)}
                </div >
            },
        }
    },
    {
        field: 'shippingName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.shippingMethod'),
        width: 150,
    },
    {
        field: 'orderQuantity',
        headerName: getTranslatedRawText('adminPanel.shippings.orderCount'),
        width: 120,
    },
    {
        field: 'orderDetailSeriesCount',
        headerName: getTranslatedRawText('adminPanel.shippings.orderDetailSeriesCount'),
        width: 210,
    },
    {
        field: 'transferStateType',
        headerName: getTranslatedRawText('adminPanel.shippings.shippingLastStatus'),
        width: 150,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className="d-flex" >
                    {ConstantsWithTranslation.getDeliveryRequestStateType(props?.transferStateType)}
                </div >
            },
        }
    },
    {
        field: 'shippingLastStatusTime',
        headerName: getTranslatedRawText('adminPanel.shippings.shippingLastStatusTime'),
        width: 200,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'requestDate',
        headerName: getTranslatedRawText('adminPanel.shippings.shippingRequestTime'),
        width: 200,
        dataType: Enumerations.gridType.dateTime,
    },
];

export const createShippingDetailcolumns = (detailViewClicked) => [
    hasAccess([UserClaimEnumerations.getApOrderDetail]) && {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: 80,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasDetail: true,
            detailClicked: (id) => detailViewClicked(id)
        }
    },

    {
        field: 'userFullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 120
    },
    {
        field: 'nasInvoiceId',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber'),
        width: 120,
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'lastStatusType',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.lastStatus'),
        width: 130,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className={`${CommonHelper.getShareSheetStateColor(props?.lastStatusType)}`}>
                    {ConstantsWithTranslation.getSharedSheetOrderStateType(props?.lastStatusType)}
                </div>
            },
        }
    },
    {
        field: 'lastStatusTime',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.statusDate'),
        width: 150,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'productName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
        width: 135
    },
    {
        field: 'circulation',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.circulation'),
        width: 110,
        dataType: Enumerations.gridType.circulation
    },
    {
        field: 'twoSidePrintingType',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.printedSide'),
        width: 120, dataType: Enumerations.gridType.printSide
    },
    {
        field: 'turnaroundType',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.turnaround'),
        width: 110, dataType: Enumerations.gridType.turnaround
    },
    {
        field: 'totalQuantity',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.quantity'),
        width: 130,
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'submitTime',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
        width: 120,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'totalPrice',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceExcVat'),
        width: 140,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'priceInclusiveOfVat',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceIncVat'),
        width: 140,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'discountAmount',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.discount'),
        width: 120,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'paymentInAdvance',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentInAdvance'),
        width: 170,
        dataType: Enumerations.gridType.price,
    },
];

export const createShippingQueueEstimatedTimeColumns = (detailViewClicked) => [
    hasAccess([UserClaimEnumerations.getApShippingById]) && {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: 80,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasDetail: true,
            detailClicked: (id) => detailViewClicked(id),
        }
    },
    {
        field: 'remainingToEstimatedDeliveryToShippingMethodDay',
        headerName: getTranslatedRawText('adminPanel.shippings.remainingToEstimatedSendingTimeToShippingmethod'),
        width: 250,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className={`${CommonHelper.getOrderQueueRemainingDaysByEstimateTime(props?.remainingToEstimatedDeliveryToShippingMethodDay)}`}>
                    {props?.remainingToEstimatedDeliveryToShippingMethodLabel}
                </div>
            },
        }
    },
    {
        field: 'estimatedDeliveryToShippingMethod',
        headerName: getTranslatedRawText('adminPanel.shippings.estimatedDeliveryTimeToShippingMethod'),
        width: 250,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'transferStateType',
        headerName: getTranslatedRawText('adminPanel.shippings.shippingLastStatus'),
        width: 150,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className="d-flex" >
                    {ConstantsWithTranslation.getDeliveryRequestStateType(props?.transferStateType)}
                </div >
            },
        }
    },
    {
        field: 'shippingPlanTypeId',
        headerName: getTranslatedRawText('adminPanel.shippings.shippingPlan'),
        width: 120,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className="d-flex" >
                    {EnumerationTranslationHelper.getShippingPlanType(props?.shippingPlanTypeId)}
                </div >
            },
        }
    },
    {
        field: 'shippingName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.shippingMethod'),
        width: 150,
    },
    {
        field: 'orderQuantity',
        headerName: getTranslatedRawText('adminPanel.shippings.orderCount'),
        width: 120,
    },
    {
        field: 'orderDetailSeriesCount',
        headerName: getTranslatedRawText('adminPanel.shippings.orderDetailSeriesCount'),
        width: 210,
    },
    {
        field: 'userFullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 150
    },
    {
        field: 'requestNumber',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.shippingRequestNumber'),
        dataType: Enumerations.gridType.number,
        width: 180,
    },
    {
        field: 'shippingLastStatusTime',
        headerName: getTranslatedRawText('adminPanel.shippings.shippingLastStatusTime'),
        width: 200,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'requestDate',
        headerName: getTranslatedRawText('adminPanel.shippings.shippingRequestTime'),
        width: 200,
        dataType: Enumerations.gridType.dateTime,
    },
];
