import React from 'react';
import { Helmet } from "react-helmet";

import Config from '../../configs/Config';
import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../utilities/ConfigHelper';
import { getCurrentCulture, getTranslatedRawText } from '../../utilities/CultureHelper';
import DateHelper from '../../utilities/DateHelper';
import StorageHelper from '../../utilities/StorageHelper';
import { isEmpty } from '../../utilities/ValidationHelper';

class NsReachResult extends React.Component {

    constructor(props) {
        super(props);
        this.currentAbbreviation = StorageHelper.getFromStorage(Enumerations.storage.userCurrency)?.currentCurrency?.abbreviation;
        this.defaultAbbreviation = Config.defaultCurrency.type.en;
    }

    getReachResultType = () => {
        let currency = this.currentAbbreviation || this.defaultAbbreviation;
        switch (this.props.reachResultType) {
            case Enumerations.reachResultType.website: return webSiteReachResult();
            case Enumerations.reachResultType.itemListProducts: return productsListItemReachResult({ ...this.props, currency: currency });
            case Enumerations.reachResultType.breadCrumbs: return productsBreadCrumbsReachResult({ ...this.props });
            case Enumerations.reachResultType.product: return productReachResult({ ...this.props, currency: currency });
            case Enumerations.reachResultType.faq: return FaqReachResult(this.props.faqs);
        }
    }

    render() {
        return !!Config.handleReachResult
            ? <Helmet>
                <script type="application/ld+json">
                    {this.getReachResultType()}
                </script>
            </Helmet>
            : null
    }
};

function webSiteReachResult() {
    let metaTags = getConfigObjectByCulture(Config.metaTags);
    let target = metaTags.url + getCurrentCulture() + "/products?page-number=1&search-input={search_term_string}&sort-by=3";
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": metaTags.ogSiteName,
        "url": metaTags.url,
        "image": metaTags.ogImage,
        "og:image": metaTags.ogImage,
        "description": metaTags.ogDescription,
        "og:description": metaTags.ogDescription,
        "potentialAction": {
            "@type": "SearchAction",
            "target": target,
            "query-input": "required name=search_term_string"
        }
    });
}

function productReachResult(props) {
    const { name, images, description, productUrl, productId, price, category, currency } = props;
    let metaTags = getConfigObjectByCulture(Config.metaTags);
    let pprSearchResult = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": name,
        "image": {
            "@type": "ImageObject",
            "url": isEmpty(images) ? Config.apiServer.baseUrl : Config.apiServer.baseUrl + images
        },
        "description": description,
        "category": category,
        "brand": {
            "@type": "Brand",
            "name": metaTags.ogSiteName
        },
        "sku": `"${productId}"`,
        "offers": {
            "@type": "Offer",
            "url": productUrl,
            "price": price,
            "priceCurrency": currency,
            "priceValidUntil": DateHelper.getFistDayOfNextMonth(),
            "availability": "https://schema.org/OnlineOnly",
            "itemCondition": "https://schema.org/NewCondition"
        }
    }

    return JSON.stringify(pprSearchResult);
}

function FaqReachResult(resultData) {

    let faqs = [];

    if (!isEmpty(resultData)) {
        let faqsData = resultData.staticPages;
        for (let i = 0; i < faqsData?.length; i++) {
            faqs.push({
                "@type": "Question",
                "name": faqsData[i]?.title,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": faqsData[i]?.mainContent
                }
            })
        }
    }

    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs
    });
}

function productsListItemReachResult(props) {
    const { products, primaryTitle, currency } = props;
    if (isEmpty(products)) return;
    let metaTags = getConfigObjectByCulture(Config.metaTags);
    let target = metaTags.url + getCurrentCulture() + "/products";
    let listItems = products.map((product, index) => {
        let productUrl = target + '/' + product.selectedId + '/regular/detail/' + CommonHelper.makeCorrectLink(product.name);
        let price = isEmpty(product?.printCirculations) ? "0" : product.printCirculations.find(item => item.key === product.selectedId)?.price
        let pprObj = {
            "@type": "ListItem",
            "position": index + 1,
            "url": productUrl,
            "name": product?.name,
            "image": isEmpty(product.imageUrl) ? '' : Config.apiServer.baseUrl + product?.imageUrl,
            "sku": product.selectedId,
            "offers": {
                "@type": "Offer",
                "url": productUrl,
                "availability": "https://schema.org/OnlineOnly",
                "itemCondition": "https://schema.org/NewCondition"
            }
        }
        if (!!price) {
            pprObj["offers"]["price"] = price;
            pprObj["offers"]["priceCurrency"] = currency;
            pprObj["offers"]["priceValidUntil"] = DateHelper.getFistDayOfNextMonth();
        }
        return pprObj;
    });

    return (JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "ItemList",
        "name": (getTranslatedRawText('product.common.breadcrumbs.products') + '-' + primaryTitle).toLowerCase(),
        "description": primaryTitle,
        "url": target,
        "numberOfItems": products?.length,
        "itemListElement": [
            listItems
        ]
    }));
}
function productsBreadCrumbsReachResult(props) {
    const { products } = props;
    if (isEmpty(products)) return;
    let metaTags = getConfigObjectByCulture(Config.metaTags);
    let target = metaTags.url + getCurrentCulture() + "/products";
    let listItems = products.map((product, index) => {
        let productUrl = target + '/' + product.selectedId + '/regular/detail/' + CommonHelper.makeCorrectLink(product.name);
        let price = isEmpty(product?.printCirculations) ? "0" : product.printCirculations.find(item => item.key === product.selectedId)?.price
        let pprObj = {
            "@type": "ListItem",
            "position": index + 1,
            "item": productUrl,
            "name": product?.name,
            "image": isEmpty(product.imageUrl) ? '' : Config.apiServer.baseUrl + product?.imageUrl,
        }
        return pprObj;
    });

    return (JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "name": (getTranslatedRawText('product.common.breadcrumbs.products')).toLowerCase(),
        "itemListElement": [
            listItems
        ]
    }));
}

export default NsReachResult;