import React from 'react';

import NsButton from '../../../../../components/buttons/NsButton';
import NsTypography from '../../../../../components/text/NsTypography';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { messageClass } from '../../../../../utilities/MessageModalHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import AddPunchModal from '../../../modals/AddPunchModal';

class PoExtendedServicePunch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            punchService: {},
            masterFiles: null,
            isShowAddPunchModal: false,
            resultMessageClass: (<></>),
            refreshExtendedServicesDate: props.refreshExtendedServicesDate,
        };
    }

    componentDidMount = () => this.prepareService();

    componentDidUpdate = (prevProps) => {
        if (prevProps.refreshExtendedServicesDate !== this.state.refreshExtendedServicesDate)
            this.prepareService();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshExtendedServicesDate !== prevState.refreshExtendedServicesDate)
            return ({ refreshExtendedServicesDate: nextProps.refreshExtendedServicesDate });
        return null;
    }

    prepareService = () => this.setState({ punchService: OrderHelper.getSpecificService(this.props.routeId, Enumerations.extendedServices.punch) });

    addPunchClicked = () => {
        if (OrderHelper.checkFrontFileExist(this.props.routeId)) {
            OrderHelper.getMasterFilesWithFullInfo(this.props.routeId, (masterFiles) => {
                this.setState({ isShowAddPunchModal: true, masterFiles: masterFiles[0].frontSideFileInfo });
            });
        } else
            this.setState({ resultMessageClass: messageClass(getTranslatedRawText('common.messages.selectFileError')) });
    }

    closeAddPunchModalClicked = () => this.setState({ isShowAddPunchModal: false });

    saveAddPunchModalClicked = (selectedPunch) => {
        OrderHelper.updateExtendedService(this.props.routeId, Enumerations.extendedServices.punch, selectedPunch)
        this.setState({ punchService: { ...this.state.punchService, selectedItem: selectedPunch }, isShowAddPunchModal: false });
        CommonHelper.safeFunctionCall(this.props.refreshSummary);
    }

    render() {
        const { punchService, isShowAddPunchModal, masterFiles } = this.state;
        const isPunchSelected = !isEmpty(punchService?.selectedItem);

        return (!isEmpty(punchService)
            ? <div className='d-flex align-items-center mb-2'>

                <NsTypography variant='inherit' className='font-size-14'>
                    {getTranslatedRawText('professionalOrder.extendedService.punch')}:
                </NsTypography>
                <NsButton startIcon='ns-icon-add black-color'
                    wrapperClassName='mx-1'
                    size='small'
                    onClick={this.addPunchClicked} />

                {isPunchSelected &&
                    <div className='font-size-12 ms-3'>
                        <b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(punchService.price)}</b>
                    </div>
                }

                {isShowAddPunchModal &&
                    <AddPunchModal showModal={isShowAddPunchModal}
                        masterFileInfo={masterFiles}
                        trimZone={OrderHelper.getTrimZone(this.props.routeId)}
                        productDesignTemplateUrl={OrderHelper.getProductDesignTemplateUrl(this.props.routeId)}
                        selectedPunch={punchService.selectedItem}
                        pointDiameter={punchService.pointDiameter}
                        punchPrice={punchService.price}
                        saveAddPunchModalClicked={this.saveAddPunchModalClicked}
                        closeAddPunchModalClicked={this.closeAddPunchModalClicked} />
                }

                {this.state.resultMessageClass}
            </div>

            : null
        );
    }
}

export default PoExtendedServicePunch;