import { getTranslatedRawText } from "../../../utilities/CultureHelper";

class HeaderTours {

    static getHeaderTour() {
        return ([
            {
                selector: '.header-navbar-shop',
                content: getTranslatedRawText('home.tour.shop'),
            },
            {
                selector: '.header-navbar-iOrderFrequently',
                content: getTranslatedRawText('home.tour.iOrderFrequently'),
            },
        ]);
    }
}

export default HeaderTours;