const CommonTranslationFa = {

    common: {
        and: 'و',
        categories: 'دسته‌بندی‌ها',
        category: 'دسته‌بندی‌',
        save: 'ذخیره',
        discard: 'انصراف',
        select: "انتخاب",
        remove: 'حذف',
        yes: 'بله',
        no: 'خیر',
        on: 'فعال',
        off: 'غیرفعال',
        add: 'افزودن',
        edit: 'ویرایش',
        ok: 'تایید',
        next: "بعدی",
        confirm: 'تایید',
        cancel: 'انصراف',
        img: 'تصویر',
        delete: 'حذف',
        filter: 'فیلتر',
        search: 'جستجو',
        pay: 'پرداخت',
        mm: 'میلیمتر',
        noDescription: 'بدون شرح',
        alert: 'هشدار',
        mb: 'مگابایت',
        kb: "کیلو بایت",
        help: 'راهنما',
        omanTrn: 'OM',
        clear: 'پاک کردن',
        exportExcel: 'خروجی اکسل',
        download: 'دانلود',
        showMore: 'نمایش بیشتر',
        showLess: 'نمایش کمتر',
        agree: 'خواندم وقبول می‌کنم',
        send: 'ارسال',
        readMore: 'بیشتر بخوانید',
        imageFormat: 'image',
        reset: 'بازنشانی',
        submit: 'ارسال',
        about: 'درباره',
        messages: {
            areYouSureQuestion: 'آیا از حذف «{0}» اطمینان دارید؟',
            areYouSureToDeleteDepartment: 'آیا از حذف چارت سازمانی {0} اطمینان دارید؟',
            areYouSureQuestionOrder: ' آیا از حذف سفارش «{0}» اطمینان دارید؟',
            areYouSureQuestionOrderWithoutParam: 'آیا از حذف سفارش(ها) اطمینان دارید؟',
            areYouSureQuestionOrderWithLength: 'آیا از حذف {0} سفارش اطمینان دارید؟',
            areYouSureQuestionSingleOrderWithoutParam: 'آیا از حذف سفارش اطمینان دارید؟',
            folderDeleteQuestion: 'تمامی فایل‌ها و پوشه‌های داخل این پوشه حذف خواهند شد، آیا از حذف پوشه {0} اطمینان دارید؟',
            areYouSureQuestionWithOutParams: 'آیا از حذف اطمینان دارید ؟',
            areYouSureUpdateMatchingStatusTypesQuestion: ' آیا از بروزرسانی تمام وضعیت تطابق ها اطمینان دارید؟',
            areYouSureMultiFileQuestionFileManager: 'آیا از حذف {0} اطمینان دارید؟',
            questionToGoProductDetail: 'با زدن دکمه تایید، اطلاعات سفارش جاری حذف و جزئیات محصول نمایش داده خواهد شد.',
            fileValidationError: 'نام فایل نمی تواند شامل این موارد باشد: \\ /?*:|"<>',
            folderValidationError: 'نام فولدر نمی تواند شامل این موارد باشد: \\ /?*:|"<>',
            chooseFileOrFolder: 'انتخاب فایل یا فولدر',
            noFileChosen: 'فایلی انتخاب نشده است, فایل را انتخاب کنید',
            somethingIsWrong: 'بروز مشکل، دوباره سعی کنید',
            selectFrontSide: 'ابتدا فایل رو را انتخاب کنید',
            discardNewFileMessage: 'آیا مایل به حذف فایل جدید هستید؟',
            preMessageForSignIn: 'برای شروع سفارش ابتدا وارد حساب کاربری شوید',
            messageBeforeSignIn: 'برای نمایش قیمتها ابتدا وارد حساب کاربری شوید',
            smallFilterSelected: 'فیلتر(های) {0} از حداقل ابعاد مجاز کوچکتر هست/هستند',
            duplicatedFilter: '(فیلتر {0} و {1})',
            duplicatedFilterText: 'فیلتر بی اثر را حذف کنید {0}',
            notAuthorizedAreaText: 'موقعیت پانچ را تغییر دهید.(موقعیت فعلی مجاز نیست)',
            duplicatedSerial: 'همپوشانی شماره سریال‌ها را برطرف کنید',
            enter: '{0} را وارد کنید',
            lengthCondition: 'تعداد حروف {0} باید بیشتر از {1} باشد',
            select: '{0} را انتخاب کنید',
            errorResponse: 'صفحه را مجددا اجرا کنید ({0})',
            cancelUploadingFile: 'بارگذاری فایل متوقف شد',
            areYouSureToCancelUploadingFile: 'آیا از لغو بارگذاری فایل اطمینان دارید؟',
            unTrustableFile: 'محتویات این فایل غیر قابل اعتماد است پنجره را بسته و مجدد باز کنید.',
            noFoldLineOption: 'نوع خط تا برای این محصول یافت نشد.',
            noPromotionOption: 'تبلیغی یافت نشد',
            tokenExpired: 'توکن شما منقضی شده است دوباره لاگین نمایید.',
            selectFileError: 'به منظور افزودن خدمات ، ابتدا فایل را آپلود نمایید.',
            foldLineDirectionError: 'جهت تغییر راستای خط تا، تمامی خط تا‌های موجود را پاک کنید. شما فقط میتوانید یک راستا را برای خط تا انتخاب کنید.',
            serialNumberDirectionError: 'جهت تغییر راستای شماره سریال، تمامی شماره سریال‌های موجود را پاک کنید. شما فقط میتوانید یک راستا را برای شماره سریال انتخاب کنید.',
            perforationDirectionError: 'جهت تغییر راستای خط پرفراژ، تمامی خط پرفراژ‌های موجود را پاک کنید. شما فقط میتوانید یک راستا را برای خط پرفراژ انتخاب کنید.',
            foldLineChangeTabError: 'برای تعیین خط تای پیشفرض، تمام خطوط تای قبلی را حذف نمایید.',
            masterFileIsNotSelected: 'فایل رو موجود نیست . ابتدا فایل رو سفارش را بارگذاری کنید.',
            masterFrontFileIsNotSelected: 'فایل اصلی رو انتخاب نشده است',
            fileIsNotSelected: 'فایلی برای آپلود انتخاب نشده است',
            allLastInfoIsReset: 'تمامی اطلاعات قبلی حذف خواهد شد',
            maxFoldLineMessage: 'انتخاب بیش از{0} خط تا ممنوع',
            wrongIdNumber: '{0} اشتباه است',
            notSupportedExtendedServices: 'این محصول دارای خدمات {0} می‌باشد که در حال حاضر قابل ارایه نیست',
            invalidContent: 'فیلدهای ضروری را پر کنید',
            successStoreOrderPaymentMessage: 'سفارش با موفقیت ثبت شد.',
            alertStoreOrderPaymentMessagePart1: 'در صورت پیدا نشدن سفارش خود به صفحه ی ',
            alertStoreOrderPaymentMessagePart2: ' مراجعه کنید.',
            doYouWantDisplayCommentToAll: 'آیا مایلید این نظر به اشتراک گذاشته شود؟',
            undisplayingCommentToAll: 'آیا از حالت نمایش به همه خارج شود؟',
            updateMatchingStatusQuestionMessage: 'آیا از آپدیت {0} ردیف وضعیت تطابق اطمینان دارید؟',
            orderNotReachedToPrintingState: 'سفارش هنوز به مرحله چاپ نرسیده است، به محض رسیدن سفارش به مرحله چاپ، فاکتور رسمی صادر خواهد شد.',
            userInformationIsNotCompeleted: 'با توجه به اینکه اطلاعات پروفایل به صورت کامل وارد نشده است، فاکتور رسمی برای این سفارش با اطلاعات ناقص صادر خواهد شد. این موضوع ممکن است مورد تایید سازمان امور مالیاتی کشور نباشد. فرصت برای تکمیل اطلاعات پروفایل و صدور فاکتور رسمی تا قبل از رسیدن این سفارش به مرحله چاپ فراهم شده است.',
            activateProfileQuestion: 'آیا از فعال‌سازی پروفایل "{0}" اطمینان دارید؟',
            deactivateProfileQuestion: 'آیا از غیرفعال‌سازی پروفایل "{0}" اطمینان دارید؟',
            userNotFoundTitle: 'متاسفانه کاربر مورد نظر یافت نشد :(',
            userNotFoundDescription: 'در حال حاضر هیچ گزینه ای برای ارائه بازخورد به کاربر خاص وجود ندارد.',
            customColumnOrder: '1- برای جابجایی ستون‌ها، آنها را به محل مورد نظر درون جدول بکشید.',
            customeColumnResize: '2- عرض ستون‌ها قابل تنظیم می‌باشند.',
            resetCustomColumnOrderAndResizeToDefault: '3- با کلیک بر روی دکمه "بازنشانی"، چیدمان و عرض ستون‌ها به حالت اولیه یا پیش‌فرض خود بازخواهد گشت.',
            cashbackRemaningTimeMessage: 'اطلاعات در {0} ثبت شده‌اند و با توجه به احتمال ثبت سفارش‌های جدید، حذف یا مرجوع شدن سفارش‌های موجود، این اطلاعات ممکن است پس از تاریخ مذکور دچار تغییر شده باشند. بنابراین، این اطلاعات .نمایی از سفارش‌ها را در آن زمان، ارائه می‌دهند.',
            orderMasterFileModalImageHelper: 'تصویری که مشاهده می‌شود، پیش‌نمایش فایل با کیفیت 72dpi است. کیفیت نهایی معادل کیفیت اصلی فایل خواهد بود.',
            latDescription: 'لت واحدی برای اندازه‌گیری شیت‌های چاپی است و تعداد لت‌ها بسته به نوع محصول متفاوت است.سیستم به صورت خودکار تعداد لت مورد نیاز برای فایل آپلود شده را محاسبه می‌کند. هزینه چاپ نیز بر اساس تعداد لت‌های مصرفی برای هر فایل تعیین می‌گردد.'
        },

        label: {
            information: 'اطلاعات',
            next: 'مرحله بعدی',
            previous: 'مرحله قبل',
            back: 'بازگشت',
            backToSelectFile: 'بازگشت به انتخاب فایل',
            confirmFiles: 'تایید فایل‌ها',
            downloadGuide: 'دانلود فایل راهنما {0}',
            addToCard: 'افزودن به کارت',
            startProcessing: 'شروع فرآیند',
            showResult: 'نمایش نتایج',
        },
        tour: {
            lastStep: 'تمام! برای بسته شدن کلیک کنید',
            of: 'از',
            badgeContent: '{0} از {1}'
        },

        fileExtension: {
            ai: 'ai',
            bmp: 'bmp',
            csv: 'csv',
            doc: 'doc',
            docx: 'docx',
            gif: 'gif',
            gif: 'gif',
            jpeg: 'jpeg',
            jpg: 'jpg',
            mp3: 'mp3',
            pdf: 'pdf',
            png: 'png',
            psd: 'psd',
            svg: 'svg',
            rar: 'rar',
            tif: 'tif',
            txt: 'txt',
            webp: 'webp',
            xls: 'xls',
            xlsx: 'xlsx',
            zip: 'zip',
            mp4: 'mp4'
        },

        breadcrumbs: {
            signin: 'ورود به حساب کاربری',
            signup: 'ایجاد حساب کاربری',
            forgotPassword: 'فراموشی کلمه عبور',
            changePassword: 'تغییر کلمه عبور',
            successChangePassword: 'موفقیت تغییر کلمه عبور',
            signupActivationMobile: 'فعال کردن موبایل',
            signupAccountInformation: 'ساخت حساب کاربری',
            signupWelcome: 'خوش آمدید',
            products: 'فرم های عمومی',
            storeProducts: 'محصول های فروشگاهی',
            productDetails: 'جزئیات محصول',
            regularOrder: 'سفارش عادی',
            professionalOrder: 'ثبت سفارش حرفه‌‌ای',
            profile: 'پروفایل',
            fileManager: 'مدیریت فایل‌ها',
            userOrderCart: 'سبد خرید کاربر',
            profileInformation: 'اطلاعات شخصی',
            notificationSetting: 'تنظیمات ارسال پیامک',
            autoDeliverySetting: 'تنظیمات ارسال پیشفرض',
            ordersCart: 'سفارش‌ها-سبد',
            ordersPreparing: 'سفارش‌ها-در حال آماده سازی',
            ordersInProcess: 'سفارش‌ها-در حال انجام',
            ordersReady: 'سفارش‌ها-آماده ارسال',
            ordersArchive: 'سفارش‌ها-بایگانی',
            allOrders: 'همه سفارش‌ها',
            orderReport: 'گزارش سفارش',
            address: 'نشانی',
            about: 'درباره ما',
            contactUs: 'تماس با ما',
            FAQs: 'سوالات متداول',
            gallery: 'گالری',
            galleryCategories: 'دسته‌بندی پست‌های گالری',
            privacyPolicy: ' حریم خصوصی',
            warrantyTerms: 'شرایط گارانتی',
            deliveryRequest: 'مدیریت ارسال‌ها',
            paymentReport: 'گزارش پرداخت',
            debitCreditReport: 'گزارش بدهکاری-بستانکاری',
            ourEquipment: 'تجهیزات ما',
            termsOfUse: 'شرایط استفاده',
            help: 'راهنما',
            siteMap: 'نقشه سایت',
            festival: 'جشنواره',
            festivals: 'جشنواره‌ها',
            blogs: 'وبلاگ‌',
            news: 'اخبار',
            blogCategories: 'دسته‌بندی‌های وبلاگ',
            blogCategoryPosts: 'دسته‌بندی پست‌های وبلاگ',
            blogPostDetail: 'جزئیات پست وبلاگ',
            blogPosts: 'پست‌های وبلاگ',
            newsCategories: 'دسته‌بندی‌های اخبار',
            newsCategoryPosts: 'دسته‌بندی پست‌های اخبار',
            newsPostDetail: 'جزئیات پست خبر',
            newsPosts: 'پست‌های اخبار',
            detail: 'جزئیات',
            chooseGateway: 'انتخاب درگاه',
            resultPayment: 'نتیجه پرداخت',
            agencySetting: 'تنظیمات نمایندگی',
            downloadCenter: 'مرکز دانلود',
            downloadDetail: 'جزییات دانلود',
            agencyInformation: 'اطلاعات نمایندگی',
            agencyCustomers: 'مشتریان نمایندگی',
            agencyCustomersOrders: 'سفارش‌های مشتریان نمایندگی',
            agencyProducts: 'محصول‌های نمایندگی',
            agencyShipping: "ارسال‌ها",
            exception: 'استثنا',
            specialNeeds: 'Special Needs',
            paperBag: 'خرید ساک',
            paperBagFaq: 'سوالات متداول',
            paperBagGallery: 'گالری',
            paperBagExploreProduct: 'توضیحات محصول',
            saveOutputInPdfFormat: 'ذخیره خروجی با فرمت PDF',
            productCategories: 'دسته‌بندی‌های محصول ها',
            cashback: 'برگشت پول',
            dashboard: 'داشبورد',
            shoppingBagExploreProduct: 'shopping Bag Explore Product',
            publicProfile: 'مشخصات عمومی {0}',
            instantDiscounts: 'تخفیف های لحظه‌ای',
        },

        socialChannels: {
            instagram: "اینستاگرام",
            facebook: "فیس‌بوک",
            whatsapp: "واتساپ",
        },

        exception: {
            title404: 'متاسفانه صفحه مورد نظر یافت نشد :(',
            description404: 'امکان دارد بتوانیم کمک کنیم تا صفحه مورد نظر را پیدا کنید:'
        },

        currencyUnits: {
            hundreds: 'صد',
            thousands: 'هزار',
            millions: 'میلیون',
            billions: 'میلیارد',
            trillions: 'تریلیارد',
        },

        numbers: {
            zero: 'صفر ',
            negative: 'منفی ',
            one: 'یک ',
            two: 'دو ',
            three: 'سه ',
            four: 'چهار ',
            five: 'پنج ',
            six: 'شش ',
            seven: 'هفت ',
            eight: 'هشت ',
            nine: 'نه ',
            ten: 'ده ',
            eleven: 'یازده ',
            tweleve: 'دوازده ',
            thirteen: 'سیزده ',
            fourteen: 'چهارده ',
            fifteen: 'پانزده ',
            sixteen: 'شانزده ',
            seventeen: 'هفده ',
            eighteen: 'هجده ',
            nineteen: 'نوزده ',
            twenty: 'بیست ',
            thirty: 'سی ',
            forty: 'چهل ',
            fifty: 'پنجاه ',
            sixty: 'شصت ',
            seventy: 'هفتاد ',
            eighty: 'هشتاد ',
            ninety: 'نود ',
            oneHundred: 'یکصد ',
            twoHundred: 'دویست ',
            threeHundred: 'سیصد ',
            fourHundred: 'چهارصد ',
            fiveHundred: 'پانصد ',
            sixHundred: 'ششصد ',
            sevenHundred: 'هفتصد ',
            eightHundred: 'هشتصد ',
            nineHundred: 'نهصد ',
            thousand: 'هزار ',
            million: 'میلیون ',
            billion: 'میلیارد ',
            trillion: 'بیلیون ',
            quadrillion: 'بیلیارد ',
            quintillion: 'تریلیون ',
            sextillion: 'تریلیارد ',
            septillion: 'کوآدریلیون ',
            octillion: 'کادریلیارد ',
            nonillion: 'کوینتیلیون ',
            decillion: 'کوانتینیارد ',
            undecillion: 'سکستیلیون ',
            duodecillion: 'سکستیلیارد ',
            tredecillion: 'سپتیلیون ',
            quattuordecillion: 'سپتیلیارد ',
            quindecillion: 'اکتیلیون ',
            sexdecillion: 'اکتیلیارد ',
            septendecillion: 'نانیلیون ',
            octodecillion: 'نانیلیارد ',
            novemdecillion: 'دسیلیون ',
            vigintillion: 'دسیلیارد ',
            tenths: 'دهم ',
            hundredths: ' صدم',
            thousandths: ' هزارم',
            tenThousandths: ' ده‌هزارم',
            hundredThousandths: ' صد‌هزارم',
            millionths: 'میلیونوم ',
            tenMillionths: 'ده‌میلیونوم ',
            hundredMillionths: 'صدمیلیونوم ',
            billionths: 'میلیاردم ',
            tenBillionths: 'ده‌میلیاردم ',
            hundredBillionths: 'صد‌‌میلیاردم ',
            point: 'ممیز ',
            outOfRange: ' خارج از محدوده'
        }
    },

};

export default CommonTranslationFa;