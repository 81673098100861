import React from "react";
import NsSkeleton from "../../../../components/skeletons/NsSkeleton";
import Enumerations from "../../../../configs/Enumerations";

const ApBasesDashboardChartSkeleton = () => {

    return <div className="d-inline d-md-inline-block col-md-4 p-2" >
        <NsSkeleton isVisible={true}
            variant={[
                Enumerations.skeleton.text,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.text,
            ]} />
    </div >
}

export default React.memo(ApBasesDashboardChartSkeleton);