import React, { Component } from "react";
import { PaginationItem } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";

import '../../assets/sass/components/pagination/NsPagination.scss';
import CommonHelper from "../../utilities/CommonHelper";
import UrlHelper from "../../utilities/UrlHelper";

class NsPagination extends Component {

    onChange = (event, page) => {
        CommonHelper.safeFunctionCall(this.props.onChange, page);
    };

    renderItem = (item) => {
        const { isLink } = this.props;

        if (!isLink)
            return <PaginationItem {...item} />;

        let parameters = UrlHelper.getRouteParameters(window.location.search)
        parameters["page-number"] = item.page;
        try {
            const paramString = Object.entries(parameters)
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&');
            return <PaginationItem
                className="pagination"
                component={Link}
                to={window.location.pathname + `?${paramString}`}
                onChange={(e) => e.preventDefault()}
                {...item}
            />
        }
        catch {
            return <PaginationItem {...item} />;
        }
    }

    render() {
        const { count, page, variant, showFirstButton, showLastButton } = this.props;
        const isMobile = CommonHelper.isMobileDevice();
        return (
            <div className='pagination d-inline-block'>
                <Pagination selected={true}
                    showFirstButton={!!showFirstButton}
                    showLastButton={!!showLastButton}
                    variant={variant || "text"}
                    hidePrevButton={page <= 1}
                    hideNextButton={page >= count}
                    count={count}
                    shape="rounded"
                    page={page}
                    renderItem={this.renderItem}
                    boundaryCount={1}
                    onChange={this.onChange}
                    siblingCount={isMobile ? 0 : 1} />
            </div>
        );
    }
}

export default withRouter(NsPagination);