import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsAutocomplete from '../../../../components/dropdowns/NsAutoComplete';
import NsTypography from '../../../../components/text/NsTypography';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

function SupportLanguage(props) {

    const [state, setState] = useState({
        loading: false,
        showValidationDate: '',
        selectedSubjects: '',
        faIsActive: '',
    })



    const saveClicked = () => {
        setState({
            ...state,
            showValidationDate: new Date()
        });
        return;
    }

    const discardClicked = () => setState({ loading: true },
        /* () =>  api*/)

    const mainButtons = [
        { label: "common.save", onClicked: saveClicked, icon: 'ns-icon-save', className: 'primary' },
        { label: "common.discard", onClicked: discardClicked, icon: 'ns-icon-close', className: 'primary-outline' },
    ];

    const Languages = [
        { id: 1, label: "English" },
        { id: 2, label: "Persian" }
    ];

    return (
        <NsPageMainContentWrapper header={getTranslatedRawText("adminPanel.sidebar.supportLanguage")}
            icon={'ns-icon-setting h1'}>
            <Col xs={4}>
                <NsAutocomplete
                    label={getTranslatedRawText('adminPanel.supportLanguage.primaryLanguage')}
                    items={Languages}
                    onChange={(value) => setState({ ...state, selectedSubjects: value })}
                    value={state.selectedSubjects}
                    className='w-100 mb-2' />
            </Col>
            <Col>
                <div className='d-flex mt-3'>
                    <NsTypography variant='inherit' className='text-color font-size-16 me-2'>{getTranslatedRawText('adminPanel.supportLanguage.fa')}</NsTypography>
                    {/* <NsSwitchButton className=' primary'
                        labelOn='on'
                        checked={state.newOrderEmailIsActive}
                        labelOff='off'
                        switchChanged={() => setState({
                            ...state, faIsActive: !state.faIsActive
                        })} /> */}
                </div>

            </Col>
            <NsStickyButton stickyButtons={mainButtons} />

        </NsPageMainContentWrapper>
    );
}

export default React.memo(SupportLanguage);