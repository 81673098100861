import { getTranslatedRawText, isEnCulture } from "./CultureHelper";

class NumberHelper {

  #delimiter = isEnCulture() ? ',' + ' ' : ' ' + getTranslatedRawText('common.and') + ' ';

  #zero = getTranslatedRawText('common.numbers.zero');

  #negative = getTranslatedRawText('common.numbers.negative');

  #letters = [

    [
      '', getTranslatedRawText('common.numbers.one'), getTranslatedRawText('common.numbers.two'), getTranslatedRawText('common.numbers.three'),
      getTranslatedRawText('common.numbers.four'), getTranslatedRawText('common.numbers.five'), getTranslatedRawText('common.numbers.six'),
      getTranslatedRawText('common.numbers.seven'), getTranslatedRawText('common.numbers.eight'), getTranslatedRawText('common.numbers.nine')
    ],

    [
      getTranslatedRawText('common.numbers.ten'), getTranslatedRawText('common.numbers.eleven'), getTranslatedRawText('common.numbers.tweleve'),
      getTranslatedRawText('common.numbers.thirteen'), getTranslatedRawText('common.numbers.fourteen'), getTranslatedRawText('common.numbers.fifteen'),
      getTranslatedRawText('common.numbers.sixteen'), getTranslatedRawText('common.numbers.seventeen'), getTranslatedRawText('common.numbers.eighteen'),
      getTranslatedRawText('common.numbers.nineteen'), getTranslatedRawText('common.numbers.twenty')
    ],
    [
      '', '', getTranslatedRawText('common.numbers.twenty'), getTranslatedRawText('common.numbers.thirty'), getTranslatedRawText('common.numbers.forty'),
      getTranslatedRawText('common.numbers.fifty'), getTranslatedRawText('common.numbers.sixty'), getTranslatedRawText('common.numbers.seventy'),
      getTranslatedRawText('common.numbers.eighty'), getTranslatedRawText('common.numbers.ninety')
    ],
    [
      '', getTranslatedRawText('common.numbers.oneHundred'), getTranslatedRawText('common.numbers.twoHundred'), getTranslatedRawText('common.numbers.threeHundred'),
      getTranslatedRawText('common.numbers.fourHundred'), getTranslatedRawText('common.numbers.fiveHundred'), getTranslatedRawText('common.numbers.sixHundred'),
      getTranslatedRawText('common.numbers.sevenHundred'), getTranslatedRawText('common.numbers.eightHundred'), getTranslatedRawText('common.numbers.nineHundred')
    ],
    ['', getTranslatedRawText('common.numbers.thousand'), getTranslatedRawText('common.numbers.million'), getTranslatedRawText('common.numbers.billion'),
      getTranslatedRawText('common.numbers.trillion'), getTranslatedRawText('common.numbers.quadrillion'), getTranslatedRawText('common.numbers.quintillion'),
      getTranslatedRawText('common.numbers.sextillion'), getTranslatedRawText('common.numbers.septillion'), getTranslatedRawText('common.numbers.octillion'),
      getTranslatedRawText('common.numbers.nonillion'), getTranslatedRawText('common.numbers.decillion'), getTranslatedRawText('common.numbers.undecillion'),
      getTranslatedRawText('common.numbers.duodecillion'), getTranslatedRawText('common.numbers.tredecillion'), getTranslatedRawText('common.numbers.quattuordecillion'),
      getTranslatedRawText('common.numbers.quindecillion'), getTranslatedRawText('common.numbers.sexdecillion'), getTranslatedRawText('common.numbers.septendecillion'),
      getTranslatedRawText('common.numbers.octodecillion'), getTranslatedRawText('common.numbers.novemdecillion'), getTranslatedRawText('common.numbers.vigintillion')
    ],
  ];


  #decimalSuffixes = [
    '',
    getTranslatedRawText('common.numbers.tenths'),
    getTranslatedRawText('common.numbers.hundredths'),
    getTranslatedRawText('common.numbers.thousandths'),
    getTranslatedRawText('common.numbers.tenThousandths'),
    getTranslatedRawText('common.numbers.hundredThousandths'),
    getTranslatedRawText('common.numbers.millionths'),
    getTranslatedRawText('common.numbers.tenMillionths'),
    getTranslatedRawText('common.numbers.hundredMillionths'),
    getTranslatedRawText('common.numbers.billionths'),
    getTranslatedRawText('common.numbers.tenBillionths'),
    getTranslatedRawText('common.numbers.hundredBillionths')
  ];

  #prepareNumber(num) {
    let out = num;
    if (typeof out === 'number') {
      out = out.toString();
    }

    //make first part 3 chars
    if (out.length % 3 === 1) {
      out = `00${out}`;
    } else if (out.length % 3 === 2) {
      out = `0${out}`;
    }
    // Explode to array
    return out.replace(/\d{3}(?=\d)/g, '$&*').split('*');
  }

  //tinyNumToWord convert 3tiny parts to word
  #tinyNumToWord = (num) => {
    // return zero
    var seperator = isEnCulture() ? '' : this.#delimiter;
    if (parseInt(num, 0) === 0) {
      return '';
    }
    const parsedInt = parseInt(num, 0);
    if (parsedInt < 10) {
      return this.#letters[0][parsedInt];
    }
    if (parsedInt <= 20) {
      return this.#letters[1][parsedInt - 10];
    }
    if (parsedInt < 100) {
      const one = parsedInt % 10;
      const ten = (parsedInt - one) / 10;
      if (one > 0) {
        return this.#letters[2][ten] + seperator + this.#letters[0][one];
      }
      return this.#letters[2][ten];
    }
    const one = parsedInt % 10;
    const hundreds = (parsedInt - (parsedInt % 100)) / 100;
    const ten = (parsedInt - ((hundreds * 100) + one)) / 10;
    const out = [this.#letters[3][hundreds]];
    const secondPart = ((ten * 10) + one);

    if (secondPart === 0) {
      return out.join(seperator);
    }

    if (secondPart < 10) {
      out.push(this.#letters[0][secondPart]);
    } else if (secondPart <= 20) {
      out.push(this.#letters[1][secondPart - 10]);
    } else {
      out.push(this.#letters[2][ten]);
      if (one > 0) {
        out.push(this.#letters[0][one]);
      }
    }

    return out.join(seperator);
  }


  #convertDecimalPart(decimalPart) {
    // Clear right zero
    decimalPart = decimalPart.replace(/0*$/, "");

    if (decimalPart === '') {
      return '';
    }

    if (decimalPart.length > 11)
      decimalPart = decimalPart.substr(0, 11);
    else if (decimalPart == 0)
      return '';
    return getTranslatedRawText('common.numbers.point') + this.#tinyNumToWord(decimalPart) + ' ' + this.#decimalSuffixes[decimalPart.length];
  }

  convertNumberToText = (input) => {
    // Clear Non digits
    input = input.toString().replace(/[^0-9.-]/g, '');
    let isNegative = false;
    const floatParse = parseFloat(input);
    // return zero if this isn't a valid number
    if (isNaN(floatParse)) {
      return this.#zero;
    }
    // check for zero
    if (floatParse === 0) {
      return this.#zero;
    }
    // set negative flag:true if the number is less than 0
    if (floatParse < 0) {
      isNegative = true;
      input = input.replace(/-/g, '');
    }

    // Declare Parts
    let decimalPart = '';
    let integerPart = input;
    let pointIndex = input.indexOf('.');
    // Check for float numbers form string and split Int/Dec
    if (pointIndex > -1) {
      integerPart = input.substring(0, pointIndex);
      decimalPart = input.substring(pointIndex + 1, input.length);
    }

    if (integerPart.length > 66) {
      return getTranslatedRawText('common.numbers.outOfRange');
    }

    // Split to sections
    const slicedNumber = this.#prepareNumber(integerPart);
    // Fetch Sections and convert
    const out = [];
    for (let i = 0; i < slicedNumber.length; i += 1) {
      const converted = this.#tinyNumToWord(slicedNumber[i]);
      if (converted !== '') {
        out.push(converted + this.#letters[4][slicedNumber.length - (i + 1)]);
      }
    }

    // Convert Decimal part
    if (decimalPart.length > 0) {
      decimalPart = this.#convertDecimalPart(decimalPart);
    }

    return (isNegative ? this.#negative : '') + out.join(this.#delimiter) + decimalPart + ' ';
  }
}


export default NumberHelper;