import React from 'react';
import '../../assets/sass/components/stepper/NsStepper.scss';
import CommonHelper from '../../utilities/CommonHelper';
import NsButton from '../buttons/NsButton';



class NsCarouselController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: props.activeStep,
            maxSteps: props.maxSteps,
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {

        if (newProps.maxSteps !== this.state.maxSteps)
            this.setState({ maxSteps: newProps.maxSteps });

        if (newProps.activeStep !== this.state.activeStep)
            this.setState({ activeStep: newProps.activeStep });
    }

    backClicked = () => this.setState({ activeStep: this.state.activeStep - 1 }, this.changeActiveStep);

    nextClicked = () => this.setState({ activeStep: this.state.activeStep + 1 }, this.changeActiveStep);

    changeActiveStep = () => CommonHelper.safeFunctionCall(this.props.changeActiveStep, this.state.activeStep)


    render() {
        const { activeStep, maxSteps } = this.state;
        return (
            <div className='pdf-stepper d-flex flex-column d-md-row'>
                <div className='d-flex'>
                    <NsButton className='secondary-text'
                        startIcon='ns-icon-expand-left'
                        onClick={this.backClicked}
                        disabled={activeStep <= 0} />

                    <div className="p-1 d-none d-md-flex rounded-2 background-element-color">
                        {CommonHelper.stringFormat('{0} / {1}', activeStep + 1, maxSteps)}
                    </div>

                    <NsButton className='secondary-text'
                        startIcon='ns-icon-expand-right'
                        onClick={this.nextClicked}
                        disabled={activeStep === maxSteps - 1} />
                </div>
                <div>
                    <div className="p-1 rounded-2 d-flex d-md-none align-items-center justify-content-center background-element-color">
                        {CommonHelper.stringFormat('{0} / {1}', activeStep + 1, maxSteps)}
                    </div>
                </div>
            </div>

        );
    }
}

export default NsCarouselController;