import React from 'react';
import Switch from '@mui/material/Switch';

import '../../assets/sass/components/switch/NsSwitch.scss'
import CommonHelper from '../../utilities/CommonHelper';
import NsTypography from '../text/NsTypography';
import { getTranslatedRawText } from '../../utilities/CultureHelper';

class NsSwitch extends React.Component {
    render() {
        return (
            <div className='d-flex align-items-center'>
                <NsTypography variant="inherit" className='light-paragraph '>{getTranslatedRawText(this.props.label)}</NsTypography>
                <Switch className='ns-switch'
                    checked={this.props.checked || false}
                    disabled={this.props.disabled}
                    onClick={(event) => CommonHelper.safeFunctionCall(this.props.onClick, event)}
                    onChange={(event) => CommonHelper.safeFunctionCall(this.props.onChange, event.target.checked, event)} />
            </div>
        )
    }
}
export default NsSwitch;