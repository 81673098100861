import React, { useCallback, useEffect, useRef, useState } from 'react';

import { createApStoreOrderColumns } from '../../../../profileSettings/contents/userOrder/stores/bases/UpBaseStoreOrderHelper';
import { GetApStoreOrdersService } from '../../../../../services/adminPanel/AdminStoreOrderService';
import NsAdminPanelContentWrapper from '../../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import ApStoreOrderDetailModal from './ApStoreOrderDetailModal';
import ApStoreOrderSearch from './ApStoreOrderSearch';

export const ApStoreContent = (props) => {

    const { isOrderSelection, hasCheckbox, refreshOrdersGrid, customerDetails, isQueue, selectedRows } = props;

    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState();
    const [searchData, setSearchData] = useState(props.searchData);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData]);

    useEffect(() => {
        if (!!refreshOrdersGrid) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
    }, [refreshOrdersGrid])

    const selectOrderClicked = (order) => CommonHelper.safeFunctionCall(selectedRows, order);

    const getParameters = () => {
        const parameters = {
            "Filter.UserId": searchData?.selectedUser?.userId || searchData?.user || props?.searchData?.user || null,
            "Filter.InvoiceNumbers": searchData?.invoiceNo || null,
            "Filter.SharedSheetOrderStateTypes": searchData?.lastStatuses?.id || props.searchData?.lastStatuses?.id || [],
            "Filter.OrderApplicationTypes": searchData?.orderApplicationTypes?.id || [],
            "Filter.ProductCategoryIds": searchData?.productCategories?.id || [],
            "Filter.SubmitTimeFrom": searchData?.submitTimeFrom || '',
            "Filter.SubmitTimeTo": searchData?.submitTimeTo || '',
            "Filter.LastStatusDateFrom": searchData?.statusDateFrom || '',
            "Filter.LastStatusDateTo": searchData?.statusDateTo || '',
            "Filter.MinPriceExcludeVat": searchData?.minPrice || null,
            "Filter.MaxPriceExcludeVat": searchData?.maxPrice || null,
            "Filter.MaxPaymentInAdvance": searchData?.maxPaymentInAdvance || null,
            "Filter.MinPaymentInAdvance": searchData?.minPaymentInAdvance || null,
            "Filter.MinDiscountAmount": searchData?.minDiscountAmount || null,
            "Filter.MaxDiscountAmount": searchData?.maxDiscountAmount || null,
            "Filter.AgencyId": searchData?.selectedAgency?.key || [],
            "Filter.AdminUserId": searchData?.selectedAdmin?.userId || null,
            "Filter.ProductIds[0]": searchData?.selectedProductsId?.key || null
        };

        // CommonHelper.pushParameterToObject(searchData?.selectedProductsId, "Filter.ProductId", parameters);
        return parameters;
    };

    const handlers = {

        orderDetailClicked: (id) => {
            setSelectedOrder(orders.filter(order => order.id === id)[0]);
            setShowOrderDetailModal(true);
        },

        getSearchData: useCallback((data) => {
            setSearchData(data);
        }, []),

        closeApShareSheetOrderDetailModal: (messageClassModal) => {
            setShowOrderDetailModal(false);
            if (!!messageClassModal) {
                setResultMessageClass(messageClassModal);
                setCallServiceDate(new Date());
            }
        },

        getData: (orders, isLoading) => {
            setOrders(orders);
            setIsLoading(isLoading);
        }
    }

    const { orderDetailClicked, getData, getSearchData, closeApShareSheetOrderDetailModal } = handlers;

    const columns = createApStoreOrderColumns(orderDetailClicked);

    return (
        <>
            <ApStoreOrderSearch customerDetails={customerDetails}
                setSearchData={getSearchData}
                isQueue={isQueue}
                isLoading={isLoading} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetApStoreOrdersService}
                parameters={getParameters()}
                columnDefs={columns}
                hasPagination={true}
                selectedRows={selectedRows}
                hasCheckbox={hasCheckbox}
                getData={getData} />

            {showOrderDetailModal &&
                <ApStoreOrderDetailModal showModal={true}
                    selectedOrder={selectedOrder}
                    closeModal={closeApShareSheetOrderDetailModal} />
            }
            {resultMessageClass}
        </>
    );
}

const ApStoreOrder = () => {
    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.stores")}
            icon={'ns-icon-orders h1'}>
            <ApStoreContent />
        </NsAdminPanelContentWrapper>
    );
}

export default React.memo(ApStoreOrder);