import React from 'react';

import NsButton from '../../../../../components/buttons/NsButton';
import NsProductGuidance from '../../../../../components/guidance/NsProductGuidance';
import NsImage from '../../../../../components/images/NsImage';
import NsTypography from '../../../../../components/text/NsTypography';
import NsTooltip from '../../../../../components/tooltips/NsTooltip';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ImageHelper from '../../../../../utilities/ImageHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';

class PoBaseGuidanceInModal extends React.Component {
    constructor(props) {
        super(props);
        this.guidancesUrls = OrderHelper.findOrderById(this.props.routeId).guidancesUrls;
        this.state = {
            isTooltipOpen: false,
        }
    }

    toggleTooltipClicked = () => this.setState({ isTooltipOpen: !this.state.isTooltipOpen });

    render() {
        const { currentFileKey, title, headerLabel } = this.props;

        let label, tooltipTitle;
        if (currentFileKey === 0) {
            label = CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.fourColorPrintingFile'), headerLabel);
            tooltipTitle = <MasterFileGuidance />;
        } else {
            label = CommonHelper.stringFormat('{0} ({1})', title, headerLabel);
            tooltipTitle = <FilmFileGuidance />;
        }

        return <div className='d-flex align-items-center justify-content-start mb-4'>
            <span className='primary-paragraph pe-2'>
                {label}
            </span>
            <NsTooltip open={this.state.isTooltipOpen}
                title={tooltipTitle}
                closeTooltip={() => this.setState({ isTooltipOpen: false })}>
                <NsButton className='secondary-text masterFileModal-tour-preview p-0'
                    startIcon='ns-icon-question'
                    onClick={this.toggleTooltipClicked} />
            </NsTooltip>
            <div className='ms-auto'>
                <NsProductGuidance guidancesUrls={this.guidancesUrls} />
            </div>
        </div>
    }
}

function MasterFileGuidance() {
    return (
        <div className="masterFileModal-tour-preview">
            <NsTypography className='font-size-12'>
                {getTranslatedRawText('regularOrder.modal.followingPointsTitle')}
            </NsTypography>
            <NsTypography className=''>
                {getTranslatedRawText('regularOrder.modal.zoneSafeDescription')}
            </NsTypography>
            <NsImage src={ImageHelper.blankSrc()}
                className='preview'
                imgAlt="components.imagesAlt.masterUploadGuidance" />
        </div>
    );
}

function FilmFileGuidance() {
    return (
        <div className="filmFileModal-tour-preview">
            <NsTypography className='font-size-12'>
                {getTranslatedRawText('regularOrder.modal.filmDescription')}
            </NsTypography>
        </div>
    );
}

export default PoBaseGuidanceInModal;