import Config from '../../configs/Config';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';
import { servicesMessageClass } from '../../utilities/MessageModalHelper';
import Enumerations from '../../configs/Enumerations';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CommonHelper from '../../utilities/CommonHelper';
import { isBusinessPartnerRoute } from '../../utilities/BusinessPartnerHelper';
import { isEmpty } from '../../utilities/ValidationHelper';

function GetAxiosInstance() {

    const token = CurrentUserHelper.get().token;

    let authorizationType = isBusinessPartnerRoute()
        ? { 'X-Secure-API-KEY': token }
        : { 'Authorization': 'Bearer ' + token }

    const axiosInstance = axios.create({
        baseURL: Config.apiServer.baseUrl,
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': Config.accessControlAllowOrigin,
            ...authorizationType
        },
    });

    const handleRetryCondition = (e) => {
        if (e.response?.status === 403 || e.response?.status === 401 ||
            e.response?.status === 404 || e.response?.status === 405)
            return false;
        return axiosRetry.isNetworkOrIdempotentRequestError(e) || e.response?.status === 408 ||
            e.toString().includes('Error')
    }

    axiosRetry(axiosInstance,
        {
            retries: 5,
            retryCondition: e => handleRetryCondition(e),
            retryDelay: (retryCount) => {
                return retryCount * 3000;
            }
        }
    );
    axiosInstance.interceptors.request.use(
        request => {
            return request;
        },
        error => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
}

const requestMethod = {
    Get: 'GET',
    Post: 'POST',
    Put: 'PUT',
    Patch: 'PATCH',
    Delete: 'DELETE'
};

const SendRequest = (method, url, parameters, data, callback, showSuccessResponseMessage, onUploadProgressCallback) => {

    const axiosInstance = GetAxiosInstance();
    const cancelToken = axios.CancelToken.source();
    const expirationDate = CurrentUserHelper.get().expirationDate;
    let options = { cancelToken: cancelToken.token };
    const randomId = Math.random();
    let totalUploadingSize = null;
    if (!!onUploadProgressCallback) {
        options.onUploadProgress = (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            onUploadProgressCallback(randomId, loaded, total, percent, percent === 100 ? Enumerations.uploadingStatus.waiting : Enumerations.uploadingStatus.uploading);
            totalUploadingSize = total;
        }
    }
    if (expirationDate < new Date().toISOString().split('T')[0]) {
        let result = {
            data: {
                messageItems: [{
                    messageType: Enumerations.messageType.authentication,
                    messageText: getTranslatedRawText('common.messages.tokenExpired')
                }],
                hasError: true
            }
        };

        prepareResult(result, callback, showSuccessResponseMessage, randomId, totalUploadingSize, onUploadProgressCallback);
        return;
    }
    
    parameters = setUtmParameters(parameters);

    axiosInstance({
        method: method,
        url: url,
        params: parameters,
        data: data,
        ...options,
        transformResponse: [function (data) {
            return JSON.parse(data);
        }]
    })
        .then(result => {
            prepareResult(result, callback, showSuccessResponseMessage, randomId, totalUploadingSize, onUploadProgressCallback, data);
        })
        .catch((error) => {
            let result = null;
            let errorResponse = !!error.response ? toCamel(error.response) : error;
            if (axios.isCancel(error))
                errorResponse = getTranslatedRawText('common.messages.cancelUploadingFile');

            if (!!errorResponse?.data && !!errorResponse?.data?.messageItems && errorResponse?.data?.messageItems?.length > 0)
                result = errorResponse;
            else
                result = {
                    data: {
                        messageItems: [{
                            messageType: errorResponse?.status === 401 ? Enumerations.messageType.authentication : Enumerations.messageType.error,
                            messageText: errorResponse?.status === 401 ? getTranslatedRawText('common.messages.tokenExpired')
                                : CommonHelper.stringFormat(getTranslatedRawText('common.messages.errorResponse'), errorResponse),
                            isCanceled: !!axios?.isCancel(error)
                        }],
                        hasError: true
                    }
                };

            prepareResult(result, callback, showSuccessResponseMessage, randomId, totalUploadingSize, onUploadProgressCallback);

        });
    return cancelToken;
}

function setUtmParameters(parameters){
    if(isEmpty(parameters))
        parameters = {};
    parameters.utm_source = 'web-application';
    parameters.utm_phoneNumber = CurrentUserHelper.getMobile();

    return parameters;
}

function prepareResult(result, callback, showSuccessResponseMessage, randomId, totalUploadingSize, onUploadProgressCallback, data) {

    let resultData = [];
    let messageItems = [];

    if (!!result.data && !!result.data.messageItems && result.data.messageItems.length > 0) {
        resultData = result.data.messageItems[0].data;
        messageItems = result.data.messageItems;
    }

    if (onUploadProgressCallback) {
        if (messageItems.find(item => item.messageType === Enumerations.messageType.error))
            onUploadProgressCallback(randomId, null, null, null, Enumerations.uploadingStatus.failed, result.data);
        else if (messageItems.find(item => item.messageType === Enumerations.messageType.warning))
            onUploadProgressCallback(randomId, 0, totalUploadingSize, 0, Enumerations.uploadingStatus.warning, result.data);
        else
            onUploadProgressCallback(randomId, totalUploadingSize, totalUploadingSize, 100, Enumerations.uploadingStatus.uploaded);
    }

    let messageClassModal = servicesMessageClass('', messageItems, showSuccessResponseMessage);

    callback(resultData, result, messageClassModal);
}

function toCamel(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value?.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
}

export const GetRequest = (url, parameters, callBack, showSuccessResponseMessage) => SendRequest(requestMethod.Get, url, parameters, {}, callBack, showSuccessResponseMessage);

export const PostRequest = (url, data, callBack, showSuccessResponseMessage, onUploadProgressCallback) => SendRequest(requestMethod.Post, url, {}, data, callBack, showSuccessResponseMessage, onUploadProgressCallback);

export const PutRequest = (url, data, callBack, showSuccessResponseMessage) => SendRequest(requestMethod.Put, url, {}, data, callBack, showSuccessResponseMessage);

export const PatchRequest = (url, data, callBack, showSuccessResponseMessage) => SendRequest(requestMethod.Patch, url, {}, data, callBack, showSuccessResponseMessage);

export const DeleteRequest = (url, parameters, data, callBack, showSuccessResponseMessage) => SendRequest(requestMethod.Delete, url, parameters, data, callBack, showSuccessResponseMessage);

export const CancelRequest = (cancelToken) => !!cancelToken && cancelToken.cancel();