import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetApDfbStrengthWeeknessChartService } from '../../../../../services/adminPanel/AdminDashboardService';
import ApBasesDashboardDoughnutCharts from '../../bases/ApBasesDashboardDoughnutCharts';
import ApBasesDashboardChartSkeleton from '../../bases/ApBasesDashboardChartSkeleton';
import ApBasesDashboardHelper from '../../bases/ApBasesDashboardHelper';
import '../../../../../assets/sass/views/adminPanel/AdminPanel.scss';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsCard from '../../../../../components/cards/NsCard';

const ApBasesDfbStrengthWeeknessChart = (props) => {
    const { feedbackTypeMetricType } = props;
    const { parameters, refreshDate, title } = props;
    const [data, setData] = useState({});
    const [dataDetails, setDataDetails] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        getStrengthWeeknessChart();
    }, [refreshDate]);

    const getStrengthWeeknessChart = () => {
        setIsLoading(true);
        GetApDfbStrengthWeeknessChartService({ ...parameters, feedbackTypeMetricType }, getStrengthWeeknessChartCallback);
    };

    const getStrengthWeeknessChartCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(resultData);
            !isEmpty(resultData?.labels) && setDataObject(ApBasesDashboardHelper.setDoughnutChartFeedbackStrengthAndWeekness(resultData));
        } else
            setMessageClassModal(messageClassModal);
    };

    const options = {
        cutout: 60,
    }

    const setDataObject = (data) => {
        let secondData = data;
        let newData = [];

        secondData.labels.map(label =>
            newData.push({ label: label })
        );
        newData.map((item, index) => {
            item.value = secondData.datasets[0].data[index].value;
            item.backgroundColor = secondData.datasets[0].backgroundColor[index];
        })
        setDataDetails(newData);
    }
    return (!isEmpty(data?.labels) &&
        <NsCard className='p-3 h-100'>
            <ApBasesDashboardDoughnutCharts title={title}
                height='200'
                type='doughnut'
                data={data}
                options={options}
                refreshClicked={getStrengthWeeknessChart}
                isLoading={isLoading} >
                {isLoading
                    ? <ApBasesDashboardChartSkeleton />
                    : !isEmpty(dataDetails) &&
                    dataDetails.map((item, index) =>
                        <Row key={index} className='m-0 mb-2 px-0 align-items-center'>
                            <Col className='px-0 rounded' xs={1} style={{ backgroundColor: item.backgroundColor, width: '1rem', height: '1rem' }}></Col>
                            <Col className='px-0 ps-3' xs={8}>{item.label}</Col>
                            <Col className='px-0 text-end' xs={3}>{!!item.value && item.value.toFixed(2)} %</Col>
                        </Row>
                    )
                }
            </ApBasesDashboardDoughnutCharts>
            {messageClassModal}
        </NsCard >
    );
};

export default React.memo(ApBasesDfbStrengthWeeknessChart);