import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsTypography from '../../../../../components/text/NsTypography';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import clsx from 'clsx';


class FdCountDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            days: "-",
            hours: "-",
            min: "-",
            sec: "-",
            timeEnded: null,
        }
    }

    componentDidMount = () => this.startTimer();

    timeDiffCalc = (dateFuture, dateNow) => {
        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
        dateFuture > dateNow
            ? this.setState({
                timeEnded: false,
                sec: Math.floor(diffInMilliSeconds % 60),
                min: Math.floor(diffInMilliSeconds / 60) % 60,
                hours: Math.floor(diffInMilliSeconds / 3600) % 24,
                days: Math.floor(diffInMilliSeconds / 86400),
            })
            : this.setState({
                timeEnded: true
            })
    }

    startTimer = () => {
        setInterval(() => {
            this.timeDiffCalc(new Date(this.props.festivalDetail.endDate), new Date())
        }, 1000);
    }

    render() {
        const { containerClassName, hasExplanation, countdownClassName } = this.props;

        const endDate = !!this.props.festivalDetail.endDate ? Intl.DateTimeFormat('en-Us', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(this.props.festivalDetail.endDate)) : '';
        const startDate = !!this.props.festivalDetail.startDate ? Intl.DateTimeFormat('en-Us', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(this.props.festivalDetail.startDate)) : '';
        return (
            <div className={clsx('not-rtl', containerClassName)}>
                <div >
                    {/* {this.state.timeEnded
                        ? <>
                            <div>
                                <NsTypography variant='inherit' className='festival__countdown__title fw-bold'>{CommonHelper.stringFormat(getTranslatedRawText('festival.countdown.festivalHasEndedTitle'), this.props.festivalDetail.name)}</NsTypography>
                            </div>
                            <NsTypography variant='inherit' className='font-size-18 '>{getTranslatedRawText('festival.countdown.festivalHasEndedDescription')}</NsTypography>
                        </>
                        :  */}
                    <>
                        <Row className='justify-content-around d-flex'>
                            <Col xs={12} md={8} lg={12} >
                                <Row className={clsx('d-flex justify-content-between', countdownClassName)}>
                                    <Col className='d-flex flex-column align-items-center'>
                                        <NsTypography variant='inherit' className='festival__countdown__date-box fw-bold'>{this.state.days}</NsTypography>
                                        <NsTypography variant='inherit' className='font-size-14 text-white-50'>{getTranslatedRawText('festival.countdown.days')}</NsTypography>
                                    </Col>
                                    <span className='w-auto festival__countdown__date-box px-0'>:</span>
                                    <Col className='d-flex flex-column align-items-center'>
                                        <NsTypography variant='inherit' className='festival__countdown__date-box fw-bold'>{this.state.hours}</NsTypography>
                                        <NsTypography variant='inherit' className='font-size-14 text-white-50'>{getTranslatedRawText('festival.countdown.hours')}</NsTypography>
                                    </Col>
                                    <span className='w-auto festival__countdown__date-box px-0'>:</span>
                                    <Col className='d-flex flex-column align-items-center'>
                                        <NsTypography variant='inherit' className='festival__countdown__date-box fw-bold'>{this.state.min}</NsTypography>
                                        <NsTypography variant='inherit' className='font-size-14 text-white-50'>{getTranslatedRawText('festival.countdown.minutes')}</NsTypography>
                                    </Col>
                                    <span className='w-auto festival__countdown__date-box px-0'>:</span>
                                    <Col className='d-flex flex-column align-items-center'>
                                        <NsTypography variant='inherit' className='festival__countdown__date-box fw-bold'>{this.state.sec}</NsTypography>
                                        <NsTypography variant='inherit' className='font-size-14 text-white-50'>{getTranslatedRawText('festival.countdown.seconds')}</NsTypography>
                                    </Col>
                                </Row>
                            </Col>
                            {hasExplanation &&
                                <Col xs={12} md={3} lg={12} xxl={3}>
                                    <Row>
                                        <Col xs={6} md={12} lg={6} xxl={12} className='d-flex flex-column mb-lg-3 mb-md-3'>
                                            <NsTypography variant='inherit' className='font-size-14 text-nowrap '>{getTranslatedRawText('festival.countdown.eventStartedTime')}</NsTypography>
                                            <NsTypography variant='inherit' className='font-size-14 text-white-50'>{startDate}</NsTypography>
                                        </Col>
                                        <Col xs={6} md={12} lg={6} xxl={12} className='d-flex flex-column '>
                                            <NsTypography variant='inherit' className='font-size-14 text-nowrap '>{getTranslatedRawText('festival.countdown.eventEndTime')}</NsTypography>
                                            <NsTypography variant='inherit' className='font-size-14 text-white-50'>{endDate}</NsTypography>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    </>
                    {/* } */}
                </div>
            </div>
        );
    }
}

export default FdCountDown;