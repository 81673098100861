import React, { Component } from 'react';
import NsButton from '../../../components/buttons/NsButton';
import NsCard from '../../../components/cards/NsCard';
import NsChip from '../../../components/chips/NsChip';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';

class SidebarFilterResults extends Component {

    removeAllFiltersClicked = () => {
        this.props.removeAllFilters();
    }

    render() {
        const { productsFilterLabel } = this.props;

        const showFilters = productsFilterLabel.map((item, index) =>
            item.id !== 0 && <NsChip key={index}
                chipLabel={item.filterLabel}
                size='small'
                className='m-1'
                deleteChipClicked={() => item.labelDeleteClicked(item.id)} />
        );
        return (
            <NsCard className='p-3 categories '>
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <p className='section-title m-0'>
                        {getTranslatedRawText('product.sideBarTitles.selectedFilter')}
                    </p>
                    <NsButton className="primary-text"
                        label='product.common.remove'
                        size='small'
                        onClick={this.removeAllFiltersClicked} />
                </div>
                {showFilters}
            </NsCard >
        );
    }
}

export default SidebarFilterResults;