
import { Redirect } from 'react-router-dom';
import { getCurrentCulture } from './utilities/CultureHelper';

import BusinessPartnerRoutes from './routes/BusinessPartnerRoutes';
import AdminPanelRoutes from './routes/AdminPanelRoutes';
import AccountRoutes from './routes/AccountRoutes';
import MainRoutes from './routes/MainRoutes';
import { isBusinessPartnerRoute } from './utilities/BusinessPartnerHelper';

const redirectLink = isBusinessPartnerRoute() ? `/${getCurrentCulture()}/business-partner/exception` : `/${getCurrentCulture()}/exception`
const AppRoutes = [
    ...AccountRoutes,
    ...MainRoutes,
    ...BusinessPartnerRoutes,
    ...AdminPanelRoutes,

    // { Layout: LoginLayoutRoute, path: "/:lng/test-home/:id", Component: HomeSample, breadCrumb: [], title: '', exact: false },
    { Layout: Redirect, to: redirectLink, breadCrumb: [], title: 'common.breadcrumbs.exception', exact: false },
];

export default AppRoutes;