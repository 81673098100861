const ComponentsTranslationFa = {

	components: {

		mobileInput: {
			enterMobile: 'موبایل را وارد کنید',
			countryCodeWrong: 'کد کشور اشتباه است',
			mobileFormatWrong: 'شماره موبایل درست نمی باشد',
			mobileInvalid: 'شماره موبایل موجود نیست',
		},

		passwordInput: {
			password: ' گذرواژه',
			passwordEnter: 'گذرواژه را وارد کنید',
			currentPassword: 'گذرواژه فعلی',
			currentPasswordEnter: 'گذرواژه را وارد کنید',
			newPassword: 'گذرواژه جدید',
			newPasswordEnter: 'گذرواژه جدید را وارد کنید',
			confirmPassword: 'تکرار گذرواژه ',
			confirmPasswordEnter: 'تکرار گذرواژه را وارد کنید',
			passwordNotMatch: 'تکرار گذرواژه تطابق ندارد',
			passwordRegex: 'حداقل 6 کاراکتر وعدد',
			persianPasswordRegex: 'از حروف انگلیسی واعداد استفاده کنید',
			needCharacter: 'گذرواژه باید شامل حداقل یک حرف باشد',
			needNumber: 'گذرواژه باید شامل حداقل یک عدد باشد',
		},

		captcha: {
			typeLetters: 'حروف را وارد کنید',
			caseSensitive: 'حساس به حروف کوچک و بزرگ',
			tooltipInfo: 'به ما کمک کنید متوجه بشیم ربات نیستید.'
		},

		datePicker: {
			dateIsTooFar: 'تاریخ وارد شده دور می‌باشد',
			invalidDateMessage: 'تاریخ وارد شده در فرمت نادرستی است',
			minDateMessage: 'تاریخ  وارد شده نباید کمتر از حداقل باشد',
			today: 'امروز',
			now: 'اکنون',
			days: {
				sat: 'شنبه',
				sun: 'یکشنبه',
				mon: 'دوشنبه',
				tues: 'سه شنبه',
				wed: 'چهارشنبه',
				thurs: 'پنج شنبه',
				fri: 'جمعه',
			}
		},

		verificationInput: {
			verifyMaskLabel: 'کد تایید',
			verifyMaskEnter: 'کد تایید را وارد کنید',
			verifyMaskNotMatch: 'کد تایید اشتباه است',
		},

		emailInput: {
			email: 'ایمیل',
			enterEmail: 'ایمیل را وارد کنید',
			emailFormatWrong: 'فرمت ایمیل اشتباه است',
			emailInvalid: 'ایمیل وجود ندارد',
		},

		country: {
			country: 'کشور',
			countryEnter: 'کشور را انتخاب کنید',
		},

		state: {
			state: 'استان',
		},

		city: {
			city: 'شهر',
		},

		genderType: {
			gender: 'جنسیت',
			male: 'آقا',
			female: 'خانم',
			genderEnter: 'جنسیت را انتخاب کنید',
		},

		paymentStatusType: {
			type: 'نوع'
		},

		accountType: {
			accountType: 'نوع حساب کاربری',
			business: 'تجاری',
			personal: 'شخصی',
			accountTypeEnter: 'حساب کاربری را وارد کنید',
		},

		orderType: {
			orderTypes: 'نوع ثبت سفارش',
			orderApplicationType: ' برنامه ثبت سفارش',
			online: 'آنلاین',
			inPerson: 'حضوری',
		},

		orderApplicationType: {
			regular: 'ثبت سفارش معمولی',
			nas: 'ثبت سفارش حضوری',
			professional: 'ثبت سفارش حرفه‌‌ای',
		},

		signInModal: {
			title: 'ورود به حساب کاربری',
			close: 'بستن',
		},

		searchBox: {
			allCategories: 'تمام دسته‌بندی‌ها',
			search: 'جستجو',
		},

		nsChooseFile: {
			frontSide: 'روی سفارش',
			backSide: 'پشت سفارش',
			clickHere: 'برای انتخاب فایل اینجا کلیک کنید',
			dragHere: 'فایل را بکشید و رها کنید',
			or: 'یا',
			changeFile: 'تغییر فایل',
		},

		nsStepper: {
			next: 'مرحله بعد',
			previous: 'مرحله قبل',
			skip: 'رد شدن',
		},

		nsDragFile: {
			dragHere: 'فایل را بکشید و رها کنید',
			or: 'یا',
			browse: 'انتخاب فایل',
			browseYourFile: ' فایل خود را انتخاب کنید',
			changeYourFile: ' فایل خود را تغییر دهید',
		},

		nsLinearProgress: {
			progressInfo: ' {0}مگابایت از {1} مگابایت',
			percentageInfo: '{2}%',
			finalInfo: ' {1}مگابایت',
			prepareForUpload: 'آماده سازی فایل برای بارگذاری...',
			waiting: 'بارگذاری انجام شد. چند دقیقه منتظر بمانید...',
			preparePage: 'آماده سازی صفحه...',
			uploadFailedWithWarning: "خطا",
			uploadFailedWithError: "خطا",
			deleteFailed: 'خطای حذف',
			waitToDecision: "در انتظار",
			replace: "جایگزین",
			keepBoth: "هر دو را نگه دار",
			cancel: "حذف"
		},

		nsDataGrid: {
			address: 'نشانی',
			delete: 'حذف',
			edit: 'ویرایش',
			noRowData: 'اطلاعاتی برای نمایش وجود ندارد',
			loading: 'در حال دریافت اطلاعات...',
			resetSetting: 'بازنشانی تنظیمات'
		},

		nsProductsList: {
			noProductMatch: 'محصولی با توجه به جستجو شما پیدا نشد',
		},

		advanceSearch: {
			new: 'جدید',
			statusDateFrom: 'تاریخ آخرین وضعیت از',
			statusDateTo: 'تاریخ آخرین وضعیت تا',
			user: 'شناسه کاربری',
			order: 'سفارش',
			paymentGateway: 'درگاه پرداخت',
			trackingNo: 'شماره رهگیری',
			referenceNo: 'شماره مرجع سیستم بانکی',
			submitTimeFrom: "تاریخ ثبت از",
			submitTimeTo: "تاریخ ثبت تا",
			createTimeFrom: "زمان ایجاد از",
			createTimeTo: "زمان ایجاد تا",
			minAmount: "حداقل مبلغ",
			maxAmount: "حداکثر مبلغ",
			minAmountFile: "حداقل  مبلغ فایل",
			maxAmountFile: "حداکثر  مبلغ فایل",
			paymentStatus: "وضعیت پرداخت",
			statusTimeFrom: "تاریخ وضعیت از",
			statusTimeTo: "تاریخ وضعیت تا",
			paymentType: "نوع پرداخت",
			invoiceNo: "شماره فاکتور",
			voucherNo: "شماره سند",
			changeStatusByAdmin: "شناسه ادمین تغییر دهنده وضعبت",
			role: 'نقش',
			detailLedger: 'حساب تفصیلی',
			accountStatus: 'وضعیت حساب',
			idNumber: 'کد ملی',
			registrationTimeFrom: 'زمان ثبت نام از',
			registrationTimeTo: 'زمان ثبت نام تا',
			expirationDate: 'تاریخ انقضا',
			mobileActivation: 'فعال سازی موبایل',
			dateOfBirthFrom: 'تاریخ تولد از',
			dateOfBirthTo: 'تاریخ تولد تا',
			name: 'نام',
			lastName: 'نام خانوادگی',
			mobile: 'موبایل',
			lastStatus: 'آخرین وضعیت',
			product: 'محصول',
			circulation: 'تیراژ',
			printedSide: 'یکرو یا دورو',
			turnaround: 'سرعت چاپ',
			minPaymentInAdvance: 'حداقل پیش پرداخت',
			maxPaymentInAdvance: 'حداکثر پیش پرداخت',
			minDiscountAmount: 'حداقل مبلغ تخفیف',
			maxDiscountAmount: 'حداکثر مبلغ تخفیف',
			companyName: 'نام شرکت',
			firstName: 'نام',
			reportDateFrom: 'گزارش از تاریخ',
			reportDateTo: 'گزارش تا تاریخ',
			shippingRequestNumber: 'شماره درخواست ارسال',
			agreementStatus: 'وضعیت توافقنامه',
			agree: 'موافقت شده',
			disAgree: 'موافقت نشده',
			agreementsHistory: 'تاریخچه توافقنامه‌ها',
			matchingStatus: 'وضعیت تطابق',
			fileTransactionStatus: 'وضعیت پرداخت فایل',
			websiteTransactionStatus: 'وضعیت پرداخت وبسایت',
			datePaidFrom: 'تاریخ پرداخت از',
			datePaidTo: 'تاریخ پرداخت تا',
			productName: 'نام محصول',
			displayAll: 'نمایش به همه',
			averageRateFrom: 'میانگین امتیاز از',
			averageRateTo: 'میانگین امتیاز تا',
			demandService: 'خدمات درخواستی',
			displaytoAll: 'نمایش به همه',
			feedbackStatus: 'وضعیت بازخورد',
			productType: 'نوع محصول',
			priceExcVat: 'مبلغ (بدون احتساب مالیات)',
			feedbackGiver: 'بازخورد دهنده',
			departments: 'واحد',
		},

		history: {
			from: 'از',
			to: 'تا',
			user: 'کاربر',
			type: 'وضعیت',
		},

		addToHomeScreen: {
			installAppText: "For download {0} application click on install",
			installAppText: "برای دانلود اپلیکیشن {0} بر روی دکمه نصب کلیک کنید",
			install: 'نصب',
			neverMind: 'بیخیال',
			ios: {
				installAppText: "وب اپلیکیشن {0} را به صفحه اصلی موبایل خود اضافه کنید.",
				firstStep: '1. در نوار پایین روی دکمه {0} بزنید',
				share: "Share",
				secondStep: '2. در منوی باز شده، گزینه {0} را انتخاب کنید',
				a2hc: 'Add to Home Screen',
				thirdStep: '3. در مرحله بعد در قسمت بالا روی {0} بزنید.',
				add: 'Add',
				understood: 'متوجه شدم'
			}
		},

		chart: {
			zoomIn: "بزرگنمایی",
			zoomOut: "کوچک نمایی",
			resetZoom: "بازنشانی"
		},

		imagesAlt: {
			NsLoadingGif: "وبسایت در حال بارگذاری است",
			logo: 'لوگو وبسایت {0}',
			enamd: "لوگوی تجارت الکترونیک و کسب و کار های اینترنتی",
			uploadedImage: "تصویر فایل بارگذاری شده",
			scaledImage: "نمای بزرگ شده تصویر",
			countryFlag: "پرچم کشور {0}",
			selectCountryFlag: "پرچم کشور انتخاب شده",
			UploadProgressThumbnail: "پروسه بارگذاری",
			contentImage: "پیش نمایش تصویر {0}",
			imageThumbnail: "تصویر بندانگشتی",
			promotionSetting: "قراردادن {0}",
			imageDraft: "پیش نمایش تصویر",
			festivalPrize: "تصویر جایزه جشنواره",
			orderFrontSide: "تصویر جلوی سفارش",
			orderBackSide: "تصویر پشت سفارش",
			newsThumbnails: "تصویر کوچک خبر",
			socialMediaLogo: "لوگو شبکه اجتماعی {0}",
			imagePreview: "تصویر فایل {0}",
			aboutUs: "درباره {0}",
			homeBanner: "بنر صفحه اصلی وبسایت {0}",
			orderProcessSection: "تصویر {0}",
			language: "آیکون زبان {0}",
			festivalIcon: "آیکون جایزه جشنواره",
			masterUploadGuidance: "راهنمای آپلود فایل اصلی",
			productDesignTemplate: "قالب طراحی محصول",
			foldLineIcons: "آیکون پیشفرض خط تا",
			submittedOrderImage: "تصویر اتمام سفارش",
			paymentMethodIcon: "آیکون درگاه پرداخت",
			returnToBasket: "بازگشت به سبد خرید",
			ourEquipment: "تجهیزات شرکت {0}",
			festivalBanners: "بنر جشنواره {0}",
			festivalResultBanner: "بنر جایزه جشنواره",
			festivalWinner: "برنده جشنواره",
			blogImage: "تصویر پست {0}",
			testimonialAvatar: "تصویر پروفایل نظردهنده",
			extendedService: "خدمات بیشتر (لب پنجه)",
			imageNotFound: "عکسی برای نمایش موجود نیست",
			captchaImage: "تصویر امنیتی",
			downloadImage: "تصویر قسمت دانلود",
			galleryImage: "تصویر گالری {0}",
			guidance: 'راهنمای {0}',
			instantDiscount: 'تخفیف لحظه‌ای',
		},
		nsInvoiceButton: {
			orderRegistrationReceipt: 'رسید ثبت سفارش',
			vatInvoice: 'فاکتور رسمی'
		},

		basicInfo: {
			// phoneNumberConfirmed: 'اعتبار اعطایی توسط شرکت',
			// category: "دسته‌بندی",
			// files: "فایل‌ها",
			fullName: 'نام و نام خانوادگی',
			// status: 'وضعیت',
			// title: 'عنوان',
			// image: 'تصویر',
			// creator: 'ایجاد کننده',
			modifyDate: 'تاریخ تغییر',
			// modifier: 'تغییر دهنده',
			// question: 'سوال',
			// link: 'لینک',
			// lastStatus: 'آخرین وضعیت',
			// mobile: 'موبایل',
			// registrationTime: 'زمان ثبت',
			// detailLedger: 'حساب تفصیلی',
			// inactiveUsers: 'کاربران بدون ثبت سفارش',
			// country: 'کشور',
			// state: 'استان',
			// city: 'شهر',
			// role: 'نقش‌ها',
			// content: 'محتوا',
			// accountType: 'نوع حساب کاربری',
			// accountStatus: 'وضعیت حساب',
			// idNumber: 'کد ملی',
			// companyName: 'نام شرکت',
			// mobileActivation: 'فعال سازی موبایل',
			// licenseNumber: 'شناسه ملی شرکت',
			// trn: 'کد اقتصادی',
			// trackNumber: 'شماره رهگیری',
			// amount: 'مبلغ',
			createDate: 'زمان ثبت سفارش',
			// paymentGateway: 'درگاه پرداخت',
			// paymentType: 'نوع پرداخت',
			// TermsOfPayment: "روش/ قوانین پرداخت",
			// statusDate: 'تاریخ آخرین وضعیت',
			// voucherNumber: 'شماره سند',
			// referenceNumber: 'شماره مرجع سیستم بانکی',
			invoiceNumber: 'شماره فاکتور',
			// submitDate: 'تاریخ ثبت',
			// description: 'توضیحات',
			productName: 'نام محصول',
			// totalPrice: 'مبلغ کل',
			// priceExcVat: 'مبلغ (بدون احتساب مالیات)',
			// excVat: '(بدون احتساب مالیات)',
			// incVat: '(با احتساب مالیات)',
			// priceIncVat: 'مبلغ (با احتساب مالیات)',
			// paymentInAdvance: 'پیش پرداخت',
			// discount: 'تخفیف',
			// totalOrdersDiscountAmount: 'مجموع مبلغ تخفیف سفارش‌ها',
			// quantity: 'مجموع تعداد',
			// debtor: 'بدهکار',
			// creditor: 'بستانکار',
			// detailLedger: 'حساب تفصیلی',
			// circulation: 'تیراژ',
			// printedSide: 'یکرو یا دورو',
			// turnaround: 'سرعت چاپ',
			// date: 'تاریخ',
			// newUsers: 'کاربران جدید',
			// orderUsers: 'کاربران سفارش داده',
			// orders: 'سفارش‌ها',
			// notConfirmed: 'تایید نشده',
			// prepareFailed: 'رد شده',
			// prepare: 'آماده',
			// inProcess: "در پروسه تولید",
			// archived: "بایگانی شده",
			// inTransit: 'در پروسه ارسال ',
			// ordersAmount: 'مقدار سفارش‌ها',
			// successfullyPaymentAmount: 'مبلغ پرداختی‌های موفق',
			// totalOrdersCount: 'تعداد کل سفارش‌ها',
			// confirmedOrdersCount: 'تعداد سفارش‌های تایید شده',
			// totalOrdersAmount: 'مبلغ کل سفارش‌ها',
			// failedPaymentAmount: 'مبلغ پرداختی‌های ناموفق',
			// successfullyPaymentCount: 'مبلغ پرداختی‌های موفق',
			// failedPaymentCount: 'تعداد پرداختی‌های ناموفق',
			// successfullyPaymentDistinctUserCount: 'مجموع کاربران با پرداخت موفق',
			// failedPaymentDistinctUserCount: 'مجموع کاربران با پرداخت ناموفق',
			// imageUrl: 'نشانی عکس',
			// filePreview: "نمایش فایل",
			// usingInAOrder: "استفاده در سفارش",
			// used: 'استفاده شده',
			// fileUrl: 'لینک فایل',
			// remotingOrderId: "شماره سفارش",
			// user: "کاربر",
			// shippingRequestNumber: 'شماره درخواست ارسال',
			// shippingRequestTime: 'زمان درخواست ارسال',
			// shippingMethod: 'روش ارسال',
			// courierNumber: 'شماره پیک',
			// agencyName: 'نام نمایندگی',
			// stateCity: 'استان/شهر',
			// priceChange: 'تغییر قیمت',
			// agencyDiscount: 'تخفیف نمایندگی',
			// code: 'کد کالا',
			// showIn: 'نمایش در',
			// paymentProblemType: 'نوع مشکل پرداخت',
			// cardNumber: 'شماره کارت',
			// websiteTransactionStatus: 'وضعیت پرداخت وب سایت',
			// fileTransactionStatus: 'وضعیت پرداخت فایل',
			// transactionStatus: 'وضعیت تراکنش',
			// websiteAmount: 'مبلغ وب سایت',
			// fileAmount: 'مبلغ فایل',
			// paymentAuditMatchingTypeId: 'وضعیت تطابق',
			// paymentAuditMatchingType: 'نوع تطابق پرداخت',
			// filePayment: "فایل",
			// lastStatusType: 'آخرین وضعیت',
			// lastStatusTime: 'آخرین زمان استعلام وضعیت',
			// websitePayment: 'وب سایت',
			// invoiceNumberId: 'شناسه فاکتور',
			// merchantId: 'کد پذيرنده',
			// payGateTranId: 'شماره تراکنش سوئیچ',
			// traceNumber: 'شماره پیگیری تراکنش',
			// paymentId: 'شناسه پرداخت',
			// paymentStateType: 'وضعیت نهایی پرداخت',
			// refoundStatus: 'وضعیت برگشت تراکنش',
			// settleStatus: 'وضعیت تسویه',
			// datePaid: 'تاریخ پرداخت',
			// terminalId: 'کد ترمینال',
			// transactionDate: 'تاریخ تراکنش',
			// approvalTime: 'زمان تایید',
			// extendedName: 'نام اضافه',
			// usersWhoRegisterWithTwoNumber: 'کاربرانی که با ۲ شماره ثبت نام کرده‌اند',
			// demandService: 'خدمات درخواستی',
			displaytoAll: 'نمایش به همه',
			// averageRate: 'میانگین امتیاز',
			announcementTitle: 'عنوان اطلاعیه',
			// avgRate: 'میانگین امتیاز',
			// qty: 'تعداد',
			// purchase: 'خرید',
			// cashbackPlan: 'پلن برگشت پول',
			// cashback: 'برگشت پول',
			// remainingPurchaseToNextPlan: 'خرید باقیمانده تا پلن بعدی',
			// nextCashbackPlan: 'پلن برگشت پول بعدی',
			// nextCashback: 'برگشت پول بعدی',
			// estimateReadyToDeliverTime: 'تخمین زمان آماده تحویل',
			// actualReadyToDeliveryTime: 'زمان آماده به تحویل',
			// actualDeliveredTime: 'زمان تحویل داده شده',
			// productType: 'نوع محصول',
			// lastSheetStateQueueType: 'آخرین وضعیت صف سفارش',
			// lastChangeStateUser: 'آخرین تغییر وضعیت توسط',
			// openTime: 'زمان باز شدن',
			// closeTime: 'زمان بسته شدن',
			// isClosed: 'وضعیت بسته شدن',
			// lockedUsername: 'یوزر قفل شده',
			// isSheetDeleted: 'آیا شیت حذف شده؟',
			// collectPaperStatus: 'وضعیت جمعی کاغذ',
			// collectPaperUser: 'نام جمعی کاغذ',
			// layoutedCloseStatus: 'وضعیت بستن قالب',
			// layoutedCloseTime: 'زمان بستن قالب',
			// layoutedException: 'استثنا قالب',
			// deliveryTimeForecast: 'پیش بینی زمان تحویل',
			// submitTime: 'زمان ثبت',
			// confirmTime: 'زمان تایید',
			// readyTime: 'زمان آماده سازی',
			// parents: 'گروه ها',
			// remainingDaysToEstimateReadyToDeliverTime: 'مانده تا زمان تخمینی آماده به تحویل',
			// days: 'روز',
			feedbackSubmitDate: 'تاریخ ثبت بازخورد',
			lastUserFeedbackStateTypeId: 'آخرین وضعیت بازخورد',
			employeeFullName: "نام کامل پرسنل",
			// extraSupport: 'پشتیبانی بیشتر',
			// employee: 'پرسنل',
			// transactionStatusMessage: 'پیغام وضعیت تراکنش',
			// gatewayMessage: 'پیغام درگاه پرداخت ',
			// estimateReadyToDeliverTimeFrom: 'از زمان تقریبی آماده به تحویل',
			// estimateReadyToDeliverTimeTo: 'تا زمان تقریبی آماده به تحویل',
			// onlyDelayedOrder: 'فقط سفارش‌های دارای تاخیر',
			// department: 'چارت سازمانی',
			// feedbackGiver: 'بازخورد دهنده',
			// fileRegulatorServer: 'سرور رگولاتور فایل',
			// orderStatus: 'وضعیت سفارش',
			// currencyType: 'واحد پول',
			// capturedAmount: 'Captured Amount',
			// capturedDate: 'Captured Date',
			// refundAmount: 'Refund Amount',
			// refundDate: 'Refund Date',
			// systemGeneratedOrder: 'System Generated Order',
			// declineReason: 'Decline Reason',
			// gatewayRisk: 'Gateway Risk',
			// gatewayRiskViolations: 'Gateway Risk Violations',
			// authenticationResponse: 'Authentication Response',
			// threeDsfailureReason: 'Three Ds Failure Reason',
			// outlet: 'Outlet',
			// cardHolderEmail: 'Card Holder Email',
			// outletReferenceId: 'Outlet Reference',
			// cardholderName: 'Card Holder Name',
			// paymentMethod: 'Payment Method',
			// originIp: 'Origin Ip',
			// paymentStatus: 'Payment Status',
			// paymentWay: 'نحوه پرداخت'
			isActive: 'وضعیت',
			feedbackType: 'نوع بازخورد',
			logType: 'نوع Log',
			clientIp: 'آی پی مشتری',
			host: 'دامنه',
			source: 'منبع',
			message: 'پیام',
			type: 'نوع',
			orderApplicationType: ' برنامه ثبت سفارش',


		}
	},
};

export default ComponentsTranslationFa;