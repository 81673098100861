const setCurrentFolderIdType = 'SET_CURRENT_FOLDER_ID';
const setCurrentUploadFolderIdType = 'SET_CURRENT_UPLOAD_FOLDER_ID';
const setCurrentFileContentType = 'SET_CURRENT_FILE_CONTENT';
const refreshMainSectionItemsType = 'REFRESH_MAIN_SECTION_ITEMS';
const refreshHeaderDiskUsage = 'REFRESH_HEADER_DISK_USAGE';
const refreshTreeViewType = 'REFRESH_TREE_VIEW';


const initialState = {
    currentFolderId: null,
    currentUploadFolderId: null,
    currentFileContent: null,
    mainSectionFileFolderSectionItemsRefreshDate: null,
    headerDiskUsageRefreshDate: null,
    treeViewRefreshDate: null,
};

export const fileManagerActionCreators = {

    setCurrentFolderId: (id) => ({ type: setCurrentFolderIdType, id }),

    setCurrentUploadFolderId: (id) => ({ type: setCurrentUploadFolderIdType, id }),

    setCurrentFileContent: (content) => ({ type: setCurrentFileContentType, content }),

    refreshMainSectionItems: () => ({ type: refreshMainSectionItemsType }),

    refreshHeaderDiskUsage: () => ({ type: refreshHeaderDiskUsage }),

    refreshTreeView: () => ({ type: refreshTreeViewType }),
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case setCurrentFolderIdType:
            return {
                ...state,
                currentFolderId: action.id,
                currentUploadFolderId: action.id,
                currentFileContent: null,
            };
        case setCurrentUploadFolderIdType:
            return {
                ...state,
                currentUploadFolderId: action.id,
            };

        case setCurrentFileContentType:
            return {
                ...state,
                currentFileContent: action.content,
            };

        case refreshMainSectionItemsType:
            return {
                ...state,
                mainSectionFileFolderSectionItemsRefreshDate: new Date(),
            };

        case refreshHeaderDiskUsage:
            return {
                ...state,
                headerDiskUsageRefreshDate: new Date(),
            };

        case refreshTreeViewType:
            return {
                ...state,
                treeViewRefreshDate: new Date(),
            };

        default:
            return state;
    }
};

