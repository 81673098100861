import ConstantsWithTranslation from '../configs/ConstantsWithTranslation';
import CommonHelper from "./CommonHelper";

export const viewItemListEvent = (products, listEnum, isStoreProducts) => {
    let items = products?.map((product, index) => {
        return {
            item_id: product.selectedId,
            item_name: product.name,
            affiliation: null,
            coupon: null,
            currency: CommonHelper.getDefaultCurrency(),
            discount: null,
            index: index,
            item_brand: null,
            item_category: null,
            item_category2: null,
            item_category3: null,
            item_category4: null,
            item_category5: null,
            item_list_id: listEnum,
            item_list_name: ConstantsWithTranslation.getListNameGoogleTagManager(listEnum),
            item_variant: null,
            location_id: null,
            price: isStoreProducts ? product.storeProductUnitPrices[0].price : product.printCirculations[0]?.price,
            prices: isStoreProducts ? product.storeProductUnitPrices.map(product => product.price) : product.printCirculations.map(product => product.price),
            quantity: isStoreProducts ? product.storeProductUnitPrices[0].value : product.printCirculations[0].value,
            quantities: isStoreProducts ? product.storeProductUnitPrices.map(product => product.value) : product.printCirculations.map(product => product.value),
        }
    })
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({ ecommerce: null });
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
            items
        }
    });
}

export const selectItemEvent = (product, listEnum, googleTagManagerIndex, isStoreProducts) => {
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({ ecommerce: null });
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({
        event: "select_item",
        ecommerce: {
            items: [{
                item_id: product.selectedId,
                item_name: product.name,
                affiliation: null,
                coupon: null,
                currency: CommonHelper.getDefaultCurrency(),
                discount: null,
                index: googleTagManagerIndex,
                item_brand: null,
                item_category: null,
                item_category2: null,
                item_category3: null,
                item_category4: null,
                item_category5: null,
                item_list_id: listEnum,
                item_list_name: ConstantsWithTranslation.getListNameGoogleTagManager(listEnum),
                item_variant: null,
                location_id: null,
                price: isStoreProducts ? product.storeProductUnitPrices[0].price : product.printCirculations[0]?.price,
                prices: isStoreProducts ? product.storeProductUnitPrices.map(product => product.price) : product.printCirculations.map(product => product.price),
                quantity: isStoreProducts ? product.storeProductUnitPrices[0].value : product.printCirculations[0].value,
                quantities: isStoreProducts ? product.storeProductUnitPrices.map(product => product.value) : product.printCirculations.map(product => product.value),
            }]
        }
    });
}

export const viewItemEvent = (product, listEnum, sizeAndPrices) => {
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({ ecommerce: null });
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({
        event: "view_item",
        ecommerce: {
            currency: CommonHelper.getDefaultCurrency(),
            value: sizeAndPrices[0]?.basePrice,
            items: [{
                item_id: product.selectedId,
                item_name: product.name,
                affiliation: null,
                coupon: null,
                currency: CommonHelper.getDefaultCurrency(),
                discount: null,
                index: 1,
                item_brand: null,
                item_category: product.categories?.[0]?.name,
                item_category2: product.categories?.[1]?.name,
                item_category3: product.categories?.[2]?.name,
                item_category4: product.categories?.[3]?.name,
                item_category5: product.categories?.[4]?.name,
                item_categories: product.categories,
                item_list_id: listEnum,
                item_list_name: ConstantsWithTranslation.getListNameGoogleTagManager(listEnum),
                item_variant: null,
                location_id: null,
                price: sizeAndPrices[0]?.basePrice,
                prices: sizeAndPrices.map(product => product?.basePrice),
                quantity: product.printCirculations[0].value,
                quantities: product.printCirculations.map(product => product.value),
            }]
        }
    });
}

export const viewPromotionEvent = (promotionEvent, index, promotionEnum, listEnum) => {
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({ ecommerce: null });
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({
        event: "view_promotion",
        ecommerce: {
            items: [{
                item_id: promotionEnum,
                item_name: ConstantsWithTranslation.getPromotionNameGoogleTagManager(promotionEnum),
                affiliation: null,
                coupon: null,
                currency: null,
                discount: null,
                index: index,
                item_brand: null,
                item_category: null,
                item_category2: null,
                item_category3: null,
                item_category4: null,
                item_category5: null,
                item_list_id: listEnum,
                item_list_name: ConstantsWithTranslation.getListNameGoogleTagManager(listEnum),
                item_variant: promotionEvent?.alt || promotionEvent?.title,
                location_id: null,
                price: null,
                quantity: null
            }]
        }
    });
}

export const selectPromotionEvent = (promotionEvent, index, promotionEnum, listEnum) => {
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({ ecommerce: null });
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({
        event: "select_promotion",
        ecommerce: {
            items: [{
                item_id: promotionEnum,
                item_name: ConstantsWithTranslation.getPromotionNameGoogleTagManager(promotionEnum),
                affiliation: null,
                coupon: null,
                currency: null,
                discount: null,
                index: index,
                item_brand: null,
                item_category: null,
                item_category2: null,
                item_category3: null,
                item_category4: null,
                item_category5: null,
                item_list_id: listEnum,
                item_list_name: ConstantsWithTranslation.getListNameGoogleTagManager(listEnum),
                item_variant: promotionEvent.alt,
                location_id: null,
                price: null,
                quantity: null
            }]
        }
    });
}

export const addToCartEvent = (product, listEnum) => {
    const { productName, id, fileSizeAndOrderPrices, circulation, series, categories } = product;
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({ ecommerce: null });
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: [{
                item_id: id.split("-").shift(),
                item_name: productName,
                affiliation: null,
                coupon: null,
                currency: null,
                discount: null,
                index: 1,
                item_brand: null,
                item_category: categories?.[0]?.name,
                item_category2: categories?.[1]?.name,
                item_category3: categories?.[2]?.name,
                item_category4: categories?.[3]?.name,
                item_category5: categories?.[4]?.name,
                item_categories: categories,
                item_list_id: listEnum,
                item_list_name: ConstantsWithTranslation.getListNameGoogleTagManager(listEnum),
                item_variant: fileSizeAndOrderPrices?.productDimension,
                location_id: null,
                price: fileSizeAndOrderPrices?.totalPriceAfterMultipleInQuantity,
                quantity: circulation.value * series
            }]
        }
    });
}

export const beginCheckoutEvent = (products, listEnum) => {
    let items = products?.map((product, index) => {
        return {
            item_id: product.id,
            item_name: product.productName,
            affiliation: null,
            coupon: null,
            currency: CommonHelper.getDefaultCurrency(),
            discount: null,
            index: index,
            item_brand: null,
            item_category: null,
            item_category2: null,
            item_category3: null,
            item_category4: null,
            item_category5: null,
            item_list_id: listEnum,
            item_list_name: ConstantsWithTranslation.getListNameGoogleTagManager(listEnum),
            item_variant: null,
            location_id: null,
            price: product.totalPrice,
            quantity: null
        }
    })
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({ ecommerce: null });
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
            value: products?.map(product => product.totalPrice).reduce((prev, curr) => prev + curr, 0),
            currency: CommonHelper.getDefaultCurrency(),
            items
        }
    });
}

export const purchaseEvent = (payment, listEnum) => {
    let items = payment?.products?.length > 0
        ? payment.products?.map((product, index) => {
            return {
                item_id: product.id,
                item_name: product.productName,
                affiliation: null,
                coupon: null,
                currency: CommonHelper.getDefaultCurrency(),
                discount: null,
                index: index,
                item_brand: null,
                item_category: null,
                item_category2: null,
                item_category3: null,
                item_category4: null,
                item_category5: null,
                item_list_id: listEnum,
                item_list_name: ConstantsWithTranslation.getListNameGoogleTagManager(listEnum),
                item_variant: null,
                location_id: null,
                price: null,
                quantity: null
            }
        })
        : [{
            item_id: payment.paymentGatewayId,
            item_name: payment.paymentGatewayName,
            affiliation: null,
            coupon: null,
            currency: CommonHelper.getDefaultCurrency(),
            discount: null,
            index: 1,
            item_brand: null,
            item_category: null,
            item_category2: null,
            item_category3: null,
            item_category4: null,
            item_category5: null,
            item_list_id: listEnum,
            item_list_name: ConstantsWithTranslation.getListNameGoogleTagManager(listEnum),
            item_variant: null,
            location_id: null,
            price: payment.amount,
            quantity: null
        }]

    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({ ecommerce: null });
    typeof window.dataLayer !== 'undefined' && window.dataLayer.push({
        event: "purchase",
        ecommerce: {
            transaction_id: payment.trackingNo,
            affiliation: null,
            value: payment.amount,
            tax: null,
            shipping: null,
            currency: CommonHelper.getDefaultCurrency(),
            coupon: null,
            items
        }
    });
}