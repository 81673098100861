import React, { Component } from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import CommonHelper from '../../utilities/CommonHelper';
import '../../assets/sass/components/buttons/NsRadioButton.scss';

class NsRadioButton extends Component {

    radioButtonClicked = (event) => {
        !this.props.disabled && CommonHelper.safeFunctionCall(this.props.radioButtonClicked, event.target.value);
    };

    render() {
        const { option, checked, disabled } = this.props;
        return (
            <FormControlLabel value={option.id}
                className='ns-radio p-0 m-0'
                control={<Radio />}
                label={option.label || option.title || option.name}
                checked={checked}
                disabled={disabled}
                onClick={this.radioButtonClicked} />
        );
    }
}

export default NsRadioButton;