import React from 'react';
import clsx from 'clsx';

import CommonHelper from '../../utilities/CommonHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import NsRating from './NsRating';

class NsRatingGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refreshDate: null
        }
    }

    onChange = (newValue, index) => {
        let tempRatings = this.props.ratings;
        tempRatings[index]['rate'] = newValue;
        CommonHelper.safeFunctionCall(this.props.onChange, tempRatings);
        this.setState({ refreshDate: new Date() })
    }

    render() {
        const { className, ratings, size, readOnly, precision, containerClassName } = this.props;

        return isEmpty(ratings)
            ? null
            : ratings.map((rate, index) =>
                <div key={index} className={clsx('d-flex justify-content-between pt-1', containerClassName)}>
                    <span className={clsx('font-size-14', rate?.isActive == false ? 'light-text-color' : '')}>{rate?.title}</span>
                    <NsRating precision={precision}
                        readOnly={readOnly}
                        size={size}
                        disabled={rate?.isActive ?? false}
                        className={className}
                        value={rate?.rate}
                        onChange={(newValue) => this.onChange(newValue, index)} />
                </div>)
    }
}

export default NsRatingGroup;