import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PostRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';

const GetCashBackHistoriesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getCashbackHistories), parameters, callback)
}

const GetCashbackPlansService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getCashbackPlans), {}, callback)
}

const GetCashbackForecastService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getCashbackForecast), {}, callback)
}

const GetCurrentCashbackInfoService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getCurrentCashbackInfo), {}, callback)
}

export {
    GetCashBackHistoriesService,
    GetCashbackPlansService,
    GetCashbackForecastService,
    GetCurrentCashbackInfoService,
}