import clsx from 'clsx';
import React from 'react';
import '../../assets/sass/components/buttons/NsButton.scss';

import NsButton from './NsButton';
import CommonHelper from '../../utilities/CommonHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import { hasAccess } from '../../utilities/ClaimHelper';

class NsStickyButton extends React.Component {

    render() {
        const { stickyButtons, buttonsContainerClassName, isLoading } = this.props;
        const className = !!CommonHelper.isMobileDevice() && 'p-3 sticky-buttons';
        let stickyButtonsFilter = CommonHelper.clearArrayFromEmptyItem(stickyButtons);
        const buttons = isEmpty(stickyButtonsFilter)
            ? null
            : stickyButtonsFilter.map((button, index) =>
                hasAccess(button?.accessIds) && <div className={clsx(!!CommonHelper.isMobileDevice() ? 'w-50 px-1' : 'ms-2')} key={index}>
                    <NsButton className={button.className}
                        wrapperClassName={button.wrapperClassName}
                        startIcon={button.icon}
                        disabled={button.disabled}
                        label={button.label}
                        loading={isLoading}
                        onClick={button.onClicked} />
                </div>
            )

        return !isEmpty(CommonHelper.clearArrayFromEmptyItem(buttons)) &&
            <div className={clsx('w-100', className, this.props.className)}>
                {this.props.children}
                <div className={clsx('d-flex w-100', !!buttonsContainerClassName
                    ? buttonsContainerClassName
                    : 'justify-content-center justify-content-md-end')}>
                    {buttons}
                </div>
            </div >
    }
}

export default NsStickyButton;