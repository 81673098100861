import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    DeleteFileService, RenameFileService, UploadFileService
} from '../../services/fileManager/FileManagementService';
import {
    CreateFolderService, DeleteFolderService, RenameFolderService
} from '../../services/fileManager/FolderManagementService';

class TestApis extends Component {

    constructor(props) {
        super(props);
        this.rotate90Style = { transform: 'rotate(90deg)' }
        this.rotate270Style = { transform: 'rotate(-90deg)' }
        this.rotate180Style = { transform: 'rotate(180deg)' }
        this.state = {
            usedSpace: 150,
            maxAvailableSpace: 500,
            currentFolderId: null,
            selectedItemId: null,
            selectedFile: null,
            resultMessageClass: '',
            newFolder: '',
            foldersAndFiles: [],
            fileActions: [],
            rotate: 0,
            Vflip: 1,
            Hflip: 1,
            imgStyle: {},
            imgWidth: "100%",
            imgheight: "100%"
        };
    }

    changeUploadFile = (event) => {
        let fileExtension = event.target.files[0].name.split('.')[1];
        if (Config.validFileExtensions.indexOf(fileExtension) > -1) 
            this.setState({ selectedFile: event.target.files[0] })
         else {
            alert('file format is wrong');
            event.target.value = null;
        }
    }

    uploadFile = () => {
        if (this.state.selectedFile) {
            let formData = new FormData();
            formData.append('file', this.state.selectedFile, this.state.selectedFile.name)
            UploadFileService(formData, this.uploadFileCallback);
        } else 
            alert("choose file")
    }

    uploadFileCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) 
            console.log(resultData);
         else 
            this.setState({ resultMessageClass: messageClassModal });
    }

    renameFile = () => {
        let data = {
            id: "90c0a742-fe6a-42b3-8c86-f4a1eb2d33c2",
            name: 'img_paris - Copy.jpg',
            newName: 'img_paris1.jpg'
        };
        RenameFileService(data, this.renameFileCallback);
    }

    renameFileCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) 
            console.log(resultData);
         else 
            this.setState({ resultMessageClass: messageClassModal });
    }

    deleteFile = () => {
        let data = {
            id: 12,
        };
        DeleteFileService(data, this.deleteFileCallback);
    }

    deleteFileCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) 
            console.log(resultData);
        else 
            this.setState({ resultMessageClass: messageClassModal });
    }

    changeNewFolderName = (event) => {
        if (event.target.value) {
            this.setState({ newFolder: event.target.value.trim() })
        }
    }

    createFolder = () => {
        if (this.state.newFolder) {
            let data = {
                parentId: null,
                name: this.state.newFolder,
            };
            CreateFolderService(data, this.createFolderCallback);
        }
    }

    createFolderCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) 
            console.log(resultData);
        else 
            this.setState({ resultMessageClass: messageClassModal });
    }

    renameFolder = () => {
        let data = {
            id: 12,
            newName: 'folder1'
        };
        RenameFolderService(data, this.renameFolderCallback);
    }

    renameFolderCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) 
            console.log(resultData);
        else 
            this.setState({ resultMessageClass: messageClassModal });
    }



    deleteFolder = () => {
        let data = {
            id: 12,
        };
        DeleteFolderService(data, this.deleteFolderCallback);
    }

    deleteFolderCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            console.log(resultData);
        else 
            this.setState({ resultMessageClass: messageClassModal });
    }

    flipVertical = () => {
        this.setState({
            Vflip: this.state.Vflip === 1 ? -1 : 1,
            fileActions: [...this.state.fileActions, { transform: 'Vflip' }]
        });
    }

    flipHorizontal = () => {
        this.setState({
            Hflip: this.state.Hflip === 1 ? -1 : 1,
            fileActions: [...this.state.fileActions, { transform: 'Hflip' }]
        });
    }

    rotate90 = () => {
        this.setState({
            rotate: this.state.rotate + 90,
            fileActions: [...this.state.fileActions, this.rotate90Style]
        });
    }

    rotate270 = () => {
        this.setState({
            rotate: this.state.rotate - 90,
            fileActions: [...this.state.fileActions, this.rotate270Style]
        });
    }

    rotate180 = () => {
        this.setState({
            rotate: this.state.rotate + 180,
            fileActions: [...this.state.fileActions, this.rotate180Style]
        });
    }

    ChangeImgWidth = (event) => {
        let value = event.target.value
        if (value)
            setTimeout((value) => {
                this.setState({ imgWidth: value });
            }, 2000)
        else 
            this.setState({ imgWidth: "100%" });
    }

    getFolderContentServiceCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.setState({ foldersAndFiles: resultData })
        else 
            this.setState({ resultMessageClass: messageClassModal });
    }

    showFoldersAndFiles = () => {
        return this.state.foldersAndFiles ?
            <Row>
                {this.state.foldersAndFiles.map(item => <Col key={item.name}>
                    <h6>{item.name}</h6>
                    {!item.isFolder ? <img src={item.content} alt="img" width="125px" height="125px" /> : ''}
                </Col>)}
            </Row>
            : ''
    }

    getDPI() {
        var div = document.createElement("img");
        div.style.height = "1in";
        div.style.width = "1in";
        div.style.top = "-100%";
        div.style.left = "-100%";
        div.style.position = "absolute";
        div.src = testpic;
        document.body.appendChild(div);
        var result = div.offsetHeight;
        document.body.removeChild(div);

        return result;
    }

    render() {
        this.getDPI();
        let img = new Image();
        img.src = testpic;
        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }
        return (
            <Row className="border m-4">
                <Col sm={12} className="mb-3">
                    <input type="number" placeholder="directory id" />
                    <button onClick={this.loadDirectoryContent}>
                        Load directory content
                    </button>
                </Col>
                <Col>
                    {this.showFoldersAndFiles()}
                </Col>
                <Col sm={12} className="mb-3">
                    <h6>Get folders and files</h6>
                    <button onClick={this.getFoldersAndFiles}>
                        Get folders and files
                    </button>
                </Col>
                <Col sm={12} className="mb-3">
                    <input type="file" onChange={this.changeUploadFile} />
                    <button onClick={this.uploadFile}>
                        upload file
                    </button>
                    <button onClick={this.renameFile}>
                        rename file
                    </button>
                    <button onClick={this.deleteFile}>
                        delete file
                    </button>
                </Col>
                <Col sm={12} className="mb-3">
                    <input type="text" placeholder="new folder name" onChange={this.changeNewFolderName} />
                    <button onClick={this.createFolder}>
                        create folder
                    </button>
                    <button onClick={this.renameFolder}>
                        rename folder
                    </button>
                    <button onClick={this.deleteFolder}>
                        delete folder
                    </button>
                </Col>
                <Col sm={12} className="mb-3">
                    <h6>Actions</h6>
                    <img src={testpic}
                        alt="img"
                        width={this.state.imgWidth}
                        height={this.state.imgHeight}
                        style={{
                            transform: 'rotate(' + this.state.rotate + 'deg) scaleX(' + this.state.Vflip + ') scaleY(' + this.state.Hflip + ')',
                        }} />
                    {this.state.foldersAndFiles[this.state.foldersAndFiles.length - 1] ?
                        <div className="border bg-white" style={{ 'width': "500px", "height": "500px" }}>
                            <img src={testpic}
                                alt="img"
                                width={this.state.imgWidth}
                                height={this.state.imgHeight}
                                style={{
                                    transform: 'rotate(' + this.state.rotate + 'deg) scaleX(' + this.state.Vflip + ') scaleY(' + this.state.Hflip + ')',
                                }} />

                        </div> : ''}
                    <p>resize</p>
                    <input type="number" placeholder="x" onChange={this.ChangeImgWidth} />
                    <input type="number" placeholder="y" onChange={this.ChangeImgHeight} />
                    <button onClick={this.changeFileSize}>change file size</button>
                    <button onClick={this.flipVertical}>flip V</button>
                    <button onClick={this.flipHorizontal}>flip H</button>
                    <button onClick={this.rotate90}>rotate 90</button>
                    <button onClick={this.rotate270}>rotate -90</button>
                    <button onClick={this.rotate180}>rotate 180</button>
                </Col>
                {this.state.resultMessageClass}
            </Row>
        );
    }
}

export default TestApis;