import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsDataGridWithServiceCall from '../../../components/grids/NsDataGridWithServiceCall';
import NsHistoryWithServiceCall from '../../../components/histories/NsHistoryWithServiceCall';
import NsTypography from '../../../components/text/NsTypography';
import ConstantsWithTranslation from '../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../configs/UserClaimEnumerations';
import { hasAccess } from '../../../utilities/ClaimHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import EnumerationTranslationHelper from '../../../utilities/EnumerationTranslationHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import ApCurrentActiveAgreementDetailModal from '../contents/singlePages/agreements/agreementDetailModal/ApCurrentActiveAgreementDetailModal';
import ApBaseLabelInfo from './ApBaseLabelInfo';

const ApBaseWrapper = (props) => {

    const { isLoadingInfo, service, parameters, info, paymentType, columnDefs, isOrderDetail,
        hasAccessInfo, hasAccessHistory, historyService, refreshDate, tabs } = props;

    const [showAgreementDetailModal, setShowAgreementDetailModal] = useState(false);
    const [agreementId, setAgreementId] = useState(null);

    const agreementShowDetailClicked = (id) => {
        if (!hasAccess([UserClaimEnumerations.getApOrderUserAggrementDetail])) return;
        setShowAgreementDetailModal(true);
        setAgreementId(id);
    }

    const closeCurrentActiveAgreementModalClicked = () => {
        setShowAgreementDetailModal(false);
        setAgreementId(null);
    }

    const selectedHistory = (data) => CommonHelper.safeFunctionCall(props.selectedHistoryHandler, data);
    const sheetIdClickedHandler = (data) => CommonHelper.safeFunctionCall(props.sheetIdClicked, data)

    return (
        <>
            <div className='background-color px-3 py-2'>
                <ApBaseLabelInfo agreementShowDetailClicked={agreementShowDetailClicked} info={info} isLoading={isLoadingInfo} hasAccessInfo={hasAccessInfo} />
            </div>
            <Row className='p-2 m-0 flex-md-nowrap g-2'>
                {hasAccessHistory && <Col md={4} className=' m-0'>
                    <NsHistoryWithServiceCall service={historyService}
                        parameters={parameters}
                        hasUserName={true}
                        isAdminPanel={true}
                        typeItems={isOrderDetail ? ConstantsWithTranslation.getAllStatusesOrder() : ConstantsWithTranslation.getHistoryPaymentStatus()}
                        hasPagination={true}
                        refreshDate={refreshDate}
                        tabs={tabs}
                        selectedHistory={selectedHistory}
                        sheetIdClicked={sheetIdClickedHandler}
                    />
                </Col>
                }
                {!isEmpty(service) && <Col md={hasAccessHistory ? 8 : 12} className=' m-0'>
                    <div className='fw-bold mb-2'>
                        <NsTypography variant='inherit' >
                            {EnumerationTranslationHelper.getPaymentTypeText(paymentType)}
                        </NsTypography>
                    </div>
                    <NsDataGridWithServiceCall service={service}
                        parameters={parameters}
                        hasPagination={true}
                        columnDefs={columnDefs} />
                </Col>
                }
                {showAgreementDetailModal && !isEmpty(agreementId) &&
                    <ApCurrentActiveAgreementDetailModal agreementId={agreementId}
                        closeModal={closeCurrentActiveAgreementModalClicked} />
                }
            </Row>
        </>
    );
}

export default React.memo(ApBaseWrapper);