import React from "react";

import { getTranslatedRawText } from "../../utilities/CultureHelper";
import NsGroupNumberInput from "../inputs/NsGroupNumberInput";
import CommonHelper from "../../utilities/CommonHelper";
import NsButton from "./NsButton";

class NsAddToCartButton extends React.Component {

    onClicked = () => this.onChanged(this.props.isAuthenticated ? 1 : null);

    onChanged = (value) => CommonHelper.safeFunctionCall(this.props.valueChanged, value);

    render() {
        const { isAuthenticated, value, isLoading, showIconButton, isBasket, isSmallSize, hasDeleteIcon } = this.props;

        return isAuthenticated && !!value
            ? <NsGroupNumberInput key={value} label={getTranslatedRawText('')}
                value={CommonHelper.toInt(value)}
                isBasket={isBasket}
                isSmallSize={isSmallSize}
                hasDeleteIcon={hasDeleteIcon}
                disabled={true}
                isLoading={isLoading}
                inputProps={{ min: 0, max: 100 }}
                step={1}
                addButtonDisabled={false}
                minusButtonDisabled={false}
                onChanged={this.onChanged}
            />
            : <NsButton label={getTranslatedRawText(showIconButton ? '' : 'product.store.addToBasket')}
                startIcon={showIconButton ? 'ns-icon-plus' : ''}
                size='small'
                className='primary-outline'
                loading={isLoading}
                onClick={this.onClicked} />
    }
}

export default NsAddToCartButton;