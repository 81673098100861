import React, { Component } from "react";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import NsCard from "../../../components/cards/NsCard";
import NsButton from "../../../components/buttons/NsButton";
import NsTypography from "../../../components/text/NsTypography";
import ProductsMobileFilter from "./ProductsMobileFilter";
import NsChip from "../../../components/chips/NsChip";
import NsModal from "../../../components/modals/NsModal";
import NsRadioGroupButtons from "../../../components/buttons/NsRadioGroupButtons";
import { isEmpty } from "../../../utilities/ValidationHelper";
import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import NsReachResult from "../../../components/metaTags/NsReachResult";
import Enumerations from "../../../configs/Enumerations";

class ContentHeader extends Component {
  constructor(props) {
    super(props)
    this.sortButtonList = ConstantsWithTranslation.getSortItems().filter(item => !!item)
    this.state = {
      sortItemSelected: props.filters.sortBy,
      isFilterModalOpen: false,
      isSortFilterOpen: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      sortItemSelected: nextProps.filters.sortBy,
    });
  }

  sortButtonClicked = (id) => {
    const sortItemSelected = this.sortButtonList.find(item => item.id === Number(id));
    this.setState({ sortItemSelected, isSortFilterOpen: false },
      () => this.props.sortFilter(this.state.sortItemSelected));
  }
  modalToggle = () => {
    this.setState({ isFilterModalOpen: !this.state.isFilterModalOpen });
  }

  sortToggle = () => {
    this.setState({ isSortFilterOpen: !this.state.isSortFilterOpen });
  }

  removeAllFiltersClicked = () => {
    this.props.removeAllFilters();
  }

  render() {
    const { sortItemSelected, isFilterModalOpen, isSortFilterOpen } = this.state;
    const { filters, productsFilterLabel, products, isStoreProducts } = this.props;
    const isFiltered = productsFilterLabel.some(item => !isEmpty(item));
    const showMobileFilters = productsFilterLabel.map((item, index) =>
      <NsChip key={index}
        chipLabel={item.filterLabel}
        size='small'
        className='my-1 mx-1'
        deleteChipClicked={() => item.labelDeleteClicked(item.id)} />
    );
    return (
      <NsCard className='content-header px-2 py-2'
        contentClassName='d-flex align-items-center'>
        <div className="w-100 d-flex justify-content-between flex-wrap">
          {productsFilterLabel?.length > 0 ?
            <div className="d-flex align-items-center ps-2">
              <h1 className="font-size-16 mb-2 text-truncate content-header-text">
                {productsFilterLabel.map(filter => filter.filterLabel).join(' | ')}
              </h1>
            </div> :
            <div className="d-flex align-items-center ps-2">
              <h1 className="font-size-16 mb-2 text-truncate content-header-text">{getTranslatedRawText(isStoreProducts ? 'product.common.storeList' : 'product.common.groupSheetList')}</h1>
            </div>
          }
          <div className='d-none d-lg-flex align-items-center'>
            <NsTypography variant='inherit' className='primary-paragraph me-2'>
              {getTranslatedRawText('product.sortItems.sortBy')}
            </NsTypography>
            <ShowGroupButton sortItemSelected={sortItemSelected}
              buttons={this.sortButtonList}
              sortButtonClicked={this.sortButtonClicked} />
          </div>
          <div className='d-block d-lg-none w-auto'>
            <div className='d-flex'>
              <NsButton className="primary-outline"
                label={sortItemSelected.label}
                startIcon='ns-icon-sort-by'
                size='small'
                onClick={this.sortToggle} />
              <NsButton className="primary product-tour-sidebar-mobile"
                wrapperClassName='mx-1'
                label='product.common.filter'
                startIcon='ns-icon-filter'
                size='small'
                onClick={this.modalToggle} />
              {isFiltered && <NsButton className="secondary-outline"
                label='product.common.removeAllFilters'
                startIcon='ns-icon-delete'
                size='small'
                onClick={this.removeAllFiltersClicked} />
              }
            </div>
            <ProductsSortFilter radioList={this.sortButtonList}
              sortItemSelected={sortItemSelected}
              isSortFilterOpen={isSortFilterOpen}
              sortToggle={this.sortToggle}
              radioButtonClicked={this.sortButtonClicked} />
            <ProductsMobileFilter filters={filters}
              isStoreProducts={isStoreProducts}
              isFilterModalOpen={isFilterModalOpen}
              modalToggle={this.modalToggle}
              submitFilter={this.props.submitFilter}
              submitMobileFilters={this.props.submitMobileFilters} />
            <div className='d-flex flex-wrap flex-nowrap mobile-categories'>
              {showMobileFilters}
            </div>
          </div>
        </div>

        <NsReachResult reachResultType={Enumerations.reachResultType.itemListProducts}
          products={products}
          primaryTitle={sortItemSelected?.label}
        />
      </NsCard>
    );
  }
}

function ShowGroupButton(props) {
  const { sortItemSelected, buttons, sortButtonClicked } = props;
  const buttonList = buttons.map((item, index) => (
    <NsButton key={index}
      size='small'
      className={sortItemSelected.id === item.id ? "primary my-0" : "primary-outline my-0"}
      onClick={() => sortButtonClicked(item.id)}
      wrapperClassName="mx-1 my-0"
      label={item.label} />
  ));
  return buttonList;
}

function ProductsSortFilter(props) {
  const { sortItemSelected, radioList, radioButtonClicked } = props;
  return (
    <NsModal maxWidth="sm"
      dialogTitle={getTranslatedRawText('product.sortItems.sortBy')}
      showModal={props.isSortFilterOpen}
      closeModal={props.sortToggle} >
      <NsRadioGroupButtons radioList={radioList}
        selectedItem={sortItemSelected.id}
        radioButtonClicked={radioButtonClicked} />
    </NsModal>
  );
}

export default ContentHeader;