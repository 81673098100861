import React, { Component } from 'react';

import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { GetAgencyProductsService } from '../../../../services/ProfileSetting/AgencyService';
import { getTranslatedRawText, isEnCulture } from '../../../../utilities/CultureHelper';
import UpBaseAgencySearchOptions from './bases/UpBaseAgencySearchOptions';
import UserPanelWrapper from '../../UserPanelWrapper';
import Enumerations from '../../../../configs/Enumerations';

class UpAgencyProducts extends Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                field: 'productName',
                headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
                width: 500
            },
            {
                field: 'companyPriceChangePercent',
                headerName: getTranslatedRawText('profile.dataGridColumns.priceChange'),
                dataType: Enumerations.gridType.price,
                width: 200,
                cellClass: `${!!isEnCulture() && 'text-end not-rtl'}`
            },
            {
                field: 'agancyDiscountPercent',
                headerName: getTranslatedRawText('profile.dataGridColumns.agencyDiscount'),
                dataType: Enumerations.gridType.price,
                width: 200,
            },
        ]
        this.state = {
            isLoading: false,
            searchButtonTimeStamp: null,
            searchTerm: null,
            callServiceDate: null,
        }
    }

    searchFormClicked = (parameters) => this.setState({ searchTerm: parameters.searchTerm, callServiceDate: new Date() });

    getData = (rows, isLoading) => this.setState({ isLoading });

    render() {
        const { isLoading, callServiceDate, searchTerm } = this.state;

        return (
            <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.agencyProducts')}
                titleIcon='ns-icon-agency-products'>

                <UpBaseAgencySearchOptions searchFormClicked={this.searchFormClicked}
                    autoFocus={true}
                    isLoading={isLoading}>

                </UpBaseAgencySearchOptions>

                <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                    service={GetAgencyProductsService}
                    parameters={{
                        productName: searchTerm
                    }}
                    getData={this.getData}
                    columnDefs={this.columns}
                    hasPagination={true} />

            </UserPanelWrapper>
        )
    }
}

export default UpAgencyProducts;