import React, { useState } from 'react';

import NsButton from '../../../../components/buttons/NsButton';
import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import Enumerations from '../../../../configs/Enumerations';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import EditSmsModal from './EditSmsModal';

const SmsConfig = () => {

    const [pageLength, setPageLength] = useState(CommonHelper.defaultRowPerPage());
    const [isLoading, setIsLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isShowEditSmsModal, setIsShowEditSmsModal] = useState(false);
    const [selectedSmsId, setSelectedSmsId] = useState(null);

    const rows = [
        {
            id: 1,
            title: 'sms signIn',
            content: 'sms config signIn',
            isActive: true,
            createAt: '2021/12/12',
            createByUser: 'Kazem',
            editAt: '2021/12/19',
            editByUser: 'Mohsen',
        }
    ]

    const columns = [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: true,
                editClicked: (id) => editSmsClicked(id),
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 400
        },
        {
            field: 'content',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.content'),
            width: 400
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 90,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 150
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 150,
            cellClass: `${!!isEnCulture() && 'text-end not-rtl'}`,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 150
        },
    ]

    const getSmsConfigs = () => {

    }

    const currentPageChanged = (currentPage) => setCurrentPage(currentPage);

    const pageLengthChanged = (pageLength) => setPageLength(pageLength);

    const editSmsClicked = (id) => {
        setIsShowEditSmsModal(true);
        setSelectedSmsId(id);
    }

    const closeModal = () => {
        setIsShowEditSmsModal(false);
        getSmsConfigs();
    }

    return (
        <NsPageMainContentWrapper header={getTranslatedRawText("adminPanel.sidebar.smsConfig")}
            icon='ns-icon-setting h1'>

            {false && <div className="d-flex justify-content-end mb-3">
                <NsButton className="primary"
                    loading={isLoading}
                    startIcon='ns-icon-add'
                    label={getTranslatedRawText("adminPanel.common.new")}
                    onClick={() => editSmsClicked(null)} />
            </div>}

            <NsDataGridWithServiceCall rows={rows}
                isLoading={isLoading}
                columnDefs={columns}
                totalRows={totalRows}
                rowPerPage={pageLength}
                currentPage={currentPage}
                paginationMode="server"
                handlePagination={currentPageChanged}
                handleRowPerPage={pageLengthChanged} />

            {isShowEditSmsModal && <EditSmsModal closeModal={closeModal}
                selectedSmsId={selectedSmsId}
                isNewSmsConfig={isEmpty(selectedSmsId)} />
            }

        </NsPageMainContentWrapper>
    );
};

export default React.memo(SmsConfig);