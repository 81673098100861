import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { currentUserActionCreators } from '../../redux/reducers/CurrentUserReducer';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import '../../assets/sass/components/modals/NsMessageClassModal.scss';
import { isEmpty } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from './../../configs/Enumerations';
import NsStickyButton from '../buttons/NsStickyButton';
import NsTypography from '../text/NsTypography';
import Links from '../../configs/links/AllLinks';
import NsModal from './NsModal';

class NsMessageClassModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            hasCancelButton: (props.messageItems[0].messageType === Enumerations.messageType.question) || (props.messageItems[0].messageType === Enumerations.messageType.warning),
            show: !!props.show,
            showOnlyWhenHasNotSuccess: !!props.showOnlyWhenHasNotSuccess,
            messageItems: props.messageItems.filter(item => item?.messageType !== Enumerations.messageType.needCaptcha) || [],
            isLoading: false,
        };
    }

    onClose = (confirm) => {
        this.setState({ isLoading: true });
        CommonHelper.safeFunctionCall(this.props.onClose, !!confirm, this.state.hasCancelButton);
        !this.props.onClose && this.setState({ show: false });

        this.props.messageItems.map(item => {
            if ((item.messageType === Enumerations.messageType.authentication) ||
                (item.messageType === Enumerations.messageType.reSigninRequired)) {
                this.props.removeCurrentUser();
                this.props.history.push(getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn, { 'back-url': window.location.pathname }));
            }
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showDate !== this.props.showDate)
            this.setState({
                show: !!nextProps.show,
                showOnlyWhenHasNotSuccess: !!nextProps.showOnlyWhenHasNotSuccess,
                messageItems: nextProps.messageItems,
                // hasCancelButton: nextProps.messageItems[0].messageType === Enumerations.messageType.question,
            });
    }

    messageItemIcon = (messageItem) => {

        switch (messageItem.messageType) {
            case Enumerations.messageType.success:
                return 'ns-icon-circle-confirm icon-size success-color m-0';

            case Enumerations.messageType.error:
            case Enumerations.messageType.needCaptcha:
                return 'ns-icon-critical icon-size red-color m-0';

            case Enumerations.messageType.warning:
            case Enumerations.messageType.reSigninRequired:
                return 'ns-icon-exclamation icon-size warning-color m-0';

            case Enumerations.messageType.question:
                return 'ns-icon-question icon-size info-color m-0';

            case Enumerations.messageType.info:
            default:
                return 'ns-icon-information icon-size info-color m-0';
        }

    }

    isModalShowable = () => {

        if (isEmpty(this.state.messageItems))
            return false;

        if (this.state.showOnlyWhenHasNotSuccess)
            return this.state
                .messageItems
                .filter(messageItem => messageItem.messageType !== Enumerations.messageType.success)
                .length > 0;

        return true;
    }

    displayButtons = () => {
        let buttonList = [CommonHelper.createStickyButtonItem("common.ok", '', () => this.onClose(true), 'primary ')];
        this.state.hasCancelButton && buttonList.push(
            CommonHelper.createStickyButtonItem("common.cancel", '', () => this.onClose(false), 'primary-outline'));
        return buttonList;
    }

    filterMessages = (messageType) => this.state.messageItems.filter(message => message.messageType === messageType);

    showSortedMessageItems = () => {
        const sortedMessages = [
            this.filterMessages(Enumerations.messageType.authentication),
            this.filterMessages(Enumerations.messageType.reSigninRequired),
            this.filterMessages(Enumerations.messageType.needCaptcha),
            this.filterMessages(Enumerations.messageType.authorization),
            this.filterMessages(Enumerations.messageType.error),
            this.filterMessages(Enumerations.messageType.warning),
            this.filterMessages(Enumerations.messageType.question),
            this.filterMessages(Enumerations.messageType.info),
            this.filterMessages(Enumerations.messageType.success),
        ];

        return sortedMessages.map((message, index) => {
            if (!isEmpty(message)) {
                return (
                    <div key={index} className='d-flex align-items-center'>
                        <div className='me-3'>
                            <i className={this.messageItemIcon(message[0])} />
                        </div>
                        <div className='flex-grow-1'>
                            {message.map((item, idx) =>
                                <NsTypography key={idx} className='mb-2'>{item.messageText}</NsTypography>
                            )}
                        </div>
                    </div>
                )
            }
        })
    }

    render() {

        if (!this.isModalShowable())
            return (<div></div>);
        return (
            <NsModal
                dialogTitle={!!this.state.title ? this.state.title : null}
                showModal={this.state.show}
                closeModal={() => this.onClose(false)}
                actions={<NsStickyButton stickyButtons={this.displayButtons()} isLoading={this.state.isLoading} />} >
                {this.showSortedMessageItems()}
            </NsModal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeCurrentUser: () => dispatch(currentUserActionCreators.removeCurrentUser())
    };
}

export default withRouter(connect(null, mapDispatchToProps)((NsMessageClassModal)));