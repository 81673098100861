import React, { Component } from 'react'

import CommonHelper from '../../../utilities/CommonHelper';
import NsCard from '../../../components/cards/NsCard';
import { GetCmsBlogsFilterPostService, GetCmsNewsFilterPostService } from '../../../services/singlePages/CmsService';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import BnBasesPostMedia from '../contents/bases/BnBasesPostMedia';
import { isEmpty } from '../../../utilities/ValidationHelper';

class BnSidebarPosts extends Component {

    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.state = {
            posts: [],
        }
    }

    componentDidMount = () => this.getPosts();

    getPosts = () => {
        if ((this.isBlog && !this.props.isReversePost) || (!this.isBlog && this.props.isReversePost))
            GetCmsBlogsFilterPostService(this.props.parameters, this.getCmsFilterPostCallback)
        else
            GetCmsNewsFilterPostService(this.props.parameters, this.getCmsFilterPostCallback);
    }

    getCmsFilterPostCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError)
            this.setState({ posts: resultData.dataItems });
    };

    mapPosts = () => {
        return !isEmpty(this.state.posts) && this.state.posts.map(post =>
            <div key={post?.id} className='mb-2'>
                <BnBasesPostMedia post={post}
                    isReversePost={this.props.isReversePost}
                    otherClass='title-color' />
            </div>
        )
    }

    render() {
        const { title } = this.props;
        return (!isEmpty(this.state.posts) &&
            <NsCard className='p-3 categories mb-3' >
                <p className='section-title'>
                    {getTranslatedRawText(!!title ? title : !this.isBlog ? 'adminPanel.sidebar.blog' : 'adminPanel.sidebar.news')}
                </p>
                {this.mapPosts()}
            </NsCard>
        )
    }
}

export default BnSidebarPosts;
