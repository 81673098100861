const ClaimTranslationFa = {
    adminPanelClaim: {
        AdminPanel: 'پنل مدیریت',

        FinancialDashboard: 'داشبورد مالی',
        getApDfSaleGadgets: 'دریافت فروش کل در ابزارها',
        getApDfTotalOnlinePayments: 'دریافت تمامی پرداخت‌های آنلاین',
        getApDfTotalSales: 'دریافت فروش کل',
        getApDfOnlinePaymentsGateway: 'دریافت پرداخت آنلاین به تفکیک درگاه',
        getApDfOrderByTurnaround: 'دریافت سفارش‌ها به تفکیک سرعت چاپ',
        getApDfOrderByApplicationType: 'دریافت سفارش‌ها به تفکیک نوع سفارش',

        BusinessDashboard: 'داشبورد تجاری',
        getApDbTotalSales: 'دریافت کل فروش',
        getApDbTotalOrders: 'دریافت کل سفارش‌ها',
        getApDbOnlinePaymentStatus: 'دریافت پرداخت آنلاین با تفکیک وضعیت',
        getApDbTotalOnlinePayments: 'دریافت تمامی پرداخت‌های آنلاین',

        UserDashboard: 'داشبورد مشتریان',
        getApDuTotalUsers: 'دریافت کل کاربران',
        getApDuLastJoinedUsers: 'دریافت آخرین کاربران ثبت نام شده',
        getApDuRemainingLimitWithCredit: 'دریافت کاربران با اعتبار',
        getApDuRemainingLimitWithoutCredit: 'دریافت کاربران بدون اعتبار',
        getApDuGrantedCredit: 'دریافت کاربران اعتبار داده شده',

        Dashboard: 'داشبورد پنل‌ها',
        getApDSmsPanelCredit: 'دریافت اعتبار پنل‌های اس ام اس',

        PaymentAuditDashboards: 'داشبورد ممیزی',
        getApDauAggregateStatistics: 'دریافت آمار تجمیع ممیزی شده ها و یافت نشده ها',
        getApDauNotProcessedPaymentAuditCount: 'دریافت تعداد پردازش نشده ها',
        getApDauPaymentGatewaysMessages: 'دریافت پیامهای درگاه',
        getApDauStatisticsBasedOnAuditedAndPaymentStatuses: 'دریافت آمار ممیزی ها به تفکیک حالت',
        getApDauStatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes: 'دریافت ناهمسانها به تفکیک حالت',

        UserFeedbackDashboard: 'داشبورد بازخورد',
        getApDfbComments: 'دریافت نظر بازخوردها',
        getApDfbRatingParametersChart: 'دریافت چارت پارامترهای امتیاز دهی',
        getApDfbStrengthWeeknessChart: 'دریافت چارت نقاط قدرت و ضعف',
        getApDfbProducts: 'دریافت لیست محصولات بازخورد شده',
        getApDfbTotalByStatusGedgets: 'دریافت بازخوردها به تفکیک وضعیت',
        getApDfbUserFeedbacks: 'دریافت چارت مجموع بازخوردها و میانگین امتیازشان',
        getApDfbStatuses: 'دریافت مجموع تعداد و میانگین امتیاز بازخوردها',
        getApDfbByTypeMetricAndRates: 'دریافت پارامترهای امتیاز دهی بازخوردها',
        getApDfbByStateTypesAndDateChart: 'دریافت جارت بازخوردها به تفکیک تاریخ',
        getApDfbCitiesGrid: 'دریافت بازخوردهای شهرها',
        getApDfbCustomers: 'دریافت بازخوردهای مشتریان',
        getApDfbProvinces: 'دریافت بازخوردهای استان ها',
        getApDfbTotalStatusByFilterOptions: 'دریافت مجموع بازخوردها بر اساس وضعیت و گزینه های فیلتر',
        getApDfbSharedSheetOrderFeedback: 'دریافت بازخورهای سفارشات عمومی',
        getApDfbEmployeesGrid: 'دریافت بازخوردهای کارمندان',

        UserCashbackDashboard: 'داشبورد پول برگشتی',
        getApCshbUsersCashbackForecastes: 'دریافت پول های برگشتی مشتریان',
        getApCshbFromInquiryTimes: 'دریافت لیست تاریخ از در هدر',
        getApCshbToInquiryTimes: 'دریافت لیست تا تاریخ در هدر',
        getApCshbExcelReport: 'دریافت گزارش اکسل داشبورد برگشت پول',
        getApCshbForcastsWithGrowthPercent: 'دریافت پیش بینی برگشت پول با درصد رشد',

        UserCustomer: 'مشتریان',
        getApCustomers: 'دریافت لیست مشتریان',
        getApCustomerById: 'دریافت اطلاعات مشتری خاص',
        editApCustomer: 'ویرایش اطلاعات مشتری',
        getApUserBasicInfoById: 'دریافت اطلاعات پایه مشتری',
        updateApCustomerToken: 'ابطال توکن مشتری',
        getApCustomersDuplicatePhoneNumbers: 'دریافت مشتریان با دو شماره موبایل',
        getApUserCredit: 'دریافت اعتبار مشتری',
        getApCustomerFieldVisibilityType: 'دریافت فیلدهای فابل نمایش مشتری',

        UserSupporter: 'پشتیبانان',
        getApSupporters: 'دریافت لیست پشتیبانان',
        getApSupporterById: 'دریافت اطلاعات پشتیبان خاص',
        getApSupporterBasicInfoById: 'دریافت اطلاعات پایه پرسنل',
        editApSupporter: 'ویرایش اطلاعات پشتیبان',
        editApUserCredit: 'افزایش اعتبار مشتری',

        UserBusinessPartner: 'همکاران تجاری',
        getApUserBusinessPartners: 'دریافت لیست همکاران تجاری',
        getApUserBusinessPartnerById: 'دریافت اطلاعات همکار تجاری خاص',
        editApUserBusinessPartner: 'ویرایش اطلاعات همکار تجاری ',
        getApUserBusinessPartnerBasicInfoById: 'دریافت اطلاعات پایه همکاران تجاری',

        UserAddressesAdmin: 'آدرس های مشتری',
        getApUserActiveAddresses: 'دریافت آدرس های فعال مشتری',

        UserDepartment: 'چارت سازمانی',
        postApAddUserDepartment: 'افزودن چارت سازمانی به کاربر',
        getApUserDepartments: 'دریافت چارتهای تعریف شده',
        getApUserDepartmentById: 'دریافت چارت تعریف شده خاص',
        postApUserDepartment: 'افزودن چارت سازمانی جدید',
        editApUserDepartment: 'ویرایش چارت سازمانی خاص',
        deleteApUserDepartment: 'حذف چارت سازمانی خاص',
        getApDepartmentsTree: 'دریافت لیست درختی چارتها',
        getApUserDepartmentsTree: 'دریافت لیست درختی چارتهای کاربر خاص',
        getApDepartmentsList: 'دریافت لیست چارتها',

        SharedSheetOrdersAdmins: 'سفارش‌ها',
        SharedSheetOrderDetailOptionAdmin: 'اطلاعات ریز هر سفارش',
        getApOrders: 'دریافت لیست سفارش‌ها',
        getApOrderDto: 'دریافت اطلاعات پایه هر سفارش',
        getApOrderDetail: 'دریافت جزییات هر سفارش',
        updateApOrderStatus: 'آپدیت کردن وضعیت سفارش',
        getApOrderStatusHistory: 'دریافت تاریخجه وضعیت هر سفارش',
        getApOrderNasStatusHistory: 'دریافت تاریخجه وضعیت هر سفارش در Nas',
        getApOrderNasStatusHistoryDetail: 'دریافت جزییات تاریخجه وضعیت هر سفارش در Nas',
        deleteApNotConfirmedOrder: 'حذف سفارش‌های تایید نشده',
        getApOrderUnOfficialReport: 'دانلود فاکتور سفارش',
        getApOrderOfficialReport: 'دانلود فاکتور رسمی',
        getApStatusQueue: ' دریافت اطلاعات در منوی صف',
        getApOrderFilterPoints: 'دریافت اطلاعات فیلتر هر سفارش',
        getApOrderBillBookOptions: 'دریافت اطلاعات سفارش‌های فاکتور',
        getApOrderPunchPoints: 'دریافت اطلاعات پانج هر سفارش',
        getApOrderFoldLines: 'دریافت اطلاعات خط تای هر سفارش',
        getApOrderAdditionalInformation: 'دریافت اطلاعات مازاد هر سفارش',
        getApOrderProductDetail: 'دریافت جزییات محصول',
        getApOrderUserAggrementDetail: 'دریافت جزییات توافقنامه مشتری در سفارش',
        getApStatusQueueByServerName: 'دریافت اطلاعات صف به تفکیک سرور',
        getApOrderEstimatedTime: 'دریافت اطلاعات تخمین زمان سفارش',
        getApOrderQueueByRemainingToEstimatedTime: 'دریافت صف سفارش ها بر اساس باقیمانده زمان تخمین تحویل',

        StoreProductOrdersAdmin: 'سفارش های محصولات آماده',
        getApStoreOrders: 'دریافت سفارش های محصولات آماده',
        getApStoreOrderOfficialReport: 'دریافت فاکتور رسمی محصولات آماده',

        PaymentsAdmins: 'پرداخت‌ها',
        getApPayments: 'دریافت لیست پرداخت‌ها',
        getApPaymentById: 'دریافت اطلاعات پرداخت خاص',
        getApPaymentOrders: 'دریافت پرداخت برای سفارش',
        getApPaymentIncreaseCredit: 'دریافت پرداخت افرایش اعتبار',
        getApPaymentStatusHistory: 'دریافت تاریخچه وضعیت پرداخت',
        getApPaymentReport: 'دانلود گزارش پرداخت',
        updateApPaymentStatus: 'آپدیت وضعیت پرداخت',
        getApPaymentsHasProblem: 'دریافت پرداختی‌های مشکل دار',
        getApAllPaymentsExcelReport: 'دریافت گزارش تمامی پرداخت ها',

        PaymentAudits: 'ممیزی',
        getApPaymentAudits: 'دریافت تمامی ممیزی ها',
        getApPaymentAuditById: '(پیشرفته)دریافت ممیزی خاص',
        getApPaymentAuditGateways: 'دریافت درگاه های پرداخت مجاز برای ممیزی',
        getApPaymentAuditHistories: 'دریافت تارخچه ممیزی ها(پیشرفته)',
        updateApPaymentAuditStateToFail: 'آپدیت حالت باز به نا موفق',
        updateApPaymentAuditMatchingType: 'آپدیت حالتهای همسان',
        getApAllPaymentAuditsExcelReport: 'دریافت گزارش پرداخت های ممیزی شده',
        updateApPaymentAuditsMatchingTypes: 'آپدیت پرداختهای ممیزی ناهمسان ها',

        PaymentAuditSources: 'منابع ممیزی',
        getApPaymentAuditAsanPardakhts: 'دریافت جزییات فایل آسان پرداخت',
        getApPaymentAuditAsanPardakhtByActionId: 'دریافت ردیف خاص از فایل آسان پرداخت(نرمال)',
        getApPaymentAuditSepehrs: 'دریافت جزییات سپهر',
        getApPaymentAuditSepehtByActionId: 'دریافت ردیف خاص از فایل سپهر(نرمال)',
        getApPaymentAuditNgenius: 'دریافت جزییات انجنیوس',
        getApPaymentAuditNgeniusByActionId: 'دریافت ردیف خاص از فایل انجنیوس(نرمال)',
        postApPaymentAuditSources: 'بارگذاری فایل بانکی برای ممیزی',
        getApPaymentAuditSources: 'دریافت آخرین بارگذاری فایلها',

        SharedSheetOrderReport: 'گزارش فرم‌های عمومی',
        getApUsersDebitCredit: 'دربافت گزارش بدهکاری-بستانکاری کاربر',
        getApStatisticalReportOfOrders: 'دریافت گزارش تجمعی سفارش‌ها',
        getApStatisticalReportOfPayments: 'دریافت گزارش تجمعی پرداخت‌ها',

        Shipping: 'ارسال‌ها',
        getApShippings: 'دریافت لیست تمامی ارسال‌ها',
        getApShippingById: 'دریافت اطلاعات پایه ارسال خاص',
        getApShippingGroupSheetOrders: 'دریافت سفارش‌های عمومی هر ارسال',
        getApShippingStoreOrders: 'دریافت سفارش‌های فروشگاهی هر ارسال',
        getApShippingQueueByLastStatuses: 'دریافت صف ارسال ها بر اساس آخرین وضعیت ارسال',
        getApShippingRemainingEstimatedTime: 'دریافت صف ارسال بر اساس زمان باقیمانده',
        getApQueueByShippingMethods: 'دریافت صف ارسال ها بر اساس روش های ارسال',
        getApShippingMethods: 'دریافت روش های ارسال',
        getApShippingPlans: 'دریافت پلن های ارسال',

        CustomerFiles: 'فایل مشتریان',
        getApCustomerFiles: 'دریافت لیست تمامی فایل‌ها',
        getApCustomerFileById: 'دریافت اطلاعات هر فایل',
        getApDownloadOriginFile: 'دانلود فایل اوریجینال',

        PprContents: 'محتوای محصول‌ها',
        getApPprContents: 'دریافت محتوای محصول‌ها',
        getApPprContentByPprId: 'دریافت محتوای محصول خاص با آیدی',
        getApPprContentByName: 'دریافت محتوای محصول خاص با نام',
        postApPprContent: 'اضافه کردن محتوای جدید محصول',
        editApPprContentById: 'ویرایش محتوای محصول',
        getApPprContentBasicInfo: 'دریافت اطلاعات پایه محصول خاص',

        FeedbackToAnnouncementContents: 'بازخوردهای اطلاعیه‌ها',
        getApFeedbackAnnouncements: 'دریافت بازخورد اطلاعیه‌ها',
        getApFeedbackAnnouncementBasicInfo: 'دریافت اطلاعات پایه بازخورد هر اطلاعیه',

        FeedbackToSharedSheetOrders: 'بازخورد‌های سفارش‌های عمومی',
        getApFeedbackShareSheetOrders: 'دریافت بازخورد سفارش‌های عمومی',
        getApFeedbackShareSheetOrderBasicInfo: 'دریافت اطلاعات پایه بازخورد هر سفارش',

        FeedbackToEmployees: 'بازخوردهای کارمندان',
        getApFeedbackToEmployees: 'دریافت بازخوردها به کارمندان ',
        getApFeedbackToEmployeeBasicInfo: 'دریافت اطلاعات پایه بازخورد به کارمند',

        UserFeedbacks: 'بازخورد کاربر',
        getApFeedbackActivityHistory: 'دریافت تاریخچه فعالیت بازخورد',
        updateApFeedbackIsPublic: 'ویرایش نمایش همگانی بازخورد',
        postApFeedbackAddState: 'افزودن وضعیت جدید بازخورد',

        FeedbackTypes: 'بازخوردها',
        getApFeedbackTypes: 'دریافت انواع بازخوردها',
        getApFeedbackTypeBasicInfo: 'دریافت اطلاعات پایه نوع بازخورد',
        updateApFeedbackTypeState: 'ویرایش وضعیت نوع بازخورد',

        FeedbackTypeMetrics: 'معیارها',
        getApFeedbackTypeMetrics: 'دریافت معیارها',
        getApFeedbackTypeMetricById: 'دریافت معیار خاص',
        postApFeedbackTypeMetric: 'افزودن معیار',
        editApFeedbackTypeMetric: 'ویرایش کردن معیار',
        deleteApFeedbackTypeMetric: 'حذف کردن معیار',
        editApSortFeedbackTypeMetric: 'ویرایش ترتیب معیارها',

        DemandedServiceTypes: 'معیار پشتیبانی بیشتر',
        getApFeedbackTypeDemandServices: 'دریافت معیارهای پشتیبانی بیشتر ',
        getApFeedbackTypeDemandServiceById: 'دریافت معیار خاص از پشتیبانی بیشتر',
        postApFeedbackTypeDemandService: 'اقزودن معیار پشتیبانی بیشتر',
        editApFeedbackTypeDemandService: 'ویرایش معیار پشتیبانی بیشتر',
        deleteApFeedbackTypeDemandService: 'حذف معیار پشتیبانی بیشتر',
        editApSortFeedbackTypeDemandServices: 'ویرایش ترتیب معیارهای پشتیبانی بیشتر',

        Blog: 'بلاگ',
        BlogCategory: 'دسته‌بندی در بلاگ‌ها',
        getApBlogs: 'دریافت لیست بلاگ‌ها',
        getApBlogById: 'دریافت اطلاعات هر بلاگ',
        postApBlog: 'اضافه کردن بلاگ جدید',
        editApBlog: 'ویرایش بلاگ',
        deleteApBlog: 'حذف بلاگ',
        getApBlogCategories: 'دریافت لیست دسته‌بندی بلاگ‌ها',
        getApBasicBlogCategories: 'دریافت لیست دسته‌بندی‌ها در منو',
        getApBlogCategoryById: 'دریافت اطلاعات هر دسته‌بندی ',
        deleteApBlogCategory: 'حذف دسته‌بندی',
        editApBlogCategory: 'ویرایش دسته‌بندی ',
        postApBlogCategory: 'اضافه کردن دسته‌بندی جدید',

        News: 'اخبار',
        NewsCategory: 'دسته‌بندی در اخبار',
        getApNews: 'دریافت لیست اخبار',
        getApNewsById: 'دریافت اطلاعات هر خبر',
        postApNews: 'اضافه کردن خبر جدید',
        editApNews: 'ویرایش خبر',
        deleteApNews: 'حذف خبر',
        getApNewsCategories: 'دریافت لیست دسته‌بندی اخبار',
        getApBasicNewsCategories: 'دریافت لیست دسته‌بندی‌ها در منو',
        getApNewsCategoryById: 'دریافت اطلاعات هر دسته‌بندی',
        postApNewsCategory: 'اضافه کردن دسته‌بندی جدید',
        editApNewsCategory: 'ویرایش دسته‌بندی',
        deleteApNewsCategory: 'حذف دسته‌بندی',

        AnnouncementContent: 'اطلاعیه‌ها',
        getApAnnouncements: 'دریافت لیست اطلاعیه‌ها',
        getApAnnouncementById: 'دریافت اطلاعات  اطلاعیه خاص',
        getApAnnouncementTitleContentById: 'دریافت اطلاعات  اطلاعیه محتویات تیتر خاص',
        postApAnnouncement: 'اضافه کردن اطلاعیه جدید',
        editApAnnouncement: 'ویرایش اطلاعیه خاص',
        editApSortAnnouncement: 'ویرایش ترتیب اطلاعیه خاص',
        deleteApAnnouncement: 'حذف اطلاعیه خاص',

        StaticPageAdmin: 'تک صفحات',
        getApSpecificStaticPageList: 'دریافت لیست سطرهای هر صفحه استاتیک',
        getApSpecificStaticPageRowById: 'دریافت اطلاعات هر سطر از صفحه استاتیک',
        postApSpecificStaticPageRow: 'اضافه کردن سطرجدید در هر صفحه استاتیک',
        editApSpecificStaticPageRow: 'ویرایش سطر در هر صفحه استاتیک',
        editApSortStaticPageAdmin: 'ویرایش ترتیب سطرها در هر صفحه استاتیک',
        deleteApSpecificStaticPageRow: 'حذف سطر در هر صفحه استاتیک',

        FileUploader: 'بارگذاری کننده فایل',
        postFileUploader: 'اضافه کردن بارگذاری کننده فایل',

        Slider: 'اسلایدر',
        getApSliders: 'دریافت لیست اسلایدها',
        getApSliderById: 'دریافت اطلاعات هر اسلاید',
        postApSlider: 'اضافه کردن اسلاید جدید',
        editApSlider: 'ویرایش اسلاید خاص',
        editApSortSlider: 'ویرایش ترتیب اسلاید خاص',
        deleteApSlider: 'حذف اسلاید خاص',

        PromoteContent: 'تبلیغ‌ها',
        getApPromoteContent: 'دریافت اطلاعات هر تبلیغ',
        editApPromoteContent: 'ویرایش محتوای هر تبلیغ',

        StoreProductProperties: 'ویژگی‌های محصول‌های فروشگاهی',
        StoreProducts: 'محصول‌های فروشگاهی',
        StoreProductSupplierPriceDiscounts: 'میزان تخفیف‌ها تامین کننده محصول‌های فروشگاهی',
        StoreProductSupplierPrices: 'قیمت‌های تامین کننده محصول‌های فروشگاهی',
        StoreProductUnits: 'واحد‌های محصول‌های فروشگاهی',

        deleteApStoreProductProperty: ' حذف ویژگی‌های محصول‌های فروشگاهی',
        getApStoreProductProperty: ' دریافت ویژگی‌های محصول‌های فروشگاهی',
        editApStoreProductProperty: ' ویرایش ویژگی‌های محصول‌های فروشگاهی',
        postApStoreProductProperty: ' اضافه کردن ویژگی‌های محصول‌های فروشگاهی',
        deleteApStoreProduct: 'حذف محصول‌های فروشگاهی',
        getApStoreProduct: 'دریافت هر محصول فروشگاهی',
        getApStoreProducts: 'دریافت لیست محصول‌های فروشگاهی',
        editApStoreProduct: 'دریافت محصول‌های فروشگاهی',
        postApStoreProduct: 'دریافت محصول‌های فروشگاهی',
        deleteApStoreProductSupplierPriceDiscount: 'حذف میزان تخفیف‌ها تامین کننده محصول‌های فروشگاهی',
        getApStoreProductSupplierPriceDiscountsById: 'دریافت میزان تخفیف‌ها تامین کننده برای هر محصول فروشگاهی',
        getApStoreProductSupplierPriceDiscounts: 'دریافت میزان تخفیف‌ها تامین کننده محصول‌های فروشگاهی',
        editApStoreProductSupplierPriceDiscounts: 'ویرایش میزان تخفیف‌ها تامین کننده محصول‌های فروشگاهی',
        postApStoreProductSupplierPriceDiscounts: 'اضافه کردن میزان تخفیف‌ها تامین کننده محصول‌های فروشگاهی',
        deleteApStoreProductSupplierPrices: 'حذف قیمت‌های تامین کننده محصول‌های فروشگاهی',
        getApStoreProductSupplierPricesById: 'دریافت قیمت‌های تامین کننده هر محصول فروشگاهی',
        getApStoreProductSupplierPrices: 'دریافت قیمت‌های تامین کننده محصول‌های فروشگاهی',
        editApStoreProductSupplierPrices: 'ویرایش قیمت‌های تامین کننده محصول‌های فروشگاهی',
        postApStoreProductSupplierPrices: 'اضافه کردن قیمت‌های تامین کننده محصول‌های فروشگاهی',
        deleteApStoreProductUnits: 'حذف واحد‌های محصول‌های فروشگاهی',
        getApStoreProductUnits: 'دریافت لیست واحد‌های محصول‌های فروشگاهی',
        editApStoreProductUnits: 'ویرایش واحد‌های محصول‌های فروشگاهی',
        postApStoreProductUnits: 'اضافه کردن واحد‌های محصول‌های فروشگاهی',

        getApDuInactiveUsers: 'دریافت کاربران کم کار',
        editResolveOrderPaymentProblem: 'ویرایش مشکلات پرداخت سفارش',
        deleteFileUploader: 'حذف بارگذاری کننده فایل',
        getFileUploader: 'دریافت بارگزاری کننده فایل',

        Agency: 'نمایندگی',
        getApAgencyBasicDetails: 'دریافت اطلاعات پایه نمایندگی',
        getApAgencies: 'دریافت نمایندگی‌ها',
        getApAgencyById: 'دریافت نمایندگی با آیدی',
        getApProductsOfAgency: 'دریافت محصول‌های نمایندگی',
        getApDaLatestSubmittedAgencies: 'دریافت آخرین نمایندگی‌های تایید شده',
        getApDaTotalSalesAmountAgencies: 'میزان فروش نمایندگی‌ها',
        getApDaPopularAgencies: 'نمایندگی‌های محبوب',
        getApDaActiveStates: 'استان‌های فعال',

        Festival: 'جشنواره',
        getApFestivals: 'دریافت جشنواره‌ها',
        getApFestivalById: 'دریافت جشنواره',
        getApFestivalPrizes: 'دریافت جوایز جشنواره',
        getApFestivalWinners: 'دریافت برنده‌های جشنواره',
        getApFestivalWinnersActionId: 'دریافت آیدی برنده‌های جشنواره',

        CmsLog: 'لاگ ها',
        getApCmsLogs: 'دریافت لاگ ها',
        getApCmsLogById: 'دریافت لاگ خاص',
        getApCmsLogException: 'دریافت استثناهای لاگ',
        getApCmsLogExceptionType: 'دریافت حالتهای استثناها',
        getApCmsLogSources: 'دریافت ماخذ لاگ',

        Downloads: 'مرکز دانلود',
        DownloadCategory: 'دسته‌بندی دانلود',
        getApDownloadCategories: 'دریافت دسته‌بندی‌های دانلود',
        getApDownloadCategoriesDropDown: 'دریافت دسته‌بندی‌های دانلود بصورت لیست',
        getApDownloadCategoryById: 'دریافت اطلاعات هر دسته‌بندی',
        postApDownloadCategory: 'اضافه کردن دسته‌بندی جدید',
        editApDownloadCategory: 'ویرایش دسته‌بندی',
        deleteApDownloadsCategory: 'حذف دسته‌بندی',

        getApDownloads: 'دریافت دانلودها',
        getApDownloadById: 'دریافت اطلاعات هر دانلود',
        postApDownload: 'اضافه کردن دانلود جدید',
        editApDownload: 'ویرایش دانلود',
        deleteApDownloads: 'حذف دانلود',

        DownloadDetails: 'فایل‌های دانلود',
        getApDownloadsByIdDetails: 'دریافت لیست فایل‌های دانلودی هر دانلود',
        getApDownloadDetailsById: 'دریافت اطلاعات فایل دانلود',
        postApDownloadDetails: 'اضافه کردن فایل دانلود',
        editApDownloadDetails: 'ویرایش فایل دانلود',
        deleteApDownloadDetails: 'حذف فایل دانلود',

        Gallery: 'گالری‌ها',
        getApGalleries: 'دریافت گالری‌ها',
        getApGalleryBasicDetails: 'دریافت اطلاعات پایه گالری',
        getApGalleryById: 'دریافت اطلاعات هر گالری',
        postApGallery: 'اضافه کردن گالری',
        editApGallery: 'ویرایش گالری',
        deleteApGallery: 'حذف گالری',

        GalleryCategory: 'دسته‌بندی گالری',
        getApGalleryCategories: 'دریافت دسته‌بندی‌های گالری',
        getApGalleryCategoryById: 'دریافت اطلاعات هر دسته‌بندی',
        postApGalleryCategory: 'اضافه کردن دسته‌بندی جدید',
        editApGalleryCategory: 'ویرایش دسته‌بندی',
        deleteApGalleryCategory: 'حذف دسته‌بندی',

        Advertisement: 'تبلیغ‌ها',
        getApAdvertisements: 'دریافت تبلیغ‌ها',
        getApAdvertisementById: 'دریافا اطلاعات هر تبلیغ',
        postApAdvertisement: 'اضافه کردن تبلیغ',
        editApAdvetisement: 'ویرایش تبلیغ',
        deleteAdvertisement: 'حذف تبلیغ',

        UserAgreements: 'توافق نامه های مشتری',
        UserAgreementSigns: 'امضای توافق نامه های مشتری',
        getApUserAggrements: 'دریافت توافق نامه‌ها',
        getApUserAggrementById: 'دریافت توافق نامه خاص',
        getApUserAggrementDetailById: 'دریافت اطلاعات توافق نامه برای تغییر وضعیت',
        postApUserAggrement: 'ایجاد توافق نامه جدید',
        updateApUserAggrement: 'ویرایش توافق نامه',
        deleteApUserAggrement: 'حذف توافق نامه',
        getApUserAggrementStaticPages: 'دریافت صفحات توافق نامه خاص',
        getApUserAgreementsStaticPageDetailById: 'دریافت جزییات توافق نامه خاص',
        postApUserAggrementStaticPages: 'ایجاد صفحات جدید برای یک توافق',
        editApUserAggrementStaticPages: 'ویرایش صفحات یک توافق',
        deleteApUserAggrementStaticPages: 'حذف صفحات یک توافق',
        updateApUserAggrementState: 'آپدیت کردن وضعیت توافق نامه',
        getApUserAggrementSignHistories: 'دریافت تاریخچه توافق نامه امضا شده',
        getApUserAggrementSignUserInfo: 'دریافت اطلاعات توافق نامه امضا شده',
        getApUserAggrementSignUsers: 'دریافت کاربرانی که این توافق نامه را امضا کرده اند',
    }
};

export default ClaimTranslationFa;