import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PatchRequest, PostRequest } from '../bases/BaseService';

const GetPaymentsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPayments), parameter, callback);
}
const GetPaymentsHasProblemService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPaymentsHasProblem), parameter, callback);
}
const GetPaymentByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPaymentById), id), {}, callback);
}
const GetPaymentIncreaseCreditService = (parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPaymentIncreaseCredit), parameters.paymentId, parameters.id), {}, callback);
}
const GetPaymentOrdersService = (parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPaymentOrders), parameters?.paymentId), {}, callback);
}
const GetPaymentHistoryService = (parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPaymentStatusHistory), parameters?.paymentId, parameters?.id), parameters, callback);
}
const GetPaymentReportService = (id, userId, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPaymentReport), id), { id: userId }, callback);
}
const UpdatePaymentStatusService = (id, callback) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.updateApPaymentStatus), id), {}, callback);
}
const PaymentResolveProblemService = (parameters, callback, showSuccessResponseMessage) => {
    PatchRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPaymentResolveProblem), parameters, callback, showSuccessResponseMessage);
}

const GetPaymentsExcelExportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApPaymentListExcelReport), data, callback);
}

const GetAuditsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAudits), parameters, callback);
}
const GetAuditDetailByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAuditDetailById), id), {}, callback);
}
const GetAuditHistoriesService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAuditHistoriesById), id), {}, callback);
}
const UpdateAuditOpenToFailStatusService = (id, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.updateApAuditOpenToFail), id), {}, callback, showSuccessResponseMessage);
}
const UpdateAuditMatchingTypeService = (id, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.updateApAuditMatchingType), id), {}, callback, showSuccessResponseMessage);
}
const UpdateAuditMatchingTypesService = (parameters, callback, showSuccessResponseMessage) => {
    PatchRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.updateApAuditMatchingTypes), parameters, callback, showSuccessResponseMessage);
}
const GetAuditGatewaysService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAuditGateways), {}, callback);
}
const UploadAuditFile = (file, selectedGatewayType, callback, onUploadProgressCallback) => {
    let data = new FormData();
    data.append('file', file, file.name);
    data.append('paymentGatewayType', selectedGatewayType);
    return PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApAuditFile), data, callback, false, onUploadProgressCallback);
}
const GetAuditFileService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAuditFile), parameters, callback);
}
const GetAuditFileAsanPardakhtService = (parameters, callback) => {
    const { id, actionId, ...params } = parameters
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAuditFileAsanPardakht), id, actionId), params, callback);
}
const GetAuditFileSepehrService = (parameters, callback) => {
    const { id, actionId, ...params } = parameters
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAuditFileSepehr), id, actionId), params, callback);
}
const GetAuditFileNgeniusService = (parameters, callback) => {
    const { id, actionId, ...params } = parameters
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAuditFileNgenius), id, actionId), params, callback);
}
const GetApAuditExcelReportService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAuditExcelReport), data, callback);
}

export {
    GetPaymentsService,
    GetPaymentsHasProblemService,
    GetPaymentByIdService,
    GetPaymentIncreaseCreditService,
    GetPaymentOrdersService,
    GetPaymentHistoryService,
    GetPaymentReportService,
    PaymentResolveProblemService,
    UpdatePaymentStatusService,
    UpdateAuditMatchingTypesService,
    GetAuditsService,
    GetAuditDetailByIdService,
    GetAuditHistoriesService,
    UploadAuditFile,
    GetAuditFileService,
    GetAuditFileAsanPardakhtService,
    GetAuditFileSepehrService,
    GetAuditFileNgeniusService,
    GetAuditGatewaysService,
    UpdateAuditOpenToFailStatusService,
    UpdateAuditMatchingTypeService,
    GetPaymentsExcelExportService,
    GetApAuditExcelReportService
}