import React, { useEffect, useState } from "react";

import { GetApShippingPlansService, GetShippingsService } from "../../../../../services/adminPanel/AdminShippingService";
import NsAdminPanelContentWrapper from "../../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../../components/grids/NsDataGridWithServiceCall";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import ApShippingInTheWarehouseSearch from "./ApShippingInTheWarehouseSearch";
import { createShippingListColumns } from "../bases/ApBaseShippingHelper";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import ApShippingDetailModal from "../list/ApShippingDetailModal";
import Enumerations from "../../../../../configs/Enumerations";
import NsTab from "../../../../../components/tabs/NsTab";

const ApShippingInTheWarehouse = (props) => {

    const [showShipmentDetailModal, setShowShipmentDetailModal] = useState(false);
    const [selectedShipmentId, setSelectedShipmentId] = useState(null);
    const [searchData, setSearchData] = useState(props.searchData);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [shippingPlans, setShippingPlans] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getShippingPlans();
    }, []);

    const getShippingPlans = () => GetApShippingPlansService(getApShippingPlansCallback);

    const getApShippingPlansCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            if (!isEmpty(resultData)) {
                resultData.map(data => {
                    data.id = data.key;
                    data.label = data.value
                })
                setShippingPlans(resultData);
                setSelectedTab(resultData[0]);
                setCallServiceDate(new Date());
            }
        } else
            setResultMessageClass(messageClassModal);
    };

    const handlers = {
        detailViewClicked: (id) => {
            setSelectedShipmentId(id);
            setShowShipmentDetailModal(true);
        },
        closeApShipmentDetailModal: () => {
            setShowShipmentDetailModal(false);
        },
        getSearchData: (data) => {
            setSearchData(data);
            setIsLoading(true);
            setCallServiceDate(new Date());
        },
    }

    const tabChanged = (newValue, selectedTab) => {
        if (newValue !== selectedTabIndex) {
            setSelectedTabIndex(newValue);
            setSelectedTab(selectedTab);
            setCallServiceDate(new Date());
        }
    }

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    const { detailViewClicked, closeApShipmentDetailModal, getSearchData } = handlers;

    const parameters = {
        "Filter.UserIds": searchData?.selectedUser?.userId ?? [],
        "Filter.NasInvoiceIds[0]": searchData?.invoiceNo || [],
        "Filter.RequestNumbers[0]": searchData?.requestNumber || [],
        "Filter.ReceiptTransferToIds[0]": searchData?.transerNumber || [],
        "Filter.ShippingMethodTypeId": searchData?.shippingMethod?.key || [],
        "Filter.TransferStateTypes": Enumerations.transferStateType.inTheWarehouse,
        "Filter.SubmitTimeFrom": searchData?.submitTimeFrom || null,
        "Filter.SubmitTimeTo": searchData?.submitTimeTo || null,
        "Filter.ShippingPlanTypeId": selectedTab?.key || null,
        "Filter.EstimatedDeliveryTimeFrom": searchData?.estimatedDeliveryTimeFrom || null,
        "Filter.EstimatedDeliveryTimeTo": searchData?.estimatedDeliveryTimeTo || null,
        "Filter.ShippingLastStatusTimeFrom": searchData?.shippingLastStatusTimeFrom || null,
        "Filter.ShippingLastStatusTimeTo": searchData?.shippingLastStatusTimeTo || null,
    }

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText('adminPanel.shippings.inTheWarehouseShippingRequests')}
            icon={'ns-icon-delivery-request h1'}>

            <div className="mb-2">
                <ApShippingInTheWarehouseSearch isLoading={isLoading}
                    customerDetails={props.customerDetails}
                    setSearchData={getSearchData} />
            </div>

            {!isEmpty(shippingPlans) &&
                <NsTab tabs={shippingPlans}
                    value={selectedTabIndex}
                    onChange={tabChanged} />
            }
            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={isEmpty(selectedTab) ? '' : GetShippingsService}
                columnDefs={createShippingListColumns(detailViewClicked)}
                parameters={parameters}
                hasPagination={true}
                getData={getData} />

            {showShipmentDetailModal &&
                <ApShippingDetailModal showModal={true}
                    selectedShipmentId={selectedShipmentId}
                    closeModal={closeApShipmentDetailModal} />
            }

        </NsAdminPanelContentWrapper>
    );
}

export default React.memo(ApShippingInTheWarehouse);