import clsx from 'clsx';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { currencyActionCreators } from '../../../../../redux/reducers/CurrencyReducer';
import NsTypography from '../../../../../components/text/NsTypography';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsMenu from '../../../../../components/menu/NsMenu';

class CurrencySelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closeMenuDate: null,
        }
    }

    currencyClicked = (id) => {
        this.props.setCurrency(id);
        this.setState({ closeMenuDate: new Date() });
    }

    render() {
        const { className, currencies, selectedCurrency } = this.props;
        const { closeMenuDate } = this.state;
        return (
            currencies?.length > 1
                ? <NsMenu buttonClassName="p-0"
                    closeMenuDate={closeMenuDate}
                    buttonChildren={<ButtonChildren className={className} selectedCurrency={selectedCurrency} />}>

                    <CurrencyOptions currencyOptions={currencies}
                        currencyClicked={this.currencyClicked} />
                </NsMenu>
                : currencies?.length === 1
                    ? <div className='d-flex align-items-center'>
                        <div className='bg-white rounded black-color font-size-10 px-1'>
                            {currencies[0]?.symbol}
                        </div>
                        <NsTypography variant='inherit' className={clsx(CommonHelper.isMobileDevice() ? 'black-color' : 'white-color', 'text-capitalize font-size-14 ms-2')}>
                            {currencies[0]?.name}
                        </NsTypography>
                    </div>
                    : null
        );
    }
}

function ButtonChildren(props) {
    return <div className='d-flex align-items-center' >
        <div className='bg-white rounded black-color font-size-10 px-1'>
            {props.selectedCurrency?.symbol}
        </div>
        <NsTypography variant='inherit' className={clsx('text-capitalize mx-1', props.className)}>
            {props.selectedCurrency?.name}
        </NsTypography>
        <i className={clsx('ns-icon-expand-down', props.className)} />
    </div>
}

function CurrencyOptions(props) {
    const currencies = props.currencyOptions.map(item => (
        <NsButton key={item.id}
            onClick={() => props.currencyClicked(item.id)}>
            <div className='bg-black rounded white-color font-size-10 px-1'>
                {item.symbol}
            </div>
            <NsTypography variant='inherit' className='text-capitalize black-color font-size-14 ms-2' >
                {item?.name}
            </NsTypography>
        </NsButton>
    ));
    return currencies;
}

const mapStateToProps = (state) => {
    return {
        selectedCurrency: state.currencies.selectedCurrency,
        currencies: state.currencies.currencies,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(currencyActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelection);