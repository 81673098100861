import clsx from 'clsx';
import React from 'react';

import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsModal from '../../../../components/modals/NsModal';
import CommonHelper from '../../../../utilities/CommonHelper';
import Config from '../../../../configs/Config';

class ShoppingBagSubmitOrderModal extends React.Component {

    render() {
        return (
            <NsModal maxWidth="xs"
                dialogTitle={getTranslatedRawText('product.shoppingBag.orderProcess')}
                showModal={true}
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeModal)}>
                <ContactInfo isModal={true} selectedPriceInqueryValuesDescription = {this.props.selectedPriceInqueryValuesDescription} />
            </NsModal>
        );
    }
}

export function ContactInfo(props) {
    const whatsAppLink = (!!Config.socialMedia.whatsApp && !!Config.customizedUnit.whatsApPhoneNumber) && CommonHelper.stringFormat("https://wa.me/{0}?text={1}", Config.customizedUnit.whatsApPhoneNumber, props.selectedPriceInqueryValuesDescription);
    return <div className={clsx("d-flex flex-column p-2 gap-3", props.isModal ? "font-size-14" : "font-size-12")}>
        {props.isModal
            ? <span>Looking for a custom-sized shopping bag? Reach out to us with your specifications through the following methods:</span>
            : <>
                <span className='fw-bold'>Customize your Shopping Bag</span>
                <span>With Diamond Printing Press, you have the freedom to tailor your shopping bag order to your precise specifications</span>
                <span>Select from a range of customizable components, such as Gusset, Width, Height, Top Fold, Paper Thickness (gsm), Paper Type (Virgin, Recycle), Quantity, and Packaging (Shrinked Film, Carton, Both) to craft the ideal bag that suits your unique needs. Tailor each element to your exact requirements. When you're content with your choices, just click "Calculate" to find out the price of your product, taxes excluded.</span>
                <span>To place your order or inquire further, please reach out through the following contact options:</span>
            </>
        }
        <a className='text-decoration-none black-color' href={`tel:${Config.customizedUnit.phoneNumber}`} rel="nofollow">
            <span className='d-flex align-items-center'><i className="ns-icon-call fs-4" />{Config.customizedUnit.phoneNumber}</span>
        </a>
        <a className='text-decoration-none black-color' href={`mailto:${Config.customizedUnit.email}`} rel="nofollow">
            <span className='d-flex align-items-center'><i className="ns-icon-email fs-4" />{Config.customizedUnit.email}</span>
        </a>
        <a className='text-decoration-none black-color' target="_blank" rel="nofollow" href={whatsAppLink}>
            <span className='d-flex align-items-center'><i className="ns-icon-whatsapp fs-4" />{Config.customizedUnit.whatsApPhoneNumber}</span>
        </a>
        {!props.isModal && <>
            <span>If you'd like to order from our standard, available products online, simply click the following link:</span>
            <div className='d-flex align-items-end font-size-14 fw-bold cursor-pointer' onClick={props.onClick} >
                {getTranslatedRawText('product.shoppingBag.onlineAvailableProducts')}
                <i className={clsx(CommonHelper.getNextThinIconByCulture(), 'fs-5')} />
            </div>
        </>
        }
    </div >
}

export default ShoppingBagSubmitOrderModal;