import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { getTranslatedRawText, getUrlWithCurrentCulture, removeQueryFromURL } from '../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsCollapse from '../../../components/dropdowns/NsCollapse';
import UserOrderTours from '../../bases/tours/UserOrdersTours';
import CommonHelper from '../../../utilities/CommonHelper';
import Enumerations from '../../../configs/Enumerations';
import NsCard from '../../../components/cards/NsCard';
import NsTour from '../../../components/tour/NsTour';
import Links from '../../../configs/links/AllLinks';
import Config from '../../../configs/Config';
import ConstantsWithTranslation from '../../../configs/ConstantsWithTranslation';
import { isEmpty } from '../../../utilities/ValidationHelper';

class UpSidebarUserOrders extends React.Component {

    navLinks = [
        { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder), icon: "ns-icon-cart", label: "profile.actionButton.cart", showMenu: true, subMenus: [], tourClassName: 'userOrders-tour-card', tabsUlrs: [] },
        {
            to: '#', icon: "ns-icon-orders", label: "profile.actionButton.groupSheetOrder", showMenu: true, subMenus: [
                { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.preparing), icon: "ns-icon-preparing-order", label: "profile.actionButton.preparing", tourClassName: '', tabsUlrs: ConstantsWithTranslation.getUserOrderPreparingTabs() },
                { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrder), icon: "ns-icon-inprocess-order", label: "profile.actionButton.inProcess", tourClassName: '', tabsUlrs: ConstantsWithTranslation.getUserOrderInProcessingTabs() },
                { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.readyToDeliverOrderInTheWarehouse), icon: "ns-icon-ready-order", label: "profile.actionButton.readyToDeliver", tourClassName: '', tabsUlrs: ConstantsWithTranslation.getUserOrderReadyToDeliverTabs(true) },
                { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.archiveOrder), icon: "ns-icon-archived-order", label: "profile.actionButton.archive", tourClassName: '', tabsUlrs: ConstantsWithTranslation.getUserOrderArchiveTabs() },
                { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.allOrders), icon: "ns-icon-find-orders", label: "profile.actionButton.allOrders", tourClassName: '', tabsUlrs: [] },
            ]
        },
        {
            to: '#', icon: "ns-icon-orders", label: "profile.actionButton.storeOrder", showMenu: Config.hasStoreProducts, subMenus: [
                { to: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.inProcessOrder), icon: "ns-icon-preparing-order", label: "profile.actionButton.preparing", tourClassName: '', tabsUlrs: [] },
                { to: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.readyToDeliverOrderInTheWarehouse), icon: "ns-icon-ready-order", label: "profile.actionButton.readyToDeliver", tourClassName: '', tabsUlrs: ConstantsWithTranslation.getStoreOrderReadyToDeliverTabs() },
                { to: getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.archiveOrderDelivered), icon: "ns-icon-archived-order", label: "profile.actionButton.archive", tourClassName: '', tabsUlrs: ConstantsWithTranslation.getStoreOrderArchiveTabs() },
            ]
        },
        { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.groupSheetOrderQueueStatus), icon: "ns-icon-queue", label: "adminPanel.sidebar.queue", showMenu: true, subMenus: [], tourClassName: '', tabsUlrs: ConstantsWithTranslation.getOrderProductTabs(true, true) },
        { to: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequest), icon: "ns-icon-delivery-request", label: "profile.actionButton.deliveryRequest", showMenu: true, subMenus: [], tourClassName: '', tabsUlrs: ConstantsWithTranslation.getDeliveryRequestTabs() },
    ]

    createSubMenus = (subMenus) => {
        return subMenus.map((link, index) => {
            return link.to && <NavLink key={index}
                to={link.to}
                onClick={this.props.onClick}
                className={isActive => clsx('nav-link-profile-setting ps-3 pb-1', link.tourClassName, (isActive || link.tabsUlrs.some(tabUrl => removeQueryFromURL(tabUrl?.url) == window.location.pathname)) && 'background-element-color')}>
                <i className={clsx(link.icon, "align-middle me-2 h2")} />
                <span className='primary-paragraph'>
                    {getTranslatedRawText(link.label)}
                </span>
            </NavLink>
        })
    }

    render() {
        const showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.userCart) && !CommonHelper.isMobileDevice();
        return (
            <NsCard className='px-3 py-2'>
                {
                    this.navLinks.map((link, index) => link?.subMenus?.length > 0 && link.showMenu
                        ? <NsCollapse key={index} className='mb-2'
                            defaultIsOpen={index == 1
                                ? !this.navLinks[2].subMenus.some(subMenu => isEmpty(subMenu.tabsUlrs)
                                    ? removeQueryFromURL(subMenu.to) == window.location.pathname
                                    : subMenu.tabsUlrs.some(tabUrl => removeQueryFromURL(tabUrl?.url) == window.location.pathname))
                                : link.subMenus.some(subMenu => isEmpty(subMenu.tabsUlrs)
                                    ? removeQueryFromURL(subMenu.to) == window.location.pathname
                                    : subMenu.tabsUlrs.some(tabUrl => removeQueryFromURL(tabUrl?.url) == window.location.pathname))}
                            // : link.subMenus.some(subMenu => removeQueryFromURL(subMenu.to) == window.location.pathname)}
                            titleClassName='w-100'
                            icon={`${link.icon} align-middle me-2 h2`}
                            title={getTranslatedRawText(link.label)} >

                            {this.createSubMenus(link.subMenus)}

                        </NsCollapse>
                        : link.showMenu && <NavLink key={index}
                            to={link.to}
                            onClick={this.props.onClick}
                            className={isActive => clsx('nav-link-profile-setting pb-1', link.tourClassName, (isActive || link.tabsUlrs.some(tabUrl => removeQueryFromURL(tabUrl?.url) == window.location.pathname)) && 'background-element-color')}
                        >
                            <i className={clsx(link.icon, "align-middle me-2 h2")} />
                            <span className="primary-paragraph">
                                {getTranslatedRawText(link.label)}
                            </span>
                        </NavLink>)
                }

                {
                    showTour && <NsTour steps={UserOrderTours.getUserCartTour()}
                        id={Enumerations.tourGuide.userCart} />
                }
            </NsCard >
        );
    }
}

export default UpSidebarUserOrders;