import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { getTitleMetaTagByCulture } from '../../../../../components/metaTags/NsSetPageTitleAndDescription';
import { GetFestivalEventDetailService } from '../../../../../services/singlePages/FestivalService';
import NsLoadingProgress from '../../../../../components/circularProgress/NsLoadingProgress';
import { getConfigObjectByCulture } from '../../../../../utilities/ConfigHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsHtmlToReact from '../../../../../components/htmlToReact/NsHtmlToReact';
import '../../../../../assets/sass/views/singlePages/Festival.scss';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import DateHelper from '../../../../../utilities/DateHelper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import Config from '../../../../../configs/Config';
import FdResultBanner from './FdResultBanner';
import FdCountDown from './FdCountDown';
import FdUserInfo from './FdUserInfo';
import FdPrizes from './FdPrizes';
import FdCodes from './FdCodes';
import NsTypography from '../../../../../components/text/NsTypography';

class FestivalDetail extends React.Component {

    constructor(props) {
        super(props);
        this.festivalBanners = getConfigObjectByCulture(Config.images)?.festivalBanners;
        this.state = {
            festivalDetail: {},
            isLoading: false,
            resultMessageClass: (<></>),
        }
    }

    componentDidMount = () => this.getFestivalEventDetail();

    viewMyPointClicked = () => window.scrollTo({ top: 600, behavior: 'smooth' });

    getFestivalEventDetail = () => {
        let id = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.setState({ isLoading: true });
        GetFestivalEventDetailService(id, this.getFestivalEventDetailCallback);
    }

    getFestivalEventDetailCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            this.setState({ festivalDetail: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        const { festivalDetail, isLoading } = this.state;
        const executeDate = DateHelper.convertDateToText(festivalDetail?.executeTime);
        const showFestivalResult = new Date(festivalDetail?.executeTime) < new Date();
        return (
            <div className='festival'>
                <NsLoadingProgress visible={isLoading} />
                <Row className='festival__main-banner w-100 mb-3 p-4 m-0' style={{ '--top-banner': `url(${this.festivalBanners[0]?.src})` }}>
                    <Col xs={12} lg={6} className='d-flex flex-column festival__main-banner__text-on-image'>
                        <p className={CommonHelper.isMobileDevice() ? 'festival__font-32' : 'festival__font-40 lh-sm'}>
                            {festivalDetail?.name}
                        </p>
                        <p className='text-justify'>{festivalDetail?.description}</p>
                        <div className='d-flex'>
                            <NsButton label={getTranslatedRawText('festival.showYourPoint')}
                                className='primary'
                                onClick={this.viewMyPointClicked} />
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className='py-4 px-lg-5 text-center'>
                        {!Config.festivalEvent?.showLiveExecution &&
                            <img src={this.festivalBanners[1]?.src}
                                className='w-100'
                                alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.festivalBanners"), getTitleMetaTagByCulture)} />
                        }
                        {Config.festivalEvent?.showLiveExecution &&
                            <div>
                                <NsTypography variant='inherit' className='font-size-16 mb-3'>
                                    <NsHtmlToReact>
                                        {CommonHelper.stringFormat(getTranslatedRawText('festival.festivalAwaitMessage'),
                                            `<span className='fw-bold'>${getTranslatedRawText('festival.liveShow')}</span>`
                                        )}
                                    </NsHtmlToReact>
                                </NsTypography>
                                <NsHtmlToReact>
                                    {Config.festivalEvent?.liveExecutionViewer}
                                </NsHtmlToReact>
                            </div>
                        }
                    </Col>
                </Row>
                <Row className={clsx('justify-content-center g-3 mb-5', showFestivalResult && "flex-row-reverse")}>
                    <Col xs={12}>
                        <div className='festival__execute-banner d-flex justify-content-center align-items-center' style={{ '--execute-banner': `url(${this.festivalBanners[4]?.src})` }}>
                            <span>
                                {getTranslatedRawText('festival.executingDate')}
                                <span className='festival__font-32 text-nowrap'>
                                    {executeDate}
                                </span>
                            </span>
                        </div>
                    </Col>
                    <Col lg={showFestivalResult ? 3 : 6} className={showFestivalResult && 'd-flex flex-column'}>

                        {festivalDetail.endDate > new Date()
                            ? <div className='festival__border-box__inside mb-2'>
                                <FdCountDown countdownClassName='my-4' festivalDetail={festivalDetail} />
                            </div>
                            : !isEmpty(festivalDetail?.lotteryEventCodes)
                                ? <div className={clsx("mb-2 festival__codes background-white-color", showFestivalResult ? "red-color border-outline-color-1px" : "festival__border-box")} >
                                    <FdCodes lotteryEventCodes={festivalDetail?.lotteryEventCodes} />
                                </div>
                                : <div className='festival__border-box__inside mb-2'>
                                    <FdCountDown countdownClassName='my-4' festivalDetail={festivalDetail} />
                                </div>
                        }
                        <FdUserInfo festivalEventUser={festivalDetail?.lotteryEventUser}
                            festivalEventSalesConfigs={festivalDetail?.lotteryEventSalesConfigs}
                            endDate={festivalDetail.endDate}
                            showFestivalResult={showFestivalResult}
                            getFestivalEvent={this.getFestivalEventDetail} />
                    </Col>
                    <Col lg={showFestivalResult ? 9 : 6}>
                        <Row className={clsx('festival__middle-banner w-100 h-100 p-4 m-0', showFestivalResult ? 'pb-0' : 'mb-3')} style={{ '--middle-banner': `url(${this.festivalBanners[2]?.src})` }}>
                            {showFestivalResult
                                ? <FdResultBanner festivalName={festivalDetail?.name} />
                                : <>
                                    <Col xs={12} className='d-flex flex-column'>
                                        <p className='font-size-16'>
                                            {festivalDetail.systemDescription}
                                        </p>
                                    </Col>
                                    <Col xs={12} className='text-center' >
                                        <img src={this.festivalBanners[3]?.src}
                                            className='w-100'
                                            alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.festivalBanners"), getTitleMetaTagByCulture)} />
                                    </Col>
                                </>}
                        </Row>
                    </Col>
                    <Col xs={12} className='pt-5 position-relative'>
                        <div className={clsx(CommonHelper.isMobileDevice() ? 'festival__font-40' : 'festival__font-64', ' text-center disable-color')}>
                            {getTranslatedRawText('festival.prizesList')}
                        </div>
                        <div className={clsx(CommonHelper.isMobileDevice() ? 'festival__font-32' : 'festival__font-40', 'red-color position-absolute top-50 start-50 translate-middle pt-5')} >
                            {getTranslatedRawText('festival.prizesList')}
                        </div>
                    </Col>
                    <FdPrizes prizes={festivalDetail?.lotteryEventAwards}
                        showFestivalResult={showFestivalResult} />

                </Row>

                {this.state.resultMessageClass}
            </div >
        );
    }
}

export default FestivalDetail;