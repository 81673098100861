import React from "react";
import { Col, Row } from "react-bootstrap";

import NsCollapse from "../dropdowns/NsCollapse";
import NsTextField from "../inputs/NsTextField";
import NsTypography from "../text/NsTypography";
import { getTranslatedRawText } from "../../utilities/CultureHelper";
import NsDateTmePicker from "../dateTimePickers/NsDateTimePicker";
import CommonHelper from "../../utilities/CommonHelper";
import NsButton from "../buttons/NsButton";
import ApBaseUserSelectModal from "../../views/adminPanel/bases/ApBaseUserSelectModal";
import NsAutocomplete from "../dropdowns/NsAutoComplete";

class HistorySearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultIsOpen: false,
            selectedUser: null,
            type: null,
            dateFrom: null,
            dateTo: null,
            showUserSelectModal: false,
        }
    }

    isOpenStatus = (state) => this.setState({ defaultIsOpen: state });

    collapseTitle = () => <div className='d-flex py-2'>
        <NsTypography variant='inherit' className='fw-bold text-nowrap flex-grow-1'>
            {getTranslatedRawText('adminPanel.common.statusHistory')}
        </NsTypography>
        <i className='ns-icon-filter' />
    </div>

    valueChanged = (value, valueName) => {
        let name = CommonHelper.getVariableName(valueName);
        this.setState({ [name]: value })
    }

    searchClicked = () => {
        CommonHelper.safeFunctionCall(this.props.searchClicked, this.state);
    }

    clearClicked = () => {
        this.setState({ selectedUser: null, type: null, dateFrom: '', dateTo: '' }, this.searchClicked);
    }

    selectUserModalClicked = () => {
        this.setState({ showUserSelectModal: true });
    }

    closeUserSelectModal = () => {
        this.setState({ showUserSelectModal: false });
    }

    userSelected = (selectedUser) => {
        this.setState({ selectedUser });
        this.closeUserSelectModal();
    }

    render() {
        const { selectedUser, type, dateFrom, dateTo, defaultIsOpen, showUserSelectModal } = this.state;
        const { hasUserName, typeItems } = this.props;
        return (
            <NsCollapse defaultIsOpen={defaultIsOpen}
                title={this.collapseTitle()}
                className="px-2"
                isOpenStatus={this.isOpenStatus}
                collapseClassName="pb-2">
                <Row className="m-0 align-items-center">
                    {hasUserName && <Col md={6}>
                        <NsTextField label={getTranslatedRawText('components.history.user')}
                            value={selectedUser?.phoneNumber}
                            endAdornment={<NsButton className='light-circle'
                                onClick={this.selectUserModalClicked}
                                startIcon="ns-icon-more" />}
                            className='w-100' />
                    </Col>
                    }
                    <Col md={hasUserName ? 6 : 12}>
                        <NsAutocomplete label={getTranslatedRawText('components.history.type')}
                            onChange={(value) => this.valueChanged(value, { type })}
                            items={typeItems}
                            value={type}
                            className='mt-n2' />

                    </Col>
                    <Col md={6}>
                        <NsDateTmePicker className='w-100'
                            label={getTranslatedRawText('components.advanceSearch.statusTimeFrom')}
                            value={dateFrom}
                            valueChanged={(value) => this.valueChanged(value, { dateFrom })} />
                    </Col>
                    <Col md={6}>
                        <NsDateTmePicker className='w-100'
                            label={getTranslatedRawText('components.advanceSearch.statusTimeTo')}
                            value={dateTo}
                            valueChanged={(value) => this.valueChanged(value, { dateTo })} />
                    </Col>
                    <Col className='d-flex   gap-1 mt-2'>
                        <NsButton label='common.clear'
                            wrapperClassName='ms-auto'
                            className='secondary-outline'
                            size='small'
                            loading={this.props.isLoadingHistory}
                            startIcon='ns-icon-clear'
                            onClick={this.clearClicked} />
                        <NsButton label='common.search'
                            className='secondary'
                            loading={this.props.isLoadingHistory}
                            size='small'
                            startIcon='ns-icon-search'
                            onClick={this.searchClicked} />
                    </Col>

                </Row>

                {showUserSelectModal &&
                    <ApBaseUserSelectModal showModal={showUserSelectModal}
                        userSelected={this.userSelected}
                        closeModal={this.closeUserSelectModal} />
                }
            </NsCollapse >
        );
    }
}

export default HistorySearch;