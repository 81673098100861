import React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NsButton from '../../../../components/buttons/NsButton';
import NsChart from '../../../../components/charts/NsChart';
import NsSelect from '../../../../components/dropdowns/NsSelect';
import NsLabelBox from '../../../../components/labels/NsLabelBox';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import NsTooltip from '../../../../components/tooltips/NsTooltip';
import Enumerations from '../../../../configs/Enumerations';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBasesDashboardChartSkeleton from './ApBasesDashboardChartSkeleton';

const ApBasesDashboardCharts = (props) => {

    const { title, data, options, height, type, hasLabelBoxInChart, isLoading, tooltipTitle, hasSelect, selectedItem, selectLabel, selectOptions, hasZoom } = props;
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const toggleTooltipClicked = () => {
        setIsTooltipOpen(!isTooltipOpen);
    }

    return (
        <div className='d-flex flex-column h-100'>
            <Row>
                <Col xs={hasSelect ? 8 : 12}>
                    <div className='d-flex fw-bold'>
                        <span>{getTranslatedRawText(title)}</span>
                        {/* {!isEmpty(tooltipTitle) && <span className='ms-2'>
                            <NsTooltip open={isTooltipOpen}
                                title={getTranslatedRawText(tooltipTitle)}
                                closeTooltip={() => setIsTooltipOpen(false)}>
                                <NsButton className='secondary-text masterFileModal-tour-preview p-0'
                                    startIcon='ns-icon-question'
                                    onClick={toggleTooltipClicked} />
                            </NsTooltip>
                        </span>} */}
                        <span>
                            <NsButton className='ms-2 secondary-text masterFileModal-tour-preview p-0'
                                startIcon='ns-icon-refresh'
                                onClick={() => CommonHelper.safeFunctionCall(props.refreshClicked)} />
                        </span>
                    </div>
                </Col>
                {hasSelect && <Col xs={4} >
                    <NsSelect options={selectOptions}
                        label={getTranslatedRawText(selectLabel)}
                        valueChanged={(value) => CommonHelper.safeFunctionCall(props.selectValueChanged, value)}
                        selectedItem={selectedItem} />
                </Col>}
            </Row>
            {hasLabelBoxInChart && <div className='d-flex gap-2'>
                {isLoading
                    ? CommonHelper.createArray(2).map((item, index) =>
                        <div key={index} className="d-inline d-md-inline-block col-md-2 p-2" >
                            <NsSkeleton isVisible={true}
                                variant={[
                                    Enumerations.skeleton.rect,
                                    Enumerations.skeleton.rect,
                                ]} />
                        </div >
                    )
                    : data?.additionalData?.map((item, index) =>
                        <NsLabelBox key={index} className='background-white-color'
                            label={item.key}
                            value={CommonHelper.currencyFormatByDefaultConfig(item.value)} />
                    )}
            </div>}
            <Row className='mt-auto'>
                {isLoading
                    ? <ApBasesDashboardChartSkeleton />
                    : !isEmpty(data)
                        ? type === 'doughnut'
                            ? <Row className='m-0 my-2 justify-content-center px-0'>
                                <Col className='px-0' xs={8} sm={4} md={6} xl={4}>
                                    <NsChart height={height} type={type} data={data} options={options} />
                                </Col>
                            </Row>
                            : <NsChart hasZoom={hasZoom} height={height} type={type} data={data} options={options} />
                        : ''
                }
            </Row>
        </div>
    );
};

export default React.memo(ApBasesDashboardCharts);