import React, { useEffect, useState } from 'react';

import NsCard from '../../../../components/cards/NsCard';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import { GetTotalOnlinePaymentsService } from '../../../../services/adminPanel/AdminDashboardService';
import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';

const ApDfTotalOnlinePayment = (props) => {

    const onlinePaymentOptions = ConstantsWithTranslation.getPaymentGateways(true);
    const { parameters, refreshDate } = props;
    const [gatewayType, setGatewayType] = useState(onlinePaymentOptions[0]?.id);
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        getTotalOnlinePayments();
    }, [refreshDate, gatewayType]);

    const getTotalOnlinePayments = () => {
        let paymentGatewayType = gatewayType !== onlinePaymentOptions[0]?.id ? gatewayType : '';
        setIsLoading(true);
        GetTotalOnlinePaymentsService({ ...parameters, paymentGatewayType: paymentGatewayType }, getTotalOnlinePaymentsCallback)
    };

    const getTotalOnlinePaymentsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setBarChartOptions(resultData));
        } else
            setMessageClassModal(messageClassModal);
    };

    const options = {
        scales: {
            x: {
                display: true,
                grid: {
                    display: true
                }
            },
        },
    }

    return <NsCard className='p-3 h-100 align-content-between'>
        <ApBasesDashboardCharts title='adminPanel.dashboard.onlinePaymentsStatus'
            height=''
            type='bar'
            hasZoom={true}
            tooltipTitle='adminPanel.dashboard.totalOnlinePaymentsChartDes'
            data={data}
            hasSelect={true}
            refreshClicked={getTotalOnlinePayments}
            options={options}
            selectOptions={onlinePaymentOptions}
            selectValueChanged={(value) => setGatewayType(value)}
            selectedItem={gatewayType}
            selectLabel='components.advanceSearch.paymentGateway'
            isLoading={isLoading} />

        {messageClassModal}
    </NsCard >
};

export default React.memo(ApDfTotalOnlinePayment);