import Enumerations from './Enumerations';
import ConstHelper from '../utilities/ConstHelper';
// console.info('***process.env.NODE_ENV =', process.env.NODE_ENV);
// console.log(window.location.href.split('/').some(item => item == 'diamond-press.com'));
const isUAE = window.location.href.split('/').some(item => item == 'diamond-press.com' || item == 'www.diamond-press.com');
const globalConfig = process.env.NODE_ENV === 'development'
    ? { ...GlobalConfig }
    : isUAE ? { ...GlobalConfigProductionUae } : { ...GlobalConfigProductionIr };

const Config = {
    apiServer: {
        baseUrl: globalConfig.apiServer.baseUrl
    },

    redirectToWww: globalConfig.redirectToWww,

    loadImagesByCdn: globalConfig.loadImagesByCdn,

    hasStoreProducts: globalConfig.hasStoreProducts,

    showCashback: globalConfig.showCashback,

    showOrderEstimatedTime: globalConfig.showOrderEstimatedTime,

    taxDemandedInformation: globalConfig.taxDemandedInformation,

    defaultCurrency: globalConfig.defaultCurrency,

    defaultChatService: globalConfig.defaultChatService,

    favIcon: globalConfig.favIcon,

    dashboardsCountryMap: globalConfig.dashboardsCountryMap,

    lsEncryptionSecret: 'MMMD0tD1@m0ndPre$$D0tC0m',

    mapApiKeys: {
        googleMapApiKey: globalConfig.mapApiKeys.googleMapApiKey,
    },

    chatApiKeys: {
        goftinoApiKey: globalConfig.chatApiKeys.goftinoApiKey,
    },

    defaultMapCenter: {
        lng: globalConfig.defaultMapCenter.lng,
        lat: globalConfig.defaultMapCenter.lat,
    },

    defaultMapCenter: {
        lng: globalConfig.locationOfCountyWarehouse.lng,
        lat: globalConfig.locationOfCountyWarehouse.lat,
    },

    countryDefaultMap: {
        [Enumerations.countryId.uae]: globalConfig.countryDefaultMap.uae,
        [Enumerations.countryId.oman]: globalConfig.countryDefaultMap.oman,
        [Enumerations.countryId.bahrain]: globalConfig.countryDefaultMap.bahrain,
        [Enumerations.countryId.qatar]: globalConfig.countryDefaultMap.qatar,
        [Enumerations.countryId.kuwait]: globalConfig.countryDefaultMap.kuwait,
        [Enumerations.countryId.iran]: globalConfig.countryDefaultMap.iran,
    },

    ipApiKey: {
        ipStack: globalConfig.ipApiKey.ipStack,
    },

    embedCodes: {
        wordPress: globalConfig.embedCodes.wordPress,
        iframe: globalConfig.embedCodes.iframe,
        script: globalConfig.embedCodes.script,
    },

    signup: {
        defaultCountryTelCode: globalConfig.signup.defaultCountryTelCode,
        minAcceptableDate: globalConfig.signup.minAcceptableDate,
    },

    accessControlAllowOrigin: '*',

    validFilePickerExtensions: globalConfig.validFilePickerExtensions,

    validFileExtensions: globalConfig.validFileExtensions,

    validExcelFilePickerExtensions: globalConfig.validExcelFilePickerExtensions,

    validExcelFileExtensions: globalConfig.validExcelFileExtensions,

    minOrderQuantityValue: globalConfig.minOrderQuantityValue,

    minDistanceBetweenPerforation: globalConfig.minDistanceBetweenPerforation,

    countDownButtonTime: globalConfig.countDownButtonTime,

    ourEquipments: globalConfig.ourEquipments,

    mandatoryTwoSideOfFilm: globalConfig.mandatoryTwoSideOfFilm,

    faqs: globalConfig.fAQs,

    warrantyTerms: globalConfig.warrantyTerms,

    termsOfUse: globalConfig.termsOfUse,

    PrivacyPolicy: globalConfig.PrivacyPolicy,

    numberInputsLength: 20,

    phoneNumber: globalConfig.phoneNumber,
    whatsApPhoneNumber: globalConfig.whatsApPhoneNumber,
    email: globalConfig.email,

    validDateFormat: globalConfig.validDateFormat,

    customizedUnit: {
        phoneNumber: globalConfig.customizedUnit.phoneNumber,
        whatsApPhoneNumber: globalConfig.customizedUnit.whatsApPhoneNumber,
        email: globalConfig.customizedUnit.email
    },

    socialMedia: {
        facebook: globalConfig.socialMedia.facebook,
        youtube: globalConfig.socialMedia.youtube,
        whatsApp: globalConfig.socialMedia.whatsApp,
        instagram: globalConfig.socialMedia.instagram,
    },

    siteLogo: globalConfig.siteLogo,
    siteLogoMobile: globalConfig.siteLogoMobile,
    enamad: globalConfig.enamad,

    pageLoadingTitle: globalConfig.pageLoadingTitle,

    images: globalConfig.images,

    regularOrder: {
        maxRegionsForMobile: 1,
        maxRegionsForDesktop: 5,
    },

    dataGridLengthPerPage: globalConfig.dataGridLengthPerPage,

    products: {
        cardPerPage: globalConfig.products.cardPerPage,
        rowPerCategory: globalConfig.products.rowPerCategory,
        maximumFilterPrice: globalConfig.products.maximumFilterPrice,
        storeMaximumFilterPrice: globalConfig.products.storeMaximumFilterPrice,
    },

    order: {
        maxPunchQuantity: 1,
        maxPunchDistanceFromEdge: 20,
        minPunchDistanceFromEdge: 5,
        minPerforationDistanceFromEdge: 15,
        minFoldLineDistanceFromEdge: 40,
        minSerialDistanceFromEdge: 5,
    },

    ipgVisibilityType: Enumerations.ipgVisibilityType.select,

    app: {
        defaultCulture: globalConfig.defaultCulture,
    },

    homeSection: globalConfig.homeSection,

    metaTags: globalConfig.metaTags,

    handleReachResult: globalConfig.handleReachResult,

    supportedLanguages: globalConfig.supportedLanguages,

    footerLinks: globalConfig.footerLinks,

    vatHistory: globalConfig.vatHistory,

    contactInformation: globalConfig.contactInformation,

    testimonials: globalConfig.testimonials,

    aboutUs: globalConfig.aboutUs,

    standardDimensionOfCMSImages: globalConfig.standardDimensionOfCMSImages,

    standardDimensionForPromotions: globalConfig.standardDimensionForPromotions,

    aboutUsHome: globalConfig.aboutUsHome,

    ads: globalConfig.ads,

    festivalEvent: globalConfig.festivalEvent,

    showProductContent: globalConfig.showProductContent,

    showShoppingBag: globalConfig.showShoppingBag,

    shoppingBags: globalConfig.shoppingBags,

    hasOfficialInvoiceIssue: globalConfig.hasOfficialInvoiceIssue,

    googleTagManagerApiCode: globalConfig.googleTagManagerApiCode,

    googleAnalyticsApiCode: globalConfig.googleAnalyticsApiCode,

    idNumberLabel: globalConfig.idNumberLabel,

    reportRowCountPerExcel: globalConfig.reportRowCountPerExcel,

    orders: globalConfig.orders,

    sharedSheetOrderEstimatedQueue: globalConfig.sharedSheetOrderEstimatedQueue,

    sixHoursCategoryId: globalConfig.sixHoursCategoryId,

    pdMoreInfo: globalConfig.pdMoreInfo,

    feedbackReadyMessages: globalConfig.feedbackReadyMessages,

    shippingPlans: globalConfig.shippingPlans
};

ConstHelper.make(Config);

export default Config;