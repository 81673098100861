import React from 'react';
import NsMessageClassModal from "../components/modals/NsMessageClassModal";
import Enumerations from '../configs/Enumerations';

export const servicesMessageClass = (title, messageItems, showSuccessResponseMessage) => {

    let key = Math.floor(Math.random() * 10000) + 1;
    return <NsMessageClassModal show={true}
        title={title}
        key={key}
        showOnlyWhenHasNotSuccess={!showSuccessResponseMessage}
        messageItems={messageItems}
        showDate={Math.random()} />
}

export const messageClass = (message) => {

    let key = Math.floor(Math.random() * 10000) + 1;
    return <NsMessageClassModal show={true}
        // title="warning"
        key={key}
        showOnlyWhenHasNotSuccess={false}
        messageItems={[{ messageType: Enumerations.messageType.error, messageText: message }]}
        showDate={Math.random()} />
}