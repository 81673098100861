import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NsButton from '../../../../../components/buttons/NsButton';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import NsPageMainContentWrapper from '../../../../../components/cards/NsPageMainContentWrapper';
import NsChip from '../../../../../components/chips/NsChip';
import NsTextField from '../../../../../components/inputs/NsTextField';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApLanguageTabs from '../../../bases/ApLanguageTabs';

function MetaTags(props) {
    const [state, setState] = useState({
        loading: false,

        title: '',
        isValidTitle: false,

        keyWords: '',
        isValidKeyWords: false,

        description: '',
        isValidDescription: false,

        organizationTitle: '',
        isValidOrganizationTitle: false,

        organizationType: '',
        isValidOrganizationType: false,

        organizationImage: '',
        isValidOrganizationImage: false,

        organizationDescription: '',
        isValidOrganizationDescription: false,

        organizationSiteName: '',
        isValidOrganizationSiteName: false,

        organizationEmail: '',
        isValidOrganizationEmail: false,

        organizationPhoneNumber: '',
        isValidOrganizationPhoneNumber: false,

        organizationFaxNumber: '',
        isValidOrganizationFaxNumber: false,

        organizationLocation: '',
        isValidOrganizationLocation: false,

        organizationStreetAddress: '',
        isValidOrganizationStreetAddress: false,

        organizationLocality: '',
        isValidOrganizationLocality: false,

        organizationRegion: '',
        isValidOrganizationRegion: false,

        organizationPostalCode: '',
        isValidOrganizationPostalCode: false,

        countryName: '',
        isValidCountryName: false,

        subject: '',
        isValidSubject: false,

        author: '',
        isValidAuthor: false,

        replyTo: '',
        isValidReplyTo: false,

        url: '',
        isValidUrl: false,

        identifierUrl: '',
        isValidIdentifierUrl: false,

        showValidationDate: '',

        keyWordsLabel: [],
    });

    const saveClicked = () => {
        setState({
            ...state,
            showValidationDate: new Date()
        });
        return;
    }

    const discardClicked = () => setState({ loading: true },
        /* () =>  api*/)


    const mainButtons = [
        { label: "common.save", onClicked: saveClicked, icon: 'ns-icon-save', className: 'primary' },
        { label: "common.discard", onClicked: discardClicked, icon: 'ns-icon-close', className: 'primary-outline' },
    ];
    const valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        setState({
            ...state,
            [valueName]: value,
            [isValidName]: isValid
        })
    }
    const addTagCicked = () => {
        let array = state.keyWordsLabel
        array.push(state.keyWords)
        setState({
            ...state,
            keyWordsLabel: array,
            keyWords: ''
        })
    }

    const labelDeleteClicked = (label) => {
        let labelIndex = state.keyWordsLabel.indexOf(label)
        let array = state.keyWordsLabel
        array.splice(labelIndex, 1)
        setState(
            {
                ...state,
                keyWordsLabel: array
            }
        )
    }
    return (
        <NsPageMainContentWrapper header={getTranslatedRawText("adminPanel.sidebar.metaTags")}
            icon={'ns-icon-information h1'}>

            <ApLanguageTabs />

            <Row className='w-100' >
                <Row className='w-100' >
                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { title: state.title }, { isValidTitle: state.isValidTitle })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.title")}
                            size="small"
                            value={state.title}
                            className="w-100" />
                    </Col>
                </Row>

                <Row className='w-100 d-flex align-items-center' >
                    <Col xs={4} className="mb-3 d-flex align-items-center">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { keyWords: state.keyWords }, { isValidKeyWords: state.isValidKeyWords })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.keyWords")}
                            size="small"
                            value={state.keyWords}
                            className="w-100 me-2" />

                        <NsButton
                            size="small"
                            className="mt-3 secondary"
                            onClick={addTagCicked}
                            startIcon='ns-icon-add cursor-pointer'
                        >
                        </NsButton>
                    </Col>

                    <Col>
                        {state.keyWordsLabel.map(label =>
                            <NsChip key={label.id}
                                chipLabel={label}
                                size='small'
                                className='me-1 mb-1'
                                deleteChipClicked={() => labelDeleteClicked(label)} />
                        )
                        }
                    </Col>
                </Row>

                <Row className='w-100' >
                    <Col xs={12} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { description: state.description }, { isValidDescription: state.isValidDescription })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.description")}
                            size="small"
                            value={state.description}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationTitle: state.organizationTitle }, { isValidOrganizationTitle: state.isValidOrganizationTitle })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationTitle")}
                            size="small"
                            value={state.organizationTitle}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationType: state.organizationType }, { isValidOrganizationType: state.isValidOrganizationType })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationType")}
                            size="small"
                            fullWidth={true}
                            value={state.organizationType}
                            className="w-100"
                        />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationImage: state.organizationImage }, { isValidOrganizationImage: state.isValidOrganizationImage })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationImage")}
                            size="small"
                            value={state.organizationImage}
                            className="w-100" />
                    </Col>

                    <Col xs={12} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationDescription: state.organizationDescription }, { isValidOrganizationDescription: state.isValidOrganizationDescription })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationDescription")}
                            size="small"
                            value={state.organizationDescription}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationSiteName: state.organizationSiteName }, { isValidOrganizationSiteName: state.isValidOrganizationSiteName })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationSiteName")}
                            size="small"
                            value={state.organizationSiteName}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationEmail: state.organizationEmail }, { isValidOrganizationEmail: state.isValidOrganizationEmail })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationEmail")}
                            size="small"
                            fullWidth={true}
                            value={state.organizationEmail}
                            className="w-100"
                        />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationPhoneNumber: state.organizationPhoneNumber }, { isValidOrganizationPhoneNumber: state.isValidOrganizationPhoneNumber })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationPhoneNumber")}
                            size="small"
                            value={state.organizationPhoneNumber}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationFaxNumber: state.organizationFaxNumber }, { isValidOrganizationFaxNumber: state.isValidOrganizationFaxNumber })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationFaxNumber")}
                            size="small"
                            value={state.organizationFaxNumber}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationLocation: state.organizationLocation }, { isValidOrganizationLocation: state.isValidOrganizationLocation })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationLocation")}
                            size="small"
                            fullWidth={true}
                            value={state.organizationLocation}
                            className="w-100"
                        />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationStreetAddress: state.organizationStreetAddress }, { isValidOrganizationStreetAddress: state.isValidOrganizationStreetAddress })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationStreetAddress")}
                            size="small"
                            value={state.organizationStreetAddress}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationLocality: state.organizationLocality }, { isValidOrganizationLocality: state.isValidOrganizationLocality })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationLocality")}
                            size="small"
                            value={state.organizationLocality}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationRegion: state.organizationRegion }, { isValidOrganizationRegion: state.isValidOrganizationRegion })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationRegion")}
                            size="small"
                            fullWidth={true}
                            value={state.organizationRegion}
                            className="w-100"
                        />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { organizationPostalCode: state.organizationPostalCode }, { isValidOrganizationPostalCode: state.isValidOrganizationPostalCode })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.organizationPostalCode")}
                            size="small"
                            value={state.organizationPostalCode}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { countryName: state.countryName }, { isValidCountryName: state.isValidCountryName })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.countryName")}
                            size="small"
                            value={state.countryName}
                            className="w-100" />
                    </Col>

                </Row>

                <Row className='w-100' >

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { subject: state.subject }, { isValidSubject: state.isValidSubject })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.subject")}
                            size="small"
                            value={state.subject}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { author: state.author }, { isValidAuthor: state.isValidAuthor })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.author")}
                            size="small"
                            fullWidth={true}
                            value={state.author}
                            className="w-100"
                        />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { replyTo: state.replyTo }, { isValidReplyTo: state.isValidReplyTo })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.replyTo")}
                            size="small"
                            value={state.replyTo}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { url: state.url }, { isValidUrl: state.isValidUrl })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.url")}
                            size="small"
                            value={state.url}
                            className="w-100" />
                    </Col>

                    <Col xs={4} className="mb-3">
                        <NsTextField
                            valueChanged={(value, isValid) =>
                                valueChanged(value, isValid, { identifierUrl: state.identifierUrl }, { isValidIdentifierUrl: state.isValidIdentifierUrl })}
                            showValidationDate={state.showValidationDate}
                            label={getTranslatedRawText("adminPanel.metaTags.identifierUrl")}
                            size="small"
                            value={state.identifierUrl}
                            className="w-100" />
                    </Col>

                </Row>

            </Row>

            <NsStickyButton stickyButtons={mainButtons} />

        </NsPageMainContentWrapper>
    );
}

export default React.memo(MetaTags);