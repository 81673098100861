import React, { Component } from 'react';

import { GetOfficialPrintInvoiceService, GetOfficialPrintInvoiceStatusService, GetUnOfficialPrintInvoiceService } from '../../services/ProfileSetting/UserOrderService';
import { GetOrderOfficialInvoiceStatusService, GetOrderOfficialReportService, GetOrderUnOfficialReportService } from '../../services/adminPanel/AdminOrderService';
import ProfileOfficialInvoiceModal from '../../views/profileSettings/modals/ProfileOfficialInvoiceModal';
import { GetStoreOrderOfficialReportService } from '../../services/adminPanel/AdminStoreOrderService';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsDownloadFile from '../downloadFiles/NsDownloadFile';
import { isEmpty } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';
import NsTypography from '../text/NsTypography';
import NsStickyButton from './NsStickyButton';
import Config from '../../configs/Config';
import NsModal from '../modals/NsModal';
import NsMenu from '../menu/NsMenu';
import NsButton from './NsButton';

class NsInvoiceButton extends Component {
    constructor(props) {
        super(props);
        this.isSeasonalPurchaseLimitEnabled = Config.orders.isSeasonalPurchaseLimitEnabled;
        this.reportItems = [
            { label: getTranslatedRawText('components.nsInvoiceButton.orderRegistrationReceipt'), onClick: this.printUnOfficialInvoiceClicked },
            { label: getTranslatedRawText('components.nsInvoiceButton.vatInvoice'), onClick: this.checkOfficialInvoiceStatus },
        ];
        this.state = {
            closeMenuDate: null,
            invoiceFile: null,
            isLoading: false,
            showInvoiceStatusModal: false,
            showProfileOfficialInvoiceModal: false,
            isOfficialInvoiceEnabled: null,
            resultMessageClass: (<></>)
        };
    }

    setLoadingButtons = (isLoading) => this.setState({ isLoading, closeMenuDate: new Date() }, () => {
        CommonHelper.safeFunctionCall(this.props.setLoading, this.state.isLoading);
    });

    checkOfficialInvoiceStatus = () => {
        this.setLoadingButtons(true);
        this.props.isAdminPanel
            ? GetOrderOfficialInvoiceStatusService(this.props.id, this.props.userId, this.getPrintInvoiceStatusCallback)
            : GetOfficialPrintInvoiceStatusService({ id: this.props.id }, this.getPrintInvoiceStatusCallback);
    }

    getPrintInvoiceStatusCallback = (resultData, result, resultMessageClass) => {
        this.setLoadingButtons(false);
        if (!result.data.hasError) {
            if (!!resultData?.isOfficialInvoiceIssued)
                this.printOfficialInvoiceClicked();
            else
                this.setState({ showInvoiceStatusModal: true, isOfficialInvoiceEnabled: resultData?.isOfficialInvoiceEnabled });
        } else
            this.setState({ resultMessageClass });
    }

    printUnOfficialInvoiceClicked = () => {
        this.setLoadingButtons(true);
        this.props.isAdminPanel
            ? this.props.isStoreOrder
                ? GetStoreOrderOfficialReportService(this.props.id, this.props.userId, this.getPrintInvoiceCallback)
                : GetOrderUnOfficialReportService(this.props.id, this.props.userId, this.getPrintInvoiceCallback)
            : GetUnOfficialPrintInvoiceService({ id: this.props.id }, this.getPrintInvoiceCallback);
    }

    printOfficialInvoiceClicked = () => {
        this.setLoadingButtons(true);
        this.props.isAdminPanel
            ? GetOrderOfficialReportService(this.props.id, this.props.userId, this.getPrintInvoiceCallback)
            : GetOfficialPrintInvoiceService({ id: this.props.id }, this.getPrintInvoiceCallback);
    }

    getPrintInvoiceCallback = (resultData, result, resultMessageClass) => {
        this.setLoadingButtons(false);
        if (!result?.data?.hasError) {
            const invoiceFile = { fileContents: !isEmpty(resultData) ? CommonHelper.createDownloadFileUrl(resultData?.fileDownloadName, resultData?.fileContents) : null };
            this.setState({ invoiceFile });
        }
        else
            this.setState({ resultMessageClass });
    }

    closeMenuClicked = () => this.setState({ closeMenuDate: new Date() });

    closeProfileOfficialInvoiceModalClicked = (resultMessageClass) => this.setState({ showProfileOfficialInvoiceModal: false, resultMessageClass });

    closeModalClicked = (showProfileOfficialInvoiceModal) => this.setState({ showInvoiceStatusModal: false, showProfileOfficialInvoiceModal });

    mapReportItems = () => {
        return this.reportItems.map((report, index) =>
            <div key={index}
                className='w-100 light p-2 font-size-14 my-1 border-bottom cursor-pointer'
                onClick={report.onClick}>
                {report.label}
            </div>
        );
    }

    render() {
        const { closeMenuDate, isLoading, resultMessageClass, invoiceFile, showInvoiceStatusModal,
            isOfficialInvoiceEnabled, showProfileOfficialInvoiceModal } = this.state;
        const { hasInvoiceNumber, isAdminPanel, isStoreOrder } = this.props;
        const helperText = hasInvoiceNumber ? '' : 'profile.detail.invoiceNumberIsMissing';

        return <div>
            {!!isStoreOrder || !this.isSeasonalPurchaseLimitEnabled
                ? <NsButton label='adminPanel.common.invoice'
                    startIcon='ns-icon-pdf-file'
                    className='secondary-outline'
                    size='small'
                    isLoading={isLoading || this.props.isLoading}
                    disabled={!hasInvoiceNumber}
                    onClick={this.printUnOfficialInvoiceClicked} />
                : <NsMenu closeMenuDate={closeMenuDate}
                    disabled={!hasInvoiceNumber}
                    isLoading={isLoading || this.props.isLoading}
                    buttonClassName='p-1 secondary-outline'
                    buttonChildren={<div className="d-flex align-items-center gap-1">
                        <i className="ns-icon-pdf-file" />
                        <span className='font-size-12'>{getTranslatedRawText('adminPanel.common.invoice')}</span>
                        <i className="ns-icon-expand-down" />
                    </div>}
                    transformOrigin={{ vertical: "top", horizontal: "left" }} >

                    {this.mapReportItems()}
                </NsMenu >
            }
            {!!helperText &&
                <NsTypography variant='inherit' className='red-color font-size-12'>{getTranslatedRawText(helperText)}</NsTypography>
            }
            <NsDownloadFile file={invoiceFile} />
            {showInvoiceStatusModal &&
                <InvoiceStatusModal isOfficialInvoiceEnabled={isOfficialInvoiceEnabled}
                    isAdminPanel={isAdminPanel}
                    closeModal={this.closeModalClicked} />}
            {showProfileOfficialInvoiceModal &&
                <ProfileOfficialInvoiceModal closeModal={this.closeProfileOfficialInvoiceModalClicked} />}
            {resultMessageClass}
        </div>
    }
}

function InvoiceStatusModal(props) {
    const { isOfficialInvoiceEnabled, isAdminPanel } = props;

    const stickyButtons = [
        !isOfficialInvoiceEnabled && !isAdminPanel && { label: "profile.actionButton.profileInfo", icon: 'ns-icon-profile-information fs-4', onClicked: () => props.closeModal(true), className: 'secondary-outline' },
        { label: "common.ok", icon: '', onClicked: () => props.closeModal(false), className: 'primary' },
    ]
    return (
        <NsModal
            dialogTitle={getTranslatedRawText('components.nsInvoiceButton.vatInvoice')}
            showModal={true}
            closeModal={props.closeModal}
            actions={<NsStickyButton stickyButtons={stickyButtons} />}>
            <div className='text-justify'>
                {getTranslatedRawText(isOfficialInvoiceEnabled
                    ? 'common.messages.orderNotReachedToPrintingState'
                    : 'common.messages.userInformationIsNotCompeleted')}
            </div>
        </NsModal>
    );

}

export default NsInvoiceButton;