import React, { useEffect, useState } from 'react';

import NsBrowseFileButton from '../../../components/buttons/NsBrowseFileButton';
import NsMessageClassModal from '../../../components/modals/NsMessageClassModal';
import NsUploadingFileProgress from '../../../components/progresses/NsUploadingFileProgress';
import Enumerations from '../../../configs/Enumerations';
import UserClaimEnumerations from '../../../configs/UserClaimEnumerations';
import { PostFileService } from '../../../services/adminPanel/AdminFileUploaderService';
import { CancelRequest } from '../../../services/bases/BaseService';
import { hasAccess } from '../../../utilities/ClaimHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import { messageClass } from '../../../utilities/MessageModalHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';

const ApBaseUploadFile = (props) => {

    const [file, setFile] = useState(props.value?.file || {});
    const [progress, setProgress] = useState({
        loaded: props.value?.loaded || 0,
        total: props.value?.total || 0,
        percent: props.value?.percent || 0,
        status: props.value?.status || Enumerations.uploadingStatus.prepareForUpload,
        message: props.value?.message || null
    });
    const [cancelationUploadingReference, setCancelationUploadingReference] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isShowQuestionModal, setIsShowQuestionModal] = useState(false);
    const [messageItems, setMessageItems] = useState(<></>);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [uploadTimestamp, setUploadTimestamp] = useState(null);
    const [triggerFilePicker, setTriggerFilePicker] = useState(null);

    useEffect(() => {
        !isEmpty(file) && progress.status !== Enumerations.uploadingStatus.finished && uploadFile()
    }, [uploadTimestamp])

    const selectedFileHandler = (selectedFile, checkFileExtension) => {
        if (checkFileExtension) {
            setMessageItems(<></>);
            setProgress({
                loaded: 0,
                total: 0,
                percent: 0,
                status: Enumerations.uploadingStatus.prepareForUpload,
                message: null
            });
            setFile({
                name: selectedFile.name || '',
                size: selectedFile.size || 0,
                id: Math.random(),
                fileContent: selectedFile,
            });
            setUploadTimestamp(new Date());
        } else {
            setResultMessageClass(messageClass(CommonHelper.stringFormat('{0} {1}',
                selectedFile.name, getTranslatedRawText('regularOrder.messages.formatIsWrong'))))
        }
    }

    const uploadFile = () => {
        setIsLoading(true);
        setCancelationUploadingReference(PostFileService(file.fileContent, props.fileUploaderCategoryType, props.userId, uploadFileCallback, onUploadProgressCallback));
    }

    const uploadFileCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError) {
            let newProgress = progress;
            newProgress.total = resultData[0].volume;
            newProgress.status = Enumerations.uploadingStatus.finished;
            newProgress.file = file;
            newProgress.file.contentUrl = resultData[0].contentUrl;
            setProgress(newProgress)
            CommonHelper.safeFunctionCall(props.fileUploadHandler, {
                uploadedFileDetail: newProgress,
                fileUrl: resultData[0].contentUrl
            })
        } else {
            setProgress({
                ...progress,
                status: Enumerations.uploadingStatus.failed,
                message: messageClassModal.props
            })
        }
    }

    const onUploadProgressCallback = (id, loaded, total, percent, status, message) => {
        setIsLoading(false);
        setProgress({ ...progress, loaded, total, percent, status })
    }

    const closeQuestionModal = (confirm) => {
        if (!!confirm)
            CancelRequest(cancelationUploadingReference);

        setIsShowQuestionModal(false);
        setMessageItems([]);

    }

    const cancelUploadingClicked = () => {
        setIsShowQuestionModal(true);
        setMessageItems([{
            messageText: (
                <span>{getTranslatedRawText('common.messages.areYouSureToCancelUploadingFile')}</span>
            ),
            messageType: Enumerations.messageType.question
        }])
    }

    const changeFileClicked = () => setTriggerFilePicker(new Date())

    return (hasAccess([UserClaimEnumerations.postFileUploader])
        ? <>
            {
                !isEmpty(file) && <NsUploadingFileProgress fileName={file.name}
                    progress={progress}
                    isLoading={isLoading}
                    isSingleFile={true}
                    changeFileClicked={changeFileClicked}
                    cancelUploadingClicked={cancelUploadingClicked} />
            }
            <div className='w-100 cursor-pointer my-2 card text-center' >
                <NsBrowseFileButton setSelectedFile={selectedFileHandler}
                    isSecondary={true}
                    triggerFilePicker={triggerFilePicker}
                    content={props.content}
                    serviceCall={props.serviceCall}
                    isChangeFile={props.isChangeFile}
                    noValidation={true} />
                {
                    !!isShowQuestionModal && <NsMessageClassModal
                        show={isShowQuestionModal}
                        onClose={closeQuestionModal}
                        messageItems={messageItems} />
                }
                {resultMessageClass}
            </div >
        </>
        : ''
    )
}

export default React.memo(ApBaseUploadFile);