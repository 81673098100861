import React from 'react';

import NsUploadingFileProgress from '../../../../components/progresses/NsUploadingFileProgress';
import NsMessageClassModal from '../../../../components/modals/NsMessageClassModal';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { CancelRequest } from '../../../../services/bases/BaseService';
import Enumerations from '../../../../configs/Enumerations';
import { DeleteDraftOrderFileService, UploadDraftOrderFileService } from '../../../../services/ProfileSetting/UserOrderService';
import CommonHelper from '../../../../utilities/CommonHelper';

class FileCorrectionUploadingProgress extends React.Component {
    constructor(props) {
        super(props);
        this.cancelationUploadingReference = null;
        this.state = {
            progress: {
                loaded: 0,
                total: 0,
                percent: 0,
                status: Enumerations.uploadingStatus.prepareForUpload,
                message: null
            },
            filesInfo: [],
            fileName: null,
            fileId: null,
            isLoading: false,
            isShowQuestionModal: false,
            messageItems: [],
        };
    }

    componentDidMount = () => this.uploadFile();

    uploadFile = () => {
        const { file } = this.props;
        this.cancelationUploadingReference = UploadDraftOrderFileService(file, this.uploadFileCallback, this.onUploadProgressCallback);
        this.setState({ progress: { ...this.state.progress, message: null } });
    }

    cancelDuplicateClicked = () => {
        this.props.getCanceledProgressId(this.props.id);
    }

    componentWillUnmount() {
        CancelRequest(this.cancelationUploadingReference);
    }

    onUploadProgressCallback = (id, loaded, total, percent, status) => {
        this.setState({ progress: { ...this.state.progress, loaded, total, percent, status } });
    }

    uploadFileCallback = (resultData, result, messageClassModal) => {

        if (!result?.data?.hasError) {
            this.setState({
                progress: {
                    ...this.state.progress,
                    status: Enumerations.uploadingStatus.finished
                },
                filesInfo: resultData,
                fileName: resultData[0]?.fileName,
                fileId: resultData[0]?.id
            }, () => CommonHelper.safeFunctionCall(this.props.setUploadedFiles, this.state.fileId));
        } else {
            this.setState({
                progress: {
                    ...this.state.progress,
                    status: Enumerations.uploadingStatus.failed,
                    message: messageClassModal.props
                }
            });
        }
    }

    cancelUploadingClicked = () => this.setState({
        isShowQuestionModal: true,
        messageItems: this.setMessageItems('common.messages.areYouSureToCancelUploadingFile'),
        isDeleteQuestion: false
    });

    deleteUploadedFileClicked = () => this.setState({
        isShowQuestionModal: true,
        messageItems: this.setMessageItems('common.messages.areYouSureQuestion'),
        isDeleteQuestion: true
    });

    closeQuestionModal = (confirm) => {
        if (!!confirm)
            if (this.state.isDeleteQuestion) {
                this.setState({ isLoading: true });
                DeleteDraftOrderFileService({ id: this.state.fileId }, this.deleteCallback)
            } else {
                CancelRequest(this.cancelationUploadingReference);
                CommonHelper.safeFunctionCall(this.props.getCanceledProgressId, this.props.id);
            }
        this.setState({ isShowQuestionModal: false, messageItems: [] });
    }

    deleteCallback = (resultData, result, messageClassModal) => {

        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            CommonHelper.safeFunctionCall(this.props.getCanceledProgressId, this.props.id);
        } else {
            this.setState({
                progress: {
                    ...this.state.progress,
                    status: Enumerations.uploadingStatus.deleteFailed,
                    message: messageClassModal.props
                }
            });
        }
    }

    setMessageItems = (message) => {
        return [{
            messageText: (
                <span>
                    <span>{getTranslatedRawText(message)}</span>
                    <span className='fw-bold'> {this.state.fileName} </span> ?
                </span>
            ),
            messageType: Enumerations.messageType.question
        }];
    }

    render() {
        const { filesInfo, progress, isLoading, isShowQuestionModal, messageItems } = this.state;
        const { file } = this.props;

        return (
            <div className='wrapper-white mb-2 p-1'>
                <NsUploadingFileProgress fileName={file.name}
                    progress={progress}
                    isLoading={isLoading}
                    cancelUploadingClicked={this.cancelUploadingClicked}
                    deleteUploadedFileClicked={this.deleteUploadedFileClicked} />
                {
                    !!isShowQuestionModal &&
                    <NsMessageClassModal show={isShowQuestionModal}
                        onClose={this.closeQuestionModal}
                        messageItems={messageItems} />
                }
            </div>
        );
    }
}

export default FileCorrectionUploadingProgress