import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsEtiquette from '../../../../components/etiquette/NsEtiquette';
import NsRegionSelect from '../../../../components/filterAreas/NsRegionSelect';
import NsTypography from '../../../../components/text/NsTypography';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ImageHelper from '../../../../utilities/ImageHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

class FiltersContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regions: [],
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        const { resultData } = newProps;
        if (!isEmpty(resultData) && resultData !== this.props.resultData) {
            this.setState({
                regions: ImageHelper.convertFiltersFromMillimeterToPercentage(this.props.file?.width, this.props.file?.height,
                    this.props.file?.resolution, resultData)
            })
        }
    }

    render() {

        const { regions } = this.state;
        const { isLoading } = this.props;

        return (
            <Row className='modal-add-filter g-0'>
                <Col md={8} className='border'>
                    <NsRegionSelect
                        imgSrc={this.props.file?.draft}
                        isReadOnly={true}
                        regions={regions} />
                </Col>
                <Col xs={{ span: 12, order: 'first' }} md={{ span: 4, order: 'last' }} className='d-flex flex-column ps-md-4'>
                    <FilterList regions={regions}
                        isLoading={isLoading} />
                </Col>
            </Row>
        );
    }
}

function FilterList(props) {
    let style = 'background-outline-color';
    let border = 'border-outline-color-1px';
    let errorText = '';
    return !isEmpty(props.regions)
        ? props.regions.map((region, index) => {
            const width = Math.floor(region.bottomRightCornerX - region.topLeftCornerX);
            const height = Math.floor(region.bottomRightCornerY - region.topLeftCornerY);

            return (
                <NsEtiquette style={style}
                    key={index}
                    border={border}
                    errorText={errorText}
                    index={index}
                    isLoading={props.isLoading}
                    title={getTranslatedRawText('regularOrder.extendedService.filter')}>

                    <div className='d-flex flex-column justify-content-between mx-3 my-2'>
                        <NsTypography variant='inherit' className='font-size-14'>
                            {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.filterWidth'), width)}
                        </NsTypography>
                        <NsTypography variant='inherit' className='font-size-14'>
                            {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.filterHeight'), height)}
                        </NsTypography>
                    </div>
                </NsEtiquette>
            );
        })
        : <NsEtiquette style={style}
            border={border}
            errorText={errorText}
            index={0}
            isLoading={props.isLoading}
            title={getTranslatedRawText('regularOrder.extendedService.filter')}>
            <div className='d-flex  justify-content-center mx-1 my-2'>
                <NsTypography variant='inherit' className='font-size-14'>
                    {getTranslatedRawText('regularOrder.modal.noFilterSelected')}
                </NsTypography>
            </div>
        </NsEtiquette>;
}

export default FiltersContent;