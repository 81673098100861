const AccountsTranslationFa = {

    account: {

        common: {
            home: 'بازگشت به صفحه اصلی',
            login: 'ورود',
            signup: 'ثبت نام',
            mobile: 'موبایل',
        },

        titles: {
            signupActivation: 'ثبت نام | تایید موبایل',
            signupAccountInformation: 'ثبت نام | اطلاعات حساب',
            signupWelcome: 'ثبت نام | خوش آمدید',

            signInForgotPassword: 'ورود | فراموشی رمزعبور',
            signInChangePassword: 'ورود | تغییر رمز عبور',
            signInSuccessChangePassword: 'ورود | تغییر رمز عبور موفق',
        },

        signup: {
            signupMobileTitle: 'تاییدیه موبایل',
            signupMobileDescription: 'برای امنیت شما ، ما می‌خواهیم اطمینان حاصل کنیم که واقعاً شما هستید. ما یک پیام متنی با کد تأیید 6 رقمی ارسال خواهیم کرد.',
            sendVerificationCode: 'ارسال کد تایید',
            signupVerificationTitle: 'تاییدیه موبایل',
            signupVerificationDescription: 'ما یک کد تأیید 6 رقمی به شماره تلفن همراه شما ارسال کرده ایم. کد را وارد کرده و موبایل خود را تایید کنید.',
            verifyMobile: 'تایید موبایل',
            resendCode: 'ارسال مجدد',
            createAccountTitle: 'ایجاد حساب کاربری',
            createMyAccount: 'ایجاد حساب من',
            firstName: 'نام',
            state: 'استان',
            city: 'شهر',
            firstNameEnter: 'نام را وارد کنید',
            lastName: 'نام خانوادگی',
            lastNameEnter: 'نام خانوادگی را وارد کنید',
            nationalCode: 'کدملی',
            nationalCodeEnter: 'کد ملی را وارد کنید',
            birthDate: 'تاریخ تولد',
            birthDateEnter: 'تاریخ تولد را وارد کنید',
            companyName: 'نام شرکت',
            companyNameEnter: 'نام شرکت را وارد کنید',
            license: 'شناسه ملی شرکت',
            iranLicense: 'شناسه ملی شرکت',
            licenseEnter: 'شناسه ملی شرکت را وارد کنید',
            licenseNumber: 'شناسه ملی شرکت',
            trn: 'کد اقتصادی',
            trnEnter: 'تی آر ان را وارد کنید',
            signupWelcomeTitle: 'به {0} خوش آمدید',
            dear: 'آقا/خانم ',
            signupWelcomeDescription: 'حساب کاربری شما با موفقیت ایجاد شد. جهت انجام سفارش وارد سایت شوید.',
            iHaveRead: ' ',
            and: 'و',
            agreeToThem: 'را خوانده و قبول می‌کنم.',
            privacyPolicy: ' شرایط حریم خصوصی',
            termsOfUse: 'شرایط استفاده'
        },

        signIn: {
            rememberMe: 'مرا بخاطر بسپار',
            forgotPassword: 'گذرواژه خود را فراموش کرده ام',
            doYouHaveAccount: 'آیا حساب کاربری دارید؟',
            signupNow: 'ایجاد حساب کاربری',
        },

        forgotPassword: {
            forgotPasswordMobileTitle: 'گذرواژه خود را فراموش کرده اید؟',
            forgotPasswordMobileDescription: 'برای امنیت شما ، ما می‌خواهیم اطمینان حاصل کنیم که واقعاً شما هستید. ما یک پیام متنی با کد تأیید 6 رقمی ارسال خواهیم کرد.',
            sendVerificationCode: 'ارسال کد تایید',
            forgotPasswordChangeTitle: 'تایید موبایل و تغییر گذرواژه ',
            forgotPasswordChangeDescription: 'ما یک کد تأیید 6 رقمی به شماره تلفن همراه شما ارسال کرده ایم. کد را وارد کرده و گذرواژه خود را تغییر دهید.',
            changePassword: 'تغییر گذرواژه',
            resendCode: 'ارسال  دوباره کد',
            forgotPasswordSuccessTitle: 'گذرواژه تغییر کرد',
            forgotPasswordSuccessDescription: 'گذرواژه شما با موفقیت تغییر کرد. حالا با گذرواژه جدید وارد شوید.',
        }
    }

};
export default AccountsTranslationFa;