import clsx from 'clsx';
import React from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NsButton from '../../../../../components/buttons/NsButton';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import { currentUserActionCreators } from '../../../../../redux/reducers/CurrentUserReducer';
import { miniCartActionCreators } from '../../../../../redux/reducers/MiniCartReducer';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import SignInModal from '../../../../layouts/main/headers/modals/signIn/SigninModal';

class FdUserInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSignInModal: false
        };
    }

    createFestivalConfigMessage = () => {
        const { festivalEventSalesConfigs } = this.props;
        return !isEmpty(festivalEventSalesConfigs)
            ? <p>
                {getTranslatedRawText('festival.from')}
                {/* <b>{festivalEventSalesConfigs.map(item => ConstantsWithTranslation.getFestivalSaleAppType(item.orderApplicationType))}</b> */}
                <b>{ConstantsWithTranslation.getFestivalSaleAppType(festivalEventSalesConfigs[0]?.salesAppType)}</b>
                {getTranslatedRawText('festival.purchaseOf')}
                {/* <b> {festivalEventSalesConfigs.map(item => ConstantsWithTranslation.getFestivalInvoiceType(item.invoiceTypes))}</b> */}
                <b> {ConstantsWithTranslation.getFestivalInvoiceType(festivalEventSalesConfigs[0]?.invoiceTypes)}</b>
            </p>
            : null
    }

    toggleModal = () => this.setState({ showSignInModal: !this.state.showSignInModal });

    signInClicked = (data) => {
        this.props.setCurrentUser(data);
        this.props.getTotalPriceAndCount();
        CommonHelper.safeFunctionCall(this.props.getFestivalEvent);
    }

    loginLayout = () => {
        const { festivalEventUser } = this.props;
        return <div className='festival__border-box px-4'>
            <div className='d-flex flex-wrap align-items-center justify-content-between'>
                <span className='font-size-18 fw-bold text-nowrap'>
                    {getTranslatedRawText('festival.minPurchase')}
                </span>
                <div className='festival__font-32 d-block'>
                    {CommonHelper.currencyFormatByDefaultConfig(festivalEventUser?.minimumAmount)}
                </div>
            </div>
            <div className='d-flex justify-content-center pt-5 pb-3'>
                <NsButton label='festival.loginLabel'
                    className='primary'
                    onClick={this.toggleModal} />
            </div>
            {this.state.showSignInModal &&
                <SignInModal showModal={true}
                    closeModal={this.toggleModal}
                    signIn={this.signInClicked} />
            }
        </div>
    }

    userInfoLayout = () => {

        const { festivalEventUser, endDate, showFestivalResult } = this.props;
        const point = !!festivalEventUser?.point ? festivalEventUser?.point : 0;
        const remainToNextPoint = festivalEventUser?.minimumAmount - festivalEventUser?.salesAmount % festivalEventUser?.amountOfEachPoint;
        const progressPercentage = (festivalEventUser?.salesAmount % festivalEventUser?.amountOfEachPoint) * 100 / festivalEventUser?.minimumAmount;
        const progressPosition = progressPercentage < 15 ? progressPercentage < 5 ? progressPercentage : progressPercentage - 5 : progressPercentage < 80 ? progressPercentage - 15 : 60;

        return <div className={clsx('p-3 background-white-color', showFestivalResult ? "red-color border-outline-color-1px" : "festival__border-box")}>
            <div className='font-size-18 fw-bold'>
                {getTranslatedRawText('festival.totalPoint')}
            </div>

            <Row className={clsx('d-flex align-items-end', showFestivalResult && " flex-column")}>
                <Col xs={showFestivalResult ? 12 : 6} className={clsx(showFestivalResult && "mt-2 order-last")}>
                    {getTranslatedRawText('festival.totalPurchaseAmount')}
                    <span className='fw-bold'>
                        {CommonHelper.currencyFormatByDefaultConfig(festivalEventUser?.salesAmount)}
                    </span>
                    {this.createFestivalConfigMessage()}
                </Col>

                <Col xs={showFestivalResult ? 12 : 6} className='festival__font-32 text-center'>
                    <span className='festival__font-64 d-block'>
                        {point}
                    </span>
                    {getTranslatedRawText('festival.points')}
                </Col>

            </Row>

            {
                new Date(endDate) > new Date() &&
                <Row className='flex-column mt-4'>
                    <Col>
                        <p>
                            {CommonHelper.stringFormat(getTranslatedRawText('festival.moreChanceMessage'),
                                CommonHelper.currencyFormatByDefaultConfig(remainToNextPoint))}
                        </p>
                    </Col>

                    <Col>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex justify-self-between background-red-color rounded white-color px-1 text-wrap' style={{ marginLeft: `${progressPosition}%` }}>
                                {CommonHelper.stringFormat(getTranslatedRawText('festival.youNeedMorePoint'), CommonHelper.currencyFormatByDefaultConfig(remainToNextPoint))}
                            </div>
                        </div>

                        <ProgressBar className='festival__progress-red-background my-2' variant='danger' animated now={progressPercentage} />

                        <div className='d-flex justify-content-between'>
                            <div className='background-red-color rounded white-color px-1'>{point}</div>
                            <div className='background-red-color rounded white-color px-1'>{point + 1}</div>
                        </div>
                    </Col>
                </Row>
            }

        </div >
    }

    render() {
        return !this.props.userFullName ? this.loginLayout() : this.userInfoLayout();
    }
}

const mapStateToProps = state => {
    return {
        userFullName: state.currentUser.userFullName,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...currentUserActionCreators, ...miniCartActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FdUserInfo);