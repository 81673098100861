import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsTextField from '../../../../components/inputs/NsTextField';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

function FileManagerConfig(props) {
    const [state, setState] = useState({
        loading: false,

        maxSpace: '',
        isValidMaxSpace: '',

        showValidationDate: ''
    })

    const saveClicked = () => {
        setState({
            ...state,
            showValidationDate: new Date()
        });
        return;
    }

    const discardClicked = () => setState({ loading: true },
        /* () =>  api*/)

    const mainButtons = [
        { label: "common.save", onClicked: saveClicked, icon: 'ns-icon-save', className: 'primary' },
        { label: "common.discard", onClicked: discardClicked, icon: 'ns-icon-close', className: 'primary-outline' },
    ];

    const valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        setState({
            ...state,
            [valueName]: value,
            [isValidName]: isValid
        })
    }

    return (
        <NsPageMainContentWrapper header={getTranslatedRawText("adminPanel.sidebar.fileManagerConfig")}
            icon={'ns-icon-setting h1'}>

            <Row className="w-100">
                <Col xs={4} className="mb-3">
                    <NsTextField
                        valueChanged={(value, isValid) =>
                            valueChanged(value, isValid, { maxSpace: state.maxSpace }, { isValidMaxSpace: state.isValidMaxSpace })}
                        showValidationDate={state.showValidationDate}
                        label={getTranslatedRawText("adminPanel.fileManagerConfig.maxSpace")}
                        size="small"
                        value={state.maxSpace}
                        className="w-100" />
                </Col>

            </Row>

            <NsStickyButton stickyButtons={mainButtons} />

        </NsPageMainContentWrapper>
    );
}

export default React.memo(FileManagerConfig);