import React, { useEffect, useState } from 'react';

import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../configs/Enumerations';
import { GetCmsLogService } from '../../../../services/adminPanel/AdminExceptionService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApLogDetailModal from './ApLogDetailModal';
import ApSearchLogs from './ApSearchLogs';
import DateHelper from '../../../../utilities/DateHelper';

const ApLogs = () => {

    const [callServiceDate, setCallServiceDate] = useState(null);
    const [selectedLogId, setSelectedLogId] = useState(null);
    const [showLogDetailModal, setShowLogDetailModal] = useState(false);
    const [searchData, setSearchData] = useState({
        'Filter.SubmitTimeFrom': DateHelper.getTodayZeroHour(),
    });

    useEffect(() => {
        setCallServiceDate(new Date());
    }, [searchData])

    const columns = [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => showLogDetailModalHandler(id),
            }
        },
        {
            field: 'userFullName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 200
        },
        {
            field: 'clientIp',
            headerName: getTranslatedRawText('adminPanel.systemMonitoring.clientIp'),
            width: 140
        },
        {
            field: 'customLogType',
            headerName: getTranslatedRawText('adminPanel.systemMonitoring.logType'),
            width: 200,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div>
                        {ConstantsWithTranslation.getLogTypeLabel(props?.customLogType)}
                    </div>
                },
            }
        },
        {
            field: 'message',
            headerName: getTranslatedRawText('adminPanel.systemMonitoring.message'),
            width: 330,
        },
        {
            field: 'orderApplicationType',
            headerName: getTranslatedRawText('components.orderType.orderApplicationType'),
            width: 180,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div>
                        {ConstantsWithTranslation.getOrderApplicationTypeById(props?.orderApplicationType)}
                    </div>
                },
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
    ];

    const showLogDetailModalHandler = (id) => {
        setSelectedLogId(id);
        setShowLogDetailModal(true);
    }

    const searchDataChanged = (searchValues) => {
        setSearchData(searchValues);
    }

    const getData = () => {

    }

    return (
        <div>
            <ApSearchLogs isLoading={false}
                setSearchData={searchDataChanged} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetCmsLogService}
                parameters={searchData}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showLogDetailModal && <ApLogDetailModal selectedLogId={selectedLogId}
                closeModal={() => setShowLogDetailModal(false)} />

            }
        </div>
    );
};

export default React.memo(ApLogs);