import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NsButton from '../../../../components/buttons/NsButton';
import NsMessageClassModal from '../../../../components/modals/NsMessageClassModal';
import Enumerations from '../../../../configs/Enumerations';
import { fileManagerActionCreators } from '../../../../redux/reducers/FileManagerReducer';
import { DeleteFileService } from '../../../../services/fileManager/FileManagementService';
import { DeleteFolderService } from '../../../../services/fileManager/FolderManagementService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { messageClass } from '../../../../utilities/MessageModalHelper';
import FmEditFileModal from '../../modals/EditFileModal/FmEditFileModal';
import FmRenameFileFolderModal from '../../modals/FmRenameFileFolderModal';
import CommonHelper from '../../../../utilities/CommonHelper';

class FmDetailActionButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowDeleteModal: false,
            isShowRenameModal: false,
            isShowEditFileModal: false,
            selectedItemContent: props.selectedItemContent,
            resultMessageClass: (<React.Fragment></React.Fragment>)
        }
    }

    createFileFolderActionButtonList = () => {
        let list = [
            { label: 'fileManager.headerActionButtons.delete', icon: 'ns-icon-delete', onClicked: this.deleteClicked },
            { label: 'fileManager.headerActionButtons.rename', icon: 'ns-icon-rename', onClicked: this.renameClicked },
        ];
        !this.state.selectedItemContent?.isFolder && list.push({ label: 'fileManager.headerActionButtons.editFile', icon: 'ns-icon-edit', onClicked: () => this.editFileClicked() });
        return list;
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.selectedItemContent !== this.state.selectedItemContent)
            this.setState({ selectedItemContent: newProps.selectedItemContent });
    }

    setMessageItems = () => {
        return [{
            messageText: (
                <span>
                    <span>{CommonHelper.stringFormatWithBold(getTranslatedRawText(this.state.selectedItemContent?.isFolder ? 'common.messages.folderDeleteQuestion' : 'common.messages.areYouSureQuestion'), this.state.selectedItemContent.name)}</span>
                </span>
            ),
            messageType: Enumerations.messageType.warning
        }];
    }

    deleteClicked = () => this.setState({ isShowDeleteModal: true })

    renameClicked = () => this.setState({ isShowRenameModal: true })

    editFileClicked = () => this.setState({ isShowEditFileModal: true });

    deleteSelectedContent = (confirm) => {
        const { selectedItemContent } = this.state;

        if (!confirm) {
            this.closeModals();
            return;
        }

        if (!!selectedItemContent) {
            let data = {
                id: selectedItemContent.id,
                name: selectedItemContent.name,
            };

            selectedItemContent.isFolder ?
                DeleteFolderService(data, this.deleteCallback)
                :
                DeleteFileService([data], this.deleteCallback);

        } else
            this.setState({ resultMessageClass: messageClass(getTranslatedRawText("common.messages.noFileChosen")) });
    }

    deleteCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {

            !!this.props.selectedItemContent.isFolder ?
                this.closeModals(Enumerations.fileManagerContentType.folder)
                :
                this.closeModals(Enumerations.fileManagerContentType.file);
            this.props.refreshHeaderDiskUsage();
        } else
            this.setState({ resultMessageClass: messageClassModal, isShowDeleteModal: false });
    }

    closeModals = (refreshType) => {
        this.setState({ isShowEditFileModal: false, isShowRenameModal: false, isShowDeleteModal: false },
            () => {
                if (refreshType === Enumerations.fileManagerContentType.folder)
                    this.props.refreshTreeView();
                if (refreshType === Enumerations.fileManagerContentType.file)
                    this.props.refreshMainSectionItems();
            });
    }

    render() {
        const { selectedItemContent } = this.state;
        const { isShowDeleteModal, isShowEditFileModal, isShowRenameModal } = this.state;

        return (
            <div className='d-flex flex-wrap'>
                <div className='d-flex detail__action-button' >
                    <FileFolderActionButtons buttonList={this.createFileFolderActionButtonList()} />
                </div>
                {!!isShowDeleteModal &&
                    <NsMessageClassModal show={isShowDeleteModal}
                        onClose={this.deleteSelectedContent}
                        messageItems={this.setMessageItems()} />
                }

                {isShowRenameModal &&
                    <FmRenameFileFolderModal showModal={this.state.isShowRenameModal}
                        selectedItemContent={selectedItemContent}
                        onClose={this.closeModals} />
                }

                {isShowEditFileModal &&
                    <FmEditFileModal showModal={isShowEditFileModal}
                        selectedItemContent={selectedItemContent}
                        onClose={this.closeModals} />
                }

                {this.state.resultMessageClass}
            </div>
        );
    }
}

function FileFolderActionButtons(props) {
    return props.buttonList.map((button, index) =>
        <NsButton key={index}
            wrapperClassName='mt-1 pe-1'
            size='small'
            className={clsx('secondary text-nowrap', button.className)}
            startIcon={button.icon}
            label={button.label}
            onClick={button.onClicked} />
    );
}

const mapStateToProps = (state) => {
    return {
        treeViewRefreshDate: state.fileManager.treeViewRefreshDate,
    }
}

const mapDispatchToPops = (dispatch) => {
    return bindActionCreators(fileManagerActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToPops)(FmDetailActionButton);