import clsx from 'clsx';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import OrderHelper from '../../../../../utilities/OrderHelper';
import NsTour from '../../../../../components/tour/NsTour';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import FilmFileActions from './FilmFileActions';
import UploadFileHelper from '../../bases/UploadFileHelper';
import { ResizeFileService } from '../../../../../services/order/OrderService';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import NsProductGuidance from '../../../../../components/guidance/NsProductGuidance';
import NsAlertModal from '../../../../../components/modals/NsAlertModal';
import SelectAndUploadFileManagement from '../bases/SelectAndUploadFileManagement';
import OBaseFilmImagePreview from '../../../bases/OBaseFilmImagePreview';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
class FilmFileModal extends React.Component {

    constructor(props) {
        super(props);
        this.tourSteps = RegularOrderTours.getFilmFileModalTour();
        this.uploadFilmHelperInstance = new UploadFileHelper(CommonHelper.toInt(props.routeId), props.routeId, props.isFront, this.onUploadProgressCallback, this.uploadFilmHelperCallback);
        this.trimZone = OrderHelper.getTrimZone(props.routeId);
        this.mainButtons = [
            CommonHelper.createStickyButtonItem("common.save", 'ns-icon-save', this.confirmFileClicked, 'primary masterFileModal-tour-save'),
            CommonHelper.createStickyButtonItem("common.remove", 'ns-icon-delete', this.removeAndSaveClicked, 'secondary'),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.handleAlertModal, 'primary-outline')
        ];
        this.mainBox = React.createRef();
        this.state = {
            headerLabel: props.isFront ? getTranslatedRawText('regularOrder.common.frontSide')
                : getTranslatedRawText('regularOrder.common.backSide'),
            messageClassModalItems: [
                { messageText: getTranslatedRawText('common.messages.discardNewFileMessage'), messageType: Enumerations.messageType.question },
                { messageText: CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), ''), messageType: Enumerations.messageType.question }
            ],

            previewFileClassName: 'd-none',
            guideColumnClassName: 'd-none',
            routeId: props.routeId,
            isFront: props.isFront,
            top: 0,
            left: 0,
            isChangedFilmSize: false,
            selectedFileInfo: '',
            masterImageInfo: {},
            suggestedFilesInfo: [],
            fileInfoFromFilePicker: '',
            alignType: null,
            currentFilmKey: props.currentFilmKey,
            isCloseWithAlert: false,
            isAutoMatchClicked: null,
            isShowNsMessageClassModal: false,
            isShowDeleteMessageModal: false,
            closeAfterResize: false,
            isAlertModalOpen: false,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => this.getMasterImageInfo();

    getInfoFromManagement = (state) => {
        const { imageSrc, previewFileClassName, guideColumnClassName, fileInfoFromFilePicker, selectedFileInfo, suggestedFilesInfo, isChangedFilmSize } = state;
        this.setState({ imageSrc, previewFileClassName, guideColumnClassName, fileInfoFromFilePicker, selectedFileInfo, suggestedFilesInfo, isChangedFilmSize });
    }

    removeAndSaveClicked = () => this.setState({ isShowDeleteMessageModal: true });

    deleteFilm = (confirm) => !!confirm
        ? this.setState({ isShowDeleteMessageModal: false, selectedFileInfo: '', isLoading: true },
            () => this.confirmFileClickedAndCloseModal())
        : this.setState({ isShowDeleteMessageModal: false });

    closeClicked = (confirm = false) => {
        if (!!this.state.isCloseWithAlert) {
            this.setState({ isShowNsMessageClassModal: !this.state.isShowNsMessageClassModal },
                () => {
                    if (!!confirm) {
                        CommonHelper.safeFunctionCall(this.props.closeFilmFileModalClicked);
                    }
                }
            );
        }
        else
            CommonHelper.safeFunctionCall(this.props.closeFilmFileModalClicked);
    }

    handleAlertModal = () => {
        if (!!this.state.fileInfoFromFilePicker)
            this.setState({ isAlertModalOpen: true });
        else
            this.closeClicked();
    };

    alertCancelClicked = () => this.setState({ isAlertModalOpen: false });

    alertConfirmClicked = () => {
        this.setState({ isAlertModalOpen: false });
        this.closeClicked();
    }

    confirmFileClicked = () => {
        const { selectedFileInfo } = this.state
        if (selectedFileInfo.width && selectedFileInfo.height) {
            this.setState({ isLoading: true });
            if (!!this.state.isChangedFilmSize) {
                ResizeFileService(selectedFileInfo?.id, selectedFileInfo.width, selectedFileInfo.height,
                    this.resizeFileCallback);
            }
            else
                this.confirmFileClickedAndCloseModal();
        }
    }

    resizeFileCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (result.data && !result?.data?.hasError) {
            this.setState({ closeAfterResize: true });
            this.uploadFilmHelperInstance.getFilePublicUrl(0, [resultData], Enumerations.presentableFileType.isPresentableInFilmFile);
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    confirmFileClickedAndCloseModal = () => {

        let film = {};
        if (!!this.state.selectedFileInfo) {
            film.id = this.state.selectedFileInfo.id;
            film.name = this.state.selectedFileInfo.name;
            film.top = this.state.top;
            film.left = this.state.left;
        }


        if (!this.state.fileInfoFromFilePicker) {
            this.setState({ isLoading: false });
            CommonHelper.safeFunctionCall(this.props.saveFilmFileModalClicked, film, this.state.suggestedFilesInfo);
            return;
        }
        this.setState({ selectedFileInfo: film, isLoading: true },
            () => CommonHelper.safeFunctionCall(this.props.saveFilmFileModalClicked, film, this.state.suggestedFilesInfo));
    }

    alignFilmButtonClicked = (alignType) => {
        this.setState({ alignType })
    };

    uploadFilmHelperCallback = (messageClassModal, tempContentList) => {
        if (!!messageClassModal) {
            this.setState({
                isChangeFileView: true,
                progress: {
                    ...this.state.progress,
                    status: Enumerations.uploadingStatus.failed,
                    message: messageClassModal.props
                }
            });
            return;
        }

        this.setState({
            suggestedFilesInfo: [...this.state.suggestedFilesInfo, ...tempContentList],
            selectedFileInfo: tempContentList[0],
            stepMessage: <i className='ns-icon-circle-confirm align-middle h4' />,
            progress: {
                ...this.state.progress, status: Enumerations.uploadingStatus.finished
            },
            isChangedFilmSize: false,
            isLoading: false,
        },
            () => this.state.closeAfterResize && this.confirmFileClickedAndCloseModal()
        );
    }

    setNewWidthAndHeightFilm = (width, height, isAutoMatchClicked) => {
        if (!!isAutoMatchClicked)
            this.setState({ isAutoMatchClicked: new Date(), top: 0, left: 0 });
        let selectedFileInfo = { ...this.state.selectedFileInfo };
        if (selectedFileInfo.width !== width || selectedFileInfo.height !== height) {
            selectedFileInfo.width = width;
            selectedFileInfo.height = height;
            this.setState({
                selectedFileInfo,
                isChangedFilmSize: true
            });
        }
    }

    setTopAndLeft = (top, left) => this.setState({ top, left, alignType: null });

    closeModal = () => CommonHelper.safeFunctionCall(this.props.closeFilmFileModalClicked);

    getMasterImageInfo = () => {
        OrderHelper.getMasterFilesWithFullInfo(this.state.routeId, (masterFiles) => {
            this.setState({
                masterImageInfo: this.state.isFront ? masterFiles[0].frontSideFileInfo : masterFiles[0].backSideFileInfo
            });
        });
    }

    render() {
        const { selectedFileInfo, isAutoMatchClicked, isAlertModalOpen } = this.state;
        const showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.chooseFilmModal);

        return (
            <NsModal showModal={this.props.showModal}
                closeModal={this.handleAlertModal}
                primaryActionButton={!!this.state.selectedFileInfo && this.confirmFileClicked}
                dialogClassName="modal-choose-printing-files"
                dialogTitle={CommonHelper.stringFormat('{0} ({1})', this.props.title, this.state.headerLabel)}
                maxWidth="md"
                actions={this.state.guideColumnClassName === 'd-block' &&
                    <NsStickyButton stickyButtons={this.mainButtons}
                        isLoading={this.state.isLoading} />}>

                <Row className="justify-content-center">
                    <Col xs={{ span: 12, order: 2 }} lg={{ span: 8, order: 1 }} >
                        <SelectAndUploadFileManagement isFront={this.state.isFront}
                            routeId={this.props.routeId}
                            currentFilmKey={this.props.currentFilmKey}
                            presentableFileType={Enumerations.presentableFileType.isPresentableInMasterFile}
                            getInfoFromManagement={this.getInfoFromManagement} />

                        <div ref={this.mainBox}
                            id='draggable-image position-absolute fixed-top fixed-bottom'
                            className={clsx("w-100 border not-rtl", 'position-relative', this.state.previewFileClassName)}>

                            <OBaseFilmImagePreview masterImageInfo={this.state.masterImageInfo}
                                filmImageInfo={selectedFileInfo}
                                isAutoMatchClicked={isAutoMatchClicked}
                                trimZone={isEmpty(this.trimZone) ? null : this.trimZone}
                                alignType={this.state.alignType}
                                setTopAndLeft={this.setTopAndLeft}
                                mainBox={this.mainBox} />
                        </div>
                        {!isEmpty(selectedFileInfo) &&
                            <div className='font-size-12 mt-1 text-justify mb-3 mb-md-0'>
                                {getTranslatedRawText('common.messages.orderMasterFileModalImageHelper')}
                            </div>
                        }
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} lg={{ span: 4, order: 2 }} className={this.state.guideColumnClassName}>
                        <div className='text-end'>
                            <NsProductGuidance guidancesUrls={OrderHelper.findOrderById(this.props.routeId).guidancesUrls} />
                        </div>
                        <div className=''>
                            <Guidance title={this.props.title} headerLabel={this.state.headerLabel} />
                        </div>
                        <div className="mt-3">
                            <FilmFileActions masterImageInfo={this.state.masterImageInfo}
                                isAutoMatchClicked={isAutoMatchClicked}
                                filmWidth={selectedFileInfo?.width}
                                filmHeight={selectedFileInfo?.height}
                                alignFilm={this.alignFilmButtonClicked}
                                setNewWidthAndHeightFilm={this.setNewWidthAndHeightFilm} />
                        </div>
                        <div className='my-2'></div>
                    </Col>
                </Row>

                {this.state.isShowDeleteMessageModal
                    ? <NsMessageClassModal show={this.state.isShowDeleteMessageModal}
                        onClose={(confirm) => this.deleteFilm(confirm)}
                        messageItems={[this.state.messageClassModalItems[1]]} />
                    : null
                }

                {isAlertModalOpen &&
                    <NsAlertModal isOpen={isAlertModalOpen}
                        cancelClicked={this.alertCancelClicked}
                        confirmClicked={this.alertConfirmClicked} >
                        {getTranslatedRawText('regularOrder.modal.closeModalAlert')}
                    </NsAlertModal>}

                {this.state.resultMessageClass}
                {(this.state.guideColumnClassName === 'd-block' && showTour) && <NsTour steps={this.tourSteps}
                    id={Enumerations.tourGuide.chooseFilmModal} />}

            </NsModal >
        );
    }
}

function Guidance(props) {
    return (
        <div className="masterFileModal-tour-preview">

            <span className='section-title'>
                {CommonHelper.stringFormat('{0} ({1})', props.title, props.headerLabel)}
            </span>
            <p className='primary-paragraph'>
                {getTranslatedRawText('regularOrder.modal.filmDescription')}
            </p>

        </div>
    );
}

export default FilmFileModal