import React, { useEffect, useState } from 'react';

import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch';
import NsButton from '../../../../components/buttons/NsButton';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { GetPaymentsExcelExportService } from '../../../../services/adminPanel/AdminPaymentService';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import DateHelper from '../../../../utilities/DateHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBaseSearchButtons from '../../bases/ApBaseSearchButtons';
import ApBaseSupporterSelectModal from '../../bases/ApBaseSupporterSelectModal';
import ApBaseUserSelectModal from '../../bases/ApBaseUserSelectModal';

const ApPaymentSearch = (props) => {

    const [showUserSelectModal, setShowUserSelectModal] = useState(false)
    const [user, setUser] = useState(props.customerDetails?.phoneNumber || '');
    const [trackingNo, setTrackingNo] = useState('');
    const [invoiceNo, setInvoiceNo] = useState(props.searchData?.invoiceNo);
    const [paymentGateways, setPaymentGateways] = useState([]);
    const [minAmount, setMinAmount] = useState('');
    const [maxAmount, setMaxAmount] = useState('');
    const [orderProductTypeId, setOrderProductTypeId] = useState('');
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [statusTimeFrom, setStatusTimeFrom] = useState(null);
    const [statusTimeTo, setStatusTimeTo] = useState(null);
    const [voucherNo, setVoucherNo] = useState('');
    const [referenceNo, setReferenceNo] = useState('');
    const [paymentType, setPaymentType] = useState([]);
    const [changeStatusByAdmin, setChangeStatusByAdmin] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [submitTimeFrom, setSubmitTimeFrom] = useState(props?.useInPaymentsHasProblem ? DateHelper.getPreviousWeek() : null);
    const [submitTimeTo, setSubmitTimeTo] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showAdminSelectModal, setShowAdminSelectModal] = useState(false)
    const [selectedAdmin, setSelectedAdmin] = useState()

    useEffect(() => {
        !isEmpty(selectedUser) && searchClicked();
    }, [selectedUser])

    useEffect(() => {
        !isEmpty(selectedAdmin) && searchClicked();
    }, [selectedAdmin])

    useEffect(() => { adminSelected }, [selectedAdmin, user])

    const usersModalButtonClicked = () => {
        setShowUserSelectModal(true)
    }

    const adminsModalButtonClicked = () => {
        setShowAdminSelectModal(true)
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptionsInMobile = [
        !props?.userId && hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            endAdornment: !props.customerDetails?.phoneNumber ? <NsButton className='light-circle'
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" /> : null
        },
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.paymentGateway'),
            fieldName: 'paymentGateways',
            value: paymentGateways,
            items: ConstantsWithTranslation.getPaymentGateways(),
            onChange: (value) => setPaymentGateways(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.trackingNo'),
            fieldName: 'trackingNo',
            value: trackingNo,
            onChange: (value) => setTrackingNo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.referenceNo'),
            fieldName: 'refrenceNo',
            value: referenceNo,
            onChange: (value) => setReferenceNo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeFrom'),
            fieldName: 'submitTimeFrom',
            value: submitTimeFrom,
            onChange: (value) => setSubmitTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeTo'),
            fieldName: 'submitTimeTo',
            value: submitTimeTo,
            onChange: (value) => setSubmitTimeTo(value)
        }
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.minAmount'),
            fieldName: 'minAmount',
            value: minAmount,
            onChange: (value) => setMinAmount(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.maxAmount'),
            fieldName: 'maxAmount',
            value: maxAmount,
            onChange: (value) => setMaxAmount(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.productType'),
            fieldName: 'orderProductTypeId',
            value: orderProductTypeId,
            items: ConstantsWithTranslation.getOrderProductTabs(true, true),
            onChange: (value) => setOrderProductTypeId(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.paymentStatus'),
            fieldName: 'paymentStatus',
            value: paymentStatus,
            items: ConstantsWithTranslation.getPaymentStatus(),
            onChange: (value) => setPaymentStatus(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.statusTimeFrom'),
            fieldName: 'statusTimeFrom',
            value: statusTimeFrom,
            onChange: (value) => setStatusTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.statusTimeTo'),
            fieldName: 'statusTimeTo',
            value: statusTimeTo,
            onChange: (value) => setStatusTimeTo(value)
        },
        !props?.useInPaymentsHasProblem && {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.paymentType'),
            fieldName: 'paymentType',
            value: paymentType,
            items: ConstantsWithTranslation.getPaymentType(),
            onChange: (value) => setPaymentType(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.invoiceNo'),
            fieldName: 'invoiceNo',
            value: invoiceNo,
            onChange: (value) => setInvoiceNo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.voucherNo'),
            fieldName: 'voucherNo',
            value: voucherNo,
            onChange: (value) => setVoucherNo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.changeStatusByAdmin'),
            fieldName: 'changeStatusByAdmin',
            value: changeStatusByAdmin,
            disabled: true,
            onChange: (value) => setChangeStatusByAdmin(value),
            endAdornment: <NsButton className='light-circle'
                onClick={adminsModalButtonClicked}
                startIcon="ns-icon-more" />
        },
    ];

    const showMoreClicked = () => setShowMore(!showMore);

    const searchClicked = () => {
        let data = {
            user: selectedUser?.userId,
            paymentGateways,
            trackingNo,
            admin: selectedAdmin?.userId,
            refrenceNo: referenceNo,
            submitTimeFrom,
            submitTimeTo,
            minAmount,
            maxAmount,
            paymentStatus,
            orderProductTypeId,
            statusTimeFrom,
            statusTimeTo,
            paymentType,
            invoiceNo,
            voucherNo,
            changeStatusByAdmin
        }
        CommonHelper.safeFunctionCall(props.setSearchData, data);
    }

    const clearButtonClicked = () => {
        setUser('');
        setSelectedAdmin('');
        setSelectedUser('');
        setPaymentGateways([]);
        setTrackingNo('');
        setReferenceNo('');
        setMinAmount('');
        setMaxAmount('');
        setPaymentStatus([]);
        setOrderProductTypeId('');
        setStatusTimeTo(null);
        setStatusTimeFrom(null);
        setSubmitTimeFrom(props?.useInPaymentsHasProblem ? DateHelper.getPreviousWeek() : null);
        setSubmitTimeTo(null);
        setPaymentType('');
        setInvoiceNo('');
        setVoucherNo('');
        setChangeStatusByAdmin('');
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    const searchValues = {
        user,
        selectedAdmin,
        paymentGateways,
        trackingNo,
        refrenceNo: referenceNo,
        submitTimeFrom,
        submitTimeTo,
        minAmount,
        maxAmount,
        paymentStatus,
        orderProductTypeId,
        statusTimeFrom,
        statusTimeTo,
        paymentType,
        invoiceNo,
        voucherNo,
        changeStatusByAdmin
    }

    const excelSearchValues = {
        "userId": !isEmpty(selectedUser) ? Number(selectedUser?.userId) : null,
        "trackNumber": !isEmpty(trackingNo) ? Number(trackingNo) : null,
        "fromSubmitTime": submitTimeFrom,
        "toSubmitTime": submitTimeTo,
        "fromStatusTime": statusTimeFrom,
        "toStatusTime": statusTimeTo,
        "invoiceNumber": !isEmpty(invoiceNo) ? Number(invoiceNo) : null,
        "minPrice": !isEmpty(minAmount) ? Number(minAmount) : null,
        "maxPrice": !isEmpty(maxAmount) ? Number(maxAmount) : null,
        "paymentStateType": !isEmpty(paymentStatus) ? paymentStatus.id : null,
        "orderProductTypeId": !isEmpty(orderProductTypeId) ? orderProductTypeId?.id : null,
        "paymentType": !isEmpty(paymentType) ? paymentType.id : null,
        "paymentGatewayType": !isEmpty(paymentGateways) ? paymentGateways.id : null,
        "voucherNumber": !isEmpty(voucherNo) ? Number(voucherNo) : null,
        "refrenceNumber": !isEmpty(referenceNo) ? referenceNo : null,
        "adminUserId": !isEmpty(changeStatusByAdmin) ? Number(changeStatusByAdmin) : null,
    }

    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user?.phoneNumber);
        setShowUserSelectModal(false);
    }

    const adminSelected = (admin) => {
        setSelectedAdmin(admin);
        setChangeStatusByAdmin(admin?.phoneNumber);
        setShowAdminSelectModal(false);
    }

    return (
        <>
            {
                !props.isHideSearch && <div onKeyDown={handleKeyDown}>
                    <NsAdvanceSearch searchOptions={showMore ? advanceSearchOptions : CommonHelper.isMobileDevice() ? basicSearchOptionsInMobile : basicSearchOptions}
                        searchValues={searchValues} mdCol={2} smCol={4} />
                    <ApBaseSearchButtons searchClicked={searchClicked}
                        isLoading={props.isLoading}
                        showMoreHandler={showMoreClicked}
                        showMore={showMore}
                        clearButtonClicked={clearButtonClicked}
                        excelSearchValues={excelSearchValues}
                        exportExcelService={hasAccess([UserClaimEnumerations.getApAllPaymentsExcelReport]) ? GetPaymentsExcelExportService : ''} />

                </div>
            }

            {
                showUserSelectModal &&
                <ApBaseUserSelectModal showModal={showUserSelectModal}
                    userSelected={userSelected}
                    closeModal={() => setShowUserSelectModal(false)} />
            }

            {
                showAdminSelectModal &&
                <ApBaseSupporterSelectModal showModal={showAdminSelectModal}
                    adminSelected={adminSelected}
                    closeModal={() => setShowAdminSelectModal(false)} />
            }
        </>
    )

}

export default ApPaymentSearch;