import { Map, Marker } from "pigeon-maps";
import React, { Component } from 'react';
import Config from '../../configs/Config';
import CommonHelper from '../../utilities/CommonHelper';

class NsPigeon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowMarker: !this.props.isAddNewAddress
        }
    }

    mapClicked = (e) => {
        const lat = e.latLng[0];
        const lng = e.latLng[1];
        this.setState({ isShowMarker: true })
        CommonHelper.safeFunctionCall(this.props.onClick, lat, lng);
    }

    render() {
        const { lat, lng, mapCenterLatitude, mapCenterLongitude } = this.props;
        return (
            <Map key={mapCenterLatitude} onClick={this.mapClicked} height={300} defaultCenter={[lat || mapCenterLatitude || Config.defaultMapCenter.lat, lng || mapCenterLongitude || Config.defaultMapCenter.lng]} defaultZoom={13}>
                {(!!lat && !!lng) && this.state.isShowMarker && <Marker width={50} anchor={[lat, lng]} />}
            </Map>
        )
    }
}

export default NsPigeon;