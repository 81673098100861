import React from "react";

import { GetStoreProductCartItemsCountService, GetStoreProductSuppliersService } from "../../../services/product/StoreProductService";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import BaseAddToCart from "../../products/bases/BaseAddToCart";
import { isEmpty } from "../../../utilities/ValidationHelper";
import CommonHelper from "../../../utilities/CommonHelper";
import Enumerations from "../../../configs/Enumerations";

class PdsSuppliers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            suppliers: [],
            supplierOrderCart: [],
            value: 0,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.getSupplierOrderCount();

    componentDidUpdate = (prevProps) => {
        if (!!this.props.getSupplierDate && (this.props.getSupplierDate !== prevProps.getSupplierDate))
            this.getSuppliers();
    }

    getSuppliers = () => {
        this.setState({ isLoading: true });
        GetStoreProductSuppliersService(this.props.parameters, this.getStoreProductSupplierCallback);
    }

    getStoreProductSupplierCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            this.setState(oldState => {
                resultData.map(item => {
                    item.value = this.state.supplierOrderCart.find(supplier => supplier.key === item.id)?.value || 0
                })
                return { suppliers: resultData }
            });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getSupplierOrderCount = () => this.props.isAuthenticated && GetStoreProductCartItemsCountService(this.getStoreProductCartItemsCountCallback);

    getStoreProductCartItemsCountCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({ supplierOrderCart: resultData });
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    // refreshProduct = () => this.getSupplierOrderCount();

    mapSuppliers = () => {
        const { suppliers, supplierOrderCart } = this.state;
        const { isAuthenticated } = this.props;
        return isEmpty(suppliers)
            ? null
            : suppliers.map((supplier, index) =>
                <div key={index} className={`d-flex ${!CommonHelper.isMobileDevice() ? 'flex-column' : 'gap-2 mb-2'}  align-items-end w-100 pb-3 border-bottom`} >

                    {suppliers.length > 1 && <span>{supplier.name}</span>}

                    {!!supplier.oldPrice && isAuthenticated && !!supplier.discountPercent &&
                        <div className="text-decoration-line-through">
                            {!!supplier.currencyTypeName ? CommonHelper.currencyFormat(supplier.oldPriceInCurrentCurrency, true) : CommonHelper.currencyFormat(supplier.oldPrice, true)}
                        </div>
                    }
                    {isAuthenticated && <div className="p-1 fw-bold red-color">
                        {!!supplier.priceInCurrentCurrency ? CommonHelper.currencyFormat(supplier.priceInCurrentCurrency, true) : CommonHelper.currencyFormat(supplier.price, true)}
                        <span className="ms-1 title-color">{supplier.currencyTypeName}</span>
                    </div>
                    }
                    <div className='ms-auto me-2'>
                        <BaseAddToCart className='mt-3'
                            isBasket={true}
                            isSmallSize={true}
                            hasDeleteIcon={true}
                            storeProductSupplierPriceId={suppliers[index]?.id}
                            supplierOrderCart={supplierOrderCart.find(item => item.key === suppliers[index].id)}
                            // refreshProduct={this.refreshProduct}
                            isAuthenticated={isAuthenticated} />
                    </div>
                </div>
            )
    }

    render() {
        const { isLoading, resultMessageClass } = this.state;
        return <>
            {isLoading
                ? <NsSkeleton isVisible={true}
                    className='my-2 w-100'
                    variant={[Enumerations.skeleton.text, Enumerations.skeleton.rect]} />
                : <div className="d-flex flex-column align-items-end w-100" >
                    {this.mapSuppliers()}
                </ div >
            }
            {resultMessageClass}
        </>
    }
}

export default PdsSuppliers;