import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import clsx from 'clsx';
import React from 'react';

import '../../assets/sass/components/inputs/TextField.scss';
import Config from '../../configs/Config';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../utilities/CultureHelper';

class NsDateTimePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }

    setSelectedDate = (value) => this.setState({ value });
    // let dateTime = !!value ? `${DateHelper.convertDateTimeToDate(new Date(value))}T${DateHelper.getTime(new Date(value))}` : null;

    onAccept = (value) => CommonHelper.safeFunctionCall(this.props.valueChanged, value, true);

    render() {
        const { label, disabled, className, helperText, availableFuture } = this.props;

        return <LocalizationProvider dateAdapter={isEnCulture() ? AdapterDateFns : AdapterDateFnsJalali}>
            <DateTimePicker
                label={getTranslatedRawText(label)}
                className={clsx('date-time-picker', className)}
                disabled={disabled}
                value={this.state.value}
                format="yyyy/MM/dd HH:mm"
                minDate={new Date(Config.signup.minAcceptableDate)}
                disableFuture={!availableFuture}
                ampm={false}
                localeText={{
                    cancelButtonLabel: getTranslatedRawText('common.cancel'),
                    clearButtonLabel: getTranslatedRawText('common.clear'),
                    okButtonLabel: getTranslatedRawText('common.save'),
                    todayButtonLabel: getTranslatedRawText('components.datePicker.today')
                }}
                slotProps={{
                    actionBar: {
                        actions: ["clear", "today", "cancel", "accept"],
                    },
                    textField: { variant: 'standard', disabled: !CommonHelper.isMobileDevice() },

                    toolbar: {
                        toolbarPlaceholder: '__',
                        toolbarFormat: 'yyyy / MM / dd',
                        hidden: false,
                    }

                }}
                onAccept={this.onAccept}
                onChange={this.setSelectedDate}
                helperText={helperText}
            />
        </LocalizationProvider>
    }
}

export default NsDateTimePicker;