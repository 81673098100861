import Enumerations from "../../configs/Enumerations";
import Links from "../../configs/links/AllLinks";
import { isBusinessPartnerRoute } from "../../utilities/BusinessPartnerHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import BreadcrumbsReducerHelper from "./bases/BreadcrumbsReducerHelper";

const BREADCRUMBS = 'BREADCRUMBS';

export const breadcrumbsActionCreators = {
    setBreadcrumbs: (item) => ({ type: BREADCRUMBS, payload: item })
}

const initState = [
    {
        id: Enumerations.breadCrumbs.home,
        name: <i className="ns-icon-home h3" />,
        link: isBusinessPartnerRoute()
            ? getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)
            : getUrlWithCurrentCulture(Links, Links.home),
    }
];

const BreadcrumbsReducer = (state = initState, action) => {

    switch (action.payload?.length ? action.payload[0] : action.payload) {
        case Enumerations.breadCrumbs.home:
            return [];

        case Enumerations.breadCrumbs.fileManager:
            return [];


        case Enumerations.breadCrumbs.products:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.products,
                    name: 'common.breadcrumbs.products',
                    link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)
                }
            ];
        case Enumerations.breadCrumbs.storeProducts:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.storeProducts,
                    name: 'common.breadcrumbs.storeProducts',
                    link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.allStoreProducts)
                }
            ];
        case Enumerations.breadCrumbs.regularOrder:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.regularOrder,
                    name: 'common.breadcrumbs.regularOrder',
                    link: '#'
                }
            ];
        case Enumerations.breadCrumbs.roProductDetail:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.products,
                    name: 'common.breadcrumbs.products',
                    link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)
                },
                {
                    id: Enumerations.breadCrumbs.roProductDetail,
                    name: 'common.breadcrumbs.productDetails',
                    link: '#'
                }
            ];
        case Enumerations.breadCrumbs.poProductDetail:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.products,
                    name: 'common.breadcrumbs.products',
                    link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)
                },
                {
                    id: Enumerations.breadCrumbs.poProductDetail,
                    name: 'common.breadcrumbs.productDetails',
                    link: '#'
                }
            ];
        case Enumerations.breadCrumbs.stProductDetails:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.storeProducts,
                    name: 'common.breadcrumbs.storeProducts',
                    link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.allStoreProducts)
                },
                {
                    id: Enumerations.breadCrumbs.stProductDetails,
                    name: 'common.breadcrumbs.productDetails',
                    link: '#'
                }
            ];
        case Enumerations.breadCrumbs.professionalOrder:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.professionalOrder,
                    name: 'common.breadcrumbs.professionalOrder',
                    link: '#'
                }
            ];
        case Enumerations.breadCrumbs.paperBag:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.paperBag,
                    name: 'common.breadcrumbs.paperBag',
                    link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBag)
                },
            ];
        case Enumerations.breadCrumbs.paperBagFaqs:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.paperBag,
                    name: 'common.breadcrumbs.paperBag',
                    link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBag)
                },
                {
                    id: Enumerations.breadCrumbs.paperBagFaqs,
                    name: 'common.breadcrumbs.paperBagFaq',
                    link: '#'
                },
            ];
        case Enumerations.breadCrumbs.paperBagGalleries:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.paperBag,
                    name: 'common.breadcrumbs.paperBag',
                    link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBag)
                },
                {
                    id: Enumerations.breadCrumbs.paperBagGalleries,
                    name: 'common.breadcrumbs.paperBagGallery',
                    link: '#'
                },
            ];
        case Enumerations.breadCrumbs.paperBagExploreProduct:
            return [
                ...initState,
                {
                    id: Enumerations.breadCrumbs.paperBag,
                    name: 'common.breadcrumbs.paperBag',
                    link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBag)
                },
                {
                    id: Enumerations.breadCrumbs.paperBagExploreProduct,
                    name: 'common.breadcrumbs.paperBagExploreProduct',
                    link: '#'
                },
            ];
        case Enumerations.breadCrumbs.orderCard:
        case Enumerations.breadCrumbs.orderPreparing:
        case Enumerations.breadCrumbs.orderInProcess:
        case Enumerations.breadCrumbs.orderReady:
        case Enumerations.breadCrumbs.orderArchive:
        case Enumerations.breadCrumbs.orderAll:
            return [
                ...initState,
                ...BreadcrumbsReducerHelper.createProfileUserGroupSheetOrderBreadcrumbs(action.payload?.length ? action.payload[0] : action.payload)
            ];
        case Enumerations.breadCrumbs.storeOrderInProcess:
        case Enumerations.breadCrumbs.storeOrderReady:
        case Enumerations.breadCrumbs.storeOrderArchive:
            return [
                ...initState,
                ...BreadcrumbsReducerHelper.createProfileUserStoreOrderBreadcrumbs(action.payload?.length ? action.payload[0] : action.payload)
            ];

        case Enumerations.breadCrumbs.myShipping:
        case Enumerations.breadCrumbs.myShippingDetail:
            return [
                ...initState,
                ...BreadcrumbsReducerHelper.createProfileMyShippingBreadcrumbs(action.payload?.length ? action.payload[0] : action.payload)
            ];

        case Enumerations.breadCrumbs.reportPayment:
        case Enumerations.breadCrumbs.reportFinancial:
            return [
                ...initState,
                ...BreadcrumbsReducerHelper.createProfileReportsBreadcrumbs(action.payload?.length ? action.payload[0] : action.payload)
            ];

        case Enumerations.breadCrumbs.cashback:
            return [
                ...initState,
                ...BreadcrumbsReducerHelper.createProfileDashboardBreadcrumbs(action.payload?.length ? action.payload[0] : action.payload)
            ];

        case Enumerations.breadCrumbs.profileInformation:
        case Enumerations.breadCrumbs.profileNotification:
        case Enumerations.breadCrumbs.profileAgency:
        case Enumerations.breadCrumbs.profileAutoShipping:
        case Enumerations.breadCrumbs.profileAddress:
            return [
                ...initState,
                ...BreadcrumbsReducerHelper.createProfileInformationBreadcrumbs(action.payload?.length ? action.payload[0] : action.payload)
            ];

        case Enumerations.breadCrumbs.aboutUs:
        case Enumerations.breadCrumbs.contactUs:
        case Enumerations.breadCrumbs.instantDiscounts:
        case Enumerations.breadCrumbs.faqs:
        case Enumerations.breadCrumbs.gallery:
        case Enumerations.breadCrumbs.galleryCategories:
        case Enumerations.breadCrumbs.ourEquipment:
        case Enumerations.breadCrumbs.termOfUse:
        case Enumerations.breadCrumbs.privacyPolicy:
        case Enumerations.breadCrumbs.warrantyTerms:
        case Enumerations.breadCrumbs.festivals:
        case Enumerations.breadCrumbs.siteMap:
        case Enumerations.breadCrumbs.downloadCenter:
        case Enumerations.breadCrumbs.downloadDetail:
        case Enumerations.breadCrumbs.specialNeeds:
            return [
                ...initState,
                ...BreadcrumbsReducerHelper.createSinglePageBreadcrumbs(action.payload?.length ? action.payload[0] : action.payload)
            ];

        case Enumerations.breadCrumbs.blogs:
        case Enumerations.breadCrumbs.blogCategories:
        case Enumerations.breadCrumbs.blogCategoryPosts:
        case Enumerations.breadCrumbs.blogPostDetail:
        case Enumerations.breadCrumbs.blogPosts:
            return [
                ...initState,
                ...BreadcrumbsReducerHelper.createNewsBlogBreadcrumbs(action.payload?.length ? action.payload[0] : action.payload, true)
            ];
        case Enumerations.breadCrumbs.news:
        case Enumerations.breadCrumbs.newsCategories:
        case Enumerations.breadCrumbs.newsCategoryPosts:
        case Enumerations.breadCrumbs.newsPostDetail:
        case Enumerations.breadCrumbs.newsPosts:
            return [
                ...initState,
                ...BreadcrumbsReducerHelper.createNewsBlogBreadcrumbs(action.payload?.length ? action.payload[0] : action.payload, false)
            ];
        default:
            return state;
    }
}

export default BreadcrumbsReducer;