import React, { useEffect, useState } from 'react';

import NsCard from '../../../../components/cards/NsCard';
import { GetTotalUsersService } from '../../../../services/adminPanel/AdminDashboardService';
import { isEnCulture } from '../../../../utilities/CultureHelper';
import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';

const ApDuUsers = (props) => {

    const { parameters, refreshDate } = props;
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState();

    useEffect(() => {
        getUsers();
    }, [refreshDate])

    const getUsers = () => {
        setIsLoading(true);
        GetTotalUsersService(parameters, getTotalUsersCallback);
    }
    const getTotalUsersCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setSingleBarChartOptions(resultData));
        } else
            setMessageClassModal(messageClassModal);
    }

    const options = {
        scales: {
            x: {
                display: true,
                grid: {
                    display: false
                }
            },
        },
    }

    return (
        <NsCard className='p-3 h-100 align-content-between'>

            <ApBasesDashboardCharts title='adminPanel.dashboard.totalUsers'
                height='80px'
                type='bar'
                hasLabelBoxInChart={true}
                tooltipTitle='adminPanel.dashboard.totalUsersChartDes'
                data={data}
                refreshClicked={getUsers}
                options={options}
                hasZoom={true}
                isLoading={isLoading} />

            {messageClassModal}
        </NsCard>
    );
};

export default React.memo(ApDuUsers);