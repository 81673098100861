import React, { Component } from "react";
import NsPagination from "../../../components/pagination/NsPagination";

class ContentPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        };
    }

    handlePage = (currentPage) => {
        this.setState({ currentPage },
            () => this.props.handleCurrentPage(this.state.currentPage));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.pageNumber !== nextProps.pageNumber)
            this.setState({ currentPage: nextProps.pageNumber });
    }

    render() {
        const { currentPage } = this.state;
        const { totalPageCount, isLink } = this.props;

        if (totalPageCount > 1)
            return (
                <div className='my-4 text-center'>
                    <NsPagination variant='outlined'
                        page={currentPage}
                        count={totalPageCount}
                        isLink={isLink ?? true}
                        onChange={this.handlePage} />
                </div>
            );
        return null;
    }
}

export default ContentPagination