import React from 'react';
import ReactRegionSelect from 'react-region-select';

import CommonHelper from '../../utilities/CommonHelper';
import Config from '../../configs/Config';
import NsTypography from '../text/NsTypography';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { isNumberBetweenRange } from '../../utilities/ValidationHelper';

class NsRegionSelect extends React.Component {
    constructor(props) {
        super(props);
        this.maxRegions = CommonHelper.isMobileDevice() ? Config.regularOrder.maxRegionsForMobile : Config.regularOrder.maxRegionsForDesktop;
        this.reactRegionParent = React.createRef();
    }

    onChange = (regions) => {
        CommonHelper.safeFunctionCall(this.props.onChange, regions);
    }

    onReactRegionSelectMouseUp = (e) => {
        const { regions } = this.props;
        const parentWidth = this.reactRegionParent.current.offsetWidth;
        const parentHeight = this.reactRegionParent.current.offsetHeight;
        const widthPosition = Math.ceil(e.target.offsetLeft / parentWidth * 100);
        const heightPosition = Math.ceil(e.target.offsetTop / parentHeight * 100);

        const selectedRegionIndex = regions.findIndex(region => isNumberBetweenRange(widthPosition, region.x - 1, region.x + 1) && isNumberBetweenRange(heightPosition, region.y - 1, region.y + 1)) + 1 || regions.length;

        CommonHelper.safeFunctionCall(this.props.handleSelectedRegion, selectedRegionIndex);
    }

    regionRenderer(regionProps, isReadOnly) {

        if (!regionProps.isChanging) {
            const { duplicatedFilterIndexs, regions, minDimension } = this.props;
            const width = Math.floor(regions[regionProps.index].bottomRightCornerX - regions[regionProps.index].topLeftCornerX);
            const height = Math.floor(regions[regionProps.index].bottomRightCornerY - regions[regionProps.index].topLeftCornerY);
            let style = '';

            if ((width <= height) && ((width < minDimension?.minWidth) || (height < minDimension?.minHeight)) ||
                ((width >= height) && ((width < minDimension?.minHeight) || (height < minDimension?.minWidth))) ||
                duplicatedFilterIndexs?.flat().includes(regionProps.index + 1)) {
                style = 'background-red-color white-color';
            }
            else {
                style = 'background-white-color';
            }
            return (
                <div className={`region-select d-flex ${style} p-1 ${regionProps.index + 1 === this.props.selectedRegionIndex && 'selected-filter'} extendedService-tour-addedFilter`}>
                    <NsTypography variant='inherit' className='region-select--text fw-bold font-size-12'>
                        {getTranslatedRawText("common.filter")}&nbsp;{regionProps.index + 1}
                    </NsTypography>
                    {!isReadOnly && <button className={`border-0 p-0 ${style}`}
                        onClick={() => this.deleteFilter(regionProps.index)}>
                        <i className='ns-icon-close align-bottom font-size-16' />
                    </button>
                    }
                </div>
            );
        }
    }

    deleteFilter = (index) => {
        CommonHelper.safeFunctionCall(this.props.deleteFilter, index);
    }
    render() {
        const { regions, imgSrc, maxRegions, deleteFilter, isReadOnly } = this.props;
        return (
            <div className='border' onMouseUp={this.onReactRegionSelectMouseUp} ref={this.reactRegionParent}>
                <ReactRegionSelect maxRegions={maxRegions || this.maxRegions}
                    regions={regions}
                    constraint
                    onChange={this.onChange}
                    renderHandles={this.onChange2}
                    className='region-area w-100'
                    regionRenderer={(regionProps) => this.regionRenderer(regionProps, !!isReadOnly)}>
                    <img src={imgSrc}
                        width='100%'
                        alt='image region' />
                </ReactRegionSelect>
            </div>
        );
    }
}

export default NsRegionSelect;