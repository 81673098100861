import clsx from 'clsx';
import React from 'react';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import '../../assets/sass/components/images/NsImages.scss';
import ImageHelper from '../../utilities/ImageHelper';
import CommonHelper from '../../utilities/CommonHelper';
import { isEmpty } from '../../utilities/ValidationHelper';

class NsImageOrderMasterFileView extends React.Component {

    constructor(props) {
        super(props);
        this.mainBox = React.createRef();
        this.state = {
            imgWidth: '0',
            imgHeight: '0',
            ratioWidth: null,
            ratioHeight: null,
        };
    }

    componentDidMount = () => {
        ImageHelper.getResolutionAndDimension(this.props.imgSrc, this.getResolutionAndDimensionCallback);
        CommonHelper.safeFunctionCall(this.props.getMainBoxDimension, this.mainBox);
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.imgSrc !== this.props.imgSrc)
            ImageHelper.getResolutionAndDimension(newProps.imgSrc, this.getResolutionAndDimensionCallback);
    }

    getResolutionAndDimensionCallback = (imageInfo) => {
        this.setState({
            imgWidth: imageInfo.width,
            imgHeight: imageInfo.height,
            ratioWidth: this.mainBox.current?.clientWidth / imageInfo.width,
            ratioHeight: this.mainBox.current?.clientHeight / imageInfo.height
        },
            () => CommonHelper.safeFunctionCall(this.props.setRatio, Math.min(this.state.ratioWidth, this.state.ratioHeight)));
    }

    onClick = (e) => CommonHelper.safeFunctionCall(this.props.onClick, e);

    getImageAppliedWidth = (imgWidth, imgHeight) => {
        return ImageHelper.getImageAppliedWidth(imgWidth + '', imgHeight + '');
    }

    render() {
        const { imgSrc, wrapperClassName, productDesignTemplateUrl, trimZone, maxPunchDistanceEdges, hasMaxPunchDistanceEdges, isSharpProductDesignTemplate } = this.props;
        const hasTrimZone = !isEmpty(trimZone);
        return (

            <div className={clsx("ns-image-order-master-file d-flex flex-column justify-content-center align-items-center background-element-color")}
                style={{ 'width': 'w-100', "height": '100-vh' }} ref={this.mainBox} onClick={this.onClick}>

                {!!imgSrc && <div className={clsx('', !!hasTrimZone && 'ns-image-order-master-file__img-safe-zone', wrapperClassName)}
                    style={{
                        "--border-top": `${trimZone?.pixelTop * this.state.ratioWidth}px`,
                        "--border-left": `${trimZone?.pixelLeft * this.state.ratioWidth}px`,
                        "--border-right": `${trimZone?.pixelRight * this.state.ratioWidth}px`,
                        "--border-bottom": `${trimZone?.pixelBottom * this.state.ratioWidth}px`,
                        "width": this.state.ratioHeight < this.state.ratioWidth ? this.state.ratioHeight * this.state.imgWidth : '100%',
                        "height": this.state.ratioHeight < this.state.ratioWidth ? '100%' : ''
                    }}>

                    {!!hasMaxPunchDistanceEdges && <div className='w-100 ns-image-order-master-file__img-safe-zone-inside'
                        style={{
                            "--border-top-inside": `${maxPunchDistanceEdges?.pixelTop * this.state.ratioWidth}px`,
                            "--border-left-inside": `${maxPunchDistanceEdges?.pixelLeft * this.state.ratioWidth}px`,
                            "--border-right-inside": `${maxPunchDistanceEdges?.pixelRight * this.state.ratioWidth}px`,
                            "--border-bottom-inside": `${maxPunchDistanceEdges?.pixelBottom * this.state.ratioWidth}px`,
                            "height": '100%',
                        }} ></div>
                    }

                    {this.props.children}

                    <img src={imgSrc}
                        alt={getTranslatedRawText("components.imagesAlt.uploadedImage")}
                        width="100%"
                        style={{ opacity: isSharpProductDesignTemplate ? .5 : 1 }} />

                    {!!productDesignTemplateUrl && <img src={CommonHelper.createImageUrl(productDesignTemplateUrl)}
                        className='ns-image-order-master-file__template-design'
                        alt={getTranslatedRawText("components.imagesAlt.uploadedImage")} />
                    }

                </div>
                }
            </div>
        );
    }
}

export default NsImageOrderMasterFileView;