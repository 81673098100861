import memoizeOne from 'memoize-one';
import NsHtmlToReact from "../components/htmlToReact/NsHtmlToReact";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import Enumerations from "../configs/Enumerations";
import { getCurrentCulture, getTranslatedRawText, isEnCulture } from "../utilities/CultureHelper";
import { getEnumKeyFromValue } from "./EnumerationHelper";
import StorageHelper from "./StorageHelper";
import { isEmpty } from "./ValidationHelper";
import NumberHelper from './NumberHelper';

class CommonHelper {

    static textTruncate(name) {
        if (name.length > 10) {
            return name.substr(0, 4) + '...' + name.substr(name.length - 11, name.length);
        }
        return name;
    }

    static convertAdminBreadCrumbToStaticPageInfo = (id) => {
        switch (id) {
            case Enumerations.adminBreadCrumbs.faq:
            case Enumerations.staticPageType.faqs:
                return {
                    id: Enumerations.staticPageType.faqs,
                    label: 'adminPanel.sidebar.faqs',
                    icon: 'ns-icon-notification-setting'
                }
            case Enumerations.adminBreadCrumbs.termOfUse:
            case Enumerations.staticPageType.termOfUse:
                return {
                    id: Enumerations.staticPageType.termOfUse,
                    label: 'adminPanel.sidebar.termsOfUse',
                    icon: 'ns-icon-notification-setting'
                }
            case Enumerations.adminBreadCrumbs.privacyPolicy:
            case Enumerations.staticPageType.privacyPolicy:
                return {
                    id: Enumerations.staticPageType.privacyPolicy,
                    label: 'adminPanel.sidebar.privacyPolicy',
                    icon: 'ns-icon-notification-setting'
                }
            case Enumerations.adminBreadCrumbs.warrantyTerms:
            case Enumerations.staticPageType.warrantyTerms:
                return {
                    id: Enumerations.staticPageType.warrantyTerms,
                    label: 'adminPanel.sidebar.warrantyTerms',
                    icon: 'ns-icon-notification-setting'
                }
            default:
                return {}
        }
    }

    static redirectToCorrectPathIfInvalidPageNumber = (currentPage, pageCount, correctPathname, props) => {
        if (pageCount >= 1 && !(currentPage <= pageCount && currentPage >= 1)) props.history.push(`${correctPathname}?page-number=${pageCount}`);
    }

    static isUAE = () => window.location.href.split('/').some(item => item == 'diamond-press.com' || item == 'www.diamond-press.com');

    static getVariableName = (inputVariable) => Object.keys(inputVariable)[0];

    static getVariableValue = (inputVariable) => Object.values(inputVariable)[0];

    static defaultRowPerPage = (index) => Constants.rowPerPageOptionsInGrid[index || 0];

    static stringFormat(pattern, parameters) {
        for (let i = 1; i < arguments.length && !isEmpty(pattern); i++) {
            const regExp = new RegExp("\{" + (i - 1) + "\\}", 'g')
            pattern = pattern.replace(regExp, arguments[i]);
        }

        return pattern
    }

    static trimmedString = (str, lengthTrim) => str?.length <= lengthTrim ? str : str.substring(0, lengthTrim) + '...';

    static stringFormatWithBold(pattern, parameters) {
        for (let i = 1; i < arguments.length; i++) {
            pattern = <span>
                <NsHtmlToReact>
                    {pattern.replace("{" + (i - 1) + "}", `<strong>${arguments[i]}</strong>`)}
                </NsHtmlToReact>
            </span>
        }
        return pattern
    }

    static kebabize = str => {
        return str.split('').map((letter, idx) => {
            return letter.toUpperCase() === letter
                ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
                : letter;
        }).join('');
    }

    static camelize = s => s.replace(/-./g, x => x[1].toUpperCase());

    static replaceSlashNToLinebreaks = (str) => isEmpty(str) ? '' : str.split('\n').map((item, index) => <div key={index} className='d-block'>{item}</div>)

    static removeStringSpaces = (inputString) => inputString?.replace(/ /g, '');

    static replaceHyphenToSpace = (text) => !isEmpty(text) ? text.replace(/-/g, ' ') : '';

    static replaceSlashWithDash = (text) => !isEmpty(text) ? text.replace(/\//g, '-') : '';

    static stringSpacesToHyphen = (inputString) => inputString?.replace(/\s+/g, '-');

    static makeCorrectLink = (inputString) => isEmpty(inputString) ? '' : this.stringSpacesToHyphen(inputString).toLowerCase().replace(/\//g, '-');

    static removeWhiteSpaceFromText = (text) => !isEmpty(text) ? text.replace(/\u200c/g, ' ').trim() : ''

    static getContentFromBodyTag = (text) => {
        if (!isEmpty(text)) {
            let regexForFindBodyTag = /<body>/i;
            if (regexForFindBodyTag.test(text)) {
                let patternForGetContentOfBodyTag = /<body>([\s\S]*?)<\/body>/i;
                return text.match(patternForGetContentOfBodyTag)[1];
            } else return text;
        }
    }

    static getBackThinIconByCulture = () => isEnCulture() ? 'ns-icon-back-thin' : 'ns-icon-next-thin';

    static getBackIconByCulture = () => isEnCulture() ? 'ns-icon-back' : 'ns-icon-next';

    static getNextIconByCulture = () => isEnCulture() ? 'ns-icon-next' : 'ns-icon-back';

    static getNextThinIconByCulture = () => isEnCulture() ? 'ns-icon-next-thin' : 'ns-icon-back-thin';

    static getExpandIconByCulture = () => isEnCulture() ? 'ns-icon-expand-right' : 'ns-icon-expand-left';

    static getDefaultCurrency = () => isEmpty(StorageHelper.getFromStorage(Enumerations.storage.userCurrency)?.defaultCurrency?.name)
        ? isEnCulture() ? Config.defaultCurrency.type.en.toUpperCase() : Config.defaultCurrency.type.fa
        : StorageHelper.getFromStorage(Enumerations.storage.userCurrency)?.defaultCurrency?.name

    static currencyFormat = (num, isHideCurrency) => {
        if (Number.isFinite(num)) {
            let decimalPointLength = StorageHelper.getFromStorage(Enumerations.storage.userCurrency)?.currentCurrency?.decimalPointLength;
            return num.toLocaleString('en-US', { minimumFractionDigits: decimalPointLength, maximumFractionDigits: decimalPointLength }) + (!isHideCurrency ? ' ' + StorageHelper.getFromStorage(Enumerations.storage.userCurrency)?.currentCurrency?.name : '');
        }

    }

    static currencyFormatByDefaultConfig = (num, isHideCurrency) => {
        if (Number.isFinite(num)) {
            let decimalPointLength = StorageHelper.getFromStorage(Enumerations.storage.userCurrency)?.defaultCurrency?.decimalPointLength;
            return num.toLocaleString('en-US', { minimumFractionDigits: decimalPointLength, maximumFractionDigits: decimalPointLength }) + (!isHideCurrency ? ' ' + this.getDefaultCurrency() : '');
        }
        return 0;
    }

    static letterCurrencyFormatByDefaultConfig = (num, isHideCurrency) => {
        let naturalPart = Math.floor(num);
        let str = num.toString();
        let decimalIndex = str.indexOf('.');
        let decimalPart = decimalIndex !== -1 ? parseInt(str.slice(decimalIndex + 1)) : null
        let naturalNumLength = naturalPart.toString().length;

        let reversedNumArray = Array.from(String(naturalPart)).reverse().map(Number);
        let lastTwoDigits = naturalPart % 100;
        let lastThreeDigits = naturalPart % 1000;
        let hundreds = parseInt(reversedNumArray.slice(2, 3).reverse().join(''), 10);
        let thousands = parseInt(reversedNumArray.slice(3, 6).reverse().join(''), 10);
        let millions = parseInt(reversedNumArray.slice(6, 9).reverse().join(''), 10);
        let billions = parseInt(reversedNumArray.slice(9, 12).reverse().join(''), 10);
        let trillions = parseInt(reversedNumArray.slice(12, 15).reverse().join(''), 10);


        return `${!!trillions ? `${trillions} ${getTranslatedRawText('common.currencyUnits.trillions')} ${!isEnCulture() ? getTranslatedRawText('common.and') : ''}` : ''}
                ${!!billions ? `${billions} ${getTranslatedRawText('common.currencyUnits.billions')} ${!isEnCulture() ? getTranslatedRawText('common.and') : ''}` : ''}
                ${!!millions ? `${millions} ${getTranslatedRawText('common.currencyUnits.millions')} ${!isEnCulture() ? getTranslatedRawText('common.and') : ''}` : ''}
                ${!!thousands ? `${thousands} ${getTranslatedRawText('common.currencyUnits.thousands')} ${getTranslatedRawText('common.and')}` : ''}
                ${isEnCulture()
                ? `${!!hundreds ? `${hundreds} hundreds ` : ''} ${!!lastTwoDigits ? lastTwoDigits : ''}`
                : !!lastThreeDigits ? lastThreeDigits : ''}`;


    }

    static rtlFriendlyDefaultCurrencyFormat = (num) => {
        if (isEmpty(num) || (num > -1 || isEnCulture())) return CommonHelper.currencyFormatByDefaultConfig(num);

        let numberWithoutCurrency = CommonHelper.currencyFormatByDefaultConfig(Math.abs(num), true);
        let currency = CommonHelper.getDefaultCurrency();
        return <span>{numberWithoutCurrency}- {currency}</span>;
    };

    static isBlog = (url) => url.split('/').some(item => item == getEnumKeyFromValue(Enumerations.blogAndNews, Enumerations.blogAndNews.blogs));

    static createImageUrl = (imageUrl) => !!imageUrl ? Config.apiServer.baseUrl + imageUrl : '';

    static toInt = (inputVariable) => parseInt(inputVariable, 10);

    static toFloat = (inputVariable) => parseFloat(inputVariable, 10);

    static safeFunctionCall(functionDefinition, parameters) {
        let realArguments = [];
        for (let i = 1; i < arguments.length; i++)
            realArguments[i - 1] = arguments[i];

        return typeof functionDefinition === 'function' && functionDefinition(...realArguments);
    }

    static async callAsyncFunctionAsSync(targetFunction, ...parametersWithoutCallback) {

        /*
            متد 
            targetFunction
            باید حتما پارامتر آخرش 
            callback function
            باشد.
        */
        let promise = new Promise((resolve, reject) => {

            parametersWithoutCallback = [...parametersWithoutCallback, result => resolve(result)];
            CommonHelper.safeFunctionCall(targetFunction, ...parametersWithoutCallback);
        });

        return result;
    }

    static checkDevice = (regexTextLong, regexTextShort) => {
        let regexLong = new RegExp(regexTextLong, 'i');
        let regexShort = new RegExp(regexTextShort, 'i');
        var userAgent = (navigator.userAgent || navigator.vendor || window.opera);
        const widthChecking = window.innerWidth < 768;
        return widthChecking || regexLong.test(userAgent) || regexShort.test(userAgent.substr(0, 4));
    }

    static isMobileDevice = () => this.checkDevice(Constants.clientDeviceRegex.mobileLong, Constants.clientDeviceRegex.mobileShort);

    static isIosDevice = () => Constants.clientDeviceRegex.iphone.test(navigator.userAgent) && !window.MSStream;

    static isMobileOrTabletDevice = () => this.checkDevice(Constants.clientDeviceRegex.mobileOrTabletLong, Constants.clientDeviceRegex.mobileOrTabletShort);

    static cleanObject = (obj) => {
        for (const key in obj) {
            if (isEmpty(obj[key]))
                Reflect.deleteProperty(obj, key)
        }
        return obj
    }

    static removePropertyFromObject = (obj, property) => {
        if (isEmpty(obj) || isEmpty(property)) return;
        let myObject = { ...obj };
        delete myObject[property];
        return myObject;
    }

    static createArray = (length) => new Array(length).fill();

    static clearArrayFromEmptyItem = (list) => list.filter(item => !!item);

    static getBreadcrumbsSeparatorIcon = () =>
        getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en) ? 'ns-icon-expand-right' : 'ns-icon-expand-left';

    static convertByteToMegaByte = (number) => (number / 1048576).toFixed(2);

    static createStickyButtonItem = (label, icon, onClicked, className, disabled) => {
        return { label, icon, onClicked, className, disabled };
    }

    static memoizeFunc = (func) => {
        return memoizeOne(func);
    }

    static getFileExtension = (file) => file?.split('.')?.pop();

    static createDownloadFileUrl = (fileName, fileContent) => {
        let fileExtension = fileName.split('.').pop();
        let bytes = Base64ToBytes(fileContent);
        const blob = new Blob([bytes], { type: `application/${fileExtension}` });
        return URL.createObjectURL(blob);
    }

    static getPdfPageNumber = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = (res) => {
            if (res.target.result.match(/\/Type[\s]*\/Page[^s]/g))
                callback(res.target.result.match(/\/Type[\s]*\/Page[^s]/g)?.length);
            else
                callback(null);
        }
        reader.onerror = () => callback(null);
        reader.readAsBinaryString(file);
    }

    static getShareSheetStateColor = (id) => {
        switch (id) {
            case Enumerations.sharedSheetOrderStateType.notApplied: return 'warning-color';
            case Enumerations.sharedSheetOrderStateType.inFirstQueue: return 'black-color'
            case Enumerations.sharedSheetOrderStateType.prepare: return 'black-color'
            case Enumerations.sharedSheetOrderStateType.inProgress: return 'black-color'
            case Enumerations.sharedSheetOrderStateType.layouting: return 'black-color'
            case Enumerations.sharedSheetOrderStateType.progressFail: return 'red-color'
            case Enumerations.sharedSheetOrderStateType.prepareFail: return 'red-color'
            case Enumerations.sharedSheetOrderStateType.lithography: return 'black-color'
            case Enumerations.sharedSheetOrderStateType.printing: return 'black-color'
            case Enumerations.sharedSheetOrderStateType.cutting: return 'black-color'
            case Enumerations.sharedSheetOrderStateType.ready: return 'success-color'
            case Enumerations.sharedSheetOrderStateType.delivered: return 'black-color'
            case Enumerations.sharedSheetOrderStateType.inSecondQueue: return 'black-color'
            case Enumerations.sharedSheetOrderStateType.canceled: return 'light-text-color'
            case Enumerations.sharedSheetOrderStateType.transferToWarehouse: return 'success-color'
        }
    }

    static getOrderQueueRemainingDaysByEstimateTime = (day) => {
        if (0 <= day && day < 2) return 'warning-color';
        else if (day < 0) return 'red-color'
    }

    static getActivationStatusColor = (status) => {
        if (status)
            return 'success-color'
        else
            return 'red-color'
    }

    static getGrowthPercentageIcon = (percent) => {
        if (percent > 0)
            return <i className='ns-icon-up success-color' />
        else
            return <i className='ns-icon-down red-color' />
    }

    static recursiveSeparator = (parameter) => {
        let parentLessItems = [];
        let itemsWithChild = []
        const finder = (item) => {
            if (isEmpty(item.children))
                parentLessItems.push(item)
            else {
                itemsWithChild.push(item)
                item.children.map(childItem => finder(childItem))
            }
        }
        Array.isArray(parameter)
            ? parameter.map(item => finder(item))
            : finder(parameter)
        let data = {
            parentLessItems,
            itemsWithChild,
        }

        return data
    }

    static recursiveSelector = (nestedArray, payload = { targetIds: [] }) => {
        const targetReverser = (item) => {
            if (isEmpty(item.children)) {
                item.filterLabel = item.name
                if (payload.targetIds.includes(item.id)) {
                    return { ...item, checked: !isEmpty(payload.isChecked) ? !payload.isChecked : !item.checked };
                }
                else return {
                    ...item,
                    checked: !isEmpty(payload.isKeepOtherCheckes) ? payload.isKeepOtherCheckes : item.checked
                };
            } else return {
                ...item,
                children: item.children.map(childItem => targetReverser(childItem)),
            };
        }
        return nestedArray.map(item => targetReverser(item))
    }

    static addFieldToRecursiveObj = (objects, fieldName, fieldValue) => {

        if (!Array.isArray(objects) || objects.length === 0 || isEmpty(fieldName) || typeof fieldName !== 'string') {
            return [];
        } else {
            function addField(obj) {
                obj[fieldName] = fieldValue;
                if (obj.children && obj.children.length > 0) {
                    obj.children.forEach(addField);
                }
            }

            objects.forEach(addField);
            return objects;
        }
    }

    static removeMobileNumberMask = (mobile) => {
        if (!isEmpty(mobile)) {
            let convertedMobile = mobile.replace(/ /g, '').substr(1);
            return convertedMobile.split('-')[0];
        }
        return null
    }

    static pushParameterToObject = (value, key, parameters) => {
        if ((Array.isArray(value) && value.length > 0) && (!isEmpty(key) && !isEmpty(parameters))) {
            value.forEach((id, index) => {
                parameters[`${key}[${index}]`] = id;
            });
        }
        return null;
    };

    static getTotalPageCount = (totalCount, pageLength) => Math.ceil(totalCount / pageLength);

    static sortingObject = (object, sortByFeild, isDec) => {
        object.sort((a, b) => {
            if (a[sortByFeild] < b[sortByFeild]) {
                return isDec ? 1 : -1;
            }
            if (a[sortByFeild] > b[sortByFeild]) {
                return isDec ? -1 : 1;
            }

            return 0;
        });
        return object;
    }

    static getDimensionFormat = (width, height, unit) => {
        if (isEmpty(width) && isEmpty(height)) return;
        return <span>{width} × {height} {unit || getTranslatedRawText("common.mm")}</span>
    }

    static getNullIfHiddenFieldBasedOnFieldVisibilityType = (fieldVisibilityTypeId, value) => CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityTypeId) ? value : null;

    static isNotHiddenFieldBasedOnFieldVisibilityType = (fieldVisibilityType) =>
        fieldVisibilityType !== Enumerations.fieldVisibilityType.hidden

    static isReadOnlyFieldBasedOnFieldVisibilityType = (fieldVisibilityType) =>
        fieldVisibilityType === Enumerations.fieldVisibilityType.readOnly

    static isNaturalNumber = (str) => {
        var pattern = /^(0|([1-9]\d*))$/;
        return pattern.test(str);
    }
}

function Base64ToBytes(base64) {
    var s = window.atob(base64);
    var bytes = new Uint8Array(s.length);
    for (var i = 0; i < s.length; i++) {
        bytes[i] = s.charCodeAt(i);
    }
    return bytes;
}

export default CommonHelper;