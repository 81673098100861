import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";

import EnumerationTranslationHelper from "../../../utilities/EnumerationTranslationHelper";
import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import UserClaimEnumerations from "../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import NsLabelBox from "../../../components/labels/NsLabelBox";
import { isEmpty } from "../../../utilities/ValidationHelper";
import CommonHelper from "../../../utilities/CommonHelper";
import { hasAccess } from "../../../utilities/ClaimHelper";
import Enumerations from "../../../configs/Enumerations";
import DateHelper from "../../../utilities/DateHelper";

const ApBaseLabelInfo = (props) => {
    const { info, isLoading, hasAccessInfo, isEditMode, isAdminPanel } = props;

    if (!hasAccessInfo) return;

    const setOrderItemInfo = (orderItemInfoDto) => {
        if (!isEmpty(orderItemInfoDto)) {
            const { circulation, orderApplicationType, lastStatusTime, lastStatusType, nasInvoiceId, paymentInAdvance, priceInclusiveOfVat, productName, shipmentNumber, discountAmount,
                submitTime, userAgreement, serverName, totalPrice, totalQuantity, businessPartnerCustomerIdentifier, turnaroundType, twoSidePrintingType, userFullName, mobile, confirmTime,
                paymentType, nasPaymentType, detailLedgerId, estimateReadyToDeliverTime, serverNameFileRegulator, category, title } = orderItemInfoDto;
            return (
                <>
                    {!!title && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.announcementTitle')}
                        value={title} />
                    }
                    {!!userFullName && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                        value={userFullName}
                        hasTooltip={true} />
                    }
                    {!!mobile && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.mobile')}
                        value={mobile} />
                    }
                    {!!productName && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.productName')}
                        value={productName}
                        hasTooltip={true} />
                    }
                    {!!nasInvoiceId && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber')}
                        value={nasInvoiceId} />
                    }
                    {!!lastStatusType && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.lastStatus')}
                        value={ConstantsWithTranslation.getSharedSheetOrderStateType(lastStatusType)} />
                    }
                    {!!orderApplicationType && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('components.orderType.orderApplicationType')}
                        value={ConstantsWithTranslation.getOrderApplicationTypeById(orderApplicationType)} />
                    }
                    {!isEmpty(estimateReadyToDeliverTime) && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime')}
                        value={DateHelper.getDateAndTime(estimateReadyToDeliverTime)} />
                    }
                    {!!businessPartnerCustomerIdentifier && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier')}
                        value={businessPartnerCustomerIdentifier} />
                    }
                    {!!lastStatusTime && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.statusDate')}
                        value={DateHelper.getDateAndTime(lastStatusTime)} />
                    }
                    {!!submitTime && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.submitDate')}
                        value={DateHelper.getDateAndTime(submitTime)} />
                    }
                    {!!confirmTime && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.confirmDate')}
                        value={DateHelper.getDateAndTime(confirmTime)} />
                    }
                    {!!nasPaymentType && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.TermsOfPayment')}
                        value={EnumerationTranslationHelper.getModeOfPayment(nasPaymentType)} />
                    }
                    {!!paymentType && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.paymentType')}
                        value={EnumerationTranslationHelper.getPaymentTypeText(paymentType)} />
                    }
                    {!!twoSidePrintingType && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.printedSide')}
                        value={EnumerationTranslationHelper.getPrintedSideText(twoSidePrintingType)} />
                    }
                    {!!turnaroundType && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.turnaround')}
                        value={EnumerationTranslationHelper.getTurnaroundText(turnaroundType)} />
                    }
                    {!!circulation && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.circulation')}
                        value={`${circulation} ${getTranslatedRawText('product.common.pieces')}`} />
                    }
                    {!!totalQuantity && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.quantity')}
                        value={`${totalQuantity} ${getTranslatedRawText('product.common.piece')}`} />
                    }
                    {!!totalPrice && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.priceExcVat')}
                        value={CommonHelper.currencyFormatByDefaultConfig(totalPrice)} />
                    }
                    {!!priceInclusiveOfVat && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.priceIncVat')}
                        value={CommonHelper.currencyFormatByDefaultConfig(priceInclusiveOfVat)} />
                    }
                    {!!discountAmount && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.discount')}
                        value={CommonHelper.currencyFormatByDefaultConfig(discountAmount)} />
                    }
                    {!!paymentInAdvance && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.paymentInAdvance')}
                        value={CommonHelper.currencyFormatByDefaultConfig(paymentInAdvance)} />
                    }
                    {!!shipmentNumber && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.shipmentNumber')}
                        value={shipmentNumber} />
                    }
                    {!isEmpty(serverName) && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.orders.serverName')}
                        value={serverName} />}
                    {!!userAgreement?.title && <NsLabelBox className={clsx(hasAccess([UserClaimEnumerations.getApOrderUserAggrementDetail]) ? 'cursor-pointer' : '', 'background-white-color')}
                        label={getTranslatedRawText('adminPanel.singlePage.activeAgreement')}
                        value={<span>{userAgreement.title}
                            {hasAccess([UserClaimEnumerations.getApOrderUserAggrementDetail]) && <i className='ns-icon-expand-right' />}
                        </span>}
                        onClick={() => CommonHelper.safeFunctionCall(props.agreementShowDetailClicked, userAgreement.id)} />}
                    {!!detailLedgerId && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.detailLedger')}
                        value={detailLedgerId} />
                    }
                    {!!serverNameFileRegulator && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.fileRegulatorServer')}
                        value={serverNameFileRegulator} />
                    }
                    {!!category && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.category')}
                        value={category} />
                    }
                </>
            );
        }
    }

    const setStoreOrderItemInfo = (storeOrderItemInfoDto) => {
        if (!isEmpty(storeOrderItemInfoDto)) {
            const { fullName, invoiceNumber, paymentTypeId, priceIncludeVat, lastStatusTime, lastStatusType, priceExcludeVat, discount, activeAgreementId, activeAgreementName,
                paymentInAdvance, submitTime, cancellationCode } = storeOrderItemInfoDto;
            return (
                <>
                    {!!fullName && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                        value={fullName}
                        hasTooltip={true} />
                    }
                    {!!invoiceNumber && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber')}
                        value={invoiceNumber} />
                    }
                    {!!lastStatusType && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.lastStatus')}
                        value={ConstantsWithTranslation.getSharedSheetOrderStateType(lastStatusType)} />
                    }
                    {!!lastStatusTime && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.statusDate')}
                        value={DateHelper.getDateAndTime(lastStatusTime)} />
                    }
                    {!!submitTime && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.submitDate')}
                        value={DateHelper.getDateAndTime(submitTime)} />
                    }
                    {!!paymentTypeId && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.TermsOfPayment')}
                        value={EnumerationTranslationHelper.getModeOfPayment(paymentTypeId)} />
                    }
                    {!!priceExcludeVat && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.priceExcVat')}
                        value={CommonHelper.currencyFormatByDefaultConfig(priceExcludeVat)} />
                    }
                    {!!priceIncludeVat && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.priceIncVat')}
                        value={CommonHelper.currencyFormatByDefaultConfig(priceIncludeVat)} />
                    }
                    {!!discount && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.discount')}
                        value={CommonHelper.currencyFormatByDefaultConfig(discount)} />
                    }
                    {!!paymentInAdvance && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.paymentInAdvance')}
                        value={CommonHelper.currencyFormatByDefaultConfig(paymentInAdvance)} />
                    }
                    {!!activeAgreementName && <NsLabelBox className={clsx(hasAccess([UserClaimEnumerations.getApOrderUserAggrementDetail]) ? 'cursor-pointer' : '', 'background-white-color')}
                        label={getTranslatedRawText('adminPanel.singlePage.activeAgreement')}
                        value={<span>{activeAgreementName}
                            {hasAccess([UserClaimEnumerations.getApOrderUserAggrementDetail]) && <i className='ns-icon-expand-right' />}
                        </span>}
                        onClick={() => CommonHelper.safeFunctionCall(props.agreementShowDetailClicked, activeAgreementId)} />}
                    {!!cancellationCode && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('regularOrder.header.cancellationCode')}
                        value={cancellationCode} />}
                </>
            );
        }
    }

    const setPaymentItemInfo = (paymentItemInfoDto) => {
        if (!isEmpty(paymentItemInfoDto)) {
            const { amount, lastStatusTime, lastStatusType, trackNumber, cardNumber, payGateTranId, traceNumber, submitTime,
                userFullName, phoneNumber, paymentType, paymentGateway, refrenceNumber, transactionStatusMessage } = paymentItemInfoDto;
            return (
                <>
                    {!!lastStatusType && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.lastStatus')}
                        value={EnumerationTranslationHelper.getPaymentStatusTypeText(lastStatusType)} />
                    }
                    {!!lastStatusTime && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.statusDate')}
                        value={DateHelper.getDateAndTime(lastStatusTime)} />
                    }
                    {!!submitTime && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.submitDate')}
                        value={DateHelper.getDateAndTime(submitTime)} />
                    }
                    {!!userFullName && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                        value={userFullName}
                        hasTooltip={true} />
                    }
                    {!!amount && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.amount')}
                        value={CommonHelper.currencyFormatByDefaultConfig(amount)} />
                    }
                    {!!trackNumber && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.trackNumber')}
                        value={trackNumber} />
                    }
                    {!!refrenceNumber && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.referenceNumber')}
                        value={refrenceNumber} />
                    }
                    {!!paymentGateway && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.paymentGateway')}
                        value={paymentGateway} />
                    }
                    {!!paymentType && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.paymentType')}
                        value={EnumerationTranslationHelper.getPaymentTypeText(paymentType)} />
                    }
                    {!!traceNumber && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.traceNumber')}
                        value={traceNumber} />
                    }
                    {!!cardNumber && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.cardNumber')}
                        value={cardNumber} />
                    }
                    {!!payGateTranId && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.payGateTranId')}
                        value={payGateTranId} />
                    }
                    {!isEmpty(transactionStatusMessage) && <NsLabelBox className='background-white-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.transactionStatusMessage')}
                        value={transactionStatusMessage} />
                    }
                </>
            );
        }
    }

    const setCustomerFileItemInfo = (customerFileInfo) => {
        if (!isEmpty(customerFileInfo)) {
            const { createAt, createByUser, name, extension, volume, resolution, dimension, used, sharedSheetOrderId, nasInvoiceId } = customerFileInfo;
            return (
                <>
                    {!!createByUser && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                        className='background-white-color min-size'
                        value={createByUser} />
                    }
                    {!!name && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileName')}
                        className='background-white-color min-size'
                        value={name} />
                    }
                    {!!extension && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileFormat')}
                        className='background-white-color min-size'
                        value={extension.toUpperCase()} />
                    }
                    {!!volume && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileSize')}
                        className='background-white-color min-size'
                        value={`${CommonHelper.convertByteToMegaByte(volume)} MB`} />
                    }
                    {!!dimension && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileDimension')}
                        className='background-white-color min-size'
                        value={dimension} />
                    }
                    {!!resolution && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileResolution')}
                        className='background-white-color min-size'
                        value={resolution} />
                    }
                    {!!createAt && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.createDate')}
                        className='background-white-color min-size'
                        value={DateHelper.getDateAndTime(createAt)} />
                    }
                    <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.usingInAOrder')}
                        className='background-white-color min-size'
                        value={used ? getTranslatedRawText('common.yes') : getTranslatedRawText('common.no')} />

                    {!!sharedSheetOrderId && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.remotingOrderId')}
                        className='background-white-color min-size'
                        value={sharedSheetOrderId} />
                    }
                    {!!nasInvoiceId && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber')}
                        className='background-white-color min-size'
                        value={nasInvoiceId} />
                    }
                </>
            );
        }
    }

    const setShippingInfo = (shippingInfo) => {
        if (!isEmpty(shippingInfo)) {
            const { address, courier, courierAddress, senderAddress, countyWarehouseAddress, requestDate, requestNumber, transferStateType,
                tel, tel2, countyWarehouseTel1, countyWarehouseTel2, courierTel1, courierTel2, senderTel1, senderTel2, shippingPlan,
                carrier, className, shippingMethodAddress, shippingMethodName, description, orderProductType, userAddress, userFullName,
                estimatedDeliveryTimeFrom, estimatedDeliveryTimeTo, shippingPlanTypeId } = shippingInfo;
            return (
                <>
                    {!!address && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.shippings.address')}
                        value={address} />
                    }
                    {!!userAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.shippings.address')}
                        value={userAddress} />
                    }
                    {!!requestNumber && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.dataGridColumn.shippingRequestNumber')}
                        value={requestNumber} />
                    }
                    {!!requestDate && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.dataGridColumn.shippingRequestTime')}
                        value={DateHelper.getDateAndTime(requestDate)} />
                    }
                    {!!shippingPlan && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.delivery.shippingPlan')}
                        value={shippingPlan} />
                    }
                    {!!shippingPlanTypeId && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.delivery.shippingPlan')}
                        value={EnumerationTranslationHelper.getShippingPlanType(shippingPlanTypeId)} />
                    }
                    {!!shippingMethodName && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.delivery.shipmentMethod')}
                        value={shippingMethodName} />
                    }
                    {!!senderAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.deliveryRequest.senderAddress')}
                        value={senderAddress} />
                    }
                    {!!senderTel1 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.deliveryRequest.senderTel')}
                        value={senderTel1} />
                    }
                    {!!senderTel2 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.deliveryRequest.senderTel')}
                        value={senderTel2} />
                    }
                    {!!transferStateType && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.dataGridColumn.status')}
                        value={ConstantsWithTranslation.getDeliveryRequestStateType(transferStateType)} />
                    }
                    {!!description && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.delivery.shipmentDescription')}
                        value={description} />
                    }
                    {!!userFullName && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                        value={userFullName} />
                    }
                    {!!orderProductType && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.dataGridColumn.productType')}
                        value={EnumerationTranslationHelper.getProductType(orderProductType)} />
                    }
                    {!!countyWarehouseAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.deliveryRequest.countyWarehouseAddress')}
                        value={countyWarehouseAddress} />
                    }
                    {!!courierAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.deliveryRequest.courierAddress')}
                        value={courierAddress} />
                    }
                    {!!courier && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.dataGridColumn.courierServiceName')}
                        value={courier} />
                    }
                    {!!tel && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.delivery.phoneNumber')}
                        value={tel} />
                    }
                    {!!tel2 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.delivery.phoneNumber')}
                        value={tel2} />
                    }
                    {!!countyWarehouseTel1 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.deliveryRequest.countyWarehouseTel')}
                        value={countyWarehouseTel1} />
                    }
                    {!!countyWarehouseTel2 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.deliveryRequest.countyWarehouseTel')}
                        value={countyWarehouseTel2} />
                    }
                    {!!courierTel1 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.deliveryRequest.courierTel')}
                        value={courierTel1} />
                    }
                    {!!courierTel2 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.deliveryRequest.courierTel')}
                        value={courierTel2} />
                    }
                    {!!shippingMethodAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.shippings.address')}
                        value={shippingMethodAddress} />
                    }
                    {!!carrier && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('profile.delivery.carrier')}
                        value={carrier} />
                    }
                    {!!estimatedDeliveryTimeFrom && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.shippings.estimatedDeliveryTimeFrom')}
                        value={DateHelper.getDatePart(estimatedDeliveryTimeFrom)} />
                    }
                    {!!estimatedDeliveryTimeTo && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
                        label={getTranslatedRawText('adminPanel.shippings.estimatedDeliveryTimeTo')}
                        value={DateHelper.getDatePart(estimatedDeliveryTimeTo)} />
                    }
                </>
            );
        }
    }

    const setCmsLogInfo = (cmsLogInfo) => {
        if (!isEmpty(cmsLogInfo)) {
            const { clientIp, createAt, source, type, message, customLogType, host, orderApplicationType, userFullName } = cmsLogInfo;
            return (
                <>
                    {!!userFullName && <NsLabelBox className='background-disable-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                        value={userFullName} />
                    }
                    {!!customLogType && <NsLabelBox className='background-disable-color min-size'
                        label={getTranslatedRawText('adminPanel.systemMonitoring.logType')}
                        value={ConstantsWithTranslation.getLogTypeLabel(customLogType)} />
                    }
                    {!!orderApplicationType && <NsLabelBox className='background-disable-color min-size'
                        label={getTranslatedRawText('components.orderType.orderApplicationType')}
                        value={ConstantsWithTranslation.getOrderApplicationTypeById(orderApplicationType)} />
                    }
                    {!!host && <NsLabelBox className='background-disable-color min-size'
                        label={getTranslatedRawText('adminPanel.systemMonitoring.host')}
                        value={host} />
                    }
                    {!!clientIp && <NsLabelBox className='background-disable-color min-size'
                        label={getTranslatedRawText('adminPanel.systemMonitoring.clientIp')}
                        value={clientIp} />
                    }
                    {!!createAt && <NsLabelBox className='background-disable-color min-size'
                        label={getTranslatedRawText('adminPanel.dataGridColumn.createDate')}
                        value={DateHelper.getDateAndTime(createAt)} />
                    }
                    {!!type && <NsLabelBox className='background-disable-color min-size'
                        label={getTranslatedRawText('adminPanel.systemMonitoring.type')}
                        value={type} />
                    }
                    {!!source && <NsLabelBox className='background-disable-color min-size'
                        label={getTranslatedRawText('adminPanel.systemMonitoring.source')}
                        value={source} />
                    }
                    {!!message && <NsLabelBox className='background-disable-color min-size'
                        label={getTranslatedRawText('adminPanel.systemMonitoring.message')}
                        value={message} />
                    }
                </>
            );
        }
    }
    const setFeedbackBasicInfo = (feedbackTypeInfo, className) => {

        const { userFeedbackStateTypeId, sharedSheetOrderStateTypeId, isPublic, fullName, announcementTitle, productName, submitDate,
            estimateReadyToDeliverTime, employeeFullName, feedbackToAnnouncementContentTitle, nasInvoiceId } = feedbackTypeInfo;
        return <>
            {!!fullName && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                value={fullName} />
            }
            {!!employeeFullName && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.employeeFullName')}
                value={employeeFullName} />
            }
            {!!productName && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.productName')}
                value={productName} />
            }
            {!!nasInvoiceId && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber')}
                value={nasInvoiceId} />
            }
            {!!sharedSheetOrderStateTypeId && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.lastStatus')}
                value={ConstantsWithTranslation.getSharedSheetOrderStateType(sharedSheetOrderStateTypeId)} />
            }
            {!isEmpty(estimateReadyToDeliverTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime')}
                value={DateHelper.getDateAndTime(estimateReadyToDeliverTime)} />}
            {!!announcementTitle && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.announcementTitle')}
                value={announcementTitle} />
            }
            {!!feedbackToAnnouncementContentTitle && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.announcementTitle')}
                value={feedbackToAnnouncementContentTitle} />
            }
            {!!submitDate && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.feedbackSubmitDate')}
                value={DateHelper.getDateAndTime(submitDate)} />
            }
            {!isEmpty(userFeedbackStateTypeId) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.feedback.lastUserFeedbackStateTypeId')}
                value={EnumerationTranslationHelper.getTranslategetFeedbackState(userFeedbackStateTypeId)} />
            }
            {!isEmpty(isPublic) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.displaytoAll')}
                value={EnumerationTranslationHelper.getYesOrNoStatus(isPublic)} />
            }
        </>
    }

    const setFeedbackTypeInfo = (feedbackTypeInfo) => {
        const { feedbackTypeId, isActive } = feedbackTypeInfo;
        return <>
            {!isEmpty(feedbackTypeId) && <NsLabelBox className='background-white-color min-size'
                label={getTranslatedRawText('adminPanel.feedback.feedbackType')}
                value={EnumerationTranslationHelper.getTranslategetFeedbackType(feedbackTypeId)} />
            }
            {!isEmpty(isActive) && <NsLabelBox className='background-white-color min-size'
                label={getTranslatedRawText('adminPanel.orders.isActive')}
                value={EnumerationTranslationHelper.getActivationStatus(isActive)} />
            }
        </>
    }

    const setbusinessDayCalulationInfo = (businessDayCalulationInfo) => {
        const { submitTime, confirmTime, estimateReadyToDeliverTime, actualReadyToDeliveryTime, actualDeliveredTime, businessDays, fullName,
            productName, turnaroundType, printedSide, nasInvoiceId } = businessDayCalulationInfo;
        const className = clsx(isAdminPanel ? 'background-white-color' : '', 'min-size');
        return <>
            {!isEmpty(fullName) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                value={fullName} />}
            {!isEmpty(productName) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.productName')}
                value={productName} />}
            {!isEmpty(turnaroundType) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.turnaround')}
                value={EnumerationTranslationHelper.getTurnaroundText(turnaroundType)} />}
            {!isEmpty(printedSide) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.printedSide')}
                value={EnumerationTranslationHelper.getPrintedSideText(printedSide)} />}
            {!isEmpty(nasInvoiceId) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber')}
                value={nasInvoiceId} />
            }
            {!isEmpty(submitTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.submitTime')}
                value={DateHelper.getDateAndTime(submitTime)} />
            }
            {!isEmpty(confirmTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.confirmTime')}
                value={DateHelper.getDateAndTime(confirmTime)} />
            }
            {!isEmpty(estimateReadyToDeliverTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime')}
                value={DateHelper.getDateAndTime(estimateReadyToDeliverTime)} />
            }
            {!isEmpty(actualReadyToDeliveryTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.actualReadyToDeliveryTime')}
                value={DateHelper.getDateAndTime(actualReadyToDeliveryTime)} />
            }
            {!isEmpty(actualDeliveredTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.actualDeliveredTime')}
                value={DateHelper.getDateAndTime(actualDeliveredTime)} />
            }
            {!isEmpty(businessDays) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.readyTime')}
                value={businessDays} />
            }
        </>
    }

    const setSheetInfo = (sheetInfo) => {
        const { changeStateTime, changeStateUserName, closeTime, collectPaperUserName, deliveryTimeForecast, isClosed, isCollectPaper, isFilesDeleted,
            layoutedException, layoutedIsClose, layoutedIsCloseTime, lockedUserName, nasInvoiceIds, openTime, sheetID, sheetStateQueueTypeID, stateName,
            turnaroundType, twoSidePrintingType } = sheetInfo;
        const className = clsx(isAdminPanel ? 'background-white-color' : '', 'min-size');
        return <>
            {!isEmpty(stateName) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.lastStatus')}
                value={stateName} />
            }
            {!isEmpty(changeStateTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.statusDate')}
                value={DateHelper.getDateAndTime(changeStateTime)} />
            }
            {!isEmpty(sheetStateQueueTypeID) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.lastSheetStateQueueType')}
                value={ConstantsWithTranslation.getSharedSheetOrderStateType(sheetStateQueueTypeID)} />
            }
            {!isEmpty(changeStateUserName) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.lastChangeStateUser')}
                value={changeStateUserName} />
            }
            {!isEmpty(sheetID) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.orders.sheetId')}
                value={sheetID} />
            }
            {!isEmpty(turnaroundType) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.turnaround')}
                value={EnumerationTranslationHelper.getTurnaroundText(turnaroundType)} />
            }
            {!isEmpty(twoSidePrintingType) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.printedSide')}
                value={EnumerationTranslationHelper.getPrintedSideText(twoSidePrintingType)} />
            }
            {/* SERIES */}
            {!isEmpty(openTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.openTime')}
                value={DateHelper.getDateAndTime(openTime)} />
            }
            {!isEmpty(closeTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.closeTime')}
                value={DateHelper.getDateAndTime(closeTime)} />
            }
            {!isEmpty(isClosed) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.isClosed')}
                value={EnumerationTranslationHelper.getYesOrNoStatus(isClosed)} />
            }
            {!isEmpty(lockedUserName) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.lockedUsername')}
                value={lockedUserName} />
            }
            {!isEmpty(isFilesDeleted) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.isSheetDeleted')}
                value={EnumerationTranslationHelper.getYesOrNoStatus(isFilesDeleted)} />
            }
            {!isEmpty(isCollectPaper) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.collectPaperStatus')}
                value={EnumerationTranslationHelper.getYesOrNoStatus(isCollectPaper)} />
            }
            {!isEmpty(collectPaperUserName) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.collectPaperUser')}
                value={collectPaperUserName} />
            }
            {!isEmpty(layoutedIsClose) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.LayoutedCloseStatus')}
                value={EnumerationTranslationHelper.getYesOrNoStatus(layoutedIsClose)} />
            }
            {!isEmpty(layoutedIsCloseTime) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.layoutedCloseTime')}
                value={DateHelper.getDateAndTime(layoutedIsCloseTime)} />
            }
            {!isEmpty(layoutedException) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.layoutedException')}
                value={DateHelper.getDateAndTime(layoutedException)} />
            }
            {!isEmpty(layoutedException) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.layoutedException')}
                value={layoutedException} />
            }
            {/* Layouting Machine Name  */}
            {!isEmpty(deliveryTimeForecast) && <NsLabelBox className={className}
                label={getTranslatedRawText('adminPanel.dataGridColumn.deliveryTimeForecast')}
                value={DateHelper.getDateAndTime(deliveryTimeForecast)} />
            }
        </>
    }

    return (
        isLoading
            ? <Row>
                {isLoading && CommonHelper.createArray(3).map((item, index) =>
                    <Col key={index} xs={2}>
                        <NsSkeleton isVisible={true}
                            variant={[Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect]} />
                    </Col>
                )}
            </Row>
            : <div className='d-flex flex-wrap gap-2'>
                {!!info?.mobile && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.mobile')}
                    value={info?.mobile} />}
                {!!info?.submitTime && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.submitDate')}
                    value={info?.submitTime} />}
                {/* customer Labels */}
                {!!info?.fullName && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
                    value={info?.fullName} />}
                {!!info?.phoneNumber && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.mobile')}
                    value={info?.phoneNumber} />}
                {!!info?.registredTime && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.submitDate')}
                    value={DateHelper.getDateAndTime(info?.registredTime)} />}
                {!!info?.accountStatus && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.lastStatus')}
                    value={info?.accountStatus ? getTranslatedRawText('adminPanel.usersInfo.active') : getTranslatedRawText('adminPanel.usersInfo.block')} />}
                {!!info?.phoneNumberConfirmed && isEditMode && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.mobileActivation')}
                    value={info?.phoneNumberConfirmed ? getTranslatedRawText('adminPanel.usersInfo.confirmed') : getTranslatedRawText('adminPanel.usersInfo.notConfirmed')} />
                }
                {!!info?.createAt && <NsLabelBox className='background-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.createDate')}
                    value={DateHelper.getDateAndTime(info?.createAt)} />
                }
                {!!info?.editAt && <NsLabelBox className='background-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.modifyDate')}
                    value={DateHelper.getDateAndTime(info?.editAt)} />
                }
                {!isEmpty(info?.isActive) && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.orders.isActive')}
                    value={EnumerationTranslationHelper.getActivationStatus(info?.isActive)} />
                }
                {info?.userDepartments?.length > 0 && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.department')}
                    value={<div className="labelbox-truncate text-truncate">{info?.userDepartments.join(',')}</div>} />
                }
                {!isEmpty(info?.description) && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.description')}
                    value={<div className="labelbox-truncate text-truncate">{info?.description}</div>} />
                }
                {!isEmpty(info?.startDateFileRegulator) && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.orders.startDateFileRegulator')}
                    value={DateHelper.getDateAndTime(info?.startDateFileRegulator)} />
                }
                {!isEmpty(info?.serverNameFileRegulator) && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.orders.serverNameFileRegulator')}
                    value={info?.serverNameFileRegulator} />
                }
                {!isEmpty(info?.estimateReadyToDeliverTimeFrom) && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTimeFrom')}
                    value={DateHelper.getDateAndTime(info?.estimateReadyToDeliverTimeFrom)} />
                }
                {!isEmpty(info?.estimateReadyToDeliverTimeTo) && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTimeTo')}
                    value={DateHelper.getDateAndTime(info?.estimateReadyToDeliverTimeTo)} />
                }
                {!isEmpty(info?.orderItemInfoDto) && setOrderItemInfo(info?.orderItemInfoDto)}

                {!isEmpty(info?.storeOrderItemInfoDto) && setStoreOrderItemInfo(info?.storeOrderItemInfoDto)}

                {!isEmpty(info?.paymentItemInfoDto) && setPaymentItemInfo(info?.paymentItemInfoDto)}

                {!isEmpty(info?.customerFileItemInfoDto) && setCustomerFileItemInfo(info?.customerFileItemInfoDto)}

                {!isEmpty(info?.shippingInfoDto) && setShippingInfo(info?.shippingInfoDto)}

                {!isEmpty(info?.cmsLogInfoDto) && setCmsLogInfo(info?.cmsLogInfoDto)}

                {!isEmpty(info?.feedbackTypeInfo) && setFeedbackTypeInfo(info?.feedbackTypeInfo)}

                {!isEmpty(info?.feedbackBasicInfo) && setFeedbackBasicInfo(info?.feedbackBasicInfo, props?.className)}

                {!isEmpty(info?.businessDayCalulationInfo) && setbusinessDayCalulationInfo(info?.businessDayCalulationInfo)}

                {!isEmpty(info?.sheetInfoDto) && setSheetInfo(info?.sheetInfoDto)}

            </div>
    );
}

export default React.memo(ApBaseLabelInfo);