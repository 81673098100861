import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import homeDownload from '../../assets/images/homeDownload.png';
import NsCard from '../../components/cards/NsCard';
import NsImage from '../../components/images/NsImage';
import NsSkeleton from '../../components/skeletons/NsSkeleton';
import Enumerations from '../../configs/Enumerations';
import Links from '../../configs/links/AllLinks';
import { GetDownloadCategoriesService } from '../../services/singlePages/DownloadService';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../utilities/CultureHelper';

class HomeDownloadsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            categories: [],
        }
    }

    componentDidMount = () => GetDownloadCategoriesService(this.getDownloadCategoriesCallback);

    getDownloadCategoriesCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError)
            this.setState({ categories: resultData.slice(0, 4), isLoading: false });
    }

    mapDownloadCategories = () => {
        return this.state.isLoading
            ? CommonHelper.createArray(4).map((item, index) =>
                <Col md={6} key={index} >
                    <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                        ]} />
                </Col>)
            : this.state.categories.map((item, index) =>
                <Col md={6} key={index} aria-label={item.alt}>
                    <Link className='font-size-12 text-decoration-none primary-text' to={CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.download), item.id, CommonHelper.makeCorrectLink(item.title))}>
                        <div className='background-color border rounded w-100 px-3 py-2'>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex gap-3'>
                                    {item.fileUrl
                                        && <NsImage src={CommonHelper.createImageUrl(item.fileUrl) || ''}
                                            imgAlt={CommonHelper.stringFormat(getTranslatedRawText('components.imagesAlt.orderProcessSection'), item.title)}
                                            width='90px'
                                            wrapperClassName='w-auto' />}
                                    <div>{item.title}</div>
                                </div>
                                <div className='align-self-end'>
                                    <Link className='font-size-12 text-decoration-none primary-text' to={CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.download), item.id, CommonHelper.makeCorrectLink(item.title))}>
                                        <div className='d-flex align-items-center gap-1'>
                                            <i className='ns-icon-download fs-5' />
                                            <span>{getTranslatedRawText('common.download')}</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Col >
            );
    }

    render() {
        return (
            <NsCard className='p-5'>
                <Row>
                    <Col md={4} className='d-none d-lg-flex align-items-center justify-content-center'>
                        <img src={homeDownload} className='w-75'
                            alt={getTranslatedRawText("components.imagesAlt.downloadImage")} />
                    </Col>
                    <Col lg={8}>
                        <Row className='justify-content-center g-3'>
                            <Col xs={12} className='d-flex justify-content-center mb-2'>
                                <Link to={getUrlWithCurrentCulture(Links, Links.downloadCenter)} className='page-title text-center text-decoration-none black-color'>
                                    {getTranslatedRawText('singlePage.download.downloadCenter')}
                                </Link>
                            </Col>
                            {this.mapDownloadCategories()}
                        </Row>
                    </Col>
                </Row>
            </NsCard>
        );
    }
}

export default HomeDownloadsSection;