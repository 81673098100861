import Links from "../../configs/links/AllLinks";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { GetRequest } from "../bases/BaseService";

const GetBagPrintPricesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.shoppingBag, Links.api.shoppingBag.getBagPrintPrices), {}, callback);
}

export {
    GetBagPrintPricesService
}