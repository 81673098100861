import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { miniCartActionCreators } from '../../redux/reducers/MiniCartReducer';
import CommonHelper from "../../utilities/CommonHelper";
import CurrentUserHelper from "../../utilities/CurrentUserHelper";
import ImageHelper from "../../utilities/ImageHelper";
import UrlHelper from "../../utilities/UrlHelper";
import { isEmpty } from "../../utilities/ValidationHelper";
import SignInModal from "../../views/layouts/main/headers/modals/signIn/SigninModal";

function NsLinkImage(props) {
    const [showSignInModal, setShowSignInModal] = useState(false);
    const { imgInfo, imageSitePosition, imgClassName } = props;

    const isNeedSignIn = () => imgInfo?.isAuthenticationRequiredForLinkUrl && !CurrentUserHelper.getFirstName();
    const toggleModal = () => setShowSignInModal(!showSignInModal);
    const signInClicked = () => props.getTotalPriceAndCount();

    const promotionAuthenticationHandler = () => {
        return imgInfo?.isAuthenticationRequiredForLinkUrl
            ? !!CurrentUserHelper.getFirstName()
            && UrlHelper.linkUrlHandler(imgInfo?.linkUrl, window.location.host).url
            : UrlHelper.linkUrlHandler(imgInfo?.linkUrl, window.location.host).url;
    };

    return (
        <>
            {isEmpty(imgInfo?.linkUrl) ? (
                <img
                    className={imgClassName}
                    src={CommonHelper.createImageUrl(imgInfo?.fileUrl)}
                    srcSet={ImageHelper.getCdnImageSrcset(imageSitePosition, CommonHelper.createImageUrl(imgInfo?.fileUrl))}
                    alt={imgInfo?.title}
                    onLoad={(e) => props.onLoad && props.onLoad(e)}
                    onClick={(e) => props.onClick && props.onClick(e)} />
            ) : (
                <Link rel="nofollow"
                    to={!isNeedSignIn() && promotionAuthenticationHandler()}
                    target={!isNeedSignIn() && UrlHelper.linkUrlHandler(imgInfo?.linkUrl, window.location.host).target}
                    onClick={isNeedSignIn() ? () => setShowSignInModal(true) : null}>
                    <img
                        className={imgClassName}
                        src={CommonHelper.createImageUrl(imgInfo?.fileUrl)}
                        srcSet={ImageHelper.getCdnImageSrcset(imageSitePosition, CommonHelper.createImageUrl(imgInfo?.fileUrl))}
                        alt={imgInfo?.title}
                        onLoad={(e) => props.onLoad && props.onLoad(e)}
                        onClick={(e) => props.onClick && props.onClick(e)}
                    />
                </Link >
            )
            }
            {showSignInModal &&
                <SignInModal closeModal={toggleModal}
                    signInClicked={() => signInClicked()}
                />}
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...miniCartActionCreators }, dispatch);
}
export default connect(null, mapDispatchToProps)(NsLinkImage);