import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import UpShippingRequestHeaderPlanAvailabilityOrMethod from '../shippingRequests/UpShippingRequestHeaderPlanAvailabilityOrMethod';
import NsToggleGroupButton from '../../../../../components/buttons/NsToggleGroupButton';
import UpShippingPlanInformationModal from '../modals/UpShippingPlanInformationModal';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsTypography from '../../../../../components/text/NsTypography';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';

class UpShippingRequestHeaderPlan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showShippingPlanInformationModal: false,
        }
    }

    toggleShippingPlanInformationModal = () => this.setState({ showShippingPlanInformationModal: !this.state.showShippingPlanInformationModal });

    render() {
        const { selectedAddress, shippingPlans, selectedShippingPlan, selectedShippingMethod, shippingMethods,
            shippingPlanClicked, availableMethodChanged, orderProductType } = this.props;
        const { showShippingPlanInformationModal } = this.state;

        return (
            <>
                <Col md={12} xs={12} className='pt-3 mt-3'>
                    <NsToggleGroupButton isShowGroupLabel={true}
                        label={getTranslatedRawText('profile.delivery.shippingPlans')}
                        vertical={false}
                        disabled={!selectedAddress?.id}
                        onClick={shippingPlanClicked}
                        selectedItemId={selectedShippingPlan && selectedShippingPlan?.key}
                        items={shippingPlans}
                        className='d-inline-block' />
                    {selectedShippingPlan?.isAvailable &&
                        <ShippingPlanTypeHint selectedAddress={selectedAddress}
                            toggleShippingPlanInformationModal={this.toggleShippingPlanInformationModal} />
                    }
                </Col>
                <Row className='my-3'>
                    <UpShippingRequestHeaderPlanAvailabilityOrMethod orderProductType={orderProductType}
                        selectedShippingPlan={selectedShippingPlan}
                        selectedAddress={selectedAddress}
                        availableMethodChanged={availableMethodChanged}
                        selectedShippingMethod={selectedShippingMethod}
                        shippingMethods={shippingMethods}
                        getIsAvailabel={this.props.getIsAvailabel}
                        createExpressShippingAvailableMessage={this.props.createExpressShippingAvailableMessage} />
                </Row>
                {showShippingPlanInformationModal &&
                    <UpShippingPlanInformationModal selectedShippingPlan={selectedShippingPlan}
                        shippingPlans={shippingPlans}
                        closeModal={this.toggleShippingPlanInformationModal} />
                }
            </>
        )
    }
}

function ShippingPlanTypeHint({ selectedAddress, toggleShippingPlanInformationModal }) {
    return selectedAddress?.id
        ? <div className={clsx(isEmpty(selectedAddress?.id) && 'light-text-color', 'ns-icon-question font-size-24 cursor-pointer mx-1 d-inline-block')}
            onClick={() => CommonHelper.safeFunctionCall(toggleShippingPlanInformationModal)} />
        : <div className='pt-2'>
            <NsTypography variant='inherit' className='font-size-12 red-color mt-2' >
                <i className='ns-icon-information red-color' />{getTranslatedRawText('profile.delivery.addressErrorMessage')}
            </NsTypography>
        </div>
}

export default UpShippingRequestHeaderPlan;