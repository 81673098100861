import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsPageMainContentWrapper from '../../components/cards/NsPageMainContentWrapper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import '../../assets/sass/views/profileSettings/ProfileSetting.scss';
import CommonHelper from '../../utilities/CommonHelper';
import UpSidebarMain from './sidebars/UpSidebarMain';

class UserPanelWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSidebarDisplayed: false,
        };
    }

    componentDidMount = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    componentDidUpdate = (prevProps) => {
        if (prevProps.sidebarProfileToggleDate !== this.props.sidebarProfileToggleDate)
            this.setState({ isSidebarDisplayed: true });
    }

    displayToggler = () => this.setState({ isSidebarDisplayed: !this.state.isSidebarDisplayed });

    render() {
        return (
            <Row className="profile-setting">

                <Col md={4} lg={3} className={this.state.isSidebarDisplayed ? '' : 'd-none d-md-block'} >
                    <UpSidebarMain onClick={this.displayToggler}
                        refreshUserAccountDate={this.props.refreshUserAccountDate} />
                </Col>

                <Col md={8} lg={9} className={clsx('px-0', this.state.isSidebarDisplayed ? 'd-none d-md-block' : '')} >

                    {CommonHelper.isMobileDevice() &&
                        <div className='d-flex align-items-center background-white-color p-2 gap-1 mb-2' onClick={this.displayToggler}>
                            <i className={CommonHelper.getBackThinIconByCulture()} />
                            <span>{getTranslatedRawText('profile.common.backToMenu')}</span>
                        </div>
                    }
                    <div className={CommonHelper.isMobileDevice() ? 'px-2' : ''} >
                        <NsPageMainContentWrapper header={this.props.titleName}
                            icon={this.props.titleIcon}>
                            {this.props.children}
                        </NsPageMainContentWrapper>
                    </div>
                </Col>

            </Row >
        );
    }
}

export default UserPanelWrapper;