import React from 'react';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import OrderHelper from '../../../utilities/OrderHelper';

class OSummaryChoosingFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refreshSummaryDate: props.refreshSummaryDate,
            choosingFileSummary: OrderHelper.getFileSizeAndOrderPrices(props.routeId),
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshSummaryDate !== prevState.refreshSummaryDate) 
            return ({ choosingFileSummary: OrderHelper.getFileSizeAndOrderPrices(nextProps.routeId), refreshSummaryDate: nextProps.refreshSummaryDate });
        return null
    }

    render() {
        const { isViewMore } = this.props;
        const { choosingFileSummary } = this.state;

        return (
            <div className='mb-3'>
                <div className='border-bottom pb-1'>
                    <div className='font-size-14 fw-bold mb-1'>{getTranslatedRawText('regularOrder.confirmDetail.productDetails')}</div>
                    <div className='w-100 font-size-12 mb-1'>
                        {getTranslatedRawText('regularOrder.confirmDetail.productUpDimension')}:&nbsp;
                        <span className='fw-bold '>
                            {choosingFileSummary?.productDimension ?? '--'}
                        </span>
                    </div>
                    <div className='w-100 font-size-12 mb-1'>
                        {getTranslatedRawText('regularOrder.confirmDetail.fileDimension')}:&nbsp;
                        <span className='fw-bold'>
                            {choosingFileSummary?.fileDimension ?? '--'}
                        </span>
                    </div>
                </div>
                <div className='w-100 font-size-12 mt-1'>
                    {getTranslatedRawText('regularOrder.confirmDetail.productPrice')}:&nbsp;
                    <span className='fw-bold'>
                        {isViewMore
                            ? CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.priceDetailWithUps'), CommonHelper.currencyFormatByDefaultConfig(choosingFileSummary.basePrice), choosingFileSummary.latCount,
                                CommonHelper.currencyFormatByDefaultConfig(choosingFileSummary?.basePrice * choosingFileSummary?.latCount))
                            : CommonHelper.currencyFormatByDefaultConfig(choosingFileSummary?.basePrice * choosingFileSummary?.latCount)
                        }
                    </span>
                </div>
            </div>
        );
    }
}

export default OSummaryChoosingFile;