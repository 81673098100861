import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import NsCheckboxTreeView from '../treeViews/NsCheckboxTreeView';
class NsDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            icon: this.props.iconAfterTitle,
            isShow: false
        };
    }

    toggleDropdown = () => this.setState({ isShow: !this.state.isShow })

    render() {
        const { iconBeforeTitleClassName, iconBeforeTitle,rel, iconAfterTitleClassName, items, label, title, toggleClassName, className, menuClassName } = this.props;
        const { icon, isShow } = this.state;
        return (
            <div className={clsx("dropdown ", className)}>
                <Link className={toggleClassName}
                    to="#"
                    onClick={this.toggleDropdown}
                    data-bs-toggle="dropdown"
                    aria-label={label}
                    rel={rel}
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i className={clsx(iconBeforeTitleClassName, iconBeforeTitle)} />
                    <span className='text-nowrap'>{title}</span>
                    <i className={clsx(iconAfterTitleClassName, icon)} />
                </Link>

                <ul className={clsx(isShow ? 'd-block position-absolute mt-2 px-2' : 'd-none', menuClassName, 'dropdown-menu ')}
                    aria-labelledby={label}>
                    <NsCheckboxTreeView items={items}
                        hasDefaultExpanded={true}
                        menuItemClicked={(item) => this.setState({ isShow: false })}
                        isMenu={true}
                        menuClassName={'font-size-10'}
                        isHeader={true} />
                </ul>
            </div>
        );
    }
}

export default NsDropdown;