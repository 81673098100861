import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NsButton from '../../../components/buttons/NsButton';
import { fileManagerActionCreators } from '../../../redux/reducers/FileManagerReducer';
import CommonHelper from '../../../utilities/CommonHelper';
import FmCreateFolderModal from '../modals/FmCreateFolderModal';
import FmUploadFileModal from '../modals/FmUploadFileModal';


class FmHeaderToolbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isShowCreateFolderModal: false,
            isShowFmUploadFileModal: false,
        }
    }

    createFolderClicked = () => this.setState({ isShowCreateFolderModal: true });

    closeModals = (isRefreshNeeded) => {
        this.setState({ isShowCreateFolderModal: false, isShowFmUploadFileModal: false });
        !!isRefreshNeeded && this.props.refreshTreeView();
    }

    uploadFileClicked = () => {
        this.setState({ isShowFmUploadFileModal: true });
        CommonHelper.safeFunctionCall(this.props.toggleProfessionalOrderTour);
    }

    closeUploadModal = (isRefreshNeeded) => {
        this.setState({ isShowFmUploadFileModal: false });
        this.props.setCurrentUploadFolderId(this.props.currentFolderId);
        !!isRefreshNeeded && this.props.refreshTreeView();
    }

    refreshMainSectionItems = () => {
        this.props.refreshMainSectionItems();
        this.props.refreshHeaderDiskUsage();
    }

    render() {
        return (
            <div className="d-flex align-items-baseline">

                <NsButton className='secondary-outline'
                    wrapperClassName={clsx('pe-1', this.props.wrapperClassName)}
                    size='small'
                    startIcon={'ns-icon-new-folder'}
                    label={!this.props.isUsedInPo && 'fileManager.headerActionButtons.newFolder'}
                    onClick={this.createFolderClicked} />

                <NsButton className='secondary'
                    wrapperClassName={clsx('pe-1', this.props.wrapperClassName)}
                    size='small'
                    startIcon={'ns-icon-upload'}
                    label={!this.props.isUsedInPo && 'fileManager.headerActionButtons.uploadFile'}
                    onClick={this.uploadFileClicked} />

                {this.state.isShowCreateFolderModal &&
                    <FmCreateFolderModal showModal={this.state.isShowCreateFolderModal}
                        treeViewFolders={this.props.treeViewFolders}
                        currentFolderId={this.props.currentFolderId}
                        onClose={this.closeModals} />
                }

                {!!this.state.isShowFmUploadFileModal &&
                    <FmUploadFileModal showModal={this.state.isShowFmUploadFileModal}
                        treeViewFolders={this.props.treeViewFolders}
                        currentFolderId={this.props.currentUploadFolderId}
                        onClose={this.closeUploadModal}
                        refresh={this.refreshMainSectionItems}
                        isUsedInPo={this.props.isUsedInPo} />
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentFolderId: state.fileManager.currentFolderId,
        currentUploadFolderId: state.fileManager.currentUploadFolderId,
    }
}

const mapDispatchToPops = (dispatch) => {
    return bindActionCreators(fileManagerActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToPops)(FmHeaderToolbar);