import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import '../../assets/sass/views/accounts/Account.scss';
import NsButton from '../../components/buttons/NsButton';
import Config from '../../configs/Config';
import Enumerations from '../../configs/Enumerations';
import Links from '../../configs/links/AllLinks';
import { metaTagActionCreators } from '../../redux/reducers/MetaTagReducer';
import {
    getCurrentDirection,
    getTranslatedRawText, getUrlWithCurrentCulture
} from '../../utilities/CultureHelper';
import { withRouter } from 'react-router-dom';

class LoginLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.initializeState(props);
    }

    initializeState = (props) => {
        if (props.layoutType === Enumerations.loginLayoutType.signIn)
            return {
                blackPanelOrder: 'order-md-2',
                whitePanelOrder: 'order-md-1 ps-sm-5',
                label: "account.common.signup",
                icon: 'ns-icon-sign-up',
                redirect: getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.mobile),
                locale: getCurrentDirection(props.locale)
            };
        else
            return {
                blackPanelOrder: 'order-md-1',
                whitePanelOrder: 'order-md-2 pe-sm-5',
                label: "account.common.login",
                icon: 'ns-icon-sign-in',
                redirect: getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn),
                locale: getCurrentDirection(props.locale)
            }
    }

    // UNSAFE_componentWillReceiveProps(newProps) {
    //     this.setState({
    //         locale: getCurrentDirection(newProps.locale)
    //     });
    //     if (newProps.layoutType !== this.props.layoutType)
    //         this.setState(this.initializeState(newProps));

    // }

    changeOrder = () => {
        this.setState({
            redirect: this.state.redirect === getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn)
                ? getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.mobile)
                : getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn)
        });
    }

    render() {
        return (
            <Row className="min-vh-100">
                <Col md={10} lg={8} xl={6} className="mx-auto align-self-center">
                    <Row className="black-panel g-0">
                        {/* black panel */}
                        <Col sm={4} md={5} className={clsx("py-sm-4", this.state.blackPanelOrder)}>
                            <div className="d-flex flex-column align-items-center h-100">
                                <Link to={getUrlWithCurrentCulture(Links, Links.home)}>
                                    <img src={Config.siteLogo} className='p-3 img-fluid' alt={getTranslatedRawText('components.imagesAlt.home')} />
                                </Link>
                                <div className="my-2 button-link mt-auto pb-2">
                                    <Link className='home-link' to={getUrlWithCurrentCulture(Links, Links.home)}>{getTranslatedRawText('account.common.home')}</Link>
                                    <Link to={this.state.redirect} className='d-none d-sm-block'>
                                        <NsButton className="primary mt-2"
                                            startIcon={this.state.icon}
                                            onClick={this.changeOrder}
                                            label={this.state.label} />
                                    </Link>
                                </div>
                            </div>
                        </Col>

                        <Col sm={8} md={7} className={clsx("align-self-center", this.state.whitePanelOrder)}>
                            {this.props.children}
                        </Col>

                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({ locale: state.i18n.locale });

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(metaTagActionCreators, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginLayout));