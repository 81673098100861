import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../assets/sass/views/home/Home.scss';
import Config from '../../configs/Config';
import Enumerations from '../../configs/Enumerations';
import Links from '../../configs/links/AllLinks';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import HomeAboutSection from './HomeAboutSection';
import HomeAdvertisementSection from './HomeAdvertisementSection';
import HomeBannerSection from './HomeBannerSection';
import HomeDownloadsSection from './HomeDownloadsSection';
import HomeOrderingProcessSection from './HomeOrderingProcessSection';
import HomeProductSection from './HomeProductSection';
import HomeStoreProductSection from './HomeStoreProductSection';
import '../../assets/sass/views/home/Home.scss';
import HomeBaseInstantDiscount from './bases/HomeBaseInstantDiscount';
import { isEmpty } from '../../utilities/ValidationHelper';

class Home extends Component {

    componentDidMount = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    render() {
        return (
            <div className="home d-flex flex-column">
                <div className="home-banner my-3">
                    <HomeBannerSection />
                </div>
                {/* {Config.homeSection?.showBestSellerProducts && <div className="mb-5" aria-label='best Selling'>
                    <HomeProductSection sortIndex={Enumerations.sortItems.bestSelling}
                        isAuthenticated={!!this.props.userFullName}
                        reachResultType={Enumerations.reachResultType.breadCrumbs}
                        linkTo={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)}
                        primaryTitle={getTranslatedRawText('home.productSection.bestSellers')}
                        secondaryTitle={getTranslatedRawText('home.productSection.groupSheets')} />
                </div>
                } */}

                <HomeAdvertisementSection />

                {Config.homeSection?.showIsNewestProducts && <div className="mb-5" aria-label='new arrival'>
                    <HomeProductSection sortIndex={Enumerations.sortItems.newest}
                        isAuthenticated={!!this.props.userFullName}
                        reachResultType={Enumerations.reachResultType.itemListProducts}
                        linkTo={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { 'sort-by': Enumerations.sortItems.newest })}
                        primaryTitle={getTranslatedRawText('home.productSection.newArrival')}
                        secondaryTitle={getTranslatedRawText('home.productSection.groupSheets')} />
                </div>
                }

                <HomeAdvertisementSection isBottomSec={true} />

                {!isEmpty(this.props.userFullName) && <HomeBaseInstantDiscount />}

                <HomeStoreProductSection />

                <div className="home-ordering mb-5" aria-label='download templates'>
                    <HomeDownloadsSection />
                </div>

                <div className="home-about mb-5">
                    <HomeAboutSection />
                </div>

                <div className="home-ordering mb-5 d-none d-lg-block" aria-label='order steps'>
                    <HomeOrderingProcessSection />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userFullName: state.currentUser.userFullName,
    };
};

export default connect(mapStateToProps)(Home);