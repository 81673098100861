import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router";

import '../../../assets/sass/views/store/Store.scss'
import NsSetPageTitleAndDescription from "../../../components/metaTags/NsSetPageTitleAndDescription";
import { GetStoreProductInfoService } from "../../../services/product/StoreProductService";
import NsLoadingProgress from "../../../components/circularProgress/NsLoadingProgress";
import NsToggleGroupButton from "../../../components/buttons/NsToggleGroupButton";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import PdBaseSimilarProducts from "../bases/PdBaseSimilarProducts";
import { isEmpty } from "../../../utilities/ValidationHelper";
import Enumerations from "../../../configs/Enumerations";
import PdBaseImagePanel from "../bases/PdBaseImagePanel";
import NsCard from "../../../components/cards/NsCard";
import UrlHelper from "../../../utilities/UrlHelper";
import PdsDescription from "./PdsDescription";
import PdsProperties from "./PdsProperties";
import PdsSuppliers from "./PdsSuppliers";

class PdsStoreDetail extends React.Component {

    constructor(props) {
        super(props);
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = {
            isLoading: false,
            product: {},
            selectedUnit: null,
            selectedProperties: [],
            getSupplierDate: null,
            getSimilarProductsDate: null,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.getStoreProduct();

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getStoreProduct();
        }
        if (prevProps.selectedCurrency !== this.props.selectedCurrency)
            this.getStoreProduct();
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    getStoreProduct = () => {
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.setState({ isLoading: true });
        GetStoreProductInfoService(this.routeId, this.getStoreProductInfoCallback);
    }

    getStoreProductInfoCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError) {
            resultData.effectiveProperties = this.getProperties(resultData.storeProductProperties, true);
            resultData.nonEffectiveProperties = this.getProperties(resultData.storeProductProperties, false);
            this.setState({
                product: resultData,
                selectedUnit: resultData.storeProductUnitValues[0].key,
                selectedProperties: this.getDefaultSelectedPropertiesValue(resultData.effectiveProperties),
                getSupplierDate: new Date(),
                getSimilarProductsDate: new Date(),
                isLoading: false
            });

        } else {
            this.setState({ resultMessageClass, isLoading: false });
        }
    }

    getProperties = (properties, filter) => properties.filter(property => property.hasPriceEffect === filter);

    getDefaultSelectedPropertiesValue = (data) => data.map(property => property.propertyValues[0].key);


    unitClicked = (selectedUnit) => {
        this.setState({ selectedUnit: selectedUnit?.key, getSupplierDate: new Date() });
    }

    propertyClicked = (selectedProperty, index, hasPriceEffect) => this.setState(oldState => {
        oldState.selectedProperties[index] = selectedProperty.key
        return { selectedProperty: oldState.selectedProperties };
    }, () => {
        hasPriceEffect && this.setState({ getSupplierDate: new Date() });
    });

    render() {
        const { isLoading, product, selectedUnit, selectedProperties, getSupplierDate, getSimilarProductsDate, resultMessageClass } = this.state;

        return <>
            <NsCard className='mb-3'>
                <Row className={clsx(this.props.isUsedInPo ? '' : "product-detail justify-content-center m-0 p-0 mb-5 mb-md-0")} onKeyDown={this.keyDown}>
                    <NsLoadingProgress visible={isLoading} />
                    <Col xs={10} sm={8} lg={4} className="p-3">
                        <PdBaseImagePanel isLoading={isLoading}
                            className='product-detail__product-image'
                            product={product} />
                    </Col>

                    <Col lg={5} className="py-2">
                        <div className="d-md-flex justify-content-between pb-2">
                            <span className="page-title mb-2">
                                {product.name}
                            </span>
                        </div>
                        <p>
                            {product.summary}
                        </p>
                        <NsToggleGroupButton isShowGroupLabel={true}
                            label={getTranslatedRawText('product.customCard.availableUnitType')}
                            labelClassName='light-paragraph'
                            borderColor='mb-3'
                            vertical={false}
                            onClick={this.unitClicked}
                            selectedItemId={selectedUnit}
                            items={product?.storeProductUnitValues || []} />

                        <StoreProductProperties properties={product?.effectiveProperties || []}
                            selectedProperties={selectedProperties}
                            propertyClicked={this.propertyClicked} />

                        <PdsProperties className='mt-3'
                            isLoading={isLoading}
                            nonEffectiveProperties={product?.nonEffectiveProperties || []} />
                    </Col>

                    <Col lg={3} className="align-self-end py-2">
                        <PdsSuppliers isAuthenticated={!!this.props.userFullName}
                            parameters={{
                                'storeProductPropertyValueIds[0]': selectedProperties[0],
                                StoreProductUnitValueId: selectedUnit,
                                storeProductId: this.routeId
                            }}
                            selectedCurrency={this.props.selectedCurrency?.id}
                            getSupplierDate={getSupplierDate} />
                    </Col>
                </Row>
            </NsCard>
            {false && <PdsDescription description={product?.description}
                overview={product?.overview}
                isLoading={isLoading} >

                <PdBaseSimilarProducts
                    getSimilarProductsDate={getSimilarProductsDate}
                    selectedCurrency={this.props.selectedCurrency?.id}
                    categoryIds={isEmpty(product) ? [] : product.categories.map(category => category.id)} />
            </PdsDescription>
            }
            <NsSetPageTitleAndDescription pageTitle={product.name}
                description={product?.description} />
            {resultMessageClass}
        </>
    }
}

function StoreProductProperties(props) {
    const { properties, propertyClicked, selectedProperties } = props;
    return isEmpty(properties)
        ? null
        : properties.map((property, index) => (
            <NsToggleGroupButton isShowGroupLabel={true}
                key={index}
                label={property?.title}
                borderColor='mb-3'
                labelClassName='light-paragraph'
                vertical={false}
                onClick={(item) => propertyClicked(item, index, property.hasPriceEffect)}
                selectedItemId={selectedProperties[index] || null}
                items={!isEmpty(property) ? property?.propertyValues : []} />
        ));
}

const mapStateToProps = (state) => {
    return {
        userFullName: state.currentUser.userFullName,
        selectedCurrency: state.currencies.selectedCurrency
    };
}

export default withRouter(connect(mapStateToProps)(PdsStoreDetail));