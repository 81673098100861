import React from 'react';

import UpStoreReadyToDeliverOrderDetailModal from '../userOrder/stores/readyToDeliver/UpStoreReadyToDeliverOrderDetailModal';
import { GetDeliveryRequestsStoreOrdersService } from '../../../../services/ProfileSetting/StoreOrderService';
import { GetShippingRequestOrdersService } from '../../../../services/ProfileSetting/UserOrderService';
import UpGroupSheetReadyToDeliverOrderDetailModal from '../userOrder/groupSheets/readyToDeliver/UpGroupSheetReadyToDeliverOrderDetailModal';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { createStoreOrderColumns } from '../userOrder/stores/bases/UpBaseStoreOrderHelper';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import { createMyShippingDetailColumn } from '../userOrder/groupSheets/bases/UpBaseGroupSheetOrderHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import GridEnumerations from '../../../../configs/GridEnumerations';
import UpShippingDetailHeader from './UpShippingDetailHeader';
import CommonHelper from '../../../../utilities/CommonHelper';
import NsModal from '../../../../components/modals/NsModal';
import Enumerations from '../../../../configs/Enumerations';
import NsTab from '../../../../components/tabs/NsTab';
import Config from '../../../../configs/Config';

class UpShippingDetail extends React.Component {

    constructor(props) {
        super(props);
        this.groupSheetOrdercolumns = createMyShippingDetailColumn(this.detailViewClicked, props.inModal);
        this.storeOrdercolumns = createStoreOrderColumns(this.detailViewClicked);
        this.state = {
            showDeliveryRequestOrderDetailModal: false,
            deliveryRequestId: null,
            tabs: [],
            selectedTab: null,
            selectedTabIndex: 0,
            currentPageIndex: 1,
            rowPerPage: CommonHelper.defaultRowPerPage(2),
            callServiceDate: null
        };
    }

    updateOrdersCount = (badgeCount0, badgeCount1) => {
        let tabs = ConstantsWithTranslation.getOrderProductTabs(!!badgeCount0, !!badgeCount1);
        if (tabs.length > 1) {
            tabs[0].badgeCount = badgeCount0;
            tabs[1].badgeCount = badgeCount1;
        }
        this.setState({ tabs, selectedTab: tabs[0], callServiceDate: new Date() });
    }

    detailViewClicked = (id) => this.setState({ showDeliveryRequestOrderDetailModal: true, deliveryRequestId: id });

    refreshClicked = (props) => { console.log(props); }

    closeDetailView = () => this.setState({ showDeliveryRequestOrderDetailModal: false });

    tabChanged = (newValue, selectedTab) => {
        newValue !== this.state.selectedTabIndex && this.setState({
            selectedTabIndex: newValue,
            callServiceDate: new Date(),
            selectedTab,
        });
    }

    render() {
        const { deliveryRequestId, showDeliveryRequestOrderDetailModal, tabs, selectedTabIndex, selectedTab, callServiceDate } = this.state;
        const isStore = selectedTab?.id == Enumerations.orderProductType.store;

        return (
            <NsModal maxWidth="lg"
                dialogTitle={getTranslatedRawText('profile.detail.deliveryRequestDetail')}
                showModal={true}
                noSpace={true}
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeModal)}>

                <div>
                    <UpShippingDetailHeader orderId={this.props.orderId}
                        updateOrdersCount={this.updateOrdersCount} />
                    <div className='p-3'>
                        {Config.hasStoreProducts && <div className='w-100 mt-3'>
                            <NsTab value={selectedTabIndex}
                                tabs={tabs}
                                onChange={this.tabChanged} />
                        </div>}
                        <div className='w-100'>
                            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                                columnDefs={isStore ? this.storeOrdercolumns : this.groupSheetOrdercolumns}
                                hasPagination={true}
                                service={isStore ? GetDeliveryRequestsStoreOrdersService : GetShippingRequestOrdersService}
                                parameters={{
                                    filter: {
                                        id: this.props.orderId
                                    }
                                }}
                                gridId={selectedTab?.id === Enumerations.orderProductType.sharedSheet ? GridEnumerations.siteGrids.myShippmentGroupSheetDetail : GridEnumerations.siteGrids.myShippmentStoreDetail}
                                hasCustomColumnOrder={true} />
                        </div>
                    </div>
                </div>

                {showDeliveryRequestOrderDetailModal && !isStore &&
                    <UpGroupSheetReadyToDeliverOrderDetailModal closeModal={this.closeDetailView}
                        showModal={true}
                        orderId={deliveryRequestId} />
                }
                {showDeliveryRequestOrderDetailModal && isStore &&
                    <UpStoreReadyToDeliverOrderDetailModal closeModal={this.closeDetailView}
                        showModal={true}
                        orderId={deliveryRequestId} />
                }
            </NsModal>
        );
    }
}

export default UpShippingDetail;