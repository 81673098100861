import React from 'react';

import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
// import UserOrderActionButtons from '../../../bases/UserOrderActionButtons';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import NsModal from '../../../../../../components/modals/NsModal';
import UpBaseStoreOrderDetailGrid from '../bases/UpBaseStoreOrderDetailGrid';
import NsStickyButton from '../../../../../../components/buttons/NsStickyButton';
import NsMessageClassModal from '../../../../../../components/modals/NsMessageClassModal';
import Enumerations from '../../../../../../configs/Enumerations';
import { DeleteFromCartStoreProductService } from '../../../../../../services/ProfileSetting/StoreOrderService';
import OrderCartConfirmationModal from '../../../../modals/OrderCartConfirmationModal';

class UpStoreNotAppliedOrderDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.buttons = [
            CommonHelper.createStickyButtonItem("profile.orderCart.checkoutAndPay", 'ns-icon-confirm', this.confirmClicked, 'primary'),
            CommonHelper.createStickyButtonItem("common.delete", 'ns-icon-delete', () => this.deleteClicked(null), 'secondary'),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-cancel', () => this.props.closeModal(), 'primary-outline'),
        ]
        this.state = {
            showDeleteOrderMessageModal: false,
            showConfirmOrderModal: false,
            data: [],
            isLoading: true,
        };
    }

    deleteClicked = () => this.setState({ showDeleteOrderMessageModal: true });

    deleteOrder = (confirm) => {
        !!confirm && this.setState({ isLoading: true });
        if (!!confirm) {
            DeleteFromCartStoreProductService({ orderIds: [this.props.orderId] }, this.deleteOrderCallback, true);
        } else {
            this.closeDeleteOrderMessageModal(false);
        }
    }

    deleteOrderCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError) {
            this.closeDeleteOrderMessageModal(true, resultMessageClass);
        }
        this.setState({ resultMessageClass, isLoading: false });
        this.closeDeleteOrderMessageModal(false);
    }

    closeDeleteOrderMessageModal = (confirm, resultMessageClass) => {
        this.setState({ showDeleteOrderMessageModal: false, showConfirmOrderModal: false },
            () => {
                !!confirm && this.props.closeModal(true, resultMessageClass);
            }
        );
    }

    confirmClicked = () => this.setState({ showConfirmOrderModal: true });

    getData = (data, isLoading) => this.setState({ data, isLoading });

    render() {
        const { showDeleteOrderMessageModal, showConfirmOrderModal, isLoading, resultMessageClass, data } = this.state;
        const { orderId } = this.props;
        return (
            <NsModal maxWidth="md"
                dialogTitle={getTranslatedRawText('profile.detail.orderDetail')}
                showModal={true}
                noSpace={true}
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeModal)}
                actions={<NsStickyButton stickyButtons={this.buttons} isLoading={isLoading} />}>

                <div className='p-3'>
                    <UpBaseStoreOrderDetailGrid orderId={orderId}
                        getData={this.getData} />
                </div>
                {showDeleteOrderMessageModal &&
                    <NsMessageClassModal show={true}
                        onClose={this.deleteOrder}
                        messageItems={[
                            {
                                messageText: getTranslatedRawText('common.messages.areYouSureQuestionSingleOrderWithoutParam'),
                                messageType: Enumerations.messageType.question
                            }]} />
                }
                {showConfirmOrderModal &&
                    <OrderCartConfirmationModal isStore={true}
                        selectedRowIds={[orderId]}
                        isNotAppliedStoreOrders={true}
                        onClose={this.closeDeleteOrderMessageModal} />
                }
                {resultMessageClass}
            </NsModal>
        );
    }
}

export default UpStoreNotAppliedOrderDetailModal;