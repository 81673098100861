import clsx from 'clsx';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import OrderHelper from '../../../../../utilities/OrderHelper';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import NsAlertModal from '../../../../../components/modals/NsAlertModal';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import PoBaseImageInModal from '../bases/PoBaseImageInModal';
import PoBaseGuidanceInModal from '../bases/PoBaseGuidanceInModal';
import PoBaseFileSelectorHelper from '../bases/PoBaseFileSelectorHelper';
import PoBaseEditFileActionInModal from '../bases/PoBaseEditFileActionInModal';
import NsLoadingProgress from '../../../../../components/circularProgress/NsLoadingProgress';
import { PrepareFilmFileService } from '../../../../../services/order/ProfessionalOrderService';
import { GetFilePublicUrlService } from '../../../../../services/order/OrderService';
import { GetImageService } from '../../../../../services/common/CommonService';

class PoModalFilmFile extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            CommonHelper.createStickyButtonItem("common.save", 'ns-icon-save', this.confirmFileClicked, 'primary masterFileModal-tour-save'),
            CommonHelper.createStickyButtonItem("common.remove", 'ns-icon-delete', this.removeAndSaveClicked, 'secondary'),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.handleAlertModal, 'primary-outline')
        ];
        this.headerLabel = props.isFront ? getTranslatedRawText('regularOrder.common.frontSide')
            : getTranslatedRawText('regularOrder.common.backSide');

        this.mainBox = React.createRef();
        this.trimZone = OrderHelper.getTrimZone(props.routeId);
        this.state = {
            messageClassModalItems: [
                { messageText: getTranslatedRawText('common.messages.discardNewFileMessage'), messageType: Enumerations.messageType.question },
                { messageText: CommonHelper.stringFormat(getTranslatedRawText('professionalOrder.messages.areYouSureQuestion'), ''), messageType: Enumerations.messageType.question }
            ],

            selectedFileInfo: '',
            imgContent: '',
            imgContentActions: [],
            imgResizeActionsStack: [],
            masterImageInfo: props.frontSideMasterFileInfo,
            alignType: null,
            isShowDeleteMessageModal: false,
            isAlertModalOpen: false,
            getFileInformationDate: null,
            changeFileDate: null,
            isLoading: false,
            isChangedPosition: false,
            resultMessageClass: (<></>)
        };
    }

    componentDidMount = () => this.setState({ masterImageInfo: this.props.frontSideMasterFileInfo });

    removeAndSaveClicked = () => this.setState({ isShowDeleteMessageModal: true });

    deleteFilm = (confirm) => !!confirm
        ? this.setState({ isShowDeleteMessageModal: false, selectedFileInfo: '', isLoading: true },
            () => this.saveFileInStorage())
        : this.setState({ isShowDeleteMessageModal: false });

    changeFileClicked = () => this.setState({ changeFileDate: new Date() });

    closeClicked = () => CommonHelper.safeFunctionCall(this.props.closeFilmFileModalClicked);

    handleAlertModal = () => {
        const { imgContentActions, imgResizeActionsStack } = this.state;
        if (!isEmpty(imgContentActions) || !isEmpty(imgResizeActionsStack) || this.state.isChangedPosition)
            this.setState({ isAlertModalOpen: true });
        else
            this.closeClicked();
    };

    closeAlertClicked = (confirm) => {
        this.setState({ isAlertModalOpen: false });
        !!confirm && this.closeClicked();
    }

    confirmFileClicked = () => {
        const { selectedFileInfo, imgContentActions, imgResizeActionsStack } = this.state;
        let storageFileId = this.props.isFront
            ? OrderHelper.getFiles(this.props.routeId)[this.props.currentFileKey].frontSideFileInfo?.id
            : OrderHelper.getFiles(this.props.routeId)[this.props.currentFileKey].backSideFileInfo?.id;
        if (isEmpty(imgContentActions) && isEmpty(imgResizeActionsStack) && selectedFileInfo.id === storageFileId) {
            this.saveFileInStorage();
        } else {
            this.changeIsLoadingState(true);
            this.prepareFilmFile();
        }
    }

    prepareFilmFile = () => {
        CommonHelper.safeFunctionCall(this.changeIsLoadingState, true);
        if (!isEmpty(this.state.selectedFileInfo)) {
            PrepareFilmFileService(this.state.selectedFileInfo, this.props.isFront, this.props.routeId, [...this.state.imgContentActions, ...this.state.imgResizeActionsStack], this.prepareFilmFileCallback)
        }
    }

    prepareFilmFileCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            resultData.top = this.state.selectedFileInfo.top;
            resultData.left = this.state.selectedFileInfo.left;
            this.setState({ selectedFileInfo: resultData },
                () => {
                    GetFilePublicUrlService(resultData.id, this.getFilePublicUrlCallback);
                });
        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });

    }

    getFilePublicUrlCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            let selectedFileInfo = { ...this.state.selectedFileInfo };
            selectedFileInfo.filePublicUrl = resultData;
            selectedFileInfo.resolution = resultData.resolution;
            this.setState({ selectedFileInfo },
                GetImageService(resultData.draft, this.getDraftCallback));
        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    getDraftCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            let selectedFileInfo = { ...this.state.selectedFileInfo };
            selectedFileInfo.draft = resultData;
            this.setState({ selectedFileInfo },
                GetImageService(selectedFileInfo.filePublicUrl.thumbnail, this.getThumbnailCallback));
        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    getThumbnailCallback = (resultData, result, messageClassModal) => {
        CommonHelper.safeFunctionCall(this.props.changeIsLoadingState, false);
        if (!result?.data?.hasError) {
            let selectedFileInfo = { ...this.state.selectedFileInfo };
            selectedFileInfo.thumbnail = resultData;
            this.setState({ selectedFileInfo },
                () => this.saveFileInStorage());
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    saveFileInStorage = () => {
        let film = {};
        if (!!this.state.selectedFileInfo) {
            film.id = this.state.selectedFileInfo.id;
            film.name = this.state.selectedFileInfo.name;
            film.top = this.state.selectedFileInfo.top;
            film.left = this.state.selectedFileInfo.left;
        }
        OrderHelper.updateFilesInStorages(this.props.routeId, this.props.currentFileKey, film, [this.state.selectedFileInfo], this.props.isFront, this.updateFilesCallback);
    }

    updateFilesCallback = () => {
        this.closeClicked();
    }

    alignFilmButtonClicked = (alignType) => this.setState({ alignType });

    setTopAndLeft = (top, left) => {
        let selectedFileInfo = { ...this.state.selectedFileInfo };
        selectedFileInfo.top = top;
        selectedFileInfo.left = left;
        this.setState({ selectedFileInfo, alignType: null, isChangedPosition: true });
    }

    setSelectedFileInfo = (selectedFileInfo, availableSizes) => {
        this.setState({ selectedFileInfo, imgContent: selectedFileInfo?.draft, availableSizes });
    }

    setEditFileActionChanges = (selectedFileInfo, imgContent, imgContentActions, imgResizeActionsStack) => {
        !!imgContent && this.setState({ imgContent });
        this.setState({ selectedFileInfo, imgContentActions, imgResizeActionsStack });
    }

    undoAllChangesClicked = () => this.setState({ imgResizeActionsStack: [], imgContentActions: [], getFileInformationDate: new Date() });

    changeIsLoadingState = (state) => this.setState({ isLoading: state });

    render() {
        const { masterImageInfo, selectedFileInfo, isAlertModalOpen, imgContent, getFileInformationDate,
            isLoading, changeFileDate } = this.state;

        return (
            <NsModal showModal={this.props.showModal}
                closeModal={this.handleAlertModal}
                dialogClassName="modal-choose-printing-files"
                dialogTitle={CommonHelper.stringFormat('{0} ({1})', this.props.title, this.headerLabel)}
                maxWidth="md"
                primaryActionButton={this.confirmFileClicked}
                actions={<NsStickyButton stickyButtons={this.mainButtons} isLoading={isLoading} />}>

                <Row className="justify-content-center">
                    <NsLoadingProgress visible={isLoading} />
                    <PoBaseFileSelectorHelper selectedFileInfo={this.props.selectedFileInfo}
                        storageFileInfo={this.props.storageFileInfo}
                        currentFileKey={this.props.currentFileKey}
                        isFront={this.props.isFront}
                        changeFileDate={changeFileDate}
                        getFileInformationDate={getFileInformationDate}
                        changeIsLoadingState={this.changeIsLoadingState}
                        setSelectedFileInfo={this.setSelectedFileInfo}
                        closeParentModal={this.closeClicked}
                        routeId={this.props.routeId} />
                    <Col md={7} >
                        <div ref={this.mainBox}
                            id='draggable-image position-absolute fixed-top fixed-bottom'
                            className={clsx("w-100 border", 'position-relative')}>
                            <PoBaseImageInModal masterImageInfo={masterImageInfo}
                                fileName={selectedFileInfo?.name}
                                filmImageInfo={selectedFileInfo}
                                alignType={this.state.alignType}
                                trimZone={this.trimZone}
                                setTopAndLeft={this.setTopAndLeft}
                                mainBox={this.mainBox}
                                currentFileKey={this.props.currentFileKey}
                                changeFileClicked={this.changeFileClicked} />
                        </div>

                    </Col>
                    <Col md={5} className='ps-md-0'>
                        <PoBaseGuidanceInModal routeId={this.props.routeId}
                            headerLabel={this.headerLabel}
                            title={this.props.title}
                            currentFileKey={this.props.currentFileKey} />

                        <PoBaseEditFileActionInModal hasPositioning={true}
                            hasResizeButton={true}
                            imgContent={imgContent}
                            masterImageWidth={masterImageInfo?.width}
                            masterImageHeight={masterImageInfo?.height}
                            selectedFileInfo={selectedFileInfo}
                            currentFileKey={this.props.currentFileKey}
                            alignFilm={this.alignFilmButtonClicked}
                            changeIsLoadingState={this.changeIsLoadingState}
                            undoAllChangesClicked={this.undoAllChangesClicked}
                            setEditFileActionChanges={this.setEditFileActionChanges} />

                    </Col>
                </Row>

                {this.state.isShowDeleteMessageModal &&
                    <NsMessageClassModal show={this.state.isShowDeleteMessageModal}
                        onClose={(confirm) => this.deleteFilm(confirm)}
                        messageItems={[this.state.messageClassModalItems[1]]} />
                }

                {isAlertModalOpen &&
                    <NsAlertModal isOpen={isAlertModalOpen}
                        cancelClicked={() => this.closeAlertClicked(false)}
                        confirmClicked={() => this.closeAlertClicked(true)} >
                        {getTranslatedRawText('regularOrder.modal.closeServicesModalAlert')}
                    </NsAlertModal>
                }

                {this.state.resultMessageClass}

            </NsModal >
        );
    }
}

export default PoModalFilmFile;