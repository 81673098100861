import React, { useEffect, useState } from 'react';

import NsButton from '../../../../../../components/buttons/NsButton';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import NsModal from '../../../../../../components/modals/NsModal';
import Enumerations from '../../../../../../configs/Enumerations';
import { GetUsersAcceptedAgreementService } from '../../../../../../services/adminPanel/AdminAgreementService';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import ApBaseUserInfoModal from '../../../../managements/users/bases/ApBaseUserInfoModal';
import ApAgreementUserAcceptedSearchSection from './ApAgreementUserAcceptedSearchSection';
import { hasAccess } from '../../../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../../../configs/UserClaimEnumerations';

const ApAgreementUsersAccepted = ({ agreementId, closeModal }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({ id: agreementId });
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showCustomerDetailModal, setShowCustomerDetailModal] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);

    useEffect(() => {
        setCallServiceDate(new Date());
        setIsLoading(true);
    }, [searchParams]);

    const setSearchData = (data) => {
        setSearchParams(data);
    }

    const customerDetailClicked = (userId) => {
        setSelectedUserId(userId);
        setShowCustomerDetailModal(true);
    }

    const columns = [
        hasAccess([UserClaimEnumerations.getApCustomerById]) && {
            field: 'action', headerName: getTranslatedRawText('adminPanel.common.action'),
            cellRenderer: "customRowRenderer",
            width: 80,
            cellRendererParams: {
                customElementRender: (user) => {
                    return <NsButton className='secondary-text '
                        startIcon='ns-icon-invoice-details'
                        onClick={() => customerDetailClicked(user.userId)} />
                }
            }
        },
        {
            field: 'fullName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 175,
        },
        {
            field: 'phoneNumber',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
            dataType: Enumerations.gridType.number,
            width: 150,
        },
        {
            field: 'acceptTime',
            headerName: getTranslatedRawText('adminPanel.singlePage.acceptedTime'),
            dataType: Enumerations.gridType.date,
            width: 150,
        }
    ]

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsModal maxWidth="sm"
            headerClassName='px-3'
            dialogContentClassName='p-3'
            dialogTitle={getTranslatedRawText('adminPanel.singlePage.usersAccepted')}
            showModal={true}
            noSpace={true}
            closeModal={closeModal}>

            <ApAgreementUserAcceptedSearchSection agreementId={agreementId}
                isLoading={isLoading}
                setSearchData={setSearchData} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetUsersAcceptedAgreementService}
                parameters={searchParams}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showCustomerDetailModal &&
                <ApBaseUserInfoModal showModal={true}
                    selectedUserId={selectedUserId}
                    closeModal={() => setShowCustomerDetailModal(false)} />
            }

        </NsModal>
    );
};

export default ApAgreementUsersAccepted;