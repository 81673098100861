import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { PostRequest, GetRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';

const GetFolderDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.getFolderDetail), parameters, callback);
}

const CreateFolderService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.createFolder), data, callback);
}

const DeleteFolderService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.deleteFolder), data, callback);
}

const RenameFolderService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.fileManager, Links.api.fileManager.renameFolder), data, callback);
}

export {
    GetFolderDetailService,
    CreateFolderService,
    RenameFolderService,
    DeleteFolderService,
}