import clsx from 'clsx';
import React from 'react';

import '../../../../../assets/sass/components/images/NsImages.scss';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ImageHelper from '../../../../../utilities/ImageHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';

class PoBaseMasterFileView extends React.Component {

    constructor(props) {
        super(props);
        this.mainBox = React.createRef();
        this.state = {
            ratio: null,
        };
    }

    componentDidMount = () => this.calculateRatio(this.props);

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.imgSrc !== this.props.imgSrc)
            this.calculateRatio(newProps);
    }

    calculateRatio = (props) => {
        const { imgWidth, imgHeight, resolution } = props;
        let pixelImgWidth = ImageHelper.convertMillimeterToPixel(imgWidth, resolution);
        let pixelImgHeight = ImageHelper.convertMillimeterToPixel(imgHeight, resolution);
        this.setState({
            ratio: ImageHelper.calculateRatio(this.mainBox.current?.clientWidth, this.mainBox.current?.clientHeight, pixelImgWidth, pixelImgHeight),
        },
            // () => CommonHelper.safeFunctionCall(this.props.setRatio, this.state.ratio)
        );
    }

    render() {
        const { imgSrc, imgWidth, imgHeight, resolution, wrapperClassName, productDesignTemplateUrl, trimZone, isSharpProductDesignTemplate } = this.props;
        const hasTrimZone = !isEmpty(trimZone);
        const { ratio } = this.state;

        return (

            <div className={clsx("ns-image-order-master-file d-flex flex-column justify-content-center align-items-center background-element-color overflow-auto")}
                style={{ 'width': 'w-100', "height": CommonHelper.isMobileDevice() ? '40vh' : '60vh' }} ref={this.mainBox} onClick={this.onClick}>

                {!!imgSrc && <div className={clsx('', !!hasTrimZone && 'ns-image-order-master-file__img-safe-zone', wrapperClassName)}
                    style={{
                        "--border-top": `${trimZone?.pixelTop * ratio}px`,
                        "--border-left": `${trimZone?.pixelLeft * ratio}px`,
                        "--border-right": `${trimZone?.pixelRight * ratio}px`,
                        "--border-bottom": `${trimZone?.pixelBottom * ratio}px`,
                    }}>

                    <img src={imgSrc} alt={getTranslatedRawText("components.imagesAlt.uploadedImage")}
                        className='border'
                        width={ImageHelper.convertMillimeterToPixel(imgWidth, resolution) * ratio}
                        height={ImageHelper.convertMillimeterToPixel(imgHeight, resolution) * ratio}
                        style={{ opacity: isSharpProductDesignTemplate ? .5 : 1 }} />

                    {!!productDesignTemplateUrl && <img src={CommonHelper.createImageUrl(productDesignTemplateUrl)}
                        className='ns-image-order-master-file__template-design'
                        alt={getTranslatedRawText("components.imagesAlt.productDesignTemplate")} />
                    }

                </div>
                }
            </div>
        );
    }
}

export default PoBaseMasterFileView;