export const checkIsValidEnumKey = (enumObject, key) => {
	
	for (let enumKey in enumObject) {
	    if(enumKey === key)
			return true;
	}
	return false;
}

export const getEnumKeyFromValue = (enumObject, value) => {
	
	for (let enumKey in enumObject) {
		
	    if(enumObject[enumKey] === value)
			return enumKey;
	}
	
	return null;
}

export const checkIsValidEnumKeyFromValue = (enumObject, value) => {

	return checkIsValidEnumKey(enumObject, getEnumKeyFromValue(enumObject, value));
}