import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import AgreementPolicyModal from '../../layouts/main/headers/modals/signIn/AgreementPolicyModal';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { currentUserActionCreators } from '../../../redux/reducers/CurrentUserReducer';
import { getEnumKeyFromValue } from '../../../utilities/EnumerationHelper';
import { SignInService } from '../../../services/accounts/AccountService';
import NsPasswordInput from '../../../components/inputs/NsPasswordInput';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsMobileInput from '../../../components/inputs/NsMobileInput';
import NsCaptcha from '../../../components/recaptcha/NsCaptcha';
import NsCheckbox from '../../../components/inputs/NsCheckbox';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';

class BaseSigninContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countryId: '',
            mobile: '',
            isValidMobile: false,
            password: '',
            isValidPassword: false,
            captchaCode: '',
            isValidCaptchaCode: false,
            captchaId: null,
            refreshCaptchaDate: null,
            showValidationDate: null,
            showValidationDateCaptcha: null,
            showAgreementModal: false,
            isAcceptedAgreement: false,
            isLoading: false,
            isPersistent: CurrentUserHelper.getIsPersistent(),
            resultMessageClass: (<></>),
            captchaMessage: null,
        };
    }

    componentDidMount = () => !!CurrentUserHelper.getPersistentMobile() && this.mobileChanged(CurrentUserHelper.getPersistentMobile(), true)

    componentDidUpdate = (prevProps) => {
        if (prevProps.signInDate !== this.props.signInDate)
            this.signInClicked();
    }

    keyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter) {
            event.preventDefault();
            this.signInClicked();
        }
    }

    mobileChanged = (value, isValid, countryId) => {
        this.setState({
            mobile: value,
            isValidMobile: isValid,
            countryId: countryId,
            refreshCaptchaDate: null
        });
        if (isValid)
            CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.persistentMobile), value);
    }

    passwordChanged = (value, isValid) => {
        this.setState({
            password: value,
            isValidPassword: isValid
        });
    }

    setIsPersistent = (value) => {
        this.setState({ isPersistent: value });
    }

    recaptchaChanged = (captchaCode, isValidCaptchaCode, captchaId) => {
        this.setState({ captchaCode, isValidCaptchaCode, captchaId });
    }

    signInClicked = () => {
        if (!this.state.isValidPassword || !this.state.isValidMobile) {
            this.setState({ showValidationDate: new Date() });
            return false;
        }
        if (!!this.state.refreshCaptchaDate && !this.state.isValidCaptchaCode) {
            this.setState({ showValidationDateCaptcha: new Date() });
            return false;
        }
        this.setState({ showValidationDate: new Date() });
        this.toggleLoading(true);
        let data = {
            mobile: this.state.mobile?.replace(/ /g, '').substr(1),
            password: this.state.password,
            isPersistent: this.state.isPersistent,
            isAcceptedAgreement: this.state.isAcceptedAgreement
        };
        if (!!this.state.refreshCaptchaDate) {
            data = {
                ...data,
                captchaCode: this.state.captchaCode,
                captchaId: this.state.captchaId,
            }
        }
        SignInService(data, this.signInCallback);
    }

    signInCallback = (resultData, result, messageClassModal) => {
        this.toggleLoading(false);
        if (!result?.data?.hasError) {
            this.props.setCurrentUser({
                userInfo: resultData,
                isPersistent: this.state.isPersistent,
                persistentMobile: this.state.mobile,
            });
            CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.customerAgency),
                { customerMustHaveAgency: resultData?.customerMustHaveAgency, customerAgencyId: resultData?.userSelectedAgencyId });
            !resultData?.showTips && CurrentUserHelper.addAllToursInStorage();
            CommonHelper.safeFunctionCall(this.props.useInModal
                ? this.props.closeModal
                : this.props.handleBackUrl);
        } else {
            if (!isEmpty(resultData) && !resultData?.isAcceptedAgreement)
                this.setState({ showAgreementModal: true, isAcceptedAgreement: resultData?.isAcceptedAgreement });
            this.setState({
                resultMessageClass: messageClassModal,
                refreshCaptchaDate: result.data.messageItems.some(item => item?.messageType === Enumerations.messageType.needCaptcha)
                    ? new Date()
                    : null,
                captchaMessage: result.data.messageItems.find(item => item?.messageType === Enumerations.messageType.needCaptcha)?.messageText
            });
        }
    }

    toggleLoading = (isLoading) => {
        this.setState({ isLoading });
        this.props.useInModal && CommonHelper.safeFunctionCall(this.props.toggleLoading, isLoading);
    }

    closeModal = () => this.setState({ showAgreementModal: false, isAcceptedAgreement: true }, this.signInClicked);

    render() {
        const { useInModal } = this.props;
        const { captchaMessage, mobile, showValidationDate, showValidationDateCaptcha, refreshCaptchaDate,
            isLoading, isPersistent, showAgreementModal, resultMessageClass } = this.state;

        return (
            <Form onKeyDown={this.keyDown} className={useInModal ? 'p-2' : ''}>
                <div className="mt-4 mb-3 ">
                    <input hidden name="username" value={mobile} readOnly />
                    <NsMobileInput
                        mobileChanged={this.mobileChanged}
                        mobile={mobile}
                        showValidationDate={showValidationDate} />
                </div>
                <div className="mb-3">
                    <NsPasswordInput label={getTranslatedRawText("components.passwordInput.password")}
                        passwordChanged={this.passwordChanged}
                        className="w-100"
                        showValidationDate={showValidationDate} />
                </div>
                {!!refreshCaptchaDate &&
                    <div className="mb-3">
                        <NsCaptcha refreshCaptchaDate={refreshCaptchaDate}
                            recaptchaChanged={this.recaptchaChanged}
                            captchaMessage={captchaMessage}
                            showValidationDateCaptcha={showValidationDateCaptcha} />
                    </div>
                }
                <div>
                    <NsCheckbox label={getTranslatedRawText("account.signIn.rememberMe")}
                        value={isPersistent}
                        onChange={this.setIsPersistent} />
                </div>
                <div className="my-2 font-size-14">
                    <Link to={getUrlWithCurrentCulture(Links.view.account.forgotPassword, Links.view.account.forgotPassword.mobile)} className=" py-1">
                        {getTranslatedRawText("account.signIn.forgotPassword")}
                    </Link>
                </div>
                {useInModal || CommonHelper.isMobileDevice() &&
                    <div className="mb-3 font-size-14">
                        {getTranslatedRawText("account.signIn.doYouHaveAccount")}
                        <Link to={getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.mobile)} className=" p-1">
                            {getTranslatedRawText("account.signIn.signupNow")}
                        </Link>
                    </div>
                }
                {!useInModal &&
                    <div className="d-flex justify-content-center mt-5 mb-3">
                        <NsButton className="primary"
                            onClick={this.signInClicked}
                            startIcon='ns-icon-sign-in'
                            loading={isLoading}
                            label="account.common.login" />
                    </div>
                }
                {showAgreementModal &&
                    <AgreementPolicyModal closeModal={this.closeModal} />
                }
                {resultMessageClass}
            </Form>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(currentUserActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(BaseSigninContent);