import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { DeleteRequest, GetRequest, PatchRequest, PostRequest } from '../bases/BaseService';

const GetNewsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApNews), parameter, callback);
}

const GetNewsByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApNewsById), id), {}, callback);
}

const EditNewsService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApNews), id), data, callback, showSuccessResponseMessage);
}

const DeleteNewsService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApNews), id), {}, callback, showSuccessResponseMessage);
}

const PostNewsService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApNews), data, callback, showSuccessResponseMessage);
}

const GetNewsCategoriesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApNewsCategories), parameter, callback);
}

const GetBasicNewsCategoriesService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApBasicNewsCategories), {}, callback);
}

const GetNewsCategoryByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApNewsCategoryById), id), {}, callback);
}

const EditNewsCategoryService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApNewsCategory), id), data, callback, showSuccessResponseMessage);
}

const DeleteNewsCategoryService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApNewsCategory), id), {}, callback, showSuccessResponseMessage);
}

const PostNewsCategoryService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApNewsCategory), data, callback, showSuccessResponseMessage);
}

export {
    GetNewsService,
    GetNewsByIdService,
    EditNewsService,
    DeleteNewsService,
    PostNewsService,
    GetBasicNewsCategoriesService,
    GetNewsCategoriesService,
    GetNewsCategoryByIdService,
    EditNewsCategoryService,
    DeleteNewsCategoryService,
    PostNewsCategoryService,

}