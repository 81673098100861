import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../assets/sass/views/fileManager/FileManager.scss';

import NsLoadingProgress from '../../components/circularProgress/NsLoadingProgress';
import NsTour from '../../components/tour/NsTour';
import Enumerations from '../../configs/Enumerations';
import { fileManagerActionCreators } from '../../redux/reducers/FileManagerReducer';
import { GetTreeViewFoldersService } from '../../services/fileManager/ContentManagementService';
import CommonHelper from '../../utilities/CommonHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';
import FileManagerTour from '../bases/tours/FileManagerTour';
import { ConvertArrayToTreeList } from './bases/CreateTreeViewJson';
import FmHeader from './headers/FmHeader';
import FmMainSection from './mainSections/FmMainSection';
import FmSidbar from './sidebars/FmSidbar';

class FileManager extends React.Component {

    constructor(props) {
        super(props);
        this.showTour = !this.props.isUsedInPo && !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.fileManager);
        this.tourSteps = FileManagerTour.getFileManagerTour();
        this.state = {
            isLoading: true,
            treeViewFolders: [],
            treeViewRefreshDate: null,
            isLoading: false,
            resultMessageClass: (<React.Fragment></React.Fragment>),
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.treeViewRefreshDate !== prevState.treeViewRefreshDate)
            return ({
                treeViewRefreshDate: nextProps.treeViewRefreshDate,
                isLoading: true
            });
        return null;
    }

    GetTreeViewFoldersCallback = (resultData, result, messageClassModal) => {

        if (result.data && !result?.data?.hasError)
            this.setState({
                treeViewFolders: ConvertArrayToTreeList(resultData),
                isLoading: false,
            });

        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.treeViewRefreshDate !== this.state.treeViewRefreshDate) {
            this.props.refreshMainSectionItems()
            GetTreeViewFoldersService(this.GetTreeViewFoldersCallback);
        }
    }

    componentDidMount = () => {
        this.props.refreshMainSectionItems()
        GetTreeViewFoldersService(this.GetTreeViewFoldersCallback);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        const { isModal, isUsedInPo, toggleProfessionalOrderTour, hideCheckbox } = this.props;
        let isMobileDevice = isUsedInPo ? isUsedInPo : CommonHelper.isMobileDevice()

        return (
            <Row className="file-manager m-0 no-select">
                <NsLoadingProgress visible={this.state.isLoading} />

                {!isUsedInPo &&
                    <FmHeader treeViewFolders={this.state.treeViewFolders} />
                }

                {!isModal &&
                    <Col lg={2} className="p-0 pe-2 d-none d-lg-flex">
                        <FmSidbar treeViewFolders={this.state.treeViewFolders} />
                    </Col>
                }

                <Col lg={isModal ? 12 : 10} className={clsx("px-0", !isUsedInPo && 'mt-2')}>
                    <FmMainSection treeViewFolders={this.state.treeViewFolders}
                        isUsedInPo={isUsedInPo}
                        hideCheckbox={hideCheckbox}
                        toggleProfessionalOrderTour={toggleProfessionalOrderTour}
                        isMobileDevice={isMobileDevice} />
                </Col>

                {this.showTour && <NsTour steps={this.tourSteps}
                    id={Enumerations.tourGuide.fileManager} />
                }

                {this.state.resultMessageClass}
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        treeViewRefreshDate: state.fileManager.treeViewRefreshDate,
    }
}

const mapDispatchToPops = (dispatch) => {
    return bindActionCreators(fileManagerActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToPops)(FileManager);