import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsRadioButton from '../../../../../components/buttons/NsRadioButton';
import NsOptionBox from '../../../../../components/labels/NsOptionBox';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import ExtendedServiceWrapper from './ExtendedServiceWrapper';

class ExtendedServiceGripperEdgeType extends React.Component {

    constructor(props) {
        super(props);
        this.tourId = Enumerations.tourGuide.gripperEdge;
        this.tourSteps = RegularOrderTours.getGripperEdgeTour();
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = {
            showTour: !CurrentUserHelper.isTourSeen(this.tourId),
            selectedHandleType: {},
            refreshExtendedServiceSummaryDate: null,
            handleTypeOptions: this.getHandleTypeOptions(),
        }
    }

    getHandleTypeOptions = () => {
        if (OrderHelper.isValidOrder(this.routeId)) {
            let handleTypePrice = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.handleType)?.price;
            let handleTypeOptions = ConstantsWithTranslation.getBagHandleOptions(handleTypePrice)
            return handleTypeOptions;
        }
        return [];
    }

    componentDidMount = () => {
        if (OrderHelper.isValidOrder(this.routeId)) {
            let service = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.handleType);
            if (!!service?.selectedItem)
                this.setState({ selectedHandleType: service?.selectedItem });
            else
                this.handleTypeOptionClicked(this.state.handleTypeOptions[0]?.id, true);
        }
    }

    handleTypeOptionClicked = (id, dontAddToSeenTours) => {
        let selectedHandleType = this.state.handleTypeOptions.find(option => option.id === Number(id));
        OrderHelper.updateExtendedService(this.routeId, Enumerations.extendedServices.handleType, selectedHandleType);
        this.setState({ selectedHandleType, refreshExtendedServiceSummaryDate: new Date(), showTour: !CurrentUserHelper.isTourSeen(this.tourId) });
        !dontAddToSeenTours && !CurrentUserHelper.isTourSeen(this.tourId) && CurrentUserHelper.addToSeenTours(this.tourId);
    }

    render() {
        const { selectedHandleType, handleTypeOptions, refreshExtendedServiceSummaryDate, showTour } = this.state;
        return (
            <ExtendedServiceWrapper otherProps={this.props}
                routeId={this.routeId}
                isSelectedRequirements={!isEmpty(selectedHandleType)}
                refreshExtendedServiceSummaryDate={refreshExtendedServiceSummaryDate}
                currentExtendedService={Enumerations.extendedServices.handleType}>

                <Row className='extendedService-tour-handeType g-0 px-3'>
                    <HandleTypeOptions handleTypeOptions={handleTypeOptions}
                        handleTypeOptionClicked={this.handleTypeOptionClicked}
                        selectedHandleType={selectedHandleType} />

                </Row>

                {/* {showTour && <NsTour steps={this.tourSteps}
                    id={this.tourId} />} */}
            </ExtendedServiceWrapper>
        );
    }
}

function HandleTypeOptions(props) {
    if (props.handleTypeOptions) {
        return props.handleTypeOptions.map((option) => {
            return (
                <Col xs={10} sm={4} key={option.id} className='mb-2' onClick={() => props.handleTypeOptionClicked(option.id)}>
                    <NsOptionBox isSelected={props.selectedHandleType.id === option.id}>

                        <img src={option.img}
                            className='pt-2'
                            width='60px'
                            height='50px'
                            alt={getTranslatedRawText("components.imagesAlt.extendedService")} />
                        <div>
                            <div className="font-size-12 light-text-divor fw-bold mt-2">
                                <NsRadioButton option={option}
                                    checked={props.selectedHandleType.id === option.id} />
                            </div>
                            <div className="font-size-12 d-none d-md-block ms-3 mb-2">
                                {option.description}
                            </div>
                            <div className="font-size-12 light-text-color mt-auto ms-3">
                                {CommonHelper.currencyFormatByDefaultConfig(option.price)}
                            </div>

                        </div>
                    </NsOptionBox>
                </Col>
            );
        });
    }
}

export default ExtendedServiceGripperEdgeType