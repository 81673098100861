import React, { Component } from 'react';
import clsx from 'clsx';

import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsTypography from '../../../../components/text/NsTypography';
import Links from '../../../../configs/links/AllLinks';
import { getTranslatedRawText, getUrlWithCurrentCulture, removeQueryFromURL } from '../../../../utilities/CultureHelper';
import SinglePage from '../../SinglePage';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import { GetCmsSinglePageService } from '../../../../services/singlePages/CmsService';
import Enumerations from '../../../../configs/Enumerations';
import NsHtmlToReact from '../../../../components/htmlToReact/NsHtmlToReact';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';

class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            privacyPolicy: {},
            resultMessageClass: '',
            isLoading: false,
        }
    }

    componentDidMount = () => { this.getContent(); }

    getContent = () => {
        this.setState({ isLoading: true });
        GetCmsSinglePageService(Enumerations.staticPageType.privacyPolicy, this.GetPageContentCallback);
    }

    GetPageContentCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ privacyPolicy: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    tableContent = () => {
        const titles = this.state.privacyPolicy.staticPages;
        const tableTitles = !isEmpty(titles) && titles.map((item, index) =>
            <a key={index} href={`${removeQueryFromURL(getUrlWithCurrentCulture(Links, Links.privacyPolicy))}#${index}`} className='table-content d-block pb-1'>
                {item.isTitleVisible && item.title}
            </a>
        );
        return tableTitles;
    }

    render() {
        const { privacyPolicy, resultMessageClass, isLoading } = this.state;
        const isTitleVisible = !isEmpty(privacyPolicy) && privacyPolicy.staticPages.some(item => item.isTitleVisible === true);
        const summary = privacyPolicy?.staticPageType?.summary;
        return (
            <SinglePage >

                <NsPageMainContentWrapper header={getTranslatedRawText('singlePage.privacyPolicy.privacyPolicy')}
                    icon='ns-icon-privacy-policy h1' className='mb-3'>
                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                        : <>
                            {summary && <p>{summary}</p>}

                            {isTitleVisible && <div className='d-inline-block background-element-color p-3 rounded'>
                                <NsTypography variant='inherit' className='d-inline-block section-title mb-2'>
                                    {getTranslatedRawText('singlePage.privacyPolicy.tableOfContent')}
                                </NsTypography>
                                {this.tableContent()}
                            </div>}

                            <PrivacyPolicyContent privacyPolicy={privacyPolicy}
                                isTitleVisible={isTitleVisible} />
                        </>
                    }
                </NsPageMainContentWrapper>
                {resultMessageClass}
            </SinglePage >
        );
    }
}

export function PrivacyPolicyContent(props) {
    const { privacyPolicy, isTitleVisible } = props;
    return !isEmpty(privacyPolicy) && privacyPolicy.staticPages.map((item, index) =>
        <div key={item.id} id={index} className={clsx(isTitleVisible && "pt-5")}>
            <NsTypography key={index} variant='inherit' className='d-block section-title'>
                {item.isTitleVisible && item.title}
            </NsTypography>
            <div className='mt-3'>
                <NsHtmlToReact>
                    {item.mainContent}
                </NsHtmlToReact>
            </div>
        </div>
    )
}

export default PrivacyPolicy;