import React, { useEffect, useState } from 'react';
import NsDataGrid from '../../../../components/grids/NsDataGrid';
import NsModal from '../../../../components/modals/NsModal';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { GetCmsLogByIdService } from '../../../../services/adminPanel/AdminExceptionService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBaseMapActionButtons from '../../bases/ApBaseMapActionButtons';
import ApBaseUserInfoModal from '../../managements/users/bases/ApBaseUserInfoModal';
import ApLogsAllXmlModal from './ApLogsAllXmlModal';
import NsBasicInfoLabelBox from '../../../../components/labels/NsBasicInfoLabelBox';
import { getCmsLogBasicInfos } from '../../../../utilities/BasicInfoHelper';

const ApLogDetailModal = ({ selectedLogId, closeModal }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [isShowUserInfoModal, setIsShowUserInfoModal] = useState(false);
    const [isShowLogDetail, setIsShowLogDetail] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    const columns = [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => showLogDetailModal(id),
            }
        },
        {
            field: 'type',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.type'),
            width: 200
        },
        {
            field: 'source',
            headerName: getTranslatedRawText('adminPanel.systemMonitoring.source'),
            width: 200
        },
        {
            field: 'message',
            headerName: getTranslatedRawText('adminPanel.systemMonitoring.message'),
            width: 380,
        },
    ]

    const showLogDetailModal = (id) => {
        setIsShowLogDetail(true);
        setSelectedId(id);
    }

    useEffect(() => {
        getCmsLogDetail();
    }, [])

    const getCmsLogDetail = () => {
        if (!isEmpty(selectedLogId)) {
            setIsLoading(true);
            GetCmsLogByIdService(selectedLogId, getCmsLogDetailCallback);
        }
    }

    const getCmsLogDetailCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(resultData);
        } else setMessageClassModal(resultMessageClass);
    }

    const showUserInfoModal = () => setIsShowUserInfoModal(!isShowUserInfoModal);

    const headerButtons = [
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: showUserInfoModal, accessIds: [UserClaimEnumerations.getApCustomerById] },
    ]

    return (
        <div>
            <NsModal maxWidth="md"
                dialogTitle={getTranslatedRawText('adminPanel.systemMonitoring.logsDetail')}
                showModal={true}
                noSpace={true}
                closeModal={closeModal}>

                <ApBaseMapActionButtons actionButtons={headerButtons}
                    isLoading={isLoading} />

                <div className='background-color px-3 py-2'>
                    <NsBasicInfoLabelBox basicInfo={getCmsLogBasicInfos(data)}
                        hasAccessInfo={true}
                        isLoading={isLoading} />

                    <div className='mt-3'>
                        <NsDataGrid key={data?.abstractCustomLogExceptions?.length}
                            isLoading={isLoading}
                            columnDefs={columns}
                            noFooter={true}
                            rows={data?.abstractCustomLogExceptions || []} />
                    </div>
                </div>

                {isShowUserInfoModal &&
                    <ApBaseUserInfoModal showModal={true}
                        selectedUserId={data?.userId}
                        closeModal={() => setIsShowUserInfoModal(false)} />
                }

                {isShowLogDetail &&
                    <ApLogsAllXmlModal selectedId={selectedId}
                        closeModal={() => setIsShowLogDetail(false)} />
                }

            </NsModal>
            {messageClassModal}
        </div>
    );
};

export default React.memo(ApLogDetailModal);