import React from 'react';

import NsDataGrid from '../../../../../../components/grids/NsDataGrid';
import { createStoreCartColumns } from '../bases/UpBaseStoreOrderHelper';
import UpStoreNotAppliedOrders from '../notApplied/UpStoreNotAppliedOrders';
import GridEnumerations from '../../../../../../configs/GridEnumerations';

class UpStoreCartOrders extends React.Component {

    columns = createStoreCartColumns(this.props.deleteRowClicked, this.props.refreshStoreProductGrid);

    render() {
        return (
            <>
                <div className='w-100'>
                    <NsDataGrid totalRows={this.props.totalRows}
                        rowPerPage={this.props.rowPerPage}
                        isLoading={this.props.isLoading}
                        columnDefs={this.columns}
                        rows={this.props.rows}
                        currentPage={this.props.currentPage}
                        paginationMode="server"
                        selectedRowIds={this.props.selectedRowIds}
                        handlePagination={this.props.handlePagination}
                        handleRowPerPage={this.props.handleRowPerPage}
                        hasCheckbox={true}
                        gridId={GridEnumerations.siteGrids.cartStoreOrders}
                        hasCustomColumnOrder={true}
                        selectedRows={this.props.selectedRows} />
                </div>
                {!this.props.isModal && <div className='mt-3'>
                    <UpStoreNotAppliedOrders />
                </div>
                }
            </>
        );
    }
};

export default UpStoreCartOrders;