import React from 'react';
import PoBaseMasterFileView from './PoBaseMasterFileView';
import OBaseFilmImagePreview from '../../../bases/OBaseFilmImagePreview';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsSwitch from '../../../../../components/switch/NsSwitch';

class PoBaseImageInModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sharpness: false
        }
    }

    sharpDieCutLineClicked = () => this.setState({ sharpness: !this.state.sharpness });

    changeFileClicked = () => CommonHelper.safeFunctionCall(this.props.changeFileClicked);

    render() {
        const { imgContent, imgWidth, imgHeight, fileName, currentFileKey, masterImageInfo, filmImageInfo, alignType, mainBox, resolution, trimZone, productDesignTemplateUrl } = this.props;

        return (
            <div className="w-100 border">
                <div className='d-flex align-items-center border-bottom p-1'>
                    <i className='ns-icon-jpg-file h2' />
                    {/* <span className='font-size-12 w-25 '> */}
                    {/* <NsTextTruncate>{fileName}</NsTextTruncate> */}
                    <span className='font-size-12 w-50 text-truncate'>
                        {fileName}
                    </span>
                    {CommonHelper.isMobileDevice() && <NsButton wrapperClassName='ms-auto'
                        startIcon='ns-icon-change'
                        className='secondary'
                        onClick={this.changeFileClicked} />
                    }
                </div>
                <div>
                </div>
                {
                    currentFileKey === 0
                        ? <>
                            <div className='d-flex justify-content-end'>
                                <NsSwitch label='product.detail.sharpDieCutLine'
                                    checked={this.state.sharpness}
                                    disabled={!imgContent}
                                    onChange={this.sharpDieCutLineClicked} />
                            </div>
                            <div className="w-100 align-self-center border ">
                                <PoBaseMasterFileView className='border p-1'
                                    imgSrc={imgContent}
                                    productDesignTemplateUrl={productDesignTemplateUrl}
                                    trimZone={isEmpty(trimZone) ? null : trimZone}
                                    resolution={resolution}
                                    isSharpProductDesignTemplate={this.state.sharpness}

                                    imgWidth={imgWidth}
                                    imgHeight={imgHeight} />
                            </div>
                        </>
                        : <div className='not-rtl'>
                            <OBaseFilmImagePreview masterImageInfo={masterImageInfo}
                                filmImageInfo={filmImageInfo}
                                trimZone={isEmpty(trimZone) ? null : trimZone}
                                alignType={alignType}
                                setTopAndLeft={this.props.setTopAndLeft}
                                mainBox={mainBox} />
                        </div>
                }
            </div >)
    }
}

export default PoBaseImageInModal;