import React from "react";
import { Col, Row } from "react-bootstrap";

import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../utilities/CultureHelper";
import NsLabelLine from "../../../components/labels/NsLabelLine";
import CommonHelper from "../../../utilities/CommonHelper";
import NsImage from "../../../components/images/NsImage";
import Links from "../../../configs/links/AllLinks";
import ShoppingBagWrapper from "./ShoppingBagWrapper";
import GalleryDetailModal from "../../singlePages/mainSections/gallery/GalleryDetailModal";

//shoppingBag images
import shoppingBag_1 from '../../../assets/images/shoppingbag/ShoppingBag_1.jpg';
import shoppingBag_2 from '../../../assets/images/shoppingbag/ShoppingBag_2.jpg';
import shoppingBag_3 from '../../../assets/images/shoppingbag/ShoppingBag_3.jpg';
import shoppingBag_4 from '../../../assets/images/shoppingbag/ShoppingBag_4.jpg';
import shoppingBag_5 from '../../../assets/images/shoppingbag/ShoppingBag_5.jpg';
import shoppingBag_6 from '../../../assets/images/shoppingbag/ShoppingBag_6.jpg';
import shoppingBag_7 from '../../../assets/images/shoppingbag/ShoppingBag_7.jpg';
import shoppingBag_8 from '../../../assets/images/shoppingbag/ShoppingBag_8.jpg';
import shoppingBag_9 from '../../../assets/images/shoppingbag/ShoppingBag_9.jpg';
import shoppingBag_10 from '../../../assets/images/shoppingbag/ShoppingBag_10.jpg';

class ShoppingBagGalleries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPreviewModal: false,
            selectedImage: null,
        }
        this.shortGallery = [
            {
                id: 1,
                title: '',
                fileUrl: shoppingBag_1
            },
            {
                id: 2,
                title: '',
                fileUrl: shoppingBag_2
            },
            {
                id: 3,
                title: '',
                fileUrl: shoppingBag_3
            },
        ];
        this.galleries = [
            ...this.shortGallery,
            {
                id: 4,
                title: '',
                fileUrl: shoppingBag_4
            },
            {
                id: 5,
                title: '',
                fileUrl: shoppingBag_5
            },
            {
                id: 6,
                title: '',
                fileUrl: shoppingBag_6
            },
            {
                id: 7,
                title: '',
                fileUrl: shoppingBag_7
            },
            {
                id: 8,
                title: '',
                fileUrl: shoppingBag_8
            },
            {
                id: 9,
                title: '',
                fileUrl: shoppingBag_9
            },
            {
                id: 10,
                title: '',
                fileUrl: shoppingBag_10
            },
        ];
    }

    componentDidMount = () => !this.props.isMainGallery && window.scrollTo({ top: 120, behavior: 'smooth' });

    imagePreviewClicked = (image) => this.setState({ selectedImage: image, showPreviewModal: true });

    renderChild = (isMainGallery) => {
        const galleries = isMainGallery ? this.shortGallery : this.galleries;
        return (
            <div>
                <NsLabelLine linkTo='#'
                    smallSize={true}
                    primaryTitle={getTranslatedRawText('singlePage.gallery')}
                    secondaryTitle={getTranslatedRawText('product.shoppingBag.discoverOurProducts')}
                    viewMoreLink={isMainGallery ? getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagGallery) : null} />
                <Row className="my-3">
                    {galleries?.map(image =>
                        <Col className='mb-3' key={image?.id} xs={12} md={4}>
                            <div>
                                <NsImage className='w-100' src={image?.fileUrl}
                                    wrapperClassName='border cursor-pointer h-100 rounded me-2'
                                    onClick={() => this.imagePreviewClicked(image)}
                                    height='200px'
                                    imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.galleryImage"), image?.title)} />
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }

    render() {
        const { isMainGallery } = this.props;
        const { showPreviewModal, selectedImage } = this.state;
        return (<>
            {isMainGallery
                ? <div>
                    {this.renderChild(isMainGallery)}
                </div >
                : <ShoppingBagWrapper>
                    {this.renderChild(isMainGallery)}
                </ShoppingBagWrapper>}
            {showPreviewModal && selectedImage &&
                <GalleryDetailModal isLocal={true}
                    selectedImage={selectedImage}
                    images={this.galleries}
                    closeModal={() => this.setState({ showPreviewModal: false })} />
            }
        </>
        );

    }
}

export default ShoppingBagGalleries;