import clsx from "clsx";
import React from "react";
import { Col } from "react-bootstrap";
import { withRouter } from "react-router";

import NsSkeleton from "../../../../components/skeletons/NsSkeleton";
import Enumerations from "../../../../configs/Enumerations";
import { GetDownloadCategoryDetailsService } from "../../../../services/singlePages/DownloadService";
import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../../utilities/CultureHelper";
import UrlHelper from "../../../../utilities/UrlHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ContentPagination from "../../../products/content/ContentPagination";
import DelayedTaskHelper from '../../../../utilities/DelayedTaskHelper';
import DownloadViewCard from "./DownloadViewCard";
import DownloadViewList from "./DownloadViewList";
import NsChip from "../../../../components/chips/NsChip";
import NsButton from "../../../../components/buttons/NsButton";
import CommonHelper from "../../../../utilities/CommonHelper";
import Links from "../../../../configs/links/AllLinks";
import NsTypography from "../../../../components/text/NsTypography";

class DownloadCategoryContent extends React.Component {

    constructor(props) {
        super(props);
        this.delayedGetDownloadCategoryDetails = new DelayedTaskHelper(this.getDownloadCategoryDetails, 1000);
        this.pageNumberOnRoute = Number(UrlHelper.getRouteParameters(this.props.location.search)?.['page-number']);
        this.state = {
            pageLength: props.isSinglePage ? 6 : 3,
            currentPageIndex: 1,
            downloadCategoryDetails: [],
            totalCount: 0,
            isLoading: true,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        this.delayedGetDownloadCategoryDetails.run();
        if (this.pageNumberOnRoute > 1) this.setState({ currentPageIndex: this.pageNumberOnRoute })
        else this.setState({ currentPageIndex: 1 })
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { currentPageIndex } = this.state;
        if (this.props.getDownloadDetailDate !== prevProps.getDownloadDetailDate)
            this.getDownloadCategoryDetails();

        if (this.state.totalCount !== prevState.totalCount && this.state.totalCount < this.pageNumberOnRoute) {
            this.setState({ currentPageIndex: this.state.totalCount })
            CommonHelper.redirectToCorrectPathIfInvalidPageNumber(currentPageIndex, this.state.totalCount, window.location.pathname, this.props);
            this.getDownloadCategoryDetails(this.state.totalCount);
        }
    }

    getDownloadCategoryDetails = (correctPageIndex) => {
        let parameters = {
            pageLength: this.state.pageLength,
            currentPageIndex: correctPageIndex ? correctPageIndex : this.state.currentPageIndex,
            'Filter.CategoryId': UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id),
            'Filter.Title': this.props.searchValue,
        }
        this.setState({ isLoading: true });
        GetDownloadCategoryDetailsService(parameters, this.getDownloadCategoryDetailsCallback)
    }

    getDownloadCategoryDetailsCallback = (resultData, result, messageClassModal) => {
        const { pageLength } = this.state;
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            let totalCount = CommonHelper.getTotalPageCount(resultData.totalCount, pageLength);
            this.setState({ downloadCategoryDetails: resultData?.dataItems, totalCount });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    handleCurrentPage = (currentPageIndex) => this.setState({ currentPageIndex }, this.getDownloadCategoryDetails);

    viewAllClicked = () => this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.downloadDetail), this.props.activeCategoryId, CommonHelper.makeCorrectLink(this.props.activeCategoryTitle)));

    mapItems = () => {
        const { downloadCategoryDetails } = this.state;

        return !isEmpty(downloadCategoryDetails)
            ? downloadCategoryDetails.map((detail, index) => {
                return this.props.selectedViewId == Enumerations.viewType.viewSingle
                    ? <Col key={index} md={4}>
                        <DownloadViewCard downloadCategoryDetail={detail} />
                    </Col>
                    : <DownloadViewList key={index} downloadCategoryDetail={detail} />
            })
            : <NsTypography variant='inherit' className='section-title ms-2'>
                {getTranslatedRawText('singlePage.dataNotFound')}
            </NsTypography>;
    }

    deleteChipClicked = () => CommonHelper.safeFunctionCall(this.props.deleteSearchChipClicked, '');

    render() {
        const { selectedViewId, searchValue, isSinglePage } = this.props;
        const { downloadCategoryDetails, currentPageIndex, totalCount, isLoading, resultMessageClass } = this.state;

        return <div className={clsx("py-2 ", selectedViewId == Enumerations.viewType.viewSingle ? 'row' : '')}>
            {isLoading
                ? <NsSkeleton isVisible={true}
                    variant={[
                        Enumerations.skeleton.text,
                        Enumerations.skeleton.text,
                    ]} />
                : <>
                    {searchValue &&
                        <div className="m-1">
                            {getTranslatedRawText('singlePage.download.searchInResult')} : {!!searchValue && <NsChip chipLabel={searchValue}
                                size='small'
                                className='m-1'
                                deleteChipClicked={this.deleteChipClicked} />
                            }
                        </div>}
                    {this.mapItems()}
                </>
            }
            <div className="d-flex justify-content-center align-items-center m-1">
                <ContentPagination pageNumber={currentPageIndex}
                    totalPageCount={totalCount}
                    handleCurrentPage={this.handleCurrentPage} />

                {!isSinglePage && !isEmpty(downloadCategoryDetails) && <NsButton label='singlePage.download.viewAll'
                    size='small'
                    className='primary'
                    onClick={this.viewAllClicked} />
                }
            </div>

            {resultMessageClass}
        </div>
    }
}

export default withRouter(DownloadCategoryContent);