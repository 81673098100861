import React from 'react';

import NsButton from '../../../components/buttons/NsButton';
import NsImage from '../../../components/images/NsImage';
import NsModal from '../../../components/modals/NsModal';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import OrderHelper from '../../../utilities/OrderHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import OBaseFilmImagePreview from '../bases/OBaseFilmImagePreview';
import OBaseWrapper from '../bases/OBaseWrapper';
import OrderFilesPreview from '../regularOrders/orderSummary/OrderFilesPreview';
import OSummaryChoosingFile from './OSummaryChoosingFile';
import OSummaryConfirm from './OSummaryConfirm';
import OSummaryExtendedService from './OSummaryExtendedService';

class OSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isViewMore: false,
            disabled: isEmpty(OrderHelper.getFrontSideMasterFiles(this.props.routeId)),
            refreshSummaryDate: props.refreshSummaryDate,
            hasImage: null,
            selectedImageIndex: null,
            isFront: true,
            files: []
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.refreshSummaryDate !== nextProps.refreshSummaryDate)
            return ({
                isViewMore: false,
                refreshSummaryDate: nextProps.refreshSummaryDate,
                disabled: isEmpty(OrderHelper.getFrontSideMasterFiles(nextProps.routeId)),
            });
        return null;
    }

    detailMoreClicked = () => this.setState({ isViewMore: !this.state.isViewMore });

    imageClicked = (hasImage, selectedImageIndex, isFront) => this.setState({ hasImage, selectedImageIndex, isFront });

    closePreviewImageClicked = () => this.setState({ hasImage: null });

    filesHandler = (files) => this.setState({ files });

    render() {
        const { routeId, refreshSummaryDate, totalExtendedServicePrice, addToCurrentListClicked, pricesLoading,
            isUsedInProfessional, doRefreshSummaryDate } = this.props;

        const { isViewMore, disabled } = this.state;

        return (
            <OBaseWrapper defaultIsOpen={true} title='regularOrder.confirmDetail.orderDetails'
                className='h-100'>
                <div className='d-flex justify-content-between d-none d-md-flex'>
                    <div className='mb-3 section-title'>{getTranslatedRawText('regularOrder.confirmDetail.orderDetails')}</div>
                    <NsButton label={isViewMore ? getTranslatedRawText('regularOrder.confirmDetail.viewLess') : getTranslatedRawText('regularOrder.confirmDetail.viewMore')}
                        wrapperClassName='ms-2'
                        size='small'
                        disabled={disabled}
                        className='primary-text text-nowrap'
                        onClick={this.detailMoreClicked} />
                </div>
                <div className='order-tour-choosingFileSummary'>
                    <OSummaryChoosingFile routeId={routeId}
                        isViewMore={isViewMore}
                        refreshSummaryDate={refreshSummaryDate} />
                </div>

                {!!totalExtendedServicePrice &&
                    <OSummaryExtendedService routeId={routeId}
                        isViewMore={isViewMore}
                        pricesLoading={pricesLoading}
                        totalExtendedServicePrice={totalExtendedServicePrice}
                        refreshSummaryDate={refreshSummaryDate} />
                }

                <OSummaryConfirm routeId={routeId}
                    totalExtendedServicePrice={totalExtendedServicePrice}
                    pricesLoading={pricesLoading}
                    isViewMore={isViewMore}
                    isUsedInProfessional={isUsedInProfessional}
                    refreshSummaryDate={refreshSummaryDate}
                    addToCurrentListClicked={addToCurrentListClicked}
                    doRefreshSummaryDate={doRefreshSummaryDate} />

                {!isUsedInProfessional &&
                    <div className='orderFilesPreview-tour-preview'>
                        <OrderFilesPreview routeId={routeId}
                            refreshFilePreviewSummaryDate={refreshSummaryDate}
                            imageClicked={this.imageClicked}
                            filesHandler={this.filesHandler} />
                    </div>
                }

                {!!this.state.hasImage &&
                    <DraftImagePreview hasImage={this.state.hasImage}
                        files={this.state.files}
                        isFront={this.state.isFront}
                        selectedImageIndex={this.state.selectedImageIndex}
                        closePreviewImageClicked={this.closePreviewImageClicked} />
                }

            </OBaseWrapper>
        );
    }
}

export function DraftImagePreview(props) {
    const { selectedImageIndex, files, isFront } = props;
    let file = isFront ? files[selectedImageIndex]?.frontSideFileInfo : files[selectedImageIndex]?.backSideFileInfo;
    let mainBox = React.createRef();
    return (
        <NsModal showModal={!!props.hasImage}
            maxWidth="sm"
            dialogTitle={getTranslatedRawText(isFront ? 'profile.detail.originalFrontFileDimension' : 'profile.detail.originalBackFileDimension')}
            closeModal={props.closePreviewImageClicked} >

            <span>{getTranslatedRawText('regularOrder.confirmDetail.fileDimension')}:&nbsp;</span>
            <span className='fw-bold'>{CommonHelper.stringFormat(getTranslatedRawText('fileManager.mainSectionDetail.fileDimension'), file?.width, file?.height)}</span>
            <div>
                {selectedImageIndex === 0
                    ? <NsImage src={isFront ? file?.draft : file?.draft}
                        imgAlt="components.imagesAlt.imageDraft"
                        className="w-100 border"
                        draggable={false} />

                    : <div ref={mainBox}
                        id='draggable-image position-absolute fixed-top fixed-bottom'
                        className="w-100 not-rtl border position-relative">
                        <OBaseFilmImagePreview masterImageInfo={isFront ? files[0]?.frontSideFileInfo : files[0]?.backSideFileInfo}
                            isReadOnly={true}
                            filmImageInfo={file}
                            mainBox={mainBox} />
                    </div>
                }
            </div>
        </NsModal >
    );
}
export default OSummary;