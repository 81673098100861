import React, { useEffect, useState } from 'react';

import NsModal from '../../../../components/modals/NsModal';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseLabelInfo from '../../bases/ApBaseLabelInfo';
import ApBaseUserInfoModal from '../users/bases/ApBaseUserInfoModal';
import { PaymentContent } from './ApPayment';
import { GetUserBasicInfoByIdService } from '../../../../services/adminPanel/AdminUserService'
import { GetOrderDtoService } from '../../../../services/adminPanel/AdminOrderService';
import ApBaseMapActionButtons from '../../bases/ApBaseMapActionButtons';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';


const ApPaymentModal = (props) => {

    const hasAccessInfo = hasAccess([UserClaimEnumerations.getApUserBasicInfoById]);
    const hasAccessGrid = hasAccess([UserClaimEnumerations.getApOrderDto]);

    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [info, setInfo] = useState([])
    const [messageClassModal, setMessageClassModal] = useState(<></>)
    const [selectedOrderDetail, setSelectedOrderDetail] = useState('')

    const userInfoClicked = () => {
        setShowUserInfoModal(true);
    }

    const closeUserInfoModal = () => {
        setShowUserInfoModal(false);
    }

    const headerButtons = [
        hasAccess([UserClaimEnumerations.getApCustomerById]) && { wrapperClassName: '', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: userInfoClicked },
    ];

    const [showModal, setShowMdoal] = useState(props.showModal);

    const closeModal = () => {
        setShowMdoal(false);
        CommonHelper.safeFunctionCall(props.closeModal);
    }

    useEffect(() => {
        !!props?.userId && getUserInfo(props?.userId)
    }, [props?.userId])

    const getUserInfo = (userId) => hasAccessInfo && GetUserBasicInfoByIdService(userId, getUserBasicInfoByIdCallback)

    useEffect(() => {
        !!props?.selectedOrder?.id && getOrdersDetail(props?.selectedOrder.id)
    }, [props?.selectedOrder?.id])

    const getOrdersDetail = (id) => {
        hasAccessGrid && GetOrderDtoService(id, getOrdersByIdCallback);
    }

    const getOrdersByIdCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            setInfo({ orderItemInfoDto: resultData });
        } else
            setMessageClassModal(messageClassModal);
    }

    const getUserBasicInfoByIdCallback = (resultData, result, messageClassModal) => {
        if (!result?.HasError)
            setInfo(resultData)
        else
            setMessageClassModal(messageClassModal)
    }

    return (
        <NsModal maxWidth='lg '
            showModal={showModal}
            closeModal={closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.sidebar.payments")}>

            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={false} />

            {
                !!info && <div className='background-color p-2 px-3'>
                    <ApBaseLabelInfo info={info} hasAccessInfo={hasAccessInfo} />
                </div>
            }

            <div className='p-3'>
                <PaymentContent customerDetails={props.customerDetails}
                    isHideSearch={props.isHideSearch}
                    selectedOrder={props.selectedOrder}
                    searchData={props.searchData}
                    userId={props?.userId} />
            </div>

            {showUserInfoModal &&
                <ApBaseUserInfoModal showModal={showUserInfoModal}
                    selectedUserId={props.userId}
                    closeModal={closeUserInfoModal} />
            }
            {messageClassModal}
        </NsModal>
    );

}

export default React.memo(ApPaymentModal);