import React from 'react';

import '../../assets/sass/components/dragFiles/NsDragFiles.scss';
import Config from '../../configs/Config';
import CommonHelper from '../../utilities/CommonHelper';
import { isEmpty, isValidFileExtension } from '../../utilities/ValidationHelper';
import NsImage from '../images/NsImage';
import { DeleteApFileManagerFilesService } from '../../services/adminPanel/AdminFileUploaderService';
import NsAlertModal from '../modals/NsAlertModal';
import { getTranslatedRawText } from '../../utilities/CultureHelper';

class NsBrowseFileButton extends React.Component {

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.validFilePickerExtensions = Config.validFilePickerExtensions;
        this.state = {
            showDeleteImageModal: false,
            isLoading: false,
            resultMessageClass: <></>
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.triggerFilePicker !== prevProps.triggerFilePicker)
            this.clickOnBrowseFile()
    }

    clickOnBrowseFile = () => this.fileInput.current.click()

    chooseInputFile = () => {
        if (this.fileInput.current && this.fileInput.current.files) {
            for (let file of this.fileInput.current.files) {
                if (this.props.noValidation || isValidFileExtension(file.name)) {
                    CommonHelper.safeFunctionCall(this.props.setSelectedFile, file, true);
                } else {
                    CommonHelper.safeFunctionCall(this.props.setSelectedFile, file, false);
                }
            }
            this.fileInput.current.value = null;
        }
    }

    deleteImageClicked = (isAccept, imgUrl) => {
        if (isAccept) {
            this.setState({
                isLoading: true
            })
            DeleteApFileManagerFilesService({
                fileUrl: imgUrl
            }, this.deleteImageCallback);
        } else this.setState({
            showDeleteImageModal: false
        })
    }

    deleteImageCallback = (resultData, result, messageClassModal) => {
        CommonHelper.safeFunctionCall(this.props.serviceCall, !result?.data?.hasError);
        this.setState({
            showDeleteImageModal: false,
            isLoading: false,
            resultMessageClass: messageClassModal
        })
    }

    render() {
        const { multiple, className, noValidation, content } = this.props;
        return (
            <div className={className}>
                <input className="d-none"
                    ref={this.fileInput}
                    type="file"
                    id="myFile"
                    multiple={!!multiple}
                    accept={noValidation ? '*' : this.validFilePickerExtensions}
                    onChange={this.chooseInputFile} />
                {isEmpty(content?.fileUrl)
                    ? <>
                        <div onClick={this.clickOnBrowseFile} className='d-flex min-vh-25 align-items-center justify-content-center'>
                            <div>click here to upload</div>
                        </div>
                    </>
                    : <>
                        <NsImage key={content?.fileUrl} src={CommonHelper.createImageUrl(content?.fileUrl)}
                            imgAlt="components.imagesAlt.uploadedImage"
                            className='w-100 min-vh-25' />
                        <div className="position-absolute top-0 end-0 d-flex p-2">
                            <i className="ns-icon-edit-file border-outline-color-1px cursor-pointer background-disable-color rounded-circle me-1 h2"
                                onClick={this.clickOnBrowseFile} />
                            <i className="ns-icon-delete border-outline-color-1px cursor-pointer background-disable-color rounded-circle h2"
                                onClick={() => this.setState({ showDeleteImageModal: true })} />
                        </div>
                    </>}

                {this.state.showDeleteImageModal &&
                    <NsAlertModal isOpen={true}
                        activeEnter={true}
                        cancelClicked={() => this.deleteImageClicked(false, content?.fileUrl)}
                        confirmClicked={() => this.deleteImageClicked(true, content?.fileUrl)} >
                        {getTranslatedRawText('common.messages.areYouSureQuestionWithOutParams')}
                    </NsAlertModal>}

                {this.state.resultMessageClass}

            </div>
        );
    }
}

export default NsBrowseFileButton;