import React from "react";

import NsAdminPanelContentWrapper from "../../../../../components/cards/NsAdminPanelContentWrapper";
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { ApCustomerContent } from "./ApCustomerContent";

const ApCustomerUser = (props) => {

    return (<NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.customers")}
        icon={'ns-icon-customer-users h1'}>
        <ApCustomerContent isUserSelection={false} />
    </NsAdminPanelContentWrapper>);
}

export default React.memo(ApCustomerUser);