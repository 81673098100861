import React, { useEffect, useState } from 'react';

import { GetAdditionalOrderInformationService } from '../../../../../../services/adminPanel/AdminOrderService';
import NsModal from '../../../../../../components/modals/NsModal';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import OrderDetailPreviewContent from '../../../../../profileSettings/modals/orderDetailPreview/OrderDetailPreviewContent';
import ApOrderPunchModal from './ApOrderPunchModal';
import ApOrderFoldLinesModal from './ApOrderFoldLinesModal';
import ApOrderFiltersModal from './ApOrderFiltersModal';
import ApOrderBillBookModal from './ApOrderBillBookModal';

const ApOrderDetailPreviewModal = (props) => {

    const { orderId, userId, showModal } = props;

    const [resultData, setResultData] = useState(null);
    const [filtersModalProps, setFiltersModalProps] = useState({
        showFiltersModal: false, file: null, isFront: null
    });
    const [foldLineModalProps, setFoldLineModalProps] = useState({
        showFoldLinesModal: false, draft: null, resolution: null
    });
    const [billBookModalProps, setBillBookModalProps] = useState({
        showBillBookModal: false, draft: null, resolution: null, billBookId: null
    });
    const [punchModalProps, setPunchModalProps] = useState({
        showPunchModal: false, draft: null, resolution: null
    });
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        GetAdditionalOrderInformationService(orderId, userId, getAdditionalOrderInformationCallback);
    }, []);

    const getAdditionalOrderInformationCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            setResultData(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const showFiltersModalClicked = (file, isFront) => setFiltersModalProps({ showFiltersModal: true, file, isFront });
    const showFoldLineshModalClicked = (draft, resolution) => setFoldLineModalProps({ showFoldLinesModal: true, draft, resolution });
    const showBillBookModalClicked = (draft, resolution, billBookId) => setBillBookModalProps({ showBillBookModal: true, draft, resolution, billBookId });
    const showPunchModalClicked = (draft, resolution) => setPunchModalProps({ showPunchModal: true, draft, resolution });

    const closeFiltersModalClicked = () => setFiltersModalProps({ showFiltersModal: false, file: null, isFront: null });
    const closeFoldLinesModalClicked = () => setFoldLineModalProps({ showFoldLinesModal: false, draft: null, resolution: null });
    const closeBillBookModalClicked = () => setBillBookModalProps({ showPunchModal: false, draft: null, resolution: null, billBookId: null });
    const closePunchModalClicked = () => setPunchModalProps({ showPunchModal: false, draft: null, resolution: null });

    const hasExtendedService = resultData?.extendedServices?.length > 0;

    return (
        <NsModal showModal={showModal}
            maxWidth={hasExtendedService ? 'md' : 'sm'}
            dialogTitle={getTranslatedRawText("profile.detail.orderDetail")}
            closeModal={props.closeModal}>

            <OrderDetailPreviewContent resultData={resultData}
                orderId={orderId}
                showDownloadButton={true}
                showFiltersModalClicked={showFiltersModalClicked}
                showFoldLineshModalClicked={showFoldLineshModalClicked}
                showBillBookModalClicked={showBillBookModalClicked}
                showPunchModalClicked={showPunchModalClicked} />

            {filtersModalProps.showFiltersModal &&
                <ApOrderFiltersModal id={orderId}
                    userId={userId}
                    isFront={filtersModalProps.isFront}
                    file={filtersModalProps.file}
                    closeModal={closeFiltersModalClicked} />}

            {foldLineModalProps.showFoldLinesModal &&
                <ApOrderFoldLinesModal id={orderId}
                    userId={userId}
                    draft={foldLineModalProps.draft}
                    resolution={foldLineModalProps.resolution}
                    closeModal={closeFoldLinesModalClicked} />}

            {billBookModalProps.showBillBookModal &&
                <ApOrderBillBookModal id={billBookModalProps.billBookId}
                    userId={userId}
                    draft={billBookModalProps.draft}
                    resolution={billBookModalProps.resolution}
                    closeModal={closeBillBookModalClicked} />}

            {punchModalProps.showPunchModal &&
                <ApOrderPunchModal id={orderId}
                    userId={userId}
                    draft={punchModalProps.draft}
                    resolution={punchModalProps.resolution}
                    closeModal={closePunchModalClicked} />}

            {resultMessageClass}

        </NsModal>
    );
}

export default React.memo(ApOrderDetailPreviewModal);