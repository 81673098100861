import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router';

import { GetGalleriesService } from '../../../../services/singlePages/CmsService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsPageTitleBox from '../../../../components/labels/NsPageTitleBox';
import CommonHelper from '../../../../utilities/CommonHelper';
import NsImage from '../../../../components/images/NsImage';
import NsCard from '../../../../components/cards/NsCard';
import GalleryDetailModal from './GalleryDetailModal';
import { GallerySkeleton } from './Gallery';
import SinglePage from '../../SinglePage';

const GalleryCategories = (props) => {

    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [galleryCategoryDetails, setGalleryCategoryDetails] = useState([]);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getGalleryCategoryDetails();
    }, [])

    const getGalleryCategoryDetails = () => {
        setIsLoading(true);
        GetGalleriesService({ id: params.id }, getGalleryCategoryDetailsCallback);
    }

    const getGalleryCategoryDetailsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setGalleryCategoryDetails(resultData[0]);
        } else
            setResultMessageClass(messageClassModal);
    }

    const imagePreviewClicked = (image) => {
        setShowPreviewModal(true);
        setSelectedImage(image);
    }

    return (
        <SinglePage>
            <NsPageTitleBox title={getTranslatedRawText('singlePage.gallery')}
                icon='ns-icon-blog h1' />
            <NsCard className="p-3">
                {isLoading
                    ? <GallerySkeleton />
                    : <Row className='d-flex align-items-start g-3'>
                        <Col md={4}>
                            <div style={{ maxHeight: '280px' }}>
                                <NsImage className='w-100' src={CommonHelper.createImageUrl(galleryCategoryDetails?.fileUrl)}
                                    wrapperClassName='border h-100 rounded me-2'
                                    height='230px'
                                    imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.galleryImage"), galleryCategoryDetails?.title)} />
                            </div>
                        </Col>
                        <Col md={8} className='d-flex flex-column gap-2'>
                            <span className='page-title mb-0'>
                                {galleryCategoryDetails?.title}
                            </span>
                            <span>
                                {galleryCategoryDetails?.mainContent}
                            </span>
                        </Col>

                        {galleryCategoryDetails?.galleryImages?.map(image =>
                            <Col className='mb-3' key={image?.id} xs={6} md={3}>
                                <div style={{ height: '180px' }}>
                                    <NsImage className='w-100' src={CommonHelper.createImageUrl(image?.fileUrl)}
                                        wrapperClassName='border cursor-pointer h-100 rounded me-2'
                                        onClick={() => imagePreviewClicked(image)}
                                        height='200px'
                                        imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.galleryImage"), galleryCategoryDetails?.title)} />
                                </div>
                            </Col>
                        )}
                    </Row>
                }
            </NsCard>
            {showPreviewModal &&
                <GalleryDetailModal selectedImage={selectedImage}
                    images={galleryCategoryDetails?.galleryImages}
                    closeModal={() => setShowPreviewModal(false)} />
            }
            {resultMessageClass}
        </SinglePage>
    );
};

export default React.memo(GalleryCategories);