import React from 'react';
import { connect } from 'react-redux';

import Enumerations from '../../../../../configs/Enumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { GetShippingMethodsService } from '../../../../../services/adminPanel/AdminShippingService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsButton from '../../../../../components/buttons/NsButton';
import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';

class UpBaseShippingSearchSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            requestNumber: '',
            estimatedDeliveryTimeFrom: null,
            estimatedDeliveryTimeTo: null,
            shippingMethod: null,
            shippingMethods: [],
            isLoading: false,
            resultMessageClass: (<></>),
        }
    }

    componentDidMount = () => this.getShippingMethods();

    getShippingMethods = () => GetShippingMethodsService(this.getShippingMethodsCallback);

    getShippingMethodsCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) this.setState({ shippingMethods: resultData });
        else this.setState({ resultMessageClass: messageClassModal });
    };

    getSearchOptions = () => {
        const { requestNumber, shippingMethod, shippingMethods, estimatedDeliveryTimeFrom, estimatedDeliveryTimeTo } = this.state;
        let searchFields = [
            {
                type: Enumerations.advanceSearchFieldType.numberInput,
                label: getTranslatedRawText('components.advanceSearch.shippingRequestNumber'),
                fieldName: 'requestNumber',
                value: requestNumber,
                onChange: (value) => this.setState({ requestNumber: value })
            },
            {
                type: Enumerations.advanceSearchFieldType.autoComplete,
                label: getTranslatedRawText('adminPanel.shippings.shippingMethod'),
                fieldName: 'shippingMethod',
                value: shippingMethod,
                items: shippingMethods,
                onChange: (value) => this.setState({ shippingMethod: value })
            },
            {
                type: Enumerations.advanceSearchFieldType.dateTimePicker,
                label: getTranslatedRawText('profile.delivery.estimatedSendingTimeFrom'),
                fieldName: 'estimatedDeliveryTimeFrom',
                value: estimatedDeliveryTimeFrom,
                onChange: (value) => this.setState({ estimatedDeliveryTimeFrom: value })
            },
            {
                type: Enumerations.advanceSearchFieldType.dateTimePicker,
                label: getTranslatedRawText('profile.delivery.estimatedSendingTimeTo'),
                fieldName: 'estimatedDeliveryTimeTo',
                value: estimatedDeliveryTimeTo,
                onChange: (value) => this.setState({ estimatedDeliveryTimeTo: value })
            }
        ];

        return searchFields.sort((itemPrev, itemNext) => itemPrev.sortId - itemNext.sortId);
    }

    valueChanged = (value, valueStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        this.setState({ [valueName]: value });
    }

    searchTextChanged = (value) => this.setState({ searchTerm: value });

    searchFormClicked = () => {
        const { requestNumber, shippingMethod, estimatedDeliveryTimeFrom, estimatedDeliveryTimeTo } = this.state;
        let searchParameters = {
            shippingRequestNumber: !!requestNumber ? requestNumber : null,
            shippingMethodId: !!shippingMethod?.key ? shippingMethod?.key : null,
            estimatedDeliveryTimeFrom,
            estimatedDeliveryTimeTo,
        };
        CommonHelper.safeFunctionCall(this.props.searchFormClicked, searchParameters)
    };

    clearClicked = () => {
        this.setState({
            requestNumber: '',
            shippingMethod: null,
            estimatedDeliveryTimeFrom: null,
            estimatedDeliveryTimeTo: null,
        }, () => {
            CommonHelper.safeFunctionCall(this.props.searchFormClicked, {});
        });
    };

    render() {
        const { requestNumber, shippingMethod, estimatedDeliveryTimeFrom, estimatedDeliveryTimeTo,isLoading } = this.state;
        const { autoFocus, children } = this.props;

        const searchValues = { requestNumber, shippingMethod, estimatedDeliveryTimeFrom, estimatedDeliveryTimeTo }
        const searchOptions = CommonHelper.clearArrayFromEmptyItem(this.getSearchOptions());
        return (
            <>
                <NsAdvanceSearch searchOptions={searchOptions}
                    autoFocus={autoFocus}
                    searchValues={searchValues} />
                <div className='d-flex flex-wrap justify-content-between align-items-end'>

                    <div className='d-flex flex-row-reverse my-2 gap-1'>
                        {children}
                        <NsButton className='secondary-outline'
                            label='common.clear'
                            startIcon='ns-icon-clear'
                            loading={isLoading}
                            onClick={this.clearClicked} />
                        <NsButton className='primary'
                            label={getTranslatedRawText('profile.orderReport.search')}
                            startIcon='ns-icon-search'
                            loading={isLoading}
                            onClick={this.searchFormClicked} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({ categories: state.categories.categories });

export default connect(mapStateToProps)(UpBaseShippingSearchSection);