import React, { useEffect, useState } from 'react';

import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsModal from '../../../../components/modals/NsModal';
import { GetAgencyProductsService } from '../../../../services/adminPanel/AdminAgenciesService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApAgencyProductsSearch from './ApAgencyProductsSearch';
import Enumerations from '../../../../configs/Enumerations';

const ApAgencyProductsModal = (props) => {

    const [searchData, setSearchData] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            field: 'productName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
            width: 460
        },
        {
            field: 'companyPriceChangePercent',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.priceChange'),
            dataType: Enumerations.gridType.number,
            width: 200
        },
        {
            field: 'agancyDiscountPercent',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.agencyDiscount'),
            dataType: Enumerations.gridType.number,
            width: 200
        }
    ];

    useEffect(() => {
        setCallServiceDate(new Date());
        setIsLoading(true);
    }, [searchData]);

    const searchClicked = (data) => setSearchData(data);

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsModal maxWidth='md'
            showModal={true}
            closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
            noSpace={true}
            dialogTitle={getTranslatedRawText('adminPanel.agency.productPriceChange')} >

            <div className='p-3'>
                <ApAgencyProductsSearch searchClicked={searchClicked}
                    isLoading={isLoading} />

                <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                    service={GetAgencyProductsService}
                    parameters={{
                        'Filter.AgencyId': props.agencyId || null,
                        'Filter.ProductName': searchData?.name || ''
                    }}
                    columnDefs={columns}
                    hasPagination={true}
                    getData={getData} />
            </div>
        </NsModal>
    );
};

export default React.memo(ApAgencyProductsModal);