import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { GetAccountService, GetSignupFieldVisibilityTypeService, SignupService } from '../../../services/accounts/AccountService';
import { GetCitiesService, GetStatesService } from '../../../services/ProfileSetting/DeliveryAddressSettingService';
import { isEmpty, trnIsValid } from '../../../utilities/ValidationHelper';
import NsSetPageTitleAndDescription from '../../../components/metaTags/NsSetPageTitleAndDescription';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import EnumerationTranslationHelper from '../../../utilities/EnumerationTranslationHelper';
import ConstantsWithTranslation from '../../../configs/ConstantsWithTranslation';
import NsDatePicker from '../../../components/dateTimePickers/NsDatePicker';
import { getEnumKeyFromValue } from '../../../utilities/EnumerationHelper';
import NsPasswordInput from '../../../components/inputs/NsPasswordInput';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsNumberInput from '../../../components/inputs/NsNumberInput';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsTextField from '../../../components/inputs/NsTextField';
import NsTypography from '../../../components/text/NsTypography';
import NsCheckbox from '../../../components/inputs/NsCheckbox';
import NsSelect from '../../../components/dropdowns/NsSelect';
import StorageHelper from '../../../utilities/StorageHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import Enumerations from '../../../configs/Enumerations';
import DateHelper from '../../../utilities/DateHelper';
import Links from '../../../configs/links/AllLinks';
import AccountWrapper from '../AccountWrapper';
import Config from '../../../configs/Config';

class SignupAccountInformation extends React.Component {

    constructor(props) {
        super(props);
        this.mobile = CurrentUserHelper.getMobile();
        this.countryId = CurrentUserHelper.getCountryId();
        this.state = {
            hasOrder: false,

            gender: '',
            isValidGender: false,

            firstName: '',
            isValidFirstName: false,

            lastName: '',
            isValidLastName: false,

            selectedCity: null,
            cityList: [],
            isValidCity: false,

            selectedState: null,
            stateList: [],
            isValidState: false,

            birthDate: new Date(Config.signup.minAcceptableDate),
            isValidBirthDate: true,

            accountType: Enumerations.accountType.business,
            isValidAccountType: true,

            companyName: '',
            isValidCompanyName: false,

            license: '',
            isValidLicense: false,

            trn: '',
            isValidTrn: false,
            trnHelperText: null,

            password: '',
            isValidPassword: false,

            confirmPassword: '',
            isValidConfirmPassword: false,

            showMatchValidationDate: null,
            showValidationDate: null,
            isLoading: false,
            isChecked: false,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => {
        let mobile = CurrentUserHelper.getMobile();
        if (!!this.mobile && !!this.countryId) {
            this.getCurrentAccountInfo(this.mobile);
        }
        // } else
        //     this.props.history.push(getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.mobile));
        GetStatesService({ id: this.countryId }, this.getStatesCallback);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!!prevState.accountType && prevState.accountType !== this.state.accountType)
            this.getFieldVisibilityType();
    }

    keyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter) {
            event.preventDefault();
            this.createAccountClicked()
        }
    }

    checkboxClicked = () => this.setState({ isChecked: !this.state.isChecked });

    valueChanged = (value, isValid, valueStateName, isValidStateName) => {

        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);

        this.setState({
            [valueName]: value,
            [isValidName]: isValid,
        }, () => {
            valueName === CommonHelper.getVariableName({ selectedState: null }) && this.getCities(value);
            valueName === CommonHelper.getVariableName({ trn: null }) && !isEmpty(StorageHelper.getFromStorage(Enumerations.storage.countryData).trnJsRegexPattern) && this.setState({
                trnHelperText: isEmpty(value) || trnIsValid(parseInt(value), StorageHelper.getFromStorage(Enumerations.storage.countryData).trnJsRegexPattern)
                    ? null
                    : CommonHelper.stringFormat(getTranslatedRawText('profile.personal.invalidInput'), getTranslatedRawText('profile.personal.trn')),
                [isValidName]: !(isEmpty(value) || trnIsValid(parseInt(value), StorageHelper.getFromStorage(Enumerations.storage.countryData).trnJsRegexPattern))
            })
        });
    }

    createAccountClicked = () => {
        if (!this.state.isValidGender ||
            !this.state.isValidBirthDate ||
            !this.state.isValidFirstName ||
            !this.state.isValidLastName ||
            !this.state.isValidAccountType ||
            !this.state.isValidPassword ||
            !this.state.isValidState ||
            !this.state.isValidCity ||
            !this.state.isValidConfirmPassword) {
            this.setState({ showValidationDate: new Date() });
            return false;
        }

        if (this.state.password !== this.state.confirmPassword)
            this.setState({ showMatchValidationDate: new Date() });
        else {
            const fieldVisibilityType = this.state.fieldVisibilityType;
            let data = {
                mobile: this.mobile.replace(/ /g, '').substr(1),
                genderTypeId: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.genderTypeIdVisibilityType, this.state.gender),
                birthday: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.birthDateVisibilityType, DateHelper.convertDateTimeToDate(new Date(this.state.birthDate))),
                firstName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.firstNameVisibilityType, this.state.firstName),
                lastName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.lastNameVisibilityType, this.state.lastName),
                cityId: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.cityIdVisibilityType, this.state.selectedCity),
                isCompany: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.isCompanyVisibilityType, this.state.accountType === Enumerations.accountType.business),
                companyName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType, this.state.companyName),
                licenceNumber: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType, this.state.license),
                trn: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType, this.state.trn),
                password: this.state.password,
                confirmPassword: this.state.confirmPassword,
                isAcceptAgreement: this.state.isChecked
            }
            this.setState({ isLoading: true });
            SignupService(data, this.signupCallback);
        }
    }

    signupCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError && !!resultData && resultData.length > 0) {
            CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.username), resultData);
            this.props.history.push(getUrlWithCurrentCulture(Links.view.account.signup, Links.view.account.signup.welcome));
            StorageHelper.removeFromStorage(Enumerations.storage.countryData)
        }
        else
            this.setState({ resultMessageClass });
    }

    getStatesCallback = (resultData, result, resultMessageClass) => {
        if (!result.hasError && !!resultData && resultData.length > 0)
            this.setState({ stateList: resultData });
        else
            this.setState({ resultMessageClass });
    }

    getCities = (value) => {
        value && GetCitiesService({ stateId: value }, this.getCitiesCallback);
    }

    getCitiesCallback = (resultData, result, resultMessageClass) => {
        if (!result.hasError)
            this.setState({ cityList: resultData });
        else
            this.setState({ resultMessageClass });
    }

    getCurrentAccountInfo = (mobile) => {
        this.setState({ isLoading: true });
        GetAccountService(mobile.replace(/ /g, '').substr(1), this.getCurrentAccountInfoCallback);
    }

    getCurrentAccountInfoCallback = (resultData, result) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError && !!resultData) {
            this.setState({
                hasOrder: !!resultData.isEditable ? false : true,
                gender: !!resultData.genderType ? resultData.genderType : '',
                isValidGender: !!resultData.genderType,
                firstName: !!resultData.firstName ? resultData.firstName : '',
                lastName: !!resultData.lastName ? resultData.lastName : '',
                birthDate: !!resultData.birthDate ? new Date(resultData.birthDate) : this.state.birthDate,
                accountType: !!resultData.accountType ? resultData.accountType : Enumerations.accountType.personal,
                companyName: !!resultData.companyName ? resultData.companyName : '',
                license: !!resultData.licenceNumber ? resultData.licenceNumber : '',
                trn: !!resultData.trn ? resultData.trn : '',
                selectedState: resultData.stateId || '',
                isValidState: !!resultData.stateId,
                selectedCity: resultData.cityId || null,
                isValidCity: !!resultData.cityId,
            }, () => {
                !!resultData.stateId && this.getCities(resultData.stateId);
                this.getFieldVisibilityType();
            });
        } else this.props.history.replace(getUrlWithCurrentCulture(Links.view.account.signup.activation))
    }

    getFieldVisibilityType = () => {
        this.setState({ isLoading: true });
        let parameters = {
            pageType: Enumerations.pageType.signUp,
            accountType: this.state.accountType,
            isOfficialInvoiceEnabled: false,
            phoneNumber: this.mobile.replace(/ /g, '').substr(1),
        };
        GetSignupFieldVisibilityTypeService(parameters, this.getFieldVisibilityTypeCallback);
    }

    getFieldVisibilityTypeCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            this.setState({ fieldVisibilityType: resultData });
        else
            this.setState({ resultMessageClass });
    }

    render() {

        const {
            accountType,
            isValidAccountType,
            companyName,
            isValidCompanyName,
            license,
            isValidLicense,
            trn,
            isValidTrn,
            gender,
            isValidGender,
            firstName,
            selectedCity,
            cityList,
            isValidCity,
            selectedState,
            stateList,
            isValidState,
            isValidFirstName,
            lastName,
            isValidLastName,
            birthDate,
            isValidBirthDate,
            password,
            isValidPassword,
            confirmPassword,
            isValidConfirmPassword,
            showValidationDate,
            isChecked,
            trnHelperText,
            fieldVisibilityType
        } = this.state;

        return (
            <AccountWrapper layoutType={Enumerations.loginLayoutType.signup}>

                <NsSetPageTitleAndDescription pageTitle='common.breadcrumbs.signupAccountInformation' />

                <Form onKeyDown={this.keyDown}>

                    <div className="white-panel g-0 d-flex flex-column my-sm-n5 w-100 p-4">
                        <h1 className='section-title'>
                            {getTranslatedRawText("account.signup.createAccountTitle")}
                        </h1>
                        <div className='d-flex mb-2'>
                            <NsTypography variant='inherit' className='light-paragraph'>
                                {getTranslatedRawText("account.common.mobile")}
                            </NsTypography>

                            <NsTypography variant='inherit' className='light-paragraph not-rtl'>
                                : {this.mobile}
                            </NsTypography>
                        </div>

                        <Row >
                            <Col sm={6}>
                                <NsSelect label={getTranslatedRawText('components.genderType.gender')}
                                    disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.genderTypeIdVisibilityType)}
                                    valueChanged={(value, isValid) =>
                                        this.valueChanged(value, isValid, { gender }, { isValidGender })}
                                    className="mt-1 mb-2"
                                    options={ConstantsWithTranslation.getGender()}
                                    selectedItem={gender}
                                    showValidationDate={this.state.showValidationDate} />
                            </Col>
                            <Col sm={6}>
                                <NsDatePicker label="account.signup.birthDate"
                                    disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.birthDateVisibilityType)}
                                    valueChanged={(value, isValid) =>
                                        this.valueChanged(value, isValid, { birthDate }, { isValidBirthDate })}
                                    showValidationDate={this.state.showValidationDate}
                                    className='w-100 mb-2'
                                    selectedDate={birthDate} />
                            </Col>
                        </Row>


                        <Row >
                            <Col sm={6}>
                                <NsTextField label={getTranslatedRawText("account.signup.firstName")}
                                    disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.firstNameVisibilityType)}
                                    valueChanged={(value, isValid) =>
                                        this.valueChanged(value, isValid, { firstName }, { isValidFirstName })}
                                    showValidationDate={this.state.showValidationDate}
                                    size="small"
                                    required
                                    value={firstName}
                                    className="mb-2 w-100" />
                            </Col>
                            <Col sm={6}>
                                <NsTextField label={getTranslatedRawText("account.signup.lastName")}
                                    disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.lastNameVisibilityType)}
                                    valueChanged={(value, isValid) =>
                                        this.valueChanged(value, isValid, { lastName }, { isValidLastName })}
                                    showValidationDate={this.state.showValidationDate}
                                    size="small"
                                    required
                                    value={lastName}
                                    className="mb-2 w-100" />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <NsSelect label={getTranslatedRawText('account.signup.state')}
                                    disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.stateIdVisibilityType)}
                                    className='mb-2'
                                    showValidationDate={showValidationDate}
                                    valueChanged={(value, isValid) => this.valueChanged(value, isValid, { selectedState }, { isValidState })}
                                    options={stateList}
                                    selectedItem={selectedState} />
                            </Col>
                            <Col sm={6}>
                                <NsSelect className='mb-2'
                                    label={getTranslatedRawText('account.signup.city')}
                                    showValidationDate={showValidationDate}
                                    disabled={!selectedState || CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.cityIdVisibilityType)}
                                    valueChanged={(value, isValid) => this.valueChanged(value, isValid, { selectedCity }, { isValidCity })}
                                    options={cityList}
                                    selectedItem={selectedCity} />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <NsSelect label={getTranslatedRawText('components.accountType.accountType')}
                                    disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.isCompanyVisibilityType)}
                                    className='mb-2'
                                    valueChanged={(value, isValid) =>
                                        this.valueChanged(value, isValid, { accountType }, { isValidAccountType })}
                                    selectedItem={accountType}
                                    options={ConstantsWithTranslation.getAccountType()}
                                    showValidationDate={this.state.showValidationDate} />
                            </Col>
                            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType) &&
                                < Col sm={6}>
                                    <NsTextField label={getTranslatedRawText("account.signup.companyName")}
                                        disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType)}
                                        valueChanged={(value, isValid) => this.valueChanged(value, isValid, { companyName }, { isValidCompanyName })}
                                        size="small"
                                        value={companyName}
                                        className="w-100 mb-2" />
                                </Col>
                            }
                        </Row>

                        <BusinessInputs fieldVisibilityType={fieldVisibilityType}
                            accountType={accountType}
                            trnHelperText={trnHelperText}
                            license={license}
                            isValidLicense={isValidLicense}
                            trn={trn}
                            countryId={this.countryId}
                            isValidTrn={isValidTrn}
                            licenseChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { license }, { isValidLicense })}
                            trnChanged={(value, isValid) => this.valueChanged(value, isValid, { trn }, { isValidTrn })}
                            showValidationDate={this.state.showValidationDate} />

                        <input hidden name="username" value={this.mobile} readOnly />
                        <Row >
                            <Col sm={6} className='my-2'>
                                <NsPasswordInput passwordChanged={(value, isValid) =>
                                    this.valueChanged(value, isValid, { password }, { isValidPassword })}
                                    showMatchValidationDate={''}
                                    showValidationDate={this.state.showValidationDate}
                                    className="w-100"
                                    label={getTranslatedRawText("components.passwordInput.password")} />
                            </Col>
                            <Col sm={6} className='my-2'>
                                <NsPasswordInput passwordChanged={(value, isValid) =>
                                    this.valueChanged(value, isValid, { confirmPassword }, { isValidConfirmPassword })}
                                    showMatchValidationDate={this.state.showMatchValidationDate}
                                    showValidationDate={this.state.showValidationDate}
                                    className="w-100"
                                    label={getTranslatedRawText("components.passwordInput.confirmPassword")} />
                            </Col>
                        </Row>
                        <div className='d-flex mb-5 align-items-start mb-md-2'>
                            <AgreementPolicy onChange={this.checkboxClicked}
                                isChecked={isChecked} />
                        </div>
                        <div className="d-flex justify-content-center">
                            <NsStickyButton stickyButtons={[CommonHelper.createStickyButtonItem('account.signup.createMyAccount',
                                '', this.createAccountClicked, 'primary ', !isChecked)]}
                                className='justify-content-md-center'
                                isLoading={this.state.isLoading} />
                        </div>
                    </div>
                    {this.state.resultMessageClass}
                </Form >
            </AccountWrapper >
        );
    }

}

function BusinessInputs(props) {
    const { fieldVisibilityType, countryId, trn, trnHelperText, showValidationDate, license } = props;
    const labelOflicenseNumber = EnumerationTranslationHelper.getLicenseNameByCountryId(countryId);

    return (
        <Row>
            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType) &&
                <Col sm={6}>
                    <NsTextField label={labelOflicenseNumber}
                        valueChanged={(value, isValid) => props.licenseChanged(value, isValid)}
                        disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType)}
                        size="small"
                        value={license}
                        className="mb-2 w-100" />
                </Col>
            }
            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType) &&
                <Col sm={6}>
                    <NsNumberInput label={getTranslatedRawText("account.signup.trn")}
                        valueChanged={(value, isValid) => props.trnChanged(value, isValid)}
                        disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType)}
                        size="small"
                        helperText={trnHelperText}
                        prefix={countryId === Enumerations.countryId.oman ? getTranslatedRawText('common.omanTrn') : ''}
                        value={trn}
                        className="mb-2 w-100" />
                </Col>
            }
        </Row>
    );
}

export function AgreementPolicy(props) {
    return <>
        <NsCheckbox value={props.isChecked}
            onChange={props.onChange}
            className='p-0 m-0 mt-1'
            checkboxClassName='ps-0' />
        <div>
            <NsTypography className='d-inline-block'>
                {CommonHelper.stringFormat(getTranslatedRawText("account.signup.iHaveRead"))}
            </NsTypography>
            <a target='_blank'
                href={getUrlWithCurrentCulture(Links, Links.privacyPolicy)}
                className='signup mx-1' >
                {getTranslatedRawText('account.signup.privacyPolicy')}
            </a>

            <NsTypography className='d-inline-block'>
                {CommonHelper.stringFormat(getTranslatedRawText("account.signup.and"))}
            </NsTypography>
            <a target='_blank'
                href={getUrlWithCurrentCulture(Links, Links.termsOfUse)}
                className='signup mx-1'>
                {getTranslatedRawText('account.signup.termsOfUse')}
            </a>

            <NsTypography className='d-inline-block'>
                {CommonHelper.stringFormat(getTranslatedRawText("account.signup.agreeToThem"))}
            </NsTypography>
        </div>
    </>
}

export default SignupAccountInformation;