const FestivalTranslationEn = {

    festival: {
        festivals: {
            festivals: 'Festivals',
            viewFestival: 'View Festival',
            excDate: 'Festival Executing Date'
        },
        congratulations: 'Congratulations',
        sorry: 'Oh! Sorry!',
        sorryDes: "We regret to inform you that you did not win any prize in {0}. we will have another shopping festival soon and you can try your chance again.",
        needToSignIn: "To see the Festival result and check if you have won or not, you must log in to your user account.",
        winnerPrize: "We are glad to inform you that you won ",
        in: ' in ',
        theOneOf: 'The one of ',
        winnerIs: ' winner is ',
        winningCode: 'winning code.',
        prizesList: 'Prizes list',
        viewWinner: "View Winner",
        minPurchase: 'Minimum Purchase to Earn Points',
        loginLabel: 'Login to Calculate your Points',
        showYourPoint: 'View Your Point',
        executingDate: 'Festival Executing Date ',
        totalPoint: 'Total Earned Points',
        totalPurchaseAmount: 'Total purchase amount ',
        purchaseOf: ' purchase of ',
        from: 'From ',
        groupSheetJobs: 'Group Sheet Jobs',
        customizedSheetJobs: 'Customized Sheet Jobs',
        readyProductJobs: 'Ready-Made Product Jobs',
        online: 'Online',
        inPerson: 'In-Person',
        firstPointMessage: 'You are yet to order and receive first points.',
        minAmount: "To receive one more point, place an order of {0}.",
        morePointMessage: 'You are yet to order and receive more points.',
        moreChanceMessage: 'To participate in the Shopping Festival, a minimum purchase of {0} is required.',
        points: 'Points',
        youNeedMorePoint: 'To receive one more point, place an order of {0}.',
        takePartIn: 'Take Part in',
        giftCount: ' x {0}',
        festivalAwaitMessage: 'Stay tuned for the {0} of Shopping Festival on this page.',
        liveShow: 'Live Show',

        countdown: {
            pointReceivingRemainingTime: "Point Receiving Remaining Time",
            seconds: "Seconds",
            minutes: "Minutes",
            hours: "Hours",
            days: "Days",
            eventStartedTime: "Festival Starting Date",
            eventEndTime: "Festival Ending Date",
            festivalHasEndedTitle: "Time for {0}'s Festival has Ended.",
            festivalHasEndedDescription: "We are calculating your points. We will send your festival codes soon and will see them here. "
        },

        codes: {
            yourCodes: "Your Festival Codes",
            searchPlaceHolder: "Search in codes...",
        }
    }
};

export default FestivalTranslationEn;