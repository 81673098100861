import React, { Component } from 'react';
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import NsLoadingProgress from './components/circularProgress/NsLoadingProgress';
import { getCurrentDirection, isEnCulture, isLanguageAdmissible } from './utilities/CultureHelper';

class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoadedBootstrap: false
        };
    }

    importingPackage = () => {
        isEnCulture()
            ? import('bootstrap/dist/css/bootstrap.css')
                .then(() => this.setState({ isLoadedBootstrap: true }))
                .catch((err) => this.importingPackage())
            : import('bootstrap/dist/css/bootstrap.rtl.css')
                .then(() => this.setState({ isLoadedBootstrap: true }))
                .catch((err) => this.importingPackage())
    }

    componentDidMount = () => {
        !isLanguageAdmissible({ ...this.props }) && window.location.reload();
        const currentDirection = getCurrentDirection();
        document.body.className = currentDirection;
        document.body.dir = currentDirection;
        this.importingPackage();
    }

    render() {
        return (!this.state.isLoadedBootstrap
            ? <NsLoadingProgress visible={this.state.isLoadedBootstrap} />
            : <Router>
                <Switch>
                    {
                        AppRoutes.map((routeInfo, index) => {
                            const { Layout, path, Component, breadCrumb, title, exact, showResetTour, from, to, needAuthentication, ...rest } = routeInfo;
                            return (<Layout key={index} path={path} component={Component} breadCrumb={breadCrumb} title={title} exact={exact} from={from} to={to} needAuthentication={needAuthentication} {...rest} showResetTour={showResetTour} />);
                        })
                    }
                </Switch>
            </Router>
        );
    }
}

export default App;