import React from 'react';
import { Row } from 'react-bootstrap';

import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import NsLabelBox from '../../../../components/labels/NsLabelBox';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import NsTypography from '../../../../components/text/NsTypography';

class StoreOrderDetailPreviewContent extends React.Component {

    render() {
        const { resultData } = this.props;
        return (
            <>
                <NsTypography className='section-title fw-bold mb-2'>{resultData?.productName}</NsTypography>
                <BasicInfoLabelBox basicInfo={resultData} />
                <div className='mt-3'>
                    <div>{getTranslatedRawText("profile.detail.totalAmount")}:
                        <b className='red-color'>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(resultData?.totalPrice)}</b><b>&nbsp;({getTranslatedRawText("profile.detail.exclusiveOfVAT")})</b>
                    </div>
                </div>
            </>
        );
    }
}

function BasicInfoLabelBox(props) {
    const { basicInfo } = props;
    return (
        <Row className='mb-2'>
            {!basicInfo ?
                CommonHelper.createArray(4).map((item, index) =>
                    <div key={index} className='d-inline-block w-25 px-1'>
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                    </div>
                )
                : <div className='d-flex gap-2 flex-wrap'>
                    <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.quantity')}
                        value={basicInfo?.unitCount} />

                    <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.unitPrice')}
                        value={CommonHelper.currencyFormatByDefaultConfig(basicInfo?.unitPrice)} />

                </div>
            }
        </Row>
    );
}

export default StoreOrderDetailPreviewContent;