
import { Redirect } from 'react-router-dom';

import LoginLayoutRoute from '../views/layouts/login/LoginLayoutRoute';
// // sign up
import SignupMobile from '../views/accounts/signup/SignupMobile';
import SignupActivation from '../views/accounts/signup/SignupActivation';
import SignupAccountInformation from '../views/accounts/signup/SignupAccountInformation';
import SignupWelcome from '../views/accounts/signup/SignupWelcome';
// // sign in
import SignIn from '../views/accounts/signIn/Signin';
import ForgotPasswordMobile from '../views/accounts/signIn/ForgotPasswordMobile';
import ChangeForgotPassword from '../views/accounts/signIn/ChangeForgotPassword';
import SuccessChangeForgotPassword from '../views/accounts/signIn/SuccessChangeForgotPassword';


const AccountRoutes = [
    { Layout: LoginLayoutRoute, path: "/:lng/sign-up/mobile", Component: SignupMobile, breadCrumb: [], title: 'account.common.signup', exact: false },
    { Layout: LoginLayoutRoute, path: "/:lng/sign-up/activation", Component: SignupActivation, breadCrumb: [], title: 'account.titles.signupActivation', exact: false },
    { Layout: LoginLayoutRoute, path: "/:lng/sign-up/account-information", Component: SignupAccountInformation, breadCrumb: [], title: 'account.titles.signupAccountInformation', exact: false },
    { Layout: LoginLayoutRoute, path: "/:lng/sign-up/welcome", Component: SignupWelcome, breadCrumb: [], title: 'account.titles.signupWelcome', exact: false },
    { Layout: Redirect, from: "/:lng/sign-up", to: "/:lng/sign-up/mobile" },

    { Layout: LoginLayoutRoute, path: "/:lng/sign-in", Component: SignIn, breadCrumb: [], title: 'account.common.login', exact: false },
    { Layout: LoginLayoutRoute, path: "/:lng/forgot-password/mobile", Component: ForgotPasswordMobile, breadCrumb: [], title: 'account.titles.signInForgotPassword', exact: false },
    { Layout: LoginLayoutRoute, path: "/:lng/forgot-password/change-password", Component: ChangeForgotPassword, breadCrumb: [], title: 'account.titles.signInChangePassword', exact: false },
    { Layout: LoginLayoutRoute, path: "/:lng/forgot-password/success", Component: SuccessChangeForgotPassword, breadCrumb: [], title: 'account.titles.signInSuccessChangePassword', exact: false },
    { Layout: Redirect, from: "/:lng/forgot", to: "/:lng/forgot-password/mobile", breadCrumb: [], title: 'account.titles.signInForgotPassword', exact: false },
];

export default AccountRoutes;