import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { metaTagActionCreators } from '../../redux/reducers/MetaTagReducer';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import Config from '../../configs/Config';
import { getConfigObjectByCulture } from '../../utilities/ConfigHelper';

class NsSetPageTitleAndDescription extends Component {

    componentDidMount() {
        this.updateMetaTags();
    }

    componentDidUpdate(prevProps) {
        const {
            pageTitle,
            sortBy,
            circulation,
            pageNumber,
            minPrice,
            maxPrice,
            selectedCategoryNames,
            printedSide,
            turnaround,
            searchText,
        } = this.props;

        if ((prevProps?.pageNumber !== pageNumber) ||
            (prevProps?.pageTitle !== pageTitle) ||
            (prevProps?.sortBy !== sortBy) ||
            (prevProps?.circulation !== circulation) ||
            (prevProps?.minPrice !== minPrice) ||
            (prevProps?.maxPrice !== maxPrice) ||
            (prevProps?.selectedCategoryNames !== selectedCategoryNames) ||
            (prevProps?.printedSide?.key !== printedSide?.key) ||
            (prevProps?.turnaround?.key !== turnaround?.key) ||
            (prevProps?.searchText !== searchText)) {
            this.updateMetaTags();
        }
    }

    updateMetaTags() {
        const {
            pageTitle,
            sortBy,
            circulation,
            pageNumber,
            minPrice,
            maxPrice,
            maximumFilterPrice,
            selectedCategoryNames,
            printedSide,
            turnaround,
            searchText,
            setMetaTags,
            description,
            noNeedToTranslate,
            keywords
        } = this.props;

        let title = '';
        if (!isEmpty(pageTitle))
            title = title + `${!noNeedToTranslate ? getTranslatedRawText(pageTitle) : pageTitle}`;
        if (!isEmpty(circulation))
            title = title + ` | ${getTranslatedRawText('adminPanel.dataGridColumn.circulation')}: ${circulation}`;
        if (!isEmpty(printedSide?.label || printedSide?.value))
            title = title + ` | ${getTranslatedRawText('profile.dataGridColumns.printedSide')}: ${printedSide?.label || printedSide?.value}`;
        if (!isEmpty(turnaround?.label || turnaround?.value))
            title = title + ` | ${getTranslatedRawText('profile.dataGridColumns.turnaround')}: ${turnaround?.label || turnaround?.value}`;
        if (!isEmpty(pageNumber))
            if (pageNumber !== 1)
                title = title + ` | ${getTranslatedRawText('profile.dataGridColumns.page')}: ${pageNumber}`;
        if (!isEmpty(minPrice))
            if (minPrice !== 0 || maxPrice !== maximumFilterPrice)
                title = title + ` | ${getTranslatedRawText('profile.dataGridColumns.price')}: ${minPrice + '-' + maxPrice}`;
        if (!isEmpty(selectedCategoryNames))
            title = title + ` | ${getTranslatedRawText(Array.isArray(selectedCategoryNames) && selectedCategoryNames.length > 1 ? 'common.categories' : 'common.category')}: ${selectedCategoryNames}`;
        if (!isEmpty(searchText))
            title = title + ` | ${getTranslatedRawText('product.sideBarTitles.searchInResult')}: ${searchText}`;
        if (!isEmpty(sortBy))
            title = title + ` | ${getTranslatedRawText('product.sortItems.sortBy')}: ${sortBy}`;
        
            setMetaTags({ title, description, keywords });
    }

    render() {
        return null;
    }
}

export const getTitleMetaTagByCulture = () => getConfigObjectByCulture(Config.metaTags)?.title;

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...metaTagActionCreators }, dispatch);
};

export default connect(null, mapDispatchToProps)(NsSetPageTitleAndDescription);