import React, { Component } from "react";
import { Link } from "react-router-dom";

import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../utilities/CultureHelper";
import { GetCmsBlogFilterCategoriesService, GetCmsNewsFilterCategoriesService } from "../../../services/singlePages/CmsService";
import NsCard from "../../../components/cards/NsCard";
import NsCollapse from "../../../components/dropdowns/NsCollapse";
import CommonHelper from "../../../utilities/CommonHelper";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import NsButton from "../../../components/buttons/NsButton";
import { isEmpty } from "../../../utilities/ValidationHelper";
import Enumerations from "../../../configs/Enumerations";
import { withRouter } from "react-router";
import Links from "../../../configs/links/AllLinks";

class BnSidebarCategories extends Component {
    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.state = {
            categories: [],
            isLoading: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.getCategories();

    getCategories = () => {
        let parameters = { pageLength: 4, currentPageIndex: 1 };
        this.setState({ isLoading: true });
        this.isBlog
            ? GetCmsBlogFilterCategoriesService(parameters, this.getCategoriesCallback)
            : GetCmsNewsFilterCategoriesService(parameters, this.getCategoriesCallback);
    }

    getCategoriesCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.setState({ categories: resultData.dataItems });
        } else
            this.setState({
                resultMessageClass: messageClassModal,
                categories: []
            });
    };

    viewMoreClicked = () => {
        return this.isBlog
            ? getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogCategories)
            : getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsCategories);
    }

    categoryItemClicked = (item) => {
        return this.isBlog
            ? CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogCategoryPosts), item.id, item.title)
            : CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsCategoryPosts), item.id, item.title);
    }

    render() {
        const { categories, resultMessageClass, isLoading } = this.state;
        const lengthOfCategoriesToShowInSidebar = 5;
        const isShowShowMoreButton = categories.length > lengthOfCategoriesToShowInSidebar;
        return (
            <NsCard className='p-3 categories'>
                <NsCollapse className='mb-2'
                    defaultIsOpen={true}
                    titleClassName='section-title w-100'
                    title={getTranslatedRawText('product.sideBarTitles.categories')} >
                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                            ]} />
                        : <div className="d-flex flex-column">
                            {categories?.map((item, index) =>
                                index < lengthOfCategoriesToShowInSidebar && <Link key={index}
                                    className='black-color text-decoration-none mb-1'
                                    to={() => this.categoryItemClicked(item)} >
                                    {item.title}
                                </Link>
                            )}
                        </div>
                    }
                </NsCollapse>
                {!isEmpty(categories) && !isLoading && isShowShowMoreButton && <NsButton className='light'>
                    <Link to={this.viewMoreClicked} className='d-flex justify-content-center w-100 black-color text-decoration-none'>
                        {getTranslatedRawText('product.common.viewMore')}
                    </Link>
                </NsButton>}
                {resultMessageClass}
            </NsCard >
        );
    }
}

export default withRouter(BnSidebarCategories);