import React from "react";

import NsProductImage from "../../../components/images/NsProductImage";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import Enumerations from "../../../configs/Enumerations";
import '../../../assets/sass/views/products/ProductDetail.scss';

class PdBaseImagePanel extends React.Component {

    render() {
        const { className } = this.props;
        return <div className={className}>
            <NsSkeleton isVisible={this.props.isLoading}
                variant={[Enumerations.skeleton.rect]} />
            <NsProductImage product={this.props.product}
                usedIn={Enumerations.imageSitePosition.productDetailsImage}
                className="w-100" />
        </div>
    }
}

export default PdBaseImagePanel;