import React from 'react';
import App from './App';
import { getCurrentCulture } from './utilities/CultureHelper';
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom';
import { RtlDirection } from './components/theme/RtlDirection';

class CultureRouter extends React.Component {
  constructor(props) {
    super(props)

    let culture = getCurrentCulture();

    this.state = {
      culture: culture
    };
  }

  render() {
    const { culture } = this.state;
    return (
      <Router>
        <Switch>
          <Route path="/" exact render={propRouter => {
            return <Redirect to={culture + "/"} />
          }} />
          <Route path="/sitemap.xml" exact render={propRouter => {
            return <Redirect to={culture + "/site-map"} />
          }} />
          <Route path="/en/*" render={
            propsRouter =>
              <App
                {...propsRouter}
                culture={culture}
              />
          } />
          <Route path="/fa/*" render={
            propsRouter =>
              <RtlDirection>
                <App
                  {...propsRouter}
                  culture={culture}
                />
              </RtlDirection>
          } />

          <Route path="*" render={propRouter => {
            return <Redirect to={"/" + culture + "/"} />
          }} />

        </Switch>
      </Router>
    )
  }
}

export default CultureRouter;