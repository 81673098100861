const ClaimTranslationEn = {
    adminPanelClaim: {
        AdminPanel: 'Admin Panel',

        FinancialDashboard: 'Financial Dashboard',
        getApDfSaleGadgets: 'Get sales gadgets',
        getApDfTotalOnlinePayments: 'Get total online Payment',
        getApDfTotalSales: 'Get total sales',
        getApDfOnlinePaymentsGateway: 'Get online payments by gateways',
        getApDfOrderByTurnaround: 'Get orders by turnaround',
        getApDfOrderByApplicationType: 'Get orders by application type',

        BusinessDashboard: 'Business Dashboard',
        getApDbTotalSales: 'Get total sales in business dashboard',
        getApDbTotalOrders: 'Get total order in business dashboard',
        getApDbOnlinePaymentStatus: 'Get online payment status',
        getApDbTotalOnlinePayments: 'Get total online payments',

        UserDashboard: 'User Dashboard',
        getApDuTotalUsers: 'Get total users',
        getApDuLastJoinedUsers: 'Get last joined users',
        getApDuRemainingLimitWithCredit: 'Get Remaining Limit With Credit Users',
        getApDuRemainingLimitWithoutCredit: 'Get Remaining Limit Without Credit Users',
        getApDuGrantedCredit: 'Get Granted Credit Users',

        Dashboard: 'Sms Dashboard',
        getApDSmsPanelCredit: 'Get credit of Sms panels',

        PaymentAuditDashboards: 'Payment Audit Dashboards',
        getApDauAggregateStatistics: 'Get aggregate statistics',
        getApDauNotProcessedPaymentAuditCount: 'Get not processed payment audit count',
        getApDauPaymentGatewaysMessages: 'Get payment gateways messages',
        getApDauStatisticsBasedOnAuditedAndPaymentStatuses: 'Get statistics based on audited and payment statuses',
        getApDauStatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes: 'Get statistics based on mismatched types of payment audit matching types',

        UserFeedbackDashboard: 'Feedback Dashboard',
        getApDfbComments: 'Get Feedback Comments',
        getApDfbRatingParametersChart: 'Get Rating Parameters Chart',
        getApDfbStrengthWeeknessChart: 'Get Strengths and Weeknesses Chart',
        getApDfbProducts: 'Get Feedback Products',
        getApDfbTotalByStatusGedgets: 'Get Feedbacks By Status Gedgets',
        getApDfbUserFeedbacks: 'Get Total User Feedbacks Quantity and Avrage Chart',
        getApDfbByTypeMetricAndRates: 'Get Feedbacks By Type Metric And Rates',
        getApDfbByStateTypesAndDateChart: 'Get Feedbacks By State Types And Date Chart',
        getApDfbStatuses: 'Get Feedback Statuses',
        getApDfbCitiesGrid: 'Get Feedback Cities Grid',
        getApDfbCustomers: 'Get Feedback Customers',
        getApDfbProvinces: 'Get Feedback Provinces',
        getApDfbTotalStatusByFilterOptions: 'Get Feedback Total Status By Filter Options',
        getApDfbSharedSheetOrderFeedback: 'Get Feedback Shared Sheet Orders',
        getApDfbEmployeesGrid: 'Get Feedback Employees Grid',

        UserCashbackDashboard: 'User Cashback Dashboard',
        getApCshbUsersCashbackForecastes: 'Get Users Cashback Forecastes',
        getApCshbFromInquiryTimes: 'Get Cashback header From Inquiry Times',
        getApCshbToInquiryTimes: 'Get Cashback header To Inquiry Times',
        getApCshbExcelReport: 'Get Cashbvack Excel Report',
        getApCshbForcastsWithGrowthPercent: 'Get Cashback Forcasts With Growth Percent',

        UserCustomer: 'Customers',
        getApCustomers: 'Get Customers',
        getApCustomerById: 'Get specific customer info',
        editApCustomer: 'Edit customer profile',
        getApUserBasicInfoById: 'Get basic info of each customer',
        updateApCustomerToken: 'Disable customer token',
        getApCustomersDuplicatePhoneNumbers: 'Get Customers Duplicate Phone Numbers',
        getApUserCredit: 'Get User Credit',
        getApCustomerFieldVisibilityType: 'Get Customer Field Visibility Type',

        UserSupporter: 'Supporters',
        getApSupporters: 'Get Supporters',
        getApSupporterById: 'Get specific supporter info',
        getApSupporterBasicInfoById: 'Get basic info of each Employee',
        editApSupporter: 'Edit supporter profile',
        editApUserCredit: 'Granted User Credit',

        UserBusinessPartner: 'User Business Partner',
        getApUserBusinessPartners: 'Get User Business Partners',
        getApUserBusinessPartnerById: 'Get specific User Business Partner info',
        editApUserBusinessPartner: 'Edit User Business Partner profile',
        getApUserBusinessPartnerBasicInfoById: 'Get User Business Partner Basic Info',

        UserAddressesAdmin: 'User Addresses Admin',
        getApUserActiveAddresses: 'Get User Active Addresses',

        UserDepartment: 'User Department',
        postApAddUserDepartment: 'Asign Depatment To User',
        getApUserDepartments: 'Get User Departments',
        getApUserDepartmentById: 'Get Specific User Department',
        postApUserDepartment: 'Add New User Department',
        editApUserDepartment: 'Edit Specific User Department',
        deleteApUserDepartment: 'Delete Specific User Department',
        getApDepartmentsTree: 'Get Departments Tree',
        getApUserDepartmentsTree: 'Get User Departments Tree',
        getApDepartmentsList: 'Get Departments List',

        SharedSheetOrdersAdmins: 'Share Sheet Orders',
        SharedSheetOrderDetailOptionAdmin: 'Share Sheet Order Detail Options',
        getApOrders: 'Get all orders',
        getApOrderDto: 'Get order info label',
        getApOrderDetail: 'Get order detail',
        updateApOrderStatus: 'Update orders status',
        getApOrderStatusHistory: 'Get order history status',
        getApOrderNasStatusHistory: 'Get order Nas history status',
        getApOrderNasStatusHistoryDetail: 'Get order Nas history status detail',
        deleteApNotConfirmedOrder: 'Delete not confirmed order',
        getApOrderUnOfficialReport: 'Get order Unofficial Report',
        getApOrderOfficialReport: 'Get order Official Report',
        getApStatusQueue: ' Get Status Queue',
        getApOrderFilterPoints: 'Get order filter points',
        getApOrderBillBookOptions: 'Get order billBook options',
        getApOrderPunchPoints: 'Get order punch point',
        getApOrderFoldLines: 'Get order fold lines',
        getApOrderAdditionalInformation: 'Get order additional information',
        getApOrderProductDetail: 'Get order product detail',
        getApOrderUserAggrementDetail: 'Get user aggrement detail in order',
        getApStatusQueueByServerName: 'Get Status Queue By Server Name',
        getApOrderEstimatedTime: 'Get Order Estimated Time',
        getApOrderQueueByRemainingToEstimatedTime: 'Get Order Queue By Remaining Estimated Time',

        StoreProductOrdersAdmin: 'Store Product Orders Admin',
        getApStoreOrders: 'Get Store Orders',
        getApStoreOrderOfficialReport: 'Get Store Order Official Report',

        PaymentsAdmins: 'Payments',
        getApPayments: 'Get all payments',
        getApPaymentById: 'Get specific payment',
        getApPaymentOrders: 'Get orders payment',
        getApPaymentIncreaseCredit: 'Get increase credit payment',
        getApPaymentStatusHistory: 'Get payment history status',
        getApPaymentReport: 'Get payment Pdf report',
        updateApPaymentStatus: 'Update payment status',
        getApPaymentsHasProblem: 'Get payments has problem',
        getApAllPaymentsExcelReport: 'Get All Payments Excel Report',

        PaymentAudits: 'Payment Audits',
        getApPaymentAudits: 'Get all payment audits',
        getApPaymentAuditById: 'Get specific payment audit(advance)',
        getApPaymentAuditGateways: 'Get payment audit gateways',
        getApPaymentAuditHistories: 'Get payment audit histories(advance)',
        updateApPaymentAuditStateToFail: 'Update payment audit state to fail',
        updateApPaymentAuditMatchingType: 'Update payment audit matching type',
        getApAllPaymentAuditsExcelReport: 'Get All Payment Audits Excel Report',
        updateApPaymentAuditsMatchingTypes: 'Update Payment Audits Matching Types',

        PaymentAuditSources: 'Payment Audit Sources',
        getApPaymentAuditAsanPardakhts: 'Get Asan Pardakht file records',
        getApPaymentAuditAsanPardakhtByActionId: 'Get specific record of Asan Pardakht file(basic)',
        getApPaymentAuditSepehrs: 'Get Sepehrs file records',
        getApPaymentAuditSepehtByActionId: 'Get specific record of Sepeht file(basic)',
        getApPaymentAuditNgenius: 'Get Ngenius file records',
        getApPaymentAuditNgeniusByActionId: 'Get specific record of Ngenius file(basic)',
        postApPaymentAuditSources: 'Upload payment audit sources',
        getApPaymentAuditSources: 'Get last upload sources',

        SharedSheetOrderReport: 'Share Sheet Order Report',
        getApUsersDebitCredit: 'Get Users Debit-Credit Report',
        getApStatisticalReportOfOrders: 'Get Statistical Report Of Orders',
        getApStatisticalReportOfPayments: 'Get Statistical Report Of Payments',

        Shipping: 'Transfer Request',
        getApShippings: 'Get all shippings',
        getApShippingById: 'Get basic info of each shipment',
        getApShippingGroupSheetOrders: 'Get group sheet orders shipping',
        getApShippingStoreOrders: 'Get store orders shipping',
        getApShippingQueueByLastStatuses: 'Get Shipping Queue By Last Statuses',
        getApShippingRemainingEstimatedTime: 'Get Shipping Remaining Estimated Time',
        getApQueueByShippingMethods: 'Get Queue By Shipping Methods',
        getApShippingMethods: 'Get Shipping Methods',
        getApShippingPlans: 'Get Shipping Plans',

        CustomerFiles: 'Customer Files',
        getApCustomerFiles: 'Get all customer files',
        getApCustomerFileById: 'Get each file info',
        getApDownloadOriginFile: 'Download original file',

        PprContents: 'Product Contents',
        getApPprContents: 'Get Ppr Contents',
        getApPprContentByPprId: 'Get Ppr Content By Ppr Id',
        getApPprContentByName: 'Get Ppr Content By Name',
        postApPprContent: 'Add Ppr content',
        editApPprContentById: 'Edit Ppr Content',
        getApPprContentBasicInfo: 'Get Ppr Content Basic Info',

        FeedbackToAnnouncementContents: 'Announcement Feedbacks',
        getApFeedbackAnnouncements: 'Get Feedback Announcements',
        getApFeedbackAnnouncementBasicInfo: 'Get Feedback Announcement Basic Info',

        FeedbackToSharedSheetOrders: 'Shared Sheet Order Feedbacks',
        getApFeedbackShareSheetOrders: 'Get Feedback Shared Sheet Orders',
        getApFeedbackShareSheetOrderBasicInfo: 'Get Feedback Shared Sheet Order Basic Info',

        FeedbackToEmployees: 'Feedback To Employees',
        getApFeedbackToEmployees: 'Get Feedback To Employees',
        getApFeedbackToEmployeeBasicInfo: 'Get Feedback To Employee Basic Info',

        UserFeedbacks: 'User Feedbacks',
        getApFeedbackActivityHistory: 'Get Feedback Activity History',
        updateApFeedbackIsPublic: 'Update Feedback Is Public',
        postApFeedbackAddState: 'Add Feedback State',

        FeedbackTypes: 'Feedback Types',
        getApFeedbackTypes: 'Get Feedback Types',
        getApFeedbackTypeBasicInfo: 'get Feedback Type Basic Info',
        updateApFeedbackTypeState: 'Update Feedback Type State',

        FeedbackTypeMetrics: 'Feedback Type Metrics',
        getApFeedbackTypeMetrics: 'Get Feedback Type Metrics',
        getApFeedbackTypeMetricById: 'Get Feedback Type Metric By Id',
        postApFeedbackTypeMetric: 'Add Feedback Type Metric',
        editApFeedbackTypeMetric: 'Edit Feedback Type Metric',
        deleteApFeedbackTypeMetric: 'Delete Feedback Type Metric',
        editApSortFeedbackTypeMetric: 'Sort Feedback Type Metric',

        DemandedServiceTypes: 'Demanded Service Types',
        getApFeedbackTypeDemandServices: 'Get Feedback Type Demanded Services',
        getApFeedbackTypeDemandServiceById: 'Get Feedback Type Demanded Service By Id',
        postApFeedbackTypeDemandService: 'Add Feedback Type Demanded Service',
        editApFeedbackTypeDemandService: 'Edit Feedback Type Demanded Service',
        deleteApFeedbackTypeDemandService: 'Delete Feedback Type Demanded Service',
        editApSortFeedbackTypeDemandServices: 'Sort Feedback Type Demanded Services',

        Blog: 'Blog',
        BlogCategory: 'Blog Category',
        getApBlogs: 'Get all blogs',
        getApBlogById: 'Get each blog info',
        postApBlog: 'Add new blog',
        editApBlog: 'Edit blog',
        deleteApBlog: 'Delete blog',
        getApBlogCategories: 'Get all blog category',
        getApBasicBlogCategories: 'Get list of blog categories',
        getApBlogCategoryById: 'Get each blog category info',
        deleteApBlogCategory: 'Delete blog category',
        editApBlogCategory: 'Edit blog category',
        postApBlogCategory: 'Add blog category',

        News: 'News',
        NewsCategory: 'News Category',
        getApNews: 'Get all news',
        getApNewsById: 'Get each news info',
        postApNews: 'Add new news',
        editApNews: 'Edit news',
        deleteApNews: 'Delete news',
        getApNewsCategories: 'Get all news category',
        getApBasicNewsCategories: 'Get list of news categories',
        getApNewsCategoryById: 'Get each news category info',
        postApNewsCategory: 'Add news category',
        editApNewsCategory: 'Edit news category',
        deleteApNewsCategory: 'Delete news category',

        AnnouncementContent: 'Announcement Content',
        getApAnnouncements: 'Get all announcements',
        getApAnnouncementById: 'Get each announcement info',
        getApAnnouncementTitleContentById: 'Get each announcement title content',
        postApAnnouncement: 'Add new announcement',
        editApAnnouncement: 'Edit announcement',
        editApSortAnnouncement: 'Edit sort of announcement',
        deleteApAnnouncement: 'Delete announcement',

        StaticPageAdmin: 'Static Pages',
        getApSpecificStaticPageList: 'Get specific static page list',
        getApSpecificStaticPageRowById: 'Get specific static page row info',
        postApSpecificStaticPageRow: 'Add specific static page row',
        editApSpecificStaticPageRow: 'Edit specific static page row',
        editApSortStaticPageAdmin: 'Edit sort static page row',
        deleteApSpecificStaticPageRow: 'Delete specific static page row',

        FileUploader: 'Upload File',
        postFileUploader: 'Upload File',

        Slider: 'Slider',
        getApSliders: 'Get All Sliders',
        getApSliderById: 'Get each slider info',
        postApSlider: 'Add new slider',
        editApSlider: 'Edit slider image',
        editApSortSlider: 'Edit sort of slider',
        deleteApSlider: 'Delete slider',

        PromoteContent: 'Promote Content',
        getApPromoteContent: 'Get each promote info',
        editApPromoteContent: 'Edit promote content',

        StoreProductProperties: 'Store Product Properties',
        StoreProducts: 'Store Products',
        StoreProductSupplierPriceDiscounts: 'Store Product Supplier Price Discounts',
        StoreProductSupplierPrices: 'Store Product Supplier Prices',
        StoreProductUnits: 'Store Product Units',

        deleteApStoreProductProperty: 'Delete Store Product Property',
        getApStoreProductProperty: 'Get Store Product Property',
        editApStoreProductProperty: 'Edit Store Product Property',
        postApStoreProductProperty: 'Add Store Product Property',
        deleteApStoreProduct: 'Delete Store Product',
        getApStoreProduct: 'Get Store Product',
        getApStoreProducts: 'Get Store Products',
        editApStoreProduct: 'Edit Store Product',
        postApStoreProduct: 'Add Store Product',
        deleteApStoreProductSupplierPriceDiscount: 'Delete Store Product Supplier Price Discount',
        getApStoreProductSupplierPriceDiscountsById: 'Get Store Product Supplier Price Discounts By Id',
        getApStoreProductSupplierPriceDiscounts: 'Get Store Product Supplier Price Discounts',
        editApStoreProductSupplierPriceDiscounts: 'Edit Store Product Supplier Price Discounts',
        postApStoreProductSupplierPriceDiscounts: 'Add Store Product Supplier Price Discounts',
        deleteApStoreProductSupplierPrices: 'Delete Store Product Supplier Prices',
        getApStoreProductSupplierPricesById: 'Get Store Product Supplier Prices By Id',
        getApStoreProductSupplierPrices: 'Get Store Product Supplier Prices',
        editApStoreProductSupplierPrices: 'Edit Store Product Supplier Prices',
        postApStoreProductSupplierPrices: 'Add Store Product Supplier Prices',
        deleteApStoreProductUnits: 'Delete Store Product Units',
        getApStoreProductUnits: 'Get Store Product Units',
        editApStoreProductUnits: 'Edit Store Product Units',
        postApStoreProductUnits: 'Add Store Product Units',

        getApDuInactiveUsers: 'Get Inactive Users',
        editResolveOrderPaymentProblem: 'Edit Resolve Order Payment Problem',
        deleteFileUploader: 'Delete File Uploader',
        getFileUploader: 'Get File Uploader',

        Agency: 'Agency',
        getApAgencyBasicDetails: 'Get Agency Basic Details',
        getApAgencies: 'Get Agencies',
        getApAgencyById: 'Get Agency By Id',
        getApProductsOfAgency: 'Get Products Of Agency',
        getApDaLatestSubmittedAgencies: 'Get Latest Submitted Agencies',
        getApDaTotalSalesAmountAgencies: 'Sales Amount Agencies',
        getApDaPopularAgencies: 'Popular Agencies',
        getApDaActiveStates: 'Active States',

        Festival: 'Festival',
        getApFestivals: 'Get Festivals',
        getApFestivalById: 'Get Festival By Id',
        getApFestivalPrizes: 'Get Festival Prizes',
        getApFestivalWinners: 'Get Festival Winners',
        getApFestivalWinnersActionId: 'Get Festival Winners Action Id',

        CmsLog: 'Cms Log',
        getApCmsLogs: 'Get cms logs',
        getApCmsLogById: 'Get specific cms log',
        getApCmsLogException: 'Get cms log exception',
        getApCmsLogExceptionType: 'Get cms log exception type',
        getApCmsLogSources: 'Get cms log sources',

        Downloads: 'Downloads',
        DownloadCategory: 'Download Category',
        getApDownloadCategories: 'Get Download Categories',
        getApDownloadCategoriesDropDown: 'Get Download Categories DropDown',
        getApDownloadCategoryById: 'Get Download Category By Id',
        postApDownloadCategory: 'Post Download Category',
        editApDownloadCategory: 'Edit Download Category',
        deleteApDownloadsCategory: 'Delete Downloads Category',

        getApDownloads: 'Get Download Center',
        getApDownloadById: 'Get Download By Id',
        postApDownload: 'Post Download',
        editApDownload: 'Edit Download',
        deleteApDownloads: 'Delete Downloads',

        DownloadDetails: 'Download Details',
        getApDownloadsByIdDetails: 'Get Downloads By Id Details',
        getApDownloadDetailsById: 'Get Download Details By Id',
        postApDownloadDetails: 'Post Download Details',
        editApDownloadDetails: 'Edit Download Details',
        deleteApDownloadDetails: 'Delete Download Details',

        Gallery: 'Gallery',
        getApGalleries: 'Get Galleries',
        getApGalleryBasicDetails: 'Get Gallery Basic Details',
        getApGalleryById: 'Get Gallery By Id',
        postApGallery: 'Post Gallery',
        editApGallery: 'Edit Gallery',
        deleteApGallery: 'Delete Gallery',

        GalleryCategory: 'Gallery Category',
        getApGalleryCategories: 'Get Gallery Categories',
        getApGalleryCategoryById: 'Get Gallery Category By Id',
        postApGalleryCategory: 'Post Gallery Category',
        editApGalleryCategory: 'Edit Gallery Category',
        deleteApGalleryCategory: 'Delete Gallery Category',

        Advertisement: 'Advertisements',
        getApAdvertisements: 'Get Advertisements',
        getApAdvertisementById: 'Get Advertisement Detail',
        postApAdvertisement: 'Post Advertisement',
        editApAdvetisement: 'Edit Advertisement',
        deleteAdvertisement: 'Delete Advertisement',

        UserAgreements: 'User Agreements',
        UserAgreementSigns: 'User Agreement Sign',
        getApUserAggrements: 'Get aggrements',
        getApUserAggrementById: 'Get specific aggrement',
        getApUserAggrementDetailById: 'Get user aggrement Detail by id',
        postApUserAggrement: 'Add new aggrement',
        updateApUserAggrement: 'edit aggrement',
        deleteApUserAggrement: 'delete aggrement',
        getApUserAggrementStaticPages: 'Get aggrement static pages',
        getApUserAgreementsStaticPageDetailById: 'Get Agreements Static Page Detail By Id',
        postApUserAggrementStaticPages: 'Add aggrement static pages',
        editApUserAggrementStaticPages: 'Edit aggrement static pages',
        deleteApUserAggrementStaticPages: 'Delete aggrement static pages',
        updateApUserAggrementState: 'Update user aggrement state',
        getApUserAggrementSignHistories: 'Get user aggrement sign histories',
        getApUserAggrementSignUserInfo: 'Get user aggrement sign user info',
        getApUserAggrementSignUsers: 'Get user aggrement sign users',
    }
};

export default ClaimTranslationEn;