import React from 'react';

import NsTypography from '../../../../../components/text/NsTypography';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import OBaseWrapper from '../../../bases/OBaseWrapper';
import PoExtendedServiceCornersType from './PoExtendedServiceCornersType';
import PoExtendedServiceBillBook from './PoExtendedServiceBillBook';
import PoExtendedServiceFilter from './PoExtendedServiceFilter';
import PoExtendedServiceFoldLine from './PoExtendedServiceFoldLine';
import PoExtendedServiceGripperEdgeType from './PoExtendedServiceGripperEdgeType';
import PoExtendedServicePunch from './PoExtendedServicePunch';
import PoExtendedServiceBagHandle from './PoExtendedServiceBagHandle';
import PoExtendedServiceBagTopFolding from './PoExtendedServiceBagTopFolding';

class PoExtendedService extends React.Component {

    render() {
        const { routeId, refreshExtendedServicesDate, refreshSummary } = this.props;
        const hasExtendedService = OrderHelper.hasExtendedService(routeId);

        return (hasExtendedService
            ? <OBaseWrapper defaultIsOpen={true} title='regularOrder.extendedService.extendedServices'>

                <NsTypography variant='inherit' className='section-title mb-3 d-none d-md-flex'>
                    {getTranslatedRawText('regularOrder.extendedService.extendedServices')}
                </NsTypography>

                <PoExtendedServiceFilter routeId={routeId}
                    refreshSummary={refreshSummary}
                    refreshExtendedServicesDate={refreshExtendedServicesDate} />

                <PoExtendedServicePunch routeId={routeId}
                    refreshSummary={refreshSummary}
                    refreshExtendedServicesDate={refreshExtendedServicesDate} />

                <PoExtendedServiceFoldLine routeId={routeId}
                    refreshSummary={refreshSummary}
                    refreshExtendedServicesDate={refreshExtendedServicesDate} />

                <PoExtendedServiceBillBook routeId={routeId}
                    refreshSummary={refreshSummary}
                    refreshExtendedServicesDate={refreshExtendedServicesDate} />

                <PoExtendedServiceCornersType routeId={routeId}
                    refreshSummary={refreshSummary}
                    refreshExtendedServicesDate={refreshExtendedServicesDate} />

                <PoExtendedServiceGripperEdgeType routeId={routeId}
                    refreshSummary={refreshSummary}
                    refreshExtendedServicesDate={refreshExtendedServicesDate} />

                <PoExtendedServiceBagTopFolding routeId={routeId}
                    refreshSummary={refreshSummary}
                    refreshExtendedServicesDate={refreshExtendedServicesDate} />

                <PoExtendedServiceBagHandle routeId={routeId}
                    refreshSummary={refreshSummary}
                    refreshExtendedServicesDate={refreshExtendedServicesDate} />

            </OBaseWrapper>
            : null
        );
    }
}

export default PoExtendedService;