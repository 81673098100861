import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import NsPageMainContentWrapper from '../../../../../components/cards/NsPageMainContentWrapper';
import NsTextField from '../../../../../components/inputs/NsTextField';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApLanguageTabs from '../../../bases/ApLanguageTabs';

function ContactInfo(props) {
    const [state, setState] = useState({
        loading: false,
        availableTimes: '',
        isValidAvailableTimes: false,
        website: '',
        isValidWebsite: false,
        email: '',
        isValidEmail: false,
        hotline: '',
        isValidHotline: false,
        googleMapLink: '',
        isValidGoogleMapLink: false,
        address: '',
        isValidAddress: false,
        showValidationDate: '',
    });

    const saveClicked = () => {
        setState({
            ...state,
            showValidationDate: new Date()
        });
        return;
    }

    const discardClicked = () => setState({ loading: true },
        /* () =>  api*/)


    const mainButtons = [
        { label: "common.save", onClicked: saveClicked, icon: 'ns-icon-save', className: 'primary' },
        { label: "common.discard", onClicked: discardClicked, icon: 'ns-icon-close', className: 'primary-outline' },
    ];
    const valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        setState({
            ...state,
            [valueName]: value,
            [isValidName]: isValid
        })
    }
    return (
        <NsPageMainContentWrapper header={getTranslatedRawText("adminPanel.sidebar.contactInfo")}
            icon={'ns-icon-information h1'}>

            <ApLanguageTabs />

            <Row className='w-100' >
                <Col xs={4} className="mb-3">
                    <NsTextField
                        valueChanged={(value, isValid) =>
                            valueChanged(value, isValid, { availableTimes: state.availableTimes }, { isValidAvailableTimes: state.isValidAvailableTimes })}
                        showValidationDate={state.showValidationDate}
                        label={getTranslatedRawText("adminPanel.contactInfo.availableTimes")}
                        size="small"
                        value={state.availableTimes}
                        className="w-100" />
                </Col>

                <Col xs={4} className="mb-3">
                    <NsTextField
                        valueChanged={(value, isValid) =>
                            valueChanged(value, isValid, { website: state.website }, { isValidWebsite: state.isValidWebsite })}
                        showValidationDate={state.showValidationDate}
                        label={getTranslatedRawText("adminPanel.contactInfo.website")}
                        size="small"
                        value={state.website}
                        className="w-100" />
                </Col>

                <Col xs={4} className="mb-3">
                    <NsTextField
                        valueChanged={(value, isValid) =>
                            valueChanged(value, isValid, { email: state.email }, { isValidEmail: state.isValidEmail })}
                        showValidationDate={state.showValidationDate}
                        label={getTranslatedRawText("adminPanel.contactInfo.email")}
                        size="small"
                        value={state.email}
                        className="w-100" />
                </Col>

                <Col xs={4} className="mb-3">
                    <NsTextField
                        valueChanged={(value, isValid) =>
                            valueChanged(value, isValid, { hotline: state.hotline }, { isValidHotline: state.isValidHotline })}
                        showValidationDate={state.showValidationDate}
                        label={getTranslatedRawText("adminPanel.contactInfo.hotline")}
                        value={state.hotline}
                        className="w-100" />
                </Col>

                <Col xs={4} className="mb-3">
                    <NsTextField
                        valueChanged={(value, isValid) =>
                            valueChanged(value, isValid, { googleMapLink: state.googleMapLink }, { isValidGoogleMapLink: state.isValidGoogleMapLink })}
                        showValidationDate={state.showValidationDate}
                        label={getTranslatedRawText("adminPanel.contactInfo.googleMapLink")}
                        size="small"
                        fullWidth={true}
                        value={state.googleMapLink}
                        className="w-100"
                    />
                </Col>

                <Col xs={4} className="mb-3">
                    <NsTextField
                        valueChanged={(value, isValid) =>
                            valueChanged(value, isValid, { address: state.address }, { isValidAddress: state.isValidAddress })}
                        showValidationDate={state.showValidationDate}
                        label={getTranslatedRawText("adminPanel.contactInfo.address")}
                        size="small"
                        value={state.address}
                        className="w-100" />
                </Col>

            </Row>

            <NsStickyButton stickyButtons={mainButtons} />

        </NsPageMainContentWrapper>
    );
}
export default React.memo(ContactInfo);