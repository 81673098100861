import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsDragFile from '../../../components/dragFiles/NsDragFile';
import NsAlertModal from '../../../components/modals/NsAlertModal';
import NsModal from '../../../components/modals/NsModal';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import { messageClass } from '../../../utilities/MessageModalHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import ModalTitleRoute from '../bases/ModalTitleRoute';
import UploadFile from '../bases/UploadFile';
import FmSidbar from '../sidebars/FmSidbar';

class FmUploadFileModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [CommonHelper.createStickyButtonItem('common.ok', 'ns-icon-confirm', this.handleAlertModal, 'primary')];
        this.state = {
            overwriteExistingFile: false,
            currentFolderId: props.currentFolderId,
            files: [],
            isShowUploadingProgress: true,
            uploadedFilesIds: [],
            isAlertModalOpen: false,
            resultMessageClass: (<></>)
        }
    }

    getSelectedFile = (file, checkFileExtention) => {
        // const isFileDuplicate = this.state.files.some(oldFile => oldFile.name === file.name);
        // if (isFileDuplicate) {
        //     this.setState({
        //         resultMessageClass: messageClass(CommonHelper.stringFormat('{0} {1}',
        //             file.name, getTranslatedRawText('regularOrder.messages.isAlreadyUploaded')))
        //     });
        //     return;
        // }
        if (checkFileExtention) {
            this.setState(oldState => ({
                ...oldState,
                files: [
                    {
                        name: file.name || '',
                        size: file.size || 0,
                        id: Math.random(),
                        fileContent: file,
                        currentFolderId: this.props.currentFolderId,
                        folderRoute: this.createFolderRoute(true)
                    },
                    ...oldState.files,
                ]
            }));
        } else {
            this.setState({
                resultMessageClass: messageClass(CommonHelper.stringFormat('{0} {1}',
                    file.name, getTranslatedRawText('regularOrder.messages.formatIsWrong')))
            });
        }
    }

    overwriteExistingFileChanged = (checked) => this.setState(state => ({ overwriteExistingFile: !state.overwriteExistingFile }));

    getCanceledProgressId = (id) => {
        const filteredFiles = this.state.files.filter(file => file.id !== id);
        this.setState({ files: filteredFiles });
    }

    handleAlertModal = () => {
        const { files, uploadedFilesIds } = this.state;
        if (uploadedFilesIds.length < files.length)
            this.setState({ isAlertModalOpen: true });
        else {
            CommonHelper.safeFunctionCall(this.props.onClose);
            CommonHelper.safeFunctionCall(this.props.refresh);
        }
    }

    setUploadedFiles = (id) => {
        this.setState({ uploadedFilesIds: [...this.state.uploadedFilesIds, id] });
    }

    alertCancelClicked = () => this.setState({ isAlertModalOpen: false });

    alertConfirmClicked = () => {
        this.props.onClose();
        this.props.refresh();
    }

    createFolderRoute = (darkMode) => {
        return <div className={clsx('d-flex align-items-baseline', !!darkMode && 'font-size-12')} >
            {getTranslatedRawText("fileManager.modals.uploadFile.title")}
            <ModalTitleRoute treeViewFolders={this.props.treeViewFolders}
                currentFolderId={this.props.currentFolderId}
                darkMode={darkMode} />
        </div >
    }

    render() {
        const { files, isAlertModalOpen } = this.state;

        return (
            <NsModal maxWidth="md"
                dialogTitle={getTranslatedRawText("fileManager.modals.uploadFile.title")}
                showModal={this.props.showModal}
                closeModal={this.handleAlertModal}
                actions={!isEmpty(files) && <NsStickyButton stickyButtons={this.mainButtons} />} >

                <Row className="justify-content-around file-manager__upload-file light-text-color g-0">
                    <Col md={3} className={CommonHelper.isMobileDevice() ? 'd-none' : 'pe-2'} >
                        <FmSidbar treeViewFolders={this.props.treeViewFolders}
                            isUploadTreeView={true}
                            className='h-100' />
                    </Col>
                    <Col md={9} className='mt-2 '>
                        <div className='mb-2'>
                            <NsDragFile getSelectedFile={this.getSelectedFile}
                                isShowSelectFileButton={true}
                                isShowVertical={isEmpty(files)}
                                noInnerPadding={true}
                                isAdminPanelFileManager={this.props?.isAdminPanelFileManager}
                                multiple={true} />
                        </div>

                        <div className='mb-2'>
                            {!isEmpty(files) && files.map(file =>
                                <UploadFile file={file.fileContent}
                                    key={file.id}
                                    id={file.id}
                                    isAdminPanelFileManager={this.props?.isAdminPanelFileManager}
                                    folderRoute={file.folderRoute}
                                    setUploadedFiles={this.setUploadedFiles}
                                    getCanceledProgressId={this.getCanceledProgressId}
                                    currentFolderId={file.currentFolderId} />
                            )}
                        </div>
                    </Col>
                </Row >
                <NsAlertModal isOpen={isAlertModalOpen}
                    cancelClicked={this.alertCancelClicked}
                    confirmClicked={this.alertConfirmClicked} >
                    {getTranslatedRawText('fileManager.modals.uploadFile.closeModalAlert')}
                </NsAlertModal>
                {this.state.resultMessageClass}
            </NsModal >
        );
    }
}

export default FmUploadFileModal;