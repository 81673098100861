import React, { Component } from 'react';

import NsMenu from '../menu/NsMenu';
import NsTypography from '../text/NsTypography';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import { MenuItem } from '@mui/material';

class NsProductGuidance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closeMenuDate: null
        };
    }

    closeMenuClicked = () => this.setState({ closeMenuDate: new Date() });

    render() {
        const { closeMenuDate } = this.state;
        const { guidancesUrls } = this.props;

        if (isEmpty(guidancesUrls)) return null;

        if (guidancesUrls?.length === 1)
            return <GuidanceList guidancesUrls={guidancesUrls} />
        else
            return (
                <NsMenu buttonClassName="p-0 secondary-text"
                    closeMenuDate={closeMenuDate}
                    buttonChildren={<ButtonChildren />}>
                    <GuidanceList guidancesUrls={guidancesUrls}
                        closeMenuClicked={this.closeMenuClicked} />
                </NsMenu>
            );
    }
}

function ButtonChildren() {
    return <>
        <i className='ns-icon-download h4' />
        <NsTypography className='fw-bold text-capitalize mx-1'>
            {getTranslatedRawText('product.detail.downloadGuide')}
        </NsTypography>
        <i className='ns-icon-expand-down' />
    </>
}

function GuidanceList(props) {
    const { guidancesUrls, closeMenuClicked } = props;
    const guidanceList = guidancesUrls?.length === 1
        ? <div className='d-flex'>
            <i className='ns-icon-download h4' />
            <a target='_blank'
                href={CommonHelper.createImageUrl(guidancesUrls[0])}
                className='text-decoration-none text-color my-1' >
                {CommonHelper.stringFormat(getTranslatedRawText('common.label.downloadGuide'), '')}
            </a>
        </div>
        :
        <div>{guidancesUrls.map((item, index) =>
            <MenuItem>
                <a key={index}
                    target='_blank'
                    href={CommonHelper.createImageUrl(item)}
                    onClick={closeMenuClicked}
                    className='d-block text-decoration-none text-color my-2'>
                    {CommonHelper.stringFormat(getTranslatedRawText('common.label.downloadGuide'),
                        index + 1)}
                </a></MenuItem>)}
        </div>
    return guidanceList;
}

export default NsProductGuidance;