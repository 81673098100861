import Config from "../../../../configs/Config";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation"
import { isEmpty } from "../../../../utilities/ValidationHelper";
import { getEnumKeyFromValue } from '../../../../utilities/EnumerationHelper'
import Enumerations from '../../../../configs/Enumerations'

const languages = ConstantsWithTranslation.getAllLanguages();

export default class ContentHelper {

    static normalizeContentObject = (contents, defaultValues) => {
        languages.map(lng => {
            if (!contents.some(content => content.languageType === lng.id))
                contents.push({ languageType: lng.id, content: { ...defaultValues } });
            return true;
        });
        return contents;
    }

    static updateContentHandler = (data, value, selectedLanguageId) => {
        Object.keys(value).forEach((key, index) => {
            let valueName = key;
            let valueState = value[key];
            data.map(item => {
                if (item.languageType == selectedLanguageId) {
                    item.content[valueName] = valueState;
                }
            });
        })
        return ([...data]);
    }

    static getDefaultCultureId = () => {
        return Config.app.defaultCulture === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en)
            ? Enumerations.culture.en
            : Enumerations.culture.fa;
    }

    static getSpecificLanguageContent = (contents, selectedLanguageId) => {
        contents = ContentHelper.mergeCommonData(contents)
        let selectedTabContent = isEmpty(contents) ? null : contents.filter(content => content.languageType == selectedLanguageId)[0];
        return selectedTabContent
    }

    static mergeCommonData = (contents) => {
        let defaultCulture = ContentHelper.getDefaultCultureId();
        let defaultTabContent = isEmpty(contents) ? null : contents.filter(content => content.languageType == defaultCulture)[0];
        for (let index = 0; index < contents.length; index++) {
            const currentTabContent = contents[index];
            if (defaultTabContent.content.isActive !== currentTabContent.content.isActive) {
                currentTabContent.content.isActive = defaultTabContent.content.isActive;
            }
            if (defaultTabContent.content.pageContentCommentType !== currentTabContent.content.pageContentCommentType) {
                currentTabContent.content.pageContentCommentType = defaultTabContent.content.pageContentCommentType;
            }
            if (defaultTabContent.content.pageCategories !== currentTabContent.content.pageCategories) {
                currentTabContent.content.pageCategories = defaultTabContent.content.pageCategories;
            }
        }
        return contents
    }

    static removeBlogCategoryEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title) || !isEmpty(item.content?.mainContent));
    }

    static isValidBlogCategoryContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title) || isEmpty(item.content?.mainContent));
    }

    static removeDownloadCategoryEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title));
    }

    static isValidDownloadCategoryContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title));
    }

    static removeDownloadFileEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title));
    }

    static isValidDownloadFileContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title) || isEmpty(item.content?.pageCategories));
    }

    static removeDownloadAttachFileEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title));
    }

    static isValidDownloadAttachFileContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title));
    }

    static removeAnnouncementEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title) || !isEmpty(item.content?.mainContent));
    }

    static isValidAnnouncementContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title) || isEmpty(item.content?.mainContent));
    }

    static removePostEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title) || !isEmpty(item.content?.mainContent));
    }

    static isValidPostContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title) || isEmpty(item.content?.mainContent) || isEmpty(item.content?.pageCategories));
    }

    static isValidGalleryPostContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title) || isEmpty(item.content?.pageCategories));
    }

    static removeFaqEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.question) || !isEmpty(item.content?.answer));
    }

    static isValidFaqContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.question) || isEmpty(item.content?.answer));
    }

    static removeAgreementEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title));
    }

    static isValidAgreementContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title));
    }

    static removeStaticPageEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title) || !isEmpty(item.content?.mainContent));
    }

    static isValidStaticPageContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title) || isEmpty(item.content?.mainContent));
    }

    static removeSliderEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title) || !isEmpty(item.content?.summary));
    }

    static isValidSliderContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title) || isEmpty(item.content?.summary));
    }

    static removePromotionEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title) || !isEmpty(item.content?.linkUrl));
    }

    static isValidPromotionContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title) || isEmpty(item.content?.linkUrl));
    }

    static removeSmsConfigsEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title) || !isEmpty(item.content?.content));
    }

    static isValidSmsConfigsContent = (contents) => {
        return contents.some(item => isEmpty(item.content?.title) || isEmpty(item.content?.content));
    }

    static removFeedbackTypeMetricEmptyContent = (contents, isDemandService) => {
        return isDemandService
            ? contents.filter(item => !isEmpty(item.content?.demandedServiceType))
            : contents.filter(item => !isEmpty(item.content?.title));
    }

    static isValidFeedbackTypeMetricContent = (contents, isDemandService) => {
        return isDemandService
            ? contents.some(item => isEmpty(item.content?.demandedServiceType))
            : contents.some(item => isEmpty(item.content?.title));
    }

    static removeDepartmentEmptyContent = (contents) => {
        return contents.filter(item => !isEmpty(item.content?.title));
    }
}