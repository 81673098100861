import clsx from 'clsx';
import React from 'react';
import '../../assets/sass/components/labels/NsOptionBox.scss'
import CommonHelper from '../../utilities/CommonHelper';

class NsOptionBox extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { isSelected } = this.props;

        return (
            <div className={clsx({
                'g-0': !!CommonHelper.isMobileDevice(),
                'option-box rounded p-1 m-1': true,
                'd-flex': !CommonHelper.isMobileDevice(),
                'option-box--selected ': isSelected,
            })}>
                {this.props.children}
            </div>
        )
    }
}

export default NsOptionBox;