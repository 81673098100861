import React from "react";

import NsButton from "../../../../components/buttons/NsButton";
import NsLoadingProgress from "../../../../components/circularProgress/NsLoadingProgress";
import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import NsTab from "../../../../components/tabs/NsTab";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../../configs/Enumerations";
import { GetProfileUserFeedbacksService } from "../../../../services/ProfileSetting/FeedbackSettingService";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import EnumerationTranslationHelper from "../../../../utilities/EnumerationTranslationHelper";
import UserPanelWrapper from "../../UserPanelWrapper";
import FeedbackModal from "../../modals/FeedbackModal";

class UpProfileFeedbackSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedTabIndex: 0,
            selectedTab: ConstantsWithTranslation.getFeedbackstatus()[0],
            refreshDate: new Date(),
            showFeedbackDetailModal: false,
            selectedFeedback: '',
            resultMessageClass: (<></>),
        };
        this.columns = [{
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return < NsButton startIcon='ns-icon-invoice-details'
                        className='secondary-text'
                        onClick={() => this.feedbackDetailModalClicked(props)} />
                }
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 250
        },
        {
            field: 'feedbackType',
            headerName: getTranslatedRawText('adminPanel.feedback.feedbackType'),
            width: 150,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => EnumerationTranslationHelper.getTranslategetFeedbackType(props?.feedbackType),
            }
        },
        {
            field: 'averageRate',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.averageRate'),
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => !!props.averageRate ? props.averageRate.toFixed(2) : props.averageRate
            }
        },
        {
            field: 'demandedServiceTypeName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.extraSupport'),
            width: 150
        },
        {
            field: 'submitDate',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        ]
    }

    feedbackDetailModalClicked = (data) => this.setState({ selectedFeedback: data, showFeedbackDetailModal: true });
    tabChanged = (selectedTabIndex, selectedTab) => this.setState({ selectedTabIndex, selectedTab, refreshDate: new Date() });
    toggleFeedbackModal = (id, isMessageModal) => {
        this.setState(prevState => ({
            resultMessageClass: isMessageModal ? id : prevState.resultMessageClass,
            showFeedbackDetailModal: !this.state.showFeedbackDetailModal,
        }));
    }

    parameters = () => ({ "Filter.UserFeedbackStateType": this.state.selectedTab?.id });

    handleFeedbackModal = () => {
        const { selectedFeedback } = this.state;
        switch (selectedFeedback?.feedbackType) {
            case Enumerations.feedbackTypes.user:
                return selectedFeedback?.employeeId;
            case Enumerations.feedbackTypes.sharedSheetOrder:
                return selectedFeedback?.sharedSheetOrderId;
            case Enumerations.feedbackTypes.announcementContent:
                return selectedFeedback?.announcementContentId;
            case Enumerations.feedbackTypes.sharedSheetOrder:
                return selectedFeedback?.sharedSheetOrderId;
        }
    }

    render() {
        const { isLoading, selectedTabIndex, refreshDate, showFeedbackDetailModal, selectedFeedback, resultMessageClass } = this.state;
        return (<>
            <UserPanelWrapper titleName={getTranslatedRawText("profile.feedback.feedbacks")}
                titleIcon='ns-icon-agency-setting'>
                <NsLoadingProgress visible={isLoading} />

                <NsTab tabs={ConstantsWithTranslation.getFeedbackstatus()}
                    value={selectedTabIndex}
                    onChange={this.tabChanged}>
                </NsTab>

                <NsDataGridWithServiceCall callServiceDate={refreshDate}
                    service={GetProfileUserFeedbacksService}
                    parameters={this.parameters()}
                    hasPagination={true}
                    columnDefs={this.columns} />

            </UserPanelWrapper>
            {showFeedbackDetailModal &&
                <FeedbackModal id={this.handleFeedbackModal()}
                    usedInProfileSetting={true}
                    label={selectedFeedback?.title}
                    feedbackType={selectedFeedback?.feedbackType}
                    closeModal={this.toggleFeedbackModal} />
            }
            {resultMessageClass}
        </>
        )
    }
}
export default UpProfileFeedbackSetting;