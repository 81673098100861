import React from 'react';

import NsModal from '../../../../components/modals/NsModal';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import RegularOrderSaveResult from '../../regularOrders/RegularOrderSaveResult';

class PoModalSuccessful extends React.Component {
    render() {
        const { closeSuccessfulModal } = this.props;
        return (
            <NsModal maxWidth="md"
                showModal={this.props.showModal}
                dialogTitle={getTranslatedRawText('professionalOrder.orderResult')}
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeSuccessfulModal)}>

                <RegularOrderSaveResult isProfessional={true}
                    closeSuccessfulModal={closeSuccessfulModal} />

            </NsModal >
        );
    }
}

export default PoModalSuccessful;