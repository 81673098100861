import React from 'react';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../utilities/CultureHelper';
import NsChooseFile from '../../../../../components/dragFiles/NsChooseFile';
import OrderHelper from '../../../../../utilities/OrderHelper';
import Enumerations from '../../../../../configs/Enumerations';
import MasterFileModal from '../../modals/MasterFileModal';
import UrlHelper from '../../../../../utilities/UrlHelper';
import Links from '../../../../../configs/links/AllLinks';
import ChoosingFileWrapper from './ChoosingFileWrapper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';

class MasterFile extends React.Component {

    constructor(props) {
        super(props);
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = {
            currentFileKey: 0,
            isFront: true,
            printedSide: '',
            masterFiles: [],
            isShowMasterFileModal: false,
            isShowMesageBox: false,
            refreshFilePreviewSummaryDate: null,
            refreshExtendedServiceSummaryDate: null,
            toggleTourDate: null,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => {
        this.getMasterFilesInfo();
        this.setState({ printedSide: OrderHelper.getPrintedSide(this.routeId)?.key });
    }

    openMasterFileModalClicked = (isFront) => {
        if (!isFront && isEmpty(this.state.masterFiles.frontSideFileInfo))
            this.setState({ isShowMesageBox: true });
        else
            this.setState({ isShowMasterFileModal: true, isFront, toggleTourDate: new Date() });
    }

    closeMasterFileModalClicked = () => this.setState({ isShowMasterFileModal: false });

    saveMasterFileModalClicked = (selectedFile, suggestedFiles) => {
        if (!!selectedFile) {
            OrderHelper.updateFilesInStorages(this.routeId, this.state.currentFileKey, selectedFile, suggestedFiles, this.state.isFront, this.updateFilesCallback);
        } else
            this.closeMasterFileModalClicked();
    }

    updateFilesCallback = () => {
        this.getMasterFilesInfo();
        this.setState({ refreshExtendedServiceSummaryDate: new Date() });
        this.closeMasterFileModalClicked();
    }

    getProductAdvancePriceCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            OrderHelper.setFileSizeAndOrderPrices(this.routeId, resultData);
            this.setState({ refreshExtendedServiceSummaryDate: new Date() });
            this.closeMasterFileModalClicked();
        } else {
            this.setState({ resultMessageClass: messageClassModal });

        }
    }

    getMasterFilesInfo = () => OrderHelper.getMasterFilesWithFullInfo(this.routeId, (masterFiles) => {
        this.setState({ masterFiles: masterFiles[0], refreshFilePreviewSummaryDate: new Date() });
    });

    render() {
        if (!OrderHelper.isValidOrder(this.routeId)) {
            this.props.history.replace(getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts));
            return null;
        }
        const { masterFiles, isFront, printedSide, currentFileKey, isShowMasterFileModal, refreshExtendedServiceSummaryDate,
            refreshFilePreviewSummaryDate, isShowMesageBox, toggleTourDate } = this.state;

        return (
            <ChoosingFileWrapper otherProps={this.props}
                routeId={this.routeId}
                ChoosingFileCurrentStep={currentFileKey}
                toggleTourDate={toggleTourDate}
                refreshExtendedServiceSummaryDate={refreshExtendedServiceSummaryDate}
                refreshFilePreviewSummaryDate={refreshFilePreviewSummaryDate} >

                <NsChooseFile
                    printedSide={printedSide}
                    uploadFileClicked={this.openMasterFileModalClicked}
                    file={masterFiles} />

                {isShowMasterFileModal &&
                    (<MasterFileModal
                        showModal={isShowMasterFileModal}
                        isFront={isFront}
                        routeId={this.routeId}
                        saveMasterFileModalClicked={this.saveMasterFileModalClicked}
                        closeMasterFileModalClicked={this.closeMasterFileModalClicked} />)
                }

                {isShowMesageBox &&
                    <NsMessageClassModal
                        show={isShowMesageBox}
                        messageItems={[{
                            messageText: getTranslatedRawText("common.messages.selectFrontSide"),
                            messageType: Enumerations.messageType.info
                        }]} />
                }

                {this.state.resultMessageClass}
            </ChoosingFileWrapper >
        );
    }
}

export default MasterFile;