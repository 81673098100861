import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '../../../../assets/sass/views/singlePages/bases/productList/SpBasesProductList.scss';
import SpBasesCategoryCarousel from '../carousel/SpBasesCategoryCarousel';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import Links from '../../../../configs/links/AllLinks';
import CommonHelper from '../../../../utilities/CommonHelper';
import NsTypography from '../../../../components/text/NsTypography';
import SpBasesCategoryCard from '../card/SpBasesCategoryCard';
import NsLabelLine from '../../../../components/labels/NsLabelLine';

class SpBasesProductList extends Component {

    constructor(props) {
        super(props);
        this.isMobileDevice = CommonHelper.isMobileDevice();
        this.state = {
            isExpanded: true
        }
    }

    expandHandler = () => { this.setState({ isExpanded: !this.state.isExpanded }) }

    render() {
        const { categoryList, title, subTitle, text, colClassName, id } = this.props;
        return (
            <Col className='mt-3 d-flex flex-wrap flex-column align-self-stretch justify-content-between' id={id}>
                <Row className='mb-4'>
                    <Col>
                        <NsLabelLine primaryTitle={title}
                            secondaryTitle={subTitle}
                            linkTo={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts, { 'search-input': title })}
                            smallSize={true}
                        />
                        <NsTypography className={clsx('special-text-color font-size-12', !this.state.isExpanded || !this.isMobileDevice ? '' : 'product-group-info')}>{text}</NsTypography>
                    </Col>
                    {this.isMobileDevice &&
                        <button className='d-flex mt-3 border-0 background-color' onClick={this.expandHandler}>
                            <div className='font-size-14 text-color fw-bold'>{getTranslatedRawText('common.readMore')}</div>
                            <i className={clsx('text-color font-size-24', this.state.isExpanded ? 'ns-icon-expand-down' : 'ns-icon-expand-up')}></i>
                        </button>}
                </Row>
                <Row>
                    {!CommonHelper.isMobileDevice() ?
                        categoryList.map(item =>
                            <Col className={clsx('mt-2', colClassName)} ><SpBasesCategoryCard img={item.img} title={item.title} /></Col>
                        )
                        :
                        <SpBasesCategoryCarousel isStore={true} products={categoryList} />
                    }

                </Row>
            </Col>
        )
    }
}

export default SpBasesProductList;