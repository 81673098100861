import React, { useEffect, useState } from 'react';

import { GetCityNamesService, GetStateNamesService } from '../../../../services/common/CommonService';
import { GetAgencyListService } from '../../../../services/adminPanel/AdminAgenciesService';
import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../bases/ApBaseSearchButtons';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';

const ApAgenciesSearch = (props) => {

    const [agencyName, setAgencyName] = useState('');
    const [agencyNameList, setAgencyNameList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedState, setSelectedState] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [stateNames, setStateNames] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cityNames, setCityNames] = useState([]);
    const [resultMessageClass, setResultMessageClass] = useState('');

    useEffect(() => {
        hasAccess([UserClaimEnumerations.getApAgencyBasicDetails]) && getAgencyNameList();
    }, [])

    const getAgencyNameList = () => {
        setIsLoading(true);
        GetAgencyListService({}, getAgencyNameListCallback);
    }

    const getAgencyNameListCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError)
            setAgencyNameList(resultData);
    }

    const searchFields = [
        hasAccess([UserClaimEnumerations.getApAgencyBasicDetails]) && {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.dataGridColumn.agencyName'),
            fieldName: 'agencyName',
            disabled: props.isLoading || isLoading,
            value: agencyName,
            onChange: (value) => setAgencyName(value),
            items: agencyNameList,
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('adminPanel.communicationChannel.phoneNumber'),
            fieldName: 'phoneNumber',
            value: phoneNumber,
            onChange: (value) => setPhoneNumber(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.state.state'),
            fieldName: 'selectedState',
            value: selectedState,
            onChange: (value) => setSelectedState(value),
            items: stateNames || [],
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.city.city'),
            fieldName: 'selectedCity',
            value: selectedCity,
            disabled: isEmpty(selectedState),
            onChange: (value) => setSelectedCity(value),
            items: cityNames || [],
        },
    ]

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    useEffect(() => {
        GetStateNamesService(Enumerations.countryId.iran, getStateNamesCallback);
    }, [])

    const getStateNamesCallback = (resultData, result, messageClassModal) => {
        if (!result?.hasError)
            setStateNames(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    useEffect(() => {
        setSelectedCity([]);
    }, [selectedState])

    useEffect(() => {
        !!selectedState?.key && GetCityNamesService(selectedState?.key, getCityNamesCallback);
    }, [selectedState?.key])

    const getCityNamesCallback = (resultData, result, messageClassModal) => {
        if (!result?.hasError)
            setCityNames(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const searchClicked = () => {
        let data = {
            agencyName: agencyName.value,
            phoneNumber,
            selectedState,
            selectedCity
        }
        CommonHelper.safeFunctionCall(props.searchClicked, data);
    }

    const clearButtonClicked = () => {
        setAgencyName('');
        setPhoneNumber('');
        setSelectedState([]);
        setSelectedCity([]);
        CommonHelper.safeFunctionCall(props.searchClicked, {});
    }

    const searchValues = {
        agencyName,
        phoneNumber,
        selectedState,
        selectedCity
    }

    return (
        <div onKeyDown={handleKeyDown}>
            <NsAdvanceSearch searchOptions={searchFields}
                searchValues={searchValues} mdCol={3} smCol={6} />
            <ApBaseSearchButtons searchClicked={searchClicked}
                clearButtonClicked={clearButtonClicked}
                isLoading={props.isLoading} />
            {resultMessageClass}
        </div>
    );
};

export default React.memo(ApAgenciesSearch);