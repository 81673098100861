import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';

import NsImage from '../../../../../../components/images/NsImage';
import NsLabelBox from '../../../../../../components/labels/NsLabelBox';
import NsModal from '../../../../../../components/modals/NsModal';
import NsSkeleton from '../../../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../../../components/text/NsTypography';
import Enumerations from '../../../../../../configs/Enumerations';
import { GetOrderProductDetailService } from '../../../../../../services/adminPanel/AdminOrderService';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { GetProductDetailService } from '../../../../../../services/product/ProductService';
import NsCard from '../../../../../../components/cards/NsCard';
import DateHelper from '../../../../../../utilities/DateHelper';
import { GetApProductContentDetailByIdService } from '../../../../../../services/adminPanel/AdminProductContentService';
import NsHtmlToReact from '../../../../../../components/htmlToReact/NsHtmlToReact';

const ApOrderProductDetailModal = (props) => {

    const { selectedOrder, selectedPprId, isUseInProductContentModal } = props;
    const [productDetails, setProductDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        if (isUseInProductContentModal) {
            !!selectedPprId
                ? GetApProductContentDetailByIdService(selectedPprId, getOrderProductDetailCallback)
                : GetProductDetailService(selectedOrder, getOrderProductDetailCallback);
        } else GetOrderProductDetailService(selectedOrder?.id, getOrderProductDetailCallback);
    }, []);

    const getOrderProductDetailCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError)
            setProductDetails(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const mapProductLabel = () => {
        return <div className='d-flex flex-wrap gap-2'>
            <NsLabelBox label={getTranslatedRawText('adminPanel.orders.printedSide')}
                value={productDetails?.printedSide.map((printSide, index) =>
                    <span key={index}
                        className={printSide?.key == selectedOrder?.twoSidePrintingType ? 'fw-bold' : 'outline-color'}>
                        {printSide?.value + (index + 1 !== productDetails?.printedSide.length ? ' - ' : '')}
                    </span>
                )} />
            <NsLabelBox label={getTranslatedRawText('adminPanel.orders.turnaround')}
                value={productDetails?.turnaround.map((turnaround, index) =>
                    <span key={index}
                        className={turnaround?.key == selectedOrder?.turnaroundType ? 'fw-bold' : 'outline-color'}>
                        {turnaround?.value + (index + 1 !== productDetails?.turnaround.length ? ' - ' : '')}
                    </span>
                )} />
            <NsLabelBox label={getTranslatedRawText('adminPanel.orders.circulation')}
                value={productDetails?.printCirculations.map((circulation, index) =>
                    <span key={index}
                        className={circulation?.key == productDetails?.productId ? 'fw-bold' : 'outline-color'}>
                        {circulation?.value + (index + 1 !== productDetails?.printCirculations.length ? ' - ' : '')}
                    </span>
                )}
            />
            <NsLabelBox label={getTranslatedRawText('adminPanel.orders.filmFile')}
                value={productDetails?.filmCount} />
            {!!productDetails?.extendedService &&
                <NsLabelBox label={getTranslatedRawText('regularOrder.extendedService.extendedServices')}
                    value={productDetails?.extendedService} />}
            <NsLabelBox label={getTranslatedRawText('adminPanel.orders.isActive')}
                value={getTranslatedRawText(productDetails?.isActive ? 'adminPanel.orders.active' : 'adminPanel.orders.inactive')} />
            <NsLabelBox label={getTranslatedRawText('adminPanel.orders.statusInWebsite')}
                value={getTranslatedRawText(productDetails?.isActiveRemote ? 'adminPanel.orders.active' : 'adminPanel.orders.inactive')} />
            <NsLabelBox label={getTranslatedRawText('adminPanel.orders.statusInNas')}
                value={getTranslatedRawText(productDetails?.isActiveLocal ? 'adminPanel.orders.active' : 'adminPanel.orders.inactive')} />
            <NsLabelBox label={getTranslatedRawText('adminPanel.orders.category')}
                value={productDetails?.category} />
        </div>
    }

    const createBodyOfTable = (id) => {
        let row = productDetails?.printingProcessTime.filter(item => item.key === id);
        return row?.map(item => <td className='border'>{DateHelper.calcProcessHoursAndDays(item?.processHours)}</td>)
    }

    return (
        <NsModal showModal={true}
            closeModal={props.closeModal}
            dialogClassName="modal-choose-printing-files"
            dialogTitle={getTranslatedRawText('adminPanel.orders.productDetail')}
            maxWidth="md">
            {isLoading
                ? <Col xs={6}>
                    <NsSkeleton isVisible={true}
                        variant={[Enumerations.skeleton.text,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect]} />
                </Col>
                : <Row>
                    <Col md={5}>
                        <div className='d-flex flex-column'>
                            <NsTypography className='fw-bold'>
                                {productDetails?.productName}
                            </NsTypography>
                            <NsImage className='rounded-3 mt-2 w-100'
                                src={CommonHelper.createImageUrl(productDetails?.imageUrl)}
                                imgAlt="components.imagesAlt.uploadedImage"
                            />
                            <div className='font-size-12 mt-2'>
                                <NsHtmlToReact>{CommonHelper.getContentFromBodyTag(productDetails?.description)}</NsHtmlToReact>
                            </div>
                        </div>
                    </Col>
                    <Col md={7}>
                        {mapProductLabel()}
                        <NsCard className='mt-3 p-3 pb-0 background-element-color'>
                            <div className='mb-3'>
                                {getTranslatedRawText('product.detail.processTimeNotice')}
                            </div>
                            <Table>
                                <thead className='border'>
                                    <tr>
                                        <th className='text-center text-nowrap background-element-color border'>{getTranslatedRawText('regularOrder.header.printedSide')}</th>
                                        {productDetails?.turnaround.some(item => item.key === Enumerations.turnaround.normal) && <th className='text-center text-nowrap background-element-color border'>{getTranslatedRawText('regularOrder.header.normal')}</th>}
                                        {productDetails?.turnaround.some(item => item.key === Enumerations.turnaround.express) && <th className='text-center text-nowrap background-element-color border'>{getTranslatedRawText('regularOrder.header.express')}</th>}
                                    </tr>
                                </thead>
                                <tbody className="background-white-color">
                                    {productDetails?.printedSide.map(item =>
                                        <tr>
                                            <td className='border'>{item.value}</td>
                                            {createBodyOfTable(item.key)}
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </NsCard>
                    </Col>
                </Row>
            }

            {resultMessageClass}

        </NsModal >
    );
}

export default React.memo(ApOrderProductDetailModal);