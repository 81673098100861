import clsx from 'clsx';
import React, { useState } from 'react';

import { ApPaymentAuditImportedFileDetailContent } from './ApPaymentAuditImportedFileDetailModal';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApPaymentAuditHistoryContent from './ApPaymentAuditHistoryContent';
import ApPaymentAuditAttempContent from './ApPaymentAuditAttempContent';
import ApPaymentAuditDetailStepper from './ApPaymentAuditDetailStepper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';

const ApPaymentAuditDetailModal = (props) => {

    const { selectedAuditId, paymentGatewayType } = props;
    const [activeState, setActiveState] = useState(1);
    const [args, setArgs] = useState();

    const updateActiveState = (activeState, args) => {
        setActiveState(activeState);
        setArgs(args);
    }

    const mapStepperContents = () => {
        switch (activeState) {
            case Enumerations.auditDetailStepper.attempts: return <ApPaymentAuditAttempContent selectedAuditId={selectedAuditId} updateActiveState={updateActiveState} />
            case Enumerations.auditDetailStepper.history: return <ApPaymentAuditHistoryContent selectedAuditId={selectedAuditId} updateActiveState={updateActiveState} />
            case Enumerations.auditDetailStepper.details: return <ApPaymentAuditImportedFileDetailContent {...args} paymentGatewayType={paymentGatewayType}
                updateActiveState={updateActiveState} />
        }
    }

    const setDialogTitle = () => {
        switch (activeState) {
            case Enumerations.auditDetailStepper.attempts: return 'adminPanel.audit.attempts';
            case Enumerations.auditDetailStepper.history: return 'adminPanel.audit.history';
            case Enumerations.auditDetailStepper.details: return 'adminPanel.audit.details';
        }
    }

    return (<NsModal maxWidth='md'
        showModal={true}
        closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
        noSpace={true}
        dialogTitle={getTranslatedRawText(setDialogTitle())} >

        {props.isAdvanceMode &&
            <div className={clsx('p-2', CommonHelper.isMobileDevice() ? 'w-100' : 'w-50')}>
                <ApPaymentAuditDetailStepper updateActiveState={updateActiveState}
                    activeState={activeState} />
            </div>
        }

        {mapStepperContents()}

    </NsModal >
    );
}

export default React.memo(ApPaymentAuditDetailModal);