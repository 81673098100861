import React from 'react';

import ProfileOfficialInvoiceModal from './ProfileOfficialInvoiceModal';
import { getCurrentCulture, getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import NsHtmlToReact from '../../../components/htmlToReact/NsHtmlToReact';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import OfficialInvoiceRulesModal from './OfficialInvoiceRulesModal';
import NsTypography from '../../../components/text/NsTypography';
import NsCheckbox from '../../../components/inputs/NsCheckbox';
import StorageHelper from '../../../utilities/StorageHelper';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import Enumerations from '../../../configs/Enumerations';
import Links from '../../../configs/links/AllLinks';

class PaymentOfficialInvoiceModal extends React.Component {

    constructor(props) {
        super(props);
        this.actionButtons = [
            { icon: CommonHelper.getExpandIconByCulture(), className: 'secondary-text', label: 'profile.orderCart.vatRules', onClicked: this.toggleOfficialInvoiceRulesModal },
            { icon: CommonHelper.getNextThinIconByCulture(), className: 'primary', label: 'payment.ipg.completeProfileAndPay', onClicked: () => this.toggleProfileOfficialInvoiceModal(<></>) },
        ];
        this.isCompany = CurrentUserHelper.getIsCompany();
        this.state = {
            showOfficialInvoiceRulesModal: false,
            showProfileOfficialInvoiceModal: false,
            isNotOfficialInvoiceSelected: false,
            resultMessageClass: (<></>)
        }
    }

    closeModal = (closeWithPayment) => CommonHelper.safeFunctionCall(this.props.closeModal, closeWithPayment);

    toggleOfficialInvoiceRulesModal = () => this.setState({ showOfficialInvoiceRulesModal: !this.state.showOfficialInvoiceRulesModal });

    toggleProfileOfficialInvoiceModal = (resultMessageClass) => this.setState({ showProfileOfficialInvoiceModal: !this.state.showProfileOfficialInvoiceModal, resultMessageClass });

    notOfficialInvoiceClicked = () => this.setState({ isNotOfficialInvoiceSelected: !this.state.isNotOfficialInvoiceSelected });

    dontShowMessageClicked = (checked) => StorageHelper.saveInStorage(Enumerations.storage.dontShowPaymentOfficialInvoiceModal, checked);

    render() {
        const { showOfficialInvoiceRulesModal, showProfileOfficialInvoiceModal, isNotOfficialInvoiceSelected, resultMessageClass } = this.state;
        const { isOnlinePayment } = this.props;
        return (
            <NsModal maxWidth="sm"
                dialogTitle={getTranslatedRawText('profile.userInfo.profileInformationForVatInvoice')}
                showModal={true}
                closeModal={this.closeModal}>

                <div className='d-flex flex-column border rounded p-2 mb-2'>
                    <NsTypography variant='inherit' className='font-size-14 fw-bold mb-2'>
                        {getTranslatedRawText('payment.ipg.iNeedOfficialInvoice')}
                    </NsTypography>
                    <NsTypography variant='inherit' className='font-size-12 text-justify mb-2'>
                        {getTranslatedRawText('payment.ipg.iNeedOfficialInvoiceDescription')}
                    </NsTypography>
                    <NsTypography variant='inherit' className='font-size-12 fw-bold text-justify mb-3'>
                        {getTranslatedRawText('payment.ipg.clickOfficialInvoice')}
                    </NsTypography>
                    <div className='d-flex justify-content-end gap-2'>
                        {this.actionButtons.map((button, index) =>
                            <NsButton key={index}
                                label={button.label}
                                startIcon={button.icon}
                                className={button.className}
                                size='small'
                                onClick={button.onClicked} />
                        )}
                    </div>
                </div>
                {this.isCompany
                    ? <MandatoryOfficialInvoiceBox toggleProfileOfficialInvoiceModal={this.toggleProfileOfficialInvoiceModal} />
                    : <NoOfficialInvoiceBox closeModal={this.closeModal}
                        dontShowMessageClicked={this.dontShowMessageClicked}
                        notOfficialInvoiceClicked={this.notOfficialInvoiceClicked}
                        isNotOfficialInvoiceSelected={isNotOfficialInvoiceSelected}
                        isOnlinePayment={isOnlinePayment} />
                }

                {showOfficialInvoiceRulesModal &&
                    <OfficialInvoiceRulesModal closeModal={this.toggleOfficialInvoiceRulesModal} />
                }
                {showProfileOfficialInvoiceModal &&
                    <ProfileOfficialInvoiceModal
                        closeModal={this.toggleProfileOfficialInvoiceModal} />
                }

                {resultMessageClass}

            </NsModal >
        )
    }
}

function NoOfficialInvoiceBox(props) {
    const { isNotOfficialInvoiceSelected, isOnlinePayment } = props;
    return (
        <div className='d-flex flex-column border rounded p-2'>

            <div className='d-flex align-items-center'>
                <NsCheckbox label={<span className="font-size-14 fw-bold">
                    {getTranslatedRawText('payment.ipg.iDontNeedOfficialInvoice')}
                </span>
                }
                    value={isNotOfficialInvoiceSelected}
                    onChange={props.notOfficialInvoiceClicked} />
                <i className={isNotOfficialInvoiceSelected ? 'ns-icon-expand-up mt-0 fs-5' : 'ns-icon-expand-down fs-5'} />
            </div>
            <div className={isNotOfficialInvoiceSelected ? 'd-flex flex-column mt-3' : 'd-none'} >

                <NsTypography variant='inherit' className='font-size-12 fw-bold mb-2'>
                    {getTranslatedRawText('payment.ipg.byConfirmingTitle')}
                </NsTypography>
                <ul style={{ listStyleType: "disc" }}>
                    <li>
                        <NsTypography variant='inherit' className='font-size-12 text-justify mb-2'>
                            {getTranslatedRawText('payment.ipg.byConfirmingItem1')}
                        </NsTypography>
                    </li>
                    <li>
                        <NsTypography variant='inherit' className='font-size-12 text-justify mb-2'>
                            {getTranslatedRawText('payment.ipg.byConfirmingItem2')}
                        </NsTypography>
                    </li>
                </ul>

                <div className='d-flex justify-content-between align-items-center'>
                    <NsCheckbox value={false}
                        onChange={props.dontShowMessageClicked}
                        label={<span className="font-size-12">
                            {getTranslatedRawText('profile.pay.modal.iWasNoticed')}
                        </span>
                        }
                    />
                    <NsButton label={isOnlinePayment ? 'profile.orderCart.onlinePayment' : 'profile.orderCart.useMyRemainingLimit'}
                        className='primary-outline'
                        size='small'
                        startIcon={isOnlinePayment ? 'ns-icon-payments' : 'ns-icon-increase-credit'}
                        onClick={() => props.closeModal(true)} />
                </div>
            </div>
        </div>
    );
}

function MandatoryOfficialInvoiceBox() {
    return (
        <div className='d-flex flex-column gap-2 background-color rounded border p-2'>
            <div className='d-flex align-items-center'>
                <i className='ns-icon-information fs-4' />
                <NsTypography variant='inherit' className="font-size-14 fw-bold">
                    {getTranslatedRawText('payment.ipg.mandatoryOfficialInvoiceTitle')}
                </NsTypography>
            </div>
            <NsTypography variant='inherit' className="font-size-12 text-justify">
                {getTranslatedRawText('payment.ipg.mandatoryOfficialInvoiceDescription1')}
            </NsTypography>
            <NsTypography variant='inherit' className="font-size-12 text-justify">
                <NsHtmlToReact>
                    {CommonHelper.stringFormat(getTranslatedRawText('payment.ipg.mandatoryOfficialInvoiceDescription2'), getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationSetting))}
                </NsHtmlToReact>
            </NsTypography>
        </div>
    );
}

export default PaymentOfficialInvoiceModal;