import React from 'react';
import AppBar from '@mui/material/AppBar';
import { useDispatch, useSelector } from 'react-redux';

import NsButton from '../../../../components/buttons/NsButton';
import { adminSidebarActionCreators } from '../../../../redux/reducers/AdminSidebarReducer';
import ApHeaderNavbar from './ApHeaderNavbar';

const ApHeader = (props) => {

    const dispatch = useDispatch();

    const store = useSelector(state => {
        return {
            userName: state.currentUser.userFullName,
            isAdminSidebarOpen: state.AdminSidebarReducer.isOpen
        }
    });

    const handleToggler = () => dispatch(adminSidebarActionCreators.toggleAdminSidebar());

    return (
        <div className='admin-panel__header'>
            <AppBar position="fixed"
                className={store.isAdminSidebarOpen ? 'admin-panel__header--open-sidebar' : 'admin-panel__header--close-sidebar'}
                style={{ "--drawerWidth": `${props.width}px`, }} >
                <div className='admin-panel__appbar d-flex align-items-center w-100'>
                    <NsButton startIcon='ns-icon-menu'
                        loading={props?.isLoading}
                        onClick={handleToggler} />

                    <ApHeaderNavbar userName={store.userName} />
                </div>
                {/* <ApHeaderBreadCrumbs /> */}
            </AppBar>

        </div >
    );
}

export default React.memo(ApHeader);