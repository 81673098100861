import clsx from 'clsx';
import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';

import '../../assets/sass/components/cards/NsPageMainContentCard.scss';
import CommonHelper from '../../utilities/CommonHelper';

class NsPageMainContentWrapper extends React.Component {

    onClickHandler = (e) => CommonHelper.safeFunctionCall(this.props.onClick);
    onKeyDownHandler = (e) => CommonHelper.safeFunctionCall(this.props.onKeyDown, e);
    swicthClicked = (e) => e.stopPropagation();

    render() {
        const { className, headerClassName, icon, header, contentClassName, children } = this.props;

        return (
            <Card className={clsx('ns-card-page-main-content border-outline-color-1px w-100', className)}
                variant='outlined'
                onKeyDown={this.onKeyDownHandler}>
                <CardHeader className={clsx('header background-disable-color border-bottom page-title py-1', headerClassName)}
                    onClick={this.onClickHandler}
                    title={
                        <div className='d-flex align-items-center'>
                            {!!icon &&
                                <div>
                                    <i className={clsx('align-middle h2 pe-2', icon)} />
                                </div>
                            }
                            <h1 className='font-size-18 fw-bold mb-0'>{header}</h1>
                        </div>
                    } />
                <CardContent className={contentClassName}>
                    {children}
                </CardContent>
            </Card>
        );
    }
}

export default NsPageMainContentWrapper;