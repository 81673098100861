import Links from "../../configs/links/AllLinks"
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest, PatchRequest, DeleteRequest, PostRequest } from "../bases/BaseService"

const GetStaticPageService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApSpecificStaticPageList), parameters, callback);
};

const PostStaticPageService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApSpecificStaticPageRow), data, callback, showSuccessResponseMessage);
};

const GetStaticPageRowByIdService = (id, parameters, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApSpecificStaticPageRowById), id), parameters, callback);
};

const EditSortStaticPageAdminService = (data, callback) => {
    PatchRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApSortStaticPageAdmin), data, callback);
};

const DeleteStaticPageRowService = (rowId, staticPageId, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApSpecificStaticPageRow), rowId), { StaticPageType: staticPageId }, callback, showSuccessResponseMessage);
};

const EditStaticPageRowService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApSpecificStaticPageRow), id), data, callback, showSuccessResponseMessage);
};

export {
    GetStaticPageService,
    PostStaticPageService,
    GetStaticPageRowByIdService,
    EditSortStaticPageAdminService,
    DeleteStaticPageRowService,
    EditStaticPageRowService
}