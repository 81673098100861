import React from 'react';

import { isEmpty } from '../../utilities/ValidationHelper';

class NsDownloadFile extends React.Component {

    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.file !== prevProps.file)
            if (!isEmpty(this.fileRef.current?.click))
                this.fileRef.current.click();
    }

    render() {
        const { file } = this.props;

        return (
            !!file?.fileDownloadName
                ? <a ref={this.fileRef} target='_blank' href={file?.fileContents}
                    download={file?.fileDownloadName} />
                : <a ref={this.fileRef} target='_blank' href={file?.fileContents} />
        )
    }
}

export default NsDownloadFile;