import React from "react";

import NsButton from "../buttons/NsButton";
import DatePicker, { DateObject } from "react-multi-date-picker";
import '../../assets/sass/components/dateTimePickers/NsDateTimePickers.scss';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { getTranslatedRawText, isEnCulture } from "../../utilities/CultureHelper";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import CommonHelper from "../../utilities/CommonHelper";

class NsDateRangePicker extends React.Component {

    onChange = (dates) => CommonHelper.safeFunctionCall(this.props.onChange, dates);

    onTodayClicked = () => {
        const { dates } = this.props;
        dates?.length > 0 ? this.onChange([dates[0], new Date()]) : this.onChange([new Date()]);
    }

    render() {
        const { dates, buttonColor, onlyMonthPicker, onlyYearPicker } = this.props;
        return <DatePicker range
            className="date-picker"
            numberOfMonths={CommonHelper.isMobileDevice() ? 1 : 2}
            maxDate={new Date()}
            value={dates}
            onlyMonthPicker={onlyMonthPicker}
            onlyYearPicker={onlyYearPicker}
            calendar={isEnCulture() ? '' : persian}
            locale={isEnCulture() ? '' : persian_fa}
            onChange={this.onChange}
            onClose={() => dates?.length > 1}

            plugins={[
                <DatePanel position={CommonHelper.isMobileDevice() ? "bottom" : "right"} />
            ]}

            render={(value, openCalendar) =>
                <NsButton className={buttonColor}
                    size='small'
                    onClick={openCalendar} >
                    {getTranslatedRawText('components.history.from')} {value[0]} {!!value[1] && getTranslatedRawText('components.history.to')} {value[1]}
                </NsButton>
            }
        >
            <div className="d-flex justify-align-start p-2">
                <NsButton label='components.datePicker.today'
                    className='secondary-outline'
                    onClick={this.onTodayClicked} />
            </div>

        </DatePicker>
    }
}

export default NsDateRangePicker;

export const converDates = (dates, onlyMonthPicker, onlyYearPicker) => {
    if (dates.length > 1) {
        if (onlyMonthPicker) {
            let firstDate = new DateObject(new Date(dates[0]));
            let lastDate = new DateObject(new Date(dates[1]));
            firstDate.toFirstOfMonth();
            lastDate.toLastOfMonth();
            if (new Date(lastDate).getTime() > new Date().getTime())
                lastDate = new DateObject();
            dates = [firstDate, lastDate];
        }
        if (onlyYearPicker) {
            let firstDate = new DateObject(new Date(dates[0]));
            let lastDate = new DateObject(new Date(dates[1]));
            firstDate.toFirstOfYear();
            lastDate.toLastOfYear();
            if (new Date(lastDate).getTime() > new Date().getTime())
                lastDate = new DateObject();
            dates = [firstDate, lastDate];
        }
    }

    return dates;
}