import clsx from 'clsx';
import React from 'react';
import NsBreadcrumbs from '../../../components/breadcrumbs/NsBreadcrumbs';
import { getContentListById } from './TraceTreeView';

class ModalTitleRoute extends React.Component {

    breadcrumbItems = (currentFolderId, treeViewFolders, darkMode) => {
        let contentList = getContentListById(currentFolderId, treeViewFolders)

        return contentList.map((link, index) => (
            <span key={index} className={clsx("w-50 mt-4", !darkMode && "white-color")}>
                {link.name}
            </span>
        ));
    }

    render() {
        const { currentFolderId, treeViewFolders, darkMode } = this.props;
        return (
            <NsBreadcrumbs aria-label="breadcrumb"
                maxItems={4}
                className='not-rtl'
                itemsAfterCollapse={3}
                itemsBeforeCollapse={1}
                separator={<span className={clsx("m-0 ", !darkMode && "white-color")}>/</span>} >

                <span className={!darkMode && "white-color"}>(.</span>
                {this.breadcrumbItems(currentFolderId, treeViewFolders, darkMode)}
                <span className={!darkMode && "white-color"}>)</span>

            </NsBreadcrumbs>
        );
    }
}

export default ModalTitleRoute;