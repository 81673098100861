import React, { useState, useEffect } from 'react';

import { isEmpty } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';
import NsHistory from './NsHistory';
import Enumerations from '../../configs/Enumerations';

const NsHistoryWithServiceCall = (props) => {

    const { service, parameters, typeItems, isAdminPanel, hasUserName, hasPagination, refreshDate, tabs, noSearchSection } = props;

    const [rowPerPage, setRowPerPage] = useState(CommonHelper.defaultRowPerPage());
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [history, setHistory] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(!!tabs && tabs[0]);

    useEffect(() => {
        getHistory();
    }, [currentPage, rowPerPage, refreshDate, selectedTabIndex]);

    useEffect(() => {
        setRowPerPage(CommonHelper.defaultRowPerPage());
        setCurrentPage(1);
    }, [selectedTabIndex])

    const paginationButtonClicked = (currentPage) => setCurrentPage(currentPage);

    const rowPerPageChanged = (rowPerPage) => setRowPerPage(rowPerPage);

    const getHistory = (searchData) => {
        if (isEmpty(parameters)) return;
        setIsLoading(true);
        let params = {
            pageLength: rowPerPage.value,
            currentPageIndex: currentPage,
        };
        let websiteStatusParams = {
            ...params,
            ...parameters,
            'Filter.UserIds': searchData?.selectedUser?.userId,
            'Filter.FromStatusTime': searchData?.dateFrom,
            'Filter.ToStatusTime': searchData?.dateTo,
            'Filter.SharedSheetOrderStateTypes': searchData?.type?.id,
        }
        let nasStatusParams = {
            ...params,
            'Filter.Id': selectedTab?.id === Enumerations.sharedSheetOrderStatusHistoryType.websiteStatus ? parameters?.id : parameters?.nasInvoiceId
        }

        switch (selectedTab?.id) {
            case Enumerations.sharedSheetOrderStatusHistoryType.websiteStatus:
                params = websiteStatusParams
                break;
            case Enumerations.sharedSheetOrderStatusHistoryType.nasStatus:
                params = nasStatusParams
                break;
            default:
                params = websiteStatusParams
                break;
        }

        tabs?.length > 0 ? selectedTab?.service(params, getHistoryCallback) : service(websiteStatusParams, getHistoryCallback);
    }

    const getHistoryCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setHistory(resultData?.dataItems);
            setTotalCount(resultData?.totalCount);
            CommonHelper.safeFunctionCall(props.histories, resultData?.dataItems)
        } else {
            setResultMessageClass(messageClassModal);
            setHistory([]);
        }
    }

    const tabChanged = (selectedTabIndex, selectedTab) => {
        setSelectedTabIndex(selectedTabIndex);
        setSelectedTab(selectedTab)
    }

    const selectedHistory = (data) => CommonHelper.safeFunctionCall(props.selectedHistory, data);
    const sheetIdClickedHandler = (data) => CommonHelper.safeFunctionCall(props.sheetIdClicked, data);

    return (
        <>
            <NsHistory history={history}
                hasUserName={hasUserName}
                isAdminPanel={isAdminPanel}
                totalHistoryRows={totalCount}
                currentPage={currentPage}
                rowPerPage={rowPerPage}
                typeItems={typeItems}
                hasPagination={hasPagination}
                isLoadingHistory={isLoading}
                searchClicked={getHistory}
                paginationButtonClicked={paginationButtonClicked}
                rowPerPageChanged={rowPerPageChanged}
                tabs={tabs}
                tabChanged={tabChanged}
                selectedHistory={selectedHistory}
                sheetIdClicked={sheetIdClickedHandler}
                noSearchSection={noSearchSection ? noSearchSection : selectedTab?.id === Enumerations.sharedSheetOrderStatusHistoryType.nasStatus ? true : false}
            />
            {resultMessageClass}
        </>
    );
}

export default React.memo(NsHistoryWithServiceCall);