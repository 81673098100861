import Links from "../../configs/links/AllLinks"
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest, PutRequest, PatchRequest, DeleteRequest, PostRequest } from "../bases/BaseService"

const GetSlidersService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApSliders), parameters, callback);
};

const GetSliderByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApSliderById), id), {}, callback);
};

const PostSliderService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApSlider), data, callback, showSuccessResponseMessage);
};

const EditSliderService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApSlider), id), data, callback, showSuccessResponseMessage);
};

const EditSortSliderService = (data, callback) => {
    PatchRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApSortSlider), data, callback);
};

const DeleteSliderService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApSlider), id), {}, callback, showSuccessResponseMessage);
};

export {
    GetSlidersService,
    GetSliderByIdService,
    PostSliderService,
    EditSliderService,
    EditSortSliderService,
    DeleteSliderService
}