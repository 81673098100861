import React from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Config from '../../../../configs/Config';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import Ads from './Ads';
import ContactInformation from './ContactInformation';
import { isEmpty } from '../../../../utilities/ValidationHelper';

class MiddleFooter extends React.Component {

    linksInformation = [
        {
            id: 1,
            title: getTranslatedRawText('footer.ourCompany'),
            children: ConstantsWithTranslation.getOurCompanyMenus(),
            ariaLabel: 'our Company',
            isExternalLink: false
        },
        {
            id: 2,
            title: getTranslatedRawText('footer.customerService'),
            children: ConstantsWithTranslation.getCustomerServiceMenus(),
            ariaLabel: 'customer Service',
            isExternalLink: false
        },
        {
            id: 3,
            title: getTranslatedRawText('footer.resources'),
            children: ConstantsWithTranslation.getResourcesMenus(),
            ariaLabel: 'resources',
            isExternalLink: false
        },
        {
            id: 4,
            title: getTranslatedRawText('footer.links'),
            children: isEmpty(getConfigObjectByCulture(Config.footerLinks))
                ? []
                : getConfigObjectByCulture(Config.footerLinks),
            ariaLabel: 'links',
            isExternalLink: true
        },
        {
            id: 5,
            title: getTranslatedRawText('footer.news'),
            children: ConstantsWithTranslation.getBlogsAndNewsMenus(),
            ariaLabel: 'news',
            isExternalLink: false
        },
    ]

    createSubMenus = (menus, isExternalLink) => {
        return menus?.map((menu, index) => 
            !isEmpty(menu.label) && <Nav.Item key={index} className="mt-1">
                <Link key={menu.id || index}
                    style={menu.disable && { pointerEvents: 'none', color: '#6c757d' }}
                    className='text-decoration-none font-size-12 white-color'
                    target={(!!menu.link && !!isExternalLink) ? "_blank" : "_self"}
                    to={!!menu.link
                        ? !!isExternalLink
                            ? { pathname: menu.link }
                            : getUrlWithCurrentCulture(menu.baseLink, menu.link)
                        : '#'}>
                    <div className={(this.props.currentPage === menu.id && !isExternalLink) ? 'red-color' : ''}>
                        {getTranslatedRawText(menu.label)}
                    </div>
                </Link>
            </Nav.Item>
        );
    }

    render() {
        return (
            <Container>
                <Row className="top-footer justify-content-center p-0 m-0 pt-4" aria-label='footer'>
                    <Col md={3} className="p-0 m-0 mb-4 mb-md-2 pe-0" aria-label='contact info'>
                        <ContactInformation />
                    </Col>
                    <Col className='p-0 m-0 ps-md-5 ps-lg-2' md={9}>
                        <Row className='p-0 m-0'>
                            {this.linksInformation?.map((item, index) =>
                                <Col key={index} xs={4} md={4} lg={2} className='d-flex justify-content-between p-0 m-0 mb-md-3' >
                                    <Nav className={`d-flex flex-column ${CommonHelper.isMobileDevice() && 'mb-3'}`} key={item.id} aria-label={item.ariaLabel}>
                                        <p className="font-size-14 fw-bold">
                                            {item.title}
                                        </p>
                                        {this.createSubMenus(item.children, item.isExternalLink)}
                                    </Nav>
                                </Col>)}
                            <Col className='p-0 m-0' xs={4} md={4} lg={2}>
                                <Ads />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.props.children}
            </Container>
        );
    }
}

export default MiddleFooter;