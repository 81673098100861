import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import NsButton from "../../../../../components/buttons/NsButton";
import NsTextField from "../../../../../components/inputs/NsTextField";
import NsMenu from "../../../../../components/menu/NsMenu";
import NsTab from "../../../../../components/tabs/NsTab";
import NsModal from "../../../../../components/modals/NsModal";
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseUserSelectModal from '../../../bases/ApBaseUserSelectModal';
import NsStickyButton from "../../../../../components/buttons/NsStickyButton";
import CommonHelper from "../../../../../utilities/CommonHelper";
import ApBaseLabelInfo from "../../../bases/ApBaseLabelInfo";
import { EditClaimValueTypesService, GetClaimValueTypesService } from "../../../../../services/adminPanel/AdminUserService";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import NsCheckboxTreeView from "../../../../../components/treeViews/NsCheckboxTreeView";
import { getRoles, getUserRole, normalizeUserClaimTypes, searchClaim } from "../../../../../utilities/ClaimHelper";
import Enumerations from "../../../../../configs/Enumerations";
import NsSearchInput from "../../../../../components/inputs/NsSearchInput";

const ApAddSupporterUserModal = (props) => {

    const { isAddNewAdmin, user } = props;

    const [selectedUser, setSelectedUser] = useState(user);
    const [selectedRole, setSelectedRole] = useState();
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [userClaimTypes, setUserClaimTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showUserSelectModal, setShowUserSelectModal] = useState(false);
    const [closeMenuDate, setCloseMenuDate] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState((<></>));

    useEffect(() => {
        selectedUser?.userId && getClaimValueTypes();
    }, [selectedRole]);

    useEffect(() => {
        setSelectedTabIndex(0);
        setSelectedTab(tabs[0]);
    }, [tabs]);

    const getClaimValueTypes = () => {
        setIsLoading(true);
        let parameters = {
            userId: selectedUser?.userId,
            'roleIds[0]': selectedRole?.id,
        };
        GetClaimValueTypesService(parameters, getClaimValueTypesCallback);
    }

    const getClaimValueTypesCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            if (resultData?.length > 0) {
                setUserClaimTypes(resultData.map(data => ({ roleId: data.roleId, data: normalizeUserClaimTypes(data?.claimValueTypes) })));
                setTabs(resultData.map(result => ({ id: result.roleId, label: getUserRole(result.roleId) })));
                setSelectedTab(resultData[0].roleId);
            }
        } else
            setResultMessageClass(messageClassModal);
        setIsLoading(false);
    }

    const editClaimValueTypesCallback = (resultData, result, messageClassModal) => {
        setResultMessageClass(messageClassModal);
        setIsLoading(false);
    }

    const handlers = {

        selectUserButtonClicked: () => setShowUserSelectModal(true),

        userSelected: (user) => {
            setSelectedUser(user);
            setShowUserSelectModal(false);
        },

        selectedRoleClicked: (role) => {
            setSelectedRole(role);
            setUserClaimTypes([]);
            setCloseMenuDate(new Date());
        },

        tabChanged: (newValue, selectedTab) => {
            if (newValue !== selectedTabIndex) {
                setSelectedTabIndex(newValue);
                setSelectedTab(selectedTab);
            }
        },

        searchValueChanged: (value) => {
            setSearchValue(value);
        },

        searchFormClicked: () => {
            searchClaim(searchValue, userClaimTypes.find(item => item.roleId === selectedTab.id)?.data);
            setUserClaimTypes([...userClaimTypes])
        },

        closeTabItemClicked: (tab) => {
            setTabs(tabs.filter(item => item.id !== tab.id));
            setUserClaimTypes(userClaimTypes.filter(item => item.roleId !== tab.id));
        },

        updateClaimChecked: (item, selectedRoleCheked) => {
            if (!isEmpty(selectedRoleCheked)) {
                let temp = userClaimTypes.filter(type => type?.roleId !== selectedRoleCheked?.roleId);
                setUserClaimTypes([...temp, selectedRoleCheked]);
            }
        },

        saveButtonClicked: () => {
            // const temp = Object.assign([], userClaimTypes);
            // let temp = userClaimTypes.slice(0);
            let temp = [...userClaimTypes];
            temp.map(item => {
                item.claimValueTypeIds = item.data ? CommonHelper.recursiveSeparator(item.data).parentLessItems.filter(item => item.checked)?.map(item => item.id.toString()) : []
                // delete item.data;
                // delete item.checked
            })
            setIsLoading(true);
            EditClaimValueTypesService(selectedUser?.userId, userClaimTypes, editClaimValueTypesCallback, true);
        }
    }

    const { selectUserButtonClicked, userSelected, tabChanged, closeTabItemClicked,
        selectedRoleClicked, saveButtonClicked, updateClaimChecked, searchValueChanged, searchFormClicked } = handlers;

    const mapRoles = () => {
        return getRoles().map(role =>
            <div key={role.id}
                className='w-100 light p-2 my-1 border-bottom cursor-pointer'
                onClick={() => selectedRoleClicked(role)}>
                {role.label}
            </div>
        );
    }

    const stickyButtons = [
        CommonHelper.createStickyButtonItem('common.save', 'ns-icon-save', saveButtonClicked, 'primary', isEmpty(selectedUser) && isAddNewAdmin),
        CommonHelper.createStickyButtonItem('common.cancel', 'ns-icon-close', props.closeModal, 'primary-outline')
    ];

    return <NsModal showModal={true}
        maxWidth='md'
        noSpace={!isAddNewAdmin}
        dialogTitle={getTranslatedRawText(isAddNewAdmin ? "adminPanel.usersInfo.newAdmin" : "adminPanel.usersInfo.editAccess")}
        closeModal={props.closeModal}
        actions={<NsStickyButton stickyButtons={stickyButtons} isLoading={isLoading} />}>

        {!isAddNewAdmin &&
            <div className='background-color px-3 py-2'>
                <ApBaseLabelInfo info={user} isLoading={false} />
            </div>
        }

        <div className={!isAddNewAdmin ? "p-3" : ''}>
            <Row className="align-items-center">
                <Col md={5}>
                    {isAddNewAdmin &&
                        <NsTextField label={getTranslatedRawText('adminPanel.usersInfo.user')}
                            value={selectedUser?.phoneNumber}
                            className='w-75 mb-2 '
                            disabled={true}
                            endAdornment={<NsButton className='light-circle'
                                onClick={selectUserButtonClicked}
                                startIcon="ns-icon-more" />} />
                    }
                </Col>
                <Col md='auto' className="ms-auto">
                    <NsMenu closeMenuDate={closeMenuDate}
                        buttonClassName='primary'
                        disabledOpenMenu={isEmpty(selectedUser)}
                        buttonChildren={<div className="d-flex align-items-center gap-1">
                            <i className="ns-icon-add" />
                            {getTranslatedRawText('adminPanel.buttons.addRole')}
                            <i className="ns-icon-expand-down" />
                        </div>}
                        transformOrigin={{ vertical: "top", horizontal: "left" }} >

                        {mapRoles()}
                    </NsMenu >
                </Col>
            </Row>

            {!isEmpty(tabs) && <NsTab tabs={tabs}
                onChange={tabChanged}
                value={selectedTabIndex}
                hasCloseButton={true}
                closeTabItemClicked={closeTabItemClicked}
                className='mt-2' />
            }
            <div className="border">
                {selectedTab?.id === Enumerations.role.supporter
                    ? !isEmpty(userClaimTypes) &&
                    <div className="pb-2">
                        <NsSearchInput hasSearchIcon={true}
                            className='border-bottom'
                            value={searchValue}
                            searchFormClicked={searchFormClicked}
                            handleSearchTextChanged={searchValueChanged} />
                        <NsCheckboxTreeView items={userClaimTypes.find(item => item.roleId === selectedTab.id)?.data}
                            roleId={selectedTab.id}
                            isReturnData={true}
                            checked={userClaimTypes.find(item => item.roleId === selectedTab.id)?.checked}
                            onChange={updateClaimChecked} />
                    </div>
                    : <div className="p-2">
                        {getTranslatedRawText(selectedTab?.id === Enumerations.role.admin
                            ? 'adminPanel.usersInfo.adminRoleMessage'
                            : selectedTab?.id === Enumerations.role.agency
                                ? 'adminPanel.usersInfo.agencyRoleMessage'
                                : selectedTab?.id === Enumerations.role.businessPartner
                                    ? 'adminPanel.usersInfo.businessPartnerRoleMessage'
                                    : ''
                        )}
                    </div>
                }
            </div>
        </div>

        {
            showUserSelectModal &&
            <ApBaseUserSelectModal showModal={true}
                userSelected={userSelected}
                closeModal={() => setShowUserSelectModal(false)} />
        }

        {resultMessageClass}
    </NsModal >

}

export default React.memo(ApAddSupporterUserModal);