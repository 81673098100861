const FileManagerTranslationEn = {
    fileManager: {
        root: "root",
        admin: {
            announcement: 'Announcement',
            blog: 'Blog',
            blogCategory: 'Blog Category',
            news: 'news',
            newsCategory: 'News Category',
            promotion: 'Promotion',
            slider: 'Slider',
        },
        common: {
            undoAllChanges: 'Undo All Changes',
            fileName: 'File Name',
            dimension: 'Dimension',
            fileSize: 'File Size',
            fileType: 'File Type',
            file: 'File',
            folder: 'Folder'
        },
        headerActionButtons: {
            newFolder: 'New Folder',
            uploadFile: 'Upload File',
            delete: 'Delete',
            deleteSelectedFiles: 'Delete Selected Files',
            rename: 'Rename',
            refresh: 'Refresh',
            editFile: 'Edit File',
            selectAllFiles: 'Select All',
            deselectAllFiles: 'Deselect All'
        },
        headerUsageSpace: {
            usedCapacity: '{0} MB / {1} MB',
        },
        mainSectionDetail: {
            preview: 'Preview',
            noItemSelected: 'No Item Selected',
            detail: 'Detail:',
            fileName: 'File Name:',
            folderName: 'Folder Name:',
            resolution: 'Resolution:',
            dimension: 'Dimension:',
            fileSize: 'File Size:',
            editFile: 'Edit File',
            fileDimension: '{0}×{1} mm',
            fileResolution: '{0} DPI'
        },
        modals: {
            create: {
                inputLabel: 'Folder Name',
                enterInput: 'Enter folder name',
                submit: 'Create',
                title: 'Create New Folder ',
            },
            uploadFile: {
                title: 'Upload File',
                closeModalAlert: 'If you close modal, files that have not yet been uploaded will be canceled. Are you sure to close the modal?'
            },
            rename: {
                title: 'Rename ({0})',
                inputLabel: 'New name',
                enterInput: 'Enter New name',
                submit: 'Change',
                isLoading: 'is loading...',
            },
            editFile: {
                position: 'Position',
                title: 'Edit Image File ({0})',
                resize: 'Resize',
                rotate: 'Rotate',
                rotate90: '90 CW',
                rotate270: '90 CCW',
                rotate180: '180 CW',
                flip: 'Flip',
                horizontallyFlip: 'Horizontally',
                verticallyFlip: 'Vertically',
                width: 'Width',
                height: 'Height',
                mm: 'mm',
                apply: 'Apply'
            },
        },
        tour: {
            PoFileManager: 'Here You Can Manage Your Files And Upload New File',
            usageSpace: 'Usable Space',
            breadcrumbButtons: 'to Upload Your Files Click Here',
            selectedItemPreview: 'Here You See a Preview of Your File',
            actionButton: 'Here You Can Delete , Edit and Rename Your File'
        }
    }
};
export default FileManagerTranslationEn;