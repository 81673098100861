import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { NsTransition } from './NsTransition';
import NsButton from '../buttons/NsButton';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsTypography from '../text/NsTypography';
import Enumerations from '../../configs/Enumerations';

const isMobileDevice = CommonHelper.isMobileDevice();

class NsAlertModal extends React.Component {

    cancelClicked = () => CommonHelper.safeFunctionCall(this.props.cancelClicked);

    confirmClicked = () => CommonHelper.safeFunctionCall(this.props.confirmClicked);

    closeDialogClicked = () => CommonHelper.safeFunctionCall(this.props.closeDialogClicked);

    onKeyUp = (e) => {
        e.stopPropagation();
        e.keyCode == Enumerations.keyboard.enter && CommonHelper.safeFunctionCall(this.props.confirmClicked)
        e.keyCode == Enumerations.keyboard.esc && CommonHelper.safeFunctionCall(this.props.cancelClicked)
    }

    render() {
        const { isOpen,
            alertModalTitle,
            children,
            confirmLabel,
            cancelLabel,
            confirmStartIcon,
            cancelStartIcon,
            confirmClassName,
            cancelClassName,
            closeDialogClicked,
            isLoading
        } = this.props;
        return (
            <Dialog open={isOpen}
                fullWidth={true}
                onKeyUp={(e) => this.props.activeEnter && this.onKeyUp(e)}
                TransitionComponent={NsTransition}
                keepMounted
                PaperProps={{ className: `${isMobileDevice && 'position-fixed fixed-bottom'} w-100 m-0` }}
                disableEscapeKeyDown={true}>
                <DialogTitle className="bg-dialog-title py-1 px-2">
                    <div className='d-flex justify-content-between align-items-center p-1'>
                        <NsTypography>
                            {alertModalTitle ? alertModalTitle : getTranslatedRawText('common.alert')}
                        </NsTypography>
                        {closeDialogClicked && <NsButton startIcon='ns-icon-close white-color'
                            className='p-0'
                            onClick={this.closeDialogClicked} />
                        }
                    </div>
                </DialogTitle>
                <DialogContent className='p-3'>
                    {children}
                </DialogContent>
                <DialogActions className='px-2'>
                    <NsButton label={`${confirmLabel ? confirmLabel : getTranslatedRawText('common.ok')}`}
                        startIcon={`${confirmStartIcon ? confirmStartIcon : 'ns-icon-confirm'}`}
                        wrapperClassName={`${isMobileDevice && 'flex-grow-1'}`}
                        className={`${confirmClassName ? confirmClassName : 'primary'}`}
                        loading={!!isLoading}
                        onClick={this.confirmClicked} />
                    <NsButton label={`${cancelLabel ? cancelLabel : getTranslatedRawText('common.cancel')}`}
                        startIcon={`${cancelStartIcon ? cancelStartIcon : 'ns-icon-close'}`}
                        wrapperClassName={`${isMobileDevice && 'flex-grow-1'}`}
                        className={`${cancelClassName ? cancelClassName : 'primary-outline'}`}
                        loading={!!isLoading}
                        onClick={this.cancelClicked} />
                </DialogActions>
            </Dialog>
        );
    }
}

export default NsAlertModal;