const ProfessionalOrderTranslationFa = {
    professionalOrder: {
        orderResult: 'نتیجه سفارش',
        noProductsFound: 'در دسته‌بندی انتخابی شما محصولی یافت نشد',

        collapse: {
            products: 'محصول‌ها',
            productDetail: 'جزئیات محصول',
        },

        sidebar: {
            productCategories: 'دسته‌بندی محصول‌ها',
            all: "همه"
        },

        choosingFile: {
            clearOrderQuestion: 'آیا از حذف این سفارش مطمئن هستید ؟',
            dragHere: 'فایل را اینجا بکشید',
        },

        orderDetails: {
            toolTip: "حداکثر سری چاپی برای این محصول {0} است."
        },

        extendedService: {
            filter: 'فیلتر',
            foldLine: 'خط تا',
            punch: 'پانچ',
            box: 'جعبه'

        },

        summaries: {
            foldLineCount: 'خط تا',
            foldLinePriceDetail: '{0}×{1}='
        },

        modal: {
            fit: 'هم سایز کردن با فایل رو',
        },

        messages: {
            areYouSureQuestion: 'آیا از حذف این سفارش اطمینان دارید؟',
            masterFileIsNotEmpty: "شما سفارشی در حال انجام دارید که آن را به لیست اضافه نکرده اید. پس از تایید، آن سفارش حذف خواهد شد. آیا از حذف آن اطمینان دارید؟"
        },
    }

}
export default ProfessionalOrderTranslationFa
