import { Snackbar } from '@mui/material';
import React from 'react';

class NsSnackbar extends React.Component {

    render() {
        const { children, ...otherProps } = this.props;
        return (
            <Snackbar {...otherProps} >
                {children}
            </Snackbar>
        );
    }
}

export default NsSnackbar;