import CommonHelper from "./CommonHelper"

class DelayedTaskHelper {

    constructor(callback, delay = null) {
        this.callback = callback;
        this.delay = delay || 1000;
        this.timeoutId = null;
    }

    run = (parameter) => {
        if (this.timeoutId != null)
            clearTimeout(this.timeoutId);

        this.timeoutId = setTimeout(() => { CommonHelper.safeFunctionCall(this.callback, parameter); }, this.delay);
    }

    clear = () => {
        clearTimeout(this.timeoutId);
    }
    
}

export default DelayedTaskHelper;