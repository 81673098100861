import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetAgenciesService, SetAgencyService } from '../../../../services/ProfileSetting/PersonalSettingService';
import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import { getEnumKeyFromValue } from '../../../../utilities/EnumerationHelper';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import NsCollapse from '../../../../components/dropdowns/NsCollapse';
import UpBaseProfileAgencyInfo from './bases/UpBaseProfileAgencyInfo';
import NsCheckbox from '../../../../components/inputs/NsCheckbox';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../UserPanelWrapper';
import Enumerations from '../../../../configs/Enumerations';

class UpProfileAgencySetting extends React.Component {

    constructor(props) {
        super(props);
        this.customerMustHaveAgency = CurrentUserHelper.getCustomerMustHaveAgency();
        this.state = {
            isLoading: true,
            selectedAgencyId: null,
            agencies: [],
            hasAgency: false,
            resultMessageClass: (<></>)
        };
    }

    componentDidMount = () => this.getAgencies();

    getAgencies = () => {
        this.setState({ isLoading: true });
        GetAgenciesService(this.getAgenciesCallback);
    }

    getAgenciesCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({
                agencies: resultData,
                hasAgency: (resultData.some(agency => agency.isSelected === true) || this.customerMustHaveAgency) ? true : false,
                selectedAgencyId: resultData.find(agency => agency.isSelected === true)?.id
            });
        else
            this.setState({ resultMessageClass });
    }

    discardClicked = () => this.getAgencies();

    saveClicked = () => {
        this.setState({ isLoading: true });
        SetAgencyService(this.state.hasAgency ? this.state.selectedAgencyId : null, this.setAgencyCallback, true);
    }

    setAgencyCallback = (resultData, result, messageClassModal) => {
        this.setState({
            isLoading: false,
            resultMessageClass: messageClassModal
        });
        if (!result.data.hasError) {
            CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.customerAgency),
                { customerMustHaveAgency: this.customerMustHaveAgency, customerAgencyId: this.state.selectedAgencyId });
        }
    }

    hasAgencyChanged = (value) => {
        if (!value) {
            let newAgencies = this.state.agencies.map(agency => {
                agency.isSelected = false
                return agency;
            });
            this.setState({ selectedAgencyId: null, agencies: newAgencies });
        }
        this.setState({ hasAgency: value });
    }

    mapAgencies = () => {
        return !isEmpty(this.state.agencies) && this.state.agencies.map((agency, index) =>
            <Col key={index} md={6} >
                <UpBaseProfileAgencyInfo option={agency}
                    radioButtonClicked={this.radioButtonClicked}
                    disabled={!this.state.hasAgency}
                    isSelectableAgency={true} />
            </Col >
        );
    }

    radioButtonClicked = (id) => {
        let newAgencies = this.state.agencies;
        newAgencies.map(agency => {
            agency.id == id
                ? agency.isSelected = true
                : agency.isSelected = false
        })
        this.setState({ selectedAgencyId: id, agencies: newAgencies });
    }

    render() {
        const { agencies, isLoading, resultMessageClass, selectedAgencyId, hasAgency } = this.state;

        this.mainButtons = [
            { label: "common.save", icon: 'ns-icon-save', onClicked: this.saveClicked, className: 'primary', disabled: hasAgency && !selectedAgencyId },
            { label: "common.discard", icon: 'ns-icon-close', onClicked: this.discardClicked, className: 'primary-outline' },
        ];

        return (
            <UserPanelWrapper titleName={getTranslatedRawText("profile.actionButton.agencySetting")}
                titleIcon='ns-icon-agency-setting'>

                <NsLoadingProgress visible={isLoading} />

                {!isEmpty(agencies) ?
                    <Row className='g-1'>
                        <Col xs={12} className='mb-3'>

                            <NsCheckbox label={getTranslatedRawText('profile.agency.orderDeliveryFromAgency')}
                                className='d-flex align-items-center'
                                disabled={this.customerMustHaveAgency}
                                value={hasAgency}
                                onChange={this.hasAgencyChanged} />

                        </Col>
                        <p className='section-title'>{getTranslatedRawText('profile.agency.agencies')}</p>
                        {this.mapAgencies()}

                        <NsStickyButton stickyButtons={this.mainButtons} isLoading={isLoading}
                            className='py-md-2' />

                        <Col xs={12}>
                            <div className='background-element-color rounded-3'>
                                <NsCollapse
                                    className="p-3"
                                    titleClassName='font-size-16 fw-bold'
                                    title={getTranslatedRawText('profile.agency.agentAdvantageTitle')} >
                                    <div className='d-block px-3 pb-3'>
                                        <p>
                                            {getTranslatedRawText('profile.agency.agentAdvantageDescription')}
                                        </p>
                                    </div>
                                </NsCollapse>
                            </div >
                        </Col>

                    </Row>
                    : <Row className='g-1'>
                        <div className='py-5 text-center'>
                            {getTranslatedRawText('profile.agency.noAgencyToChoose')}
                        </div>
                        <Col xs={12}>
                            <div className='background-element-color rounded-3'>
                                <NsCollapse
                                    className="p-3"
                                    titleClassName='font-size-16 fw-bold'
                                    title={getTranslatedRawText('profile.agency.agentAdvantageTitle')} >
                                    <div className='d-block px-3 pb-3'>
                                        <p>
                                            {getTranslatedRawText('profile.agency.agentAdvantageDescription')}
                                        </p>
                                    </div>
                                </NsCollapse>
                            </div >
                        </Col>

                    </Row>
                }
                {resultMessageClass}
            </UserPanelWrapper >
        );
    }
}

export default UpProfileAgencySetting;