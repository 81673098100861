import React, { useEffect, useState } from 'react';

import StoreOrderDetailPreviewContent from '../../../../../profileSettings/modals/StoreOrderDetailPreview/StoreOrderDetailPreviewContent';
import { GetStoreOrderProductDetailService } from '../../../../../../services/adminPanel/AdminStoreOrderService';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import NsModal from '../../../../../../components/modals/NsModal';

const ApStoreOrderDetailPreviewModal = (props) => {

    const { orderId, closeModal } = props;
    const [resultData, setResultData] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState(null);


    useEffect(() => {
        GetStoreOrderProductDetailService(orderId, getStoreOrderProductDetailCallback);
    }, []);

    const getStoreOrderProductDetailCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError)
            setResultData(resultData);
        else
            setResultMessageClass(resultMessageClass);
    }

    return (
        <NsModal showModal={true}
            maxWidth='md'
            dialogTitle={getTranslatedRawText("profile.detail.orderDetail")}
            closeModal={closeModal} >

            <StoreOrderDetailPreviewContent resultData={resultData} />

            {resultMessageClass}

        </NsModal >
    );
}

export default React.memo(ApStoreOrderDetailPreviewModal);