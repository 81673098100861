const ProductTranslationFa = {
    product: {
        common: {
            common: 'درهم',
            pieces: "عددی",
            piece: "عدد",
            save: 'ذخیره',
            add: 'افزودن',
            clear: 'پاک کردن',
            next: 'مرحله بعد',
            previous: 'مرحله قبل',
            skip: 'رد شدن',
            frontSide: 'روی سفارش',
            backSide: 'پشت سفارش',
            searchPlaceHolder: 'جستجو در نام محصول‌ها',
            viewMore: 'نمایش بیشتر',
            viewLess: 'نمایش کمتر',
            filter: 'فیلتر',
            goldFoil: 'فویل طلا',
            silverFoil: 'فویل نقره',
            UV: 'UV',
            dieCut: 'برش مهره‌‌ای',
            remove: 'حذف',
            removeAllFilters: "حذف همه فیلتر‌ها",
            search: "جستجو",
            groupSheetList: 'محصول های فرم های عمومی',
            storeList: 'فروشگاه'
        },
        sideBarTitles: {
            selectedFilter: 'فیلترهای انتخاب شده',
            categories: 'دسته‌بندی‌ها',
            searchInResult: 'جستجو در نتایج',
            price: 'محدوده ی قیمت',
            options: 'گزینه‌های چاپ',
            finishing: 'روکش',
            monthlyArchive: 'بایگانی ماهانه'
        },
        months: {
            january: 'ژانوِیه',
            february: 'فِورِیه ',
            march: 'مارس ',
            april: 'آوریل ',
            may: 'مه ',
            june: 'ژوئن ',
            july: 'ژوئیه ',
            august: 'اوت ',
            september: 'سِپتامبر ',
            october: 'اُکتُبر ',
            november: 'نُوامبر ',
            december: 'دِسامبر ',
        },
        sortItems: {
            sortBy: 'مرتب سازی',
            lowestPrice: 'ارزان ترین',
            highestPrice: "گرانترین",
            bestSelling: "پر فروش ترین",
            newest: "جدیدترین",
            fastestDelivery: "سریع ترین ارسال"
        },
        detail: {
            downloadGuide: 'راهنمای محصول',
            quantityHelper: ' سیستم به صورت خودکار به صرفه ترین تیراژ را محاسبه و انتخاب می‌کند.',
            turnaround: 'سرعت چاپ',
            printedSide: 'یکرو یا دورو',
            days: 'روز کاری',
            hours: 'ساعت',
            circulation: 'تیراژ',
            quantity: 'تعداد',
            series: 'سری چاپ',
            availableDimension: 'ابعاد موجود:',
            dieLineFile: 'خط قالب/برش',
            sharpDieCutLine: 'وضوح خط قالب/برش',
            customDimension: 'استعلام قیمت ابعاد سفارشی',
            rowDimension: '{0} ({1}×{2} میلیمتر)',
            latCount: ' ({0} لت)',
            startOrdering: 'شروع سفارش',
            priceLabel: '{0} - {1}',
            available: 'موجود',
            unavailable: 'ناموجود',
            processHoursAndDays: '{0} {1}',
            processTimeNotice: "مدت زمان آماده‌سازی: ",
            selectedDimension: 'سایز انتخاب شده',
            dimensionNotFound: 'برای تیراژ انتخاب شده ابعادی یافت نشد.',
            unavailableNote: '*توجه: موارد ناموجود به علت تعدادی است که انتخاب کرده اید.',
            overview: 'نقد و بررسی',
            explanation: 'توضیحات',
            recommendedProducts: 'محصول های مشابه',
            showPrice: 'نمایش قیمت',
            explanationViewPart1: 'محصول انتخابی شما شامل {0} سری از تیراژ {1} تایی، برابر با تعداد {2} از {3}, است که به صورت {4} و {5} چاپ می‌شود.',
            explanationViewPart2: 'سفارش شما طی {1} آینده آماده ارسال خواهد شد. پس از اتمام چاپ، می‌توانید روش تحویل را انتخاب کنید  تا سفارش خود را سریعتر دریافت کنید.',
            explanationGuidances: 'راهنمای این محصول در دسترس است. می‌توانید آن را دانلود کنید و بر اساس {0} محصول را طراحی کنید.',
            explanationNotViewPart1: 'قیمت‌های ما برای سفارشات چاپی بسیار رقابتی بوده و ابزار تبلیغاتی مناسبی برای کسب‌وکارهای کوچک و بزرگ است. مطابق با نیاز خود، ',
            explanationNotViewPart2: 'بهترین و مناسب‌ترین ابعاد موجود را انتخاب کنید. ما به شما کمک می‌کنیم تا تأثیری ماندگار در ذهن مخاطبان خود  ثبت نمایید.',
            explanationAvailableDimensionTitle: 'لیست بهترین پیشنهاد قیمت و ابعاد موجود (بدون احتساب مالیات بر ارزش افزوده (VAT)):',
            explanationAvailableText: '{0} به قیمت {1} (عرض {2} میلی‌متر و ارتفاع {3} میلی‌متر)',
            explanationUnavailableText: '{0} به قیمت {1} (عرض {2} میلی‌متر و ارتفاع {3} میلی‌متر)، با توجه به تعداد/سری انتخاب‌شده قابل سفارش نیست.',
            explanationCustomSizeText: 'اگر ابعاد و اندازه مورد نیاز خود را نمی‌بینید روی “استعلام قیمت ابعاد سفارشی” کلیک کنید.',
            explanationSelectableCirculation: 'می‌توانید تیراژ سفارش را از بین {0} تایی انتخاب کنید.',
            explanationSelectableTurnaround: ' می‌توانید سرعت چاپ سفارش را بین معمولی و فوری انتخاب کنید.',
            explanationSelectablePrintedSide: ' همچنین می‌توانید نوع چاپ سفارش خود را به صورت یک رو یا دو رو انتخاب کنید.',
            explanationNormalFrontProgressTime: 'در صورت انتخاب سرعت معمولی برای چاپ تک‌رو، محصول شما طی {0} آماده خواهد بود.',
            explanationExpressFrontProgressTime: 'در صورت انتخاب تحویل فوری برای چاپ تک‌رو، سفارش شما در {0} آماده خواهد بود.',
            explanationNormalFrontBackProgressTime: 'برای چاپ افست دو‌رو با به صورت معمولی، محصول شما طی {0} آماده خواهد بود.',
            explanationExpressFrontBackProgressTime: 'با انتخاب تحویل سریع برای چاپ دو رو، سفارش شما ظرف {0} تکمیل خواهد شد.',
            explanationTerms: 'با کلیک بر روی دکمه "شروع سفارش"، می‌توانید به راحتی و به صورت آنلاین سفارش خود را ثبت کنید. پیش از ثبت سفارش، لطفاً به {0}، {1} و {2} توجه نمایید. در صورت داشتن هرگونه مشکل در ثبت سفارش یا سوال در رابطه با ثبت سفارش اختصاصی می‌توانید به بخش {3} مراجعه و اگر پاسخ خود را در این بخش مشاهده ننمودید با {4} ما تماس حاصل نمایید.',
            explanationExploreCategory: 'برای اطلاع از تنوع محصول‌ها می‌توانید به بخش دسته‌بندی {1} در وب سایت ما مراجعه کنید.',
            explanationIntroduction: 'مجتمع چاپ نقش الماس، بزرگترین ارائه‌دهنده آنلاین خدمات چاپ افست در ایران، خدمات متنوع چاپی از جمله کارت ویزیت (لمینیت مات و براق، دورگرد، مخملی، UV)، بروشور، سربرگ، برچسب، پوستر، تراکت، فاکتور، ست‌ اداری، و  و نیز انواع  جعبه‌های بسته‌بندی، به همراه خدمات پس از چاپ نظیر خط تا، برش، پانچ، طلاکوب و … را عرضه می‌کند. تمامی خدمات در مجتمع چاپ نقش الماس با بالاترین کیفیت و قیمت رقابتی ارائه می‌گردد. همچنین امکان چاپ اختصاصی سفارشات با طرح اختصاصی برای مشتریان عزیز در این مجتمع فراهم می‌باشد. چاپخانه نقش الماس با داشتن سامانه ثبت سفارش آنلاین کاربرپسند و امن خود، این امکان را می‌دهد که سفارش‌های چاپی خود را در هر زمان و از هر مکانی ثبت نموده و آن‌ها را در کوتاه‌ترین زمان تحویل بگیرید.',
            confirmationDate: 'تاریخ تایید',
            confirmationTime: 'زمان تایید',
            productLicenseRequired: 'آیا سفارش شما نیاز به مجوز چاپ دارد؟',
            prohibitsPrintingDetail: 'جزئیات مجوز/ممنوعیت چاپ',
            pdrOnlineDiscountInformation: ' تا {0} درصد تخفیف برای سفارش های آنلاین. قیمت خط‌خورده برای خرید های ایمیل یا حضوری است.',
        },

        messages: {
            formatIsWrong: 'فرمت اشتباه است',
            noResult: 'نتیجه‌‌ای یافت نشد',
            noDiscountedProductAvailable: 'متاسفانه در حال حاظر، هیچ محصول دارای تخفیفی موجود نیست.',
        },
        customCard: {
            availableCirculations: 'تیراژ موجود:',
            availableUnitType: 'بسته‌بندی موجود',
            availableInStore: 'موجود در انبار'
        },
        store: {
            similarProducts: 'محصول‌های مشابه',
            cart: 'سبد خرید',
            addToBasket: 'افزودن به سبد خرید'
        },
        shoppingBag: {
            primaryImageTitle: 'کیسه خرید سازگار با محیط زیست',
            primaryMiddleImageTitle: 'آیا محصول مورد نظر خود را در بین گزینه های بالا پیدا نکردید؟ مشخصات مورد نظر خود را وارد کنید، من قیمت را برای شما محاسبه می کنم.',
            secondaryMiddleImageTitle: 'سفارشی کردن سفارشات',
            customizeProduct: 'محصول های سفارشی شده',
            customizeProductDescription: 'محصول های خود را با گزینه های خاص و قابل تنظیم مطابق با ترجیحات و نیازهای خود سفارشی کنید و قیمت نهایی را فورا مشاهده کنید.',
            priceInquiry: 'استعلام قیمت',
            onlineAvailableProducts: 'محصول های آماده آنلاین',
            onlineAvailableProductsDescription: 'طیف گسترده ای از کیسه های خرید موجود به صورت آنلاین را کاوش کنید که به دقت تنظیم شده اند تا هر جنبه ای از تجربه خرید خود را ارتقا داده و بهبود بخشند.',
            popularSpecification: 'مشخصات رایج',
            gusset: 'فاق (میلیمتر) ',
            width: 'پهنا (میلیمتر) ',
            height: 'ارتفاع (میلیمتر) ',
            quantity: 'تعداد',
            paperThickness: 'ضخامت کاغذ (gsm) ',
            packaging: 'بسته بندی',
            paperType: 'جنس کاغذ',
            topFold: 'تای لبه بالا',
            shrinkedFilm: 'شرینک',
            carton: 'کارتن',
            both: 'هردو',
            all: 'همه',
            virgin: 'نو',
            recycle: 'بازیافت',
            calculate: 'محاسبه قیمت',
            howToOrder: 'چطور سفارش دهیم',
            price: 'قیمت',
            paperWasteWeight: 'درصد دورریز کاغذ',
            pricePerShoppingbag: 'قیمت به ازای هر ساک',
            description: 'توضیحات',
            howToMeasure: 'چطور اندازه گیری کنیم',
            discoverOurProducts: 'محصول های ما را کشف کنید',
            thePerfectShopping: 'خرید عالی',
            introducingOurShoppingBags: 'معرفی ساکهای خرید ما',
            seeMore: 'نمایش بیشتر',
            orderProcess: 'فرایند سفارش',
            faqs: 'سوالات متداول',
            openWidth: 'پهنای باز شده',
            rollWidth: 'پهنای رول',
            openHeight: 'ارتفاع باز شده',
            printSize: 'اندازه چاپ',
            makePrice: 'قیمت ساخت',
            shrinkPrice: 'قیمت شرینک',
            cartonPrice: 'قیمت کارتن',
            totalPaperWeight: 'وزن کل کاغذ',
            printPrice: 'قیمت چاپ',
            makePricePerShoppingBag: 'قیمت ساخت به ازای هر کیسه',
            needPrint: 'چاپ میخواهد',
            mm: 'میلیمتر',
            kg: 'کیلوگرم',
            unavailable: 'غیر قابل دسترس',
            calculateHistory: 'تاریخچه محاسبات',
            gwh: 'G*W*H',
            openwh: 'باز W*H',
            WhyWeAreDifferent: 'چرا ما متفاوتیم',
            UniqueValueProposition: 'ارزش پیشنهادی منحصر به فرد',
            gripperMarginPrint: 'لب پنچه',
            turnaround: 'دور چاپ',
            history: 'تاریخچه',
            saved: 'ذخیره شده',
            saveItem: 'ذخیره نام',
            name: 'نام'
        },

        listStyle: {
            action: 'عملیات',
            productName: 'نام محصول',
            circulation: 'تیراژ',
            dimension: 'ابعاد',
            normalFrontOnly: 'معمولی | فقط رو',
            normalFrontAndBack: 'معمولی | پشت و رو',
            expressFrontOnly: 'فوری | فقط رو',
            expressFrontAndBack: 'فوری | پشت و رو',
        },

        tour: {
            sidebarFilter: 'فیلترهای مختلف مانند قیمت، نوع محصول و غیره قابل انتخاب است.',
            productResult: 'محصول ها براساس فیلترهای انتخابی، نمایش داده می‌شوند. با کلیک روی محصول، فرآیند سفارش را شروع کنید.',
            detailQuantity: 'تعداد مورد نیاز را وارد و تیراژ و سری را بررسی کنید.',
            detailOptions: 'قابلیت یک رو یا دو رو و سرعت چاپ نمایش داده شده است.',
            detailAvailable: 'سیستم به طور اتوماتیک بر اساس فایلی که آپلود می‌شود، ابعاد موجود مناسب را در نظر می‌گیرد.',
            detailStartOrdering: 'پس از اطمینان از تمام جزئیات محصول انتخاب شده، بر روی شروع سفارش کلیک کنید.',
            selectProduct: 'محصول خود را انتخاب نمایید. شما میتوانید محصول خود را به راحتی با استفاده از گزینه‌های جست و جو و دسته‌بندی محصول‌ها انتخاب نمایید.',
            poDetailQuantity: 'تعداد دور چاپ مورد نیاز خود را بررسی و انتخاب نمایید.',
        }
    }
};
export default ProductTranslationFa;