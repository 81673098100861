import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import EnumerationTranslationHelper from '../../../../../utilities/EnumerationTranslationHelper'
import { GetCartItemsService } from '../../../../../services/ProfileSetting/UserOrderService';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../utilities/CultureHelper';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../../components/text/NsTypography';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import DateHelper from '../../../../../utilities/DateHelper';
import NsCard from '../../../../../components/cards/NsCard';
import Config from '../../../../../configs/Config';
import UrlHelper from '../../../../../utilities/UrlHelper';
import Links from '../../../../../configs/links/AllLinks';

class CartList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sharesheetOrders: [],
            storeOrders: [],
            resultMessageClass: null
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        GetCartItemsService(this.getCartItemsCallback);
    }

    getCartItemsCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (result.data && !result?.data?.hasError) {
            if (!isEmpty(resultData?.dataItems) && Config.hasStoreProducts) {
                let sharesheetOrders = resultData?.dataItems.filter(item => item.orderProductType === Enumerations.orderProductType.sharedSheet)
                let storeOrders = resultData?.dataItems.filter(item => item.orderProductType === Enumerations.orderProductType.store)
                this.setState({ sharesheetOrders, storeOrders });

            } else
                this.setState({ sharesheetOrders: resultData.dataItems });
        }
        else
            this.setState({ resultMessageClass });
    }

    confirmOrderClicked = (isStore) => {
        const allOrderIds = isStore
            ? this.state.storeOrders.map(item => item.id)
            : this.state.sharesheetOrders.map(item => item.id);
        this.props.confirmOrderClicked(allOrderIds, isStore);
    }

    showCartClicked = () => this.props.showCartClicked(checkIsStoreSelected(this.state.sharesheetOrders, this.state.storeOrders));

    render() {
        const { sharesheetOrders, storeOrders, isLoading } = this.state;
        const { miniCartInfo } = this.props;
        const orders = [...sharesheetOrders, ...storeOrders];
        const count = miniCartInfo?.sharedSheetOrderCount + miniCartInfo?.storeProductOrderCount;

        return (
            <div className="cartlist">
                <div className="cartlist__header d-flex justify-content-between align-items-center border-bottom mb-2 px-2">
                    <NsTypography variant='inherit' className='font-size-14 fw-bold'>
                        {!count ? CommonHelper.stringFormat('{0} {1}', 0,
                            getTranslatedRawText('headers.modals.order'))
                            :
                            !isLoading &&
                            CommonHelper.stringFormat('{0} {1}', orders.length,
                                getTranslatedRawText('headers.modals.order'))
                        }
                    </NsTypography>
                    <div>
                        <NsButton label='headers.modals.showCart'
                            endIcon={<i className={CommonHelper.getBreadcrumbsSeparatorIcon()} />}
                            onClick={this.showCartClicked}
                            className='primary-text' />
                    </div>
                </div>
                <div className='cartlist__content  custom-scrollbar px-2 pb-2'>
                    <OrderList isLoading={isLoading}
                        orderCount={count}
                        orders={orders}
                        history={this.props.history} />
                </div>
                {count
                    ? <div className='cartlist__footer p-2 pt-1'>
                        <div className='d-flex justify-content-between'>
                            <NsTypography variant="inherit" className='font-size-12 d-block'>
                                {getTranslatedRawText('headers.modals.totalPriceWithVat')}
                            </NsTypography>
                            <b className='font-size-16'>{CommonHelper.currencyFormatByDefaultConfig(miniCartInfo?.totalPrice)}</b>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            {!isEmpty(sharesheetOrders) && <NsButton className='primary-outline'
                                size='small'
                                disabled={isLoading || isEmpty(sharesheetOrders)}
                                label='headers.modals.confirmShareSheetOrder'
                                onClick={() => this.confirmOrderClicked(false)} />
                            }
                            {!isEmpty(storeOrders) && <NsButton className='primary-outline'
                                size='small'
                                disabled={isLoading || isEmpty(storeOrders)}
                                label='headers.modals.confirmStoreOrder'
                                onClick={() => this.confirmOrderClicked(true)} />
                            }
                        </div>
                    </div>
                    : ''
                }
            </div>
        );
    }
}

export const checkIsStoreSelected = (sharesheetOrders, storeOrders) => {

    let isStoreSelected = false;
    isStoreSelected = isEmpty(sharesheetOrders) && !isEmpty(storeOrders)
        ? true
        : false

    return isStoreSelected;
}

function OrderList(props) {

    const productClicked = (product) => {
        let productName = CommonHelper.makeCorrectLink(product?.productName);
        product.orderProductType === Enumerations.orderProductType.sharedSheet ?
            props.history.push(UrlHelper.createPdRegularDetailUrl(null, product?.productId, productName))
            :
            props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main, Links.view.main.storeDetails),
                product?.productId, productName))
    }

    if (!props.orderCount)
        return <NsCard className="p-5 text-center font-size-18 fw-bold">{getTranslatedRawText('headers.modals.cartIsEmpty')}</NsCard>;
    else if (props.isLoading)
        return (
            <div className='cartlist__skeleton'>
                {CommonHelper.createArray(2).map((item, index) =>
                    <div key={index} className="my-4 px-2">
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                            ]} />
                    </div>
                )}
            </div>
        )
    else
        return (
            props.orders.map(item =>
                <div className='d-flex flex-column font-size-12' key={item.id}>
                    <div className='mb-1 border-0 cursor-pointer'>
                        <span onClick={() => productClicked(item)} className=' fw-bold font-size-14'>{item.productName}</span>
                    </div>
                    <span className='background-light-text-color white-color rounded p-1 font-size-10 ms-auto'> {item.orderProductType === Enumerations.orderProductType.store
                        ? getTranslatedRawText('headers.common.storeProduct')
                        : getTranslatedRawText('headers.common.sharedSheetProduct')}</span>
                    {item.orderProductType === Enumerations.orderProductType.sharedSheet && <NsTypography variant='inherit' >
                        {CommonHelper.stringFormat('{0}: {1}', getTranslatedRawText('headers.modals.submitDate'),
                            DateHelper.getDatePart(item.createDate.split('T')[0]))}
                    </NsTypography>
                    }
                    {item.orderProductType === Enumerations.orderProductType.sharedSheet && <NsTypography variant='inherit' >
                        {CommonHelper.stringFormat('{0}: {1}', getTranslatedRawText('headers.modals.turnaround'),
                            EnumerationTranslationHelper.getTurnaroundText(item.turnaroundType))}
                    </NsTypography>}
                    {item.orderProductType === Enumerations.orderProductType.sharedSheet && <NsTypography variant='inherit' >
                        {CommonHelper.stringFormat('{0}: {1}', getTranslatedRawText('headers.modals.circulation'),
                            item.circulation)}
                    </NsTypography>}
                    {item.orderProductType === Enumerations.orderProductType.sharedSheet && <NsTypography variant='inherit' >
                        {CommonHelper.stringFormat('{0}: {1}', getTranslatedRawText('headers.modals.printedSide'),
                            EnumerationTranslationHelper.getPrintedSideText(item.twoSidePrintingType))}
                    </NsTypography>}
                    {item.orderProductType === Enumerations.orderProductType.store && !isEmpty(item.details) && <NsTypography variant='inherit' className='mb-1'>
                        {CommonHelper.stringFormat('{0}: {1}', getTranslatedRawText('headers.modals.detail'), item.details)}
                    </NsTypography>}
                    {item.orderProductType === Enumerations.orderProductType.store && <NsTypography variant='inherit' className='mb-1'>
                        {CommonHelper.stringFormat('{0}: {1}', getTranslatedRawText('headers.modals.unit'), `${item.unitValue} ${item.unitTypeName}`)}
                    </NsTypography>}
                    {item.orderProductType === Enumerations.orderProductType.store && <NsTypography variant='inherit' className='mb-1'>
                        {CommonHelper.stringFormat('{0}: {1}', getTranslatedRawText('headers.modals.totalQuantity'), `${item.unitValue * item.totalQuantity} ${item.unitTypeName}`)}
                    </NsTypography>}
                    {item.orderProductType === Enumerations.orderProductType.store && false && <NsTypography variant='inherit' className='mb-1'>
                        {CommonHelper.stringFormat('{0}: {1}', getTranslatedRawText('headers.modals.supplierName'), item.storeProductSupplierName)}
                    </NsTypography>}
                    <NsTypography variant='inherit' className='mb-1'>
                        {CommonHelper.stringFormat('{0}: {1}', getTranslatedRawText('headers.modals.totalPrice'),
                            CommonHelper.currencyFormatByDefaultConfig(item.totalPrice))}
                    </NsTypography>
                </div>
            )
        );
}

export default withRouter(CartList);