import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";

import NsButton from "../../../components/buttons/NsButton";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import Enumerations from "../../../configs/Enumerations";

class PdsProperties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMore: false,
        }
    }

    showMoreClicked = () => this.setState({ showMore: !this.state.showMore });

    concatValues = (data) => data.map((item, index) => index !== data.length - 1 ? item.value + ',' : item.value);

    render() {
        const { showMore } = this.state;
        const { className, isLoading, nonEffectiveProperties } = this.props;
        const protertiesLength = nonEffectiveProperties.length;
        const properties = showMore
            ? nonEffectiveProperties.slice(0, protertiesLength)
            : nonEffectiveProperties.slice(0, 6);

        return isLoading
            ? <NsSkeleton isVisible={true}
                className='my-2'
                variant={[Enumerations.skeleton.text, Enumerations.skeleton.rect]} />
            : <Row className={clsx("font-size-14", className)}>
                {properties.map((property, index) =>
                    <Col key={index} className="mb-2" md={6}>{property.title}: <span className='fw-bold'>
                        {this.concatValues(property.propertyValues)}</span></Col>
                )}
                {protertiesLength > 6 &&
                    <div className="d-flex">
                        <NsButton label={!showMore ? 'product.common.viewMore' : 'product.common.viewLess'}
                            className='primary-text'
                            startIcon={showMore ? 'ns-icon-expand-up' : 'ns-icon-expand-down'}
                            onClick={this.showMoreClicked} />
                    </div>
                }
            </Row>
    }
}

export default PdsProperties;