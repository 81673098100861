import clsx from 'clsx';
import React from 'react';

import NsCheckbox from '../../../../components/inputs/NsCheckbox';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

function BillBookModalTableOption(props) {

    const { rows, billBookCopy, isSerial, className } = props;

    const Headers = [
        { text: isSerial ? 'regularOrder.billBook.serialNumber' : 'regularOrder.billBook.perforation', className: 'background-element-color' },
        { text: 'regularOrder.billBook.master', className: 'background-white-color' },
        { text: 'regularOrder.billBook.firstCopy', className: 'background-white-color' },
        { text: 'regularOrder.billBook.secondCopy', className: 'background-white-color' },
        { text: 'regularOrder.billBook.thirdCopy', className: 'background-white-color' },
    ];

    return <table className='table table-bordered font-size-14 fw-bold'>
        <thead >
            <tr>{Headers.map((item, index) => index <= billBookCopy &&
                <th key={index} className={item.className} > {getTranslatedRawText(item.text)}</th>
            )}
            </tr>
        </thead>
        <tbody className={className}>
            {!isEmpty(rows) && rows.map((row, index) =>
                <tr key={index}>
                    <th className={Headers[0].className}>
                        {getTranslatedRawText(index == 0 ? 'regularOrder.billBook.first' : 'regularOrder.billBook.second')}
                    </th>
                    {row.copyNumberOptions.map((item, index) => <td key={index} className={clsx('text-center', Headers[index + 1]?.className)}>
                        <NsCheckbox className='m-0'
                            onChange={(checked) => { item.isSelected = checked }}
                            value={item.isSelected} />
                    </td>
                    )}
                </tr>
            )}
        </tbody>
    </table >
}

export default BillBookModalTableOption;