import React, { useEffect, useState } from 'react';

import { GetStoreOrderDetailService, GetStoreOrderDtoService, GetStoreOrderHistoryService, UpdateStoreRetryToReadyStateService } from '../../../../../services/adminPanel/AdminStoreOrderService';
import { createStoreOrderDetaiColumns } from '../../../../profileSettings/contents/userOrder/stores/bases/UpBaseStoreOrderHelper';
import ApStoreOrderDetailPreviewModal from './orderDetailPreview/ApStoreOrderDetailPreviewModal';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import NsInvoiceButton from '../../../../../components/buttons/NsInvoiceButton';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApBaseUserInfoModal from '../../users/bases/ApBaseUserInfoModal';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import ApPaymentModal from '../../payments/ApPaymentModal';
import ApBaseWrapper from '../../../bases/ApBaseWrapper';

const ApStoreOrderDetailModal = (props) => {

    const { showModal, selectedOrder } = props;
    const hasAccessInfo = hasAccess([UserClaimEnumerations.getApOrderDto]);
    const hasAccessHistory = hasAccess([UserClaimEnumerations.getApOrderStatusHistory]);
    const hasAccessGrid = hasAccess([UserClaimEnumerations.getApOrderDetail]);

    const messageItems = [
        {
            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'),
                selectedOrder?.productName)}</>,
            messageType: Enumerations.messageType.question
        }];

    const [order, setOrder] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState();
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [showOrderPaymentModal, setShowOrderPaymentModal] = useState(false);
    const [showProductDetailModal, setShowProductDetailModal] = useState(false);
    const [showOrderDetailPreviewModal, setShowOrderDetailPreviewModal] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getOrderDto();
    }, []);

    const handlers = {

        detailClicked: (id) => {
            setSelectedOrderId(id);
            setShowOrderDetailPreviewModal(true);
        },

        ordersPaymentClicked: () => setShowOrderPaymentModal(!showOrderPaymentModal),

        closeOrderDetailPreviewModal: () => setShowOrderDetailPreviewModal(false),

        toggleUserInfoModal: () => setShowUserInfoModal(!showUserInfoModal),

        toggleProductDetailModal: () => setShowProductDetailModal(!showProductDetailModal),
    }

    const getOrderDto = () => {
        if (!hasAccessInfo) return;
        setIsLoadingInfo(true);
        GetStoreOrderDtoService(selectedOrder?.id, getOrdersByIdCallback);
    }

    const getOrdersByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoadingInfo(false);
        if (!result.data.hasError)
            setOrder({ storeOrderItemInfoDto: resultData });
        else
            setResultMessageClass(messageClassModal);
    }

    const updateStoreRetryToReadyStateCallback = (resultData, result, resultMessageClass) => {
        setIsLoadingInfo(false);
        setResultMessageClass(resultMessageClass);
    }

    const changeStatusToReadyClicked = () => {
        setIsLoadingInfo(true);
        UpdateStoreRetryToReadyStateService({ orderId: selectedOrder?.id, userId: selectedOrder?.userId }, updateStoreRetryToReadyStateCallback);
    }

    const { detailClicked, ordersPaymentClicked, closeOrderDetailPreviewModal, toggleUserInfoModal } = handlers;

    const headerButtons = () => {
        let isFailedOrder = selectedOrder?.lastStatusType == Enumerations.sharedSheetOrderStateType.prepare;
        return [
            isFailedOrder &&
            //  hasAccess([UserClaimEnumerations.updateApOrderStatus]) &&
            { wrapperClassName: '', startIcon: 'ns-icon-retry', label: 'adminPanel.common.retry', onClick: changeStatusToReadyClicked },
            // isFailedOrder && hasAccess([UserClaimEnumerations.deleteApNotConfirmedOrder]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-ppr-info', label: 'common.delete', onClick: deleteOrderClicked },
            // { wrapperClassName: 'ms-1', startIcon: 'ns-icon-details', label: 'adminPanel.common.shipmentDetail', },
            hasAccess([UserClaimEnumerations.getApCustomerById]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserInfoModal },
            // hasAccess([UserClaimEnumerations.getApPayments]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-payments', label: 'adminPanel.common.ordersPayment', onClick: ordersPaymentClicked },
            // hasAccess([UserClaimEnumerations.getApOrderUnOfficialReport]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-pdf-file', label: 'adminPanel.common.invoice', onClick: orderReportClicked },
            // hasAccess([UserClaimEnumerations.getApOrderProductDetail]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-product-information', label: 'adminPanel.orders.productDetail', onClick: toggleProductDetailModal },
        ];
    }

    return (<NsModal maxWidth='lg'
        showModal={showModal}
        closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
        noSpace={true}
        dialogTitle={getTranslatedRawText("adminPanel.orders.storeOrderDetail")} >

        <ApBaseMapActionButtons actionButtons={headerButtons()}
            isLoading={isLoadingInfo || isLoadingInvoice}>
            {hasAccess([UserClaimEnumerations.getApStoreOrderOfficialReport]) &&
                <NsInvoiceButton id={selectedOrder?.id}
                    userId={selectedOrder?.userId}
                    isAdminPanel={true}
                    isStoreOrder={true}
                    isLoading={isLoadingInfo || isLoadingInvoice}
                    hasInvoiceNumber={!!selectedOrder?.nasInvoiceId || !!selectedOrder?.invoiceNumber}
                    setLoading={(isLoading) => setIsLoadingInvoice(isLoading)} />
            }
        </ApBaseMapActionButtons>

        <ApBaseWrapper isLoadingInfo={isLoadingInfo}
            info={order}
            isOrderDetail={true}
            selectedItem={selectedOrder}
            hasAccessInfo={hasAccessInfo}
            hasAccessHistory={hasAccessHistory}
            typeItems={ConstantsWithTranslation.getAllStatusesOrder()}
            historyService={GetStoreOrderHistoryService}
            service={hasAccessGrid ? GetStoreOrderDetailService : ''}
            parameters={{
                id: selectedOrder?.userId,
                orderId: selectedOrder?.id
            }}
            columnDefs={createStoreOrderDetaiColumns(detailClicked, order)} />

        {showOrderDetailPreviewModal &&
            <ApStoreOrderDetailPreviewModal orderId={selectedOrderId}
                userId={selectedOrder?.userId}
                closeModal={closeOrderDetailPreviewModal} />
        }

        {showOrderPaymentModal &&
            <ApPaymentModal showModal={showOrderPaymentModal}
                searchData={{ orderId: selectedOrder?.id }}
                isHideSearch={true}
                selectedOrder={selectedOrder}
                userId={selectedOrder?.userId}
                closeModal={ordersPaymentClicked} />
        }

        {showUserInfoModal &&
            <ApBaseUserInfoModal showModal={showUserInfoModal}
                selectedUserId={selectedOrder?.userId}
                closeModal={toggleUserInfoModal} />
        }

        {/* {showProductDetailModal &&
            <ApOrderProductDetailModal showModal={true}
                selectedOrder={selectedOrder}
                closeModal={toggleProductDetailModal} />
        } */}

        {/* {showDeleteOrderMessageModal &&
            <NsMessageClassModal show={true}
                onClose={deleteOrderApproved}
                messageItems={messageItems} />
        } */}


        {resultMessageClass}
    </NsModal >
    );
}

export default React.memo(ApStoreOrderDetailModal);