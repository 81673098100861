import { GetFilePublicUrlService, UploadImageFileService, CheckFileBeforeUploadService, UploadFilmFileService } from '../../../../services/order/OrderService';
import { GetImageService } from '../../../../services/common/CommonService';
import { CancelRequest } from '../../../../services/bases/BaseService';
import CommonHelper from '../../../../utilities/CommonHelper';
import OrderHelper from '../../../../utilities/OrderHelper';
import ImageHelper from '../../../../utilities/ImageHelper';
import Enumerations from '../../../../configs/Enumerations';

class UploadFileHelper {

    constructor(productId, routeId, isFront, onUploadProgressCallback, callback) {
        this.productId = productId;
        this.routeId = routeId;
        this.isFront = isFront;
        this.thumbnails = [];
        this.presentableFileType = Enumerations.presentableFileType.none;

        this.cancelationUploadingReference = null;
        this.onUploadProgressCallback = onUploadProgressCallback;
        this.callback = callback;
    };

    uploadFimFile = (selectedFilmInfo) => {
        this.presentableFileType = Enumerations.presentableFileType.isPresentableInFilmFile;
        UploadFilmFileService(this.routeId, this.productId, selectedFilmInfo,
            this.uploadFileCallback, this.onInternalUploadProgressCallback);
    }

    uploadMasterFile = (selectedFileInfo) => {
        this.thumbnails = [];
        this.presentableFileType = Enumerations.presentableFileType.isPresentableInBoth;
        if (selectedFileInfo?.name?.split('.').pop() !== 'pdf') {
            ImageHelper.getResolutionAndDimension(ImageHelper.imageURl(selectedFileInfo),
                (resolutionAndDimension) => this.checkFileBeforeUpload(resolutionAndDimension, selectedFileInfo))
        }
        else {
            this.checkFileBeforeUpload(null, selectedFileInfo);
        }
    }

    checkFileBeforeUpload = (resolutionAndDimension, selectedFileInfo) => {
        let data = {
            ProductId: this.productId,
            FileName: selectedFileInfo.name,
            FileSize: selectedFileInfo.size,
            Width: !!resolutionAndDimension?.width
                ? ImageHelper.convertPixelToMillimeter(resolutionAndDimension.width, resolutionAndDimension.xResolution)
                : null,
            Height: resolutionAndDimension?.height
                ? ImageHelper.convertPixelToMillimeter(resolutionAndDimension.height, resolutionAndDimension.yResolution)
                : null,
            VerticalResolution: resolutionAndDimension?.xResolution,
            HorizontalResolution: resolutionAndDimension?.yResolution,
            IsDouble: OrderHelper.getPrintedSide(this.routeId).key === Enumerations.printedSide.twoSide,
        };

        CheckFileBeforeUploadService(data, this.checkFileBeforeUploadCallback(selectedFileInfo));
    }

    checkFileBeforeUploadCallback = (selectedFileInfo) => (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.cancelationUploadingReference = UploadImageFileService(this.productId, this.routeId, selectedFileInfo, this.isFront, this.uploadFileCallback, this.onInternalUploadProgressCallback);
        }
        else
            CommonHelper.safeFunctionCall(this.callback, messageClassModal);
    }

    onInternalUploadProgressCallback = (id, loaded, total, percent, status, message) => {
        CommonHelper.safeFunctionCall(this.onUploadProgressCallback, id, loaded, total, percent, status, message);
    }

    uploadFileCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            let tempContentList = resultData;
            for (let i = 0; i < tempContentList.length; i++)
                this.getFilePublicUrl(i, tempContentList, this.presentableFileType);
        }
        else
            CommonHelper.safeFunctionCall(this.callback, messageClassModal);
    }

    getFilePublicUrl = (i, tempContentList, presentableFileType) => GetFilePublicUrlService(tempContentList[i].id, this.getFilePublicUrlCallback(i, tempContentList, presentableFileType));

    getFilePublicUrlCallback = (index, tempContentList, presentableFileType) => (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            tempContentList[index].presentableFileType = presentableFileType;
            tempContentList[index].filePublicUrl = resultData;
            tempContentList[index].resolution = resultData?.resolution;
            tempContentList[index].width = resultData?.width;
            tempContentList[index].height = resultData?.height;
            GetImageService(tempContentList[index].filePublicUrl.thumbnail, this.getThumbnailCallback(index, tempContentList));
        } else
            CommonHelper.safeFunctionCall(this.callback, messageClassModal);
    }

    getThumbnailCallback = (index, tempContentList) => (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            tempContentList[index].thumbnail = resultData;
            GetImageService(tempContentList[index].filePublicUrl.draft, this.getDraftCallback(index, tempContentList));
        } else
            CommonHelper.safeFunctionCall(this.callback, messageClassModal);
    }

    getDraftCallback = (index, tempContentList) => (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            tempContentList[index].draft = resultData;
            if (this.isAllImageLoaded(tempContentList))
                CommonHelper.safeFunctionCall(this.callback, null, tempContentList);
        } else
            CommonHelper.safeFunctionCall(this.callback, messageClassModal);
    }

    isAllImageLoaded = (tempContentList) => {
        return tempContentList.every(temp => !!temp.draft && !!temp.thumbnail);
    }

    cancelUpload = () => CancelRequest(this.cancelationUploadingReference);
}

export default UploadFileHelper;