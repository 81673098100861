import React, { useEffect, useState } from 'react';

import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseUserSelectModal from '../../bases/ApBaseUserSelectModal';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import DateHelper from '../../../../utilities/DateHelper';

const ApUsersReportsSearch = (props) => {

    const { userDetails } = props;

    const [reportDateFrom, setReportDateFrom] = useState(DateHelper.getPreviousMonth());
    const [reportDateTo, setReportDateTo] = useState(new Date());
    const [user, setUser] = useState('');
    const [userId, setUserId] = useState('');
    const [showUsersModal, setShowUsersModal] = useState(false);

    const usersModalClicked = () => setShowUsersModal(true);

    const searchOptions = [
        isEmpty(userDetails) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            onChange: (value) => setUser(value),
            disabled: true,
            endAdornment: <NsButton className='light-circle'
                loading={props?.isLoading}
                onClick={usersModalClicked}
                startIcon="ns-icon-more" />
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.reportDateFrom'),
            fieldName: 'reportDateFrom',
            value: reportDateFrom,
            onChange: (value) => setReportDateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.reportDateTo'),
            fieldName: 'reportDateTo',
            value: reportDateTo,
            onChange: (value) => setReportDateTo(value)
        }
    ]

    useEffect(() => {
        !isEmpty(userDetails) && searchButtonClicked();
    }, [userDetails])


    const searchButtonClicked = () => {
        let data = isEmpty(userDetails) ? {
            userId,
            reportDateFrom,
            reportDateTo
        }
            : {
                userId: userDetails?.userId,
                reportDateFrom,
                reportDateTo
            }
        CommonHelper.safeFunctionCall(props.searchClicked, data);
    }

    const clearButtonClicked = () => {
        setUser('');
        setUserId('');
        setReportDateFrom(DateHelper.getPreviousMonth());
        setReportDateTo(new Date());
        CommonHelper.safeFunctionCall(props.searchClicked, {});
    }

    const searchValues = {
        user,
        userId,
        reportDateFrom,
        reportDateTo
    }

    const userSelected = (user) => {
        setUser(user?.phoneNumber)
        setUserId(user?.userId)
        setShowUsersModal(false)
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            searchButtonClicked();
    }

    const exportExcelButtonClicked = () => {
        let data = {
            userId,
            reportDateFrom,
            reportDateTo
        }
        CommonHelper.safeFunctionCall(props.excelButtonClicked, data)
    }

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <div>
                    <NsAdvanceSearch searchOptions={searchOptions}
                        searchValues={searchValues}
                        mdCol={3} smCol={6} />
                </div>

                <div className="d-flex my-2 gap-1 me-auto">
                    <NsButton label={getTranslatedRawText('common.search')}
                        size='small'
                        onClick={searchButtonClicked}
                        className='secondary'
                        disabled={!userId && !userDetails?.userId}
                        loading={props?.isLoading}
                        startIcon='ns-icon-search' />

                    {isEmpty(userDetails) &&
                        <NsButton label={getTranslatedRawText('regularOrder.common.clear')}
                            size='small'
                            onClick={clearButtonClicked}
                            className='secondary-outline'
                            loading={props?.isLoading}
                            startIcon='ns-icon-clear' />
                    }
                    {false && <NsButton label={getTranslatedRawText('common.exportExcel')}
                        size='small'
                        onClick={exportExcelButtonClicked}
                        className='secondary-outline text-nowrap'
                        loading={props?.isLoading}
                        startIcon='ns-icon-excel' />}

                </div>
            </div>
            {showUsersModal &&
                <ApBaseUserSelectModal showModal={true}
                    userSelected={userSelected}
                    closeModal={() => setShowUsersModal(false)} />
            }
        </>
    );
};

export default React.memo(ApUsersReportsSearch);