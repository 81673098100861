import React, { useCallback, useEffect, useState } from "react";

import { GetAnnouncementContentByIdService } from "../../../../services/adminPanel/AdminAnnouncementService";
import EnumerationTranslationHelper from "../../../../utilities/EnumerationTranslationHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsLabelBox from "../../../../components/labels/NsLabelBox";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import CommonHelper from "../../../../utilities/CommonHelper";
import NsModal from "../../../../components/modals/NsModal";
import DateHelper from "../../../../utilities/DateHelper";
import ApLanguageTabs from "../../bases/ApLanguageTabs";
import ContentHelper from "../bases/ContentHelper";
import { Col, Row } from "react-bootstrap";

const ApAnnouncementViewModal = (props) => {

    const { announcementId } = props;
    const [multiLanguageAnnouncementContent, setMultiLanguageAnnouncementContent] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        GetAnnouncementContentByIdService(announcementId, getAnnouncementContentByIdCallback);
    }, []);

    const getAnnouncementContentByIdCallback = (resultData, result, messageClassModal) => {
        (false);
        if (!result.data.hasError) {
            setMultiLanguageAnnouncementContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive, isDisplayAsModal: resultData[0].content.isDisplayAsModal }));
        } else
            setMessageClassModal(messageClassModal);
    };

    const closeModalClicked = () => CommonHelper.safeFunctionCall(props.closeModal);

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const announcementContent = ContentHelper.getSpecificLanguageContent([...multiLanguageAnnouncementContent], selectedLanguage.id);
    console.log(announcementContent);
    return (
        <NsModal
            maxWidth="md"
            dialogTitle={getTranslatedRawText('adminPanel.announcement.announcementDetail')}
            showModal={true}
            noSpace={true}
            closeModal={closeModalClicked} >

            <div className='d-flex flex-wrap gap-2 background-color px-3 py-2'>
                {!!announcementContent?.content?.createAt && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.registrationTime')}
                    value={DateHelper.getDateAndTime(announcementContent?.content?.createAt)} />
                }
                {!!announcementContent?.content?.editAt && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.modifyDate')}
                    value={DateHelper.getDateAndTime(announcementContent?.content?.editAt)} />
                }
                {!!announcementContent?.content?.expirationAt && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('components.advanceSearch.expirationDate')}
                    value={DateHelper.getDateAndTime(announcementContent?.content?.expirationAt)} />
                }
                {!!announcementContent?.content?.isActive && <NsLabelBox className='background-white-color min-size'
                    label={getTranslatedRawText('adminPanel.orders.isActive')}
                    value={EnumerationTranslationHelper.getActivationStatus(announcementContent?.content?.isActive)} />
                }
            </div>

            <div className="p-3">
                <ApLanguageTabs languageChanged={languageChangedHandler}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguageAnnouncementContent) &&
                    <TabDataTables key={selectedLanguage.id}
                        announcementContent={announcementContent}
                        selectedLanguage={selectedLanguage} />
                }
            </div>

            {messageClassModal}
        </NsModal >
    );
}

function TabDataTables(props) {
    const { announcementContent } = props;

    return (
        <Row className='align-items-center justify-content-between g-2 font-size-14'>
            {!!announcementContent?.content.title && <Col md={6} className="d-flex flex-column" >
                <span>{getTranslatedRawText('adminPanel.dataGridColumn.title')}</span>
                <div className="border rounded p-2 my-1">
                    {announcementContent?.content.title}
                </div>
            </Col>
            }
            {!!announcementContent?.content.linkUrl && <Col md={6} className="d-flex flex-column" >
                <span>{getTranslatedRawText('adminPanel.dataGridColumn.link')}</span>
                <div className="border rounded p-2 my-1">
                    {announcementContent?.content.linkUrl}
                </div>
            </Col>
            }
            {!!announcementContent?.content.mainContent && <Col xs={12} className="d-flex flex-column over-flow-wrap" >
                <span>{getTranslatedRawText('adminPanel.sidebar.content')}</span>
                <div className="border rounded p-2 my-1">
                    {announcementContent?.content.mainContent}
                </div>
            </Col>
            }
        </Row >
    )
}

export default React.memo(ApAnnouncementViewModal);