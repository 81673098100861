import React from "react";

import NsHtmlToReact from "../../../components/htmlToReact/NsHtmlToReact";
import NsLabelLine from "../../../components/labels/NsLabelLine";
import NsTypography from "../../../components/text/NsTypography";
import Links from "../../../configs/links/AllLinks";
import CommonHelper from "../../../utilities/CommonHelper";
import { getTranslatedRawText, getUrlWithCurrentCulture, removeQueryFromURL } from "../../../utilities/CultureHelper";
import ShoppingBagWrapper from "./ShoppingBagWrapper";
import NsSetPageTitleAndDescription from "../../../components/metaTags/NsSetPageTitleAndDescription";

class ShoppingBagExploreProduct extends React.Component {
    constructor(props) {
        super(props);
        this.textItems = [
            {
                title: 'Celebrate Sustainability with Our Eco-Friendly Paper Shopping Bags',
                body: '<p>At Diamond Printing Press, we believe in making a positive impact on the environment and providing sustainable solutions for our customers. Our eco-friendly shopping bags are a testament to our commitment to sustainability. These bags are not just functional; they are a statement of our dedication to environmental responsibility.</p>'
            },
            {
                title: 'Why Choose Our Eco-Friendly paper bags?',
                body: `<ol>
                    <li><b>Sustainability:</b> Our eco-friendly shopping bags are made from recycled and recyclable materials. They help reduce the environmental footprint and contribute to a greener planet.</li>
                    <li><b>Durability:</b> These bags are designed to withstand the test of time. With reinforced handles and sturdy construction, they can carry your shopping items safely.</li>
                    <li><b>Style:</b> Our shopping bags are not only eco-friendly but also stylish. You can choose from a range of designs, colors, and sizes to match your preferences.</li>
                    <li><b>Customization:</b> We offer the option to personalize your eco-friendly bags with your brand's logo or design, making them a unique and eco-conscious choice.</li>
                    <li><b>Versatility:</b> Our bags are suitable for various purposes, from grocery shopping to promotional events. They are the perfect choice for any occasion.</li>
                </ol>
                Join us in our mission to protect the environment and reduce plastic waste. Choose our eco-friendly shopping bags and make a positive impact with every use.`
            },
            {
                title: 'The Versatility of Paper bags: Their Role in Various Business Sectors',
                body: `brown paper bags with handles play an integral role in our daily lives, and their significance spans far beyond carrying groceries or goods from one place to another. Whether you need bags for your business or personal use, we have a diverse collection to cater to your needs. Our range includes:
                <ol>
                    <li><b>Retail Excellence:</b> In the retail industry, custom printed paper bags are not just a means of carrying products. They are a powerful branding tool. Branded paper party bags are a unique way to promote your retail business, allowing your customers to carry your logo and message wherever they go. This mobile advertising contributes to brand visibility and helps you stand out in a competitive market.</li>
                    <li><b>Hospitality and Events:</b> In the world of hospitality, custom printed paper bags become a stylish and functional accessory. High-end hotels and event venues use custom shopping bags to enhance the guest experience. From welcome bags in hotel rooms to event giveaways, these bags make guests feel special and appreciated.</li>
                    <li><b>Food and Beverage:</b> For restaurants and food delivery services, paper bags with logo are a must. They keep food hot or cold, ensuring that customers receive their orders in the best condition. Branded bags also serve as a marketing tool for these businesses.</li>
                    <li><b>Promotional and Marketing:</b> In the world of marketing, paper bags with logo are an innovative medium for brand promotion. Companies use them as part of marketing campaigns, trade shows, and promotional events. When a unique and stylish shopping bag is part of a campaign, it leaves a lasting impression.</li>
                    <li><b>Tote Bags:</b> For those who appreciate style and eco-friendliness, our tote bags are the ideal choice. These stylish and reusable bags complement your fashion sense while reducing your environmental footprint.</li>
                    <li><b>Gift Bags:</b> Special occasions deserve special presentation. Our gift bags are perfect for gift-giving, adding an extra layer of elegance and thoughtfulness to your tokens of appreciation.</li>
                </ol>`
            },
            {
                title: `Designing Eco-Friendly Kraft Paper Bags: A Structural Insight`,
                body: `The design structure of Kraft paper bags encompasses several key elements, all aimed at ensuring functionality, durability, and an eco-friendly approach:
                <ul>
                    <li><b>Material Selection:</b> kraft paper bags are typically crafted from Kraft paper, which is known for its sturdiness and eco-friendliness. The material choice, including considerations like paper thickness (measured in GSM), plays a vital role in ensuring the bag's strength and its recyclable and biodegradable properties.</li>
                    <li><b>Handle Type:</b> kraft bags with handles can feature either flat handles or twisted handles. The handle type is designed for ease of carrying and varies based on personal preference and the bag's intended use. The width and height of the bag contribute to its ergonomic design for efficient use.</li>
                    <li><b>Gusset:</b> Some kraft shopping bags incorporate a gusseted design. The gusset, located at the base and sides, allows the bag to expand and accommodate a wider range of items, making it versatile for various shopping needs.</li>
                    <li><b>Top Fold:</b> The top fold, present at the bag's opening, reinforces its structure, offering additional support for carrying heavier items.</li>
                    <li><b>Packaging:</b> Kraft bags can be packaged in shrink film, cartons, or a combination of both, ensuring that they are delivered to customers in excellent condition.</li>
                </ul>
                
                This comprehensive insight into custom kraft paper bags design highlights the significance of factors like gusset dimensions, width, height, top fold, paper thickness, and paper type (virgin or recycled) in creating eco-friendly and versatile packaging solutions. The customization options further enhance the functionality and appeal of Kraft shopping bags, making them an excellent choice for both individuals and businesses.
`
            },
            {
                title: `Virgin Shopping Bags vs. Recycled: What Sets Them Apart?`,
                body: `
                <ul>
                    <li><b>The Virgin Difference:</b> Virgin shopping bags are crafted from new, unused materials. Their exceptional quality and strength make them an excellent choice for businesses looking for top-tier durability. They are perfect for high-end retail and luxury branding.</li>
                    <li><b>Recycled Revolution:</b> Recycled shopping bags are the embodiment of sustainability. They are made from post-consumer recycled materials, such as plastic bottles or containers. Choosing recycled bags is a step toward reducing plastic waste and promoting a circular economy.</li>
                </ul>`
            },
            {
                title: `Elevate Your Gifting Experience with Paper Gift Bags`,
                body: `Whether you're presenting a thoughtful gesture or wrapping up a special purchase, our collection of paper gift bags adds a touch of elegance to every occasion. Choose from a range of brown and white paper bags, each crafted with quality materials to ensure durability and style. Our brown paper bags exude a rustic charm, while the crisp white options provide a clean and classic look. From birthdays to weddings, these paper gift bags are the perfect finishing touch for your heartfelt moments. Make your gifts stand out with the simplicity and eco-friendly appeal of our brown bags with handles bulk.`
            },
            {
                title: `Explore Diverse Paper Bag Types for Your Unique Needs`,
                body: `
                printed paper bags are not just carriers; they play a vital role in our daily lives and businesses. When it comes to choosing the right paper shopping bags, you're met with a variety of options, each tailored to specific preferences and purposes. Let's delve into the world of paper gift bags and discover their distinctive characteristics:
                    <ul>
                        <li>
                            <b>Paper Shopping Bags:</b><br/>
                            Material: Crafted from sturdy paper.<br/>
                            Strength: Moderate, suitable for light to medium-weight items.<br/>
                            Eco-Friendly: Yes, biodegradable and recyclable.<br/>
                            Customization: Easy to print with various designs.<br/><br/>
                        </li>

                        <li>
                            <b>Plastic Shopping Bags:</b><br/>
                            Material: Made from polyethylene.<br/>
                            Strength: Versatile, suitable for various weights.<br/>
                            Eco-Friendly: Less environmentally friendly due to non-biodegradable nature.<br/>
                            Customization: Limited compared to paper bags.<br/><br/>
                        </li>

                        <li>
                            <b>Reusable Shopping Bags:</b><br/>
                            Material: Diverse, including fabric, non-woven polypropylene, or recycled materials.<br/>
                            Strength: Designed for multiple uses, suitable for various weights.<br/>
                            Eco-Friendly: Yes, especially when made from recycled materials.<br/>
                            Customization: Often customizable with different styles.<br/><br/>
                        </li>

                        <li>
                            <b>Cotton Canvas Bags:</b><br/>
                            Material: Sturdy cotton canvas fabric.<br/>
                            Strength: High, suitable for heavy items and frequent use.<br/>
                            Eco-Friendly: Yes, reusable and eco-conscious.<br/>
                            Customization: Easily customizable with prints and designs.<br/>
                        </li>
                    </ul>
                    Now, let's turn our attention to the star of the show: the Kraft Shopping Bag. What sets Kraft bags apart from the rest? These eco-friendly champions are crafted from sustainable and biodegradable materials, perfectly aligning with the global movement to reduce environmental impact and minimize plastic waste.
                `
            },
            {
                title: `Advantages of Kraft Shopping Bags:`,
                body: `
                <ul>
                    <li><b>Quality and Durability:</b> Unmatched quality ensures these bags can carry substantial weight without a hitch.</li>
                    <li><b>Customization for Branding:</b> Kraft bags are more than just carriers; they are powerful branding tools. They transform into mobile billboards for your business, letting your logo and message travel with your customers. This boosts brand visibility and helps you stand out in the competitive market.</li>
                    <li><b>Eco-Consciousness:</b> Kraft shopping bags epitomize eco-friendliness, being both recyclable and made from renewable resources.</li>
                </ul>
                However, it's important to consider some factors when choosing Kraft shopping bags. While these bags shine in many aspects, they are sensitive to water, which may lead to quicker deterioration when exposed to moisture. Additionally, their load-bearing capacity for heavy items may be slightly reduced due to their manufacturing process. Lastly, the production cost of Kraft bags can be higher than some other options.
                <br/><b>Diamond Printing Press</b> invites you to explore the remarkable world of kraft bags with handles. Our commitment to quality and eco-friendliness aligns perfectly with your sustainability goals. Make the eco-conscious choice today!<br/><br/>`
            }
        ];

        this.state = {
            selectedTitle: this.textItems[0].title
        };
    }

    componentDidMount = () => !this.props.isMainExplore && window.scrollTo({ top: 120, behavior: 'smooth' });

    showTableOfContent = (isMainExplore) => {
        return !isMainExplore && (<div className='d-inline-block background-element-color p-3 my-5 rounded'>
            <NsTypography variant='inherit' className='d-inline-block section-title mb-2'>
                {getTranslatedRawText('singlePage.privacyPolicy.tableOfContent')}
            </NsTypography>
            {this.textItems.map((textItem, index) => {
                return (<a key={index} href={`${removeQueryFromURL(getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagExploreProduct))}#${CommonHelper.makeCorrectLink(textItem.title)}`} className='table-content d-block pb-1' onClick={() => this.setState({ selectedTitle: textItem.title })}>
                    {textItem.title}
                </a>)
            })}
        </div>)
    }

    renderChild = (isMainExplore) => {
        const currentTextItems = !isMainExplore
            ? this.textItems
            : [this.textItems[0]];

        return (
            <div>
                <NsLabelLine linkTo={isMainExplore ? getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagExploreProduct) : null}
                    smallSize={true}
                    primaryTitle={getTranslatedRawText('product.shoppingBag.thePerfectShopping')}
                    secondaryTitle={getTranslatedRawText('product.shoppingBag.introducingOurShoppingBags')}
                    viewMoreLink={isMainExplore ? getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagExploreProduct) : null} />
                {
                    this.showTableOfContent(isMainExplore)
                }
                {
                    currentTextItems.map((textItem, index) => {
                        return (<div key={index} className='pt-5 text-justify' id={CommonHelper.makeCorrectLink(textItem.title)}>
                            <h6 className="fw-bold mb-2">{textItem.title}</h6>
                            <div><NsHtmlToReact key={index} >{textItem.body}</NsHtmlToReact></div>
                        </div>)
                    })
                }
            </div>
        );
    }

    render() {
        const { isMainExplore } = this.props;
        return (<>
            {isMainExplore
                ? <div>
                    {this.renderChild(isMainExplore)}
                    <br />
                </div >
                : <ShoppingBagWrapper>
                    {this.renderChild(isMainExplore)}
                </ShoppingBagWrapper>
            }
            <NsSetPageTitleAndDescription pageTitle={CommonHelper.stringFormat('{0} | {1}', getTranslatedRawText('common.breadcrumbs.shoppingBagExploreProduct'), this.state.selectedTitle)} />
        </>
        );
    }

}

export default ShoppingBagExploreProduct;