import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import NsCard from '../../../../components/cards/NsCard';
import NsLabelBox from '../../../../components/labels/NsLabelBox';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';
import { GetSaleGadgetsService } from '../../../../services/adminPanel/AdminDashboardService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

const ApDfGadget = (props) => {

    const { parameters, refreshDate } = props;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getSaleGadgets();
    }, [refreshDate])

    const description = [
        'adminPanel.dashboard.totalSaleDes',
        'adminPanel.dashboard.totalPaymentDes',
        'adminPanel.dashboard.totalVatDes',
        'adminPanel.dashboard.totalDiscountDes',
    ]

    const getSaleGadgets = () => {
        setIsLoading(true);
        GetSaleGadgetsService(parameters, getSaleGadgetsCallback)
    }

    const getSaleGadgetsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            let newData = resultData.map((item, index) => {
                return (
                    { ...item, description: getTranslatedRawText(description[index]) }
                )
            });
            setData(newData)
        }
    }

    return isLoading
        ? CommonHelper.createArray(4).map((item, index) =>
            <div key={index} className="d-inline d-md-inline-block col-md-3 p-2" >
                <NsSkeleton isVisible={true}
                    variant={[
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                    ]} />
            </div >
        )
        : data.map((item, index) =>
            <Col sm={6} xl={3} key={index}>
                <NsCard contentClassName='d-flex align-items-center justify-content-between p-3'>
                    <NsLabelBox className='background-white-color'
                        hasDescription={false}
                        description={item.description}
                        label={item?.key}
                        value={CommonHelper.currencyFormatByDefaultConfig(item?.value)} />
                    <span className='rounded-circle'><i className='ns-icon-credit opacity-100 red-color h2'></i></span>
                </NsCard>
            </Col>
        )
};

export default React.memo(ApDfGadget);