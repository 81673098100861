import React from 'react';

import NsButton from '../../../../../components/buttons/NsButton';
import NsTypography from '../../../../../components/text/NsTypography';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { messageClass } from '../../../../../utilities/MessageModalHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import PoFoldLineModal from '../../modals/foldLine/PoFoldLineModal';

class PoExtendedServiceFoldLine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasFoldLineService: false,
            isShowFoldLineModal: false,
            masterFiles: [],
            resultMessageClass: (<></>),
            selectedFoldLinePreset: {},
            customFoldLines: [],
            foldLinePrice: 0,
            refreshExtendedServicesDate: props.refreshExtendedServicesDate,

        }
    }

    componentDidMount = () => this.prepareService();

    componentDidUpdate = (prevProps) => {
        if (prevProps.refreshExtendedServicesDate !== this.state.refreshExtendedServicesDate)
            this.prepareService();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshExtendedServicesDate !== prevState.refreshExtendedServicesDate)
            return ({ refreshExtendedServicesDate: nextProps.refreshExtendedServicesDate });
        return null;
    }

    prepareService = () => {
        let service = OrderHelper.getSpecificService(this.props.routeId, Enumerations.extendedServices.foldLine);
        this.setState({
            hasFoldLineService: !isEmpty(service),
            selectedFoldLinePreset: !Array.isArray(service?.selectedItem) ? service?.selectedItem : {},
            customFoldLines: Array.isArray(service?.selectedItem) ? service?.selectedItem : [],
            foldLinePrice: service?.price,
        });
    }

    addFoldLineClicked = () => {
        if (OrderHelper.checkFrontFileExist(this.props.routeId)) {
            OrderHelper.getMasterFilesWithFullInfo(this.props.routeId, (masterFiles) => {
                this.setState({
                    masterFiles: masterFiles[0].frontSideFileInfo,
                    isShowFoldLineModal: true
                });
            });
        } else
            this.setState({ resultMessageClass: messageClass(getTranslatedRawText('common.messages.selectFileError')) });
    }

    closeFoldLineModalClicked = () => {
        this.setState({ isShowFoldLineModal: false });
    }

    saveFoldLineModalClicked = (selectedFoldLinePreset, customFoldLines) => {
        OrderHelper.updateExtendedService(this.props.routeId, Enumerations.extendedServices.foldLine, isEmpty(selectedFoldLinePreset) ? customFoldLines : selectedFoldLinePreset);
        this.setState({ selectedFoldLinePreset, customFoldLines, isShowFoldLineModal: false });
        CommonHelper.safeFunctionCall(this.props.refreshSummary);
    }

    render() {
        const { hasFoldLineService, isShowFoldLineModal, masterFiles, selectedFoldLinePreset, customFoldLines, foldLinePrice } = this.state;
        return (hasFoldLineService
            ? <div className='d-flex align-items-center mb-2'>

                <NsTypography variant='inherit' className='font-size-14'>
                    {getTranslatedRawText('professionalOrder.extendedService.foldLine')}:
                </NsTypography>
                <NsButton startIcon='ns-icon-add black-color'
                    wrapperClassName='mx-1'
                    size='small'
                    onClick={this.addFoldLineClicked} />

                {!isEmpty(selectedFoldLinePreset) &&
                    <div className='font-size-12 ms-3'>
                        <span>{selectedFoldLinePreset?.title}</span>
                        <span><b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(selectedFoldLinePreset?.price)}</b></span>
                    </div>
                }
                {!isEmpty(customFoldLines) &&
                    <div className='font-size-12 ms-3'>
                        <b>{customFoldLines?.length}</b> <span>{getTranslatedRawText('professionalOrder.summaries.foldLineCount')}</span>
                        <b>&nbsp;({CommonHelper.currencyFormatByDefaultConfig(foldLinePrice * customFoldLines?.length)})</b>
                    </div>}
                {isShowFoldLineModal &&
                    <PoFoldLineModal masterFiles={masterFiles}
                        trimZone={OrderHelper.getTrimZone(this.props.routeId)}
                        showModal={isShowFoldLineModal}
                        closeModal={this.closeFoldLineModalClicked}
                        savedClicked={this.saveFoldLineModalClicked}
                        selectedFoldLinePreset={selectedFoldLinePreset}
                        customFoldLines={customFoldLines} />
                }

                {this.state.resultMessageClass}
            </div>

            : null
        );
    }
}

export default PoExtendedServiceFoldLine;