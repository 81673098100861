import React from 'react';

import NsModal from '../../../../components/modals/NsModal';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import BillBookPreviewModalContent from './BillBookPreviewModalContent';

class BillBookPreviewModal extends React.Component {

    render() {
        const { masterFile, trimZone, billBookCopy, billBookBindingType, billBookBindingSideType, billBookPrintColor,
            serialBoxDimension, serialStartFrom, selectedPerforations, selectedSerials, hasUnderhand } = this.props;

        return (
            <NsModal showModal={true}
                maxWidth='md'
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closePreviewBillBookModalClicked)}
                dialogTitle={getTranslatedRawText("regularOrder.billBook.previewBillBook")} >

                <BillBookPreviewModalContent masterFile={masterFile}
                    billBookCopy={billBookCopy}
                    billBookPrintColor={billBookPrintColor}
                    billBookBindingSideType={billBookBindingSideType}
                    billBookBindingType={billBookBindingType}
                    hasUnderhand={hasUnderhand}
                    serialStartFrom={serialStartFrom}
                    trimZone={trimZone}
                    serialBoxDimension={serialBoxDimension}
                    selectedSerials={selectedSerials}
                    selectedPerforations={selectedPerforations} />

            </NsModal >
        )
    }
}

export default BillBookPreviewModal;