import React, { Component } from 'react';
import clsx from 'clsx';
import Chip from '@mui/material/Chip';
import '../../assets/sass/components/chips/NsChip.scss';

class NsChip extends Component {
    render() {
        const { chipLabel, deleteChipClicked, size, className } = this.props;
        return (
            <Chip color="primary"
                deleteIcon={<i className="ns-icon-close d-flex align-items-center" />}
                className={clsx('chip mw-100', className)}
                label={chipLabel}
                onDelete={deleteChipClicked}
                size={size} />
        );
    }
}

export default NsChip;