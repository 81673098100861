import React from 'react';

import UpSidebarProfileSetting from './UpSidebarProfileSetting';
import UpSidebarUserOrders from './UpSidebarUserOrders';
import { isAgency } from '../../../utilities/ClaimHelper';
import UpSidebarUserReports from './UpSidebarUserReports';
import UpSidebarUserAgency from './UpSidebarUserAgency';
import UpSidebarUserInfo from './UpSidebarUserInfo';
import UpSidebarUserDashboard from './UpSidebarUserDashboard';
import Config from '../../../configs/Config';
import { getConfigObjectByCulture } from '../../../utilities/ConfigHelper';

class UpSidebarMain extends React.Component {

    render() {
        return (
            <div className='py-2 py-md-0' >
                <div className='px-md-0'>
                    <UpSidebarUserInfo refreshUserAccountDate={this.props.refreshUserAccountDate} />
                </div>
                <div className='px-md-0 py-2'>
                    <UpSidebarUserOrders onClick={this.props.onClick} />
                </div>
                <div className='px-md-0 pb-2'>
                    <UpSidebarUserReports onClick={this.props.onClick} />
                </div>
                {getConfigObjectByCulture(Config.showCashback) &&
                    <div className='px-md-0 pb-2'>
                        <UpSidebarUserDashboard onClick={this.props.onClick} />
                    </div>}
                {isAgency() && <div className='px-md-0 pb-2'>
                    <UpSidebarUserAgency onClick={this.props.onClick} />
                </div>
                }
                <div className='px-md-0'>
                    <UpSidebarProfileSetting onClick={this.props.onClick} />
                </div>
            </div>
        );
    }
}

export default UpSidebarMain;