import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsSetPageTitleAndDescription from '../../../../components/metaTags/NsSetPageTitleAndDescription';
import NsPagination from '../../../../components/pagination/NsPagination';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../../components/text/NsTypography';
import Enumerations from '../../../../configs/Enumerations';
import { GetCmsBlogFilterCategoriesService, GetCmsNewsFilterCategoriesService } from '../../../../services/singlePages/CmsService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import BlogNews from '../../BlogNews';
import BnBasesCategoryCard from '../bases/BnBasesCategoryCard';

class BnContentCategories extends React.Component {

    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.state = {
            categories: [],
            pageLength: 4,
            currentPageIndex: 1,
            isLoading: true,
            totalCount: 0,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        this.getCategories();
    }

    getCategories = () => {
        const { pageLength, currentPageIndex } = this.state;
        let parameters = { pageLength, currentPageIndex };
        this.setState({ isLoading: true });
        this.isBlog
            ? GetCmsBlogFilterCategoriesService(parameters, this.getCmsCategoriesCallback)
            : GetCmsNewsFilterCategoriesService(parameters, this.getCmsCategoriesCallback);
    }

    getCmsCategoriesCallback = (resultData, result, messageClassModal) => {
        const { currentPageIndex, pageLength } = this.state;
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            let totalCount = CommonHelper.getTotalPageCount(resultData.totalCount, pageLength);
            CommonHelper.redirectToCorrectPathIfInvalidPageNumber(currentPageIndex, totalCount, window.location.pathname, this.props)
            this.setState({ categories: resultData.dataItems, totalCount });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    };

    onPageChanged = (currentPageIndex) => this.setState({ currentPageIndex }, this.getCategories);

    mapCategories = () => {
        const { categories, isLoading } = this.state;
        return isLoading
            ? CommonHelper.createArray(4).map((item, index) =>
                <div key={index} className="d-inline d-md-inline-block col-sm-12 col-md-6 p-2" >
                    <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                        ]} />
                </div >
            )
            : isEmpty(categories)
                ? <NsTypography>{getTranslatedRawText('adminPanel.newsBlog.noAnyPost')}</NsTypography>
                : categories.map((category, index) =>
                    <Col key={index} xs={12} md={6} className=''>
                        <BnBasesCategoryCard category={category} />
                    </Col>
                )
    }

    render() {
        const { currentPageIndex, totalCount, resultMessageClass } = this.state;
        return <BlogNews >
            <Row className='g-2'>
                {this.mapCategories()}
            </Row>
            {totalCount > 1 && <div className='text-center mt-2'>
                <NsPagination variant='outlined'
                    showFirstButton={true}
                    showLastButton={true}
                    count={totalCount}
                    onChange={this.onPageChanged}
                />
            </div>
            }
            {resultMessageClass}
            <NsSetPageTitleAndDescription pageNumber={currentPageIndex}
                pageTitle={this.isBlog ? 'adminPanel.sidebar.blog' : 'adminPanel.sidebar.news'} />
        </BlogNews>
    }
}

export default BnContentCategories