import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetStoreProductsService } from '../../services/product/StoreProductService';
import NsToggleGroupButton from '../../components/buttons/NsToggleGroupButton';
import { categoryActionCreators } from '../../redux/reducers/CategoryReducer';
import NsCarouselSwiper from '../../components/carousel/NsCarouselSwiper';
import NsSkeleton from '../../components/skeletons/NsSkeleton';
import NsLabelLine from '../../components/labels/NsLabelLine';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from '../../configs/Enumerations';
import Links from '../../configs/links/AllLinks';
import Config from '../../configs/Config';

const HomeStoreProductSection = (props) => {

    const [selectedCategoryId, setSelectedCategoryId] = useState(1000000);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(categoryActionCreators.getStoreCategories());
        getData();
    }, [selectedCategoryId]);

    const getData = () => {
        setIsLoading(true);
        let parameters = {
            'Filter.categoryIds[0]': selectedCategoryId !== 1000000 ? selectedCategoryId : null,
            pageLength: 6,
            currentPageIndex: 1
        }
        GetStoreProductsService(parameters, getStoreProductsCallback);
    }

    const storeCategories = useSelector(state => {
        return {
            storeCategories: state.categories.storeCategories
        }
    });

    const getStoreProductsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError)
            setProducts(resultData);
    }

    const selectedStoreCategoryChanged = (selectedItem) => setSelectedCategoryId(selectedItem.id);
    const hasAccessToNewStoreProducts = Config.hasStoreProducts === true;

    return (hasAccessToNewStoreProducts && <div>
        <NsLabelLine linkTo={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allStoreProducts)}
            primaryTitle={getTranslatedRawText('adminPanel.sidebar.storeProducts')}
            secondaryTitle={''} />

        <div className='my-4 d-flex justify-content-center overflow-auto'>
            <NsToggleGroupButton isShowGroupLabel={true}
                items={storeCategories?.storeCategories.slice(0, CommonHelper.isMobileDevice() ? 3 : 5)}
                onClick={selectedStoreCategoryChanged}
                size='small'
                selectedItemId={selectedCategoryId} />
        </div>

        <div className='mb-4'>
            {storeProductsSection(products?.dataItems, isLoading)}
        </div>
    </div>);
};

function storeProductsSection(products, isLoading) {
    return isLoading
        ? <NsSkeleton isVisible={true}
            variant={[Enumerations.skeleton.rect,
            Enumerations.skeleton.rect,
            Enumerations.skeleton.rect,
            Enumerations.skeleton.rect]} />
        : <NsCarouselSwiper isStore={true} products={products} />

}

export default HomeStoreProductSection;