import React from 'react';

import Config from '../../../../configs/Config';
import CommonHelper from '../../../../utilities/CommonHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

const SocialMedias = () => {

    const socials = [
        {
            id: 1,
            label: 'whatsapp',
            link: Config.socialMedia.facebook,
            icon: 'ns-icon-facebook',
        },
        {
            id: 2,
            label: 'instagram',
            link: Config.socialMedia.instagram,
            icon: 'ns-icon-instagram',
        },
        {
            id: 3,
            label: 'facebook',
            link: (!!Config.socialMedia.whatsApp && !!Config.whatsApPhoneNumber) && CommonHelper.stringFormat(Config.socialMedia.whatsApp, Config.whatsApPhoneNumber),
            icon: 'ns-icon-whatsapp',
        },
    ]

    return (
        !isEmpty(socials) &&
        <div className='d-flex'>
            {socials.map((item, index) =>
                !!item.link && <a key={index} rel="nofollow" className='text-decoration-none text-color me-1' target="_blank" href={item.link}>
                    <i className={`${item.icon} h3`} />
                </a>
            )}
        </div>
    );
};

export default React.memo(SocialMedias);