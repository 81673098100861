const CommonTranslationEn = {

    common: {
        and: 'and',
        categories: 'Categories',
        category: 'Category',
        save: 'Save',
        select: "Select",
        discard: 'Discard',
        remove: 'Remove',
        yes: 'Yes',
        no: 'No',
        on: 'ON',
        off: 'OFF',
        add: 'Add',
        edit: 'Edit',
        ok: 'Ok',
        next: "Next",
        confirm: 'Confirm',
        cancel: 'Cancel',
        img: 'img',
        delete: 'Delete',
        filter: 'Filter',
        search: 'Search',
        pay: 'Pay',
        mm: 'mm',
        noDescription: 'No Description',
        currency: 'AED',
        alert: 'Alert',
        mb: 'MB',
        kb: "KB",
        help: 'Help',
        clear: 'Clear',
        omanTrn: 'OM',
        exportExcel: 'Export Excel',
        download: 'Download',
        showMore: 'Show More',
        showLess: 'Show Less',
        agree: 'I have read and agree to it',
        readMore: 'read more',
        send: 'send',
        imageFormat: 'image',
        reset: 'Reset',
        submit: 'Submit',
        about: 'about',
        messages: {
            areYouSureQuestion: 'Are you sure to delete {0} ?',
            areYouSureToDeleteDepartment: 'Are You Sure To Delete {0} Department?',
            areYouSureQuestionOrderWithoutParam: 'Are you sure to delete order(s)?',
            areYouSureQuestionOrder: 'Are you sure to delete «{0}» order?',
            areYouSureQuestionOrderWithLength: 'Are you sure to delete «{0}» orders?',
            areYouSureQuestionSingleOrderWithoutParam: 'Are you sure to delete order?',
            folderDeleteQuestion: 'All files and folders inside the current folder has been deleted,Are you sure to delete {0} ?',
            areYouSureQuestionWithOutParams: 'Are you sure to delete?',
            areYouSureUpdateMatchingStatusTypesQuestion: 'Are you sure to Update All Matching Status Types?',
            areYouSureMultiFileQuestionFileManager: 'Are you sure to delete {0} file(s)?',
            questionToGoProductDetail: 'By confirming this, the current order’s information is deleted and the product details are shown.',
            fileValidationError: 'A file name can not contain any of the following characters: \\ /?*:|"<>',
            folderValidationError: 'A folder name can not contain any of the following characters: \\ /?*:|"<>',
            chooseFileOrFolder: 'Choose file or folder',
            noFileChosen: 'No file has been selected, select your file',
            somethingIsWrong: 'Something is wrong, try again',
            selectFrontSide: 'Select front side first',
            discardNewFileMessage: 'Would you like to discard new file?',
            preMessageForSignIn: 'To start ordering sign in first',
            messageBeforeSignIn: 'To show prices sign in first',
            smallFilterSelected: 'filter(s) {0} is/are less than minimum size',
            duplicatedFilter: '(Filter {0} and {1})',
            duplicatedFilterText: 'remove ineffective filter {0}',
            notAuthorizedAreaText: 'change punch position. (this position is not valid)',
            duplicatedSerial: 'resolve serial\'s overlay',
            enter: 'Enter {0}',
            lengthCondition: '{0} must be at least {1} characters',
            select: 'Select {0}',
            errorResponse: 'reload the page ({0})',
            cancelUploadingFile: 'uploading file has canceled',
            areYouSureToCancelUploadingFile: 'Are you sure to cancel uploading?',
            unTrustableFile: 'This file content is unTrustable, close popup and open again',
            noFoldLineOption: 'There is no fold line options to select',
            noPromotionOption: 'There is no promotion',
            tokenExpired: 'Your token is expired, click Ok to sign in again',
            selectFileError: 'To add extended services, first you must upload your file.',
            foldLineDirectionError: 'remove all fold lines to change type. You can have only one fold line type at the same time.',
            serialNumberDirectionError: 'remove all serial numbers to change type. You can have only one Serial Number direction at the same time.',
            perforationDirectionError: 'remove all perforation lines to change type. You can have only one perforation line type at the same time.',
            foldLineChangeTabError: 'To make preset fold lines, remove all previous custom folding lines.',
            masterFileIsNotSelected: 'Front File is missing. upload the Front file first.',
            masterFrontFileIsNotSelected: 'Four-Color print is not selected',
            fileIsNotSelected: 'File is not selected',
            allLastInfoIsReset: 'With Replacing new file , previous files including front file, back file and extended services will be reset.',
            maxFoldLineMessage: 'impossible selecting more than {0} fold line',
            wrongIdNumber: '{0} is invalid',
            notSupportedExtendedServices: 'This product has {0}.We don\'t currently support this service',
            invalidContent: 'fill required fields',
            successStoreOrderPaymentMessage: 'order has been submitted successfully.',
            alertStoreOrderPaymentMessagePart1: 'If you can\'t find your order, go to the ',
            alertStoreOrderPaymentMessagePart2: ' page.',
            doYouWantDisplayCommentToAll: 'Do you want to display feedback to all users?',
            undisplayingCommentToAll: 'Do you want to get out of display-to-all mode?',
            updateMatchingStatusQuestionMessage: 'Are you sure to update {0} Record Matching Status?',
            orderNotReachedToPrintingState: 'The order has not yet reached the printing state; as soon as it does, an official invoice will be issued.',
            userInformationIsNotCompeleted: 'Due to incomplete profile information, a VAT invoice for this order will be issued with incomplete details. This may not be approved by the National Tax Administration. The opportunity to complete profile information and issue a VAT invoice is available until this order reaches the printing state.',
            activateProfileQuestion: 'Are you sure  to Activate "{0}" Profile?',
            deactivateProfileQuestion: 'Are you sure  to Deactivate "{0}" Profile?',
            userNotFoundTitle: 'Sorry, User Not Found :(',
            userNotFoundDescription: 'All the moment, there is no option available to provide feedback to the specific user.',
            customColumnOrder: '1. To reposition columns, simply drag them to your preferred location within the grid.',
            customeColumnResize: '2. The width of the columns is adjustable.',
            resetCustomColumnOrderAndResizeToDefault: '3. Clicking the “Reset” button will restore the columns’ arrangement and width to their default settings.',
            cashbackRemaningTimeMessage: 'Information have been submitted on {0} and due the possibility of submitting new orders and deleting or returning of new orders, this information may change after the mentioned date. Therefore, they present only a view of them in specific time and date.',
            orderMasterFileModalImageHelper: 'The image being viewed is a preview of the file at 72dpi. The final quality will match the original file\'s quality.',
            latDescription: 'Up is a unit for measuring printed sheets, and the number of Ups required varies depending on the product type.The system automatically calculates the number of Ups required for the uploaded file. The printing cost is also determined based on the number of Ups required for each file.'
        },

        label: {
            information: 'Information',
            next: 'Next',
            previous: 'Previous',
            back: 'Back',
            backToSelectFile: 'Back to select file',
            confirmFiles: 'Confirm Files',
            downloadGuide: 'Download Guidance {0}',
            addToCard: 'Add to Cart',
            startProcessing: 'Start Processing',
            showResult: 'Show Result',

        },
        tour: {
            lastStep: 'Done! click to close tour',
            of: 'of',
            badgeContent: '{0} of {1}'
        },

        fileExtension: {
            ai: 'ai',
            bmp: 'bmp',
            csv: 'csv',
            doc: 'doc',
            docx: 'docx',
            gif: 'gif',
            gif: 'gif',
            jpeg: 'jpeg',
            jpg: 'jpg',
            mp3: 'mp3',
            pdf: 'pdf',
            png: 'png',
            psd: 'psd',
            svg: 'svg',
            rar: 'rar',
            tif: 'tif',
            txt: 'txt',
            webp: 'webp',
            xls: 'xls',
            xlsx: 'xlsx',
            zip: 'zip',
            mp4: 'mp4'
        },

        breadcrumbs: {
            signIn: 'Sign in',
            signup: 'Sign up',
            forgotPassword: 'Forgot Password',
            changePassword: 'Change Password',
            successChangePassword: 'Success Change Password',
            signupActivationMobile: 'Activation Mobile',
            signupAccountInformation: 'Create Account',
            signupWelcome: 'Sign up Welcome',
            products: 'Group Sheets',
            storeProducts: 'Store Products',
            productDetails: 'Product Details',
            regularOrder: 'Regular Order',
            professionalOrder: 'Professional Order',
            profile: 'Profile',
            fileManager: 'File Manager',
            userOrderCart: 'User Order Cart',
            profileInformation: 'Profile Information',
            notificationSetting: 'Notification Setting',
            autoDeliverySetting: 'Auto Shipping Setting',
            ordersCart: 'Orders-Cart',
            ordersPreparing: 'Orders-Preparing',
            ordersInProcess: 'Orders-In Process',
            ordersReady: 'Orders-Ready To Deliver',
            ordersArchive: 'Orders-Archive',
            allOrders: 'All-Order',
            orderReport: 'Order-Report',
            address: 'Addresses',
            about: 'About Us',
            contactUs: 'Contact Us',
            FAQs: 'FAQs',
            gallery: 'Gallery',
            galleryCategories: 'Gallery Category Posts',
            privacyPolicy: 'Privacy Policy',
            warrantyTerms: 'Warranty Terms',
            deliveryRequest: 'My Shippings',
            paymentReport: 'Payment Report',
            debitCreditReport: 'Debit-Credit Report',
            ourEquipment: 'Our Equipment',
            termsOfUse: 'Terms of Use',
            help: 'Help',
            siteMap: 'Site Map',
            festival: 'Festival',
            festivals: 'Festivals',
            blogs: 'Blogs',
            news: 'News',
            blogCategories: 'Blog Categories',
            blogCategoryPosts: 'Blog Category Posts',
            blogPostDetail: 'Blog Post Detail',
            blogPosts: 'Blog Posts',
            newsCategories: 'News Categories',
            newsCategoryPosts: 'News Category Posts',
            newsPostDetail: 'News Post Detail',
            newsPosts: 'News Posts',
            detail: 'Detail',
            chooseGateway: 'Choose Gateway',
            resultPayment: 'Result Payment',
            agencySetting: 'Agency Setting',
            downloadCenter: 'Download Center',
            downloadDetail: 'Download Detail',
            agencyInformation: 'Agency Information',
            agencyCustomers: 'Agency Customers',
            agencyCustomersOrders: 'Agency Customers Orders',
            agencyProducts: 'Agency Products',
            agencyShipping: "Shipping",
            exception: 'Exception',
            specialNeeds: 'Special Needs',
            paperBag: 'Paper Bag',
            paperBagFaq: 'FAQs',
            paperBagGallery: 'Gallery',
            paperBagExploreProduct: 'Paper Bag Explanation',
            saveOutputInPdfFormat: 'Save Out put In PDF Format',
            productCategories: 'products Categories',
            cashback: 'Cashback',
            dashboard: 'Dashboard',
            shoppingBagExploreProduct: 'Shopping Bag Explore Product',
            publicProfile: '{0} Public Profile',
            instantDiscounts: 'Instant Discounts',
        },

        socialChannels: {
            instagram: 'Instagram',
            facebook: 'Facebook',
            whatsapp: 'Whatsapp',
        },

        exception: {
            title404: 'Sorry, We can\'t find that :(',
            description404: 'Perhaps we can help you find the content you\'re looking for:'
        },

        currencyUnits: {
            hundreds: 'Hundreds',
            thousands: 'Thousands',
            millions: 'Millions',
            billions: 'Billions',
            trillions: 'Trillions',
        },

        numbers: {
            zero: 'zero ',
            negative: 'negative ',
            one: 'one ',
            two: 'two ',
            three: 'three ',
            four: 'four ',
            five: 'five ',
            six: 'six ',
            seven: 'seven ',
            eight: 'eight ',
            nine: 'nine ',
            ten: 'ten ',
            eleven: 'eleven ',
            tweleve: 'twelve ',
            thirteen: 'thirteen ',
            fourteen: 'fourteen ',
            fifteen: 'fifteen ',
            sixteen: 'sixteen ',
            seventeen: 'seventeen ',
            eighteen: 'eighteen ',
            nineteen: 'nineteen ',
            twenty: 'twenty ',
            thirty: 'thirty ',
            forty: 'forty ',
            fifty: 'fifty ',
            sixty: 'sixty ',
            seventy: 'seventy ',
            eighty: 'eighty ',
            ninety: 'ninety ',
            oneHundred: 'one hundred ',
            twoHundred: 'two hundred ',
            threeHundred: 'three hundred ',
            fourHundred: 'four hundred ',
            fiveHundred: 'five hundred ',
            sixHundred: 'six hundred ',
            sevenHundred: 'seven hundred ',
            eightHundred: 'eight hundred ',
            nineHundred: 'nine hundred ',
            thousand: 'thousand',
            million: 'million',
            billion: 'billion',
            trillion: 'trillion',
            quadrillion: 'quadrillion',
            quintillion: 'quintillion',
            sextillion: 'sextillion',
            septillion: 'septillion',
            octillion: 'octillion',
            nonillion: 'nonillion',
            decillion: 'decillion',
            undecillion: 'undecillion',
            duodecillion: 'duodecillion',
            tredecillion: 'tredecillion',
            quattuordecillion: 'quattuordecillion',
            quindecillion: 'quindecillion',
            sexdecillion: 'sexdecillion',
            septendecillion: 'septendecillion',
            octodecillion: 'octodecillion',
            novemdecillion: 'novemdecillion',
            vigintillion: 'vigintillion',
            tenths: 'tenths',
            hundredths: 'hundredths',
            thousandths: 'thousandths',
            tenThousandths: 'ten thousandths',
            hundredThousandths: 'hundred thousandths',
            millionths: 'millionths',
            tenMillionths: 'ten millionths',
            hundredMillionths: 'hundred millionths',
            billionths: 'billionths',
            tenBillionths: 'ten billionths',
            hundredBillionths: 'hundred billionths',
            point: 'point ',
            outOfRange: 'Out of Range '
        }
    },

};

export default CommonTranslationEn;