import React from "react";


import NsButton from "../../../../components/buttons/NsButton";
import NsPageMainContentWrapper from "../../../../components/cards/NsPageMainContentWrapper";
import NsSetPageTitleAndDescription from "../../../../components/metaTags/NsSetPageTitleAndDescription";
import Enumerations from "../../../../configs/Enumerations";
import Links from "../../../../configs/links/AllLinks";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../../utilities/CultureHelper";
import UrlHelper from "../../../../utilities/UrlHelper";
import SinglePage from "../../SinglePage";
import DownloadCategoryContent from "./DownloadCategoryContent";
import { connect } from "react-redux";
import NsCard from "../../../../components/cards/NsCard";
import PdBaseSimilarProducts from "../../../productDetails/bases/PdBaseSimilarProducts";

class DownloadCategoryDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refreshTimeStamp: null
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({
                refreshTimeStamp: new Date()
            })
        }
    }

    backToDownloadCenterClicked = () => {
        const { params } = this.props?.match;
        this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.download), params?.id, params?.name));
    }

    render() {
        const { name } = this.props?.match?.params;
        return (
            <SinglePage key={this.state.refreshTimeStamp}>
                <NsPageMainContentWrapper header={getTranslatedRawText('singlePage.download.downloadDetail')}
                    icon='ns-icon-download h1' className='mb-3'>

                    <div className="d-flex">
                        <NsButton label='singlePage.download.downloadCenter'
                            startIcon={CommonHelper.getBackIconByCulture()}
                            className='primary-outline'
                            onClick={this.backToDownloadCenterClicked} />
                    </div>

                    <DownloadCategoryContent activeCategoryId={UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id)}
                        isSinglePage={true} />
                </NsPageMainContentWrapper>
                <NsSetPageTitleAndDescription pageTitle='singlePage.download.downloadCenter'
                    selectedCategoryNames={CommonHelper.replaceHyphenToSpace(name)} />
                <NsCard className='px-3'>
                    <PdBaseSimilarProducts getSimilarProductsDate={''}
                        categoryIds={[]}
                        selectedCurrency={this.props.selectedCurrency?.id} />
                </NsCard>
            </SinglePage>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedCurrency: state.currencies.selectedCurrency
    };
}

export default connect(mapStateToProps)(DownloadCategoryDetail);