import React, { Component } from 'react';
//import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import '../../assets/sass/components/maps/NsMaps.scss';
import Config from '../../configs/Config';
import CommonHelper from '../../utilities/CommonHelper';


class NsGoogleMap extends Component {

    mapClicked = (e) => {
        CommonHelper.safeFunctionCall(this.props.onClick, e.latLng.lat(), e.latLng.lng());
    }

    render() {
        const { lat, lng } = this.props;
        const center = { lat: lat || Config.defaultMapCenter.lat, lng: lng || Config.defaultMapCenter.lng };

        return (<div>Not Support!</div>)
        // return (
        //     <MyMapComponent isMarkerShown={!!lat}
        //         lat={lat}
        //         lng={lng}
        //         defaultCenter={center}
        //         googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
        //         loadingElement={<div style={{ height: `100%` }} />}
        //         mapElement={<div style={{ height: `100%` }} />}
        //         containerElement={<div style={{ height: `200px` }} />}
        //         mapClicked={this.mapClicked} />
        // );
    }
}

// const MyMapComponent = withScriptjs(withGoogleMap((props) =>
//     <GoogleMap
//         defaultZoom={11}
//         defaultCenter={props.defaultCenter}
//         onClick={e => props.mapClicked(e)}
//     >
//         {props.isMarkerShown && <Marker onClick={props.onMarkerClick} position={{ lat: props.lat, lng: props.lng }} />}
//     </GoogleMap>
// ));


export default NsGoogleMap;