import clsx from 'clsx';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import NsButton from '../../components/buttons/NsButton';
import NsLabelNoData from '../../components/labels/NsLabelNoData';
import NsSkeleton from '../../components/skeletons/NsSkeleton';
import NsTab from '../../components/tabs/NsTab';
import NsTypography from '../../components/text/NsTypography';
import ConstantsWithTranslation from '../../configs/ConstantsWithTranslation';
import Enumerations from '../../configs/Enumerations';
import { GetCmsAnnouncementsService, GetCmsNewsFilterPostService } from '../../services/singlePages/CmsService';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import DateHelper from '../../utilities/DateHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import BnBasesPostMedia from '../blogs/contents/bases/BnBasesPostMedia';
import StorageHelper from '../../utilities/StorageHelper';
import NsModal from '../../components/modals/NsModal';
import NsStickyButton from '../../components/buttons/NsStickyButton';
import CommonHelper from '../../utilities/CommonHelper';
import UrlHelper from '../../utilities/UrlHelper';
import FeedbackModal from '../profileSettings/modals/FeedbackModal';

class HomeAnnouncementSection extends Component {

    constructor(props) {
        super(props);
        this.tabs = ConstantsWithTranslation.getHomeAnnouncementTabs();
        this.storageAnnouncementIds = StorageHelper.getFromStorage(Enumerations.storage.announcementIds) || [];
        this.state = {
            announcements: [],
            announcementId: null,
            selectedTabIndex: 1,
            displayAnnouncement: null,
            showAnnouncementModal: false,
            showFeedBackModal: false,
            isLoading: true,
            posts: [],
            resultMessageClass: (<></>),
        }
    }

    componentDidMount = () => GetCmsAnnouncementsService(this.getCmsAnnouncementsCallback);

    getCmsAnnouncementsCallback = (resultData, result, messageClassModal) => {
        GetCmsNewsFilterPostService({ PageLength: 10, CurrentPageIndex: 1 }, this.getCmsFilterPostCallback);
        if (!result.data.hasError)
            this.setState({ announcements: resultData }, () => {
                this.checkDisplayAsModal(resultData);
            });
    }

    getCmsFilterPostCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ posts: resultData.dataItems });
    };

    tabChanged = (newValue, selectedTab) => {
        newValue !== this.state.selectedTabIndex && this.setState({ selectedTabIndex: newValue, selectedTabId: selectedTab.id })
    }

    checkDisplayAsModal = (announcements) => {
        if (isEmpty(announcements)) return;
        let displayAnnouncement = announcements.map(announcement => {
            if (!announcement.isDisplayAsModal) return;
            if (this.storageAnnouncementIds.some(id => id === announcement.id)) return;
            return announcement;
        });
        displayAnnouncement = CommonHelper.clearArrayFromEmptyItem(displayAnnouncement);
        if (!isEmpty(displayAnnouncement)) {
            this.setState({ displayAnnouncement: displayAnnouncement[0], showAnnouncementModal: true });
        }
    }

    colseAnnouncementModal = () => {
        StorageHelper.saveInStorage(Enumerations.storage.announcementIds, [...this.storageAnnouncementIds, this.state.displayAnnouncement?.id]);
        this.storageAnnouncementIds = [...this.storageAnnouncementIds, this.state.displayAnnouncement?.id];
        this.setState({ showAnnouncementModal: false }, () => {
            this.checkDisplayAsModal(this.state.announcements);
        });
    }

    toggleFeedbackModal = (id, isMessageModal) => {
        this.setState(prevState => ({
            resultMessageClass: isMessageModal ? id : prevState.resultMessageClass,
            showFeedBackModal: !prevState.showFeedBackModal,
            announcementId: id
        }));
    }

    render() {
        const { posts, announcements, selectedTabIndex, isLoading, showAnnouncementModal, displayAnnouncement, showFeedBackModal, announcementId, resultMessageClass } = this.state;
        const announcementTitle = announcements.filter(item => item.id === announcementId);
        return (
            <div className='announce d-flex background-white-color flex-column border w-100 p-2'>
                <div className=''>
                    <NsTab value={selectedTabIndex}
                        tabs={this.tabs}
                        tabClassName='normal-tab'
                        onChange={this.tabChanged} />
                </div>
                {isLoading
                    ? <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.text,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                        ]} />
                    : <>
                        <div className={this.state.selectedTabIndex === 0 ? 'd-none' : 'custom-scrollbar'}>
                            <Announcement announcements={announcements}
                                isAuthenticated={this.props.isAuthenticated}
                                toggleFeedbackModal={this.toggleFeedbackModal} />
                        </div>
                        <div className={this.state.selectedTabIndex !== 0 ? 'd-none' : 'p-2 custom-scrollbar'}>
                            <NewsPost posts={posts} />
                        </div>
                    </>
                }
                <div className='d-flex justify-content-end'>
                    {false && <NsButton label='history'
                        className='primary-text' />
                    }
                </div>
                {showAnnouncementModal &&
                    <AnnouncementModal displayAnnouncement={displayAnnouncement}
                        toggleFeedbackModal={this.toggleFeedbackModal}
                        isAuthenticated={this.props.isAuthenticated}
                        colseAnnouncementModal={this.colseAnnouncementModal} />
                }
                {showFeedBackModal &&
                    <FeedbackModal id={announcementId}
                        label={announcementTitle[0]?.title}
                        feedbackType={Enumerations.feedbackTypes.announcementContent}
                        closeModal={this.toggleFeedbackModal} />
                }
                {resultMessageClass}
            </div >
        );
    }
}

function Announcement(props) {
    return !isEmpty(props.announcements)
        ? <div className='d-flex flex-column gap-2'>
            {props.announcements.map((announce, index) =>
                <div key={index} className={clsx('d-flex flex-column p-2 rounded', index % 2 !== 0 && 'background-color')} >
                    <NsTypography variant='inherit' className='font-size-14 fw-bold py-1'>
                        {announce.title}
                    </NsTypography>
                    <NsTypography variant='inherit' className='black-color font-size-14 w-100'>
                        {announce.mainContent}
                    </NsTypography>
                    <NsTypography variant='inherit' className='text-color font-size-12 pt-2'>
                        {DateHelper.getDateAndTime(announce.createAt)}
                    </NsTypography>
                    <div className='d-flex justify-content-end align-items-center'>
                        {announce.linkUrl && <Link to={UrlHelper.linkUrlHandler(announce.linkUrl, window.location.host).url}
                            target={UrlHelper.linkUrlHandler(announce.linkUrl, window.location.host).target}
                            className='text-decoration-none w-auto'>
                            <NsButton size='small'
                                startIcon=''
                                className='secondary-text font-size-10'>
                                {isEmpty(announce.linkText) ? getTranslatedRawText('common.showMore') : announce.linkText}
                            </NsButton>
                        </Link>
                        }
                        {announce.isFeedbackActive && props.isAuthenticated && <NsButton label='adminPanel.feedback.sendFeedback'
                            size='small'
                            wrapperClassName={!announce.linkUrl ? 'ms-auto' : 'ms-1'}
                            startIcon=''
                            className='secondary-text font-size-10'
                            onClick={() => props.toggleFeedbackModal(announce?.id)} />
                        }
                    </div>
                </div>
            )}
        </div>
        : <div className='d-flex justify-content-center'>
            <NsLabelNoData label='home.bannerSection.noAnyAnnouncement' />
        </div>
}

function NewsPost(props) {
    return !isEmpty(props.posts)
        ? props.posts.map(post =>
            <div key={post?.id} className='mb-2'>
                <BnBasesPostMedia post={post}
                    otherClass='title-color' />
            </div>)
        : <div className='d-flex justify-content-center'>
            <NsLabelNoData label='home.bannerSection.noAnyNews' />
        </div>
}

function AnnouncementModal(props) {
    const { displayAnnouncement, isAuthenticated } = props;
    const buttons = [
        displayAnnouncement?.isFeedbackActive && isAuthenticated && CommonHelper.createStickyButtonItem('profile.feedback.feedback', '', () => props.toggleFeedbackModal(displayAnnouncement?.id), 'primary'),
        CommonHelper.createStickyButtonItem('common.ok', '', props.colseAnnouncementModal, 'primary-outline'),
    ];
    return <NsModal showModal={true}
        maxWidth='sm'
        closeModal={props.colseAnnouncementModal}
        actions={<NsStickyButton stickyButtons={buttons} />}>
        <Announcement announcements={[displayAnnouncement]} />
    </NsModal>
}

export default HomeAnnouncementSection;