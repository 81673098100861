import { List, ListItem, ListItemText } from '@mui/material';
import clsx from 'clsx';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../../assets/sass/components/productsList/NsProductsList.scss';
import Enumerations from '../../configs/Enumerations';
import Links from '../../configs/links/AllLinks';
import { GetProductNameListService } from '../../services/product/ProductService';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import NsSearchInput from '../inputs/NsSearchInput';
import NsSkeleton from '../skeletons/NsSkeleton';
import NsTypography from '../text/NsTypography';

class NsProductsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchResultList: [],
            search: '',
            productId: this.props.productId,
            productList: [],
            isLoadingProducts: false,
            resultMessageClass: (<></>),
        }
    }

    searchTextChanged = (searchText) => {
        let searchResultList = this.state.productList;
        if (searchText !== '') {
            const searchKeys = searchText.toLowerCase().split(' ');
            searchResultList = this.state.productList.filter(productInfo => searchKeys.every(key => productInfo.productName.toLowerCase().indexOf(key) >= 0))
        }
        this.setState({ search: searchText, searchResultList: searchResultList })
    };

    componentDidMount = () => {
        this.getProducts(this.props.selectedCategoryId, () => {
            !isEmpty(this.state.productList) && !this.props.productId && CommonHelper.safeFunctionCall(this.props.selectedIdChanged, this.state.productList[0]?.productId),
                CommonHelper.safeFunctionCall(this.props.productsLoaded, true)
        })
    }

    getProducts = (selectedCategoryId, setDefaultProductIdCallBack) => {
        this.setState({ isLoadingProducts: true });
        GetProductNameListService(selectedCategoryId || null, (resultData, result, messageClassModal) => this.getProductsCallback(resultData, result, messageClassModal, setDefaultProductIdCallBack));
    }

    getProductsCallback = (resultData, result, messageClassModal, setDefaultProductIdCallBack) => {
        if (!result?.data?.hasError) {
            this.setState({
                ...this.state,
                productList: resultData,
                searchResultList: resultData,
                isLoading: false,
                isLoadingProducts: false
            }, () => {
                !!setDefaultProductIdCallBack && setDefaultProductIdCallBack();
            });
        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false, isLoadingProducts: false },
                () => CommonHelper.safeFunctionCall(this.props.productsLoaded, false))
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.printCirculations !== this.props.printCirculations)
            this.setState({ productId: this.props.printCirculations[0]?.key });

        if (prevProps.selectedCategoryId !== this.props.selectedCategoryId)
            this.getProducts(this.props.selectedCategoryId);

        if (prevProps.productId !== this.props.productId && !prevProps.productId) {
            this.setState({ productId: this.props.productId });
        }
    }

    productIdChanged = (product) => {
        this.setState({ productId: product.productId },
            () => CommonHelper.safeFunctionCall(this.props.selectedIdChanged, this.state.productId))
    }

    render() {
        return (
            <>
                <div className='w-100 product-list__search'>
                    <NsSearchInput hasSearchIcon={true}
                        value={this.state.search}
                        handleSearchTextChanged={this.searchTextChanged}
                        placeHolder={getTranslatedRawText('product.common.searchPlaceHolder')}
                        className='py-2 background-element-color'
                        inputClassName='background-element-color' />
                </div>
                {this.state.isLoadingProducts
                    ? <>
                        <NsSkeleton isVisible={true} variant={[Enumerations.skeleton.text]} />
                        <NsSkeleton isVisible={true} variant={[Enumerations.skeleton.text]} />
                        <NsSkeleton isVisible={true} variant={[Enumerations.skeleton.text]} />
                        <NsSkeleton isVisible={true} variant={[Enumerations.skeleton.text]} />
                    </>
                    : isEmpty(this.state.productList)
                        ? <NsTypography className='d-flex pt-3 justify-content-center'>{getTranslatedRawText('professionalOrder.noProductsFound')}</NsTypography>
                        : <div className='list-container mb-3' >

                            {!isEmpty(this.state.searchResultList) ? <List className='mt-0 pt-0'>
                                {this.state.searchResultList.map((product, index) => {
                                    return (
                                        <Link className='list-item-parent' key={index} to={this.props.isModal ? "#"
                                            : CommonHelper.stringFormat(
                                                getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.productDetails),
                                                product.productId, product.productName.replace(/\s+/g, '-'))}>
                                            <ListItem dense={true} selected={parseInt(this.state.productId) == product.productId}
                                                className={clsx('list', index % 2 === 1 && 'odd-list')}
                                                alignItems="flex-start"
                                                button={true}
                                                onClick={() => this.productIdChanged(product)}>
                                                <ListItemText>{product.productName}</ListItemText>
                                            </ListItem>
                                        </Link>
                                    )
                                })}
                            </List> : <NsTypography className='d-flex mt-1 justify-content-center'>{getTranslatedRawText('components.nsProductsList.noProductMatch')}</NsTypography>}
                        </div>
                }
                {this.state.resultMessageClass}
            </>
        );
    }
}

export default NsProductsList;