import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import NsDropdown from '../../../../components/dropdowns/NsDropdown';
import NsTour from '../../../../components/tour/NsTour';
import Enumerations from '../../../../configs/Enumerations';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import HeaderTours from '../../../bases/tours/HeaderTours';

class BottomHeader extends React.Component {

    constructor(props) {
        super(props);
        this.tourSteps = HeaderTours.getHeaderTour();
    }

    navLinkItems = () => {
        return (
            <Nav className='d-flex px-3'>
                {this.props.linkItems.map((item, index) => {
                    if (!item) return;
                    return <Nav.Item key={index}
                        className='px-2 align-self-center' >
                        <Link to={!!item.isExternalLink ? { pathname: item.link } : item.link} target={!!item.isExternalLink ? "_blank" : "_self"}
                            onClick={item.showItem ? this.closeSideMenu : (event) => event.preventDefault()}
                            className={`text-decoration-none font-size-14 pe-lg-1 pe-xl-2 active ${!item.showItem && 'text-muted'} ${item?.tourName}`} >
                            {getTranslatedRawText(item.text)}
                        </Link>
                    </Nav.Item>
                })}
            </Nav >
        );
    }

    render() {
        const showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.headerNavbar);

        return (
            <div className=" d-none d-lg-flex pt-1 align-items-center">
                <NsDropdown className="categories col-sm-3 text-start text-white py-2 rounded-top"
                    toggleClassName=" d-flex align-self-center align-items-center"
                    title={getTranslatedRawText('headers.bottom.allProductsCategories')}
                    iconBeforeTitle="ns-icon-menu h5 m-0"
                    iconBeforeTitleClassName="text-white align-middle mx-3"
                    label='main-category'
                    rel='canonical'
                    menuClassName="background-red-color w-100 mt-0 pb-3 rounded-0"
                    items={this.props.categories?.filter(item => item.id !== 0)} />

                {this.navLinkItems()}

                {this.props.isInHome && !!this.props.username && showTour && <NsTour steps={this.tourSteps}
                    id={Enumerations.tourGuide.headerNavbar} />
                }

            </div>
        );
    }
}

export default BottomHeader;