import React, { Component } from 'react';
import '../../assets/sass/components/addToHomeScreen/NsAddToHomeScreen.scss';
import Config from '../../configs/Config';
import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import StorageHelper from '../../utilities/StorageHelper';
import NsButton from '../buttons/NsButton';
import NsImage from '../images/NsImage';
import NsModal from '../modals/NsModal';
import NsTypography from '../text/NsTypography';
import { getTitleMetaTagByCulture } from '../metaTags/NsSetPageTitleAndDescription';

class NsAddToHomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            beforeInstallPromptEventData: null,
            showAddToHomeScreenBanner: false
        }
    }

    componentDidMount() {
        if (!window.matchMedia('(display-mode: standalone)').matches) {
            CommonHelper.isIosDevice() && this.setState({
                showAddToHomeScreenBanner: !StorageHelper.getFromStorage(Enumerations.storage.hideA2hs)
            })
            window.addEventListener('beforeinstallprompt', (event) => {
                event.preventDefault();
                this.setState({
                    beforeInstallPromptEventData: event,
                    showAddToHomeScreenBanner: !StorageHelper.getFromStorage(Enumerations.storage.hideA2hs)
                })
            });
        }
    }

    acceptButtonClicked = (e) => {
        this.state.beforeInstallPromptEventData.prompt()
        StorageHelper.saveInStorage(Enumerations.storage.hideA2hs, true)
        this.setState({
            showAddToHomeScreenBanner: false
        })

        window.removeEventListener('beforeinstallprompt', () => {
            this.setState({
                beforeInstallPromptEventData: null,
            })
        })
    }

    closeModal = () => {
        this.setState({ showAddToHomeScreenBanner: false });
        StorageHelper.saveInStorage(Enumerations.storage.hideA2hs, true)

        window.removeEventListener('beforeinstallprompt', () => {
            this.setState({
                beforeInstallPromptEventData: null,
            })
        })
    }

    aboutTitle = () => CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.logo"), getTitleMetaTagByCulture);

    render() {
        return (
            this.state.showAddToHomeScreenBanner && CommonHelper.isMobileDevice() && <>
                {CommonHelper.isIosDevice()
                    ? <div className='h-100 w-100 background-black-color text-white text-center mx-auto d-flex flex-column justify-content-around py-5 px-3 position-absolute a2hs__ios'>
                        <div>
                            <NsImage src={Config.siteLogo}
                                width={'200'}
                                imgAlt={this.aboutTitle()} />
                            <NsTypography variant='inherit' className="mt-4 fw-bold font-size-18">{CommonHelper.stringFormat(getTranslatedRawText("components.addToHomeScreen.ios.installAppText"), getTitleMetaTagByCulture)}</NsTypography>
                        </div>

                        <div className='text-start'>
                            <div className='mb-4 d-flex align-items-center'>
                                <i className='ns-icon-close me-3 h1' />
                                <NsTypography className='font-size-18'>
                                    {CommonHelper.stringFormatWithBold(getTranslatedRawText('components.addToHomeScreen.ios.firstStep'), getTranslatedRawText('components.addToHomeScreen.ios.share'))}
                                </NsTypography>
                            </div>
                            <div className='mb-4 d-flex align-items-center'>
                                <i className='ns-icon-close me-3 h1' />
                                <NsTypography className='font-size-18'>
                                    {CommonHelper.stringFormatWithBold(getTranslatedRawText('components.addToHomeScreen.ios.secondStep'), getTranslatedRawText('components.addToHomeScreen.ios.a2hc'))}
                                </NsTypography>
                            </div>
                            <div className='mb-4 d-flex align-items-center'>
                                <i className='ns-icon-close me-3 h1' />
                                <NsTypography className='font-size-18'>
                                    {CommonHelper.stringFormatWithBold(getTranslatedRawText('components.addToHomeScreen.ios.thirdStep'), getTranslatedRawText('components.addToHomeScreen.ios.add'))}
                                </NsTypography>
                            </div>
                        </div>

                        <NsButton label="components.addToHomeScreen.ios.understood" className="primary" onClick={this.closeModal} />

                    </div>
                    : <NsModal showModal={true} dialogTitleClassName={'background-black-color'}
                        dialogContentClassName={'background-black-color'}
                        titleCloseIconClassName={'text-white'}
                        showCloseButtonWithoutTitle={true}
                        closeModal={this.closeModal}>
                        <div className='text-white text-center mx-auto'>
                            <NsImage src={Config.siteLogo}
                                width={'200'}
                                imgAlt={this.aboutTitle()}
                            />
                            <NsTypography variant='inherit' className="mt-4">{CommonHelper.stringFormat(getTranslatedRawText("components.addToHomeScreen.installAppText"), getTitleMetaTagByCulture)}</NsTypography>
                            <NsButton label={"components.addToHomeScreen.install"} className="primary mt-5" onClick={this.acceptButtonClicked} />
                            <NsButton label="components.addToHomeScreen.neverMind" className="secondary mt-2" onClick={this.closeModal} />
                        </div>
                    </NsModal>
                }
            </>
        )
    }
}

export default NsAddToHomeScreen;
