import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import { GetFeedbackActivityHistoryService, UpdateFeedbackDisplayToAllStateService } from '../../../../services/adminPanel/AdminFeedbackService';
import EnumerationTranslationHelper from '../../../../utilities/EnumerationTranslationHelper';
import ApAnnouncementViewModal from '../../contents/announcements/ApAnnouncementViewModal';
import ApBaseFeedbackAddActivityModal from './ApBaseFeedbackAddActivityModal';
import NsMessageClassModal from '../../../../components/modals/NsMessageClassModal';
import ApBaseUserInfoModal from '../../managements/users/bases/ApBaseUserInfoModal';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseMapActionButtons from '../../bases/ApBaseMapActionButtons';
import NsTypography from '../../../../components/text/NsTypography';
import CommonHelper from '../../../../utilities/CommonHelper';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import NsModal from '../../../../components/modals/NsModal';
import NsLabelBox from '../../../../components/labels/NsLabelBox';
import Enumerations from '../../../../configs/Enumerations';
import ApBaseFeedbackMetrics from './ApBaseFeedbackMetrics';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsButton from '../../../../components/buttons/NsButton';
import DateHelper from '../../../../utilities/DateHelper';
import ApShareSheetOrderDetailModal from '../../managements/orders/shareSheets/ApShareSheetOrderDetailModal';
import { getFeedbackBasicInfos } from '../../../../utilities/BasicInfoHelper';
import NsBasicInfoLabelBox from '../../../../components/labels/NsBasicInfoLabelBox';

const ApBaseFeedbackDetailModal = (props) => {

    const { selectedFeedback, getFeedbackDtoService, hasAccessInfo } = props;
    const [feedbackInfo, setFeedbackInfo] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [showFeedbackAddActivityModal, setShowFeedbackAddActivityModal] = useState(false);
    const [showAnnouncementInfoModal, setShowAnnouncementInfoModal] = useState(false);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getfeedbackBasicInfo();
    }, []);

    const handlers = {
        toggleUserInfoModal: () => hasAccess([UserClaimEnumerations.getApCustomerById]) && setShowUserInfoModal(!showUserInfoModal),

        toggleAnnouncementInfoModal: () => setShowAnnouncementInfoModal(!showAnnouncementInfoModal),

        toggleOrderDetailModal: () => setShowOrderDetailModal(!showOrderDetailModal),

        toggleFeedbackAddActivityModal: (confirm, isResultMessageClass) => {
            setShowFeedbackAddActivityModal(!showFeedbackAddActivityModal);
            !!confirm && getfeedbackBasicInfo();
            !!confirm && props?.updateFeedbackOrdersGrid();
            isResultMessageClass && setResultMessageClass(confirm);
        },

        toggleMessageModal: (confirm) => {

            setShowMessageModal(!showMessageModal);
            if (!!confirm) {
                setIsLoadingInfo(true);
                UpdateFeedbackDisplayToAllStateService(selectedFeedback?.userFeedbackId, !feedbackInfo?.isPublic, updateFeedbackDisplayToAllStateCallback);
            }
        },
    }

    const getfeedbackBasicInfo = () => {
        if (!hasAccessInfo) return;
        setIsLoadingInfo(true);
        setIsLoadingFeedback(true);
        getFeedbackDtoService(selectedFeedback, getFeedbackBasicInfoCallback);
    }

    const updateFeedbackDisplayToAllStateCallback = (resultData, result, messageClassModal) => {
        setIsLoadingInfo(false);
        setResultMessageClass(messageClassModal);
        getfeedbackBasicInfo();
        props?.updateFeedbackOrdersGrid();
    }

    const getFeedbackActivityHistoryCallback = (resultData, result, messageClassModal) => {
        setIsLoadingFeedback(false);
        if (!result.data.hasError) {
            setFeedback(resultData);
        } else
            setResultMessageClass(messageClassModal);
    }

    const getFeedbackBasicInfoCallback = (resultData, result, messageClassModal) => {
        setIsLoadingInfo(false);
        if (!result.data.hasError) {
            setFeedbackInfo(resultData);
            GetFeedbackActivityHistoryService(selectedFeedback, resultData?.userId || selectedFeedback?.employeeId, getFeedbackActivityHistoryCallback)
        } else
            setResultMessageClass(messageClassModal);
    }

    const { toggleUserInfoModal, toggleAnnouncementInfoModal, toggleMessageModal, toggleFeedbackAddActivityModal, toggleOrderDetailModal } = handlers;

    const headerButtons = () => {
        return [
            hasAccess([UserClaimEnumerations.postApFeedbackAddState]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-change', label: 'adminPanel.feedback.changeStatus', onClick: () => toggleFeedbackAddActivityModal(null, null) },
            hasAccess([UserClaimEnumerations.getApCustomerById]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserInfoModal },
            // selectedFeedback?.feedbackType == Enumerations.feedbackTypes.user && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-users-feedback', label: 'adminPanel.feedback.feedbackToUser', onClick: toggleAnnouncementInfoModal },
            selectedFeedback?.feedbackType == Enumerations.feedbackTypes.announcementContent && hasAccess([UserClaimEnumerations.getApAnnouncementById]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-announcement', label: 'adminPanel.announcement.announcementDetail', onClick: toggleAnnouncementInfoModal },
            selectedFeedback?.feedbackType == Enumerations.feedbackTypes.sharedSheetOrder && hasAccess([UserClaimEnumerations.getApOrderDetail, UserClaimEnumerations.getApOrderDto]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-orders', label: 'adminPanel.feedback.orderDetail', onClick: toggleOrderDetailModal },
            hasAccess([UserClaimEnumerations.updateApFeedbackIsPublic]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-announcement-feedback', label: feedbackInfo?.isPublic ? 'adminPanel.feedback.displayNottoAll' : 'adminPanel.dataGridColumn.displaytoAll', onClick: () => toggleMessageModal(false) },
        ];
    }

    const mapActivityHistory = () => {
        return !isEmpty(feedback?.states) && feedback?.states.map((item, index) =>
            <div key={index} className='border rounded-3 p-2 font-size-14 background-white-color my-2 mx-3' >
                <div className='font-size-12 fw-bold mb-1'>{EnumerationTranslationHelper.getTranslategetFeedbackState(item?.userFeedbackStateType)}</div>

                <span className='light-text-color d-block'>{CommonHelper.replaceSlashNToLinebreaks(item?.comment)}</span>
                <div className='d-flex justify-content-between mt-2'>
                    <div className='d-flex align-items-center'>
                        {item?.isPublic &&
                            <>
                                <i className='ns-icon-view font-size-16 light-text-color me-1'></i>
                                <span className='font-size-12 light-text-color'>{getTranslatedRawText('profile.common.visibleToUser')}</span>
                            </>}
                    </div>
                    <div className='d-flex justify-content-end align-items-center gap-2 font-size-12'>
                        <NsButton className='secondary-text d-block'
                            size='small'
                            label={item?.fullName}
                            onClick={toggleUserInfoModal} />
                        <span>{DateHelper.getDateAndTime(item?.submitDate)}</span>
                    </div>
                </div>
            </div >)
    }
    console.log(feedbackInfo);
    return (<NsModal maxWidth='lg'
        showModal={true}
        closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
        noSpace={true}
        dialogTitle={getTranslatedRawText("adminPanel.feedback.feebackDetail")} >

        {props?.isUsedInProfileSetting
            ? <div className='d-flex background-color px-3 pb-2 pt-4'>
                <NsTypography variant='inherit me-2'>{getTranslatedRawText('profile.feedback.about')}</NsTypography>
                <NsTypography variant='inherit' className='fw-bold'>{selectedFeedback?.announcementContentTitle || selectedFeedback?.productName}</NsTypography>
            </div>
            : <ApBaseMapActionButtons actionButtons={headerButtons()}
                isLoading={isLoadingInfo || isLoadingFeedback} />
        }

        <div className='background-color px-3 py-2 mb-3'>
            <NsBasicInfoLabelBox basicInfo={getFeedbackBasicInfos(feedbackInfo)}
                isLoading={isLoadingInfo}
                hasAccessInfo={hasAccessInfo} />
        </div>
        <Row className='g-3 mb-3 px-0 mx-0'>
            <Col md={7} className='p-3'>
                <ShowFeedbackMetrics feedback={feedback} />
            </Col>
            <Col md={5} className='p-3'>
                <div className='background-color py-2 h-100'>
                    <NsTypography variant='inherit' className='px-3 py-2 text-color fw-bold'>{getTranslatedRawText('adminPanel.audit.history')}</NsTypography>
                    {mapActivityHistory()}
                </div>
            </Col>
        </Row>
        {showUserInfoModal &&
            <ApBaseUserInfoModal showModal={true}
                selectedUserId={feedbackInfo?.userId}
                closeModal={toggleUserInfoModal} />
        }
        {showAnnouncementInfoModal &&
            <ApAnnouncementViewModal announcementId={selectedFeedback?.announcementContentId || selectedFeedback?.actionId}
                closeModal={toggleAnnouncementInfoModal} />
        }
        {showOrderDetailModal &&
            <ApShareSheetOrderDetailModal showModal={true}
                selectedOrder={{ ...selectedFeedback, id: selectedFeedback?.sharedSheetOrderId || selectedFeedback?.actionId }}
                closeModal={toggleOrderDetailModal}
            // updateSharedSheetOrderData={updateSharedSheetOrderData} 
            />
        }
        {showFeedbackAddActivityModal &&
            <ApBaseFeedbackAddActivityModal userFeedbackId={selectedFeedback?.userFeedbackId}
                closeModal={toggleFeedbackAddActivityModal} />
        }
        {showMessageModal &&
            <NsMessageClassModal show={true}
                onClose={toggleMessageModal}
                messageItems={[
                    {
                        messageText: <>{getTranslatedRawText(feedbackInfo?.isPublic ? 'common.messages.undisplayingCommentToAll' : 'common.messages.doYouWantDisplayCommentToAll')}</>,
                        messageType: Enumerations.messageType.question
                    }]}
            />
        }
        {resultMessageClass}
    </NsModal >
    );
}

function ShowFeedbackMetrics(props) {
    const { feedback } = props;
    const strengths = isEmpty(feedback?.tags) ? [] : feedback.tags.filter(item => item.feedbackTypeMetricType == Enumerations.feedbackMetricTypes.strengths && !!item.isSelected);
    const weaknesses = isEmpty(feedback?.tags) ? [] : feedback.tags.filter(item => item.feedbackTypeMetricType == Enumerations.feedbackMetricTypes.weaknesses && !!item.isSelected);
    return <>
        <ApBaseFeedbackMetrics items={feedback?.rates}
            metricTypeId={Enumerations.feedbackMetricTypes.ratingparamenter}
            readOnly={true}
            className='rating--muted'
            containerClassName='mb-3 border-bottom' />
        <Row className='d-flex flex-wrap px-2 mt-4'>
            {!isEmpty(strengths) && <Col md={6} className='px-1 my-1'>
                <NsLabelBox className='background-color'
                    label={<><i className='ns-icon-like' />{getTranslatedRawText('adminPanel.feedback.strengths')}</>}
                    value={strengths.map((item, index) => <div key={index} className='d-block'>{item?.title}</div>)} />
            </Col>}
            {!isEmpty(weaknesses) && <Col md={6} className='px-1 my-1'>
                <NsLabelBox className='background-color'
                    label={<><i className='ns-icon-dislike' />{getTranslatedRawText('adminPanel.feedback.weaknesses')}</>}
                    value={weaknesses.map((item, index) => <div key={index} className='d-block'>{item?.title}</div>)} />
            </Col>}
        </Row>
        <Row className='px-2 mt-1 mb-2'>
            {!isEmpty(feedback?.demandedServiceTypeId) && <Col md={6} className='px-1'>
                <NsLabelBox className='background-color w-auto'
                    label={getTranslatedRawText('adminPanel.feedback.demandService')}
                    value={feedback?.demandedServices.find(item => item.key == feedback.demandedServiceTypeId)?.value} />
            </Col>}
        </Row>
        {!isEmpty(feedback?.comment) && <NsLabelBox className='background-color'
            label={getTranslatedRawText('adminPanel.feedback.originalComment')}
            value={CommonHelper.replaceSlashNToLinebreaks(feedback?.comment)} />
        }
    </>
}

export default React.memo(ApBaseFeedbackDetailModal);