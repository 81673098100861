const HomeTranslationFa = {
    home: {
        bannerSection: {
            instagramTitle: "صفحه اینستاگرام",
            instagramDescription: "به صفحه اینستاگرام ما بپیوندید",
            NewReleaseTitle: "محصول جدید",
            NewReleaseDescription: "توضیحات محصول جدید",
            announcement: 'اطلاعیه‌ها',
            noAnyAnnouncement: 'اطلاعیه‌‌ای وجود ندارد',
            noAnyNews: 'خبری موجود نیست',
            noAnyDataToView: 'اطلاعاتی برای نمایش وجود ندارد.'
        },
        productSection: {
            bestSellers: "پر فروش ترین",
            newArrival: "جدیدترین",
            groupSheets: 'فرم‌های عمومی',
        },
        advertisementSection: {},
        aboutSection: {
            readMore: "ادامه مطلب",
            aboutTitle: "درباره ما",
            primaryAboutText: "{0} درابتدای سال 2019 فعالیت خود را به عنوان یکی از بزرگترین چاپخانه‌های شهر دبی و کشور امارات آغاز نموده است.  تاسیس یک چاپخانه مجهز و مدرن همواره در دستور کار مدیران توانمند مجموعه قرار داشته است. با مطالعات دقیق بازار و نیز بررسی وضعیت صنعت چاپ در کشورهای هدف، سرانجام کشور امارات متحده عربی و شهر دبی برای ایجاد شعبه بین المللی انتخاب شدند.",
            secondaryAboutText: "{0} هم اکنون و پس از گذشت حدود 2 سال از آغاز فعالیت خود توانسته است با ارائه خدمات متنوع چاپی و قیمت‌های مناسب در بازار رقابتی دبی، به خوبی جایگاه خود را به دست آورده و روز به روز گسترش یابد.",
        },
        orderingProcessSection: {
            ordering: "سفارش",
            payment: "پرداخت",
            printingProcess: "فرآیند چاپ",
            readyToDelivery: "آماده تحویل",
            delivered: "تحویل به مشتری",
        },
        siteMap: {
            salesAndSupport: 'فروش و خدمات',
            ordersAndShipping: 'سفارش‌ها و ارسال',
            inProcessOrders: 'سفارش‌های در پروسه چاپ',
            readyToDeliverOrders: 'سفارش‌های آماده تحویل',
            archivedOrders: 'سفارش‌های بایگانی شده',
            regularOrdering: 'سفارش معمولی',
            professionalOrdering: 'سفارش حرفه‌‌ای',
            fileChecking: 'بررسی فایل',
            support: 'پشتیبانی',
            company: 'شرکت',
            newsAndBlog: 'وبلاگ‌ها و تازه‌ها',
        },
        tour: {
            shop: 'برای ثبت سفارش سریع از منوی فروشگاه اقدام به سفارش کنید.',
            iOrderFrequently: 'برای ثبت سفارش حرفه‌‌ای از منوی ثبت سفارش حرفه‌‌ای اقدام به سفارش کنید.'
        },
        instantDiscountSection: {
            cheaperThanBefore: 'ارزانتر از قبل',
            pcs: 'تیراژ',
        },
    },
};

export default HomeTranslationFa;
