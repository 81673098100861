import React from 'react';
import { Link } from 'react-router-dom';

import NsTypography from '../../../../components/text/NsTypography';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getCurrentCulture, getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { getTitleMetaTagByCulture } from '../../../../components/metaTags/NsSetPageTitleAndDescription';

const BottomFooter = () => {
    return (
        <div className="d-flex justify-content-center py-2 flex-wrap align-items-baseline top-footer__border-top m-0" >

            <NsTypography variant='inherit' className='font-size-12 text-nowrap'>
                {getTranslatedRawText("footer.copyright")}
            </NsTypography>

            <Link to={'/' + getCurrentCulture()}
                className="red-color mx-2 fw-bold text-nowrap text-decoration-none font-size-14">
                {CommonHelper.stringFormat(getTranslatedRawText("footer.website"), getTitleMetaTagByCulture)}
            </Link>

            <NsTypography variant='inherit' className='font-size-12 '>
                {getTranslatedRawText("footer.reserved")}
            </NsTypography>

        </div>
    );
};

export default React.memo(BottomFooter);