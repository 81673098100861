import React from "react";
import { Col, Row } from "react-bootstrap";

import NsToggleGroupButton from "../../../../components/buttons/NsToggleGroupButton";
import NsAutocomplete from "../../../../components/dropdowns/NsAutoComplete";
import NsEditor from "../../../../components/editor/NsEditor";
import NsTextField from "../../../../components/inputs/NsTextField";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../../configs/Enumerations";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import ContentHelper from "./ContentHelper";
import ApBasesImageContent from "./ApBasesImageContent";
import NsSwitch from "../../../../components/switch/NsSwitch";
import ApBasesContentVisibility from "./ApBasesContentVisibility";
import ApBasesStandardSizesForUpload from './ApBasesStandardSizesForUpload';

const ApBasesPostContentModal = (props) => {

    const { stateHandler, serviceCall, data, editorHandler, showValidationDate, selectedLanguage, categories, fileUploaderCategoryType, isGallery } = props;
    let postContent = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);

    const contentVisibilityTypeIdChanged = (selectedItem) => {
        stateHandler(data, { contentVisibilityTypeId: selectedItem }, selectedLanguage.id)
    }

    const selectedAgencyIdsChanged = (agencyIds) => {
        stateHandler(data, { agencyIds }, selectedLanguage.id)
    }

    return (
        <Row>
            <Col md={5}>
                <ApBasesImageContent content={postContent?.content}
                    isChangeFile={!!postContent?.content?.fileUrl}
                    serviceCall={serviceCall}
                    fileUploaderCategoryType={fileUploaderCategoryType}
                    stateHandler={(uploadedFileDetail) => stateHandler(data, { ...uploadedFileDetail, }, selectedLanguage.id)} />
                <ApBasesStandardSizesForUpload />
            </Col>
            <Col md={7}>
                <div className='d-flex flex-column'>
                    <div className='d-flex'>
                        <div className='flex-grow-1 pe-2'>
                            <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                                valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                                value={postContent?.content.title || null}
                                showValidationDate={showValidationDate}
                                className='w-100 mb-2' />
                        </div>
                        <div className='align-self-center'>
                            <NsSwitch label='adminPanel.slider.active'
                                checked={postContent?.content.isActive}
                                disabled={!selectedLanguage.isDefault}
                                onChange={() => stateHandler(data, { isActive: !postContent?.content.isActive }, selectedLanguage.id)} />
                        </div>
                    </div>
                    {!isGallery &&
                        <>
                            <NsTextField label={getTranslatedRawText('adminPanel.post.summary')}
                                valueChanged={(value) => stateHandler(data, { summary: value }, selectedLanguage.id)}
                                value={postContent?.content.summary || null}
                                showValidationDate={showValidationDate}
                                className='w-100 mt-1' />

                            <NsTextField label={getTranslatedRawText('adminPanel.post.tags')}
                                valueChanged={(value) => stateHandler(data, { tags: value }, selectedLanguage.id)}
                                value={postContent?.content.tags || null}
                                showValidationDate={showValidationDate}
                                className='w-100 mt-1' />
                            <div className="font-size-12 pt-2">
                                {getTranslatedRawText('adminPanel.post.tagsInfo')}
                            </div>
                        </>
                    }

                    <NsAutocomplete label={getTranslatedRawText('adminPanel.post.category')}
                        items={categories}
                        onChange={(value) => stateHandler(data, { pageCategories: value }, selectedLanguage.id)}
                        value={postContent?.content.pageCategories || []}
                        disabled={!selectedLanguage.isDefault}
                        showValidationDate={showValidationDate}
                        multiple={true}
                        className='w-100 mb-2' />

                    {!isGallery && <NsToggleGroupButton isShowGroupLabel={true}
                        label={getTranslatedRawText('adminPanel.post.commentType')}
                        onClick={(value) => stateHandler(data, { pageContentCommentType: value.key }, selectedLanguage.id)}
                        disabled={!selectedLanguage.isDefault}
                        borderColor='mb-2'
                        size='small'
                        items={ConstantsWithTranslation.getPageContentCommentType()}
                        selectedItemId={postContent?.content.pageContentCommentType} />}

                    <ApBasesContentVisibility contentVisibilityTypeId={postContent?.content.contentVisibilityTypeId}
                        selectedAgencies={postContent?.content.selectedAgencies}
                        contentVisibilityTypeIdChanged={contentVisibilityTypeIdChanged}
                        selectedAgencyIdsChanged={selectedAgencyIdsChanged} />

                </div>
            </Col>
            {!isGallery && <div className='mt-3'>
                <NsEditor editorMode={Enumerations.editorMode.advance}
                    initialValue={postContent?.content.mainContent || null}
                    selectedLanguage={props.selectedLanguage.id}
                    changeHandler={(value) => {
                        editorHandler(false, data, { mainContent: value })
                    }} />
            </div>}
        </Row>
    )
}

export default React.memo(ApBasesPostContentModal);