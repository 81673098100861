const setPrimaryMeta = 'SET_PRIMARY_META';
const initialState = {
    title: '',
    keywords: '',
    description: '',
    ogTitle: '',
    ogType: '',
    ogImage: '',
    ogDescription: '',
};

export const metaTagActionCreators = {
    setMetaTags: ({title, keywords, description, ogTitle, ogType, ogImage, ogDescription, author}) => ({
        type: setPrimaryMeta,
        title: title || ogTitle,
        keywords: keywords,
        description: description,
        ogTitle: ogTitle || title ,
        ogType: ogType,
        ogImage: ogImage,
        ogDescription: ogDescription,
        author: author
    })
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case setPrimaryMeta:
            return {
                ...state,
                title: action.title,
                keywords: action.keywords,
                description: action.description,
                ogTitle: action.ogTitle,
                ogType: action.ogType,
                ogImage: action.ogImage,
                ogDescription: action.ogDescription,
                author: action.author
            };

        default:
            return state;
    }
};