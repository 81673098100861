import React from "react";

import Drawer from '@mui/material/Drawer';
import Config from "../../configs/Config";
import Links from "../../configs/links/AllLinks";
import CommonHelper from "../../utilities/CommonHelper";
import { getTranslatedRawText, getUrlWithCurrentCulture, isEnCulture } from "../../utilities/CultureHelper";
import NsButton from '../buttons/NsButton';
import { getTitleMetaTagByCulture } from "../metaTags/NsSetPageTitleAndDescription";

class NsDrawer extends React.Component {

    handleDrawerClose = () => CommonHelper.safeFunctionCall(this.props.onClose);

    render() {
        const { variant, open } = this.props;

        return <Drawer variant={variant}
            anchor='left'
            open={open}
            onClose={this.handleDrawerClose}>

            <div className='d-flex flex-column h-100 background-black-color outline-color'>
                <div className='d-flex align-items-center pb-2'>
                    <NsButton onClick={this.handleDrawerClose}
                        startIcon={isEnCulture() ? 'ns-icon-expand-left' : 'ns-icon-expand-right'}
                        className='secondary mx-1' />

                    <div className='text-center my-3'>
                        <a href={getUrlWithCurrentCulture(Links, Links.home)}>
                            <img src={Config.siteLogoMobile} alt={CommonHelper.stringFormat(getTranslatedRawText('components.imagesAlt.logo'), getTitleMetaTagByCulture)} />
                        </a>
                    </div>
                </div>
                {this.props.children}
            </div>

        </ Drawer >
    }
}

export default NsDrawer;