import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import { GetGalleriesService } from '../../../../services/singlePages/CmsService';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import CommonHelper from '../../../../utilities/CommonHelper';
import NsImage from '../../../../components/images/NsImage';
import Enumerations from '../../../../configs/Enumerations';
import NsCard from '../../../../components/cards/NsCard';
import Links from '../../../../configs/links/AllLinks';
import GalleryDetailModal from './GalleryDetailModal';
import SinglePage from '../../SinglePage';
import NsPageTitleBox from '../../../../components/labels/NsPageTitleBox';
import NsLabelLine from '../../../../components/labels/NsLabelLine';

const Gallery = () => {

    const [galleries, setGalleries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getChildOfGalleryCategory();
    }, [])

    const getChildOfGalleryCategory = () => {
        setIsLoading(true);
        GetGalleriesService({}, getGalleryCategoriesCallback);
    }

    const getGalleryCategoriesCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setGalleries(resultData);
        } else
            setResultMessageClass(messageClassModal);
    }

    const imagePreviewClicked = (image, galleryImages) => {
        setShowPreviewModal(true);
        setSelectedImage(image);
        setGalleryImages(galleryImages);
    }

    return (
        <SinglePage >
            <NsPageTitleBox title={getTranslatedRawText('singlePage.gallery')}
                icon='ns-icon-blog h1' />
            <NsCard className="p-3">
                {isLoading
                    ? <GallerySkeleton />
                    : galleries.map((item, index) =>
                        <div key={index} className='mb-3'>
                            <NsLabelLine viewMoreLink={CommonHelper.stringFormat(getUrlWithCurrentCulture(Links, Links.galleryCategoryPosts), item.id, item.title)}
                                smallSize={true}
                                primaryTitle={item.title} />

                            <Row className='align-items-stretch center g-2'>
                                <Col md={5} className=''>
                                    <div style={{ maxHeight: '310px' }}>
                                        <NsImage className='w-100' src={CommonHelper.createImageUrl(item?.fileUrl)}
                                            wrapperClassName='border h-100 rounded-top overflow-hidden me-2'
                                            // height='400px'
                                            alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.galleryImage"), item.title)} />
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <Row className='align-items-stretch g-2'>

                                        {item.galleryImages.slice(0, 7).map(image =>
                                            <Col className='' key={image?.id} xs={6} md={3}>
                                                <NsImage className='w-100' src={CommonHelper.createImageUrl(image?.fileUrl)}
                                                    wrapperClassName='border cursor-pointer rounded-top h-100 overflow-hidden'
                                                    onClick={() => imagePreviewClicked(image, item.galleryImages)}
                                                    alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.galleryImage"), item.title)} >

                                                    <div className='p-1 bg-secondary rounded-bottom text-white truncate-text font-size-12'>{image?.title}</div>
                                                </NsImage>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )}
            </NsCard>

            {showPreviewModal &&
                <GalleryDetailModal selectedImage={selectedImage}
                    images={galleryImages}
                    closeModal={() => setShowPreviewModal(false)} />
            }
            {resultMessageClass}
        </SinglePage>
    );
};

export function GallerySkeleton() {
    return <Row>
        {CommonHelper.createArray(3).map((item, index) =>
            <Col key={index} ms={4}>
                <NsSkeleton isVisible={true}
                    variant={[
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.text,
                    ]} />
            </Col>
        )}
    </Row>
}

export default React.memo(Gallery);