import React, { useState, useEffect } from 'react';

import NsModal from '../../../../../components/modals/NsModal';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { GetUserBusinessPartnerApiKeyWithAbstractDetailService, GetUserBusinessPartnerResetSecureApiKeyService } from '../../../../../services/adminPanel/AdminUserService';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import Enumerations from '../../../../../configs/Enumerations';

const ApBusinessPartnerSecureKeyModal = (props) => {

    const { userId, showModal, closeModal } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [showResetApiKeyModal, setShowResetApiKeyModal] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);

    useEffect(() => {
        getBasicUserInfo();
    }, [callServiceDate])

    const getBasicUserInfo = () => {
        setIsLoading(true);
        GetUserBusinessPartnerApiKeyWithAbstractDetailService(userId, getUserBusinessPartnerApiKeyWithAbstractDetailCallback);
    }

    const getUserBusinessPartnerApiKeyWithAbstractDetailCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) setData(resultData);
    }

    const resetSecureApiKeyClicked = () => setShowResetApiKeyModal(true);

    const resetApiKey = (confirm) => {
        setShowResetApiKeyModal(false);
        if (confirm) {
            setIsLoading(true);
            GetUserBusinessPartnerResetSecureApiKeyService(userId, resetApiKeyCallback, true);
        }
    }

    const resetApiKeyCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        setResultMessageClass(messageClassModal);
        setCallServiceDate(new Date())
    }

    const copyApiKeyClick = (value) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(value);
        }
    }

    const headerButtons = [
        { wrapperClassName: '', startIcon: 'ns-icon-retry', label: 'adminPanel.usersInfo.resetSecureApiKey', onClick: resetSecureApiKeyClicked },
    ]

    return (
        <NsModal
            maxWidth='sm'
            showModal={showModal}
            closeModal={closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.usersInfo.xSecureApiKey")}>

            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />

            <div className='background-color p-2'>
                <ApBaseLabelInfo info={data}
                    hasAccessInfo={true}
                    isLoading={isLoading} />

                <div className='fw-bold font-size-14 mb-1 mt-3 title-color ps-1'>{getTranslatedRawText("adminPanel.usersInfo.xSecureApiKey")} :</div>
                <div>
                    <div className='d-flex flex-row card p-2'>
                        <div className='col-11 pe-2'>
                            {data?.apiKey}
                        </div>
                        <div onClick={() => copyApiKeyClick(apiKey)} className='col-1 d-flex align-items-center justify-content-center'>
                            <i className='ns-icon-copy h4 cursor-pointer m-0'></i>
                        </div>
                    </div>
                </div>
            </div>

            {showResetApiKeyModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => resetApiKey(confirm)}
                    messageItems={[
                        {
                            messageText: <>{getTranslatedRawText('adminPanel.usersInfo.areYouSureToResetSecureApiKey')} ?</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }

            {resultMessageClass}

        </NsModal>
    );
};

export default ApBusinessPartnerSecureKeyModal;