import React, { Component } from 'react';

import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';
import NsTag from './NsTag';

class NsTags extends Component {

    render() {
        const { tags, linkToMaster, linkToSlave, containerClassName, tagClassName, isTexttrimmed, lengthTrimmed } = this.props;
        console.log(getUrlWithCurrentCulture(linkToMaster, linkToSlave));
        return <div className='d-flex flex-wrap gap-1'>
            {!isEmpty(tags) && tags.map((tag, index) =>
                <NsTag key={index}
                    tag={tag}
                    isTexttrimmed={isTexttrimmed}
                    lengthTrimmed={lengthTrimmed}
                    linkTo={!!linkToMaster && !!linkToSlave ? getUrlWithCurrentCulture(linkToMaster, linkToSlave, { searchInput: CommonHelper.removeStringSpaces(tag) }) : null}
                    containerClassName={containerClassName}
                    tagClassName={tagClassName}
                    tagClicked={this.props.tagClicked}
                />
            )}
        </div>
    }
}

export default NsTags;