import React from 'react';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import { messageClass } from '../../utilities/MessageModalHelper';
import NsButton from '../../components/buttons/NsButton';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from '../../configs/Enumerations';
import ImageHelper from '../../utilities/ImageHelper';

class NsEditFileActionFlip extends React.Component {
    constructor(props) {
        super(props);
        this.flipButtonList = [
            { label: 'fileManager.modals.editFile.horizontallyFlip', icon: 'ns-icon-flip-horizontal', onClicked: () => this.flipClicked(Enumerations.fileActions.flipHorizontally) },
            { label: 'fileManager.modals.editFile.verticallyFlip', icon: 'ns-icon-flip-vertical', onClicked: () => this.flipClicked(Enumerations.fileActions.flipVertically) },
        ];
    }

    flipClicked = (flipType) => {
        CommonHelper.safeFunctionCall(this.props.changeIsLoadingState, true);
        flipType === Enumerations.fileActions.flipHorizontally
            ? ImageHelper.flipHorizontally(this.props.imgContent, this.flipCallback(flipType))
            : ImageHelper.flipVertically(this.props.imgContent, this.flipCallback(flipType));
    }

    flipCallback = (flipType) => (newContent) => {
        if (!!newContent)
            CommonHelper.safeFunctionCall(this.props.setNewContent, newContent, false, () => {
                let newAction = {
                    fileChangeType: flipType,
                    width: 0,
                    height: 0
                }
                CommonHelper.safeFunctionCall(this.props.imgContentActionsChanged, newAction);
                CommonHelper.safeFunctionCall(this.props.changeIsLoadingState, false);
            });
        else
            this.setState({ resultMessageClass: messageClass(getTranslatedRawText("common.messages.somethingIsWrong")) });
    }

    render() {

        return (
            <div className="d-flex p-3 background-white-color">
                {this.flipButtonList.map((button, index) =>
                    <NsButton key={index} className="light"
                        wrapperClassName='pe-1'
                        size='small'
                        startIcon={button.icon}
                        label={button.label}
                        onClick={button.onClicked}
                    />
                )}
            </div>
        );
    }
}

export default NsEditFileActionFlip;