import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { PostRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';
import Enumerations from '../../configs/Enumerations';
import OrderHelper from '../../utilities/OrderHelper';
import CommonHelper from '../../utilities/CommonHelper';

const MasterFileInformationService = (selectedFileInfo, routeId, callback) => {
    let data = {
        fileId: selectedFileInfo.id,
        fileName: selectedFileInfo.name,
        productId: CommonHelper.toInt(routeId),
    }
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.masterFileInformation), data, callback, null);
}

const PrepareMasterFileService = (selectedFileInfo, isFront, routeId, imgContentActions, callback) => {
    let data = {
        fileId: selectedFileInfo.id,
        fileName: selectedFileInfo.name,
        frontFileId: isFront ? null : OrderHelper.getFrontSideMasterFiles(routeId)?.id,
        SharedSheetOrderDetailUserFileType: isFront ? Enumerations.sharedSheetOrderDetailUserFileType.FrontImage : Enumerations.sharedSheetOrderDetailUserFileType.BackImage,
        productId: CommonHelper.toInt(routeId),
        series: OrderHelper.getSeries(routeId),
        width: selectedFileInfo?.width,
        height: selectedFileInfo?.height,
        isDouble: OrderHelper.getPrintedSide(routeId).key === Enumerations.printedSide.twoSide,
        userFileChanges: imgContentActions
    }
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.prepareMasterFile), data, callback, null);
}

const FilmFileInformationService = (selectedFileInfo, callback) => {
    let data = {
        fileId: selectedFileInfo.id,
        fileName: selectedFileInfo.name,
    }
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.filmFileInformation), data, callback, null);
}

const PrepareFilmFileService = (selectedFileInfo, isFront, routeId, imgContentActions, callback) => {
    let data = {
        masterFileId: OrderHelper.getFrontSideMasterFiles(routeId)?.id,
        fileId: selectedFileInfo.id,
        fileName: selectedFileInfo.name,
        SharedSheetOrderDetailUserFileType: isFront ? Enumerations.sharedSheetOrderDetailUserFileType.FrontImage : Enumerations.sharedSheetOrderDetailUserFileType.BackImage,
        userFileChanges: imgContentActions,
    }
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.prepareFilmFile), data, callback, null);
}

const GetExtendedServicePriceService = (routeId, callback) => {
    let data = OrderHelper.getExtendedServicesValue(routeId);
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.getExtendedServicePrice), data, callback, null);
}

const AddToOrderListService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.addToOrderList), data, callback, null);
}

const DeleteOrderDetailService = (id, callback, showSuccessResponseMessage) => {
    let data = { sharedSheetOrderDetailId: id }
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.deleteOrderDetail), data, callback, showSuccessResponseMessage);
}

const SaveProfessionalOrderService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.saveProfessionalOrder), data, callback, null);
}

const ChangeSeries = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.changeSeries), data, callback)
}

export {
    MasterFileInformationService,
    PrepareMasterFileService,
    FilmFileInformationService,
    PrepareFilmFileService,
    GetExtendedServicePriceService,
    AddToOrderListService,
    DeleteOrderDetailService,
    SaveProfessionalOrderService,
    ChangeSeries
}