import React, { useState } from 'react';

import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../../bases/ApBaseSearchButtons';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';

const ApSupporterUserSearch = (props) => {

    const [mobile, setMobile] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [accountStatus, setAccountStatus] = useState(null);
    const [roles, setRoles] = useState([]);
    const [registionTimeFrom, setRegistionTimeFrom] = useState(null);
    const [registionTimeTo, setRegistionTimeTo] = useState(null);
    const [detailLedger, setDetailLedger] = useState('');
    const [showMore, setShowMore] = useState(false);

    const basicSearchOptionsInMobile = [
        {
            type: Enumerations.advanceSearchFieldType.mobileInput,
            label: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
            fieldName: 'mobile',
            value: mobile,
            onChange: (value) => setMobile(value),
        }
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.firstName'),
            fieldName: 'name',
            value: name,
            onChange: (value) => setName(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.lastName'),
            fieldName: 'lastName',
            value: lastName,
            onChange: (value) => setLastName(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.idNumber'),
            fieldName: 'idNumber',
            value: idNumber,
            onChange: (value) => setIdNumber(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.accountStatus'),
            fieldName: 'accountStatus',
            value: accountStatus,
            items: ConstantsWithTranslation.getAccountStatus(),
            onChange: (value) => setAccountStatus(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.role'),
            fieldName: 'roles',
            value: roles,
            items: ConstantsWithTranslation.getAdminsRole(),
            onChange: (value) => setRoles(value)
        }
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.registrationTimeFrom'),
            fieldName: 'registionTimeFrom',
            value: registionTimeFrom,
            onChange: (value) => setRegistionTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.registrationTimeTo'),
            fieldName: 'registionTimeTo',
            value: registionTimeTo,
            onChange: (value) => setRegistionTimeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.detailLedger'),
            fieldName: 'detailLedger',
            value: detailLedger,
            onChange: (value) => setDetailLedger(value)
        }
    ];

    const searchValues = {
        mobile,
        name,
        lastName,
        idNumber,
        accountStatus,
        roles,
        registionTimeFrom,
        registionTimeTo,
        detailLedger
    };

    const searchClicked = () => {
        let data = {
            mobile,
            name,
            lastName,
            idNumber,
            accountStatus,
            roles,
            registionTimeFrom,
            registionTimeTo,
            detailLedger
        };
        CommonHelper.safeFunctionCall(props.setSearchData, data);
    }

    const clearButtonClicked = () => {
        setMobile('');
        setName('');
        setLastName('');
        setIdNumber('');
        setAccountStatus(null);
        setRoles([]);
        setRegistionTimeFrom(null);
        setRegistionTimeTo(null);
        setDetailLedger();
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    const showMoreClicked = () => setShowMore(!showMore);

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    return (
        <div onKeyDown={handleKeyDown}>
            <NsAdvanceSearch searchOptions={showMore
                ? advanceSearchOptions
                : CommonHelper.isMobileDevice() ? basicSearchOptionsInMobile : basicSearchOptions}
                searchValues={searchValues} mdCol={2} smCol={4} />

            <ApBaseSearchButtons searchClicked={searchClicked}
                showMoreHandler={showMoreClicked}
                showMore={showMore}
                isLoading={props.isLoading}
                clearButtonClicked={clearButtonClicked} />
        </div>
    );
};

export default React.memo(ApSupporterUserSearch);