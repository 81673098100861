import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import ApBasesDashboardHeader from '../bases/ApBasesDashboardHeader';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import DateHelper from '../../../../utilities/DateHelper';
import ApDaPopularAgencies from './ApDaPopularAgencies';
import ApDaNewSubmitted from './ApDaNewSubmitted';
import ApDaActiveState from './ApDaActiveState';
import ApDaTotalSale from './ApDaTotalSale';

const ApDashboardAgencies = () => {

    const [dates, setDates] = useState([new Date(DateHelper.getCustomPreviousMonth(2)), new Date()]);
    const [dateRangeType, setDateRangeType] = useState(ConstantsWithTranslation.getDateRanges()[0].key);
    const [refreshDate, setRefreshDate] = useState();

    const refreshClicked = useCallback((dates, isRefreshDate, dateRange) => {
        setDateRangeType(dateRange);
        setDates(dates);
        isRefreshDate && setRefreshDate(new Date());
    })

    const parameters = {
        submitTimeFrom: DateHelper.convertDateTimeToDate(new Date(dates[0])),
        submitTimeTo: dates[1],
        timeIntervalType: dateRangeType
    }

    return (
        <Row>
            <Col>
                <ApBasesDashboardHeader title='adminPanel.dashboard.dashboardAgencies'
                    refreshClicked={refreshClicked} />
            </Col>
            <Row className='align-self-stretch m-0 px-0 gy-2'>
                {hasAccess([UserClaimEnumerations.getApDaLatestSubmittedAgencies]) &&
                    <Col md={7}>
                        <ApDaNewSubmitted parameters={parameters} refreshDate={refreshDate} />
                    </Col>
                }
                {hasAccess([UserClaimEnumerations.getApDaTotalSalesAmountAgencies]) &&
                    <Col md={5}>
                        <ApDaTotalSale parameters={parameters} refreshDate={refreshDate} />
                    </Col>
                }
            </Row>
            <Row className='align-self-stretch m-0 mt-2 px-0 gy-2'>
                {hasAccess([UserClaimEnumerations.getApDaPopularAgencies]) &&
                    <Col md={4}>
                        <ApDaPopularAgencies parameters={parameters} refreshDate={refreshDate} />
                    </Col>
                }
                {hasAccess([UserClaimEnumerations.getApDaActiveStates]) &&
                    <Col md={8}>
                        <ApDaActiveState parameters={parameters} refreshDate={refreshDate} />
                    </Col>
                }
            </Row>
        </Row>
    )
}

export default React.memo(ApDashboardAgencies);