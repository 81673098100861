import clsx from 'clsx';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

import CommonHelper from '../../utilities/CommonHelper';
import { isEmpty } from '../../utilities/ValidationHelper';

class NsTag extends Component {

    tagClicked = (tag) => CommonHelper.safeFunctionCall(this.props.tagClicked, tag)

    render() {
        const { tag, linkTo, containerClassName, tagClassName, isTexttrimmed, lengthTrimmed } = this.props;

        return (
            <div className={clsx(containerClassName, "word-break text-center cursor-pointer")}>
                {!isEmpty(linkTo)
                    ? <Link to={linkTo} className={clsx(!!tagClassName ? tagClassName : 'font-size-14', 'px-2 background-disable-color black-color text-decoration-none rounded')}>
                        {' ' + isTexttrimmed ? CommonHelper.trimmedString(tag, lengthTrimmed) : tag + ' '}
                    </Link>
                    : <span onClick={() => this.tagClicked(tag)} className={clsx('px-2 py-1 background-disable-color rounded', !!tagClassName ? tagClassName : 'font-size-14')}>
                        {' ' + isTexttrimmed ? CommonHelper.trimmedString(tag, lengthTrimmed) : tag + ' '}
                    </span>
                }
            </div>
        )
    }
}

export default NsTag;