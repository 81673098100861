import React, { useState } from "react";

import NsButton from "../../../../components/buttons/NsButton";
import NsAdminPanelContentWrapper from "../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import NsImage from "../../../../components/images/NsImage";
import Enumerations from "../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import { EditSortSliderService, GetSlidersService } from "../../../../services/adminPanel/AdminSliderService";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApSliderModal from "./ApSliderModal";

const ApSlider = () => {

    const columns = [
        hasAccess([UserClaimEnumerations.getApSliderById, UserClaimEnumerations.editApSortSlider]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 160,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: hasAccess([UserClaimEnumerations.getApSliderById]),
                editClicked: (id) => showSliderModalClicked(id),
                customElementRender: (props) => {
                    return hasAccess([UserClaimEnumerations.editApSortSlider]) && <div className="d-flex" >
                        <NsButton startIcon='ns-icon-expand-down'
                            loading={isLoading}
                            onClick={() => sortArrowClicked(props, false)} />
                        <NsButton startIcon='ns-icon-expand-up'
                            loading={isLoading}
                            onClick={() => sortArrowClicked(props, true)} />
                    </div >
                },
            }
        },
        {
            field: 'imageUrl',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.image'),
            width: 110,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => <NsImage className="w-100"
                    imgAlt="components.imagesAlt.imageThumbnail"
                    src={CommonHelper.createImageUrl(props.fileUrl)} />
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 330
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 90,
            cellClass: 'text-center',
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 150
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 150
        },
    ];

    const [selectedSliderId, setSelectedSliderId] = useState(null);
    const [showSliderModal, setShowSliderModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState();
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [sliders, setSliders] = useState([]);

    const getData = (sliders, isLoading) => {
        setSliders(sliders);
        setIsLoading(isLoading);
    }

    const sortArrowClicked = (selectedSlider, isUpArrow) => {
        const index = sliders.findIndex(slider => {
            return slider.id == selectedSlider.id;
        });
        if ((index == 0 && isUpArrow) || (index == sliders.length - 1 && !isUpArrow)) return;
        setIsLoading(true);
        EditSortSliderService({
            movedContentId: selectedSlider.id,
            mustBeChangeContentId: (sliders[isUpArrow ? index - 1 : index + 1])?.id
        }, editSortSliderCallback);
    };

    const editSortSliderCallback = (resultData, result, messageClassModal) => {
        if (result.data.hasError) {
            setMessageClassModal(messageClassModal);
            setIsLoading(false);
        } else
            setCallServiceDate(new Date());
    };

    const showSliderModalClicked = (id) => {
        setSelectedSliderId(id);
        setShowSliderModal(true);
    }

    const closeSliderModalClicked = (messageClassModal) => {
        setShowSliderModal(false);
        if (!isEmpty(messageClassModal)) {
            setMessageClassModal(messageClassModal);
            setCallServiceDate(new Date());
        }
    }

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.slider")}
            actionButton={{
                onClick: showSliderModalClicked,
                isLoading: isLoading,
                disabled: !hasAccess([UserClaimEnumerations.postApSlider])
            }}
            hasAction={true}
            headerButtonClicked={() => showSliderModalClicked(null)}
            icon={'ns-icon-slider h1'}>

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetSlidersService}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showSliderModal &&
                <ApSliderModal sliderId={selectedSliderId}
                    isNewSlider={!selectedSliderId}
                    closeModal={closeSliderModalClicked} />
            }

            {messageClassModal}
        </NsAdminPanelContentWrapper>
    )
};

export default React.memo(ApSlider);