import React from 'react';

import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
// import UserOrderActionButtons from '../../../bases/UserOrderActionButtons';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import NsModal from '../../../../../../components/modals/NsModal';
import UpBaseStoreOrderDetailGrid from '../bases/UpBaseStoreOrderDetailGrid';

class UpStoreReadyToDeliverOrderDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            transferRequestId: null,
            hasPayment: false
        };
    }

    orderDataLoaded = (resultData) => this.setState({ transferRequestId: resultData?.transferRequestId });

    render() {
        const { orderId } = this.props;
        return (
            <NsModal maxWidth="md"
                dialogTitle={getTranslatedRawText('profile.detail.orderDetail')}
                showModal={true}
                noSpace={true}
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeModal)}>

                <div className='p-3'>
                    <UpBaseStoreOrderDetailGrid orderId={orderId}
                        hasPayment={(hasPayment) => this.setState({ hasPayment })}
                        orderDataLoaded={this.orderDataLoaded} />
                </div>
            </NsModal>
        );
    }
}

export default UpStoreReadyToDeliverOrderDetailModal;