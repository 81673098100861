import clsx from "clsx";
import React from "react";

import { GetShippingEstimatedTimeSheetService } from "../../../../../services/ProfileSetting/DeliveryAddressSettingService";
import { TableRowSkeleton } from "../../../../../components/availableDimensions/NsAvailableDimensionTable";
import NsLabelNoData from "../../../../../components/labels/NsLabelNoData";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import Enumerations from "../../../../../configs/Enumerations";
import DateHelper from "../../../../../utilities/DateHelper";

class UpBaseShippingEstimatedTimeSheet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shippingBusinessDays: [],
            shippingTime: null,
            isLoading: true,
            resultMessageClassModal: (<></>)
        }
    }

    componentDidMount = () => GetShippingEstimatedTimeSheetService({
        shippingPlanType: this.props.selectedShippingPlan?.key,
        shippingMethodCategoryType: this.props.selectedShippingMethod.shippingMethodCategoryTypeId,
        shippingRequestTime: this.props.shippingRequestTime
    }, this.getShippingEstimatedTimeSheetCallback);

    getShippingEstimatedTimeSheetCallback = (resultData, result, resultMessageClassModal) => {
        this.setState({ isLoading: false });
        let shippingTime = null;
        let hasTime = false;
        if (result.data.hasError) this.setState({ resultMessageClassModal });
        else {
            resultData.map(result => {
                hasTime = result.messageClass.messageItems.some(message => message.data == Enumerations.timeSheetDayType.shippingToShippingMethodDay);
                if (hasTime) shippingTime = result.dayDateTime;

            })
            this.setState({ shippingBusinessDays: resultData, shippingTime });
        }
    }

    hasShippingToShippingMethodDay = (item) => !isEmpty(item?.messageClass?.messageItems?.filter(el => el.data == Enumerations.timeSheetDayType.shippingToShippingMethodDay));

    mapShippingBusinessDates = () => {
        return this.state.shippingBusinessDays.map((item, index) => {
            let messageTypeColor = item?.messageClass?.messageItems[0].data == Enumerations.timeSheetDayType.holiday || !this.state.shippingTime ? 'red-color' : 'text-color';
            let isToday = DateHelper.getDatePart(item?.dayDateTime) == DateHelper.getDatePart(DateHelper.getIsoDateTime(new Date()))

            return <tr key={index} className={clsx('font-size-14', isToday ? 'fw-bold black-color' : '')}>
                <td className='p-2'><div className={messageTypeColor}>{this.hasShippingToShippingMethodDay(item) ? DateHelper.getDateAndTime(item?.dayDateTime) : DateHelper.getDatePart(item?.dayDateTime)}</div></td>
                {this.props.showWeekDayName &&
                    <td className='p-2'><div className={messageTypeColor}>{DateHelper.getWeekDayName(item?.dayDateTime)}</div></td>
                }
                <td>{item?.messageClass.messageItems.map((message, index) =>
                    <span key={index} className={clsx('d-flex fw-normal', messageTypeColor)}>
                        {message.data === Enumerations.timeSheetDayType.shippingRequestDay
                            ? <i className='ns-icon-save fs-6 me-1' />
                            : message.data === Enumerations.timeSheetDayType.shippingToShippingMethodDay
                                ? <i className='ns-icon-circle-confirm success-color fs-6 me-1' />
                                : undefined
                        }
                        {message.messageText}
                        {message.data === Enumerations.timeSheetDayType.shippingToShippingMethodDay
                            ? <span>&nbsp;({DateHelper.getTime(item?.dayDateTime)})</span>
                            : <></>
                        }
                    </span>
                )}
                </td>

            </tr>
        })
    }
    render() {
        const { isLoading, resultMessageClassModal, shippingBusinessDays } = this.state;
        const { showWeekDayName } = this.props;
        return <>
            {isLoading
                ? <table className="w-100">
                    <TableRowSkeleton className='flex-grow-1 ms-2 w-100' />
                    {showWeekDayName && <TableRowSkeleton className='flex-grow-1 ms-2 w-100' />}
                    <TableRowSkeleton className='flex-grow-1 ms-2 w-100' />
                </table>
                : isEmpty(shippingBusinessDays)
                    ? <NsLabelNoData label={'singlePage.dataNotFound'} />
                    : <table className='table'>
                        <thead>
                            <tr>
                                <th className='col-2'></th>
                                {showWeekDayName && <th ></th>}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className='overflow-auto'>
                            {this.mapShippingBusinessDates()}
                        </tbody>
                    </table>
            }
            {resultMessageClassModal}
        </>
    }
}

export default UpBaseShippingEstimatedTimeSheet;