import React from 'react';
import { withRouter } from 'react-router-dom';

import ApShareSheetOrderEstimatedTimeModal from '../../../../../adminPanel/managements/orders/shareSheets/ApShareSheetOrderEstimatedTimeModal';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../../utilities/CultureHelper';
import OrderWithInvoiceCancellationModal from '../../../../modals/OrderWithInvoiceCancellationModal';
import { DeleteOrderService } from '../../../../../../services/ProfileSetting/UserOrderService';
import UpShippingDetail from '../../../userShippings/UpShippingDetail';
import NsMessageClassModal from '../../../../../../components/modals/NsMessageClassModal';
import NsInvoiceButton from '../../../../../../components/buttons/NsInvoiceButton';
import NsTypography from '../../../../../../components/text/NsTypography';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import NsButton from '../../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import UserOrderPaymentsModal from '../../../../modals/UserOrderPaymentsModal';
import Enumerations from '../../../../../../configs/Enumerations';
import UrlHelper from '../../../../../../utilities/UrlHelper';
import Links from '../../../../../../configs/links/AllLinks';
import FeedbackModal from '../../../../modals/FeedbackModal';
import HistoryModal from '../../../../modals/HistoryModal';
import Config from '../../../../../../configs/Config';

class UpBaseGroupSheetOrdersActionButtons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showHistoryModal: false,
            isLoading: false,
            isShowDeleteOrderMessageModal: false,
            showReadyToDeliverOrderDetailModal: false,
            showCancellationOrderWithInvoiceModal: false,
            showFeedbackModal: false,
            showOrderEstimatedTimeModal: false,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => !!this.props.openFeedbackModalDate && this.toggleFeedbackModal();

    historyModalToggle = () => this.setState({ showHistoryModal: !this.state.showHistoryModal });

    toggleOrderEstimatedTimeModal = () => this.setState({ showOrderEstimatedTimeModal: !this.state.showOrderEstimatedTimeModal });

    toggleReadyToDeliverOrderDetailModal = () => this.setState({ showReadyToDeliverOrderDetailModal: !this.state.showReadyToDeliverOrderDetailModal });

    deleteOrderClicked = () => {
        this.props.sharedSheetOrderStateType === Enumerations.sharedSheetOrderStateType.prepareFail
            ? this.setState({ isShowDeleteOrderMessageModal: true })
            : this.setState({ showCancellationOrderWithInvoiceModal: true });
    }

    setDeleteMessageTextModal = () => {
        return [
            {
                messageText: <>{!isEmpty(this.props.orderTitle)
                    ? CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestionOrder'), this.props.orderTitle)
                    : getTranslatedRawText('common.messages.areYouSureQuestionOrderWithoutParam')}</>,
                messageType: Enumerations.messageType.question
            }
        ];
    }

    deleteOrder = (confirm) => {
        !!confirm &&
            DeleteOrderService([this.props.orderId], this.deleteOrderCallback, true);
        this.setState({ isShowDeleteOrderMessageModal: false, selectedOrderId: null });
    }

    deleteOrderCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError)
            CommonHelper.safeFunctionCall(this.props.closeDeleteOrderModal, resultMessageClass);

        this.setState({ resultMessageClass });
    }

    closeCancellationOrderModal = (closeParent, resultMessageClass) => {
        this.setState({ showCancellationOrderWithInvoiceModal: false });
        !!closeParent && CommonHelper.safeFunctionCall(this.props.closeCancellationOrderModal, closeParent, resultMessageClass);
    }

    isDeletableOrderByState = (stateType) => {
        switch (stateType) {
            case Enumerations.sharedSheetOrderStateType.prepareFail:
            case Enumerations.sharedSheetOrderStateType.layouting:
                return true
            default: return false
        }
    }

    ordersPaymentClicked = () => this.setState({ showOrderPaymentModal: true });

    toggleFeedbackModal = (resultMessageClass, showMessage) => {
        this.setState({ showFeedbackModal: !this.state.showFeedbackModal, resultMessageClass: showMessage ? resultMessageClass : (<></>) }, () => {
            !isEmpty(this.props.breadcrumbs) && this.props.history.push(CommonHelper.stringFormat(
                getUrlWithCurrentCulture(Links.view.main.userOrder, UrlHelper.getUserOrderFeedbackUrl(this.state.showFeedbackModal, this.props.breadcrumbs[0])), this.props.orderId));
        });

    }

    setLoading = (isLoading) => this.setState({ isLoading });

    createActionButtons = () => {
        const { sharedSheetOrderStateType, transferRequestId, hasInvoiceNumber, isAgency, hasPayment, isFeedbackActive, hasOrderEstimatedTime } = this.props;
        const checkDeletable = this.isDeletableOrderByState(sharedSheetOrderStateType);

        return CommonHelper.clearArrayFromEmptyItem([
            Config.showOrderEstimatedTime && hasOrderEstimatedTime && { label: 'adminPanel.orders.estimatedPreparationTime', icon: 'ns-icon-preparation-time', className: 'secondary-outline', onClick: this.toggleOrderEstimatedTimeModal, },
            checkDeletable && { label: 'common.delete', icon: 'ns-icon-delete', className: 'secondary', onClick: this.deleteOrderClicked, },
            hasPayment && { label: 'adminPanel.common.ordersPayment', icon: 'ns-icon-payments', className: 'secondary-outline', onClick: this.ordersPaymentClicked, },
            transferRequestId && { label: 'profile.detail.deliveryRequestDetail', icon: 'ns-icon-delivery-request', className: 'secondary-outline', onClick: this.toggleReadyToDeliverOrderDetailModal },
            !isAgency && { label: 'profile.actionButton.statusHistory', icon: 'ns-icon-history', className: 'secondary-outline', onClick: this.historyModalToggle, },
            isFeedbackActive && { label: 'profile.feedback.feedback', icon: 'ns-icon-orders-feedback', className: 'secondary-outline', onClick: this.toggleFeedbackModal },
        ]);
    }

    render() {
        const { isLoading, showHistoryModal, isShowDeleteOrderMessageModal, showCancellationOrderWithInvoiceModal, showFeedbackModal,
            showOrderPaymentModal, showOrderEstimatedTimeModal, resultMessageClass, showReadyToDeliverOrderDetailModal } = this.state;
        const { transferRequestId, hasInvoiceNumber, orderId } = this.props;

        return (<>
            <div className='d-flex flex-wrap gap-1'>
                {this.createActionButtons().map((button, index) => <div key={index}>
                    <NsButton label={button.label}
                        startIcon={button.icon}
                        className={button.className}
                        size='small'
                        onClick={button.onClick}
                        disabled={button?.disabled}
                        loading={isLoading} />
                    {!!button?.helperText &&
                        <NsTypography variant='inherit' className='red-color font-size-12'>{getTranslatedRawText(button?.helperText)}</NsTypography>
                    }
                </div>
                )}
                <NsInvoiceButton id={this.props.orderId}
                    hasInvoiceNumber={hasInvoiceNumber}
                    setLoading={this.setLoading} />
            </div>

            {showHistoryModal &&
                <HistoryModal showModal={showHistoryModal}
                    orderId={orderId}
                    closeModal={this.historyModalToggle} />
            }

            {showOrderPaymentModal &&
                <UserOrderPaymentsModal orderId={orderId}
                    closeModal={() => this.setState({ showOrderPaymentModal: false })} />
            }

            {isShowDeleteOrderMessageModal &&
                <NsMessageClassModal show={true}
                    onClose={this.deleteOrder}
                    messageItems={this.setDeleteMessageTextModal()} />
            }

            {showReadyToDeliverOrderDetailModal &&
                <UpShippingDetail closeModal={this.toggleReadyToDeliverOrderDetailModal}
                    orderId={transferRequestId} />
            }

            {showCancellationOrderWithInvoiceModal &&
                <OrderWithInvoiceCancellationModal orderId={orderId}
                    hasInvoiceNumber={hasInvoiceNumber}
                    printInvoiceClicked={this.printInvoiceClicked}
                    isLoading={isLoading}
                    closeModal={this.closeCancellationOrderModal} />
            }

            {showFeedbackModal &&
                <FeedbackModal id={orderId}
                    feedbackType={Enumerations.feedbackTypes.sharedSheetOrder}
                    closeModal={this.toggleFeedbackModal} />
            }


            {showOrderEstimatedTimeModal &&
                <ApShareSheetOrderEstimatedTimeModal selectedOrder={{ id: orderId }}
                    closeModal={this.toggleOrderEstimatedTimeModal} />
            }

            {resultMessageClass}
        </>
        );
    }
}

export default withRouter(UpBaseGroupSheetOrdersActionButtons);