import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { EditOfficialInvoiceStatusService, GetPersonalSettingService, GetProfileFieldVisibilityTypeService } from '../../../services/ProfileSetting/PersonalSettingService';
import { isEmpty, trnIsValid } from '../../../utilities/ValidationHelper';
import NsNumberInputWithPattern from '../../../components/inputs/NsNumberInputWithPattern';
import EnumerationTranslationHelper from '../../../utilities/EnumerationTranslationHelper';
import NsLoadingProgress from '../../../components/circularProgress/NsLoadingProgress';
import UpBaseBusinessInputs from '../contents/userProfile/bases/UpBaseBusinessInputs';
import UpBaseDefaultAddress from '../contents/userProfile/bases/UpBaseDefaultAddress';
import ConstantsWithTranslation from '../../../configs/ConstantsWithTranslation';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import OfficialInvoiceRulesModal from './OfficialInvoiceRulesModal';
import NsTextField from '../../../components/inputs/NsTextField';
import NsLabelBox from '../../../components/labels/NsLabelBox';
import NsSelect from '../../../components/dropdowns/NsSelect';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import Enumerations from '../../../configs/Enumerations';

class ProfileOfficialInvoiceModal extends React.Component {

    constructor(props) {
        super(props);
        this.stickyButtons = [
            { icon: CommonHelper.getExpandIconByCulture(), className: 'secondary-text', label: 'profile.orderCart.vatRules', onClicked: this.toggleOfficialInvoiceRulesModal },
            { icon: 'ns-icon-save', className: 'primary', label: 'profile.userInfo.saveAndEnableVatInvoice', onClicked: this.saveClicked },
        ];
        this.state = {
            accountType: null,
            isValidAccountType: false,

            firstName: '',
            isValidFirstName: false,

            lastName: '',
            isValidLastName: false,

            idNumber: null,
            isValidIdNumber: false,
            showValidationIdNumberDate: false,
            nationalCodeJsRegexPattern: null,
            nationalCodeMask: null,

            companyName: '',
            isValidCompanyName: false,

            license: '',
            isValidLicense: false,

            trn: '',
            isValidTrn: false,
            trnJsRegexPattern: null,
            trnMask: null,
            showValidationTrnDate: null,

            isOfficialInvoiceEnabled: false,

            fieldVisibilityType: null,

            selectedDefaultUserAddressId: null,
            isValidSelectedDefaultUserAddressId: false,

            showValidationDate: null,
            isLoading: true,
            countryId: null,

            showOfficialInvoiceRulesModal: false,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => GetPersonalSettingService(this.getPersonalSettingCallback);

    componentDidUpdate = (prevProps, prevState) => {
        if (!!prevState.accountType && prevState.accountType !== this.state.accountType)
            this.getFieldVisibilityType();
    }

    getPersonalSettingCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError) {
            this.setState({
                accountType: resultData.isCompany ? Enumerations.accountType.business : Enumerations.accountType.personal,
                isValidAccountType: true,
                companyName: resultData.companyName || '',
                license: resultData.licenceNumber || '',
                trn: resultData.trn || '',
                firstName: resultData.firstName,
                isValidFirstName: true,
                lastName: resultData.lastName,
                isValidLastName: true,
                idNumber: resultData.nationalCode,
                isValidIdNumber: !!resultData.nationalCode,
                nationalCodeMask: resultData?.nationalCodeMask,
                trnJsRegexPattern: resultData?.trnJsRegexPattern,
                trnMask: resultData?.trnMask,
                nationalCodeJsRegexPattern: resultData?.nationalCodeJsRegexPattern,
                countryId: resultData?.countryId,
                selectedDefaultUserAddressId: resultData?.defaultUserAddressId,
                isValidSelectedDefaultUserAddressId: true,
                isOfficialInvoiceEnabled: resultData?.isOfficialInvoiceEnabled
            }, () => {
                this.getFieldVisibilityType();
            });
        }
        else
            this.setState({ resultMessageClass, isLoading: false });
    }

    getFieldVisibilityType = () => {
        this.setState({ isLoading: true });
        let parameters = {
            pageType: Enumerations.pageType.profileInformation,
            accountType: this.state.accountType,
            isOfficialInvoiceEnabled: this.state.isOfficialInvoiceEnabled
        };
        GetProfileFieldVisibilityTypeService(parameters, this.getFieldVisibilityTypeCallback);
    }

    getFieldVisibilityTypeCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            this.setState({ fieldVisibilityType: resultData });
        else
            this.setState({ resultMessageClass });
    }

    valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        this.setState({
            [valueName]: value,
            [isValidName]: isValid,
        }, () => {
            valueName === CommonHelper.getVariableName({ trn: null }) && !isEmpty(this.state.trnJsRegexPattern) &&
                this.setState({
                    isValidTrn: trnIsValid(parseInt(value), this.state.trnJsRegexPattern),
                    // trnHelperText: isEmpty(value) || trnIsValid(parseInt(value), this.state.trnJsRegexPattern)
                    //     ? null
                    //     : CommonHelper.stringFormat(getTranslatedRawText('profile.personal.invalidInput'), getTranslatedRawText('profile.personal.trn')),
                })
        });
    }

    checkFormValidation = () => {
        let isFormValid = true;
        const isValidAddress = !!this.state.selectedDefaultUserAddressId;
        const isValidOtherFieldsWithoutEmail = !!this.state.isValidFirstName && !!this.state.isValidLastName;

        if (!!this.state.idNumber && !this.state.isValidIdNumber) {
            isFormValid = false;
            this.setState({ showValidationIdNumberDate: new Date() });
        }

        if (!isValidOtherFieldsWithoutEmail || !isValidAddress) {
            isFormValid = false;
            this.setState({ showValidationDate: new Date() });
        }

        return isFormValid;
    }

    saveClicked = () => {
        if (!this.checkFormValidation())
            return false;
        this.setState({ showValidationDate: new Date() });

        const fieldVisibilityType = this.state.fieldVisibilityType;
        let data = {
            isCompany: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.isCompanyVisibilityType, this.state.accountType === Enumerations.accountType.business),
            firstName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.firstNameVisibilityType, this.state.firstName),
            lastName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.lastNameVisibilityType, this.state.lastName),
            nationalCode: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.nationalCodeVisibilityType, this.state.idNumber),
            companyName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType, this.state.companyName),
            licenceNumber: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType, this.state.license),
            trn: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType, this.state.trn.replace(/_/g, '')),
            defaultUserAddressId: this.state.selectedDefaultUserAddressId,
            mobile: CurrentUserHelper.getMobile()
        };
        this.setState({ isLoading: true },
            () => EditOfficialInvoiceStatusService(data, this.editOfficialInvoiceStatusCallback, true));
    }

    editOfficialInvoiceStatusCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            this.closeModal(resultMessageClass);
        else
            this.setState({ resultMessageClass });
    }

    closeModal = (resultMessageClass) => CommonHelper.safeFunctionCall(this.props.closeModal, resultMessageClass);

    toggleOfficialInvoiceRulesModal = () => this.setState({ showOfficialInvoiceRulesModal: !this.state.showOfficialInvoiceRulesModal });

    render() {
        const { accountType, isValidAccountType, companyName, isValidCompanyName, license, isValidLicense,
            trn, isValidTrn, firstName, isValidFirstName, lastName, isValidLastName, selectedDefaultUserAddressId,
            isValidSelectedDefaultUserAddressId, idNumber, isValidIdNumber, showValidationIdNumberDate, showValidationDate,
            showValidationTrnDate, trnHelperText, nationalCodeJsRegexPattern, nationalCodeMask, trnMask, trnJsRegexPattern,
            countryId, showOfficialInvoiceRulesModal, isLoading, resultMessageClass, fieldVisibilityType } = this.state;
        const endAdornment = EnumerationTranslationHelper.getCountryNameById(countryId);
        const labelOfNationalCode = EnumerationTranslationHelper.getNationalCodeNameById(countryId);

        return (
            <NsModal maxWidth="md"
                dialogTitle={getTranslatedRawText('profile.userInfo.profileInformationForVatInvoice')}
                showModal={true}
                closeModal={this.closeModal}
                actions={<NsStickyButton stickyButtons={this.stickyButtons} isLoading={isLoading} />}>
                <Row className='w-100 g-3'>
                    <NsLoadingProgress visible={isLoading} />

                    <Col xl={4} lg={6} sm={12} >
                        {CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.isCompanyVisibilityType)
                            ? <NsLabelBox label={getTranslatedRawText("profile.personal.accountType")}
                                value={EnumerationTranslationHelper.getAccountTypeLabel(accountType)} />
                            : <NsSelect label={getTranslatedRawText('components.accountType.accountType')}
                                className='mb-2'
                                valueChanged={(value, isValid) =>
                                    this.valueChanged(value, isValid, { accountType }, { isValidAccountType })}
                                selectedItem={accountType}
                                options={ConstantsWithTranslation.getAccountType()}
                                showValidationDate={showValidationDate} />
                        }
                    </Col>
                    <Col sm={12} />
                    <Col xl={4} lg={6} sm={12} className="mb-3">
                        <NsTextField valueChanged={(value, isValid) =>
                            this.valueChanged(value, isValid, { firstName }, { isValidFirstName })}
                            showValidationDate={showValidationDate}
                            label={getTranslatedRawText("profile.personal.firstName")}
                            size="small"
                            required
                            disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.firstNameVisibilityType)}
                            minLength={2}
                            value={firstName}
                            className="w-100" />
                    </Col>
                    <Col xl={4} lg={6} sm={12} className="mb-3">
                        <NsTextField valueChanged={(value, isValid) =>
                            this.valueChanged(value, isValid, { lastName }, { isValidLastName })}
                            showValidationDate={showValidationDate}
                            label={getTranslatedRawText("profile.personal.lastName")}
                            size="small"
                            required
                            disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.lastNameVisibilityType)}
                            minLength={2}
                            value={lastName}
                            className="w-100" />
                    </Col>
                    {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.nationalCodeVisibilityType) &&
                        <Col xl={4} lg={6} sm={12} >
                            <NsNumberInputWithPattern valueChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { idNumber }, { isValidIdNumber })}
                                label={labelOfNationalCode}
                                showValidationDate={showValidationIdNumberDate}
                                size="small"
                                codeMask={nationalCodeMask}
                                regex={nationalCodeJsRegexPattern}
                                disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.nationalCodeVisibilityType)}
                                value={idNumber}
                                endAdornment={endAdornment}
                                className="w-100" />
                        </Col>
                    }

                    <UpBaseBusinessInputs companyName={companyName}
                        fieldVisibilityType={fieldVisibilityType}
                        isValidCompanyName={isValidCompanyName}
                        license={license}
                        trnHelperText={trnHelperText}
                        isValidLicense={isValidLicense}
                        trn={trn}
                        isValidTrn={isValidTrn}
                        companyNameChanged={(value, isValid) => this.valueChanged(value, isValid, { companyName }, { isValidCompanyName })}
                        licenceChanged={(value, isValid) => this.valueChanged(value, isValid, { license }, { isValidLicense })}
                        trnChanged={(value, isValid) => this.valueChanged(value, isValid, { trn }, { isValidTrn })}
                        showValidationDate={showValidationDate}
                        showValidationTrnDate={showValidationTrnDate}
                        countryId={countryId}
                        trnMask={trnMask}
                        trnRegex={trnJsRegexPattern}
                        xl={4} />

                    <Col xs={12} className="mb-3">
                        <UpBaseDefaultAddress selectedDefaultUserAddressId={selectedDefaultUserAddressId}
                            isValidSelectedDefaultUserAddressId={isValidSelectedDefaultUserAddressId}
                            defaultUserAddressVisibilityType={fieldVisibilityType?.defaultUserAddressIdVisibilityType}
                            valueChanged={this.valueChanged} />
                    </Col>

                </Row>
                {showOfficialInvoiceRulesModal &&
                    <OfficialInvoiceRulesModal closeModal={this.toggleOfficialInvoiceRulesModal} />
                }

                {resultMessageClass}
            </NsModal>
        )
    }
}

export default ProfileOfficialInvoiceModal;