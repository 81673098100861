import clsx from 'clsx';
import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';

import '../../assets/sass/components/cards/NsPageMainContentCard.scss';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CommonHelper from '../../utilities/CommonHelper';
import NsButton from '../buttons/NsButton';
import { isEmpty } from '../../utilities/ValidationHelper';

class NsAdminPanelContentWrapper extends React.Component {

    headerButtonClicked = (e) => CommonHelper.safeFunctionCall(this.props.headerButtonClicked, e);

    render() {
        const { className, headerClassName, icon, header, contentClassName, children, actionButton } = this.props;

        return (
            <Card className={clsx(className, ' ns-card-admin-panel-content w-100')}
                variant='outlined'>
                <CardHeader className={clsx(headerClassName, ' header background-element-color page-title py-3 px-0')}
                    title={
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center'>
                                {!!icon &&
                                    <div>
                                        <i className={clsx('align-middle h2 pe-2', icon)} />
                                    </div>
                                }
                                {header}
                            </div>
                            {!isEmpty(actionButton) && <div className='px-3'>
                                <NsButton onClick={() => CommonHelper.safeFunctionCall(actionButton.onClick)}
                                    startIcon={actionButton?.icon || "ns-icon-add"}
                                    className={actionButton?.className || 'primary w-auto'}
                                    loading={actionButton?.isLoading}
                                    disabled={actionButton?.disabled}
                                    label={actionButton?.label || getTranslatedRawText('adminPanel.common.new')} />
                            </div>}
                        </div>
                    } />
                <CardContent className={contentClassName}>
                    {children}
                </CardContent>
            </Card>
        );
    }
}

export default NsAdminPanelContentWrapper;