import { GetCmsSinglePageCategoriesService } from '../../services/singlePages/CmsService';
import SpSidebarHelper from '../../views/singlePages/sidebars/SpSidebarHelper';

const GETCATEGORIES = 'GET_SIDEBAR_CATEGORIES';

const initialState = { sidebarCategories: [] };

export const SidebarCategoriesCreator = {
    getCategoriesSideBar: () => async (dispatch, getState) => {
        GetCmsSinglePageCategoriesService((resultData, result, messageClassModal) => {
            if (!!result?.data?.hasError) return;
            let data = resultData.map(menu => {
                return {
                    value: menu.value,
                    key: menu.key.map(subMenu => {
                        return {
                            ...subMenu,
                            link: SpSidebarHelper.setLink(subMenu.id),
                            code: SpSidebarHelper.setEnum(subMenu.id)
                        }
                    })
                }
            });
            dispatch({ type: GETCATEGORIES, sidebarCategories: data });
        });
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GETCATEGORIES:
            return {
                ...state,
                sidebarCategories: action.sidebarCategories
            }

        default:
            return state;
    }
}

