import React from 'react';

import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';

class OrdersModal extends React.Component {

    closeModal = () => CommonHelper.safeFunctionCall(this.props.closeModal);

    render() {

        return (
            <NsModal maxWidth='lg'
                showModal={true}
                closeModal={this.closeModal}
                dialogTitle={getTranslatedRawText("adminPanel.usersInfo.searchInOrders")}>
                {this.props.children}
            </NsModal>
        )
    }
}

export default OrdersModal;