import NsModal from "../../../../../components/modals/NsModal";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import ApPaymentAudit from "./ApPaymentAudit";

const ApPaymentAuditModal = (props) => {
    const { searchData, showModal, closeModal } = props;

    return <NsModal maxWidth='lg'
        showModal={showModal}
        closeModal={closeModal}
        noSpace={true}
        dialogTitle={getTranslatedRawText("adminPanel.usersInfo.searchInOrders")}>

        <ApPaymentAudit searchData={searchData}
            isUsedInModal={true}
        />
    </NsModal>
}

export default ApPaymentAuditModal;