import React from 'react';

import NsModal from '../../../../../../components/modals/NsModal';
import NsTypography from '../../../../../../components/text/NsTypography';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import UpBaseGroupSheetOrdersActionButtons from '../bases/UpBaseGroupSheetOrdersActionButtons';
import UpBaseGroupSheetOrdersDetailGrid from '../bases/UpBaseGroupSheetOrdersDetailGrid';

class UpGroupSheetPreparingOrderDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderTitle: '',
            sharedSheetOrderStateType: null,
            hasInvoiceNumber: null,
            orderInfo: null,
            hasPayment: false,
            isFeedbackActive: false,
        };
    }

    orderTitleChanged = (orderTitle, hasInvoiceNumber, sharedSheetOrderStateType, orderInfo) => this.setState({ orderTitle, hasInvoiceNumber, sharedSheetOrderStateType, orderInfo });

    feedbackActive = (data) => this.setState({ isFeedbackActive: data });

    render() {
        return (
            <NsModal maxWidth="lg"
                dialogTitle={getTranslatedRawText('profile.detail.orderDetail')}
                showModal={true}
                noSpace={true}
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeModal)}>

                <div className='p-3'>
                    <div className="d-flex align-items-center w-100 pb-3 mx-auto">
                        <NsTypography className='black-color fw-bold'>
                            {this.state.orderTitle}
                        </NsTypography>
                        <div className='ms-auto'>
                            <UpBaseGroupSheetOrdersActionButtons orderId={this.props.orderId}
                                hasInvoiceNumber={this.state.hasInvoiceNumber}
                                orderTitle={this.state.orderTitle}
                                hasPayment={this.state.hasPayment}
                                breadcrumbs={this.props.breadcrumbs}
                                hasOrderEstimatedTime={this.state.orderInfo?.confirmTime}
                                openFeedbackModalDate={this.props.openFeedbackModalDate}
                                closeDeleteOrderModal={this.props.closeDeleteOrderModalClicked}
                                closeCancellationOrderModal={this.props.closeCancellationOrderModalClicked}
                                sharedSheetOrderStateType={this.state.sharedSheetOrderStateType}
                                isFeedbackActive={this.state.isFeedbackActive} />
                        </div>
                    </div>

                    <UpBaseGroupSheetOrdersDetailGrid orderId={this.props.orderId}
                        hasPayment={(hasPayment) => this.setState({ hasPayment })}
                        orderTitleChanged={this.orderTitleChanged}
                        isFeedbackActive={this.feedbackActive} />
                </div>
            </NsModal>

        );
    }
}

export default UpGroupSheetPreparingOrderDetailModal;