import React from 'react';

import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import NsToggleGroupButton from '../../../../components/buttons/NsToggleGroupButton';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import Enumerations from '../../../../configs/Enumerations';
import {
    EditDefaultDeliveryAddressService, GetDefaultDeliveryAddressService
} from '../../../../services/ProfileSetting/DeliveryAddressSettingService';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../UserPanelWrapper';
import UpBaseProfileInPersonShipping from './bases/UpBaseProfileInPersonShipping';
import UpShippingRequestHeader from '../userShippings/shippingRequests/UpShippingRequestHeader';

class UpProfileAutoShippingSetting extends React.Component {

    constructor(props) {
        super(props);
        this.autoDeliveryGroupButtons = ConstantsWithTranslation.getOnOffSwitch(),
            this.state = {
                autoDelivery: false,
                addressId: '',
                addressErrorMessage: null,
                carrierErrorMessage: null,
                carrierOptions: [],
                selectedCarrier: null,
                isLoading: true,
                getDefaultAddressLoading: true,
                disableSave: false,
                saveLoading: false,
                inPersonDelivery: null,
                resultMessageClass: (<></>),
            };
    }

    componentDidMount = () => GetDefaultDeliveryAddressService(this.getDefaultDeliveryAddressCallback);

    addressChanged = (value, isValid) => {
        this.setState({
            addressId: value,
            isValidAddressId: isValid,
            selectedCarrier: null,
            addressErrorMessage: '',
            carrierErrorMessage: ''
        });
    }

    carrierChanged = (id) => this.setState({ selectedCarrier: id, disableSave: false, carrierErrorMessage: '' });

    autoDeliveryClicked = (value) => {
        this.setState({ autoDelivery: value.key === Enumerations.switchValues.on });
        if (value.key === Enumerations.switchValues.on) {
            this.setState({ disableSave: this.checkSaveButtonStatus() });
            !this.state.addressId && this.setState({ addressErrorMessage: getTranslatedRawText("profile.delivery.addressErrorMessage") });
        } else
            this.setState({ disableSave: false, addressErrorMessage: '', carrierErrorMessage: '' });
    }

    checkSaveButtonStatus = () => {
        const { addressId, carrierOptions } = this.state;
        if (!addressId || isEmpty(carrierOptions))
            return true;
        else
            return false;
    }

    discardClicked = () => {
        this.setState({ saveLoading: true, disableSave: false, getDefaultAddressLoading: true });
        GetDefaultDeliveryAddressService(this.getDefaultDeliveryAddressCallback);
    }

    saveClicked = () => {
        const { addressId, autoDelivery, selectedCarrier } = this.state;
        if (autoDelivery && (!addressId || !selectedCarrier)) {

            if (autoDelivery && !addressId)
                this.setState({ addressErrorMessage: getTranslatedRawText("profile.delivery.addressErrorMessage") });
            if (autoDelivery && !selectedCarrier)
                this.setState({ carrierErrorMessage: getTranslatedRawText("profile.delivery.carrierErrorMessage") });

            this.setState({ disableSave: true })
            return false;
        }
        this.setState({ saveLoading: true, addressErrorMessage: '', carrierErrorMessage: '', disableSave: false });
        let data = {
            autoSending: autoDelivery,
            transferToId: selectedCarrier,
            addressId: addressId
        };
        EditDefaultDeliveryAddressService(data, this.editDefaultDeliveryAddressCallback, true);
    }

    editDefaultDeliveryAddressCallback = (resultData, result, resultMessageClass) => this.setState({ saveLoading: false, resultMessageClass });


    getDefaultDeliveryAddressCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false, saveLoading: false });
        if (!result?.data?.hasError) {
            this.setState({
                addressId: resultData.addressId,
                selectedCarrier: resultData.transferToId,
                autoDelivery: resultData.autoSending,
                getDefaultAddressLoading: false,
                inPersonDelivery: resultData.inPersonDelivery
            });
            (!resultData.addressId && resultData.autoSending) && this.setState({ addressErrorMessage: getTranslatedRawText("profile.delivery.addressErrorMessage") });
        } else
            this.setState({ resultMessageClass });
    }

    handleDisableSave = (boolean) => this.state.autoDelivery && this.setState({ disableSave: boolean });

    handleCarrieroptions = (carrierOptions) => this.setState({ carrierOptions });

    render() {
        const { addressId, selectedCarrier, autoDelivery, addressErrorMessage, isLoading, getDefaultAddressLoading, carrierErrorMessage,
            disableSave, saveLoading, resultMessageClass } = this.state;

        const selectedGroupButton = !!autoDelivery
            ? this.autoDeliveryGroupButtons[1].key
            : this.autoDeliveryGroupButtons[0].key;

        this.mainButtons = [
            { label: "common.save", icon: 'ns-icon-save', onClicked: this.saveClicked, className: 'primary', disabled: disableSave },
            { label: "common.discard", icon: 'ns-icon-close', onClicked: this.discardClicked, className: 'primary-outline', disabled: false },
        ];

        return (
            <UserPanelWrapper titleName={getTranslatedRawText("profile.actionButton.autoDeliverySetting")}
                titleIcon='ns-icon-auto-delivery-setting'>

                <NsLoadingProgress visible={isLoading} />

                {!isEmpty(this.state.inPersonDelivery)
                    ? !!this.state.inPersonDelivery
                        ? <UpBaseProfileInPersonShipping isKarajInPersonDelivery={this.state.autoDelivery} />
                        : <>
                            <div className='d-flex mb-3'>
                                <NsToggleGroupButton isShowGroupLabel={false}
                                    onClick={this.autoDeliveryClicked}
                                    selectedItemId={selectedGroupButton}
                                    items={this.autoDeliveryGroupButtons} />
                            </div>
                            <p className="section-title">
                                {getTranslatedRawText("profile.delivery.defaultSending")}
                            </p>
                            {!getDefaultAddressLoading &&
                                <div className={`${!autoDelivery && 'mute-cover'} mb-5  w-100`}>
                                    <UpShippingRequestHeader autoDelivery={autoDelivery}
                                        selectedCarrierId={selectedCarrier}
                                        selectedAddressId={addressId}
                                        addressErrorMessage={addressErrorMessage}
                                        carrierErrorMessage={carrierErrorMessage}
                                        handleDisableSave={this.handleDisableSave}
                                        handleCarrieroptions={this.handleCarrieroptions}
                                        addressChanged={this.addressChanged}
                                        carrierChanged={this.carrierChanged} />
                                </div>
                            }
                            <NsStickyButton stickyButtons={this.mainButtons} isLoading={saveLoading} />
                        </>
                    : null}

                {resultMessageClass}
            </UserPanelWrapper >
        );
    }
}

export default UpProfileAutoShippingSetting;