import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsButton from '../../../../../components/buttons/NsButton';
import NsImageScaledView from '../../../../../components/images/NsImageScaledView';
import NsTypography from '../../../../../components/text/NsTypography';
import NsTour from '../../../../../components/tour/NsTour';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import UrlHelper from '../../../../../utilities/UrlHelper';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import AddFilterModal from '../../../modals/AddFilterModal';
import ExtendedServiceWrapper from './ExtendedServiceWrapper';
class ExtendedServiceFilter extends React.Component {

    constructor(props) {
        super(props);
        this.tourId = Enumerations.tourGuide.filterFile;
        this.tourSteps = RegularOrderTours.getFilterTour();
        this.routeId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.state = {
            showTour: !CurrentUserHelper.isTourSeen(this.tourId),
            filterPrice: 0,
            filterMinDimension: null,
            masterFiles: [],
            printedSide: OrderHelper.getPrintedSide(this.routeId)?.key,
            isShowAddFiltersModal: false,
            isFront: true,
            selectedFrontFilters: [],
            selectedBackFilters: [],
            selectedFilters: [],
            refreshExtendedServiceSummaryDate: null,
        };
    }

    componentDidMount = () => {
        OrderHelper.getMasterFilesWithFullInfo(this.routeId, (masterFiles) => {
            let service = OrderHelper.getSpecificService(this.routeId, Enumerations.extendedServices.filters)
            this.setState({
                selectedFilters: service.selectedItem,
                filterPrice: service.price,
                filterMinDimension: service.filterMinDimension?.minWidth <= service.filterMinDimension?.minHeight
                    ? service.filterMinDimension
                    : { minWidth: service.filterMinDimension?.minHeight, minHeight: service.filterMinDimension?.minWidth },
                masterFiles: masterFiles[0],
            }, () => this.seperateFiltersToSideFilter());
        });
    }

    showAddFilterModalFront = () => {
        this.setState({ isShowAddFiltersModal: true, showTour: false });
        !CurrentUserHelper.isTourSeen(this.tourId) && CurrentUserHelper.addToSeenTours(this.tourId);
    }

    showAddFilterModalBack = () => this.setState({ isShowAddFiltersModal: true, isFront: false });

    closeAddFilterModalClicked = () => this.setState({ isShowAddFiltersModal: false, isFront: true });

    saveAddFilterModalClicked = (filters, isFront) => {
        const { selectedFrontFilters, selectedBackFilters } = this.state;
        let filterSideName = CommonHelper.getVariableName(isFront ? { selectedFrontFilters } : { selectedBackFilters });
        this.setState({ [filterSideName]: filters },
            () => this.setState({ selectedFilters: [...this.state.selectedFrontFilters, ...this.state.selectedBackFilters] },
                () => {
                    OrderHelper.updateExtendedService(this.routeId, Enumerations.extendedServices.filters, this.state.selectedFilters)
                    this.setState({ refreshExtendedServiceSummaryDate: new Date() });
                }
            ));
    }

    seperateFiltersToSideFilter = () => {
        let filters = this.state.selectedFilters;
        if (!!filters)
            this.setState({
                selectedFrontFilters: filters.filter(filter => filter.isFront === true),
                selectedBackFilters: filters.filter(filter => filter.isFront === false)
            });
    }

    render() {
        const { masterFiles, printedSide, isFront, selectedFilters, selectedBackFilters, selectedFrontFilters, filterPrice, isShowAddFiltersModal,
            refreshExtendedServiceSummaryDate, filterMinDimension, showTour } = this.state;

        return (
            <ExtendedServiceWrapper otherProps={this.props}
                routeId={this.routeId}
                isSelectedRequirements={selectedFilters?.length > 0}
                refreshExtendedServiceSummaryDate={refreshExtendedServiceSummaryDate}
                currentExtendedService={Enumerations.extendedServices.filters}>

                <Row className="w-100 justify-content-start p-3">

                    <SideFilters printedSide={printedSide}
                        title={getTranslatedRawText('regularOrder.common.frontSide')}
                        file={masterFiles.frontSideFileInfo}
                        showAddFilterModal={this.showAddFilterModalFront}
                        filterslength={selectedFrontFilters?.length}
                        filterPrice={filterPrice} />

                    {printedSide === Enumerations.printedSide.twoSide &&
                        <SideFilters printedSide={printedSide}
                            title={getTranslatedRawText('regularOrder.common.backSide')}
                            file={masterFiles.backSideFileInfo}
                            showAddFilterModal={this.showAddFilterModalBack}
                            filterslength={selectedBackFilters?.length}
                            filterPrice={filterPrice} />
                    }

                </Row>
                {isShowAddFiltersModal &&
                    <AddFilterModal
                        filterPrice={filterPrice}
                        filterMinDimension={filterMinDimension}
                        showModal={isShowAddFiltersModal}
                        file={isFront ? masterFiles.frontSideFileInfo : masterFiles.backSideFileInfo}
                        isFront={isFront}
                        selectedAreas={isFront ? selectedFrontFilters : selectedBackFilters}
                        saveAddFilterModalClicked={this.saveAddFilterModalClicked}
                        closeAddFilterModalClicked={this.closeAddFilterModalClicked} />
                }

                {showTour && <NsTour steps={this.tourSteps}
                    id={this.tourId} />}

            </ExtendedServiceWrapper>
        );
    }
}

function SideFilters(props) {
    const { file, filterPrice, filterslength, title } = props;
    const totalSideFilterPrice = CommonHelper.currencyFormatByDefaultConfig(filterPrice * filterslength);
    return (
        <Col xs={12} md={6} className=''>
            <NsTypography variant='inherit' className='light-text'>
                {title}
            </NsTypography>

            <div className='d-flex mt-2'>
                <div className='w-25 d-none d-md-block'>
                    <NsImageScaledView src={!!file && file.thumbnail}
                        imgAlt='components.imagesAlt.scaledImage'
                        width='w-100' />
                </div>

                <div className='mx-2 mb-2'>
                    <NsButton startIcon={!!filterslength ? 'ns-icon-edit me-auto' : 'ns-icon-add me-auto'}
                        size='small'
                        label={!!filterslength ? "regularOrder.extendedService.editFilter" : "regularOrder.extendedService.addFilter"}
                        onClick={() => props.showAddFilterModal()}
                        className='secondary extendedService-tour-filter' />
                </div>
            </div>

            {!!filterslength &&
                <NsTypography className='light-paragraph my-2'>
                    {CommonHelper.stringFormat(getTranslatedRawText("regularOrder.extendedService.totalFilter"),
                        filterslength,
                        totalSideFilterPrice)}
                </NsTypography>
            }
        </Col >
    );
}

export default ExtendedServiceFilter