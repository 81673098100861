import { Component } from "react";
import { Table } from "react-bootstrap";
import { withRouter } from "react-router";

import { GetPrintingProcessTimeService } from "../../../services/product/ProductService";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import CommonHelper from "../../../utilities/CommonHelper";
import Enumerations from "../../../configs/Enumerations";
import DateHelper from "../../../utilities/DateHelper";
import ApShareSheetOrderEstimatedTimeModal from "../../adminPanel/managements/orders/shareSheets/ApShareSheetOrderEstimatedTimeModal";

class PdPrintingProcessTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCirculationKey: props.selectedCirculationKey,
            selectedTurnaround: props.selectedTurnaround,
            selectedPrintedSide: props.selectedPrintedSide,
            rows: [],
            processTimeBasedSelectedOptions: {},
            isLoading: false,
            showOrderEstimatedTimeModal: false,
        }
    }

    componentDidMount = () => {
        this.getPrintingProcessTime();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.selectedCirculationKey !== this.state.selectedCirculationKey) {
            this.setState({
                selectedCirculationKey: this.props.selectedCirculationKey,
            }, () => {
                this.getPrintingProcessTime();
            })
        }

        if (prevProps.selectedTurnaround !== this.state.selectedTurnaround || prevProps.selectedPrintedSide !== this.state.selectedPrintedSide) {
            this.setState({
                selectedTurnaround: this.props.selectedTurnaround,
                selectedPrintedSide: this.props.selectedPrintedSide
            }, () => {
                this.getProcessTimeBasedSelectedOptions(this.state.rows);
            })
        }

    }

    toggleOrderEstimatedTimeModal = () => this.setState({ showOrderEstimatedTimeModal: !this.state.showOrderEstimatedTimeModal })

    getPrintingProcessTime = () => {
        if (this.state.selectedCirculationKey) {
            GetPrintingProcessTimeService({ id: this.state.selectedCirculationKey }, this.getPrintingProcessTimeCallback);
            this.setState({
                isLoading: true
            });
        };
    }

    getPrintingProcessTimeCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.setState({
                rows: resultData
            }, () => this.getProcessTimeBasedSelectedOptions(this.state.rows));
        }
    }

    getProcessTimeBasedSelectedOptions = (data) => {
        data.map(turnaround => {
            turnaround.key === this.state.selectedTurnaround.key && turnaround.printingSideProductFeature.map(printingSide => {
                printingSide.key === this.state.selectedPrintedSide.key && this.setState({
                    processTimeBasedSelectedOptions: printingSide
                }, () => {
                    CommonHelper.safeFunctionCall(this.props.getProcessTimeBasedSelectedOptions, DateHelper.calcProcessHoursAndDays(this.state.processTimeBasedSelectedOptions?.processHours), this.state.rows);
                })
            })
        })
    }

    getDataOfCell = (turnaround, printedSide) => {
        return this.state.rows[turnaround]?.printingSideProductFeature[printedSide]
    }

    productDetails = () => ({
        pprId: this.state?.selectedCirculationKey,
        turnarounds: this.props?.turnarounds,
        printedSides: this.props?.printedSides,
        selectedPrintedSide: this.state?.selectedPrintedSide,
        selectedTurnaround: this.state?.selectedTurnaround,
    })

    render() {
        const { showOrderEstimatedTimeModal } = this.state;
        return (!this.state.isLoading ?
            <>
                <span>
                    {getTranslatedRawText('product.detail.processTimeNotice') + ' '}
                    <b className="text-decoration-underline cursor-pointer" onClick={this.toggleOrderEstimatedTimeModal}>{DateHelper.calcProcessHoursAndDays(this.state.processTimeBasedSelectedOptions?.processHours)}</b>
                </span>
                <Table className='w-auto mt-3 m-0 border table-bordered font-size-14 table-sm mb-2'>
                    <thead>
                        <tr className='background-element-color title-color'>
                            <th className='text-center text-nowrap'>{getTranslatedRawText('regularOrder.header.printedSide')}</th>
                            {(this.getDataOfCell(0, 0)?.value || this.getDataOfCell(0, 1)?.value) && <th className='text-center text-nowrap'>{getTranslatedRawText('regularOrder.header.normal')}</th>}
                            {(this.getDataOfCell(1, 0)?.value || this.getDataOfCell(1, 1)?.value) && <th className='text-center text-nowrap'>{getTranslatedRawText('regularOrder.header.express')}</th>}
                        </tr>
                    </thead>
                    <tbody className="background-white-color">
                        {this.getDataOfCell(0, 0)?.value && <tr>
                            <td>{this.getDataOfCell(0, 0)?.value}</td>
                            {this.getDataOfCell(0, 0) && <td>{DateHelper.calcProcessHoursAndDays(this.getDataOfCell(0, 0)?.processHours)}</td>}
                            {this.getDataOfCell(1, 0) && <td>{DateHelper.calcProcessHoursAndDays(this.getDataOfCell(1, 0)?.processHours)}</td>}
                        </tr>}
                        {this.getDataOfCell(0, 1)?.value && <tr>
                            <td>{this.getDataOfCell(0, 1)?.value}</td>
                            {this.getDataOfCell(0, 1) && <td>{DateHelper.calcProcessHoursAndDays(this.getDataOfCell(0, 1)?.processHours)}</td>}
                            {this.getDataOfCell(1, 1) && <td>{DateHelper.calcProcessHoursAndDays(this.getDataOfCell(1, 1)?.processHours)}</td>}
                        </tr>}
                    </tbody>
                </Table>

                {showOrderEstimatedTimeModal &&
                    <ApShareSheetOrderEstimatedTimeModal productDetails={this.productDetails()}
                        closeModal={this.toggleOrderEstimatedTimeModal} />
                }
            </>
            : <NsSkeleton isVisible={true}
                variant={[
                    Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect,
                ]} />
        )
    }
}

export default withRouter(PdPrintingProcessTime)