import clsx from 'clsx';
import React from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import "../../assets/sass/components/carouselSwiper/NsCarouselSwiper.scss";
import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import NsProductCard from '../cards/NsProductCard';
import NsImage from '../images/NsImage';
import NsCarouselSwiperController from './NsCarouselSwiperController';

class NsCarouselSwiper extends React.Component {

    constructor(props) {
        super(props);
        this.borderStyle = 'border-outline-color-1px rounded-2';
        this.newRef = React.createRef();
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.images !== this.props.images || newProps.activeStep !== this.props.activeStep)
            this.activeStepChanged(newProps.activeStep)
    }

    activeStepChanged = (activeStep) => {
        this.newRef.current.swiper.slideTo(activeStep);
        CommonHelper.safeFunctionCall(this.props.imageChanged, activeStep)
    }

    mapImages = (activeStep, images, isFestivalAwardModal) => {
        return !isEmpty(images) && images.map((image, index) =>
            <SwiperSlide key={index} className='pb-1 d-flex align-self-center' >
                <NsImage className={clsx('cursor-pointer', activeStep === index && this.borderStyle)}
                    imgAlt="components.imagesAlt.uploadedImage"
                    onClick={() => this.activeStepChanged(index)}
                    src={isFestivalAwardModal ? CommonHelper.createImageUrl(image.imageUrl) : image}
                    width={isFestivalAwardModal && "100"} />
            </SwiperSlide>
        );
    }

    mapProducts = (activeStep, products, isStore, reloadPageAfterClickingProduct) => {
        return !isEmpty(products) && products.map((product, index) =>
            <SwiperSlide key={product.selectedId} className={`d-flex align-self-center ${this.props.isFullWidth
                ? 'swiper-slide__productsWithFullWidth'
                : 'swiper-slide__products'}`}>
                <NsProductCard product={product}
                    isAuthenticated={CurrentUserHelper.isAuthenticated()}
                    isStoreProducts={isStore}
                    isNotShowAddToCardSection={isStore}
                    reloadPageAfterClickingProduct={reloadPageAfterClickingProduct}
                    usedIn={Enumerations.imageSitePosition.shopProductCard}
                    className="mb-4 mt-2" />
            </SwiperSlide>
        );
    }

    render() {
        const { activeStep, images, isFestivalAwardModal, isStore, products, reloadPageAfterClickingProduct } = this.props;
        return (
            <div className={'align-items-end flex-nowrap d-flex'} >
                <Swiper className={clsx('d-flex align-items-center flex-nowrap', isFestivalAwardModal || !isEmpty(products) ? 'w-100' : 'w-75')}
                    watchSlidesProgress={true}
                    watchSlidesVisibility={true}
                    slidesPerView={'auto'}
                    spaceBetween={3}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={!isEmpty(products) && [Pagination]}
                    ref={this.newRef}>
                    {
                        !isEmpty(products)
                            ? this.mapProducts(activeStep, products, isStore, reloadPageAfterClickingProduct)
                            : this.mapImages(activeStep, images, isFestivalAwardModal)
                    }

                </Swiper>
                {!isFestivalAwardModal && isEmpty(products) && <NsCarouselSwiperController activeStep={activeStep}
                    changeActiveStep={this.activeStepChanged}
                    maxSteps={images?.length ?? 0} />}
            </div>
        );
    }
}

export default NsCarouselSwiper;