import React from 'react';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsLoadingProgress from '../../../components/circularProgress/NsLoadingProgress';
import NsTextField from '../../../components/inputs/NsTextField';
import NsModal from '../../../components/modals/NsModal';
import Enumerations from '../../../configs/Enumerations';
import { GetFileDetailService, RenameFileService } from '../../../services/fileManager/FileManagementService';
import { GetFolderDetailService, RenameFolderService } from '../../../services/fileManager/FolderManagementService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import { messageClass } from '../../../utilities/MessageModalHelper';
import { fileOrFolderNameIsValid } from '../../../utilities/ValidationHelper';


class FmRenameFileFolderModal extends React.Component {
    constructor(props) {
        super(props);
        this.mainButtonList = [
            { label: "fileManager.modals.rename.submit", onClicked: this.renameClicked, className: 'primary' },
            { label: "common.cancel", onClicked: () => this.closeClicked(), className: 'primary-outline' },
        ];
        this.state = {
            selectedItem: { name: '' },
            newName: '',
            isValidNewName: false,
            showValidationDate: '',
            isLoading: true,
            resultMessageClass: (<React.Fragment></React.Fragment>),
        }
    }

    newNameChanged = (value, isValid) => {
        this.setState({
            newName: value,
            isValidNewName: isValid,
        });
    }

    closeClicked = (isRefreshNeeded) => {
        if (!isRefreshNeeded) {
            CommonHelper.safeFunctionCall(this.props.onClose);
            return;
        }
        CommonHelper.safeFunctionCall(this.props.onClose,
            !!this.state.selectedItem.isFolder ? Enumerations.fileManagerContentType.folder : Enumerations.fileManagerContentType.file)
    }

    renameClicked = () => {
        const { selectedItem, newName, isValidNewName } = this.state;

        if (!isValidNewName) {
            this.setState({ showValidationDate: new Date() });
            return false;
        }

        if (this.getName(selectedItem) === newName) {
            this.closeClicked();
            return;
        }

        if (!!selectedItem && selectedItem.name !== newName) {
            if (fileOrFolderNameIsValid(this.state.newName)) {
                this.setState({ isLoading: true }, () => {
                    if (!!this.state.selectedItem.isFolder) {
                        let data = {
                            id: this.state.selectedItem.id,
                            name: this.state.selectedItem.name,
                            newName: this.state.newName
                        };
                        RenameFolderService(data, this.renameCallback);
                    } else {
                        let data = {
                            id: this.state.selectedItem.id,
                            name: this.state.selectedItem.name,
                            newName: this.state.newName + '.' + this.state.selectedItem.name.split('.').pop()
                        };
                        RenameFileService(data, this.renameCallback);

                    }
                });
            } else
                this.setState({
                    resultMessageClass: messageClass(!!this.state.selectedItem.isFolder ?
                        getTranslatedRawText("common.messages.folderValidationError")
                        :
                        getTranslatedRawText("common.messages.fileValidationError")
                    )
                });
        } else
            this.closeClicked(true);
    }

    renameCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.closeClicked(true)
        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });

    }

    componentDidMount = () => {
        this.props.selectedItemContent.isFolder ?
            GetFolderDetailService({ id: this.props.selectedItemContent.id },
                this.getDetailCallback)
            :
            GetFileDetailService({ id: this.props.selectedItemContent.id },
                this.getDetailCallback)
    }

    getDetailCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.setState({
                selectedItem: resultData || { name: '' },
                newName: this.getName(resultData),
                isValidNewName: true,
                showValidationDate: '',
                isLoading: false,
                resultMessageClass: (<React.Fragment></React.Fragment>),
            });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getName = (data) => {
        return data.isFolder ? data?.name : data?.name.substring(0, data?.name.lastIndexOf("."));
    }

    render() {
        const selectedItemName = CommonHelper.textTruncate(this.state.selectedItem?.name)
        const modalTitle = CommonHelper.stringFormat(getTranslatedRawText("fileManager.modals.rename.title"),
            selectedItemName ?? getTranslatedRawText('fileManager.modals.rename.isLoading'));

        return (
            <>
                <NsLoadingProgress visible={this.state.isLoading} />
                <NsModal maxWidth="xs"
                    dialogTitle={modalTitle}
                    primaryActionButton={this.renameClicked}
                    showModal={this.props.showModal}
                    closeModal={this.closeClicked}
                    actions={<NsStickyButton stickyButtons={this.mainButtonList} isModal={true} isLoading={this.state.isLoading} />}>

                    <NsTextField label={getTranslatedRawText("fileManager.modals.rename.inputLabel")}
                        showValidationDate={this.state.showValidationDate}
                        className="w-100 my-4"
                        value={this.state.newName}
                        minLength={1}
                        autoFocus={true}
                        disabled={this.state.isLoading}
                        valueChanged={this.newNameChanged} />

                    {this.state.resultMessageClass}
                </NsModal>
            </>
        );
    }
}

export default FmRenameFileFolderModal;