import clsx from "clsx";

import EnumerationTranslationHelper from "./EnumerationTranslationHelper";
import Enumerations from "../configs/Enumerations";
import { isEmpty } from "./ValidationHelper";
import StorageHelper from "./StorageHelper";

export const PaymentStateType = (props) => {
    return !!props.status && <div className={clsx('d-flex align-items-start', props.status === Enumerations.paymentStateType.success
        ? 'success-color'
        : props.status === Enumerations.paymentStateType.open ? 'warning-color' : 'red-color')} >
        <i className={props.status === Enumerations.paymentStateType.success
            ? 'ns-icon-circle-confirm h5'
            : props.status === Enumerations.paymentStateType.open ? 'ns-icon-post-on h5' : 'ns-icon-critical h5'} />
        {EnumerationTranslationHelper.getPaymentStatusTypeText(props.status)}
    </div >
}

export const TransactionStateType = (props) => {
    const { fileTransactionStatus, websiteTransactionStatus } = props;
    return <div className={clsx('d-flex align-items-start', fileTransactionStatus !== websiteTransactionStatus
        ? 'red-color'
        : '')} >
        {EnumerationTranslationHelper.getPaymentStatusTypeText(websiteTransactionStatus)}
    </div >
}

export const PaymentAuditMatchingType = (props) => {
    let matchingStatus = EnumerationTranslationHelper.getPaymentAuditMatchingType(props.matchingType);
    return <div className={clsx('d-flex align-items-start', matchingStatus[1])}>
        {matchingStatus[0]}
    </div >
}

export const addGridRowClassName = (rows, trueConditionClassName, falseConditionClassName, rowsIndex) => {
    if (isEmpty(rows)) return rows;
    rows.map((row, index) => {
        row.rowClassName = rowsIndex.some(rowIndex => rowIndex == index)
            ? trueConditionClassName
            : falseConditionClassName;
    })

    return rows;
}

export const addOrUpdateGridColumnOrder = (gridId, columnState) => {
    let gridColumnOrders = StorageHelper.getFromStorage(Enumerations.storage.gridColumnOrder);
    if (isEmpty(gridColumnOrders))
        StorageHelper.saveInStorage(Enumerations.storage.gridColumnOrder, [{ id: gridId, columnState }])
    else {
        let gridColumnOrderIndex = gridColumnOrders.findIndex(item => item.id == gridId);
        if (gridColumnOrderIndex > -1) {
            gridColumnOrders[gridColumnOrderIndex].columnState = columnState;
            StorageHelper.saveInStorage(Enumerations.storage.gridColumnOrder, gridColumnOrders);
        } else
            StorageHelper.saveInStorage(Enumerations.storage.gridColumnOrder, [...gridColumnOrders, { id: gridId, columnState }]);
    }
}

export const deleteGridColumnOrder = (gridId) => {
    let gridColumnOrders = StorageHelper.getFromStorage(Enumerations.storage.gridColumnOrder);
    if (isEmpty(gridColumnOrders))
        return;
    else {
        gridColumnOrders = gridColumnOrders.filter(item => item.id !== gridId);
        StorageHelper.saveInStorage(Enumerations.storage.gridColumnOrder, gridColumnOrders);
    }
}
