import React, { useState } from 'react';

import ShoppingBagMainPriceInquery from '../../../products/shoppingBags/main/ShoppingBagMainPriceInquery';
import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ShoppingBagMainHistory from '../../../products/shoppingBags/main/ShoppingBagMainHistory';

const ApShoppingBagPriceInquery = (props) => {

    const [refreshHistoriesDate, setRefreshHistoriesDate] = useState(null);
    const [reviewHistoryId, setReviewHistoryId] = useState(null);
    const refreshHistories = () => setRefreshHistoriesDate(new Date());
    const reviewHistoryClicked = (reviewHistoryId) => setReviewHistoryId(reviewHistoryId);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.calculateCustomize")}
            icon={'ns-icon-calculate-customize h1'}>


            <ShoppingBagMainPriceInquery isAuthenticated={true}
                isUsedInAdmin={true}
                reviewHistoryId={reviewHistoryId}
                refreshHistories={refreshHistories} />
            <ShoppingBagMainHistory refreshHistoriesDate={refreshHistoriesDate}
                isUsedInAdmin={true}
                reviewHistoryClicked={reviewHistoryClicked} />

        </NsAdminPanelContentWrapper>
    );
}

export default React.memo(ApShoppingBagPriceInquery);