import React from "react";
import { Col } from "react-bootstrap";

import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsSkeleton from "../../../../components/skeletons/NsSkeleton";
import NsTypography from "../../../../components/text/NsTypography";
import CommonHelper from "../../../../utilities/CommonHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsCard from "../../../../components/cards/NsCard";

const ApDcbCustomerNotReached = (props) => {

    const { cashback, isLoading } = props;

    return (<>
        <Col md={6}>
            <NsCard className="p-4 h-100">
                <NsTypography className='font-size-16 fw-bold mb-3'>
                    {getTranslatedRawText('adminPanel.cashback.qtyOfCustomersNotReached')}
                    <span className="font-size-14 fw-normal d-block">{getTranslatedRawText('adminPanel.cashback.withSubmittedOrders')}</span>
                </NsTypography>
                <div className="d-flex justify-content-end py-2">
                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            className='w-100'
                            variant={[Enumerations.skeleton.text]} />
                        : <NsTypography className='page-title'>
                            {cashback?.countNotAchievedUserToDiscountButHasOrder}
                        </NsTypography>
                    }
                </div>
            </NsCard>
        </Col>
        <Col md={6}>
            <NsCard className="p-4 h-100">

                <NsTypography className='font-size-16 fw-bold mb-3'>
                    {getTranslatedRawText('adminPanel.cashback.totalSalesAmountOfCustomersWhoHaveNotReached')}
                    <span className="font-size-14 fw-normal d-block">{getTranslatedRawText('adminPanel.cashback.withSubmittedOrders')}</span>
                </NsTypography>
                <div className="d-flex justify-content-end  py-2">
                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            className='w-100'
                            variant={[Enumerations.skeleton.text]} />
                        : <NsTypography className='page-title'>
                            {CommonHelper.currencyFormatByDefaultConfig(cashback?.sumNetSaleNotAchievedUserToDiscountButHasOrder)}
                        </NsTypography>
                    }
                </div>
            </NsCard >
        </Col>
    </>
    );
};

export default React.memo(ApDcbCustomerNotReached);