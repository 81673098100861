import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import NsButton from '../../../../components/buttons/NsButton';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsImage from '../../../../components/images/NsImage';
import NsLabelBox from '../../../../components/labels/NsLabelBox';
import NsModal from '../../../../components/modals/NsModal';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import NsTab from '../../../../components/tabs/NsTab';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';
import {
    GetFestivalDetailService,
    GetFestivalPrizesService,
    GetFestivalWinnersService
} from '../../../../services/adminPanel/AdminFestivalsService';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import DateHelper from '../../../../utilities/DateHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBaseUserInfoModal from '../users/bases/ApBaseUserInfoModal';
import ApFestivalPrizesModal from './ApFestivalPrizesModal';

const ApFestivalDetailModal = (props) => {

    const tabs = CommonHelper.clearArrayFromEmptyItem(ConstantsWithTranslation.getFestivalInfoTabs());
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [festivalData, setFestivalData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [showWinnerDetailModal, setShowWinnerDetailModal] = useState(false);
    const [selectedWinnerId, setSelectedWinnerId] = useState(null);
    const [showPrizeDetailModal, setShowPrizeDetailModal] = useState(false);
    const [selectedPrizeId, setSelectedPrizeId] = useState(null);
    const isWinners = ConstantsWithTranslation.getFestivalInfoTabs()[selectedTabIndex].id === Enumerations.festivalInfoTabs.winners;

    const columns = isWinners
        ? [
            hasAccess([UserClaimEnumerations.getApCustomerById]) && {
                field: 'action',
                headerName: getTranslatedRawText('adminPanel.common.action'),
                width: 80,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (winner) => {
                        return <NsButton className='secondary-text'
                            loading={isLoading}
                            startIcon={'ns-icon-invoice-details'}
                            onClick={() => winnerDetailClicked(winner.detailLedgerId)} />
                    }
                }
            },
            {
                field: 'name',
                headerName: getTranslatedRawText('adminPanel.festivals.winnerName'),
                width: 520
            },
            {
                field: 'prizeName',
                headerName: getTranslatedRawText('adminPanel.festivals.prizeName'),
                width: 220
            },
            {
                field: 'winCode',
                headerName: getTranslatedRawText('adminPanel.festivals.winsCode'),
                width: 150,
                dataType: Enumerations.gridType.number,
            },
            {
                field: 'dateOfWinning',
                headerName: getTranslatedRawText('adminPanel.festivals.dateOfWinning'),
                width: 180,
                dataType: Enumerations.gridType.dateTime
            }
        ]
        : [
            hasAccess([UserClaimEnumerations.getApFestivalWinnersActionId]) && {
                field: 'action',
                headerName: getTranslatedRawText('adminPanel.common.action'),
                width: 80,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (festival) => {
                        return <NsButton className='secondary-text'
                            loading={isLoading}
                            startIcon={'ns-icon-invoice-details'}
                            onClick={() => prizeDetailClicked(festival.id)} />
                    }
                }
            },
            {
                field: 'prizePhoto',
                headerName: getTranslatedRawText('adminPanel.festivals.prizePhoto'),
                width: 150,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (prize) => {
                        return <NsImage imageUrl={prize.thumbnail}
                            imgAlt="components.imagesAlt.festivalPrize" />
                    }
                }
            },
            {
                field: 'name',
                headerName: getTranslatedRawText('adminPanel.festivals.prizeName'),
                width: 520
            },
            {
                field: 'count',
                headerName: getTranslatedRawText('adminPanel.festivals.prizesCount'),
                width: 100
            },
            {
                field: 'minAmount',
                headerName: getTranslatedRawText('adminPanel.festivals.minBuyAmount'),
                width: 300,
                dataType: Enumerations.gridType.number,
            },
        ];

    useEffect(() => {
        GetFestivalDetailService(props.festivalId, getFestivalDetailCallback);
    }, [])

    const getFestivalDetailCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            setFestivalData(resultData);
        } else
            setResultMessageClass(messageClassModal);
        setIsLoading(false);
    }

    const getFestivalWinnersOrPrizes = () => isWinners
        ? hasAccess([UserClaimEnumerations.getApFestivalWinners]) ? GetFestivalWinnersService : ''
        : hasAccess([UserClaimEnumerations.getApFestivalPrizes]) ? GetFestivalPrizesService : '';

    const winnerDetailClicked = (id) => {
        setShowWinnerDetailModal(true);
        setSelectedWinnerId(id);
    }

    const prizeDetailClicked = (id) => {
        setSelectedPrizeId(id);
        setShowPrizeDetailModal(true);
    }

    const tabChanged = (tabIndex) => setSelectedTabIndex(tabIndex);

    const closeWinnerModal = () => setShowWinnerDetailModal(false);

    const closePrizeModal = () => setShowPrizeDetailModal(false);

    return (
        <NsModal maxWidth='lg'
            showModal={true}
            closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
            dialogTitle={getTranslatedRawText("adminPanel.sidebar.festivals")} >
            {isLoading
                ? <NsSkeleton isVisible={true}
                    variant={[Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect]} />
                : <Row className='mx-auto'>
                    <div className='fw-bold py-1 pb-3'>{festivalData?.name}</div>
                    <div className='py-1 gap-2 flex-wrap d-flex'>
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.codeCount')} value={festivalData?.codeCount} />
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.prizesCount')} value={festivalData?.prizeCount} />
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.executionType')} value={festivalData?.execution} />
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.createDate')} value={DateHelper.getDatePart(festivalData?.createDate)} />
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.invoiceType')} value={festivalData?.salesConfigString} />
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.removeWinnerType')} value={festivalData?.removeWinner} />
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.pointingType')} value={festivalData?.pointing} />
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.startDate')} value={DateHelper.getDatePart(festivalData?.startDate)} />
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.endDate')} value={DateHelper.getDateAndTime(festivalData?.endDate)} />
                        <NsLabelBox label={getTranslatedRawText('adminPanel.festivals.executionDate')} value={DateHelper.getDatePart(festivalData?.executionDate)} />
                    </div>
                </Row>
            }

            <NsTab tabs={tabs}
                onChange={tabChanged}
                value={selectedTabIndex}
                className='mt-2' />

            {!isEmpty(tabs) && <NsDataGridWithServiceCall service={getFestivalWinnersOrPrizes()}
                parameters={{ id: props.festivalId }}
                columnDefs={columns}
                hasPagination={true} />
            }

            {showPrizeDetailModal &&
                <ApFestivalPrizesModal selectedPrizeId={selectedPrizeId}
                    festivalId={props.festivalId}
                    closeModal={closePrizeModal} />}

            {showWinnerDetailModal &&
                <ApBaseUserInfoModal showModal={showWinnerDetailModal}
                    selectedUserId={selectedWinnerId}
                    closeModal={closeWinnerModal} />
            }

            {resultMessageClass}
        </NsModal >
    )
}

export default React.memo(ApFestivalDetailModal);