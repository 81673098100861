import React, { useEffect, useState } from "react";

import { GetFeedbackAnnouncementBasicInfoService, GetFeedbackEmployeeBasicInfoService, GetFeedbackShareSheetOrderBasicInfoService } from "../../../../../services/adminPanel/AdminFeedbackService";
import { GetApDfbCommentsService } from "../../../../../services/adminPanel/AdminDashboardService";
import ApBaseFeedbackDetailModal from "../../../feedbacks/bases/ApBaseFeedbackDetailModal";
import ConstantsWithTranslation from "../../../../../configs/ConstantsWithTranslation";
import NsGridPagination from "../../../../../components/pagination/NsGridPagination";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import NsSkeleton from "../../../../../components/skeletons/NsSkeleton";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import NsSelect from "../../../../../components/dropdowns/NsSelect";
import NsButton from "../../../../../components/buttons/NsButton";
import CommonHelper from "../../../../../utilities/CommonHelper";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import Enumerations from "../../../../../configs/Enumerations";
import NsCard from "../../../../../components/cards/NsCard";
import DateHelper from "../../../../../utilities/DateHelper";
import Constants from "../../../../../configs/Constants";

const ApBasesDfbComments = (props) => {

    const { refreshDate, parameters } = props;
    const [comments, setComments] = useState([{ comment: '...' }]);
    const [selectedStatus, setSelectedStatus] = useState(100);
    const [selectedFeedback, setSelectedFeedback] = useState();
    const [showFeedbackDetailModal, setShowFeedbackDetailModal] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [pageLength, setPageLength] = useState(CommonHelper.defaultRowPerPage());
    const [totalRows, setTotalRows] = useState();
    const [refreshaPaginationDate, setRefreshaPaginationDate] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [resultMessageClassModal, setResultMessageClassModal] = useState();


    useEffect(() => {
        getComments();
    }, [selectedStatus, currentPageIndex, pageLength, refreshaPaginationDate, refreshDate])

    const getComments = () => {
        setIsLoading(true);
        let params = {
            'Filter.UserFeedbackStateType': selectedStatus == 100 ? null : selectedStatus,
            'Filter.FeedbackType': parameters.feedbackType,
            'Filter.SubmitTimeFrom': parameters.submitTimeFrom,
            'Filter.SubmitTimeTo': parameters.submitTimeTo,
            'Filter.UserId': parameters?.userId,
            currentPageIndex,
            pageLength: pageLength.value
        }
        GetApDfbCommentsService(params, getCommentsCallback);
    }

    const getCommentsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setComments(resultData.dataItems);
            setTotalRows(resultData.totalCount);
        } else
            setResultMessageClassModal(messageClassModal);
    }

    const valueChanged = (value) => {
        setSelectedStatus(value);
        setCurrentPageIndex(1);
    }

    const refreshPaginationButtonClicked = () => setRefreshaPaginationDate(new Date());

    const currentPageChanged = (currentPageIndex) => setCurrentPageIndex(currentPageIndex);

    const rowPerPageChanged = (pageLength) => {
        setPageLength(pageLength);
        setCurrentPageIndex(1);
    }

    const showFeedbackClicked = (selectedFeedback) => {
        setSelectedFeedback(selectedFeedback);
        setShowFeedbackDetailModal(true);
    }

    const closeFeedbackDetailModalClicked = () => setShowFeedbackDetailModal(false);

    const hasFeedbackModalAccess = (feedbackType) => {
        switch (feedbackType) {
            case Enumerations.feedbackTypes.announcementContent:
                return hasAccess([UserClaimEnumerations.getApFeedbackAnnouncementBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]);
            case Enumerations.feedbackTypes.sharedSheetOrder:
                return hasAccess([UserClaimEnumerations.getApFeedbackShareSheetOrderBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]);
            case Enumerations.feedbackTypes.user:
                return hasAccess([UserClaimEnumerations.getApFeedbackToEmployeeBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]);
            default: false
        }
    }

    const getFeedbackDtoservice = (feedbackType) => {
        switch (feedbackType) {
            case Enumerations.feedbackTypes.announcementContent:
                return GetFeedbackAnnouncementBasicInfoService;
            case Enumerations.feedbackTypes.sharedSheetOrder:
                return GetFeedbackShareSheetOrderBasicInfoService;
            case Enumerations.feedbackTypes.user:
                return GetFeedbackEmployeeBasicInfoService;
            default: false
        }
    }

    const mapComments = () => {
        return !isEmpty(comments) && comments.map((comment, index) =>
            < div key={index} className="mb-4 border-bottom">
                <div className="truncate-text mb-2 font-size-14">{comment?.comment}</div>

                <div className="d-flex align-items-center gap-2 font-size-12">
                    <span>{comment?.userFullName}</span>
                    <span>{DateHelper.getDateAndTime(comment?.createAt)}</span>
                    {hasFeedbackModalAccess(comment?.feedbackType) && <NsButton label={getTranslatedRawText('adminPanel.feedback.feedback')}
                        className="secondary-text"
                        wrapperClassName='ms-auto'
                        startIcon="ns-icon-view "
                        size='small'
                        onClick={() => showFeedbackClicked(comment)} />
                    }
                </div>
            </div >
        )
    }
    return (!isEmpty(comments) &&
        <NsCard className='admin-panel__feedback__comments position-relative p-3 h-100' contentClassName='h-100'>
            <div className="d-flex flex-column h-100 overflow-auto custom-scrollbar">
                <div className='d-flex justify-content-between page-title mb-3'>
                    <span>{getTranslatedRawText('adminPanel.dashboard.comments')}</span>
                    <div className="d=flex w-25">
                        <NsSelect label={getTranslatedRawText('')}
                            options={ConstantsWithTranslation.getFeedbackstatus(true)}
                            valueChanged={valueChanged}
                            selectedItem={selectedStatus} />
                    </div>
                </div>
                <div className="mb-auto">
                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            className='w-100'
                            variant={[Enumerations.skeleton.text,
                            Enumerations.skeleton.text]} />
                        : mapComments()
                    }
                </div>

                {!isLoading && < div className="position-absolute w-100 bottom-0 end-0">
                    <NsGridPagination currentPage={currentPageIndex}
                        totalRows={totalRows}
                        isHistory={false}
                        rowPerPage={pageLength}
                        rowPerPageOptionsInGrid={Constants.rowPerPageOptionsInGrid}
                        refreshButtonClicked={refreshPaginationButtonClicked}
                        handleRowPerPage={rowPerPageChanged}
                        handlePagination={currentPageChanged} />
                </div>
                }
            </div>
            {showFeedbackDetailModal &&
                <ApBaseFeedbackDetailModal selectedFeedback={{ ...selectedFeedback, userFeedbackId: selectedFeedback.id }}
                    hasAccessInfo={true}
                    getFeedbackDtoService={getFeedbackDtoservice(selectedFeedback.feedbackType)}
                    closeModal={closeFeedbackDetailModalClicked} />
            }
            {resultMessageClassModal}
        </NsCard >
    );
};

export default React.memo(ApBasesDfbComments);