import React from "react";

import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import NsAutocomplete from "../../../components/dropdowns/NsAutoComplete";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import NsCollapse from "../../../components/dropdowns/NsCollapse";
import CommonHelper from "../../../utilities/CommonHelper";
import NsCard from "../../../components/cards/NsCard";

class SidebarOptions extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            printedSide: props.filters.option.printedSide,
            turnaround: props.filters.option.turnaround,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            printedSide: nextProps.filters.option.printedSide,
            turnaround: nextProps.filters.option.turnaround
        });
    }

    optionChanged = (value, name) => {
        if (!value || this.state.printedSide?.label !== value?.label && this.state.turnaround?.label !== value?.label) {
            const optionName = CommonHelper.getVariableName(name);
            this.setState({ [optionName]: value },
                () => {
                    CommonHelper.safeFunctionCall(this.props.optionFilter,
                        {
                            printedSide: this.state.printedSide,
                            turnaround: this.state.turnaround
                        }),
                        CommonHelper.safeFunctionCall(this.props.optionMobileFilter,
                            {
                                printedSide: this.state.printedSide,
                                turnaround: this.state.turnaround
                            })
                });
        }
    };

    render() {
        const { printedSide, turnaround } = this.state;

        return (
            <NsCard className='p-3'>
                <NsCollapse className='mb-2'
                    defaultIsOpen={true}
                    titleClassName='section-title w-100'
                    title={getTranslatedRawText('product.sideBarTitles.options')} >
                    <NsAutocomplete label={getTranslatedRawText('product.detail.printedSide')}
                        items={ConstantsWithTranslation.getPrintedSide()}
                        onChange={value => this.optionChanged(value, { printedSide })}
                        value={printedSide}
                        textFieldClassName='m-0' />
                    <NsAutocomplete label={getTranslatedRawText('product.detail.turnaround')}
                        items={ConstantsWithTranslation.getTurnarounds()}
                        onChange={value => this.optionChanged(value, { turnaround })}
                        value={turnaround}
                        textFieldClassName='m-0'
                        className='mt-3' />
                </NsCollapse>
            </NsCard>
        );
    }
}

export default SidebarOptions;