import clsx from "clsx";
import React from "react";

import NsButton from "../../../components/buttons/NsButton";
import { hasAccess } from "../../../utilities/ClaimHelper";
import CommonHelper from "../../../utilities/CommonHelper";
import { isEmpty } from "../../../utilities/ValidationHelper";

const ApBaseMapActionButtons = (props) => {
    const { actionButtons, isLoading, className } = props;

    const buttons = isEmpty(actionButtons)
        ? null
        : CommonHelper.clearArrayFromEmptyItem(actionButtons).map((button, index) =>
            hasAccess(button?.accessIds)
            && <NsButton key={index}
                label={button.label}
                startIcon={button.startIcon}
                className='secondary-outline'
                size='small'
                disabled={button.disabled}
                loading={isLoading}
                wrapperClassName={button.wrapperClassName}
                onClick={button.onClick} />
        )

    return !isEmpty(CommonHelper.clearArrayFromEmptyItem(buttons)) && <div className={clsx('d-flex flex-wrap gap-2 mb-2 background-color p-3', className)}>
        {buttons}
        {props.children}
    </div>
}

export default React.memo(ApBaseMapActionButtons);