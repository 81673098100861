import CommonHelper from '../../utilities/CommonHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';

const setCurrentUser = 'SET_CURRENT_USER';
const editCurrentUser = 'EDIT_CURRENT_USER';
const removeCurrentUser = 'REMOVE_CURRENT_USER';
const setCurrentUserInChatService = 'SET_CURRENT_USER_IN_CHAT_SERVICE';

const initialState = {
    userFullName: !!CurrentUserHelper.getToken() ? CurrentUserHelper.getFullName() : null,
    isSetUserInChatService: false,
};

export const currentUserActionCreators = {

    setCurrentUser: (data) => async (dispatch, getState) => {
        dispatch({ type: setCurrentUser, data });
    },

    editCurrentUser: (data) => async (dispatch, getState) => {
        dispatch({ type: editCurrentUser, data });
    },

    removeCurrentUser: () => async (dispatch, getState) => {
        dispatch({ type: removeCurrentUser });
    },

    setCurrentUserInChatService: () => async (dispatch, getState) => {
        dispatch({ type: setCurrentUserInChatService, });
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case setCurrentUser: {
            CurrentUserHelper.create(
                action.data.userInfo?.expirationDate,
                action.data.userInfo?.firstName,
                action.data.userInfo?.lastName,
                action.data.userInfo?.mobile,
                action.data.userInfo?.genterTypeId,
                action.data.userInfo?.token,
                action.data?.persistentMobile,
                action.data?.isPersistent,
                action.data.userInfo?.isAdmin,
                action.data.userInfo?.hasAutoDeliveryAccess,
                action.data.userInfo?.defaultCurrencyTypeId,
                action.data.userInfo?.countryId,
                action.data.userInfo?.cityName,
                action.data.userInfo?.stateName,
                action.data.userInfo?.businessPartnerCustomerIdentifier,
                action.data.userInfo?.isOfficialInvoiceEnabled,
                action.data.userInfo?.isCompany,
            );
        }

            return {
                ...state,
                userFullName: CurrentUserHelper.getFullName(),
            };

        case editCurrentUser:
            const { firstName, lastName } = action.data;
            CurrentUserHelper.set(CommonHelper.getVariableName({ firstName }), firstName);
            CurrentUserHelper.set(CommonHelper.getVariableName({ lastName }), lastName);
            return {
                ...state,
                userFullName: CurrentUserHelper.getFullName(),
            };

        case removeCurrentUser:
            CurrentUserHelper.remove();
            return {
                ...state,
                userFullName: '',
            };

        case setCurrentUserInChatService:
            return {
                ...state,
                isSetUserInChatService: true
            }

        default:
            return state;
    }

};

