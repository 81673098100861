import clsx from 'clsx';
import React from 'react';

import NsCircularProgressWithLabel from "../../../../../components/circularProgress/NsCircularProgressWithLabel";
import { getTranslatedRawText, isEnCulture } from "../../../../../utilities/CultureHelper";
import NsSkeleton from "../../../../../components/skeletons/NsSkeleton";
import Enumerations from "../../../../../configs/Enumerations";
import NsCard from "../../../../../components/cards/NsCard";
import { isEmpty } from "../../../../../utilities/ValidationHelper";

const ApBasesDfbStatusGadget = (props) => {
    const { data, isLoading } = props;

    return (
        <NsCard>
            <div className='d-flex justify-content-around px-1 py-3 gap-1'>
                <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-between mb-auto gap-3'>
                        <span >{data.title}</span>
                        <span className='page-title'>{isLoading ? <NsSkeleton isVisible={true} variant={[Enumerations.skeleton.text]} /> : data?.count}</span>
                    </div>

                    <div className={clsx('d-flex justify-content-between font-size-12', isEnCulture() ? 'gap-5' : 'gap-4')}>
                        {data?.minimumRate && <span className='mb-1'>{getTranslatedRawText('adminPanel.dashboard.min')}</span>}
                        {data?.averageRate && <span className='mb-1'>{getTranslatedRawText('adminPanel.dashboard.avg')}</span>}
                        {data?.maximumRate && <span className='mb-1'>{getTranslatedRawText('adminPanel.dashboard.max')}</span>}
                    </div>
                    <div className='d-flex justify-content-between font-size-14'>
                        {isLoading
                            ? <span className='w-100'> <NsSkeleton isVisible={true} variant={[Enumerations.skeleton.text]} /> </span>
                            : <>
                                {data?.minimumRate && <span >{data.minimumRate}</span>}
                                {data?.averageRate && <span >{data.averageRate}</span>}
                                {data?.maximumRate && <span >{data.maximumRate}</span>}
                            </>
                        }
                    </div>
                </div>
                {data.userFeedbackStateTypeId !== Enumerations.feedbackStatus.none && !isEmpty(data.userFeedbackStateTypeId) &&
                    <div>
                        <NsCircularProgressWithLabel value={data?.percent}
                            isLoading={isLoading}
                            thickness={5}
                            progressSize='admin-panel__audit-dashboard--circular-progress-feedback-size'
                            progressColor={data?.progressColor || 'success-color'} />
                    </div>
                }
            </div>

        </NsCard >
    );

}

export default React.memo(ApBasesDfbStatusGadget);