import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import { getConfigObjectByCulture } from '../../../../../utilities/ConfigHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsTypography from '../../../../../components/text/NsTypography';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsImage from '../../../../../components/images/NsImage';
import DateHelper from '../../../../../utilities/DateHelper';
import Config from '../../../../../configs/Config';
import NsCountDownTimerLabel from '../../../../../components/labels/NsCountDownTimerLabel';

export default class UpDashboardCashbackReminderCard extends Component {
    constructor(props) {
        super(props);
        this.prizeImage = getConfigObjectByCulture(Config.images)?.cashback[0];
    }

    render() {
        const { cashbackForecast, currentCashbackInfo, cashbackPlans } = this.props;

        return (
            <div className='w-100 pt-4'>
                {cashbackPlans?.length > 1 && <><NsTypography variant='inherit'
                    className='fw-bold font-size-14 text-color mb-2 ps-2 '>{getTranslatedRawText('profile.cashback.toTheNextPlan')}</NsTypography>
                    <Row className='wrapper-red text-white px-2 py-3 gap-2 mx-1'>
                        <Col md={8} sm={8} xs={8}>
                            <div className='mb-2'>
                                <NsTypography variant='inherit' className='font-size-12 mb-1'>{getTranslatedRawText('profile.cashback.remainingPurchase')}</NsTypography>
                                <NsTypography variant='inherit' className='fw-bold font-size-18'>{CommonHelper.currencyFormatByDefaultConfig(cashbackForecast?.remaindToNextDiscount)}</NsTypography>
                            </div>
                            <div>
                                <NsTypography variant='inherit' className='font-size-12 mb-1'>{getTranslatedRawText('profile.cashback.totalCashback')}</NsTypography>
                                <NsTypography variant='inherit' className='fw-bold font-size-18'>{CommonHelper.currencyFormatByDefaultConfig(cashbackForecast?.nextDiscountAmount)}</NsTypography>
                            </div>
                        </Col>
                        <Col md={3} sm={3} xs={3} className='d-flex align-items-start justify-content-start'>
                            <NsImage className='cashback-prize-image  d-flex justify-content-end' src={this.prizeImage.src} />
                        </Col>
                    </Row>
                    <div className="label-line-line m-3"></div>
                </>}
                <Row className='d-flex align-content-center m-0'>
                    {currentCashbackInfo &&
                        <NsCountDownTimerLabel currentCashbackInfo={currentCashbackInfo} />
                    }
                    <NsTypography variant='inherit'
                        className='d-flex mt-2 justify-content-center'>
                        {DateHelper.getDatePart(currentCashbackInfo?.firstDay)}
                        &nbsp;<span>{getTranslatedRawText('components.history.to')}</span>&nbsp;
                        {DateHelper.getDatePart(currentCashbackInfo?.lastDay)}
                    </NsTypography>
                </Row>
            </div>
        )
    }
}
