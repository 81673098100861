import React from "react";
import { Col } from "react-bootstrap";

import { GetShippingPlanAvailabilityService } from "../../../../../services/ProfileSetting/DeliveryAddressSettingService";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import NsSelect from "../../../../../components/dropdowns/NsSelect";
import CommonHelper from "../../../../../utilities/CommonHelper";
import Enumerations from "../../../../../configs/Enumerations";
import NsTypography from "../../../../../components/text/NsTypography";
import NsHtmlToReact from "../../../../../components/htmlToReact/NsHtmlToReact";
import UpShippingMethodInformationModal from "../modals/UpShippingMethodInformationModal";
import NsSkeleton from "../../../../../components/skeletons/NsSkeleton";

class UpShippingRequestHeaderPlanAvailabilityOrMethod extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shippingPlanAvailability: null,
            expressShippingPlanAvailability: null,
            showMethodInformationModal: false,
            isLoading: false,
            showShippingMethodInformationModal: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.selectedShippingPlan?.key !== this.props.selectedShippingPlan?.key ||
            prevProps.selectedAddress?.id !== this.props.selectedAddress?.id) {
            this.getShippingPlanAvailability();
        }
    }

    getShippingPlanAvailability = () => {
        this.setState({ isLoading: true });
        const isStandardTab = this.props.selectedShippingPlan?.key === Enumerations.shippingPlanType.standard;
        GetShippingPlanAvailabilityService({ shippingPlanTypeId: this.props.selectedShippingPlan?.key, orderProductTypeId: this.props.orderProductType }, this.getShippingPlanAvailabilityCallback(false));
        isStandardTab &&
            GetShippingPlanAvailabilityService({ shippingPlanTypeId: Enumerations.shippingPlanType.express, orderProductTypeId: this.props.orderProductType }, this.getShippingPlanAvailabilityCallback(isStandardTab));
    }

    getShippingPlanAvailabilityCallback = (isStandardTab) => (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            isStandardTab
                ? this.setState({ expressShippingPlanAvailability: resultData }, () => this.createExpressShippingAvailableMessage(isStandardTab))
                : this.setState({ shippingPlanAvailability: resultData }, () => {
                    this.createExpressShippingAvailableMessage(false);
                    CommonHelper.safeFunctionCall(this.props.getIsAvailabel, this.state.shippingPlanAvailability?.isAvailable);
                });
        }
        else this.setState({ resultMessageClass: messageClassModal });
    }

    createExpressShippingAvailableMessage = (isStandardTab) =>
        CommonHelper.safeFunctionCall(this.props.createExpressShippingAvailableMessage, isStandardTab,
            this.props.selectedShippingPlan?.key === Enumerations.shippingPlanType.express,
            this.state.shippingPlanAvailability?.isAvailable, this.state.expressShippingPlanAvailability?.isAvailable);

    toggleShippingMethodInformationModal = () => this.setState({ showShippingMethodInformationModal: !this.state.showShippingMethodInformationModal });

    render() {
        const { shippingPlanAvailability, isLoading, showShippingMethodInformationModal, resultMessageClass } = this.state;
        const { selectedAddress, selectedShippingPlan, selectedShippingMethod, shippingMethods, availableMethodChanged } = this.props;

        return <>
            {isLoading
                ? <NsSkeleton className='ms-2 w-100'
                    isVisible={true}
                    variant={[Enumerations.skeleton.text, Enumerations.skeleton.rect]} />
                : shippingPlanAvailability?.isAvailable
                    ? <>
                        <Col md={4} sm={12}>
                            <NsSelect label={getTranslatedRawText("profile.delivery.shippingMethods")}
                                valueChanged={availableMethodChanged}
                                selectedItem={selectedShippingMethod?.id}
                                options={shippingMethods}
                                disabled={(!selectedShippingPlan?.key) || (shippingMethods.length < 1)} />
                            {selectedShippingPlan?.key === Enumerations.shippingPlanType.standard && selectedShippingMethod?.id &&
                                <ShippingMethodDescription showShippingMethodInformationModal={showShippingMethodInformationModal}
                                    toggleShippingMethodInformationModal={this.toggleShippingMethodInformationModal}
                                    selectedShippingMethod={selectedShippingMethod}
                                    selectedShippingPlan={selectedShippingPlan} />
                            }
                        </Col>
                        <Col md={6} sm={12}>
                            <ShippingMethodHint shippingMethods={shippingMethods}
                                shippingMethodCategoryTypeId={selectedShippingMethod?.shippingMethodCategoryTypeId}
                                selectedAddress={selectedAddress} />
                        </Col>
                    </>
                    : isEmpty(shippingPlanAvailability?.errorMessages)
                        ? ''
                        : shippingPlanAvailability?.errorMessages.map((message, index) =>
                            <div key={index} className="d-flex align-items-start gap-1">
                                <i className='ns-icon-information mt-1' />
                                {message}
                            </div>
                        )}
            {resultMessageClass}
        </>
    }
}

function ShippingMethodHint({ shippingMethods, shippingMethodCategoryTypeId, selectedAddress }) {

    if (isEmpty(selectedAddress?.id))
        return <></>;

    if (isEmpty(shippingMethods)) {
        return (<NsTypography variant='inherit' className='font-size-12 mt-4'>
            <NsHtmlToReact>
                {CommonHelper.stringFormat(getTranslatedRawText('profile.delivery.informationForWhenShippingMethodNotAvailable')
                    , `<span className='fw-bold'>${selectedAddress?.cityName}</span>`)}
            </NsHtmlToReact>
        </NsTypography>
        );
    }

    if (shippingMethodCategoryTypeId == Enumerations.shippingMethodCategoryType.doorToDoor)
        return (<div className='mt-md-4'>
            <NsTypography variant='inherit' className='font-size-12 d-flex align-items-center'>
                <i className='ns-icon-information text-color font-size-18 me-1' />
                {getTranslatedRawText('profile.delivery.bulkyOrdersHint')}
            </NsTypography>
        </div>);
}

function ShippingMethodDescription({ showShippingMethodInformationModal, selectedShippingMethod, selectedShippingPlan, toggleShippingMethodInformationModal }) {

    return (
        <>
            <NsTypography variant='inherit' className='font-size-12 pt-3'>
                <div onClick={toggleShippingMethodInformationModal}>
                    <NsHtmlToReact>
                        {CommonHelper.stringFormat(getTranslatedRawText('profile.delivery.about'),
                            `<span className='fw-bold cursor-pointer text-decoration-underline'>${selectedShippingMethod?.name}</span>`)}
                    </NsHtmlToReact>
                </div>
            </NsTypography >

            {showShippingMethodInformationModal &&
                <UpShippingMethodInformationModal selectedShippingMethod={selectedShippingMethod}
                    selectedShippingPlan={selectedShippingPlan}
                    closeModal={toggleShippingMethodInformationModal} />}
        </>
    );
}

export default UpShippingRequestHeaderPlanAvailabilityOrMethod;