import React, { useEffect, useState } from 'react';

import { GetDownloadCategoryDropDownService, } from '../../../../../services/adminPanel/AdminDownloadService';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../../bases/ApBaseSearchButtons';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';

const ApDownloadSearch = (props) => {

    const [fileTitle, setFileTitle] = useState(null);
    const [category, setCategory] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [createTimeFrom, setCreateTimeFrom] = useState(null);
    const [createTimeTo, setCreateTimeTo] = useState(null);
    const [status, setStatus] = useState(null);
    const [isShowMore, setIsShowMore] = useState(false);

    useEffect(() => {
        GetDownloadCategoryDropDownService(getDownloadCategoryDropDownCallback);
    }, []);

    const getDownloadCategoryDropDownCallback = (resultData, result, resultMessageClass) => {
        if (!result.data.hasError)
            setCategoryList(resultData);
    }

    const basicSearchFields = [
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('adminPanel.download.fileTitle'),
            fieldName: 'fileTitle',
            value: fileTitle,
            onChange: (value) => setFileTitle(value),
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.download.category'),
            fieldName: 'category',
            value: category,
            onChange: (value) => setCategory(value),
            items: categoryList,
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.download.createTimeFrom'),
            fieldName: 'createTimeFrom',
            value: createTimeFrom,
            onChange: (value) => setCreateTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.download.createTimeTo'),
            fieldName: 'createTimeTo',
            value: createTimeTo,
            onChange: (value) => setCreateTimeTo(value)
        },
    ]

    const advanceSearchFields = [
        ...basicSearchFields,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.download.status'),
            fieldName: 'status',
            value: status,
            onChange: (value) => setStatus(value),
            items: ConstantsWithTranslation.getActivation(),
        },
    ]

    const searchValues = {
        fileTitle,
        category,
        createTimeFrom,
        createTimeTo,
        status
    }

    const searchClicked = () => {
        let data = {
            fileTitle,
            category: category?.key,
            createTimeFrom,
            createTimeTo,
            status,
        }
        CommonHelper.safeFunctionCall(props.searchClicked, data);
    }
    const clearButtonClicked = () => {
        setFileTitle(null);
        setCategory(null);
        setCreateTimeFrom(null);
        setCreateTimeTo(null);
        setStatus(null);
        CommonHelper.safeFunctionCall(props.searchClicked, {});
    }

    const showMoreHandler = () => setIsShowMore(!isShowMore);

    return (
        <>
            <NsAdvanceSearch searchOptions={isShowMore
                ? advanceSearchFields
                : CommonHelper.isMobileDevice() ? [basicSearchFields[0]] : basicSearchFields}
                searchValues={searchValues} mdCol={3} smCol={6} />
            <ApBaseSearchButtons searchClicked={searchClicked}
                clearButtonClicked={clearButtonClicked}
                isLoading={props.isLoading}
                showMore={isShowMore}
                showMoreHandler={showMoreHandler} />
        </>
    )
}

export default React.memo(ApDownloadSearch);