import parse from 'html-react-parser';
import { Component } from 'react';

import { isEmpty } from '../../utilities/ValidationHelper';

class NsHtmlToReact extends Component {
    render() {
        return (
            !isEmpty(this.props.children)
                ? <div className='mw-100'>{parse(this.props.children)}</div>
                : null
        )
    }
}

export default NsHtmlToReact;