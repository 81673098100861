import React from "react";
import { Col } from "react-bootstrap";

import NsImage from "../../../../components/images/NsImage";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";

class DownloadItems extends React.Component {

    render() {
        const { isShowInCard, downloadDetails } = this.props;

        return <>
            {!isEmpty(downloadDetails) && downloadDetails.map((item, index) => <Col md={isShowInCard ? 12 : 6} key={index} >
                <div className="background-color border rounded d-flex align-items-center gap-2 px-1">
                    {item.fileUrl
                        && <NsImage wrapperClassName='w-auto'
                            className={item.iconCssClassName}
                            width='50px'
                            src={CommonHelper.createImageUrl(item.fileUrl)}
                            imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.contentImage"), item.title)} />
                    }
                    <div className="d-flex flex-column w-100">
                        <div className="font-size-12 d-flex w-100">
                            {item.title}
                            <span className="font-size-10 red-color mx-1">{item.version}</span>
                        </div>
                        {item.volume > 0 && <div className="font-size-10">{CommonHelper.convertByteToMegaByte(item.volume) + getTranslatedRawText('common.mb')}</div>}
                    </div>
                    <a
                        className="text-decoration-none font-size-12 red-color"
                        href={CommonHelper.createImageUrl(item?.downloadFileUrl)}
                        rel="noopener noreferrer"
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(CommonHelper.createImageUrl(item?.downloadFileUrl), '_blank');
                        }}
                    >
                        <i className='ns-icon-download' />
                        {getTranslatedRawText('common.download')}
                    </a>
                </div>
            </Col >
            )}
        </>
    }
}

export default DownloadItems;