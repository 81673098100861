const AccountsTranslationEn = {

    account: {

        common: {
            home: 'Back to home page',
            login: 'Sign in',
            signup: 'Sign up',
            mobile: 'Mobile',
        },

        titles: {
            signupActivation: 'Signup | Activation',
            signupAccountInformation: 'Signup | Account Information',
            signupWelcome: 'Signup | Welcome',

            signInForgotPassword: 'SignIn | Forgot Password',
            signInChangePassword: 'SignIn | Change Password',
            signInSuccessChangePassword: 'SignIn | Success Change Password',
        },

        signup: {
            signupMobileTitle: 'Verify your mobile',
            signupMobileDescription: 'For your security, we want to make sure it\'s really you. We will send a text message with a 6-digit verification code.',
            sendVerificationCode: 'send Verification Code',
            signupVerificationTitle: 'Verify your mobile',
            signupVerificationDescription: 'We have send a 6-digit verification code to your mobile number. enter the code and verify your mobile number.',
            verifyMobile: 'Verify mobile',
            resendCode: 'Resend Code',
            createAccountTitle: 'Create your account',
            createMyAccount: 'Create My Account',
            firstName: 'First Name',
            state: 'State',
            city: 'City',
            firstNameEnter: 'Enter first name',
            lastName: 'Last Name',
            lastNameEnter: 'Enter last name',
            nationalCode: 'License',
            nationalCodeEnter: 'Enter ID number',
            birthDate: 'Date of birth',
            birthDateEnter: 'Enter birth date',
            companyName: 'Company Name',
            companyNameEnter: 'Enter company name',
            license: 'License Number',
            iranLicense: 'National Id',
            licenseEnter: 'Enter license',
            licenseNumber: 'licenceNumber',
            trn: 'TRN',
            trnEnter: 'Enter TRN',
            signupWelcomeTitle: 'Welcome to {0}',
            dear: 'Dear ',
            signupWelcomeDescription: 'You have successfully created your account. sign in to your account and submit your printing orders.',
            iHaveRead: 'I have read',
            and: 'and',
            agreeToThem: 'and agree to it.',
            privacyPolicy: 'Privacy Policy',
            termsOfUse: 'Terms of Use'
        },

        signIn: {
            rememberMe: 'Remember me',
            forgotPassword: 'Forgot password',
            doYouHaveAccount: 'Do you have account?',
            signupNow: 'Sign up now.',
        },

        forgotPassword: {
            forgotPasswordMobileTitle: 'Forgot password?',
            forgotPasswordMobileDescription: 'For your security, we want to make sure it\'s really you. We will send a text message with a 6-digit verification code.',
            sendVerificationCode: 'send Verification Code',
            forgotPasswordChangeTitle: 'Verify mobile and change your password',
            forgotPasswordChangeDescription: 'We have send a 6-digit verification code to your mobile number. enter the code and change your password.',
            changePassword: 'Change password',
            resendCode: 'Resend Code',
            forgotPasswordSuccessTitle: 'Password changed',
            forgotPasswordSuccessDescription: 'Your password successfully changed. Now you can sign in with your new password.',
        }

    },

};
export default AccountsTranslationEn;