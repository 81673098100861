import React, { useEffect, useState } from "react";

import ApOrderProductDetailModal from "../../managements/orders/shareSheets/orderDetailPreview/ApOrderProductDetailModal";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import { AddApProductContentService, EditApProductContentService, GetApProductContentByNameService } from "../../../../services/adminPanel/AdminProductContentService";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsStickyButton from "../../../../components/buttons/NsStickyButton";
import ApBaseMapActionButtons from "../../bases/ApBaseMapActionButtons";
import NsTextField from "../../../../components/inputs/NsTextField";
import NsLabelBox from "../../../../components/labels/NsLabelBox";
import NsRating from "../../../../components/ratings/NsRating";
import NsSlider from "../../../../components/sliders/NsSlider";
import NsEditor from "../../../../components/editor/NsEditor";
import CommonHelper from "../../../../utilities/CommonHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsModal from "../../../../components/modals/NsModal";
import NsTab from "../../../../components/tabs/NsTab";
import { hasAccess } from "../../../../utilities/ClaimHelper";

const ApProductContentDetailModal = (props) => {

    const { selectedProductContent } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [productContent, setProductContent] = useState({});
    const [showProductDetailModal, setShowProductDetailModal] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [messageClassModal, setMessageClassModal] = useState(<></>)

    useEffect(() => {
        GetApProductContentByNameService({ pprName: selectedProductContent }, getApProductContentByNameCallback);
    }, [])

    const getApProductContentByNameCallback = (resultData, result, messageClassModal) => {
        if (result?.data?.hasError) setMessageClassModal(messageClassModal);
        else setProductContent(resultData);
        setIsLoading(false);
    }

    const tabChanged = (tabIndex) => setSelectedTabIndex(tabIndex);

    const toggleProductDetailModal = () => setShowProductDetailModal(!showProductDetailModal);

    const closeModalClicked = () => CommonHelper.safeFunctionCall(props.closeModal);

    const stateHandler = (value, valueName) => {
        let name = CommonHelper.getVariableName(valueName);
        setProductContent(oldState => ({ ...oldState, [name]: value }))
    }

    const saveButtonClicked = () => {
        setIsLoading(true);
        !!productContent?.id
            ? EditApProductContentService(productContent?.id, { ...productContent }, productContentCallback)
            : AddApProductContentService({ ...productContent }, productContentCallback);
    };

    const productContentCallback = (resultData, result, messageClassModal) => {
        if (result?.data?.hasError)
            setMessageClassModal(messageClassModal);
        else
            closeModalClicked();
        setIsLoading(false);
    }

    const mainButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary', accessIds: !!productContent?.id ? [UserClaimEnumerations.editApPprContentById] : [UserClaimEnumerations.postApPprContent] },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(false), className: 'primary-outline' },
    ];

    const headerButtons = [
        hasAccess([UserClaimEnumerations.getApOrderProductDetail]) &&
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-product-information', label: 'adminPanel.orders.productDetail', onClick: toggleProductDetailModal },
    ];

    const { productName, extendedName, ratingValue, metaKeywords, tags, overview, metaDescription, faqs } = productContent;

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText('adminPanel.productContent.productContentDetail')}
            showModal={true}
            noSpace={true}
            closeModal={closeModalClicked}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>

            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />

            <div className='background-color px-3 py-2'>
                <NsLabelBox className='background-white-color min-size col-md-6'
                    label={getTranslatedRawText('adminPanel.dataGridColumn.productName')}
                    value={productName} />
            </div>

            <div className='d-flex flex-column py-2 px-4'>
                <div className=' d-flex gap-3 align-items-end'>
                    <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.extendedName')}
                        valueChanged={(value) => stateHandler(value, { extendedName })}
                        value={extendedName}
                        className='w-100 mb-2' />
                    <div className="d-flex gap-4 w-50">
                        <NsSlider value={ratingValue || 0}
                            isSingleValue={true}
                            onChanged={(value) => stateHandler(value, { ratingValue })}
                            max={5}
                            valueDisplay='on'
                            step={0.1} />
                        <NsRating precision={0.1}
                            className='admin-panel__rating'
                            readOnly
                            value={ratingValue || 0} />
                    </div>
                </div>

                <NsTextField label={getTranslatedRawText('adminPanel.productContent.metaKeyword')}
                    valueChanged={(value) => stateHandler(value, { metaKeywords })}
                    value={metaKeywords}
                    className='w-100 mt-1' />
                <div className="font-size-12 pt-2">
                    {getTranslatedRawText('adminPanel.productContent.metaKeywordInfo')}
                </div>

                <NsTextField label={getTranslatedRawText('adminPanel.productContent.metaDescription')}
                    valueChanged={(value) => stateHandler(value, { metaDescription })}
                    value={metaDescription}
                    className='w-100 mt-1' />

                <NsTextField label={getTranslatedRawText('adminPanel.post.tags')}
                    valueChanged={(value) => stateHandler(value, { tags })}
                    value={tags}
                    className='w-100 mt-1' />
                <div className="font-size-12 pt-2">
                    {getTranslatedRawText('adminPanel.post.tagsInfo')}
                </div>

                <div className='mt-3'>
                    <NsTab tabs={ConstantsWithTranslation.getAdminProductContentDetailTabs()}
                        value={selectedTabIndex}
                        onChange={tabChanged} />
                </div>
                <div>
                    <NsEditor editorMode={Enumerations.editorMode.advance}
                        initialValue={!!selectedTabIndex ? overview : faqs || null}
                        changeHandler={(value) => stateHandler(value, !!selectedTabIndex ? { overview } : { faqs })} />
                </div>ّ
            </div>

            {showProductDetailModal &&
                <ApOrderProductDetailModal showModal={true}
                    isUseInProductContentModal={true}
                    selectedOrder={selectedProductContent}
                    closeModal={toggleProductDetailModal} />
            }
            {messageClassModal}
        </NsModal >
    )
}

export default React.memo(ApProductContentDetailModal);