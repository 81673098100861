import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';

import { getCurrentCulture, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import { currentUserActionCreators } from '../../../../redux/reducers/CurrentUserReducer';
import { miniCartActionCreators } from '../../../../redux/reducers/MiniCartReducer';
import { categoryActionCreators } from '../../../../redux/reducers/CategoryReducer';
import '../../../../assets/sass/views/layout/main/headers/Headers.scss';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import Links from '../../../../configs/links/AllLinks';
import Config from '../../../../configs/Config';
import MiddleHeader from './MiddleHeader';
import BottomHeader from './BottomHeader';
import TopHeader from './TopHeader';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.isInHome = window.location.pathname === `/${getCurrentCulture()}/`;
        this.state = {
            showModal: null,
            linkItems: this.createLinkItems(props.currentUser),
            resultMessageClass: (<></>),
        };
    }

    createLinkItems = (currentUser) => {
        let hasAccessToNewStoreProducts = Config.hasStoreProducts === true;

        return [
            { link: getUrlWithCurrentCulture(Links, Links.home), text: 'headers.bottom.home', showItem: true, tourName: '' },
            { link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts), text: 'headers.bottom.shop', showItem: true, tourName: 'header-navbar-shop' },            
            Config.showShoppingBag && { link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBag), text: 'headers.bottom.paperBag', showItem: true, tourName: '' },
            !!currentUser && { link: getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.productDetailsWithoutId), text: 'headers.bottom.iOrderFrequently', showItem: true, tourName: 'header-navbar-iOrderFrequently' },
            Config.hasStoreProducts && {
                link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.allStoreProducts)
                , text: 'headers.common.headerStoreProduct', showItem: true, isExternalLink: !hasAccessToNewStoreProducts, tourName: ''
            },
            !!currentUser && { link: getUrlWithCurrentCulture(Links.view.main, Links.view.main.fileManager), text: 'headers.bottom.fileManager', showItem: true, tourName: '' },
            !!currentUser && { link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequest), text: 'headers.bottom.deliveryRequest', showItem: true, tourName: '' },
            !!currentUser && { link: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder), text: 'profile.actionButton.myOrder', showItem: true, tourName: '' },
            CurrentUserHelper.getIsAdmin() && { link: getUrlWithCurrentCulture(Links, Links.admin), text: 'headers.bottom.admin', showItem: true, tourName: '' },
            !currentUser && { link: getUrlWithCurrentCulture(Links, Links.festivals), text: 'headers.bottom.festivals', showItem: true, tourName: '' },
            !currentUser && { link: getUrlWithCurrentCulture(Links, Links.aboutUs), text: 'headers.bottom.aboutUs', showItem: true, tourName: '' },
        ];
    }

    componentDidMount = () => {
        isEmpty(this.props.categories) && this.props.getCategories();
        isEmpty(this.props.storeCategories) && this.props.getStoreCategories();
        !!this.props.currentUser && this.props.getTotalPriceAndCount();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.currentUser !== this.props.currentUser) {
            this.setState({ linkItems: this.createLinkItems(this.props.currentUser) });
        }
    }

    render() {
        return (
            <>
                <div className='background-black-color'>
                    <Container>
                        <TopHeader username={this.props.currentUser} />
                    </Container>
                </div>
                <div className='background-black-color middle-header'>
                    <Container>
                        <MiddleHeader {...this.props}
                            username={this.props.currentUser}
                            miniCartInfo={this.props.miniCartInfo}
                            categories={this.props.categories}
                            linkItems={this.state.linkItems} />
                    </Container>
                </div>
                <div className='background-black-color bottom-header' aria-label='navbar'>
                    <Container>
                        <BottomHeader username={this.props.currentUser}
                            categories={this.props.categories}
                            isInHome={this.isInHome}
                            linkItems={this.state.linkItems} />
                    </Container>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.userFullName,
        categories: state.categories.categories,
        storeCategories: state.categories.storeCategories,
        miniCartInfo: state.miniCartInfo.miniCartInfo,
    };
}

const mapDispachToProps = (dispatch) => {
    return bindActionCreators({ ...currentUserActionCreators, ...categoryActionCreators, ...miniCartActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(Header);