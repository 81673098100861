import ConstantsWithTranslation from "../configs/ConstantsWithTranslation";
import Enumerations from "../configs/Enumerations";
import UserClaimEnumerations from "../configs/UserClaimEnumerations";
import { isBusinessPartnerRoute } from "./BusinessPartnerHelper";
import { getTranslatedRawText } from "./CultureHelper";
import StorageHelper from "./StorageHelper";
import { isEmpty } from "./ValidationHelper";

export const encodeJwtToken = () => {
    if (isBusinessPartnerRoute()) return;
    let token = StorageHelper.getDecFromStorage(Enumerations.storage.currentUserInfo)?.token;
    if (!isEmpty(token)) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    return null;
}

export const getUserRole = (role) => {
    switch (role) {
        case Enumerations.role.admin:
            return getTranslatedRawText('adminPanel.usersInfo.admin');
        case Enumerations.role.user:
            return getTranslatedRawText('adminPanel.usersInfo.user');
        case Enumerations.role.subUser:
            return getTranslatedRawText('adminPanel.usersInfo.subUser');
        case Enumerations.role.system:
            return getTranslatedRawText('adminPanel.usersInfo.system');
        case Enumerations.role.supporter:
            return getTranslatedRawText('adminPanel.usersInfo.supporter');
        case Enumerations.role.nas:
            return getTranslatedRawText('adminPanel.usersInfo.nas');
        case Enumerations.role.superAdmin:
            return getTranslatedRawText('adminPanel.usersInfo.superAdmin');
        case Enumerations.role.agency:
            return getTranslatedRawText('adminPanel.usersInfo.agency');
        case Enumerations.role.businessPartner:
            return getTranslatedRawText('adminPanel.usersInfo.businessPartner');
        default:
            return '';
    }
}

export const isBusinessPartner = () => {
    let roles = encodeJwtToken()?.role;
    if (typeof roles === "object")
        return roles.some(role => role == Enumerations.role.businessPartner);
    else
        return roles == Enumerations.role.businessPartner
}

export const isAgency = () => {
    let roles = encodeJwtToken()?.role;
    if (typeof roles === "object")
        return roles.some(role => role == Enumerations.role.agency);
    else
        return roles == Enumerations.role.agency
}

export const isSuperAdmin = () => {
    let roles = encodeJwtToken()?.role;
    if (typeof roles === "object")
        return roles.some(role => role == Enumerations.role.superAdmin);
    else
        return roles == Enumerations.role.superAdmin
}

export const isAdmin = () => {
    let roles = encodeJwtToken()?.role;
    if (typeof roles === "object")
        return roles?.some(role => role == Enumerations.role.admin);
    else
        return roles == Enumerations.role.admin
}

export const isAdminOrSuperAdmin = () => {
    return isAdmin() || isSuperAdmin();
}

export const getRoles = () => {
    return isSuperAdmin()
        ? ConstantsWithTranslation.getAdminsRole() :
        isAdmin()
            ? [ConstantsWithTranslation.getAdminsRole()[1]]
            : [];
}

export const normalizeUserClaimTypes = (claim) => {

    const handler = (item) => {
        if (isEmpty(item.controllers || item.actions)) {
            return {
                // name: item?.actionName + ' (' + item?.routePattern + ')',
                name: getTranslatedRawText(resourceClaimNameById(item?.id)) + ' (' + item?.routePattern + ')',
                id: item?.id || Math.floor((Math.random() * 200000) + 1),
                checked: item.hasAccess,
                show: true
            }
        } else {
            let obj = {
                name: !!item.areaName
                    ? getTranslatedRawText(`adminPanelClaim.${item.areaName}`)
                    : getTranslatedRawText(`adminPanelClaim.${item.controllerName}`),
                id: item?.id || Math.floor((Math.random() * 200000) + 1),
                show: true,
            }
            if (!isEmpty(item.controllers))
                obj.children = item.controllers.map(item => handler(item))
            if (!isEmpty(item.actions))
                obj.children = item.actions.map(item => handler(item))
            return obj
        }
    }

    return claim?.map(item => handler(item))

}

export const searchClaim = (searchValue, claimTypes) => {

    const filteredChild = (children, searchValue) => children.map(child => {
        child.show = !searchValue.trim() ? true : child.name.includes(searchValue);
        if (!isEmpty(child?.children) && !!child.show) {
            let newChilds = filteredChild(child.children, '');
            child.children = newChilds;
        } else
            if (!isEmpty(child?.children)) {
                let newChilds = filteredChild(child.children, searchValue);
                if (!isEmpty(newChilds) && newChilds.some(item => item.show === true)) {
                    child.show = true;
                    child.children = newChilds;
                }
            }

        return child;
    });

    !isEmpty(claimTypes) && filteredChild(claimTypes[0]?.children, searchValue);
}

export const hasAccess = (ids) => {
    if (isAdminOrSuperAdmin() || !ids || isEmpty(ids))
        return true;
    let permissions = encodeJwtToken()?.DynamicPermission
    if (typeof permissions === "object")
        return isEmpty(ids) ? false : ids.some(item => permissions.includes(item + ''));
    else
        return isEmpty(ids) ? false : ids.some(item => permissions == item);
}

const resourceClaimNameById = (id) => {
    switch (id) {
        //Financial DashBoard
        case UserClaimEnumerations.getApDfSaleGadgets: return 'adminPanelClaim.getApDfSaleGadgets'
        case UserClaimEnumerations.getApDfTotalOnlinePayments: return 'adminPanelClaim.getApDfTotalOnlinePayments'
        case UserClaimEnumerations.getApDfTotalSales: return 'adminPanelClaim.getApDfTotalSales'
        case UserClaimEnumerations.getApDfOnlinePaymentsGateway: return 'adminPanelClaim.getApDfOnlinePaymentsGateway'
        case UserClaimEnumerations.getApDfOrderByApplicationType: return 'adminPanelClaim.getApDfOrderByApplicationType'
        case UserClaimEnumerations.getApDfOrderByTurnaround: return 'adminPanelClaim.getApDfOrderByTurnaround'
        //Business Dashboard
        case UserClaimEnumerations.getApDbTotalSales: return 'adminPanelClaim.getApDbTotalSales'
        case UserClaimEnumerations.getApDbTotalOrders: return 'adminPanelClaim.getApDbTotalOrders'
        case UserClaimEnumerations.getApDbOnlinePaymentStatus: return 'adminPanelClaim.getApDbOnlinePaymentStatus'
        case UserClaimEnumerations.getApDbTotalOnlinePayments: return 'adminPanelClaim.getApDbTotalOnlinePayments'
        //User Dashboard
        case UserClaimEnumerations.getApDuTotalUsers: return 'adminPanelClaim.getApDuTotalUsers'
        case UserClaimEnumerations.getApDuLastJoinedUsers: return 'adminPanelClaim.getApDuLastJoinedUsers'
        case UserClaimEnumerations.getApDuInactiveUsers: return 'adminPanelClaim.getApDuInactiveUsers'
        case UserClaimEnumerations.getApDuRemainingLimitWithCredit: return 'adminPanelClaim.getApDuRemainingLimitWithCredit'
        case UserClaimEnumerations.getApDuRemainingLimitWithoutCredit: return 'adminPanelClaim.getApDuRemainingLimitWithoutCredit'
        case UserClaimEnumerations.getApDuGrantedCredit: return 'adminPanelClaim.getApDuGrantedCredit'
        //Sms Panel Dashboard
        case UserClaimEnumerations.getApDSmsPanelCredit: return 'adminPanelClaim.getApDSmsPanelCredit'
        //Agency Dashboard
        case UserClaimEnumerations.getApDaLatestSubmittedAgencies: return 'adminPanelClaim.getApDaLatestSubmittedAgencies'
        case UserClaimEnumerations.getApDaTotalSalesAmountAgencies: return 'adminPanelClaim.getApDaTotalSalesAmountAgencies'
        case UserClaimEnumerations.getApDaPopularAgencies: return 'adminPanelClaim.getApDaPopularAgencies'
        case UserClaimEnumerations.getApDaActiveStates: return 'adminPanelClaim.getApDaActiveStates'
        //Audit Dashboard
        case UserClaimEnumerations.getApDauAggregateStatistics: return 'adminPanelClaim.getApDauAggregateStatistics'
        case UserClaimEnumerations.getApDauNotProcessedPaymentAuditCount: return 'adminPanelClaim.getApDauNotProcessedPaymentAuditCount'
        case UserClaimEnumerations.getApDauPaymentGatewaysMessages: return 'adminPanelClaim.getApDauPaymentGatewaysMessages'
        case UserClaimEnumerations.getApDauStatisticsBasedOnAuditedAndPaymentStatuses: return 'adminPanelClaim.getApDauStatisticsBasedOnAuditedAndPaymentStatuses'
        case UserClaimEnumerations.getApDauStatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes: return 'adminPanelClaim.getApDauStatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes'
        //Feedback dashboard
        case UserClaimEnumerations.getApDfbComments: return 'adminPanelClaim.getApDfbComments'
        case UserClaimEnumerations.getApDfbRatingParametersChart: return 'adminPanelClaim.getApDfbRatingParametersChart'
        case UserClaimEnumerations.getApDfbStrengthWeeknessChart: return 'adminPanelClaim.getApDfbStrengthWeeknessChart'
        case UserClaimEnumerations.getApDfbProducts: return 'adminPanelClaim.getApDfbProducts'
        case UserClaimEnumerations.getApDfbTotalByStatusGedgets: return 'adminPanelClaim.getApDfbTotalByStatusGedgets'
        case UserClaimEnumerations.getApDfbUserFeedbacks: return 'adminPanelClaim.getApDfbUserFeedbacks'
        case UserClaimEnumerations.getApDfbByTypeMetricAndRates: return 'adminPanelClaim.getApDfbByTypeMetricAndRates'
        case UserClaimEnumerations.getApDfbByStateTypesAndDateChart: return 'adminPanelClaim.getApDfbByStateTypesAndDateChart'
        case UserClaimEnumerations.getApDfbStatuses: return 'adminPanelClaim.getApDfbStatuses'
        case UserClaimEnumerations.getApDfbCitiesGrid: return 'adminPanelClaim.getApDfbCitiesGrid'
        case UserClaimEnumerations.getApDfbCustomers: return 'adminPanelClaim.getApDfbCustomers'
        case UserClaimEnumerations.getApDfbProvinces: return 'adminPanelClaim.getApDfbProvinces'
        case UserClaimEnumerations.getApDfbTotalStatusByFilterOptions: return 'adminPanelClaim.getApDfbTotalStatusByFilterOptions'
        case UserClaimEnumerations.getApDfbSharedSheetOrderFeedback: return 'adminPanelClaim.getApDfbSharedSheetOrderFeedback'
        case UserClaimEnumerations.getApDfbEmployeesGrid: return 'adminPanelClaim.getApDfbEmployeesGrid'
        //Cashback Dashboard
        case UserClaimEnumerations.getApCshbUsersCashbackForecastes: return 'adminPanelClaim.getApCshbUsersCashbackForecastes'
        case UserClaimEnumerations.getApCshbFromInquiryTimes: return 'adminPanelClaim.getApCshbFromInquiryTimes'
        case UserClaimEnumerations.getApCshbToInquiryTimes: return 'adminPanelClaim.getApCshbToInquiryTimes'
        case UserClaimEnumerations.getApCshbExcelReport: return 'adminPanelClaim.getApCshbExcelReport'
        case UserClaimEnumerations.getApCshbForcastsWithGrowthPercent: return 'adminPanelClaim.getApCshbForcastsWithGrowthPercent'
        //Customers
        case UserClaimEnumerations.getApCustomers: return 'adminPanelClaim.'
        case UserClaimEnumerations.getApCustomerById: return 'adminPanelClaim.getApCustomerById'
        case UserClaimEnumerations.editApCustomer: return 'adminPanelClaim.editApCustomer'
        case UserClaimEnumerations.getApUserBasicInfoById: return 'adminPanelClaim.getApUserBasicInfoById'
        case UserClaimEnumerations.updateApCustomerToken: return 'adminPanelClaim.updateApCustomerToken'
        case UserClaimEnumerations.getApCustomersDuplicatePhoneNumbers: return 'adminPanelClaim.getApCustomersDuplicatePhoneNumbers'
        case UserClaimEnumerations.getApUserCredit: return 'adminPanelClaim.getApUserCredit'
        case UserClaimEnumerations.getApCustomerFieldVisibilityType: return 'adminPanelClaim.getApCustomerFieldVisibilityType'

        //supporters
        case UserClaimEnumerations.getApSupporters: return 'adminPanelClaim.getApSupporters'
        case UserClaimEnumerations.getApSupporterById: return 'adminPanelClaim.getApSupporterById'
        case UserClaimEnumerations.getApSupporterBasicInfoById: return 'adminPanelClaim.getApSupporterBasicInfoById'
        case UserClaimEnumerations.editApSupporter: return 'adminPanelClaim.editApSupporter'
        case UserClaimEnumerations.editApUserCredit: return 'adminPanelClaim.editApUserCredit'
        //User Business Partner
        case UserClaimEnumerations.getApUserBusinessPartners: return 'adminPanelClaim.getApUserBusinessPartners'
        case UserClaimEnumerations.getApUserBusinessPartnerById: return 'adminPanelClaim.getApUserBusinessPartnerById'
        case UserClaimEnumerations.editApUserBusinessPartner: return 'adminPanelClaim.editApUserBusinessPartner'
        case UserClaimEnumerations.getApUserBusinessPartnerBasicInfoById: return 'adminPanelClaim.getApUserBusinessPartnerBasicInfoById'
        //User Addresses
        case UserClaimEnumerations.getApUserActiveAddresses: return 'adminPanelClaim.getApUserActiveAddresses'
        //User Department
        case UserClaimEnumerations.postApAddUserDepartment: return 'adminPanelClaim.postApAddUserDepartment'
        case UserClaimEnumerations.getApUserDepartments: return 'adminPanelClaim.getApUserDepartments'
        case UserClaimEnumerations.getApUserDepartmentById: return 'adminPanelClaim.getApUserDepartmentById'
        case UserClaimEnumerations.postApUserDepartment: return 'adminPanelClaim.postApUserDepartment'
        case UserClaimEnumerations.editApUserDepartment: return 'adminPanelClaim.editApUserDepartment'
        case UserClaimEnumerations.deleteApUserDepartment: return 'adminPanelClaim.deleteApUserDepartment'
        case UserClaimEnumerations.getApDepartmentsTree: return 'adminPanelClaim.getApDepartmentsTree'
        case UserClaimEnumerations.getApUserDepartmentsTree: return 'adminPanelClaim.getApUserDepartmentsTree'
        case UserClaimEnumerations.getApDepartmentsList: return 'adminPanelClaim.getApDepartmentsList'
        //Orders
        case UserClaimEnumerations.getApOrders: return 'adminPanelClaim.getApOrders'
        case UserClaimEnumerations.getApOrderDto: return 'adminPanelClaim.getApOrderDto'
        case UserClaimEnumerations.getApOrderDetail: return 'adminPanelClaim.getApOrderDetail'
        case UserClaimEnumerations.updateApOrderStatus: return 'adminPanelClaim.updateApOrderStatus'
        case UserClaimEnumerations.getApOrderStatusHistory: return 'adminPanelClaim.getApOrderStatusHistory'
        case UserClaimEnumerations.getApOrderNasStatusHistory: return 'adminPanelClaim.getApOrderNasStatusHistory'
        case UserClaimEnumerations.getApOrderNasStatusHistoryDetail: return 'adminPanelClaim.getApOrderNasStatusHistoryDetail'
        case UserClaimEnumerations.deleteApNotConfirmedOrder: return 'adminPanelClaim.deleteApNotConfirmedOrder'
        case UserClaimEnumerations.getApOrderUnOfficialReport: return 'adminPanelClaim.getApOrderUnOfficialReport'
        case UserClaimEnumerations.getApOrderOfficialReport: return 'adminPanelClaim.getApOrderOfficialReport'
        case UserClaimEnumerations.getApStatusQueue: return 'adminPanelClaim.getApStatusQueue'
        case UserClaimEnumerations.getApOrderFilterPoints: return 'adminPanelClaim.getApOrderFilterPoints'
        case UserClaimEnumerations.getApOrderBillBookOptions: return 'adminPanelClaim.getApOrderBillBookOptions'
        case UserClaimEnumerations.getApOrderPunchPoints: return 'adminPanelClaim.getApOrderPunchPoints'
        case UserClaimEnumerations.getApOrderFoldLines: return 'adminPanelClaim.getApOrderFoldLines'
        case UserClaimEnumerations.getApOrderAdditionalInformation: return 'adminPanelClaim.getApOrderAdditionalInformation'
        case UserClaimEnumerations.editResolveOrderPaymentProblem: return 'adminPanelClaim.editResolveOrderPaymentProblem'
        case UserClaimEnumerations.getApOrderProductDetail: return 'adminPanelClaim.getApOrderProductDetail'
        case UserClaimEnumerations.getApOrderUserAggrementDetail: return 'adminPanelClaim.getApOrderUserAggrementDetail'
        case UserClaimEnumerations.getApStatusQueueByServerName: return 'adminPanelClaim.getApStatusQueueByServerName'
        case UserClaimEnumerations.getApOrderEstimatedTime: return 'adminPanelClaim.getApOrderEstimatedTime'
        case UserClaimEnumerations.getApOrderQueueByRemainingToEstimatedTime: return 'adminPanelClaim.getApOrderQueueByRemainingToEstimatedTime'
        //Store orders
        case UserClaimEnumerations.getApStoreOrders: return 'adminPanelClaim.getApStoreOrders'
        case UserClaimEnumerations.getApStoreOrderOfficialReport: return 'adminPanelClaim.getApStoreOrderOfficialReport'
        //Payments
        case UserClaimEnumerations.getApPayments: return 'adminPanelClaim.getApPayments'
        case UserClaimEnumerations.getApPaymentById: return 'adminPanelClaim.getApPaymentById'
        case UserClaimEnumerations.getApPaymentOrders: return 'adminPanelClaim.getApPaymentOrders'
        case UserClaimEnumerations.getApPaymentIncreaseCredit: return 'adminPanelClaim.getApPaymentIncreaseCredit'
        case UserClaimEnumerations.getApPaymentStatusHistory: return 'adminPanelClaim.getApPaymentStatusHistory'
        case UserClaimEnumerations.getApPaymentReport: return 'adminPanelClaim.getApPaymentReport'
        case UserClaimEnumerations.updateApPaymentStatus: return 'adminPanelClaim.updateApPaymentStatus'
        case UserClaimEnumerations.getApPaymentsHasProblem: return 'adminPanelClaim.getApPaymentsHasProblem'
        case UserClaimEnumerations.getApAllPaymentsExcelReport: return 'adminPanelClaim.getApAllPaymentsExcelReport'

        //Audits
        case UserClaimEnumerations.getApPaymentAudits: return 'adminPanelClaim.getApPaymentAudits'
        case UserClaimEnumerations.getApPaymentAuditById: return 'adminPanelClaim.getApPaymentAuditById'
        case UserClaimEnumerations.getApPaymentAuditGateways: return 'adminPanelClaim.getApPaymentAuditGateways'
        case UserClaimEnumerations.updateApPaymentAuditStateToFail: return 'adminPanelClaim.updateApPaymentAuditStateToFail'
        case UserClaimEnumerations.getApPaymentAuditHistories: return 'adminPanelClaim.getApPaymentAuditHistories'
        case UserClaimEnumerations.updateApPaymentAuditMatchingType: return 'adminPanelClaim.updateApPaymentAuditMatchingType'
        case UserClaimEnumerations.getApAllPaymentAuditsExcelReport: return 'adminPanelClaim.getApAllPaymentAuditsExcelReport'
        case UserClaimEnumerations.updateApPaymentAuditsMatchingTypes: return 'adminPanelClaim.updateApPaymentAuditsMatchingTypes'
        //Audit Resources
        case UserClaimEnumerations.getApPaymentAuditAsanPardakhts: return 'adminPanelClaim.getApPaymentAuditAsanPardakhts'
        case UserClaimEnumerations.getApPaymentAuditAsanPardakhtByActionId: return 'adminPanelClaim.getApPaymentAuditAsanPardakhtByActionId'
        case UserClaimEnumerations.getApPaymentAuditSepehrs: return 'adminPanelClaim.getApPaymentAuditSepehrs'
        case UserClaimEnumerations.getApPaymentAuditSepehtByActionId: return 'adminPanelClaim.getApPaymentAuditSepehtByActionId'
        case UserClaimEnumerations.getApPaymentAuditNgenius: return 'adminPanelClaim.getApPaymentAuditNgenius'
        case UserClaimEnumerations.getApPaymentAuditNgeniusByActionId: return 'adminPanelClaim.getApPaymentAuditNgeniusByActionId'
        case UserClaimEnumerations.postApPaymentAuditSources: return 'adminPanelClaim.postApPaymentAuditSources'
        case UserClaimEnumerations.getApPaymentAuditSources: return 'adminPanelClaim.getApPaymentAuditSources'
        //RePorts
        case UserClaimEnumerations.getApStatisticalReportOfOrders: return 'adminPanelClaim.getApStatisticalReportOfOrders'
        case UserClaimEnumerations.getApStatisticalReportOfPayments: return 'adminPanelClaim.getApStatisticalReportOfPayments'
        case UserClaimEnumerations.getApUsersDebitCredit: return 'adminPanelClaim.getApUsersDebitCredit'
        //Shippings
        case UserClaimEnumerations.getApShippings: return 'adminPanelClaim.getApShippings'
        case UserClaimEnumerations.getApShippingById: return 'adminPanelClaim.getApShippingById'
        case UserClaimEnumerations.getApShippingGroupSheetOrders: return 'adminPanelClaim.getApShippingGroupSheetOrders'
        case UserClaimEnumerations.getApShippingStoreOrders: return 'adminPanelClaim.getApShippingStoreOrders'
        case UserClaimEnumerations.getApShippingQueueByLastStatuses: return 'adminPanelClaim.getApShippingQueueByLastStatuses'
        case UserClaimEnumerations.getApShippingRemainingEstimatedTime: return 'adminPanelClaim.getApShippingRemainingEstimatedTime'
        case UserClaimEnumerations.getApQueueByShippingMethods: return 'adminPanelClaim.getApQueueByShippingMethods'
        case UserClaimEnumerations.getApShippingMethods: return 'adminPanelClaim.getApShippingMethods'
        case UserClaimEnumerations.getApShippingPlans: return 'adminPanelClaim.getApShippingPlans'
        //Customer Files
        case UserClaimEnumerations.getApCustomerFiles: return 'adminPanelClaim.getApCustomerFiles'
        case UserClaimEnumerations.getApCustomerFileById: return 'adminPanelClaim.getApCustomerFileById'
        case UserClaimEnumerations.getApDownloadOriginFile: return 'adminPanelClaim.getApDownloadOriginFile'
        //PPR Contents
        case UserClaimEnumerations.getApPprContents: return 'adminPanelClaim.getApPprContents'
        case UserClaimEnumerations.getApPprContentByPprId: return 'adminPanelClaim.getApPprContentByPprId'
        case UserClaimEnumerations.getApPprContentByName: return 'adminPanelClaim.getApPprContentByName'
        case UserClaimEnumerations.postApPprContent: return 'adminPanelClaim.postApPprContent'
        case UserClaimEnumerations.editApPprContentById: return 'adminPanelClaim.editApPprContentById'
        case UserClaimEnumerations.getApPprContentBasicInfo: return 'adminPanelClaim.getApPprContentBasicInfo'
        //Central Feedback
        case UserClaimEnumerations.getApFeedbackAnnouncements: return 'adminPanelClaim.getApFeedbackAnnouncements'
        case UserClaimEnumerations.getApFeedbackAnnouncementBasicInfo: return 'adminPanelClaim.getApFeedbackAnnouncementBasicInfo'

        case UserClaimEnumerations.getApFeedbackShareSheetOrders: return 'adminPanelClaim.getApFeedbackShareSheetOrders'
        case UserClaimEnumerations.getApFeedbackShareSheetOrderBasicInfo: return 'adminPanelClaim.getApFeedbackShareSheetOrderBasicInfo'

        case UserClaimEnumerations.getApFeedbackToEmployees: return 'adminPanelClaim.getApFeedbackToEmployees'
        case UserClaimEnumerations.getApFeedbackToEmployeeBasicInfo: return 'adminPanelClaim.getApFeedbackToEmployeeBasicInfo'

        case UserClaimEnumerations.getApFeedbackActivityHistory: return 'adminPanelClaim.getApFeedbackActivityHistory'
        case UserClaimEnumerations.updateApFeedbackIsPublic: return 'adminPanelClaim.updateApFeedbackIsPublic'
        case UserClaimEnumerations.postApFeedbackAddState: return 'adminPanelClaim.postApFeedbackAddState'
        //feedbacks
        case UserClaimEnumerations.getApFeedbackTypes: return 'adminPanelClaim.getApFeedbackTypes'
        case UserClaimEnumerations.getApFeedbackTypeBasicInfo: return 'adminPanelClaim.getApFeedbackTypeBasicInfo'
        case UserClaimEnumerations.updateApFeedbackTypeState: return 'adminPanelClaim.updateApFeedbackTypeState'
        //Feedback metrics
        case UserClaimEnumerations.getApFeedbackTypeMetrics: return 'adminPanelClaim.getApFeedbackTypeMetrics'
        case UserClaimEnumerations.getApFeedbackTypeMetricById: return 'adminPanelClaim.getApFeedbackTypeMetricById'
        case UserClaimEnumerations.postApFeedbackTypeMetric: return 'adminPanelClaim.postApFeedbackTypeMetric'
        case UserClaimEnumerations.editApFeedbackTypeMetric: return 'adminPanelClaim.editApFeedbackTypeMetric'
        case UserClaimEnumerations.deleteApFeedbackTypeMetric: return 'adminPanelClaim.deleteApFeedbackTypeMetric'
        case UserClaimEnumerations.editApSortFeedbackTypeMetric: return 'adminPanelClaim.editApSortFeedbackTypeMetric'
        //Feedback Demand service metric
        case UserClaimEnumerations.getApFeedbackTypeDemandServices: return 'adminPanelClaim.getApFeedbackTypeDemandServices'
        case UserClaimEnumerations.getApFeedbackTypeDemandServiceById: return 'adminPanelClaim.getApFeedbackTypeDemandServiceById'
        case UserClaimEnumerations.postApFeedbackTypeDemandService: return 'adminPanelClaim.postApFeedbackTypeDemandService'
        case UserClaimEnumerations.editApFeedbackTypeDemandService: return 'adminPanelClaim.editApFeedbackTypeDemandService'
        case UserClaimEnumerations.deleteApFeedbackTypeDemandService: return 'adminPanelClaim.deleteApFeedbackTypeDemandService'
        case UserClaimEnumerations.editApSortFeedbackTypeDemandServices: return 'adminPanelClaim.editApSortFeedbackTypeDemandServices'
        //Blogs
        case UserClaimEnumerations.getApBlogs: return 'adminPanelClaim.getApBlogs'
        case UserClaimEnumerations.getApBlogById: return 'adminPanelClaim.getApBlogById'
        case UserClaimEnumerations.postApBlog: return 'adminPanelClaim.postApBlog'
        case UserClaimEnumerations.editApBlog: return 'adminPanelClaim.editApBlog'
        case UserClaimEnumerations.deleteApBlog: return 'adminPanelClaim.deleteApBlog'
        case UserClaimEnumerations.getApBlogCategories: return 'adminPanelClaim.getApBlogCategories'
        case UserClaimEnumerations.getApBasicBlogCategories: return 'adminPanelClaim.getApBasicBlogCategories'
        case UserClaimEnumerations.getApBlogCategoryById: return 'adminPanelClaim.getApBlogCategoryById'
        case UserClaimEnumerations.deleteApBlogCategory: return 'adminPanelClaim.deleteApBlogCategory'
        case UserClaimEnumerations.editApBlogCategory: return 'adminPanelClaim.editApBlogCategory'
        case UserClaimEnumerations.postApBlogCategory: return 'adminPanelClaim.postApBlogCategory'
        //News
        case UserClaimEnumerations.getApNews: return 'adminPanelClaim.getApNews'
        case UserClaimEnumerations.getApNewsById: return 'adminPanelClaim.getApNewsById'
        case UserClaimEnumerations.postApNews: return 'adminPanelClaim.postApNews'
        case UserClaimEnumerations.editApNews: return 'adminPanelClaim.editApNews'
        case UserClaimEnumerations.deleteApNews: return 'adminPanelClaim.deleteApNews'
        case UserClaimEnumerations.getApNewsCategories: return 'adminPanelClaim.getApNewsCategories'
        case UserClaimEnumerations.getApBasicNewsCategories: return 'adminPanelClaim.getApBasicNewsCategories'
        case UserClaimEnumerations.getApNewsCategoryById: return 'adminPanelClaim.getApNewsCategoryById'
        case UserClaimEnumerations.postApNewsCategory: return 'adminPanelClaim.postApNewsCategory'
        case UserClaimEnumerations.editApNewsCategory: return 'adminPanelClaim.editApNewsCategory'
        case UserClaimEnumerations.deleteApNewsCategory: return 'adminPanelClaim.deleteApNewsCategory'
        //Announcements
        case UserClaimEnumerations.getApAnnouncements: return 'adminPanelClaim.getApAnnouncements'
        case UserClaimEnumerations.getApAnnouncementById: return 'adminPanelClaim.getApAnnouncementById'
        case UserClaimEnumerations.getApAnnouncementTitleContentById: return 'adminPanelClaim.getApAnnouncementTitleContentById'
        case UserClaimEnumerations.postApAnnouncement: return 'adminPanelClaim.postApAnnouncement'
        case UserClaimEnumerations.editApAnnouncement: return 'adminPanelClaim.editApAnnouncement'
        case UserClaimEnumerations.editApSortAnnouncement: return 'adminPanelClaim.editApSortAnnouncement'
        case UserClaimEnumerations.deleteApAnnouncement: return 'adminPanelClaim.deleteApAnnouncement'
        //singlePages
        case UserClaimEnumerations.getApSpecificStaticPageList: return 'adminPanelClaim.getApSpecificStaticPageList'
        case UserClaimEnumerations.getApSpecificStaticPageRowById: return 'adminPanelClaim.getApSpecificStaticPageRowById'
        case UserClaimEnumerations.postApSpecificStaticPageRow: return 'adminPanelClaim.postApSpecificStaticPageRow'
        case UserClaimEnumerations.editApSpecificStaticPageRow: return 'adminPanelClaim.editApSpecificStaticPageRow'
        case UserClaimEnumerations.editApSortStaticPageAdmin: return 'adminPanelClaim.editApSortStaticPageAdmin'
        case UserClaimEnumerations.deleteApSpecificStaticPageRow: return 'adminPanelClaim.deleteApSpecificStaticPageRow'
        //fileUploader
        case UserClaimEnumerations.postFileUploader: return 'adminPanelClaim.postFileUploader'
        case UserClaimEnumerations.deleteFileUploader: return 'adminPanelClaim.deleteFileUploader'
        case UserClaimEnumerations.getFileUploader: return 'adminPanelClaim.getFileUploader'
        //Sliders
        case UserClaimEnumerations.getApSliders: return 'adminPanelClaim.getApSliders'
        case UserClaimEnumerations.getApSliderById: return 'adminPanelClaim.getApSliderById'
        case UserClaimEnumerations.postApSlider: return 'adminPanelClaim.postApSlider'
        case UserClaimEnumerations.editApSlider: return 'adminPanelClaim.editApSlider'
        case UserClaimEnumerations.editApSortSlider: return 'adminPanelClaim.editApSortSlider'
        case UserClaimEnumerations.deleteApSlider: return 'adminPanelClaim.deleteApSlider'
        //Promotions
        case UserClaimEnumerations.getApPromoteContent: return 'adminPanelClaim.getApPromoteContent'
        case UserClaimEnumerations.editApPromoteContent: return 'adminPanelClaim.editApPromoteContent'
        //storeProductProperties
        case UserClaimEnumerations.deleteApStoreProductProperty: return 'adminPanelClaim.deleteApStoreProductProperty'
        case UserClaimEnumerations.getApStoreProductProperty: return 'adminPanelClaim.getApStoreProductProperty'
        case UserClaimEnumerations.editApStoreProductProperty: return 'adminPanelClaim.editApStoreProductProperty'
        case UserClaimEnumerations.postApStoreProductProperty: return 'adminPanelClaim.postApStoreProductProperty'
        case UserClaimEnumerations.deleteApStoreProduct: return 'adminPanelClaim.deleteApStoreProduct'
        case UserClaimEnumerations.getApStoreProduct: return 'adminPanelClaim.getApStoreProduct'
        case UserClaimEnumerations.getApStoreProducts: return 'adminPanelClaim.getApStoreProducts'
        case UserClaimEnumerations.editApStoreProduct: return 'adminPanelClaim.editApStoreProduct'
        case UserClaimEnumerations.postApStoreProduct: return 'adminPanelClaim.postApStoreProduct'
        case UserClaimEnumerations.deleteApStoreProductSupplierPriceDiscount: return 'adminPanelClaim.deleteApStoreProductSupplierPriceDiscount'
        case UserClaimEnumerations.getApStoreProductSupplierPriceDiscountsById: return 'adminPanelClaim.getApStoreProductSupplierPriceDiscountsById'
        case UserClaimEnumerations.getApStoreProductSupplierPriceDiscounts: return 'adminPanelClaim.getApStoreProductSupplierPriceDiscounts'
        case UserClaimEnumerations.editApStoreProductSupplierPriceDiscounts: return 'adminPanelClaim.editApStoreProductSupplierPriceDiscounts'
        case UserClaimEnumerations.postApStoreProductSupplierPriceDiscounts: return 'adminPanelClaim.postApStoreProductSupplierPriceDiscounts'
        case UserClaimEnumerations.deleteApStoreProductSupplierPrices: return 'adminPanelClaim.deleteApStoreProductSupplierPrices'
        case UserClaimEnumerations.getApStoreProductSupplierPricesById: return 'adminPanelClaim.getApStoreProductSupplierPricesById'
        case UserClaimEnumerations.getApStoreProductSupplierPrices: return 'adminPanelClaim.getApStoreProductSupplierPrices'
        case UserClaimEnumerations.editApStoreProductSupplierPrices: return 'adminPanelClaim.editApStoreProductSupplierPrices'
        case UserClaimEnumerations.postApStoreProductSupplierPrices: return 'adminPanelClaim.postApStoreProductSupplierPrices'
        case UserClaimEnumerations.deleteApStoreProductUnits: return 'adminPanelClaim.deleteApStoreProductUnits'
        case UserClaimEnumerations.getApStoreProductUnits: return 'adminPanelClaim.getApStoreProductUnits'
        case UserClaimEnumerations.editApStoreProductUnits: return 'adminPanelClaim.editApStoreProductUnits'
        case UserClaimEnumerations.postApStoreProductUnits: return 'adminPanelClaim.postApStoreProductUnits'
        //agency
        case UserClaimEnumerations.getApAgencyBasicDetails: return 'adminPanelClaim.getApAgencyBasicDetails'
        case UserClaimEnumerations.getApAgencies: return 'adminPanelClaim.getApAgencies'
        case UserClaimEnumerations.getApAgencyById: return 'adminPanelClaim.getApAgencyById'
        case UserClaimEnumerations.getApProductsOfAgency: return 'adminPanelClaim.getApProductsOfAgency'

        //festival
        case UserClaimEnumerations.getApFestivals: return 'adminPanelClaim.getApFestivals'
        case UserClaimEnumerations.getApFestivalById: return 'adminPanelClaim.getApFestivalById'
        case UserClaimEnumerations.getApFestivalPrizes: return 'adminPanelClaim.getApFestivalPrizes'
        case UserClaimEnumerations.getApFestivalWinners: return 'adminPanelClaim.getApFestivalWinners'
        case UserClaimEnumerations.getApFestivalWinnersActionId: return 'adminPanelClaim.getApFestivalWinnersActionId'
        //log exception
        case UserClaimEnumerations.getApCmsLogs: return 'adminPanelClaim.getApCmsLogs'
        case UserClaimEnumerations.getApCmsLogById: return 'adminPanelClaim.getApCmsLogById'
        case UserClaimEnumerations.getApCmsLogException: return 'adminPanelClaim.getApCmsLogException'
        case UserClaimEnumerations.getApCmsLogExceptionType: return 'adminPanelClaim.getApCmsLogExceptionType'
        case UserClaimEnumerations.getApCmsLogSources: return 'adminPanelClaim.getApCmsLogSources'
        //downloads
        case UserClaimEnumerations.getApDownloadCategories: return 'adminPanelClaim.getApDownloadCategories'
        case UserClaimEnumerations.getApDownloadCategoriesDropDown: return 'adminPanelClaim.getApDownloadCategoriesDropDown'
        case UserClaimEnumerations.getApDownloadCategoryById: return 'adminPanelClaim.getApDownloadCategoryById'
        case UserClaimEnumerations.postApDownloadCategory: return 'adminPanelClaim.postApDownloadCategory'
        case UserClaimEnumerations.editApDownloadCategory: return 'adminPanelClaim.editApDownloadCategory'
        case UserClaimEnumerations.deleteApDownloadsCategory: return 'adminPanelClaim.deleteApDownloadsCategory'
        case UserClaimEnumerations.getApDownloads: return 'adminPanelClaim.getApDownloads'
        case UserClaimEnumerations.getApDownloadById: return 'adminPanelClaim.getApDownloadById'
        case UserClaimEnumerations.postApDownload: return 'adminPanelClaim.postApDownload'
        case UserClaimEnumerations.editApDownload: return 'adminPanelClaim.editApDownload'
        case UserClaimEnumerations.deleteApDownloads: return 'adminPanelClaim.deleteApDownloads'
        case UserClaimEnumerations.getApDownloadsByIdDetails: return 'adminPanelClaim.getApDownloadsByIdDetails'
        case UserClaimEnumerations.getApDownloadDetailsById: return 'adminPanelClaim.getApDownloadDetailsById'
        case UserClaimEnumerations.postApDownloadDetails: return 'adminPanelClaim.postApDownloadDetails'
        case UserClaimEnumerations.editApDownloadDetails: return 'adminPanelClaim.editApDownloadDetails'
        case UserClaimEnumerations.deleteApDownloadDetails: return 'adminPanelClaim.deleteApDownloadDetails'
        //galleries
        case UserClaimEnumerations.getApGalleries: return 'adminPanelClaim.getApGalleries'
        case UserClaimEnumerations.getApGalleryBasicDetails: return 'adminPanelClaim.getApGalleryBasicDetails'
        case UserClaimEnumerations.getApGalleryById: return 'adminPanelClaim.getApGalleryById'
        case UserClaimEnumerations.postApGallery: return 'adminPanelClaim.postApGallery'
        case UserClaimEnumerations.editApGallery: return 'adminPanelClaim.editApGallery'
        case UserClaimEnumerations.deleteApGallery: return 'adminPanelClaim.deleteApGallery'
        case UserClaimEnumerations.getApGalleryCategories: return 'adminPanelClaim.getApGalleryCategories'
        case UserClaimEnumerations.getApGalleryCategoryById: return 'adminPanelClaim.getApGalleryCategoryById'
        case UserClaimEnumerations.postApGalleryCategory: return 'adminPanelClaim.postApGalleryCategory'
        case UserClaimEnumerations.editApGalleryCategory: return 'adminPanelClaim.editApGalleryCategory'
        case UserClaimEnumerations.deleteApGalleryCategory: return 'adminPanelClaim.deleteApGalleryCategory'
        //advertisement
        case UserClaimEnumerations.getApAdvertisements: return 'adminPanelClaim.getApAdvertisements'
        case UserClaimEnumerations.getApAdvertisementById: return 'adminPanelClaim.getApAdvertisementById'
        case UserClaimEnumerations.postApAdvertisement: return 'adminPanelClaim.postApAdvertisement'
        case UserClaimEnumerations.editApAdvetisement: return 'adminPanelClaim.editApAdvetisement'
        case UserClaimEnumerations.deleteAdvertisement: return 'adminPanelClaim.deleteAdvertisement'
        //aggrements
        case UserClaimEnumerations.getApUserAggrements: return 'adminPanelClaim.getApUserAggrements'
        case UserClaimEnumerations.getApUserAggrementById: return 'adminPanelClaim.getApUserAggrementById'
        case UserClaimEnumerations.getApUserAggrementDetailById: return 'adminPanelClaim.getApUserAggrementDetailById'
        case UserClaimEnumerations.postApUserAggrement: return 'adminPanelClaim.postApUserAggrement'
        case UserClaimEnumerations.updateApUserAggrement: return 'adminPanelClaim.updateApUserAggrement'
        case UserClaimEnumerations.deleteApUserAggrement: return 'adminPanelClaim.deleteApUserAggrement'
        case UserClaimEnumerations.getApUserAggrementStaticPages: return 'adminPanelClaim.getApUserAggrementStaticPages'
        case UserClaimEnumerations.getApUserAgreementsStaticPageDetailById: return 'adminPanelClaim.getApUserAgreementsStaticPageDetailById'
        case UserClaimEnumerations.postApUserAggrementStaticPages: return 'adminPanelClaim.postApUserAggrementStaticPages'
        case UserClaimEnumerations.editApUserAggrementStaticPages: return 'adminPanelClaim.editApUserAggrementStaticPages'
        case UserClaimEnumerations.deleteApUserAggrementStaticPages: return 'adminPanelClaim.deleteApUserAggrementStaticPages'
        case UserClaimEnumerations.updateApUserAggrementState: return 'adminPanelClaim.updateApUserAggrementState'
        case UserClaimEnumerations.getApUserAggrementSignHistories: return 'adminPanelClaim.getApUserAggrementSignHistories'
        case UserClaimEnumerations.getApUserAggrementSignUserInfo: return 'adminPanelClaim.getApUserAggrementSignUserInfo'
        case UserClaimEnumerations.getApUserAggrementSignUsers: return 'adminPanelClaim.getApUserAggrementSignUsers'

        default: return 'None'
    }
}
