import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import UpBaseQueueOrdersHeader from './bases/UpBaseQueueOrdersHeader';
import UpQueueOrdersGroupSheet from './UpQueueOrdersGroupSheet';
import UserPanelWrapper from '../../../UserPanelWrapper';
import Enumerations from '../../../../../configs/Enumerations';
import DateHelper from '../../../../../utilities/DateHelper';
import NsTab from '../../../../../components/tabs/NsTab';
import UpQueueOrdersStore from './UpQueueOrdersStore';
import Config from '../../../../../configs/Config';

class UserOrdersQueueContent extends React.Component {
    constructor(props) {
        super(props);
        this.tabs = ConstantsWithTranslation.getOrderProductTabs(true, true);
        this.selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);
        this.hasStoreProducts = Config.hasStoreProducts;
        this.state = {
            selectedTab: this.selectedTab || this.tabs[0],
            selectedTabIndex: this.tabs.indexOf(this.selectedTab) > -1 ? this.tabs.indexOf(this.selectedTab) : 0,
            fromDate: new Date(DateHelper.getCustomPreviousMonth(2)),
            toDate: null,
            refreshDate: null,
        }
    }

    tabChanged = (newValue, selectedTab) => {
        newValue !== this.state.selectedTabIndex &&
            this.setState({ selectedTabIndex: newValue, selectedTab },
                () => !!this.props.history && this.props.history.push(selectedTab.url));
    }

    updateDates = (fromDate, toDate, refreshDate) => this.setState({ fromDate, toDate, refreshDate });

    render() {
        const { fromDate, toDate, selectedTabIndex, selectedTab, refreshDate } = this.state;

        return (
            <>
                <div className='d-flex justify-content-end gap-2 mb-3 px-3'>
                    <UpBaseQueueOrdersHeader updateDates={this.updateDates} />
                </div>

                {this.hasStoreProducts &&
                    <NsTab tabs={this.tabs}
                        className='mb-3'
                        value={selectedTabIndex}
                        onChange={this.tabChanged} />
                }
                {selectedTab?.id === Enumerations.orderProductType.sharedSheet
                    ? <UpQueueOrdersGroupSheet toDate={toDate}
                        fromDate={fromDate}
                        refreshDate={refreshDate} />
                    : <UpQueueOrdersStore toDate={toDate}
                        fromDate={fromDate}
                        isStore={true}
                        refreshDate={refreshDate} />
                }
            </>
        );
    }
}

export { UserOrdersQueueContent };

const UpQueueOrders = (props) => {
    return <UserPanelWrapper titleName={getTranslatedRawText('adminPanel.sidebar.queue')}
        titleIcon='ns-icon-queue' >
        <UserOrdersQueueContent {...props} />
    </UserPanelWrapper>
}

export default React.memo(withRouter(UpQueueOrders));