import { getTranslatedRawText, isEnCulture } from "../../../../utilities/CultureHelper";
import Enumerations from "../../../../configs/Enumerations";

const notRtlClass = `${!!isEnCulture() && 'text-end not-rtl'}`;

export const getApStatisticalReportOfOrdersColumn = () => [
    {
        field: 'date',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.date'),
        width: 110,
        dataType: Enumerations.gridType.date,
    },
    {
        field: 'orderUsers',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.orderUsers'),
        width: 205,
    },
    {
        field: 'orders',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.orders'),
        width: 100,
    },
    {
        field: 'notConfirmed',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.notConfirmed'),
        width: 135,
    },
    {
        field: 'preparedFaild',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.prepareFailed'),
        width: 130,
    },
    {
        field: 'prepare',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.prepare'),
        width: 90,
    },
    {
        field: 'inProcess',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.inProcess'),
        width: 120,
    },
    {
        field: 'inTransit',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.inTransit'),
        width: 120,
    },
    {
        field: 'archived',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.archived'),
        width: 100,
    },
    {
        field: 'ordersAmount',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.ordersAmount'),
        width: 210,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'discountAmount',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.totalOrdersDiscountAmount'),
        width: 240,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'successfullyOrderPaymentAmount',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.successfullyPaymentAmount'),
        width: 260,
        dataType: Enumerations.gridType.price,
    }
];

export const getApStatisticalReportOfPaymentsColumn = () => [
    {
        field: 'date',
        headerName: getTranslatedRawText('profile.orderReport.date'),
        width: 110,
        dataType: Enumerations.gridType.date,
    },
    {
        field: 'totalPaymentAmount',
        headerName: getTranslatedRawText('adminPanel.financialReports.totalPaymentAmount'),
        width: 190,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'successfullyPaymentAmount',
        headerName: getTranslatedRawText('adminPanel.financialReports.successfullyPaymentAmount'),
        width: 225,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'failedPaymentAmount',
        headerName: getTranslatedRawText('adminPanel.financialReports.failedPaymentAmount'),
        width: 185,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'unknownPaymentAmount',
        headerName: getTranslatedRawText('adminPanel.financialReports.unknownPaymentAmount'),
        width: 185,
        dataType: Enumerations.gridType.price,
    },
    {
        field: 'numberOfTotalPayments',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.financialReports.numberOfTotalPayments'),
        width: 240,
    },
    {
        field: 'numberOfSuccessfulPayments',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.financialReports.numberOfSuccessfulPayments'),
        width: 185,
    },
    {
        field: 'numberOfFailedPayments',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.financialReports.numberOfFailedPayments'),
        width: 235,
    },
    {
        field: 'numberOfUnknownPayment',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.financialReports.numberOfUnknownPayments'),
        width: 235,
    },
    {
        field: 'numberOfUserWithPayments',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.financialReports.numberOfTotalPaymentsByDistinctUser'),
        width: 225,
    },
    {
        field: 'numberOfUserWithSuccessfulPayments',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.financialReports.numberOfSuccessfulPaymentsByDistinctUser'),
        width: 300,
    },
    {
        field: 'numberOfUserWithFailedPayments',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.financialReports.numberOfFailedPaymentsByDistinctUser'),
        width: 260,
    },
    {
        field: 'numberOfUserWithUnknownPayments',
        dataType: Enumerations.gridType.number,
        headerName: getTranslatedRawText('adminPanel.financialReports.numberOfUnknownPaymentsByDistinctUser'),
        width: 260,
    }
];

export const getApUsersFinancialBalanceSheetColumn = () => [
    {
        field: 'submitTime',
        headerName: getTranslatedRawText('profile.orderReport.date'),
        dataType: Enumerations.gridType.dateTime,
        width: 150
    },
    {
        field: 'description',
        headerName: getTranslatedRawText('profile.orderReport.details'),
        width: 300
    },
    {
        field: 'bedAmount',
        headerName: getTranslatedRawText('profile.orderReport.debtor'),
        dataType: Enumerations.gridType.price,
        width: 160
    },
    {
        field: 'besAmount',
        headerName: getTranslatedRawText('profile.orderReport.creditor'),
        dataType: Enumerations.gridType.price,
        width: 160
    },
    {
        field: 'balance',
        headerName: getTranslatedRawText('profile.orderReport.balance'),
        dataType: Enumerations.gridType.price,
        width: 160
    },
    {
        field: 'sign',
        headerName: getTranslatedRawText('profile.orderReport.sign'),
        width: 100
    },
    {
        field: 'sanadNumber',
        headerName: getTranslatedRawText('profile.orderReport.voucherNumber'),
        width: 145
    },
    {
        field: 'systemResult',
        headerName: getTranslatedRawText('profile.orderReport.systemResult'),
        width: 190
    },
];

