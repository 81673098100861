import React from "react";

import NsToggleGroupButton from "../../../../components/buttons/NsToggleGroupButton";
import NsCard from "../../../../components/cards/NsCard";
import NsSearchInput from "../../../../components/inputs/NsSearchInput";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";

class DownloadHeader extends React.Component {

    render() {
        const { searchText, selectedViewId } = this.props;

        return (
            <NsCard className='p-2 mb-2'>
                <div className="d-flex justify-content-around gap-2">

                    <NsSearchInput hasSearchIcon={true}
                        value={searchText}
                        className='background-color'
                        searchFormClicked={this.props.searchFormClicked}
                        handleSearchTextChanged={this.props.searchValueChanged}
                        placeHolder={getTranslatedRawText('headers.middle.search')}
                        inputClassName='background-color' />

                    <NsToggleGroupButton items={ConstantsWithTranslation.getViewStyle()}
                        className=''
                        selectedItemId={selectedViewId}
                        onClick={this.props.viewItemChanged} />
                </div>

            </NsCard>
        );
    }
}

export default DownloadHeader;