import React from 'react';

import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import '../../assets/sass/components/maps/NsMaps.scss';
import Config from '../../configs/Config';
import CommonHelper from '../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../utilities/ConfigHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';

class NsLeaflet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowMarker: !this.props.isAddNewAddress
        }
    }
    mapClicked = (e) => {
        const { lng, lat } = e.latlng;
        this.setState({ isShowMarker: true })
        CommonHelper.safeFunctionCall(this.props.onClick, lat, lng);
    }

    render() {
        const { lat, lng, mapCenterLatitude, mapCenterLongitude } = this.props;

        return (
            <div className='overflow-hidden' key={mapCenterLatitude}>
                <MapContainer zoom={13} center={[lat || mapCenterLatitude || Config.defaultMapCenter.lat, lng || mapCenterLongitude || Config.defaultMapCenter.lng]}>
                    <TileLayer attribution={getConfigObjectByCulture(Config.metaTags)?.title}
                        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                    <MarkerComponent mapClicked={this.mapClicked}
                        lat={lat}
                        isShowMarker={this.state.isShowMarker}
                        lng={lng} />
                </MapContainer>
            </div>
        );
    }
}

function MarkerComponent({ lat, lng, mapClicked, isShowMarker }) {
    useMapEvents({
        click: (e) => {
            mapClicked(e);
        },
    })

    return (
        (!!lat && !!lng) && isShowMarker && <Marker position={[lat, lng]} />
    )
}

export default NsLeaflet;
