import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

class NsLinearProgress extends React.Component {

  render() {
    const { progress, isLoading, ...otherProps } = this.props;

    return (isLoading
      ? <LinearProgress variant="indeterminate"
        value={progress}
        {...otherProps} />
      : <LinearProgress variant="determinate"
        value={progress}
        {...otherProps} />
    );
  }
}

export default NsLinearProgress;
