import React from 'react';

import { GetDetailBasicInfoStoreOrderService, GetDetailStoreOrderService } from '../../../../../../services/ProfileSetting/StoreOrderService';
import StoreOrderDetailPreviewModal from '../../../../modals/StoreOrderDetailPreview/StoreOrderDetailPreviewModal';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import NsSkeleton from '../../../../../../components/skeletons/NsSkeleton';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import NsLabelBox from '../../../../../../components/labels/NsLabelBox';
import { createStoreOrderDetaiColumns } from './UpBaseStoreOrderHelper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../../configs/Enumerations';
import AgencyInfoModal from '../../../../modals/AgencyInfoModal';
import DateHelper from '../../../../../../utilities/DateHelper';
import NsButton from '../../../../../../components/buttons/NsButton';
import HistoryModal from '../../../../modals/HistoryModal';

class UpBaseStoreOrderDetailGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItemDetailId: '',
            basicInfo: {},
            basicInfoLoading: false,
            showOrderDetailPreviewModal: false,
            showAgencyInfoModal: false,
            showStatusHistoryModal: false,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => !!this.props.orderId && this.getOrderInfo();

    getOrderInfo = () => {
        this.setState({ basicInfoLoading: true });
        GetDetailBasicInfoStoreOrderService({ orderId: this.props.orderId }, this.getOrderBasicInfoCallback);
    }

    getOrderBasicInfoCallback = (resultData, result, resultMessageClass) => {
        this.setState({ basicInfoLoading: false });
        if (!result?.data?.hasError) {
            this.setState({ basicInfo: resultData });
            CommonHelper.safeFunctionCall(this.props.orderDataLoaded, resultData);
        } else
            this.setState({ resultMessageClass });
    }

    orderDetailPreviewClicked = (id) => this.setState({ selectedItemDetailId: id, showOrderDetailPreviewModal: true });

    closeOrderDetailPreviewModal = () => this.setState({ showOrderDetailPreviewModal: false });

    agencyClicked = () => this.setState({ showAgencyInfoModal: true });

    closeAgencyModal = () => this.setState({ showAgencyInfoModal: false });

    statusHistoryClicked = () => this.setState({ showStatusHistoryModal: true });

    render() {
        const { showOrderDetailPreviewModal, showStatusHistoryModal, isLoading, columns, rows, basicInfo, selectedItemDetailId, basicInfoLoading, showAgencyInfoModal } = this.state;
        const { orderId } = this.props;
        return (
            <div className='h-100'>
                <div className='mb-3'>
                    <NsButton label='profile.actionButton.statusHistory'
                        size='small'
                        startIcon='ns-icon-delivery-request'
                        className='secondary-outline w-auto'
                        onClick={this.statusHistoryClicked} />
                </div>

                <div className='mb-2'>
                    {basicInfoLoading ?
                        CommonHelper.createArray(4).map((item, index) =>
                            <div key={index} className='d-inline-block w-25 px-1'>
                                <NsSkeleton isVisible={true}
                                    variant={[
                                        Enumerations.skeleton.rect,
                                        Enumerations.skeleton.rect,
                                        Enumerations.skeleton.rect,
                                    ]} />
                            </div>
                        )
                        :
                        <BasicInfoLabelBox basicInfo={basicInfo}
                            basicInfoLoading={basicInfoLoading}
                            agencyClicked={this.agencyClicked} />
                    }
                </div>

                <NsDataGridWithServiceCall service={GetDetailStoreOrderService}
                    parameters={{ id: orderId }}
                    columnDefs={createStoreOrderDetaiColumns(this.orderDetailPreviewClicked)}
                    getData={this.props.getData}
                    gridId={GridEnumerations.siteGrids.storeOrderDetail}
                    hasCustomColumnOrder={true}
                    hasPagination={true} />

                {showOrderDetailPreviewModal && <StoreOrderDetailPreviewModal orderId={selectedItemDetailId}
                    closeModal={this.closeOrderDetailPreviewModal} />
                }

                {showAgencyInfoModal && <AgencyInfoModal agencyId={basicInfo?.agencyId}
                    closeModal={this.closeAgencyModal} />
                }

                {showStatusHistoryModal && <HistoryModal showModal={true}
                    orderId={orderId}
                    isStoreHistory={true}
                    closeModal={() => this.setState({ showStatusHistoryModal: false })} />
                }

                {this.state.resultMessageClass}
            </div>
        );
    }
}

function BasicInfoLabelBox(props) {
    const { status, nasInvoiceId, businessPartnerCustomerIdentifier, paymentInAdvance, priceInclusiveOfVat, totalPrice, agencyName,
        submitTime, discount, cancellationCode } = props.basicInfo;
    return (
        <div className='d-flex flex-wrap gap-2'>
            {!!status &&
                <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.status')}
                    value={status} />
            }

            {!!nasInvoiceId &&
                <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.invoiceNumber')}
                    value={nasInvoiceId} />
            }

            <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.submitTime')}
                value={DateHelper.getDateAndTime(submitTime)} />

            <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.totalPrice')}
                value={CommonHelper.currencyFormatByDefaultConfig(totalPrice)} />

            <NsLabelBox label={getTranslatedRawText('regularOrder.header.discount')}
                value={CommonHelper.currencyFormatByDefaultConfig(discount)} />

            <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.priceInclusiveOfVat')}
                value={CommonHelper.currencyFormatByDefaultConfig(priceInclusiveOfVat)} />

            <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.paymentInAdvance')}
                value={CommonHelper.currencyFormatByDefaultConfig(paymentInAdvance)} />

            {!!cancellationCode && <NsLabelBox label={getTranslatedRawText('regularOrder.header.cancellationCode')}
                value={cancellationCode} />}

            {!!agencyName && <NsLabelBox label={getTranslatedRawText('regularOrder.header.agency')}
                className='cursor-pointer'
                value={<i className='ns-icon-expand-left' >{agencyName}</i>}
                onClick={props.agencyClicked} />}

            {!!businessPartnerCustomerIdentifier && <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier')}
                value={businessPartnerCustomerIdentifier} />}

        </div>
    );
}

export default UpBaseStoreOrderDetailGrid;