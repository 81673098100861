import React, { useEffect, useState } from 'react';

import { GetCityNamesService, GetCountryNamesService, GetStateNamesService } from '../../../../../services/common/CommonService';
import { GetAgencyListService } from '../../../../../services/adminPanel/AdminAgenciesService';
import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../../bases/ApBaseSearchButtons';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';

const ApCustomerUserSearch = (props) => {

    const [mobile, setMobile] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [accountTypes, setAccountTypes] = useState([]);
    const [roles, setRoles] = useState([]);
    const [detailLedger, setDetailLedger] = useState('');
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [accountStatus, setAccountStatus] = useState(null);
    const [agreementStatus, setAgreementStatus] = useState(null);
    const [idNumber, setIdNumber] = useState('');
    const [registrationTimeFrom, setRegistrationTimeFrom] = useState(null);
    const [registrationTimeTo, setRegistrationTimeTo] = useState(null);
    const [mobileActivations, setMobileActivations] = useState([]);
    const [dateOfBirthFrom, setDateOfBirthFrom] = useState(null);
    const [dateOfBirthTo, setDateOfBirthTo] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [countryNames, setCountryNames] = useState([]);
    const [stateNames, setStateNames] = useState([]);
    const [cityNames, setCityNames] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState('');
    const [licenceNumber, setLicenceNumber] = useState('');
    const [trn, setTrn] = useState('');

    const basicSearchOptionsInMobile = [
        {
            type: Enumerations.advanceSearchFieldType.mobileInput,
            label: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
            fieldName: 'mobile',
            value: mobile,
            onChange: (value) => setMobile(value),
        }
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('account.signup.firstName'),
            fieldName: 'name',
            value: name,
            onChange: (value) => setName(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('account.signup.lastName'),
            fieldName: 'lastName',
            value: lastName,
            onChange: (value) => setLastName(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.idNumber'),
            fieldName: 'idNumber',
            value: idNumber,
            onChange: (value) => setIdNumber(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.numberInput,
            label: getTranslatedRawText('components.advanceSearch.detailLedger'),
            fieldName: 'detailLedger',
            value: detailLedger,
            onChange: (value) => setDetailLedger(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('adminPanel.dataGridColumn.companyName'),
            fieldName: 'companyName',
            value: companyName,
            onChange: (value) => setCompanyName(value)
        },
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.accountType.accountType'),
            fieldName: 'accountTypes',
            value: accountTypes,
            items: ConstantsWithTranslation.getAccountType(),
            onChange: (value) => setAccountTypes(value)
        },       
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('account.signup.licenseNumber'),
            fieldName: 'licenceNumber',
            value: licenceNumber,
            onChange: (value) => setLicenceNumber(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('account.signup.trn'),
            fieldName: 'trn',
            value: trn,
            onChange: (value) => setTrn(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.usersInfo.agency'),
            fieldName: 'selectedAgency',
            value: selectedAgency,
            items: !isEmpty(agencies) ? agencies : [],
            onChange: (value) => setSelectedAgency(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.country.country'),
            fieldName: 'selectedCountry',
            value: selectedCountry,
            items: countryNames || [],
            onChange: (value) => setSelectedCountry(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.state.state'),
            fieldName: 'selectedState',
            value: selectedState,
            items: stateNames || [],
            disabled: isEmpty(selectedCountry),
            onChange: (value) => setSelectedState(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.city.city'),
            fieldName: 'selectedCity',
            value: selectedCity,
            items: cityNames || [],
            disabled: isEmpty(selectedState),
            onChange: (value) => setSelectedCity(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.mobileActivation'),
            fieldName: 'mobileActivations',
            value: mobileActivations,
            items: ConstantsWithTranslation.getMobileActivation(),
            onChange: (value) => setMobileActivations(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.accountStatus'),
            fieldName: 'accountStatus',
            value: accountStatus,
            items: ConstantsWithTranslation.getAccountStatus(),
            onChange: (value) => setAccountStatus(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.role'),
            fieldName: 'roles',
            value: roles,
            items: ConstantsWithTranslation.getCustomersRole(),
            onChange: (value) => setRoles(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.agreementStatus'),
            fieldName: 'agreementStatus',
            value: agreementStatus,
            items: ConstantsWithTranslation.getAgreementStatus(),
            onChange: (value) => setAgreementStatus(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.registrationTimeFrom'),
            fieldName: 'registionTimeFrom',
            value: registrationTimeFrom,
            onChange: (value) => setRegistrationTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.registrationTimeTo'),
            fieldName: 'registionTimeTo',
            value: registrationTimeTo,
            onChange: (value) => setRegistrationTimeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.dateOfBirthFrom'),
            fieldName: 'dateOfBirthFrom',
            value: dateOfBirthFrom,
            onChange: (value) => setDateOfBirthFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('components.advanceSearch.dateOfBirthTo'),
            fieldName: 'dateOfBirthTo',
            value: dateOfBirthTo,
            onChange: (value) => setDateOfBirthTo(value)
        },
    ];

    const searchValues = {
        mobile,
        name,
        lastName,
        companyName,
        accountTypes,
        roles,
        detailLedger,
        selectedCountry,
        selectedState,
        selectedCity,
        accountStatus,
        idNumber,
        selectedAgency,
        registionTimeFrom: registrationTimeFrom,
        registionTimeTo: registrationTimeTo,
        mobileActivations,
        dateOfBirthFrom,
        dateOfBirthTo,
        agreementStatus,
        licenceNumber,
        trn
    }

    const searchClicked = () => {
        let data = {
            mobile,
            name,
            lastName,
            companyName,
            accountTypes,
            roles,
            detailLedger,
            selectedCountry,
            selectedState,
            selectedCity,
            accountStatus,
            idNumber,
            selectedAgency,
            registionTimeFrom: registrationTimeFrom,
            registionTimeTo: registrationTimeTo,
            mobileActivations,
            dateOfBirthFrom,
            dateOfBirthTo,
            agreementStatus,
            licenceNumber,
            trn
        };
        CommonHelper.safeFunctionCall(props.setSearchData, data);
    }

    const clearButtonClicked = () => {
        setMobile('');
        setName('');
        setLastName('');
        setIdNumber('');
        setCompanyName('');
        setAccountStatus(null);
        setRoles([]);
        setRegistrationTimeFrom(null);
        setRegistrationTimeTo(null);
        setAccountTypes([]);
        setDetailLedger('');
        setSelectedAgency(null);
        setMobileActivations([]);
        setDateOfBirthFrom(null);
        setDateOfBirthTo(null);
        setSelectedCountry([]);
        setSelectedState([]);
        setSelectedCity([]);
        setAgreementStatus(null);
        setLicenceNumber('');
        setTrn('');
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    const showMoreClicked = () => setShowMore(!showMore);

    useEffect(() => {
        GetCountryNamesService(getCountryNamesCallback);
        GetAgencyListService({}, getAgencyNameListCallback);
    }, [])

    const getAgencyNameListCallback = (resultData, result, messageClassModal) => {
        if (result?.data?.hasError) {
            setResultMessageClass(messageClassModal);
        } else {
            setAgencies(resultData);
        }
    }

    useEffect(() => {
        setSelectedCity([]);
    }, [selectedState])

    useEffect(() => {
        setSelectedCity([]);
        setSelectedState([]);
    }, [selectedCountry])

    useEffect(() => {
        !!selectedCountry?.key && GetStateNamesService(selectedCountry?.key, getStateNamesCallback);
        setSelectedCity();
        setCityNames();
        setSelectedState();
        setStateNames();
    }, [selectedCountry?.key])

    useEffect(() => {
        !!selectedState?.key && GetCityNamesService(selectedState?.key, getCityNamesCallback);
    }, [selectedState?.key])

    const getCountryNamesCallback = (resultData, result, messageClassModal) => {
        if (!result?.hasError)
            setCountryNames(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const getStateNamesCallback = (resultData, result, messageClassModal) => {
        if (!result?.hasError)
            setStateNames(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const getCityNamesCallback = (resultData, result, messageClassModal) => {
        if (!result?.hasError)
            setCityNames(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    return (
        <div onKeyDown={handleKeyDown}>
            <NsAdvanceSearch searchOptions={showMore ? advanceSearchOptions : CommonHelper.isMobileDevice() ? basicSearchOptionsInMobile : basicSearchOptions}
                searchValues={searchValues} mdCol={2} smCol={4} />

            <ApBaseSearchButtons 
                searchClicked={searchClicked}
                showMoreHandler={showMoreClicked}
                showMore={showMore}
                isLoading={props.isLoading}
                clearButtonClicked={clearButtonClicked} />

            {resultMessageClass}
        </div>
    );
};

export default React.memo(ApCustomerUserSearch);