import clsx from 'clsx';
import React from 'react';
import '../../../../assets/sass/components/dragFiles/NsDragFiles.scss';

import { ThumbnailPreview } from '../../regularOrders/bases/OrderThumbnailGroupPreview';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsTypography from '../../../../components/text/NsTypography';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import { connect } from 'react-redux';

class PoBaseSelectFile extends React.Component {

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.content = null;
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (!isEmpty(newProps.currentFileContent) && newProps.currentFileContent !== this.props.currentFileContent)
            CommonHelper.safeFunctionCall(this.props.showSelectingFileTour);
    }

    allowDrop = (ev) => ev.preventDefault();

    drop = (ev) => {
        ev.preventDefault();
        let data = ev.dataTransfer.getData("text");
        CommonHelper.safeFunctionCall(this.props.fileDropped, JSON.parse(data));
    }

    selectFileClicked = () => CommonHelper.safeFunctionCall(this.props.selectFileClicked);


    imageClicked = () => CommonHelper.safeFunctionCall(this.props.imageClicked);

    dragFileClicked = () => {
        !CommonHelper.isMobileDevice() && !isEmpty(this.props.currentFileContent) &&
            CommonHelper.safeFunctionCall(this.props.fileDropped, this.props.currentFileContent);
    }

    render() {
        const { title, thumbnail } = this.props;
        const isCursorPointer = !CommonHelper.isMobileDevice() && !isEmpty(this.props.currentFileContent);

        return (
            <div className={clsx('d-flex flex-column justify-content-center align-items-center border rounded p-2 my-1 professionalOrder-tour-choosingFileItem', !isEmpty(thumbnail) ? 'background-disable-color' : ' background-element-color')}
                onDrop={this.drop} onDragOver={this.allowDrop}>

                {!isEmpty(thumbnail)
                    ? <div>
                        <ThumbnailPreview showDefaultImage={false}
                            className='cursor-pointer'
                            imageClicked={this.imageClicked}
                            thumbnail={thumbnail}
                            isUsedInPo={true}
                            width={'67px'}
                            height={'67px'} />
                    </div>
                    : <div className={clsx('background-white-color rounded text-center py-3 mb-2', isCursorPointer && 'cursor-pointer')} style={{ width: '67px', height: '67px' }}
                        onClick={this.dragFileClicked}>
                        {CommonHelper.isMobileDevice()
                            ? <NsButton label='regularOrder.common.clickHere'
                                size='small'
                                className='secondary-text text-nowrap'
                                onClick={this.selectFileClicked} />
                            : <NsTypography variant='inherit' className={clsx('red-color font-size-10 my-2', isCursorPointer && 'cursor-pointer')}>
                                {getTranslatedRawText('professionalOrder.choosingFile.dragHere')}
                            </NsTypography>
                        }
                    </div>
                }
                <NsTypography variant='inherit' className='font-size-10'>
                    {getTranslatedRawText(title)}
                </NsTypography>
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        currentFileContent: state.fileManager.currentFileContent
    }
}

export default connect(mapStateToProps)(PoBaseSelectFile);