import React, { useEffect, useState } from 'react';

import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import NsButton from '../../../../../components/buttons/NsButton';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { GetApDepartments } from '../../../../../services/adminPanel/AdminDepartmentService';
import { GetProductsCirculationsService } from "../../../../../services/product/ProductService";
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import ApBaseOrderSelectModal from '../../../bases/ApBaseOrderSelectModal';
import ApBaseSearchButtons from '../../../bases/ApBaseSearchButtons';
import ApBaseSupporterSelectModal from '../../../bases/ApBaseSupporterSelectModal';
import ApBaseUserSelectModal from '../../../bases/ApBaseUserSelectModal';

const ApFeedbackUsersSearch = (props) => {

    const [showUserSelectModal, setShowUserSelectModal] = useState(false)
    const [showOrderSelectModal, setShowOrderSelectModal] = useState(false);
    const [showUserSupporterSelectModal, setShowUserSupporterSelectModal] = useState();
    const [user, setUser] = useState(props.customerDetails?.phoneNumber || '');
    const [userSupporter, setUserSupporter] = useState('');
    const [userDepartment, setUserDepartment] = useState('');
    const [departments, setDepartments] = useState('')
    const [selectedUserSupporter, setSelectedUserSupporter] = useState('');
    const [selectedOrder, setSelectedOrder] = useState('');
    const [selectedSharedSheetOrderIds, setSelectedSharedSheetOrderIds] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsId, setSelectedProductsId] = useState([]);
    const [selectedCirculations, setSelectedCirculations] = useState([]);
    const [displayToAll, setDisplayToAll] = useState([]);
    const [averageRateFrom, setAverageRateFrom] = useState([]);
    const [averageRateTo, setAverageRateTo] = useState([]);
    const [submitTimeFrom, setSubmitTimeFrom] = useState(null);
    const [submitTimeTo, setSubmitTimeTo] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [selectedUser, setSelectedUser] = useState(props.customerDetails || '');
    const [demandService, setDemandService] = useState(null);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState('');

    const showOrderSelectModalToggle = () => setShowOrderSelectModal(!showOrderSelectModal);
    const showUseSupporterSelectModalToggle = () => setShowUserSupporterSelectModal(!showUserSupporterSelectModal);

    useEffect(() => {
        GetApDepartments(getApDepartmentsCallback);
    }, []);

    const getApDepartmentsCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            setDepartments(resultData)
        } else
            setResultMessageClass(messageClassModal);
    }

    useEffect(() => {
        !isEmpty(selectedUser) && searchClicked();
    }, [selectedUser])

    useEffect(() => {
        !isEmpty(selectedOrder) && searchClicked();
    }, [selectedOrder])

    useEffect(() => {
        !isEmpty(selectedUserSupporter) && searchClicked();
    }, [selectedUserSupporter])

    useEffect(() => {
        !isEmpty(selectedProducts)
            ? setSelectedProductsId(selectedProducts.circulations.map(circulation => circulation.key))
            : setSelectedProductsId([]);
    }, [selectedProducts])

    const getProductsCirculationsCallback = (resultData, result) => {
        setIsLoading(false);
        if (!result?.data?.hasError) {
            setProducts(resultData);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        GetProductsCirculationsService(null, getProductsCirculationsCallback);
    }, [])

    const usersModalButtonClicked = () => {
        setShowUserSelectModal(true);
    }

    const userSupporterModalButtonClicked = () => {
        setShowUserSupporterSelectModal(true);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptionsInMobile = [
        !props.customerDetails?.phoneNumber && hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.feedbackGiver'),
            fieldName: 'user',
            value: user,
            disabled: true,
            onChange: (value) => setUser(value),
            endAdornment: !props.customerDetails?.phoneNumber ? <NsButton className='light-circle'
                loading={isLoading}
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" /> : null
        },
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        hasAccess([UserClaimEnumerations.getApSupporters]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('adminPanel.dataGridColumn.employee'),
            fieldName: 'userSupporter',
            value: userSupporter,
            disabled: true,
            onChange: (value) => setUserSupporter(value),
            endAdornment: <NsButton className='light-circle'
                loading={isLoading}
                onClick={userSupporterModalButtonClicked}
                startIcon="ns-icon-more" />
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.departments'),
            fieldName: 'userDepartment',
            value: userDepartment,
            items: departments || [],
            onChange: (value) => setUserDepartment(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.averageRateFrom'),
            fieldName: 'averageRateFrom',
            value: averageRateFrom,
            items: ConstantsWithTranslation.getFeedbackRates(),
            onChange: (value) => setAverageRateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.averageRateTo'),
            fieldName: 'averageRateTo',
            value: averageRateTo,
            items: ConstantsWithTranslation.getFeedbackRates(),
            onChange: (value) => setAverageRateTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.feedback.demandService'),
            fieldName: 'demandService',
            value: demandService,
            items: ConstantsWithTranslation.getFeedbackDemandServiceType(),
            onChange: (value) => setDemandService(value)
        },
    ];

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeFrom'),
            fieldName: 'submitTimeFrom',
            value: submitTimeFrom,
            onChange: (value) => setSubmitTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('components.advanceSearch.submitTimeTo'),
            fieldName: 'submitTimeTo',
            value: submitTimeTo,
            onChange: (value) => setSubmitTimeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.dataGridColumn.displaytoAll'),
            fieldName: 'displayToAll',
            value: displayToAll,
            items: ConstantsWithTranslation.getUseFileInOrderOptions(),
            onChange: (value) => setDisplayToAll(value)
        },
    ];

    const searchValues = {
        user,
        selectedUser,
        selectedUserSupporter,
        userSupporter,
        selectedOrder,
        userDepartment,
        selectedSharedSheetOrderIds,
        products,
        selectedProducts,
        selectedProductsId,
        selectedCirculations,
        averageRateFrom,
        averageRateTo,
        submitTimeFrom,
        submitTimeTo,
        demandService,
        displayToAll,
    }

    const showMoreClicked = () => setShowMore(!showMore);

    const searchClicked = () => CommonHelper.safeFunctionCall(props.setSearchData, searchValues);

    const clearButtonClicked = () => {
        setUser('');
        setSelectedUser('');
        setSelectedUserSupporter('');
        setUserSupporter('');
        setSelectedOrder('');
        setUserDepartment('');
        setSelectedSharedSheetOrderIds([]);
        setSelectedProducts([]);
        setSelectedProductsId([]);
        setAverageRateFrom([]);
        setAverageRateTo([]);
        setSelectedCirculations([]);
        setSubmitTimeFrom(null);
        setSubmitTimeTo(null);
        setDemandService(null);
        setDisplayToAll(null);
        CommonHelper.safeFunctionCall(props.setSearchData, {});
    }

    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user?.phoneNumber);
        setShowUserSelectModal(false);
    }

    const userSupporterSelected = (supporter) => {
        setSelectedUserSupporter(supporter);
        setUserSupporter(supporter?.fullName);
        showUseSupporterSelectModalToggle();
    };

    const orderSelected = (order) => {
        setSelectedOrder(order?.productName);
        setSelectedSharedSheetOrderIds([order?.id]);
        showOrderSelectModalToggle();
    };

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={showMore
                    ? advanceSearchOptions
                    : CommonHelper.isMobileDevice()
                        ? basicSearchOptionsInMobile
                        : basicSearchOptions}
                    searchValues={searchValues} mdCol={2} smCol={4} />
                <ApBaseSearchButtons searchClicked={searchClicked}
                    showMoreHandler={showMoreClicked}
                    showMore={showMore}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={props.isLoading} />
            </div>

            {showUserSelectModal &&
                <ApBaseUserSelectModal showModal={showUserSelectModal}
                    userSelected={userSelected}
                    closeModal={() => setShowUserSelectModal(false)} />
            }
            {showOrderSelectModal &&
                <ApBaseOrderSelectModal showModal={showOrderSelectModal}
                    orderSelected={orderSelected}
                    closeModal={() => setShowOrderSelectModal()} />
            }
            {showUserSupporterSelectModal &&
                <ApBaseSupporterSelectModal showModal={showUserSupporterSelectModal}
                    adminSelected={userSupporterSelected}
                    closeModal={() => setShowUserSupporterSelectModal(false)} />
            }


            {resultMessageClass}
        </>
    )
}

export default React.memo(ApFeedbackUsersSearch);