import React from 'react';
import { Avatar } from '@mui/material';

const NsAvatar = (props) => {

    const { name, ...otherProps } = props;

    function stringAvatar(name) {
        if (!name) return;
        return {
            sx: {
                bgcolor: '#bdbdbd',
            },
            children: `${name.split(' ')[0][0]} ${name.split(' ')[1][0]}`,
        };
    }

    return (
        <Avatar {...otherProps} {...stringAvatar(name)} />
    );
}

export default React.memo(NsAvatar);