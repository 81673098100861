import clsx from 'clsx';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import NsCard from '../../../components/cards/NsCard';
import NsHtmlToReact from '../../../components/htmlToReact/NsHtmlToReact';
import NsTab from '../../../components/tabs/NsTab';
import { isEmpty } from '../../../utilities/ValidationHelper';

class ProductCategoryInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoriesDescription: '',
            categoriesDescriptionTabs: [],
            selectedTab: null,
            selectedTabIndex: null,
            resultMessageClass: <></>
        }
    }

    getCategoryInfoCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.setState({
                categoriesDescription: resultData
            })
        } else
            this.setState({ resultMessageClass: messageClassModal })

    }

    getCategoryDescription = () => {
        const selectedCategoriesObject = Object.fromEntries(
            this.props.selectedCategories.map((value, index) => [
                `model[${index}]`,
                value,
            ])
        );
        this.props.service(selectedCategoriesObject, this.getCategoryInfoCallback);
    }

    categoriesDescriptionTabsHandler = (categories) => {
        categories.forEach((item) => {
            const newObj = {
                id: item.id,
                label: item.name,
                description: item.description,
            };
            this.setState((prevState) => ({ categoriesDescriptionTabs: [...prevState.categoriesDescriptionTabs, newObj] }));
        });
    }

    tabChanged = (selectedTabIndex, selectedTab) => this.setState({ selectedTabIndex, selectedTab });

    contentHandler = (content, label) => {
        if (isEmpty(content)) return;
        return (
            <div className={clsx("font-size-14 p-2", label !== this.state.selectedTab?.label && 'd-none')}>
                <NsHtmlToReact>
                    {content}
                </NsHtmlToReact>
            </div>
        );
    }

    componentDidMount() {
        this.getCategoryDescription();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.categoriesDescription?.length !== prevState.categoriesDescription?.length && this.state.categoriesDescription?.length > 0)
            this.categoriesDescriptionTabsHandler(this.state.categoriesDescription)
    }

    render() {
        const { wrapperClassName } = this.props;
        const { categoriesDescription, resultMessageClass, categoriesDescriptionTabs, selectedTabIndex, selectedTab } = this.state;
        const shouldRenderWrapper = categoriesDescription.length > 0 && categoriesDescription.every(item => isEmpty(item.description));

        return (
            shouldRenderWrapper ? null : <NsCard className='mt-3 p-2'>
                <NsTab tabs={categoriesDescriptionTabs}
                    onChange={this.tabChanged}
                    value={selectedTabIndex}
                    selectedTab={selectedTab}
                />
                {categoriesDescriptionTabs.map(category =>
                    this.contentHandler(category?.description, category?.label)
                )}
            </NsCard>
        );
    }

}

export default withRouter(ProductCategoryInfo)