import React from "react";
import { Row } from "react-bootstrap";

import { FAQList } from "../../singlePages/mainSections/frequentlyAskedQuestions/FrequentlyAskedQuestions";
import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../utilities/CultureHelper";
import NsLabelLine from "../../../components/labels/NsLabelLine";
import Links from "../../../configs/links/AllLinks";
import ShoppingBagWrapper from "./ShoppingBagWrapper";

class ShoppingBagFaqs extends React.Component {
    constructor(props) {
        super(props);
        this.shortFaqs = [
            {
                id: 1,
                isTitleVisible: true,
                mainContent: 'We offer a choice between classic white and natural brown as color options for our shopping bags. You can select the one that best aligns with your branding or preferences.',
                title: 'What color options are available for paper shopping bags?'
            },
            {
                id: 2,
                isTitleVisible: true,
                mainContent: "Virgin and recycled shopping bags each have their unique characteristics. Virgin bags, made from new, unused materials, offer exceptional quality and are ideal for packaging delicate items like pastries or confectionery. In contrast, recycled bags, crafted from post-consumer recycled content, are an excellent eco-friendly choice for packaging non-food items such as clothing, accessories, or other retail products. We provide both options to cater to your specific needs, whether it's pristine packaging for delicate goods or sustainable choices for everyday groceries.",
                title: 'What is the difference between virgin shopping bags and recycled shopping bags?'
            },
            {
                id: 3,
                isTitleVisible: true,
                mainContent: "Certainly, our paper bags are designed for reusability. You can utilize them for multiple shopping trips, making them an environmentally responsible choice and helping reduce the demand for single-use plastic bags.",
                title: "Can the paper bag be reused?"
            },
            {
                id: 4,
                isTitleVisible: true,
                mainContent: "Quality assurance is a top priority at Diamond Printing Press. Our paper bags are crafted with precision and attention to detail. We ensure quality through the following measures:High-Quality Materials: Our paper bags are made from premium materials, ensuring their strength, durability, and reliability.Stringent Quality Checks: Each bag undergoes a rigorous quality control process to identify and address any imperfections or defects.Craftsmanship: Our skilled artisans meticulously design and assemble each bag to meet our stringent quality standards.Eco-Friendly Materials: We prioritize sustainability by using biodegradable kraft paper for our bags.",
                title: "How can I ensure the durability and quality of printed paper bags?"
            }];
        this.faqs = [
            ...this.shortFaqs,
            {
                id: 5,
                isTitleVisible: true,
                mainContent: 'Our paper shopping bags can be personalized with your unique designs, logos, and messages. We offer high-quality printing services to help you create customized shopping bags that represent your brand effectively.',
                title: 'Can I customize the design and printing on Paper Bags?'
            },
            {
                id: 6,
                isTitleVisible: true,
                mainContent: 'Diamond Printing Press proudly serves customers across various cities in the United Arab Emirates, including Dubai, Abu Dhabi, Sharjah, Ajman, Ras Al Khaimah, Fujairah, and Umm al-Quwain. Our reach extends throughout the UAE to ensure that you can easily access our products and services.',
                title: 'In which cities do you offer your products and services in the United Arab Emirates (UAE)?',
            },
            {
                id: 7,
                isTitleVisible: true,
                mainContent: "For custom sizes, we recommend reaching out to us directly. Unfortunately, we don't have the option to order custom sizes online. Feel free to contact our team, and we'll assist you in placing orders for your customized products.",
                title: 'Are customized sizes available for online products?'
            },
            {
                id: 8,
                isTitleVisible: true,
                mainContent: "Placing a custom order for brown paper bags is simple. You can specify the dimensions, color, handle type, printing preferences, and quantity. We offer the flexibility to create bags that align with your brand's identity and requirements. Contact us with your specifications, and we will provide you with a custom quote and start the process to bring your unique kraft paper bags to life.",
                title: 'How Can I Place a Custom Order for Paper Bag?'
            },
            {
                id: 9,
                isTitleVisible: true,
                mainContent: "The processing and shipping time for paper bag paper bag orders may vary depending on the quantity and customization requirements. Please contact our customer service for estimated delivery times.",
                title: "How long does it take to process and ship an order of kraft paper bags?"
            },
            {
                id: 10,
                isTitleVisible: true,
                mainContent: "A top fold, in the context of a Paper Bags, is the fold at the bag's opening. This feature not only adds structural reinforcement but also enhances the bag's ability to carry heavier items. The top fold is a functional element that ensures durability and reliability in your paper shopping bags. It is particularly useful when you need to transport bulky or heavy items, making it an ideal choice for grocery shopping, retail purchases, and more.",
                title: "What Is a Top Fold in a Paper Bags?"
            },
            {
                id: 11,
                isTitleVisible: true,
                mainContent: "Yes, kraft shopping bags are eco-friendly because they are made from renewable resources and are biodegradable. They are a greener alternative to plastic bags.",
                title: "Are kraft shopping bags eco-friendly?"
            },
            {
                id: 12,
                isTitleVisible: true,
                mainContent: "The paper thickness, indicated by GSM (Grams per Square Meter), is a crucial factor that influences the strength and durability of a shopping bag. Higher GSM values signify thicker and more robust paper, enhancing the bag's capacity to carry items and ensuring its long-lasting resilience.",
                title: "How does paper thickness (GSM) affect the strength of a shopping bag?"
            },
            {
                id: 13,
                isTitleVisible: true,
                mainContent: "A gusset in a shopping bag is an expandable section that adds extra space and width to the bag. It plays a vital role in enabling the bag to accommodate larger or bulkier items, making it a versatile and practical choice for various shopping needs.",
                title: "What is the significance of the gusset in a shopping bag?"
            },
            {
                id: 14,
                isTitleVisible: true,
                mainContent: "Our customer support team is easily reachable through various communication channels. You can get in touch with us via phone, or email. We're dedicated to providing prompt and helpful assistance with any questions or concerns you may have.",
                title: "How can I contact customer support for further assistance or inquiries?"
            },
            {
                id: 15,
                isTitleVisible: true,
                mainContent: 'Yes, you can choose from our available products, including Kraft shopping bags, and conveniently place your order online at Diamond Printing Press.',
                title: 'Can I conveniently order your Kraft shopping bags online?'
            },
            {
                id: 16,
                isTitleVisible: true,
                mainContent: "Our paper gift bags are eco-friendly and sustainable. They are crafted from renewable kraft paper, contributing to a reduced environmental footprint. Our commitment to sustainability extends to our product materials.",
                title: "Are the paper gift bags sustainable?"
            },
            {
                id: 17,
                isTitleVisible: true,
                mainContent: 'Diamond Press provides two handle types: Flat Handle and Twisted Handle. You can choose the handle style that suits your needs and enhances the overall appeal of your bags.',
                title: 'What are the handle options for paper bags?'
            },
            {
                id: 18,
                isTitleVisible: true,
                mainContent: "The minimum order quantity for our customized shopping bags varies depending on the product and your specific requirements. You can find the details for each product in the respective product listings.",
                title: "What is the minimum order quantity for customized kraft shopping bags?"
            },
            {
                id: 19,
                isTitleVisible: true,
                mainContent: "Yes, we offer samples of our brown paper bags so you can evaluate the quality and design before placing a bulk order. Contact our customer service to request samples.",
                title: "Can I get samples of your brown paper bags before placing a bulk order?"
            },
            {
                id: 20,
                isTitleVisible: true,
                mainContent: "No, we currently do not offer design services for custom printing on white paper bags. However, you can provide your own artwork or design for printing.",
                title: "Do you offer design services for custom printing on white paper bags?"
            },
            {
                id: 21,
                isTitleVisible: true,
                mainContent: "We are pleased to extend our offerings not only within the United Arab Emirates but also to Oman, including the bustling cities of Muscat and beyond. Our commitment spans these areas, ensuring that a wide spectrum of customers and businesses can benefit from our products and services.",
                title: "Which Regions Do We Cover?"
            },
            {
                id: 22,
                isTitleVisible: true,
                mainContent: "Our kraft paper bags are designed to be sturdy and reliable, making them suitable for carrying a variety of items, including heavier or bulkier objects.",
                title: "Are your kraft paper bags suitable for heavy or bulky items?"
            },
            {
                id: 23,
                isTitleVisible: true,
                mainContent: "We offer various printing options, including single-color, multi-color, and full-color printing, to help you achieve the desired look for your kraft bags.",
                title: "What printing options are available for customizing kraft bags?"
            },
            {
                id: 24,
                isTitleVisible: true,
                mainContent: "We offer different packaging options for shipping, including shrink film, carton packaging, or a combination of both. You can select the packaging method that best suits your needs.",
                title: "How is the brown bag paper packaged for delivery?"
            },
            {
                id: 25,
                isTitleVisible: true,
                mainContent: "",
                title: "What is a shopping bag also called?"
            },
            {
                id: 26,
                isTitleVisible: true,
                mainContent: "",
                title: "What is a shopping bag a bag which is for?"
            },
            {
                id: 27,
                isTitleVisible: true,
                mainContent: "",
                title: "What is a shopper bag?"
            },
            {
                id: 28,
                isTitleVisible: true,
                mainContent: "",
                title: "How do you make a shopping bag?"
            },
            {
                id: 29,
                isTitleVisible: true,
                mainContent: "",
                title: "Is a tote bag a shopping bag?"
            },
            {
                id: 30,
                isTitleVisible: true,
                mainContent: "",
                title: "Which bag will you prefer for shopping?"
            },
            {
                id: 31,
                isTitleVisible: true,
                mainContent: "",
                title: "What is a luxury bag?"
            },
            {
                id: 32,
                isTitleVisible: true,
                mainContent: "",
                title: "How do you store shopper bags?"
            },
            {
                id: 33,
                isTitleVisible: true,
                mainContent: "",
                title: "What is a paper shopper bag?"
            },
            {
                id: 34,
                isTitleVisible: true,
                mainContent: "Brown paper bags are also often referred to as tote bags, carry bags, or reusable bags, highlighting their versatility and purpose beyond a traditional bag.",
                title: "What other names are commonly used for brown paper bags?"
            },
            {
                id: 35,
                isTitleVisible: true,
                mainContent: "A brown paper bag is designed to conveniently carry and transport purchased items, making it an essential accessory for shopping trips and daily activities.",
                title: "What purpose do brown paper bags serve?"
            },
            {
                id: 36,
                isTitleVisible: true,
                mainContent: "A shopping bag is produced through various manufacturing processes involving paper pulp, cutting, folding, and gluing. These steps contribute to creating a sturdy and functional bag for carrying items.",
                title: "How is a shopping bag manufactured?"
            },
            {
                id: 37,
                isTitleVisible: true,
                mainContent: "Yes, a tote bag is a type of kraft paper bags that typically has an open top and parallel handles, making it suitable for carrying various items during shopping.",
                title: "Can a tote bag be considered a type of kraft paper bags?"
            },
            {
                id: 38,
                isTitleVisible: true,
                mainContent: "It depends on the situation and what I'm buying. If it's a small shopping trip, I might opt for a reusable tote bag. For larger purchases or groceries, a sturdy merchandise paper bag or a recycled paper bag would be my preference, considering the environmental impact.",
                title: "What type of bag do you prefer for shopping?"
            },
            {
                id: 39,
                isTitleVisible: true,
                mainContent: "A luxury bag is not merely an accessory; it's a statement of style and craftsmanship. Renowned for their superior quality and exquisite design, luxury bags are crafted from the finest materials with meticulous attention to detail. They often bear the emblem of prestigious brands, signifying exclusivity and sophistication in the world of fashion.",
                title: "What defines a luxury bag?"
            },
            {
                id: 40,
                isTitleVisible: true,
                mainContent: "To ensure the longevity and usability of your shopper bags, store them in a cool, dry place away from direct sunlight. Consider hanging larger bags to maintain their shape, and keep them away from sharp objects to prevent snags. For reusable fabric bags, occasional washing is recommended to maintain hygiene. Storing bags in a designated space, such as a closet or pantry, will help keep them organized and ready for your next shopping trip.",
                title: "How should shopper bags be properly stored?"
            },
            {
                id: 41,
                isTitleVisible: true,
                mainContent: "A paper shopper bag is a versatile and eco-friendly alternative for carrying goods. Crafted from paper materials, it offers a sustainable solution to single-use plastic bags. These bags come in various types, including flat-bottom, merchandise, Euro Tote, and more, catering to different needs. They are often chosen for their biodegradability, recyclability, and stylish designs, making them a popular choice among environmentally conscious shoppers and businesses.",
                title: "What defines a paper shopper bag?"
            },
        ];
    }

    componentDidMount = () => !this.props.isMainFaqs && window.scrollTo({ top: 120, behavior: 'smooth' });

    renderChild = (isMainFaqs) => {
        return <>
            <NsLabelLine linkTo={getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagFaq)}
                smallSize={true}
                primaryTitle={getTranslatedRawText('product.shoppingBag.faqs')}
                viewMoreLink={isMainFaqs ? getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagFaq) : null} />
            <Row className="ustify-content-between my-3">
                <FAQList FAQArray={isMainFaqs ? this.shortFaqs : this.faqs}
                    widthCol={isMainFaqs ? 6 : 12}
                    childClassName='background-element-color p-3 rounded mb-2' />
            </Row>
        </>
    }

    render() {
        const { isMainFaqs } = this.props;

        return (isMainFaqs
            ? <div>
                {this.renderChild(isMainFaqs)}
            </div >
            : <ShoppingBagWrapper>
                {this.renderChild(isMainFaqs)}
            </ShoppingBagWrapper>
        );
    }
}

export default ShoppingBagFaqs;