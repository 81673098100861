import React, { useEffect, useState } from 'react';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import Links from '../../../configs/links/AllLinks';
import NsCard from '../../../components/cards/NsCard';
import NsSkeleton from '../../../components/skeletons/NsSkeleton';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Enumerations from '../../../configs/Enumerations';
import { GetProductsService } from '../../../services/product/ProductService';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsImage from '../../../components/images/NsImage';
import UrlHelper from '../../../utilities/UrlHelper';

const SpSidebarOurSuggestionProducts = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getProducts()
    }, [])

    const getProducts = () => {
        const parameters = {
            pageLength: 4,
            currentPageIndex: 1,
            'Filter.OrderProductType': Enumerations.orderProductType.sharedSheet,
            "Filter.productSortType": Enumerations.sortItems.random
        };
        setIsLoading(true);
        GetProductsService(parameters, getProductsCallback)
    }

    const getProductsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.data?.hasError)
            setProducts(resultData.dataItems);
        else
            setResultMessageClass(messageClassModal);
    }

    const baseLinkToProduct = (id, name) => UrlHelper.createPdRegularDetailUrl(null, id, name)

    return (
        <NsCard className='p-3'>
            <p className='fw-bold'>{getTranslatedRawText('singlePage.suggestionProducts')}</p>
            <div className='text-center'>
                {isLoading ?
                    <div className="d-inline-block w-100 p-2">
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                            ]} />
                    </div>
                    : !isEmpty(products)
                        ? products?.map(item => <div className='d-flex my-2'>
                            <div className="border rounded-3">
                                <Link to={baseLinkToProduct(item?.selectedId, item?.name)}>
                                    <NsImage width={64}
                                        imgAlt="components.imagesAlt.newsThumbnails"
                                        src={CommonHelper.createImageUrl(item?.imageUrl)} />
                                </Link>
                            </div>
                            <div className="ms-3 d-flex flex-column w-100">
                                <Link className='text-decoration-none text-color text-truncate' to={baseLinkToProduct(item?.selectedId, item?.name)}>
                                    <span className='font-size-12 d-flex flex-start mb-1 fw-bold'>
                                        {item?.name}
                                    </span>
                                </Link>
                                <div className='d-flex flex-start w-100'>{item?.printCirculations?.map((circulation, index) =>
                                    <div className='font-size-12 m-0 line-clamp line-clamp--2'>

                                        <Link className='text-decoration-none text-color line-clamp line-clamp--1' to={baseLinkToProduct(circulation?.key, item?.name)}>
                                            {circulation?.value}
                                            {item.printCirculations.length - 1 > index && <span className='mx-1'>|</span>}
                                        </Link>

                                    </div>)}
                                </div>
                            </div>
                        </div>)
                        : <div></div>
                }
            </div>
        </NsCard >
    );
};

export default SpSidebarOurSuggestionProducts;