import Links from "../../configs/links/AllLinks";
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { GetRequest } from "../bases/BaseService";

const GetAgenciesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAgencies), parameter, callback);
};

const GetAgentDetailService = (parameter, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAgencyById), id), {}, callback);
};

const GetAgencyProductsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAgencyProducts), parameter, callback);
};

const GetAgencyListService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAgencyList), parameter, callback);
};

export {
    GetAgenciesService,
    GetAgentDetailService,
    GetAgencyProductsService,
    GetAgencyListService,

}