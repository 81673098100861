import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fileManagerActionCreators } from '../../../../redux/reducers/FileManagerReducer';
import CommonHelper from '../../../../utilities/CommonHelper';
import { Col, Row } from 'react-bootstrap';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';

class FmItemFolder extends React.Component {

    constructor(props) {
        super(props);
        this.touchduration = 1000;
        this.timer = null;
        this.state = {
            content: props.content,
            selectedItemId: props.selectedItemId,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            content: newProps.content,
            selectedItemId: newProps.selectedItemId,
        });
    }

    isSelectedFolder = () => {
        return this.state.selectedItemId === this.state.content.id;
    }

    folderClicked = () => !CommonHelper.isMobileDevice() && !this.props.isUsedInPo
        ? CommonHelper.safeFunctionCall(this.props.folderClicked, this.state.content)
        : CommonHelper.safeFunctionCall(this.props.folderDoubleClicked, this.state.content);

    folderDoubleClicked = () => {
        CommonHelper.safeFunctionCall(this.props.folderDoubleClicked, this.state.content);
        this.props.setCurrentFolderId(this.state.content.id);
    }

    touchstart = () => {
        this.timer = setTimeout(() => {
            CommonHelper.safeFunctionCall(this.props.folderClicked, this.state.content)
        }, this.touchduration);
    }

    touchend = () => {
        if (this.timer)
            clearTimeout(this.timer);
    }

    render() {
        const { content } = this.state;

        return (this.props.contentStyle
            ? <Row className={clsx('m-1 cursor-pointer font-size-12 w-100',
                this.isSelectedFolder() ? 'selected-item' : 'hover-item')}
                onClick={this.folderClicked}
                onDoubleClick={this.folderDoubleClicked}>

                <Col xs={this.props.isUsedInPo ? 8 : 6} className='d-flex ps-3 '

                    onTouchStart={this.touchstart}
                    onTouchEnd={this.touchend}>

                    <div className="text-end">
                        <span className="ns-icon-folder h1 text-color" />
                    </div>

                    <div className='text-center word-break text-break'>
                        <span className=''>
                            {content.name}
                        </span>
                    </div>
                </Col>
                {!this.props.isUsedInPo && <Col xs={2} className='text-center'>{getTranslatedRawText('fileManager.common.folder')}</Col>}
                {!this.props.isUsedInPo && <Col xs={2} className='text-center'>{/*CommonHelper.convertByteToMegaByte(content.volume)} {getTranslatedRawText('common.mb')*/}</Col>}
                <Col xs={2} className='text-center'></Col>

            </Row>
            : <div className={clsx('item-width flex-grow-1 m-1 align-self-end',

                this.isSelectedFolder() ? 'selected-item' : 'hover-item')}>


                <div className='cursor-pointer m-1 d-flex flex-column '
                    onClick={this.folderClicked}
                    onDoubleClick={this.folderDoubleClicked}
                    onTouchStart={this.touchstart}
                    onTouchEnd={this.touchend}>

                    <div className="text-center item-height">
                        <span className="ns-icon-folder folder-icon text-color" />
                    </div>

                    {/* <NsTextTruncate fontSize={10}
                                textCenter={true}
                                className='black-color mt-2'>
                                {this.state.content.name}
                            </NsTextTruncate> */}
                    <div className='text-center'>
                        <span className='font-size-10 mt-2 truncate-text '>
                            {content.name}
                        </span>
                    </div>
                </div>

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(fileManagerActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(FmItemFolder);