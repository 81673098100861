import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../../../../../assets/sass/views/orders/professionalOrder.scss';

import NsImageOrderMasterFileView from '../../../../../components/images/NsImageOrderMasterFileView';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import PoFoldLineModalCustomized from './PoFoldLineModalCustomized';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import ImageHelper from '../../../../../utilities/ImageHelper';
import PoFoldLineModalPreset from './PoFoldLineModalPreset';
import NsTab from '../../../../../components/tabs/NsTab';
import NsAlertModal from '../../../../../components/modals/NsAlertModal';
import { createChechboxOptions } from '../../../modals/AddSerialModal';
import RegularOrderTours from '../../../../bases/tours/RegularOrderTours';
import CurrentUserHelper from '../../../../../utilities/CurrentUserHelper';
import NsTour from '../../../../../components/tour/NsTour';
import Config from '../../../../../configs/Config';

class PoFoldLineModal extends Component {

    constructor(props) {
        super(props);

        this.maxFoldLineNumber = props.isPerforation
            ? props.hasExtraPerforation
                ? 2 : 1
            : props.masterFiles.maximumFoldLineCount;
        this.minimumDistanceBetweenFoldLines = props.isPerforation ? Config.minDistanceBetweenPerforation : props.masterFiles.minimumDistanceBetweenTwoFoldLine;
        this.foldLineDirections = ConstantsWithTranslation.foldLineDirections();
        this.foldLineShapes = ConstantsWithTranslation.foldLineShapes();
        this.foldLineModalTabs = ConstantsWithTranslation.getFoldLineModalTabs();
        this.tourSteps = RegularOrderTours.getPerforationModalTour();
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.perforationModal);

        this.state = {
            foldLines: props.customFoldLines || [],
            foldLinesLength: !!props.customFoldLines ? props.customFoldLines?.length : 0,
            isFoldLineDirectionVertical: true,
            fileDimension: null,
            unacceptableFoldLines: [],
            outOfTrimZoneFoldLines: [],
            selectedTabIndex: Enumerations.foldLineModalTab.preset,
            selectedFoldLinePreset: props.selectedFoldLinePreset || null,
            messageModalText: null,
            ratio: null,
            isChangedFoldLines: false,
            isAlertModalOpen: false,
        }
    }

    componentDidMount = () => {
        !isEmpty(this.state.selectedFoldLinePreset) && this.setState({ selectedTabIndex: Enumerations.foldLineModalTab.preset });
        window.addEventListener('keydown', this.handleFoldLinesWithArrow);
    }

    componentWillUnmount = () => {
        removeEventListener('keydown', this.handleFoldLinesWithArrow);
    }

    setRatio = (ratio) => {
        const { masterFiles } = this.props;
        let foldLines = [...this.state.foldLines];
        !isEmpty(foldLines) && foldLines.map(foldLine => {
            foldLine.position = Math.round(ImageHelper.convertMillimeterToPixel(foldLine.distanceFromOrigin, masterFiles?.resolution) * ratio);
        });
        this.setState({
            foldLines,
            isFoldLineDirectionVertical: isEmpty(foldLines) ? true : foldLines[0]?.isVertical,
            ratio
        });
    }

    sortHandler = (array) => [...array].sort((a, b) => a.distanceFromOrigin - b.distanceFromOrigin);

    handleFoldLinesWithArrow = (e) => {
        e.preventDefault()
        const { isFoldLineDirectionVertical, foldLines } = this.state;
        const selectedFoldLinePositionIndex = foldLines.findIndex(foldLine => foldLine.isSelected);
        let selectedFoldLinePosition = foldLines.find(foldLine => foldLine.isSelected)?.distanceFromOrigin;

        if (e.keyCode === Enumerations.keyboard.rightArrow || e.keyCode === Enumerations.keyboard.downArrow)
            selectedFoldLinePosition += 1;
        if (e.keyCode === Enumerations.keyboard.leftArrow || e.keyCode === Enumerations.keyboard.upArrow)
            selectedFoldLinePosition -= 1;
        selectedFoldLinePosition && this.changeFoldLinePositionClicked(selectedFoldLinePosition, selectedFoldLinePositionIndex);
    }

    imageClicked = (event) => {
        const { selectedTabIndex } = this.state;
        if (this.maxFoldLineNumberReachedHandler()) {
            return;
        };
        if (selectedTabIndex === Enumerations.foldLineModalTab.preset) return;

        let foldLinePosition;
        const bounds = event.target.getBoundingClientRect();

        if (this.state.isFoldLineDirectionVertical)
            foldLinePosition = event.clientX - bounds.left;
        else
            foldLinePosition = event.clientY - bounds.top;

        let mmfoldLinePosition = Math.round(ImageHelper.convertPixelToMillimeter(foldLinePosition, this.props.masterFiles.resolution) / this.state.ratio);

        // this.checkFoldLineAdding(mmfoldLinePosition) && this.handleAddNewFoldLine(mmfoldLinePosition);
        this.handleAddNewFoldLine(mmfoldLinePosition);
    }

    checkFoldLineAdding = (newFoldLinePosition) => {
        const { foldLines } = this.state;
        const isAllowed = !foldLines.some(foldLine => Math.abs(foldLine.distanceFromOrigin - newFoldLinePosition) < this.minimumDistanceBetweenFoldLines + 1);
        return isAllowed;
    }

    closeClicked = () => {
        this.state.isChangedFoldLines
            ? this.setState({ isAlertModalOpen: true })
            : CommonHelper.safeFunctionCall(this.props.closeModal);
    }

    closeAlertClicked = (confirm) => {
        this.setState({ isAlertModalOpen: false });
        !!confirm && CommonHelper.safeFunctionCall(this.props.closeModal);
    }

    saveClicked = () => {
        if (isEmpty(this.state.unacceptableFoldLines) && isEmpty(this.state.outOfTrimZoneFoldLines))
            CommonHelper.safeFunctionCall(this.props.savedClicked, this.state.selectedFoldLinePreset, this.state.foldLines);
    }

    toggleLineDirectionClicked = () => {
        const { foldLines } = this.state;
        if (!isEmpty(foldLines)) {
            this.setState({ messageModalText: this.props.isPerforation ? 'common.messages.perforationDirectionError' : 'common.messages.foldLineDirectionError' });
            return;
        }
        this.setState(prevState => ({
            isFoldLineDirectionVertical: !prevState.isFoldLineDirectionVertical
        }));
    }

    addFoldLineClicked = () => {
        const { isFoldLineDirectionVertical, fileDimension } = this.state;
        let addedFoldLine = this.minimumDistanceBetweenFoldLines;
        let fileSize;

        this.setState({ selectedFoldLinePreset: null });

        if (isFoldLineDirectionVertical)
            // fileSize = fileDimension?.offsetWidth;
            fileSize = this.props.masterFiles?.width;
        else
            fileSize = this.props.masterFiles?.height;
        // fileSize = fileDimension?.offsetHeight;


        if (this.checkFoldLineAdding(this.minimumDistanceBetweenFoldLines)) {
            this.handleAddNewFoldLine(this.minimumDistanceBetweenFoldLines);
        } else {
            let maxIteration = Math.ceil(fileSize / this.minimumDistanceBetweenFoldLines);
            if (maxIteration > 20)
                maxIteration = 10;

            let i = 0;

            while (i < maxIteration &&
                !this.checkFoldLineAdding(addedFoldLine) &&
                addedFoldLine < fileSize - this.minimumDistanceBetweenFoldLines) {

                i += 1;
                addedFoldLine += this.minimumDistanceBetweenFoldLines;

                if (this.checkFoldLineAdding(addedFoldLine) && addedFoldLine < fileSize - this.minimumDistanceBetweenFoldLines)
                    this.handleAddNewFoldLine(addedFoldLine);
            }
        }
    }

    handleAddNewFoldLine = (distanceFromOrigin) => {
        let copyNumberOptions = [];
        if (this.props.isPerforation) {
            copyNumberOptions = createChechboxOptions(this.props.billBookCopy);
        }
        this.setState(prevState => {
            const prepareOldState = prevState.foldLines.map(foldLine => ({ ...foldLine, isSelected: false }));
            const newFoldLines = [...prepareOldState, {
                // position: Math.round(position),
                position: Math.round(ImageHelper.convertMillimeterToPixel(distanceFromOrigin, this.props.masterFiles?.resolution) * this.state.ratio),
                // distanceFromOrigin: Math.round(ImageHelper.convertPixelToMillimeter(position, this.props.masterFiles.resolution) / this.state.ratio),
                distanceFromOrigin,
                isConvex: true,
                isSelected: true,
                isVertical: prevState.isFoldLineDirectionVertical,
                id: new Date().toISOString(),
                copyNumberOptions
            }];
            return { foldLines: this.sortHandler(newFoldLines), selectedFoldLinePreset: null };
        }, this.getUnacceptableFoldLines);
    }

    getMainBoxDimension = (event) => {
        this.setState({ fileDimension: event.current });
    }

    foldLineClicked = (selectedFoldLineIndex) => {
        this.setState(prevState => {
            const newFoldLine = prevState.foldLines.map((foldLine, index) =>
                index === selectedFoldLineIndex ? { ...foldLine, isSelected: true } : { ...foldLine, isSelected: false }
            )
            return { foldLines: newFoldLine }
        });
    }

    maxFoldLineNumberReachedHandler = () => {
        if (this.state.foldLines.length >= this.maxFoldLineNumber)
            () => this.setState({ messageModalText: CommonHelper.stringFormat(getTranslatedRawText('common.messages.maxFoldLineMessage'), this.maxFoldLineNumber) });
        return this.state.foldLines.length >= this.maxFoldLineNumber;
    }

    deleteFoldLineClicked = (deletedFoldLineIndex) => {
        this.setState(prevState => ({
            foldLines: prevState.foldLines.filter((foldLine, index) => index !== deletedFoldLineIndex)
        }), this.getUnacceptableFoldLines);
    }

    changeFoldLinePositionClicked = (modifiedFoldLine, modifiedFoldLineIndex) => {
        const isAllowedToChange = this.checkFoldLineMoving(modifiedFoldLine, modifiedFoldLineIndex);
        if (isAllowedToChange) {
            this.setState(prevState => {
                const newFoldLine = prevState.foldLines.map((foldLine, index) => (
                    index === modifiedFoldLineIndex
                        ? {
                            ...foldLine,
                            distanceFromOrigin: modifiedFoldLine,
                            position: Math.round(ImageHelper.convertMillimeterToPixel(modifiedFoldLine, this.props.masterFiles?.resolution) * this.state.ratio)
                        }
                        : foldLine
                ));
                return { foldLines: this.sortHandler(newFoldLine) };
            }, this.getUnacceptableFoldLines);
        }
    }

    checkFoldLineMoving = (modifiedFoldLine, modifiedFoldLineIndex) => {
        const { foldLines } = this.state;
        const isDistanceOk = !foldLines.some((item, index) =>
            index !== modifiedFoldLineIndex &&
            Math.abs(item.distanceFromOrigin - modifiedFoldLine) < 1);
        return isDistanceOk;
    }

    toggleConvexityClicked = (e, foldLineIndex) => {
        this.foldLineClicked(foldLineIndex);
        this.setState(prevState => {
            const newFoldLine = prevState.foldLines.map((foldLine, index) => index === foldLineIndex ? { ...foldLine, isConvex: e.key === Enumerations.foldLineShapes.convex } : foldLine);
            return { foldLines: newFoldLine }
        })
    }

    getUnacceptableFoldLines = () => {
        const { foldLines, isFoldLineDirectionVertical, ratio } = this.state;
        const distanceFromEdge = ImageHelper.convertMillimeterToPixel(this.props.isPerforation ? Config.order.minPerforationDistanceFromEdge : Config.order.minFoldLineDistanceFromEdge, this.props.masterFiles.resolution);
        const unacceptableFoldLines = [];
        const outOfTrimZoneFoldLines = [];
        for (let i = 0; i < foldLines.length; i++) {
            for (let j = 0; j < foldLines.length; j++) {
                if ((i < j) && (Math.abs(foldLines[i].distanceFromOrigin - foldLines[j].distanceFromOrigin) < this.minimumDistanceBetweenFoldLines)) {
                    unacceptableFoldLines.push(i + 1, j + 1);
                }
            }
            if (isFoldLineDirectionVertical) {
                foldLines[i].position <= distanceFromEdge * ratio && outOfTrimZoneFoldLines.push(i + 1);
                foldLines[i].position >= ImageHelper.convertMillimeterToPixel(this.props.masterFiles.width, this.props.masterFiles.resolution) * ratio - distanceFromEdge * ratio && outOfTrimZoneFoldLines.push(i + 1);
            } else {
                foldLines[i].position <= distanceFromEdge * ratio && outOfTrimZoneFoldLines.push(i + 1);
                foldLines[i].position >= ImageHelper.convertMillimeterToPixel(this.props.masterFiles.height, this.props.masterFiles.resolution) * ratio - distanceFromEdge * ratio && outOfTrimZoneFoldLines.push(i + 1);
            }
        };
        this.setState({ unacceptableFoldLines, outOfTrimZoneFoldLines, isChangedFoldLines: true });
    }

    foldLineModalTabChanged = (e, selectedTab) => {
        const { foldLines } = this.state;
        if (!isEmpty(foldLines)) {
            this.setState({ messageModalText: 'common.messages.foldLineChangeTabError' });
            return;
        }
        this.setState({
            selectedTabIndex: selectedTab.id,
            foldLines: selectedTab.id !== 1 && !isEmpty(this.state.selectedFoldLinePreset?.customFoldLineItems) ? [...this.state.selectedFoldLinePreset?.customFoldLineItems] : [],
            selectedFoldLinePreset: null,
            isFoldLineDirectionVertical: true,
            isChangedFoldLines: foldLines.length !== 0 || foldLines.length !== this.state.foldLinesLength,
        });
    }

    foldLinePresetClicked = (option) => {
        option.customFoldLineItems.map(foldLine => {
            foldLine.position = Math.round(ImageHelper.convertMillimeterToPixel(foldLine.distanceFromOrigin, this.props.masterFiles?.resolution) * this.state.ratio);
            foldLine.isVertical = true;
        })
        this.setState({ selectedFoldLinePreset: option, isChangedFoldLines: true });
    }

    closeMessageModalClicked = () => {
        this.setState({ messageModalText: null });
    }

    handleFoldLineFocus = (selectedFoldLineIndex) => {
        this.foldLineClicked(selectedFoldLineIndex);
    }

    render() {
        const {
            foldLines,
            fileDimension,
            unacceptableFoldLines,
            outOfTrimZoneFoldLines,
            selectedTabIndex,
            selectedFoldLinePreset,
            isFoldLineDirectionVertical,
            messageModalText,
            isAlertModalOpen,
        } = this.state;
        const { showModal, masterFiles, trimZone, isPerforation } = this.props;
        const mainButtons = [
            CommonHelper.createStickyButtonItem("common.save", 'ns-icon-save', this.saveClicked, 'primary', !isEmpty(unacceptableFoldLines) || !isEmpty(outOfTrimZoneFoldLines)),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.closeClicked, 'primary-outline')
        ];

        let isAddButtonDisabled;
        if (this.maxFoldLineNumberReachedHandler())
            isAddButtonDisabled = true;


        // let maxFileDimension = fileDimension?.offsetWidth;
        let maxFileDimension = this.props.masterFiles?.width;
        if (!isFoldLineDirectionVertical)
            maxFileDimension = this.props.masterFiles?.height;
        // maxFileDimension = fileDimension?.offsetHeight;

        let isPresetTab = selectedTabIndex === Enumerations.foldLineModalTab.preset;
        if (isPerforation) isPresetTab = false;
        const foldLinesData = isPresetTab ? selectedFoldLinePreset?.customFoldLineItems : foldLines;
        return (
            <NsModal showModal={showModal}
                maxWidth="md"
                // onKeyDown={this.keyDown}
                primaryActionButton={this.saveClicked}
                closeModal={this.closeClicked}
                className='fold-line-modal'
                dialogClassName="modal-choose-printing-files"
                dialogTitle={getTranslatedRawText(isPerforation ? 'regularOrder.modal.perforationTitle' : 'regularOrder.modal.foldLineTitle')}
                actions={< NsStickyButton stickyButtons={mainButtons} />}>

                <Row className=''>
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 7, order: 1 }} className='position-relative'>
                        <div className='fold-line-modal_img'>
                            <NsImageOrderMasterFileView onClick={this.imageClicked}
                                imgSrc={masterFiles?.draft}
                                wrapperClassName='position-relative'
                                trimZone={trimZone}
                                getMainBoxDimension={this.getMainBoxDimension}
                                setRatio={this.setRatio} >
                            </NsImageOrderMasterFileView>
                            <FoldLineDrawing isFoldLineDirectionVertical={isFoldLineDirectionVertical}
                                foldLines={foldLinesData}
                                isPerforation={isPerforation}
                                foldLineShapes={this.foldLineShapes}
                                foldLineClicked={this.foldLineClicked}
                                outOfTrimZoneFoldLines={outOfTrimZoneFoldLines}
                                unacceptableFoldLines={unacceptableFoldLines} />
                        </div>
                    </Col>

                    <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
                        <div className='border'>
                            {!isPerforation && <div className='background-element-color mb-4 border'>
                                <NsTab value={selectedTabIndex - 1}
                                    className='fold-line-modal'
                                    tabs={this.foldLineModalTabs}
                                    onChange={this.foldLineModalTabChanged} />
                            </div>
                            }
                            <div className='p-2 extendedService__tour__perforation-modal'>
                                {isPresetTab ?
                                    <PoFoldLineModalPreset foldLinePresetOptions={masterFiles.foldLineItems}
                                        selectedFoldLinePreset={selectedFoldLinePreset}
                                        foldLinePresetClicked={this.foldLinePresetClicked} />
                                    :
                                    <PoFoldLineModalCustomized foldLines={foldLines}
                                        isPerforation={isPerforation}
                                        isAddButtonDisabled={isAddButtonDisabled}
                                        maxFileDimension={maxFileDimension}
                                        maxFoldLineNumber={this.maxFoldLineNumber}
                                        minimumDistanceBetweenFoldLines={this.minimumDistanceBetweenFoldLines}
                                        unacceptableFoldLines={unacceptableFoldLines}
                                        outOfTrimZoneFoldLines={outOfTrimZoneFoldLines}
                                        isFoldLineDirectionVertical={isFoldLineDirectionVertical}
                                        toggleLineDirectionClicked={this.toggleLineDirectionClicked}
                                        addFoldLineClicked={this.addFoldLineClicked}
                                        changeFoldLinePositionClicked={this.changeFoldLinePositionClicked}
                                        toggleConvexityClicked={this.toggleConvexityClicked}
                                        setInputRefToFocus={this.setInputRefToFocus}
                                        inputRefToFocus={this.state.inputRefToFocus}
                                        handleFoldLineFocus={this.handleFoldLineFocus}
                                        deleteFoldLineClicked={this.deleteFoldLineClicked} />
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                {isAlertModalOpen &&
                    <NsAlertModal isOpen={isAlertModalOpen}
                        cancelClicked={() => this.closeAlertClicked(false)}
                        confirmClicked={() => this.closeAlertClicked(true)} >
                        {getTranslatedRawText('regularOrder.modal.closeServicesModalAlert')}
                    </NsAlertModal>}

                {!isEmpty(messageModalText) &&
                    <NsMessageClassModal show={true}
                        onClose={this.closeMessageModalClicked}
                        messageItems={[{
                            messageText: <>{getTranslatedRawText(messageModalText)}</>,
                            messageType: Enumerations.messageType.info
                        }]} />
                }
                {
                    (isPerforation && this.showTour) && <NsTour steps={this.tourSteps}
                        id={Enumerations.tourGuide.perforationModal} />
                }
            </NsModal >
        )
    }
}

function FoldLineDrawing(props) {
    const {
        foldLines,
        isFoldLineDirectionVertical,
        foldLineClicked,
        unacceptableFoldLines,
        outOfTrimZoneFoldLines,
        foldLineShapes,
        isPerforation
    } = props;
    let className = 'fold-line-modal_vertical-line';
    let direction = 'left';
    if (!isFoldLineDirectionVertical) {
        className = 'fold-line-modal_horizontal-line';
        direction = 'top';
    }
    const foldLineList = !isEmpty(foldLines) ? foldLines.map((foldLine, index) => {

        const foldLineText = foldLineShapes.find(foldLineShape => foldLineShape.key === (foldLine?.isConvex ? Enumerations.foldLineShapes.convex : Enumerations.foldLineShapes.concave))?.value
        let foldLineNumberClassName = 'white-color background-black-color';

        if (unacceptableFoldLines.includes(index + 1) || outOfTrimZoneFoldLines.includes(index + 1))
            foldLineNumberClassName = 'white-color background-red-color';
        return (
            <div className={className}
                key={foldLine.id}
                style={{ [direction]: `${foldLine.position}px` }} >
                <button onClick={() => foldLineClicked(index)}
                    className={`fold-line-modal_button  px-2 ${foldLineNumberClassName} ${foldLine.isSelected && 'selected-fold-line'}`}>
                    <span className='font-size-18'>
                        {index + 1}
                    </span>
                    <span className='font-size-12'>
                        ({isPerforation ? getTranslatedRawText('regularOrder.billBook.perforation') : foldLineText})
                    </span>
                </button>
            </div>
        )
    })
        : [];

    return foldLineList;
}

export default PoFoldLineModal;