import React from 'react';
import { CircularProgress } from '@mui/material';
import '../../assets/sass/components/circularProgress/NsCircularProgress.scss'

class NsCircularProgress extends React.Component {
  render() {
    const { visible, size, relative, color } = this.props;

    if (visible)
      return (
        <div className={`circular-progress ${relative ? 'circular-progress--relative' : 'circular-progress--absolute'}`}>
          <CircularProgress className='circular-progress__progress' color={"inherit"} size={size}>
            {this.props.children}
          </CircularProgress>
        </div>
      );
    return null;
  }
}

export default NsCircularProgress;