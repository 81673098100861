import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import { GetAuditDetailByIdService, UpdateAuditMatchingTypeService } from '../../../../../services/adminPanel/AdminPaymentService';
import EnumerationTranslationHelper from '../../../../../utilities/EnumerationTranslationHelper';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import NsLabelBox from '../../../../../components/labels/NsLabelBox';
import NsDataGrid from '../../../../../components/grids/NsDataGrid';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import Enumerations from '../../../../../configs/Enumerations';
import { getApAuditDetailColumns } from '../ApPaymentHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';

const ApPaymentAuditAttempContent = (props) => {

    const [columns, setColumns] = useState([]);
    const [auditData, setAuditData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState((<></>));

    useEffect(() => {
        if (!!props.selectedAuditId) getAuditDetail();
    }, []);

    const getAuditDetail = () => {
        setIsLoading(true);
        GetAuditDetailByIdService(props.selectedAuditId, getAuditDetailByIdCallback);
    }

    const getAuditDetailByIdCallback = (resultData, result, resultMessageClass) => {
        if (result?.data?.hasError)
            setResultMessageClass(resultMessageClass);
        else {
            setColumns(getApAuditDetailColumns(paymentAuditHistoryClicked, resultData.paymentGatewayType, true));
            setAuditData(resultData);
        }
        setIsLoading(false);
    }

    const paymentAuditHistoryClicked = (selectedAuditData) =>
        CommonHelper.safeFunctionCall(props.updateActiveState, Enumerations.auditDetailStepper.history, selectedAuditData);

    const refreshGridClicked = () => {
        setAuditData({});
        getAuditDetail();
    }

    const updateMatchingStatus = () => {
        setIsLoading(true);
        UpdateAuditMatchingTypeService(auditData?.selectedPaymentAuditId, updateAuditMatchingTypeCallback, true);
    }

    const updateAuditMatchingTypeCallback = (resultData, result, resultMessageClass) => {
        setResultMessageClass(resultMessageClass);
        setIsLoading(false);
        if (!result?.data?.hasError)
            refreshGridClicked();
    }

    const actionButtons = [
        hasAccess([UserClaimEnumerations.updateApPaymentAuditMatchingType]) &&
        { wrapperClassName: '', startIcon: 'ns-icon-retry', label: 'adminPanel.audit.updateMatchingStatus', onClick: () => updateMatchingStatus() },
    ];

    return (<>

        {!!auditData.selectedPaymentAuditId &&
            <ApBaseMapActionButtons actionButtons={actionButtons}
                isLoading={isLoading} />
        }
        <div className='background-color py-2 mb-3'>

            {isLoading
                ? <NsSkeleton isVisible={true}
                    variant={[Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect,
                    Enumerations.skeleton.rect]} />
                : <Row className='mx-auto'>
                    <div className='gap-2 flex-wrap d-flex'>
                        <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.paymentGateway')} value={EnumerationTranslationHelper.getPaymentGatewayType(auditData?.paymentGatewayType)} />
                        {/* <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.referenceNumber')} value={auditData?.rrn} /> */}
                    </div>
                </Row>
            }
        </div>

        {!isEmpty(columns) &&
            <div className='px-2 pb-2'>
                <NsDataGrid key={auditData.length}
                    isLoading={isLoading}
                    columnDefs={columns}
                    handlePagination={refreshGridClicked}
                    rows={auditData?.paymentAuditInformations || []} />
            </div>
        }

        {resultMessageClass}
    </ >
    );
}

export default React.memo(ApPaymentAuditAttempContent);