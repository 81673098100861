import Links from "../../configs/links/AllLinks"
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest } from "../bases/BaseService"

const GetShippingsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApShippings), parameter, callback);
};

const GetShippingByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApShippingById), id), {}, callback);
};

const GetShippingGroupSheetOrdersService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApShippingGroupSheetOrders), parameters, callback);
};

const GetShippingStoreOrdersService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApShippingStoreOrders), parameters, callback);
};

const GetShippingMethodsService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApShippingMethods), {}, callback);
};

const GetShippingQueueByMethodsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApShippingQueueByMethods), parameters, callback);
};

const GetShippingQueueByLastStatusService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApShippingQueueByLastStatus), parameters, callback);
};

const GetShippingQueueByEstimatedTimeService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApShippingQueueByEstimatedTime), parameters, callback);
};

const GetApShippingPlansService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApShippingPlans), {}, callback);
};

export {
    GetShippingsService,
    GetShippingByIdService,
    GetShippingGroupSheetOrdersService,
    GetShippingStoreOrdersService,
    GetShippingMethodsService,
    GetShippingQueueByMethodsService,
    GetShippingQueueByLastStatusService,
    GetShippingQueueByEstimatedTimeService,
    GetApShippingPlansService,
}