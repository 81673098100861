import clsx from 'clsx';
import React from 'react';
import '../../assets/sass/components/labels/NsLabelBox.scss'
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsTooltip from '../tooltips/NsTooltip';

class NsLabelBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipOpen: false
        }
    }

    toggleTooltipClicked = () => this.setState({ isTooltipOpen: !this.state.isTooltipOpen });

    onClick = () => CommonHelper.safeFunctionCall(this.props.onClick);

    render() {
        const { className, label, value, hasTooltip, hasDescription, description } = this.props;
        return (
            <div className={clsx("label-box d-flex flex-column", className)} onClick={this.onClick}>
                <div className="my-2 ms-2 me-3 title">
                    {hasDescription ?
                        <div className='d-flex text-nowrap'>
                            <span className='me-2'>{label}</span>
                            <NsTooltip title={description} placement='top'>
                                <i className='ns-icon-question fs-6' />
                            </NsTooltip>
                        </div>
                        : <>{label}</>}
                </div>
                {hasTooltip
                    ? <NsTooltip title={value}>
                        <div className="ms-3 mb-2 me-3 fw-bold pe-3 truncate-text ">
                            {value}
                        </div>
                    </NsTooltip>
                    : <div className="ms-3 mb-2 me-3 fw-bold">
                        {value}
                    </div>
                }
            </div>
        )
    }
}

export default NsLabelBox;