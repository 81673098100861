import React from 'react';

import '../../../assets/sass/views/singlePages/SinglePageSidebar.scss';
import Enumerations from '../../../configs/Enumerations';
import { GetCmsSidebarBlogPromoteContentsService } from '../../../services/singlePages/CmsService';
import { AdvertisingImage } from '../../singlePages/sidebars/SpSidebar';
import BnSidebarArchiveDate from './BnSidebarArchiveDate';
import BnSidebarCategories from './BnSidebarCategories';
import BnSidebarPosts from './BnSidebarPosts';
import BnSidebarSearch from './BnSidebarSearch';
import BnSpSidebarSocialMedia from './BnSidebarSocialMedia';
import SpSidebarLinkToShopBanner from '../../singlePages/sidebars/SpSidebarLinkToShopBanner';
import SpSidebarOurSuggestionProducts from '../../singlePages/sidebars/SpSidebarOurSuggestionProducts';

class SpSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            advertisements: [],
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => GetCmsSidebarBlogPromoteContentsService(this.getCmsSidebarBlogPromoteContentsCallback);

    getCmsSidebarBlogPromoteContentsCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError)
            this.setState({ advertisements: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    render() {
        const { resultMessageClass, advertisements } = this.state;

        return (
            <div className='sidebar d-flex flex-column'>
                <div className="mb-3">
                    <BnSidebarSearch filters={{}} urlParameters={this.props.urlParameters} />
                </div>

                <div className="mb-3">
                    <BnSidebarCategories />
                </div>

                <SpSidebarLinkToShopBanner />

                <div className='mb-3'>
                    <SpSidebarOurSuggestionProducts />
                </div>

                <AdvertisingImage img={advertisements[0]} promotionEnum={Enumerations.promotionsId.singleTop} listEnum={Enumerations.googleTagManagerListId.singlePageSidebar} />

                {false && <BnSidebarPosts title='adminPanel.post.popularPosts'
                    parameters={{ PageLength: 4, CurrentPageIndex: 1 }} />
                }
                <BnSidebarPosts title='adminPanel.post.recentPosts'
                    parameters={{ PageLength: 3, CurrentPageIndex: 1 }} />

                <AdvertisingImage img={advertisements[1]} promotionEnum={Enumerations.promotionsId.singleBottom} listEnum={Enumerations.googleTagManagerListId.singlePageSidebar} />

                <BnSidebarPosts isReversePost={true}
                    parameters={{ PageLength: 2, CurrentPageIndex: 1 }} />

                <BnSidebarArchiveDate />

                <div className="mb-3">
                    <BnSpSidebarSocialMedia />
                </div>

                {resultMessageClass}
            </div>
        );
    }
}

export default SpSidebar;