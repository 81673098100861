import React, { useEffect, useState, useRef } from 'react';

import NsAdminPanelContentWrapper from '../../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import { GetBusinessPartnersService } from '../../../../../services/adminPanel/AdminUserService';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { getApBussinessPartnerColumn } from '../bases/ApBaseUserHelper';
import ApBusinessPartnersSearch from './ApBusinessPartnersSearch';
import ApBaseUserInfoModal from '../bases/ApBaseUserInfoModal';
import Enumerations from '../../../../../configs/Enumerations';

const ApBusinessPartners = () => {

    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState(null);
    const [selectedBusinessPartnerId, setSelectedBusinessPartnerId] = useState(null);
    const [showBusinessPartnerModal, setShowBusinessPartnerModal] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData]);

    const detailViewClicked = (id) => {
        setSelectedBusinessPartnerId(id);
        setShowBusinessPartnerModal(true);
    }

    const searchClicked = (data) => setSearchData(data);

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    const columns = getApBussinessPartnerColumn(detailViewClicked);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.businessPartners")}
            icon={'ns-icon-business-partners h1'}>

            <ApBusinessPartnersSearch isLoading={isLoading}
                searchClicked={searchClicked} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetBusinessPartnersService}
                parameters={{
                    'Filter.PhoneNumber': searchData?.phoneNumber || '',
                    'Filter.firstName': searchData?.fullName || '',
                }}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showBusinessPartnerModal &&
                <ApBaseUserInfoModal showModal={true}
                    roleType={Enumerations.role.businessPartner}
                    selectedUserId={selectedBusinessPartnerId}
                    closeModal={() => setShowBusinessPartnerModal(false)} />
            }

        </NsAdminPanelContentWrapper>
    );
};

export default React.memo(ApBusinessPartners);