import React, { Component } from 'react';
import { Row } from 'react-bootstrap';

import { FAQList } from '../frequentlyAskedQuestions/FrequentlyAskedQuestions';
import NsLabelLine from '../../../../components/labels/NsLabelLine';
import Links from '../../../../configs/links/AllLinks';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';

export default class SpecialNeedsFaq extends Component {
    constructor(props) {
        super(props);
        this.SpecialNeedsFaq = [
            {
                id: 1,
                isTitleVisible: true,
                mainContent: "Placing an order is easy. Simply navigate to the product page, select your specifications, and follow the intuitive ordering process. If you need assistance, our customer support is ready to help.",
                title: "How do I place an order for special needs products?"
            },
            {
                id: 2,
                isTitleVisible: true,
                mainContent: "We provide diverse packaging options to ensure the safe and secure delivery of special needs products. From recycled materials to specialized designs, explore the choices that best suit your preferences.",
                title: "What packaging options are available for special needs items?"
            },
            {
                id: 3,
                isTitleVisible: true,
                mainContent: "Our store caters to diverse needs with a range of specialized products. From customized bags to unique packaging solutions, explore how we address the specific requirements of individuals with special needs.",
                title: "What special products does your store offer for individuals with unique needs?"
            },
            {
                id: 4,
                isTitleVisible: true,
                mainContent: "We offer a variety of packaging options to guarantee the secure delivery of special needs products. From recycled materials to specialized designs, explore the choices that best fit your preferences.",
                title: "What packaging options are available to ensure the safe delivery of special needs items?"
            },
        ]
    }

    render() {
        return (
            <>
                <NsLabelLine linkTo={getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBagFaq)}
                    smallSize={true}
                    primaryTitle={getTranslatedRawText('product.shoppingBag.faqs')} />
                <Row className="ustify-content-between my-3">
                    <FAQList FAQArray={this.SpecialNeedsFaq}
                        widthCol={6}
                        childClassName='background-element-color p-3 rounded mb-2' />
                </Row>
            </>
        )
    }
}
