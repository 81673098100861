import React from 'react';
import FmSidebarFolderTreeView from './FmSidebarFolderTreeView';

class FmSidbar extends React.Component {

    render() {
        return (
            <div className={`file-manager__border-style file-manager__left-panel ps-3 mt-2 position-relative w-100 ${this.props.className}`} >

                <FmSidebarFolderTreeView treeViewFolders={this.props.treeViewFolders}
                    isUploadTreeView={this.props.isUploadTreeView} />
            </div >
        );
    }
}

export default FmSidbar;