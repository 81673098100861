import clsx from "clsx";
import React from "react";
import { connect } from 'react-redux';
import { Col, Row } from "react-bootstrap";

import NsSetPageTitleAndDescription from "../../../../components/metaTags/NsSetPageTitleAndDescription";
import { GetPublicProfileService } from "../../../../services/ProfileSetting/PersonalSettingService";
import ShoppingBagMainBanner from "../../../products/shoppingBags/main/ShoppingBagMainBanner";
import banner from '../../../../assets/images/profileSettings/ذannerUsersProfile.jpg';
import SignInModal from "../../../layouts/main/headers/modals/signIn/SigninModal";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import '../../../../assets/sass/views/singlePages/publicProfile.scss';
import NsTypography from "../../../../components/text/NsTypography";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import NsButton from "../../../../components/buttons/NsButton";
import CommonHelper from "../../../../utilities/CommonHelper";
import NsAvatar from "../../../../components/avatar/NsAvatar";
import Enumerations from "../../../../configs/Enumerations";
import UrlHelper from "../../../../utilities/UrlHelper";
import FeedbackModal from "../../../profileSettings/modals/FeedbackModal";

class ProfilPublicUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {
                imageUrl: '',
                fullName: '',
                departments: [],
                description: 'This is the user description'
            },
            id: null,
            showFeedBackModal: false,
            resultMessageClass: null,
            messageClassModal: <></>,
        }
    }

    componentDidMount = () => {
        let id = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id);
        this.setState({ id });
        GetPublicProfileService({ id }, this.getPublicProfileCallback);
    }

    getPublicProfileCallback = (resultData, result, resultMessageClass) => {
        if (!result.data.hasError)
            this.setState({ profile: resultData, className: 'public-profile', resultMessageClass: null });
        else
            this.setState({ className: 'public-profile__blure', resultMessageClass });
    }

    toggleFeedbackModal = (messageClassModal, wasRequestSuccessful) => {
        if (!!this.props.userFullName) {
            this.setState(prevState => ({
                showFeedBackModal: !prevState.showFeedBackModal,
                messageClassModal: !!wasRequestSuccessful && messageClassModal
            }));

        }
        else this.toggleSignInModal()
    }

    toggleSignInModal = (showFeedBackModal) => this.setState({ showSignInModal: !this.state.showSignInModal },
        () => {
            showFeedBackModal && this.setState({ showFeedBackModal: true });
        });

    render() {
        const { id, profile, showFeedBackModal, className, resultMessageClass, showSignInModal, messageClassModal } = this.state;
        return (
            <>
                <div>
                    <ShoppingBagMainBanner bannerBackground={banner} applyOpacity={!isEmpty(resultMessageClass)} />
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <div className='public-profile__feature px-2'>
                                <div className='d-flex flex-column align-items-center align-items-md-start'>
                                    <div className='title-color d-flex flex-column align-items-start'>
                                        <NsAvatar src={CommonHelper.createImageUrl(profile?.imageUrl)}
                                            sx={{ width: 130, height: 130 }}
                                            className={` ${!isEmpty(resultMessageClass) && 'public-profile__blure'}`} />
                                        <NsTypography variant='inherit' className='font-size-24 fw-bold mt-2'>
                                            {profile?.fullName}
                                        </NsTypography>
                                        {!isEmpty(resultMessageClass) &&
                                            <NsTypography variant='inherit' className='font-size-24 fw-bold mt-2'>
                                                {getTranslatedRawText('singlePage.supporterProfile.anonymous')}
                                            </NsTypography>}
                                    </div>
                                    {false && <div className="d-flex flex-column flex-md-row justify-content-between align-items-center flex-wrap w-100 p-2 p-md-0">
                                        <NsTypography variant='inherit' className={`font-size-14 mt-2 ${!isEmpty(resultMessageClass) && 'public-profile__blure'}`}>
                                            {/* {profile?.departments.join(', ')} */}
                                            Public Relations, Central Office
                                        </NsTypography>
                                        <NsTypography variant='inherit' className={`font-size-14 rounded-pill background-element-color px-4 py-2 mt-3 mt-md-0 ${!isEmpty(resultMessageClass) && 'public-profile__blure'}`}>
                                            Supporter
                                            {console.log('##isEmpty', isEmpty(resultMessageClass))}
                                        </NsTypography>
                                    </div>
                                    }
                                    {isEmpty(resultMessageClass) && <div className="d-flex w-100">
                                        <NsTypography variant='inherit' className='d-block font-size-14 text-justify my-5'>
                                            {profile?.description}
                                        </NsTypography>
                                    </div>}
                                </div>
                                {!isEmpty(resultMessageClass) &&
                                    <div className="d-flex justify-content-center my-5">
                                        <div className={clsx(CommonHelper.isMobileDevice() ? 'w-100' : 'w-50')}>
                                            <NsTypography variant='inherit' className='d-block page-title'>
                                                {getTranslatedRawText('common.messages.userNotFoundTitle')}
                                            </NsTypography>
                                            <NsTypography variant='inherit' className='mt-2 text-justify'>
                                                {getTranslatedRawText('common.messages.userNotFoundDescription')}
                                            </NsTypography>
                                        </div>
                                    </div>
                                }

                                <div className="d-flex justify-content-center">
                                    <NsButton label={CommonHelper.stringFormat(getTranslatedRawText('singlePage.supporterProfile.feedbackTo'), profile?.fullName)}
                                        className='primary mt-4'
                                        startIcon='ns-icon-users-feedback'
                                        disabled={!isEmpty(resultMessageClass)}
                                        onClick={this.toggleFeedbackModal} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <NsSetPageTitleAndDescription pageTitle={CommonHelper.stringFormat(
                        getTranslatedRawText('common.breadcrumbs.publicProfile'), profile?.fullName)} />
                </div>

                {showFeedBackModal &&
                    <FeedbackModal id={id}
                        label={profile?.fullName}
                        feedbackType={Enumerations.feedbackTypes.user}
                        closeModal={this.toggleFeedbackModal} />
                }

                {showSignInModal &&
                    <SignInModal closeModal={() => this.toggleSignInModal(false)}
                        signInClicked={() => this.toggleSignInModal(true)}
                    />
                }
                {messageClassModal}
            </>
        );
    }
}

const mapStateToProps = state => ({ userFullName: state.currentUser.userFullName });

export default connect(mapStateToProps)(ProfilPublicUser);