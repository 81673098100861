import React from 'react';
import { Col, Row } from 'react-bootstrap';
import '../../../assets/sass/views/orders/RegularOrder.scss';

import { GetExtendedServicePriceService } from '../../../services/order/ProfessionalOrderService';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import RegularOrderTours from '../../bases/tours/RegularOrderTours';
import MainStepper from './mainContents/mainStepper/MainStepper';
import Enumerations from '../../../configs/Enumerations';
import NsTour from '../../../components/tour/NsTour';
import UrlHelper from '../../../utilities/UrlHelper';
import OSummary from '../summaries/OSummary';
import OHeader from '../headers/OHeader';
import OrderHelper from '../../../utilities/OrderHelper';
import CommonHelper from '../../../utilities/CommonHelper';

class RegularOrderWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTour: false,
            tourSteps: [],
            tourId: null,
            refreshSummaryDate: null,
            pricesLoading: false,
            totalExtendedServicePrice: 0,
            resultMessageClass: (<></>),
        }
    }

    componentDidMount = () => {
        const id = UrlHelper.getRouteValue(this.props.otherProps, Enumerations.routeKey.id);
        this.refreshSummary();
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if ((this.props.refreshExtendedServiceSummaryDate !== newProps.refreshExtendedServiceSummaryDate)
            || (this.props.refreshFilePreviewSummaryDate !== newProps.refreshFilePreviewSummaryDate))
            this.refreshSummary();
        if (this.props.toggleTourDate !== newProps.toggleTourDate) {
            !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.masterFile) && CurrentUserHelper.addToSeenTours(Enumerations.tourGuide.masterFile);
            this.toggleProfessionalOrderTour(false);
        }
    }

    refreshSummary = () => {
        this.setState({ pricesLoading: true });
        GetExtendedServicePriceService(this.props.routeId, this.getExtendedServicePriceCallback);
    }

    getExtendedServicePriceCallback = (resultData, result, messageClassModal) => {
        this.setState({ pricesLoading: false });
        if (!result.data.hasError) {
            this.setState({ totalExtendedServicePrice: resultData.totalPrice, refreshSummaryDate: new Date() });
            OrderHelper.setTotalExtendedServicePrice(this.props.routeId, resultData.totalPrice);
            this.toggleProfessionalOrderTour(true);
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    toggleProfessionalOrderTour = (isToogle, tourId) => {
        if (!!tourId) {
            !CurrentUserHelper.isTourSeen(tourId) && CurrentUserHelper.addToSeenTours(tourId);
            this.setState({ showTour: false },
                () => !!isToogle ? recognizeCurrentTour(this.props.routeId, (showTour, tourSteps, tourId) =>
                    this.setState({ showTour, tourSteps, tourId }))
                    : this.setState({ showTour: false, tourSteps: [], tourId: null })
            );
        } else
            !!isToogle
                ? recognizeCurrentTour(this.props.routeId, (showTour, tourSteps, tourId) => {
                    this.setState({ showTour, tourSteps, tourId })
                })
                : this.setState({ showTour: false, tourSteps: [], tourId: null });
    }

    render() {
        const { refreshSummaryDate, pricesLoading, totalExtendedServicePrice, showTour, tourSteps, tourId } = this.state;

        return (
            <Row className='regular-order-detail'>

                <Col xs={12} className='mb-1 my-md-2'>
                    <OHeader {...this.props.otherProps}
                        toggleProfessionalOrderTour={this.toggleProfessionalOrderTour}
                        totalExtendedServicePrice={this.totalExtendedServicePrice}
                        routeId={this.props.routeId} />
                </Col>

                <Col lg={{ span: 8, order: 0 }} xs={{ order: 'last' }} className="bachground-white-color">
                    <MainStepper otherProps={this.props.otherProps}
                        routeId={this.props.routeId}
                        mainStepperActiveStep={this.props.mainStepperActiveStep}
                        refreshFilePreviewSummaryDate={refreshSummaryDate} />

                    {this.props.children}
                </Col>

                <Col lg={4} className='mb-1 d-none d-md-block'>
                    <OSummary routeId={this.props.routeId}
                        pricesLoading={pricesLoading}
                        isUsedInProfessional={false}
                        totalExtendedServicePrice={totalExtendedServicePrice}
                        refreshSummaryDate={refreshSummaryDate} />
                </Col>

                {this.state.resultMessageClass}

                {showTour && <NsTour steps={tourSteps}
                    id={tourId} />
                }
            </Row>
        );
    }
}

function recognizeCurrentTour(routeId, callback) {
    if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.masterFile)) {
        callback(true, RegularOrderTours.getRegularOrderWrapperTour(), Enumerations.tourGuide.masterFile)
        return;
    }
    if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.preview) && !CommonHelper.isMobileDevice() && !!OrderHelper.getFrontSideMasterFiles(routeId)?.id) {
        callback(true, RegularOrderTours.getSummaryFilePreviewTour(), Enumerations.tourGuide.preview)
        return;
    }
    if (!CurrentUserHelper.isTourSeen(Enumerations.tourGuide.roExtendedServiceSummary) && OrderHelper.getTotalExtendedServicesPrice(routeId) > 0) {
        callback(true, RegularOrderTours.getExtendedServiceSummaryTours(), Enumerations.tourGuide.roExtendedServiceSummary)
        return;
    }

}

export default RegularOrderWrapper;