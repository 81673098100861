import clsx from 'clsx'
import React, { Component } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";

import '../../assets/sass/components/collapsibleList/NsCollapsibleList.scss';
import CommonHelper from '../../utilities/CommonHelper';
import NsTypography from '../text/NsTypography';
import { isEmpty } from '../../utilities/ValidationHelper';
import NsCheckboxTreeView from '../treeViews/NsCheckboxTreeView';

class NSCollapsibleList extends Component {

    constructor(props) {
        super(props);
        this.state = { isOpen: false };
    }

    listToggle = () => this.setState({ isOpen: !this.state.isOpen });

    categoryItemClicked = (item, payload) => {
        if (isEmpty(payload))
            CommonHelper.safeFunctionCall(this.props.categoryItemClicked, { targetIds: [item.id], isChecked: item.checked });
        else {
            CommonHelper.safeFunctionCall(this.props.categoryItemClicked, {
                targetIds: CommonHelper.recursiveSeparator(item).parentLessItems.map(item => item.id),
                isChecked: payload.isChecked
            })
        }
    }

    render() {
        const { className, collapseClassName, visibleCount, list, viewLessWord, viewMoreWord } = this.props;
        const { isOpen } = this.state;
        const mainItems = isEmpty(list) ? [] : list.slice(0, visibleCount);
        const hiddenItems = isEmpty(list) ? [] : list.slice(visibleCount);
        const isCollapseNeed = isEmpty(list) ? false : list.length > visibleCount;

        return (
            <List component="nav"
                className="p-0">
                <ShowItems show={true}
                    mainItems={mainItems}
                    className={className}
                    categoryItemClicked={this.categoryItemClicked} />
                {isCollapseNeed &&
                    <>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <ShowItems show={false}
                                hiddenItems={hiddenItems}
                                className={className}
                                categoryItemClicked={this.categoryItemClicked} />
                        </Collapse>
                        <ListItem button
                            className={clsx("p-0", className, collapseClassName)}
                            onClick={this.listToggle} >
                            {isOpen ? <i className='ns-icon-expand-up' /> :
                                <i className={clsx('ns-icon-expand-down ', collapseClassName)} />}
                            <NsTypography className={clsx(className, collapseClassName)}
                                variant='inherit'>
                                {isOpen ? viewLessWord : viewMoreWord}
                            </NsTypography>
                        </ListItem>
                    </>
                }
            </List>
        );
    }
}

function ShowItems(props) {
    const items = props.show ? props.mainItems : props.hiddenItems;
    return <NsCheckboxTreeView items={items}
        checkboxClassName='light-paragraph w-100 mb-1'
        onChange={props.categoryItemClicked} />
}

export default NSCollapsibleList;