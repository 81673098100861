import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { EditNotificationsService, GetNotificationsService } from '../../../../services/ProfileSetting/PersonalSettingService';
import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsTypography from '../../../../components/text/NsTypography';
import NsCheckbox from '../../../../components/inputs/NsCheckbox';
import UserPanelWrapper from '../../UserPanelWrapper';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import NsTooltip from '../../../../components/tooltips/NsTooltip';
import NsButton from '../../../../components/buttons/NsButton';

class UpProfileNotificationSetting extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            { label: "common.save", icon: 'ns-icon-save', onClicked: this.saveClicked, className: 'primary' },
            { label: "common.discard", icon: 'ns-icon-close', onClicked: this.discardClicked, className: 'primary-outline' },
        ];
        this.autoDeliveryGroupButtons = ConstantsWithTranslation.getOnOffSwitch();
        this.state = {
            //Notification Setting
            hasLoginNotification: false,
            hasOrderNotification: false,
            isTooltipOpen: false,
            //Select Notification Type
            hasNewsNotification: false,
            hasLottary: false,
            hasSubmitNewGroupSheetInvoice: false,
            hasConfirmNewGroupSheetInvoice: false,
            hasGroupSheetOrderIsReady: false,
            hasSubmitNewCustimizedJob: false,
            hasConfirmNewCustimizedInvoice: false,
            hasCustimizedOrderIsReady: false,
            hasOrderCancelation: false,
            hasMonthlyDiscount: false,
            typeCheckboxItems: () => this.defineTypeCheckboxItems(),

            //Your Social Networks
            mobileNumber: '',
            hasNotifyMeByMobile: false,
            emailAddress: '',
            hasNotifyMeByEmail: false,
            channelsCheckboxItems: () => this.defineChannesCheckboxItems(),

            communicationChannels: [],
            isLoading: false,
            selectAll: false,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => this.getNotifications();

    defineChannesCheckboxItems = () => {
        const { hasNotifyMeByMobile, hasNotifyMeByEmail, emailAddress, mobileNumber } = this.state;
        return [
            { name: { hasNotifyMeByMobile }, label: getTranslatedRawText("profile.notification.notifyByMobile"), boxLabel: getTranslatedRawText('profile.personal.mobile'), value: hasNotifyMeByMobile, boxValue: mobileNumber, icon: 'ns-icon-sms h1' },
            { name: { hasNotifyMeByEmail }, label: getTranslatedRawText("profile.notification.notifyByEmail"), boxLabel: getTranslatedRawText('components.emailInput.email'), value: hasNotifyMeByEmail, boxValue: emailAddress, icon: 'ns-icon-email h1' },
        ]
    }

    defineTypeCheckboxItems = () => {
        const { hasLoginNotification, hasOrderNotification, hasLottary, hasSubmitNewGroupSheetInvoice, hasConfirmNewGroupSheetInvoice, hasGroupSheetOrderIsReady, hasMonthlyDiscount,
            hasSubmitNewCustimizedJob, hasConfirmNewCustimizedInvoice, hasCustimizedOrderIsReady, hasOrderCancelation } = this.state;
        return [
            { name: { hasLoginNotification }, label: getTranslatedRawText("profile.notification.signInNotify"), value: hasLoginNotification },
            { name: { hasOrderNotification }, label: getTranslatedRawText("profile.notification.newOrderNotify"), value: hasOrderNotification },
            { name: { hasSubmitNewGroupSheetInvoice }, label: getTranslatedRawText("profile.notification.newGroupSheetNotify"), value: hasSubmitNewGroupSheetInvoice },
            { name: { hasSubmitNewCustimizedJob }, label: getTranslatedRawText("profile.notification.newCustomizedNotify"), value: hasSubmitNewCustimizedJob },
            { name: { hasGroupSheetOrderIsReady }, label: getTranslatedRawText("profile.notification.readyGroupSheetNotify"), value: hasGroupSheetOrderIsReady },
            { name: { hasCustimizedOrderIsReady }, label: getTranslatedRawText("profile.notification.readyCustomizedNotify"), value: hasCustimizedOrderIsReady },
            { name: { hasConfirmNewGroupSheetInvoice }, label: getTranslatedRawText("profile.notification.groupSheetConfirmationNotify"), value: hasConfirmNewGroupSheetInvoice },
            { name: { hasConfirmNewCustimizedInvoice }, label: getTranslatedRawText("profile.notification.customizedConfirmationNotify"), value: hasConfirmNewCustimizedInvoice },
            { name: { hasOrderCancelation }, label: getTranslatedRawText("profile.notification.deletingInvoiceNotify"), value: hasOrderCancelation },
            { name: { hasLottary }, label: getTranslatedRawText("profile.notification.lotteryNotify"), value: hasLottary },
            { name: { hasMonthlyDiscount }, label: getTranslatedRawText("profile.notification.monthlyDiscountNotify"), value: hasMonthlyDiscount },
        ]
    }

    checkboxValueChanged = (key, value, changeSelectAll) => {
        const valueName = CommonHelper.getVariableName(key);
        this.setState({ [valueName]: value, selectAll: changeSelectAll ? false : this.state.selectAll });
    }

    discardClicked = () => this.getNotifications();

    saveClicked = () => {
        this.setState({ isLoading: true, });
        const { hasNotifyMeByMobile, hasNotifyMeByEmail, hasLoginNotification, hasOrderNotification, hasNewsNotification, hasLottary, hasSubmitNewGroupSheetInvoice,
            hasConfirmNewGroupSheetInvoice, hasGroupSheetOrderIsReady, hasSubmitNewCustimizedJob, hasConfirmNewCustimizedInvoice,
            hasCustimizedOrderIsReady, hasOrderCancelation, hasMonthlyDiscount } = this.state;
        let data = {};
        data = {
            hasLoginNotification,
            hasOrderNotification,
            hasNewsNotification,
            hasLottary,
            hasSubmitNewGroupSheetInvoice,
            hasConfirmNewGroupSheetInvoice,
            hasGroupSheetOrderIsReady,
            hasSubmitNewCustimizedJob,
            hasConfirmNewCustimizedInvoice,
            hasCustimizedOrderIsReady,
            hasOrderCancelation,
            hasMonthlyDiscount,
            editUserCommunicationChannel: [
                { userCommunicationChannelTypeId: Enumerations.userCommunicationChannelType.primaryEmail, notificationAddress: null, hasNotifyByThis: hasNotifyMeByEmail },
                { userCommunicationChannelTypeId: Enumerations.userCommunicationChannelType.primaryMobile, notificationAddress: null, hasNotifyByThis: hasNotifyMeByMobile },
            ],
        };
        EditNotificationsService(data, this.editNotificationscallback, true);
    }

    editNotificationscallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError) {
            this.setState({ resultMessageClass },
                () => this.getNotifications());
        } else
            this.setState({ resultMessageClass, isLoading: false });
    }

    getNotificationsCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError) {

            this.setState({
                hasLoginNotification: resultData.hasLoginNotification,
                hasOrderNotification: resultData.hasOrderNotification,

                hasNewsNotification: resultData.hasNewsNotification,
                hasLottary: resultData.hasLottary,
                hasSubmitNewGroupSheetInvoice: resultData.hasSubmitNewGroupSheetInvoice,
                hasConfirmNewGroupSheetInvoice: resultData.hasConfirmNewGroupSheetInvoice,
                hasGroupSheetOrderIsReady: resultData.hasGroupSheetOrderIsReady,
                hasSubmitNewCustimizedJob: resultData.hasSubmitNewCustimizedJob,
                hasConfirmNewCustimizedInvoice: resultData.hasConfirmNewCustimizedInvoice,
                hasCustimizedOrderIsReady: resultData.hasCustimizedOrderIsReady,
                hasOrderCancelation: resultData.hasOrderCancelation,
                hasMonthlyDiscount: resultData.hasMonthlyDiscount,

                emailAddress: resultData.userCommunicationChannels[4].notificationAddress,
                hasNotifyMeByEmail: !!resultData.userCommunicationChannels[4].hasNotifyByThis,
                mobileNumber: resultData.userCommunicationChannels[5].notificationAddress,
                hasNotifyMeByMobile: !!resultData.userCommunicationChannels[5].hasNotifyByThis,
                communicationChannels: resultData.userCommunicationChannels,
                isLoading: false
            });
        } else
            this.setState({ resultMessageClass, isLoading: false });
    }

    getNotifications = () => this.setState({ isLoading: true },
        () => GetNotificationsService(this.getNotificationsCallback));

    selectAllClicked = () => {
        const checkStatus = this.state.selectAll ? false : true;
        this.setState({
            selectAll: checkStatus,
            hasLoginNotification: checkStatus,
            hasOrderNotification: checkStatus,
            hasNewsNotification: checkStatus,
            hasLottary: checkStatus,
            hasSubmitNewGroupSheetInvoice: checkStatus,
            hasConfirmNewGroupSheetInvoice: checkStatus,
            hasGroupSheetOrderIsReady: checkStatus,
            hasSubmitNewCustimizedJob: checkStatus,
            hasConfirmNewCustimizedInvoice: checkStatus,
            hasCustimizedOrderIsReady: checkStatus,
            hasOrderCancelation: checkStatus,
            hasMonthlyDiscount: checkStatus,
        });
    }

    render() {
        const { selectAll, isLoading, resultMessageClass, communicationChannels } = this.state;

        return (
            <UserPanelWrapper titleName={getTranslatedRawText("profile.notification.notificationSetting")}
                titleIcon='ns-icon-notification-setting'>

                <NsLoadingProgress visible={isLoading} />

                <div className='mb-4'>
                    <div className=' mb-3'>
                        <p className="section-title">
                            {getTranslatedRawText("profile.notification.notificationOptions")}
                        </p>
                        <NsCheckbox value={selectAll}
                            label={getTranslatedRawText("profile.notification.selectAll")}
                            onChange={this.selectAllClicked} />
                    </div>
                    <Row className="w-100 mb-3">
                        <NotificationOptions checkboxItems={this.state.typeCheckboxItems.apply(this)}
                            onChanged={this.checkboxValueChanged} />
                    </Row>
                    <p className="section-title d-flex align-items-center">
                        {getTranslatedRawText("profile.notification.communicationChannels")}
                        <NsTooltip open={this.state.isTooltipOpen}
                            closeTooltip={() => this.setState({ isTooltipOpen: false })}
                            title={getTranslatedRawText('infos.notificationSetting')}>
                            <div className="p-1 fw-bold truncate-text">
                                <NsButton className='secondary-text p-0 m-0'
                                    startIcon='ns-icon-question'
                                    onClick={() => this.setState({ isTooltipOpen: !this.state.isTooltipOpen })} />
                            </div>
                        </NsTooltip>
                    </p>
                    <ChannelCheckboxList checkboxItems={this.state.channelsCheckboxItems.apply(this)}
                        channels={communicationChannels}
                        onChanged={this.checkboxValueChanged} />
                </div>
                <NsStickyButton stickyButtons={this.mainButtons} isLoading={isLoading} />
                {resultMessageClass}
            </UserPanelWrapper >
        );
    }
}

function NotificationOptions(props) {
    return props.checkboxItems.map((item, index) =>
        <Col key={index} xl={4} lg={6} sm={12} >
            <NsCheckbox value={!!item.value}
                label={item.label}
                onChange={(value) => props.onChanged(item.name, value, true)} />
        </Col>
    );
}

function ChannelCheckboxList(props) {
    return props.checkboxItems.map((item, index) =>
        !!item.boxValue &&
        <div key={index} className='d-flex mb-3'>
            <div className='d-flex align-items-center border rounded me-3'>
                <i className={clsx('black-color m-0 mx-2', item.icon)} />
            </div>
            <div className='background-element-color rounded p-2'>
                <NsCheckbox value={!!item.value}
                    label={item.label}
                    className='font-size-18 fw-bold'
                    checkboxClassName='font-size-18 fw-bold'
                    onChange={(value) => props.onChanged(item.name, value)} />
                <NsTypography variant='inherit' className='d-block light-paragraph'>
                    {item.boxValue}
                </NsTypography>
            </div>
        </div>
    );
}

export default UpProfileNotificationSetting;