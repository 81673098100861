import React, { useEffect, useState } from 'react';

import { GetApShippingPlansService, GetShippingQueueByEstimatedTimeService } from '../../../../../../services/adminPanel/AdminShippingService';
import NsAdminPanelContentWrapper from '../../../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import {  createShippingQueueEstimatedTimeColumns } from '../../bases/ApBaseShippingHelper';
import ApShippingQueueByEstimatedTimeSearch from './ApShippingQueueByEstimatedTimeSearch';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import ApShippingDetailModal from '../../list/ApShippingDetailModal';
import NsTab from '../../../../../../components/tabs/NsTab';

export function ApShippingQueueByEstimatedTimeContent(props) {

    const [searchData, setSearchData] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(false);
    const [showShipmentDetailModal, setShowShipmentDetailModal] = useState(false);
    const [selectedShipping, setSelectedShipping] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [shippingPlans, setShippingPlans] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(null);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getShippingPlans();
    }, []);

    const getShippingPlans = () => GetApShippingPlansService(getApShippingPlansCallback);

    const getApShippingPlansCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            if (!isEmpty(resultData)) {
                resultData.map(data => {
                    data.id = data.key;
                    data.label = data.value
                })
                setShippingPlans(resultData);
                setSelectedTab(resultData[0]);
                setCallServiceDate(new Date());
            }
        } else
            setResultMessageClass(messageClassModal);
    };

    const toggleShippingDetailModal = () => setShowShipmentDetailModal(!showShipmentDetailModal);


    const tabChanged = (newValue, selectedTab) => {
        if (newValue !== selectedTabIndex) {
            setSelectedTabIndex(newValue);
            setSelectedTab(selectedTab);
            setCallServiceDate(new Date());
        }
    };

    const getSearchData = (data) => {
        setSearchData(data);
        setIsLoading(true);
        setCallServiceDate(new Date());
    };

    const getData = (data, isLoading) => setIsLoading(isLoading);

    const detailViewClicked = (id) => {
        setSelectedShipping(id);
        toggleShippingDetailModal();
    }

    const columns = createShippingQueueEstimatedTimeColumns(detailViewClicked);

    const parameters = {
        "Filter.UserIds": searchData?.selectedUser?.userId ?? [],
        "Filter.TransferStateTypes": searchData?.lastStatuses?.id ?? [],
        "Filter.EstimatedDeliveryTimeFrom": searchData?.estimatedDeliveryTimeFrom || null,
        "Filter.EstimatedDeliveryTimeTo": searchData?.estimatedDeliveryTimeTo || null,
        "Filter.ShippingLastStatusTimeFrom": searchData?.shippingLastStatusTimeFrom || null,
        "Filter.ShippingLastStatusTimeTo": searchData?.shippingLastStatusTimeTo || null,
        "Filter.SubmitTimeFrom": searchData?.submitTimeFrom || null,
        "Filter.SubmitTimeTo": searchData?.submitTimeTo || null,
        "Filter.NasInvoiceIds[0]": searchData?.invoiceNo || [],
        "Filter.RequestNumbers[0]": searchData?.requestNumber || [],
        "Filter.ReceiptTransferToIds[0]": searchData?.transerNumber || [],
        "Filter.ShippingPlanTypeId": selectedTab?.key || null,
        "Filter.ShippingMethodTypeId": searchData?.shippingMethod?.key || [],
        "Filter.orderProductType": searchData?.orderProductType?.id || [],
    };

    return (
        <>
            <ApShippingQueueByEstimatedTimeSearch customerDetails={props?.customerDetails}
                setSearchData={getSearchData}
                isLoading={isLoading}
                selectedTab={selectedTab}
                tabChanged={tabChanged} />

            <NsTab tabs={shippingPlans}
                value={selectedTabIndex}
                className='border-bottom'
                onChange={tabChanged} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetShippingQueueByEstimatedTimeService}
                parameters={parameters}
                getData={getData}
                columnDefs={columns}
                hasPagination={true} />

            {showShipmentDetailModal &&
                <ApShippingDetailModal showModal={true}
                    selectedShipmentId={selectedShipping}
                    closeModal={toggleShippingDetailModal} />
            }
            {resultMessageClass}
        </>
    );
}

const ApShippingQueueByEstimatedTime = () => {
    return (<NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.queueByRemainingToERDGroupSheets")}
        icon={'ns-icon-remaining-estimated h1'}>
        <ApShippingQueueByEstimatedTimeContent />
    </NsAdminPanelContentWrapper>);
}

export default ApShippingQueueByEstimatedTime;