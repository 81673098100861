import Enumerations from "../../configs/Enumerations"
import CurrentUserHelper from "../../utilities/CurrentUserHelper"
import StorageHelper from "../../utilities/StorageHelper"

const RESETTOUR = 'RESET_TOUR'

const initState = { tours: [] }

const getProcessTourGuides = (id) => {
    switch (id) {
        case 2:
            return [Enumerations.tourGuide.product]
        case 16:
        case 4:
            StorageHelper.saveInStorage(Enumerations.storage.showAddToListProfessionalOrderModal, false)
            return [
                Enumerations.tourGuide.professionalProductDetail,
                Enumerations.tourGuide.poBeforeSelectingFile,
                Enumerations.tourGuide.poSelectingFile,
                Enumerations.tourGuide.poChoosingFileSummary,
                Enumerations.tourGuide.poExtendedServiceSummary,
                Enumerations.tourGuide.poAddToCurrentList,
                Enumerations.tourGuide.poAfterClickingAddToCurrentList,
                Enumerations.tourGuide.poSubmit,
                Enumerations.tourGuide.poExtendedServiceSummary,
            ]
        case 11:
        case 15:
            return [
                Enumerations.tourGuide.regularProductDetails,
                Enumerations.tourGuide.masterFile,
                Enumerations.tourGuide.chooseFileModal,
                Enumerations.tourGuide.preview,
                Enumerations.tourGuide.filmFile,
                Enumerations.tourGuide.chooseFilmModal,
                Enumerations.tourGuide.filterFile,
                Enumerations.tourGuide.chooseFilterModal,
                Enumerations.tourGuide.foldLine,
                Enumerations.tourGuide.roundedCorner,
                Enumerations.tourGuide.punch,
                Enumerations.tourGuide.choosePunchModal,
                Enumerations.tourGuide.confirmDetails,
                Enumerations.tourGuide.roExtendedServiceSummary
            ]
        case 17:
            return [
                Enumerations.tourGuide.fileManager,
                Enumerations.tourGuide.fileManagerDetail
            ]
        default:
            break;
    }
}

export const resetTourActionCreators = {
    setResetTour: (data) => (
        {
            type: RESETTOUR,
            tours: data
        }
    ),
}

export const reducer = (state = initState, action) => {
    const selectedTours = !!action.tours && getProcessTourGuides(action.tours[action.tours.length - 1])
    !!selectedTours && CurrentUserHelper.resetTour(selectedTours)
    return null
}