import React from 'react';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsTextField from '../../../components/inputs/NsTextField';
import NsModal from '../../../components/modals/NsModal';
import Enumerations from '../../../configs/Enumerations';
import { CreateFolderService } from '../../../services/fileManager/FolderManagementService';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import { messageClass } from '../../../utilities/MessageModalHelper';
import { fileOrFolderNameIsValid } from '../../../utilities/ValidationHelper';
import ModalTitleRoute from '../bases/ModalTitleRoute';


class FmCreateFolderModal extends React.Component {
    constructor(props) {
        super(props);
        this.mainButtonList = [
            { label: "fileManager.modals.create.submit", onClicked: this.confirmClicked, className: 'primary' },
            { label: "common.cancel", onClicked: () => this.closeClicked(), className: 'primary-outline' },
        ];
        this.state = {
            currentFolderId: props.currentFolderId || null,
            newFolderName: '',
            isValidNewFolderName: false,
            showValidationDate: '',
            isLoading: false,
            resultMessageClass: (<React.Fragment></React.Fragment>)
        }
    }

    newFolderNameChanged = (value, isValid) => {
        this.setState({
            newFolderName: value,
            isValidNewFolderName: isValid,

        });
    }

    closeClicked = (isRefreshNeeded) => CommonHelper.safeFunctionCall(this.props.onClose, isRefreshNeeded);

    confirmClicked = () => {
        if (!this.state.isValidNewFolderName) {
            this.setState({ showValidationDate: new Date() })
            return false;
        }

        if (fileOrFolderNameIsValid(this.state.newFolderName)) {
            this.setState({ isLoading: true }, () => {
                let data = {
                    name: this.state.newFolderName,
                    parentId: !!this.state.currentFolderId ? this.state.currentFolderId : null
                }
                CreateFolderService(data, this.createFolderCallback);
            });
        } else {
            this.setState({
                resultMessageClass:
                    messageClass(getTranslatedRawText("common.messages.folderValidationError"))
            });
        }
    }

    createFolderCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.closeClicked(true);
        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    render() {
        const { showValidationDate, newFolderName } = this.state;
        const headerTitle = <div className='d-flex text-nowrap align-items-baseline '>
            {getTranslatedRawText("fileManager.modals.create.title")}
            <ModalTitleRoute treeViewFolders={this.props.treeViewFolders} currentFolderId={this.props.currentFolderId} />
        </div>

        return (
            <NsModal maxWidth="xs"
                primaryActionButton={this.confirmClicked}
                dialogTitle={headerTitle}
                showModal={this.props.showModal}
                closeModal={this.closeClicked}
                actions={<NsStickyButton stickyButtons={this.mainButtonList} isModal={true} isLoading={this.state.isLoading} />}
            >
                <NsTextField label={getTranslatedRawText("fileManager.modals.create.inputLabel")}
                    showValidationDate={showValidationDate}
                    autoFocus={true}
                    className="w-100 my-4 text-muted"
                    minLength={1}
                    value={newFolderName}
                    valueChanged={this.newFolderNameChanged} />

                {this.state.resultMessageClass}
            </NsModal>
        );
    }
}

export default FmCreateFolderModal;