import React from 'react';

import { GetStoreProductOrderQueueStatusService } from '../../../../../services/product/StoreProductService';
import UpBaseQueueOrdersContent from './bases/UpBaseQueueOrdersContent';

const UpQueueOrdersStore = (props) => {

    return (
        <UpBaseQueueOrdersContent service={GetStoreProductOrderQueueStatusService} {...props} />
    );
};

export default React.memo(UpQueueOrdersStore);