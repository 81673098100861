import React, { useState } from 'react';

import NsAdvanceSearch from '../../../../../../components/advanceSearch/NsAdvanceSearch';
import ApBaseSearchButtons from '../../../../bases/ApBaseSearchButtons';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import Enumerations from '../../../../../../configs/Enumerations';
import NsButton from '../../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import ApBaseUserSelectModal from '../../../../bases/ApBaseUserSelectModal';
import { hasAccess } from '../../../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../../../configs/UserClaimEnumerations';

const ApAgreementUserAcceptedSearchSection = ({ setSearchData, agreementId, isLoading }) => {

    const [user, setUser] = useState(null);
    const [acceptedTimeFrom, setAcceptedTimeFrom] = useState(null);
    const [acceptedTimeTo, setAcceptedTimeTo] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showUserSelectModal, setShowUserSelectModal] = useState(false);

    const searchValues = {
        user,
        acceptedTimeFrom,
        acceptedTimeTo
    };

    const usersModalButtonClicked = () => setShowUserSelectModal(!showUserSelectModal);

    const searchOptions = [
        hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            onChange: (value) => setUser(value),
            endAdornment: <NsButton className='light-circle'
                loading={isLoading}
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" />
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('adminPanel.singlePage.acceptedTimeFrom'),
            fieldName: 'acceptedTimeFrom',
            value: acceptedTimeFrom,
            onChange: (value) => setAcceptedTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('adminPanel.singlePage.acceptedTimeTo'),
            fieldName: 'acceptedTimeTo',
            value: acceptedTimeTo,
            onChange: (value) => setAcceptedTimeTo(value)
        },
    ];


    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user?.phoneNumber);
        setShowUserSelectModal(false);
    }

    const clearButtonClicked = () => {
        setUser('');
        setSelectedUser('');
        setAcceptedTimeFrom(null);
        setAcceptedTimeTo(null);
        CommonHelper.safeFunctionCall(setSearchData, { id: agreementId });
    }

    const searchClicked = () => {
        let data = {
            id: agreementId,
            'Filter.UserId': selectedUser?.userId,
            'Filter.FromAcceptTime': acceptedTimeFrom,
            'Filter.ToAcceptTime': acceptedTimeTo
        }
        CommonHelper.safeFunctionCall(setSearchData, data);
    }

    return <div>
        <NsAdvanceSearch searchOptions={searchOptions}
            searchValues={searchValues}
            mdCol={4}
            smCol={6} />
        <ApBaseSearchButtons searchClicked={searchClicked}
            clearButtonClicked={clearButtonClicked}
            isLoading={isLoading} />

        {showUserSelectModal &&
            <ApBaseUserSelectModal showModal={true}
                userSelected={userSelected}
                closeModal={() => setShowUserSelectModal(false)} />
        }
    </div>
};

export default ApAgreementUserAcceptedSearchSection;