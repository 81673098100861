const ServerClaimEnumerations = {
    None: 0,
    Patch_Api_Culture_AdminPanel_AnnouncementContent_ChangeSortNumber: 32,
    Delete_Api_Culture_AdminPanel_AnnouncementContent_Id: 33,
    Get_Api_Culture_AdminPanel_AnnouncementContent: 34,
    Get_Api_Culture_AdminPanel_AnnouncementContent_Id: 35,
    Patch_Api_Culture_AdminPanel_AnnouncementContent_Id: 36,
    Post_Api_Culture_AdminPanel_AnnouncementContent: 37,
    Delete_Api_Culture_AdminPanel_Blog_Id: 42,
    Get_Api_Culture_AdminPanel_Blog: 43,
    Get_Api_Culture_AdminPanel_Blog_Id: 44,
    Patch_Api_Culture_AdminPanel_Blog_Id: 45,
    Post_Api_Culture_AdminPanel_Blog: 46,
    Delete_Api_Culture_AdminPanel_BlogCategory_Id: 48,
    Get_Api_Culture_AdminPanel_BlogCategory: 49,
    Get_Api_Culture_AdminPanel_BlogCategory_Id: 50,
    Patch_Api_Culture_AdminPanel_BlogCategory_Id: 51,
    Post_Api_Culture_AdminPanel_BlogCategory: 52,
    Get_Api_Culture_AdminPanel_CustomerFiles_Id_Download: 55,
    Get_Api_Culture_AdminPanel_CustomerFiles: 56,
    Get_Api_Culture_AdminPanel_CustomerFiles_Id: 57,
    Get_Api_Culture_AdminPanel_Dashboard_SmsPanelsCredit: 58,
    Delete_Api_Culture_AdminPanel_News_Id: 69,
    Get_Api_Culture_AdminPanel_News: 70,
    Get_Api_Culture_AdminPanel_News_Id: 71,
    Patch_Api_Culture_AdminPanel_News_Id: 72,
    Post_Api_Culture_AdminPanel_News: 73,
    Delete_Api_Culture_AdminPanel_NewsCategory_Id: 75,
    Get_Api_Culture_AdminPanel_NewsCategory: 76,
    Get_Api_Culture_AdminPanel_NewsCategory_Id: 77,
    Patch_Api_Culture_AdminPanel_NewsCategory_Id: 78,
    Post_Api_Culture_AdminPanel_NewsCategory: 79,
    Get_Api_Culture_AdminPanel_PaymentsAdmins: 80,
    Get_Api_Culture_AdminPanel_PaymentsAdmins_Id: 81,
    Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_OrderPaymentType: 83,
    Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_Orders: 84,
    Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_Report: 85,
    Patch_Api_Culture_AdminPanel_PaymentsAdmins_Id_Status: 86,
    Get_Api_Culture_AdminPanel_PromoteContent_Id: 88,
    Patch_Api_Culture_AdminPanel_PromoteContent_Id: 89,
    Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_BillBookOptions: 90,
    Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_FilterPoints: 91,
    Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_FoldLines: 92,
    Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_GetAdditionalInformation: 93,
    Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_PunchPoints: 94,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins: 97,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id: 98,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_OrderDetail: 100,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Report_Id: 101,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_StateHistories: 102,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_StatusQueue: 104,
    Patch_Api_Culture_AdminPanel_Slider_ChangeSortNumber: 105,
    Delete_Api_Culture_AdminPanel_Slider_Id: 106,
    Get_Api_Culture_AdminPanel_Slider: 107,
    Get_Api_Culture_AdminPanel_Slider_Id: 108,
    Patch_Api_Culture_AdminPanel_Slider_Id: 109,
    Post_Api_Culture_AdminPanel_Slider: 110,
    Get_Api_Culture_AdminPanel_UserAccess_ClaimValueTypes: 111,
    Patch_Api_Culture_AdminPanel_UserAccess_ClaimValueTypes_Id: 112,
    Get_Api_Culture_AdminPanel_UserDashboard_TotalUsers: 113,
    Get_Api_Culture_AdminPanel_UserDashboard_LastJoinedUsers: 273,
    Get_Api_Culture_AdminPanel_Blog_BasicDetails: 275,
    Get_Api_Culture_AdminPanel_News_BasicDetails: 276,
    Post_Api_Culture_AdminPanel_FileUploader: 277,
    Patch_Api_Culture_AdminPanel_StaticPageAdmin_ChangeSortNumber: 278,
    Delete_Api_Culture_AdminPanel_StaticPageAdmin_Id: 279,
    Get_Api_Culture_AdminPanel_StaticPageAdmin: 280,
    Get_Api_Culture_AdminPanel_StaticPageAdmin_Id: 281,
    Patch_Api_Culture_AdminPanel_StaticPageAdmin_Id: 282,
    Post_Api_Culture_AdminPanel_StaticPageAdmin: 283,
    Delete_Api_Culture_AdminPanel_StoreProductProperties_Id: 284,
    Get_Api_Culture_AdminPanel_StoreProductProperties_Id: 285,
    Patch_Api_Culture_AdminPanel_StoreProductProperties_Id: 286,
    Post_Api_Culture_AdminPanel_StoreProductProperties: 287,
    Delete_Api_Culture_AdminPanel_StoreProducts_Id: 288,
    Get_Api_Culture_AdminPanel_StoreProducts_Id: 289,
    Get_Api_Culture_AdminPanel_StoreProducts: 290,
    Patch_Api_Culture_AdminPanel_StoreProducts_Id: 291,
    Post_Api_Culture_AdminPanel_StoreProducts: 292,
    Delete_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts_Id: 293,
    Get_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts_Id: 294,
    Get_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts: 295,
    Patch_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts_Id: 296,
    Post_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts: 297,
    Delete_Api_Culture_AdminPanel_StoreProductSupplierPrices_Id: 298,
    Get_Api_Culture_AdminPanel_StoreProductSupplierPrices_Id: 299,
    Get_Api_Culture_AdminPanel_StoreProductSupplierPrices: 300,
    Patch_Api_Culture_AdminPanel_StoreProductSupplierPrices_Id: 301,
    Post_Api_Culture_AdminPanel_StoreProductSupplierPrices: 302,
    Delete_Api_Culture_AdminPanel_StoreProductUnits_Id: 303,
    Get_Api_Culture_AdminPanel_StoreProductUnits_Id: 304,
    Patch_Api_Culture_AdminPanel_StoreProductUnits_Id: 305,
    Post_Api_Culture_AdminPanel_StoreProductUnits: 306,
    Get_Api_Culture_AdminPanel_Agency_BasicDetails: 319,
    Get_Api_Culture_AdminPanel_Agency: 320,
    Get_Api_Culture_AdminPanel_Agency_Id: 321,
    Get_Api_Culture_AdminPanel_Agency_GetProducts: 322,
    Get_Api_Culture_AdminPanel_Festival: 323,
    Get_Api_Culture_AdminPanel_Festival_Id: 324,
    Get_Api_Culture_AdminPanel_Festival_Id_Prizes: 325,
    Get_Api_Culture_AdminPanel_Festival_Id_Winners: 326,
    Get_Api_Culture_AdminPanel_Festival_Id_Winners_actionId: 327,
    Delete_Api_Culture_AdminPanel_FileUploader: 328,
    Get_Api_Culture_AdminPanel_FileUploader: 329,
    Get_Api_Culture_AdminPanel_UserDashboard_InactiveUsers: 331,
    Get_Api_Culture_AdminPanel_Agency_LatestSubmittedAgencies: 338,
    Delete_Api_Culture_AdminPanel_DownloadCategory_Id: 341,
    Get_Api_Culture_AdminPanel_DownloadCategory_DropDown: 342,
    Get_Api_Culture_AdminPanel_DownloadCategory: 343,
    Get_Api_Culture_AdminPanel_DownloadCategory_Id: 344,
    Patch_Api_Culture_AdminPanel_DownloadCategory_Id: 345,
    Post_Api_Culture_AdminPanel_DownloadCategory: 346,
    Delete_Api_Culture_AdminPanel_DownloadDetails_Id: 347,
    Get_Api_Culture_AdminPanel_DownloadDetails_Id: 348,
    Patch_Api_Culture_AdminPanel_DownloadDetails_Id: 349,
    Post_Api_Culture_AdminPanel_DownloadDetails: 350,
    Delete_Api_Culture_AdminPanel_Downloads_Id: 351,
    Get_Api_Culture_AdminPanel_Downloads_Id_DownloadDetails: 352,
    Get_Api_Culture_AdminPanel_Downloads: 353,
    Get_Api_Culture_AdminPanel_Downloads_Id: 354,
    Patch_Api_Culture_AdminPanel_Downloads_Id: 355,
    Post_Api_Culture_AdminPanel_Downloads: 356,
    Get_Api_Culture_AdminPanel_Gallery_BasicDetails: 357,
    Delete_Api_Culture_AdminPanel_Gallery_Id: 358,
    Get_Api_Culture_AdminPanel_Gallery: 359,
    Get_Api_Culture_AdminPanel_Gallery_Id: 360,
    Patch_Api_Culture_AdminPanel_Gallery_Id: 361,
    Post_Api_Culture_AdminPanel_Gallery: 362,
    Delete_Api_Culture_AdminPanel_GalleryCategory_Id: 363,
    Get_Api_Culture_AdminPanel_GalleryCategory: 364,
    Get_Api_Culture_AdminPanel_GalleryCategory_Id: 365,
    Patch_Api_Culture_AdminPanel_GalleryCategory_Id: 366,
    Post_Api_Culture_AdminPanel_GalleryCategory: 367,
    Get_Api_Culture_AdminPanel_Agency_ActiveStates: 391,
    Get_Api_Culture_AdminPanel_Agency_PopularAgencies: 392,
    Get_Api_Culture_AdminPanel_Agency_TotalSalesAmount: 393,
    Get_Api_Culture_AdminPanel_BusinessDashboard_BusinessOnlinePaymentByStatus: 398,
    Get_Api_Culture_AdminPanel_BusinessDashboard_BusinessOrderByApplicationType: 399,
    Get_Api_Culture_AdminPanel_BusinessDashboard_BusinessOrderByTurnaround: 400,
    Get_Api_Culture_AdminPanel_BusinessDashboard_BusinessTotalAndAverageOrder: 401,
    Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialOnlinePaymentByStatus: 402,
    Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialOnlinePaymentsGateway: 403,
    Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialOrderByApplicationType: 404,
    Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialOrderByTurnaround: 405,
    Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialSaleGadgets: 406,
    Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialTotalAndAverageOrder: 407,
    Patch_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_ChangeFailedStatus: 420,
    Get_Api_Culture_AdminPanel_SharedSheetOrderReport_StatisticalReportOfOrders: 426,
    Get_Api_Culture_AdminPanel_SharedSheetOrderReport_StatisticalReportOfPayments: 427,
    Delete_Api_Culture_AdminPanel_Advertisement_Id: 429,
    Get_Api_Culture_AdminPanel_Advertisement_Id: 431,
    Patch_Api_Culture_AdminPanel_Advertisement_Id: 432,
    Post_Api_Culture_AdminPanel_Advertisement: 433,
    Get_Api_Culture_AdminPanel_CmsLog: 434,
    Get_Api_Culture_AdminPanel_CmsLog_Id: 435,
    Get_Api_Culture_AdminPanel_CmsLog_GetCustomLogException: 436,
    Get_Api_Culture_AdminPanel_CmsLog_GetExceptionTypes: 437,
    Get_Api_Culture_AdminPanel_CmsLog_GetSources: 438,
    Get_Api_Culture_AdminPanel_PaymentsAdmins_IncompletedProcessPayments: 439,
    Patch_Api_Culture_AdminPanel_PaymentsAdmins_ResolveProblem: 440,
    Delete_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_Delete: 441,
    Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner_Api: 442,
    Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner_ApiChart: 443,
    Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner: 444,
    Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner_Id: 445,
    Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner_UsefulApi: 446,
    Get_Api_Culture_AdminPanel_UserBusinessPartner: 447,
    Get_Api_Culture_AdminPanel_UserBusinessPartner_Id: 448,
    Patch_Api_Culture_AdminPanel_UserBusinessPartner_Id: 449,
    Get_Api_Culture_AdminPanel_UserBusinessPartner_UserAbstractById_Id: 450,
    Get_Api_Culture_AdminPanel_UserCustomer: 451,
    Get_Api_Culture_AdminPanel_UserCustomer_Id: 452,
    Patch_Api_Culture_AdminPanel_UserCustomer_Id: 453,
    Get_Api_Culture_AdminPanel_UserCustomer_UserAbstractById_Id: 454,
    Get_Api_Culture_AdminPanel_UserSupporter_AdminUserAbstractByIdQuery: 455,
    Get_Api_Culture_AdminPanel_UserSupporter: 456,
    Get_Api_Culture_AdminPanel_UserSupporter_Id: 457,
    Patch_Api_Culture_AdminPanel_UserSupporter_Id: 458,
    Get_Api_Culture_AdminPanel_Advertisement: 477,
    Get_Api_Culture_AdminPanel_PaymentAudits_Id: 478,
    Get_Api_Culture_AdminPanel_PaymentAudits: 479,
    Get_Api_Culture_AdminPanel_PaymentAudits_PaymentGateways: 480,
    Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_AsanPardakhts_actionId: 481,
    Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_AsanPardakhts: 482,
    Post_Api_Culture_AdminPanel_PaymentAuditSources: 483,
    Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_Sepehrs_actionId: 484,
    Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_Sepehrs: 485,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_ProductDetail: 486,
    Get_Api_Culture_AdminPanel_PaymentAuditDashboards_AggregateStatisticsBasedOnAuditedPaymentsAndNotFoundPayments: 491,
    Get_Api_Culture_AdminPanel_PaymentAuditDashboards_NotProcessedPaymentAuditCount: 492,
    Get_Api_Culture_AdminPanel_PaymentAuditDashboards_PaymentGatewaysMessages: 493,
    Get_Api_Culture_AdminPanel_PaymentAuditDashboards_StatisticsBasedOnAuditedAndPaymentStatuses: 494,
    Get_Api_Culture_AdminPanel_PaymentAuditDashboards_StatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes: 495,
    Patch_Api_Culture_AdminPanel_PaymentAudits_Id_ChangePaymentStateToFail: 496,
    Get_Api_Culture_AdminPanel_PaymentAudits_Id_Histories: 497,
    Patch_Api_Culture_AdminPanel_PaymentAudits_Id_UpdateMatchingType: 498,
    Get_Api_Culture_AdminPanel_PaymentAuditSources: 499,
    Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_IncreaseCreditDetails_actionId: 500,
    Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_StatusHistories_actionId: 501,
    Get_Api_Culture_AdminPanel_PprContents_ByName: 502,
    Get_Api_Culture_AdminPanel_PprContents: 503,
    Patch_Api_Culture_AdminPanel_PprContents_Id: 504,
    Post_Api_Culture_AdminPanel_PprContents: 505,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_UserAgreementDetail_Id: 506,

    Patch_Api_Culture_AdminPanel_UserAgreements_ActivateUserAgreementState_Id: 507,
    Delete_Api_Culture_AdminPanel_UserAgreements_Id: 508,
    Get_Api_Culture_AdminPanel_UserAgreements: 509,
    Get_Api_Culture_AdminPanel_UserAgreements_Id: 510,
    Patch_Api_Culture_AdminPanel_UserAgreements_Id: 511,
    Post_Api_Culture_AdminPanel_UserAgreements: 512,
    Post_Api_Culture_AdminPanel_UserAgreements_Id_StaticPages: 513,
    Get_Api_Culture_AdminPanel_UserAgreements_Id_StaticPages: 514,
    Delete_Api_Culture_AdminPanel_UserAgreements_StaticPages_Id: 515,
    Patch_Api_Culture_AdminPanel_UserAgreements_Id_StaticPages_actionId: 516,
    Get_Api_Culture_AdminPanel_UserAgreements_UserAgreementDetail_Id: 517,
    Get_Api_Culture_AdminPanel_UserAgreementSigns_Histories_Id: 518,
    Get_Api_Culture_AdminPanel_UserAgreementSigns_UserInfo_Id: 519,
    Get_Api_Culture_AdminPanel_UserAgreementSigns_Users_Id: 520,
    Post_Api_Culture_AdminPanel_UserCustomer_DisableToken: 521,

    Post_Api_Culture_AdminPanel_PaymentAudits_AllPaymentAuditsExcelReport: 551,
    Patch_Api_Culture_AdminPanel_PaymentAudits_UpdateMatchingTypes: 552,
    Post_Api_Culture_AdminPanel_PaymentsAdmins_AllPaymentsExcelReport: 553,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_StatusQueueByServerNameFileRegulator: 554,
    Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin: 555,
    Get_Api_Culture_AdminPanel_UserAgreements_StaticPageDetail_Id: 556,
    // Get_Api_Culture_AdminPanel_UserBusinessPartner_ApiKeyWithAbstractDetail_Id: 557,
    Get_Api_Culture_AdminPanel_UserBusinessPartner_UserAbstract_Id: 558,
    Get_Api_Culture_AdminPanel_UserCustomer_DuplicatePhoneNumbers: 559,

    Patch_Api_Culture_AdminPanel_DemandedServiceTypes_ChangeSortNumber: 592,
    Delete_Api_Culture_AdminPanel_DemandedServiceTypes_Id: 593,
    Get_Api_Culture_AdminPanel_DemandedServiceTypes_Id: 594,
    Get_Api_Culture_AdminPanel_DemandedServiceTypes_GetAll: 595,
    Patch_Api_Culture_AdminPanel_DemandedServiceTypes_Id: 596,
    Post_Api_Culture_AdminPanel_DemandedServiceTypes: 597,
    Get_Api_Culture_AdminPanel_FeedbackToAnnouncementContents: 599,
    Get_Api_Culture_AdminPanel_FeedbackToSharedSheetOrders_Id_BasicInfo_actionId: 600,
    Get_Api_Culture_AdminPanel_FeedbackToSharedSheetOrders: 601,
    Patch_Api_Culture_AdminPanel_FeedbackTypeMetrics_ChangeSortNumber: 602,
    Delete_Api_Culture_AdminPanel_FeedbackTypeMetrics_Id: 603,
    Get_Api_Culture_AdminPanel_FeedbackTypeMetrics_Id_FeedbackTypeMetrics: 604,
    Get_Api_Culture_AdminPanel_FeedbackTypeMetrics_Id: 605,
    Patch_Api_Culture_AdminPanel_FeedbackTypeMetrics_Id: 606,
    Post_Api_Culture_AdminPanel_FeedbackTypeMetrics: 607,
    Get_Api_Culture_AdminPanel_FeedbackTypes_BasicInfo: 608,
    Patch_Api_Culture_AdminPanel_FeedbackTypes_ChangeFeedbackTypeState_Id: 609,
    Get_Api_Culture_AdminPanel_FeedbackTypes: 610,
    //is not done
    // Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin_Id: 611,
    // Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin_GetStoreProductNames: 612,
    // Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin_Id_GetStoreProductOrderDetailInfo: 613,
    // Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin_Id_OrderDetail: 614,
    // Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin_Id_StateHistories: 615,
    // Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin_StatusQueue: 616,
    // Get_Api_Culture_AdminPanel_UserBusinessPartner_ResetSecureApiKey: 617,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_Comments: 619,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByFeedbackTypeMetrics: 620,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByFeedbackTypeMetricTypes: 621,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByProducts: 622,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByStateTypes: 623,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbacks: 624,
    Get_Api_Culture_AdminPanel_UserFeedbacks_ActivityHistory_Id: 625,
    Post_Api_Culture_AdminPanel_UserFeedbacks_AddState: 626,
    Get_Api_Culture_AdminPanel_UserFeedbacks_GetUserFeedbackGridDetails: 627,
    Get_Api_Culture_AdminPanel_UserFeedbacks_GetUserFeedbacks: 628,
    Patch_Api_Culture_AdminPanel_UserFeedbacks_IsPublic_Id: 629,

    Get_Api_Culture_AdminPanel_FeedbackToAnnouncementContents_Id_BasicInfo_actionId: 641,
    // Get_Api_Culture_AdminPanel_NotificationMessages : 645,
    Get_Api_Culture_AdminPanel_UserCashbackDashboard_GetUsersCashbackForecasts: 646,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_NasInvoiceSheetDetail_Id: 647,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_OrderPreparationEstimatedTimeSheets_Id: 649,

    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByFeedbackTypeMetricAndRates: 654,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByStateTypesAndDate: 655,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackStatuses: 656,
    Get_Api_Culture_AdminPanel_PprContents_GetDetail: 657,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_NasInvoiceSheetStateHistories: 658,
    Get_Api_Culture_AdminPanel_UserDepartment_DepartmentTrees: 660,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByCities: 663,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByCustomers: 664,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByProvinces: 665,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByStateTypesFilterByCommentsAndDemandServices: 666,

    // Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_OfficialInvoiceStatus_Id: 670,
    // Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_OfficialReport_Id: 671,
    // Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_UnOfficialReport_Id: 672,
    Get_Api_Culture_AdminPanel_UserAddressesAdmin_Actives: 676,
    // Get_Api_Culture_AdminPanel_UserCustomer_ToggleUserPublicProfileStatus: 677,
    // Get_Api_Culture_AdminPanel_UserCustomer_UserProfileQrCode: 678,
    Get_Api_Culture_AdminPanel_UserDashboard_UsersRemainingLimitWithCredit: 679,
    Get_Api_Culture_AdminPanel_UserDashboard_UsersRemainingLimitWithoutCredit: 680,
    Post_Api_Culture_AdminPanel_UserDepartment_AddUserDepartment: 681,
    Delete_Api_Culture_AdminPanel_UserDepartment_Id: 682,
    Get_Api_Culture_AdminPanel_UserDepartment: 683,
    Get_Api_Culture_AdminPanel_UserDepartment_Id: 684,
    Patch_Api_Culture_AdminPanel_UserDepartment_Id: 685,
    Post_Api_Culture_AdminPanel_UserDepartment: 686,
    Get_Api_Culture_AdminPanel_UserDepartment_UserDepartmentTrees: 687,
    Get_Api_Culture_AdminPanel_FeedbackToEmployees_Id_BasicInfo_actionId: 700,
    Get_Api_Culture_AdminPanel_FeedbackToEmployees: 701,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_QueueByRemainingToEstimateReadyToDeliverGroupSheet: 702,
    // Post_Api_Culture_AdminPanel_StoreProductOrdersAdmin_RetryToReadyState: 703,
    Get_Api_Culture_AdminPanel_UserDashboard_UserGrantedCredits: 704,
    Get_Api_Culture_AdminPanel_UserDepartment_Departments: 705,

    Post_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_QueueByRemainingToEstimateReadyToDeliverGroupSheetExcelReport: 707,
    Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin_OfficialReport_Id: 711,
    Get_Api_Culture_AdminPanel_UserCustomer_Id_Credit: 712,
    Patch_Api_Culture_AdminPanel_UserSupporter_Id_GrantedCredit: 713,

    Get_Api_Culture_AdminPanel_SharedSheetOrderReport_UsersDebitCredit: 718,
    Get_Api_Culture_AdminPanel_UserCashbackDashboard_FromInquiryTimes: 719,
    Get_Api_Culture_AdminPanel_UserCashbackDashboard_ToInquiryTimes: 720,
    Get_Api_Culture_AdminPanel_UserCustomer_Id_FieldVisibilityType: 721,
    // Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByAnnouncementContents: 722,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByEmployees: 723,

    Get_Api_Culture_AdminPanel_PprContents_BasicInfo: 733,
    Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_DailyQueueOfEstimateReadyToDeliverByLastStatusOfGroupSheetOrders: 734,
    Get_Api_Culture_AdminPanel_Shipping: 735,
    Get_Api_Culture_AdminPanel_Shipping_Id: 736,
    Get_Api_Culture_AdminPanel_Shipping_GetDeliveryRequestOrders: 737,
    Get_Api_Culture_AdminPanel_Shipping_GetDeliveryRequestStoreProductOrders: 738,
    Get_Api_Culture_AdminPanel_Shipping_GetShippingMethods: 739,
    Get_Api_Culture_AdminPanel_Shipping_GetShippingPlans: 740,
    Get_Api_Culture_AdminPanel_Shipping_QueueByLastStatuses: 741,
    Get_Api_Culture_AdminPanel_Shipping_QueueByShippingMethods: 742,
    Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackSharedSheetOrders: 744,

    Get_Api_Culture_AdminPanel_Shipping_ShippingQueueByRemainingToEstimatedReadyToDeliver: 764,
    Get_Api_Culture_AdminPanel_UserCashbackDashboard_GetUsersCashbackForecastsExcelReport: 765,
    Get_Api_Culture_AdminPanel_UserCashbackDashboard_UserCashbackForecastWithGrowthPercent: 766,

    Get_Api_Culture_AdminPanel_AnnouncementContent_AnnouncementContentTitleById_Id: 769,
    Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_Ngenius_actionId: 770,
    Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_Ngenius: 771,
    Get_Api_Culture_AdminPanel_UserSupporter_UserAbstractById_Id: 772

}

const UserClaimEnumerations = {
    none: 0,
    //Financial DashBoard
    getApDfSaleGadgets: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialSaleGadgets,
    getApDfTotalSales: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialTotalAndAverageOrder,
    getApDfOrderByApplicationType: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialOrderByApplicationType,
    getApDfTotalOnlinePayments: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialOnlinePaymentByStatus,
    getApDfOrderByTurnaround: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialOrderByTurnaround,
    getApDfOnlinePaymentsGateway: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FinancialDashboard_FinancialOnlinePaymentsGateway,
    //Business Dashboard
    getApDbTotalSales: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_BusinessDashboard_BusinessTotalAndAverageOrder,
    getApDbTotalOrders: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_BusinessDashboard_BusinessOrderByTurnaround,
    getApDbOnlinePaymentStatus: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_BusinessDashboard_BusinessOnlinePaymentByStatus,
    getApDbTotalOnlinePayments: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_BusinessDashboard_BusinessOrderByApplicationType,
    //User Dashboard
    getApDuTotalUsers: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDashboard_TotalUsers,
    getApDuLastJoinedUsers: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDashboard_LastJoinedUsers,
    getApDuInactiveUsers: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDashboard_InactiveUsers,
    getApDuRemainingLimitWithCredit: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDashboard_UsersRemainingLimitWithCredit,
    getApDuRemainingLimitWithoutCredit: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDashboard_UsersRemainingLimitWithoutCredit,
    getApDuGrantedCredit: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDashboard_UserGrantedCredits,
    //Sms Panel Dashboard
    getApDSmsPanelCredit: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Dashboard_SmsPanelsCredit,
    //Agency Dashboard
    getApDaLatestSubmittedAgencies: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Agency_LatestSubmittedAgencies,
    getApDaTotalSalesAmountAgencies: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Agency_TotalSalesAmount,
    getApDaPopularAgencies: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Agency_PopularAgencies,
    getApDaActiveStates: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Agency_ActiveStates,
    //Business Partner Dashboard
    // Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner_Api: 442,
    // Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner_ApiChart: 443,
    // Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner: 444,
    // Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner_Id: 445,
    // Get_Api_Culture_AdminPanel_SubmittedApiBusinessPartner_UsefulApi: 446,
    //Audit Dashboard
    getApDauAggregateStatistics: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditDashboards_AggregateStatisticsBasedOnAuditedPaymentsAndNotFoundPayments,
    getApDauNotProcessedPaymentAuditCount: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditDashboards_NotProcessedPaymentAuditCount,
    getApDauPaymentGatewaysMessages: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditDashboards_PaymentGatewaysMessages,
    getApDauStatisticsBasedOnAuditedAndPaymentStatuses: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditDashboards_StatisticsBasedOnAuditedAndPaymentStatuses,
    getApDauStatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditDashboards_StatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes,
    //Dashboard Feedback
    getApDfbComments: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_Comments,
    getApDfbRatingParametersChart: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByFeedbackTypeMetrics,
    getApDfbStrengthWeeknessChart: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByFeedbackTypeMetricTypes,
    getApDfbProducts: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByProducts,
    getApDfbTotalByStatusGedgets: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByStateTypes,
    getApDfbUserFeedbacks: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbacks,
    getApDfbByTypeMetricAndRates: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByFeedbackTypeMetricAndRates,
    getApDfbByStateTypesAndDateChart: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByStateTypesAndDate,
    getApDfbStatuses: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackStatuses,
    getApDfbCitiesGrid: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByCities,
    getApDfbCustomers: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByCustomers,
    getApDfbProvinces: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByProvinces,
    getApDfbTotalStatusByFilterOptions: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByStateTypesFilterByCommentsAndDemandServices,
    getApDfbSharedSheetOrderFeedback: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackSharedSheetOrders,
    getApDfbEmployeesGrid: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbackDashboard_UserFeedbackAggregateByEmployees,
    //Cashback Dashboard
    getApCshbUsersCashbackForecastes: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCashbackDashboard_GetUsersCashbackForecasts,
    getApCshbFromInquiryTimes: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCashbackDashboard_FromInquiryTimes,
    getApCshbToInquiryTimes: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCashbackDashboard_ToInquiryTimes,
    getApCshbExcelReport: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCashbackDashboard_GetUsersCashbackForecastsExcelReport,
    getApCshbForcastsWithGrowthPercent: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCashbackDashboard_UserCashbackForecastWithGrowthPercent,
    //Customers
    getApCustomers: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCustomer,
    getApCustomerById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCustomer_Id,
    editApCustomer: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserCustomer_Id,
    getApUserBasicInfoById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCustomer_UserAbstractById_Id,
    updateApCustomerToken: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_UserCustomer_DisableToken,
    getApCustomersDuplicatePhoneNumbers: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCustomer_DuplicatePhoneNumbers,
    getApUserCredit: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCustomer_Id_Credit,
    getApCustomerFieldVisibilityType: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserCustomer_Id_FieldVisibilityType,
    //Supporters
    getApSupporters: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserSupporter,
    getApSupporterById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserSupporter_Id,
    getApSupporterBasicInfoById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserSupporter_UserAbstractById_Id,
    editApSupporter: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserSupporter_Id,
    getApClaimValueTypes: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAccess_ClaimValueTypes,
    editApClaimValueTypes: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserAccess_ClaimValueTypes_Id,
    editApUserCredit: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserSupporter_Id_GrantedCredit,
    // ServerClaimEnumerations.Get_Api_Culture_AdminPanel_BasicInformationAdmins_FilterUsers,
    //UserBusinessPartner
    getApUserBusinessPartners: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserBusinessPartner,
    getApUserBusinessPartnerById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserBusinessPartner_Id,
    editApUserBusinessPartner: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserBusinessPartner_Id,
    // getApUserBusinessPartnerApiKeyWithAbstractDetailById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserBusinessPartner_ApiKeyWithAbstractDetail_Id,
    getApUserBusinessPartnerBasicInfoById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserBusinessPartner_UserAbstract_Id,
    //User addresses
    getApUserActiveAddresses: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAddressesAdmin_Actives,
    //User Departments
    postApAddUserDepartment: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_UserDepartment_AddUserDepartment,
    getApUserDepartments: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDepartment,
    getApUserDepartmentById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDepartment_Id,
    postApUserDepartment: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_UserDepartment,
    editApUserDepartment: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserDepartment_Id,
    deleteApUserDepartment: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_UserDepartment_Id,
    getApDepartmentsTree: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDepartment_DepartmentTrees,
    getApUserDepartmentsTree: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDepartment_UserDepartmentTrees,
    getApDepartmentsList: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserDepartment_Departments,
    //Orders
    getApOrders: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins,
    getApOrderDto: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id,
    getApOrderDetail: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_OrderDetail,
    updateApOrderStatus: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_ChangeFailedStatus,
    getApOrderStatusHistory: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_StateHistories,
    getApOrderNasStatusHistory: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_NasInvoiceSheetStateHistories,
    getApOrderNasStatusHistoryDetail: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_NasInvoiceSheetDetail_Id,
    deleteApNotConfirmedOrder: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_Delete,
    getApOrderUnOfficialReport: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Report_Id,
    getApOrderOfficialReport: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Report_Id,
    getApStatusQueue: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_StatusQueue,
    getApOrderFilterPoints: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_FilterPoints,
    getApOrderBillBookOptions: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_BillBookOptions,
    getApOrderPunchPoints: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_PunchPoints,
    getApOrderFoldLines: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_FoldLines,
    getApOrderAdditionalInformation: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrderDetailOptionAdmin_Id_GetAdditionalInformation,
    getApOrderProductDetail: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_ProductDetail,
    getApOrderUserAggrementDetail: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_UserAgreementDetail_Id,
    getApStatusQueueByServerName: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_StatusQueueByServerNameFileRegulator,
    getApOrderEstimatedTime: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_OrderPreparationEstimatedTimeSheets_Id,
    getApOrderQueueByRemainingToEstimatedTime: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_QueueByRemainingToEstimateReadyToDeliverGroupSheet,
    getApOrderQueueByRemainingToEstimatedTimeExcelReport: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_QueueByRemainingToEstimateReadyToDeliverGroupSheetExcelReport,
    getApDailyQueueOfEstimateTime: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_DailyQueueOfEstimateReadyToDeliverByLastStatusOfGroupSheetOrders,
    //Store Orders
    getApStoreOrders: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin,
    getApStoreOrderOfficialReport: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProductOrdersAdmin_OfficialReport_Id,
    // getApStoreOrderDto: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id,
    // getApStoreOrderDetail: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_OrderDetail,
    // getApStoreOrderStatusHistory: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Id_StateHistories,
    // getApStoreOrderReport: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Report_Id,
    getApStoreStatusQueue: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrdersAdmins_Report_Id,
    getApOrdersExcelReport: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_PaymentsAdmins_AllPaymentsExcelReport,
    //Payments
    getApPayments: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentsAdmins,
    getApPaymentById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentsAdmins_Id,
    getApPaymentOrders: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_Orders,
    getApPaymentIncreaseCredit: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_IncreaseCreditDetails_actionId,
    getApPaymentStatusHistory: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_StatusHistories_actionId,
    getApPaymentReport: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_Report,
    updateApPaymentStatus: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_PaymentsAdmins_Id_Status,
    editResolveOrderPaymentProblem: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_PaymentsAdmins_ResolveProblem,
    getApPaymentsHasProblem: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentsAdmins_IncompletedProcessPayments,
    getApAllPaymentsExcelReport: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_PaymentsAdmins_AllPaymentsExcelReport,
    //ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentsAdmins_Id_OrderPaymentType,
    //Audits
    getApPaymentAudits: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAudits,
    getApPaymentAuditById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAudits_Id,
    getApPaymentAuditGateways: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAudits_PaymentGateways,
    updateApPaymentAuditStateToFail: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_PaymentAudits_Id_ChangePaymentStateToFail,
    getApPaymentAuditHistories: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAudits_Id_Histories,
    updateApPaymentAuditMatchingType: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_PaymentAudits_Id_UpdateMatchingType,
    getApAllPaymentAuditsExcelReport: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_PaymentAudits_AllPaymentAuditsExcelReport,
    updateApPaymentAuditsMatchingTypes: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_PaymentAudits_UpdateMatchingTypes,
    //Audit Resources
    getApPaymentAuditAsanPardakhts: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_AsanPardakhts,
    getApPaymentAuditAsanPardakhtByActionId: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_AsanPardakhts_actionId,
    getApPaymentAuditSepehrs: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_Sepehrs,
    getApPaymentAuditSepehtByActionId: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_Sepehrs_actionId,
    getApPaymentAuditNgenius: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_Ngenius,
    getApPaymentAuditNgeniusByActionId: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditSources_Id_Ngenius_actionId,
    postApPaymentAuditSources: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_PaymentAuditSources,
    getApPaymentAuditSources: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PaymentAuditSources,
    //RePorts
    getApStatisticalReportOfOrders: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrderReport_StatisticalReportOfOrders,
    getApStatisticalReportOfPayments: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrderReport_StatisticalReportOfPayments,
    getApUsersDebitCredit: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_SharedSheetOrderReport_UsersDebitCredit,
    //Shippings
    getApShippings: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Shipping,
    getApShippingById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Shipping_Id,
    getApShippingGroupSheetOrders: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Shipping_GetDeliveryRequestOrders,
    getApShippingStoreOrders: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Shipping_GetDeliveryRequestStoreProductOrders,
    getApShippingQueueByLastStatuses: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Shipping_QueueByLastStatuses,
    getApShippingRemainingEstimatedTime: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Shipping_ShippingQueueByRemainingToEstimatedReadyToDeliver,
    getApQueueByShippingMethods: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Shipping_QueueByShippingMethods,
    getApShippingMethods: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Shipping_GetShippingMethods,
    getApShippingPlans: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Shipping_GetShippingPlans,
    //Customer Files
    getApCustomerFiles: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_CustomerFiles,
    getApCustomerFileById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_CustomerFiles_Id,
    getApDownloadOriginFile: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_CustomerFiles_Id_Download,
    //PPR Content
    getApPprContents: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PprContents,
    getApPprContentByPprId: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PprContents_GetDetail,
    getApPprContentByName: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PprContents_ByName,
    postApPprContent: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_PprContents,
    editApPprContentById: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_PprContents_Id,
    getApPprContentBasicInfo: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PprContents_BasicInfo,
    //Feedback Central
    getApFeedbackAnnouncements: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackToAnnouncementContents,
    getApFeedbackAnnouncementBasicInfo: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackToAnnouncementContents_Id_BasicInfo_actionId,

    getApFeedbackShareSheetOrders: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackToSharedSheetOrders,
    getApFeedbackShareSheetOrderBasicInfo: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackToSharedSheetOrders_Id_BasicInfo_actionId,

    getApFeedbackToEmployees: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackToEmployees,
    getApFeedbackToEmployeeBasicInfo: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackToEmployees_Id_BasicInfo_actionId,


    getApFeedbackActivityHistory: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserFeedbacks_ActivityHistory_Id,
    updateApFeedbackIsPublic: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserFeedbacks_IsPublic_Id,
    postApFeedbackAddState: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_UserFeedbacks_AddState,
    //Feedbacks
    getApFeedbackTypes: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackTypes,
    getApFeedbackTypeBasicInfo: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackTypes_BasicInfo,
    updateApFeedbackTypeState: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_FeedbackTypes_ChangeFeedbackTypeState_Id,
    //Feedback metrics
    getApFeedbackTypeMetrics: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackTypeMetrics_Id_FeedbackTypeMetrics,
    getApFeedbackTypeMetricById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FeedbackTypeMetrics_Id,
    postApFeedbackTypeMetric: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_FeedbackTypeMetrics,
    editApFeedbackTypeMetric: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_FeedbackTypeMetrics_Id,
    deleteApFeedbackTypeMetric: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_FeedbackTypeMetrics_Id,
    editApSortFeedbackTypeMetric: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_FeedbackTypeMetrics_ChangeSortNumber,
    //Feedback Demand service metric
    getApFeedbackTypeDemandServices: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_DemandedServiceTypes_GetAll,
    getApFeedbackTypeDemandServiceById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_DemandedServiceTypes_Id,
    postApFeedbackTypeDemandService: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_DemandedServiceTypes,
    editApFeedbackTypeDemandService: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_DemandedServiceTypes_Id,
    deleteApFeedbackTypeDemandService: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_DemandedServiceTypes_Id,
    editApSortFeedbackTypeDemandServices: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_DemandedServiceTypes_ChangeSortNumber,

    //Blogs
    getApBlogs: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Blog,
    getApBlogById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Blog_Id,
    postApBlog: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_Blog,
    editApBlog: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_Blog_Id,
    deleteApBlog: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_Blog_Id,
    getApBlogCategories: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_BlogCategory,
    getApBasicBlogCategories: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Blog_BasicDetails,
    getApBlogCategoryById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_BlogCategory_Id,
    deleteApBlogCategory: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_BlogCategory_Id,
    editApBlogCategory: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_BlogCategory_Id,
    postApBlogCategory: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_BlogCategory,
    //News
    getApNews: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_News,
    getApNewsById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_News_Id,
    postApNews: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_News,
    editApNews: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_News_Id,
    deleteApNews: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_News_Id,
    getApNewsCategories: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_NewsCategory,
    getApBasicNewsCategories: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_News_BasicDetails,
    getApNewsCategoryById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_NewsCategory_Id,
    postApNewsCategory: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_NewsCategory,
    editApNewsCategory: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_NewsCategory_Id,
    deleteApNewsCategory: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_NewsCategory_Id,
    //Announcements
    getApAnnouncements: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_AnnouncementContent,
    getApAnnouncementById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_AnnouncementContent_Id,
    getApAnnouncementTitleContentById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_AnnouncementContent_AnnouncementContentTitleById_Id,
    postApAnnouncement: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_AnnouncementContent,
    editApAnnouncement: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_AnnouncementContent_Id,
    editApSortAnnouncement: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_AnnouncementContent_ChangeSortNumber,
    deleteApAnnouncement: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_AnnouncementContent_Id,
    //singlePages
    getApSpecificStaticPageList: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StaticPageAdmin,
    getApSpecificStaticPageRowById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StaticPageAdmin_Id,
    postApSpecificStaticPageRow: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_StaticPageAdmin,
    editApSpecificStaticPageRow: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_StaticPageAdmin_Id,
    editApSortStaticPageAdmin: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_StaticPageAdmin_ChangeSortNumber,
    deleteApSpecificStaticPageRow: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_StaticPageAdmin_Id,
    //fileUploader
    postFileUploader: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_FileUploader,
    deleteFileUploader: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_FileUploader,
    getFileUploader: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_FileUploader,
    //Sliders
    getApSliders: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Slider,
    getApSliderById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Slider_Id,
    postApSlider: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_Slider,
    editApSlider: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_Slider_Id,
    editApSortSlider: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_Slider_ChangeSortNumber,
    deleteApSlider: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_Slider_Id,
    //Promotions
    getApPromoteContent: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_PromoteContent_Id,
    editApPromoteContent: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_PromoteContent_Id,
    //storeProducts
    deleteApStoreProductProperty: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_StoreProductProperties_Id,
    getApStoreProductProperty: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProductProperties_Id,
    editApStoreProductProperty: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_StoreProductProperties_Id,
    postApStoreProductProperty: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_StoreProductProperties,
    deleteApStoreProduct: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_StoreProducts_Id,
    getApStoreProduct: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProducts_Id,
    getApStoreProducts: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProducts,
    editApStoreProduct: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_StoreProducts_Id,
    postApStoreProduct: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_StoreProducts,
    deleteApStoreProductSupplierPriceDiscount: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts_Id,
    getApStoreProductSupplierPriceDiscountsById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts_Id,
    getApStoreProductSupplierPriceDiscounts: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts,
    editApStoreProductSupplierPriceDiscounts: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts_Id,
    postApStoreProductSupplierPriceDiscounts: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_StoreProductSupplierPriceDiscounts,
    deleteApStoreProductSupplierPrices: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_StoreProductSupplierPrices_Id,
    getApStoreProductSupplierPricesById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProductSupplierPrices_Id,
    getApStoreProductSupplierPrices: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProductSupplierPrices,
    editApStoreProductSupplierPrices: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_StoreProductSupplierPrices_Id,
    postApStoreProductSupplierPrices: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_StoreProductSupplierPrices,
    deleteApStoreProductUnits: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_StoreProductUnits_Id,
    getApStoreProductUnits: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_StoreProductUnits_Id,
    editApStoreProductUnits: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_StoreProductUnits_Id,
    postApStoreProductUnits: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_StoreProductUnits,
    //agency
    getApAgencyBasicDetails: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Agency_BasicDetails,
    getApAgencies: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Agency,
    getApAgencyById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Agency_Id,
    getApProductsOfAgency: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Agency_GetProducts,
    //festival
    getApFestivals: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Festival,
    getApFestivalById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Festival_Id,
    getApFestivalPrizes: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Festival_Id_Prizes,
    getApFestivalWinners: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Festival_Id_Winners,
    getApFestivalWinnersActionId: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Festival_Id_Winners_actionId,
    //log exceptions
    getApCmsLogs: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_CmsLog,
    getApCmsLogById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_CmsLog_Id,
    getApCmsLogException: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_CmsLog_GetCustomLogException,
    getApCmsLogExceptionType: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_CmsLog_GetExceptionTypes,
    getApCmsLogSources: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_CmsLog_GetSources,
    //downloads
    getApDownloadCategories: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_DownloadCategory,
    getApDownloadCategoriesDropDown: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_DownloadCategory_DropDown,
    getApDownloadCategoryById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_DownloadCategory_Id,
    postApDownloadCategory: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_DownloadCategory,
    editApDownloadCategory: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_DownloadCategory_Id,
    deleteApDownloadsCategory: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_DownloadCategory_Id,
    getApDownloads: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Downloads,
    getApDownloadById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Downloads_Id,
    postApDownload: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_Downloads,
    editApDownload: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_Downloads_Id,
    deleteApDownloads: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_Downloads_Id,
    getApDownloadsByIdDetails: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Downloads_Id_DownloadDetails,
    getApDownloadDetailsById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_DownloadDetails_Id,
    postApDownloadDetails: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_DownloadDetails,
    editApDownloadDetails: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_DownloadDetails_Id,
    deleteApDownloadDetails: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_DownloadDetails_Id,
    //galleries
    getApGalleries: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Gallery,
    getApGalleryBasicDetails: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Gallery_BasicDetails,
    getApGalleryById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Gallery_Id,
    postApGallery: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_Gallery,
    editApGallery: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_Gallery_Id,
    deleteApGallery: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_Gallery_Id,
    getApGalleryCategories: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_GalleryCategory,
    getApGalleryCategoryById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_GalleryCategory_Id,
    postApGalleryCategory: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_GalleryCategory,
    editApGalleryCategory: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_GalleryCategory_Id,
    deleteApGalleryCategory: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_GalleryCategory_Id,
    //advertisement
    getApAdvertisements: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Advertisement,
    getApAdvertisementById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_Advertisement_Id,
    postApAdvertisement: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_Advertisement,
    editApAdvetisement: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_Advertisement_Id,
    deleteAdvertisement: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_Advertisement_Id,
    //Aggremets
    getApUserAggrements: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAgreements,
    getApUserAggrementById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAgreements_Id,
    getApUserAggrementDetailById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAgreements_UserAgreementDetail_Id,
    postApUserAggrement: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_UserAgreements,
    updateApUserAggrement: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserAgreements_Id,
    deleteApUserAggrement: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_UserAgreements_Id,
    getApUserAggrementStaticPages: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAgreements_Id_StaticPages,
    postApUserAggrementStaticPages: ServerClaimEnumerations.Post_Api_Culture_AdminPanel_UserAgreements_Id_StaticPages,
    editApUserAggrementStaticPages: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserAgreements_Id_StaticPages_actionId,
    deleteApUserAggrementStaticPages: ServerClaimEnumerations.Delete_Api_Culture_AdminPanel_UserAgreements_StaticPages_Id,
    getApUserAgreementsStaticPageDetailById: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAgreements_StaticPageDetail_Id,
    updateApUserAggrementState: ServerClaimEnumerations.Patch_Api_Culture_AdminPanel_UserAgreements_ActivateUserAgreementState_Id,
    getApUserAggrementSignHistories: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAgreementSigns_Histories_Id,
    getApUserAggrementSignUserInfo: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAgreementSigns_UserInfo_Id,
    getApUserAggrementSignUsers: ServerClaimEnumerations.Get_Api_Culture_AdminPanel_UserAgreementSigns_Users_Id,
}

export default UserClaimEnumerations;