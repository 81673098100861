import React, { useEffect, useState, useRef } from 'react';

import { GetStatisticalReportOfOrdersService } from '../../../../services/adminPanel/AdminReportService';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import { getApStatisticalReportOfOrdersColumn } from './ApStaticalReportHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApStatisticalReportSearch from './ApStatisticalReportSearch';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import DateHelper from '../../../../utilities/DateHelper';

const ApStatisticalReportOfOrders = () => {

    const columns = getApStatisticalReportOfOrdersColumn();
    const avoidingRunUseEffectForFirstTime = useRef(true);

    const [isLoading, setIsLoading] = useState(true);
    const [searchData, setSearchData] = useState([]);
    const [callServiceDate, setCallServiceDate] = useState(null);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData]);

    const getParameters = () => {
        let parameters = {
            "Filter.FromTime": DateHelper.convertDateTimeToDate(searchData?.reportDateFrom || DateHelper.getPreviousWeek()),
            "Filter.ToTime": DateHelper.convertDateTimeToDate(searchData?.reportDateTo || new Date()),
        }

        if (!isEmpty(searchData?.orderApplicationTypes)) {
            parameters["filter.OrderApplicationTypes"] = searchData.orderApplicationTypes?.id
        } else if (!isEmpty(searchData?.orderTypes)) {
            let orderApplicationType = ConstantsWithTranslation.getOrderApplicationType(searchData.orderTypes.id);
            for (let index = 0; index < orderApplicationType.length; index++) {
                parameters["filter.OrderApplicationTypes[" + index + "]"] = orderApplicationType[index]?.id;
            }
        }
        return parameters;
    };

    const searchClicked = (data) => setSearchData(data);

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.title.statisticalReportOfOrders")}
            icon={'ns-icon-reports h1'}>

            <ApStatisticalReportSearch isLoading={isLoading}
                isBusiness={true}
                searchClicked={searchClicked} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetStatisticalReportOfOrdersService}
                parameters={getParameters()}
                columnDefs={columns}
                getData={getData} />
        </NsAdminPanelContentWrapper>
    );
};

export default React.memo(ApStatisticalReportOfOrders);