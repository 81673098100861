import React from "react";

import ApBaseFeedbackStrengthsWeaknesses from "./ApBaseFeedbackStrengthsWeaknesses";
import NsAutocomplete from "../../../../components/dropdowns/NsAutoComplete";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsRatingGroup from "../../../../components/ratings/NsRatingGroup";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import Enumerations from "../../../../configs/Enumerations";

const ApBaseFeedbackMetrics = (props) => {
    const { metricTypeId, items, className, size, readOnly, precision, onChange, selectedDemandService, containerClassName } = props;

    const createMetricComponent = () => {
        switch (metricTypeId) {
            case Enumerations.feedbackMetricTypes.ratingparamenter: return isEmpty(items) ? '' : <NsRatingGroup className={className}
                ratings={items}
                size={size}
                containerClassName={containerClassName}
                readOnly={readOnly}
                precision={precision}
                onChange={onChange} />

            case Enumerations.feedbackMetricTypes.demandService: return isEmpty(items) ? '' : <NsAutocomplete label={getTranslatedRawText('adminPanel.feedback.demandService')}
                items={items}
                value={selectedDemandService}
                onChange={onChange}
                className={className} />

            case Enumerations.feedbackMetricTypes.strengths:
            case Enumerations.feedbackMetricTypes.weaknesses: return isEmpty(items) ? '' : <ApBaseFeedbackStrengthsWeaknesses
                items={items}
                onChange={onChange}
                className={className}
            />
            default: return <></>
        }
    }

    return createMetricComponent();
}

export default React.memo(ApBaseFeedbackMetrics);