import React, { useEffect, useState } from 'react';

import { GetApDfbByProvincesService } from '../../../../../services/adminPanel/AdminDashboardService';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBasesDashboardHelper from '../../bases/ApBasesDashboardHelper';
import NsIranSvgMap from '../../../../../components/svgMaps/NsIranSvgMap';
import NsUaeSvgMap from '../../../../../components/svgMaps/NsUaeSvgMap';
import NsSelect from '../../../../../components/dropdowns/NsSelect';
import Enumerations from '../../../../../configs/Enumerations';
import NsCard from '../../../../../components/cards/NsCard';
import Config from '../../../../../configs/Config';

const ApBasesDfbProvinceMap = ({ refreshDate, parameters }) => {

    const dashboardCountryMap = Config.dashboardsCountryMap;
    const [selectedValue, setSelectedValue] = useState(1);
    const [activeStates, setActiveStates] = useState([]);
    const [refreshMapDate, setRefreshMapDate] = useState(new Date());
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    const labels = [
        { id: 5, rateLabel: '4 - 5', percentLabel: '80-100 %', color: ApBasesDashboardHelper.getRatingParameterBackgroundColor(5) },
        { id: 4, rateLabel: '3 - 4', percentLabel: '60-80 %', color: ApBasesDashboardHelper.getRatingParameterBackgroundColor(4) },
        { id: 3, rateLabel: '2 - 3', percentLabel: '40-60 %', color: ApBasesDashboardHelper.getRatingParameterBackgroundColor(3) },
        { id: 2, rateLabel: '1 - 2', percentLabel: '20-40 %', color: ApBasesDashboardHelper.getRatingParameterBackgroundColor(2) },
        { id: 1, rateLabel: '0 - 1', percentLabel: '0-20 %', color: ApBasesDashboardHelper.getRatingParameterBackgroundColor(1) }
    ];

    useEffect(() => {
        GetApDfbByProvincesService(parameters, getApDfbByProvincesCallback);
    }, [refreshDate])

    useEffect(() => {
        setActiveStates(normalizeStatesObject(activeStates));
        setRefreshMapDate(new Date());
    }, [selectedValue])

    const getApDfbByProvincesCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) setActiveStates(normalizeStatesObject(resultData));
        else setMessageClassModal(messageClassModal);
    };

    const valueChanged = (id) => setSelectedValue(id);

    const normalizeStatesObject = (data) => {
        let instanceStates = data;
        if (selectedValue == 1) {
            instanceStates.map(state => {
                state.value = state.quantity;
                state.className = state.quantity >= 80 ? 'feedbacks--80100'
                    : state.quantity >= 60 && state.quantity < 80 ? 'feedbacks--6080'
                        : state.quantity >= 40 && state.quantity < 60 ? 'feedbacks--4060'
                            : state.quantity >= 20 && state.quantity < 40 ? 'feedbacks--2040'
                                : state.quantity < 20 ? 'feedbacks--020'
                                    : ''
            })
        } else {
            instanceStates.map(state => {
                state.value = state.averageRate;
                console.log(state.value);
                state.className = state.averageRate >= 4 ? 'feedbacks--80100'
                    : state.averageRate >= 3 && state.averageRate < 4 ? 'feedbacks--6080'
                        : state.averageRate >= 2 && state.averageRate < 3 ? 'feedbacks--4060'
                            : state.averageRate >= 1 && state.averageRate < 2 ? 'feedbacks--2040'
                                : state.averageRate < 1 ? 'feedbacks--020'
                                    : ''
            })
        }
        return instanceStates;
    }

    const countryMap = () => {
        switch (dashboardCountryMap) {
            case Enumerations.dashboardCountryMap.iran: return <NsIranSvgMap key={refreshMapDate} activeStates={activeStates} />
            case Enumerations.dashboardCountryMap.uae: return <NsUaeSvgMap key={refreshMapDate} activeStates={activeStates} />
            default: null
        }
    }

    return (
        <NsCard className="p-3 h-100">
            <div className='d-flex'>

                <div className='page-title mb-2'>{getTranslatedRawText('adminPanel.dashboard.province')}</div>
                <div className="d=flex ms-auto w-25">
                    <NsSelect options={ConstantsWithTranslation.getFeedbackFilterByValueOptions()}
                        valueChanged={valueChanged}
                        selectedItem={selectedValue} />
                </div>
            </div>

            <div className='d-flex mt-2 p-2'>
                <div className='m-0 mb-2 px-0 d-flex flex-column align-items-start'>
                    {labels.map((item, index) =>
                        <div key={index} className='m-0 mb-2 px-0 d-flex align-items-center'>
                            <div className='px-0 rounded ' style={{ backgroundColor: item.color, width: '.7rem', height: '.7rem' }}></div>
                            <div className='px-0 ps-3 text-nowrap' >{selectedValue !== 1 ? <><i className='ns-icon-star-fill' />{item.rateLabel}</> : item.percentLabel}</div>
                        </div>
                    )}
                </div>
                {countryMap()}
            </div>
            {messageClassModal}
        </NsCard >
    )
}

export default React.memo(ApBasesDfbProvinceMap);