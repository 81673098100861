
import Enumerations from "../configs/Enumerations";
import { getTranslatedRawText } from "./CultureHelper";

class EnumerationTranslationHelper {
    static getTurnaroundText(turnaround) {
        return turnaround === Enumerations.turnaround.normal
            ? getTranslatedRawText('regularOrder.header.normal')
            : turnaround === Enumerations.turnaround.express
                ? getTranslatedRawText('regularOrder.header.express')
                : ''
    }

    static getAccountTypeLabel = (accountType) => {
        return accountType === Enumerations.accountType.business
            ? getTranslatedRawText('components.accountType.business')
            : getTranslatedRawText('components.accountType.personal')
    }

    static getCountryNameById = (countryId) => {
        switch (countryId) {
            case Enumerations.countryId.azerbaijan: return getTranslatedRawText('profile.countryName.azerbaijan');
            case Enumerations.countryId.uae: return getTranslatedRawText('profile.countryName.uae');
            case Enumerations.countryId.oman: return getTranslatedRawText('profile.countryName.oman');
            case Enumerations.countryId.bahrain: return getTranslatedRawText('profile.countryName.bahrain');
            case Enumerations.countryId.qatar: return getTranslatedRawText('profile.countryName.qatar');
            case Enumerations.countryId.iran: return getTranslatedRawText('profile.countryName.iran');
            case Enumerations.countryId.kuwait: return getTranslatedRawText('profile.countryName.kuwait');
            case Enumerations.countryId.turkey: return getTranslatedRawText('profile.countryName.turkey');
            case Enumerations.countryId.iraq: return getTranslatedRawText('profile.countryName.iraq');
        }
    }

    static getNationalCodeNameById = (countryId) => {
        switch (countryId) {
            case Enumerations.countryId.azerbaijan: return getTranslatedRawText('profile.nationalCode.azerbaijan');
            case Enumerations.countryId.uae: return getTranslatedRawText('profile.nationalCode.emirates');
            case Enumerations.countryId.oman: return getTranslatedRawText('profile.nationalCode.oman');
            case Enumerations.countryId.bahrain: return getTranslatedRawText('profile.nationalCode.bahrain');
            case Enumerations.countryId.qatar: return getTranslatedRawText('profile.nationalCode.qatar');
            case Enumerations.countryId.iran: return getTranslatedRawText('profile.nationalCode.iran');
            case Enumerations.countryId.kuwait: return getTranslatedRawText('profile.nationalCode.kuwait');
            case Enumerations.countryId.turkey: return getTranslatedRawText('profile.nationalCode.turkey');
            case Enumerations.countryId.iraq: return getTranslatedRawText('profile.nationalCode.iraq');
            default: return getTranslatedRawText('profile.nationalCode.iran');
        }
    }

    static getLicenseNameByCountryId = (countryId) => {
        switch (countryId) {
            case Enumerations.countryId.azerbaijan: return getTranslatedRawText('account.signup.license');
            case Enumerations.countryId.uae: return getTranslatedRawText('account.signup.license');
            case Enumerations.countryId.oman: return getTranslatedRawText('account.signup.license');
            case Enumerations.countryId.bahrain: return getTranslatedRawText('account.signup.license');
            case Enumerations.countryId.qatar: return getTranslatedRawText('account.signup.license');
            case Enumerations.countryId.iran: return getTranslatedRawText('account.signup.iranLicense');
            case Enumerations.countryId.kuwait: return getTranslatedRawText('account.signup.license');
            case Enumerations.countryId.turkey: return getTranslatedRawText('account.signup.license');
            case Enumerations.countryId.iraq: return getTranslatedRawText('account.signup.license');
        }
    }

    static getPrintedSideText(printedSide) {
        return printedSide === Enumerations.printedSide.oneSide
            ? getTranslatedRawText('regularOrder.header.frontOnly')
            : printedSide === Enumerations.printedSide.twoSide
                ? getTranslatedRawText('regularOrder.header.frontAndBack')
                : ''
    }

    static getPaymentStatusTypeText(statusType) {
        return statusType === Enumerations.paymentStateType.success
            ? getTranslatedRawText('adminPanel.payments.success')
            : statusType === Enumerations.paymentStateType.open
                ? getTranslatedRawText('adminPanel.payments.open')
                : statusType === Enumerations.paymentStateType.failed
                    ? getTranslatedRawText('adminPanel.payments.failed')
                    : ''
    }

    static getPaymentTypeText(paymentType) {
        return paymentType === Enumerations.paymentType.increaseCredit
            ? getTranslatedRawText('adminPanel.payments.increaseCredit')
            : paymentType === Enumerations.paymentType.orderPayment
                ? getTranslatedRawText('adminPanel.payments.orderPayment')
                : ''
    }

    static setTranslateForPaymentProblemType = (paymentProblemType) => {
        switch (paymentProblemType) {
            case Enumerations.paymentProblemType.missingIncreaseCreditInNas:
                return getTranslatedRawText('adminPanel.payments.missingIncreaseCreditInNas');
            case Enumerations.paymentProblemType.someOrdersNotConfirmed:
                return getTranslatedRawText('adminPanel.payments.someOrdersNotConfirmed');
            case Enumerations.paymentProblemType.inAdvanceOrderNotFilled:
                return getTranslatedRawText('adminPanel.payments.inAdvanceOrderNotFilled');
            case Enumerations.paymentProblemType.someOrderHaveMoreThanOneSuccessfulPayment:
                return getTranslatedRawText('adminPanel.payments.moreThanOneSuccessfulPayment');
        }
    };

    static getPaymentAuditMatchingType(paymentAuditTypeId) {
        switch (paymentAuditTypeId) {
            case Enumerations.paymentAuditMatchingType.notProcessed:
                return [getTranslatedRawText('adminPanel.audit.notProcessed')];
            case Enumerations.paymentAuditMatchingType.paymentNotFound:
                return [getTranslatedRawText('adminPanel.audit.paymentNotFound')];
            case Enumerations.paymentAuditMatchingType.amountMismatch:
                return [getTranslatedRawText('adminPanel.audit.amountMismatch'), 'red-color'];
            case Enumerations.paymentAuditMatchingType.stateTypeMismatch:
                return [getTranslatedRawText('adminPanel.audit.stateTypeMismatch'), 'red-color'];
            case Enumerations.paymentAuditMatchingType.amountAndStateTypeMismatch:
                return [getTranslatedRawText('adminPanel.audit.amountAndStateTypeMismatch'), 'red-color'];
            case Enumerations.paymentAuditMatchingType.matched:
                return [getTranslatedRawText('adminPanel.audit.matched')];
            case Enumerations.paymentAuditMatchingType.alternativePaymentAttempt:
                return [getTranslatedRawText('adminPanel.audit.alternativePaymentAttempt')];
            case Enumerations.paymentAuditMatchingType.oldWebiteStoreProduct:
                return [getTranslatedRawText('adminPanel.audit.oldWebiteStoreProduct')];
        }
    }

    static getPaymentGatewayType(paymentGatewayTypeId) {
        switch (paymentGatewayTypeId) {
            case Enumerations.paymentGateway.asanPardakht:
                return (getTranslatedRawText('payment.gateways.asanPardakht'));
            case Enumerations.paymentGateway.behPardakht:
                return (getTranslatedRawText('payment.gateways.behPardakht'));
            case Enumerations.paymentGateway.iranKish:
                return (getTranslatedRawText('payment.gateways.iranKish'));
            case Enumerations.paymentGateway.sadad:
                return (getTranslatedRawText('payment.gateways.sadad'));
            case Enumerations.paymentGateway.saman:
                return (getTranslatedRawText('payment.gateways.saman'));
            case Enumerations.paymentGateway.nGeniusPayment:
                return (getTranslatedRawText('payment.gateways.nGeniusPayment'));
            case Enumerations.paymentGateway.sepehr:
                return (getTranslatedRawText('payment.gateways.sepehr'));
        }
    }

    static getTranslateAccountType(accountType) {
        switch (accountType) {
            case Enumerations.accountType.personal:
                return getTranslatedRawText('adminPanel.usersInfo.personal');
            case Enumerations.accountType.business:
                return getTranslatedRawText('adminPanel.usersInfo.business');
            default:
                return;
        };
    }

    static getModeOfPayment(paymentType) {
        switch (paymentType) {
            case 1:
                return getTranslatedRawText("adminPanel.payments.usedByDepositCredit")
                break;
            case 2:
                return getTranslatedRawText("adminPanel.payments.managerPermit")
                break;
            case 3:
                return getTranslatedRawText("adminPanel.payments.usedByBalance")
                break;
            case 4:
                return getTranslatedRawText("adminPanel.payments.onlinePayment")
                break;

            default:
                break;
        }
    }

    static getTranslateGenderType(genderType) {
        switch (genderType) {
            case Enumerations.gender.male:
                return getTranslatedRawText('components.genderType.male');
            case Enumerations.gender.female:
                return getTranslatedRawText('components.genderType.female');
            default:
                return;
        };
    }

    static getTranslateBookNumberOfCopyType(billBookCopyType) {
        switch (billBookCopyType) {
            case Enumerations.billBookCopyType.oneCopy:
                return {
                    label: getTranslatedRawText('regularOrder.billBook.oneCopy'),
                    iconClassName: "ns-icon-master"
                };
            case Enumerations.billBookCopyType.twoCopies:
                return {
                    label: getTranslatedRawText('regularOrder.billBook.twoCopies'),
                    iconClassName: "ns-icon-master-1-copy"
                };
            case Enumerations.billBookCopyType.threeCopies:
                return {
                    label: getTranslatedRawText('regularOrder.billBook.threeCopies'),
                    iconClassName: "ns-icon-master-2-copy"
                };
            case Enumerations.billBookCopyType.fourCopies:
                return {
                    label: getTranslatedRawText('regularOrder.billBook.fourCopies'),
                    iconClassName: "ns-icon-master-3-copy"
                };
            default:
                return;
        };
    }

    static getTranslateBookNumberOfPrintColorType(billBookPrintColorType) {
        switch (billBookPrintColorType) {
            case Enumerations.billBookPrintColorType.black:
                return getTranslatedRawText('regularOrder.billBook.black');
            case Enumerations.billBookPrintColorType.navyBlue:
                return getTranslatedRawText('regularOrder.billBook.navyBlue');
            case Enumerations.billBookPrintColorType.redAndBlack:
                return getTranslatedRawText('regularOrder.billBook.redAndBlack');
            case Enumerations.billBookPrintColorType.fourColors:
                return getTranslatedRawText('regularOrder.billBook.fourColors');
            default:
                return;
        };
    }

    static getTranslateBookBindingType(bindingType) {
        switch (bindingType) {
            case Enumerations.billBookBindingType.free:
                return {
                    label: getTranslatedRawText('regularOrder.billBook.free'),
                    iconClassName: 'ns-icon-free-papers'
                }
            case Enumerations.billBookBindingType.glue:
                return {
                    label: getTranslatedRawText('regularOrder.billBook.glue'),
                    iconClassName: 'ns-icon--Glue-Binding'
                }
            case Enumerations.billBookBindingType.stapler:
                return {
                    label: getTranslatedRawText('regularOrder.billBook.stapler'),
                    iconClassName: 'ns-icon-Stitching'
                }
            default:
                return;
        };
    }

    static getTranslateBookBindingSideType(bindingSideType) {
        switch (bindingSideType) {
            case Enumerations.billBookBindingSideType.up:
                return getTranslatedRawText('regularOrder.billBook.up');
            case Enumerations.billBookBindingSideType.right:
                return getTranslatedRawText('regularOrder.billBook.right');
            case Enumerations.billBookBindingSideType.down:
                return getTranslatedRawText('regularOrder.billBook.down');
            case Enumerations.billBookBindingSideType.left:
                return getTranslatedRawText('regularOrder.billBook.left');
            default:
                return;
        };
    }

    static getTranslategetFeedbackType(feedbackTypeId) {
        switch (feedbackTypeId) {
            case Enumerations.feedbackTypes.user:
                return getTranslatedRawText('adminPanel.sidebar.users');
            case Enumerations.feedbackTypes.sharedSheetOrder:
                return getTranslatedRawText('adminPanel.sidebar.shareSheets');
            case Enumerations.feedbackTypes.department:
                return getTranslatedRawText('adminPanel.sidebar.department');
            case Enumerations.feedbackTypes.announcementContent:
                return getTranslatedRawText('adminPanel.sidebar.announcements');
            case Enumerations.feedbackTypes.storeProductOrder:
                return getTranslatedRawText('adminPanel.sidebar.stores');
            default:
                return;
        };
    }

    static getTranslategetFeedbackState(status) {
        switch (status) {
            case Enumerations.feedbackStatus.pending:
                return getTranslatedRawText('adminPanel.feedback.pending');
            case Enumerations.feedbackStatus.inprocess:
                return getTranslatedRawText('adminPanel.feedback.inprocess');
            case Enumerations.feedbackStatus.archive:
                return getTranslatedRawText('adminPanel.feedback.archive');
            default:
                return getTranslatedRawText('adminPanel.common.all');;
        };
    }

    static getActivationStatus = (activationStatus) => {
        if (activationStatus)
            return getTranslatedRawText('profile.address.active')
        else
            return getTranslatedRawText('profile.address.inactive')
    }

    static getYesOrNoStatus = (status) => {
        if (!!status)
            return getTranslatedRawText('common.yes')
        else
            return getTranslatedRawText('common.no')
    }

    static getProductType = (id) => {
        switch (id) {
            case Enumerations.orderProductType.none:
                return '';
            case Enumerations.orderProductType.sharedSheet:
                return getTranslatedRawText('adminPanel.orders.groupSheet');
            case Enumerations.orderProductType.store:
                return getTranslatedRawText('adminPanel.orders.store');
            case Enumerations.orderProductType.dedicated:
                return getTranslatedRawText('adminPanel.orders.dedicated');
            default:
                return '';
        };
    }

    static getShippingPaymentType = (ShippingPaymentType) => {
		switch (ShippingPaymentType) {
			case Enumerations.shippingPaymentType.none: return;
			case Enumerations.shippingPaymentType.prepaid: return getTranslatedRawText('profile.userInfo.prepaid');
			case Enumerations.shippingPaymentType.postpaid: return getTranslatedRawText('profile.userInfo.postpaid');
			default: return;
		}
	}

    static getShippingPlanType = (shippingPlanType) => {
		switch (shippingPlanType) {
			case Enumerations.shippingPlanType.none: return;
			case Enumerations.shippingPlanType.standard: return getTranslatedRawText('profile.delivery.standard');
			case Enumerations.shippingPlanType.express: return getTranslatedRawText('profile.delivery.express');
			case Enumerations.shippingPlanType.byAgency: return getTranslatedRawText('profile.delivery.byAgency');
			default: return;
		}
	}
}

export default EnumerationTranslationHelper;