import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';

import { GetCitiesService, GetStatesService } from '../../../../services/ProfileSetting/DeliveryAddressSettingService';
import { EditPersonalSettingService, GetExpireUserTokenService, GetPersonalSettingService, GetProfileFieldVisibilityTypeService } from '../../../../services/ProfileSetting/PersonalSettingService';
import { isEmpty, trnIsValid } from '../../../../utilities/ValidationHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import NsNumberInputWithPattern from '../../../../components/inputs/NsNumberInputWithPattern';
import EnumerationTranslationHelper from '../../../../utilities/EnumerationTranslationHelper';
import { currentUserActionCreators } from '../../../../redux/reducers/CurrentUserReducer';
import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import NsToggleGroupButton from '../../../../components/buttons/NsToggleGroupButton';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import NsMessageClassModal from '../../../../components/modals/NsMessageClassModal';
import ProfileOfficialInvoiceModal from '../../modals/ProfileOfficialInvoiceModal';
import NsDatePicker from '../../../../components/dateTimePickers/NsDatePicker';
import { getEnumKeyFromValue } from '../../../../utilities/EnumerationHelper';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import CurrentUserHelper from '../../../../utilities/CurrentUserHelper';
import CoachMarksStatusModal from '../../modals/CoachMarksStatusModal';
import NsEmailInput from '../../../../components/inputs/NsEmailInput';
import NsAlertModal from '../../../../components/modals/NsAlertModal';
import NsTextField from '../../../../components/inputs/NsTextField';
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import NsLabelBox from '../../../../components/labels/NsLabelBox';
import NsSelect from '../../../../components/dropdowns/NsSelect';
import UserPanelWrapper from '../../UserPanelWrapper';
import UpBaseDefaultAddress from './bases/UpBaseDefaultAddress';
import UpBaseBusinessInputs from './bases/UpBaseBusinessInputs';
import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import DateHelper from '../../../../utilities/DateHelper';
import Links from '../../../../configs/links/AllLinks';
import Config from '../../../../configs/Config';

class UpProfileInformationSetting extends React.Component {

    constructor(props) {
        super(props);
        this.hasOfficialInvoiceIssue = Config.hasOfficialInvoiceIssue;
        this.mainButtons = [
            { label: "common.save", icon: 'ns-icon-save', onClicked: this.saveClicked, className: 'primary' },
            { label: "common.discard", icon: 'ns-icon-close', onClicked: this.discardClicked, className: 'primary-outline' },
        ];
        this.state = {
            isShowCoachMarkModal: false,
            isShowExpireTokenModal: false,
            showOfficialInvoiceModal: false,
            showChangePasswordModal: false,

            accountType: null,
            isValidAccountType: false,

            companyName: '',
            isValidCompanyName: false,

            license: '',
            isValidLicense: false,

            trn: '',
            isValidTrn: false,
            trnJsRegexPattern: null,
            trnMask: null,
            showValidationTrnDate: null,

            gender: '',
            isValidGender: false,

            firstName: '',
            isValidFirstName: false,

            lastName: '',
            isValidLastName: false,

            selectedCityId: null,
            cityList: [],
            isValidCity: false,

            selectedStateId: null,
            stateList: [],
            isValidState: false,

            selectedDefaultUserAddressId: null,
            isValidSelectedDefaultUserAddressId: false,

            idNumber: null,
            isValidIdNumber: false,
            showValidationIdNumberDate: false,
            nationalCodeJsRegexPattern: null,
            nationalCodeMask: null,

            birthDate: '',
            isValidBirthDate: false,

            email: '',
            isValidEmail: false,
            showEmailValidationDate: null,

            description: '',
            isValidDescription: false,

            showValidationDate: null,
            loading: false,
            rightPanelLoading: true,
            countryId: null,

            fieldVisibilityType: null,

            resultMessageClass: (<></>),

            isOfficialInvoiceActive: Enumerations.switchValues.off,

            isShowOfficialInvoiceModal: false,
            isOfficialInvoiceEnabled: false,

        };
        this.isFirstTime = true;
    }

    valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        this.setState({
            [valueName]: value,
            [isValidName]: isValid,
        }, () => {
            valueName === CommonHelper.getVariableName({ selectedStateId: null }) && this.getCities(value)
            valueName === CommonHelper.getVariableName({ trn: null }) && !isEmpty(this.state.trnJsRegexPattern) && this.setState({
                trnHelperText: isEmpty(value) || trnIsValid(parseInt(value), this.state.trnJsRegexPattern)
                    ? null
                    : CommonHelper.stringFormat(getTranslatedRawText('profile.personal.invalidInput'), getTranslatedRawText('profile.personal.trn')),
            })
        });
    }

    getCities = (value) => {
        !isEmpty(this.state.cityList) && this.setState({ selectedCityId: null, isValidCity: false });
        value && GetCitiesService({ stateId: value }, this.getCitiesCallback);
    }

    saveClicked = () => {

        if (!this.checkFormValidation())
            return false;
        this.setState({ showValidationDate: new Date() });
        const fieldVisibilityType = this.state.fieldVisibilityType;
        let data = {
            genderTypeId: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.genderTypeIdVisibilityType, this.state.gender),
            birthDate: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.birthDateVisibilityType, DateHelper.convertDateTimeToDate(new Date(this.state.birthDate))),
            firstName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.firstNameVisibilityType, this.state.firstName),
            lastName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.lastNameVisibilityType, this.state.lastName),
            cityId: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.cityIdVisibilityType, this.state.selectedCityId),
            nationalCode: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.nationalCodeVisibilityType, this.state.idNumber),
            isCompany: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.isCompanyVisibilityType, this.state.accountType === Enumerations.accountType.business),
            companyName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType, this.state.companyName),
            licenceNumber: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType, this.state.license),
            trn: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType, this.state.trn.replace(/_/g, '')),
            email: this.state.email,
            description: this.state.description,
            isOfficialInvoiceEnabled: this.state.isOfficialInvoiceActive === Enumerations.switchValues.on ? true : false,
            defaultUserAddressId: this.state.selectedDefaultUserAddressId,
            mobile: CurrentUserHelper.getMobile()
        };

        this.setState({ loading: true, rightPanelLoading: true },
            () => EditPersonalSettingService(data, this.editPersonalSettingCallback, true));
    }

    checkFormValidation = () => {
        let isFormValid = true;
        const isCompany = this.state.accountType === Enumerations.accountType.business;

        const isValidEmailAddress = isEmpty(this.state.email) || !!this.state.isValidEmail;

        const isValidOtherFieldsWithoutEmail = !!this.state.isValidGender && !!this.state.isValidBirthDate
            && !!this.state.isValidFirstName && !!this.state.isValidLastName && !!this.state.isValidCity && !!this.state.isValidState;

        if (!isValidEmailAddress) {
            isFormValid = false;
            this.setState({ showEmailValidationDate: new Date() });
        }
        if (!!this.state.idNumber && !this.state.isValidIdNumber) {
            isFormValid = false;
            this.setState({ showValidationIdNumberDate: new Date() });
        }

        if (!isValidOtherFieldsWithoutEmail) {
            isFormValid = false;
            this.setState({ showValidationDate: new Date() });
        }

        return isFormValid;
    }

    editPersonalSettingCallback = (resultData, result, resultMessageClass) => {
        this.setState({ loading: false, rightPanelLoading: false, resultMessageClass });
        if (!result?.data?.hasError)
            GetPersonalSettingService(this.getPersonalSettingCallback);
    }

    discardClicked = () => this.setState({ loading: true, rightPanelLoading: true },
        () => GetPersonalSettingService(this.getPersonalSettingCallback));

    getPersonalSettingCallback = (resultData, result, resultMessageClass) => {
        this.setState({ loading: false, rightPanelLoading: false });
        if (result.data && !result?.data?.hasError) {
            this.setState({
                accountType: resultData.isCompany ? Enumerations.accountType.business : Enumerations.accountType.personal,
                isValidAccountType: true,
                companyName: resultData.companyName || '',
                license: resultData.licenceNumber || '',
                trn: resultData.trn || '',
                selectedCityId: resultData.cityId,
                selectedStateId: resultData.stateId,
                gender: resultData.genderTypeId,
                isValidGender: true,
                firstName: resultData.firstName,
                isValidFirstName: true,
                lastName: resultData.lastName,
                isValidLastName: true,
                idNumber: resultData.nationalCode,
                birthDate: new Date(resultData.birthDate),
                isValidBirthDate: true,
                email: resultData.email,
                isValidEmail: true,
                description: resultData.description,
                isValidCity: true,
                isValidState: true,
                nationalCodeMask: resultData?.nationalCodeMask,
                isOfficialInvoiceActive: !!resultData.isOfficialInvoiceEnabled ? Enumerations.switchValues.on : Enumerations.switchValues.off,
                isOfficialInvoiceEnabled: !!resultData.isOfficialInvoiceEnabled,
                trnJsRegexPattern: resultData?.trnJsRegexPattern,
                trnMask: resultData?.trnMask,
                nationalCodeJsRegexPattern: resultData?.nationalCodeJsRegexPattern,
                countryId: resultData?.countryId,
                selectedDefaultUserAddressId: resultData?.defaultUserAddressId,
                isValidSelectedDefaultUserAddressId: true
            }, () => {
                CurrentUserHelper.set(getEnumKeyFromValue(Enumerations.storage, Enumerations.storage.customerAgency),
                    { customerMustHaveAgency: resultData?.customerMustHaveAgency, customerAgencyId: resultData?.agencyId });
                this.props.editCurrentUser({ firstName: resultData.firstName, lastName: resultData.lastName }),
                    isEmpty(this.state.cityList) && GetCitiesService({ stateId: resultData.stateId }, this.getCitiesCallback);
                this.isFirstTime = false;
                this.getFieldVisibilityType();
            });
        }
        else
            this.setState({ resultMessageClass });
    }

    getFieldVisibilityType = () => {
        this.setState({ loading: true });
        let parameters = {
            pageType: Enumerations.pageType.profileInformation,
            accountType: this.state.accountType,
            isOfficialInvoiceEnabled: this.state.isOfficialInvoiceEnabled
        };
        GetProfileFieldVisibilityTypeService(parameters, this.getFieldVisibilityTypeCallback);
    }

    getFieldVisibilityTypeCallback = (resultData, result, resultMessageClass) => {
        this.setState({ loading: false });
        if (!result?.data?.hasError)
            this.setState({ fieldVisibilityType: resultData });
        else
            this.setState({ resultMessageClass });
    }

    componentDidMount = () => {
        GetPersonalSettingService(this.getPersonalSettingCallback);
        GetStatesService({ id: CurrentUserHelper.getCountryId() }, this.getStatesCallback);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!!prevState.accountType && prevState.accountType !== this.state.accountType)
            this.getFieldVisibilityType();
    }

    checkRouteAndToggleOfficialInvoiceModal = () => this.props.history.location.pathname ==
        getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationOfficialInvoiceSetting)
        && this.showOfficialInvoiceModalClicked();

    getCitiesCallback = (resultData, result, resultMessageClass) => {
        if (!result.hasError && !!resultData)
            this.setState({ cityList: resultData });
        else
            this.setState({ resultMessageClass });
    }

    getStatesCallback = (resultData, result, resultMessageClass) => {
        if (!result.hasError && !!resultData && resultData.length > 0)
            this.setState({ stateList: resultData });
        else
            this.setState({ resultMessageClass });
    }

    officialInvoiceClicked = (selectedItem) => {
        if (selectedItem.key === Enumerations.switchValues.on)
            this.setState({ isShowOfficialInvoiceModal: true });
        else
            this.setState({ isOfficialInvoiceActive: selectedItem.key });
    }

    showOfficialInvoiceModalHandler = (isAccept) => {
        this.setState({
            isOfficialInvoiceActive: isAccept ? Enumerations.switchValues.on : Enumerations.switchValues.off,
            isShowOfficialInvoiceModal: false,
        });
    }

    closeCoachMarkModalClicked = (resultMessageClass) => this.setState({ isShowCoachMarkModal: false, resultMessageClass });

    confirmExpireToken = (confirm) => {
        confirm
            ? GetExpireUserTokenService(this.confirmExpireTokenCallback, true)
            : this.setState({ isShowExpireTokenModal: false });
    }

    confirmExpireTokenCallback = (resultData, result, messageClassModal) => {
        this.setState({
            isShowExpireTokenModal: false,
            resultMessageClass: messageClassModal
        });
    }

    showOfficialInvoiceModalClicked = () => {
        this.props.history.push(getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationOfficialInvoiceSetting))
        this.setState({ showOfficialInvoiceModal: true });
    }

    closeOfficialInvoiceModalClicked = (resultMessageClass) => {
        this.props.history.push(getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationSetting));
        this.setState({ showOfficialInvoiceModal: false, resultMessageClass });
        !isEmpty(resultMessageClass) && this.discardClicked();
    }

    showChangePasswordModalClicked = () => this.setState({ showChangePasswordModal: true });

    closeChangePasswordModal = () => this.setState({ showChangePasswordModal: false });

    render() {
        const { accountType, isValidAccountType, companyName, isValidCompanyName, license, isValidLicense,
            trn, isValidTrn, gender, isValidGender, firstName, isValidFirstName, lastName, isValidLastName,
            selectedCityId, cityList, isValidCity, selectedStateId, stateList, isValidState, selectedDefaultUserAddressId,
            isValidSelectedDefaultUserAddressId, idNumber, isValidIdNumber, showValidationIdNumberDate,
            birthDate, isValidBirthDate, email, isValidEmail, showEmailValidationDate, description, isValidDescription,
            showValidationDate, showValidationTrnDate, isOfficialInvoiceEnabled, trnHelperText, nationalCodeJsRegexPattern,
            nationalCodeMask, trnMask, trnJsRegexPattern, countryId, showChangePasswordModal,
            fieldVisibilityType
        } = this.state;
        const endAdornment = EnumerationTranslationHelper.getCountryNameById(countryId);
        const labelOfNationalCode = EnumerationTranslationHelper.getNationalCodeNameById(countryId);
        const isCompany = accountType === Enumerations.accountType.business;
        return (
            <UserPanelWrapper titleName={getTranslatedRawText("profile.personal.profileInformation")}
                titleIcon='ns-icon-profile-information'>

                <NsLoadingProgress visible={this.state.rightPanelLoading} />

                <div className='d-flex justify-content-end'>
                    {this.hasOfficialInvoiceIssue &&
                        <NsButton label="profile.userInfo.vatInvoice"
                            size='small'
                            startIcon='ns-icon-invoice-details'
                            className="me-2 secondary-outline mt-3 w-auto d-flex align-items-center"
                            onClick={this.showOfficialInvoiceModalClicked} />
                    }
                    <NsButton label="profile.personal.coachMarks"
                        size='small'
                        startIcon='ns-icon-coach-marks'
                        className="secondary-outline mt-3 w-auto"
                        onClick={() => this.setState({ isShowCoachMarkModal: true })} />

                    <NsButton label='profile.userInfo.changePassword'
                        size='small'
                        startIcon='ns-icon-change-password'
                        className="ms-2 secondary-outline mt-3 w-auto"
                        onClick={this.showChangePasswordModalClicked}
                    />
                    <NsButton label="adminPanel.usersInfo.expireToken"
                        size='small'
                        startIcon='ns-icon-disable-user'
                        className="ms-2 secondary-outline mt-3 w-auto"
                        onClick={() => this.setState({ isShowExpireTokenModal: true })} />
                </div>

                <p className="section-title w-100">
                    {getTranslatedRawText("profile.personal.accountSetting")}
                </p>
                <div className={clsx('d-flex', isCompany ? "mb-2" : "mb-3")}>
                    {CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.isCompanyVisibilityType)
                        ? <NsLabelBox label={getTranslatedRawText("profile.personal.accountType")}
                            value={EnumerationTranslationHelper.getAccountTypeLabel(accountType)} />
                        : <div className='col-xl-3 col-lg-4'>
                            <NsSelect label={getTranslatedRawText('components.accountType.accountType')}
                                className='mb-2'
                                valueChanged={(value, isValid) =>
                                    this.valueChanged(value, isValid, { accountType }, { isValidAccountType })}
                                selectedItem={accountType}
                                options={ConstantsWithTranslation.getAccountType()}
                                showValidationDate={showValidationDate} />
                        </div>
                    }
                </div>

                <UpBaseBusinessInputs isCompany={isCompany}
                    companyName={companyName}
                    fieldVisibilityType={fieldVisibilityType}
                    isValidCompanyName={isValidCompanyName}
                    license={license}
                    trnHelperText={trnHelperText}
                    isValidLicense={isValidLicense}
                    trn={trn}
                    isValidTrn={isValidTrn}
                    companyNameChanged={(value, isValid) => this.valueChanged(value, isValid, { companyName }, { isValidCompanyName })}
                    licenceChanged={(value, isValid) => this.valueChanged(value, isValid, { license }, { isValidLicense })}
                    trnChanged={(value, isValid) => this.valueChanged(value, isValid, { trn }, { isValidTrn })}
                    showValidationDate={showValidationDate}
                    showValidationTrnDate={showValidationTrnDate}
                    countryId={countryId}
                    trnMask={trnMask}
                    trnRegex={trnJsRegexPattern}
                    xl={3} />

                <p className="section-title">
                    {getTranslatedRawText("profile.personal.personalSetting")}
                </p>

                <div className="d-flex mb-3">
                    <NsLabelBox label={getTranslatedRawText('profile.personal.mobile')}
                        value={<div className='not-rtl'>{CurrentUserHelper.getMobileForUI()}</div>} />

                    {this.hasOfficialInvoiceIssue && <div className='ms-3'>
                        <NsToggleGroupButton label={getTranslatedRawText('profile.personal.officialInvoiceIssue')}
                            items={ConstantsWithTranslation.getOnOffSwitch()}
                            onClick={this.officialInvoiceClicked}
                            isShowGroupLabel={true}
                            disabled={isOfficialInvoiceEnabled}
                            selectedItemId={this.state.isOfficialInvoiceActive} />
                    </div>}
                </div>

                <Row className='w-100'>
                    <Col xl={3} lg={6} sm={12} className="mb-3">
                        <NsSelect label={getTranslatedRawText('components.genderType.gender')}
                            valueChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { gender }, { isValidGender })}
                            options={ConstantsWithTranslation.getGender()}
                            selectedItem={gender}
                            disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.genderTypeIdVisibilityType)} />
                    </Col>
                    <Col xl={3} lg={6} sm={12} className="mb-3">
                        <NsTextField valueChanged={(value, isValid) =>
                            this.valueChanged(value, isValid, { firstName }, { isValidFirstName })}
                            showValidationDate={showValidationDate}
                            label={getTranslatedRawText("profile.personal.firstName")}
                            size="small"
                            required
                            disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.firstNameVisibilityType)}
                            minLength={2}
                            value={firstName}
                            className="w-100" />
                    </Col>
                    <Col xl={3} lg={6} sm={12} className="mb-3">
                        <NsTextField valueChanged={(value, isValid) =>
                            this.valueChanged(value, isValid, { lastName }, { isValidLastName })}
                            showValidationDate={showValidationDate}
                            label={getTranslatedRawText("profile.personal.lastName")}
                            size="small"
                            required
                            disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.lastNameVisibilityType)}
                            minLength={2}
                            value={lastName}
                            className="w-100" />
                    </Col>
                    {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.nationalCodeVisibilityType) &&
                        <Col xl={3} lg={6} sm={12} className="mb-3">
                            <NsNumberInputWithPattern valueChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { idNumber }, { isValidIdNumber })}
                                label={labelOfNationalCode}
                                showValidationDate={showValidationIdNumberDate}
                                size="small"
                                codeMask={nationalCodeMask}
                                regex={nationalCodeJsRegexPattern}
                                disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.nationalCodeVisibilityType)}
                                value={idNumber}
                                endAdornment={endAdornment}
                                className="w-100" />
                        </Col>
                    }
                    <Col xl={3} lg={6} sm={12} className="mt-n1 mb-3">
                        <NsDatePicker valueChanged={(value, isValid) =>
                            this.valueChanged(value, isValid, { birthDate }, { isValidBirthDate })}
                            showValidationDate={showValidationDate}
                            className='w-100'
                            selectedDate={birthDate}
                            disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.birthDateVisibilityType)}
                            label="profile.personal.birthDate" />
                    </Col>
                    <Col xl={3} lg={6} sm={12} className="email-input mb-3">
                        <NsEmailInput valueChanged={(value, isValid) =>
                            this.valueChanged(value, isValid, { email }, { isValidEmail })}
                            showValidationDate={showEmailValidationDate}
                            label={getTranslatedRawText("components.emailInput.email")}
                            value={email}
                            className="w-100" />
                    </Col>
                    <Col xl={3} lg={6} sm={12} className="mb-3">
                        <NsSelect label={getTranslatedRawText('account.signup.state')}
                            valueChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { selectedStateId }, { isValidState })}
                            options={stateList}
                            disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.stateIdVisibilityType)}
                            selectedItem={selectedStateId} />
                    </Col>
                    <Col xl={3} lg={6} sm={12} className="mb-3">
                        <NsSelect label={getTranslatedRawText('account.signup.city')}
                            valueChanged={(value, isValid) =>
                                this.valueChanged(value, isValid, { selectedCityId }, { isValidCity })}
                            options={cityList}
                            disabled={!selectedStateId || CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.cityIdVisibilityType)}
                            selectedItem={selectedCityId} />
                    </Col>
                    <Col xl={12} lg={12} sm={12} className="mb-3">
                        <UpBaseDefaultAddress selectedDefaultUserAddressId={selectedDefaultUserAddressId}
                            isValidSelectedDefaultUserAddressId={isValidSelectedDefaultUserAddressId}
                            checkRouteAndToggleOfficialInvoiceModal={this.checkRouteAndToggleOfficialInvoiceModal}
                            defaultUserAddressVisibilityType={fieldVisibilityType?.defaultUserAddressIdVisibilityType}
                            valueChanged={this.valueChanged} />
                    </Col>

                    <Col xs={12} className="mb-5">
                        <NsTextField valueChanged={(value, isValid) =>
                            this.valueChanged(value, isValid, { description }, { isValidDescription })}
                            label={getTranslatedRawText("profile.personal.description")}
                            size="small"
                            multiline
                            value={description}
                            className="w-100" />
                    </Col>
                </Row>

                <NsStickyButton stickyButtons={this.mainButtons} isLoading={this.state.loading} />
                <NsAlertModal isOpen={this.state.isShowOfficialInvoiceModal}
                    cancelClicked={() => this.showOfficialInvoiceModalHandler(false)}
                    confirmClicked={() => this.showOfficialInvoiceModalHandler(true)} >
                    <div className='text-justify px-4 pt-4'>
                        <p>
                            {getTranslatedRawText('profile.personal.modals.officialInvoiceAlertP1')}
                        </p>
                        <p>
                            {getTranslatedRawText('profile.personal.modals.officialInvoiceAlertP2')}
                        </p>
                    </div>
                </NsAlertModal>

                {this.state.isShowExpireTokenModal && <NsMessageClassModal show={true}
                    onClose={this.confirmExpireToken}
                    messageItems={[{
                        messageText: (
                            <>
                                <p className='text-justify pe-4 pt-4'>{getTranslatedRawText('adminPanel.usersInfo.areYouSureToExpireTokenP1')}</p>
                                <p className='text-justify pe-4'>{getTranslatedRawText('adminPanel.usersInfo.areYouSureToExpireTokenP2')}</p>
                            </>
                        ),
                        messageType: Enumerations.messageType.warning
                    }]} />
                }
                {this.state.showOfficialInvoiceModal &&
                    <ProfileOfficialInvoiceModal
                        closeModal={this.closeOfficialInvoiceModalClicked} />
                }

                {this.state.isShowCoachMarkModal &&
                    <CoachMarksStatusModal closeModal={this.closeCoachMarkModalClicked} />
                }

                {showChangePasswordModal &&
                    <ChangePasswordModal showModal={showChangePasswordModal}
                        handleMessage={this.handleMessage}
                        closeChangePasswordModal={() => this.closeChangePasswordModal()} />
                }

                {this.state.resultMessageClass}
            </UserPanelWrapper >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...currentUserActionCreators }, dispatch);
};

export default connect(null, mapDispatchToProps)(UpProfileInformationSetting);