import React, { useEffect, useState } from 'react';

import NsButton from '../../../../../components/buttons/NsButton';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import NsModal from '../../../../../components/modals/NsModal';
import NsTab from '../../../../../components/tabs/NsTab';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { GetApAnnouncementContentByIdService } from '../../../../../services/adminPanel/AdminAnnouncementService';
import { GetApDfbByProductService } from '../../../../../services/adminPanel/AdminDashboardService';
import { GetFeedbackAnnouncementBasicInfoService, GetFeedbackAnnouncementsService, GetFeedbackShareSheetOrderBasicInfoService } from '../../../../../services/adminPanel/AdminFeedbackService';
import { GetApProductBasicInfoByIdService } from '../../../../../services/adminPanel/AdminProductContentService';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import EnumerationTranslationHelper from '../../../../../utilities/EnumerationTranslationHelper';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApAnnouncementViewModal from '../../../contents/announcements/ApAnnouncementViewModal';
import ApBaseFeedbackDetailModal from '../../../feedbacks/bases/ApBaseFeedbackDetailModal';
import ApOrderProductDetailModal from '../../../managements/orders/shareSheets/orderDetailPreview/ApOrderProductDetailModal';

const ApDfbTypesFeedbacksModal = (props) => {
    const [feedbackTypeInfo, setFeedbackTypeInfo] = useState(null);
    const [selectedTab, setSelectedTab] = useState(ConstantsWithTranslation.getFeedbackstatus()[0]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [userDetails, setUserDetails] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(new Date());
    const [showProductDetailModal, setShowProductDetailModal] = useState(false);
    const [showAnnouncementDetailModal, setShowAnnouncementDetailModal] = useState(false);
    const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);

    const { feedbackType } = props;
    const { sharedSheetOrder, announcementContent } = Enumerations.feedbackTypes;

    const columns = [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (row) => {
                    return <NsButton
                        startIcon='ns-icon-invoice-details'
                        size='medium'
                        className='secondary-text p-0'
                        loading={isLoading}
                        onClick={() => userInfoDetailClicked(row)} />
                }
            }
        },
        {
            field: 'fullName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 200
        },
        {
            field: 'nasInvoiceId',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber'),
            width: 100,
            dataType: Enumerations.gridType.number
        },
        {
            field: 'submitDate',
            width: '150',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'averageRate',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.avgRate'),
            dataType: Enumerations.gridType.number,
            sortable: true,
            width: 130
        },
        {
            field: 'isPublic',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.displaytoAll'),
            width: 150,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => EnumerationTranslationHelper.getYesOrNoStatus(props.isPublic)
            }
        },
        {
            field: 'demandedServiceTypeName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.extraSupport'),
            width: 150
        },
    ];

    useEffect(() => {
        getFeedbackTypeInfo();
    }, [])

    const getFeedbackTypeInfo = () => {
        let parameters = { Id: props?.selectedFeedbackTypeId };
        switch (feedbackType) {
            case sharedSheetOrder: return GetApProductBasicInfoByIdService(parameters, getFeedbackTypeInfoCallback);
            case announcementContent: return GetApAnnouncementContentByIdService(props?.selectedFeedbackTypeId, getFeedbackTypeInfoCallback);
            default:
                break;
        }
    };


    const getFeedbackTypeInfoCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setFeedbackTypeInfo(resultData);
        } else
            setMessageClassModal(messageClassModal);
    }

    const getGridProperties = () => {
        switch (feedbackType) {
            case sharedSheetOrder:
                return {
                    feedbackGrid: {
                        service: GetApDfbByProductService,
                        parameters: {
                            "Filter.ProductId": props?.selectedFeedbackTypeId,
                            "Filter.UserFeedbackStateTypeId": selectedTab?.id,
                        }
                    },
                    usersGrid: {
                        service: GetFeedbackShareSheetOrderBasicInfoService,
                        hasAccessInfo: hasAccess([UserClaimEnumerations.getApFeedbackShareSheetOrderBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory])
                    },
                }
            case announcementContent:
                return {
                    feedbackGrid: {
                        service: GetFeedbackAnnouncementsService,
                        parameters: { "Filter.AnnouncementContentId": props?.selectedFeedbackTypeId }
                    },
                    usersGrid: {
                        service: GetFeedbackAnnouncementBasicInfoService,
                        hasAccessInfo: hasAccess([UserClaimEnumerations.getApFeedbackAnnouncementBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory])
                    },
                }
            default:
                break;
        }
    }

    const tabChanged = (selectedTabIndex, selectedTab) => {
        setSelectedTabIndex(selectedTabIndex);
        setSelectedTab(selectedTab);
        setCallServiceDate(new Date());
    };

    const userInfoDetailClicked = (userDetail) => {
        setUserDetails(userDetail);
        toggleUserDetailsModal();
    }

    const toggleProductDetailModal = () => setShowProductDetailModal(!showProductDetailModal);
    const toggleUserDetailsModal = () => setShowUserDetailsModal(!showUserDetailsModal);
    const toggleAnnouncementDetailModal = () => setShowAnnouncementDetailModal(!showAnnouncementDetailModal);

    const headerButtons = [
        feedbackType === sharedSheetOrder &&
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-product-information', label: 'adminPanel.dashboard.productsInfo', onClick: toggleProductDetailModal, accessIds: [UserClaimEnumerations.getApOrderProductDetail] },
        feedbackType === announcementContent &&
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-product-information', label: 'adminPanel.announcement.announcementDetail', onClick: toggleAnnouncementDetailModal, accessIds: [UserClaimEnumerations.getApAnnouncementById] }
    ];

    const { closeModal, selectedFeedbackTypeId } = props;
    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText('adminPanel.dashboard.feedbackDetails')}
            showModal={true}
            noSpace
            closeModal={closeModal}>
            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            <div className='background-color p-2 my-2'>
                <ApBaseLabelInfo info={{ orderItemInfoDto: feedbackTypeInfo }}
                    hasAccessInfo={true}
                    isLoading={isLoading} />
            </div>
            <div className='p-3'>
                <NsTab tabs={ConstantsWithTranslation.getFeedbackstatus()}
                    value={selectedTabIndex}
                    onChange={tabChanged}>
                </NsTab>
                <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                    service={getGridProperties()?.feedbackGrid?.service}
                    parameters={getGridProperties()?.feedbackGrid?.parameters}
                    columnDefs={columns}
                    hasPagination={true} />
            </div>

            {showProductDetailModal &&
                <ApOrderProductDetailModal showModal={true}
                    selectedPprId={selectedFeedbackTypeId}
                    isUseInProductContentModal={true}
                    closeModal={toggleProductDetailModal} />
            }
            {showUserDetailsModal &&
                < ApBaseFeedbackDetailModal selectedFeedback={{ ...userDetails, feedbackType }}
                    getFeedbackDtoService={getGridProperties()?.usersGrid?.service}
                    hasAccessInfo={getGridProperties()?.usersGrid?.hasAccessInfo}
                    closeModal={toggleUserDetailsModal} />
            }
            {showAnnouncementDetailModal &&
                <ApAnnouncementViewModal announcementId={selectedFeedbackTypeId}
                    closeModal={toggleAnnouncementDetailModal} />
            }

            {messageClassModal}
        </NsModal>
    );
};

export default ApDfbTypesFeedbacksModal;
