import React from "react";
import clsx from "clsx";

import NsButton from "../buttons/NsButton";
import NsSelectMenu from "../dropdowns/NsSelectMenu";
import NsTypography from "../text/NsTypography";
import CommonHelper from "../../utilities/CommonHelper";
import { getCurrentCulture, getTranslatedRawText } from "../../utilities/CultureHelper";
import { getEnumKeyFromValue } from "../../utilities/EnumerationHelper";
import Enumerations from "../../configs/Enumerations";
import { isEmpty } from "../../utilities/ValidationHelper";
import NsNumberInput from "../inputs/NsNumberInput";
import Constants from "../../configs/Constants";
import { deleteGridColumnOrder } from "../../utilities/DataGridHelper";

const NsGridPagination = (props) => {
    const { currentPage, totalRows, rowPerPage, rowPerPageOptionsInGrid, borderStyle, isHistory, hasCustomColumnOrder } = props;

    const rowPerPageChanged = (id) => {
        if (CommonHelper.toInt(id)) {
            const selectedRowPerPage = rowPerPageOptionsInGrid.find(item => item.id === id);
            CommonHelper.safeFunctionCall(props.handleRowPerPage, selectedRowPerPage);
        }
    }

    const paginationButtonClicked = (pageChange, refreshFooterGridDate) => {
        !!props.refreshButtonClicked && CommonHelper.safeFunctionCall(props.refreshButtonClicked)
        if (pageChange.toString().substr(0, 1) !== '0') {
            if (!!pageChange) {
                pageChange > getTotalPage()
                    ? CommonHelper.safeFunctionCall(props.handlePagination, getTotalPage())
                    : CommonHelper.safeFunctionCall(props.handlePagination, pageChange, refreshFooterGridDate);
            }
        } else CommonHelper.safeFunctionCall(props.handlePagination, 1)
    }

    const getTotalPage = () => CommonHelper.getTotalPageCount(totalRows, rowPerPage?.value);


    return (<div className={clsx('background-color p-2', isHistory ? 'd-flex flex-column justify-content-end' : 'd-flex flex-column flex-md-row justify-content-end align-items-md-center')}
        style={borderStyle} >
        <div className="d-flex me-auto">
            <NsButton size='small'
                onClick={() => paginationButtonClicked(1, new Date())}
                startIcon={'ns-icon-refresh'} />
            {hasCustomColumnOrder &&
                <NsButton size='small'
                    onClick={props.deleteGridColumnOrder}
                    startIcon={'ns-icon-setting'} />
            }
        </div>
        {!!currentPage && totalRows > CommonHelper.defaultRowPerPage().value &&
            <div className={clsx(isHistory ? 'd-flex flex-column' : 'd-flex flex-column flex-md-row align-items-md-center')}>
                <div className="d-flex align-items-center">
                    <NsTypography variant='inherit' className='text-color font-size-12'>
                        {getTranslatedRawText('profile.dataGridColumns.rowsPerPage')}
                    </NsTypography>

                    <NsSelectMenu options={Constants.rowPerPageOptionsInGrid}
                        menuItemClassName='font-size-12'
                        className='font-size-12 mx-2'
                        selectedItem={rowPerPage?.id}
                        valueChanged={rowPerPageChanged} />
                </div>
                <div className='d-flex align-items-center'>
                    <NsButton size='small'
                        disabled={currentPage - 1 < 1}
                        onClick={() => paginationButtonClicked(1)}
                        className=''
                        wrapperClassName='d-inline-block'
                        startIcon={getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en) ? 'ns-icon-first' : 'ns-icon-last'} />

                    <NsButton size='small'
                        disabled={currentPage - 1 < 1}
                        onClick={() => paginationButtonClicked(currentPage - 1)}
                        className=''
                        wrapperClassName='d-inline-block'
                        startIcon={getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en) ? 'ns-icon-expand-left' : 'ns-icon-expand-right'} />

                    <NsTypography variant='inherit' className='text-color font-size-12 d-inline-block'>
                        {getTranslatedRawText('profile.dataGridColumns.page')}
                    </NsTypography>

                    <NsNumberInput delayValueChanged={(pageNumber) => paginationButtonClicked(parseInt(pageNumber))}
                        size="small"
                        maxLength="3"
                        fontSize="small"
                        value={currentPage}
                        placeholder={'' + currentPage}
                        className="mx-2 d-inline-block align-self-start ns-text-field__pagination" />

                    <NsTypography variant='inherit' className='text-color font-size-12 d-inline-block'>
                        {CommonHelper.stringFormat(getTranslatedRawText('profile.dataGridColumns.of'), getTotalPage())}
                    </NsTypography>

                    <NsButton size='small'
                        disabled={currentPage + 1 > getTotalPage()}
                        onClick={() => paginationButtonClicked(currentPage + 1)}
                        className=''
                        wrapperClassName='d-inline-block'
                        startIcon={getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en) ? 'ns-icon-expand-right' : 'ns-icon-expand-left'} />

                    <NsButton size='small'
                        disabled={currentPage + 1 > getTotalPage()}
                        onClick={() => paginationButtonClicked(getTotalPage())}
                        className=''
                        wrapperClassName='d-inline-block'
                        startIcon={getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.en) ? 'ns-icon-last' : 'ns-icon-first'} />
                </div>
            </div>
        }
        {
            !isEmpty(totalRows) && <NsTypography variant='inherit' className='text-color font-size-12'>
                {CommonHelper.stringFormat(getTranslatedRawText('profile.dataGridColumns.total'), totalRows ?? 0)}
            </NsTypography>
        }
    </div >
    );
}

export default React.memo(NsGridPagination);