import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import NsImage from '../../../../components/images/NsImage';
import Links from '../../../../configs/links/AllLinks';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';

class BnBasesPostMedia extends Component {

	isBlog = CommonHelper.isBlog(window.location.href);

	render() {
		const { post, otherClass, isReversePost } = this.props;

		return (
			<Link to={(this.isBlog && !isReversePost) || (!this.isBlog && isReversePost)
				? CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogPostDetail), post.id, post.title)
				: CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsPostDetail), post.id, post.title)
			}
				className={`d-flex text-decoration-none ${!!otherClass && otherClass}`
				} >
				<div className="flex-shrink-0 border rounded-3">
					<NsImage width={64}
						height={64}
						imgAlt="components.imagesAlt.newsThumbnails"
						src={CommonHelper.createImageUrl(post?.fileUrl)} />
				</div>
				<div className="flex-grow-1 ms-3">
					<span className='font-size-12 line-clamp mb-1 line-clamp--1 fw-bold'>
						{post?.title}
					</span>
					<p className='font-size-12 m-0 line-clamp line-clamp--2'>{post?.summary}</p>
				</div>
			</Link >
		);
	}
}

export default withRouter(BnBasesPostMedia);