import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import NsCard from '../../components/cards/NsCard';
import NsImage from '../../components/images/NsImage';
import NsTypography from '../../components/text/NsTypography';
import Config from '../../configs/Config';
import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../utilities/ConfigHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import ImageHelper from '../../utilities/ImageHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import clsx from 'clsx';

class HomeAboutSection extends Component {

    aboutUsHome = getConfigObjectByCulture(Config.aboutUsHome);

    aboutTitle = () => CommonHelper.stringFormat(getTranslatedRawText('components.imagesAlt.aboutUs'), getConfigObjectByCulture(Config.metaTags)?.title);

    render() {
        return (
            <NsCard>
                <Row className="about m-0" aria-label='about us'>
                    <Col lg={7} className="p-3 d-flex flex-column">
                        <div className="about-title gap-3">
                            <div className="about-header-line m-0"></div>
                            <h1 className={clsx(CommonHelper.isMobileDevice() ? 'about-header-title-small' : 'about-header-title')}>
                                {this.aboutTitle()}
                            </h1>
                            <div className="about-header-line m-0"></div>
                        </div>
                        <div className="d-flex flex-column flex-grow-1 justify-content-around align-items-center">
                            <div className="about-description">
                                {!isEmpty(this.aboutUsHome) && this.aboutUsHome.map((item, index) =>
                                    <div key={index} variant="inherit" className='font-size-16 text-color' >
                                        <p className='lh-lg'>{item.description}</p>
                                        {this.aboutUsHome.length - 1 === index ? <></> : <hr className="my-4" />}
                                    </div>
                                )}
                            </div>
                            <Link to={getUrlWithCurrentCulture(Links, Links.aboutUs)}
                                className="primary-text fw-bold text-center text-decoration-none mt-3">
                                {getTranslatedRawText('home.aboutSection.readMore')}
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className="p-3 d-none d-lg-block">
                        <NsImage className="home-about-img w-100 my-4"
                            imgAlt={this.aboutTitle()}
                            src={ImageHelper.blankSrc()} />
                    </Col>
                </Row >
            </NsCard>
        );
    }
}

export default HomeAboutSection;