import { Row, Col } from "react-bootstrap";
import React, { useCallback, useState } from "react";

import { GetApDaUCbForecastsService } from "../../../../services/adminPanel/AdminDashboardService";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import ApDcbCustomerSpecialPlanChart from "./ApDcbCustomerSpecialPlanChart";
import ApDcbCurrentMonthVsFromDate from "./ApDcbCurrentMonthVsFromDate";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApDcbCustomerNotReached from "./ApDcbCustomerNotReached";
import ApDcbCustomerPlanList from "./ApDcbCustomerPlanList";
import Enumerations from "../../../../configs/Enumerations";
import DateHelper from "../../../../utilities/DateHelper";
import NsTab from "../../../../components/tabs/NsTab";
import ApDcbTotalGadget from "./ApDcbTotalGadget";
import ApDcbPlansChart from "./ApDcbPlansChart";
import ApDcbPlansGrid from "./ApDcbPlansGrid";
import ApDcbHeader from "./ApDcbHeader";

const ApDashboardCashback = (props) => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTabId, setSelectedTabId] = useState(ConstantsWithTranslation.getCashbackTimesTabs()[0].id);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [cashbackData, setCashbackData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const GetCashbackForecasts = useCallback((parameter, callback) => {
        setIsLoading(true);
        GetApDaUCbForecastsService(parameter, (resultData, result, resultMessageClass) => {
            if (!result?.data?.hasError) {
                setCashbackData(resultData);
                setIsLoading(false);
            }
            callback(resultData?.cashbacks, result, resultMessageClass);
        });
    }, [])

    const tabChanged = (newValue, selectedTab) => {
        if (newValue !== selectedTabIndex) {
            setSelectedTabId(selectedTab.id);
            setSelectedTabIndex(newValue);
            setCallServiceDate(new Date());
        }
    }

    const refreshClicked = () => setCallServiceDate(new Date());

    const setFromAndToDateAndRefreshClicked = (fromDate, toDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
        let tabIndex = isEmpty(toDate) && isEmpty(fromDate)
            ? 0
            : isEmpty(toDate) && !isEmpty(fromDate) ? 1 : 2
        setSelectedTabIndex(tabIndex);
        setSelectedTabId(tabIndex + 1);
        refreshClicked();
    }

    const getCashbackTimesTabs = () => ConstantsWithTranslation.getCashbackTimesTabs(DateHelper.getDateAndTime(fromDate), DateHelper.getDateAndTime(toDate));

    return (<>
        <ApDcbHeader title='adminPanel.dashboard.cashbackDashboard'
            refreshClicked={setFromAndToDateAndRefreshClicked} />

        <NsTab tabs={getCashbackTimesTabs()}
            value={selectedTabIndex}
            onChange={tabChanged} />

        <Row className="mt-3 g-2">
            {selectedTabId != Enumerations.cashbackTimeType.currentMonthVsFromDate
                ? <>
                    <ApDcbTotalGadget cashback={cashbackData}
                        isLoading={isLoading}
                        fromDate={fromDate}
                        toDate={selectedTabId !== Enumerations.cashbackTimeType.basedOnSelectedDate ? null : toDate} />

                    <Col md={6}>
                        <ApDcbCustomerSpecialPlanChart data={cashbackData?.customersWithSpecialPlanVsCommonPlanChart}
                            refreshClicked={refreshClicked}
                            isLoading={isLoading} />
                    </Col>
                    <Col md={6}>
                        <ApDcbCustomerPlanList cashback={cashbackData} isLoading={isLoading} />
                    </Col>

                    <ApDcbCustomerNotReached cashback={cashbackData} isLoading={isLoading} />

                    <Col xs={12}>
                        <ApDcbPlansChart data={cashbackData?.cashbackPlansChart}
                            refreshClicked={refreshClicked}
                            isLoading={isLoading} />
                    </Col>
                    <Col xs={12}>
                        <ApDcbPlansGrid GetCashbackForecasts={GetCashbackForecasts}
                            toDate={selectedTabId !== Enumerations.cashbackTimeType.basedOnSelectedDate ? null : toDate}
                            fromDate={selectedTabId !== Enumerations.cashbackTimeType.currentMonthVsFromDate ? null : fromDate}
                            callServiceDate={callServiceDate}
                            isLoading={isLoading} />
                    </Col>
                </>
                : <ApDcbCurrentMonthVsFromDate fromDate={selectedTabId !== Enumerations.cashbackTimeType.currentMonthVsFromDate ? null : fromDate}
                    refreshDate={callServiceDate} />
            }
        </Row>
    </>
    );
}

export default React.memo(ApDashboardCashback);