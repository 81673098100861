import clsx from 'clsx';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsImageScaledView from '../../../../components/images/NsImageScaledView';
import NsCheckbox from '../../../../components/inputs/NsCheckbox';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';


class FmItemFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: props.content,
            selectedItemId: props.selectedItemId,
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(
            {
                content: newProps.content,
                selectedItemId: newProps.selectedItemId,
            }
        );
    }

    getIamgeCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.setState({ thumbnail: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });

    }

    fileClicked = () => CommonHelper.safeFunctionCall(this.props.fileClicked, this.state.content);

    isSelectedFile = () => {
        return this.state.selectedItemId === this.state.content.id
    }

    dragStarted = (ev) => {
        ev.dataTransfer.setData("text", JSON.stringify(this.state.content));
    }

    multiFileSelectClicked = () => {
        CommonHelper.safeFunctionCall(this.props.multiFileSelectClicked, { id: this.state.content.id, name: this.state.content.name })
    }

    render() {
        const { content } = this.state;
        const { isUsedInPo, contentStyle, isSelected, hideCheckbox } = this.props;

        return (contentStyle
            ? <Row className={clsx('w-100 cursor-pointer m-1 p-1  font-size-12',
                this.isSelectedFile() ? 'selected-item' : 'hover-item')}
                onClick={this.fileClicked}
                draggable='true' onDragStart={this.dragStarted}>

                <Col xs={isUsedInPo ? 8 : 6} className='ps-1 d-flex'>

                    {!hideCheckbox && <div onClick={(e) => e.stopPropagation()}>
                        <NsCheckbox onChange={this.multiFileSelectClicked} value={isSelected} className='me-0' />
                    </div>}
                    <div className="pe-2 item-height-list d-flex justify-content-center">
                        <NsImageScaledView className={'hover-item'}
                            imageUrl={content.thumbnail}
                            imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.imagePreview"), content.name)} />
                    </div>
                    <div className={clsx('d-flex-grow-1 not-rtl word-break text-break', isUsedInPo && 'text-center')} >
                        {content.name}
                    </div>
                </Col>
                {!isUsedInPo && <Col xs={2}>{CommonHelper.getFileExtension(content.name).toUpperCase()}</Col>}
                {!isUsedInPo && <Col xs={2}>{CommonHelper.convertByteToMegaByte(content.volume)} {getTranslatedRawText('common.mb')}</Col>}
                <Col xs={isUsedInPo ? 4 : 2}>{CommonHelper.getDimensionFormat(content.width, content.height)}</Col>
            </Row >

            : <div className={clsx('item-width m-1',
                this.isSelectedFile() ? 'selected-item' : 'hover-item')}>

                <div onClick={this.fileClicked} className='cursor-pointer m-1 d-flex flex-column'>

                    <div className="pt-2 item-height d-flex justify-content-centerd"
                        draggable='true' onDragStart={this.dragStarted}>
                        <NsImageScaledView className={'hover-item'}
                            imageUrl={content.thumbnail}
                            imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.imagePreview"), content.name)} />
                    </div>
                    <div className={clsx('d-flex', this.props.isUsedInPo && 'text-center')} >
                        {!this.props.hideCheckbox && <div onClick={(e) => e.stopPropagation()} className="w-25">
                            <NsCheckbox onChange={this.multiFileSelectClicked} value={this.props.isSelected} />
                        </div>}
                        <span className='font-size-10 mt-2 truncate-text'>
                            {content.name}
                        </span>
                    </div>
                </div>
            </div >
        );
    }
}

export default FmItemFile;