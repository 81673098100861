import React, { useState } from "react";

import ApPaymentAuditImportedFileDetailModal from "../../managements/payments/audits/ApPaymentAuditImportedFileDetailModal";
import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import EnumerationTranslationHelper from "../../../../utilities/EnumerationTranslationHelper";
import { GetAuditFileService } from "../../../../services/adminPanel/AdminPaymentService";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsButton from "../../../../components/buttons/NsButton";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsCard from "../../../../components/cards/NsCard";

const ApDauLastUploaders = (props) => {

    const { refreshDate, parameters } = props;
    const [selectedFile, setSelectedFile] = useState();
    const [showDetailImportedFile, setShowDetailImportedFile] = useState(false);

    const columns = [
        hasAccess([UserClaimEnumerations.getApPaymentAuditAsanPardakhts, UserClaimEnumerations.getApPaymentAuditSepehrs, UserClaimEnumerations.getApPaymentAuditNgenius]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.dashboard.lastUploader'),
            width: 40,
            cellClass: 'text-center px-0',
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (prop) => {
                    return <NsButton className='secondary-text'
                        startIcon='ns-icon-details'
                        onClick={() => detailClicked(prop)} />
                }
            }
        },
        {
            field: 'name',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.name'),
            width: 150,
        },
        {
            field: 'userFullName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 120,
            cellClass: 'text-center'
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 130,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'paymentGatewayType',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.paymentGateway'),
            cellRenderer: "customRowRenderer",
            width: 120,
            cellClass: 'text-center',
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div className="ps-2"> {EnumerationTranslationHelper.getPaymentGatewayType(props.paymentGatewayType)}</div>
                }
            }
        },

    ];

    const importedFiledetailModalClicked = () => setShowDetailImportedFile(!showDetailImportedFile);

    const detailClicked = (selectedFile) => {
        setSelectedFile(selectedFile);
        importedFiledetailModalClicked();
    }

    return (
        <NsCard className='p-3 h-100'>
            <div className='page-title'>{getTranslatedRawText('adminPanel.dashboard.lastUploader')}</div>
            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={GetAuditFileService}
                parameters={{
                    'Filter.SubmitTimeFrom': parameters?.submitTimeFrom,
                    'Filter.SubmitTimeTo': parameters?.submitTimeTo,
                    pageLength: 4,
                    currentPageIndex: 1
                }}
                theme='ag-theme-alpine__no-border'
                headerHeight={0}
                noFooter={true}
                columnDefs={columns} />

            {showDetailImportedFile &&
                <ApPaymentAuditImportedFileDetailModal fileSourceId={selectedFile?.id}
                    paymentGatewayType={{
                        key: selectedFile?.paymentGatewayType,
                        value: EnumerationTranslationHelper.getPaymentGatewayType(selectedFile?.paymentGatewayType)
                    }}
                    closeModal={importedFiledetailModalClicked} />
            }
        </NsCard>
    );
};

export default React.memo(ApDauLastUploaders);