import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
    Tooltip
} from 'chart.js';
import 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import React, { useRef } from 'react';
import { Chart } from 'react-chartjs-2';

import CommonHelper from "../../utilities/CommonHelper";
import { getTranslatedRawText, isEnCulture } from '../../utilities/CultureHelper';
import NsButton from '../buttons/NsButton';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const NsChart = (props) => {
    const chartRef = useRef(null);
    const { type, height, width, data, hasZoom } = props;
    const options = {
        ...props.options,
        responsive: true,
        barThickness: 10,
        maxBarThickness: 20,
        maintainAspectRatio: type !== 'doughnut',
        cutout: props.options?.cutout || 45,
        plugins: {
            tooltip: {
                bodyFont: {
                    family: isEnCulture() ? 'Segoe UI' : "NsSansFont"
                },
                titleFont: {
                    family: isEnCulture() ? 'Segoe UI' : "NsSansFont"
                },
            },
            legend: {
                position: 'top',
                align: 'end',
                labels: {
                    boxWidth: 5,
                    boxHeight: 5,
                    usePointStyle: true,
                    textAlign: 'center',
                    font: {
                        size: 14,
                        family: isEnCulture() ? 'Segoe UI' : "NsSansFont"
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    modifierKey: 'ctrl',
                },
                zoom: {
                    drag: {
                        enabled: true
                    },
                    mode: 'x',
                    wheel: {
                        enabled: false,
                    },
                    pinch: {
                        enabled: true
                    },
                },
            }
        },
        interaction: {
            intersect: false,
            mode: 'index'
        },
        elements: {
            bar: {
                borderSkipped: 'start',
                borderWidth: 2
            }
        },
    }

    const actions = [
        {
            name: 'components.chart.zoomIn',
            handler() {
                chartRef.current.zoom(1.1);
            }
        }, {
            name: 'components.chart.zoomOut',
            handler() {
                chartRef.current.zoom(0.9);
            },
        }, {
            name: 'components.chart.resetZoom',
            handler() {
                chartRef.current.resetZoom();
            }
        }
    ];

    return (
        <>
            <Chart ref={chartRef} width={width} height={CommonHelper.isMobileDevice() ? "250" : height} type={type} options={options} data={data} />
            {hasZoom && <div className='d-flex mt-2'>
                {actions.map((action, index) => {
                    return <NsButton className='secondary-outline text-nowrap'
                        key={index}
                        wrapperClassName='ps-2'
                        size="small"
                        label={getTranslatedRawText(action.name)}
                        onClick={action.handler} />
                })}
            </div>}
        </>
    );
};

export default NsChart;