import React, { useState } from 'react';

import NsButton from '../../../../../components/buttons/NsButton';
import NsCard from '../../../../../components/cards/NsCard';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import Enumerations from '../../../../../configs/Enumerations';
import { GetApDfbAnnouncementsGridService } from '../../../../../services/adminPanel/AdminDashboardService';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApDfbTypesFeedbacksModal from '../bases/ApDfbTypesFeedbacksModal';

function ApDfbAnnouncementsGrid(props) {
  const [selectedAnnounement, setSelectedAnnouncement] = useState(null);
  const [callServiceDate, setCallServiceDate] = useState(new Date());
  const [showFeedbackTypeDetailModal, setShowFeedbackTypeDetailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { refreshDate, parameters } = props;

  const columns = [
    {
      field: 'action',
      headerName: getTranslatedRawText('adminPanel.common.action'),
      width: 80,
      cellRenderer: "customRowRenderer",
      cellRendererParams: {
        customElementRender: (props) => {
          return <NsButton startIcon='ns-icon-invoice-details cursor-pointer'
            className='secondary-text'
            loading={isLoading}
            onClick={() => announcementClicked(props)} />
        }
      },
    },
    {
      field: 'announcementContentTitle',
      headerName: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
      width: 190
    },
    {
      field: 'averageRate',
      headerName: getTranslatedRawText('adminPanel.dataGridColumn.avgRate'),
      dataType: Enumerations.gridType.number,
      sortable: true,
      width: 130
    },
    {
      field: 'quantity',
      headerName: getTranslatedRawText('adminPanel.dataGridColumn.qty'),
      dataType: Enumerations.gridType.number,
      sortable: true,
      width: 90
    }
  ];

  const announcementClicked = (selectedAnnounement) => {
    setShowFeedbackTypeDetailModal(!showFeedbackTypeDetailModal);
    setSelectedAnnouncement(selectedAnnounement);
  }

  const toggleFeedbackTypeDetailModal = () => setShowFeedbackTypeDetailModal(!showFeedbackTypeDetailModal)

  return (
    <NsCard className='p-3 h-100 align-content-between'>
      <div className='page-title mb-2'>
        {getTranslatedRawText('adminPanel.sidebar.announcements')}
      </div>

      <NsDataGridWithServiceCall callServiceDate={callServiceDate || refreshDate}
        service={GetApDfbAnnouncementsGridService}
        parameters={parameters}
        columnDefs={columns}
        hasPagination={true} />

      {showFeedbackTypeDetailModal &&
        <ApDfbTypesFeedbacksModal showModal={true}
          feedbackType={parameters?.feedbackType}
          selectedFeedbackTypeId={selectedAnnounement?.announcementContentId}
          closeModal={toggleFeedbackTypeDetailModal} />
      }
    </NsCard>
  )
}

export default ApDfbAnnouncementsGrid;