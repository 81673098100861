import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsCircularProgress from '../../../components/circularProgress/NsCircularProgress';
import ConstantsWithTranslation from '../../../configs/ConstantsWithTranslation';
import NsToggleGroupButton from '../../../components/buttons/NsToggleGroupButton';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsSelect from '../../../components/dropdowns/NsSelect';
import NsTypography from '../../../components/text/NsTypography';
import NsTextField from '../../../components/inputs/NsTextField';
import NsMapWithTab from '../../../components/maps/NsMapWithTab';
import NsLabelBox from '../../../components/labels/NsLabelBox';
import NsTooltip from '../../../components/tooltips/NsTooltip';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import {
    AddAddressService,
    EditAddressService,
    GetAddressDetailService, GetCitiesService, GetCountryService,
    GetStatesService
} from '../../../services/ProfileSetting/DeliveryAddressSettingService';
import Enumerations from '../../../configs/Enumerations';
import NsNumberInputWithPattern from '../../../components/inputs/NsNumberInputWithPattern';

class ProfileAddressSettintgModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            { label: "common.save", icon: 'ns-icon-save', onClicked: !!props.addressId ? this.editClicked : this.addClicked, className: 'primary' },
            { label: "common.cancel", icon: 'ns-icon-close', onClicked: this.closeModal, className: 'primary-outline' },
        ];
        this.state = {
            country: '',
            countryId: '',
            isActive: true,
            statesValue: [],
            stateId: '',
            isValidStateId: false,

            citiesValue: [],
            cityId: '',
            isValidCityId: false,

            address: '',
            isValidAddress: false,

            postalCode: '',
            isValidPostalCode: false,
            postalCodeJsRegexPattern: null,
            postalCodeMask: '',

            latitude: null,
            longitude: null,

            isLoading: false,
            isLoadedMap: false,
            showValidationDate: '',

            isTooltipOpen: false,

            mapCenterLongitude: null,
            mapCenterLatitude: null,
            isAddressInputChangedByUser: false,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => {
        this.setState({ isLoading: true });
        GetCountryService(this.getCountryCallback);
        if (!!this.props.addressId) {
            this.setState({ isLoading: true })
            GetAddressDetailService({ id: this.props.addressId }, this.getAddressDetailCallback);
        }
    }

    getAddressDetailCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false })
        if (!result?.data?.hasError)
            this.setState({
                stateId: resultData.stateId,
                isValidStateId: !!resultData.stateId,
                cityId: resultData.cityId,
                isValidCityId: !!resultData.cityId,
                address: resultData.address,
                isValidAddress: !!resultData.address,
                postalCode: resultData.postalCode,
                isValidPostalCode: !!resultData.postalCode,
                latitude: resultData.latitude,
                longitude: resultData.longitude,
                isActive: resultData.isActive
            }, this.getCities(resultData.stateId));
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    valueChanged = (value, isValid, valueStateName, isValidStateName) => {
        const { stateId } = this.state;
        const valueName = CommonHelper.getVariableName(valueStateName);
        const isValidName = CommonHelper.getVariableName(isValidStateName);
        this.setState({
            [valueName]: value,
            [isValidName]: isValid,
        },
            () => valueName === CommonHelper.getVariableName({ stateId }) && this.getCities(value));
    }

    mapClicked = (lat, lng, clickedAddress) => {
        const { addressId } = this.props;
        const { isAddressInputChangedByUser, address } = this.state;

        if (isEmpty(address)) this.setState({ isAddressInputChangedByUser: false });
        let isAddressChangeable = ((!isAddressInputChangedByUser || isEmpty(address)) && !addressId && !isEmpty(clickedAddress?.neighbourhood) && !isEmpty(clickedAddress?.road));
        
        this.setState({
            latitude: lat, longitude: lng,
            address: isAddressChangeable ? clickedAddress?.neighbourhood + ' , ' + clickedAddress?.road : address,
        });
    };

    addClicked = () => {
        if (!this.state.isValidStateId || !this.state.isValidCityId || !this.state.isValidAddress || !this.state.isValidPostalCode || !this.state.latitude || !this.state.longitude) {
            this.setState({ showValidationDate: new Date() });
            return false;
        }
        let data = {
            id: null,
            cityId: this.state.cityId,
            address: this.state.address,
            postalCode: this.state.postalCode,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            isActive: this.state.isActive
        };
        this.setState({ isLoading: true });
        AddAddressService(data, this.addAddressCallback, true);
    }

    addAddressCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            CommonHelper.safeFunctionCall(this.props.closeModal, resultMessageClass);
        else
            this.setState({ resultMessageClass });
    }

    editClicked = () => {
        if (!this.state.isValidStateId ||
            !this.state.isValidCityId ||
            !this.state.isValidAddress ||
            !this.state.isValidPostalCode) {
            this.setState({ showValidationDate: new Date() });
            return false;
        }
        let data = {
            id: this.props.addressId,
            cityId: this.state.cityId,
            address: this.state.address,
            postalCode: this.state.postalCode,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            isActive: this.state.isActive
        };
        this.setState({ isLoading: true });
        EditAddressService(data, this.editAddressCallback, true);
    }

    editAddressCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            CommonHelper.safeFunctionCall(this.props.closeModal, resultMessageClass);
        else
            this.setState({ resultMessageClass });
    }

    closeModal = () => CommonHelper.safeFunctionCall(this.props.closeModal);

    getStates = () => GetStatesService({ id: CurrentUserHelper.getCountryId() }, this.getStatesCallback);

    getCountryCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError)
            this.setState({
                country: resultData.name,
                mapCenterLatitude: resultData.mapCenterLatitude,
                mapCenterLongitude: resultData.mapCenterLongitude,
                postalCodeJsRegexPattern: resultData.postalCodeJsRegexPattern,
                postalCodeMask: resultData.postalCodeMask,
            }, () => this.getStates());
        else
            this.setState({ resultMessageClass });
    }

    getStatesCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.hasError && !!resultData && resultData.length > 0)
            this.setState({ statesValue: resultData, isLoadedMap: true });
        else
            this.setState({ resultMessageClass });
    }

    getCitiesCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.hasError && !!resultData && resultData.length > 0)
            this.setState({ citiesValue: resultData, isLoadedMap: true });
        else
            this.setState({ resultMessageClass });
    }

    getCities = (value) => {
        if (!isEmpty(value)) {
            this.setState({ isLoading: true });
            GetCitiesService({ stateId: value }, this.getCitiesCallback);
        }
    }

    toggleTooltipClicked = () => this.setState({ isTooltipOpen: !this.state.isTooltipOpen });
    addressInputChangedByUser = (value) => this.setState({ isAddressInputChangedByUser: value });

    activationAddressClicked = (value) => {
        switch (value.key) {
            case Enumerations.activation.active:
                this.setState({ isActive: true })
                break;
            case Enumerations.activation.inactive:
                this.setState({ isActive: false })
                break;
        }
    }

    render() {
        const { stateId, isValidStateId, cityId, isValidCityId, address, isValidAddress, postalCode, isValidPostalCode, latitude,
            longitude, mapCenterLongitude, mapCenterLatitude, isTooltipOpen, isLoading, resultMessageClass, postalCodeJsRegexPattern, postalCodeMask, } = this.state;
        const { addressId } = this.props;

        return (
            <NsModal maxWidth="xl"
                dialogClassName='add-address-modal'
                dialogTitle={getTranslatedRawText(addressId ? "profile.address.modal.editAddress" : "profile.address.modal.addAddress")}
                showModal={true}
                primaryActionButton={!!addressId ? this.editClicked : this.addClicked}
                closeModal={this.closeModal}
                className="overflow-hidden"
                actions={<NsStickyButton stickyButtons={this.mainButtons} isLoading={isLoading} />}
            >
                <div className='d-flex align-items-start mb-3'>
                    <NsLabelBox label={getTranslatedRawText("profile.address.modal.country")}
                        value={this.state.country} />
                    <NsTooltip open={isTooltipOpen}
                        title={<NsTypography variant='inherit' className='font-size-14'>
                            {getTranslatedRawText("profile.address.modal.countryTooltip")}
                        </NsTypography>}
                        closeTooltip={() => this.setState({ isTooltipOpen: false })}>
                        <NsButton className='secondary-text'
                            startIcon='ns-icon-question'
                            onClick={this.toggleTooltipClicked} />
                    </NsTooltip>
                    <NsToggleGroupButton label={false}
                        items={ConstantsWithTranslation.getActivation()}
                        onClick={this.activationAddressClicked}
                        selectedItemId={this.state.isActive ? Enumerations.activation.active : Enumerations.activation.inactive} />
                </div>
                <Row>
                    <Col md={4} className="mb-3">
                        <NsCircularProgress size={24}
                            visible={isLoading} />
                        <NsSelect className='w-75'
                            label={getTranslatedRawText('components.state.state')}
                            showValidationDate={this.state.showValidationDate}
                            valueChanged={(value, isValid) => this.valueChanged(value, isValid, { stateId }, { isValidStateId })}
                            options={this.state.statesValue}
                            selectedItem={this.state.stateId} />
                    </Col>
                    <Col md={4} className="mb-3">
                        <NsCircularProgress size={24}
                            visible={isLoading} />
                        <NsSelect className='w-75'
                            label={getTranslatedRawText('components.city.city')}
                            showValidationDate={this.state.showValidationDate}
                            disabled={!stateId}
                            valueChanged={(value, isValid) => this.valueChanged(value, isValid, { cityId }, { isValidCityId })}
                            options={this.state.citiesValue}
                            selectedItem={this.state.cityId} />
                    </Col>
                    <Col md={4} className="mb-3">
                        <NsNumberInputWithPattern className='w-100 mb-3'
                            label={getTranslatedRawText("profile.address.postalCode")}
                            showValidationDate={this.state.showValidationDate}
                            value={this.state.postalCode}
                            codeMask={postalCodeMask}
                            regex={postalCodeJsRegexPattern}
                            required
                            valueChanged={(value, isValid) => this.valueChanged(value, isValid, { postalCode }, { isValidPostalCode })} />
                    </Col>
                    <Col className="mb-3">
                        <NsTextField className='w-100 mb-3'
                            label={getTranslatedRawText("profile.address.address")}
                            isInputChangedByUser={this.addressInputChangedByUser}
                            showValidationDate={this.state.showValidationDate}
                            value={this.state.address}
                            required
                            valueChanged={(value, isValid) => this.valueChanged(value, isValid, { address }, { isValidAddress })} />
                    </Col>
                </Row>

                {this.state.isLoadedMap &&
                    <NsMapWithTab lat={latitude}
                        lng={longitude}
                        mapCenterLatitude={mapCenterLatitude}
                        mapCenterLongitude={mapCenterLongitude}
                        label={getTranslatedRawText("profile.address.locationOnMap")}
                        showValidationDate={this.state.showValidationDate}
                        isAddNewAddress={!addressId}
                        onClick={this.mapClicked} />
                }

                {resultMessageClass}
            </NsModal>
        );
    }
}

export default ProfileAddressSettintgModal;