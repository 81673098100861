import React from 'react';

import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import PoModalMasterFile from '../../modals/masterFile/PoModalMasterFile';
import NsTypography from '../../../../../components/text/NsTypography';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import PoModalFilmFile from '../../modals/FilmFile/PoModalFilmFile';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import OrderHelper from '../../../../../utilities/OrderHelper';
import OBaseWrapper from '../../../bases/OBaseWrapper';
import PoBaseSelectFile from '../../bases/PoBaseSelectFile';

class PoChoosingFile extends React.Component {

    constructor(props) {
        super(props);
        this.isFrontAndBack = OrderHelper.getPrintedSide(this.props.routeId)?.key === Enumerations.printedSide.twoSide;
        this.state = {
            routeId: props.routeId,
            files: OrderHelper.getFiles(this.props.routeId),
            defaultIsOpen: true,
            selectedFileInfo: null,
            currentFileKey: 0,
            isShowMasterFileModal: false,
            isShowFilmFileModal: false,
            isFront: true,
            isShowClearOrderMessage: false,
        }
    }

    componentDidMount = () => this.getFilesFromStorage();

    componentDidUpdate = (prevProps) => {
        if (prevProps.routeId !== this.state.routeId)
            this.getFilesFromStorage();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.routeId !== prevState.routeId)
            return ({ routeId: nextProps.routeId });
        return null;
    }

    getFilesFromStorage = () => {
        OrderHelper.getLocalSuggestedFilesInfo(this.state.routeId, Enumerations.presentableFileType.none, (suggestedFilesInfo, thumbnails) => {
            let files = OrderHelper.getFiles(this.state.routeId);
            files.map((file, index) => {
                let extraFrontInfo = suggestedFilesInfo.find(suggestedFile => suggestedFile.id === file.frontSideFileInfo?.id);
                let extraBackInfo = suggestedFilesInfo.find(suggestedFile => suggestedFile.id === file.backSideFileInfo?.id);
                if (index > 0) {
                    if (!isEmpty(extraFrontInfo)) {
                        extraFrontInfo.top = file.frontSideFileInfo?.top;
                        extraFrontInfo.left = file.frontSideFileInfo?.left;
                    }
                    if (!isEmpty(extraBackInfo)) {
                        extraBackInfo.top = file.backSideFileInfo?.top;
                        extraBackInfo.left = file.backSideFileInfo?.left;
                    }
                }
                file.frontSideFileInfo = extraFrontInfo;
                file.backSideFileInfo = extraBackInfo;
                return true;
            })
            this.setState({ files });
            CommonHelper.safeFunctionCall(this.props.refreshSummary);
            CommonHelper.safeFunctionCall(this.props.refreshExtendedServices);
        });
    }

    selectedFileClicked = (selectedFileInfo, index, isFront) => {
        if (index === 0) {
            this.setState({ isShowMasterFileModal: true, selectedFileInfo, isFront, currentFileKey: index, defaultIsOpen: true })
            CommonHelper.safeFunctionCall(this.props.toggleProfessionalOrderTour);

        } else
            this.setState({ isShowFilmFileModal: true, selectedFileInfo, isFront, currentFileKey: index, defaultIsOpen: true });
    }

    closeModalClicked = () => {
        this.setState({ isShowMasterFileModal: false, isShowFilmFileModal: false }, this.getFilesFromStorage);
        CommonHelper.safeFunctionCall(this.props.toggleProfessionalOrderTour, true, Enumerations.tourGuide.poSelectingFile);
    }

    clearOrderClicked = () => this.setState({ isShowClearOrderMessage: true });

    clearOrder = (confirm) => {
        this.setState({ isShowClearOrderMessage: false });
        !!confirm && OrderHelper.clearOrder(this.state.routeId, this.getFilesFromStorage);
    }

    showSelectingFileTour = () => {
        CommonHelper.safeFunctionCall(this.props.toggleProfessionalOrderTour, true, Enumerations.tourGuide.poBeforeSelectingFile);
    }

    render() {
        const { files, isShowFilmFileModal, isShowClearOrderMessage, currentFileKey, selectedFileInfo, isShowMasterFileModal,
            isFront, defaultIsOpen, routeId } = this.state;

        return (
            <OBaseWrapper title='regularOrder.choosingFile.choosingFile' defaultIsOpen={defaultIsOpen}>
                <div className=' d-none d-md-flex mb-3'>
                    <NsTypography variant='inherit' className='section-title'>
                        {getTranslatedRawText('regularOrder.choosingFile.choosingFile')}
                    </NsTypography>
                    <NsButton label='regularOrder.choosingFile.clearOrder'
                        wrapperClassName='ms-auto'
                        size='small'
                        className='secondary-outline'
                        onClick={this.clearOrderClicked} />
                </div>

                <div className='d-flex w-100  professionalOrder-tour-choosingFile'>
                    {files.map((file, index) =>
                        <div key={index} className='d-flex flex-column pe-2'>
                            <NsTypography variant='inherit' className='font-size-12' >
                                {file.title}
                            </NsTypography>
                            <PoBaseSelectFile title='regularOrder.common.frontSide'
                                thumbnail={file.frontSideFileInfo?.thumbnail}
                                showSelectingFileTour={this.showSelectingFileTour}
                                fileDropped={(draggedFile) => this.selectedFileClicked(draggedFile, index, true)}
                                imageClicked={() => this.selectedFileClicked(file.frontSideFileInfo, index, true)}
                                selectFileClicked={() => this.selectedFileClicked(null, index, true)} />
                            {this.isFrontAndBack &&
                                <PoBaseSelectFile title='regularOrder.common.backSide'
                                    thumbnail={file.backSideFileInfo?.thumbnail}
                                    imageClicked={() => this.selectedFileClicked(file.backSideFileInfo, index, false)}
                                    fileDropped={(draggedFile) => this.selectedFileClicked(draggedFile, index, false)}
                                    selectFileClicked={() => this.selectedFileClicked(null, index, false)} />
                            }
                        </div>)
                    }

                </div>

                {isShowMasterFileModal &&
                    <PoModalMasterFile showModal={isShowMasterFileModal}
                        storageFileInfo={isFront ? files[0].frontSideFileInfo : files[0].backSideFileInfo}
                        frontSideMasterFileInfo={files[0].frontSideFileInfo}
                        selectedFileInfo={selectedFileInfo}
                        isFront={isFront}
                        routeId={routeId}
                        currentFileKey={currentFileKey}
                        closeMasterFileModalClicked={this.closeModalClicked} />
                }

                {
                    isShowFilmFileModal &&
                    <PoModalFilmFile showModal={isShowFilmFileModal}
                        title={files[currentFileKey].title}
                        storageFileInfo={isFront ? files[currentFileKey].frontSideFileInfo : files[currentFileKey].backSideFileInfo}
                        selectedFileInfo={selectedFileInfo}
                        isFront={isFront}
                        routeId={routeId}
                        frontSideMasterFileInfo={isFront ? files[0].frontSideFileInfo : files[0].backSideFileInfo}
                        currentFileKey={currentFileKey}
                        closeFilmFileModalClicked={this.closeModalClicked} />
                }

                {isShowClearOrderMessage &&
                    <NsMessageClassModal show={isShowClearOrderMessage}
                        onClose={this.clearOrder}
                        messageItems={[{
                            messageText: getTranslatedRawText('professionalOrder.choosingFile.clearOrderQuestion'),
                            messageType: Enumerations.messageType.warning
                        }]} />
                }

            </OBaseWrapper >
        );
    }
}

export default PoChoosingFile;