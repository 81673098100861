import React from 'react';

import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import Config from '../../../../configs/Config';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';
import Enumerations from '../../../../configs/Enumerations';
import { useSelector } from 'react-redux';

const ApBasesStandardSizesForUpload = (props) => {

    const standardDimensionOfCMSImages = getConfigObjectByCulture(Config.standardDimensionOfCMSImages);
    const standardDimensionForPromotions = getConfigObjectByCulture(Config.standardDimensionForPromotions);
    const store = useSelector(state => {
        return state.AdminSidebarReducer.selectedItemId;
    });

    const setStandardDimensionByPageEnum = (pageId) => {
        switch (pageId) {
            case Enumerations.adminBreadCrumbs.blogPost:
            case Enumerations.adminBreadCrumbs.newsPost:
            case Enumerations.adminBreadCrumbs.customer:
                return standardDimensionOfCMSImages.blogAndNewsItems;
            case Enumerations.adminBreadCrumbs.blogCategory:
            case Enumerations.adminBreadCrumbs.newsCategory:
            case Enumerations.adminBreadCrumbs.galleryCategories:
                return standardDimensionOfCMSImages.blogAndNewsCategories;
            case Enumerations.adminBreadCrumbs.galleryPosts:
                return standardDimensionOfCMSImages.gallery;
            case Enumerations.adminBreadCrumbs.downloadCenter:
                return standardDimensionOfCMSImages.downloadItems;
            case Enumerations.adminBreadCrumbs.downloadCategory:
                return standardDimensionOfCMSImages.downloadCategories;
            case Enumerations.adminBreadCrumbs.promotion:
            case Enumerations.adminBreadCrumbs.advertisement:
                return standardDimensionOfCMSImages.promotions;
            case Enumerations.adminBreadCrumbs.slider:
                return standardDimensionOfCMSImages.slider;
        }
    }

    const setPromotionStandardDimension = (selectedPromotion) => {
        switch (selectedPromotion) {
            case Enumerations.promotionsId.homeTopRightTop:
            case Enumerations.promotionsId.homeTopRightBottom:
            case Enumerations.promotionsId.homeTopSlider:
                return standardDimensionForPromotions.homeTop;
            case Enumerations.promotionsId.homeCenterLeft:
            case Enumerations.promotionsId.homeCenterCenter:
            case Enumerations.promotionsId.homeCenterRight:
                return standardDimensionForPromotions.homeMiddle;
            case Enumerations.promotionsId.singleTop:
                return standardDimensionForPromotions.singlePagesSidebar1;
            case Enumerations.promotionsId.singleBottom:
                return standardDimensionForPromotions.singlePagesSidebar2;
        }
    }

    return (
        <div className='d-flex mt-3 background-element-color p-2 rounded'>
            <i className='ns-icon-information h1 m-0'></i>
            <div className='font-size-12'>
                <div>
                    {getTranslatedRawText('adminPanel.standardSizesForUpload.minAndMaxDimensions')}
                    <span className='fw-bold'> {props.selectedPromotion ? setPromotionStandardDimension(props.selectedPromotion.id) : setStandardDimensionByPageEnum(store)} px </span>
                </div>
                <div>
                    {getTranslatedRawText('adminPanel.standardSizesForUpload.fileSize')}
                </div>
            </div>
        </div>
    );
};

export default React.memo(ApBasesStandardSizesForUpload);