import React, { Component } from "react";

import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import CommonHelper from "../../../utilities/CommonHelper";
import NsCard from "../../../components/cards/NsCard";
import NsCollapse from "../../../components/dropdowns/NsCollapse";
import DelayedTaskHelper from "../../../utilities/DelayedTaskHelper";
import Config from "../../../configs/Config";
import { InputAdornment, TextField } from "@mui/material";

class SidebarPrice extends Component {
    constructor(props) {
        super(props);
        this.delayPriceFilter = new DelayedTaskHelper(() => {
            this.submitPriceFilter();
        }, 1500);
        this.maximumPrice = !!props.isStoreProducts ? Config.products.storeMaximumFilterPrice : Config.products.maximumFilterPrice;
        this.state = {
            minPrice: props.filters.price.minPrice || 0,
            maxPrice: props.filters.price.maxPrice || 0 || this.maximumPrice,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            minPrice: nextProps.filters.price.minPrice,
            maxPrice: nextProps.filters.price.maxPrice
        });
    }

    minPriceChanged = (minPrice) => {
        if (minPrice < this.maximumPrice) {
            this.setState({ minPrice: +minPrice },
                () => CommonHelper.safeFunctionCall(this.props.priceMobileFilter, { minPrice, maxPrice: this.state.maxPrice, filterLabel: this.priceLabel() }));
            this.onChangeCommitted();
        }
    }

    maxPriceChanged = (maxPrice) => {
        if (maxPrice < this.maximumPrice) {
            this.setState({ maxPrice: +maxPrice },
                () => CommonHelper.safeFunctionCall(this.props.priceMobileFilter, { minPrice: this.state.minPrice, maxPrice, filterLabel: this.priceLabel() }));
            this.onChangeCommitted();
        }
    }

    onChangeCommitted = () => {
        this.delayPriceFilter.run();
    }

    submitPriceFilter = () => {
        const { minPrice, maxPrice } = this.state;
        const maximumFilterPrice = !!this.props.isStoreProducts ? Config.products.storeMaximumFilterPrice : Config.products.maximumFilterPrice;
        CommonHelper.safeFunctionCall(this.props.priceFilter, { minPrice, maxPrice, filterLabel: this.state.minPrice !== 0 || this.state.maxPrice !== maximumFilterPrice ? this.priceLabel() : '' });
    }

    priceLabel = () => {
        const { minPrice, maxPrice } = this.state;
        return (
            CommonHelper.stringFormat(getTranslatedRawText('product.detail.priceLabel'),
                CommonHelper.currencyFormatByDefaultConfig(minPrice),
                CommonHelper.currencyFormatByDefaultConfig(maxPrice))
        );
    }

    render() {
        const { minPrice, maxPrice } = this.state;
        const { isStoreProducts } = this.props;
        const maximumPrice = !!isStoreProducts ? Config.products.storeMaximumFilterPrice : Config.products.maximumFilterPrice;

        return (
            <NsCard className='p-3 price'>
                <NsCollapse className='mb-2'
                    defaultIsOpen={true}
                    titleClassName='section-title w-100'
                    title={getTranslatedRawText('product.sideBarTitles.price')} >
                    <div className='w-100'>
                        <div className="px-2">
                            <TextField type='number'
                                label={getTranslatedRawText('profile.allOrders.minPrice')}
                                className='w-100 mb-3'
                                variant='standard'
                                value={minPrice || 0}
                                onChange={(e) => this.minPriceChanged(e.target.value)}
                                InputProps={{
                                    inputMode: 'numeric',
                                    inputProps: { min: 0, max: maximumPrice },
                                    endAdornment: <InputAdornment position="end">
                                        <span className='font-size-12'>{CommonHelper.getDefaultCurrency() || ''}</span>
                                    </InputAdornment>
                                }}
                            />

                            <TextField type='number'
                                label={getTranslatedRawText('profile.allOrders.maxPrice')}
                                className="w-100"
                                variant='standard'
                                value={maxPrice || maximumPrice}
                                onChange={(e) => this.maxPriceChanged(e.target.value)}
                                InputProps={{
                                    inputMode: 'numeric',
                                    inputProps: { min: 0, max: maximumPrice },
                                    endAdornment: <InputAdornment position="end">
                                        <span className='font-size-12'>{CommonHelper.getDefaultCurrency() || ''}</span>
                                    </InputAdornment>
                                }}
                            />
                        </div>
                    </div>
                </NsCollapse>
            </NsCard>
        );
    }
}

export default SidebarPrice;