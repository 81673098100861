import Enumerations from "../../../configs/Enumerations"
import Links from "../../../configs/links/AllLinks"

class SpSidebarHelper {
    static setLink(id) {
        switch (id) {
            case Enumerations.staticPageType.aboutUs: return Links.aboutUs
            case Enumerations.staticPageType.contactUs: return Links.contactUs
            case Enumerations.staticPageType.ourEquipment: return Links.ourEquipment
            case Enumerations.staticPageType.faqs: return Links.frequentlyAskedQuestions
            case Enumerations.staticPageType.gallery: return Links.gallery
            case Enumerations.staticPageType.termOfUse: return Links.termsOfUse
            case Enumerations.staticPageType.privacyPolicy: return Links.privacyPolicy
            case Enumerations.staticPageType.warrantyTerms: return Links.warrantyTerms
            case Enumerations.staticPageType.festival: return Links.festivals
            case Enumerations.staticPageType.requestSample: return '#'
            case Enumerations.staticPageType.downloadTemplate: return Links.downloadCenter
            case Enumerations.staticPageType.siteMap: return Links.siteMap
            default:
                break;
        }
    }

    static setEnum(id) {
        switch (id) {
            case Enumerations.staticPageType.aboutUs: return Enumerations.breadCrumbs.aboutUs
            case Enumerations.staticPageType.contactUs: return Enumerations.breadCrumbs.contactUs
            case Enumerations.staticPageType.instantDiscounts: return Enumerations.breadCrumbs.instantDiscounts
            case Enumerations.staticPageType.ourEquipment: return Enumerations.breadCrumbs.ourEquipment
            case Enumerations.staticPageType.faqs: return Enumerations.breadCrumbs.faqs
            case Enumerations.staticPageType.gallery: return Enumerations.breadCrumbs.gallery
            case Enumerations.staticPageType.termOfUse: return Enumerations.breadCrumbs.termOfUse
            case Enumerations.staticPageType.privacyPolicy: return Enumerations.breadCrumbs.privacyPolicy
            case Enumerations.staticPageType.warrantyTerms: return Enumerations.breadCrumbs.warrantyTerms
            case Enumerations.staticPageType.festival: return Enumerations.breadCrumbs.festivals
            case Enumerations.staticPageType.requestSample: return Enumerations.breadCrumbs.none
            case Enumerations.staticPageType.downloadTemplate: return Enumerations.breadCrumbs.downloadCenter
            case Enumerations.staticPageType.siteMap: return Enumerations.breadCrumbs.siteMap
            case Enumerations.staticPageType.specialNeeds: return Enumerations.breadCrumbs.specialNeeds
            default:
                break;
        }
    };
}

export default SpSidebarHelper;