import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { isBusinessPartner } from '../../../../../../utilities/ClaimHelper';
import NsButton from '../../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../../configs/Enumerations';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import EnumerationTranslationHelper from '../../../../../../utilities/EnumerationTranslationHelper';

export const createGroupSheetOrderColumn = (hasInvoiceNumber, showConfirmedTime, showSubmitTime, showTotalQuantity,
    detailViewClicked, deleteOrderClicked, changeStateFromFailToPreparing, hideEstimateReadyToDeliverTime) =>
    [
        {
            field: 'invoiceDetail',
            headerName: getTranslatedRawText('profile.dataGridColumns.action'),
            width: 120,
            lockPosition: true,
            resizable: false,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return props.lastStatusType === Enumerations.sharedSheetOrderStateType.prepareFail && <>
                        <NsButton className='secondary-text'
                            wrapperClassName={props.paymentStateType === Enumerations.paymentStateType.success && 'invisible'}
                            startIcon='ns-icon-retry cursor-pointer'
                            onClick={() => CommonHelper.safeFunctionCall(changeStateFromFailToPreparing, props.id)} />
                        <NsButton className='secondary-text'
                            wrapperClassName={props.paymentStateType === Enumerations.paymentStateType.success && 'invisible'}
                            startIcon='ns-icon-delete cursor-pointer'
                            onClick={() => CommonHelper.safeFunctionCall(deleteOrderClicked, props)} />
                    </>
                },
                hasDetail: true,
                detailClicked: (id) => CommonHelper.safeFunctionCall(detailViewClicked, id),
            }
        },

        !!showSubmitTime ? {
            field: 'submitTime',
            headerName: getTranslatedRawText('profile.dataGridColumns.submitDate'),
            width: 115,
            maxWidth: 130,
            dataType: Enumerations.gridType.dateTime,
        } : { hide: true },

        !!hasInvoiceNumber ? {
            field: 'nasInvoiceId',
            dataType: Enumerations.gridType.number,
            headerName: getTranslatedRawText('profile.dataGridColumns.invoiceNumber'),
            width: 135,
        } : { hide: true },
        !hideEstimateReadyToDeliverTime ? {
            field: 'estimateReadyToDeliverTime',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime'),
            width: 250,
            dataType: Enumerations.gridType.dateTime,
        } : { hide: true },
        {
            field: 'priceExclusiveOfVat',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat'),
            width: 150,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'priceInclusiveOfVat',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithVat'),
            width: 150,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'productName',
            headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
            width: 210,
            renderCell: (params) => {
                return (
                    <span data-toggle="tooltip" data-placement="right" className='text-truncate' title={params.row.productName}>{params.row.productName}</span>
                );
            },
        },
        {
            field: 'circulation',
            headerName: getTranslatedRawText('profile.dataGridColumns.circulation'),
            width: 105,
            dataType: Enumerations.gridType.circulation
        },

        !!showConfirmedTime ? {
            field: 'confirmedTime',
            headerName: getTranslatedRawText('profile.dataGridColumns.confirmedDate'),
            width: 140,
            dataType: Enumerations.gridType.dateTime,
        } : { hide: true },

        {
            field: 'twoSidePrintingType',
            headerName: getTranslatedRawText('profile.dataGridColumns.printedSide'),
            width: 115,
            dataType: Enumerations.gridType.printSide,
        },
        {
            field: 'turnaroundType',
            headerName: getTranslatedRawText('profile.dataGridColumns.turnaround'),
            width: 110,
            dataType: Enumerations.gridType.turnaround,
        },
        !!showTotalQuantity ? {
            field: 'totalQuantity',
            dataType: Enumerations.gridType.number,
            headerName: getTranslatedRawText('profile.dataGridColumns.totalQuantity'),
            width: 125
        } : { hide: true },
        isBusinessPartner() &&
        {
            field: 'businessPartnerCustomerIdentifier',
            headerName: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
            width: 160,
        },
    ];

export const createGroupSheetAllOrderColumns = (detailViewClicked, isAdvancedMod) => {
    return ([
        {
            field: 'invoiceDetail',
            headerName: getTranslatedRawText('profile.dataGridColumns.action'),
            width: 80,
            lockPosition: true,
            resizable: false,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => <NsButton startIcon='ns-icon-invoice-details'
                    className='secondary-text p-0'
                    onClick={() => CommonHelper.safeFunctionCall(detailViewClicked, props.id, props.lastStatusType)} />
            }
        },
        {
            field: 'sharedSheetOrderStateType',
            headerName: getTranslatedRawText('profile.dataGridColumns.page'),
            width: 110,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div>
                        {ConstantsWithTranslation.getPageNameBasedOnId(props?.lastStatusType)}
                    </div>
                },
            }
        },
        {
            field: 'estimateReadyToDeliverTime',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime'),
            width: 250,
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'lastStatusType',
            headerName: getTranslatedRawText('profile.dataGridColumns.status'),
            width: 140,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return <div className={`${CommonHelper.getShareSheetStateColor(props?.lastStatusType)}`}>
                        {ConstantsWithTranslation.getSharedSheetOrderStateType(props?.lastStatusType)}
                    </div>
                },
            }
        },
        {
            field: 'lastStatusTime',
            headerName: getTranslatedRawText('profile.dataGridColumns.statusDate'),
            width: 120,
            sortable: true,
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'submitTime',
            sortable: true,
            headerName: getTranslatedRawText('profile.dataGridColumns.submitDate'),
            width: 120,
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'nasInvoiceId',
            dataType: Enumerations.gridType.number,
            headerName: getTranslatedRawText('profile.dataGridColumns.invoiceNumber'),
            width: 110,
        },
        {
            field: 'priceExclusiveOfVat',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat'),
            width: 150,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'priceInclusiveOfVat',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithVat'),
            width: 150,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'productName',
            headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
            width: 210,
            renderCell: (params) => {
                return (
                    <span data-toggle="tooltip" data-placement="right" className='text-truncate' title={params.row.productName}>{params.row.productName}</span>
                );
            },
        },
        {
            field: 'circulation',
            headerName: getTranslatedRawText('profile.dataGridColumns.circulation'),
            width: 105,
            dataType: Enumerations.gridType.circulation
        },
        isAdvancedMod ? {
            field: 'confirmedTime',
            headerName: getTranslatedRawText('profile.dataGridColumns.confirmedDate'),
            width: 140,
            dataType: Enumerations.gridType.dateTime,
        } : { hide: true },
        isAdvancedMod ? {
            field: 'deliveredTime',
            headerName: getTranslatedRawText('profile.dataGridColumns.deliveredDate'),
            width: 115,
            dataType: Enumerations.gridType.dateTime,
        } : { hide: true },
        {
            field: 'twoSidePrintingType',
            headerName: getTranslatedRawText('profile.dataGridColumns.printedSide'),
            width: 115,
            dataType: Enumerations.gridType.printSide,
        },
        {
            field: 'turnaroundType',
            headerName: getTranslatedRawText('profile.dataGridColumns.turnaround'),
            width: 110,
            dataType: Enumerations.gridType.turnaround,
        },
        {
            field: 'totalQuantity',
            dataType: Enumerations.gridType.number,
            headerName: getTranslatedRawText('profile.dataGridColumns.totalQuantity'),
            width: 125
        },
        isAdvancedMod ? {
            field: 'priceInclusiveOfVat',
            headerName: getTranslatedRawText('profile.dataGridColumns.priceInclusiveOfVat'),
            width: 110,
            dataType: Enumerations.gridType.price,
        } : { hide: true },
        isAdvancedMod
            ? {
                field: 'paymentInAdvance',
                headerName: getTranslatedRawText('profile.dataGridColumns.paymentInAdvance'),
                width: 110,
                dataType: Enumerations.gridType.price,
            }
            : { hide: true },
        isBusinessPartner() &&
        {
            field: 'businessPartnerCustomerIdentifier',
            headerName: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
            width: 160,
        },
    ]);
}

export const createMyShippingColumn = (detailViewClicked, isSendingToWarehouse) => [
    {
        field: 'action',
        headerName: getTranslatedRawText('profile.dataGridColumns.action'),
        width: 80,
        lockPosition: true,
        resizable: false,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <NsButton startIcon='ns-icon-invoice-details'
                    className='secondary-text px-0'
                    onClick={() => detailViewClicked(props)} />
            },
        }
    },
    {
        field: 'requestNumber',
        headerName: getTranslatedRawText('profile.delivery.shippingRequestNumber'),
        dataType: Enumerations.gridType.number,
        width: 170,
    },
    !!isSendingToWarehouse && {
        field: 'estimatedDeliveryToShippingMethod',
        headerName: getTranslatedRawText('adminPanel.shippings.estimatedDeliveryTimeToShippingMethod'),
        width: 250,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'requestDate',
        headerName: getTranslatedRawText('profile.delivery.shippingRequestTime'),
        width: 170,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'shippingPlanTypeId',
        headerName: getTranslatedRawText('adminPanel.shippings.shippingPlan'),
        width: 100,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className="d-flex" >
                    {EnumerationTranslationHelper.getShippingPlanType(props?.shippingPlanTypeId)}
                </div >
            },
        }
    },
    {
        field: 'shippingName',
        headerName: getTranslatedRawText('profile.delivery.shippingMethod'),
        width: 150,
    },
    {
        field: 'orderQuantity',
        headerName: getTranslatedRawText('profile.delivery.orderCount'),
        width: 120,
    },
    {
        field: 'orderDetailSeriesCount',
        headerName: getTranslatedRawText('profile.dataGridColumns.orderDetailSeriesCount'),
        width: 200,
    },
    {
        field: 'estimatedDeliveryToShippingMethod',
        headerName: getTranslatedRawText('profile.delivery.estimatedSendingTimeToShippingMethod'),
        width: 250,
        dataType: Enumerations.gridType.dateTime,
    },
]

export const createMyShippingDetailColumn = (detailViewClicked, inModal) => {
    return ([
        {
            field: 'action',
            headerName: getTranslatedRawText('profile.dataGridColumns.action'),
            width: 110,
            resizable: false,
            lockPosition: true,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => detailViewClicked(id),
            }
        },
        {
            field: 'productName',
            headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
            width: 170,
        },

        {
            field: 'nasInvoiceId',
            headerName: getTranslatedRawText('profile.dataGridColumns.invoiceNumber'),
            dataType: Enumerations.gridType.number,
            width: 140,
        },
        {
            field: 'submitTime',
            headerName: getTranslatedRawText('profile.dataGridColumns.submitTime'),
            width: 150,
            dataType: Enumerations.gridType.dateTime,
        },

        !inModal && {
            field: 'twoSidePrintingType',
            headerName: getTranslatedRawText('profile.dataGridColumns.printedSide'),
            width: 105,
            dataType: Enumerations.gridType.printSide,
            width: 120,
        },
        {
            field: 'turnaroundType',
            headerName: getTranslatedRawText('profile.dataGridColumns.turnaround'),
            dataType: Enumerations.gridType.turnaround,
            width: 110,
        },
        {
            field: 'priceExclusiveOfVat',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat'),
            width: 150,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'priceInclusiveOfVat',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithVat'),
            width: 150,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'circulation',
            headerName: getTranslatedRawText('profile.dataGridColumns.circulation'),
            dataType: Enumerations.gridType.circulation,
            width: 110,
        },
    ]);
}
