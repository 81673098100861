import React from 'react';

import UpCartOrderContent from '../contents/userOrder/carts/UpCartOrderContent';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import { connect } from 'react-redux';

class OrderCartModal extends React.Component {

    closeModal = () => CommonHelper.safeFunctionCall(this.props.closeModal);

    render() {
        return (
            <NsModal maxWidth="md"
                dialogTitle={getTranslatedRawText('profile.orderCart.cart')}
                showModal={true}
                closeModal={this.closeModal}>

                <UpCartOrderContent selectedTab={this.props.selectedTab}
                    isModal={true}
                    selectedTabIndex={this.props.selectedTabIndex} />
                {false && <div className='my-3 primary-paragraph'>
                    <b>{getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat')}:</b>
                    <span> {CommonHelper.currencyFormatByDefaultConfig(this.props.miniCartInfo.totalPrice)}</span>
                </div>
                }
            </NsModal>
        )
    }
}

const mapStateToProps = state => ({ miniCartInfo: state.miniCartInfo.miniCartInfo });


export default connect(mapStateToProps)(OrderCartModal);