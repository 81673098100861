import Config from '../configs/Config';
import Constants from '../configs/Constants';
import Enumerations from '../configs/Enumerations';
const validFileExtensions = Config.validFileExtensions;

export const mobileIsValid = (mobile, regString) => {
	let regex = new RegExp(regString);
	return (regex.test(mobile));
}

export const trnIsValid = (trn, trnJsRegexPattern) => {
	let regex = new RegExp(trnJsRegexPattern);
	return (regex.test(trn));
}

export const fileOrFolderNameIsValid = (name) => {
	let regex = new RegExp(Constants.regex.fileAndFolder);
	return (regex.test(name));
}

export const passwordIsValid = (password) => {
	let regex = new RegExp(Constants.regex.password);
	return (regex.test(password));
}

export const isValidInvoiceNumber = (invoiceNumber) => {
	let regex = new RegExp(Constants.regex.invoiceNumber);
	return (regex.test(invoiceNumber));
}

export const isValidIdNumber = (idNumber, countryId, nationalCodeJsRegexPattern) => {
	if (!isEmpty(nationalCodeJsRegexPattern)) {
		let regex = new RegExp(nationalCodeJsRegexPattern)
		return (regex.test(idNumber));
	} else {
		switch (countryId) {
			case Enumerations.countryId.uae: {
				let regex = new RegExp(Constants.regex.uae)
				return (regex.test(idNumber));
			}
			case Enumerations.countryId.oman: {
				let regex = new RegExp(Constants.regex.oman)
				return (regex.test(idNumber));
			}
			case Enumerations.countryId.qatar: {
				let regex = new RegExp(Constants.regex.qatar)
				return (regex.test(idNumber));
			}
			case Enumerations.countryId.kuwait: {
				let regex = new RegExp(Constants.regex.kuwait)
				return (regex.test(idNumber));
			}
			case Enumerations.countryId.bahrain: {
				let regex = new RegExp(Constants.regex.bahrain)
				return (regex.test(idNumber));
			}
			case Enumerations.countryId.iraq: {
				let regex = new RegExp(Constants.regex.iraq)
				return (regex.test(idNumber));
			}
			case Enumerations.countryId.azerbaijan: {
				let regex = new RegExp(Constants.regex.azerbaijan)
				return (regex.test(idNumber));
			}
			case Enumerations.countryId.iran: {
				let regex = new RegExp(Constants.regex.iran)
				if (regex.test(idNumber)) {
					return isValidNationalCodeForIran(idNumber);
				}
				return false;
			}
			case Enumerations.countryId.turkey: {
				let regex = new RegExp(Constants.regex.turkey)
				if (regex.test(idNumber)) {
					return () => isValidNationalCodeForTurkey(idNumber);
				}
				return false;
			}
			default: return false;
		}
	}
}

export const isContainNumber = (password) => {
	let regex = new RegExp(Constants.regex.containNumber);
	return (regex.test(password));
}

export const isContainCharacter = (password) => {
	let regex = new RegExp(Constants.regex.containCharacters);
	return (regex.test(password));
}

export const englishCharacterIsValid = (password) => {
	let regex = new RegExp(Constants.regex.englishCharacters);
	return (regex.test(password));
}

export const emailIsValid = (email) => {
	let regex = new RegExp(Constants.regex.email);
	return (regex.test(email));

}

export const isValidFileExtension = (fileName, accept) => {
	let validFileExtensionList = !isEmpty(accept)
		? accept
		: validFileExtensions
	let fileExtension = fileName?.split('.')?.pop();
	if (validFileExtensionList.indexOf(fileExtension?.toString()?.toLowerCase()) > -1)
		return true;
	return false;
}

export const isValidImgExtension = (extensionEnum) => {
	let imgExtensions = [
		Enumerations.fileExtension.jpeg,
		Enumerations.fileExtension.jpg,
		Enumerations.fileExtension.png,
		Enumerations.fileExtension.webp,
		Enumerations.fileExtension.gif,
	]
	return imgExtensions.includes(extensionEnum)
}

export const isValidExternalUrl = (url) => {
	let regex = new RegExp(Constants.regex.url);
	return (regex.test(url));
}

export const isEmpty = (value) =>
	value === undefined ||
	value === null ||
	(typeof value === 'object' && Object.keys(value).length === 0) ||
	(typeof value === 'string' && value.trim().length === 0);

export const isNumberBetweenRange = (value, min, max) => {
	return value >= min && value <= max;
}

export const isValidNumber = (number) => {
	let regex = new RegExp(Constants.regex.number);
	return (regex.test(number));
}

const isValidNationalCodeForIran = code => {
	if (code.length !== 10) return false;

	let sum = 0,
		chars = code.split(''),
		lastDigit,
		remainder;

	for (let i = 0; i < 9; i++) sum += +chars[i] * (10 - i);

	remainder = sum % 11;
	lastDigit = remainder < 2 ? remainder : 11 - remainder;

	return +chars[9] === lastDigit;
};

const isValidNationalCodeForTurkey = code => {
	let value = String(code);

	const digits = value.split('');
	// store last 2 digits (10th and 11th) which are actually used for validation
	const d10 = Number(digits[9]);
	const d11 = Number(digits[10]);
	// we'll also need the sum of first 10 digits for validation
	let sumOf10 = 0;
	let evens = 0;
	let odds = 0;

	digits.forEach((d, index) => {
		d = Number(d);
		if (index < 10) sumOf10 += d;
		if (index < 9) {
			if ((index + 1) % 2 === 0) {
				evens += d;
			} else {
				odds += d;
			}
		}
	});

	// check if the unit-digit of the sum of first 10 digits equals to the 11th digit.
	if (sumOf10 % 10 !== d11) return false;

	// check if unit-digit of the sum of odds * 7 and evens * 9 is equal to 10th digit.
	if (((odds * 7) + (evens * 9)) % 10 !== d10) return false;

	// check if unit-digit of the sum of odds * 8 is equal to 11th digit.
	if ((odds * 8) % 10 !== d11) return false;

	return true;

};