import React from 'react';
import { withRouter } from 'react-router-dom';

import { GetAllOrderExcelReportService, GetAllOrderFilterExcelReportService, GetAllOrdersFilterService, GetAllOrdersService } from '../../../../../../services/ProfileSetting/UserOrderService';
import NsExportExcelReportButton from '../../../../../../components/downloadFiles/NsExportExcelReportButton';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../../utilities/CultureHelper';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import UpBaseGroupSheetOrdersSearchSection from '../bases/UpBaseGroupSheetOrdersSearchSection';
import { createGroupSheetAllOrderColumns } from '../bases/UpBaseGroupSheetOrderHelper';
import UpGroupSheetAllOrderDetailModal from './UpGroupSheetAllOrderDetailModal';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../../../UserPanelWrapper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import DateHelper from '../../../../../../utilities/DateHelper';
import Links from '../../../../../../configs/links/AllLinks';
import UrlHelper from '../../../../../../utilities/UrlHelper';

class UpGroupSheetAllOrders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            searchValues: null,
            showDetailModal: false,
            orderId: null,
            lastStatusType: null,
            advancedMode: false,
            callServiceDate: null,
            openFeedbackModalDate: null,
            columns: createGroupSheetAllOrderColumns(this.detailViewClicked, false),
            resultMessageClass: (<></>)
        };
    }

    componentDidMount = () => {
        let pathparams = UrlHelper.checkUrlIsOrderDetailFeedback(window.location.pathname);
        if (pathparams.isOrderDetail) {
            this.detailViewClicked(pathparams.orderId);
            if (pathparams.isOrderDetailFeedback)
                this.setState({ openFeedbackModalDate: new Date() });
        }
    }

    switchChanged = (advancedMode) => this.setState({ advancedMode, isLoading: true, columns: createGroupSheetAllOrderColumns(this.detailViewClicked, advancedMode) });

    searchFormClicked = (searchValues) => this.setState({ searchValues, isLoading: true, callServiceDate: new Date() });

    getData = (rows, isLoading) => this.setState({ isLoading });

    detailViewClicked = (id, lastStatusType) => this.setState({ showDetailModal: true, orderId: id, lastStatusType }, () => {
        this.props.history.push(CommonHelper.stringFormat(this.props.isAgency
            ? getUrlWithCurrentCulture(Links.view.main.agency, Links.view.main.agency.agencyCustomersOrderDetail)
            : getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.allOrdersDetail), id));
    });

    closeDetailModalClicked = (resultMessageClass) => this.setState({ showDetailModal: false, openFeedbackModalDate: null }, () => {
        this.props.history.push(this.props.isAgency
            ? getUrlWithCurrentCulture(Links.view.main.agency, Links.view.main.agency.agencyCustomersOrders)
            : getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.allOrders)
        );
        !isEmpty(resultMessageClass) && this.setState({ callServiceDate: new Date(), resultMessageClass });
    });

    createParameters = () => {
        const { searchValues, advancedMode } = this.state;

        let data = {
            nasInvoiceIds: searchValues?.nasInvoiceIds,
            productId: !isEmpty(searchValues?.pprId) ? [searchValues?.pprId] : null,
            fromSubmitTime: searchValues?.fromSubmitTime,
            toSubmitTime: searchValues?.toSubmitTime,
        };
        return data = advancedMode
            ? {
                ...data,
                fromConfirmTime: DateHelper.getDateWithEndOfDayTime(searchValues?.confirmTimeFrom),
                toConfirmTime: DateHelper.getDateWithEndOfDayTime(searchValues?.confirmTimeTo),
                turnaroundTypes: !isEmpty(searchValues?.turnaround) ? [searchValues.turnaround?.id] : null,
                twoSidePrintingTypes: !isEmpty(searchValues?.printedSide) ? [searchValues.printedSide?.id] : null,
                productCategoryIds: !isEmpty(searchValues?.productCategory) ? [searchValues.productCategory?.id] : null,
                fromDeliverTime: DateHelper.getDateWithEndOfDayTime(searchValues?.deliveredTimeFrom),
                toDeliverTime: DateHelper.getDateWithEndOfDayTime(searchValues?.deliveredTimeTo),
                minPrice: !isEmpty(searchValues?.minPrice) ? Number(searchValues?.minPrice) : null,
                maxPrice: !isEmpty(searchValues?.maxPrice) ? Number(searchValues?.maxPrice) : null,
                sharedSheetOrderStateTypes: !isEmpty(searchValues?.status) ? searchValues.status?.sharedSheetOrderStateTypes : null,
                businessPartnerCustomerIdentifier: !isEmpty(searchValues?.businessPartnerCustomerIdentifier) ? searchValues?.businessPartnerCustomerIdentifier : null,
            }
            : data
    }

    render() {
        const { isLoading, advancedMode, callServiceDate, searchValues, orderId, columns, showDetailModal, lastStatusType, resultMessageClass, openFeedbackModalDate } = this.state;
        const { isAgency, titleIcon, agencyAdvanceService, agencyBasicService } = this.props;
        return (
            <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.allOrders')}
                titleIcon={titleIcon || 'ns-icon-find-orders'}>

                <UpBaseGroupSheetOrdersSearchSection searchFormClicked={this.searchFormClicked}
                    isAgency={isAgency}
                    advancedMode={advancedMode}
                    isLoading={isLoading}
                    autoFocus={true}
                    switchChanged={this.switchChanged}
                    hasAdvancedMode={true}
                    findOrdersSearchSection={true} >

                    <NsExportExcelReportButton searchValues={this.createParameters()}
                        isLoading={isLoading}
                        service={advancedMode
                            ? GetAllOrderFilterExcelReportService
                            : GetAllOrderExcelReportService} />

                </UpBaseGroupSheetOrdersSearchSection>

                <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                    key={advancedMode}
                    service={isAgency
                        ? advancedMode
                            ? agencyAdvanceService
                            : agencyBasicService
                        : advancedMode
                            ? GetAllOrdersFilterService
                            : GetAllOrdersService
                    }
                    parameters={{ filter: this.createParameters() }}
                    hasPagination={true}
                    getData={this.getData}
                    columnDefs={columns}
                    gridId={advancedMode ? GridEnumerations.siteGrids.advancedAllGroupSheetOrders : GridEnumerations.siteGrids.allGroupSheetOrders}
                    hasCustomColumnOrder={true} />

                {showDetailModal && orderId &&
                    <UpGroupSheetAllOrderDetailModal closeModal={this.closeDetailModalClicked}
                        isAgency={isAgency}
                        openFeedbackModalDate={openFeedbackModalDate}
                        breadcrumbs={this.props.breadCrumb}
                        sharedSheetOrderStateType={lastStatusType}
                        orderId={orderId} />
                }
                {resultMessageClass}
            </UserPanelWrapper >
        )
    }
}

export default withRouter(UpGroupSheetAllOrders);