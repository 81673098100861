import React from 'react';
import { Redirect } from 'react-router-dom';

import Links from '../../../configs/links/AllLinks';
import { getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';

const AuthenticationChecker = (props) => {

    const { needAuthentication } = props;

    if (!!needAuthentication && !CurrentUserHelper.isAuthenticated()) {
        const backUrl = !isEmpty(window.location.pathname) ? { 'back-url': window.location.pathname } : ''
        return (<Redirect to={getUrlWithCurrentCulture(Links.view.account, Links.view.account.signIn, backUrl)} status={302} />);
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
}

export default React.memo(AuthenticationChecker);