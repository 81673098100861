import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { TableRowSkeleton } from '../../../../../components/availableDimensions/NsAvailableDimensionTable';
import NsStaticDatePicker from '../../../../../components/dateTimePickers/NsStaticDatePicker';
import NsLabelNoData from '../../../../../components/labels/NsLabelNoData';
import NsModal from '../../../../../components/modals/NsModal';
import NsSkeleton from '../../../../../components/skeletons/NsSkeleton';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { GetProfileOrderEstimatedDeliveryTimeService } from '../../../../../services/ProfileSetting/UserOrderService';
import { GetOrderEstimatedDeliveryTimeService } from '../../../../../services/adminPanel/AdminOrderService';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../../../../utilities/CultureHelper';
import DateHelper from '../../../../../utilities/DateHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApBaseUserInfoModal from '../../users/bases/ApBaseUserInfoModal';
import NsTab from '../../../../../components/tabs/NsTab';
import NsTimePicker from '../../../../../components/dateTimePickers/NsTimePicker';
import NsDatePicker from '../../../../../components/dateTimePickers/NsDatePicker';
import { GetOPreparationEstimatedTimeSheetsBySelectedConfirmTimeService } from '../../../../../services/product/ProductService';

const ApShareSheetOrderEstimatedTimeModal = (props) => {
    const isExpressAvailable = props?.productDetails?.turnarounds?.length > 1;
    const hasAccessInfo = hasAccess([UserClaimEnumerations.getApUserBasicInfoById]);
    const tabIndex = props?.productDetails?.turnarounds.findIndex(turnaround => turnaround?.key === props?.productDetails?.selectedTurnaround?.key);

    const { selectedOrder, isAdminPanel } = props;
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [orderBusinessDays, setOrderBusinessDays] = useState([]);
    const [info, setInfo] = useState();
    const [isTwoMonth, setIsTwoMonth] = useState(false);
    const [normalBusinessDays, setNoramalBusinessDays] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pdrParameters, setPdrParameters] = useState({
        PprId: props?.productDetails?.pprId,
        TurnaroundTypeId: props?.productDetails?.selectedTurnaround?.key,
        TwoSidePrintingTypeId: props?.productDetails?.selectedPrintedSide?.key,
        ConfirmTime: DateHelper.getIsoDateTime(DateHelper.getTodayDateTime(0))
    });
    const [selectedTabIndex, setSelectedTabIndex] = useState(tabIndex);
    const [selectedTab, setSelectedTab] = useState(props?.productDetails?.selectedTurnaround);
    const [confirmationTime, setConfirmationTime] = useState(DateHelper.getTodayDateTime(0));
    const [confirmationDate, setConfirmationDate] = useState(DateHelper.getTodayDateTime(0));
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    const toggleUserInfoClicked = () => setShowUserInfoModal(!showUserInfoModal);

    const closeModal = () => CommonHelper.safeFunctionCall(props.closeModal);

    useEffect(() => {
        isAdminPanel
            ? GetOrderEstimatedDeliveryTimeService(selectedOrder?.id, getOrderStimatedDeliveryTimeCallback)
            : !!selectedOrder?.id ? GetProfileOrderEstimatedDeliveryTimeService(selectedOrder?.id, getOrderStimatedDeliveryTimeCallback)
                : GetOPreparationEstimatedTimeSheetsBySelectedConfirmTimeService(pdrParameters, getOrderStimatedDeliveryTimeCallback)
    }, [pdrParameters.ConfirmTime, pdrParameters.TurnaroundTypeId])

    const getCurrentMonth = (date) => {
        if (isEnCulture())
            return new Date(date).getMonth() + 1;
        else
            return date.split('/')[1];
    }

    const confirmationDateAndTimeCallback = (dateAndTime) => {
        setPdrParameters(prevState => ({
            ...prevState,
            ConfirmTime: dateAndTime,
        }))
        setIsLoading(false);
    };

    useEffect(() => {
        DateHelper.mergeDateAndTime(confirmationDate, confirmationTime, confirmationDateAndTimeCallback);
        setIsLoading(true);
    }, [confirmationTime, confirmationDate])

    useEffect(() => {
        if (isEmpty(orderBusinessDays)) return;
        let normalBusinessDays = [];
        let isReachedToDeliveryDate = false;
        let currentMonth = null;
        let isTwoMonth = false;
        orderBusinessDays.map(item => {
            let currentDate = DateHelper.getDatePart(item.dayDateTime);
            let isoCurrentDate = DateHelper.convertDateTimeToDate(new Date(item?.dayDateTime));
            item.messageClass.messageItems.map(message => {
                switch (message.data) {
                    case Enumerations.timeSheetDayType.confirmOrderDay:
                    case Enumerations.timeSheetDayType.normalBusinessDay:
                        !isReachedToDeliveryDate && !normalBusinessDays.includes(isoCurrentDate) && normalBusinessDays.push(isoCurrentDate);
                        currentMonth = !!currentMonth ? currentMonth : getCurrentMonth(currentDate);
                        isTwoMonth = isReachedToDeliveryDate ? isTwoMonth : currentMonth !== getCurrentMonth(currentDate);
                        break;
                    case Enumerations.timeSheetDayType.completionDay:
                        isTwoMonth = isReachedToDeliveryDate ? isTwoMonth : currentMonth !== getCurrentMonth(currentDate);
                        isReachedToDeliveryDate = true; !normalBusinessDays.includes(isoCurrentDate) && normalBusinessDays.push(isoCurrentDate);
                        break;
                }
            })
        })
        setNoramalBusinessDays(normalBusinessDays);
        setIsTwoMonth(isTwoMonth);
    }, [orderBusinessDays])

    const getOrderStimatedDeliveryTimeCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result?.HasError) {
            setOrderBusinessDays(resultData?.timeSheetOrderPreparationEstimates);
            setInfo({
                fullName: resultData?.userFullName,
                productName: resultData?.productName,
                turnaroundType: resultData?.turnaroundType,
                nasInvoiceId: resultData?.nasInvoiceId,
                submitTime: resultData?.submitTime,
                confirmTime: resultData?.confirmTime,
                estimateReadyToDeliverTime: resultData?.estimateReadyToDeliverTime,
                actualReadyToDeliveryTime: resultData?.actualReadyToDeliveryTime,
                actualDeliveredTime: resultData?.actualDeliveredTime,
                businessDays: DateHelper.calcProcessHoursAndDays(resultData?.orderPreparationEstimateHour)
            });
        }

        setMessageClassModal(messageClassModal);
    }

    const tabChanged = (selectedTabIndex, selectedTab) => {
        setSelectedTabIndex(selectedTabIndex);
        setSelectedTab(selectedTab);
        setPdrParameters(prevState => ({
            ...prevState,
            TurnaroundTypeId: selectedTab?.key
        }));
    }

    const headerButtons = [
        hasAccessInfo && hasAccess([UserClaimEnumerations.getApCustomerById]) && { startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserInfoClicked },
    ];

    return (
        <NsModal maxWidth='lg'
            showModal={true}
            closeModal={closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.orders.estimatedPreparationTime")}>

            {isAdminPanel && <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            }
            <div className={clsx(isAdminPanel ? 'background-color' : '', 'px-3 py-2')}>
                <ApBaseLabelInfo info={{ businessDayCalulationInfo: { ...info, printedSide: pdrParameters.TwoSidePrintingTypeId, turnaroundType: '' } }}
                    isLoading={isLoading}
                    isAdminPanel={isAdminPanel}
                    hasAccessInfo={true} />
            </div>
            {!selectedOrder?.id &&
                <Row className='mx-2'>
                    <Col sm={6} md={2} className='my-1 pt-2'>
                        <NsTimePicker valueChanged={(data) => setConfirmationTime(data)}
                            label='product.detail.confirmationTime'
                            value={confirmationTime}
                            margin='normal'
                            className="w-100" />
                    </Col>
                    <Col sm={6} md={2} className='my-1 pt-2'>
                        <NsDatePicker label='product.detail.confirmationDate'
                            selectedDate={confirmationDate}
                            valueChanged={(data) => setConfirmationDate(data)}
                            noValidationDate={true}
                            className='w-100'
                            disableFuture={false}
                            nullAble={true} />
                    </Col>
                </Row>}
            <Row className='p-0 m-0 g-2'>
                <Col md={8} className='p-3'>
                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[Enumerations.skeleton.rect]} />

                        : props?.productDetails?.turnarounds.length > 1 &&
                        <NsTab tabs={ConstantsWithTranslation.getTurnarounds()}
                            value={selectedTabIndex}
                            onChange={tabChanged} />
                    }
                    <EstimatedTable orderBusinessDays={orderBusinessDays} isLoading={isLoading} />
                </Col>
                <Col md={4} className='p-3'>
                    <div className='d-flex flex-column background-color p-3 gap-2'>
                        {isLoading
                            ? <NsSkeleton isVisible={true}
                                variant={[Enumerations.skeleton.rect]} />
                            : !isEmpty(normalBusinessDays) && <>

                                <NsStaticDatePicker normalBusinessDays={normalBusinessDays}
                                    minDate={normalBusinessDays[0]} />
                                {isTwoMonth &&
                                    <NsStaticDatePicker normalBusinessDays={normalBusinessDays}
                                        minDate={normalBusinessDays[normalBusinessDays.length - 1] || null}
                                    />
                                }
                            </>
                        }
                    </div>
                </Col>
            </Row>


            {showUserInfoModal &&
                <ApBaseUserInfoModal showModal={showUserInfoModal}
                    selectedUserId={selectedOrder.userId}
                    closeModal={toggleUserInfoClicked} />
            }

            {messageClassModal}
        </NsModal>
    );
}

function EstimatedTable(props) {
    const { orderBusinessDays, isLoading } = props;

    const mapBusinessDates = () => {
        return orderBusinessDays.map((item, index) => {
            let messageTypeColor = item?.messageClass?.messageItems[0].data == Enumerations.timeSheetDayType.holiday ? 'red-color' : 'text-color';
            let isToday = DateHelper.getDatePart(item?.dayDateTime) == DateHelper.getDatePart(DateHelper.getIsoDateTime(new Date()))
            // console.log(messageTypeColor, isToday);
            return <tr key={index} className={clsx('font-size-14', isToday ? 'fw-bold black-color' : '')}>
                <td className='p-2'><div className={messageTypeColor}>{DateHelper.getDatePart(item?.dayDateTime)}</div></td>
                <td className='p-2'><div className={messageTypeColor}>{DateHelper.getWeekDayName(item?.dayDateTime)}</div></td>
                <td className='px-5 py-2 background-element-color'><div>{item?.businessDayIndex}</div></td>
                <td>{item?.messageClass.messageItems.map((message, index) =>
                    <span key={index} className={clsx('d-flex fw-normal', messageTypeColor)}>
                        {message.data === Enumerations.timeSheetDayType.confirmOrderDay
                            ? <i className='ns-icon-save fs-6 me-1' />
                            : message.data === Enumerations.timeSheetDayType.completionDay
                                ? <i className='ns-icon-circle-confirm success-color fs-6 me-1' />
                                : undefined
                        }
                        {message.messageText}
                    </span>
                )}
                </td>

            </tr>
        })
    }

    return isEmpty(orderBusinessDays)
        ? <NsLabelNoData label={'singlePage.dataNotFound'} />
        : isLoading
            ? <>
                <TableRowSkeleton className='flex-grow-1 ms-2 w-100' />
                <TableRowSkeleton className='flex-grow-1 ms-2 w-100' />
                <TableRowSkeleton className='flex-grow-1 ms-2 w-100' />
            </>
            : <table className='table'>
                <thead>
                    <tr>
                        <th className='col-2'></th>
                        <th></th>
                        <th className='background-element-color rounded-top text-center font-size-14'>{getTranslatedRawText('adminPanel.orders.businessDay')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className='overflow-auto'>
                    {mapBusinessDates()}
                </tbody>
            </table>
}

export default React.memo(ApShareSheetOrderEstimatedTimeModal);