const FestivalTranslationFa = {

    festival: {
        festivals: {
            festivals: 'جشنواره‌ها',
            viewFestival: 'مشاهده جشنواره',
            excDate: 'تاریخ اجرای جشنواره'
        },
        congratulations: 'تبریک',
        sorry: 'متاسفانه',
        sorryDes: "متاسفانه شما در {0} برنده نشدید. ما به زودی جشنواره خرید دیگری خواهیم داشت و شما میتوانید شانس خود را در دوره آینده امتحان کنید.",
        needToSignIn: "برای مشاهده نتیجه و اطلاع از این که آیا نام شما جزء برندگان می‌باشد یا خیر، باید وارد حساب کاربری خود شوید.",
        winnerPrize: "خوشبختانه به اطلاع شما می‌رسانیم",
        in: ' در ',
        theOneOf: 'یکی از برندگان ',
        winnerIs: '  ',
        winningCode: "با کد جایزه ",
        prizesList: 'لیست جوایز',
        viewWinner: "مشاهده برندگان",
        minPurchase: 'حداقل خرید برای کسب امتیاز',
        loginLabel: 'برای محاسبه امتیاز خود وارد سامانه شوید',
        showYourPoint: 'مشاهده امتیاز',
        executingDate: 'تاریخ اجرای قرعه کشی ',
        totalPoint: 'تعداد کل امتیاز کسب شده',
        totalPurchaseAmount: 'میزان کل خرید ',
        purchaseOf: ' ',
        from: 'از خرید ',
        groupSheetJobs: 'فرم‌های عمومی',
        customizedSheetJobs: 'فرم‌های اختصاصی',
        readyProductJobs: 'سفارش‌های آماده',
        online: 'آنلاین',
        inPerson: 'حضوری',
        firstPointMessage: 'هنوز امتیازی برای قرعه کشی دریافت نکرده اید.',
        minAmount: 'حداقل مبلغ خرید برای شرکت در جشنواره {0} است.',
        morePointMessage: 'برای کسب امتیاز بیشتر سفارش‌های خود را افزایش دهید',
        moreChanceMessage: 'حداقل مبلغ خرید برای شرکت در جشنواره، {0} است.',
        points: 'امتیاز',
        youNeedMorePoint: 'با {0} سفارش دیگر، یک امتیاز دریافت کنید.',
        takePartIn: 'شرکت کردن در',
        giftCount: '{0}x',
        festivalAwaitMessage: 'منتظر قرعه‌کشی بصورت {0} در همین صفحه بمانید.',
        liveShow: 'پخش زنده',

        countdown: {
            pointReceivingRemainingTime: "زمان باقی مانده جهت کسب امتیاز",
            seconds: "ثانیه",
            minutes: "دقیقه",
            hours: "ساعت",
            days: "روز",
            eventStartedTime: "تاریخ شروع قرعه کشی",
            eventEndTime: "تاریخ پایان قرعه کشی",
            festivalHasEndedTitle: "زمان قرعه کشی {0} به پایان رسیده است",
            festivalHasEndedDescription: "ما در حال محاسبه امتیاز شما هستیم. به زودی کد قرعه کشی را دریافت خواهید کرد"
        },

        codes: {
            yourCodes: "کدهای قرعه کشی شما",
            searchPlaceHolder: "جستجو در کدها...",
        }
    }
};

export default FestivalTranslationFa;