import React, { useState } from "react";

import ApPaymentAuditImportedFileDetailModal from "../../managements/payments/audits/ApPaymentAuditImportedFileDetailModal";
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import EnumerationTranslationHelper from "../../../../utilities/EnumerationTranslationHelper";
import { GetAuditsService } from "../../../../services/adminPanel/AdminPaymentService";
import { getApAuditColumns } from "../../managements/payments/ApPaymentHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsCard from "../../../../components/cards/NsCard";
import Enumerations from "../../../../configs/Enumerations";
import CommonHelper from "../../../../utilities/CommonHelper";

const ApDauMismatchesGrid = (props) => {

    const { parameters, refreshDate } = props;
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showAuditDetailModal, setShowAuditDetailModal] = useState(false);

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    const getParameters = () => {
        const mismatchIds = [
            Enumerations.paymentAuditMatchingType.amountAndStateTypeMismatch,
            Enumerations.paymentAuditMatchingType.amountMismatch,
            Enumerations.paymentAuditMatchingType.stateTypeMismatch,
        ]
        let params = {
            "Filter.FromSubmitTime": parameters?.submitTimeFrom,
            "Filter.ToSubmitTime": parameters?.submitTimeTo,
        }
        CommonHelper.pushParameterToObject(mismatchIds, "Filter.PaymentAuditMatchingTypes", params);
        return params;
    }

    const auditDetailClicked = (row) => {
        setSelectedAudit(row);
        setShowAuditDetailModal(true);
    }

    const closeApAuditDetailModal = () => setShowAuditDetailModal(false);

    const columns = getApAuditColumns(auditDetailClicked);
    const paymentGatewayType = {
        key: selectedAudit?.paymentGatewayType,
        value: EnumerationTranslationHelper.getPaymentGatewayType(selectedAudit?.paymentGatewayType)
    }
    return (
        <NsCard className='p-3'>
            <div className='fw-bold mb-2'>{getTranslatedRawText('adminPanel.dashboard.mismatch')}</div>

            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={GetAuditsService}
                parameters={getParameters()}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showAuditDetailModal &&
                <ApPaymentAuditImportedFileDetailModal fileSourceId={selectedAudit?.paymentAuditSourceId}
                    auditId={selectedAudit?.id}
                    paymentGatewayType={paymentGatewayType}
                    closeModal={closeApAuditDetailModal} />
            }

        </NsCard>
    );
};

export default React.memo(ApDauMismatchesGrid);