import EnumerationTranslationHelper from "../../../../utilities/EnumerationTranslationHelper";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsButton from "../../../../components/buttons/NsButton";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import Enumerations from "../../../../configs/Enumerations";

export const feedbackTypesColumns = (detailClicked) => [
    hasAccess([UserClaimEnumerations.getApFeedbackTypeBasicInfo]) &&
    {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: 90,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasDetail: hasAccess([UserClaimEnumerations.getApFeedbackTypeBasicInfo]),
            detailClicked: (id) => detailClicked(id),
        }
    },
    {
        field: 'title',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
        width: 330
    },
    {
        field: 'isActive',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
        width: 150,
        cellClass: 'text-center',
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasActivationStatus: true
        }
    },
];

export const feedbackMetricColumns = (editClicked, sortArrowClicked, isLoading, isDemandService) => [
    {
        field: 'isActive', headerName: getTranslatedRawText('adminPanel.common.action'), maxWidth: 115, width: 160,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasEdit: hasAccess([isDemandService ? UserClaimEnumerations.getApFeedbackTypeDemandServiceById : UserClaimEnumerations.getApFeedbackTypeMetricById]),
            editClicked: (id) => editClicked(id),
            customElementRender: (props) => {
                return hasAccess([isDemandService ? UserClaimEnumerations.editApSortFeedbackTypeDemandServices : UserClaimEnumerations.editApSortFeedbackTypeMetric]) &&
                    <div className="d-flex">
                        <NsButton startIcon='ns-icon-expand-down'
                            loading={isLoading}
                            onClick={() => sortArrowClicked(props, false)} />
                        <NsButton startIcon='ns-icon-expand-up'
                            loading={isLoading}
                            onClick={() => sortArrowClicked(props, true)} />
                    </div>
            },
        }
    },
    {
        field: 'title',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
        width: 400
    },
    {
        field: 'isActive',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
        width: 90,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            hasActivationStatus: true
        }
    },
    {
        field: 'createAt',
        headerName: getTranslatedRawText('adminPanel.festivals.createDate'),
        width: 150,
        dataType: Enumerations.gridType.dateTime
    },
    {
        field: 'createBy',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
        width: 150
    },
    {
        field: 'editAt',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
        width: 150,
        dataType: Enumerations.gridType.dateTime
    },
    {
        field: 'editBy',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
        width: 150
    },
];

export const createFeedbackAnnouncementsColumns = (showFeedbackTypeModalClicked) => [
    hasAccess([UserClaimEnumerations.getApFeedbackAnnouncementBasicInfo]) &&
    hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]) &&
    {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: 80,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return < NsButton startIcon='ns-icon-details'
                    className='secondary-text'
                    onClick={() => showFeedbackTypeModalClicked(props)} />
            }
        }
    },
    {
        field: 'fullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 250
    },
    {
        field: 'announcementContentTitle',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.announcementTitle'),
        width: 250,
        cellClass: 'text-start',
    },
    {
        field: 'averageRate',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.averageRate'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => !!props.averageRate ? props.averageRate.toFixed(2) : props.averageRate
        }
    },
    {
        field: 'demandedServiceTypeName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.demandService'),
        width: 150
    },
    {
        field: 'isPublic',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.displaytoAll'),
        width: 150,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => EnumerationTranslationHelper.getYesOrNoStatus(props.isPublic)
        }
    },
    {
        field: 'submitDate',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
        width: 150,
        dataType: Enumerations.gridType.dateTime
    },
];

export const createFeedbackShareSheetOrdersColumns = (showFeedbackTypeModalClicked) => [
    hasAccess([UserClaimEnumerations.getApFeedbackShareSheetOrderBasicInfo]) &&
    hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]) &&
    {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: 80,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return < NsButton startIcon='ns-icon-details'
                    className='secondary-text'
                    onClick={() => showFeedbackTypeModalClicked(props)} />
            }
        }
    },
    {
        field: 'fullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 250
    },
    {
        field: 'productName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
        width: 250,
        cellClass: 'text-start',
    },
    {
        field: 'nasInvoiceId',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber'),
        width: 100,
        dataType: Enumerations.gridType.number
    },
    {
        field: 'averageRate',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.averageRate'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => !!props.averageRate ? props.averageRate.toFixed(2) : props.averageRate
        }
    },
    {
        field: 'demandedServiceTypeName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.demandService'),
        width: 150
    },
    {
        field: 'isPublic',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.displaytoAll'),
        width: 150,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => EnumerationTranslationHelper.getYesOrNoStatus(props.isPublic)
        }
    },
    {
        field: 'submitDate',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
        width: 150,
        dataType: Enumerations.gridType.dateTime
    },
];

export const createFeedbackUserColumns = (showFeedbackTypeModalClicked) => [
    hasAccess([UserClaimEnumerations.getApFeedbackToEmployeeBasicInfo]) &&
    hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory]) &&
    {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: 80,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return < NsButton startIcon='ns-icon-details'
                    className='secondary-text'
                    onClick={() => showFeedbackTypeModalClicked(props)} />
            }
        }
    },
    {
        field: 'fullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.feedbackGiver'),
        width: 150
    },
    {
        field: 'employeeFullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.employee'),
        width: 250
    },
    {
        field: 'employeeDepartments',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.department'),
        width: 250,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return props?.employeeDepartments?.length > 0
                    && props?.employeeDepartments?.map(department => <span>{department}&nbsp;</span>)
            }
        }
    },
    {
        field: 'averageRate',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.averageRate'),
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => !!props.averageRate ? props.averageRate.toFixed(2) : props.averageRate
        }
    },
    {
        field: 'demandedServiceTypeName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.extraSupport'),
        width: 150
    },
    {
        field: 'isPublic',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.displaytoAll'),
        width: 150,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => EnumerationTranslationHelper.getYesOrNoStatus(props.isPublic)
        }
    },
    {
        field: 'submitDate',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
        width: 150,
        dataType: Enumerations.gridType.dateTime
    },
];