import React, { Component } from 'react';
import clsx from 'clsx';
import '../../assets/sass/components/textTruncate/NsTextTruncate.scss'

class NsTextTruncate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numberOfCharactersAfterDots: this.props.numberOfCharactersAfterDots || 11 || '',
            children: this.props.children || '',
            fontSize: this.props.fontSize ? `font-size-${this.props.fontSize}` : 'font-size-16'
        }
    }

    lengthOfSecondPart = (text, numberOfCharactersAfterDots) => {
        return text.length - (this.thiredPart(text, numberOfCharactersAfterDots)?.length + 1)
    }

    firstLetter = (text) => {
        return text.substr(0, 1)
    }

    secondPart = (text) => {
        return text.substr(1, this.lengthOfSecondPart(text, this.state.numberOfCharactersAfterDots))
    }

    thiredPart = (text, numberOfCharactersAfterDots) => {
        if (text.length <= 1) {
            return null
        } else if (numberOfCharactersAfterDots - text.length > text.length || text.length == numberOfCharactersAfterDots) {
            return text.substr(1, Math.min(text.length - 1, numberOfCharactersAfterDots))
        }
        else return text.substr(text.length - numberOfCharactersAfterDots, text.length)


    }
    render() {
        const { numberOfCharactersAfterDots, children } = this.state
        return (
            <ul className={clsx('text-truncate-ul d-flex not-rtl', this.props.textCenter ? ' justify-content-center align-items-center' : '', this.props.className, this.state.fontSize)}>
                <li className={clsx('text-nowrap', this.state.fontSize)}><span>{this.firstLetter(children, numberOfCharactersAfterDots)}</span></li>
                <li className={clsx('text-truncate text-nowrap overflow-hidden', this.state.fontSize)}><span>{this.secondPart(children)}</span></li>
                <li className={clsx('text-pre', this.state.fontSize)}><span>{this.thiredPart(children, numberOfCharactersAfterDots)}</span></li>
            </ul>
        );
    }
}

export default NsTextTruncate;