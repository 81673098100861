import clsx from 'clsx';
import React from 'react';

import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import RegularOrderRouteHelper from '../../bases/RegularOrderRouteHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsCard from '../../../../../components/cards/NsCard';
import Links from '../../../../../configs/links/AllLinks';

class MainStepper extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.refreshFilePreviewSummaryDate !== this.props.refreshFilePreviewSummaryDate)
            this.initialState();
    }

    initialState = () => {
        return (
            {
                routeId: this.props.routeId,
                steppersServices: this.createMainStepperlist(),
                isCompeteledChoosingFileSteps: !!OrderHelper.getIsCompeteledChoosingFileSteps(this.props.routeId),
                isCompeteledExtendedServiceSteps: !!OrderHelper.getIsCompeteledExtendedServiceSteps(this.props.routeId),
                files: OrderHelper.getFiles(this.props.routeId),
                fileSizeAndOrderPrices: OrderHelper.getFileSizeAndOrderPrices(this.props.routeId),
                extendedServices: OrderHelper.getExtendedServices(this.props.routeId),
                printedSide: OrderHelper.getPrintedSide(this.props.routeId).key,
            }
        );
    }

    createMainStepperlist = () => {
        let index = 1;
        let option = (!!OrderHelper.getExtendedServices(this.props.routeId) && OrderHelper.getExtendedServicesLength(this.props.routeId) === 0);
        let mainStepper = [
            {
                sequence: index,
                disable: false,
                active: this.props.mainStepperActiveStep === Enumerations.orderLocation.choosingFile,
                title: getTranslatedRawText('regularOrder.mainStepper.chooseYourFilesTitle'),
                description: getTranslatedRawText('regularOrder.mainStepper.chooseYourFilesDescription'),
                icon: <i className='ns-icon-choose-file h2' />
            }];
        index++;

        if (!option) {
            mainStepper.push(
                {
                    sequence: index,
                    disable: !OrderHelper.getIsCompeteledChoosingFileSteps(this.props.routeId),
                    title: getTranslatedRawText('regularOrder.mainStepper.addExtendedServiceTitle'),
                    active: this.props.mainStepperActiveStep === Enumerations.orderLocation.extendedService,
                    description: getTranslatedRawText('regularOrder.mainStepper.addExtendedServiceDescription'),
                    icon: <i className='ns-icon-product-information h2' />
                }
            );
            index++;
        }

        mainStepper.push(
            {
                sequence: index,
                disable: !option ? !OrderHelper.getIsCompeteledExtendedServiceSteps(this.props.routeId) : !OrderHelper.getIsCompeteledChoosingFileSteps(this.props.routeId),
                title: getTranslatedRawText('regularOrder.mainStepper.confirmDetailsTitle'),
                active: this.props.mainStepperActiveStep === Enumerations.orderLocation.confirmDetail,
                description: getTranslatedRawText('regularOrder.mainStepper.confirmDetailsDescription'),
                icon: <i className='ns-icon-circle-confirm h2' />
            });

        return mainStepper;
    }

    stepperItemClicked = (item) => {
        switch (item) {
            case 0:
                RegularOrderRouteHelper.goTo(this.props.otherProps, Links.view.main.regularOrder.orderDetailChoosingMasterFile);
                return;
            case 1:
                if (!!this.state.isCompeteledChoosingFileSteps)
                    if (OrderHelper.getExtendedServicesLength(this.state.routeId) > 0)
                        RegularOrderRouteHelper.extendedServicesStep(this.state.routeId, this.props.otherProps, OrderHelper.getFirstExtendedService(this.state.routeId).extendedServiceType);
                    else
                        RegularOrderRouteHelper.goTo(this.props.otherProps, Links.view.main.regularOrder.orderDetailConfirm);
                return;
            case 2:
                if (!!this.state.isCompeteledExtendedServiceSteps)
                    RegularOrderRouteHelper.goTo(this.props.otherProps, Links.view.main.regularOrder.orderDetailConfirm);
                return;
            default:
                return;
        }
    }

    mapStepperItems = () => {
        const { extendedServices, steppersServices } = this.state;
        const stepperItems = steppersServices.map((step, index) =>
            <div key={index} onClick={() => this.stepperItemClicked(index)}
                className={clsx({
                    'main-stepper__complete auxiliary ': (!step.disable) && (!step.active),
                    'main-stepper__active auxiliary ': (!step.disable) && (step.active),
                    'main-stepper__disable auxiliary ': step.disable
                },
                    `d-flex align-items-center ${CommonHelper.isMobileDevice() ? (!step.disable) && (step.active) ? isEmpty(extendedServices) ? "col-9" : "col-8" : isEmpty(extendedServices) ? "col-3" : "col-2"
                        :
                        isEmpty(extendedServices) ? "col-6" : 'col-4'}`
                )}>
                <span style={{ 'zIndex': '2' }}>{step.icon}</span>
                {CommonHelper.isMobileDevice() ? (!step.disable) && (step.active)
                    && <span className='ps-2'>{step.title}</span>
                    :
                    <span className='ps-2'>{step.title}</span>
                }
            </div>
        );
        return (
            <NsCard className='mb-2'
                contentClassName="main-stepper">
                {stepperItems}
            </NsCard>
        );
    }

    render() {
        return (this.mapStepperItems());
    }
}

export default MainStepper;