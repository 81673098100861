import React from 'react';

import NsStickyButton from '../../../../../../components/buttons/NsStickyButton';
import NsModal from '../../../../../../components/modals/NsModal';
import NsTypography from '../../../../../../components/text/NsTypography';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import UpBaseGroupSheetOrdersDetailGrid from '../bases/UpBaseGroupSheetOrdersDetailGrid';

class UpGroupSheetCartOrderDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            { label: "common.confirm", onClicked: () => CommonHelper.safeFunctionCall(this.props.confirmClicked, this.props.orderId), className: 'primary', icon: 'ns-icon-confirm' },
            { label: "common.delete", onClicked: () => CommonHelper.safeFunctionCall(this.props.deleteOrderClicked, this.props.orderId), className: 'secondary', icon: 'ns-icon-delete' },
            { label: "common.cancel", onClicked: () => CommonHelper.safeFunctionCall(this.props.closeModal), className: 'primary-outline', icon: 'ns-icon-close' },
        ];
        this.state = {
            orderTitle: '',
        };
    }

    orderTitleChanged = (orderTitle) => this.setState({ orderTitle });

    render() {
        return (
            <NsModal maxWidth="lg"
                dialogTitle={getTranslatedRawText('profile.detail.orderDetail')}
                showModal={true}
                noSpace={true}
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeModal)}
                actions={<NsStickyButton stickyButtons={this.mainButtons} isLoading={this.props.isLoading} />}>

                <div className='p-3'>
                    <div className='mb-3'>
                        <NsTypography className='black-color fw-bold'>
                            {this.state.orderTitle}
                        </NsTypography>
                    </div>

                    <UpBaseGroupSheetOrdersDetailGrid orderId={this.props.orderId}
                        orderTitleChanged={this.orderTitleChanged}
                        isLoading={this.props.isLoading} />

                </div>

            </NsModal>
        );
    }
}

export default UpGroupSheetCartOrderDetailModal;