import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import NsDatePicker from '../../../../../components/dateTimePickers/NsDatePicker';
import NsDragFile from '../../../../../components/dragFiles/NsDragFile';
import NsTextField from '../../../../../components/inputs/NsTextField';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import NsModal from '../../../../../components/modals/NsModal';
import NsTab from '../../../../../components/tabs/NsTab';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import { DeleteDownloadDetailByIdService, EditDownloadDetailService, GetDownloadDetailByIdService, PostDownloadDetailService } from '../../../../../services/adminPanel/AdminDownloadService';
import { DeleteApFileManagerFilesService } from '../../../../../services/adminPanel/AdminFileUploaderService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApBaseUploadFile from '../../../bases/ApBaseUploadFile';
import ApBasesImageContent from "../../bases/ApBasesImageContent";
import ApLanguageTabs from '../../../bases/ApLanguageTabs';
import ContentHelper from '../../bases/ContentHelper';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import ApBasesStandardSizesForUpload from '../../bases/ApBasesStandardSizesForUpload';

const ApDownloadFileUploader = ({ downloadId, selectedFileId, closeModalClicked, ...props }) => {

    const tabs = ConstantsWithTranslation.getAttachFileTabs();
    const [isLoading, setIsLoading] = useState(false);
    const [showValidationDate, setShowValidationDate] = useState(null);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [multiLanguageAttachContent, setMultiLanguageAttachContent] = useState([]);
    const [showDeleteTabDataModal, setShowDeleteTabDataModal] = useState(false);
    const [messageClass, setMessageClass] = useState(<></>);
    const [tempSelectedTabIndex, setTempSelectedTabIndex] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    let fileContent = ContentHelper.getSpecificLanguageContent([...multiLanguageAttachContent], selectedLanguage.id);

    useEffect(() => {
        if ((!isEmpty(selectedFileId) && !isEmpty(downloadId))) {
            setIsLoading(true);
            GetDownloadDetailByIdService(selectedFileId, { downloadId }, getDownloadDetailCallback);
        } else
            setMultiLanguageAttachContent(ContentHelper.normalizeContentObject([], { downloadId, isActive: true }));
    }, []);

    const getDownloadDetailCallback = (resultData, result, messageClass) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguageAttachContent(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive }));
        } setMessageClass(messageClass);
    }

    const saveButtonClicked = () => {
        let tempContent = ContentHelper.removeDownloadAttachFileEmptyContent(multiLanguageAttachContent);
        let isNotValidData = ContentHelper.isValidDownloadAttachFileContent(tempContent);
        if (isEmpty(tempContent) || isNotValidData) {
            setShowValidationDate(new Date());
            return;
        } else {
            setIsLoading(true);
            if (isEmpty(selectedFileId)) {
                PostDownloadDetailService(tempContent, postAndEditDownloadDetailCallback);
            } else EditDownloadDetailService(selectedFileId, tempContent, postAndEditDownloadDetailCallback);
        }
    }

    const postAndEditDownloadDetailCallback = (resultData, result, messageClass) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModalClicked();
        } else setMessageClass(messageClass);
    }

    const tabChanged = (index) => {
        if (isEmpty(fileContent.content?.downloadFileUrl))
            setSelectedTabIndex(index)
        else {
            setTempSelectedTabIndex(index);
            setShowDeleteTabDataModal(true);
        }
    }

    const mainButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary', accessIds: !!selectedFileId ? [UserClaimEnumerations.editApDownloadDetails] : [UserClaimEnumerations.postApDownloadDetails] },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: closeModalClicked, className: 'primary-outline' },
    ]

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, []);

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguageAttachContent([...newData]);
    }

    const deleteTabData = (confirm) => {
        if (confirm) {
            setShowDeleteTabDataModal(false);
            if (selectedTabIndex === 1) {
                setIsLoading(true);
                DeleteApFileManagerFilesService({
                    fileUrl: fileContent.content.downloadFileUrl,
                    fileUploaderCategoryType: Enumerations.fileUploaderCategoryType.adminDownload
                }, deleteFileCallback);
            } else setMultiLanguageAttachContent(multiLanguageAttachContent.map(content => {
                setSelectedTabIndex(tempSelectedTabIndex);
                if (content.languageType === fileContent.languageType) {
                    return {
                        ...fileContent,
                        content: {
                            ...fileContent.content,
                            downloadFileUrl: null,
                            uploadedAttachFileDetail: null,
                            volume: 0,
                        }
                    }
                } else return content;
            }))
        } else setShowDeleteTabDataModal(false);
    }

    const deleteFileCallback = (resultData, result, messageClass) => {
        setShowDeleteTabDataModal(false);
        setIsLoading(false);
        if (!result.data.hasError) {
            setSelectedTabIndex(tempSelectedTabIndex);
            setTempSelectedTabIndex(null);
            setMultiLanguageAttachContent(multiLanguageAttachContent.map(content => {
                if (content.languageType === fileContent.languageType) {
                    return {
                        ...fileContent,
                        content: {
                            ...fileContent.content,
                            downloadFileUrl: null,
                            uploadedAttachFileDetail: null
                        }
                    }
                } else return content;
            }))
        } else setMessageClass(messageClass);
    }

    const deleteFileClicked = () => setShowDeleteModal(true);

    const deleteFile = (confirm) => {
        if (confirm) {
            DeleteDownloadDetailByIdService(selectedFileId, deleteDownloadDetailCallback);
        }
        else setShowDeleteModal(false);
    }

    const deleteDownloadDetailCallback = (resultData, result, messageClass) => {
        if (!result.data.hasError) {
            setShowDeleteModal(false);
            closeModalClicked();
        }
        else setMessageClass(messageClass);
    };

    const headerButtons = [
        (!isEmpty(selectedFileId) && !isEmpty(downloadId)) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deleteFileClicked, accessIds: [UserClaimEnumerations.deleteApDownloadDetails] }
    ]

    return (
        <NsModal maxWidth="sm"
            dialogTitle={getTranslatedRawText('adminPanel.download.attachFile')}
            showModal={true}
            noSpace={true}
            closeModal={closeModalClicked}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>
            <ApBaseMapActionButtons actionButtons={headerButtons} isLoading={isLoading} />
            <div className="p-3">
                <ApLanguageTabs languageChanged={languageChangedHandler}
                    selectedTabId={selectedLanguage?.id} />
                {!isEmpty(multiLanguageAttachContent) && <TabDataTables key={selectedLanguage.id}
                    stateHandler={stateHandler}
                    fileContent={fileContent}
                    serviceCall={() => GetDownloadDetailByIdService(selectedFileId, { downloadId }, getDownloadDetailCallback)}
                    data={[...multiLanguageAttachContent]}
                    showValidationDate={showValidationDate}
                    selectedLanguage={selectedLanguage}
                    tabs={tabs}
                    selectedTabIndex={selectedTabIndex}
                    tabChanged={tabChanged} />}
            </div>

            {showDeleteTabDataModal && !isEmpty(fileContent.content?.downloadFileUrl) &&
                <NsMessageClassModal show={showDeleteTabDataModal}
                    onClose={(confirm) => deleteTabData(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('adminPanel.download.clearByChangingTab'), '')}</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }

            {showDeleteModal &&
                <NsMessageClassModal show={showDeleteModal}
                    onClose={(confirm) => deleteFile(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), '')}</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }

            {messageClass}
        </NsModal>
    )
}

function TabDataTables(props) {
    const { tabs, selectedTabIndex, fileContent, tabChanged, data, selectedLanguage, showValidationDate, stateHandler, serviceCall } = props
    return (
        <Row>
            <div className='d-flex flex-column'>
                <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                    required
                    valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                    value={fileContent?.content.title || null}
                    showValidationDate={showValidationDate}
                    className='w-100' />
                <Row className='my-2'>
                    <Col md={6}>
                        <NsDatePicker label={getTranslatedRawText('adminPanel.download.releaseDate')}
                            selectedDate={new Date(fileContent?.content.releaseDate)}
                            valueChanged={(value) => stateHandler(data, { releaseDate: value }, selectedLanguage.id)}
                            className='w-100' />
                    </Col>
                    <Col md={6}>
                        <NsTextField label={getTranslatedRawText('adminPanel.download.version')}
                            valueChanged={(value) => stateHandler(data, { version: value }, selectedLanguage.id)}
                            value={fileContent?.content.version}
                            className='w-100' />
                    </Col>
                </Row>
            </div>
            <Row className='m-0 p-0 flex-column'>
                <label className='ms-1 font-size-14'>{getTranslatedRawText('adminPanel.standardSizesForUpload.uploadYourFileCover')}</label>
                <Col md={6}>
                    <ApBasesImageContent content={fileContent?.content}
                        isChangeFile={!!fileContent?.content?.fileUrl}
                        fileUploaderCategoryType={Enumerations.fileUploaderCategoryType.adminDownload}
                        stateHandler={(uploadedFileDetail) => stateHandler(data, {
                            attachFileCoverImage: { ...uploadedFileDetail },
                            fileUrl: uploadedFileDetail.fileUrl
                        }, selectedLanguage.id)} />
                    <ApBasesStandardSizesForUpload />
                </Col>
            </Row>
            <NsTab tabs={tabs}
                value={selectedTabIndex}
                onChange={tabChanged}>
            </NsTab>

            {
                tabs[selectedTabIndex].id === Enumerations.attachType.attachLink
                    ? <div className='d-flex flex-column mt-2'>
                        <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.fileUrl')}
                            valueChanged={(value) => stateHandler(data, { downloadFileUrl: value }, selectedLanguage.id)}
                            value={fileContent?.content.downloadFileUrl}
                            className='w-100 mb-2' />

                        <NsTextField label={getTranslatedRawText('adminPanel.customersFiles.fileSize')}
                            valueChanged={(value) => stateHandler(data, { volume: value }, selectedLanguage.id)}
                            value={fileContent?.content.volume || 0}
                            endAdornment="common.kb"
                            className='w-auto' />
                    </div>
                    : <>
                        {isEmpty(fileContent?.content.uploadedAttachFileDetail)
                            ? <NsDragFile getSelectedFile={(file) => stateHandler(data, {
                                uploadedAttachFileDetail: {
                                    file: {
                                        name: file.name || '', size: file.size || 0, id: Math.random(), fileContent: file,
                                    }
                                }
                            }, selectedLanguage.id)}
                                isShowSelectFileButton={true}
                                multiple={true} />
                            : <ApBaseUploadFile value={fileContent?.content.uploadedAttachFileDetail || null}
                                isChangeFile={!!fileContent?.content?.fileUrl}
                                serviceCall={serviceCall}
                                fileUploaderCategoryType={Enumerations.fileUploaderCategoryType.adminDownload}
                                fileUploadHandler={(uploadedAttachFileDetail) => stateHandler(data, { downloadFileUrl: uploadedAttachFileDetail.fileUrl, }, selectedLanguage.id)} />}
                    </>
            }
        </Row >
    )
}

export default React.memo(ApDownloadFileUploader);