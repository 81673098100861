import React from "react";

import { GetBpOrderStateHistoriesService } from "../../../services/businessPartners/BusinessPartnerService";
import NsHistoryWithServiceCall from "../../../components/histories/NsHistoryWithServiceCall";
import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import CommonHelper from "../../../utilities/CommonHelper";
import NsModal from "../../../components/modals/NsModal";

class BpOrdersHistoryModal extends React.Component {

    closeModal = () => CommonHelper.safeFunctionCall(this.props.closeModal);

    render() {

        return (
            <NsModal maxWidth="xs"
                dialogTitle={getTranslatedRawText("profile.actionButton.statusHistory")}
                showModal={true}
                closeModal={this.closeModal}>

                <NsHistoryWithServiceCall service={GetBpOrderStateHistoriesService}
                    parameters={{ invoiceNumber: this.props.invoiceNumber }}
                    noSearchSection={true}
                    typeItems={ConstantsWithTranslation.getAllStatusesOrder()}
                    hasPagination={true} />

            </NsModal>
        )
    }
}

export default BpOrdersHistoryModal;