import React, { Component } from 'react';
import Badge from '@mui/material/Badge';
import '../../assets/sass/components/badges/NsBadge.scss';

class NsBadge extends Component {
    render() {
        const { badgeIcon, badgeNumber, className, color } = this.props;
        return (
            <Badge badgeContent={badgeNumber}
                overlap='circular'
                color={color ?? "secondary"}
                className={className} >
                {badgeIcon}
            </Badge >
        );
    }
}

export default NsBadge;