import React, { useEffect, useState } from 'react';

import NsModal from '../../../components/modals/NsModal';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import NsSwitch from '../../../components/switch/NsSwitch';
import { EditCoachMarksStatusService, GetCoachMarksStatusService } from '../../../services/ProfileSetting/PersonalSettingService';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import CommonHelper from '../../../utilities/CommonHelper';
import StorageHelper from '../../../utilities/StorageHelper';
import Enumerations from '../../../configs/Enumerations';

const CoachMarksStatusModal = ({ closeModal }) => {

    const [isCheckedCoachMarksModal, setIsCheckedCoachMarksModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        setIsLoading(true);
        GetCoachMarksStatusService({}, getCoachMarksStatusCallback);
    }, [])

    const getCoachMarksStatusCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) setIsCheckedCoachMarksModal(resultData.isActive);
    }

    const saveButtonClicked = () => {
        setIsLoading(true);
        EditCoachMarksStatusService({ isActive: isCheckedCoachMarksModal }, editCoachMarksStatusCallback, true);
    }

    const editCoachMarksStatusCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            if (isCheckedCoachMarksModal) {
                StorageHelper.saveInStorage(Enumerations.storage.tours, [])
            }
            CommonHelper.safeFunctionCall(closeModal, messageClassModal);
        } else setResultMessageClass(messageClassModal);
    }

    const coachMarksStatusChanged = (status) => setIsCheckedCoachMarksModal(status);

    const stickyButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary' },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => CommonHelper.safeFunctionCall(closeModal), className: 'primary-outline' },
    ]

    return (
        <NsModal maxWidth="sm"
            dialogTitle={getTranslatedRawText("profile.personal.coachMarksStatus")}
            showModal={true}
            closeModal={closeModal}
            actions={<NsStickyButton stickyButtons={stickyButtons} />}>

            <div className='font-size-14 mb-3'>{getTranslatedRawText('profile.personal.modals.coachMarksAlertDescription')}</div>
            <NsSwitch label='adminPanel.slider.active'
                checked={isCheckedCoachMarksModal}
                onChange={coachMarksStatusChanged} />

            {resultMessageClass}
        </NsModal>
    );
};

export default CoachMarksStatusModal;