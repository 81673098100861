import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import {
    EditBusinessPartnerDetailService, EditCustomerDetailService,
    EditSupporterDetailService, GetBusinessPartnerDetailService,
    GetCustomerDetailService, GetSupporterDetailService, GetUserFieldVisibilityTypeService, PostUserDisableTokenService
} from "../../../../../services/adminPanel/AdminUserService";
import ApBasesStandardSizesForUpload from "../../../contents/bases/ApBasesStandardSizesForUpload";
import NsLoadingProgress from "../../../../../components/circularProgress/NsLoadingProgress";
import NsMessageClassModal from "../../../../../components/modals/NsMessageClassModal";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import ApBasesImageContent from "../../../contents/bases/ApBasesImageContent";
import NsStickyButton from "../../../../../components/buttons/NsStickyButton";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import ApBaseMapActionButtons from "../../../bases/ApBaseMapActionButtons";
import NsAlertModal from "../../../../../components/modals/NsAlertModal";
import NsTypography from "../../../../../components/text/NsTypography";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import CommonHelper from "../../../../../utilities/CommonHelper";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import NsModal from "../../../../../components/modals/NsModal";
import Enumerations from "../../../../../configs/Enumerations";
import ApBaseLabelInfo from "../../../bases/ApBaseLabelInfo";
import DateHelper from "../../../../../utilities/DateHelper";
import ApBaseUserEditContent from "./ApBaseUserEditContent";

const ApBaseUserEditModal = (props) => {

    const { roleType } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [fieldVisibilityType, setFieldVisibilityType] = useState({});
    const [userInfoDate, setUserInfoDate] = useState(null);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [isShowExpireTokenModal, setIsShowExpireTokenModal] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState((<></>));

    useEffect(() => {
        getUserDetails();
    }, []);

    useEffect(() => {
        !isEmpty(userDetails) && getUserFieldVisibilityType(userDetails.accountType);
    }, [userDetails]);

    const getUserDetails = () => {
        setIsLoading(true);
        switch (roleType) {
            case Enumerations.role.businessPartner: GetBusinessPartnerDetailService(props.selectedUserId, getUserDetailCallback);
                break;
            case Enumerations.role.supporter: GetSupporterDetailService(props.selectedUserId, getUserDetailCallback);
                break;
            default: GetCustomerDetailService(props.selectedUserId, getUserDetailCallback);
                break;
        }
    }

    const getUserDetailCallback = (resultData, result, messageClassModal) => {
        if (!result.data?.hasError) {
            setUserDetails(resultData);
        }
        else {
            setIsLoading(false);
            setResultMessageClass(messageClassModal);
        }
    }

    const getUserFieldVisibilityType = (accountType) => {
        setIsLoading(true);
        let parameters = {
            pageType: Enumerations.pageType.userInformationInAdminPanel,
            accountType,
            isOfficialInvoiceEnabled: userDetails.isOfficialInvoiceEnabled
        };
        GetUserFieldVisibilityTypeService(userDetails.userId, parameters, getUserFieldVisibilityTypeCallback);
    }

    const getUserFieldVisibilityTypeCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result?.data?.hasError)
            setFieldVisibilityType(resultData);
        else
            setResultMessageClass(resultMessageClass);
    }

    const getParametersClicked = () => {
        setUserInfoDate(new Date());
    }

    const saveClicked = (editValue) => {
        let parameters = {
            userId: userDetails?.userId,
            mobile: userDetails?.phoneNumber,
            accountStatus: editValue.accountStatus,
            isOfficialInvoiceEnabled: editValue.isOfficialInvoiceEnabled,
            accountType: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.isCompanyVisibilityType, editValue.accountType),
            companyName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType, editValue.companyName),
            licenceNumber: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType, editValue.licenceNumber),
            TRN: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType, isEmpty(editValue.TRN) ? null : editValue.TRN.replace(/_/g, '')),
            genderTypeId: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.genderTypeIdVisibilityType, editValue.genderTypeId),
            firstName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.firstNameVisibilityType, editValue.firstName),
            lastName: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.lastNameVisibilityType, editValue.lastName),
            nationalCode: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.nationalCodeVisibilityType, editValue.nationalCode),
            birthday: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.birthDateVisibilityType, DateHelper.convertDateTimeToDate(new Date(editValue.birthday))),
            email: editValue.email,
            stateId: editValue.stateId,
            cityId: CommonHelper.getNullIfHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.cityIdVisibilityType, editValue.cityId),
            defaultUserAddressId: editValue.defaultUserAddressId,
            agencyId: editValue.agencyId,
            description: editValue.description
        }
        setIsLoading(true);
        switch (roleType) {
            case Enumerations.role.businessPartner: EditBusinessPartnerDetailService(props.selectedUserId, parameters, editUserDetailCallback, true);
                break;
            case Enumerations.role.supporter: EditSupporterDetailService(props.selectedUserId, parameters, editUserDetailCallback, true);
                break;
            default: EditCustomerDetailService(props.selectedUserId, parameters, editUserDetailCallback, true);
                break;
        }
    }

    const editUserDetailCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError) {
            CommonHelper.safeFunctionCall(props.closeUserEditModal);
        }
        CommonHelper.safeFunctionCall(props.getMessageClassModal, messageClassModal);
    }

    const closeModal = () => setIsAlertModalOpen(true);
    const alertConfirmClicked = () => CommonHelper.safeFunctionCall(props.closeModal);
    const alertCancelClicked = () => setIsAlertModalOpen(false);

    const onKeyUp = (e) => {
        e.keyCode == Enumerations.keyboard.enter && getParametersClicked();
        e.keyCode == Enumerations.keyboard.esc && closeModal();
    }

    const mainButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: getParametersClicked, className: 'primary' },
    ];

    const dialogTitle = getTranslatedRawText(roleType === Enumerations.role.supporter
        ? "adminPanel.usersInfo.editSupporterInfo" :
        roleType === Enumerations.role.businessPartner
            ? "adminPanel.usersInfo.editBusinessPartnerInfo"
            : "adminPanel.usersInfo.editCustomerInfo");

    const expireTokenClicked = () => setIsShowExpireTokenModal(true);

    const confirmExpireToken = (confirm) => {
        confirm
            ? PostUserDisableTokenService(userDetails?.userId, confirmExpireTokenCallback, true)
            : setIsShowExpireTokenModal(false);
    }

    const confirmExpireTokenCallback = (resultData, result, messageClassModal) => {
        setIsShowExpireTokenModal(false);
        setResultMessageClass(messageClassModal);
    }

    const headerButtons = [
        hasAccess([UserClaimEnumerations.updateApCustomerToken]) &&
        { wrapperClassName: '', startIcon: 'ns-icon-disable-user', label: 'adminPanel.usersInfo.expireToken', onClick: expireTokenClicked }
    ];

    const stateHandler = (uploadedFileDetail) => {
        setUserDetails((prevState) => ({
            ...prevState,
            publicProfileUrl: uploadedFileDetail.fileUrl
        }));
    };

    const imageDeleted = (wasDeletedSuccessfuly) => {
        !!wasDeletedSuccessfuly && setUserDetails((prevState) => ({
            ...prevState,
            publicProfileUrl: ''
        }));
    }

    const userProfileImage = { fileUrl: userDetails?.publicProfileUrl, id: userDetails?.userId };
    return (
        <NsModal maxWidth='lg'
            showModal={props.showModal}
            closeModal={() => closeModal()}
            noSpace={true}
            onKeyUp={onKeyUp}
            dialogTitle={dialogTitle}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />} >

            <ApBaseMapActionButtons actionButtons={headerButtons} />

            <NsLoadingProgress visible={isLoading} />

            <div className='background-color px-3 py-2'>
                <ApBaseLabelInfo info={userDetails}
                    hasAccessInfo={hasAccess([UserClaimEnumerations.getApCustomerById])}
                    isEditMode={true} />
            </div>
            <Row className="mx-1 overflow-hidden">
                <Col md={3} sm={12}>
                    <div className="mt-2 pt-1">
                        <NsTypography variant='inherit' className='fw-bold'>{getTranslatedRawText('adminPanel.usersInfo.profilePicture')}</NsTypography>
                    </div>
                    <ApBasesImageContent
                        content={userProfileImage}
                        isChangeFile={userDetails?.publicProfileUrl}
                        userId={userDetails?.userId}
                        fileUploaderCategoryType={Enumerations.fileUploaderCategoryType.userProfileImage}
                        stateHandler={stateHandler}
                        serviceCall={imageDeleted}
                    />
                    <ApBasesStandardSizesForUpload />
                </Col>
                <Col>
                    <ApBaseUserEditContent userDetails={userDetails}
                        fieldVisibilityType={fieldVisibilityType}
                        accountTypechange={getUserFieldVisibilityType}
                        userInfoDate={userInfoDate}
                        setNewUserInfo={saveClicked} />
                </Col>
            </Row>


            {isAlertModalOpen &&
                <NsAlertModal isOpen={isAlertModalOpen}
                    activeEnter={true}
                    cancelClicked={alertCancelClicked}
                    confirmClicked={alertConfirmClicked} >
                    {getTranslatedRawText('adminPanel.common.closeModalAlert')}
                </NsAlertModal>}

            {isShowExpireTokenModal && <NsMessageClassModal show={true}
                onClose={confirmExpireToken}
                messageItems={[{
                    messageText: (
                        <>
                            <p className='text-justify pe-4 pt-4'>{getTranslatedRawText('adminPanel.usersInfo.areYouSureToExpireTokenP1')}</p>
                            <p className='text-justify pe-4'>{getTranslatedRawText('adminPanel.usersInfo.areYouSureToExpireTokenP2')}</p>
                        </>),
                    messageType: Enumerations.messageType.warning
                }]} />
            }

            {resultMessageClass}
        </NsModal>
    );
}

export default React.memo(ApBaseUserEditModal);