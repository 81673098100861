import React from "react";
import NsModal from "../../../components/modals/NsModal";
import UserClaimEnumerations from "../../../configs/UserClaimEnumerations";
import { GetAgencyDetailService } from "../../../services/ProfileSetting/PersonalSettingService";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import ApBaseMapActionButtons from "../../adminPanel/bases/ApBaseMapActionButtons";
import ApAgencyProductsModal from "../../adminPanel/managements/agency/ApAgencyProductsModal";
import UpBaseProfileAgencyInfo from "../contents/userProfile/bases/UpBaseProfileAgencyInfo";

class AgencyInfoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agency: [],
            isLoading: true,
            showAgencyProductsModal: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => {
        !this.props.isInAdminPanel
            ? GetAgencyDetailService(this.props.agencyId, this.getAgencyDetailCallback)
            : GetAgencyDetailService(this.props.agencyId, this.getAgencyDetailCallback)
    }

    getAgencyDetailCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError)
            this.setState({ agency: resultData });
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    agencyProductsClicked = () => this.setState({ showAgencyProductsModal: true })

    closeModalClicked = () => this.setState({ showAgencyProductsModal: false })

    render() {
        const { agency, resultMessageClass, showAgencyProductsModal } = this.state;
        const headerButtons = [{ label: 'adminPanel.agency.productPriceChange', onClick: this.agencyProductsClicked, accessIds: [UserClaimEnumerations.getApProductsOfAgency] },];
        return <NsModal maxWidth='md'
            showModal={true}
            noSpace={true}
            closeModal={this.props.closeModal}
            dialogTitle={getTranslatedRawText('adminPanel.agency.agencyInfo')}>

            {this.props?.isInAdminPanel && <ApBaseMapActionButtons actionButtons={headerButtons} />}

            <div className="m-3">
                <UpBaseProfileAgencyInfo option={agency}
                    radioButtonClicked={this.radioButtonClicked}
                    isSelectableAgency={false} />
            </div>

            {showAgencyProductsModal &&
                <ApAgencyProductsModal closeModal={this.closeModalClicked}
                    agencyId={this.props.agencyId} />}


            {resultMessageClass}
        </NsModal>
    }
}

export default AgencyInfoModal;