import React, { Component } from 'react'
import { getTranslatedRawText } from '../../utilities/CultureHelper'
import clsx from 'clsx'
import NsButton from '../buttons/NsButton'
import '../../assets/sass/components/textExtender/NsTextExtender.scss'
import NsTypography from '../text/NsTypography'

class NsTextExtender extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isViewMore: false,
        }
    }

    detailMoreClicked = () => this.setState({ isViewMore: !this.state.isViewMore })

    render() {

        const { isViewMore } = this.state;
        const { text, className, textClassName, viewMoreClassName, hasViewMore, viewLessClassName, viewMoreLabel, viewLessLabel, disabled } = this.props;

        return (
            <>
                {!!text && <div className={clsx('d-flex', className)}>
                    <NsTypography variant='inherit' className={clsx("text-extender", textClassName, isViewMore || !hasViewMore && 'view-less', isViewMore && viewMoreClassName, !isViewMore && viewLessClassName)}>
                        {text}
                    </NsTypography>
                    {!!hasViewMore &&
                        <NsButton label={isViewMore
                            ? viewMoreLabel || getTranslatedRawText('regularOrder.confirmDetail.viewLess')
                            : viewLessLabel || getTranslatedRawText('regularOrder.confirmDetail.viewMore')}
                            wrapperClassName='ms-2'
                            size='small'
                            disabled={disabled}
                            className='primary-text text-nowrap'
                            onClick={this.detailMoreClicked} />
                    }
                </div>}
            </>
        )
    }
}
export default NsTextExtender