import clsx from 'clsx';
import React from 'react';

import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsCard from '../../../../../components/cards/NsCard';

const ApPaymentAuditDetailStepper = (props) => {
    const { activeState } = props;

    const createMainStepperlist = () => {
        let mainStepper = [
            {
                sequence: Enumerations.auditDetailStepper.attempts,
                disable: false,
                active: activeState === Enumerations.auditDetailStepper.attempts,
                title: getTranslatedRawText('adminPanel.audit.attempts'),
                icon: <i className='ns-icon-audit-attempts fs-2' />
            },
            {
                sequence: Enumerations.auditDetailStepper.history,
                disable: activeState < Enumerations.auditDetailStepper.history,
                title: getTranslatedRawText('adminPanel.audit.history'),
                active: activeState === Enumerations.auditDetailStepper.history,
                icon: <i className='ns-icon-audit-histories fs-2' />
            },
            {
                sequence: Enumerations.auditDetailStepper.details,
                disable: activeState < Enumerations.auditDetailStepper.details,
                title: getTranslatedRawText('adminPanel.audit.details'),
                active: activeState === Enumerations.auditDetailStepper.details,
                icon: <i className='ns-icon-audit-details fs-2' />
            }];

        return mainStepper;
    }

    const stepperItemClicked = (step) => !step.disable && CommonHelper.safeFunctionCall(props.updateActiveState, step.sequence);

    const mapStepperItems = (steppersServices) => {
        const stepperItems = steppersServices.map((step, index) =>
            <div key={index} onClick={() => stepperItemClicked(step)}
                className={clsx({
                    'main-stepper__complete auxiliary ': (!step.disable) && (!step.active),
                    'main-stepper__active auxiliary ': (!step.disable) && (step.active),
                    'main-stepper__disable auxiliary ': step.disable
                },
                    `d-flex align-items-center ${CommonHelper.isMobileDevice() ? (!step.disable) && (step.active) ? "col-8" : "col-2"
                        : 'col-4'}`
                )}>
                <span style={{ 'zIndex': '2' }}>{step.icon}</span>
                {CommonHelper.isMobileDevice() ? (!step.disable) && (step.active)
                    && <span className='ps-2'>{step.title}</span>
                    :
                    <span className='ps-2'>{step.title}</span>
                }
            </div>
        );
        return (
            <NsCard className='mb-2'
                contentClassName="main-stepper">
                {stepperItems}
            </NsCard>
        );
    }

    const steppersServices = createMainStepperlist();
    return (mapStepperItems(steppersServices));

}

export default ApPaymentAuditDetailStepper;