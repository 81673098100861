import React from 'react';

import NsModal from '../../../../components/modals/NsModal';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { CashBackContent } from '../../../profileSettings/contents/userDashboard/cashback/UpDashboardCashback';

const CashbackDetailModal = ({ showCashbackDetailModal, closeModalClicked, detailLedgerId, userId, roleType }) => {
    return (
        <NsModal maxWidth="lg"
            dialogTitle={getTranslatedRawText('adminPanel.dashboard.cashbackDetail')}
            showModal={showCashbackDetailModal}
            closeModal={closeModalClicked}
            noSpace={true} >

            <CashBackContent detailLedgerId={detailLedgerId}
                userId={userId}
                isUsedInAdminPanel={true} />
        </NsModal>
    )
}

export default CashbackDetailModal;