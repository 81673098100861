import React, { useState, useEffect } from 'react';

import { GetProductsCirculationsService } from '../../../../services/product/ProductService';
import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../bases/ApBaseSearchButtons';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';

const ApAgencyProductsSearch = (props) => {

    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState(null);

    useEffect(() => {
        GetProductsCirculationsService(null, getProductsCirculationsCallback);
    }, [])

    const getProductsCirculationsCallback = (resultData, result) => {
        if (!result?.data?.hasError) {
            setProducts(resultData);
        }
    };

    const searchFields = [
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
            fieldName: 'product',
            value: product,
            onChange: (value) => setProduct(value),
            items: products,
        }
    ]

    const searchValues = { product };

    const searchClicked = () => CommonHelper.safeFunctionCall(props.searchClicked, product);

    const clearButtonClicked = () => {
        setProduct(null);
        CommonHelper.safeFunctionCall(props.searchClicked, {});
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            searchClicked();
    }

    return (
        <div onKeyDown={handleKeyDown}>
            <NsAdvanceSearch searchOptions={searchFields}
                searchValues={searchValues} mdCol={4} smCol={6} />
            <ApBaseSearchButtons searchClicked={searchClicked}
                isLoading={props.isLoading}
                clearButtonClicked={clearButtonClicked} />
        </div>
    );
};

export default React.memo(ApAgencyProductsSearch);