import clsx from 'clsx';
import React from 'react';
import Rating from '@mui/material/Rating';
import CommonHelper from '../../utilities/CommonHelper';
import '../../assets/sass/components/ratings/NsRating.scss';

class NsRating extends React.Component {

    onChange = (e, newValue) => CommonHelper.safeFunctionCall(this.props.onChange, newValue);
    render() {
        const { className, value, size, readOnly, precision } = this.props;
        return <Rating className={clsx('rating', className)}
            name='NsRating'
            value={value}
            precision={precision || 1}
            size={size}
            readOnly={readOnly}
            onChange={this.onChange} />
    }
}

export default NsRating;