import React, { useEffect, useState } from 'react';

import { GetFeedbackEmployeeBasicInfoService, GetFeedbackEmployeesService } from '../../../../../services/adminPanel/AdminFeedbackService';
import { GetSupporterBasicInfoByIdService } from '../../../../../services/adminPanel/AdminUserService';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import ApBaseFeedbackDetailModal from '../../../feedbacks/bases/ApBaseFeedbackDetailModal';
import { createFeedbackUserColumns } from '../../../feedbacks/bases/ApBasesFeedbackHelper';
import ApBaseUserInfoModal from '../../../managements/users/bases/ApBaseUserInfoModal';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import NsTab from '../../../../../components/tabs/NsTab';

const ApDfbEmployeeFeedbacksModal = (props) => {
    const [supporterBasicInfo, setSupporterBasicInfo] = useState(null);
    const [selectedTab, setSelectedTab] = useState(ConstantsWithTranslation.getFeedbackstatus()[0]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedFeedback, setSelectedFeedback] = useState();
    const [callServiceDate, setCallServiceDate] = useState(new Date());
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [showFeedbackTypeDetailModal, setShowFeedbackTypeDetailModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState(<></>);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        hasAccess([UserClaimEnumerations.getApSupporterBasicInfoById]) && getSupporterBasicInfo();
    }, [])

    const getSupporterBasicInfo = () => {
        GetSupporterBasicInfoByIdService(props?.selectedUserId, getApSupporterBasicInfoByIdCallback);
    }

    const getApSupporterBasicInfoByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setSupporterBasicInfo(resultData);
        } else
            setMessageClassModal(messageClassModal);
    }

    const tabChanged = (selectedTabIndex, selectedTab) => {
        setSelectedTabIndex(selectedTabIndex);
        setSelectedTab(selectedTab);
        setCallServiceDate(new Date());
    };

    const showFeedbackTypeDetailModalClicked = (feedback) => {
        setSelectedFeedback(feedback);
        setShowFeedbackTypeDetailModal(true);
    }

    const closeFeedbackTypeModalClicked = () => setShowFeedbackTypeDetailModal(false);

    const toggleUserInfoModal = () => setShowUserInfoModal(!showUserInfoModal)

    const getParameters = {
        "Filter.EmployeeIds": props?.selectedUserId,
        "Filter.UserFeedbackStateTypeId": selectedTab?.id,
    };

    const headerButtons = [
        hasAccess([UserClaimEnumerations.getApSupporterById]) && { wrapperClassName: 'ms-1', startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserInfoModal },
    ];

    const getData = (data, isLoading) => setIsLoading(isLoading);

    const { closeModal, selectedUserId } = props;

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText('adminPanel.dashboard.feedbackDetails')}
            showModal={true}
            noSpace
            closeModal={closeModal}>
            <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            <div className='background-color p-2 my-2'>
                <ApBaseLabelInfo info={supporterBasicInfo}
                    hasAccessInfo={true}
                    isLoading={isLoading} />
            </div>
            <div className='p-3'>
                <NsTab tabs={ConstantsWithTranslation.getFeedbackstatus()}
                    value={selectedTabIndex}
                    onChange={tabChanged}>
                </NsTab>

                <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                    service={GetFeedbackEmployeesService}
                    parameters={getParameters}
                    columnDefs={createFeedbackUserColumns(showFeedbackTypeDetailModalClicked)}
                    hasPagination={true}
                    getData={getData} />
                {showFeedbackTypeDetailModal &&
                    <ApBaseFeedbackDetailModal selectedFeedback={{ ...selectedFeedback, feedbackType: Enumerations.feedbackTypes.user }}
                        getFeedbackDtoService={GetFeedbackEmployeeBasicInfoService}
                        hasAccessInfo={hasAccess([UserClaimEnumerations.getApFeedbackToEmployeeBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory])}
                        closeModal={closeFeedbackTypeModalClicked}
                        updateFeedbackOrdersGrid={() => setCallServiceDate(new Date())} />
                }
                {showUserInfoModal &&
                    <ApBaseUserInfoModal showModal={true}
                        selectedUserId={selectedUserId}
                        roleType={Enumerations.role.supporter}
                        closeModal={toggleUserInfoModal} />
                }
                {messageClassModal}
            </div>
        </NsModal>
    );
};

export default React.memo(ApDfbEmployeeFeedbacksModal);
