import clsx from "clsx";
import React from "react";

import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import '../../../assets/sass/views/store/Store.scss'
import NsCarouselSwiper from "../../../components/carousel/NsCarouselSwiper";
import { isEmpty } from "../../../utilities/ValidationHelper";
import { GetProductsService } from "../../../services/product/ProductService";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import Enumerations from "../../../configs/Enumerations";

class PdBaseSimilarProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            similarProducts: [],
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.getSimilarProducts();

    componentDidUpdate = (prevProps) => {
        if (!!this.props.getSimilarProductsDate && (this.props.getSimilarProductsDate !== prevProps.getSimilarProductsDate))
            this.getSimilarProducts();
        if (prevProps.selectedCurrency !== this.props.selectedCurrency)
            this.getSimilarProducts();
    }

    getSimilarProducts = () => {
        this.setState({ isLoading: true });
        GetProductsService({ 'Filter.CategoryIds': this.props.categoryIds[0] }, this.getProductsCallback);
    }

    getProductsCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ similarProducts: resultData.dataItems });
        else
            this.setState({ resultMessageClass: messageClassModal });

    }

    render() {
        const { isLoading, similarProducts } = this.state;
        const { showTitle, reloadPageAfterClickingProduct } = this.props;

        return <>
            {!!showTitle &&
                <span className={clsx('d-inline-block border_bottom_red fw-bold font-size-12 p-2', className)}>{getTranslatedRawText('product.store.similarProducts')}</span>
            }
            {
                isLoading
                    ? <NsSkeleton isVisible={true}
                        variant={[Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect]} />
                    : !isEmpty(similarProducts)
                        ? <NsCarouselSwiper products={similarProducts}
                            reloadPageAfterClickingProduct={reloadPageAfterClickingProduct} />
                        : null

            }
        </>
    }
}

export default PdBaseSimilarProducts;