import React, { Component } from 'react';

import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { GetAgencyProductsService } from '../../../../services/ProfileSetting/AgencyService';
import { getTranslatedRawText, isEnCulture } from '../../../../utilities/CultureHelper';
import UpBaseAgencySearchOptions from './bases/UpBaseAgencySearchOptions';
import UserPanelWrapper from '../../UserPanelWrapper';
import Enumerations from '../../../../configs/Enumerations';

class UpAgencyShipping extends Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                field: 'productName',
                headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
                width: 300,
            },
            {
                field: 'companyPriceChangePercent',
                headerName: getTranslatedRawText('profile.dataGridColumns.priceChange'),
                dataType: Enumerations.gridType.price,
                width: 200,
            },
            {
                field: 'agancyDiscountPercent',
                headerName: getTranslatedRawText('profile.dataGridColumns.agencyDiscount'),
                dataType: Enumerations.gridType.price,
                width: 200,
            },
        ]
        this.state = {
            isLoading: false,
            searchButtonTimeStamp: null,
            searchTerm: null,
            callServiceDate: null,
        }
    }

    searchFormClicked = (parameters) => this.setState({ searchTerm: parameters.searchTerm, callServiceDate: new Date() });

    getData = (rows, isLoading) => this.setState({ isLoading });

    render() {
        const { isLoading, callServiceDate, searchTerm } = this.state;

        return (
            <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.agencyShipping')}
                titleIcon='ns-icon-agency-shipping'>

                ...Comming soon


                {/* <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                    // service={GetAgencyProductsService}
                    parameters={{
                        productName: searchTerm
                    }}
                    getData={this.getData}
                    columnDefs={this.columns}
                    hasPagination={true} /> */}

            </UserPanelWrapper>
        )
    }
}

export default UpAgencyShipping;