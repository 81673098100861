import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { GetShippingMethodService } from '../../../../../services/ProfileSetting/DeliveryAddressSettingService';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../utilities/CultureHelper';
import UpBaseShippingEstimatedTimeSheet from '../bases/UpBaseShippingEstimatedTimeSheet';
import ApBaseLabelInfo from '../../../../adminPanel/bases/ApBaseLabelInfo';
import NsTypography from '../../../../../components/text/NsTypography';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import { changeAddressAndTelProperty } from '../UpShippings';
import Links from '../../../../../configs/links/AllLinks';

class UpShippingMethodInformationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shippingMethodInformation: null,
            isLoading: false,
            messageClassModal: <></>,
        }
    }

    componentDidMount = () => this.getShippingMethodInformation();

    getShippingMethodInformation = () => {
        const { selectedShippingMethod } = this.props;

        let parameters = { shippingMethodTypeId: selectedShippingMethod?.shippingMethodTypeId }
        GetShippingMethodService(parameters, this.getShippingMethodInformationCallback);
    }

    getShippingMethodInformationCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            var reformatResultData = changeAddressAndTelProperty({ ...resultData });
            this.setState({ shippingMethodInformation: reformatResultData });
        }
        else
            this.setState({ messageClassModal });
    }

    shippingMethodInformationHandler = () => {
        const { shippingMethodInformation } = this.state;

        switch (shippingMethodInformation?.shippingMethodCategoryTypeId) {
            case Enumerations.shippingMethodCategoryType.none: return;
            case Enumerations.shippingMethodCategoryType.inPersonDelivery: return getTranslatedRawText('profile.delivery.inPersonMethodInformation');
            case Enumerations.shippingMethodCategoryType.cargo: return getTranslatedRawText('profile.delivery.selectedCarrierMethodInformation');
            case Enumerations.shippingMethodCategoryType.doorToDoor: return getTranslatedRawText('profile.delivery.tipaxMethodInformation');
            default: return;
        }
    }

    render() {
        const { isLoading, messageClassModal, shippingMethodInformation } = this.state;
        const { closeModal, selectedShippingMethod, selectedShippingPlan, shippingRequestTime } = this.props;

        return (
            <NsModal
                dialogTitle={CommonHelper.stringFormat(getTranslatedRawText('profile.delivery.about'), shippingMethodInformation?.name)}
                showModal={true}
                maxWidth='md'
                closeModal={closeModal}
                noSpace={true}>
                <div className='d-flex justify-content-center p-2'>
                    <UpBaseShippingEstimatedTimeSheet selectedShippingPlan={selectedShippingPlan}
                        showWeekDayName={true}
                        shippingRequestTime={shippingRequestTime}
                        selectedShippingMethod={selectedShippingMethod} />
                </div>
                <div className='p-3 background-color'>
                    <ApBaseLabelInfo info={{ shippingInfoDto: shippingMethodInformation }}
                        isLoading={isLoading}
                        hasAccessInfo />
                </div>
                <div className='px-2 pt-2 pb-3'>
                    <NsTypography variant='inherit' className='font-size-14 p-3'>
                        {this.shippingMethodInformationHandler()}
                    </NsTypography>
                    {!isEmpty(shippingMethodInformation?.description) &&
                        <NsTypography variant='inherit' className='font-size-14 p-3'>
                            {shippingMethodInformation?.description}
                        </NsTypography>}
                    <NsTypography variant='inherit' className='font-size-14 px-3 py-2'>
                        {getTranslatedRawText("profile.readyToDeliver.readyToDeliveryDescription")}
                    </NsTypography>

                    {shippingMethodInformation?.shippingMethodCategoryTypeId === Enumerations.shippingMethodCategoryType.inPersonDelivery &&
                        <div className='d-flex justify-content-between p-3'>
                            <NsTypography variant='inherit' className='font-size-12'>
                                {getTranslatedRawText('profile.delivery.addressAndPhone')}
                            </NsTypography>
                            <a className='btn btn-sm secondary-text border border-secondary w-auto'
                                target='_blank'
                                href={getUrlWithCurrentCulture(Links, Links.contactUs) + '?' + CommonHelper.makeCorrectLink(getTranslatedRawText('singlePage.contact.common.countyWarehouse'))} >
                                {getTranslatedRawText('profile.delivery.countyWarehouse')}
                            </a>
                        </div>
                    }
                    {shippingMethodInformation?.shippingMethodCategoryTypeId === Enumerations.shippingMethodCategoryType.doorToDoor &&
                        <a href='https://tipaxco.com/branches/standardpoint' target='_blank'
                            className='d-flex justify-content-between p-3'>
                            <NsTypography variant='inherit' className='font-size-12 cursor-pointer'>
                                {getTranslatedRawText('profile.delivery.tipaxAgencies')}
                            </NsTypography>
                        </a>
                    }
                </div>
                {messageClassModal}
            </NsModal>
        )
    }
}

export default withRouter(UpShippingMethodInformationModal);