import React from "react";

import EnumerationTranslationHelper from "./EnumerationTranslationHelper";
import UserClaimEnumerations from "../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "./CultureHelper";
import { isEmpty } from "./ValidationHelper";
import CommonHelper from "./CommonHelper";
import { hasAccess } from "./ClaimHelper";
import DateHelper from "./DateHelper";
import ConstantsWithTranslation from "../configs/ConstantsWithTranslation";


export const getUserLabelInfos = (info) => {

}

export const getOrderLabelInfos = (info, agreementShowDetailClicked) => {
    if (!isEmpty(info)) {
        const { circulation, orderApplicationType, lastStatusTime, lastStatusType, nasInvoiceId, paymentInAdvance, priceInclusiveOfVat, productName, shipmentNumber, discountAmount,
            submitTime, userAgreement, serverName, totalPrice, totalQuantity, businessPartnerCustomerIdentifier, turnaroundType, twoSidePrintingType, userFullName, mobile, confirmTime,
            paymentType, nasPaymentType, detailLedgerId, estimateReadyToDeliverTime, serverNameFileRegulator, category, title } = info?.orderItemInfoDto;

        return {
            title: title,
            userFullName: userFullName,
            mobile: mobile,
            productName: productName,
            nasInvoiceId: nasInvoiceId,
            lastStatusType: ConstantsWithTranslation.getSharedSheetOrderStateType(lastStatusType),
            orderApplicationType: ConstantsWithTranslation.getOrderApplicationTypeById(orderApplicationType),
            estimateReadyToDeliverTime: DateHelper.getDateAndTime(estimateReadyToDeliverTime),
            businessPartnerCustomerIdentifier: businessPartnerCustomerIdentifier,
            lastStatusTime: DateHelper.getDateAndTime(lastStatusTime),
            submitTime: DateHelper.getDateAndTime(submitTime),
            confirmTime: DateHelper.getDateAndTime(confirmTime),
            nasPaymentType: EnumerationTranslationHelper.getModeOfPayment(nasPaymentType),
            paymentType: EnumerationTranslationHelper.getPaymentTypeText(paymentType),
            twoSidePrintingType: EnumerationTranslationHelper.getPrintedSideText(twoSidePrintingType),
            turnaroundType: EnumerationTranslationHelper.getTurnaroundText(turnaroundType),
            circulation: `${circulation} ${getTranslatedRawText('product.common.pieces')}`,
            totalQuantity: `${totalQuantity} ${getTranslatedRawText('product.common.piece')}`,
            totalPrice: CommonHelper.currencyFormatByDefaultConfig(totalPrice),
            priceInclusiveOfVat: CommonHelper.currencyFormatByDefaultConfig(priceInclusiveOfVat),
            discountAmount: CommonHelper.currencyFormatByDefaultConfig(discountAmount),
            paymentInAdvance: CommonHelper.currencyFormatByDefaultConfig(paymentInAdvance),
            shipmentNumber: shipmentNumber,
            serverName: serverName,
            userAgreement: <span onClick={() => CommonHelper.safeFunctionCall(agreementShowDetailClicked, userAgreement.id)} >{userAgreement.title}
                {hasAccess([UserClaimEnumerations.getApOrderUserAggrementDetail]) && <i className='ns-icon-expand-right' />}
            </span>,
            detailLedgerId: detailLedgerId,
            serverNameFileRegulator: serverNameFileRegulator,
            category: category
        }
    } else
        return {};
}

const getStoreOrderLabelInfo = (info, agreementShowDetailClicked) => {
    if (!isEmpty(storeOrderItemInfoDto)) {
        const { fullName, invoiceNumber, paymentTypeId, priceIncludeVat, lastStatusTime, lastStatusType, priceExcludeVat, discount, activeAgreementId, activeAgreementName,
            paymentInAdvance, submitTime, cancellationCode } = info?.storeOrderItemInfoDto;
        return {
            fullName: fullName,
            invoiceNumber: invoiceNumber,
            lastStatus: ConstantsWithTranslation.getSharedSheetOrderStateType(lastStatusType),
            statusDate: DateHelper.getDateAndTime(lastStatusTime),
            submitDate: DateHelper.getDateAndTime(submitTime),
            TermsOfPayment: EnumerationTranslationHelper.getModeOfPayment(paymentTypeId),
            priceExcVat: CommonHelper.currencyFormatByDefaultConfig(priceExcludeVat),
            priceIncVat: CommonHelper.currencyFormatByDefaultConfig(priceIncludeVat),
            discount: CommonHelper.currencyFormatByDefaultConfig(discount),
            paymentInAdvance: CommonHelper.currencyFormatByDefaultConfig(paymentInAdvance),
            activeAgreement: <span onClick={() => CommonHelper.safeFunctionCall(agreementShowDetailClicked, activeAgreementId)}>{activeAgreementName}
                {hasAccess([UserClaimEnumerations.getApOrderUserAggrementDetail]) && <i className='ns-icon-expand-right' />}
            </span>,
            cancellationCode: cancellationCode
        }
    } else
        return {}
}

// const setPaymentItemInfo = (paymentItemInfoDto) => {
//     if (!isEmpty(paymentItemInfoDto)) {
//         const { amount, lastStatusTime, lastStatusType, trackNumber, cardNumber, payGateTranId, traceNumber, submitTime,
//             userFullName, phoneNumber, paymentType, paymentGateway, refrenceNumber, transactionStatusMessage } = paymentItemInfoDto;
//         return (
//             <>
//                 {!!lastStatusType && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.lastStatus')}
//                     value={EnumerationTranslationHelper.getPaymentStatusTypeText(lastStatusType)} />
//                 }
//                 {!!lastStatusTime && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.statusDate')}
//                     value={DateHelper.getDateAndTime(lastStatusTime)} />
//                 }
//                 {!!submitTime && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.submitDate')}
//                     value={DateHelper.getDateAndTime(submitTime)} />
//                 }
//                 {!!userFullName && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
//                     value={userFullName}
//                     hasTooltip={true} />
//                 }
//                 {!!amount && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.amount')}
//                     value={CommonHelper.currencyFormatByDefaultConfig(amount)} />
//                 }
//                 {!!trackNumber && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.trackNumber')}
//                     value={trackNumber} />
//                 }
//                 {!!refrenceNumber && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.referenceNumber')}
//                     value={refrenceNumber} />
//                 }
//                 {!!paymentGateway && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.paymentGateway')}
//                     value={paymentGateway} />
//                 }
//                 {!!paymentType && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.paymentType')}
//                     value={EnumerationTranslationHelper.getPaymentTypeText(paymentType)} />
//                 }
//                 {!!traceNumber && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.traceNumber')}
//                     value={traceNumber} />
//                 }
//                 {!!cardNumber && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.cardNumber')}
//                     value={cardNumber} />
//                 }
//                 {!!payGateTranId && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.payGateTranId')}
//                     value={payGateTranId} />
//                 }
//                 {!isEmpty(transactionStatusMessage) && <NsLabelBox className='background-white-color min-size'
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.transactionStatusMessage')}
//                     value={transactionStatusMessage} />
//                 }
//             </>
//         );
//     }
// }

// const setCustomerFileItemInfo = (customerFileInfo) => {
//     if (!isEmpty(customerFileInfo)) {
//         const { createAt, createByUser, name, extension, volume, resolution, dimension, used, sharedSheetOrderId, nasInvoiceId } = customerFileInfo;
//         return (
//             <>
//                 {!!createByUser && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
//                     className='background-white-color min-size'
//                     value={createByUser} />
//                 }
//                 {!!name && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileName')}
//                     className='background-white-color min-size'
//                     value={name} />
//                 }
//                 {!!extension && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileFormat')}
//                     className='background-white-color min-size'
//                     value={extension.toUpperCase()} />
//                 }
//                 {!!volume && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileSize')}
//                     className='background-white-color min-size'
//                     value={`${CommonHelper.convertByteToMegaByte(volume)} MB`} />
//                 }
//                 {!!dimension && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileDimension')}
//                     className='background-white-color min-size'
//                     value={dimension} />
//                 }
//                 {!!resolution && <NsLabelBox label={getTranslatedRawText('adminPanel.customersFiles.fileResolution')}
//                     className='background-white-color min-size'
//                     value={resolution} />
//                 }
//                 {!!createAt && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.createDate')}
//                     className='background-white-color min-size'
//                     value={DateHelper.getDateAndTime(createAt)} />
//                 }
//                 <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.usingInAOrder')}
//                     className='background-white-color min-size'
//                     value={used ? getTranslatedRawText('common.yes') : getTranslatedRawText('common.no')} />

//                 {!!sharedSheetOrderId && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.remotingOrderId')}
//                     className='background-white-color min-size'
//                     value={sharedSheetOrderId} />
//                 }
//                 {!!nasInvoiceId && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber')}
//                     className='background-white-color min-size'
//                     value={nasInvoiceId} />
//                 }
//             </>
//         );
//     }
// }

// const setShippingInfo = (shippingInfo) => {
//     if (!isEmpty(shippingInfo)) {
//         const { address, courier, courierAddress, senderAddress, countyWarehouseAddress, requestDate, requestNumber, transferStateType,
//             tel, tel2, countyWarehouseTel1, countyWarehouseTel2, courierTel1, courierTel2, senderTel1, senderTel2, shippingPlan,
//             carrier, className, shippingMethodAddress, shippingMethodName, description, orderProductType, userAddress, userFullName,
//             estimatedDeliveryTimeFrom, estimatedDeliveryTimeTo, shippingPlanTypeId } = shippingInfo;
//         return (
//             <>
//                 {!!address && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.shippings.address')}
//                     value={address} />
//                 }
//                 {!!userAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.shippings.address')}
//                     value={userAddress} />
//                 }
//                 {!!requestNumber && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.shippingRequestNumber')}
//                     value={requestNumber} />
//                 }
//                 {!!requestDate && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.shippingRequestTime')}
//                     value={DateHelper.getDateAndTime(requestDate)} />
//                 }
//                 {!!shippingPlan && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.delivery.shippingPlan')}
//                     value={shippingPlan} />
//                 }
//                 {!!shippingPlanTypeId && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.delivery.shippingPlan')}
//                     value={EnumerationTranslationHelper.getShippingPlanType(shippingPlanTypeId)} />
//                 }
//                 {!!shippingMethodName && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.delivery.shipmentMethod')}
//                     value={shippingMethodName} />
//                 }
//                 {!!senderAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.deliveryRequest.senderAddress')}
//                     value={senderAddress} />
//                 }
//                 {!!senderTel1 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.deliveryRequest.senderTel')}
//                     value={senderTel1} />
//                 }
//                 {!!senderTel2 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.deliveryRequest.senderTel')}
//                     value={senderTel2} />
//                 }
//                 {!!transferStateType && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.status')}
//                     value={ConstantsWithTranslation.getDeliveryRequestStateType(transferStateType)} />
//                 }
//                 {!!description && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.delivery.shipmentDescription')}
//                     value={description} />
//                 }
//                 {!!userFullName && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
//                     value={userFullName} />
//                 }
//                 {!!orderProductType && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.productType')}
//                     value={EnumerationTranslationHelper.getProductType(orderProductType)} />
//                 }
//                 {!!countyWarehouseAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.deliveryRequest.countyWarehouseAddress')}
//                     value={countyWarehouseAddress} />
//                 }
//                 {!!courierAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.deliveryRequest.courierAddress')}
//                     value={courierAddress} />
//                 }
//                 {!!courier && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.dataGridColumn.courierServiceName')}
//                     value={courier} />
//                 }
//                 {!!tel && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.delivery.phoneNumber')}
//                     value={tel} />
//                 }
//                 {!!tel2 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.delivery.phoneNumber')}
//                     value={tel2} />
//                 }
//                 {!!countyWarehouseTel1 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.deliveryRequest.countyWarehouseTel')}
//                     value={countyWarehouseTel1} />
//                 }
//                 {!!countyWarehouseTel2 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.deliveryRequest.countyWarehouseTel')}
//                     value={countyWarehouseTel2} />
//                 }
//                 {!!courierTel1 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.deliveryRequest.courierTel')}
//                     value={courierTel1} />
//                 }
//                 {!!courierTel2 && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.deliveryRequest.courierTel')}
//                     value={courierTel2} />
//                 }
//                 {!!shippingMethodAddress && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.shippings.address')}
//                     value={shippingMethodAddress} />
//                 }
//                 {!!carrier && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('profile.delivery.carrier')}
//                     value={carrier} />
//                 }
//                 {!!estimatedDeliveryTimeFrom && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.shippings.estimatedDeliveryTimeFrom')}
//                     value={DateHelper.getDatePart(estimatedDeliveryTimeFrom)} />
//                 }
//                 {!!estimatedDeliveryTimeTo && <NsLabelBox className={clsx(!!className ? className : 'background-element-color', 'min-size')}
//                     label={getTranslatedRawText('adminPanel.shippings.estimatedDeliveryTimeTo')}
//                     value={DateHelper.getDatePart(estimatedDeliveryTimeTo)} />
//                 }
//             </>
//         );
//     }
// }

export const getCmsLogBasicInfos = (info) => {
    if (!isEmpty(info)) {
        const { clientIp, createAt, source, type, message, customLogType, host, orderApplicationType, userFullName } = info;
        return {
            fullName: userFullName,
            logType: ConstantsWithTranslation.getLogTypeLabel(customLogType),
            orderApplicationType: ConstantsWithTranslation.getOrderApplicationTypeById(orderApplicationType),
            host: host,
            clientIp: clientIp,
            createDate: DateHelper.getDateAndTime(createAt),
            type: type,
            source: source,
            message: message
        }
    } else
        return {}

}

export const getFeedbackBasicInfos = (info) => {
    if (!isEmpty(info)) {
        const { userFeedbackStateTypeId, sharedSheetOrderStateTypeId, isPublic, fullName, announcementTitle, productName, submitDate,
            estimateReadyToDeliverTime, employeeFullName, feedbackToAnnouncementContentTitle, nasInvoiceId } = info;
        return {
            fullName: fullName,
            employeeFullName: employeeFullName,
            productName: productName,
            invoiceNumber: nasInvoiceId,
            lastStatus: ConstantsWithTranslation.getSharedSheetOrderStateType(sharedSheetOrderStateTypeId),
            estimateReadyToDeliverTime: DateHelper.getDateAndTime(estimateReadyToDeliverTime),
            announcementTitle: announcementTitle || feedbackToAnnouncementContentTitle,
            feedbackSubmitDate: DateHelper.getDateAndTime(submitDate),
            lastUserFeedbackStateTypeId: EnumerationTranslationHelper.getTranslategetFeedbackState(userFeedbackStateTypeId),
            displaytoAll: EnumerationTranslationHelper.getYesOrNoStatus(isPublic)
        }
    } else
        return {}
}

export const getFeedbackTypeBasicInfos = (info) => {
    if (!isEmpty(info)) {
        const { feedbackTypeId, isActive } = info;
        return {
            feedbackType: EnumerationTranslationHelper.getTranslategetFeedbackType(feedbackTypeId),
            isActive: EnumerationTranslationHelper.getActivationStatus(isActive),
        }
    } else
        return {}
}

export const getAgreementBasicInfos = (info) => {
    if (!isEmpty(info)) {
        const { createAt, editAt, isActive } = info;
        return {
            createDate: DateHelper.getDateAndTime(createAt),
            modifyDate: DateHelper.getDateAndTime(editAt),
            isActive: EnumerationTranslationHelper.getActivationStatus(isActive),
        }
    } else
        return {}
}

// const setbusinessDayCalulationInfo = (businessDayCalulationInfo) => {
//     const { submitTime, confirmTime, estimateReadyToDeliverTime, actualReadyToDeliveryTime, actualDeliveredTime, businessDays, fullName,
//         productName, turnaroundType, printedSide, nasInvoiceId } = businessDayCalulationInfo;
//     const className = clsx(isAdminPanel ? 'background-white-color' : '', 'min-size');
//     return <>
//         {!isEmpty(fullName) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
//             value={fullName} />}
//         {!isEmpty(productName) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.productName')}
//             value={productName} />}
//         {!isEmpty(turnaroundType) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.turnaround')}
//             value={EnumerationTranslationHelper.getTurnaroundText(turnaroundType)} />}
//         {!isEmpty(printedSide) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.printedSide')}
//             value={EnumerationTranslationHelper.getPrintedSideText(printedSide)} />}
//         {!isEmpty(nasInvoiceId) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber')}
//             value={nasInvoiceId} />
//         }
//         {!isEmpty(submitTime) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.submitTime')}
//             value={DateHelper.getDateAndTime(submitTime)} />
//         }
//         {!isEmpty(confirmTime) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.confirmTime')}
//             value={DateHelper.getDateAndTime(confirmTime)} />
//         }
//         {!isEmpty(estimateReadyToDeliverTime) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime')}
//             value={DateHelper.getDateAndTime(estimateReadyToDeliverTime)} />
//         }
//         {!isEmpty(actualReadyToDeliveryTime) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.actualReadyToDeliveryTime')}
//             value={DateHelper.getDateAndTime(actualReadyToDeliveryTime)} />
//         }
//         {!isEmpty(actualDeliveredTime) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.actualDeliveredTime')}
//             value={DateHelper.getDateAndTime(actualDeliveredTime)} />
//         }
//         {!isEmpty(businessDays) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.readyTime')}
//             value={businessDays} />
//         }
//     </>
// }

// const setSheetInfo = (sheetInfo) => {
//     const { changeStateTime, changeStateUserName, closeTime, collectPaperUserName, deliveryTimeForecast, isClosed, isCollectPaper, isFilesDeleted,
//         layoutedException, layoutedIsClose, layoutedIsCloseTime, lockedUserName, nasInvoiceIds, openTime, sheetID, sheetStateQueueTypeID, stateName,
//         turnaroundType, twoSidePrintingType } = sheetInfo;
//     const className = clsx(isAdminPanel ? 'background-white-color' : '', 'min-size');
//     return <>
//         {!isEmpty(stateName) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.lastStatus')}
//             value={stateName} />
//         }
//         {!isEmpty(changeStateTime) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.statusDate')}
//             value={DateHelper.getDateAndTime(changeStateTime)} />
//         }
//         {!isEmpty(sheetStateQueueTypeID) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.lastSheetStateQueueType')}
//             value={ConstantsWithTranslation.getSharedSheetOrderStateType(sheetStateQueueTypeID)} />
//         }
//         {!isEmpty(changeStateUserName) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.lastChangeStateUser')}
//             value={changeStateUserName} />
//         }
//         {!isEmpty(sheetID) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.orders.sheetId')}
//             value={sheetID} />
//         }
//         {!isEmpty(turnaroundType) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.turnaround')}
//             value={EnumerationTranslationHelper.getTurnaroundText(turnaroundType)} />
//         }
//         {!isEmpty(twoSidePrintingType) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.printedSide')}
//             value={EnumerationTranslationHelper.getPrintedSideText(twoSidePrintingType)} />
//         }
//         {/* SERIES */}
//         {!isEmpty(openTime) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.openTime')}
//             value={DateHelper.getDateAndTime(openTime)} />
//         }
//         {!isEmpty(closeTime) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.closeTime')}
//             value={DateHelper.getDateAndTime(closeTime)} />
//         }
//         {!isEmpty(isClosed) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.isClosed')}
//             value={EnumerationTranslationHelper.getYesOrNoStatus(isClosed)} />
//         }
//         {!isEmpty(lockedUserName) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.lockedUsername')}
//             value={lockedUserName} />
//         }
//         {!isEmpty(isFilesDeleted) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.isSheetDeleted')}
//             value={EnumerationTranslationHelper.getYesOrNoStatus(isFilesDeleted)} />
//         }
//         {!isEmpty(isCollectPaper) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.collectPaperStatus')}
//             value={EnumerationTranslationHelper.getYesOrNoStatus(isCollectPaper)} />
//         }
//         {!isEmpty(collectPaperUserName) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.collectPaperUser')}
//             value={collectPaperUserName} />
//         }
//         {!isEmpty(layoutedIsClose) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.LayoutedCloseStatus')}
//             value={EnumerationTranslationHelper.getYesOrNoStatus(layoutedIsClose)} />
//         }
//         {!isEmpty(layoutedIsCloseTime) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.layoutedCloseTime')}
//             value={DateHelper.getDateAndTime(layoutedIsCloseTime)} />
//         }
//         {!isEmpty(layoutedException) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.layoutedException')}
//             value={DateHelper.getDateAndTime(layoutedException)} />
//         }
//         {!isEmpty(layoutedException) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.layoutedException')}
//             value={layoutedException} />
//         }
//         {/* Layouting Machine Name  */}
//         {!isEmpty(deliveryTimeForecast) && <NsLabelBox className={className}
//             label={getTranslatedRawText('adminPanel.dataGridColumn.deliveryTimeForecast')}
//             value={DateHelper.getDateAndTime(deliveryTimeForecast)} />
//         }
//     </>
// }

// // return (
// //     isLoading
// //         ? <Row>
// //             {isLoading && CommonHelper.createArray(3).map((item, index) =>
// //                 <Col key={index} xs={2}>
// //                     <NsSkeleton isVisible={true}
// //                         variant={[Enumerations.skeleton.rect,
// //                         Enumerations.skeleton.rect,
// //                         Enumerations.skeleton.rect,
// //                         Enumerations.skeleton.rect]} />
// //                 </Col>
// //             )}
// //         </Row>
// //         : <div className='d-flex flex-wrap gap-2'>
// //             {!!info?.mobile && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.mobile')}
// //                 value={info?.mobile} />}
// //             {!!info?.submitTime && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.submitDate')}
// //                 value={info?.submitTime} />}
// //             {/* customer Labels */}
// //             {!!info?.fullName && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.fullName')}
// //                 value={info?.fullName} />}
// //             {!!info?.phoneNumber && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.mobile')}
// //                 value={info?.phoneNumber} />}
// //             {!!info?.registredTime && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.submitDate')}
// //                 value={DateHelper.getDateAndTime(info?.registredTime)} />}
// //             {!!info?.accountStatus && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.lastStatus')}
// //                 value={info?.accountStatus ? getTranslatedRawText('adminPanel.usersInfo.active') : getTranslatedRawText('adminPanel.usersInfo.block')} />}
// //             {!!info?.phoneNumberConfirmed && isEditMode && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.mobileActivation')}
// //                 value={info?.phoneNumberConfirmed ? getTranslatedRawText('adminPanel.usersInfo.confirmed') : getTranslatedRawText('adminPanel.usersInfo.notConfirmed')} />
// //             }
// //             {!!info?.createAt && <NsLabelBox className='background-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.createDate')}
// //                 value={DateHelper.getDateAndTime(info?.createAt)} />
// //             }
// //             {!!info?.editAt && <NsLabelBox className='background-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.modifyDate')}
// //                 value={DateHelper.getDateAndTime(info?.editAt)} />
// //             }
// //             {!isEmpty(info?.isActive) && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.orders.isActive')}
// //                 value={EnumerationTranslationHelper.getActivationStatus(info?.isActive)} />
// //             }
// //             {info?.userDepartments?.length > 0 && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.department')}
// //                 value={<div className="labelbox-truncate text-truncate">{info?.userDepartments.join(',')}</div>} />
// //             }
// //             {!isEmpty(info?.description) && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.description')}
// //                 value={<div className="labelbox-truncate text-truncate">{info?.description}</div>} />
// //             }
// //             {!isEmpty(info?.startDateFileRegulator) && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.orders.startDateFileRegulator')}
// //                 value={DateHelper.getDateAndTime(info?.startDateFileRegulator)} />
// //             }
// //             {!isEmpty(info?.serverNameFileRegulator) && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.orders.serverNameFileRegulator')}
// //                 value={info?.serverNameFileRegulator} />
// //             }
// //             {!isEmpty(info?.estimateReadyToDeliverTimeFrom) && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTimeFrom')}
// //                 value={DateHelper.getDateAndTime(info?.estimateReadyToDeliverTimeFrom)} />
// //             }
// //             {!isEmpty(info?.estimateReadyToDeliverTimeTo) && <NsLabelBox className='background-white-color min-size'
// //                 label={getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTimeTo')}
// //                 value={DateHelper.getDateAndTime(info?.estimateReadyToDeliverTimeTo)} />
// //             }
// //             {!isEmpty(info?.orderItemInfoDto) && setOrderItemInfo(info?.orderItemInfoDto)}

// //             {!isEmpty(info?.storeOrderItemInfoDto) && setStoreOrderItemInfo(info?.storeOrderItemInfoDto)}

// //             {!isEmpty(info?.paymentItemInfoDto) && setPaymentItemInfo(info?.paymentItemInfoDto)}

// //             {!isEmpty(info?.customerFileItemInfoDto) && setCustomerFileItemInfo(info?.customerFileItemInfoDto)}

// //             {!isEmpty(info?.shippingInfoDto) && setShippingInfo(info?.shippingInfoDto)}

// //             {!isEmpty(info?.cmsLogInfoDto) && setCmsLogInfo(info?.cmsLogInfoDto)}

// //             {!isEmpty(info?.feedbackTypeInfo) && setFeedbackTypeInfo(info?.feedbackTypeInfo)}

// //             {!isEmpty(info?.feedbackBasicInfo) && setFeedbackBasicInfo(info?.feedbackBasicInfo, props?.className)}

// //             {!isEmpty(info?.businessDayCalulationInfo) && setbusinessDayCalulationInfo(info?.businessDayCalulationInfo)}

// //             {!isEmpty(info?.sheetInfoDto) && setSheetInfo(info?.sheetInfoDto)}

// //         </div>
// // );
