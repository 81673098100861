import React, { Component } from "react";

import { GetStoreProductCartItemsCountService } from "../../../services/product/StoreProductService";
import ConstantsWithTranslation from "../../../configs/ConstantsWithTranslation";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import NsProductCard from "../../../components/cards/NsProductCard";
import NsSkeleton from "../../../components/skeletons/NsSkeleton";
import CommonHelper from "../../../utilities/CommonHelper";
import Enumerations from "../../../configs/Enumerations";
import NsCard from "../../../components/cards/NsCard";
import Config from "../../../configs/Config";

class ContentCardBody extends Component {

    constructor(props) {
        super(props);

        this.state = {
            supplierOrderCart: [],
            isLoadingStoreCartItems: false,
            resultMessageClass: (<></>)
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.supplierOrderCartDate !== this.props.supplierOrderCartDate)
            this.getSupplierOrderCount();
    }

    getSupplierOrderCount = () => {
        if (!!this.props.isAuthenticated && this.props.isStoreProducts && Config.hasStoreProducts) {
            this.setState({ isLoadingStoreCartItems: true });
            GetStoreProductCartItemsCountService(this.getStoreProductCartItemsCountCallback);
        }
    }

    getStoreProductCartItemsCountCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoadingStoreCartItems: false });
        if (!result?.data?.hasError) {
            this.setState({ supplierOrderCart: resultData });
        }
        else
            this.setState({ resultMessageClass });
    }

    refreshProduct = () => this.getSupplierOrderCount();

    render() {
        const { supplierOrderCart, resultMessageClass, isLoadingStoreCartItems } = this.state;
        const { isLoading, isAuthenticated, products, isStoreProducts } = this.props;
        return (
            <div className='content-body row m-0 mt-2'>
                <ShowCard products={products}
                    isAuthenticated={isAuthenticated}
                    supplierOrderCart={supplierOrderCart}
                    isStoreProducts={isStoreProducts}
                    isLoadingStoreCartItems={isLoadingStoreCartItems}
                    isLoading={isLoading}
                    refreshProduct={this.refreshProduct} />
                {resultMessageClass}
            </div>
        );
    }
}

function ShowCard(props) {
    const numberOfSkeletons = CommonHelper.createArray(12);
    if (props.isLoading)
        return numberOfSkeletons.map((item, index) =>
            <div key={index} className="d-inline d-md-inline-block col-sm-6 col-lg-4 p-2">
                <NsSkeleton isVisible={true}
                    variant={[
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.text,
                        Enumerations.skeleton.text,
                    ]} />
            </div>
        );
    if (props?.products?.length > 0)
        return props.products.map((product, index) => (
            <NsProductCard key={index}
                isAuthenticated={props.isAuthenticated}
                usedIn={Enumerations.imageSitePosition.shopProductCard}
                product={product}
                isStoreProducts={props.isStoreProducts}
                className="col-sm-6 col-lg-4 p-1"
                isLoadingStoreCartItems={props.isLoadingStoreCartItems}
                googleTagManagerListName={ConstantsWithTranslation.getListNameGoogleTagManager(Enumerations.googleTagManagerListId.shop)}
                googleTagManagerListId={Enumerations.googleTagManagerListId.shop}
                googleTagManagerIndex={index}
                supplierOrderCart={props.supplierOrderCart.find(item => item.key === product.storeProductSupplierPriceId)}
                refreshProduct={props.refreshProduct} />
        ));
    else
        return <NsCard className="p-5 text-center font-size-18 fw-bold">{getTranslatedRawText('product.messages.noResult')}</NsCard>;
}

export default ContentCardBody;
