import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsModal from '../../../../../components/modals/NsModal';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import Enumerations from '../../../../../configs/Enumerations';
import { EditUserPublicProfileStatusService, GetApUserProfileQrCodeService, GetBusinessPartnerDetailService, GetCustomerDetailService, GetSupporterDetailService } from '../../../../../services/adminPanel/AdminUserService';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseLabelInfo from '../../../bases/ApBaseLabelInfo';
import ApBaseUserInfoModal from './ApBaseUserInfoModal';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import CommonHelper from '../../../../../utilities/CommonHelper';
import NsImage from '../../../../../components/images/NsImage';
import ImageHelper from '../../../../../utilities/ImageHelper';
import NsTypography from '../../../../../components/text/NsTypography';
import NsDownloadFile from '../../../../../components/downloadFiles/NsDownloadFile';

export default function ApBaseUserProfileModal(props) {
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [showActivationProfileModal, setShowActivationProfileModal] = useState(false);
    const [userDetails, setUserDetails] = useState('');
    const [userPublicProfile, setUserPublicProfile] = useState('');
    const [fileQrCode, setFileQrCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    const toggleUserInfoModal = () => setShowUserInfoModal(!showUserInfoModal);
    const closeApCustomerUserModal = () => setShowUserInfoModal(false);
    const toggleUserActivationProfileModal = () => setShowActivationProfileModal(!showActivationProfileModal);

    const activeDeActiveProfileClicked = (confirm) => {
        !!confirm && setIsLoading(true);
        let parameters = {
            Id: userPublicProfile?.userId,
            isActive: !userPublicProfile?.isActive
        }
        toggleUserActivationProfileModal();
        !!confirm && EditUserPublicProfileStatusService(parameters, editUserPublicProfileStatusCallback, true)
    }

    const handleCopyClick = (path) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(path);
        }
    }

    useEffect(() => {
        let parameters = { id: props?.userId };
        props?.userId && GetApUserProfileQrCodeService(parameters, getApUserProfileQrCodeCallback);
    }, [])

    const getApUserProfileQrCodeCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError)
            setUserPublicProfile(resultData);
        else
            setResultMessageClass(messageClassModal);
    }

    const editUserPublicProfileStatusCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data?.hasError) props.closeModal(false, messageClassModal);
        else setResultMessageClass(messageClassModal);
    }

    const headerButtons = [
        hasAccess([UserClaimEnumerations.getApCustomerById]) && { startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserInfoModal },
        { startIcon: '', label: userPublicProfile.isActive ? 'adminPanel.usersInfo.deActivateProfile' : 'adminPanel.usersInfo.activateProfile', onClick: toggleUserActivationProfileModal },
    ]

    return (
        <div>
            <NsModal
                maxWidth='sm'
                showModal={props.showModal}
                closeModal={props.closeModal}
                noSpace={true}
                dialogTitle={getTranslatedRawText("adminPanel.usersInfo.employeesProfile")}>
                <ApBaseMapActionButtons actionButtons={headerButtons}
                    isLoading={isLoading} />
                <div className='background-color p-2'>
                    <ApBaseLabelInfo info={userPublicProfile}
                        hasAccessInfo={true}
                        isLoading={isLoading} />
                </div>
                <Row className='d-flex justify-content-between mx-0 p-2 px-4 mb-4'>
                    <Col md={3}>
                        <NsImage
                            className='w-100'
                            src={ImageHelper.contentToBase64(userPublicProfile?.userProfileQrCode,
                                getTranslatedRawText('common.imageFormat'),
                                getTranslatedRawText('common.fileExtension.png'))} />
                    </Col>
                    <Col md={9}>
                        <div className='d-flex justify-content-around border rounded p-2 align-items-center mt-2 row mx-0'>
                            <div className='d-flex align-items-center col-11 px-1'>
                                <i className='ns-icon-link font-size-16 outline-color pe-2' />
                                <a target='_blank' href={userPublicProfile?.userProfileUrlAddress} className='cursor-pointer text-decoration-underline text-truncate black-color'>
                                    {userPublicProfile?.userProfileUrlAddress}
                                </a>
                            </div>
                            <i className='ns-icon-copy font-size-24 col-1 cursor-pointer'
                                onClick={() => handleCopyClick(userPublicProfile?.userProfileUrlAddress)}
                            />
                        </div>
                        <div className='d-flex align-items-center gap-1 mt-4 cursor-pointer'
                            onClick={() => setFileQrCode({
                                fileDownloadName: `${userPublicProfile?.fullName ? userPublicProfile?.fullName : ''} QR-Code.jpg`,
                                fileContents: CommonHelper.createDownloadFileUrl('test.jpg', userPublicProfile?.userProfileQrCode)
                            })}>
                            <i className='ns-icon-download font-size-24' />
                            <NsTypography variant='inherit' className='fw-bold'>
                                {getTranslatedRawText('adminPanel.usersInfo.downloadQrCode')}
                            </NsTypography>
                        </div>
                    </Col>
                </Row>
            </NsModal>
            {showUserInfoModal &&
                <ApBaseUserInfoModal showModal={true}
                    roleType={Enumerations.role.supporter}
                    selectedUserId={props.userId}
                    closeModal={closeApCustomerUserModal} />
            }
            {showActivationProfileModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => activeDeActiveProfileClicked(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText(userPublicProfile?.isActive
                                ? 'common.messages.deactivateProfileQuestion'
                                : 'common.messages.activateProfileQuestion'), userPublicProfile?.fullName)}</>,
                            messageType: Enumerations.messageType.warning
                        }]} />
            }
            <NsDownloadFile file={fileQrCode} />
            {resultMessageClass}
        </div>
    )
}
