import React, { Component } from 'react';

import '../../../../../assets/sass/views/orders/professionalOrder.scss';
import NsRadioButton from '../../../../../components/buttons/NsRadioButton';
import NsOptionBox from '../../../../../components/labels/NsOptionBox';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';

class PoFoldLineModalPreset extends Component {
    render() {
        const { foldLinePresetOptions, selectedFoldLinePreset, foldLinePresetClicked } = this.props;
        return (
            foldLinePresetOptions.map((option) =>
                <div key={option.id} className='col-lg-12 col-6 d-inline-block py-1' onClick={() => foldLinePresetClicked(option)}>
                    <NsOptionBox isSelected={selectedFoldLinePreset?.id === option.id}>

                        <img src={option.flagImage}
                            className='pt-2'
                            width='60px'
                            height='50px'
                            alt={getTranslatedRawText("components.imagesAlt.foldLineIcons")} />

                        <div className='w-100'>
                            <NsRadioButton option={option}
                                checked={selectedFoldLinePreset?.id === option.id} />
                            <div className="font-size-12 d-none d-md-block ms-3 mb-2">
                                {option.description}
                            </div>
                            <div className="font-size-12 light-text-color mt-auto ms-3">
                                {CommonHelper.currencyFormatByDefaultConfig(option.price)}
                            </div>

                        </div>
                    </NsOptionBox>
                </div>
            )
        );
    }
}
export default PoFoldLineModalPreset;