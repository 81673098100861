import React, { Component } from 'react';
import NsModal from '../../../components/modals/NsModal';
import CommonHelper from '../../../utilities/CommonHelper';
import SidebarCategories from '../sidebar/SidebarCategories';
import SidebarSearch from '../sidebar/SidebarSearch';
import SidebarPrice from '../sidebar/SidebarPrice';
import SidebarOptions from '../sidebar/SidebarOptions';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import NsStickyButton from '../../../components/buttons/NsStickyButton';

class ProductsMobileFilter extends Component {
    constructor(props) {
        super(props);
        this.mainButtons = [
            CommonHelper.createStickyButtonItem("product.common.filter", 'ns-icon-filter', this.submitMobileFilters, 'primary'),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', props.modalToggle, 'primary-outline'),
        ];
        this.state = {
            mobileFilters: {
                pageNumber: 1,
                selectedCategoriesFromURL: null,
                categories: props.filters.categories,
                searchInput: '',
                price: {
                    minPrice: 0,
                    maxPrice: 100,
                    filterLabel: ""
                },
                option: {
                    printedSide: null,
                    turnaround: null
                },
                sortBy: [],
            },
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.filters !== nextProps.filters)
            this.setState({ mobileFilters: nextProps.filters });
    }

    handleMobileFilter = (filter, name) => {
        const filterName = CommonHelper.getVariableName(name);
        this.setState({ mobileFilters: { ...this.state.mobileFilters, [filterName]: filter } });
    }

    submitMobileFilters = () => {
        this.props.submitMobileFilters(this.state.mobileFilters);
        this.props.modalToggle();
    }

    render() {
        const { filters, modalToggle, isFilterModalOpen, isStoreProducts } = this.props;
        const { mobileFilters } = this.state;
        const { categories, searchInput, price, option } = this.state.mobileFilters;

        return (
            <NsModal maxWidth="sm"
                dialogTitle={getTranslatedRawText('product.common.filter')}
                showModal={isFilterModalOpen}
                closeModal={modalToggle}
                actions={<NsStickyButton stickyButtons={this.mainButtons} isModal={true} />}
            >
                <div className="mb-3 border-0">
                    <SidebarCategories filters={filters}
                        visibleCount='all'
                        categoriesMobileFilter={filter => this.handleMobileFilter(filter, { categories })} />
                </div>
                <div className="mb-3">
                    <SidebarSearch filters={mobileFilters}
                        hasSearchIcon={false}
                        searchMobileFilter={filter => this.handleMobileFilter(filter, { searchInput })} />
                </div>
                <div className="mb-3">
                    <SidebarPrice filters={mobileFilters}
                        hasFilterButton={false}
                        isStoreProducts={isStoreProducts}
                        priceMobileFilter={filter => this.handleMobileFilter(filter, { price })} />
                </div>
                {!isStoreProducts && <div className="mb-3">
                    <SidebarOptions filters={mobileFilters}
                        optionMobileFilter={filter => this.handleMobileFilter(filter, { option })} />
                </div>
                }
            </NsModal>
        );
    }
}

export default ProductsMobileFilter;