const AdminPanelTranslationFa = {

    adminPanel: {

        common: {
            action: 'عملیات',
            new: 'جدید',
            retry: 'تلاش مجدد',
            invoice: 'فاکتور',
            receipt: 'رسید',
            userInfo: 'اطلاعات کاربر',
            editUser: 'ویرایش کاربر',
            updateStatus: 'بروز رسانی وضعیت',
            statusHistory: 'تاریخچه',
            notAvailableHistory: 'تاریخچه‌‌ای وجود ندارد',
            usersOrder: 'سفارش‌های کاربر',
            usersPayments: 'پرداخت‌های کاربر',
            ordersPayment: "پرداخت‌های سفارش",
            notificationSetting: 'تنظیمات اطلاع رسانی',
            searchPlaceHolder: 'جستجو در وبلاگ / اخبار...',
            closeModalAlert: 'در صورت بستن این پنجره، تغییرات ذخیره نشده از بین خواهند رفت. آیا از بستن این پنجره مطمئن هستید؟',
            newTitle: 'عنوان جدید',
            editTitle: 'ویرایش عنوان',
            isTitleVisible: 'نمایش عنوان',
            updatePaymentState: 'رفع مشکل ({0})',
            noAnyDataToView: 'اطلاعاتی برای نمایش وجود ندارد.',
            all: 'همه',
            ordersInfo: "اطلاعات سفارش",
            usersDebitCredit: 'بدهکاری-بستانکاری',
            guest: 'مهمان',
        },

        title: {
            statisticalReportOfOrders: 'گزارش تجمعی سفارش‌ها',
            statisticalReportOfPayments: 'گزارش تجمعی پرداخت‌ها',
            debitCreditReport: 'گزارش بدهکاری-بستانکاری',
            paymentReport: 'گزارش پرداخت'
        },

        dateRange: {
            daily: 'روزانه',
            weekly: 'هفتگی',
            monthly: 'ماهانه',
            yearly: 'سالانه',
        },

        sidebar: {
            dashboard: 'داشبورد',
            management: 'مدیریت',
            users: 'کاربران',
            customers: 'مشتریان',
            customersFiles: "فایل مشتریان",
            supporters: 'پشتیبانان',
            orders: 'سفارش‌ها',
            shareSheets: 'عمومی',
            stores: 'فروشگاهی',
            queue: 'صف',
            payments: 'پرداخت‌ها',
            list: 'لیست',
            problematicPayments: 'پرداخت‌های مشکل دار',
            reports: 'گزارش فرم‌های عمومی',
            statisticalReportOfOrders: 'تجمعی سفارش‌ها',
            statisticalReportOfPayments: 'تجمعی پرداخت‌ها',
            usersDebitCredit: 'بدهکاری-بستانکاری',
            comments: 'نظرها',
            content: 'محتوا',
            downloadCenter: 'مرکز دانلود',
            downloadDetail: 'جزئیات دانلود',
            blog: 'وبلاگ',
            blogsPosts: 'وبلاگ-پست‌ها',
            blogCategories: 'وبلاگ-دسته‌بندی‌ها',
            newssPosts: 'اخبار-پست‌ها',
            newsCategories: 'اخبار-دسته‌بندی‌ها',
            posts: 'پست‌ها',
            category: 'دسته‌بندی',
            news: 'اخبار',
            announcements: 'اطلاعیه‌ها',
            singlePages: 'تک صفحات',
            aboutUs: 'درباره ما',
            contactUs: 'تماس با ما',
            warrantyTerms: 'شرایط گارانتی',
            agreements: 'توافق نامه‌ها',
            faqs: 'سوالات متداول',
            slider: 'اسلایدر',
            promotions: 'پروموشن‌ها',
            testimonial: 'گواهینامه',
            setting: 'تنظیمات',
            websiteInfo: 'اطلاعات وبسایت',
            contactInfo: 'اطلاعات تماس',
            communicationChannel: 'کانال ارتباطی',
            websiteSetting: 'تنظیمات وبسایت',
            signupAgeRules: 'قوانین سنی ثبت نام',
            telecommunication: 'کانال ارتباطی',
            notificationConfig: 'پیکربندی اطلاعیه‌ها',
            smsConfig: 'پیکربندی پیامک‌ها',
            editSmsConfig: 'ویرایش تنظیمات پیامک',
            addNewSmsConfig: 'افزودن تنظیمات پیامک جدید',
            supportLanguage: 'زبان پشتیبانی',
            fileManagerConfig: 'پیکربندی مدیریت فایل‌ها',
            mapConfig: 'پیکربندی نقشه',
            shippings: 'ارسال‌ها',
            inTheWarehouse: 'در انبار',
            queueByLatestStatus: 'صف بر اساس آخرین وضعیت',
            queueByShippingMethods: 'صف بر اساس روش های ارسال',
            agencies: 'نمایندگی‌ها',
            businessPartners: 'همکاران تجاری',
            festivals: 'جشنواره‌ها',
            termsOfUse: 'شرایط استفاده',
            privacyPolicy: 'حریم خصوصی',
            warrantyTerms: 'شرایط گارانتی',
            help: "راهنما",
            fileManager: "مدیریت فایل‌ها",
            products: 'محصول‌ها',
            gallery: 'گالری',
            storeProducts: 'محصول‌های فروشگاهی',
            advertisement: 'تبلیغ‌ها',
            audit: 'ممیزی',
            productContent: 'محتوای محصول‌ها',
            logs: 'Logs',
            systemMonitoring: 'نظارت سیستم',
            paperBag: 'ساک خرید',
            calculateCustomize: 'محاسبه قیمت',
            feedbacks: 'بازخوردها',
            basicInformation: 'اطلاعات پایه',
            feedbackTypes: 'تنظیمات بازخورد‌ها',
            usersFeedbackTo: 'بازخورد کاربران به',
            employees: 'پرسنل',
            departments: 'چارت سازمانی',
            galleryPosts: 'پست های گالری',
            galleryCategories: 'دسته‌بندی های گالری',
            downloadCategory: 'دسته‌بندی های دانلود',
            blogPosts: 'پست‌های بلاگ',
            newsPosts: 'پست‌های اخبار',
            blogsCategory: "دسته‌بندی بلاگ",
            newsCategory: 'دسته‌بندی اخبار',
            galleryCategory: "دسته‌بندی گالری",
            queueByEstimatedTime: 'صف براساس زمان باقیمانده',
            queueByRemainingToERDGroupSheets: 'صف سفارش‌های عمومی بر اساس زمان باقیمانده تا تحویل',
            queueByRemaining: 'صف بر اساس زمان باقیمانده ...',
            remainingToEstimateReadyToDeliver: 'باقیمانده تا زمان تخمینی آماده به تحویل',
            dailyQueue: 'صف روزانه',
            dailyQueueOfEstimateTime: 'صف روزانه‌ی زمان تخمینی آماده تحویل بر اساس آخرین وضعیت سفارش‌های عمومی',
            dailyQueueShorted: 'صف روزانه‌ی زمان تخمینی ...',
        },

        department: {
            editDepartments: 'تغییر چارت سازمانی',
            parentDepartment: 'دپارتمان',
            addDepartment: 'ایجاد چارت سازمانی',
        },

        systemMonitoring: {
            message: 'پیام',
            logType: 'نوع Log',
            generalException: 'استثناهای عمومی',
            nullException: 'استثناهای نامشخص',
            clientIp: 'آی پی مشتری',
            host: 'دامنه',
            source: 'منبع',
            logsDetail: 'Logs جزئیات',
            allXml: 'همه XMLs'
        },

        newsBlog: {
            noAnyPost: 'پستی وجود ندارد',
            newBlog: 'وبلاگ جدید',
            newNews: 'اخبار جدید',
            minimals: 'حداقل‌ها',
            share: 'اشتراک گذاری'
        },

        dataGridColumn: {
            phoneNumberConfirmed: 'اعتبار اعطایی توسط شرکت',
            category: "دسته‌بندی",
            files: "فایل‌ها",
            fullName: 'نام و نام خانوادگی',
            status: 'وضعیت',
            title: 'عنوان',
            image: 'تصویر',
            creator: 'ایجاد کننده',
            modifyDate: 'تاریخ تغییر',
            modifier: 'تغییر دهنده',
            question: 'سوال',
            link: 'لینک',
            lastStatus: 'آخرین وضعیت',
            mobile: 'موبایل',
            registrationTime: 'زمان ثبت',
            detailLedger: 'حساب تفصیلی',
            inactiveUsers: 'کاربران بدون ثبت سفارش',
            country: 'کشور',
            state: 'استان',
            city: 'شهر',
            role: 'نقش‌ها',
            content: 'محتوا',
            accountType: 'نوع حساب کاربری',
            accountStatus: 'وضعیت حساب',
            idNumber: 'کد ملی',
            companyName: 'نام شرکت',
            mobileActivation: 'فعال سازی موبایل',
            licenseNumber: 'شناسه ملی شرکت',
            trn: 'کد اقتصادی',
            trackNumber: 'شماره رهگیری',
            amount: 'مبلغ',
            createDate: 'زمان ثبت سفارش',
            paymentGateway: 'درگاه پرداخت',
            paymentType: 'نوع پرداخت',
            TermsOfPayment: "روش/ قوانین پرداخت",
            statusDate: 'تاریخ آخرین وضعیت',
            voucherNumber: 'شماره سند',
            referenceNumber: 'شماره مرجع سیستم بانکی',
            invoiceNumber: 'شماره فاکتور',
            submitDate: 'تاریخ ثبت',
            description: 'توضیحات',
            productName: 'نام محصول',
            totalPrice: 'مبلغ کل',
            priceExcVat: 'مبلغ (بدون احتساب مالیات)',
            excVat: '(بدون احتساب مالیات)',
            incVat: '(با احتساب مالیات)',
            priceIncVat: 'مبلغ (با احتساب مالیات)',
            paymentInAdvance: 'پیش پرداخت',
            discount: 'تخفیف',
            totalOrdersDiscountAmount: 'مجموع مبلغ تخفیف سفارش‌ها',
            quantity: 'مجموع تعداد',
            debtor: 'بدهکار',
            creditor: 'بستانکار',
            detailLedger: 'حساب تفصیلی',
            circulation: 'تیراژ',
            printedSide: 'یکرو یا دورو',
            turnaround: 'سرعت چاپ',
            date: 'تاریخ',
            newUsers: 'کاربران جدید',
            orderUsers: 'کاربران سفارش داده',
            orders: 'سفارش‌ها',
            notConfirmed: 'تایید نشده',
            prepareFailed: 'رد شده',
            prepare: 'آماده',
            inProcess: "در پروسه تولید",
            archived: "بایگانی شده",
            inTransit: 'در پروسه ارسال ',
            ordersAmount: 'مقدار سفارش‌ها',
            successfullyPaymentAmount: 'مبلغ پرداختی‌های موفق',
            totalOrdersCount: 'تعداد کل سفارش‌ها',
            confirmedOrdersCount: 'تعداد سفارش‌های تایید شده',
            totalOrdersAmount: 'مبلغ کل سفارش‌ها',
            failedPaymentAmount: 'مبلغ پرداختی‌های ناموفق',
            successfullyPaymentCount: 'مبلغ پرداختی‌های موفق',
            failedPaymentCount: 'تعداد پرداختی‌های ناموفق',
            successfullyPaymentDistinctUserCount: 'مجموع کاربران با پرداخت موفق',
            failedPaymentDistinctUserCount: 'مجموع کاربران با پرداخت ناموفق',
            imageUrl: 'نشانی عکس',
            filePreview: "نمایش فایل",
            usingInAOrder: "استفاده در سفارش",
            used: 'استفاده شده',
            fileUrl: 'لینک فایل',
            remotingOrderId: "شماره سفارش",
            user: "کاربر",
            shippingRequestNumber: 'شماره درخواست ارسال',
            shippingRequestTime: 'زمان درخواست ارسال',
            shippingMethod: 'روش ارسال',
            courierNumber: 'شماره پیک',
            agencyName: 'نام نمایندگی',
            stateCity: 'استان/شهر',
            priceChange: 'تغییر قیمت',
            agencyDiscount: 'تخفیف نمایندگی',
            code: 'کد کالا',
            showIn: 'نمایش در',
            paymentProblemType: 'نوع مشکل پرداخت',
            cardNumber: 'شماره کارت',
            websiteTransactionStatus: 'وضعیت پرداخت وب سایت',
            fileTransactionStatus: 'وضعیت پرداخت فایل',
            transactionStatus: 'وضعیت تراکنش',
            websiteAmount: 'مبلغ وب سایت',
            fileAmount: 'مبلغ فایل',
            paymentAuditMatchingTypeId: 'وضعیت تطابق',
            paymentAuditMatchingType: 'نوع تطابق پرداخت',
            filePayment: "فایل",
            lastStatusType: 'آخرین وضعیت',
            lastStatusTime: 'آخرین زمان استعلام وضعیت',
            websitePayment: 'وب سایت',
            invoiceNumberId: 'شناسه فاکتور',
            merchantId: 'کد پذيرنده',
            payGateTranId: 'شماره تراکنش سوئیچ',
            traceNumber: 'شماره پیگیری تراکنش',
            paymentId: 'شناسه پرداخت',
            paymentStateType: 'وضعیت نهایی پرداخت',
            refoundStatus: 'وضعیت برگشت تراکنش',
            settleStatus: 'وضعیت تسویه',
            datePaid: 'تاریخ پرداخت',
            terminalId: 'کد ترمینال',
            transactionDate: 'تاریخ تراکنش',
            approvalTime: 'زمان تایید',
            extendedName: 'نام اضافه',
            usersWhoRegisterWithTwoNumber: 'کاربرانی که با ۲ شماره ثبت نام کرده‌اند',
            demandService: 'خدمات درخواستی',
            displaytoAll: 'نمایش به همه',
            averageRate: 'میانگین امتیاز',
            announcementTitle: 'عنوان اطلاعیه',
            avgRate: 'میانگین امتیاز',
            qty: 'تعداد',
            purchase: 'خرید',
            cashbackPlan: 'پلن برگشت پول',
            cashback: 'برگشت پول',
            remainingPurchaseToNextPlan: 'خرید باقیمانده تا پلن بعدی',
            nextCashbackPlan: 'پلن برگشت پول بعدی',
            nextCashback: 'برگشت پول بعدی',
            estimateReadyToDeliverTime: 'تخمین زمان آماده تحویل',
            actualReadyToDeliveryTime: 'زمان آماده به تحویل',
            actualDeliveredTime: 'زمان تحویل داده شده',
            productType: 'نوع محصول',
            lastSheetStateQueueType: 'آخرین وضعیت صف سفارش',
            lastChangeStateUser: 'آخرین تغییر وضعیت توسط',
            openTime: 'زمان باز شدن',
            closeTime: 'زمان بسته شدن',
            isClosed: 'وضعیت بسته شدن',
            lockedUsername: 'یوزر قفل شده',
            isSheetDeleted: 'آیا شیت حذف شده؟',
            collectPaperStatus: 'وضعیت جمعی کاغذ',
            collectPaperUser: 'نام جمعی کاغذ',
            layoutedCloseStatus: 'وضعیت بستن قالب',
            layoutedCloseTime: 'زمان بستن قالب',
            layoutedException: 'استثنا قالب',
            deliveryTimeForecast: 'پیش بینی زمان تحویل',
            submitTime: 'زمان ثبت',
            confirmTime: 'زمان تایید',
            readyTime: 'زمان آماده سازی',
            parents: 'گروه ها',
            remainingDaysToEstimateReadyToDeliverTime: 'مانده تا زمان تخمینی آماده به تحویل',
            days: 'روز',
            feedbackSubmitDate: 'تاریخ ثبت بازخورد',
            employeeFullName: "نام کامل پرسنل",
            extraSupport: 'پشتیبانی بیشتر',
            employee: 'پرسنل',
            transactionStatusMessage: 'پیغام وضعیت تراکنش',
            gatewayMessage: 'پیغام درگاه پرداخت ',
            estimateReadyToDeliverTimeFrom: 'از زمان تقریبی آماده به تحویل',
            estimateReadyToDeliverTimeTo: 'تا زمان تقریبی آماده به تحویل',
            onlyDelayedOrder: 'فقط سفارش‌های دارای تاخیر',
            department: 'چارت سازمانی',
            feedbackGiver: 'بازخورد دهنده',
            fileRegulatorServer: 'سرور رگولاتور فایل',
            orderStatus: 'وضعیت سفارش',
            currencyType: 'واحد پول',
            capturedAmount: 'Captured Amount',
            capturedDate: 'Captured Date',
            refundAmount: 'Refund Amount',
            refundDate: 'Refund Date',
            systemGeneratedOrder: 'System Generated Order',
            declineReason: 'Decline Reason',
            gatewayRisk: 'Gateway Risk',
            gatewayRiskViolations: 'Gateway Risk Violations',
            authenticationResponse: 'Authentication Response',
            threeDsfailureReason: 'Three Ds Failure Reason',
            outlet: 'Outlet',
            cardHolderEmail: 'Card Holder Email',
            outletReferenceId: 'Outlet Reference',
            cardholderName: 'Card Holder Name',
            paymentMethod: 'Payment Method',
            originIp: 'Origin Ip',
            paymentStatus: 'Payment Status'
        },

        financialReports: {
            totalPaymentAmount: 'کل مبلغ',
            successfullyPaymentAmount: 'موفق',
            failedPaymentAmount: 'ناموفق',
            numberOfTotalPayments: 'تعداد کل',
            unknownPaymentAmount: 'نامشخص',
            numberOfSuccessfulPayments: 'تعداد پرداخت‌های موفق',
            numberOfUnknownPayments: 'تعداد پرداخت‌های نامشخص',
            numberOfFailedPayments: 'تعداد پرداخت‌های ناموفق',
            numberOfTotalPaymentsByDistinctUser: 'تعداد کاربران دارای پرداخت',
            numberOfSuccessfulPaymentsByDistinctUser: 'کاربران دارای پرداخت موفق',
            numberOfFailedPaymentsByDistinctUser: 'کاربران دارای پرداخت ناموفق',
            numberOfUnknownPaymentsByDistinctUser: 'کاربران دارای پرداخت نامشخص',
            moreInsights: 'اطلاعات بیشتر'
        },

        usersInfo: {
            displayUserInfo: 'نمایش اطلاعات کاربر',
            officialInvoiceIssue: 'تنظیمات صدور فاکتور رسمی',
            editUserInfo: 'ویرایش اطلاعات کاربر',
            searchInUsers: 'جستجو در کاربران',
            accountSettings: 'تنظیمات حساب',
            personalInformation: 'اطلاعات شخصی',
            firstName: 'نام',
            lastName: 'نام خانوادگی',
            description: 'توضیحات',
            dateOfBirth: 'روز تولد',
            email: 'ایمیل',
            genderType: 'جنسیت',
            business: 'تجاری',
            personal: 'شخصی',
            admin: 'ادمین',
            superAdmin: 'سوپر ادمین',
            user: 'کاربر',
            subUser: 'کاربر فرعی',
            system: 'سیستم',
            supporter: 'پشتیبان',
            none: 'در انتظار پرداخت',
            confirmed: 'تایید شده',
            notConfirmed: 'تایید نشده',
            active: 'فعال',
            inactive: 'غیر فعال',
            block: 'بلاک',
            accountSetting: 'تنظیمات حساب',
            accountType: 'نوع حساب کاربری',
            companyName: 'نام شرکت',
            licenseNumber: 'شناسه ملی شرکت',
            trn: 'کد اقتصادی',
            idNumber: 'کد ملی',
            state: 'استان',
            city: 'شهر',
            agency: 'نمایندگی',
            company: "شرکت",
            noAgency: 'نمایندگی ندارد',
            editSupporterInfo: 'ویرایش اطلاعات پشتیبان',
            editCustomerInfo: 'ویرایش اطلاعات مشتری',
            editBusinessPartnerInfo: 'ویرایش اطلاعات همکار تجاری',
            newAdmin: 'دسترسی جدید',
            editAccess: 'ویرایش دسترسی‌ها',
            searchInOrders: 'جستجو در سفارش‌ها',
            adminRoleMessage: 'این نقش دارای تمامی دسترسی‌ها می‌باشد',
            agencyRoleMessage: 'این نقش دارای تمامی دسترسی‌های نمایندگی می‌باشد',
            businessPartnerRoleMessage: 'این نقش دارای تمامی دسترسی‌های همکار تجاری می‌باشد',
            businessPartner: 'همکار تجاری',
            inactiveUntil: 'غیرفعال تا',
            expireToken: 'خروج از حساب کاربری (تمام دستگاه‌ها)',
            areYouSureToExpireTokenP1: 'با کلیک بر روی دکمه "تایید"، تمام ورودهای شما به حساب کاربری این وب سایت، در هر مرورگر یا دستگاه، منقضی می شود و باید مجددا وارد شوید.',
            areYouSureToExpireTokenP2: 'این قابلیت به منظور افزایش امنیت حساب کاربری و جلوگیری از دسترسی‌های غیرمجاز است و در صورت افشا گذرواژه، لازم است که سریعا گذرواژه را تغییر داده و از حساب کاربری خود در تمام مرورگر‌ها و دستگاه‌ها خارج شوید.',
            BusinessPartnerEmbedCode: 'کد امبد همکار تجاری',
            xSecureApiKey: 'X Secure Api Key',
            resetSecureApiKey: 'بازنشانی X Secure Api Key',
            areYouSureToResetSecureApiKey: 'آیا از بازنشانی X Secure Api Key مطمئن هستید؟',
            remainingLimit: 'اعتبار باقیمانده',
            remainingLimitDescription: 'اعتبار باقی‌مانده: از حاصل جمع "مبلغ بستانکاری" و "اعتبار داده شده" و سپس کسر "مبلغ بدهکاری" و "مبلغ سفارش های داخل صف" بدست می آید.',
            creditor: 'بستانکار',
            creditorDescription: 'بستانکاری: شامل مجموع واریزهای نقدی یا آنلاین، سفارش‌های حذفی یا مرجوعی و برگشت پول (Cashback یا تخفیف ماهیانه) می‌باشد.',
            debtor: 'بدهکار',
            debtorDescription: 'بدهکاری: شامل کل سفارش‌ها و بدهی‌ها می‌باشد.',
            grantedCredit: 'اعتبار داده شده',
            grantedCreditDescription: 'اعتبار داده شده: مبلغی است که در هنگام محاسبه‌ی "اعتبار باقی‌مانده" در نظر گرفته می‌شود و به مشتری اجازه می‌دهد تا به میزان آن مبلغ، سفارش خود را به صورت اعتباری (نسیه) تایید کند.',
            amountOfGantedCredit: 'مبلغ اعتبار داده شده',
            queue: 'صف',
            queueDescription: 'سفارش‌ها پس از تایید برای دریافت شماره فاکتور به صف افزوده می‌شوند. در این حالت، مبلغ کل سفارش‌ها از موجودی حساب کسر می‌شود و پس از خروج از صف، این مبلغ به حساب افزوده می‌شود.',
            remainingLimitResultMessage: 'نتیجه به شرح زیر است:',
            usersFeedback: "بازخوردهای کاربر",
            profilePicture: 'عکس پروفایل',
            withGrantedCredit: 'با اعتبار داده شده',
            withoutGrantedCredit: 'بدون اعتبار داده شده',
            justGrantedCredit: 'صرفا اعتبار داده شده',
            defaultUserAddress: 'نشانی پیش فرض',
            userProfile: "پروفایل کاربر",
            activateProfile: 'فعال سازی پروفایل',
            deActivateProfile: 'غیرفعال‌سازی پروفایل',
            activing: 'فعال‌سازی',
            deActiving: 'غیرفعال‌سازی',
            downloadQrCode: 'دانلود QR-Code',
            employeesProfile: "پروفایل پرسنل",
            userNotFoundHint: 'هیچ اطلاعاتی از این کاربر در دسترس نیست.'
        },

        storeProduct: {
            productDetail: 'جزییات محصول',
            supplierPrice: 'قیمت تامین کنندگان',
            property: 'مشخصات',
            editStoreProduct: 'ویرایش محصول فروشگاهی',
            addStoreProduct: 'اضافه کردن محصول فروشگاهی',
            localAndRemote: 'داخلی-ریموت',
            showProductIn: 'نمایش محصول در',
            local: 'داخلی',
            remote: 'ریموت',
            createTimeFrom: 'زمان ساخت از',
            createTimeTo: 'زمان ساخت تا',
            modifyTimeFrom: "زمان ویرایش از",
            modifyTimeTo: "زمان ویرایش تا",
            description: 'توضیحات',
            overview: 'بررسی',
            saveAndNext: 'ذخیره و بعدی',
            saveAndClose: 'ذخیره و بستن',

        },

        buttons: {
            getFromPbx: 'Get From Pbx',
            addRole: 'اضافه کردن نقش',
        },

        orders: {
            firstOrder: 'اولین سفارش',
            lastOrder: 'آخرین سفارش',
            isActive: 'وضعیت',
            serverName: 'نام سرور',
            isActiveRemote: 'وضعیت در ریموت',
            isActiveLocal: 'وضعیت در لوکال',
            extendedServices: 'سرویس‌های اضافه',
            filmFile: 'تعداد فیلم',
            circulation: 'دور چاپ',
            turnaround: 'سرعت چاپ',
            printedSide: 'طرف چاپ',
            category: 'دسته‌بندی',
            printProcessTime: 'زمان آماده شدن سفارش',
            productDetail: 'جزییات محصول',
            active: 'فعال',
            inactive: 'غیرفعال',
            orderQueue: 'سفارش‌های عمومی براساس {0}',
            storeOrderQueue: 'سفارش‌های فروشگاهی براساس {0}',
            orderQueueStatusByServerName: 'سفارش‌های عمومی براساس {0}',
            setActive: 'فعال کردن',
            deactivate: 'غیرفعال کردن',
            startDateFileRegulator: 'زمان شروع رگولاتور فایل',
            serverNameFileRegulator: 'نام سرور رگولاتور فایل',
            estimatedPreparationTime: 'تخمین زمان آماده تحویل',
            businessDay: 'روز کاری',
            groupSheet: 'عمومی',
            store: 'فروشگاهی',
            dedicated: 'اختصاصی',
            readyInTheNext: 'آماده بعد از',
            websiteStatus: 'وضعیت وبسایت',
            nasStatus: 'وضعیت سفارش در سیستم جامع حسابداری (NAS)',
            sheetId: 'شماره سفارش',
            ordersSheet: "Order's Sheet",
            storeOrderDetail: 'جزئیات سفارش فروشگاهی',
            groupSheetOrderDetail: 'جزئیات سفارش عمومی',
            statusInNas: 'وضعیت در NAS',
            statusInWebsite: 'وضعیت در وبسایت',
            sixHours: '6 ساعته',
            groupSheetsOrders: 'سفارش های فرم های عمومی',
        },

        shippings: {
            shipmentDetails: 'جزئیات ارسال',
            courierAddress: 'آدرس پیک',
            address: 'آدرس مشتری',
            shippingRequest: 'درخواست ارسال',
            shippingRequests: 'درخواست های ارسال',
            firstLastShippingRequest: 'اولین/آخرین درخواست ارسال',
            fromTo: 'از {0} تا {1}',
            orderDetailSeries: 'مجموع سری در جزییات سفارش',
            orderSum: 'مجموع تعداد سفارش',
            request: 'درخواست',
            shippingLastStatus: 'آخرین وضعیت ارسال',
            shippingPlan: 'پلن ارسال',
            shippingMethod: 'روش ارسال',
            estimatedDeliveryTimeFrom: 'زمان تقریبی تحویل از',
            estimatedDeliveryTimeTo: 'زمان تقریبی تحویل تا',
            shippingLastStatusTimeFrom: 'زمان آخرین وضعیت ارسال از',
            shippingLastStatusTimeTo: 'زمان آخرین وضعیت ارسال تا',
            shippingRequestTimeFrom: 'زمان درخواست ارسال از',
            shippingRequestTimeTo: 'زمان درخواست ارسال تا',
            estimatedDeliveryTimeToShippingMethod: 'زمان تخمینی تحویل به روش ارسال',
            remainingToEstimatedSendingTimeToShippingmethod: 'زمان باقیمانده تا تحویل به روش ارسال',
            orderCount: 'تعداد سفارش',
            orderDetailSeriesCount: 'مجموع سری در جزییات سفارش',
            shippingLastStatusTime: 'زمان آخرین وضعیت ارسال',
            shippingRequestTime: 'زمان درخواست ارسال',
            calculationBases: 'مبنای محاسبه',
            basedOnSeriesInOrderDetail: 'بر مبنای سری در جزییات سفارش',
            basedOnOrderCount: 'بر مبنای تعداد سفارش',
            inTheWarehouseShippingRequests: 'درخواست‌های ارسال در انبار',
            shippingRequestsQueueByLatestStatuses: 'صف درخواست‌های ارسال بر اساس آخرین وضعیت',
            shippingRequestsQueueByShippingMethods: 'صف درخواست‌های ارسال بر اساس روش های ارسال',
            estimatedSendingTimeTo: 'زمان تقریبی ارسال با {0} ',
        },

        promotions: {
            change: 'تغییر',
            homeTopPromotion1: 'بنر بالای صفحه اصلی 1',
            homeTopPromotion2: 'بنر بالای صفحه اصلی 2',
            middlePromotion1: 'بنر وسط 1',
            middlePromotion2: 'بنر وسط 2',
            middlePromotion3: 'بنر وسط 3',
            sidebarPromotion1: 'بنر سایدبار 1',
            sidebarPromotion2: 'بنر سایدبار 2',
            blogNewsPromotion1: 'بنر وبلاگ و اخبار 1',
            blogNewsPromotion2: 'بنر وبلاگ و اخبار 2',
            homeAboveTheAboutPromotion1: 'صفحه اصلی بالای قسمت درباره ما 1',
            homeAboveTheAboutPromotion2: 'صفحه اصلی بالای قسمت درباره ما 2',
            homeAboveTheAboutPromotion3: 'صفحه اصلی بالای قسمت درباره ما 3',
        },

        payments: {
            increaseCredit: 'افزایش اعتبار',
            orderPayment: 'پرداخت سفارش',
            success: 'موفق',
            open: 'نا مشخص',
            failed: 'ناموفق',
            onlinePayment: "پرداخت آنلاین",
            usedByBalance: "استفاده شده از موجودی",
            managerPermit: "اجازه مدیریت",
            usedByDepositCredit: "استفاده شده توسط اعتبار سپرده",
            missingIncreaseCreditInNas: 'عدم درج سند افزایش اعتبار',
            someOrdersNotConfirmed: 'عدم تایید برخی سفارش‌ها',
            inAdvanceOrderNotFilled: 'پیش پرداخت پر نشده است.',
            moreThanOneSuccessfulPayment: 'بیش از یک پرداخت موفق'
        },

        announcement: {
            announcementDetail: 'جزییات اطلاعیه',
            newAnnouncement: 'اطلاعیه جدید',
            editAnnouncement: 'ویرایش اطلاعیه‌',
            displayAsModal: 'نمایش بصورت پنجره بازشو (Pop-up)',
            linkText: 'عنوان لینک'
        },

        advertisement: {
            newAdvertisement: 'تبلیغ جدید',
            editAdvertisement: 'ویرایش تبلیغ',
        },

        post: {
            summary: 'خلاصه',
            subject: 'موضوع',
            commentType: 'نوع نظر',
            newPost: 'پست جدید',
            editPost: 'ویرایش پست',
            postImage: 'تصویر پست',
            category: "دسته‌بندی",
            allowWithAdminConfirmation: "با تأیید مدیر اجازه دهید",
            allow: "مجاز",
            disable: "غیر فعال",
            recentPosts: 'آخرین مطالب',
            popularPosts: 'محبوبترین مطالب',
            tags: 'برچسب‌ها',
            tagsInfo: 'برچسب‌ها را با - یا , از هم جدا کنید.',
            showInWebsite: 'نمایش در وبسایت',
        },

        subject: {
            newSubject: 'موضوع جدید',
            editSubject: 'ویرایش موضوع',
            newCategory: 'دسته‌بندی جدید',
            editCategory: 'ویرایش دسته‌بندی',
            showInMainPage: 'نمایش در صفحه اصلی'
        },

        singlePage: {
            newAgreement: 'توافقنامه جدید',
            editAgreement: 'ویرایش توافقنامه',
            approvalUsers: 'کاربران تایید کرده',
            activeAgreement: 'توافقنامه فعال',
            agreementDetail: 'جزئیات توافقنامه',
            agreementStatus: 'وضعیت توافقنامه',
            activatingAgreement: 'فعال سازی توافقنامه',
            usersAccepted: 'کاربران قبول کرده‌ی توافقنامه',
            acceptedTimeFrom: 'تاریخ قبول کردن از',
            acceptedTimeTo: 'تاریخ قبول کردن تا',
            selectedAgreement: 'توافقنامه انتخابی',
            agreementAttention: 'با فعالسازی این توافقنامه، توافقنامه فعال فعلی غیرفعال می‌شود.',
            acceptedTime: 'تاریخ قبول کردن',
        },

        slider: {
            newSlider: 'اسلایدر جدید',
            editSlider: 'ویرایش اسلایدر',
            sliderImage: 'تصویر اسلایدر',
            setToActive: 'فعال شود',
            setToInactive: 'غیر فعال شود',
            active: 'فعال',
            summary: 'خلاصه',
            imageUrl: 'لینک عکس',
            summary: 'خلاصه',
            contentVisibilityMode: 'حالت نمایش محتوا',
            onlyActiveLinkInLoggingMode: 'صرفا برای کاربران وارد شده در سایت لینک فعال شود'
        },

        standardSizesForUpload: {
            minAndMaxDimensions: 'حداقل و حداکثر ابعاد : ',
            fileSize: 'فایل خود را به عنوان Save For Web خروجی بگیرید.',
            uploadYourFileCover: 'کاور فایل را آپلود کنید.'
        },

        promotion: {
            newPromotion: 'تبلیغ جدید',
            editPromotion: 'ویرایش تبلیغ',
            promotionImage: 'تصویر تبلیغ'
        },

        contactInfo: {
            availableTimes: 'ساعات کاری',
            website: 'وبسایت',
            email: 'ایمیل',
            hotline: 'خط تلفن',
            googleMapLink: 'لینک نقشه گوگل',
            address: 'نشانی',
        },

        orderStateType: {
            notApplied: 'در انتظار تایید (Not Applied)',
            inFirstQueue: 'در صف آماده سازی (In First Queue)',
            prepare: 'آماده سازی (Prepare)',
            inProgress: 'ارسال به چاپ خانه (In Progress)',
            layouting: 'چیدمان (Layouting)',
            progressFail: 'خطا در ارسال به چاپ خانه (Progress Fail)',
            prepareFail: 'خطا در آماده سازی (Prepare Fail)',
            lithography: 'لیتوگرافی (Lithography)',
            printing: 'در حال چاپ (Printing)',
            cutting: 'برش و بسته‌بندی (Cutting)',
            ready: 'آماده تحویل (Ready)',
            delivered: 'تحویل داده شده (Delivered)',
            inSecondQueue: 'در صف ارسال به چاپ خانه (In Second Queue)',
            canceled: 'انصراف (Canceled)',
            preparing: 'در حال آماده سازی (Preparing)',
            failed: 'ناموفق (Failed)',
            inProcess: "در پروسه تولید (In Process)",
            cart: "سبد خرید (Cart)",
            archive: "بایگانی (Archive)",
            readyToDeliver: 'آماده تحویل (Ready To Deliver)',
            transferToWarehouse: 'انتقال به انبار (Transfer To Warehouse)'
        },

        communicationChannel: {
            phoneNumber: 'شماره تلفن',
            facebook: 'فیسبوک',
            instagram: 'اینستاگرام',
            whatsAppPhoneNumber: 'شماره واتساپ',
            youTube: 'یوتیوب',
            whatsApp: 'واتساپ',
        },

        metaTags: {
            title: 'عنوان',
            keyWords: 'کلیدواژه‌ها',
            description: 'توضیحات',
            organizationTitle: 'عنوان سازمان',
            organizationType: 'نوع سازمان',
            organizationImage: 'تصویر سازمان',
            organizationDescription: 'توضیحات سازمان',
            organizationSiteName: 'نام وبسایت سازمان',
            organizationEmail: 'ایمیل سازمان',
            organizationPhoneNumber: 'شماره تلفن سازمان',
            organizationFaxNumber: 'شماره فکس سازمان',
            organizationLocation: 'موقعیت سازمان',
            organizationStreetAddress: 'نشانی خیابان سازمان',
            organizationLocality: 'محل سازمان',
            organizationRegion: 'منطقه سازمان',
            organizationPostalCode: 'کدپستی سازمان',
            countryName: 'نام کشور',
            subject: 'موضوعات',
            author: 'نویسنده',
            replyTo: 'پاسخ دادن به',
            url: 'نشانی اینترنتی',
            identifierUrl: 'شناسه نشانی',
        },

        signupAgeRules: {
            minAge: 'کمترین سن برای ثبت نام',
            maxAge: 'بیشترین سن برای ثبت نام'
        },

        telecommunication: {
            sahandSMS: 'Sahand SMS',
            username: 'نام کاربری',
            password: 'گذرواژه',
            fromNumber: 'از شماره',
            senderID: 'شناسه فرستنده',
            baseApiURL: 'Base Api URL',
            smartSMS: 'پیامک هوشمند',
            smtp: 'SMTP',
            host: 'میزبانی',
            port: 'Port',
            useDefaultCredential: 'از اعتبار پیش فرض استفاده کنید'

        },

        notificationConfig: {
            signInNotification: 'اطلاع رسانی ورود',
            sms: 'پیامک:',
            email: 'ایمیل:',
            newOrderNotification: 'اطلاع رسانی سفارش جدید'
        },

        supportLanguage: {
            primaryLanguage: 'زبان اولیه',
            fa: 'فارسی'
        },

        fileManagerConfig: {
            maxSpace: 'حداکثر فضا برای هر کاربر (MB)'
        },

        agency: {
            productPriceChange: 'تغییر قیمت محصول / تخفیف',
            agencyInfo: 'اطلاعات نمایندگی',
            signedInUser: 'کاربران وارد شده',
            signedInUserWithoutAgency: 'بدون نمایندگی',
            signedInUserWithSpecificAgencies: 'با نمایندگی خاص',
        },

        mapConfig: {
            mapApiKeys: 'Map API Keys',
            leaflet: 'Leaflet',
            googleMap: 'Google Map'
        },

        festivals: {
            festivalName: 'نام جشنواره',
            festivalStatus: 'وضعیت جشنواره',
            codeCount: 'تعداد کدها',
            prizesCount: 'تعداد جوایز',
            startDate: 'تاریخ شروع',
            endDate: 'تاریخ پایان',
            executionDate: 'تاریخ اجرا',
            startDateFrom: 'تاریخ شروع از',
            startDateTo: 'تاریخ شروع تا',
            endDateFrom: 'تاریخ پایان از',
            endDateTo: 'تاریخ پایان تا',
            executionDateFrom: 'تاریخ اجرا از',
            executionDateTo: 'تاریخ اجرا تا',
            active: 'فعال',
            done: 'انجام شده',
            salesApp: 'برنامه فروش',
            executionType: 'نوع اجرا',
            createDate: 'تاریخ ایجاد',
            invoiceType: 'نوع فاکتور',
            removeWinnerType: 'نوع حذف برنده',
            pointingType: 'نوع امتیاز دهی',
            winners: 'برندگان',
            prizes: 'جوایز',
            winnerName: 'نام برنده',
            prizeName: 'نام جایزه',
            winsCode: 'کد برنده',
            dateOfWinning: 'زمان برنده شدن',
            prizePhoto: 'تصویر جایزه',
            count: 'تعداد',
            code: 'کد',
            prizeWinner: 'برندگان جایزه',
            mobileNumber: 'شماره موبایل',
            minBuyAmount: 'حداقل مبلغ خرید برای شرکت در قرعه کشی'
        },

        customersFiles: {
            fileName: "نام فایل",
            fileFormat: "فرمت فایل",
            fileSize: "حجم فایل",
            fileSizeFrom: "حجم فایل از",
            fileSizeTo: "حجم فایل تا",
            fileDimension: "ابعاد فایل",
            createTimeFrom: 'زمان ساخت از',
            createTimeTo: 'زمان ساخت تا',
            draftFile: 'فایل پیش نویس',
            thumbnailFile: 'فایل بند انگشتی',
            downloadFile: ' دانلود فایل اصلی',
            filesOrder: 'جزئیات سفارش',
            fileResolution: 'وضوح فایل'
        },

        dashboard: {
            financialDashboard: 'داشبورد مالی',
            businessDashboard: 'داشبورد تجاری',
            dashboardAgencies: 'داشبورد نمایندگی‌ها',
            auditDashboard: 'داشبورد ممیزی ها',
            smsPanelsCreditDashboard: 'داشبورد اعتبار پنل‌های پیامک',
            usersDashboard: 'داشبورد کاربران',
            financial: 'مالی',
            smsPanelsCredit: 'اعتبار پنل‌های پیامک',
            business: 'تجاری',
            users: 'کاربران',
            agencies: 'نمایندگی‌ها',
            audits: 'ممیزی‌ها',
            feedbacks: 'بازخورد‌ها',
            cashback: 'برگشت پول',
            lastJoinedUsers: 'آخرین کاربران اضافه شده',
            usersWithGrantedCredit: 'کاربران براساس میزان اعتبار باقیمانده',
            totalUsers: 'تمام کاربران',
            totalSales: 'مجموع فروش آنلاین',
            allOrders: 'تمام سفارش‌ها',
            onlinePaymentStatus: 'وضعیت پرداخت‌های آنلاین',
            totalOnlinePayments: 'تمام پرداخت‌های آنلاین بر اساس سرعت چاپ',
            onlinePaymentsStatus: 'وضعیت پرداخت‌های آنلاین',
            onlinePaymentsGateway: 'درگاه‌های پرداخت آنلاین',
            lastOrder: 'آخرین سفارش',
            orderByApplicationType: 'تمام سفارش‌ها بر اساس برنامه ثبت سفارش',
            totalAmount: 'مجموع مبلغ',
            totalSaleDes: 'فروش کل شامل تمام سفارش‌هایی می‌شود که مشتریان دارای حساب کاربری چه به صورت حضوری چه به صورت آنلاین در بازه ی زمانی مشخص ثبت کرده اند. آن دسته از مشتریانی که فاقد حساب کاربری در سایت هستند و سفارش حضوری داده اند شامل فروش کل نمی شوند.',
            totalPaymentDes: 'پرداخت کل شامل تمام سفارش‌هایی می‌شود که مشتریان دارای حساب کاربری در بازه زمانی مشخص چه بصورت آنلاین و چه بصورت حضوری پرداخت نموده اند. پرداخت آن دسته از سفارش‌هایی که فاقد حساب کاربری در سایت هستند و بصورت حضوری ثبت شده اند شامل پرداخت کل نمی گردد.',
            totalDiscountDes: 'مجموع ارزش کل شامل کل تخفیف‌های اعمال شده برروی محصول‌های سفارش داده شده دارای تخفیف در بازه زمانی مشخص را نشان می‌دهد.',
            totalVatDes: 'مبلغ مالیات کل شامل مالیات بر فروش کل در بازه ی زمانی مشخص می‌باشد.',
            totalSalesDfChartDes: 'این نمودار نشان دهنده فروش کل آنلاین در بازه زمانی انتخابی می‌باشد.',
            totalOnlinePaymentsChartDes: 'این نمودار نشان دهنده پرداخت‌های موفق، ناموفق و نامشخص می‌باشد. شما می‌توانید وضعیت پرداخت‌ها را در بازه ی زمانی مشخص نیز مشاهده نمایید. همچنین شما می‌توانید داده‌های مورد نظر را به تفکیک درگاه بانکی مورد نظر نیز مشاهده نمایید.',
            onlinePaymentsIpgChartDes: 'این نمودار نشان دهنده وضعیت پرداخت‌ها به تفکیک موفق، ناموفق و نامشخص در بازه زمانی انتخابی می‌باشد.',
            totalSalesDbChartDes: 'این نمودار نشان دهنده کل سفارش‌ها اعم از پرداخت شده و نشده است که به روش حضوری، سفارش حرفه‌‌ای و معمولی در بازه زمانی مشخص انجام شده اند.',
            totalOnlinePaymentsDbChartDes: 'این نمودار نشان دهنده پرداخت‌هایی است که فقط به صورت آنلاین به تفکیک سفارش‌های فوری و معمولی انجام شده است.',
            totalUsersChartDes: 'این نمودار نشان دهنده تعداد کاربران جدیدی است که در بازه انتخابی در سامانه فروش آنلاین ثبت نام نموده و نیز کاربرانی که در همان بازه اقدام به ثبت سفارش نموده اند.',
            orderByApplicationTypeDbChartDes: '',
            orderByTurnaroundDbChartDes: '',
            orderByApplicationTypeDfChartDes: '',
            orderByTurnaroundDfChartDes: '',
            newSubmitted: 'اخیرا افزوده شده',
            popularAgencies: 'نمایندگان محبوب',
            state: "استان",
            usersCount: "تعداد کاربران",
            ordersAmount: "میزان سفارش‌ها",
            companyPriceChangePercent: "تغییر قیمت",
            agancyDiscountPercent: "تخفیف نماینده",
            stateInfoOnMap: "استان {0}: {1}",
            noStateInfoOnMap: "اطلاعاتی برای این استان وجود ندارد",
            payments: 'پرداخت ها',
            mismatch: 'ناهمسان ها',
            audited: 'ممیزی شده ',
            auditedSubTitle: '{0} ممیزی شده ها + ممیزی نشده ها(پرداختهای موفق)',
            paymentNotFound: 'پرداخت های یافت نشده',
            paymentNotFoundSubTitle: '{0} فایل پرداختی ها',
            lastUploader: 'آخرین بارگذاری ها',
            gatewayResponseTypes: 'پاسخ های درگاه',
            successNotProcess: 'ممیزی نشده (پرداختهای موفق)',
            ordersQueue: 'صف سفارشات',
            feedbackDashboard: 'داشبورد بازخوردها',
            cashbackDashboard: 'داشبورد برگشت پول (Cashback)',
            province: 'استان',
            customer: 'مشتری',
            totalFeedback: 'مجموع بازخوردها',
            status: 'وضعیت',
            comments: 'نظرها',
            min: 'حداقل',
            avg: 'میانگین',
            max: 'حداکثر',
            lastFeedback: 'آخرین بازخوردها',
            customerCashback: 'برگشت پول مشتری',
            cashbackDetail: 'جزئیات برگشت پول',
            feedbackDetails: 'جزئیات بازخورد',
            productsInfo: "جزئیات محصول",
        },

        download: {
            fileTitle: 'عنوان فایل',
            category: "دسته‌بندی",
            createTimeFrom: "تاریخ ساخت از",
            createTimeTo: "تاریخ ساخت تا",
            status: "وضعیت",
            releaseDate: 'تاریخ انتشار',
            version: "نسخه",
            newFile: 'فایل جدید',
            editFile: 'ویرایش فایل',
            cssClass: "Css کلاس",
            attachFile: "پیوست فایل",
            attachLink: "پیوست لینک",
            uploadFile: "بارگذاری فایل",
            details: "جزئیات",
            files: "فایل‌ها",
            saveAndNextStep: "ذخیره و رفتن به مرحله بعد",
            editCategory: 'ویرایش دسته‌بندی',
            newCategory: 'ایجاد دسته‌بندی',
            clearByChangingTab: 'در صورت تغییر زبانه فایل آپلود شده شما حذف می‌شود، آیا می‌خواهید ادامه دهید؟'
        },

        audit: {
            notProcessed: 'پردازش نشده',
            paymentNotFound: 'پرداخت یافت نشد',
            amountMismatch: 'عدم تطابق مبلغ',
            stateTypeMismatch: 'عدم تطابق وضعیت تراکنش',
            amountAndStateTypeMismatch: 'عدم تطابق وضعیت تراکنش و مبلغ',
            matched: 'همسان',
            alternativePaymentAttempt: 'تلاش های دیگر پرداخت',
            auditpaymentHistory: 'تاریخچه پرداخت های ممیزی',
            auditpaymentAttempt: 'تلاش‌های پرداخت | ممیزی پرداخت',
            importedFileDetail: 'اطلاعات فایل بارگذاری شده',
            isConfirmed: 'تائید شده',
            isNotConfirmed: 'تائید نشده',
            guidanceTitle: 'فایلی که می‌خواهید آپلود کنید باید با فرمت مشخص شده مطابق باشد.',
            guidanceGatewayFile: 'راهنمای {0}',
            updateMatchingStatus: 'بروزرسانی وضعیت تطابق',
            updateRecordsMatchingStatus: 'بروزرسانی {0} ردیف وضعیت تطابق',
            updateRecordsMatchingStatusTooltip: 'برای به‌روزرسانی «وضعیت تطابق»، ابتدا باید «درگاه پرداخت» مورد نظر انتخاب شود. سپس با استفاده از فیلترهای جستجو مورد نیاز دیگر انتخاب و با کلیک بر روی دکمه جستجو، نتایج نمایش داده می شود. حال می توان با کلیک بر روی دکمه «به‌روزرسانی وضعیت تطابق»، اقدام به بروزرسانی وضعیت تطابق نتایج فیلترشده، نمود.',
            changeOpenStatusToFail: 'تغییر وضعیت نامشخص وبسایت به ناموفق',
            basicMode: 'نرمال',
            advanceMode: 'پیشرفته',
            attempts: 'تلاشهای مجدد',
            history: 'تاریخچه',
            details: 'جزییات',
            oldWebiteStoreProduct: 'وب سایت قدیم - محصول های فروشگاهی',
        },

        productContent: {
            productContentDetail: 'جرییات محتوای محصول',
            metaKeyword: 'کلمات کلیدی',
            metaKeywordInfo: 'کلمات کلیدی را با - یا , از هم جدا کنید.',
            metaDescription: 'توضیحات متا',
            overview: 'نقد و بررسی',
            faqs: 'سوالات متداول'
        },
        feedback: {
            feedback: 'بازخورد',
            feebackDetail: 'جزییات بازخورد',
            feedbackOrders: 'بازخورد کاربران به سفارش‌ها',
            feedbackUsers: 'بازخورد کاربران به پرسنل',
            feedbackAnnouncements: 'بازخورد کاربران به اطلاعیه‌ها',
            metricsDetails: 'معیارها',
            ratingParameter: 'شاخص‌های امتیازدهی',
            demandService: 'پشتیبانی بیشتر',
            strengths: 'نقاط قوت',
            weaknesses: 'نقاط ضعف',
            feedbackType: 'نوع بازخورد',
            newFeedbackMetric: 'افزودن {0}',
            editFeedbackMetric: 'ویرایش {0}',
            overall: 'همه',
            pending: 'بررسی نشده',
            inprocess: 'در حال پیگیری',
            archive: 'بایگانی (انجام شده)',
            callMe: 'با من تماس بگیر',
            originalComment: 'نظر اصلی',
            comment: 'نظر',
            activityHistory: 'تاریخچه فعالیت',
            sendFeedback: 'بازخورد',
            newActivity: 'تغییر وضعیت',
            activityState: 'وضعیت',
            feedbackTo: 'بازخورد به {0}',
            changeStatus: 'تغییر وضعیت',
            displayNottoAll: 'عدم نمایش به همه',
            orderDetail: 'جزییات سفارش',
            hasDemandService: 'دارای پشتیبانی',
            hasComment: 'دارای نظر',
            feedbackToUser: 'بازخورد به کاربر',
            thisMessageIsVisibleToUser: 'این پیام برای کاربر قابل مشاهده است.',
            employees: 'پرسنل',
            lastUserFeedbackStateTypeId: 'آخرین وضعیت بازخورد',
            customerFeedbackTo: 'بازخورد کاربر به'
        },
        cashback: {
            currentMonth: 'ماه جاری',
            currentMonthWithVs: 'مقایسه ماه جاری با ',
            lastMonthsOfThisYear: 'از شروع سال جاری تا قبل از ماه جاری',
            totalSalesAmountEligibleForCashback: 'کل مبلغ فروش واجد شرایط برگشت پول',
            totalCashbackAmount: 'مبلغ کل برگشت پول',
            qtyOfCustomersIncludedInCashback: 'تعداد مشتریان شامل برگشت پول',
            reachedAtLeastTheFirstCashbackPlan: '(حداقل به اولین پلن برگشت پول رسیده‌اند)',
            specialVsCommon: 'مقایسه مشتریان با پلن ویژه و پلن عمومی (واجد شرایط برگشت پول)',
            eligibleForCashback: '(واجد شرایط برگشت پول)',
            customersCommonPlans: 'مشتریان با پلن برگشت پول عمومی',
            qtyOfCustomers: 'تعداد مشتریان',
            totalSalesAmount: 'مبلغ کل فروش',
            totalCashbackAmount: 'مبلغ کل برگشت پول',
            avgSalesAmount: 'میانگین مبلغ فروش',
            avgCashbackPercentage: 'میانگین درصد برگشت پول',
            customersSpecialPlan: 'مشتریان با پلن برگشت پول ویژه',
            cashbackPlans: 'پلن برگشت پول (درصد)',
            qtyOfCustomersNotReached: 'تعداد مشتریانی که به اولین پلن برگشت پول نرسیده اند',
            withSubmittedOrders: '(اما دارای سفارش هستند)',
            totalSalesAmountOfCustomersWhoHaveNotReached: 'مبلغ فروش به مشتریانی که به اولین پلن برگشت پول نرسیده اند ',
            common: 'پلن عمومی',
            special: 'پلن ویژه',
            noPlan: 'بدون پلن',
            withoutCashback: 'فاقد برگشت پول',
            cashbackAmount: 'مبلغ برگشت پول',
            purchaseAmount: 'مبلغ خرید',
            qtyOfCustomers: 'تعداد مشتریان',
            basedOn: 'بر اساس',
            from: 'از',
            to: 'تا',
            vs: '{0} در مقایسه با {1}',
            sumNetSale: 'مبلغ کل فروش',
            totalCashbackAmount: 'مبلغ کل برگشتی',
            countAchievedUserToDiscount: 'تعداد مشتریان در پلن برگشت',
            customerWithSpecialPlan: 'مشتریان پلن ویژه',
            customerWithCommonPlan: 'مشتریان پلن عمومی',
            countUser: 'تعداد مشتریان',
            averageSaleAmount: 'میانگین مبلغ فروش',
            averageCashbackPercentage: 'میانگین درصد برگشت پول',
            qtyOfCustomersNotReachedTheCashbackPlan: 'تعداد مشتریانی که به پلن اول نرسیده اند',
            totalSalesOfCustomersNotReachedTheCashbackPlan: 'فروش کل مشتریانی که به پلن اول نرسیده اند'
        }

    }
};

export default AdminPanelTranslationFa;