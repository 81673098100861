import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsEtiquette from '../../../../components/etiquette/NsEtiquette';
import NsPunchArea from '../../../../components/punchArea/NsPunchArea';
import NsTypography from '../../../../components/text/NsTypography';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ImageHelper from '../../../../utilities/ImageHelper';

class PunchContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            top: null,
            left: null,
            pixelTop: null,
            pixelLeft: null,
            pointDiameter: null,
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        const { resultData } = newProps;
        if (resultData !== this.props.resultData)
            this.setState({
                top: resultData.verticalPoint,
                left: resultData.horizontalPoint,
                pixelTop: ImageHelper.convertMillimeterToPixel(resultData.verticalPoint, this.props.resolution) * resultData.ratio,
                pixelLeft: ImageHelper.convertMillimeterToPixel(resultData.horizontalPoint, this.props.resolution) * resultData.ratio,
                pointDiameter: ImageHelper.convertMillimeterToPixel(resultData.pointDiameter, this.props.resolution) * resultData.ratio,
            });
    }

    render() {

        const { top, left, pixelLeft, pixelTop, pointDiameter } = this.state;
        const { isLoading, draft } = this.props;

        return (
            <Row className='justify-content-center'>
                <Col md={8} >
                    <NsPunchArea imgSrc={draft}
                        setRatio={this.props.setRatio}
                        wrapperClassName='position-relative'
                        punches={[{ top: pixelTop, left: pixelLeft }]}
                        top={pixelTop}
                        left={pixelLeft}
                        pointDiameter={pointDiameter} />
                </Col>

                <Col xs={{ span: 12, order: 'first' }} md={{ span: 4, order: 'last' }} className='mb-2'>
                    <PunchList top={top}
                        left={left}
                        isLoading={isLoading} />
                </Col>
            </Row>
        );
    }
}

function PunchList(props) {
    let style = 'background-outline-color';
    let border = 'border-outline-color-1px';
    let errorText = '';
    const { top, left, isLoading } = props;

    return (<NsEtiquette style={style}
        border={border}
        errorText={errorText}
        index={0}
        title={getTranslatedRawText('regularOrder.extendedService.punch')}
        isLoading={isLoading}
        deleteEtiquette={props.deletePunch}>

        <div className='d-flex flex-column justify-content-between mx-3 my-2'>
            <NsTypography variant='inherit' className='font-size-14'>
                {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.topPunch'), top?.toFixed(2))}
            </NsTypography>
            <NsTypography variant='inherit' className='font-size-14'>
                {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.leftPunch'), left?.toFixed(2))}
            </NsTypography>
        </div>
    </NsEtiquette>
    );
}

export default PunchContent;