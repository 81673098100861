import React from 'react';

import NsCard from '../../../components/cards/NsCard';
import NsTypography from '../../../components/text/NsTypography';
import Config from '../../../configs/Config';
import Enumerations from '../../../configs/Enumerations';
import { getCurrentCulture, getTranslatedRawText } from '../../../utilities/CultureHelper';
import { getEnumKeyFromValue } from '../../../utilities/EnumerationHelper';

class SpSidebarTestimonial extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            testimonials: getCurrentCulture() === getEnumKeyFromValue(Enumerations.culture, Enumerations.culture.fa)
                ? Config.testimonials.fa
                : Config.testimonials.en,
            userAvatar: null,
        }
    }

    getRandomTestimonial = () => {
        const index = Math.floor(Math.random() * this.state.testimonials.length);
        return this.state.testimonials[index]
    }

    render() {
        const testimonial = this.getRandomTestimonial();
        return (
            !!testimonial
                ? <div className="mb-3">
                    <NsCard className='sidebar__testimonial p-3'>
                        <div className='mb-3'>
                            <NsTypography variant='inherit' className='section-title'>
                                {getTranslatedRawText('footer.testimonial')}
                            </NsTypography>
                        </div>
                        <div className='sidebar__testimonial--border'></div>
                        <div className='d-flex flex-column justify-content-center align-items-center pt-4'>
                            <Avatar userAvatar={this.state.userAvatar} />
                            <div className='py-3 fw-bold'>
                                {testimonial?.name}
                            </div>
                            <div className='d-flex align-items-start'>
                                <p className='light-text-color'>
                                    {testimonial?.message}
                                </p>
                            </div>
                        </div>

                    </NsCard>
                </div>
                : null
        );

    }
}

function Avatar(props) {
    return props.userAvatar
        ? (<img src={props.userAvatar}
            className="d-block rounded-circle border"
            alt={getTranslatedRawText("components.imagesAlt.testimonialAvatar")}
            style={{ width: '85px', height: '85px' }} />)
        : <i className='ns-icon-avatar sidebar__testimonial--avatar-icon background-outline-color white-color ' />
}

export default SpSidebarTestimonial;