import Links from '../../configs/links/AllLinks';
import CommonHelper from '../../utilities/CommonHelper';
import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import { GetCategoriesService, GetStoreCategoriesService } from './../../services/common/CommonService';

const GETCATEGORIES = 'GET_CATEGORIES';
const GETSTORECATEGORIES = 'GET_STORE_CATEGORIES';

const initialState = { categories: [], storeCategories: [] };

export const categoryActionCreators = {
    getCategories: () => async (dispatch, getState) => {
        GetCategoriesService((resultData, result, messageClassModal) => {
            if (result?.data?.hasError)
                return;
            resultData.map(item => {
                const itemNormalizer = (item) => {
                    if (isEmpty(item.children)) {
                        item.key = item.id,
                            item.id = !isEmpty(item.id) ? item.id : 0,
                            item.value = item.name,
                            item.show = true,
                            item.path = CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main, Links.view.main.productsByCategory)
                                , item.id, item.name.replace(/ /g, '-').toLowerCase());
                        item.itemClassName = 'mt-3'
                    } else item.children.map(item => itemNormalizer(item))
                }
                return itemNormalizer(item)
            });
            dispatch({ type: GETCATEGORIES, categories: resultData });
        });
    },
    getStoreCategories: () => async (dispatch, getState) => {
        GetStoreCategoriesService((resultData, result, messageClassModal) => {
            if (result?.data?.hasError)
                return;
            resultData.map(item => {
                const itemNormalizer = (item) => {
                    if (isEmpty(item.children)) {
                        item.key = !isEmpty(item.id) ? item.id : 1000000,
                            item.id = !isEmpty(item.id) ? item.id : 1000000,
                            item.value = item.name,
                            item.show = true,
                            item.path = CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main, Links.view.main.productsByCategory)
                                , item.id, item.name.replace(/ /g, '-').toLowerCase());
                        item.itemClassName = 'mt-3'
                    } else item.children.map(item => itemNormalizer(item))
                }
                return itemNormalizer(item)
            });
            dispatch({ type: GETSTORECATEGORIES, storeCategories: resultData });
        });
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GETCATEGORIES:
            if (state.categories.length === 0)
                return {
                    ...state,
                    categories: action.categories
                }
            return state;
        case GETSTORECATEGORIES:
            if (state.storeCategories.length === 0)
                return {
                    ...state,
                    storeCategories: action.storeCategories
                }
            return state;

        default:
            return state;
    }
}

