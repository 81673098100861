import React from 'react';
import { useEffect, useState, useCallback } from 'react';

import NsModal from '../../../../../../components/modals/NsModal';
import Enumerations from '../../../../../../configs/Enumerations';
import { GetCurrentActiveUserAgreementService } from '../../../../../../services/adminPanel/AdminAgreementService';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import NsTab from '../../../../../../components/tabs/NsTab';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import NsSkeleton from '../../../../../../components/skeletons/NsSkeleton';
import ApLanguageTabs from '../../../../bases/ApLanguageTabs';
import NsBasicInfoLabelBox from '../../../../../../components/labels/NsBasicInfoLabelBox';
import NsHtmlToReact from '../../../../../../components/htmlToReact/NsHtmlToReact';
import NsLabelNoData from '../../../../../../components/labels/NsLabelNoData';
import { getAgreementBasicInfos } from '../../../../../../utilities/BasicInfoHelper';


const ApCurrentActiveAgreementDetailModal = ({ agreementId, closeModal }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [agreementDetail, setAgreementDetail] = useState([]);
    const [selectedStaticPageTabIndex, setSelectedStaticPageTabIndex] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [selectedStaticPageType, setSelectedStaticPageType] = useState(Enumerations.staticPageType.termOfUse);

    useEffect(() => {
        serviceCall();
    }, [selectedLanguage])

    const serviceCall = () => {
        setIsLoading(true);
        GetCurrentActiveUserAgreementService({
            id: agreementId,
            languageType: selectedLanguage?.id
        }, serviceCallback)
    }

    const serviceCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setAgreementDetail(resultData[0].content);
        } else {
            setAgreementDetail([]);
        }
    }

    const tabChanged = (tabIndex, selectedTab) => {
        setSelectedStaticPageTabIndex(tabIndex);
        setSelectedStaticPageType(selectedTab.id);
    }

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    return (
        <NsModal maxWidth="lg"
            headerClassName='px-3'
            dialogTitle={getTranslatedRawText('adminPanel.singlePage.agreementDetail')}
            showModal={true}
            noSpace={true}
            closeModal={closeModal}>

            <div className='background-color px-3 py-2'>
                <NsBasicInfoLabelBox basicInfo={getAgreementBasicInfos(agreementDetail)}
                    hasAccessInfo={true}
                    isLoading={isLoading} />
            </div>
            <div className='p-3'>
                <div className='border rounded my-3'>
                    <ApLanguageTabs languageChanged={languageChangedHandler}
                        selectedTabId={selectedLanguage?.id} />
                    <div key={selectedLanguage?.id} className='p-2 pt-0'>{agreementDetail?.title}</div>
                </div>

                <NsTab tabs={ConstantsWithTranslation.getApAgreementsTabs()}
                    value={selectedStaticPageTabIndex}
                    onChange={tabChanged} />

                {isLoading
                    ? <div>
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                    </div>
                    : !isEmpty(agreementDetail)
                        ? <AgreementContent agreementDetail={agreementDetail}
                            selectedStaticPageType={selectedStaticPageType} />
                        : <div></div>}
            </div>

        </NsModal>
    );
};

function AgreementContent({ agreementDetail, selectedStaticPageType }) {
    const selectedStaticPageContent = agreementDetail.staticPages.filter(item => item.key === selectedStaticPageType)[0].value
    return <div className='mt-3 min-height-10rem'>
        {!isEmpty(selectedStaticPageContent) ?
            selectedStaticPageContent.map((item, index) =>
                item.isActive
                    ? <p><NsHtmlToReact>{item.mainContent}</NsHtmlToReact></p>
                    : <p><del><NsHtmlToReact>{item.mainContent}</NsHtmlToReact></del></p>
            )
            : <div className='d-flex justify-content-center w-100'>
                <NsLabelNoData label='adminPanel.common.noAnyDataToView' />
            </div>
        }
    </div>
}

export default React.memo(ApCurrentActiveAgreementDetailModal);