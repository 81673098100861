import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { GetApDauAggregateStatisticsService } from "../../../../services/adminPanel/AdminDashboardService";
import ApDauNotProcessSuccessfullyPayments from "./ApDauNotProcessSuccessfullyPayments";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import ApBasesDashboardHeader from "../bases/ApBasesDashboardHeader";
import ApDauMismatchesProgress from "./ApDauMismatchesProgress";
import ApDauPaymentErrorTypes from "./ApDauPaymentErrorTypes";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import Enumerations from "../../../../configs/Enumerations";
import DateHelper from "../../../../utilities/DateHelper";
import ApDauMismatchesGrid from "./ApDauMismatchesGrid";
import ApDauPercentageBox from "./ApDauPercentageBox";
import ApDauLastUploaders from "./ApDauLastUploaders";
import ApDauPayments from "./ApDauPayments";
import ApDauNotProcessed from "./ApDauNotProcessed";

const ApAuditDashboard = (props) => {

    const [dates, setDates] = useState([new Date(DateHelper.getCustomPreviousMonth(2)), new Date()]);
    const [dateRangeType, setDateRangeType] = useState(ConstantsWithTranslation.getDateRanges()[0].key);
    const [refreshDate, setRefreshDate] = useState();
    const [aggregateStatistics, setAggregateStatistics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState();

    const parameters = {
        submitTimeFrom: DateHelper.convertDateTimeToDate(new Date(dates[0])),
        submitTimeTo: dates[1],
        timeIntervalType: dateRangeType
    }

    useEffect(() => {
        hasAccess([UserClaimEnumerations.getApDauAggregateStatistics]) &&
            GetApDauAggregateStatisticsService(parameters, getApDauAggregateStatisticsCallback)
    }, [refreshDate])

    const getApDauAggregateStatisticsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setAggregateStatistics(resultData);
        } else
            setMessageClassModal(messageClassModal);
    };

    const refreshClicked = useCallback((dates, isRefreshDate, dateRange) => {
        setDateRangeType(dateRange);
        setDates(dates);
        if (isRefreshDate) {
            setIsLoading(true);
            setRefreshDate(new Date());
        }
    })

    return (
        <Row className="g-2">
            <Col xs={12} className="mb-3">
                <ApBasesDashboardHeader title='adminPanel.dashboard.auditDashboard'
                    refreshClicked={refreshClicked} />
            </Col>
            {hasAccess([UserClaimEnumerations.getApDauStatisticsBasedOnAuditedAndPaymentStatuses]) &&
                <Col xs={12} >
                    <ApDauPayments parameters={parameters} refreshDate={refreshDate} />
                </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDauStatisticsBasedOnMismatchedTypesOfPaymentAuditMatchingTypes]) &&
                <Col md={4}>
                    <ApDauMismatchesProgress parameters={parameters} refreshDate={refreshDate} />
                </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDauAggregateStatistics]) &&
                <Col md={4}>
                    <div className="d-flex flex-column gap-2">

                        <ApDauPercentageBox title='adminPanel.dashboard.audited'
                            subTitle='adminPanel.dashboard.auditedSubTitle'
                            progressColor='admin-panel__audit-dashboard--circular-progress-audited-color'
                            isLoading={isLoading}
                            data={aggregateStatistics.find(item => item.aggregateStatisticsPaymentAuditType === Enumerations.aggregateStatisticsPaymentAuditType.audited)}
                            refreshDate={refreshDate} />
                        <ApDauPercentageBox title='adminPanel.dashboard.paymentNotFound'
                            subTitle='adminPanel.dashboard.paymentNotFoundSubTitle'
                            progressColor='admin-panel__audit-dashboard--circular-progress-payment-not-found-color'
                            isLoading={isLoading}
                            data={aggregateStatistics.find(item => item.aggregateStatisticsPaymentAuditType === Enumerations.aggregateStatisticsPaymentAuditType.paymentNotFound)}
                            refreshDate={refreshDate} />
                    </div>
                </Col>
            }
            <Col md={4}>
                <div className="d-flex flex-column gap-2">
                    {hasAccess([UserClaimEnumerations.getApPaymentAuditSources]) &&
                        <ApDauLastUploaders parameters={parameters} refreshDate={refreshDate} />
                    }
                    {hasAccess([UserClaimEnumerations.getApDauNotProcessedPaymentAuditCount]) &&
                        <ApDauNotProcessed parameters={parameters} refreshDate={refreshDate} />
                    }
                </div>
            </Col>
            {hasAccess([UserClaimEnumerations.getApPaymentAudits]) &&
                <Col xs={12}>
                    <ApDauMismatchesGrid parameters={parameters} refreshDate={refreshDate} />
                </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDauPaymentGatewaysMessages]) &&
                <Col md={5}>
                    <ApDauPaymentErrorTypes parameters={parameters} refreshDate={refreshDate} />
                </Col>
            }
            {hasAccess([UserClaimEnumerations.getApPayments]) &&
                <Col md={7}>
                    <ApDauNotProcessSuccessfullyPayments parameters={parameters} refreshDate={refreshDate} />
                </Col>
            }
            {messageClassModal}
        </Row >
    );
}

export default React.memo(ApAuditDashboard);