import React from 'react';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsTypography from '../text/NsTypography';

class NsLabelNoData extends React.Component {

    render() {
        const { label } = this.props;

        return <div className='d-flex flex-column align-items-center border rounded background-white-color w-75 py-3 my-3'>
            <i className='ns-icon-empty h1' />
            <NsTypography variant='inherit' className='section-title'>
                {getTranslatedRawText(label)}
            </NsTypography>
        </div >
    }
}

export default NsLabelNoData;