import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import '../../assets/sass/views/blogs/Blogs.scss';
import SpSidebar from './sidebars/BnSidebar';

class BlogNews extends Component {

    componentDidMount = () => window.scrollTo({ top: -200, behavior: 'smooth' });

    render() {
        return (
            <Row className='blogs'>
                <Col lg={8} xxl={9} className='mb-lg-0 mb-3'>
                    {this.props.children}
                </Col>
                <Col lg={4} xxl={3} >
                    <SpSidebar urlParameters={this.props.urlParameters} />
                </Col>
            </Row >
        );
    }
}

export default BlogNews;