import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { GetApDaCbFromInqueryTimesService, GetApDaCbToInqueryTimesService } from '../../../../services/adminPanel/AdminDashboardService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import NsSelect from '../../../../components/dropdowns/NsSelect';
import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

const ApDcbHeader = (props) => {

    const [fromDates, setFromDates] = useState([]);
    const [toDates, setToDates] = useState([]);
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [refreshDate, setRefreshDate] = useState(null);

    useEffect(() => {
        GetApDaCbFromInqueryTimesService(getApDaCbFromInqueryTimesCallback);
    }, [])

    useEffect(() => {
        if (!!selectedFromDate) {
            GetApDaCbToInqueryTimesService({ fromDate: selectedFromDate }, getApDaCbToInqueryTimesCallback);
            CommonHelper.safeFunctionCall(props.refreshClicked, selectedFromDate, selectedToDate);
        }
    }, [selectedFromDate])

    const getApDaCbFromInqueryTimesCallback = (resultData, result) => !result.data.hasError && setFromDates(resultData);

    const getApDaCbToInqueryTimesCallback = (resultData, result) => !result.data.hasError && setToDates(resultData);

    useEffect(() => {
        !!selectedToDate && CommonHelper.safeFunctionCall(props.refreshClicked, selectedFromDate, selectedToDate);
    }, [selectedToDate])

    useEffect(() => {
        !!refreshDate && CommonHelper.safeFunctionCall(props.refreshClicked, selectedFromDate, selectedToDate);
    }, [refreshDate])

    const refreshClicked = () => setRefreshDate(new Date());

    const toDateChanged = (selectedToDate) => setSelectedToDate(selectedToDate);

    const fromDateChanged = (selectedFromDate) => {
        setSelectedFromDate(selectedFromDate);
        setSelectedToDate(null);
        // setSelectedToDate(DateHelper.getEndDateOfMonth(selectedFromDate));
    }

    const clearButtonClicked = () => {
        setSelectedFromDate(null);
        setSelectedToDate(null);
        refreshClicked();
    }

    return (
        <div className={clsx('d-flex ', CommonHelper.isMobileDevice() ? 'flex-column' : 'justify-content-between')}>
            <div className='fw-bold'>{getTranslatedRawText(props?.title)}</div>
            <div>
                <div className={clsx('d-flex align-items-end gap-2', CommonHelper.isMobileDevice() ? 'flex-wrap' : '')} >
                    <div style={{ width: '150px' }}>
                        <NsSelect options={fromDates}
                            label={getTranslatedRawText('adminPanel.cashback.from')}
                            valueChanged={fromDateChanged}
                            notRequired={true}
                            selectedItem={selectedFromDate} />

                    </div>
                    <div style={{ width: '150px' }}>
                        <NsSelect options={toDates}
                            label={getTranslatedRawText('adminPanel.cashback.to')}
                            valueChanged={toDateChanged}
                            notRequired={true}
                            disabled={!selectedFromDate}
                            selectedItem={selectedToDate} />

                    </div>

                    <NsButton className='secondary-outline'
                        loading={props?.isLoading}
                        size='small'
                        onClick={refreshClicked}
                        startIcon='ns-icon-refresh' />

                    {!isEmpty(selectedToDate) && <NsButton size='small'
                        onClick={clearButtonClicked}
                        className='secondary-outline'
                        wrapperClassName='ms-1'
                        loading={props?.isLoading}
                        disabled={props?.isLoading}
                        startIcon='ns-icon-clear' />}
                </div>
            </div>
        </div >
    );
};

export default React.memo(ApDcbHeader);