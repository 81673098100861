import React from 'react';
import RegularOrderWrapper from '../RegularOrderWrapper';

class MainContentWrapper extends React.Component {
    render() {
        return (
            <RegularOrderWrapper otherProps={this.props.otherProps}
                routeId={this.props.routeId}
                toggleTourDate={this.props.toggleTourDate}
                mainStepperActiveStep={this.props.mainStepperActiveStep}
                isCompeteledChoosingFileSteps={this.props.isCompeteledChoosingFileSteps}
                isCompeteledExtendedServiceSteps={this.props.isCompeteledExtendedServiceSteps}
                refreshExtendedServiceSummaryDate={this.props.refreshExtendedServiceSummaryDate}
                refreshFilePreviewSummaryDate={this.props.refreshFilePreviewSummaryDate} >
                {this.props.children}
            </RegularOrderWrapper >
        );
    }
}

export default MainContentWrapper;