import React from 'react';

import NsStickyButton from '../../../../../../components/buttons/NsStickyButton';
import { GetPreparingStoreOrdersService } from '../../../../../../services/ProfileSetting/StoreOrderService';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import UserPanelWrapper from '../../../../UserPanelWrapper';
import UpBaseStoreOrdersGrid from '../bases/UpBaseStoreOrdersGrid';
import UpBaseStoreOrderSearchSection from '../bases/UpBaseStoreOrderSearchSection';
import NsExportExcelReportButton from '../../../../../../components/downloadFiles/NsExportExcelReportButton';

class UpStorePreparingOrdersContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchValues: {},
            isLoading: false,
            resultMessageClass: (<></>),
            callServiceDate: new Date(),
        };
    }

    getData = (rows, isLoading) => this.setState({ isLoading });

    excelReportButtonClicked = () => { }

    searchFormClicked = (searchValues) => this.setState({ searchValues, isLoading: true, callServiceDate: new Date() });

    render() {
        const { searchValues, callServiceDate, isLoading, resultMessageClass } = this.state;

        const mainButtons = [
            { label: "regularOrder.common.exportExcel", icon: 'ns-icon-excel', onClicked: this.excelReportButtonClicked, className: 'secondary-outline', disabled: true },
        ];

        return (
            <div className="w-100">
                <UpBaseStoreOrderSearchSection
                    searchFormClicked={this.searchFormClicked}
                    findOrdersSearchSection
                    autoFocus
                    isLoading={isLoading}>
                </UpBaseStoreOrderSearchSection>

                <NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />

                <div className='w-100 mt-3'>
                    <UpBaseStoreOrdersGrid callServiceDate={callServiceDate}
                        service={GetPreparingStoreOrdersService}
                        parameters={{ filter: searchValues }}
                        hasPagination={true}
                        hasRetry={true}
                        tabChanged={this.tabChanged}
                        gridId={GridEnumerations.siteGrids.preparingStoreOrders}
                        hasCustomColumnOrder={true}
                        getData={this.getData} />
                </div>
                {resultMessageClass}
            </div>
        );
    }
}

export { UpStorePreparingOrdersContent };

const UpStorePreparingOrders = () => {
    return <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.preparing')}
        titleIcon='ns-icon-preparing-order' >
        <UpStorePreparingOrdersContent />
    </UserPanelWrapper>
}

export default UpStorePreparingOrders;

