import React from 'react';
import { withRouter } from 'react-router';

import { ChangeStateFromFailToPreparingService, GetFailedOrdersService, GetPreParingOrdersService, GetFailedOrderCountService, GetPreparingExcelReportService, GetFailedExcelReportService } from '../../../../../../services/ProfileSetting/UserOrderService';
import NsExportExcelReportButton from '../../../../../../components/downloadFiles/NsExportExcelReportButton';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../../utilities/CultureHelper';
import UpBaseGroupSheetMobileRedirectToAllOrders from '../bases/UpBaseGroupSheetMobileRedirectToAllOrders';
import UpBaseGroupSheetOrdersSearchSection from '../bases/UpBaseGroupSheetOrdersSearchSection';
import UpGroupSheetPreparingOrderDetailModal from './UpGroupSheetPreparingOrderDetailModal';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import UpBaseGroupSheetOrdersGrid from '../bases/UpBaseGroupSheetOrdersGrid';
import GridEnumerations from '../../../../../../configs/GridEnumerations';
import NsInfoBox from '../../../../../../components/infoBox/NsInfoBox';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../../../UserPanelWrapper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../../configs/Enumerations';
import Links from '../../../../../../configs/links/AllLinks';
import UrlHelper from '../../../../../../utilities/UrlHelper';
import NsTab from '../../../../../../components/tabs/NsTab';

class PreparingOrdersContent extends React.Component {

    constructor(props) {
        super(props);
        this.tabs = ConstantsWithTranslation.getUserOrderPreparingTabs();
        this.selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);

        this.state = {
            selectedTab: this.selectedTab || this.tabs[1],
            selectedTabIndex: this.tabs.indexOf(this.selectedTab) > -1 ? this.tabs.indexOf(this.selectedTab) : 1,
            tabs: ConstantsWithTranslation.getUserOrderPreparingTabs(),
            callServiceDate: null,
            badgeCount: 0,
            orderId: null,
            showDetailModal: false,
            isLoading: true,
            searchValues: {},
            openFeedbackModalDate: null,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => {
        let pathparams = UrlHelper.checkUrlIsOrderDetailFeedback(window.location.pathname);
        if (pathparams.isOrderDetail) {
            this.detailViewClicked(pathparams.orderId);
            if (pathparams.isOrderDetailFeedback)
                this.setState({ openFeedbackModalDate: new Date() });
        }
        GetFailedOrderCountService(this.getFailedOrderCountCallback);
    }

    tabChanged = (newValue, selectedTab) => newValue !== this.state.selectedTabIndex &&
        this.setState({ selectedTabIndex: newValue, selectedTab, isLoading: true },
            () => !!this.props.history && this.props.history.push(selectedTab.url));

    getFailedOrderCountCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError) {
            const updatedTabs = this.state.tabs.map(tab => ({
                ...tab,
                badgeCount: tab.hasBadgeCount && resultData
            }));
            this.setState({ tabs: updatedTabs, badgeCount: resultData });
        }
        else
            this.setState({ resultMessageClass });
    }

    searchFormClicked = (searchValues) => this.setState({ searchValues, isLoading: true, callServiceDate: new Date() });

    getOrdersFromServer = (rows, isLoading) => this.setState({ isLoading });

    changeStateFromFailToPreparing = (id) => {
        this.setState({ isLoading: true },
            () => ChangeStateFromFailToPreparingService({ id }, this.changeStateFromFailToPreparingCallback)
        );
    }

    changeStateFromFailToPreparingCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            this.setState({ callServiceDate: new Date() });
            GetFailedOrderCountService(this.getFailedOrderCountCallback);
        }
        else
            this.setState({ resultMessageClass });
    }

    detailViewClicked = (id) => {
        this.setState({ showDetailModal: true, orderId: id });
        !!this.props.history && this.props.history.push(CommonHelper.stringFormat(
            getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.preparingDetail), id));
    }

    closeDetailModalClicked = () => {
        this.setState({ showDetailModal: false, openFeedbackModalDate: null });
        this.props?.history && this.props.history.push(CommonHelper.stringFormat(
            getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.preparing)
        ));
    }

    closeDeleteOrderModalClicked = (resultMessageClass) => {
        this.closeDetailModalClicked();
        !isEmpty(resultMessageClass) && this.setState({ callServiceDate: new Date(), resultMessageClass });
        GetFailedOrderCountService(this.getFailedOrderCountCallback);
    }

    closeCancellationOrderModalClicked = (refresh, resultMessageClass) => {
        this.closeDetailModalClicked();
        !!refresh && this.setState({ callServiceDate: new Date(), resultMessageClass });
    }

    render() {
        const { callServiceDate, selectedTabIndex, selectedTab, tabs, isLoading, orderId, showDetailModal,
            resultMessageClass, openFeedbackModalDate, searchValues } = this.state;
        const isPreParing = selectedTab?.id === Enumerations.preparingOrderStateType.preparing;
        return (
            <>
                <UpBaseGroupSheetOrdersSearchSection toggleBasicSearchOptions={this.toggleBasicSearchOptions}
                    searchFormClicked={this.searchFormClicked}
                    findOrdersSearchSection
                    autoFocus
                    isLoading={isLoading}>

                    <NsExportExcelReportButton searchValues={searchValues}
                        isLoading={isLoading}
                        service={isPreParing
                            ? GetPreparingExcelReportService
                            : GetFailedExcelReportService} />

                </UpBaseGroupSheetOrdersSearchSection>

                <NsTab value={selectedTabIndex}
                    tabs={tabs}
                    onChange={this.tabChanged} />

                <UpBaseGroupSheetOrdersGrid callServiceDate={callServiceDate}
                    hasPagination={true}
                    service={isPreParing
                        ? GetPreParingOrdersService
                        : GetFailedOrdersService}
                    parameters={{ filter: searchValues }}
                    getOrdersFromServer={this.getOrdersFromServer}
                    detailViewClicked={this.detailViewClicked}
                    changeStateFromFailToPreparing={this.changeStateFromFailToPreparing}
                    showTotalQuantity={true}
                    gridId={GridEnumerations.siteGrids.preparingGroupSheetOrders}
                    hasCustomColumnOrder={true}
                    showSubmitTime={true} />
                <UpBaseGroupSheetMobileRedirectToAllOrders />

                <NsInfoBox isShow={!isPreParing}
                    title={getTranslatedRawText('profile.common.moreInformation')}
                    subject={<i className='ns-icon-retry h5 m-0'></i>}
                    content={getTranslatedRawText('infos.preparingFailedRefresh')} />

                {showDetailModal &&
                    <UpGroupSheetPreparingOrderDetailModal closeModal={this.closeDetailModalClicked}
                        orderId={orderId}
                        openFeedbackModalDate={openFeedbackModalDate}
                        breadcrumbs={this.props.breadCrumb}
                        closeCancellationOrderModalClicked={this.closeCancellationOrderModalClicked}
                        closeDeleteOrderModalClicked={this.closeDeleteOrderModalClicked} />
                }

                {resultMessageClass}
            </>
        )
    }
}

export { PreparingOrdersContent };

const UpGroupSheetPreparingOrders = (props) => {
    return (

        <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.preparing')}
            titleIcon='ns-icon-preparing-order' >
            <PreparingOrdersContent {...props} />
        </UserPanelWrapper >
    )
}

export default withRouter(UpGroupSheetPreparingOrders);