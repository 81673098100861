import { ArrowRight } from "@mui/icons-material";

const RegularOrderTranslationEn = {
    regularOrder: {
        common: {
            aed: 'AED',
            save: 'Save',
            add: 'Add',
            clear: 'Clear',
            next: 'Next',
            previous: 'Previous',
            skip: 'Skip',
            clickHere: 'Click Here',
            dragHere: 'Drag Here',
            frontSide: 'Front Side',
            backSide: 'Back Side',
            height: "Height",
            width: "Width",
            imgSide: "img-side",
            removeAndSave: 'Remove and save',
            horizontal: "Horizontal",
            vertical: "Vertical",
            convex: "Convex",
            concave: "Concave",
            exportExcel: "Export Excel",
            preview: 'Preview'
        },

        header: {
            editOption: 'Edit Option',
            turnaround: 'Turnaround',
            normal: 'Normal',
            express: 'Express',
            printedSide: 'Printed Side',
            frontOnly: 'Front Only',
            frontAndBack: 'Front and Back',
            circulation: 'Circulation',
            latCount: 'Lat Count',
            totalUps: 'Total Up\'s',
            quantity: 'Quantity',
            downloadGuide: 'Guides',
            discount: 'Discount',
            cancellationCode: "Cancellation code",
            totalQuantity: 'Total Quantity'
        },

        mainStepper: {
            chooseYourFilesTitle: 'Select Files',
            chooseYourFilesDescription: 'As soon as you upload your first file our online detects your file dimension and price.',
            addExtendedServiceTitle: 'Extended Service',
            addExtendedServiceDescription: 'Based on your printing order, extended services can include folding line, filter,  punch etc.',
            confirmDetailsTitle: 'Confirm Details',
            confirmDetailsDescription: 'Here you can check all the details of your order and submit for print.',
        },

        choosingFile: {
            fourColorPrintedFile: 'Four-Color print',
            choosingFile: 'Choosing File',
            clearOrder: 'Clear Order',
        },

        extendedService: {
            extendedService: 'Extended Service',
            extendedServices: 'Extended Services',
            filter: 'Filter',
            foldLine: 'Fold Line',
            offSetFromLeft: 'Distance from left',
            offSetFromTop: 'Distance from top',
            punch: 'Punch',
            addFilter: 'Add Filter',
            editFilter: 'Edit Filter',
            totalFilter: 'Filter ({0}Box) : {1}',
            addPunch: 'Add Punch',
            editPunch: 'Edit Punch',
            punchAdded: 'One Punch Added: {0}',
            punchPrice: 'Punch Price: ',
            corners: 'Corners Type',
            squareCorners: 'Square Corners',
            roundedCorners: 'Rounded Corners',
            square: 'Square',
            rounded: 'Rounded',
            gripperEdge: 'Gripper Edge',
            cutLine: 'Cutting Line',
            withGripperEdge: 'With Gripper Edge',
            withoutGripperEdge: 'Without Gripper Edge',
            billBook: 'Bill Book',
            handleType: 'Handle Type',
            none: 'None',
            flatHandle: 'Flat',
            twistHandle: 'Twist',
            topFolding: 'Top Folding',
            hasTopFolding: 'Has Top Folding', 
            gripperEdgeDescription: 'In design and formatting, a margin of 1 centimeter from the bottom of the paper is considered as the gripper edge, which is not printable. (The gripper edge in offset printing is a clamp on the paper-carrying cylinders that grabs the paper from outside the machine and brings it inside.)'
        },

        billBook: {
            noSerialSelected: 'select the serial insertion location by clicking the mouse on the image.',
            noSerialStartFrom: 'make sure the serial number is correct',
            enterSerial: 'Enter serial number',
            billBookOptions: 'Bill Book Options',
            previewBillBook: 'Bill Book Preview',
            billBookDescription: 'Regarding the added serial and perforation numbers in the following table , specify if your order has any of these serial and perforation numbers or not.',
            billBookCopy: 'Number of Copies',
            billBookCopiesErrorText: 'Copies quantity is empty or incorrect. contact group sheet unit.',
            billBookPrintColor: 'Printing Types',
            billBookBindingType: 'Binding Options',
            billBookBindingSideType: 'Binding Side',
            hasUnderHand: 'Has Cumin',
            underHand: 'Under Hand',
            serialStartFrom: 'Serial Start From',
            hasIt: 'Has It',
            hasNotIt: 'Hasn\'t It',
            serialNumber: 'Serial Number',
            serialNumberAndPerforation: 'First Serial Number and First Perforation',
            firstSerialNumber: 'First Serial Number',
            secondSerialNumber: 'Second Serial Number',
            perforation: 'Perforation',
            firstPerforation: 'First Perforation',
            secondPerforation: 'Second Perforation',
            addSerial: 'Add Serial Number',
            serialNumberDirection: 'Direction',
            oneCopy: 'Only Master',
            twoCopies: 'Master + 1 Copy',
            threeCopies: 'Master + 2 Copies',
            fourCopies: 'Master + 3 Copies',
            black: 'One-color(Black)',
            navyBlue: 'One-color(Navy Blue)',
            redAndBlack: 'Two-color(Red And Black)',
            fourColors: 'Four Colors(CMYK)',
            master: 'Master',
            firstCopy: 'First Copy',
            secondCopy: 'Second Copy',
            thirdCopy: 'Third Copy',
            first: 'First',
            second: 'Second',
            free: 'Free Papers',
            glue: 'Glue Binding',
            stapler: 'Stitching',
            up: 'Up',
            right: 'Right',
            down: 'Down',
            left: 'Left',
            invalidSerial: 'Serial number is not valid',
            serialNumberStartedFromTitle: 'Started From',
            serialNumberStartedFromTo: 'Serial Number from {0} to {1}',
            billBookLanguageType: 'Language',
            persian: 'Persian',
            english: 'English',
            copies: 'Copies',
            nextSeriesMode: 'Next Series Mode',
            restartNumbering: 'Restart Numbering',
            continueNumbering: 'Continue Numbering',
        },

        modal: {
            fourColorPrintingFile: 'Four-Color Printing File ({0})',
            closeModalAlert: 'If you close this window, file(s) you didn\'t save will be deleted. Are you sure to close the window?',
            closeServicesModalAlert: 'If you close this window, changes will be deleted. Are you sure to close the window?',
            changeFile: 'Change File',
            followingPointsTitle: 'The picture you see here is your final printing file. double check the file.',
            zoneSafeTitle: '1- Safe zone',
            zoneSafeDescription: 'Text outside the dashed line will get cut off in the trimming process. Make sure all information does not go over the dashed line.',
            bleedTitle: '2- Bleeds and Cut Lines',
            bleedDescription: 'To get a desired and perfect printing order, you should extend background color up to the edge of your artwork.',
            filmDescription: 'To get a perfect printing order, your printing file and film file must be completely matched. otherwise we can\'t be held responsible for any damaged printing order.',
            howToMatch: 'How to Align?',
            howToMatchDescription: 'To align your printing and film file, drag your film file on the printing file. Also you can use the following alignment tools.',
            howToResize: 'How to Resize?',
            howToResizeDescription: 'To resize your film file, use Resize To Print File or put width and height of your film file.',
            filterTitle: 'Extended Services (Filter)',
            foldLineTitle: 'Extended Services (Fold Line)',
            filterDescription: 'clarify filter areas by moving the mouse on the picture.',
            filterWidth: 'Width= {0} mm',
            filterHeight: 'Height= {0} mm',
            dimensionError: 'Minimum dimensions are {0}×{1}',
            overlapError: 'Overlapping error is detected',
            foldLineDistanceError: 'Fold line spacing must be greater than {0}',
            perforationDistanceError: 'Perforation spacing must be greater than {0}',
            foldLineTrimZoneError: 'Folding line can\'t exceed the trim zone',
            filterQuantity: '{0} Filter',
            noFilterSelected: 'No any filter selected for this side.',
            noFoldLineSelected: 'No any fold line selected.',
            total: 'Total: ',
            align: 'Align',
            resizingFilmFile: 'Resizing Film File',
            resize: 'Resize',
            dimension: 'Dimension',
            selectedDimension: 'Selected Dimension',
            noDimensionSelected: 'No Dimension Selected',
            maxSeries: 'Max Series',
            use: 'Use',
            Used: 'Used',
            fit: 'Resize To Print File',
            punchPositionError: 'Punching position must be inside the permitted range',
            serialPositionError: 'Serial number position must be inside the permitted range',
            topPunch: 'Top= {0} mm',
            leftPunch: 'Left= {0} mm',
            preset: 'Preset',
            customized: 'Customized',
            addToListResultModalTitle: 'Your Order Added to List',
            addToListResultModalMessage: 'Your Order Added to List. You Can continue your ordering process.',
            addToListResultSnackbarMessage: 'Your Order Added to List.',
            ups: 'Ups',
            perforationTitle: 'Extended Services (Bill Book Perforation)',
        },

        confirmDetail: {
            detailDescription: 'Here you can check all the details of your order and submit your order.',
            filePreview: 'File Preview',
            orderDetails: 'Order Details',
            quantityDetail: '{0}×{1} series = {2}',
            productUpDimension: 'Product Up Dimension',
            fileDimension: 'File Dimension',
            dimension: '{0}×{1} mm',
            latCount: '{0} Ups',
            price: 'Price',
            basePrice: 'Base Price',
            productPrice: 'Product Price',
            priceDetail: '{0}×{1}×{2} series = {3}',
            priceDetailWithoutSeries: '{0}×{1} = {2}',
            priceDetailWithoutUps: '{0}×{1} series = {2}',
            priceDetailWithUps: '{0}×{1} Up = {2}',
            priceDetailWithUpsAndSeries: '{0}×{1} Up×{2} series = {3}',
            servicesPrice: 'Extended Services Price',
            servicesPriceDetail: '{0} services price×{1} series= {2}',
            series: 'Series',
            totalPriceExVat: 'Total Price (Exclusive of VAT)',
            submitOrder: 'Submit order',
            viewMore: 'View more',
            viewLess: 'View less',
            viewList: 'View List',
            addToCurrentList: 'Add To Current List',
            currentList: 'Current List',
            productDetails: 'Product Details',
            totalPrice: 'Total Price',
            totalProductPrice: 'Total Product Price',
            totalExtendedServicePrice: 'Total Extended Services Price',
            totalAmount: 'Total Amount',
            grandTotal: 'Grand Total',
            status: 'Status',
            resumeOrdering: 'Resume Ordering',
            availableDimesionTooltip: 'The final product Dimension will be automatically determined based on the dimensions of the uploaded file Click "{0}" to upload file.',
            startOrdering: 'Start Ordering',
            customDimension: '* Note: Dimensions entered are for price inquiry only. Click "{0}" for final size. Dimension will be automatically determined based on uploaded file.',
        },

        saveResult: {
            successMessage: 'Your order has been submitted successfully.',
            successDescription: 'You have submitted your order successfully. To start printing process confirm your order, otherwise your order will remain in pending status.',
            confirmOrder: 'Confirm Orders',
            orderAnotherProduct: 'Order Another Product',
            returnToShop: 'Return to shop',
        },

        messages: {
            formatIsWrong: 'Format is wrong',
            quantityError: 'Quantity must be a factor of circulation',
            isAlreadyUploaded: 'Is already uploaded.',
            chooseFrontMasterFile: 'Choose front side of 4-color printed file',
            chooseBackMasterFile: 'upload or select back side of 4-color printing file.',
            chooseFrontFilmFile: 'upload or select front side of 4-color printing file.',
            chooseFilmFile: 'upload or select your film {0} file.',
            chooseFilter: 'drag your mouse and specify your filtering area(s).',
            mandatoryExtendedServiceMessage: 'Adding or setting {0} service is mandatory',
        },

        tour: {
            headerDetails: 'Here you see the name and the details of your selected product.',
            headerEditOption: 'By clicking on edit options you can change product options.',
            masterFile: 'Click here to upload your file.',
            filmFile: 'drag your film file on the printing file and make sure it is fully matched.',
            chooseFileModalPreview: 'Here check your file with the guideline.',
            chooseFileModalChangeFile: 'Click on change file to change and upload another file.',
            chooseFileModalSave: 'If your file is matching with the guideline click on save',
            chooseFileModalAlignment: 'You can use these alignment tools to align your printing and film file.',
            chooseFileModalResize: 'You can use these tools to resize your film file.',
            chooseFileModalFit: 'To match your printing file and film file click on Resize To Print File.',
            extendedServiceFoldLine: 'Here you can choose the type of folding line.',
            extendedServiceRoundedCorner: 'Here you can choose whether your order is rounded corner or square corner.',
            extendedServiceGripperEdge: 'Here you can choose whether your order have gripper Edge or not.',
            extendedServiceFilter: 'By clicking on add filter you can tell us which areas shouldn\'t covered by UV.',
            extendedServicePunch: 'By clicking on add punch you can tell us which area should be punched.',
            extendedServiceConfirmDetails: 'check all the details of your product including dimension, price, quantity etc.',
            extendedServiceConfirmSubmit: 'Click on submit order and finalize your order.',
            extendedServiceModalAddedFilter: 'This filter has been selected. Use the arrow keys to move the filter',
            extendedServiceModalAddedPunch: 'This punch has been selected. Use the arrow keys to move the punching area',
            extendedServiceModalBillBookInputs: 'select number of copies, printing type and binding type of the bill book',
            extendedServiceModalBillBookBindingSide: 'determine the binding direction of bill book',
            extendedServiceBillBookOptionButton: 'Click here to select different options of the bill book',
            extendedServiceBillBookPreviewButton: 'double check the details of the selected bill book',
            extendedServiceModalBillBookModalContent: 'enter the settings of perforation and serial number',
            extendedServiceModalBillBookModalSummary: 'check the details of the selected order',
            extendedServiceModalBillBookSerialNumberRow: 'determine in which copies the serial number need to be created',
            extendedServiceModalBillBookPerforationRow: 'determine in which copies the perforation line need to be created',
            extendedServiceModalSerialNo: 'First select the serial number direction then select starting number and click on add button',
            extendedServiceModalPerforation: 'select perforating direction or move your mouse and select it on the picture',
            extendedServiceBillBookModalPreview: "check the quantity of the bill book based on the selected options.",

            orderFilesPreview: 'Here you see a preview of your order based on your selected options and files.',

            clickHere: 'click here to apply the selected file on your order. Also you can easily drag and drop your file here.',
            infoFile: "Based on your file's size, system calculated order price. check them.",
            addToCurrentList: 'check all the details of your order and make sure they are correct. Then click on add to current list.',
            extendedServiceInfo: 'Your selected extended services have been added to your order',
            currentList: 'Your order now was added to the list but It is not yet submitted.',
            addOrder: 'In professional ordering you can add other orders of the same product. If you have other orders you can add to the list.',
            viewList: 'To see your added orders to the list , click on view the list.',
            submit: 'Here you can see the grand total of your orders. check and after making sure of it, click on submit order.'
        }
    }
};

export default RegularOrderTranslationEn;