import React from 'react';
import '../../assets/sass/components/editor/NsEditor.scss';


import { Editor } from '@tinymce/tinymce-react';
import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import { isEnCulture } from '../../utilities/CultureHelper';
import { isEmpty } from '../../utilities/ValidationHelper';

class NsEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toolbar: this.editorMode(props),
            value: this.props.initialValue
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }


    editorMode = (props) => {
        switch (props.editorMode) {
            case Enumerations.editorMode.basic:
                return 'ltr rtl'
                break;
            case Enumerations.editorMode.advance:
                return 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | lineheight | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview | insertfile image media template link anchor codesample | ltr rtl'
                break;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({ value: this.props.initialValue ?? '' });
        }
    }

    handleEditorChange(value, editor) {
        this.setState({ value }, () => CommonHelper.safeFunctionCall(this.props.changeHandler, this.state.value));
    }

    useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    render() {
        return (
            <Editor
                init={{
                    selector: 'textarea#full-featured-non-premium',
                    plugins: 'preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                    imagetools_cors_hosts: ['picsum.photos'],
                    menubar: this.props.showMenuBar ? 'file edit view insert format tools table help' : '',
                    toolbar: this.state.toolbar,
                    toolbar_sticky: true,
                    image_advtab: true,
                    image_dimensions: false,
                    directionality: !isEmpty(this.props.selectedLanguage)
                        ? this.props.selectedLanguage == Enumerations.culture.fa
                            ? 'rtl'
                            : "ltr"
                        : isEnCulture()
                            ? 'ltr'
                            : 'rtl',
                    link_list: [
                        { title: 'Diamond Website', value: 'http://www.diamond-press.com' },
                        { title: 'Diamond Shop', value: 'http://www.diamond-press.com/en/products' },
                    ],
                    image_list: [
                        { title: 'Diamond logo SVG', value: 'http://www.diamond-press.com/static/media/Diamond-Logo.07b20e47.svg' },
                        { title: 'Test Card', value: 'https://api.diamond-press.com/api/en/Nas/Product/File/?PPR7766_1.png' }
                    ],
                    image_class_list: [
                        { title: 'None', value: '' },
                        { title: 'Some class', value: 'test' }
                    ],
                    importcss_append: true,
                    file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === 'file') {
                            callback('http://www.diamond-press.com/static/media/Diamond-Logo.07b20e47.svg', { text: 'link to an diamond logo image' });
                        }
                        if (meta.filetype === 'image') {
                            callback('http://www.diamond-press.com/static/media/Diamond-Logo.07b20e47.svg', { alt: 'Diamond Logo' });
                        }
                        if (meta.filetype === 'media') {
                            callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                        }
                    },
                    templates: [
                        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                        { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                    ],
                    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                    height: !!this.props.height ? this.props.height : 600,
                    image_caption: true,
                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                    noneditable_noneditable_class: 'mceNonEditable',
                    toolbar_mode: 'sliding',
                    contextmenu: 'link image imagetools table',
                    skin: this.useDarkMode ? 'oxide-dark' : 'oxide',
                    content_css: this.useDarkMode ? 'dark' : 'default',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                initialValue={this.props.initialValue}
                value={this.state.value}
                onEditorChange={this.handleEditorChange}
            />
        )
    }
}
export default NsEditor
