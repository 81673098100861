import React, { useEffect, useRef, useState } from "react";

import NsAdminPanelContentWrapper from "../../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../../components/grids/NsDataGridWithServiceCall";
import NsTab from "../../../../../components/tabs/NsTab";
import ConstantsWithTranslation from "../../../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { GetFeedbackEmployeeBasicInfoService, GetFeedbackEmployeesService } from "../../../../../services/adminPanel/AdminFeedbackService";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import ApBaseFeedbackDetailModal from "../../bases/ApBaseFeedbackDetailModal";
import { createFeedbackUserColumns } from "../../bases/ApBasesFeedbackHelper";
import ApFeedbackUsersSearch from "./ApFeedbackUsersSearch";

const ApFeedbackUsers = (props) => {

    const tabs = ConstantsWithTranslation.getFeedbackstatus();
    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [searchData, setSearchData] = useState(props.searchData);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showFeedbackTypeDetailModal, setShowFeedbackTypeDetailModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState();
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData, selectedTabIndex]);

    const showFeedbackTypeDetailModalClicked = (selectedFeedback) => {
        setSelectedFeedback(selectedFeedback);
        setShowFeedbackTypeDetailModal(true);
    }

    const closeFeedbackTypeModalClicked = () => setShowFeedbackTypeDetailModal(false);

    const tabChanged = (newValue, selectedTab) => {
        setSelectedTabIndex(newValue);
        setSelectedTab(selectedTab);
    }

    const getData = (data, isLoading) => setIsLoading(isLoading);

    const getSearchData = (data) => setSearchData(data);

    const getParameters = () => {
        let params = {
            "Filter.EmployeeIds": searchData?.selectedUserSupporter?.userId || null,
            "Filter.DepartmentIds[0]": !!searchData?.userDepartment?.key ? searchData?.userDepartment?.key : null,
            "Filter.UserId": searchData?.selectedUser?.userId || searchData?.user || props?.searchData?.user || null,
            "Filter.SubmitTimeFrom": searchData?.submitTimeFrom || null,
            "Filter.SubmitTimeTo": searchData?.submitTimeTo || null,
            "Filter.FromAverageRateValue": searchData?.averageRateFrom?.value || null,
            "Filter.ToAverageRateValue": searchData?.averageRateTo?.value || null,
            "Filter.IsPublic": searchData?.displayToAll?.key === 2 || null,
            "Filter.DemandedServiceTypeId": searchData?.demandService?.key || null,
            "Filter.UserFeedbackStateTypeId": selectedTab?.id,
        }
        !isEmpty(searchData?.selectedProductsId) && searchData?.selectedProductsId.map((product, index) => params["Filter.ProductIds[" + index + "]"] = product);
        return params;
    }

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.feedback.feedbackUsers")}
            icon={'ns-icon-orders-feedback h1'}>
            <ApFeedbackUsersSearch setSearchData={getSearchData}
                isLoading={isLoading} />

            <NsTab tabs={tabs}
                value={selectedTabIndex}
                onChange={tabChanged} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetFeedbackEmployeesService}
                parameters={getParameters()}
                columnDefs={createFeedbackUserColumns(showFeedbackTypeDetailModalClicked)}
                hasPagination={true}
                getData={getData} />
            {showFeedbackTypeDetailModal &&
                <ApBaseFeedbackDetailModal selectedFeedback={{ ...selectedFeedback, feedbackType: Enumerations.feedbackTypes.user }}
                    getFeedbackDtoService={GetFeedbackEmployeeBasicInfoService}
                    hasAccessInfo={hasAccess([UserClaimEnumerations.getApFeedbackToEmployeeBasicInfo]) && hasAccess([UserClaimEnumerations.getApFeedbackActivityHistory])}
                    closeModal={closeFeedbackTypeModalClicked}
                    updateFeedbackOrdersGrid={() => setCallServiceDate(new Date())} />
            }

            {messageClassModal}
        </NsAdminPanelContentWrapper>
    )
};

export default React.memo(ApFeedbackUsers);