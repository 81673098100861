import React, { useState, useEffect } from "react";

import { GetApDfbProgressMetricsService } from "../../../../../services/adminPanel/AdminDashboardService";
import NsLinearProgress from "../../../../../components/progresses/NsLinearProgress";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import NsSkeleton from "../../../../../components/skeletons/NsSkeleton";
import NsTypography from "../../../../../components/text/NsTypography";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import CommonHelper from "../../../../../utilities/CommonHelper";
import Enumerations from "../../../../../configs/Enumerations";
import NsCard from "../../../../../components/cards/NsCard";

const ApDfbRatingParameterProgress = (props) => {

    const { parameters, refreshDate } = props;
    const [data, setData] = useState([
        { feedbackTypeMetricTitle: 'sadfasdf', count: 12, averageRate: 4.5, percentage: 75 },
        { feedbackTypeMetricTitle: 'sadfasdf', count: 23434, averageRate: 4.5, percentage: 23 },
        { feedbackTypeMetricTitle: 'sadfasdf', count: 234, averageRate: 4.5, percentage: 89 },
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState();

    useEffect(() => {
        setIsLoading(true);
        GetApDfbProgressMetricsService(parameters, getApDfbProgressMetricsCallback);
    }, [refreshDate])

    const getApDfbProgressMetricsCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(resultData);
        } else {
            setMessageClassModal(messageClassModal);
        }
    };

    const mapRatingParameter = (data) => {
        return (!isEmpty(data) && data.map((item, index) =>
            <div key={index} className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                        <span className="fw-bold">{item?.count}</span>
                        <NsTypography variant='inherit' className='light-text-color font-size-14'>
                            {item?.feedbackTypeMetricTitle}
                        </NsTypography>
                    </div>
                    <div className="fw-bold">
                        <i className="ns-icon-star-fill" />{!!item.averageRate ? item.averageRate.toFixed(1) : item.averageRate} <i className="ns-icon-rate" />
                    </div>
                </div>
                <NsLinearProgress progress={item?.averageRate * 20 || 0}
                    isLoading={isLoading}
                    className='admin-panel__audit-dashboard--rating' />
            </div>
        ));
    }

    return (!isEmpty(data) &&
        <NsCard className='p-3 h-100'>
            <div className="page-title mb-4">
                {getTranslatedRawText('adminPanel.feedback.ratingParameter')}
            </div>
            <div className="d-flex flex-column gap-3">
                {isLoading
                    ? <LinearProgressSkeleton number={3} />
                    : mapRatingParameter(data)
                }
            </div>
            {messageClassModal}
        </NsCard >
    );
};

export function LinearProgressSkeleton(props) {
    return CommonHelper.createArray(props.number).map((item, index) =>
        <div className="mb-3 " key={index}>
            <div className="col-8">
                <NsSkeleton isVisible={true}
                    variant={[
                        Enumerations.skeleton.text,
                    ]} />
            </div>
            <NsLinearProgress progress={0}
                isLoading={true}
                className='admin-panel__audit-dashboard--rating' />
        </div>
    )
}

export default React.memo(ApDfbRatingParameterProgress);