import clsx from 'clsx';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import Config from '../../../../configs/Config';
import Links from '../../../../configs/links/AllLinks';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import SinglePage from '../../SinglePage';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';

class SiteMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: !isEmpty(this.props.currentUser) && this.props.currentUser !== '',
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.currentUser !== prevProps.currentUser) {
            this.setState({ isAuthenticated: !isEmpty(this.props.currentUser) && this.props.currentUser !== '' })
        }
    }

    siteMapPagesList = [
        {
            title: 'home.siteMap.ordersAndShipping',
            icon: 'ns-icon-orders h4',
            links: [
                { pageName: 'profile.orderCart.cart', url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder), ariaLabel: 'Cart page', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.preparing', url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.preparing), ariaLabel: 'Preparing page', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.inProcess', url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.inProcessOrder), ariaLabel: 'In Process page', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.readyToDeliver', url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.readyToDeliverOrderInTheWarehouse), ariaLabel: 'Ready To Deliver page', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.archive', url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.archiveOrder), ariaLabel: 'Archived page', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.allOrders', url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.allOrders), ariaLabel: 'Find Orders', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.deliveryRequest', url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequest), ariaLabel: 'My Shipping', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.payment', url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderPaymentReport), ariaLabel: 'Payment', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.debitCredit', url: getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderFinancialReport), ariaLabel: 'Financial', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.instantDiscounts', url: getUrlWithCurrentCulture(Links, Links.instantDiscounts), ariaLabel: 'Instant Discounts', isRequireAuthentication: true },
            ]
        },
        {
            title: 'profile.dataGridColumns.services',
            icon: 'ns-icon-setting h4',
            links: [
                { pageName: 'headers.bottom.shop', url: getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts), ariaLabel: 'Shop', isRequireAuthentication: false },
                { pageName: 'headers.bottom.iOrderFrequently', url: getUrlWithCurrentCulture(Links.view.main.professionalOrder, Links.view.main.professionalOrder.productDetailsWithoutId), ariaLabel: 'Professional Ordering', isRequireAuthentication: true },
                Config.hasStoreProducts && { pageName: 'headers.common.storeProduct', url: getUrlWithCurrentCulture(Links.view.main, Links.view.main.allStoreProducts), ariaLabel: 'Store Product', isRequireAuthentication: false },
                { pageName: 'headers.bottom.fileManager', url: getUrlWithCurrentCulture(Links.view.main, Links.view.main.fileManager), ariaLabel: 'File Manager', isRequireAuthentication: true },
                { pageName: 'common.breadcrumbs.productCategories', url: getUrlWithCurrentCulture(Links.view.main, Links.view.main.productCategories), ariaLabel: 'Products Categories', isRequireAuthentication: false },
            ]
        },
        {
            title: 'headers.top.account',
            icon: 'ns-icon-customer-users h4',
            links: [
                { pageName: 'profile.actionButton.profileInfo', url: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileInformationSetting), ariaLabel: 'Profile Information', isRequireAuthentication: true },
                { pageName: 'profile.feedback.feedbacks', url: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileFeedbackSetting), ariaLabel: 'Feedbacks', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.notificationSetting', url: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileNotificationSetting), ariaLabel: 'Notification Setting', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.autoDeliverySetting', url: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAutoShippingSetting), ariaLabel: 'Auto Delivery Setting', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.address', url: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAddressSetting), ariaLabel: 'Address', isRequireAuthentication: true },
                { pageName: 'profile.actionButton.agencySetting', url: getUrlWithCurrentCulture(Links.view.main.profile, Links.view.main.profile.profileAgencySetting), ariaLabel: 'Agency Setting', isRequireAuthentication: true },
                { pageName: 'common.breadcrumbs.festivals', url: getUrlWithCurrentCulture(Links, Links.festivals), ariaLabel: 'Festivals', isRequireAuthentication: false },
                getConfigObjectByCulture(Config.showCashback) && { pageName: 'common.breadcrumbs.cashback', url: getUrlWithCurrentCulture(Links.view.main.userDashboard, Links.view.main.userDashboard.cashback), ariaLabel: 'Cashback', isRequireAuthentication: true },
            ]
        },
        {
            title: 'home.siteMap.support',
            icon: 'ns-icon-security-setting h4',
            links: [
                { pageName: 'singlePage.warrantyTerms', url: getUrlWithCurrentCulture(Links, Links.warrantyTerms), ariaLabel: 'Warranty Terms', isRequireAuthentication: false },
                { pageName: 'singlePage.privacyPolicy.privacyPolicy', url: getUrlWithCurrentCulture(Links, Links.privacyPolicy), ariaLabel: 'Privacy Policy', isRequireAuthentication: false },
                { pageName: 'singlePage.termsOfUse', url: getUrlWithCurrentCulture(Links, Links.termsOfUse), ariaLabel: 'Terms of Use', isRequireAuthentication: false },
                { pageName: 'singlePage.faqs.frequentlyAskedQuestions', url: getUrlWithCurrentCulture(Links, Links.frequentlyAskedQuestions), ariaLabel: 'Frequently Ask Questions', isRequireAuthentication: false },
                false && { pageName: 'common.breadcrumbs.saveOutputInPdfFormat', url: getUrlWithCurrentCulture(Links, Links.saveOutputInPdfFormat), ariaLabel: 'save Output In Pdf Format', isRequireAuthentication: false },
            ]
        },
        {
            title: 'home.siteMap.company',
            icon: 'ns-icon-news h4',
            links: [
                { pageName: 'common.breadcrumbs.about', url: getUrlWithCurrentCulture(Links, Links.aboutUs), ariaLabel: 'About Us', isRequireAuthentication: false },
                { pageName: 'common.breadcrumbs.contactUs', url: getUrlWithCurrentCulture(Links, Links.contactUs), ariaLabel: 'Contact Us', isRequireAuthentication: false },
                { pageName: 'singlePage.ourEquipment.ourEquipment', url: getUrlWithCurrentCulture(Links, Links.ourEquipment), ariaLabel: 'Our Equipment', isRequireAuthentication: false },
                { pageName: 'footer.requestSample', url: '#', ariaLabel: 'Request Sample', disable: true },
                { pageName: 'footer.downloadTemplate', url: getUrlWithCurrentCulture(Links, Links.downloadCenter), ariaLabel: 'Download Template', disable: false },
                { pageName: 'singlePage.gallery', url: getUrlWithCurrentCulture(Links, Links.gallery), ariaLabel: 'gallery', disable: false, isRequireAuthentication: false },
                { pageName: 'common.breadcrumbs.specialNeeds', url: getUrlWithCurrentCulture(Links, Links.specialNeeds), ariaLabel: 'Special Needs', isRequireAuthentication: false },
            ]
        },
        {
            title: 'home.siteMap.newsAndBlog',
            icon: 'ns-icon-news h4',
            links: [
                { pageName: 'adminPanel.sidebar.news', url: getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.news), ariaLabel: 'News', isRequireAuthentication: false },
                { pageName: 'adminPanel.sidebar.blog', url: getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogs), ariaLabel: 'Blog', isRequireAuthentication: false }
            ]
        }

    ];

    mapSalesAndSupport = () => {
        const data = this.siteMapPagesList;
        return (<Row>
            {data.map((item, index) => {
                return (
                    <Col md={4} key={index}>
                        <ul className=' m-0 list-unstyled mb-4'>
                            <li className='black-color text-color fw-bold d-flex align-items-start'>
                                <i className={item.icon} />
                                {getTranslatedRawText(item.title)}
                            </li>
                            {item.links.map((link, index) => {
                                return !!link && <li key={index} >
                                    {link.isRequireAuthentication && !this.state.isAuthenticated || !!link?.disable
                                        ? <label className='pe-none disable-color font-size-14'>{getTranslatedRawText(link.pageName)}</label>
                                        : <Link className='font-size-14 text-decoration-none'
                                            to={!!link.isExternalLink ? { pathname: link.url } : link.url}
                                            target={!!link.isExternalLink ? "_blank" : "_self"}>
                                            {getTranslatedRawText(link.pageName)}
                                        </Link>
                                    }
                                </li>
                            })}
                        </ul>
                    </Col>
                )
            })
            }
        </Row >
        )
    }

    render() {
        return (
            <SinglePage >
                <NsPageMainContentWrapper header={getTranslatedRawText("common.breadcrumbs.siteMap")}
                    icon="ns-icon-site-map h1"
                    className="mb-3">
                    {this.mapSalesAndSupport()}
                </NsPageMainContentWrapper>
            </SinglePage>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.userFullName,
    };
}

export default connect(mapStateToProps, null)(SiteMap);