import React from 'react';

import '../../assets/sass/components/buttons/NsButton.scss';
import NsButton from './NsButton';

class NsCountDownButton extends React.Component {

    constructor(props) {
        super(props);
        this.interval = '';
        this.state = {
            min: this.props.time.min,
            second: this.props.time.second,
            disableButton: true
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.time.min !== this.props.time.min || nextProps.time.second !== this.props.time.second)
            this.setState({ min: nextProps.time.min, second: nextProps.time.second, },
                () => { if ((this.state.second !== 0 || this.state.min !== 0) && !this.interval) this.interval = setInterval(() => this.countDown(), 1000) })
    }

    buttonClick = (event) => {
        this.props.onClick && this.props.onClick({ event });
        this.setState({ disableButton: true, min: this.props.time.min, second: this.props.time.second });
        this.interval = setInterval(() => this.countDown(), 1000);
    }

    countDown = () => {
        if (this.state.second === 0) {
            if (this.state.min > 0)
                this.setState({ min: this.state.min - 1, second: 59 });
            else {
                clearInterval(this.interval);
                this.setState({ disableButton: false });
            }
        } else
            this.setState({ second: this.state.second - 1 });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setLabel = () => {
        return '0' + this.state.min + ':' + (this.state.second < 10 ? '0' + this.state.second : this.state.second) + '-'
    }

    render() {
        return (
            <NsButton disabled={this.state.disableButton}
                className="secondary"
                onClick={this.buttonClick}>
                {this.setLabel()}{this.props.label}
            </NsButton>
        );
    }
}

export default NsCountDownButton;