import React from 'react';
import '../../assets/sass/components/punchAreas/NsPunchArea.scss';

import NsImageOrderMasterFileView from '../images/NsImageOrderMasterFileView';
import ImageHelper from '../../utilities/ImageHelper';
import Config from '../../configs/Config';
// import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';

class NsPunchArea extends React.Component {

    constructor(props) {
        super(props);
        this.minPunchDistanceEdges = null;
        this.maxPunchDistanceEdges = null;
        if (!!props.masterFileInfo) {
            this.minPunchDistanceFromEdge = ImageHelper.convertMillimeterToPixel(props.isSerrialArea ? Config.order.minSerialDistanceFromEdge : Config.order.minPunchDistanceFromEdge, props.masterFileInfo?.resolution);
            this.maxPunchDistanceFromEdge = ImageHelper.convertMillimeterToPixel(Config.order.maxPunchDistanceFromEdge, props.masterFileInfo?.resolution);
            this.minPunchDistanceEdges = {
                pixelTop: Math.max(props.trimZone?.pixelTop, this.minPunchDistanceFromEdge),
                pixelBottom: Math.max(props.trimZone?.pixelBottom, this.minPunchDistanceFromEdge),
                pixelLeft: Math.max(props.trimZone?.pixelLeft, this.minPunchDistanceFromEdge),
                pixelRight: Math.max(props.trimZone?.pixelRight, this.minPunchDistanceFromEdge),
            };
            this.maxPunchDistanceEdges = {
                pixelTop: Math.max(props.trimZone?.pixelTop, this.maxPunchDistanceFromEdge),
                pixelBottom: Math.max(props.trimZone?.pixelBottom, this.maxPunchDistanceFromEdge),
                pixelLeft: Math.max(props.trimZone?.pixelLeft, this.maxPunchDistanceFromEdge),
                pixelRight: Math.max(props.trimZone?.pixelRight, this.maxPunchDistanceFromEdge),
            };
        }
    }

    // componentDidMount = () => window.addEventListener('keydown', this.moveSelection);

    // componentWillUnmount = () => window.removeEventListener('keydown', this.moveSelection);

    imageClicked = (event) => {
        var bounds = event.target.getBoundingClientRect();
        var left = event.clientX - bounds.left;
        var top = event.clientY - bounds.top;
        CommonHelper.safeFunctionCall(this.props.setItemPosition, left, top);
    }

    // convertToMillimeter = (position) => {
    //     const { masterFileInfo, ratio } = this.props;
    //     return ImageHelper.convertPixelToMillimeter(position / ratio, masterFileInfo?.resolution)
    // }

    // moveSelection = (e) => {
    //     const { punches, masterFileInfo } = this.props;
    //     const selectedPunchIndex = punches.findIndex(punch => punch.isSelected === true);
    //     if (selectedPunchIndex < 0) return;
    //     switch (e.keyCode) {
    //         case Enumerations.keyboard.leftArrow:
    //             punches[selectedPunchIndex]?.horizontalPoint > 0 && CommonHelper.safeFunctionCall(this.props.changeLeftPunchPositionClicked, punches[selectedPunchIndex].horizontalPoint - 0.5, selectedPunchIndex);
    //             break;
    //         case Enumerations.keyboard.rightArrow:
    //             punches[selectedPunchIndex]?.horizontalPoint < masterFileInfo?.width && CommonHelper.safeFunctionCall(this.props.changeLeftPunchPositionClicked, punches[selectedPunchIndex].horizontalPoint + 0.5, selectedPunchIndex);
    //             break;
    //         case Enumerations.keyboard.upArrow:
    //             punches[selectedPunchIndex]?.verticalPoint > 1 && CommonHelper.safeFunctionCall(this.props.changeTopPunchPositionClicked, punches[selectedPunchIndex].verticalPoint - 0.5, selectedPunchIndex);
    //             break;
    //         case Enumerations.keyboard.downArrow:
    //             punches[selectedPunchIndex]?.verticalPoint < masterFileInfo?.height && CommonHelper.safeFunctionCall(this.props.changeTopPunchPositionClicked, punches[selectedPunchIndex].verticalPoint + 0.5, selectedPunchIndex);
    //             break;
    //     }
    // }

    // punchClicked = (index) => CommonHelper.safeFunctionCall(this.props.punchClicked, index);

    render() {
        const { imgSrc, punches, pointDiameter, selectedSerials, serialBoxDimension, hasMaxPunchDistanceEdges, productDesignTemplateUrl } = this.props;
        return (

            <NsImageOrderMasterFileView onClick={this.imageClicked}
                imgSrc={imgSrc}
                wrapperClassName='position-relative'
                trimZone={this.minPunchDistanceEdges}
                hasMaxPunchDistanceEdges={hasMaxPunchDistanceEdges}
                maxPunchDistanceEdges={this.maxPunchDistanceEdges}
                productDesignTemplateUrl={productDesignTemplateUrl}
                setRatio={this.props.setRatio} >


                {!isEmpty(punches) && punches.map((punch, index) =>
                    <div key={index} className='punch-area--pointer extendedService-tour-addedPunch'
                        style={{
                            "--point-diameter": `${pointDiameter}px`,
                            "--top": `${punch?.top - pointDiameter / 2}px`,
                            "--left": `${punch?.left - pointDiameter / 2}px`,
                        }}
                    >
                    </div>
                )}

                {!isEmpty(selectedSerials) && selectedSerials.map((serial, index) =>
                    <div key={index} className='serial-area--pointer'
                        style={{
                            "--width": `${serialBoxDimension.width}px`,
                            "--height": `${serialBoxDimension.height}px`,
                            "--top": `${serial.top}px`,
                            "--left": `${serial.left}px`,
                        }} >
                        <div className='position-absolute font-size-12 top-0 end-0  background-white-color px-2 rounded-1 text-center cursor-pointer opacity-50'>
                            {getTranslatedRawText('regularOrder.billBook.serialNumber') + ' ' + (index + 1)}
                        </div>
                    </div>
                )}

            </NsImageOrderMasterFileView>

        );
    }
}

export default NsPunchArea;