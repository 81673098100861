import React from 'react';
import NsModal from "../../../components/modals/NsModal"
import CommonHelper from "../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";
import ApSupporterContent from "../../adminPanel/managements/users/supporters/ApSupporterContent";

const ApBaseSupporterSelectModal = (props) => {
    const closeModal = () => {
        CommonHelper.safeFunctionCall(props.closeModal);
    }
    return (
        <NsModal maxWidth='lg'
            showModal={props.showModal}
            closeModal={closeModal}
            noSpace={true}
            dialogTitle={getTranslatedRawText("adminPanel.usersInfo.searchInUsers")}>
            <div className='p-3 pb-0'>
                <ApSupporterContent isAdminSelection={true} adminSelected={props.adminSelected} />
            </div>
        </NsModal>
    )
}
export default React.memo(ApBaseSupporterSelectModal);