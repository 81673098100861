import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PostRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';
import OrderHelper from '../../utilities/OrderHelper';
import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import { getApiUrlWithCurrentCulture } from '../bases/GetApiUrlWithCurrentCulture';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';


const GetProductBasicPriceService = (data, callback) => {
    return PostRequest(getApiUrlWithCurrentCulture(Links.api.order, Links.api.order.getProductBasicPrice), data, callback);
}

const GetProductAdvancePriceService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.getProductAdvancePrice), data, callback);
}

const CheckFileBeforeUploadService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.checkFileBeforeUpload), data, callback, null);
}

const UploadImageFileService = (productId, routeId, file, isFront, callback, onUploadProgressCallback) => {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('SharedSheetOrderDetailUserFileType',
        isFront ? Enumerations.sharedSheetOrderDetailUserFileType.FrontImage : Enumerations.sharedSheetOrderDetailUserFileType.BackImage);
    formData.append('productId', productId);
    formData.append('businessPartnerCustomerIdentifier', CurrentUserHelper.getBusinessPartnerCustomerIdentifier());
    formData.append('series', OrderHelper.getSeries(routeId));
    formData.append('isDouble', OrderHelper.getPrintedSide(routeId).key === Enumerations.printedSide.twoSide);
    if (!isFront) formData.append('frontFileId', OrderHelper.getFrontSideMasterFiles(routeId).id);
    return PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.uploadImageFile), formData, callback, null, onUploadProgressCallback);
}

const GetFilePublicUrlService = (id, callback) => {
    let data = { id: id };
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.getFilePublicUrl), data, callback);
}

const PostDiscountService = (id, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.postDiscount), { orderIds: id }, callback);
}

const UploadFilmFileService = (routeId, productId, file, callback, onUploadProgressCallback) => {
    let formData = new FormData();
    formData.append('MasterFileId', OrderHelper.getFrontSideMasterFiles(routeId).id);
    formData.append('businessPartnerCustomerIdentifier', CurrentUserHelper.getBusinessPartnerCustomerIdentifier());
    formData.append('file', file, file.name);
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.uploadFilmFile), formData, callback, null, onUploadProgressCallback);
}

const DeleteRegularOrderFileService = (id, callback) => {
    let data = { id: id };
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.deleteFileAndFilm), data, callback);
}

const ResizeFileService = (id, width, height, callback) => {
    let data = {
        fileId: id,
        width: width,
        height: height
    };
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.resizeFile), data, callback);
}

const SaveOrderService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.saveOrder), data, callback);
}

const GetSpecificDimensionInfoService = (routeId, latWidth, latHeight, fileWidth, fileHeight, isBackSide, callback) => {
    let parameters = {
        productId: CommonHelper.toInt(routeId),
        latWidth,
        latHeight,
        fileWidth,
        fileHeight,
        isBackSide,
    }
    GetRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.getSpecificDimensionInfo), parameters, callback);
}

const GetBillBookPrintColorService = (id, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.getBillBookPrintColor), { id }, callback);
}

const GetBillBookOptionPriceService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.order, Links.api.order.billBookOptionPrice), data, callback);
}

export {
    GetProductBasicPriceService,
    GetProductAdvancePriceService,
    CheckFileBeforeUploadService,
    UploadImageFileService,
    UploadFilmFileService,
    DeleteRegularOrderFileService,
    GetFilePublicUrlService,
    ResizeFileService,
    SaveOrderService,
    GetSpecificDimensionInfoService,
    GetBillBookPrintColorService,
    GetBillBookOptionPriceService,
    PostDiscountService
}