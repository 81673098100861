import React from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import '../../../assets/sass/views/singlePages/shoppingBags.scss';

import { getTranslatedRawText, getUrlWithCurrentCulture } from "../../../utilities/CultureHelper";
import SpBasesFeatureCard from "../../singlePages/bases/card/SpBasesFeatureCard";
import bannerShoppingbag from '../../../assets/images/shoppingbag/bannerShoppingbag.png';
import customizeProduct from '../../../assets/images/shoppingbag/customizeProduct.svg';
import availableProducts from '../../../assets/images/shoppingbag/availableProducts.svg';
import ShoppingBagMainBanner from "./main/ShoppingBagMainBanner";
import Links from "../../../configs/links/AllLinks";
import CommonHelper from "../../../utilities/CommonHelper";


class ShoppingBagWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.shoppingBagFeatures = [
            {
                img: availableProducts,
                title: getTranslatedRawText('product.shoppingBag.onlineAvailableProducts'),
                text: getTranslatedRawText('product.shoppingBag.onlineAvailableProductsDescription'),
                button: getTranslatedRawText('product.shoppingBag.seeMore'),
                icon: CommonHelper.getNextThinIconByCulture(),
                onClick: () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBag), { onlineshoppingbag: true })
            },
            {
                img: customizeProduct,
                title: getTranslatedRawText('product.shoppingBag.customizeProduct'),
                text: getTranslatedRawText('product.shoppingBag.customizeProductDescription'),
                button: getTranslatedRawText('product.shoppingBag.seeMore'),
                icon: CommonHelper.getNextThinIconByCulture(),
                onClick: () => this.props.history.push(getUrlWithCurrentCulture(Links.view.main, Links.view.main.paperBag), { onlineshoppingbag: false })
            },
        ];
    }

    render() {
        return (
            <div className="background-white-color shopping-bag">

                <ShoppingBagMainBanner bannerBackground={bannerShoppingbag} />
                <Row className='shopping-bag__feature gap-4 mx-4 justify-content-center feature-row' >
                    {this.shoppingBagFeatures.map((item, index) =>
                        <Col md={4} key={index}>
                            <SpBasesFeatureCard item={item} />
                        </Col>
                    )}
                </Row>

                <div className="pb-2 p-3 px-md-5">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default withRouter(ShoppingBagWrapper);