import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsCard from '../../../../components/cards/NsCard';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import { GetOnlinePaymentsGatewayService } from '../../../../services/adminPanel/AdminDashboardService';
import { isEnCulture } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import ApBasesDashboardChartSkeleton from '../bases/ApBasesDashboardChartSkeleton';
import '../../../../assets/sass/views/adminPanel/AdminPanel.scss';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';
import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';

const ApDfOnlinePaymentGateway = (props) => {
    const paymentStatusOptions = ConstantsWithTranslation.getPaymentStatus(true);
    const { parameters, refreshDate } = props;
    const [paymentStatus, setPaymentStatus] = useState(paymentStatusOptions[0]?.id);
    const [data, setData] = useState({});
    const [dataDetails, setDataDetails] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        getOnlinePaymentsGateway();
    }, [refreshDate, paymentStatus]);

    const getOnlinePaymentsGateway = () => {
        let paymentStatusType = paymentStatus !== paymentStatusOptions[0]?.id ? paymentStatus : '';
        setIsLoading(true);
        GetOnlinePaymentsGatewayService({ ...parameters, paymentStateType: paymentStatusType }, getOnlinePaymentsGatewayCallback)
    };

    const getOnlinePaymentsGatewayCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setDoughnutChartOptions(resultData));
            setDataObject(ApBasesDashboardHelper.setDoughnutChartOptions(resultData));
        } else
            setMessageClassModal(messageClassModal);
    };

    const options = {

    }

    const setDataObject = (data) => {
        let secondData = data;
        let newData = [];

        secondData.labels.map(label =>
            newData.push({ label: label })
        );
        newData.map((item, index) => {
            item.value = secondData.datasets[0].data[index].value;
            item.backgroundColor = secondData.datasets[0].backgroundColor[index];
        })
        setDataDetails(newData);
    }

    return (!isEmpty(data) &&
        <NsCard className='p-3 h-100 '>
            <Row className='m-0 px-0 mb-auto align-items-center justify-content-between'>
                <ApBasesDashboardCharts title='adminPanel.dashboard.onlinePaymentsGateway'
                    height=''
                    type='doughnut'
                    tooltipTitle='adminPanel.dashboard.onlinePaymentsIpgChartDes'
                    data={data}
                    hasSelect={true}
                    options={options}
                    refreshClicked={getOnlinePaymentsGateway}
                    selectOptions={paymentStatusOptions}
                    selectValueChanged={(value) => setPaymentStatus(value)}
                    selectedItem={paymentStatus}
                    selectLabel='components.advanceSearch.paymentStatus'
                    isLoading={isLoading} />
            </Row>

            {isLoading
                ? <ApBasesDashboardChartSkeleton />
                : !isEmpty(data)
                    ? dataDetails.map((item, index) =>
                        <Row key={index} className='m-0 mb-2 px-0 align-items-center'>
                            <Col className='px-0 rounded' xs={1} style={{ backgroundColor: item.backgroundColor, width: '1rem', height: '1rem' }}></Col>
                            <Col className='px-0 ps-3' xs={8}>{item.label}</Col>
                            <Col className='px-0 text-end' xs={3}>{item.value.toFixed(2)} %</Col>
                        </Row>
                    )

                    : ''}
            {messageClassModal}
        </NsCard >
    );
};

export default React.memo(ApDfOnlinePaymentGateway);