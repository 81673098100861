import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { metaTagActionCreators } from '../../redux/reducers/MetaTagReducer';
import { getCurrentCulture } from '../../utilities/CultureHelper';
import CommonHelper from '../../utilities/CommonHelper';
import Config from '../../configs/Config';
import { getConfigObjectByCulture } from '../../utilities/ConfigHelper';
const metaTags = getConfigObjectByCulture(Config.metaTags);

class NsMetaTag extends React.Component {

    setMetaContent = (content) => {
        const contentKey = CommonHelper.getVariableName(content);
        return content[contentKey] || metaTags[contentKey];
    }

    render() {
        const { title, keywords, description, ogType, ogImage, author } = this.props;

        const pageTitle = !!title ? CommonHelper.stringFormat('{1} | {0}', metaTags.title, title) : metaTags.longTitle;
        const pageKeywords = this.setMetaContent({ keywords });
        const pageDescription = this.setMetaContent({ description });
        const pageOgType = this.setMetaContent({ ogType });
        const pageOgImage = this.setMetaContent({ ogImage });
        const pageOgDescription = this.setMetaContent({ description });
        const pageAuthor = this.setMetaContent({ author });
        return (
            <Helmet>
                {/* Change per page (default from global-config) */}
                <title>{CommonHelper.removeWhiteSpaceFromText(pageTitle)}</title>
                <link rel="shortcut icon" href={Config.favIcon} />
                <link rel="manifest" href={
                    !CommonHelper.isUAE()
                        ? `${process.env.PUBLIC_URL}/manifest.Ir.json`
                        : `${process.env.PUBLIC_URL}/manifest.Uae.json`
                } />

                <meta name="keywords" content={CommonHelper.removeWhiteSpaceFromText(pageKeywords)} />
                <meta name="description" content={CommonHelper.removeWhiteSpaceFromText(pageDescription)} />
                <meta name="og:title" content={CommonHelper.removeWhiteSpaceFromText(pageTitle)} />
                <meta name="og:type" content={pageOgType} />
                <meta name="og:image" content={pageOgImage} />
                <meta name="og:description" content={CommonHelper.removeWhiteSpaceFromText(pageOgDescription)} />

                {/* Dynamic from current page */}
                <meta name="language" content={getCurrentCulture()} />
                <meta name="og:url" content={window.location.href} />
                <meta name="url" content={window.location.href} />

                {/* GlobalConfig */}
                <meta name="subject" content={CommonHelper.removeWhiteSpaceFromText(metaTags.subject)} />
                <meta name="copyright" content={CommonHelper.removeWhiteSpaceFromText(metaTags.copyright)} />
                <meta name="author" content={pageAuthor} />
                <meta name="reply-to" content={metaTags.replyTo} />
                <meta name="identifier-URL" content={metaTags.identifierUrl} />
                <meta name="og:site_name" content={CommonHelper.removeWhiteSpaceFromText(metaTags.ogSiteName)} />
                <meta name="og:email" content={metaTags.ogEmail} />
                <meta name="og:phone_number" content={metaTags.ogPhoneNumber} />
                <meta name="og:fax_number" content={metaTags.ogFaxNumber} />
                <meta name="og:latitude" content={metaTags.ogLatitude} />
                <meta name="og:longitude" content={metaTags.ogLongitude} />
                <meta name="og:street-address" content={CommonHelper.removeWhiteSpaceFromText(metaTags.ogStreetAddress)} />
                <meta name="og:locality" content={CommonHelper.removeWhiteSpaceFromText(metaTags.ogLocality)} />
                <meta name="og:region" content={metaTags.ogRegion} />
                <meta name="og:postal-code" content={metaTags.ogPostalCode} />
                <meta name="og:country-name" content={CommonHelper.removeWhiteSpaceFromText(metaTags.ogCountryName)} />
                <meta name="google-site-verification" content={metaTags.googleSiteVerification} />

                {/* site preview when send copy of url */}
                {/* <meta property="og:title" content={CommonHelper.removeWhiteSpaceFromText(pageOgTitle)} />
                <meta property="og:description" content={CommonHelper.removeWhiteSpaceFromText(pageOgDescription)} />
                <meta property="og:site_name" content={CommonHelper.removeWhiteSpaceFromText(metaTags?.ogTitle)} />
                <meta property="og:image" content={pageOgImage} />
                <meta property="og:type" content={pageOgType} />
                <meta property="og:image:alt" content="logo" />
                <meta property="og:url" content={window.location.href} /> */}


                {/* hardcode */}
                <meta name="robots" content="index,follow" />
            </Helmet>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        title: state.metaTag.title,
        keywords: state.metaTag.keywords,
        description: state.metaTag.description,
        ogTitle: state.metaTag.ogTitle,
        ogType: state.metaTag.ogType,
        ogImage: state.metaTag.ogImage,
        ogDescription: state.metaTag.ogDescription,
        author: state.metaTag.author
    };
}

const mapDispachToProps = (dispatch) => {
    return bindActionCreators({ ...metaTagActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(NsMetaTag);