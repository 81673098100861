import React, { Component } from 'react';

import { DownloadEditedOrderFileService, GetUserItemsService } from '../../../../../services/ProfileSetting/UserOrderService';
import AddFileCorrectionModal from '../../../modals/addFileCorrection/AddFileCorrectionModal';
import { getTranslatedRawText, isEnCulture } from '../../../../../utilities/CultureHelper';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import NsTypography from '../../../../../components/text/NsTypography';
import NsDataGrid from '../../../../../components/grids/NsDataGrid';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../../UserPanelWrapper';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';

class FileCorrection extends Component {

    constructor(props) {
        super(props);
        this.memoizeCreateStickyItem = CommonHelper.memoizeFunc(CommonHelper.createStickyButtonItem);
        this.mainButtons = [CommonHelper.createStickyButtonItem("profile.fileCorrection.NewFileCorrection", 'ns-icon-add', this.fileCheckingClicked, 'primary')];
        this.downloadLink = React.createRef();
        this.state = {
            isShowFileCorrectionModal: false,
            isLoading: false,
            fileItems: [],
            resultMessageClass: (<></>),
            image: null,
            selectedFile: null,
            href: null,
            messageItems: [],
            isShowResultModal: false

        }
        this.columns = [
            {
                field: 'downloadCleanFile',
                headerName: getTranslatedRawText('profile.fileCorrection.downloadCleanFile'),
                width: 165,
                lockPosition: true,
                resizable: false,
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (props) => <NsButton className='secondary-text px-0'
                        startIcon='ns-icon-download cursor-pointer'
                        onClick={() => this.downloadClicked(props.id)} />
                },
            },
            {
                field: 'sharedSheetDraftOrderStatusType',
                headerName: getTranslatedRawText('profile.fileCorrection.status'),
                cellRenderer: "customRowRenderer",
                cellRendererParams: {
                    customElementRender: (props) => {
                        return <div>
                            {ConstantsWithTranslation.getFileCheckingStatus(props.sharedSheetDraftOrderStatusType)}
                        </div>
                    }
                }

            },
            // {
            //     field: 'fileThumbnail',
            //     headerName: getTranslatedRawText('profile.fileCorrection.filePreview'),
            //     width: 100,
            //     cellRenderer: "customRowRenderer",
            //     cellRendererParams: {
            //         hasImageFront: true,
            //     },

            // },
            {
                field: 'productName',
                headerName: getTranslatedRawText('profile.fileCorrection.product'),
                width: 230,
            },
            {
                field: 'createDate',
                headerName: getTranslatedRawText('profile.fileCorrection.createDate'),
                width: 150,
                dataType: Enumerations.gridType.dateTime,
            },
            {
                field: 'adminDescription',
                headerName: getTranslatedRawText('profile.fileCorrection.adminDescription'),
                width: 200,
            },
        ]
    }

    componentDidMount = () => this.setState({ isLoading: true }, () => this.getUserItems());

    fileCheckingClicked = () => this.setState({ isShowFileCorrectionModal: true });

    closeFileCorrectionModal = () => this.setState({ isShowFileCorrectionModal: false, isLoading: true }, () => this.getUserItems());

    getUserItems = () => {
        const data = {
            pageLength: 100,
            currentPageIndex: 1,
        }
        GetUserItemsService(data, this.getUserItemsCallback);
    }

    getUserItemsCallback = (resultDate, result, resultMessageClass) => {
        if (!result?.data?.hasError) {
            this.setState({
                fileItems: resultDate.dataItems,
                isLoading: false
            })
        }
        else
            this.setState({ resultMessageClass });
    }

    downloadFile = (id) => {
        const data = {
            sharedSheetDraftOrderId: id
        }
        DownloadEditedOrderFileService(data, this.downloadFileCallback)
    }

    downloadFileCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError) {

            this.prepareFilesForDownload(resultData, 0);
        }
        else
            this.setState({ resultMessageClass, isLoading: false });

    }

    prepareFilesForDownload = (files, index) => {

        if (files.length <= index) {
            this.setState({ isLoading: false });
            return;
        }

        const href = !isEmpty(files) && CommonHelper.createDownloadFileUrl(files[index].fileName, files[index].editedFile);
        this.downloadLink.current.download = files[index].fileName;
        this.setState({
            image: files[index].editedFile,
            href: href
        }, () => {
            href && this.downloadLink.current.click();
            let that = this;
            setTimeout(() => {
                that.prepareFilesForDownload(files, index + 1)
            }, 1000);
        });
    }

    setMessageItems = (message) => {
        return [{
            messageText: (
                <span>
                    <span>{getTranslatedRawText(message)}</span>
                </span>
            ),
            messageType: Enumerations.messageType.info
        }];
    }

    downloadClicked = (id) => {
        this.setState({
            selectedFile: this.state.fileItems.find(item => item.id === id),
            isLoading: true
        }, () => this.state.selectedFile.sharedSheetDraftOrderStatusType !== 3
            ? this.setState({
                messageItems: this.setMessageItems('profile.fileCorrection.yourFileIsNotReady'),
                isShowResultModal: true,
                isLoading: false
            })
            : this.downloadFile(this.state.selectedFile.id))
    }

    openMessagePopup = (message) => {
        this.setState({
            resultMessageClass: message
        });
    }
    closeInfoModal = () => { this.setState({ isShowResultModal: false, messageItems: [] }) }

    render() {
        const { isLoading, fileItems, resultMessageClass, href } = this.state;
        return (
            <UserPanelWrapper titleName={getTranslatedRawText("profile.actionButton.fileCorrection")}
                titleIcon='ns-icon-inprocess-order'>

                <div className="w-100">
                    <div>
                        <NsTypography variant='inherit' className='fw-bold'>
                            {getTranslatedRawText("profile.fileCorrection.noticeText")}
                        </NsTypography>
                    </div>
                    <div className=' my-3'>
                        <NsTypography variant='inherit' className='fw-bold'>
                            {getTranslatedRawText("profile.fileCorrection.secondNoticeText")}
                        </NsTypography>
                    </div>
                    <NsStickyButton stickyButtons={this.mainButtons} />
                    <div className='w-100 mt-3'>
                        <NsDataGrid totalRows={fileItems.length}
                            isLoading={isLoading}
                            refreshButtonClicked={this.getUserItems}
                            columnDefs={this.columns}
                            rows={fileItems} />
                    </div>
                </div>

                {this.state.isShowFileCorrectionModal &&
                    <AddFileCorrectionModal showModal={this.state.isShowFileCorrectionModal}
                        closeModal={this.closeFileCorrectionModal}
                        openMessagePopup={this.openMessagePopup} />
                }

                {resultMessageClass}
                {this.state.isShowResultModal && <NsMessageClassModal show={this.state.isShowResultModal}
                    onClose={this.closeInfoModal}
                    messageItems={this.state.messageItems} />}
                <a ref={this.downloadLink} target='_blank' href={href} />
            </UserPanelWrapper >
        );
    }
}

export default FileCorrection;