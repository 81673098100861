import React, { useState } from 'react';
import NsTab from '../../../../../components/tabs/NsTab';
import ApSinglePages from '../ApSinglePages';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';

const ApAgreementContent = ({ agreementId }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(Enumerations.staticPageType.termOfUse);

    const tabChanged = (tabIndex, selectedTab) => {
        setSelectedTabIndex(tabIndex);
        setSelectedTab(selectedTab.id);
    }

    return (
        <div>
            <NsTab tabs={ConstantsWithTranslation.getApAgreementsTabs()}
                value={selectedTabIndex}
                onChange={tabChanged} />
            <ApSinglePages key={selectedTab} agreementId={agreementId} defaultSelectedItemId={selectedTab} />
        </div>
    );
};

export default ApAgreementContent;