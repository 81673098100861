import clsx from 'clsx';
import React from 'react';
import { InputAdornment, TextField } from '@mui/material';

import '../../assets/sass/components/inputs/TextField.scss';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CommonHelper from '../../utilities/CommonHelper';

class NsTextField extends React.Component {

    constructor(props) {
        super(props);
        this.input = '';
        this.state = {
            input: props.value ? this.checkAndSendValue(props.value, true) : '',
            helperText: '',
            minLength: props.minLength ?? 3,
            invisibleHelperText: this.messageHelperText(),
        };
    }

    messageHelperText = () => {
        return CommonHelper.stringFormat(getTranslatedRawText("common.messages.enter"), this.props.label);
    }

    changeInput = (event) => {
        CommonHelper.safeFunctionCall(this.props?.isInputChangedByUser, true)
        this.setInput(event.target.value);
    }

    setInput = (value) => {
        this.setState({ input: value, helperText: '' });
        let isValidInput = this.getIsValidInput(value);
        this.checkAndSendValue(value, isValidInput);
    }

    checkAndSendValue = (value, isValidInput) => {
        CommonHelper.safeFunctionCall(this.props.valueChanged, value, isValidInput);
    }

    getIsValidInput = (value) => {
        if (value.length >= this.state.minLength) {
            this.setState({ invisibleHelperText: '' });
            return true;
        } else if (!value) {
            this.setState({ invisibleHelperText: this.messageHelperText() });
            return false;
        }
        else {
            this.setState({ invisibleHelperText: CommonHelper.stringFormat(getTranslatedRawText("common.messages.lengthCondition"), this.props.label, this.state.minLength) });
            return false;
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {

        if (!!newProps.value) {
            if (newProps.value != this.state.input)
                this.setInput(newProps.value);
        }
        else
            this.setState({ input: '', invisibleHelperText: this.messageHelperText() });

        if (newProps.showValidationDate !== this.props.showValidationDate)
            this.setState({ helperText: this.state.invisibleHelperText });
    }

    render() {
        const { showValidationDate, valueChanged, helperTxt, minLength, margin, endAdornment, placeholder, InputProps, ...otherProps } = this.props;
        return (
            <TextField inputRef={el => this.input = el}
                {...otherProps}
                variant='standard'
                className={clsx('ns-text-field', otherProps.className)}
                helperText={helperTxt || this.state.helperText}
                type='text'
                disabled={this.props.disabled}
                value={this.state.input || ''}
                onChange={this.changeInput}
                margin={margin}
                autoFocus={this.props.autoFocus}
                placeholder={placeholder}
                InputProps={
                    endAdornment ? {
                        endAdornment: <InputAdornment position="end">
                            {
                                typeof endAdornment === 'string' || endAdornment instanceof String
                                    ? getTranslatedRawText(endAdornment)
                                    : endAdornment
                            }
                        </InputAdornment>,
                    } : InputProps
                }
            />
        );
    }
}

export default NsTextField;