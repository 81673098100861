import React from 'react';

import NsModal from '../../../../components/modals/NsModal';
import { GetFilterPointsService } from '../../../../services/ProfileSetting/UserOrderService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import FiltersContent from './FiltersContent';

class FiltersModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resultData: null,
            isLoading: true,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => GetFilterPointsService({ id: this.props.id, isFront: this.props.isFront }, this.getFilterPointCallback);

    getFilterPointCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError)
            this.setState({ resultData, isLoading: false });
        else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    render() {

        const { resultData, isLoading, resultMessageClass } = this.state;
        const { file, isFront } = this.props;

        return (
            <NsModal showModal={true}
                maxWidth="sm"
                dialogTitle={CommonHelper.stringFormat('{0}({1})', getTranslatedRawText('regularOrder.extendedService.filter'),
                    getTranslatedRawText(isFront ? 'regularOrder.common.frontSide' : 'regularOrder.common.backSide'))}
                closeModal={this.props.closeModal}>

                <FiltersContent resultData={resultData}
                    isLoading={isLoading}
                    file={file} />

                {resultMessageClass}

            </NsModal >
        );
    }
}

export default FiltersModal;