import React, { useEffect, useState } from 'react';

import NsModal from '../../../../../../components/modals/NsModal';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { EditActivateUserAgreementStateService, GetSelectedUserAgreementService } from '../../../../../../services/adminPanel/AdminAgreementService';
import NsLabelBox from '../../../../../../components/labels/NsLabelBox';
import ApCurrentActiveAgreementDetailModal from './ApCurrentActiveAgreementDetailModal';
import NsSwitch from '../../../../../../components/switch/NsSwitch';
import NsStickyButton from '../../../../../../components/buttons/NsStickyButton';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { hasAccess } from '../../../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../../../configs/UserClaimEnumerations';

const ApAgreementsStatusModal = ({ agreementId, closeModal, closeModalWithMessageClass }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [agreementData, setAgreementData] = useState(null);
    const [showAgreementDetailModal, setShowAgreementDetailModal] = useState(false);
    const [isActiveAgreement, setIsActiveAgreement] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        getSelectedUserAgreement();
    }, [])

    const getSelectedUserAgreement = () => {
        setIsLoading(true);
        GetSelectedUserAgreementService(agreementId, getSelectedUserAgreementCallback)
    }

    const getSelectedUserAgreementCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setAgreementData(resultData);
            setIsActiveAgreement(resultData.isActive);
        } else {
            setAgreementData(null);
            setMessageClassModal(messageClassModal);
        }
    }

    const saveButtonClicked = () => {
        EditActivateUserAgreementStateService(agreementId,
            { isActive: isActiveAgreement },
            activateUserAgreementStateCallback,
            true)
    }

    const activateUserAgreementStateCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            CommonHelper.safeFunctionCall(closeModalWithMessageClass, messageClassModal);
            setAgreementData(null);
        } else {
            setMessageClassModal(messageClassModal);
        }
    }

    const showAgreementDetailClicked = () => {
        if (!hasAccess([UserClaimEnumerations.getApOrderUserAggrementDetail])) return;
        setShowAgreementDetailModal(!showAgreementDetailModal);
    }

    const mainButtons = [
        hasAccess([UserClaimEnumerations.updateApUserAggrementState]) && {
            label: "common.save",
            icon: 'ns-icon-save',
            onClicked: saveButtonClicked,
            className: 'primary',
            disabled: !isActiveAgreement
        },
        {
            label: "common.cancel",
            icon: 'ns-icon-close',
            onClicked: closeModal,
            className: 'primary-outline'
        },
    ];

    const activateStatusChanged = (isChecked) => setIsActiveAgreement(isChecked);

    return (
        <NsModal maxWidth="sm"
            headerClassName='px-3'
            dialogTitle={getTranslatedRawText('adminPanel.singlePage.agreementStatus')}
            showModal={true}
            noSpace={true}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}
            closeModal={closeModal}>

            <AgreementsDetail agreementData={agreementData}
                showAgreementDetailClicked={showAgreementDetailClicked} />
            <div className='px-3'>
                <label className='font-size-14 my-3'>{getTranslatedRawText('adminPanel.singlePage.agreementAttention')}</label>
                <NsSwitch label='adminPanel.slider.active'
                    checked={isActiveAgreement || false}
                    onChange={activateStatusChanged} />

            </div>
            {showAgreementDetailModal && !!agreementData?.lastActiveUserAgreement.id &&
                <ApCurrentActiveAgreementDetailModal agreementId={agreementData.lastActiveUserAgreement.id}
                    closeModal={showAgreementDetailClicked} />
            }
            {messageClassModal}
        </NsModal>
    );
};

function AgreementsDetail(props) {
    const { agreementData, showAgreementDetailClicked } = props;

    return (<div className='background-color px-3 py-2 d-flex flex-wrap gap-2'>
        {agreementData?.lastActiveUserAgreement && <NsLabelBox className='background-white-color min-size'
            label={getTranslatedRawText('adminPanel.singlePage.selectedAgreement')}
            value={agreementData?.title} />}

        {!!agreementData?.lastActiveUserAgreement.title && <NsLabelBox className='cursor-pointer background-white-color'
            label={getTranslatedRawText('adminPanel.singlePage.activeAgreement')}
            value={<span>
                {agreementData?.lastActiveUserAgreement.title}
                {hasAccess([UserClaimEnumerations.getApOrderUserAggrementDetail]) && <i className='ns-icon-expand-right' />}
            </span>}
            onClick={showAgreementDetailClicked} />}
    </div>)
}

export default ApAgreementsStatusModal;