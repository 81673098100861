import React from 'react';

import UpStoreReadyToDeliverOrderDetailModal from '../readyToDeliver/UpStoreReadyToDeliverOrderDetailModal';
import { GetRetryToReadyStateService } from '../../../../../../services/ProfileSetting/StoreOrderService';
import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import { createStoreOrderColumns } from './UpBaseStoreOrderHelper';

class UpBaseStoreOrdersGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDetailModal: false,
            orderId: null,
            resultMessageClass: <></>,
            columnDefs: createStoreOrderColumns(this.detailViewClicked, props.hasRetry && this.refreshClicked),
            callServiceDate: this.props.callServiceDate,
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.callServiceDate !== prevProps.callServiceDate) this.setState({ callServiceDate: this.props.callServiceDate });
    };

    detailViewClicked = (id) => !!this.props.detailViewClicked ? this.props.detailViewClicked(id) : this.setState({ showDetailModal: true, orderId: id });

    getRetryToReadyStateCallBack = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) this.setState({ callServiceDate: new Date() })
        this.setState({ resultMessageClass: messageClassModal });
    }

    refreshClicked = (props) => {
        if (isEmpty(props.id)) return;
        let parameters = { orderId: props.id }
        GetRetryToReadyStateService(parameters, this.getRetryToReadyStateCallBack)
    }

    closeDetailModalClicked = () => this.setState({ showDetailModal: false });

    render() {
        const { service, hasCheckbox, hasCustomColumnOrder, gridId, parameters } = this.props;
        const { showDetailModal, orderId, resultMessageClass, callServiceDate, columnDefs } = this.state;

        return (
            <div className='w-100'>
                <NsDataGridWithServiceCall key={gridId}
                    callServiceDate={callServiceDate}
                    service={service}
                    parameters={{ ...parameters }}
                    columnDefs={columnDefs}
                    hasPagination={true}
                    hasCheckbox={hasCheckbox}
                    selectedRows={this.props.selectionChanged}
                    gridId={gridId}
                    hasCustomColumnOrder={hasCustomColumnOrder}
                    getData={this.props.getData} />
                {showDetailModal &&
                    <UpStoreReadyToDeliverOrderDetailModal closeModal={this.closeDetailModalClicked}
                        orderId={orderId} />
                }
                {resultMessageClass}
            </div>
        );
    }
}

export default UpBaseStoreOrdersGrid;

