import { getUrlWithCurrentCulture } from '../../utilities/CultureHelper';
import { GetRequest, PostRequest } from '../bases/BaseService';
import Links from '../../configs/links/AllLinks';


const GetAvailableOrdersForShippingService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getAvailableOrdersForShipping), parameters, callback);
}

const GetAddressesForAutoDeliveryService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getAddressesForAutoDelivery), {}, callback);
}

const GetDefaultDeliveryAddressService = (callback, showSuccessResponseMessage) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getDefaultDeliveryAddress), {}, callback, showSuccessResponseMessage);
}

const EditDefaultDeliveryAddressService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.autoDelivery), data, callback, showSuccessResponseMessage);
}

const PostInPersonDeliveryService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.inPersonDelivery), data, callback, showSuccessResponseMessage);
}

const GetAddressesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getAddresses), parameters, callback);
}

const GetAddressDetailService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getAddressDetail), parameters, callback);
}

const AddAddressService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.addAddress), data, callback, showSuccessResponseMessage);
}

const EditAddressService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.editAddress), data, callback, showSuccessResponseMessage);
}

const DeleteAddressService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.deleteAddress), data, callback);
}

const GetCountryService = (callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getCountry), {}, callback);
}

const GetStatesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getStates), parameters, callback);
}

const GetCitiesService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getCities), parameters, callback);
}

const SubmitDeliveryRequestService = (data, callback) => {
    PostRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.submitDeliveryRequest), data, callback, true);
}

const GetShippingPlansService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getShippingPlans), parameters, callback);
}
const GetShippingMethodsService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getShippingMethods), parameters, callback);
}
const GetShippingMethodService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getShippingMethod), parameters, callback);
}
const GetShippingCostService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getShippingCost), parameters, callback);
}
const GetShippingPlanAvailabilityService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getShippingPlanAvailability), parameters, callback);
}
const GetShippingEstimatedTimeSheetService = (parameters, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.api.profile, Links.api.profile.getShippingEstimatedTimeSheet), parameters, callback);
}

export {
    GetAvailableOrdersForShippingService,
    GetAddressesForAutoDeliveryService,
    GetDefaultDeliveryAddressService,
    EditDefaultDeliveryAddressService,
    GetAddressesService,
    GetAddressDetailService,
    AddAddressService,
    EditAddressService,
    DeleteAddressService,
    GetCountryService,
    GetStatesService,
    GetCitiesService,
    SubmitDeliveryRequestService,
    PostInPersonDeliveryService,
    GetShippingPlansService,
    GetShippingMethodsService,
    GetShippingMethodService,
    GetShippingCostService,
    GetShippingPlanAvailabilityService,
    GetShippingEstimatedTimeSheetService
}