import Links from "../../configs/links/AllLinks";
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { GetRequest } from "../bases/BaseService";

const GetCmsLogService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCmsLog), parameter, callback);
};

const GetCmsLogByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCmsLogById), id), {}, callback);
};

const GetCmsLogExceptionTypesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCmsLogExceptionTypes), parameter, callback);
};

const GetCmsLogSourcesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCmsLogSources), parameter, callback);
};

const GetCmsLogCustomLogExceptionService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApCmsLogCustomLogException), parameter, callback);
};

export {
    GetCmsLogService,
    GetCmsLogByIdService,
    GetCmsLogExceptionTypesService,
    GetCmsLogSourcesService,
    GetCmsLogCustomLogExceptionService
}