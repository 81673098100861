import React from 'react';

import NsTab from '../tabs/NsTab';
import ConstantsWithTranslation from '../../configs/ConstantsWithTranslation';
import NsEditFileActionAlign from './NsEditFileActionAlign';
import NsEditFileActionResize from './NsEditFileActionResize';
import NsEditFileActionRotate from './NsEditFileActionRotate';
import NsEditFileActionFlip from './NsEditFileActionFlip';

class NsEditFileActionTab extends React.Component {
    constructor(props) {
        super(props);
        this.tabs = ConstantsWithTranslation.getFileManagerEditFileModalTabs();
        !props.hasPositioning && this.tabs.splice(0, 1);
        this.state = {
            selectedTabIndex: 0,
            selectedTab: this.tabs[0],
        };
    }

    tabChanged = (selectedTabIndex, selectedTab) => {
        selectedTabIndex !== this.state.selectedTabIndex && this.setState({ selectedTabIndex, selectedTab });
    }

    render() {
        const { imgContent, imgWidth, imgHeight, setNewContent, imgContentActionsChanged, imgContentSizeChanged, changeisLoadingState,
            alignFilm, hasPositioning, tabClassName } = this.props;
        let tabIndexHandler = hasPositioning ? 0 : -1;

        return (

            <div className='d-flex flex-column border rounded background-element-color'>

                <NsTab tabs={this.tabs}
                    value={this.state.selectedTabIndex}
                    tabClassName={tabClassName}
                    onChange={this.tabChanged}>
                </NsTab>

                {this.state.selectedTabIndex === tabIndexHandler && hasPositioning &&
                    <NsEditFileActionAlign alignFilm={alignFilm} />
                }

                {
                    this.state.selectedTabIndex === tabIndexHandler + 1 &&
                    <>
                        <NsEditFileActionResize imgWidth={imgWidth}
                            imgHeight={imgHeight}
                            imgContentSizeChanged={imgContentSizeChanged} >

                        </NsEditFileActionResize>
                        <div className='background-white-color px-2'>
                            {this.props.children}
                        </div>
                    </>
                }

                {
                    this.state.selectedTabIndex === tabIndexHandler + 2 &&
                    <NsEditFileActionRotate imgContent={imgContent}
                        changeisLoadingState={changeisLoadingState}
                        setNewContent={setNewContent}
                        imgContentActionsChanged={imgContentActionsChanged} />
                }

                {
                    this.state.selectedTabIndex === tabIndexHandler + 3 &&
                    <NsEditFileActionFlip imgContent={imgContent}
                        changeisLoadingState={changeisLoadingState}
                        setNewContent={setNewContent}
                        imgContentActionsChanged={imgContentActionsChanged} />
                }
            </div>
        );
    }
}

export default NsEditFileActionTab;