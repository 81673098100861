import React from 'react';

import LinkToShopBannerEn from '../../../assets/images/specialNeeds/Banner_Slidebar_EN.png';
import LinkToShopBannerFa from '../../../assets/images/specialNeeds/Banner_Slidebar_FA.png';
import NsCard from '../../../components/cards/NsCard';
import NsImage from '../../../components/images/NsImage';
import Links from '../../../configs/links/AllLinks';
import { getUrlWithCurrentCulture, isEnCulture } from '../../../utilities/CultureHelper';

const SpSidebarLinkToShopBanner = (props) => {
    return (
        <div className="mb-3">
            <NsCard>
                <a href={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)}>
                    <NsImage src={isEnCulture() ? LinkToShopBannerEn : LinkToShopBannerFa}
                        imgAlt="link to shop"
                        className='w-100 max-height-10rem' />
                </a>
            </NsCard>
        </div >
    );
};

export default SpSidebarLinkToShopBanner;