import clsx from 'clsx';
import React, { Component } from 'react';

import NsHtmlToReact from '../../../../components/htmlToReact/NsHtmlToReact';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import BnBasesPostSpecification from './BnBasesPostSpecification';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../utilities/CommonHelper';
import NsImage from '../../../../components/images/NsImage';
import { ContentCategoryButton } from './BnBasesPostCard';
import NsCard from '../../../../components/cards/NsCard';
import NsTags from '../../../../components/tags/NsTags';
import Links from '../../../../configs/links/AllLinks';

class BnBasesDetailCard extends Component {

	render() {
		const { post, className, isBlog } = this.props;
		return (
			<div className={clsx("d-inline d-md-inline-block p-1 w-100", className)} >
				<NsCard className='p-2'>
					<h1 className='section-title'>
						{post?.title}
					</h1>
					<BnBasesPostSpecification createAt={post?.createAt}
						createByUser={post?.createByUser}
						createByUserId={post?.userId} />
					<div className='d-flex gap-1 mb-3'>
						<ContentCategoryButton
							categories={post?.pageCategories}
							categoryClicked={this.props.categoryClicked} />
					</div>
					{/* <div className='blogs__categories__img blogs__categories__img__solid' style={{ '--category-url': `url("${CommonHelper.createImageUrl(post?.fileUrl)}")` }} /> */}
					<NsImage src={CommonHelper.createImageUrl(post?.fileUrl)}
						className='w-100'
						imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.blogImage"), post?.title)} />
					<div className='d-block p-3'>
						<NsHtmlToReact>
							{post?.mainContent}
						</NsHtmlToReact>
					</div>
					<div className='d-flex font-size-12 mb-2 d-none'>
						<i className='ns-icon-share' />&nbsp;
						<span className='section-title'>
							{getTranslatedRawText('adminPanel.newsBlog.share')}
						</span>
						&nbsp;
						<i className='ns-icon-menu' />&nbsp;
						<i className='ns-icon-menu' />&nbsp;
						<i className='ns-icon-menu' />&nbsp;
						<i className='ns-icon-menu' />&nbsp;
					</div>
					{!isEmpty(post?.tags)
						? <>
							<div className='mb-2 ms-2 fw-bold'>{getTranslatedRawText('adminPanel.post.tags')}</div>
							<NsTags tags={post?.tags.split(/[-,]/)}
								linkToMaster={isBlog ? Links.view.main.blog : Links.view.main.news}
								linkToSlave={isBlog ? Links.view.main.blog.blogSearchResult : Links.view.main.news.newsSearchResult} />
						</>
						: ''}

				</NsCard >
			</div >
		);
	}
}

export default BnBasesDetailCard;