import React from 'react';

import { GetShippingRequestsByStatusService } from '../../../../services/ProfileSetting/UserOrderService';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import { createMyShippingColumn } from '../userOrder/groupSheets/bases/UpBaseGroupSheetOrderHelper';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import UpBaseShippingSearchSection from '../userShippings/bases/UpBaseShippingSearchSection';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import NsStickyButton from '../../../../components/buttons/NsStickyButton';
import UpShippingRequest from './shippingRequests/UpShippingRequest';
import GridEnumerations from '../../../../configs/GridEnumerations';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';
import Links from '../../../../configs/links/AllLinks';
import UserPanelWrapper from '../../UserPanelWrapper';
import NsTab from '../../../../components/tabs/NsTab';
import UpShippingDetail from './UpShippingDetail';

class UpShippings extends React.Component {

    constructor(props) {
        super(props);
        this.mainButtons = [
            { label: "profile.readyToDeliver.addShippingRequest", icon: 'ns-icon-add', onClicked: this.submitDeliveryClicked, className: 'primary' },
        ];
        this.tabs = ConstantsWithTranslation.getDeliveryRequestTabs();
        this.selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);
        this.state = {
            selectedTab: this.selectedTab || this.tabs[0],
            selectedTabIndex: this.tabs.indexOf(this.selectedTab) > -1 ? this.tabs.indexOf(this.selectedTab) : 0,
            orderId: null,
            selectedRowIds: [],
            showAddShipmentModal: window.history.state?.state?.isShowShipmentModal || false,
            shippingDetail: null,
            searchValues: null,
            isLoading: false,
            callServiceDate: new Date(),
            showDetailModal: false,
            resultMessageClass: (<></>)
        };
    }

    detailViewClicked = (shippingDetail) => {
        this.setState({ showDetailModal: true, orderId: shippingDetail?.id, shippingDetail });
        this.props.history.push(CommonHelper.stringFormat(
            getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.deliveryRequestDetail)
            , shippingDetail?.id));
    }

    closeDetailModalClicked = (messages) => {
        this.setState({ showDetailModal: false });
        !!this.props.history && this.props.history.push(this.state.selectedTab?.url);
    };

    submitDeliveryClicked = () => this.setState({ showAddShipmentModal: true });

    closeAddShippmentRequestModalClicked = (isOperationSuccessful, resultMessageClass) =>
        this.setState({
            showAddShipmentModal: false,
            resultMessageClass: isOperationSuccessful ? resultMessageClass : '',
            callServiceDate: new Date()
        });

    getGridId = () => {
        switch (this.state.selectedTab?.id) {
            case Enumerations.transferStateType.inTheWarehouse: return GridEnumerations.siteGrids.myShippingInTheWarehouse
            case Enumerations.transferStateType.exitingWareHouse: return GridEnumerations.siteGrids.myShippingExitingWarehouse
            case Enumerations.transferStateType.sendingToCourier: return GridEnumerations.siteGrids.myShippingSendingToCourier
            case Enumerations.transferStateType.deliveredToCourier: return GridEnumerations.siteGrids.myShippingDeliveredToCourier
            case Enumerations.transferStateType.deliveredToCustomer: return GridEnumerations.siteGrids.myShippingDeliveredToCustomer
            default: return GridEnumerations.siteGrids.myShippingInTheWarehouse
        }
    }

    tabChanged = (newValue, selectedTab) =>
        newValue !== this.state.selectedTab && this.setState({ selectedTabIndex: newValue, selectedTab, callServiceDate: new Date() }, () => {
            this.props.history.push(selectedTab.url);
        });

    searchFormClicked = (searchValues) => this.setState({ searchValues, isLoading: true, callServiceDate: new Date() });

    render() {
        const { showAddShipmentModal, selectedTabIndex, selectedTab, showDetailModal, shippingDetail, callServiceDate, searchValues,
            resultMessageClass, isLoading } = this.state;
        const gridId = this.getGridId();
        return (
            <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.deliveryRequest')}
                titleIcon='ns-icon-delivery-request'>
                <UpBaseShippingSearchSection
                    searchFormClicked={this.searchFormClicked}
                    autoFocus
                    isLoading={isLoading}>
                </UpBaseShippingSearchSection>

                <div className="w-100 mx-auto">
                    <NsStickyButton stickyButtons={this.mainButtons} />

                    <div className='w-100 mt-3'>
                        <NsTab value={selectedTabIndex}
                            tabs={this.tabs}
                            onChange={this.tabChanged} />
                    </div>
                    <div className='w-100'>
                        <NsDataGridWithServiceCall key={gridId}
                            columnDefs={createMyShippingColumn(this.detailViewClicked, selectedTab.id == Enumerations.transferStateType.sendingToCourier)}
                            callServiceDate={callServiceDate}
                            service={GetShippingRequestsByStatusService}
                            parameters={{
                                filter: {
                                    transferStateType: selectedTab.id,
                                    ...searchValues
                                }
                            }}
                            gridId={gridId}
                            hasCustomColumnOrder={true} />
                    </div>
                </div>

                {showAddShipmentModal &&
                    <UpShippingRequest orderProductType={Enumerations.orderProductType.sharedSheet}
                        closeModal={this.closeAddShippmentRequestModalClicked} />
                }

                {showDetailModal &&
                    <UpShippingDetail closeModal={this.closeDetailModalClicked}
                        shippingDetail={shippingDetail}
                        orderId={this.state.orderId} />
                }
                {resultMessageClass}
            </UserPanelWrapper >
        );
    }
};

export default UpShippings;

export function changeAddressAndTelProperty(tempShippingMethodInformation) {
    switch (tempShippingMethodInformation?.shippingMethodCategoryTypeId) {
        case Enumerations.shippingMethodCategoryType.inPersonDelivery: {
            tempShippingMethodInformation.countyWarehouseAddress = tempShippingMethodInformation.shippingMethodAddress;
            tempShippingMethodInformation.countyWarehouseTel1 = tempShippingMethodInformation.shippingMethodPhoneNumberOne;
            tempShippingMethodInformation.countyWarehouseTel2 = tempShippingMethodInformation.shippingMethodPhoneNumberTwo;
            break;
        }
        case Enumerations.shippingMethodCategoryType.doorToDoor: {
            tempShippingMethodInformation.senderAddress = tempShippingMethodInformation.shippingMethodAddress;
            tempShippingMethodInformation.senderTel1 = tempShippingMethodInformation.shippingMethodPhoneNumberOne;
            tempShippingMethodInformation.senderTel2 = tempShippingMethodInformation.shippingMethodPhoneNumberTwo;
            break;
        }
        case Enumerations.shippingMethodCategoryType.cargo:
        default: {
            tempShippingMethodInformation.courierAddress = tempShippingMethodInformation.shippingMethodAddress;
            tempShippingMethodInformation.courierTel1 = tempShippingMethodInformation.shippingMethodPhoneNumberOne;
            tempShippingMethodInformation.courierTel2 = tempShippingMethodInformation.shippingMethodPhoneNumberTwo;
            break;
        }
    }
    tempShippingMethodInformation.shippingMethodAddress = null;
    tempShippingMethodInformation.shippingMethodPhoneNumber1 = null;
    tempShippingMethodInformation.shippingMethodPhoneNumber2 = null;
    return tempShippingMethodInformation;
};