// Config object
const Enumerations = {

	culture: {
		none: 0,
		en: 1,
		fa: 2
	},

	loginLayoutType: {
		none: 0,
		signIn: 1,
		signup: 2,
	},

	gender: {
		none: 0,
		male: 1,
		female: 2
	},

	direction: {
		none: 0,
		ltr: 1,
		rtl: 2
	},

	pageContentCommentType: {
		none: 0,
		allow: 1,
		disable: 2,
		allowWithAdminConfirmation: 3,
	},

	storage: {
		none: 0,
		culture: 1,
		countryId: 2,
		mobile: 3,
		username: 4,
		persistentMobile: 5,
		currentUserInfo: 6,
		orders: 7,
		tours: 8,
		isAcceptPayWarningModal: 9,
		showFestivalEventPromotion: 10,
		festivalCongratulationModals: 11,
		showAddToListProfessionalOrderModal: 12,
		userCurrency: 13,
		fileManagerContentDetailStyle: 14,
		customerAgency: 15,
		countryData: 16,
		hideA2hs: 17,
		announcementIds: 18,
		festivalId: 19,
		adminSidebarTop: 20,
		shoppingBagHistories: 21,
		gridColumnOrder: 22,
		dontShowPaymentOfficialInvoiceModal: 23,
		// isFestivalEventCollapse: 24,
	},

	accountType: {
		none: 0,
		personal: 1,
		business: 2
	},

	orderType: {
		none: 0,
		online: 1,
		inPerson: 2
	},

	customLogType: {
		none: 0,
		generalException: 1,
		nullException: 2
	},

	orderApplicationType: {
		none: 0,
		regular: 1,
		nas: 2,
		professional: 3,
	},

	months: {
		none: 0,
		January: 1,
		February: 2,
		March: 3,
		April: 4,
		May: 5,
		June: 6,
		July: 7,
		August: 8,
		September: 9,
		October: 10,
		November: 11,
		December: 12,
	},

	countryId: {
		none: 0,
		uae: 1,
		oman: 2,
		bahrain: 3,
		qatar: 4,
		iran: 5,
		kuwait: 6,
		azerbaijan: 7,
		turkey: 8,
		iraq: 9,
	},

	maps: {
		none: 0,
		googleMap: 1,
		leaflet: 2,
		pigeon: 3
	},

	dashboardCountryMap: {
		none: 0,
		iran: 1,
		uae: 2
	},

	embedCodeFormat: {
		none: 0,
		wordPress: 1,
		iframe: 2,
		script: 3
	},

	chats: {
		none: 0,
		goftino: 1
	},

	messageType: {
		none: 0,
		success: 1,
		error: 2,
		warning: 3,
		info: 4,
		question: 5,
		authentication: 6,
		authorization: 7,
		timeOut: 8,
		reSigninRequired: 9,
		needCaptcha: 10
	},

	mask: {
		validationCode: '9 - 9 - 9 - 9 - 9 - 9',
	},

	userCommunicationChannelType: {
		none: 0,
		telegram: 1,
		instagram: 2,
		facebook: 3,
		additionalEmail: 4,
		primaryEmail: 5,
		primaryMobile: 6
	},

	fileManagerContentType: {
		none: 0,
		folder: 1,
		file: 2,
	},

	fileActions: {
		none: 0,
		resize: 1,
		flipHorizontally: 2,
		flipVertically: 3,
		rotateCwAngle90: 4,
		rotateAngle180: 5,
		rotateCcwAngle90: 6
	},

	routeKey: {
		none: 0,
		id: 1,
		filmNo: 2,
		categoryId: 3,
		name: 4
	},

	turnaround: {
		none: 0,
		normal: 1,
		express: 2
	},

	printedSide: {
		none: 0,
		oneSide: 1,
		twoSide: 2
	},

	coordination: {
		none: 0,
		topLeft: 1,
		top: 2,
		topRight: 3,
		right: 4,
		bottomRight: 5,
		bottom: 6,
		bottomLeft: 7,
		left: 8,
		center: 9,
		verticalCenter: 10,
		horizontalCenter: 11,
	},

	fieldVisibilityType: {
		none: 0,
		hidden: 1,
		readOnly: 2,
		editable: 3
	},

	pageType: {
		none: 0,
		news: 1,
		blog: 2,
		staticPage: 3,
		download: 4,
		storeProduct: 5,
		gallery: 6,
		advertisement: 7,
		department: 8,
		signUp: 9,
		profileInformation: 10,
		userInformationInAdminPanel: 11
	},

	extendedServices: {
		none: 0,
		foldLine: 1,
		filters: 2,
		roundedCorner: 3,
		punch: 4,
		billBook: 5,
		gripperEdge: 6,
		cutLine: 7,
		handleType: 8,
		topFolding: 9
	},

	extendedServiceFillingOptionType: {
		none: 0,
		optional: 1,
		mandatory: 2
	},

	foldLine: {
		none: 0,
		biFold: 1,
		triFold: 2,
		zFold: 3,
		gateFold: 4,
	},

	roundedCorner: {
		none: 0,
		notCurved: 1,
		curved: 2
	},

	gripperEdge: {
		none: 0,
		withoutGripperEdge: 1,
		withGripperEdge: 2
	},

	topFolding: {
		none: 0,
		has: 1,
		notHas: 2
	},

	handleType: {
		none: 0,
		withoutHandle: 1,
		flatHandle: 2,
		twistHanle: 3
	},

	stepperButtonType: {
		none: 0,
		previous: 1,
		skip: 2,
		next: 3
	},

	orderLocation: {
		none: '',
		choosingFile: 1,
		extendedService: 2,
		confirmDetail: 3,

	},

	currencyType: {
		none: 0,
		aed: 1,
		rial: 2,
		toman: 3,
	},

	sharedSheetOrderDetailUserFileType: {
		none: 0,
		FrontImage: 1,
		BackImage: 2,
		FirstFrontFilm: 3,
		FirstBackFilm: 4,
		SecondFrontFilm: 5,
		SecondBackFilm: 6
	},

	alertConfirmation: {
		none: 0,
		confirm: 1,
		reject: 2,
	},

	resolutionUnitType: {
		none: 0,
		inch: 2,
		centimeter: 3,
	},
	sortItems: {
		none: 0,
		lowestPrice: 1,
		highestPrice: 2,
		bestSelling: 3,
		newest: 4,
		fastestDelivery: 5,
		random: 6
	},

	skeleton: {
		none: 0,
		text: 1,
		rect: 2,
		circle: 3
	},

	balanceType: {
		none: 0,
		creditor: 1,
		debtor: 2,
	},

	uploadingStatus: {
		none: 0,
		prepareForUpload: 1,
		uploading: 2,
		uploaded: 3,
		finished: 4,
		warning: 5,
		failed: 6,
		waiting: 7,
		duplicateName: 8,
		deleteFailed: 9
	},

	deleteOrderFileAfterClick: {
		none: 0,
		save: 1,
		edit: 2,
		close: 3,
	},

	sharedSheetOrderStateType: {
		none: 0,
		notApplied: 1,
		inFirstQueue: 2,
		prepare: 3,
		inProgress: 4,
		layouting: 5,
		progressFail: 6,
		prepareFail: 7,
		lithography: 8,
		printing: 9,
		cutting: 10,
		ready: 11,
		delivered: 12,
		inSecondQueue: 13,
		canceled: 14,
		transferToWarehouse: 15
	},

	SharedSheetOrderReadyToDeliverStateType: {
		none: 0,
		transferToWarehouse: 1,
		inTheWarehouse: 2,
		inTransit: 3,
	},

	sharedSheetOrderEstimatedQueueTurnaround: {
		none: 0,
		sixHours: 1,
		express: 2,
		normal: 3,
	},

	sharedSheetOrderStatusHistoryType: {
		none: 0,
		websiteStatus: 1,
		nasStatus: 2,
	},

	fileCheckingStatus: {
		none: 0,
		notChecked: 1,
		pending: 2,
		applyChanged: 3,
		rejected: 4
	},

	preparingOrderStateType: {
		none: 0,
		preparing: 1,
		failed: 2
	},

	announcementType: {
		none: 0,
		weekly: 1,
		monthly: 2
	},

	transferStateType: {
		none: 0,
		inCompletedOrder: 1,
		inTheWarehouse: 2,
		exitingWareHouse: 3,
		sendingToCourier: 4,
		deliveredToCourier: 5,
		deliveredToCustomer: 6
	},

	ipgVisibilityType: {
		none: 0,
		radio: 1,
		select: 2,
	},

	breadCrumbs: {
		none: 0,
		home: 1,
		products: 2,
		profileInformation: 3,
		poProductDetail: 4,
		aboutUs: 5,
		contactUs: 6,
		ourEquipment: 7,
		faqs: 8,
		termOfUse: 9,
		privacyPolicy: 10,
		regularOrder: 11,
		warrantyTerms: 12,
		help: 13,
		siteMap: 14,
		roProductDetail: 15,
		professionalOrder: 16,
		fileManager: 17,
		festivals: 18,
		blogs: 19,
		blogCategories: 20,
		blogCategoryPosts: 21,
		blogPostDetail: 22,
		blogPosts: 23,
		news: 24,
		newsCategories: 25,
		newsCategoryPosts: 26,
		newsPostDetail: 27,
		newsPosts: 28,
		profileNotification: 29,
		profileAutoShipping: 30,
		profileAddress: 31,
		orderCard: 32,
		orderPreparing: 34,
		orderInProcess: 36,
		orderReady: 38,
		storeOrderReady: 39,
		orderArchive: 40,
		orderAll: 42,
		myShipping: 44,
		myShippingDetail: 45,
		reportPayment: 46,
		reportFinancial: 47,
		stProductDetails: 48,
		profileAgency: 49,
		downloadCenter: 50,
		downloadDetail: 51,
		agencyInformation: 52,
		agencyCustomers: 53,
		agencyCustomersOrders: 54,
		agencyProducts: 55,
		gallery: 56,
		galleryCategories: 57,
		agencyShipping: 58,
		specialNeeds: 59,
		paperBag: 60,
		paperBagFaqs: 61,
		paperBagExploreProduct: 62,
		paperBagGalleries: 63,
		paperBagAdvantages: 64,
		saveOutputInPdfFormat: 65,
		productCategories: 66,
		userOrdersQueue: 67,
		storeOrderInProcess: 68,
		storeOrderArchive: 69,
		storeProducts: 70,
		dashboard: 71,
		cashback: 72,
		supporterProfile: 73,
		profileFeebacks: 74,
		instantDiscounts: 75,
	},

	adminBreadCrumbs: {
		none: 0,
		financialDashboard: 1,
		advertisement: 2,
		customer: 3,
		supporter: 4,
		shareSheetsOrders: 5,
		queue: 6,
		payment: 7,
		statisticalReportOfOrders: 8,
		statisticalReportOfPayments: 9,
		blogPost: 10,
		blogCategory: 11,
		newsPost: 12,
		newsCategory: 13,
		announcement: 14,
		slider: 15,
		promotion: 16,
		faq: 17,
		businessDashboard: 18,
		smsPanelsCreditDashboard: 19,
		usersDashboard: 20,
		shipping: 21,
		termOfUse: 22,
		privacyPolicy: 23,
		warrantyTerms: 24,
		help: 25,
		fileManager: 26,
		storeProducts: 27,
		usersDebitCredit: 28,
		agencies: 29,
		smsConfig: 30,
		festivals: 31,
		downloadCenter: 32,
		downloadCategory: 33,
		galleryPosts: 34,
		galleryCategories: 35,
		dashboardAgencies: 36,
		businessPartners: 37,
		problematicPayments: 38,
		agreements: 39,
		audit: 40,
		auditDashboard: 41,
		productContent: 42,
		logs: 43,
		paperBag: 44,
		feedbackTypes: 45,
		feedbackAnnouncements: 46,
		feedbackOrders: 47,
		feedbackUsers: 48,
		storeOrders: 49,
		feedbackDashboard: 50,
		cashback: 51,
		departments: 52,
		queueByEstimatedTime: 53,
		inTheWarehouseShipping: 54,
		shippingQueueByLatestStatus: 55,
		shippingQueueByShippingMethods: 56,
		shippingQueueByEstimatedTime: 57,
		dailyQueue: 58,
	},

	contentVisibilityType: {
		none: 0,
		all: 1,
		signedInUser: 2,
		signedInUserWithoutAgency: 3,
		signedInUserWithSpecificAgencies: 4,
		guest: 5,
	},

	dateRangeType: {
		none: 0,
		daily: 1,
		monthly: 2,
		yearly: 3,
	},

	tourGuide: {
		none: 0,
		product: 1,
		regularProductDetails: 2,
		masterFile: 3,
		chooseFileModal: 4,
		preview: 5,
		filmFile: 6,
		chooseFilmModal: 7,
		filterFile: 8,
		chooseFilterModal: 9,
		foldLine: 10,
		roundedCorner: 11,
		punch: 12,
		choosePunchModal: 13,
		confirmDetails: 14,
		fileManager: 15,
		fileManagerDetail: 16,
		professionalProductDetail: 17,
		poBeforeSelectingFile: 18,
		poSelectingFile: 19,
		poChoosingFileSummary: 20,
		poExtendedServiceSummary: 21,
		poAddToCurrentList: 22,
		poAfterClickingAddToCurrentList: 23,
		poSubmit: 24,
		roExtendedServiceSummary: 25,
		gripperEdge: 26,
		billBookInputs: 27,
		billBookBindingSideInput: 28,
		billBookModalContent: 29,
		billBookModalSerialRows: 30,
		billBookModalPerforationRows: 31,
		billBookOptionButton: 32,
		billBookPreviewButton: 33,
		serialNumberModal: 34,
		perforationModal: 35,
		billBookPreviewModal: 36,
		headerNavbar: 37,
		userCart: 38
	},

	switchValues: {
		none: 0,
		off: 1,
		on: 2,
	},

	city: {
		none: 0,
		tehran: 1,
		karaj: 2
	},

	foldLineDirections: {
		none: 0,
		horizontal: 1,
		vertical: 2
	},

	foldLineShapes: {
		none: 0,
		convex: 1,
		concave: 2
	},

	billBookNumberLanguageType: {
		none: 0,
		persian: 1,
		english: 2,
		persianAndEnglish: 3
	},

	billBookNumberInSeriesTypes: {
		none: 0,
		restartAtStartNumber: 1,
		continueNumbering: 2,
	},

	billBookCopyType: {
		none: 0,
		oneCopy: 1,
		twoCopies: 2,
		threeCopies: 3,
		fourCopies: 4
	},

	billBookPrintColorType: {
		none: 0,
		black: 1,
		navyBlue: 2,
		redAndBlack: 3,
		fourColors: 4
	},

	billBookBindingType: {
		none: 0,
		free: 1,
		glue: 2,
		stapler: 3
	},

	billBookBindingSideType: {
		none: 0,
		up: 1,
		left: 2,
		right: 3,
		down: 4
	},

	billBookUnderHandType: {
		none: 0,
		notHave: 1,
		have: 2,
		optional: 3
	},

	checkItem: {
		none: 0,
		isChangedBySeries: 1
	},

	fileType: {
		none: 0,
		jpg: 1,
		pdf: 2
	},

	presentableFileType: {
		none: 0,
		isPresentableInMasterFile: 1,
		isPresentableInFilmFile: 2,
		isPresentableInBoth: 3
	},

	keyboard: {
		none: 0,
		backSpace: 8,
		enter: 13,
		esc: 27,
		leftArrow: 37,
		rightArrow: 39,
		upArrow: 38,
		downArrow: 40,
		delete: 46,
		tab: 9,
		period: 190,
		decimalPoint: 110
	},
	paymentGateway: {
		none: 0,
		asanPardakht: 1,
		behPardakht: 2,
		iranKish: 3,
		sadad: 4,
		saman: 5,
		nGeniusPayment: 6,
		sepehr: 7
	},

	paymentAuditMatchingType: {
		none: 0,
		notProcessed: 1,
		paymentNotFound: 2,
		amountMismatch: 3,
		stateTypeMismatch: 4,
		amountAndStateTypeMismatch: 5,
		matched: 6,
		alternativePaymentAttempt: 7,
		oldWebiteStoreProduct: 8
	},

	aggregateStatisticsPaymentAuditType: {
		none: 0,
		audited: 1,
		paymentNotFound: 2
	},

	gridType: {
		none: 0,
		dateTime: 1,
		printSide: 2,
		turnaround: 3,
		price: 4,
		date: 5,
		circulation: 6,
		number: 7
	},

	accountStatus: {
		none: 0,
		active: 1,
		block: 2
	},

	activation: {
		none: 0,
		active: 1,
		inactive: 2
	},

	editorMode: {
		none: 0,
		basic: 1,
		advance: 2,
	},

	adminContent: {
		none: 0,
		blog: 1,
		news: 2
	},

	duplicateFile: {
		none: 0,
		keepBoth: 1,
		replace: 2,
		cancel: 3
	},

	foldLineModalTab: {
		none: 0,
		preset: 1,
		customized: 2
	},

	paymentStateType: {
		none: 0,
		success: 1,
		failed: 2,
		open: 3
	},

	paymentProblemType: {
		none: 0,
		missingIncreaseCreditInNas: 1,
		someOrdersNotConfirmed: 2,
		inAdvanceOrderNotFilled: 3,
		someOrderHaveMoreThanOneSuccessfulPayment: 4
	},

	paymentAuditProblemType: {
		none: 0,
		paymentIsInOpenStatus: 1
	},

	paymentType: {
		none: 0,
		increaseCredit: 1,
		orderPayment: 2,
		cash: 3
	},

	adminSearchType: {
		none: 0,
		payments: 1,
	},

	advanceSearchFieldType: {
		none: 0,
		textField: 1,
		autoComplete: 2,
		datePicker: 3,
		dateTimePicker: 4,
		numberInput: 5,
		mobileInput: 6,
		timePicker: 7,
		checkbox: 8
	},

	fileExtension: {
		none: 0,
		jpeg: 1,
		jpg: 2,
		tif: 3,
		pdf: 4,
		png: 5,
		bmp: 6,
		svg: 7,
		webp: 8,
		gif: 9,
		psd: 10,
		ai: 11,
		xls: 12,
		xlsx: 13,
		csv: 14,
		doc: 15,
		docx: 16,
		txt: 17,
		rar: 18,
		zip: 19,
		mp3: 20,
		mp4: 21
	},

	googleTagManagerListId: {
		none: 0,
		home: 1,
		shop: 2,
		singlePageSidebar: 3,
		regularOrderDetail: 4,
		professionalOrderDetail: 5,
		regularOrder: 6,
		professionalOrder: 7,
		cart: 9,
		orderDetail: 9,
		singlePage: 10,
		cartConfirmationModal: 11,
		credit: 12
	},

	promotionsId: {
		homeTopRightTop: 1,
		homeTopRightBottom: 2,
		homeTopSlider: 3,
		homeCenterLeft: 4,
		homeCenterCenter: 5,
		homeCenterRight: 6,
		singleTop: 7,
		singleBottom: 8
	},


	promoteContentType: {
		none: 0,
		homeTopPromotion1: 1,
		homeTopPromotion2: 2,
		middlePromotion1: 3,
		middlePromotion2: 4,
		middlePromotion3: 5,
		singlePageSideBarPromotion1: 6,
		singlePageSideBarPromotion2: 7,
		blogAndNewsSideBarPromotion1: 8,
		blogAndNewsSideBarPromotion2: 9,
		homeAboveTheAboutPromotion1: 10,
		homeAboveTheAboutPromotion2: 11,
		homeAboveTheAboutPromotion3: 12,
	},

	imageSitePosition: {
		none: 0,
		homeSlider: 1,
		homeTopPromotion: 2,
		homeMiddlePromotion: 3,
		homeProductCard: 4,
		shopProductCard: 5,
		productDetailsImage: 6,
		sidebarProductCard: 7,
		sidebarPromotion: 8,
		aboutUsOurEquipment: 9
	},

	gridSortType: {
		none: 0,
		asc: 1,
		desc: 2
	},

	role: {
		none: 0,
		admin: 1,
		user: 2,
		subUser: 3,
		system: 4,
		supporter: 5,
		nas: 6,
		superAdmin: 7,
		agency: 8,
		businessPartner: 9
	},

	festivalInvoiceType: {
		none: 0,
		sharedSheetOrder: 1,
		dedicatedOrder: 2,
		otherProducts: 3
	},

	festivalPointingType: {
		none: 0,
		withoutPoint: 1,
		specialPointsForEachPrize: 2,
		samePointsForAllPrizes: 3,
	},

	festivalSaleAppType: {
		none: 0,
		inPerson: 1,
		online: 2
	},

	blogAndNews: {
		none: 0,
		blogs: 1,
		news: 2
	},

	staticPageType: {
		none: 0,
		aboutUs: 1,
		contactUs: 2,
		ourEquipment: 3,
		faqs: 4,
		termOfUse: 5,
		privacyPolicy: 6,
		warrantyTerms: 7,
		help: 8,
		festival: 9,
		requestSample: 10,
		downloadTemplate: 11,
		siteMap: 12,
		gallery: 13,
		specialNeeds: 14,
	},

	orderProductType: {
		none: 0,
		sharedSheet: 1,
		dedicated: 2,
		store: 3,
	},

	fileUploaderCategoryType: {
		none: 0,
		root: 1,
		blogCategory: 2,
		news: 3,
		newsCategory: 4,
		announcement: 5,
		slider: 6,
		promotion: 7,
		blog: 8,
		adminDownload: 9,
		gallery: 10,
		galleryCategory: 11,
		userProfileImage: 12,
	},

	festivalStatusType: {
		none: 0,
		active: 1,
		done: 2
	},

	festivalInfoTabs: {
		none: 0,
		winners: 1,
		prizes: 2
	},

	viewType: {
		none: 0,
		viewSingle: 1,
		viewList: 2,
	},

	attachType: {
		none: 0,
		attachLink: 1,
		uploadFile: 2
	},

	chartDatasetType: {
		none: 0,
		totalSales: 1,
		onlineSales: 2,
		successPayment: 3,
		failedPayment: 4,
		unknownPayment: 5,
		asanPardakhtGateway: 6,
		behPardakhtGateway: 7,
		irankishGateway: 8,
		sadadGateway: 9,
		samanGateway: 10,
		ngeniusPaymentGateway: 11,
		sepehrGateway: 12,
		regularOrdering: 13,
		professionalOrdering: 14,
		inPersonOrdering: 15,
		expressOrder: 16,
		normalOrder: 17,
		orderedUser: 18,
		newUser: 19,
		paymentGatewayType: 20,
		audited: 24,
		notAudited: 25,
		others: 26,
		mismatch: 27,
		quantity: 28,
		averageRating: 29,
		feedbackMetricTypeStrength: 30,
		feedbackMetricTypeWeakness: 31,
		userFeedbackStateTypePending: 32,
		userFeedbackStateTypeInProgress: 33,
		userFeedbackStateTypeArchived: 34,
		cashbackCustomerCount: 38,
		cashbackTotalAmount: 39
	},

	agencyTotalSalesAmountType: {
		none: 0,
		company: 1,
		agency: 2
	},

	reachResultType: {
		none: 0,
		website: 1,
		itemListProducts: 2,
		product: 3,
		breadCrumbs: 4,
		faq: 5
	},
	productDetailContentTabs: {
		none: 0,
		overview: 1,
		explanation: 2,
		recommendedProducts: 3,
		faqs: 4
	},

	auditDetailStepper: {
		none: 0,
		attempts: 1,
		history: 2,
		details: 3
	},

	shoppingBagPaperType: {
		none: 0,
		virgin: 1,
		recycle: 2,
	},

	shoppingBagPackagingType: {
		none: 0,
		shrinkedFilm: 1,
		carton: 2,
		both: 3
	},
	feedbackTypes: {
		none: 0,
		user: 1,
		sharedSheetOrder: 2,
		department: 3,
		announcementContent: 4,
		storeProductOrder: 5
	},
	feedbackMetricTypes: {
		none: 0,
		ratingparamenter: 1,
		strengths: 2,
		weaknesses: 3,
		demandService: 4
	},
	feedbackStatus: {
		none: 0,
		pending: 1,
		inprocess: 2,
		archive: 3,
	},

	feedbackDemandServiceType: {
		none: 0,
		callMe: 1
	},

	timeSheetDayType: {
		none: 0,
		normalBusinessDay: 1,
		holiday: 2,
		confirmOrderDay: 3,
		confirmOrderAfterDayDeadline: 4,
		readyAfterWorkingHours: 5,
		completionDay: 6,
		shippingRequestDay: 7,
		invalidShippingRequestDay: 8,
		shippingToShippingMethodDay: 9,
		deliveryDeadlineDay: 10,
	},

	feedbackStatusFilterOptions: {
		none: 0,
		all: 1,
		hasComment: 2,
		hasDemandedService: 3
	},

	negativeAccountBalanceUsers: {
		none: 0,
		withCredit: 1,
		withoutCredit: 2,
		justGrantedCredit: 3
	},

	duplicateUsersbaseType: {
		none: 0,
		fullName: 1,
		companyName: 2,
		mobile: 3
	},

	seasonalPurchaseLimitType: {
		none: 0,
		disabled: 1,
		remainAndOfficialInvoiceDisabled: 2,
		overdAndOfficialInvoiceDisabled: 3,
		officalInvoiceEnabled: 4
	},

	cashbackTimeType: {
		none: 0,
		currentMonth: 1,
		currentMonthVsFromDate: 2,
		basedOnSelectedDate: 3,
		lastMonthsOfThisYear: 4
	},

	cashbackPlanType: {
		none: 0,
		common: 1,
		spacial: 2,
		noPlan: 3
	},

	shippingPlanType: {
		none: 0,
		standard: 1,
		express: 2,
		byAgency: 3,
	},

	shippingMethodCategoryType: {
		none: 0,
		inPersonDelivery: 1,
		cargo: 2,
		doorToDoor: 3
	},

	shippingPaymentType: {
		none: 0,
		prepaid: 1,
		postpaid: 2
	},

	orderCalculationBasesType: {
		none: 0,
		basedOnSeriesInOrderDetail: 1,
		basedOnOrderCount: 2
	}
};


// Export the application config.
export default Enumerations;