import React, { useEffect, useState } from 'react';

import { GetCmsLogCustomLogExceptionService } from '../../../../services/adminPanel/AdminExceptionService';
import NsBasicInfoLabelBox from '../../../../components/labels/NsBasicInfoLabelBox';
import { getCmsLogBasicInfos } from '../../../../utilities/BasicInfoHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsModal from '../../../../components/modals/NsModal';

const ApLogsAllXmlModal = ({ selectedId, closeModal }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        getCmsLogAllXmlDetail();
    }, [])

    const getCmsLogAllXmlDetail = () => {
        if (!isEmpty(selectedId)) {
            setIsLoading(true);
            GetCmsLogCustomLogExceptionService({ 'id': selectedId }, getCmsLogAllXmlDetailCallback);
        }
    }

    const getCmsLogAllXmlDetailCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(resultData);
        } else setMessageClassModal(resultMessageClass);
    }

    return (
        <NsModal maxWidth="sm"
            dialogTitle={getTranslatedRawText('adminPanel.systemMonitoring.logsDetail')}
            showModal={true}
            noSpace={true}
            closeModal={closeModal}>

            <div className='background-color px-3 py-2'>
                <NsBasicInfoLabelBox basicInfo={getCmsLogBasicInfos(data)}
                    hasAccessInfo={true}
                    isLoading={isLoading} />
            </div>

            {!isEmpty(data?.allXmls) &&
                <div className='p-2'>
                    <div className='fw-bold font-size-14 my-2'>{getTranslatedRawText('adminPanel.systemMonitoring.allXml')}</div>
                    <div className='card p-2'>
                        {data?.allXmls.map(item => <p className='font-size-12'>{item}</p>)}
                    </div>
                </div>
            }

            {messageClassModal}

        </NsModal>
    );
};

export default React.memo(ApLogsAllXmlModal);