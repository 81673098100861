import React from 'react';

import NsModal from '../../../../../../components/modals/NsModal';
import NsTypography from '../../../../../../components/text/NsTypography';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import UpBaseGroupSheetOrdersActionButtons from '../bases/UpBaseGroupSheetOrdersActionButtons';
import UpBaseGroupSheetOrdersDetailGrid from '../bases/UpBaseGroupSheetOrdersDetailGrid';

class UpGroupSheetReadyToDeliverOrderDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.mainButton = [
            CommonHelper.createStickyButtonItem("profile.readyToDeliver.submitDeliveryRequest", '', this.confirmClicked, 'primary'),
        ];
        this.state = {
            orderTitle: '',
            hasInvoiceNumber: null,
            hasPayment: false,
            isFeedbackActive: false,
            basicInfo: null
        };
    }

    orderTitleChanged = (orderTitle, hasInvoiceNumber, sharedSheetOrderStateType, resultData) => this.setState({
        orderTitle,
        hasInvoiceNumber,
        basicInfo: resultData
    });
    feedbackActive = (data) => this.setState({ isFeedbackActive: data });

    render() {

        return (
            <NsModal maxWidth="lg"
                dialogTitle={getTranslatedRawText('profile.detail.deliveryRequestDetail')}
                showModal={true}
                noSpace={true}
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeModal)}>

                <div className='p-3'>
                    <div className="d-flex align-items-center w-100 pb-3 mx-auto">
                        <NsTypography className='black-color fw-bold'>
                            {this.state.orderTitle}
                        </NsTypography>
                        <div className='ms-auto'>
                            <UpBaseGroupSheetOrdersActionButtons orderId={this.props.orderId}
                                hasPayment={this.state.hasPayment}
                                openFeedbackModalDate={this.props.openFeedbackModalDate}
                                hasOrderEstimatedTime={this.state.basicInfo?.confirmTime}
                                breadcrumbs={this.props.breadcrumbs}
                                transferRequestId={this.state.basicInfo?.transferRequestId}
                                hasInvoiceNumber={this.state.hasInvoiceNumber}
                                isFeedbackActive={this.state.isFeedbackActive} />
                        </div>
                    </div>

                    <UpBaseGroupSheetOrdersDetailGrid orderId={this.props.orderId}
                        hasPayment={(hasPayment) => this.setState({ hasPayment })}
                        orderTitleChanged={this.orderTitleChanged}
                        isFeedbackActive={this.feedbackActive} />
                </div>
            </NsModal>
        );
    }
}

export default UpGroupSheetReadyToDeliverOrderDetailModal;