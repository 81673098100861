import React, { useEffect, useState } from 'react';

import { DeleteCategoryService, EditDownloadAllCategoryService, GetDownloadCategoryByIdService, PostDownloadAllCategoryService } from '../../../../../services/adminPanel/AdminDownloadService';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import ApBaseDownloadNewFileContent from '../bases/ApBaseDownloadNewFileContent';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsModal from '../../../../../components/modals/NsModal';
import ContentHelper from '../../bases/ContentHelper';

const ApDownloadCategoryModal = ({ selectedCategoryId, closeModalClicked }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [nextButtonTriggerDate, setNextButtonTriggerDate] = useState(null);
    const [showValidationDate, setShowValidationDate] = useState(null);
    const [messageClassModal, setMessageClassModal] = useState(<></>);
    const [multiLanguageCategoryContent, setMultiLanguageCategoryContent] = useState([]);

    useEffect(() => {
        if (!isEmpty(selectedCategoryId)) {
            setIsLoading(true);
            GetDownloadCategoryByIdService(selectedCategoryId, {}, getDownloadCategoryByIdCallback);
        } else
            setMultiLanguageCategoryContent(ContentHelper.normalizeContentObject([], { isActive: false }));
    }, [])

    const getDownloadCategoryByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguageCategoryContent(resultData);
        } else setMessageClassModal(messageClassModal);
    }

    const saveButtonClicked = (id, data) => {
        let tempContent = ContentHelper.removeDownloadCategoryEmptyContent(data);
        let isNotValidData = ContentHelper.isValidDownloadCategoryContent(tempContent);
        if (isEmpty(tempContent) || isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }
        setIsLoading(true);
        if (isEmpty(id))
            PostDownloadAllCategoryService(tempContent, postAndEditDownloadCategoryCallback, true);
        else
            EditDownloadAllCategoryService(id, tempContent, postAndEditDownloadCategoryCallback, true);
    }

    const postAndEditDownloadCategoryCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModalClicked(resultMessageClass);
        } else
            setMessageClassModal(resultMessageClass);
    }

    const deleteButtonClicked = (selectedRowId) => {
        setIsLoading(true);
        DeleteCategoryService(selectedRowId, deleteCategoryCallback, true);
    }
    const deleteCategoryCallback = (resultData, result, messageClass) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModalClicked(messageClass);
        else
            setMessageClassModal(messageClass);
    }

    const mainButtons = [
        { label: "common.save", icon: 'ns-icon-save', onClicked: () => setNextButtonTriggerDate(new Date().toISOString()), className: 'primary', accessIds: !isEmpty(selectedCategoryId) ? [UserClaimEnumerations.editApDownloadCategory] : [UserClaimEnumerations.postApDownloadCategory] },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(), className: 'primary-outline' },
    ];

    return (
        <NsModal maxWidth="md"
            dialogTitle={getTranslatedRawText(!isEmpty(selectedCategoryId) ? 'adminPanel.download.editCategory' : 'adminPanel.download.newCategory')}
            showModal={true}
            noSpace={true}
            closeModal={closeModalClicked}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />} >

            <ApBaseDownloadNewFileContent selectedRowId={selectedCategoryId}
                nextButtonTriggerDate={nextButtonTriggerDate}
                isEditMode={!isEmpty(selectedCategoryId)}
                showValidationDate={showValidationDate}
                isLoading={isLoading}
                serviceCall={() => GetDownloadCategoryByIdService(selectedCategoryId, {}, getDownloadCategoryByIdCallback)}
                deleteButtonClicked={deleteButtonClicked}
                modalDetail={multiLanguageCategoryContent}
                saveButtonClicked={saveButtonClicked}
                closeModalClicked={closeModalClicked} />

            {messageClassModal}
        </NsModal>
    )
}

export default React.memo(ApDownloadCategoryModal);