import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import NsModal from '../../../../../components/modals/NsModal';
import NsTypography from '../../../../../components/text/NsTypography';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import UpBaseShippingReadyToDeliverOrdersGrid from '../bases/UpBaseShippingReadyToDeliverOrdersGrid';
import Enumerations from '../../../../../configs/Enumerations';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import { SubmitDeliveryRequestService } from '../../../../../services/ProfileSetting/DeliveryAddressSettingService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import ApBaseLabelInfo from '../../../../adminPanel/bases/ApBaseLabelInfo';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import UpShippingRequestHeaderCost from '../shippingRequests/UpShippingRequestHeaderCost';

class UpShippingRequestConfirmationModal extends Component {
    constructor(props) {
        super();
        this.state = {
            showConfirmTermsOfCarrierModal: false,
            createNewDeliveryRequestLoading: false,
            isLoading: false,
            resultMessageClass: <></>,
        }
    }

    confirmTermsOfCarrierBeforeCreateDeliveryRequest = (confirm) => {
        this.setState({ showConfirmTermsOfCarrierModal: false });
        if (confirm)
            this.createNewDeliveryRequestClicked();
    }

    createNewDeliveryRequestClicked = () => {
        const { selectedAddress, selectedShippingMethod, selectedShippingPlan, storeSelectedRowIds, sheetOrderSelectedRowIds, shipmentDescription, shippingTime } = this.props;
        const deliveryRequestData = {
            sharedSheetOrderIds: sheetOrderSelectedRowIds,
            storeProductOrderIds: storeSelectedRowIds,
            addressId: selectedAddress?.id,
            isTermsAccepted: true,
            description: shipmentDescription,
            estimatedDeliveryTimeToShippingMethod: shippingTime,
            shippingPlanTypeId: selectedShippingPlan?.key,
            shippingMethodId: selectedShippingMethod?.id
        };
        this.setState({ createNewDeliveryRequestLoading: true },
            SubmitDeliveryRequestService(deliveryRequestData, this.createNewDeliveryRequestCallback));
    }

    createNewDeliveryRequestCallback = (resultData, result, resultMessageClass) => {
        this.setState({ createNewDeliveryRequestLoading: false });
        if (!result?.data?.hasError)
            CommonHelper.safeFunctionCall(this.props.closeModal, true, resultMessageClass);
        else
            this.setState({ resultMessageClass });
    }

    render() {
        const mainButtons = [
            {
                label: "profile.readyToDeliver.addShippingRequest",
                icon: 'ns-icon-add',
                onClicked: () => this.setState({ showConfirmTermsOfCarrierModal: true }),
                className: 'primary',
            },
        ];
        const shippingLabelInfo = {
            // => MAY CHANGE AFTER PROVIDE API <=
            address: this.props?.selectedAddress?.name,
            shippingPlan: this.props?.selectedShippingPlan?.value,
            shippingMethodName: this.props?.selectedShippingMethod?.name,
            description: this?.props?.shipmentDescription,
            className: 'background-element-color'
        }

        const { showConfirmTermsOfCarrierModal, resultMessageClass, isLoading } = this.state;
        const { closeModal, selectedShippingMethod, selectedShippingPlan, orderProductType, shippingTime, sheetOrderSelectedRowIds, storeSelectedRowIds, shipmentDescription } = this.props;

        return (
            <NsModal maxWidth="lg"
                dialogTitle={getTranslatedRawText("profile.delivery.checkAndConfirmShipment")}
                showModal={true}
                closeModal={closeModal}
                actions={<NsStickyButton stickyButtons={mainButtons} />}>
                <Row>
                    <Col md={8} sm={12}>
                        <ApBaseLabelInfo info={{ shippingInfoDto: shippingLabelInfo }}
                            hasAccessInfo={true}
                            isLoading={isLoading} />
                    </Col>
                    <Col md={4} sm={12}>
                        {!isEmpty(selectedShippingMethod?.id) && !isEmpty(selectedShippingPlan?.key)
                            && <UpShippingRequestHeaderCost selectedShippingMethod={selectedShippingMethod}
                                shippingTime={shippingTime}
                                selectedShippingPlan={selectedShippingPlan} />}
                    </Col>
                </Row>

                <NsTypography className='fw-bold mb-2 mt-4'>
                    {getTranslatedRawText("profile.readyToDeliver.readyToDeliveryOrders")}
                </NsTypography>
                <UpBaseShippingReadyToDeliverOrdersGrid orderProductType={orderProductType}
                    selectedShippingPlanId={selectedShippingPlan?.key}
                    filter={{ sharedSheetOrderIds: sheetOrderSelectedRowIds, storeOrderIds: storeSelectedRowIds }} />

                {showConfirmTermsOfCarrierModal &&
                    <NsMessageClassModal show={true}
                        onClose={(confirm) => this.confirmTermsOfCarrierBeforeCreateDeliveryRequest(confirm)}
                        messageItems={[
                            {
                                messageText: <div>
                                    <div>
                                        {selectedShippingMethod.description && selectedShippingMethod.description}
                                    </div>
                                    <div className='mt-2'>
                                        {getTranslatedRawText("profile.readyToDeliver.readyToDeliveryDescription")}
                                    </div>
                                </div>,
                                messageType: Enumerations.messageType.question
                            }]} />
                }

                {resultMessageClass}
            </NsModal>
        )
    }
}

export default UpShippingRequestConfirmationModal;