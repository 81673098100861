import React from 'react';
import { withRouter } from 'react-router';

import Enumerations from '../../../../configs/Enumerations';
import Links from '../../../../configs/links/AllLinks';
import { GetCmsBlogPostDetailService, GetCmsNewsPostDetailService } from '../../../../services/singlePages/CmsService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import UrlHelper from '../../../../utilities/UrlHelper';
import BlogNews from '../../BlogNews';
import BnBasesDetailCard from '../bases/BnBasesDetailCard';
import { connect } from 'react-redux';
import { metaTagActionCreators } from '../../../../redux/reducers/MetaTagReducer';
import { bindActionCreators } from 'redux';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';

class BnContentPostDetail extends React.Component {

    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.PostId = UrlHelper.getRouteValue(this.props, Enumerations.routeKey.id)
        this.state = {
            post: {},
            isLoading: true,
            resultMessageClass: (<></>)
        }
    }

    componentDidMount = () => this.getPost();

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        let id = UrlHelper.getRouteValue(nextProps, Enumerations.routeKey.id)
        if (id !== this.id) {
            this.PostId = id;
            this.getPost();
        }
    }

    getPost = () => {
        let parameters = { id: this.PostId };
        this.isBlog
            ? GetCmsBlogPostDetailService(parameters, this.getCmsPostDetailCallback)
            : GetCmsNewsPostDetailService(parameters, this.getCmsPostDetailCallback);
    }

    categoryClicked = (e, category) => {
        this.isBlog
            ? this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogCategoryPosts), category.key, category.value))
            : this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsCategoryPosts), category.key, category.value));
    }

    getCmsPostDetailCallback = (resultData, result, messageClassModal) => {
        this.props.setMetaTags({
            title: `${resultData?.title && `${resultData?.title} | `} ${getTranslatedRawText(this.isBlog
                ? 'adminPanel.sidebar.blog'
                : 'adminPanel.sidebar.news')}`,
            keywords: CommonHelper.makeCorrectLink(resultData?.tags),
            description: resultData?.summary,
            ogTitle: resultData?.title,
            ogType: getTranslatedRawText('adminPanel.sidebar.content'),
            ogImage: resultData?.fileUrl,
            ogDescription: resultData?.summary,
            author: resultData?.createByUser
        })
        this.setState({ isLoading: false });
        window.scrollTo({ top: 0 });
        if (!result.data.hasError) {
            this.setState({ post: resultData });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    };

    render() {
        const { post, resultMessageClass, isLoading } = this.state;

        return <BlogNews >
            {isLoading
                ? <div className='w-100'>
                    <NsSkeleton isVisible={true}
                        variant={[
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                            Enumerations.skeleton.rect,
                        ]} />
                </div>
                : <BnBasesDetailCard post={post}
                    isBlog={this.isBlog}
                    categoryClicked={this.categoryClicked} />
            }
            {resultMessageClass}
        </BlogNews>
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ ...metaTagActionCreators }, dispatch);
};

export default withRouter(connect(null, mapDispatchToProps)(BnContentPostDetail));