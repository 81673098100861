import NsButton from "../../../../../../components/buttons/NsButton";
import ConstantsWithTranslation from "../../../../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../../../../configs/Enumerations";
import CommonHelper from "../../../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../../../utilities/CultureHelper";

export const getApOrderQueueByEstimatedTimeColumns = (selectOrderClicked, orderDetailClicked, isOrderSelection, isLoading, hasCheckbox) => [
    {
        field: 'action',
        headerName: getTranslatedRawText('adminPanel.common.action'),
        width: hasCheckbox ? 120 : 80,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (order) => {
                return <NsButton className='secondary-text'
                    loading={isLoading}
                    startIcon={isOrderSelection ? 'ns-icon-invoice-details' : 'ns-icon-confirm'}
                    onClick={isOrderSelection ? () => selectOrderClicked(order) : () => orderDetailClicked(order.id)} />
            }
        }
    },
    {
        field: 'remainingToEstimateReadyToDeliverTimeLabel',
        headerName: getTranslatedRawText('adminPanel.sidebar.remainingToEstimateReadyToDeliver'),
        width: 250,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className={`${CommonHelper.getOrderQueueRemainingDaysByEstimateTime(props?.remainingToEstimateReadyToDeliverTimeDay)}`}>
                    {props?.remainingToEstimateReadyToDeliverTimeLabel}
                </div>
            },
        }
    },
    {
        field: 'estimateReadyToDeliverTime',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime'),
        dataType: Enumerations.gridType.dateTime,
        width: 180
    },
    {
        field: 'lastStatusType',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.lastStatus'),
        width: 120,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                return <div className={`${CommonHelper.getShareSheetStateColor(props?.lastStatusType)}`}>
                    {ConstantsWithTranslation.getSharedSheetOrderStateType(props?.lastStatusType)}
                </div>
            },
        }
    },
    {
        field: 'lastStatusTime',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.statusDate'),
        width: 150,
        dataType: Enumerations.gridType.dateTime,
    },
    {
        field: 'processHours',
        headerName: getTranslatedRawText('product.detail.processTimeNotice'),
        width: 150,
        cellRenderer: "customRowRenderer",
        cellRendererParams: {
            customElementRender: (props) => {
                let isMoreThanADay = props?.processHours >= 24;
                return isMoreThanADay
                    ? `${props?.processHours / 24} ${getTranslatedRawText('adminPanel.dataGridColumn.days')}`
                    : `${props?.processHours} ${getTranslatedRawText('product.detail.hours')}`
            },
        }
    },
    {
        field: 'nasInvoiceId',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.invoiceNumber'),
        width: 150,
        dataType: Enumerations.gridType.number,
    },
    {
        field: 'fullName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
        width: 140
    },
    {
        field: 'productName',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
        width: 250,
    },
    {
        field: 'turnaroundType',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.turnaround'),
        width: 110,
        dataType: Enumerations.gridType.turnaround
    },
    {
        field: 'submitTime',
        headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitDate'),
        width: 150,
        dataType: Enumerations.gridType.dateTime,
    },
];