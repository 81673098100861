import { Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import '../../assets/sass/components/tabs/NsTab.scss';
import CommonHelper from '../../utilities/CommonHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import NsBadge from '../badge/NsBadge';
import NsButton from '../buttons/NsButton';

class NsTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: null,
        }
    }

    onChange = (event, newValue) => {
        CommonHelper.safeFunctionCall(this.props.onChange, newValue, this.props.tabs[newValue]);
        this.setState({ selectedTab: this.props.tabs[newValue] })
    }

    onClick = (selectedTab) => CommonHelper.safeFunctionCall(this.props.closeTabItemClicked, selectedTab);

    renderTabs = (hasCloseButton, tab) => {
        return <div className={clsx('d-flex font-size-12',
            tab?.badgeCount ? 'align-items-start' : 'align-items-end',
            hasCloseButton && 'align-items-center',
            this.state?.selectedTab?.id !== tab?.id && 'tabs-truncate')} >

            {!!tab?.icon && <i className={tab.icon} />}
            <div className='truncate-text'>{tab.label}</div>
            {!!tab.badgeCount && <NsBadge
                className={'ms-3'}
                badgeNumber={tab?.badgeCount} />}
            {!!hasCloseButton && <NsButton startIcon='ns-icon-close' onClick={() => this.onClick(tab)} />}
        </div>
    }

    componentDidMount = () => {
        !!this.props?.value && this.setState({ selectedTab: this.props?.tabs[this.props?.value] });

        (!isEmpty(window.location.search) && !!this.props.queryManagement)
            ? this.queryManager('pullFromUrl') : this.queryManager('pushToUrl');
    }

    componentDidUpdate = (newProps) => {
        if (!!this.props.queryManagement) {
            this.props?.history?.location?.search !== newProps?.history?.location?.search
                || this.props.tabs.length !== newProps.tabs.length
                && this.queryManager('pullFromUrl');

            this.props.value !== newProps.value && this.queryManager('pushToUrl');
        }
    }
    queryManager = (method, tab, e) => {
        const { tabs, history, onChange, value } = this.props;
        switch (method) {
            case "pushToUrl":
                !!e && e.preventDefault();
                !!history && history.push({ search: CommonHelper.makeCorrectLink(tabs[value]?.link) });
                break;

            case "pullFromUrl":
                let query = decodeURIComponent(window?.location?.search.replace(/\?/g, ''));
                if (!isEmpty(query)) {
                    let queryTab = tabs.find(tab => CommonHelper.makeCorrectLink(tab?.link) === query);
                    let queryTabIndex = !isEmpty(queryTab) && tabs.findIndex(tab => tab.id === queryTab.id);
                    !isEmpty(queryTab?.id) && CommonHelper.safeFunctionCall(onChange, queryTabIndex, queryTab);
                }
                break;
            default: return;
        }
    }

    render() {
        const props = {
            ...this.props,
            className: clsx('page-tabs', this.props.className),
            tabs: this.props.tabs,
            value: this.props.value,
            indicatorColor: this.props.indicatorColor || 'secondary',
            textColor: this.props.textColor || 'primary',
            onChange: this.onChange,
            variant: "scrollable",
            scrollButtons: !!this.props.scrollButtons ? !!this.props.scrollButtons : "auto",
            tabClassName: this.props.tabClassName,
        };
        const { tabClassName, hasCloseButton, closeTabItemClicked, link, history, queryManagement, ...otherProps } = props;
        return (
            <>
                <Tabs className={this.props.className} {...otherProps}>
                    {props.tabs.map((tab, index) => (

                        <Tab className={tabClassName}
                            key={index}
                            onClick={(e) => this.queryManager("pushToUrl", tab, e)}
                            label={!!queryManagement
                                ? <a href={`${window.location.href}?${tab?.link}`} //just for SEO
                                    className='text-decoration-none light-text-color'>
                                    {this.renderTabs(hasCloseButton, tab)}
                                </a>
                                : this.renderTabs(hasCloseButton, tab)
                            } />
                    ))}
                </Tabs>
                {this.props.children}
            </>
        );
    }
}

export default NsTab;