import Enumerations from '../configs/Enumerations';
import { checkIsValidEnumKeyFromValue, getEnumKeyFromValue } from './EnumerationHelper'
import IndexedDbHelper from './IndexedDbHelper';
import CommonHelper from './CommonHelper';
import SecureLS from 'secure-ls';
import Config from '../configs/Config';

const ls = new SecureLS({ encodingType: 'des', isCompression: false, encryptionSecret: Config.lsEncryptionSecret });

class StorageHelper {

	static getFromStorage = (key) => {

		if (!checkIsValidEnumKeyFromValue(Enumerations.storage, key))
			return null;

		let enumKey = getEnumKeyFromValue(Enumerations.storage, key);

		try {
			let value = window.localStorage.getItem(enumKey);
			return JSON.parse(value);
		} catch (e) {
			return null;
		}
	}

	static saveInStorage = (key, value) => {

		if (!checkIsValidEnumKeyFromValue(Enumerations.storage, key) || value === null)
			return false;
		let enumKey = getEnumKeyFromValue(Enumerations.storage, key);
		let jsonValue = JSON.stringify(value);
		try {
			window.localStorage.setItem(enumKey, jsonValue);
		} catch (e) {
			value.shift()
			saveInStorage(key, value);
		}
		return true;
	}

	static getDecFromStorage = (key) => {

		if (!checkIsValidEnumKeyFromValue(Enumerations.storage, key))
			return null;

		let enumKey = getEnumKeyFromValue(Enumerations.storage, key);

		try {
			let value = ls.get(enumKey);
			return JSON.parse(value);
		} catch (e) {
			return null;
		}
	}

	static saveEncInStorage = (key, value) => {

		if (!checkIsValidEnumKeyFromValue(Enumerations.storage, key) || value === null)
			return false;
		let enumKey = getEnumKeyFromValue(Enumerations.storage, key);
		let jsonValue = JSON.stringify(value);
		try {
			ls.set(enumKey, jsonValue);
		} catch (e) {
			value.shift()
			saveEncInStorage(key, value);
		}
		return true;
	}

	static removeFromStorage = (key, value) => {

		if (!checkIsValidEnumKeyFromValue(Enumerations.storage, key))
			return false;

		let enumKey = getEnumKeyFromValue(Enumerations.storage, key);
		window.localStorage.removeItem(enumKey);

		return true;
	}



	static saveInLobStorage = (key, value, callback) => {
		try {
			IndexedDbHelper.save(key, value).then((result) => CommonHelper.safeFunctionCall(callback, result));
		} catch (e) {
			value.shift()
			saveInLobStorage(key, value);
		}
		return true;
	}

	static getFromLobStorage = (key, callback) => {
		try {
			IndexedDbHelper.get(key).then(data => CommonHelper.safeFunctionCall(callback, data));
		} catch (e) {
			return null;
		}
	}

	static removeFromLobStorage = (key) => {
		try {
			IndexedDbHelper.remove(key);
			return true;
		} catch (e) {
			return false;
		}
	}
}

export default StorageHelper;