import React, { Component } from 'react';
import { Row } from 'react-bootstrap';

import returnToBasket from '../../../assets/images/profileSettings/returnToBasket.png';
import '../../../assets/sass/views/profileSettings/ProfileSetting.scss';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsCheckbox from '../../../components/inputs/NsCheckbox';
import NsModal from '../../../components/modals/NsModal';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';

class IpgGuidanceMessageModal extends Component {

    constructor(props) {
        super(props);
        this.memoizeCreateStickyItem = CommonHelper.memoizeFunc(this.handelPayButton)
    }

    payClicked = () => {
        CommonHelper.safeFunctionCall(this.props.payClicked)
    }

    handelPayButton = () => {
        const payButton = [
            CommonHelper.createStickyButtonItem("common.pay", 'ns-icon-payments', this.payClicked, 'primary', false),
        ];
        return payButton;
    }

    closeClicked = () => CommonHelper.safeFunctionCall(this.props.closePayModalClicked);

    render() {
        const payButton = this.memoizeCreateStickyItem();
        const { isChecked } = this.props;
        return (
            <NsModal maxWidth="xs"
                dialogTitle={getTranslatedRawText('profile.pay.modal.importantNotice')}
                showModal={this.props.showModal}
                closeModal={this.closeClicked}
                primaryActionButton={this.payClicked}
                actions={<NsStickyButton stickyButtons={payButton} />}>
                <div>
                    <span>{getTranslatedRawText('profile.pay.modal.AfterPayment')} </span>
                    <span className='fw-bold'>
                        {getTranslatedRawText('profile.pay.modal.returnToBasket')}
                    </span>
                    <span>
                        {getTranslatedRawText('profile.pay.modal.otherwiseYourPayment')}
                    </span>
                </div>
                <img className='my-3 return-to-basket w-100'
                    src={returnToBasket}
                    alt={getTranslatedRawText("components.imagesAlt.returnToBasket")} />
                <Row>
                    <div className='d-flex mb-3'>
                        <NsCheckbox value={isChecked}
                            onChange={this.props.checkboxClicked}
                            className='p-0 m-0'
                            checkboxClassName='ps-0' />
                        <div>
                            {getTranslatedRawText('profile.pay.modal.iWasNoticed')}
                        </div>
                    </div>
                </Row>
            </NsModal>
        )
    }
}

export default IpgGuidanceMessageModal