import React, { useState } from 'react';

import { GetLastJoinedUsersService } from '../../../../services/adminPanel/AdminDashboardService';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText, isEnCulture } from '../../../../utilities/CultureHelper';
import ApBaseUserInfoModal from '../../managements/users/bases/ApBaseUserInfoModal';
import NsButton from '../../../../components/buttons/NsButton';
import Enumerations from '../../../../configs/Enumerations';
import NsCard from '../../../../components/cards/NsCard';

const ApDuLastJoinedUsers = (props) => {

    const { refreshDate, parameters } = props;
    const [selectedUser, setSelectedUser] = useState();
    const [showUserDetailModal, setShowUserDetailModal] = useState(false);

    const columns = [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellClass: 'text-center px-0',
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (user) => {
                    return <NsButton className='secondary-text'
                        startIcon='ns-icon-details'
                        onClick={() => userDetailClicked(user)} />
                }
            }
        },
        {
            field: 'registeredAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.submitTime'),
            width: 120,
            dataType: Enumerations.gridType.dateTime,
        },
        {
            field: 'fullName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 170,
        },
        {
            field: 'mobile',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
            width: 100,
            cellClass: 'text-center'
        },
        {
            field: 'companyName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.companyName'),
            width: 130,
        },

    ];

    const userDetailClicked = (user) => {
        setSelectedUser(user);
        setShowUserDetailModal(true);
    }

    return (
        <NsCard className='p-3 h-100'>
            <div className='fw-bold mb-4'>{getTranslatedRawText('adminPanel.dashboard.lastJoinedUsers')}</div>

            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={GetLastJoinedUsersService}
                hasPagination={true}
                parameters={{
                    'Filter.SubmitTimeFrom': parameters.submitTimeFrom,
                    'Filter.SubmitTimeTo': parameters.submitTimeTo,
                }}
                columnDefs={columns} />

            {showUserDetailModal &&
                <ApBaseUserInfoModal showModal={true}
                    selectedUserId={selectedUser.userId}
                    closeModal={() => setShowUserDetailModal(false)} />
            }
        </NsCard>
    );
};

export default React.memo(ApDuLastJoinedUsers);