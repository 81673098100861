import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinearProgress } from '@mui/material';
import '../../assets/sass/components/progress/NsUploadingProgress.scss';

import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsTypography from '../../components/text/NsTypography';
import { isEmpty } from '../../utilities/ValidationHelper';
import CommonHelper from '../../utilities/CommonHelper';
import Enumerations from '../../configs/Enumerations';
import NsImage from '../../components/images/NsImage';
import NsButton from '../buttons/NsButton';
import NsTextTruncate from '../../components/textTruncate/NsTextTruncate'
import NsSelectMenu from '../dropdowns/NsSelectMenu';

class NsUploadingFileProgress extends React.Component {

    progressStatus = (progress) => {
        switch (progress.status) {
            case Enumerations.uploadingStatus.prepareForUpload:
                return getTranslatedRawText('components.nsLinearProgress.prepareForUpload');
            case Enumerations.uploadingStatus.uploading:
                return this.linearProgress(progress);
            case Enumerations.uploadingStatus.waiting:
                return getTranslatedRawText('components.nsLinearProgress.waiting');
            case Enumerations.uploadingStatus.uploaded:
                return getTranslatedRawText('components.nsLinearProgress.preparePage');
            case Enumerations.uploadingStatus.finished:
                return this.handleUploadMessage(this.getProgressData('components.nsLinearProgress.finalInfo', progress), 'ns-icon-circle-confirm success-color', 'black-color', progress.message);
            case Enumerations.uploadingStatus.warning:
                return this.handleUploadMessage(getTranslatedRawText('components.nsLinearProgress.uploadFailedWithWarning'), 'ns-icon-exclamation warning-color', 'warning-color', progress.message);
            case Enumerations.uploadingStatus.failed:
                return this.handleUploadMessage(getTranslatedRawText('components.nsLinearProgress.uploadFailedWithError'), 'ns-icon-exclamation red-color', 'red-color', progress.message);
            case Enumerations.uploadingStatus.deleteFailed:
                return this.handleUploadMessage(getTranslatedRawText('components.nsLinearProgress.deleteFailed'), 'ns-icon-exclamation red-color', 'red-color', progress.message);
            case Enumerations.uploadingStatus.duplicateName:
                return this.handleUploadMessage(getTranslatedRawText('components.nsLinearProgress.waitToDecision'), 'ns-icon-exclamation warning-color', 'warning-color', progress.message);
        }
    }

    linearProgress = (progress) => {
        return (
            <div className='w-100 px-1 px-md-0'>
                <div className='d-flex justify-content-between font-size-12 mb-2'>
                    <NsTypography variant='inherit'>
                        {this.getProgressData('components.nsLinearProgress.progressInfo', progress)}
                    </NsTypography>
                    <NsTypography variant='inherit'>
                        {this.getProgressData('components.nsLinearProgress.percentageInfo', progress)}
                    </NsTypography>
                </div>
                <div>
                    <LinearProgress variant="determinate"
                        value={progress.percent} />
                </div>
            </div>
        )
    }

    getProgressData = (text, progress) => {
        return CommonHelper.stringFormat(getTranslatedRawText(text),
            CommonHelper.convertByteToMegaByte(progress.loaded),
            CommonHelper.convertByteToMegaByte(progress.total),
            progress.percent);
    }

    handleUploadMessage = (message, icon, textColor, errorMessage) => {
        const errorMessageList = errorMessage?.messageItems?.map(item =>
            <NsTypography key={item.messageText}
                variant='inherit'
                className={`d-block font-size-12 mb-1 ps-1 ${textColor}`}>
                {item.messageText}
            </NsTypography>
        );
        return (
            <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                    <i className={`${icon} h2 m-0`} />
                    <NsTypography variant='inherit' className={`font-size-12 fw-bold ${textColor}`}>
                        {message}
                    </NsTypography>
                </div>
                {errorMessageList}
            </div>
        );
    }

    progressThumbnail = (thumbnails, fileExtention) => {
        if (isEmpty(thumbnails))
            return (<div className={`ns-icon-${fileExtention}-file h1 m-0 ms-1`} />);

        const thumbnailOptions = thumbnails.length > 1 && thumbnails.map((thumbnail, index) => ({
            id: index,
            value:
                <NsImage className='my-1'
                    key={index}
                    src={!!this.props.isCmsFile ? CommonHelper.createImageUrl(thumbnail) : ''}
                    imageUrl={!this.props.isCmsFile ? thumbnail : ''}
                    imgAlt="components.imagesAlt.UploadProgressThumbnail"
                    width='35px' />
        }))

        return <div className="d-flex align-self-center ms-1">

            {
                thumbnails.length > 1
                    ? <NsSelectMenu options={thumbnailOptions}
                        selectedItem={thumbnailOptions[0].id} />

                    : <NsImage
                        src={!!this.props.isCmsFile ? CommonHelper.createImageUrl(thumbnails[0]) : ''}
                        imageUrl={!this.props.isCmsFile ? thumbnails[0] : ''}
                        imgAlt="components.imagesAlt.UploadProgressThumbnail"
                        width='35px' />
            }

        </div>
    }

    render() {
        const {
            fileName,
            thumbnails,
            progress,
            isSingleFile,
            isLoading,
            replaceDuplicateClicked,
            keepBothDuplicateClicked,
            cancelDuplicateClicked,
            cancelUploadingClicked,
            changeFileClicked,
            deleteUploadedFileClicked,
            retryUploadingFileClicked,
            selectedFileInfo,
            justShowCancelButton
        } = this.props;
        const isNameDuplicate = progress.status === Enumerations.uploadingStatus.duplicateName;
        const clickOptions = {
            replaceDuplicateClicked,
            keepBothDuplicateClicked,
            cancelDuplicateClicked,
            cancelUploadingClicked,
            changeFileClicked,
            deleteUploadedFileClicked,
            retryUploadingFileClicked
        };
        return (
            <Row className='g-0 align-items-center'>
                <Col xs={{ span: 10, order: 1 }} md={{ span: 3, order: 1 }} className='progress__name overflow-auto p-0'>
                    <div key={fileName} className='d-flex align-items-center'>
                        {this.progressThumbnail(thumbnails, CommonHelper.getFileExtension(fileName))}
                        <NsTextTruncate fontSize='10' className='w-50 ms-1'>{progress.status !== Enumerations.uploadingStatus.finished ? fileName : selectedFileInfo?.name}</NsTextTruncate>
                    </div>
                </Col>
                <Col xs={{ span: 12, order: 3 }} md={{ span: isNameDuplicate ? 3 : 8, order: 2 }} className='d-flex ps-md-2 align-items-center p-0 font-size-14'>
                    {this.progressStatus(progress)}
                </Col>
                <Col xs={{ span: isNameDuplicate ? 12 : 2, order: 2 }} md={{ span: isNameDuplicate ? 6 : 1, order: 3 }} className='text-end p-1'>
                    <ButtonStatus progress={progress}
                        isSingleFile={isSingleFile}
                        isLoading={isLoading}
                        justShowCancelButton={justShowCancelButton}
                        clickOptions={clickOptions} />
                </Col>
            </Row>
        );
    }
}

function ButtonStatus({ progress, isSingleFile, clickOptions, isLoading, justShowCancelButton }) {
    if (progress.status === Enumerations.uploadingStatus.duplicateName) {
        const buttonArray = [
            { onClick: clickOptions.replaceDuplicateClicked, label: getTranslatedRawText('components.nsLinearProgress.replace') },
            { onClick: clickOptions.keepBothDuplicateClicked, label: getTranslatedRawText('components.nsLinearProgress.keepBoth') },
            { onClick: clickOptions.cancelDuplicateClicked, label: getTranslatedRawText('components.nsLinearProgress.cancel') },
        ];
        return (
            <div className='d-flex justify-content-end'>
                {buttonArray.map(button =>
                    <NsButton size='small'
                        key={button.label}
                        label={button.label}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={button.onClick}
                        className='secondary-outline'
                        wrapperClassName='d-inline-block ms-2' />
                )}
            </div>
        );
    }

    let icon, onClick = null;
    switch (progress.status) {
        case Enumerations.uploadingStatus.uploading:
            icon = 'ns-icon-close';
            onClick = clickOptions.cancelUploadingClicked;
            break;
        case Enumerations.uploadingStatus.finished:
            if (justShowCancelButton) return;
            isSingleFile ?
                (
                    icon = 'ns-icon-change masterFileModal-tour-changeFile',
                    onClick = clickOptions.changeFileClicked
                )
                :
                (
                    icon = 'ns-icon-delete',
                    onClick = clickOptions.deleteUploadedFileClicked

                )
            break;
        case Enumerations.uploadingStatus.warning:
            if (justShowCancelButton) return;
            icon = 'ns-icon-retry';
            onClick = clickOptions.retryUploadingFileClicked;
            break;
        case Enumerations.uploadingStatus.failed:
            if (justShowCancelButton) return;
            isSingleFile &&
                (
                    icon = 'ns-icon-change',
                    onClick = clickOptions.changeFileClicked
                )
            break;
    }
    return (icon ?
        <div>
            <NsButton size='small'
                loading={isLoading}
                disabled={isLoading}
                onClick={onClick}
                className='secondary border-0'
                wrapperClassName='d-inline-block'
                startIcon={icon} />
        </div>
        :
        null
    );
}

export default NsUploadingFileProgress;