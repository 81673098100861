import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';

import NsCard from '../../../../components/cards/NsCard';
import { GetBusinessTotalOrdersService } from '../../../../services/adminPanel/AdminDashboardService';
import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';

const ApDbTotalOrdering = (props) => {

    const { parameters, refreshDate } = props;
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [messageClassModal, setMessageClassModal] = useState();

    useEffect(() => {
        getBusinessTotalOrders();
    }, [refreshDate])

    const getBusinessTotalOrders = () => {
        setIsLoading(true);
        GetBusinessTotalOrdersService(parameters, getBusinessTotalOrdersCallback);
    }
    const getBusinessTotalOrdersCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setData(ApBasesDashboardHelper.setBarChartOptions(resultData));
        } else
            setMessageClassModal(messageClassModal);
    }

    const options = {
        scales: {
            x: {
                display: true,
                grid: {
                    display: false
                }
            },
        },
    }

    return (
        <NsCard className='p-3 h-100 align-content-between'>
            <Row className='align-items-center m-0 mb-3 justify-content-between'>
                <ApBasesDashboardCharts title='adminPanel.dashboard.totalOnlinePayments'
                    height=''
                    type='bar'
                    tooltipTitle='adminPanel.dashboard.totalOnlinePaymentsDbChartDes'
                    data={data}
                    hasZoom={true}
                    refreshClicked={getBusinessTotalOrders}
                    options={options}
                    isLoading={isLoading} />
            </Row>
            {messageClassModal}
        </NsCard>
    );
};

export default React.memo(ApDbTotalOrdering);