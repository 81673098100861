import clsx from 'clsx';
import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { getTranslatedRawText, isEnCulture } from '../../utilities/CultureHelper';
import { isBusinessPartnerRoute } from '../../utilities/BusinessPartnerHelper';
import CurrentUserHelper from '../../utilities/CurrentUserHelper';
import '../../assets/sass/components/carousel/NsCarousel.scss';
import CommonHelper from '../../utilities/CommonHelper';
import NsButtonOrLink from '../buttons/NsButtonOrLink';
import NsTypography from '../text/NsTypography';

class NsCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = { index: 0 };
    }

    handleSelect = (index) => this.setState({ index });

    createArray = (circulations) => {
        const createArray = [circulations.slice(0, 3)];
        for (let i = 0; i + 3 < circulations.length; i++) {
            createArray.push(circulations.slice(i + 1, i + 4));
        }
        return createArray;
    }

    cisculationClicked = (url) => isBusinessPartnerRoute() && this.props.history.push(url);

    getSharedSheetCirculations = (circulations) => {
        return this.createArray(circulations)?.map((circulation, index) => (
            <Carousel.Item key={index} className='px-3'>
                <div className="d-flex justify-content-around align-items-center">
                    {circulation.map(item => {
                        let url = CommonHelper.safeFunctionCall(this.props.productItemClicked, item.key);
                        return (<React.Fragment key={item.key}>
                            <div className={clsx(12 / circulation, 'btn p-0 text-center')}>
                                <NsTypography variant='body2'>
                                    <NsButtonOrLink className='text-decoration-none text-color' onClick={() => this.cisculationClicked(url)} to={url}>
                                        {`${item.value} ${getTranslatedRawText('product.common.pieces')}`}
                                    </NsButtonOrLink>
                                </NsTypography>
                                {CurrentUserHelper.isAuthenticated() && !!item.price &&
                                    <NsTypography variant='inherit' className='primary-paragraph fw-bold'>
                                        <NsButtonOrLink className='d-flex flex-wrap justify-content-center text-decoration-none light-text-color' onClick={() => this.cisculationClicked(url)} to={url}>
                                            <span className='font-size-16 red-color fw-bold me-2'>
                                                {!!item.priceInCurrentCurrency
                                                    ? CommonHelper.currencyFormat(item.priceInCurrentCurrency, true)
                                                    : CommonHelper.currencyFormat(item.price, true)
                                                }
                                            </span>
                                            {this.props.currencyTypeName}
                                        </NsButtonOrLink>
                                    </NsTypography>
                                }
                            </div>
                            <div className='custom-card__footer--vertical-line ms-n1'>&nbsp;</div>
                        </React.Fragment>);
                    }
                    )}
                </div>
            </Carousel.Item>
        ))
    }

    render() {
        const { circulations } = this.props;
        const { index } = this.state;

        return (
            <Carousel className="carousel text-center"
                activeIndex={index}
                onSelect={this.handleSelect}
                indicators={false}
                interval={null}
                controls={circulations.length > 3}
                prevIcon={<i className={clsx(isEnCulture() ? 'ns-icon-expand-left' : 'ns-icon-expand-right', 'h4 fw-light black-color m-0 ms-n1')} />}
                nextIcon={<i className={clsx(isEnCulture() ? 'ns-icon-expand-right' : 'ns-icon-expand-left', 'h4 fw-light black-color mb-0')} />}>
                {this.getSharedSheetCirculations(circulations)}
            </Carousel>
        );
    }
}

export default withRouter(NsCarousel);