import clsx from 'clsx';
import React from 'react';

import '../../assets/sass/components/inputs/Checkbox.scss';
import { FormControlLabel, Checkbox } from '@mui/material';
import CommonHelper from '../../utilities/CommonHelper';
class NsCheckbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: props.value || false,
            classes: props.className || '',
            disabled: props.disabled || false,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            checked: newProps.value,
            classes: newProps.className,
            disabled: newProps.disabled
        });
    }

    changeInput = (event) => this.setState({ checked: event.target.checked },
        () => CommonHelper.safeFunctionCall(this.props.onChange, this.state.checked));

    render() {
        return (
            <FormControlLabel
                className={clsx('ns-check-box', this.props.className)}
                control={
                    <Checkbox indeterminate={!!this.props.indeterminate}
                        onClick={this.props.onClick}
                        className={clsx('py-0', this.state.className, this.props.checkboxClassName)}
                        onChange={this.changeInput}
                        color="primary"
                        disabled={this.state.disabled}
                        checked={!!this.state.checked} />
                }
                label={this.props.label} />
        );
    }
}

export default NsCheckbox;