import React from "react";
import { Col, Row } from "react-bootstrap";

import ApBasesDfbPercentageStatusChart from "../bases/ApBasesDfbPercentageStatusChart";
import ApBasesDfbTotalByStatusGedgets from "../bases/ApBasesDfbTotalByStatusGedgets";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import ApBasesDfbStatusBarChart from "../bases/ApBasesDfbStatusBarChart";
import ApBasesDfbTotalLineChart from "../bases/ApBasesDfbTotalLineChart";
import ApBasesDfbCustomersGrid from "../bases/ApBasesDfbCustomersGrid";
import ApBasesDfbProvinceMap from "../bases/ApBasesDfbProvinceMap";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import ApBasesDfbCitiesGrid from "../bases/ApBasesDfbCitiesGrid";
import ApBasesDfbComments from "../bases/ApBasesDfbComments";

const ApDfbOverall = (props) => {

    const { parameters, refreshDate } = props;

    return (
        <Row className="g-2">
            {hasAccess([UserClaimEnumerations.getApDfbTotalByStatusGedgets]) && <Col xs={12} className="mb-3">
                <ApBasesDfbTotalByStatusGedgets parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbUserFeedbacks]) && <Col xs={12} className="mb-3">
                <ApBasesDfbTotalLineChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbTotalStatusByFilterOptions]) && <Col md={5} className="mb-3">
                <ApBasesDfbPercentageStatusChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbComments]) && <Col md={7} className="mb-3">
                <ApBasesDfbComments parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbByStateTypesAndDateChart]) && <Col xs={12} className="mb-3">
                <ApBasesDfbStatusBarChart parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbProvinces]) && <Col md={4} className="mb-3">
                <ApBasesDfbProvinceMap parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbCitiesGrid]) && <Col md={4} className="mb-3">
                <ApBasesDfbCitiesGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
            {hasAccess([UserClaimEnumerations.getApDfbCustomers]) && <Col md={4} className="mb-3">
                <ApBasesDfbCustomersGrid parameters={parameters} refreshDate={refreshDate} />
            </Col>
            }
        </Row>
    );
}

export default React.memo(ApDfbOverall);