import React from 'react';

import { Col, Row } from 'react-bootstrap';
import '../../assets/sass/components/dragFiles/NsChooseFile.scss';
import Enumerations from '../../configs/Enumerations';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import NsButton from '../buttons/NsButton';
import NsImageScaledView from '../images/NsImageScaledView';

class NsChooseFile extends React.Component {

    sideClicked = (isFront) => CommonHelper.safeFunctionCall(this.props.uploadFileClicked, isFront);

    mapSides = () => {
        const { printedSide, file } = this.props;
        let sides = [
            { side: getTranslatedRawText('components.nsChooseFile.frontSide'), isFront: true, fileInfo: file?.frontSideFileInfo },
            { side: getTranslatedRawText('components.nsChooseFile.backSide'), isFront: false, fileInfo: file?.backSideFileInfo },
        ];

        if (printedSide == Enumerations.printedSide.oneSide)
            sides = sides.filter(side => side.isFront);

        return sides.map((side, index) =>

            <Col key={index} xs={6} className=' px-1' >
                <div className='image-wrapper regularOrder-tour-chooseFile' onClick={() => this.sideClicked(side.isFront)} >
                    <div className='d-flex align-items-baseline w-100 background-black-color on-image-title p-1 text-on-image'>
                        <span className="px-2 ">
                            {side.side}
                        </span>
                        {!!side.fileInfo
                            ? <NsButton label='components.nsChooseFile.changeFile'
                                size='small'
                                onClick={() => this.sideClicked(side.isFront)}
                                startIcon='ns-icon-change'
                                wrapperClassName='ms-auto d-none d-sm-block'
                                className='secondary text-nowrap' />
                            : null}
                    </div>
                    {side.fileInfo
                        ?
                        <NsImageScaledView className='border background-disable-color'
                            onClick={() => this.sideClicked(side.isFront)}
                            src={side.fileInfo.draft}
                            imgAlt='components.imagesAlt.scaledImage'
                            width='w-100'
                        />

                        :
                        <NsButton wrapperClassName='align-self-center py-5 text-center'
                            className='primary-text mt-2 mt-md-5 mb-md-4'
                            label='components.nsChooseFile.clickHere'
                            onClick={() => this.sideClicked(side.isFront)} />
                    }
                </div>
            </Col>

        );
    }

    render() {
        return (
            <Row className='ns-choose-file w-100 justify-content-between px-3 m-0' >
                {this.mapSides()}
            </Row>
        );
    }
}

export default NsChooseFile;