import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsToggleGroupButton from '../../../../../components/buttons/NsToggleGroupButton';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';

class PoExtendedServiceBagHandle extends React.Component {

    constructor(props) {
        super(props);
        this.bagHandleOptions = ConstantsWithTranslation.getBagHandleOptions(null);

        this.state = {
            hasService: false,
            selectedBagHandleType: {},
            refreshExtendedServicesDate: props.refreshExtendedServicesDate
        }
    }

    componentDidMount = () => this.prepareService();

    componentDidUpdate = (prevProps) => {
        if (prevProps.refreshExtendedServicesDate !== this.state.refreshExtendedServicesDate)
            this.prepareService();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshExtendedServicesDate !== prevState.refreshExtendedServicesDate)
            return ({ refreshExtendedServicesDate: nextProps.refreshExtendedServicesDate });
        return null;
    }

    prepareService = () => {
        let service = OrderHelper.getSpecificService(this.props.routeId, Enumerations.extendedServices.handleType);
        this.setState({
            selectedBagHandleType: !isEmpty(service?.selectedItem)
                ? service.selectedItem
                : this.bagHandleOptions[0],
            hasService: !isEmpty(service),
            price: service?.price
        });
    }

    bagHandleOptionClicked = (selectedItem) => {
        this.setState({ selectedBagHandleType: selectedItem });
        OrderHelper.updateExtendedService(this.props.routeId, Enumerations.extendedServices.handleType, selectedItem);
        CommonHelper.safeFunctionCall(this.props.refreshSummary);
    }

    render() {
        const { hasService, selectedBagHandleType, price } = this.state;

        return (hasService
            ? <Row className='my-1'>
                <Col xs={8}>
                    <NsToggleGroupButton isShowGroupLabel={true}
                        label={getTranslatedRawText('regularOrder.extendedService.handleType')}
                        items={this.bagHandleOptions}
                        onClick={this.bagHandleOptionClicked}
                        selectedItemId={selectedBagHandleType?.key} />
                </Col>
                {selectedBagHandleType.id !== Enumerations.handleType.withoutHandle &&
                    <Col className='align-self-end'>
                        <div className='font-size-12 mb-2 text-nowrap'>
                            <b>&nbsp;{CommonHelper.currencyFormatByDefaultConfig(price)}</b>
                        </div>
                    </Col>
                }
            </Row >

            : null
        );
    }
}

export default PoExtendedServiceBagHandle;