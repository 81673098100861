import React from "react";

import { getTranslatedRawText } from "../../utilities/CultureHelper";
import { isEmpty } from "../../utilities/ValidationHelper";
import HistoryLabelBox from "./HistoryLabelBox";
import NsGridPagination from "../pagination/NsGridPagination";
import HistorySearch from "./HistorySearch";
import NsSkeleton from "../skeletons/NsSkeleton";
import Enumerations from "../../configs/Enumerations";
import Constants from "../../configs/Constants";
import CommonHelper from "../../utilities/CommonHelper";
import NsTab from "../tabs/NsTab";

class NsHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTabIndex: 0,
            selectedTab: !!this.props?.tabs && this.props?.tabs[0],
        };
    }

    tabChanged = (selectedTabIndex, selectedTab) => {
        CommonHelper.safeFunctionCall(this.props.tabChanged, selectedTabIndex, selectedTab);
        this.setState({ selectedTabIndex: selectedTabIndex, selectedTab: selectedTab })
    };

    selectedHistory = (item) => CommonHelper.safeFunctionCall(this.props.selectedHistory, item);
    sheetIdClickedHandler = (data) => CommonHelper.safeFunctionCall(this.props.sheetIdClicked, data);

    mapHistory = (history) => !isEmpty(history)
        ? history.map((item, index) => <HistoryLabelBox key={index}
            history={item}
            onClick={() => this.selectedHistory(item)}
            sheetIdClicked={this.sheetIdClickedHandler}
            className='mb-1'
            isAdminPanel={this.props.isAdminPanel} responseMessage={item?.responseMessage} />)
        : <HistoryLabelBox history={{ detail: getTranslatedRawText('adminPanel.common.notAvailableHistory') }}
            className='mb-1'
            isAdminPanel={this.props.isAdminPanel} />

    render() {
        const { history, rowPerPage, hasUserName, currentPage, totalHistoryRows, hasPagination, isLoadingHistory, typeItems, noSearchSection, tabs } = this.props;
        return (
            <div className='background-color mb-2 mb-md-0'>
                {!noSearchSection &&
                    < HistorySearch hasUserName={hasUserName}
                        searchClicked={this.props.searchClicked}
                        typeItems={typeItems}
                        isLoadingHistory={isLoadingHistory} />
                }
                {!!tabs &&
                    <NsTab tabs={tabs}
                        value={this.state.selectedTabIndex}
                        onChange={this.tabChanged} />}
                <div className='overflow-auto custom-scrollbar p-2' style={{ height: history?.length > 1 ? '40vh' : '25vh' }}>
                    {isLoadingHistory
                        ? <NsSkeleton isVisible={isLoadingHistory}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                        : this.mapHistory(history)}
                </div>

                {hasPagination &&
                    <NsGridPagination currentPage={currentPage}
                        totalRows={totalHistoryRows}
                        isHistory={true}
                        rowPerPage={rowPerPage}
                        refreshButtonClicked={this.props.searchClicked}
                        rowPerPageOptionsInGrid={Constants.rowPerPageOptionsInGrid}
                        handleRowPerPage={this.props.rowPerPageChanged}
                        handlePagination={this.props.paginationButtonClicked} />
                }
            </div>
        );
    }
}

export default NsHistory;