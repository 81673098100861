import React, { Component } from 'react'
import NsAdvanceSearch from '../../../../../components/advanceSearch/NsAdvanceSearch';
import NsButton from '../../../../../components/buttons/NsButton';
import NsSearchInput from '../../../../../components/inputs/NsSearchInput';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../configs/Enumerations';
import { GetAgencyCitiesService } from '../../../../../services/ProfileSetting/AgencyService';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';

class UpBaseAgencySearchOptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mobile: null,
            accountStatus: {},
            accountTypes: {},
            city: null,
            cities: [],
            searchTerm: null,
            messageClassModal: <></>
        }
    }

    componentDidMount = () => {
        GetAgencyCitiesService(this.getAgencyCitiesCallback);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.searchButtonTimeStamp !== prevProps.searchButtonTimeStamp)
            this.searchFormClicked();
    }

    getAgencyCitiesCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            this.setState({ cities: resultData });
        } else this.setState({ messageClassModal });
    }

    valueChanged = (value, valueStateName) => {
        const valueName = CommonHelper.getVariableName(valueStateName);
        this.setState({
            [valueName]: value,
        });
    }

    clearClicked = () => {
        this.setState({
            mobile: null,
            accountStatus: {},
            accountTypes: {},
            city: null
        }, () => this.searchFormClicked());
    }

    searchFormClicked = () => CommonHelper.safeFunctionCall(this.props.searchFormClicked, this.state);

    searchTextChanged = (value) => this.setState({ searchTerm: value });

    render() {

        const searchOptions = [
            {
                type: Enumerations.advanceSearchFieldType.mobileInput,
                label: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
                fieldName: 'mobile',
                value: this.state.mobile,
                onChange: (value) => this.valueChanged(value, { mobile: this.state.mobile }),
            },
            {
                type: Enumerations.advanceSearchFieldType.autoComplete,
                label: getTranslatedRawText('components.advanceSearch.accountStatus'),
                fieldName: 'accountStatus',
                value: this.state.accountStatus,
                items: ConstantsWithTranslation.getAccountStatus(),
                onChange: (value) => this.valueChanged(value, { accountStatus: this.state.accountStatus }),
            },
            {
                type: Enumerations.advanceSearchFieldType.autoComplete,
                label: getTranslatedRawText('components.accountType.accountType'),
                fieldName: 'accountTypes',
                value: this.state.accountTypes,
                items: ConstantsWithTranslation.getAccountType(),
                onChange: (value) => this.valueChanged(value, { accountTypes: this.state.accountTypes }),
            },
            {
                type: Enumerations.advanceSearchFieldType.autoComplete,
                label: getTranslatedRawText('components.city.city'),
                fieldName: 'city',
                value: this.state.city,
                items: this.state.cities,
                onChange: (value) => this.valueChanged(value, { city: this.state.city }),
            },
        ];

        const {
            searchTerm,
            mobile,
            accountStatus,
            accountTypes,
            city,
            messageClassModal
        } = this.state;

        const searchValues = {
            mobile,
            accountStatus,
            accountTypes,
            city
        }

        return (
            this.props.findAgenciesUser ?
                <>
                    <NsAdvanceSearch searchOptions={searchOptions}
                        autoFocus={this.props.autoFocus}
                        searchValues={searchValues} />

                    <div className='d-flex flex-row-reverse my-2 gap-1'>
                        {this.props.children}
                        <NsButton className='secondary-outline'
                            label='common.clear'
                            startIcon='ns-icon-clear'
                            loading={this.props.isLoading}
                            onClick={this.clearClicked} />
                        <NsButton className='primary'
                            label={getTranslatedRawText('profile.orderReport.search')}
                            startIcon='ns-icon-search'
                            loading={this.props.isLoading}
                            onClick={this.searchFormClicked} />
                    </div>
                </>
                : <>
                    <div className='d-flex flex-column flex-md-row w-100 mb-3 gap-1'>
                        <NsSearchInput hasSearchIcon={true}
                            value={searchTerm}
                            searchFormClicked={this.searchFormClicked}
                            handleSearchTextChanged={this.searchTextChanged}
                            placeHolder={getTranslatedRawText('product.common.searchPlaceHolder')}
                            autoFocus={this.props.autoFocus}
                            className='background-color rounded'
                            inputClassName='background-color' />
                        <div className='ms-auto d-flex mt-2 mt-md-0 gap-1'>
                            {this.props.showAdvanceSearch && <NsButton className='primary-outline mt-0'
                                startIcon={expandSearchIcon}
                                wrapperClassName='px-1'
                                onClick={toggleBasicSearchOptions} />}

                            <NsButton className='primary'
                                label='common.search'
                                startIcon='ns-icon-search'
                                loading={this.props.isLoading}
                                onClick={this.searchFormClicked} />

                            {this.props.children}

                        </div>
                    </div>
                    {messageClassModal}
                </>
        )
    }
}

export default UpBaseAgencySearchOptions;
