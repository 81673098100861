import React, { Component } from 'react';
import clsx from 'clsx';

import NsTypography from '../../../components/text/NsTypography';
import { getTranslatedRawText, isEnCulture } from '../../../utilities/CultureHelper';
import NsModal from '../../../components/modals/NsModal';
import NsHtmlToReact from '../../../components/htmlToReact/NsHtmlToReact';
import { getConfigObjectByCulture } from '../../../utilities/ConfigHelper';
import Config from '../../../configs/Config';
import { isEmpty } from '../../../utilities/ValidationHelper';

class ProductLicenseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProductLicenseModal: false,
        }
    }
    toggleProductLicenseModal = () => this.setState({ showProductLicenseModal: !this.state.showProductLicenseModal });

    render() {
        const { showProductLicenseModal } = this.state;
        return (
            <>
                {!isEmpty(getConfigObjectByCulture(Config?.pdMoreInfo)[0]) &&
                    <div className='d-flex flex-column mt-2 cursor-pointer' onClick={this.toggleProductLicenseModal}>
                        <NsTypography variant='inherit' className='font-size-12 mb-1'>{getTranslatedRawText('product.detail.productLicenseRequired')}</NsTypography>
                        <NsTypography variant='inherit' className='font-size-12 fw-bold'>{getTranslatedRawText('product.detail.prohibitsPrintingDetail')}
                            <i class={clsx(isEnCulture() ? "ns-icon-expand-right" : "ns-icon-expand-left")}></i></NsTypography>
                    </div>}
                {showProductLicenseModal &&
                    <NsModal
                        dialogTitle={getTranslatedRawText('product.detail.prohibitsPrintingDetail')}
                        showModal={showProductLicenseModal}
                        closeModal={this.toggleProductLicenseModal} >
                        <div className='font-size-14 border rounded p-3'>
                            <NsHtmlToReact>
                                {getConfigObjectByCulture(Config?.pdMoreInfo)[0]}
                            </NsHtmlToReact>
                        </div>
                    </NsModal>
                }
            </>
        )
    }
}
export default ProductLicenseModal;