import React from 'react';
import clsx from 'clsx';
import { GetImageService } from '../../services/common/CommonService';
import CommonHelper from '../../utilities/CommonHelper';
import { getTranslatedRawText, isEnCulture } from '../../utilities/CultureHelper';
import NsSkeleton from '../skeletons/NsSkeleton';
import Enumerations from '../../configs/Enumerations';
import imgNotFoundEn from '../../assets/images/not-found-img-en.png';
import imgNotFoundFa from '../../assets/images/not-found-img-fa.png';
import { isEmpty } from '../../utilities/ValidationHelper';

class NsImage extends React.Component {

    constructor(props) {
        super(props);
        this.defaultImgSrc = isEnCulture()
            ? imgNotFoundEn
            : imgNotFoundFa;
        this.state = {
            src: props.src,
            isLoading: false,
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (!!newProps.imageUrl && newProps.imageUrl !== this.props.imageUrl)
            this.setState({ isLoading: true },
                () => this.getImageFromServer(newProps.imageUrl));
    }

    onClickHandler = () => CommonHelper.safeFunctionCall(this.props.onClick);

    getImageFromServer = (imageUrl) => {
        GetImageService(imageUrl, (resultData) => {
            this.setState({ src: resultData, isLoading: false });
        });
    }

    componentDidMount = () => {
        const { imageUrl } = this.props;
        if (!!imageUrl)
            this.setState({ isLoading: true },
                () => this.getImageFromServer(imageUrl)
            );
    }

    render() {
        const { className, imgAlt, wrapperClassName, width, onLoad, draggable } = this.props;
        const { src, isLoading } = this.state;
        const imgSrc = this.props.src || src || this.defaultImgSrc;
        const alt = !isEmpty(imgSrc) ? getTranslatedRawText(!isEmpty(imgAlt) ? imgAlt : '') || getTranslatedRawText("components.imagesAlt.img") : getTranslatedRawText("components.imagesAlt.imageNotFound");
        return (
            <div className={clsx('d-flex justify-content-center w-100', wrapperClassName)}>
                {isLoading ?
                    <div className='w-100'>
                        <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                                Enumerations.skeleton.rect,
                            ]} />
                    </div>
                    :
                    <img src={imgSrc}
                        onClick={this.onClickHandler}
                        alt={this.props.alt ? this.props.alt : alt}
                        width={width}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = this.defaultImgSrc;
                        }}
                        className={className}
                        onLoad={onLoad}
                        draggable={draggable} />
                }
            </div>
        );
    }
}

export default NsImage;