import Links from "../../configs/links/AllLinks";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { DeleteRequest, GetRequest, PostRequest } from "../bases/BaseService";

const PostFileService = (file, fileUploaderCategoryType, userId, callback, onUploadProgressCallback) => {
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('FileUploaderCategoryType', fileUploaderCategoryType);
    !!userId && formData.append('UserId', userId);
    return PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postFileUploader), formData, callback, null, onUploadProgressCallback);
}

const GetApFileManagerFilesService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFileManagerFiles), parameter, callback);
};

const DeleteApFileManagerFilesService = (data, callback) => {
    DeleteRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApFileManagerFiles), {}, data, callback, true);
};


export {
    PostFileService,
    GetApFileManagerFilesService,
    DeleteApFileManagerFilesService
};
