import { GetSidebarTotalPriceAndCountService } from '../../services/ProfileSetting/UserOrderService';

const GetTotalPriceAndCount = 'GET_TOTAL_PRICE_AND_COUNT';

const initialState = { miniCartInfo: '' };

export const miniCartActionCreators = {
    getTotalPriceAndCount: () => async (dispatch, getState) => {
        GetSidebarTotalPriceAndCountService((resultData, result, messageClassModal) => {
            if (result?.data?.hasError)
                return;
            dispatch({ type: GetTotalPriceAndCount, miniCartInfo: resultData });
        });
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GetTotalPriceAndCount:
            return {
                ...state,
                miniCartInfo: action.miniCartInfo
            }
        default:
            return state;
    }
}

