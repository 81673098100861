import clsx from 'clsx';
import React from 'react';

import NsEditFileActionTab from '../../../../../components/editFileActions/NsEditFileActionTab';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';

class PoBaseEditFileActionInModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFileInfo: '',
            imgContent: '',
            imgContentActions: [],
            imgResizeActionsStack: [],
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.setNewSizeDate !== this.props.setNewSizeDate)
            this.resizeToSpecificSize(newProps.selectedFileInfo.width, newProps.selectedFileInfo.height);
    }

    resizeToSpecificSize = (width, height) => {
        let newAction = {
            fileChangeType: Enumerations.fileActions.resize,
            width,
            height
        };
        this.imgContentSizeChanged([newAction], width, height, this.props.currentFileKey > 0);
    }

    undoAllChangesClicked = () => this.setState({ imgResizeActionsStack: [], imgContentActions: [] },
        () => CommonHelper.safeFunctionCall(this.props.undoAllChangesClicked));

    imgContentActionsChanged = (newAction) => {
        newAction.width = this.state.selectedFileInfo?.width;
        newAction.height = this.state.selectedFileInfo?.height;
        this.setState({ imgContentActions: [...this.state.imgContentActions, newAction] },
            () => CommonHelper.safeFunctionCall(this.props.setEditFileActionChanges, this.state.selectedFileInfo, this.state.imgContent, this.state.imgContentActions, this.state.imgResizeActionsStack));
    }

    setNewContent = (newContent, isRotate90, callback) => {
        let selectedFileInfo = { ...this.props.selectedFileInfo };
        if (!!isRotate90) {
            selectedFileInfo.width = this.props.selectedFileInfo.height;
            selectedFileInfo.height = this.props.selectedFileInfo.width;
        }
        selectedFileInfo.draft = newContent;
        this.setState({
            selectedFileInfo,
            imgContent: newContent,
            imgContentActions: this.mergeActionsAndResizes(),
            imgResizeActionsStack: [],
        }, () => callback(true));
    }

    imgContentSizeChanged = (imgResizeActionsStack, imgWidth, imgHeight, resetFilmTopAndLeft) => {
        let selectedFileInfo = { ...this.props.selectedFileInfo };
        selectedFileInfo.width = imgWidth;
        selectedFileInfo.height = imgHeight;
        if (resetFilmTopAndLeft) {
            selectedFileInfo.top = 0;
            selectedFileInfo.left = 0;
        }
        this.setState({ imgResizeActionsStack, selectedFileInfo },
            () => CommonHelper.safeFunctionCall(this.props.setEditFileActionChanges, this.state.selectedFileInfo, this.state.imgContent, this.state.imgContentActions, this.state.imgResizeActionsStack));
    }

    mergeActionsAndResizes = () => {
        return !isEmpty(this.state.imgResizeActionsStack)
            ? [...this.state.imgContentActions, ...this.state.imgResizeActionsStack]
            : [...this.state.imgContentActions];
    }

    render() {
        const { selectedFileInfo, hasPositioning, hasResizeButton, imgContent, selectedDimension, masterImageWidth, masterImageHeight } = this.props;
        const { imgContentActions, imgResizeActionsStack, } = this.state;
        const resizeButtonDisabled = selectedFileInfo?.width === masterImageWidth && selectedFileInfo?.height === masterImageHeight;

        return (
            <>
                <div className='d-flex align-items-end justify-content-between mb-3'>
                    {!hasResizeButton &&
                        <div className='font-size-12 align-self-center'>
                            {getTranslatedRawText('regularOrder.modal.selectedDimension')}:
                            <div className={clsx('ps-3', isEmpty(selectedDimension) ? 'red-color' : 'success-color', 'fw-bold')}>
                                {isEmpty(selectedDimension)
                                    ? getTranslatedRawText('regularOrder.modal.noDimensionSelected')
                                    : CommonHelper.stringFormat(getTranslatedRawText('product.detail.rowDimension'), selectedDimension.name, selectedDimension.width, selectedDimension.height)
                                }

                                {selectedDimension?.latCount > 1 &&
                                    <div>
                                        ({CommonHelper.stringFormat(getTranslatedRawText('regularOrder.confirmDetail.latCount'), selectedDimension?.latCount)})
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <NsButton label={getTranslatedRawText('fileManager.common.undoAllChanges')}
                        wrapperClassName='ms-auto text-nowrap'
                        onClick={this.undoAllChangesClicked}
                        startIcon='ns-icon-undo'
                        size='small'
                        disabled={isEmpty(imgContentActions) && isEmpty(imgResizeActionsStack)}
                        className='secondary-outline' />
                </div>

                <NsEditFileActionTab tabClassName='small-tab'
                    hasPositioning={hasPositioning}
                    imgContent={imgContent}
                    imgWidth={selectedFileInfo?.width}
                    imgHeight={selectedFileInfo?.height}
                    setNewContent={this.setNewContent}
                    alignFilm={this.props.alignFilm}
                    changeIsLoadingState={this.props.changeIsLoadingState}
                    imgContentSizeChanged={this.imgContentSizeChanged}
                    imgContentActionsChanged={this.imgContentActionsChanged} >

                    {this.props.children}

                    {hasResizeButton &&
                        <NsButton label='professionalOrder.modal.fit'
                            wrapperClassName='mb-2'
                            size='small'
                            startIcon='ns-icon-fit'
                            className='secondary-outline'
                            disabled={resizeButtonDisabled}
                            onClick={() => this.resizeToSpecificSize(masterImageWidth, masterImageHeight)} />
                    }

                </NsEditFileActionTab>
            </>
        );
    }
}



export default PoBaseEditFileActionInModal;