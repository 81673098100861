import { Component, React } from 'react';
import { Col, Row } from 'react-bootstrap';

import { getTitleMetaTagByCulture } from '../../../../components/metaTags/NsSetPageTitleAndDescription';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import { GetFestivalsService } from '../../../../services/singlePages/FestivalService';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';
import NsTypography from '../../../../components/text/NsTypography';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsButton from '../../../../components/buttons/NsButton';
import CommonHelper from '../../../../utilities/CommonHelper';
import DateHelper from '../../../../utilities/DateHelper';
import Links from '../../../../configs/links/AllLinks';
import Config from '../../../../configs/Config';
import SinglePage from '../../SinglePage';

class Festivals extends Component {

    constructor(props) {
        super(props);
        this.festivalBanners = getConfigObjectByCulture(Config.images)?.festivalBanners;
        this.state = {
            festivals: [],
            isLoading: true,
            messageClassModal: (<></>)
        }
    }

    componentDidMount = () => GetFestivalsService(this.getFestivalsCallback);

    getFestivalsCallback = (resultData, result, messageClassModal) => {
        if (!result.data.hasError) {
            this.setState({
                festivals: resultData,
                isLoading: false
            });
        } else
            this.setState({ messageClassModal, isLoading: false });
    }

    viewFestivalClicked = (id) => {
        this.props.history.push(CommonHelper.stringFormat(
            getUrlWithCurrentCulture(Links, Links.festival)
            , id));
    }

    render() {
        return (
            <SinglePage >

                <NsPageMainContentWrapper header={getTranslatedRawText('festival.festivals.festivals')}
                    icon='ns-icon-festivals h1' className='mb-3'>
                    <NsLoadingProgress visible={this.state.isLoading} />

                    {!isEmpty(this.state.festivals) && this.state.festivals.map((festival, index) => {
                        return festival.isDone
                            ? <Col key={index} xs={12} lg={4} className='d-inline-block'>
                                <div className='rounded border-red-color-1px me-1 my-2 p-3 d-flex flex-column justify-content-end'>
                                    <NsTypography variant='inherit' className='fw-bold red-color'>{festival.name}</NsTypography>
                                    <div className='my-2'>
                                        <span>
                                            <span className='red-color'>{getTranslatedRawText('festival.executingDate')}</span>
                                            <span className='text-nowrap red-color fw-bold'>
                                                {DateHelper.convertDateToText(festival?.executeTime)}
                                            </span>
                                        </span>
                                    </div>
                                    <NsButton label={getTranslatedRawText('festival.festivals.viewFestival')}
                                        className='primary'
                                        onClick={() => this.viewFestivalClicked(festival.id)} />
                                </div>
                            </Col>
                            : <Row key={index} className='festival__main-banner w-100 mb-3 p-4 m-0' style={{ '--top-banner': `url(${this.festivalBanners[0]?.src})` }} >
                                <Col xs={12} lg={6} className='d-flex flex-column festival__main-banner__text-on-image'>
                                    <p className={CommonHelper.isMobileDevice() ? 'festival__font-32' : 'festival__font-40 lh-sm'}>
                                        {festival.name}
                                    </p>
                                    <p>{festival.description} </p>
                                    <div className='d-flex'>
                                        <NsButton label={getTranslatedRawText('festival.festivals.viewFestival')}
                                            className='primary'
                                            onClick={() => this.viewFestivalClicked(festival.id)} />
                                    </div>
                                </Col>
                                <Col xs={12} lg={6} className='py-4 px-lg-5 text-center' >
                                    <img src={this.festivalBanners[1]?.src}
                                        className='w-100'
                                        alt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.festivalBanners"), getTitleMetaTagByCulture)} />
                                </Col>
                            </Row>

                    })}

                </NsPageMainContentWrapper>

                {this.state.messageClassModal}

            </SinglePage>
        )
    }
}

export default Festivals;