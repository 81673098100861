import React from 'react';
import NsSkeleton from '../../../../components/skeletons/NsSkeleton';
import Enumerations from '../../../../configs/Enumerations';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import EnumerationTranslationHelper from '../../../../utilities/EnumerationTranslationHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';

function BillBookModalSummary(props) {

    const { billBookCopy, billBookPrintColor, serialStartFrom, billBookBindingType, billBookBindingSideType, hasUnderhand, selectedSerialsLength, selectedPerforationsLength,
        defaultPerforationAndSerialNumberPrice, extraSerialNumberPrice, extraPerforationPrice, optionPrice, isLoadingPrice } = props;

    const calculateTotalPrice = () => {
        let firstSerialPerforationPrice = selectedSerialsLength > 0 || selectedPerforationsLength > 0 ? defaultPerforationAndSerialNumberPrice : 0;
        let secondSerialPrice = selectedSerialsLength > 1 ? extraSerialNumberPrice : 0;
        let secondPerforationPrice = selectedPerforationsLength > 1 ? extraPerforationPrice : 0;
        CommonHelper.safeFunctionCall(props.getTotalBillBookPrice, optionPrice + firstSerialPerforationPrice + secondSerialPrice + secondPerforationPrice);
        return CommonHelper.currencyFormatByDefaultConfig(optionPrice + (firstSerialPerforationPrice + secondSerialPrice + secondPerforationPrice) * billBookCopy.billBookCopyType);
    }

    const label = selectedSerialsLength > 0 && selectedPerforationsLength < 1
        ? 'regularOrder.billBook.firstSerialNumber'
        : selectedSerialsLength < 1 && selectedPerforationsLength > 0
            ? 'regularOrder.billBook.firstPerforation'
            : selectedSerialsLength > 0 && selectedPerforationsLength > 0
                ? 'regularOrder.billBook.serialNumberAndPerforation'
                : '';

    return <div className='d-flex flex-column background-color rounded rounded-3 extended-service__tour__bill-book__modal-summary' >
        <div className='d-flex flex-column px-2 pt-2 gap-2 font-size-12' >
            <BasicOptions billBookCopy={billBookCopy}
                billBookPrintColor={billBookPrintColor}
                billBookBindingType={billBookBindingType}
                serialStartFrom={serialStartFrom}
                billBookBindingSideType={billBookBindingSideType}
                hasUnderhand={hasUnderhand} />
            {
                isLoadingPrice
                    ? <NsSkeleton className='w-100' isVisible={true} variant={[Enumerations.skeleton.text]} />
                    : <span className='mb-2'>
                        {getTranslatedRawText('regularOrder.confirmDetail.basePrice')}:&nbsp;<b>{CommonHelper.currencyFormatByDefaultConfig(optionPrice)}</b>
                    </span>
            }


            <div className='d-flex flex-column border-bottom'>
                {!isEmpty(label) &&
                    <PriceLabel label={label}
                        price={defaultPerforationAndSerialNumberPrice}
                        copies={billBookCopy.billBookCopyType} />
                }
                {
                    selectedSerialsLength > 1 &&
                    <PriceLabel label='regularOrder.billBook.secondSerialNumber'
                        price={extraSerialNumberPrice}
                        copies={billBookCopy.billBookCopyType} />
                }
                {
                    selectedPerforationsLength > 1 &&
                    <PriceLabel label='regularOrder.billBook.secondPerforation'
                        price={extraPerforationPrice}
                        copies={billBookCopy.billBookCopyType} />
                }
            </div >
        </div>
        <div className='background-outline-color w-100 p-2 rounded-bottom rounded-bottom-3'>
            {isLoadingPrice
                ? <NsSkeleton className='w-100' isVisible={true} variant={[Enumerations.skeleton.text]} />
                : <span className='mb-2 font-size-16 title-color'>
                    {getTranslatedRawText('regularOrder.confirmDetail.totalPrice')}:&nbsp;<b className='red-color'>{calculateTotalPrice()}</b>
                </span>
            }
        </div>
    </div >
}

function PriceLabel(props) {
    const { label, price, copies } = props;
    return <span className='mb-1'>
        {getTranslatedRawText(label)}:&nbsp;
        <b>
            {CommonHelper.currencyFormatByDefaultConfig(price)}
            &nbsp;*&nbsp;
            {copies} {getTranslatedRawText('regularOrder.billBook.copies')}
        </b>
    </span>
}

export function BasicOptions(props) {
    const { billBookCopy, billBookPrintColor, serialStartFrom, billBookBindingType, billBookBindingSideType, hasUnderhand } = props;
    return <div className='d-flex flex-column border-bottom  gap-2'>
        <span className='fw-bold'>
            {getTranslatedRawText('regularOrder.billBook.billBookOptions')}
        </span>
        <span>
            {getTranslatedRawText('regularOrder.billBook.billBookCopy')}:&nbsp;<b>{EnumerationTranslationHelper.getTranslateBookNumberOfCopyType(billBookCopy?.billBookCopyType)?.label}</b>
        </span>
        <span>
            {getTranslatedRawText('regularOrder.billBook.billBookPrintColor')}:&nbsp;<b>{EnumerationTranslationHelper.getTranslateBookNumberOfPrintColorType(billBookPrintColor)}</b>
        </span>
        <span>
            {getTranslatedRawText('regularOrder.billBook.billBookBindingType')}:&nbsp;<b>{EnumerationTranslationHelper.getTranslateBookBindingType(billBookBindingType)?.label}</b>
        </span>
        {billBookBindingType !== Enumerations.billBookBindingType.free &&
            <>
                <span>
                    {getTranslatedRawText('regularOrder.billBook.billBookBindingSideType')}:&nbsp;<b>
                        {billBookBindingType === Enumerations.billBookBindingType.free
                            ? getTranslatedRawText('regularOrder.billBook.hasNotIt')
                            : EnumerationTranslationHelper.getTranslateBookBindingSideType(billBookBindingSideType)}</b>
                </span>
                <span>
                    {getTranslatedRawText('regularOrder.billBook.underHand')}:&nbsp;
                    <b>
                        {getTranslatedRawText(hasUnderhand
                            ? 'regularOrder.billBook.hasIt'
                            : 'regularOrder.billBook.hasNotIt'
                        )}
                    </b>
                </span>
            </>
        }
        {serialStartFrom &&
            <span>
                {getTranslatedRawText('regularOrder.billBook.serialStartFrom')}:&nbsp;<b>{serialStartFrom}</b>
            </span>
        }

    </div>
}

export default BillBookModalSummary;