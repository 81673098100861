import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetAgenciesService } from '../../../../services/ProfileSetting/AgencyService';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import UpBaseProfileAgencyInfo from '../userProfile/bases/UpBaseProfileAgencyInfo';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import UserPanelWrapper from '../../UserPanelWrapper';

class UpAgencyInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resultMessageClass: <></>,
            agencies: [],
        }
    }

    componentDidMount = () => this.getAgencyDetail();

    getAgencyDetail = () => GetAgenciesService(this.getAgenciesCallback);

    getAgenciesCallback = (resultData, result, resultMessageClass) => {
        if (!result.data.hasError) this.setState({ agencies: resultData });
        else this.setState({ resultMessageClass });
    }

    mapAgencies = () => {
        return !isEmpty(this.state.agencies) && this.state.agencies.map((agency, index) =>
            <Col key={index} md={6} >
                <UpBaseProfileAgencyInfo option={agency} />
            </Col>
        )
    }

    render() {
        const { resultMessageClass } = this.state;

        return (
            <UserPanelWrapper titleName={getTranslatedRawText('profile.actionButton.agencyInformation')}
                titleIcon='ns-icon-agency-information' >

                <Row className='g-1'>
                    {this.mapAgencies()}
                </Row>
                {resultMessageClass}
            </UserPanelWrapper>
        )
    }
}

export default UpAgencyInformation;