import React from 'react';
import { Route } from 'react-router-dom';

import Header from './headers/Header';
import Footer from './footers/Footer';
import MainLayout from './MainLayout';
import AuthenticationChecker from '../bases/AuthenticationChecker';
import NsMetaTag from '../../../components/metaTags/NsMetaTag'
import CommonHelper from '../../../utilities/CommonHelper';
import NsgoogleTagManager from '../../../components/googleTagManager/NsGoogleTagManager'
import UrlHelper from '../../../utilities/UrlHelper';
import FestivalEventPromotion from './promotions/FestivalEventPromotion';
import NsReachResult from '../../../components/metaTags/NsReachResult';
import Enumerations from '../../../configs/Enumerations';

class MainLayoutRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice: CommonHelper.isMobileDevice(),
        }
    }

    UNSAFE_componentWillMount = () => UrlHelper.addWwwToRoute();

    componentDidMount() {
        this.setWindowSize = () => this.setState({ isMobileDevice: CommonHelper.isMobileDevice() })
        window.addEventListener('resize', this.setWindowSize)
    }

    componentWillUnmount = () => window.removeEventListener('resize', this.setWindowSize);

    render() {
        let { component, ...rest } = this.props;
        const Component = component;
        return <Route {...rest} render={matchProps => (
            <AuthenticationChecker needAuthentication={rest.needAuthentication}>
                <NsMetaTag />
                <NsReachResult reachResultType={Enumerations.reachResultType.website} />
                <div key={this.state.isMobileDevice} className='background-color d-flex flex-column min-vh-100'>
                    <NsgoogleTagManager />
                    <FestivalEventPromotion />
                    <Header {...matchProps} />
                    <MainLayout {...rest}>
                        <Component {...matchProps}
                            showResetTour={rest?.showResetTour}
                            breadCrumb={rest?.breadCrumb} />
                    </MainLayout>
                    <Footer isShowMiddleFooter={true} />
                </div>
            </ AuthenticationChecker>
        )} />
    }
};

export default MainLayoutRoute;