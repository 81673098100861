import clsx from "clsx";
import React from "react";
import { Col } from "react-bootstrap";

import { getTranslatedRawText } from "../../../../../../utilities/CultureHelper";
import NsLabelNoData from "../../../../../../components/labels/NsLabelNoData";
import NsSkeleton from "../../../../../../components/skeletons/NsSkeleton";
import NsTypography from "../../../../../../components/text/NsTypography";
import { isEmpty } from "../../../../../../utilities/ValidationHelper";
import CommonHelper from "../../../../../../utilities/CommonHelper";
import DateHelper from "../../../../../../utilities/DateHelper";
import NsCard from "../../../../../../components/cards/NsCard";
import ConstantsWithTranslation from "../../../../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../../../../configs/Enumerations";

const UpBaseQueueOrdersItem = ({ queues, isLoading, orderQueueClicked, isUseInUserPanel }) => {

    return isLoading
        ? CommonHelper.createArray(4).map((item, index) =>
            <Col key={index} xs={6} xl={4} xxl={3} onClick={() => orderQueueClicked(index)}>
                <NsSkeleton isVisible={true}
                    variant={[
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect,
                        Enumerations.skeleton.rect
                    ]} />
            </Col>)
        : !isEmpty(queues) ? queues.map((queue, index) => <Col md={6} xl={4} xxl={isUseInUserPanel ? 4 : 3} key={index} onClick={() => orderQueueClicked(index)}>
            <div className={isUseInUserPanel ? 'border border-black-colors rounded' : ''}>
                <NsCard className='border-0 p-3 cursor-pointer h-100'>
                    <div className='d-flex flex-column'>
                        <div className='d-flex gap-3 mb-4'>
                            <div className='background-element-color p-2 rounded '>
                                <i className={clsx(CommonHelper.getShareSheetStateColor(queue.sharedSheetOrderStateType), 'ns-icon-details admin-panel__queue-icon-size')} />
                            </div>
                            <div>
                                <NsTypography variant='inherit' className='font-size-18 d-block title-color'>
                                    {queue.sharedSheetOrderStateType
                                        ? ConstantsWithTranslation.getSharedSheetOrderStateType(queue.sharedSheetOrderStateType)
                                        : isEmpty(queue.serverNameFileRegulator)
                                            ? getTranslatedRawText('adminPanel.audit.notProcessed')
                                            : queue.serverNameFileRegulator
                                    }
                                </NsTypography>
                                <span className='font-size-24 fw-bold title-color'>
                                    {queue.count}
                                </span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-start gap-4'>
                            <div>
                                <NsTypography variant='inherit' className='light-text-color font-size-12 d-block'>
                                    {getTranslatedRawText('adminPanel.orders.firstOrder')}
                                </NsTypography>
                                <span className='font-size-14 title-color'>
                                    {DateHelper.getDateAndTime(queue.firstOrderDate)}
                                </span>
                            </div>
                            <div>
                                <NsTypography variant='inherit' className='light-text-color font-size-12 d-block'>
                                    {getTranslatedRawText('adminPanel.orders.lastOrder')}
                                </NsTypography>
                                <span className='font-size-14 title-color'>
                                    {DateHelper.getDateAndTime(queue.lastOrderDate)}
                                </span>
                            </div>
                        </div>
                    </div>
                </NsCard>
            </div>
        </Col>)
            : <div className='d-flex justify-content-center w-100'>
                <NsLabelNoData label='adminPanel.common.noAnyDataToView' />
            </div>
}

export default React.memo(UpBaseQueueOrdersItem);