import React from 'react';

import { Fab } from '@mui/material';

class NsFloatButton extends React.Component {

    render() {
        const { children, ...otherProps } = this.props;

        return (
            <Fab {...otherProps}>
                {children}
            </Fab>
        );
    }
}

export default NsFloatButton;