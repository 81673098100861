import Links from "../../configs/links/AllLinks";
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper";
import { GetRequest } from "../bases/BaseService";

const GetFestivalsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFestivals), parameter, callback);
};

const GetFestivalDetailService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFestivalById), id), {}, callback);
};

const GetFestivalPrizesService = (parameters, callback) => {
    const { id, ...params } = parameters
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFestivalPrizeById), id), params, callback);
};

const GetFestivalWinnersService = (parameters, callback) => {
    const { id, ...params } = parameters
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFestivalWinnerById), id), params, callback);
};

const GetFestivalPrizesWinnerService = (parameters, callback) => {
    const { id, actionId, ...params } = parameters
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApFestivalPrizesWinnerById), id, actionId), params, callback);
};

export {
    GetFestivalsService,
    GetFestivalDetailService,
    GetFestivalPrizesService,
    GetFestivalWinnersService,
    GetFestivalPrizesWinnerService
}