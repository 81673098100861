import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import NsTypography from '../../../components/text/NsTypography';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import NsTour from '../../../components/tour/NsTour';
import Config from '../../../configs/Config';
import NsRegionSelect from '../../../components/filterAreas/NsRegionSelect';
import ImageHelper from '../../../utilities/ImageHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import Enumerations from '../../../configs/Enumerations';
import { messageClass } from '../../../utilities/MessageModalHelper';
import { isEmpty } from '../../../utilities/ValidationHelper';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import NsEtiquette from '../../../components/etiquette/NsEtiquette';
import RegularOrderTours from '../../bases/tours/RegularOrderTours';
import NsAlertModal from '../../../components/modals/NsAlertModal';
class AddFilterModal extends React.Component {

    constructor(props) {
        super(props);
        this.tourSteps = RegularOrderTours.getAddFilterModalTour();
        this.minWidth = props.filterMinDimension?.minWidth ?? 0;
        this.minHeight = props.filterMinDimension?.minHeight ?? 0;
        this.maxRegion = Config.regularOrder;
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.chooseFilterModal);
        this.mainButtons = [
            CommonHelper.createStickyButtonItem("common.save", 'ns-icon-save', this.saveAddFilterModalClicked, 'primary'),
            CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', this.closeClicked, 'primary-outline')
        ];
        this.state = {
            isFront: props.isFront,
            imgSrc: props.file.draft,
            imgSrcWidth: props.file.width,
            imgSrcHeight: props.file.height,
            regions: props.selectedAreas || [],
            regionLength: !!props.selectedAreas ? props.selectedAreas?.length : 0,
            duplicatedFilterIndexs: [],
            isChangedFilters: false,
            isAlertModalOpen: false,
            resultMessageClass: (<></>)
        };
    }

    addClicked = () => {
        if (!CommonHelper.isMobileDevice() && this.state.regions.length < this.maxRegion.maxRegionsForDesktop
            || (CommonHelper.isMobileDevice() && this.state.regions.length < this.maxRegion.maxRegionsForMobile)) {
            let newArea = {
                data: { index: 2 },
                height: this.minHeight * 100 / this.state.imgSrcHeight,
                isChanging: false,
                new: false,
                width: this.minWidth * 100 / this.state.imgSrcWidth,
                x: (100 - this.minWidth * 100 / this.state.imgSrcWidth) / 2,
                y: (100 - this.minHeight * 100 / this.state.imgSrcHeight) / 2,
            }
            this.onChange([...this.state.regions, newArea]);
        }
    }

    clearClicked = () => {
        this.setState({ regions: [], duplicatedFilterIndexs: [], isChangedFilters: this.state.regionLength !== 0 });
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.chooseFilterModal);
    }

    saveAddFilterModalClicked = () => {
        const { regions, isFront } = this.state;

        const duplicatedFilterIndexs = this.getOverlappingFilters();
        const notOkDimensionRegionIndexes = this.getNotOkFiltersByDimension();

        if (!isEmpty(duplicatedFilterIndexs)) {
            const duplicatedFilterText = duplicatedFilterIndexs.map(item =>
                CommonHelper.stringFormat(getTranslatedRawText("common.messages.duplicatedFilter"), item[0], item[1]))
            this.setState({
                resultMessageClass: messageClass(CommonHelper.stringFormat(getTranslatedRawText("common.messages.duplicatedFilterText"), duplicatedFilterText.join('--')))
            });
        } else if (!isEmpty(notOkDimensionRegionIndexes)) {
            this.setState({
                resultMessageClass: messageClass(CommonHelper.stringFormat(
                    getTranslatedRawText("common.messages.smallFilterSelected"), notOkDimensionRegionIndexes.join(', ')))
            });
        }
        else {
            CommonHelper.safeFunctionCall(this.props.saveAddFilterModalClicked, regions, isFront);
            CommonHelper.safeFunctionCall(this.props.closeAddFilterModalClicked);
        }

    }
    getNotOkFiltersByDimension = () => {
        const { regions } = this.state;
        const notOkDimensionRegionIndexes = regions.map((region, index) => {
            let width = region?.bottomRightCornerX - region?.topLeftCornerX;
            let height = region?.bottomRightCornerY - region?.topLeftCornerY;
            return (((Math.floor(width) <= Math.floor(height)) && ((Math.floor(width) < this.minWidth) || (Math.floor(height) < this.minHeight))) ||
                ((Math.floor(width) >= Math.floor(height)) && ((Math.floor(width) < this.minHeight) || (Math.floor(height) < this.minWidth))))
                // (Math.floor(width) < this.minWidth) || (Math.floor(height) < this.minHeight))
                && index + 1
        })
            .filter(item => item);

        return notOkDimensionRegionIndexes;
    }
    getOverlappingFilters = () => {
        const { regions } = this.state;
        const duplicatedFilterIndexs = [];
        for (let i = 0; i < regions.length; i++) {
            for (let j = 0; (j < regions.length); j++) {
                if (regions[i].x >= regions[j].x &&
                    regions[i].x + regions[i].width <= regions[j].x + regions[j].width &&
                    regions[i].y >= regions[j].y &&
                    regions[i].y + regions[i].height <= regions[j].y + regions[j].height) {
                    (j !== i) && this.addToArray(duplicatedFilterIndexs, Math.min(i + 1, j + 1), Math.max(i + 1, j + 1))
                }
            }
        }
        this.setState({ duplicatedFilterIndexs, isChangedFilters: true });
        return duplicatedFilterIndexs;
    }
    addToArray = (items, i, j) => {
        const isExist = items.some(item => item[0] === i && item[1] === j);
        !isExist && items.push([i, j]);
    }

    closeClicked = () => {
        this.state.isChangedFilters
            ? this.setState({ isAlertModalOpen: true })
            : CommonHelper.safeFunctionCall(this.props.closeAddFilterModalClicked);
    }

    closeAlertClicked = (confirm) => {
        this.setState({ isAlertModalOpen: false });
        !!confirm && CommonHelper.safeFunctionCall(this.props.closeAddFilterModalClicked);
    }

    deleteFilter = (index) => {
        const newSelectedFilters = this.state.regions.filter((item, i) => i !== index);
        this.setState({ regions: newSelectedFilters }, this.getOverlappingFilters);
        this.showTour = !CurrentUserHelper.isTourSeen(Enumerations.tourGuide.chooseFilterModal);
    }
    
    onChange = (regions) => {
        if (regions)
            regions = ImageHelper.convertFiltersFromPercentageToMillimeter(this.state.imgSrcWidth, this.state.imgSrcHeight, regions, this.state.isFront)
        this.setState({ regions: regions },
            () => this.getOverlappingFilters());
    }

    moveSelection = (e) => {
        switch (e.keyCode) {
            case Enumerations.keyboard.leftArrow:
                this.handleArrowPressed(-1, 0);
                break;
            case Enumerations.keyboard.rightArrow:
                this.handleArrowPressed(1, 0);
                break;
            case Enumerations.keyboard.upArrow:
                this.handleArrowPressed(0, -1);
                break;
            case Enumerations.keyboard.downArrow:
                this.handleArrowPressed(0, 1);
                break;
        }
    }

    handleArrowPressed = (x, y) => {
        const regions = this.state.regions.map((item, index) => {
            if (index + 1 === this.state.selectedRegionIndex)
                return { ...item, x: item.x + (x * 0.2), y: item.y + (y * 0.2) }
            else
                return item
        });
        this.setState({ regions }, this.getOverlappingFilters);
    }

    componentDidMount = () => {
        window.addEventListener('keydown', this.moveSelection);
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.moveSelection);
    }

    handleSelectedRegion = (selectedRegionIndex) => {
        this.setState({ selectedRegionIndex });
    }

    render() {
        const { regions, imgSrc, duplicatedFilterIndexs, selectedRegionIndex, isLoading, isAlertModalOpen } = this.state;
        const { filterPrice, filterMinDimension } = this.props;

        return (
            <NsModal maxWidth="md"
                primaryActionButton={this.state.regions.length > 0 && this.saveAddFilterModalClicked}
                dialogTitle={getTranslatedRawText('regularOrder.modal.filterTitle')}
                showModal={this.props.showModal}
                closeModal={this.closeClicked}
                actions={<NsStickyButton stickyButtons={this.mainButtons} isLoading={isLoading} isModal={true} />}>
                <Row className='modal-add-filter g-0'>
                    <Col md={8}>
                        <NsRegionSelect
                            imgSrc={imgSrc}
                            regions={regions}
                            minDimension={filterMinDimension}
                            duplicatedFilterIndexs={duplicatedFilterIndexs}
                            onChange={this.onChange}
                            selectedRegionIndex={selectedRegionIndex}
                            handleSelectedRegion={this.handleSelectedRegion}
                            deleteFilter={this.deleteFilter} />
                    </Col>
                    <Col xs={{ span: 12, order: 'first' }} md={{ span: 4, order: 'last' }} className='text-center d-flex flex-column ps-4'>
                        <div className='d-flex justify-content-end mb-2'>
                            <NsButton label='regularOrder.common.add'
                                size='small'
                                className='secondary'
                                wrapperClassName='me-2'
                                startIcon="ns-icon-add"
                                onClick={this.addClicked} />

                            <NsButton label='regularOrder.common.clear'
                                size='small'
                                className='secondary-outline'
                                startIcon="ns-icon-delete"
                                onClick={this.clearClicked} />
                        </div>
                        {regions.length > 0 && <div className='d-flex justify-content-between my-2'>
                            <NsTypography variant='inherit' className='font-size-14'>
                                {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.filterQuantity'),
                                    regions.length)} &#215; <b>{CommonHelper.currencyFormatByDefaultConfig(filterPrice)}</b>
                            </NsTypography>
                            <NsTypography variant='inherit' className='font-size-14'>
                                {getTranslatedRawText('regularOrder.modal.total')}
                                <b>{CommonHelper.currencyFormatByDefaultConfig(filterPrice * regions.length)}</b>
                            </NsTypography>
                        </div>
                        }
                        <FilterList regions={regions}
                            duplicatedFilterIndexs={duplicatedFilterIndexs}
                            minWidth={this.minWidth}
                            minHeight={this.minHeight}
                            deleteFilter={(index) => this.deleteFilter(index)} />
                    </Col>
                    {this.state.resultMessageClass}
                </Row>
                {isAlertModalOpen &&
                    <NsAlertModal isOpen={isAlertModalOpen}
                        cancelClicked={() => this.closeAlertClicked(false)}
                        confirmClicked={() => this.closeAlertClicked(true)} >
                        {getTranslatedRawText('regularOrder.modal.closeServicesModalAlert')}
                    </NsAlertModal>}

                {(regions.length === 1 && !regions[0].isChanging && this.showTour) && <NsTour steps={this.tourSteps}
                    id={Enumerations.tourGuide.chooseFilterModal} />}
            </NsModal>
        );
    }
}

function FilterList(props) {
    let style, border, errorText = '';
    const filterList = props.regions.map((region, index) => {
        const width = Math.floor(region.bottomRightCornerX - region.topLeftCornerX);
        const height = Math.floor(region.bottomRightCornerY - region.topLeftCornerY);
        if ((width <= height) && ((width < props?.minWidth) || (height < props?.minHeight)) ||
            ((width >= height) && ((width < props?.minHeight) || (height < props?.minWidth))) ||
            props.duplicatedFilterIndexs.flat().includes(index + 1)) {

            errorText = CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.dimensionError'), props.minWidth, props.minHeight)
            style = 'background-red-color white-color';
            border = 'border-red-color-1px';
            if (props.duplicatedFilterIndexs.flat().includes(index + 1))
                errorText = getTranslatedRawText('regularOrder.modal.overlapError')
        }
        else {
            style = 'background-outline-color';
            border = 'border-outline-color-1px';
            errorText = ''
        }
        return (
            <NsEtiquette style={style}
                border={border}
                errorText={errorText}
                key={index}
                index={index}
                title={getTranslatedRawText('regularOrder.extendedService.filter')}
                deleteEtiquette={props.deleteFilter}>

                <div className='d-flex justify-content-between mx-3 my-2'>
                    <NsTypography variant='inherit' className='font-size-14'>
                        {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.filterWidth'), !!width ? width : 0)}
                    </NsTypography>
                    <NsTypography variant='inherit' className='font-size-14'>
                        {CommonHelper.stringFormat(getTranslatedRawText('regularOrder.modal.filterHeight'), !!height ? height : 0)}
                    </NsTypography>
                </div>
            </NsEtiquette>

        );
    });
    return filterList;
}

export default AddFilterModal