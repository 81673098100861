import React from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { currentUserActionCreators } from '../../../redux/reducers/CurrentUserReducer';
import { categoryActionCreators } from '../../../redux/reducers/CategoryReducer';
import { getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import NsResetTour from '../../../components/resetTour/NsResetTour';
import MainBreadcrumbs from '../main/breadcrumbs/MainBreadcrumbs';
import { isEmpty } from '../../../utilities/ValidationHelper';
import DateHelper from '../../../utilities/DateHelper';
import UrlHelper from '../../../utilities/UrlHelper';
import Links from '../../../configs/links/AllLinks';

class BusinessPartnerLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refreshDate: null,
        }
    }

    componentDidMount = () => {
        isEmpty(this.props.categories) && this.props.getCategories();
        const params = UrlHelper.getRouteParameters(window.location.search);
        if (!!params.token)
            this.props.setCurrentUser({
                userInfo: {
                    expirationDate: DateHelper.getFistDayOfNextMonth().toISOString(),
                    businessPartnerCustomerIdentifier: params?.businessPartnerCustomerIdentifier,
                    firstName: params?.token,
                    lastName: params?.token,
                    token: params?.token
                }
            });
        else {
            if (!CurrentUserHelper.isAuthenticated())
                return (<Redirect to={getUrlWithCurrentCulture(Links.view.main, Links.view.main.allProducts)} />);
            // return (<Redirect to={getUrlWithCurrentCulture(Links, Links.exception)} />);

        }
    }

    breadcrumbClicked = (link) => window.location.href = link;

    render() {
        return (
            <div key={this.state.refreshDate} className='background-color' aria-label='promotions'>
                <Container className='my-2 mx-auto'>
                    <div className="d-none d-lg-flex">
                        <MainBreadcrumbs
                            onClick={this.breadcrumbClicked}
                            breadCrumb={this.props.breadCrumb}
                            title={this.props.title} />
                        {!!this.props.showResetTour &&
                            <NsResetTour refreshDate={(refreshDate) => this.setState({ refreshDate })}
                                breadCrumb={this.props.breadCrumb} />
                        }
                    </div>
                    <div>
                        {this.props.children}
                    </div>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userFullName: state.currentUser.userFullName,
        categories: state.categories.categories,
    };
}

const mapDispachToProps = (dispatch) => {
    return bindActionCreators({ ...categoryActionCreators, ...currentUserActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(BusinessPartnerLayout);
