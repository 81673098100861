import { Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { GetCurrentCashbackInfoService } from "../../../../services/ProfileSetting/UserDashboardService";
import NsCountDownTimerLabel from "../../../../components/labels/NsCountDownTimerLabel";
import { getTranslatedRawText, isEnCulture } from "../../../../utilities/CultureHelper";
import NsSkeleton from "../../../../components/skeletons/NsSkeleton";
import NsTypography from "../../../../components/text/NsTypography";
import CommonHelper from "../../../../utilities/CommonHelper";
import Enumerations from "../../../../configs/Enumerations";
import DateHelper from "../../../../utilities/DateHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import NsButton from "../../../../components/buttons/NsButton";
import NsTooltip from "../../../../components/tooltips/NsTooltip";

const ApDcbTotalGadget = (props) => {
    const { cashback, isLoading, fromDate, toDate } = props;
    const [currentCashbackInfo, setCurrentCashbackInfo] = useState(null);
    const [isTooltipOpen, setIsTooltipOpen] = useState();
    const [resultMessageClass, setResultMessageClass] = useState(null);

    useEffect(() => {
        GetCurrentCashbackInfoService(getCurrentCashbackInfoCallback);
    }, [])

    const getCurrentCashbackInfoCallback = (resultData, result, resultMessageClass) => {
        if (!result.data.hasError) setCurrentCashbackInfo(resultData);
        else setResultMessageClass(resultMessageClass);
    }

    const toggleTooltipClicked = () => setIsTooltipOpen(!isTooltipOpen);

    const closeTooltipClicked = () => setIsTooltipOpen(false);

    return (isLoading
        ? <NsSkeleton isVisible={true}
            className='w-100'
            variant={
                [Enumerations.skeleton.rect,
                Enumerations.skeleton.rect,
                Enumerations.skeleton.rect]
            } />
        : <>
            <Col md={3} >
                <div className="border rounded-3 background-white-color py-3 px-4 h-100">
                    <NsTypography>
                        {getTranslatedRawText('adminPanel.cashback.totalSalesAmount')}&nbsp;
                        <span className="font-size-12">{getTranslatedRawText('adminPanel.cashback.eligibleForCashback')}</span>
                    </NsTypography>
                    <NsTypography className='mt-4'>{CommonHelper.currencyFormatByDefaultConfig(cashback?.sumNetSale)}</NsTypography>
                </div>
            </Col>
            <Col md={3} >
                <div className="border rounded-3 background-white-color py-3 px-4 h-100">
                    <NsTypography>{getTranslatedRawText('adminPanel.cashback.totalCashbackAmount')}</NsTypography>
                    <NsTypography className='mt-4'>{CommonHelper.currencyFormatByDefaultConfig(cashback?.sumCurrentDiscountAmount)}</NsTypography>
                </div>
            </Col>
            <Col md={3} >
                <div className="border rounded-3 background-white-color py-3 px-4 h-100">
                    <NsTypography>{getTranslatedRawText('adminPanel.cashback.qtyOfCustomersIncludedInCashback')}</NsTypography>
                    <span className='font-size-12'>{getTranslatedRawText('adminPanel.cashback.reachedAtLeastTheFirstCashbackPlan')}</span>
                    <NsTypography className='mt-2'>{cashback?.countAchievedUserToDiscount}</NsTypography>
                </div>
            </Col>
            <Col md={3}>
                <div md={3} className='d-flex flex-column border-black rounded-3 align-content-center background-element-color py-2 px-5 h-100'>
                    {!!isEmpty(toDate)
                        ? <>
                            {currentCashbackInfo &&
                                <NsCountDownTimerLabel currentCashbackInfo={currentCashbackInfo}
                                    showTooltipHelp={!toDate} />
                            }
                            <NsTypography variant='inherit'
                                className='d-flex mt-2 justify-content-center'>
                                {DateHelper.getDatePart(currentCashbackInfo?.firstDay)}
                                &nbsp;<span>{getTranslatedRawText('components.history.to')}</span>&nbsp;
                                {DateHelper.getDatePart(currentCashbackInfo?.lastDay)}
                            </NsTypography>
                        </>

                        : <NsTypography variant='inherit'
                            className='d-flex mt-2 gap-2 justify-content-center font-size-24'>
                                {!!toDate &&
                                    <NsTooltip open={isTooltipOpen}
                                        title={CommonHelper.stringFormat(getTranslatedRawText('common.messages.cashbackRemaningTimeMessage'), DateHelper.getDateAndTime(toDate))}
                                        closeTooltip={closeTooltipClicked}>
                                        <NsButton className='secondary-text p-0 d-inline'
                                            startIcon='ns-icon-question'
                                            size='small'
                                            onClick={toggleTooltipClicked} />
                                    </NsTooltip>
                                }
                            <div>
                                {getTranslatedRawText('components.history.from')}&nbsp;{DateHelper.getDateAndTime(fromDate)}
                                <br /><span>{getTranslatedRawText('components.history.to')}</span>&nbsp;
                                {DateHelper.getDateAndTime(toDate)}
                            </div>
                        </NsTypography>
                    }
                </div>
            </Col>
            {resultMessageClass}
        </>
    );
}

export default React.memo(ApDcbTotalGadget);