import React, { useState } from 'react';

import { GetLatestSubmittedAgenciesService } from '../../../../services/adminPanel/AdminDashboardService';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText, isEnCulture } from '../../../../utilities/CultureHelper';
import AgencyInfoModal from '../../../profileSettings/modals/AgencyInfoModal';
import NsCard from '../../../../components/cards/NsCard';
import Enumerations from '../../../../configs/Enumerations';

const ApDaNewSubmitted = (props) => {

    const { refreshDate, parameters } = props;
    const [selectedAgency, setSelectedAgency] = useState();
    const [showAgencyModal, setShowAgencyModal] = useState(false);

    const columns = [
        {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            cellRenderer: "customRowRenderer",
            width: 80,
            cellRendererParams: {
                hasDetail: true,
                detailClicked: (id) => agencyDetailClicked(id),
            }
        },
        {
            field: 'userName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.fullName'),
            width: 200,

        },
        {
            field: 'mobile',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.mobile'),
            width: 240,
            dataType: Enumerations.gridType.number,
        },
        {
            field: 'agencyName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.agencyName'),
            width: 200
        },
    ];

    const agencyDetailClicked = (id) => {
        setSelectedAgency(id);
        setShowAgencyModal(true);
    }

    return (
        <NsCard className='p-3 h-100'>
            <div className='fw-bold mb-4'>{getTranslatedRawText('adminPanel.dashboard.newSubmitted')}</div>

            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={GetLatestSubmittedAgenciesService}
                parameters={parameters}
                columnDefs={columns}
                hasPagination={true} />

            {showAgencyModal &&
                <AgencyInfoModal agencyId={selectedAgency}
                    isInAdminPanel={true}
                    closeModal={() => setShowAgencyModal(false)} />}
        </NsCard>
    );
}

export default React.memo(ApDaNewSubmitted);