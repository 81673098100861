import clsx from 'clsx';
import React from 'react';
import { MenuItem, Select } from '@mui/material';

import '../../assets/sass/components/inputs/NsSelectMenu.scss';
import CommonHelper from '../../utilities/CommonHelper';

class NsSelectMenu extends React.Component {

    onChangeHandle = (event) => {
        CommonHelper.safeFunctionCall(this.props.valueChanged, event.target.value);
    }

    mapSelectItems = () => {
        return this.props.options?.map(option =>
            <MenuItem key={option.id} value={option.id} className={clsx('ns-select-menu-items', this.props.menuItemClassName, option.itemClassName)} >
                {option.name || option.value || option.label}
            </MenuItem >)
    }
    
    render() {
        const { className, label, disabled, selectedItem, menuItemClassName } = this.props;

        return (
            <Select className={clsx('ns-select-menu', className)}
                disabled={disabled}
                variant='standard'
                onChange={this.onChangeHandle}
                inputProps={{ 'aria-label': 'Without label' }}
                value={selectedItem}>

                {!!label &&
                    <MenuItem key={0} value={0} className={menuItemClassName}>
                        {label}
                    </MenuItem>
                }
                {this.mapSelectItems()}
            </Select >
        );
    }
}

export default NsSelectMenu;