import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NsMessageClassModal from '../../../components/modals/NsMessageClassModal';
import Enumerations from '../../../configs/Enumerations';
import { currentUserActionCreators } from '../../../redux/reducers/CurrentUserReducer';
import { miniCartActionCreators } from '../../../redux/reducers/MiniCartReducer';
import CommonHelper from '../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import SignInModal from '../../layouts/main/headers/modals/signIn/SigninModal';

class PdBaseLoginMessage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowSignInMessageModal: false,
            isShowSignInModal: false,
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.showSignInMessageModalDate !== prevProps.showSignInMessageModalDate)
            this.setState({ isShowSignInMessageModal: true });
    }

    showSignInModal = () => this.setState({ isShowSignInMessageModal: false, isShowSignInModal: true });

    signIn = (resultData) => {
        CommonHelper.safeFunctionCall(this.props.setCurrentUser, resultData);
        this.props.getTotalPriceAndCount();
    }

    closeSignInModal = () => this.setState({ isShowSignInModal: false });

    render() {
        const { isShowSignInModal, isShowSignInMessageModal } = this.state;
        const { message } = this.props;
        return (<>
            {isShowSignInModal &&
                <SignInModal showModal={true}
                    closeModal={this.closeSignInModal}
                    signIn={this.signIn} />
            }

            {isShowSignInMessageModal &&
                <NsMessageClassModal show={true}
                    onClose={this.showSignInModal}
                    messageItems={[{
                        messageText: message || getTranslatedRawText('common.messages.preMessageForSignIn'),
                        messageType: Enumerations.messageType.info
                    }]} />
            }

        </>);
    }
}

const mapDispachToProps = (dispatch) => {
    return bindActionCreators({ ...currentUserActionCreators, ...miniCartActionCreators }, dispatch);
}

export default connect(null, mapDispachToProps)(PdBaseLoginMessage);