import React from 'react';

import NsLoadingProgress from '../../../../components/circularProgress/NsLoadingProgress';
import NsModal from '../../../../components/modals/NsModal';
import { GetBillBookOptionsService } from '../../../../services/ProfileSetting/UserOrderService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import BillBookPreviewModalContent from '../../../orders/modals/billBookPreview/BillBookPreviewModalContent';

class BillBookModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            masterFile: null,
            billBookCopyType: null,
            billBookPrintColorType: null,
            billBookBindingSideType: null,
            billBookBindingType: null,
            hasUnderhand: null,
            serialBoxDimension: null,
            selectedSerials: [],
            serialStartFrom: null,
            selectedPerforations: [],
            isLoading: true,
            resultMessageClass: (<></>),
        }
    }

    componentDidMount = () => GetBillBookOptionsService(this.props.id, this.getBillBookOptionsCallback);

    getBillBookOptionsCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            const { draft, resolution } = this.props;
            this.setState({
                masterFile: { draft, resolution },
                billBookCopyType: resultData.billBookCopyType,
                billBookPrintColorType: resultData.billBookPrintColorType,
                billBookBindingSideType: resultData.billBookBindingSideType,
                billBookBindingType: resultData.billBookBindingType,
                hasUnderhand: resultData.hasUnderHand,
                serialBoxDimension: resultData.serialBoxDimension,
                selectedSerials: resultData.selectedSerials,
                serialStartFrom: resultData.serialStartFrom,
                selectedPerforations: resultData.selectedPerforations,
                isLoading: false
            });
        } else
            this.setState({ resultMessageClass: messageClassModal, isLoading: false });
    }

    render() {

        const { masterFile, billBookCopyType, billBookBindingType, billBookBindingSideType, billBookPrintColorType,
            hasUnderhand, serialBoxDimension, selectedPerforations, selectedSerials, resultMessageClass, isLoading, serialStartFrom } = this.state;
        return (
            <NsModal showModal={true}
                maxWidth='md'
                closeModal={() => CommonHelper.safeFunctionCall(this.props.closeModal)}
                dialogTitle={getTranslatedRawText("regularOrder.billBook.previewBillBook")} >

                <NsLoadingProgress visible={isLoading} />

                {isEmpty(masterFile)
                    ? <div>
                        {isLoading
                            ? getTranslatedRawText('fileManager.modals.rename.isLoading')
                            : getTranslatedRawText('components.nsDataGrid.noRowData')
                        }
                    </div>
                    : <BillBookPreviewModalContent masterFile={masterFile}
                        billBookCopy={{ billBookCopyType }}
                        billBookPrintColor={billBookPrintColorType}
                        billBookBindingSideType={billBookBindingSideType}
                        billBookBindingType={billBookBindingType}
                        hasUnderhand={hasUnderhand}
                        trimZone={null}
                        serialStartFrom={serialStartFrom}
                        serialBoxDimension={serialBoxDimension}
                        selectedSerials={selectedSerials}
                        selectedPerforations={selectedPerforations} />
                }
                {resultMessageClass}

            </NsModal >
        )
    }
}

export default BillBookModal;