import React, { useState, useEffect, useRef } from "react";

import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import NsAdminPanelContentWrapper from '../../../../../components/cards/NsAdminPanelContentWrapper';
import { GetAuditsService, UpdateAuditMatchingTypesService } from "../../../../../services/adminPanel/AdminPaymentService";
import UserClaimEnumerations from "../../../../../configs/UserClaimEnumerations";
import { getTranslatedRawText } from "../../../../../utilities/CultureHelper";
import ApPaymentAuditImportFileModal from "./ApPaymentAuditImportFileModal";
import { hasAccess } from "../../../../../utilities/ClaimHelper";
import ApPaymentAuditSearch from "./ApPaymentAuditSearch";
import { getApAuditColumns } from "../ApPaymentHelper";
import NsTab from "../../../../../components/tabs/NsTab";
import ConstantsWithTranslation from "../../../../../configs/ConstantsWithTranslation";
import Enumerations from "../../../../../configs/Enumerations";
import ApPaymentAuditImportedFileDetailModal from "./ApPaymentAuditImportedFileDetailModal";
import EnumerationTranslationHelper from "../../../../../utilities/EnumerationTranslationHelper";
import ApPaymentAuditDetailModal from "./ApPaymentAuditDetailModal";
import NsMessageClassModal from "../../../../../components/modals/NsMessageClassModal";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import NsButton from "../../../../../components/buttons/NsButton";
import NsTooltip from "../../../../../components/tooltips/NsTooltip";
import CommonHelper from "../../../../../utilities/CommonHelper";

const ApPaymentAudit = (props) => {

    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [searchData, setSearchData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showAuditDetailModal, setShowAuditDetailModal] = useState(false);
    const [showImportFileModal, setShowImportFileModal] = useState(false);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [isOpenTooltip, setIsOpenTooltip] = useState(false);
    const [selectedTabId, setSelectedTabId] = useState(Enumerations.editorMode.basic);
    const [resultMessageClass, setResultMessageClass] = useState(<></>);
    const [showUpdateMatchingStatusModal, setShowUpdateMatchingStatusModal] = useState(false);
    const [disableUpdateButton, setDisableUpdateButton] = useState(true);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData, selectedTabId]);


    const getData = (rows, isLoading, totalRows) => {
        setIsLoading(isLoading);
        setRows(rows);
        setTotalRows(totalRows);
        setDisableUpdateButton(false);
    }

    const getSearchData = (data) => {
        setSearchData(data);
    }

    const getParameters = () => {
        let parameters = {
            "Filter.ShowInvalidRrn": selectedTabId === Enumerations.editorMode.advance,
            "Filter.PaymentGatewayTypes": props.searchData?.paymentGateway?.key || searchData?.paymentGateway?.key,
            "Filter.PaymentGateWayReferenceNumber": props.searchData?.referenceNo || searchData?.referenceNo,
            "Filter.TrackNumber": props.searchData?.trackingNo || searchData?.trackingNo,
            "Filter.FromSubmitTime": props.searchData?.fileDatePaidFrom || searchData?.fileDatePaidFrom,
            "Filter.ToSubmitTime": props.searchData?.fileDatePaidTo || searchData?.fileDatePaidTo,
            "Filter.FileTransactionStatus": props.searchData?.fileTransactionStatus?.id || searchData?.fileTransactionStatus?.id,
            "Filter.WebsiteTransactionStatus": props.searchData?.websiteTransactionStatus?.id || searchData?.websiteTransactionStatus?.id,
            "Filter.MinAmount": props.searchData?.minAmount || searchData?.minAmount,
            "Filter.MaxAmount": props.searchData?.maxAmount || searchData?.maxAmount,
            "Filter.UserId": props.searchData?.selectedUser?.userId || searchData?.selectedUser?.userId,
            "Filter.ChangeStateByAdminId": props.searchData?.selectedAdmin?.userId || searchData?.selectedAdmin?.userId,
            "Filter.TransactionStatusMessage": CommonHelper.stringSpacesToHyphen(props.searchData?.transactionStatusMessage || searchData?.transactionStatusMessaged),
        };
        if (selectedTabId === Enumerations.editorMode.basic) {
            !!searchData?.matchingStatus?.id
                ? parameters["Filter.PaymentAuditMatchingTypes"] = searchData?.matchingStatus?.id
                : ConstantsWithTranslation.getPaymentAuditMatchingTypes().map((item, index) => {

                    if (item.id !== Enumerations.paymentAuditMatchingType.alternativePaymentAttempt)
                        parameters["Filter.PaymentAuditMatchingTypes[" + index + "]"] = item.id;
                })

        } else
            parameters["Filter.PaymentAuditMatchingTypes"] = searchData?.matchingStatus?.id;

        return parameters;
    }

    const tabChanged = (selectedTabIdIndex, selectedTabId) => setSelectedTabId(selectedTabId.id);

    const auditDetailClicked = (row) => {
        setSelectedAudit(row);
        setShowAuditDetailModal(true);
    }

    const closeApAuditAttemptsModal = () => setShowAuditDetailModal(false);

    const importFileModalClicked = (id) => setShowImportFileModal(true);

    const closeImportFileModalModal = () => {
        setShowImportFileModal(false);
        setCallServiceDate(new Date());
    }

    const matchingTypeIds = () => {
        let ids = [];
        ConstantsWithTranslation.getPaymentAuditMatchingTypes().map(item => ids.push(item.id));

        return ids;
    }

    const updateMatchingStatusTypesClicked = () => setShowUpdateMatchingStatusModal(true);

    const updateMatchingStatusTypesConfirmationStatus = (confirm) => {
        setShowUpdateMatchingStatusModal(false);

        if (confirm) {
            let parameters = {
                "paymentAuditMatchingTypes": searchData?.matchingStatus?.id ? [searchData?.matchingStatus?.id] : matchingTypeIds(),
                "paymentGatewayType": searchData?.paymentGateway?.key,
                "paymentGatewayReferenceNumber": searchData?.referenceNo,
                "trackNumber": searchData?.trackingNo,
                "fromSubmitTime": searchData?.fileDatePaidFrom,
                "toSubmitTime": searchData?.fileDatePaidTo,
                "fileTransactionStatus": searchData?.fileTransactionStatus?.id,
                "websiteTransactionStatus": searchData?.websiteTransactionStatus?.id
            }
            setIsLoading(true);
            UpdateAuditMatchingTypesService(parameters, updateMatchingStatusTypesCallback);
        }
    };

    const updateMatchingStatusTypesCallback = (resultData, result, resultMessageClass) => {
        setIsLoading(false);
        setResultMessageClass(resultMessageClass)
    }

    const tooltipClicked = () => setIsOpenTooltip(!isOpenTooltip);

    const isAdvanceMode = selectedTabId === Enumerations.editorMode.advance;

    const columns = getApAuditColumns(auditDetailClicked, isAdvanceMode);

    const paymentGatewayType = {
        key: selectedAudit?.paymentGatewayType,
        value: EnumerationTranslationHelper.getPaymentGatewayType(selectedAudit?.paymentGatewayType)
    }

    const disableUpdateMatchingStatusButton = () => setDisableUpdateButton(true);

    const { isUsedInModal } = props;

    return <NsAdminPanelContentWrapper header={!isUsedInModal && getTranslatedRawText("adminPanel.sidebar.audit")}
        actionButton={!isUsedInModal ? {
            onClick: importFileModalClicked,
            isLoading: isLoading,
            disabled: !hasAccess([UserClaimEnumerations.postApPaymentAuditSources]),
            label: getTranslatedRawText("adminPanel.download.uploadFile")
        } : {}}
        icon={!isUsedInModal && 'ns-icon-audit h1'}>

        <ApPaymentAuditSearch setSearchData={getSearchData}
            disableUpdateMatchingStatusButton={disableUpdateMatchingStatusButton}
            isLoading={isLoading} />

        {isAdvanceMode && hasAccess([UserClaimEnumerations.updateApPaymentAuditsMatchingTypes]) &&
            <div className="d-flex justify-content-end">
                <NsTooltip title={getTranslatedRawText('adminPanel.audit.updateRecordsMatchingStatusTooltip')}
                    open={isOpenTooltip}>
                    <NsButton startIcon='ns-icon-question'
                        className='secondary-text'
                        onClick={tooltipClicked} />
                </NsTooltip>
                <NsButton onClick={updateMatchingStatusTypesClicked}
                    startIcon='ns-icon-retry'
                    className='secondary-outline'
                    label={CommonHelper.stringFormat(getTranslatedRawText('adminPanel.audit.updateRecordsMatchingStatus'), totalRows)}
                    wrapperClassName='ms-1'
                    disabled={isEmpty(rows) || !searchData?.paymentGateway?.key || disableUpdateButton}
                    isLoading={isLoading} />
            </div>
        }

        <NsTab tabs={ConstantsWithTranslation.getAuditContentTabs()}
            onChange={tabChanged}
            value={selectedTabId - 1} />

        <NsDataGridWithServiceCall callServiceDate={callServiceDate}
            service={GetAuditsService}
            parameters={getParameters()}
            columnDefs={columns}
            hasPagination={true}
            getData={getData} />

        {showAuditDetailModal
            ? isAdvanceMode
                ? <ApPaymentAuditDetailModal selectedAuditId={selectedAudit?.id}
                    isAdvanceMode={isAdvanceMode}
                    paymentGatewayType={paymentGatewayType}
                    closeModal={closeApAuditAttemptsModal} />
                : <ApPaymentAuditImportedFileDetailModal fileSourceId={selectedAudit?.paymentAuditSourceId}
                    auditId={selectedAudit?.id}
                    paymentGatewayType={paymentGatewayType}
                    closeModal={closeApAuditAttemptsModal} />
            : null
        }

        {showUpdateMatchingStatusModal &&
            <NsMessageClassModal show={true}
                onClose={updateMatchingStatusTypesConfirmationStatus}
                messageItems={[
                    {
                        messageText: CommonHelper.stringFormat(getTranslatedRawText('common.messages.updateMatchingStatusQuestionMessage'), totalRows),
                        messageType: Enumerations.messageType.question
                    }]} />
        }

        {showImportFileModal &&
            <ApPaymentAuditImportFileModal closeModal={closeImportFileModalModal} />
        }

        {resultMessageClass}
    </NsAdminPanelContentWrapper >
}

export default React.memo(ApPaymentAudit);