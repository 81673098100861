import React from 'react';

import { GetApDfbByCitiesGridService } from '../../../../../services/adminPanel/AdminDashboardService';
import NsDataGridWithServiceCall from '../../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import Enumerations from '../../../../../configs/Enumerations';
import NsCard from '../../../../../components/cards/NsCard';

const ApBasesDfbCitiesGrid = ({ refreshDate, parameters }) => {

    const columns = [
        {
            field: 'name',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.city'),
            width: 130
        },
        {
            field: 'averageRate',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.avgRate'),
            dataType: Enumerations.gridType.number,
            sortable: true,
            width: 150
        },
        {
            field: 'quantity',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.qty'),
            dataType: Enumerations.gridType.number,
            sortable: true,
            width: 100
        }
    ];

    return (
        <NsCard className="p-3 h-100">
            <div className='page-title mb-2'>{getTranslatedRawText('adminPanel.dataGridColumn.city')}</div>

            <NsDataGridWithServiceCall callServiceDate={refreshDate}
                service={GetApDfbByCitiesGridService}
                parameters={parameters}
                hasPagination={true}
                columnDefs={columns} />

        </NsCard >
    )
}

export default React.memo(ApBasesDfbCitiesGrid);