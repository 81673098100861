import React, { useState, useEffect } from "react";

import NsCircularProgressWithLabel from "../../../../components/circularProgress/NsCircularProgressWithLabel";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import NsSkeleton from "../../../../components/skeletons/NsSkeleton";
import CommonHelper from "../../../../utilities/CommonHelper";
import Enumerations from "../../../../configs/Enumerations";
import NsCard from "../../../../components/cards/NsCard";

const ApDauPercentageBox = (props) => {
    const { title, subTitle, progressColor, isLoading, data } = props;

    return (
        <NsCard className='d-flex p-3 h-100' contentClassName='w-100'>
            <div className="d-flex justify-content-start align-items-center gap-3 h-100">
                <NsCircularProgressWithLabel value={data?.percentage}
                    isLoading={isLoading}
                    thickness={6}
                    progressSize='admin-panel__audit-dashboard--circular-progress-size'
                    progressColor={progressColor} />
                <div className="d-flex flex-column w-100">
                    {isLoading
                        ? <NsSkeleton isVisible={true}
                            variant={[
                                Enumerations.skeleton.text,
                                Enumerations.skeleton.text,
                            ]} />
                        : <>
                            <span className='page-title mb-2'>{getTranslatedRawText(title)}</span>
                            <span className='fw-bold mb-2'>{CommonHelper.currencyFormat(data?.count || 0, true)}</span>
                            <span className="font-size-12" >
                                {CommonHelper.stringFormat(getTranslatedRawText(subTitle), CommonHelper.currencyFormat(data?.detailCount || 0, true))}
                            </span>
                        </>
                    }
                </div>
            </div>
        </NsCard >
    );
};

export default React.memo(ApDauPercentageBox);