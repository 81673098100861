import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { DeleteOrderService, GetMyOrderCartItemsService } from '../../../../../services/ProfileSetting/UserOrderService';
import { DeleteFromCartStoreProductService, GetStoreProductCartService } from '../../../../../services/ProfileSetting/StoreOrderService';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../../utilities/CultureHelper';
import UpGroupSheetCartOrderDetailModal from '../groupSheets/carts/UpGroupSheetCartOrderDetailModal';
import SuccessPaymentStoreOrderCartModal from '../../../modals/SuccessPaymentStoreOrderCartModal';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import ConstantsWithTranslation from '../../../../../configs/ConstantsWithTranslation';
import { miniCartActionCreators } from '../../../../../redux/reducers/MiniCartReducer';
import OrderCartConfirmationModal from '../../../modals/OrderCartConfirmationModal';
import UpGroupSheetCartOrders from '../groupSheets/carts/UpGroupSheetCartOrders';
import NsStickyButton from '../../../../../components/buttons/NsStickyButton';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import UpStoreCartOrders from '../stores/carts/UpStoreCartOrders';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import Links from '../../../../../configs/links/AllLinks';
import NsTab from '../../../../../components/tabs/NsTab';
import Config from '../../../../../configs/Config';

class UpCartOrderContent extends React.Component {

    constructor(props) {
        super(props);
        this.memoizeCreateStickyItem = CommonHelper.memoizeFunc(CommonHelper.createStickyButtonItem);
        this.tabs = ConstantsWithTranslation.getCartOrderTabs();
        this.selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);
        this.state = {
            totalRows: 0,
            currentPageIndex: 1,
            rowPerPage: CommonHelper.defaultRowPerPage(2),
            tabs: this.tabs,
            selectedTab: this.props.selectedTab || this.selectedTab || this.tabs[0],
            selectedTabIndex: this.props.selectedTabIndex || this.tabs.indexOf(this.selectedTab) > -1 ? this.tabs.indexOf(this.selectedTab) : 0,
            refreshUserAccountDate: '',
            showConfirmOrderModal: false,
            showDeleteOrderMessageModal: false,
            selectedRowIds: [],
            selectedDeleteRowId: null,
            selectedDeleteRowProductName: '',
            cartItems: [],
            showOrderDetailModal: false,
            orderId: null,
            isExpressMode: window.history.state?.state?.isExpressMode,
            isLoading: true,
            file: null,
            resultMessageClass: (<></>),
        };
    }

    componentDidMount = () => this.getOrders();

    componentDidUpdate = () => {
        if (this.state.isExpressMode !== window.history.state?.state?.isExpressMode && !!window.history.state?.state?.isExpressMode) {
            let selectedTab = this.tabs.find(tab => tab.url == this.props.location?.pathname);
            this.tabChanged(this.tabs.indexOf(selectedTab) > -1 ? this.tabs.indexOf(selectedTab) : 0,
                selectedTab, window.history.state?.state?.isExpressMode)
        }
    }

    getOrders = () => this.setState({ isLoading: true, cartItems: [] },
        () => {
            let parameters = {
                currentPageIndex: this.state.currentPageIndex,
                pageLength: this.state.rowPerPage.value,
            }
            this.state.selectedTab.id === Enumerations.orderProductType.sharedSheet
                ? GetMyOrderCartItemsService(parameters, this.getCartItemsCallback)
                : GetStoreProductCartService(parameters, this.getCartItemsCallback);
        });

    getCartItemsCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            let updatedTabs = this.state.tabs;
            updatedTabs[0].badgeCount = this.props.miniCartInfo?.sharedSheetOrderCount
            updatedTabs[1].badgeCount = this.props.miniCartInfo?.storeProductOrderCount
            this.setState({
                cartItems: resultData.dataItems,
                totalRows: resultData.totalCount,
                tabs: updatedTabs,
                selectedRowIds: this.state.isExpressMode
                    ? [...resultData.dataItems.map(order => CommonHelper.toInt(order.id))]
                    : []
            }, () => this.state.isExpressMode && this.setState({ showConfirmOrderModal: true }));
        } else
            this.setState({ resultMessageClass: messageClassModal });
    }

    confirmClicked = () => this.setState({ showConfirmOrderModal: true });

    confirmInModalClicked = (id) => this.setState({ showConfirmOrderModal: true, selectedRowIds: [id] });

    closeConfirmOrderModal = (refresh, messageClassModal, isStore) => {
        this.setState({
            showConfirmOrderModal: false,
            isExpressMode: false,
            showSuccessPaymentStoreOrderCartModal: refresh && isStore,
            resultMessageClass: isStore ? '' : messageClassModal
        });
        if (!!refresh) {
            this.getOrders();
            this.closeDetailModalClicked();
        }
    }

    closeSuccessPaymentStoreOrderCartModal = (goToUserOrder) => this.setState({ showSuccessPaymentStoreOrderCartModal: false });

    detailViewClicked = (id) => {
        this.setState({ showOrderDetailModal: true, orderId: id });
        CommonHelper.safeFunctionCall(this.props.changeRouteToCartOrderDetail, id);
    }

    closeDetailModalClicked = () => {
        this.setState({ showOrderDetailModal: false });
        CommonHelper.safeFunctionCall(this.props.changeRouteToCartOrder);
    }

    deleteRowClicked = (selectedRowIds) => {
        let selectedDeleteRowProductName = this.state.cartItems.find(item => item.id === CommonHelper.toInt(selectedRowIds))?.productName;
        this.setState({ selectedDeleteRowId: selectedRowIds, selectedDeleteRowProductName, showDeleteOrderMessageModal: true });
    }

    closeDeleteOrderMessageModal = (confirm) => {
        this.setState({ showDeleteOrderMessageModal: false },
            () => {
                !!confirm && this.getOrders();
                this.closeDetailModalClicked();
            });
    }

    deleteOrder = (confirm) => {
        !!confirm && this.isLoadingChanged(true);
        if (!!confirm) {
            this.state.selectedTab.id === Enumerations.orderProductType.sharedSheet
                ? DeleteOrderService(!!this.state.selectedDeleteRowId
                    ? [this.state.selectedDeleteRowId]
                    : this.state.selectedRowIds
                    , this.deleteOrderCallback, true)
                : DeleteFromCartStoreProductService({
                    cartIds: !!this.state.selectedDeleteRowId
                        ? [this.state.selectedDeleteRowId]
                        : this.state.selectedRowIds
                }
                    , this.deleteOrderCallback, true);

        } else {
            this.closeDeleteOrderMessageModal(false);
        }
    }

    deleteOrderCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            this.props.getTotalPriceAndCount();
            this.closeDeleteOrderMessageModal(true);
        }
        this.setState({ resultMessageClass: messageClassModal });
        this.isLoadingChanged(false);
        this.closeDeleteOrderMessageModal(false);
    }

    dataGridSelectionChanged = (selection) => {
        selection.rowIds = selection.rowIds.map(id => CommonHelper.toInt(id));
        this.setState({ selectedRowIds: [...selection.rowIds] });
    }

    setMessageItems = () => {
        return [
            {
                messageText: <>{!isEmpty(this.state.selectedDeleteRowProductName)
                    ? CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestionOrder'), this.state.selectedDeleteRowProductName)
                    : this.state.selectedRowIds.length > 1 ?
                        CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestionOrderWithLength'), this.state.selectedRowIds.length)
                        : getTranslatedRawText('common.messages.areYouSureQuestionSingleOrderWithoutParam')
                }</>,
                messageType: Enumerations.messageType.question
            }];
    }

    refreshUserAccount = () => CommonHelper.safeFunctionCall(this.props.refreshUserAccount);

    isLoadingChanged = (isLoading) => this.setState({ showDeleteOrderMessageModal: isLoading, isLoading: isLoading });

    tabChanged = (newValue, selectedTab) => {
        this.props.getTotalPriceAndCount();
        newValue !== this.state.selectedTabIndex && this.setState({
            selectedTabIndex: newValue,
            selectedTab,
            rows: [],
            isExpressMode: false,
            selectedRowIds: []
        }, this.getOrders);
        this.changeRoute(selectedTab?.id)
    }

    changeRoute = (selectedTabId) => {
        selectedTabId === Enumerations.orderProductType.sharedSheet
            ? this.props.history.push(getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.cartOrder))
            : this.props.history.push(getUrlWithCurrentCulture(Links.view.main.storeOrder, Links.view.main.storeOrder.cartOrder));
    }

    pageChanged = (currentPage) => this.setState({ currentPageIndex: currentPage }, this.getOrders);

    handleRowPerPage = (rowPerPage) => this.setState({ rowPerPage, currentPage: 1 }, this.getOrders);

    createMainButtons = () => [
        this.memoizeCreateStickyItem("profile.orderCart.checkoutAndPay", 'ns-icon-confirm', this.confirmClicked, 'primary', !this.state.selectedRowIds.length),
        this.memoizeCreateStickyItem("common.delete", 'ns-icon-delete', () => this.deleteRowClicked(null), 'secondary', !this.state.selectedRowIds.length),
    ];

    refreshStoreProductGrid = () => {
        this.setState({ isLoading: true, cartItems: [] })

        let parameters = {
            currentPageIndex: this.state.currentPageIndex,
            pageLength: this.state.rowPerPage.value,
        }
        GetStoreProductCartService(parameters, this.getCartItemsCallback);
    };

    render() {
        const { columns, tabs, isLoading, cartItems, showOrderDetailModal, showConfirmOrderModal, showDeleteOrderMessageModal, showSuccessPaymentStoreOrderCartModal,
            resultMessageClass, selectedTabIndex, selectedTab, orderId, selectedRowIds, currentPageIndex, totalRows, rowPerPage } = this.state;

        const gridProps = {
            rows: cartItems,
            columnDefs: columns,
            currentPage: currentPageIndex,
            totalRows,
            rowPerPage,
            isLoading,
            selectedRowIds,
            detailViewClicked: this.detailViewClicked,
            deleteRowClicked: this.deleteRowClicked,
            handlePagination: this.pageChanged,
            handleRowPerPage: this.handleRowPerPage,
            selectedRows: this.dataGridSelectionChanged,
        }

        return (
            <>
                <div className="w-100">

                    <NsStickyButton stickyButtons={this.createMainButtons()} isLoading={isLoading} />
                    {Config.hasStoreProducts && <div className='w-100 mt-3'>
                        <NsTab value={selectedTabIndex}
                            tabs={tabs}
                            onChange={this.tabChanged} />
                    </div>}
                    <div>
                        {selectedTab.id === Enumerations.orderProductType.sharedSheet
                            ? <UpGroupSheetCartOrders {...gridProps} />
                            : <UpStoreCartOrders {...gridProps}
                                isModal={this.props.isModal}
                                refreshStoreProductGrid={this.refreshStoreProductGrid} />
                        }
                    </div>
                </div>

                {showOrderDetailModal &&
                    <UpGroupSheetCartOrderDetailModal closeModal={this.closeDetailModalClicked}
                        confirmClicked={this.confirmInModalClicked}
                        deleteOrderClicked={this.deleteRowClicked}
                        orderId={orderId} />
                }
                {showConfirmOrderModal &&
                    <OrderCartConfirmationModal refreshUserAccount={this.refreshUserAccount}
                        isStore={selectedTab.id === Enumerations.orderProductType.store}
                        closeDetailModal={this.closeConfirmOrderModal}
                        selectedRowIds={selectedRowIds}
                        onClose={this.closeConfirmOrderModal} />
                }
                {showDeleteOrderMessageModal &&
                    <NsMessageClassModal show={true}
                        onClose={this.deleteOrder}
                        messageItems={this.setMessageItems()} />
                }
                {showSuccessPaymentStoreOrderCartModal &&
                    <SuccessPaymentStoreOrderCartModal closeModal={this.closeSuccessPaymentStoreOrderCartModal} />
                }
                {resultMessageClass}
            </ >
        );
    }
}

const mapStateToProps = state => ({ miniCartInfo: state.miniCartInfo.miniCartInfo });

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(miniCartActionCreators, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpCartOrderContent));