import clsx from 'clsx';
import React from 'react';

import ConstantsWithTranslation from '../../configs/ConstantsWithTranslation';
import DateHelper from '../../utilities/DateHelper';
import { isEmpty } from '../../utilities/ValidationHelper';
import NsTypography from '../text/NsTypography';
import { getTranslatedRawText } from '../../utilities/CultureHelper';
import CommonHelper from '../../utilities/CommonHelper';

class HistoryLabelBox extends React.Component {

    sheetIdClickedHandler = () => CommonHelper.safeFunctionCall(this.props.sheetIdClicked, true)

    render() {
        const { history, isAdminPanel, className, responseMessage, onClick } = this.props;
        return (
            <div className={clsx("label-box d-flex flex-column gap-1 background-white-color font-size-10", className)} onClick={onClick}>
                <div className="p-2 title d-flex justify-content-between w-100">
                    <div>
                        {!!history?.detail
                            ? history?.detail
                            : history?.sharedSheetOrderStateType ? ConstantsWithTranslation.getSharedSheetOrderStateType(history.sharedSheetOrderStateType)
                                : history?.stateName
                        }
                    </div>
                    {!!history.sheetID
                        && <NsTypography variant='inherit'
                            className='cursor-pointer font-size-12 text-decoration-underline'
                            onClick={this.sheetIdClickedHandler}>{getTranslatedRawText('adminPanel.orders.sheetId')} : {history.sheetID}</NsTypography>}
                </div>
                {!!responseMessage && <NsTypography variant='inherit' className='font-size-10 px-2'>{responseMessage}</NsTypography>}
                <div className="d-flex ms-auto mb-2 me-2 gap-2">
                    {history?.ServerNameFileRegulator && <NsTypography variant="inherit" className="font-size-10">{history?.serverNameFileRegulator}</NsTypography>}
                    <NsTypography variant='inherit' className="font-size-10">{history?.userFullName || history?.changeStateByUserFullName}</NsTypography>
                    <div>
                        {DateHelper.getDateAndTime(history?.statusTime || history?.changeStateTime)}
                    </div>
                </div>                
                {!isEmpty(history?.exceptionInfo) && <p className='ms-2 me-3 red-color fw-bold'>{history.exceptionInfo}</p>}
            </div>
        )
    }
}

export default HistoryLabelBox;