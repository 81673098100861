import React from 'react';

import NsButton from '../../../../../components/buttons/NsButton';
import NsTypography from '../../../../../components/text/NsTypography';
import Enumerations from '../../../../../configs/Enumerations';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { messageClass } from '../../../../../utilities/MessageModalHelper';
import OrderHelper from '../../../../../utilities/OrderHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import AddFilterModal from '../../../modals/AddFilterModal';

class PoExtendedServiceFilter extends React.Component {

    constructor(props) {
        super(props);
        this.isTwoSide = OrderHelper.getPrintedSide(props.routeId)?.key === Enumerations.printedSide.twoSide;
        this.state = {
            filterPrice: 0,
            filterMinDimension: null,
            masterFiles: [],
            isFront: true,
            isShowAddFiltersModal: false,
            selectedFrontFilters: [],
            selectedBackFilters: [],
            selectedFilters: [],
            refreshExtendedServicesDate: props.refreshExtendedServicesDate,
            resultMessageClass: (<></>),

        };
    }

    componentDidMount = () => this.prepareService();

    componentDidUpdate = (prevProps) => {
        if (prevProps.refreshExtendedServicesDate !== this.state.refreshExtendedServicesDate)
            this.prepareService();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshExtendedServicesDate !== prevState.refreshExtendedServicesDate)
            return ({ refreshExtendedServicesDate: nextProps.refreshExtendedServicesDate });
        return null;
    }

    prepareService = () => {
        let service = OrderHelper.getSpecificService(this.props.routeId, Enumerations.extendedServices.filters);
        !isEmpty(service) && this.setState({
            hasFilterService: !isEmpty(service),
            selectedFilters: service?.selectedItem,
            filterPrice: service?.price,
            filterMinDimension: service.filterMinDimension?.minWidth <= service.filterMinDimension?.minHeight
                ? service.filterMinDimension
                : { minWidth: service.filterMinDimension?.minHeight, minHeight: service.filterMinDimension?.minWidth },
        }, this.seperateFiltersToSideFilter);
    }

    addFilterClicked = (isFront) => {
        OrderHelper.getMasterFilesWithFullInfo(this.props.routeId, (masterFiles) => {
            const file = isFront ? masterFiles[0].frontSideFileInfo : masterFiles[0].backSideFileInfo;
            if (!isEmpty(file))
                this.setState({ isShowAddFiltersModal: true, isFront, masterFiles: masterFiles[0] });
            else
                this.setState({ resultMessageClass: messageClass(getTranslatedRawText('common.messages.selectFileError')) });
        });
    }

    closeAddFilterModalClicked = () => this.setState({ isShowAddFiltersModal: false, isFront: true });

    saveAddFilterModalClicked = (filters, isFront) => {
        const { selectedFrontFilters, selectedBackFilters } = this.state;
        let filterSideName = CommonHelper.getVariableName(isFront ? { selectedFrontFilters } : { selectedBackFilters });
        this.setState({ [filterSideName]: filters },
            () => this.setState({ selectedFilters: [...this.state.selectedFrontFilters, ...this.state.selectedBackFilters] },
                () => {
                    OrderHelper.updateExtendedService(this.props.routeId, Enumerations.extendedServices.filters, this.state.selectedFilters)
                    CommonHelper.safeFunctionCall(this.props.refreshSummary);
                }
            ));
    }

    seperateFiltersToSideFilter = () => {
        let filters = this.state.selectedFilters;
        this.setState({
            selectedFrontFilters: isEmpty(filters) ? [] : filters.filter(filter => filter.isFront === true),
            selectedBackFilters: isEmpty(filters) ? [] : filters.filter(filter => filter.isFront === false)
        });
    }

    render() {
        const { hasFilterService, selectedFilters, selectedFrontFilters, selectedBackFilters, filterPrice, filterMinDimension, masterFiles, isShowAddFiltersModal, isFront } = this.state;
        const isFilterSelected = !isEmpty(selectedFilters);

        return (hasFilterService
            ? <div className='d-flex align-items-center mb-2'>

                <NsTypography variant='inherit' className='font-size-14'>
                    {getTranslatedRawText('professionalOrder.extendedService.filter')}:
                </NsTypography>
                <NsButton startIcon='ns-icon-add'
                    label='regularOrder.common.frontSide'
                    wrapperClassName='mx-1'
                    className='secondary-text'
                    size='small'
                    onClick={() => this.addFilterClicked(true)} />
                {this.isTwoSide &&
                    <NsButton startIcon='ns-icon-add'
                        label='regularOrder.common.backSide'
                        wrapperClassName='mx-1'
                        className='secondary-text'
                        size='small'
                        onClick={() => this.addFilterClicked(false)} />
                }

                {isFilterSelected &&
                    <div className='font-size-12 ms-3'>
                        <b>{selectedFilters.length}</b> <span>{getTranslatedRawText('professionalOrder.extendedService.box')}</span>
                        <b>&nbsp;({CommonHelper.currencyFormatByDefaultConfig(filterPrice * selectedFilters.length)})</b>
                    </div>
                }

                {isShowAddFiltersModal &&
                    <AddFilterModal
                        filterPrice={filterPrice}
                        filterMinDimension={filterMinDimension}
                        showModal={isShowAddFiltersModal}
                        file={isFront ? masterFiles.frontSideFileInfo : masterFiles.backSideFileInfo}
                        isFront={isFront}
                        selectedAreas={isFront ? selectedFrontFilters : selectedBackFilters}
                        saveAddFilterModalClicked={this.saveAddFilterModalClicked}
                        closeAddFilterModalClicked={this.closeAddFilterModalClicked} />
                }
                {this.state.resultMessageClass}
            </div>

            : null
        );
    }
}

export default PoExtendedServiceFilter;