import React, { useState } from 'react';

import NsAdvanceSearch from '../../../../components/advanceSearch/NsAdvanceSearch';
import ConstantsWithTranslation from '../../../../configs/ConstantsWithTranslation';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApBaseSearchButtons from '../../bases/ApBaseSearchButtons';
import CommonHelper from '../../../../utilities/CommonHelper';
import Enumerations from '../../../../configs/Enumerations';

const ApFestivalSearch = (props) => {

    const [showMore, setShowMore] = useState(false);
    const [festivalName, setFestivalName] = useState(null);
    const [festivalStatus, setFestivalStatus] = useState(null);
    const [startDateFrom, setStartDateFrom] = useState(null);
    const [startDateTo, setStartDateTo] = useState(null);
    const [endDateFrom, setEndDateFrom] = useState(null);
    const [endDateTo, setEndDateTo] = useState(null);
    const [executionDateFrom, setExecutionDateFrom] = useState(null);
    const [executionDateTo, setExecutionDateTo] = useState(null);

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const basicSearchOptionsInMobile = [
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('adminPanel.festivals.festivalName'),
            fieldName: 'festivalName',
            value: festivalName,
            onChange: (value) => setFestivalName(value)
        },
    ];

    const basicSearchOptions = [
        {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('adminPanel.festivals.festivalName'),
            fieldName: 'festivalName',
            value: festivalName,
            onChange: (value) => setFestivalName(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.festivals.festivalStatus'),
            fieldName: 'festivalStatus',
            value: festivalStatus,
            items: ConstantsWithTranslation.getFestivalStatuses(),
            onChange: (value) => setFestivalStatus(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('adminPanel.festivals.startDateFrom'),
            fieldName: 'startDateFrom',
            value: startDateFrom,
            onChange: (value) => setStartDateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('adminPanel.festivals.startDateTo'),
            fieldName: 'startDateTo',
            value: startDateTo,
            onChange: (value) => setStartDateTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('adminPanel.festivals.endDateFrom'),
            fieldName: 'endDateFrom',
            value: endDateFrom,
            onChange: (value) => setEndDateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('adminPanel.festivals.endDateTo'),
            fieldName: 'endDateTo',
            value: endDateTo,
            onChange: (value) => setEndDateTo(value)
        },
    ]

    const advanceSearchOptions = [
        ...basicSearchOptions,
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('adminPanel.festivals.executionDateFrom'),
            fieldName: 'executionDateFrom',
            value: executionDateFrom,
            onChange: (value) => setExecutionDateFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.datePicker,
            label: getTranslatedRawText('adminPanel.festivals.executionDateTo'),
            fieldName: 'executionDateTo',
            value: executionDateTo,
            onChange: (value) => setExecutionDateTo(value)
        },
    ];

    const searchClicked = () => {
        let data = {
            festivalName,
            festivalStatus,
            startDateFrom,
            startDateTo,
            endDateFrom,
            endDateTo,
            executionDateFrom,
            executionDateTo,
        }
        CommonHelper.safeFunctionCall(props.searchClicked, data);
    }

    const clearButtonClicked = () => {
        setFestivalName(null);
        setFestivalStatus(null);
        setStartDateFrom(null);
        setStartDateTo(null);
        setEndDateFrom(null);
        setEndDateTo(null);
        setExecutionDateFrom(null);
        setExecutionDateTo(null);
        CommonHelper.safeFunctionCall(props.searchClicked, {});
    }

    const searchValues = {
        festivalName,
        festivalStatus,
        startDateFrom,
        startDateTo,
        endDateFrom,
        endDateTo,
        executionDateFrom,
        executionDateTo,
    }

    const showMoreHandler = () => setShowMore(!showMore);

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={showMore
                    ? advanceSearchOptions
                    : CommonHelper.isMobileDevice() ? basicSearchOptionsInMobile : basicSearchOptions}
                    searchValues={searchValues} mdCol={2} smCol={4} />

                <ApBaseSearchButtons searchClicked={searchClicked}
                    showMoreHandler={showMoreHandler}
                    showMore={showMore}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={props.isLoading} />
            </div>
        </>
    )
}

export default React.memo(ApFestivalSearch);