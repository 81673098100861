import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import NsButton from "../../../../components/buttons/NsButton";
import NsAdminPanelContentWrapper from "../../../../components/cards/NsAdminPanelContentWrapper";
import NsDataGridWithServiceCall from "../../../../components/grids/NsDataGridWithServiceCall";
import Enumerations from "../../../../configs/Enumerations";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import { EditSortStaticPageAdminService, GetStaticPageService } from "../../../../services/adminPanel/AdminSinglePagesService";
import { hasAccess } from "../../../../utilities/ClaimHelper";
import CommonHelper from "../../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ApSinglePagesModal from "./ApSinglePagesModal";
import { GetAgreementStaticPagesService } from "../../../../services/adminPanel/AdminAgreementService";

const ApSinglePages = ({ defaultSelectedItemId, agreementId }) => {

    const [showRowModal, setShowRowModal] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [messageClassModal, setMessageClassModal] = useState();
    const [rows, setRows] = useState([]);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [staticPageInfo, setStaticPageInfo] = useState({});

    const store = useSelector(state => {
        return {
            selectedItemId: state.AdminSidebarReducer.selectedItemId,
        }
    });

    const getData = (rows, isLoading) => {
        setRows(rows);
        setIsLoading(isLoading);
    }

    useEffect(() => {
        let localSelectedItemId = getSelectedItemId();
        if (!isEmpty(localSelectedItemId))
            setStaticPageInfo(CommonHelper.convertAdminBreadCrumbToStaticPageInfo(localSelectedItemId))
    }, [store.selectedItemId])

    const getSelectedItemId = () =>
        isEmpty(defaultSelectedItemId)
            ? store.selectedItemId
            : defaultSelectedItemId

    const columns = [
        hasAccess(!!agreementId
            ? [UserClaimEnumerations.editApUserAggrementStaticPages]
            : [UserClaimEnumerations.getApSpecificStaticPageRowById, UserClaimEnumerations.editApSortStaticPageAdmin]) && {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 160,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasEdit: hasAccess([!!agreementId ? UserClaimEnumerations.editApUserAggrementStaticPages : UserClaimEnumerations.getApSpecificStaticPageRowById]),
                customElementRender: (props) => {
                    return hasAccess([UserClaimEnumerations.editApSortStaticPageAdmin]) && <div className="d-flex">
                        <NsButton startIcon='ns-icon-expand-down'
                            loading={isLoading}
                            onClick={() => sortArrowClicked(props, false)} />
                        <NsButton startIcon='ns-icon-expand-up'
                            loading={isLoading}
                            onClick={() => sortArrowClicked(props, true)} />
                    </div>
                },
                color: 'danger',
                editClicked: (id) => {
                    showRowModalClicked(id)
                }
            }
        },
        {
            field: 'title',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.title'),
            width: 550
        },
        {
            field: 'isActive',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.status'),
            width: 90,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                hasActivationStatus: true
            }
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 150
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 150
        },
    ];

    const sortArrowClicked = (selectedRow, isUpArrow) => {
        const index = rows.findIndex(row => {
            return row.id == selectedRow?.id;
        });
        if ((index == 0 && isUpArrow) || (index == rows.length - 1 && !isUpArrow)) return;
        setIsLoading(true);
        EditSortStaticPageAdminService({
            staticPageType: staticPageInfo.id,
            movedContentId: selectedRow?.id,
            mustBeChangeContentId: (rows[isUpArrow ? index - 1 : index + 1])?.id
        }, editSortRowCallback);
    };

    const editSortRowCallback = (resultData, result, messageClassModal) => {
        if (result.data.hasError) {
            setIsLoading(false);
            setMessageClassModal(messageClassModal);
        }
        else setCallServiceDate(new Date());
    }

    const showRowModalClicked = (selected) => {
        setSelectedRowId(selected);
        setShowRowModal(true);
    }

    const closeRowModalClicked = (refreshData) => {
        setShowRowModal(false);
        setMessageClassModal(refreshData);
        !!refreshData && setCallServiceDate(new Date());
    }
    const actionButton = {
        onClick: showRowModalClicked,
        isLoading: isLoading,
        disabled: !hasAccess(!!agreementId ? [UserClaimEnumerations.postApUserAggrementStaticPages] : [UserClaimEnumerations.postApSpecificStaticPageRow])
    };

    return (
        !isEmpty(staticPageInfo) &&
        <NsAdminPanelContentWrapper header={!!defaultSelectedItemId ? '' : getTranslatedRawText(staticPageInfo.label)}
            actionButton={actionButton}
            icon={!defaultSelectedItemId && `${staticPageInfo.icon} h1`}>

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={!defaultSelectedItemId
                    ? GetStaticPageService
                    : GetAgreementStaticPagesService}
                parameters={!defaultSelectedItemId
                    ? { Filter: staticPageInfo.id }
                    : { id: agreementId, staticPageType: defaultSelectedItemId }}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showRowModal &&
                <ApSinglePagesModal showModal={true}
                    rowId={selectedRowId}
                    agreementId={agreementId}
                    defaultSelectedItemId={defaultSelectedItemId}
                    row={rows?.filter(item => item.id === selectedRowId)[0]}
                    isNewRow={!selectedRowId}
                    staticPageInfo={staticPageInfo}
                    closeModal={closeRowModalClicked} />
            }
            {messageClassModal}
        </NsAdminPanelContentWrapper>
    )
};

export default React.memo(ApSinglePages);