import React, { useState } from 'react';

import NsAdminPanelContentWrapper from '../../../../components/cards/NsAdminPanelContentWrapper';
import NsDataGridWithServiceCall from '../../../../components/grids/NsDataGridWithServiceCall';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import ApProductContentDetailModal from './ApProductContentDetailModal';
import ApProductContentSearch from './ApProductContentSearch';
import Enumerations from '../../../../configs/Enumerations';
import { GetApProductContentsService } from '../../../../services/adminPanel/AdminProductContentService';
import NsButton from '../../../../components/buttons/NsButton';
import { hasAccess } from '../../../../utilities/ClaimHelper';
import UserClaimEnumerations from '../../../../configs/UserClaimEnumerations';

const ProductContents = () => {
    const [showProductContentDetailModal, setShowProductContentDetailModal] = useState(false);
    const [selectedProductContent, setSelectedProductContent] = useState(null);
    const [callServiceDate, setCallServiceDate] = useState(null);
    const [searchData, setSearchData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const columns = [
        hasAccess([UserClaimEnumerations.getApPprContentByName]) && {
            field: 'action',
            headerName: getTranslatedRawText('adminPanel.common.action'),
            width: 80,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) =>
                    <NsButton className='secondary-text px-0'
                        startIcon='ns-icon-invoice-details cursor-pointer'
                        onClick={() => showProductContentDetailModalClicked(props)} />
            }
        },
        {
            field: 'productName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.productName'),
            width: 400
        },
        {
            field: 'extendeName',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.extendedName'),
            width: 300,
        },
        {
            field: 'createAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.createDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'createByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.creator'),
            width: 200
        },
        {
            field: 'editAt',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifyDate'),
            width: 150,
            dataType: Enumerations.gridType.dateTime
        },
        {
            field: 'editByUser',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.modifier'),
            width: 150
        },
    ];

    const showProductContentDetailModalClicked = (data) => {
        setSelectedProductContent(data?.productName);
        setShowProductContentDetailModal(true);
    };

    const closeProductContentModalClicked = (refreshData) => {
        setShowProductContentDetailModal(false);
        !!refreshData && setCallServiceDate(new Date());
    };

    const getSearchData = (data) => {
        setSearchData(data);
        setCallServiceDate(new Date());
    }

    const createParameters = () => {
        return {
            'Filter.ProductCategoryIds': searchData?.productCategories?.id,
            'Filter.ProductIds': searchData?.selectedProductsId,
            'Filter.ProductName': searchData?.productName
        }
    }

    const getData = (rows, isLoading) => setIsLoading(isLoading);

    return (
        <NsAdminPanelContentWrapper header={getTranslatedRawText("adminPanel.sidebar.productContent")}
            icon={'ns-icon-product-content h1'}>

            <ApProductContentSearch setSearchData={getSearchData}
                isLoading={isLoading} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetApProductContentsService}
                parameters={createParameters()}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showProductContentDetailModal &&
                <ApProductContentDetailModal selectedProductContent={selectedProductContent}
                    closeModal={closeProductContentModalClicked} />
            }

        </NsAdminPanelContentWrapper>
    );
}

export default React.memo(ProductContents);