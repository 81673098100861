import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { getTranslatedRawText, getUrlWithCurrentCulture, isEnCulture } from '../../../../../../utilities/CultureHelper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Links from '../../../../../../configs/links/AllLinks';

const UpBaseGroupSheetMobileRedirectToAllOrders = () => {
    return (
        CommonHelper.isMobileDevice() && <div className='d-flex mt-3 ps-1'>
            <span className='font-size-12 me-2'>
                {getTranslatedRawText('profile.allOrders.canNotFindYourOrder')}
            </span>
            <Link className='d-flex align-items-center cursor-pointer font-size-12'
                to={getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.allOrders)}>
                <span className='red-color fw-bold '>{getTranslatedRawText('profile.actionButton.allOrders')}</span>
                <i className={clsx(isEnCulture() ? 'ns-icon-expand-right' : 'ns-icon-expand-left', 'red-color fw-bold')} ></i>
            </Link>
        </div >
    );
};

export default UpBaseGroupSheetMobileRedirectToAllOrders;