import Enumerations from "../../../../configs/Enumerations";
import CommonHelper from "../../../../utilities/CommonHelper";
import StorageHelper from "../../../../utilities/StorageHelper";
import { isEmpty } from "../../../../utilities/ValidationHelper";

let config = {
    paperPrices: [
        {
            paperType: 1,
            price: 4600
        },
        {
            paperType: 2,
            price: 2600
        }
    ],
    twoPagePaperSize: 750,
    defaultOrderQuantity: 30000,
    availableDimensions: {
        minWidth: 625,
        maxWidth: 1020,
        minHeight: 330,
        maxHeight: 520,
    }
};

const PaperType = {
    Virgin: 1,
    Recycled: 2
}

const PackagingType = {
    ShrinkedFilm: 1,
    Carton: 2,
    Both: 3
}

function GetOpenedDimension(dimension, hasTopFold, hasGripperMargin) {
    let openedDimension = {
        width: 0,
        height: 0
    };

    openedDimension.width = (2 * (dimension.gusset + dimension.width)) + 30;
    openedDimension.height = (dimension.gusset / 2) + dimension.height + 20;

    if (!!hasTopFold)
        openedDimension.height += 50;

    if (!!hasGripperMargin)
        openedDimension.height += 10;

    return openedDimension;
}

function GetMin(wastes) {
    return wastes.reduce(function (res, obj) {
        return (obj.paperWasteWeight < res.paperWasteWeight) ? obj : res;
    });
}

function GetWastes(paperRollWidths, openedDimension) {

    let wastes = [];
    paperRollWidths.map(rollWidth => {

        if (rollWidth >= openedDimension.width)
            wastes.push({ rollWidth: rollWidth, isVertical: false, paperWasteWeight: ((rollWidth % openedDimension.width) * openedDimension.height) / Math.floor(rollWidth / openedDimension.width) });

        if (rollWidth >= openedDimension.height)
            wastes.push({ rollWidth: rollWidth, isVertical: true, paperWasteWeight: ((rollWidth % openedDimension.height) * openedDimension.width) / Math.floor(rollWidth / openedDimension.height) });

    });
    return wastes;
}

function GetPaperInfo(config, paperRoll, openedDimension, paperThickness, quantity, paperType, printing) {

    let wastes = !!paperRoll.customWidth
        ? GetWastes([paperRoll.customWidth], openedDimension)
        : GetWastes(paperRoll.widths, openedDimension);

    let minWaste = GetMin(wastes);
    if (!minWaste) {
        console.info('Erorr');
        return null;
    }

    if (!minWaste.isVertical) {
        minWaste.sheetWidth = minWaste.rollWidth / Math.floor(minWaste.rollWidth / openedDimension.width);
        minWaste.sheetHeight = openedDimension.height;
        minWaste.paperWasteWeight = (minWaste.sheetWidth / 1000 - openedDimension.width / 1000) * openedDimension.height / 1000 * paperThickness * quantity;
    } else {
        minWaste.sheetHeight = minWaste.rollWidth / Math.floor(minWaste.rollWidth / openedDimension.height);
        minWaste.sheetWidth = openedDimension.width;
        minWaste.paperWasteWeight = (minWaste.sheetHeight / 1000 - openedDimension.height / 1000) * openedDimension.width / 1000 * paperThickness * quantity;
    }

    minWaste.paperWeight = Math.round(minWaste.sheetHeight / 1000 * minWaste.sheetWidth / 1000 * paperThickness * quantity);
    minWaste.paperPrice = GetPaperPrice(config, minWaste, paperType);
    minWaste.paperName = GetPaperDimensionName(config, openedDimension.width);
    minWaste.printPrice = GetPrintPrice(config, quantity, openedDimension.width, printing);
    minWaste.customPrintPrice = printing.customPrice;

    return minWaste;
}

function GetPaperPrice(config, paperInfo, paperType) {

    let priceInfo = config.paperPrices.find(el => el.paperType == paperType);
    if (!priceInfo) {
        console.info('Erorr');
        return null;
    }

    return paperInfo.paperWeight / 1000000 * priceInfo.price;
}

function GetPaperDimensionName(config, width) {
    return IsTwoPagePaperSize(config, width) ? '2 Pages Print' : '4.5 Pages Print';
}

function IsTwoPagePaperSize(config, width) {
    return width <= config.twoPagePaperSize;
}

function GetPrintPrice(config, quantity, width, printing) {

    if (!printing.hasPrint)
        return 0;

    let priceInfo = GetRawPrintPrice(quantity, printing.printPrices);
    if (!priceInfo)
        priceInfo = GetRawPrintPrice(config.defaultOrderQuantity, printing.printPrices);

    if (!priceInfo) {
        console.info('Erorr');
        return null;
    }

    var priceValue = IsTwoPagePaperSize(config, width)
        ? priceInfo.twoPagePaperSizePrintPrice
        : priceInfo.fourAndHalfPaperSizePrintPrice;

    if (priceInfo.quantity != quantity)
        priceValue = (priceValue / priceInfo.quantity) * quantity;

    return priceValue;
}

function GetRawPrintPrice(quantity, printPrices) {
    return printPrices.find(el => el.quantity == quantity)
}

function GetMakeAndPackageingInfo(packagingType, quantity, making) {

    let makeAndPackageingInfo = {
        cartonPrice: 0,
        shrinkPrice: 0,
        ...GetMakePrice(making, quantity)
    };

    if (packagingType == PackagingType.Both || packagingType == PackagingType.Carton)
        makeAndPackageingInfo.cartonPrice = GetCartonPrice(quantity);
    if (packagingType == PackagingType.Both || packagingType == PackagingType.ShrinkedFilm)
        makeAndPackageingInfo.shrinkPrice = GetShrinkPrice(quantity);

    return makeAndPackageingInfo;
}

function GetMakePrice(making, quantity) {

    let makePriceInfo = GetRawMakePrice(making.pricePerBags, quantity);
    if (!makePriceInfo && !making.customPricePerBag) {
        console.info('Erorr');
        return null;
    }

    return {
        makePricePerBag: makePriceInfo?.pricePerBag,
        makePrice: makePriceInfo?.pricePerBag * quantity,
        customMakePricePerBag: making.customPricePerBag,
        customMakePrice: making.customPricePerBag * quantity
    }
}

function GetRawMakePrice(makePrices, quantity) {
    return makePrices.find(el => quantity <= el.quantityLowerThan)
}

function GetShrinkPrice(quantity) {
    return ((400.0 / 7000.0) * 0.7 * quantity / 50);
}

function GetCartonPrice(quantity) {
    return (quantity / 250.0 * 5.5);
}

function GetTotalPrice(paperInfo, makeAndPackageingInfo, printing, turnaround) {
    let totalPrice = paperInfo.paperPrice + makeAndPackageingInfo.shrinkPrice + makeAndPackageingInfo.cartonPrice;

    totalPrice += !!makeAndPackageingInfo.customMakePrice
        ? makeAndPackageingInfo.customMakePrice
        : makeAndPackageingInfo.makePrice;

    if (!!printing.hasPrint) {
        if (!!paperInfo.customPrintPrice)
            totalPrice += paperInfo.customPrintPrice;
        else
            totalPrice += paperInfo.printPrice;
    }

    if (turnaround == Enumerations.turnaround.express)
        totalPrice *= 1.3;

    return totalPrice;
}

function GetErrors(config, openedDimension) {
    let errors = { isWidthAvailable: true, isHeightAvailable: true };
    if (config.availableDimensions.minWidth > openedDimension.width)
        errors.isWidthAvailable = false;
    if (openedDimension.width > config.availableDimensions.maxWidth)
        errors.isWidthAvailable = false;

    if (config.availableDimensions.minHeight > openedDimension.height)
        errors.isHeightAvailable = false;
    if (openedDimension.height > config.availableDimensions.maxHeight)
        errors.isHeightAvailable = false;

    return errors;
}

export const priceCalculation = (userInput) => {

    const openedDimension = GetOpenedDimension(userInput.dimension, userInput.hasTopFold, userInput.hasGripperMargin);
    const paperInfo = GetPaperInfo(config, userInput.paperRoll, openedDimension, userInput.paperThickness, userInput.quantity, userInput.paperType, userInput.printing);
    const makeAndPackageingInfo = GetMakeAndPackageingInfo(userInput.packagingType, userInput.quantity, userInput.making);
    const totalPrice = GetTotalPrice(paperInfo, makeAndPackageingInfo, userInput.printing, userInput.turnaround);
    const paperWastePercentage = !!paperInfo?.paperWeight
        ? paperInfo.paperWasteWeight * 100 / paperInfo.paperWeight
        : 0;
    const paperWasteColor = paperWastePercentage == 0 ? 'rgb(0,0,0)' : paperWastePercentage < 20 ? `rgb(${255 * paperWastePercentage / 20},0,0)` : 'rgb(255,0,0)'
    const pricePerBag = totalPrice / userInput?.quantity;
    const errors = GetErrors(config, openedDimension);
    const obj = { openedDimension, paperInfo, makeAndPackageingInfo, totalPrice, pricePerBag, paperWastePercentage, paperWasteColor, errors };
    return obj
}

export const getShoppingBagHistories = () => {
    let histories = StorageHelper.getFromStorage(Enumerations.storage.shoppingBagHistories);
    return isEmpty(histories) ? [] : CommonHelper.sortingObject(histories, 'date',true);
}

export const getShoppingBagSavedHistories = () => {
    let histories = StorageHelper.getFromStorage(Enumerations.storage.shoppingBagHistories);
    return isEmpty(histories) ? [] : CommonHelper.sortingObject(histories.filter(history => !!history.name), 'date',true);
}

export const getShoppingBagHistoryById = (id) => {
    let histories = StorageHelper.getFromStorage(Enumerations.storage.shoppingBagHistories);
    return isEmpty(histories) ? {} : histories.find(item => item.id == id);
}

export const saveShoppingBagHistory = (newRow) => {
    let histories = StorageHelper.getFromStorage(Enumerations.storage.shoppingBagHistories);
    let isExistRow = isEmpty(histories) ? false : histories.some(item => item.id == newRow.id);
    if (isExistRow) return;
    histories = isEmpty(histories) ? [newRow] : [...histories, newRow];
    StorageHelper.saveInStorage(Enumerations.storage.shoppingBagHistories, histories);
}

export const saveShoppingBagHistoryName = (id, name) => {
    let selectedRow = getShoppingBagHistoryById(id);
    if (!isEmpty(selectedRow)) {
        selectedRow.name = name;
        let histories = StorageHelper.getFromStorage(Enumerations.storage.shoppingBagHistories);
        let selectedIndex = histories.findIndex(x => x.id === selectedRow.id);
        if (selectedIndex !== -1) {
            histories.splice(selectedIndex, 1, selectedRow);
            StorageHelper.saveInStorage(Enumerations.storage.shoppingBagHistories, histories);
        }
    }
}

export const deleteShoppingBagHistory = (id) => {
    let histories = StorageHelper.getFromStorage(Enumerations.storage.shoppingBagHistories);
    histories = histories.filter(item => item.id !== id)
    StorageHelper.saveInStorage(Enumerations.storage.shoppingBagHistories, histories);
}

export const deleteAllShoppingBagHistory = (selectedTabIndex) => {
    let histories = getShoppingBagHistories();
    histories = histories.filter(history => selectedTabIndex == 0 ? !!history.name : !history.name)
    StorageHelper.saveInStorage(Enumerations.storage.shoppingBagHistories, histories);
}


