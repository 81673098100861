import React from 'react';
import NsDataGrid from '../../../../../../components/grids/NsDataGrid';
import NsImage from '../../../../../../components/images/NsImage';
import NsLabelBox from '../../../../../../components/labels/NsLabelBox';
import NsSkeleton from '../../../../../../components/skeletons/NsSkeleton';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../../configs/Enumerations';
import { GetOrderBasicInfoService, GetOrderDetailService } from '../../../../../../services/ProfileSetting/UserOrderService';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import DateHelper from '../../../../../../utilities/DateHelper';
import EnumerationTranslationHelper from '../../../../../../utilities/EnumerationTranslationHelper';
import AgencyInfoModal from '../../../../modals/AgencyInfoModal';
import OrderDetailPreviewModal from '../../../../modals/orderDetailPreview/OrderDetailPreviewModal';
import { GetAgencyBasicInfoOrderDetailService, GetAgencyOrderDetailService } from '../../../../../../services/ProfileSetting/AgencyService';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';


class UpBaseGroupSheetOrdersDetailGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderId: props.orderId,
            selectedItemDetailId: '',
            basicInfo: {},
            columns: [],
            rows: [],
            isLoading: false,
            basicInfoLoading: false,
            rowPerPage: CommonHelper.defaultRowPerPage(),
            isShowOrderDetailPreviewModal: false,
            showAgencyInfoModal: false,
            resultMessageClass: (<React.Fragment></React.Fragment>),
        };
    }

    createColumnHeader = (printedSide, latCount) => {
        this.setState({
            columns: [
                {
                    field: 'detail',
                    headerName: getTranslatedRawText('profile.dataGridColumns.detail'),
                    width: 90,
                    resizable: false,
                    lockPosition: true,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        hasDetail: true,
                        detailClicked: (id) => this.orderDetailPreviewClicked(id),
                    }
                },
                {
                    field: 'frontImageUrl',
                    headerName: getTranslatedRawText('profile.dataGridColumns.frontImage'),
                    width: 110,
                    resizable: false,
                    cellRenderer: "customRowRenderer",
                    cellRendererParams: {
                        customElementRender: (props) => <NsImage className="w-100"
                            imgAlt="components.imagesAlt.orderFrontSide"
                            imageUrl={props.frontImageUrl} />
                    },
                },
                printedSide === Enumerations.printedSide.twoSide
                    ? {
                        field: 'backImageUrl',
                        headerName: getTranslatedRawText('profile.dataGridColumns.backImage'),
                        width: 110,
                        resizable: false,
                        cellRenderer: "customRowRenderer",
                        cellRendererParams: {
                            customElementRender: (props) => <NsImage className="w-100"
                                imgAlt="components.imagesAlt.orderBackSide"
                                imageUrl={props.backImageUrl} />
                        },
                    }
                    : { hide: true },

                {
                    field: 'size',
                    headerName: getTranslatedRawText('profile.dataGridColumns.dimension'),
                    width: 180,
                },
                {
                    field: 'fileDimension',
                    headerName: getTranslatedRawText('profile.dataGridColumns.fileDimension'),
                    width: 230,
                },
                {
                    field: 'basePriceMultiplyInLatCount',
                    headerName: getTranslatedRawText('profile.dataGridColumns.rate'),
                    width: 160,
                    dataType: Enumerations.gridType.price,
                },
                {
                    field: 'extendedServicePrice',
                    headerName: getTranslatedRawText('profile.dataGridColumns.servicePrice'),
                    width: 160,
                    dataType: Enumerations.gridType.price,
                },
                {
                    field: 'series',
                    headerName: getTranslatedRawText('profile.dataGridColumns.series'),
                    dataType: Enumerations.gridType.number,
                    width: 90,
                },
                {
                    field: 'totalPrice',
                    headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat'),
                    width: 150,
                    dataType: Enumerations.gridType.price,
                },
                {
                    field: 'totalPriceIncludeVat',
                    headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithVat'),
                    width: 150,
                    dataType: Enumerations.gridType.price,
                },
            ]
        });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.orderId !== this.props.orderId)
            this.setState({ orderId: newProps.orderId, },
                () => this.getOrderInfo());
    }

    orderDetailPreviewClicked = (id) => this.setState({ selectedItemDetailId: id, isShowOrderDetailPreviewModal: true });

    closeOrderDetailPreviewModal = () => this.setState({ isShowOrderDetailPreviewModal: false });

    agencyClicked = () => this.setState({ showAgencyInfoModal: true });

    closeAgencyModal = () => this.setState({ showAgencyInfoModal: false });

    getOrderDetailCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result?.data?.hasError) {
            this.setState({ rows: resultData }
                , () => this.createColumnHeader(this.state.basicInfo.twoSidePrintingType, this.state.basicInfo.totalLatCount)
            );
            CommonHelper.safeFunctionCall(this.props.hasPayment, !!resultData[0]?.paymentInAdvance);
            CommonHelper.safeFunctionCall(this.props.isFeedbackActive, !!resultData[0]?.isFeedbackActive);

        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getOrderBasicInfoCallback = (resultData, result, messageClassModal) => {
        this.setState({ basicInfoLoading: false });
        if (!result?.data?.hasError) {
            this.setState({ basicInfo: resultData });
            CommonHelper.safeFunctionCall(this.props.orderTitleChanged, resultData.productName, !!resultData.invoiceNumber, resultData.sharedSheetOrderStateType, resultData);
            let params = {
                id: this.state.orderId,
                currentpageIndex: 1,
                pageLength: this.state.rowPerPage.value,
            };
            this.props.isAgency
                ? GetAgencyOrderDetailService(params, this.getOrderDetailCallback)
                : GetOrderDetailService(params, this.getOrderDetailCallback);
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getOrderInfo = () => {
        this.setState({ isLoading: true, basicInfoLoading: true });
        this.props.isAgency
            ? GetAgencyBasicInfoOrderDetailService({ orderId: this.state.orderId }, this.getOrderBasicInfoCallback)
            : GetOrderBasicInfoService({ orderId: this.state.orderId }, this.getOrderBasicInfoCallback);
    }

    componentDidMount = () => !!this.state.orderId && this.getOrderInfo();

    componentDidUpdate = (prevProps) => {
        if (prevProps.isLoading !== this.props.isLoading)
            this.setState({ isLoading: this.props.isLoading })
    }

    render() {
        const { isShowOrderDetailPreviewModal, isLoading, columns, rows, basicInfo, selectedItemDetailId, basicInfoLoading, showAgencyInfoModal } = this.state;
        return (
            <div className='h-100'>
                <div className='mb-2'>
                    {basicInfoLoading ?
                        CommonHelper.createArray(4).map((item, index) =>
                            <div key={index} className='d-inline-block w-25 px-1'>
                                <NsSkeleton isVisible={true}
                                    variant={[
                                        Enumerations.skeleton.rect,
                                        Enumerations.skeleton.rect,
                                        Enumerations.skeleton.rect,
                                    ]} />
                            </div>
                        )
                        :
                        <BasicInfoLabelBox basicInfo={basicInfo}
                            basicInfoLoading={basicInfoLoading}
                            agencyClicked={this.agencyClicked} />
                    }
                </div>

                <NsDataGrid totalRows={rows.length}
                    isLoading={isLoading}
                    columnDefs={columns}
                    refreshButtonClicked={this.getOrderInfo}
                    rows={rows} />

                {isShowOrderDetailPreviewModal && <OrderDetailPreviewModal showModal={isShowOrderDetailPreviewModal}
                    orderId={selectedItemDetailId}
                    closeModal={this.closeOrderDetailPreviewModal} />
                }

                {showAgencyInfoModal && <AgencyInfoModal agencyId={basicInfo?.agencyId}
                    closeModal={this.closeAgencyModal} />
                }

                {this.state.resultMessageClass}
            </div>
        );
    }
}

function BasicInfoLabelBox(props) {
    const { status, invoiceNumber, businessPartnerCustomerIdentifier, twoSidePrintingType, turnaroundType, circulation, totalLatCount, createDate, discount, cancellationCode, agencyName,
        estimateReadyToDeliverTime, remainingDaysToEstimateReadyToDeliverTime, totalQuantity } = props.basicInfo;
    return (
        <div className='d-flex flex-wrap gap-2'>
            {!!status &&
                <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.status')}
                    value={ConstantsWithTranslation.getSharedSheetOrderStateType(status)} />
            }

            {!!invoiceNumber &&
                <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.invoiceNumber')}
                    value={invoiceNumber} />
            }

            <NsLabelBox label={getTranslatedRawText('profile.common.submitDate')}
                value={DateHelper.getDateAndTime(createDate)} />
            {!isEmpty(estimateReadyToDeliverTime) && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime')}
                value={DateHelper.getDateAndTime(estimateReadyToDeliverTime)} />
            }
            {!isEmpty(remainingDaysToEstimateReadyToDeliverTime) && <NsLabelBox label={getTranslatedRawText('adminPanel.dataGridColumn.remainingDaysToEstimateReadyToDeliverTime')}
                value={remainingDaysToEstimateReadyToDeliverTime} />
            }
            <NsLabelBox label={getTranslatedRawText('regularOrder.header.printedSide')}
                value={EnumerationTranslationHelper.getPrintedSideText(twoSidePrintingType)} />

            <NsLabelBox label={getTranslatedRawText('regularOrder.header.turnaround')}
                value={EnumerationTranslationHelper.getTurnaroundText(turnaroundType)} />

            <NsLabelBox label={getTranslatedRawText('regularOrder.header.circulation')}
                value={circulation} />

            <NsLabelBox label={getTranslatedRawText('regularOrder.header.totalQuantity')}
                value={totalQuantity} />

            <NsLabelBox label={getTranslatedRawText('regularOrder.header.totalUps')}
                value={totalLatCount} />

            <NsLabelBox label={getTranslatedRawText('regularOrder.header.discount')}
                value={CommonHelper.currencyFormatByDefaultConfig(discount)} />

            {!!cancellationCode && <NsLabelBox label={getTranslatedRawText('regularOrder.header.cancellationCode')}
                value={cancellationCode} />}

            {!!agencyName && <NsLabelBox label={getTranslatedRawText('regularOrder.header.agency')}
                className='cursor-pointer'
                value={<i className='ns-icon-expand-left' >{agencyName}</i>}
                onClick={props.agencyClicked} />}

            {!!businessPartnerCustomerIdentifier && <NsLabelBox label={getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier')}
                value={businessPartnerCustomerIdentifier} />}

        </div>
    );
}

export default UpBaseGroupSheetOrdersDetailGrid;