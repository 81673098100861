import React, { useEffect, useRef, useState } from "react";

import NsDataGridWithServiceCall from "../../../../../components/grids/NsDataGridWithServiceCall";
import { GetCustomersService } from "../../../../../services/adminPanel/AdminUserService";
import { isEmpty } from "../../../../../utilities/ValidationHelper";
import { getApCustomerColumn } from "../bases/ApBaseUserHelper";
import ApBaseUserInfoModal from '../bases/ApBaseUserInfoModal';
import ApCustomerUserSearch from "./ApCustomerUserSearch";

export const ApCustomerContent = (props) => {

    const { isUserSelection } = props;

    const avoidingRunUseEffectForFirstTime = useRef(true);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [searchData, setSearchData] = useState({});
    const [showCustomerDetailModal, setShowCustomerDetailModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [callServiceDate, setCallServiceDate] = useState(null);

    useEffect(() => {
        if (!avoidingRunUseEffectForFirstTime.current) {
            setCallServiceDate(new Date());
            setIsLoading(true);
        }
        return () => { avoidingRunUseEffectForFirstTime.current = false; }
    }, [searchData]);

    const getParameters = () => ({
        "Filter.PhoneNumber": !!searchData?.mobile ? convertMobile(searchData?.mobile) : '',
        "Filter.FirstName": searchData?.name || '',
        "Filter.LastName": searchData?.lastName || '',
        "Filter.CompanyName": searchData?.companyName || '',
        "Filter.IsCompany": searchData?.accountTypes?.id || [],
        "Filter.Roles": searchData?.roles?.id || [],
        "Filter.DetailLedgerId": searchData?.detailLedger || '',
        "Filter.CountryId": searchData?.selectedCountry?.key || [],
        "Filter.StateId": searchData?.selectedState?.key || [],
        "Filter.CityId": searchData?.selectedCity?.key || [],
        "Filter.AgencyIds": searchData?.selectedAgency?.key || [],
        "Filter.AccountStatus": searchData.accountStatus?.id,
        "Filter.NationalCode": searchData.idNumber || '',
        "Filter.RegistrationTimeFrom": searchData.registionTimeFrom || '',
        "Filter.RegistrationTimeTo": searchData?.registionTimeTo || '',
        "Filter.PhoneNumberConfirmed": searchData.mobileActivations?.id,
        "Filter.DateOfBirthFrom": searchData?.dateOfBirthFrom || '',
        "Filter.DateOfBirthTo": searchData?.dateOfBirthTo || '',
        "Filter.AgreementStatus": searchData?.agreementStatus?.id,
        "Filter.licenceNumber": searchData?.licenceNumber || '',
        "Filter.trn": searchData?.trn || '',
    });

    const handlers = {

        selectUserClicked: (user) => {
            props.userSelected(user)
        },

        getSearchData: (data) => {
            setSearchData(data)
        },

        customerDetailClicked: (id) => {
            setSelectedCustomer(customers.filter(customer => customer.userId === id)[0])
            setShowCustomerDetailModal(true)
        },

        closeApCustomerUserModal: () => {
            setShowCustomerDetailModal(false);
            setCallServiceDate(new Date());
        },

        getData: (customers, isLoading) => {
            setCustomers(customers);
            setIsLoading(isLoading);
        }
    }

    const { selectUserClicked, customerDetailClicked, closeApCustomerUserModal, getSearchData, getData } = handlers;

    const columns = getApCustomerColumn(selectUserClicked, customerDetailClicked, isUserSelection);

    return (
        <>
            <ApCustomerUserSearch isLoading={isLoading}
                setSearchData={getSearchData} />

            <NsDataGridWithServiceCall callServiceDate={callServiceDate}
                service={GetCustomersService}
                parameters={getParameters()}
                columnDefs={columns}
                hasPagination={true}
                getData={getData} />

            {showCustomerDetailModal &&
                <ApBaseUserInfoModal showModal={true}
                    selectedUserId={selectedCustomer?.userId}
                    closeModal={closeApCustomerUserModal} />
            }
        </>
    )
}

export const convertMobile = (mobile) => {
    if (!isEmpty(mobile)) {
        let convertedMobile = mobile.replace(/ /g, '').substr(1);
        return convertedMobile.split('-')[0];
    }
    return null
}