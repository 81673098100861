import CommonHelper from "../../../utilities/CommonHelper";
import { getTranslatedRawText } from "../../../utilities/CultureHelper";

class RegularOrderTours {

    static getRegularOrderWrapperTour() {
        return ([
            {
                selector: `${CommonHelper.isMobileDevice() ? '.header-tour' : '.header-tour-details'}`,
                content: getTranslatedRawText('regularOrder.tour.headerDetails'),
            },
            {
                selector: `${CommonHelper.isMobileDevice() ? '.header-tour' : '.header-tour-edit'}`,
                content: getTranslatedRawText('regularOrder.tour.headerEditOption'),
            },
            {
                selector: '.regularOrder-tour-chooseFile',
                content: getTranslatedRawText('regularOrder.tour.masterFile'),
            },
        ]);
    }

    static getHeaderTour() {
        return ([
            {
                selector: '.header-tour',
                content: getTranslatedRawText('regularOrder.tour.orderFilesPreview'),
            },
        ]);
    }

    static getSummaryFilePreviewTour() {
        return ([
            {
                selector: '.order-tour-choosingFileSummary',
                content: getTranslatedRawText('regularOrder.tour.infoFile'),
            },
            {
                selector: '.orderFilesPreview-tour-preview',
                content: getTranslatedRawText('regularOrder.tour.orderFilesPreview'),
            },
        ]);
    }

    static getExtendedServiceSummaryTours() {
        return [
            {
                selector: '.order-tour-extendedServiceSummary',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceInfo'),
            },
        ];
    }

    static getFilmFileTour() {
        return ([
            {
                selector: '.regularOrder-tour-chooseFile',
                content: getTranslatedRawText('regularOrder.tour.filmFile'),
            },
        ]);
    }

    static getFilterTour() {
        return ([
            {
                selector: '.extendedService-tour-filter',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceFilter'),
            },
        ]);
    }

    static getFoldLineTour() {
        return ([
            {
                selector: '.extendedService-tour-foldLine',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceFoldLine'),
            },
        ]);
    }

    static getRoundedCornerTour() {
        return ([
            {
                selector: '.extendedService-tour-roundedCorner',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceRoundedCorner'),
            },
        ]);
    }

    static getGripperEdgeTour() {
        return ([
            {
                selector: '.extendedService-tour-gripperEdge',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceGripperEdge'),
            },
        ]);
    }

    static getPunchTour() {
        return ([
            {
                selector: '.extendedService-tour-punch',
                content: getTranslatedRawText('regularOrder.tour.extendedServicePunch'),
            },
        ]);
    }

    static getAddFilterModalTour() {
        return ([
            {
                selector: '.extendedService-tour-addedFilter',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalAddedFilter'),
            },
        ]);
    }

    static getPerforationModalTour() {
        return ([
            {
                selector: '.extendedService__tour__perforation-modal',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalPerforation'),
            },
        ]);
    }

    static getBillBookPreviewModalTour() {
        return ([
            {
                selector: '.extended-service__tour__bill-book__preview__header',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceBillBookModalPreview'),
            },
        ]);
    }

    static getSerialModalTour() {
        return ([
            {
                selector: '.extendedService__tour__serial-modal',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalSerialNo'),
            },
        ]);
    }

    static getBillBookModalInputsTour() {
        return ([
            {
                selector: '.extended-service__tour__bill-book__inputs',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalBillBookInputs'),
            },
        ]);
    }

    static getBillBookModalBindingSideInputTour() {
        return ([
            {
                selector: '.extended-service__tour__bill-book__binding-side',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalBillBookBindingSide'),
            },
        ]);
    }

    static getBillBookOptionButtonTour() {
        return ([
            {
                selector: '.extended-service__tour__bill-book__option-button',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceBillBookOptionButton'),
            },
        ]);
    }

    static getBillBookPreviewButtonTour() {
        return ([
            {
                selector: '.extended-service__tour__bill-book__preview-button',
                content: getTranslatedRawText('regularOrder.tour.orderFilesPreview'),
            },
        ]);
    }

    static getBillBookModalContentTour() {
        return ([
            {
                selector: '.extended-service__tour__bill-book__modal-content',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalBillBookModalContent'),
            },
            {
                selector: '.extended-service__tour__bill-book__modal-summary',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalBillBookModalSummary'),
            },
        ]);
    }

    static getBillBookSerialNumberRowsModalTour() {
        return ([
            {
                selector: '.extended-service__tour__bill-book__serial-number-row',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalBillBookSerialNumberRow'),
            },
        ]);
    }

    static getBillBookPerforationRowsModalTour() {
        return ([
            {
                selector: '.extended-service__tour__bill-book__perforation-row',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalBillBookPerforationRow'),
            },
        ]);
    }

    static getAddPunchModalTour() {
        return ([
            {
                selector: '.extendedService-tour-addedPunch',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceModalAddedPunch'),
            },
        ]);
    }

    static getMasterFileModalTour() {
        return ([
            {
                selector: `.masterFileModal-tour-preview${CommonHelper.isMobileDevice() ? '-mobile' : ''}`,
                content: getTranslatedRawText('regularOrder.tour.chooseFileModalPreview'),
            },
            {
                selector: '.masterFileModal-tour-changeFile',
                content: getTranslatedRawText('regularOrder.tour.chooseFileModalChangeFile'),
            },
            {
                selector: '.masterFileModal-tour-save',
                content: getTranslatedRawText('regularOrder.tour.chooseFileModalSave'),
            },
        ]);
    }

    static getFilmFileModalTour() {
        return ([
            {
                selector: `.masterFileModal-tour-preview${CommonHelper.isMobileDevice() ? '-mobile' : ''}`,
                content: getTranslatedRawText('regularOrder.tour.chooseFileModalPreview'),
            },
            {
                selector: '.masterFileModal-tour-changeFile',
                content: getTranslatedRawText('regularOrder.tour.chooseFileModalChangeFile'),
            },
            {
                selector: '.masterFileModal-tour-align',
                content: getTranslatedRawText('regularOrder.tour.chooseFileModalAlignment'),
            },
            {
                selector: '.masterFileModal-tour-resize',
                content: getTranslatedRawText('regularOrder.tour.chooseFileModalResize'),
            },
            {
                selector: '.masterFileModal-tour-fit',
                content: getTranslatedRawText('regularOrder.tour.chooseFileModalFit'),
            },
            {
                selector: '.masterFileModal-tour-save',
                content: getTranslatedRawText('regularOrder.tour.chooseFileModalSave'),
            },
        ]);
    }

    static getConfirmDetailTour() {
        return ([
            {
                selector: '.extendedService-tour-confirmDetails',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceConfirmDetails'),
            },
            {
                selector: '.extendedService-tour-confirmSubmit',
                content: getTranslatedRawText('regularOrder.tour.extendedServiceConfirmSubmit'),
            },
        ]);
    }
}

export default RegularOrderTours;