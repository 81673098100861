import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { GetPrintingFeatureService } from '../../../services/product/ProductService';
import NsToggleGroupButton from '../../../components/buttons/NsToggleGroupButton';
import { GetProductBasicPriceService } from '../../../services/order/OrderService';
import SingletonCallService from '../../../services/bases/SingletonCallService';
import { getTranslatedRawText } from '../../../utilities/CultureHelper';
import PdPrintingProcessTime from '../bases/PdPrintingProcessTime';
import { isEmpty } from '../../../utilities/ValidationHelper';
import CommonHelper from '../../../utilities/CommonHelper';

class PdBaseFeature extends React.Component {

    constructor(props) {
        super(props);
        // this.memoizeGetPrice = CommonHelper.memoizeFunc(this.getPrice);
        this.singletonBasicPriceService = new SingletonCallService(GetProductBasicPriceService, this.getProductBasicPriceCallback);

        this.state = {
            productId: props.productId,
            turnarounds: [],
            printedSides: [],
            selectedTurnaround: {},
            selectedPrintedSide: null,
            resultMessageClass: (<></>)
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.refreshFeaturesDate !== this.props.refreshFeaturesDate)
            if (newProps.productId !== this.props.productId) {
                this.setState({ productId: newProps.productId }
                    , () => this.getPrintingFeature(newProps.productId));
            } else
                this.getPrice();
    }

    componentDidUpdate(prevProps, prevState) {

        if ((!isEmpty(this.props.userFullName) && this.props.userFullName !== prevProps.userFullName) ||
            ((prevProps.selectedCurrency !== this.props.selectedCurrency && !!this.state.productId))) {
            CommonHelper.safeFunctionCall(this.props.sizeAndPricesChanged, true);
            this.getPrice();
        }
    }

    getPrintingFeature = (productId) => {
        CommonHelper.safeFunctionCall(this.props.enableLoadingClicked);
        GetPrintingFeatureService(productId, this.getPrintingFeatureCallback);
    }

    getPrintingFeatureCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            const selectedPrintedSide = (resultData.printedSideTypes.length === 1 || !this.state.selectedPrintedSide)
                ? resultData.printedSideTypes[0]
                : this.state.selectedPrintedSide;
            const selectedTurnaround = resultData.turnaroundTypes.find(turnaround => turnaround.key === this.state.selectedTurnaround.key) || resultData.turnaroundTypes[0];
            this.setState({
                turnarounds: resultData.turnaroundTypes,
                printedSides: resultData.printedSideTypes,
                selectedTurnaround,
                selectedPrintedSide,
            }, () => {
                CommonHelper.safeFunctionCall(this.props.guidancesUrlUpdated, resultData?.guidancesUrl);
                this.getPrice();
            }
            );
        }
        else
            this.setState({ resultMessageClass: messageClassModal });
    }

    getPrice = () => {
        if (isEmpty(this.state.selectedPrintedSide) || isEmpty(this.state.selectedTurnaround))
            return;
        let data = {
            productId: this.state.productId,
            series: this.props.series,
            turnaroundType: this.state.selectedTurnaround?.key,
            twoSidePrintingType: this.state.selectedPrintedSide?.key,
            width: null,
            height: null
        }
        data.series && this.singletonBasicPriceService.run(data);
    }

    getProductBasicPriceCallback = (resultData, result, messageClassModal) => {
        const { selectedTurnaround, selectedPrintedSide } = this.state;
        CommonHelper.safeFunctionCall(this.props.sizeAndPricesChanged, false, isEmpty(resultData) ? [] : resultData, selectedTurnaround, selectedPrintedSide);
        if (result?.data?.hasError) {
            !!messageClassModal?.props?.messageItems[0]?.isCanceled
                ? CommonHelper.safeFunctionCall(this.props.sizeAndPricesChanged, true)
                : this.setState({ resultMessageClass: messageClassModal });
        }
    }

    turnaroundClicked = (selectedItem) => {
        if (selectedItem !== this.state.selectedTurnaround)
            this.setState({ selectedTurnaround: selectedItem, printedSides: this.state.printedSides },
                () => {
                    CommonHelper.safeFunctionCall(this.props.sizeAndPricesChanged, true);
                    this.getPrice();
                }
            );
    }

    printedSideClicked = (selectedItem) => {
        if (selectedItem !== this.state.selectedPrintedSide)
            this.setState({ selectedPrintedSide: selectedItem },
                () => {
                    CommonHelper.safeFunctionCall(this.props.sizeAndPricesChanged, true);
                    this.getPrice();
                }
            );
    }

    render() {
        const { selectedTurnaround, turnarounds, printedSides, selectedPrintedSide, productId, resultMessageClass } = this.state;
        const md = turnarounds.length > 1 ? 4 : 2;
        return (
            <Row>
                <Col md={md} className="py-2">
                    <NsToggleGroupButton isShowGroupLabel={true}
                        label={getTranslatedRawText('product.detail.turnaround')}
                        vertical={false}
                        onClick={this.turnaroundClicked}
                        selectedItemId={!!selectedTurnaround && selectedTurnaround.key}
                        items={turnarounds} />
                </Col>
                <Col md={8} lg={7} xl={6} xxl={5} className="py-2">
                    <NsToggleGroupButton isShowGroupLabel={true}
                        label={getTranslatedRawText('product.detail.printedSide')}
                        vertical={false}
                        onClick={this.printedSideClicked}
                        selectedItemId={!!selectedPrintedSide && selectedPrintedSide.key}
                        items={printedSides} />
                </Col>
                <Col xs={10} md={8} className='mt-3 background-element-color p-3 rounded mx-2'>
                    <PdPrintingProcessTime selectedCirculationKey={productId}
                        selectedTurnaround={selectedTurnaround}
                        selectedPrintedSide={selectedPrintedSide}
                        getProcessTimeBasedSelectedOptions={this.props.getProcessTimeBasedSelectedOptions}
                        printedSides={printedSides}
                        turnarounds={turnarounds} />
                </Col>
                {resultMessageClass}
            </Row>
        );
    }
}

export default PdBaseFeature;