import React, { useCallback, useEffect, useState } from "react";

import { DeleteDemandedServiceTypes, DeleteFeedbackTypeMetricService, EdiFeedbackTypeMetricService, EditDemandServiceMetricService, GeFeedbackTypeMetricByIdService, GetApDemandServiceMetricByIdService, GetDemandServiceMetricTypesService, PosFeedbackTypeMetricService, PostDemandServiceMetricService } from "../../../../services/adminPanel/AdminFeedbackService";
import NsMessageClassModal from "../../../../components/modals/NsMessageClassModal";
import ConstantsWithTranslation from "../../../../configs/ConstantsWithTranslation";
import UserClaimEnumerations from "../../../../configs/UserClaimEnumerations";
import NsAutoComplete from "../../../../components/dropdowns/NsAutoComplete";
import NsStickyButton from "../../../../components/buttons/NsStickyButton";
import { getTranslatedRawText } from "../../../../utilities/CultureHelper";
import ApBaseMapActionButtons from "../../bases/ApBaseMapActionButtons";
import NsTextField from "../../../../components/inputs/NsTextField";
import { isEmpty } from "../../../../utilities/ValidationHelper";
import ContentHelper from "../../contents/bases/ContentHelper";
import CommonHelper from "../../../../utilities/CommonHelper";
import NsSwitch from "../../../../components/switch/NsSwitch";
import NsModal from "../../../../components/modals/NsModal";
import Enumerations from "../../../../configs/Enumerations";
import ApLanguageTabs from "../../bases/ApLanguageTabs";

const ApFeedbackTypeMetricModal = (props) => {

    const { selectedfeedbackTypeMetric, feedbackTypeMetricTypeId, feedbackTypeId } = props;
    const isDemandService = feedbackTypeMetricTypeId === Enumerations.feedbackMetricTypes.demandService;
    const [multiLanguageFeedbackTypeMetric, setMultiLanguageFeedbackTypeMetric] = useState([]);
    const [demandServices, setDemandServices] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({ id: 1 });
    const [isLoading, setIsLoading] = useState(!!selectedfeedbackTypeMetric);
    const [showValidationDate, setShowValidationDate] = useState();
    const [showFeedbackTypeMetricModal, setShowFeedbackTypeMetricModal] = useState(false);
    const [messageClassModal, setMessageClassModal] = useState(<></>);

    useEffect(() => {
        isDemandService && GetDemandServiceMetricTypesService(getDemandServiceMetricTypesCallback);
        !selectedfeedbackTypeMetric
            ? setMultiLanguageFeedbackTypeMetric(ContentHelper.normalizeContentObject([], { isActive: false, feedbackTypeMetricTypeId, feedbackTypeId, sortNumber: 1 }))
            : isDemandService
                ? GetApDemandServiceMetricByIdService(selectedfeedbackTypeMetric, getFeedbackTypeMetricByIdCallback)
                : GeFeedbackTypeMetricByIdService(selectedfeedbackTypeMetric, getFeedbackTypeMetricByIdCallback);
    }, []);

    const getFeedbackTypeMetricByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setMultiLanguageFeedbackTypeMetric(ContentHelper.normalizeContentObject(resultData, { isActive: resultData[0].content.isActive }));
        } else
            setMessageClassModal(messageClassModal);
    };

    const getDemandServiceMetricTypesCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            setDemandServices(resultData);
        } else
            setMessageClassModal(messageClassModal);
    };

    const closeModalClicked = (messageClassModal) => CommonHelper.safeFunctionCall(props.closeModal, messageClassModal);

    const saveButtonClicked = () => {
        let tempContent = ContentHelper.removFeedbackTypeMetricEmptyContent(multiLanguageFeedbackTypeMetric, isDemandService);
        let isNotValidData = ContentHelper.isValidFeedbackTypeMetricContent(tempContent, isDemandService);
        if (isNotValidData) {
            setShowValidationDate(new Date());
            return;
        }

        setIsLoading(true);
        !selectedfeedbackTypeMetric
            ? isDemandService
                ? PostDemandServiceMetricService(tempContent, feedbackTypeMetricCallback, true)
                : PosFeedbackTypeMetricService(tempContent, feedbackTypeMetricCallback, true)
            : isDemandService
                ? EditDemandServiceMetricService(selectedfeedbackTypeMetric, tempContent, feedbackTypeMetricCallback, true)
                : EdiFeedbackTypeMetricService(selectedfeedbackTypeMetric, tempContent, feedbackTypeMetricCallback, true);
    };

    const feedbackTypeMetricCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError) {
            closeModalClicked(messageClassModal);
        } else
            setMessageClassModal(messageClassModal);
    };

    const deleteFeedbackTypeMetricClicked = () => setShowFeedbackTypeMetricModal(true);

    const deleteFeedbackTypeMetric = (confirm) => {
        setShowFeedbackTypeMetricModal(false);
        if (confirm) {
            setIsLoading(true);
            isDemandService
                ? DeleteDemandedServiceTypes(selectedfeedbackTypeMetric, deleteFeedbackTypeMetricCallback, true)
                : DeleteFeedbackTypeMetricService(selectedfeedbackTypeMetric, deleteFeedbackTypeMetricCallback, true)
        }
    };

    const deleteFeedbackTypeMetricCallback = (resultData, result, messageClassModal) => {
        setIsLoading(false);
        if (!result.data.hasError)
            closeModalClicked(messageClassModal);
        else
            setMessageClassModal(messageClassModal);
    };

    const mainButtons = [
        {
            label: "common.save", icon: 'ns-icon-save', onClicked: saveButtonClicked, className: 'primary',
            accessIds: isDemandService
                ? !selectedfeedbackTypeMetric ? [UserClaimEnumerations.postApFeedbackTypeDemandService] : [UserClaimEnumerations.editApFeedbackTypeDemandService]
                : !selectedfeedbackTypeMetric ? [UserClaimEnumerations.postApFeedbackTypeMetric] : [UserClaimEnumerations.editApFeedbackTypeMetric]
        },
        { label: "common.cancel", icon: 'ns-icon-close', onClicked: () => closeModalClicked(false), className: 'primary-outline' },
    ];

    const languageChangedHandler = useCallback(selectedTab => {
        setSelectedLanguage(selectedTab);
    }, [])

    const stateHandler = (data, value, selectedLanguageId) => {
        const newData = ContentHelper.updateContentHandler(data, value, selectedLanguageId);
        setMultiLanguageFeedbackTypeMetric([...newData]);
    }

    const headerButtons = [
        { wrapperClassName: 'ms-1', startIcon: 'ns-icon-delete', label: 'common.delete', onClick: deleteFeedbackTypeMetricClicked, accessIds: [isDemandService ? UserClaimEnumerations.deleteApFeedbackTypeDemandService : UserClaimEnumerations.deleteApFeedbackTypeMetric] }
    ]

    return (
        <NsModal
            maxWidth="md"
            dialogTitle={getTranslatedRawText(selectedfeedbackTypeMetric ?
                CommonHelper.stringFormat(getTranslatedRawText('adminPanel.feedback.editFeedbackMetric'), props.selectedTab?.label) :
                CommonHelper.stringFormat(getTranslatedRawText('adminPanel.feedback.newFeedbackMetric'), props.selectedTab?.label)
            )}
            showModal={true}
            noSpace={true}
            closeModal={closeModalClicked}
            actions={<NsStickyButton stickyButtons={mainButtons} isLoading={isLoading} />}>

            {!!selectedfeedbackTypeMetric && <ApBaseMapActionButtons actionButtons={headerButtons}
                isLoading={isLoading} />
            }
            <div className="p-3">
                <ApLanguageTabs languageChanged={languageChangedHandler}
                    selectedTabId={selectedLanguage?.id} />

                {!isEmpty(multiLanguageFeedbackTypeMetric) &&
                    <TabDataTables key={selectedLanguage.id}
                        demandServices={demandServices}
                        feedbackTypeMetricTypeId={feedbackTypeMetricTypeId}
                        stateHandler={stateHandler}
                        selectedfeedbackTypeMetric={selectedfeedbackTypeMetric}
                        data={[...multiLanguageFeedbackTypeMetric]}
                        showValidationDate={showValidationDate}
                        selectedLanguage={selectedLanguage} />
                }
            </div>
            {showFeedbackTypeMetricModal &&
                <NsMessageClassModal show={true}
                    onClose={(confirm) => deleteFeedbackTypeMetric(confirm)}
                    messageItems={[
                        {
                            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), '')}</>,
                            messageType: Enumerations.messageType.question
                        }]} />
            }

            {messageClassModal}
        </NsModal >
    );
}

function TabDataTables(props) {
    const { stateHandler, data, showValidationDate, selectedLanguage, feedbackTypeMetricTypeId, selectedfeedbackTypeMetric, demandServices } = props;
    let feedbackTypeMetric = ContentHelper.getSpecificLanguageContent(data, selectedLanguage.id);

    return (
        <div className='d-flex flex-column'>
            <div className="d-flex mb-2 align-items-end justify-content-between w-100 flew-row" >
                {feedbackTypeMetricTypeId == Enumerations.feedbackMetricTypes.demandService
                    ? <NsAutoComplete items={demandServices}
                        value={feedbackTypeMetric?.content.demandedServiceType}
                        disabled={!!selectedfeedbackTypeMetric}
                        onChange={(value) => stateHandler(data, { demandedServiceType: value }, selectedLanguage.id)} />
                    : <NsTextField label={getTranslatedRawText('adminPanel.dataGridColumn.title')}
                        required
                        valueChanged={(value) => stateHandler(data, { title: value }, selectedLanguage.id)}
                        value={feedbackTypeMetric?.content.title || null}
                        showValidationDate={showValidationDate}
                        className='w-100' />
                }
                <div className='ps-2 d-flex align-items-end'>
                    <NsSwitch label='adminPanel.slider.active'
                        checked={feedbackTypeMetric?.content.isActive || false}
                        disabled={!selectedLanguage.isDefault}
                        onChange={() => stateHandler(data, { isActive: !feedbackTypeMetric?.content.isActive }, selectedLanguage.id)} />
                </div>
            </div>
        </div>
    )
}

export default React.memo(ApFeedbackTypeMetricModal);