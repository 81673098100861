import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { GetSelectedStoreOrdersForConfirmationService } from '../../../services/ProfileSetting/StoreOrderService';
import { createStoreConfirmationModalColumns } from '../contents/userOrder/stores/bases/UpBaseStoreOrderHelper';
import { GetCreditService, PaymentByCreditService } from '../../../services/ProfileSetting/UserBalanceService';
import { GetSelectedOrdersForConfirmationService } from '../../../services/ProfileSetting/UserOrderService';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../utilities/CultureHelper';
import { PaymentByCreditStoreOrderService } from '../../../services/product/StoreProductService';
import { beginCheckoutEvent, purchaseEvent } from '../../../utilities/GoogleTagManagerHelper';
import NsDataGridWithServiceCall from '../../../components/grids/NsDataGridWithServiceCall';
import { PostPaymentForOrdersService, PostPaymentForStoreProductOrdersService } from '../../../services/payments/PaymentService';
import { miniCartActionCreators } from '../../../redux/reducers/MiniCartReducer';
import { getConfigObjectByCulture } from '../../../utilities/ConfigHelper';
import PaymentOfficialInvoiceModal from './PaymentOfficialInvoiceModal';
import NsStickyButton from '../../../components/buttons/NsStickyButton';
import ProfileOfficialInvoiceModal from './ProfileOfficialInvoiceModal';
import CurrentUserHelper from '../../../utilities/CurrentUserHelper';
import OfficialInvoiceRulesModal from './OfficialInvoiceRulesModal';
import NsSkeleton from '../../../components/skeletons/NsSkeleton';
import NsTypography from '../../../components/text/NsTypography';
import { isEmpty } from '../../../utilities/ValidationHelper';
import StorageHelper from '../../../utilities/StorageHelper';
import NsButton from '../../../components/buttons/NsButton';
import CommonHelper from '../../../utilities/CommonHelper';
import NsModal from '../../../components/modals/NsModal';
import Enumerations from '../../../configs/Enumerations';
import NsMenu from '../../../components/menu/NsMenu';
import Links from '../../../configs/links/AllLinks';
import Config from '../../../configs/Config';

class OrderCartConfirmationModal extends React.Component {

    constructor(props) {
        super(props);
        this.isOfficialInvoiceEnabled = Config.hasOfficialInvoiceIssue ? CurrentUserHelper.getIsOfficialInvoiceEnabled() : true;
        this.showPaymentOfficialInvoiceModal = this.isOfficialInvoiceEnabled ? false : !StorageHelper.getFromStorage(Enumerations.storage.dontShowPaymentOfficialInvoiceModal);
        this.state = {
            columns: this.props.isStore
                ? createStoreConfirmationModalColumns()
                : [
                    {
                        field: 'productName',
                        headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
                        width: 160,
                        renderCell: (params) => {
                            return (
                                <span data-toggle="tooltip" data-placement="right" className='text-truncate' title={params.row.productName}>{params.row.productName}</span>
                            );
                        },
                    },
                    {
                        field: 'createDate',
                        headerName: getTranslatedRawText('profile.dataGridColumns.submitDate'),
                        width: 115,
                        dataType: Enumerations.gridType.dateTime,
                    },
                    {
                        field: 'turnaroundType',
                        headerName: getTranslatedRawText('profile.dataGridColumns.turnaround'),
                        width: 115,
                        dataType: Enumerations.gridType.turnaround,
                    },
                    {
                        field: 'totalPrice',
                        headerName: getTranslatedRawText('profile.dataGridColumns.taxable'),
                        width: 270,
                        dataType: Enumerations.gridType.price,
                    },
                    {
                        field: 'vatPrice',
                        headerName: getTranslatedRawText('profile.dataGridColumns.vat'),
                        dataType: Enumerations.gridType.price,
                    },
                    {
                        field: 'totalPriceWithVat',
                        headerName: getTranslatedRawText('profile.dataGridColumns.includingVat'),
                        width: 270,
                        dataType: Enumerations.gridType.price,
                    },
                ],
            totalPrice: 0,
            totalVatPrice: 0,
            totalDiscountPrice: 0,
            totalPriceIncludedVat: 0,
            isLoadingButtons: true,
            rows: [],
            credit: 0,
            isShowVatRulesModal: false,
            resultMessageClass: (<></>),
            isRemainingLimitDetails: false,
            showProfileInformationModal: false,
            showPaymentOfficialInvoiceModal: false,
            isOnlinePaymentClicked: false
        };
    }

    closeModal = (refresh, resultMessageClass) => CommonHelper.safeFunctionCall(this.props.onClose, !!refresh, resultMessageClass, !!this.props.isStore);

    closeVatRulesModal = () => this.setState({ isShowVatRulesModal: false })

    remainingLimitDetailsHandler = () => this.setState({ isRemainingLimitDetails: !this.state.isRemainingLimitDetails });

    toggleVatRules = () => this.setState({ isShowVatRulesModal: !this.state.isShowVatRulesModal });

    togglePaymentOfficialInvoiceModal = (closeWithPayment) => {
        this.setState({ showPaymentOfficialInvoiceModal: !this.state.showPaymentOfficialInvoiceModal });
        !!closeWithPayment
            ? this.state.isOnlinePaymentClicked
                ? this.onlinePaymentClicked()
                : this.creditPaymentClicked()
            : null;
    }

    checkTogglePaymentOfficialInvoiceModal = (isOnlinePaymentClicked) => {
        if (this.showPaymentOfficialInvoiceModal)
            this.setState({ isOnlinePaymentClicked, showPaymentOfficialInvoiceModal: true });
        else
            !!isOnlinePaymentClicked
                ? this.onlinePaymentClicked()
                : this.creditPaymentClicked();
    }

    onlinePaymentClicked = () => {
        const selectedOrderIds = this.state.rows.map(item => item.id);
        beginCheckoutEvent(this.state.rows, Enumerations.googleTagManagerListId.cartConfirmationModal)
        this.setState({ isLoadingButtons: true },
            this.props.isStore
                ? PostPaymentForStoreProductOrdersService(this.props.isNotAppliedStoreOrders ? { orderIds: this.props.selectedRowIds } : { cartIds: this.props.selectedRowIds }, this.postPaymentForOrdersCallback)
                : PostPaymentForOrdersService({ orderIds: selectedOrderIds }, this.postPaymentForOrdersCallback)
        );
    }

    postPaymentForOrdersCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError && !!resultData) {
            this.setState({ showModal: false },
                this.props.history.push(CommonHelper.stringFormat(
                    getUrlWithCurrentCulture(Links.view.main.userPayment, Links.view.main.userPayment.readyToPay), resultData))
            );
            CommonHelper.safeFunctionCall(this.props.onClose);
        }
        else
            this.setState({ isLoadingButtons: false, resultMessageClass });
    }

    creditPaymentClicked = () => {
        beginCheckoutEvent(this.state.rows, Enumerations.googleTagManagerListId.cartConfirmationModal)
        this.setState({ isLoadingButtons: true });
        this.props.isStore
            ? PaymentByCreditStoreOrderService(this.props.isNotAppliedStoreOrders ? { orderIds: this.props.selectedRowIds } : { cartIds: this.props.selectedRowIds }, this.paymentByCreditCallback)
            : PaymentByCreditService(this.props.selectedRowIds, this.paymentByCreditCallback);
    }

    paymentByCreditCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoadingButtons: false });
        if (!result?.data?.hasError) {
            this.props.getTotalPriceAndCount();
            CommonHelper.safeFunctionCall(this.props.refreshUserAccount);
            this.closeModal(true, resultMessageClass);
            purchaseEvent({ products: this.state.rows, amount: this.state.totalPrice, trackingNo: Math.floor(Math.random() * 100000000) }, Enumerations.googleTagManagerListId.credit)
        } else
            this.setState({ resultMessageClass });
    }

    getData = (rows, isLoading) => {
        if (!isEmpty(rows)) {
            let totalPrice = 0, totalVatPrice = 0, totalPriceIncludedVat = 0, totalDiscountPrice = 0;
            rows.map(item => {
                totalPrice = totalPrice + item.totalPrice;
                totalVatPrice = totalVatPrice + item.vatPrice;
                totalDiscountPrice = !isEmpty(item.discount) ? totalDiscountPrice + item.discount : 0;
                totalPriceIncludedVat = totalPriceIncludedVat + item.totalPriceWithVat;
                return true;
            });
            GetCreditService(this.getCreditCallback);
            this.setState({ rows, totalPrice, totalVatPrice, totalDiscountPrice, totalPriceIncludedVat });
        } else
            this.setState({ isLoadingButtons: false });
    }

    getCreditCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoadingButtons: false });
        if (!result?.data?.hasError)
            this.setState({ credit: resultData });
        else
            this.setState({ resultMessageClass });
    }

    createMemoizeStickyButtons = (disabled) => [
        this.props.isStore
            ? CommonHelper.createStickyButtonItem("profile.orderCart.useMyRemainingLimit", 'ns-icon-increase-credit', () => this.checkTogglePaymentOfficialInvoiceModal(false), 'primary', this.state.credit.remainingLimit < this.state.totalPriceIncludedVat)
            : this.state.totalPriceIncludedVat <= this.state.credit.remainingLimit && !this.state.isLoadingButtons && CommonHelper.createStickyButtonItem("profile.orderCart.useMyRemainingLimit", 'ns-icon-increase-credit', () => this.checkTogglePaymentOfficialInvoiceModal(false), 'primary')
        ,
        (!this.props.isStore || CurrentUserHelper.getMobile() == 989372557032) && CommonHelper.createStickyButtonItem("profile.orderCart.onlinePayment", 'ns-icon-payments', () => this.checkTogglePaymentOfficialInvoiceModal(true), this.state.totalPriceIncludedVat <= this.state.remainingLimit ? 'primary-outline' : 'primary'),
        !CommonHelper.isMobileDevice() && CommonHelper.createStickyButtonItem("common.cancel", 'ns-icon-close', () => this.closeModal(false, ''), 'primary-outline'),
    ];

    remainingLimitButton = () => {
        return <div className='page-title'>
            {CommonHelper.currencyFormatByDefaultConfig(this.state.credit.remainingLimit)}
            <span onClick={this.remainingLimitDetailsHandler}
                className={clsx('d-inline cursor-pointer no-white-space', this.state.isRemainingLimitDetails ? 'ns-icon-expand-up' : 'ns-icon-expand-down')}></span>
        </div>
    }

    toggleProfileInformationModal = (resultMessageClass) => {
        this.setState({ showProfileInformationModal: !this.state.showProfileInformationModal });
        !!resultMessageClass && this.setState({ resultMessageClass });
    }

    render() {
        const { credit, totalVatPrice, totalDiscountPrice, totalPriceIncludedVat, columns, showPaymentOfficialInvoiceModal,
            totalPrice, isLoadingButtons, resultMessageClass, showProfileInformationModal, isShowVatRulesModal, isOnlinePaymentClicked } = this.state;
        const { selectedRowIds, isStore, isNotAppliedStoreOrders } = this.props;

        return (
            <NsModal maxWidth="md"
                dialogTitle={getTranslatedRawText("profile.orderCart.modal.confirmation")}
                showModal={true}
                closeModal={this.closeModal}
                actions={<NsStickyButton stickyButtons={this.createMemoizeStickyButtons(!totalPrice)} isLoading={isLoadingButtons} />} >

                <Row className='my-2 d-flex align-items-center'>
                    <Col>
                        <div className='d-flex mb-2 font-size-16 black-color align-items-start align-items-lg-center align-items-md-center align-item-sm-start'>
                            <div className='d-flex'>
                                <NsTypography variant='inherit' className='no-white-space'>{getTranslatedRawText('regularOrder.confirmDetail.totalPrice')}</NsTypography>
                                &nbsp;
                                <NsTypography variant='inherit' className='font-size-14'>{getTranslatedRawText('profile.orderCart.modal.withVat')}:</NsTypography>
                            </div>
                            <div className='page-title ps-2 no-white-space'>
                                {CommonHelper.currencyFormatByDefaultConfig(totalPriceIncludedVat)}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='d-flex font-size-16 black-color mb-2 align-items-center'>
                            {getTranslatedRawText('profile.userInfo.remainingLimit')}:
                            <div className='ps-2 page-title w-50'>
                                {isLoadingButtons
                                    ? <NsSkeleton isVisible={true}
                                        variant={[
                                            Enumerations.skeleton.text,
                                        ]} />
                                    : <NsMenu buttonClassName="text-capitalize text-start p-0"
                                        buttonChildren={this.remainingLimitButton()}>
                                        <div className='p-2'>
                                            <Link to={getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderFinancialReport)} className='text-decoration-none black-color'>
                                                <NsTypography variant='inherit' className='ps-1 font-size-12 fw-bold'>
                                                    {getTranslatedRawText('profile.userInfo.creditor')}:&nbsp;
                                                    {CommonHelper.currencyFormatByDefaultConfig(credit?.creditor)}
                                                </NsTypography>
                                            </Link>
                                            <Link to={getUrlWithCurrentCulture(Links.view.main.userOrder, Links.view.main.userOrder.userOrderFinancialReport)} className='text-decoration-none black-color'>
                                                <NsTypography variant='inherit' className='ps-1 font-size-12 fw-bold'>
                                                    {getTranslatedRawText('profile.userInfo.debtor')}:&nbsp;
                                                    {CommonHelper.currencyFormatByDefaultConfig(credit?.debtor)}
                                                </NsTypography>
                                            </Link>
                                            <NsTypography variant='inherit' className='ps-1 font-size-12 fw-bold'>
                                                {getTranslatedRawText('profile.userInfo.grantedCredit')}:&nbsp;
                                                {CommonHelper.currencyFormatByDefaultConfig(credit?.grantedCredit)}
                                            </NsTypography>
                                            <NsTypography variant='inherit' className='ps-1 font-size-12 fw-bold'>
                                                {getTranslatedRawText('profile.userInfo.queue')}:&nbsp;
                                                {CommonHelper.currencyFormatByDefaultConfig(credit?.queue)}
                                            </NsTypography>
                                        </div>
                                    </NsMenu>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className='mb-2 primary-paragraph'>
                    {getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat')}:
                    <span> {CommonHelper.currencyFormatByDefaultConfig(totalPrice)}</span>
                </div>
                <div className='mb-2 primary-paragraph'>
                    {getTranslatedRawText('profile.orderCart.modal.totalDiscountPrice')}:
                    <span> {totalDiscountPrice}</span>
                </div>
                <div className='mb-2 primary-paragraph d-flex'>
                    <div>{getTranslatedRawText('profile.dataGridColumns.vat')}:
                        <span> {CommonHelper.currencyFormatByDefaultConfig(totalVatPrice)}</span>
                    </div>
                    <i className='ns-icon-question fs-5 cursor-pointer' onClick={this.toggleVatRules} />
                </div>

                <NsDataGridWithServiceCall service={isStore ? GetSelectedStoreOrdersForConfirmationService : GetSelectedOrdersForConfirmationService}
                    parameters={{
                        orderIds: isStore ? isNotAppliedStoreOrders ? selectedRowIds : [] : selectedRowIds,
                        cartIds: isStore ? isNotAppliedStoreOrders ? [] : selectedRowIds : [],
                        orderProductType: isStore ? Enumerations.orderProductType.store : Enumerations.orderProductType.sharedSheet
                    }}
                    columnDefs={columns}
                    getData={this.getData} />

                {!this.isOfficialInvoiceEnabled &&
                    <div className='border rounded-3 p-2 mt-3'>
                        <div className='d-flex flex-wrap align-items-center gap-2 mb-2'>
                            <i className='ns-icon-information fs-4' />
                            <NsTypography variant='inherit' className='font-size-12'>{getTranslatedRawText('profile.personal.enableOfficialInvoceTitle')}</NsTypography>
                            <NsButton label='profile.actionButton.profileInfo'
                                startIcon='ns-icon-profile-information'
                                size='small'
                                className='secondary-outline'
                                onClick={() => this.toggleProfileInformationModal(false)}
                            />
                        </div>
                        <NsTypography variant='inherit' className='text-justify font-size-12'>{getTranslatedRawText('common.messages.userInformationIsNotCompeleted')}</NsTypography>
                    </div>
                }

                {showProfileInformationModal &&
                    <ProfileOfficialInvoiceModal closeModal={this.toggleProfileInformationModal} />
                }

                {isShowVatRulesModal &&
                    <OfficialInvoiceRulesModal showModal={isShowVatRulesModal}
                        closeModal={this.closeVatRulesModal} />
                }
                {showPaymentOfficialInvoiceModal &&
                    <PaymentOfficialInvoiceModal isOnlinePayment={isOnlinePaymentClicked}
                        closeModal={this.togglePaymentOfficialInvoiceModal} />
                }

                {resultMessageClass}
            </NsModal >
        )
    }
}

function VatCertificate({ vat }) {

    const hasVat = vat > 0;

    const downloadVatCertificate = () => {

    }

    return <div>
        {getConfigObjectByCulture(Config.vatHistory).vatCertificateDescription}
        <div className='text-center'>
            {false && <NsButton className='secondary w-auto my-1'
                size='small'
                label='profile.orderCart.modal.vatCertificate'
                onClick={downloadVatCertificate} />}
        </div>
    </div>
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(miniCartActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(withRouter(OrderCartConfirmationModal));
