import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NsAdvanceSearch from '../../../../../../components/advanceSearch/NsAdvanceSearch';
import NsButton from '../../../../../../components/buttons/NsButton';
import ConstantsWithTranslation from '../../../../../../configs/ConstantsWithTranslation';
import Enumerations from '../../../../../../configs/Enumerations';
import UserClaimEnumerations from '../../../../../../configs/UserClaimEnumerations';
import { GetProductsCirculationsService } from "../../../../../../services/product/ProductService";
import { hasAccess } from '../../../../../../utilities/ClaimHelper';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../../utilities/ValidationHelper';
import ApBaseSearchButtons from '../../../../bases/ApBaseSearchButtons';
import ApBaseUserSelectModal from '../../../../bases/ApBaseUserSelectModal';
import { categoryActionCreators } from '../../../../../../redux/reducers/CategoryReducer';
import DateHelper from '../../../../../../utilities/DateHelper';

const ApDailyQueueOfEstimateTimeSearch = (props) => {

    const [showUserSelectModal, setShowUserSelectModal] = useState(false)
    const [user, setUser] = useState(props.customerDetails?.phoneNumber || '');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductIds, setSelectedProductIds] = useState(null);
    const [estimateReadyToDeliverTimeFrom, setEstimateReadyToDeliverTimeFrom] = useState(DateHelper.changeDayPartofDateTime(-6).setHours(0, 0, 0, 0));
    const [estimateReadyToDeliverTimeTo, setEstimateReadyToDeliverTimeTo] = useState(DateHelper.changeDayPartofDateTime(3).setHours(23, 59, 59, 59));
    const [selectedUser, setSelectedUser] = useState(props.customerDetails || '');
    const [products, setProducts] = useState([]);
    const [calculationBasis, setCalculationBasis] = useState(ConstantsWithTranslation.getOrderCalculationBasesTypeOptions()[0]);
    const [productCategory, setProductCategory] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessageClass, setResultMessageClass] = useState('');

    useEffect(() => {
        handleCategories();
        setIsLoading(true);
    }, []);

    useEffect(() => {
        !isEmpty(selectedUser) && searchClicked();
    }, [selectedUser])

    useEffect(() => {
        !isEmpty(props.selectedTab.id) && searchClicked();
    }, [props.selectedTab.id])

    useEffect(() => {
        !isEmpty(selectedProduct)
            ? setSelectedProductIds(selectedProduct.circulations.map(circulation => circulation.key))
            : setSelectedProductIds([]);
    }, [selectedProduct])

    const categories = useSelector(state => {
        return {
            categories: state.categories.categories
        }
    });

    const dispatch = useDispatch();
    const handleCategories = () => dispatch(categoryActionCreators.getCategories());

    const getProductsCirculationsCallback = (resultData, result) => {
        setIsLoading(false);
        if (!result?.data?.hasError) {
            setProducts(resultData);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        GetProductsCirculationsService(null, getProductsCirculationsCallback);
    }, [])

    const usersModalButtonClicked = () => {
        setShowUserSelectModal(true);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === Enumerations.keyboard.enter)
            CommonHelper.safeFunctionCall(searchClicked);
    }

    const showMoreClicked = () => {
        setShowMore(!showMore);
    }

    const basicSearchOptionsInMobile = [
        !props.customerDetails?.phoneNumber && hasAccess([UserClaimEnumerations.getApCustomers]) && {
            type: Enumerations.advanceSearchFieldType.textField,
            label: getTranslatedRawText('components.advanceSearch.user'),
            fieldName: 'user',
            value: user,
            disabled: true,
            onChange: (value) => setUser(value),
            endAdornment: !props.customerDetails?.phoneNumber ? <NsButton className='light-circle'
                loading={isLoading}
                onClick={usersModalButtonClicked}
                startIcon="ns-icon-more" /> : null
        },
    ];

    const basicSearchOptions = [
        ...basicSearchOptionsInMobile,
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('adminPanel.shippings.calculationBases'),
            fieldName: 'calculationBasis',
            value: calculationBasis,
            items: ConstantsWithTranslation.getOrderCalculationBasesTypeOptions(),
            onChange: (value) => setCalculationBasis(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTimeFrom'),
            fieldName: 'estimateReadyToDeliverTimeFrom',
            availableFuture: true,
            value: estimateReadyToDeliverTimeFrom,
            onChange: (value) => setEstimateReadyToDeliverTimeFrom(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.dateTimePicker,
            label: getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTimeTo'),
            fieldName: 'estimateReadyToDeliverTimeTo',
            value: estimateReadyToDeliverTimeTo,
            availableFuture: true,
            onChange: (value) => setEstimateReadyToDeliverTimeTo(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('profile.allOrders.productCategory'),
            fieldName: 'productCategory',
            value: productCategory,
            items: categories.categories || [],
            onChange: (value) => setProductCategory(value)
        },
        {
            type: Enumerations.advanceSearchFieldType.autoComplete,
            label: getTranslatedRawText('components.advanceSearch.product'),
            fieldName: 'selectedProduct',
            value: selectedProduct,
            items: !isEmpty(products) ? products : [],
            onChange: (value) => setSelectedProduct(value),
        },
    ];

    const searchClicked = () => {
        CommonHelper.safeFunctionCall(props.setSearchData, searchValues());
    }

    const userSelected = (user) => {
        setSelectedUser(user);
        setUser(user.phoneNumber);
        setShowUserSelectModal(false);
    }

    const clearButtonClicked = () => {
        setProductCategory([]);
        setUser('');
        setSelectedUser('');
        setSelectedProduct([]);
        setSelectedProductIds([]);
        setCalculationBasis(ConstantsWithTranslation.getOrderCalculationBasesTypeOptions()[0]);
        setEstimateReadyToDeliverTimeFrom(null);
        setEstimateReadyToDeliverTimeTo(null);
        CommonHelper.safeFunctionCall(props?.tabChanged, 0, ConstantsWithTranslation.sharedSheetOrderEstimatedQueueTurnaround()[0]);
        CommonHelper.safeFunctionCall(props.setSearchData, { turnaroundType: searchValues().turnaroundType });
    }

    const searchValues = () => {
        return {
            user,
            userId: selectedUser?.userId,
            calculationBasis,
            productCategory,
            estimateReadyToDeliverTimeFrom,
            estimateReadyToDeliverTimeTo,
            selectedProduct,
            productId: selectedProductIds,
        }
    };

    return (
        <>
            <div onKeyDown={handleKeyDown}>
                <NsAdvanceSearch searchOptions={CommonHelper.isMobileDevice() ? showMore ? basicSearchOptions : basicSearchOptionsInMobile : basicSearchOptions}
                    searchValues={searchValues()} mdCol={2} smCol={4} />
                <ApBaseSearchButtons searchClicked={searchClicked}
                    clearButtonClicked={clearButtonClicked}
                    isLoading={props.isLoading}
                    showMoreHandler={CommonHelper.isMobileDevice() && showMoreClicked}
                    showMore={showMore} />
            </div>
            {showUserSelectModal &&
                <ApBaseUserSelectModal showModal={showUserSelectModal}
                    userSelected={userSelected}
                    closeModal={() => setShowUserSelectModal(false)} />
            }

            {resultMessageClass}
        </>
    )

}
export default React.memo(ApDailyQueueOfEstimateTimeSearch);