import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import NsDataGridWithServiceCall from '../../../../../../components/grids/NsDataGridWithServiceCall';
import { DeleteOrderService } from '../../../../../../services/ProfileSetting/UserOrderService';
import NsMessageClassModal from '../../../../../../components/modals/NsMessageClassModal';
import { getTranslatedRawText } from '../../../../../../utilities/CultureHelper';
import { createGroupSheetOrderColumn } from './UpBaseGroupSheetOrderHelper';
import { isBusinessPartner } from '../../../../../../utilities/ClaimHelper';
import NsButton from '../../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../../configs/Enumerations';

class UpBaseGroupSheetOrdersGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOrderId: null,
            selectedProductName: '',
            showDeleteOrderMessageModal: false,
            columns: createGroupSheetOrderColumn(props?.hasInvoiceNumber, props?.showConfirmedTime, props?.showSubmitTime, props?.showTotalQuantity,
                props?.detailViewClicked, this.deleteOrderClicked, props?.changeStateFromFailToPreparing, props?.hideEstimateReadyToDeliverTime) || [],
            resultMessageClass: (<></>),
        };
    }

    createColumnHeader = (hasInvoiceNumber, showConfirmedTime, showSubmitTime, showTotalQuantity, hideEstimateReadyToDeliverTime) => [
        {
            field: 'invoiceDetail',
            headerName: getTranslatedRawText('profile.dataGridColumns.action'),
            width: 120,
            lockPosition: true,
            resizable: false,
            cellRenderer: "customRowRenderer",
            cellRendererParams: {
                customElementRender: (props) => {
                    return props.lastStatusType === Enumerations.sharedSheetOrderStateType.prepareFail && <>
                        <NsButton className='secondary-text '
                            wrapperClassName={props.paymentStateType === Enumerations.paymentStateType.success && 'invisible'}
                            startIcon='ns-icon-retry cursor-pointer'
                            onClick={() => CommonHelper.safeFunctionCall(this.props.changeStateFromFailToPreparing, props.id)} />
                        <NsButton className='secondary-text '
                            wrapperClassName={props.paymentStateType === Enumerations.paymentStateType.success && 'invisible'}
                            startIcon='ns-icon-delete cursor-pointer'
                            onClick={() => this.deleteOrderClicked(props)} />
                    </>
                },
                hasDetail: true,
                detailClicked: (id) => CommonHelper.safeFunctionCall(this.props.detailViewClicked, id),
            }
        },

        !!showSubmitTime ? {
            field: 'submitTime',
            headerName: getTranslatedRawText('profile.dataGridColumns.submitDate'),
            width: 115,
            maxWidth: 130,
            dataType: Enumerations.gridType.dateTime,
        } : { hide: true },

        !!hasInvoiceNumber ? {
            field: 'nasInvoiceId',
            dataType: Enumerations.gridType.number,
            headerName: getTranslatedRawText('profile.dataGridColumns.invoiceNumber'),
            width: 135,
        } : { hide: true },
        !hideEstimateReadyToDeliverTime ? {
            field: 'estimateReadyToDeliverTime',
            headerName: getTranslatedRawText('adminPanel.dataGridColumn.estimateReadyToDeliverTime'),
            width: 250,
            dataType: Enumerations.gridType.dateTime,
        } : { hide: true },
        {
            field: 'priceExclusiveOfVat',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithoutVat'),
            width: 150,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'priceInclusiveOfVat',
            headerName: getTranslatedRawText('profile.orderCart.modal.TotalPriceWithVat'),
            width: 150,
            dataType: Enumerations.gridType.price,
        },
        {
            field: 'productName',
            headerName: getTranslatedRawText('profile.dataGridColumns.productName'),
            width: 210,
            renderCell: (params) => {
                return (
                    <span data-toggle="tooltip" data-placement="right" className='text-truncate' title={params.row.productName}>{params.row.productName}</span>
                );
            },
        },
        {
            field: 'circulation',
            headerName: getTranslatedRawText('profile.dataGridColumns.circulation'),
            width: 105,
            dataType: Enumerations.gridType.circulation
        },

        !!showConfirmedTime ? {
            field: 'confirmedTime',
            headerName: getTranslatedRawText('profile.dataGridColumns.confirmedDate'),
            width: 140,
            dataType: Enumerations.gridType.dateTime,
        } : { hide: true },

        {
            field: 'twoSidePrintingType',
            headerName: getTranslatedRawText('profile.dataGridColumns.printedSide'),
            width: 115,
            dataType: Enumerations.gridType.printSide,
        },
        {
            field: 'turnaroundType',
            headerName: getTranslatedRawText('profile.dataGridColumns.turnaround'),
            width: 110,
            dataType: Enumerations.gridType.turnaround,
        },
        !!showTotalQuantity ? {
            field: 'totalQuantity',
            dataType: Enumerations.gridType.number,
            headerName: getTranslatedRawText('profile.dataGridColumns.totalQuantity'),
            width: 125
        } : { hide: true },
        isBusinessPartner() &&
        {
            field: 'businessPartnerCustomerIdentifier',
            headerName: getTranslatedRawText('profile.dataGridColumns.businessPartnerCustomerIdentifier'),
            width: 160,
        },
    ];

    setMessageItems = () => [
        {
            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'), this.state.selectedProductName)}</>,
            messageType: Enumerations.messageType.question
        }];

    deleteOrderClicked = (selectedOrder) =>
        this.setState({ selectedProductName: selectedOrder.productName, selectedOrderId: selectedOrder.id, showDeleteOrderMessageModal: true });

    deleteOrder = (confirm) => {
        !!confirm && DeleteOrderService([this.state.selectedOrderId], this.deleteOrderCallback, true);
        this.setState({ showDeleteOrderMessageModal: false, selectedOrderId: null });
    }

    deleteOrderCallback = (resultData, result, resultMessageClass) => {
        if (!result?.data?.hasError) {
            CommonHelper.safeFunctionCall(this.props.tabChanged, this.state.selectedTabId);
        }
        this.setState({ resultMessageClass });
    }

    tabChanged = (newValue, selectedTab) => {
        newValue !== this.state.selectedTabIndex && this.setState({ selectedTabIndex: newValue, selectedTabId: selectedTab.id },
            () => {
                CommonHelper.safeFunctionCall(this.props.tabChanged, selectedTab.id, true, newValue, selectedTab)
                this.props.history.push(selectedTab.url)
            });
    }

    getData = (rows, isLoading) => CommonHelper.safeFunctionCall(this.props.getOrdersFromServer, rows, isLoading, this.state.selectedTabId);

    render() {
        const { checkboxSelection, hasPagination, service, parameters, tabs, callServiceDate, selectedRowIds, hasCustomColumnOrder, gridId } = this.props;
        const { showDeleteOrderMessageModal, resultMessageClass } = this.state;
        return (

            <div className='w-100'>
                <NsDataGridWithServiceCall key={checkboxSelection || gridId} columnDefs={this.state.columns}
                    hasPagination={hasPagination}
                    service={service}
                    parameters={{ ...parameters }}
                    callServiceDate={callServiceDate}
                    hasCheckbox={!!checkboxSelection}
                    selectedRowIds={selectedRowIds}
                    gridId={gridId}
                    hasCustomColumnOrder={hasCustomColumnOrder}
                    selectedRows={this.props.selectionChanged}
                    getData={this.getData} />

                {!!showDeleteOrderMessageModal &&
                    <NsMessageClassModal show={true}
                        onClose={this.deleteOrder}
                        messageItems={this.setMessageItems()} />
                }
                {resultMessageClass}
            </div>
        );
    }
}

export default withRouter(UpBaseGroupSheetOrdersGrid);