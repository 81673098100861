import { GetCountryService } from './../../services/common/CommonService';

const GETCOUNTRIES = 'GET_Country';
const initialState = { countries: [] };

export const countryActionCreators = {
    getCountries: () => async (dispatch, getState) => {
        GetCountryService((resultData, result, messageClassModal) => {
            if (result?.data?.hasError)
                return;
            dispatch({ type: GETCOUNTRIES, countries: resultData });
        });
    }
};

export const reducer = (state = initialState, action) => {
    
    switch (action.type) {
        case GETCOUNTRIES:
            if (state.countries.length === 0)
                return {
                    ...state,
                    countries: action.countries
                }
            return state;

        default:
            return state;
    }
};


