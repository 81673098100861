import CommonHelper from "../../utilities/CommonHelper";

const toggleSidebar = 'TOGGLE_SIDEBAR';
const selectedItem = 'SELECTED_ITEM';
const initialState = { isOpen: CommonHelper.isMobileDevice() ? false : true, selectedItemId: null };

export const adminSidebarActionCreators = {
  toggleAdminSidebar: () => ({ type: toggleSidebar }),
  selectedItemIdChanged: (id) => ({ type: selectedItem, id }),
};

const AdminSidebarReducer = (state = initialState, action) => {

  if (action.type === toggleSidebar) {
    return { ...state, isOpen: !state.isOpen };
  }
  if (action.type === selectedItem) {
    return { ...state, selectedItemId: action.id };
  }

  return state;
};

export default AdminSidebarReducer;
