import clsx from 'clsx';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import NsButton from '../../../../components/buttons/NsButton';
import NsCard from '../../../../components/cards/NsCard';
import NsImage from '../../../../components/images/NsImage';
import Links from '../../../../configs/links/AllLinks';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText, getUrlWithCurrentCulture } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import BnBasesPostSpecification from './BnBasesPostSpecification';

class BnBasesPostCard extends Component {

	isBlog = CommonHelper.isBlog(window.location.href);

	categoryClicked = (event, category) => {
		event.event.preventDefault();
		this.isBlog
			? this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogCategoryPosts), category.key, category.value))
			: this.props.history.push(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsCategoryPosts), category.key, category.value));
	}

	render() {
		const { post, className } = this.props;
		return (
			<div className={clsx("d-inline d-md-inline-block w-100 h-100", className)} >
				<NsCard className='h-100' >
					<Link to={this.isBlog
						? CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.blog, Links.view.main.blog.blogPostDetail), post.id, post.title)
						: CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.view.main.news, Links.view.main.news.newsPostDetail), post.id, post.title)}>
						<div className='position-relative'>
							<NsImage src={CommonHelper.createImageUrl(post?.fileUrl)}
								imgAlt={CommonHelper.stringFormat(getTranslatedRawText("components.imagesAlt.blogImage"), post.title)}
								className="w-100" />
							<div className='d-flex flex-wrap gap-1 position-absolute top-0 m-2'>
								<ContentCategoryButton categories={post.pageCategories}
									categoryClicked={this.categoryClicked} />
							</div>
						</div>
					</Link >

					<div className=' p-2' >
						<span className='font-size-14 fw-bold'>
							{post.title}
						</span>
						<BnBasesPostSpecification createAt={post.createAt}
							createByUser={post.createByUser}
							createByUserId={post.userId} />
						<span className='font-size-14 truncate-text'>
							{post.summary}
						</span>

					</div >
				</NsCard >
			</div >
		);
	}
}

export function ContentCategoryButton(props) {
	const { categories } = props;
	return isEmpty(categories)
		? null
		: categories.map((item, index) =>
			<NsButton key={index}
				className='primary'
				label={item?.value}
				size='small'
				onClick={(event) => props.categoryClicked(event, item)}
			/>
		)
}

export default withRouter(BnBasesPostCard);