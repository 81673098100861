import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import LoginLayout from './LoginLayout';
import NsMetaTag from '../../../components/metaTags/NsMetaTag'
import Footer from '../main/footers/Footer';
import NsGoogleTagManager from '../../../components/googleTagManager/NsGoogleTagManager'
import UrlHelper from '../../../utilities/UrlHelper';
import CommonHelper from '../../../utilities/CommonHelper';

class LoginLayoutRoute extends React.Component {

    state = {
        isMobileDevice:  CommonHelper.isMobileDevice(),
    }

    UNSAFE_componentWillMount = () => UrlHelper.addWwwToRoute();

    componentDidMount() {
        this.setWindowSize = () => this.setState({ isMobileDevice: CommonHelper.isMobileDevice() })
        window.addEventListener('resize', this.setWindowSize)
    }

    componentWillUnmount = () => window.removeEventListener('resize', this.setWindowSize);

    render() {
        let { component, locale, ...rest } = this.props;
        const Component = component;

        return <Route {...rest} render={matchProps => (
            <Container fluid>
                <NsMetaTag />
                <LoginLayout {...rest}>
                    <NsGoogleTagManager />
                    <Component {...matchProps} />
                </LoginLayout>
                <Footer isShowMiddleFooter={false} />
            </Container>
        )} />
    }
};

const mapStateToProps = state => ({ locale: state.i18n.locale });

export default connect(mapStateToProps)(LoginLayoutRoute);