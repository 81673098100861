import React from 'react';

import NsStepperWithActionButtons from '../../../../../components/stepper/NsStepperWithActionButtons';
import RegularOrderRouteHelper from '../../bases/RegularOrderRouteHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import Enumerations from '../../../../../configs/Enumerations';
import OrderHelper from '../../../../../utilities/OrderHelper';
import MainContentWrapper from '../MainContentWrapper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import Config from '../../../../../configs/Config';

class ChoosingFileWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ChoosingFileCurrentStep: props.ChoosingFileCurrentStep,
            isCompeletedCurrentStep: false,
            printedSide: '',
            stepList: [],
        };
    }

    componentDidMount = () => this.setState({ printedSide: OrderHelper.getPrintedSide(this.props.routeId).key }
        , () => this.createStepList());

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.choosingFileActiveStep !== this.state.ChoosingFileCurrentStep) {
            this.setState({ ChoosingFileCurrentStep: newProps.ChoosingFileCurrentStep });
            this.createStepList();
        }
    }

    createStepList = () => {
        let stepList = [];
        let files = OrderHelper.getFiles(this.props.routeId);
        files.map((file, index) =>
            stepList.push(
                {
                    title: file.title,
                    buttons: this.createStepButtons(file, index)
                }
            ));
        this.setState({ stepList });
    }

    createStepButtons = (file, index) => {
        let orderSizesAndPrices = OrderHelper.getFileSizeAndOrderPrices(this.props.routeId);
        let buttons = [];
        if (index === 0)
            buttons.push(
                {
                    visible: true,
                    className: 'primary mx-auto',
                    label: 'common.label.next',
                    icon: CommonHelper.getNextIconByCulture(),
                    disabled: this.state.printedSide === Enumerations.printedSide.oneSide
                        ? !file.frontSideFileInfo || !orderSizesAndPrices
                        : (!file.frontSideFileInfo || !file.backSideFileInfo) || !orderSizesAndPrices,
                    type: Enumerations.stepperButtonType.next,
                    validation: this.stepprButtonValidation,
                    event: this.stepperButtonClicked
                },
            );
        else
            buttons.push(
                {
                    visible: true,
                    className: 'primary-outline mx-auto',
                    label: 'common.label.back',
                    icon: CommonHelper.getBackIconByCulture(),
                    disabled: false,
                    type: Enumerations.stepperButtonType.previous,
                    validation: this.stepprButtonValidation,
                    event: this.stepperButtonClicked
                },
                {
                    visible: true,
                    className: 'primary ',
                    label: 'common.label.next',
                    icon: CommonHelper.getNextIconByCulture(),
                    disabled: Config.mandatoryTwoSideOfFilm
                        ? OrderHelper.getPrintedSide(this.props.routeId)?.key === Enumerations.printedSide.twoSide
                            ? (isEmpty(file.frontSideFileInfo) || isEmpty(file.backSideFileInfo))
                            : isEmpty(file.frontSideFileInfo)
                        : (isEmpty(file.frontSideFileInfo) && isEmpty(file.backSideFileInfo)),
                    type: Enumerations.stepperButtonType.next,
                    validation: this.stepprButtonValidation,
                    event: this.stepperButtonClicked
                },
            );
        return buttons;
    }

    stepprButtonValidation = (buttonType, currentStep) => {
        switch (buttonType) {
            case Enumerations.stepperButtonType.previous:
            case Enumerations.stepperButtonType.skip:
            case Enumerations.stepperButtonType.next:
                return this.state.ChoosingFileCurrentStep === currentStep;
            default: return false;
        }
    }

    stepperButtonClicked = (buttonType, newStep) => {
        switch (buttonType) {
            case Enumerations.stepperButtonType.previous:
            case Enumerations.stepperButtonType.skip:
            case Enumerations.stepperButtonType.next:
            default: this.foundNextOrPreviousStep(newStep);
                return;
        }
    }

    foundNextOrPreviousStep = (newStep) => {
        if (newStep > OrderHelper.getFilesLength(this.props.routeId) - 1)
            OrderHelper.setIsCompeteledChoosingFileSteps(this.props.routeId, true);
        RegularOrderRouteHelper.choosingFilesStep(this.props.routeId, this.props.otherProps, newStep);
    }

    render() {
        const { isCompeletedCurrentStep, stepList, ChoosingFileCurrentStep } = this.state;
        const { otherProps, routeId, refreshExtendedServiceSummaryDate, refreshFilePreviewSummaryDate, children, toggleTourDate } = this.props;

        return (
            <MainContentWrapper otherProps={otherProps}
                routeId={routeId}
                toggleTourDate={toggleTourDate}
                mainStepperActiveStep={Enumerations.orderLocation.choosingFile}
                isCompeteledChoosingFileSteps={isCompeletedCurrentStep}
                refreshExtendedServiceSummaryDate={refreshExtendedServiceSummaryDate}
                refreshFilePreviewSummaryDate={refreshFilePreviewSummaryDate} >

                <div className='border border-light-text-color regular-order-detail__header w-100'>
                    <NsStepperWithActionButtons stepList={stepList}
                        orientation={CommonHelper.isMobileDevice() ? 'vertical' : 'horizontal'}
                        activeStep={ChoosingFileCurrentStep}
                        currentStep={ChoosingFileCurrentStep}>

                        {children}

                    </NsStepperWithActionButtons>
                </div>

            </MainContentWrapper >
        );
    }
}

export default ChoosingFileWrapper;