import React from 'react';
import { Col, Row } from 'react-bootstrap';

import NsNumberInputWithPattern from '../../../../../components/inputs/NsNumberInputWithPattern';
import EnumerationTranslationHelper from '../../../../../utilities/EnumerationTranslationHelper';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import NsTextField from '../../../../../components/inputs/NsTextField';
import CommonHelper from '../../../../../utilities/CommonHelper';

class UpBaseBusinessInputs extends React.Component {

    render() {
        const { fieldVisibilityType, xl, countryId, showValidationDate, companyName, license, trn,
            trnHelperText, showValidationTrnDate, trnMask, trnRegex } = this.props;
        const endAdornment = EnumerationTranslationHelper.getCountryNameById(countryId);

        return <Row className="w-100 g-3">
            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType) &&
                <Col xl={xl} lg={6} sm={12} className="mb-3">
                    <NsTextField valueChanged={(value, isValid) => this.props.companyNameChanged(value, isValid)}
                        showValidationDate={showValidationDate}
                        label={getTranslatedRawText("account.signup.companyName")}
                        size="small"
                        required
                        disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.companyNameVisibilityType)}
                        value={companyName}
                        className="w-100" />
                </Col>
            }
            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType) &&
                <Col xl={xl} lg={6} sm={12} className="mb-3">
                    <NsTextField label={EnumerationTranslationHelper.getLicenseNameByCountryId(countryId)}
                        valueChanged={(value, isValid) => this.props.licenceChanged(value, isValid)}
                        showValidationDate={showValidationDate}
                        size="small"
                        required
                        disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.licenceNumberVisibilityType)}
                        value={license}
                        className="w-100" />
                </Col>
            }
            {CommonHelper.isNotHiddenFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType) &&
                <Col xl={xl} lg={6} sm={12} className="mb-3">
                    <NsNumberInputWithPattern label={getTranslatedRawText("account.signup.trn")}
                        valueChanged={(value, isValid) => this.props.trnChanged(value, isValid)}
                        size="small"
                        helperText={trnHelperText}
                        disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(fieldVisibilityType?.trnVisibilityType)}
                        value={trn}
                        showValidationDate={showValidationTrnDate}
                        className="w-100"
                        codeMask={trnMask}
                        endAdornment={endAdornment}
                        regex={trnRegex}
                        escapeWordInPattern='_' />
                </Col>
            }
        </Row>
    }
}

export default UpBaseBusinessInputs;