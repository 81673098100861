import React, { useEffect, useState } from 'react';

import ApBasesDashboardCharts from '../bases/ApBasesDashboardCharts';
import ApBasesDashboardHelper from '../bases/ApBasesDashboardHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import NsCard from '../../../../components/cards/NsCard';

const ApDcbCustomerSpecialPlanChart = (props) => {

    const { isLoading, data, refreshClicked } = props;
    const [datasets, setDatasets] = useState(data);

    useEffect(() => {
        setDatasets(ApBasesDashboardHelper.setCashbackSpecialChartOptions(data));
    }, [data])

    const options = {
        scales: {
            x: {
                display: true,
                grid: {
                    display: false
                }
            },
        },
    }

    return (<NsCard className='p-4 h-100 align-content-between' contentClassName='h-100'>
        {isEmpty(data)
            ? <></>
            : <ApBasesDashboardCharts title='adminPanel.cashback.specialVsCommon'
                type='bar'
                tooltipTitle='adminPanel.dashboard.totalUsersChartDes'
                data={datasets}
                refreshClicked={refreshClicked}
                options={options}
                isLoading={isLoading} />
        }
    </NsCard>
    );
};

export default React.memo(ApDcbCustomerSpecialPlanChart);