import clsx from 'clsx';
import React, { Component } from 'react';
import { Button, ClickAwayListener, Paper, Popper } from '@mui/material';
import './../../assets/sass/components/menu/NsMenu.scss';
import NsButton from '../buttons/NsButton';
import { LoadingButton } from '@mui/lab';

class NsMenu extends Component {

    constructor(props) {
        super(props);
        this.anchorRef = React.createRef(null);
        this.state = {
            open: false
        }
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.closeMenuDate !== this.props.closeMenuDate)
            this.closeMenuClicked();
    }

    openMenuClicked = (event) => {
        this.anchorRef = event.currentTarget
        this.setState({ open: !this.state.open });

    }

    closeMenuClicked = () => this.setState({ open: false });

    render() {
        const { children, buttonChildren, menuClassName, disabledOpenMenu, buttonLabel,
            buttonClassName, buttonEndIcon, buttonStartIcon, buttonSize, transformOrigin, placement, isLoading, disabled } = this.props;

        return (
            <div className='d-flex'>
                <LoadingButton ref={this.anchorRef}
                    className={clsx('ns-button', buttonClassName)}
                    endIcon={buttonEndIcon}
                    startIcon={buttonStartIcon}
                    size={buttonSize}
                    loading={isLoading}
                    disabled={disabled}
                    onClick={!disabledOpenMenu ? this.openMenuClicked : null}>
                    {buttonChildren || buttonLabel}
                </LoadingButton>

                <Popper open={this.state.open}
                    anchorEl={this.anchorRef}
                    role={undefined}
                    placement={placement}
                    // transition
                    disablePortal
                    className='popper'>
                    <Paper className={menuClassName} elevation={3}>
                        <ClickAwayListener onClickAway={this.closeMenuClicked}>
                            <div id="composition-menu" aria-labelledby="composition-button">
                                {children}
                            </div>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </div>
        );
    }
}

export default NsMenu;