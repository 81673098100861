import React from 'react';

import { GetCmsBlogFilterCategoriesService, GetCmsNewsFilterCategoriesService } from '../../../../services/singlePages/CmsService';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import BlogNews from '../../BlogNews';
import BnContentMainCategories from './BnContentMainCategories';
import BnContentMainPosts from './BnContentMainPosts';
import PdBaseSimilarProducts from '../../../productDetails/bases/PdBaseSimilarProducts'
import NsCard from '../../../../components/cards/NsCard';
import { connect } from 'react-redux';
import NsPageTitleBox from '../../../../components/labels/NsPageTitleBox';

class BnContentMain extends React.Component {

    constructor(props) {
        super(props);
        this.isBlog = CommonHelper.isBlog(window.location.href);
        this.title = getTranslatedRawText(this.isBlog ? 'adminPanel.sidebar.blog' : 'adminPanel.sidebar.news');
        this.state = {
            categories: []
        }
    }

    componentDidMount = () => this.getHomeCategories();

    getHomeCategories = () => {
        const { pageLength, currentPageIndex } = this.state;
        let parameters = { pageLength: 2, currentPageIndex: 1, isInHome: true };
        this.setState({ isLoading: true });
        this.isBlog
            ? GetCmsBlogFilterCategoriesService(parameters, this.getCmsCategoriesCallback)
            : GetCmsNewsFilterCategoriesService(parameters, this.getCmsCategoriesCallback);
    }

    getCmsCategoriesCallback = (resultData, result, messageClassModal) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError) {
            this.setState({ categories: resultData.dataItems, totalCount: resultData.totalCount });
        } else
            this.setState({ resultMessageClass: messageClassModal });
    };

    mapContentMainPosts = () => {
        return this.state.categories.map((category, index) =>
            <BnContentMainPosts key={category.id} title={category.title} categoryId={category.id} className='mb-2' />
        )
    }

    render() {
        return <BlogNews >
            <NsPageTitleBox title={this.title}
                icon={this.isBlog ? 'ns-icon-blog h1' : 'ns-icon-news h1'} />
            <BnContentMainPosts className='mb-2'
                title={getTranslatedRawText(this.isBlog ? 'adminPanel.newsBlog.newBlog' : 'adminPanel.newsBlog.newNews')}
                isNewPosts={true} />
            <BnContentMainCategories />
            {this.mapContentMainPosts()}
            <NsCard className='px-3'>
                <PdBaseSimilarProducts getSimilarProductsDate={''}
                    categoryIds={[]}
                    selectedCurrency={this.props.selectedCurrency?.id} />
            </NsCard>
        </BlogNews>
    }
}

const mapStateToProps = (state) => {
    return {
        selectedCurrency: state.currencies.selectedCurrency
    };
}

export default connect(mapStateToProps)(BnContentMain);