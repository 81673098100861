import clsx from 'clsx';
import React from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import "../../../../assets/sass/views/singlePages/bases/carousel/SpBasesCategoryCarousel.scss";
import CommonHelper from '../../../../utilities/CommonHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import SpBasesCategoryCard from '../../../../views/singlePages/bases/card/SpBasesCategoryCard';

class SpBasesCategoryCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.borderStyle = 'border-outline-color-1px rounded-2';
        this.newRef = React.createRef();
    }

    componentDidUpdate = (newProps) => {
        if (newProps.images !== this.props.images || newProps.activeStep !== this.props.activeStep)
            this.activeStepChanged(newProps.activeStep)
    }

    activeStepChanged = (activeStep) => {
        this.newRef.current.swiper.slideTo(activeStep);
        CommonHelper.safeFunctionCall(this.props.imageChanged, activeStep)
    }

    mapProducts = (activeStep, products) => {
        return !isEmpty(products) && products.map((product, index) =>
            <SwiperSlide key={product.selectedId} className='d-flex align-self-center swiper-slide__products' >
                <SpBasesCategoryCard img={product.img} title={product.title} />
            </SwiperSlide>
        );
    }

    render() {
        const { activeStep, images, isFestivalAwardModal, isStore, products } = this.props;
        return (
            <div className={'align-items-end flex-nowrap d-flex px-0'} >
                <Swiper className={clsx('d-flex align-items-center flex-nowrap pb-5')}
                    watchSlidesProgress={true}
                    watchSlidesVisibility={true}
                    slidesPerView={'auto'}
                    spaceBetween={10}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={isStore && [Pagination]}
                    ref={this.newRef}>
                    {this.mapProducts(activeStep, products)}
                </Swiper>
            </div>
        );
    }
}

export default SpBasesCategoryCarousel;