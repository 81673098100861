import Links from "../../configs/links/AllLinks"
import CommonHelper from "../../utilities/CommonHelper";
import { getUrlWithCurrentCulture } from "../../utilities/CultureHelper"
import { GetRequest, PutRequest, PatchRequest, DeleteRequest, PostRequest } from "../bases/BaseService"

const GetAnnouncementContentsService = (parameter, callback) => {
    GetRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAnnouncements), parameter, callback);
};

const GetAnnouncementContentByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAnnouncementById), id), {}, callback);
};

const PostAnnouncementContentService = (data, callback, showSuccessResponseMessage) => {
    PostRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.postApAnnouncement), data, callback, showSuccessResponseMessage);
};

const EditAnnouncementContentService = (id, data, callback, showSuccessResponseMessage) => {
    PatchRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApAnnouncement), id), data, callback, showSuccessResponseMessage);
};

const EditSortAnnouncementContentService = (data, callback) => {
    PatchRequest(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.editApSortAnnouncement), data, callback);
};

const DeleteAnnouncementContentService = (id, callback, showSuccessResponseMessage) => {
    DeleteRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.deleteApAnnouncement), id), {}, callback, showSuccessResponseMessage);
};

const GetApAnnouncementContentByIdService = (id, callback) => {
    GetRequest(CommonHelper.stringFormat(getUrlWithCurrentCulture(Links.adminPanel.api, Links.adminPanel.api.getApAnnouncementContentById), id), {}, callback);
};

export {
    GetAnnouncementContentsService,
    GetAnnouncementContentByIdService,
    PostAnnouncementContentService,
    EditAnnouncementContentService,
    EditSortAnnouncementContentService,
    DeleteAnnouncementContentService,
    GetApAnnouncementContentByIdService
}