import React, { useEffect, useState } from 'react';

import ApOrderProductDetailModal from './orderDetailPreview/ApOrderProductDetailModal';
import ApOrderDetailPreviewModal from './orderDetailPreview/ApOrderDetailPreviewModal';
import NsMessageClassModal from '../../../../../components/modals/NsMessageClassModal';
import UserClaimEnumerations from '../../../../../configs/UserClaimEnumerations';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import ApBaseMapActionButtons from '../../../bases/ApBaseMapActionButtons';
import ApBaseUserInfoModal from '../../users/bases/ApBaseUserInfoModal';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { hasAccess } from '../../../../../utilities/ClaimHelper';
import NsModal from '../../../../../components/modals/NsModal';
import Enumerations from '../../../../../configs/Enumerations';
import { getApOrderDetailColumns } from './ApShareSheetOrderHelper';
import ApPaymentModal from '../../payments/ApPaymentModal';
import ApBaseWrapper from '../../../bases/ApBaseWrapper';
import {
    GetOrderDtoService, GetOrderDetailService, GetOrderWebsiteHistoryService, UpdateOrderStatusService,
    DeleteOrderService, GetOrderNasHistoryService
} from '../../../../../services/adminPanel/AdminOrderService';
import ApShareSheetOrderEstimatedTimeModal from './ApShareSheetOrderEstimatedTimeModal';
import ApShareSheetOrderSheetInfoModal from './ApShareSheetOrderSheetInfoModal';
import NsInvoiceButton from '../../../../../components/buttons/NsInvoiceButton';

const ApShareSheetOrderDetailModal = (props) => {

    const { showModal, selectedOrder } = props;
    const hasAccessInfo = hasAccess([UserClaimEnumerations.getApOrderDto]);
    const hasAccessHistory = hasAccess([UserClaimEnumerations.getApOrderStatusHistory]);
    const hasAccessGrid = hasAccess([UserClaimEnumerations.getApOrderDetail]);

    const messageItems = [
        {
            messageText: <>{CommonHelper.stringFormat(getTranslatedRawText('common.messages.areYouSureQuestion'),
                selectedOrder?.productName)}</>,
            messageType: Enumerations.messageType.question
        }];

    const [order, setOrder] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState();
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [showOrderPaymentModal, setShowOrderPaymentModal] = useState(false);
    const [showProductDetailModal, setShowProductDetailModal] = useState(false);
    const [showOrderDetailPreviewModal, setShowOrderDetailPreviewModal] = useState(false);
    const [showDeleteOrderMessageModal, setShowDeleteOrderMessageModal] = useState(false);
    const [showOrderEstimatedTimeModal, setShowOrderEstimatedTimeModal] = useState(false);
    const [showSheetInfoModal, setShowSheetInfoModal] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
    const [selectedHistory, setSelectedHistory] = useState('');
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [resultMessageClass, setResultMessageClass] = useState(<></>);

    useEffect(() => {
        getOrderDto();
    }, []);

    const getSharedSheetOrderStatusHistoryTabs = () => {
        return ([
            { id: Enumerations.sharedSheetOrderStatusHistoryType.websiteStatus, label: getTranslatedRawText('adminPanel.orders.websiteStatus'), service: GetOrderWebsiteHistoryService },
            hasAccess([UserClaimEnumerations.getApOrderNasStatusHistory]) && (!isEmpty(order?.orderItemInfoDto?.nasInvoiceId) || !isEmpty(selectedOrder?.nasInvoiceId)) && { id: Enumerations.sharedSheetOrderStatusHistoryType.nasStatus, label: getTranslatedRawText('adminPanel.orders.nasStatus'), service: GetOrderNasHistoryService },
        ]);
    }

    const handlers = {

        detailClicked: (id) => {
            setSelectedOrderId(id);
            setShowOrderDetailPreviewModal(true);
        },

        changeStatusToInFirstQueueClicked: () => {
            setIsLoadingInfo(true);
            UpdateOrderStatusService([{ orderId: selectedOrder?.id, userId: selectedOrder?.userId }], updateOrderStatusCallback, true);
        },

        deleteOrderClicked: () => setShowDeleteOrderMessageModal(true),

        deleteOrderApproved: (confirm) => {
            setShowDeleteOrderMessageModal(false);
            if (!confirm)
                return;
            setIsLoadingInfo(true);
            DeleteOrderService(selectedOrder?.id, selectedOrder?.userId, deleteOrderCallback, true);
        },

        ordersPaymentClicked: () => setShowOrderPaymentModal(!showOrderPaymentModal),

        closeOrderDetailPreviewModal: () => setShowOrderDetailPreviewModal(false),

        toggleUserInfoModal: () => setShowUserInfoModal(!showUserInfoModal),

        toggleProductDetailModal: () => setShowProductDetailModal(!showProductDetailModal),

        toggleOrderEstimatedTimeModal: () => setShowOrderEstimatedTimeModal(!showOrderEstimatedTimeModal),

        selectedHistoryHandler: (data) => setSelectedHistory(data),

        sheetIdClickedHandler: (data) => setShowSheetInfoModal(data),

        closeSheetInfoModal: () => setShowSheetInfoModal(false),
    }

    const getOrderDto = () => {
        if (!hasAccessInfo) return;
        setIsLoadingInfo(true);
        GetOrderDtoService(selectedOrder?.id, getOrdersByIdCallback);
    }

    const getOrdersByIdCallback = (resultData, result, messageClassModal) => {
        setIsLoadingInfo(false);
        if (!result.data.hasError)
            setOrder({ orderItemInfoDto: resultData });
        else
            setResultMessageClass(messageClassModal);
    }

    const updateOrderStatusCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            setRefreshDate(new Date());
            CommonHelper.safeFunctionCall(props.updateSharedSheetOrderData, new Date());
        }
        setIsLoadingInfo(false);
        setResultMessageClass(messageClassModal);
    }

    const deleteOrderCallback = (resultData, result, messageClassModal) => {
        if (!result?.data?.hasError) {
            CommonHelper.safeFunctionCall(props.closeModal, messageClassModal);
        }
        else {
            setIsLoadingInfo(false);
            setResultMessageClass(messageClassModal);
        }
    }

    const { changeStatusToInFirstQueueClicked, deleteOrderClicked, toggleProductDetailModal, deleteOrderApproved,
        detailClicked, ordersPaymentClicked, closeOrderDetailPreviewModal, toggleUserInfoModal, toggleOrderEstimatedTimeModal,
        selectedHistoryHandler, sheetIdClickedHandler, closeSheetInfoModal } = handlers;

    const isDeletableOrderByState = (stateType) => {
        switch (stateType) {
            case Enumerations.sharedSheetOrderStateType.prepareFail:
            case Enumerations.sharedSheetOrderStateType.notApplied:
                return true
            default: return false
        }
    }

    const headerButtons = () => {
        let isNotAppliedOrder = isDeletableOrderByState(selectedOrder?.lastStatusType);
        let isFailedInPreparingOrder = selectedOrder?.lastStatusType === Enumerations.sharedSheetOrderStateType.prepareFail;
        return [
            isFailedInPreparingOrder && hasAccess([UserClaimEnumerations.updateApOrderStatus]) && { startIcon: 'ns-icon-retry', label: 'adminPanel.common.retry', onClick: changeStatusToInFirstQueueClicked },
            isNotAppliedOrder && hasAccess([UserClaimEnumerations.deleteApNotConfirmedOrder]) && { startIcon: 'ns-icon-ppr-info', label: 'common.delete', onClick: deleteOrderClicked },
            // {  startIcon: 'ns-icon-details', label: 'adminPanel.common.shipmentDetail', },
            hasAccess([UserClaimEnumerations.getApCustomerById]) && { startIcon: 'ns-icon-display-info', label: 'adminPanel.common.userInfo', onClick: toggleUserInfoModal },
            !!order?.orderItemInfoDto?.confirmedTime && hasAccess([UserClaimEnumerations.getApOrderEstimatedTime]) && { startIcon: 'ns-icon-preparation-time', label: 'adminPanel.orders.estimatedPreparationTime', onClick: toggleOrderEstimatedTimeModal },
            hasAccess([UserClaimEnumerations.getApPayments]) && { startIcon: 'ns-icon-payments', label: 'adminPanel.common.ordersPayment', onClick: ordersPaymentClicked },
            hasAccess([UserClaimEnumerations.getApOrderProductDetail]) && { startIcon: 'ns-icon-product-information', label: 'adminPanel.orders.productDetail', onClick: toggleProductDetailModal },
        ];
    }
    const nasInvoiceId = order?.orderItemInfoDto?.nasInvoiceId || selectedOrder?.nasInvoiceId;
    return (<NsModal maxWidth='lg'
        showModal={showModal}
        closeModal={() => CommonHelper.safeFunctionCall(props.closeModal)}
        noSpace={true}
        dialogTitle={getTranslatedRawText("adminPanel.orders.groupSheetOrderDetail")} >

        <ApBaseMapActionButtons actionButtons={headerButtons()}
            isLoading={isLoadingInfo || isLoadingInvoice} >
            {hasAccess([UserClaimEnumerations.getApOrderUnOfficialReport]) &&
                <NsInvoiceButton id={selectedOrder?.id}
                    userId={selectedOrder?.userId}
                    isAdminPanel={true}
                    isLoading={isLoadingInfo || isLoadingInvoice}
                    hasInvoiceNumber={!!order?.orderItemInfoDto?.nasInvoiceId}
                    setLoading={(isLoading) => setIsLoadingInvoice(isLoading)} />
            }
        </ApBaseMapActionButtons>

        <ApBaseWrapper isLoadingInfo={isLoadingInfo}
            info={order}
            isOrderDetail={true}
            hasAccessInfo={hasAccessInfo}
            hasAccessHistory={hasAccessHistory}
            historyService={GetOrderWebsiteHistoryService}
            service={hasAccessGrid ? GetOrderDetailService : ''}
            parameters={{
                id: selectedOrder?.userId,
                orderId: selectedOrder?.id,
                nasInvoiceId: order?.orderItemInfoDto?.nasInvoiceId || selectedOrder?.nasInvoiceId
            }}
            columnDefs={getApOrderDetailColumns(detailClicked, order)}
            refreshDate={refreshDate}
            tabs={getSharedSheetOrderStatusHistoryTabs()}
            selectedHistoryHandler={selectedHistoryHandler}
            sheetIdClicked={sheetIdClickedHandler} />

        {showOrderDetailPreviewModal &&
            <ApOrderDetailPreviewModal showModal={true}
                orderId={selectedOrderId}
                userId={selectedOrder?.userId}
                closeModal={closeOrderDetailPreviewModal} />
        }

        {showOrderPaymentModal &&
            <ApPaymentModal showModal={showOrderPaymentModal}
                searchData={{ orderId: selectedOrder?.id }}
                isHideSearch={true}
                selectedOrder={selectedOrder}
                userId={selectedOrder?.userId}
                closeModal={ordersPaymentClicked} />
        }

        {showUserInfoModal &&
            <ApBaseUserInfoModal showModal={showUserInfoModal}
                selectedUserId={selectedOrder?.userId}
                closeModal={toggleUserInfoModal} />
        }

        {showProductDetailModal &&
            <ApOrderProductDetailModal showModal={true}
                selectedOrder={selectedOrder}
                closeModal={toggleProductDetailModal} />
        }

        {showOrderEstimatedTimeModal &&
            <ApShareSheetOrderEstimatedTimeModal selectedOrder={selectedOrder}
                isAdminPanel={true}
                closeModal={toggleOrderEstimatedTimeModal} />
        }

        {showDeleteOrderMessageModal &&
            <NsMessageClassModal show={true}
                onClose={deleteOrderApproved}
                messageItems={messageItems} />
        }
        {showSheetInfoModal &&
            <ApShareSheetOrderSheetInfoModal showModal={showSheetInfoModal}
                closeModal={closeSheetInfoModal}
                sheetId={selectedHistory?.sheetID}
                nasInvoiceId={nasInvoiceId !== 0 && nasInvoiceId} />
        }

        {resultMessageClass}
    </NsModal >
    );
}

export default React.memo(ApShareSheetOrderDetailModal);