import React, { Component } from 'react';

import { ReactComponent as Telegram } from '../../../../assets/images/social-media-icons/Telegram_logo.svg';
import { ReactComponent as WhatsApp } from '../../../../assets/images/social-media-icons/WhatsApp_Logo.svg';
import '../../../../assets/sass/views/singlePages/contactUs.scss';
import NsPageMainContentWrapper from '../../../../components/cards/NsPageMainContentWrapper';
import NsSetPageTitleAndDescription, { getTitleMetaTagByCulture } from '../../../../components/metaTags/NsSetPageTitleAndDescription';
import NsTypography from '../../../../components/text/NsTypography';
import Config from '../../../../configs/Config';
import CommonHelper from '../../../../utilities/CommonHelper';
import { getConfigObjectByCulture } from '../../../../utilities/ConfigHelper';
import { getTranslatedRawText } from '../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../utilities/ValidationHelper';
import SinglePage from '../../SinglePage';
import NsTab from '../../../../components/tabs/NsTab';
import NsLabelBox from '../../../../components/labels/NsLabelBox';

class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.mapLink = getConfigObjectByCulture(Config.contactInformation)?.googleMapLink;
        this.locationOfCountyWarehouseLink = getConfigObjectByCulture(Config.contactInformation)?.locationOfCountyWarehouseLink;
        this.contactUsInfos = getConfigObjectByCulture(Config.contactInformation)?.contentOfTable;
        this.addressInfos = [
            {
                id: 1,
                label: getTranslatedRawText('singlePage.contact.common.addressOfCentralOffice'),
                address: getTranslatedRawText('singlePage.contact.address'),
                postalCode: getTranslatedRawText('singlePage.contact.postalCode'),
                link: getTranslatedRawText('singlePage.contact.common.centralOffice')
            },
            !isEmpty(this.locationOfCountyWarehouseLink) && {
                id: 2,
                label: getTranslatedRawText('singlePage.contact.common.addressOfCountyWarehouse'),
                address: getTranslatedRawText('singlePage.contact.addressOfCountyWarehouse'),
                postalCode: getTranslatedRawText(''),
                link: getTranslatedRawText('singlePage.contact.common.countyWarehouse')
            },
        ]
        this.state = {
            selectedTabIndex: 0,
            selectedTab: this.addressInfos[0],
        };
    }

    getTableHeaderNames = () => {
        return <thead>
            <tr>
                {this.contactUsInfos.headerNames?.map((item, index) =>
                    <th key={index} className="background-disable-color fw-bold">
                        {item}
                    </th>
                )}
            </tr>
        </thead>
    }

    getContactInformation = () => {
        return this.contactUsInfos.content?.map(unit =>
            <tr key={unit.id}>
                <td>{unit.unit}</td>
                {!isEmpty(unit.hotlineNumber)
                    ? <td>{unit.hotlineNumber}</td>
                    : <td>-</td>
                }
                {!isEmpty(unit.number)
                    ? <td>{unit.number}</td>
                    : <td>-</td>
                }
                {!isEmpty(unit.phoneNumber)
                    ? <td><a href={`tel:${unit.phoneNumber}`} rel="nofollow">{unit.phoneNumber}</a></td>
                    : <td>-</td>
                }
                {!isEmpty(unit.email)
                    ? <td className='text-start'>
                        <a href={`mailto:${unit.email}`} rel="nofollow">{unit.email}</a>
                    </td>
                    : <td>-</td>
                }
                <td>
                    {!isEmpty(unit.whatsapp)
                        ? <a href={`https://wa.me/${unit.whatsapp}`} rel="nofollow" target="_blank"><WhatsApp className='me-1' /></a>
                        : <></>
                    }
                    {!isEmpty(unit.telegram)
                        ? <a href={`https://t.me/${unit.telegram}`} rel="nofollow" target="_blank"><Telegram /></a>
                        : <></>
                    }
                </td>
            </tr>
        )
    }

    tabChanged = (selectedTabIndex, selectedTab) => {
        selectedTabIndex !== this.state.selectedTabIndex && this.setState({ selectedTabIndex, selectedTab });
    }

    render() {
        return (
            <SinglePage >

                <NsPageMainContentWrapper header={getTranslatedRawText('singlePage.contact.contactUs')}
                    icon='ns-icon-contact-us h1' className='mb-3'>
                    <div className='contact-us'>
                        <div className='d-flex flex-column font-size-14'>
                            <NsTypography variant='inherit'>
                                <span className='fw-bold me-2'>{getTranslatedRawText('singlePage.contact.common.availableTimes')}</span>{getTranslatedRawText('singlePage.contact.availableTimes')}
                            </NsTypography>
                        </div>

                        <div style={{ overflowX: 'auto' }}>
                            <table className="w-100 mt-3 mb-5 contact-us contact-us-table" >
                                <caption className="font-size-14 title-color background-element-color border border-light-text-color px-3">
                                    {CommonHelper.stringFormat(getTranslatedRawText("singlePage.contact.tableCaption"), getTitleMetaTagByCulture)}
                                </caption>
                                {this.getTableHeaderNames()}
                                <tbody>
                                    {this.getContactInformation()}
                                </tbody>
                            </table>
                        </div>

                        <NsTab tabs={this.addressInfos}
                            value={this.state.selectedTabIndex}
                            onChange={this.tabChanged}
                            history={this.props.history}
                            queryManagement={true}>
                        </NsTab>

                        <div className='border background-element-color'>
                            <div className='d-flex p-2'>
                                {this.state.selectedTab?.address && <NsLabelBox className='background-white-color min-size me-2'
                                    label={this.state.selectedTab?.label}
                                    value={this.state.selectedTab?.address} />}
                                {this.state.selectedTab?.postalCode && <NsLabelBox className='background-white-color min-size'
                                    label={getTranslatedRawText('singlePage.contact.common.postalCode')}
                                    value={this.state.selectedTab?.postalCode} />}
                            </div>
                            <iframe key={this.state.selectedTab?.id}
                                src={this.state.selectedTab?.id === 1 ? this.mapLink : this.locationOfCountyWarehouseLink}
                                className='border-0 px-2'
                                width="100%"
                                height="450"
                                allowFullScreen=""
                                loading="lazy" />
                        </div>
                    </div>
                </NsPageMainContentWrapper>
                <NsSetPageTitleAndDescription pageTitle='singlePage.contact.contactUs'
                    description={getConfigObjectByCulture(Config.metaTags)?.descriptions.contactUs} />
            </SinglePage >
        );
    }
}

export default ContactUs;