import React from 'react';

import { GetAddressesForAutoDeliveryService } from '../../../../../services/ProfileSetting/DeliveryAddressSettingService';
import ProfileAddressSettintgModal from '../../../modals/ProfileAddressSettintgModal';
import { getTranslatedRawText } from '../../../../../utilities/CultureHelper';
import { isEmpty } from '../../../../../utilities/ValidationHelper';
import NsSelect from '../../../../../components/dropdowns/NsSelect';
import NsButton from '../../../../../components/buttons/NsButton';
import CommonHelper from '../../../../../utilities/CommonHelper';
import { Col, Row } from 'react-bootstrap';

class UpBaseDefaultAddress extends React.Component {

    constructor(props) {
        super(props);
        this.isEditableDefaultAddress = false
        this.state = {
            showAddressModal: false,
            userActiveAddresses: [],
            isLoading: false,
            resultMessageClass: (<></>)
        };
    }

    componentDidMount = () => isEmpty(this.state.userActiveAddresses) && this.getActiveAdresses();

    showModalAddress = () => this.setState({ showAddressModal: true });

    closeAddressModal = (resultMessageClass) => {
        this.setState({ showAddressModal: false, resultMessageClass });
        !!resultMessageClass && this.getActiveAdresses();
    }

    getActiveAdresses = () => {
        this.setState({ isLoading: true });
        GetAddressesForAutoDeliveryService(this.getUserActiveAddressCallback);
    }

    getUserActiveAddressCallback = (resultData, result, resultMessageClass) => {
        this.setState({ isLoading: false });
        if (!result.data.hasError)
            this.setState({ userActiveAddresses: resultData }, () => {
                CommonHelper.safeFunctionCall(this.props.checkRouteAndToggleOfficialInvoiceModal);
            });
        else
            this.setState({ resultMessageClass });
    }

    render() {
        const { showAddressModal, resultMessageClass, userActiveAddresses, isLoading } = this.state;
        const { buttonClassName, selectedDefaultUserAddressId, isValidSelectedDefaultUserAddressId, defaultUserAddressVisibilityType } = this.props;

        return (
            <Row>
                <Col xs={9}>
                    <NsSelect label={getTranslatedRawText('profile.personal.defaultUserAddress')}
                        valueChanged={(value, isValid) =>
                            this.props.valueChanged(value, isValid, { selectedDefaultUserAddressId }, { isValidSelectedDefaultUserAddressId }, userActiveAddresses)}
                        options={userActiveAddresses}
                        disabled={CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(defaultUserAddressVisibilityType)}
                        selectedItem={selectedDefaultUserAddressId} />
                </Col>
                <Col xs={2} className="pt-3">
                    {!CommonHelper.isReadOnlyFieldBasedOnFieldVisibilityType(defaultUserAddressVisibilityType) &&
                        <NsButton className={buttonClassName || "secondary"}
                            startIcon='ns-icon-add'
                            label={CommonHelper.isMobileDevice() ? '' : "profile.address.add"}
                            size="small"
                            onClick={this.showModalAddress} />
                    }
                    {showAddressModal &&
                        <ProfileAddressSettintgModal closeModal={this.closeAddressModal} />
                    }
                    {resultMessageClass}
                </Col>
            </Row>
        );
    }
}

export default UpBaseDefaultAddress;