
export const findNodeById = (id, data, result = null) => {
    if (result) {
        return result;
    }
    for (let i in data) {
        if (data[i].id === id) {
            result = result || data[i];
        } else {
            if (data[i].children && data[i].children.length > 0) {
                result = findNodeById(id, data[i].children, result) || result;
            }
        }
    }
    return result;
}

export const getPathListById = (id, data) => {
    let fileOrFolder = findNodeById(id, data, null);
    if (fileOrFolder) return fileOrFolder.path || [];
    return [];
}

export const getContentListById = (id, data) => {
    let fileOrFolder = findNodeById(id, data, null);
    if (fileOrFolder)
        return getContentListByPath(fileOrFolder.path, data) || [];
    return [];
}

const getContentListByPath = (path, data) => {
    let contentListResult = [];
    let currentContent = data;
    if (Array.isArray(path) && path.length > 0) {
        for (let id of path) {
            if (!Array.isArray(currentContent)) break;
            let content = currentContent.find(x => x.id === id);
            if (content === null) break;
            contentListResult.push({ ...content });
            if (content && content.children)
                currentContent = content.children;
            else break;
        }
    } else {
        return [];
    }
    return contentListResult;
}



